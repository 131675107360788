import React, { useEffect, memo } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';

import { useFormContext, RegisterOptions } from 'react-hook-form';
import { CircularProgress } from '@material-ui/core';
import { AutocompleteChangeReason, Value } from '@material-ui/lab/useAutocomplete';
import { get } from 'lodash';

export interface AutocompleteInputProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  optionText?: string;
  name: string;
  label?: string;
  rules?: RegisterOptions;
  autoFocus?: boolean;
  primaryKey?: string;
  required?: boolean;
}

const AutocompleteInput = <
  T extends { [key: string]: any },
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  autoFocus,
  optionText,
  name,
  label = name,
  rules,
  primaryKey = 'id',
  required,
  options,
  onChange,
  onInputChange,
  ...props
}: Omit<AutocompleteInputProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>) => {
  const { register, setValue: setValueForm, errors, getValues } = useFormContext();
  const [value, setValue] = React.useState<Value<T, Multiple, DisableClearable, FreeSolo>>(
    props.defaultValue || (props.value as any) || null
  );
  const [inputValue, setInputValue] = React.useState<string>('');
  const val = get(getValues(), name);

  useEffect(() => {
    register(name, rules);
  }, [register, name, rules]);

  useEffect(() => {
    if (options?.length && props.defaultValue) {
      const selected = options.find((item: any) => item[primaryKey] === props.defaultValue);

      if (selected) {
        setValue(selected as any);
        setValueForm(name, selected[primaryKey]);
      } else {
        setValue(null as any);
        setValueForm(name, null);
      }
    }
  }, [name, options, primaryKey, props.defaultValue, setValueForm]);

  useEffect(() => {
    if (value) return;
    if (options?.length) {
      const selected = options.find((item: any) => item[primaryKey] === val);

      if (selected) {
        setValue(selected as any);
        setValueForm(name, selected[primaryKey]);
      }
    }
  }, [options, val, name, primaryKey, setValueForm, value]);

  return (
    <Autocomplete
      value={value}
      onChange={(event: React.ChangeEvent<{}>, newValue: any | null, reason: AutocompleteChangeReason) => {
        setValueForm(name, newValue ? newValue[primaryKey] : null);
        setValue(newValue);
        if (onChange) onChange(event, newValue, reason);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue, reason) => {
        setInputValue(newInputValue);
        if (onInputChange) onInputChange(event, newInputValue, reason);
      }}
      loadingText={<CircularProgress size="24px" color="secondary" />}
      options={options}
      noOptionsText="ไม่พบข้อมูล"
      renderInput={(params) => (
        <TextField
          error={!!(errors && get(errors, name))}
          // size="small"
          variant="outlined"
          {...params}
          autoFocus={autoFocus}
          label={label}
          required={required}
        />
      )}
      {...props}
    />
  );
};

export default memo(AutocompleteInput);
