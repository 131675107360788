import React, { Fragment, useMemo, useState } from 'react';
import { useGetProjectsQuery } from '@app/generated/graphql';
import { projectonColumns } from './columns';
import DataGridContainer from '@app/shared/components/DataGridContainer';
import LoadMoreRowsTag from '@app/shared/components/LoadMoreRowsTag';
import { listPageContent } from '@app/shared/hoc/list-page';
import DataGridFetchMore from '@app/shared/components/DataGridFetchMore';
import AddIcon from '@material-ui/icons/Add';
import { useRdgSort } from '@app/shared/hooks/useRdgSort';
import { useDebounce } from 'use-debounce';
import { groupBy as rowGrouper } from 'lodash';
import PageTitle from '@app/shared/components/PageTitle';
import { Typography } from '@material-ui/core';
import { useTypography } from '@app/shared/styles/typography';
import LinkButton from '@app/shared/components/buttons/LinkButton';
import Spacing from '@app/components/common/Spacing';
import { InfoCard } from '@mystiny/ui';
import { ExportButton } from '@app/views/Project/components/export/ExportButton';
import { ProjectFilter } from './components';

const ProjectList = () => {
  const typographyClasses = useTypography();
  const [filters, setFilters] = useState<any>({
    title: '',
    reference: '',
    industry: '',
  });
  const [value] = useDebounce(filters, 200);
  const [offset, setOffset] = React.useState(0);
  const { handleSort, orderBy, sort } = useRdgSort();

  const [projectStatus, setProjectStatus] = useState<string | null>(null);
  const { data, fetchMore, loading, variables } = useGetProjectsQuery({
    variables: {
      where: {
        title: {
          _ilike: value.title ? `%${value.title}%` : undefined,
        },
        reference: {
          _ilike: value.reference ? `%${value.reference}%` : undefined,
        },
        industry: {
          _ilike: value.industry ? `%${value.industry}%` : undefined,
        },
        ...(projectStatus !== null
          ? {
              step_id: { _eq: projectStatus },
            }
          : {}),
      },
      orderBy: orderBy,
    },
    fetchPolicy: 'cache-and-network',
  });

  const budgetList = useMemo(
    () => (
      <DataGridContainer height="80vh">
        <DataGridFetchMore
          fetchMore={fetchMore}
          variables={variables}
          offset={offset}
          setOffset={setOffset}
          sortColumn={sort?.column}
          sortDirection={sort?.direction}
          onSort={handleSort}
          defaultColumnOptions={{ sortable: true, resizable: true }}
          rows={data?.rows || []}
          rowHeight={42}
          columns={projectonColumns}
          rowGrouper={rowGrouper}
          filters={filters}
          onFiltersChange={setFilters}
          enableFilters
        />
        <LoadMoreRowsTag loading={loading} />
      </DataGridContainer>
    ),
    [data, fetchMore, loading, variables, filters, setFilters, offset, setOffset, handleSort, sort]
  );

  return (
    <React.Fragment>
      <PageTitle
        title={
          <Typography variant="h5" color="primary" className={typographyClasses.pageTitle}>
            คำของบประมาณ
          </Typography>
        }
        actions={
          <Fragment>
            <ExportButton data={data?.rows || []} name={'คำของบประมาณ'} />
            <LinkButton startIcon={<AddIcon />} to={`/bof/projects/proposals/create`}>
              สร้างคำของบประมาณใหม่
            </LinkButton>
          </Fragment>
        }
      />
      <Spacing />
      <InfoCard noPadding>
        <ProjectFilter index={projectStatus} setIndex={setProjectStatus} />
        {budgetList}
      </InfoCard>
    </React.Fragment>
  );
};

export default listPageContent(ProjectList);
