import Button, { ButtonProps } from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import React from 'react';

const SaveButton = (props: Omit<ButtonProps, 'startIcon'>) => (
  <Button variant="contained" color="primary" startIcon={<SaveIcon />} {...props}>
    {props.children ? props.children : 'บันทึกข้อมูล'}
  </Button>
);

export default SaveButton;
