import { useGetProjectByPkQuery } from '@app/generated/graphql';
import Page from '@app/components/Page';
import DescriptionIcon from '@material-ui/icons/Description';
import { pageContent } from '@app/shared/hoc/page-content';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from '@app/shared/components/PageTitle';
import BackButton from '@app/shared/components/buttons/BackButton';
import Spacing from '@app/components/common/Spacing';
import { ProjectStatus, ProjectOperatingActivitiesProgress } from './components';
import { Alert } from '@material-ui/lab';
import { Link } from 'react-router-dom';

const ProjectPerformanceReportPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetProjectByPkQuery({
    variables: {
      id,
    },
  });

  if (loading && !data?.row) {
    return <CircularProgress />;
  }

  if (!data?.row) {
    return null;
  }

  return (
    <Page
      title={
        <PageTitle
          title={
            <React.Fragment>
              <Typography variant="h6" component="div">
                <DescriptionIcon color="primary" fontSize="small" /> {data.row.title}
              </Typography>
              <ProjectStatus project={data.row} />
            </React.Fragment>
          }
          actions={
            <div>
              <BackButton to="/bof/projects/proposals" />
            </div>
          }
        />
      }
    >
      <Fragment>
        <Alert severity="info">
          <Button component={Link} to={`/bof/projects/proposals/${id}`} color="primary" variant="outlined">
            คลิกที่นี่ เพื่อไปข้อมูลคำของบประมาณ
          </Button>
        </Alert>
        <Spacing />
      </Fragment>
      <ProjectOperatingActivitiesProgress projectId={id} />
    </Page>
  );
};

export default pageContent(ProjectPerformanceReportPage);
