import { withIfUser } from '@app/components/common/Routes';
import { hasPermissions } from '@app/utils';
import TargetGroupList from '@app/views/ProjectTargetGroup/List';
import { memo } from 'react';

export default memo(
  withIfUser(TargetGroupList)({
    condition: ({ roles }) => roles.some(hasPermissions),
    redirect: true,
    redirectTo: '/unauthorized',
  })
);
