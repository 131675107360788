import Button, { ButtonProps } from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/ArrowBack';
import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export interface BackButtonProps extends Omit<ButtonProps, 'startIcon'> {
  to: string;
}

const BackButton = ({ to, ...props }: BackButtonProps) => {
  const history = useHistory();

  const handleBack = useCallback(() => {
    if (history.length <= 2) {
      history.push(to!);
    } else {
      history.goBack();
    }
  }, [history, to]);

  return (
    <Button variant="outlined" color="primary" startIcon={<CloseIcon />} onClick={handleBack} {...props}>
      ย้อนกลับ
    </Button>
  );
};

export default memo(BackButton);
