import React, { Fragment } from 'react';
import Spacing from '@app/components/common/Spacing';
import { InfoCard } from '@mystiny/ui';
import {
  Typography,
  FormControl,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Switch,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';
import PageTitle from '@app/shared/components/PageTitle';
import { listPageContent } from '@app/shared/hoc/list-page';
import { FormProvider, useForm } from 'react-hook-form';
import { DatePicker } from '@aginix/mui-react-hook-form-input';
import { DateUtils } from '@app/shared/libs/date';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { useGetLedgerTotalReportQuery, GetLedgerTotalReportQuery } from '@app/generated/graphql';
import sumBy from 'lodash/sumBy';
import groupBy from 'lodash/groupBy';

const DisplayNumber = ({ value, ...props }: NumberFormatProps) => (
  <NumberFormat value={value} displayType="text" decimalScale={2} thousandSeparator {...props} />
);

const TotalReport = () => {
  const [state, setState] = React.useState(true);
  const form = useForm({
    defaultValues: {
      date: new Date(),
      budgets: [],
    },
  });

  const date = form.watch('date', new Date()) as Date;

  const { data, loading } = useGetLedgerTotalReportQuery({
    variables: {
      fiscalYear: 2564,
      // fiscalYear: DateUtils.getThaiFiscalYear(date),
    },
    fetchPolicy: 'cache-and-network',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.checked);
  };

  return (
    <Fragment>
      <PageTitle
        title={
          <Typography variant="h5" color="primary">
            Total Report
          </Typography>
        }
      />
      <Spacing />
      <InfoCard>
        <FormProvider {...form}>
          <form noValidate onSubmit={form.handleSubmit((formData) => console.log(formData))}>
            <FormControl margin="dense" variant="outlined">
              <DatePicker
                size="small"
                label="ปีงบประมาณ"
                name="date"
                format="yyyy"
                inputVariant="outlined"
                views={['year']}
                fullWidth
              />
            </FormControl>
            {/* <FormControl margin="dense" variant="outlined">
              <InputLabel id="demo-mutiple-checkbox-label">งบประมาณที่ต้องการแสดง</InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                name="budgets"
                input={<Input />}
                variant="outlined"
                renderValue={(selected: any) => `Selected ${selected.length}`}
                MenuProps={MenuProps}
              >
                {data?.budgets.map((budget) => (
                  <MenuItem key={budget.id + '-option'} value={budget.id}>
                    <Checkbox checked={personbudget.indexOf(budget) > -1} />
                    <ListItemText primary={budget.description} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </form>
        </FormProvider>
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={state} onChange={handleChange} name="group" />}
              label="แสดงตามกลุ่มงบประมาณ"
            />
          </FormGroup>
        </FormControl>
        <Spacing />
        <Typography variant="h6" gutterBottom>
          ปีงบประมาณ พ.ศ. {DateUtils.getThaiFiscalYear(date)}
        </Typography>

        {state ? (
          <TotalReportTableGroupBy data={data} loading={loading} />
        ) : (
          <TotalReportTable data={data} loading={loading} />
        )}
      </InfoCard>
    </Fragment>
  );
};

const TotalReportTableGroupBy = ({
  data,
  loading,
}: {
  data: GetLedgerTotalReportQuery | undefined;
  loading?: boolean;
}) => {
  const budgetGroup = groupBy(data?.budgets, 'group_name');
  const groupLength = Object.keys(budgetGroup).length;
  return (
    <TableContainer>
      <Table size="small" style={{ minWidth: 'max-content' }}>
        <TableHead>
          <TableRow>
            <TableCell style={{ minWidth: 200 }} rowSpan={2}>
              หน่วยงาน
            </TableCell>
            {data?.accounts.map((account) => (
              <TableCell key={`${account.id}-header`} colSpan={groupLength} align="center">
                {account.code} {account.name}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {data?.accounts.map((account) => (
              <Fragment key={`${account.id}-budget-header`}>
                {Object.keys(budgetGroup).map((groupName) => (
                  <TableCell style={{ minWidth: 160 }} key={`${account.id}-${groupName}-header`} align="center">
                    {groupName}
                  </TableCell>
                ))}
              </Fragment>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>ยอดรวม</TableCell>
            {data?.accounts.map((account) => (
              <Fragment key={`${account.id}-budget-header`}>
                {Object.keys(budgetGroup).map((groupName, groupIdx) => {
                  const budgets = budgetGroup[groupName];
                  return (
                    <TableCell style={{ minWidth: 160, borderRight: groupIdx === groupLength - 1 ? '2px solid rgb(117 117 117)' : undefined, }} key={`${account.id}-${groupName}-balance-total`} align="right">
                      <DisplayNumber
                        style={{ fontWeight: 'bold' }}
                        value={sumBy(
                          data?.total.filter((row) => row.account_id === account.id && budgets.some(budget => row.budget_id === budget.id)),
                          'budget_balance'
                        )}
                      />
                    </TableCell>
                  );
                })}
              </Fragment>
            ))}
          </TableRow>
          {loading && (
            <TableRow>
              <TableCell style={{ minWidth: 200 }}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
          {data?.organizations.map((organization) => (
            <TableRow key={organization.id}>
              <TableCell style={{ minWidth: 200 }} scope="row">
                {organization.reference} {organization.short_name}
              </TableCell>
              {data?.accounts.map((account) => (
                <Fragment key={`${account.id}-budget-data`}>
                  {Object.keys(budgetGroup).map((groupName, groupIdx) => {
                  const budgets = budgetGroup[groupName];
                  return (
                    <TableCell style={{ minWidth: 160, borderRight: groupIdx === groupLength - 1 ? '2px solid rgb(117 117 117)' : undefined, }} key={`${account.id}-${groupName}-data`} align="right">
                      <DisplayNumber
                        style={{ fontWeight: 'bold' }}
                        value={
                          data?.total.find((row) => row.account_id === account.id && budgets.some(budget => row.budget_id === budget.id) && row.organization_id === organization.id)?.budget_balance || 0
                        }
                      />
                    </TableCell>
                  );
                })}
                </Fragment>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TotalReportTable = ({ data, loading }: { data: GetLedgerTotalReportQuery | undefined; loading?: boolean }) => (
  <TableContainer>
    <Table size="small" style={{ minWidth: 'max-content' }}>
      <TableHead>
        <TableRow>
          <TableCell style={{ minWidth: 200 }} rowSpan={2}>
            หน่วยงาน
          </TableCell>
          {data?.accounts.map((account) => (
            <TableCell key={`${account.id}-header`} colSpan={data?.budgets.length} align="center">
              {account.code} {account.name}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          {data?.accounts.map((account) => (
            <Fragment key={`${account.id}-budget-header`}>
              {data?.budgets.map((budget) => (
                <TableCell style={{ minWidth: 160 }} key={`${account.id}-${budget.id}-header`} align="center">
                  {budget.description}
                </TableCell>
              ))}
            </Fragment>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>ยอดรวม</TableCell>
          {data?.accounts.map((account) => (
            <Fragment key={`${account.id}-budget-header`}>
              {data?.budgets.map((budget, budgetIdx) => (
                <TableCell
                  style={{
                    minWidth: 160,
                    borderRight: budgetIdx === data?.budgets.length - 1 ? '2px solid rgb(117 117 117)' : undefined,
                  }}
                  key={`${account.id}-${budget.id}-balance-total`}
                  align="right"
                >
                  <DisplayNumber
                    style={{ fontWeight: 'bold' }}
                    value={sumBy(
                      data?.total.filter((row) => row.account_id === account.id && row.budget_id === budget.id),
                      'budget_balance'
                    )}
                  />
                </TableCell>
              ))}
            </Fragment>
          ))}
        </TableRow>
        {loading && (
          <TableRow>
            <TableCell style={{ minWidth: 200 }}>
              <CircularProgress />
            </TableCell>
          </TableRow>
        )}
        {data?.organizations.map((organization) => (
          <TableRow key={organization.id}>
            <TableCell style={{ minWidth: 200 }} scope="row">
              {organization.reference} {organization.short_name}
            </TableCell>
            {data?.accounts.map((account) => (
              <Fragment key={`${account.id}-budget-data`}>
                {data?.budgets.map((budget, budgetIdx) => (
                  <TableCell
                    style={{
                      minWidth: 160,
                      borderRight: budgetIdx === data?.budgets.length - 1 ? '2px solid rgb(117 117 117)' : undefined,
                    }}
                    key={`${account.id}-${budget.id}-data`}
                    align="right"
                  >
                    <DisplayNumber
                      value={
                        data?.total.find(
                          (row) =>
                            row.account_id === account.id &&
                            row.budget_id === budget.id &&
                            row.organization_id === organization.id
                        )?.budget_balance || 0
                      }
                    />
                  </TableCell>
                ))}
              </Fragment>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default listPageContent(TotalReport);
