import DataGridContainer from '@app/shared/components/DataGridContainer';
import React, { Fragment, memo } from 'react';
import DataGrid, { Column } from 'react-data-grid';
import { ReceivableLogFragment } from '@app/generated/graphql';
import { DateUtils } from '@app/shared/libs/date';
import { ReceivableStatus } from './ReceivableStatus';
import { InfoCard } from '@mystiny/ui';

const columns: Column<ReceivableLogFragment>[] = [
  {
    key: 'created_at',
    name: 'วันที่ทำรายการ',
    formatter: ({ row }) => <Fragment>{DateUtils.toThaiDatetime(row.created_at)}</Fragment>,
  },
  {
    key: 'old_status.description',
    name: 'สถานะเก่า',
    formatter: ({ row }) => <ReceivableStatus status={row.old_status} ChipProps={{ size: 'small' }} />,
  },
  {
    key: 'new_status.description',
    name: 'สถานะใหม่',
    formatter: ({ row }) => <ReceivableStatus status={row.new_status} ChipProps={{ size: 'small' }} />,
  },
  {
    key: 'description',
    name: 'คำอธิบาย',
    formatter: ({ row }) => <Fragment>{row.description}</Fragment>,
  },
  {
    key: 'created_by_user.display_name',
    name: 'ชื่อผู้ทำรายการ',
    formatter: ({ row }) => <Fragment>{row.created_by_user?.display_name}</Fragment>,
  },
];

const ReceivableLogs = ({ logs }: { logs: ReceivableLogFragment[] }) => (
  <InfoCard title="ประวัติการทำรายการ" noPadding>
    <DataGridContainer height={240}>
      <DataGrid
        columns={columns}
        className="fill-grid rdg-light"
        defaultColumnOptions={{ resizable: true }}
        rows={logs || []}
      />
    </DataGridContainer>
  </InfoCard>
);

export default memo(ReceivableLogs);
