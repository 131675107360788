export function prompt(message: string, options: { value: string; defaultValue?: string; callback?: any }) {
  const { value, defaultValue, callback } = options;
  const promptValue = window.prompt(message, defaultValue);
  if (promptValue !== null && promptValue === value) {
    if (callback) callback();
    return;
  }
  if (promptValue !== null) {
    prompt(message, options);
  }
}
