import React, { Fragment, memo, useMemo } from 'react';
import { Row } from 'react-data-grid';
import { GetAccountRolesSummaryComponent, GetUsersComponent } from '@app/generated/graphql';
import { userColumns } from './columns';
import DataGridContainer from '@app/shared/components/DataGridContainer';
import { useHistory } from 'react-router-dom';
import { useRdgSort } from '@app/shared/hooks/useRdgSort';
import { useDebounce } from 'use-debounce';
import LoadMoreRowsTag from '@app/shared/components/LoadMoreRowsTag';
import DataGridFetchMore from '@app/shared/components/DataGridFetchMore';
import { listPageContent } from '@app/shared/hoc/list-page';
import { Box, Grid, ListItem, ListItemText, Paper } from '@material-ui/core';
import GmailSidebarItem from '@mui-treasury/components/sidebarItem/gmail';
import Menu from '@mui-treasury/components/menu/collapsible';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import { green } from '@material-ui/core/colors';
import SidebarItemAmount from '@app/shared/components/SidebarItemAmount';

const UserSideMenu = memo(({ selectedRole, handleSelectRole }: any) => {
  return (
    <GetAccountRolesSummaryComponent>
      {({ data, loading }) => (
        <Menu
          collapsed
          renderToggle={({ collapsed, onClick }: any) => (
            <GmailSidebarItem
              startIcon={collapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              label={collapsed ? 'ซ่อน' : 'แสดง'}
              onClick={onClick}
            />
          )}
        >
          <ListItem>
            <ListItemText primary="ประเภท" />
          </ListItem>
          <GmailSidebarItem
            color={green[500]}
            startIcon={<Fragment />}
            label="ทั้งหมด"
            amount={<SidebarItemAmount value={data?.total.aggregate?.count} loading={loading} />}
            onClick={handleSelectRole('all')}
            selected={selectedRole === 'all'}
          />
          <GmailSidebarItem
            color={green[500]}
            startIcon={<Fragment />}
            label="ผู้ใช้ทั่วไป"
            amount={<SidebarItemAmount value={data?.user.aggregate?.count} loading={loading} />}
            onClick={handleSelectRole('user')}
            selected={selectedRole === 'user'}
          />
          <GmailSidebarItem
            color={green[500]}
            startIcon={<Fragment />}
            label="เจ้าหน้าที่ (ศภ.)"
            amount={<SidebarItemAmount value={data?.employee.aggregate?.count} loading={loading} />}
            onClick={handleSelectRole('employee')}
            selected={selectedRole === 'employee'}
          />
          <GmailSidebarItem
            color={green[500]}
            startIcon={<Fragment />}
            label="เจ้าหน้าที่ดูแลระบบ"
            amount={<SidebarItemAmount value={data?.sysadmin.aggregate?.count} loading={loading} />}
            onClick={handleSelectRole('sysadmin')}
            selected={selectedRole === 'sysadmin'}
          />
        </Menu>
      )}
    </GetAccountRolesSummaryComponent>
  );
});

const UserListPage = () => {
  const history = useHistory();
  const [selectedRole, setSelectedRole] = React.useState('all');
  const [offset, setOffset] = React.useState(0);
  const { handleSort, orderBy, sort } = useRdgSort();
  const [filters, setFilters] = React.useState<any>({
    display_name: '',
    'account.email': '',
  });

  const [value] = useDebounce(filters, 200);

  const handleSelectRole = React.useCallback(
    (role: string) => () => {
      setSelectedRole(role);
    },
    [setSelectedRole]
  );

  const userList = useMemo(
    () => (
      <GetUsersComponent
        variables={{
          where: {
            ...(value['organization.short_name']
              ? {
                  organization: {
                    short_name: {
                      _ilike: `%${value['organization.short_name']}%`,
                    },
                  },
                }
              : {}),
            tel: { _ilike: value.tel ? `%${value.tel}%` : undefined },
            display_name: {
              _ilike: value.display_name ? `%${value.display_name}%` : undefined,
            },
            account: {
              email: {
                _ilike: value['account.email'] ? `%${value['account.email']}%` : undefined,
              },
              account_roles: {
                role: {
                  _eq: selectedRole !== 'all' ? selectedRole : undefined,
                },
              },
            },
          },
          orderBy,
        }}
        fetchPolicy="cache-and-network"
      >
        {({ data, fetchMore, variables, loading }) => (
          <DataGridContainer height="80vh">
            <DataGridFetchMore
              offset={offset}
              setOffset={setOffset}
              fetchMore={fetchMore}
              variables={variables}
              loading={loading}
              sortColumn={sort?.column}
              sortDirection={sort?.direction}
              onSort={handleSort}
              onRowClick={() => {}}
              rowRenderer={(props) => (
                <Row {...props} onDoubleClick={() => history.push(`/bof/users/${props.row.id}`)} />
              )}
              defaultColumnOptions={{ sortable: true }}
              rows={data?.rows || []}
              columns={userColumns}
              enableFilters
              filters={filters}
              onFiltersChange={(records) => {
                setFilters(() => records);
              }}
            />
            <LoadMoreRowsTag loading={loading} />
          </DataGridContainer>
        )}
      </GetUsersComponent>
    ),
    [value, selectedRole, sort, handleSort, history, filters, setFilters, offset, setOffset, orderBy]
  );

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
          <Paper variant="outlined" style={{ height: '100%' }}>
            <Box padding={1}>
              <UserSideMenu selectedRole={selectedRole} handleSelectRole={handleSelectRole} />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={8} lg={9} xl={10}>
          {userList}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default listPageContent(React.memo(UserListPage));
