import { DatePicker, ErrorMessage, TextField } from '@aginix/mui-react-hook-form-input';
import { useIfUser } from '@app/components/common/Routes';
import {
  BudgetTypeFragment,
  LedgerLedgerStatusEnum,
  LedgerFragment,
  GetOrganizationsComponent,
  useGetAccountBudgetAccountsQuery,
  useGetBudgetTypesQuery,
} from '@app/generated/graphql';
import { FormProps } from '@app/global-props';
import AutocompleteInput from '@app/shared/components/AutocompleteInput';
import { FormControl, FormHelperText, TextField as MuiTextField, Typography } from '@material-ui/core';
import { FormLayout, FormLayoutGroup } from '@mystiny/ui';
import React, { Fragment, useMemo } from 'react';
import { Control, Controller, useFormContext, useWatch } from 'react-hook-form';
import { DateUtils } from '@app/shared/libs/date';
import { getDateRange } from './hooks/useDateRange';
import { createNumberFormatInput } from '@app/components/NumberFormatInput';
import Select from 'react-select';

const NumberFormatInput = createNumberFormatInput({
  allowNegative: true,
  thousandSeparator: true,
});

const BudgetAutocomplete = ({
  control,
  fiscalYear,
  setValue,
  disabled,
}: {
  control: Control;
  fiscalYear: number;
  setValue: any;
  disabled?: boolean;
}) => {
  const { data: budget, loading: budgetLoading } = useGetBudgetTypesQuery({
    fetchPolicy: 'cache-first',
  });

  const options = useMemo(() => (budget?.rows ? budget?.rows.filter((row) => row.fiscal_year === fiscalYear) : []), [
    budget,
    fiscalYear,
  ]);

  return (
    <FormControl fullWidth>
      <Typography color="textSecondary">งบประมาณ</Typography>
      <Controller
        name="budget"
        control={control}
        rules={{ required: 'กรุณาระบุงบประมาณ' }}
        render={({ name, value, onChange }) => (
          <Select
            name={name}
            isSearchable
            isLoading={budgetLoading}
            value={value}
            onChange={(v) => {
              onChange(v);
              setValue('account', null);
            }}
            isDisabled={disabled}
            options={options}
            placeholder="งบประมาณ"
            getOptionLabel={(v) => v.description}
            getOptionValue={(option) => option.id}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        )}
      />
      <ErrorMessage name="budget" />
    </FormControl>
  );
};

const AccountAutocomplete = ({
  control,
  fiscalYear,
  setValue,
  disabled,
}: {
  control: Control;
  fiscalYear: number;
  setValue: any;
  disabled?: boolean;
}) => {
  const budget = useWatch<BudgetTypeFragment>({
    control,
    name: 'budget',
  });
  const { data: account } = useGetAccountBudgetAccountsQuery({
    fetchPolicy: 'cache-first',
  });

  const options = useMemo(
    () =>
      account?.rows
        ? account?.rows.filter((row) => row.budget_id === budget?.id && row.fiscal_year === fiscalYear)
        : [],
    [account, budget, fiscalYear]
  );

  return (
    <FormControl fullWidth>
      <Typography color="textSecondary">รหัสบัญชีและชื่อบัญชี</Typography>
      <Controller
        id="account"
        name="account"
        control={control}
        rules={{ required: 'กรุณาระบุบัญชี' }}
        render={({ name, value, onChange }) => (
          <Select
            id="account"
            name={name}
            isDisabled={disabled}
            isSearchable
            value={value}
            onChange={(v) => onChange(v)}
            options={options}
            getOptionLabel={(v) => {
              let option = v;
              if (!option.account) {
                option = options.find((a) => a.account_id === v.id);
              }
              return option?.account
                ? `${option.account?.code} ${option.account?.name} (${option.account?.account_type?.description})`
                : '';
            }}
            placeholder="รหัสและชื่อบัญชี"
            getOptionValue={(option) => option.account_id}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        )}
      />
      <ErrorMessage name="account" />
    </FormControl>
  );
};

export const LedgerForm = ({ data, mode }: FormProps<LedgerFragment>) => {
  const { maxDate } = getDateRange();
  const isSysadmin = useIfUser({ condition: ({ roles }) => roles.includes('sysadmin') });
  const form = useFormContext();
  const fiscalYear = DateUtils.getThaiFiscalYear(form.watch('date'));

  const disabled =
    mode === 'update' &&
    [LedgerLedgerStatusEnum.Submitted, LedgerLedgerStatusEnum.Approved, LedgerLedgerStatusEnum.Locked].includes(
      data?.status_id || LedgerLedgerStatusEnum.Draft
    );

  return (
    <FormLayout>
      <FormLayoutGroup>
        <FormControl fullWidth>
          <DatePicker
            label="วันที่ทำรายการ"
            name="date"
            id="date"
            format="dd/MM/yyyy"
            inputVariant="outlined"
            disableFuture
            // minDate={minDate}
            maxDate={maxDate}
            size="small"
            fullWidth
            required
            rules={{ required: 'กรุณาระบุวันที่ทำรายการ' }}
            disabled={disabled}
          />
          <ErrorMessage name="date" />
          <FormHelperText>*สามารถทำรายการได้ไม่เกินวันที่ 5 ของเดือนถัดไป </FormHelperText>
          <FormHelperText error>ปัจจุบันระบบเปิดให้กรอกข้อมูลย้อนหลังจากได้</FormHelperText>
        </FormControl>
        <FormControl fullWidth>
          <MuiTextField
            label="ปีงบประมาณ (พ.ศ.)"
            name="fiscal_year"
            id="fiscal_year"
            variant="standard"
            size="small"
            value={fiscalYear}
            InputProps={{ readOnly: true }}
            disabled={disabled}
          />
          <FormHelperText>*คำนวณอัตโนมัติจากข้อมูลวันที่ทำรายการ</FormHelperText>
        </FormControl>
      </FormLayoutGroup>
      <FormLayoutGroup>
        <BudgetAutocomplete
          control={form.control}
          setValue={form.setValue}
          fiscalYear={fiscalYear}
          disabled={disabled}
        />
        <AccountAutocomplete
          control={form.control}
          setValue={form.setValue}
          fiscalYear={fiscalYear}
          disabled={disabled}
        />
      </FormLayoutGroup>
      <FormLayoutGroup>
        <FormControl fullWidth error={!!form.errors['amount']}>
          <TextField
            label="จำนวนเงิน"
            name="amount"
            variant="outlined"
            size="small"
            fullWidth
            rules={{ required: 'กรุณากรอกจำนวนเงิน' }}
            InputProps={{
              inputComponent: NumberFormatInput as any,
            }}
            required
            disabled={disabled}
          />
          <ErrorMessage name="amount" />
        </FormControl>
        <FormControl fullWidth error={!!form.errors['gfmis_id']}>
          <TextField
            label="เลขที่ใบขอเบิก GFMIS"
            name="gfmis_id"
            id="gfmis_id"
            variant="outlined"
            size="small"
            rules={{
              required: 'กรุณากรอกเลขที่ใบขอเบิก',
              minLength: { value: 10, message: 'กรุณากรอกเลขที่ใบขอเบิก 10 หลัก' },
              maxLength: { value: 10, message: 'กรุณากรอกเลขที่ใบขอเบิก 10 หลัก' },
            }}
            required
            disabled={disabled}
          />
        </FormControl>
      </FormLayoutGroup>
      {isSysadmin && (
        <FormLayoutGroup>
          <GetOrganizationsComponent fetchPolicy="cache-and-network">
            {({ data, loading }) => (
              <FormControl fullWidth>
                <AutocompleteInput
                  label="หน่วยงาน"
                  name="organization_id"
                  options={data?.rows || []}
                  loading={loading}
                  disableClearable
                  size="small"
                  primaryKey="id"
                  getOptionLabel={(option: any) => `${option.name}`}
                  rules={{ required: 'กรุณาระบุหน่วยงาน' }}
                  required
                  disabled={disabled}
                />
              </FormControl>
            )}
          </GetOrganizationsComponent>
        </FormLayoutGroup>
      )}
      <FormLayoutGroup>
        <FormControl fullWidth error={!!form.errors['note']}>
          <TextField
            label="หมายเหตุ"
            name="note"
            variant="outlined"
            rows={3}
            size="small"
            fullWidth
            multiline
            disabled={disabled}
          />
          <ErrorMessage name="note" />
        </FormControl>
      </FormLayoutGroup>
      {mode === 'update' && (
        <Fragment>
          <FormLayoutGroup>
            <MuiTextField
              fullWidth
              variant="standard"
              disabled
              label="แก้ไขล่าสุดเมื่อวันที่"
              size="small"
              InputProps={{ readOnly: true }}
              value={DateUtils.toThaiDatetime(data?.updated_at)}
            />
            <MuiTextField
              fullWidth
              variant="standard"
              disabled
              label="สร้างเมื่อวันที่"
              size="small"
              InputProps={{ readOnly: true }}
              value={DateUtils.toThaiDatetime(data?.created_at)}
            />
          </FormLayoutGroup>
          <FormLayoutGroup>
            <MuiTextField
              fullWidth
              variant="standard"
              disabled
              label="แก้ไขโดย"
              size="small"
              InputProps={{ readOnly: true }}
              value={data?.created_by_user?.display_name}
            />
            <MuiTextField
              fullWidth
              variant="standard"
              disabled
              label="สร้างโดย"
              size="small"
              InputProps={{ readOnly: true }}
              value={data?.created_by_user?.display_name}
            />
          </FormLayoutGroup>
        </Fragment>
      )}
    </FormLayout>
  );
};
