import { OptionsObject, useSnackbar } from 'notistack';
import { ReactNode, useCallback } from 'react';

export function useResponseBar() {
  const { enqueueSnackbar } = useSnackbar();

  const enqueueSuccess = useCallback(
    (message: ReactNode, options?: OptionsObject | undefined) => {
      return enqueueSnackbar(message, {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'left',
          vertical: 'bottom',
        },
      });
    },
    [enqueueSnackbar]
  );

  const enqueueFailure = useCallback(
    (message: ReactNode, options?: OptionsObject | undefined) => {
      return enqueueSnackbar(message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'left',
          vertical: 'bottom',
        },
      });
    },
    [enqueueSnackbar]
  );

  return {
    enqueueSuccess,
    enqueueFailure,
  };
}
