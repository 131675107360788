import {
  useGetOrganizationByPkQuery,
  useUpdateOrganizationByPkMutation,
  OrganizationFragment,
} from '@app/generated/graphql';
import Page from '@app/components/Page';
import DescriptionIcon from '@material-ui/icons/Description';
import { pageContent } from '@app/shared/hoc/page-content';
import { CircularProgress, FormControl, Typography } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from '@app/shared/components/PageTitle';
import BackButton from '@app/shared/components/buttons/BackButton';
import { FormLayout, FormLayoutGroup, InfoCard } from '@mystiny/ui';
import SaveButton from '@app/shared/components/buttons/SaveButton';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { FormProvider, useForm } from 'react-hook-form';
import Spacing from '@app/components/common/Spacing';
import { TextField } from '@aginix/mui-react-hook-form-input';
import UserListInOrganization from './components/UserListInOrganization';

const OrganizationDetailForm = ({ data }: { data: OrganizationFragment }) => {
  const form = useForm<OrganizationFragment>({
    defaultValues: data,
  });
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [updateFn] = useUpdateOrganizationByPkMutation({
    onCompleted: () => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
    },
    onError: (err: any) => {
      console.error(err);
      enqueueFailure('เกิดข้อผิดพลาด');
    },
  });

  const handleSubmit = form.handleSubmit((formData: OrganizationFragment) => {
    updateFn({
      variables: {
        id: data.id,
        _set: {
          area_code: formData.area_code,
          name: formData.name,
          name_en: formData.name_en,
          province: formData.province,
          reference: formData.reference,
          unit_code: formData.unit_code,
        },
      },
    });
  });

  return (
    <InfoCard title="ข้อมูลหน่วยงาน" actions={<SaveButton onClick={handleSubmit} />}>
      <FormProvider {...form}>
        <FormLayout>
          <FormLayoutGroup>
            <FormControl fullWidth>
              <TextField label="ชื่อหน่วยงาน (ไทย)" name="name" variant="outlined" size="small" />
            </FormControl>
            <FormControl fullWidth>
              <TextField label="ชื่อหน่วยงาน (อังกฤษ)" name="name_en" variant="outlined" size="small" />
            </FormControl>
          </FormLayoutGroup>
          <FormLayoutGroup>
            <FormControl fullWidth>
              <TextField label="รหัสพื้นที่" name="area_code" variant="outlined" size="small" />
            </FormControl>
            <FormControl fullWidth>
              <TextField label="รหัสอ้างอิง" name="reference" variant="outlined" size="small" />
            </FormControl>
          </FormLayoutGroup>
          <FormLayoutGroup>
            <FormControl fullWidth>
              <TextField label="หน่วยเบิกจ่าย" name="unit_code" variant="outlined" size="small" />
            </FormControl>
            <FormControl fullWidth>
              <TextField label="จังหวัด" name="province" variant="outlined" size="small" />
            </FormControl>
          </FormLayoutGroup>
          <FormLayoutGroup>
            <FormControl fullWidth>
              <TextField label="Note" name="note" variant="outlined" size="small" multiline rows={3} />
            </FormControl>
          </FormLayoutGroup>
        </FormLayout>
      </FormProvider>
    </InfoCard>
  );
};

const OrganizationDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetOrganizationByPkQuery({
    variables: {
      id: id,
    },
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return <CircularProgress />;
  }

  if (!data?.entity) {
    return null;
  }

  const organization = data.entity;

  return (
    <Page
      title={
        <PageTitle
          title={
            <React.Fragment>
              <Typography variant="h6" component="div">
                <DescriptionIcon color="primary" fontSize="small" /> หน่วยงาน
              </Typography>
              <Typography variant="h6" color="primary" component="div">
                {organization.name}
              </Typography>
              <Typography variant="subtitle1" color="primary" component="div" gutterBottom>
                {organization.reference}
              </Typography>
            </React.Fragment>
          }
          actions={
            <div>
              <BackButton to="/bof/organizations" />
            </div>
          }
        />
      }
    >
      <OrganizationDetailForm data={organization} />
      <Spacing />
      <InfoCard title="รายชื่อผู้ใช้ภายในหน่วยงาน">
        <UserListInOrganization organizationId={organization.id} />
      </InfoCard>
    </Page>
  );
};

export default pageContent(OrganizationDetailPage);
