import {
  useUpdateBudgetByPkMutation,
  useDeleteBudgetByPkMutation,
  ProjectBudgetFragment,
  useGetBudgetByPkQuery,
} from '@app/generated/graphql';
import Page from '@app/components/Page';
import DescriptionIcon from '@material-ui/icons/Description';
import { pageContent } from '@app/shared/hoc/page-content';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from '@app/shared/components/PageTitle';
import BackButton from '@app/shared/components/buttons/BackButton';
import { InfoCard } from '@mystiny/ui';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { FormProvider, useForm } from 'react-hook-form';
import Spacing from '@app/components/common/Spacing';
import SaveButton from '@app/shared/components/buttons/SaveButton';
import DeleteButton from '@app/shared/components/buttons/DeleteButton';
import { Form } from './components/Form';
import { SampleBudget } from './components/SampleBudget';
import { useHistory } from 'react-router-dom';

const BudgetDetailForm = ({ data }: { data: ProjectBudgetFragment }) => {
  const history = useHistory();
  const form = useForm<ProjectBudgetFragment>({
    defaultValues: data,
  });
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [updateFn, { loading: updating }] = useUpdateBudgetByPkMutation({
    onCompleted: () => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
    },
    onError: (err: any) => {
      console.error(err);
      enqueueFailure('เกิดข้อผิดพลาด');
    },
  });

  const [deleteFn, { loading: deleting }] = useDeleteBudgetByPkMutation({
    onCompleted: () => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
      history.push('/bof/projects/master-data/budgets');
    },
    onError: (err: any) => {
      console.error(err);
      enqueueFailure('เกิดข้อผิดพลาด');
    },
  });

  const handleSubmit = form.handleSubmit((formData: ProjectBudgetFragment) => {
    updateFn({
      variables: {
        id: data.id,
        _set: {
          name: formData.name,
          fiscal_year: formData.fiscal_year,
          price_per_unit: formData.price_per_unit,
          unit_id: formData.unit_id,
          unit_of_multiplier_id: formData.unit_of_multiplier_id,
        },
      },
    });
  });

  const handleDelete = useCallback(() => {
    if (
      window.confirm(
        'ท่านแน่ใจที่จะลบข้อมูลใช่หรือไม่?\n\nเป็นการลบข้อมูลออกจากระบบถาวร โดยที่จะไม่สามารถกู้คืนกลับมาได้'
      )
    ) {
      deleteFn({
        variables: {
          id: data.id,
        },
      });
    }
  }, [deleteFn, data.id]);

  const loading = updating || deleting;

  return (
    <Page
      title={
        <PageTitle
          title={
            <React.Fragment>
              <Typography variant="h6" component="div">
                <DescriptionIcon color="primary" fontSize="small" /> งบประมาณ (แตกตัวคูณ)
              </Typography>
              <Typography variant="h6" color="primary" component="div" gutterBottom>
                {data.name}
              </Typography>
            </React.Fragment>
          }
          actions={
            <div>
              <BackButton to="/bof/projects/master-data/budgets" />
            </div>
          }
        />
      }
    >
      <InfoCard
        title="ข้อมูลงบประมาณ (แตกตัวคูณ)"
        actions={
          <Grid container justify="space-between">
            <Grid item>
              <DeleteButton disabled={loading} onClick={handleDelete} />
            </Grid>
            <Grid item>
              <SaveButton disabled={loading} onClick={handleSubmit} />
            </Grid>
          </Grid>
        }
      >
        <FormProvider {...form}>
          <Form mode="update" />
        </FormProvider>
      </InfoCard>
      <Spacing />
      <InfoCard title="ทดสอบ รายละเอียดงบประมาณ (แตกตัวคูณ)">
        <SampleBudget data={data} />
      </InfoCard>
    </Page>
  );
};

const BudgetDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetBudgetByPkQuery({
    variables: {
      id: id,
    },
  });

  if (loading && !data?.row) {
    return <CircularProgress />;
  }

  if (!data?.row) {
    return null;
  }

  return (
    <React.Fragment>
      <BudgetDetailForm data={data?.row} />
    </React.Fragment>
  );
};

export default pageContent(BudgetDetailPage);
