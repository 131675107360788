import { GetReceivablesQuery } from '@app/generated/graphql';
import { DateUtils } from '@app/shared/libs/date';

export function mapper(data: GetReceivablesQuery['rows']) {
  return data.map((entry) => {
    return {
      สถานะ: entry.status.description,
      วันที่ทำรายการ: DateUtils.toThaiDatetime(entry.date),
      'เลขที่ขอเบิก GFMIS': entry.gfmis_id,
      เลขที่ใบยืม: entry.reference,
      งบประมาณ: entry.budget?.description,
      ปีงบประมาณ: entry.fiscal_year,
      ชื่อผู้ยืม: entry.borrower_name,
      วัตถุประสงค์: entry.proposal,
      ยอดเงินยืม: entry.amount,
      ยอดเงินคืน: entry.refund_amount,
      หน่วยงาน: entry.organization?.short_name,
      หมายเหตุ: entry.note,
      วันที่สร้างรายการ: DateUtils.toThaiDatetime(entry.created_at),
    };
  });
}
