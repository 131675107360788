import { withIfUser } from '@app/components/common/Routes';
import { hasPermissions } from '@app/utils';
import OrganizationDetail from '@app/views/Organization/Detail';
import { memo } from 'react';

export default memo(
  withIfUser(OrganizationDetail)({
    condition: ({ roles }) => roles.some(hasPermissions),
    redirect: true,
    redirectTo: '/unauthorized',
  })
);
