import {
  useInsertLedgerOneMutation,
  LedgerFragment,
  LedgerLedgerInsertInput,
  LedgerLedgerStatusEnum,
} from '@app/generated/graphql';
import DescriptionIcon from '@material-ui/icons/Description';
import { pageContent } from '@app/shared/hoc/page-content';
import React from 'react';
import PageTitle from '@app/shared/components/PageTitle';
import BackButton from '@app/shared/components/buttons/BackButton';
import { InfoCard } from '@mystiny/ui';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { FormProvider, useForm } from 'react-hook-form';
import { Divider, Typography } from '@material-ui/core';
import AddButton from '@app/shared/components/buttons/AddButton';
import Page from '@app/components/Page';
import { useIfUser } from '@app/components/common/Routes';
import { useHistory } from 'react-router-dom';
import { DateUtils } from '@app/shared/libs/date';
import { LedgerForm } from './LedgerForm';
import LedgerStepper from './components/LedgerStepper';
import { useTypography } from '@app/shared/styles/typography';
import Spacing from '@app/components/common/Spacing';
import { useCurrentUser } from '@app/hooks';

const LedgerCreate = () => {
  const typographyClasses = useTypography();
  const history = useHistory();
  const user = useCurrentUser();
  const form = useForm<LedgerFragment>({
    defaultValues: {
      date: new Date(),
      organization_id: user?.organizationId,
    },
  });

  const isSysadmin = useIfUser({ condition: ({ roles }) => roles.includes('sysadmin') });
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [createFn] = useInsertLedgerOneMutation({
    onCompleted: ({ insert_ledger_ledger_one }) => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
      history.push(`/bof/accounting/ledgers/${insert_ledger_ledger_one?.id}`);
    },
    onError: (err: any) => {
      console.error(err);
      enqueueFailure('เกิดข้อผิดพลาด' + err);
    },
  });

  const handleSubmit = form.handleSubmit((formData: LedgerFragment) => {
    if (!window.confirm('ยืนยันการสร้างข้อมูลค่าใช้จ่าย')) {
      return;
    }

    const fiscalYear = DateUtils.getThaiFiscalYear(formData.date);
    const accountId = (formData.account as any).account_id;
    const data: LedgerLedgerInsertInput = {
      date: formData.date,
      note: formData.note,
      gfmis_id: formData.gfmis_id,
      fiscal_year: fiscalYear,
      receivable_id: formData.receivable_id,
      account_id: accountId,
      budget_id: formData.budget!.id,
      amount: formData.amount,
    };

    if (isSysadmin) {
      data.organization_id = formData.organization_id;
    } else {
      data.organization_id = user?.organizationId;
    }

    createFn({
      variables: {
        object: data,
      },
    });
  });

  return (
    <Page
      title={
        <PageTitle
          title={
            <React.Fragment>
              <Typography variant="h5" color="primary" className={typographyClasses.pageTitle}>
                <DescriptionIcon color="primary" fontSize="small" /> เพิ่มข้อมูลค่าใช้จ่าย
              </Typography>
            </React.Fragment>
          }
          actions={
            <div>
              <BackButton to={`/bof/accounting/ledgers`} />
            </div>
          }
        />
      }
    >
      <InfoCard actions={<AddButton onClick={handleSubmit} />}>
        <LedgerStepper status={LedgerLedgerStatusEnum.Draft} />
        <Divider />
        <Spacing />
        <Typography variant="h6" color="textSecondary" gutterBottom>
          แบบฟอร์มข้อมูลค่าใช้จ่าย
        </Typography>
        <FormProvider {...form}>
          <LedgerForm mode="create" />
        </FormProvider>
      </InfoCard>
    </Page>
  );
};

export default pageContent(LedgerCreate);
