import { TextField } from '@aginix/mui-react-hook-form-input';
import * as uuid from 'uuid';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  useGetProjectOperatingActivitiesByProjectIdQuery,
  ProjectOperatingActivityWithProgressesFragment,
  ProjectOperatingActivityProgressInsertInput,
  GetProjectOperatingActivitiesByProjectIdQuery,
  useUpdateProjectOperatingActivityProgressesMutation,
  ProjectOperatingActivityProgressUpdateColumn,
} from '@app/generated/graphql';
import SaveButton from '@app/shared/components/buttons/SaveButton';
import { DateUtils, monthsShort } from '@app/shared/libs/date';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { InfoCard } from '@mystiny/ui';
import React, { Fragment, useMemo } from 'react';
import { Control, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import NumberFormat from 'react-number-format';
import { createNumberFormatInput } from '@app/components/NumberFormatInput';
import { useProjectEditable } from '@app/views/Project/contexts/ProjectContext';
import { red } from '@material-ui/core/colors';
import { ExportButton } from '@app/views/Project/components/export/ExportButton';

const NumberFormatInput = createNumberFormatInput({
  thousandSeparator: true,
  allowNegative: true,
  allowEmptyFormatting: false,
  style: { textAlign: 'right' },
});

const DisplayNumber = ({ value }: { value: number }) => (
  <NumberFormat value={value} displayType="text" thousandSeparator />
);

const useStyles = makeStyles((theme) => ({
  dense: {
    padding: theme.spacing(1),
  },
}));

const TotalOperating = ({
  activityIndex,
  activity,
  control,
  data,
  type = 'plan',
}: {
  activityIndex: number;
  activity: ProjectOperatingActivityWithProgressesFragment;
  control: Control;
  type?: 'plan' | 'actual';
  data: ProjectOperatingActivityWithProgressesFragment[];
}) => {
  const classes = useStyles();
  const activities = useWatch<ProjectOperatingActivityWithProgressesFragment[]>({
    control,
    name: 'activities',
  });
  const currentActivity = useMemo(() => (activities ? activities.find((a) => a.id === activity.id) : undefined), [
    activities,
    activity,
  ]);

  if (!currentActivity) {
    return (
      <Fragment>
        <TableCell component="th" className={clsx('text-right', classes.dense)}>
          0
        </TableCell>
        <TableCell component="th" className={clsx('text-right', classes.dense)}>
          0
        </TableCell>
      </Fragment>
    );
  }

  const target = currentActivity.progresses.reduce(
    (sum, progress) => sum + (Number(type === 'plan' ? progress.workload_plan : progress.workload) || 0),
    0
  );
  const budget = currentActivity.progresses.reduce(
    (sum, progress) => sum + (Number(type === 'plan' ? progress.spend_plan : progress.spend) || 0),
    0
  );

  const targetDiff = target - Number(currentActivity.target_amount);
  const budgetDiff = (currentActivity?.budget_items_aggregate?.aggregate?.sum?.total || 0) - budget;

  console.log({
    'activity?.budget_items_aggregate?.aggregate?.sum?.total ': activity?.budget_items_aggregate?.aggregate?.sum?.total,
    budget: budget,
    budgetDiff,
    'activity.progresses': currentActivity.progresses,
    activities,
  });

  return (
    <Fragment>
      <TableCell component="th" className={clsx('text-right', classes.dense)}>
        <div>
          <DisplayNumber value={target} />
        </div>
        <div>
          {targetDiff !== 0 ? (
            <span style={{ color: red[700] }}>
              (<DisplayNumber value={targetDiff} />)
            </span>
          ) : (
            ''
          )}
        </div>
      </TableCell>
      <TableCell component="th" className={clsx('text-right', classes.dense)}>
        <div>
          <DisplayNumber value={budget} />
        </div>
        <div>
          {budgetDiff !== 0 ? (
            <span style={{ color: red[700] }}>
              (<DisplayNumber value={budgetDiff} />)
            </span>
          ) : (
            ''
          )}
        </div>
      </TableCell>
    </Fragment>
  );
};

const ProjectOperatingPlanForm = ({ rows, project }: GetProjectOperatingActivitiesByProjectIdQuery) => {
  const classes = useStyles();
  const disabled = !useProjectEditable();
  const form = useForm<{ activities: ProjectOperatingActivityWithProgressesFragment[] }>({
    defaultValues: {
      activities: rows,
    },
    mode: 'onChange',
  });

  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [updateFn, { loading: updating }] = useUpdateProjectOperatingActivityProgressesMutation({
    onCompleted: () => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
    },
    onError: (err: any) => {
      console.error(err);
      enqueueFailure('เกิดข้อผิดพลาด');
    },
  });
  const handleSubmit = form.handleSubmit((formData) => {
    const cleanedData = formData.activities.reduce<ProjectOperatingActivityProgressInsertInput[]>((prev, activity) => {
      const progresses = activity.progresses.map<ProjectOperatingActivityProgressInsertInput>((progress) => ({
        id: progress.id,
        month: progress.month,
        spend_plan: Number(progress.spend_plan) || null,
        workload_plan: Number(progress.workload_plan) || null,
        revision: Number(progress.revision) || 0,
        project_operating_activity_id: activity.id,
      }));

      return [...prev, ...progresses];
    }, []);
    updateFn({
      variables: {
        objects: cleanedData,
        update_columns: [
          ProjectOperatingActivityProgressUpdateColumn.Revision,
          ProjectOperatingActivityProgressUpdateColumn.SpendPlan,
          ProjectOperatingActivityProgressUpdateColumn.WorkloadPlan,
          ProjectOperatingActivityProgressUpdateColumn.Revision,
          ProjectOperatingActivityProgressUpdateColumn.ProjectOperatingActivityId,
        ],
      },
    });
  });

  const months = useMemo(() => (project ? DateUtils.getAllMonthBetween(project?.start_at, project?.end_at) : []), [
    project,
  ]);

  const dataForExport = rows.map((activity, activityIndex) => ({
    activity_name: activity.name,
    target_amount: activity.target_amount,
    target_unit: activity.target_unit,
    total_budget: activity.budget_items_aggregate?.aggregate?.sum?.total,
    months: months.reduce<any[]>(
      (prev, date) => [
        ...prev,
        {
          month: date.getMonth(),
          // [date.getMonth()]: {
          budget: activity.progresses?.find((progress) => progress.month === date.getMonth() + 1)?.spend_plan,
          workload: activity.progresses?.find((progress) => progress.month === date.getMonth() + 1)?.workload_plan,
          // },
        } as any,
      ],
      []
    ),
  }));

  const TableContent = (
    <Table className="bordered">
      <TableHead>
        <TableRow>
          <TableCell component="th" style={{ minWidth: 220 }} rowSpan={2}>
            กิจกรรม/ค่าใช้จ่าย/รายการ
          </TableCell>
          <TableCell component="th" style={{ minWidth: 120 }} rowSpan={2}>
            กลุ่มเป้าหมาย
          </TableCell>
          <TableCell component="th" style={{ minWidth: 120 }} rowSpan={2}>
            งบประมาณ
          </TableCell>
          <TableCell component="th" align="center" colSpan={2}>
            รวม
          </TableCell>
          {months.map((date) => (
            <TableCell key={`month-${date.getTime()}`} component="th" align="center" colSpan={2}>
              {monthsShort[date.getMonth()]}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell component="th" style={{ minWidth: 105 }} align="center">
            เป้าหมาย
          </TableCell>
          <TableCell component="th" style={{ minWidth: 105 }} align="center">
            งบประมาณ
          </TableCell>
          {months.map((date) => (
            <Fragment key={`plan-${date.getTime()}`}>
              <TableCell component="th" style={{ minWidth: 105 }} align="center">
                เป้าหมาย
              </TableCell>
              <TableCell component="th" style={{ minWidth: 105 }} align="center">
                งบประมาณ
              </TableCell>
            </Fragment>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((activity, activityIndex) => (
          <Fragment key={activity.id}>
            <TableRow role="checkbox" tabIndex={-1}>
              <TableCell>
                {activityIndex + 1}. {activity.name}
                <TextField type="hidden" defaultValue={activity.id} name={`activities[${activityIndex}].id`} />
                <TextField
                  type="hidden"
                  defaultValue={activity.target_amount}
                  name={`activities[${activityIndex}].target_amount`}
                />
              </TableCell>
              <TableCell align="center">
                <DisplayNumber value={activity.target_amount} />
                <br /> {activity.target_unit?.description || ''}
                {!activity.target_amount && !activity.target_unit ? '-' : ''}
              </TableCell>
              <TableCell align="center">
                <DisplayNumber value={activity.budget_items_aggregate?.aggregate?.sum?.total} />
              </TableCell>
              <TotalOperating control={form.control} activityIndex={activityIndex} activity={activity} data={rows} />
              {DateUtils.getAllMonthBetween(project!.start_at, project!.end_at).map((date, index) => {
                const progress = activity.progresses.find((progress) => progress.month === date.getMonth() + 1);
                return (
                  <Fragment key={`${activity.id}-${date.getTime()}`}>
                    <TableCell component="th" className={clsx('text-right', classes.dense)}>
                      <TextField
                        disabled={disabled}
                        className="text-right"
                        defaultValue={progress?.workload_plan}
                        name={`activities[${activityIndex}].progresses[${index}].workload_plan`}
                        InputProps={{
                          inputComponent: NumberFormatInput as any,
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" className={clsx('text-right', classes.dense)}>
                      <TextField
                        disabled={disabled}
                        className="text-right"
                        defaultValue={progress?.spend_plan}
                        name={`activities[${activityIndex}].progresses[${index}].spend_plan`}
                        InputProps={{
                          inputComponent: NumberFormatInput as any,
                        }}
                      />
                      <span style={{ display: 'none' }}>
                        <TextField
                          type="hidden"
                          defaultValue={progress?.id || uuid.v4()}
                          name={`activities[${activityIndex}].progresses[${index}].id`}
                        />
                        <TextField
                          type="hidden"
                          defaultValue={date.getMonth() + 1}
                          name={`activities[${activityIndex}].progresses[${index}].month`}
                        />
                      </span>
                    </TableCell>
                  </Fragment>
                );
              })}
            </TableRow>
          </Fragment>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <FormProvider {...form}>
      <InfoCard
        title={`แผนการปฏิบัติงานละแผนการใช้จ่ายเงินประจำปีงบประมาณ พ.ศ. ${project?.fiscal_year}`}
        subheader={
          <Fragment>
            <Typography color="error">
              * เป้าหมาย/งบประมาณ ข้อความสีแดงหมายถึง ส่วนต่างระหว่างกลุ่มเป้าหมาย/งบประมาณ และแผน <br />
              หากมีเครื่องหมายติดลบ (-) หมายถึง ส่วนต่างเกินกว่าเป้าที่ตั้งไว้
            </Typography>
            <Typography color="primary">* Export ข้อมูล จะนำออกเฉพาะข้อมูลที่บันทึกแล้วเท่านั้น</Typography>
          </Fragment>
        }
        CardProps={{ elevation: 0 }}
        actionTopRight={
          <div>
            <Box mr={1} component="span">
              <ExportButton data={dataForExport} name="แผนการปฏิบัติงานละแผนการใช้จ่ายเงินประจำปีงบประมาณ" />
            </Box>
            {!disabled && <SaveButton disabled={updating} onClick={handleSubmit} />}
          </div>
        }
      >
        <Box width="100%">
          <TableContainer>{TableContent}</TableContainer>
        </Box>
      </InfoCard>
    </FormProvider>
  );
};

export const ProjectOperatingPlan = ({ projectId }: { projectId: string }) => {
  const { data, loading } = useGetProjectOperatingActivitiesByProjectIdQuery({
    variables: {
      projectId,
    },
    fetchPolicy: 'no-cache',
  });

  if (!data?.rows && loading) {
    return <CircularProgress />;
  }

  if (!data?.rows) {
    return <CircularProgress />;
  }

  return <ProjectOperatingPlanForm {...data!} />;
};
