import AnonymousTopBar from '@app/components/AnonymousTopBar';
import Spacing from '@app/components/common/Spacing';
import { useCurrentUser } from '@app/hooks';
import { useIsAuthenticated } from '@app/hooks/useIsAuthenticated';
import api from '@app/libs/api';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { AppBar, Button, Container, CssBaseline, Typography } from '@material-ui/core';
import { InfoCard } from '@mystiny/ui';
import React from 'react';
import { Redirect } from 'react-router-dom';

const UnauthorizedPage = () => {
  const user = useCurrentUser();
  const auth = useIsAuthenticated();
  const { enqueueSuccess } = useResponseBar();

  const handleLogout = React.useCallback(async () => {
    await api.logout();
    // Hard redirect to first page
    window.location.href = '/';
    enqueueSuccess('ออกจากระบบสำเร็จ');
  }, [enqueueSuccess]);

  if (!auth) {
    return <Redirect to="/login" />;
  }

  return (
    <React.Fragment>
      <AppBar variant="elevation" elevation={1} style={{ backgroundColor: '#fff' }}>
        <AnonymousTopBar />
      </AppBar>
      <CssBaseline />
      <Spacing />
      <Spacing />
      <Spacing />
      <Spacing />
      <Container maxWidth="md">
        <InfoCard title="ไม่มีสิทธิ์การเข้าถึง">
          <Typography variant="body1" component="h6" gutterBottom>
            ชื่อผู้ใช้งาน: {user?.displayName} ({JSON.stringify(user)})
          </Typography>
          <Typography variant="h6" component="h6" gutterBottom>
            สำหรับท่านที่เป็นเจ้าหน้าที่
          </Typography>
          <Typography variant="body1" component="p" gutterBottom>
            โปรดติดต่อเจ้าหน้าที่ผู้ดูแลระบบ เพื่อปรับสิทธิ์ให้สามารถเข้าใช้งานระบบหลังบ้าน (Back Office) ได้
          </Typography>
          <Spacing />
          <Typography variant="body2" component="p" align="right">
            สำนักกองทุนพัฒนาเอสเอ็มอี ตามแนวประชารัฐ
          </Typography>
          <Button variant="outlined" color="primary" onClick={handleLogout}>
            ออกจากระบบ
          </Button>
        </InfoCard>
      </Container>
    </React.Fragment>
  );
};

export default UnauthorizedPage;
