import React from 'react';
import { LoanProgramFragment } from '@app/generated/graphql';
import { Column } from 'react-data-grid';
import { DateUtils } from '@app/shared/libs/date';
import TextFilter from '@app/components/data-grid/TextFilter';
import NumberFormat from 'react-number-format';

export const loanProgramColumns: Column<LoanProgramFragment>[] = [
  {
    key: 'reference',
    name: 'รหัสอ้างอิง',
    width: 100,
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'name',
    name: 'ชื่อโครงการ',
    minWidth: 200,
    resizable: true,
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'start_date',
    name: 'วันที่เริ่มโครงการ',
    width: 140,
    formatter: ({ row }) => <React.Fragment>{DateUtils.toThaiDate(row.start_date)}</React.Fragment>,
  },
  {
    key: 'end_date',
    name: 'วันที่สิ้นสุดโครงการ',
    width: 140,
    formatter: ({ row }) => <React.Fragment>{DateUtils.toThaiDate(row.end_date)}</React.Fragment>,
  },
  {
    key: 'budget_total',
    name: 'วงเงินทั้งหมดของโครงการ',
    minWidth: 140,
    resizable: true,
    formatter: ({ row }) => (
      <React.Fragment>
        {row.budget_total ? <NumberFormat displayType="text" value={row.budget_total} thousandSeparator /> : '-'}
      </React.Fragment>
    ),
  },
  {
    key: 'max_credit',
    name: 'วงเงินขอสินเชื่อสูงสุด',
    minWidth: 140,
    resizable: true,
    formatter: ({ row }) => (
      <React.Fragment>
        {row.max_credit ? <NumberFormat displayType="text" value={row.max_credit} thousandSeparator /> : '-'}
      </React.Fragment>
    ),
  },
  {
    key: 'status',
    name: 'สถานะโครงการ',
    width: 140,
  },
];
