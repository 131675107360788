import { ErrorMessage, TextField } from '@aginix/mui-react-hook-form-input';
import { FormProps } from '@app/global-props';
import { FormControl } from '@material-ui/core';
import { FormLayout, FormLayoutGroup } from '@mystiny/ui';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { GetNationalStrategiesComponent } from '@app/generated/graphql';
import AutocompleteInput from '@app/shared/components/AutocompleteInput';

export const Form = (_: FormProps) => {
  const form = useFormContext();
  return (
    <FormLayout>
      <FormLayoutGroup>
        <FormControl fullWidth error={!!form.errors['id']}>
          <TextField
            label="ID"
            name="id"
            variant="outlined"
            size="small"
            rules={{ required: 'กรุณาระบุ ID' }}
            fullWidth
            required
          />
          <ErrorMessage name="id" />
        </FormControl>
      </FormLayoutGroup>
      <FormLayoutGroup>
        <FormControl fullWidth error={!!form.errors['name']}>
          <TextField
            label="ชื่อ"
            name="name"
            variant="outlined"
            size="small"
            rules={{ required: 'กรุณาระบุชื่อ' }}
            fullWidth
            required
          />
          <ErrorMessage name="name" />
        </FormControl>
      </FormLayoutGroup>
      <FormLayoutGroup>
        <FormControl fullWidth error={!!form.errors['depth']}>
          <TextField
            label="ความลึก"
            name="depth"
            variant="outlined"
            size="small"
            rules={{ required: 'กรุณาระบุความลึก' }}
            fullWidth
            required
          />
          <ErrorMessage name="depth" />
        </FormControl>
      </FormLayoutGroup>
      <FormLayoutGroup>
        <GetNationalStrategiesComponent fetchPolicy="cache-first">
          {({ data, loading }) => (
            <FormControl fullWidth>
              <AutocompleteInput
                label="ชื่อภายใต้"
                name="parent_id"
                options={data?.national_strategies || []}
                loading={loading}
                disableClearable
                size="small"
                primaryKey="id"
                getOptionLabel={(option: any) => `${option.id} ${option.name}`}
                required
              />
              <ErrorMessage name="parent_id" />
            </FormControl>
          )}
        </GetNationalStrategiesComponent>
      </FormLayoutGroup>
    </FormLayout>
  );
};
