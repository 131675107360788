import React, { memo } from 'react';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { GmailTabs, GmailTabItem } from '@mui-treasury/components/tabs/gmail';
import { LedgerLedgerTypeEnum } from '@app/generated/graphql';
import { blue, orange, green, red, brown, lightBlue } from '@material-ui/core/colors';

const indicatorColors = [blue[500], orange.A400, orange.A700, green.A400, red.A400, lightBlue.A400, brown.A400];

const LedgerTypeFilter = ({ index, setIndex }: any) => {
  return (
    <GmailTabs value={index} onChange={(_, value) => setIndex(value)} indicatorColors={indicatorColors}>
      <GmailTabItem icon={<AccountBalanceIcon />} label="ค่าใช้จ่าย (EX1)" value={LedgerLedgerTypeEnum.Expense} />
      <GmailTabItem
        icon={<CreditCardIcon />}
        label="ค่าใช้จ่ายที่เกิดขึ้นจริง (EX2)"
        value={LedgerLedgerTypeEnum.Debt}
      />
    </GmailTabs>
  );
};

export default memo(LedgerTypeFilter, (prev, next) => prev.index === next.index);
