import React, { useState } from 'react';
import { Filters } from 'react-data-grid';
import { groupBy as rowGrouper } from 'lodash';
import { GetBudgetTypesComponent } from '@app/generated/graphql';
import { budgetTypeColumns } from './columns';
import DataGridContainer from '@app/shared/components/DataGridContainer';
import { listPageContent } from '@app/shared/hoc/list-page';
import DataGridFetchMore from '@app/shared/components/DataGridFetchMore';
import { useRdgSort } from '@app/shared/hooks/useRdgSort';
import PageTitle from '@app/shared/components/PageTitle';
import LinkButton from '@app/shared/components/buttons/LinkButton';
import AddIcon from '@material-ui/icons/Add';
import Spacing from '@app/components/common/Spacing';
import { useDebounce } from 'use-debounce';
import { Typography } from '@material-ui/core';
import { useTypography } from '@app/shared/styles/typography';
import { CopyButton } from '@app/views/BudgetType/components/CopyButton';

const groupBy: string[] = ['fiscal_year'];

const BudgetTypeList = () => {
  const typographyClasses = useTypography();
  const [expandedGroupIds, setExpandedGroupIds] = useState<any>(new Set(['2564', '2563']));
  const [offset, setOffset] = useState(0);
  const { handleSort, orderBy, sort } = useRdgSort({
    column: 'sequence',
    direction: 'ASC',
  });
  const [filters, setFilters] = useState<Filters>({
    sequence: '',
    id: '',
    description: '',
  });
  const [value] = useDebounce(filters, 350);

  return (
    <React.Fragment>
      <PageTitle
        title={
          <Typography variant="h5" color="primary" className={typographyClasses.pageTitle}>
            งบประมาณ
          </Typography>
        }
        actions={
          <div>
            <CopyButton />
            <LinkButton startIcon={<AddIcon />} to="/bof/accounting/budget-types/create">
              เพิ่มข้อมูลงบประมาณ
            </LinkButton>
          </div>
        }
      />
      <Spacing />
      <GetBudgetTypesComponent
        variables={{
          where: {
            id: { _ilike: value.id ? `%${value.id}%` : undefined },
            description: { _ilike: value.description ? `%${value.description}%` : undefined },
          },
          orderBy: orderBy,
        }}
        fetchPolicy="cache-and-network"
      >
        {({ data, fetchMore, loading, variables }) => (
          <DataGridContainer height="80vh">
            <DataGridFetchMore
              fetchMore={fetchMore}
              variables={variables}
              offset={offset}
              setOffset={setOffset}
              sortColumn={sort?.column}
              sortDirection={sort?.direction}
              onSort={handleSort}
              rowKey={'_id' as any}
              defaultColumnOptions={{ sortable: true }}
              rows={data?.rows || []}
              columns={budgetTypeColumns}
              groupBy={groupBy}
              rowGrouper={rowGrouper}
              expandedGroupIds={expandedGroupIds}
              onExpandedGroupIdsChange={setExpandedGroupIds}
              filters={filters}
              onFiltersChange={setFilters}
              enableFilters
            />
          </DataGridContainer>
        )}
      </GetBudgetTypesComponent>
    </React.Fragment>
  );
};

export default listPageContent(BudgetTypeList);
