import React, { useEffect } from 'react';
import set from 'lodash.set';
import { SortDirection } from 'react-data-grid';
import { OrderBy } from '@app/generated/graphql';

function getOrderBy(direction: SortDirection) {
  switch (direction) {
    case 'ASC':
      return OrderBy.AscNullsLast;
    case 'DESC':
      return OrderBy.DescNullsLast;
  }
}

export type RdgSortHookResponse = [
  { column: string; direction: SortDirection },
  (props: { column: string; direction: SortDirection }) => void,
  { orderBy: any }
];

export type RdgSort = { column: string; direction: SortDirection };

export function useRdgSort(defaultSort?: RdgSort) {
  const [orderBy, setOrderBy] = React.useState<any>();
  const [sort, _setSort] = React.useState<RdgSort | undefined>(defaultSort);

  const handleSort = React.useCallback((column: string, direction: SortDirection) => {
    return _setSort({ column, direction });
  }, []);

  useEffect(() => {
    if (!sort || sort?.direction === 'NONE') {
      setOrderBy(undefined);
    } else {
      const orderBy = set<any>({}, sort?.column, getOrderBy(sort.direction));
      setOrderBy([orderBy]);
    }
  }, [sort]);

  return { sort, handleSort, orderBy };
}
