import type { FC, ElementType, ReactNode } from 'react';
import { format } from 'date-fns';
import { Badge, Box, IconButton, Typography, Popover, List, ListItem, ListSubheader } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { usePopover } from '@app/hooks/usePopover';
import BellIcon from '@material-ui/icons/Notifications';

interface NotificationsPopoverProps {
  // sx?: SxProps;
  notifications: Notification[];
}

export interface Notification {
  id: string;
  content: ReactNode;
  createdAt: number;
  icon: ElementType;
  iconColor: string;
  title: string;
  link?: string;
}

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 10,
    top: 12,
    height: 8,
    width: 8,
    // border: `2px solid ${theme.palette.background.paper}`,
    borderRadius: '50%',
    padding: '0 4px',
  },
}))(Badge);

export const NotificationsPopover: FC<NotificationsPopoverProps> = ({ notifications, ...props }) => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover();

  return (
    <>
      <StyledBadge color="primary" variant="dot" overlap="circular" {...props}>
        <IconButton
          onClick={handleOpen}
          ref={anchorRef}
          // sx={{
          //   color: 'primary.contrastText',
          // }}
          style={{
            color: '#000',
          }}
        >
          <BellIcon />
        </IconButton>
      </StyledBadge>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          style: {
            width: 480,
          },
          // sx: { width: 320 },
        }}
      >
        <List>
          <ListSubheader>Notifications</ListSubheader>
          {notifications.map((notification, index) => {
            const { title, content, createdAt, icon: Icon, iconColor, link } = notification;

            let button = {};
            if (link) {
              button = {
                button: true,
                component: 'a',
                href: link,
              };
            }

            return (
              <ListItem
                {...button}
                disableGutters
                divider={notifications.length > index + 1}
                key={notification.id}
                // sx={{
                //   display: 'flex',
                //   alignItems: 'flex-start',
                //   flexDirection: 'column',
                //   p: 2,
                // }}
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  padding: `8px 16px`,
                }}
              >
                <Box
                  display="flex"
                  // sx={{ display: 'flex' }}
                >
                  <Icon
                    fontSize="small"
                    style={{
                      color: iconColor,
                    }}
                    // sx={{ color: iconColor }}
                  />
                  <Typography
                    color="textPrimary"
                    style={{ marginLeft: 8 * 1.25 }}
                    // sx={{ ml: 1.25 }}
                    variant="body1"
                  >
                    {title}
                  </Typography>
                </Box>
                <Typography color="textSecondary" variant="body2">
                  {content}
                </Typography>
                <Typography color="textSecondary" variant="caption">
                  {format(createdAt, 'MMM dd, yyyy')}
                </Typography>
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </>
  );
};
