import React, { memo, FC } from 'react';
import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { Link, LinkProps } from 'react-router-dom';

const LinkButton: FC<Omit<LinkProps, 'color'> & ButtonProps> = ({ ...props }) => (
  <Button variant="contained" color="primary" style={{ marginLeft: 8 }} component={Link} {...props} />
);

export default memo(LinkButton);
