import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { useLocation } from 'react-router-dom';
import { Box, Container, Typography, Link, CircularProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';

export default function FirebaseSignInWithCustomToken() {
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!token) {
      setLoading(false);
      return;
    }

    const signIn = async () => {
      return signInWithCustomToken(getAuth(), token);
    };

    setLoading(true);
    signIn()
      .catch((err) => {
        setLoading(false);
        setError(err);
      })
      .finally(() => setLoading(false));
  }, [token]);

  if (loading) {
    return (
      <Container
        style={{
          height: '100vh',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box textAlign="center">
          <Typography
            component="div"
            variant="body1"
            style={{
              fontWeight: 600,
            }}
          >
            ระบบกำลังประมวลผลข้อมูล...
          </Typography>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (!token) {
    return (
      <Container
        style={{
          height: '100vh',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box textAlign="center">
          <Typography
            component="div"
            variant="body1"
            style={{
              fontWeight: 600,
            }}
          >
            เกิดข้อผิดพลาด ไม่สามารถยืนยันตัวตนได้ กรุณาลองใหม่อีกครั้ง
          </Typography>
          <Link href="https://authen.industry.go.th/">กลับไปยังระบบการยืนยันตัวตนผ่าน Industry SSO</Link>
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container
        style={{
          height: '100vh',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box textAlign="center">
          <Typography
            component="div"
            variant="body1"
            style={{
              fontWeight: 600,
            }}
          >
            เกิดข้อผิดพลาด ไม่สามารถยืนยันตัวตนได้ กรุณาลองใหม่อีกครั้ง
          </Typography>
          <Link href="https://authen.industry.go.th/">กลับไปยังระบบการยืนยันตัวตนผ่าน Industry SSO</Link>
        </Box>
      </Container>
    );
  }

  return <RedirectToHome />;
}

const RedirectToHome = () => {
  useEffect(() => {
    // replace location to prevent user from going back to this page
    window.location.replace('/');
  }, []);

  return null;
};
