import Button, { ButtonProps } from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';

const AddButton = ({ children, ...props }: Omit<ButtonProps, 'startIcon'>) => (
  <Button variant="contained" color="primary" startIcon={<AddIcon />} {...props}>
    {children ? children : 'เพิ่มข้อมูล'}
  </Button>
);

export default AddButton;
