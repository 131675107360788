import { monthsShort } from '@app/shared/libs/date';

export function mapper(data: any) {
  return data.map((entry: any) => {
    return {
      ชื่อกิจกรรม: entry.activity_name,
      เป้าหมายที่ตั้ง: entry.target_amount,
      หน่วยเป้าหมายที่ตั้ง: entry.target_unit?.description,
      งบประมาณที่ตั้ง: entry.total_budget,
      ...entry.months.reduce(
        (prev: any, data: any) => ({
          ...prev,
          [`${monthsShort[data.month]}_เป้าหมาย`]: data.workload || '-',
          [`${monthsShort[data.month]}_งบประมาณ`]: data.budget || '-',
        }),
        {}
      ),
    };
  });
}
