import { ErrorMessage, TextField } from '@aginix/mui-react-hook-form-input';
import { FormLayout, FormLayoutGroup } from '@mystiny/ui';
import React from 'react';
import { createNumberFormatInput } from '@app/components/NumberFormatInput';
import { FormProps } from '@app/global-props';
import { GetAccountsComponent } from '@app/generated/graphql';
import { FormControl, FormHelperText } from '@material-ui/core';
import AutocompleteInput from '@app/shared/components/AutocompleteInput';
import { sortBy } from 'lodash';

const NumberFormatInput = createNumberFormatInput({
  allowNegative: true,
  thousandSeparator: false,
});

export const Form = ({ data, mode }: FormProps) => {
  return (
    <FormLayout>
      <FormLayoutGroup>
        <FormControl fullWidth>
          <TextField
            label="รหัสงบประมาณ"
            name="id"
            disabled={mode === 'update'}
            variant="outlined"
            required
            fullWidth
          />
          <FormHelperText error>*เมื่อสร้างข้อมูลแล้ว จะไม่สามารถแก้ไขข้อมูลได้</FormHelperText>
        </FormControl>
      </FormLayoutGroup>
      <FormLayoutGroup>
        <FormControl fullWidth>
          <TextField
            label="ลำดับ"
            name="sequence"
            variant="outlined"
            required
            InputProps={{
              inputComponent: NumberFormatInput as any,
            }}
            fullWidth
          />
        </FormControl>
      </FormLayoutGroup>
      <FormLayoutGroup>
        <TextField label="คำอธิบาย" name="description" variant="outlined" required fullWidth />
      </FormLayoutGroup>
      <FormLayoutGroup>
        <FormControl fullWidth>
          <TextField label="กลุ่มงบประมาณ" name="group_name" required variant="outlined" />
        </FormControl>
      </FormLayoutGroup>
      <FormLayoutGroup>
        <FormControl fullWidth>
          <TextField
            label="ปีงบประมาณ (พ.ศ.)"
            name="fiscal_year"
            disabled={mode === 'update'}
            required
            variant="outlined"
            InputProps={{
              inputComponent: NumberFormatInput as any,
            }}
          />
          <FormHelperText error>*เมื่อสร้างข้อมูลแล้ว จะไม่สามารถแก้ไขข้อมูลได้</FormHelperText>
        </FormControl>
      </FormLayoutGroup>
      <FormLayoutGroup>
        <GetAccountsComponent fetchPolicy="cache-first">
          {({ data, loading }) => (
            <FormControl fullWidth>
              <AutocompleteInput
                label="รหัสบัญชีและชื่อบัญชี"
                name="account_id"
                options={data?.rows ? sortBy(data?.rows, ['account_type.description', 'code']) : []}
                loading={loading}
                disableClearable
                size="medium"
                groupBy={(option) => option.account_type?.description || 'ไม่มีประเภทบัญชี'}
                primaryKey="id"
                required
                getOptionLabel={(option: any) => `${option.code} ${option.name} (${option.account_type?.description})`}
                rules={{ required: 'กรุณาระบุบัญชี' }}
              />
              <ErrorMessage name="account_id" />
            </FormControl>
          )}
        </GetAccountsComponent>
      </FormLayoutGroup>
    </FormLayout>
  );
};
