let firebaseConfig = {
  apiKey: 'AIzaSyBA8pRn61aOSg9qrpfkvTbOJ867WpU7qjs',
  authDomain: 'moi-sme-dev.firebaseapp.com',
  databaseURL: 'https://moi-sme-dev.firebaseio.com',
  projectId: 'moi-sme-dev',
  storageBucket: 'moi-sme-dev.appspot.com',
  messagingSenderId: '961141240333',
  appId: '1:961141240333:web:a84019175ee390cabadb44',
  measurementId: 'G-3JBJR87KCS',
};

if (process.env.REACT_APP_APP_ENV === 'production') {
  firebaseConfig = {
    apiKey: 'AIzaSyABvflgim5fcy00F8Pell_fTGioxvV_ck4',
    authDomain: 'moi-sme-prod.firebaseapp.com',
    databaseURL: 'https://moi-sme-prod.firebaseio.com',
    projectId: 'moi-sme-prod',
    storageBucket: 'moi-sme-prod.appspot.com',
    messagingSenderId: '189540397288',
    appId: '1:189540397288:web:0213860ed44efc5958195d',
    measurementId: 'G-LCXHN08MWN',
  };
}

export default firebaseConfig;
