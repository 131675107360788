import React from 'react';
import { Chip } from '@material-ui/core';
import ApproveIcon from '@material-ui/icons/Check';
import { ChipProps as MuiChipProps } from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { ReceivableFragment, LedgerReceivableStatusEnum } from '@app/generated/graphql';
import { useColors } from '@app/shared/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 0,
  },
}));

export const ReceivableStatus = ({
  status,
  ChipProps,
}: {
  status: ReceivableFragment['status'];
  ChipProps?: MuiChipProps;
}) => {
  const colors = useColors();
  const classes = useStyles();
  const classNameMapping = {
    [LedgerReceivableStatusEnum.Done]: colors.approved,
    [LedgerReceivableStatusEnum.Draft]: colors.new,
    [LedgerReceivableStatusEnum.Submitted]: colors.submitted,
  };

  const icon = {
    [LedgerReceivableStatusEnum.Done]: <ApproveIcon />,
    [LedgerReceivableStatusEnum.Draft]: undefined,
    [LedgerReceivableStatusEnum.Submitted]: undefined,
  };

  return (
    <Chip
      label={status.description}
      variant="default"
      component="div"
      color="primary"
      icon={icon[status.id as LedgerReceivableStatusEnum] as any}
      classes={{
        root: classes.root,
        colorPrimary: classNameMapping[status.id as LedgerReceivableStatusEnum] || colors.new,
      }}
      {...ChipProps}
    />
  );
};
