import { useGetUserByPkQuery } from '@app/generated/graphql';
import DescriptionIcon from '@material-ui/icons/Description';
import { pageContent } from '@app/shared/hoc/page-content';
import { CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from '@app/shared/components/PageTitle';
import Spacing from '@app/components/common/Spacing';
import UserSection from '@app/views/User/components/UserSection';
import UserAccountRolesSection from '@app/views/User/components/UserAccountRolesSection';
import UserPermissionsSection from '@app/views/User/components/UserPermissionsSection';
import Page from '@app/components/Page';
import BackButton from '@app/shared/components/buttons/BackButton';

const UserDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetUserByPkQuery({
    variables: {
      id: id,
    },
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return <CircularProgress />;
  }

  if (!data?.entity) {
    return null;
  }

  const user = data.entity;

  return (
    <Page
      title={
        <PageTitle
          title={
            <React.Fragment>
              <Typography variant="h6" component="div">
                <DescriptionIcon color="primary" fontSize="small" /> ผู้ใช้งาน (Account)
              </Typography>
              <Typography variant="h6" color="primary" component="div">
                {user.account?.email}
              </Typography>
              <Typography variant="subtitle1">{user.organization?.short_name || '-'}</Typography>
            </React.Fragment>
          }
          actions={
            <div>
              <BackButton to="/bof/users" />
            </div>
          }
        />
      }
    >
      <UserSection data={data?.entity} />
      <Spacing />
      <UserAccountRolesSection accountId={user.account?.id} />
      <Spacing />
      <UserPermissionsSection userId={user.id} />
    </Page>
  );
};

export default pageContent(UserDetailPage);
