import React, { useMemo, useState } from 'react';

import { groupBy as rowGrouper } from 'lodash';
import { GetBudgetsComponent } from '@app/generated/graphql';
import { budgetonColumns } from './columns';
import DataGridContainer from '@app/shared/components/DataGridContainer';
import LoadMoreRowsTag from '@app/shared/components/LoadMoreRowsTag';
import { listPageContent } from '@app/shared/hoc/list-page';
import DataGridFetchMore from '@app/shared/components/DataGridFetchMore';
import { useRdgSort } from '@app/shared/hooks/useRdgSort';
import { useDebounce } from 'use-debounce';
import { Box, Typography } from '@material-ui/core';
import LinkButton from '@app/shared/components/buttons/LinkButton';
import AddIcon from '@material-ui/icons/Add';
import PageTitle from '@app/shared/components/PageTitle';

const groupBy = ['fiscal_year'];

const ProjectBudgetList = () => {
  const [expandedGroupIds, setExpandedGroupIds] = useState<any>(new Set(['2563', '2564', '2565']));
  const [filters, setFilters] = useState<any>({
    name: '',
    unit_id: '',
  });
  const [value] = useDebounce(filters, 200);
  const [offset, setOffset] = React.useState(0);
  const { handleSort, orderBy, sort } = useRdgSort();
  const strategyList = useMemo(
    () => (
      <GetBudgetsComponent
        variables={{
          where: {
            name: {
              _ilike: value.name ? `%${value.name}%` : undefined,
            },
          },
          orderBy: orderBy,
        }}
        fetchPolicy="cache-and-network"
      >
        {({ data, fetchMore, loading, variables }) => (
          <DataGridContainer height="80vh">
            <DataGridFetchMore
              fetchMore={fetchMore}
              variables={variables}
              offset={offset}
              setOffset={setOffset}
              sortColumn={sort?.column}
              sortDirection={sort?.direction}
              onSort={handleSort}
              defaultColumnOptions={{ sortable: true, resizable: true }}
              rows={data?.rows || []}
              columns={budgetonColumns}
              filters={filters}
              onFiltersChange={setFilters}
              groupBy={groupBy}
              rowGrouper={rowGrouper}
              expandedGroupIds={expandedGroupIds}
              onExpandedGroupIdsChange={setExpandedGroupIds}
              enableFilters
            />
            <LoadMoreRowsTag loading={loading} />
          </DataGridContainer>
        )}
      </GetBudgetsComponent>
    ),
    [filters, setFilters, value, offset, setOffset, handleSort, orderBy, sort, expandedGroupIds]
  );

  return (
    <React.Fragment>
      <Box padding={1}>
        <PageTitle
          title={<Typography variant="h6">งบประมาณ</Typography>}
          actions={
            <LinkButton startIcon={<AddIcon />} to="/bof/projects/master-data/budgets/create">
              เพิ่มงบประมาณ
            </LinkButton>
          }
        />
      </Box>
      {strategyList}
    </React.Fragment>
  );
};

export default listPageContent(ProjectBudgetList);
