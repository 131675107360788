import { Checkbox, Select } from '@aginix/mui-react-hook-form-input';
import {
  useGetAccountRolesByAccountIdQuery,
  GetAccountRolesByAccountIdQuery,
  useReplaceAccountRolesMutation,
} from '@app/generated/graphql';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Checkbox as MuiCheckbox,
  Button,
  MenuItem,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { FormLayout, FormLayoutGroup, InfoCard } from '@mystiny/ui';
import React, { Fragment } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { bannerCheckboxStylesHook } from '@mui-treasury/styles/checkbox/banner';

const roles: { [key: string]: any } = {
  user: { description: '' },
  employee: { description: 'เจ้าหน้าที่ สามารถเข้าถึงข้อมูลได้เฉพาะภายในหน่วยงานเท่านั้น' },
  sysadmin: { description: 'ผู้ดูแลระบบ สามารถเข้าถึงข้อมูลได้ทั้งหมด' },
};

const UserPermissionsForm = ({
  accountId,
  accountRoles,
  defaultRole = 'user',
}: {
  accountId: string;
  defaultRole: string;
  accountRoles: GetAccountRolesByAccountIdQuery['rows'];
}) => {
  const checkboxStyles = bannerCheckboxStylesHook.useCheckbox();
  const formControlLabelStyles = bannerCheckboxStylesHook.useFormControlLabel();
  const { enqueueFailure, enqueueSuccess } = useResponseBar();
  const defaultRoles = Object.keys(roles).reduce(
    (prev, role) => ({ ...prev, [role]: accountRoles.some((accountRole) => accountRole.role === role) }),
    {}
  );
  const form = useForm<{ default_role: string; [key: string]: boolean | string }>({
    defaultValues: {
      ...defaultRoles,
      default_role: defaultRole,
    },
  });

  const value = form.watch();

  const [mutateFn] = useReplaceAccountRolesMutation({
    onCompleted: () => {
      enqueueSuccess('บันทึกข้อมูลสิทธิ์ผู้ใช้สำเร็จ');
    },
    onError: () => {
      enqueueFailure('เกิดข้อผิดพลาดไม่สามารถบันทึกข้อมูลได้');
    },
    context: {
      headers: {
        'x-hasura-default-role': 'sysadmin',
      },
    },
  });

  const handleSubmit = form.handleSubmit((data) => {
    const selectedRoles = Object.keys(data)
      .filter((role) => data[role] === true)
      .map((role) => role);

    mutateFn({
      variables: {
        account_id: accountId,
        default_role: data.default_role,
        selected_roles: selectedRoles.concat('user'),
        insert_roles: selectedRoles.map((role) => ({
          account_id: accountId,
          role: role,
        })),
      },
    });
  });

  return (
    <FormProvider {...form}>
      <form id="account-roles-form" onSubmit={handleSubmit}>
        <FormLayout>
          <FormLayoutGroup>
            <FormControl component="fieldset" required>
              <FormGroup>
                {Object.keys(roles).map((role) => (
                  <React.Fragment key={role}>
                    {role === 'user' ? (
                      <FormControlLabel
                        classes={formControlLabelStyles}
                        disabled
                        control={<MuiCheckbox name={role} checked={true} readOnly classes={checkboxStyles} />}
                        label={role.toUpperCase()}
                      />
                    ) : (
                      <FormControlLabel
                        control={<Checkbox name={role} classes={checkboxStyles} />}
                        classes={formControlLabelStyles}
                        label={
                          <Fragment>
                            {role.toUpperCase()}
                            <Typography color="textSecondary">{roles[role].description}</Typography>
                          </Fragment>
                        }
                      />
                    )}
                  </React.Fragment>
                ))}
              </FormGroup>
              <FormHelperText>สามารถเลือกได้หลายสิทธิ์</FormHelperText>
            </FormControl>
            <FormControl margin="dense" variant="outlined" fullWidth>
              <InputLabel id="default_role_label" required>
                Default Role
              </InputLabel>
              <Select
                label="Default Role"
                labelId="default_role_label"
                variant="outlined"
                id="default_role"
                name="default_role"
                rules={{ required: 'Please enter age' }}
              >
                {!value['user'] ? <MenuItem value="user">USER</MenuItem> : null}
                {Object.keys(value)
                  .filter((key) => value[key] === true)
                  .map((key) => (
                    <MenuItem key={key} value={key}>
                      {key.toUpperCase()}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </FormLayoutGroup>
        </FormLayout>
      </form>
    </FormProvider>
  );
};

const UserAccountRolesSection = ({ accountId }: { accountId: string }) => {
  const { data, loading } = useGetAccountRolesByAccountIdQuery({
    fetchPolicy: 'no-cache',
    variables: {
      account_id: accountId,
    },
    context: {
      headers: {
        'x-hasura-default-role': 'sysadmin',
      },
    },
  });

  if (!data?.rows && loading) {
    return null;
  }

  return (
    <InfoCard
      title="ระดับผู้ใช้ภายในระบบ"
      actionTopRight={
        <Button variant="contained" color="primary" size="small" type="submit" form="account-roles-form">
          บันทึก
        </Button>
      }
    >
      {data?.rows ? (
        <UserPermissionsForm
          accountId={accountId}
          accountRoles={data.rows || []}
          defaultRole={data?.account?.default_role!}
        />
      ) : null}
    </InfoCard>
  );
};

export default UserAccountRolesSection;
