import { ObservableQueryFields, OperationVariables } from '@apollo/client';
import { LinearProgress } from '@material-ui/core';
import React, { Fragment, useMemo, useState } from 'react';
import DataGrid, { DataGridProps } from 'react-data-grid';
import { isAtBottom } from '../libs/events';
import NoRows from '@app/components/common/NoRows';
import equal from 'deep-equal';

export interface DataGridFetchMoreProps<R, K extends keyof R, SR = unknown> extends DataGridProps<R, K, SR> {
  loading?: boolean;
  offset: number;
  setOffset: (offset: number) => void;
  variables?: OperationVariables;
  fetchMore: ObservableQueryFields<any, OperationVariables>['fetchMore'];
  offsetStep?: number;
}

const DataGridFetchMore = <R, K extends keyof R, SR = unknown>({
  loading,
  variables,
  offset,
  setOffset,
  fetchMore,
  offsetStep = 50,
  rows,
  ...dataGridProps
}: DataGridFetchMoreProps<R, K, SR>) => {
  const [cannotFetchAnymore, setCannotFetchAnymore] = useState(false);
  const [localVariables, setLocalVariables] = useState<OperationVariables | undefined>(variables);

  const render = useMemo(
    () => (
      <DataGrid
        onScroll={async (event) => {
          if (!isAtBottom(event)) return;
          if (loading) return;
          if (equal(localVariables, variables) && !isAtBottom(event)) return;

          if (!equal(localVariables, variables) && cannotFetchAnymore) {
            setCannotFetchAnymore(false);
          }

          if (cannotFetchAnymore) return;

          const { data } = await fetchMore({
            variables: {
              ...variables,
              offset: offset + offsetStep,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              return {
                ...previousResult,
                rows: [...previousResult.rows, ...(fetchMoreResult?.rows || [])],
              };
            },
          });

          if (data?.rows?.length === 0) {
            setCannotFetchAnymore(true);
          }

          setLocalVariables(variables);
          setOffset(offset + offsetStep);
        }}
        className="fill-grid rdg-light"
        emptyRowsRenderer={() => (loading ? <LinearProgress /> : <NoRows />)}
        {...dataGridProps}
        rows={rows}
      />
    ),
    [
      loading,
      variables,
      offset,
      localVariables,
      setOffset,
      fetchMore,
      offsetStep,
      rows,
      dataGridProps,
      cannotFetchAnymore,
      setCannotFetchAnymore,
    ]
  );
  return <Fragment>{render}</Fragment>;
};

export default DataGridFetchMore;
