import { useGetUserByPkQuery, UserFragment } from '@app/generated/graphql';
import Page from '@app/components/Page';
import DescriptionIcon from '@material-ui/icons/Description';
import { pageContent } from '@app/shared/hoc/page-content';
import { CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from '@app/shared/components/PageTitle';
import BackButton from '@app/shared/components/buttons/BackButton';
import { DataField, FormLayout, FormLayoutGroup, InfoCard } from '@mystiny/ui';
import Spacing from '@app/components/common/Spacing';

const UserCreateForm = ({ data }: { data: UserFragment }) => {
  return (
    <Page
      title={
        <PageTitle
          title={
            <React.Fragment>
              <Typography variant="h6" component="div">
                <DescriptionIcon color="primary" fontSize="small" /> ผู้ใช้งาน (Account)
              </Typography>
              <Typography variant="h6" color="primary" component="div">
                {data.account?.email}
              </Typography>
              <Typography variant="subtitle1">{data.organization?.short_name || '-'}</Typography>
            </React.Fragment>
          }
          actions={
            <div>
              <BackButton to="/bof/users" />
            </div>
          }
        />
      }
    >
      <InfoCard title="ข้อมูลผู้ใช้งาน (Account)">
        <FormLayout>
          <FormLayoutGroup>
            <DataField label="E-mail" children={<Typography>{data.account?.email || '-'}</Typography>} />
            <DataField label="ชื่อผู้ใช้งาน" children={<Typography>{data.display_name}</Typography>} />
          </FormLayoutGroup>
          <FormLayoutGroup>
            <DataField label="หน่วยงาน" children={<Typography>{data.organization?.name || '-'}</Typography>} />
            <DataField label="สิทธิ์" children={<Typography>{data.account?.default_role || '-'}</Typography>} />
          </FormLayoutGroup>
        </FormLayout>
      </InfoCard>
      <Spacing />
    </Page>
  );
};

const UserCreatePage = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetUserByPkQuery({
    variables: {
      id: id,
    },
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return <CircularProgress />;
  }

  if (!data?.entity) {
    return null;
  }

  return (
    <React.Fragment>
      <UserCreateForm data={data?.entity} />
    </React.Fragment>
  );
};

export default pageContent(UserCreatePage);
