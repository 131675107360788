import React, { useState } from 'react';
import { Filters, Row } from 'react-data-grid';
import { groupBy as rowGrouper } from 'lodash';
import { GetAccountsFullComponent } from '@app/generated/graphql';
import { accountColumns } from './columns';
import DataGridContainer from '@app/shared/components/DataGridContainer';
import { useHistory } from 'react-router-dom';
import LoadMoreRowsTag from '@app/shared/components/LoadMoreRowsTag';
import { listPageContent } from '@app/shared/hoc/list-page';
import DataGridFetchMore from '@app/shared/components/DataGridFetchMore';
import { useRdgSort } from '@app/shared/hooks/useRdgSort';
import PageTitle from '@app/shared/components/PageTitle';
import Spacing from '@app/components/common/Spacing';
import LinkButton from '@app/shared/components/buttons/LinkButton';
import AddIcon from '@material-ui/icons/Add';
import { useDebounce } from 'use-debounce';

const groupBy = ['account_type.description'];

const AccountList = () => {
  const history = useHistory();
  const [expandedGroupIds, setExpandedGroupIds] = useState<any>(new Set(['ทุน', 'หนี้สิน', 'รายจ่าย', 'รายได้', 'สินทรัพย์']));
  const [offset, setOffset] = React.useState(0);
  const { handleSort, orderBy, sort } = useRdgSort({
    column: 'code',
    direction: 'ASC',
  });
  const [filters, setFilters] = useState<Filters>({
    code: '',
    name: '',
  });

  const [value] = useDebounce(filters, 200);

  return (
    <React.Fragment>
      <PageTitle
        actions={
          <LinkButton startIcon={<AddIcon />} to="/bof/accounting/accounts/create">
            เพิ่มผังบัญชี
          </LinkButton>
        }
      />
      <Spacing />
      <GetAccountsFullComponent
        variables={{
          where: {
            code: {
              _ilike: value.code ? `%${value.code}%` : undefined,
            },
            name: {
              _ilike: value.name ? `%${value.name}%` : undefined,
            },
          },
          offset: 0,
          orderBy: orderBy,
        }}
        fetchPolicy="cache-and-network"
      >
        {({ data, fetchMore, loading, variables }) => (
          <DataGridContainer height="80vh">
            <DataGridFetchMore
              fetchMore={fetchMore}
              variables={variables}
              offset={offset}
              setOffset={setOffset}
              sortColumn={sort?.column}
              sortDirection={sort?.direction}
              onSort={handleSort}
              rowRenderer={(props) => (
                <Row {...props} onDoubleClick={() => history.push(`/bof/accounting/accounts/${props.row.id}`)} />
              )}
              defaultColumnOptions={{ sortable: true }}
              rows={data?.rows || []}
              columns={accountColumns}
              groupBy={groupBy}
              rowGrouper={rowGrouper}
              expandedGroupIds={expandedGroupIds}
              onExpandedGroupIdsChange={setExpandedGroupIds}
              filters={filters}
              onFiltersChange={setFilters}
              enableFilters
            />
            <LoadMoreRowsTag loading={loading} />
          </DataGridContainer>
        )}
      </GetAccountsFullComponent>
    </React.Fragment>
  );
};

export default listPageContent(AccountList);
