import { pageContent } from '@app/shared/hoc/page-content';
import { Typography } from '@material-ui/core';
import { purple } from '@material-ui/core/colors';
import React from 'react';

const LoanApplicationDashboard = () => {
  return (
    <div>
      {process.env.REACT_APP_APP_ENV !== 'production' && (
        <Typography variant="h6" style={{ color: 'red' }} align="center" gutterBottom>
          ข้อมูลที่แสดง เป็นข้อมูลจากเซิฟเวอร์จริง
        </Typography>
      )}
      <Typography
        style={{ color: purple['700'] }}
        variant="h6"
        component="a"
        href={process.env.REACT_APP_LOAN_APPLICATION_DATA_STUDIO_URL}
        target="_blank"
        gutterBottom
      >
        คลิกเพื่อเปิดหน้าใหม่ (ดูรายงานเต็มหน้าจอ)
      </Typography>
      <iframe
        style={{ width: '100%', height: '70vh', border: 0 }}
        frameBorder="0"
        title="ThaiSMEFund Dashboard"
        width="100%"
        height="100%"
        allowFullScreen
        src={process.env.REACT_APP_LOAN_APPLICATION_DATA_STUDIO_URL}
      />
    </div>
  );
};

export default pageContent(LoanApplicationDashboard);
