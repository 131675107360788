import { TextField } from '@aginix/mui-react-hook-form-input';
import { useIfUser } from '@app/components/common/Routes';
import {
  GetProjectByPkWithMasterDataDocument,
  ProjectApprovedStatusEnum,
  useProjectApproveFormMutation,
} from '@app/generated/graphql';
import ButtonCircularProgress from '@app/shared/components/ButtonCircularProgress';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { useColors } from '@app/shared/styles';
import { useUserHasAnyPermissions } from '@app/user-permission';
import { useProject } from '@app/views/Project/contexts/ProjectContext';
import { Button, Grid, Typography } from '@material-ui/core';
import { InfoCard } from '@mystiny/ui';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export const WHITELIST_PERMISSIONS = ['PROJECT_ADMIN'];

export const ProjectApprove = () => {
  const classes = useColors();
  const [loadingStatus, setLoadingStatus] = useState<ProjectApprovedStatusEnum | null>(null);
  const isProjectManager = useUserHasAnyPermissions(WHITELIST_PERMISSIONS);
  const isSysadmin = useIfUser({
    condition: ({ roles }) => roles.includes('sysadmin'),
  });
  const { project } = useProject();
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const form = useForm();

  const [approveFn, { loading }] = useProjectApproveFormMutation({
    onCompleted: () => {
      setLoadingStatus(null);
      enqueueSuccess('ปรับสถานะคำของบประมาณเสร็จสิ้น');
    },
    onError: () => {
      setLoadingStatus(null);
      enqueueFailure('เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง');
    },
    refetchQueries: [
      {
        query: GetProjectByPkWithMasterDataDocument,
        variables: {
          id: project?.id,
        },
      },
    ],
  });

  // const handleCancel = useCallback(() => {
  //   approveFn({
  //     variables: {
  //       data: {
  //         projectId: project?.id,
  //         comment: formData.remark,
  //         approveType: ProjectApprovedStatusEnum.Cancel
  //       }
  //     }
  //   })
  // }, [approveFn, project]);

  const handleApprove = form.handleSubmit((formData) => {
    if (window.confirm('ยืนยันการพิจารณาอนุมัติงบประมาณ')) {
      setLoadingStatus(ProjectApprovedStatusEnum.Approved);
      approveFn({
        variables: {
          data: {
            projectId: project?.id,
            comment: formData.remark,
            approveType: ProjectApprovedStatusEnum.Approved,
          },
        },
      });
    }
  });

  const handleReject = form.handleSubmit((formData) => {
    if (window.confirm('ยืนยันการพิจารณาอนุมัติงบประมาณ')) {
      setLoadingStatus(ProjectApprovedStatusEnum.Rejected);
      approveFn({
        variables: {
          data: {
            projectId: project?.id,
            comment: formData.remark,
            approveType: ProjectApprovedStatusEnum.Rejected,
          },
        },
      });
    }
  });

  const handleChangeRequest = form.handleSubmit((formData) => {
    if (window.confirm('ยืนยันการพิจารณาอนุมัติงบประมาณ')) {
      setLoadingStatus(ProjectApprovedStatusEnum.ChangeRequest);
      approveFn({
        variables: {
          data: {
            projectId: project?.id,
            comment: formData.remark,
            approveType: ProjectApprovedStatusEnum.ChangeRequest,
          },
        },
      });
    }
  });

  if (['draft', 'cancelled'].includes(project?.step_id || 'draft')) {
    return null;
  }

  if (!isSysadmin || !isProjectManager) {
    return null;
  }

  return (
    <InfoCard title="พิจารณาอนุมัติคำของบประมาณ">
      <FormProvider {...form}>
        <Typography variant="body1"></Typography>
        <Grid container>
          <Grid item xs={12}>
            <TextField name="remark" variant="outlined" label="หมายเหตุ" rows={3} rowsMax={10} fullWidth multiline />
          </Grid>
          <Grid container justify="space-between" item xs={12} spacing={2}>
            {/* <Grid item xs={12} md={3}>
              <Button
                disabled={loading || project?.step_id === 'cancelled'}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                classes={{ containedPrimary: classes.cancelled }}
                onClick={handleCancel}
              >
                ยกเลิก {loadingStatus === 'cancelled' && <ButtonCircularProgress />}
              </Button>
            </Grid> */}
            <Grid item xs={12} md={3}>
              <Button
                disabled={loading || project?.step_id === 'rejected'}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                classes={{ containedPrimary: classes.rejected }}
                onClick={handleReject}
              >
                ไม่อนุมัติ {loadingStatus === ProjectApprovedStatusEnum.Rejected && <ButtonCircularProgress />}
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                disabled={loading || project?.step_id === 'approved'}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                classes={{ containedPrimary: classes.approved }}
                onClick={handleApprove}
              >
                อนุมัติ {loadingStatus === ProjectApprovedStatusEnum.Approved && <ButtonCircularProgress />}
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                disabled={loading || project?.step_id === 'change_request'}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                classes={{ containedPrimary: classes.changeRequest }}
                onClick={handleChangeRequest}
              >
                แก้ไขข้อมูล {loadingStatus === ProjectApprovedStatusEnum.ChangeRequest && <ButtonCircularProgress />}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>
    </InfoCard>
  );
};
