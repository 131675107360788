import { ErrorMessage, TextField } from '@aginix/mui-react-hook-form-input';
import AutocompleteInput from '@app/shared/components/AutocompleteInput';
import { FormControl, Grid, Typography } from '@material-ui/core';
import React, { Fragment, useCallback, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useProjectEditable } from '@app/views/Project/contexts/ProjectContext';
import DeleteButton from '@app/shared/components/buttons/DeleteButton';
import AddButton from '@app/shared/components/buttons/AddButton';
import { GetProjectByPkWithMasterDataQuery } from '@app/generated/graphql';

export const ProjectTargetGroups = ({
  targetGroups,
}: {
  targetGroups: GetProjectByPkWithMasterDataQuery['target_groups'];
}) => {
  const disabled = !useProjectEditable();
  const form = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'project_target_groups',
  });

  const handleAppend = useCallback(() => {
    append({});
  }, [append]);

  useEffect(() => {
    if (fields.length === 0) {
      append({});
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">กลุ่มเป้าหมายและสาขาอุตสาหกรรม</Typography>
        </Grid>
        {fields.map((field, index) => (
          <Grid container item xs={12} key={field.id}>
            <Grid item xs={12} sm={10}>
              <span style={{ display: 'none' }}>
                <TextField
                  defaultValue={field.target_group_id}
                  name={`project_target_groups[${index}].target_group_id`}
                  type="hidden"
                />
              </span>
              <FormControl fullWidth error={!!form.errors[`project_target_groups[${index}].target_group_id`]}>
                <AutocompleteInput
                  disabled={disabled}
                  label={`(${index + 1}) กลุ่มเป้าหมาย`}
                  defaultValue={field.target_group_id}
                  name={`project_target_groups[${index}].target_group_id`}
                  options={targetGroups || []}
                  primaryKey="id"
                  getOptionLabel={(option: any) => `${option.description}`}
                  rules={{ required: 'กรุณาระบุกลุ่มเป้าหมาย' }}
                  required
                  size="small"
                  disableClearable
                />
                <ErrorMessage name={`project_target_groups[${index}].target_group_id`} />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              {!disabled && (
                <DeleteButton variant="outlined" onClick={() => remove(index)}>
                  ลบ
                </DeleteButton>
              )}
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          {!disabled && (
            <AddButton
              disabled={fields.length >= targetGroups?.length}
              variant="outlined"
              size="small"
              onClick={handleAppend}
            >
              เพิ่มกลุ่มเป้าหมาย
            </AddButton>
          )}
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <FormControl fullWidth error={!!form.errors['industry']}>
            <TextField
              disabled={disabled}
              label="สาขาอุตสาหกรรม"
              name="industry"
              variant="outlined"
              size="small"
              rules={{ required: 'กรุณาระบุสาขาอุตสาหกรรม' }}
              fullWidth
              required
            />
            <ErrorMessage name="industry" />
          </FormControl>
        </Grid>
      </Grid>
    </Fragment>
  );
};
