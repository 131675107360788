import React from 'react';
import TextFilter from '@app/components/data-grid/TextFilter';
import { ProjectBudgetFragment } from '@app/generated/graphql';
import { Column } from 'react-data-grid';
import { Link } from 'react-router-dom';
import { Link as MuiLink } from '@material-ui/core';

export const budgetonColumns: Column<ProjectBudgetFragment>[] = [
  {
    key: 'fiscal_year',
    name: 'ปีงบประมาณ',
    width: 120,
  },
  {
    key: 'name',
    name: 'ชื่อ',
    filterRenderer: (p) => <TextFilter {...p} />,
    formatter: ({ row }) => (
      <MuiLink component={Link} to={`/bof/projects/master-data/budgets/detail/${row.id}`}>
        {row.name}
      </MuiLink>
    ),
  },

  {
    key: 'price_per_unit',
    name: 'ราคาต่อหน่วย',
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'unit.description',
    name: 'หน่วยนับ',
    filterRenderer: (p) => <TextFilter {...p} />,
    formatter: ({ row }) => <span>{row.unit?.description}</span>,
  },
  {
    key: 'unit_of_multiplier.description ',
    name: 'หน่วยนับตัวคูณ',
    // filterRenderer: (p) => <TextFilter {...p} />,
    formatter: ({ row }) => <span>{row.unit_of_multiplier?.description}</span>,
  },
];
