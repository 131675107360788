import React from 'react';
import { GroupFormatterProps, ToggleGroupFormatter } from 'react-data-grid';

export interface ToggleGroupProps<TRow, TSummaryRow> extends GroupFormatterProps<TRow, TSummaryRow> {
  defaultGroupKey?: string;
}

export const ToggleGroup = <TRow, TSummaryRow>({ defaultGroupKey = 'ไม่มีข้อมูล', groupKey, ...props }: ToggleGroupProps<TRow, TSummaryRow>) => {
  const overriddenGroupKey = groupKey !== 'undefined' ? groupKey : defaultGroupKey;
  return <ToggleGroupFormatter {...props} groupKey={overriddenGroupKey} />;
}