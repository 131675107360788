import {
  useInsertReceivableOneMutation,
  ReceivableFragment,
  LedgerReceivableInsertInput,
  LedgerReceivableStatusEnum,
} from '@app/generated/graphql';
import DescriptionIcon from '@material-ui/icons/Description';
import { pageContent } from '@app/shared/hoc/page-content';
import React from 'react';
import PageTitle from '@app/shared/components/PageTitle';
import BackButton from '@app/shared/components/buttons/BackButton';
import { InfoCard } from '@mystiny/ui';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { FormProvider, useForm } from 'react-hook-form';
import { Divider, Typography } from '@material-ui/core';
import AddButton from '@app/shared/components/buttons/AddButton';
import Page from '@app/components/Page';
import { useIfUser } from '@app/components/common/Routes';
import { useHistory } from 'react-router-dom';
import { DateUtils } from '@app/shared/libs/date';
import { ReceivableForm } from './ReceivableForm';
import ReceivableStepper from './components/ReceivableStepper';
import { useTypography } from '@app/shared/styles/typography';
import Spacing from '@app/components/common/Spacing';
import { useCurrentUser } from '@app/hooks';

const ReceivableCreate = () => {
  const typographyClasses = useTypography();
  const history = useHistory();
  const user = useCurrentUser();
  const form = useForm<ReceivableFragment>({
    defaultValues: {
      date: new Date(),
      due_date: new Date(),
      organization_id: user?.organizationId,
    },
  });

  const isSysadmin = useIfUser({ condition: ({ roles }) => roles.includes('sysadmin') });
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [createFn] = useInsertReceivableOneMutation({
    onCompleted: ({ insert_ledger_receivable_one }) => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
      history.push(`/bof/accounting/receivables/${insert_ledger_receivable_one?.id}`);
    },
    onError: (err: any) => {
      console.error(err);
      enqueueFailure('เกิดข้อผิดพลาด' + err);
    },
  });

  const handleSubmit = form.handleSubmit((formData: ReceivableFragment) => {
    if (!window.confirm('ยืนยันการสร้างข้อมูล')) {
      return;
    }

    const fiscalYear = DateUtils.getThaiFiscalYear(formData.date);
    const data: LedgerReceivableInsertInput = {
      date: formData.date,
      note: formData.note,
      gfmis_id: formData.gfmis_id,
      fiscal_year: fiscalYear,
      due_date: formData.due_date,
      proposal: formData.proposal,
      borrower_name: formData.borrower_name,
      reference: formData.reference,
      amount: formData.amount,
      budget_id: formData.budget?.id,
    };

    if (isSysadmin) {
      data.organization_id = formData.organization_id;
    } else {
      data.organization_id = user?.organizationId;
    }

    createFn({
      variables: {
        object: data,
      },
    });
  });

  return (
    <Page
      title={
        <PageTitle
          title={
            <React.Fragment>
              <Typography variant="h5" color="primary" className={typographyClasses.pageTitle}>
                <DescriptionIcon color="primary" fontSize="small" /> เพิ่มข้อมูลทะเบียนคุมลูกหนี้เงินยืม
              </Typography>
            </React.Fragment>
          }
          actions={
            <div>
              <BackButton to={`/bof/accounting/receivables`} />
            </div>
          }
        />
      }
    >
      <InfoCard actions={<AddButton onClick={handleSubmit} />}>
        <ReceivableStepper status={LedgerReceivableStatusEnum.Draft} />
        <Divider />
        <Spacing />
        <Typography variant="h6" color="textSecondary" gutterBottom>
          แบบฟอร์มข้อมูลทะเบียนคุมลูกหนี้เงินยืม
        </Typography>
        <FormProvider {...form}>
          <ReceivableForm mode="create" />
        </FormProvider>
      </InfoCard>
    </Page>
  );
};

export default pageContent(ReceivableCreate);
