import Spacing from '@app/components/common/Spacing';
import { useGetProjectActivitiesByProjectIdQuery } from '@app/generated/graphql';
import { ProjectBudgetItems } from '@app/views/Project/components/ProjectBudgetItems';
import { CircularProgress, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';

export interface ProjectBudgetsProps {
  projectId: string;
}

export const ProjectBudgets = ({ projectId }: ProjectBudgetsProps) => {
  const { data, loading } = useGetProjectActivitiesByProjectIdQuery({
    variables: {
      projectId,
    },
  });

  if (!data?.activities && loading) return <CircularProgress />;
  if (!data?.activities) return <Typography>Not Found</Typography>;

  return (
    <Fragment>
      {data?.activities.map((activity) => (
        <Fragment key={activity.id}>
          <ProjectBudgetItems
            activity={activity}
            budgets={data?.budgets}
            budget_group_mapping={data?.budget_group_mapping}
          />
          <Spacing />
        </Fragment>
      ))}
      <Spacing />
      <Spacing />
    </Fragment>
  );
};
