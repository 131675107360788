import { ErrorMessage, TextField } from '@aginix/mui-react-hook-form-input';
import { FormProps } from '@app/global-props';
import { FormControl } from '@material-ui/core';
import { FormLayout, FormLayoutGroup } from '@mystiny/ui';
import React from 'react';
import { useFormContext } from 'react-hook-form';

export const Form = (_: FormProps) => {
  const form = useFormContext();
  return (
    <FormLayout>
      <FormLayoutGroup>
        <FormControl fullWidth error={!!form.errors['id']}>
          <TextField
            label="ID"
            name="id"
            variant="outlined"
            size="small"
            fullWidth
            rules={{ required: 'กรุณาระบุ  ID' }}
          />
          <ErrorMessage name="id" />
        </FormControl>
      </FormLayoutGroup>
      <FormLayoutGroup>
        <FormControl fullWidth error={!!form.errors['description']}>
          <TextField label="Description" name="description" variant="outlined" size="small" fullWidth />
          <ErrorMessage name="description" />
        </FormControl>
      </FormLayoutGroup>
    </FormLayout>
  );
};
