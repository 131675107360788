import React, { Fragment } from 'react';
import { GetLedgerMatrixReportByOrganizationIdQuery } from '@app/generated/graphql';
import { Column } from 'react-data-grid';
import NumberFormat from 'react-number-format';
import { months } from '@app/shared/libs/date';

export interface MonthlyReportColumnsProps {
  budgets: GetLedgerMatrixReportByOrganizationIdQuery['budget_rows'];
}

const MonthlyGroupFormatter = ({ childRows, column }: any) => {
  const total = childRows.reduce((prev: number, row: any) => prev + (row[column.key] || 0), 0);
  return (
    <div className="text-right">
      <NumberFormat
        value={total}
        displayType={'text'}
        type="text"
        decimalScale={2}
        fixedDecimalScale
        thousandSeparator
      />
    </div>
  );
};

const MonthlyFormatter = ({ row, column }: any) => (
  <NumberFormat
    value={row[column.key] || 0}
    displayType={'text'}
    type="text"
    decimalScale={2}
    fixedDecimalScale
    thousandSeparator
  />
);

const monthlyWidth = 100;

export const useMonthlyReportColumns = (): Column<any>[] => {
  return [
    {
      key: 'account.name',
      name: 'รหัสและชื่อบัญชี',
      width: 240,
      minWidth: 180,
      frozen: true,
      formatter: ({ row }) => (
        <Fragment>
          {row.account.code} - {row.account.name}
        </Fragment>
      ),
      summaryCellClass: 'text-right',
      summaryFormatter: ({ row }: any) => <Fragment>{row.label}</Fragment>,
    },
    {
      key: 'budget.description',
      name: 'งบประมาณ',
      minWidth: 120,
      width: 200,
      formatter: ({ row }) => <Fragment>{row.budget.description}</Fragment>,
    },
    {
      key: 'october',
      name: months[9],
      headerCellClass: 'text-right',
      width: monthlyWidth,
      cellClass: 'text-right',
      groupFormatter: MonthlyGroupFormatter,
      formatter: MonthlyFormatter,
    },
    {
      key: 'november',
      name: months[10],
      headerCellClass: 'text-right',
      width: monthlyWidth,
      cellClass: 'text-right',
      groupFormatter: MonthlyGroupFormatter,
      formatter: MonthlyFormatter,
    },
    {
      key: 'december',
      name: months[11],
      headerCellClass: 'text-right',
      width: monthlyWidth,
      cellClass: 'text-right',
      groupFormatter: MonthlyGroupFormatter,
      formatter: MonthlyFormatter,
    },
    {
      key: 'january',
      name: months[0],
      headerCellClass: 'text-right',
      width: monthlyWidth,
      cellClass: 'text-right',
      groupFormatter: MonthlyGroupFormatter,
      formatter: MonthlyFormatter,
    },
    {
      key: 'february',
      name: months[1],
      headerCellClass: 'text-right',
      width: monthlyWidth,
      cellClass: 'text-right',
      groupFormatter: MonthlyGroupFormatter,
      formatter: MonthlyFormatter,
    },
    {
      key: 'march',
      name: months[2],
      headerCellClass: 'text-right',
      width: monthlyWidth,
      cellClass: 'text-right',
      groupFormatter: MonthlyGroupFormatter,
      formatter: MonthlyFormatter,
    },
    {
      key: 'april',
      name: months[3],
      headerCellClass: 'text-right',
      width: monthlyWidth,
      cellClass: 'text-right',
      groupFormatter: MonthlyGroupFormatter,
      formatter: MonthlyFormatter,
    },
    {
      key: 'may',
      name: months[4],
      headerCellClass: 'text-right',
      width: monthlyWidth,
      cellClass: 'text-right',
      groupFormatter: MonthlyGroupFormatter,
      formatter: MonthlyFormatter,
    },
    {
      key: 'june',
      name: months[5],
      headerCellClass: 'text-right',
      width: monthlyWidth,
      cellClass: 'text-right',
      groupFormatter: MonthlyGroupFormatter,
      formatter: MonthlyFormatter,
    },
    {
      key: 'july',
      name: months[6],
      headerCellClass: 'text-right',
      width: monthlyWidth,
      cellClass: 'text-right',
      groupFormatter: MonthlyGroupFormatter,
      formatter: MonthlyFormatter,
    },
    {
      key: 'august',
      name: months[7],
      headerCellClass: 'text-right',
      width: monthlyWidth,
      cellClass: 'text-right',
      groupFormatter: MonthlyGroupFormatter,
      formatter: MonthlyFormatter,
    },
    {
      key: 'september',
      name: months[8],
      headerCellClass: 'text-right',
      width: monthlyWidth,
      cellClass: 'text-right',
      groupFormatter: MonthlyGroupFormatter,
      formatter: MonthlyFormatter,
    },
  ];
};

// export const useMonthlyReportColumns = ({ budgets }: MonthlyReportColumnsProps): Column<any>[] => {
//   const budgetColumns = budgets.map<Column<any>>((budget) => ({
//     key: budget.id,
//     name: budget.description || '',
//     cellClass: 'text-right',
//     formatter: ({ row }) => (
//       <NumberFormat
//         value={row.budgets[budget.id]?.balance || 0}
//         displayType={'text'}
//         type="text"
//         decimalScale={2}
//         fixedDecimalScale
//         thousandSeparator
//       />
//     ),
//     summaryCellClass: 'text-right',
//     summaryFormatter: ({ row }: any) => (
//       <NumberFormat
//         value={row.budgets[budget.id]?.total || 0}
//         displayType={'text'}
//         type="text"
//         decimalScale={2}
//         fixedDecimalScale
//         thousandSeparator
//       />
//     ),
// groupFormatter: ({ childRows, groupKey }) => {
//   const total = childRows.reduce((prev, row) => prev + (row.budgets[budget.id]?.balance || 0), 0);
//   return (
//     <div className="text-right">
//       <NumberFormat
//         value={total}
//         displayType={'text'}
//         type="text"
//         decimalScale={2}
//         fixedDecimalScale
//         thousandSeparator
//       />
//     </div>
//   );
// },
//   }));
//   return [
//     {
//       key: 'account.name',
//       name: 'รหัสและชื่อบัญชี',
//       minWidth: 320,
//       formatter: ({ row }) => (
//         <Fragment>
//           {row.account.code} - {row.account.name}
//         </Fragment>
//       ),
//       summaryCellClass: 'text-right',
//       summaryFormatter: ({ row }: any) => <Fragment>{row.label}</Fragment>,
//     },
//     {
//       key: 'account.account_type.description',
//       name: 'ประเภทบัญชี',
//       width: 140,
//       formatter: ({ row }) => <Fragment>{row.account.account_type.description}</Fragment>,
//     },
//     ...budgetColumns,
//     {
//       key: 'total',
//       name: 'รวม',
//       width: 120,
//       cellClass: 'text-right',
//       formatter: ({ row }) => (
//         <NumberFormat
//           value={row.total || 0}
//           displayType={'text'}
//           type="text"
//           decimalScale={2}
//           fixedDecimalScale
//           thousandSeparator
//         />
//       ),
//       summaryCellClass: 'text-right',
//       summaryFormatter: ({ row }: any) => (
//         <NumberFormat
//           value={row.total || 0}
//           displayType={'text'}
//           type="text"
//           decimalScale={2}
//           fixedDecimalScale
//           thousandSeparator
//         />
//       ),
//       groupFormatter: ({ childRows }) => {
//         const total = childRows.reduce((prev, row) => prev + (row.total || 0), 0);
//         return (
//           <div className="text-right">
//             <NumberFormat
//               value={total}
//               displayType={'text'}
//               type="text"
//               decimalScale={2}
//               fixedDecimalScale
//               thousandSeparator
//             />
//           </div>
//         );
//       },
//     },
//   ];
// };
