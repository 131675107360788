import React from 'react';
import TextFilter from '@app/components/data-grid/TextFilter';
import { GetNationalStrategiesQuery } from '@app/generated/graphql';
import { Column } from 'react-data-grid';

export const strategyonColumns: Column<GetNationalStrategiesQuery['national_strategies'][0]>[] = [
  {
    key: 'id',
    name: 'ID',
    width: 200,
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'name',
    name: 'ชื่อด้านยุทธศาสตร์แห่งชาติ',
    filterRenderer: (p) => <TextFilter {...p} />,
    formatter: ({ row }) => <span>{row.name}</span>,
  },
  {
    key: 'depth',
    name: 'ความลึก',
    width: 100,
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'parent.name',
    name: 'ชั้นบน',
    filterRenderer: (p) => <TextFilter {...p} />,
    formatter: ({ row }) => <span>{row.parent_id ? `${row.parent?.name} (${row.parent_id})` : ''}</span>,
  },
];
