import React from 'react';
import { GetUsersQuery } from '@app/generated/graphql';
import { Column } from 'react-data-grid';
import { DateUtils } from '@app/shared/libs/date';
import TextFilter from '@app/components/data-grid/TextFilter';

export const userColumns: Column<GetUsersQuery['rows'][0]>[] = [
  {
    key: 'created_at',
    name: 'สร้างวันที่',
    minWidth: 160,
    formatter: ({ row }) => (
      <React.Fragment>
        {row.created_at ? `${DateUtils.toThaiDate(row.created_at)} ${DateUtils.toTime(row.created_at)}` : '-'}
      </React.Fragment>
    ),
  },
  {
    key: 'display_name',
    name: 'ชื่อผู้ใช้',
    minWidth: 200,
    resizable: true,
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'account.email',
    name: 'e-mail',
    minWidth: 200,
    resizable: true,
    formatter: ({ row }) => <React.Fragment>{row.account?.email}</React.Fragment>,
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'organization.short_name',
    name: 'หน่วยงาน',
    minWidth: 200,
    resizable: true,
    formatter: ({ row }) => <React.Fragment>{row.organization?.short_name || '-'}</React.Fragment>,
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'tel',
    name: 'เบอร์โทรศัพท์',
    minWidth: 150,
    resizable: true,
    formatter: ({ row }) => <React.Fragment>{row.tel || '-'}</React.Fragment>,
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'account.default_role',
    name: 'Role',
    minWidth: 200,
    resizable: true,
    formatter: ({ row }) => (
      <React.Fragment>
        {row.account?.default_role}{' '}
        {row.user_permissions.length ? ': ' + row.user_permissions.map((up) => up.permission.description).join(', ') : ''}
      </React.Fragment>
    ),
  },
  {
    key: 'auth_provider',
    name: 'Providers',
    minWidth: 200,
    sortable: false,
    resizable: true,
    formatter: ({ row }) => (
      <React.Fragment>
        {row.account?.account_providers
          .map((provider) => {
            return provider.auth_provider;
          })
          .join(', ')}
      </React.Fragment>
    ),
  },
];
