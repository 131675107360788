import React, { Fragment } from 'react';

export interface SidebarItemAmountProps {
  value: number | undefined | null;
  loading: boolean | undefined;
}

const SidebarItemAmount = ({ value, loading }: SidebarItemAmountProps) => {
  if (loading) {
    return <Fragment>...</Fragment>;
  }

  return <Fragment>{value ? value : 0}</Fragment>;
};

export default SidebarItemAmount;
