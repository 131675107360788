import React from 'react';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ListIcon from '@material-ui/icons/List';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import RestoreIcon from '@material-ui/icons/Restore';
import { GmailTabs, GmailTabItem } from '@mui-treasury/components/tabs/gmail';
import { blue, orange, green, red } from '@material-ui/core/colors';

const indicatorColors = [blue[500], orange.A400, orange.A700, green.A400, red.A400];

export const ProjectTodoFilter = ({ index, setIndex }: any) => {
  return (
    <GmailTabs value={index} onChange={(_, value) => setIndex(value)} indicatorColors={indicatorColors}>
      <GmailTabItem icon={<ListIcon />} label="ทั้งหมด" value={null} />
      <GmailTabItem icon={<HourglassEmptyIcon />} label="รอการอนุมัติ" value="submitted" />
      <GmailTabItem icon={<RestoreIcon />} label="เปลี่ยนแปลงข้อมูล" value="change_request" />
      <GmailTabItem icon={<ThumbUpIcon />} label="อนุมัติ" value="approved" />
      <GmailTabItem icon={<ThumbDownIcon />} label="ไม่อนุมัติ" value="rejected" />
    </GmailTabs>
  );
};
