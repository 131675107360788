import { useInsertBudgetTypeOneMutation, BudgetTypeFragment } from '@app/generated/graphql';
import DescriptionIcon from '@material-ui/icons/Description';
import { pageContent } from '@app/shared/hoc/page-content';
import React from 'react';
import PageTitle from '@app/shared/components/PageTitle';
import BackButton from '@app/shared/components/buttons/BackButton';
import { InfoCard } from '@mystiny/ui';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { FormProvider, useForm } from 'react-hook-form';
import Spacing from '@app/components/common/Spacing';
import { Typography } from '@material-ui/core';
import SaveButton from '@app/shared/components/buttons/SaveButton';
import Page from '@app/components/Page';
import { Form } from './components/Form';
import { useHistory } from 'react-router-dom';

const BudgetTypeCreate = () => {
  const form = useForm<BudgetTypeFragment>();
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const history = useHistory();
  const [createFn] = useInsertBudgetTypeOneMutation({
    onCompleted: ({ insert_account_budget_type_one }) => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
      history.push(`/bof/accounting/budget-types/${insert_account_budget_type_one?.id}`);
    },
    onError: (err: any) => {
      console.error(err);
      enqueueFailure('เกิดข้อผิดพลาด');
    },
  });

  const handleSubmit = form.handleSubmit((formData: BudgetTypeFragment) => {
    createFn({
      variables: {
        object: {
          sequence: formData.sequence,
          id: formData.id,
          description: formData.description,
          fiscal_year: formData.fiscal_year,
          account_id: formData.account_id,
          group_name: formData.group_name,
        },
      },
    });
  });

  return (
    <Page
      title={
        <PageTitle
          title={
            <React.Fragment>
              <Typography variant="h6" component="div">
                <DescriptionIcon color="primary" fontSize="small" /> สร้างงบประมาณ
              </Typography>
            </React.Fragment>
          }
          actions={
            <div>
              <BackButton to="/bof/accounting/budget-types" />
            </div>
          }
        />
      }
    >
      <Spacing />
      <InfoCard title="ข้อมูลงบประมาณ" actionTopRight={<SaveButton onClick={handleSubmit} />}>
        <FormProvider {...form}>
          <Form mode="create" />
        </FormProvider>
      </InfoCard>
      <Spacing />
    </Page>
  );
};

export default pageContent(BudgetTypeCreate);
