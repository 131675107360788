import React, { Fragment, FC } from 'react';

export interface IfProps {
  condition: boolean | (() => boolean);
  render?: () => React.ReactNode;
}

const If: FC<IfProps> = ({ condition, render, children }) => {
  const value = typeof condition === 'function' ? condition() : condition;
  return value ? render ? <Fragment>{render()}</Fragment> : <Fragment>{children}</Fragment> : null;
};

export default If;
