import { ArchiveView } from '@app/components/ArchiveView';
import TextFilter from '@app/components/data-grid/TextFilter';
import { GetBudgetTypesQuery } from '@app/generated/graphql';
import React, { Fragment } from 'react';
import { Column } from 'react-data-grid';
import { Link } from 'react-router-dom';
import { Link as MuiLink } from '@material-ui/core';

export const budgetTypeColumns: Column<GetBudgetTypesQuery['rows'][0]>[] = [
  {
    key: 'sequence',
    name: 'ลำดับ',
    cellClass: 'text-right',
    resizable: true,
    width: 45,
  },
  {
    key: 'fiscal_year',
    name: 'ปีงบประมาณ',
    resizable: true,
    width: 130,
  },
  {
    key: 'description',
    name: 'ชื่องบประมาณ',
    resizable: true,
    minWidth: 200,
    formatter: ({ row }) => (
      <MuiLink component={Link} to={`/bof/accounting/budget-types/${row.id}/${row.fiscal_year}`}>
        {row.description}
      </MuiLink>
    ),
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'id',
    name: 'ID',
    resizable: true,
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'account.name',
    name: 'Book Bank',
    resizable: true,
    minWidth: 200,
    formatter: ({ row }) => (
      <Fragment>
        {row?.account ? (
          <MuiLink component={Link} to={`/bof/accounting/accounts/${row.account_id}`}>
            {row.account.code} - {row.account.name}
          </MuiLink>
        ) : (
          '-'
        )}
      </Fragment>
    ),
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'group_name',
    name: 'กลุ่มงบ',
    resizable: true,
    minWidth: 200,
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'accounts_aggregate.aggregate.count',
    name: 'จำนวนบัญชี',
    resizable: true,
    sortable: false,
    formatter: ({ row }) => <span>{row?.accounts_aggregate?.aggregate?.count || 0}</span>,
  },
  {
    key: 'deleted_at',
    name: 'เปิด/ปิดการใช้งาน',
    resizable: true,
    width: 60,
    formatter: ({ row }) => <ArchiveView value={!row.deleted_at} />,
  },
];
