import { ErrorMessage, TextField } from '@aginix/mui-react-hook-form-input';
import {
  ProjectFragment,
  useProjectApproveFormMutation,
  GetProjectEventLogsByProjectIdDocument,
  ProjectApprovedStatusEnum,
  GetProjectByPkWithMasterDataDocument,
} from '@app/generated/graphql';
import ButtonCircularProgress from '@app/shared/components/ButtonCircularProgress';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { useColors } from '@app/shared/styles';
import { Button, FormControl, Grid } from '@material-ui/core';
import { FormLayout, FormLayoutGroup, InfoCard } from '@mystiny/ui';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { prompt } from '@app/shared/libs';

export interface ProjectWorkflowProps {
  project: ProjectFragment;
}

type FormState = {
  comment: string;
};

export const ProjectVerification = ({ project }: ProjectWorkflowProps) => {
  const { enqueueFailure, enqueueSuccess } = useResponseBar();
  const [loadingStatus, setLoadingStatus] = useState<string | null>(null);
  const classes = useColors();
  const form = useForm<FormState>({
    defaultValues: {
      comment: '',
    },
  });

  const [approveFn, { loading }] = useProjectApproveFormMutation({
    onCompleted: () => {
      enqueueSuccess('ปรับสถานะสำเร็จ');
      setLoadingStatus(null);
      form.reset({
        comment: '',
      });
    },
    onError: () => {
      enqueueFailure('ไม่สามารถบันทึกข้อมูลได้');
      setLoadingStatus(null);
    },
    refetchQueries: [
      {
        query: GetProjectEventLogsByProjectIdDocument,
        variables: {
          projectId: project.id,
        },
      },
      {
        query: GetProjectByPkWithMasterDataDocument,
        variables: {
          id: project.id,
        },
      },
    ],
  });

  const handleApprove = form.handleSubmit((formData: FormState) => {
    prompt(
      'ท่านแน่ใจที่จะปรับสถานะข้อมูล "อนุมัติ" ใช่หรือไม่?\nซึ่งข้อมูลจะไม่สามารถถูกแก้ไขหรือย้อนกลับได้\n\nโปรดพิมพ์คำว่า "yes" เพื่อยืนยันการปรับสถานะ',
      {
        value: 'yes',
        callback: () => {
          approveFn({
            variables: {
              data: {
                projectId: project.id,
                comment: formData.comment,
                approveType: ProjectApprovedStatusEnum.Approved,
              },
            },
          });
          setLoadingStatus('approved');
        },
      }
    );
  });

  const handleReject = form.handleSubmit((formData: FormState) => {
    prompt(
      'ท่านแน่ใจที่จะปรับสถานะข้อมูล "ไม่อนุมัติ" ใช่หรือไม่?\nซึ่งข้อมูลจะไม่สามารถถูกแก้ไขหรือย้อนกลับได้\n\nโปรดพิมพ์คำว่า "yes" เพื่อยืนยันการปรับสถานะ',
      {
        value: 'yes',
        callback: () => {
          approveFn({
            variables: {
              data: {
                projectId: project.id,
                comment: formData.comment,
                approveType: ProjectApprovedStatusEnum.Rejected,
              },
            },
          });
          setLoadingStatus('rejected');
        },
      }
    );
  });

  const handleChangeRequest = form.handleSubmit((formData: FormState) => {
    prompt(
      'ท่านแน่ใจที่จะปรับสถานะข้อมูล "เปลี่ยนแปลงข้อมูล" ใช่หรือไม่?\nซึ่งข้อมูลจะไม่สามารถถูกแก้ไขหรือย้อนกลับได้\n\nโปรดพิมพ์คำว่า "yes" เพื่อยืนยันการปรับสถานะ',
      {
        value: 'yes',
        callback: () => {
          approveFn({
            variables: {
              data: {
                projectId: project.id,
                comment: formData.comment,
                approveType: ProjectApprovedStatusEnum.ChangeRequest,
              },
            },
          });
          setLoadingStatus('change_request');
        },
      }
    );
  });

  return (
    <InfoCard title="พิจารณาคำของบประมาณ" subheader='มองเห็นเฉพาะสิทธิ์ "แอดมินส่วนกลาง"'>
      <FormProvider {...form}>
        <FormLayout>
          <FormLayoutGroup>
            <FormControl fullWidth error={!!form.errors['comment']}>
              <TextField
                name="comment"
                label="หมายเหตุ"
                rows={3}
                variant="outlined"
                rules={{ required: 'กรุณาระบุหมายเหตุ' }}
                multiline
                fullWidth
              />
              <ErrorMessage name="comment" />
            </FormControl>
          </FormLayoutGroup>
          <FormLayoutGroup>
            <Grid container justify="space-between" spacing={1}>
              <Grid item xs={12} md={3}>
                <Button
                  disabled={loading || project.step_id === 'rejected'}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  classes={{ containedPrimary: classes.rejected }}
                  onClick={handleReject}
                >
                  ไม่อนุมัติ {loadingStatus === 'rejected' && <ButtonCircularProgress />}
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  disabled={loading || ['change_request'].includes(project.step_id)}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  classes={{ containedPrimary: classes.changeRequest }}
                  onClick={handleChangeRequest}
                >
                  ร้องขอการเปลี่ยนแปลง {loadingStatus === 'change_request' && <ButtonCircularProgress />}
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  disabled={loading || project.step_id === 'approved'}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  classes={{ containedPrimary: classes.approved }}
                  onClick={handleApprove}
                >
                  อนุมัติ {loadingStatus === 'approved' && <ButtonCircularProgress />}
                </Button>
              </Grid>
            </Grid>
          </FormLayoutGroup>
        </FormLayout>
      </FormProvider>
    </InfoCard>
  );
};
