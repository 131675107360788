import React, { FC, Fragment, useEffect } from 'react';
import { useGetUserPermissionsLazyQuery, GetUserPermissionsQuery } from '../generated/graphql';
import { useCurrentUser } from '@app/hooks';

export interface UserPermissionProps {
  permissionIds: string[];
  inactiveComponent?: React.ComponentType<{}>;
}

export function useUserPermission(): GetUserPermissionsQuery['user_permissions'] {
  const user = useCurrentUser();
  const [fetchFn, { data }] = useGetUserPermissionsLazyQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
  });

  useEffect(() => {
    fetchFn();
  }, [fetchFn, user?.id]);

  return data?.user_permissions || [];
}

export function useUserHasAnyPermissions(permissionIds: UserPermissionProps['permissionIds']) {
  const userPermissions = useUserPermission();
  return userPermissions.some(({ permission_id }) => permissionIds.includes(permission_id!));
}

export const UserPermission: FC<UserPermissionProps> = ({ permissionIds, children }) => {
  const condition = useUserHasAnyPermissions(permissionIds);
  return <Fragment>{condition ? children : null}</Fragment>;
};
