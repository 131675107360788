import React from 'react';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ListIcon from '@material-ui/icons/List';
import EditIcon from '@material-ui/icons/Edit';
import RestoreIcon from '@material-ui/icons/Restore';
import { GmailTabs, GmailTabItem } from '@mui-treasury/components/tabs/gmail';
import { LedgerReceivableStatusEnum } from '@app/generated/graphql';
import { blue, orange, green, red, grey, brown, lightBlue } from '@material-ui/core/colors';

const indicatorColors = [
  blue[500],
  orange.A400,
  orange.A700,
  green.A400,
  red.A400,
  grey.A400,
  brown.A400,
  lightBlue.A400,
];

export const ReceivableFilter = ({ index, setIndex }: any) => {
  return (
    <GmailTabs value={index} onChange={(_, value) => setIndex(value)} indicatorColors={indicatorColors}>
      <GmailTabItem icon={<ListIcon />} label="ทั้งหมด" value={null} />
      <GmailTabItem icon={<EditIcon />} label="แบบร่าง" value={LedgerReceivableStatusEnum.Draft} />
      <GmailTabItem icon={<HourglassEmptyIcon />} label="กำลังดำเนินการ" value={LedgerReceivableStatusEnum.Submitted} />
      <GmailTabItem icon={<RestoreIcon />} label="เสร็จสิ้น" value={LedgerReceivableStatusEnum.Done} />
    </GmailTabs>
  );
};
