import { ErrorMessage, TextField } from '@aginix/mui-react-hook-form-input';
import { GetAccountTypesComponent } from '@app/generated/graphql';
import { FormProps } from '@app/global-props';
import AutocompleteInput from '@app/shared/components/AutocompleteInput';
import { FormControl } from '@material-ui/core';
import { FormLayout, FormLayoutGroup } from '@mystiny/ui';
import React from 'react';
import { useFormContext } from 'react-hook-form';

export const Form = (_: FormProps) => {
  const form = useFormContext();
  return (
    <FormLayout>
      <FormLayoutGroup>
        <FormControl fullWidth error={!!form.errors['code']}>
          <TextField
            label="รหัสบัญชี"
            name="code"
            variant="outlined"
            size="small"
            fullWidth
            rules={{ required: 'กรุณากรอกรหัสบัญชี' }}
            required
          />
          <ErrorMessage name="code" />
        </FormControl>
      </FormLayoutGroup>
      <FormLayoutGroup>
        <FormControl fullWidth error={!!form.errors['name']}>
          <TextField
            label="ชื่อบัญชี"
            name="name"
            variant="outlined"
            size="small"
            fullWidth
            rules={{ required: 'กรุณากรอกชื่อบัญชี' }}
            required
          />
          <ErrorMessage name="name" />
        </FormControl>
      </FormLayoutGroup>
      <FormLayoutGroup>
        <GetAccountTypesComponent fetchPolicy="cache-and-network">
          {({ data, loading }) => (
            <FormControl fullWidth>
              <AutocompleteInput
                label="หมวดบัญชี"
                name="type_id"
                options={data?.rows || []}
                loading={loading}
                primaryKey="id"
                getOptionLabel={(option: any) => `${option.description}`}
                rules={{ required: 'กรุณาระบุหมวดบัญชี' }}
                required
                size="small"
                disableClearable
              />
              <ErrorMessage name="type_id" />
            </FormControl>
          )}
        </GetAccountTypesComponent>
      </FormLayoutGroup>
      <FormLayoutGroup>
        <FormControl fullWidth error={!!form.errors['note']}>
          <TextField label="หมายเหตุ" name="note" variant="outlined" rows={3} size="small" fullWidth multiline />
          <ErrorMessage name="note" />
        </FormControl>
      </FormLayoutGroup>
    </FormLayout>
  );
};
