import { ErrorMessage, TextField } from '@aginix/mui-react-hook-form-input';
import { FormProps } from '@app/global-props';
import { FormControl } from '@material-ui/core';
import { FormLayout, FormLayoutGroup } from '@mystiny/ui';
import React from 'react';
import { Control, useFormContext } from 'react-hook-form';
import { createNumberFormatInput } from '@app/components/NumberFormatInput';
import { ProjectTargetGroups } from './ProjectTargetGroups';
import { ProjectOperatingAreas } from './ProjectOperatingAreas';
import Spacing from '@app/components/common/Spacing';
import { useProjectEditable } from '@app/views/Project/contexts/ProjectContext';

const FiscalFormatInput = createNumberFormatInput({
  allowNegative: false,
  thousandSeparator: false,
});

const ProjectDateRange = ({
  control,
  data,
  errors,
  setValue,
}: {
  control: Control;
  setValue: any;
  data: any;
  errors: any;
}) => {
  const disabled = !useProjectEditable();

  return (
    <FormLayoutGroup>
      <FormControl error={!!errors['fiscal_year']}>
        <TextField
          disabled={disabled}
          label="ปีงบประมาณ (พ.ศ.)"
          name="fiscal_year"
          variant="outlined"
          size="small"
          InputProps={{
            inputComponent: FiscalFormatInput as any,
          }}
          rules={{
            required: 'กรุณาระบุปีงบประมาณ',
          }}
          required
        />
        <ErrorMessage name="fiscal_year" />
      </FormControl>
    </FormLayoutGroup>
  );
};

export const Form = ({ data, mode }: FormProps) => {
  const disabled = !useProjectEditable();
  const form = useFormContext();

  return (
    <FormLayout>
      {/* {mode === 'update' && (
        <Fragment>
          <FormLayoutGroup>
            <MuiTextField
              value={DateUtils.toThaiDatetime(data.project?.created_at)}
              label="วันที่สร้าง"
              size="small"
              variant="standard"
              fullWidth
              disabled
            />
            <MuiTextField
              value={DateUtils.toThaiDatetime(data.project?.updated_at)}
              label="วันที่แก้ไขล่าสุด"
              size="small"
              variant="standard"
              fullWidth
              disabled
            />
          </FormLayoutGroup>
        </Fragment>
      )} */}
      <FormLayoutGroup>
        <FormControl fullWidth error={!!form.errors['title']}>
          <TextField
            disabled={disabled}
            label="ชื่อโครงการ"
            name="title"
            variant="outlined"
            size="small"
            rules={{ required: 'กรุณาระบุชื่อโครงการ' }}
            fullWidth
            required
          />
          <ErrorMessage name="title" />
        </FormControl>
      </FormLayoutGroup>
      <FormLayoutGroup>
        <FormControl fullWidth error={!!form.errors['introduction']}>
          <TextField
            disabled={disabled}
            label="หลักการและเหตุผลความจำเป็น"
            name="introduction"
            variant="outlined"
            defaultValue={data?.project?.introduction || ''}
            size="small"
            rows={5}
            rowsMax={10}
            rules={{ required: 'กรุณาระบุหลักการและเหตุผลความจำเป็น' }}
            multiline
            fullWidth
            required
          />
          <ErrorMessage name="introduction" />
        </FormControl>
      </FormLayoutGroup>
      <FormLayoutGroup>
        <FormControl fullWidth error={!!form.errors['effect']}>
          <TextField
            disabled={disabled}
            label="ผลกระทบ"
            name="effect"
            variant="outlined"
            defaultValue={data?.project?.effect || ''}
            size="small"
            rows={2}
            rowsMax={4}
            multiline
            fullWidth
          />
          <ErrorMessage name="effect" />
        </FormControl>
        <FormControl fullWidth error={!!form.errors['last_overall_operation']}>
          <TextField
            disabled={disabled}
            label="ผลการดำเนินงานในปีที่ผ่านมา"
            name="last_overall_operation"
            variant="outlined"
            defaultValue={data?.project?.last_overall_operation || ''}
            size="small"
            rows={2}
            rowsMax={4}
            multiline
            fullWidth
          />
          <ErrorMessage name="last_overall_operation" />
        </FormControl>
      </FormLayoutGroup>
      <ProjectDateRange errors={form.errors} control={form.control} setValue={form.setValue} data={data} />
      <ProjectTargetGroups targetGroups={data.target_groups} />
      {/* <Spacing /> */}
      <ProjectOperatingAreas project={data.project} regions={data.regions} />
      <Spacing />
      {/* <ProjectOrganizationAndMembers /> */}
    </FormLayout>
  );
};
