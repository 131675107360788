import React, { memo } from 'react';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LedgerLedgerStatusEnum } from '@app/generated/graphql';

const useStyles = makeStyles(() => ({ root: {} }));

const statusValueMapping = {
  [LedgerLedgerStatusEnum.Draft]: 0,
  [LedgerLedgerStatusEnum.Submitted]: 1,
  [LedgerLedgerStatusEnum.Approved]: 2,
  [LedgerLedgerStatusEnum.Locked]: 2,
  [LedgerLedgerStatusEnum.ChangeRequest]: 0,
  [LedgerLedgerStatusEnum.Rejected]: 2,
};

const LedgerStepper = ({ status }: { status: LedgerLedgerStatusEnum }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stepper activeStep={statusValueMapping[status]} alternativeLabel>
        <Step active={statusValueMapping[status] >= 0} completed={statusValueMapping[status] > 0}>
          <StepLabel>{status === LedgerLedgerStatusEnum.ChangeRequest ? 'แก้ไขข้อมูล' : 'ข้อมูลค่าใช้จ่าย'}</StepLabel>
        </Step>
        <Step
          active={statusValueMapping[status] >= 1}
          completed={statusValueMapping[status] >= 2 && status !== LedgerLedgerStatusEnum.ChangeRequest}
        >
          <StepLabel error={[LedgerLedgerStatusEnum.ChangeRequest, LedgerLedgerStatusEnum.Rejected].includes(status)}>
            {status === LedgerLedgerStatusEnum.Draft && 'รอการอนุมัติ'}
            {status === LedgerLedgerStatusEnum.Submitted && 'รอการอนุมัติ'}
            {status === LedgerLedgerStatusEnum.ChangeRequest && 'เปลี่ยนแปลงข้อมูล'}
            {status === LedgerLedgerStatusEnum.Approved && 'อนุมัติ'}
            {status === LedgerLedgerStatusEnum.Rejected && 'ไม่อนุมัติ'}
            {status === LedgerLedgerStatusEnum.Locked && 'Locked'}
          </StepLabel>
        </Step>
        <Step active={statusValueMapping[status] >= 2} completed={statusValueMapping[status] >= 2}>
          <StepLabel>เสร็จสิ้น</StepLabel>
        </Step>
      </Stepper>
    </div>
  );
};

export default memo(LedgerStepper, (prev, next) => prev.status === next.status);
