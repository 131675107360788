import { ErrorMessage, TextField } from '@aginix/mui-react-hook-form-input';
import Spacing from '@app/components/common/Spacing';
import {
  GetProjectActivitiesByProjectIdQuery,
  useReplaceProjectBudgetItemsMutation,
  ProjectProjectBudgetItemInsertInput,
  ProjectBudgetItemFragment,
} from '@app/generated/graphql';
import AutocompleteInput from '@app/shared/components/AutocompleteInput';
import AddButton from '@app/shared/components/buttons/AddButton';
import DeleteButton from '@app/shared/components/buttons/DeleteButton';
import SaveButton from '@app/shared/components/buttons/SaveButton';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { FormControl, Grid, InputAdornment, TextField as MuiTextField, Typography } from '@material-ui/core';
import { DataField, InfoCard } from '@mystiny/ui';
import React, { Fragment, useCallback, useMemo } from 'react';
import { Control, FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { createNumberFormatInput } from '@app/components/NumberFormatInput';
import { useProjectEditable } from '@app/views/Project/contexts/ProjectContext';

const NumberFormatInput = createNumberFormatInput({
  allowNegative: false,
  thousandSeparator: true,
});

const TotalBudgets = ({
  control,
  budgets,
}: {
  control: Control;
  budgets: GetProjectActivitiesByProjectIdQuery['budgets'];
}) => {
  const getBudget = useCallback((budgetId: string) => budgets.find((budget) => budget.id === budgetId), [budgets]);
  const items = useWatch<ProjectBudgetItemFragment[]>({
    name: 'budgetItems',
    control,
  });

  const total = items?.reduce((prev, item) => {
    const budget = getBudget(item.budget_id);
    return prev + (Number(item.multiplier) || 1) * budget?.price_per_unit * (Number(item.unit_amount) || 1);
  }, 0);

  return (
    <DataField
      label="จำนวนเงินรวม"
      children={
        <Typography variant="body1">
          <NumberFormat fixedDecimalScale decimalScale={2} displayType="text" value={total} thousandSeparator />
        </Typography>
      }
    />
  );
};

export const ProjectBudgetItems = ({
  activity,
  budgets,
  budget_group_mapping,
}: {
  activity: GetProjectActivitiesByProjectIdQuery['activities'][0];
  budgets: GetProjectActivitiesByProjectIdQuery['budgets'];
  budget_group_mapping: GetProjectActivitiesByProjectIdQuery['budget_group_mapping'];
}) => {
  const disabled = !useProjectEditable();
  const form = useForm<{ budgetItems: ProjectBudgetItemFragment[] }>({
    defaultValues: {
      budgetItems: activity.budget_items,
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: 'budgetItems',
    control: form.control,
  });

  const { enqueueSuccess, enqueueFailure } = useResponseBar();

  const [replaceFn, { loading }] = useReplaceProjectBudgetItemsMutation({
    onCompleted: () => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
    },
    onError: () => {
      enqueueFailure('เกิดข้อผิดพลาด ไม่สามารถบันทึกข้อมูลได้');
    },
  });

  const handleAppend = useCallback(() => {
    append({
      budget_group_id: activity.budget_group_id,
      project_operating_activity_id: activity.id,
      sequence: 100,
      multiplier: 1,
      price_per_unit: 1,
      unit_amount: 1,
    });
  }, [activity, append]);

  const getBudget = useCallback((budgetId: string) => budgets.find((budget) => budget.id === budgetId), [budgets]);

  const handleSubmit = form.handleSubmit((formData: any) => {
    const insertedIds: string[] = formData.budgetItems?.map((item: any) => item.id) || [];

    replaceFn({
      variables: {
        projectId: activity.project_id,
        activityId: activity.id,
        insertedBudgetItemIds: insertedIds,
        insertBudgetItemsData:
          formData.budgetItems?.map((item: ProjectProjectBudgetItemInsertInput) => ({
            ...item,
            budget_group_id: activity.budget_group_id,
            project_operating_activity_id: activity.id,
            multiplier: Number(item.multiplier) || 1,
            unit_amount: Number(item.unit_amount) || 1,
            price_per_unit: getBudget(item.budget_id)?.price_per_unit,
            unit_id: getBudget(item.budget_id)?.unit_id,
            unit_of_multiplier_id: getBudget(item.budget_id)?.unit_of_multiplier_id,
            total:
              (Number(item.multiplier) || 1) *
              (Number(item.unit_amount) || 1) *
              getBudget(item.budget_id)?.price_per_unit,
          })) || [],
      },
    });
  });

  const budgetIds: string[] = useMemo(
    () =>
      budget_group_mapping
        .filter((mapping) => mapping.budget_group_id === activity.budget_group_id)
        .map((mapping) => mapping.budget_id),
    [activity, budget_group_mapping]
  );
  const budgetsOptions = useMemo(() => budgets.filter((budget) => budgetIds.includes(budget.id)), [budgetIds, budgets]);

  return (
    <InfoCard
      title={activity.name}
      subheader={activity.budget_group?.description}
      actions={
        !disabled && (
          <Grid container justify="space-between">
            <Grid item>
              <AddButton onClick={handleAppend}>เพิ่มงบประมาณ</AddButton>
            </Grid>
            <Grid item>
              <SaveButton disabled={loading} onClick={handleSubmit} />
            </Grid>
          </Grid>
        )
      }
    >
      <FormProvider {...form}>
        <Grid container spacing={1}>
          {fields.map((field, index) => {
            const name = `budgetItems[${index}]`;
            const budgetId = form.watch(`${name}.budget_id`) as string;
            const budget = getBudget(budgetId);
            const multiUnit = budget?.unit_of_multiplier?.description || '';
            const multiplier = form.watch(`${name}.multiplier`);
            const unitAmount = form.watch(`${name}.unit_amount`);
            const pricePerUnit = form.watch(`${name}.price_per_unit`, budget?.price_per_unit) || budget?.price_per_unit;
            const total = (Number(multiplier) || 1) * (Number(budget?.price_per_unit) || 1) * (Number(unitAmount) || 1);

            return (
              <Fragment key={field.id}>
                <span style={{ display: 'none' }}>
                  <TextField disabled={disabled} name={`${name}.id`} defaultValue={field.id} />
                  <TextField disabled={disabled} name={`${name}.sequence`} defaultValue={index + 1} />
                  <TextField disabled={disabled} name={`${name}.price_per_unit`} defaultValue={pricePerUnit} />
                  <TextField disabled={disabled} name={`${name}.budget_id`} defaultValue={budget?.id} />
                </span>
                <Grid item container xs={12}>
                  <Grid item xs={12} sm={10}>
                    <FormControl fullWidth>
                      <AutocompleteInput
                        disabled={disabled}
                        label={`งบประมาณ`}
                        defaultValue={budgetId}
                        name={`${name}.budget_id`}
                        options={budgetsOptions || []}
                        primaryKey="id"
                        getOptionLabel={(option: any) =>
                          `${option.name} - ${option.unit?.description}ละ ${option.price_per_unit} บาท`
                        }
                        rules={{ required: 'กรุณาระบุงบประมาณ' }}
                        required
                        size="small"
                        disableClearable
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    {!disabled && <DeleteButton onClick={() => remove(index)} />}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={disabled}
                        label={`จำนวน`}
                        defaultValue={field.multiplier}
                        name={`${name}.multiplier`}
                        variant="outlined"
                        size="small"
                        rules={{ required: `กรุณาระบุจำนวน${multiUnit}` }}
                        InputProps={{
                          inputComponent: NumberFormatInput as any,
                          endAdornment: <InputAdornment position="end">{multiUnit || '-'}</InputAdornment>,
                        }}
                        fullWidth
                        required
                      />
                      <ErrorMessage name={`${name}.multiplier`} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={disabled}
                        label="จำนวน"
                        defaultValue={field.unit_amount}
                        name={`${name}.unit_amount`}
                        variant="outlined"
                        size="small"
                        rules={{ required: 'กรุณาระบุจำนวน' }}
                        InputProps={{
                          inputComponent: NumberFormatInput as any,
                          endAdornment: (
                            <InputAdornment position="end">{budget?.unit?.description || '-'}</InputAdornment>
                          ),
                        }}
                        fullWidth
                        required
                      />
                      <ErrorMessage name={`${name}.unit_amount`} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <MuiTextField
                      label="รวมเงิน"
                      value={total}
                      variant="outlined"
                      disabled
                      size="small"
                      InputProps={{
                        inputComponent: NumberFormatInput as any,
                        endAdornment: <InputAdornment position="end">บาท</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      disabled={disabled}
                      label="หมายเหตุเพิ่มเติม"
                      id={`${name}.note`}
                      name={`${name}.note`}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Spacing height={12} />
                  </Grid>
                </Grid>
              </Fragment>
            );
          })}

          <Grid item xs={12}>
            <TotalBudgets control={form.control} budgets={budgets} />
          </Grid>

          {/* {!disabled && (
            <Grid item xs={12}>
              <AddButton onClick={handleAppend}>เพิ่มงบประมาณ</AddButton>
            </Grid>
          )} */}
        </Grid>
      </FormProvider>
    </InfoCard>
  );
};
