import React, { Fragment, useMemo, useState } from 'react';
import { Row } from 'react-data-grid';
import { GetNationalStrategiesComponent } from '@app/generated/graphql';
import { strategyonColumns } from './columns';
import DataGridContainer from '@app/shared/components/DataGridContainer';
import LoadMoreRowsTag from '@app/shared/components/LoadMoreRowsTag';
import { listPageContent } from '@app/shared/hoc/list-page';
import DataGridFetchMore from '@app/shared/components/DataGridFetchMore';
import { useRdgSort } from '@app/shared/hooks/useRdgSort';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { Box, Typography } from '@material-ui/core';
import LinkButton from '@app/shared/components/buttons/LinkButton';
import AddIcon from '@material-ui/icons/Add';
import PageTitle from '@app/shared/components/PageTitle';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ListIcon from '@material-ui/icons/List';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { InfoCard } from '@mystiny/ui';
import { RecursiveTreeView } from '../ProjectStrategyTreeView/TreeView';

const NationalStrategyList = () => {
  const history = useHistory();
  const [filters, setFilters] = useState<any>({
    name: '',
  });
  const [value] = useDebounce(filters, 200);
  const [offset, setOffset] = React.useState(0);
  const { handleSort, orderBy, sort } = useRdgSort();

  const strategyList = useMemo(
    () => (
      <GetNationalStrategiesComponent
        variables={{
          where: {
            id: {
              _ilike: value['id'] ? `%${value['id']}%` : undefined,
            },
            name: {
              _ilike: value['name'] ? `%${value['name']}%` : undefined,
            },
            ...(value['parent.name']
              ? {
                  parent: {
                    name: {
                      _ilike: value['parent.name'] ? `%${value['parent.name']}%` : undefined,
                    },
                  },
                }
              : {}),
          },
          orderBy: orderBy,
        }}
        fetchPolicy="cache-and-network"
      >
        {({ data, fetchMore, loading, variables }) => (
          <DataGridContainer height="80vh">
            <DataGridFetchMore
              fetchMore={fetchMore}
              variables={variables}
              offset={offset}
              setOffset={setOffset}
              sortColumn={sort?.column}
              sortDirection={sort?.direction}
              onSort={handleSort}
              rowRenderer={(props) => (
                <Row
                  {...props}
                  onDoubleClick={() => history.push(`/bof/projects/master-data/strategy-subject/${props.row.id}`)}
                />
              )}
              defaultColumnOptions={{ sortable: true, resizable: true }}
              rows={data?.national_strategies || []}
              columns={strategyonColumns}
              filters={filters}
              onFiltersChange={setFilters}
              enableFilters
            />
            <LoadMoreRowsTag loading={loading} />
          </DataGridContainer>
        )}
      </GetNationalStrategiesComponent>
    ),
    [filters, setFilters, value, offset, setOffset, handleSort, orderBy, sort, history]
  );

  const strategyTreeView = useMemo(
    () => (
      <Box padding={2}>
        <RecursiveTreeView />
      </Box>
    ),
    []
  );

  const [alignment, setAlignment] = React.useState('list');

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setAlignment(newAlignment);
  };

  return (
    <Fragment>
      <Box padding={1}>
        <PageTitle
          title={<Typography variant="h6">ยุทธศาสตร์</Typography>}
          actions={
            <Fragment>
              <LinkButton startIcon={<AddIcon />} to="/bof/projects/master-data/strategy-subject/create">
                เพิ่มข้อมูล
              </LinkButton>
            </Fragment>
          }
        />
      </Box>
      <InfoCard
        noPadding
        title={
          <Fragment>
            <ToggleButtonGroup size="medium" value={alignment} exclusive onChange={handleChange}>
              <ToggleButton value="list">
                <ListIcon fontSize="small" color="primary" />
              </ToggleButton>
              <ToggleButton value="treeview">
                <AccountTreeIcon fontSize="small" color="primary" />
              </ToggleButton>
            </ToggleButtonGroup>
          </Fragment>
        }
      >
        {alignment === 'list' ? strategyList : strategyTreeView}
      </InfoCard>
    </Fragment>
  );
};

export default listPageContent(NationalStrategyList);
