import React, { FC, memo, useCallback, useState } from 'react';
import { Frame, useBreakpoints } from '@mystiny/ui';
import BackOfficeTopBar from './BackOfficeTopBar';
import Sidebar from './BackOfficeSidebar';

const BackOfficeLayout: FC<{}> = ({ children }) => {
  const isTabletDown = useBreakpoints('down', 'sm');
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const toggleMobileNavigationActive = useCallback(
    () => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive),
    []
  );

  const topbarMarkup = <BackOfficeTopBar showNavigationToggle onNavigationToggle={toggleMobileNavigationActive} />;

  const navigationMarkup = (
    <Sidebar
      open={isTabletDown ? mobileNavigationActive : true}
      handleClose={toggleMobileNavigationActive}
      addSpaceForTopBar
    />
  );

  return (
    <Frame
      openNavigation={isTabletDown ? mobileNavigationActive : true}
      showNavigationBottom={isTabletDown}
      topBar={topbarMarkup}
      navigation={navigationMarkup}
    >
      {children}
    </Frame>
  );
};

export default memo(BackOfficeLayout);
