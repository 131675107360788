import { useInsertBudgetOneMutation, ProjectBudgetFragment } from '@app/generated/graphql';
import DescriptionIcon from '@material-ui/icons/Description';
import { pageContent } from '@app/shared/hoc/page-content';
import React from 'react';
import PageTitle from '@app/shared/components/PageTitle';
import BackButton from '@app/shared/components/buttons/BackButton';
import { InfoCard } from '@mystiny/ui';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { FormProvider, useForm } from 'react-hook-form';
import Spacing from '@app/components/common/Spacing';
import { Typography } from '@material-ui/core';
import SaveButton from '@app/shared/components/buttons/SaveButton';
import Page from '@app/components/Page';
import { Form } from '@app/views/ProjectBudget/components/Form';
import { useHistory } from 'react-router';

const AccountCreate = () => {
  const form = useForm<ProjectBudgetFragment>();
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const history = useHistory();
  const [createFn] = useInsertBudgetOneMutation({
    onCompleted: ({ insert_project_budget_one }) => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
      history.push(`/bof/projects/master-data/budgets/detail/${insert_project_budget_one?.id}`);
    },
    onError: (err: any) => {
      console.error(err);
      enqueueFailure('เกิดข้อผิดพลาด' + err);
    },
  });
  const handleSubmit = form.handleSubmit((formData: ProjectBudgetFragment) => {
    createFn({
      variables: {
        object: {
          name: formData.name,
          fiscal_year: formData.fiscal_year,
          price_per_unit: formData.price_per_unit,
          unit_id: formData.unit_id,
          unit_of_multiplier_id: formData.unit_of_multiplier_id,
        },
      },
    });
  });

  return (
    <Page
      title={
        <PageTitle
          title={
            <React.Fragment>
              <Typography variant="h6" component="div">
                <DescriptionIcon color="primary" fontSize="small" /> สร้างงบประมาณ (แตกตัวคูณ)
              </Typography>
            </React.Fragment>
          }
          actions={
            <div>
              <BackButton to="/bof/projects/master-data/budgets" />
            </div>
          }
        />
      }
    >
      <Spacing />
      <InfoCard title="ข้อมูลงบประมาณ (แตกตัวคูณ)" actionTopRight={<SaveButton onClick={handleSubmit} />}>
        <FormProvider {...form}>
          <Form mode="create" />
        </FormProvider>
      </InfoCard>
      <Spacing />
    </Page>
  );
};

export default pageContent(AccountCreate);
