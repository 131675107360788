import React from 'react';
import { Page, Content } from '@mystiny/ui';

export const pageContent = <P extends object>(Component: React.ComponentType<P>): React.FC<P> => (props) => (
  <Page>
    <Content>
      <Component {...(props as P)} />
    </Content>
  </Page>
);
