import {
  ProjectBudgetGroupFragment,
  useGetProjectBudgetGroupByPkQuery,
  GetFiscalYearsComponent,
  useGetProjectBudgetsByFiscalYearAndGroupIdQuery,
  ProjectBudgetGroupEnum,
  ProjectBudgetGroupMappingFragment,
  useReplaceProjectBudgetByFiscalYearAndGroupIdMutation,
} from '@app/generated/graphql';
import Page from '@app/components/Page';
import DescriptionIcon from '@material-ui/icons/Description';
import { pageContent } from '@app/shared/hoc/page-content';
import { CircularProgress, Grid, Typography, Box, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from '@app/shared/components/PageTitle';
import BackButton from '@app/shared/components/buttons/BackButton';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { InfoCard } from '@mystiny/ui';
import Menu from '@mui-treasury/components/menu/collapsible';
import GmailSidebarItem from '@mui-treasury/components/sidebarItem/gmail';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { DateUtils } from '@app/shared/libs/date';
import SaveButton from '@app/shared/components/buttons/SaveButton';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto',
    },
    cardHeader: {
      padding: theme.spacing(1, 2),
    },
    list: {
      width: '100%',
      height: '70vh',
      backgroundColor: theme.palette.background.paper,
      overflow: 'auto',
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
  })
);

function not(a: ProjectBudgetGroupMappingFragment[], b: ProjectBudgetGroupMappingFragment[]) {
  return a.filter((value) => b.findIndex((_) => _.id === value.id) === -1);
}

function intersection(a: ProjectBudgetGroupMappingFragment[], b: ProjectBudgetGroupMappingFragment[]) {
  return a.filter((value) => b.findIndex((_) => _.id === value.id) !== -1);
}

function union(a: ProjectBudgetGroupMappingFragment[], b: ProjectBudgetGroupMappingFragment[]) {
  return [...a, ...not(b, a)];
}

const ProjectBudgetGroupDetailForm = ({ budgetdata }: { budgetdata: ProjectBudgetGroupFragment }) => {
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const classes = useStyles();
  const [fiscalYear, setFisicalyear] = React.useState(DateUtils.getThaiFiscalYear(new Date()));
  const [checked, setChecked] = React.useState<ProjectBudgetGroupMappingFragment[]>([]);
  const [left, setLeft] = React.useState<ProjectBudgetGroupMappingFragment[]>([]);
  const [right, setRight] = React.useState<ProjectBudgetGroupMappingFragment[]>([]);

  const { data, loading, variables } = useGetProjectBudgetsByFiscalYearAndGroupIdQuery({
    variables: {
      fiscalYear,
      budgetGroupId: budgetdata?.id as ProjectBudgetGroupEnum,
    },
  });

  const [updateFn] = useReplaceProjectBudgetByFiscalYearAndGroupIdMutation({
    onCompleted: () => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
    },
    onError: (err: any) => {
      console.error(err);
      enqueueFailure('เกิดข้อผิดพลาด');
    },
  });

  useEffect(() => {
    if (data?.budgets) {
      const right = data?.budgets?.filter((budget) => budget.group_mappings.aggregate?.count);
      const left = data?.budgets?.filter((budget) => !budget.group_mappings.aggregate?.count);
      setRight(right);
      setLeft(left);
    }
  }, [data, loading, variables]);

  useEffect(() => {
    setRight([]);
    setLeft([]);
    setChecked([]);
  }, [fiscalYear]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const handleToggle = (value: ProjectBudgetGroupMappingFragment) => () => {
    const currentIndex = checked.findIndex((_) => _.id === value.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: ProjectBudgetGroupMappingFragment[]) => intersection(checked, items).length;

  const handleToggleAll = (items: ProjectBudgetGroupMappingFragment[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleSubmit = () => {
    console.log(right);
    updateFn({
      variables: {
        fiscalYear: fiscalYear,
        budgetGroupId: budgetdata.id as ProjectBudgetGroupEnum,
        budgetIds: right.map(({ id }) => id),
        budgetGroupMappingData: right.map(({ id }) => ({
          budget_id: id,
          budget_group_id: budgetdata.id as ProjectBudgetGroupEnum,
        })),
      },
    });
  };

  const customList = (title: React.ReactNode, items: ProjectBudgetGroupMappingFragment[]) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          return (
            <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.some((_) => _.id === value.id)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.name}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );
  return (
    <InfoCard title="จัดหมวดหมู่งบประมาณ" actionTopRight={<SaveButton onClick={handleSubmit} />}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <Paper variant="outlined" style={{ height: '100%' }}>
            <Box padding={1}>
              <Menu
                collapsed
                renderToggle={({ collapsed, onClick }: any) => (
                  <GmailSidebarItem
                    startIcon={collapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    label={collapsed ? 'ซ่อน' : 'แสดง'}
                    onClick={onClick}
                  />
                )}
              >
                <GetFiscalYearsComponent>
                  {({ data }) => (
                    <React.Fragment>
                      {data?.fiscal_years.map((row) => (
                        <GmailSidebarItem
                          key={row.year}
                          startIcon={<DateRangeIcon />}
                          label={row.year}
                          onClick={() => setFisicalyear(row.year)}
                          selected={fiscalYear === row.year}
                        />
                      ))}
                    </React.Fragment>
                  )}
                </GetFiscalYearsComponent>
              </Menu>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
            <Grid md={5} item>
              {customList(`ไม่อยู่ภายใต้หมวดหมู่งบประมาณ`, left)}
            </Grid>
            <Grid md={1} item>
              <Grid container direction="column" alignItems="center">
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid md={5} item>
              {customList('อยู่ภายใต้หมวดหมู่งบประมาณ', right)}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </InfoCard>
  );
};

const ProjectBudgetGroupDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetProjectBudgetGroupByPkQuery({
    variables: {
      id: id,
    },
  });

  if (loading && !data?.row) {
    return <CircularProgress />;
  }

  if (!data?.row) {
    return null;
  }

  return (
    <Page
      title={
        <PageTitle
          title={
            <React.Fragment>
              <Typography variant="h6" component="div">
                <DescriptionIcon color="primary" fontSize="small" /> หมวดค่าใช้จ่ายงบประมาณ
              </Typography>
              <Typography variant="h6" color="primary" component="div" gutterBottom>
                {data.row.description}
              </Typography>
            </React.Fragment>
          }
          actions={
            <div>
              <BackButton to="/bof/projects/master-data/budget-groups" />
            </div>
          }
        />
      }
    >
      <ProjectBudgetGroupDetailForm budgetdata={data.row} />
    </Page>
  );
};

export default pageContent(ProjectBudgetGroupDetailPage);
