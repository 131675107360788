import { UnitFragment, useInsertUnitOneMutation } from '@app/generated/graphql';
import DescriptionIcon from '@material-ui/icons/Description';
import { pageContent } from '@app/shared/hoc/page-content';
import React from 'react';
import PageTitle from '@app/shared/components/PageTitle';
import BackButton from '@app/shared/components/buttons/BackButton';
import { InfoCard } from '@mystiny/ui';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { FormProvider, useForm } from 'react-hook-form';
import Spacing from '@app/components/common/Spacing';
import { Typography } from '@material-ui/core';
import SaveButton from '@app/shared/components/buttons/SaveButton';
import Page from '@app/components/Page';
import { Form } from '@app/views/ProjectUnit/components/Form';
import { useHistory } from 'react-router';

const UnitCreate = () => {
  const form = useForm<UnitFragment>();
  const history = useHistory();
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [createFn] = useInsertUnitOneMutation({
    onCompleted: ({ unit }) => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
      history.push('/bof/projects/master-data/units/' + unit?.id);
    },
    onError: (err: any) => {
      console.error(err);
      enqueueFailure('เกิดข้อผิดพลาด' + err);
    },
  });
  const handleSubmit = form.handleSubmit((formData: UnitFragment) => {
    createFn({
      variables: {
        object: {
          description: formData.description,
          id: formData.id,
        },
      },
    });
  });

  return (
    <Page
      title={
        <PageTitle
          title={
            <React.Fragment>
              <Typography variant="h6" component="div">
                <DescriptionIcon color="primary" fontSize="small" /> สร้างหน่วย
              </Typography>
            </React.Fragment>
          }
          actions={
            <div>
              <BackButton to="/bof/projects/master-data/units" />
            </div>
          }
        />
      }
    >
      <Spacing />
      <InfoCard title="เพิ่มหน่วย" actionTopRight={<SaveButton onClick={handleSubmit} />}>
        <FormProvider {...form}>
          <Form mode="create" />
        </FormProvider>
      </InfoCard>
      <Spacing />
    </Page>
  );
};

export default pageContent(UnitCreate);
