import React, { FC, Fragment } from 'react';
import { useGetFeatureByPkQuery } from '../generated/graphql';

export interface FeatureProps {
  name: string;
  activeComponent?: React.ComponentType<{}>;
  inactiveComponent?: React.ComponentType<{}>;
}

const noop = () => null;

export const Feature: FC<FeatureProps> = ({ name, activeComponent, children, inactiveComponent = noop }) => {
  const { data } = useGetFeatureByPkQuery({
    variables: { id: name },
  });

  const Component = data?.row?.enable ? activeComponent : inactiveComponent;

  if (!data?.row?.enable) {
    const InactiveComponent = inactiveComponent;
    return <InactiveComponent />;
  }

  return <Fragment>{(activeComponent && Component) ? <Component /> : children}</Fragment>;
};
