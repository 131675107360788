import { format, addMonths, differenceInMonths } from 'date-fns';
import { setDay, setMonth, setYear } from 'date-fns/fp';
import { flow } from 'lodash/fp';

export const daysThai = ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'];

export const months = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม',
];

export const monthsShort = [
  'ม.ค.',
  'ก.พ.',
  'มี.ค',
  'เม.ย.',
  'พ.ค.',
  'มิ.ย.',
  'ก.ค.',
  'ส.ค.',
  'ก.ย.',
  'ต.ค.',
  'พ.ย.',
  'ธ.ค.',
];

export class DateUtils {
  public static toThaiDateStyles(input: string | Date) {
    const date = typeof input === 'string' ? new Date(input) : input;
    return `วัน${daysThai[date.getDay()]} ที่ ${date.getDate()} ${months[date.getMonth()]} พ.ศ. ${date.getFullYear()}`;
  }
  /**
   * วัน/เดือน/ปี ค.ศ.
   * @example 20/01/2020
   */
  public static toDate(input: string | Date): string {
    const date = typeof input === 'string' ? new Date(input) : input;
    return format(date, 'dd/MM/yyyy');
  }

  /**
   * วัน/เดือน/ปี พ.ศ.
   * @example 20/01/2563
   */
  public static toThaiDate(input: string | Date): string | undefined {
    const date = typeof input === 'string' ? new Date(input) : input;
    if (!date || !(date instanceof Date)) {
      return undefined;
    }
    const year = date.getFullYear() + 543;
    return format(date, 'dd/MM') + `/${year}`;
  }

  /**
   * HH:mm
   * @example 13:02
   */
  public static toTime(input: string | Date, formatString: string = 'HH:mm'): string {
    const date = typeof input === 'string' ? new Date(input) : input;
    return format(date, formatString);
  }

  /**
   * ปี พ.ศ.
   */
  public static getBuddishYear(input?: string | Date): number {
    if (!input) {
      return new Date().getFullYear() + 543;
    }
    const date = typeof input === 'string' ? new Date(input) : input;
    return date?.getFullYear() + 543;
  }

  /**
   * ปีงบประมาณไทย
   * @param input
   */
  public static getThaiFiscalYear(input: string | Date): number {
    // IIF(DATEPART(month, @inputDate) < 10, DATEPART(year, @inputDate), DATEPART(year, @inputDate) + 1);
    const date = typeof input === 'string' ? new Date(input) : input;
    // แปลงจาก Index ให้เป็นลำดับเดือน 1 ถึง 12
    const month = date.getMonth() + 1;
    const thaiYear = date.getFullYear() + 543;

    return month < 10 ? thaiYear : thaiYear + 1;
  }

  public static getStartThatFiscalYearDate(input: string | Date): Date {
    const date = typeof input === 'string' ? new Date(input) : input;
    const fiscalYear = this.getThaiFiscalYear(input);
    return flow(setDay(1), setMonth(9), setYear(fiscalYear - 543 - 1))(date);
  }

  public static getEndThatFiscalYearDate(input: string | Date): Date {
    const date = typeof input === 'string' ? new Date(input) : input;
    const fiscalYear = this.getThaiFiscalYear(input);
    return flow(setDay(30), setMonth(8), setYear(fiscalYear - 543))(date);
  }

  public static toThaiDatetime(input: string | Date): string | undefined {
    const date = typeof input === 'string' ? new Date(input) : input;
    if (!date || !(date instanceof Date)) {
      return undefined;
    }
    return this.toThaiDate(input) + ' ' + this.toTime(input, 'HH:mm:ss');
  }

  public static getAllMonthBetween(start: string | Date, end: string | Date): Date[] {
    const startDate = typeof start === 'string' ? new Date(start) : start;
    const endDate = typeof end === 'string' ? new Date(end) : end;
    const months = differenceInMonths(endDate, startDate) || 0;

    return [
      ...Array(months + 1)
        .fill('0')
        .map((_, i) => i),
    ].map((i) => addMonths(startDate, i));
  }
}
