import React, { Fragment, useCallback, useState } from 'react';

export function ToggleState({
  children,
}: {
  children: (props: { isOpen: boolean; toggle: () => void; open: () => void; close: () => void }) => any;
}) {
  const [openState, setOpen] = useState(false);

  const toggle = useCallback(() => setOpen((open) => !open), []);
  const open = useCallback(() => setOpen(true), []);
  const close = useCallback(() => setOpen(false), []);

  return (
    <Fragment>
      {children({
        isOpen: openState,
        toggle,
        open,
        close,
      })}
    </Fragment>
  );
}
