import { GetUsersComponent, GetUsersQuery } from '@app/generated/graphql';
import DataGridContainer from '@app/shared/components/DataGridContainer';
import NoRows from '@app/components/common/NoRows';
import { CircularProgress } from '@material-ui/core';
import React from 'react';
import DataGrid, { Column, Row } from 'react-data-grid';
import { useHistory } from 'react-router-dom';

const columns: Column<GetUsersQuery['rows'][0]>[] = [
  {
    key: 'display_name',
    name: 'ชื่อผู้ใช้',
    minWidth: 200,
    resizable: true,
  },
  {
    key: 'account.email',
    name: 'e-mail',
    minWidth: 200,
    resizable: true,
    formatter: ({ row }) => <React.Fragment>{row.account?.email}</React.Fragment>,
  },
  {
    key: 'tel',
    name: 'เบอร์โทรศัพท์',
    minWidth: 150,
    resizable: true,
    formatter: ({ row }) => <React.Fragment>{row.tel || '-'}</React.Fragment>,
  },
  {
    key: 'account.default_role',
    name: 'Role',
    minWidth: 200,
    resizable: true,
    formatter: ({ row }) => <React.Fragment>{row.account?.default_role}</React.Fragment>,
  },
];

const UserListInOrganization = ({ organizationId }: { organizationId: string }) => {
  const history = useHistory();
  return (
    <GetUsersComponent
      variables={{ where: { organization_id: { _eq: organizationId } } }}
      fetchPolicy="cache-and-network"
    >
      {({ data, loading }) => (
        <DataGridContainer height={220}>
          <DataGrid
            defaultColumnOptions={{ sortable: true }}
            rows={data?.rows || []}
            columns={columns}
            rowRenderer={(props) => <Row {...props} onDoubleClick={() => history.push(`/bof/users/${props.row.id}`)} />}
            className="fill-grid rdg-light"
            emptyRowsRenderer={() => (loading ? <CircularProgress /> : <NoRows />)}
          />
        </DataGridContainer>
      )}
    </GetUsersComponent>
  );
};

export default UserListInOrganization;
