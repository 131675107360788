import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { UniqueIdFactoryContext, UniqueIdFactory, globalIdGeneratorFactory } from '@mystiny/ui';
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import thLocale from 'date-fns/locale/th';
import { FC, ReactNode } from 'react';
import GlobalStyles from './GlobalStyles';
import { theme } from '../theme/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FirebaseProvider } from '@app/firebase/firebase_provider';
import ApolloWrapper from '@app/global/ApolloWrapper';

const client = new QueryClient();
const uniqueIdFactory = new UniqueIdFactory(globalIdGeneratorFactory);

export const AppWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <QueryClientProvider client={client}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={thLocale}>
          <UniqueIdFactoryContext.Provider value={uniqueIdFactory}>
            <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <FirebaseProvider>
                <ApolloWrapper>{children}</ApolloWrapper>
              </FirebaseProvider>
            </SnackbarProvider>
            <GlobalStyles />
          </UniqueIdFactoryContext.Provider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
