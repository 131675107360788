import {
  useGetTargetGroupByPkQuery,
  TargetGroupFragment,
  useUpdateTargetGroupByPkMutation,
} from '@app/generated/graphql';
import Page from '@app/components/Page';
import DescriptionIcon from '@material-ui/icons/Description';
import { pageContent } from '@app/shared/hoc/page-content';
import { CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from '@app/shared/components/PageTitle';
import BackButton from '@app/shared/components/buttons/BackButton';
import { InfoCard } from '@mystiny/ui';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { FormProvider, useForm } from 'react-hook-form';
import Spacing from '@app/components/common/Spacing';
import SaveButton from '@app/shared/components/buttons/SaveButton';
import { Form } from './components/Form';

const TargetGroupDetail = ({ data }: { data: TargetGroupFragment }) => {
  const form = useForm<TargetGroupFragment>({
    defaultValues: data,
  });
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [updateFn, { loading }] = useUpdateTargetGroupByPkMutation({
    onCompleted: () => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
    },
    onError: (err: any) => {
      console.error(err);
      enqueueFailure('เกิดข้อผิดพลาด');
    },
  });

  const handleSubmit = form.handleSubmit((formData: TargetGroupFragment) => {
    updateFn({
      variables: {
        id: data.id,
        _set: {
          id: formData.id,
          description: formData.description,
        },
      },
    });
  });

  return (
    <Page
      title={
        <PageTitle
          title={
            <React.Fragment>
              <Typography variant="h6" component="div">
                <DescriptionIcon color="primary" fontSize="small" /> กลุ่มเป้าหมาย
              </Typography>
              <Typography variant="h6" color="primary" component="div" gutterBottom>
                {data.description}
              </Typography>
            </React.Fragment>
          }
          actions={
            <div>
              <BackButton to="/bof/projects/master-data/target-groups" />
            </div>
          }
        />
      }
    >
      <InfoCard title="ข้อมูลกลุ่มเป้าหมาย" actionTopRight={<SaveButton disabled={loading} onClick={handleSubmit} />}>
        <FormProvider {...form}>
          <Form mode="update" />
        </FormProvider>
      </InfoCard>
      <Spacing />
    </Page>
  );
};

const TargetGroupDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetTargetGroupByPkQuery({
    variables: {
      id: id,
    },
  });

  if (loading && !data?.row) {
    return <CircularProgress />;
  }

  if (!data?.row) {
    return null;
  }

  return (
    <React.Fragment>
      <TargetGroupDetail data={data?.row} />
    </React.Fragment>
  );
};

export default pageContent(TargetGroupDetailPage);
