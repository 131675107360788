import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { SectionHeader } from '@app/components/molecules';
import { Section } from '@app/components/organisms';

const useStyles = makeStyles((theme) => ({
  root: {},
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: `calc(100vh - ${(theme.mixins.toolbar['@media (min-width:600px)'] as any).minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  label: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
}));

const ErrorFallback = ({ error, componentStack, resetErrorBoundary }: FallbackProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Section className={classes.section}>
        <div className={classes.formContainer}>
          <SectionHeader
            label="Oh no!"
            title="Something went wrong"
            subtitle={
              <span>
                เกิดข้อผิดพลาดขึ้น แก้ปัญหาเบื้องต้นโดย Refresh หน้าเว็บไซต์ หากยังพบเจอปัญหา
                โปรดถ่ายรูปหน้าจอส่งให้ทางเจ้าหน้าที่กองทุน เพื่อทำการตรวจสอบ และแก้ไขต่อไป
              </span>
            }
            titleProps={{
              variant: 'h3',
            }}
            labelProps={{
              color: 'secondary',
              className: classes.label,
              variant: 'h5',
            }}
            ctaGroup={[
              <Button size="large" variant="contained" color="primary" onClick={resetErrorBoundary}>
                Try Again
              </Button>,
            ]}
            disableGutter
          />
        </div>
      </Section>
    </div>
  );
};

export default ErrorFallback;
