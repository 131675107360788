import Page from '@app/components/Page';
import { GetOrganizationsComponent, useGetProjectSummaryQuery } from '@app/generated/graphql';
import { listPageContent } from '@app/shared/hoc/list-page';
import { useTypography } from '@app/shared/styles/typography';
import { Grid, Typography } from '@material-ui/core';
import { InfoCard } from '@mystiny/ui';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import Select from 'react-select';
import Spacing from '@app/components/common/Spacing';

const shortenProjectName = (name: string) => {
  if (name.length > 10) {
    return [name.substr(0, 10), '...'].join('');
  }
  return name;
};

const ProjectBudgetComparison = () => {
  const typographyClasses = useTypography();
  const [selectedOrgId, setSelectedOrgId] = useState<string | null>(null);
  const { data } = useGetProjectSummaryQuery({
    fetchPolicy: 'cache-and-network',
  });

  const budgetSummary = useMemo(() => {
    return data?.projects
      .reduce<any[]>((prev, project) => {
        const spendPlan = project.budget_summary.reduce((prev, budget) => prev + budget.total_spend_plan, 0);
        const spend = project.budget_summary.reduce((prev, budget) => prev + budget.total_spend, 0);
        const workloadPlan = project.workload_summary.reduce((prev, workload) => 100, 0);
        const workload = project.workload_summary.reduce((prev, workload) => {
          const a = (workload.total_workload / workload.total_workload_plan) * 100;
          if (Number.isFinite(a)) {
            return prev + a;
          }
          return prev;
        }, 0);
        return [
          ...prev,
          {
            id: project.id,
            organization: project.organization,
            name: project.title,
            spendPlan,
            spend,
            workloadPlan,
            workload,
          },
        ];
      }, [])
      .filter(({ organization }) => !selectedOrgId || (selectedOrgId && organization.id === selectedOrgId));
  }, [data, selectedOrgId]);

  const handleChange = useCallback((data) => {
    setSelectedOrgId(data?.value || null);
  }, []);

  return (
    <Page
      title={
        <Typography variant="h5" color="primary" className={typographyClasses.pageTitle}>
          รายงานสรุปแผน/ผล ของแต่ละหน่วยงาน
        </Typography>
      }
    >
      <GetOrganizationsComponent fetchPolicy="cache-and-network">
        {({ data, loading }) => (
          <Fragment>
            <Typography variant="h6">หน่วยงานรับผิดชอบโครงการ</Typography>
            <Select
              isClearable
              options={data?.rows.map(({ id, short_name }) => ({
                label: short_name,
                value: id,
              }))}
              name="organization"
              onChange={handleChange}
              components={{}}
            ></Select>

            <Spacing />

            <Typography variant="h6">
              {data?.rows.find(({ id }) => id === selectedOrgId)?.name || 'แสดงข้อมูลทุกหน่วยงาน'}
            </Typography>
          </Fragment>
        )}
      </GetOrganizationsComponent>

      <Grid container>
        <Grid item md={6}>
          <InfoCard title="รายงานสรุปแผน/ผล การใช้งบประมาณ">
            <BarChart
              width={700}
              height={480}
              data={budgetSummary}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" tickFormatter={shortenProjectName} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="spendPlan" fill="#82ca9d" name="แผน" onClick={console.log} />
              <Bar dataKey="spend" fill="#8884d8" name="ผล" />
            </BarChart>
          </InfoCard>
        </Grid>
        <Grid item md={6}>
          <InfoCard title="รายงานสรุปแผน/ผล ตามเป้าหมายผลผลิต (Percentage)">
            <BarChart
              width={700}
              height={480}
              data={budgetSummary}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" tickFormatter={shortenProjectName} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="workloadPlan" fill="#82ca9d" name="แผน" />
              <Bar dataKey="workload" fill="#8884d8" name="ผล" />
            </BarChart>
          </InfoCard>
        </Grid>
      </Grid>
    </Page>
  );
};

export default listPageContent(ProjectBudgetComparison);
