import { withIfUser } from '@app/components/common/Routes';
import GoogleAnalytics from '@app/views/Admin/GoogleAnalytics';
import { memo } from 'react';

export default memo(
  withIfUser(GoogleAnalytics)({
    condition: ({ roles }) => roles.includes('sysadmin'),
    redirect: true,
    redirectTo: '/unauthorized',
  })
);
