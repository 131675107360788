import { Button } from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import * as XLSX from 'xlsx';
import React, { useCallback } from 'react';
import { mapper } from './mapper';

export const ExportButton = ({ data, name }: { data: any; name?: string }) => {
  const handleClick = useCallback(() => {
    const dataWS = XLSX.utils.json_to_sheet(mapper(data));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, dataWS);
    XLSX.writeFile(wb, `${name}.xlsx`);
  }, [data, name]);

  return (
    <Button variant="contained" color="primary" startIcon={<ImportExportIcon />} onClick={handleClick}>
      Export ข้อมูล (Excel)
    </Button>
  );
};
