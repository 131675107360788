import React from 'react';
import TextFilter from '@app/components/data-grid/TextFilter';
import { ProjectBudgetGroupFragment } from '@app/generated/graphql';
import { Column } from 'react-data-grid';

export const budgetGroupColumns: Column<ProjectBudgetGroupFragment>[] = [
  {
    key: 'id',
    name: 'ID',
    width: 200,
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'description',
    name: 'ชื่อหมวดค่าใช้จ่าย',
    filterRenderer: (p) => <TextFilter {...p} />,
  },
];
