import { setDate, subMonths, endOfMonth } from 'date-fns';

/**
 * Get Date Range ที่สามารถทำรายการรายรับ-รายจ่ายได้ โดยมีเงื่อนไขว่า
 * จะไม่สามารถทำรายการย้อนหลังได้ ซึ่งสามารถแก้ไขได้ล่าช้าที่สุดคือ วันที่ 5 ของเดือนถัดไป
 * @returns
 */
export function getDateRange(date = new Date()) {
  const now = date;
  const dayOfMonth = now.getDate();
  const minDate = dayOfMonth > 5 ? setDate(now, 1) : subMonths(setDate(now, 1), 1);
  const maxDate = endOfMonth(now);

  return {
    minDate,
    maxDate,
  };
}
