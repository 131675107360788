import React from 'react';
import { CircularProgress, Box } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => ({
  circularProgress: {
    color: theme.palette.secondary.main,
  },
});

export interface ButtonCircularProgressProps {
  size?: number;
  classes: Record<string, any>;
}

const ButtonCircularProgress = (props: ButtonCircularProgressProps) => {
  const { size, classes } = props;
  return (
    <Box color="secondary.main" pl={1.5} display="flex">
      <CircularProgress
        size={size ? size : 24}
        thickness={size ? (size / 5) * 24 : 5}
        className={classes.circularProgress}
      />
    </Box>
  );
};

export default withStyles(styles, { withTheme: true })(ButtonCircularProgress);
