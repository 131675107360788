import { ErrorMessage, TextField } from '@aginix/mui-react-hook-form-input';
import {
  LedgerFragment,
  LedgerLedgerStatusEnum,
  GetLedgerByPkDocument,
  useApproveLedgerByPkMutation,
  LedgerLedgerApproveEnum,
  GetLedgerLogsByPkDocument,
} from '@app/generated/graphql';
import ButtonCircularProgress from '@app/shared/components/ButtonCircularProgress';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { useColors } from '@app/shared/styles';
import { Button, FormControl, Grid } from '@material-ui/core';
import { FormLayout, FormLayoutGroup, InfoCard } from '@mystiny/ui';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { prompt } from '@app/shared/libs';

export interface LedgerWorkflowProps {
  ledger: LedgerFragment;
}

type FormState = {
  comment: string;
};

export const LedgerVerification = ({ ledger }: LedgerWorkflowProps) => {
  const { enqueueFailure, enqueueSuccess } = useResponseBar();
  const [loadingStatus, setLoadingStatus] = useState<LedgerLedgerStatusEnum | null>(null);
  const classes = useColors();
  const form = useForm<FormState>({
    defaultValues: {
      comment: '',
    },
  });

  const [approveFn, { loading }] = useApproveLedgerByPkMutation({
    onCompleted: () => {
      enqueueSuccess('ปรับสถานะสำเร็จ');
      setLoadingStatus(null);
      form.reset({
        comment: '',
      });
    },
    onError: () => {
      enqueueFailure('ไม่สามารถบันทึกข้อมูลได้');
      setLoadingStatus(null);
    },
    refetchQueries: [
      {
        query: GetLedgerByPkDocument,
        variables: {
          id: ledger.id,
        },
      },
      {
        query: GetLedgerLogsByPkDocument,
        variables: {
          ledgerId: ledger.id,
        },
      },
    ],
  });

  const handleApprove = form.handleSubmit((formData: FormState) => {
    prompt(
      'ท่านแน่ใจที่จะปรับสถานะข้อมูล "อนุมัติ" ใช่หรือไม่?\nซึ่งข้อมูลจะไม่สามารถถูกแก้ไขหรือย้อนกลับได้\n\nโปรดพิมพ์คำว่า "yes" เพื่อยืนยันการปรับสถานะ',
      {
        value: 'yes',
        callback: () => {
          approveFn({
            variables: {
              data: {
                ledgerId: ledger.id,
                note: formData.comment,
                statusId: LedgerLedgerApproveEnum.Approved,
              },
            },
          });
          setLoadingStatus(LedgerLedgerStatusEnum.Approved);
        },
      }
    );
  });

  const handleReject = form.handleSubmit((formData: FormState) => {
    prompt(
      'ท่านแน่ใจที่จะปรับสถานะข้อมูล "ไม่อนุมัติ" ใช่หรือไม่?\nซึ่งข้อมูลจะไม่สามารถถูกแก้ไขหรือย้อนกลับได้\n\nโปรดพิมพ์คำว่า "yes" เพื่อยืนยันการปรับสถานะ',
      {
        value: 'yes',
        callback: () => {
          approveFn({
            variables: {
              data: {
                ledgerId: ledger.id,
                note: formData.comment,
                statusId: LedgerLedgerApproveEnum.Rejected,
              },
            },
          });
          setLoadingStatus(LedgerLedgerStatusEnum.Rejected);
        },
      }
    );
  });

  const handleChangeRequest = form.handleSubmit((formData: FormState) => {
    prompt(
      'ท่านแน่ใจที่จะปรับสถานะข้อมูล "เปลี่ยนแปลงข้อมูล" ใช่หรือไม่?\nซึ่งข้อมูลจะไม่สามารถถูกแก้ไขหรือย้อนกลับได้\n\nโปรดพิมพ์คำว่า "yes" เพื่อยืนยันการปรับสถานะ',
      {
        value: 'yes',
        callback: () => {
          approveFn({
            variables: {
              data: {
                ledgerId: ledger.id,
                note: formData.comment,
                statusId: LedgerLedgerApproveEnum.ChangeRequest,
              },
            },
          });
          setLoadingStatus(LedgerLedgerStatusEnum.ChangeRequest);
        },
      }
    );
  });

  return (
    <InfoCard title="ปรับสถานะข้อมูลค่าใช้จ่าย" subheader='มองเห็นเฉพาะสิทธิ์ "ระดับหัวหน้า"'>
      <FormProvider {...form}>
        <FormLayout>
          <FormLayoutGroup>
            <FormControl fullWidth error={!!form.errors['comment']}>
              <TextField
                name="comment"
                label="หมายเหตุ"
                rows={3}
                variant="outlined"
                rules={{ required: 'กรุณาระบุหมายเหตุ' }}
                multiline
                fullWidth
              />
              <ErrorMessage name="comment" />
            </FormControl>
          </FormLayoutGroup>
          <FormLayoutGroup>
            <Grid container justify="space-between" spacing={1}>
              <Grid item xs={12} md={3}>
                <Button
                  disabled={loading || ledger.status_id === LedgerLedgerStatusEnum.Rejected}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  classes={{ containedPrimary: classes.rejected }}
                  onClick={handleReject}
                >
                  ไม่อนุมัติ {loadingStatus === LedgerLedgerStatusEnum.Rejected && <ButtonCircularProgress />}
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  disabled={loading || [LedgerLedgerStatusEnum.ChangeRequest].includes(ledger.status_id)}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  classes={{ containedPrimary: classes.changeRequest }}
                  onClick={handleChangeRequest}
                >
                  ร้องขอการเปลี่ยนแปลง{' '}
                  {loadingStatus === LedgerLedgerStatusEnum.ChangeRequest && <ButtonCircularProgress />}
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  disabled={loading || ledger.status_id === LedgerLedgerStatusEnum.Approved}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  classes={{ containedPrimary: classes.approved }}
                  onClick={handleApprove}
                >
                  อนุมัติ {loadingStatus === LedgerLedgerStatusEnum.Approved && <ButtonCircularProgress />}
                </Button>
              </Grid>
            </Grid>
          </FormLayoutGroup>
        </FormLayout>
      </FormProvider>
    </InfoCard>
  );
};
