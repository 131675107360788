import { brown, lightBlue, orange } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useColors = makeStyles((theme) => ({
  new: {
    backgroundColor: orange.A400,
    '&:hover': {
      backgroundColor: orange.A700,
    }
  },
  approved: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    }
  },
  rejected: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    }
  },
  cancelled: {
    backgroundColor: theme.palette.grey.A400,
    '&:hover': {
      backgroundColor: theme.palette.grey[700],
    }
  },
  changeRequest: {
    backgroundColor: brown.A400,
    '&:hover': {
      backgroundColor: brown.A700,
    }
  },
  submitted: {
    backgroundColor: lightBlue.A400,
    '&:hover': {
      backgroundColor: lightBlue.A700,
    }
  },
}));
