import React from 'react';
import { Box } from '@material-ui/core';
import { withModalPage } from '@app/shared/hoc/modal-page';

export const listPageContent = <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
  withModalPage((props) => (
    <Box p={{ xs: 0, sm: 2 }}>
      <Component {...(props as P)} />
    </Box>
  ));
