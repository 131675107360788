import api from '@app/libs/api';
import { useQuery } from '@tanstack/react-query';

export function useCurrentUser() {
  const { data } = useQuery({
    queryKey: ['currentUser'],
    queryFn: async () => {
      return api.getCurrentUser();
    },
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  return data;
}

useCurrentUser.getQueryKey = () => ['currentUser'];
