import { ErrorMessage, TextField } from '@aginix/mui-react-hook-form-input';
import AddButton from '@app/shared/components/buttons/AddButton';
import EditButton from '@app/shared/components/buttons/EditButton';
import SaveButton from '@app/shared/components/buttons/SaveButton';
import DeleteButton from '@app/shared/components/buttons/DeleteButton';
import { get } from 'lodash';
import { useProjectEditable } from '@app/views/Project/contexts/ProjectContext';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import React, { memo, Fragment, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Spacing from '@app/components/common/Spacing';
import {
  ProjectObjectiveFragment,
  ProjectIndicatorFragment,
  ProjectUnitEnum,
  ProjectAchievementFragment,
  GetUnitsComponent,
  useGetProjectObjectivesByProjectIdQuery,
  GetProjectObjectivesByProjectIdDocument,
  useInsertProjectObjectiveOneMutation,
  useUpdateProjectObjectiveByPkMutation,
  useDeleteProjectObjectiveByPkMutation,
  useInsertProjectIndicatorOneMutation,
  useUpdateProjectIndicatorByPkMutation,
  useDeleteProjectIndicatorByPkMutation,
  useInsertProjectAchievementOneMutation,
  useUpdateProjectAchievementByPkMutation,
  useDeleteProjectAchievementByPkMutation,
  ProjectIndicatorTypeEnum,
} from '@app/generated/graphql';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { InfoCard } from '@mystiny/ui';
import AutocompleteInput from '@app/shared/components/AutocompleteInput';
import { ToggleState } from '@app/components/ToggleState';
import { createNumberFormatInput } from '@app/components/NumberFormatInput';

const NumberFormatInput = createNumberFormatInput({
  allowNegative: false,
  thousandSeparator: true,
  allowEmptyFormatting: false,
  min: 0,
});

export interface ProjectObjectivesFormProps {
  projectId: string;
  render?: (props: { onSubmit: () => any }) => React.ReactNode;
}

const ObjectiveForm = ({ errors }: any) => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <FormControl fullWidth error={!!get(errors, `name`)}>
        <TextField
          error={!!get(errors, `name`)}
          label="รายละเอียดวัตถุประสงค์"
          name={`name`}
          variant="outlined"
          size="small"
          rules={{ required: 'กรุณาระบุวัตถุประสงค์' }}
          fullWidth
          required
        />
        <ErrorMessage name={`name`} />
      </FormControl>
    </Grid>
  </Grid>
);

const AddObjectiveDialog = (props: { open: boolean; onClose: () => any; projectId: string }) => {
  const { open, onClose, projectId } = props;
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [insertFn, { loading }] = useInsertProjectObjectiveOneMutation({
    onCompleted: () => {
      onClose();
      enqueueSuccess('เพิ่มวัตถุประสงค์โครงการสำเร็จ');
    },
    onError: (err) => {
      enqueueFailure('เกิดข้อผิดพลาด: ' + err.name);
    },
    refetchQueries: [
      {
        query: GetProjectObjectivesByProjectIdDocument,
        variables: {
          projectId,
        },
      },
    ],
  });

  const form = useForm<{ name: string }>({
    defaultValues: {
      name: '',
    },
  });

  const handleSubmit = form.handleSubmit((formData) => {
    insertFn({
      variables: {
        data: {
          name: formData.name,
          project_id: projectId,
          sequence: new Date().getTime(),
        },
      },
    });
  });

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="add-objective-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle id="add-objective-dialog-title">เพิ่มวัตถุประสงค์โครงการ</DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <form id="objective-form" noValidate onSubmit={handleSubmit}>
            <ObjectiveForm errors={form.errors} />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
          Cancel
        </Button>
        <AddButton onClick={handleSubmit} disabled={loading} type="submit" form="objective-form" />
      </DialogActions>
    </Dialog>
  );
};

const EditObjectiveDialog = (props: { onClose: () => any; projectId: string; objective: ProjectObjectiveFragment }) => {
  const { onClose, projectId, objective } = props;
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [updateFn, { loading }] = useUpdateProjectObjectiveByPkMutation({
    onCompleted: () => {
      onClose();
      enqueueSuccess('แก้ไขวัตถุประสงค์โครงการสำเร็จ');
    },
    onError: (err) => {
      enqueueFailure('เกิดข้อผิดพลาด: ' + err.name);
    },
    refetchQueries: [
      {
        query: GetProjectObjectivesByProjectIdDocument,
        variables: {
          projectId,
        },
      },
    ],
  });
  const form = useForm<{ name: string }>({
    defaultValues: {
      name: objective.name || '',
    },
  });

  const handleSubmit = form.handleSubmit((formData) => {
    updateFn({
      variables: {
        objectiveId: objective.id,
        data: {
          name: formData.name,
        },
      },
    });
  });

  return (
    <Dialog open={!!objective} onClose={onClose} aria-labelledby="add-objective-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle id="add-objective-dialog-title">แก้ไขวัตถุประสงค์โครงการ</DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <form id="objective-form" noValidate onSubmit={handleSubmit}>
            <ObjectiveForm errors={form.errors} />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
          Cancel
        </Button>
        <SaveButton onClick={handleSubmit} disabled={loading} type="submit" form="objective-form" />
      </DialogActions>
    </Dialog>
  );
};

const IndicatorForm = ({ errors }: { errors: any }) => (
  <Grid container item xs={12}>
    <Grid item xs={12}>
      <FormControl fullWidth error={!!errors[`name`]}>
        <TextField
          error={!!errors[`name`]}
          label="ชื่อตัวชี้วัด"
          name={`name`}
          variant="outlined"
          size="small"
          rules={{ required: 'กรุณาระบุชื่อตัวชี้วัด' }}
          fullWidth
          required
        />
        <ErrorMessage name={`name`} />
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      <FormControl fullWidth error={!!get(errors, `amount`)}>
        <TextField
          error={!!errors[`amount`]}
          label={`จำนวนเป้าหมาย`}
          name={`amount`}
          variant="outlined"
          rules={{ required: 'กรุณาระบุจำนวนเป้าหมาย' }}
          InputProps={{
            inputComponent: NumberFormatInput as any,
          }}
          size="small"
          required
        />
        <ErrorMessage name={`amount`} />
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      <GetUnitsComponent>
        {({ data, loading }) => (
          <FormControl fullWidth error={!!get(errors, `unit_id`)}>
            <AutocompleteInput
              label={`หน่วยนับ`}
              name={`unit_id`}
              options={data?.rows || []}
              loading={loading}
              primaryKey="id"
              getOptionLabel={(option: any) => `${option.description}`}
              rules={{ required: 'กรุณาระบุหน่วยนับ' }}
              required
              size="small"
              disableClearable
            />
            <ErrorMessage name={`unit_id`} />
          </FormControl>
        )}
      </GetUnitsComponent>
    </Grid>
  </Grid>
);

const AddIndicatorDialog = (props: {
  open: boolean;
  onClose: () => any;
  projectId: string;
  objectiveId: string;
  type: ProjectIndicatorTypeEnum;
}) => {
  const { open, onClose, projectId, type, objectiveId } = props;
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [insertFn, { loading }] = useInsertProjectIndicatorOneMutation({
    onCompleted: () => {
      onClose();
      enqueueSuccess('เพิ่มวัตถุประสงค์โครงการสำเร็จ');
    },
    onError: (err) => {
      enqueueFailure('เกิดข้อผิดพลาด: ' + err.name);
    },
    refetchQueries: [
      {
        query: GetProjectObjectivesByProjectIdDocument,
        variables: {
          projectId,
        },
      },
    ],
  });

  const form = useForm<{ name: string; amount: number; unit_id: ProjectUnitEnum }>({
    defaultValues: {
      name: '',
    },
  });

  const handleSubmit = form.handleSubmit((formData) => {
    insertFn({
      variables: {
        data: {
          name: formData.name,
          project_id: projectId,
          project_objective_id: objectiveId,
          amount: formData.amount,
          unit_id: formData.unit_id,
          sequence: new Date().getTime(),
          indicator_type_id: type,
        },
      },
    });
  });

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="add-indicator-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle id="add-indicator-dialog-title">เพิ่มตัวชี้วัดโครงการ</DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <form id="indicator-form" noValidate onSubmit={handleSubmit}>
            <IndicatorForm errors={form.errors} />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
          Cancel
        </Button>
        <AddButton onClick={handleSubmit} disabled={loading} type="submit" form="indicator-form" />
      </DialogActions>
    </Dialog>
  );
};

const EditIndicatorDialog = (props: { onClose: () => any; projectId: string; indicator: ProjectIndicatorFragment }) => {
  const { onClose, projectId, indicator } = props;
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [updateFn, { loading }] = useUpdateProjectIndicatorByPkMutation({
    onCompleted: () => {
      onClose();
      enqueueSuccess('แก้ไขวัตถุประสงค์โครงการสำเร็จ');
    },
    onError: (err) => {
      enqueueFailure('เกิดข้อผิดพลาด: ' + err.name);
    },
    refetchQueries: [
      {
        query: GetProjectObjectivesByProjectIdDocument,
        variables: {
          projectId,
        },
      },
    ],
  });
  const form = useForm<ProjectIndicatorFragment>({
    defaultValues: indicator,
  });

  const handleSubmit = form.handleSubmit((formData) => {
    updateFn({
      variables: {
        indicatorId: indicator.id,
        data: {
          name: formData.name,
          amount: formData.amount,
          unit_id: formData.unit_id,
          project_id: projectId,
        },
      },
    });
  });

  return (
    <Dialog open={!!indicator} onClose={onClose} aria-labelledby="edit-indicator-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle id="edit-indicator-dialog-title">แก้ไขวัตถุประสงค์โครงการ</DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <form id="indicator-form" noValidate onSubmit={handleSubmit}>
            <IndicatorForm errors={form.errors} />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
          Cancel
        </Button>
        <SaveButton onClick={handleSubmit} disabled={loading} type="submit" form="indicator-form" />
      </DialogActions>
    </Dialog>
  );
};

const AchievementForm = ({ errors }: any) => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <FormControl fullWidth error={!!get(errors, `name`)}>
        <TextField
          error={!!get(errors, `name`)}
          label="รายละเอียดวัตถุประสงค์"
          name={`name`}
          variant="outlined"
          size="small"
          rules={{ required: 'กรุณาระบุวัตถุประสงค์' }}
          fullWidth
          required
        />
        <ErrorMessage name={`name`} />
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      <FormControl fullWidth error={!!get(errors, `kpi`)}>
        <TextField
          error={!!get(errors, `kpi`)}
          label="ตัวชี้วัดผลสัมฤทธิ์"
          name={`kpi`}
          variant="outlined"
          size="small"
          rules={{ required: 'กรุณาระบุตัวชี้วัดผลสัมฤทธิ์' }}
          fullWidth
          required
        />
        <ErrorMessage name={`kpi`} />
      </FormControl>
    </Grid>
  </Grid>
);

const AddAchievementDialog = (props: { open: boolean; onClose: () => any; projectId: string; objectiveId: string }) => {
  const { open, onClose, projectId, objectiveId } = props;
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [insertFn, { loading }] = useInsertProjectAchievementOneMutation({
    onCompleted: () => {
      onClose();
      enqueueSuccess('เพิ่มผลสัมฤทธิ์	โครงการสำเร็จ');
    },
    onError: (err) => {
      enqueueFailure('เกิดข้อผิดพลาด: ' + err.name);
    },
    refetchQueries: [
      {
        query: GetProjectObjectivesByProjectIdDocument,
        variables: {
          projectId,
        },
      },
    ],
  });

  const form = useForm<{ name: string; kpi: string }>({
    defaultValues: {
      name: '',
      kpi: '',
    },
  });

  const handleSubmit = form.handleSubmit((formData) => {
    insertFn({
      variables: {
        data: {
          name: formData.name,
          kpi: formData.kpi,
          project_id: projectId,
          project_objective_id: objectiveId,
          sequence: new Date().getTime(),
        },
      },
    });
  });

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="add-achievement-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle id="add-achievement-dialog-title">เพิ่มผลสัมฤทธิ์ โครงการ</DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <form id="achievement-form" noValidate onSubmit={handleSubmit}>
            <AchievementForm errors={form.errors} />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
          Cancel
        </Button>
        <AddButton onClick={handleSubmit} disabled={loading} type="submit" form="achievement-form" />
      </DialogActions>
    </Dialog>
  );
};

const EditAchievementDialog = (props: {
  onClose: () => any;
  projectId: string;
  achievement: ProjectAchievementFragment;
}) => {
  const { onClose, projectId, achievement } = props;
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [updateFn, { loading }] = useUpdateProjectAchievementByPkMutation({
    onCompleted: () => {
      onClose();
      enqueueSuccess('แก้ไขผลสัมฤทธิ์	โครงการสำเร็จ');
    },
    onError: (err) => {
      enqueueFailure('เกิดข้อผิดพลาด: ' + err.name);
    },
    refetchQueries: [
      {
        query: GetProjectObjectivesByProjectIdDocument,
        variables: {
          projectId,
        },
      },
    ],
  });
  const form = useForm<ProjectAchievementFragment>({
    defaultValues: achievement,
  });

  const handleSubmit = form.handleSubmit((formData) => {
    updateFn({
      variables: {
        achievementId: achievement.id,
        data: {
          project_id: projectId,
          name: formData.name,
          kpi: formData.kpi,
        },
      },
    });
  });

  return (
    <Dialog
      open={!!achievement}
      onClose={onClose}
      aria-labelledby="add-achievement-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="add-achievement-dialog-title">แก้ไขผลสัมฤทธิ์ โครงการ</DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <form id="achievement-form" noValidate onSubmit={handleSubmit}>
            <AchievementForm errors={form.errors} />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
          Cancel
        </Button>
        <SaveButton onClick={handleSubmit} disabled={loading} type="submit" form="achievement-form" />
      </DialogActions>
    </Dialog>
  );
};

const DeleteObjectiveButton = (props: { objectiveId: string; projectId: string }) => {
  const { projectId, objectiveId } = props;
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [deleteFn, { loading }] = useDeleteProjectObjectiveByPkMutation({
    onCompleted: () => {
      enqueueSuccess('ลบวัตถุประสงค์โครงการสำเร็จ');
    },
    onError: (err) => {
      enqueueFailure('เกิดข้อผิดพลาด: ' + err.name);
    },
    refetchQueries: [
      {
        query: GetProjectObjectivesByProjectIdDocument,
        variables: {
          projectId,
        },
      },
    ],
  });

  const handleSubmit = () => {
    if (
      window.confirm(
        'ท่านแน่ใจที่จะลบข้อมูลใช่หรือไม่?\n\nเป็นการลบข้อมูลออกจากระบบถาวร โดยที่จะไม่สามารถกู้คืนกลับมาได้'
      )
    ) {
      deleteFn({
        variables: {
          objectiveId: objectiveId,
        },
      });
    }
  };

  return <DeleteButton onClick={handleSubmit} disabled={loading} />;
};

const DeleteIndicatorButton = (props: { indicatorId: string; projectId: string }) => {
  const { projectId, indicatorId } = props;
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [deleteFn, { loading }] = useDeleteProjectIndicatorByPkMutation({
    onCompleted: () => {
      enqueueSuccess('ลบตัวชี้วัดโครงการสำเร็จ');
    },
    onError: (err) => {
      enqueueFailure('เกิดข้อผิดพลาด: ' + err.name);
    },
    refetchQueries: [
      {
        query: GetProjectObjectivesByProjectIdDocument,
        variables: {
          projectId,
        },
      },
    ],
  });

  const handleSubmit = () => {
    if (
      window.confirm(
        'ท่านแน่ใจที่จะลบข้อมูลใช่หรือไม่?\n\nเป็นการลบข้อมูลออกจากระบบถาวร โดยที่จะไม่สามารถกู้คืนกลับมาได้'
      )
    ) {
      deleteFn({
        variables: {
          indicatorId: indicatorId,
        },
      });
    }
  };

  return <DeleteButton size="small" onClick={handleSubmit} disabled={loading} />;
};

const DeleteAchievementButton = (props: { achievementId: string; projectId: string }) => {
  const { projectId, achievementId } = props;
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [deleteFn, { loading }] = useDeleteProjectAchievementByPkMutation({
    onCompleted: () => {
      enqueueSuccess('ลบผลสัมฤทธิ์สำเร็จ');
    },
    onError: (err) => {
      enqueueFailure('เกิดข้อผิดพลาด: ' + err.name);
    },
    refetchQueries: [
      {
        query: GetProjectObjectivesByProjectIdDocument,
        variables: {
          projectId,
        },
      },
    ],
  });

  const handleSubmit = () => {
    if (
      window.confirm(
        'ท่านแน่ใจที่จะลบข้อมูลใช่หรือไม่?\n\nเป็นการลบข้อมูลออกจากระบบถาวร โดยที่จะไม่สามารถกู้คืนกลับมาได้'
      )
    ) {
      deleteFn({
        variables: {
          achievementId: achievementId,
        },
      });
    }
  };

  return <DeleteButton size="small" onClick={handleSubmit} disabled={loading} />;
};

const ProjectObjectivesForm = ({ projectId, render }: ProjectObjectivesFormProps) => {
  const [selectedObjective, setSelectedObjective] = useState<ProjectObjectiveFragment | null>(null);
  const [selectedIndicator, setSelectedIndicator] = useState<ProjectIndicatorFragment | null>(null);
  const [selectedAchievement, setSelectedAchievement] = useState<ProjectAchievementFragment | null>(null);
  const disabled = !useProjectEditable();
  const { data, loading } = useGetProjectObjectivesByProjectIdQuery({
    variables: {
      projectId,
    },
  });

  if (!data?.objectives && loading) {
    return <CircularProgress />;
  }

  return (
    <Fragment>
      <Grid container spacing={3}>
        {data?.objectives.map((objective) => (
          <Grid item xs={12} key={objective.id}>
            <InfoCard
              title={objective.name}
              CardProps={{ variant: 'outlined' }}
              actionTopRight={
                !disabled && (
                  <Grid container justify="flex-end" spacing={1} style={{ maxWidth: 480 }}>
                    <Grid item>
                      <EditButton onClick={() => setSelectedObjective(objective)} />
                    </Grid>
                    <Grid item>
                      <DeleteObjectiveButton projectId={projectId} objectiveId={objective.id} />
                    </Grid>
                  </Grid>
                )
              }
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell component="th">ตัวชี้วัดระดับผลผลิต (Output)</TableCell>
                    <TableCell component="th" style={{ width: 120 }}>
                      จำนวนเป้าหมาย
                    </TableCell>
                    <TableCell component="th" style={{ width: 120 }}>
                      หน่วยนับ
                    </TableCell>
                    {!disabled && <TableCell component="th" style={{ width: 240 }} />}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {objective.output.map((output) => (
                    <TableRow key={output.id}>
                      <TableCell>
                        <Typography>{output.name}</Typography>
                      </TableCell>
                      <TableCell align="right">{output.amount}</TableCell>
                      <TableCell>{output.unit?.description}</TableCell>
                      {!disabled && (
                        <TableCell>
                          <Grid container justify="flex-end" spacing={1} style={{ maxWidth: 320 }}>
                            <Grid item>
                              <EditButton size="small" onClick={() => setSelectedIndicator(output)} />
                            </Grid>
                            <Grid item>
                              <DeleteIndicatorButton projectId={projectId} indicatorId={output.id} />
                            </Grid>
                          </Grid>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                  {!disabled && (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <ToggleState>
                          {({ isOpen, close, open }) => (
                            <Grid item xs={12}>
                              <AddButton
                                variant="outlined"
                                onClick={open}
                                disabled={objective.output.length >= 3}
                                size="small"
                              >
                                เพิ่มตัวชี้วัดระดับผลผลิต (Output)
                              </AddButton>
                              {isOpen && (
                                <AddIndicatorDialog
                                  projectId={projectId}
                                  objectiveId={objective.id}
                                  open={isOpen}
                                  type={ProjectIndicatorTypeEnum.Output}
                                  onClose={close}
                                />
                              )}
                            </Grid>
                          )}
                        </ToggleState>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <Spacing />
              <Spacing />

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell component="th">ตัวชี้วัดระดับผลลัพธ์ (Outcome)</TableCell>
                    <TableCell component="th" style={{ width: 120 }}>
                      จำนวนเป้าหมาย
                    </TableCell>
                    <TableCell component="th" style={{ width: 120 }}>
                      หน่วยนับ
                    </TableCell>
                    {!disabled && <TableCell component="th" style={{ width: 240 }} />}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {objective.outcome.map((outcome) => (
                    <TableRow key={outcome.id}>
                      <TableCell>
                        <Typography>{outcome.name}</Typography>
                      </TableCell>
                      <TableCell align="right">{outcome.amount}</TableCell>
                      <TableCell>{outcome.unit?.description}</TableCell>
                      {!disabled && (
                        <TableCell>
                          <Grid container justify="flex-end" spacing={1} style={{ maxWidth: 320 }}>
                            <Grid item>
                              <EditButton size="small" onClick={() => setSelectedIndicator(outcome)} />
                            </Grid>
                            <Grid item>
                              <DeleteIndicatorButton projectId={projectId} indicatorId={outcome.id} />
                            </Grid>
                          </Grid>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                  {!disabled && (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <ToggleState>
                          {({ isOpen, close, open }) => (
                            <Grid item xs={12}>
                              <AddButton
                                variant="outlined"
                                onClick={open}
                                disabled={objective.outcome.length >= 3}
                                size="small"
                              >
                                เพิ่มตัวชี้วัดระดับผลลัพธ์ (Outcome)
                              </AddButton>
                              {isOpen && (
                                <AddIndicatorDialog
                                  projectId={projectId}
                                  objectiveId={objective.id}
                                  open={isOpen}
                                  type={ProjectIndicatorTypeEnum.Outcome}
                                  onClose={close}
                                />
                              )}
                            </Grid>
                          )}
                        </ToggleState>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <Spacing />
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell component="th">ผลสัมฤทธิ์</TableCell>
                    <TableCell component="th" style={{ width: 120 }}>
                      ตัวชี้วัด
                    </TableCell>
                    {!disabled && <TableCell component="th" style={{ width: 240 }} />}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {objective.achievements.map((achievement) => (
                    <TableRow key={achievement.id}>
                      <TableCell>
                        <Typography>{achievement.name}</Typography>
                      </TableCell>
                      <TableCell>{achievement.kpi}</TableCell>
                      {!disabled && (
                        <TableCell>
                          <Grid container justify="flex-end" spacing={1} style={{ maxWidth: 320 }}>
                            <Grid item>
                              <EditButton size="small" onClick={() => setSelectedAchievement(achievement)} />
                            </Grid>
                            <Grid item>
                              <DeleteAchievementButton projectId={projectId} achievementId={achievement.id} />
                            </Grid>
                          </Grid>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                  {!disabled && (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <ToggleState>
                          {({ isOpen, close, open }) => (
                            <Grid item xs={12}>
                              <AddButton
                                variant="outlined"
                                onClick={open}
                                disabled={objective.achievements.length >= 3}
                                size="small"
                              >
                                เพิ่มผลสัมฤทธิ์
                              </AddButton>
                              {isOpen && (
                                <AddAchievementDialog
                                  projectId={projectId}
                                  objectiveId={objective.id}
                                  open={isOpen}
                                  onClose={close}
                                />
                              )}
                            </Grid>
                          )}
                        </ToggleState>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </InfoCard>
          </Grid>
        ))}
        {!disabled && (
          <ToggleState>
            {({ isOpen, close, open }) => (
              <Grid item xs={12}>
                <AddButton onClick={open} disabled={data!.objectives?.length >= 3}>
                  เพิ่มวัตถุประสงค์โครงการ
                </AddButton>
                {isOpen && <AddObjectiveDialog projectId={projectId} open={isOpen} onClose={close} />}
              </Grid>
            )}
          </ToggleState>
        )}
      </Grid>
      {selectedObjective && (
        <EditObjectiveDialog
          objective={selectedObjective}
          onClose={() => setSelectedObjective(null)}
          projectId={projectId}
        />
      )}
      {selectedIndicator && (
        <EditIndicatorDialog
          indicator={selectedIndicator}
          onClose={() => setSelectedIndicator(null)}
          projectId={projectId}
        />
      )}
      {selectedAchievement && (
        <EditAchievementDialog
          achievement={selectedAchievement}
          onClose={() => setSelectedAchievement(null)}
          projectId={projectId}
        />
      )}
      <Spacing />
      <Spacing />
      <Spacing />
      {render && render({ onSubmit: () => {} })}
    </Fragment>
  );
};

export default memo(ProjectObjectivesForm);
