import React from 'react';

import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import ButtonCircularProgress from '@app/shared/components/ButtonCircularProgress';

interface ToggleArchiveButtonProps extends Omit<ButtonProps, 'children'> {
  archived?: boolean;
  loading?: boolean;
}

export const ToggleArchiveButton = ({ archived, disabled, loading, ...buttonProps }: ToggleArchiveButtonProps) => {
  const text = !archived ? 'Archive' : 'Unarchive';
  const icon = !archived ? <ArchiveIcon /> : <UnarchiveIcon />;
  const color = !archived ? 'secondary': 'primary';
  return (
    <Button startIcon={icon} variant="contained" color={color} disabled={disabled || loading} {...buttonProps}>
      {text} {loading && <ButtonCircularProgress />}
    </Button>
  );
};
