import Spacing from '@app/components/common/Spacing';
import Page from '@app/components/Page';
import { pageContent } from '@app/shared/hoc/page-content';
import { useTypography } from '@app/shared/styles/typography';
import { ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import { useGetPublicAttachmentsQuery } from '@app/generated/graphql';
import { InfoCard } from '@mystiny/ui';
import React from 'react';
import { DateUtils } from '@app/shared/libs/date';
import DeleteButton from '@app/shared/components/buttons/DeleteButton';
import { FormProvider, useForm } from 'react-hook-form';
import SaveButton from '@app/shared/components/buttons/SaveButton';

const Attachment = () => {
  const typographyClasses = useTypography();
  const { data } = useGetPublicAttachmentsQuery();
  const form = useForm();

  return (
    <Page
      title={
        <Typography variant="h5" color="primary" className={typographyClasses.pageTitle}>
          แก้ไขเอกสารแนบ
        </Typography>
      }
    >
      <InfoCard title="">
        {data?.attachments.map((attachment) => (
          <ListItem button component="a" key={attachment.id}>
            <ListItemText
              primary={attachment.original_name}
              secondary={DateUtils.toThaiDatetime(attachment.created_at)}
            />
            <ListItemSecondaryAction>
              <DeleteButton>ลบ</DeleteButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        <Spacing />
        <Typography variant="h6">เพิ่มเอกสาร</Typography>
        <FormProvider {...form}>
          <SaveButton></SaveButton>
        </FormProvider>
      </InfoCard>
    </Page>
  );
};

export default pageContent(Attachment);
