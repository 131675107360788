import { useCurrentUser } from '@app/hooks';
import api from '@app/libs/api';
import PageLoading from '@app/shared/components/PageLoading';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { useQueryClient } from '@tanstack/react-query';
import { User as FirebaseUser, getAuth, onAuthStateChanged } from 'firebase/auth';
import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';

export const FirebaseContext = createContext<any>(null);

FirebaseContext.displayName = 'FirebaseContext';

export const FirebaseProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [completed, setCompleted] = useState(false);
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null>(getAuth().currentUser);
  const { enqueueFailure } = useResponseBar();
  const queryClient = useQueryClient();

  useEffect(() => {
    onAuthStateChanged(
      getAuth(),
      async (firebaseUser) => {
        if (firebaseUser) {
          const user = await api.getCurrentUser();
          queryClient.setQueryData(useCurrentUser.getQueryKey(), user);
        } else {
          queryClient.setQueryData(useCurrentUser.getQueryKey(), null);
        }

        setFirebaseUser(firebaseUser);
        setCompleted(true);
      },
      (error) => {
        enqueueFailure(`เกิดข้อผิดพลาดในการตรวจสอบสถานะการเข้าสู่ระบบ [${error.message}]`);
        setCompleted(true);
      },
      () => {
        setCompleted(true);
      }
    );
  }, [enqueueFailure, queryClient]);

  if (!completed) {
    return <PageLoading />;
  }

  return <FirebaseContext.Provider value={firebaseUser}>{children}</FirebaseContext.Provider>;
};

FirebaseProvider.displayName = 'FirebaseProvider';

export const useFirebaseAuth = () => {
  return useContext(FirebaseContext);
};
