import api from '@app/libs/api';
import { Box } from '@material-ui/core';
import { useEffect } from 'react';

export default function LogoutPage() {
  useEffect(() => {
    const handleLogout = async () => {
      await api.logout();
      // Redirect to login page
      window.location.href = '/login';
    };

    handleLogout();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      กำลังออกจากระบบ...
    </Box>
  );
}
