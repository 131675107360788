import Page from '@app/components/Page';
import { listPageContent } from '@app/shared/hoc/list-page';
import { useTypography } from '@app/shared/styles/typography';
import { Typography } from '@material-ui/core';
import { InfoCard } from '@mystiny/ui';
import React from 'react';

const ProjectDashboardIndex = () => {
  const typographyClasses = useTypography();

  return (
    <Page
      title={
        <Typography variant="h5" color="primary" className={typographyClasses.pageTitle}>
          รายงานสรุปคำขอโครงการ
        </Typography>
      }
    >
      <InfoCard>
        <iframe
          style={{ width: '100%', height: '90vh', border: 0 }}
          frameBorder="0"
          title="รายงานแผนงานและงบประมาณ"
          width="100%"
          height="100%"
          allowFullScreen
          src="https://datastudio.google.com/embed/reporting/8fe497ba-b25c-496f-be9c-c9ad0193a95a/page/Nnr9B"
        />
      </InfoCard>
    </Page>
  );
};

export default listPageContent(ProjectDashboardIndex);
