import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { getAuth, getIdToken } from 'firebase/auth';
import * as React from 'react';

const createApolloClient = () => {
  const uri = process.env.REACT_APP_HASURA_ENDPOINT + '/v1/graphql';

  const httpLink = new HttpLink({
    uri,
    credentials: 'include',
  });

  const authLink = setContext(async (a, { headers }) => {
    let extraHeaders: Record<string, string> = {};
    const user = getAuth().currentUser;

    // add auth headers if signed in
    // or add 'public' role if not signed in
    if (user) {
      extraHeaders['authorization'] = `Bearer ${await getIdToken(user)}`;
    }

    return {
      headers: {
        ...headers,
        ...extraHeaders,
      },
    };
  });

  const link = split(({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition';
  }, authLink.concat(httpLink));

  const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache({
      typePolicies: {
        account_budget_type: {
          keyFields: ['id', 'fiscal_year'],
        },
        account_budget_account: {
          keyFields: ['account_id', 'budget_id', 'fiscal_year'],
        },
        account_account_balance_sheet: {
          keyFields: ['id', 'budget_id', 'fiscal_year', 'organization_id'],
        },
        users_permission: {
          keyFields: ['user_id', 'permission_id'],
        },
        ledger_account_budget_balance: {
          keyFields: ['account_id', 'budget_id', 'fiscal_year'],
        },
        ledger_account_budget_monthly: {
          keyFields: ['id', 'budget_id', 'fiscal_year'],
        },
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });

  return {
    client,
  };
};

function ApolloWrapper(props: { children: React.ReactNode }) {
  const { client } = createApolloClient();

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}

export default ApolloWrapper;
