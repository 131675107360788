import { withStyles, Theme, lighten, darken } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';

const styles = (theme: Theme) => ({
  '@global': {
    '.MuiCardActions-root': {
      padding: 16,
    },
    '.MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.78)',
    },
    '.MuiInputBase-input.Mui-disabled': {
      cursor: 'not-allowed !important',
    },
    '.MuiFormLabel-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.78)',
    },
    '.MuiTabItem-label': {
      fontFamily: theme.typography.body1.fontFamily,
    },
    // MuiTable
    '.MuiTableCell-root': {
      display: 'table-cell',
      verticalAlign: 'inherit',
      borderBottom: `1px solid ${
        theme.palette.type === 'light' ? lighten(theme.palette.divider, 0.88) : darken(theme.palette.divider, 0.68)
      }`,
      lineHeight: 2,
    },
    'th.MuiTableCell-root': {
      fontWeight: 500,
      fontSize: '0.915rem',
      borderBottom: '2px solid      rgb(117 117 117)',
      borderTop: '2px solid      rgb(117 117 117)',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    // '.bordered .MuiTableCell-root': {
    //   borderRight: `1px solid
    //   ${
    //     theme.palette.type === 'light'
    //       ? lighten(fade(theme.palette.divider, 1), 0.88)
    //       : darken(fade(theme.palette.divider, 1), 0.68)
    //   }`,
    // },
    '.MuiTableCell-head': {
      color: 'unset',
    },
    '.MuiTableRow-root:nth-of-type(odd)': {
      backgroundColor: 'unset',
    },
    '.MuiTableRow-head:nth-of-type(odd)': {
      backgroundColor: 'unset',
    },
    /**
     * Start React-Data-Grid
     */
    '.text-right': {
      textAlign: 'right',
    },
    '.danger': {
      color: red[500],
    },
    '.success': {
      color: green[500],
    },
    '.ledger-entry-row': {
      height: 60,
    },
    // End React-Data-Grid
    '.MuiFormHelperText-root': {
      fontSize: '0.85rem',
    },
    '.MuiCard-root > .MuiDivider-root': {
      backgroundColor: theme.palette.primary.main,
      height: 3,
    },
    '.GmailSidebarItem-label': {
      textAlign: 'left',
    },
    '.MuiFormLabel-asterisk, .MuiInputLabel-asterisk': {
      color: red[600],
    },
    '.active.MuiButton-root': {
      textDecoration: 'underline',
    },
    '.active .MuiListItem-root': {
      backgroundColor: '#c4e19942',
    },
    '.rdg-filter-row, .rdg-header-row': {
      fontWeight: 400,
    },
    /**
     * Disable the focus outline, which is default on some browsers like
     * chrome when focusing elements
     */
    '*:focus': {
      outline: 0,
    },
    '.MuiIcon-root': {
      width: '1.3em',
    },
    '.MuiTable-root': {
      minWidth: 680,
    },
    '.text-white': {
      color: theme.palette.common.white,
    },
    '.fill-grid': {
      height: '100%',
    },
    '#root': {
      height: '100%',
    },
    '.load-more-rows-tag': {
      width: 180,
      padding: '8px 16px',
      position: 'absolute',
      bottom: 8,
      right: 8,
      height: 42,
      color: 'white',
      lineHeight: 2,
      background: 'rgba(0, 0, 0, 0.6)',
    },
    '.listItemLeftPadding': {
      paddingTop: `${theme.spacing(1.75)}px !important`,
      paddingBottom: `${theme.spacing(1.75)}px !important`,
      paddingLeft: `${theme.spacing(4)}px !important`,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: `${theme.spacing(4)}px !important`,
      },
      '@media (max-width:  420px)': {
        paddingLeft: `${theme.spacing(1)}px !important`,
      },
    },
    '.container': {
      width: '100%',
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      marginRight: 'auto',
      marginLeft: 'auto',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 540,
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 720,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1170,
      },
    },
    '.row': {
      display: 'flex',
      flexWrap: 'wrap',
      marginRight: -theme.spacing(2),
      marginLeft: -theme.spacing(2),
    },
    '.container-fluid': {
      width: '100%',
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      marginRight: 'auto',
      marginLeft: 'auto',
      maxWidth: 1370,
    },
    '.lg-mg-top': {
      marginTop: `${theme.spacing(20)}px !important`,
      [theme.breakpoints.down('md')]: {
        marginTop: `${theme.spacing(18)}px !important`,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: `${theme.spacing(16)}px !important`,
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: `${theme.spacing(14)}px !important`,
      },
    },
    '.lg-mg-bottom': {
      marginBottom: `${theme.spacing(20)}px !important`,
      [theme.breakpoints.down('md')]: {
        marginBottom: `${theme.spacing(18)}px !important`,
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: `${theme.spacing(16)}px !important`,
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: `${theme.spacing(14)}px !important`,
      },
    },
    '.lg-p-top': {
      paddingTop: `${theme.spacing(20)}px !important`,
      [theme.breakpoints.down('md')]: {
        paddingTop: `${theme.spacing(18)}px !important`,
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: `${theme.spacing(16)}px !important`,
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: `${theme.spacing(14)}px !important`,
      },
    },
    a: {
      textDecoration: 'unset',
    },
  },
});

function globalStyles() {
  return null;
}

export default withStyles(styles, { withTheme: true })(globalStyles);
