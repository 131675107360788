import { CircularProgress, Dialog } from '@material-ui/core';
import { PaperProps } from '@material-ui/core/Paper';
import React, { FC, Fragment } from 'react';

const FakePaper: FC<PaperProps> = ({ children }) => <Fragment children={children} />;

const SuspenseLoading = () => {
  return (
    <Fragment>
      <Dialog disableEscapeKeyDown PaperComponent={FakePaper} open={true}>
        <CircularProgress style={{ color: '#fff' }} size={60} />
      </Dialog>
    </Fragment>
  );
};

export default SuspenseLoading;
