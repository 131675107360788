import Spacing from '@app/components/common/Spacing';
import DataGridContainer from '@app/shared/components/DataGridContainer';
import PageTitle from '@app/shared/components/PageTitle';
import { useMonthlyReportColumns } from './columns';
import { CircularProgress, FormControl, Grid, InputLabel, MenuItem, TextField, Typography } from '@material-ui/core';
import { groupBy as rowGrouper } from 'lodash';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import DataGrid from 'react-data-grid';
import {
  useGetLedgerMonthlyReportByOrganizationIdQuery,
  GetLedgerMonthlyReportByOrganizationIdQuery,
  useGetOrganizationsQuery,
} from '@app/generated/graphql';
import { listPageContent } from '@app/shared/hoc/list-page';
import { FormLayout, FormLayoutGroup, InfoCard } from '@mystiny/ui';
import { FormProvider, useForm } from 'react-hook-form';
import { DateUtils } from '@app/shared/libs/date';
import { ErrorMessage, Select } from '@aginix/mui-react-hook-form-input';
import AutocompleteInput from '@app/shared/components/AutocompleteInput';
import { useIfUser } from '@app/components/common/Routes';
import { useTypography } from '@app/shared/styles/typography';
import { ExportButton } from './export/ExportButton';
import { useUserHasAnyPermissions } from '@app/user-permission';
import { useCurrentUser } from '@app/hooks';

function parseNumber(value: number | undefined | null) {
  return value ? value : 0;
}

const MonthlyReportDataTable = ({ data }: { data: GetLedgerMonthlyReportByOrganizationIdQuery }) => {
  const [expandedGroupIds, setExpandedGroupIds] = useState(new Set());
  const [groupBy] = useState(['budget.description']);
  const columns = useMonthlyReportColumns();

  const rows = useMemo(
    () =>
      data.budget_rows.reduce<any[]>((array, budget) => {
        const accounts = data.account_rows.map((account) => {
          const monthly: any = data.ledger_account_budget_monthly_rows
            .filter((row) => row.id === account.id && row.budget_id === budget.id)
            .reduce(
              (prev, curr) => ({
                ...prev,
                october: parseNumber(curr['october']) + parseNumber(prev.october),
                november: parseNumber(curr['november']) + parseNumber(prev.november),
                december: parseNumber(curr['december']) + parseNumber(prev.december),
                january: parseNumber(curr['january']) + parseNumber(prev.january),
                february: parseNumber(curr['february']) + parseNumber(prev.february),
                march: parseNumber(curr['march']) + parseNumber(prev.march),
                april: parseNumber(curr['april']) + parseNumber(prev.april),
                may: parseNumber(curr['may']) + parseNumber(prev.may),
                june: parseNumber(curr['june']) + parseNumber(prev.june),
                july: parseNumber(curr['july']) + parseNumber(prev.july),
                august: parseNumber(curr['august']) + parseNumber(prev.august),
                september: parseNumber(curr['september']) + parseNumber(prev.september),
              }),
              {}
            );

          return {
            account,
            budget,
            october: monthly['october'],
            november: monthly['november'],
            december: monthly['december'],
            january: monthly['january'],
            february: monthly['february'],
            march: monthly['march'],
            april: monthly['april'],
            may: monthly['may'],
            june: monthly['june'],
            july: monthly['july'],
            august: monthly['august'],
            september: monthly['september'],
          };
        });
        return [...array, ...accounts];
      }, []),
    [data]
  );

  return (
    <div>
      <ExportButton data={rows} name="Monthly Report" />
      <Spacing />
      <DataGridContainer height="70vh">
        <DataGrid
          columns={columns}
          rows={rows}
          className="fill-grid rdg-light"
          defaultColumnOptions={{ sortable: true, resizable: true }}
          groupBy={groupBy}
          rowGrouper={rowGrouper}
          expandedGroupIds={expandedGroupIds}
          onExpandedGroupIdsChange={setExpandedGroupIds}
        />
      </DataGridContainer>
    </div>
  );
};

const MonthlyReportView = () => {
  const typographyClasses = useTypography();
  const user = useCurrentUser();
  const isSysadmin = useIfUser({ condition: ({ roles }) => roles.includes('sysadmin') });
  const isModuleAdmin = useUserHasAnyPermissions(['ACCOUNTING_ADMIN']);
  const form = useForm({
    defaultValues: {
      fiscal_year: DateUtils.getThaiFiscalYear(new Date()),
      organization_id: user?.organizationId,
    },
  });

  useEffect(() => {
    form.setValue('organization_id', user?.organizationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const fiscalYear = form.watch('fiscal_year', DateUtils.getThaiFiscalYear(new Date()));
  const organizationId = form.watch('organization_id');

  const { data: organizationData, loading: organizationLoading } = useGetOrganizationsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const organization = useMemo(
    () => organizationData?.rows.find((org) => org.id === organizationId),
    [organizationData, organizationId]
  );

  const organizationOptions = useMemo(
    () => [{ id: undefined, name: 'ภาพรวม' }, ...(organizationData?.rows || [])],
    [organizationData]
  );

  const { data, loading } = useGetLedgerMonthlyReportByOrganizationIdQuery({
    variables: {
      fiscalYear: Number(fiscalYear),
      organizationId: form.watch('organization_id'),
    },
  });

  return (
    <Fragment>
      <PageTitle
        title={
          <Typography variant="h6" color="primary" className={typographyClasses.pageTitle}>
            Monthly Report
          </Typography>
        }
        actions={<Fragment></Fragment>}
      />
      <Spacing />
      <InfoCard>
        <Grid container spacing={2} justify="space-between">
          <Grid item md={5}>
            <Typography variant="h6" color="primary">
              {loading ? '....' : organization?.name || 'ระดับภาพรวมทุกหน่วยงาน'}
            </Typography>
            <Typography variant="h6" color="textSecondary">
              ปีงบประมาณพ.ศ. {fiscalYear}
            </Typography>
          </Grid>
          <Grid item md={7}>
            <FormProvider {...form}>
              <form noValidate onSubmit={form.handleSubmit((formData) => console.log(formData))}>
                <FormLayout>
                  <FormLayoutGroup>
                    <FormControl fullWidth margin="dense">
                      {isSysadmin || isModuleAdmin ? (
                        <AutocompleteInput
                          label="หน่วยงาน"
                          name="organization_id"
                          options={organizationOptions}
                          loading={organizationLoading}
                          disableClearable
                          primaryKey="id"
                          getOptionLabel={(option: any) => `${option.name}`}
                          size="small"
                        />
                      ) : (
                        <TextField
                          label="หน่วยงาน"
                          size="small"
                          name="organization_name"
                          id="organization_name"
                          variant="outlined"
                          value={user?.organization?.name}
                          disabled
                        />
                      )}
                      <ErrorMessage name="organization_id" />
                    </FormControl>
                    <FormControl fullWidth margin="dense" variant="outlined">
                      <InputLabel htmlFor="fiscal_year">ปีงบประมาณ</InputLabel>
                      <Select name="fiscal_year" id="fiscal_year" label="ปีงบประมาณ">
                        <MenuItem value="2564">2564</MenuItem>
                        <MenuItem value="2563">2563</MenuItem>
                      </Select>
                    </FormControl>
                  </FormLayoutGroup>
                </FormLayout>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
        {loading ? (
          <CircularProgress />
        ) : (
          <Fragment>{data?.ledger_account_budget_monthly_rows && <MonthlyReportDataTable data={data} />}</Fragment>
        )}
      </InfoCard>
    </Fragment>
  );
};

export default listPageContent(MonthlyReportView);
