import React, { memo } from 'react';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ListIcon from '@material-ui/icons/List';
import EditIcon from '@material-ui/icons/Edit';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import RestoreIcon from '@material-ui/icons/Restore';
import LockIcon from '@material-ui/icons/Lock';
import { LedgerLedgerStatusEnum } from '@app/generated/graphql';
import { ListItem, ListItemText } from '@material-ui/core';
import GmailSidebarItem from '@mui-treasury/components/sidebarItem/gmail';
import { blue, orange, green, red, brown, lightBlue } from '@material-ui/core/colors';
import { Paper, Box } from '@material-ui/core';
import Menu from '@mui-treasury/components/menu/collapsible';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';

const indicatorColors = [blue[500], orange.A400, orange.A700, green.A400, red.A400, lightBlue.A400, brown.A400];

export interface LedgerStatusFilterMenuProps {
  selectedStatus?: LedgerLedgerStatusEnum | null;
  handleSelectedStatus: any;
}

const LedgerStatusFilterMenu = ({ selectedStatus, handleSelectedStatus }: LedgerStatusFilterMenuProps) => {
  return (
    <Paper variant="outlined" style={{ height: '100%' }}>
      <Box padding={1}>
        <Menu
          collapsed
          renderToggle={({ collapsed, onClick }: any) => (
            <GmailSidebarItem
              startIcon={collapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              label={collapsed ? 'ซ่อน' : 'แสดง'}
              onClick={onClick}
            />
          )}
        >
          <ListItem>
            <ListItemText primary="สถานะ" />
          </ListItem>
          <GmailSidebarItem
            color={indicatorColors[0]}
            startIcon={<ListIcon />}
            label={'ทั้งหมด'}
            amount={undefined}
            onClick={() => handleSelectedStatus(null)}
            selected={selectedStatus === null}
          />
          <GmailSidebarItem
            color={indicatorColors[1]}
            startIcon={<HourglassEmptyIcon />}
            label="รอการอนุมัติ"
            amount={undefined}
            onClick={() => handleSelectedStatus(LedgerLedgerStatusEnum.Submitted)}
            selected={selectedStatus === LedgerLedgerStatusEnum.Submitted}
          />
          <GmailSidebarItem
            color={indicatorColors[2]}
            startIcon={<RestoreIcon />}
            label="เปลี่ยนแปลงข้อมูล"
            amount={undefined}
            onClick={() => handleSelectedStatus(LedgerLedgerStatusEnum.ChangeRequest)}
            selected={selectedStatus === LedgerLedgerStatusEnum.ChangeRequest}
          />
          <GmailSidebarItem
            color={indicatorColors[3]}
            startIcon={<ThumbUpIcon />}
            label="อนุมัติ"
            amount={undefined}
            onClick={() => handleSelectedStatus(LedgerLedgerStatusEnum.Approved)}
            selected={selectedStatus === LedgerLedgerStatusEnum.Approved}
          />
          <GmailSidebarItem
            color={indicatorColors[4]}
            startIcon={<ThumbDownIcon />}
            label="ไม่อนุมัติ"
            amount={undefined}
            onClick={() => handleSelectedStatus(LedgerLedgerStatusEnum.Rejected)}
            selected={selectedStatus === LedgerLedgerStatusEnum.Rejected}
          />
          <GmailSidebarItem
            color={indicatorColors[5]}
            startIcon={<EditIcon />}
            label="แบบร่าง"
            amount={undefined}
            onClick={() => handleSelectedStatus(LedgerLedgerStatusEnum.Draft)}
            selected={selectedStatus === LedgerLedgerStatusEnum.Draft}
          />
          <GmailSidebarItem
            color={indicatorColors[6]}
            startIcon={<LockIcon />}
            label="ล็อก"
            amount={undefined}
            onClick={() => handleSelectedStatus(LedgerLedgerStatusEnum.Locked)}
            selected={selectedStatus === LedgerLedgerStatusEnum.Locked}
          />
        </Menu>
      </Box>
    </Paper>
  );
};

export default memo(LedgerStatusFilterMenu, (prev, next) => prev.selectedStatus === next.selectedStatus);
