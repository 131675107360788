import React from 'react';
import { Chip } from '@material-ui/core';
import { ChipProps as MuiChipProps } from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { ProjectFragment } from '@app/generated/graphql';
import { useColors } from '@app/shared/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 0,
  },
}));

export const ProjectStatus = ({ project, ChipProps }: { project: ProjectFragment; ChipProps?: MuiChipProps }) => {
  const colors = useColors();
  const classes = useStyles();

  const classNameMapping: {
    [key: string]: string;
  } = {
    approved: colors.approved,
    cancelled: colors.cancelled,
    change_request: colors.changeRequest,
    draft: colors.new,
    rejected: colors.rejected,
    submitted: colors.submitted,
  };

  return (
    <Chip
      label={project.project_step.description}
      variant="default"
      color="primary"
      classes={{
        root: classes.root,
        colorPrimary: classNameMapping[project.project_step.id] || colors.new,
      }}
      {...ChipProps}
    />
  );
};
