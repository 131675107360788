import { ArchiveView } from '@app/components/ArchiveView';
import TextFilter from '@app/components/data-grid/TextFilter';
import { ToggleGroup } from '@app/components/data-grid/ToggleGroup';
import { AccountFullFragment } from '@app/generated/graphql';
import React from 'react';
import { Column } from 'react-data-grid';

export const accountColumns: Column<AccountFullFragment>[] = [
  {
    key: 'account_type.description',
    name: 'หมวดบัญชี',
    width: 220,
    resizable: true,
    formatter: ({ row }) => <React.Fragment>{row.account_type?.description}</React.Fragment>,
    filterRenderer: (p) => <TextFilter {...p} />,
    groupFormatter: (props) => <ToggleGroup {...props} defaultGroupKey="ไม่มีหมวดบัญชี" />,
  },
  {
    key: 'code',
    name: 'รหัสบัญชี',
    width: 150,
    resizable: true,
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'name',
    name: 'ชื่อบัญชี',
    minWidth: 250,
    resizable: true,
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  // {
  //   key: 'entry_type.description',
  //   name: 'ประเภทบัญชี',
  //   width: 150,
  //   resizable: true,
  //   formatter: ({ row }) => <React.Fragment>{row.entry_type.description}</React.Fragment>,
  // },
  {
    key: 'deleted_at',
    name: 'Active',
    width: 100,
    resizable: true,
    formatter: ({ row }) => <ArchiveView value={!row.deleted_at} />,
    filterRenderer: (p) => <TextFilter {...p} />,
  },
];
