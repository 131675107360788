import Spacing from '@app/components/common/Spacing';
import React from 'react';

export interface PageProps {
  title?: React.ReactNode;
  back?: {
    label?: string;
    to: string;
  };
}

const Page: React.FC<PageProps> = ({ children, title, back }) => {
  return (
    <React.Fragment>
      <div>{title}</div>
      <main style={{ paddingTop: 16 }}>{children}</main>
      <Spacing />
    </React.Fragment>
  );
};

export default Page;
