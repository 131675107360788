import React from 'react';
import TextFilter from '@app/components/data-grid/TextFilter';
import { OrganizationFragment } from '@app/generated/graphql';
import { Column } from 'react-data-grid';

export const organizationColumns: Column<OrganizationFragment>[] = [
  {
    key: 'name',
    name: 'ชื่อหน่วยงาน',
    width: 370,
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'province',
    name: 'จังหวัด',
    width: 180,
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'area_code',
    name: 'รหัสพื้นที่',
    width: 120,
    resizable: true,
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'unit_code',
    name: 'รหัสหน่วยงาน',
    resizable: true,
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'reference',
    name: 'รหัสอ้างอิง',
    resizable: true,
    filterRenderer: (p) => <TextFilter {...p} />,
  },
];
