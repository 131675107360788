import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useIsAuthenticated } from '@app/hooks/useIsAuthenticated';
import { useCurrentUser } from '@app/hooks';

export interface IfUserProps {
  condition: ({
    isAuthenticated,
    roles,
    defaultRole,
    organizationId,
  }: {
    isAuthenticated: boolean | null;
    roles: string[];
    defaultRole?: string;
    organizationId?: string;
  }) => boolean;
  redirect?: boolean;
  redirectTo?: string;
}

const uuidV4Regex = /^[A-F\d]{8}-[A-F\d]{4}-4[A-F\d]{3}-[89AB][A-F\d]{3}-[A-F\d]{12}$/i;

export const IfUser: React.FC<IfUserProps> = ({ children, condition, redirect, redirectTo }) => {
  const signedIn = useIsAuthenticated();
  const user = useCurrentUser();
  const location = useLocation();

  if (!signedIn) {
    return <Redirect to={`/login?redirect_uri=${location.pathname}`} />;
  }

  const organizationId = user?.organizationId || undefined;

  const hasPermission = condition({
    isAuthenticated: signedIn,
    roles: user!.account!.accountRoles.map((role) => role.role),
    defaultRole: user!.account!.defaultRole!,
    organizationId: uuidV4Regex.test(organizationId || '') ? organizationId : undefined,
  });

  if (!hasPermission && redirect) {
    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }
    return <Redirect to={`/login?redirect_uri=${location.pathname}`} />;
  } else if (!hasPermission) {
    return null;
  }

  // user is logged in
  return <React.Fragment>{children}</React.Fragment>;
};

export const withIfUser =
  <P extends object>(Component: React.ComponentType<P>) =>
  (ifUserProps: IfUserProps): React.FC<P> =>
  (props) =>
    (
      <IfUser {...ifUserProps}>
        <Component {...(props as P)} />
      </IfUser>
    );

export function useIfUser({ condition }: IfUserProps): boolean {
  const signedIn = useIsAuthenticated();
  const user = useCurrentUser();

  if (!signedIn) {
    return false;
  }

  const organizationId = user!.organizationId!;
  const hasPermission = condition({
    isAuthenticated: signedIn,
    roles: user!.account!.accountRoles.map((role) => role.role),
    defaultRole: user!.account!.defaultRole!,
    organizationId: uuidV4Regex.test(organizationId) ? organizationId : undefined,
  });

  return hasPermission;
}
