import React, { useMemo, useState } from 'react';
import { Row } from 'react-data-grid';

import { GetProjectBudgetGroupsComponent } from '@app/generated/graphql';
import { budgetGroupColumns } from './columns';
import DataGridContainer from '@app/shared/components/DataGridContainer';
import LoadMoreRowsTag from '@app/shared/components/LoadMoreRowsTag';
import { listPageContent } from '@app/shared/hoc/list-page';
import DataGridFetchMore from '@app/shared/components/DataGridFetchMore';
import { useRdgSort } from '@app/shared/hooks/useRdgSort';
import { useHistory } from 'react-router-dom';
// import { useDebounce } from 'use-debounce';
import { Box, Typography } from '@material-ui/core';
import PageTitle from '@app/shared/components/PageTitle';

const ProjectBudgetGroupList = () => {
  const history = useHistory();
  const [filters, setFilters] = useState<any>({
    name: '',
    unit_id: '',
  });
  const [offset, setOffset] = React.useState(0);
  const { handleSort, orderBy, sort } = useRdgSort();
  const strategyList = useMemo(
    () => (
      <GetProjectBudgetGroupsComponent
        variables={{
          where: {},
          orderBy: orderBy,
        }}
        fetchPolicy="cache-and-network"
      >
        {({ data, fetchMore, loading, variables }) => (
          <DataGridContainer height="80vh">
            <DataGridFetchMore
              fetchMore={fetchMore}
              variables={variables}
              offset={offset}
              setOffset={setOffset}
              sortColumn={sort?.column}
              sortDirection={sort?.direction}
              onSort={handleSort}
              rowRenderer={(props) => (
                <Row
                  {...props}
                  onDoubleClick={() => history.push(`/bof/projects/master-data/budget-groups/${props.row.id}`)}
                />
              )}
              defaultColumnOptions={{ sortable: true }}
              rows={data?.rows || []}
              columns={budgetGroupColumns}
              filters={filters}
              onFiltersChange={setFilters}
              enableFilters
            />
            <LoadMoreRowsTag loading={loading} />
          </DataGridContainer>
        )}
      </GetProjectBudgetGroupsComponent>
    ),
    [filters, setFilters, offset, setOffset, handleSort, orderBy, sort, history]
  );

  return (
    <React.Fragment>
      <Box padding={1}>
        <PageTitle
          title={<Typography variant="h6">หมวดค่าใช้จ่ายงบประมาณ</Typography>}
          // actions={
          //   <LinkButton startIcon={<AddIcon />} to="/bof/projects/master-data/budgets/create">
          //     เพิ่มหมวดค่าใช้จ่ายงบประมาณ
          //   </LinkButton>
          // }
        />
      </Box>
      {strategyList}
    </React.Fragment>
  );
};

export default listPageContent(ProjectBudgetGroupList);
