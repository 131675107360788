import { get } from 'lodash';
import { FieldErrors } from 'react-hook-form';

export const requiredRoles = ['employee', 'manager', 'sysadmin', 'admin'];

export function hasPermissions(role: string): boolean {
  return requiredRoles.includes(role);
}

export function hasError(errors: FieldErrors, path: string) {
  return !!get(errors, path);
}
