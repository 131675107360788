import React, { Fragment, useCallback, useState } from 'react';
import {
  ProjectMemberFragment,
  useGetProjectMembersByProjectIdQuery,
  useInsertProjectMemberOneMutation,
  useUpdateProjectMemberByPkMutation,
  useDeleteProjectMemberByPkMutation,
  GetProjectMembersByProjectIdDocument,
} from '@app/generated/graphql';
import SaveButton from '@app/shared/components/buttons/SaveButton';
import {
  CircularProgress,
  FormControl,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { ErrorMessage, TextField } from '@aginix/mui-react-hook-form-input';
import { FormProvider, useForm } from 'react-hook-form';
import { useProjectEditable } from '@app/views/Project/contexts/ProjectContext';
import AddButton from '@app/shared/components/buttons/AddButton';
import DeleteButton from '@app/shared/components/buttons/DeleteButton';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import EditButton from '@app/shared/components/buttons/EditButton';
import { ToggleState } from '@app/components/ToggleState';

const MemberForm = ({ errors }: any) => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <FormControl fullWidth error={!!errors[`title`]}>
        <TextField
          error={!!errors[`title`]}
          label="คำนำหน้าชื่อ"
          name={`title`}
          variant="outlined"
          size="small"
          rules={{ required: 'กรุณาระบุคำนำหน้าชื่อ' }}
          fullWidth
          required
        />
        <ErrorMessage name={`title`} />
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      <FormControl fullWidth error={!!errors[`first_name`]}>
        <TextField
          error={!!errors[`first_name`]}
          label="ชื่อ"
          name={`first_name`}
          variant="outlined"
          size="small"
          rules={{ required: 'กรุณาระบุชื่อ' }}
          fullWidth
          required
        />
        <ErrorMessage name={`first_name`} />
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      <FormControl fullWidth error={!!errors[`last_name`]}>
        <TextField
          error={!!errors[`last_name`]}
          label="นามสกุล"
          name={`last_name`}
          variant="outlined"
          size="small"
          rules={{ required: 'กรุณาระบุนามสกุล' }}
          fullWidth
          required
        />
        <ErrorMessage name={`last_name`} />
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      <FormControl fullWidth error={!!errors[`position`]}>
        <TextField
          error={!!errors[`position`]}
          label="ตำแหน่ง"
          name={`position`}
          variant="outlined"
          size="small"
          rules={{ required: 'กรุณาระบุตำแหน่ง' }}
          fullWidth
          required
        />
        <ErrorMessage name={`position`} />
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      <FormControl fullWidth error={!!errors[`tel`]}>
        <TextField
          error={!!errors[`tel`]}
          label="เบอร์โทร"
          name={`tel`}
          variant="outlined"
          size="small"
          rules={{ required: 'กรุณาระบุเบอร์โทร' }}
          fullWidth
          required
        />
        <ErrorMessage name={`tel`} />
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      <FormControl fullWidth error={!!errors[`email`]}>
        <TextField
          error={!!errors[`email`]}
          label="อีเมล"
          name={`email`}
          variant="outlined"
          size="small"
          rules={{ required: 'กรุณาระบุอีเมล' }}
          fullWidth
          required
        />
        <ErrorMessage name={`email`} />
      </FormControl>
    </Grid>
  </Grid>
);

const AddMemberDialog = (props: { open: boolean; onClose: () => any; projectId: string }) => {
  const { open, onClose, projectId } = props;
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [insertFn, { loading }] = useInsertProjectMemberOneMutation({
    onCompleted: () => {
      onClose();
      enqueueSuccess('เพิ่มข้อมูลผู้รับผิดชอบสำเร็จ');
    },
    onError: (err) => {
      enqueueFailure('เกิดข้อผิดพลาด: ' + err.name);
    },
    refetchQueries: [
      {
        query: GetProjectMembersByProjectIdDocument,
        variables: {
          projectId,
        },
      },
    ],
  });

  const form = useForm<ProjectMemberFragment>({});

  const handleSubmit = form.handleSubmit((formData) => {
    insertFn({
      variables: {
        data: {
          title: formData.title,
          first_name: formData.first_name,
          last_name: formData.last_name,
          tel: formData.tel,
          email: formData.email,
          position: formData.position,
          project_id: projectId,
          sequence: new Date().getTime(),
        },
      },
    });
  });

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="add-member-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle id="add-member-dialog-title">เพิ่มข้อมูลผู้รับผิดชอบ</DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <form id="member-form" noValidate onSubmit={handleSubmit}>
            <MemberForm errors={form.errors} />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
          Cancel
        </Button>
        <AddButton onClick={handleSubmit} disabled={loading} type="submit" form="member-form" />
      </DialogActions>
    </Dialog>
  );
};

const EditMemberDialog = (props: { onClose: () => any; projectId: string; member: ProjectMemberFragment }) => {
  const { onClose, projectId, member } = props;
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [updateFn, { loading }] = useUpdateProjectMemberByPkMutation({
    onCompleted: () => {
      onClose();
      enqueueSuccess('แก้ไขข้อมูลผู้รับผิดชอบสำเร็จ');
    },
    onError: (err) => {
      enqueueFailure('เกิดข้อผิดพลาด: ' + err.name);
    },
    refetchQueries: [
      {
        query: GetProjectMembersByProjectIdDocument,
        variables: {
          projectId,
        },
      },
    ],
  });
  const form = useForm<ProjectMemberFragment>({
    defaultValues: member,
  });

  const handleSubmit = form.handleSubmit((formData) => {
    updateFn({
      variables: {
        memberId: member.id,
        data: {
          title: formData.title,
          first_name: formData.first_name,
          last_name: formData.last_name,
          tel: formData.tel,
          email: formData.email,
          position: formData.position,
        },
      },
    });
  });

  return (
    <Dialog open={!!member} onClose={onClose} aria-labelledby="add-member-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle id="add-member-dialog-title">แก้ไขข้อมูลผู้รับผิดชอบ</DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <form id="member-form" noValidate onSubmit={handleSubmit}>
            <MemberForm errors={form.errors} />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
          Cancel
        </Button>
        <SaveButton onClick={handleSubmit} disabled={loading} type="submit" form="member-form" />
      </DialogActions>
    </Dialog>
  );
};
export interface ProjectOrganizationAndMembersProps {
  projectId: string;
  render?: () => React.ReactNode;
}

export const ProjectOrganizationAndMembers = ({ projectId, render }: ProjectOrganizationAndMembersProps) => {
  const [selectedMember, setSelectedMember] = useState<ProjectMemberFragment | null>(null);
  const { data, loading } = useGetProjectMembersByProjectIdQuery({
    variables: {
      projectId,
    },
  });
  const editable = useProjectEditable();
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [deleteFn, { loading: deleting }] = useDeleteProjectMemberByPkMutation({
    onCompleted: () => {
      enqueueSuccess('แก้ไขข้อมูลผู้รับผิดชอบสำเร็จ');
    },
    onError: (err) => {
      enqueueFailure('เกิดข้อผิดพลาด: ' + err.name);
    },
    refetchQueries: [
      {
        query: GetProjectMembersByProjectIdDocument,
        variables: {
          projectId,
        },
      },
    ],
  });

  const handleDelete = useCallback(
    (id: string) => {
      if (
        window.confirm(
          'ท่านแน่ใจที่จะลบข้อมูลใช่หรือไม่?\n\nเป็นการลบข้อมูลออกจากระบบถาวร โดยที่จะไม่สามารถกู้คืนกลับมาได้'
        )
      ) {
        deleteFn({
          variables: {
            memberId: id,
          },
        });
      }
    },
    [deleteFn]
  );

  if (!data?.members && loading) {
    return <CircularProgress />;
  }

  return (
    <Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell component="th">คำนำหน้า</TableCell>
            <TableCell component="th">ชื่อ-สกุล</TableCell>
            <TableCell component="th">ตำแหน่ง</TableCell>
            <TableCell component="th">เบอร์โทร</TableCell>
            <TableCell component="th">อีเมล</TableCell>
            {editable && <TableCell component="th" />}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.members.map((member) => (
            <TableRow key={member.id}>
              <TableCell>{member.title}</TableCell>
              <TableCell>
                {member.first_name} {member.last_name}
              </TableCell>
              <TableCell>{member.position}</TableCell>
              <TableCell>{member.tel}</TableCell>
              <TableCell>{member.email}</TableCell>
              {editable && (
                <TableCell>
                  <Grid container justify="flex-end" spacing={1} style={{ maxWidth: 320 }}>
                    <Grid item>
                      <EditButton size="small" onClick={() => setSelectedMember(member)} />
                    </Grid>
                    <Grid item>
                      <DeleteButton onClick={() => handleDelete(member.id)} disabled={deleting} size="small" />
                    </Grid>
                  </Grid>
                </TableCell>
              )}
            </TableRow>
          ))}
          {editable && (
            <TableRow>
              <ToggleState>
                {({ isOpen, open, close }) => (
                  <TableCell colSpan={7}>
                    <AddButton onClick={open} variant="outlined" size="small">
                      เพิ่มผู้รับผิดชอบ
                    </AddButton>
                    <AddMemberDialog onClose={close} open={isOpen} projectId={projectId} />
                  </TableCell>
                )}
              </ToggleState>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {selectedMember && (
        <EditMemberDialog member={selectedMember} onClose={() => setSelectedMember(null)} projectId={projectId} />
      )}
    </Fragment>
  );
};
