import Logo from '../../static/moi-sme-logo.png';

const PageLoading = () => {
  return (
    <div className="full-page-loader">
      <img width="200" src={Logo} alt="Thaismefund" />
    </div>
  );
};

export default PageLoading;
