import { useChangePasswordMutation } from '@app/generated/graphql';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl } from '@material-ui/core';
import React, { Fragment, useCallback, useState } from 'react';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { useForm, FormProvider } from 'react-hook-form';
import SaveButton from '@app/shared/components/buttons/SaveButton';
import { ErrorMessage, TextField } from '@aginix/mui-react-hook-form-input';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';

const ChangePasswordDialog = ({ accountId }: { accountId: string }) => {
  const { enqueueSuccess } = useResponseBar();
  const [open, setOpen] = useState(false);
  const form = useForm<{ password: string }>();

  const [changePasswordFn, { loading }] = useChangePasswordMutation({
    onCompleted: () => {
      enqueueSuccess('เปลี่ยนรหัสผ่านสำเร็จ');
      handleClose();
    },
  });

  const handleSubmit = form.handleSubmit((formData) => {
    changePasswordFn({
      variables: {
        data: {
          accountId: accountId,
          newPassword: formData.password.trim().replace(/ /g, ''),
        },
      },
    });
  });

  const handleClose = useCallback(() => {
    setOpen(false);
    form.reset({
      password: '',
    });
  }, [form]);

  const handleOpen = useCallback(() => setOpen(true), []);

  return (
    <Fragment>
      <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<VpnKeyIcon />}>
        เปลี่ยนรหัสผ่าน
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>เปลี่ยนรหัสผ่านผู้ใช้งาน</DialogTitle>
        <DialogContent>
          <FormProvider {...form}>
            <form id="change-password-form" onSubmit={handleSubmit}>
              <FormControl fullWidth error={!!form.errors['password']}>
                <TextField
                  error={!!form.errors['password']}
                  name="password"
                  label="password"
                  variant="outlined"
                  type="password"
                  rules={{
                    required: 'กรุณาระบุรหัสผ่าน',
                    minLength: { value: 3, message: 'รหัสผ่านไม่น้อยกว่า 3 ตัวอักษร' },
                  }}
                  required
                  fullWidth
                />
                <ErrorMessage name="password" />
              </FormControl>
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="default" onClick={handleClose} disabled={loading}>
            ยกเลิก
          </Button>
          <SaveButton type="submit" form="change-password-form" onClick={handleSubmit} disabled={loading}>
            เปลี่ยนรหัสผ่าน
          </SaveButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ChangePasswordDialog;
