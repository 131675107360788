import { withIfUser } from '@app/components/common/Routes';
import { hasPermissions } from '@app/utils';
import JuristicDetail from '@app/views/LoanProgram/Detail';
import { memo } from 'react';

export default memo(
  withIfUser(JuristicDetail)({
    condition: ({ roles }) => roles.some(hasPermissions),
    redirect: true,
    redirectTo: '/unauthorized',
  })
);
