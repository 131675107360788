import { months } from '@app/shared/libs/date';

export function mapper(data: any[]) {
  return data.map((r) => ({
    งบประมาณ: r.budget.description,
    รหัสบัญชี: r.account.code,
    ชื่อบัญชี: r.account.name,
    [months[9]]: r['october'] || 0,
    [months[10]]: r['november'] || 0,
    [months[11]]: r['december'] || 0,
    [months[0]]: r['january'] || 0,
    [months[1]]: r['february'] || 0,
    [months[2]]: r['march'] || 0,
    [months[3]]: r['april'] || 0,
    [months[4]]: r['may'] || 0,
    [months[5]]: r['june'] || 0,
    [months[6]]: r['july'] || 0,
    [months[7]]: r['august'] || 0,
    [months[8]]: r['september'] || 0,
  }));
}
