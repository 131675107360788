import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Home } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paperWidthXs': {
    maxWidth: '330px',
    padding: '16px',
  },
});

export default function ErrorPage() {
  const search = useLocation().search;
  const errorMessage = new URLSearchParams(search).get('error') || `เกิดข้อผิดพลาดไม่ทราบสาเหตุ กรุณาลองใหม่อีกครั้ง`;

  return (
    <Box>
      <StyledDialog open maxWidth="xs" fullWidth>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <img src="/error-page.svg" alt="error" style={{ width: '255px' }} />
            <Typography variant="h6" align="center">
              เกิดข้อผิดพลาด
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
              {errorMessage}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button startIcon={<Home />} component="a" href="/" variant="contained" color="primary" fullWidth>
            กลับสู่หน้าหลัก
          </Button>
        </DialogActions>
      </StyledDialog>
    </Box>
  );
}
