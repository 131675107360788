import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

/**
 * ใช้สำหรับหน้าที่มีการเรียก Route ที่เป็น Modal
 */
export function useMounted() {
  const location = useLocation();
  const match = useRouteMatch();
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    if (mounted) return;
    setMounted(match.path === location.pathname);
  }, [mounted, match, location]);

  return mounted;
}
