import React, { Fragment, useState } from 'react';
import { UnitFragment } from '@app/generated/graphql';
import { TextField, Typography } from '@material-ui/core';

export const SampleUnit = ({ data }: { data: UnitFragment }) => {
  const [amount, setAmount] = useState(1);

  return (
    <Fragment>
      <TextField
        onChange={(event) => {
          const value = event.target.value;
          if (value) {
            setAmount(Number(value));
          }
        }}
        value={amount}
      />
      <Typography variant="body1">
        จำนวน <b>{amount}</b> {data.description}
      </Typography>
    </Fragment>
  );
};
