import { GetProjectByPkWithMasterDataQuery } from '@app/generated/graphql';
import React, { useContext } from 'react';

export const ProjectContext = React.createContext<Required<GetProjectByPkWithMasterDataQuery> | null>(null);

export function useProject(): Required<GetProjectByPkWithMasterDataQuery> {
  const data = useContext(ProjectContext);

  if (data === null) {
    throw new Error('Cannot find ProjectContext');
  }

  return data;
}

export function useProjectEditable(): boolean {
  const data = useContext(ProjectContext);

  if (data === null) {
    return true;
  }

  const { project } = data;
  return ['draft', 'change_request'].includes(project?.step_id || 'draft');
}
