import { withIfUser } from '@app/components/common/Routes';
import { hasPermissions } from '@app/utils';
import StrategySubjectCreate from '@app/views/ProjectStrategy/Create';
import { memo } from 'react';

export default memo(
  withIfUser(StrategySubjectCreate)({
    condition: ({ roles }) => roles.some(hasPermissions),
    redirect: true,
    redirectTo: '/unauthorized',
  })
);
