import { Box } from '@material-ui/core';
import React from 'react';
import { useRowFlexStyles } from '@mui-treasury/styles/flex/row';

export interface PageTitleProps {
  title?: React.ReactNode;
  actions?: React.ReactNode;
}

const PageTitle = ({ title, actions }: PageTitleProps) => {
  const flexStyles = useRowFlexStyles();
  return (
    <Box className={flexStyles.parent}>
      <Box>{title}</Box>
      <Box className={flexStyles.rightChild}>{actions}</Box>
    </Box>
  );
};

export default PageTitle;
