import React, { memo } from 'react';
import { Stepper, Step, StepLabel } from '@material-ui/core';

export interface ProjectStepperProps {
  step: number;
}

const ProjectStepper = ({ step }: ProjectStepperProps) => {
  return (
    <Stepper activeStep={step} alternativeLabel>
      <Step>
        <StepLabel>ข้อมูลโครงการ</StepLabel>
      </Step>
      <Step>
        <StepLabel>วัตถุประสงค์</StepLabel>
      </Step>
      <Step>
        <StepLabel>กิจกรรมดำเนินงาน</StepLabel>
      </Step>
      <Step>
        <StepLabel>ความสอดคล้อง</StepLabel>
      </Step>
      <Step>
        <StepLabel>งบประมาณ</StepLabel>
      </Step>
      <Step>
        <StepLabel>แผนงาน</StepLabel>
      </Step>
      <Step>
        <StepLabel>แนบไฟล์เอกสาร</StepLabel>
      </Step>
      <Step>
        <StepLabel>ผู้รับผิดชอบโครงการ</StepLabel>
      </Step>
      <Step>
        <StepLabel>ตรวจสอบความถูกต้อง</StepLabel>
      </Step>
      <Step>
        <StepLabel>ส่งเรื่อง</StepLabel>
      </Step>
    </Stepper>
  );
};

export default memo(ProjectStepper);
