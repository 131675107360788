import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Box, Button, Card, CardContent, FormControl, FormHelperText, TextField } from '@material-ui/core';
import { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import ButtonCircularProgress from '@app/shared/components/ButtonCircularProgress';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';

export const SignInWithEmailPassword = () => {
  const { enqueueFailure } = useResponseBar();
  const [expanded, setExpanded] = useState(false);
  const form = useForm();

  const { mutate, isLoading, error } = useMutation({
    mutationFn: async (input: { email: string; password: string }) => {
      return signInWithEmailAndPassword(getAuth(), input.email, input.password);
    },
    onSuccess(data, variables, context) {
      // Hard redirect to first page
      window.location.href = '/';
    },
    onError: (error) => {
      if (error instanceof Error) {
        enqueueFailure(error.message);
      } else {
        enqueueFailure(`เกิดข้อผิดพลาดในการเข้าสู่ระบบ กรุณาลองอีกครั้ง`);
      }
    },
  });

  const handleSubmit = form.handleSubmit(async (formValues) => {
    mutate({ email: formValues.email, password: formValues.password });
  });

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        style={{
          backgroundColor: '#fff',
        }}
        fullWidth
        onClick={() => setExpanded(!expanded)}
        startIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      >
        เข้าระบบด้วยอีเมลและรหัสผ่าน
      </Button>
      {expanded && (
        <Box
          sx={{
            mt: 2,
          }}
        >
          <Card variant="outlined">
            <CardContent>
              <FormProvider {...form}>
                <form
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.75rem',
                  }}
                  noValidate
                  // className={classes.form}
                  onSubmit={handleSubmit}
                >
                  <Controller
                    name="email"
                    defaultValue=""
                    rules={{
                      required: true,
                    }}
                    render={(field) => (
                      <FormControl fullWidth error={Boolean(form.formState.errors.email)}>
                        <TextField
                          {...field}
                          inputRef={field.ref}
                          margin="normal"
                          required
                          fullWidth
                          size="small"
                          variant="outlined"
                          id="email"
                          label="Email Address"
                          type="email"
                          name="email"
                          autoComplete="email"
                          autoFocus
                        />
                      </FormControl>
                    )}
                  />
                  <Controller
                    name="password"
                    defaultValue=""
                    rules={{
                      required: true,
                    }}
                    render={(field) => (
                      <FormControl fullWidth error={Boolean(form.formState.errors.password)}>
                        <TextField
                          {...field}
                          inputRef={field.ref}
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          size="small"
                          variant="outlined"
                          type="password"
                          id="password"
                          autoComplete="current-password"
                        />
                      </FormControl>
                    )}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    // className={classes.submit}
                    disabled={isLoading}
                  >
                    เข้าสู่ระบบ
                    {isLoading && <ButtonCircularProgress />}
                  </Button>
                  {error && <FormHelperText error>{(error as any).message}</FormHelperText>}
                </form>
              </FormProvider>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
};
