import Spacing from '@app/components/common/Spacing';
import Page from '@app/components/Page';
import { setYear } from 'date-fns';
import { PageHeader } from '@app/components/PageHeader';
import {
  ProjectFragment,
  useInsertProjectOneMutation,
  ProjectProject,
  useGetProjectMasterDataQuery,
  ProjectProjectOperatingArea,
} from '@app/generated/graphql';
import AddButton from '@app/shared/components/buttons/AddButton';
import BackButton from '@app/shared/components/buttons/BackButton';
import PageTitle from '@app/shared/components/PageTitle';
import { pageContent } from '@app/shared/hoc/page-content';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { Form } from '@app/views/Project/components/Form';
import { Divider } from '@material-ui/core';
import { InfoCard } from '@mystiny/ui';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import ProjectStepper from './components/ProjectStepper';
import { DateUtils } from '@app/shared/libs/date';

const ProjectCreate = () => {
  const history = useHistory();
  const { data, loading: fetching } = useGetProjectMasterDataQuery();
  const form = useForm<ProjectFragment>({
    defaultValues: {
      fiscal_year: new Date().getFullYear() + 543 + 2,
      start_at: setYear(new Date(), new Date().getFullYear() + 2),
      end_at: setYear(new Date(), new Date().getFullYear() + 2),
      project_target_groups: [],
    },
  });
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [createFn, { loading }] = useInsertProjectOneMutation({
    onCompleted: ({ insert_project_project_one }) => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
      history.push(`/bof/projects/proposals/${insert_project_project_one?.id}`);
    },
    onError: (err: any) => {
      console.error(err);
      enqueueFailure('เกิดข้อผิดพลาด');
    },
  });

  const handleSubmit = form.handleSubmit((formData: ProjectProject) => {
    const areas = (formData as any)['operating_areas'];
    formData['project_operating_areas'] = Object.keys(areas)
      .filter((provinceId) => areas[provinceId])
      .map((provinceId, index) => ({
        province_id: provinceId,
        sequence: index,
      })) as ProjectProjectOperatingArea[];

    const startAt = DateUtils.getStartThatFiscalYearDate(setYear(new Date(), Number(formData.fiscal_year) - 543));
    const endAt = DateUtils.getEndThatFiscalYearDate(setYear(new Date(), Number(formData.fiscal_year) - 543));

    createFn({
      variables: {
        object: {
          introduction: formData.introduction,
          title: formData.title,
          effect: formData.effect,
          start_at: startAt,
          end_at: endAt,
          last_overall_operation: formData.last_overall_operation,
          industry: formData.industry,
          fiscal_year: formData.fiscal_year,
          organization_id: formData.organization_id,
          project_target_groups: {
            data:
              (formData.project_target_groups?.map((obj) => ({
                target_group_id: obj.target_group_id,
              })) as any) || [],
          },
          project_operating_areas: {
            data: formData.project_operating_areas as any,
          },
          project_event_logs: {
            data: [
              {
                step_id: 'draft',
                description: 'สร้างคำขอโครงการ',
              },
            ],
          },
        },
      },
    });
  });

  return (
    <Page
      title={
        <PageTitle
          title={<PageHeader>สร้างคำของบประมาณ</PageHeader>}
          actions={
            <div>
              <BackButton to="/bof/projects/proposals" />
            </div>
          }
        />
      }
    >
      <InfoCard
        actions={
          <AddButton disabled={loading} onClick={handleSubmit}>
            สร้างคำของบประมาณ
          </AddButton>
        }
      >
        <ProjectStepper step={0} />
        <Divider />
        <FormProvider {...form}>{!fetching && <Form mode="create" data={data} />}</FormProvider>
      </InfoCard>
      <Spacing />
    </Page>
  );
};

export default pageContent(ProjectCreate);
