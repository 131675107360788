import React, { Fragment, useMemo, useState } from 'react';
import { Filters, Row } from 'react-data-grid';
import { LedgerReceivableStatusEnum, useGetReceivablesQuery } from '@app/generated/graphql';
import { receivableColumns } from './columns';
import DataGridContainer from '@app/shared/components/DataGridContainer';
import LoadMoreRowsTag from '@app/shared/components/LoadMoreRowsTag';
import { listPageContent } from '@app/shared/hoc/list-page';
import DataGridFetchMore from '@app/shared/components/DataGridFetchMore';
import { useRdgSort } from '@app/shared/hooks/useRdgSort';
import PageTitle from '@app/shared/components/PageTitle';
import Spacing from '@app/components/common/Spacing';
import LinkButton from '@app/shared/components/buttons/LinkButton';
import AddIcon from '@material-ui/icons/Add';
import { Typography } from '@material-ui/core';
import { useTypography } from '@app/shared/styles/typography';
import { ReceivableFilter } from './components/ReceivableFilter';
import { InfoCard } from '@mystiny/ui';
import { ExportButton } from './components/receivable-export/ExportButton';
import { useDebounce } from 'use-debounce/lib';
import { useHistory } from 'react-router-dom';

const ReceivableList = () => {
  const history = useHistory();
  const typographyClasses = useTypography();
  const [receivableStatus, setReceivableStatus] = useState<LedgerReceivableStatusEnum | null>(null);
  const [offset, setOffset] = useState(0);
  const { handleSort, orderBy, sort } = useRdgSort({
    column: 'created_at',
    direction: 'DESC',
  });
  const [filters, setFilters] = useState<Filters>({});

  const [value] = useDebounce(filters, 200);

  const { data, fetchMore, loading, variables } = useGetReceivablesQuery({
    variables: {
      where: {
        status_id: {
          _eq: receivableStatus ? receivableStatus : undefined,
        },
        budget: {
          description: {
            _ilike: value['budget.description'] ? `%${value['budget.description']}%` : undefined,
          },
        },
        gfmis_id: {
          _ilike: value['gfmis_id'] ? `%${value['gfmis_id']}%` : undefined,
        },
        reference: {
          _ilike: value['reference'] ? `%${value['reference']}%` : undefined,
        },
        borrower_name: {
          _ilike: value['borrower_name'] ? `%${value['borrower_name']}%` : undefined,
        },
        proposal: {
          _ilike: value['proposal'] ? `%${value['proposal']}%` : undefined,
        },
        note: {
          _ilike: value['note'] ? `%${value['note']}%` : undefined,
        },
        organization: {
          short_name: {
            _ilike: value['organization.short_name'] ? `%${value['organization.short_name']}%` : undefined,
          },
        },
      },
      limit: 200,
      offset: 0,
      orderBy: orderBy,
    },
    fetchPolicy: 'cache-and-network',
  });

  const generalReceivableEntryList = useMemo(
    () => (
      <DataGridContainer height="80vh">
        <DataGridFetchMore
          fetchMore={fetchMore}
          variables={variables}
          offset={offset}
          setOffset={setOffset}
          sortColumn={sort?.column}
          sortDirection={sort?.direction}
          onSort={handleSort}
          defaultColumnOptions={{ sortable: true, resizable: true }}
          rowRenderer={(props) => (
            <Row {...props} onDoubleClick={() => history.push(`/bof/accounting/receivables/${props.row.id}`)} />
          )}
          rowHeight={32}
          rows={(data?.rows as any) || []}
          columns={receivableColumns}
          filters={filters}
          onFiltersChange={setFilters}
          enableFilters
        />
        <LoadMoreRowsTag loading={loading} />
      </DataGridContainer>
    ),
    [data, fetchMore, loading, variables, offset, setOffset, sort, handleSort, filters, setFilters, history]
  );

  return (
    <React.Fragment>
      <PageTitle
        title={
          <Typography variant="h5" color="primary" className={typographyClasses.pageTitle}>
            ทะเบียนคุมลูกหนี้เงินยืม
          </Typography>
        }
        actions={
          <Fragment>
            <ExportButton data={data?.rows || []} name="ทะเบียนคุมลูกหนี้เงินยืม" />
            <LinkButton startIcon={<AddIcon />} to={`/bof/accounting/receivables/create`}>
              สร้างข้อมูล
            </LinkButton>
          </Fragment>
        }
      />
      <Spacing />
      <InfoCard noPadding>
        <ReceivableFilter index={receivableStatus} setIndex={setReceivableStatus} />
        {generalReceivableEntryList}
      </InfoCard>
    </React.Fragment>
  );
};

export default listPageContent(ReceivableList);
