import { ErrorMessage, TextField } from '@aginix/mui-react-hook-form-input';
import Spacing from '@app/components/common/Spacing';
import {
  ProjectFragment,
  GetProjectActivitiesByProjectIdQuery,
  ProjectBudgetGroupEnum,
  useReplaceProjectOperatingActivitiesMutation,
  ProjectProjectOperatingActivityInsertInput,
  ProjectOperatingActivityTypeEnum,
  useGetProjectActivitiesByProjectIdQuery,
} from '@app/generated/graphql';
import AutocompleteInput from '@app/shared/components/AutocompleteInput';
import AddButton from '@app/shared/components/buttons/AddButton';
import DeleteButton from '@app/shared/components/buttons/DeleteButton';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { useProjectEditable } from '@app/views/Project/contexts/ProjectContext';
import { CircularProgress, Divider, FormControl, Grid, InputAdornment } from '@material-ui/core';
import { get } from 'lodash';
import React, { Fragment, useCallback, useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { createNumberFormatInput } from '@app/components/NumberFormatInput';
import { hasError } from '@app/utils';

const NumberFormatInput = createNumberFormatInput({
  allowNegative: false,
  allowEmptyFormatting: false,
  thousandSeparator: true,
});

const ProjectOperatingActivitiesForm = ({
  activities,
  budget_groups,
  units,
  render,
  projectId,
}: GetProjectActivitiesByProjectIdQuery & ProjectOperatingActivitiesProps) => {
  const disabled = !useProjectEditable();
  const form = useForm<ProjectFragment>({
    defaultValues: {
      project_operating_activities: activities,
    },
  });

  const { enqueueSuccess, enqueueFailure } = useResponseBar();

  const [replaceFn, { loading }] = useReplaceProjectOperatingActivitiesMutation({
    onCompleted: () => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
    },
    onError: () => {
      enqueueFailure('เกิดข้อผิดพลาด ไม่สามารถบันทึกข้อมูลได้');
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: 'project_operating_activities',
    control: form.control,
  });

  const handleAppend = useCallback(() => {
    append({
      name: '',
      budget_group_id: ProjectBudgetGroupEnum.Self,
      sequence: 100,
    });
  }, [append]);

  useEffect(() => {
    if (fields.length === 0) {
      append({
        name: '',
        budget_group_id: ProjectBudgetGroupEnum.Self,
        sequence: 100,
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleSubmit = form.handleSubmit((formData) => {
    const ids = formData.project_operating_activities.map((activity) => activity.id);

    const data: ProjectProjectOperatingActivityInsertInput[] = formData.project_operating_activities.map(
      (activity) =>
        (({
          ...activity,
          target_amount: activity.target_amount ? Number(activity.target_amount) : null,
          project_id: projectId,
          operating_activity_type_id: ProjectOperatingActivityTypeEnum.Primary,
        } as unknown) as ProjectProjectOperatingActivityInsertInput)
    );
    replaceFn({
      variables: {
        projectId: projectId,
        insertedActivityIds: ids,
        insertActivityData: data,
      },
    });
  });

  return (
    <FormProvider {...form}>
      <Grid container spacing={3}>
        {fields.map((field, index) => (
          <Fragment key={field.id}>
            <span style={{ display: 'none' }}>
              <TextField
                disabled={disabled}
                name={`project_operating_activities[${index}].id`}
                defaultValue={field.id}
              />
              <TextField
                disabled={disabled}
                name={`project_operating_activities[${index}].sequence`}
                defaultValue={index + 1}
              />
              <TextField
                disabled={disabled}
                name={`project_operating_activities[${index}].budget_group_id`}
                defaultValue={field.budget_group_id}
              />
            </span>
            <Grid item container xs={12}>
              <Grid item xs={12} sm={10}>
                <FormControl fullWidth error={hasError(form.errors, `project_operating_activities[${index}].name`)}>
                  <TextField
                    disabled={disabled}
                    label="ชื่อกิจกรรม"
                    defaultValue={field.name}
                    name={`project_operating_activities[${index}].name`}
                    error={hasError(form.errors, `project_operating_activities[${index}].name`)}
                    variant="outlined"
                    size="small"
                    rules={{ required: 'กรุณาระบุชื่อกิจกรรม' }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{index + 1}.</InputAdornment>,
                    }}
                    fullWidth
                    required
                  />
                  <ErrorMessage name={`project_operating_activities[${index}].name`} />
                </FormControl>
              </Grid>
              {!disabled && (
                <Grid item xs={12} sm={2}>
                  <DeleteButton onClick={() => remove(index)} disabled={index === 0} />
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={!!get(form.errors, `project_operating_activities[${index}].budget_group_id`)}
                >
                  <AutocompleteInput
                    disabled={disabled}
                    label={`หมวดค่าใช้จ่าย`}
                    defaultValue={field.budget_group_id}
                    name={`project_operating_activities[${index}].budget_group_id`}
                    options={budget_groups || []}
                    primaryKey="id"
                    getOptionLabel={(option: any) => `${option.description}`}
                    rules={{ required: 'กรุณาระบุหมวดค่าใช้จ่าย' }}
                    required
                    size="small"
                    disableClearable
                  />
                  <ErrorMessage name={`project_operating_activities[${index}].budget_group_id`} />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    disabled={disabled}
                    label="อธิบายกิจกรรมดำเนินงาน"
                    defaultValue={field.description}
                    name={`project_operating_activities[${index}].description`}
                    variant="outlined"
                    size="small"
                    rows={4}
                    rowsMax={20}
                    multiline
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    disabled={disabled}
                    label="จำนวนเป้าหมาย"
                    defaultValue={field.target_amount}
                    name={`project_operating_activities[${index}].target_amount`}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      inputComponent: NumberFormatInput as any,
                    }}
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <AutocompleteInput
                    disabled={disabled}
                    label="หน่วยเป้าหมาย"
                    name={`project_operating_activities[${index}].target_unit_id`}
                    defaultValue={field.target_unit_id}
                    options={units || []}
                    primaryKey="id"
                    getOptionLabel={(option: any) => `${option.description}`}
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Spacing />
              <Divider variant="middle" />
              <Spacing />
            </Grid>
          </Fragment>
        ))}
        {!disabled && (
          <Grid item xs={12}>
            <AddButton onClick={handleAppend} disabled={loading}>
              เพิ่มกิจกรรมดำเนินงาน
            </AddButton>
          </Grid>
        )}
      </Grid>
      <Spacing />
      <Spacing />
      {render && render({ onSubmit: handleSubmit })}
    </FormProvider>
  );
};

export interface ProjectOperatingActivitiesProps {
  projectId: string;
  render?: (props: { onSubmit: () => any }) => React.ReactNode;
}

export const ProjectOperatingActivities = ({ projectId, render }: ProjectOperatingActivitiesProps) => {
  const { data, loading } = useGetProjectActivitiesByProjectIdQuery({
    variables: {
      projectId,
    },
  });

  if (!data?.activities && loading) {
    return <CircularProgress />;
  }

  return <ProjectOperatingActivitiesForm {...(data as any)} projectId={projectId} render={render} />;
};
