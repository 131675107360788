import React from 'react';
import TextFilter from '@app/components/data-grid/TextFilter';
import { TargetGroupFragment } from '@app/generated/graphql';
import { Column } from 'react-data-grid';

export const targetGrouponColumns: Column<TargetGroupFragment>[] = [
  {
    key: 'id',
    name: 'ID',
    filterRenderer: (p) => <TextFilter {...p} />,
    resizable: true,
  },
  {
    key: 'description',
    name: 'คำอธิบาย กลุ่มเป้าหมาย',
    filterRenderer: (p) => <TextFilter {...p} />,
    resizable: true,
  },
];
