import { useMounted } from '@app/shared/hooks/useMounted';
import React from 'react';

export const withModalPage = <P extends object>(Component: React.ComponentType<P>): React.FC<P> => (props) => {
  const mounted = useMounted();
  if (!mounted) {
    return null;
  }

  return <Component {...props} />;
};
