import React, { Fragment, useMemo, useState } from 'react';
import { Filters, Row } from 'react-data-grid';
import { LedgerLedgerStatusEnum, LedgerLedgerTypeEnum, useGetLedgersQuery } from '@app/generated/graphql';
import { ledgerColumns } from './columns';
import DataGridContainer from '@app/shared/components/DataGridContainer';
import LoadMoreRowsTag from '@app/shared/components/LoadMoreRowsTag';
import { listPageContent } from '@app/shared/hoc/list-page';
import DataGridFetchMore from '@app/shared/components/DataGridFetchMore';
import { useRdgSort } from '@app/shared/hooks/useRdgSort';
import PageTitle from '@app/shared/components/PageTitle';
import Spacing from '@app/components/common/Spacing';
import LinkButton from '@app/shared/components/buttons/LinkButton';
import AddIcon from '@material-ui/icons/Add';
import { Grid, Typography } from '@material-ui/core';
import { useTypography } from '@app/shared/styles/typography';
import LedgerStatusFilterMenu from './components/LedgerStatusFilterMenu';
import LedgerTypeFilter from './components/LedgerTypeFilter';
import { InfoCard } from '@mystiny/ui';
import { ExportButton } from './components/ledger-export/ExportButton';
import { useDebounce } from 'use-debounce/lib';
import { useHistory } from 'react-router-dom';

const LedgerList = () => {
  const typographyClasses = useTypography();
  const history = useHistory();
  const [ledgerStatus, setLedgerStatus] = useState<LedgerLedgerStatusEnum | null>(null);
  const [ledgerType, setLedgerType] = useState<LedgerLedgerTypeEnum>(LedgerLedgerTypeEnum.Expense);
  const [offset, setOffset] = useState(0);
  const { handleSort, orderBy, sort } = useRdgSort({
    column: 'created_at',
    direction: 'DESC',
  });
  const [filters, setFilters] = useState<Filters>({});

  const [value] = useDebounce(filters, 200);

  const { data, fetchMore, loading, variables } = useGetLedgersQuery({
    variables: {
      where: {
        status_id: {
          _eq: ledgerStatus ? ledgerStatus : undefined,
        },
        type_id: {
          _eq: ledgerType ? ledgerType : undefined,
        },
        gfmis_id: {
          _ilike: value['gfmis_id'] ? `%${value['gfmis_id']}%` : undefined,
        },
        note: {
          _ilike: value['note'] ? `%${value['note']}%` : undefined,
        },
        organization: {
          short_name: {
            _ilike: value['organization.short_name'] ? `%${value['organization.short_name']}%` : undefined,
          },
        },
        budget: {
          description: {
            _ilike: value['budget.description'] ? `%${value['budget.description']}%` : undefined,
          },
        },
        created_by_user: {
          display_name: {
            _ilike: value['created_by_user.display_name'] ? `%${value['created_by_user.display_name']}%` : undefined,
          },
        },
        _or: [
          {
            account: {
              _or: [
                {
                  name: {
                    _ilike: value['account.name'] ? `%${value['account.name']}%` : undefined,
                  },
                },
                {
                  code: {
                    _ilike: value['account.name'] ? `%${value['account.name']}%` : undefined,
                  },
                },
              ],
            },
          },
        ],
      },
      limit: 200,
      offset: 0,
      orderBy: orderBy,
    },
    fetchPolicy: 'cache-and-network',
  });

  const generalLedgerEntryList = useMemo(
    () => (
      <DataGridContainer height="80vh">
        <DataGridFetchMore
          fetchMore={fetchMore}
          variables={variables}
          offset={offset}
          setOffset={setOffset}
          sortColumn={sort?.column}
          sortDirection={sort?.direction}
          onSort={handleSort}
          defaultColumnOptions={{ sortable: true, resizable: true }}
          rowRenderer={(props) => (
            <Row {...props} onDoubleClick={() => history.push(`/bof/accounting/ledgers/${props.row.id}`)} />
          )}
          rowHeight={32}
          rows={(data?.rows as any) || []}
          columns={ledgerColumns}
          filters={filters}
          onFiltersChange={setFilters}
          enableFilters
        />
        <LoadMoreRowsTag loading={loading} />
      </DataGridContainer>
    ),
    [data, fetchMore, loading, variables, offset, setOffset, sort, handleSort, filters, setFilters, history]
  );

  return (
    <React.Fragment>
      <PageTitle
        title={
          <Typography variant="h5" color="primary" className={typographyClasses.pageTitle}>
            ค่าใช้จ่าย
          </Typography>
        }
        actions={
          <Fragment>
            <ExportButton data={data?.rows || []} name="ค่าใช้จ่าย" />
            <LinkButton
              startIcon={<AddIcon />}
              to={`/bof/accounting/ledgers/create`}
              disabled={ledgerType !== LedgerLedgerTypeEnum.Expense}
            >
              เพิ่มค่าใช้จ่าย (EX1)
            </LinkButton>
          </Fragment>
        }
      />
      <Spacing />
      <Grid container spacing={1}>
        <Grid item xs={12} md={2}>
          <LedgerStatusFilterMenu selectedStatus={ledgerStatus} handleSelectedStatus={setLedgerStatus} />
        </Grid>
        <Grid item xs={12} md={10}>
          <InfoCard noPadding>
            <LedgerTypeFilter index={ledgerType} setIndex={setLedgerType} />
            {generalLedgerEntryList}
          </InfoCard>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default listPageContent(LedgerList);
