import React from 'react';
import { GetNationalStrategiesTreeViewQuery, useGetNationalStrategiesTreeViewQuery } from '@app/generated/graphql';
import { listPageContent } from '@app/shared/hoc/list-page';
import { InfoCard } from '@mystiny/ui';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    marginTop: 4,
    marginBottom: 4,
  },
  label: {
    fontSize: '1.2rem',
  },
});

export const RecursiveTreeView = () => {
  const classes = useStyles();
  const { data, loading } = useGetNationalStrategiesTreeViewQuery();

  const renderTree = (nodes: GetNationalStrategiesTreeViewQuery['national_strategies'][0]) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={nodes.name}
      classes={{ label: classes.label, root: classes.root }}
    >
      {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node as any)) : null}
    </TreeItem>
  );

  if (loading) {
    return <CircularProgress />;
  }

  const strategies = data?.national_strategies;

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={['root']}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      <TreeItem
        nodeId="national_strategy"
        label="ยุทธศาสตร์ชาติ 20 ปี"
        classes={{ label: classes.label, root: classes.root }}
      >
        {strategies?.map((strategy) => renderTree(strategy))}
      </TreeItem>
    </TreeView>
  );
};

const StrategyTreeView = () => {
  return (
    <React.Fragment>
      <InfoCard title="ยุทธศาสตร์ชาติ แผนภาพต้นไม้">
        <RecursiveTreeView />
      </InfoCard>
    </React.Fragment>
  );
};

export default listPageContent(StrategyTreeView);
