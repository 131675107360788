import React, { Fragment } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { green, red } from '@material-ui/core/colors';

export interface ArchiveViewProps {
  value: boolean;
}

export const ArchiveView = ({ value }: ArchiveViewProps) => {
  return (
    <Fragment>
      {value ? <CheckIcon style={{ color: green[500] }} /> : <CloseIcon style={{ color: red[500] }} />}
    </Fragment>
  );
};
