import React from 'react';
import { Route, RouteProps, RouteComponentProps } from 'react-router-dom';

interface RouteWithLayoutProps extends RouteProps {
  layout: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

const RouteWithLayout = ({ component: Component, layout: Layout, ...routeProps }: RouteWithLayoutProps) => (
  <Route
    {...routeProps}
    render={(props: any) => (
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    )}
  />
);

export default RouteWithLayout;
