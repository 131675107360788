import { Fragment } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';
import LoginWithIndustrySSO from '@app/views/Login/sign_in_with_industry_sso';
import SignInWithGoogleProvider from '../../firebase/firebase_sign_in_with_google_provider';
import { SignInWithEmailPassword } from '@app/views/Login/sign_in_with_email_password';

export default function LoginPage() {
  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '100vh',
        }}
      >
        <Box
          sx={{
            marginTop: {
              xs: 4,
              md: 16,
            },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: {
              xs: '80vw',
              md: '420px',
            },
          }}
        >
          <img
            alt="Thaismefund Logo"
            src="/moi-sme-logo.png"
            style={{
              width: 200,
              height: 'auto',
            }}
          />
          <Box
            sx={{
              my: 2,
            }}
          >
            <Typography align="center" variant="h5" style={{ fontWeight: 500 }} gutterBottom>
              ระบบสารสนเทศ <br />
              กองทุนพัฒนาเอสเอ็มอี ตามแนวประชารัฐ
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LoginWithIndustrySSO />
            </Grid>
            <Grid item xs={12}>
              <SignInWithGoogleProvider />
            </Grid>
            <Grid item xs={12}>
              <SignInWithEmailPassword />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{
                  fontWeight: 500,
                  marginTop: 16,
                }}
                component="div"
                align="center"
              >
                หากพบปัญหาหรือไม่สามารถเข้าสู่ระบบได้ <br /> กรุณาติดต่อเจ้าหน้าที่ส่วนกลาง <br />
                <Link href="https://thaismefund.com/" target="_blank" rel="noopener noreferrer">
                  https://thaismefund.com/
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Fragment>
  );
}
