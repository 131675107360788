import { useUpdateAccountByPkMutation, AccountFullFragment, useGetAccountByPkQuery } from '@app/generated/graphql';
import Page from '@app/components/Page';
import DescriptionIcon from '@material-ui/icons/Description';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import PageTitle from '@app/shared/components/PageTitle';
import BackButton from '@app/shared/components/buttons/BackButton';
import { InfoCard } from '@mystiny/ui';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { FormProvider, useForm } from 'react-hook-form';
import Spacing from '@app/components/common/Spacing';
import SaveButton from '@app/shared/components/buttons/SaveButton';
import { ToggleArchiveButton } from '@app/components/ToggleArchiveButton';
import { Form } from './components/Form';
import { useParams } from 'react-router-dom';
import { pageContent } from '@app/shared/hoc/page-content';

const AccountDetailForm = ({ data }: { data: AccountFullFragment }) => {
  const form = useForm<AccountFullFragment>({
    defaultValues: data,
  });
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [updateFn, { loading }] = useUpdateAccountByPkMutation({
    onCompleted: () => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
    },
    onError: (err: any) => {
      console.error(err);
      enqueueFailure('เกิดข้อผิดพลาด');
    },
  });

  const handleSubmit = form.handleSubmit((formData: AccountFullFragment) => {
    updateFn({
      variables: {
        id: data.id,
        _set: {
          code: formData.code,
          name: formData.name,
          fiscal_year: formData.fiscal_year,
          note: formData.note,
          type_id: formData.type_id,
          category_id: formData.category_id,
          default_entry_type_id: formData.default_entry_type_id,
        },
      },
    });
  });

  const toggleArchive = useCallback(() => {
    updateFn({
      variables: {
        id: data.id,
        _set: {
          deleted_at: data.deleted_at ? null : new Date(),
        },
      },
    });
  }, [data, updateFn]);

  return (
    <Page
      title={
        <PageTitle
          title={
            <React.Fragment>
              <Typography variant="h6" component="div">
                <DescriptionIcon color="primary" fontSize="small" /> ผังบัญชี
              </Typography>
              <Typography variant="h6" color="primary" component="div" gutterBottom>
                {data.name}
              </Typography>
            </React.Fragment>
          }
          actions={
            <div>
              <BackButton to="/bof/accounting/accounts" />
            </div>
          }
        />
      }
    >
      <InfoCard
        title="ข้อมูลผังบัญชี"
        actions={
          <Grid container justify="space-between">
            <Grid item>
              <ToggleArchiveButton
                archived={!!data.deleted_at}
                size="small"
                loading={loading}
                onClick={toggleArchive}
                variant="contained"
              />
            </Grid>
            <Grid item>
              <SaveButton disabled={loading} onClick={handleSubmit} />
            </Grid>
          </Grid>
        }
      >
        <FormProvider {...form}>
          <Form data={data} mode="update" />
        </FormProvider>

        <Spacing />
      </InfoCard>
      <Spacing />
    </Page>
  );
};

const AccountDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetAccountByPkQuery({
    variables: {
      id: id,
    },
  });

  if (loading && !data?.entity) {
    return <CircularProgress />;
  }

  if (!data?.entity) {
    return null;
  }

  return (
    <React.Fragment>
      <AccountDetailForm data={data?.entity} />
    </React.Fragment>
  );
};

export default pageContent(AccountDetailPage);
