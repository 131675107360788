import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  GetReceivableByPkDocument,
  AccountFragment,
  LedgerReceivableStatusEnum,
  ReceivableFragment,
  useSubmitReceivableByPkMutation,
  useDeleteReceivableByPkMutation,
  useDoneReceivableByPkMutation,
  useGetAccountBudgetAccountsQuery,
} from '@app/generated/graphql';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import React, { Fragment, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button, FormControl, Grid, Typography, TextField as MuiTextField } from '@material-ui/core';
import { DataField } from '@mystiny/ui';
import { ReceivableStatus } from './ReceivableStatus';
import { DatePicker, ErrorMessage, TextField } from '@aginix/mui-react-hook-form-input';
import ButtonCircularProgress from '@app/shared/components/ButtonCircularProgress';
import Select from 'react-select';
import { prompt } from '@app/shared/libs';

import NumberFormat from 'react-number-format';
import { createNumberFormatInput } from '@app/components/NumberFormatInput';
import { useHistory } from 'react-router';

const NumberFormatInput = createNumberFormatInput({
  allowNegative: false,
  thousandSeparator: true,
});

export interface ReceivableWorkflowProps {
  receivable: ReceivableFragment;
}

type FormState = {
  comment: string;
};

const defaultValues = {
  comment: '',
};

export const ReceivableSubmit = ({ receivable }: ReceivableWorkflowProps) => {
  const history = useHistory();
  const { enqueueFailure, enqueueSuccess } = useResponseBar();
  const form = useForm<FormState>({
    defaultValues: defaultValues,
  });

  const [submitFn, { loading: submitting }] = useSubmitReceivableByPkMutation({
    onCompleted: () => {
      enqueueSuccess('ปรับสถานะสำเร็จ');
      form.reset(defaultValues);
    },
    onError: () => {
      enqueueFailure('ไม่สามารถบันทึกข้อมูลได้');
    },
    refetchQueries: [
      {
        query: GetReceivableByPkDocument,
        variables: {
          id: receivable.id,
        },
      },
    ],
  });

  const [deleteFn, { loading: deleting }] = useDeleteReceivableByPkMutation({
    onCompleted: () => {
      enqueueSuccess('ลบข้อมูลสำเร็จ');
      history.replace('/bof/accounting/receivables');
    },
    onError: () => {
      enqueueFailure('ไม่สามารถลบข้อมูลได้');
    },
    refetchQueries: [
      {
        query: GetReceivableByPkDocument,
        variables: {
          id: receivable.id,
        },
      },
    ],
  });

  const handleSubmit = form.handleSubmit((formData: FormState) => {
    prompt(
      'ท่านแน่ใจที่จะปรับสถานะข้อมูลใช่หรือไม่?\nซึ่งข้อมูลจะไม่สามารถถูกแก้ไขหรือย้อนกลับได้\n\nโปรดพิมพ์คำว่า "yes" เพื่อยืนยันการปรับสถานะ',
      {
        value: 'yes',
        callback: () => {
          submitFn({
            variables: {
              id: receivable.id,
            },
          });
        },
      }
    );
  });

  const handleDelete = form.handleSubmit((formData: FormState) => {
    prompt(
      'ท่านแน่ใจที่จะลบข้อมูลใช่หรือไม่?\n\nเป็นการลบข้อมูลออกจากระบบถาวร โดยที่จะไม่สามารถกู้คืนกลับมาได้\n\nโปรดพิมพ์คำว่า "delete" เพื่อยืนยันการลบข้อมูล',
      {
        value: 'delete',
        callback: () => {
          deleteFn({
            variables: {
              data: {
                receivableId: receivable.id,
              },
            },
          });
        },
      }
    );
  });

  const loading = submitting || deleting;

  return (
    <Fragment>
      {receivable.status_id === LedgerReceivableStatusEnum.Submitted && (
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          startIcon={<DeleteIcon />}
          disabled={loading}
          onClick={handleDelete}
        >
          ลบข้อมูล {loading && <ButtonCircularProgress />}
        </Button>
      )}
      {receivable.status_id === LedgerReceivableStatusEnum.Draft && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<CheckIcon />}
          size="large"
          disabled={loading}
          onClick={handleSubmit}
        >
          ยืนยันข้อมูล {loading && <ButtonCircularProgress />}
        </Button>
      )}
    </Fragment>
  );
};

const defaultValuesReceivableDone = () => ({
  refundAmount: undefined,
  date: new Date(),
});

type ReceivableDoneFormState = {
  account: AccountFragment;
  refundAmount: number;
  date: Date;
};

export const ReceivableDone = ({ receivable }: ReceivableWorkflowProps) => {
  const { enqueueFailure, enqueueSuccess } = useResponseBar();
  const form = useForm<ReceivableDoneFormState>({
    defaultValues: defaultValuesReceivableDone(),
  });

  const { data: account, loading: accountLoading } = useGetAccountBudgetAccountsQuery({
    fetchPolicy: 'cache-first',
  });

  const [submitFn, { loading }] = useDoneReceivableByPkMutation({
    onCompleted: () => {
      enqueueSuccess('ปรับสถานะสำเร็จ');
      form.reset(defaultValuesReceivableDone());
    },
    onError: () => {
      enqueueFailure('ไม่สามารถบันทึกข้อมูลได้');
    },
    refetchQueries: [
      {
        query: GetReceivableByPkDocument,
        variables: {
          id: receivable.id,
        },
      },
    ],
  });

  const handleSubmit = form.handleSubmit((formData: any) => {
    prompt(
      'ท่านยืนยันหักล้างหนี้เงินยืมใช่หรือไม่?\nซึ่งข้อมูลจะไม่สามารถแก้ไขหรือย้อนกลับได้\n\nโปรดพิมพ์คำว่า "yes" เพื่อยืนยันการปรับสถานะ',
      {
        value: 'yes',
        callback: () => {
          submitFn({
            variables: {
              data: {
                refundAmount: Number(formData.refundAmount),
                receivableId: receivable.id,
                expenseAccountId: formData.account.account_id,
                date: formData.date,
              },
            },
          });
        },
      }
    );
  });

  const options = useMemo(
    () =>
      account?.rows
        ? account?.rows.filter(
            (row) =>
              row.budget_id === receivable.budget_id &&
              row.fiscal_year === receivable.fiscal_year &&
              row.account?.account_type?.id === 'expense'
          )
        : [],
    [account, receivable]
  );

  return (
    <FormProvider {...form}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DataField
            label="คำอธิบาย"
            children={
              <Typography variant="body1" component="div">
                1. การยืนยันจำนวนเงินค่าใช้จ่ายจริง และจำนวนเงินคืน
                <br />
                2. เมื่อยืนยันข้อมูลแล้ว สถานะจะถูกปรับจาก{' '}
                <ReceivableStatus
                  status={{ id: LedgerReceivableStatusEnum.Submitted, description: 'กำลังดำเนินการ' }}
                  ChipProps={{ size: 'small' }}
                />
                เป็น{' '}
                <ReceivableStatus
                  status={{ id: LedgerReceivableStatusEnum.Done, description: 'เสร็จสิ้น' }}
                  ChipProps={{ size: 'small' }}
                />{' '}
                ซึ่งข้อมูลจะไม่สามารถถูกแก้ไขหรือย้อนกลับได้
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <DataField label="ยอดเงินยืม">
            <Typography variant="body1" component="div">
              <NumberFormat value={receivable.amount} displayType="text" thousandSeparator />
            </Typography>
          </DataField>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Typography color="textSecondary">ชื่อบัญชีค่าใช้จ่าย</Typography>
            <Controller
              id="account"
              name="account"
              control={form.control}
              rules={{ required: 'กรุณาระบุชื่อบัญชีค่าใช้จ่าย' }}
              render={({ name, value, onChange }) => (
                <Select
                  id="account"
                  name={name}
                  isSearchable
                  value={value}
                  onChange={(v) => onChange(v)}
                  options={options}
                  loading={accountLoading}
                  getOptionLabel={(v) => {
                    let option = v;
                    if (!option.account) {
                      option = options.find((a) => a.account_id === v.id);
                    }
                    return option?.account
                      ? `${option.account?.code} ${option.account?.name} (${option.account?.account_type?.description})`
                      : '';
                  }}
                  placeholder="ชื่อบัญชีค่าใช้จ่าย"
                  getOptionValue={(option) => option.account_id}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              )}
            />
            <ErrorMessage name="account" />
          </FormControl>
        </Grid>
        <Grid container item xs={12}>
          <Grid item md={3}>
            <FormControl fullWidth error={!!form.errors['refundAmount']}>
              <TextField
                label="จำนวนเงินคืน"
                name="refundAmount"
                variant="outlined"
                size="small"
                fullWidth
                rules={{
                  required: 'กรุณากรอกจำนวนเงินคืน',
                  min: { value: 0, message: 'จำนวนเงินคืนไม่สามารถติดลบ' },
                  max: { value: receivable.amount, message: 'จำนวนเงินคืนไม่สามารถเกินกว่าจำนวนเงินยืม' },
                }}
                InputProps={{
                  inputComponent: NumberFormatInput as any,
                }}
                required
              />
              <ErrorMessage name="refundAmount" />
            </FormControl>
          </Grid>
          <Grid item md={3}>
            <FormControl fullWidth>
              <MuiTextField
                label="ยอดค่าใช้จ่ายจริง"
                name="actualAmount"
                value={receivable.amount - Number(form.watch('refundAmount', 0))}
                variant="outlined"
                size="small"
                fullWidth
                disabled
                helperText="ยอดค่าใช้จ่ายจริง = ยอดเงินยืม - จำนวนเงินคืน"
                InputProps={{
                  readOnly: true,
                  inputComponent: NumberFormatInput as any,
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth error={!!form.errors['date']}>
            <DatePicker
              name="date"
              label="วันที่ส่งคืน"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              minDate={receivable.date}
              size="small"
              rules={{ required: 'กรุณาระบุวันที่ส่งคืน' }}
              required
            />
            <ErrorMessage name="date" />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
            disabled={loading}
            onClick={handleSubmit}
          >
            ยืนยันข้อมูล {loading && <ButtonCircularProgress />}
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
