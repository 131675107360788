import React from 'react';
import TextFilter from '@app/components/data-grid/TextFilter';
import { LedgerFragment } from '@app/generated/graphql';
import { DateUtils } from '@app/shared/libs/date';
import { Fragment } from 'react';
import { Column } from 'react-data-grid';
import NumberFormat from 'react-number-format';
import { LedgerStatus } from '../components/LedgerStatus';
import { Link as MuiLink } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const ledgerColumns: Column<LedgerFragment>[] = [
  {
    key: 'status.description',
    name: 'สถานะ',
    width: 85,
    formatter: ({ row }) => <LedgerStatus ChipProps={{ size: 'small' }} status={row.status} />,
  },
  {
    key: 'date',
    name: 'วันที่ทำรายการ',
    width: 110,
    filterRenderer: (p) => <TextFilter {...p} />,
    formatter: ({ row }) => <Fragment>{DateUtils.toThaiDate(row.date)}</Fragment>,
  },
  {
    key: 'account.name',
    name: 'รหัสบัญชีและชื่อบัญชี',
    width: 250,
    resizable: true,
    formatter: ({ row }) => (
      <Fragment>
        {row.account.code} {row.account.name}
      </Fragment>
    ),
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'budget.description',
    name: 'งบประมาณ',
    width: 180,
    filterRenderer: (p) => <TextFilter {...p} />,
    formatter: ({ row }) => <Fragment>{row.budget?.description}</Fragment>,
  },
  {
    key: 'amount',
    name: 'จำนวนเงิน',
    cellClass: 'text-right',
    width: 135,
    formatter: ({ row }) => (
      <NumberFormat fixedDecimalScale decimalScale={2} displayType="text" value={row.amount} thousandSeparator />
    ),
  },
  {
    key: 'gfmis_id',
    name: 'เลขที่ขอเบิก GFMIS',
    width: 160,
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'note',
    name: 'หมายเหตุ',
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'receivable_id',
    name: 'ทะเบียนคุมลูกหนี้เงินยืม',
    formatter: ({ row }) => (
      <Fragment>
        {row.receivable ? (
          <MuiLink component={Link} to={`/bof/accounting/receivables/${row.receivable_id}`}>
            {row.receivable?.reference}
          </MuiLink>
        ) : (
          ''
        )}
      </Fragment>
    ),
  },
  {
    key: 'created_by_user.display_name',
    name: 'สร้างโดย',
    width: 160,
    filterRenderer: (p) => <TextFilter {...p} />,
    formatter: ({ row }) => <Fragment>{row.created_by_user?.display_name || '-'}</Fragment>,
  },
  {
    key: 'organization.short_name',
    name: 'หน่วยงาน',
    width: 160,
    filterRenderer: (p) => <TextFilter {...p} />,
    formatter: ({ row }) => <Fragment>{row.organization.short_name}</Fragment>,
  },
];
