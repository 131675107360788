import { useUpdateBudgetTypeByPkMutation, BudgetTypeFragment, useGetBudgetTypeByPkQuery } from '@app/generated/graphql';
import Page from '@app/components/Page';
import DescriptionIcon from '@material-ui/icons/Description';
import { pageContent } from '@app/shared/hoc/page-content';
import { Grid, Chip, CircularProgress, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from '@app/shared/components/PageTitle';
import BackButton from '@app/shared/components/buttons/BackButton';
import { InfoCard } from '@mystiny/ui';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { FormProvider, useForm } from 'react-hook-form';
import Spacing from '@app/components/common/Spacing';
import SaveButton from '@app/shared/components/buttons/SaveButton';
import { ToggleArchiveButton } from '@app/components/ToggleArchiveButton';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import DetailsIcon from '@material-ui/icons/Details';
import { Form } from './components/Form';
import { BudgetAccountsForm } from '@app/views/BudgetType/components/BudgetAccountsForm';
import { TabPanel } from '@app/components/TabPanel';

const BudgetTypeDetailForm = ({ data }: { data: BudgetTypeFragment }) => {
  const form = useForm<BudgetTypeFragment>({
    defaultValues: data,
  });
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [updateFn, { loading }] = useUpdateBudgetTypeByPkMutation({
    onCompleted: () => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
    },
    onError: (err: any) => {
      enqueueFailure('เกิดข้อผิดพลาด');
    },
  });

  const handleSubmit = form.handleSubmit((formData: BudgetTypeFragment) => {
    updateFn({
      variables: {
        id: data.id,
        fiscal_year: formData.fiscal_year,
        _set: {
          sequence: formData.sequence,
          id: formData.id,
          description: formData.description,
          fiscal_year: formData.fiscal_year,
          account_id: formData.account_id,
          group_name: formData.group_name,
        },
      },
    });
  });

  const toggleArchive = useCallback(() => {
    updateFn({
      variables: {
        id: data.id,
        fiscal_year: data.fiscal_year,
        _set: {
          deleted_at: data.deleted_at ? null : new Date(),
        },
      },
    });
  }, [data, updateFn]);

  // const handleCloseBudget = useCallback(() => {
  //   if (window.confirm('ยืนยันปิดงบประมาณประจำปี')) {
  //     updateFn({
  //       variables: {
  //         id: data.id,
  //         fiscal_year: data.fiscal_year,
  //         _set: {
  //           closed_at: new Date(),
  //         },
  //       },
  //     });
  //   }
  // }, [data.id, data.fiscal_year]);

  return (
    <InfoCard
      title="ข้อมูลงบประมาณ"
      actions={
        <Grid container justify="space-between">
          <Grid item>
            <ToggleArchiveButton
              archived={!!data.deleted_at}
              size="small"
              loading={loading}
              onClick={toggleArchive}
              variant="contained"
            />
          </Grid>
          <Grid item>
            <SaveButton onClick={handleSubmit} />
          </Grid>
        </Grid>
      }
    >
      <FormProvider {...form}>
        <Form data={data} mode="update" />
      </FormProvider>
      <Spacing />
    </InfoCard>
  );
};

const BudgetTypeDetailPage = () => {
  const { id, fiscalYear } = useParams<{ id: string; fiscalYear: string }>();
  const [value, setValue] = useState(0);
  const { data, loading } = useGetBudgetTypeByPkQuery({
    variables: {
      id: id,
      fiscalYear: Number(fiscalYear),
    },
    fetchPolicy: 'cache-and-network',
  });

  const handleChange = useCallback((_, value: number) => {
    setValue(value);
  }, []);

  if (loading && !data?.entity) {
    return <CircularProgress />;
  }

  if (!data?.entity) {
    return null;
  }

  return (
    <React.Fragment>
      <Page
        title={
          <PageTitle
            title={
              <React.Fragment>
                <Typography variant="h6" component="div">
                  <DescriptionIcon color="primary" fontSize="small" /> งบประมาณ{' '}
                  {data.entity.closed_at && <Chip component="span" color="secondary" label="ปิดงบประมาณ" />}
                </Typography>
                <Typography variant="h6" color="primary" component="div">
                  {data?.entity.description}
                </Typography>
                <Typography variant="h6" color="textSecondary" component="div" gutterBottom>
                  ปีงบประมาณ {data?.entity.fiscal_year}
                </Typography>
              </React.Fragment>
            }
            actions={
              <div>
                <BackButton to="/bof/accounting/budget-types" />
              </div>
            }
          />
        }
      >
        <Paper square>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab icon={<DetailsIcon />} label="รายละเอียด" />
            <Tab icon={<AccountTreeIcon />} label="รหัสและชื่อบัญชี" />
            {/* <Tab icon={<PaymentIcon />} label="จัดสรรงบประมาณ" /> */}
          </Tabs>
        </Paper>
        <Spacing />
        <TabPanel value={value} index={0}>
          <BudgetTypeDetailForm data={data?.entity} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <BudgetAccountsForm data={data?.entity} />
        </TabPanel>
        {/* <TabPanel value={value} index={2}>
          <BudgetManagementForm budget={data?.entity} />
        </TabPanel> */}
      </Page>
    </React.Fragment>
  );
};

export default pageContent(BudgetTypeDetailPage);
