import { GetOrganizationsComponent, useUpdateUserByPkMutation, UserFragment } from '@app/generated/graphql';
import {
  FormGroup,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { FormLayout, FormLayoutGroup, InfoCard } from '@mystiny/ui';
import AutocompleteInput from '@app/shared/components/AutocompleteInput';
import { useForm, FormProvider } from 'react-hook-form';
import SaveButton from '@app/shared/components/buttons/SaveButton';
import { TextField, Checkbox } from '@aginix/mui-react-hook-form-input';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import Spacing from '@app/components/common/Spacing';
import ChangePasswordDialog from './ChangePasswordDialog';

const UserSection = ({ data }: { data: UserFragment }) => {
  const { enqueueSuccess } = useResponseBar();
  const [mutateFn, { loading }] = useUpdateUserByPkMutation({
    onCompleted: () => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
    },
  });
  const form = useForm<UserFragment>({
    defaultValues: data,
  });

  const handleSubmit = form.handleSubmit((formData: UserFragment) => {
    mutateFn({
      variables: {
        user_id: data.id,
        _set: {
          display_name: `${formData.first_name} ${formData.last_name}`.trim(),
          first_name: formData.first_name,
          last_name: formData.last_name,
          organization_id: formData.organization_id,
          bank_branch_id: formData.bank_branch_id,
          tel: formData.tel,
          note: formData.note,
        },
      },
    });
  });

  return (
    <InfoCard
      title="ข้อมูลผู้ใช้งาน (Account)"
      actionTopRight={<SaveButton disabled={loading} type="submit" form="user-form" />}
      actions={
        <Grid container justify="flex-start">
          <Grid item>
            <ChangePasswordDialog accountId={data.account?.id} />
          </Grid>
        </Grid>
      }
    >
      <FormProvider {...form}>
        <form id="user-form" onSubmit={handleSubmit}>
          <FormLayout>
            <FormLayoutGroup>
              <FormControl fullWidth>
                <TextField id="first_name" name="first_name" label="ชื่อ" size="small" variant="outlined" />
              </FormControl>
              <FormControl fullWidth>
                <TextField id="last_name" name="last_name" label="นามสกุล" size="small" variant="outlined" />
              </FormControl>
            </FormLayoutGroup>
            <FormLayoutGroup>
              <FormControl fullWidth>
                <TextField id="tel" name="tel" label="เบอร์โทรศัพท์" size="small" variant="outlined" />
              </FormControl>
              <FormControl fullWidth>
                <TextField id="note" name="note" label="Note" size="small" variant="outlined" rows={3} multiline />
                <FormHelperText>สำหรับจดบันทึกคอมเม้น, โน้ต</FormHelperText>
              </FormControl>
            </FormLayoutGroup>
            <FormLayoutGroup>
              <FormControl component="fieldset">
                <FormLabel component="legend">ยินยอมตามข้อกำหนดและเงื่อนไขในการใช้งาน</FormLabel>
                <FormGroup>
                  <FormControlLabel disabled control={<Checkbox name="consent_checked" readOnly />} label="ยินยอม" />
                </FormGroup>
                <FormHelperText>*ผู้ใช้เป็นผู้ให้ข้อมูลส่วนนี้เท่านั้น</FormHelperText>
              </FormControl>
            </FormLayoutGroup>
            <Spacing />
            <Typography variant="body1">ข้อมูลส่วนเจ้าหน้าที่</Typography>
            <FormLayoutGroup>
              <GetOrganizationsComponent fetchPolicy="cache-and-network">
                {({ data, loading }) => (
                  <FormControl fullWidth>
                    <AutocompleteInput
                      label="หน่วยงาน (ศภ.)"
                      name="organization_id"
                      options={data?.rows || []}
                      loading={loading}
                      primaryKey="id"
                      getOptionLabel={(option: any) => `${option.name} ${option.short_name}`}
                    />
                    <FormHelperText component="div">
                      <Typography variant="subtitle2" color="secondary">
                        *สำหรับเจ้าหน้าที่ศภ.
                      </Typography>
                    </FormHelperText>
                  </FormControl>
                )}
              </GetOrganizationsComponent>
            </FormLayoutGroup>
          </FormLayout>
        </form>
      </FormProvider>
    </InfoCard>
  );
};

export default UserSection;
