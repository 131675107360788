import {
  useGetLoanProgramByPkQuery,
  LoanProgramFragment,
  useUpdateLoanProgramByPkMutation,
} from '@app/generated/graphql';
import Page from '@app/components/Page';
import DescriptionIcon from '@material-ui/icons/Description';
import { pageContent } from '@app/shared/hoc/page-content';
import { CircularProgress, InputAdornment, Typography, TextField as MuiTextField } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from '@app/shared/components/PageTitle';
import { FormLayout, FormLayoutGroup, InfoCard } from '@mystiny/ui';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { FormProvider, useForm } from 'react-hook-form';
import Spacing from '@app/components/common/Spacing';
import { DateTimePicker, TextField } from '@aginix/mui-react-hook-form-input';
import SaveButton from '@app/shared/components/buttons/SaveButton';
import NumberFormatInput from '@app/components/NumberFormatInput';

const LoanProgramDetailForm = ({ data }: { data: LoanProgramFragment }) => {
  const form = useForm<LoanProgramFragment>({
    defaultValues: data,
  });
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [updateFn] = useUpdateLoanProgramByPkMutation({
    onCompleted: () => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
    },
    onError: (err: any) => {
      console.error(err);
      enqueueFailure('เกิดข้อผิดพลาด');
    },
  });

  const handleSubmit = form.handleSubmit((formData: any) => {
    const budget_total = parseFloat(formData.budget_total_value.replace(/,/g, ''));
    const max_credit = parseFloat(formData.max_credit_value.replace(/,/g, ''));

    updateFn({
      variables: {
        id: data.id,
        _set: {
          name: formData.name,
          reference: formData.reference,
          budget_total: budget_total,
          max_credit: max_credit,
          start_date: formData.start_date,
          end_date: formData.end_date,
          description: formData.description,
        },
      },
    });
  });

  return (
    <Page
      title={
        <PageTitle
          title={
            <React.Fragment>
              <Typography variant="h6" component="div">
                <DescriptionIcon color="primary" fontSize="small" /> โครงการสินเชื่อ
              </Typography>
              <Typography variant="h6" color="primary" component="div" gutterBottom>
                {data.name}
              </Typography>
            </React.Fragment>
          }
        />
      }
    >
      <InfoCard title="ข้อมูลโครงการ" actionTopRight={<SaveButton onClick={handleSubmit} />}>
        <FormProvider {...form}>
          <FormLayout>
            <FormLayoutGroup>
              <TextField label="ชื่อโครงการ" name="name" variant="outlined" fullWidth />
              <TextField label="รหัสอ้างอิงโครงการ" name="reference" variant="outlined" fullWidth />
            </FormLayoutGroup>
            <FormLayoutGroup>
              <MuiTextField
                inputRef={form.register()}
                label="วงเงินทั้งหมดของโครงการ"
                name="budget_total_value"
                defaultValue={data.budget_total}
                InputProps={{
                  inputComponent: NumberFormatInput as any,
                  endAdornment: <InputAdornment position="end">บาท</InputAdornment>,
                }}
                variant="outlined"
                fullWidth
              />
              <MuiTextField
                inputRef={form.register()}
                defaultValue={data.max_credit}
                label="วงเงินสูงสุดที่สามารถขอสินเชื่อได้"
                name="max_credit_value"
                InputProps={{
                  inputComponent: NumberFormatInput as any,
                  endAdornment: <InputAdornment position="end">บาท</InputAdornment>,
                }}
                variant="outlined"
                fullWidth
              />
            </FormLayoutGroup>
            <FormLayoutGroup>
              <DateTimePicker
                label="วันที่เริ่มโครงการ"
                name="start_date"
                format="dd/MM/yyyy HH:mm:ss"
                inputVariant="outlined"
                fullWidth
                ampm={false}
              />
              <DateTimePicker
                label="วันที่สิ้นสุดโครงการ"
                name="end_date"
                format="dd/MM/yyyy HH:mm:ss"
                inputVariant="outlined"
                fullWidth
                ampm={false}
              />
            </FormLayoutGroup>
            <FormLayoutGroup>
              <TextField label="รายละเอียดโครงการ" name="description" variant="outlined" rows={3} fullWidth multiline />
            </FormLayoutGroup>
          </FormLayout>
        </FormProvider>
      </InfoCard>
      <Spacing />
    </Page>
  );
};

const LoanProgramDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetLoanProgramByPkQuery({
    variables: {
      id: id,
    },
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return <CircularProgress />;
  }

  if (!data?.entity) {
    return null;
  }

  return (
    <React.Fragment>
      <LoanProgramDetailForm data={data?.entity} />
    </React.Fragment>
  );
};

export default pageContent(LoanProgramDetailPage);
