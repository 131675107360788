import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  bpchar: any;
  citext: any;
  date: any;
  float8: any;
  inet: any;
  jsonb: any;
  numeric: any;
  oid: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};


export type AccountBudgetDuplicateByFiscalYearInput = {
  fromFiscalYear: Scalars['Int'];
  toFiscalYear: Scalars['Int'];
};

export type AccountBudgetDuplicateByFiscalYearOutput = {
  __typename?: 'AccountBudgetDuplicateByFiscalYearOutput';
  budgetIds: Array<Scalars['String']>;
  fiscalYear: Scalars['Int'];
};

export type AccountTransactionApprovalInput = {
  comment: Scalars['String'];
  status: AccountTransactionApproveStatusEnum;
  transactionId: Scalars['ID'];
};

export type AccountTransactionApprovalOutput = {
  __typename?: 'AccountTransactionApprovalOutput';
  transactionId: Scalars['ID'];
};

export enum AccountTransactionApproveStatusEnum {
  Approved = 'Approved',
  Cancelled = 'Cancelled',
  ChangeRequest = 'ChangeRequest',
  New = 'New',
  Rejected = 'Rejected',
  Submitted = 'Submitted'
}

export enum ActionType {
  Bank = 'bank',
  Committee = 'committee',
  Document = 'document',
  SmeSign = 'sme_sign'
}

export type ApproveLoanApplicationInput = {
  action_type: ActionType;
  application_header_id: Scalars['String'];
  approve_type: ApproveType;
  comment?: Maybe<Scalars['String']>;
  credit?: Maybe<Scalars['numeric']>;
  sme_sign_date?: Maybe<Scalars['date']>;
  step_id: Scalars['String'];
};

export type ApproveLoanApplicationOrganizationTransferInput = {
  approved_note?: Maybe<Scalars['String']>;
  organization_transfer_id?: Maybe<Scalars['String']>;
  status_value?: Maybe<TransferStatusEnum>;
};

export type ApproveLoanApplicationOrganizationTransferOutput = {
  __typename?: 'ApproveLoanApplicationOrganizationTransferOutput';
  organization_transfer_id: Scalars['String'];
};

export type ApproveLoanApplicationOutput = {
  __typename?: 'ApproveLoanApplicationOutput';
  application_header_id: Scalars['String'];
};

export enum ApproveType {
  Approve = 'approve',
  ChangeRequest = 'change_request',
  Reject = 'reject'
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type ChangePasswordInput = {
  accountId: Scalars['String'];
  newPassword: Scalars['String'];
};

export type ChangePasswordOutput = {
  __typename?: 'ChangePasswordOutput';
  accountId: Scalars['String'];
};

export type CreateDraftLoanApplicationInput = {
  business_group_description?: Maybe<Scalars['String']>;
  business_group_id?: Maybe<Scalars['uuid']>;
  business_proprietary_description?: Maybe<Scalars['String']>;
  business_proprietary_else?: Maybe<Scalars['String']>;
  business_proprietary_id?: Maybe<Scalars['uuid']>;
  business_status_description?: Maybe<Scalars['String']>;
  business_status_id?: Maybe<Scalars['uuid']>;
  business_type_description?: Maybe<Scalars['String']>;
  business_type_id?: Maybe<Scalars['uuid']>;
  contact_email?: Maybe<Scalars['String']>;
  contact_first_name?: Maybe<Scalars['String']>;
  contact_last_name?: Maybe<Scalars['String']>;
  contact_tel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  juristic_id: Scalars['String'];
  office_address_amphoe?: Maybe<Scalars['String']>;
  office_address_district?: Maybe<Scalars['String']>;
  office_address_province?: Maybe<Scalars['String']>;
  office_address_zipcode?: Maybe<Scalars['String']>;
  program_id: Scalars['uuid'];
  tel?: Maybe<Scalars['String']>;
  working_address_amphoe?: Maybe<Scalars['String']>;
  working_address_district?: Maybe<Scalars['String']>;
  working_address_is_office_address?: Maybe<Scalars['Boolean']>;
  working_address_province?: Maybe<Scalars['String']>;
  working_address_street?: Maybe<Scalars['String']>;
  working_address_zipcode?: Maybe<Scalars['String']>;
};

export type CreateDraftLoanApplicationOutput = {
  __typename?: 'CreateDraftLoanApplicationOutput';
  id: Scalars['uuid'];
  reference?: Maybe<Scalars['String']>;
};

export type CreateDraftLoanApplicationStandaloneInput = {
  business_group_description?: Maybe<Scalars['String']>;
  business_group_id?: Maybe<Scalars['uuid']>;
  business_proprietary_description?: Maybe<Scalars['String']>;
  business_proprietary_else?: Maybe<Scalars['String']>;
  business_proprietary_id?: Maybe<Scalars['uuid']>;
  business_status_description?: Maybe<Scalars['String']>;
  business_status_id?: Maybe<Scalars['uuid']>;
  business_type_description?: Maybe<Scalars['String']>;
  business_type_id?: Maybe<Scalars['uuid']>;
  contact_email?: Maybe<Scalars['String']>;
  contact_first_name?: Maybe<Scalars['String']>;
  contact_last_name?: Maybe<Scalars['String']>;
  contact_tel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  juristic_id: Scalars['String'];
  juristic_name: Scalars['String'];
  office_address_amphoe?: Maybe<Scalars['String']>;
  office_address_district?: Maybe<Scalars['String']>;
  office_address_province: Scalars['String'];
  office_address_street?: Maybe<Scalars['String']>;
  office_address_zipcode?: Maybe<Scalars['String']>;
  program_id: Scalars['uuid'];
  tel?: Maybe<Scalars['String']>;
  working_address_amphoe?: Maybe<Scalars['String']>;
  working_address_district?: Maybe<Scalars['String']>;
  working_address_is_office_address?: Maybe<Scalars['Boolean']>;
  working_address_province?: Maybe<Scalars['String']>;
  working_address_street?: Maybe<Scalars['String']>;
  working_address_zipcode?: Maybe<Scalars['String']>;
};

export type CreateDraftLoanApplicationStandaloneOutput = {
  __typename?: 'CreateDraftLoanApplicationStandaloneOutput';
  id: Scalars['uuid'];
  reference?: Maybe<Scalars['String']>;
};

export type GeneratePdfFileInput = {
  application_header_id: Scalars['uuid'];
};

export type GeneratePdfFileOutput = {
  __typename?: 'GeneratePdfFileOutput';
  pdf_file: Scalars['String'];
};

export type HelloOutput = {
  __typename?: 'HelloOutput';
  message: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type JuristicDetailOutput = {
  __typename?: 'JuristicDetailOutput';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  imported?: Maybe<Scalars['Boolean']>;
  is_member?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  juristic_addresses: Array<JuristicAddress>;
  /** An aggregate relationship */
  juristic_addresses_aggregate: JuristicAddressAggregate;
  /** An array relationship */
  juristic_committees: Array<JuristicCommittee>;
  /** An aggregate relationship */
  juristic_committees_aggregate: JuristicCommitteeAggregate;
  /** An object relationship */
  juristic_detail: Juristic;
  /** An array relationship */
  juristic_standard_objectives: Array<JuristicStandardObjective>;
  /** An aggregate relationship */
  juristic_standard_objectives_aggregate: JuristicStandardObjectiveAggregate;
  juristic_status?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  name_th?: Maybe<Scalars['String']>;
  number_of_commitee?: Maybe<Scalars['numeric']>;
  number_of_objective?: Maybe<Scalars['numeric']>;
  paid_register_capital?: Maybe<Scalars['String']>;
  register_capital?: Maybe<Scalars['String']>;
  register_date?: Maybe<Scalars['date']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


export type JuristicDetailOutputJuristicAddressesArgs = {
  distinct_on?: Maybe<Array<JuristicAddressSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicAddressOrderBy>>;
  where?: Maybe<JuristicAddressBoolExp>;
};


export type JuristicDetailOutputJuristicAddressesAggregateArgs = {
  distinct_on?: Maybe<Array<JuristicAddressSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicAddressOrderBy>>;
  where?: Maybe<JuristicAddressBoolExp>;
};


export type JuristicDetailOutputJuristicCommitteesArgs = {
  distinct_on?: Maybe<Array<JuristicCommitteeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicCommitteeOrderBy>>;
  where?: Maybe<JuristicCommitteeBoolExp>;
};


export type JuristicDetailOutputJuristicCommitteesAggregateArgs = {
  distinct_on?: Maybe<Array<JuristicCommitteeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicCommitteeOrderBy>>;
  where?: Maybe<JuristicCommitteeBoolExp>;
};


export type JuristicDetailOutputJuristicStandardObjectivesArgs = {
  distinct_on?: Maybe<Array<JuristicStandardObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicStandardObjectiveOrderBy>>;
  where?: Maybe<JuristicStandardObjectiveBoolExp>;
};


export type JuristicDetailOutputJuristicStandardObjectivesAggregateArgs = {
  distinct_on?: Maybe<Array<JuristicStandardObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicStandardObjectiveOrderBy>>;
  where?: Maybe<JuristicStandardObjectiveBoolExp>;
};

export type LedgerApproveInput = {
  ledgerId: Scalars['String'];
  note: Scalars['String'];
  statusId: LedgerLedgerApproveEnum;
};

export type LedgerApproveOutput = {
  __typename?: 'LedgerApproveOutput';
  ledgerId: Scalars['String'];
  statusId: LedgerLedgerApproveEnum;
};

export enum LedgerLedgerApproveEnum {
  Approved = 'Approved',
  ChangeRequest = 'ChangeRequest',
  Draft = 'Draft',
  Locked = 'Locked',
  Rejected = 'Rejected',
  Submitted = 'Submitted'
}

export type LedgerSubmitInput = {
  ledgerId: Scalars['String'];
  note: Scalars['String'];
};

export type LedgerSubmitOutput = {
  __typename?: 'LedgerSubmitOutput';
  ledgerId: Scalars['String'];
};

export type PkInput = {
  juristicId: Scalars['String'];
};

export type ProjectApprovalInput = {
  approveType: ProjectApprovedStatusEnum;
  comment: Scalars['String'];
  projectId: Scalars['ID'];
};

export type ProjectApprovalOutput = {
  __typename?: 'ProjectApprovalOutput';
  projectId: Scalars['ID'];
};

export enum ProjectApprovedStatusEnum {
  Approved = 'Approved',
  Cancelled = 'Cancelled',
  ChangeRequest = 'ChangeRequest',
  Completed = 'Completed',
  Rejected = 'Rejected'
}

export type ProjectSubmitInput = {
  projectId: Scalars['ID'];
};

export type ProjectSubmitOutput = {
  __typename?: 'ProjectSubmitOutput';
  projectId: Scalars['ID'];
};

export type ReceivableCloseInput = {
  date: Scalars['String'];
  expenseAccountId: Scalars['String'];
  receivableId: Scalars['String'];
  refundAmount: Scalars['Float'];
};

export type ReceivableCloseOutput = {
  __typename?: 'ReceivableCloseOutput';
  receivableId: Scalars['String'];
};

export type ReceivableDeleteInput = {
  receivableId: Scalars['String'];
};

export type ReceivableDeleteOutput = {
  __typename?: 'ReceivableDeleteOutput';
  receivableId: Scalars['String'];
};

export type ReceivableSubmitInput = {
  receivableId: Scalars['String'];
};

export type ReceivableSubmitOutput = {
  __typename?: 'ReceivableSubmitOutput';
  receivableId: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

export type SubmitLoanApplicationInput = {
  application_header_id: Scalars['uuid'];
};

export type SubmitLoanApplicationOutput = {
  __typename?: 'SubmitLoanApplicationOutput';
  id: Scalars['String'];
  juristic_id: Scalars['String'];
};

export type TransferLoanApplicationBankBranchInput = {
  application_header_id: Scalars['uuid'];
  note?: Maybe<Scalars['String']>;
  to_bank_branch_id: Scalars['String'];
};

export type TransferLoanApplicationBankBranchOutput = {
  __typename?: 'TransferLoanApplicationBankBranchOutput';
  application_header_id: Scalars['String'];
};

export enum TransferStatusEnum {
  Approved = 'approved',
  Cancelled = 'cancelled',
  Rejected = 'rejected'
}

export type ValidateLoanApplicationInput = {
  application_header_id: Scalars['uuid'];
};

export type ValidateLoanApplicationOutput = {
  __typename?: 'ValidateLoanApplicationOutput';
  id: Scalars['String'];
  juristic_id: Scalars['String'];
};

/** columns and relationships of "account.account" */
export type AccountAccount = {
  __typename?: 'account_account';
  /** An object relationship */
  account_balance?: Maybe<AccountAccountBalance>;
  /** An object relationship */
  account_category?: Maybe<AccountAccountCategory>;
  /** An object relationship */
  account_type?: Maybe<AccountAccountType>;
  /** An array relationship */
  balances: Array<AccountAccountBalanceSheet>;
  /** An aggregate relationship */
  balances_aggregate: AccountAccountBalanceSheetAggregate;
  /** An array relationship */
  budget_accounts: Array<AccountBudgetAccount>;
  /** An aggregate relationship */
  budget_accounts_aggregate: AccountBudgetAccountAggregate;
  /** An array relationship */
  budget_types: Array<AccountBudgetType>;
  /** An aggregate relationship */
  budget_types_aggregate: AccountBudgetTypeAggregate;
  category_id?: Maybe<Scalars['uuid']>;
  /** รหัสบัญชี */
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  default_entry_type_id: AccountEntryTypeEnum;
  deleted_at?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  entry_type: AccountEntryType;
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  /** An array relationship */
  ledger_balances_debt: Array<LedgerAccountBudgetBalanceDebt>;
  /** An aggregate relationship */
  ledger_balances_debt_aggregate: LedgerAccountBudgetBalanceDebtAggregate;
  /** An array relationship */
  ledger_balances_expense: Array<LedgerAccountBudgetBalanceExpense>;
  /** An aggregate relationship */
  ledger_balances_expense_aggregate: LedgerAccountBudgetBalanceExpenseAggregate;
  /** An array relationship */
  ledger_entries: Array<AccountLedgerEntry>;
  /** An aggregate relationship */
  ledger_entries_aggregate: AccountLedgerEntryAggregate;
  /** An array relationship */
  ledger_entry_views: Array<AccountLedgerEntryView>;
  /** An aggregate relationship */
  ledger_entry_views_aggregate: AccountLedgerEntryViewAggregate;
  /** An array relationship */
  ledgers: Array<LedgerLedger>;
  /** An aggregate relationship */
  ledgers_aggregate: LedgerLedgerAggregate;
  /** ชื่อบัญชี */
  name: Scalars['String'];
  /** โน้ตเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** บัญชีที่เป็น parent */
  parent_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  receivables: Array<LedgerReceivable>;
  /** An aggregate relationship */
  receivables_aggregate: LedgerReceivableAggregate;
  /** ประเภทบัญชี (FK) */
  type_id?: Maybe<AccountAccountTypeEnum>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "account.account" */
export type AccountAccountBalancesArgs = {
  distinct_on?: Maybe<Array<AccountAccountBalanceSheetSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountBalanceSheetOrderBy>>;
  where?: Maybe<AccountAccountBalanceSheetBoolExp>;
};


/** columns and relationships of "account.account" */
export type AccountAccountBalancesAggregateArgs = {
  distinct_on?: Maybe<Array<AccountAccountBalanceSheetSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountBalanceSheetOrderBy>>;
  where?: Maybe<AccountAccountBalanceSheetBoolExp>;
};


/** columns and relationships of "account.account" */
export type AccountAccountBudgetAccountsArgs = {
  distinct_on?: Maybe<Array<AccountBudgetAccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetAccountOrderBy>>;
  where?: Maybe<AccountBudgetAccountBoolExp>;
};


/** columns and relationships of "account.account" */
export type AccountAccountBudgetAccountsAggregateArgs = {
  distinct_on?: Maybe<Array<AccountBudgetAccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetAccountOrderBy>>;
  where?: Maybe<AccountBudgetAccountBoolExp>;
};


/** columns and relationships of "account.account" */
export type AccountAccountBudgetTypesArgs = {
  distinct_on?: Maybe<Array<AccountBudgetTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetTypeOrderBy>>;
  where?: Maybe<AccountBudgetTypeBoolExp>;
};


/** columns and relationships of "account.account" */
export type AccountAccountBudgetTypesAggregateArgs = {
  distinct_on?: Maybe<Array<AccountBudgetTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetTypeOrderBy>>;
  where?: Maybe<AccountBudgetTypeBoolExp>;
};


/** columns and relationships of "account.account" */
export type AccountAccountLedgerBalancesDebtArgs = {
  distinct_on?: Maybe<Array<LedgerAccountBudgetBalanceDebtSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerAccountBudgetBalanceDebtOrderBy>>;
  where?: Maybe<LedgerAccountBudgetBalanceDebtBoolExp>;
};


/** columns and relationships of "account.account" */
export type AccountAccountLedgerBalancesDebtAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerAccountBudgetBalanceDebtSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerAccountBudgetBalanceDebtOrderBy>>;
  where?: Maybe<LedgerAccountBudgetBalanceDebtBoolExp>;
};


/** columns and relationships of "account.account" */
export type AccountAccountLedgerBalancesExpenseArgs = {
  distinct_on?: Maybe<Array<LedgerAccountBudgetBalanceExpenseSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerAccountBudgetBalanceExpenseOrderBy>>;
  where?: Maybe<LedgerAccountBudgetBalanceExpenseBoolExp>;
};


/** columns and relationships of "account.account" */
export type AccountAccountLedgerBalancesExpenseAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerAccountBudgetBalanceExpenseSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerAccountBudgetBalanceExpenseOrderBy>>;
  where?: Maybe<LedgerAccountBudgetBalanceExpenseBoolExp>;
};


/** columns and relationships of "account.account" */
export type AccountAccountLedgerEntriesArgs = {
  distinct_on?: Maybe<Array<AccountLedgerEntrySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLedgerEntryOrderBy>>;
  where?: Maybe<AccountLedgerEntryBoolExp>;
};


/** columns and relationships of "account.account" */
export type AccountAccountLedgerEntriesAggregateArgs = {
  distinct_on?: Maybe<Array<AccountLedgerEntrySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLedgerEntryOrderBy>>;
  where?: Maybe<AccountLedgerEntryBoolExp>;
};


/** columns and relationships of "account.account" */
export type AccountAccountLedgerEntryViewsArgs = {
  distinct_on?: Maybe<Array<AccountLedgerEntryViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLedgerEntryViewOrderBy>>;
  where?: Maybe<AccountLedgerEntryViewBoolExp>;
};


/** columns and relationships of "account.account" */
export type AccountAccountLedgerEntryViewsAggregateArgs = {
  distinct_on?: Maybe<Array<AccountLedgerEntryViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLedgerEntryViewOrderBy>>;
  where?: Maybe<AccountLedgerEntryViewBoolExp>;
};


/** columns and relationships of "account.account" */
export type AccountAccountLedgersArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerOrderBy>>;
  where?: Maybe<LedgerLedgerBoolExp>;
};


/** columns and relationships of "account.account" */
export type AccountAccountLedgersAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerOrderBy>>;
  where?: Maybe<LedgerLedgerBoolExp>;
};


/** columns and relationships of "account.account" */
export type AccountAccountReceivablesArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableOrderBy>>;
  where?: Maybe<LedgerReceivableBoolExp>;
};


/** columns and relationships of "account.account" */
export type AccountAccountReceivablesAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableOrderBy>>;
  where?: Maybe<LedgerReceivableBoolExp>;
};

/** aggregated selection of "account.account" */
export type AccountAccountAggregate = {
  __typename?: 'account_account_aggregate';
  aggregate?: Maybe<AccountAccountAggregateFields>;
  nodes: Array<AccountAccount>;
};

/** aggregate fields of "account.account" */
export type AccountAccountAggregateFields = {
  __typename?: 'account_account_aggregate_fields';
  avg?: Maybe<AccountAccountAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AccountAccountMaxFields>;
  min?: Maybe<AccountAccountMinFields>;
  stddev?: Maybe<AccountAccountStddevFields>;
  stddev_pop?: Maybe<AccountAccountStddevPopFields>;
  stddev_samp?: Maybe<AccountAccountStddevSampFields>;
  sum?: Maybe<AccountAccountSumFields>;
  var_pop?: Maybe<AccountAccountVarPopFields>;
  var_samp?: Maybe<AccountAccountVarSampFields>;
  variance?: Maybe<AccountAccountVarianceFields>;
};


/** aggregate fields of "account.account" */
export type AccountAccountAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountAccountSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account.account" */
export type AccountAccountAggregateOrderBy = {
  avg?: Maybe<AccountAccountAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<AccountAccountMaxOrderBy>;
  min?: Maybe<AccountAccountMinOrderBy>;
  stddev?: Maybe<AccountAccountStddevOrderBy>;
  stddev_pop?: Maybe<AccountAccountStddevPopOrderBy>;
  stddev_samp?: Maybe<AccountAccountStddevSampOrderBy>;
  sum?: Maybe<AccountAccountSumOrderBy>;
  var_pop?: Maybe<AccountAccountVarPopOrderBy>;
  var_samp?: Maybe<AccountAccountVarSampOrderBy>;
  variance?: Maybe<AccountAccountVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "account.account" */
export type AccountAccountArrRelInsertInput = {
  data: Array<AccountAccountInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<AccountAccountOnConflict>;
};

/** aggregate avg on columns */
export type AccountAccountAvgFields = {
  __typename?: 'account_account_avg_fields';
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "account.account" */
export type AccountAccountAvgOrderBy = {
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<OrderBy>;
};

/** columns and relationships of "account.account_balance" */
export type AccountAccountBalance = {
  __typename?: 'account_account_balance';
  account_id?: Maybe<Scalars['uuid']>;
  balance?: Maybe<Scalars['numeric']>;
  category_id?: Maybe<Scalars['uuid']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "account.account_balance" */
export type AccountAccountBalanceAggregate = {
  __typename?: 'account_account_balance_aggregate';
  aggregate?: Maybe<AccountAccountBalanceAggregateFields>;
  nodes: Array<AccountAccountBalance>;
};

/** aggregate fields of "account.account_balance" */
export type AccountAccountBalanceAggregateFields = {
  __typename?: 'account_account_balance_aggregate_fields';
  avg?: Maybe<AccountAccountBalanceAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AccountAccountBalanceMaxFields>;
  min?: Maybe<AccountAccountBalanceMinFields>;
  stddev?: Maybe<AccountAccountBalanceStddevFields>;
  stddev_pop?: Maybe<AccountAccountBalanceStddevPopFields>;
  stddev_samp?: Maybe<AccountAccountBalanceStddevSampFields>;
  sum?: Maybe<AccountAccountBalanceSumFields>;
  var_pop?: Maybe<AccountAccountBalanceVarPopFields>;
  var_samp?: Maybe<AccountAccountBalanceVarSampFields>;
  variance?: Maybe<AccountAccountBalanceVarianceFields>;
};


/** aggregate fields of "account.account_balance" */
export type AccountAccountBalanceAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountAccountBalanceSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type AccountAccountBalanceAvgFields = {
  __typename?: 'account_account_balance_avg_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "account.account_balance". All fields are combined with a logical 'AND'. */
export type AccountAccountBalanceBoolExp = {
  _and?: Maybe<Array<AccountAccountBalanceBoolExp>>;
  _not?: Maybe<AccountAccountBalanceBoolExp>;
  _or?: Maybe<Array<AccountAccountBalanceBoolExp>>;
  account_id?: Maybe<UuidComparisonExp>;
  balance?: Maybe<NumericComparisonExp>;
  category_id?: Maybe<UuidComparisonExp>;
  code?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  parent_id?: Maybe<UuidComparisonExp>;
  type_id?: Maybe<StringComparisonExp>;
};

/** input type for inserting data into table "account.account_balance" */
export type AccountAccountBalanceInsertInput = {
  account_id?: Maybe<Scalars['uuid']>;
  balance?: Maybe<Scalars['numeric']>;
  category_id?: Maybe<Scalars['uuid']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AccountAccountBalanceMaxFields = {
  __typename?: 'account_account_balance_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  balance?: Maybe<Scalars['numeric']>;
  category_id?: Maybe<Scalars['uuid']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AccountAccountBalanceMinFields = {
  __typename?: 'account_account_balance_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  balance?: Maybe<Scalars['numeric']>;
  category_id?: Maybe<Scalars['uuid']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "account.account_balance" */
export type AccountAccountBalanceObjRelInsertInput = {
  data: AccountAccountBalanceInsertInput;
};

/** Ordering options when selecting data from "account.account_balance". */
export type AccountAccountBalanceOrderBy = {
  account_id?: Maybe<OrderBy>;
  balance?: Maybe<OrderBy>;
  category_id?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  parent_id?: Maybe<OrderBy>;
  type_id?: Maybe<OrderBy>;
};

/** select columns of table "account.account_balance" */
export enum AccountAccountBalanceSelectColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Balance = 'balance',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Code = 'code',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  TypeId = 'type_id'
}

/** columns and relationships of "account.account_balance_sheet" */
export type AccountAccountBalanceSheet = {
  __typename?: 'account_account_balance_sheet';
  balance?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "account.account_balance_sheet" */
export type AccountAccountBalanceSheetAggregate = {
  __typename?: 'account_account_balance_sheet_aggregate';
  aggregate?: Maybe<AccountAccountBalanceSheetAggregateFields>;
  nodes: Array<AccountAccountBalanceSheet>;
};

/** aggregate fields of "account.account_balance_sheet" */
export type AccountAccountBalanceSheetAggregateFields = {
  __typename?: 'account_account_balance_sheet_aggregate_fields';
  avg?: Maybe<AccountAccountBalanceSheetAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AccountAccountBalanceSheetMaxFields>;
  min?: Maybe<AccountAccountBalanceSheetMinFields>;
  stddev?: Maybe<AccountAccountBalanceSheetStddevFields>;
  stddev_pop?: Maybe<AccountAccountBalanceSheetStddevPopFields>;
  stddev_samp?: Maybe<AccountAccountBalanceSheetStddevSampFields>;
  sum?: Maybe<AccountAccountBalanceSheetSumFields>;
  var_pop?: Maybe<AccountAccountBalanceSheetVarPopFields>;
  var_samp?: Maybe<AccountAccountBalanceSheetVarSampFields>;
  variance?: Maybe<AccountAccountBalanceSheetVarianceFields>;
};


/** aggregate fields of "account.account_balance_sheet" */
export type AccountAccountBalanceSheetAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountAccountBalanceSheetSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account.account_balance_sheet" */
export type AccountAccountBalanceSheetAggregateOrderBy = {
  avg?: Maybe<AccountAccountBalanceSheetAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<AccountAccountBalanceSheetMaxOrderBy>;
  min?: Maybe<AccountAccountBalanceSheetMinOrderBy>;
  stddev?: Maybe<AccountAccountBalanceSheetStddevOrderBy>;
  stddev_pop?: Maybe<AccountAccountBalanceSheetStddevPopOrderBy>;
  stddev_samp?: Maybe<AccountAccountBalanceSheetStddevSampOrderBy>;
  sum?: Maybe<AccountAccountBalanceSheetSumOrderBy>;
  var_pop?: Maybe<AccountAccountBalanceSheetVarPopOrderBy>;
  var_samp?: Maybe<AccountAccountBalanceSheetVarSampOrderBy>;
  variance?: Maybe<AccountAccountBalanceSheetVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "account.account_balance_sheet" */
export type AccountAccountBalanceSheetArrRelInsertInput = {
  data: Array<AccountAccountBalanceSheetInsertInput>;
};

/** aggregate avg on columns */
export type AccountAccountBalanceSheetAvgFields = {
  __typename?: 'account_account_balance_sheet_avg_fields';
  balance?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "account.account_balance_sheet" */
export type AccountAccountBalanceSheetAvgOrderBy = {
  balance?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "account.account_balance_sheet". All fields are combined with a logical 'AND'. */
export type AccountAccountBalanceSheetBoolExp = {
  _and?: Maybe<Array<AccountAccountBalanceSheetBoolExp>>;
  _not?: Maybe<AccountAccountBalanceSheetBoolExp>;
  _or?: Maybe<Array<AccountAccountBalanceSheetBoolExp>>;
  balance?: Maybe<NumericComparisonExp>;
  budget_id?: Maybe<StringComparisonExp>;
  code?: Maybe<StringComparisonExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  organization_id?: Maybe<UuidComparisonExp>;
  type_id?: Maybe<StringComparisonExp>;
};

/** input type for inserting data into table "account.account_balance_sheet" */
export type AccountAccountBalanceSheetInsertInput = {
  balance?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AccountAccountBalanceSheetMaxFields = {
  __typename?: 'account_account_balance_sheet_max_fields';
  balance?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "account.account_balance_sheet" */
export type AccountAccountBalanceSheetMaxOrderBy = {
  balance?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  type_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AccountAccountBalanceSheetMinFields = {
  __typename?: 'account_account_balance_sheet_min_fields';
  balance?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "account.account_balance_sheet" */
export type AccountAccountBalanceSheetMinOrderBy = {
  balance?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  type_id?: Maybe<OrderBy>;
};

/** Ordering options when selecting data from "account.account_balance_sheet". */
export type AccountAccountBalanceSheetOrderBy = {
  balance?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  type_id?: Maybe<OrderBy>;
};

/** select columns of table "account.account_balance_sheet" */
export enum AccountAccountBalanceSheetSelectColumn {
  /** column name */
  Balance = 'balance',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  Code = 'code',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  TypeId = 'type_id'
}

/** aggregate stddev on columns */
export type AccountAccountBalanceSheetStddevFields = {
  __typename?: 'account_account_balance_sheet_stddev_fields';
  balance?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "account.account_balance_sheet" */
export type AccountAccountBalanceSheetStddevOrderBy = {
  balance?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type AccountAccountBalanceSheetStddevPopFields = {
  __typename?: 'account_account_balance_sheet_stddev_pop_fields';
  balance?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "account.account_balance_sheet" */
export type AccountAccountBalanceSheetStddevPopOrderBy = {
  balance?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type AccountAccountBalanceSheetStddevSampFields = {
  __typename?: 'account_account_balance_sheet_stddev_samp_fields';
  balance?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "account.account_balance_sheet" */
export type AccountAccountBalanceSheetStddevSampOrderBy = {
  balance?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type AccountAccountBalanceSheetSumFields = {
  __typename?: 'account_account_balance_sheet_sum_fields';
  balance?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "account.account_balance_sheet" */
export type AccountAccountBalanceSheetSumOrderBy = {
  balance?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type AccountAccountBalanceSheetVarPopFields = {
  __typename?: 'account_account_balance_sheet_var_pop_fields';
  balance?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "account.account_balance_sheet" */
export type AccountAccountBalanceSheetVarPopOrderBy = {
  balance?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type AccountAccountBalanceSheetVarSampFields = {
  __typename?: 'account_account_balance_sheet_var_samp_fields';
  balance?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "account.account_balance_sheet" */
export type AccountAccountBalanceSheetVarSampOrderBy = {
  balance?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type AccountAccountBalanceSheetVarianceFields = {
  __typename?: 'account_account_balance_sheet_variance_fields';
  balance?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "account.account_balance_sheet" */
export type AccountAccountBalanceSheetVarianceOrderBy = {
  balance?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev on columns */
export type AccountAccountBalanceStddevFields = {
  __typename?: 'account_account_balance_stddev_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AccountAccountBalanceStddevPopFields = {
  __typename?: 'account_account_balance_stddev_pop_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AccountAccountBalanceStddevSampFields = {
  __typename?: 'account_account_balance_stddev_samp_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type AccountAccountBalanceSumFields = {
  __typename?: 'account_account_balance_sum_fields';
  balance?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type AccountAccountBalanceVarPopFields = {
  __typename?: 'account_account_balance_var_pop_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AccountAccountBalanceVarSampFields = {
  __typename?: 'account_account_balance_var_samp_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AccountAccountBalanceVarianceFields = {
  __typename?: 'account_account_balance_variance_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "account.account". All fields are combined with a logical 'AND'. */
export type AccountAccountBoolExp = {
  _and?: Maybe<Array<AccountAccountBoolExp>>;
  _not?: Maybe<AccountAccountBoolExp>;
  _or?: Maybe<Array<AccountAccountBoolExp>>;
  account_balance?: Maybe<AccountAccountBalanceBoolExp>;
  account_category?: Maybe<AccountAccountCategoryBoolExp>;
  account_type?: Maybe<AccountAccountTypeBoolExp>;
  balances?: Maybe<AccountAccountBalanceSheetBoolExp>;
  budget_accounts?: Maybe<AccountBudgetAccountBoolExp>;
  budget_types?: Maybe<AccountBudgetTypeBoolExp>;
  category_id?: Maybe<UuidComparisonExp>;
  code?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  default_entry_type_id?: Maybe<AccountEntryTypeEnumComparisonExp>;
  deleted_at?: Maybe<TimestampComparisonExp>;
  entry_type?: Maybe<AccountEntryTypeBoolExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  ledger_balances_debt?: Maybe<LedgerAccountBudgetBalanceDebtBoolExp>;
  ledger_balances_expense?: Maybe<LedgerAccountBudgetBalanceExpenseBoolExp>;
  ledger_entries?: Maybe<AccountLedgerEntryBoolExp>;
  ledger_entry_views?: Maybe<AccountLedgerEntryViewBoolExp>;
  ledgers?: Maybe<LedgerLedgerBoolExp>;
  name?: Maybe<StringComparisonExp>;
  note?: Maybe<StringComparisonExp>;
  parent_id?: Maybe<UuidComparisonExp>;
  receivables?: Maybe<LedgerReceivableBoolExp>;
  type_id?: Maybe<AccountAccountTypeEnumComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/**
 * Account Category
 *
 *
 * columns and relationships of "account.account_category"
 */
export type AccountAccountCategory = {
  __typename?: 'account_account_category';
  /** An array relationship */
  accounts: Array<AccountAccount>;
  /** An aggregate relationship */
  accounts_aggregate: AccountAccountAggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  note: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/**
 * Account Category
 *
 *
 * columns and relationships of "account.account_category"
 */
export type AccountAccountCategoryAccountsArgs = {
  distinct_on?: Maybe<Array<AccountAccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountOrderBy>>;
  where?: Maybe<AccountAccountBoolExp>;
};


/**
 * Account Category
 *
 *
 * columns and relationships of "account.account_category"
 */
export type AccountAccountCategoryAccountsAggregateArgs = {
  distinct_on?: Maybe<Array<AccountAccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountOrderBy>>;
  where?: Maybe<AccountAccountBoolExp>;
};

/** aggregated selection of "account.account_category" */
export type AccountAccountCategoryAggregate = {
  __typename?: 'account_account_category_aggregate';
  aggregate?: Maybe<AccountAccountCategoryAggregateFields>;
  nodes: Array<AccountAccountCategory>;
};

/** aggregate fields of "account.account_category" */
export type AccountAccountCategoryAggregateFields = {
  __typename?: 'account_account_category_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AccountAccountCategoryMaxFields>;
  min?: Maybe<AccountAccountCategoryMinFields>;
};


/** aggregate fields of "account.account_category" */
export type AccountAccountCategoryAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountAccountCategorySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "account.account_category". All fields are combined with a logical 'AND'. */
export type AccountAccountCategoryBoolExp = {
  _and?: Maybe<Array<AccountAccountCategoryBoolExp>>;
  _not?: Maybe<AccountAccountCategoryBoolExp>;
  _or?: Maybe<Array<AccountAccountCategoryBoolExp>>;
  accounts?: Maybe<AccountAccountBoolExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  note?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "account.account_category" */
export enum AccountAccountCategoryConstraint {
  /** unique or primary key constraint */
  CoaCategoryPkey = 'coa_category_pkey'
}

/** input type for inserting data into table "account.account_category" */
export type AccountAccountCategoryInsertInput = {
  accounts?: Maybe<AccountAccountArrRelInsertInput>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type AccountAccountCategoryMaxFields = {
  __typename?: 'account_account_category_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type AccountAccountCategoryMinFields = {
  __typename?: 'account_account_category_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "account.account_category" */
export type AccountAccountCategoryMutationResponse = {
  __typename?: 'account_account_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccountAccountCategory>;
};

/** input type for inserting object relation for remote table "account.account_category" */
export type AccountAccountCategoryObjRelInsertInput = {
  data: AccountAccountCategoryInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<AccountAccountCategoryOnConflict>;
};

/** on conflict condition type for table "account.account_category" */
export type AccountAccountCategoryOnConflict = {
  constraint: AccountAccountCategoryConstraint;
  update_columns?: Array<AccountAccountCategoryUpdateColumn>;
  where?: Maybe<AccountAccountCategoryBoolExp>;
};

/** Ordering options when selecting data from "account.account_category". */
export type AccountAccountCategoryOrderBy = {
  accounts_aggregate?: Maybe<AccountAccountAggregateOrderBy>;
  created_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: account_account_category */
export type AccountAccountCategoryPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "account.account_category" */
export enum AccountAccountCategorySelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "account.account_category" */
export type AccountAccountCategorySetInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "account.account_category" */
export enum AccountAccountCategoryUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** unique or primary key constraints on table "account.account" */
export enum AccountAccountConstraint {
  /** unique or primary key constraint */
  AccountPkey = 'account_pkey'
}

/** input type for incrementing numeric columns in table "account.account" */
export type AccountAccountIncInput = {
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "account.account" */
export type AccountAccountInsertInput = {
  account_balance?: Maybe<AccountAccountBalanceObjRelInsertInput>;
  account_category?: Maybe<AccountAccountCategoryObjRelInsertInput>;
  account_type?: Maybe<AccountAccountTypeObjRelInsertInput>;
  balances?: Maybe<AccountAccountBalanceSheetArrRelInsertInput>;
  budget_accounts?: Maybe<AccountBudgetAccountArrRelInsertInput>;
  budget_types?: Maybe<AccountBudgetTypeArrRelInsertInput>;
  category_id?: Maybe<Scalars['uuid']>;
  /** รหัสบัญชี */
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_entry_type_id?: Maybe<AccountEntryTypeEnum>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  entry_type?: Maybe<AccountEntryTypeObjRelInsertInput>;
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  ledger_balances_debt?: Maybe<LedgerAccountBudgetBalanceDebtArrRelInsertInput>;
  ledger_balances_expense?: Maybe<LedgerAccountBudgetBalanceExpenseArrRelInsertInput>;
  ledger_entries?: Maybe<AccountLedgerEntryArrRelInsertInput>;
  ledger_entry_views?: Maybe<AccountLedgerEntryViewArrRelInsertInput>;
  ledgers?: Maybe<LedgerLedgerArrRelInsertInput>;
  /** ชื่อบัญชี */
  name?: Maybe<Scalars['String']>;
  /** โน้ตเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** บัญชีที่เป็น parent */
  parent_id?: Maybe<Scalars['uuid']>;
  receivables?: Maybe<LedgerReceivableArrRelInsertInput>;
  /** ประเภทบัญชี (FK) */
  type_id?: Maybe<AccountAccountTypeEnum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type AccountAccountMaxFields = {
  __typename?: 'account_account_max_fields';
  category_id?: Maybe<Scalars['uuid']>;
  /** รหัสบัญชี */
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อบัญชี */
  name?: Maybe<Scalars['String']>;
  /** โน้ตเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** บัญชีที่เป็น parent */
  parent_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "account.account" */
export type AccountAccountMaxOrderBy = {
  category_id?: Maybe<OrderBy>;
  /** รหัสบัญชี */
  code?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  /** ชื่อบัญชี */
  name?: Maybe<OrderBy>;
  /** โน้ตเพิ่มเติม */
  note?: Maybe<OrderBy>;
  /** บัญชีที่เป็น parent */
  parent_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AccountAccountMinFields = {
  __typename?: 'account_account_min_fields';
  category_id?: Maybe<Scalars['uuid']>;
  /** รหัสบัญชี */
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อบัญชี */
  name?: Maybe<Scalars['String']>;
  /** โน้ตเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** บัญชีที่เป็น parent */
  parent_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "account.account" */
export type AccountAccountMinOrderBy = {
  category_id?: Maybe<OrderBy>;
  /** รหัสบัญชี */
  code?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  /** ชื่อบัญชี */
  name?: Maybe<OrderBy>;
  /** โน้ตเพิ่มเติม */
  note?: Maybe<OrderBy>;
  /** บัญชีที่เป็น parent */
  parent_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** columns and relationships of "account.account_monthly_report" */
export type AccountAccountMonthlyReport = {
  __typename?: 'account_account_monthly_report';
  april?: Maybe<Scalars['numeric']>;
  august?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  december?: Maybe<Scalars['numeric']>;
  february?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  january?: Maybe<Scalars['numeric']>;
  july?: Maybe<Scalars['numeric']>;
  june?: Maybe<Scalars['numeric']>;
  march?: Maybe<Scalars['numeric']>;
  may?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  november?: Maybe<Scalars['numeric']>;
  october?: Maybe<Scalars['numeric']>;
  organization_id?: Maybe<Scalars['uuid']>;
  september?: Maybe<Scalars['numeric']>;
  type_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "account.account_monthly_report" */
export type AccountAccountMonthlyReportAggregate = {
  __typename?: 'account_account_monthly_report_aggregate';
  aggregate?: Maybe<AccountAccountMonthlyReportAggregateFields>;
  nodes: Array<AccountAccountMonthlyReport>;
};

/** aggregate fields of "account.account_monthly_report" */
export type AccountAccountMonthlyReportAggregateFields = {
  __typename?: 'account_account_monthly_report_aggregate_fields';
  avg?: Maybe<AccountAccountMonthlyReportAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AccountAccountMonthlyReportMaxFields>;
  min?: Maybe<AccountAccountMonthlyReportMinFields>;
  stddev?: Maybe<AccountAccountMonthlyReportStddevFields>;
  stddev_pop?: Maybe<AccountAccountMonthlyReportStddevPopFields>;
  stddev_samp?: Maybe<AccountAccountMonthlyReportStddevSampFields>;
  sum?: Maybe<AccountAccountMonthlyReportSumFields>;
  var_pop?: Maybe<AccountAccountMonthlyReportVarPopFields>;
  var_samp?: Maybe<AccountAccountMonthlyReportVarSampFields>;
  variance?: Maybe<AccountAccountMonthlyReportVarianceFields>;
};


/** aggregate fields of "account.account_monthly_report" */
export type AccountAccountMonthlyReportAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountAccountMonthlyReportSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type AccountAccountMonthlyReportAvgFields = {
  __typename?: 'account_account_monthly_report_avg_fields';
  april?: Maybe<Scalars['Float']>;
  august?: Maybe<Scalars['Float']>;
  december?: Maybe<Scalars['Float']>;
  february?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  january?: Maybe<Scalars['Float']>;
  july?: Maybe<Scalars['Float']>;
  june?: Maybe<Scalars['Float']>;
  march?: Maybe<Scalars['Float']>;
  may?: Maybe<Scalars['Float']>;
  november?: Maybe<Scalars['Float']>;
  october?: Maybe<Scalars['Float']>;
  september?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "account.account_monthly_report". All fields are combined with a logical 'AND'. */
export type AccountAccountMonthlyReportBoolExp = {
  _and?: Maybe<Array<AccountAccountMonthlyReportBoolExp>>;
  _not?: Maybe<AccountAccountMonthlyReportBoolExp>;
  _or?: Maybe<Array<AccountAccountMonthlyReportBoolExp>>;
  april?: Maybe<NumericComparisonExp>;
  august?: Maybe<NumericComparisonExp>;
  budget_id?: Maybe<StringComparisonExp>;
  code?: Maybe<StringComparisonExp>;
  december?: Maybe<NumericComparisonExp>;
  february?: Maybe<NumericComparisonExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  january?: Maybe<NumericComparisonExp>;
  july?: Maybe<NumericComparisonExp>;
  june?: Maybe<NumericComparisonExp>;
  march?: Maybe<NumericComparisonExp>;
  may?: Maybe<NumericComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  november?: Maybe<NumericComparisonExp>;
  october?: Maybe<NumericComparisonExp>;
  organization_id?: Maybe<UuidComparisonExp>;
  september?: Maybe<NumericComparisonExp>;
  type_id?: Maybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type AccountAccountMonthlyReportMaxFields = {
  __typename?: 'account_account_monthly_report_max_fields';
  april?: Maybe<Scalars['numeric']>;
  august?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  december?: Maybe<Scalars['numeric']>;
  february?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  january?: Maybe<Scalars['numeric']>;
  july?: Maybe<Scalars['numeric']>;
  june?: Maybe<Scalars['numeric']>;
  march?: Maybe<Scalars['numeric']>;
  may?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  november?: Maybe<Scalars['numeric']>;
  october?: Maybe<Scalars['numeric']>;
  organization_id?: Maybe<Scalars['uuid']>;
  september?: Maybe<Scalars['numeric']>;
  type_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AccountAccountMonthlyReportMinFields = {
  __typename?: 'account_account_monthly_report_min_fields';
  april?: Maybe<Scalars['numeric']>;
  august?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  december?: Maybe<Scalars['numeric']>;
  february?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  january?: Maybe<Scalars['numeric']>;
  july?: Maybe<Scalars['numeric']>;
  june?: Maybe<Scalars['numeric']>;
  march?: Maybe<Scalars['numeric']>;
  may?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  november?: Maybe<Scalars['numeric']>;
  october?: Maybe<Scalars['numeric']>;
  organization_id?: Maybe<Scalars['uuid']>;
  september?: Maybe<Scalars['numeric']>;
  type_id?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "account.account_monthly_report". */
export type AccountAccountMonthlyReportOrderBy = {
  april?: Maybe<OrderBy>;
  august?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
  december?: Maybe<OrderBy>;
  february?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  january?: Maybe<OrderBy>;
  july?: Maybe<OrderBy>;
  june?: Maybe<OrderBy>;
  march?: Maybe<OrderBy>;
  may?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  november?: Maybe<OrderBy>;
  october?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  september?: Maybe<OrderBy>;
  type_id?: Maybe<OrderBy>;
};

/** select columns of table "account.account_monthly_report" */
export enum AccountAccountMonthlyReportSelectColumn {
  /** column name */
  April = 'april',
  /** column name */
  August = 'august',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  Code = 'code',
  /** column name */
  December = 'december',
  /** column name */
  February = 'february',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  Id = 'id',
  /** column name */
  January = 'january',
  /** column name */
  July = 'july',
  /** column name */
  June = 'june',
  /** column name */
  March = 'march',
  /** column name */
  May = 'may',
  /** column name */
  Name = 'name',
  /** column name */
  November = 'november',
  /** column name */
  October = 'october',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  September = 'september',
  /** column name */
  TypeId = 'type_id'
}

/** aggregate stddev on columns */
export type AccountAccountMonthlyReportStddevFields = {
  __typename?: 'account_account_monthly_report_stddev_fields';
  april?: Maybe<Scalars['Float']>;
  august?: Maybe<Scalars['Float']>;
  december?: Maybe<Scalars['Float']>;
  february?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  january?: Maybe<Scalars['Float']>;
  july?: Maybe<Scalars['Float']>;
  june?: Maybe<Scalars['Float']>;
  march?: Maybe<Scalars['Float']>;
  may?: Maybe<Scalars['Float']>;
  november?: Maybe<Scalars['Float']>;
  october?: Maybe<Scalars['Float']>;
  september?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AccountAccountMonthlyReportStddevPopFields = {
  __typename?: 'account_account_monthly_report_stddev_pop_fields';
  april?: Maybe<Scalars['Float']>;
  august?: Maybe<Scalars['Float']>;
  december?: Maybe<Scalars['Float']>;
  february?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  january?: Maybe<Scalars['Float']>;
  july?: Maybe<Scalars['Float']>;
  june?: Maybe<Scalars['Float']>;
  march?: Maybe<Scalars['Float']>;
  may?: Maybe<Scalars['Float']>;
  november?: Maybe<Scalars['Float']>;
  october?: Maybe<Scalars['Float']>;
  september?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AccountAccountMonthlyReportStddevSampFields = {
  __typename?: 'account_account_monthly_report_stddev_samp_fields';
  april?: Maybe<Scalars['Float']>;
  august?: Maybe<Scalars['Float']>;
  december?: Maybe<Scalars['Float']>;
  february?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  january?: Maybe<Scalars['Float']>;
  july?: Maybe<Scalars['Float']>;
  june?: Maybe<Scalars['Float']>;
  march?: Maybe<Scalars['Float']>;
  may?: Maybe<Scalars['Float']>;
  november?: Maybe<Scalars['Float']>;
  october?: Maybe<Scalars['Float']>;
  september?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type AccountAccountMonthlyReportSumFields = {
  __typename?: 'account_account_monthly_report_sum_fields';
  april?: Maybe<Scalars['numeric']>;
  august?: Maybe<Scalars['numeric']>;
  december?: Maybe<Scalars['numeric']>;
  february?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  january?: Maybe<Scalars['numeric']>;
  july?: Maybe<Scalars['numeric']>;
  june?: Maybe<Scalars['numeric']>;
  march?: Maybe<Scalars['numeric']>;
  may?: Maybe<Scalars['numeric']>;
  november?: Maybe<Scalars['numeric']>;
  october?: Maybe<Scalars['numeric']>;
  september?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type AccountAccountMonthlyReportVarPopFields = {
  __typename?: 'account_account_monthly_report_var_pop_fields';
  april?: Maybe<Scalars['Float']>;
  august?: Maybe<Scalars['Float']>;
  december?: Maybe<Scalars['Float']>;
  february?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  january?: Maybe<Scalars['Float']>;
  july?: Maybe<Scalars['Float']>;
  june?: Maybe<Scalars['Float']>;
  march?: Maybe<Scalars['Float']>;
  may?: Maybe<Scalars['Float']>;
  november?: Maybe<Scalars['Float']>;
  october?: Maybe<Scalars['Float']>;
  september?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AccountAccountMonthlyReportVarSampFields = {
  __typename?: 'account_account_monthly_report_var_samp_fields';
  april?: Maybe<Scalars['Float']>;
  august?: Maybe<Scalars['Float']>;
  december?: Maybe<Scalars['Float']>;
  february?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  january?: Maybe<Scalars['Float']>;
  july?: Maybe<Scalars['Float']>;
  june?: Maybe<Scalars['Float']>;
  march?: Maybe<Scalars['Float']>;
  may?: Maybe<Scalars['Float']>;
  november?: Maybe<Scalars['Float']>;
  october?: Maybe<Scalars['Float']>;
  september?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AccountAccountMonthlyReportVarianceFields = {
  __typename?: 'account_account_monthly_report_variance_fields';
  april?: Maybe<Scalars['Float']>;
  august?: Maybe<Scalars['Float']>;
  december?: Maybe<Scalars['Float']>;
  february?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  january?: Maybe<Scalars['Float']>;
  july?: Maybe<Scalars['Float']>;
  june?: Maybe<Scalars['Float']>;
  march?: Maybe<Scalars['Float']>;
  may?: Maybe<Scalars['Float']>;
  november?: Maybe<Scalars['Float']>;
  october?: Maybe<Scalars['Float']>;
  september?: Maybe<Scalars['Float']>;
};

/** response of any mutation on the table "account.account" */
export type AccountAccountMutationResponse = {
  __typename?: 'account_account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccountAccount>;
};

/** input type for inserting object relation for remote table "account.account" */
export type AccountAccountObjRelInsertInput = {
  data: AccountAccountInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<AccountAccountOnConflict>;
};

/** on conflict condition type for table "account.account" */
export type AccountAccountOnConflict = {
  constraint: AccountAccountConstraint;
  update_columns?: Array<AccountAccountUpdateColumn>;
  where?: Maybe<AccountAccountBoolExp>;
};

/** Ordering options when selecting data from "account.account". */
export type AccountAccountOrderBy = {
  account_balance?: Maybe<AccountAccountBalanceOrderBy>;
  account_category?: Maybe<AccountAccountCategoryOrderBy>;
  account_type?: Maybe<AccountAccountTypeOrderBy>;
  balances_aggregate?: Maybe<AccountAccountBalanceSheetAggregateOrderBy>;
  budget_accounts_aggregate?: Maybe<AccountBudgetAccountAggregateOrderBy>;
  budget_types_aggregate?: Maybe<AccountBudgetTypeAggregateOrderBy>;
  category_id?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  default_entry_type_id?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  entry_type?: Maybe<AccountEntryTypeOrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ledger_balances_debt_aggregate?: Maybe<LedgerAccountBudgetBalanceDebtAggregateOrderBy>;
  ledger_balances_expense_aggregate?: Maybe<LedgerAccountBudgetBalanceExpenseAggregateOrderBy>;
  ledger_entries_aggregate?: Maybe<AccountLedgerEntryAggregateOrderBy>;
  ledger_entry_views_aggregate?: Maybe<AccountLedgerEntryViewAggregateOrderBy>;
  ledgers_aggregate?: Maybe<LedgerLedgerAggregateOrderBy>;
  name?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  parent_id?: Maybe<OrderBy>;
  receivables_aggregate?: Maybe<LedgerReceivableAggregateOrderBy>;
  type_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: account_account */
export type AccountAccountPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "account.account" */
export enum AccountAccountSelectColumn {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultEntryTypeId = 'default_entry_type_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "account.account" */
export type AccountAccountSetInput = {
  category_id?: Maybe<Scalars['uuid']>;
  /** รหัสบัญชี */
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_entry_type_id?: Maybe<AccountEntryTypeEnum>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อบัญชี */
  name?: Maybe<Scalars['String']>;
  /** โน้ตเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** บัญชีที่เป็น parent */
  parent_id?: Maybe<Scalars['uuid']>;
  /** ประเภทบัญชี (FK) */
  type_id?: Maybe<AccountAccountTypeEnum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type AccountAccountStddevFields = {
  __typename?: 'account_account_stddev_fields';
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "account.account" */
export type AccountAccountStddevOrderBy = {
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type AccountAccountStddevPopFields = {
  __typename?: 'account_account_stddev_pop_fields';
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "account.account" */
export type AccountAccountStddevPopOrderBy = {
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type AccountAccountStddevSampFields = {
  __typename?: 'account_account_stddev_samp_fields';
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "account.account" */
export type AccountAccountStddevSampOrderBy = {
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type AccountAccountSumFields = {
  __typename?: 'account_account_sum_fields';
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "account.account" */
export type AccountAccountSumOrderBy = {
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<OrderBy>;
};

/**
 * ประเภทบัญชี
 *
 *
 * columns and relationships of "account.account_type"
 */
export type AccountAccountType = {
  __typename?: 'account_account_type';
  /** An array relationship */
  accounts: Array<AccountAccount>;
  /** An aggregate relationship */
  accounts_aggregate: AccountAccountAggregate;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};


/**
 * ประเภทบัญชี
 *
 *
 * columns and relationships of "account.account_type"
 */
export type AccountAccountTypeAccountsArgs = {
  distinct_on?: Maybe<Array<AccountAccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountOrderBy>>;
  where?: Maybe<AccountAccountBoolExp>;
};


/**
 * ประเภทบัญชี
 *
 *
 * columns and relationships of "account.account_type"
 */
export type AccountAccountTypeAccountsAggregateArgs = {
  distinct_on?: Maybe<Array<AccountAccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountOrderBy>>;
  where?: Maybe<AccountAccountBoolExp>;
};

/** aggregated selection of "account.account_type" */
export type AccountAccountTypeAggregate = {
  __typename?: 'account_account_type_aggregate';
  aggregate?: Maybe<AccountAccountTypeAggregateFields>;
  nodes: Array<AccountAccountType>;
};

/** aggregate fields of "account.account_type" */
export type AccountAccountTypeAggregateFields = {
  __typename?: 'account_account_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AccountAccountTypeMaxFields>;
  min?: Maybe<AccountAccountTypeMinFields>;
};


/** aggregate fields of "account.account_type" */
export type AccountAccountTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountAccountTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "account.account_type". All fields are combined with a logical 'AND'. */
export type AccountAccountTypeBoolExp = {
  _and?: Maybe<Array<AccountAccountTypeBoolExp>>;
  _not?: Maybe<AccountAccountTypeBoolExp>;
  _or?: Maybe<Array<AccountAccountTypeBoolExp>>;
  accounts?: Maybe<AccountAccountBoolExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "account.account_type" */
export enum AccountAccountTypeConstraint {
  /** unique or primary key constraint */
  CoaTypePkey = 'coa_type_pkey'
}

export enum AccountAccountTypeEnum {
  /** สินทรัพย์ */
  Asset = 'asset',
  /** ทุน */
  Equity = 'equity',
  /** ค่าใช้จ่าย */
  Expense = 'expense',
  /** หนี้สิน */
  Liability = 'liability',
  /** รายได้ */
  Revenue = 'revenue'
}

/** Boolean expression to compare columns of type "account_account_type_enum". All fields are combined with logical 'AND'. */
export type AccountAccountTypeEnumComparisonExp = {
  _eq?: Maybe<AccountAccountTypeEnum>;
  _in?: Maybe<Array<AccountAccountTypeEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<AccountAccountTypeEnum>;
  _nin?: Maybe<Array<AccountAccountTypeEnum>>;
};

/** input type for inserting data into table "account.account_type" */
export type AccountAccountTypeInsertInput = {
  accounts?: Maybe<AccountAccountArrRelInsertInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AccountAccountTypeMaxFields = {
  __typename?: 'account_account_type_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AccountAccountTypeMinFields = {
  __typename?: 'account_account_type_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "account.account_type" */
export type AccountAccountTypeMutationResponse = {
  __typename?: 'account_account_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccountAccountType>;
};

/** input type for inserting object relation for remote table "account.account_type" */
export type AccountAccountTypeObjRelInsertInput = {
  data: AccountAccountTypeInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<AccountAccountTypeOnConflict>;
};

/** on conflict condition type for table "account.account_type" */
export type AccountAccountTypeOnConflict = {
  constraint: AccountAccountTypeConstraint;
  update_columns?: Array<AccountAccountTypeUpdateColumn>;
  where?: Maybe<AccountAccountTypeBoolExp>;
};

/** Ordering options when selecting data from "account.account_type". */
export type AccountAccountTypeOrderBy = {
  accounts_aggregate?: Maybe<AccountAccountAggregateOrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** primary key columns input for table: account_account_type */
export type AccountAccountTypePkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "account.account_type" */
export enum AccountAccountTypeSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "account.account_type" */
export type AccountAccountTypeSetInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** update columns of table "account.account_type" */
export enum AccountAccountTypeUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** update columns of table "account.account" */
export enum AccountAccountUpdateColumn {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultEntryTypeId = 'default_entry_type_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type AccountAccountVarPopFields = {
  __typename?: 'account_account_var_pop_fields';
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "account.account" */
export type AccountAccountVarPopOrderBy = {
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type AccountAccountVarSampFields = {
  __typename?: 'account_account_var_samp_fields';
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "account.account" */
export type AccountAccountVarSampOrderBy = {
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type AccountAccountVarianceFields = {
  __typename?: 'account_account_variance_fields';
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "account.account" */
export type AccountAccountVarianceOrderBy = {
  /** ปีงบประมาณ พ.ศ. */
  fiscal_year?: Maybe<OrderBy>;
};

/** columns and relationships of "account.budget_account" */
export type AccountBudgetAccount = {
  __typename?: 'account_budget_account';
  /** An object relationship */
  account: AccountAccount;
  account_id: Scalars['uuid'];
  budget_id: Scalars['String'];
  /** An object relationship */
  budget_type?: Maybe<AccountBudgetType>;
  fiscal_year: Scalars['Int'];
};

/** aggregated selection of "account.budget_account" */
export type AccountBudgetAccountAggregate = {
  __typename?: 'account_budget_account_aggregate';
  aggregate?: Maybe<AccountBudgetAccountAggregateFields>;
  nodes: Array<AccountBudgetAccount>;
};

/** aggregate fields of "account.budget_account" */
export type AccountBudgetAccountAggregateFields = {
  __typename?: 'account_budget_account_aggregate_fields';
  avg?: Maybe<AccountBudgetAccountAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AccountBudgetAccountMaxFields>;
  min?: Maybe<AccountBudgetAccountMinFields>;
  stddev?: Maybe<AccountBudgetAccountStddevFields>;
  stddev_pop?: Maybe<AccountBudgetAccountStddevPopFields>;
  stddev_samp?: Maybe<AccountBudgetAccountStddevSampFields>;
  sum?: Maybe<AccountBudgetAccountSumFields>;
  var_pop?: Maybe<AccountBudgetAccountVarPopFields>;
  var_samp?: Maybe<AccountBudgetAccountVarSampFields>;
  variance?: Maybe<AccountBudgetAccountVarianceFields>;
};


/** aggregate fields of "account.budget_account" */
export type AccountBudgetAccountAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountBudgetAccountSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account.budget_account" */
export type AccountBudgetAccountAggregateOrderBy = {
  avg?: Maybe<AccountBudgetAccountAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<AccountBudgetAccountMaxOrderBy>;
  min?: Maybe<AccountBudgetAccountMinOrderBy>;
  stddev?: Maybe<AccountBudgetAccountStddevOrderBy>;
  stddev_pop?: Maybe<AccountBudgetAccountStddevPopOrderBy>;
  stddev_samp?: Maybe<AccountBudgetAccountStddevSampOrderBy>;
  sum?: Maybe<AccountBudgetAccountSumOrderBy>;
  var_pop?: Maybe<AccountBudgetAccountVarPopOrderBy>;
  var_samp?: Maybe<AccountBudgetAccountVarSampOrderBy>;
  variance?: Maybe<AccountBudgetAccountVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "account.budget_account" */
export type AccountBudgetAccountArrRelInsertInput = {
  data: Array<AccountBudgetAccountInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<AccountBudgetAccountOnConflict>;
};

/** aggregate avg on columns */
export type AccountBudgetAccountAvgFields = {
  __typename?: 'account_budget_account_avg_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "account.budget_account" */
export type AccountBudgetAccountAvgOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "account.budget_account". All fields are combined with a logical 'AND'. */
export type AccountBudgetAccountBoolExp = {
  _and?: Maybe<Array<AccountBudgetAccountBoolExp>>;
  _not?: Maybe<AccountBudgetAccountBoolExp>;
  _or?: Maybe<Array<AccountBudgetAccountBoolExp>>;
  account?: Maybe<AccountAccountBoolExp>;
  account_id?: Maybe<UuidComparisonExp>;
  budget_id?: Maybe<StringComparisonExp>;
  budget_type?: Maybe<AccountBudgetTypeBoolExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "account.budget_account" */
export enum AccountBudgetAccountConstraint {
  /** unique or primary key constraint */
  BudgetAccountPkey = 'budget_account_pkey'
}

/** input type for incrementing numeric columns in table "account.budget_account" */
export type AccountBudgetAccountIncInput = {
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "account.budget_account" */
export type AccountBudgetAccountInsertInput = {
  account?: Maybe<AccountAccountObjRelInsertInput>;
  account_id?: Maybe<Scalars['uuid']>;
  budget_id?: Maybe<Scalars['String']>;
  budget_type?: Maybe<AccountBudgetTypeObjRelInsertInput>;
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type AccountBudgetAccountMaxFields = {
  __typename?: 'account_budget_account_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  budget_id?: Maybe<Scalars['String']>;
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "account.budget_account" */
export type AccountBudgetAccountMaxOrderBy = {
  account_id?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AccountBudgetAccountMinFields = {
  __typename?: 'account_budget_account_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  budget_id?: Maybe<Scalars['String']>;
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "account.budget_account" */
export type AccountBudgetAccountMinOrderBy = {
  account_id?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** response of any mutation on the table "account.budget_account" */
export type AccountBudgetAccountMutationResponse = {
  __typename?: 'account_budget_account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccountBudgetAccount>;
};

/** on conflict condition type for table "account.budget_account" */
export type AccountBudgetAccountOnConflict = {
  constraint: AccountBudgetAccountConstraint;
  update_columns?: Array<AccountBudgetAccountUpdateColumn>;
  where?: Maybe<AccountBudgetAccountBoolExp>;
};

/** Ordering options when selecting data from "account.budget_account". */
export type AccountBudgetAccountOrderBy = {
  account?: Maybe<AccountAccountOrderBy>;
  account_id?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  budget_type?: Maybe<AccountBudgetTypeOrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** primary key columns input for table: account_budget_account */
export type AccountBudgetAccountPkColumnsInput = {
  account_id: Scalars['uuid'];
  budget_id: Scalars['String'];
  fiscal_year: Scalars['Int'];
};

/** select columns of table "account.budget_account" */
export enum AccountBudgetAccountSelectColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  FiscalYear = 'fiscal_year'
}

/** input type for updating data in table "account.budget_account" */
export type AccountBudgetAccountSetInput = {
  account_id?: Maybe<Scalars['uuid']>;
  budget_id?: Maybe<Scalars['String']>;
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type AccountBudgetAccountStddevFields = {
  __typename?: 'account_budget_account_stddev_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "account.budget_account" */
export type AccountBudgetAccountStddevOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type AccountBudgetAccountStddevPopFields = {
  __typename?: 'account_budget_account_stddev_pop_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "account.budget_account" */
export type AccountBudgetAccountStddevPopOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type AccountBudgetAccountStddevSampFields = {
  __typename?: 'account_budget_account_stddev_samp_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "account.budget_account" */
export type AccountBudgetAccountStddevSampOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type AccountBudgetAccountSumFields = {
  __typename?: 'account_budget_account_sum_fields';
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "account.budget_account" */
export type AccountBudgetAccountSumOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
};

/** update columns of table "account.budget_account" */
export enum AccountBudgetAccountUpdateColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  FiscalYear = 'fiscal_year'
}

/** aggregate var_pop on columns */
export type AccountBudgetAccountVarPopFields = {
  __typename?: 'account_budget_account_var_pop_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "account.budget_account" */
export type AccountBudgetAccountVarPopOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type AccountBudgetAccountVarSampFields = {
  __typename?: 'account_budget_account_var_samp_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "account.budget_account" */
export type AccountBudgetAccountVarSampOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type AccountBudgetAccountVarianceFields = {
  __typename?: 'account_budget_account_variance_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "account.budget_account" */
export type AccountBudgetAccountVarianceOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
};

/** columns and relationships of "account.budget_allocation" */
export type AccountBudgetAllocation = {
  __typename?: 'account_budget_allocation';
  /** An array relationship */
  budget_allocation_logs: Array<AccountBudgetAllocationLog>;
  /** An aggregate relationship */
  budget_allocation_logs_aggregate: AccountBudgetAllocationLogAggregate;
  budget_total: Scalars['numeric'];
  /** An object relationship */
  budget_type?: Maybe<AccountBudgetType>;
  budget_type_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  fiscal_year: Scalars['Int'];
  id: Scalars['uuid'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "account.budget_allocation" */
export type AccountBudgetAllocationBudgetAllocationLogsArgs = {
  distinct_on?: Maybe<Array<AccountBudgetAllocationLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetAllocationLogOrderBy>>;
  where?: Maybe<AccountBudgetAllocationLogBoolExp>;
};


/** columns and relationships of "account.budget_allocation" */
export type AccountBudgetAllocationBudgetAllocationLogsAggregateArgs = {
  distinct_on?: Maybe<Array<AccountBudgetAllocationLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetAllocationLogOrderBy>>;
  where?: Maybe<AccountBudgetAllocationLogBoolExp>;
};

/** aggregated selection of "account.budget_allocation" */
export type AccountBudgetAllocationAggregate = {
  __typename?: 'account_budget_allocation_aggregate';
  aggregate?: Maybe<AccountBudgetAllocationAggregateFields>;
  nodes: Array<AccountBudgetAllocation>;
};

/** aggregate fields of "account.budget_allocation" */
export type AccountBudgetAllocationAggregateFields = {
  __typename?: 'account_budget_allocation_aggregate_fields';
  avg?: Maybe<AccountBudgetAllocationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AccountBudgetAllocationMaxFields>;
  min?: Maybe<AccountBudgetAllocationMinFields>;
  stddev?: Maybe<AccountBudgetAllocationStddevFields>;
  stddev_pop?: Maybe<AccountBudgetAllocationStddevPopFields>;
  stddev_samp?: Maybe<AccountBudgetAllocationStddevSampFields>;
  sum?: Maybe<AccountBudgetAllocationSumFields>;
  var_pop?: Maybe<AccountBudgetAllocationVarPopFields>;
  var_samp?: Maybe<AccountBudgetAllocationVarSampFields>;
  variance?: Maybe<AccountBudgetAllocationVarianceFields>;
};


/** aggregate fields of "account.budget_allocation" */
export type AccountBudgetAllocationAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountBudgetAllocationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account.budget_allocation" */
export type AccountBudgetAllocationAggregateOrderBy = {
  avg?: Maybe<AccountBudgetAllocationAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<AccountBudgetAllocationMaxOrderBy>;
  min?: Maybe<AccountBudgetAllocationMinOrderBy>;
  stddev?: Maybe<AccountBudgetAllocationStddevOrderBy>;
  stddev_pop?: Maybe<AccountBudgetAllocationStddevPopOrderBy>;
  stddev_samp?: Maybe<AccountBudgetAllocationStddevSampOrderBy>;
  sum?: Maybe<AccountBudgetAllocationSumOrderBy>;
  var_pop?: Maybe<AccountBudgetAllocationVarPopOrderBy>;
  var_samp?: Maybe<AccountBudgetAllocationVarSampOrderBy>;
  variance?: Maybe<AccountBudgetAllocationVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "account.budget_allocation" */
export type AccountBudgetAllocationArrRelInsertInput = {
  data: Array<AccountBudgetAllocationInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<AccountBudgetAllocationOnConflict>;
};

/** aggregate avg on columns */
export type AccountBudgetAllocationAvgFields = {
  __typename?: 'account_budget_allocation_avg_fields';
  budget_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "account.budget_allocation" */
export type AccountBudgetAllocationAvgOrderBy = {
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "account.budget_allocation". All fields are combined with a logical 'AND'. */
export type AccountBudgetAllocationBoolExp = {
  _and?: Maybe<Array<AccountBudgetAllocationBoolExp>>;
  _not?: Maybe<AccountBudgetAllocationBoolExp>;
  _or?: Maybe<Array<AccountBudgetAllocationBoolExp>>;
  budget_allocation_logs?: Maybe<AccountBudgetAllocationLogBoolExp>;
  budget_total?: Maybe<NumericComparisonExp>;
  budget_type?: Maybe<AccountBudgetTypeBoolExp>;
  budget_type_id?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  organization?: Maybe<OrganizationBoolExp>;
  organization_id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "account.budget_allocation" */
export enum AccountBudgetAllocationConstraint {
  /** unique or primary key constraint */
  BudgetAllocationOrganizationIdBudgetTypeIdFiscalYearKe = 'budget_allocation_organization_id_budget_type_id_fiscal_year_ke',
  /** unique or primary key constraint */
  BudgetAllocationPkey = 'budget_allocation_pkey'
}

/** input type for incrementing numeric columns in table "account.budget_allocation" */
export type AccountBudgetAllocationIncInput = {
  budget_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "account.budget_allocation" */
export type AccountBudgetAllocationInsertInput = {
  budget_allocation_logs?: Maybe<AccountBudgetAllocationLogArrRelInsertInput>;
  budget_total?: Maybe<Scalars['numeric']>;
  budget_type?: Maybe<AccountBudgetTypeObjRelInsertInput>;
  budget_type_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  organization?: Maybe<OrganizationObjRelInsertInput>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/**
 * ประวัติการจัดสรรงบประมาณ
 *
 *
 * columns and relationships of "account.budget_allocation_log"
 */
export type AccountBudgetAllocationLog = {
  __typename?: 'account_budget_allocation_log';
  /** An object relationship */
  budget_allocation: AccountBudgetAllocation;
  budget_allocation_id: Scalars['uuid'];
  budget_type_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  fiscal_year: Scalars['Int'];
  id: Scalars['uuid'];
  new_budget_total: Scalars['numeric'];
  old_budget_total: Scalars['numeric'];
  organization_id: Scalars['uuid'];
};

/** aggregated selection of "account.budget_allocation_log" */
export type AccountBudgetAllocationLogAggregate = {
  __typename?: 'account_budget_allocation_log_aggregate';
  aggregate?: Maybe<AccountBudgetAllocationLogAggregateFields>;
  nodes: Array<AccountBudgetAllocationLog>;
};

/** aggregate fields of "account.budget_allocation_log" */
export type AccountBudgetAllocationLogAggregateFields = {
  __typename?: 'account_budget_allocation_log_aggregate_fields';
  avg?: Maybe<AccountBudgetAllocationLogAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AccountBudgetAllocationLogMaxFields>;
  min?: Maybe<AccountBudgetAllocationLogMinFields>;
  stddev?: Maybe<AccountBudgetAllocationLogStddevFields>;
  stddev_pop?: Maybe<AccountBudgetAllocationLogStddevPopFields>;
  stddev_samp?: Maybe<AccountBudgetAllocationLogStddevSampFields>;
  sum?: Maybe<AccountBudgetAllocationLogSumFields>;
  var_pop?: Maybe<AccountBudgetAllocationLogVarPopFields>;
  var_samp?: Maybe<AccountBudgetAllocationLogVarSampFields>;
  variance?: Maybe<AccountBudgetAllocationLogVarianceFields>;
};


/** aggregate fields of "account.budget_allocation_log" */
export type AccountBudgetAllocationLogAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountBudgetAllocationLogSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account.budget_allocation_log" */
export type AccountBudgetAllocationLogAggregateOrderBy = {
  avg?: Maybe<AccountBudgetAllocationLogAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<AccountBudgetAllocationLogMaxOrderBy>;
  min?: Maybe<AccountBudgetAllocationLogMinOrderBy>;
  stddev?: Maybe<AccountBudgetAllocationLogStddevOrderBy>;
  stddev_pop?: Maybe<AccountBudgetAllocationLogStddevPopOrderBy>;
  stddev_samp?: Maybe<AccountBudgetAllocationLogStddevSampOrderBy>;
  sum?: Maybe<AccountBudgetAllocationLogSumOrderBy>;
  var_pop?: Maybe<AccountBudgetAllocationLogVarPopOrderBy>;
  var_samp?: Maybe<AccountBudgetAllocationLogVarSampOrderBy>;
  variance?: Maybe<AccountBudgetAllocationLogVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "account.budget_allocation_log" */
export type AccountBudgetAllocationLogArrRelInsertInput = {
  data: Array<AccountBudgetAllocationLogInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<AccountBudgetAllocationLogOnConflict>;
};

/** aggregate avg on columns */
export type AccountBudgetAllocationLogAvgFields = {
  __typename?: 'account_budget_allocation_log_avg_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
  new_budget_total?: Maybe<Scalars['Float']>;
  old_budget_total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "account.budget_allocation_log" */
export type AccountBudgetAllocationLogAvgOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  new_budget_total?: Maybe<OrderBy>;
  old_budget_total?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "account.budget_allocation_log". All fields are combined with a logical 'AND'. */
export type AccountBudgetAllocationLogBoolExp = {
  _and?: Maybe<Array<AccountBudgetAllocationLogBoolExp>>;
  _not?: Maybe<AccountBudgetAllocationLogBoolExp>;
  _or?: Maybe<Array<AccountBudgetAllocationLogBoolExp>>;
  budget_allocation?: Maybe<AccountBudgetAllocationBoolExp>;
  budget_allocation_id?: Maybe<UuidComparisonExp>;
  budget_type_id?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  new_budget_total?: Maybe<NumericComparisonExp>;
  old_budget_total?: Maybe<NumericComparisonExp>;
  organization_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "account.budget_allocation_log" */
export enum AccountBudgetAllocationLogConstraint {
  /** unique or primary key constraint */
  BudgetAllocationLogPkey = 'budget_allocation_log_pkey'
}

/** input type for incrementing numeric columns in table "account.budget_allocation_log" */
export type AccountBudgetAllocationLogIncInput = {
  fiscal_year?: Maybe<Scalars['Int']>;
  new_budget_total?: Maybe<Scalars['numeric']>;
  old_budget_total?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "account.budget_allocation_log" */
export type AccountBudgetAllocationLogInsertInput = {
  budget_allocation?: Maybe<AccountBudgetAllocationObjRelInsertInput>;
  budget_allocation_id?: Maybe<Scalars['uuid']>;
  budget_type_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  new_budget_total?: Maybe<Scalars['numeric']>;
  old_budget_total?: Maybe<Scalars['numeric']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AccountBudgetAllocationLogMaxFields = {
  __typename?: 'account_budget_allocation_log_max_fields';
  budget_allocation_id?: Maybe<Scalars['uuid']>;
  budget_type_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  new_budget_total?: Maybe<Scalars['numeric']>;
  old_budget_total?: Maybe<Scalars['numeric']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "account.budget_allocation_log" */
export type AccountBudgetAllocationLogMaxOrderBy = {
  budget_allocation_id?: Maybe<OrderBy>;
  budget_type_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  new_budget_total?: Maybe<OrderBy>;
  old_budget_total?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AccountBudgetAllocationLogMinFields = {
  __typename?: 'account_budget_allocation_log_min_fields';
  budget_allocation_id?: Maybe<Scalars['uuid']>;
  budget_type_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  new_budget_total?: Maybe<Scalars['numeric']>;
  old_budget_total?: Maybe<Scalars['numeric']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "account.budget_allocation_log" */
export type AccountBudgetAllocationLogMinOrderBy = {
  budget_allocation_id?: Maybe<OrderBy>;
  budget_type_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  new_budget_total?: Maybe<OrderBy>;
  old_budget_total?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "account.budget_allocation_log" */
export type AccountBudgetAllocationLogMutationResponse = {
  __typename?: 'account_budget_allocation_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccountBudgetAllocationLog>;
};

/** on conflict condition type for table "account.budget_allocation_log" */
export type AccountBudgetAllocationLogOnConflict = {
  constraint: AccountBudgetAllocationLogConstraint;
  update_columns?: Array<AccountBudgetAllocationLogUpdateColumn>;
  where?: Maybe<AccountBudgetAllocationLogBoolExp>;
};

/** Ordering options when selecting data from "account.budget_allocation_log". */
export type AccountBudgetAllocationLogOrderBy = {
  budget_allocation?: Maybe<AccountBudgetAllocationOrderBy>;
  budget_allocation_id?: Maybe<OrderBy>;
  budget_type_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  new_budget_total?: Maybe<OrderBy>;
  old_budget_total?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: account_budget_allocation_log */
export type AccountBudgetAllocationLogPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "account.budget_allocation_log" */
export enum AccountBudgetAllocationLogSelectColumn {
  /** column name */
  BudgetAllocationId = 'budget_allocation_id',
  /** column name */
  BudgetTypeId = 'budget_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  Id = 'id',
  /** column name */
  NewBudgetTotal = 'new_budget_total',
  /** column name */
  OldBudgetTotal = 'old_budget_total',
  /** column name */
  OrganizationId = 'organization_id'
}

/** input type for updating data in table "account.budget_allocation_log" */
export type AccountBudgetAllocationLogSetInput = {
  budget_allocation_id?: Maybe<Scalars['uuid']>;
  budget_type_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  new_budget_total?: Maybe<Scalars['numeric']>;
  old_budget_total?: Maybe<Scalars['numeric']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type AccountBudgetAllocationLogStddevFields = {
  __typename?: 'account_budget_allocation_log_stddev_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
  new_budget_total?: Maybe<Scalars['Float']>;
  old_budget_total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "account.budget_allocation_log" */
export type AccountBudgetAllocationLogStddevOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  new_budget_total?: Maybe<OrderBy>;
  old_budget_total?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type AccountBudgetAllocationLogStddevPopFields = {
  __typename?: 'account_budget_allocation_log_stddev_pop_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
  new_budget_total?: Maybe<Scalars['Float']>;
  old_budget_total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "account.budget_allocation_log" */
export type AccountBudgetAllocationLogStddevPopOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  new_budget_total?: Maybe<OrderBy>;
  old_budget_total?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type AccountBudgetAllocationLogStddevSampFields = {
  __typename?: 'account_budget_allocation_log_stddev_samp_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
  new_budget_total?: Maybe<Scalars['Float']>;
  old_budget_total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "account.budget_allocation_log" */
export type AccountBudgetAllocationLogStddevSampOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  new_budget_total?: Maybe<OrderBy>;
  old_budget_total?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type AccountBudgetAllocationLogSumFields = {
  __typename?: 'account_budget_allocation_log_sum_fields';
  fiscal_year?: Maybe<Scalars['Int']>;
  new_budget_total?: Maybe<Scalars['numeric']>;
  old_budget_total?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "account.budget_allocation_log" */
export type AccountBudgetAllocationLogSumOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  new_budget_total?: Maybe<OrderBy>;
  old_budget_total?: Maybe<OrderBy>;
};

/** update columns of table "account.budget_allocation_log" */
export enum AccountBudgetAllocationLogUpdateColumn {
  /** column name */
  BudgetAllocationId = 'budget_allocation_id',
  /** column name */
  BudgetTypeId = 'budget_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  Id = 'id',
  /** column name */
  NewBudgetTotal = 'new_budget_total',
  /** column name */
  OldBudgetTotal = 'old_budget_total',
  /** column name */
  OrganizationId = 'organization_id'
}

/** aggregate var_pop on columns */
export type AccountBudgetAllocationLogVarPopFields = {
  __typename?: 'account_budget_allocation_log_var_pop_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
  new_budget_total?: Maybe<Scalars['Float']>;
  old_budget_total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "account.budget_allocation_log" */
export type AccountBudgetAllocationLogVarPopOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  new_budget_total?: Maybe<OrderBy>;
  old_budget_total?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type AccountBudgetAllocationLogVarSampFields = {
  __typename?: 'account_budget_allocation_log_var_samp_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
  new_budget_total?: Maybe<Scalars['Float']>;
  old_budget_total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "account.budget_allocation_log" */
export type AccountBudgetAllocationLogVarSampOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  new_budget_total?: Maybe<OrderBy>;
  old_budget_total?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type AccountBudgetAllocationLogVarianceFields = {
  __typename?: 'account_budget_allocation_log_variance_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
  new_budget_total?: Maybe<Scalars['Float']>;
  old_budget_total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "account.budget_allocation_log" */
export type AccountBudgetAllocationLogVarianceOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  new_budget_total?: Maybe<OrderBy>;
  old_budget_total?: Maybe<OrderBy>;
};

/** aggregate max on columns */
export type AccountBudgetAllocationMaxFields = {
  __typename?: 'account_budget_allocation_max_fields';
  budget_total?: Maybe<Scalars['numeric']>;
  budget_type_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "account.budget_allocation" */
export type AccountBudgetAllocationMaxOrderBy = {
  budget_total?: Maybe<OrderBy>;
  budget_type_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AccountBudgetAllocationMinFields = {
  __typename?: 'account_budget_allocation_min_fields';
  budget_total?: Maybe<Scalars['numeric']>;
  budget_type_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "account.budget_allocation" */
export type AccountBudgetAllocationMinOrderBy = {
  budget_total?: Maybe<OrderBy>;
  budget_type_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "account.budget_allocation" */
export type AccountBudgetAllocationMutationResponse = {
  __typename?: 'account_budget_allocation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccountBudgetAllocation>;
};

/** input type for inserting object relation for remote table "account.budget_allocation" */
export type AccountBudgetAllocationObjRelInsertInput = {
  data: AccountBudgetAllocationInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<AccountBudgetAllocationOnConflict>;
};

/** on conflict condition type for table "account.budget_allocation" */
export type AccountBudgetAllocationOnConflict = {
  constraint: AccountBudgetAllocationConstraint;
  update_columns?: Array<AccountBudgetAllocationUpdateColumn>;
  where?: Maybe<AccountBudgetAllocationBoolExp>;
};

/** Ordering options when selecting data from "account.budget_allocation". */
export type AccountBudgetAllocationOrderBy = {
  budget_allocation_logs_aggregate?: Maybe<AccountBudgetAllocationLogAggregateOrderBy>;
  budget_total?: Maybe<OrderBy>;
  budget_type?: Maybe<AccountBudgetTypeOrderBy>;
  budget_type_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  organization?: Maybe<OrganizationOrderBy>;
  organization_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** primary key columns input for table: account_budget_allocation */
export type AccountBudgetAllocationPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "account.budget_allocation" */
export enum AccountBudgetAllocationSelectColumn {
  /** column name */
  BudgetTotal = 'budget_total',
  /** column name */
  BudgetTypeId = 'budget_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "account.budget_allocation" */
export type AccountBudgetAllocationSetInput = {
  budget_total?: Maybe<Scalars['numeric']>;
  budget_type_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type AccountBudgetAllocationStddevFields = {
  __typename?: 'account_budget_allocation_stddev_fields';
  budget_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "account.budget_allocation" */
export type AccountBudgetAllocationStddevOrderBy = {
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type AccountBudgetAllocationStddevPopFields = {
  __typename?: 'account_budget_allocation_stddev_pop_fields';
  budget_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "account.budget_allocation" */
export type AccountBudgetAllocationStddevPopOrderBy = {
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type AccountBudgetAllocationStddevSampFields = {
  __typename?: 'account_budget_allocation_stddev_samp_fields';
  budget_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "account.budget_allocation" */
export type AccountBudgetAllocationStddevSampOrderBy = {
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type AccountBudgetAllocationSumFields = {
  __typename?: 'account_budget_allocation_sum_fields';
  budget_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "account.budget_allocation" */
export type AccountBudgetAllocationSumOrderBy = {
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** update columns of table "account.budget_allocation" */
export enum AccountBudgetAllocationUpdateColumn {
  /** column name */
  BudgetTotal = 'budget_total',
  /** column name */
  BudgetTypeId = 'budget_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type AccountBudgetAllocationVarPopFields = {
  __typename?: 'account_budget_allocation_var_pop_fields';
  budget_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "account.budget_allocation" */
export type AccountBudgetAllocationVarPopOrderBy = {
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type AccountBudgetAllocationVarSampFields = {
  __typename?: 'account_budget_allocation_var_samp_fields';
  budget_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "account.budget_allocation" */
export type AccountBudgetAllocationVarSampOrderBy = {
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type AccountBudgetAllocationVarianceFields = {
  __typename?: 'account_budget_allocation_variance_fields';
  budget_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "account.budget_allocation" */
export type AccountBudgetAllocationVarianceOrderBy = {
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/**
 * ประเภทงบประมาณ
 *
 *
 * columns and relationships of "account.budget_type"
 */
export type AccountBudgetType = {
  __typename?: 'account_budget_type';
  /** An object relationship */
  account?: Maybe<AccountAccount>;
  account_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  allocations: Array<LedgerBudgetAllocation>;
  /** An aggregate relationship */
  allocations_aggregate: LedgerBudgetAllocationAggregate;
  /** An array relationship */
  budget_accounts: Array<AccountBudgetAccount>;
  /** An aggregate relationship */
  budget_accounts_aggregate: AccountBudgetAccountAggregate;
  /** An array relationship */
  budget_allocations: Array<AccountBudgetAllocation>;
  /** An aggregate relationship */
  budget_allocations_aggregate: AccountBudgetAllocationAggregate;
  closed: Scalars['Boolean'];
  closed_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  fiscal_year: Scalars['Int'];
  group_id: Scalars['String'];
  group_name: Scalars['String'];
  id: Scalars['String'];
  /** An array relationship */
  ledger_entries: Array<AccountLedgerEntry>;
  /** An aggregate relationship */
  ledger_entries_aggregate: AccountLedgerEntryAggregate;
  /** An array relationship */
  ledgers: Array<LedgerLedger>;
  /** An aggregate relationship */
  ledgers_aggregate: LedgerLedgerAggregate;
  sequence?: Maybe<Scalars['Int']>;
};


/**
 * ประเภทงบประมาณ
 *
 *
 * columns and relationships of "account.budget_type"
 */
export type AccountBudgetTypeAllocationsArgs = {
  distinct_on?: Maybe<Array<LedgerBudgetAllocationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerBudgetAllocationOrderBy>>;
  where?: Maybe<LedgerBudgetAllocationBoolExp>;
};


/**
 * ประเภทงบประมาณ
 *
 *
 * columns and relationships of "account.budget_type"
 */
export type AccountBudgetTypeAllocationsAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerBudgetAllocationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerBudgetAllocationOrderBy>>;
  where?: Maybe<LedgerBudgetAllocationBoolExp>;
};


/**
 * ประเภทงบประมาณ
 *
 *
 * columns and relationships of "account.budget_type"
 */
export type AccountBudgetTypeBudgetAccountsArgs = {
  distinct_on?: Maybe<Array<AccountBudgetAccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetAccountOrderBy>>;
  where?: Maybe<AccountBudgetAccountBoolExp>;
};


/**
 * ประเภทงบประมาณ
 *
 *
 * columns and relationships of "account.budget_type"
 */
export type AccountBudgetTypeBudgetAccountsAggregateArgs = {
  distinct_on?: Maybe<Array<AccountBudgetAccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetAccountOrderBy>>;
  where?: Maybe<AccountBudgetAccountBoolExp>;
};


/**
 * ประเภทงบประมาณ
 *
 *
 * columns and relationships of "account.budget_type"
 */
export type AccountBudgetTypeBudgetAllocationsArgs = {
  distinct_on?: Maybe<Array<AccountBudgetAllocationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetAllocationOrderBy>>;
  where?: Maybe<AccountBudgetAllocationBoolExp>;
};


/**
 * ประเภทงบประมาณ
 *
 *
 * columns and relationships of "account.budget_type"
 */
export type AccountBudgetTypeBudgetAllocationsAggregateArgs = {
  distinct_on?: Maybe<Array<AccountBudgetAllocationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetAllocationOrderBy>>;
  where?: Maybe<AccountBudgetAllocationBoolExp>;
};


/**
 * ประเภทงบประมาณ
 *
 *
 * columns and relationships of "account.budget_type"
 */
export type AccountBudgetTypeLedgerEntriesArgs = {
  distinct_on?: Maybe<Array<AccountLedgerEntrySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLedgerEntryOrderBy>>;
  where?: Maybe<AccountLedgerEntryBoolExp>;
};


/**
 * ประเภทงบประมาณ
 *
 *
 * columns and relationships of "account.budget_type"
 */
export type AccountBudgetTypeLedgerEntriesAggregateArgs = {
  distinct_on?: Maybe<Array<AccountLedgerEntrySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLedgerEntryOrderBy>>;
  where?: Maybe<AccountLedgerEntryBoolExp>;
};


/**
 * ประเภทงบประมาณ
 *
 *
 * columns and relationships of "account.budget_type"
 */
export type AccountBudgetTypeLedgersArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerOrderBy>>;
  where?: Maybe<LedgerLedgerBoolExp>;
};


/**
 * ประเภทงบประมาณ
 *
 *
 * columns and relationships of "account.budget_type"
 */
export type AccountBudgetTypeLedgersAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerOrderBy>>;
  where?: Maybe<LedgerLedgerBoolExp>;
};

/** aggregated selection of "account.budget_type" */
export type AccountBudgetTypeAggregate = {
  __typename?: 'account_budget_type_aggregate';
  aggregate?: Maybe<AccountBudgetTypeAggregateFields>;
  nodes: Array<AccountBudgetType>;
};

/** aggregate fields of "account.budget_type" */
export type AccountBudgetTypeAggregateFields = {
  __typename?: 'account_budget_type_aggregate_fields';
  avg?: Maybe<AccountBudgetTypeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AccountBudgetTypeMaxFields>;
  min?: Maybe<AccountBudgetTypeMinFields>;
  stddev?: Maybe<AccountBudgetTypeStddevFields>;
  stddev_pop?: Maybe<AccountBudgetTypeStddevPopFields>;
  stddev_samp?: Maybe<AccountBudgetTypeStddevSampFields>;
  sum?: Maybe<AccountBudgetTypeSumFields>;
  var_pop?: Maybe<AccountBudgetTypeVarPopFields>;
  var_samp?: Maybe<AccountBudgetTypeVarSampFields>;
  variance?: Maybe<AccountBudgetTypeVarianceFields>;
};


/** aggregate fields of "account.budget_type" */
export type AccountBudgetTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountBudgetTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account.budget_type" */
export type AccountBudgetTypeAggregateOrderBy = {
  avg?: Maybe<AccountBudgetTypeAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<AccountBudgetTypeMaxOrderBy>;
  min?: Maybe<AccountBudgetTypeMinOrderBy>;
  stddev?: Maybe<AccountBudgetTypeStddevOrderBy>;
  stddev_pop?: Maybe<AccountBudgetTypeStddevPopOrderBy>;
  stddev_samp?: Maybe<AccountBudgetTypeStddevSampOrderBy>;
  sum?: Maybe<AccountBudgetTypeSumOrderBy>;
  var_pop?: Maybe<AccountBudgetTypeVarPopOrderBy>;
  var_samp?: Maybe<AccountBudgetTypeVarSampOrderBy>;
  variance?: Maybe<AccountBudgetTypeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "account.budget_type" */
export type AccountBudgetTypeArrRelInsertInput = {
  data: Array<AccountBudgetTypeInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<AccountBudgetTypeOnConflict>;
};

/** aggregate avg on columns */
export type AccountBudgetTypeAvgFields = {
  __typename?: 'account_budget_type_avg_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "account.budget_type" */
export type AccountBudgetTypeAvgOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "account.budget_type". All fields are combined with a logical 'AND'. */
export type AccountBudgetTypeBoolExp = {
  _and?: Maybe<Array<AccountBudgetTypeBoolExp>>;
  _not?: Maybe<AccountBudgetTypeBoolExp>;
  _or?: Maybe<Array<AccountBudgetTypeBoolExp>>;
  account?: Maybe<AccountAccountBoolExp>;
  account_id?: Maybe<UuidComparisonExp>;
  allocations?: Maybe<LedgerBudgetAllocationBoolExp>;
  budget_accounts?: Maybe<AccountBudgetAccountBoolExp>;
  budget_allocations?: Maybe<AccountBudgetAllocationBoolExp>;
  closed?: Maybe<BooleanComparisonExp>;
  closed_at?: Maybe<TimestamptzComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
  group_id?: Maybe<StringComparisonExp>;
  group_name?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  ledger_entries?: Maybe<AccountLedgerEntryBoolExp>;
  ledgers?: Maybe<LedgerLedgerBoolExp>;
  sequence?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "account.budget_type" */
export enum AccountBudgetTypeConstraint {
  /** unique or primary key constraint */
  BudgetTypePkey = 'budget_type_pkey'
}

/** input type for incrementing numeric columns in table "account.budget_type" */
export type AccountBudgetTypeIncInput = {
  fiscal_year?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "account.budget_type" */
export type AccountBudgetTypeInsertInput = {
  account?: Maybe<AccountAccountObjRelInsertInput>;
  account_id?: Maybe<Scalars['uuid']>;
  allocations?: Maybe<LedgerBudgetAllocationArrRelInsertInput>;
  budget_accounts?: Maybe<AccountBudgetAccountArrRelInsertInput>;
  budget_allocations?: Maybe<AccountBudgetAllocationArrRelInsertInput>;
  closed?: Maybe<Scalars['Boolean']>;
  closed_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['String']>;
  group_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ledger_entries?: Maybe<AccountLedgerEntryArrRelInsertInput>;
  ledgers?: Maybe<LedgerLedgerArrRelInsertInput>;
  sequence?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type AccountBudgetTypeMaxFields = {
  __typename?: 'account_budget_type_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  closed_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['String']>;
  group_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "account.budget_type" */
export type AccountBudgetTypeMaxOrderBy = {
  account_id?: Maybe<OrderBy>;
  closed_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  group_id?: Maybe<OrderBy>;
  group_name?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AccountBudgetTypeMinFields = {
  __typename?: 'account_budget_type_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  closed_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['String']>;
  group_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "account.budget_type" */
export type AccountBudgetTypeMinOrderBy = {
  account_id?: Maybe<OrderBy>;
  closed_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  group_id?: Maybe<OrderBy>;
  group_name?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** response of any mutation on the table "account.budget_type" */
export type AccountBudgetTypeMutationResponse = {
  __typename?: 'account_budget_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccountBudgetType>;
};

/** input type for inserting object relation for remote table "account.budget_type" */
export type AccountBudgetTypeObjRelInsertInput = {
  data: AccountBudgetTypeInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<AccountBudgetTypeOnConflict>;
};

/** on conflict condition type for table "account.budget_type" */
export type AccountBudgetTypeOnConflict = {
  constraint: AccountBudgetTypeConstraint;
  update_columns?: Array<AccountBudgetTypeUpdateColumn>;
  where?: Maybe<AccountBudgetTypeBoolExp>;
};

/** Ordering options when selecting data from "account.budget_type". */
export type AccountBudgetTypeOrderBy = {
  account?: Maybe<AccountAccountOrderBy>;
  account_id?: Maybe<OrderBy>;
  allocations_aggregate?: Maybe<LedgerBudgetAllocationAggregateOrderBy>;
  budget_accounts_aggregate?: Maybe<AccountBudgetAccountAggregateOrderBy>;
  budget_allocations_aggregate?: Maybe<AccountBudgetAllocationAggregateOrderBy>;
  closed?: Maybe<OrderBy>;
  closed_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  group_id?: Maybe<OrderBy>;
  group_name?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ledger_entries_aggregate?: Maybe<AccountLedgerEntryAggregateOrderBy>;
  ledgers_aggregate?: Maybe<LedgerLedgerAggregateOrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** primary key columns input for table: account_budget_type */
export type AccountBudgetTypePkColumnsInput = {
  fiscal_year: Scalars['Int'];
  id: Scalars['String'];
};

/** select columns of table "account.budget_type" */
export enum AccountBudgetTypeSelectColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Closed = 'closed',
  /** column name */
  ClosedAt = 'closed_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  GroupName = 'group_name',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "account.budget_type" */
export type AccountBudgetTypeSetInput = {
  account_id?: Maybe<Scalars['uuid']>;
  closed?: Maybe<Scalars['Boolean']>;
  closed_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['String']>;
  group_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type AccountBudgetTypeStddevFields = {
  __typename?: 'account_budget_type_stddev_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "account.budget_type" */
export type AccountBudgetTypeStddevOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type AccountBudgetTypeStddevPopFields = {
  __typename?: 'account_budget_type_stddev_pop_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "account.budget_type" */
export type AccountBudgetTypeStddevPopOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type AccountBudgetTypeStddevSampFields = {
  __typename?: 'account_budget_type_stddev_samp_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "account.budget_type" */
export type AccountBudgetTypeStddevSampOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type AccountBudgetTypeSumFields = {
  __typename?: 'account_budget_type_sum_fields';
  fiscal_year?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "account.budget_type" */
export type AccountBudgetTypeSumOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "account.budget_type" */
export enum AccountBudgetTypeUpdateColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Closed = 'closed',
  /** column name */
  ClosedAt = 'closed_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  GroupName = 'group_name',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type AccountBudgetTypeVarPopFields = {
  __typename?: 'account_budget_type_var_pop_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "account.budget_type" */
export type AccountBudgetTypeVarPopOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type AccountBudgetTypeVarSampFields = {
  __typename?: 'account_budget_type_var_samp_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "account.budget_type" */
export type AccountBudgetTypeVarSampOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type AccountBudgetTypeVarianceFields = {
  __typename?: 'account_budget_type_variance_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "account.budget_type" */
export type AccountBudgetTypeVarianceOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/**
 * ประเภทการลงบัญชีแยกประเภท
 *
 *
 * columns and relationships of "account.entry_type"
 */
export type AccountEntryType = {
  __typename?: 'account_entry_type';
  /** An array relationship */
  accounts: Array<AccountAccount>;
  /** An aggregate relationship */
  accounts_aggregate: AccountAccountAggregate;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** An array relationship */
  ledger_entries: Array<AccountLedgerEntry>;
  /** An aggregate relationship */
  ledger_entries_aggregate: AccountLedgerEntryAggregate;
};


/**
 * ประเภทการลงบัญชีแยกประเภท
 *
 *
 * columns and relationships of "account.entry_type"
 */
export type AccountEntryTypeAccountsArgs = {
  distinct_on?: Maybe<Array<AccountAccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountOrderBy>>;
  where?: Maybe<AccountAccountBoolExp>;
};


/**
 * ประเภทการลงบัญชีแยกประเภท
 *
 *
 * columns and relationships of "account.entry_type"
 */
export type AccountEntryTypeAccountsAggregateArgs = {
  distinct_on?: Maybe<Array<AccountAccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountOrderBy>>;
  where?: Maybe<AccountAccountBoolExp>;
};


/**
 * ประเภทการลงบัญชีแยกประเภท
 *
 *
 * columns and relationships of "account.entry_type"
 */
export type AccountEntryTypeLedgerEntriesArgs = {
  distinct_on?: Maybe<Array<AccountLedgerEntrySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLedgerEntryOrderBy>>;
  where?: Maybe<AccountLedgerEntryBoolExp>;
};


/**
 * ประเภทการลงบัญชีแยกประเภท
 *
 *
 * columns and relationships of "account.entry_type"
 */
export type AccountEntryTypeLedgerEntriesAggregateArgs = {
  distinct_on?: Maybe<Array<AccountLedgerEntrySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLedgerEntryOrderBy>>;
  where?: Maybe<AccountLedgerEntryBoolExp>;
};

/** aggregated selection of "account.entry_type" */
export type AccountEntryTypeAggregate = {
  __typename?: 'account_entry_type_aggregate';
  aggregate?: Maybe<AccountEntryTypeAggregateFields>;
  nodes: Array<AccountEntryType>;
};

/** aggregate fields of "account.entry_type" */
export type AccountEntryTypeAggregateFields = {
  __typename?: 'account_entry_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AccountEntryTypeMaxFields>;
  min?: Maybe<AccountEntryTypeMinFields>;
};


/** aggregate fields of "account.entry_type" */
export type AccountEntryTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountEntryTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "account.entry_type". All fields are combined with a logical 'AND'. */
export type AccountEntryTypeBoolExp = {
  _and?: Maybe<Array<AccountEntryTypeBoolExp>>;
  _not?: Maybe<AccountEntryTypeBoolExp>;
  _or?: Maybe<Array<AccountEntryTypeBoolExp>>;
  accounts?: Maybe<AccountAccountBoolExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  ledger_entries?: Maybe<AccountLedgerEntryBoolExp>;
};

/** unique or primary key constraints on table "account.entry_type" */
export enum AccountEntryTypeConstraint {
  /** unique or primary key constraint */
  EntryTypePkey = 'entry_type_pkey'
}

export enum AccountEntryTypeEnum {
  /** Credit */
  Credit = 'credit',
  /** Debit */
  Debit = 'debit'
}

/** Boolean expression to compare columns of type "account_entry_type_enum". All fields are combined with logical 'AND'. */
export type AccountEntryTypeEnumComparisonExp = {
  _eq?: Maybe<AccountEntryTypeEnum>;
  _in?: Maybe<Array<AccountEntryTypeEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<AccountEntryTypeEnum>;
  _nin?: Maybe<Array<AccountEntryTypeEnum>>;
};

/** input type for inserting data into table "account.entry_type" */
export type AccountEntryTypeInsertInput = {
  accounts?: Maybe<AccountAccountArrRelInsertInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ledger_entries?: Maybe<AccountLedgerEntryArrRelInsertInput>;
};

/** aggregate max on columns */
export type AccountEntryTypeMaxFields = {
  __typename?: 'account_entry_type_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AccountEntryTypeMinFields = {
  __typename?: 'account_entry_type_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "account.entry_type" */
export type AccountEntryTypeMutationResponse = {
  __typename?: 'account_entry_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccountEntryType>;
};

/** input type for inserting object relation for remote table "account.entry_type" */
export type AccountEntryTypeObjRelInsertInput = {
  data: AccountEntryTypeInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<AccountEntryTypeOnConflict>;
};

/** on conflict condition type for table "account.entry_type" */
export type AccountEntryTypeOnConflict = {
  constraint: AccountEntryTypeConstraint;
  update_columns?: Array<AccountEntryTypeUpdateColumn>;
  where?: Maybe<AccountEntryTypeBoolExp>;
};

/** Ordering options when selecting data from "account.entry_type". */
export type AccountEntryTypeOrderBy = {
  accounts_aggregate?: Maybe<AccountAccountAggregateOrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ledger_entries_aggregate?: Maybe<AccountLedgerEntryAggregateOrderBy>;
};

/** primary key columns input for table: account_entry_type */
export type AccountEntryTypePkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "account.entry_type" */
export enum AccountEntryTypeSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "account.entry_type" */
export type AccountEntryTypeSetInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** update columns of table "account.entry_type" */
export enum AccountEntryTypeUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/**
 * การลงบัญชีแยกประเภท
 *
 *
 * columns and relationships of "account.ledger_entry"
 */
export type AccountLedgerEntry = {
  __typename?: 'account_ledger_entry';
  /** An object relationship */
  account: AccountAccount;
  /** บัญชี (FK) */
  account_id: Scalars['uuid'];
  /** จำนวนเงิน */
  amount: Scalars['numeric'];
  /** ประเภทงบประมาณ (FK) */
  budget_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  budget_type?: Maybe<AccountBudgetType>;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  date: Scalars['date'];
  /** ประเภทการลงบัญชี */
  entry_type: AccountEntryTypeEnum;
  /** An object relationship */
  entry_type_node: AccountEntryType;
  /** ปีงบประมาณ */
  fiscal_year: Scalars['Int'];
  /** เลขที่ขอเบิก ในระบบ GFMIS */
  gfmis_id?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  /** An object relationship */
  organization?: Maybe<Organization>;
  /** หน่วยงาน (FK) */
  organization_id?: Maybe<Scalars['uuid']>;
  reference?: Maybe<Scalars['String']>;
  /** An object relationship */
  transaction?: Maybe<AccountTransaction>;
  /** ใบหัว transaction (FK) */
  transaction_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "account.ledger_entry" */
export type AccountLedgerEntryAggregate = {
  __typename?: 'account_ledger_entry_aggregate';
  aggregate?: Maybe<AccountLedgerEntryAggregateFields>;
  nodes: Array<AccountLedgerEntry>;
};

/** aggregate fields of "account.ledger_entry" */
export type AccountLedgerEntryAggregateFields = {
  __typename?: 'account_ledger_entry_aggregate_fields';
  avg?: Maybe<AccountLedgerEntryAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AccountLedgerEntryMaxFields>;
  min?: Maybe<AccountLedgerEntryMinFields>;
  stddev?: Maybe<AccountLedgerEntryStddevFields>;
  stddev_pop?: Maybe<AccountLedgerEntryStddevPopFields>;
  stddev_samp?: Maybe<AccountLedgerEntryStddevSampFields>;
  sum?: Maybe<AccountLedgerEntrySumFields>;
  var_pop?: Maybe<AccountLedgerEntryVarPopFields>;
  var_samp?: Maybe<AccountLedgerEntryVarSampFields>;
  variance?: Maybe<AccountLedgerEntryVarianceFields>;
};


/** aggregate fields of "account.ledger_entry" */
export type AccountLedgerEntryAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountLedgerEntrySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account.ledger_entry" */
export type AccountLedgerEntryAggregateOrderBy = {
  avg?: Maybe<AccountLedgerEntryAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<AccountLedgerEntryMaxOrderBy>;
  min?: Maybe<AccountLedgerEntryMinOrderBy>;
  stddev?: Maybe<AccountLedgerEntryStddevOrderBy>;
  stddev_pop?: Maybe<AccountLedgerEntryStddevPopOrderBy>;
  stddev_samp?: Maybe<AccountLedgerEntryStddevSampOrderBy>;
  sum?: Maybe<AccountLedgerEntrySumOrderBy>;
  var_pop?: Maybe<AccountLedgerEntryVarPopOrderBy>;
  var_samp?: Maybe<AccountLedgerEntryVarSampOrderBy>;
  variance?: Maybe<AccountLedgerEntryVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "account.ledger_entry" */
export type AccountLedgerEntryArrRelInsertInput = {
  data: Array<AccountLedgerEntryInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<AccountLedgerEntryOnConflict>;
};

/** aggregate avg on columns */
export type AccountLedgerEntryAvgFields = {
  __typename?: 'account_ledger_entry_avg_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "account.ledger_entry" */
export type AccountLedgerEntryAvgOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "account.ledger_entry". All fields are combined with a logical 'AND'. */
export type AccountLedgerEntryBoolExp = {
  _and?: Maybe<Array<AccountLedgerEntryBoolExp>>;
  _not?: Maybe<AccountLedgerEntryBoolExp>;
  _or?: Maybe<Array<AccountLedgerEntryBoolExp>>;
  account?: Maybe<AccountAccountBoolExp>;
  account_id?: Maybe<UuidComparisonExp>;
  amount?: Maybe<NumericComparisonExp>;
  budget_id?: Maybe<StringComparisonExp>;
  budget_type?: Maybe<AccountBudgetTypeBoolExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  date?: Maybe<DateComparisonExp>;
  entry_type?: Maybe<AccountEntryTypeEnumComparisonExp>;
  entry_type_node?: Maybe<AccountEntryTypeBoolExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
  gfmis_id?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  note?: Maybe<StringComparisonExp>;
  organization?: Maybe<OrganizationBoolExp>;
  organization_id?: Maybe<UuidComparisonExp>;
  reference?: Maybe<StringComparisonExp>;
  transaction?: Maybe<AccountTransactionBoolExp>;
  transaction_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "account.ledger_entry" */
export enum AccountLedgerEntryConstraint {
  /** unique or primary key constraint */
  LedgerEntryPkey = 'ledger_entry_pkey'
}

/** input type for incrementing numeric columns in table "account.ledger_entry" */
export type AccountLedgerEntryIncInput = {
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "account.ledger_entry" */
export type AccountLedgerEntryInsertInput = {
  account?: Maybe<AccountAccountObjRelInsertInput>;
  /** บัญชี (FK) */
  account_id?: Maybe<Scalars['uuid']>;
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  /** ประเภทงบประมาณ (FK) */
  budget_id?: Maybe<Scalars['String']>;
  budget_type?: Maybe<AccountBudgetTypeObjRelInsertInput>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['date']>;
  /** ประเภทการลงบัญชี */
  entry_type?: Maybe<AccountEntryTypeEnum>;
  entry_type_node?: Maybe<AccountEntryTypeObjRelInsertInput>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** เลขที่ขอเบิก ในระบบ GFMIS */
  gfmis_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationObjRelInsertInput>;
  /** หน่วยงาน (FK) */
  organization_id?: Maybe<Scalars['uuid']>;
  reference?: Maybe<Scalars['String']>;
  transaction?: Maybe<AccountTransactionObjRelInsertInput>;
  /** ใบหัว transaction (FK) */
  transaction_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AccountLedgerEntryMaxFields = {
  __typename?: 'account_ledger_entry_max_fields';
  /** บัญชี (FK) */
  account_id?: Maybe<Scalars['uuid']>;
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  /** ประเภทงบประมาณ (FK) */
  budget_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['date']>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** เลขที่ขอเบิก ในระบบ GFMIS */
  gfmis_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  /** หน่วยงาน (FK) */
  organization_id?: Maybe<Scalars['uuid']>;
  reference?: Maybe<Scalars['String']>;
  /** ใบหัว transaction (FK) */
  transaction_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "account.ledger_entry" */
export type AccountLedgerEntryMaxOrderBy = {
  /** บัญชี (FK) */
  account_id?: Maybe<OrderBy>;
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** ประเภทงบประมาณ (FK) */
  budget_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<OrderBy>;
  /** เลขที่ขอเบิก ในระบบ GFMIS */
  gfmis_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  /** หน่วยงาน (FK) */
  organization_id?: Maybe<OrderBy>;
  reference?: Maybe<OrderBy>;
  /** ใบหัว transaction (FK) */
  transaction_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AccountLedgerEntryMinFields = {
  __typename?: 'account_ledger_entry_min_fields';
  /** บัญชี (FK) */
  account_id?: Maybe<Scalars['uuid']>;
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  /** ประเภทงบประมาณ (FK) */
  budget_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['date']>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** เลขที่ขอเบิก ในระบบ GFMIS */
  gfmis_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  /** หน่วยงาน (FK) */
  organization_id?: Maybe<Scalars['uuid']>;
  reference?: Maybe<Scalars['String']>;
  /** ใบหัว transaction (FK) */
  transaction_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "account.ledger_entry" */
export type AccountLedgerEntryMinOrderBy = {
  /** บัญชี (FK) */
  account_id?: Maybe<OrderBy>;
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** ประเภทงบประมาณ (FK) */
  budget_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<OrderBy>;
  /** เลขที่ขอเบิก ในระบบ GFMIS */
  gfmis_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  /** หน่วยงาน (FK) */
  organization_id?: Maybe<OrderBy>;
  reference?: Maybe<OrderBy>;
  /** ใบหัว transaction (FK) */
  transaction_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "account.ledger_entry" */
export type AccountLedgerEntryMutationResponse = {
  __typename?: 'account_ledger_entry_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccountLedgerEntry>;
};

/** on conflict condition type for table "account.ledger_entry" */
export type AccountLedgerEntryOnConflict = {
  constraint: AccountLedgerEntryConstraint;
  update_columns?: Array<AccountLedgerEntryUpdateColumn>;
  where?: Maybe<AccountLedgerEntryBoolExp>;
};

/** Ordering options when selecting data from "account.ledger_entry". */
export type AccountLedgerEntryOrderBy = {
  account?: Maybe<AccountAccountOrderBy>;
  account_id?: Maybe<OrderBy>;
  amount?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  budget_type?: Maybe<AccountBudgetTypeOrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  entry_type?: Maybe<OrderBy>;
  entry_type_node?: Maybe<AccountEntryTypeOrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  gfmis_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  organization?: Maybe<OrganizationOrderBy>;
  organization_id?: Maybe<OrderBy>;
  reference?: Maybe<OrderBy>;
  transaction?: Maybe<AccountTransactionOrderBy>;
  transaction_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: account_ledger_entry */
export type AccountLedgerEntryPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "account.ledger_entry" */
export enum AccountLedgerEntrySelectColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Date = 'date',
  /** column name */
  EntryType = 'entry_type',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  GfmisId = 'gfmis_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Reference = 'reference',
  /** column name */
  TransactionId = 'transaction_id'
}

/** input type for updating data in table "account.ledger_entry" */
export type AccountLedgerEntrySetInput = {
  /** บัญชี (FK) */
  account_id?: Maybe<Scalars['uuid']>;
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  /** ประเภทงบประมาณ (FK) */
  budget_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['date']>;
  /** ประเภทการลงบัญชี */
  entry_type?: Maybe<AccountEntryTypeEnum>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** เลขที่ขอเบิก ในระบบ GFMIS */
  gfmis_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  /** หน่วยงาน (FK) */
  organization_id?: Maybe<Scalars['uuid']>;
  reference?: Maybe<Scalars['String']>;
  /** ใบหัว transaction (FK) */
  transaction_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type AccountLedgerEntryStddevFields = {
  __typename?: 'account_ledger_entry_stddev_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "account.ledger_entry" */
export type AccountLedgerEntryStddevOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type AccountLedgerEntryStddevPopFields = {
  __typename?: 'account_ledger_entry_stddev_pop_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "account.ledger_entry" */
export type AccountLedgerEntryStddevPopOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type AccountLedgerEntryStddevSampFields = {
  __typename?: 'account_ledger_entry_stddev_samp_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "account.ledger_entry" */
export type AccountLedgerEntryStddevSampOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type AccountLedgerEntrySumFields = {
  __typename?: 'account_ledger_entry_sum_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "account.ledger_entry" */
export type AccountLedgerEntrySumOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<OrderBy>;
};

/** update columns of table "account.ledger_entry" */
export enum AccountLedgerEntryUpdateColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Date = 'date',
  /** column name */
  EntryType = 'entry_type',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  GfmisId = 'gfmis_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Reference = 'reference',
  /** column name */
  TransactionId = 'transaction_id'
}

/** aggregate var_pop on columns */
export type AccountLedgerEntryVarPopFields = {
  __typename?: 'account_ledger_entry_var_pop_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "account.ledger_entry" */
export type AccountLedgerEntryVarPopOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type AccountLedgerEntryVarSampFields = {
  __typename?: 'account_ledger_entry_var_samp_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "account.ledger_entry" */
export type AccountLedgerEntryVarSampOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type AccountLedgerEntryVarianceFields = {
  __typename?: 'account_ledger_entry_variance_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "account.ledger_entry" */
export type AccountLedgerEntryVarianceOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<OrderBy>;
};

/** columns and relationships of "account.ledger_entry_view" */
export type AccountLedgerEntryView = {
  __typename?: 'account_ledger_entry_view';
  account_id?: Maybe<Scalars['uuid']>;
  account_name?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  entry_type?: Maybe<Scalars['String']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
  status_id?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "account.ledger_entry_view" */
export type AccountLedgerEntryViewAggregate = {
  __typename?: 'account_ledger_entry_view_aggregate';
  aggregate?: Maybe<AccountLedgerEntryViewAggregateFields>;
  nodes: Array<AccountLedgerEntryView>;
};

/** aggregate fields of "account.ledger_entry_view" */
export type AccountLedgerEntryViewAggregateFields = {
  __typename?: 'account_ledger_entry_view_aggregate_fields';
  avg?: Maybe<AccountLedgerEntryViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AccountLedgerEntryViewMaxFields>;
  min?: Maybe<AccountLedgerEntryViewMinFields>;
  stddev?: Maybe<AccountLedgerEntryViewStddevFields>;
  stddev_pop?: Maybe<AccountLedgerEntryViewStddevPopFields>;
  stddev_samp?: Maybe<AccountLedgerEntryViewStddevSampFields>;
  sum?: Maybe<AccountLedgerEntryViewSumFields>;
  var_pop?: Maybe<AccountLedgerEntryViewVarPopFields>;
  var_samp?: Maybe<AccountLedgerEntryViewVarSampFields>;
  variance?: Maybe<AccountLedgerEntryViewVarianceFields>;
};


/** aggregate fields of "account.ledger_entry_view" */
export type AccountLedgerEntryViewAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountLedgerEntryViewSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account.ledger_entry_view" */
export type AccountLedgerEntryViewAggregateOrderBy = {
  avg?: Maybe<AccountLedgerEntryViewAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<AccountLedgerEntryViewMaxOrderBy>;
  min?: Maybe<AccountLedgerEntryViewMinOrderBy>;
  stddev?: Maybe<AccountLedgerEntryViewStddevOrderBy>;
  stddev_pop?: Maybe<AccountLedgerEntryViewStddevPopOrderBy>;
  stddev_samp?: Maybe<AccountLedgerEntryViewStddevSampOrderBy>;
  sum?: Maybe<AccountLedgerEntryViewSumOrderBy>;
  var_pop?: Maybe<AccountLedgerEntryViewVarPopOrderBy>;
  var_samp?: Maybe<AccountLedgerEntryViewVarSampOrderBy>;
  variance?: Maybe<AccountLedgerEntryViewVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "account.ledger_entry_view" */
export type AccountLedgerEntryViewArrRelInsertInput = {
  data: Array<AccountLedgerEntryViewInsertInput>;
};

/** aggregate avg on columns */
export type AccountLedgerEntryViewAvgFields = {
  __typename?: 'account_ledger_entry_view_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "account.ledger_entry_view" */
export type AccountLedgerEntryViewAvgOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "account.ledger_entry_view". All fields are combined with a logical 'AND'. */
export type AccountLedgerEntryViewBoolExp = {
  _and?: Maybe<Array<AccountLedgerEntryViewBoolExp>>;
  _not?: Maybe<AccountLedgerEntryViewBoolExp>;
  _or?: Maybe<Array<AccountLedgerEntryViewBoolExp>>;
  account_id?: Maybe<UuidComparisonExp>;
  account_name?: Maybe<StringComparisonExp>;
  amount?: Maybe<NumericComparisonExp>;
  budget_id?: Maybe<StringComparisonExp>;
  date?: Maybe<DateComparisonExp>;
  entry_type?: Maybe<StringComparisonExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
  organization_id?: Maybe<UuidComparisonExp>;
  status_id?: Maybe<StringComparisonExp>;
  transaction_id?: Maybe<UuidComparisonExp>;
  type_id?: Maybe<StringComparisonExp>;
};

/** input type for inserting data into table "account.ledger_entry_view" */
export type AccountLedgerEntryViewInsertInput = {
  account_id?: Maybe<Scalars['uuid']>;
  account_name?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  entry_type?: Maybe<Scalars['String']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
  status_id?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AccountLedgerEntryViewMaxFields = {
  __typename?: 'account_ledger_entry_view_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  account_name?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  entry_type?: Maybe<Scalars['String']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
  status_id?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "account.ledger_entry_view" */
export type AccountLedgerEntryViewMaxOrderBy = {
  account_id?: Maybe<OrderBy>;
  account_name?: Maybe<OrderBy>;
  amount?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  entry_type?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  status_id?: Maybe<OrderBy>;
  transaction_id?: Maybe<OrderBy>;
  type_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AccountLedgerEntryViewMinFields = {
  __typename?: 'account_ledger_entry_view_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  account_name?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  entry_type?: Maybe<Scalars['String']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
  status_id?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "account.ledger_entry_view" */
export type AccountLedgerEntryViewMinOrderBy = {
  account_id?: Maybe<OrderBy>;
  account_name?: Maybe<OrderBy>;
  amount?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  entry_type?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  status_id?: Maybe<OrderBy>;
  transaction_id?: Maybe<OrderBy>;
  type_id?: Maybe<OrderBy>;
};

/** Ordering options when selecting data from "account.ledger_entry_view". */
export type AccountLedgerEntryViewOrderBy = {
  account_id?: Maybe<OrderBy>;
  account_name?: Maybe<OrderBy>;
  amount?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  entry_type?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  status_id?: Maybe<OrderBy>;
  transaction_id?: Maybe<OrderBy>;
  type_id?: Maybe<OrderBy>;
};

/** select columns of table "account.ledger_entry_view" */
export enum AccountLedgerEntryViewSelectColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AccountName = 'account_name',
  /** column name */
  Amount = 'amount',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  Date = 'date',
  /** column name */
  EntryType = 'entry_type',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  TypeId = 'type_id'
}

/** aggregate stddev on columns */
export type AccountLedgerEntryViewStddevFields = {
  __typename?: 'account_ledger_entry_view_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "account.ledger_entry_view" */
export type AccountLedgerEntryViewStddevOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type AccountLedgerEntryViewStddevPopFields = {
  __typename?: 'account_ledger_entry_view_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "account.ledger_entry_view" */
export type AccountLedgerEntryViewStddevPopOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type AccountLedgerEntryViewStddevSampFields = {
  __typename?: 'account_ledger_entry_view_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "account.ledger_entry_view" */
export type AccountLedgerEntryViewStddevSampOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type AccountLedgerEntryViewSumFields = {
  __typename?: 'account_ledger_entry_view_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "account.ledger_entry_view" */
export type AccountLedgerEntryViewSumOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type AccountLedgerEntryViewVarPopFields = {
  __typename?: 'account_ledger_entry_view_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "account.ledger_entry_view" */
export type AccountLedgerEntryViewVarPopOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type AccountLedgerEntryViewVarSampFields = {
  __typename?: 'account_ledger_entry_view_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "account.ledger_entry_view" */
export type AccountLedgerEntryViewVarSampOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type AccountLedgerEntryViewVarianceFields = {
  __typename?: 'account_ledger_entry_view_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "account.ledger_entry_view" */
export type AccountLedgerEntryViewVarianceOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/**
 * ทะเบียนคุมลูกหนี้เงินยืม
 *
 *
 * columns and relationships of "account.lending"
 */
export type AccountLending = {
  __typename?: 'account_lending';
  amount: Scalars['numeric'];
  borrower_name: Scalars['String'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  /** An object relationship */
  created_by_user: Users;
  date: Scalars['date'];
  description: Scalars['String'];
  due_date: Scalars['date'];
  fiscal_year: Scalars['Int'];
  gfmis_id: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  lending_items: Array<AccountLendingItem>;
  /** An aggregate relationship */
  lending_items_aggregate: AccountLendingItemAggregate;
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  reference: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  /** An object relationship */
  status: AccountLendingStatus;
  status_id: AccountLendingStatusEnum;
  /** An array relationship */
  transactions: Array<AccountTransaction>;
  /** An aggregate relationship */
  transactions_aggregate: AccountTransactionAggregate;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['uuid'];
  /** An object relationship */
  updated_by_user: Users;
};


/**
 * ทะเบียนคุมลูกหนี้เงินยืม
 *
 *
 * columns and relationships of "account.lending"
 */
export type AccountLendingLendingItemsArgs = {
  distinct_on?: Maybe<Array<AccountLendingItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLendingItemOrderBy>>;
  where?: Maybe<AccountLendingItemBoolExp>;
};


/**
 * ทะเบียนคุมลูกหนี้เงินยืม
 *
 *
 * columns and relationships of "account.lending"
 */
export type AccountLendingLendingItemsAggregateArgs = {
  distinct_on?: Maybe<Array<AccountLendingItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLendingItemOrderBy>>;
  where?: Maybe<AccountLendingItemBoolExp>;
};


/**
 * ทะเบียนคุมลูกหนี้เงินยืม
 *
 *
 * columns and relationships of "account.lending"
 */
export type AccountLendingTransactionsArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};


/**
 * ทะเบียนคุมลูกหนี้เงินยืม
 *
 *
 * columns and relationships of "account.lending"
 */
export type AccountLendingTransactionsAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};

/** aggregated selection of "account.lending" */
export type AccountLendingAggregate = {
  __typename?: 'account_lending_aggregate';
  aggregate?: Maybe<AccountLendingAggregateFields>;
  nodes: Array<AccountLending>;
};

/** aggregate fields of "account.lending" */
export type AccountLendingAggregateFields = {
  __typename?: 'account_lending_aggregate_fields';
  avg?: Maybe<AccountLendingAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AccountLendingMaxFields>;
  min?: Maybe<AccountLendingMinFields>;
  stddev?: Maybe<AccountLendingStddevFields>;
  stddev_pop?: Maybe<AccountLendingStddevPopFields>;
  stddev_samp?: Maybe<AccountLendingStddevSampFields>;
  sum?: Maybe<AccountLendingSumFields>;
  var_pop?: Maybe<AccountLendingVarPopFields>;
  var_samp?: Maybe<AccountLendingVarSampFields>;
  variance?: Maybe<AccountLendingVarianceFields>;
};


/** aggregate fields of "account.lending" */
export type AccountLendingAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountLendingSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account.lending" */
export type AccountLendingAggregateOrderBy = {
  avg?: Maybe<AccountLendingAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<AccountLendingMaxOrderBy>;
  min?: Maybe<AccountLendingMinOrderBy>;
  stddev?: Maybe<AccountLendingStddevOrderBy>;
  stddev_pop?: Maybe<AccountLendingStddevPopOrderBy>;
  stddev_samp?: Maybe<AccountLendingStddevSampOrderBy>;
  sum?: Maybe<AccountLendingSumOrderBy>;
  var_pop?: Maybe<AccountLendingVarPopOrderBy>;
  var_samp?: Maybe<AccountLendingVarSampOrderBy>;
  variance?: Maybe<AccountLendingVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "account.lending" */
export type AccountLendingArrRelInsertInput = {
  data: Array<AccountLendingInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<AccountLendingOnConflict>;
};

/** aggregate avg on columns */
export type AccountLendingAvgFields = {
  __typename?: 'account_lending_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "account.lending" */
export type AccountLendingAvgOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "account.lending". All fields are combined with a logical 'AND'. */
export type AccountLendingBoolExp = {
  _and?: Maybe<Array<AccountLendingBoolExp>>;
  _not?: Maybe<AccountLendingBoolExp>;
  _or?: Maybe<Array<AccountLendingBoolExp>>;
  amount?: Maybe<NumericComparisonExp>;
  borrower_name?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  created_by_user?: Maybe<UsersBoolExp>;
  date?: Maybe<DateComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  due_date?: Maybe<DateComparisonExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
  gfmis_id?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  lending_items?: Maybe<AccountLendingItemBoolExp>;
  organization?: Maybe<OrganizationBoolExp>;
  organization_id?: Maybe<UuidComparisonExp>;
  reference?: Maybe<StringComparisonExp>;
  remark?: Maybe<StringComparisonExp>;
  status?: Maybe<AccountLendingStatusBoolExp>;
  status_id?: Maybe<AccountLendingStatusEnumComparisonExp>;
  transactions?: Maybe<AccountTransactionBoolExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<UuidComparisonExp>;
  updated_by_user?: Maybe<UsersBoolExp>;
};

/** unique or primary key constraints on table "account.lending" */
export enum AccountLendingConstraint {
  /** unique or primary key constraint */
  LendingPkey = 'lending_pkey',
  /** unique or primary key constraint */
  LendingReferenceKey = 'lending_reference_key'
}

/** input type for incrementing numeric columns in table "account.lending" */
export type AccountLendingIncInput = {
  amount?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "account.lending" */
export type AccountLendingInsertInput = {
  amount?: Maybe<Scalars['numeric']>;
  borrower_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  created_by_user?: Maybe<UsersObjRelInsertInput>;
  date?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['date']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  gfmis_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lending_items?: Maybe<AccountLendingItemArrRelInsertInput>;
  organization?: Maybe<OrganizationObjRelInsertInput>;
  organization_id?: Maybe<Scalars['uuid']>;
  reference?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  status?: Maybe<AccountLendingStatusObjRelInsertInput>;
  status_id?: Maybe<AccountLendingStatusEnum>;
  transactions?: Maybe<AccountTransactionArrRelInsertInput>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
  updated_by_user?: Maybe<UsersObjRelInsertInput>;
};

/**
 * รายการค่าใช้จ่าย
 *
 *
 * columns and relationships of "account.lending_item"
 */
export type AccountLendingItem = {
  __typename?: 'account_lending_item';
  amount: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  date: Scalars['date'];
  id: Scalars['uuid'];
  /** An object relationship */
  lending: AccountLending;
  lending_id: Scalars['uuid'];
  name: Scalars['String'];
  note: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['uuid'];
};

/** aggregated selection of "account.lending_item" */
export type AccountLendingItemAggregate = {
  __typename?: 'account_lending_item_aggregate';
  aggregate?: Maybe<AccountLendingItemAggregateFields>;
  nodes: Array<AccountLendingItem>;
};

/** aggregate fields of "account.lending_item" */
export type AccountLendingItemAggregateFields = {
  __typename?: 'account_lending_item_aggregate_fields';
  avg?: Maybe<AccountLendingItemAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AccountLendingItemMaxFields>;
  min?: Maybe<AccountLendingItemMinFields>;
  stddev?: Maybe<AccountLendingItemStddevFields>;
  stddev_pop?: Maybe<AccountLendingItemStddevPopFields>;
  stddev_samp?: Maybe<AccountLendingItemStddevSampFields>;
  sum?: Maybe<AccountLendingItemSumFields>;
  var_pop?: Maybe<AccountLendingItemVarPopFields>;
  var_samp?: Maybe<AccountLendingItemVarSampFields>;
  variance?: Maybe<AccountLendingItemVarianceFields>;
};


/** aggregate fields of "account.lending_item" */
export type AccountLendingItemAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountLendingItemSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account.lending_item" */
export type AccountLendingItemAggregateOrderBy = {
  avg?: Maybe<AccountLendingItemAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<AccountLendingItemMaxOrderBy>;
  min?: Maybe<AccountLendingItemMinOrderBy>;
  stddev?: Maybe<AccountLendingItemStddevOrderBy>;
  stddev_pop?: Maybe<AccountLendingItemStddevPopOrderBy>;
  stddev_samp?: Maybe<AccountLendingItemStddevSampOrderBy>;
  sum?: Maybe<AccountLendingItemSumOrderBy>;
  var_pop?: Maybe<AccountLendingItemVarPopOrderBy>;
  var_samp?: Maybe<AccountLendingItemVarSampOrderBy>;
  variance?: Maybe<AccountLendingItemVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "account.lending_item" */
export type AccountLendingItemArrRelInsertInput = {
  data: Array<AccountLendingItemInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<AccountLendingItemOnConflict>;
};

/** aggregate avg on columns */
export type AccountLendingItemAvgFields = {
  __typename?: 'account_lending_item_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "account.lending_item" */
export type AccountLendingItemAvgOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "account.lending_item". All fields are combined with a logical 'AND'. */
export type AccountLendingItemBoolExp = {
  _and?: Maybe<Array<AccountLendingItemBoolExp>>;
  _not?: Maybe<AccountLendingItemBoolExp>;
  _or?: Maybe<Array<AccountLendingItemBoolExp>>;
  amount?: Maybe<NumericComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  date?: Maybe<DateComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  lending?: Maybe<AccountLendingBoolExp>;
  lending_id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  note?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "account.lending_item" */
export enum AccountLendingItemConstraint {
  /** unique or primary key constraint */
  LendingItemPkey = 'lending_item_pkey'
}

/** input type for incrementing numeric columns in table "account.lending_item" */
export type AccountLendingItemIncInput = {
  amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "account.lending_item" */
export type AccountLendingItemInsertInput = {
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  lending?: Maybe<AccountLendingObjRelInsertInput>;
  lending_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AccountLendingItemMaxFields = {
  __typename?: 'account_lending_item_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  lending_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "account.lending_item" */
export type AccountLendingItemMaxOrderBy = {
  amount?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lending_id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AccountLendingItemMinFields = {
  __typename?: 'account_lending_item_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  lending_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "account.lending_item" */
export type AccountLendingItemMinOrderBy = {
  amount?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lending_id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "account.lending_item" */
export type AccountLendingItemMutationResponse = {
  __typename?: 'account_lending_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccountLendingItem>;
};

/** on conflict condition type for table "account.lending_item" */
export type AccountLendingItemOnConflict = {
  constraint: AccountLendingItemConstraint;
  update_columns?: Array<AccountLendingItemUpdateColumn>;
  where?: Maybe<AccountLendingItemBoolExp>;
};

/** Ordering options when selecting data from "account.lending_item". */
export type AccountLendingItemOrderBy = {
  amount?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lending?: Maybe<AccountLendingOrderBy>;
  lending_id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** primary key columns input for table: account_lending_item */
export type AccountLendingItemPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "account.lending_item" */
export enum AccountLendingItemSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  LendingId = 'lending_id',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "account.lending_item" */
export type AccountLendingItemSetInput = {
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  lending_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type AccountLendingItemStddevFields = {
  __typename?: 'account_lending_item_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "account.lending_item" */
export type AccountLendingItemStddevOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type AccountLendingItemStddevPopFields = {
  __typename?: 'account_lending_item_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "account.lending_item" */
export type AccountLendingItemStddevPopOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type AccountLendingItemStddevSampFields = {
  __typename?: 'account_lending_item_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "account.lending_item" */
export type AccountLendingItemStddevSampOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type AccountLendingItemSumFields = {
  __typename?: 'account_lending_item_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "account.lending_item" */
export type AccountLendingItemSumOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** update columns of table "account.lending_item" */
export enum AccountLendingItemUpdateColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  LendingId = 'lending_id',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type AccountLendingItemVarPopFields = {
  __typename?: 'account_lending_item_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "account.lending_item" */
export type AccountLendingItemVarPopOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type AccountLendingItemVarSampFields = {
  __typename?: 'account_lending_item_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "account.lending_item" */
export type AccountLendingItemVarSampOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type AccountLendingItemVarianceFields = {
  __typename?: 'account_lending_item_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "account.lending_item" */
export type AccountLendingItemVarianceOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate max on columns */
export type AccountLendingMaxFields = {
  __typename?: 'account_lending_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  borrower_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['date']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  gfmis_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  reference?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "account.lending" */
export type AccountLendingMaxOrderBy = {
  amount?: Maybe<OrderBy>;
  borrower_name?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  due_date?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  gfmis_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  reference?: Maybe<OrderBy>;
  remark?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AccountLendingMinFields = {
  __typename?: 'account_lending_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  borrower_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['date']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  gfmis_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  reference?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "account.lending" */
export type AccountLendingMinOrderBy = {
  amount?: Maybe<OrderBy>;
  borrower_name?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  due_date?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  gfmis_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  reference?: Maybe<OrderBy>;
  remark?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "account.lending" */
export type AccountLendingMutationResponse = {
  __typename?: 'account_lending_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccountLending>;
};

/** input type for inserting object relation for remote table "account.lending" */
export type AccountLendingObjRelInsertInput = {
  data: AccountLendingInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<AccountLendingOnConflict>;
};

/** on conflict condition type for table "account.lending" */
export type AccountLendingOnConflict = {
  constraint: AccountLendingConstraint;
  update_columns?: Array<AccountLendingUpdateColumn>;
  where?: Maybe<AccountLendingBoolExp>;
};

/** Ordering options when selecting data from "account.lending". */
export type AccountLendingOrderBy = {
  amount?: Maybe<OrderBy>;
  borrower_name?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  created_by_user?: Maybe<UsersOrderBy>;
  date?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  due_date?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  gfmis_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lending_items_aggregate?: Maybe<AccountLendingItemAggregateOrderBy>;
  organization?: Maybe<OrganizationOrderBy>;
  organization_id?: Maybe<OrderBy>;
  reference?: Maybe<OrderBy>;
  remark?: Maybe<OrderBy>;
  status?: Maybe<AccountLendingStatusOrderBy>;
  status_id?: Maybe<OrderBy>;
  transactions_aggregate?: Maybe<AccountTransactionAggregateOrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
  updated_by_user?: Maybe<UsersOrderBy>;
};

/** primary key columns input for table: account_lending */
export type AccountLendingPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "account.lending" */
export enum AccountLendingSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  BorrowerName = 'borrower_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  GfmisId = 'gfmis_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Reference = 'reference',
  /** column name */
  Remark = 'remark',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "account.lending" */
export type AccountLendingSetInput = {
  amount?: Maybe<Scalars['numeric']>;
  borrower_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['date']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  gfmis_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  reference?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  status_id?: Maybe<AccountLendingStatusEnum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/**
 * สถานะทะเบียนคุมลูกหนี้เงินยืม
 *
 *
 * columns and relationships of "account.lending_status"
 */
export type AccountLendingStatus = {
  __typename?: 'account_lending_status';
  description: Scalars['String'];
  id: Scalars['String'];
  /** An array relationship */
  lendings: Array<AccountLending>;
  /** An aggregate relationship */
  lendings_aggregate: AccountLendingAggregate;
};


/**
 * สถานะทะเบียนคุมลูกหนี้เงินยืม
 *
 *
 * columns and relationships of "account.lending_status"
 */
export type AccountLendingStatusLendingsArgs = {
  distinct_on?: Maybe<Array<AccountLendingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLendingOrderBy>>;
  where?: Maybe<AccountLendingBoolExp>;
};


/**
 * สถานะทะเบียนคุมลูกหนี้เงินยืม
 *
 *
 * columns and relationships of "account.lending_status"
 */
export type AccountLendingStatusLendingsAggregateArgs = {
  distinct_on?: Maybe<Array<AccountLendingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLendingOrderBy>>;
  where?: Maybe<AccountLendingBoolExp>;
};

/** aggregated selection of "account.lending_status" */
export type AccountLendingStatusAggregate = {
  __typename?: 'account_lending_status_aggregate';
  aggregate?: Maybe<AccountLendingStatusAggregateFields>;
  nodes: Array<AccountLendingStatus>;
};

/** aggregate fields of "account.lending_status" */
export type AccountLendingStatusAggregateFields = {
  __typename?: 'account_lending_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AccountLendingStatusMaxFields>;
  min?: Maybe<AccountLendingStatusMinFields>;
};


/** aggregate fields of "account.lending_status" */
export type AccountLendingStatusAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountLendingStatusSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "account.lending_status". All fields are combined with a logical 'AND'. */
export type AccountLendingStatusBoolExp = {
  _and?: Maybe<Array<AccountLendingStatusBoolExp>>;
  _not?: Maybe<AccountLendingStatusBoolExp>;
  _or?: Maybe<Array<AccountLendingStatusBoolExp>>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  lendings?: Maybe<AccountLendingBoolExp>;
};

/** unique or primary key constraints on table "account.lending_status" */
export enum AccountLendingStatusConstraint {
  /** unique or primary key constraint */
  LendingStatusPkey = 'lending_status_pkey'
}

export enum AccountLendingStatusEnum {
  /** ยกเลิก */
  Cancelled = 'cancelled',
  /** เสร็จสิ้น */
  Done = 'done',
  /** ใหม่ */
  New = 'new'
}

/** Boolean expression to compare columns of type "account_lending_status_enum". All fields are combined with logical 'AND'. */
export type AccountLendingStatusEnumComparisonExp = {
  _eq?: Maybe<AccountLendingStatusEnum>;
  _in?: Maybe<Array<AccountLendingStatusEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<AccountLendingStatusEnum>;
  _nin?: Maybe<Array<AccountLendingStatusEnum>>;
};

/** input type for inserting data into table "account.lending_status" */
export type AccountLendingStatusInsertInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lendings?: Maybe<AccountLendingArrRelInsertInput>;
};

/** aggregate max on columns */
export type AccountLendingStatusMaxFields = {
  __typename?: 'account_lending_status_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AccountLendingStatusMinFields = {
  __typename?: 'account_lending_status_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "account.lending_status" */
export type AccountLendingStatusMutationResponse = {
  __typename?: 'account_lending_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccountLendingStatus>;
};

/** input type for inserting object relation for remote table "account.lending_status" */
export type AccountLendingStatusObjRelInsertInput = {
  data: AccountLendingStatusInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<AccountLendingStatusOnConflict>;
};

/** on conflict condition type for table "account.lending_status" */
export type AccountLendingStatusOnConflict = {
  constraint: AccountLendingStatusConstraint;
  update_columns?: Array<AccountLendingStatusUpdateColumn>;
  where?: Maybe<AccountLendingStatusBoolExp>;
};

/** Ordering options when selecting data from "account.lending_status". */
export type AccountLendingStatusOrderBy = {
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lendings_aggregate?: Maybe<AccountLendingAggregateOrderBy>;
};

/** primary key columns input for table: account_lending_status */
export type AccountLendingStatusPkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "account.lending_status" */
export enum AccountLendingStatusSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "account.lending_status" */
export type AccountLendingStatusSetInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** update columns of table "account.lending_status" */
export enum AccountLendingStatusUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** aggregate stddev on columns */
export type AccountLendingStddevFields = {
  __typename?: 'account_lending_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "account.lending" */
export type AccountLendingStddevOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type AccountLendingStddevPopFields = {
  __typename?: 'account_lending_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "account.lending" */
export type AccountLendingStddevPopOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type AccountLendingStddevSampFields = {
  __typename?: 'account_lending_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "account.lending" */
export type AccountLendingStddevSampOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type AccountLendingSumFields = {
  __typename?: 'account_lending_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "account.lending" */
export type AccountLendingSumOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** update columns of table "account.lending" */
export enum AccountLendingUpdateColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  BorrowerName = 'borrower_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  GfmisId = 'gfmis_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Reference = 'reference',
  /** column name */
  Remark = 'remark',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type AccountLendingVarPopFields = {
  __typename?: 'account_lending_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "account.lending" */
export type AccountLendingVarPopOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type AccountLendingVarSampFields = {
  __typename?: 'account_lending_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "account.lending" */
export type AccountLendingVarSampOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type AccountLendingVarianceFields = {
  __typename?: 'account_lending_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "account.lending" */
export type AccountLendingVarianceOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/**
 * ใบหัว ledger_entry
 *
 *
 * columns and relationships of "account.transaction"
 */
export type AccountTransaction = {
  __typename?: 'account_transaction';
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  approved_by_user?: Maybe<Users>;
  cancelled_at?: Maybe<Scalars['timestamptz']>;
  cancelled_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  cancelled_by_user?: Maybe<Users>;
  change_requested_at?: Maybe<Scalars['timestamptz']>;
  change_requested_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  change_requested_by_user?: Maybe<Users>;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  created_by_user?: Maybe<Users>;
  date: Scalars['date'];
  description?: Maybe<Scalars['String']>;
  fiscal_year: Scalars['Int'];
  gfmis_id: Scalars['String'];
  id: Scalars['uuid'];
  is_allocation: Scalars['Boolean'];
  /** An array relationship */
  ledger_entries: Array<AccountLedgerEntry>;
  /** An aggregate relationship */
  ledger_entries_aggregate: AccountLedgerEntryAggregate;
  /** An object relationship */
  lending?: Maybe<AccountLending>;
  lending_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  organization?: Maybe<Organization>;
  organization_id?: Maybe<Scalars['uuid']>;
  reference?: Maybe<Scalars['String']>;
  rejected_at?: Maybe<Scalars['timestamptz']>;
  rejected_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  rejected_by_user?: Maybe<Users>;
  status_id: AccountTransactionStatusEnum;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  submitted_by?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  transaction_logs: Array<AccountTransactionLog>;
  /** An aggregate relationship */
  transaction_logs_aggregate: AccountTransactionLogAggregate;
  /** An object relationship */
  transaction_status: AccountTransactionStatus;
  /** An object relationship */
  transaction_type: AccountTransactionType;
  type_id: AccountTransactionTypeEnum;
  updated_by?: Maybe<Scalars['uuid']>;
};


/**
 * ใบหัว ledger_entry
 *
 *
 * columns and relationships of "account.transaction"
 */
export type AccountTransactionLedgerEntriesArgs = {
  distinct_on?: Maybe<Array<AccountLedgerEntrySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLedgerEntryOrderBy>>;
  where?: Maybe<AccountLedgerEntryBoolExp>;
};


/**
 * ใบหัว ledger_entry
 *
 *
 * columns and relationships of "account.transaction"
 */
export type AccountTransactionLedgerEntriesAggregateArgs = {
  distinct_on?: Maybe<Array<AccountLedgerEntrySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLedgerEntryOrderBy>>;
  where?: Maybe<AccountLedgerEntryBoolExp>;
};


/**
 * ใบหัว ledger_entry
 *
 *
 * columns and relationships of "account.transaction"
 */
export type AccountTransactionTransactionLogsArgs = {
  distinct_on?: Maybe<Array<AccountTransactionLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionLogOrderBy>>;
  where?: Maybe<AccountTransactionLogBoolExp>;
};


/**
 * ใบหัว ledger_entry
 *
 *
 * columns and relationships of "account.transaction"
 */
export type AccountTransactionTransactionLogsAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTransactionLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionLogOrderBy>>;
  where?: Maybe<AccountTransactionLogBoolExp>;
};

/** aggregated selection of "account.transaction" */
export type AccountTransactionAggregate = {
  __typename?: 'account_transaction_aggregate';
  aggregate?: Maybe<AccountTransactionAggregateFields>;
  nodes: Array<AccountTransaction>;
};

/** aggregate fields of "account.transaction" */
export type AccountTransactionAggregateFields = {
  __typename?: 'account_transaction_aggregate_fields';
  avg?: Maybe<AccountTransactionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AccountTransactionMaxFields>;
  min?: Maybe<AccountTransactionMinFields>;
  stddev?: Maybe<AccountTransactionStddevFields>;
  stddev_pop?: Maybe<AccountTransactionStddevPopFields>;
  stddev_samp?: Maybe<AccountTransactionStddevSampFields>;
  sum?: Maybe<AccountTransactionSumFields>;
  var_pop?: Maybe<AccountTransactionVarPopFields>;
  var_samp?: Maybe<AccountTransactionVarSampFields>;
  variance?: Maybe<AccountTransactionVarianceFields>;
};


/** aggregate fields of "account.transaction" */
export type AccountTransactionAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountTransactionSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account.transaction" */
export type AccountTransactionAggregateOrderBy = {
  avg?: Maybe<AccountTransactionAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<AccountTransactionMaxOrderBy>;
  min?: Maybe<AccountTransactionMinOrderBy>;
  stddev?: Maybe<AccountTransactionStddevOrderBy>;
  stddev_pop?: Maybe<AccountTransactionStddevPopOrderBy>;
  stddev_samp?: Maybe<AccountTransactionStddevSampOrderBy>;
  sum?: Maybe<AccountTransactionSumOrderBy>;
  var_pop?: Maybe<AccountTransactionVarPopOrderBy>;
  var_samp?: Maybe<AccountTransactionVarSampOrderBy>;
  variance?: Maybe<AccountTransactionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "account.transaction" */
export type AccountTransactionArrRelInsertInput = {
  data: Array<AccountTransactionInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<AccountTransactionOnConflict>;
};

/** aggregate avg on columns */
export type AccountTransactionAvgFields = {
  __typename?: 'account_transaction_avg_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "account.transaction" */
export type AccountTransactionAvgOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "account.transaction". All fields are combined with a logical 'AND'. */
export type AccountTransactionBoolExp = {
  _and?: Maybe<Array<AccountTransactionBoolExp>>;
  _not?: Maybe<AccountTransactionBoolExp>;
  _or?: Maybe<Array<AccountTransactionBoolExp>>;
  approved_at?: Maybe<TimestamptzComparisonExp>;
  approved_by?: Maybe<UuidComparisonExp>;
  approved_by_user?: Maybe<UsersBoolExp>;
  cancelled_at?: Maybe<TimestamptzComparisonExp>;
  cancelled_by?: Maybe<UuidComparisonExp>;
  cancelled_by_user?: Maybe<UsersBoolExp>;
  change_requested_at?: Maybe<TimestamptzComparisonExp>;
  change_requested_by?: Maybe<UuidComparisonExp>;
  change_requested_by_user?: Maybe<UsersBoolExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  created_by_user?: Maybe<UsersBoolExp>;
  date?: Maybe<DateComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
  gfmis_id?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_allocation?: Maybe<BooleanComparisonExp>;
  ledger_entries?: Maybe<AccountLedgerEntryBoolExp>;
  lending?: Maybe<AccountLendingBoolExp>;
  lending_id?: Maybe<UuidComparisonExp>;
  organization?: Maybe<OrganizationBoolExp>;
  organization_id?: Maybe<UuidComparisonExp>;
  reference?: Maybe<StringComparisonExp>;
  rejected_at?: Maybe<TimestamptzComparisonExp>;
  rejected_by?: Maybe<UuidComparisonExp>;
  rejected_by_user?: Maybe<UsersBoolExp>;
  status_id?: Maybe<AccountTransactionStatusEnumComparisonExp>;
  submitted_at?: Maybe<TimestamptzComparisonExp>;
  submitted_by?: Maybe<UuidComparisonExp>;
  transaction_logs?: Maybe<AccountTransactionLogBoolExp>;
  transaction_status?: Maybe<AccountTransactionStatusBoolExp>;
  transaction_type?: Maybe<AccountTransactionTypeBoolExp>;
  type_id?: Maybe<AccountTransactionTypeEnumComparisonExp>;
  updated_by?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "account.transaction" */
export enum AccountTransactionConstraint {
  /** unique or primary key constraint */
  TransactionPkey = 'transaction_pkey'
}

/** input type for incrementing numeric columns in table "account.transaction" */
export type AccountTransactionIncInput = {
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "account.transaction" */
export type AccountTransactionInsertInput = {
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['uuid']>;
  approved_by_user?: Maybe<UsersObjRelInsertInput>;
  cancelled_at?: Maybe<Scalars['timestamptz']>;
  cancelled_by?: Maybe<Scalars['uuid']>;
  cancelled_by_user?: Maybe<UsersObjRelInsertInput>;
  change_requested_at?: Maybe<Scalars['timestamptz']>;
  change_requested_by?: Maybe<Scalars['uuid']>;
  change_requested_by_user?: Maybe<UsersObjRelInsertInput>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  created_by_user?: Maybe<UsersObjRelInsertInput>;
  date?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  gfmis_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_allocation?: Maybe<Scalars['Boolean']>;
  ledger_entries?: Maybe<AccountLedgerEntryArrRelInsertInput>;
  lending?: Maybe<AccountLendingObjRelInsertInput>;
  lending_id?: Maybe<Scalars['uuid']>;
  organization?: Maybe<OrganizationObjRelInsertInput>;
  organization_id?: Maybe<Scalars['uuid']>;
  reference?: Maybe<Scalars['String']>;
  rejected_at?: Maybe<Scalars['timestamptz']>;
  rejected_by?: Maybe<Scalars['uuid']>;
  rejected_by_user?: Maybe<UsersObjRelInsertInput>;
  status_id?: Maybe<AccountTransactionStatusEnum>;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  submitted_by?: Maybe<Scalars['uuid']>;
  transaction_logs?: Maybe<AccountTransactionLogArrRelInsertInput>;
  transaction_status?: Maybe<AccountTransactionStatusObjRelInsertInput>;
  transaction_type?: Maybe<AccountTransactionTypeObjRelInsertInput>;
  type_id?: Maybe<AccountTransactionTypeEnum>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/**
 * ประวัติที่เกิดขึ้นกับ Transaction
 *
 *
 * columns and relationships of "account.transaction_log"
 */
export type AccountTransactionLog = {
  __typename?: 'account_transaction_log';
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  /** An object relationship */
  created_by_user: Users;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  new_status?: Maybe<AccountTransactionStatusEnum>;
  old_status?: Maybe<AccountTransactionStatusEnum>;
  /** An object relationship */
  transaction: AccountTransaction;
  transaction_id: Scalars['uuid'];
  /** An object relationship */
  transaction_status_new?: Maybe<AccountTransactionStatus>;
  /** An object relationship */
  transaction_status_old?: Maybe<AccountTransactionStatus>;
};

/** aggregated selection of "account.transaction_log" */
export type AccountTransactionLogAggregate = {
  __typename?: 'account_transaction_log_aggregate';
  aggregate?: Maybe<AccountTransactionLogAggregateFields>;
  nodes: Array<AccountTransactionLog>;
};

/** aggregate fields of "account.transaction_log" */
export type AccountTransactionLogAggregateFields = {
  __typename?: 'account_transaction_log_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AccountTransactionLogMaxFields>;
  min?: Maybe<AccountTransactionLogMinFields>;
};


/** aggregate fields of "account.transaction_log" */
export type AccountTransactionLogAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountTransactionLogSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account.transaction_log" */
export type AccountTransactionLogAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<AccountTransactionLogMaxOrderBy>;
  min?: Maybe<AccountTransactionLogMinOrderBy>;
};

/** input type for inserting array relation for remote table "account.transaction_log" */
export type AccountTransactionLogArrRelInsertInput = {
  data: Array<AccountTransactionLogInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<AccountTransactionLogOnConflict>;
};

/** Boolean expression to filter rows from the table "account.transaction_log". All fields are combined with a logical 'AND'. */
export type AccountTransactionLogBoolExp = {
  _and?: Maybe<Array<AccountTransactionLogBoolExp>>;
  _not?: Maybe<AccountTransactionLogBoolExp>;
  _or?: Maybe<Array<AccountTransactionLogBoolExp>>;
  comment?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  created_by_user?: Maybe<UsersBoolExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  new_status?: Maybe<AccountTransactionStatusEnumComparisonExp>;
  old_status?: Maybe<AccountTransactionStatusEnumComparisonExp>;
  transaction?: Maybe<AccountTransactionBoolExp>;
  transaction_id?: Maybe<UuidComparisonExp>;
  transaction_status_new?: Maybe<AccountTransactionStatusBoolExp>;
  transaction_status_old?: Maybe<AccountTransactionStatusBoolExp>;
};

/** unique or primary key constraints on table "account.transaction_log" */
export enum AccountTransactionLogConstraint {
  /** unique or primary key constraint */
  TransactionLogPkey = 'transaction_log_pkey'
}

/** input type for inserting data into table "account.transaction_log" */
export type AccountTransactionLogInsertInput = {
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  created_by_user?: Maybe<UsersObjRelInsertInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  new_status?: Maybe<AccountTransactionStatusEnum>;
  old_status?: Maybe<AccountTransactionStatusEnum>;
  transaction?: Maybe<AccountTransactionObjRelInsertInput>;
  transaction_id?: Maybe<Scalars['uuid']>;
  transaction_status_new?: Maybe<AccountTransactionStatusObjRelInsertInput>;
  transaction_status_old?: Maybe<AccountTransactionStatusObjRelInsertInput>;
};

/** aggregate max on columns */
export type AccountTransactionLogMaxFields = {
  __typename?: 'account_transaction_log_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  transaction_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "account.transaction_log" */
export type AccountTransactionLogMaxOrderBy = {
  comment?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  transaction_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AccountTransactionLogMinFields = {
  __typename?: 'account_transaction_log_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  transaction_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "account.transaction_log" */
export type AccountTransactionLogMinOrderBy = {
  comment?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  transaction_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "account.transaction_log" */
export type AccountTransactionLogMutationResponse = {
  __typename?: 'account_transaction_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccountTransactionLog>;
};

/** on conflict condition type for table "account.transaction_log" */
export type AccountTransactionLogOnConflict = {
  constraint: AccountTransactionLogConstraint;
  update_columns?: Array<AccountTransactionLogUpdateColumn>;
  where?: Maybe<AccountTransactionLogBoolExp>;
};

/** Ordering options when selecting data from "account.transaction_log". */
export type AccountTransactionLogOrderBy = {
  comment?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  created_by_user?: Maybe<UsersOrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  new_status?: Maybe<OrderBy>;
  old_status?: Maybe<OrderBy>;
  transaction?: Maybe<AccountTransactionOrderBy>;
  transaction_id?: Maybe<OrderBy>;
  transaction_status_new?: Maybe<AccountTransactionStatusOrderBy>;
  transaction_status_old?: Maybe<AccountTransactionStatusOrderBy>;
};

/** primary key columns input for table: account_transaction_log */
export type AccountTransactionLogPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "account.transaction_log" */
export enum AccountTransactionLogSelectColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  NewStatus = 'new_status',
  /** column name */
  OldStatus = 'old_status',
  /** column name */
  TransactionId = 'transaction_id'
}

/** input type for updating data in table "account.transaction_log" */
export type AccountTransactionLogSetInput = {
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  new_status?: Maybe<AccountTransactionStatusEnum>;
  old_status?: Maybe<AccountTransactionStatusEnum>;
  transaction_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "account.transaction_log" */
export enum AccountTransactionLogUpdateColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  NewStatus = 'new_status',
  /** column name */
  OldStatus = 'old_status',
  /** column name */
  TransactionId = 'transaction_id'
}

/** aggregate max on columns */
export type AccountTransactionMaxFields = {
  __typename?: 'account_transaction_max_fields';
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['uuid']>;
  cancelled_at?: Maybe<Scalars['timestamptz']>;
  cancelled_by?: Maybe<Scalars['uuid']>;
  change_requested_at?: Maybe<Scalars['timestamptz']>;
  change_requested_by?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  gfmis_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lending_id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  reference?: Maybe<Scalars['String']>;
  rejected_at?: Maybe<Scalars['timestamptz']>;
  rejected_by?: Maybe<Scalars['uuid']>;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  submitted_by?: Maybe<Scalars['uuid']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "account.transaction" */
export type AccountTransactionMaxOrderBy = {
  approved_at?: Maybe<OrderBy>;
  approved_by?: Maybe<OrderBy>;
  cancelled_at?: Maybe<OrderBy>;
  cancelled_by?: Maybe<OrderBy>;
  change_requested_at?: Maybe<OrderBy>;
  change_requested_by?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  gfmis_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lending_id?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  reference?: Maybe<OrderBy>;
  rejected_at?: Maybe<OrderBy>;
  rejected_by?: Maybe<OrderBy>;
  submitted_at?: Maybe<OrderBy>;
  submitted_by?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AccountTransactionMinFields = {
  __typename?: 'account_transaction_min_fields';
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['uuid']>;
  cancelled_at?: Maybe<Scalars['timestamptz']>;
  cancelled_by?: Maybe<Scalars['uuid']>;
  change_requested_at?: Maybe<Scalars['timestamptz']>;
  change_requested_by?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  gfmis_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lending_id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  reference?: Maybe<Scalars['String']>;
  rejected_at?: Maybe<Scalars['timestamptz']>;
  rejected_by?: Maybe<Scalars['uuid']>;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  submitted_by?: Maybe<Scalars['uuid']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "account.transaction" */
export type AccountTransactionMinOrderBy = {
  approved_at?: Maybe<OrderBy>;
  approved_by?: Maybe<OrderBy>;
  cancelled_at?: Maybe<OrderBy>;
  cancelled_by?: Maybe<OrderBy>;
  change_requested_at?: Maybe<OrderBy>;
  change_requested_by?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  gfmis_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lending_id?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  reference?: Maybe<OrderBy>;
  rejected_at?: Maybe<OrderBy>;
  rejected_by?: Maybe<OrderBy>;
  submitted_at?: Maybe<OrderBy>;
  submitted_by?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "account.transaction" */
export type AccountTransactionMutationResponse = {
  __typename?: 'account_transaction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccountTransaction>;
};

/** input type for inserting object relation for remote table "account.transaction" */
export type AccountTransactionObjRelInsertInput = {
  data: AccountTransactionInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<AccountTransactionOnConflict>;
};

/** on conflict condition type for table "account.transaction" */
export type AccountTransactionOnConflict = {
  constraint: AccountTransactionConstraint;
  update_columns?: Array<AccountTransactionUpdateColumn>;
  where?: Maybe<AccountTransactionBoolExp>;
};

/** Ordering options when selecting data from "account.transaction". */
export type AccountTransactionOrderBy = {
  approved_at?: Maybe<OrderBy>;
  approved_by?: Maybe<OrderBy>;
  approved_by_user?: Maybe<UsersOrderBy>;
  cancelled_at?: Maybe<OrderBy>;
  cancelled_by?: Maybe<OrderBy>;
  cancelled_by_user?: Maybe<UsersOrderBy>;
  change_requested_at?: Maybe<OrderBy>;
  change_requested_by?: Maybe<OrderBy>;
  change_requested_by_user?: Maybe<UsersOrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  created_by_user?: Maybe<UsersOrderBy>;
  date?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  gfmis_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_allocation?: Maybe<OrderBy>;
  ledger_entries_aggregate?: Maybe<AccountLedgerEntryAggregateOrderBy>;
  lending?: Maybe<AccountLendingOrderBy>;
  lending_id?: Maybe<OrderBy>;
  organization?: Maybe<OrganizationOrderBy>;
  organization_id?: Maybe<OrderBy>;
  reference?: Maybe<OrderBy>;
  rejected_at?: Maybe<OrderBy>;
  rejected_by?: Maybe<OrderBy>;
  rejected_by_user?: Maybe<UsersOrderBy>;
  status_id?: Maybe<OrderBy>;
  submitted_at?: Maybe<OrderBy>;
  submitted_by?: Maybe<OrderBy>;
  transaction_logs_aggregate?: Maybe<AccountTransactionLogAggregateOrderBy>;
  transaction_status?: Maybe<AccountTransactionStatusOrderBy>;
  transaction_type?: Maybe<AccountTransactionTypeOrderBy>;
  type_id?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** primary key columns input for table: account_transaction */
export type AccountTransactionPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "account.transaction" */
export enum AccountTransactionSelectColumn {
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  ApprovedBy = 'approved_by',
  /** column name */
  CancelledAt = 'cancelled_at',
  /** column name */
  CancelledBy = 'cancelled_by',
  /** column name */
  ChangeRequestedAt = 'change_requested_at',
  /** column name */
  ChangeRequestedBy = 'change_requested_by',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  GfmisId = 'gfmis_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsAllocation = 'is_allocation',
  /** column name */
  LendingId = 'lending_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Reference = 'reference',
  /** column name */
  RejectedAt = 'rejected_at',
  /** column name */
  RejectedBy = 'rejected_by',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  SubmittedAt = 'submitted_at',
  /** column name */
  SubmittedBy = 'submitted_by',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "account.transaction" */
export type AccountTransactionSetInput = {
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['uuid']>;
  cancelled_at?: Maybe<Scalars['timestamptz']>;
  cancelled_by?: Maybe<Scalars['uuid']>;
  change_requested_at?: Maybe<Scalars['timestamptz']>;
  change_requested_by?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  gfmis_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_allocation?: Maybe<Scalars['Boolean']>;
  lending_id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  reference?: Maybe<Scalars['String']>;
  rejected_at?: Maybe<Scalars['timestamptz']>;
  rejected_by?: Maybe<Scalars['uuid']>;
  status_id?: Maybe<AccountTransactionStatusEnum>;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  submitted_by?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<AccountTransactionTypeEnum>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/**
 * สถานะ Transaction
 *
 *
 * columns and relationships of "account.transaction_status"
 */
export type AccountTransactionStatus = {
  __typename?: 'account_transaction_status';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** An array relationship */
  transaction_new_status: Array<AccountTransactionLog>;
  /** An aggregate relationship */
  transaction_new_status_aggregate: AccountTransactionLogAggregate;
  /** An array relationship */
  transaction_old_status: Array<AccountTransactionLog>;
  /** An aggregate relationship */
  transaction_old_status_aggregate: AccountTransactionLogAggregate;
  /** An array relationship */
  transactions: Array<AccountTransaction>;
  /** An aggregate relationship */
  transactions_aggregate: AccountTransactionAggregate;
};


/**
 * สถานะ Transaction
 *
 *
 * columns and relationships of "account.transaction_status"
 */
export type AccountTransactionStatusTransactionNewStatusArgs = {
  distinct_on?: Maybe<Array<AccountTransactionLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionLogOrderBy>>;
  where?: Maybe<AccountTransactionLogBoolExp>;
};


/**
 * สถานะ Transaction
 *
 *
 * columns and relationships of "account.transaction_status"
 */
export type AccountTransactionStatusTransactionNewStatusAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTransactionLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionLogOrderBy>>;
  where?: Maybe<AccountTransactionLogBoolExp>;
};


/**
 * สถานะ Transaction
 *
 *
 * columns and relationships of "account.transaction_status"
 */
export type AccountTransactionStatusTransactionOldStatusArgs = {
  distinct_on?: Maybe<Array<AccountTransactionLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionLogOrderBy>>;
  where?: Maybe<AccountTransactionLogBoolExp>;
};


/**
 * สถานะ Transaction
 *
 *
 * columns and relationships of "account.transaction_status"
 */
export type AccountTransactionStatusTransactionOldStatusAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTransactionLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionLogOrderBy>>;
  where?: Maybe<AccountTransactionLogBoolExp>;
};


/**
 * สถานะ Transaction
 *
 *
 * columns and relationships of "account.transaction_status"
 */
export type AccountTransactionStatusTransactionsArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};


/**
 * สถานะ Transaction
 *
 *
 * columns and relationships of "account.transaction_status"
 */
export type AccountTransactionStatusTransactionsAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};

/** aggregated selection of "account.transaction_status" */
export type AccountTransactionStatusAggregate = {
  __typename?: 'account_transaction_status_aggregate';
  aggregate?: Maybe<AccountTransactionStatusAggregateFields>;
  nodes: Array<AccountTransactionStatus>;
};

/** aggregate fields of "account.transaction_status" */
export type AccountTransactionStatusAggregateFields = {
  __typename?: 'account_transaction_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AccountTransactionStatusMaxFields>;
  min?: Maybe<AccountTransactionStatusMinFields>;
};


/** aggregate fields of "account.transaction_status" */
export type AccountTransactionStatusAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountTransactionStatusSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "account.transaction_status". All fields are combined with a logical 'AND'. */
export type AccountTransactionStatusBoolExp = {
  _and?: Maybe<Array<AccountTransactionStatusBoolExp>>;
  _not?: Maybe<AccountTransactionStatusBoolExp>;
  _or?: Maybe<Array<AccountTransactionStatusBoolExp>>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  transaction_new_status?: Maybe<AccountTransactionLogBoolExp>;
  transaction_old_status?: Maybe<AccountTransactionLogBoolExp>;
  transactions?: Maybe<AccountTransactionBoolExp>;
};

/** unique or primary key constraints on table "account.transaction_status" */
export enum AccountTransactionStatusConstraint {
  /** unique or primary key constraint */
  TransactionStatusPkey = 'transaction_status_pkey'
}

export enum AccountTransactionStatusEnum {
  /** อนุมัติ */
  Approved = 'approved',
  /** ยกเลิก */
  Cancelled = 'cancelled',
  /** เปลี่ยนแปลงข้อมูล */
  ChangeRequest = 'change_request',
  /** ร่าง */
  New = 'new',
  /** ไม่อนุมัติ */
  Rejected = 'rejected',
  /** รออนุมัติ */
  Submitted = 'submitted'
}

/** Boolean expression to compare columns of type "account_transaction_status_enum". All fields are combined with logical 'AND'. */
export type AccountTransactionStatusEnumComparisonExp = {
  _eq?: Maybe<AccountTransactionStatusEnum>;
  _in?: Maybe<Array<AccountTransactionStatusEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<AccountTransactionStatusEnum>;
  _nin?: Maybe<Array<AccountTransactionStatusEnum>>;
};

/** input type for inserting data into table "account.transaction_status" */
export type AccountTransactionStatusInsertInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  transaction_new_status?: Maybe<AccountTransactionLogArrRelInsertInput>;
  transaction_old_status?: Maybe<AccountTransactionLogArrRelInsertInput>;
  transactions?: Maybe<AccountTransactionArrRelInsertInput>;
};

/** aggregate max on columns */
export type AccountTransactionStatusMaxFields = {
  __typename?: 'account_transaction_status_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AccountTransactionStatusMinFields = {
  __typename?: 'account_transaction_status_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "account.transaction_status" */
export type AccountTransactionStatusMutationResponse = {
  __typename?: 'account_transaction_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccountTransactionStatus>;
};

/** input type for inserting object relation for remote table "account.transaction_status" */
export type AccountTransactionStatusObjRelInsertInput = {
  data: AccountTransactionStatusInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<AccountTransactionStatusOnConflict>;
};

/** on conflict condition type for table "account.transaction_status" */
export type AccountTransactionStatusOnConflict = {
  constraint: AccountTransactionStatusConstraint;
  update_columns?: Array<AccountTransactionStatusUpdateColumn>;
  where?: Maybe<AccountTransactionStatusBoolExp>;
};

/** Ordering options when selecting data from "account.transaction_status". */
export type AccountTransactionStatusOrderBy = {
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  transaction_new_status_aggregate?: Maybe<AccountTransactionLogAggregateOrderBy>;
  transaction_old_status_aggregate?: Maybe<AccountTransactionLogAggregateOrderBy>;
  transactions_aggregate?: Maybe<AccountTransactionAggregateOrderBy>;
};

/** primary key columns input for table: account_transaction_status */
export type AccountTransactionStatusPkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "account.transaction_status" */
export enum AccountTransactionStatusSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "account.transaction_status" */
export type AccountTransactionStatusSetInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** update columns of table "account.transaction_status" */
export enum AccountTransactionStatusUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** aggregate stddev on columns */
export type AccountTransactionStddevFields = {
  __typename?: 'account_transaction_stddev_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "account.transaction" */
export type AccountTransactionStddevOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type AccountTransactionStddevPopFields = {
  __typename?: 'account_transaction_stddev_pop_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "account.transaction" */
export type AccountTransactionStddevPopOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type AccountTransactionStddevSampFields = {
  __typename?: 'account_transaction_stddev_samp_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "account.transaction" */
export type AccountTransactionStddevSampOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type AccountTransactionSumFields = {
  __typename?: 'account_transaction_sum_fields';
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "account.transaction" */
export type AccountTransactionSumOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
};

/** columns and relationships of "account.transaction_type" */
export type AccountTransactionType = {
  __typename?: 'account_transaction_type';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** An array relationship */
  transactions: Array<AccountTransaction>;
  /** An aggregate relationship */
  transactions_aggregate: AccountTransactionAggregate;
};


/** columns and relationships of "account.transaction_type" */
export type AccountTransactionTypeTransactionsArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};


/** columns and relationships of "account.transaction_type" */
export type AccountTransactionTypeTransactionsAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};

/** aggregated selection of "account.transaction_type" */
export type AccountTransactionTypeAggregate = {
  __typename?: 'account_transaction_type_aggregate';
  aggregate?: Maybe<AccountTransactionTypeAggregateFields>;
  nodes: Array<AccountTransactionType>;
};

/** aggregate fields of "account.transaction_type" */
export type AccountTransactionTypeAggregateFields = {
  __typename?: 'account_transaction_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AccountTransactionTypeMaxFields>;
  min?: Maybe<AccountTransactionTypeMinFields>;
};


/** aggregate fields of "account.transaction_type" */
export type AccountTransactionTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountTransactionTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "account.transaction_type". All fields are combined with a logical 'AND'. */
export type AccountTransactionTypeBoolExp = {
  _and?: Maybe<Array<AccountTransactionTypeBoolExp>>;
  _not?: Maybe<AccountTransactionTypeBoolExp>;
  _or?: Maybe<Array<AccountTransactionTypeBoolExp>>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  transactions?: Maybe<AccountTransactionBoolExp>;
};

/** unique or primary key constraints on table "account.transaction_type" */
export enum AccountTransactionTypeConstraint {
  /** unique or primary key constraint */
  TransactionTypePkey = 'transaction_type_pkey'
}

export enum AccountTransactionTypeEnum {
  /** รายวันทั่วไป */
  JournalEntry = 'journal_entry',
  /** รายวันจ่าย */
  PaymentVoucher = 'payment_voucher',
  /** รายวันรับ */
  ReceiptVoucher = 'receipt_voucher'
}

/** Boolean expression to compare columns of type "account_transaction_type_enum". All fields are combined with logical 'AND'. */
export type AccountTransactionTypeEnumComparisonExp = {
  _eq?: Maybe<AccountTransactionTypeEnum>;
  _in?: Maybe<Array<AccountTransactionTypeEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<AccountTransactionTypeEnum>;
  _nin?: Maybe<Array<AccountTransactionTypeEnum>>;
};

/** input type for inserting data into table "account.transaction_type" */
export type AccountTransactionTypeInsertInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  transactions?: Maybe<AccountTransactionArrRelInsertInput>;
};

/** aggregate max on columns */
export type AccountTransactionTypeMaxFields = {
  __typename?: 'account_transaction_type_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AccountTransactionTypeMinFields = {
  __typename?: 'account_transaction_type_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "account.transaction_type" */
export type AccountTransactionTypeMutationResponse = {
  __typename?: 'account_transaction_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccountTransactionType>;
};

/** input type for inserting object relation for remote table "account.transaction_type" */
export type AccountTransactionTypeObjRelInsertInput = {
  data: AccountTransactionTypeInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<AccountTransactionTypeOnConflict>;
};

/** on conflict condition type for table "account.transaction_type" */
export type AccountTransactionTypeOnConflict = {
  constraint: AccountTransactionTypeConstraint;
  update_columns?: Array<AccountTransactionTypeUpdateColumn>;
  where?: Maybe<AccountTransactionTypeBoolExp>;
};

/** Ordering options when selecting data from "account.transaction_type". */
export type AccountTransactionTypeOrderBy = {
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  transactions_aggregate?: Maybe<AccountTransactionAggregateOrderBy>;
};

/** primary key columns input for table: account_transaction_type */
export type AccountTransactionTypePkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "account.transaction_type" */
export enum AccountTransactionTypeSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "account.transaction_type" */
export type AccountTransactionTypeSetInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** update columns of table "account.transaction_type" */
export enum AccountTransactionTypeUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** update columns of table "account.transaction" */
export enum AccountTransactionUpdateColumn {
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  ApprovedBy = 'approved_by',
  /** column name */
  CancelledAt = 'cancelled_at',
  /** column name */
  CancelledBy = 'cancelled_by',
  /** column name */
  ChangeRequestedAt = 'change_requested_at',
  /** column name */
  ChangeRequestedBy = 'change_requested_by',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  GfmisId = 'gfmis_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsAllocation = 'is_allocation',
  /** column name */
  LendingId = 'lending_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Reference = 'reference',
  /** column name */
  RejectedAt = 'rejected_at',
  /** column name */
  RejectedBy = 'rejected_by',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  SubmittedAt = 'submitted_at',
  /** column name */
  SubmittedBy = 'submitted_by',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type AccountTransactionVarPopFields = {
  __typename?: 'account_transaction_var_pop_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "account.transaction" */
export type AccountTransactionVarPopOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type AccountTransactionVarSampFields = {
  __typename?: 'account_transaction_var_samp_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "account.transaction" */
export type AccountTransactionVarSampOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type AccountTransactionVarianceFields = {
  __typename?: 'account_transaction_variance_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "account.transaction" */
export type AccountTransactionVarianceOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
};

/** columns and relationships of "account.trial_balance_monthly" */
export type AccountTrialBalanceMonthly = {
  __typename?: 'account_trial_balance_monthly';
  account_code?: Maybe<Scalars['String']>;
  account_id?: Maybe<Scalars['uuid']>;
  account_name?: Maybe<Scalars['String']>;
  credit_total?: Maybe<Scalars['numeric']>;
  debit_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['float8']>;
  organization_id?: Maybe<Scalars['uuid']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "account.trial_balance_monthly" */
export type AccountTrialBalanceMonthlyAggregate = {
  __typename?: 'account_trial_balance_monthly_aggregate';
  aggregate?: Maybe<AccountTrialBalanceMonthlyAggregateFields>;
  nodes: Array<AccountTrialBalanceMonthly>;
};

/** aggregate fields of "account.trial_balance_monthly" */
export type AccountTrialBalanceMonthlyAggregateFields = {
  __typename?: 'account_trial_balance_monthly_aggregate_fields';
  avg?: Maybe<AccountTrialBalanceMonthlyAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AccountTrialBalanceMonthlyMaxFields>;
  min?: Maybe<AccountTrialBalanceMonthlyMinFields>;
  stddev?: Maybe<AccountTrialBalanceMonthlyStddevFields>;
  stddev_pop?: Maybe<AccountTrialBalanceMonthlyStddevPopFields>;
  stddev_samp?: Maybe<AccountTrialBalanceMonthlyStddevSampFields>;
  sum?: Maybe<AccountTrialBalanceMonthlySumFields>;
  var_pop?: Maybe<AccountTrialBalanceMonthlyVarPopFields>;
  var_samp?: Maybe<AccountTrialBalanceMonthlyVarSampFields>;
  variance?: Maybe<AccountTrialBalanceMonthlyVarianceFields>;
};


/** aggregate fields of "account.trial_balance_monthly" */
export type AccountTrialBalanceMonthlyAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountTrialBalanceMonthlySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type AccountTrialBalanceMonthlyAvgFields = {
  __typename?: 'account_trial_balance_monthly_avg_fields';
  credit_total?: Maybe<Scalars['Float']>;
  debit_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "account.trial_balance_monthly". All fields are combined with a logical 'AND'. */
export type AccountTrialBalanceMonthlyBoolExp = {
  _and?: Maybe<Array<AccountTrialBalanceMonthlyBoolExp>>;
  _not?: Maybe<AccountTrialBalanceMonthlyBoolExp>;
  _or?: Maybe<Array<AccountTrialBalanceMonthlyBoolExp>>;
  account_code?: Maybe<StringComparisonExp>;
  account_id?: Maybe<UuidComparisonExp>;
  account_name?: Maybe<StringComparisonExp>;
  credit_total?: Maybe<NumericComparisonExp>;
  debit_total?: Maybe<NumericComparisonExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
  month?: Maybe<Float8ComparisonExp>;
  organization_id?: Maybe<UuidComparisonExp>;
  year?: Maybe<Float8ComparisonExp>;
};

/** aggregate max on columns */
export type AccountTrialBalanceMonthlyMaxFields = {
  __typename?: 'account_trial_balance_monthly_max_fields';
  account_code?: Maybe<Scalars['String']>;
  account_id?: Maybe<Scalars['uuid']>;
  account_name?: Maybe<Scalars['String']>;
  credit_total?: Maybe<Scalars['numeric']>;
  debit_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['float8']>;
  organization_id?: Maybe<Scalars['uuid']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type AccountTrialBalanceMonthlyMinFields = {
  __typename?: 'account_trial_balance_monthly_min_fields';
  account_code?: Maybe<Scalars['String']>;
  account_id?: Maybe<Scalars['uuid']>;
  account_name?: Maybe<Scalars['String']>;
  credit_total?: Maybe<Scalars['numeric']>;
  debit_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['float8']>;
  organization_id?: Maybe<Scalars['uuid']>;
  year?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "account.trial_balance_monthly". */
export type AccountTrialBalanceMonthlyOrderBy = {
  account_code?: Maybe<OrderBy>;
  account_id?: Maybe<OrderBy>;
  account_name?: Maybe<OrderBy>;
  credit_total?: Maybe<OrderBy>;
  debit_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  month?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  year?: Maybe<OrderBy>;
};

/** select columns of table "account.trial_balance_monthly" */
export enum AccountTrialBalanceMonthlySelectColumn {
  /** column name */
  AccountCode = 'account_code',
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AccountName = 'account_name',
  /** column name */
  CreditTotal = 'credit_total',
  /** column name */
  DebitTotal = 'debit_total',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  Month = 'month',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Year = 'year'
}

/** aggregate stddev on columns */
export type AccountTrialBalanceMonthlyStddevFields = {
  __typename?: 'account_trial_balance_monthly_stddev_fields';
  credit_total?: Maybe<Scalars['Float']>;
  debit_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AccountTrialBalanceMonthlyStddevPopFields = {
  __typename?: 'account_trial_balance_monthly_stddev_pop_fields';
  credit_total?: Maybe<Scalars['Float']>;
  debit_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AccountTrialBalanceMonthlyStddevSampFields = {
  __typename?: 'account_trial_balance_monthly_stddev_samp_fields';
  credit_total?: Maybe<Scalars['Float']>;
  debit_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type AccountTrialBalanceMonthlySumFields = {
  __typename?: 'account_trial_balance_monthly_sum_fields';
  credit_total?: Maybe<Scalars['numeric']>;
  debit_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['float8']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type AccountTrialBalanceMonthlyVarPopFields = {
  __typename?: 'account_trial_balance_monthly_var_pop_fields';
  credit_total?: Maybe<Scalars['Float']>;
  debit_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AccountTrialBalanceMonthlyVarSampFields = {
  __typename?: 'account_trial_balance_monthly_var_samp_fields';
  credit_total?: Maybe<Scalars['Float']>;
  debit_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AccountTrialBalanceMonthlyVarianceFields = {
  __typename?: 'account_trial_balance_monthly_variance_fields';
  credit_total?: Maybe<Scalars['Float']>;
  debit_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "attachment" */
export type Attachment = {
  __typename?: 'attachment';
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  deleted_at: Scalars['timestamptz'];
  file_path: Scalars['String'];
  filename: Scalars['String'];
  id: Scalars['uuid'];
  mimetype: Scalars['String'];
  name: Scalars['String'];
  original_name: Scalars['String'];
};

/** aggregated selection of "attachment" */
export type AttachmentAggregate = {
  __typename?: 'attachment_aggregate';
  aggregate?: Maybe<AttachmentAggregateFields>;
  nodes: Array<Attachment>;
};

/** aggregate fields of "attachment" */
export type AttachmentAggregateFields = {
  __typename?: 'attachment_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AttachmentMaxFields>;
  min?: Maybe<AttachmentMinFields>;
};


/** aggregate fields of "attachment" */
export type AttachmentAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AttachmentSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "attachment". All fields are combined with a logical 'AND'. */
export type AttachmentBoolExp = {
  _and?: Maybe<Array<AttachmentBoolExp>>;
  _not?: Maybe<AttachmentBoolExp>;
  _or?: Maybe<Array<AttachmentBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  file_path?: Maybe<StringComparisonExp>;
  filename?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  mimetype?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  original_name?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "attachment" */
export enum AttachmentConstraint {
  /** unique or primary key constraint */
  AttachmentPkey = 'attachment_pkey'
}

/** input type for inserting data into table "attachment" */
export type AttachmentInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file_path?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mimetype?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  original_name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AttachmentMaxFields = {
  __typename?: 'attachment_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file_path?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mimetype?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  original_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AttachmentMinFields = {
  __typename?: 'attachment_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file_path?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mimetype?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  original_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "attachment" */
export type AttachmentMutationResponse = {
  __typename?: 'attachment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Attachment>;
};

/** on conflict condition type for table "attachment" */
export type AttachmentOnConflict = {
  constraint: AttachmentConstraint;
  update_columns?: Array<AttachmentUpdateColumn>;
  where?: Maybe<AttachmentBoolExp>;
};

/** Ordering options when selecting data from "attachment". */
export type AttachmentOrderBy = {
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  file_path?: Maybe<OrderBy>;
  filename?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mimetype?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  original_name?: Maybe<OrderBy>;
};

/** primary key columns input for table: attachment */
export type AttachmentPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "attachment" */
export enum AttachmentSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Mimetype = 'mimetype',
  /** column name */
  Name = 'name',
  /** column name */
  OriginalName = 'original_name'
}

/** input type for updating data in table "attachment" */
export type AttachmentSetInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file_path?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mimetype?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  original_name?: Maybe<Scalars['String']>;
};

/** update columns of table "attachment" */
export enum AttachmentUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Mimetype = 'mimetype',
  /** column name */
  Name = 'name',
  /** column name */
  OriginalName = 'original_name'
}

/**
 * History of auditable actions on audited tables, from audit.if_modified_func()
 *
 *
 * columns and relationships of "audit.logged_actions"
 */
export type AuditLoggedActions = {
  __typename?: 'audit_logged_actions';
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action: Scalars['String'];
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk: Scalars['timestamptz'];
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm: Scalars['timestamptz'];
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx: Scalars['timestamptz'];
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: Maybe<Scalars['String']>;
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: Maybe<Scalars['jsonb']>;
  /** IP address of client that issued query. Null for unix domain socket. */
  client_addr?: Maybe<Scalars['inet']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Int']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: Maybe<Scalars['String']>;
  /** Unique identifier for each auditable event */
  event_id: Scalars['bigint'];
  hasura_user?: Maybe<Scalars['jsonb']>;
  hasura_user_id?: Maybe<Scalars['String']>;
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid: Scalars['oid'];
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: Maybe<Scalars['jsonb']>;
  /** Database schema audited table for this event is in */
  schema_name: Scalars['String'];
  /** Login / session user whose statement caused the audited event */
  session_user_name?: Maybe<Scalars['String']>;
  /** 't' if audit event is from an FOR EACH STATEMENT trigger, 'f' for FOR EACH ROW */
  statement_only: Scalars['Boolean'];
  /** Non-schema-qualified table name of table event occured in */
  table_name: Scalars['String'];
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['bigint']>;
};


/**
 * History of auditable actions on audited tables, from audit.if_modified_func()
 *
 *
 * columns and relationships of "audit.logged_actions"
 */
export type AuditLoggedActionsChangedFieldsArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * History of auditable actions on audited tables, from audit.if_modified_func()
 *
 *
 * columns and relationships of "audit.logged_actions"
 */
export type AuditLoggedActionsHasuraUserArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * History of auditable actions on audited tables, from audit.if_modified_func()
 *
 *
 * columns and relationships of "audit.logged_actions"
 */
export type AuditLoggedActionsRowDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "audit.logged_actions" */
export type AuditLoggedActionsAggregate = {
  __typename?: 'audit_logged_actions_aggregate';
  aggregate?: Maybe<AuditLoggedActionsAggregateFields>;
  nodes: Array<AuditLoggedActions>;
};

/** aggregate fields of "audit.logged_actions" */
export type AuditLoggedActionsAggregateFields = {
  __typename?: 'audit_logged_actions_aggregate_fields';
  avg?: Maybe<AuditLoggedActionsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AuditLoggedActionsMaxFields>;
  min?: Maybe<AuditLoggedActionsMinFields>;
  stddev?: Maybe<AuditLoggedActionsStddevFields>;
  stddev_pop?: Maybe<AuditLoggedActionsStddevPopFields>;
  stddev_samp?: Maybe<AuditLoggedActionsStddevSampFields>;
  sum?: Maybe<AuditLoggedActionsSumFields>;
  var_pop?: Maybe<AuditLoggedActionsVarPopFields>;
  var_samp?: Maybe<AuditLoggedActionsVarSampFields>;
  variance?: Maybe<AuditLoggedActionsVarianceFields>;
};


/** aggregate fields of "audit.logged_actions" */
export type AuditLoggedActionsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AuditLoggedActionsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AuditLoggedActionsAppendInput = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: Maybe<Scalars['jsonb']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type AuditLoggedActionsAvgFields = {
  __typename?: 'audit_logged_actions_avg_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "audit.logged_actions". All fields are combined with a logical 'AND'. */
export type AuditLoggedActionsBoolExp = {
  _and?: Maybe<Array<AuditLoggedActionsBoolExp>>;
  _not?: Maybe<AuditLoggedActionsBoolExp>;
  _or?: Maybe<Array<AuditLoggedActionsBoolExp>>;
  action?: Maybe<StringComparisonExp>;
  action_tstamp_clk?: Maybe<TimestamptzComparisonExp>;
  action_tstamp_stm?: Maybe<TimestamptzComparisonExp>;
  action_tstamp_tx?: Maybe<TimestamptzComparisonExp>;
  application_name?: Maybe<StringComparisonExp>;
  changed_fields?: Maybe<JsonbComparisonExp>;
  client_addr?: Maybe<InetComparisonExp>;
  client_port?: Maybe<IntComparisonExp>;
  client_query?: Maybe<StringComparisonExp>;
  event_id?: Maybe<BigintComparisonExp>;
  hasura_user?: Maybe<JsonbComparisonExp>;
  hasura_user_id?: Maybe<StringComparisonExp>;
  relid?: Maybe<OidComparisonExp>;
  row_data?: Maybe<JsonbComparisonExp>;
  schema_name?: Maybe<StringComparisonExp>;
  session_user_name?: Maybe<StringComparisonExp>;
  statement_only?: Maybe<BooleanComparisonExp>;
  table_name?: Maybe<StringComparisonExp>;
  transaction_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "audit.logged_actions" */
export enum AuditLoggedActionsConstraint {
  /** unique or primary key constraint */
  LoggedActionsPkey = 'logged_actions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AuditLoggedActionsDeleteAtPathInput = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: Maybe<Array<Scalars['String']>>;
  hasura_user?: Maybe<Array<Scalars['String']>>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AuditLoggedActionsDeleteElemInput = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: Maybe<Scalars['Int']>;
  hasura_user?: Maybe<Scalars['Int']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AuditLoggedActionsDeleteKeyInput = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: Maybe<Scalars['String']>;
  hasura_user?: Maybe<Scalars['String']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "audit.logged_actions" */
export type AuditLoggedActionsIncInput = {
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Int']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['bigint']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "audit.logged_actions" */
export type AuditLoggedActionsInsertInput = {
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: Maybe<Scalars['String']>;
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: Maybe<Scalars['String']>;
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: Maybe<Scalars['jsonb']>;
  /** IP address of client that issued query. Null for unix domain socket. */
  client_addr?: Maybe<Scalars['inet']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Int']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: Maybe<Scalars['String']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['bigint']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  hasura_user_id?: Maybe<Scalars['String']>;
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid?: Maybe<Scalars['oid']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: Maybe<Scalars['jsonb']>;
  /** Database schema audited table for this event is in */
  schema_name?: Maybe<Scalars['String']>;
  /** Login / session user whose statement caused the audited event */
  session_user_name?: Maybe<Scalars['String']>;
  /** 't' if audit event is from an FOR EACH STATEMENT trigger, 'f' for FOR EACH ROW */
  statement_only?: Maybe<Scalars['Boolean']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?: Maybe<Scalars['String']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type AuditLoggedActionsMaxFields = {
  __typename?: 'audit_logged_actions_max_fields';
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: Maybe<Scalars['String']>;
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: Maybe<Scalars['String']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Int']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: Maybe<Scalars['String']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['bigint']>;
  hasura_user_id?: Maybe<Scalars['String']>;
  /** Database schema audited table for this event is in */
  schema_name?: Maybe<Scalars['String']>;
  /** Login / session user whose statement caused the audited event */
  session_user_name?: Maybe<Scalars['String']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?: Maybe<Scalars['String']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type AuditLoggedActionsMinFields = {
  __typename?: 'audit_logged_actions_min_fields';
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: Maybe<Scalars['String']>;
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: Maybe<Scalars['String']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Int']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: Maybe<Scalars['String']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['bigint']>;
  hasura_user_id?: Maybe<Scalars['String']>;
  /** Database schema audited table for this event is in */
  schema_name?: Maybe<Scalars['String']>;
  /** Login / session user whose statement caused the audited event */
  session_user_name?: Maybe<Scalars['String']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?: Maybe<Scalars['String']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "audit.logged_actions" */
export type AuditLoggedActionsMutationResponse = {
  __typename?: 'audit_logged_actions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AuditLoggedActions>;
};

/** on conflict condition type for table "audit.logged_actions" */
export type AuditLoggedActionsOnConflict = {
  constraint: AuditLoggedActionsConstraint;
  update_columns?: Array<AuditLoggedActionsUpdateColumn>;
  where?: Maybe<AuditLoggedActionsBoolExp>;
};

/** Ordering options when selecting data from "audit.logged_actions". */
export type AuditLoggedActionsOrderBy = {
  action?: Maybe<OrderBy>;
  action_tstamp_clk?: Maybe<OrderBy>;
  action_tstamp_stm?: Maybe<OrderBy>;
  action_tstamp_tx?: Maybe<OrderBy>;
  application_name?: Maybe<OrderBy>;
  changed_fields?: Maybe<OrderBy>;
  client_addr?: Maybe<OrderBy>;
  client_port?: Maybe<OrderBy>;
  client_query?: Maybe<OrderBy>;
  event_id?: Maybe<OrderBy>;
  hasura_user?: Maybe<OrderBy>;
  hasura_user_id?: Maybe<OrderBy>;
  relid?: Maybe<OrderBy>;
  row_data?: Maybe<OrderBy>;
  schema_name?: Maybe<OrderBy>;
  session_user_name?: Maybe<OrderBy>;
  statement_only?: Maybe<OrderBy>;
  table_name?: Maybe<OrderBy>;
  transaction_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: audit_logged_actions */
export type AuditLoggedActionsPkColumnsInput = {
  /** Unique identifier for each auditable event */
  event_id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AuditLoggedActionsPrependInput = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: Maybe<Scalars['jsonb']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "audit.logged_actions" */
export enum AuditLoggedActionsSelectColumn {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTstampClk = 'action_tstamp_clk',
  /** column name */
  ActionTstampStm = 'action_tstamp_stm',
  /** column name */
  ActionTstampTx = 'action_tstamp_tx',
  /** column name */
  ApplicationName = 'application_name',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ClientAddr = 'client_addr',
  /** column name */
  ClientPort = 'client_port',
  /** column name */
  ClientQuery = 'client_query',
  /** column name */
  EventId = 'event_id',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  HasuraUserId = 'hasura_user_id',
  /** column name */
  Relid = 'relid',
  /** column name */
  RowData = 'row_data',
  /** column name */
  SchemaName = 'schema_name',
  /** column name */
  SessionUserName = 'session_user_name',
  /** column name */
  StatementOnly = 'statement_only',
  /** column name */
  TableName = 'table_name',
  /** column name */
  TransactionId = 'transaction_id'
}

/** input type for updating data in table "audit.logged_actions" */
export type AuditLoggedActionsSetInput = {
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: Maybe<Scalars['String']>;
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: Maybe<Scalars['String']>;
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: Maybe<Scalars['jsonb']>;
  /** IP address of client that issued query. Null for unix domain socket. */
  client_addr?: Maybe<Scalars['inet']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Int']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: Maybe<Scalars['String']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['bigint']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  hasura_user_id?: Maybe<Scalars['String']>;
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid?: Maybe<Scalars['oid']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: Maybe<Scalars['jsonb']>;
  /** Database schema audited table for this event is in */
  schema_name?: Maybe<Scalars['String']>;
  /** Login / session user whose statement caused the audited event */
  session_user_name?: Maybe<Scalars['String']>;
  /** 't' if audit event is from an FOR EACH STATEMENT trigger, 'f' for FOR EACH ROW */
  statement_only?: Maybe<Scalars['Boolean']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?: Maybe<Scalars['String']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type AuditLoggedActionsStddevFields = {
  __typename?: 'audit_logged_actions_stddev_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AuditLoggedActionsStddevPopFields = {
  __typename?: 'audit_logged_actions_stddev_pop_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AuditLoggedActionsStddevSampFields = {
  __typename?: 'audit_logged_actions_stddev_samp_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type AuditLoggedActionsSumFields = {
  __typename?: 'audit_logged_actions_sum_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Int']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['bigint']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "audit.logged_actions" */
export enum AuditLoggedActionsUpdateColumn {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTstampClk = 'action_tstamp_clk',
  /** column name */
  ActionTstampStm = 'action_tstamp_stm',
  /** column name */
  ActionTstampTx = 'action_tstamp_tx',
  /** column name */
  ApplicationName = 'application_name',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ClientAddr = 'client_addr',
  /** column name */
  ClientPort = 'client_port',
  /** column name */
  ClientQuery = 'client_query',
  /** column name */
  EventId = 'event_id',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  HasuraUserId = 'hasura_user_id',
  /** column name */
  Relid = 'relid',
  /** column name */
  RowData = 'row_data',
  /** column name */
  SchemaName = 'schema_name',
  /** column name */
  SessionUserName = 'session_user_name',
  /** column name */
  StatementOnly = 'statement_only',
  /** column name */
  TableName = 'table_name',
  /** column name */
  TransactionId = 'transaction_id'
}

/** aggregate var_pop on columns */
export type AuditLoggedActionsVarPopFields = {
  __typename?: 'audit_logged_actions_var_pop_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AuditLoggedActionsVarSampFields = {
  __typename?: 'audit_logged_actions_var_samp_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AuditLoggedActionsVarianceFields = {
  __typename?: 'audit_logged_actions_variance_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "auth.account_providers" */
export type AuthAccountProviders = {
  __typename?: 'auth_account_providers';
  /** An object relationship */
  account: AuthAccounts;
  account_id: Scalars['uuid'];
  auth_provider: Scalars['String'];
  auth_provider_unique_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  provider: AuthProviders;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "auth.account_providers" */
export type AuthAccountProvidersAggregate = {
  __typename?: 'auth_account_providers_aggregate';
  aggregate?: Maybe<AuthAccountProvidersAggregateFields>;
  nodes: Array<AuthAccountProviders>;
};

/** aggregate fields of "auth.account_providers" */
export type AuthAccountProvidersAggregateFields = {
  __typename?: 'auth_account_providers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AuthAccountProvidersMaxFields>;
  min?: Maybe<AuthAccountProvidersMinFields>;
};


/** aggregate fields of "auth.account_providers" */
export type AuthAccountProvidersAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AuthAccountProvidersSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.account_providers" */
export type AuthAccountProvidersAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<AuthAccountProvidersMaxOrderBy>;
  min?: Maybe<AuthAccountProvidersMinOrderBy>;
};

/** input type for inserting array relation for remote table "auth.account_providers" */
export type AuthAccountProvidersArrRelInsertInput = {
  data: Array<AuthAccountProvidersInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<AuthAccountProvidersOnConflict>;
};

/** Boolean expression to filter rows from the table "auth.account_providers". All fields are combined with a logical 'AND'. */
export type AuthAccountProvidersBoolExp = {
  _and?: Maybe<Array<AuthAccountProvidersBoolExp>>;
  _not?: Maybe<AuthAccountProvidersBoolExp>;
  _or?: Maybe<Array<AuthAccountProvidersBoolExp>>;
  account?: Maybe<AuthAccountsBoolExp>;
  account_id?: Maybe<UuidComparisonExp>;
  auth_provider?: Maybe<StringComparisonExp>;
  auth_provider_unique_id?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  provider?: Maybe<AuthProvidersBoolExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "auth.account_providers" */
export enum AuthAccountProvidersConstraint {
  /** unique or primary key constraint */
  AccountProvidersAccountIdAuthProviderKey = 'account_providers_account_id_auth_provider_key',
  /** unique or primary key constraint */
  AccountProvidersAuthProviderAuthProviderUniqueIdKey = 'account_providers_auth_provider_auth_provider_unique_id_key',
  /** unique or primary key constraint */
  AccountProvidersPkey = 'account_providers_pkey'
}

/** input type for inserting data into table "auth.account_providers" */
export type AuthAccountProvidersInsertInput = {
  account?: Maybe<AuthAccountsObjRelInsertInput>;
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  provider?: Maybe<AuthProvidersObjRelInsertInput>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type AuthAccountProvidersMaxFields = {
  __typename?: 'auth_account_providers_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "auth.account_providers" */
export type AuthAccountProvidersMaxOrderBy = {
  account_id?: Maybe<OrderBy>;
  auth_provider?: Maybe<OrderBy>;
  auth_provider_unique_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AuthAccountProvidersMinFields = {
  __typename?: 'auth_account_providers_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "auth.account_providers" */
export type AuthAccountProvidersMinOrderBy = {
  account_id?: Maybe<OrderBy>;
  auth_provider?: Maybe<OrderBy>;
  auth_provider_unique_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "auth.account_providers" */
export type AuthAccountProvidersMutationResponse = {
  __typename?: 'auth_account_providers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthAccountProviders>;
};

/** on conflict condition type for table "auth.account_providers" */
export type AuthAccountProvidersOnConflict = {
  constraint: AuthAccountProvidersConstraint;
  update_columns?: Array<AuthAccountProvidersUpdateColumn>;
  where?: Maybe<AuthAccountProvidersBoolExp>;
};

/** Ordering options when selecting data from "auth.account_providers". */
export type AuthAccountProvidersOrderBy = {
  account?: Maybe<AuthAccountsOrderBy>;
  account_id?: Maybe<OrderBy>;
  auth_provider?: Maybe<OrderBy>;
  auth_provider_unique_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  provider?: Maybe<AuthProvidersOrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: auth_account_providers */
export type AuthAccountProvidersPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.account_providers" */
export enum AuthAccountProvidersSelectColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AuthProvider = 'auth_provider',
  /** column name */
  AuthProviderUniqueId = 'auth_provider_unique_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "auth.account_providers" */
export type AuthAccountProvidersSetInput = {
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "auth.account_providers" */
export enum AuthAccountProvidersUpdateColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AuthProvider = 'auth_provider',
  /** column name */
  AuthProviderUniqueId = 'auth_provider_unique_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "auth.account_roles" */
export type AuthAccountRoles = {
  __typename?: 'auth_account_roles';
  /** An object relationship */
  account: AuthAccounts;
  account_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  role: Scalars['String'];
  /** An object relationship */
  roleByRole: AuthRoles;
};

/** aggregated selection of "auth.account_roles" */
export type AuthAccountRolesAggregate = {
  __typename?: 'auth_account_roles_aggregate';
  aggregate?: Maybe<AuthAccountRolesAggregateFields>;
  nodes: Array<AuthAccountRoles>;
};

/** aggregate fields of "auth.account_roles" */
export type AuthAccountRolesAggregateFields = {
  __typename?: 'auth_account_roles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AuthAccountRolesMaxFields>;
  min?: Maybe<AuthAccountRolesMinFields>;
};


/** aggregate fields of "auth.account_roles" */
export type AuthAccountRolesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AuthAccountRolesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.account_roles" */
export type AuthAccountRolesAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<AuthAccountRolesMaxOrderBy>;
  min?: Maybe<AuthAccountRolesMinOrderBy>;
};

/** input type for inserting array relation for remote table "auth.account_roles" */
export type AuthAccountRolesArrRelInsertInput = {
  data: Array<AuthAccountRolesInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<AuthAccountRolesOnConflict>;
};

/** Boolean expression to filter rows from the table "auth.account_roles". All fields are combined with a logical 'AND'. */
export type AuthAccountRolesBoolExp = {
  _and?: Maybe<Array<AuthAccountRolesBoolExp>>;
  _not?: Maybe<AuthAccountRolesBoolExp>;
  _or?: Maybe<Array<AuthAccountRolesBoolExp>>;
  account?: Maybe<AuthAccountsBoolExp>;
  account_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  role?: Maybe<StringComparisonExp>;
  roleByRole?: Maybe<AuthRolesBoolExp>;
};

/** unique or primary key constraints on table "auth.account_roles" */
export enum AuthAccountRolesConstraint {
  /** unique or primary key constraint */
  AccountRolesPkey = 'account_roles_pkey',
  /** unique or primary key constraint */
  UserRolesAccountIdRoleKey = 'user_roles_account_id_role_key'
}

/** input type for inserting data into table "auth.account_roles" */
export type AuthAccountRolesInsertInput = {
  account?: Maybe<AuthAccountsObjRelInsertInput>;
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  roleByRole?: Maybe<AuthRolesObjRelInsertInput>;
};

/** aggregate max on columns */
export type AuthAccountRolesMaxFields = {
  __typename?: 'auth_account_roles_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "auth.account_roles" */
export type AuthAccountRolesMaxOrderBy = {
  account_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  role?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AuthAccountRolesMinFields = {
  __typename?: 'auth_account_roles_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "auth.account_roles" */
export type AuthAccountRolesMinOrderBy = {
  account_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  role?: Maybe<OrderBy>;
};

/** response of any mutation on the table "auth.account_roles" */
export type AuthAccountRolesMutationResponse = {
  __typename?: 'auth_account_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthAccountRoles>;
};

/** on conflict condition type for table "auth.account_roles" */
export type AuthAccountRolesOnConflict = {
  constraint: AuthAccountRolesConstraint;
  update_columns?: Array<AuthAccountRolesUpdateColumn>;
  where?: Maybe<AuthAccountRolesBoolExp>;
};

/** Ordering options when selecting data from "auth.account_roles". */
export type AuthAccountRolesOrderBy = {
  account?: Maybe<AuthAccountsOrderBy>;
  account_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  role?: Maybe<OrderBy>;
  roleByRole?: Maybe<AuthRolesOrderBy>;
};

/** primary key columns input for table: auth_account_roles */
export type AuthAccountRolesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.account_roles" */
export enum AuthAccountRolesSelectColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "auth.account_roles" */
export type AuthAccountRolesSetInput = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
};

/** update columns of table "auth.account_roles" */
export enum AuthAccountRolesUpdateColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role'
}

/** columns and relationships of "auth.accounts" */
export type AuthAccounts = {
  __typename?: 'auth_accounts';
  /** An array relationship */
  account_providers: Array<AuthAccountProviders>;
  /** An aggregate relationship */
  account_providers_aggregate: AuthAccountProvidersAggregate;
  /** An array relationship */
  account_roles: Array<AuthAccountRoles>;
  /** An aggregate relationship */
  account_roles_aggregate: AuthAccountRolesAggregate;
  active: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  custom_register_data?: Maybe<Scalars['jsonb']>;
  default_role: Scalars['String'];
  email?: Maybe<Scalars['citext']>;
  id: Scalars['uuid'];
  is_anonymous: Scalars['Boolean'];
  mfa_enabled: Scalars['Boolean'];
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  /** An array relationship */
  refresh_tokens: Array<AuthRefreshTokens>;
  /** An aggregate relationship */
  refresh_tokens_aggregate: AuthRefreshTokensAggregate;
  /** An object relationship */
  role: AuthRoles;
  ticket: Scalars['uuid'];
  ticket_expires_at: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};


/** columns and relationships of "auth.accounts" */
export type AuthAccountsAccountProvidersArgs = {
  distinct_on?: Maybe<Array<AuthAccountProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountProvidersOrderBy>>;
  where?: Maybe<AuthAccountProvidersBoolExp>;
};


/** columns and relationships of "auth.accounts" */
export type AuthAccountsAccountProvidersAggregateArgs = {
  distinct_on?: Maybe<Array<AuthAccountProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountProvidersOrderBy>>;
  where?: Maybe<AuthAccountProvidersBoolExp>;
};


/** columns and relationships of "auth.accounts" */
export type AuthAccountsAccountRolesArgs = {
  distinct_on?: Maybe<Array<AuthAccountRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountRolesOrderBy>>;
  where?: Maybe<AuthAccountRolesBoolExp>;
};


/** columns and relationships of "auth.accounts" */
export type AuthAccountsAccountRolesAggregateArgs = {
  distinct_on?: Maybe<Array<AuthAccountRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountRolesOrderBy>>;
  where?: Maybe<AuthAccountRolesBoolExp>;
};


/** columns and relationships of "auth.accounts" */
export type AuthAccountsCustomRegisterDataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "auth.accounts" */
export type AuthAccountsRefreshTokensArgs = {
  distinct_on?: Maybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthRefreshTokensOrderBy>>;
  where?: Maybe<AuthRefreshTokensBoolExp>;
};


/** columns and relationships of "auth.accounts" */
export type AuthAccountsRefreshTokensAggregateArgs = {
  distinct_on?: Maybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthRefreshTokensOrderBy>>;
  where?: Maybe<AuthRefreshTokensBoolExp>;
};

/** aggregated selection of "auth.accounts" */
export type AuthAccountsAggregate = {
  __typename?: 'auth_accounts_aggregate';
  aggregate?: Maybe<AuthAccountsAggregateFields>;
  nodes: Array<AuthAccounts>;
};

/** aggregate fields of "auth.accounts" */
export type AuthAccountsAggregateFields = {
  __typename?: 'auth_accounts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AuthAccountsMaxFields>;
  min?: Maybe<AuthAccountsMinFields>;
};


/** aggregate fields of "auth.accounts" */
export type AuthAccountsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AuthAccountsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.accounts" */
export type AuthAccountsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<AuthAccountsMaxOrderBy>;
  min?: Maybe<AuthAccountsMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AuthAccountsAppendInput = {
  custom_register_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "auth.accounts" */
export type AuthAccountsArrRelInsertInput = {
  data: Array<AuthAccountsInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<AuthAccountsOnConflict>;
};

/** Boolean expression to filter rows from the table "auth.accounts". All fields are combined with a logical 'AND'. */
export type AuthAccountsBoolExp = {
  _and?: Maybe<Array<AuthAccountsBoolExp>>;
  _not?: Maybe<AuthAccountsBoolExp>;
  _or?: Maybe<Array<AuthAccountsBoolExp>>;
  account_providers?: Maybe<AuthAccountProvidersBoolExp>;
  account_roles?: Maybe<AuthAccountRolesBoolExp>;
  active?: Maybe<BooleanComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  custom_register_data?: Maybe<JsonbComparisonExp>;
  default_role?: Maybe<StringComparisonExp>;
  email?: Maybe<CitextComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_anonymous?: Maybe<BooleanComparisonExp>;
  mfa_enabled?: Maybe<BooleanComparisonExp>;
  new_email?: Maybe<CitextComparisonExp>;
  otp_secret?: Maybe<StringComparisonExp>;
  password_hash?: Maybe<StringComparisonExp>;
  refresh_tokens?: Maybe<AuthRefreshTokensBoolExp>;
  role?: Maybe<AuthRolesBoolExp>;
  ticket?: Maybe<UuidComparisonExp>;
  ticket_expires_at?: Maybe<TimestamptzComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<UsersBoolExp>;
  user_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "auth.accounts" */
export enum AuthAccountsConstraint {
  /** unique or primary key constraint */
  AccountsEmailKey = 'accounts_email_key',
  /** unique or primary key constraint */
  AccountsNewEmailKey = 'accounts_new_email_key',
  /** unique or primary key constraint */
  AccountsPkey = 'accounts_pkey',
  /** unique or primary key constraint */
  AccountsUserIdKey = 'accounts_user_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AuthAccountsDeleteAtPathInput = {
  custom_register_data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AuthAccountsDeleteElemInput = {
  custom_register_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AuthAccountsDeleteKeyInput = {
  custom_register_data?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "auth.accounts" */
export type AuthAccountsInsertInput = {
  account_providers?: Maybe<AuthAccountProvidersArrRelInsertInput>;
  account_roles?: Maybe<AuthAccountRolesArrRelInsertInput>;
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_register_data?: Maybe<Scalars['jsonb']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  is_anonymous?: Maybe<Scalars['Boolean']>;
  mfa_enabled?: Maybe<Scalars['Boolean']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  refresh_tokens?: Maybe<AuthRefreshTokensArrRelInsertInput>;
  role?: Maybe<AuthRolesObjRelInsertInput>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<UsersObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AuthAccountsMaxFields = {
  __typename?: 'auth_accounts_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "auth.accounts" */
export type AuthAccountsMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  default_role?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  new_email?: Maybe<OrderBy>;
  otp_secret?: Maybe<OrderBy>;
  password_hash?: Maybe<OrderBy>;
  ticket?: Maybe<OrderBy>;
  ticket_expires_at?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AuthAccountsMinFields = {
  __typename?: 'auth_accounts_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "auth.accounts" */
export type AuthAccountsMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  default_role?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  new_email?: Maybe<OrderBy>;
  otp_secret?: Maybe<OrderBy>;
  password_hash?: Maybe<OrderBy>;
  ticket?: Maybe<OrderBy>;
  ticket_expires_at?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "auth.accounts" */
export type AuthAccountsMutationResponse = {
  __typename?: 'auth_accounts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthAccounts>;
};

/** input type for inserting object relation for remote table "auth.accounts" */
export type AuthAccountsObjRelInsertInput = {
  data: AuthAccountsInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<AuthAccountsOnConflict>;
};

/** on conflict condition type for table "auth.accounts" */
export type AuthAccountsOnConflict = {
  constraint: AuthAccountsConstraint;
  update_columns?: Array<AuthAccountsUpdateColumn>;
  where?: Maybe<AuthAccountsBoolExp>;
};

/** Ordering options when selecting data from "auth.accounts". */
export type AuthAccountsOrderBy = {
  account_providers_aggregate?: Maybe<AuthAccountProvidersAggregateOrderBy>;
  account_roles_aggregate?: Maybe<AuthAccountRolesAggregateOrderBy>;
  active?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  custom_register_data?: Maybe<OrderBy>;
  default_role?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_anonymous?: Maybe<OrderBy>;
  mfa_enabled?: Maybe<OrderBy>;
  new_email?: Maybe<OrderBy>;
  otp_secret?: Maybe<OrderBy>;
  password_hash?: Maybe<OrderBy>;
  refresh_tokens_aggregate?: Maybe<AuthRefreshTokensAggregateOrderBy>;
  role?: Maybe<AuthRolesOrderBy>;
  ticket?: Maybe<OrderBy>;
  ticket_expires_at?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user?: Maybe<UsersOrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: auth_accounts */
export type AuthAccountsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AuthAccountsPrependInput = {
  custom_register_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "auth.accounts" */
export enum AuthAccountsSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomRegisterData = 'custom_register_data',
  /** column name */
  DefaultRole = 'default_role',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymous = 'is_anonymous',
  /** column name */
  MfaEnabled = 'mfa_enabled',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  OtpSecret = 'otp_secret',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticket_expires_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "auth.accounts" */
export type AuthAccountsSetInput = {
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_register_data?: Maybe<Scalars['jsonb']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  is_anonymous?: Maybe<Scalars['Boolean']>;
  mfa_enabled?: Maybe<Scalars['Boolean']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "auth.accounts" */
export enum AuthAccountsUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomRegisterData = 'custom_register_data',
  /** column name */
  DefaultRole = 'default_role',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymous = 'is_anonymous',
  /** column name */
  MfaEnabled = 'mfa_enabled',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  OtpSecret = 'otp_secret',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticket_expires_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "auth.providers" */
export type AuthProviders = {
  __typename?: 'auth_providers';
  /** An array relationship */
  account_providers: Array<AuthAccountProviders>;
  /** An aggregate relationship */
  account_providers_aggregate: AuthAccountProvidersAggregate;
  provider: Scalars['String'];
};


/** columns and relationships of "auth.providers" */
export type AuthProvidersAccountProvidersArgs = {
  distinct_on?: Maybe<Array<AuthAccountProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountProvidersOrderBy>>;
  where?: Maybe<AuthAccountProvidersBoolExp>;
};


/** columns and relationships of "auth.providers" */
export type AuthProvidersAccountProvidersAggregateArgs = {
  distinct_on?: Maybe<Array<AuthAccountProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountProvidersOrderBy>>;
  where?: Maybe<AuthAccountProvidersBoolExp>;
};

/** aggregated selection of "auth.providers" */
export type AuthProvidersAggregate = {
  __typename?: 'auth_providers_aggregate';
  aggregate?: Maybe<AuthProvidersAggregateFields>;
  nodes: Array<AuthProviders>;
};

/** aggregate fields of "auth.providers" */
export type AuthProvidersAggregateFields = {
  __typename?: 'auth_providers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AuthProvidersMaxFields>;
  min?: Maybe<AuthProvidersMinFields>;
};


/** aggregate fields of "auth.providers" */
export type AuthProvidersAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AuthProvidersSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "auth.providers". All fields are combined with a logical 'AND'. */
export type AuthProvidersBoolExp = {
  _and?: Maybe<Array<AuthProvidersBoolExp>>;
  _not?: Maybe<AuthProvidersBoolExp>;
  _or?: Maybe<Array<AuthProvidersBoolExp>>;
  account_providers?: Maybe<AuthAccountProvidersBoolExp>;
  provider?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "auth.providers" */
export enum AuthProvidersConstraint {
  /** unique or primary key constraint */
  ProvidersPkey = 'providers_pkey'
}

/** input type for inserting data into table "auth.providers" */
export type AuthProvidersInsertInput = {
  account_providers?: Maybe<AuthAccountProvidersArrRelInsertInput>;
  provider?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AuthProvidersMaxFields = {
  __typename?: 'auth_providers_max_fields';
  provider?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AuthProvidersMinFields = {
  __typename?: 'auth_providers_min_fields';
  provider?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "auth.providers" */
export type AuthProvidersMutationResponse = {
  __typename?: 'auth_providers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthProviders>;
};

/** input type for inserting object relation for remote table "auth.providers" */
export type AuthProvidersObjRelInsertInput = {
  data: AuthProvidersInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<AuthProvidersOnConflict>;
};

/** on conflict condition type for table "auth.providers" */
export type AuthProvidersOnConflict = {
  constraint: AuthProvidersConstraint;
  update_columns?: Array<AuthProvidersUpdateColumn>;
  where?: Maybe<AuthProvidersBoolExp>;
};

/** Ordering options when selecting data from "auth.providers". */
export type AuthProvidersOrderBy = {
  account_providers_aggregate?: Maybe<AuthAccountProvidersAggregateOrderBy>;
  provider?: Maybe<OrderBy>;
};

/** primary key columns input for table: auth_providers */
export type AuthProvidersPkColumnsInput = {
  provider: Scalars['String'];
};

/** select columns of table "auth.providers" */
export enum AuthProvidersSelectColumn {
  /** column name */
  Provider = 'provider'
}

/** input type for updating data in table "auth.providers" */
export type AuthProvidersSetInput = {
  provider?: Maybe<Scalars['String']>;
};

/** update columns of table "auth.providers" */
export enum AuthProvidersUpdateColumn {
  /** column name */
  Provider = 'provider'
}

/** columns and relationships of "auth.refresh_tokens" */
export type AuthRefreshTokens = {
  __typename?: 'auth_refresh_tokens';
  /** An object relationship */
  account: AuthAccounts;
  account_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  expires_at: Scalars['timestamptz'];
  refresh_token: Scalars['uuid'];
};

/** aggregated selection of "auth.refresh_tokens" */
export type AuthRefreshTokensAggregate = {
  __typename?: 'auth_refresh_tokens_aggregate';
  aggregate?: Maybe<AuthRefreshTokensAggregateFields>;
  nodes: Array<AuthRefreshTokens>;
};

/** aggregate fields of "auth.refresh_tokens" */
export type AuthRefreshTokensAggregateFields = {
  __typename?: 'auth_refresh_tokens_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AuthRefreshTokensMaxFields>;
  min?: Maybe<AuthRefreshTokensMinFields>;
};


/** aggregate fields of "auth.refresh_tokens" */
export type AuthRefreshTokensAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AuthRefreshTokensSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.refresh_tokens" */
export type AuthRefreshTokensAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<AuthRefreshTokensMaxOrderBy>;
  min?: Maybe<AuthRefreshTokensMinOrderBy>;
};

/** input type for inserting array relation for remote table "auth.refresh_tokens" */
export type AuthRefreshTokensArrRelInsertInput = {
  data: Array<AuthRefreshTokensInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<AuthRefreshTokensOnConflict>;
};

/** Boolean expression to filter rows from the table "auth.refresh_tokens". All fields are combined with a logical 'AND'. */
export type AuthRefreshTokensBoolExp = {
  _and?: Maybe<Array<AuthRefreshTokensBoolExp>>;
  _not?: Maybe<AuthRefreshTokensBoolExp>;
  _or?: Maybe<Array<AuthRefreshTokensBoolExp>>;
  account?: Maybe<AuthAccountsBoolExp>;
  account_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  expires_at?: Maybe<TimestamptzComparisonExp>;
  refresh_token?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "auth.refresh_tokens" */
export enum AuthRefreshTokensConstraint {
  /** unique or primary key constraint */
  RefreshTokensPkey = 'refresh_tokens_pkey'
}

/** input type for inserting data into table "auth.refresh_tokens" */
export type AuthRefreshTokensInsertInput = {
  account?: Maybe<AuthAccountsObjRelInsertInput>;
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AuthRefreshTokensMaxFields = {
  __typename?: 'auth_refresh_tokens_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "auth.refresh_tokens" */
export type AuthRefreshTokensMaxOrderBy = {
  account_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  expires_at?: Maybe<OrderBy>;
  refresh_token?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AuthRefreshTokensMinFields = {
  __typename?: 'auth_refresh_tokens_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "auth.refresh_tokens" */
export type AuthRefreshTokensMinOrderBy = {
  account_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  expires_at?: Maybe<OrderBy>;
  refresh_token?: Maybe<OrderBy>;
};

/** response of any mutation on the table "auth.refresh_tokens" */
export type AuthRefreshTokensMutationResponse = {
  __typename?: 'auth_refresh_tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthRefreshTokens>;
};

/** on conflict condition type for table "auth.refresh_tokens" */
export type AuthRefreshTokensOnConflict = {
  constraint: AuthRefreshTokensConstraint;
  update_columns?: Array<AuthRefreshTokensUpdateColumn>;
  where?: Maybe<AuthRefreshTokensBoolExp>;
};

/** Ordering options when selecting data from "auth.refresh_tokens". */
export type AuthRefreshTokensOrderBy = {
  account?: Maybe<AuthAccountsOrderBy>;
  account_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  expires_at?: Maybe<OrderBy>;
  refresh_token?: Maybe<OrderBy>;
};

/** primary key columns input for table: auth_refresh_tokens */
export type AuthRefreshTokensPkColumnsInput = {
  refresh_token: Scalars['uuid'];
};

/** select columns of table "auth.refresh_tokens" */
export enum AuthRefreshTokensSelectColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  RefreshToken = 'refresh_token'
}

/** input type for updating data in table "auth.refresh_tokens" */
export type AuthRefreshTokensSetInput = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** update columns of table "auth.refresh_tokens" */
export enum AuthRefreshTokensUpdateColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  RefreshToken = 'refresh_token'
}

/** columns and relationships of "auth.roles" */
export type AuthRoles = {
  __typename?: 'auth_roles';
  /** An array relationship */
  account_roles: Array<AuthAccountRoles>;
  /** An aggregate relationship */
  account_roles_aggregate: AuthAccountRolesAggregate;
  /** An array relationship */
  accounts: Array<AuthAccounts>;
  /** An aggregate relationship */
  accounts_aggregate: AuthAccountsAggregate;
  role: Scalars['String'];
};


/** columns and relationships of "auth.roles" */
export type AuthRolesAccountRolesArgs = {
  distinct_on?: Maybe<Array<AuthAccountRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountRolesOrderBy>>;
  where?: Maybe<AuthAccountRolesBoolExp>;
};


/** columns and relationships of "auth.roles" */
export type AuthRolesAccountRolesAggregateArgs = {
  distinct_on?: Maybe<Array<AuthAccountRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountRolesOrderBy>>;
  where?: Maybe<AuthAccountRolesBoolExp>;
};


/** columns and relationships of "auth.roles" */
export type AuthRolesAccountsArgs = {
  distinct_on?: Maybe<Array<AuthAccountsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountsOrderBy>>;
  where?: Maybe<AuthAccountsBoolExp>;
};


/** columns and relationships of "auth.roles" */
export type AuthRolesAccountsAggregateArgs = {
  distinct_on?: Maybe<Array<AuthAccountsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountsOrderBy>>;
  where?: Maybe<AuthAccountsBoolExp>;
};

/** aggregated selection of "auth.roles" */
export type AuthRolesAggregate = {
  __typename?: 'auth_roles_aggregate';
  aggregate?: Maybe<AuthRolesAggregateFields>;
  nodes: Array<AuthRoles>;
};

/** aggregate fields of "auth.roles" */
export type AuthRolesAggregateFields = {
  __typename?: 'auth_roles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AuthRolesMaxFields>;
  min?: Maybe<AuthRolesMinFields>;
};


/** aggregate fields of "auth.roles" */
export type AuthRolesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AuthRolesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "auth.roles". All fields are combined with a logical 'AND'. */
export type AuthRolesBoolExp = {
  _and?: Maybe<Array<AuthRolesBoolExp>>;
  _not?: Maybe<AuthRolesBoolExp>;
  _or?: Maybe<Array<AuthRolesBoolExp>>;
  account_roles?: Maybe<AuthAccountRolesBoolExp>;
  accounts?: Maybe<AuthAccountsBoolExp>;
  role?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "auth.roles" */
export enum AuthRolesConstraint {
  /** unique or primary key constraint */
  RolesPkey = 'roles_pkey'
}

/** input type for inserting data into table "auth.roles" */
export type AuthRolesInsertInput = {
  account_roles?: Maybe<AuthAccountRolesArrRelInsertInput>;
  accounts?: Maybe<AuthAccountsArrRelInsertInput>;
  role?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AuthRolesMaxFields = {
  __typename?: 'auth_roles_max_fields';
  role?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AuthRolesMinFields = {
  __typename?: 'auth_roles_min_fields';
  role?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "auth.roles" */
export type AuthRolesMutationResponse = {
  __typename?: 'auth_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthRoles>;
};

/** input type for inserting object relation for remote table "auth.roles" */
export type AuthRolesObjRelInsertInput = {
  data: AuthRolesInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<AuthRolesOnConflict>;
};

/** on conflict condition type for table "auth.roles" */
export type AuthRolesOnConflict = {
  constraint: AuthRolesConstraint;
  update_columns?: Array<AuthRolesUpdateColumn>;
  where?: Maybe<AuthRolesBoolExp>;
};

/** Ordering options when selecting data from "auth.roles". */
export type AuthRolesOrderBy = {
  account_roles_aggregate?: Maybe<AuthAccountRolesAggregateOrderBy>;
  accounts_aggregate?: Maybe<AuthAccountsAggregateOrderBy>;
  role?: Maybe<OrderBy>;
};

/** primary key columns input for table: auth_roles */
export type AuthRolesPkColumnsInput = {
  role: Scalars['String'];
};

/** select columns of table "auth.roles" */
export enum AuthRolesSelectColumn {
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "auth.roles" */
export type AuthRolesSetInput = {
  role?: Maybe<Scalars['String']>;
};

/** update columns of table "auth.roles" */
export enum AuthRolesUpdateColumn {
  /** column name */
  Role = 'role'
}

/**
 * สาขาธนาคาร
 *
 *
 * columns and relationships of "bank_branch"
 */
export type BankBranch = {
  __typename?: 'bank_branch';
  /** An array relationship */
  agreement_withdraws: Array<ContractAgreementWithdraw>;
  /** An aggregate relationship */
  agreement_withdraws_aggregate: ContractAgreementWithdrawAggregate;
  /** An array relationship */
  agreements: Array<ContractAgreement>;
  /** An aggregate relationship */
  agreements_aggregate: ContractAgreementAggregate;
  /** An array relationship */
  application_bank_branch_transfers_from: Array<LoanApplicationBankBranchTransfer>;
  /** An aggregate relationship */
  application_bank_branch_transfers_from_aggregate: LoanApplicationBankBranchTransferAggregate;
  /** An array relationship */
  application_bank_branch_transfers_to: Array<LoanApplicationBankBranchTransfer>;
  /** An aggregate relationship */
  application_bank_branch_transfers_to_aggregate: LoanApplicationBankBranchTransferAggregate;
  /** An array relationship */
  application_headers: Array<LoanApplicationHeader>;
  /** An aggregate relationship */
  application_headers_aggregate: LoanApplicationHeaderAggregate;
  /** ชื่อสาขาธนาคาร */
  branch?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at: Scalars['timestamptz'];
  /** รหัสสาขาธนาคาร */
  id: Scalars['String'];
  /** สาขาหลักโดย true = เป็นสาขาหลักของจังหวัด, false = ไม่เป็นสาขาหลักของจังหวัด */
  main_branch: Scalars['Boolean'];
  /** ข้อความเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** จังหวัด */
  province?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: UsersAggregate;
};


/**
 * สาขาธนาคาร
 *
 *
 * columns and relationships of "bank_branch"
 */
export type BankBranchAgreementWithdrawsArgs = {
  distinct_on?: Maybe<Array<ContractAgreementWithdrawSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementWithdrawOrderBy>>;
  where?: Maybe<ContractAgreementWithdrawBoolExp>;
};


/**
 * สาขาธนาคาร
 *
 *
 * columns and relationships of "bank_branch"
 */
export type BankBranchAgreementWithdrawsAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementWithdrawSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementWithdrawOrderBy>>;
  where?: Maybe<ContractAgreementWithdrawBoolExp>;
};


/**
 * สาขาธนาคาร
 *
 *
 * columns and relationships of "bank_branch"
 */
export type BankBranchAgreementsArgs = {
  distinct_on?: Maybe<Array<ContractAgreementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementOrderBy>>;
  where?: Maybe<ContractAgreementBoolExp>;
};


/**
 * สาขาธนาคาร
 *
 *
 * columns and relationships of "bank_branch"
 */
export type BankBranchAgreementsAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementOrderBy>>;
  where?: Maybe<ContractAgreementBoolExp>;
};


/**
 * สาขาธนาคาร
 *
 *
 * columns and relationships of "bank_branch"
 */
export type BankBranchApplicationBankBranchTransfersFromArgs = {
  distinct_on?: Maybe<Array<LoanApplicationBankBranchTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationBankBranchTransferOrderBy>>;
  where?: Maybe<LoanApplicationBankBranchTransferBoolExp>;
};


/**
 * สาขาธนาคาร
 *
 *
 * columns and relationships of "bank_branch"
 */
export type BankBranchApplicationBankBranchTransfersFromAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationBankBranchTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationBankBranchTransferOrderBy>>;
  where?: Maybe<LoanApplicationBankBranchTransferBoolExp>;
};


/**
 * สาขาธนาคาร
 *
 *
 * columns and relationships of "bank_branch"
 */
export type BankBranchApplicationBankBranchTransfersToArgs = {
  distinct_on?: Maybe<Array<LoanApplicationBankBranchTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationBankBranchTransferOrderBy>>;
  where?: Maybe<LoanApplicationBankBranchTransferBoolExp>;
};


/**
 * สาขาธนาคาร
 *
 *
 * columns and relationships of "bank_branch"
 */
export type BankBranchApplicationBankBranchTransfersToAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationBankBranchTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationBankBranchTransferOrderBy>>;
  where?: Maybe<LoanApplicationBankBranchTransferBoolExp>;
};


/**
 * สาขาธนาคาร
 *
 *
 * columns and relationships of "bank_branch"
 */
export type BankBranchApplicationHeadersArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};


/**
 * สาขาธนาคาร
 *
 *
 * columns and relationships of "bank_branch"
 */
export type BankBranchApplicationHeadersAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};


/**
 * สาขาธนาคาร
 *
 *
 * columns and relationships of "bank_branch"
 */
export type BankBranchUsersArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};


/**
 * สาขาธนาคาร
 *
 *
 * columns and relationships of "bank_branch"
 */
export type BankBranchUsersAggregateArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};

/** aggregated selection of "bank_branch" */
export type BankBranchAggregate = {
  __typename?: 'bank_branch_aggregate';
  aggregate?: Maybe<BankBranchAggregateFields>;
  nodes: Array<BankBranch>;
};

/** aggregate fields of "bank_branch" */
export type BankBranchAggregateFields = {
  __typename?: 'bank_branch_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<BankBranchMaxFields>;
  min?: Maybe<BankBranchMinFields>;
};


/** aggregate fields of "bank_branch" */
export type BankBranchAggregateFieldsCountArgs = {
  columns?: Maybe<Array<BankBranchSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "bank_branch". All fields are combined with a logical 'AND'. */
export type BankBranchBoolExp = {
  _and?: Maybe<Array<BankBranchBoolExp>>;
  _not?: Maybe<BankBranchBoolExp>;
  _or?: Maybe<Array<BankBranchBoolExp>>;
  agreement_withdraws?: Maybe<ContractAgreementWithdrawBoolExp>;
  agreements?: Maybe<ContractAgreementBoolExp>;
  application_bank_branch_transfers_from?: Maybe<LoanApplicationBankBranchTransferBoolExp>;
  application_bank_branch_transfers_to?: Maybe<LoanApplicationBankBranchTransferBoolExp>;
  application_headers?: Maybe<LoanApplicationHeaderBoolExp>;
  branch?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  main_branch?: Maybe<BooleanComparisonExp>;
  note?: Maybe<StringComparisonExp>;
  province?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  users?: Maybe<UsersBoolExp>;
};

/** unique or primary key constraints on table "bank_branch" */
export enum BankBranchConstraint {
  /** unique or primary key constraint */
  BankBranchPkey = 'bank_branch_pkey'
}

/** input type for inserting data into table "bank_branch" */
export type BankBranchInsertInput = {
  agreement_withdraws?: Maybe<ContractAgreementWithdrawArrRelInsertInput>;
  agreements?: Maybe<ContractAgreementArrRelInsertInput>;
  application_bank_branch_transfers_from?: Maybe<LoanApplicationBankBranchTransferArrRelInsertInput>;
  application_bank_branch_transfers_to?: Maybe<LoanApplicationBankBranchTransferArrRelInsertInput>;
  application_headers?: Maybe<LoanApplicationHeaderArrRelInsertInput>;
  /** ชื่อสาขาธนาคาร */
  branch?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** รหัสสาขาธนาคาร */
  id?: Maybe<Scalars['String']>;
  /** สาขาหลักโดย true = เป็นสาขาหลักของจังหวัด, false = ไม่เป็นสาขาหลักของจังหวัด */
  main_branch?: Maybe<Scalars['Boolean']>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** จังหวัด */
  province?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  users?: Maybe<UsersArrRelInsertInput>;
};

/** aggregate max on columns */
export type BankBranchMaxFields = {
  __typename?: 'bank_branch_max_fields';
  /** ชื่อสาขาธนาคาร */
  branch?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** รหัสสาขาธนาคาร */
  id?: Maybe<Scalars['String']>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** จังหวัด */
  province?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type BankBranchMinFields = {
  __typename?: 'bank_branch_min_fields';
  /** ชื่อสาขาธนาคาร */
  branch?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** รหัสสาขาธนาคาร */
  id?: Maybe<Scalars['String']>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** จังหวัด */
  province?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "bank_branch" */
export type BankBranchMutationResponse = {
  __typename?: 'bank_branch_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BankBranch>;
};

/** input type for inserting object relation for remote table "bank_branch" */
export type BankBranchObjRelInsertInput = {
  data: BankBranchInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<BankBranchOnConflict>;
};

/** on conflict condition type for table "bank_branch" */
export type BankBranchOnConflict = {
  constraint: BankBranchConstraint;
  update_columns?: Array<BankBranchUpdateColumn>;
  where?: Maybe<BankBranchBoolExp>;
};

/** Ordering options when selecting data from "bank_branch". */
export type BankBranchOrderBy = {
  agreement_withdraws_aggregate?: Maybe<ContractAgreementWithdrawAggregateOrderBy>;
  agreements_aggregate?: Maybe<ContractAgreementAggregateOrderBy>;
  application_bank_branch_transfers_from_aggregate?: Maybe<LoanApplicationBankBranchTransferAggregateOrderBy>;
  application_bank_branch_transfers_to_aggregate?: Maybe<LoanApplicationBankBranchTransferAggregateOrderBy>;
  application_headers_aggregate?: Maybe<LoanApplicationHeaderAggregateOrderBy>;
  branch?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  main_branch?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  province?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  users_aggregate?: Maybe<UsersAggregateOrderBy>;
};

/** primary key columns input for table: bank_branch */
export type BankBranchPkColumnsInput = {
  /** รหัสสาขาธนาคาร */
  id: Scalars['String'];
};

/** select columns of table "bank_branch" */
export enum BankBranchSelectColumn {
  /** column name */
  Branch = 'branch',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MainBranch = 'main_branch',
  /** column name */
  Note = 'note',
  /** column name */
  Province = 'province',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "bank_branch" */
export type BankBranchSetInput = {
  /** ชื่อสาขาธนาคาร */
  branch?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** รหัสสาขาธนาคาร */
  id?: Maybe<Scalars['String']>;
  /** สาขาหลักโดย true = เป็นสาขาหลักของจังหวัด, false = ไม่เป็นสาขาหลักของจังหวัด */
  main_branch?: Maybe<Scalars['Boolean']>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** จังหวัด */
  province?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "bank_branch" */
export enum BankBranchUpdateColumn {
  /** column name */
  Branch = 'branch',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MainBranch = 'main_branch',
  /** column name */
  Note = 'note',
  /** column name */
  Province = 'province',
  /** column name */
  UpdatedAt = 'updated_at'
}


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type BigintComparisonExp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};


/** Boolean expression to compare columns of type "bpchar". All fields are combined with logical 'AND'. */
export type BpcharComparisonExp = {
  _eq?: Maybe<Scalars['bpchar']>;
  _gt?: Maybe<Scalars['bpchar']>;
  _gte?: Maybe<Scalars['bpchar']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['bpchar']>;
  _in?: Maybe<Array<Scalars['bpchar']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['bpchar']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['bpchar']>;
  _lt?: Maybe<Scalars['bpchar']>;
  _lte?: Maybe<Scalars['bpchar']>;
  _neq?: Maybe<Scalars['bpchar']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['bpchar']>;
  _nin?: Maybe<Array<Scalars['bpchar']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['bpchar']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['bpchar']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['bpchar']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['bpchar']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['bpchar']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['bpchar']>;
};


/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export type CitextComparisonExp = {
  _eq?: Maybe<Scalars['citext']>;
  _gt?: Maybe<Scalars['citext']>;
  _gte?: Maybe<Scalars['citext']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['citext']>;
  _in?: Maybe<Array<Scalars['citext']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['citext']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['citext']>;
  _lt?: Maybe<Scalars['citext']>;
  _lte?: Maybe<Scalars['citext']>;
  _neq?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['citext']>;
  _nin?: Maybe<Array<Scalars['citext']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['citext']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['citext']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['citext']>;
};

/**
 * สถานะใบคำขอกู้จากธนาคาร
 *
 *
 * columns and relationships of "contract.af_status"
 */
export type ContractAfStatus = {
  __typename?: 'contract_af_status';
  /** An array relationship */
  agreement_af_status_counts: Array<ContractAgreementAfStatusCount>;
  /** An aggregate relationship */
  agreement_af_status_counts_aggregate: ContractAgreementAfStatusCountAggregate;
  /** สถานะใบคำขอกู้จากธนาคาร */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
};


/**
 * สถานะใบคำขอกู้จากธนาคาร
 *
 *
 * columns and relationships of "contract.af_status"
 */
export type ContractAfStatusAgreementAfStatusCountsArgs = {
  distinct_on?: Maybe<Array<ContractAgreementAfStatusCountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementAfStatusCountOrderBy>>;
  where?: Maybe<ContractAgreementAfStatusCountBoolExp>;
};


/**
 * สถานะใบคำขอกู้จากธนาคาร
 *
 *
 * columns and relationships of "contract.af_status"
 */
export type ContractAfStatusAgreementAfStatusCountsAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementAfStatusCountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementAfStatusCountOrderBy>>;
  where?: Maybe<ContractAgreementAfStatusCountBoolExp>;
};

/** aggregated selection of "contract.af_status" */
export type ContractAfStatusAggregate = {
  __typename?: 'contract_af_status_aggregate';
  aggregate?: Maybe<ContractAfStatusAggregateFields>;
  nodes: Array<ContractAfStatus>;
};

/** aggregate fields of "contract.af_status" */
export type ContractAfStatusAggregateFields = {
  __typename?: 'contract_af_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContractAfStatusMaxFields>;
  min?: Maybe<ContractAfStatusMinFields>;
};


/** aggregate fields of "contract.af_status" */
export type ContractAfStatusAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ContractAfStatusSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "contract.af_status". All fields are combined with a logical 'AND'. */
export type ContractAfStatusBoolExp = {
  _and?: Maybe<Array<ContractAfStatusBoolExp>>;
  _not?: Maybe<ContractAfStatusBoolExp>;
  _or?: Maybe<Array<ContractAfStatusBoolExp>>;
  agreement_af_status_counts?: Maybe<ContractAgreementAfStatusCountBoolExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "contract.af_status" */
export enum ContractAfStatusConstraint {
  /** unique or primary key constraint */
  AfStatusPkey = 'af_status_pkey'
}

/** input type for inserting data into table "contract.af_status" */
export type ContractAfStatusInsertInput = {
  agreement_af_status_counts?: Maybe<ContractAgreementAfStatusCountArrRelInsertInput>;
  /** สถานะใบคำขอกู้จากธนาคาร */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ContractAfStatusMaxFields = {
  __typename?: 'contract_af_status_max_fields';
  /** สถานะใบคำขอกู้จากธนาคาร */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ContractAfStatusMinFields = {
  __typename?: 'contract_af_status_min_fields';
  /** สถานะใบคำขอกู้จากธนาคาร */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "contract.af_status" */
export type ContractAfStatusMutationResponse = {
  __typename?: 'contract_af_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContractAfStatus>;
};

/** input type for inserting object relation for remote table "contract.af_status" */
export type ContractAfStatusObjRelInsertInput = {
  data: ContractAfStatusInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<ContractAfStatusOnConflict>;
};

/** on conflict condition type for table "contract.af_status" */
export type ContractAfStatusOnConflict = {
  constraint: ContractAfStatusConstraint;
  update_columns?: Array<ContractAfStatusUpdateColumn>;
  where?: Maybe<ContractAfStatusBoolExp>;
};

/** Ordering options when selecting data from "contract.af_status". */
export type ContractAfStatusOrderBy = {
  agreement_af_status_counts_aggregate?: Maybe<ContractAgreementAfStatusCountAggregateOrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** primary key columns input for table: contract_af_status */
export type ContractAfStatusPkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "contract.af_status" */
export enum ContractAfStatusSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "contract.af_status" */
export type ContractAfStatusSetInput = {
  /** สถานะใบคำขอกู้จากธนาคาร */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** update columns of table "contract.af_status" */
export enum ContractAfStatusUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/**
 * ข้อมูลลุกหนี้เงินกู้
 *
 *
 * columns and relationships of "contract.agreement"
 */
export type ContractAgreement = {
  __typename?: 'contract_agreement';
  /** ที่อยู่ */
  address?: Maybe<Scalars['String']>;
  /** เลขที่ใบคำขอกู้ */
  af_number?: Maybe<Scalars['String']>;
  /** สถานะใบคำขอกู้ */
  af_status?: Maybe<Scalars['String']>;
  /** รายละเอียดสถานะ */
  af_status_description?: Maybe<Scalars['String']>;
  /** AFStatusName */
  af_status_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  agreement_attachments: Array<ContractAgreementAttachment>;
  /** An aggregate relationship */
  agreement_attachments_aggregate: ContractAgreementAttachmentAggregate;
  /** An object relationship */
  agreement_detail?: Maybe<ContractAgreementDetail>;
  /** An array relationship */
  agreement_details: Array<ContractAgreementDetail>;
  /** An aggregate relationship */
  agreement_details_aggregate: ContractAgreementDetailAggregate;
  /** An array relationship */
  agreement_disbursements: Array<ContractAgreementDisbursement>;
  /** An aggregate relationship */
  agreement_disbursements_aggregate: ContractAgreementDisbursementAggregate;
  /** An array relationship */
  agreement_fee_charges: Array<ContractAgreementFeeCharge>;
  /** An aggregate relationship */
  agreement_fee_charges_aggregate: ContractAgreementFeeChargeAggregate;
  /** An array relationship */
  agreement_guarantors: Array<ContractAgreementGuarantor>;
  /** An aggregate relationship */
  agreement_guarantors_aggregate: ContractAgreementGuarantorAggregate;
  /** An array relationship */
  agreement_receipts: Array<ContractAgreementReceipt>;
  /** An aggregate relationship */
  agreement_receipts_aggregate: ContractAgreementReceiptAggregate;
  /** An array relationship */
  agreement_transactions: Array<ContractAgreementTransaction>;
  /** An aggregate relationship */
  agreement_transactions_aggregate: ContractAgreementTransactionAggregate;
  /** An array relationship */
  agreement_withdraws: Array<ContractAgreementWithdraw>;
  /** An aggregate relationship */
  agreement_withdraws_aggregate: ContractAgreementWithdrawAggregate;
  /** วันที่อนุมัติ */
  approved_date?: Maybe<Scalars['date']>;
  /** An object relationship */
  bank_branch?: Maybe<BankBranch>;
  /** ธนาคารทีรับผิดชอบ */
  bank_branch_id?: Maybe<Scalars['String']>;
  /** BusGroupDetail */
  business_group?: Maybe<Scalars['String']>;
  /** ประเภทธุรกิจ */
  business_type?: Maybe<Scalars['String']>;
  /** เลขที่ติดต่อ */
  cf_number?: Maybe<Scalars['String']>;
  /** วันที่ติดต่อ */
  contact_date?: Maybe<Scalars['date']>;
  /** ผู้ติดต่อ: อีเมล */
  contact_email?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: โทรสาร */
  contact_fax?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: ชื่อ */
  contact_first_name?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: นามสกุล */
  contact_last_name?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: โทรศัพท์มือถือ */
  contact_mobile?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: โทรศัพท์ */
  contact_tel?: Maybe<Scalars['String']>;
  /** เลขที่สัญญา */
  contract_number: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<Scalars['numeric']>;
  /** Custtype */
  customer_type?: Maybe<Scalars['String']>;
  /** DDamt */
  d_damt?: Maybe<Scalars['numeric']>;
  /** วันที่ลงนามในสัญญา */
  effective_date?: Maybe<Scalars['date']>;
  /** วันที่เริ่มชำระครั้งแรก */
  first_repay_date?: Maybe<Scalars['date']>;
  /** วันที่เบิกครั้งแรก */
  first_withdraw_date?: Maybe<Scalars['date']>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<Scalars['numeric']>;
  /** ประเภทการค้ำประกัน */
  guarantee_type?: Maybe<Scalars['String']>;
  /** เลขประจำตัวประชาชนผู้ค้ำประกัน */
  guarantor_citizen_id?: Maybe<Scalars['String']>;
  /** รายละเอียดผู้ค้ำประกัน */
  guarantor_description?: Maybe<Scalars['String']>;
  /** ชื่อผู้คำประกัน */
  guarantor_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** เวลาที่บันทึกผ่านการ import ข้อมูล ถ้าค่าเป็น null หมายความว่า ผู้ใช้งานเป็นผู้สร้างข้อมูล */
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** วันหมดอายุกรมธรรม์คุ้มครองทรัพย์ */
  insurance_due_date?: Maybe<Scalars['date']>;
  /** An object relationship */
  juristic: Juristic;
  /** เลขนิติบุคคล */
  juristic_id: Scalars['String'];
  /** ชื่อนิติบุคคลภาษาอังกฤษ */
  juristic_name_en?: Maybe<Scalars['String']>;
  /** ชื่อนิติบุคคลภาษาไทย */
  juristic_name_th?: Maybe<Scalars['String']>;
  /** LoanDetail */
  loan_description?: Maybe<Scalars['String']>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<Scalars['numeric']>;
  /** วัตถุประสงค์การกู้ */
  objective?: Maybe<Scalars['String']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['String']>;
  /** An object relationship */
  program: LoanProgram;
  /** รหัสโครงการ */
  program_id: Scalars['uuid'];
  /** ชื่อโครงการ */
  program_name?: Maybe<Scalars['String']>;
  /** รหัสโครงการ */
  program_reference?: Maybe<Scalars['String']>;
  /** ProjectDetail */
  project_description?: Maybe<Scalars['String']>;
  /** ทะเบียนลูกค้า */
  register_number: Scalars['String'];
  /** An object relationship */
  repay_current_month?: Maybe<ContractAgreementRepayCurrentMonth>;
  /** วันกำหนดชำระเงินกู้แต่ละเดือน */
  repay_day_of_month?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};


/**
 * ข้อมูลลุกหนี้เงินกู้
 *
 *
 * columns and relationships of "contract.agreement"
 */
export type ContractAgreementAgreementAttachmentsArgs = {
  distinct_on?: Maybe<Array<ContractAgreementAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementAttachmentOrderBy>>;
  where?: Maybe<ContractAgreementAttachmentBoolExp>;
};


/**
 * ข้อมูลลุกหนี้เงินกู้
 *
 *
 * columns and relationships of "contract.agreement"
 */
export type ContractAgreementAgreementAttachmentsAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementAttachmentOrderBy>>;
  where?: Maybe<ContractAgreementAttachmentBoolExp>;
};


/**
 * ข้อมูลลุกหนี้เงินกู้
 *
 *
 * columns and relationships of "contract.agreement"
 */
export type ContractAgreementAgreementDetailsArgs = {
  distinct_on?: Maybe<Array<ContractAgreementDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementDetailOrderBy>>;
  where?: Maybe<ContractAgreementDetailBoolExp>;
};


/**
 * ข้อมูลลุกหนี้เงินกู้
 *
 *
 * columns and relationships of "contract.agreement"
 */
export type ContractAgreementAgreementDetailsAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementDetailOrderBy>>;
  where?: Maybe<ContractAgreementDetailBoolExp>;
};


/**
 * ข้อมูลลุกหนี้เงินกู้
 *
 *
 * columns and relationships of "contract.agreement"
 */
export type ContractAgreementAgreementDisbursementsArgs = {
  distinct_on?: Maybe<Array<ContractAgreementDisbursementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementDisbursementOrderBy>>;
  where?: Maybe<ContractAgreementDisbursementBoolExp>;
};


/**
 * ข้อมูลลุกหนี้เงินกู้
 *
 *
 * columns and relationships of "contract.agreement"
 */
export type ContractAgreementAgreementDisbursementsAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementDisbursementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementDisbursementOrderBy>>;
  where?: Maybe<ContractAgreementDisbursementBoolExp>;
};


/**
 * ข้อมูลลุกหนี้เงินกู้
 *
 *
 * columns and relationships of "contract.agreement"
 */
export type ContractAgreementAgreementFeeChargesArgs = {
  distinct_on?: Maybe<Array<ContractAgreementFeeChargeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementFeeChargeOrderBy>>;
  where?: Maybe<ContractAgreementFeeChargeBoolExp>;
};


/**
 * ข้อมูลลุกหนี้เงินกู้
 *
 *
 * columns and relationships of "contract.agreement"
 */
export type ContractAgreementAgreementFeeChargesAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementFeeChargeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementFeeChargeOrderBy>>;
  where?: Maybe<ContractAgreementFeeChargeBoolExp>;
};


/**
 * ข้อมูลลุกหนี้เงินกู้
 *
 *
 * columns and relationships of "contract.agreement"
 */
export type ContractAgreementAgreementGuarantorsArgs = {
  distinct_on?: Maybe<Array<ContractAgreementGuarantorSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementGuarantorOrderBy>>;
  where?: Maybe<ContractAgreementGuarantorBoolExp>;
};


/**
 * ข้อมูลลุกหนี้เงินกู้
 *
 *
 * columns and relationships of "contract.agreement"
 */
export type ContractAgreementAgreementGuarantorsAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementGuarantorSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementGuarantorOrderBy>>;
  where?: Maybe<ContractAgreementGuarantorBoolExp>;
};


/**
 * ข้อมูลลุกหนี้เงินกู้
 *
 *
 * columns and relationships of "contract.agreement"
 */
export type ContractAgreementAgreementReceiptsArgs = {
  distinct_on?: Maybe<Array<ContractAgreementReceiptSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementReceiptOrderBy>>;
  where?: Maybe<ContractAgreementReceiptBoolExp>;
};


/**
 * ข้อมูลลุกหนี้เงินกู้
 *
 *
 * columns and relationships of "contract.agreement"
 */
export type ContractAgreementAgreementReceiptsAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementReceiptSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementReceiptOrderBy>>;
  where?: Maybe<ContractAgreementReceiptBoolExp>;
};


/**
 * ข้อมูลลุกหนี้เงินกู้
 *
 *
 * columns and relationships of "contract.agreement"
 */
export type ContractAgreementAgreementTransactionsArgs = {
  distinct_on?: Maybe<Array<ContractAgreementTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementTransactionOrderBy>>;
  where?: Maybe<ContractAgreementTransactionBoolExp>;
};


/**
 * ข้อมูลลุกหนี้เงินกู้
 *
 *
 * columns and relationships of "contract.agreement"
 */
export type ContractAgreementAgreementTransactionsAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementTransactionOrderBy>>;
  where?: Maybe<ContractAgreementTransactionBoolExp>;
};


/**
 * ข้อมูลลุกหนี้เงินกู้
 *
 *
 * columns and relationships of "contract.agreement"
 */
export type ContractAgreementAgreementWithdrawsArgs = {
  distinct_on?: Maybe<Array<ContractAgreementWithdrawSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementWithdrawOrderBy>>;
  where?: Maybe<ContractAgreementWithdrawBoolExp>;
};


/**
 * ข้อมูลลุกหนี้เงินกู้
 *
 *
 * columns and relationships of "contract.agreement"
 */
export type ContractAgreementAgreementWithdrawsAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementWithdrawSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementWithdrawOrderBy>>;
  where?: Maybe<ContractAgreementWithdrawBoolExp>;
};

/** columns and relationships of "contract.agreement_af_status_count" */
export type ContractAgreementAfStatusCount = {
  __typename?: 'contract_agreement_af_status_count';
  /** An object relationship */
  af_status?: Maybe<ContractAfStatus>;
  af_status_description?: Maybe<Scalars['String']>;
  af_status_id?: Maybe<Scalars['String']>;
  bank_program_reference?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  program?: Maybe<LoanProgram>;
  program_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "contract.agreement_af_status_count" */
export type ContractAgreementAfStatusCountAggregate = {
  __typename?: 'contract_agreement_af_status_count_aggregate';
  aggregate?: Maybe<ContractAgreementAfStatusCountAggregateFields>;
  nodes: Array<ContractAgreementAfStatusCount>;
};

/** aggregate fields of "contract.agreement_af_status_count" */
export type ContractAgreementAfStatusCountAggregateFields = {
  __typename?: 'contract_agreement_af_status_count_aggregate_fields';
  avg?: Maybe<ContractAgreementAfStatusCountAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ContractAgreementAfStatusCountMaxFields>;
  min?: Maybe<ContractAgreementAfStatusCountMinFields>;
  stddev?: Maybe<ContractAgreementAfStatusCountStddevFields>;
  stddev_pop?: Maybe<ContractAgreementAfStatusCountStddevPopFields>;
  stddev_samp?: Maybe<ContractAgreementAfStatusCountStddevSampFields>;
  sum?: Maybe<ContractAgreementAfStatusCountSumFields>;
  var_pop?: Maybe<ContractAgreementAfStatusCountVarPopFields>;
  var_samp?: Maybe<ContractAgreementAfStatusCountVarSampFields>;
  variance?: Maybe<ContractAgreementAfStatusCountVarianceFields>;
};


/** aggregate fields of "contract.agreement_af_status_count" */
export type ContractAgreementAfStatusCountAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ContractAgreementAfStatusCountSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contract.agreement_af_status_count" */
export type ContractAgreementAfStatusCountAggregateOrderBy = {
  avg?: Maybe<ContractAgreementAfStatusCountAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ContractAgreementAfStatusCountMaxOrderBy>;
  min?: Maybe<ContractAgreementAfStatusCountMinOrderBy>;
  stddev?: Maybe<ContractAgreementAfStatusCountStddevOrderBy>;
  stddev_pop?: Maybe<ContractAgreementAfStatusCountStddevPopOrderBy>;
  stddev_samp?: Maybe<ContractAgreementAfStatusCountStddevSampOrderBy>;
  sum?: Maybe<ContractAgreementAfStatusCountSumOrderBy>;
  var_pop?: Maybe<ContractAgreementAfStatusCountVarPopOrderBy>;
  var_samp?: Maybe<ContractAgreementAfStatusCountVarSampOrderBy>;
  variance?: Maybe<ContractAgreementAfStatusCountVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "contract.agreement_af_status_count" */
export type ContractAgreementAfStatusCountArrRelInsertInput = {
  data: Array<ContractAgreementAfStatusCountInsertInput>;
};

/** aggregate avg on columns */
export type ContractAgreementAfStatusCountAvgFields = {
  __typename?: 'contract_agreement_af_status_count_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contract.agreement_af_status_count" */
export type ContractAgreementAfStatusCountAvgOrderBy = {
  count?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "contract.agreement_af_status_count". All fields are combined with a logical 'AND'. */
export type ContractAgreementAfStatusCountBoolExp = {
  _and?: Maybe<Array<ContractAgreementAfStatusCountBoolExp>>;
  _not?: Maybe<ContractAgreementAfStatusCountBoolExp>;
  _or?: Maybe<Array<ContractAgreementAfStatusCountBoolExp>>;
  af_status?: Maybe<ContractAfStatusBoolExp>;
  af_status_description?: Maybe<StringComparisonExp>;
  af_status_id?: Maybe<StringComparisonExp>;
  bank_program_reference?: Maybe<StringComparisonExp>;
  count?: Maybe<BigintComparisonExp>;
  program?: Maybe<LoanProgramBoolExp>;
  program_id?: Maybe<UuidComparisonExp>;
};

/** input type for inserting data into table "contract.agreement_af_status_count" */
export type ContractAgreementAfStatusCountInsertInput = {
  af_status?: Maybe<ContractAfStatusObjRelInsertInput>;
  af_status_description?: Maybe<Scalars['String']>;
  af_status_id?: Maybe<Scalars['String']>;
  bank_program_reference?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['bigint']>;
  program?: Maybe<LoanProgramObjRelInsertInput>;
  program_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ContractAgreementAfStatusCountMaxFields = {
  __typename?: 'contract_agreement_af_status_count_max_fields';
  af_status_description?: Maybe<Scalars['String']>;
  af_status_id?: Maybe<Scalars['String']>;
  bank_program_reference?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['bigint']>;
  program_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "contract.agreement_af_status_count" */
export type ContractAgreementAfStatusCountMaxOrderBy = {
  af_status_description?: Maybe<OrderBy>;
  af_status_id?: Maybe<OrderBy>;
  bank_program_reference?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  program_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ContractAgreementAfStatusCountMinFields = {
  __typename?: 'contract_agreement_af_status_count_min_fields';
  af_status_description?: Maybe<Scalars['String']>;
  af_status_id?: Maybe<Scalars['String']>;
  bank_program_reference?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['bigint']>;
  program_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "contract.agreement_af_status_count" */
export type ContractAgreementAfStatusCountMinOrderBy = {
  af_status_description?: Maybe<OrderBy>;
  af_status_id?: Maybe<OrderBy>;
  bank_program_reference?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  program_id?: Maybe<OrderBy>;
};

/** Ordering options when selecting data from "contract.agreement_af_status_count". */
export type ContractAgreementAfStatusCountOrderBy = {
  af_status?: Maybe<ContractAfStatusOrderBy>;
  af_status_description?: Maybe<OrderBy>;
  af_status_id?: Maybe<OrderBy>;
  bank_program_reference?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  program?: Maybe<LoanProgramOrderBy>;
  program_id?: Maybe<OrderBy>;
};

/** select columns of table "contract.agreement_af_status_count" */
export enum ContractAgreementAfStatusCountSelectColumn {
  /** column name */
  AfStatusDescription = 'af_status_description',
  /** column name */
  AfStatusId = 'af_status_id',
  /** column name */
  BankProgramReference = 'bank_program_reference',
  /** column name */
  Count = 'count',
  /** column name */
  ProgramId = 'program_id'
}

/** aggregate stddev on columns */
export type ContractAgreementAfStatusCountStddevFields = {
  __typename?: 'contract_agreement_af_status_count_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contract.agreement_af_status_count" */
export type ContractAgreementAfStatusCountStddevOrderBy = {
  count?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ContractAgreementAfStatusCountStddevPopFields = {
  __typename?: 'contract_agreement_af_status_count_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contract.agreement_af_status_count" */
export type ContractAgreementAfStatusCountStddevPopOrderBy = {
  count?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ContractAgreementAfStatusCountStddevSampFields = {
  __typename?: 'contract_agreement_af_status_count_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contract.agreement_af_status_count" */
export type ContractAgreementAfStatusCountStddevSampOrderBy = {
  count?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ContractAgreementAfStatusCountSumFields = {
  __typename?: 'contract_agreement_af_status_count_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "contract.agreement_af_status_count" */
export type ContractAgreementAfStatusCountSumOrderBy = {
  count?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ContractAgreementAfStatusCountVarPopFields = {
  __typename?: 'contract_agreement_af_status_count_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contract.agreement_af_status_count" */
export type ContractAgreementAfStatusCountVarPopOrderBy = {
  count?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ContractAgreementAfStatusCountVarSampFields = {
  __typename?: 'contract_agreement_af_status_count_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contract.agreement_af_status_count" */
export type ContractAgreementAfStatusCountVarSampOrderBy = {
  count?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ContractAgreementAfStatusCountVarianceFields = {
  __typename?: 'contract_agreement_af_status_count_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contract.agreement_af_status_count" */
export type ContractAgreementAfStatusCountVarianceOrderBy = {
  count?: Maybe<OrderBy>;
};

/** aggregated selection of "contract.agreement" */
export type ContractAgreementAggregate = {
  __typename?: 'contract_agreement_aggregate';
  aggregate?: Maybe<ContractAgreementAggregateFields>;
  nodes: Array<ContractAgreement>;
};

/** aggregate fields of "contract.agreement" */
export type ContractAgreementAggregateFields = {
  __typename?: 'contract_agreement_aggregate_fields';
  avg?: Maybe<ContractAgreementAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ContractAgreementMaxFields>;
  min?: Maybe<ContractAgreementMinFields>;
  stddev?: Maybe<ContractAgreementStddevFields>;
  stddev_pop?: Maybe<ContractAgreementStddevPopFields>;
  stddev_samp?: Maybe<ContractAgreementStddevSampFields>;
  sum?: Maybe<ContractAgreementSumFields>;
  var_pop?: Maybe<ContractAgreementVarPopFields>;
  var_samp?: Maybe<ContractAgreementVarSampFields>;
  variance?: Maybe<ContractAgreementVarianceFields>;
};


/** aggregate fields of "contract.agreement" */
export type ContractAgreementAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ContractAgreementSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contract.agreement" */
export type ContractAgreementAggregateOrderBy = {
  avg?: Maybe<ContractAgreementAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ContractAgreementMaxOrderBy>;
  min?: Maybe<ContractAgreementMinOrderBy>;
  stddev?: Maybe<ContractAgreementStddevOrderBy>;
  stddev_pop?: Maybe<ContractAgreementStddevPopOrderBy>;
  stddev_samp?: Maybe<ContractAgreementStddevSampOrderBy>;
  sum?: Maybe<ContractAgreementSumOrderBy>;
  var_pop?: Maybe<ContractAgreementVarPopOrderBy>;
  var_samp?: Maybe<ContractAgreementVarSampOrderBy>;
  variance?: Maybe<ContractAgreementVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "contract.agreement" */
export type ContractAgreementArrRelInsertInput = {
  data: Array<ContractAgreementInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ContractAgreementOnConflict>;
};

/**
 * เอกสารแนบลูกหนี้
 *
 *
 * columns and relationships of "contract.agreement_attachment"
 */
export type ContractAgreementAttachment = {
  __typename?: 'contract_agreement_attachment';
  /** An object relationship */
  agreement: ContractAgreement;
  /** รหัสอ้างอิง agreement */
  agreement_id: Scalars['uuid'];
  /** An object relationship */
  attachment_type: ContractAttachmentType;
  /** วันที่บันทึกข้อมมูล */
  created_at: Scalars['timestamptz'];
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file_path?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  filename: Scalars['String'];
  /** Primary key */
  id: Scalars['uuid'];
  /** ประเภทไฟล์ */
  mimetype: Scalars['String'];
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  original_name: Scalars['String'];
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
  /** ประเภท */
  type_id: ContractAttachmentTypeEnum;
  /** วันที่แก้ไขข้อมูล */
  updated_at: Scalars['timestamptz'];
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "contract.agreement_attachment" */
export type ContractAgreementAttachmentAggregate = {
  __typename?: 'contract_agreement_attachment_aggregate';
  aggregate?: Maybe<ContractAgreementAttachmentAggregateFields>;
  nodes: Array<ContractAgreementAttachment>;
};

/** aggregate fields of "contract.agreement_attachment" */
export type ContractAgreementAttachmentAggregateFields = {
  __typename?: 'contract_agreement_attachment_aggregate_fields';
  avg?: Maybe<ContractAgreementAttachmentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ContractAgreementAttachmentMaxFields>;
  min?: Maybe<ContractAgreementAttachmentMinFields>;
  stddev?: Maybe<ContractAgreementAttachmentStddevFields>;
  stddev_pop?: Maybe<ContractAgreementAttachmentStddevPopFields>;
  stddev_samp?: Maybe<ContractAgreementAttachmentStddevSampFields>;
  sum?: Maybe<ContractAgreementAttachmentSumFields>;
  var_pop?: Maybe<ContractAgreementAttachmentVarPopFields>;
  var_samp?: Maybe<ContractAgreementAttachmentVarSampFields>;
  variance?: Maybe<ContractAgreementAttachmentVarianceFields>;
};


/** aggregate fields of "contract.agreement_attachment" */
export type ContractAgreementAttachmentAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ContractAgreementAttachmentSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contract.agreement_attachment" */
export type ContractAgreementAttachmentAggregateOrderBy = {
  avg?: Maybe<ContractAgreementAttachmentAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ContractAgreementAttachmentMaxOrderBy>;
  min?: Maybe<ContractAgreementAttachmentMinOrderBy>;
  stddev?: Maybe<ContractAgreementAttachmentStddevOrderBy>;
  stddev_pop?: Maybe<ContractAgreementAttachmentStddevPopOrderBy>;
  stddev_samp?: Maybe<ContractAgreementAttachmentStddevSampOrderBy>;
  sum?: Maybe<ContractAgreementAttachmentSumOrderBy>;
  var_pop?: Maybe<ContractAgreementAttachmentVarPopOrderBy>;
  var_samp?: Maybe<ContractAgreementAttachmentVarSampOrderBy>;
  variance?: Maybe<ContractAgreementAttachmentVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "contract.agreement_attachment" */
export type ContractAgreementAttachmentArrRelInsertInput = {
  data: Array<ContractAgreementAttachmentInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ContractAgreementAttachmentOnConflict>;
};

/** aggregate avg on columns */
export type ContractAgreementAttachmentAvgFields = {
  __typename?: 'contract_agreement_attachment_avg_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contract.agreement_attachment" */
export type ContractAgreementAttachmentAvgOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "contract.agreement_attachment". All fields are combined with a logical 'AND'. */
export type ContractAgreementAttachmentBoolExp = {
  _and?: Maybe<Array<ContractAgreementAttachmentBoolExp>>;
  _not?: Maybe<ContractAgreementAttachmentBoolExp>;
  _or?: Maybe<Array<ContractAgreementAttachmentBoolExp>>;
  agreement?: Maybe<ContractAgreementBoolExp>;
  agreement_id?: Maybe<UuidComparisonExp>;
  attachment_type?: Maybe<ContractAttachmentTypeBoolExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  file_path?: Maybe<StringComparisonExp>;
  filename?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  mimetype?: Maybe<StringComparisonExp>;
  note?: Maybe<StringComparisonExp>;
  original_name?: Maybe<StringComparisonExp>;
  size?: Maybe<NumericComparisonExp>;
  type_id?: Maybe<ContractAttachmentTypeEnumComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "contract.agreement_attachment" */
export enum ContractAgreementAttachmentConstraint {
  /** unique or primary key constraint */
  AgreementAttachmentPkey = 'agreement_attachment_pkey'
}

/** input type for incrementing numeric columns in table "contract.agreement_attachment" */
export type ContractAgreementAttachmentIncInput = {
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "contract.agreement_attachment" */
export type ContractAgreementAttachmentInsertInput = {
  agreement?: Maybe<ContractAgreementObjRelInsertInput>;
  /** รหัสอ้างอิง agreement */
  agreement_id?: Maybe<Scalars['uuid']>;
  attachment_type?: Maybe<ContractAttachmentTypeObjRelInsertInput>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file_path?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  filename?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  original_name?: Maybe<Scalars['String']>;
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
  /** ประเภท */
  type_id?: Maybe<ContractAttachmentTypeEnum>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ContractAgreementAttachmentMaxFields = {
  __typename?: 'contract_agreement_attachment_max_fields';
  /** รหัสอ้างอิง agreement */
  agreement_id?: Maybe<Scalars['uuid']>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file_path?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  filename?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  original_name?: Maybe<Scalars['String']>;
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "contract.agreement_attachment" */
export type ContractAgreementAttachmentMaxOrderBy = {
  /** รหัสอ้างอิง agreement */
  agreement_id?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<OrderBy>;
  file_path?: Maybe<OrderBy>;
  /** ชื่อไฟล์ */
  filename?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<OrderBy>;
  /** หมายเหตุ */
  note?: Maybe<OrderBy>;
  /** ชื่อไฟล์ */
  original_name?: Maybe<OrderBy>;
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ContractAgreementAttachmentMinFields = {
  __typename?: 'contract_agreement_attachment_min_fields';
  /** รหัสอ้างอิง agreement */
  agreement_id?: Maybe<Scalars['uuid']>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file_path?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  filename?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  original_name?: Maybe<Scalars['String']>;
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "contract.agreement_attachment" */
export type ContractAgreementAttachmentMinOrderBy = {
  /** รหัสอ้างอิง agreement */
  agreement_id?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<OrderBy>;
  file_path?: Maybe<OrderBy>;
  /** ชื่อไฟล์ */
  filename?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<OrderBy>;
  /** หมายเหตุ */
  note?: Maybe<OrderBy>;
  /** ชื่อไฟล์ */
  original_name?: Maybe<OrderBy>;
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "contract.agreement_attachment" */
export type ContractAgreementAttachmentMutationResponse = {
  __typename?: 'contract_agreement_attachment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContractAgreementAttachment>;
};

/** on conflict condition type for table "contract.agreement_attachment" */
export type ContractAgreementAttachmentOnConflict = {
  constraint: ContractAgreementAttachmentConstraint;
  update_columns?: Array<ContractAgreementAttachmentUpdateColumn>;
  where?: Maybe<ContractAgreementAttachmentBoolExp>;
};

/** Ordering options when selecting data from "contract.agreement_attachment". */
export type ContractAgreementAttachmentOrderBy = {
  agreement?: Maybe<ContractAgreementOrderBy>;
  agreement_id?: Maybe<OrderBy>;
  attachment_type?: Maybe<ContractAttachmentTypeOrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  file_path?: Maybe<OrderBy>;
  filename?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mimetype?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  original_name?: Maybe<OrderBy>;
  size?: Maybe<OrderBy>;
  type_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** primary key columns input for table: contract_agreement_attachment */
export type ContractAgreementAttachmentPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "contract.agreement_attachment" */
export enum ContractAgreementAttachmentSelectColumn {
  /** column name */
  AgreementId = 'agreement_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Mimetype = 'mimetype',
  /** column name */
  Note = 'note',
  /** column name */
  OriginalName = 'original_name',
  /** column name */
  Size = 'size',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "contract.agreement_attachment" */
export type ContractAgreementAttachmentSetInput = {
  /** รหัสอ้างอิง agreement */
  agreement_id?: Maybe<Scalars['uuid']>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file_path?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  filename?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  original_name?: Maybe<Scalars['String']>;
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
  /** ประเภท */
  type_id?: Maybe<ContractAttachmentTypeEnum>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ContractAgreementAttachmentStddevFields = {
  __typename?: 'contract_agreement_attachment_stddev_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contract.agreement_attachment" */
export type ContractAgreementAttachmentStddevOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ContractAgreementAttachmentStddevPopFields = {
  __typename?: 'contract_agreement_attachment_stddev_pop_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contract.agreement_attachment" */
export type ContractAgreementAttachmentStddevPopOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ContractAgreementAttachmentStddevSampFields = {
  __typename?: 'contract_agreement_attachment_stddev_samp_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contract.agreement_attachment" */
export type ContractAgreementAttachmentStddevSampOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ContractAgreementAttachmentSumFields = {
  __typename?: 'contract_agreement_attachment_sum_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "contract.agreement_attachment" */
export type ContractAgreementAttachmentSumOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** update columns of table "contract.agreement_attachment" */
export enum ContractAgreementAttachmentUpdateColumn {
  /** column name */
  AgreementId = 'agreement_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Mimetype = 'mimetype',
  /** column name */
  Note = 'note',
  /** column name */
  OriginalName = 'original_name',
  /** column name */
  Size = 'size',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type ContractAgreementAttachmentVarPopFields = {
  __typename?: 'contract_agreement_attachment_var_pop_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contract.agreement_attachment" */
export type ContractAgreementAttachmentVarPopOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ContractAgreementAttachmentVarSampFields = {
  __typename?: 'contract_agreement_attachment_var_samp_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contract.agreement_attachment" */
export type ContractAgreementAttachmentVarSampOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ContractAgreementAttachmentVarianceFields = {
  __typename?: 'contract_agreement_attachment_variance_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contract.agreement_attachment" */
export type ContractAgreementAttachmentVarianceOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** aggregate avg on columns */
export type ContractAgreementAvgFields = {
  __typename?: 'contract_agreement_avg_fields';
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<Scalars['Float']>;
  /** DDamt */
  d_damt?: Maybe<Scalars['Float']>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<Scalars['Float']>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contract.agreement" */
export type ContractAgreementAvgOrderBy = {
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<OrderBy>;
  /** DDamt */
  d_damt?: Maybe<OrderBy>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<OrderBy>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "contract.agreement". All fields are combined with a logical 'AND'. */
export type ContractAgreementBoolExp = {
  _and?: Maybe<Array<ContractAgreementBoolExp>>;
  _not?: Maybe<ContractAgreementBoolExp>;
  _or?: Maybe<Array<ContractAgreementBoolExp>>;
  address?: Maybe<StringComparisonExp>;
  af_number?: Maybe<StringComparisonExp>;
  af_status?: Maybe<StringComparisonExp>;
  af_status_description?: Maybe<StringComparisonExp>;
  af_status_name?: Maybe<StringComparisonExp>;
  agreement_attachments?: Maybe<ContractAgreementAttachmentBoolExp>;
  agreement_detail?: Maybe<ContractAgreementDetailBoolExp>;
  agreement_details?: Maybe<ContractAgreementDetailBoolExp>;
  agreement_disbursements?: Maybe<ContractAgreementDisbursementBoolExp>;
  agreement_fee_charges?: Maybe<ContractAgreementFeeChargeBoolExp>;
  agreement_guarantors?: Maybe<ContractAgreementGuarantorBoolExp>;
  agreement_receipts?: Maybe<ContractAgreementReceiptBoolExp>;
  agreement_transactions?: Maybe<ContractAgreementTransactionBoolExp>;
  agreement_withdraws?: Maybe<ContractAgreementWithdrawBoolExp>;
  approved_date?: Maybe<DateComparisonExp>;
  bank_branch?: Maybe<BankBranchBoolExp>;
  bank_branch_id?: Maybe<StringComparisonExp>;
  business_group?: Maybe<StringComparisonExp>;
  business_type?: Maybe<StringComparisonExp>;
  cf_number?: Maybe<StringComparisonExp>;
  contact_date?: Maybe<DateComparisonExp>;
  contact_email?: Maybe<StringComparisonExp>;
  contact_fax?: Maybe<StringComparisonExp>;
  contact_first_name?: Maybe<StringComparisonExp>;
  contact_last_name?: Maybe<StringComparisonExp>;
  contact_mobile?: Maybe<StringComparisonExp>;
  contact_tel?: Maybe<StringComparisonExp>;
  contract_number?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  credit_total?: Maybe<NumericComparisonExp>;
  customer_type?: Maybe<StringComparisonExp>;
  d_damt?: Maybe<NumericComparisonExp>;
  effective_date?: Maybe<DateComparisonExp>;
  first_repay_date?: Maybe<DateComparisonExp>;
  first_withdraw_date?: Maybe<DateComparisonExp>;
  grace_period?: Maybe<NumericComparisonExp>;
  guarantee_type?: Maybe<StringComparisonExp>;
  guarantor_citizen_id?: Maybe<StringComparisonExp>;
  guarantor_description?: Maybe<StringComparisonExp>;
  guarantor_name?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  imported_at?: Maybe<TimestamptzComparisonExp>;
  insurance_due_date?: Maybe<DateComparisonExp>;
  juristic?: Maybe<JuristicBoolExp>;
  juristic_id?: Maybe<StringComparisonExp>;
  juristic_name_en?: Maybe<StringComparisonExp>;
  juristic_name_th?: Maybe<StringComparisonExp>;
  loan_description?: Maybe<StringComparisonExp>;
  monthly_payment?: Maybe<NumericComparisonExp>;
  objective?: Maybe<StringComparisonExp>;
  period?: Maybe<StringComparisonExp>;
  program?: Maybe<LoanProgramBoolExp>;
  program_id?: Maybe<UuidComparisonExp>;
  program_name?: Maybe<StringComparisonExp>;
  program_reference?: Maybe<StringComparisonExp>;
  project_description?: Maybe<StringComparisonExp>;
  register_number?: Maybe<StringComparisonExp>;
  repay_current_month?: Maybe<ContractAgreementRepayCurrentMonthBoolExp>;
  repay_day_of_month?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "contract.agreement" */
export enum ContractAgreementConstraint {
  /** unique or primary key constraint */
  AgreementContractNumberKey = 'agreement_contract_number_key',
  /** unique or primary key constraint */
  AgreementContractNumberRegisterNumberKey = 'agreement_contract_number_register_number_key',
  /** unique or primary key constraint */
  AgreementPkey = 'agreement_pkey'
}

/**
 * อนุมัติ-ทำสัญญา-เบิกจ่าย กองทุน
 *
 *
 * columns and relationships of "contract.agreement_detail"
 */
export type ContractAgreementDetail = {
  __typename?: 'contract_agreement_detail';
  /** เลขที่ใบคำขอกู้ */
  af_number?: Maybe<Scalars['String']>;
  /** สถานะใบคำขอกู้ */
  af_status?: Maybe<Scalars['String']>;
  /** สถานะใบคำขอกู้ */
  af_status_description?: Maybe<Scalars['String']>;
  /** An object relationship */
  agreement?: Maybe<ContractAgreement>;
  /** An object relationship */
  agreement_by_contract_number: ContractAgreement;
  /** วันที่อนุมัติ */
  approved_date?: Maybe<Scalars['date']>;
  /** ประเภทธุรกิจ */
  business_type?: Maybe<Scalars['String']>;
  /** เลขที่ติดต่อ */
  cf_number?: Maybe<Scalars['String']>;
  /** วันที่ปิดวงเงิน */
  closing_date?: Maybe<Scalars['date']>;
  /** วันที่ติดต่อ */
  contact_date?: Maybe<Scalars['date']>;
  /** เลขที่สัญญา */
  contract_number: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** วันที่ลงนามในสัญญา */
  effective_date?: Maybe<Scalars['date']>;
  /** วันที่เบิกครั้งแรก */
  first_withdraw_date?: Maybe<Scalars['date']>;
  /** FL */
  fl?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** อัตราดอกเบี้ย */
  interest_description?: Maybe<Scalars['String']>;
  /** ISICDetatil */
  isic_description?: Maybe<Scalars['String']>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<Scalars['numeric']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['numeric']>;
  /** ทะเบียนลูกค้า */
  register_number: Scalars['String'];
  /** วันที่ขอกู้ */
  request_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "contract.agreement_detail" */
export type ContractAgreementDetailAggregate = {
  __typename?: 'contract_agreement_detail_aggregate';
  aggregate?: Maybe<ContractAgreementDetailAggregateFields>;
  nodes: Array<ContractAgreementDetail>;
};

/** aggregate fields of "contract.agreement_detail" */
export type ContractAgreementDetailAggregateFields = {
  __typename?: 'contract_agreement_detail_aggregate_fields';
  avg?: Maybe<ContractAgreementDetailAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ContractAgreementDetailMaxFields>;
  min?: Maybe<ContractAgreementDetailMinFields>;
  stddev?: Maybe<ContractAgreementDetailStddevFields>;
  stddev_pop?: Maybe<ContractAgreementDetailStddevPopFields>;
  stddev_samp?: Maybe<ContractAgreementDetailStddevSampFields>;
  sum?: Maybe<ContractAgreementDetailSumFields>;
  var_pop?: Maybe<ContractAgreementDetailVarPopFields>;
  var_samp?: Maybe<ContractAgreementDetailVarSampFields>;
  variance?: Maybe<ContractAgreementDetailVarianceFields>;
};


/** aggregate fields of "contract.agreement_detail" */
export type ContractAgreementDetailAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ContractAgreementDetailSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contract.agreement_detail" */
export type ContractAgreementDetailAggregateOrderBy = {
  avg?: Maybe<ContractAgreementDetailAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ContractAgreementDetailMaxOrderBy>;
  min?: Maybe<ContractAgreementDetailMinOrderBy>;
  stddev?: Maybe<ContractAgreementDetailStddevOrderBy>;
  stddev_pop?: Maybe<ContractAgreementDetailStddevPopOrderBy>;
  stddev_samp?: Maybe<ContractAgreementDetailStddevSampOrderBy>;
  sum?: Maybe<ContractAgreementDetailSumOrderBy>;
  var_pop?: Maybe<ContractAgreementDetailVarPopOrderBy>;
  var_samp?: Maybe<ContractAgreementDetailVarSampOrderBy>;
  variance?: Maybe<ContractAgreementDetailVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "contract.agreement_detail" */
export type ContractAgreementDetailArrRelInsertInput = {
  data: Array<ContractAgreementDetailInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ContractAgreementDetailOnConflict>;
};

/** aggregate avg on columns */
export type ContractAgreementDetailAvgFields = {
  __typename?: 'contract_agreement_detail_avg_fields';
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<Scalars['Float']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['Float']>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contract.agreement_detail" */
export type ContractAgreementDetailAvgOrderBy = {
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<OrderBy>;
  /** ระยะเวลากู้ */
  period?: Maybe<OrderBy>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "contract.agreement_detail". All fields are combined with a logical 'AND'. */
export type ContractAgreementDetailBoolExp = {
  _and?: Maybe<Array<ContractAgreementDetailBoolExp>>;
  _not?: Maybe<ContractAgreementDetailBoolExp>;
  _or?: Maybe<Array<ContractAgreementDetailBoolExp>>;
  af_number?: Maybe<StringComparisonExp>;
  af_status?: Maybe<StringComparisonExp>;
  af_status_description?: Maybe<StringComparisonExp>;
  agreement?: Maybe<ContractAgreementBoolExp>;
  agreement_by_contract_number?: Maybe<ContractAgreementBoolExp>;
  approved_date?: Maybe<DateComparisonExp>;
  business_type?: Maybe<StringComparisonExp>;
  cf_number?: Maybe<StringComparisonExp>;
  closing_date?: Maybe<DateComparisonExp>;
  contact_date?: Maybe<DateComparisonExp>;
  contract_number?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  credit_balance?: Maybe<NumericComparisonExp>;
  credit_total?: Maybe<NumericComparisonExp>;
  effective_date?: Maybe<DateComparisonExp>;
  first_withdraw_date?: Maybe<DateComparisonExp>;
  fl?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  imported_at?: Maybe<TimestamptzComparisonExp>;
  interest_description?: Maybe<StringComparisonExp>;
  isic_description?: Maybe<StringComparisonExp>;
  need_credit?: Maybe<NumericComparisonExp>;
  period?: Maybe<NumericComparisonExp>;
  register_number?: Maybe<StringComparisonExp>;
  request_date?: Maybe<DateComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  withdraw_total?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "contract.agreement_detail" */
export enum ContractAgreementDetailConstraint {
  /** unique or primary key constraint */
  AgreementDetailContractNumberRegisterNumberKey = 'agreement_detail_contract_number_register_number_key',
  /** unique or primary key constraint */
  AgreementDetailPkey = 'agreement_detail_pkey'
}

/** input type for incrementing numeric columns in table "contract.agreement_detail" */
export type ContractAgreementDetailIncInput = {
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<Scalars['numeric']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['numeric']>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "contract.agreement_detail" */
export type ContractAgreementDetailInsertInput = {
  /** เลขที่ใบคำขอกู้ */
  af_number?: Maybe<Scalars['String']>;
  /** สถานะใบคำขอกู้ */
  af_status?: Maybe<Scalars['String']>;
  /** สถานะใบคำขอกู้ */
  af_status_description?: Maybe<Scalars['String']>;
  agreement?: Maybe<ContractAgreementObjRelInsertInput>;
  agreement_by_contract_number?: Maybe<ContractAgreementObjRelInsertInput>;
  /** วันที่อนุมัติ */
  approved_date?: Maybe<Scalars['date']>;
  /** ประเภทธุรกิจ */
  business_type?: Maybe<Scalars['String']>;
  /** เลขที่ติดต่อ */
  cf_number?: Maybe<Scalars['String']>;
  /** วันที่ปิดวงเงิน */
  closing_date?: Maybe<Scalars['date']>;
  /** วันที่ติดต่อ */
  contact_date?: Maybe<Scalars['date']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** วันที่ลงนามในสัญญา */
  effective_date?: Maybe<Scalars['date']>;
  /** วันที่เบิกครั้งแรก */
  first_withdraw_date?: Maybe<Scalars['date']>;
  /** FL */
  fl?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** อัตราดอกเบี้ย */
  interest_description?: Maybe<Scalars['String']>;
  /** ISICDetatil */
  isic_description?: Maybe<Scalars['String']>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<Scalars['numeric']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['numeric']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** วันที่ขอกู้ */
  request_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type ContractAgreementDetailMaxFields = {
  __typename?: 'contract_agreement_detail_max_fields';
  /** เลขที่ใบคำขอกู้ */
  af_number?: Maybe<Scalars['String']>;
  /** สถานะใบคำขอกู้ */
  af_status?: Maybe<Scalars['String']>;
  /** สถานะใบคำขอกู้ */
  af_status_description?: Maybe<Scalars['String']>;
  /** วันที่อนุมัติ */
  approved_date?: Maybe<Scalars['date']>;
  /** ประเภทธุรกิจ */
  business_type?: Maybe<Scalars['String']>;
  /** เลขที่ติดต่อ */
  cf_number?: Maybe<Scalars['String']>;
  /** วันที่ปิดวงเงิน */
  closing_date?: Maybe<Scalars['date']>;
  /** วันที่ติดต่อ */
  contact_date?: Maybe<Scalars['date']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** วันที่ลงนามในสัญญา */
  effective_date?: Maybe<Scalars['date']>;
  /** วันที่เบิกครั้งแรก */
  first_withdraw_date?: Maybe<Scalars['date']>;
  /** FL */
  fl?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** อัตราดอกเบี้ย */
  interest_description?: Maybe<Scalars['String']>;
  /** ISICDetatil */
  isic_description?: Maybe<Scalars['String']>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<Scalars['numeric']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['numeric']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** วันที่ขอกู้ */
  request_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "contract.agreement_detail" */
export type ContractAgreementDetailMaxOrderBy = {
  /** เลขที่ใบคำขอกู้ */
  af_number?: Maybe<OrderBy>;
  /** สถานะใบคำขอกู้ */
  af_status?: Maybe<OrderBy>;
  /** สถานะใบคำขอกู้ */
  af_status_description?: Maybe<OrderBy>;
  /** วันที่อนุมัติ */
  approved_date?: Maybe<OrderBy>;
  /** ประเภทธุรกิจ */
  business_type?: Maybe<OrderBy>;
  /** เลขที่ติดต่อ */
  cf_number?: Maybe<OrderBy>;
  /** วันที่ปิดวงเงิน */
  closing_date?: Maybe<OrderBy>;
  /** วันที่ติดต่อ */
  contact_date?: Maybe<OrderBy>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** วันที่ลงนามในสัญญา */
  effective_date?: Maybe<OrderBy>;
  /** วันที่เบิกครั้งแรก */
  first_withdraw_date?: Maybe<OrderBy>;
  /** FL */
  fl?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  imported_at?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ย */
  interest_description?: Maybe<OrderBy>;
  /** ISICDetatil */
  isic_description?: Maybe<OrderBy>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<OrderBy>;
  /** ระยะเวลากู้ */
  period?: Maybe<OrderBy>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<OrderBy>;
  /** วันที่ขอกู้ */
  request_date?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ContractAgreementDetailMinFields = {
  __typename?: 'contract_agreement_detail_min_fields';
  /** เลขที่ใบคำขอกู้ */
  af_number?: Maybe<Scalars['String']>;
  /** สถานะใบคำขอกู้ */
  af_status?: Maybe<Scalars['String']>;
  /** สถานะใบคำขอกู้ */
  af_status_description?: Maybe<Scalars['String']>;
  /** วันที่อนุมัติ */
  approved_date?: Maybe<Scalars['date']>;
  /** ประเภทธุรกิจ */
  business_type?: Maybe<Scalars['String']>;
  /** เลขที่ติดต่อ */
  cf_number?: Maybe<Scalars['String']>;
  /** วันที่ปิดวงเงิน */
  closing_date?: Maybe<Scalars['date']>;
  /** วันที่ติดต่อ */
  contact_date?: Maybe<Scalars['date']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** วันที่ลงนามในสัญญา */
  effective_date?: Maybe<Scalars['date']>;
  /** วันที่เบิกครั้งแรก */
  first_withdraw_date?: Maybe<Scalars['date']>;
  /** FL */
  fl?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** อัตราดอกเบี้ย */
  interest_description?: Maybe<Scalars['String']>;
  /** ISICDetatil */
  isic_description?: Maybe<Scalars['String']>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<Scalars['numeric']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['numeric']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** วันที่ขอกู้ */
  request_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "contract.agreement_detail" */
export type ContractAgreementDetailMinOrderBy = {
  /** เลขที่ใบคำขอกู้ */
  af_number?: Maybe<OrderBy>;
  /** สถานะใบคำขอกู้ */
  af_status?: Maybe<OrderBy>;
  /** สถานะใบคำขอกู้ */
  af_status_description?: Maybe<OrderBy>;
  /** วันที่อนุมัติ */
  approved_date?: Maybe<OrderBy>;
  /** ประเภทธุรกิจ */
  business_type?: Maybe<OrderBy>;
  /** เลขที่ติดต่อ */
  cf_number?: Maybe<OrderBy>;
  /** วันที่ปิดวงเงิน */
  closing_date?: Maybe<OrderBy>;
  /** วันที่ติดต่อ */
  contact_date?: Maybe<OrderBy>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** วันที่ลงนามในสัญญา */
  effective_date?: Maybe<OrderBy>;
  /** วันที่เบิกครั้งแรก */
  first_withdraw_date?: Maybe<OrderBy>;
  /** FL */
  fl?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  imported_at?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ย */
  interest_description?: Maybe<OrderBy>;
  /** ISICDetatil */
  isic_description?: Maybe<OrderBy>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<OrderBy>;
  /** ระยะเวลากู้ */
  period?: Maybe<OrderBy>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<OrderBy>;
  /** วันที่ขอกู้ */
  request_date?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<OrderBy>;
};

/** response of any mutation on the table "contract.agreement_detail" */
export type ContractAgreementDetailMutationResponse = {
  __typename?: 'contract_agreement_detail_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContractAgreementDetail>;
};

/** input type for inserting object relation for remote table "contract.agreement_detail" */
export type ContractAgreementDetailObjRelInsertInput = {
  data: ContractAgreementDetailInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<ContractAgreementDetailOnConflict>;
};

/** on conflict condition type for table "contract.agreement_detail" */
export type ContractAgreementDetailOnConflict = {
  constraint: ContractAgreementDetailConstraint;
  update_columns?: Array<ContractAgreementDetailUpdateColumn>;
  where?: Maybe<ContractAgreementDetailBoolExp>;
};

/** Ordering options when selecting data from "contract.agreement_detail". */
export type ContractAgreementDetailOrderBy = {
  af_number?: Maybe<OrderBy>;
  af_status?: Maybe<OrderBy>;
  af_status_description?: Maybe<OrderBy>;
  agreement?: Maybe<ContractAgreementOrderBy>;
  agreement_by_contract_number?: Maybe<ContractAgreementOrderBy>;
  approved_date?: Maybe<OrderBy>;
  business_type?: Maybe<OrderBy>;
  cf_number?: Maybe<OrderBy>;
  closing_date?: Maybe<OrderBy>;
  contact_date?: Maybe<OrderBy>;
  contract_number?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  credit_balance?: Maybe<OrderBy>;
  credit_total?: Maybe<OrderBy>;
  effective_date?: Maybe<OrderBy>;
  first_withdraw_date?: Maybe<OrderBy>;
  fl?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  imported_at?: Maybe<OrderBy>;
  interest_description?: Maybe<OrderBy>;
  isic_description?: Maybe<OrderBy>;
  need_credit?: Maybe<OrderBy>;
  period?: Maybe<OrderBy>;
  register_number?: Maybe<OrderBy>;
  request_date?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  withdraw_total?: Maybe<OrderBy>;
};

/** primary key columns input for table: contract_agreement_detail */
export type ContractAgreementDetailPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "contract.agreement_detail" */
export enum ContractAgreementDetailSelectColumn {
  /** column name */
  AfNumber = 'af_number',
  /** column name */
  AfStatus = 'af_status',
  /** column name */
  AfStatusDescription = 'af_status_description',
  /** column name */
  ApprovedDate = 'approved_date',
  /** column name */
  BusinessType = 'business_type',
  /** column name */
  CfNumber = 'cf_number',
  /** column name */
  ClosingDate = 'closing_date',
  /** column name */
  ContactDate = 'contact_date',
  /** column name */
  ContractNumber = 'contract_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreditBalance = 'credit_balance',
  /** column name */
  CreditTotal = 'credit_total',
  /** column name */
  EffectiveDate = 'effective_date',
  /** column name */
  FirstWithdrawDate = 'first_withdraw_date',
  /** column name */
  Fl = 'fl',
  /** column name */
  Id = 'id',
  /** column name */
  ImportedAt = 'imported_at',
  /** column name */
  InterestDescription = 'interest_description',
  /** column name */
  IsicDescription = 'isic_description',
  /** column name */
  NeedCredit = 'need_credit',
  /** column name */
  Period = 'period',
  /** column name */
  RegisterNumber = 'register_number',
  /** column name */
  RequestDate = 'request_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WithdrawTotal = 'withdraw_total'
}

/** input type for updating data in table "contract.agreement_detail" */
export type ContractAgreementDetailSetInput = {
  /** เลขที่ใบคำขอกู้ */
  af_number?: Maybe<Scalars['String']>;
  /** สถานะใบคำขอกู้ */
  af_status?: Maybe<Scalars['String']>;
  /** สถานะใบคำขอกู้ */
  af_status_description?: Maybe<Scalars['String']>;
  /** วันที่อนุมัติ */
  approved_date?: Maybe<Scalars['date']>;
  /** ประเภทธุรกิจ */
  business_type?: Maybe<Scalars['String']>;
  /** เลขที่ติดต่อ */
  cf_number?: Maybe<Scalars['String']>;
  /** วันที่ปิดวงเงิน */
  closing_date?: Maybe<Scalars['date']>;
  /** วันที่ติดต่อ */
  contact_date?: Maybe<Scalars['date']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** วันที่ลงนามในสัญญา */
  effective_date?: Maybe<Scalars['date']>;
  /** วันที่เบิกครั้งแรก */
  first_withdraw_date?: Maybe<Scalars['date']>;
  /** FL */
  fl?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** อัตราดอกเบี้ย */
  interest_description?: Maybe<Scalars['String']>;
  /** ISICDetatil */
  isic_description?: Maybe<Scalars['String']>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<Scalars['numeric']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['numeric']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** วันที่ขอกู้ */
  request_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type ContractAgreementDetailStddevFields = {
  __typename?: 'contract_agreement_detail_stddev_fields';
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<Scalars['Float']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['Float']>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contract.agreement_detail" */
export type ContractAgreementDetailStddevOrderBy = {
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<OrderBy>;
  /** ระยะเวลากู้ */
  period?: Maybe<OrderBy>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ContractAgreementDetailStddevPopFields = {
  __typename?: 'contract_agreement_detail_stddev_pop_fields';
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<Scalars['Float']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['Float']>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contract.agreement_detail" */
export type ContractAgreementDetailStddevPopOrderBy = {
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<OrderBy>;
  /** ระยะเวลากู้ */
  period?: Maybe<OrderBy>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ContractAgreementDetailStddevSampFields = {
  __typename?: 'contract_agreement_detail_stddev_samp_fields';
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<Scalars['Float']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['Float']>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contract.agreement_detail" */
export type ContractAgreementDetailStddevSampOrderBy = {
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<OrderBy>;
  /** ระยะเวลากู้ */
  period?: Maybe<OrderBy>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ContractAgreementDetailSumFields = {
  __typename?: 'contract_agreement_detail_sum_fields';
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<Scalars['numeric']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['numeric']>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "contract.agreement_detail" */
export type ContractAgreementDetailSumOrderBy = {
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<OrderBy>;
  /** ระยะเวลากู้ */
  period?: Maybe<OrderBy>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<OrderBy>;
};

/** update columns of table "contract.agreement_detail" */
export enum ContractAgreementDetailUpdateColumn {
  /** column name */
  AfNumber = 'af_number',
  /** column name */
  AfStatus = 'af_status',
  /** column name */
  AfStatusDescription = 'af_status_description',
  /** column name */
  ApprovedDate = 'approved_date',
  /** column name */
  BusinessType = 'business_type',
  /** column name */
  CfNumber = 'cf_number',
  /** column name */
  ClosingDate = 'closing_date',
  /** column name */
  ContactDate = 'contact_date',
  /** column name */
  ContractNumber = 'contract_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreditBalance = 'credit_balance',
  /** column name */
  CreditTotal = 'credit_total',
  /** column name */
  EffectiveDate = 'effective_date',
  /** column name */
  FirstWithdrawDate = 'first_withdraw_date',
  /** column name */
  Fl = 'fl',
  /** column name */
  Id = 'id',
  /** column name */
  ImportedAt = 'imported_at',
  /** column name */
  InterestDescription = 'interest_description',
  /** column name */
  IsicDescription = 'isic_description',
  /** column name */
  NeedCredit = 'need_credit',
  /** column name */
  Period = 'period',
  /** column name */
  RegisterNumber = 'register_number',
  /** column name */
  RequestDate = 'request_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WithdrawTotal = 'withdraw_total'
}

/** aggregate var_pop on columns */
export type ContractAgreementDetailVarPopFields = {
  __typename?: 'contract_agreement_detail_var_pop_fields';
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<Scalars['Float']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['Float']>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contract.agreement_detail" */
export type ContractAgreementDetailVarPopOrderBy = {
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<OrderBy>;
  /** ระยะเวลากู้ */
  period?: Maybe<OrderBy>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ContractAgreementDetailVarSampFields = {
  __typename?: 'contract_agreement_detail_var_samp_fields';
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<Scalars['Float']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['Float']>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contract.agreement_detail" */
export type ContractAgreementDetailVarSampOrderBy = {
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<OrderBy>;
  /** ระยะเวลากู้ */
  period?: Maybe<OrderBy>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ContractAgreementDetailVarianceFields = {
  __typename?: 'contract_agreement_detail_variance_fields';
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<Scalars['Float']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['Float']>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contract.agreement_detail" */
export type ContractAgreementDetailVarianceOrderBy = {
  /** สินเชื่อค้างเบิก */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** วงเงินคำขอกู้ */
  need_credit?: Maybe<OrderBy>;
  /** ระยะเวลากู้ */
  period?: Maybe<OrderBy>;
  /** ยอดเบิกจ่าย */
  withdraw_total?: Maybe<OrderBy>;
};

/**
 * การเบิกเงินกู้
 *
 *
 * columns and relationships of "contract.agreement_disbursement"
 */
export type ContractAgreementDisbursement = {
  __typename?: 'contract_agreement_disbursement';
  /** An object relationship */
  agreement?: Maybe<ContractAgreement>;
  /** วันที่อนุมัติ */
  approved_date?: Maybe<Scalars['date']>;
  /** สาขา */
  bank_branch_name?: Maybe<Scalars['String']>;
  /** ฝ่ายงาน */
  bank_department_name?: Maybe<Scalars['String']>;
  /** เลขที่สัญญา */
  contract_number: Scalars['String'];
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<Scalars['numeric']>;
  /** วันที่เบิกเงินกู้ */
  disbursement_date: Scalars['date'];
  /** DrawDownNo */
  draw_down_no?: Maybe<Scalars['String']>;
  /** วันที่ครบกำหนดสัญญา */
  due_date?: Maybe<Scalars['date']>;
  /** วันที่ทำสัญญา */
  effective_date?: Maybe<Scalars['date']>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<Scalars['numeric']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** ชื่อ */
  juristic_name?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่สินเชื่อ */
  loan_officer?: Maybe<Scalars['String']>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<Scalars['numeric']>;
  /** ทะเบียนลูกค้า */
  register_number: Scalars['String'];
  /** วันที่ส่ง */
  sent_date?: Maybe<Scalars['date']>;
};

/** aggregated selection of "contract.agreement_disbursement" */
export type ContractAgreementDisbursementAggregate = {
  __typename?: 'contract_agreement_disbursement_aggregate';
  aggregate?: Maybe<ContractAgreementDisbursementAggregateFields>;
  nodes: Array<ContractAgreementDisbursement>;
};

/** aggregate fields of "contract.agreement_disbursement" */
export type ContractAgreementDisbursementAggregateFields = {
  __typename?: 'contract_agreement_disbursement_aggregate_fields';
  avg?: Maybe<ContractAgreementDisbursementAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ContractAgreementDisbursementMaxFields>;
  min?: Maybe<ContractAgreementDisbursementMinFields>;
  stddev?: Maybe<ContractAgreementDisbursementStddevFields>;
  stddev_pop?: Maybe<ContractAgreementDisbursementStddevPopFields>;
  stddev_samp?: Maybe<ContractAgreementDisbursementStddevSampFields>;
  sum?: Maybe<ContractAgreementDisbursementSumFields>;
  var_pop?: Maybe<ContractAgreementDisbursementVarPopFields>;
  var_samp?: Maybe<ContractAgreementDisbursementVarSampFields>;
  variance?: Maybe<ContractAgreementDisbursementVarianceFields>;
};


/** aggregate fields of "contract.agreement_disbursement" */
export type ContractAgreementDisbursementAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ContractAgreementDisbursementSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contract.agreement_disbursement" */
export type ContractAgreementDisbursementAggregateOrderBy = {
  avg?: Maybe<ContractAgreementDisbursementAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ContractAgreementDisbursementMaxOrderBy>;
  min?: Maybe<ContractAgreementDisbursementMinOrderBy>;
  stddev?: Maybe<ContractAgreementDisbursementStddevOrderBy>;
  stddev_pop?: Maybe<ContractAgreementDisbursementStddevPopOrderBy>;
  stddev_samp?: Maybe<ContractAgreementDisbursementStddevSampOrderBy>;
  sum?: Maybe<ContractAgreementDisbursementSumOrderBy>;
  var_pop?: Maybe<ContractAgreementDisbursementVarPopOrderBy>;
  var_samp?: Maybe<ContractAgreementDisbursementVarSampOrderBy>;
  variance?: Maybe<ContractAgreementDisbursementVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "contract.agreement_disbursement" */
export type ContractAgreementDisbursementArrRelInsertInput = {
  data: Array<ContractAgreementDisbursementInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ContractAgreementDisbursementOnConflict>;
};

/** aggregate avg on columns */
export type ContractAgreementDisbursementAvgFields = {
  __typename?: 'contract_agreement_disbursement_avg_fields';
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<Scalars['Float']>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<Scalars['Float']>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contract.agreement_disbursement" */
export type ContractAgreementDisbursementAvgOrderBy = {
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<OrderBy>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<OrderBy>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "contract.agreement_disbursement". All fields are combined with a logical 'AND'. */
export type ContractAgreementDisbursementBoolExp = {
  _and?: Maybe<Array<ContractAgreementDisbursementBoolExp>>;
  _not?: Maybe<ContractAgreementDisbursementBoolExp>;
  _or?: Maybe<Array<ContractAgreementDisbursementBoolExp>>;
  agreement?: Maybe<ContractAgreementBoolExp>;
  approved_date?: Maybe<DateComparisonExp>;
  bank_branch_name?: Maybe<StringComparisonExp>;
  bank_department_name?: Maybe<StringComparisonExp>;
  contract_number?: Maybe<StringComparisonExp>;
  credit_balance?: Maybe<NumericComparisonExp>;
  credit_total?: Maybe<NumericComparisonExp>;
  disbursement_amount?: Maybe<NumericComparisonExp>;
  disbursement_date?: Maybe<DateComparisonExp>;
  draw_down_no?: Maybe<StringComparisonExp>;
  due_date?: Maybe<DateComparisonExp>;
  effective_date?: Maybe<DateComparisonExp>;
  fee?: Maybe<NumericComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  juristic_name?: Maybe<StringComparisonExp>;
  loan_officer?: Maybe<StringComparisonExp>;
  net_disbursement_amount?: Maybe<NumericComparisonExp>;
  register_number?: Maybe<StringComparisonExp>;
  sent_date?: Maybe<DateComparisonExp>;
};

/** unique or primary key constraints on table "contract.agreement_disbursement" */
export enum ContractAgreementDisbursementConstraint {
  /** unique or primary key constraint */
  AgreementDisbursementContractNumberRegisterNumberDisburse = 'agreement_disbursement_contract_number_register_number_disburse',
  /** unique or primary key constraint */
  AgreementDisbursementPkey = 'agreement_disbursement_pkey'
}

/** input type for incrementing numeric columns in table "contract.agreement_disbursement" */
export type ContractAgreementDisbursementIncInput = {
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<Scalars['numeric']>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<Scalars['numeric']>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "contract.agreement_disbursement" */
export type ContractAgreementDisbursementInsertInput = {
  agreement?: Maybe<ContractAgreementObjRelInsertInput>;
  /** วันที่อนุมัติ */
  approved_date?: Maybe<Scalars['date']>;
  /** สาขา */
  bank_branch_name?: Maybe<Scalars['String']>;
  /** ฝ่ายงาน */
  bank_department_name?: Maybe<Scalars['String']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<Scalars['numeric']>;
  /** วันที่เบิกเงินกู้ */
  disbursement_date?: Maybe<Scalars['date']>;
  /** DrawDownNo */
  draw_down_no?: Maybe<Scalars['String']>;
  /** วันที่ครบกำหนดสัญญา */
  due_date?: Maybe<Scalars['date']>;
  /** วันที่ทำสัญญา */
  effective_date?: Maybe<Scalars['date']>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<Scalars['numeric']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อ */
  juristic_name?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่สินเชื่อ */
  loan_officer?: Maybe<Scalars['String']>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<Scalars['numeric']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** วันที่ส่ง */
  sent_date?: Maybe<Scalars['date']>;
};

/** aggregate max on columns */
export type ContractAgreementDisbursementMaxFields = {
  __typename?: 'contract_agreement_disbursement_max_fields';
  /** วันที่อนุมัติ */
  approved_date?: Maybe<Scalars['date']>;
  /** สาขา */
  bank_branch_name?: Maybe<Scalars['String']>;
  /** ฝ่ายงาน */
  bank_department_name?: Maybe<Scalars['String']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<Scalars['numeric']>;
  /** วันที่เบิกเงินกู้ */
  disbursement_date?: Maybe<Scalars['date']>;
  /** DrawDownNo */
  draw_down_no?: Maybe<Scalars['String']>;
  /** วันที่ครบกำหนดสัญญา */
  due_date?: Maybe<Scalars['date']>;
  /** วันที่ทำสัญญา */
  effective_date?: Maybe<Scalars['date']>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<Scalars['numeric']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อ */
  juristic_name?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่สินเชื่อ */
  loan_officer?: Maybe<Scalars['String']>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<Scalars['numeric']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** วันที่ส่ง */
  sent_date?: Maybe<Scalars['date']>;
};

/** order by max() on columns of table "contract.agreement_disbursement" */
export type ContractAgreementDisbursementMaxOrderBy = {
  /** วันที่อนุมัติ */
  approved_date?: Maybe<OrderBy>;
  /** สาขา */
  bank_branch_name?: Maybe<OrderBy>;
  /** ฝ่ายงาน */
  bank_department_name?: Maybe<OrderBy>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<OrderBy>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<OrderBy>;
  /** วันที่เบิกเงินกู้ */
  disbursement_date?: Maybe<OrderBy>;
  /** DrawDownNo */
  draw_down_no?: Maybe<OrderBy>;
  /** วันที่ครบกำหนดสัญญา */
  due_date?: Maybe<OrderBy>;
  /** วันที่ทำสัญญา */
  effective_date?: Maybe<OrderBy>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ชื่อ */
  juristic_name?: Maybe<OrderBy>;
  /** เจ้าหน้าที่สินเชื่อ */
  loan_officer?: Maybe<OrderBy>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<OrderBy>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<OrderBy>;
  /** วันที่ส่ง */
  sent_date?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ContractAgreementDisbursementMinFields = {
  __typename?: 'contract_agreement_disbursement_min_fields';
  /** วันที่อนุมัติ */
  approved_date?: Maybe<Scalars['date']>;
  /** สาขา */
  bank_branch_name?: Maybe<Scalars['String']>;
  /** ฝ่ายงาน */
  bank_department_name?: Maybe<Scalars['String']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<Scalars['numeric']>;
  /** วันที่เบิกเงินกู้ */
  disbursement_date?: Maybe<Scalars['date']>;
  /** DrawDownNo */
  draw_down_no?: Maybe<Scalars['String']>;
  /** วันที่ครบกำหนดสัญญา */
  due_date?: Maybe<Scalars['date']>;
  /** วันที่ทำสัญญา */
  effective_date?: Maybe<Scalars['date']>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<Scalars['numeric']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อ */
  juristic_name?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่สินเชื่อ */
  loan_officer?: Maybe<Scalars['String']>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<Scalars['numeric']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** วันที่ส่ง */
  sent_date?: Maybe<Scalars['date']>;
};

/** order by min() on columns of table "contract.agreement_disbursement" */
export type ContractAgreementDisbursementMinOrderBy = {
  /** วันที่อนุมัติ */
  approved_date?: Maybe<OrderBy>;
  /** สาขา */
  bank_branch_name?: Maybe<OrderBy>;
  /** ฝ่ายงาน */
  bank_department_name?: Maybe<OrderBy>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<OrderBy>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<OrderBy>;
  /** วันที่เบิกเงินกู้ */
  disbursement_date?: Maybe<OrderBy>;
  /** DrawDownNo */
  draw_down_no?: Maybe<OrderBy>;
  /** วันที่ครบกำหนดสัญญา */
  due_date?: Maybe<OrderBy>;
  /** วันที่ทำสัญญา */
  effective_date?: Maybe<OrderBy>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ชื่อ */
  juristic_name?: Maybe<OrderBy>;
  /** เจ้าหน้าที่สินเชื่อ */
  loan_officer?: Maybe<OrderBy>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<OrderBy>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<OrderBy>;
  /** วันที่ส่ง */
  sent_date?: Maybe<OrderBy>;
};

/** response of any mutation on the table "contract.agreement_disbursement" */
export type ContractAgreementDisbursementMutationResponse = {
  __typename?: 'contract_agreement_disbursement_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContractAgreementDisbursement>;
};

/** on conflict condition type for table "contract.agreement_disbursement" */
export type ContractAgreementDisbursementOnConflict = {
  constraint: ContractAgreementDisbursementConstraint;
  update_columns?: Array<ContractAgreementDisbursementUpdateColumn>;
  where?: Maybe<ContractAgreementDisbursementBoolExp>;
};

/** Ordering options when selecting data from "contract.agreement_disbursement". */
export type ContractAgreementDisbursementOrderBy = {
  agreement?: Maybe<ContractAgreementOrderBy>;
  approved_date?: Maybe<OrderBy>;
  bank_branch_name?: Maybe<OrderBy>;
  bank_department_name?: Maybe<OrderBy>;
  contract_number?: Maybe<OrderBy>;
  credit_balance?: Maybe<OrderBy>;
  credit_total?: Maybe<OrderBy>;
  disbursement_amount?: Maybe<OrderBy>;
  disbursement_date?: Maybe<OrderBy>;
  draw_down_no?: Maybe<OrderBy>;
  due_date?: Maybe<OrderBy>;
  effective_date?: Maybe<OrderBy>;
  fee?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  juristic_name?: Maybe<OrderBy>;
  loan_officer?: Maybe<OrderBy>;
  net_disbursement_amount?: Maybe<OrderBy>;
  register_number?: Maybe<OrderBy>;
  sent_date?: Maybe<OrderBy>;
};

/** primary key columns input for table: contract_agreement_disbursement */
export type ContractAgreementDisbursementPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "contract.agreement_disbursement" */
export enum ContractAgreementDisbursementSelectColumn {
  /** column name */
  ApprovedDate = 'approved_date',
  /** column name */
  BankBranchName = 'bank_branch_name',
  /** column name */
  BankDepartmentName = 'bank_department_name',
  /** column name */
  ContractNumber = 'contract_number',
  /** column name */
  CreditBalance = 'credit_balance',
  /** column name */
  CreditTotal = 'credit_total',
  /** column name */
  DisbursementAmount = 'disbursement_amount',
  /** column name */
  DisbursementDate = 'disbursement_date',
  /** column name */
  DrawDownNo = 'draw_down_no',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  EffectiveDate = 'effective_date',
  /** column name */
  Fee = 'fee',
  /** column name */
  Id = 'id',
  /** column name */
  JuristicName = 'juristic_name',
  /** column name */
  LoanOfficer = 'loan_officer',
  /** column name */
  NetDisbursementAmount = 'net_disbursement_amount',
  /** column name */
  RegisterNumber = 'register_number',
  /** column name */
  SentDate = 'sent_date'
}

/** input type for updating data in table "contract.agreement_disbursement" */
export type ContractAgreementDisbursementSetInput = {
  /** วันที่อนุมัติ */
  approved_date?: Maybe<Scalars['date']>;
  /** สาขา */
  bank_branch_name?: Maybe<Scalars['String']>;
  /** ฝ่ายงาน */
  bank_department_name?: Maybe<Scalars['String']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<Scalars['numeric']>;
  /** วันที่เบิกเงินกู้ */
  disbursement_date?: Maybe<Scalars['date']>;
  /** DrawDownNo */
  draw_down_no?: Maybe<Scalars['String']>;
  /** วันที่ครบกำหนดสัญญา */
  due_date?: Maybe<Scalars['date']>;
  /** วันที่ทำสัญญา */
  effective_date?: Maybe<Scalars['date']>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<Scalars['numeric']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อ */
  juristic_name?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่สินเชื่อ */
  loan_officer?: Maybe<Scalars['String']>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<Scalars['numeric']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** วันที่ส่ง */
  sent_date?: Maybe<Scalars['date']>;
};

/** aggregate stddev on columns */
export type ContractAgreementDisbursementStddevFields = {
  __typename?: 'contract_agreement_disbursement_stddev_fields';
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<Scalars['Float']>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<Scalars['Float']>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contract.agreement_disbursement" */
export type ContractAgreementDisbursementStddevOrderBy = {
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<OrderBy>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<OrderBy>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ContractAgreementDisbursementStddevPopFields = {
  __typename?: 'contract_agreement_disbursement_stddev_pop_fields';
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<Scalars['Float']>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<Scalars['Float']>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contract.agreement_disbursement" */
export type ContractAgreementDisbursementStddevPopOrderBy = {
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<OrderBy>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<OrderBy>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ContractAgreementDisbursementStddevSampFields = {
  __typename?: 'contract_agreement_disbursement_stddev_samp_fields';
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<Scalars['Float']>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<Scalars['Float']>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contract.agreement_disbursement" */
export type ContractAgreementDisbursementStddevSampOrderBy = {
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<OrderBy>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<OrderBy>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ContractAgreementDisbursementSumFields = {
  __typename?: 'contract_agreement_disbursement_sum_fields';
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<Scalars['numeric']>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<Scalars['numeric']>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "contract.agreement_disbursement" */
export type ContractAgreementDisbursementSumOrderBy = {
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<OrderBy>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<OrderBy>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<OrderBy>;
};

/** update columns of table "contract.agreement_disbursement" */
export enum ContractAgreementDisbursementUpdateColumn {
  /** column name */
  ApprovedDate = 'approved_date',
  /** column name */
  BankBranchName = 'bank_branch_name',
  /** column name */
  BankDepartmentName = 'bank_department_name',
  /** column name */
  ContractNumber = 'contract_number',
  /** column name */
  CreditBalance = 'credit_balance',
  /** column name */
  CreditTotal = 'credit_total',
  /** column name */
  DisbursementAmount = 'disbursement_amount',
  /** column name */
  DisbursementDate = 'disbursement_date',
  /** column name */
  DrawDownNo = 'draw_down_no',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  EffectiveDate = 'effective_date',
  /** column name */
  Fee = 'fee',
  /** column name */
  Id = 'id',
  /** column name */
  JuristicName = 'juristic_name',
  /** column name */
  LoanOfficer = 'loan_officer',
  /** column name */
  NetDisbursementAmount = 'net_disbursement_amount',
  /** column name */
  RegisterNumber = 'register_number',
  /** column name */
  SentDate = 'sent_date'
}

/** aggregate var_pop on columns */
export type ContractAgreementDisbursementVarPopFields = {
  __typename?: 'contract_agreement_disbursement_var_pop_fields';
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<Scalars['Float']>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<Scalars['Float']>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contract.agreement_disbursement" */
export type ContractAgreementDisbursementVarPopOrderBy = {
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<OrderBy>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<OrderBy>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ContractAgreementDisbursementVarSampFields = {
  __typename?: 'contract_agreement_disbursement_var_samp_fields';
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<Scalars['Float']>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<Scalars['Float']>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contract.agreement_disbursement" */
export type ContractAgreementDisbursementVarSampOrderBy = {
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<OrderBy>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<OrderBy>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ContractAgreementDisbursementVarianceFields = {
  __typename?: 'contract_agreement_disbursement_variance_fields';
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<Scalars['Float']>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<Scalars['Float']>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contract.agreement_disbursement" */
export type ContractAgreementDisbursementVarianceOrderBy = {
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** จำนวนเงินเบิกจ่าย */
  disbursement_amount?: Maybe<OrderBy>;
  /** ค่าธรรมเนียม */
  fee?: Maybe<OrderBy>;
  /** จำนวนเงินสุทธิ */
  net_disbursement_amount?: Maybe<OrderBy>;
};

/**
 * ข้อมูลค่าธรรมเนียมต่างๆ
 *
 *
 * columns and relationships of "contract.agreement_fee_charge"
 */
export type ContractAgreementFeeCharge = {
  __typename?: 'contract_agreement_fee_charge';
  /** An object relationship */
  agreement?: Maybe<ContractAgreement>;
  /** จำนวน */
  amount: Scalars['numeric'];
  /** เลขที่สัญญา */
  contract_number: Scalars['String'];
  /** วันที่บันทึกข้อมมูล */
  created_at: Scalars['timestamptz'];
  /** วันที่ */
  date: Scalars['date'];
  /** คำอธิบาย */
  description: Scalars['String'];
  /** Primary key */
  id: Scalars['uuid'];
  /** รหัสลุกค้า */
  register_number: Scalars['String'];
  /** วันที่แก้ไขข้อมูล */
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "contract.agreement_fee_charge" */
export type ContractAgreementFeeChargeAggregate = {
  __typename?: 'contract_agreement_fee_charge_aggregate';
  aggregate?: Maybe<ContractAgreementFeeChargeAggregateFields>;
  nodes: Array<ContractAgreementFeeCharge>;
};

/** aggregate fields of "contract.agreement_fee_charge" */
export type ContractAgreementFeeChargeAggregateFields = {
  __typename?: 'contract_agreement_fee_charge_aggregate_fields';
  avg?: Maybe<ContractAgreementFeeChargeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ContractAgreementFeeChargeMaxFields>;
  min?: Maybe<ContractAgreementFeeChargeMinFields>;
  stddev?: Maybe<ContractAgreementFeeChargeStddevFields>;
  stddev_pop?: Maybe<ContractAgreementFeeChargeStddevPopFields>;
  stddev_samp?: Maybe<ContractAgreementFeeChargeStddevSampFields>;
  sum?: Maybe<ContractAgreementFeeChargeSumFields>;
  var_pop?: Maybe<ContractAgreementFeeChargeVarPopFields>;
  var_samp?: Maybe<ContractAgreementFeeChargeVarSampFields>;
  variance?: Maybe<ContractAgreementFeeChargeVarianceFields>;
};


/** aggregate fields of "contract.agreement_fee_charge" */
export type ContractAgreementFeeChargeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ContractAgreementFeeChargeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contract.agreement_fee_charge" */
export type ContractAgreementFeeChargeAggregateOrderBy = {
  avg?: Maybe<ContractAgreementFeeChargeAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ContractAgreementFeeChargeMaxOrderBy>;
  min?: Maybe<ContractAgreementFeeChargeMinOrderBy>;
  stddev?: Maybe<ContractAgreementFeeChargeStddevOrderBy>;
  stddev_pop?: Maybe<ContractAgreementFeeChargeStddevPopOrderBy>;
  stddev_samp?: Maybe<ContractAgreementFeeChargeStddevSampOrderBy>;
  sum?: Maybe<ContractAgreementFeeChargeSumOrderBy>;
  var_pop?: Maybe<ContractAgreementFeeChargeVarPopOrderBy>;
  var_samp?: Maybe<ContractAgreementFeeChargeVarSampOrderBy>;
  variance?: Maybe<ContractAgreementFeeChargeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "contract.agreement_fee_charge" */
export type ContractAgreementFeeChargeArrRelInsertInput = {
  data: Array<ContractAgreementFeeChargeInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ContractAgreementFeeChargeOnConflict>;
};

/** aggregate avg on columns */
export type ContractAgreementFeeChargeAvgFields = {
  __typename?: 'contract_agreement_fee_charge_avg_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contract.agreement_fee_charge" */
export type ContractAgreementFeeChargeAvgOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "contract.agreement_fee_charge". All fields are combined with a logical 'AND'. */
export type ContractAgreementFeeChargeBoolExp = {
  _and?: Maybe<Array<ContractAgreementFeeChargeBoolExp>>;
  _not?: Maybe<ContractAgreementFeeChargeBoolExp>;
  _or?: Maybe<Array<ContractAgreementFeeChargeBoolExp>>;
  agreement?: Maybe<ContractAgreementBoolExp>;
  amount?: Maybe<NumericComparisonExp>;
  contract_number?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  date?: Maybe<DateComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  register_number?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "contract.agreement_fee_charge" */
export enum ContractAgreementFeeChargeConstraint {
  /** unique or primary key constraint */
  AgreementFeeChargePkey = 'agreement_fee_charge_pkey'
}

/** input type for incrementing numeric columns in table "contract.agreement_fee_charge" */
export type ContractAgreementFeeChargeIncInput = {
  /** จำนวน */
  amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "contract.agreement_fee_charge" */
export type ContractAgreementFeeChargeInsertInput = {
  agreement?: Maybe<ContractAgreementObjRelInsertInput>;
  /** จำนวน */
  amount?: Maybe<Scalars['numeric']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ */
  date?: Maybe<Scalars['date']>;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รหัสลุกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ContractAgreementFeeChargeMaxFields = {
  __typename?: 'contract_agreement_fee_charge_max_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['numeric']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ */
  date?: Maybe<Scalars['date']>;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รหัสลุกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "contract.agreement_fee_charge" */
export type ContractAgreementFeeChargeMaxOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<OrderBy>;
  /** วันที่ */
  date?: Maybe<OrderBy>;
  /** คำอธิบาย */
  description?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** รหัสลุกค้า */
  register_number?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ContractAgreementFeeChargeMinFields = {
  __typename?: 'contract_agreement_fee_charge_min_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['numeric']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ */
  date?: Maybe<Scalars['date']>;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รหัสลุกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "contract.agreement_fee_charge" */
export type ContractAgreementFeeChargeMinOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<OrderBy>;
  /** วันที่ */
  date?: Maybe<OrderBy>;
  /** คำอธิบาย */
  description?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** รหัสลุกค้า */
  register_number?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "contract.agreement_fee_charge" */
export type ContractAgreementFeeChargeMutationResponse = {
  __typename?: 'contract_agreement_fee_charge_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContractAgreementFeeCharge>;
};

/** on conflict condition type for table "contract.agreement_fee_charge" */
export type ContractAgreementFeeChargeOnConflict = {
  constraint: ContractAgreementFeeChargeConstraint;
  update_columns?: Array<ContractAgreementFeeChargeUpdateColumn>;
  where?: Maybe<ContractAgreementFeeChargeBoolExp>;
};

/** Ordering options when selecting data from "contract.agreement_fee_charge". */
export type ContractAgreementFeeChargeOrderBy = {
  agreement?: Maybe<ContractAgreementOrderBy>;
  amount?: Maybe<OrderBy>;
  contract_number?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  register_number?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: contract_agreement_fee_charge */
export type ContractAgreementFeeChargePkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "contract.agreement_fee_charge" */
export enum ContractAgreementFeeChargeSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  ContractNumber = 'contract_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  RegisterNumber = 'register_number',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "contract.agreement_fee_charge" */
export type ContractAgreementFeeChargeSetInput = {
  /** จำนวน */
  amount?: Maybe<Scalars['numeric']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ */
  date?: Maybe<Scalars['date']>;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รหัสลุกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ContractAgreementFeeChargeStddevFields = {
  __typename?: 'contract_agreement_fee_charge_stddev_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contract.agreement_fee_charge" */
export type ContractAgreementFeeChargeStddevOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ContractAgreementFeeChargeStddevPopFields = {
  __typename?: 'contract_agreement_fee_charge_stddev_pop_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contract.agreement_fee_charge" */
export type ContractAgreementFeeChargeStddevPopOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ContractAgreementFeeChargeStddevSampFields = {
  __typename?: 'contract_agreement_fee_charge_stddev_samp_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contract.agreement_fee_charge" */
export type ContractAgreementFeeChargeStddevSampOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ContractAgreementFeeChargeSumFields = {
  __typename?: 'contract_agreement_fee_charge_sum_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "contract.agreement_fee_charge" */
export type ContractAgreementFeeChargeSumOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
};

/** update columns of table "contract.agreement_fee_charge" */
export enum ContractAgreementFeeChargeUpdateColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  ContractNumber = 'contract_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  RegisterNumber = 'register_number',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type ContractAgreementFeeChargeVarPopFields = {
  __typename?: 'contract_agreement_fee_charge_var_pop_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contract.agreement_fee_charge" */
export type ContractAgreementFeeChargeVarPopOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ContractAgreementFeeChargeVarSampFields = {
  __typename?: 'contract_agreement_fee_charge_var_samp_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contract.agreement_fee_charge" */
export type ContractAgreementFeeChargeVarSampOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ContractAgreementFeeChargeVarianceFields = {
  __typename?: 'contract_agreement_fee_charge_variance_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contract.agreement_fee_charge" */
export type ContractAgreementFeeChargeVarianceOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
};

/**
 * ผู้ค้ำประกัน
 *
 *
 * columns and relationships of "contract.agreement_guarantor"
 */
export type ContractAgreementGuarantor = {
  __typename?: 'contract_agreement_guarantor';
  /** An object relationship */
  agreement: ContractAgreement;
  /** รหัสอ้างอิง agreement */
  agreement_id: Scalars['uuid'];
  /** บัตรประจำตัวประชาชน */
  citizen_id: Scalars['String'];
  /** วันที่บันทึกข้อมมูล */
  created_at: Scalars['timestamptz'];
  /** วันที่บันทึกข้อมูล */
  created_by?: Maybe<Scalars['uuid']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  /** คำนำหน้า */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at: Scalars['timestamptz'];
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "contract.agreement_guarantor" */
export type ContractAgreementGuarantorAggregate = {
  __typename?: 'contract_agreement_guarantor_aggregate';
  aggregate?: Maybe<ContractAgreementGuarantorAggregateFields>;
  nodes: Array<ContractAgreementGuarantor>;
};

/** aggregate fields of "contract.agreement_guarantor" */
export type ContractAgreementGuarantorAggregateFields = {
  __typename?: 'contract_agreement_guarantor_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContractAgreementGuarantorMaxFields>;
  min?: Maybe<ContractAgreementGuarantorMinFields>;
};


/** aggregate fields of "contract.agreement_guarantor" */
export type ContractAgreementGuarantorAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ContractAgreementGuarantorSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contract.agreement_guarantor" */
export type ContractAgreementGuarantorAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ContractAgreementGuarantorMaxOrderBy>;
  min?: Maybe<ContractAgreementGuarantorMinOrderBy>;
};

/** input type for inserting array relation for remote table "contract.agreement_guarantor" */
export type ContractAgreementGuarantorArrRelInsertInput = {
  data: Array<ContractAgreementGuarantorInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ContractAgreementGuarantorOnConflict>;
};

/** Boolean expression to filter rows from the table "contract.agreement_guarantor". All fields are combined with a logical 'AND'. */
export type ContractAgreementGuarantorBoolExp = {
  _and?: Maybe<Array<ContractAgreementGuarantorBoolExp>>;
  _not?: Maybe<ContractAgreementGuarantorBoolExp>;
  _or?: Maybe<Array<ContractAgreementGuarantorBoolExp>>;
  agreement?: Maybe<ContractAgreementBoolExp>;
  agreement_id?: Maybe<UuidComparisonExp>;
  citizen_id?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  first_name?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  last_name?: Maybe<StringComparisonExp>;
  note?: Maybe<StringComparisonExp>;
  title?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "contract.agreement_guarantor" */
export enum ContractAgreementGuarantorConstraint {
  /** unique or primary key constraint */
  AgreementGuarantorAgreementIdCitizenIdKey = 'agreement_guarantor_agreement_id_citizen_id_key',
  /** unique or primary key constraint */
  AgreementGuarantorPkey = 'agreement_guarantor_pkey'
}

/** input type for inserting data into table "contract.agreement_guarantor" */
export type ContractAgreementGuarantorInsertInput = {
  agreement?: Maybe<ContractAgreementObjRelInsertInput>;
  /** รหัสอ้างอิง agreement */
  agreement_id?: Maybe<Scalars['uuid']>;
  /** บัตรประจำตัวประชาชน */
  citizen_id?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่บันทึกข้อมูล */
  created_by?: Maybe<Scalars['uuid']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  /** คำนำหน้า */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ContractAgreementGuarantorMaxFields = {
  __typename?: 'contract_agreement_guarantor_max_fields';
  /** รหัสอ้างอิง agreement */
  agreement_id?: Maybe<Scalars['uuid']>;
  /** บัตรประจำตัวประชาชน */
  citizen_id?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่บันทึกข้อมูล */
  created_by?: Maybe<Scalars['uuid']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  /** คำนำหน้า */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "contract.agreement_guarantor" */
export type ContractAgreementGuarantorMaxOrderBy = {
  /** รหัสอ้างอิง agreement */
  agreement_id?: Maybe<OrderBy>;
  /** บัตรประจำตัวประชาชน */
  citizen_id?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_by?: Maybe<OrderBy>;
  /** ชื่อ */
  first_name?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** นามสกุล */
  last_name?: Maybe<OrderBy>;
  /** หมายเหตุ */
  note?: Maybe<OrderBy>;
  /** คำนำหน้า */
  title?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ContractAgreementGuarantorMinFields = {
  __typename?: 'contract_agreement_guarantor_min_fields';
  /** รหัสอ้างอิง agreement */
  agreement_id?: Maybe<Scalars['uuid']>;
  /** บัตรประจำตัวประชาชน */
  citizen_id?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่บันทึกข้อมูล */
  created_by?: Maybe<Scalars['uuid']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  /** คำนำหน้า */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "contract.agreement_guarantor" */
export type ContractAgreementGuarantorMinOrderBy = {
  /** รหัสอ้างอิง agreement */
  agreement_id?: Maybe<OrderBy>;
  /** บัตรประจำตัวประชาชน */
  citizen_id?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_by?: Maybe<OrderBy>;
  /** ชื่อ */
  first_name?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** นามสกุล */
  last_name?: Maybe<OrderBy>;
  /** หมายเหตุ */
  note?: Maybe<OrderBy>;
  /** คำนำหน้า */
  title?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "contract.agreement_guarantor" */
export type ContractAgreementGuarantorMutationResponse = {
  __typename?: 'contract_agreement_guarantor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContractAgreementGuarantor>;
};

/** on conflict condition type for table "contract.agreement_guarantor" */
export type ContractAgreementGuarantorOnConflict = {
  constraint: ContractAgreementGuarantorConstraint;
  update_columns?: Array<ContractAgreementGuarantorUpdateColumn>;
  where?: Maybe<ContractAgreementGuarantorBoolExp>;
};

/** Ordering options when selecting data from "contract.agreement_guarantor". */
export type ContractAgreementGuarantorOrderBy = {
  agreement?: Maybe<ContractAgreementOrderBy>;
  agreement_id?: Maybe<OrderBy>;
  citizen_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  first_name?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  last_name?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** primary key columns input for table: contract_agreement_guarantor */
export type ContractAgreementGuarantorPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "contract.agreement_guarantor" */
export enum ContractAgreementGuarantorSelectColumn {
  /** column name */
  AgreementId = 'agreement_id',
  /** column name */
  CitizenId = 'citizen_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Note = 'note',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "contract.agreement_guarantor" */
export type ContractAgreementGuarantorSetInput = {
  /** รหัสอ้างอิง agreement */
  agreement_id?: Maybe<Scalars['uuid']>;
  /** บัตรประจำตัวประชาชน */
  citizen_id?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่บันทึกข้อมูล */
  created_by?: Maybe<Scalars['uuid']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  /** คำนำหน้า */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** update columns of table "contract.agreement_guarantor" */
export enum ContractAgreementGuarantorUpdateColumn {
  /** column name */
  AgreementId = 'agreement_id',
  /** column name */
  CitizenId = 'citizen_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Note = 'note',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for incrementing numeric columns in table "contract.agreement" */
export type ContractAgreementIncInput = {
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<Scalars['numeric']>;
  /** DDamt */
  d_damt?: Maybe<Scalars['numeric']>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<Scalars['numeric']>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "contract.agreement" */
export type ContractAgreementInsertInput = {
  /** ที่อยู่ */
  address?: Maybe<Scalars['String']>;
  /** เลขที่ใบคำขอกู้ */
  af_number?: Maybe<Scalars['String']>;
  /** สถานะใบคำขอกู้ */
  af_status?: Maybe<Scalars['String']>;
  /** รายละเอียดสถานะ */
  af_status_description?: Maybe<Scalars['String']>;
  /** AFStatusName */
  af_status_name?: Maybe<Scalars['String']>;
  agreement_attachments?: Maybe<ContractAgreementAttachmentArrRelInsertInput>;
  agreement_detail?: Maybe<ContractAgreementDetailObjRelInsertInput>;
  agreement_details?: Maybe<ContractAgreementDetailArrRelInsertInput>;
  agreement_disbursements?: Maybe<ContractAgreementDisbursementArrRelInsertInput>;
  agreement_fee_charges?: Maybe<ContractAgreementFeeChargeArrRelInsertInput>;
  agreement_guarantors?: Maybe<ContractAgreementGuarantorArrRelInsertInput>;
  agreement_receipts?: Maybe<ContractAgreementReceiptArrRelInsertInput>;
  agreement_transactions?: Maybe<ContractAgreementTransactionArrRelInsertInput>;
  agreement_withdraws?: Maybe<ContractAgreementWithdrawArrRelInsertInput>;
  /** วันที่อนุมัติ */
  approved_date?: Maybe<Scalars['date']>;
  bank_branch?: Maybe<BankBranchObjRelInsertInput>;
  /** ธนาคารทีรับผิดชอบ */
  bank_branch_id?: Maybe<Scalars['String']>;
  /** BusGroupDetail */
  business_group?: Maybe<Scalars['String']>;
  /** ประเภทธุรกิจ */
  business_type?: Maybe<Scalars['String']>;
  /** เลขที่ติดต่อ */
  cf_number?: Maybe<Scalars['String']>;
  /** วันที่ติดต่อ */
  contact_date?: Maybe<Scalars['date']>;
  /** ผู้ติดต่อ: อีเมล */
  contact_email?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: โทรสาร */
  contact_fax?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: ชื่อ */
  contact_first_name?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: นามสกุล */
  contact_last_name?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: โทรศัพท์มือถือ */
  contact_mobile?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: โทรศัพท์ */
  contact_tel?: Maybe<Scalars['String']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<Scalars['numeric']>;
  /** Custtype */
  customer_type?: Maybe<Scalars['String']>;
  /** DDamt */
  d_damt?: Maybe<Scalars['numeric']>;
  /** วันที่ลงนามในสัญญา */
  effective_date?: Maybe<Scalars['date']>;
  /** วันที่เริ่มชำระครั้งแรก */
  first_repay_date?: Maybe<Scalars['date']>;
  /** วันที่เบิกครั้งแรก */
  first_withdraw_date?: Maybe<Scalars['date']>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<Scalars['numeric']>;
  /** ประเภทการค้ำประกัน */
  guarantee_type?: Maybe<Scalars['String']>;
  /** เลขประจำตัวประชาชนผู้ค้ำประกัน */
  guarantor_citizen_id?: Maybe<Scalars['String']>;
  /** รายละเอียดผู้ค้ำประกัน */
  guarantor_description?: Maybe<Scalars['String']>;
  /** ชื่อผู้คำประกัน */
  guarantor_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** เวลาที่บันทึกผ่านการ import ข้อมูล ถ้าค่าเป็น null หมายความว่า ผู้ใช้งานเป็นผู้สร้างข้อมูล */
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** วันหมดอายุกรมธรรม์คุ้มครองทรัพย์ */
  insurance_due_date?: Maybe<Scalars['date']>;
  juristic?: Maybe<JuristicObjRelInsertInput>;
  /** เลขนิติบุคคล */
  juristic_id?: Maybe<Scalars['String']>;
  /** ชื่อนิติบุคคลภาษาอังกฤษ */
  juristic_name_en?: Maybe<Scalars['String']>;
  /** ชื่อนิติบุคคลภาษาไทย */
  juristic_name_th?: Maybe<Scalars['String']>;
  /** LoanDetail */
  loan_description?: Maybe<Scalars['String']>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<Scalars['numeric']>;
  /** วัตถุประสงค์การกู้ */
  objective?: Maybe<Scalars['String']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['String']>;
  program?: Maybe<LoanProgramObjRelInsertInput>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** ชื่อโครงการ */
  program_name?: Maybe<Scalars['String']>;
  /** รหัสโครงการ */
  program_reference?: Maybe<Scalars['String']>;
  /** ProjectDetail */
  project_description?: Maybe<Scalars['String']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  repay_current_month?: Maybe<ContractAgreementRepayCurrentMonthObjRelInsertInput>;
  /** วันกำหนดชำระเงินกู้แต่ละเดือน */
  repay_day_of_month?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ContractAgreementMaxFields = {
  __typename?: 'contract_agreement_max_fields';
  /** ที่อยู่ */
  address?: Maybe<Scalars['String']>;
  /** เลขที่ใบคำขอกู้ */
  af_number?: Maybe<Scalars['String']>;
  /** สถานะใบคำขอกู้ */
  af_status?: Maybe<Scalars['String']>;
  /** รายละเอียดสถานะ */
  af_status_description?: Maybe<Scalars['String']>;
  /** AFStatusName */
  af_status_name?: Maybe<Scalars['String']>;
  /** วันที่อนุมัติ */
  approved_date?: Maybe<Scalars['date']>;
  /** ธนาคารทีรับผิดชอบ */
  bank_branch_id?: Maybe<Scalars['String']>;
  /** BusGroupDetail */
  business_group?: Maybe<Scalars['String']>;
  /** ประเภทธุรกิจ */
  business_type?: Maybe<Scalars['String']>;
  /** เลขที่ติดต่อ */
  cf_number?: Maybe<Scalars['String']>;
  /** วันที่ติดต่อ */
  contact_date?: Maybe<Scalars['date']>;
  /** ผู้ติดต่อ: อีเมล */
  contact_email?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: โทรสาร */
  contact_fax?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: ชื่อ */
  contact_first_name?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: นามสกุล */
  contact_last_name?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: โทรศัพท์มือถือ */
  contact_mobile?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: โทรศัพท์ */
  contact_tel?: Maybe<Scalars['String']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<Scalars['numeric']>;
  /** Custtype */
  customer_type?: Maybe<Scalars['String']>;
  /** DDamt */
  d_damt?: Maybe<Scalars['numeric']>;
  /** วันที่ลงนามในสัญญา */
  effective_date?: Maybe<Scalars['date']>;
  /** วันที่เริ่มชำระครั้งแรก */
  first_repay_date?: Maybe<Scalars['date']>;
  /** วันที่เบิกครั้งแรก */
  first_withdraw_date?: Maybe<Scalars['date']>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<Scalars['numeric']>;
  /** ประเภทการค้ำประกัน */
  guarantee_type?: Maybe<Scalars['String']>;
  /** เลขประจำตัวประชาชนผู้ค้ำประกัน */
  guarantor_citizen_id?: Maybe<Scalars['String']>;
  /** รายละเอียดผู้ค้ำประกัน */
  guarantor_description?: Maybe<Scalars['String']>;
  /** ชื่อผู้คำประกัน */
  guarantor_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** เวลาที่บันทึกผ่านการ import ข้อมูล ถ้าค่าเป็น null หมายความว่า ผู้ใช้งานเป็นผู้สร้างข้อมูล */
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** วันหมดอายุกรมธรรม์คุ้มครองทรัพย์ */
  insurance_due_date?: Maybe<Scalars['date']>;
  /** เลขนิติบุคคล */
  juristic_id?: Maybe<Scalars['String']>;
  /** ชื่อนิติบุคคลภาษาอังกฤษ */
  juristic_name_en?: Maybe<Scalars['String']>;
  /** ชื่อนิติบุคคลภาษาไทย */
  juristic_name_th?: Maybe<Scalars['String']>;
  /** LoanDetail */
  loan_description?: Maybe<Scalars['String']>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<Scalars['numeric']>;
  /** วัตถุประสงค์การกู้ */
  objective?: Maybe<Scalars['String']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['String']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** ชื่อโครงการ */
  program_name?: Maybe<Scalars['String']>;
  /** รหัสโครงการ */
  program_reference?: Maybe<Scalars['String']>;
  /** ProjectDetail */
  project_description?: Maybe<Scalars['String']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** วันกำหนดชำระเงินกู้แต่ละเดือน */
  repay_day_of_month?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "contract.agreement" */
export type ContractAgreementMaxOrderBy = {
  /** ที่อยู่ */
  address?: Maybe<OrderBy>;
  /** เลขที่ใบคำขอกู้ */
  af_number?: Maybe<OrderBy>;
  /** สถานะใบคำขอกู้ */
  af_status?: Maybe<OrderBy>;
  /** รายละเอียดสถานะ */
  af_status_description?: Maybe<OrderBy>;
  /** AFStatusName */
  af_status_name?: Maybe<OrderBy>;
  /** วันที่อนุมัติ */
  approved_date?: Maybe<OrderBy>;
  /** ธนาคารทีรับผิดชอบ */
  bank_branch_id?: Maybe<OrderBy>;
  /** BusGroupDetail */
  business_group?: Maybe<OrderBy>;
  /** ประเภทธุรกิจ */
  business_type?: Maybe<OrderBy>;
  /** เลขที่ติดต่อ */
  cf_number?: Maybe<OrderBy>;
  /** วันที่ติดต่อ */
  contact_date?: Maybe<OrderBy>;
  /** ผู้ติดต่อ: อีเมล */
  contact_email?: Maybe<OrderBy>;
  /** ผู้ติดต่อ: โทรสาร */
  contact_fax?: Maybe<OrderBy>;
  /** ผู้ติดต่อ: ชื่อ */
  contact_first_name?: Maybe<OrderBy>;
  /** ผู้ติดต่อ: นามสกุล */
  contact_last_name?: Maybe<OrderBy>;
  /** ผู้ติดต่อ: โทรศัพท์มือถือ */
  contact_mobile?: Maybe<OrderBy>;
  /** ผู้ติดต่อ: โทรศัพท์ */
  contact_tel?: Maybe<OrderBy>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<OrderBy>;
  /** Custtype */
  customer_type?: Maybe<OrderBy>;
  /** DDamt */
  d_damt?: Maybe<OrderBy>;
  /** วันที่ลงนามในสัญญา */
  effective_date?: Maybe<OrderBy>;
  /** วันที่เริ่มชำระครั้งแรก */
  first_repay_date?: Maybe<OrderBy>;
  /** วันที่เบิกครั้งแรก */
  first_withdraw_date?: Maybe<OrderBy>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<OrderBy>;
  /** ประเภทการค้ำประกัน */
  guarantee_type?: Maybe<OrderBy>;
  /** เลขประจำตัวประชาชนผู้ค้ำประกัน */
  guarantor_citizen_id?: Maybe<OrderBy>;
  /** รายละเอียดผู้ค้ำประกัน */
  guarantor_description?: Maybe<OrderBy>;
  /** ชื่อผู้คำประกัน */
  guarantor_name?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** เวลาที่บันทึกผ่านการ import ข้อมูล ถ้าค่าเป็น null หมายความว่า ผู้ใช้งานเป็นผู้สร้างข้อมูล */
  imported_at?: Maybe<OrderBy>;
  /** วันหมดอายุกรมธรรม์คุ้มครองทรัพย์ */
  insurance_due_date?: Maybe<OrderBy>;
  /** เลขนิติบุคคล */
  juristic_id?: Maybe<OrderBy>;
  /** ชื่อนิติบุคคลภาษาอังกฤษ */
  juristic_name_en?: Maybe<OrderBy>;
  /** ชื่อนิติบุคคลภาษาไทย */
  juristic_name_th?: Maybe<OrderBy>;
  /** LoanDetail */
  loan_description?: Maybe<OrderBy>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<OrderBy>;
  /** วัตถุประสงค์การกู้ */
  objective?: Maybe<OrderBy>;
  /** ระยะเวลากู้ */
  period?: Maybe<OrderBy>;
  /** รหัสโครงการ */
  program_id?: Maybe<OrderBy>;
  /** ชื่อโครงการ */
  program_name?: Maybe<OrderBy>;
  /** รหัสโครงการ */
  program_reference?: Maybe<OrderBy>;
  /** ProjectDetail */
  project_description?: Maybe<OrderBy>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<OrderBy>;
  /** วันกำหนดชำระเงินกู้แต่ละเดือน */
  repay_day_of_month?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ContractAgreementMinFields = {
  __typename?: 'contract_agreement_min_fields';
  /** ที่อยู่ */
  address?: Maybe<Scalars['String']>;
  /** เลขที่ใบคำขอกู้ */
  af_number?: Maybe<Scalars['String']>;
  /** สถานะใบคำขอกู้ */
  af_status?: Maybe<Scalars['String']>;
  /** รายละเอียดสถานะ */
  af_status_description?: Maybe<Scalars['String']>;
  /** AFStatusName */
  af_status_name?: Maybe<Scalars['String']>;
  /** วันที่อนุมัติ */
  approved_date?: Maybe<Scalars['date']>;
  /** ธนาคารทีรับผิดชอบ */
  bank_branch_id?: Maybe<Scalars['String']>;
  /** BusGroupDetail */
  business_group?: Maybe<Scalars['String']>;
  /** ประเภทธุรกิจ */
  business_type?: Maybe<Scalars['String']>;
  /** เลขที่ติดต่อ */
  cf_number?: Maybe<Scalars['String']>;
  /** วันที่ติดต่อ */
  contact_date?: Maybe<Scalars['date']>;
  /** ผู้ติดต่อ: อีเมล */
  contact_email?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: โทรสาร */
  contact_fax?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: ชื่อ */
  contact_first_name?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: นามสกุล */
  contact_last_name?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: โทรศัพท์มือถือ */
  contact_mobile?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: โทรศัพท์ */
  contact_tel?: Maybe<Scalars['String']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<Scalars['numeric']>;
  /** Custtype */
  customer_type?: Maybe<Scalars['String']>;
  /** DDamt */
  d_damt?: Maybe<Scalars['numeric']>;
  /** วันที่ลงนามในสัญญา */
  effective_date?: Maybe<Scalars['date']>;
  /** วันที่เริ่มชำระครั้งแรก */
  first_repay_date?: Maybe<Scalars['date']>;
  /** วันที่เบิกครั้งแรก */
  first_withdraw_date?: Maybe<Scalars['date']>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<Scalars['numeric']>;
  /** ประเภทการค้ำประกัน */
  guarantee_type?: Maybe<Scalars['String']>;
  /** เลขประจำตัวประชาชนผู้ค้ำประกัน */
  guarantor_citizen_id?: Maybe<Scalars['String']>;
  /** รายละเอียดผู้ค้ำประกัน */
  guarantor_description?: Maybe<Scalars['String']>;
  /** ชื่อผู้คำประกัน */
  guarantor_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** เวลาที่บันทึกผ่านการ import ข้อมูล ถ้าค่าเป็น null หมายความว่า ผู้ใช้งานเป็นผู้สร้างข้อมูล */
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** วันหมดอายุกรมธรรม์คุ้มครองทรัพย์ */
  insurance_due_date?: Maybe<Scalars['date']>;
  /** เลขนิติบุคคล */
  juristic_id?: Maybe<Scalars['String']>;
  /** ชื่อนิติบุคคลภาษาอังกฤษ */
  juristic_name_en?: Maybe<Scalars['String']>;
  /** ชื่อนิติบุคคลภาษาไทย */
  juristic_name_th?: Maybe<Scalars['String']>;
  /** LoanDetail */
  loan_description?: Maybe<Scalars['String']>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<Scalars['numeric']>;
  /** วัตถุประสงค์การกู้ */
  objective?: Maybe<Scalars['String']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['String']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** ชื่อโครงการ */
  program_name?: Maybe<Scalars['String']>;
  /** รหัสโครงการ */
  program_reference?: Maybe<Scalars['String']>;
  /** ProjectDetail */
  project_description?: Maybe<Scalars['String']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** วันกำหนดชำระเงินกู้แต่ละเดือน */
  repay_day_of_month?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "contract.agreement" */
export type ContractAgreementMinOrderBy = {
  /** ที่อยู่ */
  address?: Maybe<OrderBy>;
  /** เลขที่ใบคำขอกู้ */
  af_number?: Maybe<OrderBy>;
  /** สถานะใบคำขอกู้ */
  af_status?: Maybe<OrderBy>;
  /** รายละเอียดสถานะ */
  af_status_description?: Maybe<OrderBy>;
  /** AFStatusName */
  af_status_name?: Maybe<OrderBy>;
  /** วันที่อนุมัติ */
  approved_date?: Maybe<OrderBy>;
  /** ธนาคารทีรับผิดชอบ */
  bank_branch_id?: Maybe<OrderBy>;
  /** BusGroupDetail */
  business_group?: Maybe<OrderBy>;
  /** ประเภทธุรกิจ */
  business_type?: Maybe<OrderBy>;
  /** เลขที่ติดต่อ */
  cf_number?: Maybe<OrderBy>;
  /** วันที่ติดต่อ */
  contact_date?: Maybe<OrderBy>;
  /** ผู้ติดต่อ: อีเมล */
  contact_email?: Maybe<OrderBy>;
  /** ผู้ติดต่อ: โทรสาร */
  contact_fax?: Maybe<OrderBy>;
  /** ผู้ติดต่อ: ชื่อ */
  contact_first_name?: Maybe<OrderBy>;
  /** ผู้ติดต่อ: นามสกุล */
  contact_last_name?: Maybe<OrderBy>;
  /** ผู้ติดต่อ: โทรศัพท์มือถือ */
  contact_mobile?: Maybe<OrderBy>;
  /** ผู้ติดต่อ: โทรศัพท์ */
  contact_tel?: Maybe<OrderBy>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<OrderBy>;
  /** Custtype */
  customer_type?: Maybe<OrderBy>;
  /** DDamt */
  d_damt?: Maybe<OrderBy>;
  /** วันที่ลงนามในสัญญา */
  effective_date?: Maybe<OrderBy>;
  /** วันที่เริ่มชำระครั้งแรก */
  first_repay_date?: Maybe<OrderBy>;
  /** วันที่เบิกครั้งแรก */
  first_withdraw_date?: Maybe<OrderBy>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<OrderBy>;
  /** ประเภทการค้ำประกัน */
  guarantee_type?: Maybe<OrderBy>;
  /** เลขประจำตัวประชาชนผู้ค้ำประกัน */
  guarantor_citizen_id?: Maybe<OrderBy>;
  /** รายละเอียดผู้ค้ำประกัน */
  guarantor_description?: Maybe<OrderBy>;
  /** ชื่อผู้คำประกัน */
  guarantor_name?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** เวลาที่บันทึกผ่านการ import ข้อมูล ถ้าค่าเป็น null หมายความว่า ผู้ใช้งานเป็นผู้สร้างข้อมูล */
  imported_at?: Maybe<OrderBy>;
  /** วันหมดอายุกรมธรรม์คุ้มครองทรัพย์ */
  insurance_due_date?: Maybe<OrderBy>;
  /** เลขนิติบุคคล */
  juristic_id?: Maybe<OrderBy>;
  /** ชื่อนิติบุคคลภาษาอังกฤษ */
  juristic_name_en?: Maybe<OrderBy>;
  /** ชื่อนิติบุคคลภาษาไทย */
  juristic_name_th?: Maybe<OrderBy>;
  /** LoanDetail */
  loan_description?: Maybe<OrderBy>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<OrderBy>;
  /** วัตถุประสงค์การกู้ */
  objective?: Maybe<OrderBy>;
  /** ระยะเวลากู้ */
  period?: Maybe<OrderBy>;
  /** รหัสโครงการ */
  program_id?: Maybe<OrderBy>;
  /** ชื่อโครงการ */
  program_name?: Maybe<OrderBy>;
  /** รหัสโครงการ */
  program_reference?: Maybe<OrderBy>;
  /** ProjectDetail */
  project_description?: Maybe<OrderBy>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<OrderBy>;
  /** วันกำหนดชำระเงินกู้แต่ละเดือน */
  repay_day_of_month?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "contract.agreement" */
export type ContractAgreementMutationResponse = {
  __typename?: 'contract_agreement_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContractAgreement>;
};

/** input type for inserting object relation for remote table "contract.agreement" */
export type ContractAgreementObjRelInsertInput = {
  data: ContractAgreementInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<ContractAgreementOnConflict>;
};

/** on conflict condition type for table "contract.agreement" */
export type ContractAgreementOnConflict = {
  constraint: ContractAgreementConstraint;
  update_columns?: Array<ContractAgreementUpdateColumn>;
  where?: Maybe<ContractAgreementBoolExp>;
};

/** Ordering options when selecting data from "contract.agreement". */
export type ContractAgreementOrderBy = {
  address?: Maybe<OrderBy>;
  af_number?: Maybe<OrderBy>;
  af_status?: Maybe<OrderBy>;
  af_status_description?: Maybe<OrderBy>;
  af_status_name?: Maybe<OrderBy>;
  agreement_attachments_aggregate?: Maybe<ContractAgreementAttachmentAggregateOrderBy>;
  agreement_detail?: Maybe<ContractAgreementDetailOrderBy>;
  agreement_details_aggregate?: Maybe<ContractAgreementDetailAggregateOrderBy>;
  agreement_disbursements_aggregate?: Maybe<ContractAgreementDisbursementAggregateOrderBy>;
  agreement_fee_charges_aggregate?: Maybe<ContractAgreementFeeChargeAggregateOrderBy>;
  agreement_guarantors_aggregate?: Maybe<ContractAgreementGuarantorAggregateOrderBy>;
  agreement_receipts_aggregate?: Maybe<ContractAgreementReceiptAggregateOrderBy>;
  agreement_transactions_aggregate?: Maybe<ContractAgreementTransactionAggregateOrderBy>;
  agreement_withdraws_aggregate?: Maybe<ContractAgreementWithdrawAggregateOrderBy>;
  approved_date?: Maybe<OrderBy>;
  bank_branch?: Maybe<BankBranchOrderBy>;
  bank_branch_id?: Maybe<OrderBy>;
  business_group?: Maybe<OrderBy>;
  business_type?: Maybe<OrderBy>;
  cf_number?: Maybe<OrderBy>;
  contact_date?: Maybe<OrderBy>;
  contact_email?: Maybe<OrderBy>;
  contact_fax?: Maybe<OrderBy>;
  contact_first_name?: Maybe<OrderBy>;
  contact_last_name?: Maybe<OrderBy>;
  contact_mobile?: Maybe<OrderBy>;
  contact_tel?: Maybe<OrderBy>;
  contract_number?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  credit_total?: Maybe<OrderBy>;
  customer_type?: Maybe<OrderBy>;
  d_damt?: Maybe<OrderBy>;
  effective_date?: Maybe<OrderBy>;
  first_repay_date?: Maybe<OrderBy>;
  first_withdraw_date?: Maybe<OrderBy>;
  grace_period?: Maybe<OrderBy>;
  guarantee_type?: Maybe<OrderBy>;
  guarantor_citizen_id?: Maybe<OrderBy>;
  guarantor_description?: Maybe<OrderBy>;
  guarantor_name?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  imported_at?: Maybe<OrderBy>;
  insurance_due_date?: Maybe<OrderBy>;
  juristic?: Maybe<JuristicOrderBy>;
  juristic_id?: Maybe<OrderBy>;
  juristic_name_en?: Maybe<OrderBy>;
  juristic_name_th?: Maybe<OrderBy>;
  loan_description?: Maybe<OrderBy>;
  monthly_payment?: Maybe<OrderBy>;
  objective?: Maybe<OrderBy>;
  period?: Maybe<OrderBy>;
  program?: Maybe<LoanProgramOrderBy>;
  program_id?: Maybe<OrderBy>;
  program_name?: Maybe<OrderBy>;
  program_reference?: Maybe<OrderBy>;
  project_description?: Maybe<OrderBy>;
  register_number?: Maybe<OrderBy>;
  repay_current_month?: Maybe<ContractAgreementRepayCurrentMonthOrderBy>;
  repay_day_of_month?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** primary key columns input for table: contract_agreement */
export type ContractAgreementPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/**
 * รับชำระหนี้เงินกู้
 *
 *
 * columns and relationships of "contract.agreement_receipt"
 */
export type ContractAgreementReceipt = {
  __typename?: 'contract_agreement_receipt';
  /** An object relationship */
  agreement?: Maybe<ContractAgreement>;
  /** จำนวนเงิน */
  amount: Scalars['numeric'];
  /** เลขที่สัญญา */
  contract_number: Scalars['String'];
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  created_by_user?: Maybe<Users>;
  /** รับชำระเงินต้น */
  credit_amount: Scalars['numeric'];
  /** รับชำระเบี้ยปรับ */
  fine_amount: Scalars['numeric'];
  /** วันที่นำเข้าข้อมูล */
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** รับชำระดอกเบี้ย */
  interest_amount: Scalars['numeric'];
  /** ชื่อลูกค้า */
  juristic_name?: Maybe<Scalars['String']>;
  /** วันที่ชำระครั้งสุดท้าย */
  last_repay_date?: Maybe<Scalars['date']>;
  /** รายได้อื่น */
  other_income: Scalars['numeric'];
  /** เงินต้นคงเหลือ */
  outstanding_balance: Scalars['numeric'];
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance: Scalars['numeric'];
  /** เลขที่ใบเสร็จรับเงิน */
  receipt_no: Scalars['String'];
  /** ทะเบียนลูกค้า */
  register_number: Scalars['String'];
  /** วันที่สรุปรายงาน */
  report_date?: Maybe<Scalars['date']>;
  /** สถานะ */
  status?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  updated_by_user?: Maybe<Users>;
};

/** aggregated selection of "contract.agreement_receipt" */
export type ContractAgreementReceiptAggregate = {
  __typename?: 'contract_agreement_receipt_aggregate';
  aggregate?: Maybe<ContractAgreementReceiptAggregateFields>;
  nodes: Array<ContractAgreementReceipt>;
};

/** aggregate fields of "contract.agreement_receipt" */
export type ContractAgreementReceiptAggregateFields = {
  __typename?: 'contract_agreement_receipt_aggregate_fields';
  avg?: Maybe<ContractAgreementReceiptAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ContractAgreementReceiptMaxFields>;
  min?: Maybe<ContractAgreementReceiptMinFields>;
  stddev?: Maybe<ContractAgreementReceiptStddevFields>;
  stddev_pop?: Maybe<ContractAgreementReceiptStddevPopFields>;
  stddev_samp?: Maybe<ContractAgreementReceiptStddevSampFields>;
  sum?: Maybe<ContractAgreementReceiptSumFields>;
  var_pop?: Maybe<ContractAgreementReceiptVarPopFields>;
  var_samp?: Maybe<ContractAgreementReceiptVarSampFields>;
  variance?: Maybe<ContractAgreementReceiptVarianceFields>;
};


/** aggregate fields of "contract.agreement_receipt" */
export type ContractAgreementReceiptAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ContractAgreementReceiptSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contract.agreement_receipt" */
export type ContractAgreementReceiptAggregateOrderBy = {
  avg?: Maybe<ContractAgreementReceiptAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ContractAgreementReceiptMaxOrderBy>;
  min?: Maybe<ContractAgreementReceiptMinOrderBy>;
  stddev?: Maybe<ContractAgreementReceiptStddevOrderBy>;
  stddev_pop?: Maybe<ContractAgreementReceiptStddevPopOrderBy>;
  stddev_samp?: Maybe<ContractAgreementReceiptStddevSampOrderBy>;
  sum?: Maybe<ContractAgreementReceiptSumOrderBy>;
  var_pop?: Maybe<ContractAgreementReceiptVarPopOrderBy>;
  var_samp?: Maybe<ContractAgreementReceiptVarSampOrderBy>;
  variance?: Maybe<ContractAgreementReceiptVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "contract.agreement_receipt" */
export type ContractAgreementReceiptArrRelInsertInput = {
  data: Array<ContractAgreementReceiptInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ContractAgreementReceiptOnConflict>;
};

/** aggregate avg on columns */
export type ContractAgreementReceiptAvgFields = {
  __typename?: 'contract_agreement_receipt_avg_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['Float']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['Float']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['Float']>;
  /** รายได้อื่น */
  other_income?: Maybe<Scalars['Float']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['Float']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contract.agreement_receipt" */
export type ContractAgreementReceiptAvgOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<OrderBy>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<OrderBy>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<OrderBy>;
  /** รายได้อื่น */
  other_income?: Maybe<OrderBy>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<OrderBy>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "contract.agreement_receipt". All fields are combined with a logical 'AND'. */
export type ContractAgreementReceiptBoolExp = {
  _and?: Maybe<Array<ContractAgreementReceiptBoolExp>>;
  _not?: Maybe<ContractAgreementReceiptBoolExp>;
  _or?: Maybe<Array<ContractAgreementReceiptBoolExp>>;
  agreement?: Maybe<ContractAgreementBoolExp>;
  amount?: Maybe<NumericComparisonExp>;
  contract_number?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  created_by_user?: Maybe<UsersBoolExp>;
  credit_amount?: Maybe<NumericComparisonExp>;
  fine_amount?: Maybe<NumericComparisonExp>;
  imported_at?: Maybe<TimestamptzComparisonExp>;
  interest_amount?: Maybe<NumericComparisonExp>;
  juristic_name?: Maybe<StringComparisonExp>;
  last_repay_date?: Maybe<DateComparisonExp>;
  other_income?: Maybe<NumericComparisonExp>;
  outstanding_balance?: Maybe<NumericComparisonExp>;
  previous_outstanding_balance?: Maybe<NumericComparisonExp>;
  receipt_no?: Maybe<StringComparisonExp>;
  register_number?: Maybe<StringComparisonExp>;
  report_date?: Maybe<DateComparisonExp>;
  status?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<UuidComparisonExp>;
  updated_by_user?: Maybe<UsersBoolExp>;
};

/** unique or primary key constraints on table "contract.agreement_receipt" */
export enum ContractAgreementReceiptConstraint {
  /** unique or primary key constraint */
  AgreementReceiptPkey = 'agreement_receipt_pkey'
}

/** input type for incrementing numeric columns in table "contract.agreement_receipt" */
export type ContractAgreementReceiptIncInput = {
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['numeric']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['numeric']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['numeric']>;
  /** รายได้อื่น */
  other_income?: Maybe<Scalars['numeric']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['numeric']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "contract.agreement_receipt" */
export type ContractAgreementReceiptInsertInput = {
  agreement?: Maybe<ContractAgreementObjRelInsertInput>;
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  created_by_user?: Maybe<UsersObjRelInsertInput>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['numeric']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['numeric']>;
  /** วันที่นำเข้าข้อมูล */
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['numeric']>;
  /** ชื่อลูกค้า */
  juristic_name?: Maybe<Scalars['String']>;
  /** วันที่ชำระครั้งสุดท้าย */
  last_repay_date?: Maybe<Scalars['date']>;
  /** รายได้อื่น */
  other_income?: Maybe<Scalars['numeric']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['numeric']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['numeric']>;
  /** เลขที่ใบเสร็จรับเงิน */
  receipt_no?: Maybe<Scalars['String']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** วันที่สรุปรายงาน */
  report_date?: Maybe<Scalars['date']>;
  /** สถานะ */
  status?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
  updated_by_user?: Maybe<UsersObjRelInsertInput>;
};

/** aggregate max on columns */
export type ContractAgreementReceiptMaxFields = {
  __typename?: 'contract_agreement_receipt_max_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['numeric']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['numeric']>;
  /** วันที่นำเข้าข้อมูล */
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['numeric']>;
  /** ชื่อลูกค้า */
  juristic_name?: Maybe<Scalars['String']>;
  /** วันที่ชำระครั้งสุดท้าย */
  last_repay_date?: Maybe<Scalars['date']>;
  /** รายได้อื่น */
  other_income?: Maybe<Scalars['numeric']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['numeric']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['numeric']>;
  /** เลขที่ใบเสร็จรับเงิน */
  receipt_no?: Maybe<Scalars['String']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** วันที่สรุปรายงาน */
  report_date?: Maybe<Scalars['date']>;
  /** สถานะ */
  status?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "contract.agreement_receipt" */
export type ContractAgreementReceiptMaxOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<OrderBy>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<OrderBy>;
  /** วันที่นำเข้าข้อมูล */
  imported_at?: Maybe<OrderBy>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<OrderBy>;
  /** ชื่อลูกค้า */
  juristic_name?: Maybe<OrderBy>;
  /** วันที่ชำระครั้งสุดท้าย */
  last_repay_date?: Maybe<OrderBy>;
  /** รายได้อื่น */
  other_income?: Maybe<OrderBy>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<OrderBy>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<OrderBy>;
  /** เลขที่ใบเสร็จรับเงิน */
  receipt_no?: Maybe<OrderBy>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<OrderBy>;
  /** วันที่สรุปรายงาน */
  report_date?: Maybe<OrderBy>;
  /** สถานะ */
  status?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ContractAgreementReceiptMinFields = {
  __typename?: 'contract_agreement_receipt_min_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['numeric']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['numeric']>;
  /** วันที่นำเข้าข้อมูล */
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['numeric']>;
  /** ชื่อลูกค้า */
  juristic_name?: Maybe<Scalars['String']>;
  /** วันที่ชำระครั้งสุดท้าย */
  last_repay_date?: Maybe<Scalars['date']>;
  /** รายได้อื่น */
  other_income?: Maybe<Scalars['numeric']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['numeric']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['numeric']>;
  /** เลขที่ใบเสร็จรับเงิน */
  receipt_no?: Maybe<Scalars['String']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** วันที่สรุปรายงาน */
  report_date?: Maybe<Scalars['date']>;
  /** สถานะ */
  status?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "contract.agreement_receipt" */
export type ContractAgreementReceiptMinOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<OrderBy>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<OrderBy>;
  /** วันที่นำเข้าข้อมูล */
  imported_at?: Maybe<OrderBy>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<OrderBy>;
  /** ชื่อลูกค้า */
  juristic_name?: Maybe<OrderBy>;
  /** วันที่ชำระครั้งสุดท้าย */
  last_repay_date?: Maybe<OrderBy>;
  /** รายได้อื่น */
  other_income?: Maybe<OrderBy>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<OrderBy>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<OrderBy>;
  /** เลขที่ใบเสร็จรับเงิน */
  receipt_no?: Maybe<OrderBy>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<OrderBy>;
  /** วันที่สรุปรายงาน */
  report_date?: Maybe<OrderBy>;
  /** สถานะ */
  status?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "contract.agreement_receipt" */
export type ContractAgreementReceiptMutationResponse = {
  __typename?: 'contract_agreement_receipt_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContractAgreementReceipt>;
};

/** on conflict condition type for table "contract.agreement_receipt" */
export type ContractAgreementReceiptOnConflict = {
  constraint: ContractAgreementReceiptConstraint;
  update_columns?: Array<ContractAgreementReceiptUpdateColumn>;
  where?: Maybe<ContractAgreementReceiptBoolExp>;
};

/** Ordering options when selecting data from "contract.agreement_receipt". */
export type ContractAgreementReceiptOrderBy = {
  agreement?: Maybe<ContractAgreementOrderBy>;
  amount?: Maybe<OrderBy>;
  contract_number?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  created_by_user?: Maybe<UsersOrderBy>;
  credit_amount?: Maybe<OrderBy>;
  fine_amount?: Maybe<OrderBy>;
  imported_at?: Maybe<OrderBy>;
  interest_amount?: Maybe<OrderBy>;
  juristic_name?: Maybe<OrderBy>;
  last_repay_date?: Maybe<OrderBy>;
  other_income?: Maybe<OrderBy>;
  outstanding_balance?: Maybe<OrderBy>;
  previous_outstanding_balance?: Maybe<OrderBy>;
  receipt_no?: Maybe<OrderBy>;
  register_number?: Maybe<OrderBy>;
  report_date?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
  updated_by_user?: Maybe<UsersOrderBy>;
};

/** primary key columns input for table: contract_agreement_receipt */
export type ContractAgreementReceiptPkColumnsInput = {
  /** เลขที่ใบเสร็จรับเงิน */
  receipt_no: Scalars['String'];
};

/** select columns of table "contract.agreement_receipt" */
export enum ContractAgreementReceiptSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  ContractNumber = 'contract_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CreditAmount = 'credit_amount',
  /** column name */
  FineAmount = 'fine_amount',
  /** column name */
  ImportedAt = 'imported_at',
  /** column name */
  InterestAmount = 'interest_amount',
  /** column name */
  JuristicName = 'juristic_name',
  /** column name */
  LastRepayDate = 'last_repay_date',
  /** column name */
  OtherIncome = 'other_income',
  /** column name */
  OutstandingBalance = 'outstanding_balance',
  /** column name */
  PreviousOutstandingBalance = 'previous_outstanding_balance',
  /** column name */
  ReceiptNo = 'receipt_no',
  /** column name */
  RegisterNumber = 'register_number',
  /** column name */
  ReportDate = 'report_date',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "contract.agreement_receipt" */
export type ContractAgreementReceiptSetInput = {
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['numeric']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['numeric']>;
  /** วันที่นำเข้าข้อมูล */
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['numeric']>;
  /** ชื่อลูกค้า */
  juristic_name?: Maybe<Scalars['String']>;
  /** วันที่ชำระครั้งสุดท้าย */
  last_repay_date?: Maybe<Scalars['date']>;
  /** รายได้อื่น */
  other_income?: Maybe<Scalars['numeric']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['numeric']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['numeric']>;
  /** เลขที่ใบเสร็จรับเงิน */
  receipt_no?: Maybe<Scalars['String']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** วันที่สรุปรายงาน */
  report_date?: Maybe<Scalars['date']>;
  /** สถานะ */
  status?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ContractAgreementReceiptStddevFields = {
  __typename?: 'contract_agreement_receipt_stddev_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['Float']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['Float']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['Float']>;
  /** รายได้อื่น */
  other_income?: Maybe<Scalars['Float']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['Float']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contract.agreement_receipt" */
export type ContractAgreementReceiptStddevOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<OrderBy>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<OrderBy>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<OrderBy>;
  /** รายได้อื่น */
  other_income?: Maybe<OrderBy>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<OrderBy>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ContractAgreementReceiptStddevPopFields = {
  __typename?: 'contract_agreement_receipt_stddev_pop_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['Float']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['Float']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['Float']>;
  /** รายได้อื่น */
  other_income?: Maybe<Scalars['Float']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['Float']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contract.agreement_receipt" */
export type ContractAgreementReceiptStddevPopOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<OrderBy>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<OrderBy>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<OrderBy>;
  /** รายได้อื่น */
  other_income?: Maybe<OrderBy>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<OrderBy>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ContractAgreementReceiptStddevSampFields = {
  __typename?: 'contract_agreement_receipt_stddev_samp_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['Float']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['Float']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['Float']>;
  /** รายได้อื่น */
  other_income?: Maybe<Scalars['Float']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['Float']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contract.agreement_receipt" */
export type ContractAgreementReceiptStddevSampOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<OrderBy>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<OrderBy>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<OrderBy>;
  /** รายได้อื่น */
  other_income?: Maybe<OrderBy>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<OrderBy>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ContractAgreementReceiptSumFields = {
  __typename?: 'contract_agreement_receipt_sum_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['numeric']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['numeric']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['numeric']>;
  /** รายได้อื่น */
  other_income?: Maybe<Scalars['numeric']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['numeric']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "contract.agreement_receipt" */
export type ContractAgreementReceiptSumOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<OrderBy>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<OrderBy>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<OrderBy>;
  /** รายได้อื่น */
  other_income?: Maybe<OrderBy>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<OrderBy>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<OrderBy>;
};

/** update columns of table "contract.agreement_receipt" */
export enum ContractAgreementReceiptUpdateColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  ContractNumber = 'contract_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CreditAmount = 'credit_amount',
  /** column name */
  FineAmount = 'fine_amount',
  /** column name */
  ImportedAt = 'imported_at',
  /** column name */
  InterestAmount = 'interest_amount',
  /** column name */
  JuristicName = 'juristic_name',
  /** column name */
  LastRepayDate = 'last_repay_date',
  /** column name */
  OtherIncome = 'other_income',
  /** column name */
  OutstandingBalance = 'outstanding_balance',
  /** column name */
  PreviousOutstandingBalance = 'previous_outstanding_balance',
  /** column name */
  ReceiptNo = 'receipt_no',
  /** column name */
  RegisterNumber = 'register_number',
  /** column name */
  ReportDate = 'report_date',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type ContractAgreementReceiptVarPopFields = {
  __typename?: 'contract_agreement_receipt_var_pop_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['Float']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['Float']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['Float']>;
  /** รายได้อื่น */
  other_income?: Maybe<Scalars['Float']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['Float']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contract.agreement_receipt" */
export type ContractAgreementReceiptVarPopOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<OrderBy>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<OrderBy>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<OrderBy>;
  /** รายได้อื่น */
  other_income?: Maybe<OrderBy>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<OrderBy>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ContractAgreementReceiptVarSampFields = {
  __typename?: 'contract_agreement_receipt_var_samp_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['Float']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['Float']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['Float']>;
  /** รายได้อื่น */
  other_income?: Maybe<Scalars['Float']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['Float']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contract.agreement_receipt" */
export type ContractAgreementReceiptVarSampOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<OrderBy>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<OrderBy>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<OrderBy>;
  /** รายได้อื่น */
  other_income?: Maybe<OrderBy>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<OrderBy>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ContractAgreementReceiptVarianceFields = {
  __typename?: 'contract_agreement_receipt_variance_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['Float']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['Float']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['Float']>;
  /** รายได้อื่น */
  other_income?: Maybe<Scalars['Float']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['Float']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contract.agreement_receipt" */
export type ContractAgreementReceiptVarianceOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<OrderBy>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<OrderBy>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<OrderBy>;
  /** รายได้อื่น */
  other_income?: Maybe<OrderBy>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<OrderBy>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<OrderBy>;
};

/** columns and relationships of "contract.agreement_repay_current_month" */
export type ContractAgreementRepayCurrentMonth = {
  __typename?: 'contract_agreement_repay_current_month';
  /** An object relationship */
  agreement?: Maybe<ContractAgreement>;
  contract_number?: Maybe<Scalars['String']>;
  next_remaining_repay_day?: Maybe<Scalars['Int']>;
  next_repay_date?: Maybe<Scalars['date']>;
  next_repay_status?: Maybe<Scalars['String']>;
  register_number?: Maybe<Scalars['String']>;
  remaining_repay_day?: Maybe<Scalars['float8']>;
  repay_date?: Maybe<Scalars['date']>;
  repay_day_of_month?: Maybe<Scalars['String']>;
  repay_status?: Maybe<Scalars['String']>;
};

/** aggregated selection of "contract.agreement_repay_current_month" */
export type ContractAgreementRepayCurrentMonthAggregate = {
  __typename?: 'contract_agreement_repay_current_month_aggregate';
  aggregate?: Maybe<ContractAgreementRepayCurrentMonthAggregateFields>;
  nodes: Array<ContractAgreementRepayCurrentMonth>;
};

/** aggregate fields of "contract.agreement_repay_current_month" */
export type ContractAgreementRepayCurrentMonthAggregateFields = {
  __typename?: 'contract_agreement_repay_current_month_aggregate_fields';
  avg?: Maybe<ContractAgreementRepayCurrentMonthAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ContractAgreementRepayCurrentMonthMaxFields>;
  min?: Maybe<ContractAgreementRepayCurrentMonthMinFields>;
  stddev?: Maybe<ContractAgreementRepayCurrentMonthStddevFields>;
  stddev_pop?: Maybe<ContractAgreementRepayCurrentMonthStddevPopFields>;
  stddev_samp?: Maybe<ContractAgreementRepayCurrentMonthStddevSampFields>;
  sum?: Maybe<ContractAgreementRepayCurrentMonthSumFields>;
  var_pop?: Maybe<ContractAgreementRepayCurrentMonthVarPopFields>;
  var_samp?: Maybe<ContractAgreementRepayCurrentMonthVarSampFields>;
  variance?: Maybe<ContractAgreementRepayCurrentMonthVarianceFields>;
};


/** aggregate fields of "contract.agreement_repay_current_month" */
export type ContractAgreementRepayCurrentMonthAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ContractAgreementRepayCurrentMonthSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ContractAgreementRepayCurrentMonthAvgFields = {
  __typename?: 'contract_agreement_repay_current_month_avg_fields';
  next_remaining_repay_day?: Maybe<Scalars['Float']>;
  remaining_repay_day?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "contract.agreement_repay_current_month". All fields are combined with a logical 'AND'. */
export type ContractAgreementRepayCurrentMonthBoolExp = {
  _and?: Maybe<Array<ContractAgreementRepayCurrentMonthBoolExp>>;
  _not?: Maybe<ContractAgreementRepayCurrentMonthBoolExp>;
  _or?: Maybe<Array<ContractAgreementRepayCurrentMonthBoolExp>>;
  agreement?: Maybe<ContractAgreementBoolExp>;
  contract_number?: Maybe<StringComparisonExp>;
  next_remaining_repay_day?: Maybe<IntComparisonExp>;
  next_repay_date?: Maybe<DateComparisonExp>;
  next_repay_status?: Maybe<StringComparisonExp>;
  register_number?: Maybe<StringComparisonExp>;
  remaining_repay_day?: Maybe<Float8ComparisonExp>;
  repay_date?: Maybe<DateComparisonExp>;
  repay_day_of_month?: Maybe<StringComparisonExp>;
  repay_status?: Maybe<StringComparisonExp>;
};

/** input type for inserting data into table "contract.agreement_repay_current_month" */
export type ContractAgreementRepayCurrentMonthInsertInput = {
  agreement?: Maybe<ContractAgreementObjRelInsertInput>;
  contract_number?: Maybe<Scalars['String']>;
  next_remaining_repay_day?: Maybe<Scalars['Int']>;
  next_repay_date?: Maybe<Scalars['date']>;
  next_repay_status?: Maybe<Scalars['String']>;
  register_number?: Maybe<Scalars['String']>;
  remaining_repay_day?: Maybe<Scalars['float8']>;
  repay_date?: Maybe<Scalars['date']>;
  repay_day_of_month?: Maybe<Scalars['String']>;
  repay_status?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ContractAgreementRepayCurrentMonthMaxFields = {
  __typename?: 'contract_agreement_repay_current_month_max_fields';
  contract_number?: Maybe<Scalars['String']>;
  next_remaining_repay_day?: Maybe<Scalars['Int']>;
  next_repay_date?: Maybe<Scalars['date']>;
  next_repay_status?: Maybe<Scalars['String']>;
  register_number?: Maybe<Scalars['String']>;
  remaining_repay_day?: Maybe<Scalars['float8']>;
  repay_date?: Maybe<Scalars['date']>;
  repay_day_of_month?: Maybe<Scalars['String']>;
  repay_status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ContractAgreementRepayCurrentMonthMinFields = {
  __typename?: 'contract_agreement_repay_current_month_min_fields';
  contract_number?: Maybe<Scalars['String']>;
  next_remaining_repay_day?: Maybe<Scalars['Int']>;
  next_repay_date?: Maybe<Scalars['date']>;
  next_repay_status?: Maybe<Scalars['String']>;
  register_number?: Maybe<Scalars['String']>;
  remaining_repay_day?: Maybe<Scalars['float8']>;
  repay_date?: Maybe<Scalars['date']>;
  repay_day_of_month?: Maybe<Scalars['String']>;
  repay_status?: Maybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "contract.agreement_repay_current_month" */
export type ContractAgreementRepayCurrentMonthObjRelInsertInput = {
  data: ContractAgreementRepayCurrentMonthInsertInput;
};

/** Ordering options when selecting data from "contract.agreement_repay_current_month". */
export type ContractAgreementRepayCurrentMonthOrderBy = {
  agreement?: Maybe<ContractAgreementOrderBy>;
  contract_number?: Maybe<OrderBy>;
  next_remaining_repay_day?: Maybe<OrderBy>;
  next_repay_date?: Maybe<OrderBy>;
  next_repay_status?: Maybe<OrderBy>;
  register_number?: Maybe<OrderBy>;
  remaining_repay_day?: Maybe<OrderBy>;
  repay_date?: Maybe<OrderBy>;
  repay_day_of_month?: Maybe<OrderBy>;
  repay_status?: Maybe<OrderBy>;
};

/** select columns of table "contract.agreement_repay_current_month" */
export enum ContractAgreementRepayCurrentMonthSelectColumn {
  /** column name */
  ContractNumber = 'contract_number',
  /** column name */
  NextRemainingRepayDay = 'next_remaining_repay_day',
  /** column name */
  NextRepayDate = 'next_repay_date',
  /** column name */
  NextRepayStatus = 'next_repay_status',
  /** column name */
  RegisterNumber = 'register_number',
  /** column name */
  RemainingRepayDay = 'remaining_repay_day',
  /** column name */
  RepayDate = 'repay_date',
  /** column name */
  RepayDayOfMonth = 'repay_day_of_month',
  /** column name */
  RepayStatus = 'repay_status'
}

/** aggregate stddev on columns */
export type ContractAgreementRepayCurrentMonthStddevFields = {
  __typename?: 'contract_agreement_repay_current_month_stddev_fields';
  next_remaining_repay_day?: Maybe<Scalars['Float']>;
  remaining_repay_day?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ContractAgreementRepayCurrentMonthStddevPopFields = {
  __typename?: 'contract_agreement_repay_current_month_stddev_pop_fields';
  next_remaining_repay_day?: Maybe<Scalars['Float']>;
  remaining_repay_day?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ContractAgreementRepayCurrentMonthStddevSampFields = {
  __typename?: 'contract_agreement_repay_current_month_stddev_samp_fields';
  next_remaining_repay_day?: Maybe<Scalars['Float']>;
  remaining_repay_day?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ContractAgreementRepayCurrentMonthSumFields = {
  __typename?: 'contract_agreement_repay_current_month_sum_fields';
  next_remaining_repay_day?: Maybe<Scalars['Int']>;
  remaining_repay_day?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type ContractAgreementRepayCurrentMonthVarPopFields = {
  __typename?: 'contract_agreement_repay_current_month_var_pop_fields';
  next_remaining_repay_day?: Maybe<Scalars['Float']>;
  remaining_repay_day?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ContractAgreementRepayCurrentMonthVarSampFields = {
  __typename?: 'contract_agreement_repay_current_month_var_samp_fields';
  next_remaining_repay_day?: Maybe<Scalars['Float']>;
  remaining_repay_day?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ContractAgreementRepayCurrentMonthVarianceFields = {
  __typename?: 'contract_agreement_repay_current_month_variance_fields';
  next_remaining_repay_day?: Maybe<Scalars['Float']>;
  remaining_repay_day?: Maybe<Scalars['Float']>;
};

/** select columns of table "contract.agreement" */
export enum ContractAgreementSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  AfNumber = 'af_number',
  /** column name */
  AfStatus = 'af_status',
  /** column name */
  AfStatusDescription = 'af_status_description',
  /** column name */
  AfStatusName = 'af_status_name',
  /** column name */
  ApprovedDate = 'approved_date',
  /** column name */
  BankBranchId = 'bank_branch_id',
  /** column name */
  BusinessGroup = 'business_group',
  /** column name */
  BusinessType = 'business_type',
  /** column name */
  CfNumber = 'cf_number',
  /** column name */
  ContactDate = 'contact_date',
  /** column name */
  ContactEmail = 'contact_email',
  /** column name */
  ContactFax = 'contact_fax',
  /** column name */
  ContactFirstName = 'contact_first_name',
  /** column name */
  ContactLastName = 'contact_last_name',
  /** column name */
  ContactMobile = 'contact_mobile',
  /** column name */
  ContactTel = 'contact_tel',
  /** column name */
  ContractNumber = 'contract_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CreditTotal = 'credit_total',
  /** column name */
  CustomerType = 'customer_type',
  /** column name */
  DDamt = 'd_damt',
  /** column name */
  EffectiveDate = 'effective_date',
  /** column name */
  FirstRepayDate = 'first_repay_date',
  /** column name */
  FirstWithdrawDate = 'first_withdraw_date',
  /** column name */
  GracePeriod = 'grace_period',
  /** column name */
  GuaranteeType = 'guarantee_type',
  /** column name */
  GuarantorCitizenId = 'guarantor_citizen_id',
  /** column name */
  GuarantorDescription = 'guarantor_description',
  /** column name */
  GuarantorName = 'guarantor_name',
  /** column name */
  Id = 'id',
  /** column name */
  ImportedAt = 'imported_at',
  /** column name */
  InsuranceDueDate = 'insurance_due_date',
  /** column name */
  JuristicId = 'juristic_id',
  /** column name */
  JuristicNameEn = 'juristic_name_en',
  /** column name */
  JuristicNameTh = 'juristic_name_th',
  /** column name */
  LoanDescription = 'loan_description',
  /** column name */
  MonthlyPayment = 'monthly_payment',
  /** column name */
  Objective = 'objective',
  /** column name */
  Period = 'period',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  ProgramName = 'program_name',
  /** column name */
  ProgramReference = 'program_reference',
  /** column name */
  ProjectDescription = 'project_description',
  /** column name */
  RegisterNumber = 'register_number',
  /** column name */
  RepayDayOfMonth = 'repay_day_of_month',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "contract.agreement" */
export type ContractAgreementSetInput = {
  /** ที่อยู่ */
  address?: Maybe<Scalars['String']>;
  /** เลขที่ใบคำขอกู้ */
  af_number?: Maybe<Scalars['String']>;
  /** สถานะใบคำขอกู้ */
  af_status?: Maybe<Scalars['String']>;
  /** รายละเอียดสถานะ */
  af_status_description?: Maybe<Scalars['String']>;
  /** AFStatusName */
  af_status_name?: Maybe<Scalars['String']>;
  /** วันที่อนุมัติ */
  approved_date?: Maybe<Scalars['date']>;
  /** ธนาคารทีรับผิดชอบ */
  bank_branch_id?: Maybe<Scalars['String']>;
  /** BusGroupDetail */
  business_group?: Maybe<Scalars['String']>;
  /** ประเภทธุรกิจ */
  business_type?: Maybe<Scalars['String']>;
  /** เลขที่ติดต่อ */
  cf_number?: Maybe<Scalars['String']>;
  /** วันที่ติดต่อ */
  contact_date?: Maybe<Scalars['date']>;
  /** ผู้ติดต่อ: อีเมล */
  contact_email?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: โทรสาร */
  contact_fax?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: ชื่อ */
  contact_first_name?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: นามสกุล */
  contact_last_name?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: โทรศัพท์มือถือ */
  contact_mobile?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ: โทรศัพท์ */
  contact_tel?: Maybe<Scalars['String']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<Scalars['numeric']>;
  /** Custtype */
  customer_type?: Maybe<Scalars['String']>;
  /** DDamt */
  d_damt?: Maybe<Scalars['numeric']>;
  /** วันที่ลงนามในสัญญา */
  effective_date?: Maybe<Scalars['date']>;
  /** วันที่เริ่มชำระครั้งแรก */
  first_repay_date?: Maybe<Scalars['date']>;
  /** วันที่เบิกครั้งแรก */
  first_withdraw_date?: Maybe<Scalars['date']>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<Scalars['numeric']>;
  /** ประเภทการค้ำประกัน */
  guarantee_type?: Maybe<Scalars['String']>;
  /** เลขประจำตัวประชาชนผู้ค้ำประกัน */
  guarantor_citizen_id?: Maybe<Scalars['String']>;
  /** รายละเอียดผู้ค้ำประกัน */
  guarantor_description?: Maybe<Scalars['String']>;
  /** ชื่อผู้คำประกัน */
  guarantor_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** เวลาที่บันทึกผ่านการ import ข้อมูล ถ้าค่าเป็น null หมายความว่า ผู้ใช้งานเป็นผู้สร้างข้อมูล */
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** วันหมดอายุกรมธรรม์คุ้มครองทรัพย์ */
  insurance_due_date?: Maybe<Scalars['date']>;
  /** เลขนิติบุคคล */
  juristic_id?: Maybe<Scalars['String']>;
  /** ชื่อนิติบุคคลภาษาอังกฤษ */
  juristic_name_en?: Maybe<Scalars['String']>;
  /** ชื่อนิติบุคคลภาษาไทย */
  juristic_name_th?: Maybe<Scalars['String']>;
  /** LoanDetail */
  loan_description?: Maybe<Scalars['String']>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<Scalars['numeric']>;
  /** วัตถุประสงค์การกู้ */
  objective?: Maybe<Scalars['String']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['String']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** ชื่อโครงการ */
  program_name?: Maybe<Scalars['String']>;
  /** รหัสโครงการ */
  program_reference?: Maybe<Scalars['String']>;
  /** ProjectDetail */
  project_description?: Maybe<Scalars['String']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** วันกำหนดชำระเงินกู้แต่ละเดือน */
  repay_day_of_month?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/**
 * สถานะสัญญา/ลูกหนี้
 *
 *
 * columns and relationships of "contract.agreement_status"
 */
export type ContractAgreementStatus = {
  __typename?: 'contract_agreement_status';
  /** สถานะสัญญา/ลูกหนี้ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value: Scalars['String'];
};

/** aggregated selection of "contract.agreement_status" */
export type ContractAgreementStatusAggregate = {
  __typename?: 'contract_agreement_status_aggregate';
  aggregate?: Maybe<ContractAgreementStatusAggregateFields>;
  nodes: Array<ContractAgreementStatus>;
};

/** aggregate fields of "contract.agreement_status" */
export type ContractAgreementStatusAggregateFields = {
  __typename?: 'contract_agreement_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContractAgreementStatusMaxFields>;
  min?: Maybe<ContractAgreementStatusMinFields>;
};


/** aggregate fields of "contract.agreement_status" */
export type ContractAgreementStatusAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ContractAgreementStatusSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "contract.agreement_status". All fields are combined with a logical 'AND'. */
export type ContractAgreementStatusBoolExp = {
  _and?: Maybe<Array<ContractAgreementStatusBoolExp>>;
  _not?: Maybe<ContractAgreementStatusBoolExp>;
  _or?: Maybe<Array<ContractAgreementStatusBoolExp>>;
  description?: Maybe<StringComparisonExp>;
  value?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "contract.agreement_status" */
export enum ContractAgreementStatusConstraint {
  /** unique or primary key constraint */
  AgreementStatusPkey = 'agreement_status_pkey'
}

/** input type for inserting data into table "contract.agreement_status" */
export type ContractAgreementStatusInsertInput = {
  /** สถานะสัญญา/ลูกหนี้ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/**
 * จับคู่สถานะระหว่างธนาคารกับระบบ
 *
 *
 * columns and relationships of "contract.agreement_status_mapper"
 */
export type ContractAgreementStatusMapper = {
  __typename?: 'contract_agreement_status_mapper';
  /** สถานะจากธนาคาร */
  bank_status: Scalars['String'];
  /** สถานะภายในระบบ */
  status?: Maybe<Scalars['String']>;
};

/** aggregated selection of "contract.agreement_status_mapper" */
export type ContractAgreementStatusMapperAggregate = {
  __typename?: 'contract_agreement_status_mapper_aggregate';
  aggregate?: Maybe<ContractAgreementStatusMapperAggregateFields>;
  nodes: Array<ContractAgreementStatusMapper>;
};

/** aggregate fields of "contract.agreement_status_mapper" */
export type ContractAgreementStatusMapperAggregateFields = {
  __typename?: 'contract_agreement_status_mapper_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContractAgreementStatusMapperMaxFields>;
  min?: Maybe<ContractAgreementStatusMapperMinFields>;
};


/** aggregate fields of "contract.agreement_status_mapper" */
export type ContractAgreementStatusMapperAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ContractAgreementStatusMapperSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "contract.agreement_status_mapper". All fields are combined with a logical 'AND'. */
export type ContractAgreementStatusMapperBoolExp = {
  _and?: Maybe<Array<ContractAgreementStatusMapperBoolExp>>;
  _not?: Maybe<ContractAgreementStatusMapperBoolExp>;
  _or?: Maybe<Array<ContractAgreementStatusMapperBoolExp>>;
  bank_status?: Maybe<StringComparisonExp>;
  status?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "contract.agreement_status_mapper" */
export enum ContractAgreementStatusMapperConstraint {
  /** unique or primary key constraint */
  AgreementStatusMapperPkey = 'agreement_status_mapper_pkey'
}

/** input type for inserting data into table "contract.agreement_status_mapper" */
export type ContractAgreementStatusMapperInsertInput = {
  /** สถานะจากธนาคาร */
  bank_status?: Maybe<Scalars['String']>;
  /** สถานะภายในระบบ */
  status?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ContractAgreementStatusMapperMaxFields = {
  __typename?: 'contract_agreement_status_mapper_max_fields';
  /** สถานะจากธนาคาร */
  bank_status?: Maybe<Scalars['String']>;
  /** สถานะภายในระบบ */
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ContractAgreementStatusMapperMinFields = {
  __typename?: 'contract_agreement_status_mapper_min_fields';
  /** สถานะจากธนาคาร */
  bank_status?: Maybe<Scalars['String']>;
  /** สถานะภายในระบบ */
  status?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "contract.agreement_status_mapper" */
export type ContractAgreementStatusMapperMutationResponse = {
  __typename?: 'contract_agreement_status_mapper_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContractAgreementStatusMapper>;
};

/** on conflict condition type for table "contract.agreement_status_mapper" */
export type ContractAgreementStatusMapperOnConflict = {
  constraint: ContractAgreementStatusMapperConstraint;
  update_columns?: Array<ContractAgreementStatusMapperUpdateColumn>;
  where?: Maybe<ContractAgreementStatusMapperBoolExp>;
};

/** Ordering options when selecting data from "contract.agreement_status_mapper". */
export type ContractAgreementStatusMapperOrderBy = {
  bank_status?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
};

/** primary key columns input for table: contract_agreement_status_mapper */
export type ContractAgreementStatusMapperPkColumnsInput = {
  /** สถานะจากธนาคาร */
  bank_status: Scalars['String'];
};

/** select columns of table "contract.agreement_status_mapper" */
export enum ContractAgreementStatusMapperSelectColumn {
  /** column name */
  BankStatus = 'bank_status',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "contract.agreement_status_mapper" */
export type ContractAgreementStatusMapperSetInput = {
  /** สถานะจากธนาคาร */
  bank_status?: Maybe<Scalars['String']>;
  /** สถานะภายในระบบ */
  status?: Maybe<Scalars['String']>;
};

/** update columns of table "contract.agreement_status_mapper" */
export enum ContractAgreementStatusMapperUpdateColumn {
  /** column name */
  BankStatus = 'bank_status',
  /** column name */
  Status = 'status'
}

/** aggregate max on columns */
export type ContractAgreementStatusMaxFields = {
  __typename?: 'contract_agreement_status_max_fields';
  /** สถานะสัญญา/ลูกหนี้ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ContractAgreementStatusMinFields = {
  __typename?: 'contract_agreement_status_min_fields';
  /** สถานะสัญญา/ลูกหนี้ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "contract.agreement_status" */
export type ContractAgreementStatusMutationResponse = {
  __typename?: 'contract_agreement_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContractAgreementStatus>;
};

/** on conflict condition type for table "contract.agreement_status" */
export type ContractAgreementStatusOnConflict = {
  constraint: ContractAgreementStatusConstraint;
  update_columns?: Array<ContractAgreementStatusUpdateColumn>;
  where?: Maybe<ContractAgreementStatusBoolExp>;
};

/** Ordering options when selecting data from "contract.agreement_status". */
export type ContractAgreementStatusOrderBy = {
  description?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
};

/** primary key columns input for table: contract_agreement_status */
export type ContractAgreementStatusPkColumnsInput = {
  /** Primary key */
  value: Scalars['String'];
};

/** select columns of table "contract.agreement_status" */
export enum ContractAgreementStatusSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "contract.agreement_status" */
export type ContractAgreementStatusSetInput = {
  /** สถานะสัญญา/ลูกหนี้ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "contract.agreement_status" */
export enum ContractAgreementStatusUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type ContractAgreementStddevFields = {
  __typename?: 'contract_agreement_stddev_fields';
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<Scalars['Float']>;
  /** DDamt */
  d_damt?: Maybe<Scalars['Float']>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<Scalars['Float']>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contract.agreement" */
export type ContractAgreementStddevOrderBy = {
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<OrderBy>;
  /** DDamt */
  d_damt?: Maybe<OrderBy>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<OrderBy>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ContractAgreementStddevPopFields = {
  __typename?: 'contract_agreement_stddev_pop_fields';
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<Scalars['Float']>;
  /** DDamt */
  d_damt?: Maybe<Scalars['Float']>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<Scalars['Float']>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contract.agreement" */
export type ContractAgreementStddevPopOrderBy = {
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<OrderBy>;
  /** DDamt */
  d_damt?: Maybe<OrderBy>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<OrderBy>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ContractAgreementStddevSampFields = {
  __typename?: 'contract_agreement_stddev_samp_fields';
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<Scalars['Float']>;
  /** DDamt */
  d_damt?: Maybe<Scalars['Float']>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<Scalars['Float']>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contract.agreement" */
export type ContractAgreementStddevSampOrderBy = {
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<OrderBy>;
  /** DDamt */
  d_damt?: Maybe<OrderBy>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<OrderBy>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ContractAgreementSumFields = {
  __typename?: 'contract_agreement_sum_fields';
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<Scalars['numeric']>;
  /** DDamt */
  d_damt?: Maybe<Scalars['numeric']>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<Scalars['numeric']>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "contract.agreement" */
export type ContractAgreementSumOrderBy = {
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<OrderBy>;
  /** DDamt */
  d_damt?: Maybe<OrderBy>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<OrderBy>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<OrderBy>;
};

/**
 * ประวัติการเปลี่ยนแปลงข้อมูลของลูกหนี้ ตามสัญญา
 *
 *
 * columns and relationships of "contract.agreement_transaction"
 */
export type ContractAgreementTransaction = {
  __typename?: 'contract_agreement_transaction';
  /** รวม Accru */
  accru_total?: Maybe<Scalars['numeric']>;
  /** เกรด Aging */
  aging_grade?: Maybe<Scalars['String']>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  agreement?: Maybe<ContractAgreement>;
  approved: Scalars['Boolean'];
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['uuid']>;
  /** วันที่อนุมัติ */
  approved_date?: Maybe<Scalars['date']>;
  /** ARCode */
  ar_code?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่สาขา */
  bank_branch_officer?: Maybe<Scalars['String']>;
  /** BOT Code */
  bot_code?: Maybe<Scalars['String']>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<Scalars['numeric']>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<Scalars['numeric']>;
  /** Cluster */
  cluster?: Maybe<Scalars['String']>;
  /** ฝ่ายสินเชื่อ */
  commercial_credit_department?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่ฝ่ายสินเชื่อ */
  commercial_credit_department_officer?: Maybe<Scalars['String']>;
  /** เลขที่สัญญา */
  contract_number: Scalars['String'];
  /** วันที่บันทึกข้อมูล */
  created_at: Scalars['timestamptz'];
  /** ประเภทผู้สร้างข้อมูล */
  creator_type: ContractTransactionCreatorTypeEnum;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** ฝ่ายปรับโครงสร้างหนี้ */
  debt_restructuring_department?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่ฝ่ายปรับโครงสร้างหนี้ */
  debt_restructuring_department_officer?: Maybe<Scalars['String']>;
  /** Debttype */
  debt_type?: Maybe<Scalars['String']>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<Scalars['numeric']>;
  /** ฝ่ายที่ดูแลแฟ้มปัจจุบัน */
  document_department?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่ดูแลแฟ้มปัจจุบัน */
  document_department_officer?: Maybe<Scalars['String']>;
  /** วันที่ครบกำหนด */
  due_date?: Maybe<Scalars['date']>;
  /** วันที่ทำสัญญา */
  effective_date?: Maybe<Scalars['date']>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<Scalars['numeric']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['numeric']>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<Scalars['numeric']>;
  /** วันที่เริ่มชำระครั้งแรก */
  first_repay_date?: Maybe<Scalars['date']>;
  /** FullyRepayFlag */
  fully_repay_flag?: Maybe<Scalars['String']>;
  /** An object relationship */
  grade?: Maybe<ContractGrade>;
  /** GradeGL */
  grade_gl?: Maybe<Scalars['String']>;
  /** เกรดปัจจุบัน */
  grade_value?: Maybe<ContractGradeEnum>;
  /** Primary key */
  id: Scalars['uuid'];
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** Industry */
  industry?: Maybe<Scalars['String']>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<Scalars['numeric']>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<Scalars['numeric']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['numeric']>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<Scalars['numeric']>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<Scalars['numeric']>;
  /** ประเภทดอกเบี้ย */
  interest_rate_type?: Maybe<Scalars['String']>;
  /** วันที่ชำระครั้งสุดท้าย */
  last_repay_date?: Maybe<Scalars['date']>;
  /** ฝ่ายคดี */
  lawsuit_department?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่ฝ่ายคดี */
  lawsuit_department_officer?: Maybe<Scalars['String']>;
  /** ค่างวด */
  monthly_payment?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<Scalars['numeric']>;
  /** Old-Ref. */
  old_ref?: Maybe<Scalars['String']>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<Scalars['numeric']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['numeric']>;
  /** วันที่เริ่มค้างเงินต้น */
  outstanding_capital_start_date?: Maybe<Scalars['date']>;
  /** วันที่เริ่มค้างดอกเบี้ย */
  outstanding_interest_start_date?: Maybe<Scalars['date']>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<Scalars['numeric']>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<Scalars['numeric']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['numeric']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['numeric']>;
  /** รหัสโครงการ (ธนาคาร) */
  project_reference?: Maybe<Scalars['String']>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<Scalars['numeric']>;
  /** ทะเบียนลูกค้า */
  register_number: Scalars['String'];
  /** รวมรับชำระ */
  repay_balance?: Maybe<Scalars['numeric']>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<Scalars['Int']>;
  /** RES-N */
  res_n?: Maybe<Scalars['String']>;
  /** บริษัทที่ดูแลหนี้ */
  responsible_organization?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อตกลงปรับโครงสร้างหนี้ */
  restructuring_approved_date?: Maybe<Scalars['date']>;
  /** วันที่ปรับโครงสร้างหนี้ */
  restructuring_effective_date?: Maybe<Scalars['date']>;
  /** วันที่เริ่มชำระดอกเบี้ยตามเงื่อนไขปรับโครงสร้างหนี้ */
  restructuring_first_repay_date?: Maybe<Scalars['date']>;
  /** Spread Rate */
  spread_rate?: Maybe<Scalars['numeric']>;
  /** Status */
  status?: Maybe<Scalars['String']>;
  /** พักชำระหนี้ */
  suspended?: Maybe<Scalars['String']>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<Scalars['numeric']>;
  /** TermOverdueGL */
  term_overdue_gl?: Maybe<Scalars['String']>;
  /** An object relationship */
  transaction_creator_type: ContractTransactionCreatorType;
  /** An object relationship */
  user?: Maybe<Users>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<Scalars['uuid']>;
  /** วันที่เบิกเงินกู้ */
  withdraw_date?: Maybe<Scalars['date']>;
};

/** aggregated selection of "contract.agreement_transaction" */
export type ContractAgreementTransactionAggregate = {
  __typename?: 'contract_agreement_transaction_aggregate';
  aggregate?: Maybe<ContractAgreementTransactionAggregateFields>;
  nodes: Array<ContractAgreementTransaction>;
};

/** aggregate fields of "contract.agreement_transaction" */
export type ContractAgreementTransactionAggregateFields = {
  __typename?: 'contract_agreement_transaction_aggregate_fields';
  avg?: Maybe<ContractAgreementTransactionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ContractAgreementTransactionMaxFields>;
  min?: Maybe<ContractAgreementTransactionMinFields>;
  stddev?: Maybe<ContractAgreementTransactionStddevFields>;
  stddev_pop?: Maybe<ContractAgreementTransactionStddevPopFields>;
  stddev_samp?: Maybe<ContractAgreementTransactionStddevSampFields>;
  sum?: Maybe<ContractAgreementTransactionSumFields>;
  var_pop?: Maybe<ContractAgreementTransactionVarPopFields>;
  var_samp?: Maybe<ContractAgreementTransactionVarSampFields>;
  variance?: Maybe<ContractAgreementTransactionVarianceFields>;
};


/** aggregate fields of "contract.agreement_transaction" */
export type ContractAgreementTransactionAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ContractAgreementTransactionSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contract.agreement_transaction" */
export type ContractAgreementTransactionAggregateOrderBy = {
  avg?: Maybe<ContractAgreementTransactionAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ContractAgreementTransactionMaxOrderBy>;
  min?: Maybe<ContractAgreementTransactionMinOrderBy>;
  stddev?: Maybe<ContractAgreementTransactionStddevOrderBy>;
  stddev_pop?: Maybe<ContractAgreementTransactionStddevPopOrderBy>;
  stddev_samp?: Maybe<ContractAgreementTransactionStddevSampOrderBy>;
  sum?: Maybe<ContractAgreementTransactionSumOrderBy>;
  var_pop?: Maybe<ContractAgreementTransactionVarPopOrderBy>;
  var_samp?: Maybe<ContractAgreementTransactionVarSampOrderBy>;
  variance?: Maybe<ContractAgreementTransactionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "contract.agreement_transaction" */
export type ContractAgreementTransactionArrRelInsertInput = {
  data: Array<ContractAgreementTransactionInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ContractAgreementTransactionOnConflict>;
};

/** aggregate avg on columns */
export type ContractAgreementTransactionAvgFields = {
  __typename?: 'contract_agreement_transaction_avg_fields';
  /** รวม Accru */
  accru_total?: Maybe<Scalars['Float']>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<Scalars['Float']>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<Scalars['Float']>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<Scalars['Float']>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<Scalars['Float']>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<Scalars['Float']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['Float']>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<Scalars['Float']>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<Scalars['Float']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['Float']>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<Scalars['Float']>;
  /** ค่างวด */
  monthly_payment?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<Scalars['Float']>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<Scalars['Float']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['Float']>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<Scalars['Float']>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<Scalars['Float']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['Float']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<Scalars['Float']>;
  /** รวมรับชำระ */
  repay_balance?: Maybe<Scalars['Float']>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<Scalars['Float']>;
  /** Spread Rate */
  spread_rate?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contract.agreement_transaction" */
export type ContractAgreementTransactionAvgOrderBy = {
  /** รวม Accru */
  accru_total?: Maybe<OrderBy>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<OrderBy>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<OrderBy>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<OrderBy>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<OrderBy>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<OrderBy>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<OrderBy>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<OrderBy>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<OrderBy>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<OrderBy>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<OrderBy>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<OrderBy>;
  /** ค่างวด */
  monthly_payment?: Maybe<OrderBy>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<OrderBy>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<OrderBy>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<OrderBy>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<OrderBy>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<OrderBy>;
  /** ระยะเวลากู้ */
  period?: Maybe<OrderBy>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<OrderBy>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<OrderBy>;
  /** รวมรับชำระ */
  repay_balance?: Maybe<OrderBy>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<OrderBy>;
  /** Spread Rate */
  spread_rate?: Maybe<OrderBy>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<OrderBy>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<OrderBy>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "contract.agreement_transaction". All fields are combined with a logical 'AND'. */
export type ContractAgreementTransactionBoolExp = {
  _and?: Maybe<Array<ContractAgreementTransactionBoolExp>>;
  _not?: Maybe<ContractAgreementTransactionBoolExp>;
  _or?: Maybe<Array<ContractAgreementTransactionBoolExp>>;
  accru_total?: Maybe<NumericComparisonExp>;
  aging_grade?: Maybe<StringComparisonExp>;
  aging_outstanding_period?: Maybe<NumericComparisonExp>;
  agreement?: Maybe<ContractAgreementBoolExp>;
  approved?: Maybe<BooleanComparisonExp>;
  approved_at?: Maybe<TimestamptzComparisonExp>;
  approved_by?: Maybe<UuidComparisonExp>;
  approved_date?: Maybe<DateComparisonExp>;
  ar_code?: Maybe<StringComparisonExp>;
  bank_branch_officer?: Maybe<StringComparisonExp>;
  bot_code?: Maybe<StringComparisonExp>;
  capital_npl?: Maybe<NumericComparisonExp>;
  capital_outstanding?: Maybe<NumericComparisonExp>;
  cluster?: Maybe<StringComparisonExp>;
  commercial_credit_department?: Maybe<StringComparisonExp>;
  commercial_credit_department_officer?: Maybe<StringComparisonExp>;
  contract_number?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  creator_type?: Maybe<ContractTransactionCreatorTypeEnumComparisonExp>;
  credit_amount?: Maybe<NumericComparisonExp>;
  credit_total?: Maybe<NumericComparisonExp>;
  debt_restructuring_department?: Maybe<StringComparisonExp>;
  debt_restructuring_department_officer?: Maybe<StringComparisonExp>;
  debt_type?: Maybe<StringComparisonExp>;
  default_rate?: Maybe<NumericComparisonExp>;
  document_department?: Maybe<StringComparisonExp>;
  document_department_officer?: Maybe<StringComparisonExp>;
  due_date?: Maybe<DateComparisonExp>;
  effective_date?: Maybe<DateComparisonExp>;
  fine_accru?: Maybe<NumericComparisonExp>;
  fine_amount?: Maybe<NumericComparisonExp>;
  fine_outstanding?: Maybe<NumericComparisonExp>;
  first_repay_date?: Maybe<DateComparisonExp>;
  fully_repay_flag?: Maybe<StringComparisonExp>;
  grade?: Maybe<ContractGradeBoolExp>;
  grade_gl?: Maybe<StringComparisonExp>;
  grade_value?: Maybe<ContractGradeEnumComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  imported_at?: Maybe<TimestamptzComparisonExp>;
  industry?: Maybe<StringComparisonExp>;
  insurance_interest?: Maybe<NumericComparisonExp>;
  interest_accru?: Maybe<NumericComparisonExp>;
  interest_amount?: Maybe<NumericComparisonExp>;
  interest_outstanding?: Maybe<NumericComparisonExp>;
  interest_rate?: Maybe<NumericComparisonExp>;
  interest_rate_type?: Maybe<StringComparisonExp>;
  last_repay_date?: Maybe<DateComparisonExp>;
  lawsuit_department?: Maybe<StringComparisonExp>;
  lawsuit_department_officer?: Maybe<StringComparisonExp>;
  monthly_payment?: Maybe<NumericComparisonExp>;
  non_recognition_interest?: Maybe<NumericComparisonExp>;
  old_ref?: Maybe<StringComparisonExp>;
  out_insurance?: Maybe<NumericComparisonExp>;
  outstanding_balance?: Maybe<NumericComparisonExp>;
  outstanding_capital_start_date?: Maybe<DateComparisonExp>;
  outstanding_interest_start_date?: Maybe<DateComparisonExp>;
  outstanding_period?: Maybe<NumericComparisonExp>;
  outstanding_total?: Maybe<NumericComparisonExp>;
  period?: Maybe<NumericComparisonExp>;
  previous_outstanding_balance?: Maybe<NumericComparisonExp>;
  project_reference?: Maybe<StringComparisonExp>;
  recognition_interest?: Maybe<NumericComparisonExp>;
  register_number?: Maybe<StringComparisonExp>;
  repay_balance?: Maybe<NumericComparisonExp>;
  repay_day_of_month?: Maybe<IntComparisonExp>;
  res_n?: Maybe<StringComparisonExp>;
  responsible_organization?: Maybe<StringComparisonExp>;
  restructuring_approved_date?: Maybe<DateComparisonExp>;
  restructuring_effective_date?: Maybe<DateComparisonExp>;
  restructuring_first_repay_date?: Maybe<DateComparisonExp>;
  spread_rate?: Maybe<NumericComparisonExp>;
  status?: Maybe<StringComparisonExp>;
  suspended?: Maybe<StringComparisonExp>;
  suspened_interest?: Maybe<NumericComparisonExp>;
  suspened_non_recognition_interest?: Maybe<NumericComparisonExp>;
  suspened_recognition_interest?: Maybe<NumericComparisonExp>;
  term_overdue_gl?: Maybe<StringComparisonExp>;
  transaction_creator_type?: Maybe<ContractTransactionCreatorTypeBoolExp>;
  user?: Maybe<UsersBoolExp>;
  user_id?: Maybe<UuidComparisonExp>;
  withdraw_date?: Maybe<DateComparisonExp>;
};

/** unique or primary key constraints on table "contract.agreement_transaction" */
export enum ContractAgreementTransactionConstraint {
  /** unique or primary key constraint */
  AgreementTransactionPkey = 'agreement_transaction_pkey',
  /** unique or primary key constraint */
  AgreementTransactionRegisterNumberContractNumberLastRepa = 'agreement_transaction_register_number_contract_number_last_repa'
}

/** input type for incrementing numeric columns in table "contract.agreement_transaction" */
export type ContractAgreementTransactionIncInput = {
  /** รวม Accru */
  accru_total?: Maybe<Scalars['numeric']>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<Scalars['numeric']>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<Scalars['numeric']>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<Scalars['numeric']>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<Scalars['numeric']>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<Scalars['numeric']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['numeric']>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<Scalars['numeric']>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<Scalars['numeric']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['numeric']>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<Scalars['numeric']>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<Scalars['numeric']>;
  /** ค่างวด */
  monthly_payment?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<Scalars['numeric']>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<Scalars['numeric']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['numeric']>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<Scalars['numeric']>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<Scalars['numeric']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['numeric']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<Scalars['numeric']>;
  /** รวมรับชำระ */
  repay_balance?: Maybe<Scalars['numeric']>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<Scalars['Int']>;
  /** Spread Rate */
  spread_rate?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "contract.agreement_transaction" */
export type ContractAgreementTransactionInsertInput = {
  /** รวม Accru */
  accru_total?: Maybe<Scalars['numeric']>;
  /** เกรด Aging */
  aging_grade?: Maybe<Scalars['String']>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<Scalars['numeric']>;
  agreement?: Maybe<ContractAgreementObjRelInsertInput>;
  approved?: Maybe<Scalars['Boolean']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['uuid']>;
  /** วันที่อนุมัติ */
  approved_date?: Maybe<Scalars['date']>;
  /** ARCode */
  ar_code?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่สาขา */
  bank_branch_officer?: Maybe<Scalars['String']>;
  /** BOT Code */
  bot_code?: Maybe<Scalars['String']>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<Scalars['numeric']>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<Scalars['numeric']>;
  /** Cluster */
  cluster?: Maybe<Scalars['String']>;
  /** ฝ่ายสินเชื่อ */
  commercial_credit_department?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่ฝ่ายสินเชื่อ */
  commercial_credit_department_officer?: Maybe<Scalars['String']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** ประเภทผู้สร้างข้อมูล */
  creator_type?: Maybe<ContractTransactionCreatorTypeEnum>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** ฝ่ายปรับโครงสร้างหนี้ */
  debt_restructuring_department?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่ฝ่ายปรับโครงสร้างหนี้ */
  debt_restructuring_department_officer?: Maybe<Scalars['String']>;
  /** Debttype */
  debt_type?: Maybe<Scalars['String']>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<Scalars['numeric']>;
  /** ฝ่ายที่ดูแลแฟ้มปัจจุบัน */
  document_department?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่ดูแลแฟ้มปัจจุบัน */
  document_department_officer?: Maybe<Scalars['String']>;
  /** วันที่ครบกำหนด */
  due_date?: Maybe<Scalars['date']>;
  /** วันที่ทำสัญญา */
  effective_date?: Maybe<Scalars['date']>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<Scalars['numeric']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['numeric']>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<Scalars['numeric']>;
  /** วันที่เริ่มชำระครั้งแรก */
  first_repay_date?: Maybe<Scalars['date']>;
  /** FullyRepayFlag */
  fully_repay_flag?: Maybe<Scalars['String']>;
  grade?: Maybe<ContractGradeObjRelInsertInput>;
  /** GradeGL */
  grade_gl?: Maybe<Scalars['String']>;
  /** เกรดปัจจุบัน */
  grade_value?: Maybe<ContractGradeEnum>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** Industry */
  industry?: Maybe<Scalars['String']>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<Scalars['numeric']>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<Scalars['numeric']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['numeric']>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<Scalars['numeric']>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<Scalars['numeric']>;
  /** ประเภทดอกเบี้ย */
  interest_rate_type?: Maybe<Scalars['String']>;
  /** วันที่ชำระครั้งสุดท้าย */
  last_repay_date?: Maybe<Scalars['date']>;
  /** ฝ่ายคดี */
  lawsuit_department?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่ฝ่ายคดี */
  lawsuit_department_officer?: Maybe<Scalars['String']>;
  /** ค่างวด */
  monthly_payment?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<Scalars['numeric']>;
  /** Old-Ref. */
  old_ref?: Maybe<Scalars['String']>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<Scalars['numeric']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['numeric']>;
  /** วันที่เริ่มค้างเงินต้น */
  outstanding_capital_start_date?: Maybe<Scalars['date']>;
  /** วันที่เริ่มค้างดอกเบี้ย */
  outstanding_interest_start_date?: Maybe<Scalars['date']>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<Scalars['numeric']>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<Scalars['numeric']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['numeric']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['numeric']>;
  /** รหัสโครงการ (ธนาคาร) */
  project_reference?: Maybe<Scalars['String']>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<Scalars['numeric']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** รวมรับชำระ */
  repay_balance?: Maybe<Scalars['numeric']>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<Scalars['Int']>;
  /** RES-N */
  res_n?: Maybe<Scalars['String']>;
  /** บริษัทที่ดูแลหนี้ */
  responsible_organization?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อตกลงปรับโครงสร้างหนี้ */
  restructuring_approved_date?: Maybe<Scalars['date']>;
  /** วันที่ปรับโครงสร้างหนี้ */
  restructuring_effective_date?: Maybe<Scalars['date']>;
  /** วันที่เริ่มชำระดอกเบี้ยตามเงื่อนไขปรับโครงสร้างหนี้ */
  restructuring_first_repay_date?: Maybe<Scalars['date']>;
  /** Spread Rate */
  spread_rate?: Maybe<Scalars['numeric']>;
  /** Status */
  status?: Maybe<Scalars['String']>;
  /** พักชำระหนี้ */
  suspended?: Maybe<Scalars['String']>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<Scalars['numeric']>;
  /** TermOverdueGL */
  term_overdue_gl?: Maybe<Scalars['String']>;
  transaction_creator_type?: Maybe<ContractTransactionCreatorTypeObjRelInsertInput>;
  user?: Maybe<UsersObjRelInsertInput>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<Scalars['uuid']>;
  /** วันที่เบิกเงินกู้ */
  withdraw_date?: Maybe<Scalars['date']>;
};

/** aggregate max on columns */
export type ContractAgreementTransactionMaxFields = {
  __typename?: 'contract_agreement_transaction_max_fields';
  /** รวม Accru */
  accru_total?: Maybe<Scalars['numeric']>;
  /** เกรด Aging */
  aging_grade?: Maybe<Scalars['String']>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<Scalars['numeric']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['uuid']>;
  /** วันที่อนุมัติ */
  approved_date?: Maybe<Scalars['date']>;
  /** ARCode */
  ar_code?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่สาขา */
  bank_branch_officer?: Maybe<Scalars['String']>;
  /** BOT Code */
  bot_code?: Maybe<Scalars['String']>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<Scalars['numeric']>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<Scalars['numeric']>;
  /** Cluster */
  cluster?: Maybe<Scalars['String']>;
  /** ฝ่ายสินเชื่อ */
  commercial_credit_department?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่ฝ่ายสินเชื่อ */
  commercial_credit_department_officer?: Maybe<Scalars['String']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** ฝ่ายปรับโครงสร้างหนี้ */
  debt_restructuring_department?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่ฝ่ายปรับโครงสร้างหนี้ */
  debt_restructuring_department_officer?: Maybe<Scalars['String']>;
  /** Debttype */
  debt_type?: Maybe<Scalars['String']>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<Scalars['numeric']>;
  /** ฝ่ายที่ดูแลแฟ้มปัจจุบัน */
  document_department?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่ดูแลแฟ้มปัจจุบัน */
  document_department_officer?: Maybe<Scalars['String']>;
  /** วันที่ครบกำหนด */
  due_date?: Maybe<Scalars['date']>;
  /** วันที่ทำสัญญา */
  effective_date?: Maybe<Scalars['date']>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<Scalars['numeric']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['numeric']>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<Scalars['numeric']>;
  /** วันที่เริ่มชำระครั้งแรก */
  first_repay_date?: Maybe<Scalars['date']>;
  /** FullyRepayFlag */
  fully_repay_flag?: Maybe<Scalars['String']>;
  /** GradeGL */
  grade_gl?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** Industry */
  industry?: Maybe<Scalars['String']>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<Scalars['numeric']>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<Scalars['numeric']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['numeric']>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<Scalars['numeric']>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<Scalars['numeric']>;
  /** ประเภทดอกเบี้ย */
  interest_rate_type?: Maybe<Scalars['String']>;
  /** วันที่ชำระครั้งสุดท้าย */
  last_repay_date?: Maybe<Scalars['date']>;
  /** ฝ่ายคดี */
  lawsuit_department?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่ฝ่ายคดี */
  lawsuit_department_officer?: Maybe<Scalars['String']>;
  /** ค่างวด */
  monthly_payment?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<Scalars['numeric']>;
  /** Old-Ref. */
  old_ref?: Maybe<Scalars['String']>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<Scalars['numeric']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['numeric']>;
  /** วันที่เริ่มค้างเงินต้น */
  outstanding_capital_start_date?: Maybe<Scalars['date']>;
  /** วันที่เริ่มค้างดอกเบี้ย */
  outstanding_interest_start_date?: Maybe<Scalars['date']>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<Scalars['numeric']>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<Scalars['numeric']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['numeric']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['numeric']>;
  /** รหัสโครงการ (ธนาคาร) */
  project_reference?: Maybe<Scalars['String']>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<Scalars['numeric']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** รวมรับชำระ */
  repay_balance?: Maybe<Scalars['numeric']>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<Scalars['Int']>;
  /** RES-N */
  res_n?: Maybe<Scalars['String']>;
  /** บริษัทที่ดูแลหนี้ */
  responsible_organization?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อตกลงปรับโครงสร้างหนี้ */
  restructuring_approved_date?: Maybe<Scalars['date']>;
  /** วันที่ปรับโครงสร้างหนี้ */
  restructuring_effective_date?: Maybe<Scalars['date']>;
  /** วันที่เริ่มชำระดอกเบี้ยตามเงื่อนไขปรับโครงสร้างหนี้ */
  restructuring_first_repay_date?: Maybe<Scalars['date']>;
  /** Spread Rate */
  spread_rate?: Maybe<Scalars['numeric']>;
  /** Status */
  status?: Maybe<Scalars['String']>;
  /** พักชำระหนี้ */
  suspended?: Maybe<Scalars['String']>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<Scalars['numeric']>;
  /** TermOverdueGL */
  term_overdue_gl?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<Scalars['uuid']>;
  /** วันที่เบิกเงินกู้ */
  withdraw_date?: Maybe<Scalars['date']>;
};

/** order by max() on columns of table "contract.agreement_transaction" */
export type ContractAgreementTransactionMaxOrderBy = {
  /** รวม Accru */
  accru_total?: Maybe<OrderBy>;
  /** เกรด Aging */
  aging_grade?: Maybe<OrderBy>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<OrderBy>;
  approved_at?: Maybe<OrderBy>;
  approved_by?: Maybe<OrderBy>;
  /** วันที่อนุมัติ */
  approved_date?: Maybe<OrderBy>;
  /** ARCode */
  ar_code?: Maybe<OrderBy>;
  /** เจ้าหน้าที่สาขา */
  bank_branch_officer?: Maybe<OrderBy>;
  /** BOT Code */
  bot_code?: Maybe<OrderBy>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<OrderBy>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<OrderBy>;
  /** Cluster */
  cluster?: Maybe<OrderBy>;
  /** ฝ่ายสินเชื่อ */
  commercial_credit_department?: Maybe<OrderBy>;
  /** เจ้าหน้าที่ฝ่ายสินเชื่อ */
  commercial_credit_department_officer?: Maybe<OrderBy>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** ฝ่ายปรับโครงสร้างหนี้ */
  debt_restructuring_department?: Maybe<OrderBy>;
  /** เจ้าหน้าที่ฝ่ายปรับโครงสร้างหนี้ */
  debt_restructuring_department_officer?: Maybe<OrderBy>;
  /** Debttype */
  debt_type?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<OrderBy>;
  /** ฝ่ายที่ดูแลแฟ้มปัจจุบัน */
  document_department?: Maybe<OrderBy>;
  /** เจ้าหน้าที่ดูแลแฟ้มปัจจุบัน */
  document_department_officer?: Maybe<OrderBy>;
  /** วันที่ครบกำหนด */
  due_date?: Maybe<OrderBy>;
  /** วันที่ทำสัญญา */
  effective_date?: Maybe<OrderBy>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<OrderBy>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<OrderBy>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<OrderBy>;
  /** วันที่เริ่มชำระครั้งแรก */
  first_repay_date?: Maybe<OrderBy>;
  /** FullyRepayFlag */
  fully_repay_flag?: Maybe<OrderBy>;
  /** GradeGL */
  grade_gl?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  imported_at?: Maybe<OrderBy>;
  /** Industry */
  industry?: Maybe<OrderBy>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<OrderBy>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<OrderBy>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<OrderBy>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<OrderBy>;
  /** ประเภทดอกเบี้ย */
  interest_rate_type?: Maybe<OrderBy>;
  /** วันที่ชำระครั้งสุดท้าย */
  last_repay_date?: Maybe<OrderBy>;
  /** ฝ่ายคดี */
  lawsuit_department?: Maybe<OrderBy>;
  /** เจ้าหน้าที่ฝ่ายคดี */
  lawsuit_department_officer?: Maybe<OrderBy>;
  /** ค่างวด */
  monthly_payment?: Maybe<OrderBy>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<OrderBy>;
  /** Old-Ref. */
  old_ref?: Maybe<OrderBy>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<OrderBy>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<OrderBy>;
  /** วันที่เริ่มค้างเงินต้น */
  outstanding_capital_start_date?: Maybe<OrderBy>;
  /** วันที่เริ่มค้างดอกเบี้ย */
  outstanding_interest_start_date?: Maybe<OrderBy>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<OrderBy>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<OrderBy>;
  /** ระยะเวลากู้ */
  period?: Maybe<OrderBy>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<OrderBy>;
  /** รหัสโครงการ (ธนาคาร) */
  project_reference?: Maybe<OrderBy>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<OrderBy>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<OrderBy>;
  /** รวมรับชำระ */
  repay_balance?: Maybe<OrderBy>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<OrderBy>;
  /** RES-N */
  res_n?: Maybe<OrderBy>;
  /** บริษัทที่ดูแลหนี้ */
  responsible_organization?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อตกลงปรับโครงสร้างหนี้ */
  restructuring_approved_date?: Maybe<OrderBy>;
  /** วันที่ปรับโครงสร้างหนี้ */
  restructuring_effective_date?: Maybe<OrderBy>;
  /** วันที่เริ่มชำระดอกเบี้ยตามเงื่อนไขปรับโครงสร้างหนี้ */
  restructuring_first_repay_date?: Maybe<OrderBy>;
  /** Spread Rate */
  spread_rate?: Maybe<OrderBy>;
  /** Status */
  status?: Maybe<OrderBy>;
  /** พักชำระหนี้ */
  suspended?: Maybe<OrderBy>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<OrderBy>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<OrderBy>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<OrderBy>;
  /** TermOverdueGL */
  term_overdue_gl?: Maybe<OrderBy>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<OrderBy>;
  /** วันที่เบิกเงินกู้ */
  withdraw_date?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ContractAgreementTransactionMinFields = {
  __typename?: 'contract_agreement_transaction_min_fields';
  /** รวม Accru */
  accru_total?: Maybe<Scalars['numeric']>;
  /** เกรด Aging */
  aging_grade?: Maybe<Scalars['String']>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<Scalars['numeric']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['uuid']>;
  /** วันที่อนุมัติ */
  approved_date?: Maybe<Scalars['date']>;
  /** ARCode */
  ar_code?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่สาขา */
  bank_branch_officer?: Maybe<Scalars['String']>;
  /** BOT Code */
  bot_code?: Maybe<Scalars['String']>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<Scalars['numeric']>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<Scalars['numeric']>;
  /** Cluster */
  cluster?: Maybe<Scalars['String']>;
  /** ฝ่ายสินเชื่อ */
  commercial_credit_department?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่ฝ่ายสินเชื่อ */
  commercial_credit_department_officer?: Maybe<Scalars['String']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** ฝ่ายปรับโครงสร้างหนี้ */
  debt_restructuring_department?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่ฝ่ายปรับโครงสร้างหนี้ */
  debt_restructuring_department_officer?: Maybe<Scalars['String']>;
  /** Debttype */
  debt_type?: Maybe<Scalars['String']>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<Scalars['numeric']>;
  /** ฝ่ายที่ดูแลแฟ้มปัจจุบัน */
  document_department?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่ดูแลแฟ้มปัจจุบัน */
  document_department_officer?: Maybe<Scalars['String']>;
  /** วันที่ครบกำหนด */
  due_date?: Maybe<Scalars['date']>;
  /** วันที่ทำสัญญา */
  effective_date?: Maybe<Scalars['date']>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<Scalars['numeric']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['numeric']>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<Scalars['numeric']>;
  /** วันที่เริ่มชำระครั้งแรก */
  first_repay_date?: Maybe<Scalars['date']>;
  /** FullyRepayFlag */
  fully_repay_flag?: Maybe<Scalars['String']>;
  /** GradeGL */
  grade_gl?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** Industry */
  industry?: Maybe<Scalars['String']>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<Scalars['numeric']>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<Scalars['numeric']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['numeric']>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<Scalars['numeric']>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<Scalars['numeric']>;
  /** ประเภทดอกเบี้ย */
  interest_rate_type?: Maybe<Scalars['String']>;
  /** วันที่ชำระครั้งสุดท้าย */
  last_repay_date?: Maybe<Scalars['date']>;
  /** ฝ่ายคดี */
  lawsuit_department?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่ฝ่ายคดี */
  lawsuit_department_officer?: Maybe<Scalars['String']>;
  /** ค่างวด */
  monthly_payment?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<Scalars['numeric']>;
  /** Old-Ref. */
  old_ref?: Maybe<Scalars['String']>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<Scalars['numeric']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['numeric']>;
  /** วันที่เริ่มค้างเงินต้น */
  outstanding_capital_start_date?: Maybe<Scalars['date']>;
  /** วันที่เริ่มค้างดอกเบี้ย */
  outstanding_interest_start_date?: Maybe<Scalars['date']>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<Scalars['numeric']>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<Scalars['numeric']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['numeric']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['numeric']>;
  /** รหัสโครงการ (ธนาคาร) */
  project_reference?: Maybe<Scalars['String']>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<Scalars['numeric']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** รวมรับชำระ */
  repay_balance?: Maybe<Scalars['numeric']>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<Scalars['Int']>;
  /** RES-N */
  res_n?: Maybe<Scalars['String']>;
  /** บริษัทที่ดูแลหนี้ */
  responsible_organization?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อตกลงปรับโครงสร้างหนี้ */
  restructuring_approved_date?: Maybe<Scalars['date']>;
  /** วันที่ปรับโครงสร้างหนี้ */
  restructuring_effective_date?: Maybe<Scalars['date']>;
  /** วันที่เริ่มชำระดอกเบี้ยตามเงื่อนไขปรับโครงสร้างหนี้ */
  restructuring_first_repay_date?: Maybe<Scalars['date']>;
  /** Spread Rate */
  spread_rate?: Maybe<Scalars['numeric']>;
  /** Status */
  status?: Maybe<Scalars['String']>;
  /** พักชำระหนี้ */
  suspended?: Maybe<Scalars['String']>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<Scalars['numeric']>;
  /** TermOverdueGL */
  term_overdue_gl?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<Scalars['uuid']>;
  /** วันที่เบิกเงินกู้ */
  withdraw_date?: Maybe<Scalars['date']>;
};

/** order by min() on columns of table "contract.agreement_transaction" */
export type ContractAgreementTransactionMinOrderBy = {
  /** รวม Accru */
  accru_total?: Maybe<OrderBy>;
  /** เกรด Aging */
  aging_grade?: Maybe<OrderBy>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<OrderBy>;
  approved_at?: Maybe<OrderBy>;
  approved_by?: Maybe<OrderBy>;
  /** วันที่อนุมัติ */
  approved_date?: Maybe<OrderBy>;
  /** ARCode */
  ar_code?: Maybe<OrderBy>;
  /** เจ้าหน้าที่สาขา */
  bank_branch_officer?: Maybe<OrderBy>;
  /** BOT Code */
  bot_code?: Maybe<OrderBy>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<OrderBy>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<OrderBy>;
  /** Cluster */
  cluster?: Maybe<OrderBy>;
  /** ฝ่ายสินเชื่อ */
  commercial_credit_department?: Maybe<OrderBy>;
  /** เจ้าหน้าที่ฝ่ายสินเชื่อ */
  commercial_credit_department_officer?: Maybe<OrderBy>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** ฝ่ายปรับโครงสร้างหนี้ */
  debt_restructuring_department?: Maybe<OrderBy>;
  /** เจ้าหน้าที่ฝ่ายปรับโครงสร้างหนี้ */
  debt_restructuring_department_officer?: Maybe<OrderBy>;
  /** Debttype */
  debt_type?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<OrderBy>;
  /** ฝ่ายที่ดูแลแฟ้มปัจจุบัน */
  document_department?: Maybe<OrderBy>;
  /** เจ้าหน้าที่ดูแลแฟ้มปัจจุบัน */
  document_department_officer?: Maybe<OrderBy>;
  /** วันที่ครบกำหนด */
  due_date?: Maybe<OrderBy>;
  /** วันที่ทำสัญญา */
  effective_date?: Maybe<OrderBy>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<OrderBy>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<OrderBy>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<OrderBy>;
  /** วันที่เริ่มชำระครั้งแรก */
  first_repay_date?: Maybe<OrderBy>;
  /** FullyRepayFlag */
  fully_repay_flag?: Maybe<OrderBy>;
  /** GradeGL */
  grade_gl?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  imported_at?: Maybe<OrderBy>;
  /** Industry */
  industry?: Maybe<OrderBy>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<OrderBy>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<OrderBy>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<OrderBy>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<OrderBy>;
  /** ประเภทดอกเบี้ย */
  interest_rate_type?: Maybe<OrderBy>;
  /** วันที่ชำระครั้งสุดท้าย */
  last_repay_date?: Maybe<OrderBy>;
  /** ฝ่ายคดี */
  lawsuit_department?: Maybe<OrderBy>;
  /** เจ้าหน้าที่ฝ่ายคดี */
  lawsuit_department_officer?: Maybe<OrderBy>;
  /** ค่างวด */
  monthly_payment?: Maybe<OrderBy>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<OrderBy>;
  /** Old-Ref. */
  old_ref?: Maybe<OrderBy>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<OrderBy>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<OrderBy>;
  /** วันที่เริ่มค้างเงินต้น */
  outstanding_capital_start_date?: Maybe<OrderBy>;
  /** วันที่เริ่มค้างดอกเบี้ย */
  outstanding_interest_start_date?: Maybe<OrderBy>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<OrderBy>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<OrderBy>;
  /** ระยะเวลากู้ */
  period?: Maybe<OrderBy>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<OrderBy>;
  /** รหัสโครงการ (ธนาคาร) */
  project_reference?: Maybe<OrderBy>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<OrderBy>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<OrderBy>;
  /** รวมรับชำระ */
  repay_balance?: Maybe<OrderBy>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<OrderBy>;
  /** RES-N */
  res_n?: Maybe<OrderBy>;
  /** บริษัทที่ดูแลหนี้ */
  responsible_organization?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อตกลงปรับโครงสร้างหนี้ */
  restructuring_approved_date?: Maybe<OrderBy>;
  /** วันที่ปรับโครงสร้างหนี้ */
  restructuring_effective_date?: Maybe<OrderBy>;
  /** วันที่เริ่มชำระดอกเบี้ยตามเงื่อนไขปรับโครงสร้างหนี้ */
  restructuring_first_repay_date?: Maybe<OrderBy>;
  /** Spread Rate */
  spread_rate?: Maybe<OrderBy>;
  /** Status */
  status?: Maybe<OrderBy>;
  /** พักชำระหนี้ */
  suspended?: Maybe<OrderBy>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<OrderBy>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<OrderBy>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<OrderBy>;
  /** TermOverdueGL */
  term_overdue_gl?: Maybe<OrderBy>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<OrderBy>;
  /** วันที่เบิกเงินกู้ */
  withdraw_date?: Maybe<OrderBy>;
};

/** response of any mutation on the table "contract.agreement_transaction" */
export type ContractAgreementTransactionMutationResponse = {
  __typename?: 'contract_agreement_transaction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContractAgreementTransaction>;
};

/** on conflict condition type for table "contract.agreement_transaction" */
export type ContractAgreementTransactionOnConflict = {
  constraint: ContractAgreementTransactionConstraint;
  update_columns?: Array<ContractAgreementTransactionUpdateColumn>;
  where?: Maybe<ContractAgreementTransactionBoolExp>;
};

/** Ordering options when selecting data from "contract.agreement_transaction". */
export type ContractAgreementTransactionOrderBy = {
  accru_total?: Maybe<OrderBy>;
  aging_grade?: Maybe<OrderBy>;
  aging_outstanding_period?: Maybe<OrderBy>;
  agreement?: Maybe<ContractAgreementOrderBy>;
  approved?: Maybe<OrderBy>;
  approved_at?: Maybe<OrderBy>;
  approved_by?: Maybe<OrderBy>;
  approved_date?: Maybe<OrderBy>;
  ar_code?: Maybe<OrderBy>;
  bank_branch_officer?: Maybe<OrderBy>;
  bot_code?: Maybe<OrderBy>;
  capital_npl?: Maybe<OrderBy>;
  capital_outstanding?: Maybe<OrderBy>;
  cluster?: Maybe<OrderBy>;
  commercial_credit_department?: Maybe<OrderBy>;
  commercial_credit_department_officer?: Maybe<OrderBy>;
  contract_number?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  creator_type?: Maybe<OrderBy>;
  credit_amount?: Maybe<OrderBy>;
  credit_total?: Maybe<OrderBy>;
  debt_restructuring_department?: Maybe<OrderBy>;
  debt_restructuring_department_officer?: Maybe<OrderBy>;
  debt_type?: Maybe<OrderBy>;
  default_rate?: Maybe<OrderBy>;
  document_department?: Maybe<OrderBy>;
  document_department_officer?: Maybe<OrderBy>;
  due_date?: Maybe<OrderBy>;
  effective_date?: Maybe<OrderBy>;
  fine_accru?: Maybe<OrderBy>;
  fine_amount?: Maybe<OrderBy>;
  fine_outstanding?: Maybe<OrderBy>;
  first_repay_date?: Maybe<OrderBy>;
  fully_repay_flag?: Maybe<OrderBy>;
  grade?: Maybe<ContractGradeOrderBy>;
  grade_gl?: Maybe<OrderBy>;
  grade_value?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  imported_at?: Maybe<OrderBy>;
  industry?: Maybe<OrderBy>;
  insurance_interest?: Maybe<OrderBy>;
  interest_accru?: Maybe<OrderBy>;
  interest_amount?: Maybe<OrderBy>;
  interest_outstanding?: Maybe<OrderBy>;
  interest_rate?: Maybe<OrderBy>;
  interest_rate_type?: Maybe<OrderBy>;
  last_repay_date?: Maybe<OrderBy>;
  lawsuit_department?: Maybe<OrderBy>;
  lawsuit_department_officer?: Maybe<OrderBy>;
  monthly_payment?: Maybe<OrderBy>;
  non_recognition_interest?: Maybe<OrderBy>;
  old_ref?: Maybe<OrderBy>;
  out_insurance?: Maybe<OrderBy>;
  outstanding_balance?: Maybe<OrderBy>;
  outstanding_capital_start_date?: Maybe<OrderBy>;
  outstanding_interest_start_date?: Maybe<OrderBy>;
  outstanding_period?: Maybe<OrderBy>;
  outstanding_total?: Maybe<OrderBy>;
  period?: Maybe<OrderBy>;
  previous_outstanding_balance?: Maybe<OrderBy>;
  project_reference?: Maybe<OrderBy>;
  recognition_interest?: Maybe<OrderBy>;
  register_number?: Maybe<OrderBy>;
  repay_balance?: Maybe<OrderBy>;
  repay_day_of_month?: Maybe<OrderBy>;
  res_n?: Maybe<OrderBy>;
  responsible_organization?: Maybe<OrderBy>;
  restructuring_approved_date?: Maybe<OrderBy>;
  restructuring_effective_date?: Maybe<OrderBy>;
  restructuring_first_repay_date?: Maybe<OrderBy>;
  spread_rate?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  suspended?: Maybe<OrderBy>;
  suspened_interest?: Maybe<OrderBy>;
  suspened_non_recognition_interest?: Maybe<OrderBy>;
  suspened_recognition_interest?: Maybe<OrderBy>;
  term_overdue_gl?: Maybe<OrderBy>;
  transaction_creator_type?: Maybe<ContractTransactionCreatorTypeOrderBy>;
  user?: Maybe<UsersOrderBy>;
  user_id?: Maybe<OrderBy>;
  withdraw_date?: Maybe<OrderBy>;
};

/** primary key columns input for table: contract_agreement_transaction */
export type ContractAgreementTransactionPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "contract.agreement_transaction" */
export enum ContractAgreementTransactionSelectColumn {
  /** column name */
  AccruTotal = 'accru_total',
  /** column name */
  AgingGrade = 'aging_grade',
  /** column name */
  AgingOutstandingPeriod = 'aging_outstanding_period',
  /** column name */
  Approved = 'approved',
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  ApprovedBy = 'approved_by',
  /** column name */
  ApprovedDate = 'approved_date',
  /** column name */
  ArCode = 'ar_code',
  /** column name */
  BankBranchOfficer = 'bank_branch_officer',
  /** column name */
  BotCode = 'bot_code',
  /** column name */
  CapitalNpl = 'capital_npl',
  /** column name */
  CapitalOutstanding = 'capital_outstanding',
  /** column name */
  Cluster = 'cluster',
  /** column name */
  CommercialCreditDepartment = 'commercial_credit_department',
  /** column name */
  CommercialCreditDepartmentOfficer = 'commercial_credit_department_officer',
  /** column name */
  ContractNumber = 'contract_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorType = 'creator_type',
  /** column name */
  CreditAmount = 'credit_amount',
  /** column name */
  CreditTotal = 'credit_total',
  /** column name */
  DebtRestructuringDepartment = 'debt_restructuring_department',
  /** column name */
  DebtRestructuringDepartmentOfficer = 'debt_restructuring_department_officer',
  /** column name */
  DebtType = 'debt_type',
  /** column name */
  DefaultRate = 'default_rate',
  /** column name */
  DocumentDepartment = 'document_department',
  /** column name */
  DocumentDepartmentOfficer = 'document_department_officer',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  EffectiveDate = 'effective_date',
  /** column name */
  FineAccru = 'fine_accru',
  /** column name */
  FineAmount = 'fine_amount',
  /** column name */
  FineOutstanding = 'fine_outstanding',
  /** column name */
  FirstRepayDate = 'first_repay_date',
  /** column name */
  FullyRepayFlag = 'fully_repay_flag',
  /** column name */
  GradeGl = 'grade_gl',
  /** column name */
  GradeValue = 'grade_value',
  /** column name */
  Id = 'id',
  /** column name */
  ImportedAt = 'imported_at',
  /** column name */
  Industry = 'industry',
  /** column name */
  InsuranceInterest = 'insurance_interest',
  /** column name */
  InterestAccru = 'interest_accru',
  /** column name */
  InterestAmount = 'interest_amount',
  /** column name */
  InterestOutstanding = 'interest_outstanding',
  /** column name */
  InterestRate = 'interest_rate',
  /** column name */
  InterestRateType = 'interest_rate_type',
  /** column name */
  LastRepayDate = 'last_repay_date',
  /** column name */
  LawsuitDepartment = 'lawsuit_department',
  /** column name */
  LawsuitDepartmentOfficer = 'lawsuit_department_officer',
  /** column name */
  MonthlyPayment = 'monthly_payment',
  /** column name */
  NonRecognitionInterest = 'non_recognition_interest',
  /** column name */
  OldRef = 'old_ref',
  /** column name */
  OutInsurance = 'out_insurance',
  /** column name */
  OutstandingBalance = 'outstanding_balance',
  /** column name */
  OutstandingCapitalStartDate = 'outstanding_capital_start_date',
  /** column name */
  OutstandingInterestStartDate = 'outstanding_interest_start_date',
  /** column name */
  OutstandingPeriod = 'outstanding_period',
  /** column name */
  OutstandingTotal = 'outstanding_total',
  /** column name */
  Period = 'period',
  /** column name */
  PreviousOutstandingBalance = 'previous_outstanding_balance',
  /** column name */
  ProjectReference = 'project_reference',
  /** column name */
  RecognitionInterest = 'recognition_interest',
  /** column name */
  RegisterNumber = 'register_number',
  /** column name */
  RepayBalance = 'repay_balance',
  /** column name */
  RepayDayOfMonth = 'repay_day_of_month',
  /** column name */
  ResN = 'res_n',
  /** column name */
  ResponsibleOrganization = 'responsible_organization',
  /** column name */
  RestructuringApprovedDate = 'restructuring_approved_date',
  /** column name */
  RestructuringEffectiveDate = 'restructuring_effective_date',
  /** column name */
  RestructuringFirstRepayDate = 'restructuring_first_repay_date',
  /** column name */
  SpreadRate = 'spread_rate',
  /** column name */
  Status = 'status',
  /** column name */
  Suspended = 'suspended',
  /** column name */
  SuspenedInterest = 'suspened_interest',
  /** column name */
  SuspenedNonRecognitionInterest = 'suspened_non_recognition_interest',
  /** column name */
  SuspenedRecognitionInterest = 'suspened_recognition_interest',
  /** column name */
  TermOverdueGl = 'term_overdue_gl',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WithdrawDate = 'withdraw_date'
}

/** input type for updating data in table "contract.agreement_transaction" */
export type ContractAgreementTransactionSetInput = {
  /** รวม Accru */
  accru_total?: Maybe<Scalars['numeric']>;
  /** เกรด Aging */
  aging_grade?: Maybe<Scalars['String']>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<Scalars['numeric']>;
  approved?: Maybe<Scalars['Boolean']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['uuid']>;
  /** วันที่อนุมัติ */
  approved_date?: Maybe<Scalars['date']>;
  /** ARCode */
  ar_code?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่สาขา */
  bank_branch_officer?: Maybe<Scalars['String']>;
  /** BOT Code */
  bot_code?: Maybe<Scalars['String']>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<Scalars['numeric']>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<Scalars['numeric']>;
  /** Cluster */
  cluster?: Maybe<Scalars['String']>;
  /** ฝ่ายสินเชื่อ */
  commercial_credit_department?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่ฝ่ายสินเชื่อ */
  commercial_credit_department_officer?: Maybe<Scalars['String']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** ประเภทผู้สร้างข้อมูล */
  creator_type?: Maybe<ContractTransactionCreatorTypeEnum>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** ฝ่ายปรับโครงสร้างหนี้ */
  debt_restructuring_department?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่ฝ่ายปรับโครงสร้างหนี้ */
  debt_restructuring_department_officer?: Maybe<Scalars['String']>;
  /** Debttype */
  debt_type?: Maybe<Scalars['String']>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<Scalars['numeric']>;
  /** ฝ่ายที่ดูแลแฟ้มปัจจุบัน */
  document_department?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่ดูแลแฟ้มปัจจุบัน */
  document_department_officer?: Maybe<Scalars['String']>;
  /** วันที่ครบกำหนด */
  due_date?: Maybe<Scalars['date']>;
  /** วันที่ทำสัญญา */
  effective_date?: Maybe<Scalars['date']>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<Scalars['numeric']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['numeric']>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<Scalars['numeric']>;
  /** วันที่เริ่มชำระครั้งแรก */
  first_repay_date?: Maybe<Scalars['date']>;
  /** FullyRepayFlag */
  fully_repay_flag?: Maybe<Scalars['String']>;
  /** GradeGL */
  grade_gl?: Maybe<Scalars['String']>;
  /** เกรดปัจจุบัน */
  grade_value?: Maybe<ContractGradeEnum>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** Industry */
  industry?: Maybe<Scalars['String']>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<Scalars['numeric']>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<Scalars['numeric']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['numeric']>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<Scalars['numeric']>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<Scalars['numeric']>;
  /** ประเภทดอกเบี้ย */
  interest_rate_type?: Maybe<Scalars['String']>;
  /** วันที่ชำระครั้งสุดท้าย */
  last_repay_date?: Maybe<Scalars['date']>;
  /** ฝ่ายคดี */
  lawsuit_department?: Maybe<Scalars['String']>;
  /** เจ้าหน้าที่ฝ่ายคดี */
  lawsuit_department_officer?: Maybe<Scalars['String']>;
  /** ค่างวด */
  monthly_payment?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<Scalars['numeric']>;
  /** Old-Ref. */
  old_ref?: Maybe<Scalars['String']>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<Scalars['numeric']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['numeric']>;
  /** วันที่เริ่มค้างเงินต้น */
  outstanding_capital_start_date?: Maybe<Scalars['date']>;
  /** วันที่เริ่มค้างดอกเบี้ย */
  outstanding_interest_start_date?: Maybe<Scalars['date']>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<Scalars['numeric']>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<Scalars['numeric']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['numeric']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['numeric']>;
  /** รหัสโครงการ (ธนาคาร) */
  project_reference?: Maybe<Scalars['String']>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<Scalars['numeric']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** รวมรับชำระ */
  repay_balance?: Maybe<Scalars['numeric']>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<Scalars['Int']>;
  /** RES-N */
  res_n?: Maybe<Scalars['String']>;
  /** บริษัทที่ดูแลหนี้ */
  responsible_organization?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อตกลงปรับโครงสร้างหนี้ */
  restructuring_approved_date?: Maybe<Scalars['date']>;
  /** วันที่ปรับโครงสร้างหนี้ */
  restructuring_effective_date?: Maybe<Scalars['date']>;
  /** วันที่เริ่มชำระดอกเบี้ยตามเงื่อนไขปรับโครงสร้างหนี้ */
  restructuring_first_repay_date?: Maybe<Scalars['date']>;
  /** Spread Rate */
  spread_rate?: Maybe<Scalars['numeric']>;
  /** Status */
  status?: Maybe<Scalars['String']>;
  /** พักชำระหนี้ */
  suspended?: Maybe<Scalars['String']>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<Scalars['numeric']>;
  /** TermOverdueGL */
  term_overdue_gl?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<Scalars['uuid']>;
  /** วันที่เบิกเงินกู้ */
  withdraw_date?: Maybe<Scalars['date']>;
};

/** aggregate stddev on columns */
export type ContractAgreementTransactionStddevFields = {
  __typename?: 'contract_agreement_transaction_stddev_fields';
  /** รวม Accru */
  accru_total?: Maybe<Scalars['Float']>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<Scalars['Float']>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<Scalars['Float']>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<Scalars['Float']>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<Scalars['Float']>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<Scalars['Float']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['Float']>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<Scalars['Float']>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<Scalars['Float']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['Float']>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<Scalars['Float']>;
  /** ค่างวด */
  monthly_payment?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<Scalars['Float']>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<Scalars['Float']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['Float']>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<Scalars['Float']>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<Scalars['Float']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['Float']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<Scalars['Float']>;
  /** รวมรับชำระ */
  repay_balance?: Maybe<Scalars['Float']>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<Scalars['Float']>;
  /** Spread Rate */
  spread_rate?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contract.agreement_transaction" */
export type ContractAgreementTransactionStddevOrderBy = {
  /** รวม Accru */
  accru_total?: Maybe<OrderBy>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<OrderBy>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<OrderBy>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<OrderBy>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<OrderBy>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<OrderBy>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<OrderBy>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<OrderBy>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<OrderBy>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<OrderBy>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<OrderBy>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<OrderBy>;
  /** ค่างวด */
  monthly_payment?: Maybe<OrderBy>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<OrderBy>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<OrderBy>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<OrderBy>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<OrderBy>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<OrderBy>;
  /** ระยะเวลากู้ */
  period?: Maybe<OrderBy>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<OrderBy>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<OrderBy>;
  /** รวมรับชำระ */
  repay_balance?: Maybe<OrderBy>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<OrderBy>;
  /** Spread Rate */
  spread_rate?: Maybe<OrderBy>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<OrderBy>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<OrderBy>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ContractAgreementTransactionStddevPopFields = {
  __typename?: 'contract_agreement_transaction_stddev_pop_fields';
  /** รวม Accru */
  accru_total?: Maybe<Scalars['Float']>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<Scalars['Float']>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<Scalars['Float']>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<Scalars['Float']>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<Scalars['Float']>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<Scalars['Float']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['Float']>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<Scalars['Float']>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<Scalars['Float']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['Float']>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<Scalars['Float']>;
  /** ค่างวด */
  monthly_payment?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<Scalars['Float']>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<Scalars['Float']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['Float']>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<Scalars['Float']>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<Scalars['Float']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['Float']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<Scalars['Float']>;
  /** รวมรับชำระ */
  repay_balance?: Maybe<Scalars['Float']>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<Scalars['Float']>;
  /** Spread Rate */
  spread_rate?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contract.agreement_transaction" */
export type ContractAgreementTransactionStddevPopOrderBy = {
  /** รวม Accru */
  accru_total?: Maybe<OrderBy>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<OrderBy>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<OrderBy>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<OrderBy>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<OrderBy>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<OrderBy>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<OrderBy>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<OrderBy>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<OrderBy>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<OrderBy>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<OrderBy>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<OrderBy>;
  /** ค่างวด */
  monthly_payment?: Maybe<OrderBy>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<OrderBy>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<OrderBy>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<OrderBy>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<OrderBy>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<OrderBy>;
  /** ระยะเวลากู้ */
  period?: Maybe<OrderBy>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<OrderBy>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<OrderBy>;
  /** รวมรับชำระ */
  repay_balance?: Maybe<OrderBy>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<OrderBy>;
  /** Spread Rate */
  spread_rate?: Maybe<OrderBy>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<OrderBy>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<OrderBy>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ContractAgreementTransactionStddevSampFields = {
  __typename?: 'contract_agreement_transaction_stddev_samp_fields';
  /** รวม Accru */
  accru_total?: Maybe<Scalars['Float']>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<Scalars['Float']>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<Scalars['Float']>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<Scalars['Float']>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<Scalars['Float']>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<Scalars['Float']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['Float']>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<Scalars['Float']>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<Scalars['Float']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['Float']>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<Scalars['Float']>;
  /** ค่างวด */
  monthly_payment?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<Scalars['Float']>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<Scalars['Float']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['Float']>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<Scalars['Float']>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<Scalars['Float']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['Float']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<Scalars['Float']>;
  /** รวมรับชำระ */
  repay_balance?: Maybe<Scalars['Float']>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<Scalars['Float']>;
  /** Spread Rate */
  spread_rate?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contract.agreement_transaction" */
export type ContractAgreementTransactionStddevSampOrderBy = {
  /** รวม Accru */
  accru_total?: Maybe<OrderBy>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<OrderBy>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<OrderBy>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<OrderBy>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<OrderBy>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<OrderBy>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<OrderBy>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<OrderBy>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<OrderBy>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<OrderBy>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<OrderBy>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<OrderBy>;
  /** ค่างวด */
  monthly_payment?: Maybe<OrderBy>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<OrderBy>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<OrderBy>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<OrderBy>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<OrderBy>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<OrderBy>;
  /** ระยะเวลากู้ */
  period?: Maybe<OrderBy>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<OrderBy>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<OrderBy>;
  /** รวมรับชำระ */
  repay_balance?: Maybe<OrderBy>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<OrderBy>;
  /** Spread Rate */
  spread_rate?: Maybe<OrderBy>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<OrderBy>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<OrderBy>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ContractAgreementTransactionSumFields = {
  __typename?: 'contract_agreement_transaction_sum_fields';
  /** รวม Accru */
  accru_total?: Maybe<Scalars['numeric']>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<Scalars['numeric']>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<Scalars['numeric']>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<Scalars['numeric']>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<Scalars['numeric']>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<Scalars['numeric']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['numeric']>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<Scalars['numeric']>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<Scalars['numeric']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['numeric']>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<Scalars['numeric']>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<Scalars['numeric']>;
  /** ค่างวด */
  monthly_payment?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<Scalars['numeric']>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<Scalars['numeric']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['numeric']>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<Scalars['numeric']>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<Scalars['numeric']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['numeric']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<Scalars['numeric']>;
  /** รวมรับชำระ */
  repay_balance?: Maybe<Scalars['numeric']>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<Scalars['Int']>;
  /** Spread Rate */
  spread_rate?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<Scalars['numeric']>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "contract.agreement_transaction" */
export type ContractAgreementTransactionSumOrderBy = {
  /** รวม Accru */
  accru_total?: Maybe<OrderBy>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<OrderBy>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<OrderBy>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<OrderBy>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<OrderBy>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<OrderBy>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<OrderBy>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<OrderBy>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<OrderBy>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<OrderBy>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<OrderBy>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<OrderBy>;
  /** ค่างวด */
  monthly_payment?: Maybe<OrderBy>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<OrderBy>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<OrderBy>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<OrderBy>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<OrderBy>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<OrderBy>;
  /** ระยะเวลากู้ */
  period?: Maybe<OrderBy>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<OrderBy>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<OrderBy>;
  /** รวมรับชำระ */
  repay_balance?: Maybe<OrderBy>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<OrderBy>;
  /** Spread Rate */
  spread_rate?: Maybe<OrderBy>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<OrderBy>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<OrderBy>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<OrderBy>;
};

/** update columns of table "contract.agreement_transaction" */
export enum ContractAgreementTransactionUpdateColumn {
  /** column name */
  AccruTotal = 'accru_total',
  /** column name */
  AgingGrade = 'aging_grade',
  /** column name */
  AgingOutstandingPeriod = 'aging_outstanding_period',
  /** column name */
  Approved = 'approved',
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  ApprovedBy = 'approved_by',
  /** column name */
  ApprovedDate = 'approved_date',
  /** column name */
  ArCode = 'ar_code',
  /** column name */
  BankBranchOfficer = 'bank_branch_officer',
  /** column name */
  BotCode = 'bot_code',
  /** column name */
  CapitalNpl = 'capital_npl',
  /** column name */
  CapitalOutstanding = 'capital_outstanding',
  /** column name */
  Cluster = 'cluster',
  /** column name */
  CommercialCreditDepartment = 'commercial_credit_department',
  /** column name */
  CommercialCreditDepartmentOfficer = 'commercial_credit_department_officer',
  /** column name */
  ContractNumber = 'contract_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorType = 'creator_type',
  /** column name */
  CreditAmount = 'credit_amount',
  /** column name */
  CreditTotal = 'credit_total',
  /** column name */
  DebtRestructuringDepartment = 'debt_restructuring_department',
  /** column name */
  DebtRestructuringDepartmentOfficer = 'debt_restructuring_department_officer',
  /** column name */
  DebtType = 'debt_type',
  /** column name */
  DefaultRate = 'default_rate',
  /** column name */
  DocumentDepartment = 'document_department',
  /** column name */
  DocumentDepartmentOfficer = 'document_department_officer',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  EffectiveDate = 'effective_date',
  /** column name */
  FineAccru = 'fine_accru',
  /** column name */
  FineAmount = 'fine_amount',
  /** column name */
  FineOutstanding = 'fine_outstanding',
  /** column name */
  FirstRepayDate = 'first_repay_date',
  /** column name */
  FullyRepayFlag = 'fully_repay_flag',
  /** column name */
  GradeGl = 'grade_gl',
  /** column name */
  GradeValue = 'grade_value',
  /** column name */
  Id = 'id',
  /** column name */
  ImportedAt = 'imported_at',
  /** column name */
  Industry = 'industry',
  /** column name */
  InsuranceInterest = 'insurance_interest',
  /** column name */
  InterestAccru = 'interest_accru',
  /** column name */
  InterestAmount = 'interest_amount',
  /** column name */
  InterestOutstanding = 'interest_outstanding',
  /** column name */
  InterestRate = 'interest_rate',
  /** column name */
  InterestRateType = 'interest_rate_type',
  /** column name */
  LastRepayDate = 'last_repay_date',
  /** column name */
  LawsuitDepartment = 'lawsuit_department',
  /** column name */
  LawsuitDepartmentOfficer = 'lawsuit_department_officer',
  /** column name */
  MonthlyPayment = 'monthly_payment',
  /** column name */
  NonRecognitionInterest = 'non_recognition_interest',
  /** column name */
  OldRef = 'old_ref',
  /** column name */
  OutInsurance = 'out_insurance',
  /** column name */
  OutstandingBalance = 'outstanding_balance',
  /** column name */
  OutstandingCapitalStartDate = 'outstanding_capital_start_date',
  /** column name */
  OutstandingInterestStartDate = 'outstanding_interest_start_date',
  /** column name */
  OutstandingPeriod = 'outstanding_period',
  /** column name */
  OutstandingTotal = 'outstanding_total',
  /** column name */
  Period = 'period',
  /** column name */
  PreviousOutstandingBalance = 'previous_outstanding_balance',
  /** column name */
  ProjectReference = 'project_reference',
  /** column name */
  RecognitionInterest = 'recognition_interest',
  /** column name */
  RegisterNumber = 'register_number',
  /** column name */
  RepayBalance = 'repay_balance',
  /** column name */
  RepayDayOfMonth = 'repay_day_of_month',
  /** column name */
  ResN = 'res_n',
  /** column name */
  ResponsibleOrganization = 'responsible_organization',
  /** column name */
  RestructuringApprovedDate = 'restructuring_approved_date',
  /** column name */
  RestructuringEffectiveDate = 'restructuring_effective_date',
  /** column name */
  RestructuringFirstRepayDate = 'restructuring_first_repay_date',
  /** column name */
  SpreadRate = 'spread_rate',
  /** column name */
  Status = 'status',
  /** column name */
  Suspended = 'suspended',
  /** column name */
  SuspenedInterest = 'suspened_interest',
  /** column name */
  SuspenedNonRecognitionInterest = 'suspened_non_recognition_interest',
  /** column name */
  SuspenedRecognitionInterest = 'suspened_recognition_interest',
  /** column name */
  TermOverdueGl = 'term_overdue_gl',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WithdrawDate = 'withdraw_date'
}

/** aggregate var_pop on columns */
export type ContractAgreementTransactionVarPopFields = {
  __typename?: 'contract_agreement_transaction_var_pop_fields';
  /** รวม Accru */
  accru_total?: Maybe<Scalars['Float']>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<Scalars['Float']>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<Scalars['Float']>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<Scalars['Float']>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<Scalars['Float']>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<Scalars['Float']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['Float']>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<Scalars['Float']>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<Scalars['Float']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['Float']>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<Scalars['Float']>;
  /** ค่างวด */
  monthly_payment?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<Scalars['Float']>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<Scalars['Float']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['Float']>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<Scalars['Float']>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<Scalars['Float']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['Float']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<Scalars['Float']>;
  /** รวมรับชำระ */
  repay_balance?: Maybe<Scalars['Float']>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<Scalars['Float']>;
  /** Spread Rate */
  spread_rate?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contract.agreement_transaction" */
export type ContractAgreementTransactionVarPopOrderBy = {
  /** รวม Accru */
  accru_total?: Maybe<OrderBy>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<OrderBy>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<OrderBy>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<OrderBy>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<OrderBy>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<OrderBy>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<OrderBy>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<OrderBy>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<OrderBy>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<OrderBy>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<OrderBy>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<OrderBy>;
  /** ค่างวด */
  monthly_payment?: Maybe<OrderBy>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<OrderBy>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<OrderBy>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<OrderBy>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<OrderBy>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<OrderBy>;
  /** ระยะเวลากู้ */
  period?: Maybe<OrderBy>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<OrderBy>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<OrderBy>;
  /** รวมรับชำระ */
  repay_balance?: Maybe<OrderBy>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<OrderBy>;
  /** Spread Rate */
  spread_rate?: Maybe<OrderBy>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<OrderBy>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<OrderBy>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ContractAgreementTransactionVarSampFields = {
  __typename?: 'contract_agreement_transaction_var_samp_fields';
  /** รวม Accru */
  accru_total?: Maybe<Scalars['Float']>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<Scalars['Float']>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<Scalars['Float']>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<Scalars['Float']>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<Scalars['Float']>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<Scalars['Float']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['Float']>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<Scalars['Float']>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<Scalars['Float']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['Float']>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<Scalars['Float']>;
  /** ค่างวด */
  monthly_payment?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<Scalars['Float']>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<Scalars['Float']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['Float']>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<Scalars['Float']>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<Scalars['Float']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['Float']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<Scalars['Float']>;
  /** รวมรับชำระ */
  repay_balance?: Maybe<Scalars['Float']>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<Scalars['Float']>;
  /** Spread Rate */
  spread_rate?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contract.agreement_transaction" */
export type ContractAgreementTransactionVarSampOrderBy = {
  /** รวม Accru */
  accru_total?: Maybe<OrderBy>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<OrderBy>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<OrderBy>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<OrderBy>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<OrderBy>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<OrderBy>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<OrderBy>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<OrderBy>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<OrderBy>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<OrderBy>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<OrderBy>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<OrderBy>;
  /** ค่างวด */
  monthly_payment?: Maybe<OrderBy>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<OrderBy>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<OrderBy>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<OrderBy>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<OrderBy>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<OrderBy>;
  /** ระยะเวลากู้ */
  period?: Maybe<OrderBy>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<OrderBy>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<OrderBy>;
  /** รวมรับชำระ */
  repay_balance?: Maybe<OrderBy>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<OrderBy>;
  /** Spread Rate */
  spread_rate?: Maybe<OrderBy>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<OrderBy>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<OrderBy>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ContractAgreementTransactionVarianceFields = {
  __typename?: 'contract_agreement_transaction_variance_fields';
  /** รวม Accru */
  accru_total?: Maybe<Scalars['Float']>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<Scalars['Float']>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<Scalars['Float']>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<Scalars['Float']>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<Scalars['Float']>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<Scalars['Float']>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<Scalars['Float']>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<Scalars['Float']>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<Scalars['Float']>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<Scalars['Float']>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<Scalars['Float']>;
  /** ค่างวด */
  monthly_payment?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<Scalars['Float']>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<Scalars['Float']>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<Scalars['Float']>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<Scalars['Float']>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<Scalars['Float']>;
  /** ระยะเวลากู้ */
  period?: Maybe<Scalars['Float']>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<Scalars['Float']>;
  /** รวมรับชำระ */
  repay_balance?: Maybe<Scalars['Float']>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<Scalars['Float']>;
  /** Spread Rate */
  spread_rate?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<Scalars['Float']>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contract.agreement_transaction" */
export type ContractAgreementTransactionVarianceOrderBy = {
  /** รวม Accru */
  accru_total?: Maybe<OrderBy>;
  /** งวดค้างAging */
  aging_outstanding_period?: Maybe<OrderBy>;
  /** เงินต้น NPL */
  capital_npl?: Maybe<OrderBy>;
  /** ค้างชำระเงินต้น */
  capital_outstanding?: Maybe<OrderBy>;
  /** รับชำระเงินต้น */
  credit_amount?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยผิดนัด */
  default_rate?: Maybe<OrderBy>;
  /** Accru เบี้ยปรับ */
  fine_accru?: Maybe<OrderBy>;
  /** รับชำระเบี้ยปรับ */
  fine_amount?: Maybe<OrderBy>;
  /** ค้างชำระเบี้ยปรับ */
  fine_outstanding?: Maybe<OrderBy>;
  /** ดอกเบี้ยประกันภัย */
  insurance_interest?: Maybe<OrderBy>;
  /** Accru ดอกเบี้ย */
  interest_accru?: Maybe<OrderBy>;
  /** รับชำระดอกเบี้ย */
  interest_amount?: Maybe<OrderBy>;
  /** ค้างชำระดอกเบี้ย */
  interest_outstanding?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ย */
  interest_rate?: Maybe<OrderBy>;
  /** ค่างวด */
  monthly_payment?: Maybe<OrderBy>;
  /** ดอกเบี้ยไม่รับรู้ */
  non_recognition_interest?: Maybe<OrderBy>;
  /** Out ประกันภัย */
  out_insurance?: Maybe<OrderBy>;
  /** เงินต้นคงเหลือ */
  outstanding_balance?: Maybe<OrderBy>;
  /** งวดค้างปัจจุบัน */
  outstanding_period?: Maybe<OrderBy>;
  /** รวมค้างชำระ */
  outstanding_total?: Maybe<OrderBy>;
  /** ระยะเวลากู้ */
  period?: Maybe<OrderBy>;
  /** เงินต้นเดือนก่อน */
  previous_outstanding_balance?: Maybe<OrderBy>;
  /** ดอกเบี้ยรับรู้ */
  recognition_interest?: Maybe<OrderBy>;
  /** รวมรับชำระ */
  repay_balance?: Maybe<OrderBy>;
  /** วันที่จ่ายดอกเบี้ย */
  repay_day_of_month?: Maybe<OrderBy>;
  /** Spread Rate */
  spread_rate?: Maybe<OrderBy>;
  /** ดอกเบี้ยพัก */
  suspened_interest?: Maybe<OrderBy>;
  /** ดอกเบี้ยพักไม่รับรู้ */
  suspened_non_recognition_interest?: Maybe<OrderBy>;
  /** ดอกเบี้ยพักรับรู้ */
  suspened_recognition_interest?: Maybe<OrderBy>;
};

/** update columns of table "contract.agreement" */
export enum ContractAgreementUpdateColumn {
  /** column name */
  Address = 'address',
  /** column name */
  AfNumber = 'af_number',
  /** column name */
  AfStatus = 'af_status',
  /** column name */
  AfStatusDescription = 'af_status_description',
  /** column name */
  AfStatusName = 'af_status_name',
  /** column name */
  ApprovedDate = 'approved_date',
  /** column name */
  BankBranchId = 'bank_branch_id',
  /** column name */
  BusinessGroup = 'business_group',
  /** column name */
  BusinessType = 'business_type',
  /** column name */
  CfNumber = 'cf_number',
  /** column name */
  ContactDate = 'contact_date',
  /** column name */
  ContactEmail = 'contact_email',
  /** column name */
  ContactFax = 'contact_fax',
  /** column name */
  ContactFirstName = 'contact_first_name',
  /** column name */
  ContactLastName = 'contact_last_name',
  /** column name */
  ContactMobile = 'contact_mobile',
  /** column name */
  ContactTel = 'contact_tel',
  /** column name */
  ContractNumber = 'contract_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CreditTotal = 'credit_total',
  /** column name */
  CustomerType = 'customer_type',
  /** column name */
  DDamt = 'd_damt',
  /** column name */
  EffectiveDate = 'effective_date',
  /** column name */
  FirstRepayDate = 'first_repay_date',
  /** column name */
  FirstWithdrawDate = 'first_withdraw_date',
  /** column name */
  GracePeriod = 'grace_period',
  /** column name */
  GuaranteeType = 'guarantee_type',
  /** column name */
  GuarantorCitizenId = 'guarantor_citizen_id',
  /** column name */
  GuarantorDescription = 'guarantor_description',
  /** column name */
  GuarantorName = 'guarantor_name',
  /** column name */
  Id = 'id',
  /** column name */
  ImportedAt = 'imported_at',
  /** column name */
  InsuranceDueDate = 'insurance_due_date',
  /** column name */
  JuristicId = 'juristic_id',
  /** column name */
  JuristicNameEn = 'juristic_name_en',
  /** column name */
  JuristicNameTh = 'juristic_name_th',
  /** column name */
  LoanDescription = 'loan_description',
  /** column name */
  MonthlyPayment = 'monthly_payment',
  /** column name */
  Objective = 'objective',
  /** column name */
  Period = 'period',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  ProgramName = 'program_name',
  /** column name */
  ProgramReference = 'program_reference',
  /** column name */
  ProjectDescription = 'project_description',
  /** column name */
  RegisterNumber = 'register_number',
  /** column name */
  RepayDayOfMonth = 'repay_day_of_month',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type ContractAgreementVarPopFields = {
  __typename?: 'contract_agreement_var_pop_fields';
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<Scalars['Float']>;
  /** DDamt */
  d_damt?: Maybe<Scalars['Float']>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<Scalars['Float']>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contract.agreement" */
export type ContractAgreementVarPopOrderBy = {
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<OrderBy>;
  /** DDamt */
  d_damt?: Maybe<OrderBy>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<OrderBy>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ContractAgreementVarSampFields = {
  __typename?: 'contract_agreement_var_samp_fields';
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<Scalars['Float']>;
  /** DDamt */
  d_damt?: Maybe<Scalars['Float']>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<Scalars['Float']>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contract.agreement" */
export type ContractAgreementVarSampOrderBy = {
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<OrderBy>;
  /** DDamt */
  d_damt?: Maybe<OrderBy>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<OrderBy>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ContractAgreementVarianceFields = {
  __typename?: 'contract_agreement_variance_fields';
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<Scalars['Float']>;
  /** DDamt */
  d_damt?: Maybe<Scalars['Float']>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<Scalars['Float']>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contract.agreement" */
export type ContractAgreementVarianceOrderBy = {
  /** ยอดหนี้ทั้งหมด */
  credit_total?: Maybe<OrderBy>;
  /** DDamt */
  d_damt?: Maybe<OrderBy>;
  /** ระยะเวลาปลอกดอกเบี้ย */
  grace_period?: Maybe<OrderBy>;
  /** ยอดชำระรายเดือน */
  monthly_payment?: Maybe<OrderBy>;
};

/**
 * การเบิกเงินกู้
 *
 *
 * columns and relationships of "contract.agreement_withdraw"
 */
export type ContractAgreementWithdraw = {
  __typename?: 'contract_agreement_withdraw';
  /** An object relationship */
  agreement?: Maybe<ContractAgreement>;
  /** จำนวนเงิน */
  amount: Scalars['numeric'];
  /** An object relationship */
  bank_branch: BankBranch;
  /** รหัสอ้างอิงสาขา */
  bank_branch_id: Scalars['String'];
  /** สาขา */
  bank_branch_name?: Maybe<Scalars['String']>;
  /** เลขที่สัญญา */
  contract_number: Scalars['String'];
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** วงเงินคงเหลือ */
  credit_balance: Scalars['numeric'];
  /** วงเงินอนุมัติ */
  credit_total: Scalars['numeric'];
  /** DrawDownNo */
  draw_down_no: Scalars['String'];
  /** วันที่นำเข้าข้อมูล */
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** ชื่อ */
  juristic_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  program?: Maybe<LoanProgram>;
  /** รหัสโครงการ (ฐานข้อมูล) */
  program_id?: Maybe<Scalars['uuid']>;
  /** ชื่อโครงการ */
  program_name?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิงโครงการ */
  program_reference?: Maybe<Scalars['String']>;
  /** ทะเบียนลูกค้า */
  register_number: Scalars['String'];
  /** วันที่ส่ง */
  request_date: Scalars['date'];
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
  /** วันที่เบิกเงินกู้ */
  withdraw_date: Scalars['date'];
};

/** aggregated selection of "contract.agreement_withdraw" */
export type ContractAgreementWithdrawAggregate = {
  __typename?: 'contract_agreement_withdraw_aggregate';
  aggregate?: Maybe<ContractAgreementWithdrawAggregateFields>;
  nodes: Array<ContractAgreementWithdraw>;
};

/** aggregate fields of "contract.agreement_withdraw" */
export type ContractAgreementWithdrawAggregateFields = {
  __typename?: 'contract_agreement_withdraw_aggregate_fields';
  avg?: Maybe<ContractAgreementWithdrawAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ContractAgreementWithdrawMaxFields>;
  min?: Maybe<ContractAgreementWithdrawMinFields>;
  stddev?: Maybe<ContractAgreementWithdrawStddevFields>;
  stddev_pop?: Maybe<ContractAgreementWithdrawStddevPopFields>;
  stddev_samp?: Maybe<ContractAgreementWithdrawStddevSampFields>;
  sum?: Maybe<ContractAgreementWithdrawSumFields>;
  var_pop?: Maybe<ContractAgreementWithdrawVarPopFields>;
  var_samp?: Maybe<ContractAgreementWithdrawVarSampFields>;
  variance?: Maybe<ContractAgreementWithdrawVarianceFields>;
};


/** aggregate fields of "contract.agreement_withdraw" */
export type ContractAgreementWithdrawAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ContractAgreementWithdrawSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contract.agreement_withdraw" */
export type ContractAgreementWithdrawAggregateOrderBy = {
  avg?: Maybe<ContractAgreementWithdrawAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ContractAgreementWithdrawMaxOrderBy>;
  min?: Maybe<ContractAgreementWithdrawMinOrderBy>;
  stddev?: Maybe<ContractAgreementWithdrawStddevOrderBy>;
  stddev_pop?: Maybe<ContractAgreementWithdrawStddevPopOrderBy>;
  stddev_samp?: Maybe<ContractAgreementWithdrawStddevSampOrderBy>;
  sum?: Maybe<ContractAgreementWithdrawSumOrderBy>;
  var_pop?: Maybe<ContractAgreementWithdrawVarPopOrderBy>;
  var_samp?: Maybe<ContractAgreementWithdrawVarSampOrderBy>;
  variance?: Maybe<ContractAgreementWithdrawVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "contract.agreement_withdraw" */
export type ContractAgreementWithdrawArrRelInsertInput = {
  data: Array<ContractAgreementWithdrawInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ContractAgreementWithdrawOnConflict>;
};

/** aggregate avg on columns */
export type ContractAgreementWithdrawAvgFields = {
  __typename?: 'contract_agreement_withdraw_avg_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contract.agreement_withdraw" */
export type ContractAgreementWithdrawAvgOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "contract.agreement_withdraw". All fields are combined with a logical 'AND'. */
export type ContractAgreementWithdrawBoolExp = {
  _and?: Maybe<Array<ContractAgreementWithdrawBoolExp>>;
  _not?: Maybe<ContractAgreementWithdrawBoolExp>;
  _or?: Maybe<Array<ContractAgreementWithdrawBoolExp>>;
  agreement?: Maybe<ContractAgreementBoolExp>;
  amount?: Maybe<NumericComparisonExp>;
  bank_branch?: Maybe<BankBranchBoolExp>;
  bank_branch_id?: Maybe<StringComparisonExp>;
  bank_branch_name?: Maybe<StringComparisonExp>;
  contract_number?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  credit_balance?: Maybe<NumericComparisonExp>;
  credit_total?: Maybe<NumericComparisonExp>;
  draw_down_no?: Maybe<StringComparisonExp>;
  imported_at?: Maybe<TimestamptzComparisonExp>;
  juristic_name?: Maybe<StringComparisonExp>;
  program?: Maybe<LoanProgramBoolExp>;
  program_id?: Maybe<UuidComparisonExp>;
  program_name?: Maybe<StringComparisonExp>;
  program_reference?: Maybe<StringComparisonExp>;
  register_number?: Maybe<StringComparisonExp>;
  request_date?: Maybe<DateComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<UuidComparisonExp>;
  withdraw_date?: Maybe<DateComparisonExp>;
};

/** unique or primary key constraints on table "contract.agreement_withdraw" */
export enum ContractAgreementWithdrawConstraint {
  /** unique or primary key constraint */
  AgreementWithdrawPkey = 'agreement_withdraw_pkey'
}

/** input type for incrementing numeric columns in table "contract.agreement_withdraw" */
export type ContractAgreementWithdrawIncInput = {
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "contract.agreement_withdraw" */
export type ContractAgreementWithdrawInsertInput = {
  agreement?: Maybe<ContractAgreementObjRelInsertInput>;
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  bank_branch?: Maybe<BankBranchObjRelInsertInput>;
  /** รหัสอ้างอิงสาขา */
  bank_branch_id?: Maybe<Scalars['String']>;
  /** สาขา */
  bank_branch_name?: Maybe<Scalars['String']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** DrawDownNo */
  draw_down_no?: Maybe<Scalars['String']>;
  /** วันที่นำเข้าข้อมูล */
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** ชื่อ */
  juristic_name?: Maybe<Scalars['String']>;
  program?: Maybe<LoanProgramObjRelInsertInput>;
  /** รหัสโครงการ (ฐานข้อมูล) */
  program_id?: Maybe<Scalars['uuid']>;
  /** ชื่อโครงการ */
  program_name?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิงโครงการ */
  program_reference?: Maybe<Scalars['String']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** วันที่ส่ง */
  request_date?: Maybe<Scalars['date']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
  /** วันที่เบิกเงินกู้ */
  withdraw_date?: Maybe<Scalars['date']>;
};

/** aggregate max on columns */
export type ContractAgreementWithdrawMaxFields = {
  __typename?: 'contract_agreement_withdraw_max_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  /** รหัสอ้างอิงสาขา */
  bank_branch_id?: Maybe<Scalars['String']>;
  /** สาขา */
  bank_branch_name?: Maybe<Scalars['String']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** DrawDownNo */
  draw_down_no?: Maybe<Scalars['String']>;
  /** วันที่นำเข้าข้อมูล */
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** ชื่อ */
  juristic_name?: Maybe<Scalars['String']>;
  /** รหัสโครงการ (ฐานข้อมูล) */
  program_id?: Maybe<Scalars['uuid']>;
  /** ชื่อโครงการ */
  program_name?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิงโครงการ */
  program_reference?: Maybe<Scalars['String']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** วันที่ส่ง */
  request_date?: Maybe<Scalars['date']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
  /** วันที่เบิกเงินกู้ */
  withdraw_date?: Maybe<Scalars['date']>;
};

/** order by max() on columns of table "contract.agreement_withdraw" */
export type ContractAgreementWithdrawMaxOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** รหัสอ้างอิงสาขา */
  bank_branch_id?: Maybe<OrderBy>;
  /** สาขา */
  bank_branch_name?: Maybe<OrderBy>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** DrawDownNo */
  draw_down_no?: Maybe<OrderBy>;
  /** วันที่นำเข้าข้อมูล */
  imported_at?: Maybe<OrderBy>;
  /** ชื่อ */
  juristic_name?: Maybe<OrderBy>;
  /** รหัสโครงการ (ฐานข้อมูล) */
  program_id?: Maybe<OrderBy>;
  /** ชื่อโครงการ */
  program_name?: Maybe<OrderBy>;
  /** รหัสอ้างอิงโครงการ */
  program_reference?: Maybe<OrderBy>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<OrderBy>;
  /** วันที่ส่ง */
  request_date?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
  /** วันที่เบิกเงินกู้ */
  withdraw_date?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ContractAgreementWithdrawMinFields = {
  __typename?: 'contract_agreement_withdraw_min_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  /** รหัสอ้างอิงสาขา */
  bank_branch_id?: Maybe<Scalars['String']>;
  /** สาขา */
  bank_branch_name?: Maybe<Scalars['String']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** DrawDownNo */
  draw_down_no?: Maybe<Scalars['String']>;
  /** วันที่นำเข้าข้อมูล */
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** ชื่อ */
  juristic_name?: Maybe<Scalars['String']>;
  /** รหัสโครงการ (ฐานข้อมูล) */
  program_id?: Maybe<Scalars['uuid']>;
  /** ชื่อโครงการ */
  program_name?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิงโครงการ */
  program_reference?: Maybe<Scalars['String']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** วันที่ส่ง */
  request_date?: Maybe<Scalars['date']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
  /** วันที่เบิกเงินกู้ */
  withdraw_date?: Maybe<Scalars['date']>;
};

/** order by min() on columns of table "contract.agreement_withdraw" */
export type ContractAgreementWithdrawMinOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** รหัสอ้างอิงสาขา */
  bank_branch_id?: Maybe<OrderBy>;
  /** สาขา */
  bank_branch_name?: Maybe<OrderBy>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
  /** DrawDownNo */
  draw_down_no?: Maybe<OrderBy>;
  /** วันที่นำเข้าข้อมูล */
  imported_at?: Maybe<OrderBy>;
  /** ชื่อ */
  juristic_name?: Maybe<OrderBy>;
  /** รหัสโครงการ (ฐานข้อมูล) */
  program_id?: Maybe<OrderBy>;
  /** ชื่อโครงการ */
  program_name?: Maybe<OrderBy>;
  /** รหัสอ้างอิงโครงการ */
  program_reference?: Maybe<OrderBy>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<OrderBy>;
  /** วันที่ส่ง */
  request_date?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
  /** วันที่เบิกเงินกู้ */
  withdraw_date?: Maybe<OrderBy>;
};

/** response of any mutation on the table "contract.agreement_withdraw" */
export type ContractAgreementWithdrawMutationResponse = {
  __typename?: 'contract_agreement_withdraw_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContractAgreementWithdraw>;
};

/** on conflict condition type for table "contract.agreement_withdraw" */
export type ContractAgreementWithdrawOnConflict = {
  constraint: ContractAgreementWithdrawConstraint;
  update_columns?: Array<ContractAgreementWithdrawUpdateColumn>;
  where?: Maybe<ContractAgreementWithdrawBoolExp>;
};

/** Ordering options when selecting data from "contract.agreement_withdraw". */
export type ContractAgreementWithdrawOrderBy = {
  agreement?: Maybe<ContractAgreementOrderBy>;
  amount?: Maybe<OrderBy>;
  bank_branch?: Maybe<BankBranchOrderBy>;
  bank_branch_id?: Maybe<OrderBy>;
  bank_branch_name?: Maybe<OrderBy>;
  contract_number?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  credit_balance?: Maybe<OrderBy>;
  credit_total?: Maybe<OrderBy>;
  draw_down_no?: Maybe<OrderBy>;
  imported_at?: Maybe<OrderBy>;
  juristic_name?: Maybe<OrderBy>;
  program?: Maybe<LoanProgramOrderBy>;
  program_id?: Maybe<OrderBy>;
  program_name?: Maybe<OrderBy>;
  program_reference?: Maybe<OrderBy>;
  register_number?: Maybe<OrderBy>;
  request_date?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
  withdraw_date?: Maybe<OrderBy>;
};

/** primary key columns input for table: contract_agreement_withdraw */
export type ContractAgreementWithdrawPkColumnsInput = {
  /** DrawDownNo */
  draw_down_no: Scalars['String'];
};

/** select columns of table "contract.agreement_withdraw" */
export enum ContractAgreementWithdrawSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  BankBranchId = 'bank_branch_id',
  /** column name */
  BankBranchName = 'bank_branch_name',
  /** column name */
  ContractNumber = 'contract_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CreditBalance = 'credit_balance',
  /** column name */
  CreditTotal = 'credit_total',
  /** column name */
  DrawDownNo = 'draw_down_no',
  /** column name */
  ImportedAt = 'imported_at',
  /** column name */
  JuristicName = 'juristic_name',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  ProgramName = 'program_name',
  /** column name */
  ProgramReference = 'program_reference',
  /** column name */
  RegisterNumber = 'register_number',
  /** column name */
  RequestDate = 'request_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  WithdrawDate = 'withdraw_date'
}

/** input type for updating data in table "contract.agreement_withdraw" */
export type ContractAgreementWithdrawSetInput = {
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  /** รหัสอ้างอิงสาขา */
  bank_branch_id?: Maybe<Scalars['String']>;
  /** สาขา */
  bank_branch_name?: Maybe<Scalars['String']>;
  /** เลขที่สัญญา */
  contract_number?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
  /** DrawDownNo */
  draw_down_no?: Maybe<Scalars['String']>;
  /** วันที่นำเข้าข้อมูล */
  imported_at?: Maybe<Scalars['timestamptz']>;
  /** ชื่อ */
  juristic_name?: Maybe<Scalars['String']>;
  /** รหัสโครงการ (ฐานข้อมูล) */
  program_id?: Maybe<Scalars['uuid']>;
  /** ชื่อโครงการ */
  program_name?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิงโครงการ */
  program_reference?: Maybe<Scalars['String']>;
  /** ทะเบียนลูกค้า */
  register_number?: Maybe<Scalars['String']>;
  /** วันที่ส่ง */
  request_date?: Maybe<Scalars['date']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
  /** วันที่เบิกเงินกู้ */
  withdraw_date?: Maybe<Scalars['date']>;
};

/** aggregate stddev on columns */
export type ContractAgreementWithdrawStddevFields = {
  __typename?: 'contract_agreement_withdraw_stddev_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contract.agreement_withdraw" */
export type ContractAgreementWithdrawStddevOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ContractAgreementWithdrawStddevPopFields = {
  __typename?: 'contract_agreement_withdraw_stddev_pop_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contract.agreement_withdraw" */
export type ContractAgreementWithdrawStddevPopOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ContractAgreementWithdrawStddevSampFields = {
  __typename?: 'contract_agreement_withdraw_stddev_samp_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contract.agreement_withdraw" */
export type ContractAgreementWithdrawStddevSampOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ContractAgreementWithdrawSumFields = {
  __typename?: 'contract_agreement_withdraw_sum_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['numeric']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "contract.agreement_withdraw" */
export type ContractAgreementWithdrawSumOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
};

/** update columns of table "contract.agreement_withdraw" */
export enum ContractAgreementWithdrawUpdateColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  BankBranchId = 'bank_branch_id',
  /** column name */
  BankBranchName = 'bank_branch_name',
  /** column name */
  ContractNumber = 'contract_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CreditBalance = 'credit_balance',
  /** column name */
  CreditTotal = 'credit_total',
  /** column name */
  DrawDownNo = 'draw_down_no',
  /** column name */
  ImportedAt = 'imported_at',
  /** column name */
  JuristicName = 'juristic_name',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  ProgramName = 'program_name',
  /** column name */
  ProgramReference = 'program_reference',
  /** column name */
  RegisterNumber = 'register_number',
  /** column name */
  RequestDate = 'request_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  WithdrawDate = 'withdraw_date'
}

/** aggregate var_pop on columns */
export type ContractAgreementWithdrawVarPopFields = {
  __typename?: 'contract_agreement_withdraw_var_pop_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contract.agreement_withdraw" */
export type ContractAgreementWithdrawVarPopOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ContractAgreementWithdrawVarSampFields = {
  __typename?: 'contract_agreement_withdraw_var_samp_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contract.agreement_withdraw" */
export type ContractAgreementWithdrawVarSampOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ContractAgreementWithdrawVarianceFields = {
  __typename?: 'contract_agreement_withdraw_variance_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<Scalars['Float']>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contract.agreement_withdraw" */
export type ContractAgreementWithdrawVarianceOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** วงเงินคงเหลือ */
  credit_balance?: Maybe<OrderBy>;
  /** วงเงินอนุมัติ */
  credit_total?: Maybe<OrderBy>;
};

/**
 * ประเภทเอกสารประกอบข้อมูลลูกหนี้
 *
 *
 * columns and relationships of "contract.attachment_type"
 */
export type ContractAttachmentType = {
  __typename?: 'contract_attachment_type';
  /** An array relationship */
  agreement_attachments: Array<ContractAgreementAttachment>;
  /** An aggregate relationship */
  agreement_attachments_aggregate: ContractAgreementAttachmentAggregate;
  /** ประเภทเอกสารประกอบข้อมูลลูกหนี้ */
  description: Scalars['String'];
  /** Primary key */
  id: Scalars['String'];
};


/**
 * ประเภทเอกสารประกอบข้อมูลลูกหนี้
 *
 *
 * columns and relationships of "contract.attachment_type"
 */
export type ContractAttachmentTypeAgreementAttachmentsArgs = {
  distinct_on?: Maybe<Array<ContractAgreementAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementAttachmentOrderBy>>;
  where?: Maybe<ContractAgreementAttachmentBoolExp>;
};


/**
 * ประเภทเอกสารประกอบข้อมูลลูกหนี้
 *
 *
 * columns and relationships of "contract.attachment_type"
 */
export type ContractAttachmentTypeAgreementAttachmentsAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementAttachmentOrderBy>>;
  where?: Maybe<ContractAgreementAttachmentBoolExp>;
};

/** aggregated selection of "contract.attachment_type" */
export type ContractAttachmentTypeAggregate = {
  __typename?: 'contract_attachment_type_aggregate';
  aggregate?: Maybe<ContractAttachmentTypeAggregateFields>;
  nodes: Array<ContractAttachmentType>;
};

/** aggregate fields of "contract.attachment_type" */
export type ContractAttachmentTypeAggregateFields = {
  __typename?: 'contract_attachment_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContractAttachmentTypeMaxFields>;
  min?: Maybe<ContractAttachmentTypeMinFields>;
};


/** aggregate fields of "contract.attachment_type" */
export type ContractAttachmentTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ContractAttachmentTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "contract.attachment_type". All fields are combined with a logical 'AND'. */
export type ContractAttachmentTypeBoolExp = {
  _and?: Maybe<Array<ContractAttachmentTypeBoolExp>>;
  _not?: Maybe<ContractAttachmentTypeBoolExp>;
  _or?: Maybe<Array<ContractAttachmentTypeBoolExp>>;
  agreement_attachments?: Maybe<ContractAgreementAttachmentBoolExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "contract.attachment_type" */
export enum ContractAttachmentTypeConstraint {
  /** unique or primary key constraint */
  AttachmentTypePkey = 'attachment_type_pkey'
}

export enum ContractAttachmentTypeEnum {
  /** เอกสารสัญญา */
  Contract = 'contract',
  /** เอกสารประกอบการกู้ */
  ContractDocument = 'contract_document',
  /** เอกสารค้ำประกัน */
  Insurance = 'insurance',
  /** อื่น ๆ */
  Other = 'other',
  /** เอกสารกรมธรรม์คุ้มครองทรัพย์ */
  Policy = 'policy'
}

/** Boolean expression to compare columns of type "contract_attachment_type_enum". All fields are combined with logical 'AND'. */
export type ContractAttachmentTypeEnumComparisonExp = {
  _eq?: Maybe<ContractAttachmentTypeEnum>;
  _in?: Maybe<Array<ContractAttachmentTypeEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ContractAttachmentTypeEnum>;
  _nin?: Maybe<Array<ContractAttachmentTypeEnum>>;
};

/** input type for inserting data into table "contract.attachment_type" */
export type ContractAttachmentTypeInsertInput = {
  agreement_attachments?: Maybe<ContractAgreementAttachmentArrRelInsertInput>;
  /** ประเภทเอกสารประกอบข้อมูลลูกหนี้ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ContractAttachmentTypeMaxFields = {
  __typename?: 'contract_attachment_type_max_fields';
  /** ประเภทเอกสารประกอบข้อมูลลูกหนี้ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ContractAttachmentTypeMinFields = {
  __typename?: 'contract_attachment_type_min_fields';
  /** ประเภทเอกสารประกอบข้อมูลลูกหนี้ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "contract.attachment_type" */
export type ContractAttachmentTypeMutationResponse = {
  __typename?: 'contract_attachment_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContractAttachmentType>;
};

/** input type for inserting object relation for remote table "contract.attachment_type" */
export type ContractAttachmentTypeObjRelInsertInput = {
  data: ContractAttachmentTypeInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<ContractAttachmentTypeOnConflict>;
};

/** on conflict condition type for table "contract.attachment_type" */
export type ContractAttachmentTypeOnConflict = {
  constraint: ContractAttachmentTypeConstraint;
  update_columns?: Array<ContractAttachmentTypeUpdateColumn>;
  where?: Maybe<ContractAttachmentTypeBoolExp>;
};

/** Ordering options when selecting data from "contract.attachment_type". */
export type ContractAttachmentTypeOrderBy = {
  agreement_attachments_aggregate?: Maybe<ContractAgreementAttachmentAggregateOrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** primary key columns input for table: contract_attachment_type */
export type ContractAttachmentTypePkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "contract.attachment_type" */
export enum ContractAttachmentTypeSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "contract.attachment_type" */
export type ContractAttachmentTypeSetInput = {
  /** ประเภทเอกสารประกอบข้อมูลลูกหนี้ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** update columns of table "contract.attachment_type" */
export enum ContractAttachmentTypeUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/**
 * ลำดับชั้น
 *
 *
 * columns and relationships of "contract.grade"
 */
export type ContractGrade = {
  __typename?: 'contract_grade';
  /** An array relationship */
  agreement_transactions: Array<ContractAgreementTransaction>;
  /** An aggregate relationship */
  agreement_transactions_aggregate: ContractAgreementTransactionAggregate;
  /** ลำดับชั้นลูกหนี้ */
  description?: Maybe<Scalars['String']>;
  /** Priamry key */
  value: Scalars['String'];
};


/**
 * ลำดับชั้น
 *
 *
 * columns and relationships of "contract.grade"
 */
export type ContractGradeAgreementTransactionsArgs = {
  distinct_on?: Maybe<Array<ContractAgreementTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementTransactionOrderBy>>;
  where?: Maybe<ContractAgreementTransactionBoolExp>;
};


/**
 * ลำดับชั้น
 *
 *
 * columns and relationships of "contract.grade"
 */
export type ContractGradeAgreementTransactionsAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementTransactionOrderBy>>;
  where?: Maybe<ContractAgreementTransactionBoolExp>;
};

/** aggregated selection of "contract.grade" */
export type ContractGradeAggregate = {
  __typename?: 'contract_grade_aggregate';
  aggregate?: Maybe<ContractGradeAggregateFields>;
  nodes: Array<ContractGrade>;
};

/** aggregate fields of "contract.grade" */
export type ContractGradeAggregateFields = {
  __typename?: 'contract_grade_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContractGradeMaxFields>;
  min?: Maybe<ContractGradeMinFields>;
};


/** aggregate fields of "contract.grade" */
export type ContractGradeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ContractGradeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "contract.grade". All fields are combined with a logical 'AND'. */
export type ContractGradeBoolExp = {
  _and?: Maybe<Array<ContractGradeBoolExp>>;
  _not?: Maybe<ContractGradeBoolExp>;
  _or?: Maybe<Array<ContractGradeBoolExp>>;
  agreement_transactions?: Maybe<ContractAgreementTransactionBoolExp>;
  description?: Maybe<StringComparisonExp>;
  value?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "contract.grade" */
export enum ContractGradeConstraint {
  /** unique or primary key constraint */
  GradePkey = 'grade_pkey'
}

export enum ContractGradeEnum {
  /** A */
  A = 'A',
  /** B */
  B = 'B',
  /** D */
  D = 'D',
  /** M */
  M = 'M',
  /** S */
  S = 'S'
}

/** Boolean expression to compare columns of type "contract_grade_enum". All fields are combined with logical 'AND'. */
export type ContractGradeEnumComparisonExp = {
  _eq?: Maybe<ContractGradeEnum>;
  _in?: Maybe<Array<ContractGradeEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ContractGradeEnum>;
  _nin?: Maybe<Array<ContractGradeEnum>>;
};

/** input type for inserting data into table "contract.grade" */
export type ContractGradeInsertInput = {
  agreement_transactions?: Maybe<ContractAgreementTransactionArrRelInsertInput>;
  /** ลำดับชั้นลูกหนี้ */
  description?: Maybe<Scalars['String']>;
  /** Priamry key */
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ContractGradeMaxFields = {
  __typename?: 'contract_grade_max_fields';
  /** ลำดับชั้นลูกหนี้ */
  description?: Maybe<Scalars['String']>;
  /** Priamry key */
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ContractGradeMinFields = {
  __typename?: 'contract_grade_min_fields';
  /** ลำดับชั้นลูกหนี้ */
  description?: Maybe<Scalars['String']>;
  /** Priamry key */
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "contract.grade" */
export type ContractGradeMutationResponse = {
  __typename?: 'contract_grade_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContractGrade>;
};

/** input type for inserting object relation for remote table "contract.grade" */
export type ContractGradeObjRelInsertInput = {
  data: ContractGradeInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<ContractGradeOnConflict>;
};

/** on conflict condition type for table "contract.grade" */
export type ContractGradeOnConflict = {
  constraint: ContractGradeConstraint;
  update_columns?: Array<ContractGradeUpdateColumn>;
  where?: Maybe<ContractGradeBoolExp>;
};

/** Ordering options when selecting data from "contract.grade". */
export type ContractGradeOrderBy = {
  agreement_transactions_aggregate?: Maybe<ContractAgreementTransactionAggregateOrderBy>;
  description?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
};

/** primary key columns input for table: contract_grade */
export type ContractGradePkColumnsInput = {
  /** Priamry key */
  value: Scalars['String'];
};

/** select columns of table "contract.grade" */
export enum ContractGradeSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "contract.grade" */
export type ContractGradeSetInput = {
  /** ลำดับชั้นลูกหนี้ */
  description?: Maybe<Scalars['String']>;
  /** Priamry key */
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "contract.grade" */
export enum ContractGradeUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/**
 * ประเภทผู้สร้าง agreement_transaction
 *
 *
 * columns and relationships of "contract.transaction_creator_type"
 */
export type ContractTransactionCreatorType = {
  __typename?: 'contract_transaction_creator_type';
  /** An array relationship */
  agreement_transactions: Array<ContractAgreementTransaction>;
  /** An aggregate relationship */
  agreement_transactions_aggregate: ContractAgreementTransactionAggregate;
  /** ประเภทผู้สร้าง agreement_transaction */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value: Scalars['String'];
};


/**
 * ประเภทผู้สร้าง agreement_transaction
 *
 *
 * columns and relationships of "contract.transaction_creator_type"
 */
export type ContractTransactionCreatorTypeAgreementTransactionsArgs = {
  distinct_on?: Maybe<Array<ContractAgreementTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementTransactionOrderBy>>;
  where?: Maybe<ContractAgreementTransactionBoolExp>;
};


/**
 * ประเภทผู้สร้าง agreement_transaction
 *
 *
 * columns and relationships of "contract.transaction_creator_type"
 */
export type ContractTransactionCreatorTypeAgreementTransactionsAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementTransactionOrderBy>>;
  where?: Maybe<ContractAgreementTransactionBoolExp>;
};

/** aggregated selection of "contract.transaction_creator_type" */
export type ContractTransactionCreatorTypeAggregate = {
  __typename?: 'contract_transaction_creator_type_aggregate';
  aggregate?: Maybe<ContractTransactionCreatorTypeAggregateFields>;
  nodes: Array<ContractTransactionCreatorType>;
};

/** aggregate fields of "contract.transaction_creator_type" */
export type ContractTransactionCreatorTypeAggregateFields = {
  __typename?: 'contract_transaction_creator_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContractTransactionCreatorTypeMaxFields>;
  min?: Maybe<ContractTransactionCreatorTypeMinFields>;
};


/** aggregate fields of "contract.transaction_creator_type" */
export type ContractTransactionCreatorTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ContractTransactionCreatorTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "contract.transaction_creator_type". All fields are combined with a logical 'AND'. */
export type ContractTransactionCreatorTypeBoolExp = {
  _and?: Maybe<Array<ContractTransactionCreatorTypeBoolExp>>;
  _not?: Maybe<ContractTransactionCreatorTypeBoolExp>;
  _or?: Maybe<Array<ContractTransactionCreatorTypeBoolExp>>;
  agreement_transactions?: Maybe<ContractAgreementTransactionBoolExp>;
  description?: Maybe<StringComparisonExp>;
  value?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "contract.transaction_creator_type" */
export enum ContractTransactionCreatorTypeConstraint {
  /** unique or primary key constraint */
  TransactionCreatorTypePkey = 'transaction_creator_type_pkey'
}

export enum ContractTransactionCreatorTypeEnum {
  /** System */
  System = 'system',
  /** User */
  User = 'user'
}

/** Boolean expression to compare columns of type "contract_transaction_creator_type_enum". All fields are combined with logical 'AND'. */
export type ContractTransactionCreatorTypeEnumComparisonExp = {
  _eq?: Maybe<ContractTransactionCreatorTypeEnum>;
  _in?: Maybe<Array<ContractTransactionCreatorTypeEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ContractTransactionCreatorTypeEnum>;
  _nin?: Maybe<Array<ContractTransactionCreatorTypeEnum>>;
};

/** input type for inserting data into table "contract.transaction_creator_type" */
export type ContractTransactionCreatorTypeInsertInput = {
  agreement_transactions?: Maybe<ContractAgreementTransactionArrRelInsertInput>;
  /** ประเภทผู้สร้าง agreement_transaction */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ContractTransactionCreatorTypeMaxFields = {
  __typename?: 'contract_transaction_creator_type_max_fields';
  /** ประเภทผู้สร้าง agreement_transaction */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ContractTransactionCreatorTypeMinFields = {
  __typename?: 'contract_transaction_creator_type_min_fields';
  /** ประเภทผู้สร้าง agreement_transaction */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "contract.transaction_creator_type" */
export type ContractTransactionCreatorTypeMutationResponse = {
  __typename?: 'contract_transaction_creator_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContractTransactionCreatorType>;
};

/** input type for inserting object relation for remote table "contract.transaction_creator_type" */
export type ContractTransactionCreatorTypeObjRelInsertInput = {
  data: ContractTransactionCreatorTypeInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<ContractTransactionCreatorTypeOnConflict>;
};

/** on conflict condition type for table "contract.transaction_creator_type" */
export type ContractTransactionCreatorTypeOnConflict = {
  constraint: ContractTransactionCreatorTypeConstraint;
  update_columns?: Array<ContractTransactionCreatorTypeUpdateColumn>;
  where?: Maybe<ContractTransactionCreatorTypeBoolExp>;
};

/** Ordering options when selecting data from "contract.transaction_creator_type". */
export type ContractTransactionCreatorTypeOrderBy = {
  agreement_transactions_aggregate?: Maybe<ContractAgreementTransactionAggregateOrderBy>;
  description?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
};

/** primary key columns input for table: contract_transaction_creator_type */
export type ContractTransactionCreatorTypePkColumnsInput = {
  /** Primary key */
  value: Scalars['String'];
};

/** select columns of table "contract.transaction_creator_type" */
export enum ContractTransactionCreatorTypeSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "contract.transaction_creator_type" */
export type ContractTransactionCreatorTypeSetInput = {
  /** ประเภทผู้สร้าง agreement_transaction */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "contract.transaction_creator_type" */
export enum ContractTransactionCreatorTypeUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "current_user_permissions" */
export type CurrentUserPermissions = {
  __typename?: 'current_user_permissions';
  permission_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "current_user_permissions" */
export type CurrentUserPermissionsAggregate = {
  __typename?: 'current_user_permissions_aggregate';
  aggregate?: Maybe<CurrentUserPermissionsAggregateFields>;
  nodes: Array<CurrentUserPermissions>;
};

/** aggregate fields of "current_user_permissions" */
export type CurrentUserPermissionsAggregateFields = {
  __typename?: 'current_user_permissions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CurrentUserPermissionsMaxFields>;
  min?: Maybe<CurrentUserPermissionsMinFields>;
};


/** aggregate fields of "current_user_permissions" */
export type CurrentUserPermissionsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CurrentUserPermissionsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "current_user_permissions". All fields are combined with a logical 'AND'. */
export type CurrentUserPermissionsBoolExp = {
  _and?: Maybe<Array<CurrentUserPermissionsBoolExp>>;
  _not?: Maybe<CurrentUserPermissionsBoolExp>;
  _or?: Maybe<Array<CurrentUserPermissionsBoolExp>>;
  permission_id?: Maybe<StringComparisonExp>;
  user_id?: Maybe<UuidComparisonExp>;
};

/** input type for inserting data into table "current_user_permissions" */
export type CurrentUserPermissionsInsertInput = {
  permission_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CurrentUserPermissionsMaxFields = {
  __typename?: 'current_user_permissions_max_fields';
  permission_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type CurrentUserPermissionsMinFields = {
  __typename?: 'current_user_permissions_min_fields';
  permission_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "current_user_permissions" */
export type CurrentUserPermissionsMutationResponse = {
  __typename?: 'current_user_permissions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CurrentUserPermissions>;
};

/** Ordering options when selecting data from "current_user_permissions". */
export type CurrentUserPermissionsOrderBy = {
  permission_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** select columns of table "current_user_permissions" */
export enum CurrentUserPermissionsSelectColumn {
  /** column name */
  PermissionId = 'permission_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "current_user_permissions" */
export type CurrentUserPermissionsSetInput = {
  permission_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type DateComparisonExp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "department" */
export type Department = {
  __typename?: 'department';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  short_name_en?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: UsersAggregate;
};


/** columns and relationships of "department" */
export type DepartmentUsersArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};


/** columns and relationships of "department" */
export type DepartmentUsersAggregateArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};

/** aggregated selection of "department" */
export type DepartmentAggregate = {
  __typename?: 'department_aggregate';
  aggregate?: Maybe<DepartmentAggregateFields>;
  nodes: Array<Department>;
};

/** aggregate fields of "department" */
export type DepartmentAggregateFields = {
  __typename?: 'department_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<DepartmentMaxFields>;
  min?: Maybe<DepartmentMinFields>;
};


/** aggregate fields of "department" */
export type DepartmentAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DepartmentSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "department". All fields are combined with a logical 'AND'. */
export type DepartmentBoolExp = {
  _and?: Maybe<Array<DepartmentBoolExp>>;
  _not?: Maybe<DepartmentBoolExp>;
  _or?: Maybe<Array<DepartmentBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  name_en?: Maybe<StringComparisonExp>;
  reference?: Maybe<StringComparisonExp>;
  short_name?: Maybe<StringComparisonExp>;
  short_name_en?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  users?: Maybe<UsersBoolExp>;
};

/** unique or primary key constraints on table "department" */
export enum DepartmentConstraint {
  /** unique or primary key constraint */
  DepartmentPkey = 'department_pkey'
}

/** input type for inserting data into table "department" */
export type DepartmentInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  short_name_en?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  users?: Maybe<UsersArrRelInsertInput>;
};

/** aggregate max on columns */
export type DepartmentMaxFields = {
  __typename?: 'department_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  short_name_en?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type DepartmentMinFields = {
  __typename?: 'department_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  short_name_en?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "department" */
export type DepartmentMutationResponse = {
  __typename?: 'department_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Department>;
};

/** input type for inserting object relation for remote table "department" */
export type DepartmentObjRelInsertInput = {
  data: DepartmentInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<DepartmentOnConflict>;
};

/** on conflict condition type for table "department" */
export type DepartmentOnConflict = {
  constraint: DepartmentConstraint;
  update_columns?: Array<DepartmentUpdateColumn>;
  where?: Maybe<DepartmentBoolExp>;
};

/** Ordering options when selecting data from "department". */
export type DepartmentOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  name_en?: Maybe<OrderBy>;
  reference?: Maybe<OrderBy>;
  short_name?: Maybe<OrderBy>;
  short_name_en?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  users_aggregate?: Maybe<UsersAggregateOrderBy>;
};

/** primary key columns input for table: department */
export type DepartmentPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "department" */
export enum DepartmentSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  Reference = 'reference',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  ShortNameEn = 'short_name_en',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "department" */
export type DepartmentSetInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  short_name_en?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "department" */
export enum DepartmentUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  Reference = 'reference',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  ShortNameEn = 'short_name_en',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * feature_toggle
 *
 *
 * columns and relationships of "feature_toggle"
 */
export type FeatureToggle = {
  __typename?: 'feature_toggle';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย feature_toggle */
  description?: Maybe<Scalars['String']>;
  /** สถานะ true = เปิดใช้งาน, false = ปิดใช้งาน */
  enable: Scalars['Boolean'];
  /** Primary key */
  id: Scalars['String'];
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "feature_toggle" */
export type FeatureToggleAggregate = {
  __typename?: 'feature_toggle_aggregate';
  aggregate?: Maybe<FeatureToggleAggregateFields>;
  nodes: Array<FeatureToggle>;
};

/** aggregate fields of "feature_toggle" */
export type FeatureToggleAggregateFields = {
  __typename?: 'feature_toggle_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<FeatureToggleMaxFields>;
  min?: Maybe<FeatureToggleMinFields>;
};


/** aggregate fields of "feature_toggle" */
export type FeatureToggleAggregateFieldsCountArgs = {
  columns?: Maybe<Array<FeatureToggleSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "feature_toggle". All fields are combined with a logical 'AND'. */
export type FeatureToggleBoolExp = {
  _and?: Maybe<Array<FeatureToggleBoolExp>>;
  _not?: Maybe<FeatureToggleBoolExp>;
  _or?: Maybe<Array<FeatureToggleBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  enable?: Maybe<BooleanComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "feature_toggle" */
export enum FeatureToggleConstraint {
  /** unique or primary key constraint */
  FeatureTogglePkey = 'feature_toggle_pkey'
}

/** input type for inserting data into table "feature_toggle" */
export type FeatureToggleInsertInput = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย feature_toggle */
  description?: Maybe<Scalars['String']>;
  /** สถานะ true = เปิดใช้งาน, false = ปิดใช้งาน */
  enable?: Maybe<Scalars['Boolean']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type FeatureToggleMaxFields = {
  __typename?: 'feature_toggle_max_fields';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย feature_toggle */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type FeatureToggleMinFields = {
  __typename?: 'feature_toggle_min_fields';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย feature_toggle */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "feature_toggle" */
export type FeatureToggleMutationResponse = {
  __typename?: 'feature_toggle_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FeatureToggle>;
};

/** on conflict condition type for table "feature_toggle" */
export type FeatureToggleOnConflict = {
  constraint: FeatureToggleConstraint;
  update_columns?: Array<FeatureToggleUpdateColumn>;
  where?: Maybe<FeatureToggleBoolExp>;
};

/** Ordering options when selecting data from "feature_toggle". */
export type FeatureToggleOrderBy = {
  created_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  enable?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: feature_toggle */
export type FeatureTogglePkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "feature_toggle" */
export enum FeatureToggleSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Enable = 'enable',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "feature_toggle" */
export type FeatureToggleSetInput = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย feature_toggle */
  description?: Maybe<Scalars['String']>;
  /** สถานะ true = เปิดใช้งาน, false = ปิดใช้งาน */
  enable?: Maybe<Scalars['Boolean']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "feature_toggle" */
export enum FeatureToggleUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Enable = 'enable',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * ปีงบประมาณ (พ.ศ.)
 *
 *
 * columns and relationships of "fiscal_year"
 */
export type FiscalYear = {
  __typename?: 'fiscal_year';
  /** ปีงบประมาณ (พ.ศ.) */
  year: Scalars['Int'];
};

/** aggregated selection of "fiscal_year" */
export type FiscalYearAggregate = {
  __typename?: 'fiscal_year_aggregate';
  aggregate?: Maybe<FiscalYearAggregateFields>;
  nodes: Array<FiscalYear>;
};

/** aggregate fields of "fiscal_year" */
export type FiscalYearAggregateFields = {
  __typename?: 'fiscal_year_aggregate_fields';
  avg?: Maybe<FiscalYearAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<FiscalYearMaxFields>;
  min?: Maybe<FiscalYearMinFields>;
  stddev?: Maybe<FiscalYearStddevFields>;
  stddev_pop?: Maybe<FiscalYearStddevPopFields>;
  stddev_samp?: Maybe<FiscalYearStddevSampFields>;
  sum?: Maybe<FiscalYearSumFields>;
  var_pop?: Maybe<FiscalYearVarPopFields>;
  var_samp?: Maybe<FiscalYearVarSampFields>;
  variance?: Maybe<FiscalYearVarianceFields>;
};


/** aggregate fields of "fiscal_year" */
export type FiscalYearAggregateFieldsCountArgs = {
  columns?: Maybe<Array<FiscalYearSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type FiscalYearAvgFields = {
  __typename?: 'fiscal_year_avg_fields';
  /** ปีงบประมาณ (พ.ศ.) */
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "fiscal_year". All fields are combined with a logical 'AND'. */
export type FiscalYearBoolExp = {
  _and?: Maybe<Array<FiscalYearBoolExp>>;
  _not?: Maybe<FiscalYearBoolExp>;
  _or?: Maybe<Array<FiscalYearBoolExp>>;
  year?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "fiscal_year" */
export enum FiscalYearConstraint {
  /** unique or primary key constraint */
  FiscalYearPkey = 'fiscal_year_pkey'
}

/** input type for incrementing numeric columns in table "fiscal_year" */
export type FiscalYearIncInput = {
  /** ปีงบประมาณ (พ.ศ.) */
  year?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "fiscal_year" */
export type FiscalYearInsertInput = {
  /** ปีงบประมาณ (พ.ศ.) */
  year?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type FiscalYearMaxFields = {
  __typename?: 'fiscal_year_max_fields';
  /** ปีงบประมาณ (พ.ศ.) */
  year?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type FiscalYearMinFields = {
  __typename?: 'fiscal_year_min_fields';
  /** ปีงบประมาณ (พ.ศ.) */
  year?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "fiscal_year" */
export type FiscalYearMutationResponse = {
  __typename?: 'fiscal_year_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FiscalYear>;
};

/** on conflict condition type for table "fiscal_year" */
export type FiscalYearOnConflict = {
  constraint: FiscalYearConstraint;
  update_columns?: Array<FiscalYearUpdateColumn>;
  where?: Maybe<FiscalYearBoolExp>;
};

/** Ordering options when selecting data from "fiscal_year". */
export type FiscalYearOrderBy = {
  year?: Maybe<OrderBy>;
};

/** primary key columns input for table: fiscal_year */
export type FiscalYearPkColumnsInput = {
  /** ปีงบประมาณ (พ.ศ.) */
  year: Scalars['Int'];
};

/** select columns of table "fiscal_year" */
export enum FiscalYearSelectColumn {
  /** column name */
  Year = 'year'
}

/** input type for updating data in table "fiscal_year" */
export type FiscalYearSetInput = {
  /** ปีงบประมาณ (พ.ศ.) */
  year?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type FiscalYearStddevFields = {
  __typename?: 'fiscal_year_stddev_fields';
  /** ปีงบประมาณ (พ.ศ.) */
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type FiscalYearStddevPopFields = {
  __typename?: 'fiscal_year_stddev_pop_fields';
  /** ปีงบประมาณ (พ.ศ.) */
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type FiscalYearStddevSampFields = {
  __typename?: 'fiscal_year_stddev_samp_fields';
  /** ปีงบประมาณ (พ.ศ.) */
  year?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type FiscalYearSumFields = {
  __typename?: 'fiscal_year_sum_fields';
  /** ปีงบประมาณ (พ.ศ.) */
  year?: Maybe<Scalars['Int']>;
};

/** update columns of table "fiscal_year" */
export enum FiscalYearUpdateColumn {
  /** column name */
  Year = 'year'
}

/** aggregate var_pop on columns */
export type FiscalYearVarPopFields = {
  __typename?: 'fiscal_year_var_pop_fields';
  /** ปีงบประมาณ (พ.ศ.) */
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type FiscalYearVarSampFields = {
  __typename?: 'fiscal_year_var_samp_fields';
  /** ปีงบประมาณ (พ.ศ.) */
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type FiscalYearVarianceFields = {
  __typename?: 'fiscal_year_variance_fields';
  /** ปีงบประมาณ (พ.ศ.) */
  year?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8ComparisonExp = {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
};

/**
 * ประวัติการนำเข้าข้อมูล
 *
 *
 * columns and relationships of "import_log"
 */
export type ImportLog = {
  __typename?: 'import_log';
  /** วันที่บันทึกข้อมูล */
  created_at: Scalars['timestamptz'];
  /** ชื่อไฟล์ */
  filename: Scalars['String'];
  /** Primary key */
  id: Scalars['uuid'];
  /** ชื่อ */
  name: Scalars['String'];
  /** An object relationship */
  user?: Maybe<Users>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "import_log" */
export type ImportLogAggregate = {
  __typename?: 'import_log_aggregate';
  aggregate?: Maybe<ImportLogAggregateFields>;
  nodes: Array<ImportLog>;
};

/** aggregate fields of "import_log" */
export type ImportLogAggregateFields = {
  __typename?: 'import_log_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ImportLogMaxFields>;
  min?: Maybe<ImportLogMinFields>;
};


/** aggregate fields of "import_log" */
export type ImportLogAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ImportLogSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "import_log" */
export type ImportLogAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ImportLogMaxOrderBy>;
  min?: Maybe<ImportLogMinOrderBy>;
};

/** input type for inserting array relation for remote table "import_log" */
export type ImportLogArrRelInsertInput = {
  data: Array<ImportLogInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ImportLogOnConflict>;
};

/** Boolean expression to filter rows from the table "import_log". All fields are combined with a logical 'AND'. */
export type ImportLogBoolExp = {
  _and?: Maybe<Array<ImportLogBoolExp>>;
  _not?: Maybe<ImportLogBoolExp>;
  _or?: Maybe<Array<ImportLogBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  filename?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  user?: Maybe<UsersBoolExp>;
  user_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "import_log" */
export enum ImportLogConstraint {
  /** unique or primary key constraint */
  ImportLogPkey = 'import_log_pkey'
}

/** input type for inserting data into table "import_log" */
export type ImportLogInsertInput = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** ชื่อไฟล์ */
  filename?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อ */
  name?: Maybe<Scalars['String']>;
  user?: Maybe<UsersObjRelInsertInput>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ImportLogMaxFields = {
  __typename?: 'import_log_max_fields';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** ชื่อไฟล์ */
  filename?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อ */
  name?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "import_log" */
export type ImportLogMaxOrderBy = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** ชื่อไฟล์ */
  filename?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ชื่อ */
  name?: Maybe<OrderBy>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ImportLogMinFields = {
  __typename?: 'import_log_min_fields';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** ชื่อไฟล์ */
  filename?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อ */
  name?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "import_log" */
export type ImportLogMinOrderBy = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** ชื่อไฟล์ */
  filename?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ชื่อ */
  name?: Maybe<OrderBy>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "import_log" */
export type ImportLogMutationResponse = {
  __typename?: 'import_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ImportLog>;
};

/** on conflict condition type for table "import_log" */
export type ImportLogOnConflict = {
  constraint: ImportLogConstraint;
  update_columns?: Array<ImportLogUpdateColumn>;
  where?: Maybe<ImportLogBoolExp>;
};

/** Ordering options when selecting data from "import_log". */
export type ImportLogOrderBy = {
  created_at?: Maybe<OrderBy>;
  filename?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  user?: Maybe<UsersOrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: import_log */
export type ImportLogPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "import_log" */
export enum ImportLogSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "import_log" */
export type ImportLogSetInput = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** ชื่อไฟล์ */
  filename?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อ */
  name?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "import_log" */
export enum ImportLogUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'user_id'
}


/** Boolean expression to compare columns of type "inet". All fields are combined with logical 'AND'. */
export type InetComparisonExp = {
  _eq?: Maybe<Scalars['inet']>;
  _gt?: Maybe<Scalars['inet']>;
  _gte?: Maybe<Scalars['inet']>;
  _in?: Maybe<Array<Scalars['inet']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['inet']>;
  _lte?: Maybe<Scalars['inet']>;
  _neq?: Maybe<Scalars['inet']>;
  _nin?: Maybe<Array<Scalars['inet']>>;
};


/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/**
 * ข้อมูลนิติบุคคล
 *
 *
 * columns and relationships of "juristic"
 */
export type Juristic = {
  __typename?: 'juristic';
  /** An array relationship */
  agreements: Array<ContractAgreement>;
  /** An aggregate relationship */
  agreements_aggregate: ContractAgreementAggregate;
  /** An array relationship */
  application_event_logs: Array<LoanApplicationEventLog>;
  /** An aggregate relationship */
  application_event_logs_aggregate: LoanApplicationEventLogAggregate;
  /** กลุ่มธุรกิจ */
  business_group?: Maybe<Scalars['String']>;
  /** ประเภทธุรกิจ */
  business_type?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** เลขนิติบุคคล I-Industry API : (AddressInformations.JuristicID) */
  id: Scalars['String'];
  /** สถานะ true = นำเข้าแล้ว, false = ยังไม่ได้นำเข้า */
  imported: Scalars['Boolean'];
  /** เคยขอสินเชื่อหรือยัง */
  is_member: Scalars['Boolean'];
  /** An array relationship */
  juristic_addresses: Array<JuristicAddress>;
  /** An aggregate relationship */
  juristic_addresses_aggregate: JuristicAddressAggregate;
  /** An array relationship */
  juristic_committees: Array<JuristicCommittee>;
  /** An aggregate relationship */
  juristic_committees_aggregate: JuristicCommitteeAggregate;
  /** An array relationship */
  juristic_standard_objectives: Array<JuristicStandardObjective>;
  /** An aggregate relationship */
  juristic_standard_objectives_aggregate: JuristicStandardObjectiveAggregate;
  /** I-Industry API : (JuristicStatus) */
  juristic_status?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.JuristicName_EN) */
  name_en?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.JuristicName_TH) */
  name_th?: Maybe<Scalars['String']>;
  /** I-Industry API : (NumberOfCommittee) */
  number_of_commitee?: Maybe<Scalars['numeric']>;
  /** จำนวนวัตถุประสงค์ */
  number_of_objective?: Maybe<Scalars['numeric']>;
  /** I-Industry API : (AddressInformations.PaidRegisterCapital) */
  paid_register_capital?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.RegisterCapital) */
  register_capital?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.RegisterDate) */
  register_date?: Maybe<Scalars['date']>;
  /** ประเภทบริษัท I-Industry API : (AddressInformations.JuristicType) */
  type?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/**
 * ข้อมูลนิติบุคคล
 *
 *
 * columns and relationships of "juristic"
 */
export type JuristicAgreementsArgs = {
  distinct_on?: Maybe<Array<ContractAgreementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementOrderBy>>;
  where?: Maybe<ContractAgreementBoolExp>;
};


/**
 * ข้อมูลนิติบุคคล
 *
 *
 * columns and relationships of "juristic"
 */
export type JuristicAgreementsAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementOrderBy>>;
  where?: Maybe<ContractAgreementBoolExp>;
};


/**
 * ข้อมูลนิติบุคคล
 *
 *
 * columns and relationships of "juristic"
 */
export type JuristicApplicationEventLogsArgs = {
  distinct_on?: Maybe<Array<LoanApplicationEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationEventLogOrderBy>>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
};


/**
 * ข้อมูลนิติบุคคล
 *
 *
 * columns and relationships of "juristic"
 */
export type JuristicApplicationEventLogsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationEventLogOrderBy>>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
};


/**
 * ข้อมูลนิติบุคคล
 *
 *
 * columns and relationships of "juristic"
 */
export type JuristicJuristicAddressesArgs = {
  distinct_on?: Maybe<Array<JuristicAddressSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicAddressOrderBy>>;
  where?: Maybe<JuristicAddressBoolExp>;
};


/**
 * ข้อมูลนิติบุคคล
 *
 *
 * columns and relationships of "juristic"
 */
export type JuristicJuristicAddressesAggregateArgs = {
  distinct_on?: Maybe<Array<JuristicAddressSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicAddressOrderBy>>;
  where?: Maybe<JuristicAddressBoolExp>;
};


/**
 * ข้อมูลนิติบุคคล
 *
 *
 * columns and relationships of "juristic"
 */
export type JuristicJuristicCommitteesArgs = {
  distinct_on?: Maybe<Array<JuristicCommitteeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicCommitteeOrderBy>>;
  where?: Maybe<JuristicCommitteeBoolExp>;
};


/**
 * ข้อมูลนิติบุคคล
 *
 *
 * columns and relationships of "juristic"
 */
export type JuristicJuristicCommitteesAggregateArgs = {
  distinct_on?: Maybe<Array<JuristicCommitteeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicCommitteeOrderBy>>;
  where?: Maybe<JuristicCommitteeBoolExp>;
};


/**
 * ข้อมูลนิติบุคคล
 *
 *
 * columns and relationships of "juristic"
 */
export type JuristicJuristicStandardObjectivesArgs = {
  distinct_on?: Maybe<Array<JuristicStandardObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicStandardObjectiveOrderBy>>;
  where?: Maybe<JuristicStandardObjectiveBoolExp>;
};


/**
 * ข้อมูลนิติบุคคล
 *
 *
 * columns and relationships of "juristic"
 */
export type JuristicJuristicStandardObjectivesAggregateArgs = {
  distinct_on?: Maybe<Array<JuristicStandardObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicStandardObjectiveOrderBy>>;
  where?: Maybe<JuristicStandardObjectiveBoolExp>;
};

/**
 * ที่อยู่นิดิบุคคล
 *
 *
 * columns and relationships of "juristic_address"
 */
export type JuristicAddress = {
  __typename?: 'juristic_address';
  /** I-Industry API : (AddressInformations.AddressNo) */
  address_no?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Ampur) */
  amphoe?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Building) */
  building?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** I-Industry API : (AddressInformations.District) */
  district?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Email) */
  email?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Floor) */
  floor?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** An object relationship */
  juristic: Juristic;
  /** juristic.reference */
  juristic_id: Scalars['String'];
  /** I-Industry API : (AddressInformations.Moo) */
  moo?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Phone) */
  phone?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Province) */
  province?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Road) */
  road?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.RoomNo) */
  room_no?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<Scalars['numeric']>;
  /** I-Industry API : (AddressInformations.Soi) */
  soi?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** I-Industry API : (AddressInformations.VillageName) */
  village_name?: Maybe<Scalars['String']>;
  /** รหัสไปรษณีย์ */
  zipcode?: Maybe<Scalars['String']>;
};

/** aggregated selection of "juristic_address" */
export type JuristicAddressAggregate = {
  __typename?: 'juristic_address_aggregate';
  aggregate?: Maybe<JuristicAddressAggregateFields>;
  nodes: Array<JuristicAddress>;
};

/** aggregate fields of "juristic_address" */
export type JuristicAddressAggregateFields = {
  __typename?: 'juristic_address_aggregate_fields';
  avg?: Maybe<JuristicAddressAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<JuristicAddressMaxFields>;
  min?: Maybe<JuristicAddressMinFields>;
  stddev?: Maybe<JuristicAddressStddevFields>;
  stddev_pop?: Maybe<JuristicAddressStddevPopFields>;
  stddev_samp?: Maybe<JuristicAddressStddevSampFields>;
  sum?: Maybe<JuristicAddressSumFields>;
  var_pop?: Maybe<JuristicAddressVarPopFields>;
  var_samp?: Maybe<JuristicAddressVarSampFields>;
  variance?: Maybe<JuristicAddressVarianceFields>;
};


/** aggregate fields of "juristic_address" */
export type JuristicAddressAggregateFieldsCountArgs = {
  columns?: Maybe<Array<JuristicAddressSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "juristic_address" */
export type JuristicAddressAggregateOrderBy = {
  avg?: Maybe<JuristicAddressAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<JuristicAddressMaxOrderBy>;
  min?: Maybe<JuristicAddressMinOrderBy>;
  stddev?: Maybe<JuristicAddressStddevOrderBy>;
  stddev_pop?: Maybe<JuristicAddressStddevPopOrderBy>;
  stddev_samp?: Maybe<JuristicAddressStddevSampOrderBy>;
  sum?: Maybe<JuristicAddressSumOrderBy>;
  var_pop?: Maybe<JuristicAddressVarPopOrderBy>;
  var_samp?: Maybe<JuristicAddressVarSampOrderBy>;
  variance?: Maybe<JuristicAddressVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "juristic_address" */
export type JuristicAddressArrRelInsertInput = {
  data: Array<JuristicAddressInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<JuristicAddressOnConflict>;
};

/** aggregate avg on columns */
export type JuristicAddressAvgFields = {
  __typename?: 'juristic_address_avg_fields';
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "juristic_address" */
export type JuristicAddressAvgOrderBy = {
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "juristic_address". All fields are combined with a logical 'AND'. */
export type JuristicAddressBoolExp = {
  _and?: Maybe<Array<JuristicAddressBoolExp>>;
  _not?: Maybe<JuristicAddressBoolExp>;
  _or?: Maybe<Array<JuristicAddressBoolExp>>;
  address_no?: Maybe<StringComparisonExp>;
  amphoe?: Maybe<StringComparisonExp>;
  building?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  district?: Maybe<StringComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  floor?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  juristic?: Maybe<JuristicBoolExp>;
  juristic_id?: Maybe<StringComparisonExp>;
  moo?: Maybe<StringComparisonExp>;
  phone?: Maybe<StringComparisonExp>;
  province?: Maybe<StringComparisonExp>;
  road?: Maybe<StringComparisonExp>;
  room_no?: Maybe<StringComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
  soi?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  village_name?: Maybe<StringComparisonExp>;
  zipcode?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "juristic_address" */
export enum JuristicAddressConstraint {
  /** unique or primary key constraint */
  JuristicAddressPkey = 'juristic_address_pkey'
}

/** input type for incrementing numeric columns in table "juristic_address" */
export type JuristicAddressIncInput = {
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "juristic_address" */
export type JuristicAddressInsertInput = {
  /** I-Industry API : (AddressInformations.AddressNo) */
  address_no?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Ampur) */
  amphoe?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Building) */
  building?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** I-Industry API : (AddressInformations.District) */
  district?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Email) */
  email?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Floor) */
  floor?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  juristic?: Maybe<JuristicObjRelInsertInput>;
  /** juristic.reference */
  juristic_id?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Moo) */
  moo?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Phone) */
  phone?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Province) */
  province?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Road) */
  road?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.RoomNo) */
  room_no?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<Scalars['numeric']>;
  /** I-Industry API : (AddressInformations.Soi) */
  soi?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** I-Industry API : (AddressInformations.VillageName) */
  village_name?: Maybe<Scalars['String']>;
  /** รหัสไปรษณีย์ */
  zipcode?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type JuristicAddressMaxFields = {
  __typename?: 'juristic_address_max_fields';
  /** I-Industry API : (AddressInformations.AddressNo) */
  address_no?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Ampur) */
  amphoe?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Building) */
  building?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** I-Industry API : (AddressInformations.District) */
  district?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Email) */
  email?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Floor) */
  floor?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** juristic.reference */
  juristic_id?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Moo) */
  moo?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Phone) */
  phone?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Province) */
  province?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Road) */
  road?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.RoomNo) */
  room_no?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<Scalars['numeric']>;
  /** I-Industry API : (AddressInformations.Soi) */
  soi?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** I-Industry API : (AddressInformations.VillageName) */
  village_name?: Maybe<Scalars['String']>;
  /** รหัสไปรษณีย์ */
  zipcode?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "juristic_address" */
export type JuristicAddressMaxOrderBy = {
  /** I-Industry API : (AddressInformations.AddressNo) */
  address_no?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.Ampur) */
  amphoe?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.Building) */
  building?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.District) */
  district?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.Email) */
  email?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.Floor) */
  floor?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** juristic.reference */
  juristic_id?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.Moo) */
  moo?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.Phone) */
  phone?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.Province) */
  province?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.Road) */
  road?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.RoomNo) */
  room_no?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.Soi) */
  soi?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.VillageName) */
  village_name?: Maybe<OrderBy>;
  /** รหัสไปรษณีย์ */
  zipcode?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type JuristicAddressMinFields = {
  __typename?: 'juristic_address_min_fields';
  /** I-Industry API : (AddressInformations.AddressNo) */
  address_no?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Ampur) */
  amphoe?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Building) */
  building?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** I-Industry API : (AddressInformations.District) */
  district?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Email) */
  email?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Floor) */
  floor?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** juristic.reference */
  juristic_id?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Moo) */
  moo?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Phone) */
  phone?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Province) */
  province?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Road) */
  road?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.RoomNo) */
  room_no?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<Scalars['numeric']>;
  /** I-Industry API : (AddressInformations.Soi) */
  soi?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** I-Industry API : (AddressInformations.VillageName) */
  village_name?: Maybe<Scalars['String']>;
  /** รหัสไปรษณีย์ */
  zipcode?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "juristic_address" */
export type JuristicAddressMinOrderBy = {
  /** I-Industry API : (AddressInformations.AddressNo) */
  address_no?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.Ampur) */
  amphoe?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.Building) */
  building?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.District) */
  district?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.Email) */
  email?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.Floor) */
  floor?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** juristic.reference */
  juristic_id?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.Moo) */
  moo?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.Phone) */
  phone?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.Province) */
  province?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.Road) */
  road?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.RoomNo) */
  room_no?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.Soi) */
  soi?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** I-Industry API : (AddressInformations.VillageName) */
  village_name?: Maybe<OrderBy>;
  /** รหัสไปรษณีย์ */
  zipcode?: Maybe<OrderBy>;
};

/** response of any mutation on the table "juristic_address" */
export type JuristicAddressMutationResponse = {
  __typename?: 'juristic_address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<JuristicAddress>;
};

/** on conflict condition type for table "juristic_address" */
export type JuristicAddressOnConflict = {
  constraint: JuristicAddressConstraint;
  update_columns?: Array<JuristicAddressUpdateColumn>;
  where?: Maybe<JuristicAddressBoolExp>;
};

/** Ordering options when selecting data from "juristic_address". */
export type JuristicAddressOrderBy = {
  address_no?: Maybe<OrderBy>;
  amphoe?: Maybe<OrderBy>;
  building?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  district?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  floor?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  juristic?: Maybe<JuristicOrderBy>;
  juristic_id?: Maybe<OrderBy>;
  moo?: Maybe<OrderBy>;
  phone?: Maybe<OrderBy>;
  province?: Maybe<OrderBy>;
  road?: Maybe<OrderBy>;
  room_no?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
  soi?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  village_name?: Maybe<OrderBy>;
  zipcode?: Maybe<OrderBy>;
};

/** primary key columns input for table: juristic_address */
export type JuristicAddressPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "juristic_address" */
export enum JuristicAddressSelectColumn {
  /** column name */
  AddressNo = 'address_no',
  /** column name */
  Amphoe = 'amphoe',
  /** column name */
  Building = 'building',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  District = 'district',
  /** column name */
  Email = 'email',
  /** column name */
  Floor = 'floor',
  /** column name */
  Id = 'id',
  /** column name */
  JuristicId = 'juristic_id',
  /** column name */
  Moo = 'moo',
  /** column name */
  Phone = 'phone',
  /** column name */
  Province = 'province',
  /** column name */
  Road = 'road',
  /** column name */
  RoomNo = 'room_no',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  Soi = 'soi',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VillageName = 'village_name',
  /** column name */
  Zipcode = 'zipcode'
}

/** input type for updating data in table "juristic_address" */
export type JuristicAddressSetInput = {
  /** I-Industry API : (AddressInformations.AddressNo) */
  address_no?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Ampur) */
  amphoe?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Building) */
  building?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** I-Industry API : (AddressInformations.District) */
  district?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Email) */
  email?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Floor) */
  floor?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** juristic.reference */
  juristic_id?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Moo) */
  moo?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Phone) */
  phone?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Province) */
  province?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Road) */
  road?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.RoomNo) */
  room_no?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<Scalars['numeric']>;
  /** I-Industry API : (AddressInformations.Soi) */
  soi?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** I-Industry API : (AddressInformations.VillageName) */
  village_name?: Maybe<Scalars['String']>;
  /** รหัสไปรษณีย์ */
  zipcode?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type JuristicAddressStddevFields = {
  __typename?: 'juristic_address_stddev_fields';
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "juristic_address" */
export type JuristicAddressStddevOrderBy = {
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type JuristicAddressStddevPopFields = {
  __typename?: 'juristic_address_stddev_pop_fields';
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "juristic_address" */
export type JuristicAddressStddevPopOrderBy = {
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type JuristicAddressStddevSampFields = {
  __typename?: 'juristic_address_stddev_samp_fields';
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "juristic_address" */
export type JuristicAddressStddevSampOrderBy = {
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type JuristicAddressSumFields = {
  __typename?: 'juristic_address_sum_fields';
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "juristic_address" */
export type JuristicAddressSumOrderBy = {
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "juristic_address" */
export enum JuristicAddressUpdateColumn {
  /** column name */
  AddressNo = 'address_no',
  /** column name */
  Amphoe = 'amphoe',
  /** column name */
  Building = 'building',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  District = 'district',
  /** column name */
  Email = 'email',
  /** column name */
  Floor = 'floor',
  /** column name */
  Id = 'id',
  /** column name */
  JuristicId = 'juristic_id',
  /** column name */
  Moo = 'moo',
  /** column name */
  Phone = 'phone',
  /** column name */
  Province = 'province',
  /** column name */
  Road = 'road',
  /** column name */
  RoomNo = 'room_no',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  Soi = 'soi',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VillageName = 'village_name',
  /** column name */
  Zipcode = 'zipcode'
}

/** aggregate var_pop on columns */
export type JuristicAddressVarPopFields = {
  __typename?: 'juristic_address_var_pop_fields';
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "juristic_address" */
export type JuristicAddressVarPopOrderBy = {
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type JuristicAddressVarSampFields = {
  __typename?: 'juristic_address_var_samp_fields';
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "juristic_address" */
export type JuristicAddressVarSampOrderBy = {
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type JuristicAddressVarianceFields = {
  __typename?: 'juristic_address_variance_fields';
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "juristic_address" */
export type JuristicAddressVarianceOrderBy = {
  /** I-Industry API : (AddressInformations.Sequense) */
  sequence?: Maybe<OrderBy>;
};

/** aggregated selection of "juristic" */
export type JuristicAggregate = {
  __typename?: 'juristic_aggregate';
  aggregate?: Maybe<JuristicAggregateFields>;
  nodes: Array<Juristic>;
};

/** aggregate fields of "juristic" */
export type JuristicAggregateFields = {
  __typename?: 'juristic_aggregate_fields';
  avg?: Maybe<JuristicAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<JuristicMaxFields>;
  min?: Maybe<JuristicMinFields>;
  stddev?: Maybe<JuristicStddevFields>;
  stddev_pop?: Maybe<JuristicStddevPopFields>;
  stddev_samp?: Maybe<JuristicStddevSampFields>;
  sum?: Maybe<JuristicSumFields>;
  var_pop?: Maybe<JuristicVarPopFields>;
  var_samp?: Maybe<JuristicVarSampFields>;
  variance?: Maybe<JuristicVarianceFields>;
};


/** aggregate fields of "juristic" */
export type JuristicAggregateFieldsCountArgs = {
  columns?: Maybe<Array<JuristicSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type JuristicAvgFields = {
  __typename?: 'juristic_avg_fields';
  /** I-Industry API : (NumberOfCommittee) */
  number_of_commitee?: Maybe<Scalars['Float']>;
  /** จำนวนวัตถุประสงค์ */
  number_of_objective?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "juristic". All fields are combined with a logical 'AND'. */
export type JuristicBoolExp = {
  _and?: Maybe<Array<JuristicBoolExp>>;
  _not?: Maybe<JuristicBoolExp>;
  _or?: Maybe<Array<JuristicBoolExp>>;
  agreements?: Maybe<ContractAgreementBoolExp>;
  application_event_logs?: Maybe<LoanApplicationEventLogBoolExp>;
  business_group?: Maybe<StringComparisonExp>;
  business_type?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  imported?: Maybe<BooleanComparisonExp>;
  is_member?: Maybe<BooleanComparisonExp>;
  juristic_addresses?: Maybe<JuristicAddressBoolExp>;
  juristic_committees?: Maybe<JuristicCommitteeBoolExp>;
  juristic_standard_objectives?: Maybe<JuristicStandardObjectiveBoolExp>;
  juristic_status?: Maybe<StringComparisonExp>;
  name_en?: Maybe<StringComparisonExp>;
  name_th?: Maybe<StringComparisonExp>;
  number_of_commitee?: Maybe<NumericComparisonExp>;
  number_of_objective?: Maybe<NumericComparisonExp>;
  paid_register_capital?: Maybe<StringComparisonExp>;
  register_capital?: Maybe<StringComparisonExp>;
  register_date?: Maybe<DateComparisonExp>;
  type?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/**
 * กรรมการบริษัท
 *
 *
 * columns and relationships of "juristic_committee"
 */
export type JuristicCommittee = {
  __typename?: 'juristic_committee';
  /** เลขประจำตัวประชาชน */
  citizen_id?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "juristic_committee_full_name" */
  full_name?: Maybe<Scalars['String']>;
  /** md5 ระหว่าง first_name + last_name ติดกัน */
  hash_id: Scalars['String'];
  /** Primary key */
  id: Scalars['uuid'];
  /** An object relationship */
  juristic: Juristic;
  /** juristic.reference */
  juristic_id: Scalars['String'];
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  sequence: Scalars['numeric'];
  /** คำนำหน้า */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "juristic_committee" */
export type JuristicCommitteeAggregate = {
  __typename?: 'juristic_committee_aggregate';
  aggregate?: Maybe<JuristicCommitteeAggregateFields>;
  nodes: Array<JuristicCommittee>;
};

/** aggregate fields of "juristic_committee" */
export type JuristicCommitteeAggregateFields = {
  __typename?: 'juristic_committee_aggregate_fields';
  avg?: Maybe<JuristicCommitteeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<JuristicCommitteeMaxFields>;
  min?: Maybe<JuristicCommitteeMinFields>;
  stddev?: Maybe<JuristicCommitteeStddevFields>;
  stddev_pop?: Maybe<JuristicCommitteeStddevPopFields>;
  stddev_samp?: Maybe<JuristicCommitteeStddevSampFields>;
  sum?: Maybe<JuristicCommitteeSumFields>;
  var_pop?: Maybe<JuristicCommitteeVarPopFields>;
  var_samp?: Maybe<JuristicCommitteeVarSampFields>;
  variance?: Maybe<JuristicCommitteeVarianceFields>;
};


/** aggregate fields of "juristic_committee" */
export type JuristicCommitteeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<JuristicCommitteeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "juristic_committee" */
export type JuristicCommitteeAggregateOrderBy = {
  avg?: Maybe<JuristicCommitteeAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<JuristicCommitteeMaxOrderBy>;
  min?: Maybe<JuristicCommitteeMinOrderBy>;
  stddev?: Maybe<JuristicCommitteeStddevOrderBy>;
  stddev_pop?: Maybe<JuristicCommitteeStddevPopOrderBy>;
  stddev_samp?: Maybe<JuristicCommitteeStddevSampOrderBy>;
  sum?: Maybe<JuristicCommitteeSumOrderBy>;
  var_pop?: Maybe<JuristicCommitteeVarPopOrderBy>;
  var_samp?: Maybe<JuristicCommitteeVarSampOrderBy>;
  variance?: Maybe<JuristicCommitteeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "juristic_committee" */
export type JuristicCommitteeArrRelInsertInput = {
  data: Array<JuristicCommitteeInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<JuristicCommitteeOnConflict>;
};

/** aggregate avg on columns */
export type JuristicCommitteeAvgFields = {
  __typename?: 'juristic_committee_avg_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "juristic_committee" */
export type JuristicCommitteeAvgOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "juristic_committee". All fields are combined with a logical 'AND'. */
export type JuristicCommitteeBoolExp = {
  _and?: Maybe<Array<JuristicCommitteeBoolExp>>;
  _not?: Maybe<JuristicCommitteeBoolExp>;
  _or?: Maybe<Array<JuristicCommitteeBoolExp>>;
  citizen_id?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  first_name?: Maybe<StringComparisonExp>;
  full_name?: Maybe<StringComparisonExp>;
  hash_id?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  juristic?: Maybe<JuristicBoolExp>;
  juristic_id?: Maybe<StringComparisonExp>;
  last_name?: Maybe<StringComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
  title?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "juristic_committee" */
export enum JuristicCommitteeConstraint {
  /** unique or primary key constraint */
  JuristicCommitteeJuristicIdHashIdKey = 'juristic_committee_juristic_id_hash_id_key',
  /** unique or primary key constraint */
  JuristicCommitteePkey = 'juristic_committee_pkey'
}

/** input type for incrementing numeric columns in table "juristic_committee" */
export type JuristicCommitteeIncInput = {
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "juristic_committee" */
export type JuristicCommitteeInsertInput = {
  /** เลขประจำตัวประชาชน */
  citizen_id?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** md5 ระหว่าง first_name + last_name ติดกัน */
  hash_id?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  juristic?: Maybe<JuristicObjRelInsertInput>;
  /** juristic.reference */
  juristic_id?: Maybe<Scalars['String']>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** คำนำหน้า */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type JuristicCommitteeMaxFields = {
  __typename?: 'juristic_committee_max_fields';
  /** เลขประจำตัวประชาชน */
  citizen_id?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** md5 ระหว่าง first_name + last_name ติดกัน */
  hash_id?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** juristic.reference */
  juristic_id?: Maybe<Scalars['String']>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** คำนำหน้า */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "juristic_committee" */
export type JuristicCommitteeMaxOrderBy = {
  /** เลขประจำตัวประชาชน */
  citizen_id?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<OrderBy>;
  /** ชื่อ */
  first_name?: Maybe<OrderBy>;
  /** md5 ระหว่าง first_name + last_name ติดกัน */
  hash_id?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** juristic.reference */
  juristic_id?: Maybe<OrderBy>;
  /** นามสกุล */
  last_name?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** คำนำหน้า */
  title?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type JuristicCommitteeMinFields = {
  __typename?: 'juristic_committee_min_fields';
  /** เลขประจำตัวประชาชน */
  citizen_id?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** md5 ระหว่าง first_name + last_name ติดกัน */
  hash_id?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** juristic.reference */
  juristic_id?: Maybe<Scalars['String']>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** คำนำหน้า */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "juristic_committee" */
export type JuristicCommitteeMinOrderBy = {
  /** เลขประจำตัวประชาชน */
  citizen_id?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<OrderBy>;
  /** ชื่อ */
  first_name?: Maybe<OrderBy>;
  /** md5 ระหว่าง first_name + last_name ติดกัน */
  hash_id?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** juristic.reference */
  juristic_id?: Maybe<OrderBy>;
  /** นามสกุล */
  last_name?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** คำนำหน้า */
  title?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "juristic_committee" */
export type JuristicCommitteeMutationResponse = {
  __typename?: 'juristic_committee_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<JuristicCommittee>;
};

/** on conflict condition type for table "juristic_committee" */
export type JuristicCommitteeOnConflict = {
  constraint: JuristicCommitteeConstraint;
  update_columns?: Array<JuristicCommitteeUpdateColumn>;
  where?: Maybe<JuristicCommitteeBoolExp>;
};

/** Ordering options when selecting data from "juristic_committee". */
export type JuristicCommitteeOrderBy = {
  citizen_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  first_name?: Maybe<OrderBy>;
  full_name?: Maybe<OrderBy>;
  hash_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  juristic?: Maybe<JuristicOrderBy>;
  juristic_id?: Maybe<OrderBy>;
  last_name?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: juristic_committee */
export type JuristicCommitteePkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "juristic_committee" */
export enum JuristicCommitteeSelectColumn {
  /** column name */
  CitizenId = 'citizen_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  HashId = 'hash_id',
  /** column name */
  Id = 'id',
  /** column name */
  JuristicId = 'juristic_id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "juristic_committee" */
export type JuristicCommitteeSetInput = {
  /** เลขประจำตัวประชาชน */
  citizen_id?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** md5 ระหว่าง first_name + last_name ติดกัน */
  hash_id?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** juristic.reference */
  juristic_id?: Maybe<Scalars['String']>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** คำนำหน้า */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type JuristicCommitteeStddevFields = {
  __typename?: 'juristic_committee_stddev_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "juristic_committee" */
export type JuristicCommitteeStddevOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type JuristicCommitteeStddevPopFields = {
  __typename?: 'juristic_committee_stddev_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "juristic_committee" */
export type JuristicCommitteeStddevPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type JuristicCommitteeStddevSampFields = {
  __typename?: 'juristic_committee_stddev_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "juristic_committee" */
export type JuristicCommitteeStddevSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type JuristicCommitteeSumFields = {
  __typename?: 'juristic_committee_sum_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "juristic_committee" */
export type JuristicCommitteeSumOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "juristic_committee" */
export enum JuristicCommitteeUpdateColumn {
  /** column name */
  CitizenId = 'citizen_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  HashId = 'hash_id',
  /** column name */
  Id = 'id',
  /** column name */
  JuristicId = 'juristic_id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type JuristicCommitteeVarPopFields = {
  __typename?: 'juristic_committee_var_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "juristic_committee" */
export type JuristicCommitteeVarPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type JuristicCommitteeVarSampFields = {
  __typename?: 'juristic_committee_var_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "juristic_committee" */
export type JuristicCommitteeVarSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type JuristicCommitteeVarianceFields = {
  __typename?: 'juristic_committee_variance_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "juristic_committee" */
export type JuristicCommitteeVarianceOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** unique or primary key constraints on table "juristic" */
export enum JuristicConstraint {
  /** unique or primary key constraint */
  JuristicPkey = 'juristic_pkey'
}

/** input type for incrementing numeric columns in table "juristic" */
export type JuristicIncInput = {
  /** I-Industry API : (NumberOfCommittee) */
  number_of_commitee?: Maybe<Scalars['numeric']>;
  /** จำนวนวัตถุประสงค์ */
  number_of_objective?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "juristic" */
export type JuristicInsertInput = {
  agreements?: Maybe<ContractAgreementArrRelInsertInput>;
  application_event_logs?: Maybe<LoanApplicationEventLogArrRelInsertInput>;
  /** กลุ่มธุรกิจ */
  business_group?: Maybe<Scalars['String']>;
  /** ประเภทธุรกิจ */
  business_type?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** เลขนิติบุคคล I-Industry API : (AddressInformations.JuristicID) */
  id?: Maybe<Scalars['String']>;
  /** สถานะ true = นำเข้าแล้ว, false = ยังไม่ได้นำเข้า */
  imported?: Maybe<Scalars['Boolean']>;
  /** เคยขอสินเชื่อหรือยัง */
  is_member?: Maybe<Scalars['Boolean']>;
  juristic_addresses?: Maybe<JuristicAddressArrRelInsertInput>;
  juristic_committees?: Maybe<JuristicCommitteeArrRelInsertInput>;
  juristic_standard_objectives?: Maybe<JuristicStandardObjectiveArrRelInsertInput>;
  /** I-Industry API : (JuristicStatus) */
  juristic_status?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.JuristicName_EN) */
  name_en?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.JuristicName_TH) */
  name_th?: Maybe<Scalars['String']>;
  /** I-Industry API : (NumberOfCommittee) */
  number_of_commitee?: Maybe<Scalars['numeric']>;
  /** จำนวนวัตถุประสงค์ */
  number_of_objective?: Maybe<Scalars['numeric']>;
  /** I-Industry API : (AddressInformations.PaidRegisterCapital) */
  paid_register_capital?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.RegisterCapital) */
  register_capital?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.RegisterDate) */
  register_date?: Maybe<Scalars['date']>;
  /** ประเภทบริษัท I-Industry API : (AddressInformations.JuristicType) */
  type?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type JuristicMaxFields = {
  __typename?: 'juristic_max_fields';
  /** กลุ่มธุรกิจ */
  business_group?: Maybe<Scalars['String']>;
  /** ประเภทธุรกิจ */
  business_type?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** เลขนิติบุคคล I-Industry API : (AddressInformations.JuristicID) */
  id?: Maybe<Scalars['String']>;
  /** I-Industry API : (JuristicStatus) */
  juristic_status?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.JuristicName_EN) */
  name_en?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.JuristicName_TH) */
  name_th?: Maybe<Scalars['String']>;
  /** I-Industry API : (NumberOfCommittee) */
  number_of_commitee?: Maybe<Scalars['numeric']>;
  /** จำนวนวัตถุประสงค์ */
  number_of_objective?: Maybe<Scalars['numeric']>;
  /** I-Industry API : (AddressInformations.PaidRegisterCapital) */
  paid_register_capital?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.RegisterCapital) */
  register_capital?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.RegisterDate) */
  register_date?: Maybe<Scalars['date']>;
  /** ประเภทบริษัท I-Industry API : (AddressInformations.JuristicType) */
  type?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type JuristicMinFields = {
  __typename?: 'juristic_min_fields';
  /** กลุ่มธุรกิจ */
  business_group?: Maybe<Scalars['String']>;
  /** ประเภทธุรกิจ */
  business_type?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** เลขนิติบุคคล I-Industry API : (AddressInformations.JuristicID) */
  id?: Maybe<Scalars['String']>;
  /** I-Industry API : (JuristicStatus) */
  juristic_status?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.JuristicName_EN) */
  name_en?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.JuristicName_TH) */
  name_th?: Maybe<Scalars['String']>;
  /** I-Industry API : (NumberOfCommittee) */
  number_of_commitee?: Maybe<Scalars['numeric']>;
  /** จำนวนวัตถุประสงค์ */
  number_of_objective?: Maybe<Scalars['numeric']>;
  /** I-Industry API : (AddressInformations.PaidRegisterCapital) */
  paid_register_capital?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.RegisterCapital) */
  register_capital?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.RegisterDate) */
  register_date?: Maybe<Scalars['date']>;
  /** ประเภทบริษัท I-Industry API : (AddressInformations.JuristicType) */
  type?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "juristic" */
export type JuristicMutationResponse = {
  __typename?: 'juristic_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Juristic>;
};

/** input type for inserting object relation for remote table "juristic" */
export type JuristicObjRelInsertInput = {
  data: JuristicInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<JuristicOnConflict>;
};

/** on conflict condition type for table "juristic" */
export type JuristicOnConflict = {
  constraint: JuristicConstraint;
  update_columns?: Array<JuristicUpdateColumn>;
  where?: Maybe<JuristicBoolExp>;
};

/** Ordering options when selecting data from "juristic". */
export type JuristicOrderBy = {
  agreements_aggregate?: Maybe<ContractAgreementAggregateOrderBy>;
  application_event_logs_aggregate?: Maybe<LoanApplicationEventLogAggregateOrderBy>;
  business_group?: Maybe<OrderBy>;
  business_type?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  imported?: Maybe<OrderBy>;
  is_member?: Maybe<OrderBy>;
  juristic_addresses_aggregate?: Maybe<JuristicAddressAggregateOrderBy>;
  juristic_committees_aggregate?: Maybe<JuristicCommitteeAggregateOrderBy>;
  juristic_standard_objectives_aggregate?: Maybe<JuristicStandardObjectiveAggregateOrderBy>;
  juristic_status?: Maybe<OrderBy>;
  name_en?: Maybe<OrderBy>;
  name_th?: Maybe<OrderBy>;
  number_of_commitee?: Maybe<OrderBy>;
  number_of_objective?: Maybe<OrderBy>;
  paid_register_capital?: Maybe<OrderBy>;
  register_capital?: Maybe<OrderBy>;
  register_date?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: juristic */
export type JuristicPkColumnsInput = {
  /** เลขนิติบุคคล I-Industry API : (AddressInformations.JuristicID) */
  id: Scalars['String'];
};

/** select columns of table "juristic" */
export enum JuristicSelectColumn {
  /** column name */
  BusinessGroup = 'business_group',
  /** column name */
  BusinessType = 'business_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Imported = 'imported',
  /** column name */
  IsMember = 'is_member',
  /** column name */
  JuristicStatus = 'juristic_status',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NameTh = 'name_th',
  /** column name */
  NumberOfCommitee = 'number_of_commitee',
  /** column name */
  NumberOfObjective = 'number_of_objective',
  /** column name */
  PaidRegisterCapital = 'paid_register_capital',
  /** column name */
  RegisterCapital = 'register_capital',
  /** column name */
  RegisterDate = 'register_date',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "juristic" */
export type JuristicSetInput = {
  /** กลุ่มธุรกิจ */
  business_group?: Maybe<Scalars['String']>;
  /** ประเภทธุรกิจ */
  business_type?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** เลขนิติบุคคล I-Industry API : (AddressInformations.JuristicID) */
  id?: Maybe<Scalars['String']>;
  /** สถานะ true = นำเข้าแล้ว, false = ยังไม่ได้นำเข้า */
  imported?: Maybe<Scalars['Boolean']>;
  /** เคยขอสินเชื่อหรือยัง */
  is_member?: Maybe<Scalars['Boolean']>;
  /** I-Industry API : (JuristicStatus) */
  juristic_status?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.JuristicName_EN) */
  name_en?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.JuristicName_TH) */
  name_th?: Maybe<Scalars['String']>;
  /** I-Industry API : (NumberOfCommittee) */
  number_of_commitee?: Maybe<Scalars['numeric']>;
  /** จำนวนวัตถุประสงค์ */
  number_of_objective?: Maybe<Scalars['numeric']>;
  /** I-Industry API : (AddressInformations.PaidRegisterCapital) */
  paid_register_capital?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.RegisterCapital) */
  register_capital?: Maybe<Scalars['String']>;
  /** I-Industry API : (AddressInformations.RegisterDate) */
  register_date?: Maybe<Scalars['date']>;
  /** ประเภทบริษัท I-Industry API : (AddressInformations.JuristicType) */
  type?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/**
 * วัตถุประสงค์นิติบุคคล
 *
 *
 * columns and relationships of "juristic_standard_objective"
 */
export type JuristicStandardObjective = {
  __typename?: 'juristic_standard_objective';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** An object relationship */
  juristic: Juristic;
  /** juristic.reference */
  juristic_id: Scalars['bpchar'];
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** รหัสอ้างอิง */
  standard_id: Scalars['String'];
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "juristic_standard_objective" */
export type JuristicStandardObjectiveAggregate = {
  __typename?: 'juristic_standard_objective_aggregate';
  aggregate?: Maybe<JuristicStandardObjectiveAggregateFields>;
  nodes: Array<JuristicStandardObjective>;
};

/** aggregate fields of "juristic_standard_objective" */
export type JuristicStandardObjectiveAggregateFields = {
  __typename?: 'juristic_standard_objective_aggregate_fields';
  avg?: Maybe<JuristicStandardObjectiveAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<JuristicStandardObjectiveMaxFields>;
  min?: Maybe<JuristicStandardObjectiveMinFields>;
  stddev?: Maybe<JuristicStandardObjectiveStddevFields>;
  stddev_pop?: Maybe<JuristicStandardObjectiveStddevPopFields>;
  stddev_samp?: Maybe<JuristicStandardObjectiveStddevSampFields>;
  sum?: Maybe<JuristicStandardObjectiveSumFields>;
  var_pop?: Maybe<JuristicStandardObjectiveVarPopFields>;
  var_samp?: Maybe<JuristicStandardObjectiveVarSampFields>;
  variance?: Maybe<JuristicStandardObjectiveVarianceFields>;
};


/** aggregate fields of "juristic_standard_objective" */
export type JuristicStandardObjectiveAggregateFieldsCountArgs = {
  columns?: Maybe<Array<JuristicStandardObjectiveSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "juristic_standard_objective" */
export type JuristicStandardObjectiveAggregateOrderBy = {
  avg?: Maybe<JuristicStandardObjectiveAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<JuristicStandardObjectiveMaxOrderBy>;
  min?: Maybe<JuristicStandardObjectiveMinOrderBy>;
  stddev?: Maybe<JuristicStandardObjectiveStddevOrderBy>;
  stddev_pop?: Maybe<JuristicStandardObjectiveStddevPopOrderBy>;
  stddev_samp?: Maybe<JuristicStandardObjectiveStddevSampOrderBy>;
  sum?: Maybe<JuristicStandardObjectiveSumOrderBy>;
  var_pop?: Maybe<JuristicStandardObjectiveVarPopOrderBy>;
  var_samp?: Maybe<JuristicStandardObjectiveVarSampOrderBy>;
  variance?: Maybe<JuristicStandardObjectiveVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "juristic_standard_objective" */
export type JuristicStandardObjectiveArrRelInsertInput = {
  data: Array<JuristicStandardObjectiveInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<JuristicStandardObjectiveOnConflict>;
};

/** aggregate avg on columns */
export type JuristicStandardObjectiveAvgFields = {
  __typename?: 'juristic_standard_objective_avg_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "juristic_standard_objective" */
export type JuristicStandardObjectiveAvgOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "juristic_standard_objective". All fields are combined with a logical 'AND'. */
export type JuristicStandardObjectiveBoolExp = {
  _and?: Maybe<Array<JuristicStandardObjectiveBoolExp>>;
  _not?: Maybe<JuristicStandardObjectiveBoolExp>;
  _or?: Maybe<Array<JuristicStandardObjectiveBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  juristic?: Maybe<JuristicBoolExp>;
  juristic_id?: Maybe<BpcharComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
  standard_id?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "juristic_standard_objective" */
export enum JuristicStandardObjectiveConstraint {
  /** unique or primary key constraint */
  JuristicStandardObjectiveJuristicIdStandardIdKey = 'juristic_standard_objective_juristic_id_standard_id_key',
  /** unique or primary key constraint */
  JuristicStandardObjectivePkey = 'juristic_standard_objective_pkey'
}

/** input type for incrementing numeric columns in table "juristic_standard_objective" */
export type JuristicStandardObjectiveIncInput = {
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "juristic_standard_objective" */
export type JuristicStandardObjectiveInsertInput = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  juristic?: Maybe<JuristicObjRelInsertInput>;
  /** juristic.reference */
  juristic_id?: Maybe<Scalars['bpchar']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** รหัสอ้างอิง */
  standard_id?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type JuristicStandardObjectiveMaxFields = {
  __typename?: 'juristic_standard_objective_max_fields';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** juristic.reference */
  juristic_id?: Maybe<Scalars['bpchar']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** รหัสอ้างอิง */
  standard_id?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "juristic_standard_objective" */
export type JuristicStandardObjectiveMaxOrderBy = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<OrderBy>;
  /** คำอธิบาย */
  description?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** juristic.reference */
  juristic_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** รหัสอ้างอิง */
  standard_id?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type JuristicStandardObjectiveMinFields = {
  __typename?: 'juristic_standard_objective_min_fields';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** juristic.reference */
  juristic_id?: Maybe<Scalars['bpchar']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** รหัสอ้างอิง */
  standard_id?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "juristic_standard_objective" */
export type JuristicStandardObjectiveMinOrderBy = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<OrderBy>;
  /** คำอธิบาย */
  description?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** juristic.reference */
  juristic_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** รหัสอ้างอิง */
  standard_id?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "juristic_standard_objective" */
export type JuristicStandardObjectiveMutationResponse = {
  __typename?: 'juristic_standard_objective_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<JuristicStandardObjective>;
};

/** on conflict condition type for table "juristic_standard_objective" */
export type JuristicStandardObjectiveOnConflict = {
  constraint: JuristicStandardObjectiveConstraint;
  update_columns?: Array<JuristicStandardObjectiveUpdateColumn>;
  where?: Maybe<JuristicStandardObjectiveBoolExp>;
};

/** Ordering options when selecting data from "juristic_standard_objective". */
export type JuristicStandardObjectiveOrderBy = {
  created_at?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  juristic?: Maybe<JuristicOrderBy>;
  juristic_id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
  standard_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: juristic_standard_objective */
export type JuristicStandardObjectivePkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "juristic_standard_objective" */
export enum JuristicStandardObjectiveSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  JuristicId = 'juristic_id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  StandardId = 'standard_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "juristic_standard_objective" */
export type JuristicStandardObjectiveSetInput = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** juristic.reference */
  juristic_id?: Maybe<Scalars['bpchar']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** รหัสอ้างอิง */
  standard_id?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type JuristicStandardObjectiveStddevFields = {
  __typename?: 'juristic_standard_objective_stddev_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "juristic_standard_objective" */
export type JuristicStandardObjectiveStddevOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type JuristicStandardObjectiveStddevPopFields = {
  __typename?: 'juristic_standard_objective_stddev_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "juristic_standard_objective" */
export type JuristicStandardObjectiveStddevPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type JuristicStandardObjectiveStddevSampFields = {
  __typename?: 'juristic_standard_objective_stddev_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "juristic_standard_objective" */
export type JuristicStandardObjectiveStddevSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type JuristicStandardObjectiveSumFields = {
  __typename?: 'juristic_standard_objective_sum_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "juristic_standard_objective" */
export type JuristicStandardObjectiveSumOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "juristic_standard_objective" */
export enum JuristicStandardObjectiveUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  JuristicId = 'juristic_id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  StandardId = 'standard_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type JuristicStandardObjectiveVarPopFields = {
  __typename?: 'juristic_standard_objective_var_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "juristic_standard_objective" */
export type JuristicStandardObjectiveVarPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type JuristicStandardObjectiveVarSampFields = {
  __typename?: 'juristic_standard_objective_var_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "juristic_standard_objective" */
export type JuristicStandardObjectiveVarSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type JuristicStandardObjectiveVarianceFields = {
  __typename?: 'juristic_standard_objective_variance_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "juristic_standard_objective" */
export type JuristicStandardObjectiveVarianceOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev on columns */
export type JuristicStddevFields = {
  __typename?: 'juristic_stddev_fields';
  /** I-Industry API : (NumberOfCommittee) */
  number_of_commitee?: Maybe<Scalars['Float']>;
  /** จำนวนวัตถุประสงค์ */
  number_of_objective?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type JuristicStddevPopFields = {
  __typename?: 'juristic_stddev_pop_fields';
  /** I-Industry API : (NumberOfCommittee) */
  number_of_commitee?: Maybe<Scalars['Float']>;
  /** จำนวนวัตถุประสงค์ */
  number_of_objective?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type JuristicStddevSampFields = {
  __typename?: 'juristic_stddev_samp_fields';
  /** I-Industry API : (NumberOfCommittee) */
  number_of_commitee?: Maybe<Scalars['Float']>;
  /** จำนวนวัตถุประสงค์ */
  number_of_objective?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type JuristicSumFields = {
  __typename?: 'juristic_sum_fields';
  /** I-Industry API : (NumberOfCommittee) */
  number_of_commitee?: Maybe<Scalars['numeric']>;
  /** จำนวนวัตถุประสงค์ */
  number_of_objective?: Maybe<Scalars['numeric']>;
};

/** update columns of table "juristic" */
export enum JuristicUpdateColumn {
  /** column name */
  BusinessGroup = 'business_group',
  /** column name */
  BusinessType = 'business_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Imported = 'imported',
  /** column name */
  IsMember = 'is_member',
  /** column name */
  JuristicStatus = 'juristic_status',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NameTh = 'name_th',
  /** column name */
  NumberOfCommitee = 'number_of_commitee',
  /** column name */
  NumberOfObjective = 'number_of_objective',
  /** column name */
  PaidRegisterCapital = 'paid_register_capital',
  /** column name */
  RegisterCapital = 'register_capital',
  /** column name */
  RegisterDate = 'register_date',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type JuristicVarPopFields = {
  __typename?: 'juristic_var_pop_fields';
  /** I-Industry API : (NumberOfCommittee) */
  number_of_commitee?: Maybe<Scalars['Float']>;
  /** จำนวนวัตถุประสงค์ */
  number_of_objective?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type JuristicVarSampFields = {
  __typename?: 'juristic_var_samp_fields';
  /** I-Industry API : (NumberOfCommittee) */
  number_of_commitee?: Maybe<Scalars['Float']>;
  /** จำนวนวัตถุประสงค์ */
  number_of_objective?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type JuristicVarianceFields = {
  __typename?: 'juristic_variance_fields';
  /** I-Industry API : (NumberOfCommittee) */
  number_of_commitee?: Maybe<Scalars['Float']>;
  /** จำนวนวัตถุประสงค์ */
  number_of_objective?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ledger.account_budget_balance" */
export type LedgerAccountBudgetBalance = {
  __typename?: 'ledger_account_budget_balance';
  account_id?: Maybe<Scalars['uuid']>;
  balance?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "ledger.account_budget_balance" */
export type LedgerAccountBudgetBalanceAggregate = {
  __typename?: 'ledger_account_budget_balance_aggregate';
  aggregate?: Maybe<LedgerAccountBudgetBalanceAggregateFields>;
  nodes: Array<LedgerAccountBudgetBalance>;
};

/** aggregate fields of "ledger.account_budget_balance" */
export type LedgerAccountBudgetBalanceAggregateFields = {
  __typename?: 'ledger_account_budget_balance_aggregate_fields';
  avg?: Maybe<LedgerAccountBudgetBalanceAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LedgerAccountBudgetBalanceMaxFields>;
  min?: Maybe<LedgerAccountBudgetBalanceMinFields>;
  stddev?: Maybe<LedgerAccountBudgetBalanceStddevFields>;
  stddev_pop?: Maybe<LedgerAccountBudgetBalanceStddevPopFields>;
  stddev_samp?: Maybe<LedgerAccountBudgetBalanceStddevSampFields>;
  sum?: Maybe<LedgerAccountBudgetBalanceSumFields>;
  var_pop?: Maybe<LedgerAccountBudgetBalanceVarPopFields>;
  var_samp?: Maybe<LedgerAccountBudgetBalanceVarSampFields>;
  variance?: Maybe<LedgerAccountBudgetBalanceVarianceFields>;
};


/** aggregate fields of "ledger.account_budget_balance" */
export type LedgerAccountBudgetBalanceAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LedgerAccountBudgetBalanceSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LedgerAccountBudgetBalanceAvgFields = {
  __typename?: 'ledger_account_budget_balance_avg_fields';
  balance?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ledger.account_budget_balance". All fields are combined with a logical 'AND'. */
export type LedgerAccountBudgetBalanceBoolExp = {
  _and?: Maybe<Array<LedgerAccountBudgetBalanceBoolExp>>;
  _not?: Maybe<LedgerAccountBudgetBalanceBoolExp>;
  _or?: Maybe<Array<LedgerAccountBudgetBalanceBoolExp>>;
  account_id?: Maybe<UuidComparisonExp>;
  balance?: Maybe<NumericComparisonExp>;
  budget_id?: Maybe<StringComparisonExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
  organization_id?: Maybe<UuidComparisonExp>;
};

/** columns and relationships of "ledger.account_budget_balance_debt" */
export type LedgerAccountBudgetBalanceDebt = {
  __typename?: 'ledger_account_budget_balance_debt';
  account_id?: Maybe<Scalars['uuid']>;
  balance?: Maybe<Scalars['numeric']>;
  budget_balance?: Maybe<Scalars['numeric']>;
  budget_group_id?: Maybe<Scalars['String']>;
  budget_group_name?: Maybe<Scalars['String']>;
  budget_id?: Maybe<Scalars['String']>;
  budget_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "ledger.account_budget_balance_debt" */
export type LedgerAccountBudgetBalanceDebtAggregate = {
  __typename?: 'ledger_account_budget_balance_debt_aggregate';
  aggregate?: Maybe<LedgerAccountBudgetBalanceDebtAggregateFields>;
  nodes: Array<LedgerAccountBudgetBalanceDebt>;
};

/** aggregate fields of "ledger.account_budget_balance_debt" */
export type LedgerAccountBudgetBalanceDebtAggregateFields = {
  __typename?: 'ledger_account_budget_balance_debt_aggregate_fields';
  avg?: Maybe<LedgerAccountBudgetBalanceDebtAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LedgerAccountBudgetBalanceDebtMaxFields>;
  min?: Maybe<LedgerAccountBudgetBalanceDebtMinFields>;
  stddev?: Maybe<LedgerAccountBudgetBalanceDebtStddevFields>;
  stddev_pop?: Maybe<LedgerAccountBudgetBalanceDebtStddevPopFields>;
  stddev_samp?: Maybe<LedgerAccountBudgetBalanceDebtStddevSampFields>;
  sum?: Maybe<LedgerAccountBudgetBalanceDebtSumFields>;
  var_pop?: Maybe<LedgerAccountBudgetBalanceDebtVarPopFields>;
  var_samp?: Maybe<LedgerAccountBudgetBalanceDebtVarSampFields>;
  variance?: Maybe<LedgerAccountBudgetBalanceDebtVarianceFields>;
};


/** aggregate fields of "ledger.account_budget_balance_debt" */
export type LedgerAccountBudgetBalanceDebtAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LedgerAccountBudgetBalanceDebtSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ledger.account_budget_balance_debt" */
export type LedgerAccountBudgetBalanceDebtAggregateOrderBy = {
  avg?: Maybe<LedgerAccountBudgetBalanceDebtAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LedgerAccountBudgetBalanceDebtMaxOrderBy>;
  min?: Maybe<LedgerAccountBudgetBalanceDebtMinOrderBy>;
  stddev?: Maybe<LedgerAccountBudgetBalanceDebtStddevOrderBy>;
  stddev_pop?: Maybe<LedgerAccountBudgetBalanceDebtStddevPopOrderBy>;
  stddev_samp?: Maybe<LedgerAccountBudgetBalanceDebtStddevSampOrderBy>;
  sum?: Maybe<LedgerAccountBudgetBalanceDebtSumOrderBy>;
  var_pop?: Maybe<LedgerAccountBudgetBalanceDebtVarPopOrderBy>;
  var_samp?: Maybe<LedgerAccountBudgetBalanceDebtVarSampOrderBy>;
  variance?: Maybe<LedgerAccountBudgetBalanceDebtVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "ledger.account_budget_balance_debt" */
export type LedgerAccountBudgetBalanceDebtArrRelInsertInput = {
  data: Array<LedgerAccountBudgetBalanceDebtInsertInput>;
};

/** aggregate avg on columns */
export type LedgerAccountBudgetBalanceDebtAvgFields = {
  __typename?: 'ledger_account_budget_balance_debt_avg_fields';
  balance?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  budget_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ledger.account_budget_balance_debt" */
export type LedgerAccountBudgetBalanceDebtAvgOrderBy = {
  balance?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "ledger.account_budget_balance_debt". All fields are combined with a logical 'AND'. */
export type LedgerAccountBudgetBalanceDebtBoolExp = {
  _and?: Maybe<Array<LedgerAccountBudgetBalanceDebtBoolExp>>;
  _not?: Maybe<LedgerAccountBudgetBalanceDebtBoolExp>;
  _or?: Maybe<Array<LedgerAccountBudgetBalanceDebtBoolExp>>;
  account_id?: Maybe<UuidComparisonExp>;
  balance?: Maybe<NumericComparisonExp>;
  budget_balance?: Maybe<NumericComparisonExp>;
  budget_group_id?: Maybe<StringComparisonExp>;
  budget_group_name?: Maybe<StringComparisonExp>;
  budget_id?: Maybe<StringComparisonExp>;
  budget_total?: Maybe<NumericComparisonExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
  organization_id?: Maybe<UuidComparisonExp>;
};

/** input type for inserting data into table "ledger.account_budget_balance_debt" */
export type LedgerAccountBudgetBalanceDebtInsertInput = {
  account_id?: Maybe<Scalars['uuid']>;
  balance?: Maybe<Scalars['numeric']>;
  budget_balance?: Maybe<Scalars['numeric']>;
  budget_group_id?: Maybe<Scalars['String']>;
  budget_group_name?: Maybe<Scalars['String']>;
  budget_id?: Maybe<Scalars['String']>;
  budget_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LedgerAccountBudgetBalanceDebtMaxFields = {
  __typename?: 'ledger_account_budget_balance_debt_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  balance?: Maybe<Scalars['numeric']>;
  budget_balance?: Maybe<Scalars['numeric']>;
  budget_group_id?: Maybe<Scalars['String']>;
  budget_group_name?: Maybe<Scalars['String']>;
  budget_id?: Maybe<Scalars['String']>;
  budget_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "ledger.account_budget_balance_debt" */
export type LedgerAccountBudgetBalanceDebtMaxOrderBy = {
  account_id?: Maybe<OrderBy>;
  balance?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_group_id?: Maybe<OrderBy>;
  budget_group_name?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LedgerAccountBudgetBalanceDebtMinFields = {
  __typename?: 'ledger_account_budget_balance_debt_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  balance?: Maybe<Scalars['numeric']>;
  budget_balance?: Maybe<Scalars['numeric']>;
  budget_group_id?: Maybe<Scalars['String']>;
  budget_group_name?: Maybe<Scalars['String']>;
  budget_id?: Maybe<Scalars['String']>;
  budget_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "ledger.account_budget_balance_debt" */
export type LedgerAccountBudgetBalanceDebtMinOrderBy = {
  account_id?: Maybe<OrderBy>;
  balance?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_group_id?: Maybe<OrderBy>;
  budget_group_name?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
};

/** Ordering options when selecting data from "ledger.account_budget_balance_debt". */
export type LedgerAccountBudgetBalanceDebtOrderBy = {
  account_id?: Maybe<OrderBy>;
  balance?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_group_id?: Maybe<OrderBy>;
  budget_group_name?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
};

/** select columns of table "ledger.account_budget_balance_debt" */
export enum LedgerAccountBudgetBalanceDebtSelectColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Balance = 'balance',
  /** column name */
  BudgetBalance = 'budget_balance',
  /** column name */
  BudgetGroupId = 'budget_group_id',
  /** column name */
  BudgetGroupName = 'budget_group_name',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  BudgetTotal = 'budget_total',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  OrganizationId = 'organization_id'
}

/** aggregate stddev on columns */
export type LedgerAccountBudgetBalanceDebtStddevFields = {
  __typename?: 'ledger_account_budget_balance_debt_stddev_fields';
  balance?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  budget_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ledger.account_budget_balance_debt" */
export type LedgerAccountBudgetBalanceDebtStddevOrderBy = {
  balance?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LedgerAccountBudgetBalanceDebtStddevPopFields = {
  __typename?: 'ledger_account_budget_balance_debt_stddev_pop_fields';
  balance?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  budget_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ledger.account_budget_balance_debt" */
export type LedgerAccountBudgetBalanceDebtStddevPopOrderBy = {
  balance?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LedgerAccountBudgetBalanceDebtStddevSampFields = {
  __typename?: 'ledger_account_budget_balance_debt_stddev_samp_fields';
  balance?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  budget_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ledger.account_budget_balance_debt" */
export type LedgerAccountBudgetBalanceDebtStddevSampOrderBy = {
  balance?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LedgerAccountBudgetBalanceDebtSumFields = {
  __typename?: 'ledger_account_budget_balance_debt_sum_fields';
  balance?: Maybe<Scalars['numeric']>;
  budget_balance?: Maybe<Scalars['numeric']>;
  budget_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ledger.account_budget_balance_debt" */
export type LedgerAccountBudgetBalanceDebtSumOrderBy = {
  balance?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type LedgerAccountBudgetBalanceDebtVarPopFields = {
  __typename?: 'ledger_account_budget_balance_debt_var_pop_fields';
  balance?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  budget_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ledger.account_budget_balance_debt" */
export type LedgerAccountBudgetBalanceDebtVarPopOrderBy = {
  balance?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LedgerAccountBudgetBalanceDebtVarSampFields = {
  __typename?: 'ledger_account_budget_balance_debt_var_samp_fields';
  balance?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  budget_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ledger.account_budget_balance_debt" */
export type LedgerAccountBudgetBalanceDebtVarSampOrderBy = {
  balance?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LedgerAccountBudgetBalanceDebtVarianceFields = {
  __typename?: 'ledger_account_budget_balance_debt_variance_fields';
  balance?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  budget_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ledger.account_budget_balance_debt" */
export type LedgerAccountBudgetBalanceDebtVarianceOrderBy = {
  balance?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** columns and relationships of "ledger.account_budget_balance_expense" */
export type LedgerAccountBudgetBalanceExpense = {
  __typename?: 'ledger_account_budget_balance_expense';
  account_id?: Maybe<Scalars['uuid']>;
  balance?: Maybe<Scalars['numeric']>;
  budget_balance?: Maybe<Scalars['numeric']>;
  budget_group_id?: Maybe<Scalars['String']>;
  budget_group_name?: Maybe<Scalars['String']>;
  budget_id?: Maybe<Scalars['String']>;
  budget_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "ledger.account_budget_balance_expense" */
export type LedgerAccountBudgetBalanceExpenseAggregate = {
  __typename?: 'ledger_account_budget_balance_expense_aggregate';
  aggregate?: Maybe<LedgerAccountBudgetBalanceExpenseAggregateFields>;
  nodes: Array<LedgerAccountBudgetBalanceExpense>;
};

/** aggregate fields of "ledger.account_budget_balance_expense" */
export type LedgerAccountBudgetBalanceExpenseAggregateFields = {
  __typename?: 'ledger_account_budget_balance_expense_aggregate_fields';
  avg?: Maybe<LedgerAccountBudgetBalanceExpenseAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LedgerAccountBudgetBalanceExpenseMaxFields>;
  min?: Maybe<LedgerAccountBudgetBalanceExpenseMinFields>;
  stddev?: Maybe<LedgerAccountBudgetBalanceExpenseStddevFields>;
  stddev_pop?: Maybe<LedgerAccountBudgetBalanceExpenseStddevPopFields>;
  stddev_samp?: Maybe<LedgerAccountBudgetBalanceExpenseStddevSampFields>;
  sum?: Maybe<LedgerAccountBudgetBalanceExpenseSumFields>;
  var_pop?: Maybe<LedgerAccountBudgetBalanceExpenseVarPopFields>;
  var_samp?: Maybe<LedgerAccountBudgetBalanceExpenseVarSampFields>;
  variance?: Maybe<LedgerAccountBudgetBalanceExpenseVarianceFields>;
};


/** aggregate fields of "ledger.account_budget_balance_expense" */
export type LedgerAccountBudgetBalanceExpenseAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LedgerAccountBudgetBalanceExpenseSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ledger.account_budget_balance_expense" */
export type LedgerAccountBudgetBalanceExpenseAggregateOrderBy = {
  avg?: Maybe<LedgerAccountBudgetBalanceExpenseAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LedgerAccountBudgetBalanceExpenseMaxOrderBy>;
  min?: Maybe<LedgerAccountBudgetBalanceExpenseMinOrderBy>;
  stddev?: Maybe<LedgerAccountBudgetBalanceExpenseStddevOrderBy>;
  stddev_pop?: Maybe<LedgerAccountBudgetBalanceExpenseStddevPopOrderBy>;
  stddev_samp?: Maybe<LedgerAccountBudgetBalanceExpenseStddevSampOrderBy>;
  sum?: Maybe<LedgerAccountBudgetBalanceExpenseSumOrderBy>;
  var_pop?: Maybe<LedgerAccountBudgetBalanceExpenseVarPopOrderBy>;
  var_samp?: Maybe<LedgerAccountBudgetBalanceExpenseVarSampOrderBy>;
  variance?: Maybe<LedgerAccountBudgetBalanceExpenseVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "ledger.account_budget_balance_expense" */
export type LedgerAccountBudgetBalanceExpenseArrRelInsertInput = {
  data: Array<LedgerAccountBudgetBalanceExpenseInsertInput>;
};

/** aggregate avg on columns */
export type LedgerAccountBudgetBalanceExpenseAvgFields = {
  __typename?: 'ledger_account_budget_balance_expense_avg_fields';
  balance?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  budget_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ledger.account_budget_balance_expense" */
export type LedgerAccountBudgetBalanceExpenseAvgOrderBy = {
  balance?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "ledger.account_budget_balance_expense". All fields are combined with a logical 'AND'. */
export type LedgerAccountBudgetBalanceExpenseBoolExp = {
  _and?: Maybe<Array<LedgerAccountBudgetBalanceExpenseBoolExp>>;
  _not?: Maybe<LedgerAccountBudgetBalanceExpenseBoolExp>;
  _or?: Maybe<Array<LedgerAccountBudgetBalanceExpenseBoolExp>>;
  account_id?: Maybe<UuidComparisonExp>;
  balance?: Maybe<NumericComparisonExp>;
  budget_balance?: Maybe<NumericComparisonExp>;
  budget_group_id?: Maybe<StringComparisonExp>;
  budget_group_name?: Maybe<StringComparisonExp>;
  budget_id?: Maybe<StringComparisonExp>;
  budget_total?: Maybe<NumericComparisonExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
  organization_id?: Maybe<UuidComparisonExp>;
};

/** input type for inserting data into table "ledger.account_budget_balance_expense" */
export type LedgerAccountBudgetBalanceExpenseInsertInput = {
  account_id?: Maybe<Scalars['uuid']>;
  balance?: Maybe<Scalars['numeric']>;
  budget_balance?: Maybe<Scalars['numeric']>;
  budget_group_id?: Maybe<Scalars['String']>;
  budget_group_name?: Maybe<Scalars['String']>;
  budget_id?: Maybe<Scalars['String']>;
  budget_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LedgerAccountBudgetBalanceExpenseMaxFields = {
  __typename?: 'ledger_account_budget_balance_expense_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  balance?: Maybe<Scalars['numeric']>;
  budget_balance?: Maybe<Scalars['numeric']>;
  budget_group_id?: Maybe<Scalars['String']>;
  budget_group_name?: Maybe<Scalars['String']>;
  budget_id?: Maybe<Scalars['String']>;
  budget_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "ledger.account_budget_balance_expense" */
export type LedgerAccountBudgetBalanceExpenseMaxOrderBy = {
  account_id?: Maybe<OrderBy>;
  balance?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_group_id?: Maybe<OrderBy>;
  budget_group_name?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LedgerAccountBudgetBalanceExpenseMinFields = {
  __typename?: 'ledger_account_budget_balance_expense_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  balance?: Maybe<Scalars['numeric']>;
  budget_balance?: Maybe<Scalars['numeric']>;
  budget_group_id?: Maybe<Scalars['String']>;
  budget_group_name?: Maybe<Scalars['String']>;
  budget_id?: Maybe<Scalars['String']>;
  budget_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "ledger.account_budget_balance_expense" */
export type LedgerAccountBudgetBalanceExpenseMinOrderBy = {
  account_id?: Maybe<OrderBy>;
  balance?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_group_id?: Maybe<OrderBy>;
  budget_group_name?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
};

/** Ordering options when selecting data from "ledger.account_budget_balance_expense". */
export type LedgerAccountBudgetBalanceExpenseOrderBy = {
  account_id?: Maybe<OrderBy>;
  balance?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_group_id?: Maybe<OrderBy>;
  budget_group_name?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
};

/** select columns of table "ledger.account_budget_balance_expense" */
export enum LedgerAccountBudgetBalanceExpenseSelectColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Balance = 'balance',
  /** column name */
  BudgetBalance = 'budget_balance',
  /** column name */
  BudgetGroupId = 'budget_group_id',
  /** column name */
  BudgetGroupName = 'budget_group_name',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  BudgetTotal = 'budget_total',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  OrganizationId = 'organization_id'
}

/** aggregate stddev on columns */
export type LedgerAccountBudgetBalanceExpenseStddevFields = {
  __typename?: 'ledger_account_budget_balance_expense_stddev_fields';
  balance?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  budget_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ledger.account_budget_balance_expense" */
export type LedgerAccountBudgetBalanceExpenseStddevOrderBy = {
  balance?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LedgerAccountBudgetBalanceExpenseStddevPopFields = {
  __typename?: 'ledger_account_budget_balance_expense_stddev_pop_fields';
  balance?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  budget_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ledger.account_budget_balance_expense" */
export type LedgerAccountBudgetBalanceExpenseStddevPopOrderBy = {
  balance?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LedgerAccountBudgetBalanceExpenseStddevSampFields = {
  __typename?: 'ledger_account_budget_balance_expense_stddev_samp_fields';
  balance?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  budget_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ledger.account_budget_balance_expense" */
export type LedgerAccountBudgetBalanceExpenseStddevSampOrderBy = {
  balance?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LedgerAccountBudgetBalanceExpenseSumFields = {
  __typename?: 'ledger_account_budget_balance_expense_sum_fields';
  balance?: Maybe<Scalars['numeric']>;
  budget_balance?: Maybe<Scalars['numeric']>;
  budget_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ledger.account_budget_balance_expense" */
export type LedgerAccountBudgetBalanceExpenseSumOrderBy = {
  balance?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type LedgerAccountBudgetBalanceExpenseVarPopFields = {
  __typename?: 'ledger_account_budget_balance_expense_var_pop_fields';
  balance?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  budget_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ledger.account_budget_balance_expense" */
export type LedgerAccountBudgetBalanceExpenseVarPopOrderBy = {
  balance?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LedgerAccountBudgetBalanceExpenseVarSampFields = {
  __typename?: 'ledger_account_budget_balance_expense_var_samp_fields';
  balance?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  budget_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ledger.account_budget_balance_expense" */
export type LedgerAccountBudgetBalanceExpenseVarSampOrderBy = {
  balance?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LedgerAccountBudgetBalanceExpenseVarianceFields = {
  __typename?: 'ledger_account_budget_balance_expense_variance_fields';
  balance?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  budget_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ledger.account_budget_balance_expense" */
export type LedgerAccountBudgetBalanceExpenseVarianceOrderBy = {
  balance?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate max on columns */
export type LedgerAccountBudgetBalanceMaxFields = {
  __typename?: 'ledger_account_budget_balance_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  balance?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type LedgerAccountBudgetBalanceMinFields = {
  __typename?: 'ledger_account_budget_balance_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  balance?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "ledger.account_budget_balance". */
export type LedgerAccountBudgetBalanceOrderBy = {
  account_id?: Maybe<OrderBy>;
  balance?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
};

/** select columns of table "ledger.account_budget_balance" */
export enum LedgerAccountBudgetBalanceSelectColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Balance = 'balance',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  OrganizationId = 'organization_id'
}

/** aggregate stddev on columns */
export type LedgerAccountBudgetBalanceStddevFields = {
  __typename?: 'ledger_account_budget_balance_stddev_fields';
  balance?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LedgerAccountBudgetBalanceStddevPopFields = {
  __typename?: 'ledger_account_budget_balance_stddev_pop_fields';
  balance?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LedgerAccountBudgetBalanceStddevSampFields = {
  __typename?: 'ledger_account_budget_balance_stddev_samp_fields';
  balance?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LedgerAccountBudgetBalanceSumFields = {
  __typename?: 'ledger_account_budget_balance_sum_fields';
  balance?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type LedgerAccountBudgetBalanceVarPopFields = {
  __typename?: 'ledger_account_budget_balance_var_pop_fields';
  balance?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LedgerAccountBudgetBalanceVarSampFields = {
  __typename?: 'ledger_account_budget_balance_var_samp_fields';
  balance?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LedgerAccountBudgetBalanceVarianceFields = {
  __typename?: 'ledger_account_budget_balance_variance_fields';
  balance?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ledger.account_budget_monthly" */
export type LedgerAccountBudgetMonthly = {
  __typename?: 'ledger_account_budget_monthly';
  april?: Maybe<Scalars['numeric']>;
  august?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  december?: Maybe<Scalars['numeric']>;
  february?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  january?: Maybe<Scalars['numeric']>;
  july?: Maybe<Scalars['numeric']>;
  june?: Maybe<Scalars['numeric']>;
  march?: Maybe<Scalars['numeric']>;
  may?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  november?: Maybe<Scalars['numeric']>;
  october?: Maybe<Scalars['numeric']>;
  organization_id?: Maybe<Scalars['uuid']>;
  september?: Maybe<Scalars['numeric']>;
  type_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ledger.account_budget_monthly" */
export type LedgerAccountBudgetMonthlyAggregate = {
  __typename?: 'ledger_account_budget_monthly_aggregate';
  aggregate?: Maybe<LedgerAccountBudgetMonthlyAggregateFields>;
  nodes: Array<LedgerAccountBudgetMonthly>;
};

/** aggregate fields of "ledger.account_budget_monthly" */
export type LedgerAccountBudgetMonthlyAggregateFields = {
  __typename?: 'ledger_account_budget_monthly_aggregate_fields';
  avg?: Maybe<LedgerAccountBudgetMonthlyAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LedgerAccountBudgetMonthlyMaxFields>;
  min?: Maybe<LedgerAccountBudgetMonthlyMinFields>;
  stddev?: Maybe<LedgerAccountBudgetMonthlyStddevFields>;
  stddev_pop?: Maybe<LedgerAccountBudgetMonthlyStddevPopFields>;
  stddev_samp?: Maybe<LedgerAccountBudgetMonthlyStddevSampFields>;
  sum?: Maybe<LedgerAccountBudgetMonthlySumFields>;
  var_pop?: Maybe<LedgerAccountBudgetMonthlyVarPopFields>;
  var_samp?: Maybe<LedgerAccountBudgetMonthlyVarSampFields>;
  variance?: Maybe<LedgerAccountBudgetMonthlyVarianceFields>;
};


/** aggregate fields of "ledger.account_budget_monthly" */
export type LedgerAccountBudgetMonthlyAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LedgerAccountBudgetMonthlySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LedgerAccountBudgetMonthlyAvgFields = {
  __typename?: 'ledger_account_budget_monthly_avg_fields';
  april?: Maybe<Scalars['Float']>;
  august?: Maybe<Scalars['Float']>;
  december?: Maybe<Scalars['Float']>;
  february?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  january?: Maybe<Scalars['Float']>;
  july?: Maybe<Scalars['Float']>;
  june?: Maybe<Scalars['Float']>;
  march?: Maybe<Scalars['Float']>;
  may?: Maybe<Scalars['Float']>;
  november?: Maybe<Scalars['Float']>;
  october?: Maybe<Scalars['Float']>;
  september?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ledger.account_budget_monthly". All fields are combined with a logical 'AND'. */
export type LedgerAccountBudgetMonthlyBoolExp = {
  _and?: Maybe<Array<LedgerAccountBudgetMonthlyBoolExp>>;
  _not?: Maybe<LedgerAccountBudgetMonthlyBoolExp>;
  _or?: Maybe<Array<LedgerAccountBudgetMonthlyBoolExp>>;
  april?: Maybe<NumericComparisonExp>;
  august?: Maybe<NumericComparisonExp>;
  budget_id?: Maybe<StringComparisonExp>;
  code?: Maybe<StringComparisonExp>;
  december?: Maybe<NumericComparisonExp>;
  february?: Maybe<NumericComparisonExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  january?: Maybe<NumericComparisonExp>;
  july?: Maybe<NumericComparisonExp>;
  june?: Maybe<NumericComparisonExp>;
  march?: Maybe<NumericComparisonExp>;
  may?: Maybe<NumericComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  november?: Maybe<NumericComparisonExp>;
  october?: Maybe<NumericComparisonExp>;
  organization_id?: Maybe<UuidComparisonExp>;
  september?: Maybe<NumericComparisonExp>;
  type_id?: Maybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type LedgerAccountBudgetMonthlyMaxFields = {
  __typename?: 'ledger_account_budget_monthly_max_fields';
  april?: Maybe<Scalars['numeric']>;
  august?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  december?: Maybe<Scalars['numeric']>;
  february?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  january?: Maybe<Scalars['numeric']>;
  july?: Maybe<Scalars['numeric']>;
  june?: Maybe<Scalars['numeric']>;
  march?: Maybe<Scalars['numeric']>;
  may?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  november?: Maybe<Scalars['numeric']>;
  october?: Maybe<Scalars['numeric']>;
  organization_id?: Maybe<Scalars['uuid']>;
  september?: Maybe<Scalars['numeric']>;
  type_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type LedgerAccountBudgetMonthlyMinFields = {
  __typename?: 'ledger_account_budget_monthly_min_fields';
  april?: Maybe<Scalars['numeric']>;
  august?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  december?: Maybe<Scalars['numeric']>;
  february?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  january?: Maybe<Scalars['numeric']>;
  july?: Maybe<Scalars['numeric']>;
  june?: Maybe<Scalars['numeric']>;
  march?: Maybe<Scalars['numeric']>;
  may?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  november?: Maybe<Scalars['numeric']>;
  october?: Maybe<Scalars['numeric']>;
  organization_id?: Maybe<Scalars['uuid']>;
  september?: Maybe<Scalars['numeric']>;
  type_id?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "ledger.account_budget_monthly". */
export type LedgerAccountBudgetMonthlyOrderBy = {
  april?: Maybe<OrderBy>;
  august?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
  december?: Maybe<OrderBy>;
  february?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  january?: Maybe<OrderBy>;
  july?: Maybe<OrderBy>;
  june?: Maybe<OrderBy>;
  march?: Maybe<OrderBy>;
  may?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  november?: Maybe<OrderBy>;
  october?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  september?: Maybe<OrderBy>;
  type_id?: Maybe<OrderBy>;
};

/** select columns of table "ledger.account_budget_monthly" */
export enum LedgerAccountBudgetMonthlySelectColumn {
  /** column name */
  April = 'april',
  /** column name */
  August = 'august',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  Code = 'code',
  /** column name */
  December = 'december',
  /** column name */
  February = 'february',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  Id = 'id',
  /** column name */
  January = 'january',
  /** column name */
  July = 'july',
  /** column name */
  June = 'june',
  /** column name */
  March = 'march',
  /** column name */
  May = 'may',
  /** column name */
  Name = 'name',
  /** column name */
  November = 'november',
  /** column name */
  October = 'october',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  September = 'september',
  /** column name */
  TypeId = 'type_id'
}

/** aggregate stddev on columns */
export type LedgerAccountBudgetMonthlyStddevFields = {
  __typename?: 'ledger_account_budget_monthly_stddev_fields';
  april?: Maybe<Scalars['Float']>;
  august?: Maybe<Scalars['Float']>;
  december?: Maybe<Scalars['Float']>;
  february?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  january?: Maybe<Scalars['Float']>;
  july?: Maybe<Scalars['Float']>;
  june?: Maybe<Scalars['Float']>;
  march?: Maybe<Scalars['Float']>;
  may?: Maybe<Scalars['Float']>;
  november?: Maybe<Scalars['Float']>;
  october?: Maybe<Scalars['Float']>;
  september?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LedgerAccountBudgetMonthlyStddevPopFields = {
  __typename?: 'ledger_account_budget_monthly_stddev_pop_fields';
  april?: Maybe<Scalars['Float']>;
  august?: Maybe<Scalars['Float']>;
  december?: Maybe<Scalars['Float']>;
  february?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  january?: Maybe<Scalars['Float']>;
  july?: Maybe<Scalars['Float']>;
  june?: Maybe<Scalars['Float']>;
  march?: Maybe<Scalars['Float']>;
  may?: Maybe<Scalars['Float']>;
  november?: Maybe<Scalars['Float']>;
  october?: Maybe<Scalars['Float']>;
  september?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LedgerAccountBudgetMonthlyStddevSampFields = {
  __typename?: 'ledger_account_budget_monthly_stddev_samp_fields';
  april?: Maybe<Scalars['Float']>;
  august?: Maybe<Scalars['Float']>;
  december?: Maybe<Scalars['Float']>;
  february?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  january?: Maybe<Scalars['Float']>;
  july?: Maybe<Scalars['Float']>;
  june?: Maybe<Scalars['Float']>;
  march?: Maybe<Scalars['Float']>;
  may?: Maybe<Scalars['Float']>;
  november?: Maybe<Scalars['Float']>;
  october?: Maybe<Scalars['Float']>;
  september?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LedgerAccountBudgetMonthlySumFields = {
  __typename?: 'ledger_account_budget_monthly_sum_fields';
  april?: Maybe<Scalars['numeric']>;
  august?: Maybe<Scalars['numeric']>;
  december?: Maybe<Scalars['numeric']>;
  february?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  january?: Maybe<Scalars['numeric']>;
  july?: Maybe<Scalars['numeric']>;
  june?: Maybe<Scalars['numeric']>;
  march?: Maybe<Scalars['numeric']>;
  may?: Maybe<Scalars['numeric']>;
  november?: Maybe<Scalars['numeric']>;
  october?: Maybe<Scalars['numeric']>;
  september?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type LedgerAccountBudgetMonthlyVarPopFields = {
  __typename?: 'ledger_account_budget_monthly_var_pop_fields';
  april?: Maybe<Scalars['Float']>;
  august?: Maybe<Scalars['Float']>;
  december?: Maybe<Scalars['Float']>;
  february?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  january?: Maybe<Scalars['Float']>;
  july?: Maybe<Scalars['Float']>;
  june?: Maybe<Scalars['Float']>;
  march?: Maybe<Scalars['Float']>;
  may?: Maybe<Scalars['Float']>;
  november?: Maybe<Scalars['Float']>;
  october?: Maybe<Scalars['Float']>;
  september?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LedgerAccountBudgetMonthlyVarSampFields = {
  __typename?: 'ledger_account_budget_monthly_var_samp_fields';
  april?: Maybe<Scalars['Float']>;
  august?: Maybe<Scalars['Float']>;
  december?: Maybe<Scalars['Float']>;
  february?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  january?: Maybe<Scalars['Float']>;
  july?: Maybe<Scalars['Float']>;
  june?: Maybe<Scalars['Float']>;
  march?: Maybe<Scalars['Float']>;
  may?: Maybe<Scalars['Float']>;
  november?: Maybe<Scalars['Float']>;
  october?: Maybe<Scalars['Float']>;
  september?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LedgerAccountBudgetMonthlyVarianceFields = {
  __typename?: 'ledger_account_budget_monthly_variance_fields';
  april?: Maybe<Scalars['Float']>;
  august?: Maybe<Scalars['Float']>;
  december?: Maybe<Scalars['Float']>;
  february?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  january?: Maybe<Scalars['Float']>;
  july?: Maybe<Scalars['Float']>;
  june?: Maybe<Scalars['Float']>;
  march?: Maybe<Scalars['Float']>;
  may?: Maybe<Scalars['Float']>;
  november?: Maybe<Scalars['Float']>;
  october?: Maybe<Scalars['Float']>;
  september?: Maybe<Scalars['Float']>;
};

/**
 * จัดสรรงบประมาณ
 *
 *
 * columns and relationships of "ledger.budget_allocation"
 */
export type LedgerBudgetAllocation = {
  __typename?: 'ledger_budget_allocation';
  amount: Scalars['numeric'];
  /** An object relationship */
  budget?: Maybe<AccountBudgetType>;
  budget_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  fiscal_year: Scalars['Int'];
  id: Scalars['uuid'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "ledger.budget_allocation" */
export type LedgerBudgetAllocationAggregate = {
  __typename?: 'ledger_budget_allocation_aggregate';
  aggregate?: Maybe<LedgerBudgetAllocationAggregateFields>;
  nodes: Array<LedgerBudgetAllocation>;
};

/** aggregate fields of "ledger.budget_allocation" */
export type LedgerBudgetAllocationAggregateFields = {
  __typename?: 'ledger_budget_allocation_aggregate_fields';
  avg?: Maybe<LedgerBudgetAllocationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LedgerBudgetAllocationMaxFields>;
  min?: Maybe<LedgerBudgetAllocationMinFields>;
  stddev?: Maybe<LedgerBudgetAllocationStddevFields>;
  stddev_pop?: Maybe<LedgerBudgetAllocationStddevPopFields>;
  stddev_samp?: Maybe<LedgerBudgetAllocationStddevSampFields>;
  sum?: Maybe<LedgerBudgetAllocationSumFields>;
  var_pop?: Maybe<LedgerBudgetAllocationVarPopFields>;
  var_samp?: Maybe<LedgerBudgetAllocationVarSampFields>;
  variance?: Maybe<LedgerBudgetAllocationVarianceFields>;
};


/** aggregate fields of "ledger.budget_allocation" */
export type LedgerBudgetAllocationAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LedgerBudgetAllocationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ledger.budget_allocation" */
export type LedgerBudgetAllocationAggregateOrderBy = {
  avg?: Maybe<LedgerBudgetAllocationAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LedgerBudgetAllocationMaxOrderBy>;
  min?: Maybe<LedgerBudgetAllocationMinOrderBy>;
  stddev?: Maybe<LedgerBudgetAllocationStddevOrderBy>;
  stddev_pop?: Maybe<LedgerBudgetAllocationStddevPopOrderBy>;
  stddev_samp?: Maybe<LedgerBudgetAllocationStddevSampOrderBy>;
  sum?: Maybe<LedgerBudgetAllocationSumOrderBy>;
  var_pop?: Maybe<LedgerBudgetAllocationVarPopOrderBy>;
  var_samp?: Maybe<LedgerBudgetAllocationVarSampOrderBy>;
  variance?: Maybe<LedgerBudgetAllocationVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "ledger.budget_allocation" */
export type LedgerBudgetAllocationArrRelInsertInput = {
  data: Array<LedgerBudgetAllocationInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LedgerBudgetAllocationOnConflict>;
};

/** aggregate avg on columns */
export type LedgerBudgetAllocationAvgFields = {
  __typename?: 'ledger_budget_allocation_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ledger.budget_allocation" */
export type LedgerBudgetAllocationAvgOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "ledger.budget_allocation". All fields are combined with a logical 'AND'. */
export type LedgerBudgetAllocationBoolExp = {
  _and?: Maybe<Array<LedgerBudgetAllocationBoolExp>>;
  _not?: Maybe<LedgerBudgetAllocationBoolExp>;
  _or?: Maybe<Array<LedgerBudgetAllocationBoolExp>>;
  amount?: Maybe<NumericComparisonExp>;
  budget?: Maybe<AccountBudgetTypeBoolExp>;
  budget_id?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  organization?: Maybe<OrganizationBoolExp>;
  organization_id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "ledger.budget_allocation" */
export enum LedgerBudgetAllocationConstraint {
  /** unique or primary key constraint */
  BudgetAllocationBudgetIdOrganizationIdFiscalYearKey = 'budget_allocation_budget_id_organization_id_fiscal_year_key',
  /** unique or primary key constraint */
  BudgetAllocationPkey = 'budget_allocation_pkey'
}

/** input type for incrementing numeric columns in table "ledger.budget_allocation" */
export type LedgerBudgetAllocationIncInput = {
  amount?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ledger.budget_allocation" */
export type LedgerBudgetAllocationInsertInput = {
  amount?: Maybe<Scalars['numeric']>;
  budget?: Maybe<AccountBudgetTypeObjRelInsertInput>;
  budget_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  organization?: Maybe<OrganizationObjRelInsertInput>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LedgerBudgetAllocationMaxFields = {
  __typename?: 'ledger_budget_allocation_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "ledger.budget_allocation" */
export type LedgerBudgetAllocationMaxOrderBy = {
  amount?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LedgerBudgetAllocationMinFields = {
  __typename?: 'ledger_budget_allocation_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "ledger.budget_allocation" */
export type LedgerBudgetAllocationMinOrderBy = {
  amount?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "ledger.budget_allocation" */
export type LedgerBudgetAllocationMutationResponse = {
  __typename?: 'ledger_budget_allocation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LedgerBudgetAllocation>;
};

/** on conflict condition type for table "ledger.budget_allocation" */
export type LedgerBudgetAllocationOnConflict = {
  constraint: LedgerBudgetAllocationConstraint;
  update_columns?: Array<LedgerBudgetAllocationUpdateColumn>;
  where?: Maybe<LedgerBudgetAllocationBoolExp>;
};

/** Ordering options when selecting data from "ledger.budget_allocation". */
export type LedgerBudgetAllocationOrderBy = {
  amount?: Maybe<OrderBy>;
  budget?: Maybe<AccountBudgetTypeOrderBy>;
  budget_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  organization?: Maybe<OrganizationOrderBy>;
  organization_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** primary key columns input for table: ledger_budget_allocation */
export type LedgerBudgetAllocationPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "ledger.budget_allocation" */
export enum LedgerBudgetAllocationSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "ledger.budget_allocation" */
export type LedgerBudgetAllocationSetInput = {
  amount?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type LedgerBudgetAllocationStddevFields = {
  __typename?: 'ledger_budget_allocation_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ledger.budget_allocation" */
export type LedgerBudgetAllocationStddevOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LedgerBudgetAllocationStddevPopFields = {
  __typename?: 'ledger_budget_allocation_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ledger.budget_allocation" */
export type LedgerBudgetAllocationStddevPopOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LedgerBudgetAllocationStddevSampFields = {
  __typename?: 'ledger_budget_allocation_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ledger.budget_allocation" */
export type LedgerBudgetAllocationStddevSampOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LedgerBudgetAllocationSumFields = {
  __typename?: 'ledger_budget_allocation_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ledger.budget_allocation" */
export type LedgerBudgetAllocationSumOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** update columns of table "ledger.budget_allocation" */
export enum LedgerBudgetAllocationUpdateColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type LedgerBudgetAllocationVarPopFields = {
  __typename?: 'ledger_budget_allocation_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ledger.budget_allocation" */
export type LedgerBudgetAllocationVarPopOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LedgerBudgetAllocationVarSampFields = {
  __typename?: 'ledger_budget_allocation_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ledger.budget_allocation" */
export type LedgerBudgetAllocationVarSampOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LedgerBudgetAllocationVarianceFields = {
  __typename?: 'ledger_budget_allocation_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ledger.budget_allocation" */
export type LedgerBudgetAllocationVarianceOrderBy = {
  amount?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
};

/** columns and relationships of "ledger.ledger" */
export type LedgerLedger = {
  __typename?: 'ledger_ledger';
  /** An object relationship */
  account: AccountAccount;
  account_id: Scalars['uuid'];
  /** จำนวนเงิน */
  amount: Scalars['numeric'];
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  approved_by_user?: Maybe<Users>;
  /** An object relationship */
  budget?: Maybe<AccountBudgetType>;
  budget_id: Scalars['String'];
  change_request_at?: Maybe<Scalars['timestamptz']>;
  change_request_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  change_request_by_user?: Maybe<Users>;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  /** An object relationship */
  created_by_user: Users;
  /** วันที่สร้างรายการ */
  date: Scalars['date'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year: Scalars['Int'];
  /** เลขที่ขอเบิก ในระบบ GFMIS */
  gfmis_id: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  ledger_attachments: Array<LedgerLedgerAttachment>;
  /** An aggregate relationship */
  ledger_attachments_aggregate: LedgerLedgerAttachmentAggregate;
  /** An array relationship */
  ledger_logs: Array<LedgerLedgerLog>;
  /** An aggregate relationship */
  ledger_logs_aggregate: LedgerLedgerLogAggregate;
  locked_at?: Maybe<Scalars['timestamptz']>;
  locked_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  locked_by_user?: Maybe<Users>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  /** An object relationship */
  receivable?: Maybe<LedgerReceivable>;
  receivable_id?: Maybe<Scalars['uuid']>;
  rejected_at?: Maybe<Scalars['timestamptz']>;
  rejected_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  status: LedgerLedgerStatus;
  status_id: LedgerLedgerStatusEnum;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  submitted_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  submitted_by_user?: Maybe<Users>;
  /** An object relationship */
  type: LedgerLedgerType;
  type_id: LedgerLedgerTypeEnum;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['uuid'];
  /** An object relationship */
  updated_by_user: Users;
};


/** columns and relationships of "ledger.ledger" */
export type LedgerLedgerLedgerAttachmentsArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerAttachmentOrderBy>>;
  where?: Maybe<LedgerLedgerAttachmentBoolExp>;
};


/** columns and relationships of "ledger.ledger" */
export type LedgerLedgerLedgerAttachmentsAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerAttachmentOrderBy>>;
  where?: Maybe<LedgerLedgerAttachmentBoolExp>;
};


/** columns and relationships of "ledger.ledger" */
export type LedgerLedgerLedgerLogsArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerLogOrderBy>>;
  where?: Maybe<LedgerLedgerLogBoolExp>;
};


/** columns and relationships of "ledger.ledger" */
export type LedgerLedgerLedgerLogsAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerLogOrderBy>>;
  where?: Maybe<LedgerLedgerLogBoolExp>;
};

/** aggregated selection of "ledger.ledger" */
export type LedgerLedgerAggregate = {
  __typename?: 'ledger_ledger_aggregate';
  aggregate?: Maybe<LedgerLedgerAggregateFields>;
  nodes: Array<LedgerLedger>;
};

/** aggregate fields of "ledger.ledger" */
export type LedgerLedgerAggregateFields = {
  __typename?: 'ledger_ledger_aggregate_fields';
  avg?: Maybe<LedgerLedgerAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LedgerLedgerMaxFields>;
  min?: Maybe<LedgerLedgerMinFields>;
  stddev?: Maybe<LedgerLedgerStddevFields>;
  stddev_pop?: Maybe<LedgerLedgerStddevPopFields>;
  stddev_samp?: Maybe<LedgerLedgerStddevSampFields>;
  sum?: Maybe<LedgerLedgerSumFields>;
  var_pop?: Maybe<LedgerLedgerVarPopFields>;
  var_samp?: Maybe<LedgerLedgerVarSampFields>;
  variance?: Maybe<LedgerLedgerVarianceFields>;
};


/** aggregate fields of "ledger.ledger" */
export type LedgerLedgerAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LedgerLedgerSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ledger.ledger" */
export type LedgerLedgerAggregateOrderBy = {
  avg?: Maybe<LedgerLedgerAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LedgerLedgerMaxOrderBy>;
  min?: Maybe<LedgerLedgerMinOrderBy>;
  stddev?: Maybe<LedgerLedgerStddevOrderBy>;
  stddev_pop?: Maybe<LedgerLedgerStddevPopOrderBy>;
  stddev_samp?: Maybe<LedgerLedgerStddevSampOrderBy>;
  sum?: Maybe<LedgerLedgerSumOrderBy>;
  var_pop?: Maybe<LedgerLedgerVarPopOrderBy>;
  var_samp?: Maybe<LedgerLedgerVarSampOrderBy>;
  variance?: Maybe<LedgerLedgerVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "ledger.ledger" */
export type LedgerLedgerArrRelInsertInput = {
  data: Array<LedgerLedgerInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LedgerLedgerOnConflict>;
};

/** columns and relationships of "ledger.ledger_attachment" */
export type LedgerLedgerAttachment = {
  __typename?: 'ledger_ledger_attachment';
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  file_path: Scalars['String'];
  filename: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  ledger: LedgerLedger;
  ledger_id: Scalars['uuid'];
  note: Scalars['String'];
  original_name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['uuid'];
};

/** aggregated selection of "ledger.ledger_attachment" */
export type LedgerLedgerAttachmentAggregate = {
  __typename?: 'ledger_ledger_attachment_aggregate';
  aggregate?: Maybe<LedgerLedgerAttachmentAggregateFields>;
  nodes: Array<LedgerLedgerAttachment>;
};

/** aggregate fields of "ledger.ledger_attachment" */
export type LedgerLedgerAttachmentAggregateFields = {
  __typename?: 'ledger_ledger_attachment_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LedgerLedgerAttachmentMaxFields>;
  min?: Maybe<LedgerLedgerAttachmentMinFields>;
};


/** aggregate fields of "ledger.ledger_attachment" */
export type LedgerLedgerAttachmentAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LedgerLedgerAttachmentSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ledger.ledger_attachment" */
export type LedgerLedgerAttachmentAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<LedgerLedgerAttachmentMaxOrderBy>;
  min?: Maybe<LedgerLedgerAttachmentMinOrderBy>;
};

/** input type for inserting array relation for remote table "ledger.ledger_attachment" */
export type LedgerLedgerAttachmentArrRelInsertInput = {
  data: Array<LedgerLedgerAttachmentInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LedgerLedgerAttachmentOnConflict>;
};

/** Boolean expression to filter rows from the table "ledger.ledger_attachment". All fields are combined with a logical 'AND'. */
export type LedgerLedgerAttachmentBoolExp = {
  _and?: Maybe<Array<LedgerLedgerAttachmentBoolExp>>;
  _not?: Maybe<LedgerLedgerAttachmentBoolExp>;
  _or?: Maybe<Array<LedgerLedgerAttachmentBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  file_path?: Maybe<StringComparisonExp>;
  filename?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  ledger?: Maybe<LedgerLedgerBoolExp>;
  ledger_id?: Maybe<UuidComparisonExp>;
  note?: Maybe<StringComparisonExp>;
  original_name?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "ledger.ledger_attachment" */
export enum LedgerLedgerAttachmentConstraint {
  /** unique or primary key constraint */
  LedgerAttachmentPkey = 'ledger_attachment_pkey'
}

/** input type for inserting data into table "ledger.ledger_attachment" */
export type LedgerLedgerAttachmentInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  file_path?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ledger?: Maybe<LedgerLedgerObjRelInsertInput>;
  ledger_id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  original_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LedgerLedgerAttachmentMaxFields = {
  __typename?: 'ledger_ledger_attachment_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  file_path?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ledger_id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  original_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "ledger.ledger_attachment" */
export type LedgerLedgerAttachmentMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  file_path?: Maybe<OrderBy>;
  filename?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ledger_id?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  original_name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LedgerLedgerAttachmentMinFields = {
  __typename?: 'ledger_ledger_attachment_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  file_path?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ledger_id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  original_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "ledger.ledger_attachment" */
export type LedgerLedgerAttachmentMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  file_path?: Maybe<OrderBy>;
  filename?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ledger_id?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  original_name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "ledger.ledger_attachment" */
export type LedgerLedgerAttachmentMutationResponse = {
  __typename?: 'ledger_ledger_attachment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LedgerLedgerAttachment>;
};

/** on conflict condition type for table "ledger.ledger_attachment" */
export type LedgerLedgerAttachmentOnConflict = {
  constraint: LedgerLedgerAttachmentConstraint;
  update_columns?: Array<LedgerLedgerAttachmentUpdateColumn>;
  where?: Maybe<LedgerLedgerAttachmentBoolExp>;
};

/** Ordering options when selecting data from "ledger.ledger_attachment". */
export type LedgerLedgerAttachmentOrderBy = {
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  file_path?: Maybe<OrderBy>;
  filename?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ledger?: Maybe<LedgerLedgerOrderBy>;
  ledger_id?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  original_name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** primary key columns input for table: ledger_ledger_attachment */
export type LedgerLedgerAttachmentPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "ledger.ledger_attachment" */
export enum LedgerLedgerAttachmentSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  LedgerId = 'ledger_id',
  /** column name */
  Note = 'note',
  /** column name */
  OriginalName = 'original_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "ledger.ledger_attachment" */
export type LedgerLedgerAttachmentSetInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  file_path?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ledger_id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  original_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** update columns of table "ledger.ledger_attachment" */
export enum LedgerLedgerAttachmentUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  LedgerId = 'ledger_id',
  /** column name */
  Note = 'note',
  /** column name */
  OriginalName = 'original_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate avg on columns */
export type LedgerLedgerAvgFields = {
  __typename?: 'ledger_ledger_avg_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ledger.ledger" */
export type LedgerLedgerAvgOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "ledger.ledger". All fields are combined with a logical 'AND'. */
export type LedgerLedgerBoolExp = {
  _and?: Maybe<Array<LedgerLedgerBoolExp>>;
  _not?: Maybe<LedgerLedgerBoolExp>;
  _or?: Maybe<Array<LedgerLedgerBoolExp>>;
  account?: Maybe<AccountAccountBoolExp>;
  account_id?: Maybe<UuidComparisonExp>;
  amount?: Maybe<NumericComparisonExp>;
  approved_at?: Maybe<TimestamptzComparisonExp>;
  approved_by?: Maybe<UuidComparisonExp>;
  approved_by_user?: Maybe<UsersBoolExp>;
  budget?: Maybe<AccountBudgetTypeBoolExp>;
  budget_id?: Maybe<StringComparisonExp>;
  change_request_at?: Maybe<TimestamptzComparisonExp>;
  change_request_by?: Maybe<UuidComparisonExp>;
  change_request_by_user?: Maybe<UsersBoolExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  created_by_user?: Maybe<UsersBoolExp>;
  date?: Maybe<DateComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
  gfmis_id?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  ledger_attachments?: Maybe<LedgerLedgerAttachmentBoolExp>;
  ledger_logs?: Maybe<LedgerLedgerLogBoolExp>;
  locked_at?: Maybe<TimestamptzComparisonExp>;
  locked_by?: Maybe<UuidComparisonExp>;
  locked_by_user?: Maybe<UsersBoolExp>;
  note?: Maybe<StringComparisonExp>;
  organization?: Maybe<OrganizationBoolExp>;
  organization_id?: Maybe<UuidComparisonExp>;
  receivable?: Maybe<LedgerReceivableBoolExp>;
  receivable_id?: Maybe<UuidComparisonExp>;
  rejected_at?: Maybe<TimestamptzComparisonExp>;
  rejected_by?: Maybe<UuidComparisonExp>;
  status?: Maybe<LedgerLedgerStatusBoolExp>;
  status_id?: Maybe<LedgerLedgerStatusEnumComparisonExp>;
  submitted_at?: Maybe<TimestamptzComparisonExp>;
  submitted_by?: Maybe<UuidComparisonExp>;
  submitted_by_user?: Maybe<UsersBoolExp>;
  type?: Maybe<LedgerLedgerTypeBoolExp>;
  type_id?: Maybe<LedgerLedgerTypeEnumComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<UuidComparisonExp>;
  updated_by_user?: Maybe<UsersBoolExp>;
};

/** unique or primary key constraints on table "ledger.ledger" */
export enum LedgerLedgerConstraint {
  /** unique or primary key constraint */
  LedgerPkey = 'ledger_pkey'
}

/** input type for incrementing numeric columns in table "ledger.ledger" */
export type LedgerLedgerIncInput = {
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ledger.ledger" */
export type LedgerLedgerInsertInput = {
  account?: Maybe<AccountAccountObjRelInsertInput>;
  account_id?: Maybe<Scalars['uuid']>;
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['uuid']>;
  approved_by_user?: Maybe<UsersObjRelInsertInput>;
  budget?: Maybe<AccountBudgetTypeObjRelInsertInput>;
  budget_id?: Maybe<Scalars['String']>;
  change_request_at?: Maybe<Scalars['timestamptz']>;
  change_request_by?: Maybe<Scalars['uuid']>;
  change_request_by_user?: Maybe<UsersObjRelInsertInput>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  created_by_user?: Maybe<UsersObjRelInsertInput>;
  /** วันที่สร้างรายการ */
  date?: Maybe<Scalars['date']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** เลขที่ขอเบิก ในระบบ GFMIS */
  gfmis_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ledger_attachments?: Maybe<LedgerLedgerAttachmentArrRelInsertInput>;
  ledger_logs?: Maybe<LedgerLedgerLogArrRelInsertInput>;
  locked_at?: Maybe<Scalars['timestamptz']>;
  locked_by?: Maybe<Scalars['uuid']>;
  locked_by_user?: Maybe<UsersObjRelInsertInput>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationObjRelInsertInput>;
  organization_id?: Maybe<Scalars['uuid']>;
  receivable?: Maybe<LedgerReceivableObjRelInsertInput>;
  receivable_id?: Maybe<Scalars['uuid']>;
  rejected_at?: Maybe<Scalars['timestamptz']>;
  rejected_by?: Maybe<Scalars['uuid']>;
  status?: Maybe<LedgerLedgerStatusObjRelInsertInput>;
  status_id?: Maybe<LedgerLedgerStatusEnum>;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  submitted_by?: Maybe<Scalars['uuid']>;
  submitted_by_user?: Maybe<UsersObjRelInsertInput>;
  type?: Maybe<LedgerLedgerTypeObjRelInsertInput>;
  type_id?: Maybe<LedgerLedgerTypeEnum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
  updated_by_user?: Maybe<UsersObjRelInsertInput>;
};

/**
 * ประวัติการกระทำ
 *
 *
 * columns and relationships of "ledger.ledger_log"
 */
export type LedgerLedgerLog = {
  __typename?: 'ledger_ledger_log';
  action: Scalars['String'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  /** An object relationship */
  created_by_user: Users;
  description: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  ledger: LedgerLedger;
  ledger_id: Scalars['uuid'];
  /** An object relationship */
  new_status: LedgerLedgerStatus;
  new_status_id: LedgerLedgerStatusEnum;
  /** An object relationship */
  old_status: LedgerLedgerStatus;
  old_status_id: LedgerLedgerStatusEnum;
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
};

/** aggregated selection of "ledger.ledger_log" */
export type LedgerLedgerLogAggregate = {
  __typename?: 'ledger_ledger_log_aggregate';
  aggregate?: Maybe<LedgerLedgerLogAggregateFields>;
  nodes: Array<LedgerLedgerLog>;
};

/** aggregate fields of "ledger.ledger_log" */
export type LedgerLedgerLogAggregateFields = {
  __typename?: 'ledger_ledger_log_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LedgerLedgerLogMaxFields>;
  min?: Maybe<LedgerLedgerLogMinFields>;
};


/** aggregate fields of "ledger.ledger_log" */
export type LedgerLedgerLogAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LedgerLedgerLogSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ledger.ledger_log" */
export type LedgerLedgerLogAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<LedgerLedgerLogMaxOrderBy>;
  min?: Maybe<LedgerLedgerLogMinOrderBy>;
};

/** input type for inserting array relation for remote table "ledger.ledger_log" */
export type LedgerLedgerLogArrRelInsertInput = {
  data: Array<LedgerLedgerLogInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LedgerLedgerLogOnConflict>;
};

/** Boolean expression to filter rows from the table "ledger.ledger_log". All fields are combined with a logical 'AND'. */
export type LedgerLedgerLogBoolExp = {
  _and?: Maybe<Array<LedgerLedgerLogBoolExp>>;
  _not?: Maybe<LedgerLedgerLogBoolExp>;
  _or?: Maybe<Array<LedgerLedgerLogBoolExp>>;
  action?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  created_by_user?: Maybe<UsersBoolExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  ledger?: Maybe<LedgerLedgerBoolExp>;
  ledger_id?: Maybe<UuidComparisonExp>;
  new_status?: Maybe<LedgerLedgerStatusBoolExp>;
  new_status_id?: Maybe<LedgerLedgerStatusEnumComparisonExp>;
  old_status?: Maybe<LedgerLedgerStatusBoolExp>;
  old_status_id?: Maybe<LedgerLedgerStatusEnumComparisonExp>;
  organization?: Maybe<OrganizationBoolExp>;
  organization_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "ledger.ledger_log" */
export enum LedgerLedgerLogConstraint {
  /** unique or primary key constraint */
  LedgerLogPkey = 'ledger_log_pkey'
}

/** input type for inserting data into table "ledger.ledger_log" */
export type LedgerLedgerLogInsertInput = {
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  created_by_user?: Maybe<UsersObjRelInsertInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ledger?: Maybe<LedgerLedgerObjRelInsertInput>;
  ledger_id?: Maybe<Scalars['uuid']>;
  new_status?: Maybe<LedgerLedgerStatusObjRelInsertInput>;
  new_status_id?: Maybe<LedgerLedgerStatusEnum>;
  old_status?: Maybe<LedgerLedgerStatusObjRelInsertInput>;
  old_status_id?: Maybe<LedgerLedgerStatusEnum>;
  organization?: Maybe<OrganizationObjRelInsertInput>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LedgerLedgerLogMaxFields = {
  __typename?: 'ledger_ledger_log_max_fields';
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ledger_id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "ledger.ledger_log" */
export type LedgerLedgerLogMaxOrderBy = {
  action?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ledger_id?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LedgerLedgerLogMinFields = {
  __typename?: 'ledger_ledger_log_min_fields';
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ledger_id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "ledger.ledger_log" */
export type LedgerLedgerLogMinOrderBy = {
  action?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ledger_id?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "ledger.ledger_log" */
export type LedgerLedgerLogMutationResponse = {
  __typename?: 'ledger_ledger_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LedgerLedgerLog>;
};

/** on conflict condition type for table "ledger.ledger_log" */
export type LedgerLedgerLogOnConflict = {
  constraint: LedgerLedgerLogConstraint;
  update_columns?: Array<LedgerLedgerLogUpdateColumn>;
  where?: Maybe<LedgerLedgerLogBoolExp>;
};

/** Ordering options when selecting data from "ledger.ledger_log". */
export type LedgerLedgerLogOrderBy = {
  action?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  created_by_user?: Maybe<UsersOrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ledger?: Maybe<LedgerLedgerOrderBy>;
  ledger_id?: Maybe<OrderBy>;
  new_status?: Maybe<LedgerLedgerStatusOrderBy>;
  new_status_id?: Maybe<OrderBy>;
  old_status?: Maybe<LedgerLedgerStatusOrderBy>;
  old_status_id?: Maybe<OrderBy>;
  organization?: Maybe<OrganizationOrderBy>;
  organization_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: ledger_ledger_log */
export type LedgerLedgerLogPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "ledger.ledger_log" */
export enum LedgerLedgerLogSelectColumn {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LedgerId = 'ledger_id',
  /** column name */
  NewStatusId = 'new_status_id',
  /** column name */
  OldStatusId = 'old_status_id',
  /** column name */
  OrganizationId = 'organization_id'
}

/** input type for updating data in table "ledger.ledger_log" */
export type LedgerLedgerLogSetInput = {
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ledger_id?: Maybe<Scalars['uuid']>;
  new_status_id?: Maybe<LedgerLedgerStatusEnum>;
  old_status_id?: Maybe<LedgerLedgerStatusEnum>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "ledger.ledger_log" */
export enum LedgerLedgerLogUpdateColumn {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LedgerId = 'ledger_id',
  /** column name */
  NewStatusId = 'new_status_id',
  /** column name */
  OldStatusId = 'old_status_id',
  /** column name */
  OrganizationId = 'organization_id'
}

/** aggregate max on columns */
export type LedgerLedgerMaxFields = {
  __typename?: 'ledger_ledger_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['uuid']>;
  budget_id?: Maybe<Scalars['String']>;
  change_request_at?: Maybe<Scalars['timestamptz']>;
  change_request_by?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  /** วันที่สร้างรายการ */
  date?: Maybe<Scalars['date']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** เลขที่ขอเบิก ในระบบ GFMIS */
  gfmis_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locked_at?: Maybe<Scalars['timestamptz']>;
  locked_by?: Maybe<Scalars['uuid']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  receivable_id?: Maybe<Scalars['uuid']>;
  rejected_at?: Maybe<Scalars['timestamptz']>;
  rejected_by?: Maybe<Scalars['uuid']>;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  submitted_by?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "ledger.ledger" */
export type LedgerLedgerMaxOrderBy = {
  account_id?: Maybe<OrderBy>;
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  approved_at?: Maybe<OrderBy>;
  approved_by?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  change_request_at?: Maybe<OrderBy>;
  change_request_by?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  /** วันที่สร้างรายการ */
  date?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year?: Maybe<OrderBy>;
  /** เลขที่ขอเบิก ในระบบ GFMIS */
  gfmis_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  locked_at?: Maybe<OrderBy>;
  locked_by?: Maybe<OrderBy>;
  /** หมายเหตุ */
  note?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  receivable_id?: Maybe<OrderBy>;
  rejected_at?: Maybe<OrderBy>;
  rejected_by?: Maybe<OrderBy>;
  submitted_at?: Maybe<OrderBy>;
  submitted_by?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LedgerLedgerMinFields = {
  __typename?: 'ledger_ledger_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['uuid']>;
  budget_id?: Maybe<Scalars['String']>;
  change_request_at?: Maybe<Scalars['timestamptz']>;
  change_request_by?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  /** วันที่สร้างรายการ */
  date?: Maybe<Scalars['date']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** เลขที่ขอเบิก ในระบบ GFMIS */
  gfmis_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locked_at?: Maybe<Scalars['timestamptz']>;
  locked_by?: Maybe<Scalars['uuid']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  receivable_id?: Maybe<Scalars['uuid']>;
  rejected_at?: Maybe<Scalars['timestamptz']>;
  rejected_by?: Maybe<Scalars['uuid']>;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  submitted_by?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "ledger.ledger" */
export type LedgerLedgerMinOrderBy = {
  account_id?: Maybe<OrderBy>;
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  approved_at?: Maybe<OrderBy>;
  approved_by?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  change_request_at?: Maybe<OrderBy>;
  change_request_by?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  /** วันที่สร้างรายการ */
  date?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year?: Maybe<OrderBy>;
  /** เลขที่ขอเบิก ในระบบ GFMIS */
  gfmis_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  locked_at?: Maybe<OrderBy>;
  locked_by?: Maybe<OrderBy>;
  /** หมายเหตุ */
  note?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  receivable_id?: Maybe<OrderBy>;
  rejected_at?: Maybe<OrderBy>;
  rejected_by?: Maybe<OrderBy>;
  submitted_at?: Maybe<OrderBy>;
  submitted_by?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "ledger.ledger" */
export type LedgerLedgerMutationResponse = {
  __typename?: 'ledger_ledger_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LedgerLedger>;
};

/** input type for inserting object relation for remote table "ledger.ledger" */
export type LedgerLedgerObjRelInsertInput = {
  data: LedgerLedgerInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LedgerLedgerOnConflict>;
};

/** on conflict condition type for table "ledger.ledger" */
export type LedgerLedgerOnConflict = {
  constraint: LedgerLedgerConstraint;
  update_columns?: Array<LedgerLedgerUpdateColumn>;
  where?: Maybe<LedgerLedgerBoolExp>;
};

/** Ordering options when selecting data from "ledger.ledger". */
export type LedgerLedgerOrderBy = {
  account?: Maybe<AccountAccountOrderBy>;
  account_id?: Maybe<OrderBy>;
  amount?: Maybe<OrderBy>;
  approved_at?: Maybe<OrderBy>;
  approved_by?: Maybe<OrderBy>;
  approved_by_user?: Maybe<UsersOrderBy>;
  budget?: Maybe<AccountBudgetTypeOrderBy>;
  budget_id?: Maybe<OrderBy>;
  change_request_at?: Maybe<OrderBy>;
  change_request_by?: Maybe<OrderBy>;
  change_request_by_user?: Maybe<UsersOrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  created_by_user?: Maybe<UsersOrderBy>;
  date?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  gfmis_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ledger_attachments_aggregate?: Maybe<LedgerLedgerAttachmentAggregateOrderBy>;
  ledger_logs_aggregate?: Maybe<LedgerLedgerLogAggregateOrderBy>;
  locked_at?: Maybe<OrderBy>;
  locked_by?: Maybe<OrderBy>;
  locked_by_user?: Maybe<UsersOrderBy>;
  note?: Maybe<OrderBy>;
  organization?: Maybe<OrganizationOrderBy>;
  organization_id?: Maybe<OrderBy>;
  receivable?: Maybe<LedgerReceivableOrderBy>;
  receivable_id?: Maybe<OrderBy>;
  rejected_at?: Maybe<OrderBy>;
  rejected_by?: Maybe<OrderBy>;
  status?: Maybe<LedgerLedgerStatusOrderBy>;
  status_id?: Maybe<OrderBy>;
  submitted_at?: Maybe<OrderBy>;
  submitted_by?: Maybe<OrderBy>;
  submitted_by_user?: Maybe<UsersOrderBy>;
  type?: Maybe<LedgerLedgerTypeOrderBy>;
  type_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
  updated_by_user?: Maybe<UsersOrderBy>;
};

/** primary key columns input for table: ledger_ledger */
export type LedgerLedgerPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "ledger.ledger" */
export enum LedgerLedgerSelectColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  ApprovedBy = 'approved_by',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  ChangeRequestAt = 'change_request_at',
  /** column name */
  ChangeRequestBy = 'change_request_by',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Date = 'date',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  GfmisId = 'gfmis_id',
  /** column name */
  Id = 'id',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  LockedBy = 'locked_by',
  /** column name */
  Note = 'note',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ReceivableId = 'receivable_id',
  /** column name */
  RejectedAt = 'rejected_at',
  /** column name */
  RejectedBy = 'rejected_by',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  SubmittedAt = 'submitted_at',
  /** column name */
  SubmittedBy = 'submitted_by',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "ledger.ledger" */
export type LedgerLedgerSetInput = {
  account_id?: Maybe<Scalars['uuid']>;
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['uuid']>;
  budget_id?: Maybe<Scalars['String']>;
  change_request_at?: Maybe<Scalars['timestamptz']>;
  change_request_by?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  /** วันที่สร้างรายการ */
  date?: Maybe<Scalars['date']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** เลขที่ขอเบิก ในระบบ GFMIS */
  gfmis_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locked_at?: Maybe<Scalars['timestamptz']>;
  locked_by?: Maybe<Scalars['uuid']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  receivable_id?: Maybe<Scalars['uuid']>;
  rejected_at?: Maybe<Scalars['timestamptz']>;
  rejected_by?: Maybe<Scalars['uuid']>;
  status_id?: Maybe<LedgerLedgerStatusEnum>;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  submitted_by?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<LedgerLedgerTypeEnum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/**
 * Ledger Type
 *
 *
 * columns and relationships of "ledger.ledger_status"
 */
export type LedgerLedgerStatus = {
  __typename?: 'ledger_ledger_status';
  description: Scalars['String'];
  id: Scalars['String'];
  /** An array relationship */
  ledgers: Array<LedgerLedger>;
  /** An aggregate relationship */
  ledgers_aggregate: LedgerLedgerAggregate;
};


/**
 * Ledger Type
 *
 *
 * columns and relationships of "ledger.ledger_status"
 */
export type LedgerLedgerStatusLedgersArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerOrderBy>>;
  where?: Maybe<LedgerLedgerBoolExp>;
};


/**
 * Ledger Type
 *
 *
 * columns and relationships of "ledger.ledger_status"
 */
export type LedgerLedgerStatusLedgersAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerOrderBy>>;
  where?: Maybe<LedgerLedgerBoolExp>;
};

/** aggregated selection of "ledger.ledger_status" */
export type LedgerLedgerStatusAggregate = {
  __typename?: 'ledger_ledger_status_aggregate';
  aggregate?: Maybe<LedgerLedgerStatusAggregateFields>;
  nodes: Array<LedgerLedgerStatus>;
};

/** aggregate fields of "ledger.ledger_status" */
export type LedgerLedgerStatusAggregateFields = {
  __typename?: 'ledger_ledger_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LedgerLedgerStatusMaxFields>;
  min?: Maybe<LedgerLedgerStatusMinFields>;
};


/** aggregate fields of "ledger.ledger_status" */
export type LedgerLedgerStatusAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LedgerLedgerStatusSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ledger.ledger_status". All fields are combined with a logical 'AND'. */
export type LedgerLedgerStatusBoolExp = {
  _and?: Maybe<Array<LedgerLedgerStatusBoolExp>>;
  _not?: Maybe<LedgerLedgerStatusBoolExp>;
  _or?: Maybe<Array<LedgerLedgerStatusBoolExp>>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  ledgers?: Maybe<LedgerLedgerBoolExp>;
};

/** unique or primary key constraints on table "ledger.ledger_status" */
export enum LedgerLedgerStatusConstraint {
  /** unique or primary key constraint */
  LedgerTypePkey = 'ledger_type_pkey'
}

export enum LedgerLedgerStatusEnum {
  /** อนุมัติ */
  Approved = 'approved',
  /** เปลี่ยนแปลงข้อมูล */
  ChangeRequest = 'change_request',
  /** แบบร่าง */
  Draft = 'draft',
  /** ล็อก */
  Locked = 'locked',
  /** ไม่อนุมัติ */
  Rejected = 'rejected',
  /** รอการอนุมัติ */
  Submitted = 'submitted'
}

/** Boolean expression to compare columns of type "ledger_ledger_status_enum". All fields are combined with logical 'AND'. */
export type LedgerLedgerStatusEnumComparisonExp = {
  _eq?: Maybe<LedgerLedgerStatusEnum>;
  _in?: Maybe<Array<LedgerLedgerStatusEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<LedgerLedgerStatusEnum>;
  _nin?: Maybe<Array<LedgerLedgerStatusEnum>>;
};

/** input type for inserting data into table "ledger.ledger_status" */
export type LedgerLedgerStatusInsertInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ledgers?: Maybe<LedgerLedgerArrRelInsertInput>;
};

/** aggregate max on columns */
export type LedgerLedgerStatusMaxFields = {
  __typename?: 'ledger_ledger_status_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type LedgerLedgerStatusMinFields = {
  __typename?: 'ledger_ledger_status_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ledger.ledger_status" */
export type LedgerLedgerStatusMutationResponse = {
  __typename?: 'ledger_ledger_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LedgerLedgerStatus>;
};

/** input type for inserting object relation for remote table "ledger.ledger_status" */
export type LedgerLedgerStatusObjRelInsertInput = {
  data: LedgerLedgerStatusInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LedgerLedgerStatusOnConflict>;
};

/** on conflict condition type for table "ledger.ledger_status" */
export type LedgerLedgerStatusOnConflict = {
  constraint: LedgerLedgerStatusConstraint;
  update_columns?: Array<LedgerLedgerStatusUpdateColumn>;
  where?: Maybe<LedgerLedgerStatusBoolExp>;
};

/** Ordering options when selecting data from "ledger.ledger_status". */
export type LedgerLedgerStatusOrderBy = {
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ledgers_aggregate?: Maybe<LedgerLedgerAggregateOrderBy>;
};

/** primary key columns input for table: ledger_ledger_status */
export type LedgerLedgerStatusPkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "ledger.ledger_status" */
export enum LedgerLedgerStatusSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ledger.ledger_status" */
export type LedgerLedgerStatusSetInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** update columns of table "ledger.ledger_status" */
export enum LedgerLedgerStatusUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** aggregate stddev on columns */
export type LedgerLedgerStddevFields = {
  __typename?: 'ledger_ledger_stddev_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ledger.ledger" */
export type LedgerLedgerStddevOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LedgerLedgerStddevPopFields = {
  __typename?: 'ledger_ledger_stddev_pop_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ledger.ledger" */
export type LedgerLedgerStddevPopOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LedgerLedgerStddevSampFields = {
  __typename?: 'ledger_ledger_stddev_samp_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ledger.ledger" */
export type LedgerLedgerStddevSampOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LedgerLedgerSumFields = {
  __typename?: 'ledger_ledger_sum_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['numeric']>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ledger.ledger" */
export type LedgerLedgerSumOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year?: Maybe<OrderBy>;
};

/**
 * ประเภทค่าใช้จ่าย
 *
 *
 * columns and relationships of "ledger.ledger_type"
 */
export type LedgerLedgerType = {
  __typename?: 'ledger_ledger_type';
  description: Scalars['String'];
  id: Scalars['String'];
  /** An array relationship */
  ledgers: Array<LedgerLedger>;
  /** An aggregate relationship */
  ledgers_aggregate: LedgerLedgerAggregate;
};


/**
 * ประเภทค่าใช้จ่าย
 *
 *
 * columns and relationships of "ledger.ledger_type"
 */
export type LedgerLedgerTypeLedgersArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerOrderBy>>;
  where?: Maybe<LedgerLedgerBoolExp>;
};


/**
 * ประเภทค่าใช้จ่าย
 *
 *
 * columns and relationships of "ledger.ledger_type"
 */
export type LedgerLedgerTypeLedgersAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerOrderBy>>;
  where?: Maybe<LedgerLedgerBoolExp>;
};

/** aggregated selection of "ledger.ledger_type" */
export type LedgerLedgerTypeAggregate = {
  __typename?: 'ledger_ledger_type_aggregate';
  aggregate?: Maybe<LedgerLedgerTypeAggregateFields>;
  nodes: Array<LedgerLedgerType>;
};

/** aggregate fields of "ledger.ledger_type" */
export type LedgerLedgerTypeAggregateFields = {
  __typename?: 'ledger_ledger_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LedgerLedgerTypeMaxFields>;
  min?: Maybe<LedgerLedgerTypeMinFields>;
};


/** aggregate fields of "ledger.ledger_type" */
export type LedgerLedgerTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LedgerLedgerTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ledger.ledger_type". All fields are combined with a logical 'AND'. */
export type LedgerLedgerTypeBoolExp = {
  _and?: Maybe<Array<LedgerLedgerTypeBoolExp>>;
  _not?: Maybe<LedgerLedgerTypeBoolExp>;
  _or?: Maybe<Array<LedgerLedgerTypeBoolExp>>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  ledgers?: Maybe<LedgerLedgerBoolExp>;
};

/** unique or primary key constraints on table "ledger.ledger_type" */
export enum LedgerLedgerTypeConstraint {
  /** unique or primary key constraint */
  LedgetTypePkey = 'ledget_type_pkey'
}

export enum LedgerLedgerTypeEnum {
  /** หักล้างเงินยืม : ค่าใช้จ่ายที่เกิดขึ้นจริง */
  Debt = 'debt',
  /** ค่าใช้จ่าย */
  Expense = 'expense'
}

/** Boolean expression to compare columns of type "ledger_ledger_type_enum". All fields are combined with logical 'AND'. */
export type LedgerLedgerTypeEnumComparisonExp = {
  _eq?: Maybe<LedgerLedgerTypeEnum>;
  _in?: Maybe<Array<LedgerLedgerTypeEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<LedgerLedgerTypeEnum>;
  _nin?: Maybe<Array<LedgerLedgerTypeEnum>>;
};

/** input type for inserting data into table "ledger.ledger_type" */
export type LedgerLedgerTypeInsertInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ledgers?: Maybe<LedgerLedgerArrRelInsertInput>;
};

/** aggregate max on columns */
export type LedgerLedgerTypeMaxFields = {
  __typename?: 'ledger_ledger_type_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type LedgerLedgerTypeMinFields = {
  __typename?: 'ledger_ledger_type_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ledger.ledger_type" */
export type LedgerLedgerTypeMutationResponse = {
  __typename?: 'ledger_ledger_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LedgerLedgerType>;
};

/** input type for inserting object relation for remote table "ledger.ledger_type" */
export type LedgerLedgerTypeObjRelInsertInput = {
  data: LedgerLedgerTypeInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LedgerLedgerTypeOnConflict>;
};

/** on conflict condition type for table "ledger.ledger_type" */
export type LedgerLedgerTypeOnConflict = {
  constraint: LedgerLedgerTypeConstraint;
  update_columns?: Array<LedgerLedgerTypeUpdateColumn>;
  where?: Maybe<LedgerLedgerTypeBoolExp>;
};

/** Ordering options when selecting data from "ledger.ledger_type". */
export type LedgerLedgerTypeOrderBy = {
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ledgers_aggregate?: Maybe<LedgerLedgerAggregateOrderBy>;
};

/** primary key columns input for table: ledger_ledger_type */
export type LedgerLedgerTypePkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "ledger.ledger_type" */
export enum LedgerLedgerTypeSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ledger.ledger_type" */
export type LedgerLedgerTypeSetInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** update columns of table "ledger.ledger_type" */
export enum LedgerLedgerTypeUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** update columns of table "ledger.ledger" */
export enum LedgerLedgerUpdateColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  ApprovedBy = 'approved_by',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  ChangeRequestAt = 'change_request_at',
  /** column name */
  ChangeRequestBy = 'change_request_by',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Date = 'date',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  GfmisId = 'gfmis_id',
  /** column name */
  Id = 'id',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  LockedBy = 'locked_by',
  /** column name */
  Note = 'note',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ReceivableId = 'receivable_id',
  /** column name */
  RejectedAt = 'rejected_at',
  /** column name */
  RejectedBy = 'rejected_by',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  SubmittedAt = 'submitted_at',
  /** column name */
  SubmittedBy = 'submitted_by',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type LedgerLedgerVarPopFields = {
  __typename?: 'ledger_ledger_var_pop_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ledger.ledger" */
export type LedgerLedgerVarPopOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LedgerLedgerVarSampFields = {
  __typename?: 'ledger_ledger_var_samp_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ledger.ledger" */
export type LedgerLedgerVarSampOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LedgerLedgerVarianceFields = {
  __typename?: 'ledger_ledger_variance_fields';
  /** จำนวนเงิน */
  amount?: Maybe<Scalars['Float']>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ledger.ledger" */
export type LedgerLedgerVarianceOrderBy = {
  /** จำนวนเงิน */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ (พ​.ศ) */
  fiscal_year?: Maybe<OrderBy>;
};

/** columns and relationships of "ledger.receivable" */
export type LedgerReceivable = {
  __typename?: 'ledger_receivable';
  /** จำนวนเงินยืม */
  amount: Scalars['numeric'];
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  approved_by_user?: Maybe<Users>;
  /** ชื่อผู้ยืม */
  borrower_name: Scalars['String'];
  /** An object relationship */
  budget?: Maybe<AccountBudgetType>;
  /** งบประมาณ */
  budget_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  created_by_user?: Maybe<Users>;
  /** วันที่สร้างรายการ */
  date: Scalars['date'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลูกหนี้คืนเงินยืมเสร็จสิ้น NULL = ยังไม่เสร็จ */
  done_at?: Maybe<Scalars['timestamptz']>;
  done_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  done_by_user?: Maybe<Users>;
  /** วันที่ครบกำหนด */
  due_date: Scalars['date'];
  /** An object relationship */
  expense_account?: Maybe<AccountAccount>;
  /** บัญชีค่าใช้จ่าย */
  expense_account_id?: Maybe<Scalars['uuid']>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year: Scalars['Int'];
  /** เลขที่ขอเบิก ในระบบ GFMIS */
  gfmis_id: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  ledgers: Array<LedgerLedger>;
  /** An aggregate relationship */
  ledgers_aggregate: LedgerLedgerAggregate;
  locked_at?: Maybe<Scalars['timestamptz']>;
  locked_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  locked_by_user?: Maybe<Users>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  /** จุดประสงค์ */
  proposal: Scalars['String'];
  /** An array relationship */
  receivable_items: Array<LedgerReceivableItem>;
  /** An aggregate relationship */
  receivable_items_aggregate: LedgerReceivableItemAggregate;
  /** An array relationship */
  receivable_logs: Array<LedgerReceivableLog>;
  /** An aggregate relationship */
  receivable_logs_aggregate: LedgerReceivableLogAggregate;
  /** วันที่ส่งใช้ */
  received_date?: Maybe<Scalars['date']>;
  reference: Scalars['String'];
  refund_amount?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  status: LedgerReceivableStatus;
  status_id: LedgerReceivableStatusEnum;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  submitted_by?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  updated_by_user?: Maybe<Users>;
};


/** columns and relationships of "ledger.receivable" */
export type LedgerReceivableLedgersArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerOrderBy>>;
  where?: Maybe<LedgerLedgerBoolExp>;
};


/** columns and relationships of "ledger.receivable" */
export type LedgerReceivableLedgersAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerOrderBy>>;
  where?: Maybe<LedgerLedgerBoolExp>;
};


/** columns and relationships of "ledger.receivable" */
export type LedgerReceivableReceivableItemsArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableItemOrderBy>>;
  where?: Maybe<LedgerReceivableItemBoolExp>;
};


/** columns and relationships of "ledger.receivable" */
export type LedgerReceivableReceivableItemsAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableItemOrderBy>>;
  where?: Maybe<LedgerReceivableItemBoolExp>;
};


/** columns and relationships of "ledger.receivable" */
export type LedgerReceivableReceivableLogsArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableLogOrderBy>>;
  where?: Maybe<LedgerReceivableLogBoolExp>;
};


/** columns and relationships of "ledger.receivable" */
export type LedgerReceivableReceivableLogsAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableLogOrderBy>>;
  where?: Maybe<LedgerReceivableLogBoolExp>;
};

/** aggregated selection of "ledger.receivable" */
export type LedgerReceivableAggregate = {
  __typename?: 'ledger_receivable_aggregate';
  aggregate?: Maybe<LedgerReceivableAggregateFields>;
  nodes: Array<LedgerReceivable>;
};

/** aggregate fields of "ledger.receivable" */
export type LedgerReceivableAggregateFields = {
  __typename?: 'ledger_receivable_aggregate_fields';
  avg?: Maybe<LedgerReceivableAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LedgerReceivableMaxFields>;
  min?: Maybe<LedgerReceivableMinFields>;
  stddev?: Maybe<LedgerReceivableStddevFields>;
  stddev_pop?: Maybe<LedgerReceivableStddevPopFields>;
  stddev_samp?: Maybe<LedgerReceivableStddevSampFields>;
  sum?: Maybe<LedgerReceivableSumFields>;
  var_pop?: Maybe<LedgerReceivableVarPopFields>;
  var_samp?: Maybe<LedgerReceivableVarSampFields>;
  variance?: Maybe<LedgerReceivableVarianceFields>;
};


/** aggregate fields of "ledger.receivable" */
export type LedgerReceivableAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LedgerReceivableSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ledger.receivable" */
export type LedgerReceivableAggregateOrderBy = {
  avg?: Maybe<LedgerReceivableAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LedgerReceivableMaxOrderBy>;
  min?: Maybe<LedgerReceivableMinOrderBy>;
  stddev?: Maybe<LedgerReceivableStddevOrderBy>;
  stddev_pop?: Maybe<LedgerReceivableStddevPopOrderBy>;
  stddev_samp?: Maybe<LedgerReceivableStddevSampOrderBy>;
  sum?: Maybe<LedgerReceivableSumOrderBy>;
  var_pop?: Maybe<LedgerReceivableVarPopOrderBy>;
  var_samp?: Maybe<LedgerReceivableVarSampOrderBy>;
  variance?: Maybe<LedgerReceivableVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "ledger.receivable" */
export type LedgerReceivableArrRelInsertInput = {
  data: Array<LedgerReceivableInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LedgerReceivableOnConflict>;
};

/** aggregate avg on columns */
export type LedgerReceivableAvgFields = {
  __typename?: 'ledger_receivable_avg_fields';
  /** จำนวนเงินยืม */
  amount?: Maybe<Scalars['Float']>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year?: Maybe<Scalars['Float']>;
  refund_amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ledger.receivable" */
export type LedgerReceivableAvgOrderBy = {
  /** จำนวนเงินยืม */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year?: Maybe<OrderBy>;
  refund_amount?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "ledger.receivable". All fields are combined with a logical 'AND'. */
export type LedgerReceivableBoolExp = {
  _and?: Maybe<Array<LedgerReceivableBoolExp>>;
  _not?: Maybe<LedgerReceivableBoolExp>;
  _or?: Maybe<Array<LedgerReceivableBoolExp>>;
  amount?: Maybe<NumericComparisonExp>;
  approved_at?: Maybe<TimestamptzComparisonExp>;
  approved_by?: Maybe<UuidComparisonExp>;
  approved_by_user?: Maybe<UsersBoolExp>;
  borrower_name?: Maybe<StringComparisonExp>;
  budget?: Maybe<AccountBudgetTypeBoolExp>;
  budget_id?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  created_by_user?: Maybe<UsersBoolExp>;
  date?: Maybe<DateComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  done_at?: Maybe<TimestamptzComparisonExp>;
  done_by?: Maybe<UuidComparisonExp>;
  done_by_user?: Maybe<UsersBoolExp>;
  due_date?: Maybe<DateComparisonExp>;
  expense_account?: Maybe<AccountAccountBoolExp>;
  expense_account_id?: Maybe<UuidComparisonExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
  gfmis_id?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  ledgers?: Maybe<LedgerLedgerBoolExp>;
  locked_at?: Maybe<TimestamptzComparisonExp>;
  locked_by?: Maybe<UuidComparisonExp>;
  locked_by_user?: Maybe<UsersBoolExp>;
  note?: Maybe<StringComparisonExp>;
  organization?: Maybe<OrganizationBoolExp>;
  organization_id?: Maybe<UuidComparisonExp>;
  proposal?: Maybe<StringComparisonExp>;
  receivable_items?: Maybe<LedgerReceivableItemBoolExp>;
  receivable_logs?: Maybe<LedgerReceivableLogBoolExp>;
  received_date?: Maybe<DateComparisonExp>;
  reference?: Maybe<StringComparisonExp>;
  refund_amount?: Maybe<NumericComparisonExp>;
  status?: Maybe<LedgerReceivableStatusBoolExp>;
  status_id?: Maybe<LedgerReceivableStatusEnumComparisonExp>;
  submitted_at?: Maybe<TimestamptzComparisonExp>;
  submitted_by?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<UuidComparisonExp>;
  updated_by_user?: Maybe<UsersBoolExp>;
};

/** unique or primary key constraints on table "ledger.receivable" */
export enum LedgerReceivableConstraint {
  /** unique or primary key constraint */
  ReceivablePkey = 'receivable_pkey'
}

/** input type for incrementing numeric columns in table "ledger.receivable" */
export type LedgerReceivableIncInput = {
  /** จำนวนเงินยืม */
  amount?: Maybe<Scalars['numeric']>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year?: Maybe<Scalars['Int']>;
  refund_amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "ledger.receivable" */
export type LedgerReceivableInsertInput = {
  /** จำนวนเงินยืม */
  amount?: Maybe<Scalars['numeric']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['uuid']>;
  approved_by_user?: Maybe<UsersObjRelInsertInput>;
  /** ชื่อผู้ยืม */
  borrower_name?: Maybe<Scalars['String']>;
  budget?: Maybe<AccountBudgetTypeObjRelInsertInput>;
  /** งบประมาณ */
  budget_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  created_by_user?: Maybe<UsersObjRelInsertInput>;
  /** วันที่สร้างรายการ */
  date?: Maybe<Scalars['date']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลูกหนี้คืนเงินยืมเสร็จสิ้น NULL = ยังไม่เสร็จ */
  done_at?: Maybe<Scalars['timestamptz']>;
  done_by?: Maybe<Scalars['uuid']>;
  done_by_user?: Maybe<UsersObjRelInsertInput>;
  /** วันที่ครบกำหนด */
  due_date?: Maybe<Scalars['date']>;
  expense_account?: Maybe<AccountAccountObjRelInsertInput>;
  /** บัญชีค่าใช้จ่าย */
  expense_account_id?: Maybe<Scalars['uuid']>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** เลขที่ขอเบิก ในระบบ GFMIS */
  gfmis_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ledgers?: Maybe<LedgerLedgerArrRelInsertInput>;
  locked_at?: Maybe<Scalars['timestamptz']>;
  locked_by?: Maybe<Scalars['uuid']>;
  locked_by_user?: Maybe<UsersObjRelInsertInput>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationObjRelInsertInput>;
  organization_id?: Maybe<Scalars['uuid']>;
  /** จุดประสงค์ */
  proposal?: Maybe<Scalars['String']>;
  receivable_items?: Maybe<LedgerReceivableItemArrRelInsertInput>;
  receivable_logs?: Maybe<LedgerReceivableLogArrRelInsertInput>;
  /** วันที่ส่งใช้ */
  received_date?: Maybe<Scalars['date']>;
  reference?: Maybe<Scalars['String']>;
  refund_amount?: Maybe<Scalars['numeric']>;
  status?: Maybe<LedgerReceivableStatusObjRelInsertInput>;
  status_id?: Maybe<LedgerReceivableStatusEnum>;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  submitted_by?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
  updated_by_user?: Maybe<UsersObjRelInsertInput>;
};

/** columns and relationships of "ledger.receivable_item" */
export type LedgerReceivableItem = {
  __typename?: 'ledger_receivable_item';
  amount: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  date: Scalars['date'];
  id: Scalars['uuid'];
  locked_at?: Maybe<Scalars['timestamptz']>;
  locked_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  locked_by_user?: Maybe<Users>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  /** An object relationship */
  receivable: LedgerReceivable;
  receivable_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['uuid'];
};

/** aggregated selection of "ledger.receivable_item" */
export type LedgerReceivableItemAggregate = {
  __typename?: 'ledger_receivable_item_aggregate';
  aggregate?: Maybe<LedgerReceivableItemAggregateFields>;
  nodes: Array<LedgerReceivableItem>;
};

/** aggregate fields of "ledger.receivable_item" */
export type LedgerReceivableItemAggregateFields = {
  __typename?: 'ledger_receivable_item_aggregate_fields';
  avg?: Maybe<LedgerReceivableItemAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LedgerReceivableItemMaxFields>;
  min?: Maybe<LedgerReceivableItemMinFields>;
  stddev?: Maybe<LedgerReceivableItemStddevFields>;
  stddev_pop?: Maybe<LedgerReceivableItemStddevPopFields>;
  stddev_samp?: Maybe<LedgerReceivableItemStddevSampFields>;
  sum?: Maybe<LedgerReceivableItemSumFields>;
  var_pop?: Maybe<LedgerReceivableItemVarPopFields>;
  var_samp?: Maybe<LedgerReceivableItemVarSampFields>;
  variance?: Maybe<LedgerReceivableItemVarianceFields>;
};


/** aggregate fields of "ledger.receivable_item" */
export type LedgerReceivableItemAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LedgerReceivableItemSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ledger.receivable_item" */
export type LedgerReceivableItemAggregateOrderBy = {
  avg?: Maybe<LedgerReceivableItemAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LedgerReceivableItemMaxOrderBy>;
  min?: Maybe<LedgerReceivableItemMinOrderBy>;
  stddev?: Maybe<LedgerReceivableItemStddevOrderBy>;
  stddev_pop?: Maybe<LedgerReceivableItemStddevPopOrderBy>;
  stddev_samp?: Maybe<LedgerReceivableItemStddevSampOrderBy>;
  sum?: Maybe<LedgerReceivableItemSumOrderBy>;
  var_pop?: Maybe<LedgerReceivableItemVarPopOrderBy>;
  var_samp?: Maybe<LedgerReceivableItemVarSampOrderBy>;
  variance?: Maybe<LedgerReceivableItemVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "ledger.receivable_item" */
export type LedgerReceivableItemArrRelInsertInput = {
  data: Array<LedgerReceivableItemInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LedgerReceivableItemOnConflict>;
};

/** aggregate avg on columns */
export type LedgerReceivableItemAvgFields = {
  __typename?: 'ledger_receivable_item_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ledger.receivable_item" */
export type LedgerReceivableItemAvgOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "ledger.receivable_item". All fields are combined with a logical 'AND'. */
export type LedgerReceivableItemBoolExp = {
  _and?: Maybe<Array<LedgerReceivableItemBoolExp>>;
  _not?: Maybe<LedgerReceivableItemBoolExp>;
  _or?: Maybe<Array<LedgerReceivableItemBoolExp>>;
  amount?: Maybe<NumericComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  date?: Maybe<DateComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  locked_at?: Maybe<TimestamptzComparisonExp>;
  locked_by?: Maybe<UuidComparisonExp>;
  locked_by_user?: Maybe<UsersBoolExp>;
  name?: Maybe<StringComparisonExp>;
  note?: Maybe<StringComparisonExp>;
  receivable?: Maybe<LedgerReceivableBoolExp>;
  receivable_id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "ledger.receivable_item" */
export enum LedgerReceivableItemConstraint {
  /** unique or primary key constraint */
  ReceivableItemPkey = 'receivable_item_pkey'
}

/** input type for incrementing numeric columns in table "ledger.receivable_item" */
export type LedgerReceivableItemIncInput = {
  amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "ledger.receivable_item" */
export type LedgerReceivableItemInsertInput = {
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locked_at?: Maybe<Scalars['timestamptz']>;
  locked_by?: Maybe<Scalars['uuid']>;
  locked_by_user?: Maybe<UsersObjRelInsertInput>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  receivable?: Maybe<LedgerReceivableObjRelInsertInput>;
  receivable_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LedgerReceivableItemMaxFields = {
  __typename?: 'ledger_receivable_item_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locked_at?: Maybe<Scalars['timestamptz']>;
  locked_by?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  receivable_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "ledger.receivable_item" */
export type LedgerReceivableItemMaxOrderBy = {
  amount?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  locked_at?: Maybe<OrderBy>;
  locked_by?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  receivable_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LedgerReceivableItemMinFields = {
  __typename?: 'ledger_receivable_item_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locked_at?: Maybe<Scalars['timestamptz']>;
  locked_by?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  receivable_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "ledger.receivable_item" */
export type LedgerReceivableItemMinOrderBy = {
  amount?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  locked_at?: Maybe<OrderBy>;
  locked_by?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  receivable_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "ledger.receivable_item" */
export type LedgerReceivableItemMutationResponse = {
  __typename?: 'ledger_receivable_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LedgerReceivableItem>;
};

/** on conflict condition type for table "ledger.receivable_item" */
export type LedgerReceivableItemOnConflict = {
  constraint: LedgerReceivableItemConstraint;
  update_columns?: Array<LedgerReceivableItemUpdateColumn>;
  where?: Maybe<LedgerReceivableItemBoolExp>;
};

/** Ordering options when selecting data from "ledger.receivable_item". */
export type LedgerReceivableItemOrderBy = {
  amount?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  locked_at?: Maybe<OrderBy>;
  locked_by?: Maybe<OrderBy>;
  locked_by_user?: Maybe<UsersOrderBy>;
  name?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  receivable?: Maybe<LedgerReceivableOrderBy>;
  receivable_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** primary key columns input for table: ledger_receivable_item */
export type LedgerReceivableItemPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "ledger.receivable_item" */
export enum LedgerReceivableItemSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  LockedBy = 'locked_by',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  ReceivableId = 'receivable_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "ledger.receivable_item" */
export type LedgerReceivableItemSetInput = {
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  locked_at?: Maybe<Scalars['timestamptz']>;
  locked_by?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  receivable_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type LedgerReceivableItemStddevFields = {
  __typename?: 'ledger_receivable_item_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ledger.receivable_item" */
export type LedgerReceivableItemStddevOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LedgerReceivableItemStddevPopFields = {
  __typename?: 'ledger_receivable_item_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ledger.receivable_item" */
export type LedgerReceivableItemStddevPopOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LedgerReceivableItemStddevSampFields = {
  __typename?: 'ledger_receivable_item_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ledger.receivable_item" */
export type LedgerReceivableItemStddevSampOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LedgerReceivableItemSumFields = {
  __typename?: 'ledger_receivable_item_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "ledger.receivable_item" */
export type LedgerReceivableItemSumOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** update columns of table "ledger.receivable_item" */
export enum LedgerReceivableItemUpdateColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  LockedBy = 'locked_by',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  ReceivableId = 'receivable_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type LedgerReceivableItemVarPopFields = {
  __typename?: 'ledger_receivable_item_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ledger.receivable_item" */
export type LedgerReceivableItemVarPopOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LedgerReceivableItemVarSampFields = {
  __typename?: 'ledger_receivable_item_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ledger.receivable_item" */
export type LedgerReceivableItemVarSampOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LedgerReceivableItemVarianceFields = {
  __typename?: 'ledger_receivable_item_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ledger.receivable_item" */
export type LedgerReceivableItemVarianceOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** columns and relationships of "ledger.receivable_log" */
export type LedgerReceivableLog = {
  __typename?: 'ledger_receivable_log';
  action: Scalars['String'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  /** An object relationship */
  created_by_user: Users;
  description: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  new_status: LedgerReceivableStatus;
  new_status_id: LedgerReceivableStatusEnum;
  /** An object relationship */
  old_status: LedgerReceivableStatus;
  old_status_id: LedgerReceivableStatusEnum;
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['uuid'];
  /** An object relationship */
  receivable: LedgerReceivable;
  receivable_id: Scalars['uuid'];
};

/** aggregated selection of "ledger.receivable_log" */
export type LedgerReceivableLogAggregate = {
  __typename?: 'ledger_receivable_log_aggregate';
  aggregate?: Maybe<LedgerReceivableLogAggregateFields>;
  nodes: Array<LedgerReceivableLog>;
};

/** aggregate fields of "ledger.receivable_log" */
export type LedgerReceivableLogAggregateFields = {
  __typename?: 'ledger_receivable_log_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LedgerReceivableLogMaxFields>;
  min?: Maybe<LedgerReceivableLogMinFields>;
};


/** aggregate fields of "ledger.receivable_log" */
export type LedgerReceivableLogAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LedgerReceivableLogSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ledger.receivable_log" */
export type LedgerReceivableLogAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<LedgerReceivableLogMaxOrderBy>;
  min?: Maybe<LedgerReceivableLogMinOrderBy>;
};

/** input type for inserting array relation for remote table "ledger.receivable_log" */
export type LedgerReceivableLogArrRelInsertInput = {
  data: Array<LedgerReceivableLogInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LedgerReceivableLogOnConflict>;
};

/** Boolean expression to filter rows from the table "ledger.receivable_log". All fields are combined with a logical 'AND'. */
export type LedgerReceivableLogBoolExp = {
  _and?: Maybe<Array<LedgerReceivableLogBoolExp>>;
  _not?: Maybe<LedgerReceivableLogBoolExp>;
  _or?: Maybe<Array<LedgerReceivableLogBoolExp>>;
  action?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  created_by_user?: Maybe<UsersBoolExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  new_status?: Maybe<LedgerReceivableStatusBoolExp>;
  new_status_id?: Maybe<LedgerReceivableStatusEnumComparisonExp>;
  old_status?: Maybe<LedgerReceivableStatusBoolExp>;
  old_status_id?: Maybe<LedgerReceivableStatusEnumComparisonExp>;
  organization?: Maybe<OrganizationBoolExp>;
  organization_id?: Maybe<UuidComparisonExp>;
  receivable?: Maybe<LedgerReceivableBoolExp>;
  receivable_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "ledger.receivable_log" */
export enum LedgerReceivableLogConstraint {
  /** unique or primary key constraint */
  ReceivableLogPkey = 'receivable_log_pkey'
}

/** input type for inserting data into table "ledger.receivable_log" */
export type LedgerReceivableLogInsertInput = {
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  created_by_user?: Maybe<UsersObjRelInsertInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  new_status?: Maybe<LedgerReceivableStatusObjRelInsertInput>;
  new_status_id?: Maybe<LedgerReceivableStatusEnum>;
  old_status?: Maybe<LedgerReceivableStatusObjRelInsertInput>;
  old_status_id?: Maybe<LedgerReceivableStatusEnum>;
  organization?: Maybe<OrganizationObjRelInsertInput>;
  organization_id?: Maybe<Scalars['uuid']>;
  receivable?: Maybe<LedgerReceivableObjRelInsertInput>;
  receivable_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LedgerReceivableLogMaxFields = {
  __typename?: 'ledger_receivable_log_max_fields';
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  receivable_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "ledger.receivable_log" */
export type LedgerReceivableLogMaxOrderBy = {
  action?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  receivable_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LedgerReceivableLogMinFields = {
  __typename?: 'ledger_receivable_log_min_fields';
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  receivable_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "ledger.receivable_log" */
export type LedgerReceivableLogMinOrderBy = {
  action?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  receivable_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "ledger.receivable_log" */
export type LedgerReceivableLogMutationResponse = {
  __typename?: 'ledger_receivable_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LedgerReceivableLog>;
};

/** on conflict condition type for table "ledger.receivable_log" */
export type LedgerReceivableLogOnConflict = {
  constraint: LedgerReceivableLogConstraint;
  update_columns?: Array<LedgerReceivableLogUpdateColumn>;
  where?: Maybe<LedgerReceivableLogBoolExp>;
};

/** Ordering options when selecting data from "ledger.receivable_log". */
export type LedgerReceivableLogOrderBy = {
  action?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  created_by_user?: Maybe<UsersOrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  new_status?: Maybe<LedgerReceivableStatusOrderBy>;
  new_status_id?: Maybe<OrderBy>;
  old_status?: Maybe<LedgerReceivableStatusOrderBy>;
  old_status_id?: Maybe<OrderBy>;
  organization?: Maybe<OrganizationOrderBy>;
  organization_id?: Maybe<OrderBy>;
  receivable?: Maybe<LedgerReceivableOrderBy>;
  receivable_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: ledger_receivable_log */
export type LedgerReceivableLogPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "ledger.receivable_log" */
export enum LedgerReceivableLogSelectColumn {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  NewStatusId = 'new_status_id',
  /** column name */
  OldStatusId = 'old_status_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ReceivableId = 'receivable_id'
}

/** input type for updating data in table "ledger.receivable_log" */
export type LedgerReceivableLogSetInput = {
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  new_status_id?: Maybe<LedgerReceivableStatusEnum>;
  old_status_id?: Maybe<LedgerReceivableStatusEnum>;
  organization_id?: Maybe<Scalars['uuid']>;
  receivable_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "ledger.receivable_log" */
export enum LedgerReceivableLogUpdateColumn {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  NewStatusId = 'new_status_id',
  /** column name */
  OldStatusId = 'old_status_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ReceivableId = 'receivable_id'
}

/** aggregate max on columns */
export type LedgerReceivableMaxFields = {
  __typename?: 'ledger_receivable_max_fields';
  /** จำนวนเงินยืม */
  amount?: Maybe<Scalars['numeric']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['uuid']>;
  /** ชื่อผู้ยืม */
  borrower_name?: Maybe<Scalars['String']>;
  /** งบประมาณ */
  budget_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  /** วันที่สร้างรายการ */
  date?: Maybe<Scalars['date']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลูกหนี้คืนเงินยืมเสร็จสิ้น NULL = ยังไม่เสร็จ */
  done_at?: Maybe<Scalars['timestamptz']>;
  done_by?: Maybe<Scalars['uuid']>;
  /** วันที่ครบกำหนด */
  due_date?: Maybe<Scalars['date']>;
  /** บัญชีค่าใช้จ่าย */
  expense_account_id?: Maybe<Scalars['uuid']>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** เลขที่ขอเบิก ในระบบ GFMIS */
  gfmis_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locked_at?: Maybe<Scalars['timestamptz']>;
  locked_by?: Maybe<Scalars['uuid']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  /** จุดประสงค์ */
  proposal?: Maybe<Scalars['String']>;
  /** วันที่ส่งใช้ */
  received_date?: Maybe<Scalars['date']>;
  reference?: Maybe<Scalars['String']>;
  refund_amount?: Maybe<Scalars['numeric']>;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  submitted_by?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "ledger.receivable" */
export type LedgerReceivableMaxOrderBy = {
  /** จำนวนเงินยืม */
  amount?: Maybe<OrderBy>;
  approved_at?: Maybe<OrderBy>;
  approved_by?: Maybe<OrderBy>;
  /** ชื่อผู้ยืม */
  borrower_name?: Maybe<OrderBy>;
  /** งบประมาณ */
  budget_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  /** วันที่สร้างรายการ */
  date?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  /** วันที่ลูกหนี้คืนเงินยืมเสร็จสิ้น NULL = ยังไม่เสร็จ */
  done_at?: Maybe<OrderBy>;
  done_by?: Maybe<OrderBy>;
  /** วันที่ครบกำหนด */
  due_date?: Maybe<OrderBy>;
  /** บัญชีค่าใช้จ่าย */
  expense_account_id?: Maybe<OrderBy>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year?: Maybe<OrderBy>;
  /** เลขที่ขอเบิก ในระบบ GFMIS */
  gfmis_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  locked_at?: Maybe<OrderBy>;
  locked_by?: Maybe<OrderBy>;
  /** หมายเหตุ */
  note?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  /** จุดประสงค์ */
  proposal?: Maybe<OrderBy>;
  /** วันที่ส่งใช้ */
  received_date?: Maybe<OrderBy>;
  reference?: Maybe<OrderBy>;
  refund_amount?: Maybe<OrderBy>;
  submitted_at?: Maybe<OrderBy>;
  submitted_by?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LedgerReceivableMinFields = {
  __typename?: 'ledger_receivable_min_fields';
  /** จำนวนเงินยืม */
  amount?: Maybe<Scalars['numeric']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['uuid']>;
  /** ชื่อผู้ยืม */
  borrower_name?: Maybe<Scalars['String']>;
  /** งบประมาณ */
  budget_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  /** วันที่สร้างรายการ */
  date?: Maybe<Scalars['date']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลูกหนี้คืนเงินยืมเสร็จสิ้น NULL = ยังไม่เสร็จ */
  done_at?: Maybe<Scalars['timestamptz']>;
  done_by?: Maybe<Scalars['uuid']>;
  /** วันที่ครบกำหนด */
  due_date?: Maybe<Scalars['date']>;
  /** บัญชีค่าใช้จ่าย */
  expense_account_id?: Maybe<Scalars['uuid']>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** เลขที่ขอเบิก ในระบบ GFMIS */
  gfmis_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locked_at?: Maybe<Scalars['timestamptz']>;
  locked_by?: Maybe<Scalars['uuid']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  /** จุดประสงค์ */
  proposal?: Maybe<Scalars['String']>;
  /** วันที่ส่งใช้ */
  received_date?: Maybe<Scalars['date']>;
  reference?: Maybe<Scalars['String']>;
  refund_amount?: Maybe<Scalars['numeric']>;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  submitted_by?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "ledger.receivable" */
export type LedgerReceivableMinOrderBy = {
  /** จำนวนเงินยืม */
  amount?: Maybe<OrderBy>;
  approved_at?: Maybe<OrderBy>;
  approved_by?: Maybe<OrderBy>;
  /** ชื่อผู้ยืม */
  borrower_name?: Maybe<OrderBy>;
  /** งบประมาณ */
  budget_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  /** วันที่สร้างรายการ */
  date?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  /** วันที่ลูกหนี้คืนเงินยืมเสร็จสิ้น NULL = ยังไม่เสร็จ */
  done_at?: Maybe<OrderBy>;
  done_by?: Maybe<OrderBy>;
  /** วันที่ครบกำหนด */
  due_date?: Maybe<OrderBy>;
  /** บัญชีค่าใช้จ่าย */
  expense_account_id?: Maybe<OrderBy>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year?: Maybe<OrderBy>;
  /** เลขที่ขอเบิก ในระบบ GFMIS */
  gfmis_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  locked_at?: Maybe<OrderBy>;
  locked_by?: Maybe<OrderBy>;
  /** หมายเหตุ */
  note?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  /** จุดประสงค์ */
  proposal?: Maybe<OrderBy>;
  /** วันที่ส่งใช้ */
  received_date?: Maybe<OrderBy>;
  reference?: Maybe<OrderBy>;
  refund_amount?: Maybe<OrderBy>;
  submitted_at?: Maybe<OrderBy>;
  submitted_by?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "ledger.receivable" */
export type LedgerReceivableMutationResponse = {
  __typename?: 'ledger_receivable_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LedgerReceivable>;
};

/** input type for inserting object relation for remote table "ledger.receivable" */
export type LedgerReceivableObjRelInsertInput = {
  data: LedgerReceivableInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LedgerReceivableOnConflict>;
};

/** on conflict condition type for table "ledger.receivable" */
export type LedgerReceivableOnConflict = {
  constraint: LedgerReceivableConstraint;
  update_columns?: Array<LedgerReceivableUpdateColumn>;
  where?: Maybe<LedgerReceivableBoolExp>;
};

/** Ordering options when selecting data from "ledger.receivable". */
export type LedgerReceivableOrderBy = {
  amount?: Maybe<OrderBy>;
  approved_at?: Maybe<OrderBy>;
  approved_by?: Maybe<OrderBy>;
  approved_by_user?: Maybe<UsersOrderBy>;
  borrower_name?: Maybe<OrderBy>;
  budget?: Maybe<AccountBudgetTypeOrderBy>;
  budget_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  created_by_user?: Maybe<UsersOrderBy>;
  date?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  done_at?: Maybe<OrderBy>;
  done_by?: Maybe<OrderBy>;
  done_by_user?: Maybe<UsersOrderBy>;
  due_date?: Maybe<OrderBy>;
  expense_account?: Maybe<AccountAccountOrderBy>;
  expense_account_id?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  gfmis_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ledgers_aggregate?: Maybe<LedgerLedgerAggregateOrderBy>;
  locked_at?: Maybe<OrderBy>;
  locked_by?: Maybe<OrderBy>;
  locked_by_user?: Maybe<UsersOrderBy>;
  note?: Maybe<OrderBy>;
  organization?: Maybe<OrganizationOrderBy>;
  organization_id?: Maybe<OrderBy>;
  proposal?: Maybe<OrderBy>;
  receivable_items_aggregate?: Maybe<LedgerReceivableItemAggregateOrderBy>;
  receivable_logs_aggregate?: Maybe<LedgerReceivableLogAggregateOrderBy>;
  received_date?: Maybe<OrderBy>;
  reference?: Maybe<OrderBy>;
  refund_amount?: Maybe<OrderBy>;
  status?: Maybe<LedgerReceivableStatusOrderBy>;
  status_id?: Maybe<OrderBy>;
  submitted_at?: Maybe<OrderBy>;
  submitted_by?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
  updated_by_user?: Maybe<UsersOrderBy>;
};

/** primary key columns input for table: ledger_receivable */
export type LedgerReceivablePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "ledger.receivable" */
export enum LedgerReceivableSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  ApprovedBy = 'approved_by',
  /** column name */
  BorrowerName = 'borrower_name',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Date = 'date',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DoneAt = 'done_at',
  /** column name */
  DoneBy = 'done_by',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  ExpenseAccountId = 'expense_account_id',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  GfmisId = 'gfmis_id',
  /** column name */
  Id = 'id',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  LockedBy = 'locked_by',
  /** column name */
  Note = 'note',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Proposal = 'proposal',
  /** column name */
  ReceivedDate = 'received_date',
  /** column name */
  Reference = 'reference',
  /** column name */
  RefundAmount = 'refund_amount',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  SubmittedAt = 'submitted_at',
  /** column name */
  SubmittedBy = 'submitted_by',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "ledger.receivable" */
export type LedgerReceivableSetInput = {
  /** จำนวนเงินยืม */
  amount?: Maybe<Scalars['numeric']>;
  approved_at?: Maybe<Scalars['timestamptz']>;
  approved_by?: Maybe<Scalars['uuid']>;
  /** ชื่อผู้ยืม */
  borrower_name?: Maybe<Scalars['String']>;
  /** งบประมาณ */
  budget_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  /** วันที่สร้างรายการ */
  date?: Maybe<Scalars['date']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่ลูกหนี้คืนเงินยืมเสร็จสิ้น NULL = ยังไม่เสร็จ */
  done_at?: Maybe<Scalars['timestamptz']>;
  done_by?: Maybe<Scalars['uuid']>;
  /** วันที่ครบกำหนด */
  due_date?: Maybe<Scalars['date']>;
  /** บัญชีค่าใช้จ่าย */
  expense_account_id?: Maybe<Scalars['uuid']>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** เลขที่ขอเบิก ในระบบ GFMIS */
  gfmis_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locked_at?: Maybe<Scalars['timestamptz']>;
  locked_by?: Maybe<Scalars['uuid']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  /** จุดประสงค์ */
  proposal?: Maybe<Scalars['String']>;
  /** วันที่ส่งใช้ */
  received_date?: Maybe<Scalars['date']>;
  reference?: Maybe<Scalars['String']>;
  refund_amount?: Maybe<Scalars['numeric']>;
  status_id?: Maybe<LedgerReceivableStatusEnum>;
  submitted_at?: Maybe<Scalars['timestamptz']>;
  submitted_by?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "ledger.receivable_status" */
export type LedgerReceivableStatus = {
  __typename?: 'ledger_receivable_status';
  description: Scalars['String'];
  id: Scalars['String'];
  /** An array relationship */
  receivables: Array<LedgerReceivable>;
  /** An aggregate relationship */
  receivables_aggregate: LedgerReceivableAggregate;
};


/** columns and relationships of "ledger.receivable_status" */
export type LedgerReceivableStatusReceivablesArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableOrderBy>>;
  where?: Maybe<LedgerReceivableBoolExp>;
};


/** columns and relationships of "ledger.receivable_status" */
export type LedgerReceivableStatusReceivablesAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableOrderBy>>;
  where?: Maybe<LedgerReceivableBoolExp>;
};

/** aggregated selection of "ledger.receivable_status" */
export type LedgerReceivableStatusAggregate = {
  __typename?: 'ledger_receivable_status_aggregate';
  aggregate?: Maybe<LedgerReceivableStatusAggregateFields>;
  nodes: Array<LedgerReceivableStatus>;
};

/** aggregate fields of "ledger.receivable_status" */
export type LedgerReceivableStatusAggregateFields = {
  __typename?: 'ledger_receivable_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LedgerReceivableStatusMaxFields>;
  min?: Maybe<LedgerReceivableStatusMinFields>;
};


/** aggregate fields of "ledger.receivable_status" */
export type LedgerReceivableStatusAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LedgerReceivableStatusSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ledger.receivable_status". All fields are combined with a logical 'AND'. */
export type LedgerReceivableStatusBoolExp = {
  _and?: Maybe<Array<LedgerReceivableStatusBoolExp>>;
  _not?: Maybe<LedgerReceivableStatusBoolExp>;
  _or?: Maybe<Array<LedgerReceivableStatusBoolExp>>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  receivables?: Maybe<LedgerReceivableBoolExp>;
};

/** unique or primary key constraints on table "ledger.receivable_status" */
export enum LedgerReceivableStatusConstraint {
  /** unique or primary key constraint */
  ReceivableTypePkey = 'receivable_type_pkey'
}

export enum LedgerReceivableStatusEnum {
  /** เสร็จสิ้น */
  Done = 'done',
  /** แบบร่าง */
  Draft = 'draft',
  /** ยืนยันข้อมูล */
  Submitted = 'submitted'
}

/** Boolean expression to compare columns of type "ledger_receivable_status_enum". All fields are combined with logical 'AND'. */
export type LedgerReceivableStatusEnumComparisonExp = {
  _eq?: Maybe<LedgerReceivableStatusEnum>;
  _in?: Maybe<Array<LedgerReceivableStatusEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<LedgerReceivableStatusEnum>;
  _nin?: Maybe<Array<LedgerReceivableStatusEnum>>;
};

/** input type for inserting data into table "ledger.receivable_status" */
export type LedgerReceivableStatusInsertInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  receivables?: Maybe<LedgerReceivableArrRelInsertInput>;
};

/** aggregate max on columns */
export type LedgerReceivableStatusMaxFields = {
  __typename?: 'ledger_receivable_status_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type LedgerReceivableStatusMinFields = {
  __typename?: 'ledger_receivable_status_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ledger.receivable_status" */
export type LedgerReceivableStatusMutationResponse = {
  __typename?: 'ledger_receivable_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LedgerReceivableStatus>;
};

/** input type for inserting object relation for remote table "ledger.receivable_status" */
export type LedgerReceivableStatusObjRelInsertInput = {
  data: LedgerReceivableStatusInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LedgerReceivableStatusOnConflict>;
};

/** on conflict condition type for table "ledger.receivable_status" */
export type LedgerReceivableStatusOnConflict = {
  constraint: LedgerReceivableStatusConstraint;
  update_columns?: Array<LedgerReceivableStatusUpdateColumn>;
  where?: Maybe<LedgerReceivableStatusBoolExp>;
};

/** Ordering options when selecting data from "ledger.receivable_status". */
export type LedgerReceivableStatusOrderBy = {
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  receivables_aggregate?: Maybe<LedgerReceivableAggregateOrderBy>;
};

/** primary key columns input for table: ledger_receivable_status */
export type LedgerReceivableStatusPkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "ledger.receivable_status" */
export enum LedgerReceivableStatusSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ledger.receivable_status" */
export type LedgerReceivableStatusSetInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** update columns of table "ledger.receivable_status" */
export enum LedgerReceivableStatusUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** aggregate stddev on columns */
export type LedgerReceivableStddevFields = {
  __typename?: 'ledger_receivable_stddev_fields';
  /** จำนวนเงินยืม */
  amount?: Maybe<Scalars['Float']>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year?: Maybe<Scalars['Float']>;
  refund_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ledger.receivable" */
export type LedgerReceivableStddevOrderBy = {
  /** จำนวนเงินยืม */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year?: Maybe<OrderBy>;
  refund_amount?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LedgerReceivableStddevPopFields = {
  __typename?: 'ledger_receivable_stddev_pop_fields';
  /** จำนวนเงินยืม */
  amount?: Maybe<Scalars['Float']>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year?: Maybe<Scalars['Float']>;
  refund_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ledger.receivable" */
export type LedgerReceivableStddevPopOrderBy = {
  /** จำนวนเงินยืม */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year?: Maybe<OrderBy>;
  refund_amount?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LedgerReceivableStddevSampFields = {
  __typename?: 'ledger_receivable_stddev_samp_fields';
  /** จำนวนเงินยืม */
  amount?: Maybe<Scalars['Float']>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year?: Maybe<Scalars['Float']>;
  refund_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ledger.receivable" */
export type LedgerReceivableStddevSampOrderBy = {
  /** จำนวนเงินยืม */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year?: Maybe<OrderBy>;
  refund_amount?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LedgerReceivableSumFields = {
  __typename?: 'ledger_receivable_sum_fields';
  /** จำนวนเงินยืม */
  amount?: Maybe<Scalars['numeric']>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year?: Maybe<Scalars['Int']>;
  refund_amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "ledger.receivable" */
export type LedgerReceivableSumOrderBy = {
  /** จำนวนเงินยืม */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year?: Maybe<OrderBy>;
  refund_amount?: Maybe<OrderBy>;
};

/** update columns of table "ledger.receivable" */
export enum LedgerReceivableUpdateColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  ApprovedBy = 'approved_by',
  /** column name */
  BorrowerName = 'borrower_name',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Date = 'date',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DoneAt = 'done_at',
  /** column name */
  DoneBy = 'done_by',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  ExpenseAccountId = 'expense_account_id',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  GfmisId = 'gfmis_id',
  /** column name */
  Id = 'id',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  LockedBy = 'locked_by',
  /** column name */
  Note = 'note',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Proposal = 'proposal',
  /** column name */
  ReceivedDate = 'received_date',
  /** column name */
  Reference = 'reference',
  /** column name */
  RefundAmount = 'refund_amount',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  SubmittedAt = 'submitted_at',
  /** column name */
  SubmittedBy = 'submitted_by',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type LedgerReceivableVarPopFields = {
  __typename?: 'ledger_receivable_var_pop_fields';
  /** จำนวนเงินยืม */
  amount?: Maybe<Scalars['Float']>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year?: Maybe<Scalars['Float']>;
  refund_amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ledger.receivable" */
export type LedgerReceivableVarPopOrderBy = {
  /** จำนวนเงินยืม */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year?: Maybe<OrderBy>;
  refund_amount?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LedgerReceivableVarSampFields = {
  __typename?: 'ledger_receivable_var_samp_fields';
  /** จำนวนเงินยืม */
  amount?: Maybe<Scalars['Float']>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year?: Maybe<Scalars['Float']>;
  refund_amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ledger.receivable" */
export type LedgerReceivableVarSampOrderBy = {
  /** จำนวนเงินยืม */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year?: Maybe<OrderBy>;
  refund_amount?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LedgerReceivableVarianceFields = {
  __typename?: 'ledger_receivable_variance_fields';
  /** จำนวนเงินยืม */
  amount?: Maybe<Scalars['Float']>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year?: Maybe<Scalars['Float']>;
  refund_amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ledger.receivable" */
export type LedgerReceivableVarianceOrderBy = {
  /** จำนวนเงินยืม */
  amount?: Maybe<OrderBy>;
  /** ปีงบประมาณ (พ.ศ.) */
  fiscal_year?: Maybe<OrderBy>;
  refund_amount?: Maybe<OrderBy>;
};

/** columns and relationships of "ledger.total_report_monthly" */
export type LedgerTotalReportMonthly = {
  __typename?: 'ledger_total_report_monthly';
  account_id?: Maybe<Scalars['uuid']>;
  allocated_budget?: Maybe<Scalars['numeric']>;
  budget_balance?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  expense_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['float8']>;
  organization_id?: Maybe<Scalars['uuid']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "ledger.total_report_monthly" */
export type LedgerTotalReportMonthlyAggregate = {
  __typename?: 'ledger_total_report_monthly_aggregate';
  aggregate?: Maybe<LedgerTotalReportMonthlyAggregateFields>;
  nodes: Array<LedgerTotalReportMonthly>;
};

/** aggregate fields of "ledger.total_report_monthly" */
export type LedgerTotalReportMonthlyAggregateFields = {
  __typename?: 'ledger_total_report_monthly_aggregate_fields';
  avg?: Maybe<LedgerTotalReportMonthlyAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LedgerTotalReportMonthlyMaxFields>;
  min?: Maybe<LedgerTotalReportMonthlyMinFields>;
  stddev?: Maybe<LedgerTotalReportMonthlyStddevFields>;
  stddev_pop?: Maybe<LedgerTotalReportMonthlyStddevPopFields>;
  stddev_samp?: Maybe<LedgerTotalReportMonthlyStddevSampFields>;
  sum?: Maybe<LedgerTotalReportMonthlySumFields>;
  var_pop?: Maybe<LedgerTotalReportMonthlyVarPopFields>;
  var_samp?: Maybe<LedgerTotalReportMonthlyVarSampFields>;
  variance?: Maybe<LedgerTotalReportMonthlyVarianceFields>;
};


/** aggregate fields of "ledger.total_report_monthly" */
export type LedgerTotalReportMonthlyAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LedgerTotalReportMonthlySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LedgerTotalReportMonthlyAvgFields = {
  __typename?: 'ledger_total_report_monthly_avg_fields';
  allocated_budget?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  expense_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ledger.total_report_monthly". All fields are combined with a logical 'AND'. */
export type LedgerTotalReportMonthlyBoolExp = {
  _and?: Maybe<Array<LedgerTotalReportMonthlyBoolExp>>;
  _not?: Maybe<LedgerTotalReportMonthlyBoolExp>;
  _or?: Maybe<Array<LedgerTotalReportMonthlyBoolExp>>;
  account_id?: Maybe<UuidComparisonExp>;
  allocated_budget?: Maybe<NumericComparisonExp>;
  budget_balance?: Maybe<NumericComparisonExp>;
  budget_id?: Maybe<StringComparisonExp>;
  expense_total?: Maybe<NumericComparisonExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
  month?: Maybe<Float8ComparisonExp>;
  organization_id?: Maybe<UuidComparisonExp>;
  year?: Maybe<Float8ComparisonExp>;
};

/** aggregate max on columns */
export type LedgerTotalReportMonthlyMaxFields = {
  __typename?: 'ledger_total_report_monthly_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  allocated_budget?: Maybe<Scalars['numeric']>;
  budget_balance?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  expense_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['float8']>;
  organization_id?: Maybe<Scalars['uuid']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type LedgerTotalReportMonthlyMinFields = {
  __typename?: 'ledger_total_report_monthly_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  allocated_budget?: Maybe<Scalars['numeric']>;
  budget_balance?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  expense_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['float8']>;
  organization_id?: Maybe<Scalars['uuid']>;
  year?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "ledger.total_report_monthly". */
export type LedgerTotalReportMonthlyOrderBy = {
  account_id?: Maybe<OrderBy>;
  allocated_budget?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  expense_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  month?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  year?: Maybe<OrderBy>;
};

/** select columns of table "ledger.total_report_monthly" */
export enum LedgerTotalReportMonthlySelectColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AllocatedBudget = 'allocated_budget',
  /** column name */
  BudgetBalance = 'budget_balance',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  ExpenseTotal = 'expense_total',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  Month = 'month',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Year = 'year'
}

/** aggregate stddev on columns */
export type LedgerTotalReportMonthlyStddevFields = {
  __typename?: 'ledger_total_report_monthly_stddev_fields';
  allocated_budget?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  expense_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LedgerTotalReportMonthlyStddevPopFields = {
  __typename?: 'ledger_total_report_monthly_stddev_pop_fields';
  allocated_budget?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  expense_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LedgerTotalReportMonthlyStddevSampFields = {
  __typename?: 'ledger_total_report_monthly_stddev_samp_fields';
  allocated_budget?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  expense_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LedgerTotalReportMonthlySumFields = {
  __typename?: 'ledger_total_report_monthly_sum_fields';
  allocated_budget?: Maybe<Scalars['numeric']>;
  budget_balance?: Maybe<Scalars['numeric']>;
  expense_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['float8']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type LedgerTotalReportMonthlyVarPopFields = {
  __typename?: 'ledger_total_report_monthly_var_pop_fields';
  allocated_budget?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  expense_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LedgerTotalReportMonthlyVarSampFields = {
  __typename?: 'ledger_total_report_monthly_var_samp_fields';
  allocated_budget?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  expense_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LedgerTotalReportMonthlyVarianceFields = {
  __typename?: 'ledger_total_report_monthly_variance_fields';
  allocated_budget?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  expense_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ledger.total_report_yearly" */
export type LedgerTotalReportYearly = {
  __typename?: 'ledger_total_report_yearly';
  account_id?: Maybe<Scalars['uuid']>;
  allocated_budget?: Maybe<Scalars['numeric']>;
  budget_balance?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  expense_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "ledger.total_report_yearly" */
export type LedgerTotalReportYearlyAggregate = {
  __typename?: 'ledger_total_report_yearly_aggregate';
  aggregate?: Maybe<LedgerTotalReportYearlyAggregateFields>;
  nodes: Array<LedgerTotalReportYearly>;
};

/** aggregate fields of "ledger.total_report_yearly" */
export type LedgerTotalReportYearlyAggregateFields = {
  __typename?: 'ledger_total_report_yearly_aggregate_fields';
  avg?: Maybe<LedgerTotalReportYearlyAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LedgerTotalReportYearlyMaxFields>;
  min?: Maybe<LedgerTotalReportYearlyMinFields>;
  stddev?: Maybe<LedgerTotalReportYearlyStddevFields>;
  stddev_pop?: Maybe<LedgerTotalReportYearlyStddevPopFields>;
  stddev_samp?: Maybe<LedgerTotalReportYearlyStddevSampFields>;
  sum?: Maybe<LedgerTotalReportYearlySumFields>;
  var_pop?: Maybe<LedgerTotalReportYearlyVarPopFields>;
  var_samp?: Maybe<LedgerTotalReportYearlyVarSampFields>;
  variance?: Maybe<LedgerTotalReportYearlyVarianceFields>;
};


/** aggregate fields of "ledger.total_report_yearly" */
export type LedgerTotalReportYearlyAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LedgerTotalReportYearlySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LedgerTotalReportYearlyAvgFields = {
  __typename?: 'ledger_total_report_yearly_avg_fields';
  allocated_budget?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  expense_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ledger.total_report_yearly". All fields are combined with a logical 'AND'. */
export type LedgerTotalReportYearlyBoolExp = {
  _and?: Maybe<Array<LedgerTotalReportYearlyBoolExp>>;
  _not?: Maybe<LedgerTotalReportYearlyBoolExp>;
  _or?: Maybe<Array<LedgerTotalReportYearlyBoolExp>>;
  account_id?: Maybe<UuidComparisonExp>;
  allocated_budget?: Maybe<NumericComparisonExp>;
  budget_balance?: Maybe<NumericComparisonExp>;
  budget_id?: Maybe<StringComparisonExp>;
  expense_total?: Maybe<NumericComparisonExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
  organization_id?: Maybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type LedgerTotalReportYearlyMaxFields = {
  __typename?: 'ledger_total_report_yearly_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  allocated_budget?: Maybe<Scalars['numeric']>;
  budget_balance?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  expense_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type LedgerTotalReportYearlyMinFields = {
  __typename?: 'ledger_total_report_yearly_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  allocated_budget?: Maybe<Scalars['numeric']>;
  budget_balance?: Maybe<Scalars['numeric']>;
  budget_id?: Maybe<Scalars['String']>;
  expense_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "ledger.total_report_yearly". */
export type LedgerTotalReportYearlyOrderBy = {
  account_id?: Maybe<OrderBy>;
  allocated_budget?: Maybe<OrderBy>;
  budget_balance?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  expense_total?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
};

/** select columns of table "ledger.total_report_yearly" */
export enum LedgerTotalReportYearlySelectColumn {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AllocatedBudget = 'allocated_budget',
  /** column name */
  BudgetBalance = 'budget_balance',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  ExpenseTotal = 'expense_total',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  OrganizationId = 'organization_id'
}

/** aggregate stddev on columns */
export type LedgerTotalReportYearlyStddevFields = {
  __typename?: 'ledger_total_report_yearly_stddev_fields';
  allocated_budget?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  expense_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LedgerTotalReportYearlyStddevPopFields = {
  __typename?: 'ledger_total_report_yearly_stddev_pop_fields';
  allocated_budget?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  expense_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LedgerTotalReportYearlyStddevSampFields = {
  __typename?: 'ledger_total_report_yearly_stddev_samp_fields';
  allocated_budget?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  expense_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LedgerTotalReportYearlySumFields = {
  __typename?: 'ledger_total_report_yearly_sum_fields';
  allocated_budget?: Maybe<Scalars['numeric']>;
  budget_balance?: Maybe<Scalars['numeric']>;
  expense_total?: Maybe<Scalars['numeric']>;
  fiscal_year?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type LedgerTotalReportYearlyVarPopFields = {
  __typename?: 'ledger_total_report_yearly_var_pop_fields';
  allocated_budget?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  expense_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LedgerTotalReportYearlyVarSampFields = {
  __typename?: 'ledger_total_report_yearly_var_samp_fields';
  allocated_budget?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  expense_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LedgerTotalReportYearlyVarianceFields = {
  __typename?: 'ledger_total_report_yearly_variance_fields';
  allocated_budget?: Maybe<Scalars['Float']>;
  budget_balance?: Maybe<Scalars['Float']>;
  expense_total?: Maybe<Scalars['Float']>;
  fiscal_year?: Maybe<Scalars['Float']>;
};

/**
 * กิจการมีการบันทึกรายได้ และงบการเงินของกิจการในรูปแบบใด
 *
 *
 * columns and relationships of "loan.account_type"
 */
export type LoanAccountType = {
  __typename?: 'loan_account_type';
  /**
   * สถานะ
   * 0 = inactive
   * 1 = active
   */
  active: Scalars['Boolean'];
  /** An array relationship */
  application_details: Array<LoanApplicationDetail>;
  /** An aggregate relationship */
  application_details_aggregate: LoanApplicationDetailAggregate;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** กิจการมีการบันทึกรายได้ และงบการเงินของกิจการ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};


/**
 * กิจการมีการบันทึกรายได้ และงบการเงินของกิจการในรูปแบบใด
 *
 *
 * columns and relationships of "loan.account_type"
 */
export type LoanAccountTypeApplicationDetailsArgs = {
  distinct_on?: Maybe<Array<LoanApplicationDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationDetailOrderBy>>;
  where?: Maybe<LoanApplicationDetailBoolExp>;
};


/**
 * กิจการมีการบันทึกรายได้ และงบการเงินของกิจการในรูปแบบใด
 *
 *
 * columns and relationships of "loan.account_type"
 */
export type LoanAccountTypeApplicationDetailsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationDetailOrderBy>>;
  where?: Maybe<LoanApplicationDetailBoolExp>;
};

/** aggregated selection of "loan.account_type" */
export type LoanAccountTypeAggregate = {
  __typename?: 'loan_account_type_aggregate';
  aggregate?: Maybe<LoanAccountTypeAggregateFields>;
  nodes: Array<LoanAccountType>;
};

/** aggregate fields of "loan.account_type" */
export type LoanAccountTypeAggregateFields = {
  __typename?: 'loan_account_type_aggregate_fields';
  avg?: Maybe<LoanAccountTypeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanAccountTypeMaxFields>;
  min?: Maybe<LoanAccountTypeMinFields>;
  stddev?: Maybe<LoanAccountTypeStddevFields>;
  stddev_pop?: Maybe<LoanAccountTypeStddevPopFields>;
  stddev_samp?: Maybe<LoanAccountTypeStddevSampFields>;
  sum?: Maybe<LoanAccountTypeSumFields>;
  var_pop?: Maybe<LoanAccountTypeVarPopFields>;
  var_samp?: Maybe<LoanAccountTypeVarSampFields>;
  variance?: Maybe<LoanAccountTypeVarianceFields>;
};


/** aggregate fields of "loan.account_type" */
export type LoanAccountTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanAccountTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LoanAccountTypeAvgFields = {
  __typename?: 'loan_account_type_avg_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "loan.account_type". All fields are combined with a logical 'AND'. */
export type LoanAccountTypeBoolExp = {
  _and?: Maybe<Array<LoanAccountTypeBoolExp>>;
  _not?: Maybe<LoanAccountTypeBoolExp>;
  _or?: Maybe<Array<LoanAccountTypeBoolExp>>;
  active?: Maybe<BooleanComparisonExp>;
  application_details?: Maybe<LoanApplicationDetailBoolExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "loan.account_type" */
export enum LoanAccountTypeConstraint {
  /** unique or primary key constraint */
  AccountTypePkey = 'account_type_pkey'
}

/** input type for incrementing numeric columns in table "loan.account_type" */
export type LoanAccountTypeIncInput = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.account_type" */
export type LoanAccountTypeInsertInput = {
  /**
   * สถานะ
   * 0 = inactive
   * 1 = active
   */
  active?: Maybe<Scalars['Boolean']>;
  application_details?: Maybe<LoanApplicationDetailArrRelInsertInput>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** กิจการมีการบันทึกรายได้ และงบการเงินของกิจการ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type LoanAccountTypeMaxFields = {
  __typename?: 'loan_account_type_max_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** กิจการมีการบันทึกรายได้ และงบการเงินของกิจการ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type LoanAccountTypeMinFields = {
  __typename?: 'loan_account_type_min_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** กิจการมีการบันทึกรายได้ และงบการเงินของกิจการ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "loan.account_type" */
export type LoanAccountTypeMutationResponse = {
  __typename?: 'loan_account_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanAccountType>;
};

/** input type for inserting object relation for remote table "loan.account_type" */
export type LoanAccountTypeObjRelInsertInput = {
  data: LoanAccountTypeInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanAccountTypeOnConflict>;
};

/** on conflict condition type for table "loan.account_type" */
export type LoanAccountTypeOnConflict = {
  constraint: LoanAccountTypeConstraint;
  update_columns?: Array<LoanAccountTypeUpdateColumn>;
  where?: Maybe<LoanAccountTypeBoolExp>;
};

/** Ordering options when selecting data from "loan.account_type". */
export type LoanAccountTypeOrderBy = {
  active?: Maybe<OrderBy>;
  application_details_aggregate?: Maybe<LoanApplicationDetailAggregateOrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_account_type */
export type LoanAccountTypePkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "loan.account_type" */
export enum LoanAccountTypeSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "loan.account_type" */
export type LoanAccountTypeSetInput = {
  /**
   * สถานะ
   * 0 = inactive
   * 1 = active
   */
  active?: Maybe<Scalars['Boolean']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** กิจการมีการบันทึกรายได้ และงบการเงินของกิจการ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type LoanAccountTypeStddevFields = {
  __typename?: 'loan_account_type_stddev_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LoanAccountTypeStddevPopFields = {
  __typename?: 'loan_account_type_stddev_pop_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LoanAccountTypeStddevSampFields = {
  __typename?: 'loan_account_type_stddev_samp_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LoanAccountTypeSumFields = {
  __typename?: 'loan_account_type_sum_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** update columns of table "loan.account_type" */
export enum LoanAccountTypeUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type LoanAccountTypeVarPopFields = {
  __typename?: 'loan_account_type_var_pop_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LoanAccountTypeVarSampFields = {
  __typename?: 'loan_account_type_var_samp_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LoanAccountTypeVarianceFields = {
  __typename?: 'loan_account_type_variance_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/**
 * อัพโหลดไฟล์เอกสารแนบ
 *
 *
 * columns and relationships of "loan.application_attachment"
 */
export type LoanApplicationAttachment = {
  __typename?: 'loan_application_attachment';
  /** An object relationship */
  application_header: LoanApplicationHeader;
  /** ใบคำขอสินเชื่อ */
  application_header_id: Scalars['uuid'];
  /**
   * ผ่านการตรวจสอบ
   * true = ผ่าน
   * false = ไม่ผ่าน
   */
  approve: Scalars['Boolean'];
  /** วันที่ผ่านการตรวจสอบ */
  approve_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  attachment_type: LoanAttachmentType;
  /** ประเภทไฟล์ */
  attachment_type_id: Scalars['String'];
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ที่อยู่ไฟล์ */
  file_path: Scalars['String'];
  /** ชื่อไฟล์ */
  filename: Scalars['String'];
  /** Primary key */
  id: Scalars['uuid'];
  /** ประเภทไฟล์ */
  mimetype: Scalars['String'];
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ต้นฉบับ */
  original_name: Scalars['String'];
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregated selection of "loan.application_attachment" */
export type LoanApplicationAttachmentAggregate = {
  __typename?: 'loan_application_attachment_aggregate';
  aggregate?: Maybe<LoanApplicationAttachmentAggregateFields>;
  nodes: Array<LoanApplicationAttachment>;
};

/** aggregate fields of "loan.application_attachment" */
export type LoanApplicationAttachmentAggregateFields = {
  __typename?: 'loan_application_attachment_aggregate_fields';
  avg?: Maybe<LoanApplicationAttachmentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanApplicationAttachmentMaxFields>;
  min?: Maybe<LoanApplicationAttachmentMinFields>;
  stddev?: Maybe<LoanApplicationAttachmentStddevFields>;
  stddev_pop?: Maybe<LoanApplicationAttachmentStddevPopFields>;
  stddev_samp?: Maybe<LoanApplicationAttachmentStddevSampFields>;
  sum?: Maybe<LoanApplicationAttachmentSumFields>;
  var_pop?: Maybe<LoanApplicationAttachmentVarPopFields>;
  var_samp?: Maybe<LoanApplicationAttachmentVarSampFields>;
  variance?: Maybe<LoanApplicationAttachmentVarianceFields>;
};


/** aggregate fields of "loan.application_attachment" */
export type LoanApplicationAttachmentAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanApplicationAttachmentSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan.application_attachment" */
export type LoanApplicationAttachmentAggregateOrderBy = {
  avg?: Maybe<LoanApplicationAttachmentAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanApplicationAttachmentMaxOrderBy>;
  min?: Maybe<LoanApplicationAttachmentMinOrderBy>;
  stddev?: Maybe<LoanApplicationAttachmentStddevOrderBy>;
  stddev_pop?: Maybe<LoanApplicationAttachmentStddevPopOrderBy>;
  stddev_samp?: Maybe<LoanApplicationAttachmentStddevSampOrderBy>;
  sum?: Maybe<LoanApplicationAttachmentSumOrderBy>;
  var_pop?: Maybe<LoanApplicationAttachmentVarPopOrderBy>;
  var_samp?: Maybe<LoanApplicationAttachmentVarSampOrderBy>;
  variance?: Maybe<LoanApplicationAttachmentVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "loan.application_attachment" */
export type LoanApplicationAttachmentArrRelInsertInput = {
  data: Array<LoanApplicationAttachmentInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanApplicationAttachmentOnConflict>;
};

/** aggregate avg on columns */
export type LoanApplicationAttachmentAvgFields = {
  __typename?: 'loan_application_attachment_avg_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "loan.application_attachment" */
export type LoanApplicationAttachmentAvgOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "loan.application_attachment". All fields are combined with a logical 'AND'. */
export type LoanApplicationAttachmentBoolExp = {
  _and?: Maybe<Array<LoanApplicationAttachmentBoolExp>>;
  _not?: Maybe<LoanApplicationAttachmentBoolExp>;
  _or?: Maybe<Array<LoanApplicationAttachmentBoolExp>>;
  application_header?: Maybe<LoanApplicationHeaderBoolExp>;
  application_header_id?: Maybe<UuidComparisonExp>;
  approve?: Maybe<BooleanComparisonExp>;
  approve_at?: Maybe<TimestamptzComparisonExp>;
  attachment_type?: Maybe<LoanAttachmentTypeBoolExp>;
  attachment_type_id?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<StringComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  file_path?: Maybe<StringComparisonExp>;
  filename?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  mimetype?: Maybe<StringComparisonExp>;
  note?: Maybe<StringComparisonExp>;
  original_name?: Maybe<StringComparisonExp>;
  size?: Maybe<NumericComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "loan.application_attachment" */
export enum LoanApplicationAttachmentConstraint {
  /** unique or primary key constraint */
  ApplicationAttachmentPkey = 'application_attachment_pkey'
}

/** input type for incrementing numeric columns in table "loan.application_attachment" */
export type LoanApplicationAttachmentIncInput = {
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.application_attachment" */
export type LoanApplicationAttachmentInsertInput = {
  application_header?: Maybe<LoanApplicationHeaderObjRelInsertInput>;
  /** ใบคำขอสินเชื่อ */
  application_header_id?: Maybe<Scalars['uuid']>;
  /**
   * ผ่านการตรวจสอบ
   * true = ผ่าน
   * false = ไม่ผ่าน
   */
  approve?: Maybe<Scalars['Boolean']>;
  /** วันที่ผ่านการตรวจสอบ */
  approve_at?: Maybe<Scalars['timestamptz']>;
  attachment_type?: Maybe<LoanAttachmentTypeObjRelInsertInput>;
  /** ประเภทไฟล์ */
  attachment_type_id?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ที่อยู่ไฟล์ */
  file_path?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  filename?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ต้นฉบับ */
  original_name?: Maybe<Scalars['String']>;
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LoanApplicationAttachmentMaxFields = {
  __typename?: 'loan_application_attachment_max_fields';
  /** ใบคำขอสินเชื่อ */
  application_header_id?: Maybe<Scalars['uuid']>;
  /** วันที่ผ่านการตรวจสอบ */
  approve_at?: Maybe<Scalars['timestamptz']>;
  /** ประเภทไฟล์ */
  attachment_type_id?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ที่อยู่ไฟล์ */
  file_path?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  filename?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ต้นฉบับ */
  original_name?: Maybe<Scalars['String']>;
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "loan.application_attachment" */
export type LoanApplicationAttachmentMaxOrderBy = {
  /** ใบคำขอสินเชื่อ */
  application_header_id?: Maybe<OrderBy>;
  /** วันที่ผ่านการตรวจสอบ */
  approve_at?: Maybe<OrderBy>;
  /** ประเภทไฟล์ */
  attachment_type_id?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<OrderBy>;
  /** ที่อยู่ไฟล์ */
  file_path?: Maybe<OrderBy>;
  /** ชื่อไฟล์ */
  filename?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<OrderBy>;
  /** หมายเหตุ */
  note?: Maybe<OrderBy>;
  /** ชื่อไฟล์ต้นฉบับ */
  original_name?: Maybe<OrderBy>;
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanApplicationAttachmentMinFields = {
  __typename?: 'loan_application_attachment_min_fields';
  /** ใบคำขอสินเชื่อ */
  application_header_id?: Maybe<Scalars['uuid']>;
  /** วันที่ผ่านการตรวจสอบ */
  approve_at?: Maybe<Scalars['timestamptz']>;
  /** ประเภทไฟล์ */
  attachment_type_id?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ที่อยู่ไฟล์ */
  file_path?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  filename?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ต้นฉบับ */
  original_name?: Maybe<Scalars['String']>;
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "loan.application_attachment" */
export type LoanApplicationAttachmentMinOrderBy = {
  /** ใบคำขอสินเชื่อ */
  application_header_id?: Maybe<OrderBy>;
  /** วันที่ผ่านการตรวจสอบ */
  approve_at?: Maybe<OrderBy>;
  /** ประเภทไฟล์ */
  attachment_type_id?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<OrderBy>;
  /** ที่อยู่ไฟล์ */
  file_path?: Maybe<OrderBy>;
  /** ชื่อไฟล์ */
  filename?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<OrderBy>;
  /** หมายเหตุ */
  note?: Maybe<OrderBy>;
  /** ชื่อไฟล์ต้นฉบับ */
  original_name?: Maybe<OrderBy>;
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan.application_attachment" */
export type LoanApplicationAttachmentMutationResponse = {
  __typename?: 'loan_application_attachment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanApplicationAttachment>;
};

/** on conflict condition type for table "loan.application_attachment" */
export type LoanApplicationAttachmentOnConflict = {
  constraint: LoanApplicationAttachmentConstraint;
  update_columns?: Array<LoanApplicationAttachmentUpdateColumn>;
  where?: Maybe<LoanApplicationAttachmentBoolExp>;
};

/** Ordering options when selecting data from "loan.application_attachment". */
export type LoanApplicationAttachmentOrderBy = {
  application_header?: Maybe<LoanApplicationHeaderOrderBy>;
  application_header_id?: Maybe<OrderBy>;
  approve?: Maybe<OrderBy>;
  approve_at?: Maybe<OrderBy>;
  attachment_type?: Maybe<LoanAttachmentTypeOrderBy>;
  attachment_type_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  file_path?: Maybe<OrderBy>;
  filename?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mimetype?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  original_name?: Maybe<OrderBy>;
  size?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_application_attachment */
export type LoanApplicationAttachmentPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "loan.application_attachment" */
export enum LoanApplicationAttachmentSelectColumn {
  /** column name */
  ApplicationHeaderId = 'application_header_id',
  /** column name */
  Approve = 'approve',
  /** column name */
  ApproveAt = 'approve_at',
  /** column name */
  AttachmentTypeId = 'attachment_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Mimetype = 'mimetype',
  /** column name */
  Note = 'note',
  /** column name */
  OriginalName = 'original_name',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "loan.application_attachment" */
export type LoanApplicationAttachmentSetInput = {
  /** ใบคำขอสินเชื่อ */
  application_header_id?: Maybe<Scalars['uuid']>;
  /**
   * ผ่านการตรวจสอบ
   * true = ผ่าน
   * false = ไม่ผ่าน
   */
  approve?: Maybe<Scalars['Boolean']>;
  /** วันที่ผ่านการตรวจสอบ */
  approve_at?: Maybe<Scalars['timestamptz']>;
  /** ประเภทไฟล์ */
  attachment_type_id?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ที่อยู่ไฟล์ */
  file_path?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  filename?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ต้นฉบับ */
  original_name?: Maybe<Scalars['String']>;
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type LoanApplicationAttachmentStddevFields = {
  __typename?: 'loan_application_attachment_stddev_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "loan.application_attachment" */
export type LoanApplicationAttachmentStddevOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LoanApplicationAttachmentStddevPopFields = {
  __typename?: 'loan_application_attachment_stddev_pop_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "loan.application_attachment" */
export type LoanApplicationAttachmentStddevPopOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LoanApplicationAttachmentStddevSampFields = {
  __typename?: 'loan_application_attachment_stddev_samp_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "loan.application_attachment" */
export type LoanApplicationAttachmentStddevSampOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LoanApplicationAttachmentSumFields = {
  __typename?: 'loan_application_attachment_sum_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "loan.application_attachment" */
export type LoanApplicationAttachmentSumOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** update columns of table "loan.application_attachment" */
export enum LoanApplicationAttachmentUpdateColumn {
  /** column name */
  ApplicationHeaderId = 'application_header_id',
  /** column name */
  Approve = 'approve',
  /** column name */
  ApproveAt = 'approve_at',
  /** column name */
  AttachmentTypeId = 'attachment_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Mimetype = 'mimetype',
  /** column name */
  Note = 'note',
  /** column name */
  OriginalName = 'original_name',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type LoanApplicationAttachmentVarPopFields = {
  __typename?: 'loan_application_attachment_var_pop_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "loan.application_attachment" */
export type LoanApplicationAttachmentVarPopOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LoanApplicationAttachmentVarSampFields = {
  __typename?: 'loan_application_attachment_var_samp_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "loan.application_attachment" */
export type LoanApplicationAttachmentVarSampOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LoanApplicationAttachmentVarianceFields = {
  __typename?: 'loan_application_attachment_variance_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "loan.application_attachment" */
export type LoanApplicationAttachmentVarianceOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** columns and relationships of "loan.application_bank_branch_transfer" */
export type LoanApplicationBankBranchTransfer = {
  __typename?: 'loan_application_bank_branch_transfer';
  /** An object relationship */
  application_header: LoanApplicationHeader;
  application_header_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  /** ผู้สร้างใบโอนย้ายใบคำขอ */
  created_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  from_bank_branch: BankBranch;
  from_bank_branch_id: Scalars['String'];
  /** Primary key */
  id: Scalars['uuid'];
  note?: Maybe<Scalars['String']>;
  /** An object relationship */
  to_bank_branch: BankBranch;
  to_bank_branch_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** ผู้แก้ไขใบโอนย้ายใบคำขอ */
  updated_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  userByCreatedBy?: Maybe<Users>;
  /** An object relationship */
  userByUpdatedBy?: Maybe<Users>;
};

/** aggregated selection of "loan.application_bank_branch_transfer" */
export type LoanApplicationBankBranchTransferAggregate = {
  __typename?: 'loan_application_bank_branch_transfer_aggregate';
  aggregate?: Maybe<LoanApplicationBankBranchTransferAggregateFields>;
  nodes: Array<LoanApplicationBankBranchTransfer>;
};

/** aggregate fields of "loan.application_bank_branch_transfer" */
export type LoanApplicationBankBranchTransferAggregateFields = {
  __typename?: 'loan_application_bank_branch_transfer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LoanApplicationBankBranchTransferMaxFields>;
  min?: Maybe<LoanApplicationBankBranchTransferMinFields>;
};


/** aggregate fields of "loan.application_bank_branch_transfer" */
export type LoanApplicationBankBranchTransferAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanApplicationBankBranchTransferSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan.application_bank_branch_transfer" */
export type LoanApplicationBankBranchTransferAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanApplicationBankBranchTransferMaxOrderBy>;
  min?: Maybe<LoanApplicationBankBranchTransferMinOrderBy>;
};

/** input type for inserting array relation for remote table "loan.application_bank_branch_transfer" */
export type LoanApplicationBankBranchTransferArrRelInsertInput = {
  data: Array<LoanApplicationBankBranchTransferInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanApplicationBankBranchTransferOnConflict>;
};

/** Boolean expression to filter rows from the table "loan.application_bank_branch_transfer". All fields are combined with a logical 'AND'. */
export type LoanApplicationBankBranchTransferBoolExp = {
  _and?: Maybe<Array<LoanApplicationBankBranchTransferBoolExp>>;
  _not?: Maybe<LoanApplicationBankBranchTransferBoolExp>;
  _or?: Maybe<Array<LoanApplicationBankBranchTransferBoolExp>>;
  application_header?: Maybe<LoanApplicationHeaderBoolExp>;
  application_header_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  from_bank_branch?: Maybe<BankBranchBoolExp>;
  from_bank_branch_id?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  note?: Maybe<StringComparisonExp>;
  to_bank_branch?: Maybe<BankBranchBoolExp>;
  to_bank_branch_id?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<UuidComparisonExp>;
  userByCreatedBy?: Maybe<UsersBoolExp>;
  userByUpdatedBy?: Maybe<UsersBoolExp>;
};

/** unique or primary key constraints on table "loan.application_bank_branch_transfer" */
export enum LoanApplicationBankBranchTransferConstraint {
  /** unique or primary key constraint */
  ApplicationBankBranchTransferPkey = 'application_bank_branch_transfer_pkey'
}

/** input type for inserting data into table "loan.application_bank_branch_transfer" */
export type LoanApplicationBankBranchTransferInsertInput = {
  application_header?: Maybe<LoanApplicationHeaderObjRelInsertInput>;
  application_header_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** ผู้สร้างใบโอนย้ายใบคำขอ */
  created_by?: Maybe<Scalars['uuid']>;
  from_bank_branch?: Maybe<BankBranchObjRelInsertInput>;
  from_bank_branch_id?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  to_bank_branch?: Maybe<BankBranchObjRelInsertInput>;
  to_bank_branch_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ผู้แก้ไขใบโอนย้ายใบคำขอ */
  updated_by?: Maybe<Scalars['uuid']>;
  userByCreatedBy?: Maybe<UsersObjRelInsertInput>;
  userByUpdatedBy?: Maybe<UsersObjRelInsertInput>;
};

/** aggregate max on columns */
export type LoanApplicationBankBranchTransferMaxFields = {
  __typename?: 'loan_application_bank_branch_transfer_max_fields';
  application_header_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** ผู้สร้างใบโอนย้ายใบคำขอ */
  created_by?: Maybe<Scalars['uuid']>;
  from_bank_branch_id?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  to_bank_branch_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ผู้แก้ไขใบโอนย้ายใบคำขอ */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "loan.application_bank_branch_transfer" */
export type LoanApplicationBankBranchTransferMaxOrderBy = {
  application_header_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  /** ผู้สร้างใบโอนย้ายใบคำขอ */
  created_by?: Maybe<OrderBy>;
  from_bank_branch_id?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  to_bank_branch_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  /** ผู้แก้ไขใบโอนย้ายใบคำขอ */
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanApplicationBankBranchTransferMinFields = {
  __typename?: 'loan_application_bank_branch_transfer_min_fields';
  application_header_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** ผู้สร้างใบโอนย้ายใบคำขอ */
  created_by?: Maybe<Scalars['uuid']>;
  from_bank_branch_id?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  to_bank_branch_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ผู้แก้ไขใบโอนย้ายใบคำขอ */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "loan.application_bank_branch_transfer" */
export type LoanApplicationBankBranchTransferMinOrderBy = {
  application_header_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  /** ผู้สร้างใบโอนย้ายใบคำขอ */
  created_by?: Maybe<OrderBy>;
  from_bank_branch_id?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  to_bank_branch_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  /** ผู้แก้ไขใบโอนย้ายใบคำขอ */
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan.application_bank_branch_transfer" */
export type LoanApplicationBankBranchTransferMutationResponse = {
  __typename?: 'loan_application_bank_branch_transfer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanApplicationBankBranchTransfer>;
};

/** on conflict condition type for table "loan.application_bank_branch_transfer" */
export type LoanApplicationBankBranchTransferOnConflict = {
  constraint: LoanApplicationBankBranchTransferConstraint;
  update_columns?: Array<LoanApplicationBankBranchTransferUpdateColumn>;
  where?: Maybe<LoanApplicationBankBranchTransferBoolExp>;
};

/** Ordering options when selecting data from "loan.application_bank_branch_transfer". */
export type LoanApplicationBankBranchTransferOrderBy = {
  application_header?: Maybe<LoanApplicationHeaderOrderBy>;
  application_header_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  from_bank_branch?: Maybe<BankBranchOrderBy>;
  from_bank_branch_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  to_bank_branch?: Maybe<BankBranchOrderBy>;
  to_bank_branch_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
  userByCreatedBy?: Maybe<UsersOrderBy>;
  userByUpdatedBy?: Maybe<UsersOrderBy>;
};

/** primary key columns input for table: loan_application_bank_branch_transfer */
export type LoanApplicationBankBranchTransferPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "loan.application_bank_branch_transfer" */
export enum LoanApplicationBankBranchTransferSelectColumn {
  /** column name */
  ApplicationHeaderId = 'application_header_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FromBankBranchId = 'from_bank_branch_id',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  ToBankBranchId = 'to_bank_branch_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "loan.application_bank_branch_transfer" */
export type LoanApplicationBankBranchTransferSetInput = {
  application_header_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** ผู้สร้างใบโอนย้ายใบคำขอ */
  created_by?: Maybe<Scalars['uuid']>;
  from_bank_branch_id?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  to_bank_branch_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ผู้แก้ไขใบโอนย้ายใบคำขอ */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** update columns of table "loan.application_bank_branch_transfer" */
export enum LoanApplicationBankBranchTransferUpdateColumn {
  /** column name */
  ApplicationHeaderId = 'application_header_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FromBankBranchId = 'from_bank_branch_id',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  ToBankBranchId = 'to_bank_branch_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/**
 * กิจการที่ขอสินเชื่อ/ร่วมลงทุนครั้งนี้ เพื่อใช้ในการดำเนินธุรกิจลักษณะใด
 *
 *
 * columns and relationships of "loan.application_business_operation"
 */
export type LoanApplicationBusinessOperation = {
  __typename?: 'loan_application_business_operation';
  /** An object relationship */
  application_detail: LoanApplicationDetail;
  /** รหัสอ้างอิงข้อมูลรายละเอียดใบคำขอ */
  application_detail_id: Scalars['uuid'];
  /** An object relationship */
  business_operation: LoanBusinessOperation;
  /** freeze data กิจการที่ขอสินเชื่อ/ร่วมลงทุนครั้งนี้ เพื่อใช้ในการดำเนินธุรกิจลักษณะใด */
  business_operation_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ (ระบุ) */
  business_operation_else?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิงกิจการที่ขอสินเชื่อ/ร่วมลงทุนครั้งนี้ */
  business_operation_id: Scalars['String'];
  /** true = เลือก, false = ไม่เลือก */
  checked: Scalars['Boolean'];
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregated selection of "loan.application_business_operation" */
export type LoanApplicationBusinessOperationAggregate = {
  __typename?: 'loan_application_business_operation_aggregate';
  aggregate?: Maybe<LoanApplicationBusinessOperationAggregateFields>;
  nodes: Array<LoanApplicationBusinessOperation>;
};

/** aggregate fields of "loan.application_business_operation" */
export type LoanApplicationBusinessOperationAggregateFields = {
  __typename?: 'loan_application_business_operation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LoanApplicationBusinessOperationMaxFields>;
  min?: Maybe<LoanApplicationBusinessOperationMinFields>;
};


/** aggregate fields of "loan.application_business_operation" */
export type LoanApplicationBusinessOperationAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanApplicationBusinessOperationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan.application_business_operation" */
export type LoanApplicationBusinessOperationAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanApplicationBusinessOperationMaxOrderBy>;
  min?: Maybe<LoanApplicationBusinessOperationMinOrderBy>;
};

/** input type for inserting array relation for remote table "loan.application_business_operation" */
export type LoanApplicationBusinessOperationArrRelInsertInput = {
  data: Array<LoanApplicationBusinessOperationInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanApplicationBusinessOperationOnConflict>;
};

/** Boolean expression to filter rows from the table "loan.application_business_operation". All fields are combined with a logical 'AND'. */
export type LoanApplicationBusinessOperationBoolExp = {
  _and?: Maybe<Array<LoanApplicationBusinessOperationBoolExp>>;
  _not?: Maybe<LoanApplicationBusinessOperationBoolExp>;
  _or?: Maybe<Array<LoanApplicationBusinessOperationBoolExp>>;
  application_detail?: Maybe<LoanApplicationDetailBoolExp>;
  application_detail_id?: Maybe<UuidComparisonExp>;
  business_operation?: Maybe<LoanBusinessOperationBoolExp>;
  business_operation_description?: Maybe<StringComparisonExp>;
  business_operation_else?: Maybe<StringComparisonExp>;
  business_operation_id?: Maybe<StringComparisonExp>;
  checked?: Maybe<BooleanComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "loan.application_business_operation" */
export enum LoanApplicationBusinessOperationConstraint {
  /** unique or primary key constraint */
  ApplicationBusinessOperationPkey = 'application_business_operation_pkey'
}

/** input type for inserting data into table "loan.application_business_operation" */
export type LoanApplicationBusinessOperationInsertInput = {
  application_detail?: Maybe<LoanApplicationDetailObjRelInsertInput>;
  /** รหัสอ้างอิงข้อมูลรายละเอียดใบคำขอ */
  application_detail_id?: Maybe<Scalars['uuid']>;
  business_operation?: Maybe<LoanBusinessOperationObjRelInsertInput>;
  /** freeze data กิจการที่ขอสินเชื่อ/ร่วมลงทุนครั้งนี้ เพื่อใช้ในการดำเนินธุรกิจลักษณะใด */
  business_operation_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ (ระบุ) */
  business_operation_else?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิงกิจการที่ขอสินเชื่อ/ร่วมลงทุนครั้งนี้ */
  business_operation_id?: Maybe<Scalars['String']>;
  /** true = เลือก, false = ไม่เลือก */
  checked?: Maybe<Scalars['Boolean']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LoanApplicationBusinessOperationMaxFields = {
  __typename?: 'loan_application_business_operation_max_fields';
  /** รหัสอ้างอิงข้อมูลรายละเอียดใบคำขอ */
  application_detail_id?: Maybe<Scalars['uuid']>;
  /** freeze data กิจการที่ขอสินเชื่อ/ร่วมลงทุนครั้งนี้ เพื่อใช้ในการดำเนินธุรกิจลักษณะใด */
  business_operation_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ (ระบุ) */
  business_operation_else?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิงกิจการที่ขอสินเชื่อ/ร่วมลงทุนครั้งนี้ */
  business_operation_id?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "loan.application_business_operation" */
export type LoanApplicationBusinessOperationMaxOrderBy = {
  /** รหัสอ้างอิงข้อมูลรายละเอียดใบคำขอ */
  application_detail_id?: Maybe<OrderBy>;
  /** freeze data กิจการที่ขอสินเชื่อ/ร่วมลงทุนครั้งนี้ เพื่อใช้ในการดำเนินธุรกิจลักษณะใด */
  business_operation_description?: Maybe<OrderBy>;
  /** อื่น ๆ (ระบุ) */
  business_operation_else?: Maybe<OrderBy>;
  /** รหัสอ้างอิงกิจการที่ขอสินเชื่อ/ร่วมลงทุนครั้งนี้ */
  business_operation_id?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanApplicationBusinessOperationMinFields = {
  __typename?: 'loan_application_business_operation_min_fields';
  /** รหัสอ้างอิงข้อมูลรายละเอียดใบคำขอ */
  application_detail_id?: Maybe<Scalars['uuid']>;
  /** freeze data กิจการที่ขอสินเชื่อ/ร่วมลงทุนครั้งนี้ เพื่อใช้ในการดำเนินธุรกิจลักษณะใด */
  business_operation_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ (ระบุ) */
  business_operation_else?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิงกิจการที่ขอสินเชื่อ/ร่วมลงทุนครั้งนี้ */
  business_operation_id?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "loan.application_business_operation" */
export type LoanApplicationBusinessOperationMinOrderBy = {
  /** รหัสอ้างอิงข้อมูลรายละเอียดใบคำขอ */
  application_detail_id?: Maybe<OrderBy>;
  /** freeze data กิจการที่ขอสินเชื่อ/ร่วมลงทุนครั้งนี้ เพื่อใช้ในการดำเนินธุรกิจลักษณะใด */
  business_operation_description?: Maybe<OrderBy>;
  /** อื่น ๆ (ระบุ) */
  business_operation_else?: Maybe<OrderBy>;
  /** รหัสอ้างอิงกิจการที่ขอสินเชื่อ/ร่วมลงทุนครั้งนี้ */
  business_operation_id?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan.application_business_operation" */
export type LoanApplicationBusinessOperationMutationResponse = {
  __typename?: 'loan_application_business_operation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanApplicationBusinessOperation>;
};

/** on conflict condition type for table "loan.application_business_operation" */
export type LoanApplicationBusinessOperationOnConflict = {
  constraint: LoanApplicationBusinessOperationConstraint;
  update_columns?: Array<LoanApplicationBusinessOperationUpdateColumn>;
  where?: Maybe<LoanApplicationBusinessOperationBoolExp>;
};

/** Ordering options when selecting data from "loan.application_business_operation". */
export type LoanApplicationBusinessOperationOrderBy = {
  application_detail?: Maybe<LoanApplicationDetailOrderBy>;
  application_detail_id?: Maybe<OrderBy>;
  business_operation?: Maybe<LoanBusinessOperationOrderBy>;
  business_operation_description?: Maybe<OrderBy>;
  business_operation_else?: Maybe<OrderBy>;
  business_operation_id?: Maybe<OrderBy>;
  checked?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_application_business_operation */
export type LoanApplicationBusinessOperationPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "loan.application_business_operation" */
export enum LoanApplicationBusinessOperationSelectColumn {
  /** column name */
  ApplicationDetailId = 'application_detail_id',
  /** column name */
  BusinessOperationDescription = 'business_operation_description',
  /** column name */
  BusinessOperationElse = 'business_operation_else',
  /** column name */
  BusinessOperationId = 'business_operation_id',
  /** column name */
  Checked = 'checked',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "loan.application_business_operation" */
export type LoanApplicationBusinessOperationSetInput = {
  /** รหัสอ้างอิงข้อมูลรายละเอียดใบคำขอ */
  application_detail_id?: Maybe<Scalars['uuid']>;
  /** freeze data กิจการที่ขอสินเชื่อ/ร่วมลงทุนครั้งนี้ เพื่อใช้ในการดำเนินธุรกิจลักษณะใด */
  business_operation_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ (ระบุ) */
  business_operation_else?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิงกิจการที่ขอสินเชื่อ/ร่วมลงทุนครั้งนี้ */
  business_operation_id?: Maybe<Scalars['String']>;
  /** true = เลือก, false = ไม่เลือก */
  checked?: Maybe<Scalars['Boolean']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** update columns of table "loan.application_business_operation" */
export enum LoanApplicationBusinessOperationUpdateColumn {
  /** column name */
  ApplicationDetailId = 'application_detail_id',
  /** column name */
  BusinessOperationDescription = 'business_operation_description',
  /** column name */
  BusinessOperationElse = 'business_operation_else',
  /** column name */
  BusinessOperationId = 'business_operation_id',
  /** column name */
  Checked = 'checked',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/**
 * กรรมการผู้มีอำนาจลงนาม
 *
 *
 * columns and relationships of "loan.application_committee"
 */
export type LoanApplicationCommittee = {
  __typename?: 'loan_application_committee';
  /** true = ใช้งาน, false = ไม่ใช้งาน */
  active: Scalars['Boolean'];
  /** An object relationship */
  application_header: LoanApplicationHeader;
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_header_id: Scalars['uuid'];
  /** กรรมการผู้มีอำนาจลงนาม */
  authorization: Scalars['Boolean'];
  /** เลขที่บัตรประจำตัวประชาชน */
  citizen_id?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ อำเภอ */
  contact_address_amphoe?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ ตำบล */
  contact_address_district?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ โทรสาร */
  contact_address_fax?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ เบอร์มือถือ */
  contact_address_mobile?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ จังหวัด */
  contact_address_province?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ ที่อยู่ */
  contact_address_street?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ โทรศัพท์ */
  contact_address_tel?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ รหัสไปรษณีย์ */
  contact_address_zipcode?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** true = มาจาก dbd, false ไม่ได้มาจาก dbd */
  from_dbd: Scalars['Boolean'];
  /** A computed field, executes function "loan.application_committee_full_name" */
  full_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามทะเบียนบ้าน อำเภอ */
  primary_address_amphoe?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามทะเบียนบ้าน ตำบล */
  primary_address_district?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามสถานที่ติดต่อ */
  primary_address_is_contact_address: Scalars['Boolean'];
  /** ที่อยู่ตามทะเบียนบ้าน จังหวัด */
  primary_address_province?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามทะเบียนบ้าน ที่อยู่ */
  primary_address_street?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามทะเบียนบ้าน รหัสไปรษณีย์ */
  primary_address_zipcode?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
  /** คำนำหน้า */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน อำเภอ */
  working_address_amphoe?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน ตำบล */
  working_address_district?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน โทรสาร */
  working_address_fax?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามสถานที่ติดต่อ */
  working_address_is_contact_address: Scalars['Boolean'];
  /** สถานที่ทำงาน จังหวัด */
  working_address_province?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน ที่อยู่ */
  working_address_street?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน โทรศัพท์ */
  working_address_tel?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน รหัสไปรษณีย์ */
  working_address_zipcode?: Maybe<Scalars['String']>;
};

/** aggregated selection of "loan.application_committee" */
export type LoanApplicationCommitteeAggregate = {
  __typename?: 'loan_application_committee_aggregate';
  aggregate?: Maybe<LoanApplicationCommitteeAggregateFields>;
  nodes: Array<LoanApplicationCommittee>;
};

/** aggregate fields of "loan.application_committee" */
export type LoanApplicationCommitteeAggregateFields = {
  __typename?: 'loan_application_committee_aggregate_fields';
  avg?: Maybe<LoanApplicationCommitteeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanApplicationCommitteeMaxFields>;
  min?: Maybe<LoanApplicationCommitteeMinFields>;
  stddev?: Maybe<LoanApplicationCommitteeStddevFields>;
  stddev_pop?: Maybe<LoanApplicationCommitteeStddevPopFields>;
  stddev_samp?: Maybe<LoanApplicationCommitteeStddevSampFields>;
  sum?: Maybe<LoanApplicationCommitteeSumFields>;
  var_pop?: Maybe<LoanApplicationCommitteeVarPopFields>;
  var_samp?: Maybe<LoanApplicationCommitteeVarSampFields>;
  variance?: Maybe<LoanApplicationCommitteeVarianceFields>;
};


/** aggregate fields of "loan.application_committee" */
export type LoanApplicationCommitteeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanApplicationCommitteeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan.application_committee" */
export type LoanApplicationCommitteeAggregateOrderBy = {
  avg?: Maybe<LoanApplicationCommitteeAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanApplicationCommitteeMaxOrderBy>;
  min?: Maybe<LoanApplicationCommitteeMinOrderBy>;
  stddev?: Maybe<LoanApplicationCommitteeStddevOrderBy>;
  stddev_pop?: Maybe<LoanApplicationCommitteeStddevPopOrderBy>;
  stddev_samp?: Maybe<LoanApplicationCommitteeStddevSampOrderBy>;
  sum?: Maybe<LoanApplicationCommitteeSumOrderBy>;
  var_pop?: Maybe<LoanApplicationCommitteeVarPopOrderBy>;
  var_samp?: Maybe<LoanApplicationCommitteeVarSampOrderBy>;
  variance?: Maybe<LoanApplicationCommitteeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "loan.application_committee" */
export type LoanApplicationCommitteeArrRelInsertInput = {
  data: Array<LoanApplicationCommitteeInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanApplicationCommitteeOnConflict>;
};

/** aggregate avg on columns */
export type LoanApplicationCommitteeAvgFields = {
  __typename?: 'loan_application_committee_avg_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "loan.application_committee" */
export type LoanApplicationCommitteeAvgOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "loan.application_committee". All fields are combined with a logical 'AND'. */
export type LoanApplicationCommitteeBoolExp = {
  _and?: Maybe<Array<LoanApplicationCommitteeBoolExp>>;
  _not?: Maybe<LoanApplicationCommitteeBoolExp>;
  _or?: Maybe<Array<LoanApplicationCommitteeBoolExp>>;
  active?: Maybe<BooleanComparisonExp>;
  application_header?: Maybe<LoanApplicationHeaderBoolExp>;
  application_header_id?: Maybe<UuidComparisonExp>;
  authorization?: Maybe<BooleanComparisonExp>;
  citizen_id?: Maybe<StringComparisonExp>;
  contact_address_amphoe?: Maybe<StringComparisonExp>;
  contact_address_district?: Maybe<StringComparisonExp>;
  contact_address_fax?: Maybe<StringComparisonExp>;
  contact_address_mobile?: Maybe<StringComparisonExp>;
  contact_address_province?: Maybe<StringComparisonExp>;
  contact_address_street?: Maybe<StringComparisonExp>;
  contact_address_tel?: Maybe<StringComparisonExp>;
  contact_address_zipcode?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<StringComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  first_name?: Maybe<StringComparisonExp>;
  from_dbd?: Maybe<BooleanComparisonExp>;
  full_name?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  last_name?: Maybe<StringComparisonExp>;
  primary_address_amphoe?: Maybe<StringComparisonExp>;
  primary_address_district?: Maybe<StringComparisonExp>;
  primary_address_is_contact_address?: Maybe<BooleanComparisonExp>;
  primary_address_province?: Maybe<StringComparisonExp>;
  primary_address_street?: Maybe<StringComparisonExp>;
  primary_address_zipcode?: Maybe<StringComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
  title?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<StringComparisonExp>;
  working_address_amphoe?: Maybe<StringComparisonExp>;
  working_address_district?: Maybe<StringComparisonExp>;
  working_address_fax?: Maybe<StringComparisonExp>;
  working_address_is_contact_address?: Maybe<BooleanComparisonExp>;
  working_address_province?: Maybe<StringComparisonExp>;
  working_address_street?: Maybe<StringComparisonExp>;
  working_address_tel?: Maybe<StringComparisonExp>;
  working_address_zipcode?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "loan.application_committee" */
export enum LoanApplicationCommitteeConstraint {
  /** unique or primary key constraint */
  ApplicationDirectorPkey = 'application_director_pkey'
}

/** input type for incrementing numeric columns in table "loan.application_committee" */
export type LoanApplicationCommitteeIncInput = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.application_committee" */
export type LoanApplicationCommitteeInsertInput = {
  /** true = ใช้งาน, false = ไม่ใช้งาน */
  active?: Maybe<Scalars['Boolean']>;
  application_header?: Maybe<LoanApplicationHeaderObjRelInsertInput>;
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_header_id?: Maybe<Scalars['uuid']>;
  /** กรรมการผู้มีอำนาจลงนาม */
  authorization?: Maybe<Scalars['Boolean']>;
  /** เลขที่บัตรประจำตัวประชาชน */
  citizen_id?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ อำเภอ */
  contact_address_amphoe?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ ตำบล */
  contact_address_district?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ โทรสาร */
  contact_address_fax?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ เบอร์มือถือ */
  contact_address_mobile?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ จังหวัด */
  contact_address_province?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ ที่อยู่ */
  contact_address_street?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ โทรศัพท์ */
  contact_address_tel?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ รหัสไปรษณีย์ */
  contact_address_zipcode?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** true = มาจาก dbd, false ไม่ได้มาจาก dbd */
  from_dbd?: Maybe<Scalars['Boolean']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามทะเบียนบ้าน อำเภอ */
  primary_address_amphoe?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามทะเบียนบ้าน ตำบล */
  primary_address_district?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามสถานที่ติดต่อ */
  primary_address_is_contact_address?: Maybe<Scalars['Boolean']>;
  /** ที่อยู่ตามทะเบียนบ้าน จังหวัด */
  primary_address_province?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามทะเบียนบ้าน ที่อยู่ */
  primary_address_street?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามทะเบียนบ้าน รหัสไปรษณีย์ */
  primary_address_zipcode?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
  /** คำนำหน้า */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน อำเภอ */
  working_address_amphoe?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน ตำบล */
  working_address_district?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน โทรสาร */
  working_address_fax?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามสถานที่ติดต่อ */
  working_address_is_contact_address?: Maybe<Scalars['Boolean']>;
  /** สถานที่ทำงาน จังหวัด */
  working_address_province?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน ที่อยู่ */
  working_address_street?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน โทรศัพท์ */
  working_address_tel?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน รหัสไปรษณีย์ */
  working_address_zipcode?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LoanApplicationCommitteeMaxFields = {
  __typename?: 'loan_application_committee_max_fields';
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_header_id?: Maybe<Scalars['uuid']>;
  /** เลขที่บัตรประจำตัวประชาชน */
  citizen_id?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ อำเภอ */
  contact_address_amphoe?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ ตำบล */
  contact_address_district?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ โทรสาร */
  contact_address_fax?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ เบอร์มือถือ */
  contact_address_mobile?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ จังหวัด */
  contact_address_province?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ ที่อยู่ */
  contact_address_street?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ โทรศัพท์ */
  contact_address_tel?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ รหัสไปรษณีย์ */
  contact_address_zipcode?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามทะเบียนบ้าน อำเภอ */
  primary_address_amphoe?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามทะเบียนบ้าน ตำบล */
  primary_address_district?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามทะเบียนบ้าน จังหวัด */
  primary_address_province?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามทะเบียนบ้าน ที่อยู่ */
  primary_address_street?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามทะเบียนบ้าน รหัสไปรษณีย์ */
  primary_address_zipcode?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
  /** คำนำหน้า */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน อำเภอ */
  working_address_amphoe?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน ตำบล */
  working_address_district?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน โทรสาร */
  working_address_fax?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน จังหวัด */
  working_address_province?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน ที่อยู่ */
  working_address_street?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน โทรศัพท์ */
  working_address_tel?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน รหัสไปรษณีย์ */
  working_address_zipcode?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "loan.application_committee" */
export type LoanApplicationCommitteeMaxOrderBy = {
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_header_id?: Maybe<OrderBy>;
  /** เลขที่บัตรประจำตัวประชาชน */
  citizen_id?: Maybe<OrderBy>;
  /** สถานที่ติดต่อ อำเภอ */
  contact_address_amphoe?: Maybe<OrderBy>;
  /** สถานที่ติดต่อ ตำบล */
  contact_address_district?: Maybe<OrderBy>;
  /** สถานที่ติดต่อ โทรสาร */
  contact_address_fax?: Maybe<OrderBy>;
  /** สถานที่ติดต่อ เบอร์มือถือ */
  contact_address_mobile?: Maybe<OrderBy>;
  /** สถานที่ติดต่อ จังหวัด */
  contact_address_province?: Maybe<OrderBy>;
  /** สถานที่ติดต่อ ที่อยู่ */
  contact_address_street?: Maybe<OrderBy>;
  /** สถานที่ติดต่อ โทรศัพท์ */
  contact_address_tel?: Maybe<OrderBy>;
  /** สถานที่ติดต่อ รหัสไปรษณีย์ */
  contact_address_zipcode?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** ชื่อ */
  first_name?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** นามสกุล */
  last_name?: Maybe<OrderBy>;
  /** ที่อยู่ตามทะเบียนบ้าน อำเภอ */
  primary_address_amphoe?: Maybe<OrderBy>;
  /** ที่อยู่ตามทะเบียนบ้าน ตำบล */
  primary_address_district?: Maybe<OrderBy>;
  /** ที่อยู่ตามทะเบียนบ้าน จังหวัด */
  primary_address_province?: Maybe<OrderBy>;
  /** ที่อยู่ตามทะเบียนบ้าน ที่อยู่ */
  primary_address_street?: Maybe<OrderBy>;
  /** ที่อยู่ตามทะเบียนบ้าน รหัสไปรษณีย์ */
  primary_address_zipcode?: Maybe<OrderBy>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
  /** คำนำหน้า */
  title?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
  /** สถานที่ทำงาน อำเภอ */
  working_address_amphoe?: Maybe<OrderBy>;
  /** สถานที่ทำงาน ตำบล */
  working_address_district?: Maybe<OrderBy>;
  /** สถานที่ทำงาน โทรสาร */
  working_address_fax?: Maybe<OrderBy>;
  /** สถานที่ทำงาน จังหวัด */
  working_address_province?: Maybe<OrderBy>;
  /** สถานที่ทำงาน ที่อยู่ */
  working_address_street?: Maybe<OrderBy>;
  /** สถานที่ทำงาน โทรศัพท์ */
  working_address_tel?: Maybe<OrderBy>;
  /** สถานที่ทำงาน รหัสไปรษณีย์ */
  working_address_zipcode?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanApplicationCommitteeMinFields = {
  __typename?: 'loan_application_committee_min_fields';
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_header_id?: Maybe<Scalars['uuid']>;
  /** เลขที่บัตรประจำตัวประชาชน */
  citizen_id?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ อำเภอ */
  contact_address_amphoe?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ ตำบล */
  contact_address_district?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ โทรสาร */
  contact_address_fax?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ เบอร์มือถือ */
  contact_address_mobile?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ จังหวัด */
  contact_address_province?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ ที่อยู่ */
  contact_address_street?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ โทรศัพท์ */
  contact_address_tel?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ รหัสไปรษณีย์ */
  contact_address_zipcode?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามทะเบียนบ้าน อำเภอ */
  primary_address_amphoe?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามทะเบียนบ้าน ตำบล */
  primary_address_district?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามทะเบียนบ้าน จังหวัด */
  primary_address_province?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามทะเบียนบ้าน ที่อยู่ */
  primary_address_street?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามทะเบียนบ้าน รหัสไปรษณีย์ */
  primary_address_zipcode?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
  /** คำนำหน้า */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน อำเภอ */
  working_address_amphoe?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน ตำบล */
  working_address_district?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน โทรสาร */
  working_address_fax?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน จังหวัด */
  working_address_province?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน ที่อยู่ */
  working_address_street?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน โทรศัพท์ */
  working_address_tel?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน รหัสไปรษณีย์ */
  working_address_zipcode?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "loan.application_committee" */
export type LoanApplicationCommitteeMinOrderBy = {
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_header_id?: Maybe<OrderBy>;
  /** เลขที่บัตรประจำตัวประชาชน */
  citizen_id?: Maybe<OrderBy>;
  /** สถานที่ติดต่อ อำเภอ */
  contact_address_amphoe?: Maybe<OrderBy>;
  /** สถานที่ติดต่อ ตำบล */
  contact_address_district?: Maybe<OrderBy>;
  /** สถานที่ติดต่อ โทรสาร */
  contact_address_fax?: Maybe<OrderBy>;
  /** สถานที่ติดต่อ เบอร์มือถือ */
  contact_address_mobile?: Maybe<OrderBy>;
  /** สถานที่ติดต่อ จังหวัด */
  contact_address_province?: Maybe<OrderBy>;
  /** สถานที่ติดต่อ ที่อยู่ */
  contact_address_street?: Maybe<OrderBy>;
  /** สถานที่ติดต่อ โทรศัพท์ */
  contact_address_tel?: Maybe<OrderBy>;
  /** สถานที่ติดต่อ รหัสไปรษณีย์ */
  contact_address_zipcode?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** ชื่อ */
  first_name?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** นามสกุล */
  last_name?: Maybe<OrderBy>;
  /** ที่อยู่ตามทะเบียนบ้าน อำเภอ */
  primary_address_amphoe?: Maybe<OrderBy>;
  /** ที่อยู่ตามทะเบียนบ้าน ตำบล */
  primary_address_district?: Maybe<OrderBy>;
  /** ที่อยู่ตามทะเบียนบ้าน จังหวัด */
  primary_address_province?: Maybe<OrderBy>;
  /** ที่อยู่ตามทะเบียนบ้าน ที่อยู่ */
  primary_address_street?: Maybe<OrderBy>;
  /** ที่อยู่ตามทะเบียนบ้าน รหัสไปรษณีย์ */
  primary_address_zipcode?: Maybe<OrderBy>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
  /** คำนำหน้า */
  title?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
  /** สถานที่ทำงาน อำเภอ */
  working_address_amphoe?: Maybe<OrderBy>;
  /** สถานที่ทำงาน ตำบล */
  working_address_district?: Maybe<OrderBy>;
  /** สถานที่ทำงาน โทรสาร */
  working_address_fax?: Maybe<OrderBy>;
  /** สถานที่ทำงาน จังหวัด */
  working_address_province?: Maybe<OrderBy>;
  /** สถานที่ทำงาน ที่อยู่ */
  working_address_street?: Maybe<OrderBy>;
  /** สถานที่ทำงาน โทรศัพท์ */
  working_address_tel?: Maybe<OrderBy>;
  /** สถานที่ทำงาน รหัสไปรษณีย์ */
  working_address_zipcode?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan.application_committee" */
export type LoanApplicationCommitteeMutationResponse = {
  __typename?: 'loan_application_committee_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanApplicationCommittee>;
};

/** on conflict condition type for table "loan.application_committee" */
export type LoanApplicationCommitteeOnConflict = {
  constraint: LoanApplicationCommitteeConstraint;
  update_columns?: Array<LoanApplicationCommitteeUpdateColumn>;
  where?: Maybe<LoanApplicationCommitteeBoolExp>;
};

/** Ordering options when selecting data from "loan.application_committee". */
export type LoanApplicationCommitteeOrderBy = {
  active?: Maybe<OrderBy>;
  application_header?: Maybe<LoanApplicationHeaderOrderBy>;
  application_header_id?: Maybe<OrderBy>;
  authorization?: Maybe<OrderBy>;
  citizen_id?: Maybe<OrderBy>;
  contact_address_amphoe?: Maybe<OrderBy>;
  contact_address_district?: Maybe<OrderBy>;
  contact_address_fax?: Maybe<OrderBy>;
  contact_address_mobile?: Maybe<OrderBy>;
  contact_address_province?: Maybe<OrderBy>;
  contact_address_street?: Maybe<OrderBy>;
  contact_address_tel?: Maybe<OrderBy>;
  contact_address_zipcode?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  first_name?: Maybe<OrderBy>;
  from_dbd?: Maybe<OrderBy>;
  full_name?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  last_name?: Maybe<OrderBy>;
  primary_address_amphoe?: Maybe<OrderBy>;
  primary_address_district?: Maybe<OrderBy>;
  primary_address_is_contact_address?: Maybe<OrderBy>;
  primary_address_province?: Maybe<OrderBy>;
  primary_address_street?: Maybe<OrderBy>;
  primary_address_zipcode?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
  working_address_amphoe?: Maybe<OrderBy>;
  working_address_district?: Maybe<OrderBy>;
  working_address_fax?: Maybe<OrderBy>;
  working_address_is_contact_address?: Maybe<OrderBy>;
  working_address_province?: Maybe<OrderBy>;
  working_address_street?: Maybe<OrderBy>;
  working_address_tel?: Maybe<OrderBy>;
  working_address_zipcode?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_application_committee */
export type LoanApplicationCommitteePkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "loan.application_committee" */
export enum LoanApplicationCommitteeSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  ApplicationHeaderId = 'application_header_id',
  /** column name */
  Authorization = 'authorization',
  /** column name */
  CitizenId = 'citizen_id',
  /** column name */
  ContactAddressAmphoe = 'contact_address_amphoe',
  /** column name */
  ContactAddressDistrict = 'contact_address_district',
  /** column name */
  ContactAddressFax = 'contact_address_fax',
  /** column name */
  ContactAddressMobile = 'contact_address_mobile',
  /** column name */
  ContactAddressProvince = 'contact_address_province',
  /** column name */
  ContactAddressStreet = 'contact_address_street',
  /** column name */
  ContactAddressTel = 'contact_address_tel',
  /** column name */
  ContactAddressZipcode = 'contact_address_zipcode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FromDbd = 'from_dbd',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PrimaryAddressAmphoe = 'primary_address_amphoe',
  /** column name */
  PrimaryAddressDistrict = 'primary_address_district',
  /** column name */
  PrimaryAddressIsContactAddress = 'primary_address_is_contact_address',
  /** column name */
  PrimaryAddressProvince = 'primary_address_province',
  /** column name */
  PrimaryAddressStreet = 'primary_address_street',
  /** column name */
  PrimaryAddressZipcode = 'primary_address_zipcode',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  WorkingAddressAmphoe = 'working_address_amphoe',
  /** column name */
  WorkingAddressDistrict = 'working_address_district',
  /** column name */
  WorkingAddressFax = 'working_address_fax',
  /** column name */
  WorkingAddressIsContactAddress = 'working_address_is_contact_address',
  /** column name */
  WorkingAddressProvince = 'working_address_province',
  /** column name */
  WorkingAddressStreet = 'working_address_street',
  /** column name */
  WorkingAddressTel = 'working_address_tel',
  /** column name */
  WorkingAddressZipcode = 'working_address_zipcode'
}

/** input type for updating data in table "loan.application_committee" */
export type LoanApplicationCommitteeSetInput = {
  /** true = ใช้งาน, false = ไม่ใช้งาน */
  active?: Maybe<Scalars['Boolean']>;
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_header_id?: Maybe<Scalars['uuid']>;
  /** กรรมการผู้มีอำนาจลงนาม */
  authorization?: Maybe<Scalars['Boolean']>;
  /** เลขที่บัตรประจำตัวประชาชน */
  citizen_id?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ อำเภอ */
  contact_address_amphoe?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ ตำบล */
  contact_address_district?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ โทรสาร */
  contact_address_fax?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ เบอร์มือถือ */
  contact_address_mobile?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ จังหวัด */
  contact_address_province?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ ที่อยู่ */
  contact_address_street?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ โทรศัพท์ */
  contact_address_tel?: Maybe<Scalars['String']>;
  /** สถานที่ติดต่อ รหัสไปรษณีย์ */
  contact_address_zipcode?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** true = มาจาก dbd, false ไม่ได้มาจาก dbd */
  from_dbd?: Maybe<Scalars['Boolean']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามทะเบียนบ้าน อำเภอ */
  primary_address_amphoe?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามทะเบียนบ้าน ตำบล */
  primary_address_district?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามสถานที่ติดต่อ */
  primary_address_is_contact_address?: Maybe<Scalars['Boolean']>;
  /** ที่อยู่ตามทะเบียนบ้าน จังหวัด */
  primary_address_province?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามทะเบียนบ้าน ที่อยู่ */
  primary_address_street?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามทะเบียนบ้าน รหัสไปรษณีย์ */
  primary_address_zipcode?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
  /** คำนำหน้า */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน อำเภอ */
  working_address_amphoe?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน ตำบล */
  working_address_district?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน โทรสาร */
  working_address_fax?: Maybe<Scalars['String']>;
  /** ที่อยู่ตามสถานที่ติดต่อ */
  working_address_is_contact_address?: Maybe<Scalars['Boolean']>;
  /** สถานที่ทำงาน จังหวัด */
  working_address_province?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน ที่อยู่ */
  working_address_street?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน โทรศัพท์ */
  working_address_tel?: Maybe<Scalars['String']>;
  /** สถานที่ทำงาน รหัสไปรษณีย์ */
  working_address_zipcode?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type LoanApplicationCommitteeStddevFields = {
  __typename?: 'loan_application_committee_stddev_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "loan.application_committee" */
export type LoanApplicationCommitteeStddevOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LoanApplicationCommitteeStddevPopFields = {
  __typename?: 'loan_application_committee_stddev_pop_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "loan.application_committee" */
export type LoanApplicationCommitteeStddevPopOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LoanApplicationCommitteeStddevSampFields = {
  __typename?: 'loan_application_committee_stddev_samp_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "loan.application_committee" */
export type LoanApplicationCommitteeStddevSampOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LoanApplicationCommitteeSumFields = {
  __typename?: 'loan_application_committee_sum_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "loan.application_committee" */
export type LoanApplicationCommitteeSumOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "loan.application_committee" */
export enum LoanApplicationCommitteeUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  ApplicationHeaderId = 'application_header_id',
  /** column name */
  Authorization = 'authorization',
  /** column name */
  CitizenId = 'citizen_id',
  /** column name */
  ContactAddressAmphoe = 'contact_address_amphoe',
  /** column name */
  ContactAddressDistrict = 'contact_address_district',
  /** column name */
  ContactAddressFax = 'contact_address_fax',
  /** column name */
  ContactAddressMobile = 'contact_address_mobile',
  /** column name */
  ContactAddressProvince = 'contact_address_province',
  /** column name */
  ContactAddressStreet = 'contact_address_street',
  /** column name */
  ContactAddressTel = 'contact_address_tel',
  /** column name */
  ContactAddressZipcode = 'contact_address_zipcode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FromDbd = 'from_dbd',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PrimaryAddressAmphoe = 'primary_address_amphoe',
  /** column name */
  PrimaryAddressDistrict = 'primary_address_district',
  /** column name */
  PrimaryAddressIsContactAddress = 'primary_address_is_contact_address',
  /** column name */
  PrimaryAddressProvince = 'primary_address_province',
  /** column name */
  PrimaryAddressStreet = 'primary_address_street',
  /** column name */
  PrimaryAddressZipcode = 'primary_address_zipcode',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  WorkingAddressAmphoe = 'working_address_amphoe',
  /** column name */
  WorkingAddressDistrict = 'working_address_district',
  /** column name */
  WorkingAddressFax = 'working_address_fax',
  /** column name */
  WorkingAddressIsContactAddress = 'working_address_is_contact_address',
  /** column name */
  WorkingAddressProvince = 'working_address_province',
  /** column name */
  WorkingAddressStreet = 'working_address_street',
  /** column name */
  WorkingAddressTel = 'working_address_tel',
  /** column name */
  WorkingAddressZipcode = 'working_address_zipcode'
}

/** aggregate var_pop on columns */
export type LoanApplicationCommitteeVarPopFields = {
  __typename?: 'loan_application_committee_var_pop_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "loan.application_committee" */
export type LoanApplicationCommitteeVarPopOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LoanApplicationCommitteeVarSampFields = {
  __typename?: 'loan_application_committee_var_samp_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "loan.application_committee" */
export type LoanApplicationCommitteeVarSampOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LoanApplicationCommitteeVarianceFields = {
  __typename?: 'loan_application_committee_variance_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "loan.application_committee" */
export type LoanApplicationCommitteeVarianceOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/**
 * ชื่อธนาคาร/ผู้ให้กู้, ประเภทวงเงิน, วงเงินรวม
 *
 *
 * columns and relationships of "loan.application_current_loan"
 */
export type LoanApplicationCurrentLoan = {
  __typename?: 'loan_application_current_loan';
  /** An object relationship */
  application_detail: LoanApplicationDetail;
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_detail_id: Scalars['uuid'];
  /** ชื่อธนาคาร */
  bank_name?: Maybe<Scalars['String']>;
  /** วงเงิน */
  budget_total?: Maybe<Scalars['numeric']>;
  /** ประเภทที่กู้ */
  budget_type?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregated selection of "loan.application_current_loan" */
export type LoanApplicationCurrentLoanAggregate = {
  __typename?: 'loan_application_current_loan_aggregate';
  aggregate?: Maybe<LoanApplicationCurrentLoanAggregateFields>;
  nodes: Array<LoanApplicationCurrentLoan>;
};

/** aggregate fields of "loan.application_current_loan" */
export type LoanApplicationCurrentLoanAggregateFields = {
  __typename?: 'loan_application_current_loan_aggregate_fields';
  avg?: Maybe<LoanApplicationCurrentLoanAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanApplicationCurrentLoanMaxFields>;
  min?: Maybe<LoanApplicationCurrentLoanMinFields>;
  stddev?: Maybe<LoanApplicationCurrentLoanStddevFields>;
  stddev_pop?: Maybe<LoanApplicationCurrentLoanStddevPopFields>;
  stddev_samp?: Maybe<LoanApplicationCurrentLoanStddevSampFields>;
  sum?: Maybe<LoanApplicationCurrentLoanSumFields>;
  var_pop?: Maybe<LoanApplicationCurrentLoanVarPopFields>;
  var_samp?: Maybe<LoanApplicationCurrentLoanVarSampFields>;
  variance?: Maybe<LoanApplicationCurrentLoanVarianceFields>;
};


/** aggregate fields of "loan.application_current_loan" */
export type LoanApplicationCurrentLoanAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanApplicationCurrentLoanSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan.application_current_loan" */
export type LoanApplicationCurrentLoanAggregateOrderBy = {
  avg?: Maybe<LoanApplicationCurrentLoanAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanApplicationCurrentLoanMaxOrderBy>;
  min?: Maybe<LoanApplicationCurrentLoanMinOrderBy>;
  stddev?: Maybe<LoanApplicationCurrentLoanStddevOrderBy>;
  stddev_pop?: Maybe<LoanApplicationCurrentLoanStddevPopOrderBy>;
  stddev_samp?: Maybe<LoanApplicationCurrentLoanStddevSampOrderBy>;
  sum?: Maybe<LoanApplicationCurrentLoanSumOrderBy>;
  var_pop?: Maybe<LoanApplicationCurrentLoanVarPopOrderBy>;
  var_samp?: Maybe<LoanApplicationCurrentLoanVarSampOrderBy>;
  variance?: Maybe<LoanApplicationCurrentLoanVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "loan.application_current_loan" */
export type LoanApplicationCurrentLoanArrRelInsertInput = {
  data: Array<LoanApplicationCurrentLoanInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanApplicationCurrentLoanOnConflict>;
};

/** aggregate avg on columns */
export type LoanApplicationCurrentLoanAvgFields = {
  __typename?: 'loan_application_current_loan_avg_fields';
  /** วงเงิน */
  budget_total?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "loan.application_current_loan" */
export type LoanApplicationCurrentLoanAvgOrderBy = {
  /** วงเงิน */
  budget_total?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "loan.application_current_loan". All fields are combined with a logical 'AND'. */
export type LoanApplicationCurrentLoanBoolExp = {
  _and?: Maybe<Array<LoanApplicationCurrentLoanBoolExp>>;
  _not?: Maybe<LoanApplicationCurrentLoanBoolExp>;
  _or?: Maybe<Array<LoanApplicationCurrentLoanBoolExp>>;
  application_detail?: Maybe<LoanApplicationDetailBoolExp>;
  application_detail_id?: Maybe<UuidComparisonExp>;
  bank_name?: Maybe<StringComparisonExp>;
  budget_total?: Maybe<NumericComparisonExp>;
  budget_type?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "loan.application_current_loan" */
export enum LoanApplicationCurrentLoanConstraint {
  /** unique or primary key constraint */
  ApplicationCurrentLoanPkey = 'application_current_loan_pkey'
}

/** input type for incrementing numeric columns in table "loan.application_current_loan" */
export type LoanApplicationCurrentLoanIncInput = {
  /** วงเงิน */
  budget_total?: Maybe<Scalars['numeric']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.application_current_loan" */
export type LoanApplicationCurrentLoanInsertInput = {
  application_detail?: Maybe<LoanApplicationDetailObjRelInsertInput>;
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_detail_id?: Maybe<Scalars['uuid']>;
  /** ชื่อธนาคาร */
  bank_name?: Maybe<Scalars['String']>;
  /** วงเงิน */
  budget_total?: Maybe<Scalars['numeric']>;
  /** ประเภทที่กู้ */
  budget_type?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LoanApplicationCurrentLoanMaxFields = {
  __typename?: 'loan_application_current_loan_max_fields';
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_detail_id?: Maybe<Scalars['uuid']>;
  /** ชื่อธนาคาร */
  bank_name?: Maybe<Scalars['String']>;
  /** วงเงิน */
  budget_total?: Maybe<Scalars['numeric']>;
  /** ประเภทที่กู้ */
  budget_type?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "loan.application_current_loan" */
export type LoanApplicationCurrentLoanMaxOrderBy = {
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_detail_id?: Maybe<OrderBy>;
  /** ชื่อธนาคาร */
  bank_name?: Maybe<OrderBy>;
  /** วงเงิน */
  budget_total?: Maybe<OrderBy>;
  /** ประเภทที่กู้ */
  budget_type?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขโดย */
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanApplicationCurrentLoanMinFields = {
  __typename?: 'loan_application_current_loan_min_fields';
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_detail_id?: Maybe<Scalars['uuid']>;
  /** ชื่อธนาคาร */
  bank_name?: Maybe<Scalars['String']>;
  /** วงเงิน */
  budget_total?: Maybe<Scalars['numeric']>;
  /** ประเภทที่กู้ */
  budget_type?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "loan.application_current_loan" */
export type LoanApplicationCurrentLoanMinOrderBy = {
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_detail_id?: Maybe<OrderBy>;
  /** ชื่อธนาคาร */
  bank_name?: Maybe<OrderBy>;
  /** วงเงิน */
  budget_total?: Maybe<OrderBy>;
  /** ประเภทที่กู้ */
  budget_type?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขโดย */
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan.application_current_loan" */
export type LoanApplicationCurrentLoanMutationResponse = {
  __typename?: 'loan_application_current_loan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanApplicationCurrentLoan>;
};

/** on conflict condition type for table "loan.application_current_loan" */
export type LoanApplicationCurrentLoanOnConflict = {
  constraint: LoanApplicationCurrentLoanConstraint;
  update_columns?: Array<LoanApplicationCurrentLoanUpdateColumn>;
  where?: Maybe<LoanApplicationCurrentLoanBoolExp>;
};

/** Ordering options when selecting data from "loan.application_current_loan". */
export type LoanApplicationCurrentLoanOrderBy = {
  application_detail?: Maybe<LoanApplicationDetailOrderBy>;
  application_detail_id?: Maybe<OrderBy>;
  bank_name?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  budget_type?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_application_current_loan */
export type LoanApplicationCurrentLoanPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "loan.application_current_loan" */
export enum LoanApplicationCurrentLoanSelectColumn {
  /** column name */
  ApplicationDetailId = 'application_detail_id',
  /** column name */
  BankName = 'bank_name',
  /** column name */
  BudgetTotal = 'budget_total',
  /** column name */
  BudgetType = 'budget_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "loan.application_current_loan" */
export type LoanApplicationCurrentLoanSetInput = {
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_detail_id?: Maybe<Scalars['uuid']>;
  /** ชื่อธนาคาร */
  bank_name?: Maybe<Scalars['String']>;
  /** วงเงิน */
  budget_total?: Maybe<Scalars['numeric']>;
  /** ประเภทที่กู้ */
  budget_type?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type LoanApplicationCurrentLoanStddevFields = {
  __typename?: 'loan_application_current_loan_stddev_fields';
  /** วงเงิน */
  budget_total?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "loan.application_current_loan" */
export type LoanApplicationCurrentLoanStddevOrderBy = {
  /** วงเงิน */
  budget_total?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LoanApplicationCurrentLoanStddevPopFields = {
  __typename?: 'loan_application_current_loan_stddev_pop_fields';
  /** วงเงิน */
  budget_total?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "loan.application_current_loan" */
export type LoanApplicationCurrentLoanStddevPopOrderBy = {
  /** วงเงิน */
  budget_total?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LoanApplicationCurrentLoanStddevSampFields = {
  __typename?: 'loan_application_current_loan_stddev_samp_fields';
  /** วงเงิน */
  budget_total?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "loan.application_current_loan" */
export type LoanApplicationCurrentLoanStddevSampOrderBy = {
  /** วงเงิน */
  budget_total?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LoanApplicationCurrentLoanSumFields = {
  __typename?: 'loan_application_current_loan_sum_fields';
  /** วงเงิน */
  budget_total?: Maybe<Scalars['numeric']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "loan.application_current_loan" */
export type LoanApplicationCurrentLoanSumOrderBy = {
  /** วงเงิน */
  budget_total?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "loan.application_current_loan" */
export enum LoanApplicationCurrentLoanUpdateColumn {
  /** column name */
  ApplicationDetailId = 'application_detail_id',
  /** column name */
  BankName = 'bank_name',
  /** column name */
  BudgetTotal = 'budget_total',
  /** column name */
  BudgetType = 'budget_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type LoanApplicationCurrentLoanVarPopFields = {
  __typename?: 'loan_application_current_loan_var_pop_fields';
  /** วงเงิน */
  budget_total?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "loan.application_current_loan" */
export type LoanApplicationCurrentLoanVarPopOrderBy = {
  /** วงเงิน */
  budget_total?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LoanApplicationCurrentLoanVarSampFields = {
  __typename?: 'loan_application_current_loan_var_samp_fields';
  /** วงเงิน */
  budget_total?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "loan.application_current_loan" */
export type LoanApplicationCurrentLoanVarSampOrderBy = {
  /** วงเงิน */
  budget_total?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LoanApplicationCurrentLoanVarianceFields = {
  __typename?: 'loan_application_current_loan_variance_fields';
  /** วงเงิน */
  budget_total?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "loan.application_current_loan" */
export type LoanApplicationCurrentLoanVarianceOrderBy = {
  /** วงเงิน */
  budget_total?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/**
 * รายละเอียดใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_detail"
 */
export type LoanApplicationDetail = {
  __typename?: 'loan_application_detail';
  /** An object relationship */
  account_type?: Maybe<LoanAccountType>;
  /** Freeze data ปัจจุบันกิจการมีการบันทึกรายได้ และงบการเงินของกิจกรรมในรูปแบบใด */
  account_type_description?: Maybe<Scalars['String']>;
  /** อืน ๆ free text */
  account_type_else?: Maybe<Scalars['String']>;
  /** ปัจจุบันกิจการมีการบันทึกรายได้ และงบการเงินของกิจกรรมในรูปแบบใด */
  account_type_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  application_business_operations: Array<LoanApplicationBusinessOperation>;
  /** An aggregate relationship */
  application_business_operations_aggregate: LoanApplicationBusinessOperationAggregate;
  /** An array relationship */
  application_current_loans: Array<LoanApplicationCurrentLoan>;
  /** An aggregate relationship */
  application_current_loans_aggregate: LoanApplicationCurrentLoanAggregate;
  /** An object relationship */
  application_header: LoanApplicationHeader;
  /** มีความประสงค์ที่จะได้รับการส่งเสริมพัฒนาจากกองทุน ในด้านใด */
  business_proposal?: Maybe<Scalars['jsonb']>;
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost: Scalars['numeric'];
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash: Scalars['numeric'];
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit: Scalars['numeric'];
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day: Scalars['numeric'];
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total: Scalars['numeric'];
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day: Scalars['numeric'];
  /** An object relationship */
  current_loan?: Maybe<LoanCurrentLoan>;
  /** freeze data มีวงเงินสินเชื่อ */
  current_loan_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  current_loan_else?: Maybe<Scalars['String']>;
  /** มีวงเงินสินเชื่อ */
  current_loan_id?: Maybe<Scalars['String']>;
  /** รายชื่อสถาบันการเงินที่มีวงเงินสินเชื่อ หรือ หนี้สินนอกระบบ */
  current_loan_list?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  debt_restructuring?: Maybe<LoanDebtRestructuring>;
  /** freeze data ปรับโครงสร้างหนี้ */
  debt_restructuring_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  debt_restructuring_else?: Maybe<Scalars['String']>;
  /** ปรับโครงสร้างหนี้ */
  debt_restructuring_id?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit: Scalars['numeric'];
  /** An object relationship */
  financial_agreement?: Maybe<LoanFinancialAgreement>;
  /** freeze data การนำส่งข้อมูลทางบัญชีของกิจการ */
  financial_agreement_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ (ระบุ) */
  financial_agreement_else?: Maybe<Scalars['String']>;
  /** การนำส่งข้อมูลทางบัญชีของกิจการ */
  financial_agreement_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  financial_rescue?: Maybe<LoanFinancialRescue>;
  /** freeze data ความช่วยเหลือด้านการเงิน */
  financial_rescue_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  financial_rescue_else?: Maybe<Scalars['String']>;
  /** ความช่วยเหลือด้านการเงิน */
  financial_rescue_id?: Maybe<Scalars['String']>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total: Scalars['numeric'];
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash: Scalars['numeric'];
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit: Scalars['numeric'];
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day: Scalars['numeric'];
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent: Scalars['numeric'];
  /** กำไรขั้นต้น (บาท) */
  gross_profit: Scalars['numeric'];
  /** Primary key */
  id: Scalars['uuid'];
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation: Scalars['numeric'];
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash: Scalars['numeric'];
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit: Scalars['numeric'];
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day: Scalars['numeric'];
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent: Scalars['numeric'];
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit: Scalars['numeric'];
  /** An object relationship */
  proprietary?: Maybe<LoanProprietary>;
  /** freeze data กรรมสิทธิ์ */
  proprietary_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  proprietary_else?: Maybe<Scalars['String']>;
  /** กรรมสิทธิ์ */
  proprietary_id?: Maybe<Scalars['String']>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total: Scalars['numeric'];
  /** An object relationship */
  repay_type?: Maybe<LoanRepayType>;
  /** freeze data ประวัติการชำระหนี้ */
  repay_type_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  repay_type_else?: Maybe<Scalars['String']>;
  /** ประวัติการชำระหนี้ */
  repay_type_id?: Maybe<Scalars['String']>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost: Scalars['numeric'];
  /** An object relationship */
  sme_definition?: Maybe<LoanSmeDefinition>;
  /** freeze data คำนิยาม SMEs */
  sme_definition_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ */
  sme_definition_else?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิง คำนิยาม SMEs */
  sme_definition_id?: Maybe<Scalars['uuid']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขโดย */
  updated_by?: Maybe<Scalars['String']>;
};


/**
 * รายละเอียดใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_detail"
 */
export type LoanApplicationDetailApplicationBusinessOperationsArgs = {
  distinct_on?: Maybe<Array<LoanApplicationBusinessOperationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationBusinessOperationOrderBy>>;
  where?: Maybe<LoanApplicationBusinessOperationBoolExp>;
};


/**
 * รายละเอียดใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_detail"
 */
export type LoanApplicationDetailApplicationBusinessOperationsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationBusinessOperationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationBusinessOperationOrderBy>>;
  where?: Maybe<LoanApplicationBusinessOperationBoolExp>;
};


/**
 * รายละเอียดใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_detail"
 */
export type LoanApplicationDetailApplicationCurrentLoansArgs = {
  distinct_on?: Maybe<Array<LoanApplicationCurrentLoanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationCurrentLoanOrderBy>>;
  where?: Maybe<LoanApplicationCurrentLoanBoolExp>;
};


/**
 * รายละเอียดใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_detail"
 */
export type LoanApplicationDetailApplicationCurrentLoansAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationCurrentLoanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationCurrentLoanOrderBy>>;
  where?: Maybe<LoanApplicationCurrentLoanBoolExp>;
};


/**
 * รายละเอียดใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_detail"
 */
export type LoanApplicationDetailBusinessProposalArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * รายละเอียดใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_detail"
 */
export type LoanApplicationDetailCurrentLoanListArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "loan.application_detail" */
export type LoanApplicationDetailAggregate = {
  __typename?: 'loan_application_detail_aggregate';
  aggregate?: Maybe<LoanApplicationDetailAggregateFields>;
  nodes: Array<LoanApplicationDetail>;
};

/** aggregate fields of "loan.application_detail" */
export type LoanApplicationDetailAggregateFields = {
  __typename?: 'loan_application_detail_aggregate_fields';
  avg?: Maybe<LoanApplicationDetailAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanApplicationDetailMaxFields>;
  min?: Maybe<LoanApplicationDetailMinFields>;
  stddev?: Maybe<LoanApplicationDetailStddevFields>;
  stddev_pop?: Maybe<LoanApplicationDetailStddevPopFields>;
  stddev_samp?: Maybe<LoanApplicationDetailStddevSampFields>;
  sum?: Maybe<LoanApplicationDetailSumFields>;
  var_pop?: Maybe<LoanApplicationDetailVarPopFields>;
  var_samp?: Maybe<LoanApplicationDetailVarSampFields>;
  variance?: Maybe<LoanApplicationDetailVarianceFields>;
};


/** aggregate fields of "loan.application_detail" */
export type LoanApplicationDetailAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanApplicationDetailSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan.application_detail" */
export type LoanApplicationDetailAggregateOrderBy = {
  avg?: Maybe<LoanApplicationDetailAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanApplicationDetailMaxOrderBy>;
  min?: Maybe<LoanApplicationDetailMinOrderBy>;
  stddev?: Maybe<LoanApplicationDetailStddevOrderBy>;
  stddev_pop?: Maybe<LoanApplicationDetailStddevPopOrderBy>;
  stddev_samp?: Maybe<LoanApplicationDetailStddevSampOrderBy>;
  sum?: Maybe<LoanApplicationDetailSumOrderBy>;
  var_pop?: Maybe<LoanApplicationDetailVarPopOrderBy>;
  var_samp?: Maybe<LoanApplicationDetailVarSampOrderBy>;
  variance?: Maybe<LoanApplicationDetailVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type LoanApplicationDetailAppendInput = {
  /** มีความประสงค์ที่จะได้รับการส่งเสริมพัฒนาจากกองทุน ในด้านใด */
  business_proposal?: Maybe<Scalars['jsonb']>;
  /** รายชื่อสถาบันการเงินที่มีวงเงินสินเชื่อ หรือ หนี้สินนอกระบบ */
  current_loan_list?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "loan.application_detail" */
export type LoanApplicationDetailArrRelInsertInput = {
  data: Array<LoanApplicationDetailInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanApplicationDetailOnConflict>;
};

/** aggregate avg on columns */
export type LoanApplicationDetailAvgFields = {
  __typename?: 'loan_application_detail_avg_fields';
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost?: Maybe<Scalars['Float']>;
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash?: Maybe<Scalars['Float']>;
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit?: Maybe<Scalars['Float']>;
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day?: Maybe<Scalars['Float']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total?: Maybe<Scalars['Float']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day?: Maybe<Scalars['Float']>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit?: Maybe<Scalars['Float']>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent?: Maybe<Scalars['Float']>;
  /** กำไรขั้นต้น (บาท) */
  gross_profit?: Maybe<Scalars['Float']>;
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent?: Maybe<Scalars['Float']>;
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit?: Maybe<Scalars['Float']>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total?: Maybe<Scalars['Float']>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "loan.application_detail" */
export type LoanApplicationDetailAvgOrderBy = {
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost?: Maybe<OrderBy>;
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash?: Maybe<OrderBy>;
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit?: Maybe<OrderBy>;
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day?: Maybe<OrderBy>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total?: Maybe<OrderBy>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day?: Maybe<OrderBy>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit?: Maybe<OrderBy>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent?: Maybe<OrderBy>;
  /** กำไรขั้นต้น (บาท) */
  gross_profit?: Maybe<OrderBy>;
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent?: Maybe<OrderBy>;
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit?: Maybe<OrderBy>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total?: Maybe<OrderBy>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "loan.application_detail". All fields are combined with a logical 'AND'. */
export type LoanApplicationDetailBoolExp = {
  _and?: Maybe<Array<LoanApplicationDetailBoolExp>>;
  _not?: Maybe<LoanApplicationDetailBoolExp>;
  _or?: Maybe<Array<LoanApplicationDetailBoolExp>>;
  account_type?: Maybe<LoanAccountTypeBoolExp>;
  account_type_description?: Maybe<StringComparisonExp>;
  account_type_else?: Maybe<StringComparisonExp>;
  account_type_id?: Maybe<StringComparisonExp>;
  application_business_operations?: Maybe<LoanApplicationBusinessOperationBoolExp>;
  application_current_loans?: Maybe<LoanApplicationCurrentLoanBoolExp>;
  application_header?: Maybe<LoanApplicationHeaderBoolExp>;
  business_proposal?: Maybe<JsonbComparisonExp>;
  cost?: Maybe<NumericComparisonExp>;
  cost_cash?: Maybe<NumericComparisonExp>;
  cost_credit?: Maybe<NumericComparisonExp>;
  cost_day?: Maybe<NumericComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<StringComparisonExp>;
  current_liability_amount_total?: Maybe<NumericComparisonExp>;
  current_liability_day?: Maybe<NumericComparisonExp>;
  current_loan?: Maybe<LoanCurrentLoanBoolExp>;
  current_loan_description?: Maybe<StringComparisonExp>;
  current_loan_else?: Maybe<StringComparisonExp>;
  current_loan_id?: Maybe<StringComparisonExp>;
  current_loan_list?: Maybe<JsonbComparisonExp>;
  debt_restructuring?: Maybe<LoanDebtRestructuringBoolExp>;
  debt_restructuring_description?: Maybe<StringComparisonExp>;
  debt_restructuring_else?: Maybe<StringComparisonExp>;
  debt_restructuring_id?: Maybe<StringComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  earned_income_credit?: Maybe<NumericComparisonExp>;
  financial_agreement?: Maybe<LoanFinancialAgreementBoolExp>;
  financial_agreement_description?: Maybe<StringComparisonExp>;
  financial_agreement_else?: Maybe<StringComparisonExp>;
  financial_agreement_id?: Maybe<StringComparisonExp>;
  financial_rescue?: Maybe<LoanFinancialRescueBoolExp>;
  financial_rescue_description?: Maybe<StringComparisonExp>;
  financial_rescue_else?: Maybe<StringComparisonExp>;
  financial_rescue_id?: Maybe<StringComparisonExp>;
  finished_goods_amount_total?: Maybe<NumericComparisonExp>;
  foreign_income_cash?: Maybe<NumericComparisonExp>;
  foreign_income_credit?: Maybe<NumericComparisonExp>;
  foreign_income_day?: Maybe<NumericComparisonExp>;
  foreign_income_percent?: Maybe<NumericComparisonExp>;
  gross_profit?: Maybe<NumericComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  inventory_valuation?: Maybe<NumericComparisonExp>;
  local_income_cash?: Maybe<NumericComparisonExp>;
  local_income_credit?: Maybe<NumericComparisonExp>;
  local_income_day?: Maybe<NumericComparisonExp>;
  local_income_percent?: Maybe<NumericComparisonExp>;
  net_profit?: Maybe<NumericComparisonExp>;
  proprietary?: Maybe<LoanProprietaryBoolExp>;
  proprietary_description?: Maybe<StringComparisonExp>;
  proprietary_else?: Maybe<StringComparisonExp>;
  proprietary_id?: Maybe<StringComparisonExp>;
  raw_material_amount_total?: Maybe<NumericComparisonExp>;
  repay_type?: Maybe<LoanRepayTypeBoolExp>;
  repay_type_description?: Maybe<StringComparisonExp>;
  repay_type_else?: Maybe<StringComparisonExp>;
  repay_type_id?: Maybe<StringComparisonExp>;
  service_cost?: Maybe<NumericComparisonExp>;
  sme_definition?: Maybe<LoanSmeDefinitionBoolExp>;
  sme_definition_description?: Maybe<StringComparisonExp>;
  sme_definition_else?: Maybe<StringComparisonExp>;
  sme_definition_id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "loan.application_detail" */
export enum LoanApplicationDetailConstraint {
  /** unique or primary key constraint */
  ApplicationDetailPkey = 'application_detail_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type LoanApplicationDetailDeleteAtPathInput = {
  /** มีความประสงค์ที่จะได้รับการส่งเสริมพัฒนาจากกองทุน ในด้านใด */
  business_proposal?: Maybe<Array<Scalars['String']>>;
  /** รายชื่อสถาบันการเงินที่มีวงเงินสินเชื่อ หรือ หนี้สินนอกระบบ */
  current_loan_list?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type LoanApplicationDetailDeleteElemInput = {
  /** มีความประสงค์ที่จะได้รับการส่งเสริมพัฒนาจากกองทุน ในด้านใด */
  business_proposal?: Maybe<Scalars['Int']>;
  /** รายชื่อสถาบันการเงินที่มีวงเงินสินเชื่อ หรือ หนี้สินนอกระบบ */
  current_loan_list?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type LoanApplicationDetailDeleteKeyInput = {
  /** มีความประสงค์ที่จะได้รับการส่งเสริมพัฒนาจากกองทุน ในด้านใด */
  business_proposal?: Maybe<Scalars['String']>;
  /** รายชื่อสถาบันการเงินที่มีวงเงินสินเชื่อ หรือ หนี้สินนอกระบบ */
  current_loan_list?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "loan.application_detail" */
export type LoanApplicationDetailIncInput = {
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost?: Maybe<Scalars['numeric']>;
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash?: Maybe<Scalars['numeric']>;
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit?: Maybe<Scalars['numeric']>;
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day?: Maybe<Scalars['numeric']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total?: Maybe<Scalars['numeric']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day?: Maybe<Scalars['numeric']>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit?: Maybe<Scalars['numeric']>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent?: Maybe<Scalars['numeric']>;
  /** กำไรขั้นต้น (บาท) */
  gross_profit?: Maybe<Scalars['numeric']>;
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent?: Maybe<Scalars['numeric']>;
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit?: Maybe<Scalars['numeric']>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total?: Maybe<Scalars['numeric']>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.application_detail" */
export type LoanApplicationDetailInsertInput = {
  account_type?: Maybe<LoanAccountTypeObjRelInsertInput>;
  /** Freeze data ปัจจุบันกิจการมีการบันทึกรายได้ และงบการเงินของกิจกรรมในรูปแบบใด */
  account_type_description?: Maybe<Scalars['String']>;
  /** อืน ๆ free text */
  account_type_else?: Maybe<Scalars['String']>;
  /** ปัจจุบันกิจการมีการบันทึกรายได้ และงบการเงินของกิจกรรมในรูปแบบใด */
  account_type_id?: Maybe<Scalars['String']>;
  application_business_operations?: Maybe<LoanApplicationBusinessOperationArrRelInsertInput>;
  application_current_loans?: Maybe<LoanApplicationCurrentLoanArrRelInsertInput>;
  application_header?: Maybe<LoanApplicationHeaderObjRelInsertInput>;
  /** มีความประสงค์ที่จะได้รับการส่งเสริมพัฒนาจากกองทุน ในด้านใด */
  business_proposal?: Maybe<Scalars['jsonb']>;
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost?: Maybe<Scalars['numeric']>;
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash?: Maybe<Scalars['numeric']>;
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit?: Maybe<Scalars['numeric']>;
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day?: Maybe<Scalars['numeric']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total?: Maybe<Scalars['numeric']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day?: Maybe<Scalars['numeric']>;
  current_loan?: Maybe<LoanCurrentLoanObjRelInsertInput>;
  /** freeze data มีวงเงินสินเชื่อ */
  current_loan_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  current_loan_else?: Maybe<Scalars['String']>;
  /** มีวงเงินสินเชื่อ */
  current_loan_id?: Maybe<Scalars['String']>;
  /** รายชื่อสถาบันการเงินที่มีวงเงินสินเชื่อ หรือ หนี้สินนอกระบบ */
  current_loan_list?: Maybe<Scalars['jsonb']>;
  debt_restructuring?: Maybe<LoanDebtRestructuringObjRelInsertInput>;
  /** freeze data ปรับโครงสร้างหนี้ */
  debt_restructuring_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  debt_restructuring_else?: Maybe<Scalars['String']>;
  /** ปรับโครงสร้างหนี้ */
  debt_restructuring_id?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit?: Maybe<Scalars['numeric']>;
  financial_agreement?: Maybe<LoanFinancialAgreementObjRelInsertInput>;
  /** freeze data การนำส่งข้อมูลทางบัญชีของกิจการ */
  financial_agreement_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ (ระบุ) */
  financial_agreement_else?: Maybe<Scalars['String']>;
  /** การนำส่งข้อมูลทางบัญชีของกิจการ */
  financial_agreement_id?: Maybe<Scalars['String']>;
  financial_rescue?: Maybe<LoanFinancialRescueObjRelInsertInput>;
  /** freeze data ความช่วยเหลือด้านการเงิน */
  financial_rescue_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  financial_rescue_else?: Maybe<Scalars['String']>;
  /** ความช่วยเหลือด้านการเงิน */
  financial_rescue_id?: Maybe<Scalars['String']>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent?: Maybe<Scalars['numeric']>;
  /** กำไรขั้นต้น (บาท) */
  gross_profit?: Maybe<Scalars['numeric']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent?: Maybe<Scalars['numeric']>;
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit?: Maybe<Scalars['numeric']>;
  proprietary?: Maybe<LoanProprietaryObjRelInsertInput>;
  /** freeze data กรรมสิทธิ์ */
  proprietary_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  proprietary_else?: Maybe<Scalars['String']>;
  /** กรรมสิทธิ์ */
  proprietary_id?: Maybe<Scalars['String']>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total?: Maybe<Scalars['numeric']>;
  repay_type?: Maybe<LoanRepayTypeObjRelInsertInput>;
  /** freeze data ประวัติการชำระหนี้ */
  repay_type_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  repay_type_else?: Maybe<Scalars['String']>;
  /** ประวัติการชำระหนี้ */
  repay_type_id?: Maybe<Scalars['String']>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost?: Maybe<Scalars['numeric']>;
  sme_definition?: Maybe<LoanSmeDefinitionObjRelInsertInput>;
  /** freeze data คำนิยาม SMEs */
  sme_definition_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ */
  sme_definition_else?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิง คำนิยาม SMEs */
  sme_definition_id?: Maybe<Scalars['uuid']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LoanApplicationDetailMaxFields = {
  __typename?: 'loan_application_detail_max_fields';
  /** Freeze data ปัจจุบันกิจการมีการบันทึกรายได้ และงบการเงินของกิจกรรมในรูปแบบใด */
  account_type_description?: Maybe<Scalars['String']>;
  /** อืน ๆ free text */
  account_type_else?: Maybe<Scalars['String']>;
  /** ปัจจุบันกิจการมีการบันทึกรายได้ และงบการเงินของกิจกรรมในรูปแบบใด */
  account_type_id?: Maybe<Scalars['String']>;
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost?: Maybe<Scalars['numeric']>;
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash?: Maybe<Scalars['numeric']>;
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit?: Maybe<Scalars['numeric']>;
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day?: Maybe<Scalars['numeric']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total?: Maybe<Scalars['numeric']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day?: Maybe<Scalars['numeric']>;
  /** freeze data มีวงเงินสินเชื่อ */
  current_loan_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  current_loan_else?: Maybe<Scalars['String']>;
  /** มีวงเงินสินเชื่อ */
  current_loan_id?: Maybe<Scalars['String']>;
  /** freeze data ปรับโครงสร้างหนี้ */
  debt_restructuring_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  debt_restructuring_else?: Maybe<Scalars['String']>;
  /** ปรับโครงสร้างหนี้ */
  debt_restructuring_id?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit?: Maybe<Scalars['numeric']>;
  /** freeze data การนำส่งข้อมูลทางบัญชีของกิจการ */
  financial_agreement_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ (ระบุ) */
  financial_agreement_else?: Maybe<Scalars['String']>;
  /** การนำส่งข้อมูลทางบัญชีของกิจการ */
  financial_agreement_id?: Maybe<Scalars['String']>;
  /** freeze data ความช่วยเหลือด้านการเงิน */
  financial_rescue_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  financial_rescue_else?: Maybe<Scalars['String']>;
  /** ความช่วยเหลือด้านการเงิน */
  financial_rescue_id?: Maybe<Scalars['String']>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent?: Maybe<Scalars['numeric']>;
  /** กำไรขั้นต้น (บาท) */
  gross_profit?: Maybe<Scalars['numeric']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent?: Maybe<Scalars['numeric']>;
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit?: Maybe<Scalars['numeric']>;
  /** freeze data กรรมสิทธิ์ */
  proprietary_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  proprietary_else?: Maybe<Scalars['String']>;
  /** กรรมสิทธิ์ */
  proprietary_id?: Maybe<Scalars['String']>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total?: Maybe<Scalars['numeric']>;
  /** freeze data ประวัติการชำระหนี้ */
  repay_type_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  repay_type_else?: Maybe<Scalars['String']>;
  /** ประวัติการชำระหนี้ */
  repay_type_id?: Maybe<Scalars['String']>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost?: Maybe<Scalars['numeric']>;
  /** freeze data คำนิยาม SMEs */
  sme_definition_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ */
  sme_definition_else?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิง คำนิยาม SMEs */
  sme_definition_id?: Maybe<Scalars['uuid']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "loan.application_detail" */
export type LoanApplicationDetailMaxOrderBy = {
  /** Freeze data ปัจจุบันกิจการมีการบันทึกรายได้ และงบการเงินของกิจกรรมในรูปแบบใด */
  account_type_description?: Maybe<OrderBy>;
  /** อืน ๆ free text */
  account_type_else?: Maybe<OrderBy>;
  /** ปัจจุบันกิจการมีการบันทึกรายได้ และงบการเงินของกิจกรรมในรูปแบบใด */
  account_type_id?: Maybe<OrderBy>;
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost?: Maybe<OrderBy>;
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash?: Maybe<OrderBy>;
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit?: Maybe<OrderBy>;
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total?: Maybe<OrderBy>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day?: Maybe<OrderBy>;
  /** freeze data มีวงเงินสินเชื่อ */
  current_loan_description?: Maybe<OrderBy>;
  /** อื่น ๆ free text */
  current_loan_else?: Maybe<OrderBy>;
  /** มีวงเงินสินเชื่อ */
  current_loan_id?: Maybe<OrderBy>;
  /** freeze data ปรับโครงสร้างหนี้ */
  debt_restructuring_description?: Maybe<OrderBy>;
  /** อื่น ๆ free text */
  debt_restructuring_else?: Maybe<OrderBy>;
  /** ปรับโครงสร้างหนี้ */
  debt_restructuring_id?: Maybe<OrderBy>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit?: Maybe<OrderBy>;
  /** freeze data การนำส่งข้อมูลทางบัญชีของกิจการ */
  financial_agreement_description?: Maybe<OrderBy>;
  /** อื่น ๆ (ระบุ) */
  financial_agreement_else?: Maybe<OrderBy>;
  /** การนำส่งข้อมูลทางบัญชีของกิจการ */
  financial_agreement_id?: Maybe<OrderBy>;
  /** freeze data ความช่วยเหลือด้านการเงิน */
  financial_rescue_description?: Maybe<OrderBy>;
  /** อื่น ๆ free text */
  financial_rescue_else?: Maybe<OrderBy>;
  /** ความช่วยเหลือด้านการเงิน */
  financial_rescue_id?: Maybe<OrderBy>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent?: Maybe<OrderBy>;
  /** กำไรขั้นต้น (บาท) */
  gross_profit?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent?: Maybe<OrderBy>;
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit?: Maybe<OrderBy>;
  /** freeze data กรรมสิทธิ์ */
  proprietary_description?: Maybe<OrderBy>;
  /** อื่น ๆ free text */
  proprietary_else?: Maybe<OrderBy>;
  /** กรรมสิทธิ์ */
  proprietary_id?: Maybe<OrderBy>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total?: Maybe<OrderBy>;
  /** freeze data ประวัติการชำระหนี้ */
  repay_type_description?: Maybe<OrderBy>;
  /** อื่น ๆ free text */
  repay_type_else?: Maybe<OrderBy>;
  /** ประวัติการชำระหนี้ */
  repay_type_id?: Maybe<OrderBy>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost?: Maybe<OrderBy>;
  /** freeze data คำนิยาม SMEs */
  sme_definition_description?: Maybe<OrderBy>;
  /** อื่น ๆ */
  sme_definition_else?: Maybe<OrderBy>;
  /** รหัสอ้างอิง คำนิยาม SMEs */
  sme_definition_id?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขโดย */
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanApplicationDetailMinFields = {
  __typename?: 'loan_application_detail_min_fields';
  /** Freeze data ปัจจุบันกิจการมีการบันทึกรายได้ และงบการเงินของกิจกรรมในรูปแบบใด */
  account_type_description?: Maybe<Scalars['String']>;
  /** อืน ๆ free text */
  account_type_else?: Maybe<Scalars['String']>;
  /** ปัจจุบันกิจการมีการบันทึกรายได้ และงบการเงินของกิจกรรมในรูปแบบใด */
  account_type_id?: Maybe<Scalars['String']>;
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost?: Maybe<Scalars['numeric']>;
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash?: Maybe<Scalars['numeric']>;
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit?: Maybe<Scalars['numeric']>;
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day?: Maybe<Scalars['numeric']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total?: Maybe<Scalars['numeric']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day?: Maybe<Scalars['numeric']>;
  /** freeze data มีวงเงินสินเชื่อ */
  current_loan_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  current_loan_else?: Maybe<Scalars['String']>;
  /** มีวงเงินสินเชื่อ */
  current_loan_id?: Maybe<Scalars['String']>;
  /** freeze data ปรับโครงสร้างหนี้ */
  debt_restructuring_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  debt_restructuring_else?: Maybe<Scalars['String']>;
  /** ปรับโครงสร้างหนี้ */
  debt_restructuring_id?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit?: Maybe<Scalars['numeric']>;
  /** freeze data การนำส่งข้อมูลทางบัญชีของกิจการ */
  financial_agreement_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ (ระบุ) */
  financial_agreement_else?: Maybe<Scalars['String']>;
  /** การนำส่งข้อมูลทางบัญชีของกิจการ */
  financial_agreement_id?: Maybe<Scalars['String']>;
  /** freeze data ความช่วยเหลือด้านการเงิน */
  financial_rescue_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  financial_rescue_else?: Maybe<Scalars['String']>;
  /** ความช่วยเหลือด้านการเงิน */
  financial_rescue_id?: Maybe<Scalars['String']>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent?: Maybe<Scalars['numeric']>;
  /** กำไรขั้นต้น (บาท) */
  gross_profit?: Maybe<Scalars['numeric']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent?: Maybe<Scalars['numeric']>;
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit?: Maybe<Scalars['numeric']>;
  /** freeze data กรรมสิทธิ์ */
  proprietary_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  proprietary_else?: Maybe<Scalars['String']>;
  /** กรรมสิทธิ์ */
  proprietary_id?: Maybe<Scalars['String']>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total?: Maybe<Scalars['numeric']>;
  /** freeze data ประวัติการชำระหนี้ */
  repay_type_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  repay_type_else?: Maybe<Scalars['String']>;
  /** ประวัติการชำระหนี้ */
  repay_type_id?: Maybe<Scalars['String']>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost?: Maybe<Scalars['numeric']>;
  /** freeze data คำนิยาม SMEs */
  sme_definition_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ */
  sme_definition_else?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิง คำนิยาม SMEs */
  sme_definition_id?: Maybe<Scalars['uuid']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "loan.application_detail" */
export type LoanApplicationDetailMinOrderBy = {
  /** Freeze data ปัจจุบันกิจการมีการบันทึกรายได้ และงบการเงินของกิจกรรมในรูปแบบใด */
  account_type_description?: Maybe<OrderBy>;
  /** อืน ๆ free text */
  account_type_else?: Maybe<OrderBy>;
  /** ปัจจุบันกิจการมีการบันทึกรายได้ และงบการเงินของกิจกรรมในรูปแบบใด */
  account_type_id?: Maybe<OrderBy>;
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost?: Maybe<OrderBy>;
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash?: Maybe<OrderBy>;
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit?: Maybe<OrderBy>;
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total?: Maybe<OrderBy>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day?: Maybe<OrderBy>;
  /** freeze data มีวงเงินสินเชื่อ */
  current_loan_description?: Maybe<OrderBy>;
  /** อื่น ๆ free text */
  current_loan_else?: Maybe<OrderBy>;
  /** มีวงเงินสินเชื่อ */
  current_loan_id?: Maybe<OrderBy>;
  /** freeze data ปรับโครงสร้างหนี้ */
  debt_restructuring_description?: Maybe<OrderBy>;
  /** อื่น ๆ free text */
  debt_restructuring_else?: Maybe<OrderBy>;
  /** ปรับโครงสร้างหนี้ */
  debt_restructuring_id?: Maybe<OrderBy>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit?: Maybe<OrderBy>;
  /** freeze data การนำส่งข้อมูลทางบัญชีของกิจการ */
  financial_agreement_description?: Maybe<OrderBy>;
  /** อื่น ๆ (ระบุ) */
  financial_agreement_else?: Maybe<OrderBy>;
  /** การนำส่งข้อมูลทางบัญชีของกิจการ */
  financial_agreement_id?: Maybe<OrderBy>;
  /** freeze data ความช่วยเหลือด้านการเงิน */
  financial_rescue_description?: Maybe<OrderBy>;
  /** อื่น ๆ free text */
  financial_rescue_else?: Maybe<OrderBy>;
  /** ความช่วยเหลือด้านการเงิน */
  financial_rescue_id?: Maybe<OrderBy>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent?: Maybe<OrderBy>;
  /** กำไรขั้นต้น (บาท) */
  gross_profit?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent?: Maybe<OrderBy>;
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit?: Maybe<OrderBy>;
  /** freeze data กรรมสิทธิ์ */
  proprietary_description?: Maybe<OrderBy>;
  /** อื่น ๆ free text */
  proprietary_else?: Maybe<OrderBy>;
  /** กรรมสิทธิ์ */
  proprietary_id?: Maybe<OrderBy>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total?: Maybe<OrderBy>;
  /** freeze data ประวัติการชำระหนี้ */
  repay_type_description?: Maybe<OrderBy>;
  /** อื่น ๆ free text */
  repay_type_else?: Maybe<OrderBy>;
  /** ประวัติการชำระหนี้ */
  repay_type_id?: Maybe<OrderBy>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost?: Maybe<OrderBy>;
  /** freeze data คำนิยาม SMEs */
  sme_definition_description?: Maybe<OrderBy>;
  /** อื่น ๆ */
  sme_definition_else?: Maybe<OrderBy>;
  /** รหัสอ้างอิง คำนิยาม SMEs */
  sme_definition_id?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขโดย */
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan.application_detail" */
export type LoanApplicationDetailMutationResponse = {
  __typename?: 'loan_application_detail_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanApplicationDetail>;
};

/** input type for inserting object relation for remote table "loan.application_detail" */
export type LoanApplicationDetailObjRelInsertInput = {
  data: LoanApplicationDetailInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanApplicationDetailOnConflict>;
};

/** on conflict condition type for table "loan.application_detail" */
export type LoanApplicationDetailOnConflict = {
  constraint: LoanApplicationDetailConstraint;
  update_columns?: Array<LoanApplicationDetailUpdateColumn>;
  where?: Maybe<LoanApplicationDetailBoolExp>;
};

/** Ordering options when selecting data from "loan.application_detail". */
export type LoanApplicationDetailOrderBy = {
  account_type?: Maybe<LoanAccountTypeOrderBy>;
  account_type_description?: Maybe<OrderBy>;
  account_type_else?: Maybe<OrderBy>;
  account_type_id?: Maybe<OrderBy>;
  application_business_operations_aggregate?: Maybe<LoanApplicationBusinessOperationAggregateOrderBy>;
  application_current_loans_aggregate?: Maybe<LoanApplicationCurrentLoanAggregateOrderBy>;
  application_header?: Maybe<LoanApplicationHeaderOrderBy>;
  business_proposal?: Maybe<OrderBy>;
  cost?: Maybe<OrderBy>;
  cost_cash?: Maybe<OrderBy>;
  cost_credit?: Maybe<OrderBy>;
  cost_day?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  current_liability_amount_total?: Maybe<OrderBy>;
  current_liability_day?: Maybe<OrderBy>;
  current_loan?: Maybe<LoanCurrentLoanOrderBy>;
  current_loan_description?: Maybe<OrderBy>;
  current_loan_else?: Maybe<OrderBy>;
  current_loan_id?: Maybe<OrderBy>;
  current_loan_list?: Maybe<OrderBy>;
  debt_restructuring?: Maybe<LoanDebtRestructuringOrderBy>;
  debt_restructuring_description?: Maybe<OrderBy>;
  debt_restructuring_else?: Maybe<OrderBy>;
  debt_restructuring_id?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  earned_income_credit?: Maybe<OrderBy>;
  financial_agreement?: Maybe<LoanFinancialAgreementOrderBy>;
  financial_agreement_description?: Maybe<OrderBy>;
  financial_agreement_else?: Maybe<OrderBy>;
  financial_agreement_id?: Maybe<OrderBy>;
  financial_rescue?: Maybe<LoanFinancialRescueOrderBy>;
  financial_rescue_description?: Maybe<OrderBy>;
  financial_rescue_else?: Maybe<OrderBy>;
  financial_rescue_id?: Maybe<OrderBy>;
  finished_goods_amount_total?: Maybe<OrderBy>;
  foreign_income_cash?: Maybe<OrderBy>;
  foreign_income_credit?: Maybe<OrderBy>;
  foreign_income_day?: Maybe<OrderBy>;
  foreign_income_percent?: Maybe<OrderBy>;
  gross_profit?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  inventory_valuation?: Maybe<OrderBy>;
  local_income_cash?: Maybe<OrderBy>;
  local_income_credit?: Maybe<OrderBy>;
  local_income_day?: Maybe<OrderBy>;
  local_income_percent?: Maybe<OrderBy>;
  net_profit?: Maybe<OrderBy>;
  proprietary?: Maybe<LoanProprietaryOrderBy>;
  proprietary_description?: Maybe<OrderBy>;
  proprietary_else?: Maybe<OrderBy>;
  proprietary_id?: Maybe<OrderBy>;
  raw_material_amount_total?: Maybe<OrderBy>;
  repay_type?: Maybe<LoanRepayTypeOrderBy>;
  repay_type_description?: Maybe<OrderBy>;
  repay_type_else?: Maybe<OrderBy>;
  repay_type_id?: Maybe<OrderBy>;
  service_cost?: Maybe<OrderBy>;
  sme_definition?: Maybe<LoanSmeDefinitionOrderBy>;
  sme_definition_description?: Maybe<OrderBy>;
  sme_definition_else?: Maybe<OrderBy>;
  sme_definition_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_application_detail */
export type LoanApplicationDetailPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type LoanApplicationDetailPrependInput = {
  /** มีความประสงค์ที่จะได้รับการส่งเสริมพัฒนาจากกองทุน ในด้านใด */
  business_proposal?: Maybe<Scalars['jsonb']>;
  /** รายชื่อสถาบันการเงินที่มีวงเงินสินเชื่อ หรือ หนี้สินนอกระบบ */
  current_loan_list?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "loan.application_detail" */
export enum LoanApplicationDetailSelectColumn {
  /** column name */
  AccountTypeDescription = 'account_type_description',
  /** column name */
  AccountTypeElse = 'account_type_else',
  /** column name */
  AccountTypeId = 'account_type_id',
  /** column name */
  BusinessProposal = 'business_proposal',
  /** column name */
  Cost = 'cost',
  /** column name */
  CostCash = 'cost_cash',
  /** column name */
  CostCredit = 'cost_credit',
  /** column name */
  CostDay = 'cost_day',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CurrentLiabilityAmountTotal = 'current_liability_amount_total',
  /** column name */
  CurrentLiabilityDay = 'current_liability_day',
  /** column name */
  CurrentLoanDescription = 'current_loan_description',
  /** column name */
  CurrentLoanElse = 'current_loan_else',
  /** column name */
  CurrentLoanId = 'current_loan_id',
  /** column name */
  CurrentLoanList = 'current_loan_list',
  /** column name */
  DebtRestructuringDescription = 'debt_restructuring_description',
  /** column name */
  DebtRestructuringElse = 'debt_restructuring_else',
  /** column name */
  DebtRestructuringId = 'debt_restructuring_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EarnedIncomeCredit = 'earned_income_credit',
  /** column name */
  FinancialAgreementDescription = 'financial_agreement_description',
  /** column name */
  FinancialAgreementElse = 'financial_agreement_else',
  /** column name */
  FinancialAgreementId = 'financial_agreement_id',
  /** column name */
  FinancialRescueDescription = 'financial_rescue_description',
  /** column name */
  FinancialRescueElse = 'financial_rescue_else',
  /** column name */
  FinancialRescueId = 'financial_rescue_id',
  /** column name */
  FinishedGoodsAmountTotal = 'finished_goods_amount_total',
  /** column name */
  ForeignIncomeCash = 'foreign_income_cash',
  /** column name */
  ForeignIncomeCredit = 'foreign_income_credit',
  /** column name */
  ForeignIncomeDay = 'foreign_income_day',
  /** column name */
  ForeignIncomePercent = 'foreign_income_percent',
  /** column name */
  GrossProfit = 'gross_profit',
  /** column name */
  Id = 'id',
  /** column name */
  InventoryValuation = 'inventory_valuation',
  /** column name */
  LocalIncomeCash = 'local_income_cash',
  /** column name */
  LocalIncomeCredit = 'local_income_credit',
  /** column name */
  LocalIncomeDay = 'local_income_day',
  /** column name */
  LocalIncomePercent = 'local_income_percent',
  /** column name */
  NetProfit = 'net_profit',
  /** column name */
  ProprietaryDescription = 'proprietary_description',
  /** column name */
  ProprietaryElse = 'proprietary_else',
  /** column name */
  ProprietaryId = 'proprietary_id',
  /** column name */
  RawMaterialAmountTotal = 'raw_material_amount_total',
  /** column name */
  RepayTypeDescription = 'repay_type_description',
  /** column name */
  RepayTypeElse = 'repay_type_else',
  /** column name */
  RepayTypeId = 'repay_type_id',
  /** column name */
  ServiceCost = 'service_cost',
  /** column name */
  SmeDefinitionDescription = 'sme_definition_description',
  /** column name */
  SmeDefinitionElse = 'sme_definition_else',
  /** column name */
  SmeDefinitionId = 'sme_definition_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "loan.application_detail" */
export type LoanApplicationDetailSetInput = {
  /** Freeze data ปัจจุบันกิจการมีการบันทึกรายได้ และงบการเงินของกิจกรรมในรูปแบบใด */
  account_type_description?: Maybe<Scalars['String']>;
  /** อืน ๆ free text */
  account_type_else?: Maybe<Scalars['String']>;
  /** ปัจจุบันกิจการมีการบันทึกรายได้ และงบการเงินของกิจกรรมในรูปแบบใด */
  account_type_id?: Maybe<Scalars['String']>;
  /** มีความประสงค์ที่จะได้รับการส่งเสริมพัฒนาจากกองทุน ในด้านใด */
  business_proposal?: Maybe<Scalars['jsonb']>;
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost?: Maybe<Scalars['numeric']>;
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash?: Maybe<Scalars['numeric']>;
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit?: Maybe<Scalars['numeric']>;
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day?: Maybe<Scalars['numeric']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total?: Maybe<Scalars['numeric']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day?: Maybe<Scalars['numeric']>;
  /** freeze data มีวงเงินสินเชื่อ */
  current_loan_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  current_loan_else?: Maybe<Scalars['String']>;
  /** มีวงเงินสินเชื่อ */
  current_loan_id?: Maybe<Scalars['String']>;
  /** รายชื่อสถาบันการเงินที่มีวงเงินสินเชื่อ หรือ หนี้สินนอกระบบ */
  current_loan_list?: Maybe<Scalars['jsonb']>;
  /** freeze data ปรับโครงสร้างหนี้ */
  debt_restructuring_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  debt_restructuring_else?: Maybe<Scalars['String']>;
  /** ปรับโครงสร้างหนี้ */
  debt_restructuring_id?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit?: Maybe<Scalars['numeric']>;
  /** freeze data การนำส่งข้อมูลทางบัญชีของกิจการ */
  financial_agreement_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ (ระบุ) */
  financial_agreement_else?: Maybe<Scalars['String']>;
  /** การนำส่งข้อมูลทางบัญชีของกิจการ */
  financial_agreement_id?: Maybe<Scalars['String']>;
  /** freeze data ความช่วยเหลือด้านการเงิน */
  financial_rescue_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  financial_rescue_else?: Maybe<Scalars['String']>;
  /** ความช่วยเหลือด้านการเงิน */
  financial_rescue_id?: Maybe<Scalars['String']>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent?: Maybe<Scalars['numeric']>;
  /** กำไรขั้นต้น (บาท) */
  gross_profit?: Maybe<Scalars['numeric']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent?: Maybe<Scalars['numeric']>;
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit?: Maybe<Scalars['numeric']>;
  /** freeze data กรรมสิทธิ์ */
  proprietary_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  proprietary_else?: Maybe<Scalars['String']>;
  /** กรรมสิทธิ์ */
  proprietary_id?: Maybe<Scalars['String']>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total?: Maybe<Scalars['numeric']>;
  /** freeze data ประวัติการชำระหนี้ */
  repay_type_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ free text */
  repay_type_else?: Maybe<Scalars['String']>;
  /** ประวัติการชำระหนี้ */
  repay_type_id?: Maybe<Scalars['String']>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost?: Maybe<Scalars['numeric']>;
  /** freeze data คำนิยาม SMEs */
  sme_definition_description?: Maybe<Scalars['String']>;
  /** อื่น ๆ */
  sme_definition_else?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิง คำนิยาม SMEs */
  sme_definition_id?: Maybe<Scalars['uuid']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type LoanApplicationDetailStddevFields = {
  __typename?: 'loan_application_detail_stddev_fields';
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost?: Maybe<Scalars['Float']>;
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash?: Maybe<Scalars['Float']>;
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit?: Maybe<Scalars['Float']>;
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day?: Maybe<Scalars['Float']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total?: Maybe<Scalars['Float']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day?: Maybe<Scalars['Float']>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit?: Maybe<Scalars['Float']>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent?: Maybe<Scalars['Float']>;
  /** กำไรขั้นต้น (บาท) */
  gross_profit?: Maybe<Scalars['Float']>;
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent?: Maybe<Scalars['Float']>;
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit?: Maybe<Scalars['Float']>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total?: Maybe<Scalars['Float']>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "loan.application_detail" */
export type LoanApplicationDetailStddevOrderBy = {
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost?: Maybe<OrderBy>;
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash?: Maybe<OrderBy>;
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit?: Maybe<OrderBy>;
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day?: Maybe<OrderBy>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total?: Maybe<OrderBy>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day?: Maybe<OrderBy>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit?: Maybe<OrderBy>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent?: Maybe<OrderBy>;
  /** กำไรขั้นต้น (บาท) */
  gross_profit?: Maybe<OrderBy>;
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent?: Maybe<OrderBy>;
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit?: Maybe<OrderBy>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total?: Maybe<OrderBy>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LoanApplicationDetailStddevPopFields = {
  __typename?: 'loan_application_detail_stddev_pop_fields';
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost?: Maybe<Scalars['Float']>;
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash?: Maybe<Scalars['Float']>;
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit?: Maybe<Scalars['Float']>;
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day?: Maybe<Scalars['Float']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total?: Maybe<Scalars['Float']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day?: Maybe<Scalars['Float']>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit?: Maybe<Scalars['Float']>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent?: Maybe<Scalars['Float']>;
  /** กำไรขั้นต้น (บาท) */
  gross_profit?: Maybe<Scalars['Float']>;
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent?: Maybe<Scalars['Float']>;
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit?: Maybe<Scalars['Float']>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total?: Maybe<Scalars['Float']>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "loan.application_detail" */
export type LoanApplicationDetailStddevPopOrderBy = {
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost?: Maybe<OrderBy>;
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash?: Maybe<OrderBy>;
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit?: Maybe<OrderBy>;
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day?: Maybe<OrderBy>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total?: Maybe<OrderBy>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day?: Maybe<OrderBy>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit?: Maybe<OrderBy>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent?: Maybe<OrderBy>;
  /** กำไรขั้นต้น (บาท) */
  gross_profit?: Maybe<OrderBy>;
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent?: Maybe<OrderBy>;
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit?: Maybe<OrderBy>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total?: Maybe<OrderBy>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LoanApplicationDetailStddevSampFields = {
  __typename?: 'loan_application_detail_stddev_samp_fields';
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost?: Maybe<Scalars['Float']>;
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash?: Maybe<Scalars['Float']>;
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit?: Maybe<Scalars['Float']>;
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day?: Maybe<Scalars['Float']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total?: Maybe<Scalars['Float']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day?: Maybe<Scalars['Float']>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit?: Maybe<Scalars['Float']>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent?: Maybe<Scalars['Float']>;
  /** กำไรขั้นต้น (บาท) */
  gross_profit?: Maybe<Scalars['Float']>;
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent?: Maybe<Scalars['Float']>;
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit?: Maybe<Scalars['Float']>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total?: Maybe<Scalars['Float']>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "loan.application_detail" */
export type LoanApplicationDetailStddevSampOrderBy = {
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost?: Maybe<OrderBy>;
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash?: Maybe<OrderBy>;
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit?: Maybe<OrderBy>;
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day?: Maybe<OrderBy>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total?: Maybe<OrderBy>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day?: Maybe<OrderBy>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit?: Maybe<OrderBy>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent?: Maybe<OrderBy>;
  /** กำไรขั้นต้น (บาท) */
  gross_profit?: Maybe<OrderBy>;
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent?: Maybe<OrderBy>;
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit?: Maybe<OrderBy>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total?: Maybe<OrderBy>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LoanApplicationDetailSumFields = {
  __typename?: 'loan_application_detail_sum_fields';
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost?: Maybe<Scalars['numeric']>;
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash?: Maybe<Scalars['numeric']>;
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit?: Maybe<Scalars['numeric']>;
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day?: Maybe<Scalars['numeric']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total?: Maybe<Scalars['numeric']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day?: Maybe<Scalars['numeric']>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit?: Maybe<Scalars['numeric']>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day?: Maybe<Scalars['numeric']>;
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent?: Maybe<Scalars['numeric']>;
  /** กำไรขั้นต้น (บาท) */
  gross_profit?: Maybe<Scalars['numeric']>;
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day?: Maybe<Scalars['numeric']>;
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent?: Maybe<Scalars['numeric']>;
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit?: Maybe<Scalars['numeric']>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total?: Maybe<Scalars['numeric']>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "loan.application_detail" */
export type LoanApplicationDetailSumOrderBy = {
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost?: Maybe<OrderBy>;
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash?: Maybe<OrderBy>;
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit?: Maybe<OrderBy>;
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day?: Maybe<OrderBy>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total?: Maybe<OrderBy>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day?: Maybe<OrderBy>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit?: Maybe<OrderBy>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent?: Maybe<OrderBy>;
  /** กำไรขั้นต้น (บาท) */
  gross_profit?: Maybe<OrderBy>;
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent?: Maybe<OrderBy>;
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit?: Maybe<OrderBy>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total?: Maybe<OrderBy>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost?: Maybe<OrderBy>;
};

/** update columns of table "loan.application_detail" */
export enum LoanApplicationDetailUpdateColumn {
  /** column name */
  AccountTypeDescription = 'account_type_description',
  /** column name */
  AccountTypeElse = 'account_type_else',
  /** column name */
  AccountTypeId = 'account_type_id',
  /** column name */
  BusinessProposal = 'business_proposal',
  /** column name */
  Cost = 'cost',
  /** column name */
  CostCash = 'cost_cash',
  /** column name */
  CostCredit = 'cost_credit',
  /** column name */
  CostDay = 'cost_day',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CurrentLiabilityAmountTotal = 'current_liability_amount_total',
  /** column name */
  CurrentLiabilityDay = 'current_liability_day',
  /** column name */
  CurrentLoanDescription = 'current_loan_description',
  /** column name */
  CurrentLoanElse = 'current_loan_else',
  /** column name */
  CurrentLoanId = 'current_loan_id',
  /** column name */
  CurrentLoanList = 'current_loan_list',
  /** column name */
  DebtRestructuringDescription = 'debt_restructuring_description',
  /** column name */
  DebtRestructuringElse = 'debt_restructuring_else',
  /** column name */
  DebtRestructuringId = 'debt_restructuring_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EarnedIncomeCredit = 'earned_income_credit',
  /** column name */
  FinancialAgreementDescription = 'financial_agreement_description',
  /** column name */
  FinancialAgreementElse = 'financial_agreement_else',
  /** column name */
  FinancialAgreementId = 'financial_agreement_id',
  /** column name */
  FinancialRescueDescription = 'financial_rescue_description',
  /** column name */
  FinancialRescueElse = 'financial_rescue_else',
  /** column name */
  FinancialRescueId = 'financial_rescue_id',
  /** column name */
  FinishedGoodsAmountTotal = 'finished_goods_amount_total',
  /** column name */
  ForeignIncomeCash = 'foreign_income_cash',
  /** column name */
  ForeignIncomeCredit = 'foreign_income_credit',
  /** column name */
  ForeignIncomeDay = 'foreign_income_day',
  /** column name */
  ForeignIncomePercent = 'foreign_income_percent',
  /** column name */
  GrossProfit = 'gross_profit',
  /** column name */
  Id = 'id',
  /** column name */
  InventoryValuation = 'inventory_valuation',
  /** column name */
  LocalIncomeCash = 'local_income_cash',
  /** column name */
  LocalIncomeCredit = 'local_income_credit',
  /** column name */
  LocalIncomeDay = 'local_income_day',
  /** column name */
  LocalIncomePercent = 'local_income_percent',
  /** column name */
  NetProfit = 'net_profit',
  /** column name */
  ProprietaryDescription = 'proprietary_description',
  /** column name */
  ProprietaryElse = 'proprietary_else',
  /** column name */
  ProprietaryId = 'proprietary_id',
  /** column name */
  RawMaterialAmountTotal = 'raw_material_amount_total',
  /** column name */
  RepayTypeDescription = 'repay_type_description',
  /** column name */
  RepayTypeElse = 'repay_type_else',
  /** column name */
  RepayTypeId = 'repay_type_id',
  /** column name */
  ServiceCost = 'service_cost',
  /** column name */
  SmeDefinitionDescription = 'sme_definition_description',
  /** column name */
  SmeDefinitionElse = 'sme_definition_else',
  /** column name */
  SmeDefinitionId = 'sme_definition_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type LoanApplicationDetailVarPopFields = {
  __typename?: 'loan_application_detail_var_pop_fields';
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost?: Maybe<Scalars['Float']>;
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash?: Maybe<Scalars['Float']>;
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit?: Maybe<Scalars['Float']>;
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day?: Maybe<Scalars['Float']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total?: Maybe<Scalars['Float']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day?: Maybe<Scalars['Float']>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit?: Maybe<Scalars['Float']>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent?: Maybe<Scalars['Float']>;
  /** กำไรขั้นต้น (บาท) */
  gross_profit?: Maybe<Scalars['Float']>;
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent?: Maybe<Scalars['Float']>;
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit?: Maybe<Scalars['Float']>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total?: Maybe<Scalars['Float']>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "loan.application_detail" */
export type LoanApplicationDetailVarPopOrderBy = {
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost?: Maybe<OrderBy>;
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash?: Maybe<OrderBy>;
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit?: Maybe<OrderBy>;
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day?: Maybe<OrderBy>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total?: Maybe<OrderBy>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day?: Maybe<OrderBy>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit?: Maybe<OrderBy>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent?: Maybe<OrderBy>;
  /** กำไรขั้นต้น (บาท) */
  gross_profit?: Maybe<OrderBy>;
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent?: Maybe<OrderBy>;
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit?: Maybe<OrderBy>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total?: Maybe<OrderBy>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LoanApplicationDetailVarSampFields = {
  __typename?: 'loan_application_detail_var_samp_fields';
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost?: Maybe<Scalars['Float']>;
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash?: Maybe<Scalars['Float']>;
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit?: Maybe<Scalars['Float']>;
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day?: Maybe<Scalars['Float']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total?: Maybe<Scalars['Float']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day?: Maybe<Scalars['Float']>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit?: Maybe<Scalars['Float']>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent?: Maybe<Scalars['Float']>;
  /** กำไรขั้นต้น (บาท) */
  gross_profit?: Maybe<Scalars['Float']>;
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent?: Maybe<Scalars['Float']>;
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit?: Maybe<Scalars['Float']>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total?: Maybe<Scalars['Float']>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "loan.application_detail" */
export type LoanApplicationDetailVarSampOrderBy = {
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost?: Maybe<OrderBy>;
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash?: Maybe<OrderBy>;
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit?: Maybe<OrderBy>;
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day?: Maybe<OrderBy>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total?: Maybe<OrderBy>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day?: Maybe<OrderBy>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit?: Maybe<OrderBy>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent?: Maybe<OrderBy>;
  /** กำไรขั้นต้น (บาท) */
  gross_profit?: Maybe<OrderBy>;
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent?: Maybe<OrderBy>;
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit?: Maybe<OrderBy>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total?: Maybe<OrderBy>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LoanApplicationDetailVarianceFields = {
  __typename?: 'loan_application_detail_variance_fields';
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost?: Maybe<Scalars['Float']>;
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash?: Maybe<Scalars['Float']>;
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit?: Maybe<Scalars['Float']>;
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day?: Maybe<Scalars['Float']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total?: Maybe<Scalars['Float']>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day?: Maybe<Scalars['Float']>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit?: Maybe<Scalars['Float']>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day?: Maybe<Scalars['Float']>;
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent?: Maybe<Scalars['Float']>;
  /** กำไรขั้นต้น (บาท) */
  gross_profit?: Maybe<Scalars['Float']>;
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day?: Maybe<Scalars['Float']>;
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent?: Maybe<Scalars['Float']>;
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit?: Maybe<Scalars['Float']>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total?: Maybe<Scalars['Float']>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "loan.application_detail" */
export type LoanApplicationDetailVarianceOrderBy = {
  /** ต้นทุนขาย (ทั้งหมด = เงินสด + เงินเชื่อ) (บาท) */
  cost?: Maybe<OrderBy>;
  /** ต้นทุนขาย (เงินสด) (บาท) */
  cost_cash?: Maybe<OrderBy>;
  /** ต้นทุนขาย (เงินเชื่อ) (บาท) */
  cost_credit?: Maybe<OrderBy>;
  /** ต้นทุนขาย (ระยะเวลา) (บาท) */
  cost_day?: Maybe<OrderBy>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  current_liability_amount_total?: Maybe<OrderBy>;
  /** กิจการมีเจ้าหนี้การค้า จนถึงวันที่ขอกู้/ร่วมลงทุน ระยะเวลา (วัน) */
  current_liability_day?: Maybe<OrderBy>;
  /** กิจการมีลูกหนี้การค้ารอการเรียกเก็บเงิน จนถึงวันที่ขอกู้/ร่วมลงทุน (บาท) */
  earned_income_credit?: Maybe<OrderBy>;
  /** สินค้าสำเร็จรูป (บาท) */
  finished_goods_amount_total?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เงินสด) (บาท) */
  foreign_income_cash?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เงินเชื่อ) (บาท) */
  foreign_income_credit?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (ระยะเวลา) (วัน) */
  foreign_income_day?: Maybe<OrderBy>;
  /** รายได้นอกประเทศ (เปอร์เซ็นต์) */
  foreign_income_percent?: Maybe<OrderBy>;
  /** กำไรขั้นต้น (บาท) */
  gross_profit?: Maybe<OrderBy>;
  /** กิจการมีสินค้าคงเหลือ (สตต็อค) จนถึงวันที่ขอกู้ / ร่วมลงทุน (บาท) */
  inventory_valuation?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เงินสด) (บาท) */
  local_income_cash?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เงินเชื่อ) (บาท) */
  local_income_credit?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (ระยะเวลา) (วัน) */
  local_income_day?: Maybe<OrderBy>;
  /** รายได้ในประเทศ (เปอร์เซ็นต์) */
  local_income_percent?: Maybe<OrderBy>;
  /** กำไรสุทธิ (กำไรขั้นต้น - ค่าใช้จ่ายในการขายและบริการ) (บาท) */
  net_profit?: Maybe<OrderBy>;
  /** วัตถุดิบ (บาท) */
  raw_material_amount_total?: Maybe<OrderBy>;
  /** ค่าใช้จ่ายในการขายและบริการ (บาท) */
  service_cost?: Maybe<OrderBy>;
};

/**
 * application_event_log
 *
 *
 * columns and relationships of "loan.application_event_log"
 */
export type LoanApplicationEventLog = {
  __typename?: 'loan_application_event_log';
  /** หน่วยงาน */
  actor_organization_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  actor_user: Users;
  /** ผู้กระทำ */
  actor_user_id: Scalars['uuid'];
  /** An object relationship */
  application_header: LoanApplicationHeader;
  /** application_header.id */
  application_header_id: Scalars['uuid'];
  /** An object relationship */
  application_step: LoanApplicationStep;
  /** An object relationship */
  bank_approve?: Maybe<LoanBankApprove>;
  /** ผลพิจารณาและอนุมัติจากธนาคาร */
  bank_approve_value?: Maybe<LoanBankApproveEnum>;
  /** An object relationship */
  committee_approve?: Maybe<LoanCommitteeApprove>;
  /** พิจารณาคัดสรร กลั่นกรองคุณสมบัติ */
  committee_approve_value?: Maybe<LoanCommitteeApproveEnum>;
  /** วันที่บันทึกข้อมูล */
  created_at: Scalars['timestamptz'];
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  document_approve?: Maybe<LoanDocumentApprove>;
  /** ตรวจสอบเอกสาร คุณสมบัติเบื้องต้น */
  document_approve_value?: Maybe<LoanDocumentApproveEnum>;
  /** Primary key */
  id: Scalars['uuid'];
  /** An object relationship */
  juristic: Juristic;
  /** เลขนิติบุคคล */
  juristic_id: Scalars['String'];
  /** An object relationship */
  organization?: Maybe<Organization>;
  /** กำหนดสิทธิ์ในการเรียกดู event log โดย true = ทุกคนสามารถเรียกดู event log ได้, false  = สามารถมองเห็นได้เฉพาะเจ้าหน้าที่ */
  public: Scalars['Boolean'];
  /** รหัสใบคำขอสินเชื่อ */
  reference?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  remark?: Maybe<Scalars['String']>;
  /** An object relationship */
  sme_user: Users;
  /** ผู้ขอสินเชื่อ */
  sme_user_id: Scalars['uuid'];
  /** ขั้นตอนของฟอร์ม */
  step_id: Scalars['String'];
};

/** aggregated selection of "loan.application_event_log" */
export type LoanApplicationEventLogAggregate = {
  __typename?: 'loan_application_event_log_aggregate';
  aggregate?: Maybe<LoanApplicationEventLogAggregateFields>;
  nodes: Array<LoanApplicationEventLog>;
};

/** aggregate fields of "loan.application_event_log" */
export type LoanApplicationEventLogAggregateFields = {
  __typename?: 'loan_application_event_log_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LoanApplicationEventLogMaxFields>;
  min?: Maybe<LoanApplicationEventLogMinFields>;
};


/** aggregate fields of "loan.application_event_log" */
export type LoanApplicationEventLogAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanApplicationEventLogSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan.application_event_log" */
export type LoanApplicationEventLogAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanApplicationEventLogMaxOrderBy>;
  min?: Maybe<LoanApplicationEventLogMinOrderBy>;
};

/** input type for inserting array relation for remote table "loan.application_event_log" */
export type LoanApplicationEventLogArrRelInsertInput = {
  data: Array<LoanApplicationEventLogInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanApplicationEventLogOnConflict>;
};

/** Boolean expression to filter rows from the table "loan.application_event_log". All fields are combined with a logical 'AND'. */
export type LoanApplicationEventLogBoolExp = {
  _and?: Maybe<Array<LoanApplicationEventLogBoolExp>>;
  _not?: Maybe<LoanApplicationEventLogBoolExp>;
  _or?: Maybe<Array<LoanApplicationEventLogBoolExp>>;
  actor_organization_id?: Maybe<UuidComparisonExp>;
  actor_user?: Maybe<UsersBoolExp>;
  actor_user_id?: Maybe<UuidComparisonExp>;
  application_header?: Maybe<LoanApplicationHeaderBoolExp>;
  application_header_id?: Maybe<UuidComparisonExp>;
  application_step?: Maybe<LoanApplicationStepBoolExp>;
  bank_approve?: Maybe<LoanBankApproveBoolExp>;
  bank_approve_value?: Maybe<LoanBankApproveEnumComparisonExp>;
  committee_approve?: Maybe<LoanCommitteeApproveBoolExp>;
  committee_approve_value?: Maybe<LoanCommitteeApproveEnumComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  document_approve?: Maybe<LoanDocumentApproveBoolExp>;
  document_approve_value?: Maybe<LoanDocumentApproveEnumComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  juristic?: Maybe<JuristicBoolExp>;
  juristic_id?: Maybe<StringComparisonExp>;
  organization?: Maybe<OrganizationBoolExp>;
  public?: Maybe<BooleanComparisonExp>;
  reference?: Maybe<StringComparisonExp>;
  remark?: Maybe<StringComparisonExp>;
  sme_user?: Maybe<UsersBoolExp>;
  sme_user_id?: Maybe<UuidComparisonExp>;
  step_id?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "loan.application_event_log" */
export enum LoanApplicationEventLogConstraint {
  /** unique or primary key constraint */
  ApplicationEventLogPkey = 'application_event_log_pkey'
}

/** input type for inserting data into table "loan.application_event_log" */
export type LoanApplicationEventLogInsertInput = {
  /** หน่วยงาน */
  actor_organization_id?: Maybe<Scalars['uuid']>;
  actor_user?: Maybe<UsersObjRelInsertInput>;
  /** ผู้กระทำ */
  actor_user_id?: Maybe<Scalars['uuid']>;
  application_header?: Maybe<LoanApplicationHeaderObjRelInsertInput>;
  /** application_header.id */
  application_header_id?: Maybe<Scalars['uuid']>;
  application_step?: Maybe<LoanApplicationStepObjRelInsertInput>;
  bank_approve?: Maybe<LoanBankApproveObjRelInsertInput>;
  /** ผลพิจารณาและอนุมัติจากธนาคาร */
  bank_approve_value?: Maybe<LoanBankApproveEnum>;
  committee_approve?: Maybe<LoanCommitteeApproveObjRelInsertInput>;
  /** พิจารณาคัดสรร กลั่นกรองคุณสมบัติ */
  committee_approve_value?: Maybe<LoanCommitteeApproveEnum>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  document_approve?: Maybe<LoanDocumentApproveObjRelInsertInput>;
  /** ตรวจสอบเอกสาร คุณสมบัติเบื้องต้น */
  document_approve_value?: Maybe<LoanDocumentApproveEnum>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  juristic?: Maybe<JuristicObjRelInsertInput>;
  /** เลขนิติบุคคล */
  juristic_id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationObjRelInsertInput>;
  /** กำหนดสิทธิ์ในการเรียกดู event log โดย true = ทุกคนสามารถเรียกดู event log ได้, false  = สามารถมองเห็นได้เฉพาะเจ้าหน้าที่ */
  public?: Maybe<Scalars['Boolean']>;
  /** รหัสใบคำขอสินเชื่อ */
  reference?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  remark?: Maybe<Scalars['String']>;
  sme_user?: Maybe<UsersObjRelInsertInput>;
  /** ผู้ขอสินเชื่อ */
  sme_user_id?: Maybe<Scalars['uuid']>;
  /** ขั้นตอนของฟอร์ม */
  step_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LoanApplicationEventLogMaxFields = {
  __typename?: 'loan_application_event_log_max_fields';
  /** หน่วยงาน */
  actor_organization_id?: Maybe<Scalars['uuid']>;
  /** ผู้กระทำ */
  actor_user_id?: Maybe<Scalars['uuid']>;
  /** application_header.id */
  application_header_id?: Maybe<Scalars['uuid']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** เลขนิติบุคคล */
  juristic_id?: Maybe<Scalars['String']>;
  /** รหัสใบคำขอสินเชื่อ */
  reference?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  remark?: Maybe<Scalars['String']>;
  /** ผู้ขอสินเชื่อ */
  sme_user_id?: Maybe<Scalars['uuid']>;
  /** ขั้นตอนของฟอร์ม */
  step_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "loan.application_event_log" */
export type LoanApplicationEventLogMaxOrderBy = {
  /** หน่วยงาน */
  actor_organization_id?: Maybe<OrderBy>;
  /** ผู้กระทำ */
  actor_user_id?: Maybe<OrderBy>;
  /** application_header.id */
  application_header_id?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** คำอธิบาย */
  description?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** เลขนิติบุคคล */
  juristic_id?: Maybe<OrderBy>;
  /** รหัสใบคำขอสินเชื่อ */
  reference?: Maybe<OrderBy>;
  /** หมายเหตุ */
  remark?: Maybe<OrderBy>;
  /** ผู้ขอสินเชื่อ */
  sme_user_id?: Maybe<OrderBy>;
  /** ขั้นตอนของฟอร์ม */
  step_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanApplicationEventLogMinFields = {
  __typename?: 'loan_application_event_log_min_fields';
  /** หน่วยงาน */
  actor_organization_id?: Maybe<Scalars['uuid']>;
  /** ผู้กระทำ */
  actor_user_id?: Maybe<Scalars['uuid']>;
  /** application_header.id */
  application_header_id?: Maybe<Scalars['uuid']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** เลขนิติบุคคล */
  juristic_id?: Maybe<Scalars['String']>;
  /** รหัสใบคำขอสินเชื่อ */
  reference?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  remark?: Maybe<Scalars['String']>;
  /** ผู้ขอสินเชื่อ */
  sme_user_id?: Maybe<Scalars['uuid']>;
  /** ขั้นตอนของฟอร์ม */
  step_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "loan.application_event_log" */
export type LoanApplicationEventLogMinOrderBy = {
  /** หน่วยงาน */
  actor_organization_id?: Maybe<OrderBy>;
  /** ผู้กระทำ */
  actor_user_id?: Maybe<OrderBy>;
  /** application_header.id */
  application_header_id?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** คำอธิบาย */
  description?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** เลขนิติบุคคล */
  juristic_id?: Maybe<OrderBy>;
  /** รหัสใบคำขอสินเชื่อ */
  reference?: Maybe<OrderBy>;
  /** หมายเหตุ */
  remark?: Maybe<OrderBy>;
  /** ผู้ขอสินเชื่อ */
  sme_user_id?: Maybe<OrderBy>;
  /** ขั้นตอนของฟอร์ม */
  step_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan.application_event_log" */
export type LoanApplicationEventLogMutationResponse = {
  __typename?: 'loan_application_event_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanApplicationEventLog>;
};

/** on conflict condition type for table "loan.application_event_log" */
export type LoanApplicationEventLogOnConflict = {
  constraint: LoanApplicationEventLogConstraint;
  update_columns?: Array<LoanApplicationEventLogUpdateColumn>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
};

/** Ordering options when selecting data from "loan.application_event_log". */
export type LoanApplicationEventLogOrderBy = {
  actor_organization_id?: Maybe<OrderBy>;
  actor_user?: Maybe<UsersOrderBy>;
  actor_user_id?: Maybe<OrderBy>;
  application_header?: Maybe<LoanApplicationHeaderOrderBy>;
  application_header_id?: Maybe<OrderBy>;
  application_step?: Maybe<LoanApplicationStepOrderBy>;
  bank_approve?: Maybe<LoanBankApproveOrderBy>;
  bank_approve_value?: Maybe<OrderBy>;
  committee_approve?: Maybe<LoanCommitteeApproveOrderBy>;
  committee_approve_value?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  document_approve?: Maybe<LoanDocumentApproveOrderBy>;
  document_approve_value?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  juristic?: Maybe<JuristicOrderBy>;
  juristic_id?: Maybe<OrderBy>;
  organization?: Maybe<OrganizationOrderBy>;
  public?: Maybe<OrderBy>;
  reference?: Maybe<OrderBy>;
  remark?: Maybe<OrderBy>;
  sme_user?: Maybe<UsersOrderBy>;
  sme_user_id?: Maybe<OrderBy>;
  step_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_application_event_log */
export type LoanApplicationEventLogPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "loan.application_event_log" */
export enum LoanApplicationEventLogSelectColumn {
  /** column name */
  ActorOrganizationId = 'actor_organization_id',
  /** column name */
  ActorUserId = 'actor_user_id',
  /** column name */
  ApplicationHeaderId = 'application_header_id',
  /** column name */
  BankApproveValue = 'bank_approve_value',
  /** column name */
  CommitteeApproveValue = 'committee_approve_value',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DocumentApproveValue = 'document_approve_value',
  /** column name */
  Id = 'id',
  /** column name */
  JuristicId = 'juristic_id',
  /** column name */
  Public = 'public',
  /** column name */
  Reference = 'reference',
  /** column name */
  Remark = 'remark',
  /** column name */
  SmeUserId = 'sme_user_id',
  /** column name */
  StepId = 'step_id'
}

/** input type for updating data in table "loan.application_event_log" */
export type LoanApplicationEventLogSetInput = {
  /** หน่วยงาน */
  actor_organization_id?: Maybe<Scalars['uuid']>;
  /** ผู้กระทำ */
  actor_user_id?: Maybe<Scalars['uuid']>;
  /** application_header.id */
  application_header_id?: Maybe<Scalars['uuid']>;
  /** ผลพิจารณาและอนุมัติจากธนาคาร */
  bank_approve_value?: Maybe<LoanBankApproveEnum>;
  /** พิจารณาคัดสรร กลั่นกรองคุณสมบัติ */
  committee_approve_value?: Maybe<LoanCommitteeApproveEnum>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** ตรวจสอบเอกสาร คุณสมบัติเบื้องต้น */
  document_approve_value?: Maybe<LoanDocumentApproveEnum>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** เลขนิติบุคคล */
  juristic_id?: Maybe<Scalars['String']>;
  /** กำหนดสิทธิ์ในการเรียกดู event log โดย true = ทุกคนสามารถเรียกดู event log ได้, false  = สามารถมองเห็นได้เฉพาะเจ้าหน้าที่ */
  public?: Maybe<Scalars['Boolean']>;
  /** รหัสใบคำขอสินเชื่อ */
  reference?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  remark?: Maybe<Scalars['String']>;
  /** ผู้ขอสินเชื่อ */
  sme_user_id?: Maybe<Scalars['uuid']>;
  /** ขั้นตอนของฟอร์ม */
  step_id?: Maybe<Scalars['String']>;
};

/** update columns of table "loan.application_event_log" */
export enum LoanApplicationEventLogUpdateColumn {
  /** column name */
  ActorOrganizationId = 'actor_organization_id',
  /** column name */
  ActorUserId = 'actor_user_id',
  /** column name */
  ApplicationHeaderId = 'application_header_id',
  /** column name */
  BankApproveValue = 'bank_approve_value',
  /** column name */
  CommitteeApproveValue = 'committee_approve_value',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DocumentApproveValue = 'document_approve_value',
  /** column name */
  Id = 'id',
  /** column name */
  JuristicId = 'juristic_id',
  /** column name */
  Public = 'public',
  /** column name */
  Reference = 'reference',
  /** column name */
  Remark = 'remark',
  /** column name */
  SmeUserId = 'sme_user_id',
  /** column name */
  StepId = 'step_id'
}

/**
 * ใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_header"
 */
export type LoanApplicationHeader = {
  __typename?: 'loan_application_header';
  /** An array relationship */
  application_attachments: Array<LoanApplicationAttachment>;
  /** An aggregate relationship */
  application_attachments_aggregate: LoanApplicationAttachmentAggregate;
  /** An array relationship */
  application_bank_branch_transfers: Array<LoanApplicationBankBranchTransfer>;
  /** An aggregate relationship */
  application_bank_branch_transfers_aggregate: LoanApplicationBankBranchTransferAggregate;
  /** An array relationship */
  application_committees: Array<LoanApplicationCommittee>;
  /** An aggregate relationship */
  application_committees_aggregate: LoanApplicationCommitteeAggregate;
  /** An object relationship */
  application_detail?: Maybe<LoanApplicationDetail>;
  /** An array relationship */
  application_details: Array<LoanApplicationDetail>;
  /** An aggregate relationship */
  application_details_aggregate: LoanApplicationDetailAggregate;
  /** An array relationship */
  application_event_logs: Array<LoanApplicationEventLog>;
  /** An aggregate relationship */
  application_event_logs_aggregate: LoanApplicationEventLogAggregate;
  /** An object relationship */
  application_header_pdf?: Maybe<LoanApplicationHeaderPdf>;
  /** An array relationship */
  application_header_pdfs: Array<LoanApplicationHeaderPdf>;
  /** An aggregate relationship */
  application_header_pdfs_aggregate: LoanApplicationHeaderPdfAggregate;
  /** An array relationship */
  application_objectives: Array<LoanApplicationObjective>;
  /** An aggregate relationship */
  application_objectives_aggregate: LoanApplicationObjectiveAggregate;
  /** An object relationship */
  application_profile?: Maybe<LoanApplicationProfile>;
  /** An array relationship */
  application_profiles: Array<LoanApplicationProfile>;
  /** An aggregate relationship */
  application_profiles_aggregate: LoanApplicationProfileAggregate;
  /** An object relationship */
  application_step: LoanApplicationStep;
  /** An array relationship */
  application_transfers: Array<LoanApplicationOrganizationTransfer>;
  /** An aggregate relationship */
  application_transfers_aggregate: LoanApplicationOrganizationTransferAggregate;
  /** An object relationship */
  bank_approve?: Maybe<LoanBankApprove>;
  /** หมายเหตุ ขั้นตอนพิจารณาจากธนาคาร */
  bank_approve_comment?: Maybe<Scalars['String']>;
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<Scalars['numeric']>;
  /** วันที่บันทึกผลพิจารณาและอนุมัติจากธนาคาร */
  bank_approve_date?: Maybe<Scalars['date']>;
  /** ผลพิจารณาและอนุมัติจากธนาคาร */
  bank_approve_value?: Maybe<LoanBankApproveEnum>;
  /** An object relationship */
  bank_branch?: Maybe<BankBranch>;
  /** รหัสธนาคาร */
  bank_branch_id?: Maybe<Scalars['String']>;
  /** ชื่อธนาคาร */
  bank_branch_name?: Maybe<Scalars['String']>;
  /** จังหวัดธนาคาร */
  bank_branch_province?: Maybe<Scalars['String']>;
  /** วันที่ทำสัญญากับธนาคาร */
  bank_effective_date?: Maybe<Scalars['date']>;
  /** An object relationship */
  business_group?: Maybe<LoanBusinessGroup>;
  /** freeze data ประเภทธุรกิจ */
  business_group_description?: Maybe<Scalars['String']>;
  /** กลุ่มธุรกิจ */
  business_group_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  business_proprietary?: Maybe<LoanBusinessProprietary>;
  /** ลักษณะสถานประกอบการ */
  business_proprietary_description?: Maybe<Scalars['String']>;
  /** ลักษณะสถานประกอบการ อื่น ๆ ระบุ */
  business_proprietary_else?: Maybe<Scalars['String']>;
  /** ลักษณะสถานประกอบการ */
  business_proprietary_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  business_status?: Maybe<LoanBusinessStatus>;
  /** สถานะภาพการดำเนินธุรกิจ */
  business_status_description?: Maybe<Scalars['String']>;
  /** สถานะภาพการดำเนินธุรกิจ */
  business_status_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  business_type?: Maybe<LoanBusinessType>;
  /** freeze data ประเภทธุรกิจ */
  business_type_description?: Maybe<Scalars['String']>;
  /** ประเภทธุรกิจ */
  business_type_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  committee_approve?: Maybe<LoanCommitteeApprove>;
  /** หมายเหตุ ขั้นตอนตรวจพิจารณากลั่นกรอง */
  committee_approve_comment?: Maybe<Scalars['String']>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<Scalars['numeric']>;
  /** วันที่ พิจารณาคัดสรร กลั่นกรอง เสร็จ */
  committee_approve_date?: Maybe<Scalars['timestamptz']>;
  /** พิจารณาคัดสรร กลั่นกรองคุณสมบัติ */
  committee_approve_value?: Maybe<LoanCommitteeApproveEnum>;
  /** วันที่ ลงนาม ประทับตรา */
  completed_date?: Maybe<Scalars['timestamptz']>;
  /** ผู้ติดต่อ อีเมล */
  contact_email?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ ชื่อ */
  contact_first_name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "loan.loan_application_header_contact_full_name" */
  contact_full_name?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ นามสกุล */
  contact_last_name?: Maybe<Scalars['String']>;
  /** ตำแหน่งผู้ติดต่อ */
  contact_position?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ เบอร์โทรศัพท์ */
  contact_tel?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  document_approve?: Maybe<LoanDocumentApprove>;
  /** หมายเหตุ ขั้นตอนตรวจสอบเอกสารเบื้องต้น */
  document_approve_comment?: Maybe<Scalars['String']>;
  /** วันที่ ตรวจสอบคุณสมบัติเบื้องต้น เสร็จ */
  document_approve_date?: Maybe<Scalars['timestamptz']>;
  /** ตรวจสอบเอกสาร คุณสมบัติเบื้องต้น */
  document_approve_value?: Maybe<LoanDocumentApproveEnum>;
  /** E-mail */
  email?: Maybe<Scalars['String']>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total: Scalars['numeric'];
  /** Primary key */
  id: Scalars['uuid'];
  /** เลขทะเบียนนิติบุคคล */
  juristic_id: Scalars['String'];
  /** บจก./หจก./หสน. */
  name: Scalars['String'];
  /** ที่ตั้งสถานประกอบการ */
  office_address_amphoe?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_district?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_province?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_street?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_zipcode?: Maybe<Scalars['String']>;
  /** A computed field, executes function "loan.loan_application_header_office_full_address" */
  office_full_address?: Maybe<Scalars['String']>;
  /** An object relationship */
  organization?: Maybe<Organization>;
  /** รหัสอ้างอิงหน่วยงาน */
  organization_id?: Maybe<Scalars['uuid']>;
  /** ชื่อหน่วยงาน / ศูนย์ภาค (ศภ.) */
  organization_name?: Maybe<Scalars['String']>;
  /** รหัส หน่วยงาน / ศูนย์ภาค (ศภ.) */
  organization_reference?: Maybe<Scalars['String']>;
  /** ชื่อย่อหน่วยงาน / ศูนย์ภาค (ศภ.) */
  organization_short_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  program?: Maybe<LoanProgram>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** มูลโครงการทั้งหมด */
  project_value_total: Scalars['numeric'];
  /** รหัสใบคำขอสินเชื่อ */
  reference?: Maybe<Scalars['String']>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total: Scalars['numeric'];
  /** วันที่บันทึกผลการยื่นเอกสารฉบับจริง */
  sme_sign_approve_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่ยื่นเอกสารฉบับจริง */
  sme_sign_date?: Maybe<Scalars['date']>;
  /** วันที่เริ่มทำธุรกิจ */
  start_date?: Maybe<Scalars['date']>;
  /** ขั้นตอนของฟอร์ม */
  step_id: Scalars['String'];
  /** วันที่ submit ข้อมูลเข้าระบบ */
  submitted_date?: Maybe<Scalars['timestamptz']>;
  /** โทรศัพท์ */
  tel?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<Scalars['uuid']>;
  /** อำเภอ ที่ทำงานปัจจุบัน */
  working_address_amphoe?: Maybe<Scalars['String']>;
  /** ตำบล ที่ทำงานปัจจุบัน */
  working_address_district?: Maybe<Scalars['String']>;
  /** ที่เดียวกับที่ทำงาน */
  working_address_is_office_address: Scalars['Boolean'];
  /** จังหวัด ที่ทำงานปัจจุบัน */
  working_address_province?: Maybe<Scalars['String']>;
  /** ที่อยู่ ที่ทำงานปัจจุบัน */
  working_address_street?: Maybe<Scalars['String']>;
  /** รหัสไปรษณีย์ ที่ทำงานปัจจุบัน */
  working_address_zipcode?: Maybe<Scalars['String']>;
  /** A computed field, executes function "loan.loan_application_header_working_full_address" */
  working_full_address?: Maybe<Scalars['String']>;
};


/**
 * ใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_header"
 */
export type LoanApplicationHeaderApplicationAttachmentsArgs = {
  distinct_on?: Maybe<Array<LoanApplicationAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationAttachmentOrderBy>>;
  where?: Maybe<LoanApplicationAttachmentBoolExp>;
};


/**
 * ใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_header"
 */
export type LoanApplicationHeaderApplicationAttachmentsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationAttachmentOrderBy>>;
  where?: Maybe<LoanApplicationAttachmentBoolExp>;
};


/**
 * ใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_header"
 */
export type LoanApplicationHeaderApplicationBankBranchTransfersArgs = {
  distinct_on?: Maybe<Array<LoanApplicationBankBranchTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationBankBranchTransferOrderBy>>;
  where?: Maybe<LoanApplicationBankBranchTransferBoolExp>;
};


/**
 * ใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_header"
 */
export type LoanApplicationHeaderApplicationBankBranchTransfersAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationBankBranchTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationBankBranchTransferOrderBy>>;
  where?: Maybe<LoanApplicationBankBranchTransferBoolExp>;
};


/**
 * ใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_header"
 */
export type LoanApplicationHeaderApplicationCommitteesArgs = {
  distinct_on?: Maybe<Array<LoanApplicationCommitteeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationCommitteeOrderBy>>;
  where?: Maybe<LoanApplicationCommitteeBoolExp>;
};


/**
 * ใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_header"
 */
export type LoanApplicationHeaderApplicationCommitteesAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationCommitteeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationCommitteeOrderBy>>;
  where?: Maybe<LoanApplicationCommitteeBoolExp>;
};


/**
 * ใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_header"
 */
export type LoanApplicationHeaderApplicationDetailsArgs = {
  distinct_on?: Maybe<Array<LoanApplicationDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationDetailOrderBy>>;
  where?: Maybe<LoanApplicationDetailBoolExp>;
};


/**
 * ใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_header"
 */
export type LoanApplicationHeaderApplicationDetailsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationDetailOrderBy>>;
  where?: Maybe<LoanApplicationDetailBoolExp>;
};


/**
 * ใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_header"
 */
export type LoanApplicationHeaderApplicationEventLogsArgs = {
  distinct_on?: Maybe<Array<LoanApplicationEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationEventLogOrderBy>>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
};


/**
 * ใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_header"
 */
export type LoanApplicationHeaderApplicationEventLogsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationEventLogOrderBy>>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
};


/**
 * ใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_header"
 */
export type LoanApplicationHeaderApplicationHeaderPdfsArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderPdfSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderPdfOrderBy>>;
  where?: Maybe<LoanApplicationHeaderPdfBoolExp>;
};


/**
 * ใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_header"
 */
export type LoanApplicationHeaderApplicationHeaderPdfsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderPdfSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderPdfOrderBy>>;
  where?: Maybe<LoanApplicationHeaderPdfBoolExp>;
};


/**
 * ใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_header"
 */
export type LoanApplicationHeaderApplicationObjectivesArgs = {
  distinct_on?: Maybe<Array<LoanApplicationObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationObjectiveOrderBy>>;
  where?: Maybe<LoanApplicationObjectiveBoolExp>;
};


/**
 * ใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_header"
 */
export type LoanApplicationHeaderApplicationObjectivesAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationObjectiveOrderBy>>;
  where?: Maybe<LoanApplicationObjectiveBoolExp>;
};


/**
 * ใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_header"
 */
export type LoanApplicationHeaderApplicationProfilesArgs = {
  distinct_on?: Maybe<Array<LoanApplicationProfileSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationProfileOrderBy>>;
  where?: Maybe<LoanApplicationProfileBoolExp>;
};


/**
 * ใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_header"
 */
export type LoanApplicationHeaderApplicationProfilesAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationProfileSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationProfileOrderBy>>;
  where?: Maybe<LoanApplicationProfileBoolExp>;
};


/**
 * ใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_header"
 */
export type LoanApplicationHeaderApplicationTransfersArgs = {
  distinct_on?: Maybe<Array<LoanApplicationOrganizationTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationOrganizationTransferOrderBy>>;
  where?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
};


/**
 * ใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_header"
 */
export type LoanApplicationHeaderApplicationTransfersAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationOrganizationTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationOrganizationTransferOrderBy>>;
  where?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
};

/** aggregated selection of "loan.application_header" */
export type LoanApplicationHeaderAggregate = {
  __typename?: 'loan_application_header_aggregate';
  aggregate?: Maybe<LoanApplicationHeaderAggregateFields>;
  nodes: Array<LoanApplicationHeader>;
};

/** aggregate fields of "loan.application_header" */
export type LoanApplicationHeaderAggregateFields = {
  __typename?: 'loan_application_header_aggregate_fields';
  avg?: Maybe<LoanApplicationHeaderAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanApplicationHeaderMaxFields>;
  min?: Maybe<LoanApplicationHeaderMinFields>;
  stddev?: Maybe<LoanApplicationHeaderStddevFields>;
  stddev_pop?: Maybe<LoanApplicationHeaderStddevPopFields>;
  stddev_samp?: Maybe<LoanApplicationHeaderStddevSampFields>;
  sum?: Maybe<LoanApplicationHeaderSumFields>;
  var_pop?: Maybe<LoanApplicationHeaderVarPopFields>;
  var_samp?: Maybe<LoanApplicationHeaderVarSampFields>;
  variance?: Maybe<LoanApplicationHeaderVarianceFields>;
};


/** aggregate fields of "loan.application_header" */
export type LoanApplicationHeaderAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan.application_header" */
export type LoanApplicationHeaderAggregateOrderBy = {
  avg?: Maybe<LoanApplicationHeaderAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanApplicationHeaderMaxOrderBy>;
  min?: Maybe<LoanApplicationHeaderMinOrderBy>;
  stddev?: Maybe<LoanApplicationHeaderStddevOrderBy>;
  stddev_pop?: Maybe<LoanApplicationHeaderStddevPopOrderBy>;
  stddev_samp?: Maybe<LoanApplicationHeaderStddevSampOrderBy>;
  sum?: Maybe<LoanApplicationHeaderSumOrderBy>;
  var_pop?: Maybe<LoanApplicationHeaderVarPopOrderBy>;
  var_samp?: Maybe<LoanApplicationHeaderVarSampOrderBy>;
  variance?: Maybe<LoanApplicationHeaderVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "loan.application_header" */
export type LoanApplicationHeaderArrRelInsertInput = {
  data: Array<LoanApplicationHeaderInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanApplicationHeaderOnConflict>;
};

/** aggregate avg on columns */
export type LoanApplicationHeaderAvgFields = {
  __typename?: 'loan_application_header_avg_fields';
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<Scalars['Float']>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<Scalars['Float']>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total?: Maybe<Scalars['Float']>;
  /** มูลโครงการทั้งหมด */
  project_value_total?: Maybe<Scalars['Float']>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "loan.application_header" */
export type LoanApplicationHeaderAvgOrderBy = {
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<OrderBy>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<OrderBy>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total?: Maybe<OrderBy>;
  /** มูลโครงการทั้งหมด */
  project_value_total?: Maybe<OrderBy>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "loan.application_header". All fields are combined with a logical 'AND'. */
export type LoanApplicationHeaderBoolExp = {
  _and?: Maybe<Array<LoanApplicationHeaderBoolExp>>;
  _not?: Maybe<LoanApplicationHeaderBoolExp>;
  _or?: Maybe<Array<LoanApplicationHeaderBoolExp>>;
  application_attachments?: Maybe<LoanApplicationAttachmentBoolExp>;
  application_bank_branch_transfers?: Maybe<LoanApplicationBankBranchTransferBoolExp>;
  application_committees?: Maybe<LoanApplicationCommitteeBoolExp>;
  application_detail?: Maybe<LoanApplicationDetailBoolExp>;
  application_details?: Maybe<LoanApplicationDetailBoolExp>;
  application_event_logs?: Maybe<LoanApplicationEventLogBoolExp>;
  application_header_pdf?: Maybe<LoanApplicationHeaderPdfBoolExp>;
  application_header_pdfs?: Maybe<LoanApplicationHeaderPdfBoolExp>;
  application_objectives?: Maybe<LoanApplicationObjectiveBoolExp>;
  application_profile?: Maybe<LoanApplicationProfileBoolExp>;
  application_profiles?: Maybe<LoanApplicationProfileBoolExp>;
  application_step?: Maybe<LoanApplicationStepBoolExp>;
  application_transfers?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
  bank_approve?: Maybe<LoanBankApproveBoolExp>;
  bank_approve_comment?: Maybe<StringComparisonExp>;
  bank_approve_credit?: Maybe<NumericComparisonExp>;
  bank_approve_date?: Maybe<DateComparisonExp>;
  bank_approve_value?: Maybe<LoanBankApproveEnumComparisonExp>;
  bank_branch?: Maybe<BankBranchBoolExp>;
  bank_branch_id?: Maybe<StringComparisonExp>;
  bank_branch_name?: Maybe<StringComparisonExp>;
  bank_branch_province?: Maybe<StringComparisonExp>;
  bank_effective_date?: Maybe<DateComparisonExp>;
  business_group?: Maybe<LoanBusinessGroupBoolExp>;
  business_group_description?: Maybe<StringComparisonExp>;
  business_group_id?: Maybe<UuidComparisonExp>;
  business_proprietary?: Maybe<LoanBusinessProprietaryBoolExp>;
  business_proprietary_description?: Maybe<StringComparisonExp>;
  business_proprietary_else?: Maybe<StringComparisonExp>;
  business_proprietary_id?: Maybe<UuidComparisonExp>;
  business_status?: Maybe<LoanBusinessStatusBoolExp>;
  business_status_description?: Maybe<StringComparisonExp>;
  business_status_id?: Maybe<UuidComparisonExp>;
  business_type?: Maybe<LoanBusinessTypeBoolExp>;
  business_type_description?: Maybe<StringComparisonExp>;
  business_type_id?: Maybe<StringComparisonExp>;
  committee_approve?: Maybe<LoanCommitteeApproveBoolExp>;
  committee_approve_comment?: Maybe<StringComparisonExp>;
  committee_approve_credit?: Maybe<NumericComparisonExp>;
  committee_approve_date?: Maybe<TimestamptzComparisonExp>;
  committee_approve_value?: Maybe<LoanCommitteeApproveEnumComparisonExp>;
  completed_date?: Maybe<TimestamptzComparisonExp>;
  contact_email?: Maybe<StringComparisonExp>;
  contact_first_name?: Maybe<StringComparisonExp>;
  contact_full_name?: Maybe<StringComparisonExp>;
  contact_last_name?: Maybe<StringComparisonExp>;
  contact_position?: Maybe<StringComparisonExp>;
  contact_tel?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<StringComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  document_approve?: Maybe<LoanDocumentApproveBoolExp>;
  document_approve_comment?: Maybe<StringComparisonExp>;
  document_approve_date?: Maybe<TimestamptzComparisonExp>;
  document_approve_value?: Maybe<LoanDocumentApproveEnumComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  expect_credit_total?: Maybe<NumericComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  juristic_id?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  office_address_amphoe?: Maybe<StringComparisonExp>;
  office_address_district?: Maybe<StringComparisonExp>;
  office_address_province?: Maybe<StringComparisonExp>;
  office_address_street?: Maybe<StringComparisonExp>;
  office_address_zipcode?: Maybe<StringComparisonExp>;
  office_full_address?: Maybe<StringComparisonExp>;
  organization?: Maybe<OrganizationBoolExp>;
  organization_id?: Maybe<UuidComparisonExp>;
  organization_name?: Maybe<StringComparisonExp>;
  organization_reference?: Maybe<StringComparisonExp>;
  organization_short_name?: Maybe<StringComparisonExp>;
  program?: Maybe<LoanProgramBoolExp>;
  program_id?: Maybe<UuidComparisonExp>;
  project_value_total?: Maybe<NumericComparisonExp>;
  reference?: Maybe<StringComparisonExp>;
  self_spend_total?: Maybe<NumericComparisonExp>;
  sme_sign_approve_date?: Maybe<TimestamptzComparisonExp>;
  sme_sign_date?: Maybe<DateComparisonExp>;
  start_date?: Maybe<DateComparisonExp>;
  step_id?: Maybe<StringComparisonExp>;
  submitted_date?: Maybe<TimestamptzComparisonExp>;
  tel?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<StringComparisonExp>;
  user_id?: Maybe<UuidComparisonExp>;
  working_address_amphoe?: Maybe<StringComparisonExp>;
  working_address_district?: Maybe<StringComparisonExp>;
  working_address_is_office_address?: Maybe<BooleanComparisonExp>;
  working_address_province?: Maybe<StringComparisonExp>;
  working_address_street?: Maybe<StringComparisonExp>;
  working_address_zipcode?: Maybe<StringComparisonExp>;
  working_full_address?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "loan.application_header" */
export enum LoanApplicationHeaderConstraint {
  /** unique or primary key constraint */
  ApplicationHeaderJuristicIdProgramIdKey = 'application_header_juristic_id_program_id_key',
  /** unique or primary key constraint */
  ApplicationHeaderPkey = 'application_header_pkey'
}

/** input type for incrementing numeric columns in table "loan.application_header" */
export type LoanApplicationHeaderIncInput = {
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<Scalars['numeric']>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<Scalars['numeric']>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total?: Maybe<Scalars['numeric']>;
  /** มูลโครงการทั้งหมด */
  project_value_total?: Maybe<Scalars['numeric']>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.application_header" */
export type LoanApplicationHeaderInsertInput = {
  application_attachments?: Maybe<LoanApplicationAttachmentArrRelInsertInput>;
  application_bank_branch_transfers?: Maybe<LoanApplicationBankBranchTransferArrRelInsertInput>;
  application_committees?: Maybe<LoanApplicationCommitteeArrRelInsertInput>;
  application_detail?: Maybe<LoanApplicationDetailObjRelInsertInput>;
  application_details?: Maybe<LoanApplicationDetailArrRelInsertInput>;
  application_event_logs?: Maybe<LoanApplicationEventLogArrRelInsertInput>;
  application_header_pdf?: Maybe<LoanApplicationHeaderPdfObjRelInsertInput>;
  application_header_pdfs?: Maybe<LoanApplicationHeaderPdfArrRelInsertInput>;
  application_objectives?: Maybe<LoanApplicationObjectiveArrRelInsertInput>;
  application_profile?: Maybe<LoanApplicationProfileObjRelInsertInput>;
  application_profiles?: Maybe<LoanApplicationProfileArrRelInsertInput>;
  application_step?: Maybe<LoanApplicationStepObjRelInsertInput>;
  application_transfers?: Maybe<LoanApplicationOrganizationTransferArrRelInsertInput>;
  bank_approve?: Maybe<LoanBankApproveObjRelInsertInput>;
  /** หมายเหตุ ขั้นตอนพิจารณาจากธนาคาร */
  bank_approve_comment?: Maybe<Scalars['String']>;
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<Scalars['numeric']>;
  /** วันที่บันทึกผลพิจารณาและอนุมัติจากธนาคาร */
  bank_approve_date?: Maybe<Scalars['date']>;
  /** ผลพิจารณาและอนุมัติจากธนาคาร */
  bank_approve_value?: Maybe<LoanBankApproveEnum>;
  bank_branch?: Maybe<BankBranchObjRelInsertInput>;
  /** รหัสธนาคาร */
  bank_branch_id?: Maybe<Scalars['String']>;
  /** ชื่อธนาคาร */
  bank_branch_name?: Maybe<Scalars['String']>;
  /** จังหวัดธนาคาร */
  bank_branch_province?: Maybe<Scalars['String']>;
  /** วันที่ทำสัญญากับธนาคาร */
  bank_effective_date?: Maybe<Scalars['date']>;
  business_group?: Maybe<LoanBusinessGroupObjRelInsertInput>;
  /** freeze data ประเภทธุรกิจ */
  business_group_description?: Maybe<Scalars['String']>;
  /** กลุ่มธุรกิจ */
  business_group_id?: Maybe<Scalars['uuid']>;
  business_proprietary?: Maybe<LoanBusinessProprietaryObjRelInsertInput>;
  /** ลักษณะสถานประกอบการ */
  business_proprietary_description?: Maybe<Scalars['String']>;
  /** ลักษณะสถานประกอบการ อื่น ๆ ระบุ */
  business_proprietary_else?: Maybe<Scalars['String']>;
  /** ลักษณะสถานประกอบการ */
  business_proprietary_id?: Maybe<Scalars['uuid']>;
  business_status?: Maybe<LoanBusinessStatusObjRelInsertInput>;
  /** สถานะภาพการดำเนินธุรกิจ */
  business_status_description?: Maybe<Scalars['String']>;
  /** สถานะภาพการดำเนินธุรกิจ */
  business_status_id?: Maybe<Scalars['uuid']>;
  business_type?: Maybe<LoanBusinessTypeObjRelInsertInput>;
  /** freeze data ประเภทธุรกิจ */
  business_type_description?: Maybe<Scalars['String']>;
  /** ประเภทธุรกิจ */
  business_type_id?: Maybe<Scalars['String']>;
  committee_approve?: Maybe<LoanCommitteeApproveObjRelInsertInput>;
  /** หมายเหตุ ขั้นตอนตรวจพิจารณากลั่นกรอง */
  committee_approve_comment?: Maybe<Scalars['String']>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<Scalars['numeric']>;
  /** วันที่ พิจารณาคัดสรร กลั่นกรอง เสร็จ */
  committee_approve_date?: Maybe<Scalars['timestamptz']>;
  /** พิจารณาคัดสรร กลั่นกรองคุณสมบัติ */
  committee_approve_value?: Maybe<LoanCommitteeApproveEnum>;
  /** วันที่ ลงนาม ประทับตรา */
  completed_date?: Maybe<Scalars['timestamptz']>;
  /** ผู้ติดต่อ อีเมล */
  contact_email?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ ชื่อ */
  contact_first_name?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ นามสกุล */
  contact_last_name?: Maybe<Scalars['String']>;
  /** ตำแหน่งผู้ติดต่อ */
  contact_position?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ เบอร์โทรศัพท์ */
  contact_tel?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  document_approve?: Maybe<LoanDocumentApproveObjRelInsertInput>;
  /** หมายเหตุ ขั้นตอนตรวจสอบเอกสารเบื้องต้น */
  document_approve_comment?: Maybe<Scalars['String']>;
  /** วันที่ ตรวจสอบคุณสมบัติเบื้องต้น เสร็จ */
  document_approve_date?: Maybe<Scalars['timestamptz']>;
  /** ตรวจสอบเอกสาร คุณสมบัติเบื้องต้น */
  document_approve_value?: Maybe<LoanDocumentApproveEnum>;
  /** E-mail */
  email?: Maybe<Scalars['String']>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total?: Maybe<Scalars['numeric']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** เลขทะเบียนนิติบุคคล */
  juristic_id?: Maybe<Scalars['String']>;
  /** บจก./หจก./หสน. */
  name?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_amphoe?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_district?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_province?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_street?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_zipcode?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationObjRelInsertInput>;
  /** รหัสอ้างอิงหน่วยงาน */
  organization_id?: Maybe<Scalars['uuid']>;
  /** ชื่อหน่วยงาน / ศูนย์ภาค (ศภ.) */
  organization_name?: Maybe<Scalars['String']>;
  /** รหัส หน่วยงาน / ศูนย์ภาค (ศภ.) */
  organization_reference?: Maybe<Scalars['String']>;
  /** ชื่อย่อหน่วยงาน / ศูนย์ภาค (ศภ.) */
  organization_short_name?: Maybe<Scalars['String']>;
  program?: Maybe<LoanProgramObjRelInsertInput>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** มูลโครงการทั้งหมด */
  project_value_total?: Maybe<Scalars['numeric']>;
  /** รหัสใบคำขอสินเชื่อ */
  reference?: Maybe<Scalars['String']>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total?: Maybe<Scalars['numeric']>;
  /** วันที่บันทึกผลการยื่นเอกสารฉบับจริง */
  sme_sign_approve_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่ยื่นเอกสารฉบับจริง */
  sme_sign_date?: Maybe<Scalars['date']>;
  /** วันที่เริ่มทำธุรกิจ */
  start_date?: Maybe<Scalars['date']>;
  /** ขั้นตอนของฟอร์ม */
  step_id?: Maybe<Scalars['String']>;
  /** วันที่ submit ข้อมูลเข้าระบบ */
  submitted_date?: Maybe<Scalars['timestamptz']>;
  /** โทรศัพท์ */
  tel?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<Scalars['uuid']>;
  /** อำเภอ ที่ทำงานปัจจุบัน */
  working_address_amphoe?: Maybe<Scalars['String']>;
  /** ตำบล ที่ทำงานปัจจุบัน */
  working_address_district?: Maybe<Scalars['String']>;
  /** ที่เดียวกับที่ทำงาน */
  working_address_is_office_address?: Maybe<Scalars['Boolean']>;
  /** จังหวัด ที่ทำงานปัจจุบัน */
  working_address_province?: Maybe<Scalars['String']>;
  /** ที่อยู่ ที่ทำงานปัจจุบัน */
  working_address_street?: Maybe<Scalars['String']>;
  /** รหัสไปรษณีย์ ที่ทำงานปัจจุบัน */
  working_address_zipcode?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LoanApplicationHeaderMaxFields = {
  __typename?: 'loan_application_header_max_fields';
  /** หมายเหตุ ขั้นตอนพิจารณาจากธนาคาร */
  bank_approve_comment?: Maybe<Scalars['String']>;
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<Scalars['numeric']>;
  /** วันที่บันทึกผลพิจารณาและอนุมัติจากธนาคาร */
  bank_approve_date?: Maybe<Scalars['date']>;
  /** รหัสธนาคาร */
  bank_branch_id?: Maybe<Scalars['String']>;
  /** ชื่อธนาคาร */
  bank_branch_name?: Maybe<Scalars['String']>;
  /** จังหวัดธนาคาร */
  bank_branch_province?: Maybe<Scalars['String']>;
  /** วันที่ทำสัญญากับธนาคาร */
  bank_effective_date?: Maybe<Scalars['date']>;
  /** freeze data ประเภทธุรกิจ */
  business_group_description?: Maybe<Scalars['String']>;
  /** กลุ่มธุรกิจ */
  business_group_id?: Maybe<Scalars['uuid']>;
  /** ลักษณะสถานประกอบการ */
  business_proprietary_description?: Maybe<Scalars['String']>;
  /** ลักษณะสถานประกอบการ อื่น ๆ ระบุ */
  business_proprietary_else?: Maybe<Scalars['String']>;
  /** ลักษณะสถานประกอบการ */
  business_proprietary_id?: Maybe<Scalars['uuid']>;
  /** สถานะภาพการดำเนินธุรกิจ */
  business_status_description?: Maybe<Scalars['String']>;
  /** สถานะภาพการดำเนินธุรกิจ */
  business_status_id?: Maybe<Scalars['uuid']>;
  /** freeze data ประเภทธุรกิจ */
  business_type_description?: Maybe<Scalars['String']>;
  /** ประเภทธุรกิจ */
  business_type_id?: Maybe<Scalars['String']>;
  /** หมายเหตุ ขั้นตอนตรวจพิจารณากลั่นกรอง */
  committee_approve_comment?: Maybe<Scalars['String']>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<Scalars['numeric']>;
  /** วันที่ พิจารณาคัดสรร กลั่นกรอง เสร็จ */
  committee_approve_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่ ลงนาม ประทับตรา */
  completed_date?: Maybe<Scalars['timestamptz']>;
  /** ผู้ติดต่อ อีเมล */
  contact_email?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ ชื่อ */
  contact_first_name?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ นามสกุล */
  contact_last_name?: Maybe<Scalars['String']>;
  /** ตำแหน่งผู้ติดต่อ */
  contact_position?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ เบอร์โทรศัพท์ */
  contact_tel?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** หมายเหตุ ขั้นตอนตรวจสอบเอกสารเบื้องต้น */
  document_approve_comment?: Maybe<Scalars['String']>;
  /** วันที่ ตรวจสอบคุณสมบัติเบื้องต้น เสร็จ */
  document_approve_date?: Maybe<Scalars['timestamptz']>;
  /** E-mail */
  email?: Maybe<Scalars['String']>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total?: Maybe<Scalars['numeric']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** เลขทะเบียนนิติบุคคล */
  juristic_id?: Maybe<Scalars['String']>;
  /** บจก./หจก./หสน. */
  name?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_amphoe?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_district?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_province?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_street?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_zipcode?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิงหน่วยงาน */
  organization_id?: Maybe<Scalars['uuid']>;
  /** ชื่อหน่วยงาน / ศูนย์ภาค (ศภ.) */
  organization_name?: Maybe<Scalars['String']>;
  /** รหัส หน่วยงาน / ศูนย์ภาค (ศภ.) */
  organization_reference?: Maybe<Scalars['String']>;
  /** ชื่อย่อหน่วยงาน / ศูนย์ภาค (ศภ.) */
  organization_short_name?: Maybe<Scalars['String']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** มูลโครงการทั้งหมด */
  project_value_total?: Maybe<Scalars['numeric']>;
  /** รหัสใบคำขอสินเชื่อ */
  reference?: Maybe<Scalars['String']>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total?: Maybe<Scalars['numeric']>;
  /** วันที่บันทึกผลการยื่นเอกสารฉบับจริง */
  sme_sign_approve_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่ยื่นเอกสารฉบับจริง */
  sme_sign_date?: Maybe<Scalars['date']>;
  /** วันที่เริ่มทำธุรกิจ */
  start_date?: Maybe<Scalars['date']>;
  /** ขั้นตอนของฟอร์ม */
  step_id?: Maybe<Scalars['String']>;
  /** วันที่ submit ข้อมูลเข้าระบบ */
  submitted_date?: Maybe<Scalars['timestamptz']>;
  /** โทรศัพท์ */
  tel?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<Scalars['uuid']>;
  /** อำเภอ ที่ทำงานปัจจุบัน */
  working_address_amphoe?: Maybe<Scalars['String']>;
  /** ตำบล ที่ทำงานปัจจุบัน */
  working_address_district?: Maybe<Scalars['String']>;
  /** จังหวัด ที่ทำงานปัจจุบัน */
  working_address_province?: Maybe<Scalars['String']>;
  /** ที่อยู่ ที่ทำงานปัจจุบัน */
  working_address_street?: Maybe<Scalars['String']>;
  /** รหัสไปรษณีย์ ที่ทำงานปัจจุบัน */
  working_address_zipcode?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "loan.application_header" */
export type LoanApplicationHeaderMaxOrderBy = {
  /** หมายเหตุ ขั้นตอนพิจารณาจากธนาคาร */
  bank_approve_comment?: Maybe<OrderBy>;
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<OrderBy>;
  /** วันที่บันทึกผลพิจารณาและอนุมัติจากธนาคาร */
  bank_approve_date?: Maybe<OrderBy>;
  /** รหัสธนาคาร */
  bank_branch_id?: Maybe<OrderBy>;
  /** ชื่อธนาคาร */
  bank_branch_name?: Maybe<OrderBy>;
  /** จังหวัดธนาคาร */
  bank_branch_province?: Maybe<OrderBy>;
  /** วันที่ทำสัญญากับธนาคาร */
  bank_effective_date?: Maybe<OrderBy>;
  /** freeze data ประเภทธุรกิจ */
  business_group_description?: Maybe<OrderBy>;
  /** กลุ่มธุรกิจ */
  business_group_id?: Maybe<OrderBy>;
  /** ลักษณะสถานประกอบการ */
  business_proprietary_description?: Maybe<OrderBy>;
  /** ลักษณะสถานประกอบการ อื่น ๆ ระบุ */
  business_proprietary_else?: Maybe<OrderBy>;
  /** ลักษณะสถานประกอบการ */
  business_proprietary_id?: Maybe<OrderBy>;
  /** สถานะภาพการดำเนินธุรกิจ */
  business_status_description?: Maybe<OrderBy>;
  /** สถานะภาพการดำเนินธุรกิจ */
  business_status_id?: Maybe<OrderBy>;
  /** freeze data ประเภทธุรกิจ */
  business_type_description?: Maybe<OrderBy>;
  /** ประเภทธุรกิจ */
  business_type_id?: Maybe<OrderBy>;
  /** หมายเหตุ ขั้นตอนตรวจพิจารณากลั่นกรอง */
  committee_approve_comment?: Maybe<OrderBy>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<OrderBy>;
  /** วันที่ พิจารณาคัดสรร กลั่นกรอง เสร็จ */
  committee_approve_date?: Maybe<OrderBy>;
  /** วันที่ ลงนาม ประทับตรา */
  completed_date?: Maybe<OrderBy>;
  /** ผู้ติดต่อ อีเมล */
  contact_email?: Maybe<OrderBy>;
  /** ผู้ติดต่อ ชื่อ */
  contact_first_name?: Maybe<OrderBy>;
  /** ผู้ติดต่อ นามสกุล */
  contact_last_name?: Maybe<OrderBy>;
  /** ตำแหน่งผู้ติดต่อ */
  contact_position?: Maybe<OrderBy>;
  /** ผู้ติดต่อ เบอร์โทรศัพท์ */
  contact_tel?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** หมายเหตุ ขั้นตอนตรวจสอบเอกสารเบื้องต้น */
  document_approve_comment?: Maybe<OrderBy>;
  /** วันที่ ตรวจสอบคุณสมบัติเบื้องต้น เสร็จ */
  document_approve_date?: Maybe<OrderBy>;
  /** E-mail */
  email?: Maybe<OrderBy>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** เลขทะเบียนนิติบุคคล */
  juristic_id?: Maybe<OrderBy>;
  /** บจก./หจก./หสน. */
  name?: Maybe<OrderBy>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_amphoe?: Maybe<OrderBy>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_district?: Maybe<OrderBy>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_province?: Maybe<OrderBy>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_street?: Maybe<OrderBy>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_zipcode?: Maybe<OrderBy>;
  /** รหัสอ้างอิงหน่วยงาน */
  organization_id?: Maybe<OrderBy>;
  /** ชื่อหน่วยงาน / ศูนย์ภาค (ศภ.) */
  organization_name?: Maybe<OrderBy>;
  /** รหัส หน่วยงาน / ศูนย์ภาค (ศภ.) */
  organization_reference?: Maybe<OrderBy>;
  /** ชื่อย่อหน่วยงาน / ศูนย์ภาค (ศภ.) */
  organization_short_name?: Maybe<OrderBy>;
  /** รหัสโครงการ */
  program_id?: Maybe<OrderBy>;
  /** มูลโครงการทั้งหมด */
  project_value_total?: Maybe<OrderBy>;
  /** รหัสใบคำขอสินเชื่อ */
  reference?: Maybe<OrderBy>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total?: Maybe<OrderBy>;
  /** วันที่บันทึกผลการยื่นเอกสารฉบับจริง */
  sme_sign_approve_date?: Maybe<OrderBy>;
  /** วันที่ยื่นเอกสารฉบับจริง */
  sme_sign_date?: Maybe<OrderBy>;
  /** วันที่เริ่มทำธุรกิจ */
  start_date?: Maybe<OrderBy>;
  /** ขั้นตอนของฟอร์ม */
  step_id?: Maybe<OrderBy>;
  /** วันที่ submit ข้อมูลเข้าระบบ */
  submitted_date?: Maybe<OrderBy>;
  /** โทรศัพท์ */
  tel?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<OrderBy>;
  /** อำเภอ ที่ทำงานปัจจุบัน */
  working_address_amphoe?: Maybe<OrderBy>;
  /** ตำบล ที่ทำงานปัจจุบัน */
  working_address_district?: Maybe<OrderBy>;
  /** จังหวัด ที่ทำงานปัจจุบัน */
  working_address_province?: Maybe<OrderBy>;
  /** ที่อยู่ ที่ทำงานปัจจุบัน */
  working_address_street?: Maybe<OrderBy>;
  /** รหัสไปรษณีย์ ที่ทำงานปัจจุบัน */
  working_address_zipcode?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanApplicationHeaderMinFields = {
  __typename?: 'loan_application_header_min_fields';
  /** หมายเหตุ ขั้นตอนพิจารณาจากธนาคาร */
  bank_approve_comment?: Maybe<Scalars['String']>;
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<Scalars['numeric']>;
  /** วันที่บันทึกผลพิจารณาและอนุมัติจากธนาคาร */
  bank_approve_date?: Maybe<Scalars['date']>;
  /** รหัสธนาคาร */
  bank_branch_id?: Maybe<Scalars['String']>;
  /** ชื่อธนาคาร */
  bank_branch_name?: Maybe<Scalars['String']>;
  /** จังหวัดธนาคาร */
  bank_branch_province?: Maybe<Scalars['String']>;
  /** วันที่ทำสัญญากับธนาคาร */
  bank_effective_date?: Maybe<Scalars['date']>;
  /** freeze data ประเภทธุรกิจ */
  business_group_description?: Maybe<Scalars['String']>;
  /** กลุ่มธุรกิจ */
  business_group_id?: Maybe<Scalars['uuid']>;
  /** ลักษณะสถานประกอบการ */
  business_proprietary_description?: Maybe<Scalars['String']>;
  /** ลักษณะสถานประกอบการ อื่น ๆ ระบุ */
  business_proprietary_else?: Maybe<Scalars['String']>;
  /** ลักษณะสถานประกอบการ */
  business_proprietary_id?: Maybe<Scalars['uuid']>;
  /** สถานะภาพการดำเนินธุรกิจ */
  business_status_description?: Maybe<Scalars['String']>;
  /** สถานะภาพการดำเนินธุรกิจ */
  business_status_id?: Maybe<Scalars['uuid']>;
  /** freeze data ประเภทธุรกิจ */
  business_type_description?: Maybe<Scalars['String']>;
  /** ประเภทธุรกิจ */
  business_type_id?: Maybe<Scalars['String']>;
  /** หมายเหตุ ขั้นตอนตรวจพิจารณากลั่นกรอง */
  committee_approve_comment?: Maybe<Scalars['String']>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<Scalars['numeric']>;
  /** วันที่ พิจารณาคัดสรร กลั่นกรอง เสร็จ */
  committee_approve_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่ ลงนาม ประทับตรา */
  completed_date?: Maybe<Scalars['timestamptz']>;
  /** ผู้ติดต่อ อีเมล */
  contact_email?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ ชื่อ */
  contact_first_name?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ นามสกุล */
  contact_last_name?: Maybe<Scalars['String']>;
  /** ตำแหน่งผู้ติดต่อ */
  contact_position?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ เบอร์โทรศัพท์ */
  contact_tel?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** หมายเหตุ ขั้นตอนตรวจสอบเอกสารเบื้องต้น */
  document_approve_comment?: Maybe<Scalars['String']>;
  /** วันที่ ตรวจสอบคุณสมบัติเบื้องต้น เสร็จ */
  document_approve_date?: Maybe<Scalars['timestamptz']>;
  /** E-mail */
  email?: Maybe<Scalars['String']>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total?: Maybe<Scalars['numeric']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** เลขทะเบียนนิติบุคคล */
  juristic_id?: Maybe<Scalars['String']>;
  /** บจก./หจก./หสน. */
  name?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_amphoe?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_district?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_province?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_street?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_zipcode?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิงหน่วยงาน */
  organization_id?: Maybe<Scalars['uuid']>;
  /** ชื่อหน่วยงาน / ศูนย์ภาค (ศภ.) */
  organization_name?: Maybe<Scalars['String']>;
  /** รหัส หน่วยงาน / ศูนย์ภาค (ศภ.) */
  organization_reference?: Maybe<Scalars['String']>;
  /** ชื่อย่อหน่วยงาน / ศูนย์ภาค (ศภ.) */
  organization_short_name?: Maybe<Scalars['String']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** มูลโครงการทั้งหมด */
  project_value_total?: Maybe<Scalars['numeric']>;
  /** รหัสใบคำขอสินเชื่อ */
  reference?: Maybe<Scalars['String']>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total?: Maybe<Scalars['numeric']>;
  /** วันที่บันทึกผลการยื่นเอกสารฉบับจริง */
  sme_sign_approve_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่ยื่นเอกสารฉบับจริง */
  sme_sign_date?: Maybe<Scalars['date']>;
  /** วันที่เริ่มทำธุรกิจ */
  start_date?: Maybe<Scalars['date']>;
  /** ขั้นตอนของฟอร์ม */
  step_id?: Maybe<Scalars['String']>;
  /** วันที่ submit ข้อมูลเข้าระบบ */
  submitted_date?: Maybe<Scalars['timestamptz']>;
  /** โทรศัพท์ */
  tel?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<Scalars['uuid']>;
  /** อำเภอ ที่ทำงานปัจจุบัน */
  working_address_amphoe?: Maybe<Scalars['String']>;
  /** ตำบล ที่ทำงานปัจจุบัน */
  working_address_district?: Maybe<Scalars['String']>;
  /** จังหวัด ที่ทำงานปัจจุบัน */
  working_address_province?: Maybe<Scalars['String']>;
  /** ที่อยู่ ที่ทำงานปัจจุบัน */
  working_address_street?: Maybe<Scalars['String']>;
  /** รหัสไปรษณีย์ ที่ทำงานปัจจุบัน */
  working_address_zipcode?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "loan.application_header" */
export type LoanApplicationHeaderMinOrderBy = {
  /** หมายเหตุ ขั้นตอนพิจารณาจากธนาคาร */
  bank_approve_comment?: Maybe<OrderBy>;
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<OrderBy>;
  /** วันที่บันทึกผลพิจารณาและอนุมัติจากธนาคาร */
  bank_approve_date?: Maybe<OrderBy>;
  /** รหัสธนาคาร */
  bank_branch_id?: Maybe<OrderBy>;
  /** ชื่อธนาคาร */
  bank_branch_name?: Maybe<OrderBy>;
  /** จังหวัดธนาคาร */
  bank_branch_province?: Maybe<OrderBy>;
  /** วันที่ทำสัญญากับธนาคาร */
  bank_effective_date?: Maybe<OrderBy>;
  /** freeze data ประเภทธุรกิจ */
  business_group_description?: Maybe<OrderBy>;
  /** กลุ่มธุรกิจ */
  business_group_id?: Maybe<OrderBy>;
  /** ลักษณะสถานประกอบการ */
  business_proprietary_description?: Maybe<OrderBy>;
  /** ลักษณะสถานประกอบการ อื่น ๆ ระบุ */
  business_proprietary_else?: Maybe<OrderBy>;
  /** ลักษณะสถานประกอบการ */
  business_proprietary_id?: Maybe<OrderBy>;
  /** สถานะภาพการดำเนินธุรกิจ */
  business_status_description?: Maybe<OrderBy>;
  /** สถานะภาพการดำเนินธุรกิจ */
  business_status_id?: Maybe<OrderBy>;
  /** freeze data ประเภทธุรกิจ */
  business_type_description?: Maybe<OrderBy>;
  /** ประเภทธุรกิจ */
  business_type_id?: Maybe<OrderBy>;
  /** หมายเหตุ ขั้นตอนตรวจพิจารณากลั่นกรอง */
  committee_approve_comment?: Maybe<OrderBy>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<OrderBy>;
  /** วันที่ พิจารณาคัดสรร กลั่นกรอง เสร็จ */
  committee_approve_date?: Maybe<OrderBy>;
  /** วันที่ ลงนาม ประทับตรา */
  completed_date?: Maybe<OrderBy>;
  /** ผู้ติดต่อ อีเมล */
  contact_email?: Maybe<OrderBy>;
  /** ผู้ติดต่อ ชื่อ */
  contact_first_name?: Maybe<OrderBy>;
  /** ผู้ติดต่อ นามสกุล */
  contact_last_name?: Maybe<OrderBy>;
  /** ตำแหน่งผู้ติดต่อ */
  contact_position?: Maybe<OrderBy>;
  /** ผู้ติดต่อ เบอร์โทรศัพท์ */
  contact_tel?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** หมายเหตุ ขั้นตอนตรวจสอบเอกสารเบื้องต้น */
  document_approve_comment?: Maybe<OrderBy>;
  /** วันที่ ตรวจสอบคุณสมบัติเบื้องต้น เสร็จ */
  document_approve_date?: Maybe<OrderBy>;
  /** E-mail */
  email?: Maybe<OrderBy>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** เลขทะเบียนนิติบุคคล */
  juristic_id?: Maybe<OrderBy>;
  /** บจก./หจก./หสน. */
  name?: Maybe<OrderBy>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_amphoe?: Maybe<OrderBy>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_district?: Maybe<OrderBy>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_province?: Maybe<OrderBy>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_street?: Maybe<OrderBy>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_zipcode?: Maybe<OrderBy>;
  /** รหัสอ้างอิงหน่วยงาน */
  organization_id?: Maybe<OrderBy>;
  /** ชื่อหน่วยงาน / ศูนย์ภาค (ศภ.) */
  organization_name?: Maybe<OrderBy>;
  /** รหัส หน่วยงาน / ศูนย์ภาค (ศภ.) */
  organization_reference?: Maybe<OrderBy>;
  /** ชื่อย่อหน่วยงาน / ศูนย์ภาค (ศภ.) */
  organization_short_name?: Maybe<OrderBy>;
  /** รหัสโครงการ */
  program_id?: Maybe<OrderBy>;
  /** มูลโครงการทั้งหมด */
  project_value_total?: Maybe<OrderBy>;
  /** รหัสใบคำขอสินเชื่อ */
  reference?: Maybe<OrderBy>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total?: Maybe<OrderBy>;
  /** วันที่บันทึกผลการยื่นเอกสารฉบับจริง */
  sme_sign_approve_date?: Maybe<OrderBy>;
  /** วันที่ยื่นเอกสารฉบับจริง */
  sme_sign_date?: Maybe<OrderBy>;
  /** วันที่เริ่มทำธุรกิจ */
  start_date?: Maybe<OrderBy>;
  /** ขั้นตอนของฟอร์ม */
  step_id?: Maybe<OrderBy>;
  /** วันที่ submit ข้อมูลเข้าระบบ */
  submitted_date?: Maybe<OrderBy>;
  /** โทรศัพท์ */
  tel?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<OrderBy>;
  /** อำเภอ ที่ทำงานปัจจุบัน */
  working_address_amphoe?: Maybe<OrderBy>;
  /** ตำบล ที่ทำงานปัจจุบัน */
  working_address_district?: Maybe<OrderBy>;
  /** จังหวัด ที่ทำงานปัจจุบัน */
  working_address_province?: Maybe<OrderBy>;
  /** ที่อยู่ ที่ทำงานปัจจุบัน */
  working_address_street?: Maybe<OrderBy>;
  /** รหัสไปรษณีย์ ที่ทำงานปัจจุบัน */
  working_address_zipcode?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan.application_header" */
export type LoanApplicationHeaderMutationResponse = {
  __typename?: 'loan_application_header_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanApplicationHeader>;
};

/** input type for inserting object relation for remote table "loan.application_header" */
export type LoanApplicationHeaderObjRelInsertInput = {
  data: LoanApplicationHeaderInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanApplicationHeaderOnConflict>;
};

/** on conflict condition type for table "loan.application_header" */
export type LoanApplicationHeaderOnConflict = {
  constraint: LoanApplicationHeaderConstraint;
  update_columns?: Array<LoanApplicationHeaderUpdateColumn>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};

/** Ordering options when selecting data from "loan.application_header". */
export type LoanApplicationHeaderOrderBy = {
  application_attachments_aggregate?: Maybe<LoanApplicationAttachmentAggregateOrderBy>;
  application_bank_branch_transfers_aggregate?: Maybe<LoanApplicationBankBranchTransferAggregateOrderBy>;
  application_committees_aggregate?: Maybe<LoanApplicationCommitteeAggregateOrderBy>;
  application_detail?: Maybe<LoanApplicationDetailOrderBy>;
  application_details_aggregate?: Maybe<LoanApplicationDetailAggregateOrderBy>;
  application_event_logs_aggregate?: Maybe<LoanApplicationEventLogAggregateOrderBy>;
  application_header_pdf?: Maybe<LoanApplicationHeaderPdfOrderBy>;
  application_header_pdfs_aggregate?: Maybe<LoanApplicationHeaderPdfAggregateOrderBy>;
  application_objectives_aggregate?: Maybe<LoanApplicationObjectiveAggregateOrderBy>;
  application_profile?: Maybe<LoanApplicationProfileOrderBy>;
  application_profiles_aggregate?: Maybe<LoanApplicationProfileAggregateOrderBy>;
  application_step?: Maybe<LoanApplicationStepOrderBy>;
  application_transfers_aggregate?: Maybe<LoanApplicationOrganizationTransferAggregateOrderBy>;
  bank_approve?: Maybe<LoanBankApproveOrderBy>;
  bank_approve_comment?: Maybe<OrderBy>;
  bank_approve_credit?: Maybe<OrderBy>;
  bank_approve_date?: Maybe<OrderBy>;
  bank_approve_value?: Maybe<OrderBy>;
  bank_branch?: Maybe<BankBranchOrderBy>;
  bank_branch_id?: Maybe<OrderBy>;
  bank_branch_name?: Maybe<OrderBy>;
  bank_branch_province?: Maybe<OrderBy>;
  bank_effective_date?: Maybe<OrderBy>;
  business_group?: Maybe<LoanBusinessGroupOrderBy>;
  business_group_description?: Maybe<OrderBy>;
  business_group_id?: Maybe<OrderBy>;
  business_proprietary?: Maybe<LoanBusinessProprietaryOrderBy>;
  business_proprietary_description?: Maybe<OrderBy>;
  business_proprietary_else?: Maybe<OrderBy>;
  business_proprietary_id?: Maybe<OrderBy>;
  business_status?: Maybe<LoanBusinessStatusOrderBy>;
  business_status_description?: Maybe<OrderBy>;
  business_status_id?: Maybe<OrderBy>;
  business_type?: Maybe<LoanBusinessTypeOrderBy>;
  business_type_description?: Maybe<OrderBy>;
  business_type_id?: Maybe<OrderBy>;
  committee_approve?: Maybe<LoanCommitteeApproveOrderBy>;
  committee_approve_comment?: Maybe<OrderBy>;
  committee_approve_credit?: Maybe<OrderBy>;
  committee_approve_date?: Maybe<OrderBy>;
  committee_approve_value?: Maybe<OrderBy>;
  completed_date?: Maybe<OrderBy>;
  contact_email?: Maybe<OrderBy>;
  contact_first_name?: Maybe<OrderBy>;
  contact_full_name?: Maybe<OrderBy>;
  contact_last_name?: Maybe<OrderBy>;
  contact_position?: Maybe<OrderBy>;
  contact_tel?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  document_approve?: Maybe<LoanDocumentApproveOrderBy>;
  document_approve_comment?: Maybe<OrderBy>;
  document_approve_date?: Maybe<OrderBy>;
  document_approve_value?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  expect_credit_total?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  juristic_id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  office_address_amphoe?: Maybe<OrderBy>;
  office_address_district?: Maybe<OrderBy>;
  office_address_province?: Maybe<OrderBy>;
  office_address_street?: Maybe<OrderBy>;
  office_address_zipcode?: Maybe<OrderBy>;
  office_full_address?: Maybe<OrderBy>;
  organization?: Maybe<OrganizationOrderBy>;
  organization_id?: Maybe<OrderBy>;
  organization_name?: Maybe<OrderBy>;
  organization_reference?: Maybe<OrderBy>;
  organization_short_name?: Maybe<OrderBy>;
  program?: Maybe<LoanProgramOrderBy>;
  program_id?: Maybe<OrderBy>;
  project_value_total?: Maybe<OrderBy>;
  reference?: Maybe<OrderBy>;
  self_spend_total?: Maybe<OrderBy>;
  sme_sign_approve_date?: Maybe<OrderBy>;
  sme_sign_date?: Maybe<OrderBy>;
  start_date?: Maybe<OrderBy>;
  step_id?: Maybe<OrderBy>;
  submitted_date?: Maybe<OrderBy>;
  tel?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
  working_address_amphoe?: Maybe<OrderBy>;
  working_address_district?: Maybe<OrderBy>;
  working_address_is_office_address?: Maybe<OrderBy>;
  working_address_province?: Maybe<OrderBy>;
  working_address_street?: Maybe<OrderBy>;
  working_address_zipcode?: Maybe<OrderBy>;
  working_full_address?: Maybe<OrderBy>;
};

/**
 * ไฟล์ PDF ใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_header_pdf"
 */
export type LoanApplicationHeaderPdf = {
  __typename?: 'loan_application_header_pdf';
  /** An object relationship */
  application_header: LoanApplicationHeader;
  /** Primary key */
  id: Scalars['uuid'];
  /** ไฟล์ */
  pdf_file: Scalars['String'];
};

/** aggregated selection of "loan.application_header_pdf" */
export type LoanApplicationHeaderPdfAggregate = {
  __typename?: 'loan_application_header_pdf_aggregate';
  aggregate?: Maybe<LoanApplicationHeaderPdfAggregateFields>;
  nodes: Array<LoanApplicationHeaderPdf>;
};

/** aggregate fields of "loan.application_header_pdf" */
export type LoanApplicationHeaderPdfAggregateFields = {
  __typename?: 'loan_application_header_pdf_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LoanApplicationHeaderPdfMaxFields>;
  min?: Maybe<LoanApplicationHeaderPdfMinFields>;
};


/** aggregate fields of "loan.application_header_pdf" */
export type LoanApplicationHeaderPdfAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanApplicationHeaderPdfSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan.application_header_pdf" */
export type LoanApplicationHeaderPdfAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanApplicationHeaderPdfMaxOrderBy>;
  min?: Maybe<LoanApplicationHeaderPdfMinOrderBy>;
};

/** input type for inserting array relation for remote table "loan.application_header_pdf" */
export type LoanApplicationHeaderPdfArrRelInsertInput = {
  data: Array<LoanApplicationHeaderPdfInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanApplicationHeaderPdfOnConflict>;
};

/** Boolean expression to filter rows from the table "loan.application_header_pdf". All fields are combined with a logical 'AND'. */
export type LoanApplicationHeaderPdfBoolExp = {
  _and?: Maybe<Array<LoanApplicationHeaderPdfBoolExp>>;
  _not?: Maybe<LoanApplicationHeaderPdfBoolExp>;
  _or?: Maybe<Array<LoanApplicationHeaderPdfBoolExp>>;
  application_header?: Maybe<LoanApplicationHeaderBoolExp>;
  id?: Maybe<UuidComparisonExp>;
  pdf_file?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "loan.application_header_pdf" */
export enum LoanApplicationHeaderPdfConstraint {
  /** unique or primary key constraint */
  ApplicationHeaderPdfPkey = 'application_header_pdf_pkey'
}

/** input type for inserting data into table "loan.application_header_pdf" */
export type LoanApplicationHeaderPdfInsertInput = {
  application_header?: Maybe<LoanApplicationHeaderObjRelInsertInput>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ไฟล์ */
  pdf_file?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LoanApplicationHeaderPdfMaxFields = {
  __typename?: 'loan_application_header_pdf_max_fields';
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ไฟล์ */
  pdf_file?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "loan.application_header_pdf" */
export type LoanApplicationHeaderPdfMaxOrderBy = {
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ไฟล์ */
  pdf_file?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanApplicationHeaderPdfMinFields = {
  __typename?: 'loan_application_header_pdf_min_fields';
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ไฟล์ */
  pdf_file?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "loan.application_header_pdf" */
export type LoanApplicationHeaderPdfMinOrderBy = {
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ไฟล์ */
  pdf_file?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan.application_header_pdf" */
export type LoanApplicationHeaderPdfMutationResponse = {
  __typename?: 'loan_application_header_pdf_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanApplicationHeaderPdf>;
};

/** input type for inserting object relation for remote table "loan.application_header_pdf" */
export type LoanApplicationHeaderPdfObjRelInsertInput = {
  data: LoanApplicationHeaderPdfInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanApplicationHeaderPdfOnConflict>;
};

/** on conflict condition type for table "loan.application_header_pdf" */
export type LoanApplicationHeaderPdfOnConflict = {
  constraint: LoanApplicationHeaderPdfConstraint;
  update_columns?: Array<LoanApplicationHeaderPdfUpdateColumn>;
  where?: Maybe<LoanApplicationHeaderPdfBoolExp>;
};

/** Ordering options when selecting data from "loan.application_header_pdf". */
export type LoanApplicationHeaderPdfOrderBy = {
  application_header?: Maybe<LoanApplicationHeaderOrderBy>;
  id?: Maybe<OrderBy>;
  pdf_file?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_application_header_pdf */
export type LoanApplicationHeaderPdfPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "loan.application_header_pdf" */
export enum LoanApplicationHeaderPdfSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  PdfFile = 'pdf_file'
}

/** input type for updating data in table "loan.application_header_pdf" */
export type LoanApplicationHeaderPdfSetInput = {
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ไฟล์ */
  pdf_file?: Maybe<Scalars['String']>;
};

/** update columns of table "loan.application_header_pdf" */
export enum LoanApplicationHeaderPdfUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  PdfFile = 'pdf_file'
}

/** primary key columns input for table: loan_application_header */
export type LoanApplicationHeaderPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "loan.application_header" */
export enum LoanApplicationHeaderSelectColumn {
  /** column name */
  BankApproveComment = 'bank_approve_comment',
  /** column name */
  BankApproveCredit = 'bank_approve_credit',
  /** column name */
  BankApproveDate = 'bank_approve_date',
  /** column name */
  BankApproveValue = 'bank_approve_value',
  /** column name */
  BankBranchId = 'bank_branch_id',
  /** column name */
  BankBranchName = 'bank_branch_name',
  /** column name */
  BankBranchProvince = 'bank_branch_province',
  /** column name */
  BankEffectiveDate = 'bank_effective_date',
  /** column name */
  BusinessGroupDescription = 'business_group_description',
  /** column name */
  BusinessGroupId = 'business_group_id',
  /** column name */
  BusinessProprietaryDescription = 'business_proprietary_description',
  /** column name */
  BusinessProprietaryElse = 'business_proprietary_else',
  /** column name */
  BusinessProprietaryId = 'business_proprietary_id',
  /** column name */
  BusinessStatusDescription = 'business_status_description',
  /** column name */
  BusinessStatusId = 'business_status_id',
  /** column name */
  BusinessTypeDescription = 'business_type_description',
  /** column name */
  BusinessTypeId = 'business_type_id',
  /** column name */
  CommitteeApproveComment = 'committee_approve_comment',
  /** column name */
  CommitteeApproveCredit = 'committee_approve_credit',
  /** column name */
  CommitteeApproveDate = 'committee_approve_date',
  /** column name */
  CommitteeApproveValue = 'committee_approve_value',
  /** column name */
  CompletedDate = 'completed_date',
  /** column name */
  ContactEmail = 'contact_email',
  /** column name */
  ContactFirstName = 'contact_first_name',
  /** column name */
  ContactLastName = 'contact_last_name',
  /** column name */
  ContactPosition = 'contact_position',
  /** column name */
  ContactTel = 'contact_tel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DocumentApproveComment = 'document_approve_comment',
  /** column name */
  DocumentApproveDate = 'document_approve_date',
  /** column name */
  DocumentApproveValue = 'document_approve_value',
  /** column name */
  Email = 'email',
  /** column name */
  ExpectCreditTotal = 'expect_credit_total',
  /** column name */
  Id = 'id',
  /** column name */
  JuristicId = 'juristic_id',
  /** column name */
  Name = 'name',
  /** column name */
  OfficeAddressAmphoe = 'office_address_amphoe',
  /** column name */
  OfficeAddressDistrict = 'office_address_district',
  /** column name */
  OfficeAddressProvince = 'office_address_province',
  /** column name */
  OfficeAddressStreet = 'office_address_street',
  /** column name */
  OfficeAddressZipcode = 'office_address_zipcode',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OrganizationName = 'organization_name',
  /** column name */
  OrganizationReference = 'organization_reference',
  /** column name */
  OrganizationShortName = 'organization_short_name',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  ProjectValueTotal = 'project_value_total',
  /** column name */
  Reference = 'reference',
  /** column name */
  SelfSpendTotal = 'self_spend_total',
  /** column name */
  SmeSignApproveDate = 'sme_sign_approve_date',
  /** column name */
  SmeSignDate = 'sme_sign_date',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  StepId = 'step_id',
  /** column name */
  SubmittedDate = 'submitted_date',
  /** column name */
  Tel = 'tel',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WorkingAddressAmphoe = 'working_address_amphoe',
  /** column name */
  WorkingAddressDistrict = 'working_address_district',
  /** column name */
  WorkingAddressIsOfficeAddress = 'working_address_is_office_address',
  /** column name */
  WorkingAddressProvince = 'working_address_province',
  /** column name */
  WorkingAddressStreet = 'working_address_street',
  /** column name */
  WorkingAddressZipcode = 'working_address_zipcode'
}

/** input type for updating data in table "loan.application_header" */
export type LoanApplicationHeaderSetInput = {
  /** หมายเหตุ ขั้นตอนพิจารณาจากธนาคาร */
  bank_approve_comment?: Maybe<Scalars['String']>;
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<Scalars['numeric']>;
  /** วันที่บันทึกผลพิจารณาและอนุมัติจากธนาคาร */
  bank_approve_date?: Maybe<Scalars['date']>;
  /** ผลพิจารณาและอนุมัติจากธนาคาร */
  bank_approve_value?: Maybe<LoanBankApproveEnum>;
  /** รหัสธนาคาร */
  bank_branch_id?: Maybe<Scalars['String']>;
  /** ชื่อธนาคาร */
  bank_branch_name?: Maybe<Scalars['String']>;
  /** จังหวัดธนาคาร */
  bank_branch_province?: Maybe<Scalars['String']>;
  /** วันที่ทำสัญญากับธนาคาร */
  bank_effective_date?: Maybe<Scalars['date']>;
  /** freeze data ประเภทธุรกิจ */
  business_group_description?: Maybe<Scalars['String']>;
  /** กลุ่มธุรกิจ */
  business_group_id?: Maybe<Scalars['uuid']>;
  /** ลักษณะสถานประกอบการ */
  business_proprietary_description?: Maybe<Scalars['String']>;
  /** ลักษณะสถานประกอบการ อื่น ๆ ระบุ */
  business_proprietary_else?: Maybe<Scalars['String']>;
  /** ลักษณะสถานประกอบการ */
  business_proprietary_id?: Maybe<Scalars['uuid']>;
  /** สถานะภาพการดำเนินธุรกิจ */
  business_status_description?: Maybe<Scalars['String']>;
  /** สถานะภาพการดำเนินธุรกิจ */
  business_status_id?: Maybe<Scalars['uuid']>;
  /** freeze data ประเภทธุรกิจ */
  business_type_description?: Maybe<Scalars['String']>;
  /** ประเภทธุรกิจ */
  business_type_id?: Maybe<Scalars['String']>;
  /** หมายเหตุ ขั้นตอนตรวจพิจารณากลั่นกรอง */
  committee_approve_comment?: Maybe<Scalars['String']>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<Scalars['numeric']>;
  /** วันที่ พิจารณาคัดสรร กลั่นกรอง เสร็จ */
  committee_approve_date?: Maybe<Scalars['timestamptz']>;
  /** พิจารณาคัดสรร กลั่นกรองคุณสมบัติ */
  committee_approve_value?: Maybe<LoanCommitteeApproveEnum>;
  /** วันที่ ลงนาม ประทับตรา */
  completed_date?: Maybe<Scalars['timestamptz']>;
  /** ผู้ติดต่อ อีเมล */
  contact_email?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ ชื่อ */
  contact_first_name?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ นามสกุล */
  contact_last_name?: Maybe<Scalars['String']>;
  /** ตำแหน่งผู้ติดต่อ */
  contact_position?: Maybe<Scalars['String']>;
  /** ผู้ติดต่อ เบอร์โทรศัพท์ */
  contact_tel?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** หมายเหตุ ขั้นตอนตรวจสอบเอกสารเบื้องต้น */
  document_approve_comment?: Maybe<Scalars['String']>;
  /** วันที่ ตรวจสอบคุณสมบัติเบื้องต้น เสร็จ */
  document_approve_date?: Maybe<Scalars['timestamptz']>;
  /** ตรวจสอบเอกสาร คุณสมบัติเบื้องต้น */
  document_approve_value?: Maybe<LoanDocumentApproveEnum>;
  /** E-mail */
  email?: Maybe<Scalars['String']>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total?: Maybe<Scalars['numeric']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** เลขทะเบียนนิติบุคคล */
  juristic_id?: Maybe<Scalars['String']>;
  /** บจก./หจก./หสน. */
  name?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_amphoe?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_district?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_province?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_street?: Maybe<Scalars['String']>;
  /** ที่ตั้งสถานประกอบการ */
  office_address_zipcode?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิงหน่วยงาน */
  organization_id?: Maybe<Scalars['uuid']>;
  /** ชื่อหน่วยงาน / ศูนย์ภาค (ศภ.) */
  organization_name?: Maybe<Scalars['String']>;
  /** รหัส หน่วยงาน / ศูนย์ภาค (ศภ.) */
  organization_reference?: Maybe<Scalars['String']>;
  /** ชื่อย่อหน่วยงาน / ศูนย์ภาค (ศภ.) */
  organization_short_name?: Maybe<Scalars['String']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** มูลโครงการทั้งหมด */
  project_value_total?: Maybe<Scalars['numeric']>;
  /** รหัสใบคำขอสินเชื่อ */
  reference?: Maybe<Scalars['String']>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total?: Maybe<Scalars['numeric']>;
  /** วันที่บันทึกผลการยื่นเอกสารฉบับจริง */
  sme_sign_approve_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่ยื่นเอกสารฉบับจริง */
  sme_sign_date?: Maybe<Scalars['date']>;
  /** วันที่เริ่มทำธุรกิจ */
  start_date?: Maybe<Scalars['date']>;
  /** ขั้นตอนของฟอร์ม */
  step_id?: Maybe<Scalars['String']>;
  /** วันที่ submit ข้อมูลเข้าระบบ */
  submitted_date?: Maybe<Scalars['timestamptz']>;
  /** โทรศัพท์ */
  tel?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<Scalars['uuid']>;
  /** อำเภอ ที่ทำงานปัจจุบัน */
  working_address_amphoe?: Maybe<Scalars['String']>;
  /** ตำบล ที่ทำงานปัจจุบัน */
  working_address_district?: Maybe<Scalars['String']>;
  /** ที่เดียวกับที่ทำงาน */
  working_address_is_office_address?: Maybe<Scalars['Boolean']>;
  /** จังหวัด ที่ทำงานปัจจุบัน */
  working_address_province?: Maybe<Scalars['String']>;
  /** ที่อยู่ ที่ทำงานปัจจุบัน */
  working_address_street?: Maybe<Scalars['String']>;
  /** รหัสไปรษณีย์ ที่ทำงานปัจจุบัน */
  working_address_zipcode?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type LoanApplicationHeaderStddevFields = {
  __typename?: 'loan_application_header_stddev_fields';
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<Scalars['Float']>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<Scalars['Float']>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total?: Maybe<Scalars['Float']>;
  /** มูลโครงการทั้งหมด */
  project_value_total?: Maybe<Scalars['Float']>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "loan.application_header" */
export type LoanApplicationHeaderStddevOrderBy = {
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<OrderBy>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<OrderBy>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total?: Maybe<OrderBy>;
  /** มูลโครงการทั้งหมด */
  project_value_total?: Maybe<OrderBy>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LoanApplicationHeaderStddevPopFields = {
  __typename?: 'loan_application_header_stddev_pop_fields';
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<Scalars['Float']>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<Scalars['Float']>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total?: Maybe<Scalars['Float']>;
  /** มูลโครงการทั้งหมด */
  project_value_total?: Maybe<Scalars['Float']>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "loan.application_header" */
export type LoanApplicationHeaderStddevPopOrderBy = {
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<OrderBy>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<OrderBy>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total?: Maybe<OrderBy>;
  /** มูลโครงการทั้งหมด */
  project_value_total?: Maybe<OrderBy>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LoanApplicationHeaderStddevSampFields = {
  __typename?: 'loan_application_header_stddev_samp_fields';
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<Scalars['Float']>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<Scalars['Float']>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total?: Maybe<Scalars['Float']>;
  /** มูลโครงการทั้งหมด */
  project_value_total?: Maybe<Scalars['Float']>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "loan.application_header" */
export type LoanApplicationHeaderStddevSampOrderBy = {
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<OrderBy>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<OrderBy>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total?: Maybe<OrderBy>;
  /** มูลโครงการทั้งหมด */
  project_value_total?: Maybe<OrderBy>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LoanApplicationHeaderSumFields = {
  __typename?: 'loan_application_header_sum_fields';
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<Scalars['numeric']>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<Scalars['numeric']>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total?: Maybe<Scalars['numeric']>;
  /** มูลโครงการทั้งหมด */
  project_value_total?: Maybe<Scalars['numeric']>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "loan.application_header" */
export type LoanApplicationHeaderSumOrderBy = {
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<OrderBy>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<OrderBy>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total?: Maybe<OrderBy>;
  /** มูลโครงการทั้งหมด */
  project_value_total?: Maybe<OrderBy>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total?: Maybe<OrderBy>;
};

/** update columns of table "loan.application_header" */
export enum LoanApplicationHeaderUpdateColumn {
  /** column name */
  BankApproveComment = 'bank_approve_comment',
  /** column name */
  BankApproveCredit = 'bank_approve_credit',
  /** column name */
  BankApproveDate = 'bank_approve_date',
  /** column name */
  BankApproveValue = 'bank_approve_value',
  /** column name */
  BankBranchId = 'bank_branch_id',
  /** column name */
  BankBranchName = 'bank_branch_name',
  /** column name */
  BankBranchProvince = 'bank_branch_province',
  /** column name */
  BankEffectiveDate = 'bank_effective_date',
  /** column name */
  BusinessGroupDescription = 'business_group_description',
  /** column name */
  BusinessGroupId = 'business_group_id',
  /** column name */
  BusinessProprietaryDescription = 'business_proprietary_description',
  /** column name */
  BusinessProprietaryElse = 'business_proprietary_else',
  /** column name */
  BusinessProprietaryId = 'business_proprietary_id',
  /** column name */
  BusinessStatusDescription = 'business_status_description',
  /** column name */
  BusinessStatusId = 'business_status_id',
  /** column name */
  BusinessTypeDescription = 'business_type_description',
  /** column name */
  BusinessTypeId = 'business_type_id',
  /** column name */
  CommitteeApproveComment = 'committee_approve_comment',
  /** column name */
  CommitteeApproveCredit = 'committee_approve_credit',
  /** column name */
  CommitteeApproveDate = 'committee_approve_date',
  /** column name */
  CommitteeApproveValue = 'committee_approve_value',
  /** column name */
  CompletedDate = 'completed_date',
  /** column name */
  ContactEmail = 'contact_email',
  /** column name */
  ContactFirstName = 'contact_first_name',
  /** column name */
  ContactLastName = 'contact_last_name',
  /** column name */
  ContactPosition = 'contact_position',
  /** column name */
  ContactTel = 'contact_tel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DocumentApproveComment = 'document_approve_comment',
  /** column name */
  DocumentApproveDate = 'document_approve_date',
  /** column name */
  DocumentApproveValue = 'document_approve_value',
  /** column name */
  Email = 'email',
  /** column name */
  ExpectCreditTotal = 'expect_credit_total',
  /** column name */
  Id = 'id',
  /** column name */
  JuristicId = 'juristic_id',
  /** column name */
  Name = 'name',
  /** column name */
  OfficeAddressAmphoe = 'office_address_amphoe',
  /** column name */
  OfficeAddressDistrict = 'office_address_district',
  /** column name */
  OfficeAddressProvince = 'office_address_province',
  /** column name */
  OfficeAddressStreet = 'office_address_street',
  /** column name */
  OfficeAddressZipcode = 'office_address_zipcode',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OrganizationName = 'organization_name',
  /** column name */
  OrganizationReference = 'organization_reference',
  /** column name */
  OrganizationShortName = 'organization_short_name',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  ProjectValueTotal = 'project_value_total',
  /** column name */
  Reference = 'reference',
  /** column name */
  SelfSpendTotal = 'self_spend_total',
  /** column name */
  SmeSignApproveDate = 'sme_sign_approve_date',
  /** column name */
  SmeSignDate = 'sme_sign_date',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  StepId = 'step_id',
  /** column name */
  SubmittedDate = 'submitted_date',
  /** column name */
  Tel = 'tel',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WorkingAddressAmphoe = 'working_address_amphoe',
  /** column name */
  WorkingAddressDistrict = 'working_address_district',
  /** column name */
  WorkingAddressIsOfficeAddress = 'working_address_is_office_address',
  /** column name */
  WorkingAddressProvince = 'working_address_province',
  /** column name */
  WorkingAddressStreet = 'working_address_street',
  /** column name */
  WorkingAddressZipcode = 'working_address_zipcode'
}

/** aggregate var_pop on columns */
export type LoanApplicationHeaderVarPopFields = {
  __typename?: 'loan_application_header_var_pop_fields';
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<Scalars['Float']>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<Scalars['Float']>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total?: Maybe<Scalars['Float']>;
  /** มูลโครงการทั้งหมด */
  project_value_total?: Maybe<Scalars['Float']>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "loan.application_header" */
export type LoanApplicationHeaderVarPopOrderBy = {
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<OrderBy>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<OrderBy>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total?: Maybe<OrderBy>;
  /** มูลโครงการทั้งหมด */
  project_value_total?: Maybe<OrderBy>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LoanApplicationHeaderVarSampFields = {
  __typename?: 'loan_application_header_var_samp_fields';
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<Scalars['Float']>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<Scalars['Float']>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total?: Maybe<Scalars['Float']>;
  /** มูลโครงการทั้งหมด */
  project_value_total?: Maybe<Scalars['Float']>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "loan.application_header" */
export type LoanApplicationHeaderVarSampOrderBy = {
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<OrderBy>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<OrderBy>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total?: Maybe<OrderBy>;
  /** มูลโครงการทั้งหมด */
  project_value_total?: Maybe<OrderBy>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LoanApplicationHeaderVarianceFields = {
  __typename?: 'loan_application_header_variance_fields';
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<Scalars['Float']>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<Scalars['Float']>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total?: Maybe<Scalars['Float']>;
  /** มูลโครงการทั้งหมด */
  project_value_total?: Maybe<Scalars['Float']>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "loan.application_header" */
export type LoanApplicationHeaderVarianceOrderBy = {
  /** จำนวนวงเงินอนุมัติโดยธนาคาร */
  bank_approve_credit?: Maybe<OrderBy>;
  /** จำนวนวงเงินอนุมัติโดยอนุกรรมการ */
  committee_approve_credit?: Maybe<OrderBy>;
  /** จำนวนวงเงินที่ขอสินเชื่อ */
  expect_credit_total?: Maybe<OrderBy>;
  /** มูลโครงการทั้งหมด */
  project_value_total?: Maybe<OrderBy>;
  /** ส่วนออกเอง จากมูลค่าโครงการทั้งหมด */
  self_spend_total?: Maybe<OrderBy>;
};

/**
 * วัตถุประสงค์และวงเงินที่ขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_objective"
 */
export type LoanApplicationObjective = {
  __typename?: 'loan_application_objective';
  /** An object relationship */
  application_header: LoanApplicationHeader;
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_header_id: Scalars['uuid'];
  /** true = เลือก, false = ไม่เลือก */
  checked?: Maybe<Scalars['Boolean']>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** (ระบุ) */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** อื่น ๆ */
  need_addition: Scalars['Boolean'];
  /** วงเงินขอสินเชื่อ */
  need_credit: Scalars['numeric'];
  /** An object relationship */
  objective: LoanObjective;
  /** วัตถุประสงค์ */
  objective_id: Scalars['String'];
  /** มูลค่าโครงการ */
  project_value: Scalars['numeric'];
  /** ส่วนออกเอง */
  self_spend: Scalars['numeric'];
  /** ลำดับที่ */
  sequence: Scalars['numeric'];
  /** วัตถุประสงค์ */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregated selection of "loan.application_objective" */
export type LoanApplicationObjectiveAggregate = {
  __typename?: 'loan_application_objective_aggregate';
  aggregate?: Maybe<LoanApplicationObjectiveAggregateFields>;
  nodes: Array<LoanApplicationObjective>;
};

/** aggregate fields of "loan.application_objective" */
export type LoanApplicationObjectiveAggregateFields = {
  __typename?: 'loan_application_objective_aggregate_fields';
  avg?: Maybe<LoanApplicationObjectiveAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanApplicationObjectiveMaxFields>;
  min?: Maybe<LoanApplicationObjectiveMinFields>;
  stddev?: Maybe<LoanApplicationObjectiveStddevFields>;
  stddev_pop?: Maybe<LoanApplicationObjectiveStddevPopFields>;
  stddev_samp?: Maybe<LoanApplicationObjectiveStddevSampFields>;
  sum?: Maybe<LoanApplicationObjectiveSumFields>;
  var_pop?: Maybe<LoanApplicationObjectiveVarPopFields>;
  var_samp?: Maybe<LoanApplicationObjectiveVarSampFields>;
  variance?: Maybe<LoanApplicationObjectiveVarianceFields>;
};


/** aggregate fields of "loan.application_objective" */
export type LoanApplicationObjectiveAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanApplicationObjectiveSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan.application_objective" */
export type LoanApplicationObjectiveAggregateOrderBy = {
  avg?: Maybe<LoanApplicationObjectiveAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanApplicationObjectiveMaxOrderBy>;
  min?: Maybe<LoanApplicationObjectiveMinOrderBy>;
  stddev?: Maybe<LoanApplicationObjectiveStddevOrderBy>;
  stddev_pop?: Maybe<LoanApplicationObjectiveStddevPopOrderBy>;
  stddev_samp?: Maybe<LoanApplicationObjectiveStddevSampOrderBy>;
  sum?: Maybe<LoanApplicationObjectiveSumOrderBy>;
  var_pop?: Maybe<LoanApplicationObjectiveVarPopOrderBy>;
  var_samp?: Maybe<LoanApplicationObjectiveVarSampOrderBy>;
  variance?: Maybe<LoanApplicationObjectiveVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "loan.application_objective" */
export type LoanApplicationObjectiveArrRelInsertInput = {
  data: Array<LoanApplicationObjectiveInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanApplicationObjectiveOnConflict>;
};

/** aggregate avg on columns */
export type LoanApplicationObjectiveAvgFields = {
  __typename?: 'loan_application_objective_avg_fields';
  /** วงเงินขอสินเชื่อ */
  need_credit?: Maybe<Scalars['Float']>;
  /** มูลค่าโครงการ */
  project_value?: Maybe<Scalars['Float']>;
  /** ส่วนออกเอง */
  self_spend?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "loan.application_objective" */
export type LoanApplicationObjectiveAvgOrderBy = {
  /** วงเงินขอสินเชื่อ */
  need_credit?: Maybe<OrderBy>;
  /** มูลค่าโครงการ */
  project_value?: Maybe<OrderBy>;
  /** ส่วนออกเอง */
  self_spend?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "loan.application_objective". All fields are combined with a logical 'AND'. */
export type LoanApplicationObjectiveBoolExp = {
  _and?: Maybe<Array<LoanApplicationObjectiveBoolExp>>;
  _not?: Maybe<LoanApplicationObjectiveBoolExp>;
  _or?: Maybe<Array<LoanApplicationObjectiveBoolExp>>;
  application_header?: Maybe<LoanApplicationHeaderBoolExp>;
  application_header_id?: Maybe<UuidComparisonExp>;
  checked?: Maybe<BooleanComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<StringComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  need_addition?: Maybe<BooleanComparisonExp>;
  need_credit?: Maybe<NumericComparisonExp>;
  objective?: Maybe<LoanObjectiveBoolExp>;
  objective_id?: Maybe<StringComparisonExp>;
  project_value?: Maybe<NumericComparisonExp>;
  self_spend?: Maybe<NumericComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
  title?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "loan.application_objective" */
export enum LoanApplicationObjectiveConstraint {
  /** unique or primary key constraint */
  ApplicationObjectivePkey = 'application_objective_pkey'
}

/** input type for incrementing numeric columns in table "loan.application_objective" */
export type LoanApplicationObjectiveIncInput = {
  /** วงเงินขอสินเชื่อ */
  need_credit?: Maybe<Scalars['numeric']>;
  /** มูลค่าโครงการ */
  project_value?: Maybe<Scalars['numeric']>;
  /** ส่วนออกเอง */
  self_spend?: Maybe<Scalars['numeric']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.application_objective" */
export type LoanApplicationObjectiveInsertInput = {
  application_header?: Maybe<LoanApplicationHeaderObjRelInsertInput>;
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_header_id?: Maybe<Scalars['uuid']>;
  /** true = เลือก, false = ไม่เลือก */
  checked?: Maybe<Scalars['Boolean']>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** (ระบุ) */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** อื่น ๆ */
  need_addition?: Maybe<Scalars['Boolean']>;
  /** วงเงินขอสินเชื่อ */
  need_credit?: Maybe<Scalars['numeric']>;
  objective?: Maybe<LoanObjectiveObjRelInsertInput>;
  /** วัตถุประสงค์ */
  objective_id?: Maybe<Scalars['String']>;
  /** มูลค่าโครงการ */
  project_value?: Maybe<Scalars['numeric']>;
  /** ส่วนออกเอง */
  self_spend?: Maybe<Scalars['numeric']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** วัตถุประสงค์ */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LoanApplicationObjectiveMaxFields = {
  __typename?: 'loan_application_objective_max_fields';
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_header_id?: Maybe<Scalars['uuid']>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** (ระบุ) */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** วงเงินขอสินเชื่อ */
  need_credit?: Maybe<Scalars['numeric']>;
  /** วัตถุประสงค์ */
  objective_id?: Maybe<Scalars['String']>;
  /** มูลค่าโครงการ */
  project_value?: Maybe<Scalars['numeric']>;
  /** ส่วนออกเอง */
  self_spend?: Maybe<Scalars['numeric']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** วัตถุประสงค์ */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "loan.application_objective" */
export type LoanApplicationObjectiveMaxOrderBy = {
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_header_id?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** (ระบุ) */
  description?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** วงเงินขอสินเชื่อ */
  need_credit?: Maybe<OrderBy>;
  /** วัตถุประสงค์ */
  objective_id?: Maybe<OrderBy>;
  /** มูลค่าโครงการ */
  project_value?: Maybe<OrderBy>;
  /** ส่วนออกเอง */
  self_spend?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** วัตถุประสงค์ */
  title?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanApplicationObjectiveMinFields = {
  __typename?: 'loan_application_objective_min_fields';
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_header_id?: Maybe<Scalars['uuid']>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** (ระบุ) */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** วงเงินขอสินเชื่อ */
  need_credit?: Maybe<Scalars['numeric']>;
  /** วัตถุประสงค์ */
  objective_id?: Maybe<Scalars['String']>;
  /** มูลค่าโครงการ */
  project_value?: Maybe<Scalars['numeric']>;
  /** ส่วนออกเอง */
  self_spend?: Maybe<Scalars['numeric']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** วัตถุประสงค์ */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "loan.application_objective" */
export type LoanApplicationObjectiveMinOrderBy = {
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_header_id?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** (ระบุ) */
  description?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** วงเงินขอสินเชื่อ */
  need_credit?: Maybe<OrderBy>;
  /** วัตถุประสงค์ */
  objective_id?: Maybe<OrderBy>;
  /** มูลค่าโครงการ */
  project_value?: Maybe<OrderBy>;
  /** ส่วนออกเอง */
  self_spend?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** วัตถุประสงค์ */
  title?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan.application_objective" */
export type LoanApplicationObjectiveMutationResponse = {
  __typename?: 'loan_application_objective_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanApplicationObjective>;
};

/** on conflict condition type for table "loan.application_objective" */
export type LoanApplicationObjectiveOnConflict = {
  constraint: LoanApplicationObjectiveConstraint;
  update_columns?: Array<LoanApplicationObjectiveUpdateColumn>;
  where?: Maybe<LoanApplicationObjectiveBoolExp>;
};

/** Ordering options when selecting data from "loan.application_objective". */
export type LoanApplicationObjectiveOrderBy = {
  application_header?: Maybe<LoanApplicationHeaderOrderBy>;
  application_header_id?: Maybe<OrderBy>;
  checked?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  need_addition?: Maybe<OrderBy>;
  need_credit?: Maybe<OrderBy>;
  objective?: Maybe<LoanObjectiveOrderBy>;
  objective_id?: Maybe<OrderBy>;
  project_value?: Maybe<OrderBy>;
  self_spend?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_application_objective */
export type LoanApplicationObjectivePkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "loan.application_objective" */
export enum LoanApplicationObjectiveSelectColumn {
  /** column name */
  ApplicationHeaderId = 'application_header_id',
  /** column name */
  Checked = 'checked',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  NeedAddition = 'need_addition',
  /** column name */
  NeedCredit = 'need_credit',
  /** column name */
  ObjectiveId = 'objective_id',
  /** column name */
  ProjectValue = 'project_value',
  /** column name */
  SelfSpend = 'self_spend',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "loan.application_objective" */
export type LoanApplicationObjectiveSetInput = {
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_header_id?: Maybe<Scalars['uuid']>;
  /** true = เลือก, false = ไม่เลือก */
  checked?: Maybe<Scalars['Boolean']>;
  /** วันที่บันทึกข้อมมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** (ระบุ) */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** อื่น ๆ */
  need_addition?: Maybe<Scalars['Boolean']>;
  /** วงเงินขอสินเชื่อ */
  need_credit?: Maybe<Scalars['numeric']>;
  /** วัตถุประสงค์ */
  objective_id?: Maybe<Scalars['String']>;
  /** มูลค่าโครงการ */
  project_value?: Maybe<Scalars['numeric']>;
  /** ส่วนออกเอง */
  self_spend?: Maybe<Scalars['numeric']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** วัตถุประสงค์ */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type LoanApplicationObjectiveStddevFields = {
  __typename?: 'loan_application_objective_stddev_fields';
  /** วงเงินขอสินเชื่อ */
  need_credit?: Maybe<Scalars['Float']>;
  /** มูลค่าโครงการ */
  project_value?: Maybe<Scalars['Float']>;
  /** ส่วนออกเอง */
  self_spend?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "loan.application_objective" */
export type LoanApplicationObjectiveStddevOrderBy = {
  /** วงเงินขอสินเชื่อ */
  need_credit?: Maybe<OrderBy>;
  /** มูลค่าโครงการ */
  project_value?: Maybe<OrderBy>;
  /** ส่วนออกเอง */
  self_spend?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LoanApplicationObjectiveStddevPopFields = {
  __typename?: 'loan_application_objective_stddev_pop_fields';
  /** วงเงินขอสินเชื่อ */
  need_credit?: Maybe<Scalars['Float']>;
  /** มูลค่าโครงการ */
  project_value?: Maybe<Scalars['Float']>;
  /** ส่วนออกเอง */
  self_spend?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "loan.application_objective" */
export type LoanApplicationObjectiveStddevPopOrderBy = {
  /** วงเงินขอสินเชื่อ */
  need_credit?: Maybe<OrderBy>;
  /** มูลค่าโครงการ */
  project_value?: Maybe<OrderBy>;
  /** ส่วนออกเอง */
  self_spend?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LoanApplicationObjectiveStddevSampFields = {
  __typename?: 'loan_application_objective_stddev_samp_fields';
  /** วงเงินขอสินเชื่อ */
  need_credit?: Maybe<Scalars['Float']>;
  /** มูลค่าโครงการ */
  project_value?: Maybe<Scalars['Float']>;
  /** ส่วนออกเอง */
  self_spend?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "loan.application_objective" */
export type LoanApplicationObjectiveStddevSampOrderBy = {
  /** วงเงินขอสินเชื่อ */
  need_credit?: Maybe<OrderBy>;
  /** มูลค่าโครงการ */
  project_value?: Maybe<OrderBy>;
  /** ส่วนออกเอง */
  self_spend?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LoanApplicationObjectiveSumFields = {
  __typename?: 'loan_application_objective_sum_fields';
  /** วงเงินขอสินเชื่อ */
  need_credit?: Maybe<Scalars['numeric']>;
  /** มูลค่าโครงการ */
  project_value?: Maybe<Scalars['numeric']>;
  /** ส่วนออกเอง */
  self_spend?: Maybe<Scalars['numeric']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "loan.application_objective" */
export type LoanApplicationObjectiveSumOrderBy = {
  /** วงเงินขอสินเชื่อ */
  need_credit?: Maybe<OrderBy>;
  /** มูลค่าโครงการ */
  project_value?: Maybe<OrderBy>;
  /** ส่วนออกเอง */
  self_spend?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "loan.application_objective" */
export enum LoanApplicationObjectiveUpdateColumn {
  /** column name */
  ApplicationHeaderId = 'application_header_id',
  /** column name */
  Checked = 'checked',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  NeedAddition = 'need_addition',
  /** column name */
  NeedCredit = 'need_credit',
  /** column name */
  ObjectiveId = 'objective_id',
  /** column name */
  ProjectValue = 'project_value',
  /** column name */
  SelfSpend = 'self_spend',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type LoanApplicationObjectiveVarPopFields = {
  __typename?: 'loan_application_objective_var_pop_fields';
  /** วงเงินขอสินเชื่อ */
  need_credit?: Maybe<Scalars['Float']>;
  /** มูลค่าโครงการ */
  project_value?: Maybe<Scalars['Float']>;
  /** ส่วนออกเอง */
  self_spend?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "loan.application_objective" */
export type LoanApplicationObjectiveVarPopOrderBy = {
  /** วงเงินขอสินเชื่อ */
  need_credit?: Maybe<OrderBy>;
  /** มูลค่าโครงการ */
  project_value?: Maybe<OrderBy>;
  /** ส่วนออกเอง */
  self_spend?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LoanApplicationObjectiveVarSampFields = {
  __typename?: 'loan_application_objective_var_samp_fields';
  /** วงเงินขอสินเชื่อ */
  need_credit?: Maybe<Scalars['Float']>;
  /** มูลค่าโครงการ */
  project_value?: Maybe<Scalars['Float']>;
  /** ส่วนออกเอง */
  self_spend?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "loan.application_objective" */
export type LoanApplicationObjectiveVarSampOrderBy = {
  /** วงเงินขอสินเชื่อ */
  need_credit?: Maybe<OrderBy>;
  /** มูลค่าโครงการ */
  project_value?: Maybe<OrderBy>;
  /** ส่วนออกเอง */
  self_spend?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LoanApplicationObjectiveVarianceFields = {
  __typename?: 'loan_application_objective_variance_fields';
  /** วงเงินขอสินเชื่อ */
  need_credit?: Maybe<Scalars['Float']>;
  /** มูลค่าโครงการ */
  project_value?: Maybe<Scalars['Float']>;
  /** ส่วนออกเอง */
  self_spend?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "loan.application_objective" */
export type LoanApplicationObjectiveVarianceOrderBy = {
  /** วงเงินขอสินเชื่อ */
  need_credit?: Maybe<OrderBy>;
  /** มูลค่าโครงการ */
  project_value?: Maybe<OrderBy>;
  /** ส่วนออกเอง */
  self_spend?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/**
 * การโอนย้ายใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_organization_transfer"
 */
export type LoanApplicationOrganizationTransfer = {
  __typename?: 'loan_application_organization_transfer';
  /** An object relationship */
  application_header: LoanApplicationHeader;
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_header_id: Scalars['uuid'];
  /** วันที่อนุมัติ */
  approved_at?: Maybe<Scalars['timestamptz']>;
  /** หมายเหตุสำหรับผู้อนุมัติ */
  approved_note?: Maybe<Scalars['String']>;
  /** An object relationship */
  approver?: Maybe<Users>;
  /** รหัสอ้างอิงผู้ที่อนุมัติ */
  approver_id?: Maybe<Scalars['uuid']>;
  /** วันที่บันทึกข้อมูล */
  created_at: Scalars['timestamptz'];
  /** ผู้สร้างใบโอนย้ายใบคำขอ */
  created_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  from_organization: Organization;
  /** จากหน่วยงาน */
  from_organization_id: Scalars['uuid'];
  /** Primary key */
  id: Scalars['uuid'];
  /** รหัสใบคำขอสินเชื่อ (หน่วยงานใหม่) */
  new_reference?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  /** รหัสใบคำขอสินเชื่อ (หน่วยงานเก่า) */
  old_reference: Scalars['String'];
  /** An object relationship */
  status: LoanTransferStatus;
  /** สถานะ */
  status_value: LoanTransferStatusEnum;
  /** An object relationship */
  to_organization: Organization;
  /** ไปยังหน่วยงาน */
  to_organization_id: Scalars['uuid'];
  /** วันที่แก้ไขข้อมูล */
  updated_at: Scalars['timestamptz'];
  /** ผู้แก้ไขใบโอนย้ายใบคำขอ */
  updated_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  userByCreatedBy?: Maybe<Users>;
  /** An object relationship */
  userByUpdatedBy?: Maybe<Users>;
};

/** aggregated selection of "loan.application_organization_transfer" */
export type LoanApplicationOrganizationTransferAggregate = {
  __typename?: 'loan_application_organization_transfer_aggregate';
  aggregate?: Maybe<LoanApplicationOrganizationTransferAggregateFields>;
  nodes: Array<LoanApplicationOrganizationTransfer>;
};

/** aggregate fields of "loan.application_organization_transfer" */
export type LoanApplicationOrganizationTransferAggregateFields = {
  __typename?: 'loan_application_organization_transfer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LoanApplicationOrganizationTransferMaxFields>;
  min?: Maybe<LoanApplicationOrganizationTransferMinFields>;
};


/** aggregate fields of "loan.application_organization_transfer" */
export type LoanApplicationOrganizationTransferAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanApplicationOrganizationTransferSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan.application_organization_transfer" */
export type LoanApplicationOrganizationTransferAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanApplicationOrganizationTransferMaxOrderBy>;
  min?: Maybe<LoanApplicationOrganizationTransferMinOrderBy>;
};

/** input type for inserting array relation for remote table "loan.application_organization_transfer" */
export type LoanApplicationOrganizationTransferArrRelInsertInput = {
  data: Array<LoanApplicationOrganizationTransferInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanApplicationOrganizationTransferOnConflict>;
};

/** Boolean expression to filter rows from the table "loan.application_organization_transfer". All fields are combined with a logical 'AND'. */
export type LoanApplicationOrganizationTransferBoolExp = {
  _and?: Maybe<Array<LoanApplicationOrganizationTransferBoolExp>>;
  _not?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
  _or?: Maybe<Array<LoanApplicationOrganizationTransferBoolExp>>;
  application_header?: Maybe<LoanApplicationHeaderBoolExp>;
  application_header_id?: Maybe<UuidComparisonExp>;
  approved_at?: Maybe<TimestamptzComparisonExp>;
  approved_note?: Maybe<StringComparisonExp>;
  approver?: Maybe<UsersBoolExp>;
  approver_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  from_organization?: Maybe<OrganizationBoolExp>;
  from_organization_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  new_reference?: Maybe<StringComparisonExp>;
  note?: Maybe<StringComparisonExp>;
  old_reference?: Maybe<StringComparisonExp>;
  status?: Maybe<LoanTransferStatusBoolExp>;
  status_value?: Maybe<LoanTransferStatusEnumComparisonExp>;
  to_organization?: Maybe<OrganizationBoolExp>;
  to_organization_id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<UuidComparisonExp>;
  userByCreatedBy?: Maybe<UsersBoolExp>;
  userByUpdatedBy?: Maybe<UsersBoolExp>;
};

/** unique or primary key constraints on table "loan.application_organization_transfer" */
export enum LoanApplicationOrganizationTransferConstraint {
  /** unique or primary key constraint */
  ApplicationTransferPkey = 'application_transfer_pkey'
}

/** input type for inserting data into table "loan.application_organization_transfer" */
export type LoanApplicationOrganizationTransferInsertInput = {
  application_header?: Maybe<LoanApplicationHeaderObjRelInsertInput>;
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_header_id?: Maybe<Scalars['uuid']>;
  /** วันที่อนุมัติ */
  approved_at?: Maybe<Scalars['timestamptz']>;
  /** หมายเหตุสำหรับผู้อนุมัติ */
  approved_note?: Maybe<Scalars['String']>;
  approver?: Maybe<UsersObjRelInsertInput>;
  /** รหัสอ้างอิงผู้ที่อนุมัติ */
  approver_id?: Maybe<Scalars['uuid']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** ผู้สร้างใบโอนย้ายใบคำขอ */
  created_by?: Maybe<Scalars['uuid']>;
  from_organization?: Maybe<OrganizationObjRelInsertInput>;
  /** จากหน่วยงาน */
  from_organization_id?: Maybe<Scalars['uuid']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รหัสใบคำขอสินเชื่อ (หน่วยงานใหม่) */
  new_reference?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  /** รหัสใบคำขอสินเชื่อ (หน่วยงานเก่า) */
  old_reference?: Maybe<Scalars['String']>;
  status?: Maybe<LoanTransferStatusObjRelInsertInput>;
  /** สถานะ */
  status_value?: Maybe<LoanTransferStatusEnum>;
  to_organization?: Maybe<OrganizationObjRelInsertInput>;
  /** ไปยังหน่วยงาน */
  to_organization_id?: Maybe<Scalars['uuid']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ผู้แก้ไขใบโอนย้ายใบคำขอ */
  updated_by?: Maybe<Scalars['uuid']>;
  userByCreatedBy?: Maybe<UsersObjRelInsertInput>;
  userByUpdatedBy?: Maybe<UsersObjRelInsertInput>;
};

/** aggregate max on columns */
export type LoanApplicationOrganizationTransferMaxFields = {
  __typename?: 'loan_application_organization_transfer_max_fields';
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_header_id?: Maybe<Scalars['uuid']>;
  /** วันที่อนุมัติ */
  approved_at?: Maybe<Scalars['timestamptz']>;
  /** หมายเหตุสำหรับผู้อนุมัติ */
  approved_note?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิงผู้ที่อนุมัติ */
  approver_id?: Maybe<Scalars['uuid']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** ผู้สร้างใบโอนย้ายใบคำขอ */
  created_by?: Maybe<Scalars['uuid']>;
  /** จากหน่วยงาน */
  from_organization_id?: Maybe<Scalars['uuid']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รหัสใบคำขอสินเชื่อ (หน่วยงานใหม่) */
  new_reference?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  /** รหัสใบคำขอสินเชื่อ (หน่วยงานเก่า) */
  old_reference?: Maybe<Scalars['String']>;
  /** ไปยังหน่วยงาน */
  to_organization_id?: Maybe<Scalars['uuid']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ผู้แก้ไขใบโอนย้ายใบคำขอ */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "loan.application_organization_transfer" */
export type LoanApplicationOrganizationTransferMaxOrderBy = {
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_header_id?: Maybe<OrderBy>;
  /** วันที่อนุมัติ */
  approved_at?: Maybe<OrderBy>;
  /** หมายเหตุสำหรับผู้อนุมัติ */
  approved_note?: Maybe<OrderBy>;
  /** รหัสอ้างอิงผู้ที่อนุมัติ */
  approver_id?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** ผู้สร้างใบโอนย้ายใบคำขอ */
  created_by?: Maybe<OrderBy>;
  /** จากหน่วยงาน */
  from_organization_id?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** รหัสใบคำขอสินเชื่อ (หน่วยงานใหม่) */
  new_reference?: Maybe<OrderBy>;
  /** หมายเหตุ */
  note?: Maybe<OrderBy>;
  /** รหัสใบคำขอสินเชื่อ (หน่วยงานเก่า) */
  old_reference?: Maybe<OrderBy>;
  /** ไปยังหน่วยงาน */
  to_organization_id?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** ผู้แก้ไขใบโอนย้ายใบคำขอ */
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanApplicationOrganizationTransferMinFields = {
  __typename?: 'loan_application_organization_transfer_min_fields';
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_header_id?: Maybe<Scalars['uuid']>;
  /** วันที่อนุมัติ */
  approved_at?: Maybe<Scalars['timestamptz']>;
  /** หมายเหตุสำหรับผู้อนุมัติ */
  approved_note?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิงผู้ที่อนุมัติ */
  approver_id?: Maybe<Scalars['uuid']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** ผู้สร้างใบโอนย้ายใบคำขอ */
  created_by?: Maybe<Scalars['uuid']>;
  /** จากหน่วยงาน */
  from_organization_id?: Maybe<Scalars['uuid']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รหัสใบคำขอสินเชื่อ (หน่วยงานใหม่) */
  new_reference?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  /** รหัสใบคำขอสินเชื่อ (หน่วยงานเก่า) */
  old_reference?: Maybe<Scalars['String']>;
  /** ไปยังหน่วยงาน */
  to_organization_id?: Maybe<Scalars['uuid']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ผู้แก้ไขใบโอนย้ายใบคำขอ */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "loan.application_organization_transfer" */
export type LoanApplicationOrganizationTransferMinOrderBy = {
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_header_id?: Maybe<OrderBy>;
  /** วันที่อนุมัติ */
  approved_at?: Maybe<OrderBy>;
  /** หมายเหตุสำหรับผู้อนุมัติ */
  approved_note?: Maybe<OrderBy>;
  /** รหัสอ้างอิงผู้ที่อนุมัติ */
  approver_id?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** ผู้สร้างใบโอนย้ายใบคำขอ */
  created_by?: Maybe<OrderBy>;
  /** จากหน่วยงาน */
  from_organization_id?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** รหัสใบคำขอสินเชื่อ (หน่วยงานใหม่) */
  new_reference?: Maybe<OrderBy>;
  /** หมายเหตุ */
  note?: Maybe<OrderBy>;
  /** รหัสใบคำขอสินเชื่อ (หน่วยงานเก่า) */
  old_reference?: Maybe<OrderBy>;
  /** ไปยังหน่วยงาน */
  to_organization_id?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** ผู้แก้ไขใบโอนย้ายใบคำขอ */
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan.application_organization_transfer" */
export type LoanApplicationOrganizationTransferMutationResponse = {
  __typename?: 'loan_application_organization_transfer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanApplicationOrganizationTransfer>;
};

/** on conflict condition type for table "loan.application_organization_transfer" */
export type LoanApplicationOrganizationTransferOnConflict = {
  constraint: LoanApplicationOrganizationTransferConstraint;
  update_columns?: Array<LoanApplicationOrganizationTransferUpdateColumn>;
  where?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
};

/** Ordering options when selecting data from "loan.application_organization_transfer". */
export type LoanApplicationOrganizationTransferOrderBy = {
  application_header?: Maybe<LoanApplicationHeaderOrderBy>;
  application_header_id?: Maybe<OrderBy>;
  approved_at?: Maybe<OrderBy>;
  approved_note?: Maybe<OrderBy>;
  approver?: Maybe<UsersOrderBy>;
  approver_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  from_organization?: Maybe<OrganizationOrderBy>;
  from_organization_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  new_reference?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  old_reference?: Maybe<OrderBy>;
  status?: Maybe<LoanTransferStatusOrderBy>;
  status_value?: Maybe<OrderBy>;
  to_organization?: Maybe<OrganizationOrderBy>;
  to_organization_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
  userByCreatedBy?: Maybe<UsersOrderBy>;
  userByUpdatedBy?: Maybe<UsersOrderBy>;
};

/** primary key columns input for table: loan_application_organization_transfer */
export type LoanApplicationOrganizationTransferPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "loan.application_organization_transfer" */
export enum LoanApplicationOrganizationTransferSelectColumn {
  /** column name */
  ApplicationHeaderId = 'application_header_id',
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  ApprovedNote = 'approved_note',
  /** column name */
  ApproverId = 'approver_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FromOrganizationId = 'from_organization_id',
  /** column name */
  Id = 'id',
  /** column name */
  NewReference = 'new_reference',
  /** column name */
  Note = 'note',
  /** column name */
  OldReference = 'old_reference',
  /** column name */
  StatusValue = 'status_value',
  /** column name */
  ToOrganizationId = 'to_organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "loan.application_organization_transfer" */
export type LoanApplicationOrganizationTransferSetInput = {
  /** รหัสอ้างอิงใบคำขอสินเชื่อ */
  application_header_id?: Maybe<Scalars['uuid']>;
  /** วันที่อนุมัติ */
  approved_at?: Maybe<Scalars['timestamptz']>;
  /** หมายเหตุสำหรับผู้อนุมัติ */
  approved_note?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิงผู้ที่อนุมัติ */
  approver_id?: Maybe<Scalars['uuid']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** ผู้สร้างใบโอนย้ายใบคำขอ */
  created_by?: Maybe<Scalars['uuid']>;
  /** จากหน่วยงาน */
  from_organization_id?: Maybe<Scalars['uuid']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รหัสใบคำขอสินเชื่อ (หน่วยงานใหม่) */
  new_reference?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  note?: Maybe<Scalars['String']>;
  /** รหัสใบคำขอสินเชื่อ (หน่วยงานเก่า) */
  old_reference?: Maybe<Scalars['String']>;
  /** สถานะ */
  status_value?: Maybe<LoanTransferStatusEnum>;
  /** ไปยังหน่วยงาน */
  to_organization_id?: Maybe<Scalars['uuid']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ผู้แก้ไขใบโอนย้ายใบคำขอ */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** update columns of table "loan.application_organization_transfer" */
export enum LoanApplicationOrganizationTransferUpdateColumn {
  /** column name */
  ApplicationHeaderId = 'application_header_id',
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  ApprovedNote = 'approved_note',
  /** column name */
  ApproverId = 'approver_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FromOrganizationId = 'from_organization_id',
  /** column name */
  Id = 'id',
  /** column name */
  NewReference = 'new_reference',
  /** column name */
  Note = 'note',
  /** column name */
  OldReference = 'old_reference',
  /** column name */
  StatusValue = 'status_value',
  /** column name */
  ToOrganizationId = 'to_organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/**
 * ประวัติผู้ขอกู้/ร่วมลงทุน และข้อมูลธุรกิจ
 *
 *
 * columns and relationships of "loan.application_profile"
 */
export type LoanApplicationProfile = {
  __typename?: 'loan_application_profile';
  /** An object relationship */
  application_header: LoanApplicationHeader;
  /** ประวัติผู้ขอกู้/ร่วมลงทุน และข้อมูลธุรกิจ */
  company_history?: Maybe<Scalars['String']>;
  /** ประเภทผลิตภัณฑ์/บริการ */
  company_product?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** ลูกค้าปัจจุบัน */
  current_customer?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ช่องทางการจัดจำหน่าย */
  distribution?: Maybe<Scalars['String']>;
  /** อื่น ๆ(ระบุ) */
  else_usage?: Maybe<Scalars['String']>;
  /** ประวัติของผู้บริหารหลักของกิจการ */
  executive_history?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** การใช้นวัตกรรม(ระบุ) */
  innovation_usage?: Maybe<Scalars['String']>;
  /** รายชื่อลูกค้ารายใหญ่ */
  major_customer?: Maybe<Scalars['jsonb']>;
  /** รายชื่อผู้ขายวัตถุดิบรายใหญ่ */
  major_supplier?: Maybe<Scalars['jsonb']>;
  /** จุดเด่นของธุรกิจที่ขอสินเชื่อ/ร่วมลงทุน */
  prominent_point?: Maybe<Scalars['jsonb']>;
  /** ลูกค้าเป้าหมาย */
  target_group?: Maybe<Scalars['String']>;
  /** การใช้เทคโนโลยี(ระบุ) */
  technology_usage?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};


/**
 * ประวัติผู้ขอกู้/ร่วมลงทุน และข้อมูลธุรกิจ
 *
 *
 * columns and relationships of "loan.application_profile"
 */
export type LoanApplicationProfileMajorCustomerArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * ประวัติผู้ขอกู้/ร่วมลงทุน และข้อมูลธุรกิจ
 *
 *
 * columns and relationships of "loan.application_profile"
 */
export type LoanApplicationProfileMajorSupplierArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * ประวัติผู้ขอกู้/ร่วมลงทุน และข้อมูลธุรกิจ
 *
 *
 * columns and relationships of "loan.application_profile"
 */
export type LoanApplicationProfileProminentPointArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "loan.application_profile" */
export type LoanApplicationProfileAggregate = {
  __typename?: 'loan_application_profile_aggregate';
  aggregate?: Maybe<LoanApplicationProfileAggregateFields>;
  nodes: Array<LoanApplicationProfile>;
};

/** aggregate fields of "loan.application_profile" */
export type LoanApplicationProfileAggregateFields = {
  __typename?: 'loan_application_profile_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LoanApplicationProfileMaxFields>;
  min?: Maybe<LoanApplicationProfileMinFields>;
};


/** aggregate fields of "loan.application_profile" */
export type LoanApplicationProfileAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanApplicationProfileSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan.application_profile" */
export type LoanApplicationProfileAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanApplicationProfileMaxOrderBy>;
  min?: Maybe<LoanApplicationProfileMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type LoanApplicationProfileAppendInput = {
  /** รายชื่อลูกค้ารายใหญ่ */
  major_customer?: Maybe<Scalars['jsonb']>;
  /** รายชื่อผู้ขายวัตถุดิบรายใหญ่ */
  major_supplier?: Maybe<Scalars['jsonb']>;
  /** จุดเด่นของธุรกิจที่ขอสินเชื่อ/ร่วมลงทุน */
  prominent_point?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "loan.application_profile" */
export type LoanApplicationProfileArrRelInsertInput = {
  data: Array<LoanApplicationProfileInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanApplicationProfileOnConflict>;
};

/** Boolean expression to filter rows from the table "loan.application_profile". All fields are combined with a logical 'AND'. */
export type LoanApplicationProfileBoolExp = {
  _and?: Maybe<Array<LoanApplicationProfileBoolExp>>;
  _not?: Maybe<LoanApplicationProfileBoolExp>;
  _or?: Maybe<Array<LoanApplicationProfileBoolExp>>;
  application_header?: Maybe<LoanApplicationHeaderBoolExp>;
  company_history?: Maybe<StringComparisonExp>;
  company_product?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<StringComparisonExp>;
  current_customer?: Maybe<StringComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  distribution?: Maybe<StringComparisonExp>;
  else_usage?: Maybe<StringComparisonExp>;
  executive_history?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  innovation_usage?: Maybe<StringComparisonExp>;
  major_customer?: Maybe<JsonbComparisonExp>;
  major_supplier?: Maybe<JsonbComparisonExp>;
  prominent_point?: Maybe<JsonbComparisonExp>;
  target_group?: Maybe<StringComparisonExp>;
  technology_usage?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "loan.application_profile" */
export enum LoanApplicationProfileConstraint {
  /** unique or primary key constraint */
  ApplicationProfilePkey = 'application_profile_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type LoanApplicationProfileDeleteAtPathInput = {
  /** รายชื่อลูกค้ารายใหญ่ */
  major_customer?: Maybe<Array<Scalars['String']>>;
  /** รายชื่อผู้ขายวัตถุดิบรายใหญ่ */
  major_supplier?: Maybe<Array<Scalars['String']>>;
  /** จุดเด่นของธุรกิจที่ขอสินเชื่อ/ร่วมลงทุน */
  prominent_point?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type LoanApplicationProfileDeleteElemInput = {
  /** รายชื่อลูกค้ารายใหญ่ */
  major_customer?: Maybe<Scalars['Int']>;
  /** รายชื่อผู้ขายวัตถุดิบรายใหญ่ */
  major_supplier?: Maybe<Scalars['Int']>;
  /** จุดเด่นของธุรกิจที่ขอสินเชื่อ/ร่วมลงทุน */
  prominent_point?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type LoanApplicationProfileDeleteKeyInput = {
  /** รายชื่อลูกค้ารายใหญ่ */
  major_customer?: Maybe<Scalars['String']>;
  /** รายชื่อผู้ขายวัตถุดิบรายใหญ่ */
  major_supplier?: Maybe<Scalars['String']>;
  /** จุดเด่นของธุรกิจที่ขอสินเชื่อ/ร่วมลงทุน */
  prominent_point?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "loan.application_profile" */
export type LoanApplicationProfileInsertInput = {
  application_header?: Maybe<LoanApplicationHeaderObjRelInsertInput>;
  /** ประวัติผู้ขอกู้/ร่วมลงทุน และข้อมูลธุรกิจ */
  company_history?: Maybe<Scalars['String']>;
  /** ประเภทผลิตภัณฑ์/บริการ */
  company_product?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** ลูกค้าปัจจุบัน */
  current_customer?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ช่องทางการจัดจำหน่าย */
  distribution?: Maybe<Scalars['String']>;
  /** อื่น ๆ(ระบุ) */
  else_usage?: Maybe<Scalars['String']>;
  /** ประวัติของผู้บริหารหลักของกิจการ */
  executive_history?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** การใช้นวัตกรรม(ระบุ) */
  innovation_usage?: Maybe<Scalars['String']>;
  /** รายชื่อลูกค้ารายใหญ่ */
  major_customer?: Maybe<Scalars['jsonb']>;
  /** รายชื่อผู้ขายวัตถุดิบรายใหญ่ */
  major_supplier?: Maybe<Scalars['jsonb']>;
  /** จุดเด่นของธุรกิจที่ขอสินเชื่อ/ร่วมลงทุน */
  prominent_point?: Maybe<Scalars['jsonb']>;
  /** ลูกค้าเป้าหมาย */
  target_group?: Maybe<Scalars['String']>;
  /** การใช้เทคโนโลยี(ระบุ) */
  technology_usage?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LoanApplicationProfileMaxFields = {
  __typename?: 'loan_application_profile_max_fields';
  /** ประวัติผู้ขอกู้/ร่วมลงทุน และข้อมูลธุรกิจ */
  company_history?: Maybe<Scalars['String']>;
  /** ประเภทผลิตภัณฑ์/บริการ */
  company_product?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** ลูกค้าปัจจุบัน */
  current_customer?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ช่องทางการจัดจำหน่าย */
  distribution?: Maybe<Scalars['String']>;
  /** อื่น ๆ(ระบุ) */
  else_usage?: Maybe<Scalars['String']>;
  /** ประวัติของผู้บริหารหลักของกิจการ */
  executive_history?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** การใช้นวัตกรรม(ระบุ) */
  innovation_usage?: Maybe<Scalars['String']>;
  /** ลูกค้าเป้าหมาย */
  target_group?: Maybe<Scalars['String']>;
  /** การใช้เทคโนโลยี(ระบุ) */
  technology_usage?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "loan.application_profile" */
export type LoanApplicationProfileMaxOrderBy = {
  /** ประวัติผู้ขอกู้/ร่วมลงทุน และข้อมูลธุรกิจ */
  company_history?: Maybe<OrderBy>;
  /** ประเภทผลิตภัณฑ์/บริการ */
  company_product?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** ลูกค้าปัจจุบัน */
  current_customer?: Maybe<OrderBy>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** ช่องทางการจัดจำหน่าย */
  distribution?: Maybe<OrderBy>;
  /** อื่น ๆ(ระบุ) */
  else_usage?: Maybe<OrderBy>;
  /** ประวัติของผู้บริหารหลักของกิจการ */
  executive_history?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** การใช้นวัตกรรม(ระบุ) */
  innovation_usage?: Maybe<OrderBy>;
  /** ลูกค้าเป้าหมาย */
  target_group?: Maybe<OrderBy>;
  /** การใช้เทคโนโลยี(ระบุ) */
  technology_usage?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanApplicationProfileMinFields = {
  __typename?: 'loan_application_profile_min_fields';
  /** ประวัติผู้ขอกู้/ร่วมลงทุน และข้อมูลธุรกิจ */
  company_history?: Maybe<Scalars['String']>;
  /** ประเภทผลิตภัณฑ์/บริการ */
  company_product?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** ลูกค้าปัจจุบัน */
  current_customer?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ช่องทางการจัดจำหน่าย */
  distribution?: Maybe<Scalars['String']>;
  /** อื่น ๆ(ระบุ) */
  else_usage?: Maybe<Scalars['String']>;
  /** ประวัติของผู้บริหารหลักของกิจการ */
  executive_history?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** การใช้นวัตกรรม(ระบุ) */
  innovation_usage?: Maybe<Scalars['String']>;
  /** ลูกค้าเป้าหมาย */
  target_group?: Maybe<Scalars['String']>;
  /** การใช้เทคโนโลยี(ระบุ) */
  technology_usage?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "loan.application_profile" */
export type LoanApplicationProfileMinOrderBy = {
  /** ประวัติผู้ขอกู้/ร่วมลงทุน และข้อมูลธุรกิจ */
  company_history?: Maybe<OrderBy>;
  /** ประเภทผลิตภัณฑ์/บริการ */
  company_product?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** ลูกค้าปัจจุบัน */
  current_customer?: Maybe<OrderBy>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** ช่องทางการจัดจำหน่าย */
  distribution?: Maybe<OrderBy>;
  /** อื่น ๆ(ระบุ) */
  else_usage?: Maybe<OrderBy>;
  /** ประวัติของผู้บริหารหลักของกิจการ */
  executive_history?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** การใช้นวัตกรรม(ระบุ) */
  innovation_usage?: Maybe<OrderBy>;
  /** ลูกค้าเป้าหมาย */
  target_group?: Maybe<OrderBy>;
  /** การใช้เทคโนโลยี(ระบุ) */
  technology_usage?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan.application_profile" */
export type LoanApplicationProfileMutationResponse = {
  __typename?: 'loan_application_profile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanApplicationProfile>;
};

/** input type for inserting object relation for remote table "loan.application_profile" */
export type LoanApplicationProfileObjRelInsertInput = {
  data: LoanApplicationProfileInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanApplicationProfileOnConflict>;
};

/** on conflict condition type for table "loan.application_profile" */
export type LoanApplicationProfileOnConflict = {
  constraint: LoanApplicationProfileConstraint;
  update_columns?: Array<LoanApplicationProfileUpdateColumn>;
  where?: Maybe<LoanApplicationProfileBoolExp>;
};

/** Ordering options when selecting data from "loan.application_profile". */
export type LoanApplicationProfileOrderBy = {
  application_header?: Maybe<LoanApplicationHeaderOrderBy>;
  company_history?: Maybe<OrderBy>;
  company_product?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  current_customer?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  distribution?: Maybe<OrderBy>;
  else_usage?: Maybe<OrderBy>;
  executive_history?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  innovation_usage?: Maybe<OrderBy>;
  major_customer?: Maybe<OrderBy>;
  major_supplier?: Maybe<OrderBy>;
  prominent_point?: Maybe<OrderBy>;
  target_group?: Maybe<OrderBy>;
  technology_usage?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_application_profile */
export type LoanApplicationProfilePkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type LoanApplicationProfilePrependInput = {
  /** รายชื่อลูกค้ารายใหญ่ */
  major_customer?: Maybe<Scalars['jsonb']>;
  /** รายชื่อผู้ขายวัตถุดิบรายใหญ่ */
  major_supplier?: Maybe<Scalars['jsonb']>;
  /** จุดเด่นของธุรกิจที่ขอสินเชื่อ/ร่วมลงทุน */
  prominent_point?: Maybe<Scalars['jsonb']>;
};

/**
 * ธุรกิจที่ขอสินเชื่อ/ร่วมลงทุนครั้งนี้ มีความเชื่อมโยงยุทธศาสตร์ SME 4.0 ในด้านใด
 *
 *
 * columns and relationships of "loan.application_profile_s_curve"
 */
export type LoanApplicationProfileSCurve = {
  __typename?: 'loan_application_profile_s_curve';
  application_profile_id: Scalars['uuid'];
  id: Scalars['uuid'];
  s_curve_id: Scalars['String'];
};

/** aggregated selection of "loan.application_profile_s_curve" */
export type LoanApplicationProfileSCurveAggregate = {
  __typename?: 'loan_application_profile_s_curve_aggregate';
  aggregate?: Maybe<LoanApplicationProfileSCurveAggregateFields>;
  nodes: Array<LoanApplicationProfileSCurve>;
};

/** aggregate fields of "loan.application_profile_s_curve" */
export type LoanApplicationProfileSCurveAggregateFields = {
  __typename?: 'loan_application_profile_s_curve_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LoanApplicationProfileSCurveMaxFields>;
  min?: Maybe<LoanApplicationProfileSCurveMinFields>;
};


/** aggregate fields of "loan.application_profile_s_curve" */
export type LoanApplicationProfileSCurveAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanApplicationProfileSCurveSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "loan.application_profile_s_curve". All fields are combined with a logical 'AND'. */
export type LoanApplicationProfileSCurveBoolExp = {
  _and?: Maybe<Array<LoanApplicationProfileSCurveBoolExp>>;
  _not?: Maybe<LoanApplicationProfileSCurveBoolExp>;
  _or?: Maybe<Array<LoanApplicationProfileSCurveBoolExp>>;
  application_profile_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  s_curve_id?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "loan.application_profile_s_curve" */
export enum LoanApplicationProfileSCurveConstraint {
  /** unique or primary key constraint */
  ApplicationProfileSCurvePkey = 'application_profile_s_curve_pkey'
}

/** input type for inserting data into table "loan.application_profile_s_curve" */
export type LoanApplicationProfileSCurveInsertInput = {
  application_profile_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  s_curve_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LoanApplicationProfileSCurveMaxFields = {
  __typename?: 'loan_application_profile_s_curve_max_fields';
  application_profile_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  s_curve_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type LoanApplicationProfileSCurveMinFields = {
  __typename?: 'loan_application_profile_s_curve_min_fields';
  application_profile_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  s_curve_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "loan.application_profile_s_curve" */
export type LoanApplicationProfileSCurveMutationResponse = {
  __typename?: 'loan_application_profile_s_curve_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanApplicationProfileSCurve>;
};

/** on conflict condition type for table "loan.application_profile_s_curve" */
export type LoanApplicationProfileSCurveOnConflict = {
  constraint: LoanApplicationProfileSCurveConstraint;
  update_columns?: Array<LoanApplicationProfileSCurveUpdateColumn>;
  where?: Maybe<LoanApplicationProfileSCurveBoolExp>;
};

/** Ordering options when selecting data from "loan.application_profile_s_curve". */
export type LoanApplicationProfileSCurveOrderBy = {
  application_profile_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  s_curve_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_application_profile_s_curve */
export type LoanApplicationProfileSCurvePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "loan.application_profile_s_curve" */
export enum LoanApplicationProfileSCurveSelectColumn {
  /** column name */
  ApplicationProfileId = 'application_profile_id',
  /** column name */
  Id = 'id',
  /** column name */
  SCurveId = 's_curve_id'
}

/** input type for updating data in table "loan.application_profile_s_curve" */
export type LoanApplicationProfileSCurveSetInput = {
  application_profile_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  s_curve_id?: Maybe<Scalars['String']>;
};

/** update columns of table "loan.application_profile_s_curve" */
export enum LoanApplicationProfileSCurveUpdateColumn {
  /** column name */
  ApplicationProfileId = 'application_profile_id',
  /** column name */
  Id = 'id',
  /** column name */
  SCurveId = 's_curve_id'
}

/** select columns of table "loan.application_profile" */
export enum LoanApplicationProfileSelectColumn {
  /** column name */
  CompanyHistory = 'company_history',
  /** column name */
  CompanyProduct = 'company_product',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CurrentCustomer = 'current_customer',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Distribution = 'distribution',
  /** column name */
  ElseUsage = 'else_usage',
  /** column name */
  ExecutiveHistory = 'executive_history',
  /** column name */
  Id = 'id',
  /** column name */
  InnovationUsage = 'innovation_usage',
  /** column name */
  MajorCustomer = 'major_customer',
  /** column name */
  MajorSupplier = 'major_supplier',
  /** column name */
  ProminentPoint = 'prominent_point',
  /** column name */
  TargetGroup = 'target_group',
  /** column name */
  TechnologyUsage = 'technology_usage',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "loan.application_profile" */
export type LoanApplicationProfileSetInput = {
  /** ประวัติผู้ขอกู้/ร่วมลงทุน และข้อมูลธุรกิจ */
  company_history?: Maybe<Scalars['String']>;
  /** ประเภทผลิตภัณฑ์/บริการ */
  company_product?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** ลูกค้าปัจจุบัน */
  current_customer?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ช่องทางการจัดจำหน่าย */
  distribution?: Maybe<Scalars['String']>;
  /** อื่น ๆ(ระบุ) */
  else_usage?: Maybe<Scalars['String']>;
  /** ประวัติของผู้บริหารหลักของกิจการ */
  executive_history?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** การใช้นวัตกรรม(ระบุ) */
  innovation_usage?: Maybe<Scalars['String']>;
  /** รายชื่อลูกค้ารายใหญ่ */
  major_customer?: Maybe<Scalars['jsonb']>;
  /** รายชื่อผู้ขายวัตถุดิบรายใหญ่ */
  major_supplier?: Maybe<Scalars['jsonb']>;
  /** จุดเด่นของธุรกิจที่ขอสินเชื่อ/ร่วมลงทุน */
  prominent_point?: Maybe<Scalars['jsonb']>;
  /** ลูกค้าเป้าหมาย */
  target_group?: Maybe<Scalars['String']>;
  /** การใช้เทคโนโลยี(ระบุ) */
  technology_usage?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** update columns of table "loan.application_profile" */
export enum LoanApplicationProfileUpdateColumn {
  /** column name */
  CompanyHistory = 'company_history',
  /** column name */
  CompanyProduct = 'company_product',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CurrentCustomer = 'current_customer',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Distribution = 'distribution',
  /** column name */
  ElseUsage = 'else_usage',
  /** column name */
  ExecutiveHistory = 'executive_history',
  /** column name */
  Id = 'id',
  /** column name */
  InnovationUsage = 'innovation_usage',
  /** column name */
  MajorCustomer = 'major_customer',
  /** column name */
  MajorSupplier = 'major_supplier',
  /** column name */
  ProminentPoint = 'prominent_point',
  /** column name */
  TargetGroup = 'target_group',
  /** column name */
  TechnologyUsage = 'technology_usage',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/**
 * เลขรันนิ่งใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_sequence_no"
 */
export type LoanApplicationSequenceNo = {
  __typename?: 'loan_application_sequence_no';
  /** วันที่บันทึกข้อมูล */
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  organization: Organization;
  /** หน่วยงาน */
  organization_id: Scalars['uuid'];
  /** อ้างอิงหน่วยงาน */
  organization_reference: Scalars['String'];
  /** An object relationship */
  program: LoanProgram;
  /** รหัสโครงการ */
  program_id: Scalars['uuid'];
  /** รหัสอ้างอิงโครงการ */
  program_reference?: Maybe<Scalars['String']>;
  /** เลขรันนิ่ง */
  sequence: Scalars['Int'];
  /** วันที่แก้ไขข้อมูล */
  updated_at: Scalars['timestamptz'];
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year: Scalars['Int'];
};

/** aggregated selection of "loan.application_sequence_no" */
export type LoanApplicationSequenceNoAggregate = {
  __typename?: 'loan_application_sequence_no_aggregate';
  aggregate?: Maybe<LoanApplicationSequenceNoAggregateFields>;
  nodes: Array<LoanApplicationSequenceNo>;
};

/** aggregate fields of "loan.application_sequence_no" */
export type LoanApplicationSequenceNoAggregateFields = {
  __typename?: 'loan_application_sequence_no_aggregate_fields';
  avg?: Maybe<LoanApplicationSequenceNoAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanApplicationSequenceNoMaxFields>;
  min?: Maybe<LoanApplicationSequenceNoMinFields>;
  stddev?: Maybe<LoanApplicationSequenceNoStddevFields>;
  stddev_pop?: Maybe<LoanApplicationSequenceNoStddevPopFields>;
  stddev_samp?: Maybe<LoanApplicationSequenceNoStddevSampFields>;
  sum?: Maybe<LoanApplicationSequenceNoSumFields>;
  var_pop?: Maybe<LoanApplicationSequenceNoVarPopFields>;
  var_samp?: Maybe<LoanApplicationSequenceNoVarSampFields>;
  variance?: Maybe<LoanApplicationSequenceNoVarianceFields>;
};


/** aggregate fields of "loan.application_sequence_no" */
export type LoanApplicationSequenceNoAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanApplicationSequenceNoSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan.application_sequence_no" */
export type LoanApplicationSequenceNoAggregateOrderBy = {
  avg?: Maybe<LoanApplicationSequenceNoAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanApplicationSequenceNoMaxOrderBy>;
  min?: Maybe<LoanApplicationSequenceNoMinOrderBy>;
  stddev?: Maybe<LoanApplicationSequenceNoStddevOrderBy>;
  stddev_pop?: Maybe<LoanApplicationSequenceNoStddevPopOrderBy>;
  stddev_samp?: Maybe<LoanApplicationSequenceNoStddevSampOrderBy>;
  sum?: Maybe<LoanApplicationSequenceNoSumOrderBy>;
  var_pop?: Maybe<LoanApplicationSequenceNoVarPopOrderBy>;
  var_samp?: Maybe<LoanApplicationSequenceNoVarSampOrderBy>;
  variance?: Maybe<LoanApplicationSequenceNoVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "loan.application_sequence_no" */
export type LoanApplicationSequenceNoArrRelInsertInput = {
  data: Array<LoanApplicationSequenceNoInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanApplicationSequenceNoOnConflict>;
};

/** aggregate avg on columns */
export type LoanApplicationSequenceNoAvgFields = {
  __typename?: 'loan_application_sequence_no_avg_fields';
  /** เลขรันนิ่ง */
  sequence?: Maybe<Scalars['Float']>;
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "loan.application_sequence_no" */
export type LoanApplicationSequenceNoAvgOrderBy = {
  /** เลขรันนิ่ง */
  sequence?: Maybe<OrderBy>;
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "loan.application_sequence_no". All fields are combined with a logical 'AND'. */
export type LoanApplicationSequenceNoBoolExp = {
  _and?: Maybe<Array<LoanApplicationSequenceNoBoolExp>>;
  _not?: Maybe<LoanApplicationSequenceNoBoolExp>;
  _or?: Maybe<Array<LoanApplicationSequenceNoBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  organization?: Maybe<OrganizationBoolExp>;
  organization_id?: Maybe<UuidComparisonExp>;
  organization_reference?: Maybe<StringComparisonExp>;
  program?: Maybe<LoanProgramBoolExp>;
  program_id?: Maybe<UuidComparisonExp>;
  program_reference?: Maybe<StringComparisonExp>;
  sequence?: Maybe<IntComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  year?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "loan.application_sequence_no" */
export enum LoanApplicationSequenceNoConstraint {
  /** unique or primary key constraint */
  ApplicationSequenceNoPkey = 'application_sequence_no_pkey'
}

/** input type for incrementing numeric columns in table "loan.application_sequence_no" */
export type LoanApplicationSequenceNoIncInput = {
  /** เลขรันนิ่ง */
  sequence?: Maybe<Scalars['Int']>;
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "loan.application_sequence_no" */
export type LoanApplicationSequenceNoInsertInput = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  organization?: Maybe<OrganizationObjRelInsertInput>;
  /** หน่วยงาน */
  organization_id?: Maybe<Scalars['uuid']>;
  /** อ้างอิงหน่วยงาน */
  organization_reference?: Maybe<Scalars['String']>;
  program?: Maybe<LoanProgramObjRelInsertInput>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** รหัสอ้างอิงโครงการ */
  program_reference?: Maybe<Scalars['String']>;
  /** เลขรันนิ่ง */
  sequence?: Maybe<Scalars['Int']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type LoanApplicationSequenceNoMaxFields = {
  __typename?: 'loan_application_sequence_no_max_fields';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** หน่วยงาน */
  organization_id?: Maybe<Scalars['uuid']>;
  /** อ้างอิงหน่วยงาน */
  organization_reference?: Maybe<Scalars['String']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** รหัสอ้างอิงโครงการ */
  program_reference?: Maybe<Scalars['String']>;
  /** เลขรันนิ่ง */
  sequence?: Maybe<Scalars['Int']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "loan.application_sequence_no" */
export type LoanApplicationSequenceNoMaxOrderBy = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** หน่วยงาน */
  organization_id?: Maybe<OrderBy>;
  /** อ้างอิงหน่วยงาน */
  organization_reference?: Maybe<OrderBy>;
  /** รหัสโครงการ */
  program_id?: Maybe<OrderBy>;
  /** รหัสอ้างอิงโครงการ */
  program_reference?: Maybe<OrderBy>;
  /** เลขรันนิ่ง */
  sequence?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanApplicationSequenceNoMinFields = {
  __typename?: 'loan_application_sequence_no_min_fields';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** หน่วยงาน */
  organization_id?: Maybe<Scalars['uuid']>;
  /** อ้างอิงหน่วยงาน */
  organization_reference?: Maybe<Scalars['String']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** รหัสอ้างอิงโครงการ */
  program_reference?: Maybe<Scalars['String']>;
  /** เลขรันนิ่ง */
  sequence?: Maybe<Scalars['Int']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "loan.application_sequence_no" */
export type LoanApplicationSequenceNoMinOrderBy = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** หน่วยงาน */
  organization_id?: Maybe<OrderBy>;
  /** อ้างอิงหน่วยงาน */
  organization_reference?: Maybe<OrderBy>;
  /** รหัสโครงการ */
  program_id?: Maybe<OrderBy>;
  /** รหัสอ้างอิงโครงการ */
  program_reference?: Maybe<OrderBy>;
  /** เลขรันนิ่ง */
  sequence?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan.application_sequence_no" */
export type LoanApplicationSequenceNoMutationResponse = {
  __typename?: 'loan_application_sequence_no_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanApplicationSequenceNo>;
};

/** on conflict condition type for table "loan.application_sequence_no" */
export type LoanApplicationSequenceNoOnConflict = {
  constraint: LoanApplicationSequenceNoConstraint;
  update_columns?: Array<LoanApplicationSequenceNoUpdateColumn>;
  where?: Maybe<LoanApplicationSequenceNoBoolExp>;
};

/** Ordering options when selecting data from "loan.application_sequence_no". */
export type LoanApplicationSequenceNoOrderBy = {
  created_at?: Maybe<OrderBy>;
  organization?: Maybe<OrganizationOrderBy>;
  organization_id?: Maybe<OrderBy>;
  organization_reference?: Maybe<OrderBy>;
  program?: Maybe<LoanProgramOrderBy>;
  program_id?: Maybe<OrderBy>;
  program_reference?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  year?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_application_sequence_no */
export type LoanApplicationSequenceNoPkColumnsInput = {
  /** หน่วยงาน */
  organization_id: Scalars['uuid'];
  /** รหัสโครงการ */
  program_id: Scalars['uuid'];
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year: Scalars['Int'];
};

/** select columns of table "loan.application_sequence_no" */
export enum LoanApplicationSequenceNoSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OrganizationReference = 'organization_reference',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  ProgramReference = 'program_reference',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year'
}

/** input type for updating data in table "loan.application_sequence_no" */
export type LoanApplicationSequenceNoSetInput = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** หน่วยงาน */
  organization_id?: Maybe<Scalars['uuid']>;
  /** อ้างอิงหน่วยงาน */
  organization_reference?: Maybe<Scalars['String']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** รหัสอ้างอิงโครงการ */
  program_reference?: Maybe<Scalars['String']>;
  /** เลขรันนิ่ง */
  sequence?: Maybe<Scalars['Int']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type LoanApplicationSequenceNoStddevFields = {
  __typename?: 'loan_application_sequence_no_stddev_fields';
  /** เลขรันนิ่ง */
  sequence?: Maybe<Scalars['Float']>;
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "loan.application_sequence_no" */
export type LoanApplicationSequenceNoStddevOrderBy = {
  /** เลขรันนิ่ง */
  sequence?: Maybe<OrderBy>;
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LoanApplicationSequenceNoStddevPopFields = {
  __typename?: 'loan_application_sequence_no_stddev_pop_fields';
  /** เลขรันนิ่ง */
  sequence?: Maybe<Scalars['Float']>;
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "loan.application_sequence_no" */
export type LoanApplicationSequenceNoStddevPopOrderBy = {
  /** เลขรันนิ่ง */
  sequence?: Maybe<OrderBy>;
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LoanApplicationSequenceNoStddevSampFields = {
  __typename?: 'loan_application_sequence_no_stddev_samp_fields';
  /** เลขรันนิ่ง */
  sequence?: Maybe<Scalars['Float']>;
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "loan.application_sequence_no" */
export type LoanApplicationSequenceNoStddevSampOrderBy = {
  /** เลขรันนิ่ง */
  sequence?: Maybe<OrderBy>;
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LoanApplicationSequenceNoSumFields = {
  __typename?: 'loan_application_sequence_no_sum_fields';
  /** เลขรันนิ่ง */
  sequence?: Maybe<Scalars['Int']>;
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "loan.application_sequence_no" */
export type LoanApplicationSequenceNoSumOrderBy = {
  /** เลขรันนิ่ง */
  sequence?: Maybe<OrderBy>;
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year?: Maybe<OrderBy>;
};

/** update columns of table "loan.application_sequence_no" */
export enum LoanApplicationSequenceNoUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OrganizationReference = 'organization_reference',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  ProgramReference = 'program_reference',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year'
}

/** aggregate var_pop on columns */
export type LoanApplicationSequenceNoVarPopFields = {
  __typename?: 'loan_application_sequence_no_var_pop_fields';
  /** เลขรันนิ่ง */
  sequence?: Maybe<Scalars['Float']>;
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "loan.application_sequence_no" */
export type LoanApplicationSequenceNoVarPopOrderBy = {
  /** เลขรันนิ่ง */
  sequence?: Maybe<OrderBy>;
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LoanApplicationSequenceNoVarSampFields = {
  __typename?: 'loan_application_sequence_no_var_samp_fields';
  /** เลขรันนิ่ง */
  sequence?: Maybe<Scalars['Float']>;
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "loan.application_sequence_no" */
export type LoanApplicationSequenceNoVarSampOrderBy = {
  /** เลขรันนิ่ง */
  sequence?: Maybe<OrderBy>;
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LoanApplicationSequenceNoVarianceFields = {
  __typename?: 'loan_application_sequence_no_variance_fields';
  /** เลขรันนิ่ง */
  sequence?: Maybe<Scalars['Float']>;
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "loan.application_sequence_no" */
export type LoanApplicationSequenceNoVarianceOrderBy = {
  /** เลขรันนิ่ง */
  sequence?: Maybe<OrderBy>;
  /** ปีพ.ศ. 2 หลักสุดท้าย */
  year?: Maybe<OrderBy>;
};

/**
 * สถานะหลักของใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_step"
 */
export type LoanApplicationStep = {
  __typename?: 'loan_application_step';
  /** An array relationship */
  application_event_logs: Array<LoanApplicationEventLog>;
  /** An aggregate relationship */
  application_event_logs_aggregate: LoanApplicationEventLogAggregate;
  /** An array relationship */
  application_headers: Array<LoanApplicationHeader>;
  /** An aggregate relationship */
  application_headers_aggregate: LoanApplicationHeaderAggregate;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
  /** ลำดับที่ */
  value: Scalars['numeric'];
};


/**
 * สถานะหลักของใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_step"
 */
export type LoanApplicationStepApplicationEventLogsArgs = {
  distinct_on?: Maybe<Array<LoanApplicationEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationEventLogOrderBy>>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
};


/**
 * สถานะหลักของใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_step"
 */
export type LoanApplicationStepApplicationEventLogsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationEventLogOrderBy>>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
};


/**
 * สถานะหลักของใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_step"
 */
export type LoanApplicationStepApplicationHeadersArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};


/**
 * สถานะหลักของใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.application_step"
 */
export type LoanApplicationStepApplicationHeadersAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};

/** aggregated selection of "loan.application_step" */
export type LoanApplicationStepAggregate = {
  __typename?: 'loan_application_step_aggregate';
  aggregate?: Maybe<LoanApplicationStepAggregateFields>;
  nodes: Array<LoanApplicationStep>;
};

/** aggregate fields of "loan.application_step" */
export type LoanApplicationStepAggregateFields = {
  __typename?: 'loan_application_step_aggregate_fields';
  avg?: Maybe<LoanApplicationStepAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanApplicationStepMaxFields>;
  min?: Maybe<LoanApplicationStepMinFields>;
  stddev?: Maybe<LoanApplicationStepStddevFields>;
  stddev_pop?: Maybe<LoanApplicationStepStddevPopFields>;
  stddev_samp?: Maybe<LoanApplicationStepStddevSampFields>;
  sum?: Maybe<LoanApplicationStepSumFields>;
  var_pop?: Maybe<LoanApplicationStepVarPopFields>;
  var_samp?: Maybe<LoanApplicationStepVarSampFields>;
  variance?: Maybe<LoanApplicationStepVarianceFields>;
};


/** aggregate fields of "loan.application_step" */
export type LoanApplicationStepAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanApplicationStepSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LoanApplicationStepAvgFields = {
  __typename?: 'loan_application_step_avg_fields';
  /** ลำดับที่ */
  value?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "loan.application_step". All fields are combined with a logical 'AND'. */
export type LoanApplicationStepBoolExp = {
  _and?: Maybe<Array<LoanApplicationStepBoolExp>>;
  _not?: Maybe<LoanApplicationStepBoolExp>;
  _or?: Maybe<Array<LoanApplicationStepBoolExp>>;
  application_event_logs?: Maybe<LoanApplicationEventLogBoolExp>;
  application_headers?: Maybe<LoanApplicationHeaderBoolExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  value?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "loan.application_step" */
export enum LoanApplicationStepConstraint {
  /** unique or primary key constraint */
  ApplicationStepPkey = 'application_step_pkey'
}

/** input type for incrementing numeric columns in table "loan.application_step" */
export type LoanApplicationStepIncInput = {
  /** ลำดับที่ */
  value?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.application_step" */
export type LoanApplicationStepInsertInput = {
  application_event_logs?: Maybe<LoanApplicationEventLogArrRelInsertInput>;
  application_headers?: Maybe<LoanApplicationHeaderArrRelInsertInput>;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  value?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type LoanApplicationStepMaxFields = {
  __typename?: 'loan_application_step_max_fields';
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  value?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type LoanApplicationStepMinFields = {
  __typename?: 'loan_application_step_min_fields';
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  value?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "loan.application_step" */
export type LoanApplicationStepMutationResponse = {
  __typename?: 'loan_application_step_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanApplicationStep>;
};

/** input type for inserting object relation for remote table "loan.application_step" */
export type LoanApplicationStepObjRelInsertInput = {
  data: LoanApplicationStepInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanApplicationStepOnConflict>;
};

/** on conflict condition type for table "loan.application_step" */
export type LoanApplicationStepOnConflict = {
  constraint: LoanApplicationStepConstraint;
  update_columns?: Array<LoanApplicationStepUpdateColumn>;
  where?: Maybe<LoanApplicationStepBoolExp>;
};

/** Ordering options when selecting data from "loan.application_step". */
export type LoanApplicationStepOrderBy = {
  application_event_logs_aggregate?: Maybe<LoanApplicationEventLogAggregateOrderBy>;
  application_headers_aggregate?: Maybe<LoanApplicationHeaderAggregateOrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_application_step */
export type LoanApplicationStepPkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "loan.application_step" */
export enum LoanApplicationStepSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "loan.application_step" */
export type LoanApplicationStepSetInput = {
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  value?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type LoanApplicationStepStddevFields = {
  __typename?: 'loan_application_step_stddev_fields';
  /** ลำดับที่ */
  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LoanApplicationStepStddevPopFields = {
  __typename?: 'loan_application_step_stddev_pop_fields';
  /** ลำดับที่ */
  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LoanApplicationStepStddevSampFields = {
  __typename?: 'loan_application_step_stddev_samp_fields';
  /** ลำดับที่ */
  value?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LoanApplicationStepSumFields = {
  __typename?: 'loan_application_step_sum_fields';
  /** ลำดับที่ */
  value?: Maybe<Scalars['numeric']>;
};

/** update columns of table "loan.application_step" */
export enum LoanApplicationStepUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type LoanApplicationStepVarPopFields = {
  __typename?: 'loan_application_step_var_pop_fields';
  /** ลำดับที่ */
  value?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LoanApplicationStepVarSampFields = {
  __typename?: 'loan_application_step_var_samp_fields';
  /** ลำดับที่ */
  value?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LoanApplicationStepVarianceFields = {
  __typename?: 'loan_application_step_variance_fields';
  /** ลำดับที่ */
  value?: Maybe<Scalars['Float']>;
};

/**
 * ประเภทเอกสารแนบ
 *
 *
 * columns and relationships of "loan.attachment_type"
 */
export type LoanAttachmentType = {
  __typename?: 'loan_attachment_type';
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active: Scalars['Boolean'];
  /** An array relationship */
  application_attachments: Array<LoanApplicationAttachment>;
  /** An aggregate relationship */
  application_attachments_aggregate: LoanApplicationAttachmentAggregate;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
  /** จำเป็นต้องแนบเอกสาร */
  required: Scalars['Boolean'];
  /** ลำดับ */
  sequence?: Maybe<Scalars['numeric']>;
};


/**
 * ประเภทเอกสารแนบ
 *
 *
 * columns and relationships of "loan.attachment_type"
 */
export type LoanAttachmentTypeApplicationAttachmentsArgs = {
  distinct_on?: Maybe<Array<LoanApplicationAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationAttachmentOrderBy>>;
  where?: Maybe<LoanApplicationAttachmentBoolExp>;
};


/**
 * ประเภทเอกสารแนบ
 *
 *
 * columns and relationships of "loan.attachment_type"
 */
export type LoanAttachmentTypeApplicationAttachmentsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationAttachmentOrderBy>>;
  where?: Maybe<LoanApplicationAttachmentBoolExp>;
};

/** aggregated selection of "loan.attachment_type" */
export type LoanAttachmentTypeAggregate = {
  __typename?: 'loan_attachment_type_aggregate';
  aggregate?: Maybe<LoanAttachmentTypeAggregateFields>;
  nodes: Array<LoanAttachmentType>;
};

/** aggregate fields of "loan.attachment_type" */
export type LoanAttachmentTypeAggregateFields = {
  __typename?: 'loan_attachment_type_aggregate_fields';
  avg?: Maybe<LoanAttachmentTypeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanAttachmentTypeMaxFields>;
  min?: Maybe<LoanAttachmentTypeMinFields>;
  stddev?: Maybe<LoanAttachmentTypeStddevFields>;
  stddev_pop?: Maybe<LoanAttachmentTypeStddevPopFields>;
  stddev_samp?: Maybe<LoanAttachmentTypeStddevSampFields>;
  sum?: Maybe<LoanAttachmentTypeSumFields>;
  var_pop?: Maybe<LoanAttachmentTypeVarPopFields>;
  var_samp?: Maybe<LoanAttachmentTypeVarSampFields>;
  variance?: Maybe<LoanAttachmentTypeVarianceFields>;
};


/** aggregate fields of "loan.attachment_type" */
export type LoanAttachmentTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanAttachmentTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LoanAttachmentTypeAvgFields = {
  __typename?: 'loan_attachment_type_avg_fields';
  /** ลำดับ */
  sequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "loan.attachment_type". All fields are combined with a logical 'AND'. */
export type LoanAttachmentTypeBoolExp = {
  _and?: Maybe<Array<LoanAttachmentTypeBoolExp>>;
  _not?: Maybe<LoanAttachmentTypeBoolExp>;
  _or?: Maybe<Array<LoanAttachmentTypeBoolExp>>;
  active?: Maybe<BooleanComparisonExp>;
  application_attachments?: Maybe<LoanApplicationAttachmentBoolExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  required?: Maybe<BooleanComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "loan.attachment_type" */
export enum LoanAttachmentTypeConstraint {
  /** unique or primary key constraint */
  AttachmentTypePkey = 'attachment_type_pkey'
}

/** input type for incrementing numeric columns in table "loan.attachment_type" */
export type LoanAttachmentTypeIncInput = {
  /** ลำดับ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.attachment_type" */
export type LoanAttachmentTypeInsertInput = {
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active?: Maybe<Scalars['Boolean']>;
  application_attachments?: Maybe<LoanApplicationAttachmentArrRelInsertInput>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** จำเป็นต้องแนบเอกสาร */
  required?: Maybe<Scalars['Boolean']>;
  /** ลำดับ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type LoanAttachmentTypeMaxFields = {
  __typename?: 'loan_attachment_type_max_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type LoanAttachmentTypeMinFields = {
  __typename?: 'loan_attachment_type_min_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "loan.attachment_type" */
export type LoanAttachmentTypeMutationResponse = {
  __typename?: 'loan_attachment_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanAttachmentType>;
};

/** input type for inserting object relation for remote table "loan.attachment_type" */
export type LoanAttachmentTypeObjRelInsertInput = {
  data: LoanAttachmentTypeInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanAttachmentTypeOnConflict>;
};

/** on conflict condition type for table "loan.attachment_type" */
export type LoanAttachmentTypeOnConflict = {
  constraint: LoanAttachmentTypeConstraint;
  update_columns?: Array<LoanAttachmentTypeUpdateColumn>;
  where?: Maybe<LoanAttachmentTypeBoolExp>;
};

/** Ordering options when selecting data from "loan.attachment_type". */
export type LoanAttachmentTypeOrderBy = {
  active?: Maybe<OrderBy>;
  application_attachments_aggregate?: Maybe<LoanApplicationAttachmentAggregateOrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  required?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_attachment_type */
export type LoanAttachmentTypePkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "loan.attachment_type" */
export enum LoanAttachmentTypeSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Required = 'required',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "loan.attachment_type" */
export type LoanAttachmentTypeSetInput = {
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active?: Maybe<Scalars['Boolean']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** จำเป็นต้องแนบเอกสาร */
  required?: Maybe<Scalars['Boolean']>;
  /** ลำดับ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type LoanAttachmentTypeStddevFields = {
  __typename?: 'loan_attachment_type_stddev_fields';
  /** ลำดับ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LoanAttachmentTypeStddevPopFields = {
  __typename?: 'loan_attachment_type_stddev_pop_fields';
  /** ลำดับ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LoanAttachmentTypeStddevSampFields = {
  __typename?: 'loan_attachment_type_stddev_samp_fields';
  /** ลำดับ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LoanAttachmentTypeSumFields = {
  __typename?: 'loan_attachment_type_sum_fields';
  /** ลำดับ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** update columns of table "loan.attachment_type" */
export enum LoanAttachmentTypeUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Required = 'required',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type LoanAttachmentTypeVarPopFields = {
  __typename?: 'loan_attachment_type_var_pop_fields';
  /** ลำดับ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LoanAttachmentTypeVarSampFields = {
  __typename?: 'loan_attachment_type_var_samp_fields';
  /** ลำดับ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LoanAttachmentTypeVarianceFields = {
  __typename?: 'loan_attachment_type_variance_fields';
  /** ลำดับ */
  sequence?: Maybe<Scalars['Float']>;
};

/**
 * ผลพิจารณาและอนุมัติจากธนาคาร
 *
 *
 * columns and relationships of "loan.bank_approve"
 */
export type LoanBankApprove = {
  __typename?: 'loan_bank_approve';
  /** An array relationship */
  application_event_logs: Array<LoanApplicationEventLog>;
  /** An aggregate relationship */
  application_event_logs_aggregate: LoanApplicationEventLogAggregate;
  /** An array relationship */
  application_headers: Array<LoanApplicationHeader>;
  /** An aggregate relationship */
  application_headers_aggregate: LoanApplicationHeaderAggregate;
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};


/**
 * ผลพิจารณาและอนุมัติจากธนาคาร
 *
 *
 * columns and relationships of "loan.bank_approve"
 */
export type LoanBankApproveApplicationEventLogsArgs = {
  distinct_on?: Maybe<Array<LoanApplicationEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationEventLogOrderBy>>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
};


/**
 * ผลพิจารณาและอนุมัติจากธนาคาร
 *
 *
 * columns and relationships of "loan.bank_approve"
 */
export type LoanBankApproveApplicationEventLogsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationEventLogOrderBy>>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
};


/**
 * ผลพิจารณาและอนุมัติจากธนาคาร
 *
 *
 * columns and relationships of "loan.bank_approve"
 */
export type LoanBankApproveApplicationHeadersArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};


/**
 * ผลพิจารณาและอนุมัติจากธนาคาร
 *
 *
 * columns and relationships of "loan.bank_approve"
 */
export type LoanBankApproveApplicationHeadersAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};

/** aggregated selection of "loan.bank_approve" */
export type LoanBankApproveAggregate = {
  __typename?: 'loan_bank_approve_aggregate';
  aggregate?: Maybe<LoanBankApproveAggregateFields>;
  nodes: Array<LoanBankApprove>;
};

/** aggregate fields of "loan.bank_approve" */
export type LoanBankApproveAggregateFields = {
  __typename?: 'loan_bank_approve_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LoanBankApproveMaxFields>;
  min?: Maybe<LoanBankApproveMinFields>;
};


/** aggregate fields of "loan.bank_approve" */
export type LoanBankApproveAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanBankApproveSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "loan.bank_approve". All fields are combined with a logical 'AND'. */
export type LoanBankApproveBoolExp = {
  _and?: Maybe<Array<LoanBankApproveBoolExp>>;
  _not?: Maybe<LoanBankApproveBoolExp>;
  _or?: Maybe<Array<LoanBankApproveBoolExp>>;
  application_event_logs?: Maybe<LoanApplicationEventLogBoolExp>;
  application_headers?: Maybe<LoanApplicationHeaderBoolExp>;
  description?: Maybe<StringComparisonExp>;
  value?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "loan.bank_approve" */
export enum LoanBankApproveConstraint {
  /** unique or primary key constraint */
  BankApprovePkey = 'bank_approve_pkey'
}

export enum LoanBankApproveEnum {
  /** ผ่านพิจารณาและอนุมัติจากธนาคาร */
  Approved = 'approved',
  /** ไม่ผ่านพิจารณาและอนุมัติจากธนาคาร */
  Disapprove = 'disapprove'
}

/** Boolean expression to compare columns of type "loan_bank_approve_enum". All fields are combined with logical 'AND'. */
export type LoanBankApproveEnumComparisonExp = {
  _eq?: Maybe<LoanBankApproveEnum>;
  _in?: Maybe<Array<LoanBankApproveEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<LoanBankApproveEnum>;
  _nin?: Maybe<Array<LoanBankApproveEnum>>;
};

/** input type for inserting data into table "loan.bank_approve" */
export type LoanBankApproveInsertInput = {
  application_event_logs?: Maybe<LoanApplicationEventLogArrRelInsertInput>;
  application_headers?: Maybe<LoanApplicationHeaderArrRelInsertInput>;
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LoanBankApproveMaxFields = {
  __typename?: 'loan_bank_approve_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type LoanBankApproveMinFields = {
  __typename?: 'loan_bank_approve_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "loan.bank_approve" */
export type LoanBankApproveMutationResponse = {
  __typename?: 'loan_bank_approve_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanBankApprove>;
};

/** input type for inserting object relation for remote table "loan.bank_approve" */
export type LoanBankApproveObjRelInsertInput = {
  data: LoanBankApproveInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanBankApproveOnConflict>;
};

/** on conflict condition type for table "loan.bank_approve" */
export type LoanBankApproveOnConflict = {
  constraint: LoanBankApproveConstraint;
  update_columns?: Array<LoanBankApproveUpdateColumn>;
  where?: Maybe<LoanBankApproveBoolExp>;
};

/** Ordering options when selecting data from "loan.bank_approve". */
export type LoanBankApproveOrderBy = {
  application_event_logs_aggregate?: Maybe<LoanApplicationEventLogAggregateOrderBy>;
  application_headers_aggregate?: Maybe<LoanApplicationHeaderAggregateOrderBy>;
  description?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_bank_approve */
export type LoanBankApprovePkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "loan.bank_approve" */
export enum LoanBankApproveSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "loan.bank_approve" */
export type LoanBankApproveSetInput = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "loan.bank_approve" */
export enum LoanBankApproveUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/**
 * กลุ่มธุรกิจ
 *
 *
 * columns and relationships of "loan.business_group"
 */
export type LoanBusinessGroup = {
  __typename?: 'loan_business_group';
  /** An array relationship */
  application_headers: Array<LoanApplicationHeader>;
  /** An aggregate relationship */
  application_headers_aggregate: LoanApplicationHeaderAggregate;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย กลุ่มธุรกิจ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** An object relationship */
  program?: Maybe<LoanProgram>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence: Scalars['numeric'];
};


/**
 * กลุ่มธุรกิจ
 *
 *
 * columns and relationships of "loan.business_group"
 */
export type LoanBusinessGroupApplicationHeadersArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};


/**
 * กลุ่มธุรกิจ
 *
 *
 * columns and relationships of "loan.business_group"
 */
export type LoanBusinessGroupApplicationHeadersAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};

/** aggregated selection of "loan.business_group" */
export type LoanBusinessGroupAggregate = {
  __typename?: 'loan_business_group_aggregate';
  aggregate?: Maybe<LoanBusinessGroupAggregateFields>;
  nodes: Array<LoanBusinessGroup>;
};

/** aggregate fields of "loan.business_group" */
export type LoanBusinessGroupAggregateFields = {
  __typename?: 'loan_business_group_aggregate_fields';
  avg?: Maybe<LoanBusinessGroupAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanBusinessGroupMaxFields>;
  min?: Maybe<LoanBusinessGroupMinFields>;
  stddev?: Maybe<LoanBusinessGroupStddevFields>;
  stddev_pop?: Maybe<LoanBusinessGroupStddevPopFields>;
  stddev_samp?: Maybe<LoanBusinessGroupStddevSampFields>;
  sum?: Maybe<LoanBusinessGroupSumFields>;
  var_pop?: Maybe<LoanBusinessGroupVarPopFields>;
  var_samp?: Maybe<LoanBusinessGroupVarSampFields>;
  variance?: Maybe<LoanBusinessGroupVarianceFields>;
};


/** aggregate fields of "loan.business_group" */
export type LoanBusinessGroupAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanBusinessGroupSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan.business_group" */
export type LoanBusinessGroupAggregateOrderBy = {
  avg?: Maybe<LoanBusinessGroupAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanBusinessGroupMaxOrderBy>;
  min?: Maybe<LoanBusinessGroupMinOrderBy>;
  stddev?: Maybe<LoanBusinessGroupStddevOrderBy>;
  stddev_pop?: Maybe<LoanBusinessGroupStddevPopOrderBy>;
  stddev_samp?: Maybe<LoanBusinessGroupStddevSampOrderBy>;
  sum?: Maybe<LoanBusinessGroupSumOrderBy>;
  var_pop?: Maybe<LoanBusinessGroupVarPopOrderBy>;
  var_samp?: Maybe<LoanBusinessGroupVarSampOrderBy>;
  variance?: Maybe<LoanBusinessGroupVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "loan.business_group" */
export type LoanBusinessGroupArrRelInsertInput = {
  data: Array<LoanBusinessGroupInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanBusinessGroupOnConflict>;
};

/** aggregate avg on columns */
export type LoanBusinessGroupAvgFields = {
  __typename?: 'loan_business_group_avg_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "loan.business_group" */
export type LoanBusinessGroupAvgOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "loan.business_group". All fields are combined with a logical 'AND'. */
export type LoanBusinessGroupBoolExp = {
  _and?: Maybe<Array<LoanBusinessGroupBoolExp>>;
  _not?: Maybe<LoanBusinessGroupBoolExp>;
  _or?: Maybe<Array<LoanBusinessGroupBoolExp>>;
  application_headers?: Maybe<LoanApplicationHeaderBoolExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  program?: Maybe<LoanProgramBoolExp>;
  program_id?: Maybe<UuidComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "loan.business_group" */
export enum LoanBusinessGroupConstraint {
  /** unique or primary key constraint */
  BusinessGroupPkey = 'business_group_pkey'
}

/** input type for incrementing numeric columns in table "loan.business_group" */
export type LoanBusinessGroupIncInput = {
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.business_group" */
export type LoanBusinessGroupInsertInput = {
  application_headers?: Maybe<LoanApplicationHeaderArrRelInsertInput>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย กลุ่มธุรกิจ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  program?: Maybe<LoanProgramObjRelInsertInput>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type LoanBusinessGroupMaxFields = {
  __typename?: 'loan_business_group_max_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย กลุ่มธุรกิจ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "loan.business_group" */
export type LoanBusinessGroupMaxOrderBy = {
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** คำอธิบาย กลุ่มธุรกิจ */
  description?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** รหัสโครงการ */
  program_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanBusinessGroupMinFields = {
  __typename?: 'loan_business_group_min_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย กลุ่มธุรกิจ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "loan.business_group" */
export type LoanBusinessGroupMinOrderBy = {
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** คำอธิบาย กลุ่มธุรกิจ */
  description?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** รหัสโครงการ */
  program_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan.business_group" */
export type LoanBusinessGroupMutationResponse = {
  __typename?: 'loan_business_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanBusinessGroup>;
};

/** input type for inserting object relation for remote table "loan.business_group" */
export type LoanBusinessGroupObjRelInsertInput = {
  data: LoanBusinessGroupInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanBusinessGroupOnConflict>;
};

/** on conflict condition type for table "loan.business_group" */
export type LoanBusinessGroupOnConflict = {
  constraint: LoanBusinessGroupConstraint;
  update_columns?: Array<LoanBusinessGroupUpdateColumn>;
  where?: Maybe<LoanBusinessGroupBoolExp>;
};

/** Ordering options when selecting data from "loan.business_group". */
export type LoanBusinessGroupOrderBy = {
  application_headers_aggregate?: Maybe<LoanApplicationHeaderAggregateOrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  program?: Maybe<LoanProgramOrderBy>;
  program_id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_business_group */
export type LoanBusinessGroupPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "loan.business_group" */
export enum LoanBusinessGroupSelectColumn {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "loan.business_group" */
export type LoanBusinessGroupSetInput = {
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย กลุ่มธุรกิจ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type LoanBusinessGroupStddevFields = {
  __typename?: 'loan_business_group_stddev_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "loan.business_group" */
export type LoanBusinessGroupStddevOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LoanBusinessGroupStddevPopFields = {
  __typename?: 'loan_business_group_stddev_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "loan.business_group" */
export type LoanBusinessGroupStddevPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LoanBusinessGroupStddevSampFields = {
  __typename?: 'loan_business_group_stddev_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "loan.business_group" */
export type LoanBusinessGroupStddevSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LoanBusinessGroupSumFields = {
  __typename?: 'loan_business_group_sum_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "loan.business_group" */
export type LoanBusinessGroupSumOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "loan.business_group" */
export enum LoanBusinessGroupUpdateColumn {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type LoanBusinessGroupVarPopFields = {
  __typename?: 'loan_business_group_var_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "loan.business_group" */
export type LoanBusinessGroupVarPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LoanBusinessGroupVarSampFields = {
  __typename?: 'loan_business_group_var_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "loan.business_group" */
export type LoanBusinessGroupVarSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LoanBusinessGroupVarianceFields = {
  __typename?: 'loan_business_group_variance_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "loan.business_group" */
export type LoanBusinessGroupVarianceOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/**
 * กิจการที่ขอสินเชื่อ/ร่วมลงทุนครั้งนี้ เพื่อใช้ในการดำเนินธุรกิจลักษณะใด
 *
 *
 * columns and relationships of "loan.business_operation"
 */
export type LoanBusinessOperation = {
  __typename?: 'loan_business_operation';
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active: Scalars['Boolean'];
  /** An array relationship */
  application_business_operations: Array<LoanApplicationBusinessOperation>;
  /** An aggregate relationship */
  application_business_operations_aggregate: LoanApplicationBusinessOperationAggregate;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
  /** หมายเหตุเพิ่มเติม */
  need_addition: Scalars['Boolean'];
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};


/**
 * กิจการที่ขอสินเชื่อ/ร่วมลงทุนครั้งนี้ เพื่อใช้ในการดำเนินธุรกิจลักษณะใด
 *
 *
 * columns and relationships of "loan.business_operation"
 */
export type LoanBusinessOperationApplicationBusinessOperationsArgs = {
  distinct_on?: Maybe<Array<LoanApplicationBusinessOperationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationBusinessOperationOrderBy>>;
  where?: Maybe<LoanApplicationBusinessOperationBoolExp>;
};


/**
 * กิจการที่ขอสินเชื่อ/ร่วมลงทุนครั้งนี้ เพื่อใช้ในการดำเนินธุรกิจลักษณะใด
 *
 *
 * columns and relationships of "loan.business_operation"
 */
export type LoanBusinessOperationApplicationBusinessOperationsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationBusinessOperationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationBusinessOperationOrderBy>>;
  where?: Maybe<LoanApplicationBusinessOperationBoolExp>;
};

/** aggregated selection of "loan.business_operation" */
export type LoanBusinessOperationAggregate = {
  __typename?: 'loan_business_operation_aggregate';
  aggregate?: Maybe<LoanBusinessOperationAggregateFields>;
  nodes: Array<LoanBusinessOperation>;
};

/** aggregate fields of "loan.business_operation" */
export type LoanBusinessOperationAggregateFields = {
  __typename?: 'loan_business_operation_aggregate_fields';
  avg?: Maybe<LoanBusinessOperationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanBusinessOperationMaxFields>;
  min?: Maybe<LoanBusinessOperationMinFields>;
  stddev?: Maybe<LoanBusinessOperationStddevFields>;
  stddev_pop?: Maybe<LoanBusinessOperationStddevPopFields>;
  stddev_samp?: Maybe<LoanBusinessOperationStddevSampFields>;
  sum?: Maybe<LoanBusinessOperationSumFields>;
  var_pop?: Maybe<LoanBusinessOperationVarPopFields>;
  var_samp?: Maybe<LoanBusinessOperationVarSampFields>;
  variance?: Maybe<LoanBusinessOperationVarianceFields>;
};


/** aggregate fields of "loan.business_operation" */
export type LoanBusinessOperationAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanBusinessOperationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LoanBusinessOperationAvgFields = {
  __typename?: 'loan_business_operation_avg_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "loan.business_operation". All fields are combined with a logical 'AND'. */
export type LoanBusinessOperationBoolExp = {
  _and?: Maybe<Array<LoanBusinessOperationBoolExp>>;
  _not?: Maybe<LoanBusinessOperationBoolExp>;
  _or?: Maybe<Array<LoanBusinessOperationBoolExp>>;
  active?: Maybe<BooleanComparisonExp>;
  application_business_operations?: Maybe<LoanApplicationBusinessOperationBoolExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  need_addition?: Maybe<BooleanComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "loan.business_operation" */
export enum LoanBusinessOperationConstraint {
  /** unique or primary key constraint */
  BusinessOperationPkey = 'business_operation_pkey'
}

/** input type for incrementing numeric columns in table "loan.business_operation" */
export type LoanBusinessOperationIncInput = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.business_operation" */
export type LoanBusinessOperationInsertInput = {
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active?: Maybe<Scalars['Boolean']>;
  application_business_operations?: Maybe<LoanApplicationBusinessOperationArrRelInsertInput>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** หมายเหตุเพิ่มเติม */
  need_addition?: Maybe<Scalars['Boolean']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type LoanBusinessOperationMaxFields = {
  __typename?: 'loan_business_operation_max_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type LoanBusinessOperationMinFields = {
  __typename?: 'loan_business_operation_min_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "loan.business_operation" */
export type LoanBusinessOperationMutationResponse = {
  __typename?: 'loan_business_operation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanBusinessOperation>;
};

/** input type for inserting object relation for remote table "loan.business_operation" */
export type LoanBusinessOperationObjRelInsertInput = {
  data: LoanBusinessOperationInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanBusinessOperationOnConflict>;
};

/** on conflict condition type for table "loan.business_operation" */
export type LoanBusinessOperationOnConflict = {
  constraint: LoanBusinessOperationConstraint;
  update_columns?: Array<LoanBusinessOperationUpdateColumn>;
  where?: Maybe<LoanBusinessOperationBoolExp>;
};

/** Ordering options when selecting data from "loan.business_operation". */
export type LoanBusinessOperationOrderBy = {
  active?: Maybe<OrderBy>;
  application_business_operations_aggregate?: Maybe<LoanApplicationBusinessOperationAggregateOrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  need_addition?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_business_operation */
export type LoanBusinessOperationPkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "loan.business_operation" */
export enum LoanBusinessOperationSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  NeedAddition = 'need_addition',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "loan.business_operation" */
export type LoanBusinessOperationSetInput = {
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active?: Maybe<Scalars['Boolean']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** หมายเหตุเพิ่มเติม */
  need_addition?: Maybe<Scalars['Boolean']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type LoanBusinessOperationStddevFields = {
  __typename?: 'loan_business_operation_stddev_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LoanBusinessOperationStddevPopFields = {
  __typename?: 'loan_business_operation_stddev_pop_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LoanBusinessOperationStddevSampFields = {
  __typename?: 'loan_business_operation_stddev_samp_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LoanBusinessOperationSumFields = {
  __typename?: 'loan_business_operation_sum_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** update columns of table "loan.business_operation" */
export enum LoanBusinessOperationUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  NeedAddition = 'need_addition',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type LoanBusinessOperationVarPopFields = {
  __typename?: 'loan_business_operation_var_pop_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LoanBusinessOperationVarSampFields = {
  __typename?: 'loan_business_operation_var_samp_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LoanBusinessOperationVarianceFields = {
  __typename?: 'loan_business_operation_variance_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/**
 * ลักษณะสถานประกอบการ
 *
 *
 * columns and relationships of "loan.business_proprietary"
 */
export type LoanBusinessProprietary = {
  __typename?: 'loan_business_proprietary';
  /** An array relationship */
  application_headers: Array<LoanApplicationHeader>;
  /** An aggregate relationship */
  application_headers_aggregate: LoanApplicationHeaderAggregate;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ลักษณะสถานประกอบการ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** ระบุ เพิ่มเติม */
  need_addition: Scalars['Boolean'];
  /** ลำดับที่ */
  sequence: Scalars['numeric'];
};


/**
 * ลักษณะสถานประกอบการ
 *
 *
 * columns and relationships of "loan.business_proprietary"
 */
export type LoanBusinessProprietaryApplicationHeadersArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};


/**
 * ลักษณะสถานประกอบการ
 *
 *
 * columns and relationships of "loan.business_proprietary"
 */
export type LoanBusinessProprietaryApplicationHeadersAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};

/** aggregated selection of "loan.business_proprietary" */
export type LoanBusinessProprietaryAggregate = {
  __typename?: 'loan_business_proprietary_aggregate';
  aggregate?: Maybe<LoanBusinessProprietaryAggregateFields>;
  nodes: Array<LoanBusinessProprietary>;
};

/** aggregate fields of "loan.business_proprietary" */
export type LoanBusinessProprietaryAggregateFields = {
  __typename?: 'loan_business_proprietary_aggregate_fields';
  avg?: Maybe<LoanBusinessProprietaryAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanBusinessProprietaryMaxFields>;
  min?: Maybe<LoanBusinessProprietaryMinFields>;
  stddev?: Maybe<LoanBusinessProprietaryStddevFields>;
  stddev_pop?: Maybe<LoanBusinessProprietaryStddevPopFields>;
  stddev_samp?: Maybe<LoanBusinessProprietaryStddevSampFields>;
  sum?: Maybe<LoanBusinessProprietarySumFields>;
  var_pop?: Maybe<LoanBusinessProprietaryVarPopFields>;
  var_samp?: Maybe<LoanBusinessProprietaryVarSampFields>;
  variance?: Maybe<LoanBusinessProprietaryVarianceFields>;
};


/** aggregate fields of "loan.business_proprietary" */
export type LoanBusinessProprietaryAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanBusinessProprietarySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LoanBusinessProprietaryAvgFields = {
  __typename?: 'loan_business_proprietary_avg_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "loan.business_proprietary". All fields are combined with a logical 'AND'. */
export type LoanBusinessProprietaryBoolExp = {
  _and?: Maybe<Array<LoanBusinessProprietaryBoolExp>>;
  _not?: Maybe<LoanBusinessProprietaryBoolExp>;
  _or?: Maybe<Array<LoanBusinessProprietaryBoolExp>>;
  application_headers?: Maybe<LoanApplicationHeaderBoolExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  need_addition?: Maybe<BooleanComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "loan.business_proprietary" */
export enum LoanBusinessProprietaryConstraint {
  /** unique or primary key constraint */
  BusinessProprietaryPkey = 'business_proprietary_pkey'
}

/** input type for incrementing numeric columns in table "loan.business_proprietary" */
export type LoanBusinessProprietaryIncInput = {
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.business_proprietary" */
export type LoanBusinessProprietaryInsertInput = {
  application_headers?: Maybe<LoanApplicationHeaderArrRelInsertInput>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ลักษณะสถานประกอบการ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ระบุ เพิ่มเติม */
  need_addition?: Maybe<Scalars['Boolean']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type LoanBusinessProprietaryMaxFields = {
  __typename?: 'loan_business_proprietary_max_fields';
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ลักษณะสถานประกอบการ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type LoanBusinessProprietaryMinFields = {
  __typename?: 'loan_business_proprietary_min_fields';
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ลักษณะสถานประกอบการ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "loan.business_proprietary" */
export type LoanBusinessProprietaryMutationResponse = {
  __typename?: 'loan_business_proprietary_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanBusinessProprietary>;
};

/** input type for inserting object relation for remote table "loan.business_proprietary" */
export type LoanBusinessProprietaryObjRelInsertInput = {
  data: LoanBusinessProprietaryInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanBusinessProprietaryOnConflict>;
};

/** on conflict condition type for table "loan.business_proprietary" */
export type LoanBusinessProprietaryOnConflict = {
  constraint: LoanBusinessProprietaryConstraint;
  update_columns?: Array<LoanBusinessProprietaryUpdateColumn>;
  where?: Maybe<LoanBusinessProprietaryBoolExp>;
};

/** Ordering options when selecting data from "loan.business_proprietary". */
export type LoanBusinessProprietaryOrderBy = {
  application_headers_aggregate?: Maybe<LoanApplicationHeaderAggregateOrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  need_addition?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_business_proprietary */
export type LoanBusinessProprietaryPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "loan.business_proprietary" */
export enum LoanBusinessProprietarySelectColumn {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  NeedAddition = 'need_addition',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "loan.business_proprietary" */
export type LoanBusinessProprietarySetInput = {
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ลักษณะสถานประกอบการ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ระบุ เพิ่มเติม */
  need_addition?: Maybe<Scalars['Boolean']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type LoanBusinessProprietaryStddevFields = {
  __typename?: 'loan_business_proprietary_stddev_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LoanBusinessProprietaryStddevPopFields = {
  __typename?: 'loan_business_proprietary_stddev_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LoanBusinessProprietaryStddevSampFields = {
  __typename?: 'loan_business_proprietary_stddev_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LoanBusinessProprietarySumFields = {
  __typename?: 'loan_business_proprietary_sum_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** update columns of table "loan.business_proprietary" */
export enum LoanBusinessProprietaryUpdateColumn {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  NeedAddition = 'need_addition',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type LoanBusinessProprietaryVarPopFields = {
  __typename?: 'loan_business_proprietary_var_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LoanBusinessProprietaryVarSampFields = {
  __typename?: 'loan_business_proprietary_var_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LoanBusinessProprietaryVarianceFields = {
  __typename?: 'loan_business_proprietary_variance_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/**
 * สถานภาพการดำเนินธุรกิจ
 *
 *
 * columns and relationships of "loan.business_status"
 */
export type LoanBusinessStatus = {
  __typename?: 'loan_business_status';
  /** An array relationship */
  application_headers: Array<LoanApplicationHeader>;
  /** An aggregate relationship */
  application_headers_aggregate: LoanApplicationHeaderAggregate;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** สถานภาพการดำเนินธุรกิจ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** ลำดับที่ */
  sequence: Scalars['numeric'];
};


/**
 * สถานภาพการดำเนินธุรกิจ
 *
 *
 * columns and relationships of "loan.business_status"
 */
export type LoanBusinessStatusApplicationHeadersArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};


/**
 * สถานภาพการดำเนินธุรกิจ
 *
 *
 * columns and relationships of "loan.business_status"
 */
export type LoanBusinessStatusApplicationHeadersAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};

/** aggregated selection of "loan.business_status" */
export type LoanBusinessStatusAggregate = {
  __typename?: 'loan_business_status_aggregate';
  aggregate?: Maybe<LoanBusinessStatusAggregateFields>;
  nodes: Array<LoanBusinessStatus>;
};

/** aggregate fields of "loan.business_status" */
export type LoanBusinessStatusAggregateFields = {
  __typename?: 'loan_business_status_aggregate_fields';
  avg?: Maybe<LoanBusinessStatusAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanBusinessStatusMaxFields>;
  min?: Maybe<LoanBusinessStatusMinFields>;
  stddev?: Maybe<LoanBusinessStatusStddevFields>;
  stddev_pop?: Maybe<LoanBusinessStatusStddevPopFields>;
  stddev_samp?: Maybe<LoanBusinessStatusStddevSampFields>;
  sum?: Maybe<LoanBusinessStatusSumFields>;
  var_pop?: Maybe<LoanBusinessStatusVarPopFields>;
  var_samp?: Maybe<LoanBusinessStatusVarSampFields>;
  variance?: Maybe<LoanBusinessStatusVarianceFields>;
};


/** aggregate fields of "loan.business_status" */
export type LoanBusinessStatusAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanBusinessStatusSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LoanBusinessStatusAvgFields = {
  __typename?: 'loan_business_status_avg_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "loan.business_status". All fields are combined with a logical 'AND'. */
export type LoanBusinessStatusBoolExp = {
  _and?: Maybe<Array<LoanBusinessStatusBoolExp>>;
  _not?: Maybe<LoanBusinessStatusBoolExp>;
  _or?: Maybe<Array<LoanBusinessStatusBoolExp>>;
  application_headers?: Maybe<LoanApplicationHeaderBoolExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "loan.business_status" */
export enum LoanBusinessStatusConstraint {
  /** unique or primary key constraint */
  BusinessStatusPkey = 'business_status_pkey'
}

/** input type for incrementing numeric columns in table "loan.business_status" */
export type LoanBusinessStatusIncInput = {
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.business_status" */
export type LoanBusinessStatusInsertInput = {
  application_headers?: Maybe<LoanApplicationHeaderArrRelInsertInput>;
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** สถานภาพการดำเนินธุรกิจ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type LoanBusinessStatusMaxFields = {
  __typename?: 'loan_business_status_max_fields';
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** สถานภาพการดำเนินธุรกิจ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type LoanBusinessStatusMinFields = {
  __typename?: 'loan_business_status_min_fields';
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** สถานภาพการดำเนินธุรกิจ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "loan.business_status" */
export type LoanBusinessStatusMutationResponse = {
  __typename?: 'loan_business_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanBusinessStatus>;
};

/** input type for inserting object relation for remote table "loan.business_status" */
export type LoanBusinessStatusObjRelInsertInput = {
  data: LoanBusinessStatusInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanBusinessStatusOnConflict>;
};

/** on conflict condition type for table "loan.business_status" */
export type LoanBusinessStatusOnConflict = {
  constraint: LoanBusinessStatusConstraint;
  update_columns?: Array<LoanBusinessStatusUpdateColumn>;
  where?: Maybe<LoanBusinessStatusBoolExp>;
};

/** Ordering options when selecting data from "loan.business_status". */
export type LoanBusinessStatusOrderBy = {
  application_headers_aggregate?: Maybe<LoanApplicationHeaderAggregateOrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_business_status */
export type LoanBusinessStatusPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "loan.business_status" */
export enum LoanBusinessStatusSelectColumn {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "loan.business_status" */
export type LoanBusinessStatusSetInput = {
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** สถานภาพการดำเนินธุรกิจ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type LoanBusinessStatusStddevFields = {
  __typename?: 'loan_business_status_stddev_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LoanBusinessStatusStddevPopFields = {
  __typename?: 'loan_business_status_stddev_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LoanBusinessStatusStddevSampFields = {
  __typename?: 'loan_business_status_stddev_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LoanBusinessStatusSumFields = {
  __typename?: 'loan_business_status_sum_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** update columns of table "loan.business_status" */
export enum LoanBusinessStatusUpdateColumn {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type LoanBusinessStatusVarPopFields = {
  __typename?: 'loan_business_status_var_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LoanBusinessStatusVarSampFields = {
  __typename?: 'loan_business_status_var_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LoanBusinessStatusVarianceFields = {
  __typename?: 'loan_business_status_variance_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/**
 * ประเภทธุรกิจ
 *
 *
 * columns and relationships of "loan.business_type"
 */
export type LoanBusinessType = {
  __typename?: 'loan_business_type';
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active: Scalars['Boolean'];
  /** An array relationship */
  application_headers: Array<LoanApplicationHeader>;
  /** An aggregate relationship */
  application_headers_aggregate: LoanApplicationHeaderAggregate;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
  /** An object relationship */
  program?: Maybe<LoanProgram>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};


/**
 * ประเภทธุรกิจ
 *
 *
 * columns and relationships of "loan.business_type"
 */
export type LoanBusinessTypeApplicationHeadersArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};


/**
 * ประเภทธุรกิจ
 *
 *
 * columns and relationships of "loan.business_type"
 */
export type LoanBusinessTypeApplicationHeadersAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};

/** aggregated selection of "loan.business_type" */
export type LoanBusinessTypeAggregate = {
  __typename?: 'loan_business_type_aggregate';
  aggregate?: Maybe<LoanBusinessTypeAggregateFields>;
  nodes: Array<LoanBusinessType>;
};

/** aggregate fields of "loan.business_type" */
export type LoanBusinessTypeAggregateFields = {
  __typename?: 'loan_business_type_aggregate_fields';
  avg?: Maybe<LoanBusinessTypeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanBusinessTypeMaxFields>;
  min?: Maybe<LoanBusinessTypeMinFields>;
  stddev?: Maybe<LoanBusinessTypeStddevFields>;
  stddev_pop?: Maybe<LoanBusinessTypeStddevPopFields>;
  stddev_samp?: Maybe<LoanBusinessTypeStddevSampFields>;
  sum?: Maybe<LoanBusinessTypeSumFields>;
  var_pop?: Maybe<LoanBusinessTypeVarPopFields>;
  var_samp?: Maybe<LoanBusinessTypeVarSampFields>;
  variance?: Maybe<LoanBusinessTypeVarianceFields>;
};


/** aggregate fields of "loan.business_type" */
export type LoanBusinessTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanBusinessTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan.business_type" */
export type LoanBusinessTypeAggregateOrderBy = {
  avg?: Maybe<LoanBusinessTypeAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanBusinessTypeMaxOrderBy>;
  min?: Maybe<LoanBusinessTypeMinOrderBy>;
  stddev?: Maybe<LoanBusinessTypeStddevOrderBy>;
  stddev_pop?: Maybe<LoanBusinessTypeStddevPopOrderBy>;
  stddev_samp?: Maybe<LoanBusinessTypeStddevSampOrderBy>;
  sum?: Maybe<LoanBusinessTypeSumOrderBy>;
  var_pop?: Maybe<LoanBusinessTypeVarPopOrderBy>;
  var_samp?: Maybe<LoanBusinessTypeVarSampOrderBy>;
  variance?: Maybe<LoanBusinessTypeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "loan.business_type" */
export type LoanBusinessTypeArrRelInsertInput = {
  data: Array<LoanBusinessTypeInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanBusinessTypeOnConflict>;
};

/** aggregate avg on columns */
export type LoanBusinessTypeAvgFields = {
  __typename?: 'loan_business_type_avg_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "loan.business_type" */
export type LoanBusinessTypeAvgOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "loan.business_type". All fields are combined with a logical 'AND'. */
export type LoanBusinessTypeBoolExp = {
  _and?: Maybe<Array<LoanBusinessTypeBoolExp>>;
  _not?: Maybe<LoanBusinessTypeBoolExp>;
  _or?: Maybe<Array<LoanBusinessTypeBoolExp>>;
  active?: Maybe<BooleanComparisonExp>;
  application_headers?: Maybe<LoanApplicationHeaderBoolExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  program?: Maybe<LoanProgramBoolExp>;
  program_id?: Maybe<UuidComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "loan.business_type" */
export enum LoanBusinessTypeConstraint {
  /** unique or primary key constraint */
  BusinessTypePkey = 'business_type_pkey'
}

/** input type for incrementing numeric columns in table "loan.business_type" */
export type LoanBusinessTypeIncInput = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.business_type" */
export type LoanBusinessTypeInsertInput = {
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active?: Maybe<Scalars['Boolean']>;
  application_headers?: Maybe<LoanApplicationHeaderArrRelInsertInput>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  program?: Maybe<LoanProgramObjRelInsertInput>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type LoanBusinessTypeMaxFields = {
  __typename?: 'loan_business_type_max_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "loan.business_type" */
export type LoanBusinessTypeMaxOrderBy = {
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** ข้อความแสดงผล */
  description?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** รหัสโครงการ */
  program_id?: Maybe<OrderBy>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanBusinessTypeMinFields = {
  __typename?: 'loan_business_type_min_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "loan.business_type" */
export type LoanBusinessTypeMinOrderBy = {
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** ข้อความแสดงผล */
  description?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** รหัสโครงการ */
  program_id?: Maybe<OrderBy>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan.business_type" */
export type LoanBusinessTypeMutationResponse = {
  __typename?: 'loan_business_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanBusinessType>;
};

/** input type for inserting object relation for remote table "loan.business_type" */
export type LoanBusinessTypeObjRelInsertInput = {
  data: LoanBusinessTypeInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanBusinessTypeOnConflict>;
};

/** on conflict condition type for table "loan.business_type" */
export type LoanBusinessTypeOnConflict = {
  constraint: LoanBusinessTypeConstraint;
  update_columns?: Array<LoanBusinessTypeUpdateColumn>;
  where?: Maybe<LoanBusinessTypeBoolExp>;
};

/** Ordering options when selecting data from "loan.business_type". */
export type LoanBusinessTypeOrderBy = {
  active?: Maybe<OrderBy>;
  application_headers_aggregate?: Maybe<LoanApplicationHeaderAggregateOrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  program?: Maybe<LoanProgramOrderBy>;
  program_id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_business_type */
export type LoanBusinessTypePkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "loan.business_type" */
export enum LoanBusinessTypeSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "loan.business_type" */
export type LoanBusinessTypeSetInput = {
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active?: Maybe<Scalars['Boolean']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type LoanBusinessTypeStddevFields = {
  __typename?: 'loan_business_type_stddev_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "loan.business_type" */
export type LoanBusinessTypeStddevOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LoanBusinessTypeStddevPopFields = {
  __typename?: 'loan_business_type_stddev_pop_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "loan.business_type" */
export type LoanBusinessTypeStddevPopOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LoanBusinessTypeStddevSampFields = {
  __typename?: 'loan_business_type_stddev_samp_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "loan.business_type" */
export type LoanBusinessTypeStddevSampOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LoanBusinessTypeSumFields = {
  __typename?: 'loan_business_type_sum_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "loan.business_type" */
export type LoanBusinessTypeSumOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "loan.business_type" */
export enum LoanBusinessTypeUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type LoanBusinessTypeVarPopFields = {
  __typename?: 'loan_business_type_var_pop_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "loan.business_type" */
export type LoanBusinessTypeVarPopOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LoanBusinessTypeVarSampFields = {
  __typename?: 'loan_business_type_var_samp_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "loan.business_type" */
export type LoanBusinessTypeVarSampOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LoanBusinessTypeVarianceFields = {
  __typename?: 'loan_business_type_variance_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "loan.business_type" */
export type LoanBusinessTypeVarianceOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/**
 * สถานะพิจารณาคัดสรร กลั่นกรองคุณสมบัติ
 *
 *
 * columns and relationships of "loan.committee_approve"
 */
export type LoanCommitteeApprove = {
  __typename?: 'loan_committee_approve';
  /** An array relationship */
  application_event_logs: Array<LoanApplicationEventLog>;
  /** An aggregate relationship */
  application_event_logs_aggregate: LoanApplicationEventLogAggregate;
  /** An array relationship */
  application_headers: Array<LoanApplicationHeader>;
  /** An aggregate relationship */
  application_headers_aggregate: LoanApplicationHeaderAggregate;
  /** สถานะพิจารณาคัดสรร กลั่นกรองคุณสมบัติ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value: Scalars['String'];
};


/**
 * สถานะพิจารณาคัดสรร กลั่นกรองคุณสมบัติ
 *
 *
 * columns and relationships of "loan.committee_approve"
 */
export type LoanCommitteeApproveApplicationEventLogsArgs = {
  distinct_on?: Maybe<Array<LoanApplicationEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationEventLogOrderBy>>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
};


/**
 * สถานะพิจารณาคัดสรร กลั่นกรองคุณสมบัติ
 *
 *
 * columns and relationships of "loan.committee_approve"
 */
export type LoanCommitteeApproveApplicationEventLogsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationEventLogOrderBy>>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
};


/**
 * สถานะพิจารณาคัดสรร กลั่นกรองคุณสมบัติ
 *
 *
 * columns and relationships of "loan.committee_approve"
 */
export type LoanCommitteeApproveApplicationHeadersArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};


/**
 * สถานะพิจารณาคัดสรร กลั่นกรองคุณสมบัติ
 *
 *
 * columns and relationships of "loan.committee_approve"
 */
export type LoanCommitteeApproveApplicationHeadersAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};

/** aggregated selection of "loan.committee_approve" */
export type LoanCommitteeApproveAggregate = {
  __typename?: 'loan_committee_approve_aggregate';
  aggregate?: Maybe<LoanCommitteeApproveAggregateFields>;
  nodes: Array<LoanCommitteeApprove>;
};

/** aggregate fields of "loan.committee_approve" */
export type LoanCommitteeApproveAggregateFields = {
  __typename?: 'loan_committee_approve_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LoanCommitteeApproveMaxFields>;
  min?: Maybe<LoanCommitteeApproveMinFields>;
};


/** aggregate fields of "loan.committee_approve" */
export type LoanCommitteeApproveAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanCommitteeApproveSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "loan.committee_approve". All fields are combined with a logical 'AND'. */
export type LoanCommitteeApproveBoolExp = {
  _and?: Maybe<Array<LoanCommitteeApproveBoolExp>>;
  _not?: Maybe<LoanCommitteeApproveBoolExp>;
  _or?: Maybe<Array<LoanCommitteeApproveBoolExp>>;
  application_event_logs?: Maybe<LoanApplicationEventLogBoolExp>;
  application_headers?: Maybe<LoanApplicationHeaderBoolExp>;
  description?: Maybe<StringComparisonExp>;
  value?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "loan.committee_approve" */
export enum LoanCommitteeApproveConstraint {
  /** unique or primary key constraint */
  CommitteeApprovePkey = 'committee_approve_pkey'
}

export enum LoanCommitteeApproveEnum {
  /** ผ่านพิจารณาคัดสรร กลั่นกรองคุณสมบัติ */
  Approved = 'approved',
  /** แก้ไขเอกสารเพิ่มเติม */
  ChangeRequest = 'change_request',
  /** ไม่ผ่านพิจารณาคัดสรร กลั่นกรองคุณสมบัติ */
  Disapprove = 'disapprove'
}

/** Boolean expression to compare columns of type "loan_committee_approve_enum". All fields are combined with logical 'AND'. */
export type LoanCommitteeApproveEnumComparisonExp = {
  _eq?: Maybe<LoanCommitteeApproveEnum>;
  _in?: Maybe<Array<LoanCommitteeApproveEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<LoanCommitteeApproveEnum>;
  _nin?: Maybe<Array<LoanCommitteeApproveEnum>>;
};

/** input type for inserting data into table "loan.committee_approve" */
export type LoanCommitteeApproveInsertInput = {
  application_event_logs?: Maybe<LoanApplicationEventLogArrRelInsertInput>;
  application_headers?: Maybe<LoanApplicationHeaderArrRelInsertInput>;
  /** สถานะพิจารณาคัดสรร กลั่นกรองคุณสมบัติ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LoanCommitteeApproveMaxFields = {
  __typename?: 'loan_committee_approve_max_fields';
  /** สถานะพิจารณาคัดสรร กลั่นกรองคุณสมบัติ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type LoanCommitteeApproveMinFields = {
  __typename?: 'loan_committee_approve_min_fields';
  /** สถานะพิจารณาคัดสรร กลั่นกรองคุณสมบัติ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "loan.committee_approve" */
export type LoanCommitteeApproveMutationResponse = {
  __typename?: 'loan_committee_approve_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanCommitteeApprove>;
};

/** input type for inserting object relation for remote table "loan.committee_approve" */
export type LoanCommitteeApproveObjRelInsertInput = {
  data: LoanCommitteeApproveInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanCommitteeApproveOnConflict>;
};

/** on conflict condition type for table "loan.committee_approve" */
export type LoanCommitteeApproveOnConflict = {
  constraint: LoanCommitteeApproveConstraint;
  update_columns?: Array<LoanCommitteeApproveUpdateColumn>;
  where?: Maybe<LoanCommitteeApproveBoolExp>;
};

/** Ordering options when selecting data from "loan.committee_approve". */
export type LoanCommitteeApproveOrderBy = {
  application_event_logs_aggregate?: Maybe<LoanApplicationEventLogAggregateOrderBy>;
  application_headers_aggregate?: Maybe<LoanApplicationHeaderAggregateOrderBy>;
  description?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_committee_approve */
export type LoanCommitteeApprovePkColumnsInput = {
  /** Primary key */
  value: Scalars['String'];
};

/** select columns of table "loan.committee_approve" */
export enum LoanCommitteeApproveSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "loan.committee_approve" */
export type LoanCommitteeApproveSetInput = {
  /** สถานะพิจารณาคัดสรร กลั่นกรองคุณสมบัติ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "loan.committee_approve" */
export enum LoanCommitteeApproveUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/**
 * มีวงเงินสินเชื่อ (มี/ไม่มี/อื่นๆ)
 *
 *
 * columns and relationships of "loan.current_loan"
 */
export type LoanCurrentLoan = {
  __typename?: 'loan_current_loan';
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active: Scalars['Boolean'];
  /** An array relationship */
  application_details: Array<LoanApplicationDetail>;
  /** An aggregate relationship */
  application_details_aggregate: LoanApplicationDetailAggregate;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
  /** flag ระบุเพิ่มเติม เพื่อให้ frontend เช็ค */
  need_addition: Scalars['Boolean'];
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};


/**
 * มีวงเงินสินเชื่อ (มี/ไม่มี/อื่นๆ)
 *
 *
 * columns and relationships of "loan.current_loan"
 */
export type LoanCurrentLoanApplicationDetailsArgs = {
  distinct_on?: Maybe<Array<LoanApplicationDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationDetailOrderBy>>;
  where?: Maybe<LoanApplicationDetailBoolExp>;
};


/**
 * มีวงเงินสินเชื่อ (มี/ไม่มี/อื่นๆ)
 *
 *
 * columns and relationships of "loan.current_loan"
 */
export type LoanCurrentLoanApplicationDetailsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationDetailOrderBy>>;
  where?: Maybe<LoanApplicationDetailBoolExp>;
};

/** aggregated selection of "loan.current_loan" */
export type LoanCurrentLoanAggregate = {
  __typename?: 'loan_current_loan_aggregate';
  aggregate?: Maybe<LoanCurrentLoanAggregateFields>;
  nodes: Array<LoanCurrentLoan>;
};

/** aggregate fields of "loan.current_loan" */
export type LoanCurrentLoanAggregateFields = {
  __typename?: 'loan_current_loan_aggregate_fields';
  avg?: Maybe<LoanCurrentLoanAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanCurrentLoanMaxFields>;
  min?: Maybe<LoanCurrentLoanMinFields>;
  stddev?: Maybe<LoanCurrentLoanStddevFields>;
  stddev_pop?: Maybe<LoanCurrentLoanStddevPopFields>;
  stddev_samp?: Maybe<LoanCurrentLoanStddevSampFields>;
  sum?: Maybe<LoanCurrentLoanSumFields>;
  var_pop?: Maybe<LoanCurrentLoanVarPopFields>;
  var_samp?: Maybe<LoanCurrentLoanVarSampFields>;
  variance?: Maybe<LoanCurrentLoanVarianceFields>;
};


/** aggregate fields of "loan.current_loan" */
export type LoanCurrentLoanAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanCurrentLoanSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LoanCurrentLoanAvgFields = {
  __typename?: 'loan_current_loan_avg_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "loan.current_loan". All fields are combined with a logical 'AND'. */
export type LoanCurrentLoanBoolExp = {
  _and?: Maybe<Array<LoanCurrentLoanBoolExp>>;
  _not?: Maybe<LoanCurrentLoanBoolExp>;
  _or?: Maybe<Array<LoanCurrentLoanBoolExp>>;
  active?: Maybe<BooleanComparisonExp>;
  application_details?: Maybe<LoanApplicationDetailBoolExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  need_addition?: Maybe<BooleanComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "loan.current_loan" */
export enum LoanCurrentLoanConstraint {
  /** unique or primary key constraint */
  CurrentLoanPkey = 'current_loan_pkey'
}

/** input type for incrementing numeric columns in table "loan.current_loan" */
export type LoanCurrentLoanIncInput = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.current_loan" */
export type LoanCurrentLoanInsertInput = {
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active?: Maybe<Scalars['Boolean']>;
  application_details?: Maybe<LoanApplicationDetailArrRelInsertInput>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** flag ระบุเพิ่มเติม เพื่อให้ frontend เช็ค */
  need_addition?: Maybe<Scalars['Boolean']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type LoanCurrentLoanMaxFields = {
  __typename?: 'loan_current_loan_max_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type LoanCurrentLoanMinFields = {
  __typename?: 'loan_current_loan_min_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "loan.current_loan" */
export type LoanCurrentLoanMutationResponse = {
  __typename?: 'loan_current_loan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanCurrentLoan>;
};

/** input type for inserting object relation for remote table "loan.current_loan" */
export type LoanCurrentLoanObjRelInsertInput = {
  data: LoanCurrentLoanInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanCurrentLoanOnConflict>;
};

/** on conflict condition type for table "loan.current_loan" */
export type LoanCurrentLoanOnConflict = {
  constraint: LoanCurrentLoanConstraint;
  update_columns?: Array<LoanCurrentLoanUpdateColumn>;
  where?: Maybe<LoanCurrentLoanBoolExp>;
};

/** Ordering options when selecting data from "loan.current_loan". */
export type LoanCurrentLoanOrderBy = {
  active?: Maybe<OrderBy>;
  application_details_aggregate?: Maybe<LoanApplicationDetailAggregateOrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  need_addition?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_current_loan */
export type LoanCurrentLoanPkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "loan.current_loan" */
export enum LoanCurrentLoanSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  NeedAddition = 'need_addition',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "loan.current_loan" */
export type LoanCurrentLoanSetInput = {
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active?: Maybe<Scalars['Boolean']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** flag ระบุเพิ่มเติม เพื่อให้ frontend เช็ค */
  need_addition?: Maybe<Scalars['Boolean']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type LoanCurrentLoanStddevFields = {
  __typename?: 'loan_current_loan_stddev_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LoanCurrentLoanStddevPopFields = {
  __typename?: 'loan_current_loan_stddev_pop_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LoanCurrentLoanStddevSampFields = {
  __typename?: 'loan_current_loan_stddev_samp_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LoanCurrentLoanSumFields = {
  __typename?: 'loan_current_loan_sum_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** update columns of table "loan.current_loan" */
export enum LoanCurrentLoanUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  NeedAddition = 'need_addition',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type LoanCurrentLoanVarPopFields = {
  __typename?: 'loan_current_loan_var_pop_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LoanCurrentLoanVarSampFields = {
  __typename?: 'loan_current_loan_var_samp_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LoanCurrentLoanVarianceFields = {
  __typename?: 'loan_current_loan_variance_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/**
 * ปรับเงื่อนไขการชำระหนี้
 *
 *
 * columns and relationships of "loan.debt_restructuring"
 */
export type LoanDebtRestructuring = {
  __typename?: 'loan_debt_restructuring';
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active: Scalars['Boolean'];
  /** An array relationship */
  application_details: Array<LoanApplicationDetail>;
  /** An aggregate relationship */
  application_details_aggregate: LoanApplicationDetailAggregate;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};


/**
 * ปรับเงื่อนไขการชำระหนี้
 *
 *
 * columns and relationships of "loan.debt_restructuring"
 */
export type LoanDebtRestructuringApplicationDetailsArgs = {
  distinct_on?: Maybe<Array<LoanApplicationDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationDetailOrderBy>>;
  where?: Maybe<LoanApplicationDetailBoolExp>;
};


/**
 * ปรับเงื่อนไขการชำระหนี้
 *
 *
 * columns and relationships of "loan.debt_restructuring"
 */
export type LoanDebtRestructuringApplicationDetailsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationDetailOrderBy>>;
  where?: Maybe<LoanApplicationDetailBoolExp>;
};

/** aggregated selection of "loan.debt_restructuring" */
export type LoanDebtRestructuringAggregate = {
  __typename?: 'loan_debt_restructuring_aggregate';
  aggregate?: Maybe<LoanDebtRestructuringAggregateFields>;
  nodes: Array<LoanDebtRestructuring>;
};

/** aggregate fields of "loan.debt_restructuring" */
export type LoanDebtRestructuringAggregateFields = {
  __typename?: 'loan_debt_restructuring_aggregate_fields';
  avg?: Maybe<LoanDebtRestructuringAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanDebtRestructuringMaxFields>;
  min?: Maybe<LoanDebtRestructuringMinFields>;
  stddev?: Maybe<LoanDebtRestructuringStddevFields>;
  stddev_pop?: Maybe<LoanDebtRestructuringStddevPopFields>;
  stddev_samp?: Maybe<LoanDebtRestructuringStddevSampFields>;
  sum?: Maybe<LoanDebtRestructuringSumFields>;
  var_pop?: Maybe<LoanDebtRestructuringVarPopFields>;
  var_samp?: Maybe<LoanDebtRestructuringVarSampFields>;
  variance?: Maybe<LoanDebtRestructuringVarianceFields>;
};


/** aggregate fields of "loan.debt_restructuring" */
export type LoanDebtRestructuringAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanDebtRestructuringSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LoanDebtRestructuringAvgFields = {
  __typename?: 'loan_debt_restructuring_avg_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "loan.debt_restructuring". All fields are combined with a logical 'AND'. */
export type LoanDebtRestructuringBoolExp = {
  _and?: Maybe<Array<LoanDebtRestructuringBoolExp>>;
  _not?: Maybe<LoanDebtRestructuringBoolExp>;
  _or?: Maybe<Array<LoanDebtRestructuringBoolExp>>;
  active?: Maybe<BooleanComparisonExp>;
  application_details?: Maybe<LoanApplicationDetailBoolExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "loan.debt_restructuring" */
export enum LoanDebtRestructuringConstraint {
  /** unique or primary key constraint */
  DebtRestructuringPkey = 'debt_restructuring_pkey'
}

/** input type for incrementing numeric columns in table "loan.debt_restructuring" */
export type LoanDebtRestructuringIncInput = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.debt_restructuring" */
export type LoanDebtRestructuringInsertInput = {
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active?: Maybe<Scalars['Boolean']>;
  application_details?: Maybe<LoanApplicationDetailArrRelInsertInput>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type LoanDebtRestructuringMaxFields = {
  __typename?: 'loan_debt_restructuring_max_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type LoanDebtRestructuringMinFields = {
  __typename?: 'loan_debt_restructuring_min_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "loan.debt_restructuring" */
export type LoanDebtRestructuringMutationResponse = {
  __typename?: 'loan_debt_restructuring_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanDebtRestructuring>;
};

/** input type for inserting object relation for remote table "loan.debt_restructuring" */
export type LoanDebtRestructuringObjRelInsertInput = {
  data: LoanDebtRestructuringInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanDebtRestructuringOnConflict>;
};

/** on conflict condition type for table "loan.debt_restructuring" */
export type LoanDebtRestructuringOnConflict = {
  constraint: LoanDebtRestructuringConstraint;
  update_columns?: Array<LoanDebtRestructuringUpdateColumn>;
  where?: Maybe<LoanDebtRestructuringBoolExp>;
};

/** Ordering options when selecting data from "loan.debt_restructuring". */
export type LoanDebtRestructuringOrderBy = {
  active?: Maybe<OrderBy>;
  application_details_aggregate?: Maybe<LoanApplicationDetailAggregateOrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_debt_restructuring */
export type LoanDebtRestructuringPkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "loan.debt_restructuring" */
export enum LoanDebtRestructuringSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "loan.debt_restructuring" */
export type LoanDebtRestructuringSetInput = {
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active?: Maybe<Scalars['Boolean']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type LoanDebtRestructuringStddevFields = {
  __typename?: 'loan_debt_restructuring_stddev_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LoanDebtRestructuringStddevPopFields = {
  __typename?: 'loan_debt_restructuring_stddev_pop_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LoanDebtRestructuringStddevSampFields = {
  __typename?: 'loan_debt_restructuring_stddev_samp_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LoanDebtRestructuringSumFields = {
  __typename?: 'loan_debt_restructuring_sum_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** update columns of table "loan.debt_restructuring" */
export enum LoanDebtRestructuringUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type LoanDebtRestructuringVarPopFields = {
  __typename?: 'loan_debt_restructuring_var_pop_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LoanDebtRestructuringVarSampFields = {
  __typename?: 'loan_debt_restructuring_var_samp_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LoanDebtRestructuringVarianceFields = {
  __typename?: 'loan_debt_restructuring_variance_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/**
 * สถานะตรวจสอบเอกสาร คุณสมบัติเบื้องต้น
 *
 *
 * columns and relationships of "loan.document_approve"
 */
export type LoanDocumentApprove = {
  __typename?: 'loan_document_approve';
  /** An array relationship */
  application_event_logs: Array<LoanApplicationEventLog>;
  /** An aggregate relationship */
  application_event_logs_aggregate: LoanApplicationEventLogAggregate;
  /** An array relationship */
  application_headers: Array<LoanApplicationHeader>;
  /** An aggregate relationship */
  application_headers_aggregate: LoanApplicationHeaderAggregate;
  /** สถานะตรวจสอบเอกสาร คุณสมบัติเบื้องต้น */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value: Scalars['String'];
};


/**
 * สถานะตรวจสอบเอกสาร คุณสมบัติเบื้องต้น
 *
 *
 * columns and relationships of "loan.document_approve"
 */
export type LoanDocumentApproveApplicationEventLogsArgs = {
  distinct_on?: Maybe<Array<LoanApplicationEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationEventLogOrderBy>>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
};


/**
 * สถานะตรวจสอบเอกสาร คุณสมบัติเบื้องต้น
 *
 *
 * columns and relationships of "loan.document_approve"
 */
export type LoanDocumentApproveApplicationEventLogsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationEventLogOrderBy>>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
};


/**
 * สถานะตรวจสอบเอกสาร คุณสมบัติเบื้องต้น
 *
 *
 * columns and relationships of "loan.document_approve"
 */
export type LoanDocumentApproveApplicationHeadersArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};


/**
 * สถานะตรวจสอบเอกสาร คุณสมบัติเบื้องต้น
 *
 *
 * columns and relationships of "loan.document_approve"
 */
export type LoanDocumentApproveApplicationHeadersAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};

/** aggregated selection of "loan.document_approve" */
export type LoanDocumentApproveAggregate = {
  __typename?: 'loan_document_approve_aggregate';
  aggregate?: Maybe<LoanDocumentApproveAggregateFields>;
  nodes: Array<LoanDocumentApprove>;
};

/** aggregate fields of "loan.document_approve" */
export type LoanDocumentApproveAggregateFields = {
  __typename?: 'loan_document_approve_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LoanDocumentApproveMaxFields>;
  min?: Maybe<LoanDocumentApproveMinFields>;
};


/** aggregate fields of "loan.document_approve" */
export type LoanDocumentApproveAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanDocumentApproveSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "loan.document_approve". All fields are combined with a logical 'AND'. */
export type LoanDocumentApproveBoolExp = {
  _and?: Maybe<Array<LoanDocumentApproveBoolExp>>;
  _not?: Maybe<LoanDocumentApproveBoolExp>;
  _or?: Maybe<Array<LoanDocumentApproveBoolExp>>;
  application_event_logs?: Maybe<LoanApplicationEventLogBoolExp>;
  application_headers?: Maybe<LoanApplicationHeaderBoolExp>;
  description?: Maybe<StringComparisonExp>;
  value?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "loan.document_approve" */
export enum LoanDocumentApproveConstraint {
  /** unique or primary key constraint */
  DocumentApprovePkey = 'document_approve_pkey'
}

export enum LoanDocumentApproveEnum {
  /** ผ่านคุณสมบัติเบื้องต้น */
  Approved = 'approved',
  /** แก้ไขเอกสารเพิ่มเติม */
  ChangeRequest = 'change_request',
  /** ไม่ผ่านคุณสมบัติเบื้องต้น */
  Disapprove = 'disapprove'
}

/** Boolean expression to compare columns of type "loan_document_approve_enum". All fields are combined with logical 'AND'. */
export type LoanDocumentApproveEnumComparisonExp = {
  _eq?: Maybe<LoanDocumentApproveEnum>;
  _in?: Maybe<Array<LoanDocumentApproveEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<LoanDocumentApproveEnum>;
  _nin?: Maybe<Array<LoanDocumentApproveEnum>>;
};

/** input type for inserting data into table "loan.document_approve" */
export type LoanDocumentApproveInsertInput = {
  application_event_logs?: Maybe<LoanApplicationEventLogArrRelInsertInput>;
  application_headers?: Maybe<LoanApplicationHeaderArrRelInsertInput>;
  /** สถานะตรวจสอบเอกสาร คุณสมบัติเบื้องต้น */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LoanDocumentApproveMaxFields = {
  __typename?: 'loan_document_approve_max_fields';
  /** สถานะตรวจสอบเอกสาร คุณสมบัติเบื้องต้น */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type LoanDocumentApproveMinFields = {
  __typename?: 'loan_document_approve_min_fields';
  /** สถานะตรวจสอบเอกสาร คุณสมบัติเบื้องต้น */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "loan.document_approve" */
export type LoanDocumentApproveMutationResponse = {
  __typename?: 'loan_document_approve_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanDocumentApprove>;
};

/** input type for inserting object relation for remote table "loan.document_approve" */
export type LoanDocumentApproveObjRelInsertInput = {
  data: LoanDocumentApproveInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanDocumentApproveOnConflict>;
};

/** on conflict condition type for table "loan.document_approve" */
export type LoanDocumentApproveOnConflict = {
  constraint: LoanDocumentApproveConstraint;
  update_columns?: Array<LoanDocumentApproveUpdateColumn>;
  where?: Maybe<LoanDocumentApproveBoolExp>;
};

/** Ordering options when selecting data from "loan.document_approve". */
export type LoanDocumentApproveOrderBy = {
  application_event_logs_aggregate?: Maybe<LoanApplicationEventLogAggregateOrderBy>;
  application_headers_aggregate?: Maybe<LoanApplicationHeaderAggregateOrderBy>;
  description?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_document_approve */
export type LoanDocumentApprovePkColumnsInput = {
  /** Primary key */
  value: Scalars['String'];
};

/** select columns of table "loan.document_approve" */
export enum LoanDocumentApproveSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "loan.document_approve" */
export type LoanDocumentApproveSetInput = {
  /** สถานะตรวจสอบเอกสาร คุณสมบัติเบื้องต้น */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "loan.document_approve" */
export enum LoanDocumentApproveUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/**
 * การนำส่งข้อมูลทางบัญชี
 *
 *
 * columns and relationships of "loan.financial_agreement"
 */
export type LoanFinancialAgreement = {
  __typename?: 'loan_financial_agreement';
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active: Scalars['Boolean'];
  /** An array relationship */
  application_details: Array<LoanApplicationDetail>;
  /** An aggregate relationship */
  application_details_aggregate: LoanApplicationDetailAggregate;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};


/**
 * การนำส่งข้อมูลทางบัญชี
 *
 *
 * columns and relationships of "loan.financial_agreement"
 */
export type LoanFinancialAgreementApplicationDetailsArgs = {
  distinct_on?: Maybe<Array<LoanApplicationDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationDetailOrderBy>>;
  where?: Maybe<LoanApplicationDetailBoolExp>;
};


/**
 * การนำส่งข้อมูลทางบัญชี
 *
 *
 * columns and relationships of "loan.financial_agreement"
 */
export type LoanFinancialAgreementApplicationDetailsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationDetailOrderBy>>;
  where?: Maybe<LoanApplicationDetailBoolExp>;
};

/** aggregated selection of "loan.financial_agreement" */
export type LoanFinancialAgreementAggregate = {
  __typename?: 'loan_financial_agreement_aggregate';
  aggregate?: Maybe<LoanFinancialAgreementAggregateFields>;
  nodes: Array<LoanFinancialAgreement>;
};

/** aggregate fields of "loan.financial_agreement" */
export type LoanFinancialAgreementAggregateFields = {
  __typename?: 'loan_financial_agreement_aggregate_fields';
  avg?: Maybe<LoanFinancialAgreementAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanFinancialAgreementMaxFields>;
  min?: Maybe<LoanFinancialAgreementMinFields>;
  stddev?: Maybe<LoanFinancialAgreementStddevFields>;
  stddev_pop?: Maybe<LoanFinancialAgreementStddevPopFields>;
  stddev_samp?: Maybe<LoanFinancialAgreementStddevSampFields>;
  sum?: Maybe<LoanFinancialAgreementSumFields>;
  var_pop?: Maybe<LoanFinancialAgreementVarPopFields>;
  var_samp?: Maybe<LoanFinancialAgreementVarSampFields>;
  variance?: Maybe<LoanFinancialAgreementVarianceFields>;
};


/** aggregate fields of "loan.financial_agreement" */
export type LoanFinancialAgreementAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanFinancialAgreementSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LoanFinancialAgreementAvgFields = {
  __typename?: 'loan_financial_agreement_avg_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "loan.financial_agreement". All fields are combined with a logical 'AND'. */
export type LoanFinancialAgreementBoolExp = {
  _and?: Maybe<Array<LoanFinancialAgreementBoolExp>>;
  _not?: Maybe<LoanFinancialAgreementBoolExp>;
  _or?: Maybe<Array<LoanFinancialAgreementBoolExp>>;
  active?: Maybe<BooleanComparisonExp>;
  application_details?: Maybe<LoanApplicationDetailBoolExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "loan.financial_agreement" */
export enum LoanFinancialAgreementConstraint {
  /** unique or primary key constraint */
  FinancialAgreementPkey = 'financial_agreement_pkey'
}

/** input type for incrementing numeric columns in table "loan.financial_agreement" */
export type LoanFinancialAgreementIncInput = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.financial_agreement" */
export type LoanFinancialAgreementInsertInput = {
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active?: Maybe<Scalars['Boolean']>;
  application_details?: Maybe<LoanApplicationDetailArrRelInsertInput>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type LoanFinancialAgreementMaxFields = {
  __typename?: 'loan_financial_agreement_max_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type LoanFinancialAgreementMinFields = {
  __typename?: 'loan_financial_agreement_min_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "loan.financial_agreement" */
export type LoanFinancialAgreementMutationResponse = {
  __typename?: 'loan_financial_agreement_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanFinancialAgreement>;
};

/** input type for inserting object relation for remote table "loan.financial_agreement" */
export type LoanFinancialAgreementObjRelInsertInput = {
  data: LoanFinancialAgreementInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanFinancialAgreementOnConflict>;
};

/** on conflict condition type for table "loan.financial_agreement" */
export type LoanFinancialAgreementOnConflict = {
  constraint: LoanFinancialAgreementConstraint;
  update_columns?: Array<LoanFinancialAgreementUpdateColumn>;
  where?: Maybe<LoanFinancialAgreementBoolExp>;
};

/** Ordering options when selecting data from "loan.financial_agreement". */
export type LoanFinancialAgreementOrderBy = {
  active?: Maybe<OrderBy>;
  application_details_aggregate?: Maybe<LoanApplicationDetailAggregateOrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_financial_agreement */
export type LoanFinancialAgreementPkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "loan.financial_agreement" */
export enum LoanFinancialAgreementSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "loan.financial_agreement" */
export type LoanFinancialAgreementSetInput = {
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active?: Maybe<Scalars['Boolean']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type LoanFinancialAgreementStddevFields = {
  __typename?: 'loan_financial_agreement_stddev_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LoanFinancialAgreementStddevPopFields = {
  __typename?: 'loan_financial_agreement_stddev_pop_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LoanFinancialAgreementStddevSampFields = {
  __typename?: 'loan_financial_agreement_stddev_samp_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LoanFinancialAgreementSumFields = {
  __typename?: 'loan_financial_agreement_sum_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** update columns of table "loan.financial_agreement" */
export enum LoanFinancialAgreementUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type LoanFinancialAgreementVarPopFields = {
  __typename?: 'loan_financial_agreement_var_pop_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LoanFinancialAgreementVarSampFields = {
  __typename?: 'loan_financial_agreement_var_samp_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LoanFinancialAgreementVarianceFields = {
  __typename?: 'loan_financial_agreement_variance_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/**
 * การได้รับความช่วยเหลือด้านการเงิน
 *
 *
 * columns and relationships of "loan.financial_rescue"
 */
export type LoanFinancialRescue = {
  __typename?: 'loan_financial_rescue';
  /** true = ใช้งาน, false = ไม่ใช้งาน */
  active: Scalars['Boolean'];
  /** An array relationship */
  application_details: Array<LoanApplicationDetail>;
  /** An aggregate relationship */
  application_details_aggregate: LoanApplicationDetailAggregate;
  /** An array relationship */
  children: Array<LoanFinancialRescue>;
  /** An aggregate relationship */
  children_aggregate: LoanFinancialRescueAggregate;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** การได้รับความช่วยเหลือด้านการเงิน */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
  /** An object relationship */
  parent?: Maybe<LoanFinancialRescue>;
  /** Parent Id */
  parent_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  program?: Maybe<LoanProgram>;
  /** รหัสโครงการ ref:> loan.program.id */
  program_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};


/**
 * การได้รับความช่วยเหลือด้านการเงิน
 *
 *
 * columns and relationships of "loan.financial_rescue"
 */
export type LoanFinancialRescueApplicationDetailsArgs = {
  distinct_on?: Maybe<Array<LoanApplicationDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationDetailOrderBy>>;
  where?: Maybe<LoanApplicationDetailBoolExp>;
};


/**
 * การได้รับความช่วยเหลือด้านการเงิน
 *
 *
 * columns and relationships of "loan.financial_rescue"
 */
export type LoanFinancialRescueApplicationDetailsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationDetailOrderBy>>;
  where?: Maybe<LoanApplicationDetailBoolExp>;
};


/**
 * การได้รับความช่วยเหลือด้านการเงิน
 *
 *
 * columns and relationships of "loan.financial_rescue"
 */
export type LoanFinancialRescueChildrenArgs = {
  distinct_on?: Maybe<Array<LoanFinancialRescueSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanFinancialRescueOrderBy>>;
  where?: Maybe<LoanFinancialRescueBoolExp>;
};


/**
 * การได้รับความช่วยเหลือด้านการเงิน
 *
 *
 * columns and relationships of "loan.financial_rescue"
 */
export type LoanFinancialRescueChildrenAggregateArgs = {
  distinct_on?: Maybe<Array<LoanFinancialRescueSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanFinancialRescueOrderBy>>;
  where?: Maybe<LoanFinancialRescueBoolExp>;
};

/** aggregated selection of "loan.financial_rescue" */
export type LoanFinancialRescueAggregate = {
  __typename?: 'loan_financial_rescue_aggregate';
  aggregate?: Maybe<LoanFinancialRescueAggregateFields>;
  nodes: Array<LoanFinancialRescue>;
};

/** aggregate fields of "loan.financial_rescue" */
export type LoanFinancialRescueAggregateFields = {
  __typename?: 'loan_financial_rescue_aggregate_fields';
  avg?: Maybe<LoanFinancialRescueAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanFinancialRescueMaxFields>;
  min?: Maybe<LoanFinancialRescueMinFields>;
  stddev?: Maybe<LoanFinancialRescueStddevFields>;
  stddev_pop?: Maybe<LoanFinancialRescueStddevPopFields>;
  stddev_samp?: Maybe<LoanFinancialRescueStddevSampFields>;
  sum?: Maybe<LoanFinancialRescueSumFields>;
  var_pop?: Maybe<LoanFinancialRescueVarPopFields>;
  var_samp?: Maybe<LoanFinancialRescueVarSampFields>;
  variance?: Maybe<LoanFinancialRescueVarianceFields>;
};


/** aggregate fields of "loan.financial_rescue" */
export type LoanFinancialRescueAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanFinancialRescueSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan.financial_rescue" */
export type LoanFinancialRescueAggregateOrderBy = {
  avg?: Maybe<LoanFinancialRescueAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanFinancialRescueMaxOrderBy>;
  min?: Maybe<LoanFinancialRescueMinOrderBy>;
  stddev?: Maybe<LoanFinancialRescueStddevOrderBy>;
  stddev_pop?: Maybe<LoanFinancialRescueStddevPopOrderBy>;
  stddev_samp?: Maybe<LoanFinancialRescueStddevSampOrderBy>;
  sum?: Maybe<LoanFinancialRescueSumOrderBy>;
  var_pop?: Maybe<LoanFinancialRescueVarPopOrderBy>;
  var_samp?: Maybe<LoanFinancialRescueVarSampOrderBy>;
  variance?: Maybe<LoanFinancialRescueVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "loan.financial_rescue" */
export type LoanFinancialRescueArrRelInsertInput = {
  data: Array<LoanFinancialRescueInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanFinancialRescueOnConflict>;
};

/** aggregate avg on columns */
export type LoanFinancialRescueAvgFields = {
  __typename?: 'loan_financial_rescue_avg_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "loan.financial_rescue" */
export type LoanFinancialRescueAvgOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "loan.financial_rescue". All fields are combined with a logical 'AND'. */
export type LoanFinancialRescueBoolExp = {
  _and?: Maybe<Array<LoanFinancialRescueBoolExp>>;
  _not?: Maybe<LoanFinancialRescueBoolExp>;
  _or?: Maybe<Array<LoanFinancialRescueBoolExp>>;
  active?: Maybe<BooleanComparisonExp>;
  application_details?: Maybe<LoanApplicationDetailBoolExp>;
  children?: Maybe<LoanFinancialRescueBoolExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  parent?: Maybe<LoanFinancialRescueBoolExp>;
  parent_id?: Maybe<StringComparisonExp>;
  program?: Maybe<LoanProgramBoolExp>;
  program_id?: Maybe<UuidComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "loan.financial_rescue" */
export enum LoanFinancialRescueConstraint {
  /** unique or primary key constraint */
  FinancialRescuePkey = 'financial_rescue_pkey'
}

/** input type for incrementing numeric columns in table "loan.financial_rescue" */
export type LoanFinancialRescueIncInput = {
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.financial_rescue" */
export type LoanFinancialRescueInsertInput = {
  /** true = ใช้งาน, false = ไม่ใช้งาน */
  active?: Maybe<Scalars['Boolean']>;
  application_details?: Maybe<LoanApplicationDetailArrRelInsertInput>;
  children?: Maybe<LoanFinancialRescueArrRelInsertInput>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** การได้รับความช่วยเหลือด้านการเงิน */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  parent?: Maybe<LoanFinancialRescueObjRelInsertInput>;
  /** Parent Id */
  parent_id?: Maybe<Scalars['String']>;
  program?: Maybe<LoanProgramObjRelInsertInput>;
  /** รหัสโครงการ ref:> loan.program.id */
  program_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type LoanFinancialRescueMaxFields = {
  __typename?: 'loan_financial_rescue_max_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** การได้รับความช่วยเหลือด้านการเงิน */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** Parent Id */
  parent_id?: Maybe<Scalars['String']>;
  /** รหัสโครงการ ref:> loan.program.id */
  program_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "loan.financial_rescue" */
export type LoanFinancialRescueMaxOrderBy = {
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** การได้รับความช่วยเหลือด้านการเงิน */
  description?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** Parent Id */
  parent_id?: Maybe<OrderBy>;
  /** รหัสโครงการ ref:> loan.program.id */
  program_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanFinancialRescueMinFields = {
  __typename?: 'loan_financial_rescue_min_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** การได้รับความช่วยเหลือด้านการเงิน */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** Parent Id */
  parent_id?: Maybe<Scalars['String']>;
  /** รหัสโครงการ ref:> loan.program.id */
  program_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "loan.financial_rescue" */
export type LoanFinancialRescueMinOrderBy = {
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** การได้รับความช่วยเหลือด้านการเงิน */
  description?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** Parent Id */
  parent_id?: Maybe<OrderBy>;
  /** รหัสโครงการ ref:> loan.program.id */
  program_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan.financial_rescue" */
export type LoanFinancialRescueMutationResponse = {
  __typename?: 'loan_financial_rescue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanFinancialRescue>;
};

/** input type for inserting object relation for remote table "loan.financial_rescue" */
export type LoanFinancialRescueObjRelInsertInput = {
  data: LoanFinancialRescueInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanFinancialRescueOnConflict>;
};

/** on conflict condition type for table "loan.financial_rescue" */
export type LoanFinancialRescueOnConflict = {
  constraint: LoanFinancialRescueConstraint;
  update_columns?: Array<LoanFinancialRescueUpdateColumn>;
  where?: Maybe<LoanFinancialRescueBoolExp>;
};

/** Ordering options when selecting data from "loan.financial_rescue". */
export type LoanFinancialRescueOrderBy = {
  active?: Maybe<OrderBy>;
  application_details_aggregate?: Maybe<LoanApplicationDetailAggregateOrderBy>;
  children_aggregate?: Maybe<LoanFinancialRescueAggregateOrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  parent?: Maybe<LoanFinancialRescueOrderBy>;
  parent_id?: Maybe<OrderBy>;
  program?: Maybe<LoanProgramOrderBy>;
  program_id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_financial_rescue */
export type LoanFinancialRescuePkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "loan.financial_rescue" */
export enum LoanFinancialRescueSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "loan.financial_rescue" */
export type LoanFinancialRescueSetInput = {
  /** true = ใช้งาน, false = ไม่ใช้งาน */
  active?: Maybe<Scalars['Boolean']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** การได้รับความช่วยเหลือด้านการเงิน */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** Parent Id */
  parent_id?: Maybe<Scalars['String']>;
  /** รหัสโครงการ ref:> loan.program.id */
  program_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type LoanFinancialRescueStddevFields = {
  __typename?: 'loan_financial_rescue_stddev_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "loan.financial_rescue" */
export type LoanFinancialRescueStddevOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LoanFinancialRescueStddevPopFields = {
  __typename?: 'loan_financial_rescue_stddev_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "loan.financial_rescue" */
export type LoanFinancialRescueStddevPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LoanFinancialRescueStddevSampFields = {
  __typename?: 'loan_financial_rescue_stddev_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "loan.financial_rescue" */
export type LoanFinancialRescueStddevSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LoanFinancialRescueSumFields = {
  __typename?: 'loan_financial_rescue_sum_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "loan.financial_rescue" */
export type LoanFinancialRescueSumOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "loan.financial_rescue" */
export enum LoanFinancialRescueUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type LoanFinancialRescueVarPopFields = {
  __typename?: 'loan_financial_rescue_var_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "loan.financial_rescue" */
export type LoanFinancialRescueVarPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LoanFinancialRescueVarSampFields = {
  __typename?: 'loan_financial_rescue_var_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "loan.financial_rescue" */
export type LoanFinancialRescueVarSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LoanFinancialRescueVarianceFields = {
  __typename?: 'loan_financial_rescue_variance_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "loan.financial_rescue" */
export type LoanFinancialRescueVarianceOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/**
 * วัตถุประสงค์
 *
 *
 * columns and relationships of "loan.objective"
 */
export type LoanObjective = {
  __typename?: 'loan_objective';
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active: Scalars['Boolean'];
  /** An array relationship */
  application_objectives: Array<LoanApplicationObjective>;
  /** An aggregate relationship */
  application_objectives_aggregate: LoanApplicationObjectiveAggregate;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** Primary key */
  id: Scalars['String'];
  /** flag ระบุเพิ่มเติม เพื่อให้ frontend เช็ค */
  need_addition: Scalars['Boolean'];
  /** An object relationship */
  program?: Maybe<LoanProgram>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** ชื่อวัตถุประสงค์ */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};


/**
 * วัตถุประสงค์
 *
 *
 * columns and relationships of "loan.objective"
 */
export type LoanObjectiveApplicationObjectivesArgs = {
  distinct_on?: Maybe<Array<LoanApplicationObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationObjectiveOrderBy>>;
  where?: Maybe<LoanApplicationObjectiveBoolExp>;
};


/**
 * วัตถุประสงค์
 *
 *
 * columns and relationships of "loan.objective"
 */
export type LoanObjectiveApplicationObjectivesAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationObjectiveOrderBy>>;
  where?: Maybe<LoanApplicationObjectiveBoolExp>;
};

/** aggregated selection of "loan.objective" */
export type LoanObjectiveAggregate = {
  __typename?: 'loan_objective_aggregate';
  aggregate?: Maybe<LoanObjectiveAggregateFields>;
  nodes: Array<LoanObjective>;
};

/** aggregate fields of "loan.objective" */
export type LoanObjectiveAggregateFields = {
  __typename?: 'loan_objective_aggregate_fields';
  avg?: Maybe<LoanObjectiveAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanObjectiveMaxFields>;
  min?: Maybe<LoanObjectiveMinFields>;
  stddev?: Maybe<LoanObjectiveStddevFields>;
  stddev_pop?: Maybe<LoanObjectiveStddevPopFields>;
  stddev_samp?: Maybe<LoanObjectiveStddevSampFields>;
  sum?: Maybe<LoanObjectiveSumFields>;
  var_pop?: Maybe<LoanObjectiveVarPopFields>;
  var_samp?: Maybe<LoanObjectiveVarSampFields>;
  variance?: Maybe<LoanObjectiveVarianceFields>;
};


/** aggregate fields of "loan.objective" */
export type LoanObjectiveAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanObjectiveSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan.objective" */
export type LoanObjectiveAggregateOrderBy = {
  avg?: Maybe<LoanObjectiveAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanObjectiveMaxOrderBy>;
  min?: Maybe<LoanObjectiveMinOrderBy>;
  stddev?: Maybe<LoanObjectiveStddevOrderBy>;
  stddev_pop?: Maybe<LoanObjectiveStddevPopOrderBy>;
  stddev_samp?: Maybe<LoanObjectiveStddevSampOrderBy>;
  sum?: Maybe<LoanObjectiveSumOrderBy>;
  var_pop?: Maybe<LoanObjectiveVarPopOrderBy>;
  var_samp?: Maybe<LoanObjectiveVarSampOrderBy>;
  variance?: Maybe<LoanObjectiveVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "loan.objective" */
export type LoanObjectiveArrRelInsertInput = {
  data: Array<LoanObjectiveInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanObjectiveOnConflict>;
};

/** aggregate avg on columns */
export type LoanObjectiveAvgFields = {
  __typename?: 'loan_objective_avg_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "loan.objective" */
export type LoanObjectiveAvgOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "loan.objective". All fields are combined with a logical 'AND'. */
export type LoanObjectiveBoolExp = {
  _and?: Maybe<Array<LoanObjectiveBoolExp>>;
  _not?: Maybe<LoanObjectiveBoolExp>;
  _or?: Maybe<Array<LoanObjectiveBoolExp>>;
  active?: Maybe<BooleanComparisonExp>;
  application_objectives?: Maybe<LoanApplicationObjectiveBoolExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<StringComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  need_addition?: Maybe<BooleanComparisonExp>;
  program?: Maybe<LoanProgramBoolExp>;
  program_id?: Maybe<UuidComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
  title?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "loan.objective" */
export enum LoanObjectiveConstraint {
  /** unique or primary key constraint */
  ObjectivePkey = 'objective_pkey'
}

/** input type for incrementing numeric columns in table "loan.objective" */
export type LoanObjectiveIncInput = {
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.objective" */
export type LoanObjectiveInsertInput = {
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active?: Maybe<Scalars['Boolean']>;
  application_objectives?: Maybe<LoanApplicationObjectiveArrRelInsertInput>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** flag ระบุเพิ่มเติม เพื่อให้ frontend เช็ค */
  need_addition?: Maybe<Scalars['Boolean']>;
  program?: Maybe<LoanProgramObjRelInsertInput>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** ชื่อวัตถุประสงค์ */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LoanObjectiveMaxFields = {
  __typename?: 'loan_objective_max_fields';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** ชื่อวัตถุประสงค์ */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "loan.objective" */
export type LoanObjectiveMaxOrderBy = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** รหัสโครงการ */
  program_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** ชื่อวัตถุประสงค์ */
  title?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanObjectiveMinFields = {
  __typename?: 'loan_objective_min_fields';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** ชื่อวัตถุประสงค์ */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "loan.objective" */
export type LoanObjectiveMinOrderBy = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** รหัสโครงการ */
  program_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** ชื่อวัตถุประสงค์ */
  title?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan.objective" */
export type LoanObjectiveMutationResponse = {
  __typename?: 'loan_objective_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanObjective>;
};

/** input type for inserting object relation for remote table "loan.objective" */
export type LoanObjectiveObjRelInsertInput = {
  data: LoanObjectiveInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanObjectiveOnConflict>;
};

/** on conflict condition type for table "loan.objective" */
export type LoanObjectiveOnConflict = {
  constraint: LoanObjectiveConstraint;
  update_columns?: Array<LoanObjectiveUpdateColumn>;
  where?: Maybe<LoanObjectiveBoolExp>;
};

/** Ordering options when selecting data from "loan.objective". */
export type LoanObjectiveOrderBy = {
  active?: Maybe<OrderBy>;
  application_objectives_aggregate?: Maybe<LoanApplicationObjectiveAggregateOrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  need_addition?: Maybe<OrderBy>;
  program?: Maybe<LoanProgramOrderBy>;
  program_id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_objective */
export type LoanObjectivePkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "loan.objective" */
export enum LoanObjectiveSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  NeedAddition = 'need_addition',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "loan.objective" */
export type LoanObjectiveSetInput = {
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active?: Maybe<Scalars['Boolean']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** flag ระบุเพิ่มเติม เพื่อให้ frontend เช็ค */
  need_addition?: Maybe<Scalars['Boolean']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** ชื่อวัตถุประสงค์ */
  title?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type LoanObjectiveStddevFields = {
  __typename?: 'loan_objective_stddev_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "loan.objective" */
export type LoanObjectiveStddevOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LoanObjectiveStddevPopFields = {
  __typename?: 'loan_objective_stddev_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "loan.objective" */
export type LoanObjectiveStddevPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LoanObjectiveStddevSampFields = {
  __typename?: 'loan_objective_stddev_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "loan.objective" */
export type LoanObjectiveStddevSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LoanObjectiveSumFields = {
  __typename?: 'loan_objective_sum_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "loan.objective" */
export type LoanObjectiveSumOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "loan.objective" */
export enum LoanObjectiveUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  NeedAddition = 'need_addition',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type LoanObjectiveVarPopFields = {
  __typename?: 'loan_objective_var_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "loan.objective" */
export type LoanObjectiveVarPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LoanObjectiveVarSampFields = {
  __typename?: 'loan_objective_var_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "loan.objective" */
export type LoanObjectiveVarSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LoanObjectiveVarianceFields = {
  __typename?: 'loan_objective_variance_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "loan.objective" */
export type LoanObjectiveVarianceOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/**
 * โครงการ
 *
 *
 * columns and relationships of "loan.program"
 */
export type LoanProgram = {
  __typename?: 'loan_program';
  /** An array relationship */
  agreement_af_status_counts: Array<ContractAgreementAfStatusCount>;
  /** An aggregate relationship */
  agreement_af_status_counts_aggregate: ContractAgreementAfStatusCountAggregate;
  /** An array relationship */
  agreement_withdraws: Array<ContractAgreementWithdraw>;
  /** An aggregate relationship */
  agreement_withdraws_aggregate: ContractAgreementWithdrawAggregate;
  /** An array relationship */
  agreements: Array<ContractAgreement>;
  /** An aggregate relationship */
  agreements_aggregate: ContractAgreementAggregate;
  /** An array relationship */
  application_headers: Array<LoanApplicationHeader>;
  /** An aggregate relationship */
  application_headers_aggregate: LoanApplicationHeaderAggregate;
  /** An array relationship */
  application_sequence_nos: Array<LoanApplicationSequenceNo>;
  /** An aggregate relationship */
  application_sequence_nos_aggregate: LoanApplicationSequenceNoAggregate;
  /** รหัสอ้างอิงโครงการ (ธนาคาร) */
  bank_program_reference: Scalars['String'];
  /** วงเงินทั้งหมดของโครงการ */
  budget_total: Scalars['numeric'];
  /** An array relationship */
  business_groups: Array<LoanBusinessGroup>;
  /** An aggregate relationship */
  business_groups_aggregate: LoanBusinessGroupAggregate;
  /** An array relationship */
  business_types: Array<LoanBusinessType>;
  /** An aggregate relationship */
  business_types_aggregate: LoanBusinessTypeAggregate;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** รายละเอียดโครงการ */
  description?: Maybe<Scalars['String']>;
  /** วันที่สิ้นสุดโครงการ */
  end_date?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  financial_rescues: Array<LoanFinancialRescue>;
  /** An aggregate relationship */
  financial_rescues_aggregate: LoanFinancialRescueAggregate;
  /** Primary key */
  id: Scalars['uuid'];
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit: Scalars['numeric'];
  /** ชื่อโครงการ */
  name: Scalars['String'];
  /** โครงการที่ใช้งานในระบบ (true = ทำให้ back office มองเห็น, false = ทำให้ back office มองไม่เห็น) */
  new: Scalars['Boolean'];
  /** An array relationship */
  objectives: Array<LoanObjective>;
  /** An aggregate relationship */
  objectives_aggregate: LoanObjectiveAggregate;
  /** An object relationship */
  program_status: LoanProgramStatus;
  /** An array relationship */
  program_transaction_types: Array<LoanProgramTransactionType>;
  /** An aggregate relationship */
  program_transaction_types_aggregate: LoanProgramTransactionTypeAggregate;
  /** An array relationship */
  program_transactions: Array<LoanProgramTransaction>;
  /** An aggregate relationship */
  program_transactions_aggregate: LoanProgramTransactionAggregate;
  /** true = เปิดให้มองเห็นโครงการ, false = ปิดการมองเห็นโครงการ */
  public: Scalars['Boolean'];
  /** รหัสอ้างอิงโครงการ */
  reference: Scalars['String'];
  /** วันที่เริ่มโครงการ */
  start_date?: Maybe<Scalars['timestamptz']>;
  /** สถานะโครงการ */
  status?: Maybe<Scalars['String']>;
  /** คำอธิบายสถานะโครงการ */
  status_description?: Maybe<Scalars['String']>;
  /** สถานะโครงการ */
  status_value: LoanProgramStatusEnum;
};


/**
 * โครงการ
 *
 *
 * columns and relationships of "loan.program"
 */
export type LoanProgramAgreementAfStatusCountsArgs = {
  distinct_on?: Maybe<Array<ContractAgreementAfStatusCountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementAfStatusCountOrderBy>>;
  where?: Maybe<ContractAgreementAfStatusCountBoolExp>;
};


/**
 * โครงการ
 *
 *
 * columns and relationships of "loan.program"
 */
export type LoanProgramAgreementAfStatusCountsAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementAfStatusCountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementAfStatusCountOrderBy>>;
  where?: Maybe<ContractAgreementAfStatusCountBoolExp>;
};


/**
 * โครงการ
 *
 *
 * columns and relationships of "loan.program"
 */
export type LoanProgramAgreementWithdrawsArgs = {
  distinct_on?: Maybe<Array<ContractAgreementWithdrawSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementWithdrawOrderBy>>;
  where?: Maybe<ContractAgreementWithdrawBoolExp>;
};


/**
 * โครงการ
 *
 *
 * columns and relationships of "loan.program"
 */
export type LoanProgramAgreementWithdrawsAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementWithdrawSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementWithdrawOrderBy>>;
  where?: Maybe<ContractAgreementWithdrawBoolExp>;
};


/**
 * โครงการ
 *
 *
 * columns and relationships of "loan.program"
 */
export type LoanProgramAgreementsArgs = {
  distinct_on?: Maybe<Array<ContractAgreementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementOrderBy>>;
  where?: Maybe<ContractAgreementBoolExp>;
};


/**
 * โครงการ
 *
 *
 * columns and relationships of "loan.program"
 */
export type LoanProgramAgreementsAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementOrderBy>>;
  where?: Maybe<ContractAgreementBoolExp>;
};


/**
 * โครงการ
 *
 *
 * columns and relationships of "loan.program"
 */
export type LoanProgramApplicationHeadersArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};


/**
 * โครงการ
 *
 *
 * columns and relationships of "loan.program"
 */
export type LoanProgramApplicationHeadersAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};


/**
 * โครงการ
 *
 *
 * columns and relationships of "loan.program"
 */
export type LoanProgramApplicationSequenceNosArgs = {
  distinct_on?: Maybe<Array<LoanApplicationSequenceNoSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationSequenceNoOrderBy>>;
  where?: Maybe<LoanApplicationSequenceNoBoolExp>;
};


/**
 * โครงการ
 *
 *
 * columns and relationships of "loan.program"
 */
export type LoanProgramApplicationSequenceNosAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationSequenceNoSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationSequenceNoOrderBy>>;
  where?: Maybe<LoanApplicationSequenceNoBoolExp>;
};


/**
 * โครงการ
 *
 *
 * columns and relationships of "loan.program"
 */
export type LoanProgramBusinessGroupsArgs = {
  distinct_on?: Maybe<Array<LoanBusinessGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessGroupOrderBy>>;
  where?: Maybe<LoanBusinessGroupBoolExp>;
};


/**
 * โครงการ
 *
 *
 * columns and relationships of "loan.program"
 */
export type LoanProgramBusinessGroupsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanBusinessGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessGroupOrderBy>>;
  where?: Maybe<LoanBusinessGroupBoolExp>;
};


/**
 * โครงการ
 *
 *
 * columns and relationships of "loan.program"
 */
export type LoanProgramBusinessTypesArgs = {
  distinct_on?: Maybe<Array<LoanBusinessTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessTypeOrderBy>>;
  where?: Maybe<LoanBusinessTypeBoolExp>;
};


/**
 * โครงการ
 *
 *
 * columns and relationships of "loan.program"
 */
export type LoanProgramBusinessTypesAggregateArgs = {
  distinct_on?: Maybe<Array<LoanBusinessTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessTypeOrderBy>>;
  where?: Maybe<LoanBusinessTypeBoolExp>;
};


/**
 * โครงการ
 *
 *
 * columns and relationships of "loan.program"
 */
export type LoanProgramFinancialRescuesArgs = {
  distinct_on?: Maybe<Array<LoanFinancialRescueSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanFinancialRescueOrderBy>>;
  where?: Maybe<LoanFinancialRescueBoolExp>;
};


/**
 * โครงการ
 *
 *
 * columns and relationships of "loan.program"
 */
export type LoanProgramFinancialRescuesAggregateArgs = {
  distinct_on?: Maybe<Array<LoanFinancialRescueSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanFinancialRescueOrderBy>>;
  where?: Maybe<LoanFinancialRescueBoolExp>;
};


/**
 * โครงการ
 *
 *
 * columns and relationships of "loan.program"
 */
export type LoanProgramObjectivesArgs = {
  distinct_on?: Maybe<Array<LoanObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanObjectiveOrderBy>>;
  where?: Maybe<LoanObjectiveBoolExp>;
};


/**
 * โครงการ
 *
 *
 * columns and relationships of "loan.program"
 */
export type LoanProgramObjectivesAggregateArgs = {
  distinct_on?: Maybe<Array<LoanObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanObjectiveOrderBy>>;
  where?: Maybe<LoanObjectiveBoolExp>;
};


/**
 * โครงการ
 *
 *
 * columns and relationships of "loan.program"
 */
export type LoanProgramProgramTransactionTypesArgs = {
  distinct_on?: Maybe<Array<LoanProgramTransactionTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramTransactionTypeOrderBy>>;
  where?: Maybe<LoanProgramTransactionTypeBoolExp>;
};


/**
 * โครงการ
 *
 *
 * columns and relationships of "loan.program"
 */
export type LoanProgramProgramTransactionTypesAggregateArgs = {
  distinct_on?: Maybe<Array<LoanProgramTransactionTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramTransactionTypeOrderBy>>;
  where?: Maybe<LoanProgramTransactionTypeBoolExp>;
};


/**
 * โครงการ
 *
 *
 * columns and relationships of "loan.program"
 */
export type LoanProgramProgramTransactionsArgs = {
  distinct_on?: Maybe<Array<LoanProgramTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramTransactionOrderBy>>;
  where?: Maybe<LoanProgramTransactionBoolExp>;
};


/**
 * โครงการ
 *
 *
 * columns and relationships of "loan.program"
 */
export type LoanProgramProgramTransactionsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanProgramTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramTransactionOrderBy>>;
  where?: Maybe<LoanProgramTransactionBoolExp>;
};

/** aggregated selection of "loan.program" */
export type LoanProgramAggregate = {
  __typename?: 'loan_program_aggregate';
  aggregate?: Maybe<LoanProgramAggregateFields>;
  nodes: Array<LoanProgram>;
};

/** aggregate fields of "loan.program" */
export type LoanProgramAggregateFields = {
  __typename?: 'loan_program_aggregate_fields';
  avg?: Maybe<LoanProgramAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanProgramMaxFields>;
  min?: Maybe<LoanProgramMinFields>;
  stddev?: Maybe<LoanProgramStddevFields>;
  stddev_pop?: Maybe<LoanProgramStddevPopFields>;
  stddev_samp?: Maybe<LoanProgramStddevSampFields>;
  sum?: Maybe<LoanProgramSumFields>;
  var_pop?: Maybe<LoanProgramVarPopFields>;
  var_samp?: Maybe<LoanProgramVarSampFields>;
  variance?: Maybe<LoanProgramVarianceFields>;
};


/** aggregate fields of "loan.program" */
export type LoanProgramAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanProgramSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan.program" */
export type LoanProgramAggregateOrderBy = {
  avg?: Maybe<LoanProgramAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanProgramMaxOrderBy>;
  min?: Maybe<LoanProgramMinOrderBy>;
  stddev?: Maybe<LoanProgramStddevOrderBy>;
  stddev_pop?: Maybe<LoanProgramStddevPopOrderBy>;
  stddev_samp?: Maybe<LoanProgramStddevSampOrderBy>;
  sum?: Maybe<LoanProgramSumOrderBy>;
  var_pop?: Maybe<LoanProgramVarPopOrderBy>;
  var_samp?: Maybe<LoanProgramVarSampOrderBy>;
  variance?: Maybe<LoanProgramVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "loan.program" */
export type LoanProgramArrRelInsertInput = {
  data: Array<LoanProgramInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanProgramOnConflict>;
};

/** aggregate avg on columns */
export type LoanProgramAvgFields = {
  __typename?: 'loan_program_avg_fields';
  /** วงเงินทั้งหมดของโครงการ */
  budget_total?: Maybe<Scalars['Float']>;
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "loan.program" */
export type LoanProgramAvgOrderBy = {
  /** วงเงินทั้งหมดของโครงการ */
  budget_total?: Maybe<OrderBy>;
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "loan.program". All fields are combined with a logical 'AND'. */
export type LoanProgramBoolExp = {
  _and?: Maybe<Array<LoanProgramBoolExp>>;
  _not?: Maybe<LoanProgramBoolExp>;
  _or?: Maybe<Array<LoanProgramBoolExp>>;
  agreement_af_status_counts?: Maybe<ContractAgreementAfStatusCountBoolExp>;
  agreement_withdraws?: Maybe<ContractAgreementWithdrawBoolExp>;
  agreements?: Maybe<ContractAgreementBoolExp>;
  application_headers?: Maybe<LoanApplicationHeaderBoolExp>;
  application_sequence_nos?: Maybe<LoanApplicationSequenceNoBoolExp>;
  bank_program_reference?: Maybe<StringComparisonExp>;
  budget_total?: Maybe<NumericComparisonExp>;
  business_groups?: Maybe<LoanBusinessGroupBoolExp>;
  business_types?: Maybe<LoanBusinessTypeBoolExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  end_date?: Maybe<TimestamptzComparisonExp>;
  financial_rescues?: Maybe<LoanFinancialRescueBoolExp>;
  id?: Maybe<UuidComparisonExp>;
  max_credit?: Maybe<NumericComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  new?: Maybe<BooleanComparisonExp>;
  objectives?: Maybe<LoanObjectiveBoolExp>;
  program_status?: Maybe<LoanProgramStatusBoolExp>;
  program_transaction_types?: Maybe<LoanProgramTransactionTypeBoolExp>;
  program_transactions?: Maybe<LoanProgramTransactionBoolExp>;
  public?: Maybe<BooleanComparisonExp>;
  reference?: Maybe<StringComparisonExp>;
  start_date?: Maybe<TimestamptzComparisonExp>;
  status?: Maybe<StringComparisonExp>;
  status_description?: Maybe<StringComparisonExp>;
  status_value?: Maybe<LoanProgramStatusEnumComparisonExp>;
};

/** unique or primary key constraints on table "loan.program" */
export enum LoanProgramConstraint {
  /** unique or primary key constraint */
  ProgramBankProgramReferenceKey = 'program_bank_program_reference_key',
  /** unique or primary key constraint */
  ProgramPkey = 'program_pkey',
  /** unique or primary key constraint */
  ProgramReferenceKey = 'program_reference_key'
}

/** input type for incrementing numeric columns in table "loan.program" */
export type LoanProgramIncInput = {
  /** วงเงินทั้งหมดของโครงการ */
  budget_total?: Maybe<Scalars['numeric']>;
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.program" */
export type LoanProgramInsertInput = {
  agreement_af_status_counts?: Maybe<ContractAgreementAfStatusCountArrRelInsertInput>;
  agreement_withdraws?: Maybe<ContractAgreementWithdrawArrRelInsertInput>;
  agreements?: Maybe<ContractAgreementArrRelInsertInput>;
  application_headers?: Maybe<LoanApplicationHeaderArrRelInsertInput>;
  application_sequence_nos?: Maybe<LoanApplicationSequenceNoArrRelInsertInput>;
  /** รหัสอ้างอิงโครงการ (ธนาคาร) */
  bank_program_reference?: Maybe<Scalars['String']>;
  /** วงเงินทั้งหมดของโครงการ */
  budget_total?: Maybe<Scalars['numeric']>;
  business_groups?: Maybe<LoanBusinessGroupArrRelInsertInput>;
  business_types?: Maybe<LoanBusinessTypeArrRelInsertInput>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** รายละเอียดโครงการ */
  description?: Maybe<Scalars['String']>;
  /** วันที่สิ้นสุดโครงการ */
  end_date?: Maybe<Scalars['timestamptz']>;
  financial_rescues?: Maybe<LoanFinancialRescueArrRelInsertInput>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit?: Maybe<Scalars['numeric']>;
  /** ชื่อโครงการ */
  name?: Maybe<Scalars['String']>;
  /** โครงการที่ใช้งานในระบบ (true = ทำให้ back office มองเห็น, false = ทำให้ back office มองไม่เห็น) */
  new?: Maybe<Scalars['Boolean']>;
  objectives?: Maybe<LoanObjectiveArrRelInsertInput>;
  program_status?: Maybe<LoanProgramStatusObjRelInsertInput>;
  program_transaction_types?: Maybe<LoanProgramTransactionTypeArrRelInsertInput>;
  program_transactions?: Maybe<LoanProgramTransactionArrRelInsertInput>;
  /** true = เปิดให้มองเห็นโครงการ, false = ปิดการมองเห็นโครงการ */
  public?: Maybe<Scalars['Boolean']>;
  /** รหัสอ้างอิงโครงการ */
  reference?: Maybe<Scalars['String']>;
  /** วันที่เริ่มโครงการ */
  start_date?: Maybe<Scalars['timestamptz']>;
  /** สถานะโครงการ */
  status?: Maybe<Scalars['String']>;
  /** คำอธิบายสถานะโครงการ */
  status_description?: Maybe<Scalars['String']>;
  /** สถานะโครงการ */
  status_value?: Maybe<LoanProgramStatusEnum>;
};

/** aggregate max on columns */
export type LoanProgramMaxFields = {
  __typename?: 'loan_program_max_fields';
  /** รหัสอ้างอิงโครงการ (ธนาคาร) */
  bank_program_reference?: Maybe<Scalars['String']>;
  /** วงเงินทั้งหมดของโครงการ */
  budget_total?: Maybe<Scalars['numeric']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** รายละเอียดโครงการ */
  description?: Maybe<Scalars['String']>;
  /** วันที่สิ้นสุดโครงการ */
  end_date?: Maybe<Scalars['timestamptz']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit?: Maybe<Scalars['numeric']>;
  /** ชื่อโครงการ */
  name?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิงโครงการ */
  reference?: Maybe<Scalars['String']>;
  /** วันที่เริ่มโครงการ */
  start_date?: Maybe<Scalars['timestamptz']>;
  /** สถานะโครงการ */
  status?: Maybe<Scalars['String']>;
  /** คำอธิบายสถานะโครงการ */
  status_description?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "loan.program" */
export type LoanProgramMaxOrderBy = {
  /** รหัสอ้างอิงโครงการ (ธนาคาร) */
  bank_program_reference?: Maybe<OrderBy>;
  /** วงเงินทั้งหมดของโครงการ */
  budget_total?: Maybe<OrderBy>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** รายละเอียดโครงการ */
  description?: Maybe<OrderBy>;
  /** วันที่สิ้นสุดโครงการ */
  end_date?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit?: Maybe<OrderBy>;
  /** ชื่อโครงการ */
  name?: Maybe<OrderBy>;
  /** รหัสอ้างอิงโครงการ */
  reference?: Maybe<OrderBy>;
  /** วันที่เริ่มโครงการ */
  start_date?: Maybe<OrderBy>;
  /** สถานะโครงการ */
  status?: Maybe<OrderBy>;
  /** คำอธิบายสถานะโครงการ */
  status_description?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanProgramMinFields = {
  __typename?: 'loan_program_min_fields';
  /** รหัสอ้างอิงโครงการ (ธนาคาร) */
  bank_program_reference?: Maybe<Scalars['String']>;
  /** วงเงินทั้งหมดของโครงการ */
  budget_total?: Maybe<Scalars['numeric']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** รายละเอียดโครงการ */
  description?: Maybe<Scalars['String']>;
  /** วันที่สิ้นสุดโครงการ */
  end_date?: Maybe<Scalars['timestamptz']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit?: Maybe<Scalars['numeric']>;
  /** ชื่อโครงการ */
  name?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิงโครงการ */
  reference?: Maybe<Scalars['String']>;
  /** วันที่เริ่มโครงการ */
  start_date?: Maybe<Scalars['timestamptz']>;
  /** สถานะโครงการ */
  status?: Maybe<Scalars['String']>;
  /** คำอธิบายสถานะโครงการ */
  status_description?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "loan.program" */
export type LoanProgramMinOrderBy = {
  /** รหัสอ้างอิงโครงการ (ธนาคาร) */
  bank_program_reference?: Maybe<OrderBy>;
  /** วงเงินทั้งหมดของโครงการ */
  budget_total?: Maybe<OrderBy>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** รายละเอียดโครงการ */
  description?: Maybe<OrderBy>;
  /** วันที่สิ้นสุดโครงการ */
  end_date?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit?: Maybe<OrderBy>;
  /** ชื่อโครงการ */
  name?: Maybe<OrderBy>;
  /** รหัสอ้างอิงโครงการ */
  reference?: Maybe<OrderBy>;
  /** วันที่เริ่มโครงการ */
  start_date?: Maybe<OrderBy>;
  /** สถานะโครงการ */
  status?: Maybe<OrderBy>;
  /** คำอธิบายสถานะโครงการ */
  status_description?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan.program" */
export type LoanProgramMutationResponse = {
  __typename?: 'loan_program_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanProgram>;
};

/** input type for inserting object relation for remote table "loan.program" */
export type LoanProgramObjRelInsertInput = {
  data: LoanProgramInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanProgramOnConflict>;
};

/** on conflict condition type for table "loan.program" */
export type LoanProgramOnConflict = {
  constraint: LoanProgramConstraint;
  update_columns?: Array<LoanProgramUpdateColumn>;
  where?: Maybe<LoanProgramBoolExp>;
};

/** Ordering options when selecting data from "loan.program". */
export type LoanProgramOrderBy = {
  agreement_af_status_counts_aggregate?: Maybe<ContractAgreementAfStatusCountAggregateOrderBy>;
  agreement_withdraws_aggregate?: Maybe<ContractAgreementWithdrawAggregateOrderBy>;
  agreements_aggregate?: Maybe<ContractAgreementAggregateOrderBy>;
  application_headers_aggregate?: Maybe<LoanApplicationHeaderAggregateOrderBy>;
  application_sequence_nos_aggregate?: Maybe<LoanApplicationSequenceNoAggregateOrderBy>;
  bank_program_reference?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  business_groups_aggregate?: Maybe<LoanBusinessGroupAggregateOrderBy>;
  business_types_aggregate?: Maybe<LoanBusinessTypeAggregateOrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  end_date?: Maybe<OrderBy>;
  financial_rescues_aggregate?: Maybe<LoanFinancialRescueAggregateOrderBy>;
  id?: Maybe<OrderBy>;
  max_credit?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  new?: Maybe<OrderBy>;
  objectives_aggregate?: Maybe<LoanObjectiveAggregateOrderBy>;
  program_status?: Maybe<LoanProgramStatusOrderBy>;
  program_transaction_types_aggregate?: Maybe<LoanProgramTransactionTypeAggregateOrderBy>;
  program_transactions_aggregate?: Maybe<LoanProgramTransactionAggregateOrderBy>;
  public?: Maybe<OrderBy>;
  reference?: Maybe<OrderBy>;
  start_date?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  status_description?: Maybe<OrderBy>;
  status_value?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_program */
export type LoanProgramPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "loan.program" */
export enum LoanProgramSelectColumn {
  /** column name */
  BankProgramReference = 'bank_program_reference',
  /** column name */
  BudgetTotal = 'budget_total',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  MaxCredit = 'max_credit',
  /** column name */
  Name = 'name',
  /** column name */
  New = 'new',
  /** column name */
  Public = 'public',
  /** column name */
  Reference = 'reference',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Status = 'status',
  /** column name */
  StatusDescription = 'status_description',
  /** column name */
  StatusValue = 'status_value'
}

/** input type for updating data in table "loan.program" */
export type LoanProgramSetInput = {
  /** รหัสอ้างอิงโครงการ (ธนาคาร) */
  bank_program_reference?: Maybe<Scalars['String']>;
  /** วงเงินทั้งหมดของโครงการ */
  budget_total?: Maybe<Scalars['numeric']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** รายละเอียดโครงการ */
  description?: Maybe<Scalars['String']>;
  /** วันที่สิ้นสุดโครงการ */
  end_date?: Maybe<Scalars['timestamptz']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit?: Maybe<Scalars['numeric']>;
  /** ชื่อโครงการ */
  name?: Maybe<Scalars['String']>;
  /** โครงการที่ใช้งานในระบบ (true = ทำให้ back office มองเห็น, false = ทำให้ back office มองไม่เห็น) */
  new?: Maybe<Scalars['Boolean']>;
  /** true = เปิดให้มองเห็นโครงการ, false = ปิดการมองเห็นโครงการ */
  public?: Maybe<Scalars['Boolean']>;
  /** รหัสอ้างอิงโครงการ */
  reference?: Maybe<Scalars['String']>;
  /** วันที่เริ่มโครงการ */
  start_date?: Maybe<Scalars['timestamptz']>;
  /** สถานะโครงการ */
  status?: Maybe<Scalars['String']>;
  /** คำอธิบายสถานะโครงการ */
  status_description?: Maybe<Scalars['String']>;
  /** สถานะโครงการ */
  status_value?: Maybe<LoanProgramStatusEnum>;
};

/**
 * สถานะโครงการ
 *
 *
 * columns and relationships of "loan.program_status"
 */
export type LoanProgramStatus = {
  __typename?: 'loan_program_status';
  /** สถานะโครงการ */
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  programs: Array<LoanProgram>;
  /** An aggregate relationship */
  programs_aggregate: LoanProgramAggregate;
  /** Primary key */
  value: Scalars['String'];
};


/**
 * สถานะโครงการ
 *
 *
 * columns and relationships of "loan.program_status"
 */
export type LoanProgramStatusProgramsArgs = {
  distinct_on?: Maybe<Array<LoanProgramSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramOrderBy>>;
  where?: Maybe<LoanProgramBoolExp>;
};


/**
 * สถานะโครงการ
 *
 *
 * columns and relationships of "loan.program_status"
 */
export type LoanProgramStatusProgramsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanProgramSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramOrderBy>>;
  where?: Maybe<LoanProgramBoolExp>;
};

/** aggregated selection of "loan.program_status" */
export type LoanProgramStatusAggregate = {
  __typename?: 'loan_program_status_aggregate';
  aggregate?: Maybe<LoanProgramStatusAggregateFields>;
  nodes: Array<LoanProgramStatus>;
};

/** aggregate fields of "loan.program_status" */
export type LoanProgramStatusAggregateFields = {
  __typename?: 'loan_program_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LoanProgramStatusMaxFields>;
  min?: Maybe<LoanProgramStatusMinFields>;
};


/** aggregate fields of "loan.program_status" */
export type LoanProgramStatusAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanProgramStatusSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "loan.program_status". All fields are combined with a logical 'AND'. */
export type LoanProgramStatusBoolExp = {
  _and?: Maybe<Array<LoanProgramStatusBoolExp>>;
  _not?: Maybe<LoanProgramStatusBoolExp>;
  _or?: Maybe<Array<LoanProgramStatusBoolExp>>;
  description?: Maybe<StringComparisonExp>;
  programs?: Maybe<LoanProgramBoolExp>;
  value?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "loan.program_status" */
export enum LoanProgramStatusConstraint {
  /** unique or primary key constraint */
  ProgramStatusPkey = 'program_status_pkey'
}

export enum LoanProgramStatusEnum {
  /** ปิด */
  Closed = 'closed',
  /** เปิด */
  Open = 'open'
}

/** Boolean expression to compare columns of type "loan_program_status_enum". All fields are combined with logical 'AND'. */
export type LoanProgramStatusEnumComparisonExp = {
  _eq?: Maybe<LoanProgramStatusEnum>;
  _in?: Maybe<Array<LoanProgramStatusEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<LoanProgramStatusEnum>;
  _nin?: Maybe<Array<LoanProgramStatusEnum>>;
};

/** input type for inserting data into table "loan.program_status" */
export type LoanProgramStatusInsertInput = {
  /** สถานะโครงการ */
  description?: Maybe<Scalars['String']>;
  programs?: Maybe<LoanProgramArrRelInsertInput>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LoanProgramStatusMaxFields = {
  __typename?: 'loan_program_status_max_fields';
  /** สถานะโครงการ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type LoanProgramStatusMinFields = {
  __typename?: 'loan_program_status_min_fields';
  /** สถานะโครงการ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "loan.program_status" */
export type LoanProgramStatusMutationResponse = {
  __typename?: 'loan_program_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanProgramStatus>;
};

/** input type for inserting object relation for remote table "loan.program_status" */
export type LoanProgramStatusObjRelInsertInput = {
  data: LoanProgramStatusInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanProgramStatusOnConflict>;
};

/** on conflict condition type for table "loan.program_status" */
export type LoanProgramStatusOnConflict = {
  constraint: LoanProgramStatusConstraint;
  update_columns?: Array<LoanProgramStatusUpdateColumn>;
  where?: Maybe<LoanProgramStatusBoolExp>;
};

/** Ordering options when selecting data from "loan.program_status". */
export type LoanProgramStatusOrderBy = {
  description?: Maybe<OrderBy>;
  programs_aggregate?: Maybe<LoanProgramAggregateOrderBy>;
  value?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_program_status */
export type LoanProgramStatusPkColumnsInput = {
  /** Primary key */
  value: Scalars['String'];
};

/** select columns of table "loan.program_status" */
export enum LoanProgramStatusSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "loan.program_status" */
export type LoanProgramStatusSetInput = {
  /** สถานะโครงการ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "loan.program_status" */
export enum LoanProgramStatusUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type LoanProgramStddevFields = {
  __typename?: 'loan_program_stddev_fields';
  /** วงเงินทั้งหมดของโครงการ */
  budget_total?: Maybe<Scalars['Float']>;
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "loan.program" */
export type LoanProgramStddevOrderBy = {
  /** วงเงินทั้งหมดของโครงการ */
  budget_total?: Maybe<OrderBy>;
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LoanProgramStddevPopFields = {
  __typename?: 'loan_program_stddev_pop_fields';
  /** วงเงินทั้งหมดของโครงการ */
  budget_total?: Maybe<Scalars['Float']>;
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "loan.program" */
export type LoanProgramStddevPopOrderBy = {
  /** วงเงินทั้งหมดของโครงการ */
  budget_total?: Maybe<OrderBy>;
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LoanProgramStddevSampFields = {
  __typename?: 'loan_program_stddev_samp_fields';
  /** วงเงินทั้งหมดของโครงการ */
  budget_total?: Maybe<Scalars['Float']>;
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "loan.program" */
export type LoanProgramStddevSampOrderBy = {
  /** วงเงินทั้งหมดของโครงการ */
  budget_total?: Maybe<OrderBy>;
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LoanProgramSumFields = {
  __typename?: 'loan_program_sum_fields';
  /** วงเงินทั้งหมดของโครงการ */
  budget_total?: Maybe<Scalars['numeric']>;
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "loan.program" */
export type LoanProgramSumOrderBy = {
  /** วงเงินทั้งหมดของโครงการ */
  budget_total?: Maybe<OrderBy>;
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit?: Maybe<OrderBy>;
};

/**
 * Transaction
 *
 *
 * columns and relationships of "loan.program_transaction"
 */
export type LoanProgramTransaction = {
  __typename?: 'loan_program_transaction';
  /** จำนวน */
  amount: Scalars['numeric'];
  /** รหัสอ้างอิบใบคำขอสินเชื่อ */
  application_header_id: Scalars['uuid'];
  /** Primary key */
  id: Scalars['uuid'];
  /** An object relationship */
  program: LoanProgram;
  /** รหัสโครงการ */
  program_id: Scalars['uuid'];
  /** An object relationship */
  program_transaction_type: LoanProgramTransactionType;
  /** คำอธิบายประเภท */
  type_description?: Maybe<Scalars['String']>;
  /** ประเภท */
  type_id: Scalars['uuid'];
};

/** aggregated selection of "loan.program_transaction" */
export type LoanProgramTransactionAggregate = {
  __typename?: 'loan_program_transaction_aggregate';
  aggregate?: Maybe<LoanProgramTransactionAggregateFields>;
  nodes: Array<LoanProgramTransaction>;
};

/** aggregate fields of "loan.program_transaction" */
export type LoanProgramTransactionAggregateFields = {
  __typename?: 'loan_program_transaction_aggregate_fields';
  avg?: Maybe<LoanProgramTransactionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanProgramTransactionMaxFields>;
  min?: Maybe<LoanProgramTransactionMinFields>;
  stddev?: Maybe<LoanProgramTransactionStddevFields>;
  stddev_pop?: Maybe<LoanProgramTransactionStddevPopFields>;
  stddev_samp?: Maybe<LoanProgramTransactionStddevSampFields>;
  sum?: Maybe<LoanProgramTransactionSumFields>;
  var_pop?: Maybe<LoanProgramTransactionVarPopFields>;
  var_samp?: Maybe<LoanProgramTransactionVarSampFields>;
  variance?: Maybe<LoanProgramTransactionVarianceFields>;
};


/** aggregate fields of "loan.program_transaction" */
export type LoanProgramTransactionAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanProgramTransactionSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan.program_transaction" */
export type LoanProgramTransactionAggregateOrderBy = {
  avg?: Maybe<LoanProgramTransactionAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanProgramTransactionMaxOrderBy>;
  min?: Maybe<LoanProgramTransactionMinOrderBy>;
  stddev?: Maybe<LoanProgramTransactionStddevOrderBy>;
  stddev_pop?: Maybe<LoanProgramTransactionStddevPopOrderBy>;
  stddev_samp?: Maybe<LoanProgramTransactionStddevSampOrderBy>;
  sum?: Maybe<LoanProgramTransactionSumOrderBy>;
  var_pop?: Maybe<LoanProgramTransactionVarPopOrderBy>;
  var_samp?: Maybe<LoanProgramTransactionVarSampOrderBy>;
  variance?: Maybe<LoanProgramTransactionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "loan.program_transaction" */
export type LoanProgramTransactionArrRelInsertInput = {
  data: Array<LoanProgramTransactionInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanProgramTransactionOnConflict>;
};

/** aggregate avg on columns */
export type LoanProgramTransactionAvgFields = {
  __typename?: 'loan_program_transaction_avg_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "loan.program_transaction" */
export type LoanProgramTransactionAvgOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "loan.program_transaction". All fields are combined with a logical 'AND'. */
export type LoanProgramTransactionBoolExp = {
  _and?: Maybe<Array<LoanProgramTransactionBoolExp>>;
  _not?: Maybe<LoanProgramTransactionBoolExp>;
  _or?: Maybe<Array<LoanProgramTransactionBoolExp>>;
  amount?: Maybe<NumericComparisonExp>;
  application_header_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  program?: Maybe<LoanProgramBoolExp>;
  program_id?: Maybe<UuidComparisonExp>;
  program_transaction_type?: Maybe<LoanProgramTransactionTypeBoolExp>;
  type_description?: Maybe<StringComparisonExp>;
  type_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "loan.program_transaction" */
export enum LoanProgramTransactionConstraint {
  /** unique or primary key constraint */
  ProgramTransactionPkey = 'program_transaction_pkey'
}

/** input type for incrementing numeric columns in table "loan.program_transaction" */
export type LoanProgramTransactionIncInput = {
  /** จำนวน */
  amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.program_transaction" */
export type LoanProgramTransactionInsertInput = {
  /** จำนวน */
  amount?: Maybe<Scalars['numeric']>;
  /** รหัสอ้างอิบใบคำขอสินเชื่อ */
  application_header_id?: Maybe<Scalars['uuid']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  program?: Maybe<LoanProgramObjRelInsertInput>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  program_transaction_type?: Maybe<LoanProgramTransactionTypeObjRelInsertInput>;
  /** คำอธิบายประเภท */
  type_description?: Maybe<Scalars['String']>;
  /** ประเภท */
  type_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LoanProgramTransactionMaxFields = {
  __typename?: 'loan_program_transaction_max_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['numeric']>;
  /** รหัสอ้างอิบใบคำขอสินเชื่อ */
  application_header_id?: Maybe<Scalars['uuid']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** คำอธิบายประเภท */
  type_description?: Maybe<Scalars['String']>;
  /** ประเภท */
  type_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "loan.program_transaction" */
export type LoanProgramTransactionMaxOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
  /** รหัสอ้างอิบใบคำขอสินเชื่อ */
  application_header_id?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** รหัสโครงการ */
  program_id?: Maybe<OrderBy>;
  /** คำอธิบายประเภท */
  type_description?: Maybe<OrderBy>;
  /** ประเภท */
  type_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanProgramTransactionMinFields = {
  __typename?: 'loan_program_transaction_min_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['numeric']>;
  /** รหัสอ้างอิบใบคำขอสินเชื่อ */
  application_header_id?: Maybe<Scalars['uuid']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** คำอธิบายประเภท */
  type_description?: Maybe<Scalars['String']>;
  /** ประเภท */
  type_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "loan.program_transaction" */
export type LoanProgramTransactionMinOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
  /** รหัสอ้างอิบใบคำขอสินเชื่อ */
  application_header_id?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** รหัสโครงการ */
  program_id?: Maybe<OrderBy>;
  /** คำอธิบายประเภท */
  type_description?: Maybe<OrderBy>;
  /** ประเภท */
  type_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan.program_transaction" */
export type LoanProgramTransactionMutationResponse = {
  __typename?: 'loan_program_transaction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanProgramTransaction>;
};

/** on conflict condition type for table "loan.program_transaction" */
export type LoanProgramTransactionOnConflict = {
  constraint: LoanProgramTransactionConstraint;
  update_columns?: Array<LoanProgramTransactionUpdateColumn>;
  where?: Maybe<LoanProgramTransactionBoolExp>;
};

/** Ordering options when selecting data from "loan.program_transaction". */
export type LoanProgramTransactionOrderBy = {
  amount?: Maybe<OrderBy>;
  application_header_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  program?: Maybe<LoanProgramOrderBy>;
  program_id?: Maybe<OrderBy>;
  program_transaction_type?: Maybe<LoanProgramTransactionTypeOrderBy>;
  type_description?: Maybe<OrderBy>;
  type_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_program_transaction */
export type LoanProgramTransactionPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "loan.program_transaction" */
export enum LoanProgramTransactionSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  ApplicationHeaderId = 'application_header_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  TypeDescription = 'type_description',
  /** column name */
  TypeId = 'type_id'
}

/** input type for updating data in table "loan.program_transaction" */
export type LoanProgramTransactionSetInput = {
  /** จำนวน */
  amount?: Maybe<Scalars['numeric']>;
  /** รหัสอ้างอิบใบคำขอสินเชื่อ */
  application_header_id?: Maybe<Scalars['uuid']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** คำอธิบายประเภท */
  type_description?: Maybe<Scalars['String']>;
  /** ประเภท */
  type_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type LoanProgramTransactionStddevFields = {
  __typename?: 'loan_program_transaction_stddev_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "loan.program_transaction" */
export type LoanProgramTransactionStddevOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LoanProgramTransactionStddevPopFields = {
  __typename?: 'loan_program_transaction_stddev_pop_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "loan.program_transaction" */
export type LoanProgramTransactionStddevPopOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LoanProgramTransactionStddevSampFields = {
  __typename?: 'loan_program_transaction_stddev_samp_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "loan.program_transaction" */
export type LoanProgramTransactionStddevSampOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LoanProgramTransactionSumFields = {
  __typename?: 'loan_program_transaction_sum_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "loan.program_transaction" */
export type LoanProgramTransactionSumOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
};

/** columns and relationships of "loan.program_transaction_type" */
export type LoanProgramTransactionType = {
  __typename?: 'loan_program_transaction_type';
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** An object relationship */
  program: LoanProgram;
  /** รหัสโครงการ */
  program_id: Scalars['uuid'];
  /** An array relationship */
  program_transactions: Array<LoanProgramTransaction>;
  /** An aggregate relationship */
  program_transactions_aggregate: LoanProgramTransactionAggregate;
  /** ลำดับที่ */
  sequence: Scalars['numeric'];
};


/** columns and relationships of "loan.program_transaction_type" */
export type LoanProgramTransactionTypeProgramTransactionsArgs = {
  distinct_on?: Maybe<Array<LoanProgramTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramTransactionOrderBy>>;
  where?: Maybe<LoanProgramTransactionBoolExp>;
};


/** columns and relationships of "loan.program_transaction_type" */
export type LoanProgramTransactionTypeProgramTransactionsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanProgramTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramTransactionOrderBy>>;
  where?: Maybe<LoanProgramTransactionBoolExp>;
};

/** aggregated selection of "loan.program_transaction_type" */
export type LoanProgramTransactionTypeAggregate = {
  __typename?: 'loan_program_transaction_type_aggregate';
  aggregate?: Maybe<LoanProgramTransactionTypeAggregateFields>;
  nodes: Array<LoanProgramTransactionType>;
};

/** aggregate fields of "loan.program_transaction_type" */
export type LoanProgramTransactionTypeAggregateFields = {
  __typename?: 'loan_program_transaction_type_aggregate_fields';
  avg?: Maybe<LoanProgramTransactionTypeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanProgramTransactionTypeMaxFields>;
  min?: Maybe<LoanProgramTransactionTypeMinFields>;
  stddev?: Maybe<LoanProgramTransactionTypeStddevFields>;
  stddev_pop?: Maybe<LoanProgramTransactionTypeStddevPopFields>;
  stddev_samp?: Maybe<LoanProgramTransactionTypeStddevSampFields>;
  sum?: Maybe<LoanProgramTransactionTypeSumFields>;
  var_pop?: Maybe<LoanProgramTransactionTypeVarPopFields>;
  var_samp?: Maybe<LoanProgramTransactionTypeVarSampFields>;
  variance?: Maybe<LoanProgramTransactionTypeVarianceFields>;
};


/** aggregate fields of "loan.program_transaction_type" */
export type LoanProgramTransactionTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanProgramTransactionTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan.program_transaction_type" */
export type LoanProgramTransactionTypeAggregateOrderBy = {
  avg?: Maybe<LoanProgramTransactionTypeAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanProgramTransactionTypeMaxOrderBy>;
  min?: Maybe<LoanProgramTransactionTypeMinOrderBy>;
  stddev?: Maybe<LoanProgramTransactionTypeStddevOrderBy>;
  stddev_pop?: Maybe<LoanProgramTransactionTypeStddevPopOrderBy>;
  stddev_samp?: Maybe<LoanProgramTransactionTypeStddevSampOrderBy>;
  sum?: Maybe<LoanProgramTransactionTypeSumOrderBy>;
  var_pop?: Maybe<LoanProgramTransactionTypeVarPopOrderBy>;
  var_samp?: Maybe<LoanProgramTransactionTypeVarSampOrderBy>;
  variance?: Maybe<LoanProgramTransactionTypeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "loan.program_transaction_type" */
export type LoanProgramTransactionTypeArrRelInsertInput = {
  data: Array<LoanProgramTransactionTypeInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanProgramTransactionTypeOnConflict>;
};

/** aggregate avg on columns */
export type LoanProgramTransactionTypeAvgFields = {
  __typename?: 'loan_program_transaction_type_avg_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "loan.program_transaction_type" */
export type LoanProgramTransactionTypeAvgOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "loan.program_transaction_type". All fields are combined with a logical 'AND'. */
export type LoanProgramTransactionTypeBoolExp = {
  _and?: Maybe<Array<LoanProgramTransactionTypeBoolExp>>;
  _not?: Maybe<LoanProgramTransactionTypeBoolExp>;
  _or?: Maybe<Array<LoanProgramTransactionTypeBoolExp>>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  program?: Maybe<LoanProgramBoolExp>;
  program_id?: Maybe<UuidComparisonExp>;
  program_transactions?: Maybe<LoanProgramTransactionBoolExp>;
  sequence?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "loan.program_transaction_type" */
export enum LoanProgramTransactionTypeConstraint {
  /** unique or primary key constraint */
  ProgramTransactionTypePkey = 'program_transaction_type_pkey'
}

/** input type for incrementing numeric columns in table "loan.program_transaction_type" */
export type LoanProgramTransactionTypeIncInput = {
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.program_transaction_type" */
export type LoanProgramTransactionTypeInsertInput = {
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  program?: Maybe<LoanProgramObjRelInsertInput>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  program_transactions?: Maybe<LoanProgramTransactionArrRelInsertInput>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type LoanProgramTransactionTypeMaxFields = {
  __typename?: 'loan_program_transaction_type_max_fields';
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "loan.program_transaction_type" */
export type LoanProgramTransactionTypeMaxOrderBy = {
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<OrderBy>;
  /** คำอธิบาย */
  description?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** รหัสโครงการ */
  program_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanProgramTransactionTypeMinFields = {
  __typename?: 'loan_program_transaction_type_min_fields';
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "loan.program_transaction_type" */
export type LoanProgramTransactionTypeMinOrderBy = {
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<OrderBy>;
  /** คำอธิบาย */
  description?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** รหัสโครงการ */
  program_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan.program_transaction_type" */
export type LoanProgramTransactionTypeMutationResponse = {
  __typename?: 'loan_program_transaction_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanProgramTransactionType>;
};

/** input type for inserting object relation for remote table "loan.program_transaction_type" */
export type LoanProgramTransactionTypeObjRelInsertInput = {
  data: LoanProgramTransactionTypeInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanProgramTransactionTypeOnConflict>;
};

/** on conflict condition type for table "loan.program_transaction_type" */
export type LoanProgramTransactionTypeOnConflict = {
  constraint: LoanProgramTransactionTypeConstraint;
  update_columns?: Array<LoanProgramTransactionTypeUpdateColumn>;
  where?: Maybe<LoanProgramTransactionTypeBoolExp>;
};

/** Ordering options when selecting data from "loan.program_transaction_type". */
export type LoanProgramTransactionTypeOrderBy = {
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  program?: Maybe<LoanProgramOrderBy>;
  program_id?: Maybe<OrderBy>;
  program_transactions_aggregate?: Maybe<LoanProgramTransactionAggregateOrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_program_transaction_type */
export type LoanProgramTransactionTypePkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "loan.program_transaction_type" */
export enum LoanProgramTransactionTypeSelectColumn {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "loan.program_transaction_type" */
export type LoanProgramTransactionTypeSetInput = {
  /** วันที่ลบข้อมมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รหัสโครงการ */
  program_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type LoanProgramTransactionTypeStddevFields = {
  __typename?: 'loan_program_transaction_type_stddev_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "loan.program_transaction_type" */
export type LoanProgramTransactionTypeStddevOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LoanProgramTransactionTypeStddevPopFields = {
  __typename?: 'loan_program_transaction_type_stddev_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "loan.program_transaction_type" */
export type LoanProgramTransactionTypeStddevPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LoanProgramTransactionTypeStddevSampFields = {
  __typename?: 'loan_program_transaction_type_stddev_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "loan.program_transaction_type" */
export type LoanProgramTransactionTypeStddevSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LoanProgramTransactionTypeSumFields = {
  __typename?: 'loan_program_transaction_type_sum_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "loan.program_transaction_type" */
export type LoanProgramTransactionTypeSumOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "loan.program_transaction_type" */
export enum LoanProgramTransactionTypeUpdateColumn {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type LoanProgramTransactionTypeVarPopFields = {
  __typename?: 'loan_program_transaction_type_var_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "loan.program_transaction_type" */
export type LoanProgramTransactionTypeVarPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LoanProgramTransactionTypeVarSampFields = {
  __typename?: 'loan_program_transaction_type_var_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "loan.program_transaction_type" */
export type LoanProgramTransactionTypeVarSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LoanProgramTransactionTypeVarianceFields = {
  __typename?: 'loan_program_transaction_type_variance_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "loan.program_transaction_type" */
export type LoanProgramTransactionTypeVarianceOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "loan.program_transaction" */
export enum LoanProgramTransactionUpdateColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  ApplicationHeaderId = 'application_header_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  TypeDescription = 'type_description',
  /** column name */
  TypeId = 'type_id'
}

/** aggregate var_pop on columns */
export type LoanProgramTransactionVarPopFields = {
  __typename?: 'loan_program_transaction_var_pop_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "loan.program_transaction" */
export type LoanProgramTransactionVarPopOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LoanProgramTransactionVarSampFields = {
  __typename?: 'loan_program_transaction_var_samp_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "loan.program_transaction" */
export type LoanProgramTransactionVarSampOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LoanProgramTransactionVarianceFields = {
  __typename?: 'loan_program_transaction_variance_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "loan.program_transaction" */
export type LoanProgramTransactionVarianceOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
};

/** update columns of table "loan.program" */
export enum LoanProgramUpdateColumn {
  /** column name */
  BankProgramReference = 'bank_program_reference',
  /** column name */
  BudgetTotal = 'budget_total',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  MaxCredit = 'max_credit',
  /** column name */
  Name = 'name',
  /** column name */
  New = 'new',
  /** column name */
  Public = 'public',
  /** column name */
  Reference = 'reference',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Status = 'status',
  /** column name */
  StatusDescription = 'status_description',
  /** column name */
  StatusValue = 'status_value'
}

/** aggregate var_pop on columns */
export type LoanProgramVarPopFields = {
  __typename?: 'loan_program_var_pop_fields';
  /** วงเงินทั้งหมดของโครงการ */
  budget_total?: Maybe<Scalars['Float']>;
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "loan.program" */
export type LoanProgramVarPopOrderBy = {
  /** วงเงินทั้งหมดของโครงการ */
  budget_total?: Maybe<OrderBy>;
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LoanProgramVarSampFields = {
  __typename?: 'loan_program_var_samp_fields';
  /** วงเงินทั้งหมดของโครงการ */
  budget_total?: Maybe<Scalars['Float']>;
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "loan.program" */
export type LoanProgramVarSampOrderBy = {
  /** วงเงินทั้งหมดของโครงการ */
  budget_total?: Maybe<OrderBy>;
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LoanProgramVarianceFields = {
  __typename?: 'loan_program_variance_fields';
  /** วงเงินทั้งหมดของโครงการ */
  budget_total?: Maybe<Scalars['Float']>;
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "loan.program" */
export type LoanProgramVarianceOrderBy = {
  /** วงเงินทั้งหมดของโครงการ */
  budget_total?: Maybe<OrderBy>;
  /** วงเงินสูงสุดแต่ที่สามารถขอสินเชื่อได้แต่ละผู้ประกอบการ */
  max_credit?: Maybe<OrderBy>;
};

/**
 * สถานที่ตั้งของสถานประกอบการ และ/หรือ เครื่องจักร ที่ขอสินเชื่อจากกองทุน เป็นกรรมสิทธิ์ของใคร
 *
 *
 * columns and relationships of "loan.proprietary"
 */
export type LoanProprietary = {
  __typename?: 'loan_proprietary';
  /**
   * สถานะ
   * true = inactive
   * false = active
   */
  active: Scalars['Boolean'];
  /** An array relationship */
  application_details: Array<LoanApplicationDetail>;
  /** An aggregate relationship */
  application_details_aggregate: LoanApplicationDetailAggregate;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
  /** เพิ่มเติม อื่น ๆ */
  need_addition: Scalars['Boolean'];
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};


/**
 * สถานที่ตั้งของสถานประกอบการ และ/หรือ เครื่องจักร ที่ขอสินเชื่อจากกองทุน เป็นกรรมสิทธิ์ของใคร
 *
 *
 * columns and relationships of "loan.proprietary"
 */
export type LoanProprietaryApplicationDetailsArgs = {
  distinct_on?: Maybe<Array<LoanApplicationDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationDetailOrderBy>>;
  where?: Maybe<LoanApplicationDetailBoolExp>;
};


/**
 * สถานที่ตั้งของสถานประกอบการ และ/หรือ เครื่องจักร ที่ขอสินเชื่อจากกองทุน เป็นกรรมสิทธิ์ของใคร
 *
 *
 * columns and relationships of "loan.proprietary"
 */
export type LoanProprietaryApplicationDetailsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationDetailOrderBy>>;
  where?: Maybe<LoanApplicationDetailBoolExp>;
};

/** aggregated selection of "loan.proprietary" */
export type LoanProprietaryAggregate = {
  __typename?: 'loan_proprietary_aggregate';
  aggregate?: Maybe<LoanProprietaryAggregateFields>;
  nodes: Array<LoanProprietary>;
};

/** aggregate fields of "loan.proprietary" */
export type LoanProprietaryAggregateFields = {
  __typename?: 'loan_proprietary_aggregate_fields';
  avg?: Maybe<LoanProprietaryAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanProprietaryMaxFields>;
  min?: Maybe<LoanProprietaryMinFields>;
  stddev?: Maybe<LoanProprietaryStddevFields>;
  stddev_pop?: Maybe<LoanProprietaryStddevPopFields>;
  stddev_samp?: Maybe<LoanProprietaryStddevSampFields>;
  sum?: Maybe<LoanProprietarySumFields>;
  var_pop?: Maybe<LoanProprietaryVarPopFields>;
  var_samp?: Maybe<LoanProprietaryVarSampFields>;
  variance?: Maybe<LoanProprietaryVarianceFields>;
};


/** aggregate fields of "loan.proprietary" */
export type LoanProprietaryAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanProprietarySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LoanProprietaryAvgFields = {
  __typename?: 'loan_proprietary_avg_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "loan.proprietary". All fields are combined with a logical 'AND'. */
export type LoanProprietaryBoolExp = {
  _and?: Maybe<Array<LoanProprietaryBoolExp>>;
  _not?: Maybe<LoanProprietaryBoolExp>;
  _or?: Maybe<Array<LoanProprietaryBoolExp>>;
  active?: Maybe<BooleanComparisonExp>;
  application_details?: Maybe<LoanApplicationDetailBoolExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  need_addition?: Maybe<BooleanComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "loan.proprietary" */
export enum LoanProprietaryConstraint {
  /** unique or primary key constraint */
  ProprietaryPkey = 'proprietary_pkey'
}

/** input type for incrementing numeric columns in table "loan.proprietary" */
export type LoanProprietaryIncInput = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.proprietary" */
export type LoanProprietaryInsertInput = {
  /**
   * สถานะ
   * true = inactive
   * false = active
   */
  active?: Maybe<Scalars['Boolean']>;
  application_details?: Maybe<LoanApplicationDetailArrRelInsertInput>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** เพิ่มเติม อื่น ๆ */
  need_addition?: Maybe<Scalars['Boolean']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type LoanProprietaryMaxFields = {
  __typename?: 'loan_proprietary_max_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type LoanProprietaryMinFields = {
  __typename?: 'loan_proprietary_min_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "loan.proprietary" */
export type LoanProprietaryMutationResponse = {
  __typename?: 'loan_proprietary_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanProprietary>;
};

/** input type for inserting object relation for remote table "loan.proprietary" */
export type LoanProprietaryObjRelInsertInput = {
  data: LoanProprietaryInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanProprietaryOnConflict>;
};

/** on conflict condition type for table "loan.proprietary" */
export type LoanProprietaryOnConflict = {
  constraint: LoanProprietaryConstraint;
  update_columns?: Array<LoanProprietaryUpdateColumn>;
  where?: Maybe<LoanProprietaryBoolExp>;
};

/** Ordering options when selecting data from "loan.proprietary". */
export type LoanProprietaryOrderBy = {
  active?: Maybe<OrderBy>;
  application_details_aggregate?: Maybe<LoanApplicationDetailAggregateOrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  need_addition?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_proprietary */
export type LoanProprietaryPkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "loan.proprietary" */
export enum LoanProprietarySelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  NeedAddition = 'need_addition',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "loan.proprietary" */
export type LoanProprietarySetInput = {
  /**
   * สถานะ
   * true = inactive
   * false = active
   */
  active?: Maybe<Scalars['Boolean']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** เพิ่มเติม อื่น ๆ */
  need_addition?: Maybe<Scalars['Boolean']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type LoanProprietaryStddevFields = {
  __typename?: 'loan_proprietary_stddev_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LoanProprietaryStddevPopFields = {
  __typename?: 'loan_proprietary_stddev_pop_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LoanProprietaryStddevSampFields = {
  __typename?: 'loan_proprietary_stddev_samp_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LoanProprietarySumFields = {
  __typename?: 'loan_proprietary_sum_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** update columns of table "loan.proprietary" */
export enum LoanProprietaryUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  NeedAddition = 'need_addition',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type LoanProprietaryVarPopFields = {
  __typename?: 'loan_proprietary_var_pop_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LoanProprietaryVarSampFields = {
  __typename?: 'loan_proprietary_var_samp_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LoanProprietaryVarianceFields = {
  __typename?: 'loan_proprietary_variance_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/**
 * ประวัติการชำระหนี้
 *
 *
 * columns and relationships of "loan.repay_type"
 */
export type LoanRepayType = {
  __typename?: 'loan_repay_type';
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active: Scalars['Boolean'];
  /** An array relationship */
  application_details: Array<LoanApplicationDetail>;
  /** An aggregate relationship */
  application_details_aggregate: LoanApplicationDetailAggregate;
  /** An array relationship */
  children: Array<LoanRepayType>;
  /** An aggregate relationship */
  children_aggregate: LoanRepayTypeAggregate;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
  /** An object relationship */
  parent?: Maybe<LoanRepayType>;
  /** Parent Id */
  parent_id?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};


/**
 * ประวัติการชำระหนี้
 *
 *
 * columns and relationships of "loan.repay_type"
 */
export type LoanRepayTypeApplicationDetailsArgs = {
  distinct_on?: Maybe<Array<LoanApplicationDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationDetailOrderBy>>;
  where?: Maybe<LoanApplicationDetailBoolExp>;
};


/**
 * ประวัติการชำระหนี้
 *
 *
 * columns and relationships of "loan.repay_type"
 */
export type LoanRepayTypeApplicationDetailsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationDetailOrderBy>>;
  where?: Maybe<LoanApplicationDetailBoolExp>;
};


/**
 * ประวัติการชำระหนี้
 *
 *
 * columns and relationships of "loan.repay_type"
 */
export type LoanRepayTypeChildrenArgs = {
  distinct_on?: Maybe<Array<LoanRepayTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanRepayTypeOrderBy>>;
  where?: Maybe<LoanRepayTypeBoolExp>;
};


/**
 * ประวัติการชำระหนี้
 *
 *
 * columns and relationships of "loan.repay_type"
 */
export type LoanRepayTypeChildrenAggregateArgs = {
  distinct_on?: Maybe<Array<LoanRepayTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanRepayTypeOrderBy>>;
  where?: Maybe<LoanRepayTypeBoolExp>;
};

/** aggregated selection of "loan.repay_type" */
export type LoanRepayTypeAggregate = {
  __typename?: 'loan_repay_type_aggregate';
  aggregate?: Maybe<LoanRepayTypeAggregateFields>;
  nodes: Array<LoanRepayType>;
};

/** aggregate fields of "loan.repay_type" */
export type LoanRepayTypeAggregateFields = {
  __typename?: 'loan_repay_type_aggregate_fields';
  avg?: Maybe<LoanRepayTypeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanRepayTypeMaxFields>;
  min?: Maybe<LoanRepayTypeMinFields>;
  stddev?: Maybe<LoanRepayTypeStddevFields>;
  stddev_pop?: Maybe<LoanRepayTypeStddevPopFields>;
  stddev_samp?: Maybe<LoanRepayTypeStddevSampFields>;
  sum?: Maybe<LoanRepayTypeSumFields>;
  var_pop?: Maybe<LoanRepayTypeVarPopFields>;
  var_samp?: Maybe<LoanRepayTypeVarSampFields>;
  variance?: Maybe<LoanRepayTypeVarianceFields>;
};


/** aggregate fields of "loan.repay_type" */
export type LoanRepayTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanRepayTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan.repay_type" */
export type LoanRepayTypeAggregateOrderBy = {
  avg?: Maybe<LoanRepayTypeAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanRepayTypeMaxOrderBy>;
  min?: Maybe<LoanRepayTypeMinOrderBy>;
  stddev?: Maybe<LoanRepayTypeStddevOrderBy>;
  stddev_pop?: Maybe<LoanRepayTypeStddevPopOrderBy>;
  stddev_samp?: Maybe<LoanRepayTypeStddevSampOrderBy>;
  sum?: Maybe<LoanRepayTypeSumOrderBy>;
  var_pop?: Maybe<LoanRepayTypeVarPopOrderBy>;
  var_samp?: Maybe<LoanRepayTypeVarSampOrderBy>;
  variance?: Maybe<LoanRepayTypeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "loan.repay_type" */
export type LoanRepayTypeArrRelInsertInput = {
  data: Array<LoanRepayTypeInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanRepayTypeOnConflict>;
};

/** aggregate avg on columns */
export type LoanRepayTypeAvgFields = {
  __typename?: 'loan_repay_type_avg_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "loan.repay_type" */
export type LoanRepayTypeAvgOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "loan.repay_type". All fields are combined with a logical 'AND'. */
export type LoanRepayTypeBoolExp = {
  _and?: Maybe<Array<LoanRepayTypeBoolExp>>;
  _not?: Maybe<LoanRepayTypeBoolExp>;
  _or?: Maybe<Array<LoanRepayTypeBoolExp>>;
  active?: Maybe<BooleanComparisonExp>;
  application_details?: Maybe<LoanApplicationDetailBoolExp>;
  children?: Maybe<LoanRepayTypeBoolExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  parent?: Maybe<LoanRepayTypeBoolExp>;
  parent_id?: Maybe<StringComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "loan.repay_type" */
export enum LoanRepayTypeConstraint {
  /** unique or primary key constraint */
  RepayTypePkey = 'repay_type_pkey'
}

/** input type for incrementing numeric columns in table "loan.repay_type" */
export type LoanRepayTypeIncInput = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.repay_type" */
export type LoanRepayTypeInsertInput = {
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active?: Maybe<Scalars['Boolean']>;
  application_details?: Maybe<LoanApplicationDetailArrRelInsertInput>;
  children?: Maybe<LoanRepayTypeArrRelInsertInput>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  parent?: Maybe<LoanRepayTypeObjRelInsertInput>;
  /** Parent Id */
  parent_id?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type LoanRepayTypeMaxFields = {
  __typename?: 'loan_repay_type_max_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** Parent Id */
  parent_id?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "loan.repay_type" */
export type LoanRepayTypeMaxOrderBy = {
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** ข้อความแสดงผล */
  description?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** Parent Id */
  parent_id?: Maybe<OrderBy>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanRepayTypeMinFields = {
  __typename?: 'loan_repay_type_min_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** Parent Id */
  parent_id?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "loan.repay_type" */
export type LoanRepayTypeMinOrderBy = {
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** ข้อความแสดงผล */
  description?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** Parent Id */
  parent_id?: Maybe<OrderBy>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan.repay_type" */
export type LoanRepayTypeMutationResponse = {
  __typename?: 'loan_repay_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanRepayType>;
};

/** input type for inserting object relation for remote table "loan.repay_type" */
export type LoanRepayTypeObjRelInsertInput = {
  data: LoanRepayTypeInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanRepayTypeOnConflict>;
};

/** on conflict condition type for table "loan.repay_type" */
export type LoanRepayTypeOnConflict = {
  constraint: LoanRepayTypeConstraint;
  update_columns?: Array<LoanRepayTypeUpdateColumn>;
  where?: Maybe<LoanRepayTypeBoolExp>;
};

/** Ordering options when selecting data from "loan.repay_type". */
export type LoanRepayTypeOrderBy = {
  active?: Maybe<OrderBy>;
  application_details_aggregate?: Maybe<LoanApplicationDetailAggregateOrderBy>;
  children_aggregate?: Maybe<LoanRepayTypeAggregateOrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  parent?: Maybe<LoanRepayTypeOrderBy>;
  parent_id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_repay_type */
export type LoanRepayTypePkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "loan.repay_type" */
export enum LoanRepayTypeSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "loan.repay_type" */
export type LoanRepayTypeSetInput = {
  /**
   * สถานะ
   * false = inactive
   * true = active
   */
  active?: Maybe<Scalars['Boolean']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** Parent Id */
  parent_id?: Maybe<Scalars['String']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type LoanRepayTypeStddevFields = {
  __typename?: 'loan_repay_type_stddev_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "loan.repay_type" */
export type LoanRepayTypeStddevOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LoanRepayTypeStddevPopFields = {
  __typename?: 'loan_repay_type_stddev_pop_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "loan.repay_type" */
export type LoanRepayTypeStddevPopOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LoanRepayTypeStddevSampFields = {
  __typename?: 'loan_repay_type_stddev_samp_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "loan.repay_type" */
export type LoanRepayTypeStddevSampOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LoanRepayTypeSumFields = {
  __typename?: 'loan_repay_type_sum_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "loan.repay_type" */
export type LoanRepayTypeSumOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "loan.repay_type" */
export enum LoanRepayTypeUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type LoanRepayTypeVarPopFields = {
  __typename?: 'loan_repay_type_var_pop_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "loan.repay_type" */
export type LoanRepayTypeVarPopOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LoanRepayTypeVarSampFields = {
  __typename?: 'loan_repay_type_var_samp_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "loan.repay_type" */
export type LoanRepayTypeVarSampOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LoanRepayTypeVarianceFields = {
  __typename?: 'loan_repay_type_variance_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "loan.repay_type" */
export type LoanRepayTypeVarianceOrderBy = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<OrderBy>;
};

/**
 * S-Curve
 *
 *
 * columns and relationships of "loan.s_curve"
 */
export type LoanSCurve = {
  __typename?: 'loan_s_curve';
  active: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** An object relationship */
  s_curve_type?: Maybe<LoanSCurveType>;
  s_curve_type_description?: Maybe<Scalars['String']>;
  s_curve_type_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "loan.s_curve" */
export type LoanSCurveAggregate = {
  __typename?: 'loan_s_curve_aggregate';
  aggregate?: Maybe<LoanSCurveAggregateFields>;
  nodes: Array<LoanSCurve>;
};

/** aggregate fields of "loan.s_curve" */
export type LoanSCurveAggregateFields = {
  __typename?: 'loan_s_curve_aggregate_fields';
  avg?: Maybe<LoanSCurveAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanSCurveMaxFields>;
  min?: Maybe<LoanSCurveMinFields>;
  stddev?: Maybe<LoanSCurveStddevFields>;
  stddev_pop?: Maybe<LoanSCurveStddevPopFields>;
  stddev_samp?: Maybe<LoanSCurveStddevSampFields>;
  sum?: Maybe<LoanSCurveSumFields>;
  var_pop?: Maybe<LoanSCurveVarPopFields>;
  var_samp?: Maybe<LoanSCurveVarSampFields>;
  variance?: Maybe<LoanSCurveVarianceFields>;
};


/** aggregate fields of "loan.s_curve" */
export type LoanSCurveAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanSCurveSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan.s_curve" */
export type LoanSCurveAggregateOrderBy = {
  avg?: Maybe<LoanSCurveAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanSCurveMaxOrderBy>;
  min?: Maybe<LoanSCurveMinOrderBy>;
  stddev?: Maybe<LoanSCurveStddevOrderBy>;
  stddev_pop?: Maybe<LoanSCurveStddevPopOrderBy>;
  stddev_samp?: Maybe<LoanSCurveStddevSampOrderBy>;
  sum?: Maybe<LoanSCurveSumOrderBy>;
  var_pop?: Maybe<LoanSCurveVarPopOrderBy>;
  var_samp?: Maybe<LoanSCurveVarSampOrderBy>;
  variance?: Maybe<LoanSCurveVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "loan.s_curve" */
export type LoanSCurveArrRelInsertInput = {
  data: Array<LoanSCurveInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanSCurveOnConflict>;
};

/** aggregate avg on columns */
export type LoanSCurveAvgFields = {
  __typename?: 'loan_s_curve_avg_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "loan.s_curve" */
export type LoanSCurveAvgOrderBy = {
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "loan.s_curve". All fields are combined with a logical 'AND'. */
export type LoanSCurveBoolExp = {
  _and?: Maybe<Array<LoanSCurveBoolExp>>;
  _not?: Maybe<LoanSCurveBoolExp>;
  _or?: Maybe<Array<LoanSCurveBoolExp>>;
  active?: Maybe<BooleanComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  s_curve_type?: Maybe<LoanSCurveTypeBoolExp>;
  s_curve_type_description?: Maybe<StringComparisonExp>;
  s_curve_type_id?: Maybe<UuidComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "loan.s_curve" */
export enum LoanSCurveConstraint {
  /** unique or primary key constraint */
  SCurvePkey = 's_curve_pkey'
}

/** input type for incrementing numeric columns in table "loan.s_curve" */
export type LoanSCurveIncInput = {
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.s_curve" */
export type LoanSCurveInsertInput = {
  active?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  s_curve_type?: Maybe<LoanSCurveTypeObjRelInsertInput>;
  s_curve_type_description?: Maybe<Scalars['String']>;
  s_curve_type_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type LoanSCurveMaxFields = {
  __typename?: 'loan_s_curve_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  s_curve_type_description?: Maybe<Scalars['String']>;
  s_curve_type_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "loan.s_curve" */
export type LoanSCurveMaxOrderBy = {
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  s_curve_type_description?: Maybe<OrderBy>;
  s_curve_type_id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanSCurveMinFields = {
  __typename?: 'loan_s_curve_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  s_curve_type_description?: Maybe<Scalars['String']>;
  s_curve_type_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "loan.s_curve" */
export type LoanSCurveMinOrderBy = {
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  s_curve_type_description?: Maybe<OrderBy>;
  s_curve_type_id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan.s_curve" */
export type LoanSCurveMutationResponse = {
  __typename?: 'loan_s_curve_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanSCurve>;
};

/** on conflict condition type for table "loan.s_curve" */
export type LoanSCurveOnConflict = {
  constraint: LoanSCurveConstraint;
  update_columns?: Array<LoanSCurveUpdateColumn>;
  where?: Maybe<LoanSCurveBoolExp>;
};

/** Ordering options when selecting data from "loan.s_curve". */
export type LoanSCurveOrderBy = {
  active?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  s_curve_type?: Maybe<LoanSCurveTypeOrderBy>;
  s_curve_type_description?: Maybe<OrderBy>;
  s_curve_type_id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_s_curve */
export type LoanSCurvePkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "loan.s_curve" */
export enum LoanSCurveSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  SCurveTypeDescription = 's_curve_type_description',
  /** column name */
  SCurveTypeId = 's_curve_type_id',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "loan.s_curve" */
export type LoanSCurveSetInput = {
  active?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  s_curve_type_description?: Maybe<Scalars['String']>;
  s_curve_type_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type LoanSCurveStddevFields = {
  __typename?: 'loan_s_curve_stddev_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "loan.s_curve" */
export type LoanSCurveStddevOrderBy = {
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LoanSCurveStddevPopFields = {
  __typename?: 'loan_s_curve_stddev_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "loan.s_curve" */
export type LoanSCurveStddevPopOrderBy = {
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LoanSCurveStddevSampFields = {
  __typename?: 'loan_s_curve_stddev_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "loan.s_curve" */
export type LoanSCurveStddevSampOrderBy = {
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LoanSCurveSumFields = {
  __typename?: 'loan_s_curve_sum_fields';
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "loan.s_curve" */
export type LoanSCurveSumOrderBy = {
  sequence?: Maybe<OrderBy>;
};

/**
 * ประเภทกลุ่ม S Curve
 *
 *
 * columns and relationships of "loan.s_curve_type"
 */
export type LoanSCurveType = {
  __typename?: 'loan_s_curve_type';
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  s_curves: Array<LoanSCurve>;
  /** An aggregate relationship */
  s_curves_aggregate: LoanSCurveAggregate;
  sequence: Scalars['numeric'];
};


/**
 * ประเภทกลุ่ม S Curve
 *
 *
 * columns and relationships of "loan.s_curve_type"
 */
export type LoanSCurveTypeSCurvesArgs = {
  distinct_on?: Maybe<Array<LoanSCurveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSCurveOrderBy>>;
  where?: Maybe<LoanSCurveBoolExp>;
};


/**
 * ประเภทกลุ่ม S Curve
 *
 *
 * columns and relationships of "loan.s_curve_type"
 */
export type LoanSCurveTypeSCurvesAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSCurveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSCurveOrderBy>>;
  where?: Maybe<LoanSCurveBoolExp>;
};

/** aggregated selection of "loan.s_curve_type" */
export type LoanSCurveTypeAggregate = {
  __typename?: 'loan_s_curve_type_aggregate';
  aggregate?: Maybe<LoanSCurveTypeAggregateFields>;
  nodes: Array<LoanSCurveType>;
};

/** aggregate fields of "loan.s_curve_type" */
export type LoanSCurveTypeAggregateFields = {
  __typename?: 'loan_s_curve_type_aggregate_fields';
  avg?: Maybe<LoanSCurveTypeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanSCurveTypeMaxFields>;
  min?: Maybe<LoanSCurveTypeMinFields>;
  stddev?: Maybe<LoanSCurveTypeStddevFields>;
  stddev_pop?: Maybe<LoanSCurveTypeStddevPopFields>;
  stddev_samp?: Maybe<LoanSCurveTypeStddevSampFields>;
  sum?: Maybe<LoanSCurveTypeSumFields>;
  var_pop?: Maybe<LoanSCurveTypeVarPopFields>;
  var_samp?: Maybe<LoanSCurveTypeVarSampFields>;
  variance?: Maybe<LoanSCurveTypeVarianceFields>;
};


/** aggregate fields of "loan.s_curve_type" */
export type LoanSCurveTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanSCurveTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LoanSCurveTypeAvgFields = {
  __typename?: 'loan_s_curve_type_avg_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "loan.s_curve_type". All fields are combined with a logical 'AND'. */
export type LoanSCurveTypeBoolExp = {
  _and?: Maybe<Array<LoanSCurveTypeBoolExp>>;
  _not?: Maybe<LoanSCurveTypeBoolExp>;
  _or?: Maybe<Array<LoanSCurveTypeBoolExp>>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  s_curves?: Maybe<LoanSCurveBoolExp>;
  sequence?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "loan.s_curve_type" */
export enum LoanSCurveTypeConstraint {
  /** unique or primary key constraint */
  SCurveTypePkey = 's_curve_type_pkey'
}

/** input type for incrementing numeric columns in table "loan.s_curve_type" */
export type LoanSCurveTypeIncInput = {
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.s_curve_type" */
export type LoanSCurveTypeInsertInput = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  s_curves?: Maybe<LoanSCurveArrRelInsertInput>;
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type LoanSCurveTypeMaxFields = {
  __typename?: 'loan_s_curve_type_max_fields';
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type LoanSCurveTypeMinFields = {
  __typename?: 'loan_s_curve_type_min_fields';
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "loan.s_curve_type" */
export type LoanSCurveTypeMutationResponse = {
  __typename?: 'loan_s_curve_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanSCurveType>;
};

/** input type for inserting object relation for remote table "loan.s_curve_type" */
export type LoanSCurveTypeObjRelInsertInput = {
  data: LoanSCurveTypeInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanSCurveTypeOnConflict>;
};

/** on conflict condition type for table "loan.s_curve_type" */
export type LoanSCurveTypeOnConflict = {
  constraint: LoanSCurveTypeConstraint;
  update_columns?: Array<LoanSCurveTypeUpdateColumn>;
  where?: Maybe<LoanSCurveTypeBoolExp>;
};

/** Ordering options when selecting data from "loan.s_curve_type". */
export type LoanSCurveTypeOrderBy = {
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  s_curves_aggregate?: Maybe<LoanSCurveAggregateOrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_s_curve_type */
export type LoanSCurveTypePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "loan.s_curve_type" */
export enum LoanSCurveTypeSelectColumn {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "loan.s_curve_type" */
export type LoanSCurveTypeSetInput = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type LoanSCurveTypeStddevFields = {
  __typename?: 'loan_s_curve_type_stddev_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LoanSCurveTypeStddevPopFields = {
  __typename?: 'loan_s_curve_type_stddev_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LoanSCurveTypeStddevSampFields = {
  __typename?: 'loan_s_curve_type_stddev_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LoanSCurveTypeSumFields = {
  __typename?: 'loan_s_curve_type_sum_fields';
  sequence?: Maybe<Scalars['numeric']>;
};

/** update columns of table "loan.s_curve_type" */
export enum LoanSCurveTypeUpdateColumn {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type LoanSCurveTypeVarPopFields = {
  __typename?: 'loan_s_curve_type_var_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LoanSCurveTypeVarSampFields = {
  __typename?: 'loan_s_curve_type_var_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LoanSCurveTypeVarianceFields = {
  __typename?: 'loan_s_curve_type_variance_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** update columns of table "loan.s_curve" */
export enum LoanSCurveUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  SCurveTypeDescription = 's_curve_type_description',
  /** column name */
  SCurveTypeId = 's_curve_type_id',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type LoanSCurveVarPopFields = {
  __typename?: 'loan_s_curve_var_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "loan.s_curve" */
export type LoanSCurveVarPopOrderBy = {
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LoanSCurveVarSampFields = {
  __typename?: 'loan_s_curve_var_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "loan.s_curve" */
export type LoanSCurveVarSampOrderBy = {
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LoanSCurveVarianceFields = {
  __typename?: 'loan_s_curve_variance_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "loan.s_curve" */
export type LoanSCurveVarianceOrderBy = {
  sequence?: Maybe<OrderBy>;
};

/**
 * คำนิยาม SMEs
 *
 *
 * columns and relationships of "loan.sme_definition"
 */
export type LoanSmeDefinition = {
  __typename?: 'loan_sme_definition';
  /** An array relationship */
  application_details: Array<LoanApplicationDetail>;
  /** An aggregate relationship */
  application_details_aggregate: LoanApplicationDetailAggregate;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence: Scalars['numeric'];
};


/**
 * คำนิยาม SMEs
 *
 *
 * columns and relationships of "loan.sme_definition"
 */
export type LoanSmeDefinitionApplicationDetailsArgs = {
  distinct_on?: Maybe<Array<LoanApplicationDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationDetailOrderBy>>;
  where?: Maybe<LoanApplicationDetailBoolExp>;
};


/**
 * คำนิยาม SMEs
 *
 *
 * columns and relationships of "loan.sme_definition"
 */
export type LoanSmeDefinitionApplicationDetailsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationDetailOrderBy>>;
  where?: Maybe<LoanApplicationDetailBoolExp>;
};

/** aggregated selection of "loan.sme_definition" */
export type LoanSmeDefinitionAggregate = {
  __typename?: 'loan_sme_definition_aggregate';
  aggregate?: Maybe<LoanSmeDefinitionAggregateFields>;
  nodes: Array<LoanSmeDefinition>;
};

/** aggregate fields of "loan.sme_definition" */
export type LoanSmeDefinitionAggregateFields = {
  __typename?: 'loan_sme_definition_aggregate_fields';
  avg?: Maybe<LoanSmeDefinitionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanSmeDefinitionMaxFields>;
  min?: Maybe<LoanSmeDefinitionMinFields>;
  stddev?: Maybe<LoanSmeDefinitionStddevFields>;
  stddev_pop?: Maybe<LoanSmeDefinitionStddevPopFields>;
  stddev_samp?: Maybe<LoanSmeDefinitionStddevSampFields>;
  sum?: Maybe<LoanSmeDefinitionSumFields>;
  var_pop?: Maybe<LoanSmeDefinitionVarPopFields>;
  var_samp?: Maybe<LoanSmeDefinitionVarSampFields>;
  variance?: Maybe<LoanSmeDefinitionVarianceFields>;
};


/** aggregate fields of "loan.sme_definition" */
export type LoanSmeDefinitionAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanSmeDefinitionSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LoanSmeDefinitionAvgFields = {
  __typename?: 'loan_sme_definition_avg_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "loan.sme_definition". All fields are combined with a logical 'AND'. */
export type LoanSmeDefinitionBoolExp = {
  _and?: Maybe<Array<LoanSmeDefinitionBoolExp>>;
  _not?: Maybe<LoanSmeDefinitionBoolExp>;
  _or?: Maybe<Array<LoanSmeDefinitionBoolExp>>;
  application_details?: Maybe<LoanApplicationDetailBoolExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "loan.sme_definition" */
export enum LoanSmeDefinitionConstraint {
  /** unique or primary key constraint */
  SmeDefinationPkey = 'sme_defination_pkey'
}

/** input type for incrementing numeric columns in table "loan.sme_definition" */
export type LoanSmeDefinitionIncInput = {
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan.sme_definition" */
export type LoanSmeDefinitionInsertInput = {
  application_details?: Maybe<LoanApplicationDetailArrRelInsertInput>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type LoanSmeDefinitionMaxFields = {
  __typename?: 'loan_sme_definition_max_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type LoanSmeDefinitionMinFields = {
  __typename?: 'loan_sme_definition_min_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "loan.sme_definition" */
export type LoanSmeDefinitionMutationResponse = {
  __typename?: 'loan_sme_definition_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanSmeDefinition>;
};

/** input type for inserting object relation for remote table "loan.sme_definition" */
export type LoanSmeDefinitionObjRelInsertInput = {
  data: LoanSmeDefinitionInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanSmeDefinitionOnConflict>;
};

/** on conflict condition type for table "loan.sme_definition" */
export type LoanSmeDefinitionOnConflict = {
  constraint: LoanSmeDefinitionConstraint;
  update_columns?: Array<LoanSmeDefinitionUpdateColumn>;
  where?: Maybe<LoanSmeDefinitionBoolExp>;
};

/** Ordering options when selecting data from "loan.sme_definition". */
export type LoanSmeDefinitionOrderBy = {
  application_details_aggregate?: Maybe<LoanApplicationDetailAggregateOrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_sme_definition */
export type LoanSmeDefinitionPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "loan.sme_definition" */
export enum LoanSmeDefinitionSelectColumn {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "loan.sme_definition" */
export type LoanSmeDefinitionSetInput = {
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความแสดงผล */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type LoanSmeDefinitionStddevFields = {
  __typename?: 'loan_sme_definition_stddev_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LoanSmeDefinitionStddevPopFields = {
  __typename?: 'loan_sme_definition_stddev_pop_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LoanSmeDefinitionStddevSampFields = {
  __typename?: 'loan_sme_definition_stddev_samp_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LoanSmeDefinitionSumFields = {
  __typename?: 'loan_sme_definition_sum_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['numeric']>;
};

/** update columns of table "loan.sme_definition" */
export enum LoanSmeDefinitionUpdateColumn {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type LoanSmeDefinitionVarPopFields = {
  __typename?: 'loan_sme_definition_var_pop_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LoanSmeDefinitionVarSampFields = {
  __typename?: 'loan_sme_definition_var_samp_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LoanSmeDefinitionVarianceFields = {
  __typename?: 'loan_sme_definition_variance_fields';
  /** ลำดับการแสดงผล โดยเรียงจากน้อยไปมาก */
  sequence?: Maybe<Scalars['Float']>;
};

/**
 * ประเภทธุรกิจ
 *
 *
 * columns and relationships of "loan_source.business_type"
 */
export type LoanSourceBusinessType = {
  __typename?: 'loan_source_business_type';
  /** คำอธิบาย ประเภทธุรกิจ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
  /** An array relationship */
  loan_business_types: Array<LoanSourceLoanBusinessType>;
  /** An aggregate relationship */
  loan_business_types_aggregate: LoanSourceLoanBusinessTypeAggregate;
};


/**
 * ประเภทธุรกิจ
 *
 *
 * columns and relationships of "loan_source.business_type"
 */
export type LoanSourceBusinessTypeLoanBusinessTypesArgs = {
  distinct_on?: Maybe<Array<LoanSourceLoanBusinessTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceLoanBusinessTypeOrderBy>>;
  where?: Maybe<LoanSourceLoanBusinessTypeBoolExp>;
};


/**
 * ประเภทธุรกิจ
 *
 *
 * columns and relationships of "loan_source.business_type"
 */
export type LoanSourceBusinessTypeLoanBusinessTypesAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSourceLoanBusinessTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceLoanBusinessTypeOrderBy>>;
  where?: Maybe<LoanSourceLoanBusinessTypeBoolExp>;
};

/** aggregated selection of "loan_source.business_type" */
export type LoanSourceBusinessTypeAggregate = {
  __typename?: 'loan_source_business_type_aggregate';
  aggregate?: Maybe<LoanSourceBusinessTypeAggregateFields>;
  nodes: Array<LoanSourceBusinessType>;
};

/** aggregate fields of "loan_source.business_type" */
export type LoanSourceBusinessTypeAggregateFields = {
  __typename?: 'loan_source_business_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LoanSourceBusinessTypeMaxFields>;
  min?: Maybe<LoanSourceBusinessTypeMinFields>;
};


/** aggregate fields of "loan_source.business_type" */
export type LoanSourceBusinessTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanSourceBusinessTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "loan_source.business_type". All fields are combined with a logical 'AND'. */
export type LoanSourceBusinessTypeBoolExp = {
  _and?: Maybe<Array<LoanSourceBusinessTypeBoolExp>>;
  _not?: Maybe<LoanSourceBusinessTypeBoolExp>;
  _or?: Maybe<Array<LoanSourceBusinessTypeBoolExp>>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  loan_business_types?: Maybe<LoanSourceLoanBusinessTypeBoolExp>;
};

/** unique or primary key constraints on table "loan_source.business_type" */
export enum LoanSourceBusinessTypeConstraint {
  /** unique or primary key constraint */
  BusinessTypePkey = 'business_type_pkey'
}

/** input type for inserting data into table "loan_source.business_type" */
export type LoanSourceBusinessTypeInsertInput = {
  /** คำอธิบาย ประเภทธุรกิจ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  loan_business_types?: Maybe<LoanSourceLoanBusinessTypeArrRelInsertInput>;
};

/** aggregate max on columns */
export type LoanSourceBusinessTypeMaxFields = {
  __typename?: 'loan_source_business_type_max_fields';
  /** คำอธิบาย ประเภทธุรกิจ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type LoanSourceBusinessTypeMinFields = {
  __typename?: 'loan_source_business_type_min_fields';
  /** คำอธิบาย ประเภทธุรกิจ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "loan_source.business_type" */
export type LoanSourceBusinessTypeMutationResponse = {
  __typename?: 'loan_source_business_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanSourceBusinessType>;
};

/** input type for inserting object relation for remote table "loan_source.business_type" */
export type LoanSourceBusinessTypeObjRelInsertInput = {
  data: LoanSourceBusinessTypeInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanSourceBusinessTypeOnConflict>;
};

/** on conflict condition type for table "loan_source.business_type" */
export type LoanSourceBusinessTypeOnConflict = {
  constraint: LoanSourceBusinessTypeConstraint;
  update_columns?: Array<LoanSourceBusinessTypeUpdateColumn>;
  where?: Maybe<LoanSourceBusinessTypeBoolExp>;
};

/** Ordering options when selecting data from "loan_source.business_type". */
export type LoanSourceBusinessTypeOrderBy = {
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  loan_business_types_aggregate?: Maybe<LoanSourceLoanBusinessTypeAggregateOrderBy>;
};

/** primary key columns input for table: loan_source_business_type */
export type LoanSourceBusinessTypePkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "loan_source.business_type" */
export enum LoanSourceBusinessTypeSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "loan_source.business_type" */
export type LoanSourceBusinessTypeSetInput = {
  /** คำอธิบาย ประเภทธุรกิจ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** update columns of table "loan_source.business_type" */
export enum LoanSourceBusinessTypeUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/**
 * สถาบันการเงิน
 *
 *
 * columns and relationships of "loan_source.financial_institution"
 */
export type LoanSourceFinancialInstitution = {
  __typename?: 'loan_source_financial_institution';
  /** คำอธิบาย สถาบันการเงิน */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
  /** An array relationship */
  loans: Array<LoanSourceLoan>;
  /** An aggregate relationship */
  loans_aggregate: LoanSourceLoanAggregate;
};


/**
 * สถาบันการเงิน
 *
 *
 * columns and relationships of "loan_source.financial_institution"
 */
export type LoanSourceFinancialInstitutionLoansArgs = {
  distinct_on?: Maybe<Array<LoanSourceLoanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceLoanOrderBy>>;
  where?: Maybe<LoanSourceLoanBoolExp>;
};


/**
 * สถาบันการเงิน
 *
 *
 * columns and relationships of "loan_source.financial_institution"
 */
export type LoanSourceFinancialInstitutionLoansAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSourceLoanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceLoanOrderBy>>;
  where?: Maybe<LoanSourceLoanBoolExp>;
};

/** aggregated selection of "loan_source.financial_institution" */
export type LoanSourceFinancialInstitutionAggregate = {
  __typename?: 'loan_source_financial_institution_aggregate';
  aggregate?: Maybe<LoanSourceFinancialInstitutionAggregateFields>;
  nodes: Array<LoanSourceFinancialInstitution>;
};

/** aggregate fields of "loan_source.financial_institution" */
export type LoanSourceFinancialInstitutionAggregateFields = {
  __typename?: 'loan_source_financial_institution_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LoanSourceFinancialInstitutionMaxFields>;
  min?: Maybe<LoanSourceFinancialInstitutionMinFields>;
};


/** aggregate fields of "loan_source.financial_institution" */
export type LoanSourceFinancialInstitutionAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanSourceFinancialInstitutionSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "loan_source.financial_institution". All fields are combined with a logical 'AND'. */
export type LoanSourceFinancialInstitutionBoolExp = {
  _and?: Maybe<Array<LoanSourceFinancialInstitutionBoolExp>>;
  _not?: Maybe<LoanSourceFinancialInstitutionBoolExp>;
  _or?: Maybe<Array<LoanSourceFinancialInstitutionBoolExp>>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  loans?: Maybe<LoanSourceLoanBoolExp>;
};

/** unique or primary key constraints on table "loan_source.financial_institution" */
export enum LoanSourceFinancialInstitutionConstraint {
  /** unique or primary key constraint */
  FinancialInstitutionPkey = 'financial_institution_pkey'
}

export enum LoanSourceFinancialInstitutionEnum {
  /** demo */
  Demo = 'demo',
  /** กสิกร */
  Kbank = 'kbank',
  /** กรุงไทย */
  Krungthai = 'krungthai',
  /** ไทยพาณิชย์ */
  Scb = 'scb',
  /** ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย */
  Smebank = 'smebank'
}

/** Boolean expression to compare columns of type "loan_source_financial_institution_enum". All fields are combined with logical 'AND'. */
export type LoanSourceFinancialInstitutionEnumComparisonExp = {
  _eq?: Maybe<LoanSourceFinancialInstitutionEnum>;
  _in?: Maybe<Array<LoanSourceFinancialInstitutionEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<LoanSourceFinancialInstitutionEnum>;
  _nin?: Maybe<Array<LoanSourceFinancialInstitutionEnum>>;
};

/** input type for inserting data into table "loan_source.financial_institution" */
export type LoanSourceFinancialInstitutionInsertInput = {
  /** คำอธิบาย สถาบันการเงิน */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  loans?: Maybe<LoanSourceLoanArrRelInsertInput>;
};

/** aggregate max on columns */
export type LoanSourceFinancialInstitutionMaxFields = {
  __typename?: 'loan_source_financial_institution_max_fields';
  /** คำอธิบาย สถาบันการเงิน */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type LoanSourceFinancialInstitutionMinFields = {
  __typename?: 'loan_source_financial_institution_min_fields';
  /** คำอธิบาย สถาบันการเงิน */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "loan_source.financial_institution" */
export type LoanSourceFinancialInstitutionMutationResponse = {
  __typename?: 'loan_source_financial_institution_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanSourceFinancialInstitution>;
};

/** input type for inserting object relation for remote table "loan_source.financial_institution" */
export type LoanSourceFinancialInstitutionObjRelInsertInput = {
  data: LoanSourceFinancialInstitutionInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanSourceFinancialInstitutionOnConflict>;
};

/** on conflict condition type for table "loan_source.financial_institution" */
export type LoanSourceFinancialInstitutionOnConflict = {
  constraint: LoanSourceFinancialInstitutionConstraint;
  update_columns?: Array<LoanSourceFinancialInstitutionUpdateColumn>;
  where?: Maybe<LoanSourceFinancialInstitutionBoolExp>;
};

/** Ordering options when selecting data from "loan_source.financial_institution". */
export type LoanSourceFinancialInstitutionOrderBy = {
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  loans_aggregate?: Maybe<LoanSourceLoanAggregateOrderBy>;
};

/** primary key columns input for table: loan_source_financial_institution */
export type LoanSourceFinancialInstitutionPkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "loan_source.financial_institution" */
export enum LoanSourceFinancialInstitutionSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "loan_source.financial_institution" */
export type LoanSourceFinancialInstitutionSetInput = {
  /** คำอธิบาย สถาบันการเงิน */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** update columns of table "loan_source.financial_institution" */
export enum LoanSourceFinancialInstitutionUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/**
 * แหล่งเงินกู้
 *
 *
 * columns and relationships of "loan_source.loan"
 */
export type LoanSourceLoan = {
  __typename?: 'loan_source_loan';
  /** An array relationship */
  attachments: Array<LoanSourceLoanAttachment>;
  /** An aggregate relationship */
  attachments_aggregate: LoanSourceLoanAttachmentAggregate;
  /** content */
  content?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  financial_institution?: Maybe<LoanSourceFinancialInstitution>;
  /** Primary key */
  id: Scalars['uuid'];
  /** An array relationship */
  loan_business_types: Array<LoanSourceLoanBusinessType>;
  /** An aggregate relationship */
  loan_business_types_aggregate: LoanSourceLoanBusinessTypeAggregate;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<Scalars['numeric']>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<Scalars['numeric']>;
  /** ชื่อแหล่งเงินกู้ */
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  objectives: Array<LoanSourceObjective>;
  /** An aggregate relationship */
  objectives_aggregate: LoanSourceObjectiveAggregate;
  /** ระยะเวลาผ่อน */
  period?: Maybe<Scalars['numeric']>;
  /** โปรโมชั่น */
  promotion?: Maybe<Scalars['String']>;
  /** true = แสดงให้บุคคลทั่วไปเห็น, false = ไม่แสดงให้บุคคลทั่วไปเห็น */
  published: Scalars['Boolean'];
  /** รหัสอ้างอิง */
  reference?: Maybe<Scalars['String']>;
  /** สถาบันการเงิน */
  source_id?: Maybe<LoanSourceFinancialInstitutionEnum>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};


/**
 * แหล่งเงินกู้
 *
 *
 * columns and relationships of "loan_source.loan"
 */
export type LoanSourceLoanAttachmentsArgs = {
  distinct_on?: Maybe<Array<LoanSourceLoanAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceLoanAttachmentOrderBy>>;
  where?: Maybe<LoanSourceLoanAttachmentBoolExp>;
};


/**
 * แหล่งเงินกู้
 *
 *
 * columns and relationships of "loan_source.loan"
 */
export type LoanSourceLoanAttachmentsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSourceLoanAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceLoanAttachmentOrderBy>>;
  where?: Maybe<LoanSourceLoanAttachmentBoolExp>;
};


/**
 * แหล่งเงินกู้
 *
 *
 * columns and relationships of "loan_source.loan"
 */
export type LoanSourceLoanLoanBusinessTypesArgs = {
  distinct_on?: Maybe<Array<LoanSourceLoanBusinessTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceLoanBusinessTypeOrderBy>>;
  where?: Maybe<LoanSourceLoanBusinessTypeBoolExp>;
};


/**
 * แหล่งเงินกู้
 *
 *
 * columns and relationships of "loan_source.loan"
 */
export type LoanSourceLoanLoanBusinessTypesAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSourceLoanBusinessTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceLoanBusinessTypeOrderBy>>;
  where?: Maybe<LoanSourceLoanBusinessTypeBoolExp>;
};


/**
 * แหล่งเงินกู้
 *
 *
 * columns and relationships of "loan_source.loan"
 */
export type LoanSourceLoanObjectivesArgs = {
  distinct_on?: Maybe<Array<LoanSourceObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceObjectiveOrderBy>>;
  where?: Maybe<LoanSourceObjectiveBoolExp>;
};


/**
 * แหล่งเงินกู้
 *
 *
 * columns and relationships of "loan_source.loan"
 */
export type LoanSourceLoanObjectivesAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSourceObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceObjectiveOrderBy>>;
  where?: Maybe<LoanSourceObjectiveBoolExp>;
};

/** aggregated selection of "loan_source.loan" */
export type LoanSourceLoanAggregate = {
  __typename?: 'loan_source_loan_aggregate';
  aggregate?: Maybe<LoanSourceLoanAggregateFields>;
  nodes: Array<LoanSourceLoan>;
};

/** aggregate fields of "loan_source.loan" */
export type LoanSourceLoanAggregateFields = {
  __typename?: 'loan_source_loan_aggregate_fields';
  avg?: Maybe<LoanSourceLoanAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanSourceLoanMaxFields>;
  min?: Maybe<LoanSourceLoanMinFields>;
  stddev?: Maybe<LoanSourceLoanStddevFields>;
  stddev_pop?: Maybe<LoanSourceLoanStddevPopFields>;
  stddev_samp?: Maybe<LoanSourceLoanStddevSampFields>;
  sum?: Maybe<LoanSourceLoanSumFields>;
  var_pop?: Maybe<LoanSourceLoanVarPopFields>;
  var_samp?: Maybe<LoanSourceLoanVarSampFields>;
  variance?: Maybe<LoanSourceLoanVarianceFields>;
};


/** aggregate fields of "loan_source.loan" */
export type LoanSourceLoanAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanSourceLoanSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan_source.loan" */
export type LoanSourceLoanAggregateOrderBy = {
  avg?: Maybe<LoanSourceLoanAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanSourceLoanMaxOrderBy>;
  min?: Maybe<LoanSourceLoanMinOrderBy>;
  stddev?: Maybe<LoanSourceLoanStddevOrderBy>;
  stddev_pop?: Maybe<LoanSourceLoanStddevPopOrderBy>;
  stddev_samp?: Maybe<LoanSourceLoanStddevSampOrderBy>;
  sum?: Maybe<LoanSourceLoanSumOrderBy>;
  var_pop?: Maybe<LoanSourceLoanVarPopOrderBy>;
  var_samp?: Maybe<LoanSourceLoanVarSampOrderBy>;
  variance?: Maybe<LoanSourceLoanVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "loan_source.loan" */
export type LoanSourceLoanArrRelInsertInput = {
  data: Array<LoanSourceLoanInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanSourceLoanOnConflict>;
};

/**
 * เอกสารแนบแหล่งเงินกู้
 *
 *
 * columns and relationships of "loan_source.loan_attachment"
 */
export type LoanSourceLoanAttachment = {
  __typename?: 'loan_source_loan_attachment';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** ที่อยู่ไฟล์ */
  file_path?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  filename?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** An object relationship */
  loan?: Maybe<LoanSourceLoan>;
  /** แหล่งเงินกู้ */
  loan_id?: Maybe<Scalars['uuid']>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<Scalars['String']>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  original_name?: Maybe<Scalars['String']>;
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** อัพเดทข้อมมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "loan_source.loan_attachment" */
export type LoanSourceLoanAttachmentAggregate = {
  __typename?: 'loan_source_loan_attachment_aggregate';
  aggregate?: Maybe<LoanSourceLoanAttachmentAggregateFields>;
  nodes: Array<LoanSourceLoanAttachment>;
};

/** aggregate fields of "loan_source.loan_attachment" */
export type LoanSourceLoanAttachmentAggregateFields = {
  __typename?: 'loan_source_loan_attachment_aggregate_fields';
  avg?: Maybe<LoanSourceLoanAttachmentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanSourceLoanAttachmentMaxFields>;
  min?: Maybe<LoanSourceLoanAttachmentMinFields>;
  stddev?: Maybe<LoanSourceLoanAttachmentStddevFields>;
  stddev_pop?: Maybe<LoanSourceLoanAttachmentStddevPopFields>;
  stddev_samp?: Maybe<LoanSourceLoanAttachmentStddevSampFields>;
  sum?: Maybe<LoanSourceLoanAttachmentSumFields>;
  var_pop?: Maybe<LoanSourceLoanAttachmentVarPopFields>;
  var_samp?: Maybe<LoanSourceLoanAttachmentVarSampFields>;
  variance?: Maybe<LoanSourceLoanAttachmentVarianceFields>;
};


/** aggregate fields of "loan_source.loan_attachment" */
export type LoanSourceLoanAttachmentAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanSourceLoanAttachmentSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan_source.loan_attachment" */
export type LoanSourceLoanAttachmentAggregateOrderBy = {
  avg?: Maybe<LoanSourceLoanAttachmentAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanSourceLoanAttachmentMaxOrderBy>;
  min?: Maybe<LoanSourceLoanAttachmentMinOrderBy>;
  stddev?: Maybe<LoanSourceLoanAttachmentStddevOrderBy>;
  stddev_pop?: Maybe<LoanSourceLoanAttachmentStddevPopOrderBy>;
  stddev_samp?: Maybe<LoanSourceLoanAttachmentStddevSampOrderBy>;
  sum?: Maybe<LoanSourceLoanAttachmentSumOrderBy>;
  var_pop?: Maybe<LoanSourceLoanAttachmentVarPopOrderBy>;
  var_samp?: Maybe<LoanSourceLoanAttachmentVarSampOrderBy>;
  variance?: Maybe<LoanSourceLoanAttachmentVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "loan_source.loan_attachment" */
export type LoanSourceLoanAttachmentArrRelInsertInput = {
  data: Array<LoanSourceLoanAttachmentInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanSourceLoanAttachmentOnConflict>;
};

/** aggregate avg on columns */
export type LoanSourceLoanAttachmentAvgFields = {
  __typename?: 'loan_source_loan_attachment_avg_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "loan_source.loan_attachment" */
export type LoanSourceLoanAttachmentAvgOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "loan_source.loan_attachment". All fields are combined with a logical 'AND'. */
export type LoanSourceLoanAttachmentBoolExp = {
  _and?: Maybe<Array<LoanSourceLoanAttachmentBoolExp>>;
  _not?: Maybe<LoanSourceLoanAttachmentBoolExp>;
  _or?: Maybe<Array<LoanSourceLoanAttachmentBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  file_path?: Maybe<StringComparisonExp>;
  filename?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  loan?: Maybe<LoanSourceLoanBoolExp>;
  loan_id?: Maybe<UuidComparisonExp>;
  mimetype?: Maybe<StringComparisonExp>;
  note?: Maybe<StringComparisonExp>;
  original_name?: Maybe<StringComparisonExp>;
  size?: Maybe<NumericComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "loan_source.loan_attachment" */
export enum LoanSourceLoanAttachmentConstraint {
  /** unique or primary key constraint */
  LoanAttachmentPkey = 'loan_attachment_pkey'
}

/** input type for incrementing numeric columns in table "loan_source.loan_attachment" */
export type LoanSourceLoanAttachmentIncInput = {
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan_source.loan_attachment" */
export type LoanSourceLoanAttachmentInsertInput = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** ที่อยู่ไฟล์ */
  file_path?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  filename?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  loan?: Maybe<LoanSourceLoanObjRelInsertInput>;
  /** แหล่งเงินกู้ */
  loan_id?: Maybe<Scalars['uuid']>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<Scalars['String']>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  original_name?: Maybe<Scalars['String']>;
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** อัพเดทข้อมมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LoanSourceLoanAttachmentMaxFields = {
  __typename?: 'loan_source_loan_attachment_max_fields';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** ที่อยู่ไฟล์ */
  file_path?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  filename?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** แหล่งเงินกู้ */
  loan_id?: Maybe<Scalars['uuid']>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<Scalars['String']>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  original_name?: Maybe<Scalars['String']>;
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** อัพเดทข้อมมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "loan_source.loan_attachment" */
export type LoanSourceLoanAttachmentMaxOrderBy = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** ที่อยู่ไฟล์ */
  file_path?: Maybe<OrderBy>;
  /** ชื่อไฟล์ */
  filename?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** แหล่งเงินกู้ */
  loan_id?: Maybe<OrderBy>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<OrderBy>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<OrderBy>;
  /** ชื่อไฟล์ */
  original_name?: Maybe<OrderBy>;
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** อัพเดทข้อมมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanSourceLoanAttachmentMinFields = {
  __typename?: 'loan_source_loan_attachment_min_fields';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** ที่อยู่ไฟล์ */
  file_path?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  filename?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** แหล่งเงินกู้ */
  loan_id?: Maybe<Scalars['uuid']>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<Scalars['String']>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  original_name?: Maybe<Scalars['String']>;
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** อัพเดทข้อมมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "loan_source.loan_attachment" */
export type LoanSourceLoanAttachmentMinOrderBy = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** ที่อยู่ไฟล์ */
  file_path?: Maybe<OrderBy>;
  /** ชื่อไฟล์ */
  filename?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** แหล่งเงินกู้ */
  loan_id?: Maybe<OrderBy>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<OrderBy>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<OrderBy>;
  /** ชื่อไฟล์ */
  original_name?: Maybe<OrderBy>;
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** อัพเดทข้อมมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan_source.loan_attachment" */
export type LoanSourceLoanAttachmentMutationResponse = {
  __typename?: 'loan_source_loan_attachment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanSourceLoanAttachment>;
};

/** on conflict condition type for table "loan_source.loan_attachment" */
export type LoanSourceLoanAttachmentOnConflict = {
  constraint: LoanSourceLoanAttachmentConstraint;
  update_columns?: Array<LoanSourceLoanAttachmentUpdateColumn>;
  where?: Maybe<LoanSourceLoanAttachmentBoolExp>;
};

/** Ordering options when selecting data from "loan_source.loan_attachment". */
export type LoanSourceLoanAttachmentOrderBy = {
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  file_path?: Maybe<OrderBy>;
  filename?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  loan?: Maybe<LoanSourceLoanOrderBy>;
  loan_id?: Maybe<OrderBy>;
  mimetype?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  original_name?: Maybe<OrderBy>;
  size?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_source_loan_attachment */
export type LoanSourceLoanAttachmentPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "loan_source.loan_attachment" */
export enum LoanSourceLoanAttachmentSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  LoanId = 'loan_id',
  /** column name */
  Mimetype = 'mimetype',
  /** column name */
  Note = 'note',
  /** column name */
  OriginalName = 'original_name',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "loan_source.loan_attachment" */
export type LoanSourceLoanAttachmentSetInput = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** ที่อยู่ไฟล์ */
  file_path?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  filename?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** แหล่งเงินกู้ */
  loan_id?: Maybe<Scalars['uuid']>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<Scalars['String']>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  original_name?: Maybe<Scalars['String']>;
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** อัพเดทข้อมมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type LoanSourceLoanAttachmentStddevFields = {
  __typename?: 'loan_source_loan_attachment_stddev_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "loan_source.loan_attachment" */
export type LoanSourceLoanAttachmentStddevOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LoanSourceLoanAttachmentStddevPopFields = {
  __typename?: 'loan_source_loan_attachment_stddev_pop_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "loan_source.loan_attachment" */
export type LoanSourceLoanAttachmentStddevPopOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LoanSourceLoanAttachmentStddevSampFields = {
  __typename?: 'loan_source_loan_attachment_stddev_samp_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "loan_source.loan_attachment" */
export type LoanSourceLoanAttachmentStddevSampOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LoanSourceLoanAttachmentSumFields = {
  __typename?: 'loan_source_loan_attachment_sum_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "loan_source.loan_attachment" */
export type LoanSourceLoanAttachmentSumOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** update columns of table "loan_source.loan_attachment" */
export enum LoanSourceLoanAttachmentUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  LoanId = 'loan_id',
  /** column name */
  Mimetype = 'mimetype',
  /** column name */
  Note = 'note',
  /** column name */
  OriginalName = 'original_name',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type LoanSourceLoanAttachmentVarPopFields = {
  __typename?: 'loan_source_loan_attachment_var_pop_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "loan_source.loan_attachment" */
export type LoanSourceLoanAttachmentVarPopOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LoanSourceLoanAttachmentVarSampFields = {
  __typename?: 'loan_source_loan_attachment_var_samp_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "loan_source.loan_attachment" */
export type LoanSourceLoanAttachmentVarSampOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LoanSourceLoanAttachmentVarianceFields = {
  __typename?: 'loan_source_loan_attachment_variance_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "loan_source.loan_attachment" */
export type LoanSourceLoanAttachmentVarianceOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** aggregate avg on columns */
export type LoanSourceLoanAvgFields = {
  __typename?: 'loan_source_loan_avg_fields';
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<Scalars['Float']>;
  /** ระยะเวลาผ่อน */
  period?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "loan_source.loan" */
export type LoanSourceLoanAvgOrderBy = {
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<OrderBy>;
  /** ระยะเวลาผ่อน */
  period?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "loan_source.loan". All fields are combined with a logical 'AND'. */
export type LoanSourceLoanBoolExp = {
  _and?: Maybe<Array<LoanSourceLoanBoolExp>>;
  _not?: Maybe<LoanSourceLoanBoolExp>;
  _or?: Maybe<Array<LoanSourceLoanBoolExp>>;
  attachments?: Maybe<LoanSourceLoanAttachmentBoolExp>;
  content?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  credit_limit?: Maybe<NumericComparisonExp>;
  financial_institution?: Maybe<LoanSourceFinancialInstitutionBoolExp>;
  id?: Maybe<UuidComparisonExp>;
  loan_business_types?: Maybe<LoanSourceLoanBusinessTypeBoolExp>;
  max_interest?: Maybe<NumericComparisonExp>;
  min_interest?: Maybe<NumericComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  objectives?: Maybe<LoanSourceObjectiveBoolExp>;
  period?: Maybe<NumericComparisonExp>;
  promotion?: Maybe<StringComparisonExp>;
  published?: Maybe<BooleanComparisonExp>;
  reference?: Maybe<StringComparisonExp>;
  source_id?: Maybe<LoanSourceFinancialInstitutionEnumComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<UuidComparisonExp>;
};

/**
 * แหล่งเงินกู้-ประเภทธุรกิจ
 *
 *
 * columns and relationships of "loan_source.loan_business_type"
 */
export type LoanSourceLoanBusinessType = {
  __typename?: 'loan_source_loan_business_type';
  /** An object relationship */
  business_type: LoanSourceBusinessType;
  /** ประเภทธุรกิจ */
  business_type_id: Scalars['String'];
  /** An object relationship */
  loan: LoanSourceLoan;
  /** แหล่งเงินกู้ */
  loan_id: Scalars['uuid'];
};

/** aggregated selection of "loan_source.loan_business_type" */
export type LoanSourceLoanBusinessTypeAggregate = {
  __typename?: 'loan_source_loan_business_type_aggregate';
  aggregate?: Maybe<LoanSourceLoanBusinessTypeAggregateFields>;
  nodes: Array<LoanSourceLoanBusinessType>;
};

/** aggregate fields of "loan_source.loan_business_type" */
export type LoanSourceLoanBusinessTypeAggregateFields = {
  __typename?: 'loan_source_loan_business_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LoanSourceLoanBusinessTypeMaxFields>;
  min?: Maybe<LoanSourceLoanBusinessTypeMinFields>;
};


/** aggregate fields of "loan_source.loan_business_type" */
export type LoanSourceLoanBusinessTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanSourceLoanBusinessTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan_source.loan_business_type" */
export type LoanSourceLoanBusinessTypeAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanSourceLoanBusinessTypeMaxOrderBy>;
  min?: Maybe<LoanSourceLoanBusinessTypeMinOrderBy>;
};

/** input type for inserting array relation for remote table "loan_source.loan_business_type" */
export type LoanSourceLoanBusinessTypeArrRelInsertInput = {
  data: Array<LoanSourceLoanBusinessTypeInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanSourceLoanBusinessTypeOnConflict>;
};

/** Boolean expression to filter rows from the table "loan_source.loan_business_type". All fields are combined with a logical 'AND'. */
export type LoanSourceLoanBusinessTypeBoolExp = {
  _and?: Maybe<Array<LoanSourceLoanBusinessTypeBoolExp>>;
  _not?: Maybe<LoanSourceLoanBusinessTypeBoolExp>;
  _or?: Maybe<Array<LoanSourceLoanBusinessTypeBoolExp>>;
  business_type?: Maybe<LoanSourceBusinessTypeBoolExp>;
  business_type_id?: Maybe<StringComparisonExp>;
  loan?: Maybe<LoanSourceLoanBoolExp>;
  loan_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "loan_source.loan_business_type" */
export enum LoanSourceLoanBusinessTypeConstraint {
  /** unique or primary key constraint */
  LoanBusinessTypePkey = 'loan_business_type_pkey'
}

/** input type for inserting data into table "loan_source.loan_business_type" */
export type LoanSourceLoanBusinessTypeInsertInput = {
  business_type?: Maybe<LoanSourceBusinessTypeObjRelInsertInput>;
  /** ประเภทธุรกิจ */
  business_type_id?: Maybe<Scalars['String']>;
  loan?: Maybe<LoanSourceLoanObjRelInsertInput>;
  /** แหล่งเงินกู้ */
  loan_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LoanSourceLoanBusinessTypeMaxFields = {
  __typename?: 'loan_source_loan_business_type_max_fields';
  /** ประเภทธุรกิจ */
  business_type_id?: Maybe<Scalars['String']>;
  /** แหล่งเงินกู้ */
  loan_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "loan_source.loan_business_type" */
export type LoanSourceLoanBusinessTypeMaxOrderBy = {
  /** ประเภทธุรกิจ */
  business_type_id?: Maybe<OrderBy>;
  /** แหล่งเงินกู้ */
  loan_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanSourceLoanBusinessTypeMinFields = {
  __typename?: 'loan_source_loan_business_type_min_fields';
  /** ประเภทธุรกิจ */
  business_type_id?: Maybe<Scalars['String']>;
  /** แหล่งเงินกู้ */
  loan_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "loan_source.loan_business_type" */
export type LoanSourceLoanBusinessTypeMinOrderBy = {
  /** ประเภทธุรกิจ */
  business_type_id?: Maybe<OrderBy>;
  /** แหล่งเงินกู้ */
  loan_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan_source.loan_business_type" */
export type LoanSourceLoanBusinessTypeMutationResponse = {
  __typename?: 'loan_source_loan_business_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanSourceLoanBusinessType>;
};

/** on conflict condition type for table "loan_source.loan_business_type" */
export type LoanSourceLoanBusinessTypeOnConflict = {
  constraint: LoanSourceLoanBusinessTypeConstraint;
  update_columns?: Array<LoanSourceLoanBusinessTypeUpdateColumn>;
  where?: Maybe<LoanSourceLoanBusinessTypeBoolExp>;
};

/** Ordering options when selecting data from "loan_source.loan_business_type". */
export type LoanSourceLoanBusinessTypeOrderBy = {
  business_type?: Maybe<LoanSourceBusinessTypeOrderBy>;
  business_type_id?: Maybe<OrderBy>;
  loan?: Maybe<LoanSourceLoanOrderBy>;
  loan_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_source_loan_business_type */
export type LoanSourceLoanBusinessTypePkColumnsInput = {
  /** ประเภทธุรกิจ */
  business_type_id: Scalars['String'];
  /** แหล่งเงินกู้ */
  loan_id: Scalars['uuid'];
};

/** select columns of table "loan_source.loan_business_type" */
export enum LoanSourceLoanBusinessTypeSelectColumn {
  /** column name */
  BusinessTypeId = 'business_type_id',
  /** column name */
  LoanId = 'loan_id'
}

/** input type for updating data in table "loan_source.loan_business_type" */
export type LoanSourceLoanBusinessTypeSetInput = {
  /** ประเภทธุรกิจ */
  business_type_id?: Maybe<Scalars['String']>;
  /** แหล่งเงินกู้ */
  loan_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "loan_source.loan_business_type" */
export enum LoanSourceLoanBusinessTypeUpdateColumn {
  /** column name */
  BusinessTypeId = 'business_type_id',
  /** column name */
  LoanId = 'loan_id'
}

/** unique or primary key constraints on table "loan_source.loan" */
export enum LoanSourceLoanConstraint {
  /** unique or primary key constraint */
  LoanPkey = 'loan_pkey'
}

/** input type for incrementing numeric columns in table "loan_source.loan" */
export type LoanSourceLoanIncInput = {
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<Scalars['numeric']>;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<Scalars['numeric']>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<Scalars['numeric']>;
  /** ระยะเวลาผ่อน */
  period?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan_source.loan" */
export type LoanSourceLoanInsertInput = {
  attachments?: Maybe<LoanSourceLoanAttachmentArrRelInsertInput>;
  /** content */
  content?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<Scalars['numeric']>;
  financial_institution?: Maybe<LoanSourceFinancialInstitutionObjRelInsertInput>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  loan_business_types?: Maybe<LoanSourceLoanBusinessTypeArrRelInsertInput>;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<Scalars['numeric']>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<Scalars['numeric']>;
  /** ชื่อแหล่งเงินกู้ */
  name?: Maybe<Scalars['String']>;
  objectives?: Maybe<LoanSourceObjectiveArrRelInsertInput>;
  /** ระยะเวลาผ่อน */
  period?: Maybe<Scalars['numeric']>;
  /** โปรโมชั่น */
  promotion?: Maybe<Scalars['String']>;
  /** true = แสดงให้บุคคลทั่วไปเห็น, false = ไม่แสดงให้บุคคลทั่วไปเห็น */
  published?: Maybe<Scalars['Boolean']>;
  /** รหัสอ้างอิง */
  reference?: Maybe<Scalars['String']>;
  /** สถาบันการเงิน */
  source_id?: Maybe<LoanSourceFinancialInstitutionEnum>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LoanSourceLoanMaxFields = {
  __typename?: 'loan_source_loan_max_fields';
  /** content */
  content?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<Scalars['numeric']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<Scalars['numeric']>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<Scalars['numeric']>;
  /** ชื่อแหล่งเงินกู้ */
  name?: Maybe<Scalars['String']>;
  /** ระยะเวลาผ่อน */
  period?: Maybe<Scalars['numeric']>;
  /** โปรโมชั่น */
  promotion?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิง */
  reference?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "loan_source.loan" */
export type LoanSourceLoanMaxOrderBy = {
  /** content */
  content?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<OrderBy>;
  /** ชื่อแหล่งเงินกู้ */
  name?: Maybe<OrderBy>;
  /** ระยะเวลาผ่อน */
  period?: Maybe<OrderBy>;
  /** โปรโมชั่น */
  promotion?: Maybe<OrderBy>;
  /** รหัสอ้างอิง */
  reference?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanSourceLoanMinFields = {
  __typename?: 'loan_source_loan_min_fields';
  /** content */
  content?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<Scalars['numeric']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<Scalars['numeric']>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<Scalars['numeric']>;
  /** ชื่อแหล่งเงินกู้ */
  name?: Maybe<Scalars['String']>;
  /** ระยะเวลาผ่อน */
  period?: Maybe<Scalars['numeric']>;
  /** โปรโมชั่น */
  promotion?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิง */
  reference?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "loan_source.loan" */
export type LoanSourceLoanMinOrderBy = {
  /** content */
  content?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<OrderBy>;
  /** ชื่อแหล่งเงินกู้ */
  name?: Maybe<OrderBy>;
  /** ระยะเวลาผ่อน */
  period?: Maybe<OrderBy>;
  /** โปรโมชั่น */
  promotion?: Maybe<OrderBy>;
  /** รหัสอ้างอิง */
  reference?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan_source.loan" */
export type LoanSourceLoanMutationResponse = {
  __typename?: 'loan_source_loan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanSourceLoan>;
};

/** input type for inserting object relation for remote table "loan_source.loan" */
export type LoanSourceLoanObjRelInsertInput = {
  data: LoanSourceLoanInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanSourceLoanOnConflict>;
};

/** on conflict condition type for table "loan_source.loan" */
export type LoanSourceLoanOnConflict = {
  constraint: LoanSourceLoanConstraint;
  update_columns?: Array<LoanSourceLoanUpdateColumn>;
  where?: Maybe<LoanSourceLoanBoolExp>;
};

/** Ordering options when selecting data from "loan_source.loan". */
export type LoanSourceLoanOrderBy = {
  attachments_aggregate?: Maybe<LoanSourceLoanAttachmentAggregateOrderBy>;
  content?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  credit_limit?: Maybe<OrderBy>;
  financial_institution?: Maybe<LoanSourceFinancialInstitutionOrderBy>;
  id?: Maybe<OrderBy>;
  loan_business_types_aggregate?: Maybe<LoanSourceLoanBusinessTypeAggregateOrderBy>;
  max_interest?: Maybe<OrderBy>;
  min_interest?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  objectives_aggregate?: Maybe<LoanSourceObjectiveAggregateOrderBy>;
  period?: Maybe<OrderBy>;
  promotion?: Maybe<OrderBy>;
  published?: Maybe<OrderBy>;
  reference?: Maybe<OrderBy>;
  source_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_source_loan */
export type LoanSourceLoanPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "loan_source.loan" */
export enum LoanSourceLoanSelectColumn {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CreditLimit = 'credit_limit',
  /** column name */
  Id = 'id',
  /** column name */
  MaxInterest = 'max_interest',
  /** column name */
  MinInterest = 'min_interest',
  /** column name */
  Name = 'name',
  /** column name */
  Period = 'period',
  /** column name */
  Promotion = 'promotion',
  /** column name */
  Published = 'published',
  /** column name */
  Reference = 'reference',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "loan_source.loan" */
export type LoanSourceLoanSetInput = {
  /** content */
  content?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<Scalars['numeric']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<Scalars['numeric']>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<Scalars['numeric']>;
  /** ชื่อแหล่งเงินกู้ */
  name?: Maybe<Scalars['String']>;
  /** ระยะเวลาผ่อน */
  period?: Maybe<Scalars['numeric']>;
  /** โปรโมชั่น */
  promotion?: Maybe<Scalars['String']>;
  /** true = แสดงให้บุคคลทั่วไปเห็น, false = ไม่แสดงให้บุคคลทั่วไปเห็น */
  published?: Maybe<Scalars['Boolean']>;
  /** รหัสอ้างอิง */
  reference?: Maybe<Scalars['String']>;
  /** สถาบันการเงิน */
  source_id?: Maybe<LoanSourceFinancialInstitutionEnum>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type LoanSourceLoanStddevFields = {
  __typename?: 'loan_source_loan_stddev_fields';
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<Scalars['Float']>;
  /** ระยะเวลาผ่อน */
  period?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "loan_source.loan" */
export type LoanSourceLoanStddevOrderBy = {
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<OrderBy>;
  /** ระยะเวลาผ่อน */
  period?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LoanSourceLoanStddevPopFields = {
  __typename?: 'loan_source_loan_stddev_pop_fields';
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<Scalars['Float']>;
  /** ระยะเวลาผ่อน */
  period?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "loan_source.loan" */
export type LoanSourceLoanStddevPopOrderBy = {
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<OrderBy>;
  /** ระยะเวลาผ่อน */
  period?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LoanSourceLoanStddevSampFields = {
  __typename?: 'loan_source_loan_stddev_samp_fields';
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<Scalars['Float']>;
  /** ระยะเวลาผ่อน */
  period?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "loan_source.loan" */
export type LoanSourceLoanStddevSampOrderBy = {
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<OrderBy>;
  /** ระยะเวลาผ่อน */
  period?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LoanSourceLoanSumFields = {
  __typename?: 'loan_source_loan_sum_fields';
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<Scalars['numeric']>;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<Scalars['numeric']>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<Scalars['numeric']>;
  /** ระยะเวลาผ่อน */
  period?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "loan_source.loan" */
export type LoanSourceLoanSumOrderBy = {
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<OrderBy>;
  /** ระยะเวลาผ่อน */
  period?: Maybe<OrderBy>;
};

/** update columns of table "loan_source.loan" */
export enum LoanSourceLoanUpdateColumn {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CreditLimit = 'credit_limit',
  /** column name */
  Id = 'id',
  /** column name */
  MaxInterest = 'max_interest',
  /** column name */
  MinInterest = 'min_interest',
  /** column name */
  Name = 'name',
  /** column name */
  Period = 'period',
  /** column name */
  Promotion = 'promotion',
  /** column name */
  Published = 'published',
  /** column name */
  Reference = 'reference',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type LoanSourceLoanVarPopFields = {
  __typename?: 'loan_source_loan_var_pop_fields';
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<Scalars['Float']>;
  /** ระยะเวลาผ่อน */
  period?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "loan_source.loan" */
export type LoanSourceLoanVarPopOrderBy = {
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<OrderBy>;
  /** ระยะเวลาผ่อน */
  period?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LoanSourceLoanVarSampFields = {
  __typename?: 'loan_source_loan_var_samp_fields';
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<Scalars['Float']>;
  /** ระยะเวลาผ่อน */
  period?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "loan_source.loan" */
export type LoanSourceLoanVarSampOrderBy = {
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<OrderBy>;
  /** ระยะเวลาผ่อน */
  period?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LoanSourceLoanVarianceFields = {
  __typename?: 'loan_source_loan_variance_fields';
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<Scalars['Float']>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<Scalars['Float']>;
  /** ระยะเวลาผ่อน */
  period?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "loan_source.loan" */
export type LoanSourceLoanVarianceOrderBy = {
  /** วงเงินสูงสุด */
  credit_limit?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยสูงสุด */
  max_interest?: Maybe<OrderBy>;
  /** อัตราดอกเบี้ยเริ่มต้น */
  min_interest?: Maybe<OrderBy>;
  /** ระยะเวลาผ่อน */
  period?: Maybe<OrderBy>;
};

/**
 * วัตถุประสงค์แหล่งเงินกู้
 *
 *
 * columns and relationships of "loan_source.objective"
 */
export type LoanSourceObjective = {
  __typename?: 'loan_source_objective';
  /** Primary key */
  id: Scalars['uuid'];
  /** An object relationship */
  loan: LoanSourceLoan;
  /** แหล่งเงินกู้ */
  loan_id: Scalars['uuid'];
  /** วัตถุประสงค์แหล่งเงินกู้ */
  name?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  sequence: Scalars['numeric'];
};

/** aggregated selection of "loan_source.objective" */
export type LoanSourceObjectiveAggregate = {
  __typename?: 'loan_source_objective_aggregate';
  aggregate?: Maybe<LoanSourceObjectiveAggregateFields>;
  nodes: Array<LoanSourceObjective>;
};

/** aggregate fields of "loan_source.objective" */
export type LoanSourceObjectiveAggregateFields = {
  __typename?: 'loan_source_objective_aggregate_fields';
  avg?: Maybe<LoanSourceObjectiveAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LoanSourceObjectiveMaxFields>;
  min?: Maybe<LoanSourceObjectiveMinFields>;
  stddev?: Maybe<LoanSourceObjectiveStddevFields>;
  stddev_pop?: Maybe<LoanSourceObjectiveStddevPopFields>;
  stddev_samp?: Maybe<LoanSourceObjectiveStddevSampFields>;
  sum?: Maybe<LoanSourceObjectiveSumFields>;
  var_pop?: Maybe<LoanSourceObjectiveVarPopFields>;
  var_samp?: Maybe<LoanSourceObjectiveVarSampFields>;
  variance?: Maybe<LoanSourceObjectiveVarianceFields>;
};


/** aggregate fields of "loan_source.objective" */
export type LoanSourceObjectiveAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanSourceObjectiveSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan_source.objective" */
export type LoanSourceObjectiveAggregateOrderBy = {
  avg?: Maybe<LoanSourceObjectiveAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanSourceObjectiveMaxOrderBy>;
  min?: Maybe<LoanSourceObjectiveMinOrderBy>;
  stddev?: Maybe<LoanSourceObjectiveStddevOrderBy>;
  stddev_pop?: Maybe<LoanSourceObjectiveStddevPopOrderBy>;
  stddev_samp?: Maybe<LoanSourceObjectiveStddevSampOrderBy>;
  sum?: Maybe<LoanSourceObjectiveSumOrderBy>;
  var_pop?: Maybe<LoanSourceObjectiveVarPopOrderBy>;
  var_samp?: Maybe<LoanSourceObjectiveVarSampOrderBy>;
  variance?: Maybe<LoanSourceObjectiveVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "loan_source.objective" */
export type LoanSourceObjectiveArrRelInsertInput = {
  data: Array<LoanSourceObjectiveInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanSourceObjectiveOnConflict>;
};

/** aggregate avg on columns */
export type LoanSourceObjectiveAvgFields = {
  __typename?: 'loan_source_objective_avg_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "loan_source.objective" */
export type LoanSourceObjectiveAvgOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "loan_source.objective". All fields are combined with a logical 'AND'. */
export type LoanSourceObjectiveBoolExp = {
  _and?: Maybe<Array<LoanSourceObjectiveBoolExp>>;
  _not?: Maybe<LoanSourceObjectiveBoolExp>;
  _or?: Maybe<Array<LoanSourceObjectiveBoolExp>>;
  id?: Maybe<UuidComparisonExp>;
  loan?: Maybe<LoanSourceLoanBoolExp>;
  loan_id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "loan_source.objective" */
export enum LoanSourceObjectiveConstraint {
  /** unique or primary key constraint */
  ObjectivePkey = 'objective_pkey'
}

/** input type for incrementing numeric columns in table "loan_source.objective" */
export type LoanSourceObjectiveIncInput = {
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "loan_source.objective" */
export type LoanSourceObjectiveInsertInput = {
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  loan?: Maybe<LoanSourceLoanObjRelInsertInput>;
  /** แหล่งเงินกู้ */
  loan_id?: Maybe<Scalars['uuid']>;
  /** วัตถุประสงค์แหล่งเงินกู้ */
  name?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type LoanSourceObjectiveMaxFields = {
  __typename?: 'loan_source_objective_max_fields';
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** แหล่งเงินกู้ */
  loan_id?: Maybe<Scalars['uuid']>;
  /** วัตถุประสงค์แหล่งเงินกู้ */
  name?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "loan_source.objective" */
export type LoanSourceObjectiveMaxOrderBy = {
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** แหล่งเงินกู้ */
  loan_id?: Maybe<OrderBy>;
  /** วัตถุประสงค์แหล่งเงินกู้ */
  name?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanSourceObjectiveMinFields = {
  __typename?: 'loan_source_objective_min_fields';
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** แหล่งเงินกู้ */
  loan_id?: Maybe<Scalars['uuid']>;
  /** วัตถุประสงค์แหล่งเงินกู้ */
  name?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "loan_source.objective" */
export type LoanSourceObjectiveMinOrderBy = {
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** แหล่งเงินกู้ */
  loan_id?: Maybe<OrderBy>;
  /** วัตถุประสงค์แหล่งเงินกู้ */
  name?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan_source.objective" */
export type LoanSourceObjectiveMutationResponse = {
  __typename?: 'loan_source_objective_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanSourceObjective>;
};

/** on conflict condition type for table "loan_source.objective" */
export type LoanSourceObjectiveOnConflict = {
  constraint: LoanSourceObjectiveConstraint;
  update_columns?: Array<LoanSourceObjectiveUpdateColumn>;
  where?: Maybe<LoanSourceObjectiveBoolExp>;
};

/** Ordering options when selecting data from "loan_source.objective". */
export type LoanSourceObjectiveOrderBy = {
  id?: Maybe<OrderBy>;
  loan?: Maybe<LoanSourceLoanOrderBy>;
  loan_id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_source_objective */
export type LoanSourceObjectivePkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "loan_source.objective" */
export enum LoanSourceObjectiveSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  LoanId = 'loan_id',
  /** column name */
  Name = 'name',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "loan_source.objective" */
export type LoanSourceObjectiveSetInput = {
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** แหล่งเงินกู้ */
  loan_id?: Maybe<Scalars['uuid']>;
  /** วัตถุประสงค์แหล่งเงินกู้ */
  name?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type LoanSourceObjectiveStddevFields = {
  __typename?: 'loan_source_objective_stddev_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "loan_source.objective" */
export type LoanSourceObjectiveStddevOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LoanSourceObjectiveStddevPopFields = {
  __typename?: 'loan_source_objective_stddev_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "loan_source.objective" */
export type LoanSourceObjectiveStddevPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LoanSourceObjectiveStddevSampFields = {
  __typename?: 'loan_source_objective_stddev_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "loan_source.objective" */
export type LoanSourceObjectiveStddevSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type LoanSourceObjectiveSumFields = {
  __typename?: 'loan_source_objective_sum_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "loan_source.objective" */
export type LoanSourceObjectiveSumOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "loan_source.objective" */
export enum LoanSourceObjectiveUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  LoanId = 'loan_id',
  /** column name */
  Name = 'name',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type LoanSourceObjectiveVarPopFields = {
  __typename?: 'loan_source_objective_var_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "loan_source.objective" */
export type LoanSourceObjectiveVarPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LoanSourceObjectiveVarSampFields = {
  __typename?: 'loan_source_objective_var_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "loan_source.objective" */
export type LoanSourceObjectiveVarSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type LoanSourceObjectiveVarianceFields = {
  __typename?: 'loan_source_objective_variance_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "loan_source.objective" */
export type LoanSourceObjectiveVarianceOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/**
 * บันทึกการค้นหา
 *
 *
 * columns and relationships of "loan_source.search_log"
 */
export type LoanSourceSearchLog = {
  __typename?: 'loan_source_search_log';
  /** วันที่บันทึกข้อมูล */
  created_at: Scalars['timestamptz'];
  /** Primary key */
  id: Scalars['uuid'];
  /** Key */
  key?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
  /** ผู้ค้นหา */
  user_id?: Maybe<Scalars['uuid']>;
  /** Value */
  value?: Maybe<Scalars['String']>;
};

/** aggregated selection of "loan_source.search_log" */
export type LoanSourceSearchLogAggregate = {
  __typename?: 'loan_source_search_log_aggregate';
  aggregate?: Maybe<LoanSourceSearchLogAggregateFields>;
  nodes: Array<LoanSourceSearchLog>;
};

/** aggregate fields of "loan_source.search_log" */
export type LoanSourceSearchLogAggregateFields = {
  __typename?: 'loan_source_search_log_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LoanSourceSearchLogMaxFields>;
  min?: Maybe<LoanSourceSearchLogMinFields>;
};


/** aggregate fields of "loan_source.search_log" */
export type LoanSourceSearchLogAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanSourceSearchLogSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "loan_source.search_log" */
export type LoanSourceSearchLogAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<LoanSourceSearchLogMaxOrderBy>;
  min?: Maybe<LoanSourceSearchLogMinOrderBy>;
};

/** input type for inserting array relation for remote table "loan_source.search_log" */
export type LoanSourceSearchLogArrRelInsertInput = {
  data: Array<LoanSourceSearchLogInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<LoanSourceSearchLogOnConflict>;
};

/** Boolean expression to filter rows from the table "loan_source.search_log". All fields are combined with a logical 'AND'. */
export type LoanSourceSearchLogBoolExp = {
  _and?: Maybe<Array<LoanSourceSearchLogBoolExp>>;
  _not?: Maybe<LoanSourceSearchLogBoolExp>;
  _or?: Maybe<Array<LoanSourceSearchLogBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  key?: Maybe<StringComparisonExp>;
  user?: Maybe<UsersBoolExp>;
  user_id?: Maybe<UuidComparisonExp>;
  value?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "loan_source.search_log" */
export enum LoanSourceSearchLogConstraint {
  /** unique or primary key constraint */
  SearchLogPkey = 'search_log_pkey'
}

/** input type for inserting data into table "loan_source.search_log" */
export type LoanSourceSearchLogInsertInput = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** Key */
  key?: Maybe<Scalars['String']>;
  user?: Maybe<UsersObjRelInsertInput>;
  /** ผู้ค้นหา */
  user_id?: Maybe<Scalars['uuid']>;
  /** Value */
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LoanSourceSearchLogMaxFields = {
  __typename?: 'loan_source_search_log_max_fields';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** Key */
  key?: Maybe<Scalars['String']>;
  /** ผู้ค้นหา */
  user_id?: Maybe<Scalars['uuid']>;
  /** Value */
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "loan_source.search_log" */
export type LoanSourceSearchLogMaxOrderBy = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** Key */
  key?: Maybe<OrderBy>;
  /** ผู้ค้นหา */
  user_id?: Maybe<OrderBy>;
  /** Value */
  value?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type LoanSourceSearchLogMinFields = {
  __typename?: 'loan_source_search_log_min_fields';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** Key */
  key?: Maybe<Scalars['String']>;
  /** ผู้ค้นหา */
  user_id?: Maybe<Scalars['uuid']>;
  /** Value */
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "loan_source.search_log" */
export type LoanSourceSearchLogMinOrderBy = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** Key */
  key?: Maybe<OrderBy>;
  /** ผู้ค้นหา */
  user_id?: Maybe<OrderBy>;
  /** Value */
  value?: Maybe<OrderBy>;
};

/** response of any mutation on the table "loan_source.search_log" */
export type LoanSourceSearchLogMutationResponse = {
  __typename?: 'loan_source_search_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanSourceSearchLog>;
};

/** on conflict condition type for table "loan_source.search_log" */
export type LoanSourceSearchLogOnConflict = {
  constraint: LoanSourceSearchLogConstraint;
  update_columns?: Array<LoanSourceSearchLogUpdateColumn>;
  where?: Maybe<LoanSourceSearchLogBoolExp>;
};

/** Ordering options when selecting data from "loan_source.search_log". */
export type LoanSourceSearchLogOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  key?: Maybe<OrderBy>;
  user?: Maybe<UsersOrderBy>;
  user_id?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_source_search_log */
export type LoanSourceSearchLogPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "loan_source.search_log" */
export enum LoanSourceSearchLogSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "loan_source.search_log" */
export type LoanSourceSearchLogSetInput = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** Key */
  key?: Maybe<Scalars['String']>;
  /** ผู้ค้นหา */
  user_id?: Maybe<Scalars['uuid']>;
  /** Value */
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "loan_source.search_log" */
export enum LoanSourceSearchLogUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/**
 * สถานะการโอนย้ายใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.transfer_status"
 */
export type LoanTransferStatus = {
  __typename?: 'loan_transfer_status';
  /** An array relationship */
  application_organization_transfers: Array<LoanApplicationOrganizationTransfer>;
  /** An aggregate relationship */
  application_organization_transfers_aggregate: LoanApplicationOrganizationTransferAggregate;
  /** สถานะการโอนย้ายใบคำขอสินเชื่อ */
  description: Scalars['String'];
  /** Primary key */
  value: Scalars['String'];
};


/**
 * สถานะการโอนย้ายใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.transfer_status"
 */
export type LoanTransferStatusApplicationOrganizationTransfersArgs = {
  distinct_on?: Maybe<Array<LoanApplicationOrganizationTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationOrganizationTransferOrderBy>>;
  where?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
};


/**
 * สถานะการโอนย้ายใบคำขอสินเชื่อ
 *
 *
 * columns and relationships of "loan.transfer_status"
 */
export type LoanTransferStatusApplicationOrganizationTransfersAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationOrganizationTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationOrganizationTransferOrderBy>>;
  where?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
};

/** aggregated selection of "loan.transfer_status" */
export type LoanTransferStatusAggregate = {
  __typename?: 'loan_transfer_status_aggregate';
  aggregate?: Maybe<LoanTransferStatusAggregateFields>;
  nodes: Array<LoanTransferStatus>;
};

/** aggregate fields of "loan.transfer_status" */
export type LoanTransferStatusAggregateFields = {
  __typename?: 'loan_transfer_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LoanTransferStatusMaxFields>;
  min?: Maybe<LoanTransferStatusMinFields>;
};


/** aggregate fields of "loan.transfer_status" */
export type LoanTransferStatusAggregateFieldsCountArgs = {
  columns?: Maybe<Array<LoanTransferStatusSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "loan.transfer_status". All fields are combined with a logical 'AND'. */
export type LoanTransferStatusBoolExp = {
  _and?: Maybe<Array<LoanTransferStatusBoolExp>>;
  _not?: Maybe<LoanTransferStatusBoolExp>;
  _or?: Maybe<Array<LoanTransferStatusBoolExp>>;
  application_organization_transfers?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
  description?: Maybe<StringComparisonExp>;
  value?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "loan.transfer_status" */
export enum LoanTransferStatusConstraint {
  /** unique or primary key constraint */
  TransferStatusPkey = 'transfer_status_pkey'
}

export enum LoanTransferStatusEnum {
  /** Approved */
  Approved = 'approved',
  /** Cancelled */
  Cancelled = 'cancelled',
  /** Pending */
  Pending = 'pending',
  /** Rejected */
  Rejected = 'rejected'
}

/** Boolean expression to compare columns of type "loan_transfer_status_enum". All fields are combined with logical 'AND'. */
export type LoanTransferStatusEnumComparisonExp = {
  _eq?: Maybe<LoanTransferStatusEnum>;
  _in?: Maybe<Array<LoanTransferStatusEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<LoanTransferStatusEnum>;
  _nin?: Maybe<Array<LoanTransferStatusEnum>>;
};

/** input type for inserting data into table "loan.transfer_status" */
export type LoanTransferStatusInsertInput = {
  application_organization_transfers?: Maybe<LoanApplicationOrganizationTransferArrRelInsertInput>;
  /** สถานะการโอนย้ายใบคำขอสินเชื่อ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LoanTransferStatusMaxFields = {
  __typename?: 'loan_transfer_status_max_fields';
  /** สถานะการโอนย้ายใบคำขอสินเชื่อ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type LoanTransferStatusMinFields = {
  __typename?: 'loan_transfer_status_min_fields';
  /** สถานะการโอนย้ายใบคำขอสินเชื่อ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "loan.transfer_status" */
export type LoanTransferStatusMutationResponse = {
  __typename?: 'loan_transfer_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LoanTransferStatus>;
};

/** input type for inserting object relation for remote table "loan.transfer_status" */
export type LoanTransferStatusObjRelInsertInput = {
  data: LoanTransferStatusInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<LoanTransferStatusOnConflict>;
};

/** on conflict condition type for table "loan.transfer_status" */
export type LoanTransferStatusOnConflict = {
  constraint: LoanTransferStatusConstraint;
  update_columns?: Array<LoanTransferStatusUpdateColumn>;
  where?: Maybe<LoanTransferStatusBoolExp>;
};

/** Ordering options when selecting data from "loan.transfer_status". */
export type LoanTransferStatusOrderBy = {
  application_organization_transfers_aggregate?: Maybe<LoanApplicationOrganizationTransferAggregateOrderBy>;
  description?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
};

/** primary key columns input for table: loan_transfer_status */
export type LoanTransferStatusPkColumnsInput = {
  /** Primary key */
  value: Scalars['String'];
};

/** select columns of table "loan.transfer_status" */
export enum LoanTransferStatusSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "loan.transfer_status" */
export type LoanTransferStatusSetInput = {
  /** สถานะการโอนย้ายใบคำขอสินเชื่อ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "loan.transfer_status" */
export enum LoanTransferStatusUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "me" */
export type Me = {
  __typename?: 'me';
  avatar_url?: Maybe<Scalars['String']>;
  consent_checked?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  department_id?: Maybe<Scalars['uuid']>;
  display_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  reference?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['numeric']>;
  tel?: Maybe<Scalars['String']>;
  title_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "me" */
export type MeAggregate = {
  __typename?: 'me_aggregate';
  aggregate?: Maybe<MeAggregateFields>;
  nodes: Array<Me>;
};

/** aggregate fields of "me" */
export type MeAggregateFields = {
  __typename?: 'me_aggregate_fields';
  avg?: Maybe<MeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<MeMaxFields>;
  min?: Maybe<MeMinFields>;
  stddev?: Maybe<MeStddevFields>;
  stddev_pop?: Maybe<MeStddevPopFields>;
  stddev_samp?: Maybe<MeStddevSampFields>;
  sum?: Maybe<MeSumFields>;
  var_pop?: Maybe<MeVarPopFields>;
  var_samp?: Maybe<MeVarSampFields>;
  variance?: Maybe<MeVarianceFields>;
};


/** aggregate fields of "me" */
export type MeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<MeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type MeAvgFields = {
  __typename?: 'me_avg_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "me". All fields are combined with a logical 'AND'. */
export type MeBoolExp = {
  _and?: Maybe<Array<MeBoolExp>>;
  _not?: Maybe<MeBoolExp>;
  _or?: Maybe<Array<MeBoolExp>>;
  avatar_url?: Maybe<StringComparisonExp>;
  consent_checked?: Maybe<BooleanComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  department_id?: Maybe<UuidComparisonExp>;
  display_name?: Maybe<StringComparisonExp>;
  first_name?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  last_name?: Maybe<StringComparisonExp>;
  organization_id?: Maybe<UuidComparisonExp>;
  reference?: Maybe<StringComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
  tel?: Maybe<StringComparisonExp>;
  title_id?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** input type for incrementing numeric columns in table "me" */
export type MeIncInput = {
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "me" */
export type MeInsertInput = {
  avatar_url?: Maybe<Scalars['String']>;
  consent_checked?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  department_id?: Maybe<Scalars['uuid']>;
  display_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  reference?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['numeric']>;
  tel?: Maybe<Scalars['String']>;
  title_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type MeMaxFields = {
  __typename?: 'me_max_fields';
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  department_id?: Maybe<Scalars['uuid']>;
  display_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  reference?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['numeric']>;
  tel?: Maybe<Scalars['String']>;
  title_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type MeMinFields = {
  __typename?: 'me_min_fields';
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  department_id?: Maybe<Scalars['uuid']>;
  display_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  reference?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['numeric']>;
  tel?: Maybe<Scalars['String']>;
  title_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "me" */
export type MeMutationResponse = {
  __typename?: 'me_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Me>;
};

/** Ordering options when selecting data from "me". */
export type MeOrderBy = {
  avatar_url?: Maybe<OrderBy>;
  consent_checked?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  department_id?: Maybe<OrderBy>;
  display_name?: Maybe<OrderBy>;
  first_name?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  last_name?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  reference?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
  tel?: Maybe<OrderBy>;
  title_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** select columns of table "me" */
export enum MeSelectColumn {
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  ConsentChecked = 'consent_checked',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Reference = 'reference',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  Tel = 'tel',
  /** column name */
  TitleId = 'title_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "me" */
export type MeSetInput = {
  avatar_url?: Maybe<Scalars['String']>;
  consent_checked?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  department_id?: Maybe<Scalars['uuid']>;
  display_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  reference?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['numeric']>;
  tel?: Maybe<Scalars['String']>;
  title_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type MeStddevFields = {
  __typename?: 'me_stddev_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type MeStddevPopFields = {
  __typename?: 'me_stddev_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type MeStddevSampFields = {
  __typename?: 'me_stddev_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type MeSumFields = {
  __typename?: 'me_sum_fields';
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type MeVarPopFields = {
  __typename?: 'me_var_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type MeVarSampFields = {
  __typename?: 'me_var_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MeVarianceFields = {
  __typename?: 'me_variance_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "month_of_fiscal_year" */
export type MonthOfFiscalYear = {
  __typename?: 'month_of_fiscal_year';
  month?: Maybe<Scalars['Int']>;
  month_name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "month_of_fiscal_year" */
export type MonthOfFiscalYearAggregate = {
  __typename?: 'month_of_fiscal_year_aggregate';
  aggregate?: Maybe<MonthOfFiscalYearAggregateFields>;
  nodes: Array<MonthOfFiscalYear>;
};

/** aggregate fields of "month_of_fiscal_year" */
export type MonthOfFiscalYearAggregateFields = {
  __typename?: 'month_of_fiscal_year_aggregate_fields';
  avg?: Maybe<MonthOfFiscalYearAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<MonthOfFiscalYearMaxFields>;
  min?: Maybe<MonthOfFiscalYearMinFields>;
  stddev?: Maybe<MonthOfFiscalYearStddevFields>;
  stddev_pop?: Maybe<MonthOfFiscalYearStddevPopFields>;
  stddev_samp?: Maybe<MonthOfFiscalYearStddevSampFields>;
  sum?: Maybe<MonthOfFiscalYearSumFields>;
  var_pop?: Maybe<MonthOfFiscalYearVarPopFields>;
  var_samp?: Maybe<MonthOfFiscalYearVarSampFields>;
  variance?: Maybe<MonthOfFiscalYearVarianceFields>;
};


/** aggregate fields of "month_of_fiscal_year" */
export type MonthOfFiscalYearAggregateFieldsCountArgs = {
  columns?: Maybe<Array<MonthOfFiscalYearSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type MonthOfFiscalYearAvgFields = {
  __typename?: 'month_of_fiscal_year_avg_fields';
  month?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "month_of_fiscal_year". All fields are combined with a logical 'AND'. */
export type MonthOfFiscalYearBoolExp = {
  _and?: Maybe<Array<MonthOfFiscalYearBoolExp>>;
  _not?: Maybe<MonthOfFiscalYearBoolExp>;
  _or?: Maybe<Array<MonthOfFiscalYearBoolExp>>;
  month?: Maybe<IntComparisonExp>;
  month_name?: Maybe<StringComparisonExp>;
  sequence?: Maybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type MonthOfFiscalYearMaxFields = {
  __typename?: 'month_of_fiscal_year_max_fields';
  month?: Maybe<Scalars['Int']>;
  month_name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type MonthOfFiscalYearMinFields = {
  __typename?: 'month_of_fiscal_year_min_fields';
  month?: Maybe<Scalars['Int']>;
  month_name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "month_of_fiscal_year". */
export type MonthOfFiscalYearOrderBy = {
  month?: Maybe<OrderBy>;
  month_name?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** select columns of table "month_of_fiscal_year" */
export enum MonthOfFiscalYearSelectColumn {
  /** column name */
  Month = 'month',
  /** column name */
  MonthName = 'month_name',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate stddev on columns */
export type MonthOfFiscalYearStddevFields = {
  __typename?: 'month_of_fiscal_year_stddev_fields';
  month?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type MonthOfFiscalYearStddevPopFields = {
  __typename?: 'month_of_fiscal_year_stddev_pop_fields';
  month?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type MonthOfFiscalYearStddevSampFields = {
  __typename?: 'month_of_fiscal_year_stddev_samp_fields';
  month?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type MonthOfFiscalYearSumFields = {
  __typename?: 'month_of_fiscal_year_sum_fields';
  month?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type MonthOfFiscalYearVarPopFields = {
  __typename?: 'month_of_fiscal_year_var_pop_fields';
  month?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type MonthOfFiscalYearVarSampFields = {
  __typename?: 'month_of_fiscal_year_var_samp_fields';
  month?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MonthOfFiscalYearVarianceFields = {
  __typename?: 'month_of_fiscal_year_variance_fields';
  month?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type MutationRoot = {
  __typename?: 'mutation_root';
  /** คัดลอกงบประมาณจากปีงบประมาณที่มีอยู่เดิม */
  account_budget_duplicate_by_fiscal_year: AccountBudgetDuplicateByFiscalYearOutput;
  account_transaction_approve: AccountTransactionApprovalOutput;
  approve_loan_application?: Maybe<ApproveLoanApplicationOutput>;
  approve_loan_application_organization_transfer?: Maybe<ApproveLoanApplicationOrganizationTransferOutput>;
  /** เปลี่ยนรหัสผ่านผู้ใช้ */
  auth_account_change_password: ChangePasswordOutput;
  create_draft_loan_application?: Maybe<CreateDraftLoanApplicationOutput>;
  create_draft_loan_application_standalone?: Maybe<CreateDraftLoanApplicationStandaloneOutput>;
  /** delete data from the table: "account.account" */
  delete_account_account?: Maybe<AccountAccountMutationResponse>;
  /** delete single row from the table: "account.account" */
  delete_account_account_by_pk?: Maybe<AccountAccount>;
  /** delete data from the table: "account.account_category" */
  delete_account_account_category?: Maybe<AccountAccountCategoryMutationResponse>;
  /** delete single row from the table: "account.account_category" */
  delete_account_account_category_by_pk?: Maybe<AccountAccountCategory>;
  /** delete data from the table: "account.account_type" */
  delete_account_account_type?: Maybe<AccountAccountTypeMutationResponse>;
  /** delete single row from the table: "account.account_type" */
  delete_account_account_type_by_pk?: Maybe<AccountAccountType>;
  /** delete data from the table: "account.budget_account" */
  delete_account_budget_account?: Maybe<AccountBudgetAccountMutationResponse>;
  /** delete single row from the table: "account.budget_account" */
  delete_account_budget_account_by_pk?: Maybe<AccountBudgetAccount>;
  /** delete data from the table: "account.budget_allocation" */
  delete_account_budget_allocation?: Maybe<AccountBudgetAllocationMutationResponse>;
  /** delete single row from the table: "account.budget_allocation" */
  delete_account_budget_allocation_by_pk?: Maybe<AccountBudgetAllocation>;
  /** delete data from the table: "account.budget_allocation_log" */
  delete_account_budget_allocation_log?: Maybe<AccountBudgetAllocationLogMutationResponse>;
  /** delete single row from the table: "account.budget_allocation_log" */
  delete_account_budget_allocation_log_by_pk?: Maybe<AccountBudgetAllocationLog>;
  /** delete data from the table: "account.budget_type" */
  delete_account_budget_type?: Maybe<AccountBudgetTypeMutationResponse>;
  /** delete single row from the table: "account.budget_type" */
  delete_account_budget_type_by_pk?: Maybe<AccountBudgetType>;
  /** delete data from the table: "account.entry_type" */
  delete_account_entry_type?: Maybe<AccountEntryTypeMutationResponse>;
  /** delete single row from the table: "account.entry_type" */
  delete_account_entry_type_by_pk?: Maybe<AccountEntryType>;
  /** delete data from the table: "account.ledger_entry" */
  delete_account_ledger_entry?: Maybe<AccountLedgerEntryMutationResponse>;
  /** delete single row from the table: "account.ledger_entry" */
  delete_account_ledger_entry_by_pk?: Maybe<AccountLedgerEntry>;
  /** delete data from the table: "account.lending" */
  delete_account_lending?: Maybe<AccountLendingMutationResponse>;
  /** delete single row from the table: "account.lending" */
  delete_account_lending_by_pk?: Maybe<AccountLending>;
  /** delete data from the table: "account.lending_item" */
  delete_account_lending_item?: Maybe<AccountLendingItemMutationResponse>;
  /** delete single row from the table: "account.lending_item" */
  delete_account_lending_item_by_pk?: Maybe<AccountLendingItem>;
  /** delete data from the table: "account.lending_status" */
  delete_account_lending_status?: Maybe<AccountLendingStatusMutationResponse>;
  /** delete single row from the table: "account.lending_status" */
  delete_account_lending_status_by_pk?: Maybe<AccountLendingStatus>;
  /** delete data from the table: "account.transaction" */
  delete_account_transaction?: Maybe<AccountTransactionMutationResponse>;
  /** delete single row from the table: "account.transaction" */
  delete_account_transaction_by_pk?: Maybe<AccountTransaction>;
  /** delete data from the table: "account.transaction_log" */
  delete_account_transaction_log?: Maybe<AccountTransactionLogMutationResponse>;
  /** delete single row from the table: "account.transaction_log" */
  delete_account_transaction_log_by_pk?: Maybe<AccountTransactionLog>;
  /** delete data from the table: "account.transaction_status" */
  delete_account_transaction_status?: Maybe<AccountTransactionStatusMutationResponse>;
  /** delete single row from the table: "account.transaction_status" */
  delete_account_transaction_status_by_pk?: Maybe<AccountTransactionStatus>;
  /** delete data from the table: "account.transaction_type" */
  delete_account_transaction_type?: Maybe<AccountTransactionTypeMutationResponse>;
  /** delete single row from the table: "account.transaction_type" */
  delete_account_transaction_type_by_pk?: Maybe<AccountTransactionType>;
  /** delete data from the table: "attachment" */
  delete_attachment?: Maybe<AttachmentMutationResponse>;
  /** delete single row from the table: "attachment" */
  delete_attachment_by_pk?: Maybe<Attachment>;
  /** delete data from the table: "audit.logged_actions" */
  delete_audit_logged_actions?: Maybe<AuditLoggedActionsMutationResponse>;
  /** delete single row from the table: "audit.logged_actions" */
  delete_audit_logged_actions_by_pk?: Maybe<AuditLoggedActions>;
  /** delete data from the table: "auth.account_providers" */
  delete_auth_account_providers?: Maybe<AuthAccountProvidersMutationResponse>;
  /** delete single row from the table: "auth.account_providers" */
  delete_auth_account_providers_by_pk?: Maybe<AuthAccountProviders>;
  /** delete data from the table: "auth.account_roles" */
  delete_auth_account_roles?: Maybe<AuthAccountRolesMutationResponse>;
  /** delete single row from the table: "auth.account_roles" */
  delete_auth_account_roles_by_pk?: Maybe<AuthAccountRoles>;
  /** delete data from the table: "auth.accounts" */
  delete_auth_accounts?: Maybe<AuthAccountsMutationResponse>;
  /** delete single row from the table: "auth.accounts" */
  delete_auth_accounts_by_pk?: Maybe<AuthAccounts>;
  /** delete data from the table: "auth.providers" */
  delete_auth_providers?: Maybe<AuthProvidersMutationResponse>;
  /** delete single row from the table: "auth.providers" */
  delete_auth_providers_by_pk?: Maybe<AuthProviders>;
  /** delete data from the table: "auth.refresh_tokens" */
  delete_auth_refresh_tokens?: Maybe<AuthRefreshTokensMutationResponse>;
  /** delete single row from the table: "auth.refresh_tokens" */
  delete_auth_refresh_tokens_by_pk?: Maybe<AuthRefreshTokens>;
  /** delete data from the table: "auth.roles" */
  delete_auth_roles?: Maybe<AuthRolesMutationResponse>;
  /** delete single row from the table: "auth.roles" */
  delete_auth_roles_by_pk?: Maybe<AuthRoles>;
  /** delete data from the table: "bank_branch" */
  delete_bank_branch?: Maybe<BankBranchMutationResponse>;
  /** delete single row from the table: "bank_branch" */
  delete_bank_branch_by_pk?: Maybe<BankBranch>;
  /** delete data from the table: "contract.af_status" */
  delete_contract_af_status?: Maybe<ContractAfStatusMutationResponse>;
  /** delete single row from the table: "contract.af_status" */
  delete_contract_af_status_by_pk?: Maybe<ContractAfStatus>;
  /** delete data from the table: "contract.agreement" */
  delete_contract_agreement?: Maybe<ContractAgreementMutationResponse>;
  /** delete data from the table: "contract.agreement_attachment" */
  delete_contract_agreement_attachment?: Maybe<ContractAgreementAttachmentMutationResponse>;
  /** delete single row from the table: "contract.agreement_attachment" */
  delete_contract_agreement_attachment_by_pk?: Maybe<ContractAgreementAttachment>;
  /** delete single row from the table: "contract.agreement" */
  delete_contract_agreement_by_pk?: Maybe<ContractAgreement>;
  /** delete data from the table: "contract.agreement_detail" */
  delete_contract_agreement_detail?: Maybe<ContractAgreementDetailMutationResponse>;
  /** delete single row from the table: "contract.agreement_detail" */
  delete_contract_agreement_detail_by_pk?: Maybe<ContractAgreementDetail>;
  /** delete data from the table: "contract.agreement_disbursement" */
  delete_contract_agreement_disbursement?: Maybe<ContractAgreementDisbursementMutationResponse>;
  /** delete single row from the table: "contract.agreement_disbursement" */
  delete_contract_agreement_disbursement_by_pk?: Maybe<ContractAgreementDisbursement>;
  /** delete data from the table: "contract.agreement_fee_charge" */
  delete_contract_agreement_fee_charge?: Maybe<ContractAgreementFeeChargeMutationResponse>;
  /** delete single row from the table: "contract.agreement_fee_charge" */
  delete_contract_agreement_fee_charge_by_pk?: Maybe<ContractAgreementFeeCharge>;
  /** delete data from the table: "contract.agreement_guarantor" */
  delete_contract_agreement_guarantor?: Maybe<ContractAgreementGuarantorMutationResponse>;
  /** delete single row from the table: "contract.agreement_guarantor" */
  delete_contract_agreement_guarantor_by_pk?: Maybe<ContractAgreementGuarantor>;
  /** delete data from the table: "contract.agreement_receipt" */
  delete_contract_agreement_receipt?: Maybe<ContractAgreementReceiptMutationResponse>;
  /** delete single row from the table: "contract.agreement_receipt" */
  delete_contract_agreement_receipt_by_pk?: Maybe<ContractAgreementReceipt>;
  /** delete data from the table: "contract.agreement_status" */
  delete_contract_agreement_status?: Maybe<ContractAgreementStatusMutationResponse>;
  /** delete single row from the table: "contract.agreement_status" */
  delete_contract_agreement_status_by_pk?: Maybe<ContractAgreementStatus>;
  /** delete data from the table: "contract.agreement_status_mapper" */
  delete_contract_agreement_status_mapper?: Maybe<ContractAgreementStatusMapperMutationResponse>;
  /** delete single row from the table: "contract.agreement_status_mapper" */
  delete_contract_agreement_status_mapper_by_pk?: Maybe<ContractAgreementStatusMapper>;
  /** delete data from the table: "contract.agreement_transaction" */
  delete_contract_agreement_transaction?: Maybe<ContractAgreementTransactionMutationResponse>;
  /** delete single row from the table: "contract.agreement_transaction" */
  delete_contract_agreement_transaction_by_pk?: Maybe<ContractAgreementTransaction>;
  /** delete data from the table: "contract.agreement_withdraw" */
  delete_contract_agreement_withdraw?: Maybe<ContractAgreementWithdrawMutationResponse>;
  /** delete single row from the table: "contract.agreement_withdraw" */
  delete_contract_agreement_withdraw_by_pk?: Maybe<ContractAgreementWithdraw>;
  /** delete data from the table: "contract.attachment_type" */
  delete_contract_attachment_type?: Maybe<ContractAttachmentTypeMutationResponse>;
  /** delete single row from the table: "contract.attachment_type" */
  delete_contract_attachment_type_by_pk?: Maybe<ContractAttachmentType>;
  /** delete data from the table: "contract.grade" */
  delete_contract_grade?: Maybe<ContractGradeMutationResponse>;
  /** delete single row from the table: "contract.grade" */
  delete_contract_grade_by_pk?: Maybe<ContractGrade>;
  /** delete data from the table: "contract.transaction_creator_type" */
  delete_contract_transaction_creator_type?: Maybe<ContractTransactionCreatorTypeMutationResponse>;
  /** delete single row from the table: "contract.transaction_creator_type" */
  delete_contract_transaction_creator_type_by_pk?: Maybe<ContractTransactionCreatorType>;
  /** delete data from the table: "current_user_permissions" */
  delete_current_user_permissions?: Maybe<CurrentUserPermissionsMutationResponse>;
  /** delete data from the table: "department" */
  delete_department?: Maybe<DepartmentMutationResponse>;
  /** delete single row from the table: "department" */
  delete_department_by_pk?: Maybe<Department>;
  /** delete data from the table: "feature_toggle" */
  delete_feature_toggle?: Maybe<FeatureToggleMutationResponse>;
  /** delete single row from the table: "feature_toggle" */
  delete_feature_toggle_by_pk?: Maybe<FeatureToggle>;
  /** delete data from the table: "fiscal_year" */
  delete_fiscal_year?: Maybe<FiscalYearMutationResponse>;
  /** delete single row from the table: "fiscal_year" */
  delete_fiscal_year_by_pk?: Maybe<FiscalYear>;
  /** delete data from the table: "import_log" */
  delete_import_log?: Maybe<ImportLogMutationResponse>;
  /** delete single row from the table: "import_log" */
  delete_import_log_by_pk?: Maybe<ImportLog>;
  /** delete data from the table: "juristic" */
  delete_juristic?: Maybe<JuristicMutationResponse>;
  /** delete data from the table: "juristic_address" */
  delete_juristic_address?: Maybe<JuristicAddressMutationResponse>;
  /** delete single row from the table: "juristic_address" */
  delete_juristic_address_by_pk?: Maybe<JuristicAddress>;
  /** delete single row from the table: "juristic" */
  delete_juristic_by_pk?: Maybe<Juristic>;
  /** delete data from the table: "juristic_committee" */
  delete_juristic_committee?: Maybe<JuristicCommitteeMutationResponse>;
  /** delete single row from the table: "juristic_committee" */
  delete_juristic_committee_by_pk?: Maybe<JuristicCommittee>;
  /** delete data from the table: "juristic_standard_objective" */
  delete_juristic_standard_objective?: Maybe<JuristicStandardObjectiveMutationResponse>;
  /** delete single row from the table: "juristic_standard_objective" */
  delete_juristic_standard_objective_by_pk?: Maybe<JuristicStandardObjective>;
  /** delete data from the table: "ledger.budget_allocation" */
  delete_ledger_budget_allocation?: Maybe<LedgerBudgetAllocationMutationResponse>;
  /** delete single row from the table: "ledger.budget_allocation" */
  delete_ledger_budget_allocation_by_pk?: Maybe<LedgerBudgetAllocation>;
  /** delete data from the table: "ledger.ledger" */
  delete_ledger_ledger?: Maybe<LedgerLedgerMutationResponse>;
  /** delete data from the table: "ledger.ledger_attachment" */
  delete_ledger_ledger_attachment?: Maybe<LedgerLedgerAttachmentMutationResponse>;
  /** delete single row from the table: "ledger.ledger_attachment" */
  delete_ledger_ledger_attachment_by_pk?: Maybe<LedgerLedgerAttachment>;
  /** delete single row from the table: "ledger.ledger" */
  delete_ledger_ledger_by_pk?: Maybe<LedgerLedger>;
  /** delete data from the table: "ledger.ledger_log" */
  delete_ledger_ledger_log?: Maybe<LedgerLedgerLogMutationResponse>;
  /** delete single row from the table: "ledger.ledger_log" */
  delete_ledger_ledger_log_by_pk?: Maybe<LedgerLedgerLog>;
  /** delete data from the table: "ledger.ledger_status" */
  delete_ledger_ledger_status?: Maybe<LedgerLedgerStatusMutationResponse>;
  /** delete single row from the table: "ledger.ledger_status" */
  delete_ledger_ledger_status_by_pk?: Maybe<LedgerLedgerStatus>;
  /** delete data from the table: "ledger.ledger_type" */
  delete_ledger_ledger_type?: Maybe<LedgerLedgerTypeMutationResponse>;
  /** delete single row from the table: "ledger.ledger_type" */
  delete_ledger_ledger_type_by_pk?: Maybe<LedgerLedgerType>;
  /** delete data from the table: "ledger.receivable" */
  delete_ledger_receivable?: Maybe<LedgerReceivableMutationResponse>;
  /** delete single row from the table: "ledger.receivable" */
  delete_ledger_receivable_by_pk?: Maybe<LedgerReceivable>;
  /** delete data from the table: "ledger.receivable_item" */
  delete_ledger_receivable_item?: Maybe<LedgerReceivableItemMutationResponse>;
  /** delete single row from the table: "ledger.receivable_item" */
  delete_ledger_receivable_item_by_pk?: Maybe<LedgerReceivableItem>;
  /** delete data from the table: "ledger.receivable_log" */
  delete_ledger_receivable_log?: Maybe<LedgerReceivableLogMutationResponse>;
  /** delete single row from the table: "ledger.receivable_log" */
  delete_ledger_receivable_log_by_pk?: Maybe<LedgerReceivableLog>;
  /** delete data from the table: "ledger.receivable_status" */
  delete_ledger_receivable_status?: Maybe<LedgerReceivableStatusMutationResponse>;
  /** delete single row from the table: "ledger.receivable_status" */
  delete_ledger_receivable_status_by_pk?: Maybe<LedgerReceivableStatus>;
  /** delete data from the table: "loan.account_type" */
  delete_loan_account_type?: Maybe<LoanAccountTypeMutationResponse>;
  /** delete single row from the table: "loan.account_type" */
  delete_loan_account_type_by_pk?: Maybe<LoanAccountType>;
  /** delete data from the table: "loan.application_attachment" */
  delete_loan_application_attachment?: Maybe<LoanApplicationAttachmentMutationResponse>;
  /** delete single row from the table: "loan.application_attachment" */
  delete_loan_application_attachment_by_pk?: Maybe<LoanApplicationAttachment>;
  /** delete data from the table: "loan.application_bank_branch_transfer" */
  delete_loan_application_bank_branch_transfer?: Maybe<LoanApplicationBankBranchTransferMutationResponse>;
  /** delete single row from the table: "loan.application_bank_branch_transfer" */
  delete_loan_application_bank_branch_transfer_by_pk?: Maybe<LoanApplicationBankBranchTransfer>;
  /** delete data from the table: "loan.application_business_operation" */
  delete_loan_application_business_operation?: Maybe<LoanApplicationBusinessOperationMutationResponse>;
  /** delete single row from the table: "loan.application_business_operation" */
  delete_loan_application_business_operation_by_pk?: Maybe<LoanApplicationBusinessOperation>;
  /** delete data from the table: "loan.application_committee" */
  delete_loan_application_committee?: Maybe<LoanApplicationCommitteeMutationResponse>;
  /** delete single row from the table: "loan.application_committee" */
  delete_loan_application_committee_by_pk?: Maybe<LoanApplicationCommittee>;
  /** delete data from the table: "loan.application_current_loan" */
  delete_loan_application_current_loan?: Maybe<LoanApplicationCurrentLoanMutationResponse>;
  /** delete single row from the table: "loan.application_current_loan" */
  delete_loan_application_current_loan_by_pk?: Maybe<LoanApplicationCurrentLoan>;
  /** delete data from the table: "loan.application_detail" */
  delete_loan_application_detail?: Maybe<LoanApplicationDetailMutationResponse>;
  /** delete single row from the table: "loan.application_detail" */
  delete_loan_application_detail_by_pk?: Maybe<LoanApplicationDetail>;
  /** delete data from the table: "loan.application_event_log" */
  delete_loan_application_event_log?: Maybe<LoanApplicationEventLogMutationResponse>;
  /** delete single row from the table: "loan.application_event_log" */
  delete_loan_application_event_log_by_pk?: Maybe<LoanApplicationEventLog>;
  /** delete data from the table: "loan.application_header" */
  delete_loan_application_header?: Maybe<LoanApplicationHeaderMutationResponse>;
  /** delete single row from the table: "loan.application_header" */
  delete_loan_application_header_by_pk?: Maybe<LoanApplicationHeader>;
  /** delete data from the table: "loan.application_header_pdf" */
  delete_loan_application_header_pdf?: Maybe<LoanApplicationHeaderPdfMutationResponse>;
  /** delete single row from the table: "loan.application_header_pdf" */
  delete_loan_application_header_pdf_by_pk?: Maybe<LoanApplicationHeaderPdf>;
  /** delete data from the table: "loan.application_objective" */
  delete_loan_application_objective?: Maybe<LoanApplicationObjectiveMutationResponse>;
  /** delete single row from the table: "loan.application_objective" */
  delete_loan_application_objective_by_pk?: Maybe<LoanApplicationObjective>;
  /** delete data from the table: "loan.application_organization_transfer" */
  delete_loan_application_organization_transfer?: Maybe<LoanApplicationOrganizationTransferMutationResponse>;
  /** delete single row from the table: "loan.application_organization_transfer" */
  delete_loan_application_organization_transfer_by_pk?: Maybe<LoanApplicationOrganizationTransfer>;
  /** delete data from the table: "loan.application_profile" */
  delete_loan_application_profile?: Maybe<LoanApplicationProfileMutationResponse>;
  /** delete single row from the table: "loan.application_profile" */
  delete_loan_application_profile_by_pk?: Maybe<LoanApplicationProfile>;
  /** delete data from the table: "loan.application_profile_s_curve" */
  delete_loan_application_profile_s_curve?: Maybe<LoanApplicationProfileSCurveMutationResponse>;
  /** delete single row from the table: "loan.application_profile_s_curve" */
  delete_loan_application_profile_s_curve_by_pk?: Maybe<LoanApplicationProfileSCurve>;
  /** delete data from the table: "loan.application_sequence_no" */
  delete_loan_application_sequence_no?: Maybe<LoanApplicationSequenceNoMutationResponse>;
  /** delete single row from the table: "loan.application_sequence_no" */
  delete_loan_application_sequence_no_by_pk?: Maybe<LoanApplicationSequenceNo>;
  /** delete data from the table: "loan.application_step" */
  delete_loan_application_step?: Maybe<LoanApplicationStepMutationResponse>;
  /** delete single row from the table: "loan.application_step" */
  delete_loan_application_step_by_pk?: Maybe<LoanApplicationStep>;
  /** delete data from the table: "loan.attachment_type" */
  delete_loan_attachment_type?: Maybe<LoanAttachmentTypeMutationResponse>;
  /** delete single row from the table: "loan.attachment_type" */
  delete_loan_attachment_type_by_pk?: Maybe<LoanAttachmentType>;
  /** delete data from the table: "loan.bank_approve" */
  delete_loan_bank_approve?: Maybe<LoanBankApproveMutationResponse>;
  /** delete single row from the table: "loan.bank_approve" */
  delete_loan_bank_approve_by_pk?: Maybe<LoanBankApprove>;
  /** delete data from the table: "loan.business_group" */
  delete_loan_business_group?: Maybe<LoanBusinessGroupMutationResponse>;
  /** delete single row from the table: "loan.business_group" */
  delete_loan_business_group_by_pk?: Maybe<LoanBusinessGroup>;
  /** delete data from the table: "loan.business_operation" */
  delete_loan_business_operation?: Maybe<LoanBusinessOperationMutationResponse>;
  /** delete single row from the table: "loan.business_operation" */
  delete_loan_business_operation_by_pk?: Maybe<LoanBusinessOperation>;
  /** delete data from the table: "loan.business_proprietary" */
  delete_loan_business_proprietary?: Maybe<LoanBusinessProprietaryMutationResponse>;
  /** delete single row from the table: "loan.business_proprietary" */
  delete_loan_business_proprietary_by_pk?: Maybe<LoanBusinessProprietary>;
  /** delete data from the table: "loan.business_status" */
  delete_loan_business_status?: Maybe<LoanBusinessStatusMutationResponse>;
  /** delete single row from the table: "loan.business_status" */
  delete_loan_business_status_by_pk?: Maybe<LoanBusinessStatus>;
  /** delete data from the table: "loan.business_type" */
  delete_loan_business_type?: Maybe<LoanBusinessTypeMutationResponse>;
  /** delete single row from the table: "loan.business_type" */
  delete_loan_business_type_by_pk?: Maybe<LoanBusinessType>;
  /** delete data from the table: "loan.committee_approve" */
  delete_loan_committee_approve?: Maybe<LoanCommitteeApproveMutationResponse>;
  /** delete single row from the table: "loan.committee_approve" */
  delete_loan_committee_approve_by_pk?: Maybe<LoanCommitteeApprove>;
  /** delete data from the table: "loan.current_loan" */
  delete_loan_current_loan?: Maybe<LoanCurrentLoanMutationResponse>;
  /** delete single row from the table: "loan.current_loan" */
  delete_loan_current_loan_by_pk?: Maybe<LoanCurrentLoan>;
  /** delete data from the table: "loan.debt_restructuring" */
  delete_loan_debt_restructuring?: Maybe<LoanDebtRestructuringMutationResponse>;
  /** delete single row from the table: "loan.debt_restructuring" */
  delete_loan_debt_restructuring_by_pk?: Maybe<LoanDebtRestructuring>;
  /** delete data from the table: "loan.document_approve" */
  delete_loan_document_approve?: Maybe<LoanDocumentApproveMutationResponse>;
  /** delete single row from the table: "loan.document_approve" */
  delete_loan_document_approve_by_pk?: Maybe<LoanDocumentApprove>;
  /** delete data from the table: "loan.financial_agreement" */
  delete_loan_financial_agreement?: Maybe<LoanFinancialAgreementMutationResponse>;
  /** delete single row from the table: "loan.financial_agreement" */
  delete_loan_financial_agreement_by_pk?: Maybe<LoanFinancialAgreement>;
  /** delete data from the table: "loan.financial_rescue" */
  delete_loan_financial_rescue?: Maybe<LoanFinancialRescueMutationResponse>;
  /** delete single row from the table: "loan.financial_rescue" */
  delete_loan_financial_rescue_by_pk?: Maybe<LoanFinancialRescue>;
  /** delete data from the table: "loan.objective" */
  delete_loan_objective?: Maybe<LoanObjectiveMutationResponse>;
  /** delete single row from the table: "loan.objective" */
  delete_loan_objective_by_pk?: Maybe<LoanObjective>;
  /** delete data from the table: "loan.program" */
  delete_loan_program?: Maybe<LoanProgramMutationResponse>;
  /** delete single row from the table: "loan.program" */
  delete_loan_program_by_pk?: Maybe<LoanProgram>;
  /** delete data from the table: "loan.program_status" */
  delete_loan_program_status?: Maybe<LoanProgramStatusMutationResponse>;
  /** delete single row from the table: "loan.program_status" */
  delete_loan_program_status_by_pk?: Maybe<LoanProgramStatus>;
  /** delete data from the table: "loan.program_transaction" */
  delete_loan_program_transaction?: Maybe<LoanProgramTransactionMutationResponse>;
  /** delete single row from the table: "loan.program_transaction" */
  delete_loan_program_transaction_by_pk?: Maybe<LoanProgramTransaction>;
  /** delete data from the table: "loan.program_transaction_type" */
  delete_loan_program_transaction_type?: Maybe<LoanProgramTransactionTypeMutationResponse>;
  /** delete single row from the table: "loan.program_transaction_type" */
  delete_loan_program_transaction_type_by_pk?: Maybe<LoanProgramTransactionType>;
  /** delete data from the table: "loan.proprietary" */
  delete_loan_proprietary?: Maybe<LoanProprietaryMutationResponse>;
  /** delete single row from the table: "loan.proprietary" */
  delete_loan_proprietary_by_pk?: Maybe<LoanProprietary>;
  /** delete data from the table: "loan.repay_type" */
  delete_loan_repay_type?: Maybe<LoanRepayTypeMutationResponse>;
  /** delete single row from the table: "loan.repay_type" */
  delete_loan_repay_type_by_pk?: Maybe<LoanRepayType>;
  /** delete data from the table: "loan.s_curve" */
  delete_loan_s_curve?: Maybe<LoanSCurveMutationResponse>;
  /** delete single row from the table: "loan.s_curve" */
  delete_loan_s_curve_by_pk?: Maybe<LoanSCurve>;
  /** delete data from the table: "loan.s_curve_type" */
  delete_loan_s_curve_type?: Maybe<LoanSCurveTypeMutationResponse>;
  /** delete single row from the table: "loan.s_curve_type" */
  delete_loan_s_curve_type_by_pk?: Maybe<LoanSCurveType>;
  /** delete data from the table: "loan.sme_definition" */
  delete_loan_sme_definition?: Maybe<LoanSmeDefinitionMutationResponse>;
  /** delete single row from the table: "loan.sme_definition" */
  delete_loan_sme_definition_by_pk?: Maybe<LoanSmeDefinition>;
  /** delete data from the table: "loan_source.business_type" */
  delete_loan_source_business_type?: Maybe<LoanSourceBusinessTypeMutationResponse>;
  /** delete single row from the table: "loan_source.business_type" */
  delete_loan_source_business_type_by_pk?: Maybe<LoanSourceBusinessType>;
  /** delete data from the table: "loan_source.financial_institution" */
  delete_loan_source_financial_institution?: Maybe<LoanSourceFinancialInstitutionMutationResponse>;
  /** delete single row from the table: "loan_source.financial_institution" */
  delete_loan_source_financial_institution_by_pk?: Maybe<LoanSourceFinancialInstitution>;
  /** delete data from the table: "loan_source.loan" */
  delete_loan_source_loan?: Maybe<LoanSourceLoanMutationResponse>;
  /** delete data from the table: "loan_source.loan_attachment" */
  delete_loan_source_loan_attachment?: Maybe<LoanSourceLoanAttachmentMutationResponse>;
  /** delete single row from the table: "loan_source.loan_attachment" */
  delete_loan_source_loan_attachment_by_pk?: Maybe<LoanSourceLoanAttachment>;
  /** delete data from the table: "loan_source.loan_business_type" */
  delete_loan_source_loan_business_type?: Maybe<LoanSourceLoanBusinessTypeMutationResponse>;
  /** delete single row from the table: "loan_source.loan_business_type" */
  delete_loan_source_loan_business_type_by_pk?: Maybe<LoanSourceLoanBusinessType>;
  /** delete single row from the table: "loan_source.loan" */
  delete_loan_source_loan_by_pk?: Maybe<LoanSourceLoan>;
  /** delete data from the table: "loan_source.objective" */
  delete_loan_source_objective?: Maybe<LoanSourceObjectiveMutationResponse>;
  /** delete single row from the table: "loan_source.objective" */
  delete_loan_source_objective_by_pk?: Maybe<LoanSourceObjective>;
  /** delete data from the table: "loan_source.search_log" */
  delete_loan_source_search_log?: Maybe<LoanSourceSearchLogMutationResponse>;
  /** delete single row from the table: "loan_source.search_log" */
  delete_loan_source_search_log_by_pk?: Maybe<LoanSourceSearchLog>;
  /** delete data from the table: "loan.transfer_status" */
  delete_loan_transfer_status?: Maybe<LoanTransferStatusMutationResponse>;
  /** delete single row from the table: "loan.transfer_status" */
  delete_loan_transfer_status_by_pk?: Maybe<LoanTransferStatus>;
  /** delete data from the table: "me" */
  delete_me?: Maybe<MeMutationResponse>;
  /** delete data from the table: "name_title" */
  delete_name_title?: Maybe<NameTitleMutationResponse>;
  /** delete single row from the table: "name_title" */
  delete_name_title_by_pk?: Maybe<NameTitle>;
  /** delete data from the table: "notification" */
  delete_notification?: Maybe<NotificationMutationResponse>;
  /** delete single row from the table: "notification" */
  delete_notification_by_pk?: Maybe<Notification>;
  /** delete data from the table: "notification_status" */
  delete_notification_status?: Maybe<NotificationStatusMutationResponse>;
  /** delete single row from the table: "notification_status" */
  delete_notification_status_by_pk?: Maybe<NotificationStatus>;
  /** delete data from the table: "organization" */
  delete_organization?: Maybe<OrganizationMutationResponse>;
  /** delete single row from the table: "organization" */
  delete_organization_by_pk?: Maybe<Organization>;
  /** delete data from the table: "organization_type" */
  delete_organization_type?: Maybe<OrganizationTypeMutationResponse>;
  /** delete single row from the table: "organization_type" */
  delete_organization_type_by_pk?: Maybe<OrganizationType>;
  /** delete data from the table: "permission" */
  delete_permission?: Maybe<PermissionMutationResponse>;
  /** delete single row from the table: "permission" */
  delete_permission_by_pk?: Maybe<Permission>;
  /** delete data from the table: "posts" */
  delete_posts?: Maybe<PostsMutationResponse>;
  /** delete single row from the table: "posts" */
  delete_posts_by_pk?: Maybe<Posts>;
  /** delete data from the table: "project.approved" */
  delete_project_approved?: Maybe<ProjectApprovedMutationResponse>;
  /** delete single row from the table: "project.approved" */
  delete_project_approved_by_pk?: Maybe<ProjectApproved>;
  /** delete data from the table: "project.attachment_type" */
  delete_project_attachment_type?: Maybe<ProjectAttachmentTypeMutationResponse>;
  /** delete single row from the table: "project.attachment_type" */
  delete_project_attachment_type_by_pk?: Maybe<ProjectAttachmentType>;
  /** delete data from the table: "project.budget" */
  delete_project_budget?: Maybe<ProjectBudgetMutationResponse>;
  /** delete single row from the table: "project.budget" */
  delete_project_budget_by_pk?: Maybe<ProjectBudget>;
  /** delete data from the table: "project.budget_group" */
  delete_project_budget_group?: Maybe<ProjectBudgetGroupMutationResponse>;
  /** delete single row from the table: "project.budget_group" */
  delete_project_budget_group_by_pk?: Maybe<ProjectBudgetGroup>;
  /** delete data from the table: "project.budget_group_mapping" */
  delete_project_budget_group_mapping?: Maybe<ProjectBudgetGroupMappingMutationResponse>;
  /** delete single row from the table: "project.budget_group_mapping" */
  delete_project_budget_group_mapping_by_pk?: Maybe<ProjectBudgetGroupMapping>;
  /** delete data from the table: "project.indicator_type" */
  delete_project_indicator_type?: Maybe<ProjectIndicatorTypeMutationResponse>;
  /** delete single row from the table: "project.indicator_type" */
  delete_project_indicator_type_by_pk?: Maybe<ProjectIndicatorType>;
  /** delete data from the table: "project.master_plan" */
  delete_project_master_plan?: Maybe<ProjectMasterPlanMutationResponse>;
  /** delete data from the table: "project.national_strategy" */
  delete_project_national_strategy?: Maybe<ProjectNationalStrategyMutationResponse>;
  /** delete single row from the table: "project.national_strategy" */
  delete_project_national_strategy_by_pk?: Maybe<ProjectNationalStrategy>;
  /** delete data from the table: "project.operating_activity_progress" */
  delete_project_operating_activity_progress?: Maybe<ProjectOperatingActivityProgressMutationResponse>;
  /** delete single row from the table: "project.operating_activity_progress" */
  delete_project_operating_activity_progress_by_pk?: Maybe<ProjectOperatingActivityProgress>;
  /** delete data from the table: "project.operating_activity_progress_revision" */
  delete_project_operating_activity_progress_revision?: Maybe<ProjectOperatingActivityProgressRevisionMutationResponse>;
  /** delete single row from the table: "project.operating_activity_progress_revision" */
  delete_project_operating_activity_progress_revision_by_pk?: Maybe<ProjectOperatingActivityProgressRevision>;
  /** delete data from the table: "project.operating_activity_type" */
  delete_project_operating_activity_type?: Maybe<ProjectOperatingActivityTypeMutationResponse>;
  /** delete single row from the table: "project.operating_activity_type" */
  delete_project_operating_activity_type_by_pk?: Maybe<ProjectOperatingActivityType>;
  /** delete data from the table: "project.project" */
  delete_project_project?: Maybe<ProjectProjectMutationResponse>;
  /** delete data from the table: "project.project_achievement" */
  delete_project_project_achievement?: Maybe<ProjectProjectAchievementMutationResponse>;
  /** delete single row from the table: "project.project_achievement" */
  delete_project_project_achievement_by_pk?: Maybe<ProjectProjectAchievement>;
  /** delete data from the table: "project.project_attachment" */
  delete_project_project_attachment?: Maybe<ProjectProjectAttachmentMutationResponse>;
  /** delete single row from the table: "project.project_attachment" */
  delete_project_project_attachment_by_pk?: Maybe<ProjectProjectAttachment>;
  /** delete data from the table: "project.project_budget_item" */
  delete_project_project_budget_item?: Maybe<ProjectProjectBudgetItemMutationResponse>;
  /** delete single row from the table: "project.project_budget_item" */
  delete_project_project_budget_item_by_pk?: Maybe<ProjectProjectBudgetItem>;
  /** delete single row from the table: "project.project" */
  delete_project_project_by_pk?: Maybe<ProjectProject>;
  /** delete data from the table: "project.project_event_log" */
  delete_project_project_event_log?: Maybe<ProjectProjectEventLogMutationResponse>;
  /** delete single row from the table: "project.project_event_log" */
  delete_project_project_event_log_by_pk?: Maybe<ProjectProjectEventLog>;
  /** delete data from the table: "project.project_indicator" */
  delete_project_project_indicator?: Maybe<ProjectProjectIndicatorMutationResponse>;
  /** delete single row from the table: "project.project_indicator" */
  delete_project_project_indicator_by_pk?: Maybe<ProjectProjectIndicator>;
  /** delete data from the table: "project.project_member" */
  delete_project_project_member?: Maybe<ProjectProjectMemberMutationResponse>;
  /** delete single row from the table: "project.project_member" */
  delete_project_project_member_by_pk?: Maybe<ProjectProjectMember>;
  /** delete data from the table: "project.project_objective" */
  delete_project_project_objective?: Maybe<ProjectProjectObjectiveMutationResponse>;
  /** delete single row from the table: "project.project_objective" */
  delete_project_project_objective_by_pk?: Maybe<ProjectProjectObjective>;
  /** delete data from the table: "project.project_operating_activity" */
  delete_project_project_operating_activity?: Maybe<ProjectProjectOperatingActivityMutationResponse>;
  /** delete single row from the table: "project.project_operating_activity" */
  delete_project_project_operating_activity_by_pk?: Maybe<ProjectProjectOperatingActivity>;
  /** delete data from the table: "project.project_operating_area" */
  delete_project_project_operating_area?: Maybe<ProjectProjectOperatingAreaMutationResponse>;
  /** delete single row from the table: "project.project_operating_area" */
  delete_project_project_operating_area_by_pk?: Maybe<ProjectProjectOperatingArea>;
  /** delete data from the table: "project.project_step" */
  delete_project_project_step?: Maybe<ProjectProjectStepMutationResponse>;
  /** delete single row from the table: "project.project_step" */
  delete_project_project_step_by_pk?: Maybe<ProjectProjectStep>;
  /** delete data from the table: "project.project_target_group" */
  delete_project_project_target_group?: Maybe<ProjectProjectTargetGroupMutationResponse>;
  /** delete single row from the table: "project.project_target_group" */
  delete_project_project_target_group_by_pk?: Maybe<ProjectProjectTargetGroup>;
  /** delete data from the table: "project.sub_plan" */
  delete_project_sub_plan?: Maybe<ProjectSubPlanMutationResponse>;
  /** delete data from the table: "project.target_group" */
  delete_project_target_group?: Maybe<ProjectTargetGroupMutationResponse>;
  /** delete single row from the table: "project.target_group" */
  delete_project_target_group_by_pk?: Maybe<ProjectTargetGroup>;
  /** delete data from the table: "project.unit" */
  delete_project_unit?: Maybe<ProjectUnitMutationResponse>;
  /** delete single row from the table: "project.unit" */
  delete_project_unit_by_pk?: Maybe<ProjectUnit>;
  /** delete data from the table: "province" */
  delete_province?: Maybe<ProvinceMutationResponse>;
  /** delete single row from the table: "province" */
  delete_province_by_pk?: Maybe<Province>;
  /** delete data from the table: "region" */
  delete_region?: Maybe<RegionMutationResponse>;
  /** delete single row from the table: "region" */
  delete_region_by_pk?: Maybe<Region>;
  /** delete data from the table: "user_mapping" */
  delete_user_mapping?: Maybe<UserMappingMutationResponse>;
  /** delete single row from the table: "user_mapping" */
  delete_user_mapping_by_pk?: Maybe<UserMapping>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<UsersMutationResponse>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "users_permission" */
  delete_users_permission?: Maybe<UsersPermissionMutationResponse>;
  /** delete single row from the table: "users_permission" */
  delete_users_permission_by_pk?: Maybe<UsersPermission>;
  generate_pdf_file?: Maybe<GeneratePdfFileOutput>;
  /** insert data into the table: "account.account" */
  insert_account_account?: Maybe<AccountAccountMutationResponse>;
  /** insert data into the table: "account.account_category" */
  insert_account_account_category?: Maybe<AccountAccountCategoryMutationResponse>;
  /** insert a single row into the table: "account.account_category" */
  insert_account_account_category_one?: Maybe<AccountAccountCategory>;
  /** insert a single row into the table: "account.account" */
  insert_account_account_one?: Maybe<AccountAccount>;
  /** insert data into the table: "account.account_type" */
  insert_account_account_type?: Maybe<AccountAccountTypeMutationResponse>;
  /** insert a single row into the table: "account.account_type" */
  insert_account_account_type_one?: Maybe<AccountAccountType>;
  /** insert data into the table: "account.budget_account" */
  insert_account_budget_account?: Maybe<AccountBudgetAccountMutationResponse>;
  /** insert a single row into the table: "account.budget_account" */
  insert_account_budget_account_one?: Maybe<AccountBudgetAccount>;
  /** insert data into the table: "account.budget_allocation" */
  insert_account_budget_allocation?: Maybe<AccountBudgetAllocationMutationResponse>;
  /** insert data into the table: "account.budget_allocation_log" */
  insert_account_budget_allocation_log?: Maybe<AccountBudgetAllocationLogMutationResponse>;
  /** insert a single row into the table: "account.budget_allocation_log" */
  insert_account_budget_allocation_log_one?: Maybe<AccountBudgetAllocationLog>;
  /** insert a single row into the table: "account.budget_allocation" */
  insert_account_budget_allocation_one?: Maybe<AccountBudgetAllocation>;
  /** insert data into the table: "account.budget_type" */
  insert_account_budget_type?: Maybe<AccountBudgetTypeMutationResponse>;
  /** insert a single row into the table: "account.budget_type" */
  insert_account_budget_type_one?: Maybe<AccountBudgetType>;
  /** insert data into the table: "account.entry_type" */
  insert_account_entry_type?: Maybe<AccountEntryTypeMutationResponse>;
  /** insert a single row into the table: "account.entry_type" */
  insert_account_entry_type_one?: Maybe<AccountEntryType>;
  /** insert data into the table: "account.ledger_entry" */
  insert_account_ledger_entry?: Maybe<AccountLedgerEntryMutationResponse>;
  /** insert a single row into the table: "account.ledger_entry" */
  insert_account_ledger_entry_one?: Maybe<AccountLedgerEntry>;
  /** insert data into the table: "account.lending" */
  insert_account_lending?: Maybe<AccountLendingMutationResponse>;
  /** insert data into the table: "account.lending_item" */
  insert_account_lending_item?: Maybe<AccountLendingItemMutationResponse>;
  /** insert a single row into the table: "account.lending_item" */
  insert_account_lending_item_one?: Maybe<AccountLendingItem>;
  /** insert a single row into the table: "account.lending" */
  insert_account_lending_one?: Maybe<AccountLending>;
  /** insert data into the table: "account.lending_status" */
  insert_account_lending_status?: Maybe<AccountLendingStatusMutationResponse>;
  /** insert a single row into the table: "account.lending_status" */
  insert_account_lending_status_one?: Maybe<AccountLendingStatus>;
  /** insert data into the table: "account.transaction" */
  insert_account_transaction?: Maybe<AccountTransactionMutationResponse>;
  /** insert data into the table: "account.transaction_log" */
  insert_account_transaction_log?: Maybe<AccountTransactionLogMutationResponse>;
  /** insert a single row into the table: "account.transaction_log" */
  insert_account_transaction_log_one?: Maybe<AccountTransactionLog>;
  /** insert a single row into the table: "account.transaction" */
  insert_account_transaction_one?: Maybe<AccountTransaction>;
  /** insert data into the table: "account.transaction_status" */
  insert_account_transaction_status?: Maybe<AccountTransactionStatusMutationResponse>;
  /** insert a single row into the table: "account.transaction_status" */
  insert_account_transaction_status_one?: Maybe<AccountTransactionStatus>;
  /** insert data into the table: "account.transaction_type" */
  insert_account_transaction_type?: Maybe<AccountTransactionTypeMutationResponse>;
  /** insert a single row into the table: "account.transaction_type" */
  insert_account_transaction_type_one?: Maybe<AccountTransactionType>;
  /** insert data into the table: "attachment" */
  insert_attachment?: Maybe<AttachmentMutationResponse>;
  /** insert a single row into the table: "attachment" */
  insert_attachment_one?: Maybe<Attachment>;
  /** insert data into the table: "audit.logged_actions" */
  insert_audit_logged_actions?: Maybe<AuditLoggedActionsMutationResponse>;
  /** insert a single row into the table: "audit.logged_actions" */
  insert_audit_logged_actions_one?: Maybe<AuditLoggedActions>;
  /** insert data into the table: "auth.account_providers" */
  insert_auth_account_providers?: Maybe<AuthAccountProvidersMutationResponse>;
  /** insert a single row into the table: "auth.account_providers" */
  insert_auth_account_providers_one?: Maybe<AuthAccountProviders>;
  /** insert data into the table: "auth.account_roles" */
  insert_auth_account_roles?: Maybe<AuthAccountRolesMutationResponse>;
  /** insert a single row into the table: "auth.account_roles" */
  insert_auth_account_roles_one?: Maybe<AuthAccountRoles>;
  /** insert data into the table: "auth.accounts" */
  insert_auth_accounts?: Maybe<AuthAccountsMutationResponse>;
  /** insert a single row into the table: "auth.accounts" */
  insert_auth_accounts_one?: Maybe<AuthAccounts>;
  /** insert data into the table: "auth.providers" */
  insert_auth_providers?: Maybe<AuthProvidersMutationResponse>;
  /** insert a single row into the table: "auth.providers" */
  insert_auth_providers_one?: Maybe<AuthProviders>;
  /** insert data into the table: "auth.refresh_tokens" */
  insert_auth_refresh_tokens?: Maybe<AuthRefreshTokensMutationResponse>;
  /** insert a single row into the table: "auth.refresh_tokens" */
  insert_auth_refresh_tokens_one?: Maybe<AuthRefreshTokens>;
  /** insert data into the table: "auth.roles" */
  insert_auth_roles?: Maybe<AuthRolesMutationResponse>;
  /** insert a single row into the table: "auth.roles" */
  insert_auth_roles_one?: Maybe<AuthRoles>;
  /** insert data into the table: "bank_branch" */
  insert_bank_branch?: Maybe<BankBranchMutationResponse>;
  /** insert a single row into the table: "bank_branch" */
  insert_bank_branch_one?: Maybe<BankBranch>;
  /** insert data into the table: "contract.af_status" */
  insert_contract_af_status?: Maybe<ContractAfStatusMutationResponse>;
  /** insert a single row into the table: "contract.af_status" */
  insert_contract_af_status_one?: Maybe<ContractAfStatus>;
  /** insert data into the table: "contract.agreement" */
  insert_contract_agreement?: Maybe<ContractAgreementMutationResponse>;
  /** insert data into the table: "contract.agreement_attachment" */
  insert_contract_agreement_attachment?: Maybe<ContractAgreementAttachmentMutationResponse>;
  /** insert a single row into the table: "contract.agreement_attachment" */
  insert_contract_agreement_attachment_one?: Maybe<ContractAgreementAttachment>;
  /** insert data into the table: "contract.agreement_detail" */
  insert_contract_agreement_detail?: Maybe<ContractAgreementDetailMutationResponse>;
  /** insert a single row into the table: "contract.agreement_detail" */
  insert_contract_agreement_detail_one?: Maybe<ContractAgreementDetail>;
  /** insert data into the table: "contract.agreement_disbursement" */
  insert_contract_agreement_disbursement?: Maybe<ContractAgreementDisbursementMutationResponse>;
  /** insert a single row into the table: "contract.agreement_disbursement" */
  insert_contract_agreement_disbursement_one?: Maybe<ContractAgreementDisbursement>;
  /** insert data into the table: "contract.agreement_fee_charge" */
  insert_contract_agreement_fee_charge?: Maybe<ContractAgreementFeeChargeMutationResponse>;
  /** insert a single row into the table: "contract.agreement_fee_charge" */
  insert_contract_agreement_fee_charge_one?: Maybe<ContractAgreementFeeCharge>;
  /** insert data into the table: "contract.agreement_guarantor" */
  insert_contract_agreement_guarantor?: Maybe<ContractAgreementGuarantorMutationResponse>;
  /** insert a single row into the table: "contract.agreement_guarantor" */
  insert_contract_agreement_guarantor_one?: Maybe<ContractAgreementGuarantor>;
  /** insert a single row into the table: "contract.agreement" */
  insert_contract_agreement_one?: Maybe<ContractAgreement>;
  /** insert data into the table: "contract.agreement_receipt" */
  insert_contract_agreement_receipt?: Maybe<ContractAgreementReceiptMutationResponse>;
  /** insert a single row into the table: "contract.agreement_receipt" */
  insert_contract_agreement_receipt_one?: Maybe<ContractAgreementReceipt>;
  /** insert data into the table: "contract.agreement_status" */
  insert_contract_agreement_status?: Maybe<ContractAgreementStatusMutationResponse>;
  /** insert data into the table: "contract.agreement_status_mapper" */
  insert_contract_agreement_status_mapper?: Maybe<ContractAgreementStatusMapperMutationResponse>;
  /** insert a single row into the table: "contract.agreement_status_mapper" */
  insert_contract_agreement_status_mapper_one?: Maybe<ContractAgreementStatusMapper>;
  /** insert a single row into the table: "contract.agreement_status" */
  insert_contract_agreement_status_one?: Maybe<ContractAgreementStatus>;
  /** insert data into the table: "contract.agreement_transaction" */
  insert_contract_agreement_transaction?: Maybe<ContractAgreementTransactionMutationResponse>;
  /** insert a single row into the table: "contract.agreement_transaction" */
  insert_contract_agreement_transaction_one?: Maybe<ContractAgreementTransaction>;
  /** insert data into the table: "contract.agreement_withdraw" */
  insert_contract_agreement_withdraw?: Maybe<ContractAgreementWithdrawMutationResponse>;
  /** insert a single row into the table: "contract.agreement_withdraw" */
  insert_contract_agreement_withdraw_one?: Maybe<ContractAgreementWithdraw>;
  /** insert data into the table: "contract.attachment_type" */
  insert_contract_attachment_type?: Maybe<ContractAttachmentTypeMutationResponse>;
  /** insert a single row into the table: "contract.attachment_type" */
  insert_contract_attachment_type_one?: Maybe<ContractAttachmentType>;
  /** insert data into the table: "contract.grade" */
  insert_contract_grade?: Maybe<ContractGradeMutationResponse>;
  /** insert a single row into the table: "contract.grade" */
  insert_contract_grade_one?: Maybe<ContractGrade>;
  /** insert data into the table: "contract.transaction_creator_type" */
  insert_contract_transaction_creator_type?: Maybe<ContractTransactionCreatorTypeMutationResponse>;
  /** insert a single row into the table: "contract.transaction_creator_type" */
  insert_contract_transaction_creator_type_one?: Maybe<ContractTransactionCreatorType>;
  /** insert data into the table: "current_user_permissions" */
  insert_current_user_permissions?: Maybe<CurrentUserPermissionsMutationResponse>;
  /** insert a single row into the table: "current_user_permissions" */
  insert_current_user_permissions_one?: Maybe<CurrentUserPermissions>;
  /** insert data into the table: "department" */
  insert_department?: Maybe<DepartmentMutationResponse>;
  /** insert a single row into the table: "department" */
  insert_department_one?: Maybe<Department>;
  /** insert data into the table: "feature_toggle" */
  insert_feature_toggle?: Maybe<FeatureToggleMutationResponse>;
  /** insert a single row into the table: "feature_toggle" */
  insert_feature_toggle_one?: Maybe<FeatureToggle>;
  /** insert data into the table: "fiscal_year" */
  insert_fiscal_year?: Maybe<FiscalYearMutationResponse>;
  /** insert a single row into the table: "fiscal_year" */
  insert_fiscal_year_one?: Maybe<FiscalYear>;
  /** insert data into the table: "import_log" */
  insert_import_log?: Maybe<ImportLogMutationResponse>;
  /** insert a single row into the table: "import_log" */
  insert_import_log_one?: Maybe<ImportLog>;
  /** insert data into the table: "juristic" */
  insert_juristic?: Maybe<JuristicMutationResponse>;
  /** insert data into the table: "juristic_address" */
  insert_juristic_address?: Maybe<JuristicAddressMutationResponse>;
  /** insert a single row into the table: "juristic_address" */
  insert_juristic_address_one?: Maybe<JuristicAddress>;
  /** insert data into the table: "juristic_committee" */
  insert_juristic_committee?: Maybe<JuristicCommitteeMutationResponse>;
  /** insert a single row into the table: "juristic_committee" */
  insert_juristic_committee_one?: Maybe<JuristicCommittee>;
  /** insert a single row into the table: "juristic" */
  insert_juristic_one?: Maybe<Juristic>;
  /** insert data into the table: "juristic_standard_objective" */
  insert_juristic_standard_objective?: Maybe<JuristicStandardObjectiveMutationResponse>;
  /** insert a single row into the table: "juristic_standard_objective" */
  insert_juristic_standard_objective_one?: Maybe<JuristicStandardObjective>;
  /** insert data into the table: "ledger.budget_allocation" */
  insert_ledger_budget_allocation?: Maybe<LedgerBudgetAllocationMutationResponse>;
  /** insert a single row into the table: "ledger.budget_allocation" */
  insert_ledger_budget_allocation_one?: Maybe<LedgerBudgetAllocation>;
  /** insert data into the table: "ledger.ledger" */
  insert_ledger_ledger?: Maybe<LedgerLedgerMutationResponse>;
  /** insert data into the table: "ledger.ledger_attachment" */
  insert_ledger_ledger_attachment?: Maybe<LedgerLedgerAttachmentMutationResponse>;
  /** insert a single row into the table: "ledger.ledger_attachment" */
  insert_ledger_ledger_attachment_one?: Maybe<LedgerLedgerAttachment>;
  /** insert data into the table: "ledger.ledger_log" */
  insert_ledger_ledger_log?: Maybe<LedgerLedgerLogMutationResponse>;
  /** insert a single row into the table: "ledger.ledger_log" */
  insert_ledger_ledger_log_one?: Maybe<LedgerLedgerLog>;
  /** insert a single row into the table: "ledger.ledger" */
  insert_ledger_ledger_one?: Maybe<LedgerLedger>;
  /** insert data into the table: "ledger.ledger_status" */
  insert_ledger_ledger_status?: Maybe<LedgerLedgerStatusMutationResponse>;
  /** insert a single row into the table: "ledger.ledger_status" */
  insert_ledger_ledger_status_one?: Maybe<LedgerLedgerStatus>;
  /** insert data into the table: "ledger.ledger_type" */
  insert_ledger_ledger_type?: Maybe<LedgerLedgerTypeMutationResponse>;
  /** insert a single row into the table: "ledger.ledger_type" */
  insert_ledger_ledger_type_one?: Maybe<LedgerLedgerType>;
  /** insert data into the table: "ledger.receivable" */
  insert_ledger_receivable?: Maybe<LedgerReceivableMutationResponse>;
  /** insert data into the table: "ledger.receivable_item" */
  insert_ledger_receivable_item?: Maybe<LedgerReceivableItemMutationResponse>;
  /** insert a single row into the table: "ledger.receivable_item" */
  insert_ledger_receivable_item_one?: Maybe<LedgerReceivableItem>;
  /** insert data into the table: "ledger.receivable_log" */
  insert_ledger_receivable_log?: Maybe<LedgerReceivableLogMutationResponse>;
  /** insert a single row into the table: "ledger.receivable_log" */
  insert_ledger_receivable_log_one?: Maybe<LedgerReceivableLog>;
  /** insert a single row into the table: "ledger.receivable" */
  insert_ledger_receivable_one?: Maybe<LedgerReceivable>;
  /** insert data into the table: "ledger.receivable_status" */
  insert_ledger_receivable_status?: Maybe<LedgerReceivableStatusMutationResponse>;
  /** insert a single row into the table: "ledger.receivable_status" */
  insert_ledger_receivable_status_one?: Maybe<LedgerReceivableStatus>;
  /** insert data into the table: "loan.account_type" */
  insert_loan_account_type?: Maybe<LoanAccountTypeMutationResponse>;
  /** insert a single row into the table: "loan.account_type" */
  insert_loan_account_type_one?: Maybe<LoanAccountType>;
  /** insert data into the table: "loan.application_attachment" */
  insert_loan_application_attachment?: Maybe<LoanApplicationAttachmentMutationResponse>;
  /** insert a single row into the table: "loan.application_attachment" */
  insert_loan_application_attachment_one?: Maybe<LoanApplicationAttachment>;
  /** insert data into the table: "loan.application_bank_branch_transfer" */
  insert_loan_application_bank_branch_transfer?: Maybe<LoanApplicationBankBranchTransferMutationResponse>;
  /** insert a single row into the table: "loan.application_bank_branch_transfer" */
  insert_loan_application_bank_branch_transfer_one?: Maybe<LoanApplicationBankBranchTransfer>;
  /** insert data into the table: "loan.application_business_operation" */
  insert_loan_application_business_operation?: Maybe<LoanApplicationBusinessOperationMutationResponse>;
  /** insert a single row into the table: "loan.application_business_operation" */
  insert_loan_application_business_operation_one?: Maybe<LoanApplicationBusinessOperation>;
  /** insert data into the table: "loan.application_committee" */
  insert_loan_application_committee?: Maybe<LoanApplicationCommitteeMutationResponse>;
  /** insert a single row into the table: "loan.application_committee" */
  insert_loan_application_committee_one?: Maybe<LoanApplicationCommittee>;
  /** insert data into the table: "loan.application_current_loan" */
  insert_loan_application_current_loan?: Maybe<LoanApplicationCurrentLoanMutationResponse>;
  /** insert a single row into the table: "loan.application_current_loan" */
  insert_loan_application_current_loan_one?: Maybe<LoanApplicationCurrentLoan>;
  /** insert data into the table: "loan.application_detail" */
  insert_loan_application_detail?: Maybe<LoanApplicationDetailMutationResponse>;
  /** insert a single row into the table: "loan.application_detail" */
  insert_loan_application_detail_one?: Maybe<LoanApplicationDetail>;
  /** insert data into the table: "loan.application_event_log" */
  insert_loan_application_event_log?: Maybe<LoanApplicationEventLogMutationResponse>;
  /** insert a single row into the table: "loan.application_event_log" */
  insert_loan_application_event_log_one?: Maybe<LoanApplicationEventLog>;
  /** insert data into the table: "loan.application_header" */
  insert_loan_application_header?: Maybe<LoanApplicationHeaderMutationResponse>;
  /** insert a single row into the table: "loan.application_header" */
  insert_loan_application_header_one?: Maybe<LoanApplicationHeader>;
  /** insert data into the table: "loan.application_header_pdf" */
  insert_loan_application_header_pdf?: Maybe<LoanApplicationHeaderPdfMutationResponse>;
  /** insert a single row into the table: "loan.application_header_pdf" */
  insert_loan_application_header_pdf_one?: Maybe<LoanApplicationHeaderPdf>;
  /** insert data into the table: "loan.application_objective" */
  insert_loan_application_objective?: Maybe<LoanApplicationObjectiveMutationResponse>;
  /** insert a single row into the table: "loan.application_objective" */
  insert_loan_application_objective_one?: Maybe<LoanApplicationObjective>;
  /** insert data into the table: "loan.application_organization_transfer" */
  insert_loan_application_organization_transfer?: Maybe<LoanApplicationOrganizationTransferMutationResponse>;
  /** insert a single row into the table: "loan.application_organization_transfer" */
  insert_loan_application_organization_transfer_one?: Maybe<LoanApplicationOrganizationTransfer>;
  /** insert data into the table: "loan.application_profile" */
  insert_loan_application_profile?: Maybe<LoanApplicationProfileMutationResponse>;
  /** insert a single row into the table: "loan.application_profile" */
  insert_loan_application_profile_one?: Maybe<LoanApplicationProfile>;
  /** insert data into the table: "loan.application_profile_s_curve" */
  insert_loan_application_profile_s_curve?: Maybe<LoanApplicationProfileSCurveMutationResponse>;
  /** insert a single row into the table: "loan.application_profile_s_curve" */
  insert_loan_application_profile_s_curve_one?: Maybe<LoanApplicationProfileSCurve>;
  /** insert data into the table: "loan.application_sequence_no" */
  insert_loan_application_sequence_no?: Maybe<LoanApplicationSequenceNoMutationResponse>;
  /** insert a single row into the table: "loan.application_sequence_no" */
  insert_loan_application_sequence_no_one?: Maybe<LoanApplicationSequenceNo>;
  /** insert data into the table: "loan.application_step" */
  insert_loan_application_step?: Maybe<LoanApplicationStepMutationResponse>;
  /** insert a single row into the table: "loan.application_step" */
  insert_loan_application_step_one?: Maybe<LoanApplicationStep>;
  /** insert data into the table: "loan.attachment_type" */
  insert_loan_attachment_type?: Maybe<LoanAttachmentTypeMutationResponse>;
  /** insert a single row into the table: "loan.attachment_type" */
  insert_loan_attachment_type_one?: Maybe<LoanAttachmentType>;
  /** insert data into the table: "loan.bank_approve" */
  insert_loan_bank_approve?: Maybe<LoanBankApproveMutationResponse>;
  /** insert a single row into the table: "loan.bank_approve" */
  insert_loan_bank_approve_one?: Maybe<LoanBankApprove>;
  /** insert data into the table: "loan.business_group" */
  insert_loan_business_group?: Maybe<LoanBusinessGroupMutationResponse>;
  /** insert a single row into the table: "loan.business_group" */
  insert_loan_business_group_one?: Maybe<LoanBusinessGroup>;
  /** insert data into the table: "loan.business_operation" */
  insert_loan_business_operation?: Maybe<LoanBusinessOperationMutationResponse>;
  /** insert a single row into the table: "loan.business_operation" */
  insert_loan_business_operation_one?: Maybe<LoanBusinessOperation>;
  /** insert data into the table: "loan.business_proprietary" */
  insert_loan_business_proprietary?: Maybe<LoanBusinessProprietaryMutationResponse>;
  /** insert a single row into the table: "loan.business_proprietary" */
  insert_loan_business_proprietary_one?: Maybe<LoanBusinessProprietary>;
  /** insert data into the table: "loan.business_status" */
  insert_loan_business_status?: Maybe<LoanBusinessStatusMutationResponse>;
  /** insert a single row into the table: "loan.business_status" */
  insert_loan_business_status_one?: Maybe<LoanBusinessStatus>;
  /** insert data into the table: "loan.business_type" */
  insert_loan_business_type?: Maybe<LoanBusinessTypeMutationResponse>;
  /** insert a single row into the table: "loan.business_type" */
  insert_loan_business_type_one?: Maybe<LoanBusinessType>;
  /** insert data into the table: "loan.committee_approve" */
  insert_loan_committee_approve?: Maybe<LoanCommitteeApproveMutationResponse>;
  /** insert a single row into the table: "loan.committee_approve" */
  insert_loan_committee_approve_one?: Maybe<LoanCommitteeApprove>;
  /** insert data into the table: "loan.current_loan" */
  insert_loan_current_loan?: Maybe<LoanCurrentLoanMutationResponse>;
  /** insert a single row into the table: "loan.current_loan" */
  insert_loan_current_loan_one?: Maybe<LoanCurrentLoan>;
  /** insert data into the table: "loan.debt_restructuring" */
  insert_loan_debt_restructuring?: Maybe<LoanDebtRestructuringMutationResponse>;
  /** insert a single row into the table: "loan.debt_restructuring" */
  insert_loan_debt_restructuring_one?: Maybe<LoanDebtRestructuring>;
  /** insert data into the table: "loan.document_approve" */
  insert_loan_document_approve?: Maybe<LoanDocumentApproveMutationResponse>;
  /** insert a single row into the table: "loan.document_approve" */
  insert_loan_document_approve_one?: Maybe<LoanDocumentApprove>;
  /** insert data into the table: "loan.financial_agreement" */
  insert_loan_financial_agreement?: Maybe<LoanFinancialAgreementMutationResponse>;
  /** insert a single row into the table: "loan.financial_agreement" */
  insert_loan_financial_agreement_one?: Maybe<LoanFinancialAgreement>;
  /** insert data into the table: "loan.financial_rescue" */
  insert_loan_financial_rescue?: Maybe<LoanFinancialRescueMutationResponse>;
  /** insert a single row into the table: "loan.financial_rescue" */
  insert_loan_financial_rescue_one?: Maybe<LoanFinancialRescue>;
  /** insert data into the table: "loan.objective" */
  insert_loan_objective?: Maybe<LoanObjectiveMutationResponse>;
  /** insert a single row into the table: "loan.objective" */
  insert_loan_objective_one?: Maybe<LoanObjective>;
  /** insert data into the table: "loan.program" */
  insert_loan_program?: Maybe<LoanProgramMutationResponse>;
  /** insert a single row into the table: "loan.program" */
  insert_loan_program_one?: Maybe<LoanProgram>;
  /** insert data into the table: "loan.program_status" */
  insert_loan_program_status?: Maybe<LoanProgramStatusMutationResponse>;
  /** insert a single row into the table: "loan.program_status" */
  insert_loan_program_status_one?: Maybe<LoanProgramStatus>;
  /** insert data into the table: "loan.program_transaction" */
  insert_loan_program_transaction?: Maybe<LoanProgramTransactionMutationResponse>;
  /** insert a single row into the table: "loan.program_transaction" */
  insert_loan_program_transaction_one?: Maybe<LoanProgramTransaction>;
  /** insert data into the table: "loan.program_transaction_type" */
  insert_loan_program_transaction_type?: Maybe<LoanProgramTransactionTypeMutationResponse>;
  /** insert a single row into the table: "loan.program_transaction_type" */
  insert_loan_program_transaction_type_one?: Maybe<LoanProgramTransactionType>;
  /** insert data into the table: "loan.proprietary" */
  insert_loan_proprietary?: Maybe<LoanProprietaryMutationResponse>;
  /** insert a single row into the table: "loan.proprietary" */
  insert_loan_proprietary_one?: Maybe<LoanProprietary>;
  /** insert data into the table: "loan.repay_type" */
  insert_loan_repay_type?: Maybe<LoanRepayTypeMutationResponse>;
  /** insert a single row into the table: "loan.repay_type" */
  insert_loan_repay_type_one?: Maybe<LoanRepayType>;
  /** insert data into the table: "loan.s_curve" */
  insert_loan_s_curve?: Maybe<LoanSCurveMutationResponse>;
  /** insert a single row into the table: "loan.s_curve" */
  insert_loan_s_curve_one?: Maybe<LoanSCurve>;
  /** insert data into the table: "loan.s_curve_type" */
  insert_loan_s_curve_type?: Maybe<LoanSCurveTypeMutationResponse>;
  /** insert a single row into the table: "loan.s_curve_type" */
  insert_loan_s_curve_type_one?: Maybe<LoanSCurveType>;
  /** insert data into the table: "loan.sme_definition" */
  insert_loan_sme_definition?: Maybe<LoanSmeDefinitionMutationResponse>;
  /** insert a single row into the table: "loan.sme_definition" */
  insert_loan_sme_definition_one?: Maybe<LoanSmeDefinition>;
  /** insert data into the table: "loan_source.business_type" */
  insert_loan_source_business_type?: Maybe<LoanSourceBusinessTypeMutationResponse>;
  /** insert a single row into the table: "loan_source.business_type" */
  insert_loan_source_business_type_one?: Maybe<LoanSourceBusinessType>;
  /** insert data into the table: "loan_source.financial_institution" */
  insert_loan_source_financial_institution?: Maybe<LoanSourceFinancialInstitutionMutationResponse>;
  /** insert a single row into the table: "loan_source.financial_institution" */
  insert_loan_source_financial_institution_one?: Maybe<LoanSourceFinancialInstitution>;
  /** insert data into the table: "loan_source.loan" */
  insert_loan_source_loan?: Maybe<LoanSourceLoanMutationResponse>;
  /** insert data into the table: "loan_source.loan_attachment" */
  insert_loan_source_loan_attachment?: Maybe<LoanSourceLoanAttachmentMutationResponse>;
  /** insert a single row into the table: "loan_source.loan_attachment" */
  insert_loan_source_loan_attachment_one?: Maybe<LoanSourceLoanAttachment>;
  /** insert data into the table: "loan_source.loan_business_type" */
  insert_loan_source_loan_business_type?: Maybe<LoanSourceLoanBusinessTypeMutationResponse>;
  /** insert a single row into the table: "loan_source.loan_business_type" */
  insert_loan_source_loan_business_type_one?: Maybe<LoanSourceLoanBusinessType>;
  /** insert a single row into the table: "loan_source.loan" */
  insert_loan_source_loan_one?: Maybe<LoanSourceLoan>;
  /** insert data into the table: "loan_source.objective" */
  insert_loan_source_objective?: Maybe<LoanSourceObjectiveMutationResponse>;
  /** insert a single row into the table: "loan_source.objective" */
  insert_loan_source_objective_one?: Maybe<LoanSourceObjective>;
  /** insert data into the table: "loan_source.search_log" */
  insert_loan_source_search_log?: Maybe<LoanSourceSearchLogMutationResponse>;
  /** insert a single row into the table: "loan_source.search_log" */
  insert_loan_source_search_log_one?: Maybe<LoanSourceSearchLog>;
  /** insert data into the table: "loan.transfer_status" */
  insert_loan_transfer_status?: Maybe<LoanTransferStatusMutationResponse>;
  /** insert a single row into the table: "loan.transfer_status" */
  insert_loan_transfer_status_one?: Maybe<LoanTransferStatus>;
  /** insert data into the table: "me" */
  insert_me?: Maybe<MeMutationResponse>;
  /** insert a single row into the table: "me" */
  insert_me_one?: Maybe<Me>;
  /** insert data into the table: "name_title" */
  insert_name_title?: Maybe<NameTitleMutationResponse>;
  /** insert a single row into the table: "name_title" */
  insert_name_title_one?: Maybe<NameTitle>;
  /** insert data into the table: "notification" */
  insert_notification?: Maybe<NotificationMutationResponse>;
  /** insert a single row into the table: "notification" */
  insert_notification_one?: Maybe<Notification>;
  /** insert data into the table: "notification_status" */
  insert_notification_status?: Maybe<NotificationStatusMutationResponse>;
  /** insert a single row into the table: "notification_status" */
  insert_notification_status_one?: Maybe<NotificationStatus>;
  /** insert data into the table: "organization" */
  insert_organization?: Maybe<OrganizationMutationResponse>;
  /** insert a single row into the table: "organization" */
  insert_organization_one?: Maybe<Organization>;
  /** insert data into the table: "organization_type" */
  insert_organization_type?: Maybe<OrganizationTypeMutationResponse>;
  /** insert a single row into the table: "organization_type" */
  insert_organization_type_one?: Maybe<OrganizationType>;
  /** insert data into the table: "permission" */
  insert_permission?: Maybe<PermissionMutationResponse>;
  /** insert a single row into the table: "permission" */
  insert_permission_one?: Maybe<Permission>;
  /** insert data into the table: "posts" */
  insert_posts?: Maybe<PostsMutationResponse>;
  /** insert a single row into the table: "posts" */
  insert_posts_one?: Maybe<Posts>;
  /** insert data into the table: "project.approved" */
  insert_project_approved?: Maybe<ProjectApprovedMutationResponse>;
  /** insert a single row into the table: "project.approved" */
  insert_project_approved_one?: Maybe<ProjectApproved>;
  /** insert data into the table: "project.attachment_type" */
  insert_project_attachment_type?: Maybe<ProjectAttachmentTypeMutationResponse>;
  /** insert a single row into the table: "project.attachment_type" */
  insert_project_attachment_type_one?: Maybe<ProjectAttachmentType>;
  /** insert data into the table: "project.budget" */
  insert_project_budget?: Maybe<ProjectBudgetMutationResponse>;
  /** insert data into the table: "project.budget_group" */
  insert_project_budget_group?: Maybe<ProjectBudgetGroupMutationResponse>;
  /** insert data into the table: "project.budget_group_mapping" */
  insert_project_budget_group_mapping?: Maybe<ProjectBudgetGroupMappingMutationResponse>;
  /** insert a single row into the table: "project.budget_group_mapping" */
  insert_project_budget_group_mapping_one?: Maybe<ProjectBudgetGroupMapping>;
  /** insert a single row into the table: "project.budget_group" */
  insert_project_budget_group_one?: Maybe<ProjectBudgetGroup>;
  /** insert a single row into the table: "project.budget" */
  insert_project_budget_one?: Maybe<ProjectBudget>;
  /** insert data into the table: "project.indicator_type" */
  insert_project_indicator_type?: Maybe<ProjectIndicatorTypeMutationResponse>;
  /** insert a single row into the table: "project.indicator_type" */
  insert_project_indicator_type_one?: Maybe<ProjectIndicatorType>;
  /** insert data into the table: "project.master_plan" */
  insert_project_master_plan?: Maybe<ProjectMasterPlanMutationResponse>;
  /** insert a single row into the table: "project.master_plan" */
  insert_project_master_plan_one?: Maybe<ProjectMasterPlan>;
  /** insert data into the table: "project.national_strategy" */
  insert_project_national_strategy?: Maybe<ProjectNationalStrategyMutationResponse>;
  /** insert a single row into the table: "project.national_strategy" */
  insert_project_national_strategy_one?: Maybe<ProjectNationalStrategy>;
  /** insert data into the table: "project.operating_activity_progress" */
  insert_project_operating_activity_progress?: Maybe<ProjectOperatingActivityProgressMutationResponse>;
  /** insert a single row into the table: "project.operating_activity_progress" */
  insert_project_operating_activity_progress_one?: Maybe<ProjectOperatingActivityProgress>;
  /** insert data into the table: "project.operating_activity_progress_revision" */
  insert_project_operating_activity_progress_revision?: Maybe<ProjectOperatingActivityProgressRevisionMutationResponse>;
  /** insert a single row into the table: "project.operating_activity_progress_revision" */
  insert_project_operating_activity_progress_revision_one?: Maybe<ProjectOperatingActivityProgressRevision>;
  /** insert data into the table: "project.operating_activity_type" */
  insert_project_operating_activity_type?: Maybe<ProjectOperatingActivityTypeMutationResponse>;
  /** insert a single row into the table: "project.operating_activity_type" */
  insert_project_operating_activity_type_one?: Maybe<ProjectOperatingActivityType>;
  /** insert data into the table: "project.project" */
  insert_project_project?: Maybe<ProjectProjectMutationResponse>;
  /** insert data into the table: "project.project_achievement" */
  insert_project_project_achievement?: Maybe<ProjectProjectAchievementMutationResponse>;
  /** insert a single row into the table: "project.project_achievement" */
  insert_project_project_achievement_one?: Maybe<ProjectProjectAchievement>;
  /** insert data into the table: "project.project_attachment" */
  insert_project_project_attachment?: Maybe<ProjectProjectAttachmentMutationResponse>;
  /** insert a single row into the table: "project.project_attachment" */
  insert_project_project_attachment_one?: Maybe<ProjectProjectAttachment>;
  /** insert data into the table: "project.project_budget_item" */
  insert_project_project_budget_item?: Maybe<ProjectProjectBudgetItemMutationResponse>;
  /** insert a single row into the table: "project.project_budget_item" */
  insert_project_project_budget_item_one?: Maybe<ProjectProjectBudgetItem>;
  /** insert data into the table: "project.project_event_log" */
  insert_project_project_event_log?: Maybe<ProjectProjectEventLogMutationResponse>;
  /** insert a single row into the table: "project.project_event_log" */
  insert_project_project_event_log_one?: Maybe<ProjectProjectEventLog>;
  /** insert data into the table: "project.project_indicator" */
  insert_project_project_indicator?: Maybe<ProjectProjectIndicatorMutationResponse>;
  /** insert a single row into the table: "project.project_indicator" */
  insert_project_project_indicator_one?: Maybe<ProjectProjectIndicator>;
  /** insert data into the table: "project.project_member" */
  insert_project_project_member?: Maybe<ProjectProjectMemberMutationResponse>;
  /** insert a single row into the table: "project.project_member" */
  insert_project_project_member_one?: Maybe<ProjectProjectMember>;
  /** insert data into the table: "project.project_objective" */
  insert_project_project_objective?: Maybe<ProjectProjectObjectiveMutationResponse>;
  /** insert a single row into the table: "project.project_objective" */
  insert_project_project_objective_one?: Maybe<ProjectProjectObjective>;
  /** insert a single row into the table: "project.project" */
  insert_project_project_one?: Maybe<ProjectProject>;
  /** insert data into the table: "project.project_operating_activity" */
  insert_project_project_operating_activity?: Maybe<ProjectProjectOperatingActivityMutationResponse>;
  /** insert a single row into the table: "project.project_operating_activity" */
  insert_project_project_operating_activity_one?: Maybe<ProjectProjectOperatingActivity>;
  /** insert data into the table: "project.project_operating_area" */
  insert_project_project_operating_area?: Maybe<ProjectProjectOperatingAreaMutationResponse>;
  /** insert a single row into the table: "project.project_operating_area" */
  insert_project_project_operating_area_one?: Maybe<ProjectProjectOperatingArea>;
  /** insert data into the table: "project.project_step" */
  insert_project_project_step?: Maybe<ProjectProjectStepMutationResponse>;
  /** insert a single row into the table: "project.project_step" */
  insert_project_project_step_one?: Maybe<ProjectProjectStep>;
  /** insert data into the table: "project.project_target_group" */
  insert_project_project_target_group?: Maybe<ProjectProjectTargetGroupMutationResponse>;
  /** insert a single row into the table: "project.project_target_group" */
  insert_project_project_target_group_one?: Maybe<ProjectProjectTargetGroup>;
  /** insert data into the table: "project.sub_plan" */
  insert_project_sub_plan?: Maybe<ProjectSubPlanMutationResponse>;
  /** insert a single row into the table: "project.sub_plan" */
  insert_project_sub_plan_one?: Maybe<ProjectSubPlan>;
  /** insert data into the table: "project.target_group" */
  insert_project_target_group?: Maybe<ProjectTargetGroupMutationResponse>;
  /** insert a single row into the table: "project.target_group" */
  insert_project_target_group_one?: Maybe<ProjectTargetGroup>;
  /** insert data into the table: "project.unit" */
  insert_project_unit?: Maybe<ProjectUnitMutationResponse>;
  /** insert a single row into the table: "project.unit" */
  insert_project_unit_one?: Maybe<ProjectUnit>;
  /** insert data into the table: "province" */
  insert_province?: Maybe<ProvinceMutationResponse>;
  /** insert a single row into the table: "province" */
  insert_province_one?: Maybe<Province>;
  /** insert data into the table: "region" */
  insert_region?: Maybe<RegionMutationResponse>;
  /** insert a single row into the table: "region" */
  insert_region_one?: Maybe<Region>;
  /** insert data into the table: "user_mapping" */
  insert_user_mapping?: Maybe<UserMappingMutationResponse>;
  /** insert a single row into the table: "user_mapping" */
  insert_user_mapping_one?: Maybe<UserMapping>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<UsersMutationResponse>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "users_permission" */
  insert_users_permission?: Maybe<UsersPermissionMutationResponse>;
  /** insert a single row into the table: "users_permission" */
  insert_users_permission_one?: Maybe<UsersPermission>;
  /** พิจารณาสถานะค่าใช้จ่าย */
  ledger_approve_ledger: LedgerApproveOutput;
  /** ยืนยันข้อมูลทะเบียนคุมลูกหนี้เงินยืม */
  ledger_close_receivable: ReceivableCloseOutput;
  /** ลบข้อมูลทะเบียนคุมลูกหนี้เงินยืม */
  ledger_delete_receivable: ReceivableDeleteOutput;
  /** ยืนยันข้อมูลค่าใช้จ่าย */
  ledger_submit_ledger: LedgerSubmitOutput;
  /** ยืนยันข้อมูลทะเบียนคุมลูกหนี้เงินยืม */
  ledger_submit_receivable: ReceivableSubmitOutput;
  project_project_approve: ProjectApprovalOutput;
  project_project_submit: ProjectSubmitOutput;
  submit_loan_application?: Maybe<SubmitLoanApplicationOutput>;
  transfer_loan_application_bank_branch?: Maybe<TransferLoanApplicationBankBranchOutput>;
  /** update data of the table: "account.account" */
  update_account_account?: Maybe<AccountAccountMutationResponse>;
  /** update single row of the table: "account.account" */
  update_account_account_by_pk?: Maybe<AccountAccount>;
  /** update data of the table: "account.account_category" */
  update_account_account_category?: Maybe<AccountAccountCategoryMutationResponse>;
  /** update single row of the table: "account.account_category" */
  update_account_account_category_by_pk?: Maybe<AccountAccountCategory>;
  /** update data of the table: "account.account_type" */
  update_account_account_type?: Maybe<AccountAccountTypeMutationResponse>;
  /** update single row of the table: "account.account_type" */
  update_account_account_type_by_pk?: Maybe<AccountAccountType>;
  /** update data of the table: "account.budget_account" */
  update_account_budget_account?: Maybe<AccountBudgetAccountMutationResponse>;
  /** update single row of the table: "account.budget_account" */
  update_account_budget_account_by_pk?: Maybe<AccountBudgetAccount>;
  /** update data of the table: "account.budget_allocation" */
  update_account_budget_allocation?: Maybe<AccountBudgetAllocationMutationResponse>;
  /** update single row of the table: "account.budget_allocation" */
  update_account_budget_allocation_by_pk?: Maybe<AccountBudgetAllocation>;
  /** update data of the table: "account.budget_allocation_log" */
  update_account_budget_allocation_log?: Maybe<AccountBudgetAllocationLogMutationResponse>;
  /** update single row of the table: "account.budget_allocation_log" */
  update_account_budget_allocation_log_by_pk?: Maybe<AccountBudgetAllocationLog>;
  /** update data of the table: "account.budget_type" */
  update_account_budget_type?: Maybe<AccountBudgetTypeMutationResponse>;
  /** update single row of the table: "account.budget_type" */
  update_account_budget_type_by_pk?: Maybe<AccountBudgetType>;
  /** update data of the table: "account.entry_type" */
  update_account_entry_type?: Maybe<AccountEntryTypeMutationResponse>;
  /** update single row of the table: "account.entry_type" */
  update_account_entry_type_by_pk?: Maybe<AccountEntryType>;
  /** update data of the table: "account.ledger_entry" */
  update_account_ledger_entry?: Maybe<AccountLedgerEntryMutationResponse>;
  /** update single row of the table: "account.ledger_entry" */
  update_account_ledger_entry_by_pk?: Maybe<AccountLedgerEntry>;
  /** update data of the table: "account.lending" */
  update_account_lending?: Maybe<AccountLendingMutationResponse>;
  /** update single row of the table: "account.lending" */
  update_account_lending_by_pk?: Maybe<AccountLending>;
  /** update data of the table: "account.lending_item" */
  update_account_lending_item?: Maybe<AccountLendingItemMutationResponse>;
  /** update single row of the table: "account.lending_item" */
  update_account_lending_item_by_pk?: Maybe<AccountLendingItem>;
  /** update data of the table: "account.lending_status" */
  update_account_lending_status?: Maybe<AccountLendingStatusMutationResponse>;
  /** update single row of the table: "account.lending_status" */
  update_account_lending_status_by_pk?: Maybe<AccountLendingStatus>;
  /** update data of the table: "account.transaction" */
  update_account_transaction?: Maybe<AccountTransactionMutationResponse>;
  /** update single row of the table: "account.transaction" */
  update_account_transaction_by_pk?: Maybe<AccountTransaction>;
  /** update data of the table: "account.transaction_log" */
  update_account_transaction_log?: Maybe<AccountTransactionLogMutationResponse>;
  /** update single row of the table: "account.transaction_log" */
  update_account_transaction_log_by_pk?: Maybe<AccountTransactionLog>;
  /** update data of the table: "account.transaction_status" */
  update_account_transaction_status?: Maybe<AccountTransactionStatusMutationResponse>;
  /** update single row of the table: "account.transaction_status" */
  update_account_transaction_status_by_pk?: Maybe<AccountTransactionStatus>;
  /** update data of the table: "account.transaction_type" */
  update_account_transaction_type?: Maybe<AccountTransactionTypeMutationResponse>;
  /** update single row of the table: "account.transaction_type" */
  update_account_transaction_type_by_pk?: Maybe<AccountTransactionType>;
  /** update data of the table: "attachment" */
  update_attachment?: Maybe<AttachmentMutationResponse>;
  /** update single row of the table: "attachment" */
  update_attachment_by_pk?: Maybe<Attachment>;
  /** update data of the table: "audit.logged_actions" */
  update_audit_logged_actions?: Maybe<AuditLoggedActionsMutationResponse>;
  /** update single row of the table: "audit.logged_actions" */
  update_audit_logged_actions_by_pk?: Maybe<AuditLoggedActions>;
  /** update data of the table: "auth.account_providers" */
  update_auth_account_providers?: Maybe<AuthAccountProvidersMutationResponse>;
  /** update single row of the table: "auth.account_providers" */
  update_auth_account_providers_by_pk?: Maybe<AuthAccountProviders>;
  /** update data of the table: "auth.account_roles" */
  update_auth_account_roles?: Maybe<AuthAccountRolesMutationResponse>;
  /** update single row of the table: "auth.account_roles" */
  update_auth_account_roles_by_pk?: Maybe<AuthAccountRoles>;
  /** update data of the table: "auth.accounts" */
  update_auth_accounts?: Maybe<AuthAccountsMutationResponse>;
  /** update single row of the table: "auth.accounts" */
  update_auth_accounts_by_pk?: Maybe<AuthAccounts>;
  /** update data of the table: "auth.providers" */
  update_auth_providers?: Maybe<AuthProvidersMutationResponse>;
  /** update single row of the table: "auth.providers" */
  update_auth_providers_by_pk?: Maybe<AuthProviders>;
  /** update data of the table: "auth.refresh_tokens" */
  update_auth_refresh_tokens?: Maybe<AuthRefreshTokensMutationResponse>;
  /** update single row of the table: "auth.refresh_tokens" */
  update_auth_refresh_tokens_by_pk?: Maybe<AuthRefreshTokens>;
  /** update data of the table: "auth.roles" */
  update_auth_roles?: Maybe<AuthRolesMutationResponse>;
  /** update single row of the table: "auth.roles" */
  update_auth_roles_by_pk?: Maybe<AuthRoles>;
  /** update data of the table: "bank_branch" */
  update_bank_branch?: Maybe<BankBranchMutationResponse>;
  /** update single row of the table: "bank_branch" */
  update_bank_branch_by_pk?: Maybe<BankBranch>;
  /** update data of the table: "contract.af_status" */
  update_contract_af_status?: Maybe<ContractAfStatusMutationResponse>;
  /** update single row of the table: "contract.af_status" */
  update_contract_af_status_by_pk?: Maybe<ContractAfStatus>;
  /** update data of the table: "contract.agreement" */
  update_contract_agreement?: Maybe<ContractAgreementMutationResponse>;
  /** update data of the table: "contract.agreement_attachment" */
  update_contract_agreement_attachment?: Maybe<ContractAgreementAttachmentMutationResponse>;
  /** update single row of the table: "contract.agreement_attachment" */
  update_contract_agreement_attachment_by_pk?: Maybe<ContractAgreementAttachment>;
  /** update single row of the table: "contract.agreement" */
  update_contract_agreement_by_pk?: Maybe<ContractAgreement>;
  /** update data of the table: "contract.agreement_detail" */
  update_contract_agreement_detail?: Maybe<ContractAgreementDetailMutationResponse>;
  /** update single row of the table: "contract.agreement_detail" */
  update_contract_agreement_detail_by_pk?: Maybe<ContractAgreementDetail>;
  /** update data of the table: "contract.agreement_disbursement" */
  update_contract_agreement_disbursement?: Maybe<ContractAgreementDisbursementMutationResponse>;
  /** update single row of the table: "contract.agreement_disbursement" */
  update_contract_agreement_disbursement_by_pk?: Maybe<ContractAgreementDisbursement>;
  /** update data of the table: "contract.agreement_fee_charge" */
  update_contract_agreement_fee_charge?: Maybe<ContractAgreementFeeChargeMutationResponse>;
  /** update single row of the table: "contract.agreement_fee_charge" */
  update_contract_agreement_fee_charge_by_pk?: Maybe<ContractAgreementFeeCharge>;
  /** update data of the table: "contract.agreement_guarantor" */
  update_contract_agreement_guarantor?: Maybe<ContractAgreementGuarantorMutationResponse>;
  /** update single row of the table: "contract.agreement_guarantor" */
  update_contract_agreement_guarantor_by_pk?: Maybe<ContractAgreementGuarantor>;
  /** update data of the table: "contract.agreement_receipt" */
  update_contract_agreement_receipt?: Maybe<ContractAgreementReceiptMutationResponse>;
  /** update single row of the table: "contract.agreement_receipt" */
  update_contract_agreement_receipt_by_pk?: Maybe<ContractAgreementReceipt>;
  /** update data of the table: "contract.agreement_status" */
  update_contract_agreement_status?: Maybe<ContractAgreementStatusMutationResponse>;
  /** update single row of the table: "contract.agreement_status" */
  update_contract_agreement_status_by_pk?: Maybe<ContractAgreementStatus>;
  /** update data of the table: "contract.agreement_status_mapper" */
  update_contract_agreement_status_mapper?: Maybe<ContractAgreementStatusMapperMutationResponse>;
  /** update single row of the table: "contract.agreement_status_mapper" */
  update_contract_agreement_status_mapper_by_pk?: Maybe<ContractAgreementStatusMapper>;
  /** update data of the table: "contract.agreement_transaction" */
  update_contract_agreement_transaction?: Maybe<ContractAgreementTransactionMutationResponse>;
  /** update single row of the table: "contract.agreement_transaction" */
  update_contract_agreement_transaction_by_pk?: Maybe<ContractAgreementTransaction>;
  /** update data of the table: "contract.agreement_withdraw" */
  update_contract_agreement_withdraw?: Maybe<ContractAgreementWithdrawMutationResponse>;
  /** update single row of the table: "contract.agreement_withdraw" */
  update_contract_agreement_withdraw_by_pk?: Maybe<ContractAgreementWithdraw>;
  /** update data of the table: "contract.attachment_type" */
  update_contract_attachment_type?: Maybe<ContractAttachmentTypeMutationResponse>;
  /** update single row of the table: "contract.attachment_type" */
  update_contract_attachment_type_by_pk?: Maybe<ContractAttachmentType>;
  /** update data of the table: "contract.grade" */
  update_contract_grade?: Maybe<ContractGradeMutationResponse>;
  /** update single row of the table: "contract.grade" */
  update_contract_grade_by_pk?: Maybe<ContractGrade>;
  /** update data of the table: "contract.transaction_creator_type" */
  update_contract_transaction_creator_type?: Maybe<ContractTransactionCreatorTypeMutationResponse>;
  /** update single row of the table: "contract.transaction_creator_type" */
  update_contract_transaction_creator_type_by_pk?: Maybe<ContractTransactionCreatorType>;
  /** update data of the table: "current_user_permissions" */
  update_current_user_permissions?: Maybe<CurrentUserPermissionsMutationResponse>;
  /** update data of the table: "department" */
  update_department?: Maybe<DepartmentMutationResponse>;
  /** update single row of the table: "department" */
  update_department_by_pk?: Maybe<Department>;
  /** update data of the table: "feature_toggle" */
  update_feature_toggle?: Maybe<FeatureToggleMutationResponse>;
  /** update single row of the table: "feature_toggle" */
  update_feature_toggle_by_pk?: Maybe<FeatureToggle>;
  /** update data of the table: "fiscal_year" */
  update_fiscal_year?: Maybe<FiscalYearMutationResponse>;
  /** update single row of the table: "fiscal_year" */
  update_fiscal_year_by_pk?: Maybe<FiscalYear>;
  /** update data of the table: "import_log" */
  update_import_log?: Maybe<ImportLogMutationResponse>;
  /** update single row of the table: "import_log" */
  update_import_log_by_pk?: Maybe<ImportLog>;
  /** update data of the table: "juristic" */
  update_juristic?: Maybe<JuristicMutationResponse>;
  /** update data of the table: "juristic_address" */
  update_juristic_address?: Maybe<JuristicAddressMutationResponse>;
  /** update single row of the table: "juristic_address" */
  update_juristic_address_by_pk?: Maybe<JuristicAddress>;
  /** update single row of the table: "juristic" */
  update_juristic_by_pk?: Maybe<Juristic>;
  /** update data of the table: "juristic_committee" */
  update_juristic_committee?: Maybe<JuristicCommitteeMutationResponse>;
  /** update single row of the table: "juristic_committee" */
  update_juristic_committee_by_pk?: Maybe<JuristicCommittee>;
  /** update data of the table: "juristic_standard_objective" */
  update_juristic_standard_objective?: Maybe<JuristicStandardObjectiveMutationResponse>;
  /** update single row of the table: "juristic_standard_objective" */
  update_juristic_standard_objective_by_pk?: Maybe<JuristicStandardObjective>;
  /** update data of the table: "ledger.budget_allocation" */
  update_ledger_budget_allocation?: Maybe<LedgerBudgetAllocationMutationResponse>;
  /** update single row of the table: "ledger.budget_allocation" */
  update_ledger_budget_allocation_by_pk?: Maybe<LedgerBudgetAllocation>;
  /** update data of the table: "ledger.ledger" */
  update_ledger_ledger?: Maybe<LedgerLedgerMutationResponse>;
  /** update data of the table: "ledger.ledger_attachment" */
  update_ledger_ledger_attachment?: Maybe<LedgerLedgerAttachmentMutationResponse>;
  /** update single row of the table: "ledger.ledger_attachment" */
  update_ledger_ledger_attachment_by_pk?: Maybe<LedgerLedgerAttachment>;
  /** update single row of the table: "ledger.ledger" */
  update_ledger_ledger_by_pk?: Maybe<LedgerLedger>;
  /** update data of the table: "ledger.ledger_log" */
  update_ledger_ledger_log?: Maybe<LedgerLedgerLogMutationResponse>;
  /** update single row of the table: "ledger.ledger_log" */
  update_ledger_ledger_log_by_pk?: Maybe<LedgerLedgerLog>;
  /** update data of the table: "ledger.ledger_status" */
  update_ledger_ledger_status?: Maybe<LedgerLedgerStatusMutationResponse>;
  /** update single row of the table: "ledger.ledger_status" */
  update_ledger_ledger_status_by_pk?: Maybe<LedgerLedgerStatus>;
  /** update data of the table: "ledger.ledger_type" */
  update_ledger_ledger_type?: Maybe<LedgerLedgerTypeMutationResponse>;
  /** update single row of the table: "ledger.ledger_type" */
  update_ledger_ledger_type_by_pk?: Maybe<LedgerLedgerType>;
  /** update data of the table: "ledger.receivable" */
  update_ledger_receivable?: Maybe<LedgerReceivableMutationResponse>;
  /** update single row of the table: "ledger.receivable" */
  update_ledger_receivable_by_pk?: Maybe<LedgerReceivable>;
  /** update data of the table: "ledger.receivable_item" */
  update_ledger_receivable_item?: Maybe<LedgerReceivableItemMutationResponse>;
  /** update single row of the table: "ledger.receivable_item" */
  update_ledger_receivable_item_by_pk?: Maybe<LedgerReceivableItem>;
  /** update data of the table: "ledger.receivable_log" */
  update_ledger_receivable_log?: Maybe<LedgerReceivableLogMutationResponse>;
  /** update single row of the table: "ledger.receivable_log" */
  update_ledger_receivable_log_by_pk?: Maybe<LedgerReceivableLog>;
  /** update data of the table: "ledger.receivable_status" */
  update_ledger_receivable_status?: Maybe<LedgerReceivableStatusMutationResponse>;
  /** update single row of the table: "ledger.receivable_status" */
  update_ledger_receivable_status_by_pk?: Maybe<LedgerReceivableStatus>;
  /** update data of the table: "loan.account_type" */
  update_loan_account_type?: Maybe<LoanAccountTypeMutationResponse>;
  /** update single row of the table: "loan.account_type" */
  update_loan_account_type_by_pk?: Maybe<LoanAccountType>;
  /** update data of the table: "loan.application_attachment" */
  update_loan_application_attachment?: Maybe<LoanApplicationAttachmentMutationResponse>;
  /** update single row of the table: "loan.application_attachment" */
  update_loan_application_attachment_by_pk?: Maybe<LoanApplicationAttachment>;
  /** update data of the table: "loan.application_bank_branch_transfer" */
  update_loan_application_bank_branch_transfer?: Maybe<LoanApplicationBankBranchTransferMutationResponse>;
  /** update single row of the table: "loan.application_bank_branch_transfer" */
  update_loan_application_bank_branch_transfer_by_pk?: Maybe<LoanApplicationBankBranchTransfer>;
  /** update data of the table: "loan.application_business_operation" */
  update_loan_application_business_operation?: Maybe<LoanApplicationBusinessOperationMutationResponse>;
  /** update single row of the table: "loan.application_business_operation" */
  update_loan_application_business_operation_by_pk?: Maybe<LoanApplicationBusinessOperation>;
  /** update data of the table: "loan.application_committee" */
  update_loan_application_committee?: Maybe<LoanApplicationCommitteeMutationResponse>;
  /** update single row of the table: "loan.application_committee" */
  update_loan_application_committee_by_pk?: Maybe<LoanApplicationCommittee>;
  /** update data of the table: "loan.application_current_loan" */
  update_loan_application_current_loan?: Maybe<LoanApplicationCurrentLoanMutationResponse>;
  /** update single row of the table: "loan.application_current_loan" */
  update_loan_application_current_loan_by_pk?: Maybe<LoanApplicationCurrentLoan>;
  /** update data of the table: "loan.application_detail" */
  update_loan_application_detail?: Maybe<LoanApplicationDetailMutationResponse>;
  /** update single row of the table: "loan.application_detail" */
  update_loan_application_detail_by_pk?: Maybe<LoanApplicationDetail>;
  /** update data of the table: "loan.application_event_log" */
  update_loan_application_event_log?: Maybe<LoanApplicationEventLogMutationResponse>;
  /** update single row of the table: "loan.application_event_log" */
  update_loan_application_event_log_by_pk?: Maybe<LoanApplicationEventLog>;
  /** update data of the table: "loan.application_header" */
  update_loan_application_header?: Maybe<LoanApplicationHeaderMutationResponse>;
  /** update single row of the table: "loan.application_header" */
  update_loan_application_header_by_pk?: Maybe<LoanApplicationHeader>;
  /** update data of the table: "loan.application_header_pdf" */
  update_loan_application_header_pdf?: Maybe<LoanApplicationHeaderPdfMutationResponse>;
  /** update single row of the table: "loan.application_header_pdf" */
  update_loan_application_header_pdf_by_pk?: Maybe<LoanApplicationHeaderPdf>;
  /** update data of the table: "loan.application_objective" */
  update_loan_application_objective?: Maybe<LoanApplicationObjectiveMutationResponse>;
  /** update single row of the table: "loan.application_objective" */
  update_loan_application_objective_by_pk?: Maybe<LoanApplicationObjective>;
  /** update data of the table: "loan.application_organization_transfer" */
  update_loan_application_organization_transfer?: Maybe<LoanApplicationOrganizationTransferMutationResponse>;
  /** update single row of the table: "loan.application_organization_transfer" */
  update_loan_application_organization_transfer_by_pk?: Maybe<LoanApplicationOrganizationTransfer>;
  /** update data of the table: "loan.application_profile" */
  update_loan_application_profile?: Maybe<LoanApplicationProfileMutationResponse>;
  /** update single row of the table: "loan.application_profile" */
  update_loan_application_profile_by_pk?: Maybe<LoanApplicationProfile>;
  /** update data of the table: "loan.application_profile_s_curve" */
  update_loan_application_profile_s_curve?: Maybe<LoanApplicationProfileSCurveMutationResponse>;
  /** update single row of the table: "loan.application_profile_s_curve" */
  update_loan_application_profile_s_curve_by_pk?: Maybe<LoanApplicationProfileSCurve>;
  /** update data of the table: "loan.application_sequence_no" */
  update_loan_application_sequence_no?: Maybe<LoanApplicationSequenceNoMutationResponse>;
  /** update single row of the table: "loan.application_sequence_no" */
  update_loan_application_sequence_no_by_pk?: Maybe<LoanApplicationSequenceNo>;
  /** update data of the table: "loan.application_step" */
  update_loan_application_step?: Maybe<LoanApplicationStepMutationResponse>;
  /** update single row of the table: "loan.application_step" */
  update_loan_application_step_by_pk?: Maybe<LoanApplicationStep>;
  /** update data of the table: "loan.attachment_type" */
  update_loan_attachment_type?: Maybe<LoanAttachmentTypeMutationResponse>;
  /** update single row of the table: "loan.attachment_type" */
  update_loan_attachment_type_by_pk?: Maybe<LoanAttachmentType>;
  /** update data of the table: "loan.bank_approve" */
  update_loan_bank_approve?: Maybe<LoanBankApproveMutationResponse>;
  /** update single row of the table: "loan.bank_approve" */
  update_loan_bank_approve_by_pk?: Maybe<LoanBankApprove>;
  /** update data of the table: "loan.business_group" */
  update_loan_business_group?: Maybe<LoanBusinessGroupMutationResponse>;
  /** update single row of the table: "loan.business_group" */
  update_loan_business_group_by_pk?: Maybe<LoanBusinessGroup>;
  /** update data of the table: "loan.business_operation" */
  update_loan_business_operation?: Maybe<LoanBusinessOperationMutationResponse>;
  /** update single row of the table: "loan.business_operation" */
  update_loan_business_operation_by_pk?: Maybe<LoanBusinessOperation>;
  /** update data of the table: "loan.business_proprietary" */
  update_loan_business_proprietary?: Maybe<LoanBusinessProprietaryMutationResponse>;
  /** update single row of the table: "loan.business_proprietary" */
  update_loan_business_proprietary_by_pk?: Maybe<LoanBusinessProprietary>;
  /** update data of the table: "loan.business_status" */
  update_loan_business_status?: Maybe<LoanBusinessStatusMutationResponse>;
  /** update single row of the table: "loan.business_status" */
  update_loan_business_status_by_pk?: Maybe<LoanBusinessStatus>;
  /** update data of the table: "loan.business_type" */
  update_loan_business_type?: Maybe<LoanBusinessTypeMutationResponse>;
  /** update single row of the table: "loan.business_type" */
  update_loan_business_type_by_pk?: Maybe<LoanBusinessType>;
  /** update data of the table: "loan.committee_approve" */
  update_loan_committee_approve?: Maybe<LoanCommitteeApproveMutationResponse>;
  /** update single row of the table: "loan.committee_approve" */
  update_loan_committee_approve_by_pk?: Maybe<LoanCommitteeApprove>;
  /** update data of the table: "loan.current_loan" */
  update_loan_current_loan?: Maybe<LoanCurrentLoanMutationResponse>;
  /** update single row of the table: "loan.current_loan" */
  update_loan_current_loan_by_pk?: Maybe<LoanCurrentLoan>;
  /** update data of the table: "loan.debt_restructuring" */
  update_loan_debt_restructuring?: Maybe<LoanDebtRestructuringMutationResponse>;
  /** update single row of the table: "loan.debt_restructuring" */
  update_loan_debt_restructuring_by_pk?: Maybe<LoanDebtRestructuring>;
  /** update data of the table: "loan.document_approve" */
  update_loan_document_approve?: Maybe<LoanDocumentApproveMutationResponse>;
  /** update single row of the table: "loan.document_approve" */
  update_loan_document_approve_by_pk?: Maybe<LoanDocumentApprove>;
  /** update data of the table: "loan.financial_agreement" */
  update_loan_financial_agreement?: Maybe<LoanFinancialAgreementMutationResponse>;
  /** update single row of the table: "loan.financial_agreement" */
  update_loan_financial_agreement_by_pk?: Maybe<LoanFinancialAgreement>;
  /** update data of the table: "loan.financial_rescue" */
  update_loan_financial_rescue?: Maybe<LoanFinancialRescueMutationResponse>;
  /** update single row of the table: "loan.financial_rescue" */
  update_loan_financial_rescue_by_pk?: Maybe<LoanFinancialRescue>;
  /** update data of the table: "loan.objective" */
  update_loan_objective?: Maybe<LoanObjectiveMutationResponse>;
  /** update single row of the table: "loan.objective" */
  update_loan_objective_by_pk?: Maybe<LoanObjective>;
  /** update data of the table: "loan.program" */
  update_loan_program?: Maybe<LoanProgramMutationResponse>;
  /** update single row of the table: "loan.program" */
  update_loan_program_by_pk?: Maybe<LoanProgram>;
  /** update data of the table: "loan.program_status" */
  update_loan_program_status?: Maybe<LoanProgramStatusMutationResponse>;
  /** update single row of the table: "loan.program_status" */
  update_loan_program_status_by_pk?: Maybe<LoanProgramStatus>;
  /** update data of the table: "loan.program_transaction" */
  update_loan_program_transaction?: Maybe<LoanProgramTransactionMutationResponse>;
  /** update single row of the table: "loan.program_transaction" */
  update_loan_program_transaction_by_pk?: Maybe<LoanProgramTransaction>;
  /** update data of the table: "loan.program_transaction_type" */
  update_loan_program_transaction_type?: Maybe<LoanProgramTransactionTypeMutationResponse>;
  /** update single row of the table: "loan.program_transaction_type" */
  update_loan_program_transaction_type_by_pk?: Maybe<LoanProgramTransactionType>;
  /** update data of the table: "loan.proprietary" */
  update_loan_proprietary?: Maybe<LoanProprietaryMutationResponse>;
  /** update single row of the table: "loan.proprietary" */
  update_loan_proprietary_by_pk?: Maybe<LoanProprietary>;
  /** update data of the table: "loan.repay_type" */
  update_loan_repay_type?: Maybe<LoanRepayTypeMutationResponse>;
  /** update single row of the table: "loan.repay_type" */
  update_loan_repay_type_by_pk?: Maybe<LoanRepayType>;
  /** update data of the table: "loan.s_curve" */
  update_loan_s_curve?: Maybe<LoanSCurveMutationResponse>;
  /** update single row of the table: "loan.s_curve" */
  update_loan_s_curve_by_pk?: Maybe<LoanSCurve>;
  /** update data of the table: "loan.s_curve_type" */
  update_loan_s_curve_type?: Maybe<LoanSCurveTypeMutationResponse>;
  /** update single row of the table: "loan.s_curve_type" */
  update_loan_s_curve_type_by_pk?: Maybe<LoanSCurveType>;
  /** update data of the table: "loan.sme_definition" */
  update_loan_sme_definition?: Maybe<LoanSmeDefinitionMutationResponse>;
  /** update single row of the table: "loan.sme_definition" */
  update_loan_sme_definition_by_pk?: Maybe<LoanSmeDefinition>;
  /** update data of the table: "loan_source.business_type" */
  update_loan_source_business_type?: Maybe<LoanSourceBusinessTypeMutationResponse>;
  /** update single row of the table: "loan_source.business_type" */
  update_loan_source_business_type_by_pk?: Maybe<LoanSourceBusinessType>;
  /** update data of the table: "loan_source.financial_institution" */
  update_loan_source_financial_institution?: Maybe<LoanSourceFinancialInstitutionMutationResponse>;
  /** update single row of the table: "loan_source.financial_institution" */
  update_loan_source_financial_institution_by_pk?: Maybe<LoanSourceFinancialInstitution>;
  /** update data of the table: "loan_source.loan" */
  update_loan_source_loan?: Maybe<LoanSourceLoanMutationResponse>;
  /** update data of the table: "loan_source.loan_attachment" */
  update_loan_source_loan_attachment?: Maybe<LoanSourceLoanAttachmentMutationResponse>;
  /** update single row of the table: "loan_source.loan_attachment" */
  update_loan_source_loan_attachment_by_pk?: Maybe<LoanSourceLoanAttachment>;
  /** update data of the table: "loan_source.loan_business_type" */
  update_loan_source_loan_business_type?: Maybe<LoanSourceLoanBusinessTypeMutationResponse>;
  /** update single row of the table: "loan_source.loan_business_type" */
  update_loan_source_loan_business_type_by_pk?: Maybe<LoanSourceLoanBusinessType>;
  /** update single row of the table: "loan_source.loan" */
  update_loan_source_loan_by_pk?: Maybe<LoanSourceLoan>;
  /** update data of the table: "loan_source.objective" */
  update_loan_source_objective?: Maybe<LoanSourceObjectiveMutationResponse>;
  /** update single row of the table: "loan_source.objective" */
  update_loan_source_objective_by_pk?: Maybe<LoanSourceObjective>;
  /** update data of the table: "loan_source.search_log" */
  update_loan_source_search_log?: Maybe<LoanSourceSearchLogMutationResponse>;
  /** update single row of the table: "loan_source.search_log" */
  update_loan_source_search_log_by_pk?: Maybe<LoanSourceSearchLog>;
  /** update data of the table: "loan.transfer_status" */
  update_loan_transfer_status?: Maybe<LoanTransferStatusMutationResponse>;
  /** update single row of the table: "loan.transfer_status" */
  update_loan_transfer_status_by_pk?: Maybe<LoanTransferStatus>;
  /** update data of the table: "me" */
  update_me?: Maybe<MeMutationResponse>;
  /** update data of the table: "name_title" */
  update_name_title?: Maybe<NameTitleMutationResponse>;
  /** update single row of the table: "name_title" */
  update_name_title_by_pk?: Maybe<NameTitle>;
  /** update data of the table: "notification" */
  update_notification?: Maybe<NotificationMutationResponse>;
  /** update single row of the table: "notification" */
  update_notification_by_pk?: Maybe<Notification>;
  /** update data of the table: "notification_status" */
  update_notification_status?: Maybe<NotificationStatusMutationResponse>;
  /** update single row of the table: "notification_status" */
  update_notification_status_by_pk?: Maybe<NotificationStatus>;
  /** update data of the table: "organization" */
  update_organization?: Maybe<OrganizationMutationResponse>;
  /** update single row of the table: "organization" */
  update_organization_by_pk?: Maybe<Organization>;
  /** update data of the table: "organization_type" */
  update_organization_type?: Maybe<OrganizationTypeMutationResponse>;
  /** update single row of the table: "organization_type" */
  update_organization_type_by_pk?: Maybe<OrganizationType>;
  /** update data of the table: "permission" */
  update_permission?: Maybe<PermissionMutationResponse>;
  /** update single row of the table: "permission" */
  update_permission_by_pk?: Maybe<Permission>;
  /** update data of the table: "posts" */
  update_posts?: Maybe<PostsMutationResponse>;
  /** update single row of the table: "posts" */
  update_posts_by_pk?: Maybe<Posts>;
  /** update data of the table: "project.approved" */
  update_project_approved?: Maybe<ProjectApprovedMutationResponse>;
  /** update single row of the table: "project.approved" */
  update_project_approved_by_pk?: Maybe<ProjectApproved>;
  /** update data of the table: "project.attachment_type" */
  update_project_attachment_type?: Maybe<ProjectAttachmentTypeMutationResponse>;
  /** update single row of the table: "project.attachment_type" */
  update_project_attachment_type_by_pk?: Maybe<ProjectAttachmentType>;
  /** update data of the table: "project.budget" */
  update_project_budget?: Maybe<ProjectBudgetMutationResponse>;
  /** update single row of the table: "project.budget" */
  update_project_budget_by_pk?: Maybe<ProjectBudget>;
  /** update data of the table: "project.budget_group" */
  update_project_budget_group?: Maybe<ProjectBudgetGroupMutationResponse>;
  /** update single row of the table: "project.budget_group" */
  update_project_budget_group_by_pk?: Maybe<ProjectBudgetGroup>;
  /** update data of the table: "project.budget_group_mapping" */
  update_project_budget_group_mapping?: Maybe<ProjectBudgetGroupMappingMutationResponse>;
  /** update single row of the table: "project.budget_group_mapping" */
  update_project_budget_group_mapping_by_pk?: Maybe<ProjectBudgetGroupMapping>;
  /** update data of the table: "project.indicator_type" */
  update_project_indicator_type?: Maybe<ProjectIndicatorTypeMutationResponse>;
  /** update single row of the table: "project.indicator_type" */
  update_project_indicator_type_by_pk?: Maybe<ProjectIndicatorType>;
  /** update data of the table: "project.master_plan" */
  update_project_master_plan?: Maybe<ProjectMasterPlanMutationResponse>;
  /** update data of the table: "project.national_strategy" */
  update_project_national_strategy?: Maybe<ProjectNationalStrategyMutationResponse>;
  /** update single row of the table: "project.national_strategy" */
  update_project_national_strategy_by_pk?: Maybe<ProjectNationalStrategy>;
  /** update data of the table: "project.operating_activity_progress" */
  update_project_operating_activity_progress?: Maybe<ProjectOperatingActivityProgressMutationResponse>;
  /** update single row of the table: "project.operating_activity_progress" */
  update_project_operating_activity_progress_by_pk?: Maybe<ProjectOperatingActivityProgress>;
  /** update data of the table: "project.operating_activity_progress_revision" */
  update_project_operating_activity_progress_revision?: Maybe<ProjectOperatingActivityProgressRevisionMutationResponse>;
  /** update single row of the table: "project.operating_activity_progress_revision" */
  update_project_operating_activity_progress_revision_by_pk?: Maybe<ProjectOperatingActivityProgressRevision>;
  /** update data of the table: "project.operating_activity_type" */
  update_project_operating_activity_type?: Maybe<ProjectOperatingActivityTypeMutationResponse>;
  /** update single row of the table: "project.operating_activity_type" */
  update_project_operating_activity_type_by_pk?: Maybe<ProjectOperatingActivityType>;
  /** update data of the table: "project.project" */
  update_project_project?: Maybe<ProjectProjectMutationResponse>;
  /** update data of the table: "project.project_achievement" */
  update_project_project_achievement?: Maybe<ProjectProjectAchievementMutationResponse>;
  /** update single row of the table: "project.project_achievement" */
  update_project_project_achievement_by_pk?: Maybe<ProjectProjectAchievement>;
  /** update data of the table: "project.project_attachment" */
  update_project_project_attachment?: Maybe<ProjectProjectAttachmentMutationResponse>;
  /** update single row of the table: "project.project_attachment" */
  update_project_project_attachment_by_pk?: Maybe<ProjectProjectAttachment>;
  /** update data of the table: "project.project_budget_item" */
  update_project_project_budget_item?: Maybe<ProjectProjectBudgetItemMutationResponse>;
  /** update single row of the table: "project.project_budget_item" */
  update_project_project_budget_item_by_pk?: Maybe<ProjectProjectBudgetItem>;
  /** update single row of the table: "project.project" */
  update_project_project_by_pk?: Maybe<ProjectProject>;
  /** update data of the table: "project.project_event_log" */
  update_project_project_event_log?: Maybe<ProjectProjectEventLogMutationResponse>;
  /** update single row of the table: "project.project_event_log" */
  update_project_project_event_log_by_pk?: Maybe<ProjectProjectEventLog>;
  /** update data of the table: "project.project_indicator" */
  update_project_project_indicator?: Maybe<ProjectProjectIndicatorMutationResponse>;
  /** update single row of the table: "project.project_indicator" */
  update_project_project_indicator_by_pk?: Maybe<ProjectProjectIndicator>;
  /** update data of the table: "project.project_member" */
  update_project_project_member?: Maybe<ProjectProjectMemberMutationResponse>;
  /** update single row of the table: "project.project_member" */
  update_project_project_member_by_pk?: Maybe<ProjectProjectMember>;
  /** update data of the table: "project.project_objective" */
  update_project_project_objective?: Maybe<ProjectProjectObjectiveMutationResponse>;
  /** update single row of the table: "project.project_objective" */
  update_project_project_objective_by_pk?: Maybe<ProjectProjectObjective>;
  /** update data of the table: "project.project_operating_activity" */
  update_project_project_operating_activity?: Maybe<ProjectProjectOperatingActivityMutationResponse>;
  /** update single row of the table: "project.project_operating_activity" */
  update_project_project_operating_activity_by_pk?: Maybe<ProjectProjectOperatingActivity>;
  /** update data of the table: "project.project_operating_area" */
  update_project_project_operating_area?: Maybe<ProjectProjectOperatingAreaMutationResponse>;
  /** update single row of the table: "project.project_operating_area" */
  update_project_project_operating_area_by_pk?: Maybe<ProjectProjectOperatingArea>;
  /** update data of the table: "project.project_step" */
  update_project_project_step?: Maybe<ProjectProjectStepMutationResponse>;
  /** update single row of the table: "project.project_step" */
  update_project_project_step_by_pk?: Maybe<ProjectProjectStep>;
  /** update data of the table: "project.project_target_group" */
  update_project_project_target_group?: Maybe<ProjectProjectTargetGroupMutationResponse>;
  /** update single row of the table: "project.project_target_group" */
  update_project_project_target_group_by_pk?: Maybe<ProjectProjectTargetGroup>;
  /** update data of the table: "project.sub_plan" */
  update_project_sub_plan?: Maybe<ProjectSubPlanMutationResponse>;
  /** update data of the table: "project.target_group" */
  update_project_target_group?: Maybe<ProjectTargetGroupMutationResponse>;
  /** update single row of the table: "project.target_group" */
  update_project_target_group_by_pk?: Maybe<ProjectTargetGroup>;
  /** update data of the table: "project.unit" */
  update_project_unit?: Maybe<ProjectUnitMutationResponse>;
  /** update single row of the table: "project.unit" */
  update_project_unit_by_pk?: Maybe<ProjectUnit>;
  /** update data of the table: "province" */
  update_province?: Maybe<ProvinceMutationResponse>;
  /** update single row of the table: "province" */
  update_province_by_pk?: Maybe<Province>;
  /** update data of the table: "region" */
  update_region?: Maybe<RegionMutationResponse>;
  /** update single row of the table: "region" */
  update_region_by_pk?: Maybe<Region>;
  /** update data of the table: "user_mapping" */
  update_user_mapping?: Maybe<UserMappingMutationResponse>;
  /** update single row of the table: "user_mapping" */
  update_user_mapping_by_pk?: Maybe<UserMapping>;
  /** update data of the table: "users" */
  update_users?: Maybe<UsersMutationResponse>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update data of the table: "users_permission" */
  update_users_permission?: Maybe<UsersPermissionMutationResponse>;
  /** update single row of the table: "users_permission" */
  update_users_permission_by_pk?: Maybe<UsersPermission>;
  validate_loan_application?: Maybe<ValidateLoanApplicationOutput>;
};


/** mutation root */
export type MutationRootAccountBudgetDuplicateByFiscalYearArgs = {
  data: AccountBudgetDuplicateByFiscalYearInput;
};


/** mutation root */
export type MutationRootAccountTransactionApproveArgs = {
  data: AccountTransactionApprovalInput;
};


/** mutation root */
export type MutationRootApproveLoanApplicationArgs = {
  data: ApproveLoanApplicationInput;
};


/** mutation root */
export type MutationRootApproveLoanApplicationOrganizationTransferArgs = {
  data: ApproveLoanApplicationOrganizationTransferInput;
};


/** mutation root */
export type MutationRootAuthAccountChangePasswordArgs = {
  data: ChangePasswordInput;
};


/** mutation root */
export type MutationRootCreateDraftLoanApplicationArgs = {
  data: CreateDraftLoanApplicationInput;
};


/** mutation root */
export type MutationRootCreateDraftLoanApplicationStandaloneArgs = {
  data: CreateDraftLoanApplicationStandaloneInput;
};


/** mutation root */
export type MutationRootDeleteAccountAccountArgs = {
  where: AccountAccountBoolExp;
};


/** mutation root */
export type MutationRootDeleteAccountAccountByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAccountAccountCategoryArgs = {
  where: AccountAccountCategoryBoolExp;
};


/** mutation root */
export type MutationRootDeleteAccountAccountCategoryByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAccountAccountTypeArgs = {
  where: AccountAccountTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteAccountAccountTypeByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteAccountBudgetAccountArgs = {
  where: AccountBudgetAccountBoolExp;
};


/** mutation root */
export type MutationRootDeleteAccountBudgetAccountByPkArgs = {
  account_id: Scalars['uuid'];
  budget_id: Scalars['String'];
  fiscal_year: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteAccountBudgetAllocationArgs = {
  where: AccountBudgetAllocationBoolExp;
};


/** mutation root */
export type MutationRootDeleteAccountBudgetAllocationByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAccountBudgetAllocationLogArgs = {
  where: AccountBudgetAllocationLogBoolExp;
};


/** mutation root */
export type MutationRootDeleteAccountBudgetAllocationLogByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAccountBudgetTypeArgs = {
  where: AccountBudgetTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteAccountBudgetTypeByPkArgs = {
  fiscal_year: Scalars['Int'];
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteAccountEntryTypeArgs = {
  where: AccountEntryTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteAccountEntryTypeByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteAccountLedgerEntryArgs = {
  where: AccountLedgerEntryBoolExp;
};


/** mutation root */
export type MutationRootDeleteAccountLedgerEntryByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAccountLendingArgs = {
  where: AccountLendingBoolExp;
};


/** mutation root */
export type MutationRootDeleteAccountLendingByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAccountLendingItemArgs = {
  where: AccountLendingItemBoolExp;
};


/** mutation root */
export type MutationRootDeleteAccountLendingItemByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAccountLendingStatusArgs = {
  where: AccountLendingStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteAccountLendingStatusByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteAccountTransactionArgs = {
  where: AccountTransactionBoolExp;
};


/** mutation root */
export type MutationRootDeleteAccountTransactionByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAccountTransactionLogArgs = {
  where: AccountTransactionLogBoolExp;
};


/** mutation root */
export type MutationRootDeleteAccountTransactionLogByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAccountTransactionStatusArgs = {
  where: AccountTransactionStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteAccountTransactionStatusByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteAccountTransactionTypeArgs = {
  where: AccountTransactionTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteAccountTransactionTypeByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteAttachmentArgs = {
  where: AttachmentBoolExp;
};


/** mutation root */
export type MutationRootDeleteAttachmentByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAuditLoggedActionsArgs = {
  where: AuditLoggedActionsBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuditLoggedActionsByPkArgs = {
  event_id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteAuthAccountProvidersArgs = {
  where: AuthAccountProvidersBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthAccountProvidersByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAuthAccountRolesArgs = {
  where: AuthAccountRolesBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthAccountRolesByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAuthAccountsArgs = {
  where: AuthAccountsBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthAccountsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAuthProvidersArgs = {
  where: AuthProvidersBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthProvidersByPkArgs = {
  provider: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteAuthRefreshTokensArgs = {
  where: AuthRefreshTokensBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthRefreshTokensByPkArgs = {
  refresh_token: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteAuthRolesArgs = {
  where: AuthRolesBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthRolesByPkArgs = {
  role: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteBankBranchArgs = {
  where: BankBranchBoolExp;
};


/** mutation root */
export type MutationRootDeleteBankBranchByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteContractAfStatusArgs = {
  where: ContractAfStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteContractAfStatusByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteContractAgreementArgs = {
  where: ContractAgreementBoolExp;
};


/** mutation root */
export type MutationRootDeleteContractAgreementAttachmentArgs = {
  where: ContractAgreementAttachmentBoolExp;
};


/** mutation root */
export type MutationRootDeleteContractAgreementAttachmentByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteContractAgreementByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteContractAgreementDetailArgs = {
  where: ContractAgreementDetailBoolExp;
};


/** mutation root */
export type MutationRootDeleteContractAgreementDetailByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteContractAgreementDisbursementArgs = {
  where: ContractAgreementDisbursementBoolExp;
};


/** mutation root */
export type MutationRootDeleteContractAgreementDisbursementByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteContractAgreementFeeChargeArgs = {
  where: ContractAgreementFeeChargeBoolExp;
};


/** mutation root */
export type MutationRootDeleteContractAgreementFeeChargeByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteContractAgreementGuarantorArgs = {
  where: ContractAgreementGuarantorBoolExp;
};


/** mutation root */
export type MutationRootDeleteContractAgreementGuarantorByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteContractAgreementReceiptArgs = {
  where: ContractAgreementReceiptBoolExp;
};


/** mutation root */
export type MutationRootDeleteContractAgreementReceiptByPkArgs = {
  receipt_no: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteContractAgreementStatusArgs = {
  where: ContractAgreementStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteContractAgreementStatusByPkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteContractAgreementStatusMapperArgs = {
  where: ContractAgreementStatusMapperBoolExp;
};


/** mutation root */
export type MutationRootDeleteContractAgreementStatusMapperByPkArgs = {
  bank_status: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteContractAgreementTransactionArgs = {
  where: ContractAgreementTransactionBoolExp;
};


/** mutation root */
export type MutationRootDeleteContractAgreementTransactionByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteContractAgreementWithdrawArgs = {
  where: ContractAgreementWithdrawBoolExp;
};


/** mutation root */
export type MutationRootDeleteContractAgreementWithdrawByPkArgs = {
  draw_down_no: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteContractAttachmentTypeArgs = {
  where: ContractAttachmentTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteContractAttachmentTypeByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteContractGradeArgs = {
  where: ContractGradeBoolExp;
};


/** mutation root */
export type MutationRootDeleteContractGradeByPkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteContractTransactionCreatorTypeArgs = {
  where: ContractTransactionCreatorTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteContractTransactionCreatorTypeByPkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteCurrentUserPermissionsArgs = {
  where: CurrentUserPermissionsBoolExp;
};


/** mutation root */
export type MutationRootDeleteDepartmentArgs = {
  where: DepartmentBoolExp;
};


/** mutation root */
export type MutationRootDeleteDepartmentByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteFeatureToggleArgs = {
  where: FeatureToggleBoolExp;
};


/** mutation root */
export type MutationRootDeleteFeatureToggleByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteFiscalYearArgs = {
  where: FiscalYearBoolExp;
};


/** mutation root */
export type MutationRootDeleteFiscalYearByPkArgs = {
  year: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteImportLogArgs = {
  where: ImportLogBoolExp;
};


/** mutation root */
export type MutationRootDeleteImportLogByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteJuristicArgs = {
  where: JuristicBoolExp;
};


/** mutation root */
export type MutationRootDeleteJuristicAddressArgs = {
  where: JuristicAddressBoolExp;
};


/** mutation root */
export type MutationRootDeleteJuristicAddressByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteJuristicByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteJuristicCommitteeArgs = {
  where: JuristicCommitteeBoolExp;
};


/** mutation root */
export type MutationRootDeleteJuristicCommitteeByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteJuristicStandardObjectiveArgs = {
  where: JuristicStandardObjectiveBoolExp;
};


/** mutation root */
export type MutationRootDeleteJuristicStandardObjectiveByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLedgerBudgetAllocationArgs = {
  where: LedgerBudgetAllocationBoolExp;
};


/** mutation root */
export type MutationRootDeleteLedgerBudgetAllocationByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLedgerLedgerArgs = {
  where: LedgerLedgerBoolExp;
};


/** mutation root */
export type MutationRootDeleteLedgerLedgerAttachmentArgs = {
  where: LedgerLedgerAttachmentBoolExp;
};


/** mutation root */
export type MutationRootDeleteLedgerLedgerAttachmentByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLedgerLedgerByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLedgerLedgerLogArgs = {
  where: LedgerLedgerLogBoolExp;
};


/** mutation root */
export type MutationRootDeleteLedgerLedgerLogByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLedgerLedgerStatusArgs = {
  where: LedgerLedgerStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteLedgerLedgerStatusByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteLedgerLedgerTypeArgs = {
  where: LedgerLedgerTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteLedgerLedgerTypeByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteLedgerReceivableArgs = {
  where: LedgerReceivableBoolExp;
};


/** mutation root */
export type MutationRootDeleteLedgerReceivableByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLedgerReceivableItemArgs = {
  where: LedgerReceivableItemBoolExp;
};


/** mutation root */
export type MutationRootDeleteLedgerReceivableItemByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLedgerReceivableLogArgs = {
  where: LedgerReceivableLogBoolExp;
};


/** mutation root */
export type MutationRootDeleteLedgerReceivableLogByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLedgerReceivableStatusArgs = {
  where: LedgerReceivableStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteLedgerReceivableStatusByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteLoanAccountTypeArgs = {
  where: LoanAccountTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanAccountTypeByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteLoanApplicationAttachmentArgs = {
  where: LoanApplicationAttachmentBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanApplicationAttachmentByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanApplicationBankBranchTransferArgs = {
  where: LoanApplicationBankBranchTransferBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanApplicationBankBranchTransferByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanApplicationBusinessOperationArgs = {
  where: LoanApplicationBusinessOperationBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanApplicationBusinessOperationByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanApplicationCommitteeArgs = {
  where: LoanApplicationCommitteeBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanApplicationCommitteeByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanApplicationCurrentLoanArgs = {
  where: LoanApplicationCurrentLoanBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanApplicationCurrentLoanByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanApplicationDetailArgs = {
  where: LoanApplicationDetailBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanApplicationDetailByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanApplicationEventLogArgs = {
  where: LoanApplicationEventLogBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanApplicationEventLogByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanApplicationHeaderArgs = {
  where: LoanApplicationHeaderBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanApplicationHeaderByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanApplicationHeaderPdfArgs = {
  where: LoanApplicationHeaderPdfBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanApplicationHeaderPdfByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanApplicationObjectiveArgs = {
  where: LoanApplicationObjectiveBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanApplicationObjectiveByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanApplicationOrganizationTransferArgs = {
  where: LoanApplicationOrganizationTransferBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanApplicationOrganizationTransferByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanApplicationProfileArgs = {
  where: LoanApplicationProfileBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanApplicationProfileByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanApplicationProfileSCurveArgs = {
  where: LoanApplicationProfileSCurveBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanApplicationProfileSCurveByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanApplicationSequenceNoArgs = {
  where: LoanApplicationSequenceNoBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanApplicationSequenceNoByPkArgs = {
  organization_id: Scalars['uuid'];
  program_id: Scalars['uuid'];
  year: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteLoanApplicationStepArgs = {
  where: LoanApplicationStepBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanApplicationStepByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteLoanAttachmentTypeArgs = {
  where: LoanAttachmentTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanAttachmentTypeByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteLoanBankApproveArgs = {
  where: LoanBankApproveBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanBankApproveByPkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteLoanBusinessGroupArgs = {
  where: LoanBusinessGroupBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanBusinessGroupByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanBusinessOperationArgs = {
  where: LoanBusinessOperationBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanBusinessOperationByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteLoanBusinessProprietaryArgs = {
  where: LoanBusinessProprietaryBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanBusinessProprietaryByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanBusinessStatusArgs = {
  where: LoanBusinessStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanBusinessStatusByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanBusinessTypeArgs = {
  where: LoanBusinessTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanBusinessTypeByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteLoanCommitteeApproveArgs = {
  where: LoanCommitteeApproveBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanCommitteeApproveByPkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteLoanCurrentLoanArgs = {
  where: LoanCurrentLoanBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanCurrentLoanByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteLoanDebtRestructuringArgs = {
  where: LoanDebtRestructuringBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanDebtRestructuringByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteLoanDocumentApproveArgs = {
  where: LoanDocumentApproveBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanDocumentApproveByPkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteLoanFinancialAgreementArgs = {
  where: LoanFinancialAgreementBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanFinancialAgreementByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteLoanFinancialRescueArgs = {
  where: LoanFinancialRescueBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanFinancialRescueByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteLoanObjectiveArgs = {
  where: LoanObjectiveBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanObjectiveByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteLoanProgramArgs = {
  where: LoanProgramBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanProgramByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanProgramStatusArgs = {
  where: LoanProgramStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanProgramStatusByPkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteLoanProgramTransactionArgs = {
  where: LoanProgramTransactionBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanProgramTransactionByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanProgramTransactionTypeArgs = {
  where: LoanProgramTransactionTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanProgramTransactionTypeByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanProprietaryArgs = {
  where: LoanProprietaryBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanProprietaryByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteLoanRepayTypeArgs = {
  where: LoanRepayTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanRepayTypeByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteLoanSCurveArgs = {
  where: LoanSCurveBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanSCurveByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteLoanSCurveTypeArgs = {
  where: LoanSCurveTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanSCurveTypeByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanSmeDefinitionArgs = {
  where: LoanSmeDefinitionBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanSmeDefinitionByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanSourceBusinessTypeArgs = {
  where: LoanSourceBusinessTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanSourceBusinessTypeByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteLoanSourceFinancialInstitutionArgs = {
  where: LoanSourceFinancialInstitutionBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanSourceFinancialInstitutionByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteLoanSourceLoanArgs = {
  where: LoanSourceLoanBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanSourceLoanAttachmentArgs = {
  where: LoanSourceLoanAttachmentBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanSourceLoanAttachmentByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanSourceLoanBusinessTypeArgs = {
  where: LoanSourceLoanBusinessTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanSourceLoanBusinessTypeByPkArgs = {
  business_type_id: Scalars['String'];
  loan_id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanSourceLoanByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanSourceObjectiveArgs = {
  where: LoanSourceObjectiveBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanSourceObjectiveByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanSourceSearchLogArgs = {
  where: LoanSourceSearchLogBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanSourceSearchLogByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLoanTransferStatusArgs = {
  where: LoanTransferStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteLoanTransferStatusByPkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteMeArgs = {
  where: MeBoolExp;
};


/** mutation root */
export type MutationRootDeleteNameTitleArgs = {
  where: NameTitleBoolExp;
};


/** mutation root */
export type MutationRootDeleteNameTitleByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteNotificationArgs = {
  where: NotificationBoolExp;
};


/** mutation root */
export type MutationRootDeleteNotificationByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteNotificationStatusArgs = {
  where: NotificationStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteNotificationStatusByPkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteOrganizationArgs = {
  where: OrganizationBoolExp;
};


/** mutation root */
export type MutationRootDeleteOrganizationByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteOrganizationTypeArgs = {
  where: OrganizationTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteOrganizationTypeByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeletePermissionArgs = {
  where: PermissionBoolExp;
};


/** mutation root */
export type MutationRootDeletePermissionByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeletePostsArgs = {
  where: PostsBoolExp;
};


/** mutation root */
export type MutationRootDeletePostsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteProjectApprovedArgs = {
  where: ProjectApprovedBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectApprovedByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteProjectAttachmentTypeArgs = {
  where: ProjectAttachmentTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectAttachmentTypeByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteProjectBudgetArgs = {
  where: ProjectBudgetBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectBudgetByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteProjectBudgetGroupArgs = {
  where: ProjectBudgetGroupBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectBudgetGroupByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteProjectBudgetGroupMappingArgs = {
  where: ProjectBudgetGroupMappingBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectBudgetGroupMappingByPkArgs = {
  budget_group_id: ProjectBudgetGroupEnum;
  budget_id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteProjectIndicatorTypeArgs = {
  where: ProjectIndicatorTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectIndicatorTypeByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteProjectMasterPlanArgs = {
  where: ProjectMasterPlanBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectNationalStrategyArgs = {
  where: ProjectNationalStrategyBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectNationalStrategyByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteProjectOperatingActivityProgressArgs = {
  where: ProjectOperatingActivityProgressBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectOperatingActivityProgressByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteProjectOperatingActivityProgressRevisionArgs = {
  where: ProjectOperatingActivityProgressRevisionBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectOperatingActivityProgressRevisionByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteProjectOperatingActivityTypeArgs = {
  where: ProjectOperatingActivityTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectOperatingActivityTypeByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteProjectProjectArgs = {
  where: ProjectProjectBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectProjectAchievementArgs = {
  where: ProjectProjectAchievementBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectProjectAchievementByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteProjectProjectAttachmentArgs = {
  where: ProjectProjectAttachmentBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectProjectAttachmentByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteProjectProjectBudgetItemArgs = {
  where: ProjectProjectBudgetItemBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectProjectBudgetItemByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteProjectProjectByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteProjectProjectEventLogArgs = {
  where: ProjectProjectEventLogBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectProjectEventLogByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteProjectProjectIndicatorArgs = {
  where: ProjectProjectIndicatorBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectProjectIndicatorByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteProjectProjectMemberArgs = {
  where: ProjectProjectMemberBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectProjectMemberByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteProjectProjectObjectiveArgs = {
  where: ProjectProjectObjectiveBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectProjectObjectiveByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteProjectProjectOperatingActivityArgs = {
  where: ProjectProjectOperatingActivityBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectProjectOperatingActivityByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteProjectProjectOperatingAreaArgs = {
  where: ProjectProjectOperatingAreaBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectProjectOperatingAreaByPkArgs = {
  project_id: Scalars['uuid'];
  province_id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteProjectProjectStepArgs = {
  where: ProjectProjectStepBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectProjectStepByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteProjectProjectTargetGroupArgs = {
  where: ProjectProjectTargetGroupBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectProjectTargetGroupByPkArgs = {
  project_id: Scalars['uuid'];
  target_group_id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteProjectSubPlanArgs = {
  where: ProjectSubPlanBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectTargetGroupArgs = {
  where: ProjectTargetGroupBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectTargetGroupByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteProjectUnitArgs = {
  where: ProjectUnitBoolExp;
};


/** mutation root */
export type MutationRootDeleteProjectUnitByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteProvinceArgs = {
  where: ProvinceBoolExp;
};


/** mutation root */
export type MutationRootDeleteProvinceByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteRegionArgs = {
  where: RegionBoolExp;
};


/** mutation root */
export type MutationRootDeleteRegionByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteUserMappingArgs = {
  where: UserMappingBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserMappingByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteUsersArgs = {
  where: UsersBoolExp;
};


/** mutation root */
export type MutationRootDeleteUsersByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteUsersPermissionArgs = {
  where: UsersPermissionBoolExp;
};


/** mutation root */
export type MutationRootDeleteUsersPermissionByPkArgs = {
  permission_id: Scalars['String'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootGeneratePdfFileArgs = {
  data: GeneratePdfFileInput;
};


/** mutation root */
export type MutationRootInsertAccountAccountArgs = {
  objects: Array<AccountAccountInsertInput>;
  on_conflict?: Maybe<AccountAccountOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountAccountCategoryArgs = {
  objects: Array<AccountAccountCategoryInsertInput>;
  on_conflict?: Maybe<AccountAccountCategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountAccountCategoryOneArgs = {
  object: AccountAccountCategoryInsertInput;
  on_conflict?: Maybe<AccountAccountCategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountAccountOneArgs = {
  object: AccountAccountInsertInput;
  on_conflict?: Maybe<AccountAccountOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountAccountTypeArgs = {
  objects: Array<AccountAccountTypeInsertInput>;
  on_conflict?: Maybe<AccountAccountTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountAccountTypeOneArgs = {
  object: AccountAccountTypeInsertInput;
  on_conflict?: Maybe<AccountAccountTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountBudgetAccountArgs = {
  objects: Array<AccountBudgetAccountInsertInput>;
  on_conflict?: Maybe<AccountBudgetAccountOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountBudgetAccountOneArgs = {
  object: AccountBudgetAccountInsertInput;
  on_conflict?: Maybe<AccountBudgetAccountOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountBudgetAllocationArgs = {
  objects: Array<AccountBudgetAllocationInsertInput>;
  on_conflict?: Maybe<AccountBudgetAllocationOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountBudgetAllocationLogArgs = {
  objects: Array<AccountBudgetAllocationLogInsertInput>;
  on_conflict?: Maybe<AccountBudgetAllocationLogOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountBudgetAllocationLogOneArgs = {
  object: AccountBudgetAllocationLogInsertInput;
  on_conflict?: Maybe<AccountBudgetAllocationLogOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountBudgetAllocationOneArgs = {
  object: AccountBudgetAllocationInsertInput;
  on_conflict?: Maybe<AccountBudgetAllocationOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountBudgetTypeArgs = {
  objects: Array<AccountBudgetTypeInsertInput>;
  on_conflict?: Maybe<AccountBudgetTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountBudgetTypeOneArgs = {
  object: AccountBudgetTypeInsertInput;
  on_conflict?: Maybe<AccountBudgetTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountEntryTypeArgs = {
  objects: Array<AccountEntryTypeInsertInput>;
  on_conflict?: Maybe<AccountEntryTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountEntryTypeOneArgs = {
  object: AccountEntryTypeInsertInput;
  on_conflict?: Maybe<AccountEntryTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountLedgerEntryArgs = {
  objects: Array<AccountLedgerEntryInsertInput>;
  on_conflict?: Maybe<AccountLedgerEntryOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountLedgerEntryOneArgs = {
  object: AccountLedgerEntryInsertInput;
  on_conflict?: Maybe<AccountLedgerEntryOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountLendingArgs = {
  objects: Array<AccountLendingInsertInput>;
  on_conflict?: Maybe<AccountLendingOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountLendingItemArgs = {
  objects: Array<AccountLendingItemInsertInput>;
  on_conflict?: Maybe<AccountLendingItemOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountLendingItemOneArgs = {
  object: AccountLendingItemInsertInput;
  on_conflict?: Maybe<AccountLendingItemOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountLendingOneArgs = {
  object: AccountLendingInsertInput;
  on_conflict?: Maybe<AccountLendingOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountLendingStatusArgs = {
  objects: Array<AccountLendingStatusInsertInput>;
  on_conflict?: Maybe<AccountLendingStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountLendingStatusOneArgs = {
  object: AccountLendingStatusInsertInput;
  on_conflict?: Maybe<AccountLendingStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountTransactionArgs = {
  objects: Array<AccountTransactionInsertInput>;
  on_conflict?: Maybe<AccountTransactionOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountTransactionLogArgs = {
  objects: Array<AccountTransactionLogInsertInput>;
  on_conflict?: Maybe<AccountTransactionLogOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountTransactionLogOneArgs = {
  object: AccountTransactionLogInsertInput;
  on_conflict?: Maybe<AccountTransactionLogOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountTransactionOneArgs = {
  object: AccountTransactionInsertInput;
  on_conflict?: Maybe<AccountTransactionOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountTransactionStatusArgs = {
  objects: Array<AccountTransactionStatusInsertInput>;
  on_conflict?: Maybe<AccountTransactionStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountTransactionStatusOneArgs = {
  object: AccountTransactionStatusInsertInput;
  on_conflict?: Maybe<AccountTransactionStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountTransactionTypeArgs = {
  objects: Array<AccountTransactionTypeInsertInput>;
  on_conflict?: Maybe<AccountTransactionTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountTransactionTypeOneArgs = {
  object: AccountTransactionTypeInsertInput;
  on_conflict?: Maybe<AccountTransactionTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertAttachmentArgs = {
  objects: Array<AttachmentInsertInput>;
  on_conflict?: Maybe<AttachmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertAttachmentOneArgs = {
  object: AttachmentInsertInput;
  on_conflict?: Maybe<AttachmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuditLoggedActionsArgs = {
  objects: Array<AuditLoggedActionsInsertInput>;
  on_conflict?: Maybe<AuditLoggedActionsOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuditLoggedActionsOneArgs = {
  object: AuditLoggedActionsInsertInput;
  on_conflict?: Maybe<AuditLoggedActionsOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthAccountProvidersArgs = {
  objects: Array<AuthAccountProvidersInsertInput>;
  on_conflict?: Maybe<AuthAccountProvidersOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthAccountProvidersOneArgs = {
  object: AuthAccountProvidersInsertInput;
  on_conflict?: Maybe<AuthAccountProvidersOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthAccountRolesArgs = {
  objects: Array<AuthAccountRolesInsertInput>;
  on_conflict?: Maybe<AuthAccountRolesOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthAccountRolesOneArgs = {
  object: AuthAccountRolesInsertInput;
  on_conflict?: Maybe<AuthAccountRolesOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthAccountsArgs = {
  objects: Array<AuthAccountsInsertInput>;
  on_conflict?: Maybe<AuthAccountsOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthAccountsOneArgs = {
  object: AuthAccountsInsertInput;
  on_conflict?: Maybe<AuthAccountsOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthProvidersArgs = {
  objects: Array<AuthProvidersInsertInput>;
  on_conflict?: Maybe<AuthProvidersOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthProvidersOneArgs = {
  object: AuthProvidersInsertInput;
  on_conflict?: Maybe<AuthProvidersOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthRefreshTokensArgs = {
  objects: Array<AuthRefreshTokensInsertInput>;
  on_conflict?: Maybe<AuthRefreshTokensOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthRefreshTokensOneArgs = {
  object: AuthRefreshTokensInsertInput;
  on_conflict?: Maybe<AuthRefreshTokensOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthRolesArgs = {
  objects: Array<AuthRolesInsertInput>;
  on_conflict?: Maybe<AuthRolesOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthRolesOneArgs = {
  object: AuthRolesInsertInput;
  on_conflict?: Maybe<AuthRolesOnConflict>;
};


/** mutation root */
export type MutationRootInsertBankBranchArgs = {
  objects: Array<BankBranchInsertInput>;
  on_conflict?: Maybe<BankBranchOnConflict>;
};


/** mutation root */
export type MutationRootInsertBankBranchOneArgs = {
  object: BankBranchInsertInput;
  on_conflict?: Maybe<BankBranchOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAfStatusArgs = {
  objects: Array<ContractAfStatusInsertInput>;
  on_conflict?: Maybe<ContractAfStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAfStatusOneArgs = {
  object: ContractAfStatusInsertInput;
  on_conflict?: Maybe<ContractAfStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAgreementArgs = {
  objects: Array<ContractAgreementInsertInput>;
  on_conflict?: Maybe<ContractAgreementOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAgreementAttachmentArgs = {
  objects: Array<ContractAgreementAttachmentInsertInput>;
  on_conflict?: Maybe<ContractAgreementAttachmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAgreementAttachmentOneArgs = {
  object: ContractAgreementAttachmentInsertInput;
  on_conflict?: Maybe<ContractAgreementAttachmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAgreementDetailArgs = {
  objects: Array<ContractAgreementDetailInsertInput>;
  on_conflict?: Maybe<ContractAgreementDetailOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAgreementDetailOneArgs = {
  object: ContractAgreementDetailInsertInput;
  on_conflict?: Maybe<ContractAgreementDetailOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAgreementDisbursementArgs = {
  objects: Array<ContractAgreementDisbursementInsertInput>;
  on_conflict?: Maybe<ContractAgreementDisbursementOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAgreementDisbursementOneArgs = {
  object: ContractAgreementDisbursementInsertInput;
  on_conflict?: Maybe<ContractAgreementDisbursementOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAgreementFeeChargeArgs = {
  objects: Array<ContractAgreementFeeChargeInsertInput>;
  on_conflict?: Maybe<ContractAgreementFeeChargeOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAgreementFeeChargeOneArgs = {
  object: ContractAgreementFeeChargeInsertInput;
  on_conflict?: Maybe<ContractAgreementFeeChargeOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAgreementGuarantorArgs = {
  objects: Array<ContractAgreementGuarantorInsertInput>;
  on_conflict?: Maybe<ContractAgreementGuarantorOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAgreementGuarantorOneArgs = {
  object: ContractAgreementGuarantorInsertInput;
  on_conflict?: Maybe<ContractAgreementGuarantorOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAgreementOneArgs = {
  object: ContractAgreementInsertInput;
  on_conflict?: Maybe<ContractAgreementOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAgreementReceiptArgs = {
  objects: Array<ContractAgreementReceiptInsertInput>;
  on_conflict?: Maybe<ContractAgreementReceiptOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAgreementReceiptOneArgs = {
  object: ContractAgreementReceiptInsertInput;
  on_conflict?: Maybe<ContractAgreementReceiptOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAgreementStatusArgs = {
  objects: Array<ContractAgreementStatusInsertInput>;
  on_conflict?: Maybe<ContractAgreementStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAgreementStatusMapperArgs = {
  objects: Array<ContractAgreementStatusMapperInsertInput>;
  on_conflict?: Maybe<ContractAgreementStatusMapperOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAgreementStatusMapperOneArgs = {
  object: ContractAgreementStatusMapperInsertInput;
  on_conflict?: Maybe<ContractAgreementStatusMapperOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAgreementStatusOneArgs = {
  object: ContractAgreementStatusInsertInput;
  on_conflict?: Maybe<ContractAgreementStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAgreementTransactionArgs = {
  objects: Array<ContractAgreementTransactionInsertInput>;
  on_conflict?: Maybe<ContractAgreementTransactionOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAgreementTransactionOneArgs = {
  object: ContractAgreementTransactionInsertInput;
  on_conflict?: Maybe<ContractAgreementTransactionOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAgreementWithdrawArgs = {
  objects: Array<ContractAgreementWithdrawInsertInput>;
  on_conflict?: Maybe<ContractAgreementWithdrawOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAgreementWithdrawOneArgs = {
  object: ContractAgreementWithdrawInsertInput;
  on_conflict?: Maybe<ContractAgreementWithdrawOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAttachmentTypeArgs = {
  objects: Array<ContractAttachmentTypeInsertInput>;
  on_conflict?: Maybe<ContractAttachmentTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractAttachmentTypeOneArgs = {
  object: ContractAttachmentTypeInsertInput;
  on_conflict?: Maybe<ContractAttachmentTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractGradeArgs = {
  objects: Array<ContractGradeInsertInput>;
  on_conflict?: Maybe<ContractGradeOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractGradeOneArgs = {
  object: ContractGradeInsertInput;
  on_conflict?: Maybe<ContractGradeOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractTransactionCreatorTypeArgs = {
  objects: Array<ContractTransactionCreatorTypeInsertInput>;
  on_conflict?: Maybe<ContractTransactionCreatorTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertContractTransactionCreatorTypeOneArgs = {
  object: ContractTransactionCreatorTypeInsertInput;
  on_conflict?: Maybe<ContractTransactionCreatorTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertCurrentUserPermissionsArgs = {
  objects: Array<CurrentUserPermissionsInsertInput>;
};


/** mutation root */
export type MutationRootInsertCurrentUserPermissionsOneArgs = {
  object: CurrentUserPermissionsInsertInput;
};


/** mutation root */
export type MutationRootInsertDepartmentArgs = {
  objects: Array<DepartmentInsertInput>;
  on_conflict?: Maybe<DepartmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertDepartmentOneArgs = {
  object: DepartmentInsertInput;
  on_conflict?: Maybe<DepartmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertFeatureToggleArgs = {
  objects: Array<FeatureToggleInsertInput>;
  on_conflict?: Maybe<FeatureToggleOnConflict>;
};


/** mutation root */
export type MutationRootInsertFeatureToggleOneArgs = {
  object: FeatureToggleInsertInput;
  on_conflict?: Maybe<FeatureToggleOnConflict>;
};


/** mutation root */
export type MutationRootInsertFiscalYearArgs = {
  objects: Array<FiscalYearInsertInput>;
  on_conflict?: Maybe<FiscalYearOnConflict>;
};


/** mutation root */
export type MutationRootInsertFiscalYearOneArgs = {
  object: FiscalYearInsertInput;
  on_conflict?: Maybe<FiscalYearOnConflict>;
};


/** mutation root */
export type MutationRootInsertImportLogArgs = {
  objects: Array<ImportLogInsertInput>;
  on_conflict?: Maybe<ImportLogOnConflict>;
};


/** mutation root */
export type MutationRootInsertImportLogOneArgs = {
  object: ImportLogInsertInput;
  on_conflict?: Maybe<ImportLogOnConflict>;
};


/** mutation root */
export type MutationRootInsertJuristicArgs = {
  objects: Array<JuristicInsertInput>;
  on_conflict?: Maybe<JuristicOnConflict>;
};


/** mutation root */
export type MutationRootInsertJuristicAddressArgs = {
  objects: Array<JuristicAddressInsertInput>;
  on_conflict?: Maybe<JuristicAddressOnConflict>;
};


/** mutation root */
export type MutationRootInsertJuristicAddressOneArgs = {
  object: JuristicAddressInsertInput;
  on_conflict?: Maybe<JuristicAddressOnConflict>;
};


/** mutation root */
export type MutationRootInsertJuristicCommitteeArgs = {
  objects: Array<JuristicCommitteeInsertInput>;
  on_conflict?: Maybe<JuristicCommitteeOnConflict>;
};


/** mutation root */
export type MutationRootInsertJuristicCommitteeOneArgs = {
  object: JuristicCommitteeInsertInput;
  on_conflict?: Maybe<JuristicCommitteeOnConflict>;
};


/** mutation root */
export type MutationRootInsertJuristicOneArgs = {
  object: JuristicInsertInput;
  on_conflict?: Maybe<JuristicOnConflict>;
};


/** mutation root */
export type MutationRootInsertJuristicStandardObjectiveArgs = {
  objects: Array<JuristicStandardObjectiveInsertInput>;
  on_conflict?: Maybe<JuristicStandardObjectiveOnConflict>;
};


/** mutation root */
export type MutationRootInsertJuristicStandardObjectiveOneArgs = {
  object: JuristicStandardObjectiveInsertInput;
  on_conflict?: Maybe<JuristicStandardObjectiveOnConflict>;
};


/** mutation root */
export type MutationRootInsertLedgerBudgetAllocationArgs = {
  objects: Array<LedgerBudgetAllocationInsertInput>;
  on_conflict?: Maybe<LedgerBudgetAllocationOnConflict>;
};


/** mutation root */
export type MutationRootInsertLedgerBudgetAllocationOneArgs = {
  object: LedgerBudgetAllocationInsertInput;
  on_conflict?: Maybe<LedgerBudgetAllocationOnConflict>;
};


/** mutation root */
export type MutationRootInsertLedgerLedgerArgs = {
  objects: Array<LedgerLedgerInsertInput>;
  on_conflict?: Maybe<LedgerLedgerOnConflict>;
};


/** mutation root */
export type MutationRootInsertLedgerLedgerAttachmentArgs = {
  objects: Array<LedgerLedgerAttachmentInsertInput>;
  on_conflict?: Maybe<LedgerLedgerAttachmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertLedgerLedgerAttachmentOneArgs = {
  object: LedgerLedgerAttachmentInsertInput;
  on_conflict?: Maybe<LedgerLedgerAttachmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertLedgerLedgerLogArgs = {
  objects: Array<LedgerLedgerLogInsertInput>;
  on_conflict?: Maybe<LedgerLedgerLogOnConflict>;
};


/** mutation root */
export type MutationRootInsertLedgerLedgerLogOneArgs = {
  object: LedgerLedgerLogInsertInput;
  on_conflict?: Maybe<LedgerLedgerLogOnConflict>;
};


/** mutation root */
export type MutationRootInsertLedgerLedgerOneArgs = {
  object: LedgerLedgerInsertInput;
  on_conflict?: Maybe<LedgerLedgerOnConflict>;
};


/** mutation root */
export type MutationRootInsertLedgerLedgerStatusArgs = {
  objects: Array<LedgerLedgerStatusInsertInput>;
  on_conflict?: Maybe<LedgerLedgerStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertLedgerLedgerStatusOneArgs = {
  object: LedgerLedgerStatusInsertInput;
  on_conflict?: Maybe<LedgerLedgerStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertLedgerLedgerTypeArgs = {
  objects: Array<LedgerLedgerTypeInsertInput>;
  on_conflict?: Maybe<LedgerLedgerTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertLedgerLedgerTypeOneArgs = {
  object: LedgerLedgerTypeInsertInput;
  on_conflict?: Maybe<LedgerLedgerTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertLedgerReceivableArgs = {
  objects: Array<LedgerReceivableInsertInput>;
  on_conflict?: Maybe<LedgerReceivableOnConflict>;
};


/** mutation root */
export type MutationRootInsertLedgerReceivableItemArgs = {
  objects: Array<LedgerReceivableItemInsertInput>;
  on_conflict?: Maybe<LedgerReceivableItemOnConflict>;
};


/** mutation root */
export type MutationRootInsertLedgerReceivableItemOneArgs = {
  object: LedgerReceivableItemInsertInput;
  on_conflict?: Maybe<LedgerReceivableItemOnConflict>;
};


/** mutation root */
export type MutationRootInsertLedgerReceivableLogArgs = {
  objects: Array<LedgerReceivableLogInsertInput>;
  on_conflict?: Maybe<LedgerReceivableLogOnConflict>;
};


/** mutation root */
export type MutationRootInsertLedgerReceivableLogOneArgs = {
  object: LedgerReceivableLogInsertInput;
  on_conflict?: Maybe<LedgerReceivableLogOnConflict>;
};


/** mutation root */
export type MutationRootInsertLedgerReceivableOneArgs = {
  object: LedgerReceivableInsertInput;
  on_conflict?: Maybe<LedgerReceivableOnConflict>;
};


/** mutation root */
export type MutationRootInsertLedgerReceivableStatusArgs = {
  objects: Array<LedgerReceivableStatusInsertInput>;
  on_conflict?: Maybe<LedgerReceivableStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertLedgerReceivableStatusOneArgs = {
  object: LedgerReceivableStatusInsertInput;
  on_conflict?: Maybe<LedgerReceivableStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanAccountTypeArgs = {
  objects: Array<LoanAccountTypeInsertInput>;
  on_conflict?: Maybe<LoanAccountTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanAccountTypeOneArgs = {
  object: LoanAccountTypeInsertInput;
  on_conflict?: Maybe<LoanAccountTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationAttachmentArgs = {
  objects: Array<LoanApplicationAttachmentInsertInput>;
  on_conflict?: Maybe<LoanApplicationAttachmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationAttachmentOneArgs = {
  object: LoanApplicationAttachmentInsertInput;
  on_conflict?: Maybe<LoanApplicationAttachmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationBankBranchTransferArgs = {
  objects: Array<LoanApplicationBankBranchTransferInsertInput>;
  on_conflict?: Maybe<LoanApplicationBankBranchTransferOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationBankBranchTransferOneArgs = {
  object: LoanApplicationBankBranchTransferInsertInput;
  on_conflict?: Maybe<LoanApplicationBankBranchTransferOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationBusinessOperationArgs = {
  objects: Array<LoanApplicationBusinessOperationInsertInput>;
  on_conflict?: Maybe<LoanApplicationBusinessOperationOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationBusinessOperationOneArgs = {
  object: LoanApplicationBusinessOperationInsertInput;
  on_conflict?: Maybe<LoanApplicationBusinessOperationOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationCommitteeArgs = {
  objects: Array<LoanApplicationCommitteeInsertInput>;
  on_conflict?: Maybe<LoanApplicationCommitteeOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationCommitteeOneArgs = {
  object: LoanApplicationCommitteeInsertInput;
  on_conflict?: Maybe<LoanApplicationCommitteeOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationCurrentLoanArgs = {
  objects: Array<LoanApplicationCurrentLoanInsertInput>;
  on_conflict?: Maybe<LoanApplicationCurrentLoanOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationCurrentLoanOneArgs = {
  object: LoanApplicationCurrentLoanInsertInput;
  on_conflict?: Maybe<LoanApplicationCurrentLoanOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationDetailArgs = {
  objects: Array<LoanApplicationDetailInsertInput>;
  on_conflict?: Maybe<LoanApplicationDetailOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationDetailOneArgs = {
  object: LoanApplicationDetailInsertInput;
  on_conflict?: Maybe<LoanApplicationDetailOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationEventLogArgs = {
  objects: Array<LoanApplicationEventLogInsertInput>;
  on_conflict?: Maybe<LoanApplicationEventLogOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationEventLogOneArgs = {
  object: LoanApplicationEventLogInsertInput;
  on_conflict?: Maybe<LoanApplicationEventLogOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationHeaderArgs = {
  objects: Array<LoanApplicationHeaderInsertInput>;
  on_conflict?: Maybe<LoanApplicationHeaderOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationHeaderOneArgs = {
  object: LoanApplicationHeaderInsertInput;
  on_conflict?: Maybe<LoanApplicationHeaderOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationHeaderPdfArgs = {
  objects: Array<LoanApplicationHeaderPdfInsertInput>;
  on_conflict?: Maybe<LoanApplicationHeaderPdfOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationHeaderPdfOneArgs = {
  object: LoanApplicationHeaderPdfInsertInput;
  on_conflict?: Maybe<LoanApplicationHeaderPdfOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationObjectiveArgs = {
  objects: Array<LoanApplicationObjectiveInsertInput>;
  on_conflict?: Maybe<LoanApplicationObjectiveOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationObjectiveOneArgs = {
  object: LoanApplicationObjectiveInsertInput;
  on_conflict?: Maybe<LoanApplicationObjectiveOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationOrganizationTransferArgs = {
  objects: Array<LoanApplicationOrganizationTransferInsertInput>;
  on_conflict?: Maybe<LoanApplicationOrganizationTransferOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationOrganizationTransferOneArgs = {
  object: LoanApplicationOrganizationTransferInsertInput;
  on_conflict?: Maybe<LoanApplicationOrganizationTransferOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationProfileArgs = {
  objects: Array<LoanApplicationProfileInsertInput>;
  on_conflict?: Maybe<LoanApplicationProfileOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationProfileOneArgs = {
  object: LoanApplicationProfileInsertInput;
  on_conflict?: Maybe<LoanApplicationProfileOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationProfileSCurveArgs = {
  objects: Array<LoanApplicationProfileSCurveInsertInput>;
  on_conflict?: Maybe<LoanApplicationProfileSCurveOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationProfileSCurveOneArgs = {
  object: LoanApplicationProfileSCurveInsertInput;
  on_conflict?: Maybe<LoanApplicationProfileSCurveOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationSequenceNoArgs = {
  objects: Array<LoanApplicationSequenceNoInsertInput>;
  on_conflict?: Maybe<LoanApplicationSequenceNoOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationSequenceNoOneArgs = {
  object: LoanApplicationSequenceNoInsertInput;
  on_conflict?: Maybe<LoanApplicationSequenceNoOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationStepArgs = {
  objects: Array<LoanApplicationStepInsertInput>;
  on_conflict?: Maybe<LoanApplicationStepOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanApplicationStepOneArgs = {
  object: LoanApplicationStepInsertInput;
  on_conflict?: Maybe<LoanApplicationStepOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanAttachmentTypeArgs = {
  objects: Array<LoanAttachmentTypeInsertInput>;
  on_conflict?: Maybe<LoanAttachmentTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanAttachmentTypeOneArgs = {
  object: LoanAttachmentTypeInsertInput;
  on_conflict?: Maybe<LoanAttachmentTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanBankApproveArgs = {
  objects: Array<LoanBankApproveInsertInput>;
  on_conflict?: Maybe<LoanBankApproveOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanBankApproveOneArgs = {
  object: LoanBankApproveInsertInput;
  on_conflict?: Maybe<LoanBankApproveOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanBusinessGroupArgs = {
  objects: Array<LoanBusinessGroupInsertInput>;
  on_conflict?: Maybe<LoanBusinessGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanBusinessGroupOneArgs = {
  object: LoanBusinessGroupInsertInput;
  on_conflict?: Maybe<LoanBusinessGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanBusinessOperationArgs = {
  objects: Array<LoanBusinessOperationInsertInput>;
  on_conflict?: Maybe<LoanBusinessOperationOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanBusinessOperationOneArgs = {
  object: LoanBusinessOperationInsertInput;
  on_conflict?: Maybe<LoanBusinessOperationOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanBusinessProprietaryArgs = {
  objects: Array<LoanBusinessProprietaryInsertInput>;
  on_conflict?: Maybe<LoanBusinessProprietaryOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanBusinessProprietaryOneArgs = {
  object: LoanBusinessProprietaryInsertInput;
  on_conflict?: Maybe<LoanBusinessProprietaryOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanBusinessStatusArgs = {
  objects: Array<LoanBusinessStatusInsertInput>;
  on_conflict?: Maybe<LoanBusinessStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanBusinessStatusOneArgs = {
  object: LoanBusinessStatusInsertInput;
  on_conflict?: Maybe<LoanBusinessStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanBusinessTypeArgs = {
  objects: Array<LoanBusinessTypeInsertInput>;
  on_conflict?: Maybe<LoanBusinessTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanBusinessTypeOneArgs = {
  object: LoanBusinessTypeInsertInput;
  on_conflict?: Maybe<LoanBusinessTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanCommitteeApproveArgs = {
  objects: Array<LoanCommitteeApproveInsertInput>;
  on_conflict?: Maybe<LoanCommitteeApproveOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanCommitteeApproveOneArgs = {
  object: LoanCommitteeApproveInsertInput;
  on_conflict?: Maybe<LoanCommitteeApproveOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanCurrentLoanArgs = {
  objects: Array<LoanCurrentLoanInsertInput>;
  on_conflict?: Maybe<LoanCurrentLoanOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanCurrentLoanOneArgs = {
  object: LoanCurrentLoanInsertInput;
  on_conflict?: Maybe<LoanCurrentLoanOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanDebtRestructuringArgs = {
  objects: Array<LoanDebtRestructuringInsertInput>;
  on_conflict?: Maybe<LoanDebtRestructuringOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanDebtRestructuringOneArgs = {
  object: LoanDebtRestructuringInsertInput;
  on_conflict?: Maybe<LoanDebtRestructuringOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanDocumentApproveArgs = {
  objects: Array<LoanDocumentApproveInsertInput>;
  on_conflict?: Maybe<LoanDocumentApproveOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanDocumentApproveOneArgs = {
  object: LoanDocumentApproveInsertInput;
  on_conflict?: Maybe<LoanDocumentApproveOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanFinancialAgreementArgs = {
  objects: Array<LoanFinancialAgreementInsertInput>;
  on_conflict?: Maybe<LoanFinancialAgreementOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanFinancialAgreementOneArgs = {
  object: LoanFinancialAgreementInsertInput;
  on_conflict?: Maybe<LoanFinancialAgreementOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanFinancialRescueArgs = {
  objects: Array<LoanFinancialRescueInsertInput>;
  on_conflict?: Maybe<LoanFinancialRescueOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanFinancialRescueOneArgs = {
  object: LoanFinancialRescueInsertInput;
  on_conflict?: Maybe<LoanFinancialRescueOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanObjectiveArgs = {
  objects: Array<LoanObjectiveInsertInput>;
  on_conflict?: Maybe<LoanObjectiveOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanObjectiveOneArgs = {
  object: LoanObjectiveInsertInput;
  on_conflict?: Maybe<LoanObjectiveOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanProgramArgs = {
  objects: Array<LoanProgramInsertInput>;
  on_conflict?: Maybe<LoanProgramOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanProgramOneArgs = {
  object: LoanProgramInsertInput;
  on_conflict?: Maybe<LoanProgramOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanProgramStatusArgs = {
  objects: Array<LoanProgramStatusInsertInput>;
  on_conflict?: Maybe<LoanProgramStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanProgramStatusOneArgs = {
  object: LoanProgramStatusInsertInput;
  on_conflict?: Maybe<LoanProgramStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanProgramTransactionArgs = {
  objects: Array<LoanProgramTransactionInsertInput>;
  on_conflict?: Maybe<LoanProgramTransactionOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanProgramTransactionOneArgs = {
  object: LoanProgramTransactionInsertInput;
  on_conflict?: Maybe<LoanProgramTransactionOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanProgramTransactionTypeArgs = {
  objects: Array<LoanProgramTransactionTypeInsertInput>;
  on_conflict?: Maybe<LoanProgramTransactionTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanProgramTransactionTypeOneArgs = {
  object: LoanProgramTransactionTypeInsertInput;
  on_conflict?: Maybe<LoanProgramTransactionTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanProprietaryArgs = {
  objects: Array<LoanProprietaryInsertInput>;
  on_conflict?: Maybe<LoanProprietaryOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanProprietaryOneArgs = {
  object: LoanProprietaryInsertInput;
  on_conflict?: Maybe<LoanProprietaryOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanRepayTypeArgs = {
  objects: Array<LoanRepayTypeInsertInput>;
  on_conflict?: Maybe<LoanRepayTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanRepayTypeOneArgs = {
  object: LoanRepayTypeInsertInput;
  on_conflict?: Maybe<LoanRepayTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanSCurveArgs = {
  objects: Array<LoanSCurveInsertInput>;
  on_conflict?: Maybe<LoanSCurveOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanSCurveOneArgs = {
  object: LoanSCurveInsertInput;
  on_conflict?: Maybe<LoanSCurveOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanSCurveTypeArgs = {
  objects: Array<LoanSCurveTypeInsertInput>;
  on_conflict?: Maybe<LoanSCurveTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanSCurveTypeOneArgs = {
  object: LoanSCurveTypeInsertInput;
  on_conflict?: Maybe<LoanSCurveTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanSmeDefinitionArgs = {
  objects: Array<LoanSmeDefinitionInsertInput>;
  on_conflict?: Maybe<LoanSmeDefinitionOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanSmeDefinitionOneArgs = {
  object: LoanSmeDefinitionInsertInput;
  on_conflict?: Maybe<LoanSmeDefinitionOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanSourceBusinessTypeArgs = {
  objects: Array<LoanSourceBusinessTypeInsertInput>;
  on_conflict?: Maybe<LoanSourceBusinessTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanSourceBusinessTypeOneArgs = {
  object: LoanSourceBusinessTypeInsertInput;
  on_conflict?: Maybe<LoanSourceBusinessTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanSourceFinancialInstitutionArgs = {
  objects: Array<LoanSourceFinancialInstitutionInsertInput>;
  on_conflict?: Maybe<LoanSourceFinancialInstitutionOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanSourceFinancialInstitutionOneArgs = {
  object: LoanSourceFinancialInstitutionInsertInput;
  on_conflict?: Maybe<LoanSourceFinancialInstitutionOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanSourceLoanArgs = {
  objects: Array<LoanSourceLoanInsertInput>;
  on_conflict?: Maybe<LoanSourceLoanOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanSourceLoanAttachmentArgs = {
  objects: Array<LoanSourceLoanAttachmentInsertInput>;
  on_conflict?: Maybe<LoanSourceLoanAttachmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanSourceLoanAttachmentOneArgs = {
  object: LoanSourceLoanAttachmentInsertInput;
  on_conflict?: Maybe<LoanSourceLoanAttachmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanSourceLoanBusinessTypeArgs = {
  objects: Array<LoanSourceLoanBusinessTypeInsertInput>;
  on_conflict?: Maybe<LoanSourceLoanBusinessTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanSourceLoanBusinessTypeOneArgs = {
  object: LoanSourceLoanBusinessTypeInsertInput;
  on_conflict?: Maybe<LoanSourceLoanBusinessTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanSourceLoanOneArgs = {
  object: LoanSourceLoanInsertInput;
  on_conflict?: Maybe<LoanSourceLoanOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanSourceObjectiveArgs = {
  objects: Array<LoanSourceObjectiveInsertInput>;
  on_conflict?: Maybe<LoanSourceObjectiveOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanSourceObjectiveOneArgs = {
  object: LoanSourceObjectiveInsertInput;
  on_conflict?: Maybe<LoanSourceObjectiveOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanSourceSearchLogArgs = {
  objects: Array<LoanSourceSearchLogInsertInput>;
  on_conflict?: Maybe<LoanSourceSearchLogOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanSourceSearchLogOneArgs = {
  object: LoanSourceSearchLogInsertInput;
  on_conflict?: Maybe<LoanSourceSearchLogOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanTransferStatusArgs = {
  objects: Array<LoanTransferStatusInsertInput>;
  on_conflict?: Maybe<LoanTransferStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertLoanTransferStatusOneArgs = {
  object: LoanTransferStatusInsertInput;
  on_conflict?: Maybe<LoanTransferStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertMeArgs = {
  objects: Array<MeInsertInput>;
};


/** mutation root */
export type MutationRootInsertMeOneArgs = {
  object: MeInsertInput;
};


/** mutation root */
export type MutationRootInsertNameTitleArgs = {
  objects: Array<NameTitleInsertInput>;
  on_conflict?: Maybe<NameTitleOnConflict>;
};


/** mutation root */
export type MutationRootInsertNameTitleOneArgs = {
  object: NameTitleInsertInput;
  on_conflict?: Maybe<NameTitleOnConflict>;
};


/** mutation root */
export type MutationRootInsertNotificationArgs = {
  objects: Array<NotificationInsertInput>;
  on_conflict?: Maybe<NotificationOnConflict>;
};


/** mutation root */
export type MutationRootInsertNotificationOneArgs = {
  object: NotificationInsertInput;
  on_conflict?: Maybe<NotificationOnConflict>;
};


/** mutation root */
export type MutationRootInsertNotificationStatusArgs = {
  objects: Array<NotificationStatusInsertInput>;
  on_conflict?: Maybe<NotificationStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertNotificationStatusOneArgs = {
  object: NotificationStatusInsertInput;
  on_conflict?: Maybe<NotificationStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrganizationArgs = {
  objects: Array<OrganizationInsertInput>;
  on_conflict?: Maybe<OrganizationOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrganizationOneArgs = {
  object: OrganizationInsertInput;
  on_conflict?: Maybe<OrganizationOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrganizationTypeArgs = {
  objects: Array<OrganizationTypeInsertInput>;
  on_conflict?: Maybe<OrganizationTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrganizationTypeOneArgs = {
  object: OrganizationTypeInsertInput;
  on_conflict?: Maybe<OrganizationTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertPermissionArgs = {
  objects: Array<PermissionInsertInput>;
  on_conflict?: Maybe<PermissionOnConflict>;
};


/** mutation root */
export type MutationRootInsertPermissionOneArgs = {
  object: PermissionInsertInput;
  on_conflict?: Maybe<PermissionOnConflict>;
};


/** mutation root */
export type MutationRootInsertPostsArgs = {
  objects: Array<PostsInsertInput>;
  on_conflict?: Maybe<PostsOnConflict>;
};


/** mutation root */
export type MutationRootInsertPostsOneArgs = {
  object: PostsInsertInput;
  on_conflict?: Maybe<PostsOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectApprovedArgs = {
  objects: Array<ProjectApprovedInsertInput>;
  on_conflict?: Maybe<ProjectApprovedOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectApprovedOneArgs = {
  object: ProjectApprovedInsertInput;
  on_conflict?: Maybe<ProjectApprovedOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectAttachmentTypeArgs = {
  objects: Array<ProjectAttachmentTypeInsertInput>;
  on_conflict?: Maybe<ProjectAttachmentTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectAttachmentTypeOneArgs = {
  object: ProjectAttachmentTypeInsertInput;
  on_conflict?: Maybe<ProjectAttachmentTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectBudgetArgs = {
  objects: Array<ProjectBudgetInsertInput>;
  on_conflict?: Maybe<ProjectBudgetOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectBudgetGroupArgs = {
  objects: Array<ProjectBudgetGroupInsertInput>;
  on_conflict?: Maybe<ProjectBudgetGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectBudgetGroupMappingArgs = {
  objects: Array<ProjectBudgetGroupMappingInsertInput>;
  on_conflict?: Maybe<ProjectBudgetGroupMappingOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectBudgetGroupMappingOneArgs = {
  object: ProjectBudgetGroupMappingInsertInput;
  on_conflict?: Maybe<ProjectBudgetGroupMappingOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectBudgetGroupOneArgs = {
  object: ProjectBudgetGroupInsertInput;
  on_conflict?: Maybe<ProjectBudgetGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectBudgetOneArgs = {
  object: ProjectBudgetInsertInput;
  on_conflict?: Maybe<ProjectBudgetOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectIndicatorTypeArgs = {
  objects: Array<ProjectIndicatorTypeInsertInput>;
  on_conflict?: Maybe<ProjectIndicatorTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectIndicatorTypeOneArgs = {
  object: ProjectIndicatorTypeInsertInput;
  on_conflict?: Maybe<ProjectIndicatorTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectMasterPlanArgs = {
  objects: Array<ProjectMasterPlanInsertInput>;
};


/** mutation root */
export type MutationRootInsertProjectMasterPlanOneArgs = {
  object: ProjectMasterPlanInsertInput;
};


/** mutation root */
export type MutationRootInsertProjectNationalStrategyArgs = {
  objects: Array<ProjectNationalStrategyInsertInput>;
  on_conflict?: Maybe<ProjectNationalStrategyOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectNationalStrategyOneArgs = {
  object: ProjectNationalStrategyInsertInput;
  on_conflict?: Maybe<ProjectNationalStrategyOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectOperatingActivityProgressArgs = {
  objects: Array<ProjectOperatingActivityProgressInsertInput>;
  on_conflict?: Maybe<ProjectOperatingActivityProgressOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectOperatingActivityProgressOneArgs = {
  object: ProjectOperatingActivityProgressInsertInput;
  on_conflict?: Maybe<ProjectOperatingActivityProgressOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectOperatingActivityProgressRevisionArgs = {
  objects: Array<ProjectOperatingActivityProgressRevisionInsertInput>;
  on_conflict?: Maybe<ProjectOperatingActivityProgressRevisionOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectOperatingActivityProgressRevisionOneArgs = {
  object: ProjectOperatingActivityProgressRevisionInsertInput;
  on_conflict?: Maybe<ProjectOperatingActivityProgressRevisionOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectOperatingActivityTypeArgs = {
  objects: Array<ProjectOperatingActivityTypeInsertInput>;
  on_conflict?: Maybe<ProjectOperatingActivityTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectOperatingActivityTypeOneArgs = {
  object: ProjectOperatingActivityTypeInsertInput;
  on_conflict?: Maybe<ProjectOperatingActivityTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectArgs = {
  objects: Array<ProjectProjectInsertInput>;
  on_conflict?: Maybe<ProjectProjectOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectAchievementArgs = {
  objects: Array<ProjectProjectAchievementInsertInput>;
  on_conflict?: Maybe<ProjectProjectAchievementOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectAchievementOneArgs = {
  object: ProjectProjectAchievementInsertInput;
  on_conflict?: Maybe<ProjectProjectAchievementOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectAttachmentArgs = {
  objects: Array<ProjectProjectAttachmentInsertInput>;
  on_conflict?: Maybe<ProjectProjectAttachmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectAttachmentOneArgs = {
  object: ProjectProjectAttachmentInsertInput;
  on_conflict?: Maybe<ProjectProjectAttachmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectBudgetItemArgs = {
  objects: Array<ProjectProjectBudgetItemInsertInput>;
  on_conflict?: Maybe<ProjectProjectBudgetItemOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectBudgetItemOneArgs = {
  object: ProjectProjectBudgetItemInsertInput;
  on_conflict?: Maybe<ProjectProjectBudgetItemOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectEventLogArgs = {
  objects: Array<ProjectProjectEventLogInsertInput>;
  on_conflict?: Maybe<ProjectProjectEventLogOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectEventLogOneArgs = {
  object: ProjectProjectEventLogInsertInput;
  on_conflict?: Maybe<ProjectProjectEventLogOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectIndicatorArgs = {
  objects: Array<ProjectProjectIndicatorInsertInput>;
  on_conflict?: Maybe<ProjectProjectIndicatorOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectIndicatorOneArgs = {
  object: ProjectProjectIndicatorInsertInput;
  on_conflict?: Maybe<ProjectProjectIndicatorOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectMemberArgs = {
  objects: Array<ProjectProjectMemberInsertInput>;
  on_conflict?: Maybe<ProjectProjectMemberOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectMemberOneArgs = {
  object: ProjectProjectMemberInsertInput;
  on_conflict?: Maybe<ProjectProjectMemberOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectObjectiveArgs = {
  objects: Array<ProjectProjectObjectiveInsertInput>;
  on_conflict?: Maybe<ProjectProjectObjectiveOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectObjectiveOneArgs = {
  object: ProjectProjectObjectiveInsertInput;
  on_conflict?: Maybe<ProjectProjectObjectiveOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectOneArgs = {
  object: ProjectProjectInsertInput;
  on_conflict?: Maybe<ProjectProjectOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectOperatingActivityArgs = {
  objects: Array<ProjectProjectOperatingActivityInsertInput>;
  on_conflict?: Maybe<ProjectProjectOperatingActivityOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectOperatingActivityOneArgs = {
  object: ProjectProjectOperatingActivityInsertInput;
  on_conflict?: Maybe<ProjectProjectOperatingActivityOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectOperatingAreaArgs = {
  objects: Array<ProjectProjectOperatingAreaInsertInput>;
  on_conflict?: Maybe<ProjectProjectOperatingAreaOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectOperatingAreaOneArgs = {
  object: ProjectProjectOperatingAreaInsertInput;
  on_conflict?: Maybe<ProjectProjectOperatingAreaOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectStepArgs = {
  objects: Array<ProjectProjectStepInsertInput>;
  on_conflict?: Maybe<ProjectProjectStepOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectStepOneArgs = {
  object: ProjectProjectStepInsertInput;
  on_conflict?: Maybe<ProjectProjectStepOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectTargetGroupArgs = {
  objects: Array<ProjectProjectTargetGroupInsertInput>;
  on_conflict?: Maybe<ProjectProjectTargetGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectProjectTargetGroupOneArgs = {
  object: ProjectProjectTargetGroupInsertInput;
  on_conflict?: Maybe<ProjectProjectTargetGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectSubPlanArgs = {
  objects: Array<ProjectSubPlanInsertInput>;
};


/** mutation root */
export type MutationRootInsertProjectSubPlanOneArgs = {
  object: ProjectSubPlanInsertInput;
};


/** mutation root */
export type MutationRootInsertProjectTargetGroupArgs = {
  objects: Array<ProjectTargetGroupInsertInput>;
  on_conflict?: Maybe<ProjectTargetGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectTargetGroupOneArgs = {
  object: ProjectTargetGroupInsertInput;
  on_conflict?: Maybe<ProjectTargetGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectUnitArgs = {
  objects: Array<ProjectUnitInsertInput>;
  on_conflict?: Maybe<ProjectUnitOnConflict>;
};


/** mutation root */
export type MutationRootInsertProjectUnitOneArgs = {
  object: ProjectUnitInsertInput;
  on_conflict?: Maybe<ProjectUnitOnConflict>;
};


/** mutation root */
export type MutationRootInsertProvinceArgs = {
  objects: Array<ProvinceInsertInput>;
  on_conflict?: Maybe<ProvinceOnConflict>;
};


/** mutation root */
export type MutationRootInsertProvinceOneArgs = {
  object: ProvinceInsertInput;
  on_conflict?: Maybe<ProvinceOnConflict>;
};


/** mutation root */
export type MutationRootInsertRegionArgs = {
  objects: Array<RegionInsertInput>;
  on_conflict?: Maybe<RegionOnConflict>;
};


/** mutation root */
export type MutationRootInsertRegionOneArgs = {
  object: RegionInsertInput;
  on_conflict?: Maybe<RegionOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserMappingArgs = {
  objects: Array<UserMappingInsertInput>;
  on_conflict?: Maybe<UserMappingOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserMappingOneArgs = {
  object: UserMappingInsertInput;
  on_conflict?: Maybe<UserMappingOnConflict>;
};


/** mutation root */
export type MutationRootInsertUsersArgs = {
  objects: Array<UsersInsertInput>;
  on_conflict?: Maybe<UsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertUsersOneArgs = {
  object: UsersInsertInput;
  on_conflict?: Maybe<UsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertUsersPermissionArgs = {
  objects: Array<UsersPermissionInsertInput>;
  on_conflict?: Maybe<UsersPermissionOnConflict>;
};


/** mutation root */
export type MutationRootInsertUsersPermissionOneArgs = {
  object: UsersPermissionInsertInput;
  on_conflict?: Maybe<UsersPermissionOnConflict>;
};


/** mutation root */
export type MutationRootLedgerApproveLedgerArgs = {
  data: LedgerApproveInput;
};


/** mutation root */
export type MutationRootLedgerCloseReceivableArgs = {
  data: ReceivableCloseInput;
};


/** mutation root */
export type MutationRootLedgerDeleteReceivableArgs = {
  data: ReceivableDeleteInput;
};


/** mutation root */
export type MutationRootLedgerSubmitLedgerArgs = {
  data: LedgerSubmitInput;
};


/** mutation root */
export type MutationRootLedgerSubmitReceivableArgs = {
  data: ReceivableSubmitInput;
};


/** mutation root */
export type MutationRootProjectProjectApproveArgs = {
  data: ProjectApprovalInput;
};


/** mutation root */
export type MutationRootProjectProjectSubmitArgs = {
  data: ProjectSubmitInput;
};


/** mutation root */
export type MutationRootSubmitLoanApplicationArgs = {
  data: SubmitLoanApplicationInput;
};


/** mutation root */
export type MutationRootTransferLoanApplicationBankBranchArgs = {
  data: TransferLoanApplicationBankBranchInput;
};


/** mutation root */
export type MutationRootUpdateAccountAccountArgs = {
  _inc?: Maybe<AccountAccountIncInput>;
  _set?: Maybe<AccountAccountSetInput>;
  where: AccountAccountBoolExp;
};


/** mutation root */
export type MutationRootUpdateAccountAccountByPkArgs = {
  _inc?: Maybe<AccountAccountIncInput>;
  _set?: Maybe<AccountAccountSetInput>;
  pk_columns: AccountAccountPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAccountAccountCategoryArgs = {
  _set?: Maybe<AccountAccountCategorySetInput>;
  where: AccountAccountCategoryBoolExp;
};


/** mutation root */
export type MutationRootUpdateAccountAccountCategoryByPkArgs = {
  _set?: Maybe<AccountAccountCategorySetInput>;
  pk_columns: AccountAccountCategoryPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAccountAccountTypeArgs = {
  _set?: Maybe<AccountAccountTypeSetInput>;
  where: AccountAccountTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateAccountAccountTypeByPkArgs = {
  _set?: Maybe<AccountAccountTypeSetInput>;
  pk_columns: AccountAccountTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAccountBudgetAccountArgs = {
  _inc?: Maybe<AccountBudgetAccountIncInput>;
  _set?: Maybe<AccountBudgetAccountSetInput>;
  where: AccountBudgetAccountBoolExp;
};


/** mutation root */
export type MutationRootUpdateAccountBudgetAccountByPkArgs = {
  _inc?: Maybe<AccountBudgetAccountIncInput>;
  _set?: Maybe<AccountBudgetAccountSetInput>;
  pk_columns: AccountBudgetAccountPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAccountBudgetAllocationArgs = {
  _inc?: Maybe<AccountBudgetAllocationIncInput>;
  _set?: Maybe<AccountBudgetAllocationSetInput>;
  where: AccountBudgetAllocationBoolExp;
};


/** mutation root */
export type MutationRootUpdateAccountBudgetAllocationByPkArgs = {
  _inc?: Maybe<AccountBudgetAllocationIncInput>;
  _set?: Maybe<AccountBudgetAllocationSetInput>;
  pk_columns: AccountBudgetAllocationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAccountBudgetAllocationLogArgs = {
  _inc?: Maybe<AccountBudgetAllocationLogIncInput>;
  _set?: Maybe<AccountBudgetAllocationLogSetInput>;
  where: AccountBudgetAllocationLogBoolExp;
};


/** mutation root */
export type MutationRootUpdateAccountBudgetAllocationLogByPkArgs = {
  _inc?: Maybe<AccountBudgetAllocationLogIncInput>;
  _set?: Maybe<AccountBudgetAllocationLogSetInput>;
  pk_columns: AccountBudgetAllocationLogPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAccountBudgetTypeArgs = {
  _inc?: Maybe<AccountBudgetTypeIncInput>;
  _set?: Maybe<AccountBudgetTypeSetInput>;
  where: AccountBudgetTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateAccountBudgetTypeByPkArgs = {
  _inc?: Maybe<AccountBudgetTypeIncInput>;
  _set?: Maybe<AccountBudgetTypeSetInput>;
  pk_columns: AccountBudgetTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAccountEntryTypeArgs = {
  _set?: Maybe<AccountEntryTypeSetInput>;
  where: AccountEntryTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateAccountEntryTypeByPkArgs = {
  _set?: Maybe<AccountEntryTypeSetInput>;
  pk_columns: AccountEntryTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAccountLedgerEntryArgs = {
  _inc?: Maybe<AccountLedgerEntryIncInput>;
  _set?: Maybe<AccountLedgerEntrySetInput>;
  where: AccountLedgerEntryBoolExp;
};


/** mutation root */
export type MutationRootUpdateAccountLedgerEntryByPkArgs = {
  _inc?: Maybe<AccountLedgerEntryIncInput>;
  _set?: Maybe<AccountLedgerEntrySetInput>;
  pk_columns: AccountLedgerEntryPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAccountLendingArgs = {
  _inc?: Maybe<AccountLendingIncInput>;
  _set?: Maybe<AccountLendingSetInput>;
  where: AccountLendingBoolExp;
};


/** mutation root */
export type MutationRootUpdateAccountLendingByPkArgs = {
  _inc?: Maybe<AccountLendingIncInput>;
  _set?: Maybe<AccountLendingSetInput>;
  pk_columns: AccountLendingPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAccountLendingItemArgs = {
  _inc?: Maybe<AccountLendingItemIncInput>;
  _set?: Maybe<AccountLendingItemSetInput>;
  where: AccountLendingItemBoolExp;
};


/** mutation root */
export type MutationRootUpdateAccountLendingItemByPkArgs = {
  _inc?: Maybe<AccountLendingItemIncInput>;
  _set?: Maybe<AccountLendingItemSetInput>;
  pk_columns: AccountLendingItemPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAccountLendingStatusArgs = {
  _set?: Maybe<AccountLendingStatusSetInput>;
  where: AccountLendingStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateAccountLendingStatusByPkArgs = {
  _set?: Maybe<AccountLendingStatusSetInput>;
  pk_columns: AccountLendingStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAccountTransactionArgs = {
  _inc?: Maybe<AccountTransactionIncInput>;
  _set?: Maybe<AccountTransactionSetInput>;
  where: AccountTransactionBoolExp;
};


/** mutation root */
export type MutationRootUpdateAccountTransactionByPkArgs = {
  _inc?: Maybe<AccountTransactionIncInput>;
  _set?: Maybe<AccountTransactionSetInput>;
  pk_columns: AccountTransactionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAccountTransactionLogArgs = {
  _set?: Maybe<AccountTransactionLogSetInput>;
  where: AccountTransactionLogBoolExp;
};


/** mutation root */
export type MutationRootUpdateAccountTransactionLogByPkArgs = {
  _set?: Maybe<AccountTransactionLogSetInput>;
  pk_columns: AccountTransactionLogPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAccountTransactionStatusArgs = {
  _set?: Maybe<AccountTransactionStatusSetInput>;
  where: AccountTransactionStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateAccountTransactionStatusByPkArgs = {
  _set?: Maybe<AccountTransactionStatusSetInput>;
  pk_columns: AccountTransactionStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAccountTransactionTypeArgs = {
  _set?: Maybe<AccountTransactionTypeSetInput>;
  where: AccountTransactionTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateAccountTransactionTypeByPkArgs = {
  _set?: Maybe<AccountTransactionTypeSetInput>;
  pk_columns: AccountTransactionTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAttachmentArgs = {
  _set?: Maybe<AttachmentSetInput>;
  where: AttachmentBoolExp;
};


/** mutation root */
export type MutationRootUpdateAttachmentByPkArgs = {
  _set?: Maybe<AttachmentSetInput>;
  pk_columns: AttachmentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuditLoggedActionsArgs = {
  _append?: Maybe<AuditLoggedActionsAppendInput>;
  _delete_at_path?: Maybe<AuditLoggedActionsDeleteAtPathInput>;
  _delete_elem?: Maybe<AuditLoggedActionsDeleteElemInput>;
  _delete_key?: Maybe<AuditLoggedActionsDeleteKeyInput>;
  _inc?: Maybe<AuditLoggedActionsIncInput>;
  _prepend?: Maybe<AuditLoggedActionsPrependInput>;
  _set?: Maybe<AuditLoggedActionsSetInput>;
  where: AuditLoggedActionsBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuditLoggedActionsByPkArgs = {
  _append?: Maybe<AuditLoggedActionsAppendInput>;
  _delete_at_path?: Maybe<AuditLoggedActionsDeleteAtPathInput>;
  _delete_elem?: Maybe<AuditLoggedActionsDeleteElemInput>;
  _delete_key?: Maybe<AuditLoggedActionsDeleteKeyInput>;
  _inc?: Maybe<AuditLoggedActionsIncInput>;
  _prepend?: Maybe<AuditLoggedActionsPrependInput>;
  _set?: Maybe<AuditLoggedActionsSetInput>;
  pk_columns: AuditLoggedActionsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthAccountProvidersArgs = {
  _set?: Maybe<AuthAccountProvidersSetInput>;
  where: AuthAccountProvidersBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthAccountProvidersByPkArgs = {
  _set?: Maybe<AuthAccountProvidersSetInput>;
  pk_columns: AuthAccountProvidersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthAccountRolesArgs = {
  _set?: Maybe<AuthAccountRolesSetInput>;
  where: AuthAccountRolesBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthAccountRolesByPkArgs = {
  _set?: Maybe<AuthAccountRolesSetInput>;
  pk_columns: AuthAccountRolesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthAccountsArgs = {
  _append?: Maybe<AuthAccountsAppendInput>;
  _delete_at_path?: Maybe<AuthAccountsDeleteAtPathInput>;
  _delete_elem?: Maybe<AuthAccountsDeleteElemInput>;
  _delete_key?: Maybe<AuthAccountsDeleteKeyInput>;
  _prepend?: Maybe<AuthAccountsPrependInput>;
  _set?: Maybe<AuthAccountsSetInput>;
  where: AuthAccountsBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthAccountsByPkArgs = {
  _append?: Maybe<AuthAccountsAppendInput>;
  _delete_at_path?: Maybe<AuthAccountsDeleteAtPathInput>;
  _delete_elem?: Maybe<AuthAccountsDeleteElemInput>;
  _delete_key?: Maybe<AuthAccountsDeleteKeyInput>;
  _prepend?: Maybe<AuthAccountsPrependInput>;
  _set?: Maybe<AuthAccountsSetInput>;
  pk_columns: AuthAccountsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthProvidersArgs = {
  _set?: Maybe<AuthProvidersSetInput>;
  where: AuthProvidersBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthProvidersByPkArgs = {
  _set?: Maybe<AuthProvidersSetInput>;
  pk_columns: AuthProvidersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthRefreshTokensArgs = {
  _set?: Maybe<AuthRefreshTokensSetInput>;
  where: AuthRefreshTokensBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthRefreshTokensByPkArgs = {
  _set?: Maybe<AuthRefreshTokensSetInput>;
  pk_columns: AuthRefreshTokensPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthRolesArgs = {
  _set?: Maybe<AuthRolesSetInput>;
  where: AuthRolesBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthRolesByPkArgs = {
  _set?: Maybe<AuthRolesSetInput>;
  pk_columns: AuthRolesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateBankBranchArgs = {
  _set?: Maybe<BankBranchSetInput>;
  where: BankBranchBoolExp;
};


/** mutation root */
export type MutationRootUpdateBankBranchByPkArgs = {
  _set?: Maybe<BankBranchSetInput>;
  pk_columns: BankBranchPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateContractAfStatusArgs = {
  _set?: Maybe<ContractAfStatusSetInput>;
  where: ContractAfStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateContractAfStatusByPkArgs = {
  _set?: Maybe<ContractAfStatusSetInput>;
  pk_columns: ContractAfStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateContractAgreementArgs = {
  _inc?: Maybe<ContractAgreementIncInput>;
  _set?: Maybe<ContractAgreementSetInput>;
  where: ContractAgreementBoolExp;
};


/** mutation root */
export type MutationRootUpdateContractAgreementAttachmentArgs = {
  _inc?: Maybe<ContractAgreementAttachmentIncInput>;
  _set?: Maybe<ContractAgreementAttachmentSetInput>;
  where: ContractAgreementAttachmentBoolExp;
};


/** mutation root */
export type MutationRootUpdateContractAgreementAttachmentByPkArgs = {
  _inc?: Maybe<ContractAgreementAttachmentIncInput>;
  _set?: Maybe<ContractAgreementAttachmentSetInput>;
  pk_columns: ContractAgreementAttachmentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateContractAgreementByPkArgs = {
  _inc?: Maybe<ContractAgreementIncInput>;
  _set?: Maybe<ContractAgreementSetInput>;
  pk_columns: ContractAgreementPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateContractAgreementDetailArgs = {
  _inc?: Maybe<ContractAgreementDetailIncInput>;
  _set?: Maybe<ContractAgreementDetailSetInput>;
  where: ContractAgreementDetailBoolExp;
};


/** mutation root */
export type MutationRootUpdateContractAgreementDetailByPkArgs = {
  _inc?: Maybe<ContractAgreementDetailIncInput>;
  _set?: Maybe<ContractAgreementDetailSetInput>;
  pk_columns: ContractAgreementDetailPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateContractAgreementDisbursementArgs = {
  _inc?: Maybe<ContractAgreementDisbursementIncInput>;
  _set?: Maybe<ContractAgreementDisbursementSetInput>;
  where: ContractAgreementDisbursementBoolExp;
};


/** mutation root */
export type MutationRootUpdateContractAgreementDisbursementByPkArgs = {
  _inc?: Maybe<ContractAgreementDisbursementIncInput>;
  _set?: Maybe<ContractAgreementDisbursementSetInput>;
  pk_columns: ContractAgreementDisbursementPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateContractAgreementFeeChargeArgs = {
  _inc?: Maybe<ContractAgreementFeeChargeIncInput>;
  _set?: Maybe<ContractAgreementFeeChargeSetInput>;
  where: ContractAgreementFeeChargeBoolExp;
};


/** mutation root */
export type MutationRootUpdateContractAgreementFeeChargeByPkArgs = {
  _inc?: Maybe<ContractAgreementFeeChargeIncInput>;
  _set?: Maybe<ContractAgreementFeeChargeSetInput>;
  pk_columns: ContractAgreementFeeChargePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateContractAgreementGuarantorArgs = {
  _set?: Maybe<ContractAgreementGuarantorSetInput>;
  where: ContractAgreementGuarantorBoolExp;
};


/** mutation root */
export type MutationRootUpdateContractAgreementGuarantorByPkArgs = {
  _set?: Maybe<ContractAgreementGuarantorSetInput>;
  pk_columns: ContractAgreementGuarantorPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateContractAgreementReceiptArgs = {
  _inc?: Maybe<ContractAgreementReceiptIncInput>;
  _set?: Maybe<ContractAgreementReceiptSetInput>;
  where: ContractAgreementReceiptBoolExp;
};


/** mutation root */
export type MutationRootUpdateContractAgreementReceiptByPkArgs = {
  _inc?: Maybe<ContractAgreementReceiptIncInput>;
  _set?: Maybe<ContractAgreementReceiptSetInput>;
  pk_columns: ContractAgreementReceiptPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateContractAgreementStatusArgs = {
  _set?: Maybe<ContractAgreementStatusSetInput>;
  where: ContractAgreementStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateContractAgreementStatusByPkArgs = {
  _set?: Maybe<ContractAgreementStatusSetInput>;
  pk_columns: ContractAgreementStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateContractAgreementStatusMapperArgs = {
  _set?: Maybe<ContractAgreementStatusMapperSetInput>;
  where: ContractAgreementStatusMapperBoolExp;
};


/** mutation root */
export type MutationRootUpdateContractAgreementStatusMapperByPkArgs = {
  _set?: Maybe<ContractAgreementStatusMapperSetInput>;
  pk_columns: ContractAgreementStatusMapperPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateContractAgreementTransactionArgs = {
  _inc?: Maybe<ContractAgreementTransactionIncInput>;
  _set?: Maybe<ContractAgreementTransactionSetInput>;
  where: ContractAgreementTransactionBoolExp;
};


/** mutation root */
export type MutationRootUpdateContractAgreementTransactionByPkArgs = {
  _inc?: Maybe<ContractAgreementTransactionIncInput>;
  _set?: Maybe<ContractAgreementTransactionSetInput>;
  pk_columns: ContractAgreementTransactionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateContractAgreementWithdrawArgs = {
  _inc?: Maybe<ContractAgreementWithdrawIncInput>;
  _set?: Maybe<ContractAgreementWithdrawSetInput>;
  where: ContractAgreementWithdrawBoolExp;
};


/** mutation root */
export type MutationRootUpdateContractAgreementWithdrawByPkArgs = {
  _inc?: Maybe<ContractAgreementWithdrawIncInput>;
  _set?: Maybe<ContractAgreementWithdrawSetInput>;
  pk_columns: ContractAgreementWithdrawPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateContractAttachmentTypeArgs = {
  _set?: Maybe<ContractAttachmentTypeSetInput>;
  where: ContractAttachmentTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateContractAttachmentTypeByPkArgs = {
  _set?: Maybe<ContractAttachmentTypeSetInput>;
  pk_columns: ContractAttachmentTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateContractGradeArgs = {
  _set?: Maybe<ContractGradeSetInput>;
  where: ContractGradeBoolExp;
};


/** mutation root */
export type MutationRootUpdateContractGradeByPkArgs = {
  _set?: Maybe<ContractGradeSetInput>;
  pk_columns: ContractGradePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateContractTransactionCreatorTypeArgs = {
  _set?: Maybe<ContractTransactionCreatorTypeSetInput>;
  where: ContractTransactionCreatorTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateContractTransactionCreatorTypeByPkArgs = {
  _set?: Maybe<ContractTransactionCreatorTypeSetInput>;
  pk_columns: ContractTransactionCreatorTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCurrentUserPermissionsArgs = {
  _set?: Maybe<CurrentUserPermissionsSetInput>;
  where: CurrentUserPermissionsBoolExp;
};


/** mutation root */
export type MutationRootUpdateDepartmentArgs = {
  _set?: Maybe<DepartmentSetInput>;
  where: DepartmentBoolExp;
};


/** mutation root */
export type MutationRootUpdateDepartmentByPkArgs = {
  _set?: Maybe<DepartmentSetInput>;
  pk_columns: DepartmentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFeatureToggleArgs = {
  _set?: Maybe<FeatureToggleSetInput>;
  where: FeatureToggleBoolExp;
};


/** mutation root */
export type MutationRootUpdateFeatureToggleByPkArgs = {
  _set?: Maybe<FeatureToggleSetInput>;
  pk_columns: FeatureTogglePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFiscalYearArgs = {
  _inc?: Maybe<FiscalYearIncInput>;
  _set?: Maybe<FiscalYearSetInput>;
  where: FiscalYearBoolExp;
};


/** mutation root */
export type MutationRootUpdateFiscalYearByPkArgs = {
  _inc?: Maybe<FiscalYearIncInput>;
  _set?: Maybe<FiscalYearSetInput>;
  pk_columns: FiscalYearPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateImportLogArgs = {
  _set?: Maybe<ImportLogSetInput>;
  where: ImportLogBoolExp;
};


/** mutation root */
export type MutationRootUpdateImportLogByPkArgs = {
  _set?: Maybe<ImportLogSetInput>;
  pk_columns: ImportLogPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateJuristicArgs = {
  _inc?: Maybe<JuristicIncInput>;
  _set?: Maybe<JuristicSetInput>;
  where: JuristicBoolExp;
};


/** mutation root */
export type MutationRootUpdateJuristicAddressArgs = {
  _inc?: Maybe<JuristicAddressIncInput>;
  _set?: Maybe<JuristicAddressSetInput>;
  where: JuristicAddressBoolExp;
};


/** mutation root */
export type MutationRootUpdateJuristicAddressByPkArgs = {
  _inc?: Maybe<JuristicAddressIncInput>;
  _set?: Maybe<JuristicAddressSetInput>;
  pk_columns: JuristicAddressPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateJuristicByPkArgs = {
  _inc?: Maybe<JuristicIncInput>;
  _set?: Maybe<JuristicSetInput>;
  pk_columns: JuristicPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateJuristicCommitteeArgs = {
  _inc?: Maybe<JuristicCommitteeIncInput>;
  _set?: Maybe<JuristicCommitteeSetInput>;
  where: JuristicCommitteeBoolExp;
};


/** mutation root */
export type MutationRootUpdateJuristicCommitteeByPkArgs = {
  _inc?: Maybe<JuristicCommitteeIncInput>;
  _set?: Maybe<JuristicCommitteeSetInput>;
  pk_columns: JuristicCommitteePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateJuristicStandardObjectiveArgs = {
  _inc?: Maybe<JuristicStandardObjectiveIncInput>;
  _set?: Maybe<JuristicStandardObjectiveSetInput>;
  where: JuristicStandardObjectiveBoolExp;
};


/** mutation root */
export type MutationRootUpdateJuristicStandardObjectiveByPkArgs = {
  _inc?: Maybe<JuristicStandardObjectiveIncInput>;
  _set?: Maybe<JuristicStandardObjectiveSetInput>;
  pk_columns: JuristicStandardObjectivePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLedgerBudgetAllocationArgs = {
  _inc?: Maybe<LedgerBudgetAllocationIncInput>;
  _set?: Maybe<LedgerBudgetAllocationSetInput>;
  where: LedgerBudgetAllocationBoolExp;
};


/** mutation root */
export type MutationRootUpdateLedgerBudgetAllocationByPkArgs = {
  _inc?: Maybe<LedgerBudgetAllocationIncInput>;
  _set?: Maybe<LedgerBudgetAllocationSetInput>;
  pk_columns: LedgerBudgetAllocationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLedgerLedgerArgs = {
  _inc?: Maybe<LedgerLedgerIncInput>;
  _set?: Maybe<LedgerLedgerSetInput>;
  where: LedgerLedgerBoolExp;
};


/** mutation root */
export type MutationRootUpdateLedgerLedgerAttachmentArgs = {
  _set?: Maybe<LedgerLedgerAttachmentSetInput>;
  where: LedgerLedgerAttachmentBoolExp;
};


/** mutation root */
export type MutationRootUpdateLedgerLedgerAttachmentByPkArgs = {
  _set?: Maybe<LedgerLedgerAttachmentSetInput>;
  pk_columns: LedgerLedgerAttachmentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLedgerLedgerByPkArgs = {
  _inc?: Maybe<LedgerLedgerIncInput>;
  _set?: Maybe<LedgerLedgerSetInput>;
  pk_columns: LedgerLedgerPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLedgerLedgerLogArgs = {
  _set?: Maybe<LedgerLedgerLogSetInput>;
  where: LedgerLedgerLogBoolExp;
};


/** mutation root */
export type MutationRootUpdateLedgerLedgerLogByPkArgs = {
  _set?: Maybe<LedgerLedgerLogSetInput>;
  pk_columns: LedgerLedgerLogPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLedgerLedgerStatusArgs = {
  _set?: Maybe<LedgerLedgerStatusSetInput>;
  where: LedgerLedgerStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateLedgerLedgerStatusByPkArgs = {
  _set?: Maybe<LedgerLedgerStatusSetInput>;
  pk_columns: LedgerLedgerStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLedgerLedgerTypeArgs = {
  _set?: Maybe<LedgerLedgerTypeSetInput>;
  where: LedgerLedgerTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateLedgerLedgerTypeByPkArgs = {
  _set?: Maybe<LedgerLedgerTypeSetInput>;
  pk_columns: LedgerLedgerTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLedgerReceivableArgs = {
  _inc?: Maybe<LedgerReceivableIncInput>;
  _set?: Maybe<LedgerReceivableSetInput>;
  where: LedgerReceivableBoolExp;
};


/** mutation root */
export type MutationRootUpdateLedgerReceivableByPkArgs = {
  _inc?: Maybe<LedgerReceivableIncInput>;
  _set?: Maybe<LedgerReceivableSetInput>;
  pk_columns: LedgerReceivablePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLedgerReceivableItemArgs = {
  _inc?: Maybe<LedgerReceivableItemIncInput>;
  _set?: Maybe<LedgerReceivableItemSetInput>;
  where: LedgerReceivableItemBoolExp;
};


/** mutation root */
export type MutationRootUpdateLedgerReceivableItemByPkArgs = {
  _inc?: Maybe<LedgerReceivableItemIncInput>;
  _set?: Maybe<LedgerReceivableItemSetInput>;
  pk_columns: LedgerReceivableItemPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLedgerReceivableLogArgs = {
  _set?: Maybe<LedgerReceivableLogSetInput>;
  where: LedgerReceivableLogBoolExp;
};


/** mutation root */
export type MutationRootUpdateLedgerReceivableLogByPkArgs = {
  _set?: Maybe<LedgerReceivableLogSetInput>;
  pk_columns: LedgerReceivableLogPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLedgerReceivableStatusArgs = {
  _set?: Maybe<LedgerReceivableStatusSetInput>;
  where: LedgerReceivableStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateLedgerReceivableStatusByPkArgs = {
  _set?: Maybe<LedgerReceivableStatusSetInput>;
  pk_columns: LedgerReceivableStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanAccountTypeArgs = {
  _inc?: Maybe<LoanAccountTypeIncInput>;
  _set?: Maybe<LoanAccountTypeSetInput>;
  where: LoanAccountTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanAccountTypeByPkArgs = {
  _inc?: Maybe<LoanAccountTypeIncInput>;
  _set?: Maybe<LoanAccountTypeSetInput>;
  pk_columns: LoanAccountTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationAttachmentArgs = {
  _inc?: Maybe<LoanApplicationAttachmentIncInput>;
  _set?: Maybe<LoanApplicationAttachmentSetInput>;
  where: LoanApplicationAttachmentBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationAttachmentByPkArgs = {
  _inc?: Maybe<LoanApplicationAttachmentIncInput>;
  _set?: Maybe<LoanApplicationAttachmentSetInput>;
  pk_columns: LoanApplicationAttachmentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationBankBranchTransferArgs = {
  _set?: Maybe<LoanApplicationBankBranchTransferSetInput>;
  where: LoanApplicationBankBranchTransferBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationBankBranchTransferByPkArgs = {
  _set?: Maybe<LoanApplicationBankBranchTransferSetInput>;
  pk_columns: LoanApplicationBankBranchTransferPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationBusinessOperationArgs = {
  _set?: Maybe<LoanApplicationBusinessOperationSetInput>;
  where: LoanApplicationBusinessOperationBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationBusinessOperationByPkArgs = {
  _set?: Maybe<LoanApplicationBusinessOperationSetInput>;
  pk_columns: LoanApplicationBusinessOperationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationCommitteeArgs = {
  _inc?: Maybe<LoanApplicationCommitteeIncInput>;
  _set?: Maybe<LoanApplicationCommitteeSetInput>;
  where: LoanApplicationCommitteeBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationCommitteeByPkArgs = {
  _inc?: Maybe<LoanApplicationCommitteeIncInput>;
  _set?: Maybe<LoanApplicationCommitteeSetInput>;
  pk_columns: LoanApplicationCommitteePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationCurrentLoanArgs = {
  _inc?: Maybe<LoanApplicationCurrentLoanIncInput>;
  _set?: Maybe<LoanApplicationCurrentLoanSetInput>;
  where: LoanApplicationCurrentLoanBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationCurrentLoanByPkArgs = {
  _inc?: Maybe<LoanApplicationCurrentLoanIncInput>;
  _set?: Maybe<LoanApplicationCurrentLoanSetInput>;
  pk_columns: LoanApplicationCurrentLoanPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationDetailArgs = {
  _append?: Maybe<LoanApplicationDetailAppendInput>;
  _delete_at_path?: Maybe<LoanApplicationDetailDeleteAtPathInput>;
  _delete_elem?: Maybe<LoanApplicationDetailDeleteElemInput>;
  _delete_key?: Maybe<LoanApplicationDetailDeleteKeyInput>;
  _inc?: Maybe<LoanApplicationDetailIncInput>;
  _prepend?: Maybe<LoanApplicationDetailPrependInput>;
  _set?: Maybe<LoanApplicationDetailSetInput>;
  where: LoanApplicationDetailBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationDetailByPkArgs = {
  _append?: Maybe<LoanApplicationDetailAppendInput>;
  _delete_at_path?: Maybe<LoanApplicationDetailDeleteAtPathInput>;
  _delete_elem?: Maybe<LoanApplicationDetailDeleteElemInput>;
  _delete_key?: Maybe<LoanApplicationDetailDeleteKeyInput>;
  _inc?: Maybe<LoanApplicationDetailIncInput>;
  _prepend?: Maybe<LoanApplicationDetailPrependInput>;
  _set?: Maybe<LoanApplicationDetailSetInput>;
  pk_columns: LoanApplicationDetailPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationEventLogArgs = {
  _set?: Maybe<LoanApplicationEventLogSetInput>;
  where: LoanApplicationEventLogBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationEventLogByPkArgs = {
  _set?: Maybe<LoanApplicationEventLogSetInput>;
  pk_columns: LoanApplicationEventLogPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationHeaderArgs = {
  _inc?: Maybe<LoanApplicationHeaderIncInput>;
  _set?: Maybe<LoanApplicationHeaderSetInput>;
  where: LoanApplicationHeaderBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationHeaderByPkArgs = {
  _inc?: Maybe<LoanApplicationHeaderIncInput>;
  _set?: Maybe<LoanApplicationHeaderSetInput>;
  pk_columns: LoanApplicationHeaderPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationHeaderPdfArgs = {
  _set?: Maybe<LoanApplicationHeaderPdfSetInput>;
  where: LoanApplicationHeaderPdfBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationHeaderPdfByPkArgs = {
  _set?: Maybe<LoanApplicationHeaderPdfSetInput>;
  pk_columns: LoanApplicationHeaderPdfPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationObjectiveArgs = {
  _inc?: Maybe<LoanApplicationObjectiveIncInput>;
  _set?: Maybe<LoanApplicationObjectiveSetInput>;
  where: LoanApplicationObjectiveBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationObjectiveByPkArgs = {
  _inc?: Maybe<LoanApplicationObjectiveIncInput>;
  _set?: Maybe<LoanApplicationObjectiveSetInput>;
  pk_columns: LoanApplicationObjectivePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationOrganizationTransferArgs = {
  _set?: Maybe<LoanApplicationOrganizationTransferSetInput>;
  where: LoanApplicationOrganizationTransferBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationOrganizationTransferByPkArgs = {
  _set?: Maybe<LoanApplicationOrganizationTransferSetInput>;
  pk_columns: LoanApplicationOrganizationTransferPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationProfileArgs = {
  _append?: Maybe<LoanApplicationProfileAppendInput>;
  _delete_at_path?: Maybe<LoanApplicationProfileDeleteAtPathInput>;
  _delete_elem?: Maybe<LoanApplicationProfileDeleteElemInput>;
  _delete_key?: Maybe<LoanApplicationProfileDeleteKeyInput>;
  _prepend?: Maybe<LoanApplicationProfilePrependInput>;
  _set?: Maybe<LoanApplicationProfileSetInput>;
  where: LoanApplicationProfileBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationProfileByPkArgs = {
  _append?: Maybe<LoanApplicationProfileAppendInput>;
  _delete_at_path?: Maybe<LoanApplicationProfileDeleteAtPathInput>;
  _delete_elem?: Maybe<LoanApplicationProfileDeleteElemInput>;
  _delete_key?: Maybe<LoanApplicationProfileDeleteKeyInput>;
  _prepend?: Maybe<LoanApplicationProfilePrependInput>;
  _set?: Maybe<LoanApplicationProfileSetInput>;
  pk_columns: LoanApplicationProfilePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationProfileSCurveArgs = {
  _set?: Maybe<LoanApplicationProfileSCurveSetInput>;
  where: LoanApplicationProfileSCurveBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationProfileSCurveByPkArgs = {
  _set?: Maybe<LoanApplicationProfileSCurveSetInput>;
  pk_columns: LoanApplicationProfileSCurvePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationSequenceNoArgs = {
  _inc?: Maybe<LoanApplicationSequenceNoIncInput>;
  _set?: Maybe<LoanApplicationSequenceNoSetInput>;
  where: LoanApplicationSequenceNoBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationSequenceNoByPkArgs = {
  _inc?: Maybe<LoanApplicationSequenceNoIncInput>;
  _set?: Maybe<LoanApplicationSequenceNoSetInput>;
  pk_columns: LoanApplicationSequenceNoPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationStepArgs = {
  _inc?: Maybe<LoanApplicationStepIncInput>;
  _set?: Maybe<LoanApplicationStepSetInput>;
  where: LoanApplicationStepBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanApplicationStepByPkArgs = {
  _inc?: Maybe<LoanApplicationStepIncInput>;
  _set?: Maybe<LoanApplicationStepSetInput>;
  pk_columns: LoanApplicationStepPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanAttachmentTypeArgs = {
  _inc?: Maybe<LoanAttachmentTypeIncInput>;
  _set?: Maybe<LoanAttachmentTypeSetInput>;
  where: LoanAttachmentTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanAttachmentTypeByPkArgs = {
  _inc?: Maybe<LoanAttachmentTypeIncInput>;
  _set?: Maybe<LoanAttachmentTypeSetInput>;
  pk_columns: LoanAttachmentTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanBankApproveArgs = {
  _set?: Maybe<LoanBankApproveSetInput>;
  where: LoanBankApproveBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanBankApproveByPkArgs = {
  _set?: Maybe<LoanBankApproveSetInput>;
  pk_columns: LoanBankApprovePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanBusinessGroupArgs = {
  _inc?: Maybe<LoanBusinessGroupIncInput>;
  _set?: Maybe<LoanBusinessGroupSetInput>;
  where: LoanBusinessGroupBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanBusinessGroupByPkArgs = {
  _inc?: Maybe<LoanBusinessGroupIncInput>;
  _set?: Maybe<LoanBusinessGroupSetInput>;
  pk_columns: LoanBusinessGroupPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanBusinessOperationArgs = {
  _inc?: Maybe<LoanBusinessOperationIncInput>;
  _set?: Maybe<LoanBusinessOperationSetInput>;
  where: LoanBusinessOperationBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanBusinessOperationByPkArgs = {
  _inc?: Maybe<LoanBusinessOperationIncInput>;
  _set?: Maybe<LoanBusinessOperationSetInput>;
  pk_columns: LoanBusinessOperationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanBusinessProprietaryArgs = {
  _inc?: Maybe<LoanBusinessProprietaryIncInput>;
  _set?: Maybe<LoanBusinessProprietarySetInput>;
  where: LoanBusinessProprietaryBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanBusinessProprietaryByPkArgs = {
  _inc?: Maybe<LoanBusinessProprietaryIncInput>;
  _set?: Maybe<LoanBusinessProprietarySetInput>;
  pk_columns: LoanBusinessProprietaryPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanBusinessStatusArgs = {
  _inc?: Maybe<LoanBusinessStatusIncInput>;
  _set?: Maybe<LoanBusinessStatusSetInput>;
  where: LoanBusinessStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanBusinessStatusByPkArgs = {
  _inc?: Maybe<LoanBusinessStatusIncInput>;
  _set?: Maybe<LoanBusinessStatusSetInput>;
  pk_columns: LoanBusinessStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanBusinessTypeArgs = {
  _inc?: Maybe<LoanBusinessTypeIncInput>;
  _set?: Maybe<LoanBusinessTypeSetInput>;
  where: LoanBusinessTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanBusinessTypeByPkArgs = {
  _inc?: Maybe<LoanBusinessTypeIncInput>;
  _set?: Maybe<LoanBusinessTypeSetInput>;
  pk_columns: LoanBusinessTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanCommitteeApproveArgs = {
  _set?: Maybe<LoanCommitteeApproveSetInput>;
  where: LoanCommitteeApproveBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanCommitteeApproveByPkArgs = {
  _set?: Maybe<LoanCommitteeApproveSetInput>;
  pk_columns: LoanCommitteeApprovePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanCurrentLoanArgs = {
  _inc?: Maybe<LoanCurrentLoanIncInput>;
  _set?: Maybe<LoanCurrentLoanSetInput>;
  where: LoanCurrentLoanBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanCurrentLoanByPkArgs = {
  _inc?: Maybe<LoanCurrentLoanIncInput>;
  _set?: Maybe<LoanCurrentLoanSetInput>;
  pk_columns: LoanCurrentLoanPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanDebtRestructuringArgs = {
  _inc?: Maybe<LoanDebtRestructuringIncInput>;
  _set?: Maybe<LoanDebtRestructuringSetInput>;
  where: LoanDebtRestructuringBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanDebtRestructuringByPkArgs = {
  _inc?: Maybe<LoanDebtRestructuringIncInput>;
  _set?: Maybe<LoanDebtRestructuringSetInput>;
  pk_columns: LoanDebtRestructuringPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanDocumentApproveArgs = {
  _set?: Maybe<LoanDocumentApproveSetInput>;
  where: LoanDocumentApproveBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanDocumentApproveByPkArgs = {
  _set?: Maybe<LoanDocumentApproveSetInput>;
  pk_columns: LoanDocumentApprovePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanFinancialAgreementArgs = {
  _inc?: Maybe<LoanFinancialAgreementIncInput>;
  _set?: Maybe<LoanFinancialAgreementSetInput>;
  where: LoanFinancialAgreementBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanFinancialAgreementByPkArgs = {
  _inc?: Maybe<LoanFinancialAgreementIncInput>;
  _set?: Maybe<LoanFinancialAgreementSetInput>;
  pk_columns: LoanFinancialAgreementPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanFinancialRescueArgs = {
  _inc?: Maybe<LoanFinancialRescueIncInput>;
  _set?: Maybe<LoanFinancialRescueSetInput>;
  where: LoanFinancialRescueBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanFinancialRescueByPkArgs = {
  _inc?: Maybe<LoanFinancialRescueIncInput>;
  _set?: Maybe<LoanFinancialRescueSetInput>;
  pk_columns: LoanFinancialRescuePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanObjectiveArgs = {
  _inc?: Maybe<LoanObjectiveIncInput>;
  _set?: Maybe<LoanObjectiveSetInput>;
  where: LoanObjectiveBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanObjectiveByPkArgs = {
  _inc?: Maybe<LoanObjectiveIncInput>;
  _set?: Maybe<LoanObjectiveSetInput>;
  pk_columns: LoanObjectivePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanProgramArgs = {
  _inc?: Maybe<LoanProgramIncInput>;
  _set?: Maybe<LoanProgramSetInput>;
  where: LoanProgramBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanProgramByPkArgs = {
  _inc?: Maybe<LoanProgramIncInput>;
  _set?: Maybe<LoanProgramSetInput>;
  pk_columns: LoanProgramPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanProgramStatusArgs = {
  _set?: Maybe<LoanProgramStatusSetInput>;
  where: LoanProgramStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanProgramStatusByPkArgs = {
  _set?: Maybe<LoanProgramStatusSetInput>;
  pk_columns: LoanProgramStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanProgramTransactionArgs = {
  _inc?: Maybe<LoanProgramTransactionIncInput>;
  _set?: Maybe<LoanProgramTransactionSetInput>;
  where: LoanProgramTransactionBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanProgramTransactionByPkArgs = {
  _inc?: Maybe<LoanProgramTransactionIncInput>;
  _set?: Maybe<LoanProgramTransactionSetInput>;
  pk_columns: LoanProgramTransactionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanProgramTransactionTypeArgs = {
  _inc?: Maybe<LoanProgramTransactionTypeIncInput>;
  _set?: Maybe<LoanProgramTransactionTypeSetInput>;
  where: LoanProgramTransactionTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanProgramTransactionTypeByPkArgs = {
  _inc?: Maybe<LoanProgramTransactionTypeIncInput>;
  _set?: Maybe<LoanProgramTransactionTypeSetInput>;
  pk_columns: LoanProgramTransactionTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanProprietaryArgs = {
  _inc?: Maybe<LoanProprietaryIncInput>;
  _set?: Maybe<LoanProprietarySetInput>;
  where: LoanProprietaryBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanProprietaryByPkArgs = {
  _inc?: Maybe<LoanProprietaryIncInput>;
  _set?: Maybe<LoanProprietarySetInput>;
  pk_columns: LoanProprietaryPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanRepayTypeArgs = {
  _inc?: Maybe<LoanRepayTypeIncInput>;
  _set?: Maybe<LoanRepayTypeSetInput>;
  where: LoanRepayTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanRepayTypeByPkArgs = {
  _inc?: Maybe<LoanRepayTypeIncInput>;
  _set?: Maybe<LoanRepayTypeSetInput>;
  pk_columns: LoanRepayTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanSCurveArgs = {
  _inc?: Maybe<LoanSCurveIncInput>;
  _set?: Maybe<LoanSCurveSetInput>;
  where: LoanSCurveBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanSCurveByPkArgs = {
  _inc?: Maybe<LoanSCurveIncInput>;
  _set?: Maybe<LoanSCurveSetInput>;
  pk_columns: LoanSCurvePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanSCurveTypeArgs = {
  _inc?: Maybe<LoanSCurveTypeIncInput>;
  _set?: Maybe<LoanSCurveTypeSetInput>;
  where: LoanSCurveTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanSCurveTypeByPkArgs = {
  _inc?: Maybe<LoanSCurveTypeIncInput>;
  _set?: Maybe<LoanSCurveTypeSetInput>;
  pk_columns: LoanSCurveTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanSmeDefinitionArgs = {
  _inc?: Maybe<LoanSmeDefinitionIncInput>;
  _set?: Maybe<LoanSmeDefinitionSetInput>;
  where: LoanSmeDefinitionBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanSmeDefinitionByPkArgs = {
  _inc?: Maybe<LoanSmeDefinitionIncInput>;
  _set?: Maybe<LoanSmeDefinitionSetInput>;
  pk_columns: LoanSmeDefinitionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanSourceBusinessTypeArgs = {
  _set?: Maybe<LoanSourceBusinessTypeSetInput>;
  where: LoanSourceBusinessTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanSourceBusinessTypeByPkArgs = {
  _set?: Maybe<LoanSourceBusinessTypeSetInput>;
  pk_columns: LoanSourceBusinessTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanSourceFinancialInstitutionArgs = {
  _set?: Maybe<LoanSourceFinancialInstitutionSetInput>;
  where: LoanSourceFinancialInstitutionBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanSourceFinancialInstitutionByPkArgs = {
  _set?: Maybe<LoanSourceFinancialInstitutionSetInput>;
  pk_columns: LoanSourceFinancialInstitutionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanSourceLoanArgs = {
  _inc?: Maybe<LoanSourceLoanIncInput>;
  _set?: Maybe<LoanSourceLoanSetInput>;
  where: LoanSourceLoanBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanSourceLoanAttachmentArgs = {
  _inc?: Maybe<LoanSourceLoanAttachmentIncInput>;
  _set?: Maybe<LoanSourceLoanAttachmentSetInput>;
  where: LoanSourceLoanAttachmentBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanSourceLoanAttachmentByPkArgs = {
  _inc?: Maybe<LoanSourceLoanAttachmentIncInput>;
  _set?: Maybe<LoanSourceLoanAttachmentSetInput>;
  pk_columns: LoanSourceLoanAttachmentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanSourceLoanBusinessTypeArgs = {
  _set?: Maybe<LoanSourceLoanBusinessTypeSetInput>;
  where: LoanSourceLoanBusinessTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanSourceLoanBusinessTypeByPkArgs = {
  _set?: Maybe<LoanSourceLoanBusinessTypeSetInput>;
  pk_columns: LoanSourceLoanBusinessTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanSourceLoanByPkArgs = {
  _inc?: Maybe<LoanSourceLoanIncInput>;
  _set?: Maybe<LoanSourceLoanSetInput>;
  pk_columns: LoanSourceLoanPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanSourceObjectiveArgs = {
  _inc?: Maybe<LoanSourceObjectiveIncInput>;
  _set?: Maybe<LoanSourceObjectiveSetInput>;
  where: LoanSourceObjectiveBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanSourceObjectiveByPkArgs = {
  _inc?: Maybe<LoanSourceObjectiveIncInput>;
  _set?: Maybe<LoanSourceObjectiveSetInput>;
  pk_columns: LoanSourceObjectivePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanSourceSearchLogArgs = {
  _set?: Maybe<LoanSourceSearchLogSetInput>;
  where: LoanSourceSearchLogBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanSourceSearchLogByPkArgs = {
  _set?: Maybe<LoanSourceSearchLogSetInput>;
  pk_columns: LoanSourceSearchLogPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLoanTransferStatusArgs = {
  _set?: Maybe<LoanTransferStatusSetInput>;
  where: LoanTransferStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateLoanTransferStatusByPkArgs = {
  _set?: Maybe<LoanTransferStatusSetInput>;
  pk_columns: LoanTransferStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMeArgs = {
  _inc?: Maybe<MeIncInput>;
  _set?: Maybe<MeSetInput>;
  where: MeBoolExp;
};


/** mutation root */
export type MutationRootUpdateNameTitleArgs = {
  _inc?: Maybe<NameTitleIncInput>;
  _set?: Maybe<NameTitleSetInput>;
  where: NameTitleBoolExp;
};


/** mutation root */
export type MutationRootUpdateNameTitleByPkArgs = {
  _inc?: Maybe<NameTitleIncInput>;
  _set?: Maybe<NameTitleSetInput>;
  pk_columns: NameTitlePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateNotificationArgs = {
  _set?: Maybe<NotificationSetInput>;
  where: NotificationBoolExp;
};


/** mutation root */
export type MutationRootUpdateNotificationByPkArgs = {
  _set?: Maybe<NotificationSetInput>;
  pk_columns: NotificationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateNotificationStatusArgs = {
  _set?: Maybe<NotificationStatusSetInput>;
  where: NotificationStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateNotificationStatusByPkArgs = {
  _set?: Maybe<NotificationStatusSetInput>;
  pk_columns: NotificationStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOrganizationArgs = {
  _inc?: Maybe<OrganizationIncInput>;
  _set?: Maybe<OrganizationSetInput>;
  where: OrganizationBoolExp;
};


/** mutation root */
export type MutationRootUpdateOrganizationByPkArgs = {
  _inc?: Maybe<OrganizationIncInput>;
  _set?: Maybe<OrganizationSetInput>;
  pk_columns: OrganizationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOrganizationTypeArgs = {
  _set?: Maybe<OrganizationTypeSetInput>;
  where: OrganizationTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateOrganizationTypeByPkArgs = {
  _set?: Maybe<OrganizationTypeSetInput>;
  pk_columns: OrganizationTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdatePermissionArgs = {
  _inc?: Maybe<PermissionIncInput>;
  _set?: Maybe<PermissionSetInput>;
  where: PermissionBoolExp;
};


/** mutation root */
export type MutationRootUpdatePermissionByPkArgs = {
  _inc?: Maybe<PermissionIncInput>;
  _set?: Maybe<PermissionSetInput>;
  pk_columns: PermissionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdatePostsArgs = {
  _set?: Maybe<PostsSetInput>;
  where: PostsBoolExp;
};


/** mutation root */
export type MutationRootUpdatePostsByPkArgs = {
  _set?: Maybe<PostsSetInput>;
  pk_columns: PostsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectApprovedArgs = {
  _set?: Maybe<ProjectApprovedSetInput>;
  where: ProjectApprovedBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectApprovedByPkArgs = {
  _set?: Maybe<ProjectApprovedSetInput>;
  pk_columns: ProjectApprovedPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectAttachmentTypeArgs = {
  _inc?: Maybe<ProjectAttachmentTypeIncInput>;
  _set?: Maybe<ProjectAttachmentTypeSetInput>;
  where: ProjectAttachmentTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectAttachmentTypeByPkArgs = {
  _inc?: Maybe<ProjectAttachmentTypeIncInput>;
  _set?: Maybe<ProjectAttachmentTypeSetInput>;
  pk_columns: ProjectAttachmentTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectBudgetArgs = {
  _inc?: Maybe<ProjectBudgetIncInput>;
  _set?: Maybe<ProjectBudgetSetInput>;
  where: ProjectBudgetBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectBudgetByPkArgs = {
  _inc?: Maybe<ProjectBudgetIncInput>;
  _set?: Maybe<ProjectBudgetSetInput>;
  pk_columns: ProjectBudgetPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectBudgetGroupArgs = {
  _set?: Maybe<ProjectBudgetGroupSetInput>;
  where: ProjectBudgetGroupBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectBudgetGroupByPkArgs = {
  _set?: Maybe<ProjectBudgetGroupSetInput>;
  pk_columns: ProjectBudgetGroupPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectBudgetGroupMappingArgs = {
  _set?: Maybe<ProjectBudgetGroupMappingSetInput>;
  where: ProjectBudgetGroupMappingBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectBudgetGroupMappingByPkArgs = {
  _set?: Maybe<ProjectBudgetGroupMappingSetInput>;
  pk_columns: ProjectBudgetGroupMappingPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectIndicatorTypeArgs = {
  _set?: Maybe<ProjectIndicatorTypeSetInput>;
  where: ProjectIndicatorTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectIndicatorTypeByPkArgs = {
  _set?: Maybe<ProjectIndicatorTypeSetInput>;
  pk_columns: ProjectIndicatorTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectMasterPlanArgs = {
  _inc?: Maybe<ProjectMasterPlanIncInput>;
  _set?: Maybe<ProjectMasterPlanSetInput>;
  where: ProjectMasterPlanBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectNationalStrategyArgs = {
  _inc?: Maybe<ProjectNationalStrategyIncInput>;
  _set?: Maybe<ProjectNationalStrategySetInput>;
  where: ProjectNationalStrategyBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectNationalStrategyByPkArgs = {
  _inc?: Maybe<ProjectNationalStrategyIncInput>;
  _set?: Maybe<ProjectNationalStrategySetInput>;
  pk_columns: ProjectNationalStrategyPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectOperatingActivityProgressArgs = {
  _inc?: Maybe<ProjectOperatingActivityProgressIncInput>;
  _set?: Maybe<ProjectOperatingActivityProgressSetInput>;
  where: ProjectOperatingActivityProgressBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectOperatingActivityProgressByPkArgs = {
  _inc?: Maybe<ProjectOperatingActivityProgressIncInput>;
  _set?: Maybe<ProjectOperatingActivityProgressSetInput>;
  pk_columns: ProjectOperatingActivityProgressPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectOperatingActivityProgressRevisionArgs = {
  _inc?: Maybe<ProjectOperatingActivityProgressRevisionIncInput>;
  _set?: Maybe<ProjectOperatingActivityProgressRevisionSetInput>;
  where: ProjectOperatingActivityProgressRevisionBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectOperatingActivityProgressRevisionByPkArgs = {
  _inc?: Maybe<ProjectOperatingActivityProgressRevisionIncInput>;
  _set?: Maybe<ProjectOperatingActivityProgressRevisionSetInput>;
  pk_columns: ProjectOperatingActivityProgressRevisionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectOperatingActivityTypeArgs = {
  _set?: Maybe<ProjectOperatingActivityTypeSetInput>;
  where: ProjectOperatingActivityTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectOperatingActivityTypeByPkArgs = {
  _set?: Maybe<ProjectOperatingActivityTypeSetInput>;
  pk_columns: ProjectOperatingActivityTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectProjectArgs = {
  _inc?: Maybe<ProjectProjectIncInput>;
  _set?: Maybe<ProjectProjectSetInput>;
  where: ProjectProjectBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectProjectAchievementArgs = {
  _inc?: Maybe<ProjectProjectAchievementIncInput>;
  _set?: Maybe<ProjectProjectAchievementSetInput>;
  where: ProjectProjectAchievementBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectProjectAchievementByPkArgs = {
  _inc?: Maybe<ProjectProjectAchievementIncInput>;
  _set?: Maybe<ProjectProjectAchievementSetInput>;
  pk_columns: ProjectProjectAchievementPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectProjectAttachmentArgs = {
  _inc?: Maybe<ProjectProjectAttachmentIncInput>;
  _set?: Maybe<ProjectProjectAttachmentSetInput>;
  where: ProjectProjectAttachmentBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectProjectAttachmentByPkArgs = {
  _inc?: Maybe<ProjectProjectAttachmentIncInput>;
  _set?: Maybe<ProjectProjectAttachmentSetInput>;
  pk_columns: ProjectProjectAttachmentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectProjectBudgetItemArgs = {
  _inc?: Maybe<ProjectProjectBudgetItemIncInput>;
  _set?: Maybe<ProjectProjectBudgetItemSetInput>;
  where: ProjectProjectBudgetItemBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectProjectBudgetItemByPkArgs = {
  _inc?: Maybe<ProjectProjectBudgetItemIncInput>;
  _set?: Maybe<ProjectProjectBudgetItemSetInput>;
  pk_columns: ProjectProjectBudgetItemPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectProjectByPkArgs = {
  _inc?: Maybe<ProjectProjectIncInput>;
  _set?: Maybe<ProjectProjectSetInput>;
  pk_columns: ProjectProjectPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectProjectEventLogArgs = {
  _set?: Maybe<ProjectProjectEventLogSetInput>;
  where: ProjectProjectEventLogBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectProjectEventLogByPkArgs = {
  _set?: Maybe<ProjectProjectEventLogSetInput>;
  pk_columns: ProjectProjectEventLogPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectProjectIndicatorArgs = {
  _inc?: Maybe<ProjectProjectIndicatorIncInput>;
  _set?: Maybe<ProjectProjectIndicatorSetInput>;
  where: ProjectProjectIndicatorBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectProjectIndicatorByPkArgs = {
  _inc?: Maybe<ProjectProjectIndicatorIncInput>;
  _set?: Maybe<ProjectProjectIndicatorSetInput>;
  pk_columns: ProjectProjectIndicatorPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectProjectMemberArgs = {
  _inc?: Maybe<ProjectProjectMemberIncInput>;
  _set?: Maybe<ProjectProjectMemberSetInput>;
  where: ProjectProjectMemberBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectProjectMemberByPkArgs = {
  _inc?: Maybe<ProjectProjectMemberIncInput>;
  _set?: Maybe<ProjectProjectMemberSetInput>;
  pk_columns: ProjectProjectMemberPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectProjectObjectiveArgs = {
  _inc?: Maybe<ProjectProjectObjectiveIncInput>;
  _set?: Maybe<ProjectProjectObjectiveSetInput>;
  where: ProjectProjectObjectiveBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectProjectObjectiveByPkArgs = {
  _inc?: Maybe<ProjectProjectObjectiveIncInput>;
  _set?: Maybe<ProjectProjectObjectiveSetInput>;
  pk_columns: ProjectProjectObjectivePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectProjectOperatingActivityArgs = {
  _inc?: Maybe<ProjectProjectOperatingActivityIncInput>;
  _set?: Maybe<ProjectProjectOperatingActivitySetInput>;
  where: ProjectProjectOperatingActivityBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectProjectOperatingActivityByPkArgs = {
  _inc?: Maybe<ProjectProjectOperatingActivityIncInput>;
  _set?: Maybe<ProjectProjectOperatingActivitySetInput>;
  pk_columns: ProjectProjectOperatingActivityPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectProjectOperatingAreaArgs = {
  _inc?: Maybe<ProjectProjectOperatingAreaIncInput>;
  _set?: Maybe<ProjectProjectOperatingAreaSetInput>;
  where: ProjectProjectOperatingAreaBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectProjectOperatingAreaByPkArgs = {
  _inc?: Maybe<ProjectProjectOperatingAreaIncInput>;
  _set?: Maybe<ProjectProjectOperatingAreaSetInput>;
  pk_columns: ProjectProjectOperatingAreaPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectProjectStepArgs = {
  _inc?: Maybe<ProjectProjectStepIncInput>;
  _set?: Maybe<ProjectProjectStepSetInput>;
  where: ProjectProjectStepBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectProjectStepByPkArgs = {
  _inc?: Maybe<ProjectProjectStepIncInput>;
  _set?: Maybe<ProjectProjectStepSetInput>;
  pk_columns: ProjectProjectStepPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectProjectTargetGroupArgs = {
  _inc?: Maybe<ProjectProjectTargetGroupIncInput>;
  _set?: Maybe<ProjectProjectTargetGroupSetInput>;
  where: ProjectProjectTargetGroupBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectProjectTargetGroupByPkArgs = {
  _inc?: Maybe<ProjectProjectTargetGroupIncInput>;
  _set?: Maybe<ProjectProjectTargetGroupSetInput>;
  pk_columns: ProjectProjectTargetGroupPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectSubPlanArgs = {
  _inc?: Maybe<ProjectSubPlanIncInput>;
  _set?: Maybe<ProjectSubPlanSetInput>;
  where: ProjectSubPlanBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectTargetGroupArgs = {
  _set?: Maybe<ProjectTargetGroupSetInput>;
  where: ProjectTargetGroupBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectTargetGroupByPkArgs = {
  _set?: Maybe<ProjectTargetGroupSetInput>;
  pk_columns: ProjectTargetGroupPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProjectUnitArgs = {
  _set?: Maybe<ProjectUnitSetInput>;
  where: ProjectUnitBoolExp;
};


/** mutation root */
export type MutationRootUpdateProjectUnitByPkArgs = {
  _set?: Maybe<ProjectUnitSetInput>;
  pk_columns: ProjectUnitPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProvinceArgs = {
  _set?: Maybe<ProvinceSetInput>;
  where: ProvinceBoolExp;
};


/** mutation root */
export type MutationRootUpdateProvinceByPkArgs = {
  _set?: Maybe<ProvinceSetInput>;
  pk_columns: ProvincePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRegionArgs = {
  _set?: Maybe<RegionSetInput>;
  where: RegionBoolExp;
};


/** mutation root */
export type MutationRootUpdateRegionByPkArgs = {
  _set?: Maybe<RegionSetInput>;
  pk_columns: RegionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserMappingArgs = {
  _set?: Maybe<UserMappingSetInput>;
  where: UserMappingBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserMappingByPkArgs = {
  _set?: Maybe<UserMappingSetInput>;
  pk_columns: UserMappingPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUsersArgs = {
  _inc?: Maybe<UsersIncInput>;
  _set?: Maybe<UsersSetInput>;
  where: UsersBoolExp;
};


/** mutation root */
export type MutationRootUpdateUsersByPkArgs = {
  _inc?: Maybe<UsersIncInput>;
  _set?: Maybe<UsersSetInput>;
  pk_columns: UsersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUsersPermissionArgs = {
  _set?: Maybe<UsersPermissionSetInput>;
  where: UsersPermissionBoolExp;
};


/** mutation root */
export type MutationRootUpdateUsersPermissionByPkArgs = {
  _set?: Maybe<UsersPermissionSetInput>;
  pk_columns: UsersPermissionPkColumnsInput;
};


/** mutation root */
export type MutationRootValidateLoanApplicationArgs = {
  data: ValidateLoanApplicationInput;
};

/**
 * คำนำหน้าชื่อ
 *
 *
 * columns and relationships of "name_title"
 */
export type NameTitle = {
  __typename?: 'name_title';
  /** true = เปิดใช้งาน, false = ปิดใช้งาน */
  active: Scalars['Boolean'];
  /** คำนำหน้า */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: UsersAggregate;
};


/**
 * คำนำหน้าชื่อ
 *
 *
 * columns and relationships of "name_title"
 */
export type NameTitleUsersArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};


/**
 * คำนำหน้าชื่อ
 *
 *
 * columns and relationships of "name_title"
 */
export type NameTitleUsersAggregateArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};

/** aggregated selection of "name_title" */
export type NameTitleAggregate = {
  __typename?: 'name_title_aggregate';
  aggregate?: Maybe<NameTitleAggregateFields>;
  nodes: Array<NameTitle>;
};

/** aggregate fields of "name_title" */
export type NameTitleAggregateFields = {
  __typename?: 'name_title_aggregate_fields';
  avg?: Maybe<NameTitleAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<NameTitleMaxFields>;
  min?: Maybe<NameTitleMinFields>;
  stddev?: Maybe<NameTitleStddevFields>;
  stddev_pop?: Maybe<NameTitleStddevPopFields>;
  stddev_samp?: Maybe<NameTitleStddevSampFields>;
  sum?: Maybe<NameTitleSumFields>;
  var_pop?: Maybe<NameTitleVarPopFields>;
  var_samp?: Maybe<NameTitleVarSampFields>;
  variance?: Maybe<NameTitleVarianceFields>;
};


/** aggregate fields of "name_title" */
export type NameTitleAggregateFieldsCountArgs = {
  columns?: Maybe<Array<NameTitleSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type NameTitleAvgFields = {
  __typename?: 'name_title_avg_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "name_title". All fields are combined with a logical 'AND'. */
export type NameTitleBoolExp = {
  _and?: Maybe<Array<NameTitleBoolExp>>;
  _not?: Maybe<NameTitleBoolExp>;
  _or?: Maybe<Array<NameTitleBoolExp>>;
  active?: Maybe<BooleanComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
  users?: Maybe<UsersBoolExp>;
};

/** unique or primary key constraints on table "name_title" */
export enum NameTitleConstraint {
  /** unique or primary key constraint */
  NameTitlePkey = 'name_title_pkey'
}

/** input type for incrementing numeric columns in table "name_title" */
export type NameTitleIncInput = {
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "name_title" */
export type NameTitleInsertInput = {
  /** true = เปิดใช้งาน, false = ปิดใช้งาน */
  active?: Maybe<Scalars['Boolean']>;
  /** คำนำหน้า */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  users?: Maybe<UsersArrRelInsertInput>;
};

/** aggregate max on columns */
export type NameTitleMaxFields = {
  __typename?: 'name_title_max_fields';
  /** คำนำหน้า */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type NameTitleMinFields = {
  __typename?: 'name_title_min_fields';
  /** คำนำหน้า */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "name_title" */
export type NameTitleMutationResponse = {
  __typename?: 'name_title_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<NameTitle>;
};

/** input type for inserting object relation for remote table "name_title" */
export type NameTitleObjRelInsertInput = {
  data: NameTitleInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<NameTitleOnConflict>;
};

/** on conflict condition type for table "name_title" */
export type NameTitleOnConflict = {
  constraint: NameTitleConstraint;
  update_columns?: Array<NameTitleUpdateColumn>;
  where?: Maybe<NameTitleBoolExp>;
};

/** Ordering options when selecting data from "name_title". */
export type NameTitleOrderBy = {
  active?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
  users_aggregate?: Maybe<UsersAggregateOrderBy>;
};

/** primary key columns input for table: name_title */
export type NameTitlePkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "name_title" */
export enum NameTitleSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "name_title" */
export type NameTitleSetInput = {
  /** true = เปิดใช้งาน, false = ปิดใช้งาน */
  active?: Maybe<Scalars['Boolean']>;
  /** คำนำหน้า */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type NameTitleStddevFields = {
  __typename?: 'name_title_stddev_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type NameTitleStddevPopFields = {
  __typename?: 'name_title_stddev_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type NameTitleStddevSampFields = {
  __typename?: 'name_title_stddev_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type NameTitleSumFields = {
  __typename?: 'name_title_sum_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** update columns of table "name_title" */
export enum NameTitleUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type NameTitleVarPopFields = {
  __typename?: 'name_title_var_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type NameTitleVarSampFields = {
  __typename?: 'name_title_var_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type NameTitleVarianceFields = {
  __typename?: 'name_title_variance_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/**
 * การแจ้งเตือน
 *
 *
 * columns and relationships of "notification"
 */
export type Notification = {
  __typename?: 'notification';
  /** วันที่การแจ้งเตือนถูกสร้าง */
  created_at: Scalars['timestamptz'];
  /** ข้อความในรูปแบบ html */
  html?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** ข้อความ */
  message?: Maybe<Scalars['String']>;
  /** An object relationship */
  notification_status: NotificationStatus;
  /** ประเภทการแจ้งเตือน */
  notification_type?: Maybe<Scalars['String']>;
  /** ลิงค์การแจ้งเตือน */
  page_link?: Maybe<Scalars['String']>;
  /** PK */
  pk?: Maybe<Scalars['String']>;
  /** ข้อมูลเดิม */
  raw_data?: Maybe<Scalars['String']>;
  /** วันที่เปิดอ่านการแจ้งเตือน */
  read_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่เปิดดูการแจ้งเตือน */
  seen_at?: Maybe<Scalars['timestamptz']>;
  /** ใครเป็นคนกระทำ */
  sender_id?: Maybe<Scalars['uuid']>;
  /** สถานะ */
  status_value: NotificationStatusEnum;
  /** วันที่แก้ไขการแจ้งเตือน */
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  userBySenderId?: Maybe<Users>;
  /** An object relationship */
  userByUserId?: Maybe<Users>;
  /** การแจ้งเตือน จะต้องแสดงให้ใครเห็น */
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "notification" */
export type NotificationAggregate = {
  __typename?: 'notification_aggregate';
  aggregate?: Maybe<NotificationAggregateFields>;
  nodes: Array<Notification>;
};

/** aggregate fields of "notification" */
export type NotificationAggregateFields = {
  __typename?: 'notification_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<NotificationMaxFields>;
  min?: Maybe<NotificationMinFields>;
};


/** aggregate fields of "notification" */
export type NotificationAggregateFieldsCountArgs = {
  columns?: Maybe<Array<NotificationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "notification" */
export type NotificationAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<NotificationMaxOrderBy>;
  min?: Maybe<NotificationMinOrderBy>;
};

/** input type for inserting array relation for remote table "notification" */
export type NotificationArrRelInsertInput = {
  data: Array<NotificationInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<NotificationOnConflict>;
};

/** Boolean expression to filter rows from the table "notification". All fields are combined with a logical 'AND'. */
export type NotificationBoolExp = {
  _and?: Maybe<Array<NotificationBoolExp>>;
  _not?: Maybe<NotificationBoolExp>;
  _or?: Maybe<Array<NotificationBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  html?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  message?: Maybe<StringComparisonExp>;
  notification_status?: Maybe<NotificationStatusBoolExp>;
  notification_type?: Maybe<StringComparisonExp>;
  page_link?: Maybe<StringComparisonExp>;
  pk?: Maybe<StringComparisonExp>;
  raw_data?: Maybe<StringComparisonExp>;
  read_at?: Maybe<TimestamptzComparisonExp>;
  seen_at?: Maybe<TimestamptzComparisonExp>;
  sender_id?: Maybe<UuidComparisonExp>;
  status_value?: Maybe<NotificationStatusEnumComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  userBySenderId?: Maybe<UsersBoolExp>;
  userByUserId?: Maybe<UsersBoolExp>;
  user_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "notification" */
export enum NotificationConstraint {
  /** unique or primary key constraint */
  NotificationPkey = 'notification_pkey'
}

/** input type for inserting data into table "notification" */
export type NotificationInsertInput = {
  /** วันที่การแจ้งเตือนถูกสร้าง */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความในรูปแบบ html */
  html?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ข้อความ */
  message?: Maybe<Scalars['String']>;
  notification_status?: Maybe<NotificationStatusObjRelInsertInput>;
  /** ประเภทการแจ้งเตือน */
  notification_type?: Maybe<Scalars['String']>;
  /** ลิงค์การแจ้งเตือน */
  page_link?: Maybe<Scalars['String']>;
  /** PK */
  pk?: Maybe<Scalars['String']>;
  /** ข้อมูลเดิม */
  raw_data?: Maybe<Scalars['String']>;
  /** วันที่เปิดอ่านการแจ้งเตือน */
  read_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่เปิดดูการแจ้งเตือน */
  seen_at?: Maybe<Scalars['timestamptz']>;
  /** ใครเป็นคนกระทำ */
  sender_id?: Maybe<Scalars['uuid']>;
  /** สถานะ */
  status_value?: Maybe<NotificationStatusEnum>;
  /** วันที่แก้ไขการแจ้งเตือน */
  updated_at?: Maybe<Scalars['timestamptz']>;
  userBySenderId?: Maybe<UsersObjRelInsertInput>;
  userByUserId?: Maybe<UsersObjRelInsertInput>;
  /** การแจ้งเตือน จะต้องแสดงให้ใครเห็น */
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type NotificationMaxFields = {
  __typename?: 'notification_max_fields';
  /** วันที่การแจ้งเตือนถูกสร้าง */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความในรูปแบบ html */
  html?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ข้อความ */
  message?: Maybe<Scalars['String']>;
  /** ประเภทการแจ้งเตือน */
  notification_type?: Maybe<Scalars['String']>;
  /** ลิงค์การแจ้งเตือน */
  page_link?: Maybe<Scalars['String']>;
  /** PK */
  pk?: Maybe<Scalars['String']>;
  /** ข้อมูลเดิม */
  raw_data?: Maybe<Scalars['String']>;
  /** วันที่เปิดอ่านการแจ้งเตือน */
  read_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่เปิดดูการแจ้งเตือน */
  seen_at?: Maybe<Scalars['timestamptz']>;
  /** ใครเป็นคนกระทำ */
  sender_id?: Maybe<Scalars['uuid']>;
  /** วันที่แก้ไขการแจ้งเตือน */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** การแจ้งเตือน จะต้องแสดงให้ใครเห็น */
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "notification" */
export type NotificationMaxOrderBy = {
  /** วันที่การแจ้งเตือนถูกสร้าง */
  created_at?: Maybe<OrderBy>;
  /** ข้อความในรูปแบบ html */
  html?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ข้อความ */
  message?: Maybe<OrderBy>;
  /** ประเภทการแจ้งเตือน */
  notification_type?: Maybe<OrderBy>;
  /** ลิงค์การแจ้งเตือน */
  page_link?: Maybe<OrderBy>;
  /** PK */
  pk?: Maybe<OrderBy>;
  /** ข้อมูลเดิม */
  raw_data?: Maybe<OrderBy>;
  /** วันที่เปิดอ่านการแจ้งเตือน */
  read_at?: Maybe<OrderBy>;
  /** วันที่เปิดดูการแจ้งเตือน */
  seen_at?: Maybe<OrderBy>;
  /** ใครเป็นคนกระทำ */
  sender_id?: Maybe<OrderBy>;
  /** วันที่แก้ไขการแจ้งเตือน */
  updated_at?: Maybe<OrderBy>;
  /** การแจ้งเตือน จะต้องแสดงให้ใครเห็น */
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type NotificationMinFields = {
  __typename?: 'notification_min_fields';
  /** วันที่การแจ้งเตือนถูกสร้าง */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความในรูปแบบ html */
  html?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ข้อความ */
  message?: Maybe<Scalars['String']>;
  /** ประเภทการแจ้งเตือน */
  notification_type?: Maybe<Scalars['String']>;
  /** ลิงค์การแจ้งเตือน */
  page_link?: Maybe<Scalars['String']>;
  /** PK */
  pk?: Maybe<Scalars['String']>;
  /** ข้อมูลเดิม */
  raw_data?: Maybe<Scalars['String']>;
  /** วันที่เปิดอ่านการแจ้งเตือน */
  read_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่เปิดดูการแจ้งเตือน */
  seen_at?: Maybe<Scalars['timestamptz']>;
  /** ใครเป็นคนกระทำ */
  sender_id?: Maybe<Scalars['uuid']>;
  /** วันที่แก้ไขการแจ้งเตือน */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** การแจ้งเตือน จะต้องแสดงให้ใครเห็น */
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "notification" */
export type NotificationMinOrderBy = {
  /** วันที่การแจ้งเตือนถูกสร้าง */
  created_at?: Maybe<OrderBy>;
  /** ข้อความในรูปแบบ html */
  html?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ข้อความ */
  message?: Maybe<OrderBy>;
  /** ประเภทการแจ้งเตือน */
  notification_type?: Maybe<OrderBy>;
  /** ลิงค์การแจ้งเตือน */
  page_link?: Maybe<OrderBy>;
  /** PK */
  pk?: Maybe<OrderBy>;
  /** ข้อมูลเดิม */
  raw_data?: Maybe<OrderBy>;
  /** วันที่เปิดอ่านการแจ้งเตือน */
  read_at?: Maybe<OrderBy>;
  /** วันที่เปิดดูการแจ้งเตือน */
  seen_at?: Maybe<OrderBy>;
  /** ใครเป็นคนกระทำ */
  sender_id?: Maybe<OrderBy>;
  /** วันที่แก้ไขการแจ้งเตือน */
  updated_at?: Maybe<OrderBy>;
  /** การแจ้งเตือน จะต้องแสดงให้ใครเห็น */
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "notification" */
export type NotificationMutationResponse = {
  __typename?: 'notification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification>;
};

/** on conflict condition type for table "notification" */
export type NotificationOnConflict = {
  constraint: NotificationConstraint;
  update_columns?: Array<NotificationUpdateColumn>;
  where?: Maybe<NotificationBoolExp>;
};

/** Ordering options when selecting data from "notification". */
export type NotificationOrderBy = {
  created_at?: Maybe<OrderBy>;
  html?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  message?: Maybe<OrderBy>;
  notification_status?: Maybe<NotificationStatusOrderBy>;
  notification_type?: Maybe<OrderBy>;
  page_link?: Maybe<OrderBy>;
  pk?: Maybe<OrderBy>;
  raw_data?: Maybe<OrderBy>;
  read_at?: Maybe<OrderBy>;
  seen_at?: Maybe<OrderBy>;
  sender_id?: Maybe<OrderBy>;
  status_value?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  userBySenderId?: Maybe<UsersOrderBy>;
  userByUserId?: Maybe<UsersOrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: notification */
export type NotificationPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "notification" */
export enum NotificationSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Html = 'html',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  NotificationType = 'notification_type',
  /** column name */
  PageLink = 'page_link',
  /** column name */
  Pk = 'pk',
  /** column name */
  RawData = 'raw_data',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  SeenAt = 'seen_at',
  /** column name */
  SenderId = 'sender_id',
  /** column name */
  StatusValue = 'status_value',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "notification" */
export type NotificationSetInput = {
  /** วันที่การแจ้งเตือนถูกสร้าง */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** ข้อความในรูปแบบ html */
  html?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ข้อความ */
  message?: Maybe<Scalars['String']>;
  /** ประเภทการแจ้งเตือน */
  notification_type?: Maybe<Scalars['String']>;
  /** ลิงค์การแจ้งเตือน */
  page_link?: Maybe<Scalars['String']>;
  /** PK */
  pk?: Maybe<Scalars['String']>;
  /** ข้อมูลเดิม */
  raw_data?: Maybe<Scalars['String']>;
  /** วันที่เปิดอ่านการแจ้งเตือน */
  read_at?: Maybe<Scalars['timestamptz']>;
  /** วันที่เปิดดูการแจ้งเตือน */
  seen_at?: Maybe<Scalars['timestamptz']>;
  /** ใครเป็นคนกระทำ */
  sender_id?: Maybe<Scalars['uuid']>;
  /** สถานะ */
  status_value?: Maybe<NotificationStatusEnum>;
  /** วันที่แก้ไขการแจ้งเตือน */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** การแจ้งเตือน จะต้องแสดงให้ใครเห็น */
  user_id?: Maybe<Scalars['uuid']>;
};

/**
 * สถานะการแจ้งเตือน
 *
 *
 * columns and relationships of "notification_status"
 */
export type NotificationStatus = {
  __typename?: 'notification_status';
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  notifications: Array<Notification>;
  /** An aggregate relationship */
  notifications_aggregate: NotificationAggregate;
  /** Primary key */
  value: Scalars['String'];
};


/**
 * สถานะการแจ้งเตือน
 *
 *
 * columns and relationships of "notification_status"
 */
export type NotificationStatusNotificationsArgs = {
  distinct_on?: Maybe<Array<NotificationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NotificationOrderBy>>;
  where?: Maybe<NotificationBoolExp>;
};


/**
 * สถานะการแจ้งเตือน
 *
 *
 * columns and relationships of "notification_status"
 */
export type NotificationStatusNotificationsAggregateArgs = {
  distinct_on?: Maybe<Array<NotificationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NotificationOrderBy>>;
  where?: Maybe<NotificationBoolExp>;
};

/** aggregated selection of "notification_status" */
export type NotificationStatusAggregate = {
  __typename?: 'notification_status_aggregate';
  aggregate?: Maybe<NotificationStatusAggregateFields>;
  nodes: Array<NotificationStatus>;
};

/** aggregate fields of "notification_status" */
export type NotificationStatusAggregateFields = {
  __typename?: 'notification_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<NotificationStatusMaxFields>;
  min?: Maybe<NotificationStatusMinFields>;
};


/** aggregate fields of "notification_status" */
export type NotificationStatusAggregateFieldsCountArgs = {
  columns?: Maybe<Array<NotificationStatusSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "notification_status". All fields are combined with a logical 'AND'. */
export type NotificationStatusBoolExp = {
  _and?: Maybe<Array<NotificationStatusBoolExp>>;
  _not?: Maybe<NotificationStatusBoolExp>;
  _or?: Maybe<Array<NotificationStatusBoolExp>>;
  description?: Maybe<StringComparisonExp>;
  notifications?: Maybe<NotificationBoolExp>;
  value?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "notification_status" */
export enum NotificationStatusConstraint {
  /** unique or primary key constraint */
  NotificationStatusPkey = 'notification_status_pkey'
}

export enum NotificationStatusEnum {
  /** อ่านแล้ว */
  Read = 'read',
  /** ได้รับการแจ้งเตือน */
  Seen = 'seen',
  /** ยังไม่อ่าน */
  Unread = 'unread'
}

/** Boolean expression to compare columns of type "notification_status_enum". All fields are combined with logical 'AND'. */
export type NotificationStatusEnumComparisonExp = {
  _eq?: Maybe<NotificationStatusEnum>;
  _in?: Maybe<Array<NotificationStatusEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<NotificationStatusEnum>;
  _nin?: Maybe<Array<NotificationStatusEnum>>;
};

/** input type for inserting data into table "notification_status" */
export type NotificationStatusInsertInput = {
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationArrRelInsertInput>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type NotificationStatusMaxFields = {
  __typename?: 'notification_status_max_fields';
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type NotificationStatusMinFields = {
  __typename?: 'notification_status_min_fields';
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "notification_status" */
export type NotificationStatusMutationResponse = {
  __typename?: 'notification_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<NotificationStatus>;
};

/** input type for inserting object relation for remote table "notification_status" */
export type NotificationStatusObjRelInsertInput = {
  data: NotificationStatusInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<NotificationStatusOnConflict>;
};

/** on conflict condition type for table "notification_status" */
export type NotificationStatusOnConflict = {
  constraint: NotificationStatusConstraint;
  update_columns?: Array<NotificationStatusUpdateColumn>;
  where?: Maybe<NotificationStatusBoolExp>;
};

/** Ordering options when selecting data from "notification_status". */
export type NotificationStatusOrderBy = {
  description?: Maybe<OrderBy>;
  notifications_aggregate?: Maybe<NotificationAggregateOrderBy>;
  value?: Maybe<OrderBy>;
};

/** primary key columns input for table: notification_status */
export type NotificationStatusPkColumnsInput = {
  /** Primary key */
  value: Scalars['String'];
};

/** select columns of table "notification_status" */
export enum NotificationStatusSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "notification_status" */
export type NotificationStatusSetInput = {
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "notification_status" */
export enum NotificationStatusUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** update columns of table "notification" */
export enum NotificationUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Html = 'html',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  NotificationType = 'notification_type',
  /** column name */
  PageLink = 'page_link',
  /** column name */
  Pk = 'pk',
  /** column name */
  RawData = 'raw_data',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  SeenAt = 'seen_at',
  /** column name */
  SenderId = 'sender_id',
  /** column name */
  StatusValue = 'status_value',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};


/** Boolean expression to compare columns of type "oid". All fields are combined with logical 'AND'. */
export type OidComparisonExp = {
  _eq?: Maybe<Scalars['oid']>;
  _gt?: Maybe<Scalars['oid']>;
  _gte?: Maybe<Scalars['oid']>;
  _in?: Maybe<Array<Scalars['oid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['oid']>;
  _lte?: Maybe<Scalars['oid']>;
  _neq?: Maybe<Scalars['oid']>;
  _nin?: Maybe<Array<Scalars['oid']>>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type Organization = {
  __typename?: 'organization';
  /** An array relationship */
  application_event_logs: Array<LoanApplicationEventLog>;
  /** An aggregate relationship */
  application_event_logs_aggregate: LoanApplicationEventLogAggregate;
  /** An array relationship */
  application_headers: Array<LoanApplicationHeader>;
  /** An aggregate relationship */
  application_headers_aggregate: LoanApplicationHeaderAggregate;
  /** An array relationship */
  application_sequence_nos: Array<LoanApplicationSequenceNo>;
  /** An aggregate relationship */
  application_sequence_nos_aggregate: LoanApplicationSequenceNoAggregate;
  /** An array relationship */
  application_transfers_from: Array<LoanApplicationOrganizationTransfer>;
  /** An aggregate relationship */
  application_transfers_from_aggregate: LoanApplicationOrganizationTransferAggregate;
  /** An array relationship */
  application_transfers_to: Array<LoanApplicationOrganizationTransfer>;
  /** An aggregate relationship */
  application_transfers_to_aggregate: LoanApplicationOrganizationTransferAggregate;
  /** รหัสพื้นที่ */
  area_code: Scalars['String'];
  /** An array relationship */
  budget_allocations: Array<AccountBudgetAllocation>;
  /** An aggregate relationship */
  budget_allocations_aggregate: AccountBudgetAllocationAggregate;
  /** วันที่บันทึกข้อมูล */
  created_at: Scalars['timestamptz'];
  /** Primary key */
  id: Scalars['uuid'];
  /** An array relationship */
  ledger_entries: Array<AccountLedgerEntry>;
  /** An aggregate relationship */
  ledger_entries_aggregate: AccountLedgerEntryAggregate;
  /** An array relationship */
  lendings: Array<AccountLending>;
  /** An aggregate relationship */
  lendings_aggregate: AccountLendingAggregate;
  /** ชื่อไทย */
  name?: Maybe<Scalars['String']>;
  /** ชื่ออังกฤษ */
  name_en?: Maybe<Scalars['String']>;
  /** An object relationship */
  organization_type?: Maybe<OrganizationType>;
  /** ประเภทของหน่วยงาน */
  organization_type_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  project_event_logs: Array<ProjectProjectEventLog>;
  /** An aggregate relationship */
  project_event_logs_aggregate: ProjectProjectEventLogAggregate;
  /** An array relationship */
  project_members: Array<ProjectProjectMember>;
  /** An aggregate relationship */
  project_members_aggregate: ProjectProjectMemberAggregate;
  /** An array relationship */
  projects: Array<ProjectProject>;
  /** An aggregate relationship */
  projects_aggregate: ProjectProjectAggregate;
  /** ชื่อจังหวัดสำหรับ mapping */
  province?: Maybe<Scalars['String']>;
  /** รหัสหน่วยงาน/ศภ. */
  reference: Scalars['String'];
  /** ลำดับ */
  sequence?: Maybe<Scalars['numeric']>;
  /** ชื่อย่อไทย */
  short_name?: Maybe<Scalars['String']>;
  /** ชื่อย่ออังกฤษ */
  short_name_en?: Maybe<Scalars['String']>;
  /** An array relationship */
  transactions: Array<AccountTransaction>;
  /** An aggregate relationship */
  transactions_aggregate: AccountTransactionAggregate;
  /** หน่วยเบิกจ่าย */
  unit_code?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  user_mappings: Array<UserMapping>;
  /** An aggregate relationship */
  user_mappings_aggregate: UserMappingAggregate;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: UsersAggregate;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationApplicationEventLogsArgs = {
  distinct_on?: Maybe<Array<LoanApplicationEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationEventLogOrderBy>>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationApplicationEventLogsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationEventLogOrderBy>>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationApplicationHeadersArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationApplicationHeadersAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationApplicationSequenceNosArgs = {
  distinct_on?: Maybe<Array<LoanApplicationSequenceNoSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationSequenceNoOrderBy>>;
  where?: Maybe<LoanApplicationSequenceNoBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationApplicationSequenceNosAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationSequenceNoSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationSequenceNoOrderBy>>;
  where?: Maybe<LoanApplicationSequenceNoBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationApplicationTransfersFromArgs = {
  distinct_on?: Maybe<Array<LoanApplicationOrganizationTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationOrganizationTransferOrderBy>>;
  where?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationApplicationTransfersFromAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationOrganizationTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationOrganizationTransferOrderBy>>;
  where?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationApplicationTransfersToArgs = {
  distinct_on?: Maybe<Array<LoanApplicationOrganizationTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationOrganizationTransferOrderBy>>;
  where?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationApplicationTransfersToAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationOrganizationTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationOrganizationTransferOrderBy>>;
  where?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationBudgetAllocationsArgs = {
  distinct_on?: Maybe<Array<AccountBudgetAllocationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetAllocationOrderBy>>;
  where?: Maybe<AccountBudgetAllocationBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationBudgetAllocationsAggregateArgs = {
  distinct_on?: Maybe<Array<AccountBudgetAllocationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetAllocationOrderBy>>;
  where?: Maybe<AccountBudgetAllocationBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationLedgerEntriesArgs = {
  distinct_on?: Maybe<Array<AccountLedgerEntrySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLedgerEntryOrderBy>>;
  where?: Maybe<AccountLedgerEntryBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationLedgerEntriesAggregateArgs = {
  distinct_on?: Maybe<Array<AccountLedgerEntrySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLedgerEntryOrderBy>>;
  where?: Maybe<AccountLedgerEntryBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationLendingsArgs = {
  distinct_on?: Maybe<Array<AccountLendingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLendingOrderBy>>;
  where?: Maybe<AccountLendingBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationLendingsAggregateArgs = {
  distinct_on?: Maybe<Array<AccountLendingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLendingOrderBy>>;
  where?: Maybe<AccountLendingBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationProjectEventLogsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectEventLogOrderBy>>;
  where?: Maybe<ProjectProjectEventLogBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationProjectEventLogsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectEventLogOrderBy>>;
  where?: Maybe<ProjectProjectEventLogBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationProjectMembersArgs = {
  distinct_on?: Maybe<Array<ProjectProjectMemberSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectMemberOrderBy>>;
  where?: Maybe<ProjectProjectMemberBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationProjectMembersAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectMemberSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectMemberOrderBy>>;
  where?: Maybe<ProjectProjectMemberBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationProjectsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOrderBy>>;
  where?: Maybe<ProjectProjectBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationProjectsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOrderBy>>;
  where?: Maybe<ProjectProjectBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationTransactionsArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationTransactionsAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationUserMappingsArgs = {
  distinct_on?: Maybe<Array<UserMappingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserMappingOrderBy>>;
  where?: Maybe<UserMappingBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationUserMappingsAggregateArgs = {
  distinct_on?: Maybe<Array<UserMappingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserMappingOrderBy>>;
  where?: Maybe<UserMappingBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationUsersArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};


/**
 * หน่วยงาน / ศูนย์ภาค (ศภ.)
 *
 *
 * columns and relationships of "organization"
 */
export type OrganizationUsersAggregateArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};

/** aggregated selection of "organization" */
export type OrganizationAggregate = {
  __typename?: 'organization_aggregate';
  aggregate?: Maybe<OrganizationAggregateFields>;
  nodes: Array<Organization>;
};

/** aggregate fields of "organization" */
export type OrganizationAggregateFields = {
  __typename?: 'organization_aggregate_fields';
  avg?: Maybe<OrganizationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OrganizationMaxFields>;
  min?: Maybe<OrganizationMinFields>;
  stddev?: Maybe<OrganizationStddevFields>;
  stddev_pop?: Maybe<OrganizationStddevPopFields>;
  stddev_samp?: Maybe<OrganizationStddevSampFields>;
  sum?: Maybe<OrganizationSumFields>;
  var_pop?: Maybe<OrganizationVarPopFields>;
  var_samp?: Maybe<OrganizationVarSampFields>;
  variance?: Maybe<OrganizationVarianceFields>;
};


/** aggregate fields of "organization" */
export type OrganizationAggregateFieldsCountArgs = {
  columns?: Maybe<Array<OrganizationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization" */
export type OrganizationAggregateOrderBy = {
  avg?: Maybe<OrganizationAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<OrganizationMaxOrderBy>;
  min?: Maybe<OrganizationMinOrderBy>;
  stddev?: Maybe<OrganizationStddevOrderBy>;
  stddev_pop?: Maybe<OrganizationStddevPopOrderBy>;
  stddev_samp?: Maybe<OrganizationStddevSampOrderBy>;
  sum?: Maybe<OrganizationSumOrderBy>;
  var_pop?: Maybe<OrganizationVarPopOrderBy>;
  var_samp?: Maybe<OrganizationVarSampOrderBy>;
  variance?: Maybe<OrganizationVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "organization" */
export type OrganizationArrRelInsertInput = {
  data: Array<OrganizationInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<OrganizationOnConflict>;
};

/** aggregate avg on columns */
export type OrganizationAvgFields = {
  __typename?: 'organization_avg_fields';
  /** ลำดับ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "organization" */
export type OrganizationAvgOrderBy = {
  /** ลำดับ */
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "organization". All fields are combined with a logical 'AND'. */
export type OrganizationBoolExp = {
  _and?: Maybe<Array<OrganizationBoolExp>>;
  _not?: Maybe<OrganizationBoolExp>;
  _or?: Maybe<Array<OrganizationBoolExp>>;
  application_event_logs?: Maybe<LoanApplicationEventLogBoolExp>;
  application_headers?: Maybe<LoanApplicationHeaderBoolExp>;
  application_sequence_nos?: Maybe<LoanApplicationSequenceNoBoolExp>;
  application_transfers_from?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
  application_transfers_to?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
  area_code?: Maybe<StringComparisonExp>;
  budget_allocations?: Maybe<AccountBudgetAllocationBoolExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  ledger_entries?: Maybe<AccountLedgerEntryBoolExp>;
  lendings?: Maybe<AccountLendingBoolExp>;
  name?: Maybe<StringComparisonExp>;
  name_en?: Maybe<StringComparisonExp>;
  organization_type?: Maybe<OrganizationTypeBoolExp>;
  organization_type_id?: Maybe<StringComparisonExp>;
  project_event_logs?: Maybe<ProjectProjectEventLogBoolExp>;
  project_members?: Maybe<ProjectProjectMemberBoolExp>;
  projects?: Maybe<ProjectProjectBoolExp>;
  province?: Maybe<StringComparisonExp>;
  reference?: Maybe<StringComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
  short_name?: Maybe<StringComparisonExp>;
  short_name_en?: Maybe<StringComparisonExp>;
  transactions?: Maybe<AccountTransactionBoolExp>;
  unit_code?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  user_mappings?: Maybe<UserMappingBoolExp>;
  users?: Maybe<UsersBoolExp>;
};

/** unique or primary key constraints on table "organization" */
export enum OrganizationConstraint {
  /** unique or primary key constraint */
  OrganizationAreaCodeKey = 'organization_area_code_key',
  /** unique or primary key constraint */
  OrganizationPkey = 'organization_pkey',
  /** unique or primary key constraint */
  OrganizationProvinceKey = 'organization_province_key',
  /** unique or primary key constraint */
  OrganizationReferenceKey = 'organization_reference_key'
}

/** input type for incrementing numeric columns in table "organization" */
export type OrganizationIncInput = {
  /** ลำดับ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "organization" */
export type OrganizationInsertInput = {
  application_event_logs?: Maybe<LoanApplicationEventLogArrRelInsertInput>;
  application_headers?: Maybe<LoanApplicationHeaderArrRelInsertInput>;
  application_sequence_nos?: Maybe<LoanApplicationSequenceNoArrRelInsertInput>;
  application_transfers_from?: Maybe<LoanApplicationOrganizationTransferArrRelInsertInput>;
  application_transfers_to?: Maybe<LoanApplicationOrganizationTransferArrRelInsertInput>;
  /** รหัสพื้นที่ */
  area_code?: Maybe<Scalars['String']>;
  budget_allocations?: Maybe<AccountBudgetAllocationArrRelInsertInput>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  ledger_entries?: Maybe<AccountLedgerEntryArrRelInsertInput>;
  lendings?: Maybe<AccountLendingArrRelInsertInput>;
  /** ชื่อไทย */
  name?: Maybe<Scalars['String']>;
  /** ชื่ออังกฤษ */
  name_en?: Maybe<Scalars['String']>;
  organization_type?: Maybe<OrganizationTypeObjRelInsertInput>;
  /** ประเภทของหน่วยงาน */
  organization_type_id?: Maybe<Scalars['String']>;
  project_event_logs?: Maybe<ProjectProjectEventLogArrRelInsertInput>;
  project_members?: Maybe<ProjectProjectMemberArrRelInsertInput>;
  projects?: Maybe<ProjectProjectArrRelInsertInput>;
  /** ชื่อจังหวัดสำหรับ mapping */
  province?: Maybe<Scalars['String']>;
  /** รหัสหน่วยงาน/ศภ. */
  reference?: Maybe<Scalars['String']>;
  /** ลำดับ */
  sequence?: Maybe<Scalars['numeric']>;
  /** ชื่อย่อไทย */
  short_name?: Maybe<Scalars['String']>;
  /** ชื่อย่ออังกฤษ */
  short_name_en?: Maybe<Scalars['String']>;
  transactions?: Maybe<AccountTransactionArrRelInsertInput>;
  /** หน่วยเบิกจ่าย */
  unit_code?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_mappings?: Maybe<UserMappingArrRelInsertInput>;
  users?: Maybe<UsersArrRelInsertInput>;
};

/** aggregate max on columns */
export type OrganizationMaxFields = {
  __typename?: 'organization_max_fields';
  /** รหัสพื้นที่ */
  area_code?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อไทย */
  name?: Maybe<Scalars['String']>;
  /** ชื่ออังกฤษ */
  name_en?: Maybe<Scalars['String']>;
  /** ประเภทของหน่วยงาน */
  organization_type_id?: Maybe<Scalars['String']>;
  /** ชื่อจังหวัดสำหรับ mapping */
  province?: Maybe<Scalars['String']>;
  /** รหัสหน่วยงาน/ศภ. */
  reference?: Maybe<Scalars['String']>;
  /** ลำดับ */
  sequence?: Maybe<Scalars['numeric']>;
  /** ชื่อย่อไทย */
  short_name?: Maybe<Scalars['String']>;
  /** ชื่อย่ออังกฤษ */
  short_name_en?: Maybe<Scalars['String']>;
  /** หน่วยเบิกจ่าย */
  unit_code?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "organization" */
export type OrganizationMaxOrderBy = {
  /** รหัสพื้นที่ */
  area_code?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ชื่อไทย */
  name?: Maybe<OrderBy>;
  /** ชื่ออังกฤษ */
  name_en?: Maybe<OrderBy>;
  /** ประเภทของหน่วยงาน */
  organization_type_id?: Maybe<OrderBy>;
  /** ชื่อจังหวัดสำหรับ mapping */
  province?: Maybe<OrderBy>;
  /** รหัสหน่วยงาน/ศภ. */
  reference?: Maybe<OrderBy>;
  /** ลำดับ */
  sequence?: Maybe<OrderBy>;
  /** ชื่อย่อไทย */
  short_name?: Maybe<OrderBy>;
  /** ชื่อย่ออังกฤษ */
  short_name_en?: Maybe<OrderBy>;
  /** หน่วยเบิกจ่าย */
  unit_code?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type OrganizationMinFields = {
  __typename?: 'organization_min_fields';
  /** รหัสพื้นที่ */
  area_code?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อไทย */
  name?: Maybe<Scalars['String']>;
  /** ชื่ออังกฤษ */
  name_en?: Maybe<Scalars['String']>;
  /** ประเภทของหน่วยงาน */
  organization_type_id?: Maybe<Scalars['String']>;
  /** ชื่อจังหวัดสำหรับ mapping */
  province?: Maybe<Scalars['String']>;
  /** รหัสหน่วยงาน/ศภ. */
  reference?: Maybe<Scalars['String']>;
  /** ลำดับ */
  sequence?: Maybe<Scalars['numeric']>;
  /** ชื่อย่อไทย */
  short_name?: Maybe<Scalars['String']>;
  /** ชื่อย่ออังกฤษ */
  short_name_en?: Maybe<Scalars['String']>;
  /** หน่วยเบิกจ่าย */
  unit_code?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "organization" */
export type OrganizationMinOrderBy = {
  /** รหัสพื้นที่ */
  area_code?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ชื่อไทย */
  name?: Maybe<OrderBy>;
  /** ชื่ออังกฤษ */
  name_en?: Maybe<OrderBy>;
  /** ประเภทของหน่วยงาน */
  organization_type_id?: Maybe<OrderBy>;
  /** ชื่อจังหวัดสำหรับ mapping */
  province?: Maybe<OrderBy>;
  /** รหัสหน่วยงาน/ศภ. */
  reference?: Maybe<OrderBy>;
  /** ลำดับ */
  sequence?: Maybe<OrderBy>;
  /** ชื่อย่อไทย */
  short_name?: Maybe<OrderBy>;
  /** ชื่อย่ออังกฤษ */
  short_name_en?: Maybe<OrderBy>;
  /** หน่วยเบิกจ่าย */
  unit_code?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "organization" */
export type OrganizationMutationResponse = {
  __typename?: 'organization_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization>;
};

/** input type for inserting object relation for remote table "organization" */
export type OrganizationObjRelInsertInput = {
  data: OrganizationInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<OrganizationOnConflict>;
};

/** on conflict condition type for table "organization" */
export type OrganizationOnConflict = {
  constraint: OrganizationConstraint;
  update_columns?: Array<OrganizationUpdateColumn>;
  where?: Maybe<OrganizationBoolExp>;
};

/** Ordering options when selecting data from "organization". */
export type OrganizationOrderBy = {
  application_event_logs_aggregate?: Maybe<LoanApplicationEventLogAggregateOrderBy>;
  application_headers_aggregate?: Maybe<LoanApplicationHeaderAggregateOrderBy>;
  application_sequence_nos_aggregate?: Maybe<LoanApplicationSequenceNoAggregateOrderBy>;
  application_transfers_from_aggregate?: Maybe<LoanApplicationOrganizationTransferAggregateOrderBy>;
  application_transfers_to_aggregate?: Maybe<LoanApplicationOrganizationTransferAggregateOrderBy>;
  area_code?: Maybe<OrderBy>;
  budget_allocations_aggregate?: Maybe<AccountBudgetAllocationAggregateOrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ledger_entries_aggregate?: Maybe<AccountLedgerEntryAggregateOrderBy>;
  lendings_aggregate?: Maybe<AccountLendingAggregateOrderBy>;
  name?: Maybe<OrderBy>;
  name_en?: Maybe<OrderBy>;
  organization_type?: Maybe<OrganizationTypeOrderBy>;
  organization_type_id?: Maybe<OrderBy>;
  project_event_logs_aggregate?: Maybe<ProjectProjectEventLogAggregateOrderBy>;
  project_members_aggregate?: Maybe<ProjectProjectMemberAggregateOrderBy>;
  projects_aggregate?: Maybe<ProjectProjectAggregateOrderBy>;
  province?: Maybe<OrderBy>;
  reference?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
  short_name?: Maybe<OrderBy>;
  short_name_en?: Maybe<OrderBy>;
  transactions_aggregate?: Maybe<AccountTransactionAggregateOrderBy>;
  unit_code?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user_mappings_aggregate?: Maybe<UserMappingAggregateOrderBy>;
  users_aggregate?: Maybe<UsersAggregateOrderBy>;
};

/** columns and relationships of "organization_other" */
export type OrganizationOther = {
  __typename?: 'organization_other';
  area_code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  organization_type_id?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['numeric']>;
  short_name?: Maybe<Scalars['String']>;
  short_name_en?: Maybe<Scalars['String']>;
  unit_code?: Maybe<Scalars['String']>;
};

/** aggregated selection of "organization_other" */
export type OrganizationOtherAggregate = {
  __typename?: 'organization_other_aggregate';
  aggregate?: Maybe<OrganizationOtherAggregateFields>;
  nodes: Array<OrganizationOther>;
};

/** aggregate fields of "organization_other" */
export type OrganizationOtherAggregateFields = {
  __typename?: 'organization_other_aggregate_fields';
  avg?: Maybe<OrganizationOtherAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OrganizationOtherMaxFields>;
  min?: Maybe<OrganizationOtherMinFields>;
  stddev?: Maybe<OrganizationOtherStddevFields>;
  stddev_pop?: Maybe<OrganizationOtherStddevPopFields>;
  stddev_samp?: Maybe<OrganizationOtherStddevSampFields>;
  sum?: Maybe<OrganizationOtherSumFields>;
  var_pop?: Maybe<OrganizationOtherVarPopFields>;
  var_samp?: Maybe<OrganizationOtherVarSampFields>;
  variance?: Maybe<OrganizationOtherVarianceFields>;
};


/** aggregate fields of "organization_other" */
export type OrganizationOtherAggregateFieldsCountArgs = {
  columns?: Maybe<Array<OrganizationOtherSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type OrganizationOtherAvgFields = {
  __typename?: 'organization_other_avg_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "organization_other". All fields are combined with a logical 'AND'. */
export type OrganizationOtherBoolExp = {
  _and?: Maybe<Array<OrganizationOtherBoolExp>>;
  _not?: Maybe<OrganizationOtherBoolExp>;
  _or?: Maybe<Array<OrganizationOtherBoolExp>>;
  area_code?: Maybe<StringComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  name_en?: Maybe<StringComparisonExp>;
  organization_type_id?: Maybe<StringComparisonExp>;
  province?: Maybe<StringComparisonExp>;
  reference?: Maybe<StringComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
  short_name?: Maybe<StringComparisonExp>;
  short_name_en?: Maybe<StringComparisonExp>;
  unit_code?: Maybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type OrganizationOtherMaxFields = {
  __typename?: 'organization_other_max_fields';
  area_code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  organization_type_id?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['numeric']>;
  short_name?: Maybe<Scalars['String']>;
  short_name_en?: Maybe<Scalars['String']>;
  unit_code?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type OrganizationOtherMinFields = {
  __typename?: 'organization_other_min_fields';
  area_code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  organization_type_id?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['numeric']>;
  short_name?: Maybe<Scalars['String']>;
  short_name_en?: Maybe<Scalars['String']>;
  unit_code?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "organization_other". */
export type OrganizationOtherOrderBy = {
  area_code?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  name_en?: Maybe<OrderBy>;
  organization_type_id?: Maybe<OrderBy>;
  province?: Maybe<OrderBy>;
  reference?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
  short_name?: Maybe<OrderBy>;
  short_name_en?: Maybe<OrderBy>;
  unit_code?: Maybe<OrderBy>;
};

/** select columns of table "organization_other" */
export enum OrganizationOtherSelectColumn {
  /** column name */
  AreaCode = 'area_code',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  OrganizationTypeId = 'organization_type_id',
  /** column name */
  Province = 'province',
  /** column name */
  Reference = 'reference',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  ShortNameEn = 'short_name_en',
  /** column name */
  UnitCode = 'unit_code'
}

/** aggregate stddev on columns */
export type OrganizationOtherStddevFields = {
  __typename?: 'organization_other_stddev_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type OrganizationOtherStddevPopFields = {
  __typename?: 'organization_other_stddev_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type OrganizationOtherStddevSampFields = {
  __typename?: 'organization_other_stddev_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type OrganizationOtherSumFields = {
  __typename?: 'organization_other_sum_fields';
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type OrganizationOtherVarPopFields = {
  __typename?: 'organization_other_var_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type OrganizationOtherVarSampFields = {
  __typename?: 'organization_other_var_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type OrganizationOtherVarianceFields = {
  __typename?: 'organization_other_variance_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** primary key columns input for table: organization */
export type OrganizationPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** columns and relationships of "organization_province" */
export type OrganizationProvince = {
  __typename?: 'organization_province';
  area_code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  organization_type_id?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['numeric']>;
  short_name?: Maybe<Scalars['String']>;
  short_name_en?: Maybe<Scalars['String']>;
  unit_code?: Maybe<Scalars['String']>;
};

/** aggregated selection of "organization_province" */
export type OrganizationProvinceAggregate = {
  __typename?: 'organization_province_aggregate';
  aggregate?: Maybe<OrganizationProvinceAggregateFields>;
  nodes: Array<OrganizationProvince>;
};

/** aggregate fields of "organization_province" */
export type OrganizationProvinceAggregateFields = {
  __typename?: 'organization_province_aggregate_fields';
  avg?: Maybe<OrganizationProvinceAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OrganizationProvinceMaxFields>;
  min?: Maybe<OrganizationProvinceMinFields>;
  stddev?: Maybe<OrganizationProvinceStddevFields>;
  stddev_pop?: Maybe<OrganizationProvinceStddevPopFields>;
  stddev_samp?: Maybe<OrganizationProvinceStddevSampFields>;
  sum?: Maybe<OrganizationProvinceSumFields>;
  var_pop?: Maybe<OrganizationProvinceVarPopFields>;
  var_samp?: Maybe<OrganizationProvinceVarSampFields>;
  variance?: Maybe<OrganizationProvinceVarianceFields>;
};


/** aggregate fields of "organization_province" */
export type OrganizationProvinceAggregateFieldsCountArgs = {
  columns?: Maybe<Array<OrganizationProvinceSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type OrganizationProvinceAvgFields = {
  __typename?: 'organization_province_avg_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "organization_province". All fields are combined with a logical 'AND'. */
export type OrganizationProvinceBoolExp = {
  _and?: Maybe<Array<OrganizationProvinceBoolExp>>;
  _not?: Maybe<OrganizationProvinceBoolExp>;
  _or?: Maybe<Array<OrganizationProvinceBoolExp>>;
  area_code?: Maybe<StringComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  name_en?: Maybe<StringComparisonExp>;
  organization_type_id?: Maybe<StringComparisonExp>;
  province?: Maybe<StringComparisonExp>;
  reference?: Maybe<StringComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
  short_name?: Maybe<StringComparisonExp>;
  short_name_en?: Maybe<StringComparisonExp>;
  unit_code?: Maybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type OrganizationProvinceMaxFields = {
  __typename?: 'organization_province_max_fields';
  area_code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  organization_type_id?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['numeric']>;
  short_name?: Maybe<Scalars['String']>;
  short_name_en?: Maybe<Scalars['String']>;
  unit_code?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type OrganizationProvinceMinFields = {
  __typename?: 'organization_province_min_fields';
  area_code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  organization_type_id?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['numeric']>;
  short_name?: Maybe<Scalars['String']>;
  short_name_en?: Maybe<Scalars['String']>;
  unit_code?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "organization_province". */
export type OrganizationProvinceOrderBy = {
  area_code?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  name_en?: Maybe<OrderBy>;
  organization_type_id?: Maybe<OrderBy>;
  province?: Maybe<OrderBy>;
  reference?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
  short_name?: Maybe<OrderBy>;
  short_name_en?: Maybe<OrderBy>;
  unit_code?: Maybe<OrderBy>;
};

/** select columns of table "organization_province" */
export enum OrganizationProvinceSelectColumn {
  /** column name */
  AreaCode = 'area_code',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  OrganizationTypeId = 'organization_type_id',
  /** column name */
  Province = 'province',
  /** column name */
  Reference = 'reference',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  ShortNameEn = 'short_name_en',
  /** column name */
  UnitCode = 'unit_code'
}

/** aggregate stddev on columns */
export type OrganizationProvinceStddevFields = {
  __typename?: 'organization_province_stddev_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type OrganizationProvinceStddevPopFields = {
  __typename?: 'organization_province_stddev_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type OrganizationProvinceStddevSampFields = {
  __typename?: 'organization_province_stddev_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type OrganizationProvinceSumFields = {
  __typename?: 'organization_province_sum_fields';
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type OrganizationProvinceVarPopFields = {
  __typename?: 'organization_province_var_pop_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type OrganizationProvinceVarSampFields = {
  __typename?: 'organization_province_var_samp_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type OrganizationProvinceVarianceFields = {
  __typename?: 'organization_province_variance_fields';
  sequence?: Maybe<Scalars['Float']>;
};

/** select columns of table "organization" */
export enum OrganizationSelectColumn {
  /** column name */
  AreaCode = 'area_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  OrganizationTypeId = 'organization_type_id',
  /** column name */
  Province = 'province',
  /** column name */
  Reference = 'reference',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  ShortNameEn = 'short_name_en',
  /** column name */
  UnitCode = 'unit_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "organization" */
export type OrganizationSetInput = {
  /** รหัสพื้นที่ */
  area_code?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อไทย */
  name?: Maybe<Scalars['String']>;
  /** ชื่ออังกฤษ */
  name_en?: Maybe<Scalars['String']>;
  /** ประเภทของหน่วยงาน */
  organization_type_id?: Maybe<Scalars['String']>;
  /** ชื่อจังหวัดสำหรับ mapping */
  province?: Maybe<Scalars['String']>;
  /** รหัสหน่วยงาน/ศภ. */
  reference?: Maybe<Scalars['String']>;
  /** ลำดับ */
  sequence?: Maybe<Scalars['numeric']>;
  /** ชื่อย่อไทย */
  short_name?: Maybe<Scalars['String']>;
  /** ชื่อย่ออังกฤษ */
  short_name_en?: Maybe<Scalars['String']>;
  /** หน่วยเบิกจ่าย */
  unit_code?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type OrganizationStddevFields = {
  __typename?: 'organization_stddev_fields';
  /** ลำดับ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "organization" */
export type OrganizationStddevOrderBy = {
  /** ลำดับ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type OrganizationStddevPopFields = {
  __typename?: 'organization_stddev_pop_fields';
  /** ลำดับ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "organization" */
export type OrganizationStddevPopOrderBy = {
  /** ลำดับ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type OrganizationStddevSampFields = {
  __typename?: 'organization_stddev_samp_fields';
  /** ลำดับ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "organization" */
export type OrganizationStddevSampOrderBy = {
  /** ลำดับ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type OrganizationSumFields = {
  __typename?: 'organization_sum_fields';
  /** ลำดับ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "organization" */
export type OrganizationSumOrderBy = {
  /** ลำดับ */
  sequence?: Maybe<OrderBy>;
};

/**
 * ประเภทของหน่วยงาน
 *
 *
 * columns and relationships of "organization_type"
 */
export type OrganizationType = {
  __typename?: 'organization_type';
  /** ประเภทหน่วยงาน */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
  /** An array relationship */
  organizations: Array<Organization>;
  /** An aggregate relationship */
  organizations_aggregate: OrganizationAggregate;
};


/**
 * ประเภทของหน่วยงาน
 *
 *
 * columns and relationships of "organization_type"
 */
export type OrganizationTypeOrganizationsArgs = {
  distinct_on?: Maybe<Array<OrganizationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrganizationOrderBy>>;
  where?: Maybe<OrganizationBoolExp>;
};


/**
 * ประเภทของหน่วยงาน
 *
 *
 * columns and relationships of "organization_type"
 */
export type OrganizationTypeOrganizationsAggregateArgs = {
  distinct_on?: Maybe<Array<OrganizationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrganizationOrderBy>>;
  where?: Maybe<OrganizationBoolExp>;
};

/** aggregated selection of "organization_type" */
export type OrganizationTypeAggregate = {
  __typename?: 'organization_type_aggregate';
  aggregate?: Maybe<OrganizationTypeAggregateFields>;
  nodes: Array<OrganizationType>;
};

/** aggregate fields of "organization_type" */
export type OrganizationTypeAggregateFields = {
  __typename?: 'organization_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<OrganizationTypeMaxFields>;
  min?: Maybe<OrganizationTypeMinFields>;
};


/** aggregate fields of "organization_type" */
export type OrganizationTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<OrganizationTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "organization_type". All fields are combined with a logical 'AND'. */
export type OrganizationTypeBoolExp = {
  _and?: Maybe<Array<OrganizationTypeBoolExp>>;
  _not?: Maybe<OrganizationTypeBoolExp>;
  _or?: Maybe<Array<OrganizationTypeBoolExp>>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  organizations?: Maybe<OrganizationBoolExp>;
};

/** unique or primary key constraints on table "organization_type" */
export enum OrganizationTypeConstraint {
  /** unique or primary key constraint */
  OrganizationTypePkey = 'organization_type_pkey'
}

/** input type for inserting data into table "organization_type" */
export type OrganizationTypeInsertInput = {
  /** ประเภทหน่วยงาน */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  organizations?: Maybe<OrganizationArrRelInsertInput>;
};

/** aggregate max on columns */
export type OrganizationTypeMaxFields = {
  __typename?: 'organization_type_max_fields';
  /** ประเภทหน่วยงาน */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type OrganizationTypeMinFields = {
  __typename?: 'organization_type_min_fields';
  /** ประเภทหน่วยงาน */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "organization_type" */
export type OrganizationTypeMutationResponse = {
  __typename?: 'organization_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrganizationType>;
};

/** input type for inserting object relation for remote table "organization_type" */
export type OrganizationTypeObjRelInsertInput = {
  data: OrganizationTypeInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<OrganizationTypeOnConflict>;
};

/** on conflict condition type for table "organization_type" */
export type OrganizationTypeOnConflict = {
  constraint: OrganizationTypeConstraint;
  update_columns?: Array<OrganizationTypeUpdateColumn>;
  where?: Maybe<OrganizationTypeBoolExp>;
};

/** Ordering options when selecting data from "organization_type". */
export type OrganizationTypeOrderBy = {
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  organizations_aggregate?: Maybe<OrganizationAggregateOrderBy>;
};

/** primary key columns input for table: organization_type */
export type OrganizationTypePkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "organization_type" */
export enum OrganizationTypeSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "organization_type" */
export type OrganizationTypeSetInput = {
  /** ประเภทหน่วยงาน */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** update columns of table "organization_type" */
export enum OrganizationTypeUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** update columns of table "organization" */
export enum OrganizationUpdateColumn {
  /** column name */
  AreaCode = 'area_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  OrganizationTypeId = 'organization_type_id',
  /** column name */
  Province = 'province',
  /** column name */
  Reference = 'reference',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  ShortNameEn = 'short_name_en',
  /** column name */
  UnitCode = 'unit_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type OrganizationVarPopFields = {
  __typename?: 'organization_var_pop_fields';
  /** ลำดับ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "organization" */
export type OrganizationVarPopOrderBy = {
  /** ลำดับ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type OrganizationVarSampFields = {
  __typename?: 'organization_var_samp_fields';
  /** ลำดับ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "organization" */
export type OrganizationVarSampOrderBy = {
  /** ลำดับ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type OrganizationVarianceFields = {
  __typename?: 'organization_variance_fields';
  /** ลำดับ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "organization" */
export type OrganizationVarianceOrderBy = {
  /** ลำดับ */
  sequence?: Maybe<OrderBy>;
};

/**
 * สิทธิ์
 *
 *
 * columns and relationships of "permission"
 */
export type Permission = {
  __typename?: 'permission';
  /** สถานะ true = เปิดใช้งาน, false = ปิดใช้งาน */
  active: Scalars['Boolean'];
  /** คำอธิบายสิทธิ์ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
  /** ลำดับที่ */
  sequence: Scalars['numeric'];
  /** An array relationship */
  users_permissions: Array<UsersPermission>;
  /** An aggregate relationship */
  users_permissions_aggregate: UsersPermissionAggregate;
};


/**
 * สิทธิ์
 *
 *
 * columns and relationships of "permission"
 */
export type PermissionUsersPermissionsArgs = {
  distinct_on?: Maybe<Array<UsersPermissionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersPermissionOrderBy>>;
  where?: Maybe<UsersPermissionBoolExp>;
};


/**
 * สิทธิ์
 *
 *
 * columns and relationships of "permission"
 */
export type PermissionUsersPermissionsAggregateArgs = {
  distinct_on?: Maybe<Array<UsersPermissionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersPermissionOrderBy>>;
  where?: Maybe<UsersPermissionBoolExp>;
};

/** aggregated selection of "permission" */
export type PermissionAggregate = {
  __typename?: 'permission_aggregate';
  aggregate?: Maybe<PermissionAggregateFields>;
  nodes: Array<Permission>;
};

/** aggregate fields of "permission" */
export type PermissionAggregateFields = {
  __typename?: 'permission_aggregate_fields';
  avg?: Maybe<PermissionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<PermissionMaxFields>;
  min?: Maybe<PermissionMinFields>;
  stddev?: Maybe<PermissionStddevFields>;
  stddev_pop?: Maybe<PermissionStddevPopFields>;
  stddev_samp?: Maybe<PermissionStddevSampFields>;
  sum?: Maybe<PermissionSumFields>;
  var_pop?: Maybe<PermissionVarPopFields>;
  var_samp?: Maybe<PermissionVarSampFields>;
  variance?: Maybe<PermissionVarianceFields>;
};


/** aggregate fields of "permission" */
export type PermissionAggregateFieldsCountArgs = {
  columns?: Maybe<Array<PermissionSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type PermissionAvgFields = {
  __typename?: 'permission_avg_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "permission". All fields are combined with a logical 'AND'. */
export type PermissionBoolExp = {
  _and?: Maybe<Array<PermissionBoolExp>>;
  _not?: Maybe<PermissionBoolExp>;
  _or?: Maybe<Array<PermissionBoolExp>>;
  active?: Maybe<BooleanComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
  users_permissions?: Maybe<UsersPermissionBoolExp>;
};

/** unique or primary key constraints on table "permission" */
export enum PermissionConstraint {
  /** unique or primary key constraint */
  PermissionPkey = 'permission_pkey'
}

/** input type for incrementing numeric columns in table "permission" */
export type PermissionIncInput = {
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "permission" */
export type PermissionInsertInput = {
  /** สถานะ true = เปิดใช้งาน, false = ปิดใช้งาน */
  active?: Maybe<Scalars['Boolean']>;
  /** คำอธิบายสิทธิ์ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  users_permissions?: Maybe<UsersPermissionArrRelInsertInput>;
};

/** aggregate max on columns */
export type PermissionMaxFields = {
  __typename?: 'permission_max_fields';
  /** คำอธิบายสิทธิ์ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type PermissionMinFields = {
  __typename?: 'permission_min_fields';
  /** คำอธิบายสิทธิ์ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "permission" */
export type PermissionMutationResponse = {
  __typename?: 'permission_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Permission>;
};

/** input type for inserting object relation for remote table "permission" */
export type PermissionObjRelInsertInput = {
  data: PermissionInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<PermissionOnConflict>;
};

/** on conflict condition type for table "permission" */
export type PermissionOnConflict = {
  constraint: PermissionConstraint;
  update_columns?: Array<PermissionUpdateColumn>;
  where?: Maybe<PermissionBoolExp>;
};

/** Ordering options when selecting data from "permission". */
export type PermissionOrderBy = {
  active?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
  users_permissions_aggregate?: Maybe<UsersPermissionAggregateOrderBy>;
};

/** primary key columns input for table: permission */
export type PermissionPkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "permission" */
export enum PermissionSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "permission" */
export type PermissionSetInput = {
  /** สถานะ true = เปิดใช้งาน, false = ปิดใช้งาน */
  active?: Maybe<Scalars['Boolean']>;
  /** คำอธิบายสิทธิ์ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type PermissionStddevFields = {
  __typename?: 'permission_stddev_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PermissionStddevPopFields = {
  __typename?: 'permission_stddev_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PermissionStddevSampFields = {
  __typename?: 'permission_stddev_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type PermissionSumFields = {
  __typename?: 'permission_sum_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** update columns of table "permission" */
export enum PermissionUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type PermissionVarPopFields = {
  __typename?: 'permission_var_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PermissionVarSampFields = {
  __typename?: 'permission_var_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PermissionVarianceFields = {
  __typename?: 'permission_variance_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "posts" */
export type Posts = {
  __typename?: 'posts';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  description: Scalars['String'];
  id: Scalars['uuid'];
  key: Scalars['String'];
  published: Scalars['Boolean'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "posts" */
export type PostsAggregate = {
  __typename?: 'posts_aggregate';
  aggregate?: Maybe<PostsAggregateFields>;
  nodes: Array<Posts>;
};

/** aggregate fields of "posts" */
export type PostsAggregateFields = {
  __typename?: 'posts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PostsMaxFields>;
  min?: Maybe<PostsMinFields>;
};


/** aggregate fields of "posts" */
export type PostsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<PostsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "posts". All fields are combined with a logical 'AND'. */
export type PostsBoolExp = {
  _and?: Maybe<Array<PostsBoolExp>>;
  _not?: Maybe<PostsBoolExp>;
  _or?: Maybe<Array<PostsBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  key?: Maybe<StringComparisonExp>;
  published?: Maybe<BooleanComparisonExp>;
  title?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "posts" */
export enum PostsConstraint {
  /** unique or primary key constraint */
  PostsKeyKey = 'posts_key_key',
  /** unique or primary key constraint */
  PostsPkey = 'posts_pkey'
}

/** input type for inserting data into table "posts" */
export type PostsInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PostsMaxFields = {
  __typename?: 'posts_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type PostsMinFields = {
  __typename?: 'posts_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "posts" */
export type PostsMutationResponse = {
  __typename?: 'posts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Posts>;
};

/** on conflict condition type for table "posts" */
export type PostsOnConflict = {
  constraint: PostsConstraint;
  update_columns?: Array<PostsUpdateColumn>;
  where?: Maybe<PostsBoolExp>;
};

/** Ordering options when selecting data from "posts". */
export type PostsOrderBy = {
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  key?: Maybe<OrderBy>;
  published?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
};

/** primary key columns input for table: posts */
export type PostsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "posts" */
export enum PostsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Published = 'published',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "posts" */
export type PostsSetInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['uuid']>;
};

/** update columns of table "posts" */
export enum PostsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Published = 'published',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/**
 * สถานะการตรวจสอบของโครงการ
 *
 *
 * columns and relationships of "project.approved"
 */
export type ProjectApproved = {
  __typename?: 'project_approved';
  /** คำอธิบายสถานะ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
  /** An array relationship */
  project_event_logs: Array<ProjectProjectEventLog>;
  /** An aggregate relationship */
  project_event_logs_aggregate: ProjectProjectEventLogAggregate;
  /** An array relationship */
  projects: Array<ProjectProject>;
  /** An aggregate relationship */
  projects_aggregate: ProjectProjectAggregate;
};


/**
 * สถานะการตรวจสอบของโครงการ
 *
 *
 * columns and relationships of "project.approved"
 */
export type ProjectApprovedProjectEventLogsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectEventLogOrderBy>>;
  where?: Maybe<ProjectProjectEventLogBoolExp>;
};


/**
 * สถานะการตรวจสอบของโครงการ
 *
 *
 * columns and relationships of "project.approved"
 */
export type ProjectApprovedProjectEventLogsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectEventLogOrderBy>>;
  where?: Maybe<ProjectProjectEventLogBoolExp>;
};


/**
 * สถานะการตรวจสอบของโครงการ
 *
 *
 * columns and relationships of "project.approved"
 */
export type ProjectApprovedProjectsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOrderBy>>;
  where?: Maybe<ProjectProjectBoolExp>;
};


/**
 * สถานะการตรวจสอบของโครงการ
 *
 *
 * columns and relationships of "project.approved"
 */
export type ProjectApprovedProjectsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOrderBy>>;
  where?: Maybe<ProjectProjectBoolExp>;
};

/** aggregated selection of "project.approved" */
export type ProjectApprovedAggregate = {
  __typename?: 'project_approved_aggregate';
  aggregate?: Maybe<ProjectApprovedAggregateFields>;
  nodes: Array<ProjectApproved>;
};

/** aggregate fields of "project.approved" */
export type ProjectApprovedAggregateFields = {
  __typename?: 'project_approved_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ProjectApprovedMaxFields>;
  min?: Maybe<ProjectApprovedMinFields>;
};


/** aggregate fields of "project.approved" */
export type ProjectApprovedAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectApprovedSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "project.approved". All fields are combined with a logical 'AND'. */
export type ProjectApprovedBoolExp = {
  _and?: Maybe<Array<ProjectApprovedBoolExp>>;
  _not?: Maybe<ProjectApprovedBoolExp>;
  _or?: Maybe<Array<ProjectApprovedBoolExp>>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  project_event_logs?: Maybe<ProjectProjectEventLogBoolExp>;
  projects?: Maybe<ProjectProjectBoolExp>;
};

/** unique or primary key constraints on table "project.approved" */
export enum ProjectApprovedConstraint {
  /** unique or primary key constraint */
  ApprovedPkey = 'approved_pkey'
}

export enum ProjectApprovedEnum {
  /** อนุมัติโครงการ */
  Approved = 'approved',
  /** ยกเลิกโครงการ */
  Cancelled = 'cancelled',
  /** แก้ไขโครงการ */
  ChangeRequest = 'change_request',
  /** เสร็จสิ้น */
  Completed = 'completed',
  /** ไม่อนุมัติโครงการ */
  Rejected = 'rejected'
}

/** Boolean expression to compare columns of type "project_approved_enum". All fields are combined with logical 'AND'. */
export type ProjectApprovedEnumComparisonExp = {
  _eq?: Maybe<ProjectApprovedEnum>;
  _in?: Maybe<Array<ProjectApprovedEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ProjectApprovedEnum>;
  _nin?: Maybe<Array<ProjectApprovedEnum>>;
};

/** input type for inserting data into table "project.approved" */
export type ProjectApprovedInsertInput = {
  /** คำอธิบายสถานะ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  project_event_logs?: Maybe<ProjectProjectEventLogArrRelInsertInput>;
  projects?: Maybe<ProjectProjectArrRelInsertInput>;
};

/** aggregate max on columns */
export type ProjectApprovedMaxFields = {
  __typename?: 'project_approved_max_fields';
  /** คำอธิบายสถานะ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ProjectApprovedMinFields = {
  __typename?: 'project_approved_min_fields';
  /** คำอธิบายสถานะ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "project.approved" */
export type ProjectApprovedMutationResponse = {
  __typename?: 'project_approved_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectApproved>;
};

/** input type for inserting object relation for remote table "project.approved" */
export type ProjectApprovedObjRelInsertInput = {
  data: ProjectApprovedInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectApprovedOnConflict>;
};

/** on conflict condition type for table "project.approved" */
export type ProjectApprovedOnConflict = {
  constraint: ProjectApprovedConstraint;
  update_columns?: Array<ProjectApprovedUpdateColumn>;
  where?: Maybe<ProjectApprovedBoolExp>;
};

/** Ordering options when selecting data from "project.approved". */
export type ProjectApprovedOrderBy = {
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  project_event_logs_aggregate?: Maybe<ProjectProjectEventLogAggregateOrderBy>;
  projects_aggregate?: Maybe<ProjectProjectAggregateOrderBy>;
};

/** primary key columns input for table: project_approved */
export type ProjectApprovedPkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "project.approved" */
export enum ProjectApprovedSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "project.approved" */
export type ProjectApprovedSetInput = {
  /** คำอธิบายสถานะ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** update columns of table "project.approved" */
export enum ProjectApprovedUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/**
 * ประเภทเอกสาร
 *
 *
 * columns and relationships of "project.attachment_type"
 */
export type ProjectAttachmentType = {
  __typename?: 'project_attachment_type';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย ประเภทเอกสาร */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
  /** An array relationship */
  project_attachments: Array<ProjectProjectAttachment>;
  /** An aggregate relationship */
  project_attachments_aggregate: ProjectProjectAttachmentAggregate;
  /** true = จำเป็นต้องมี, false = ไม่จำเป็นต้องมี */
  required: Scalars['Boolean'];
  /** ลำดับที่ */
  sequence: Scalars['numeric'];
};


/**
 * ประเภทเอกสาร
 *
 *
 * columns and relationships of "project.attachment_type"
 */
export type ProjectAttachmentTypeProjectAttachmentsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectAttachmentOrderBy>>;
  where?: Maybe<ProjectProjectAttachmentBoolExp>;
};


/**
 * ประเภทเอกสาร
 *
 *
 * columns and relationships of "project.attachment_type"
 */
export type ProjectAttachmentTypeProjectAttachmentsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectAttachmentOrderBy>>;
  where?: Maybe<ProjectProjectAttachmentBoolExp>;
};

/** aggregated selection of "project.attachment_type" */
export type ProjectAttachmentTypeAggregate = {
  __typename?: 'project_attachment_type_aggregate';
  aggregate?: Maybe<ProjectAttachmentTypeAggregateFields>;
  nodes: Array<ProjectAttachmentType>;
};

/** aggregate fields of "project.attachment_type" */
export type ProjectAttachmentTypeAggregateFields = {
  __typename?: 'project_attachment_type_aggregate_fields';
  avg?: Maybe<ProjectAttachmentTypeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectAttachmentTypeMaxFields>;
  min?: Maybe<ProjectAttachmentTypeMinFields>;
  stddev?: Maybe<ProjectAttachmentTypeStddevFields>;
  stddev_pop?: Maybe<ProjectAttachmentTypeStddevPopFields>;
  stddev_samp?: Maybe<ProjectAttachmentTypeStddevSampFields>;
  sum?: Maybe<ProjectAttachmentTypeSumFields>;
  var_pop?: Maybe<ProjectAttachmentTypeVarPopFields>;
  var_samp?: Maybe<ProjectAttachmentTypeVarSampFields>;
  variance?: Maybe<ProjectAttachmentTypeVarianceFields>;
};


/** aggregate fields of "project.attachment_type" */
export type ProjectAttachmentTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectAttachmentTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ProjectAttachmentTypeAvgFields = {
  __typename?: 'project_attachment_type_avg_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "project.attachment_type". All fields are combined with a logical 'AND'. */
export type ProjectAttachmentTypeBoolExp = {
  _and?: Maybe<Array<ProjectAttachmentTypeBoolExp>>;
  _not?: Maybe<ProjectAttachmentTypeBoolExp>;
  _or?: Maybe<Array<ProjectAttachmentTypeBoolExp>>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  project_attachments?: Maybe<ProjectProjectAttachmentBoolExp>;
  required?: Maybe<BooleanComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "project.attachment_type" */
export enum ProjectAttachmentTypeConstraint {
  /** unique or primary key constraint */
  AttachmentTypePkey = 'attachment_type_pkey'
}

/** input type for incrementing numeric columns in table "project.attachment_type" */
export type ProjectAttachmentTypeIncInput = {
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "project.attachment_type" */
export type ProjectAttachmentTypeInsertInput = {
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย ประเภทเอกสาร */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  project_attachments?: Maybe<ProjectProjectAttachmentArrRelInsertInput>;
  /** true = จำเป็นต้องมี, false = ไม่จำเป็นต้องมี */
  required?: Maybe<Scalars['Boolean']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type ProjectAttachmentTypeMaxFields = {
  __typename?: 'project_attachment_type_max_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย ประเภทเอกสาร */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type ProjectAttachmentTypeMinFields = {
  __typename?: 'project_attachment_type_min_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย ประเภทเอกสาร */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "project.attachment_type" */
export type ProjectAttachmentTypeMutationResponse = {
  __typename?: 'project_attachment_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectAttachmentType>;
};

/** input type for inserting object relation for remote table "project.attachment_type" */
export type ProjectAttachmentTypeObjRelInsertInput = {
  data: ProjectAttachmentTypeInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectAttachmentTypeOnConflict>;
};

/** on conflict condition type for table "project.attachment_type" */
export type ProjectAttachmentTypeOnConflict = {
  constraint: ProjectAttachmentTypeConstraint;
  update_columns?: Array<ProjectAttachmentTypeUpdateColumn>;
  where?: Maybe<ProjectAttachmentTypeBoolExp>;
};

/** Ordering options when selecting data from "project.attachment_type". */
export type ProjectAttachmentTypeOrderBy = {
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  project_attachments_aggregate?: Maybe<ProjectProjectAttachmentAggregateOrderBy>;
  required?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** primary key columns input for table: project_attachment_type */
export type ProjectAttachmentTypePkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "project.attachment_type" */
export enum ProjectAttachmentTypeSelectColumn {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Required = 'required',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "project.attachment_type" */
export type ProjectAttachmentTypeSetInput = {
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย ประเภทเอกสาร */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** true = จำเป็นต้องมี, false = ไม่จำเป็นต้องมี */
  required?: Maybe<Scalars['Boolean']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type ProjectAttachmentTypeStddevFields = {
  __typename?: 'project_attachment_type_stddev_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ProjectAttachmentTypeStddevPopFields = {
  __typename?: 'project_attachment_type_stddev_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ProjectAttachmentTypeStddevSampFields = {
  __typename?: 'project_attachment_type_stddev_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ProjectAttachmentTypeSumFields = {
  __typename?: 'project_attachment_type_sum_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** update columns of table "project.attachment_type" */
export enum ProjectAttachmentTypeUpdateColumn {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Required = 'required',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type ProjectAttachmentTypeVarPopFields = {
  __typename?: 'project_attachment_type_var_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ProjectAttachmentTypeVarSampFields = {
  __typename?: 'project_attachment_type_var_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ProjectAttachmentTypeVarianceFields = {
  __typename?: 'project_attachment_type_variance_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/**
 * รายละเอียดงบประมาณ
 *
 *
 * columns and relationships of "project.budget"
 */
export type ProjectBudget = {
  __typename?: 'project_budget';
  /** An array relationship */
  budget_group_mappings: Array<ProjectBudgetGroupMapping>;
  /** An aggregate relationship */
  budget_group_mappings_aggregate: ProjectBudgetGroupMappingAggregate;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year: Scalars['Int'];
  /** Primary key */
  id: Scalars['uuid'];
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<Scalars['numeric']>;
  /** ชื่อ */
  name?: Maybe<Scalars['String']>;
  /** ราคาต่อหน่วย */
  price_per_unit: Scalars['numeric'];
  /** An array relationship */
  project_budget_items: Array<ProjectProjectBudgetItem>;
  /** An aggregate relationship */
  project_budget_items_aggregate: ProjectProjectBudgetItemAggregate;
  /** ลำดับที่ */
  sequence: Scalars['numeric'];
  /** An object relationship */
  unit?: Maybe<ProjectUnit>;
  /** หน่วยนับ */
  unit_id?: Maybe<ProjectUnitEnum>;
  /** An object relationship */
  unit_of_multiplier?: Maybe<ProjectUnit>;
  /** หน่วยนับตัวคูณ */
  unit_of_multiplier_id?: Maybe<ProjectUnitEnum>;
};


/**
 * รายละเอียดงบประมาณ
 *
 *
 * columns and relationships of "project.budget"
 */
export type ProjectBudgetBudgetGroupMappingsArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetGroupMappingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetGroupMappingOrderBy>>;
  where?: Maybe<ProjectBudgetGroupMappingBoolExp>;
};


/**
 * รายละเอียดงบประมาณ
 *
 *
 * columns and relationships of "project.budget"
 */
export type ProjectBudgetBudgetGroupMappingsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetGroupMappingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetGroupMappingOrderBy>>;
  where?: Maybe<ProjectBudgetGroupMappingBoolExp>;
};


/**
 * รายละเอียดงบประมาณ
 *
 *
 * columns and relationships of "project.budget"
 */
export type ProjectBudgetProjectBudgetItemsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetItemOrderBy>>;
  where?: Maybe<ProjectProjectBudgetItemBoolExp>;
};


/**
 * รายละเอียดงบประมาณ
 *
 *
 * columns and relationships of "project.budget"
 */
export type ProjectBudgetProjectBudgetItemsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetItemOrderBy>>;
  where?: Maybe<ProjectProjectBudgetItemBoolExp>;
};

/** aggregated selection of "project.budget" */
export type ProjectBudgetAggregate = {
  __typename?: 'project_budget_aggregate';
  aggregate?: Maybe<ProjectBudgetAggregateFields>;
  nodes: Array<ProjectBudget>;
};

/** aggregate fields of "project.budget" */
export type ProjectBudgetAggregateFields = {
  __typename?: 'project_budget_aggregate_fields';
  avg?: Maybe<ProjectBudgetAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectBudgetMaxFields>;
  min?: Maybe<ProjectBudgetMinFields>;
  stddev?: Maybe<ProjectBudgetStddevFields>;
  stddev_pop?: Maybe<ProjectBudgetStddevPopFields>;
  stddev_samp?: Maybe<ProjectBudgetStddevSampFields>;
  sum?: Maybe<ProjectBudgetSumFields>;
  var_pop?: Maybe<ProjectBudgetVarPopFields>;
  var_samp?: Maybe<ProjectBudgetVarSampFields>;
  variance?: Maybe<ProjectBudgetVarianceFields>;
};


/** aggregate fields of "project.budget" */
export type ProjectBudgetAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectBudgetSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project.budget" */
export type ProjectBudgetAggregateOrderBy = {
  avg?: Maybe<ProjectBudgetAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProjectBudgetMaxOrderBy>;
  min?: Maybe<ProjectBudgetMinOrderBy>;
  stddev?: Maybe<ProjectBudgetStddevOrderBy>;
  stddev_pop?: Maybe<ProjectBudgetStddevPopOrderBy>;
  stddev_samp?: Maybe<ProjectBudgetStddevSampOrderBy>;
  sum?: Maybe<ProjectBudgetSumOrderBy>;
  var_pop?: Maybe<ProjectBudgetVarPopOrderBy>;
  var_samp?: Maybe<ProjectBudgetVarSampOrderBy>;
  variance?: Maybe<ProjectBudgetVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "project.budget" */
export type ProjectBudgetArrRelInsertInput = {
  data: Array<ProjectBudgetInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectBudgetOnConflict>;
};

/** aggregate avg on columns */
export type ProjectBudgetAvgFields = {
  __typename?: 'project_budget_avg_fields';
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year?: Maybe<Scalars['Float']>;
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<Scalars['Float']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project.budget" */
export type ProjectBudgetAvgOrderBy = {
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year?: Maybe<OrderBy>;
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<OrderBy>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "project.budget". All fields are combined with a logical 'AND'. */
export type ProjectBudgetBoolExp = {
  _and?: Maybe<Array<ProjectBudgetBoolExp>>;
  _not?: Maybe<ProjectBudgetBoolExp>;
  _or?: Maybe<Array<ProjectBudgetBoolExp>>;
  budget_group_mappings?: Maybe<ProjectBudgetGroupMappingBoolExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  max_price_per_unit?: Maybe<NumericComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  price_per_unit?: Maybe<NumericComparisonExp>;
  project_budget_items?: Maybe<ProjectProjectBudgetItemBoolExp>;
  sequence?: Maybe<NumericComparisonExp>;
  unit?: Maybe<ProjectUnitBoolExp>;
  unit_id?: Maybe<ProjectUnitEnumComparisonExp>;
  unit_of_multiplier?: Maybe<ProjectUnitBoolExp>;
  unit_of_multiplier_id?: Maybe<ProjectUnitEnumComparisonExp>;
};

/** unique or primary key constraints on table "project.budget" */
export enum ProjectBudgetConstraint {
  /** unique or primary key constraint */
  BudgetPkey = 'budget_pkey'
}

/**
 * ประเภทรายละเอียดค่าใช้จ่าย กิจกรรมโครงการ
 *
 *
 * columns and relationships of "project.budget_group"
 */
export type ProjectBudgetGroup = {
  __typename?: 'project_budget_group';
  /** An array relationship */
  budget_group_mappings: Array<ProjectBudgetGroupMapping>;
  /** An aggregate relationship */
  budget_group_mappings_aggregate: ProjectBudgetGroupMappingAggregate;
  /** คำอธิบาย ประเภทรายละเอียดค่าใช้จ่าย กิจกรรมโครงการ */
  description: Scalars['String'];
  /** Primary key */
  id: Scalars['String'];
  /** An array relationship */
  project_budget_items: Array<ProjectProjectBudgetItem>;
  /** An aggregate relationship */
  project_budget_items_aggregate: ProjectProjectBudgetItemAggregate;
  /** An array relationship */
  project_operating_activities: Array<ProjectProjectOperatingActivity>;
  /** An aggregate relationship */
  project_operating_activities_aggregate: ProjectProjectOperatingActivityAggregate;
};


/**
 * ประเภทรายละเอียดค่าใช้จ่าย กิจกรรมโครงการ
 *
 *
 * columns and relationships of "project.budget_group"
 */
export type ProjectBudgetGroupBudgetGroupMappingsArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetGroupMappingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetGroupMappingOrderBy>>;
  where?: Maybe<ProjectBudgetGroupMappingBoolExp>;
};


/**
 * ประเภทรายละเอียดค่าใช้จ่าย กิจกรรมโครงการ
 *
 *
 * columns and relationships of "project.budget_group"
 */
export type ProjectBudgetGroupBudgetGroupMappingsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetGroupMappingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetGroupMappingOrderBy>>;
  where?: Maybe<ProjectBudgetGroupMappingBoolExp>;
};


/**
 * ประเภทรายละเอียดค่าใช้จ่าย กิจกรรมโครงการ
 *
 *
 * columns and relationships of "project.budget_group"
 */
export type ProjectBudgetGroupProjectBudgetItemsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetItemOrderBy>>;
  where?: Maybe<ProjectProjectBudgetItemBoolExp>;
};


/**
 * ประเภทรายละเอียดค่าใช้จ่าย กิจกรรมโครงการ
 *
 *
 * columns and relationships of "project.budget_group"
 */
export type ProjectBudgetGroupProjectBudgetItemsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetItemOrderBy>>;
  where?: Maybe<ProjectProjectBudgetItemBoolExp>;
};


/**
 * ประเภทรายละเอียดค่าใช้จ่าย กิจกรรมโครงการ
 *
 *
 * columns and relationships of "project.budget_group"
 */
export type ProjectBudgetGroupProjectOperatingActivitiesArgs = {
  distinct_on?: Maybe<Array<ProjectProjectOperatingActivitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOperatingActivityOrderBy>>;
  where?: Maybe<ProjectProjectOperatingActivityBoolExp>;
};


/**
 * ประเภทรายละเอียดค่าใช้จ่าย กิจกรรมโครงการ
 *
 *
 * columns and relationships of "project.budget_group"
 */
export type ProjectBudgetGroupProjectOperatingActivitiesAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectOperatingActivitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOperatingActivityOrderBy>>;
  where?: Maybe<ProjectProjectOperatingActivityBoolExp>;
};

/** aggregated selection of "project.budget_group" */
export type ProjectBudgetGroupAggregate = {
  __typename?: 'project_budget_group_aggregate';
  aggregate?: Maybe<ProjectBudgetGroupAggregateFields>;
  nodes: Array<ProjectBudgetGroup>;
};

/** aggregate fields of "project.budget_group" */
export type ProjectBudgetGroupAggregateFields = {
  __typename?: 'project_budget_group_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ProjectBudgetGroupMaxFields>;
  min?: Maybe<ProjectBudgetGroupMinFields>;
};


/** aggregate fields of "project.budget_group" */
export type ProjectBudgetGroupAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectBudgetGroupSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "project.budget_group". All fields are combined with a logical 'AND'. */
export type ProjectBudgetGroupBoolExp = {
  _and?: Maybe<Array<ProjectBudgetGroupBoolExp>>;
  _not?: Maybe<ProjectBudgetGroupBoolExp>;
  _or?: Maybe<Array<ProjectBudgetGroupBoolExp>>;
  budget_group_mappings?: Maybe<ProjectBudgetGroupMappingBoolExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  project_budget_items?: Maybe<ProjectProjectBudgetItemBoolExp>;
  project_operating_activities?: Maybe<ProjectProjectOperatingActivityBoolExp>;
};

/** unique or primary key constraints on table "project.budget_group" */
export enum ProjectBudgetGroupConstraint {
  /** unique or primary key constraint */
  BudgetGroupPkey = 'budget_group_pkey'
}

export enum ProjectBudgetGroupEnum {
  /** จ้างที่ปรึกษา */
  Consultant = 'consultant',
  /** จ้างเหมา */
  OutSourced = 'out_sourced',
  /** ดำเนินการเอง */
  Self = 'self'
}

/** Boolean expression to compare columns of type "project_budget_group_enum". All fields are combined with logical 'AND'. */
export type ProjectBudgetGroupEnumComparisonExp = {
  _eq?: Maybe<ProjectBudgetGroupEnum>;
  _in?: Maybe<Array<ProjectBudgetGroupEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ProjectBudgetGroupEnum>;
  _nin?: Maybe<Array<ProjectBudgetGroupEnum>>;
};

/** input type for inserting data into table "project.budget_group" */
export type ProjectBudgetGroupInsertInput = {
  budget_group_mappings?: Maybe<ProjectBudgetGroupMappingArrRelInsertInput>;
  /** คำอธิบาย ประเภทรายละเอียดค่าใช้จ่าย กิจกรรมโครงการ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  project_budget_items?: Maybe<ProjectProjectBudgetItemArrRelInsertInput>;
  project_operating_activities?: Maybe<ProjectProjectOperatingActivityArrRelInsertInput>;
};

/**
 * จับคู่ระหว่างรายละเอียดงบประมาณและประเภทรายละเอียด
 *
 *
 * columns and relationships of "project.budget_group_mapping"
 */
export type ProjectBudgetGroupMapping = {
  __typename?: 'project_budget_group_mapping';
  /** An object relationship */
  budget: ProjectBudget;
  /** An object relationship */
  budget_group: ProjectBudgetGroup;
  /** ประเภทรายละเอียดค่าใช้จ่าย กิจกรรมโครงการ */
  budget_group_id: ProjectBudgetGroupEnum;
  /** รายละเอียดงบประมาณ */
  budget_id: Scalars['uuid'];
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "project.budget_group_mapping" */
export type ProjectBudgetGroupMappingAggregate = {
  __typename?: 'project_budget_group_mapping_aggregate';
  aggregate?: Maybe<ProjectBudgetGroupMappingAggregateFields>;
  nodes: Array<ProjectBudgetGroupMapping>;
};

/** aggregate fields of "project.budget_group_mapping" */
export type ProjectBudgetGroupMappingAggregateFields = {
  __typename?: 'project_budget_group_mapping_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ProjectBudgetGroupMappingMaxFields>;
  min?: Maybe<ProjectBudgetGroupMappingMinFields>;
};


/** aggregate fields of "project.budget_group_mapping" */
export type ProjectBudgetGroupMappingAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectBudgetGroupMappingSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project.budget_group_mapping" */
export type ProjectBudgetGroupMappingAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ProjectBudgetGroupMappingMaxOrderBy>;
  min?: Maybe<ProjectBudgetGroupMappingMinOrderBy>;
};

/** input type for inserting array relation for remote table "project.budget_group_mapping" */
export type ProjectBudgetGroupMappingArrRelInsertInput = {
  data: Array<ProjectBudgetGroupMappingInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectBudgetGroupMappingOnConflict>;
};

/** Boolean expression to filter rows from the table "project.budget_group_mapping". All fields are combined with a logical 'AND'. */
export type ProjectBudgetGroupMappingBoolExp = {
  _and?: Maybe<Array<ProjectBudgetGroupMappingBoolExp>>;
  _not?: Maybe<ProjectBudgetGroupMappingBoolExp>;
  _or?: Maybe<Array<ProjectBudgetGroupMappingBoolExp>>;
  budget?: Maybe<ProjectBudgetBoolExp>;
  budget_group?: Maybe<ProjectBudgetGroupBoolExp>;
  budget_group_id?: Maybe<ProjectBudgetGroupEnumComparisonExp>;
  budget_id?: Maybe<UuidComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "project.budget_group_mapping" */
export enum ProjectBudgetGroupMappingConstraint {
  /** unique or primary key constraint */
  BudgetGroupMappingPkey = 'budget_group_mapping_pkey'
}

/** input type for inserting data into table "project.budget_group_mapping" */
export type ProjectBudgetGroupMappingInsertInput = {
  budget?: Maybe<ProjectBudgetObjRelInsertInput>;
  budget_group?: Maybe<ProjectBudgetGroupObjRelInsertInput>;
  /** ประเภทรายละเอียดค่าใช้จ่าย กิจกรรมโครงการ */
  budget_group_id?: Maybe<ProjectBudgetGroupEnum>;
  /** รายละเอียดงบประมาณ */
  budget_id?: Maybe<Scalars['uuid']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ProjectBudgetGroupMappingMaxFields = {
  __typename?: 'project_budget_group_mapping_max_fields';
  /** รายละเอียดงบประมาณ */
  budget_id?: Maybe<Scalars['uuid']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "project.budget_group_mapping" */
export type ProjectBudgetGroupMappingMaxOrderBy = {
  /** รายละเอียดงบประมาณ */
  budget_id?: Maybe<OrderBy>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectBudgetGroupMappingMinFields = {
  __typename?: 'project_budget_group_mapping_min_fields';
  /** รายละเอียดงบประมาณ */
  budget_id?: Maybe<Scalars['uuid']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "project.budget_group_mapping" */
export type ProjectBudgetGroupMappingMinOrderBy = {
  /** รายละเอียดงบประมาณ */
  budget_id?: Maybe<OrderBy>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "project.budget_group_mapping" */
export type ProjectBudgetGroupMappingMutationResponse = {
  __typename?: 'project_budget_group_mapping_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectBudgetGroupMapping>;
};

/** on conflict condition type for table "project.budget_group_mapping" */
export type ProjectBudgetGroupMappingOnConflict = {
  constraint: ProjectBudgetGroupMappingConstraint;
  update_columns?: Array<ProjectBudgetGroupMappingUpdateColumn>;
  where?: Maybe<ProjectBudgetGroupMappingBoolExp>;
};

/** Ordering options when selecting data from "project.budget_group_mapping". */
export type ProjectBudgetGroupMappingOrderBy = {
  budget?: Maybe<ProjectBudgetOrderBy>;
  budget_group?: Maybe<ProjectBudgetGroupOrderBy>;
  budget_group_id?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: project_budget_group_mapping */
export type ProjectBudgetGroupMappingPkColumnsInput = {
  /** ประเภทรายละเอียดค่าใช้จ่าย กิจกรรมโครงการ */
  budget_group_id: ProjectBudgetGroupEnum;
  /** รายละเอียดงบประมาณ */
  budget_id: Scalars['uuid'];
};

/** select columns of table "project.budget_group_mapping" */
export enum ProjectBudgetGroupMappingSelectColumn {
  /** column name */
  BudgetGroupId = 'budget_group_id',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  DeletedAt = 'deleted_at'
}

/** input type for updating data in table "project.budget_group_mapping" */
export type ProjectBudgetGroupMappingSetInput = {
  /** ประเภทรายละเอียดค่าใช้จ่าย กิจกรรมโครงการ */
  budget_group_id?: Maybe<ProjectBudgetGroupEnum>;
  /** รายละเอียดงบประมาณ */
  budget_id?: Maybe<Scalars['uuid']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "project.budget_group_mapping" */
export enum ProjectBudgetGroupMappingUpdateColumn {
  /** column name */
  BudgetGroupId = 'budget_group_id',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  DeletedAt = 'deleted_at'
}

/** aggregate max on columns */
export type ProjectBudgetGroupMaxFields = {
  __typename?: 'project_budget_group_max_fields';
  /** คำอธิบาย ประเภทรายละเอียดค่าใช้จ่าย กิจกรรมโครงการ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ProjectBudgetGroupMinFields = {
  __typename?: 'project_budget_group_min_fields';
  /** คำอธิบาย ประเภทรายละเอียดค่าใช้จ่าย กิจกรรมโครงการ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "project.budget_group" */
export type ProjectBudgetGroupMutationResponse = {
  __typename?: 'project_budget_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectBudgetGroup>;
};

/** input type for inserting object relation for remote table "project.budget_group" */
export type ProjectBudgetGroupObjRelInsertInput = {
  data: ProjectBudgetGroupInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectBudgetGroupOnConflict>;
};

/** on conflict condition type for table "project.budget_group" */
export type ProjectBudgetGroupOnConflict = {
  constraint: ProjectBudgetGroupConstraint;
  update_columns?: Array<ProjectBudgetGroupUpdateColumn>;
  where?: Maybe<ProjectBudgetGroupBoolExp>;
};

/** Ordering options when selecting data from "project.budget_group". */
export type ProjectBudgetGroupOrderBy = {
  budget_group_mappings_aggregate?: Maybe<ProjectBudgetGroupMappingAggregateOrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  project_budget_items_aggregate?: Maybe<ProjectProjectBudgetItemAggregateOrderBy>;
  project_operating_activities_aggregate?: Maybe<ProjectProjectOperatingActivityAggregateOrderBy>;
};

/** primary key columns input for table: project_budget_group */
export type ProjectBudgetGroupPkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "project.budget_group" */
export enum ProjectBudgetGroupSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "project.budget_group" */
export type ProjectBudgetGroupSetInput = {
  /** คำอธิบาย ประเภทรายละเอียดค่าใช้จ่าย กิจกรรมโครงการ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** update columns of table "project.budget_group" */
export enum ProjectBudgetGroupUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for incrementing numeric columns in table "project.budget" */
export type ProjectBudgetIncInput = {
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<Scalars['numeric']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['numeric']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "project.budget" */
export type ProjectBudgetInsertInput = {
  budget_group_mappings?: Maybe<ProjectBudgetGroupMappingArrRelInsertInput>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<Scalars['numeric']>;
  /** ชื่อ */
  name?: Maybe<Scalars['String']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['numeric']>;
  project_budget_items?: Maybe<ProjectProjectBudgetItemArrRelInsertInput>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  unit?: Maybe<ProjectUnitObjRelInsertInput>;
  /** หน่วยนับ */
  unit_id?: Maybe<ProjectUnitEnum>;
  unit_of_multiplier?: Maybe<ProjectUnitObjRelInsertInput>;
  /** หน่วยนับตัวคูณ */
  unit_of_multiplier_id?: Maybe<ProjectUnitEnum>;
};

/** aggregate max on columns */
export type ProjectBudgetMaxFields = {
  __typename?: 'project_budget_max_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<Scalars['numeric']>;
  /** ชื่อ */
  name?: Maybe<Scalars['String']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['numeric']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "project.budget" */
export type ProjectBudgetMaxOrderBy = {
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<OrderBy>;
  /** ชื่อ */
  name?: Maybe<OrderBy>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectBudgetMinFields = {
  __typename?: 'project_budget_min_fields';
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<Scalars['numeric']>;
  /** ชื่อ */
  name?: Maybe<Scalars['String']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['numeric']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "project.budget" */
export type ProjectBudgetMinOrderBy = {
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<OrderBy>;
  /** ชื่อ */
  name?: Maybe<OrderBy>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** response of any mutation on the table "project.budget" */
export type ProjectBudgetMutationResponse = {
  __typename?: 'project_budget_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectBudget>;
};

/** input type for inserting object relation for remote table "project.budget" */
export type ProjectBudgetObjRelInsertInput = {
  data: ProjectBudgetInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectBudgetOnConflict>;
};

/** on conflict condition type for table "project.budget" */
export type ProjectBudgetOnConflict = {
  constraint: ProjectBudgetConstraint;
  update_columns?: Array<ProjectBudgetUpdateColumn>;
  where?: Maybe<ProjectBudgetBoolExp>;
};

/** Ordering options when selecting data from "project.budget". */
export type ProjectBudgetOrderBy = {
  budget_group_mappings_aggregate?: Maybe<ProjectBudgetGroupMappingAggregateOrderBy>;
  deleted_at?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  max_price_per_unit?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  price_per_unit?: Maybe<OrderBy>;
  project_budget_items_aggregate?: Maybe<ProjectProjectBudgetItemAggregateOrderBy>;
  sequence?: Maybe<OrderBy>;
  unit?: Maybe<ProjectUnitOrderBy>;
  unit_id?: Maybe<OrderBy>;
  unit_of_multiplier?: Maybe<ProjectUnitOrderBy>;
  unit_of_multiplier_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: project_budget */
export type ProjectBudgetPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** columns and relationships of "project.budget_plan" */
export type ProjectBudgetPlan = {
  __typename?: 'project_budget_plan';
  budget_group_id?: Maybe<Scalars['String']>;
  budget_total?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  project?: Maybe<ProjectProject>;
  /** An array relationship */
  project_budget_items: Array<ProjectProjectBudgetItem>;
  /** An aggregate relationship */
  project_budget_items_aggregate: ProjectProjectBudgetItemAggregate;
  project_id?: Maybe<Scalars['uuid']>;
  project_operating_activity_id?: Maybe<Scalars['uuid']>;
  project_operating_activity_name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['numeric']>;
};


/** columns and relationships of "project.budget_plan" */
export type ProjectBudgetPlanProjectBudgetItemsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetItemOrderBy>>;
  where?: Maybe<ProjectProjectBudgetItemBoolExp>;
};


/** columns and relationships of "project.budget_plan" */
export type ProjectBudgetPlanProjectBudgetItemsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetItemOrderBy>>;
  where?: Maybe<ProjectProjectBudgetItemBoolExp>;
};

/** aggregated selection of "project.budget_plan" */
export type ProjectBudgetPlanAggregate = {
  __typename?: 'project_budget_plan_aggregate';
  aggregate?: Maybe<ProjectBudgetPlanAggregateFields>;
  nodes: Array<ProjectBudgetPlan>;
};

/** aggregate fields of "project.budget_plan" */
export type ProjectBudgetPlanAggregateFields = {
  __typename?: 'project_budget_plan_aggregate_fields';
  avg?: Maybe<ProjectBudgetPlanAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectBudgetPlanMaxFields>;
  min?: Maybe<ProjectBudgetPlanMinFields>;
  stddev?: Maybe<ProjectBudgetPlanStddevFields>;
  stddev_pop?: Maybe<ProjectBudgetPlanStddevPopFields>;
  stddev_samp?: Maybe<ProjectBudgetPlanStddevSampFields>;
  sum?: Maybe<ProjectBudgetPlanSumFields>;
  var_pop?: Maybe<ProjectBudgetPlanVarPopFields>;
  var_samp?: Maybe<ProjectBudgetPlanVarSampFields>;
  variance?: Maybe<ProjectBudgetPlanVarianceFields>;
};


/** aggregate fields of "project.budget_plan" */
export type ProjectBudgetPlanAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectBudgetPlanSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project.budget_plan" */
export type ProjectBudgetPlanAggregateOrderBy = {
  avg?: Maybe<ProjectBudgetPlanAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProjectBudgetPlanMaxOrderBy>;
  min?: Maybe<ProjectBudgetPlanMinOrderBy>;
  stddev?: Maybe<ProjectBudgetPlanStddevOrderBy>;
  stddev_pop?: Maybe<ProjectBudgetPlanStddevPopOrderBy>;
  stddev_samp?: Maybe<ProjectBudgetPlanStddevSampOrderBy>;
  sum?: Maybe<ProjectBudgetPlanSumOrderBy>;
  var_pop?: Maybe<ProjectBudgetPlanVarPopOrderBy>;
  var_samp?: Maybe<ProjectBudgetPlanVarSampOrderBy>;
  variance?: Maybe<ProjectBudgetPlanVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "project.budget_plan" */
export type ProjectBudgetPlanArrRelInsertInput = {
  data: Array<ProjectBudgetPlanInsertInput>;
};

/** aggregate avg on columns */
export type ProjectBudgetPlanAvgFields = {
  __typename?: 'project_budget_plan_avg_fields';
  budget_total?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project.budget_plan" */
export type ProjectBudgetPlanAvgOrderBy = {
  budget_total?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "project.budget_plan". All fields are combined with a logical 'AND'. */
export type ProjectBudgetPlanBoolExp = {
  _and?: Maybe<Array<ProjectBudgetPlanBoolExp>>;
  _not?: Maybe<ProjectBudgetPlanBoolExp>;
  _or?: Maybe<Array<ProjectBudgetPlanBoolExp>>;
  budget_group_id?: Maybe<StringComparisonExp>;
  budget_total?: Maybe<NumericComparisonExp>;
  project?: Maybe<ProjectProjectBoolExp>;
  project_budget_items?: Maybe<ProjectProjectBudgetItemBoolExp>;
  project_id?: Maybe<UuidComparisonExp>;
  project_operating_activity_id?: Maybe<UuidComparisonExp>;
  project_operating_activity_name?: Maybe<StringComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
};

/** input type for inserting data into table "project.budget_plan" */
export type ProjectBudgetPlanInsertInput = {
  budget_group_id?: Maybe<Scalars['String']>;
  budget_total?: Maybe<Scalars['numeric']>;
  project?: Maybe<ProjectProjectObjRelInsertInput>;
  project_budget_items?: Maybe<ProjectProjectBudgetItemArrRelInsertInput>;
  project_id?: Maybe<Scalars['uuid']>;
  project_operating_activity_id?: Maybe<Scalars['uuid']>;
  project_operating_activity_name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type ProjectBudgetPlanMaxFields = {
  __typename?: 'project_budget_plan_max_fields';
  budget_group_id?: Maybe<Scalars['String']>;
  budget_total?: Maybe<Scalars['numeric']>;
  project_id?: Maybe<Scalars['uuid']>;
  project_operating_activity_id?: Maybe<Scalars['uuid']>;
  project_operating_activity_name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "project.budget_plan" */
export type ProjectBudgetPlanMaxOrderBy = {
  budget_group_id?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  project_id?: Maybe<OrderBy>;
  project_operating_activity_id?: Maybe<OrderBy>;
  project_operating_activity_name?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectBudgetPlanMinFields = {
  __typename?: 'project_budget_plan_min_fields';
  budget_group_id?: Maybe<Scalars['String']>;
  budget_total?: Maybe<Scalars['numeric']>;
  project_id?: Maybe<Scalars['uuid']>;
  project_operating_activity_id?: Maybe<Scalars['uuid']>;
  project_operating_activity_name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "project.budget_plan" */
export type ProjectBudgetPlanMinOrderBy = {
  budget_group_id?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  project_id?: Maybe<OrderBy>;
  project_operating_activity_id?: Maybe<OrderBy>;
  project_operating_activity_name?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** Ordering options when selecting data from "project.budget_plan". */
export type ProjectBudgetPlanOrderBy = {
  budget_group_id?: Maybe<OrderBy>;
  budget_total?: Maybe<OrderBy>;
  project?: Maybe<ProjectProjectOrderBy>;
  project_budget_items_aggregate?: Maybe<ProjectProjectBudgetItemAggregateOrderBy>;
  project_id?: Maybe<OrderBy>;
  project_operating_activity_id?: Maybe<OrderBy>;
  project_operating_activity_name?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** select columns of table "project.budget_plan" */
export enum ProjectBudgetPlanSelectColumn {
  /** column name */
  BudgetGroupId = 'budget_group_id',
  /** column name */
  BudgetTotal = 'budget_total',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ProjectOperatingActivityId = 'project_operating_activity_id',
  /** column name */
  ProjectOperatingActivityName = 'project_operating_activity_name',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate stddev on columns */
export type ProjectBudgetPlanStddevFields = {
  __typename?: 'project_budget_plan_stddev_fields';
  budget_total?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project.budget_plan" */
export type ProjectBudgetPlanStddevOrderBy = {
  budget_total?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ProjectBudgetPlanStddevPopFields = {
  __typename?: 'project_budget_plan_stddev_pop_fields';
  budget_total?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project.budget_plan" */
export type ProjectBudgetPlanStddevPopOrderBy = {
  budget_total?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ProjectBudgetPlanStddevSampFields = {
  __typename?: 'project_budget_plan_stddev_samp_fields';
  budget_total?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project.budget_plan" */
export type ProjectBudgetPlanStddevSampOrderBy = {
  budget_total?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ProjectBudgetPlanSumFields = {
  __typename?: 'project_budget_plan_sum_fields';
  budget_total?: Maybe<Scalars['numeric']>;
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "project.budget_plan" */
export type ProjectBudgetPlanSumOrderBy = {
  budget_total?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ProjectBudgetPlanVarPopFields = {
  __typename?: 'project_budget_plan_var_pop_fields';
  budget_total?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project.budget_plan" */
export type ProjectBudgetPlanVarPopOrderBy = {
  budget_total?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ProjectBudgetPlanVarSampFields = {
  __typename?: 'project_budget_plan_var_samp_fields';
  budget_total?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project.budget_plan" */
export type ProjectBudgetPlanVarSampOrderBy = {
  budget_total?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProjectBudgetPlanVarianceFields = {
  __typename?: 'project_budget_plan_variance_fields';
  budget_total?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project.budget_plan" */
export type ProjectBudgetPlanVarianceOrderBy = {
  budget_total?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** select columns of table "project.budget" */
export enum ProjectBudgetSelectColumn {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  Id = 'id',
  /** column name */
  MaxPricePerUnit = 'max_price_per_unit',
  /** column name */
  Name = 'name',
  /** column name */
  PricePerUnit = 'price_per_unit',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  UnitId = 'unit_id',
  /** column name */
  UnitOfMultiplierId = 'unit_of_multiplier_id'
}

/** input type for updating data in table "project.budget" */
export type ProjectBudgetSetInput = {
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<Scalars['numeric']>;
  /** ชื่อ */
  name?: Maybe<Scalars['String']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['numeric']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** หน่วยนับ */
  unit_id?: Maybe<ProjectUnitEnum>;
  /** หน่วยนับตัวคูณ */
  unit_of_multiplier_id?: Maybe<ProjectUnitEnum>;
};

/** aggregate stddev on columns */
export type ProjectBudgetStddevFields = {
  __typename?: 'project_budget_stddev_fields';
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year?: Maybe<Scalars['Float']>;
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<Scalars['Float']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project.budget" */
export type ProjectBudgetStddevOrderBy = {
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year?: Maybe<OrderBy>;
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<OrderBy>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ProjectBudgetStddevPopFields = {
  __typename?: 'project_budget_stddev_pop_fields';
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year?: Maybe<Scalars['Float']>;
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<Scalars['Float']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project.budget" */
export type ProjectBudgetStddevPopOrderBy = {
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year?: Maybe<OrderBy>;
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<OrderBy>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ProjectBudgetStddevSampFields = {
  __typename?: 'project_budget_stddev_samp_fields';
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year?: Maybe<Scalars['Float']>;
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<Scalars['Float']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project.budget" */
export type ProjectBudgetStddevSampOrderBy = {
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year?: Maybe<OrderBy>;
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<OrderBy>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ProjectBudgetSumFields = {
  __typename?: 'project_budget_sum_fields';
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<Scalars['numeric']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['numeric']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "project.budget" */
export type ProjectBudgetSumOrderBy = {
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year?: Maybe<OrderBy>;
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<OrderBy>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "project.budget" */
export enum ProjectBudgetUpdateColumn {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  Id = 'id',
  /** column name */
  MaxPricePerUnit = 'max_price_per_unit',
  /** column name */
  Name = 'name',
  /** column name */
  PricePerUnit = 'price_per_unit',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  UnitId = 'unit_id',
  /** column name */
  UnitOfMultiplierId = 'unit_of_multiplier_id'
}

/** aggregate var_pop on columns */
export type ProjectBudgetVarPopFields = {
  __typename?: 'project_budget_var_pop_fields';
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year?: Maybe<Scalars['Float']>;
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<Scalars['Float']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project.budget" */
export type ProjectBudgetVarPopOrderBy = {
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year?: Maybe<OrderBy>;
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<OrderBy>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ProjectBudgetVarSampFields = {
  __typename?: 'project_budget_var_samp_fields';
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year?: Maybe<Scalars['Float']>;
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<Scalars['Float']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project.budget" */
export type ProjectBudgetVarSampOrderBy = {
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year?: Maybe<OrderBy>;
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<OrderBy>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProjectBudgetVarianceFields = {
  __typename?: 'project_budget_variance_fields';
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year?: Maybe<Scalars['Float']>;
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<Scalars['Float']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project.budget" */
export type ProjectBudgetVarianceOrderBy = {
  /** ปีงบประมาณ (ค.ศ.) */
  fiscal_year?: Maybe<OrderBy>;
  /** ราคาสูงสุดต่อหน่วย */
  max_price_per_unit?: Maybe<OrderBy>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/**
 * ประเภทตัวชี้วัดโครงการ
 *
 *
 * columns and relationships of "project.indicator_type"
 */
export type ProjectIndicatorType = {
  __typename?: 'project_indicator_type';
  /** คำอธิบาย ประเภทตัวชี้วัดโครงการ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
  /** An array relationship */
  project_indicators: Array<ProjectProjectIndicator>;
  /** An aggregate relationship */
  project_indicators_aggregate: ProjectProjectIndicatorAggregate;
};


/**
 * ประเภทตัวชี้วัดโครงการ
 *
 *
 * columns and relationships of "project.indicator_type"
 */
export type ProjectIndicatorTypeProjectIndicatorsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectIndicatorSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectIndicatorOrderBy>>;
  where?: Maybe<ProjectProjectIndicatorBoolExp>;
};


/**
 * ประเภทตัวชี้วัดโครงการ
 *
 *
 * columns and relationships of "project.indicator_type"
 */
export type ProjectIndicatorTypeProjectIndicatorsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectIndicatorSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectIndicatorOrderBy>>;
  where?: Maybe<ProjectProjectIndicatorBoolExp>;
};

/** aggregated selection of "project.indicator_type" */
export type ProjectIndicatorTypeAggregate = {
  __typename?: 'project_indicator_type_aggregate';
  aggregate?: Maybe<ProjectIndicatorTypeAggregateFields>;
  nodes: Array<ProjectIndicatorType>;
};

/** aggregate fields of "project.indicator_type" */
export type ProjectIndicatorTypeAggregateFields = {
  __typename?: 'project_indicator_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ProjectIndicatorTypeMaxFields>;
  min?: Maybe<ProjectIndicatorTypeMinFields>;
};


/** aggregate fields of "project.indicator_type" */
export type ProjectIndicatorTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectIndicatorTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "project.indicator_type". All fields are combined with a logical 'AND'. */
export type ProjectIndicatorTypeBoolExp = {
  _and?: Maybe<Array<ProjectIndicatorTypeBoolExp>>;
  _not?: Maybe<ProjectIndicatorTypeBoolExp>;
  _or?: Maybe<Array<ProjectIndicatorTypeBoolExp>>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  project_indicators?: Maybe<ProjectProjectIndicatorBoolExp>;
};

/** unique or primary key constraints on table "project.indicator_type" */
export enum ProjectIndicatorTypeConstraint {
  /** unique or primary key constraint */
  IndicatorTypePkey = 'indicator_type_pkey'
}

export enum ProjectIndicatorTypeEnum {
  /** ตัวชี้วัดระดับผลลัพธ์ (Outcome) */
  Outcome = 'outcome',
  /** ตัวชี้วัดระดับผลผลิต (Output) */
  Output = 'output'
}

/** Boolean expression to compare columns of type "project_indicator_type_enum". All fields are combined with logical 'AND'. */
export type ProjectIndicatorTypeEnumComparisonExp = {
  _eq?: Maybe<ProjectIndicatorTypeEnum>;
  _in?: Maybe<Array<ProjectIndicatorTypeEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ProjectIndicatorTypeEnum>;
  _nin?: Maybe<Array<ProjectIndicatorTypeEnum>>;
};

/** input type for inserting data into table "project.indicator_type" */
export type ProjectIndicatorTypeInsertInput = {
  /** คำอธิบาย ประเภทตัวชี้วัดโครงการ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  project_indicators?: Maybe<ProjectProjectIndicatorArrRelInsertInput>;
};

/** aggregate max on columns */
export type ProjectIndicatorTypeMaxFields = {
  __typename?: 'project_indicator_type_max_fields';
  /** คำอธิบาย ประเภทตัวชี้วัดโครงการ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ProjectIndicatorTypeMinFields = {
  __typename?: 'project_indicator_type_min_fields';
  /** คำอธิบาย ประเภทตัวชี้วัดโครงการ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "project.indicator_type" */
export type ProjectIndicatorTypeMutationResponse = {
  __typename?: 'project_indicator_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectIndicatorType>;
};

/** input type for inserting object relation for remote table "project.indicator_type" */
export type ProjectIndicatorTypeObjRelInsertInput = {
  data: ProjectIndicatorTypeInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectIndicatorTypeOnConflict>;
};

/** on conflict condition type for table "project.indicator_type" */
export type ProjectIndicatorTypeOnConflict = {
  constraint: ProjectIndicatorTypeConstraint;
  update_columns?: Array<ProjectIndicatorTypeUpdateColumn>;
  where?: Maybe<ProjectIndicatorTypeBoolExp>;
};

/** Ordering options when selecting data from "project.indicator_type". */
export type ProjectIndicatorTypeOrderBy = {
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  project_indicators_aggregate?: Maybe<ProjectProjectIndicatorAggregateOrderBy>;
};

/** primary key columns input for table: project_indicator_type */
export type ProjectIndicatorTypePkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "project.indicator_type" */
export enum ProjectIndicatorTypeSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "project.indicator_type" */
export type ProjectIndicatorTypeSetInput = {
  /** คำอธิบาย ประเภทตัวชี้วัดโครงการ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** update columns of table "project.indicator_type" */
export enum ProjectIndicatorTypeUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** columns and relationships of "project.master_plan" */
export type ProjectMasterPlan = {
  __typename?: 'project_master_plan';
  /** An array relationship */
  children: Array<ProjectMasterPlan>;
  /** An aggregate relationship */
  children_aggregate: ProjectMasterPlanAggregate;
  depth?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  parent?: Maybe<ProjectMasterPlan>;
  parent_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "project.master_plan" */
export type ProjectMasterPlanChildrenArgs = {
  distinct_on?: Maybe<Array<ProjectMasterPlanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectMasterPlanOrderBy>>;
  where?: Maybe<ProjectMasterPlanBoolExp>;
};


/** columns and relationships of "project.master_plan" */
export type ProjectMasterPlanChildrenAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectMasterPlanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectMasterPlanOrderBy>>;
  where?: Maybe<ProjectMasterPlanBoolExp>;
};

/** aggregated selection of "project.master_plan" */
export type ProjectMasterPlanAggregate = {
  __typename?: 'project_master_plan_aggregate';
  aggregate?: Maybe<ProjectMasterPlanAggregateFields>;
  nodes: Array<ProjectMasterPlan>;
};

/** aggregate fields of "project.master_plan" */
export type ProjectMasterPlanAggregateFields = {
  __typename?: 'project_master_plan_aggregate_fields';
  avg?: Maybe<ProjectMasterPlanAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectMasterPlanMaxFields>;
  min?: Maybe<ProjectMasterPlanMinFields>;
  stddev?: Maybe<ProjectMasterPlanStddevFields>;
  stddev_pop?: Maybe<ProjectMasterPlanStddevPopFields>;
  stddev_samp?: Maybe<ProjectMasterPlanStddevSampFields>;
  sum?: Maybe<ProjectMasterPlanSumFields>;
  var_pop?: Maybe<ProjectMasterPlanVarPopFields>;
  var_samp?: Maybe<ProjectMasterPlanVarSampFields>;
  variance?: Maybe<ProjectMasterPlanVarianceFields>;
};


/** aggregate fields of "project.master_plan" */
export type ProjectMasterPlanAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectMasterPlanSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project.master_plan" */
export type ProjectMasterPlanAggregateOrderBy = {
  avg?: Maybe<ProjectMasterPlanAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProjectMasterPlanMaxOrderBy>;
  min?: Maybe<ProjectMasterPlanMinOrderBy>;
  stddev?: Maybe<ProjectMasterPlanStddevOrderBy>;
  stddev_pop?: Maybe<ProjectMasterPlanStddevPopOrderBy>;
  stddev_samp?: Maybe<ProjectMasterPlanStddevSampOrderBy>;
  sum?: Maybe<ProjectMasterPlanSumOrderBy>;
  var_pop?: Maybe<ProjectMasterPlanVarPopOrderBy>;
  var_samp?: Maybe<ProjectMasterPlanVarSampOrderBy>;
  variance?: Maybe<ProjectMasterPlanVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "project.master_plan" */
export type ProjectMasterPlanArrRelInsertInput = {
  data: Array<ProjectMasterPlanInsertInput>;
};

/** aggregate avg on columns */
export type ProjectMasterPlanAvgFields = {
  __typename?: 'project_master_plan_avg_fields';
  depth?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project.master_plan" */
export type ProjectMasterPlanAvgOrderBy = {
  depth?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "project.master_plan". All fields are combined with a logical 'AND'. */
export type ProjectMasterPlanBoolExp = {
  _and?: Maybe<Array<ProjectMasterPlanBoolExp>>;
  _not?: Maybe<ProjectMasterPlanBoolExp>;
  _or?: Maybe<Array<ProjectMasterPlanBoolExp>>;
  children?: Maybe<ProjectMasterPlanBoolExp>;
  depth?: Maybe<IntComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  parent?: Maybe<ProjectMasterPlanBoolExp>;
  parent_id?: Maybe<StringComparisonExp>;
};

/** input type for incrementing numeric columns in table "project.master_plan" */
export type ProjectMasterPlanIncInput = {
  depth?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "project.master_plan" */
export type ProjectMasterPlanInsertInput = {
  children?: Maybe<ProjectMasterPlanArrRelInsertInput>;
  depth?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<ProjectMasterPlanObjRelInsertInput>;
  parent_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ProjectMasterPlanMaxFields = {
  __typename?: 'project_master_plan_max_fields';
  depth?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "project.master_plan" */
export type ProjectMasterPlanMaxOrderBy = {
  depth?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  parent_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectMasterPlanMinFields = {
  __typename?: 'project_master_plan_min_fields';
  depth?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "project.master_plan" */
export type ProjectMasterPlanMinOrderBy = {
  depth?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  parent_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "project.master_plan" */
export type ProjectMasterPlanMutationResponse = {
  __typename?: 'project_master_plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectMasterPlan>;
};

/** input type for inserting object relation for remote table "project.master_plan" */
export type ProjectMasterPlanObjRelInsertInput = {
  data: ProjectMasterPlanInsertInput;
};

/** Ordering options when selecting data from "project.master_plan". */
export type ProjectMasterPlanOrderBy = {
  children_aggregate?: Maybe<ProjectMasterPlanAggregateOrderBy>;
  depth?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  parent?: Maybe<ProjectMasterPlanOrderBy>;
  parent_id?: Maybe<OrderBy>;
};

/** select columns of table "project.master_plan" */
export enum ProjectMasterPlanSelectColumn {
  /** column name */
  Depth = 'depth',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id'
}

/** input type for updating data in table "project.master_plan" */
export type ProjectMasterPlanSetInput = {
  depth?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ProjectMasterPlanStddevFields = {
  __typename?: 'project_master_plan_stddev_fields';
  depth?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project.master_plan" */
export type ProjectMasterPlanStddevOrderBy = {
  depth?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ProjectMasterPlanStddevPopFields = {
  __typename?: 'project_master_plan_stddev_pop_fields';
  depth?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project.master_plan" */
export type ProjectMasterPlanStddevPopOrderBy = {
  depth?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ProjectMasterPlanStddevSampFields = {
  __typename?: 'project_master_plan_stddev_samp_fields';
  depth?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project.master_plan" */
export type ProjectMasterPlanStddevSampOrderBy = {
  depth?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ProjectMasterPlanSumFields = {
  __typename?: 'project_master_plan_sum_fields';
  depth?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "project.master_plan" */
export type ProjectMasterPlanSumOrderBy = {
  depth?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ProjectMasterPlanVarPopFields = {
  __typename?: 'project_master_plan_var_pop_fields';
  depth?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project.master_plan" */
export type ProjectMasterPlanVarPopOrderBy = {
  depth?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ProjectMasterPlanVarSampFields = {
  __typename?: 'project_master_plan_var_samp_fields';
  depth?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project.master_plan" */
export type ProjectMasterPlanVarSampOrderBy = {
  depth?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProjectMasterPlanVarianceFields = {
  __typename?: 'project_master_plan_variance_fields';
  depth?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project.master_plan" */
export type ProjectMasterPlanVarianceOrderBy = {
  depth?: Maybe<OrderBy>;
};

/**
 * ยุทธศาสตร์ชาติ
 *
 *
 * columns and relationships of "project.national_strategy"
 */
export type ProjectNationalStrategy = {
  __typename?: 'project_national_strategy';
  /** ลำดับชั้น */
  depth: Scalars['Int'];
  /** Primary key */
  id: Scalars['String'];
  /** ชื่อรายการ */
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  national_strategies: Array<ProjectNationalStrategy>;
  /** An aggregate relationship */
  national_strategies_aggregate: ProjectNationalStrategyAggregate;
  /** An object relationship */
  parent?: Maybe<ProjectNationalStrategy>;
  /** Parent Id */
  parent_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  projects_by_national_strategy_lvl1: Array<ProjectProject>;
  /** An aggregate relationship */
  projects_by_national_strategy_lvl1_aggregate: ProjectProjectAggregate;
  /** An array relationship */
  projects_by_national_strategy_lvl2: Array<ProjectProject>;
  /** An aggregate relationship */
  projects_by_national_strategy_lvl2_aggregate: ProjectProjectAggregate;
  /** An array relationship */
  projects_by_national_strategy_lvl3: Array<ProjectProject>;
  /** An aggregate relationship */
  projects_by_national_strategy_lvl3_aggregate: ProjectProjectAggregate;
  /** An array relationship */
  projects_by_national_strategy_lvl4: Array<ProjectProject>;
  /** An aggregate relationship */
  projects_by_national_strategy_lvl4_aggregate: ProjectProjectAggregate;
};


/**
 * ยุทธศาสตร์ชาติ
 *
 *
 * columns and relationships of "project.national_strategy"
 */
export type ProjectNationalStrategyNationalStrategiesArgs = {
  distinct_on?: Maybe<Array<ProjectNationalStrategySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectNationalStrategyOrderBy>>;
  where?: Maybe<ProjectNationalStrategyBoolExp>;
};


/**
 * ยุทธศาสตร์ชาติ
 *
 *
 * columns and relationships of "project.national_strategy"
 */
export type ProjectNationalStrategyNationalStrategiesAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectNationalStrategySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectNationalStrategyOrderBy>>;
  where?: Maybe<ProjectNationalStrategyBoolExp>;
};


/**
 * ยุทธศาสตร์ชาติ
 *
 *
 * columns and relationships of "project.national_strategy"
 */
export type ProjectNationalStrategyProjectsByNationalStrategyLvl1Args = {
  distinct_on?: Maybe<Array<ProjectProjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOrderBy>>;
  where?: Maybe<ProjectProjectBoolExp>;
};


/**
 * ยุทธศาสตร์ชาติ
 *
 *
 * columns and relationships of "project.national_strategy"
 */
export type ProjectNationalStrategyProjectsByNationalStrategyLvl1AggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOrderBy>>;
  where?: Maybe<ProjectProjectBoolExp>;
};


/**
 * ยุทธศาสตร์ชาติ
 *
 *
 * columns and relationships of "project.national_strategy"
 */
export type ProjectNationalStrategyProjectsByNationalStrategyLvl2Args = {
  distinct_on?: Maybe<Array<ProjectProjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOrderBy>>;
  where?: Maybe<ProjectProjectBoolExp>;
};


/**
 * ยุทธศาสตร์ชาติ
 *
 *
 * columns and relationships of "project.national_strategy"
 */
export type ProjectNationalStrategyProjectsByNationalStrategyLvl2AggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOrderBy>>;
  where?: Maybe<ProjectProjectBoolExp>;
};


/**
 * ยุทธศาสตร์ชาติ
 *
 *
 * columns and relationships of "project.national_strategy"
 */
export type ProjectNationalStrategyProjectsByNationalStrategyLvl3Args = {
  distinct_on?: Maybe<Array<ProjectProjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOrderBy>>;
  where?: Maybe<ProjectProjectBoolExp>;
};


/**
 * ยุทธศาสตร์ชาติ
 *
 *
 * columns and relationships of "project.national_strategy"
 */
export type ProjectNationalStrategyProjectsByNationalStrategyLvl3AggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOrderBy>>;
  where?: Maybe<ProjectProjectBoolExp>;
};


/**
 * ยุทธศาสตร์ชาติ
 *
 *
 * columns and relationships of "project.national_strategy"
 */
export type ProjectNationalStrategyProjectsByNationalStrategyLvl4Args = {
  distinct_on?: Maybe<Array<ProjectProjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOrderBy>>;
  where?: Maybe<ProjectProjectBoolExp>;
};


/**
 * ยุทธศาสตร์ชาติ
 *
 *
 * columns and relationships of "project.national_strategy"
 */
export type ProjectNationalStrategyProjectsByNationalStrategyLvl4AggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOrderBy>>;
  where?: Maybe<ProjectProjectBoolExp>;
};

/** aggregated selection of "project.national_strategy" */
export type ProjectNationalStrategyAggregate = {
  __typename?: 'project_national_strategy_aggregate';
  aggregate?: Maybe<ProjectNationalStrategyAggregateFields>;
  nodes: Array<ProjectNationalStrategy>;
};

/** aggregate fields of "project.national_strategy" */
export type ProjectNationalStrategyAggregateFields = {
  __typename?: 'project_national_strategy_aggregate_fields';
  avg?: Maybe<ProjectNationalStrategyAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectNationalStrategyMaxFields>;
  min?: Maybe<ProjectNationalStrategyMinFields>;
  stddev?: Maybe<ProjectNationalStrategyStddevFields>;
  stddev_pop?: Maybe<ProjectNationalStrategyStddevPopFields>;
  stddev_samp?: Maybe<ProjectNationalStrategyStddevSampFields>;
  sum?: Maybe<ProjectNationalStrategySumFields>;
  var_pop?: Maybe<ProjectNationalStrategyVarPopFields>;
  var_samp?: Maybe<ProjectNationalStrategyVarSampFields>;
  variance?: Maybe<ProjectNationalStrategyVarianceFields>;
};


/** aggregate fields of "project.national_strategy" */
export type ProjectNationalStrategyAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectNationalStrategySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project.national_strategy" */
export type ProjectNationalStrategyAggregateOrderBy = {
  avg?: Maybe<ProjectNationalStrategyAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProjectNationalStrategyMaxOrderBy>;
  min?: Maybe<ProjectNationalStrategyMinOrderBy>;
  stddev?: Maybe<ProjectNationalStrategyStddevOrderBy>;
  stddev_pop?: Maybe<ProjectNationalStrategyStddevPopOrderBy>;
  stddev_samp?: Maybe<ProjectNationalStrategyStddevSampOrderBy>;
  sum?: Maybe<ProjectNationalStrategySumOrderBy>;
  var_pop?: Maybe<ProjectNationalStrategyVarPopOrderBy>;
  var_samp?: Maybe<ProjectNationalStrategyVarSampOrderBy>;
  variance?: Maybe<ProjectNationalStrategyVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "project.national_strategy" */
export type ProjectNationalStrategyArrRelInsertInput = {
  data: Array<ProjectNationalStrategyInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectNationalStrategyOnConflict>;
};

/** aggregate avg on columns */
export type ProjectNationalStrategyAvgFields = {
  __typename?: 'project_national_strategy_avg_fields';
  /** ลำดับชั้น */
  depth?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project.national_strategy" */
export type ProjectNationalStrategyAvgOrderBy = {
  /** ลำดับชั้น */
  depth?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "project.national_strategy". All fields are combined with a logical 'AND'. */
export type ProjectNationalStrategyBoolExp = {
  _and?: Maybe<Array<ProjectNationalStrategyBoolExp>>;
  _not?: Maybe<ProjectNationalStrategyBoolExp>;
  _or?: Maybe<Array<ProjectNationalStrategyBoolExp>>;
  depth?: Maybe<IntComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  national_strategies?: Maybe<ProjectNationalStrategyBoolExp>;
  parent?: Maybe<ProjectNationalStrategyBoolExp>;
  parent_id?: Maybe<StringComparisonExp>;
  projects_by_national_strategy_lvl1?: Maybe<ProjectProjectBoolExp>;
  projects_by_national_strategy_lvl2?: Maybe<ProjectProjectBoolExp>;
  projects_by_national_strategy_lvl3?: Maybe<ProjectProjectBoolExp>;
  projects_by_national_strategy_lvl4?: Maybe<ProjectProjectBoolExp>;
};

/** unique or primary key constraints on table "project.national_strategy" */
export enum ProjectNationalStrategyConstraint {
  /** unique or primary key constraint */
  NationalStrategyPkey = 'national_strategy_pkey'
}

/** input type for incrementing numeric columns in table "project.national_strategy" */
export type ProjectNationalStrategyIncInput = {
  /** ลำดับชั้น */
  depth?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "project.national_strategy" */
export type ProjectNationalStrategyInsertInput = {
  /** ลำดับชั้น */
  depth?: Maybe<Scalars['Int']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ชื่อรายการ */
  name?: Maybe<Scalars['String']>;
  national_strategies?: Maybe<ProjectNationalStrategyArrRelInsertInput>;
  parent?: Maybe<ProjectNationalStrategyObjRelInsertInput>;
  /** Parent Id */
  parent_id?: Maybe<Scalars['String']>;
  projects_by_national_strategy_lvl1?: Maybe<ProjectProjectArrRelInsertInput>;
  projects_by_national_strategy_lvl2?: Maybe<ProjectProjectArrRelInsertInput>;
  projects_by_national_strategy_lvl3?: Maybe<ProjectProjectArrRelInsertInput>;
  projects_by_national_strategy_lvl4?: Maybe<ProjectProjectArrRelInsertInput>;
};

/** aggregate max on columns */
export type ProjectNationalStrategyMaxFields = {
  __typename?: 'project_national_strategy_max_fields';
  /** ลำดับชั้น */
  depth?: Maybe<Scalars['Int']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ชื่อรายการ */
  name?: Maybe<Scalars['String']>;
  /** Parent Id */
  parent_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "project.national_strategy" */
export type ProjectNationalStrategyMaxOrderBy = {
  /** ลำดับชั้น */
  depth?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ชื่อรายการ */
  name?: Maybe<OrderBy>;
  /** Parent Id */
  parent_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectNationalStrategyMinFields = {
  __typename?: 'project_national_strategy_min_fields';
  /** ลำดับชั้น */
  depth?: Maybe<Scalars['Int']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ชื่อรายการ */
  name?: Maybe<Scalars['String']>;
  /** Parent Id */
  parent_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "project.national_strategy" */
export type ProjectNationalStrategyMinOrderBy = {
  /** ลำดับชั้น */
  depth?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ชื่อรายการ */
  name?: Maybe<OrderBy>;
  /** Parent Id */
  parent_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "project.national_strategy" */
export type ProjectNationalStrategyMutationResponse = {
  __typename?: 'project_national_strategy_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectNationalStrategy>;
};

/** input type for inserting object relation for remote table "project.national_strategy" */
export type ProjectNationalStrategyObjRelInsertInput = {
  data: ProjectNationalStrategyInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectNationalStrategyOnConflict>;
};

/** on conflict condition type for table "project.national_strategy" */
export type ProjectNationalStrategyOnConflict = {
  constraint: ProjectNationalStrategyConstraint;
  update_columns?: Array<ProjectNationalStrategyUpdateColumn>;
  where?: Maybe<ProjectNationalStrategyBoolExp>;
};

/** Ordering options when selecting data from "project.national_strategy". */
export type ProjectNationalStrategyOrderBy = {
  depth?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  national_strategies_aggregate?: Maybe<ProjectNationalStrategyAggregateOrderBy>;
  parent?: Maybe<ProjectNationalStrategyOrderBy>;
  parent_id?: Maybe<OrderBy>;
  projects_by_national_strategy_lvl1_aggregate?: Maybe<ProjectProjectAggregateOrderBy>;
  projects_by_national_strategy_lvl2_aggregate?: Maybe<ProjectProjectAggregateOrderBy>;
  projects_by_national_strategy_lvl3_aggregate?: Maybe<ProjectProjectAggregateOrderBy>;
  projects_by_national_strategy_lvl4_aggregate?: Maybe<ProjectProjectAggregateOrderBy>;
};

/** primary key columns input for table: project_national_strategy */
export type ProjectNationalStrategyPkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "project.national_strategy" */
export enum ProjectNationalStrategySelectColumn {
  /** column name */
  Depth = 'depth',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id'
}

/** input type for updating data in table "project.national_strategy" */
export type ProjectNationalStrategySetInput = {
  /** ลำดับชั้น */
  depth?: Maybe<Scalars['Int']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ชื่อรายการ */
  name?: Maybe<Scalars['String']>;
  /** Parent Id */
  parent_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ProjectNationalStrategyStddevFields = {
  __typename?: 'project_national_strategy_stddev_fields';
  /** ลำดับชั้น */
  depth?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project.national_strategy" */
export type ProjectNationalStrategyStddevOrderBy = {
  /** ลำดับชั้น */
  depth?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ProjectNationalStrategyStddevPopFields = {
  __typename?: 'project_national_strategy_stddev_pop_fields';
  /** ลำดับชั้น */
  depth?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project.national_strategy" */
export type ProjectNationalStrategyStddevPopOrderBy = {
  /** ลำดับชั้น */
  depth?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ProjectNationalStrategyStddevSampFields = {
  __typename?: 'project_national_strategy_stddev_samp_fields';
  /** ลำดับชั้น */
  depth?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project.national_strategy" */
export type ProjectNationalStrategyStddevSampOrderBy = {
  /** ลำดับชั้น */
  depth?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ProjectNationalStrategySumFields = {
  __typename?: 'project_national_strategy_sum_fields';
  /** ลำดับชั้น */
  depth?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "project.national_strategy" */
export type ProjectNationalStrategySumOrderBy = {
  /** ลำดับชั้น */
  depth?: Maybe<OrderBy>;
};

/** update columns of table "project.national_strategy" */
export enum ProjectNationalStrategyUpdateColumn {
  /** column name */
  Depth = 'depth',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id'
}

/** aggregate var_pop on columns */
export type ProjectNationalStrategyVarPopFields = {
  __typename?: 'project_national_strategy_var_pop_fields';
  /** ลำดับชั้น */
  depth?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project.national_strategy" */
export type ProjectNationalStrategyVarPopOrderBy = {
  /** ลำดับชั้น */
  depth?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ProjectNationalStrategyVarSampFields = {
  __typename?: 'project_national_strategy_var_samp_fields';
  /** ลำดับชั้น */
  depth?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project.national_strategy" */
export type ProjectNationalStrategyVarSampOrderBy = {
  /** ลำดับชั้น */
  depth?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProjectNationalStrategyVarianceFields = {
  __typename?: 'project_national_strategy_variance_fields';
  /** ลำดับชั้น */
  depth?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project.national_strategy" */
export type ProjectNationalStrategyVarianceOrderBy = {
  /** ลำดับชั้น */
  depth?: Maybe<OrderBy>;
};

/**
 * รายงานผลดำเนินงาน
 *
 *
 * columns and relationships of "project.operating_activity_progress"
 */
export type ProjectOperatingActivityProgress = {
  __typename?: 'project_operating_activity_progress';
  /** Primary key */
  id: Scalars['uuid'];
  /** เดือน (1-12) */
  month: Scalars['Int'];
  /** An array relationship */
  operating_activity_progress_revisions: Array<ProjectOperatingActivityProgressRevision>;
  /** An aggregate relationship */
  operating_activity_progress_revisions_aggregate: ProjectOperatingActivityProgressRevisionAggregate;
  /** An object relationship */
  project_operating_activity: ProjectProjectOperatingActivity;
  /** กิจกรรมดำเนินงานโครงการ */
  project_operating_activity_id: Scalars['uuid'];
  /** Revision */
  revision?: Maybe<Scalars['numeric']>;
  /** งบประมาณ */
  spend?: Maybe<Scalars['numeric']>;
  spend_plan?: Maybe<Scalars['numeric']>;
  /** งาน */
  workload?: Maybe<Scalars['numeric']>;
  workload_plan?: Maybe<Scalars['numeric']>;
};


/**
 * รายงานผลดำเนินงาน
 *
 *
 * columns and relationships of "project.operating_activity_progress"
 */
export type ProjectOperatingActivityProgressOperatingActivityProgressRevisionsArgs = {
  distinct_on?: Maybe<Array<ProjectOperatingActivityProgressRevisionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectOperatingActivityProgressRevisionOrderBy>>;
  where?: Maybe<ProjectOperatingActivityProgressRevisionBoolExp>;
};


/**
 * รายงานผลดำเนินงาน
 *
 *
 * columns and relationships of "project.operating_activity_progress"
 */
export type ProjectOperatingActivityProgressOperatingActivityProgressRevisionsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectOperatingActivityProgressRevisionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectOperatingActivityProgressRevisionOrderBy>>;
  where?: Maybe<ProjectOperatingActivityProgressRevisionBoolExp>;
};

/** aggregated selection of "project.operating_activity_progress" */
export type ProjectOperatingActivityProgressAggregate = {
  __typename?: 'project_operating_activity_progress_aggregate';
  aggregate?: Maybe<ProjectOperatingActivityProgressAggregateFields>;
  nodes: Array<ProjectOperatingActivityProgress>;
};

/** aggregate fields of "project.operating_activity_progress" */
export type ProjectOperatingActivityProgressAggregateFields = {
  __typename?: 'project_operating_activity_progress_aggregate_fields';
  avg?: Maybe<ProjectOperatingActivityProgressAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectOperatingActivityProgressMaxFields>;
  min?: Maybe<ProjectOperatingActivityProgressMinFields>;
  stddev?: Maybe<ProjectOperatingActivityProgressStddevFields>;
  stddev_pop?: Maybe<ProjectOperatingActivityProgressStddevPopFields>;
  stddev_samp?: Maybe<ProjectOperatingActivityProgressStddevSampFields>;
  sum?: Maybe<ProjectOperatingActivityProgressSumFields>;
  var_pop?: Maybe<ProjectOperatingActivityProgressVarPopFields>;
  var_samp?: Maybe<ProjectOperatingActivityProgressVarSampFields>;
  variance?: Maybe<ProjectOperatingActivityProgressVarianceFields>;
};


/** aggregate fields of "project.operating_activity_progress" */
export type ProjectOperatingActivityProgressAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectOperatingActivityProgressSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project.operating_activity_progress" */
export type ProjectOperatingActivityProgressAggregateOrderBy = {
  avg?: Maybe<ProjectOperatingActivityProgressAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProjectOperatingActivityProgressMaxOrderBy>;
  min?: Maybe<ProjectOperatingActivityProgressMinOrderBy>;
  stddev?: Maybe<ProjectOperatingActivityProgressStddevOrderBy>;
  stddev_pop?: Maybe<ProjectOperatingActivityProgressStddevPopOrderBy>;
  stddev_samp?: Maybe<ProjectOperatingActivityProgressStddevSampOrderBy>;
  sum?: Maybe<ProjectOperatingActivityProgressSumOrderBy>;
  var_pop?: Maybe<ProjectOperatingActivityProgressVarPopOrderBy>;
  var_samp?: Maybe<ProjectOperatingActivityProgressVarSampOrderBy>;
  variance?: Maybe<ProjectOperatingActivityProgressVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "project.operating_activity_progress" */
export type ProjectOperatingActivityProgressArrRelInsertInput = {
  data: Array<ProjectOperatingActivityProgressInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectOperatingActivityProgressOnConflict>;
};

/** aggregate avg on columns */
export type ProjectOperatingActivityProgressAvgFields = {
  __typename?: 'project_operating_activity_progress_avg_fields';
  /** เดือน (1-12) */
  month?: Maybe<Scalars['Float']>;
  /** Revision */
  revision?: Maybe<Scalars['Float']>;
  /** งบประมาณ */
  spend?: Maybe<Scalars['Float']>;
  spend_plan?: Maybe<Scalars['Float']>;
  /** งาน */
  workload?: Maybe<Scalars['Float']>;
  workload_plan?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project.operating_activity_progress" */
export type ProjectOperatingActivityProgressAvgOrderBy = {
  /** เดือน (1-12) */
  month?: Maybe<OrderBy>;
  /** Revision */
  revision?: Maybe<OrderBy>;
  /** งบประมาณ */
  spend?: Maybe<OrderBy>;
  spend_plan?: Maybe<OrderBy>;
  /** งาน */
  workload?: Maybe<OrderBy>;
  workload_plan?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "project.operating_activity_progress". All fields are combined with a logical 'AND'. */
export type ProjectOperatingActivityProgressBoolExp = {
  _and?: Maybe<Array<ProjectOperatingActivityProgressBoolExp>>;
  _not?: Maybe<ProjectOperatingActivityProgressBoolExp>;
  _or?: Maybe<Array<ProjectOperatingActivityProgressBoolExp>>;
  id?: Maybe<UuidComparisonExp>;
  month?: Maybe<IntComparisonExp>;
  operating_activity_progress_revisions?: Maybe<ProjectOperatingActivityProgressRevisionBoolExp>;
  project_operating_activity?: Maybe<ProjectProjectOperatingActivityBoolExp>;
  project_operating_activity_id?: Maybe<UuidComparisonExp>;
  revision?: Maybe<NumericComparisonExp>;
  spend?: Maybe<NumericComparisonExp>;
  spend_plan?: Maybe<NumericComparisonExp>;
  workload?: Maybe<NumericComparisonExp>;
  workload_plan?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "project.operating_activity_progress" */
export enum ProjectOperatingActivityProgressConstraint {
  /** unique or primary key constraint */
  OperatingActivityProgressPkey = 'operating_activity_progress_pkey'
}

/** input type for incrementing numeric columns in table "project.operating_activity_progress" */
export type ProjectOperatingActivityProgressIncInput = {
  /** เดือน (1-12) */
  month?: Maybe<Scalars['Int']>;
  /** Revision */
  revision?: Maybe<Scalars['numeric']>;
  /** งบประมาณ */
  spend?: Maybe<Scalars['numeric']>;
  spend_plan?: Maybe<Scalars['numeric']>;
  /** งาน */
  workload?: Maybe<Scalars['numeric']>;
  workload_plan?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "project.operating_activity_progress" */
export type ProjectOperatingActivityProgressInsertInput = {
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** เดือน (1-12) */
  month?: Maybe<Scalars['Int']>;
  operating_activity_progress_revisions?: Maybe<ProjectOperatingActivityProgressRevisionArrRelInsertInput>;
  project_operating_activity?: Maybe<ProjectProjectOperatingActivityObjRelInsertInput>;
  /** กิจกรรมดำเนินงานโครงการ */
  project_operating_activity_id?: Maybe<Scalars['uuid']>;
  /** Revision */
  revision?: Maybe<Scalars['numeric']>;
  /** งบประมาณ */
  spend?: Maybe<Scalars['numeric']>;
  spend_plan?: Maybe<Scalars['numeric']>;
  /** งาน */
  workload?: Maybe<Scalars['numeric']>;
  workload_plan?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type ProjectOperatingActivityProgressMaxFields = {
  __typename?: 'project_operating_activity_progress_max_fields';
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** เดือน (1-12) */
  month?: Maybe<Scalars['Int']>;
  /** กิจกรรมดำเนินงานโครงการ */
  project_operating_activity_id?: Maybe<Scalars['uuid']>;
  /** Revision */
  revision?: Maybe<Scalars['numeric']>;
  /** งบประมาณ */
  spend?: Maybe<Scalars['numeric']>;
  spend_plan?: Maybe<Scalars['numeric']>;
  /** งาน */
  workload?: Maybe<Scalars['numeric']>;
  workload_plan?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "project.operating_activity_progress" */
export type ProjectOperatingActivityProgressMaxOrderBy = {
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** เดือน (1-12) */
  month?: Maybe<OrderBy>;
  /** กิจกรรมดำเนินงานโครงการ */
  project_operating_activity_id?: Maybe<OrderBy>;
  /** Revision */
  revision?: Maybe<OrderBy>;
  /** งบประมาณ */
  spend?: Maybe<OrderBy>;
  spend_plan?: Maybe<OrderBy>;
  /** งาน */
  workload?: Maybe<OrderBy>;
  workload_plan?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectOperatingActivityProgressMinFields = {
  __typename?: 'project_operating_activity_progress_min_fields';
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** เดือน (1-12) */
  month?: Maybe<Scalars['Int']>;
  /** กิจกรรมดำเนินงานโครงการ */
  project_operating_activity_id?: Maybe<Scalars['uuid']>;
  /** Revision */
  revision?: Maybe<Scalars['numeric']>;
  /** งบประมาณ */
  spend?: Maybe<Scalars['numeric']>;
  spend_plan?: Maybe<Scalars['numeric']>;
  /** งาน */
  workload?: Maybe<Scalars['numeric']>;
  workload_plan?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "project.operating_activity_progress" */
export type ProjectOperatingActivityProgressMinOrderBy = {
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** เดือน (1-12) */
  month?: Maybe<OrderBy>;
  /** กิจกรรมดำเนินงานโครงการ */
  project_operating_activity_id?: Maybe<OrderBy>;
  /** Revision */
  revision?: Maybe<OrderBy>;
  /** งบประมาณ */
  spend?: Maybe<OrderBy>;
  spend_plan?: Maybe<OrderBy>;
  /** งาน */
  workload?: Maybe<OrderBy>;
  workload_plan?: Maybe<OrderBy>;
};

/** response of any mutation on the table "project.operating_activity_progress" */
export type ProjectOperatingActivityProgressMutationResponse = {
  __typename?: 'project_operating_activity_progress_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectOperatingActivityProgress>;
};

/** input type for inserting object relation for remote table "project.operating_activity_progress" */
export type ProjectOperatingActivityProgressObjRelInsertInput = {
  data: ProjectOperatingActivityProgressInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectOperatingActivityProgressOnConflict>;
};

/** on conflict condition type for table "project.operating_activity_progress" */
export type ProjectOperatingActivityProgressOnConflict = {
  constraint: ProjectOperatingActivityProgressConstraint;
  update_columns?: Array<ProjectOperatingActivityProgressUpdateColumn>;
  where?: Maybe<ProjectOperatingActivityProgressBoolExp>;
};

/** Ordering options when selecting data from "project.operating_activity_progress". */
export type ProjectOperatingActivityProgressOrderBy = {
  id?: Maybe<OrderBy>;
  month?: Maybe<OrderBy>;
  operating_activity_progress_revisions_aggregate?: Maybe<ProjectOperatingActivityProgressRevisionAggregateOrderBy>;
  project_operating_activity?: Maybe<ProjectProjectOperatingActivityOrderBy>;
  project_operating_activity_id?: Maybe<OrderBy>;
  revision?: Maybe<OrderBy>;
  spend?: Maybe<OrderBy>;
  spend_plan?: Maybe<OrderBy>;
  workload?: Maybe<OrderBy>;
  workload_plan?: Maybe<OrderBy>;
};

/** primary key columns input for table: project_operating_activity_progress */
export type ProjectOperatingActivityProgressPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/**
 * ประวัติการเปลี่ยนแปลงผลการดำเนินงาน
 *
 *
 * columns and relationships of "project.operating_activity_progress_revision"
 */
export type ProjectOperatingActivityProgressRevision = {
  __typename?: 'project_operating_activity_progress_revision';
  /** วันที่บันทึกข้อมูล */
  created_at: Scalars['timestamptz'];
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** ข้อมูลใหม่ */
  new_value?: Maybe<Scalars['String']>;
  /** ข้อมูลเก่า */
  old_value?: Maybe<Scalars['String']>;
  /** An object relationship */
  operating_activity_progress: ProjectOperatingActivityProgress;
  /** รายงานผลดำเนินงาน */
  operating_activity_progress_id: Scalars['uuid'];
  /** Revision */
  revision?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "project.operating_activity_progress_revision" */
export type ProjectOperatingActivityProgressRevisionAggregate = {
  __typename?: 'project_operating_activity_progress_revision_aggregate';
  aggregate?: Maybe<ProjectOperatingActivityProgressRevisionAggregateFields>;
  nodes: Array<ProjectOperatingActivityProgressRevision>;
};

/** aggregate fields of "project.operating_activity_progress_revision" */
export type ProjectOperatingActivityProgressRevisionAggregateFields = {
  __typename?: 'project_operating_activity_progress_revision_aggregate_fields';
  avg?: Maybe<ProjectOperatingActivityProgressRevisionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectOperatingActivityProgressRevisionMaxFields>;
  min?: Maybe<ProjectOperatingActivityProgressRevisionMinFields>;
  stddev?: Maybe<ProjectOperatingActivityProgressRevisionStddevFields>;
  stddev_pop?: Maybe<ProjectOperatingActivityProgressRevisionStddevPopFields>;
  stddev_samp?: Maybe<ProjectOperatingActivityProgressRevisionStddevSampFields>;
  sum?: Maybe<ProjectOperatingActivityProgressRevisionSumFields>;
  var_pop?: Maybe<ProjectOperatingActivityProgressRevisionVarPopFields>;
  var_samp?: Maybe<ProjectOperatingActivityProgressRevisionVarSampFields>;
  variance?: Maybe<ProjectOperatingActivityProgressRevisionVarianceFields>;
};


/** aggregate fields of "project.operating_activity_progress_revision" */
export type ProjectOperatingActivityProgressRevisionAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectOperatingActivityProgressRevisionSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project.operating_activity_progress_revision" */
export type ProjectOperatingActivityProgressRevisionAggregateOrderBy = {
  avg?: Maybe<ProjectOperatingActivityProgressRevisionAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProjectOperatingActivityProgressRevisionMaxOrderBy>;
  min?: Maybe<ProjectOperatingActivityProgressRevisionMinOrderBy>;
  stddev?: Maybe<ProjectOperatingActivityProgressRevisionStddevOrderBy>;
  stddev_pop?: Maybe<ProjectOperatingActivityProgressRevisionStddevPopOrderBy>;
  stddev_samp?: Maybe<ProjectOperatingActivityProgressRevisionStddevSampOrderBy>;
  sum?: Maybe<ProjectOperatingActivityProgressRevisionSumOrderBy>;
  var_pop?: Maybe<ProjectOperatingActivityProgressRevisionVarPopOrderBy>;
  var_samp?: Maybe<ProjectOperatingActivityProgressRevisionVarSampOrderBy>;
  variance?: Maybe<ProjectOperatingActivityProgressRevisionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "project.operating_activity_progress_revision" */
export type ProjectOperatingActivityProgressRevisionArrRelInsertInput = {
  data: Array<ProjectOperatingActivityProgressRevisionInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectOperatingActivityProgressRevisionOnConflict>;
};

/** aggregate avg on columns */
export type ProjectOperatingActivityProgressRevisionAvgFields = {
  __typename?: 'project_operating_activity_progress_revision_avg_fields';
  /** Revision */
  revision?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project.operating_activity_progress_revision" */
export type ProjectOperatingActivityProgressRevisionAvgOrderBy = {
  /** Revision */
  revision?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "project.operating_activity_progress_revision". All fields are combined with a logical 'AND'. */
export type ProjectOperatingActivityProgressRevisionBoolExp = {
  _and?: Maybe<Array<ProjectOperatingActivityProgressRevisionBoolExp>>;
  _not?: Maybe<ProjectOperatingActivityProgressRevisionBoolExp>;
  _or?: Maybe<Array<ProjectOperatingActivityProgressRevisionBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  new_value?: Maybe<StringComparisonExp>;
  old_value?: Maybe<StringComparisonExp>;
  operating_activity_progress?: Maybe<ProjectOperatingActivityProgressBoolExp>;
  operating_activity_progress_id?: Maybe<UuidComparisonExp>;
  revision?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "project.operating_activity_progress_revision" */
export enum ProjectOperatingActivityProgressRevisionConstraint {
  /** unique or primary key constraint */
  OperatingActivityProgressRevisionPkey = 'operating_activity_progress_revision_pkey'
}

/** input type for incrementing numeric columns in table "project.operating_activity_progress_revision" */
export type ProjectOperatingActivityProgressRevisionIncInput = {
  /** Revision */
  revision?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "project.operating_activity_progress_revision" */
export type ProjectOperatingActivityProgressRevisionInsertInput = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ข้อมูลใหม่ */
  new_value?: Maybe<Scalars['String']>;
  /** ข้อมูลเก่า */
  old_value?: Maybe<Scalars['String']>;
  operating_activity_progress?: Maybe<ProjectOperatingActivityProgressObjRelInsertInput>;
  /** รายงานผลดำเนินงาน */
  operating_activity_progress_id?: Maybe<Scalars['uuid']>;
  /** Revision */
  revision?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type ProjectOperatingActivityProgressRevisionMaxFields = {
  __typename?: 'project_operating_activity_progress_revision_max_fields';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ข้อมูลใหม่ */
  new_value?: Maybe<Scalars['String']>;
  /** ข้อมูลเก่า */
  old_value?: Maybe<Scalars['String']>;
  /** รายงานผลดำเนินงาน */
  operating_activity_progress_id?: Maybe<Scalars['uuid']>;
  /** Revision */
  revision?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "project.operating_activity_progress_revision" */
export type ProjectOperatingActivityProgressRevisionMaxOrderBy = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ข้อมูลใหม่ */
  new_value?: Maybe<OrderBy>;
  /** ข้อมูลเก่า */
  old_value?: Maybe<OrderBy>;
  /** รายงานผลดำเนินงาน */
  operating_activity_progress_id?: Maybe<OrderBy>;
  /** Revision */
  revision?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectOperatingActivityProgressRevisionMinFields = {
  __typename?: 'project_operating_activity_progress_revision_min_fields';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ข้อมูลใหม่ */
  new_value?: Maybe<Scalars['String']>;
  /** ข้อมูลเก่า */
  old_value?: Maybe<Scalars['String']>;
  /** รายงานผลดำเนินงาน */
  operating_activity_progress_id?: Maybe<Scalars['uuid']>;
  /** Revision */
  revision?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "project.operating_activity_progress_revision" */
export type ProjectOperatingActivityProgressRevisionMinOrderBy = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ข้อมูลใหม่ */
  new_value?: Maybe<OrderBy>;
  /** ข้อมูลเก่า */
  old_value?: Maybe<OrderBy>;
  /** รายงานผลดำเนินงาน */
  operating_activity_progress_id?: Maybe<OrderBy>;
  /** Revision */
  revision?: Maybe<OrderBy>;
};

/** response of any mutation on the table "project.operating_activity_progress_revision" */
export type ProjectOperatingActivityProgressRevisionMutationResponse = {
  __typename?: 'project_operating_activity_progress_revision_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectOperatingActivityProgressRevision>;
};

/** on conflict condition type for table "project.operating_activity_progress_revision" */
export type ProjectOperatingActivityProgressRevisionOnConflict = {
  constraint: ProjectOperatingActivityProgressRevisionConstraint;
  update_columns?: Array<ProjectOperatingActivityProgressRevisionUpdateColumn>;
  where?: Maybe<ProjectOperatingActivityProgressRevisionBoolExp>;
};

/** Ordering options when selecting data from "project.operating_activity_progress_revision". */
export type ProjectOperatingActivityProgressRevisionOrderBy = {
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  new_value?: Maybe<OrderBy>;
  old_value?: Maybe<OrderBy>;
  operating_activity_progress?: Maybe<ProjectOperatingActivityProgressOrderBy>;
  operating_activity_progress_id?: Maybe<OrderBy>;
  revision?: Maybe<OrderBy>;
};

/** primary key columns input for table: project_operating_activity_progress_revision */
export type ProjectOperatingActivityProgressRevisionPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "project.operating_activity_progress_revision" */
export enum ProjectOperatingActivityProgressRevisionSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  NewValue = 'new_value',
  /** column name */
  OldValue = 'old_value',
  /** column name */
  OperatingActivityProgressId = 'operating_activity_progress_id',
  /** column name */
  Revision = 'revision'
}

/** input type for updating data in table "project.operating_activity_progress_revision" */
export type ProjectOperatingActivityProgressRevisionSetInput = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ข้อมูลใหม่ */
  new_value?: Maybe<Scalars['String']>;
  /** ข้อมูลเก่า */
  old_value?: Maybe<Scalars['String']>;
  /** รายงานผลดำเนินงาน */
  operating_activity_progress_id?: Maybe<Scalars['uuid']>;
  /** Revision */
  revision?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type ProjectOperatingActivityProgressRevisionStddevFields = {
  __typename?: 'project_operating_activity_progress_revision_stddev_fields';
  /** Revision */
  revision?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project.operating_activity_progress_revision" */
export type ProjectOperatingActivityProgressRevisionStddevOrderBy = {
  /** Revision */
  revision?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ProjectOperatingActivityProgressRevisionStddevPopFields = {
  __typename?: 'project_operating_activity_progress_revision_stddev_pop_fields';
  /** Revision */
  revision?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project.operating_activity_progress_revision" */
export type ProjectOperatingActivityProgressRevisionStddevPopOrderBy = {
  /** Revision */
  revision?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ProjectOperatingActivityProgressRevisionStddevSampFields = {
  __typename?: 'project_operating_activity_progress_revision_stddev_samp_fields';
  /** Revision */
  revision?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project.operating_activity_progress_revision" */
export type ProjectOperatingActivityProgressRevisionStddevSampOrderBy = {
  /** Revision */
  revision?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ProjectOperatingActivityProgressRevisionSumFields = {
  __typename?: 'project_operating_activity_progress_revision_sum_fields';
  /** Revision */
  revision?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "project.operating_activity_progress_revision" */
export type ProjectOperatingActivityProgressRevisionSumOrderBy = {
  /** Revision */
  revision?: Maybe<OrderBy>;
};

/** update columns of table "project.operating_activity_progress_revision" */
export enum ProjectOperatingActivityProgressRevisionUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  NewValue = 'new_value',
  /** column name */
  OldValue = 'old_value',
  /** column name */
  OperatingActivityProgressId = 'operating_activity_progress_id',
  /** column name */
  Revision = 'revision'
}

/** aggregate var_pop on columns */
export type ProjectOperatingActivityProgressRevisionVarPopFields = {
  __typename?: 'project_operating_activity_progress_revision_var_pop_fields';
  /** Revision */
  revision?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project.operating_activity_progress_revision" */
export type ProjectOperatingActivityProgressRevisionVarPopOrderBy = {
  /** Revision */
  revision?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ProjectOperatingActivityProgressRevisionVarSampFields = {
  __typename?: 'project_operating_activity_progress_revision_var_samp_fields';
  /** Revision */
  revision?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project.operating_activity_progress_revision" */
export type ProjectOperatingActivityProgressRevisionVarSampOrderBy = {
  /** Revision */
  revision?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProjectOperatingActivityProgressRevisionVarianceFields = {
  __typename?: 'project_operating_activity_progress_revision_variance_fields';
  /** Revision */
  revision?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project.operating_activity_progress_revision" */
export type ProjectOperatingActivityProgressRevisionVarianceOrderBy = {
  /** Revision */
  revision?: Maybe<OrderBy>;
};

/** select columns of table "project.operating_activity_progress" */
export enum ProjectOperatingActivityProgressSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Month = 'month',
  /** column name */
  ProjectOperatingActivityId = 'project_operating_activity_id',
  /** column name */
  Revision = 'revision',
  /** column name */
  Spend = 'spend',
  /** column name */
  SpendPlan = 'spend_plan',
  /** column name */
  Workload = 'workload',
  /** column name */
  WorkloadPlan = 'workload_plan'
}

/** input type for updating data in table "project.operating_activity_progress" */
export type ProjectOperatingActivityProgressSetInput = {
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** เดือน (1-12) */
  month?: Maybe<Scalars['Int']>;
  /** กิจกรรมดำเนินงานโครงการ */
  project_operating_activity_id?: Maybe<Scalars['uuid']>;
  /** Revision */
  revision?: Maybe<Scalars['numeric']>;
  /** งบประมาณ */
  spend?: Maybe<Scalars['numeric']>;
  spend_plan?: Maybe<Scalars['numeric']>;
  /** งาน */
  workload?: Maybe<Scalars['numeric']>;
  workload_plan?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type ProjectOperatingActivityProgressStddevFields = {
  __typename?: 'project_operating_activity_progress_stddev_fields';
  /** เดือน (1-12) */
  month?: Maybe<Scalars['Float']>;
  /** Revision */
  revision?: Maybe<Scalars['Float']>;
  /** งบประมาณ */
  spend?: Maybe<Scalars['Float']>;
  spend_plan?: Maybe<Scalars['Float']>;
  /** งาน */
  workload?: Maybe<Scalars['Float']>;
  workload_plan?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project.operating_activity_progress" */
export type ProjectOperatingActivityProgressStddevOrderBy = {
  /** เดือน (1-12) */
  month?: Maybe<OrderBy>;
  /** Revision */
  revision?: Maybe<OrderBy>;
  /** งบประมาณ */
  spend?: Maybe<OrderBy>;
  spend_plan?: Maybe<OrderBy>;
  /** งาน */
  workload?: Maybe<OrderBy>;
  workload_plan?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ProjectOperatingActivityProgressStddevPopFields = {
  __typename?: 'project_operating_activity_progress_stddev_pop_fields';
  /** เดือน (1-12) */
  month?: Maybe<Scalars['Float']>;
  /** Revision */
  revision?: Maybe<Scalars['Float']>;
  /** งบประมาณ */
  spend?: Maybe<Scalars['Float']>;
  spend_plan?: Maybe<Scalars['Float']>;
  /** งาน */
  workload?: Maybe<Scalars['Float']>;
  workload_plan?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project.operating_activity_progress" */
export type ProjectOperatingActivityProgressStddevPopOrderBy = {
  /** เดือน (1-12) */
  month?: Maybe<OrderBy>;
  /** Revision */
  revision?: Maybe<OrderBy>;
  /** งบประมาณ */
  spend?: Maybe<OrderBy>;
  spend_plan?: Maybe<OrderBy>;
  /** งาน */
  workload?: Maybe<OrderBy>;
  workload_plan?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ProjectOperatingActivityProgressStddevSampFields = {
  __typename?: 'project_operating_activity_progress_stddev_samp_fields';
  /** เดือน (1-12) */
  month?: Maybe<Scalars['Float']>;
  /** Revision */
  revision?: Maybe<Scalars['Float']>;
  /** งบประมาณ */
  spend?: Maybe<Scalars['Float']>;
  spend_plan?: Maybe<Scalars['Float']>;
  /** งาน */
  workload?: Maybe<Scalars['Float']>;
  workload_plan?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project.operating_activity_progress" */
export type ProjectOperatingActivityProgressStddevSampOrderBy = {
  /** เดือน (1-12) */
  month?: Maybe<OrderBy>;
  /** Revision */
  revision?: Maybe<OrderBy>;
  /** งบประมาณ */
  spend?: Maybe<OrderBy>;
  spend_plan?: Maybe<OrderBy>;
  /** งาน */
  workload?: Maybe<OrderBy>;
  workload_plan?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ProjectOperatingActivityProgressSumFields = {
  __typename?: 'project_operating_activity_progress_sum_fields';
  /** เดือน (1-12) */
  month?: Maybe<Scalars['Int']>;
  /** Revision */
  revision?: Maybe<Scalars['numeric']>;
  /** งบประมาณ */
  spend?: Maybe<Scalars['numeric']>;
  spend_plan?: Maybe<Scalars['numeric']>;
  /** งาน */
  workload?: Maybe<Scalars['numeric']>;
  workload_plan?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "project.operating_activity_progress" */
export type ProjectOperatingActivityProgressSumOrderBy = {
  /** เดือน (1-12) */
  month?: Maybe<OrderBy>;
  /** Revision */
  revision?: Maybe<OrderBy>;
  /** งบประมาณ */
  spend?: Maybe<OrderBy>;
  spend_plan?: Maybe<OrderBy>;
  /** งาน */
  workload?: Maybe<OrderBy>;
  workload_plan?: Maybe<OrderBy>;
};

/** update columns of table "project.operating_activity_progress" */
export enum ProjectOperatingActivityProgressUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Month = 'month',
  /** column name */
  ProjectOperatingActivityId = 'project_operating_activity_id',
  /** column name */
  Revision = 'revision',
  /** column name */
  Spend = 'spend',
  /** column name */
  SpendPlan = 'spend_plan',
  /** column name */
  Workload = 'workload',
  /** column name */
  WorkloadPlan = 'workload_plan'
}

/** aggregate var_pop on columns */
export type ProjectOperatingActivityProgressVarPopFields = {
  __typename?: 'project_operating_activity_progress_var_pop_fields';
  /** เดือน (1-12) */
  month?: Maybe<Scalars['Float']>;
  /** Revision */
  revision?: Maybe<Scalars['Float']>;
  /** งบประมาณ */
  spend?: Maybe<Scalars['Float']>;
  spend_plan?: Maybe<Scalars['Float']>;
  /** งาน */
  workload?: Maybe<Scalars['Float']>;
  workload_plan?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project.operating_activity_progress" */
export type ProjectOperatingActivityProgressVarPopOrderBy = {
  /** เดือน (1-12) */
  month?: Maybe<OrderBy>;
  /** Revision */
  revision?: Maybe<OrderBy>;
  /** งบประมาณ */
  spend?: Maybe<OrderBy>;
  spend_plan?: Maybe<OrderBy>;
  /** งาน */
  workload?: Maybe<OrderBy>;
  workload_plan?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ProjectOperatingActivityProgressVarSampFields = {
  __typename?: 'project_operating_activity_progress_var_samp_fields';
  /** เดือน (1-12) */
  month?: Maybe<Scalars['Float']>;
  /** Revision */
  revision?: Maybe<Scalars['Float']>;
  /** งบประมาณ */
  spend?: Maybe<Scalars['Float']>;
  spend_plan?: Maybe<Scalars['Float']>;
  /** งาน */
  workload?: Maybe<Scalars['Float']>;
  workload_plan?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project.operating_activity_progress" */
export type ProjectOperatingActivityProgressVarSampOrderBy = {
  /** เดือน (1-12) */
  month?: Maybe<OrderBy>;
  /** Revision */
  revision?: Maybe<OrderBy>;
  /** งบประมาณ */
  spend?: Maybe<OrderBy>;
  spend_plan?: Maybe<OrderBy>;
  /** งาน */
  workload?: Maybe<OrderBy>;
  workload_plan?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProjectOperatingActivityProgressVarianceFields = {
  __typename?: 'project_operating_activity_progress_variance_fields';
  /** เดือน (1-12) */
  month?: Maybe<Scalars['Float']>;
  /** Revision */
  revision?: Maybe<Scalars['Float']>;
  /** งบประมาณ */
  spend?: Maybe<Scalars['Float']>;
  spend_plan?: Maybe<Scalars['Float']>;
  /** งาน */
  workload?: Maybe<Scalars['Float']>;
  workload_plan?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project.operating_activity_progress" */
export type ProjectOperatingActivityProgressVarianceOrderBy = {
  /** เดือน (1-12) */
  month?: Maybe<OrderBy>;
  /** Revision */
  revision?: Maybe<OrderBy>;
  /** งบประมาณ */
  spend?: Maybe<OrderBy>;
  spend_plan?: Maybe<OrderBy>;
  /** งาน */
  workload?: Maybe<OrderBy>;
  workload_plan?: Maybe<OrderBy>;
};

/**
 * ประเภทกิจกรรมดำเนินงาน
 *
 *
 * columns and relationships of "project.operating_activity_type"
 */
export type ProjectOperatingActivityType = {
  __typename?: 'project_operating_activity_type';
  /** คำอธิบาย ประเภทกิจกรรมดำเนินงาน */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
  /** An array relationship */
  project_operating_activities: Array<ProjectProjectOperatingActivity>;
  /** An aggregate relationship */
  project_operating_activities_aggregate: ProjectProjectOperatingActivityAggregate;
};


/**
 * ประเภทกิจกรรมดำเนินงาน
 *
 *
 * columns and relationships of "project.operating_activity_type"
 */
export type ProjectOperatingActivityTypeProjectOperatingActivitiesArgs = {
  distinct_on?: Maybe<Array<ProjectProjectOperatingActivitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOperatingActivityOrderBy>>;
  where?: Maybe<ProjectProjectOperatingActivityBoolExp>;
};


/**
 * ประเภทกิจกรรมดำเนินงาน
 *
 *
 * columns and relationships of "project.operating_activity_type"
 */
export type ProjectOperatingActivityTypeProjectOperatingActivitiesAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectOperatingActivitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOperatingActivityOrderBy>>;
  where?: Maybe<ProjectProjectOperatingActivityBoolExp>;
};

/** aggregated selection of "project.operating_activity_type" */
export type ProjectOperatingActivityTypeAggregate = {
  __typename?: 'project_operating_activity_type_aggregate';
  aggregate?: Maybe<ProjectOperatingActivityTypeAggregateFields>;
  nodes: Array<ProjectOperatingActivityType>;
};

/** aggregate fields of "project.operating_activity_type" */
export type ProjectOperatingActivityTypeAggregateFields = {
  __typename?: 'project_operating_activity_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ProjectOperatingActivityTypeMaxFields>;
  min?: Maybe<ProjectOperatingActivityTypeMinFields>;
};


/** aggregate fields of "project.operating_activity_type" */
export type ProjectOperatingActivityTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectOperatingActivityTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "project.operating_activity_type". All fields are combined with a logical 'AND'. */
export type ProjectOperatingActivityTypeBoolExp = {
  _and?: Maybe<Array<ProjectOperatingActivityTypeBoolExp>>;
  _not?: Maybe<ProjectOperatingActivityTypeBoolExp>;
  _or?: Maybe<Array<ProjectOperatingActivityTypeBoolExp>>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  project_operating_activities?: Maybe<ProjectProjectOperatingActivityBoolExp>;
};

/** unique or primary key constraints on table "project.operating_activity_type" */
export enum ProjectOperatingActivityTypeConstraint {
  /** unique or primary key constraint */
  OperatingActivityTypePkey = 'operating_activity_type_pkey'
}

export enum ProjectOperatingActivityTypeEnum {
  /** หลัก */
  Primary = 'primary',
  /** รอง */
  Secondary = 'secondary'
}

/** Boolean expression to compare columns of type "project_operating_activity_type_enum". All fields are combined with logical 'AND'. */
export type ProjectOperatingActivityTypeEnumComparisonExp = {
  _eq?: Maybe<ProjectOperatingActivityTypeEnum>;
  _in?: Maybe<Array<ProjectOperatingActivityTypeEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ProjectOperatingActivityTypeEnum>;
  _nin?: Maybe<Array<ProjectOperatingActivityTypeEnum>>;
};

/** input type for inserting data into table "project.operating_activity_type" */
export type ProjectOperatingActivityTypeInsertInput = {
  /** คำอธิบาย ประเภทกิจกรรมดำเนินงาน */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  project_operating_activities?: Maybe<ProjectProjectOperatingActivityArrRelInsertInput>;
};

/** aggregate max on columns */
export type ProjectOperatingActivityTypeMaxFields = {
  __typename?: 'project_operating_activity_type_max_fields';
  /** คำอธิบาย ประเภทกิจกรรมดำเนินงาน */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ProjectOperatingActivityTypeMinFields = {
  __typename?: 'project_operating_activity_type_min_fields';
  /** คำอธิบาย ประเภทกิจกรรมดำเนินงาน */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "project.operating_activity_type" */
export type ProjectOperatingActivityTypeMutationResponse = {
  __typename?: 'project_operating_activity_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectOperatingActivityType>;
};

/** input type for inserting object relation for remote table "project.operating_activity_type" */
export type ProjectOperatingActivityTypeObjRelInsertInput = {
  data: ProjectOperatingActivityTypeInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectOperatingActivityTypeOnConflict>;
};

/** on conflict condition type for table "project.operating_activity_type" */
export type ProjectOperatingActivityTypeOnConflict = {
  constraint: ProjectOperatingActivityTypeConstraint;
  update_columns?: Array<ProjectOperatingActivityTypeUpdateColumn>;
  where?: Maybe<ProjectOperatingActivityTypeBoolExp>;
};

/** Ordering options when selecting data from "project.operating_activity_type". */
export type ProjectOperatingActivityTypeOrderBy = {
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  project_operating_activities_aggregate?: Maybe<ProjectProjectOperatingActivityAggregateOrderBy>;
};

/** primary key columns input for table: project_operating_activity_type */
export type ProjectOperatingActivityTypePkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "project.operating_activity_type" */
export enum ProjectOperatingActivityTypeSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "project.operating_activity_type" */
export type ProjectOperatingActivityTypeSetInput = {
  /** คำอธิบาย ประเภทกิจกรรมดำเนินงาน */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** update columns of table "project.operating_activity_type" */
export enum ProjectOperatingActivityTypeUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProject = {
  __typename?: 'project_project';
  /** An object relationship */
  approved?: Maybe<ProjectApproved>;
  /** ข้อความเพิ่มเติมหลังตรวจสอบโครงการ */
  approved_comment?: Maybe<Scalars['String']>;
  /** วันที่ตรวจสอบโครงการ */
  approved_date?: Maybe<Scalars['timestamptz']>;
  /** สถานะการตรวจสอบของโครงการ */
  approved_id?: Maybe<ProjectApprovedEnum>;
  /** An array relationship */
  budget_plans: Array<ProjectBudgetPlan>;
  /** An aggregate relationship */
  budget_plans_aggregate: ProjectBudgetPlanAggregate;
  /** An array relationship */
  budget_summary: Array<ProjectProjectBudgetSummary>;
  /** An aggregate relationship */
  budget_summary_aggregate: ProjectProjectBudgetSummaryAggregate;
  /** An array relationship */
  budget_templates: Array<ProjectProjectBudgetTemplate>;
  /** An aggregate relationship */
  budget_templates_aggregate: ProjectProjectBudgetTemplateAggregate;
  /** วันที่พิจารณา: ยกเลิกโครงการ */
  cancelled_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่พิจารณา: แก้ไขเอกสารเพิ่มเติม */
  change_request_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่เสร็จสิ้น */
  completed_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  created_by_user?: Maybe<Users>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ผลกระทบ */
  effect?: Maybe<Scalars['String']>;
  /** วันที่สิ้นสุด (ระยะเวลาดำเนินการ) */
  end_at?: Maybe<Scalars['date']>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** สาขาอุตสาหกรรม */
  industry?: Maybe<Scalars['String']>;
  /** หลักการและเหตุผลความจำเป็น */
  introduction?: Maybe<Scalars['String']>;
  /** ผลการดำเนินงานในปีที่ผ่านมา */
  last_overall_operation?: Maybe<Scalars['String']>;
  /** An object relationship */
  national_strategy_lvl1?: Maybe<ProjectNationalStrategy>;
  /** ด้านยุทธศาสตร์ */
  national_strategy_lvl1_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  national_strategy_lvl2?: Maybe<ProjectNationalStrategy>;
  /** ประเด็นแผนแม่บท (จ.1) */
  national_strategy_lvl2_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  national_strategy_lvl3?: Maybe<ProjectNationalStrategy>;
  /** เป้าหมายแผนแม่บท (จ.2) */
  national_strategy_lvl3_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  national_strategy_lvl4?: Maybe<ProjectNationalStrategy>;
  /** แผนแม่บทย่อย */
  national_strategy_lvl4_id?: Maybe<Scalars['String']>;
  national_strategy_lvl5_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  organization?: Maybe<Organization>;
  /** หน่วยงานรับผิดชอบ */
  organization_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  project_achievements: Array<ProjectProjectAchievement>;
  /** An aggregate relationship */
  project_achievements_aggregate: ProjectProjectAchievementAggregate;
  /** An array relationship */
  project_attachments: Array<ProjectProjectAttachment>;
  /** An aggregate relationship */
  project_attachments_aggregate: ProjectProjectAttachmentAggregate;
  /** An array relationship */
  project_event_logs: Array<ProjectProjectEventLog>;
  /** An aggregate relationship */
  project_event_logs_aggregate: ProjectProjectEventLogAggregate;
  /** An array relationship */
  project_indicators: Array<ProjectProjectIndicator>;
  /** An aggregate relationship */
  project_indicators_aggregate: ProjectProjectIndicatorAggregate;
  /** An array relationship */
  project_members: Array<ProjectProjectMember>;
  /** An aggregate relationship */
  project_members_aggregate: ProjectProjectMemberAggregate;
  /** An array relationship */
  project_objectives: Array<ProjectProjectObjective>;
  /** An aggregate relationship */
  project_objectives_aggregate: ProjectProjectObjectiveAggregate;
  /** An array relationship */
  project_operating_activities: Array<ProjectProjectOperatingActivity>;
  /** An aggregate relationship */
  project_operating_activities_aggregate: ProjectProjectOperatingActivityAggregate;
  /** An array relationship */
  project_operating_areas: Array<ProjectProjectOperatingArea>;
  /** An aggregate relationship */
  project_operating_areas_aggregate: ProjectProjectOperatingAreaAggregate;
  /** An object relationship */
  project_step: ProjectProjectStep;
  /** An array relationship */
  project_target_groups: Array<ProjectProjectTargetGroup>;
  /** An aggregate relationship */
  project_target_groups_aggregate: ProjectProjectTargetGroupAggregate;
  /** รหัสอ้างอิงโครงการ */
  reference?: Maybe<Scalars['String']>;
  /** วันที่พิจารณา: ไม่อนุมัติโครงการ */
  rejected_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่เริ่ม (ระยะเวลาดำเนินการ) */
  start_at?: Maybe<Scalars['date']>;
  /** สถานะโครงการ */
  step_id: Scalars['String'];
  /** วันที่ส่งโครงการ */
  submitted_date?: Maybe<Scalars['timestamptz']>;
  /** ชื่อโครงการ */
  title?: Maybe<Scalars['String']>;
  /** งบประมาณ */
  total_budget: Scalars['numeric'];
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  updated_by_user?: Maybe<Users>;
  /** An array relationship */
  workload_summary: Array<ProjectProjectWorkloadSummary>;
  /** An aggregate relationship */
  workload_summary_aggregate: ProjectProjectWorkloadSummaryAggregate;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectBudgetPlansArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetPlanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetPlanOrderBy>>;
  where?: Maybe<ProjectBudgetPlanBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectBudgetPlansAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetPlanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetPlanOrderBy>>;
  where?: Maybe<ProjectBudgetPlanBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectBudgetSummaryArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetSummarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetSummaryOrderBy>>;
  where?: Maybe<ProjectProjectBudgetSummaryBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectBudgetSummaryAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetSummarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetSummaryOrderBy>>;
  where?: Maybe<ProjectProjectBudgetSummaryBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectBudgetTemplatesArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetTemplateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetTemplateOrderBy>>;
  where?: Maybe<ProjectProjectBudgetTemplateBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectBudgetTemplatesAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetTemplateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetTemplateOrderBy>>;
  where?: Maybe<ProjectProjectBudgetTemplateBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectProjectAchievementsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectAchievementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectAchievementOrderBy>>;
  where?: Maybe<ProjectProjectAchievementBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectProjectAchievementsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectAchievementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectAchievementOrderBy>>;
  where?: Maybe<ProjectProjectAchievementBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectProjectAttachmentsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectAttachmentOrderBy>>;
  where?: Maybe<ProjectProjectAttachmentBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectProjectAttachmentsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectAttachmentOrderBy>>;
  where?: Maybe<ProjectProjectAttachmentBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectProjectEventLogsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectEventLogOrderBy>>;
  where?: Maybe<ProjectProjectEventLogBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectProjectEventLogsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectEventLogOrderBy>>;
  where?: Maybe<ProjectProjectEventLogBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectProjectIndicatorsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectIndicatorSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectIndicatorOrderBy>>;
  where?: Maybe<ProjectProjectIndicatorBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectProjectIndicatorsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectIndicatorSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectIndicatorOrderBy>>;
  where?: Maybe<ProjectProjectIndicatorBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectProjectMembersArgs = {
  distinct_on?: Maybe<Array<ProjectProjectMemberSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectMemberOrderBy>>;
  where?: Maybe<ProjectProjectMemberBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectProjectMembersAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectMemberSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectMemberOrderBy>>;
  where?: Maybe<ProjectProjectMemberBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectProjectObjectivesArgs = {
  distinct_on?: Maybe<Array<ProjectProjectObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectObjectiveOrderBy>>;
  where?: Maybe<ProjectProjectObjectiveBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectProjectObjectivesAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectObjectiveOrderBy>>;
  where?: Maybe<ProjectProjectObjectiveBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectProjectOperatingActivitiesArgs = {
  distinct_on?: Maybe<Array<ProjectProjectOperatingActivitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOperatingActivityOrderBy>>;
  where?: Maybe<ProjectProjectOperatingActivityBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectProjectOperatingActivitiesAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectOperatingActivitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOperatingActivityOrderBy>>;
  where?: Maybe<ProjectProjectOperatingActivityBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectProjectOperatingAreasArgs = {
  distinct_on?: Maybe<Array<ProjectProjectOperatingAreaSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOperatingAreaOrderBy>>;
  where?: Maybe<ProjectProjectOperatingAreaBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectProjectOperatingAreasAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectOperatingAreaSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOperatingAreaOrderBy>>;
  where?: Maybe<ProjectProjectOperatingAreaBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectProjectTargetGroupsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectTargetGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectTargetGroupOrderBy>>;
  where?: Maybe<ProjectProjectTargetGroupBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectProjectTargetGroupsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectTargetGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectTargetGroupOrderBy>>;
  where?: Maybe<ProjectProjectTargetGroupBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectWorkloadSummaryArgs = {
  distinct_on?: Maybe<Array<ProjectProjectWorkloadSummarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectWorkloadSummaryOrderBy>>;
  where?: Maybe<ProjectProjectWorkloadSummaryBoolExp>;
};


/**
 * ข้อมูลรายละเอียดโครงการ
 *
 *
 * columns and relationships of "project.project"
 */
export type ProjectProjectWorkloadSummaryAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectWorkloadSummarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectWorkloadSummaryOrderBy>>;
  where?: Maybe<ProjectProjectWorkloadSummaryBoolExp>;
};

/**
 * ผลสัมฤทธิ์โครงการ
 *
 *
 * columns and relationships of "project.project_achievement"
 */
export type ProjectProjectAchievement = {
  __typename?: 'project_project_achievement';
  /** Primary key */
  id: Scalars['uuid'];
  /** รายละเอียด ตัวชี้วัดผลสัมฤทธิ์โครงการ */
  kpi?: Maybe<Scalars['String']>;
  /** ชื่อรายการผลสัมฤทธิ์โครงการ */
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  project?: Maybe<ProjectProject>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  project_objective: ProjectProjectObjective;
  /** วัตถุประสงค์โครงการ */
  project_objective_id: Scalars['uuid'];
  /** ลำดับที่ */
  sequence: Scalars['numeric'];
};

/** aggregated selection of "project.project_achievement" */
export type ProjectProjectAchievementAggregate = {
  __typename?: 'project_project_achievement_aggregate';
  aggregate?: Maybe<ProjectProjectAchievementAggregateFields>;
  nodes: Array<ProjectProjectAchievement>;
};

/** aggregate fields of "project.project_achievement" */
export type ProjectProjectAchievementAggregateFields = {
  __typename?: 'project_project_achievement_aggregate_fields';
  avg?: Maybe<ProjectProjectAchievementAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectProjectAchievementMaxFields>;
  min?: Maybe<ProjectProjectAchievementMinFields>;
  stddev?: Maybe<ProjectProjectAchievementStddevFields>;
  stddev_pop?: Maybe<ProjectProjectAchievementStddevPopFields>;
  stddev_samp?: Maybe<ProjectProjectAchievementStddevSampFields>;
  sum?: Maybe<ProjectProjectAchievementSumFields>;
  var_pop?: Maybe<ProjectProjectAchievementVarPopFields>;
  var_samp?: Maybe<ProjectProjectAchievementVarSampFields>;
  variance?: Maybe<ProjectProjectAchievementVarianceFields>;
};


/** aggregate fields of "project.project_achievement" */
export type ProjectProjectAchievementAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectProjectAchievementSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project.project_achievement" */
export type ProjectProjectAchievementAggregateOrderBy = {
  avg?: Maybe<ProjectProjectAchievementAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProjectProjectAchievementMaxOrderBy>;
  min?: Maybe<ProjectProjectAchievementMinOrderBy>;
  stddev?: Maybe<ProjectProjectAchievementStddevOrderBy>;
  stddev_pop?: Maybe<ProjectProjectAchievementStddevPopOrderBy>;
  stddev_samp?: Maybe<ProjectProjectAchievementStddevSampOrderBy>;
  sum?: Maybe<ProjectProjectAchievementSumOrderBy>;
  var_pop?: Maybe<ProjectProjectAchievementVarPopOrderBy>;
  var_samp?: Maybe<ProjectProjectAchievementVarSampOrderBy>;
  variance?: Maybe<ProjectProjectAchievementVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "project.project_achievement" */
export type ProjectProjectAchievementArrRelInsertInput = {
  data: Array<ProjectProjectAchievementInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectProjectAchievementOnConflict>;
};

/** aggregate avg on columns */
export type ProjectProjectAchievementAvgFields = {
  __typename?: 'project_project_achievement_avg_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project.project_achievement" */
export type ProjectProjectAchievementAvgOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "project.project_achievement". All fields are combined with a logical 'AND'. */
export type ProjectProjectAchievementBoolExp = {
  _and?: Maybe<Array<ProjectProjectAchievementBoolExp>>;
  _not?: Maybe<ProjectProjectAchievementBoolExp>;
  _or?: Maybe<Array<ProjectProjectAchievementBoolExp>>;
  id?: Maybe<UuidComparisonExp>;
  kpi?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  project?: Maybe<ProjectProjectBoolExp>;
  project_id?: Maybe<UuidComparisonExp>;
  project_objective?: Maybe<ProjectProjectObjectiveBoolExp>;
  project_objective_id?: Maybe<UuidComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "project.project_achievement" */
export enum ProjectProjectAchievementConstraint {
  /** unique or primary key constraint */
  ProjectAchievementPkey = 'project_achievement_pkey'
}

/** input type for incrementing numeric columns in table "project.project_achievement" */
export type ProjectProjectAchievementIncInput = {
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "project.project_achievement" */
export type ProjectProjectAchievementInsertInput = {
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รายละเอียด ตัวชี้วัดผลสัมฤทธิ์โครงการ */
  kpi?: Maybe<Scalars['String']>;
  /** ชื่อรายการผลสัมฤทธิ์โครงการ */
  name?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectProjectObjRelInsertInput>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  project_objective?: Maybe<ProjectProjectObjectiveObjRelInsertInput>;
  /** วัตถุประสงค์โครงการ */
  project_objective_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type ProjectProjectAchievementMaxFields = {
  __typename?: 'project_project_achievement_max_fields';
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รายละเอียด ตัวชี้วัดผลสัมฤทธิ์โครงการ */
  kpi?: Maybe<Scalars['String']>;
  /** ชื่อรายการผลสัมฤทธิ์โครงการ */
  name?: Maybe<Scalars['String']>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** วัตถุประสงค์โครงการ */
  project_objective_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "project.project_achievement" */
export type ProjectProjectAchievementMaxOrderBy = {
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** รายละเอียด ตัวชี้วัดผลสัมฤทธิ์โครงการ */
  kpi?: Maybe<OrderBy>;
  /** ชื่อรายการผลสัมฤทธิ์โครงการ */
  name?: Maybe<OrderBy>;
  /** โครงการ */
  project_id?: Maybe<OrderBy>;
  /** วัตถุประสงค์โครงการ */
  project_objective_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectProjectAchievementMinFields = {
  __typename?: 'project_project_achievement_min_fields';
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รายละเอียด ตัวชี้วัดผลสัมฤทธิ์โครงการ */
  kpi?: Maybe<Scalars['String']>;
  /** ชื่อรายการผลสัมฤทธิ์โครงการ */
  name?: Maybe<Scalars['String']>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** วัตถุประสงค์โครงการ */
  project_objective_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "project.project_achievement" */
export type ProjectProjectAchievementMinOrderBy = {
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** รายละเอียด ตัวชี้วัดผลสัมฤทธิ์โครงการ */
  kpi?: Maybe<OrderBy>;
  /** ชื่อรายการผลสัมฤทธิ์โครงการ */
  name?: Maybe<OrderBy>;
  /** โครงการ */
  project_id?: Maybe<OrderBy>;
  /** วัตถุประสงค์โครงการ */
  project_objective_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** response of any mutation on the table "project.project_achievement" */
export type ProjectProjectAchievementMutationResponse = {
  __typename?: 'project_project_achievement_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectProjectAchievement>;
};

/** on conflict condition type for table "project.project_achievement" */
export type ProjectProjectAchievementOnConflict = {
  constraint: ProjectProjectAchievementConstraint;
  update_columns?: Array<ProjectProjectAchievementUpdateColumn>;
  where?: Maybe<ProjectProjectAchievementBoolExp>;
};

/** Ordering options when selecting data from "project.project_achievement". */
export type ProjectProjectAchievementOrderBy = {
  id?: Maybe<OrderBy>;
  kpi?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  project?: Maybe<ProjectProjectOrderBy>;
  project_id?: Maybe<OrderBy>;
  project_objective?: Maybe<ProjectProjectObjectiveOrderBy>;
  project_objective_id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** primary key columns input for table: project_project_achievement */
export type ProjectProjectAchievementPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "project.project_achievement" */
export enum ProjectProjectAchievementSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Kpi = 'kpi',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ProjectObjectiveId = 'project_objective_id',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "project.project_achievement" */
export type ProjectProjectAchievementSetInput = {
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รายละเอียด ตัวชี้วัดผลสัมฤทธิ์โครงการ */
  kpi?: Maybe<Scalars['String']>;
  /** ชื่อรายการผลสัมฤทธิ์โครงการ */
  name?: Maybe<Scalars['String']>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** วัตถุประสงค์โครงการ */
  project_objective_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type ProjectProjectAchievementStddevFields = {
  __typename?: 'project_project_achievement_stddev_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project.project_achievement" */
export type ProjectProjectAchievementStddevOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ProjectProjectAchievementStddevPopFields = {
  __typename?: 'project_project_achievement_stddev_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project.project_achievement" */
export type ProjectProjectAchievementStddevPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ProjectProjectAchievementStddevSampFields = {
  __typename?: 'project_project_achievement_stddev_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project.project_achievement" */
export type ProjectProjectAchievementStddevSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ProjectProjectAchievementSumFields = {
  __typename?: 'project_project_achievement_sum_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "project.project_achievement" */
export type ProjectProjectAchievementSumOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "project.project_achievement" */
export enum ProjectProjectAchievementUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Kpi = 'kpi',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ProjectObjectiveId = 'project_objective_id',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type ProjectProjectAchievementVarPopFields = {
  __typename?: 'project_project_achievement_var_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project.project_achievement" */
export type ProjectProjectAchievementVarPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ProjectProjectAchievementVarSampFields = {
  __typename?: 'project_project_achievement_var_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project.project_achievement" */
export type ProjectProjectAchievementVarSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProjectProjectAchievementVarianceFields = {
  __typename?: 'project_project_achievement_variance_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project.project_achievement" */
export type ProjectProjectAchievementVarianceOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregated selection of "project.project" */
export type ProjectProjectAggregate = {
  __typename?: 'project_project_aggregate';
  aggregate?: Maybe<ProjectProjectAggregateFields>;
  nodes: Array<ProjectProject>;
};

/** aggregate fields of "project.project" */
export type ProjectProjectAggregateFields = {
  __typename?: 'project_project_aggregate_fields';
  avg?: Maybe<ProjectProjectAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectProjectMaxFields>;
  min?: Maybe<ProjectProjectMinFields>;
  stddev?: Maybe<ProjectProjectStddevFields>;
  stddev_pop?: Maybe<ProjectProjectStddevPopFields>;
  stddev_samp?: Maybe<ProjectProjectStddevSampFields>;
  sum?: Maybe<ProjectProjectSumFields>;
  var_pop?: Maybe<ProjectProjectVarPopFields>;
  var_samp?: Maybe<ProjectProjectVarSampFields>;
  variance?: Maybe<ProjectProjectVarianceFields>;
};


/** aggregate fields of "project.project" */
export type ProjectProjectAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectProjectSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project.project" */
export type ProjectProjectAggregateOrderBy = {
  avg?: Maybe<ProjectProjectAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProjectProjectMaxOrderBy>;
  min?: Maybe<ProjectProjectMinOrderBy>;
  stddev?: Maybe<ProjectProjectStddevOrderBy>;
  stddev_pop?: Maybe<ProjectProjectStddevPopOrderBy>;
  stddev_samp?: Maybe<ProjectProjectStddevSampOrderBy>;
  sum?: Maybe<ProjectProjectSumOrderBy>;
  var_pop?: Maybe<ProjectProjectVarPopOrderBy>;
  var_samp?: Maybe<ProjectProjectVarSampOrderBy>;
  variance?: Maybe<ProjectProjectVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "project.project" */
export type ProjectProjectArrRelInsertInput = {
  data: Array<ProjectProjectInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectProjectOnConflict>;
};

/**
 * เอกสารแนบของโครงการ
 *
 *
 * columns and relationships of "project.project_attachment"
 */
export type ProjectProjectAttachment = {
  __typename?: 'project_project_attachment';
  /** An object relationship */
  attachment_type: ProjectAttachmentType;
  /** ประเภทเอกสารแนบ */
  attachment_type_id: Scalars['String'];
  /** วันที่บันทึกข้อมูล */
  created_at: Scalars['timestamptz'];
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  created_by_user?: Maybe<Users>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ที่อยู่ไฟล์ */
  file_path?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  filename?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** ประเภทไฟล์ */
  mimetype?: Maybe<Scalars['String']>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  original_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  project: ProjectProject;
  /** โครงการ */
  project_id: Scalars['uuid'];
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at: Scalars['timestamptz'];
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  updated_by_user?: Maybe<Users>;
};

/** aggregated selection of "project.project_attachment" */
export type ProjectProjectAttachmentAggregate = {
  __typename?: 'project_project_attachment_aggregate';
  aggregate?: Maybe<ProjectProjectAttachmentAggregateFields>;
  nodes: Array<ProjectProjectAttachment>;
};

/** aggregate fields of "project.project_attachment" */
export type ProjectProjectAttachmentAggregateFields = {
  __typename?: 'project_project_attachment_aggregate_fields';
  avg?: Maybe<ProjectProjectAttachmentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectProjectAttachmentMaxFields>;
  min?: Maybe<ProjectProjectAttachmentMinFields>;
  stddev?: Maybe<ProjectProjectAttachmentStddevFields>;
  stddev_pop?: Maybe<ProjectProjectAttachmentStddevPopFields>;
  stddev_samp?: Maybe<ProjectProjectAttachmentStddevSampFields>;
  sum?: Maybe<ProjectProjectAttachmentSumFields>;
  var_pop?: Maybe<ProjectProjectAttachmentVarPopFields>;
  var_samp?: Maybe<ProjectProjectAttachmentVarSampFields>;
  variance?: Maybe<ProjectProjectAttachmentVarianceFields>;
};


/** aggregate fields of "project.project_attachment" */
export type ProjectProjectAttachmentAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectProjectAttachmentSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project.project_attachment" */
export type ProjectProjectAttachmentAggregateOrderBy = {
  avg?: Maybe<ProjectProjectAttachmentAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProjectProjectAttachmentMaxOrderBy>;
  min?: Maybe<ProjectProjectAttachmentMinOrderBy>;
  stddev?: Maybe<ProjectProjectAttachmentStddevOrderBy>;
  stddev_pop?: Maybe<ProjectProjectAttachmentStddevPopOrderBy>;
  stddev_samp?: Maybe<ProjectProjectAttachmentStddevSampOrderBy>;
  sum?: Maybe<ProjectProjectAttachmentSumOrderBy>;
  var_pop?: Maybe<ProjectProjectAttachmentVarPopOrderBy>;
  var_samp?: Maybe<ProjectProjectAttachmentVarSampOrderBy>;
  variance?: Maybe<ProjectProjectAttachmentVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "project.project_attachment" */
export type ProjectProjectAttachmentArrRelInsertInput = {
  data: Array<ProjectProjectAttachmentInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectProjectAttachmentOnConflict>;
};

/** aggregate avg on columns */
export type ProjectProjectAttachmentAvgFields = {
  __typename?: 'project_project_attachment_avg_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project.project_attachment" */
export type ProjectProjectAttachmentAvgOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "project.project_attachment". All fields are combined with a logical 'AND'. */
export type ProjectProjectAttachmentBoolExp = {
  _and?: Maybe<Array<ProjectProjectAttachmentBoolExp>>;
  _not?: Maybe<ProjectProjectAttachmentBoolExp>;
  _or?: Maybe<Array<ProjectProjectAttachmentBoolExp>>;
  attachment_type?: Maybe<ProjectAttachmentTypeBoolExp>;
  attachment_type_id?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  created_by_user?: Maybe<UsersBoolExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  file_path?: Maybe<StringComparisonExp>;
  filename?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  mimetype?: Maybe<StringComparisonExp>;
  note?: Maybe<StringComparisonExp>;
  original_name?: Maybe<StringComparisonExp>;
  project?: Maybe<ProjectProjectBoolExp>;
  project_id?: Maybe<UuidComparisonExp>;
  size?: Maybe<NumericComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<UuidComparisonExp>;
  updated_by_user?: Maybe<UsersBoolExp>;
};

/** unique or primary key constraints on table "project.project_attachment" */
export enum ProjectProjectAttachmentConstraint {
  /** unique or primary key constraint */
  ProjectAttachmentPkey = 'project_attachment_pkey'
}

/** input type for incrementing numeric columns in table "project.project_attachment" */
export type ProjectProjectAttachmentIncInput = {
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "project.project_attachment" */
export type ProjectProjectAttachmentInsertInput = {
  attachment_type?: Maybe<ProjectAttachmentTypeObjRelInsertInput>;
  /** ประเภทเอกสารแนบ */
  attachment_type_id?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  created_by_user?: Maybe<UsersObjRelInsertInput>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ที่อยู่ไฟล์ */
  file_path?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  filename?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<Scalars['String']>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  original_name?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectProjectObjRelInsertInput>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
  updated_by_user?: Maybe<UsersObjRelInsertInput>;
};

/** aggregate max on columns */
export type ProjectProjectAttachmentMaxFields = {
  __typename?: 'project_project_attachment_max_fields';
  /** ประเภทเอกสารแนบ */
  attachment_type_id?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ที่อยู่ไฟล์ */
  file_path?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  filename?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<Scalars['String']>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  original_name?: Maybe<Scalars['String']>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "project.project_attachment" */
export type ProjectProjectAttachmentMaxOrderBy = {
  /** ประเภทเอกสารแนบ */
  attachment_type_id?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** ที่อยู่ไฟล์ */
  file_path?: Maybe<OrderBy>;
  /** ชื่อไฟล์ */
  filename?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<OrderBy>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<OrderBy>;
  /** ชื่อไฟล์ */
  original_name?: Maybe<OrderBy>;
  /** โครงการ */
  project_id?: Maybe<OrderBy>;
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectProjectAttachmentMinFields = {
  __typename?: 'project_project_attachment_min_fields';
  /** ประเภทเอกสารแนบ */
  attachment_type_id?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ที่อยู่ไฟล์ */
  file_path?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  filename?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<Scalars['String']>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  original_name?: Maybe<Scalars['String']>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "project.project_attachment" */
export type ProjectProjectAttachmentMinOrderBy = {
  /** ประเภทเอกสารแนบ */
  attachment_type_id?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** ที่อยู่ไฟล์ */
  file_path?: Maybe<OrderBy>;
  /** ชื่อไฟล์ */
  filename?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<OrderBy>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<OrderBy>;
  /** ชื่อไฟล์ */
  original_name?: Maybe<OrderBy>;
  /** โครงการ */
  project_id?: Maybe<OrderBy>;
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "project.project_attachment" */
export type ProjectProjectAttachmentMutationResponse = {
  __typename?: 'project_project_attachment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectProjectAttachment>;
};

/** on conflict condition type for table "project.project_attachment" */
export type ProjectProjectAttachmentOnConflict = {
  constraint: ProjectProjectAttachmentConstraint;
  update_columns?: Array<ProjectProjectAttachmentUpdateColumn>;
  where?: Maybe<ProjectProjectAttachmentBoolExp>;
};

/** Ordering options when selecting data from "project.project_attachment". */
export type ProjectProjectAttachmentOrderBy = {
  attachment_type?: Maybe<ProjectAttachmentTypeOrderBy>;
  attachment_type_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  created_by_user?: Maybe<UsersOrderBy>;
  deleted_at?: Maybe<OrderBy>;
  file_path?: Maybe<OrderBy>;
  filename?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mimetype?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  original_name?: Maybe<OrderBy>;
  project?: Maybe<ProjectProjectOrderBy>;
  project_id?: Maybe<OrderBy>;
  size?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
  updated_by_user?: Maybe<UsersOrderBy>;
};

/** primary key columns input for table: project_project_attachment */
export type ProjectProjectAttachmentPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "project.project_attachment" */
export enum ProjectProjectAttachmentSelectColumn {
  /** column name */
  AttachmentTypeId = 'attachment_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Mimetype = 'mimetype',
  /** column name */
  Note = 'note',
  /** column name */
  OriginalName = 'original_name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "project.project_attachment" */
export type ProjectProjectAttachmentSetInput = {
  /** ประเภทเอกสารแนบ */
  attachment_type_id?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ที่อยู่ไฟล์ */
  file_path?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  filename?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ประเภทไฟล์ */
  mimetype?: Maybe<Scalars['String']>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** ชื่อไฟล์ */
  original_name?: Maybe<Scalars['String']>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ProjectProjectAttachmentStddevFields = {
  __typename?: 'project_project_attachment_stddev_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project.project_attachment" */
export type ProjectProjectAttachmentStddevOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ProjectProjectAttachmentStddevPopFields = {
  __typename?: 'project_project_attachment_stddev_pop_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project.project_attachment" */
export type ProjectProjectAttachmentStddevPopOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ProjectProjectAttachmentStddevSampFields = {
  __typename?: 'project_project_attachment_stddev_samp_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project.project_attachment" */
export type ProjectProjectAttachmentStddevSampOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ProjectProjectAttachmentSumFields = {
  __typename?: 'project_project_attachment_sum_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "project.project_attachment" */
export type ProjectProjectAttachmentSumOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** update columns of table "project.project_attachment" */
export enum ProjectProjectAttachmentUpdateColumn {
  /** column name */
  AttachmentTypeId = 'attachment_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Mimetype = 'mimetype',
  /** column name */
  Note = 'note',
  /** column name */
  OriginalName = 'original_name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type ProjectProjectAttachmentVarPopFields = {
  __typename?: 'project_project_attachment_var_pop_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project.project_attachment" */
export type ProjectProjectAttachmentVarPopOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ProjectProjectAttachmentVarSampFields = {
  __typename?: 'project_project_attachment_var_samp_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project.project_attachment" */
export type ProjectProjectAttachmentVarSampOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProjectProjectAttachmentVarianceFields = {
  __typename?: 'project_project_attachment_variance_fields';
  /** ขนาดไฟล์ */
  size?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project.project_attachment" */
export type ProjectProjectAttachmentVarianceOrderBy = {
  /** ขนาดไฟล์ */
  size?: Maybe<OrderBy>;
};

/** aggregate avg on columns */
export type ProjectProjectAvgFields = {
  __typename?: 'project_project_avg_fields';
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Float']>;
  /** งบประมาณ */
  total_budget?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project.project" */
export type ProjectProjectAvgOrderBy = {
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<OrderBy>;
  /** งบประมาณ */
  total_budget?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "project.project". All fields are combined with a logical 'AND'. */
export type ProjectProjectBoolExp = {
  _and?: Maybe<Array<ProjectProjectBoolExp>>;
  _not?: Maybe<ProjectProjectBoolExp>;
  _or?: Maybe<Array<ProjectProjectBoolExp>>;
  approved?: Maybe<ProjectApprovedBoolExp>;
  approved_comment?: Maybe<StringComparisonExp>;
  approved_date?: Maybe<TimestamptzComparisonExp>;
  approved_id?: Maybe<ProjectApprovedEnumComparisonExp>;
  budget_plans?: Maybe<ProjectBudgetPlanBoolExp>;
  budget_summary?: Maybe<ProjectProjectBudgetSummaryBoolExp>;
  budget_templates?: Maybe<ProjectProjectBudgetTemplateBoolExp>;
  cancelled_date?: Maybe<TimestamptzComparisonExp>;
  change_request_date?: Maybe<TimestamptzComparisonExp>;
  completed_date?: Maybe<TimestamptzComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  created_by_user?: Maybe<UsersBoolExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  effect?: Maybe<StringComparisonExp>;
  end_at?: Maybe<DateComparisonExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  industry?: Maybe<StringComparisonExp>;
  introduction?: Maybe<StringComparisonExp>;
  last_overall_operation?: Maybe<StringComparisonExp>;
  national_strategy_lvl1?: Maybe<ProjectNationalStrategyBoolExp>;
  national_strategy_lvl1_id?: Maybe<StringComparisonExp>;
  national_strategy_lvl2?: Maybe<ProjectNationalStrategyBoolExp>;
  national_strategy_lvl2_id?: Maybe<StringComparisonExp>;
  national_strategy_lvl3?: Maybe<ProjectNationalStrategyBoolExp>;
  national_strategy_lvl3_id?: Maybe<StringComparisonExp>;
  national_strategy_lvl4?: Maybe<ProjectNationalStrategyBoolExp>;
  national_strategy_lvl4_id?: Maybe<StringComparisonExp>;
  national_strategy_lvl5_id?: Maybe<StringComparisonExp>;
  organization?: Maybe<OrganizationBoolExp>;
  organization_id?: Maybe<UuidComparisonExp>;
  project_achievements?: Maybe<ProjectProjectAchievementBoolExp>;
  project_attachments?: Maybe<ProjectProjectAttachmentBoolExp>;
  project_event_logs?: Maybe<ProjectProjectEventLogBoolExp>;
  project_indicators?: Maybe<ProjectProjectIndicatorBoolExp>;
  project_members?: Maybe<ProjectProjectMemberBoolExp>;
  project_objectives?: Maybe<ProjectProjectObjectiveBoolExp>;
  project_operating_activities?: Maybe<ProjectProjectOperatingActivityBoolExp>;
  project_operating_areas?: Maybe<ProjectProjectOperatingAreaBoolExp>;
  project_step?: Maybe<ProjectProjectStepBoolExp>;
  project_target_groups?: Maybe<ProjectProjectTargetGroupBoolExp>;
  reference?: Maybe<StringComparisonExp>;
  rejected_date?: Maybe<TimestamptzComparisonExp>;
  start_at?: Maybe<DateComparisonExp>;
  step_id?: Maybe<StringComparisonExp>;
  submitted_date?: Maybe<TimestamptzComparisonExp>;
  title?: Maybe<StringComparisonExp>;
  total_budget?: Maybe<NumericComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<UuidComparisonExp>;
  updated_by_user?: Maybe<UsersBoolExp>;
  workload_summary?: Maybe<ProjectProjectWorkloadSummaryBoolExp>;
};

/**
 * รายละเอียดงบประมาณ (แตกตัวคูณ)
 *
 *
 * columns and relationships of "project.project_budget_item"
 */
export type ProjectProjectBudgetItem = {
  __typename?: 'project_project_budget_item';
  /** An object relationship */
  budget: ProjectBudget;
  /** An object relationship */
  budget_group: ProjectBudgetGroup;
  /** ประเภทรายละเอียดค่าใช้จ่าย กิจกรรมโครงการ */
  budget_group_id: ProjectBudgetGroupEnum;
  /** รายละเอียดงบประมาณ */
  budget_id: Scalars['uuid'];
  /** Primary key */
  id: Scalars['uuid'];
  /** ตัวคูณ */
  multiplier: Scalars['numeric'];
  /** ข้อความเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** ราคาต่อหน่วย */
  price_per_unit: Scalars['numeric'];
  /** An object relationship */
  project_operating_activity: ProjectProjectOperatingActivity;
  /** กิจกรรมดำเนินงานโครงการ */
  project_operating_activity_id: Scalars['uuid'];
  /** ลำดับที่ */
  sequence: Scalars['numeric'];
  /** จำนวนเงินทั้งหมด */
  total: Scalars['numeric'];
  /** An object relationship */
  unit?: Maybe<ProjectUnit>;
  /** จำนวน */
  unit_amount: Scalars['numeric'];
  /** An object relationship */
  unit_by_unit_of_multiplier?: Maybe<ProjectUnit>;
  /** หน่วยนับ */
  unit_id?: Maybe<ProjectUnitEnum>;
  /** หน่วยนับตัวคูณ */
  unit_of_multiplier_id?: Maybe<ProjectUnitEnum>;
};

/** aggregated selection of "project.project_budget_item" */
export type ProjectProjectBudgetItemAggregate = {
  __typename?: 'project_project_budget_item_aggregate';
  aggregate?: Maybe<ProjectProjectBudgetItemAggregateFields>;
  nodes: Array<ProjectProjectBudgetItem>;
};

/** aggregate fields of "project.project_budget_item" */
export type ProjectProjectBudgetItemAggregateFields = {
  __typename?: 'project_project_budget_item_aggregate_fields';
  avg?: Maybe<ProjectProjectBudgetItemAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectProjectBudgetItemMaxFields>;
  min?: Maybe<ProjectProjectBudgetItemMinFields>;
  stddev?: Maybe<ProjectProjectBudgetItemStddevFields>;
  stddev_pop?: Maybe<ProjectProjectBudgetItemStddevPopFields>;
  stddev_samp?: Maybe<ProjectProjectBudgetItemStddevSampFields>;
  sum?: Maybe<ProjectProjectBudgetItemSumFields>;
  var_pop?: Maybe<ProjectProjectBudgetItemVarPopFields>;
  var_samp?: Maybe<ProjectProjectBudgetItemVarSampFields>;
  variance?: Maybe<ProjectProjectBudgetItemVarianceFields>;
};


/** aggregate fields of "project.project_budget_item" */
export type ProjectProjectBudgetItemAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectProjectBudgetItemSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project.project_budget_item" */
export type ProjectProjectBudgetItemAggregateOrderBy = {
  avg?: Maybe<ProjectProjectBudgetItemAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProjectProjectBudgetItemMaxOrderBy>;
  min?: Maybe<ProjectProjectBudgetItemMinOrderBy>;
  stddev?: Maybe<ProjectProjectBudgetItemStddevOrderBy>;
  stddev_pop?: Maybe<ProjectProjectBudgetItemStddevPopOrderBy>;
  stddev_samp?: Maybe<ProjectProjectBudgetItemStddevSampOrderBy>;
  sum?: Maybe<ProjectProjectBudgetItemSumOrderBy>;
  var_pop?: Maybe<ProjectProjectBudgetItemVarPopOrderBy>;
  var_samp?: Maybe<ProjectProjectBudgetItemVarSampOrderBy>;
  variance?: Maybe<ProjectProjectBudgetItemVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "project.project_budget_item" */
export type ProjectProjectBudgetItemArrRelInsertInput = {
  data: Array<ProjectProjectBudgetItemInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectProjectBudgetItemOnConflict>;
};

/** aggregate avg on columns */
export type ProjectProjectBudgetItemAvgFields = {
  __typename?: 'project_project_budget_item_avg_fields';
  /** ตัวคูณ */
  multiplier?: Maybe<Scalars['Float']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
  /** จำนวนเงินทั้งหมด */
  total?: Maybe<Scalars['Float']>;
  /** จำนวน */
  unit_amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project.project_budget_item" */
export type ProjectProjectBudgetItemAvgOrderBy = {
  /** ตัวคูณ */
  multiplier?: Maybe<OrderBy>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** จำนวนเงินทั้งหมด */
  total?: Maybe<OrderBy>;
  /** จำนวน */
  unit_amount?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "project.project_budget_item". All fields are combined with a logical 'AND'. */
export type ProjectProjectBudgetItemBoolExp = {
  _and?: Maybe<Array<ProjectProjectBudgetItemBoolExp>>;
  _not?: Maybe<ProjectProjectBudgetItemBoolExp>;
  _or?: Maybe<Array<ProjectProjectBudgetItemBoolExp>>;
  budget?: Maybe<ProjectBudgetBoolExp>;
  budget_group?: Maybe<ProjectBudgetGroupBoolExp>;
  budget_group_id?: Maybe<ProjectBudgetGroupEnumComparisonExp>;
  budget_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  multiplier?: Maybe<NumericComparisonExp>;
  note?: Maybe<StringComparisonExp>;
  price_per_unit?: Maybe<NumericComparisonExp>;
  project_operating_activity?: Maybe<ProjectProjectOperatingActivityBoolExp>;
  project_operating_activity_id?: Maybe<UuidComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
  total?: Maybe<NumericComparisonExp>;
  unit?: Maybe<ProjectUnitBoolExp>;
  unit_amount?: Maybe<NumericComparisonExp>;
  unit_by_unit_of_multiplier?: Maybe<ProjectUnitBoolExp>;
  unit_id?: Maybe<ProjectUnitEnumComparisonExp>;
  unit_of_multiplier_id?: Maybe<ProjectUnitEnumComparisonExp>;
};

/** unique or primary key constraints on table "project.project_budget_item" */
export enum ProjectProjectBudgetItemConstraint {
  /** unique or primary key constraint */
  ProjectBudgetItemPkey = 'project_budget_item_pkey'
}

/** input type for incrementing numeric columns in table "project.project_budget_item" */
export type ProjectProjectBudgetItemIncInput = {
  /** ตัวคูณ */
  multiplier?: Maybe<Scalars['numeric']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['numeric']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** จำนวนเงินทั้งหมด */
  total?: Maybe<Scalars['numeric']>;
  /** จำนวน */
  unit_amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "project.project_budget_item" */
export type ProjectProjectBudgetItemInsertInput = {
  budget?: Maybe<ProjectBudgetObjRelInsertInput>;
  budget_group?: Maybe<ProjectBudgetGroupObjRelInsertInput>;
  /** ประเภทรายละเอียดค่าใช้จ่าย กิจกรรมโครงการ */
  budget_group_id?: Maybe<ProjectBudgetGroupEnum>;
  /** รายละเอียดงบประมาณ */
  budget_id?: Maybe<Scalars['uuid']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ตัวคูณ */
  multiplier?: Maybe<Scalars['numeric']>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['numeric']>;
  project_operating_activity?: Maybe<ProjectProjectOperatingActivityObjRelInsertInput>;
  /** กิจกรรมดำเนินงานโครงการ */
  project_operating_activity_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** จำนวนเงินทั้งหมด */
  total?: Maybe<Scalars['numeric']>;
  unit?: Maybe<ProjectUnitObjRelInsertInput>;
  /** จำนวน */
  unit_amount?: Maybe<Scalars['numeric']>;
  unit_by_unit_of_multiplier?: Maybe<ProjectUnitObjRelInsertInput>;
  /** หน่วยนับ */
  unit_id?: Maybe<ProjectUnitEnum>;
  /** หน่วยนับตัวคูณ */
  unit_of_multiplier_id?: Maybe<ProjectUnitEnum>;
};

/** aggregate max on columns */
export type ProjectProjectBudgetItemMaxFields = {
  __typename?: 'project_project_budget_item_max_fields';
  /** รายละเอียดงบประมาณ */
  budget_id?: Maybe<Scalars['uuid']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ตัวคูณ */
  multiplier?: Maybe<Scalars['numeric']>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['numeric']>;
  /** กิจกรรมดำเนินงานโครงการ */
  project_operating_activity_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** จำนวนเงินทั้งหมด */
  total?: Maybe<Scalars['numeric']>;
  /** จำนวน */
  unit_amount?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "project.project_budget_item" */
export type ProjectProjectBudgetItemMaxOrderBy = {
  /** รายละเอียดงบประมาณ */
  budget_id?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ตัวคูณ */
  multiplier?: Maybe<OrderBy>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<OrderBy>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<OrderBy>;
  /** กิจกรรมดำเนินงานโครงการ */
  project_operating_activity_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** จำนวนเงินทั้งหมด */
  total?: Maybe<OrderBy>;
  /** จำนวน */
  unit_amount?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectProjectBudgetItemMinFields = {
  __typename?: 'project_project_budget_item_min_fields';
  /** รายละเอียดงบประมาณ */
  budget_id?: Maybe<Scalars['uuid']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ตัวคูณ */
  multiplier?: Maybe<Scalars['numeric']>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['numeric']>;
  /** กิจกรรมดำเนินงานโครงการ */
  project_operating_activity_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** จำนวนเงินทั้งหมด */
  total?: Maybe<Scalars['numeric']>;
  /** จำนวน */
  unit_amount?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "project.project_budget_item" */
export type ProjectProjectBudgetItemMinOrderBy = {
  /** รายละเอียดงบประมาณ */
  budget_id?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ตัวคูณ */
  multiplier?: Maybe<OrderBy>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<OrderBy>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<OrderBy>;
  /** กิจกรรมดำเนินงานโครงการ */
  project_operating_activity_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** จำนวนเงินทั้งหมด */
  total?: Maybe<OrderBy>;
  /** จำนวน */
  unit_amount?: Maybe<OrderBy>;
};

/** response of any mutation on the table "project.project_budget_item" */
export type ProjectProjectBudgetItemMutationResponse = {
  __typename?: 'project_project_budget_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectProjectBudgetItem>;
};

/** on conflict condition type for table "project.project_budget_item" */
export type ProjectProjectBudgetItemOnConflict = {
  constraint: ProjectProjectBudgetItemConstraint;
  update_columns?: Array<ProjectProjectBudgetItemUpdateColumn>;
  where?: Maybe<ProjectProjectBudgetItemBoolExp>;
};

/** Ordering options when selecting data from "project.project_budget_item". */
export type ProjectProjectBudgetItemOrderBy = {
  budget?: Maybe<ProjectBudgetOrderBy>;
  budget_group?: Maybe<ProjectBudgetGroupOrderBy>;
  budget_group_id?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  multiplier?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  price_per_unit?: Maybe<OrderBy>;
  project_operating_activity?: Maybe<ProjectProjectOperatingActivityOrderBy>;
  project_operating_activity_id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
  unit?: Maybe<ProjectUnitOrderBy>;
  unit_amount?: Maybe<OrderBy>;
  unit_by_unit_of_multiplier?: Maybe<ProjectUnitOrderBy>;
  unit_id?: Maybe<OrderBy>;
  unit_of_multiplier_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: project_project_budget_item */
export type ProjectProjectBudgetItemPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "project.project_budget_item" */
export enum ProjectProjectBudgetItemSelectColumn {
  /** column name */
  BudgetGroupId = 'budget_group_id',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  Id = 'id',
  /** column name */
  Multiplier = 'multiplier',
  /** column name */
  Note = 'note',
  /** column name */
  PricePerUnit = 'price_per_unit',
  /** column name */
  ProjectOperatingActivityId = 'project_operating_activity_id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  Total = 'total',
  /** column name */
  UnitAmount = 'unit_amount',
  /** column name */
  UnitId = 'unit_id',
  /** column name */
  UnitOfMultiplierId = 'unit_of_multiplier_id'
}

/** input type for updating data in table "project.project_budget_item" */
export type ProjectProjectBudgetItemSetInput = {
  /** ประเภทรายละเอียดค่าใช้จ่าย กิจกรรมโครงการ */
  budget_group_id?: Maybe<ProjectBudgetGroupEnum>;
  /** รายละเอียดงบประมาณ */
  budget_id?: Maybe<Scalars['uuid']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ตัวคูณ */
  multiplier?: Maybe<Scalars['numeric']>;
  /** ข้อความเพิ่มเติม */
  note?: Maybe<Scalars['String']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['numeric']>;
  /** กิจกรรมดำเนินงานโครงการ */
  project_operating_activity_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** จำนวนเงินทั้งหมด */
  total?: Maybe<Scalars['numeric']>;
  /** จำนวน */
  unit_amount?: Maybe<Scalars['numeric']>;
  /** หน่วยนับ */
  unit_id?: Maybe<ProjectUnitEnum>;
  /** หน่วยนับตัวคูณ */
  unit_of_multiplier_id?: Maybe<ProjectUnitEnum>;
};

/** aggregate stddev on columns */
export type ProjectProjectBudgetItemStddevFields = {
  __typename?: 'project_project_budget_item_stddev_fields';
  /** ตัวคูณ */
  multiplier?: Maybe<Scalars['Float']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
  /** จำนวนเงินทั้งหมด */
  total?: Maybe<Scalars['Float']>;
  /** จำนวน */
  unit_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project.project_budget_item" */
export type ProjectProjectBudgetItemStddevOrderBy = {
  /** ตัวคูณ */
  multiplier?: Maybe<OrderBy>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** จำนวนเงินทั้งหมด */
  total?: Maybe<OrderBy>;
  /** จำนวน */
  unit_amount?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ProjectProjectBudgetItemStddevPopFields = {
  __typename?: 'project_project_budget_item_stddev_pop_fields';
  /** ตัวคูณ */
  multiplier?: Maybe<Scalars['Float']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
  /** จำนวนเงินทั้งหมด */
  total?: Maybe<Scalars['Float']>;
  /** จำนวน */
  unit_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project.project_budget_item" */
export type ProjectProjectBudgetItemStddevPopOrderBy = {
  /** ตัวคูณ */
  multiplier?: Maybe<OrderBy>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** จำนวนเงินทั้งหมด */
  total?: Maybe<OrderBy>;
  /** จำนวน */
  unit_amount?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ProjectProjectBudgetItemStddevSampFields = {
  __typename?: 'project_project_budget_item_stddev_samp_fields';
  /** ตัวคูณ */
  multiplier?: Maybe<Scalars['Float']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
  /** จำนวนเงินทั้งหมด */
  total?: Maybe<Scalars['Float']>;
  /** จำนวน */
  unit_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project.project_budget_item" */
export type ProjectProjectBudgetItemStddevSampOrderBy = {
  /** ตัวคูณ */
  multiplier?: Maybe<OrderBy>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** จำนวนเงินทั้งหมด */
  total?: Maybe<OrderBy>;
  /** จำนวน */
  unit_amount?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ProjectProjectBudgetItemSumFields = {
  __typename?: 'project_project_budget_item_sum_fields';
  /** ตัวคูณ */
  multiplier?: Maybe<Scalars['numeric']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['numeric']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** จำนวนเงินทั้งหมด */
  total?: Maybe<Scalars['numeric']>;
  /** จำนวน */
  unit_amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "project.project_budget_item" */
export type ProjectProjectBudgetItemSumOrderBy = {
  /** ตัวคูณ */
  multiplier?: Maybe<OrderBy>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** จำนวนเงินทั้งหมด */
  total?: Maybe<OrderBy>;
  /** จำนวน */
  unit_amount?: Maybe<OrderBy>;
};

/** columns and relationships of "project.project_budget_item_summary" */
export type ProjectProjectBudgetItemSummary = {
  __typename?: 'project_project_budget_item_summary';
  amount_unit_name?: Maybe<Scalars['String']>;
  budget_group_id?: Maybe<Scalars['String']>;
  budget_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  multipier_unit_name?: Maybe<Scalars['String']>;
  multiplier?: Maybe<Scalars['numeric']>;
  note?: Maybe<Scalars['String']>;
  operating_activity_name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  price_per_unit?: Maybe<Scalars['numeric']>;
  project_operating_activity_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['numeric']>;
  target_amount?: Maybe<Scalars['numeric']>;
  target_unit_id?: Maybe<Scalars['String']>;
  target_unit_name?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['numeric']>;
  unit_amount?: Maybe<Scalars['numeric']>;
  unit_id?: Maybe<Scalars['String']>;
  unit_of_multiplier_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "project.project_budget_item_summary" */
export type ProjectProjectBudgetItemSummaryAggregate = {
  __typename?: 'project_project_budget_item_summary_aggregate';
  aggregate?: Maybe<ProjectProjectBudgetItemSummaryAggregateFields>;
  nodes: Array<ProjectProjectBudgetItemSummary>;
};

/** aggregate fields of "project.project_budget_item_summary" */
export type ProjectProjectBudgetItemSummaryAggregateFields = {
  __typename?: 'project_project_budget_item_summary_aggregate_fields';
  avg?: Maybe<ProjectProjectBudgetItemSummaryAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectProjectBudgetItemSummaryMaxFields>;
  min?: Maybe<ProjectProjectBudgetItemSummaryMinFields>;
  stddev?: Maybe<ProjectProjectBudgetItemSummaryStddevFields>;
  stddev_pop?: Maybe<ProjectProjectBudgetItemSummaryStddevPopFields>;
  stddev_samp?: Maybe<ProjectProjectBudgetItemSummaryStddevSampFields>;
  sum?: Maybe<ProjectProjectBudgetItemSummarySumFields>;
  var_pop?: Maybe<ProjectProjectBudgetItemSummaryVarPopFields>;
  var_samp?: Maybe<ProjectProjectBudgetItemSummaryVarSampFields>;
  variance?: Maybe<ProjectProjectBudgetItemSummaryVarianceFields>;
};


/** aggregate fields of "project.project_budget_item_summary" */
export type ProjectProjectBudgetItemSummaryAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectProjectBudgetItemSummarySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ProjectProjectBudgetItemSummaryAvgFields = {
  __typename?: 'project_project_budget_item_summary_avg_fields';
  multiplier?: Maybe<Scalars['Float']>;
  price_per_unit?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  target_amount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  unit_amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "project.project_budget_item_summary". All fields are combined with a logical 'AND'. */
export type ProjectProjectBudgetItemSummaryBoolExp = {
  _and?: Maybe<Array<ProjectProjectBudgetItemSummaryBoolExp>>;
  _not?: Maybe<ProjectProjectBudgetItemSummaryBoolExp>;
  _or?: Maybe<Array<ProjectProjectBudgetItemSummaryBoolExp>>;
  amount_unit_name?: Maybe<StringComparisonExp>;
  budget_group_id?: Maybe<StringComparisonExp>;
  budget_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  multipier_unit_name?: Maybe<StringComparisonExp>;
  multiplier?: Maybe<NumericComparisonExp>;
  note?: Maybe<StringComparisonExp>;
  operating_activity_name?: Maybe<StringComparisonExp>;
  organization_id?: Maybe<UuidComparisonExp>;
  price_per_unit?: Maybe<NumericComparisonExp>;
  project_operating_activity_id?: Maybe<UuidComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
  target_amount?: Maybe<NumericComparisonExp>;
  target_unit_id?: Maybe<StringComparisonExp>;
  target_unit_name?: Maybe<StringComparisonExp>;
  total?: Maybe<NumericComparisonExp>;
  unit_amount?: Maybe<NumericComparisonExp>;
  unit_id?: Maybe<StringComparisonExp>;
  unit_of_multiplier_id?: Maybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type ProjectProjectBudgetItemSummaryMaxFields = {
  __typename?: 'project_project_budget_item_summary_max_fields';
  amount_unit_name?: Maybe<Scalars['String']>;
  budget_group_id?: Maybe<Scalars['String']>;
  budget_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  multipier_unit_name?: Maybe<Scalars['String']>;
  multiplier?: Maybe<Scalars['numeric']>;
  note?: Maybe<Scalars['String']>;
  operating_activity_name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  price_per_unit?: Maybe<Scalars['numeric']>;
  project_operating_activity_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['numeric']>;
  target_amount?: Maybe<Scalars['numeric']>;
  target_unit_id?: Maybe<Scalars['String']>;
  target_unit_name?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['numeric']>;
  unit_amount?: Maybe<Scalars['numeric']>;
  unit_id?: Maybe<Scalars['String']>;
  unit_of_multiplier_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ProjectProjectBudgetItemSummaryMinFields = {
  __typename?: 'project_project_budget_item_summary_min_fields';
  amount_unit_name?: Maybe<Scalars['String']>;
  budget_group_id?: Maybe<Scalars['String']>;
  budget_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  multipier_unit_name?: Maybe<Scalars['String']>;
  multiplier?: Maybe<Scalars['numeric']>;
  note?: Maybe<Scalars['String']>;
  operating_activity_name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  price_per_unit?: Maybe<Scalars['numeric']>;
  project_operating_activity_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['numeric']>;
  target_amount?: Maybe<Scalars['numeric']>;
  target_unit_id?: Maybe<Scalars['String']>;
  target_unit_name?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['numeric']>;
  unit_amount?: Maybe<Scalars['numeric']>;
  unit_id?: Maybe<Scalars['String']>;
  unit_of_multiplier_id?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "project.project_budget_item_summary". */
export type ProjectProjectBudgetItemSummaryOrderBy = {
  amount_unit_name?: Maybe<OrderBy>;
  budget_group_id?: Maybe<OrderBy>;
  budget_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  multipier_unit_name?: Maybe<OrderBy>;
  multiplier?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  operating_activity_name?: Maybe<OrderBy>;
  organization_id?: Maybe<OrderBy>;
  price_per_unit?: Maybe<OrderBy>;
  project_operating_activity_id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
  target_amount?: Maybe<OrderBy>;
  target_unit_id?: Maybe<OrderBy>;
  target_unit_name?: Maybe<OrderBy>;
  total?: Maybe<OrderBy>;
  unit_amount?: Maybe<OrderBy>;
  unit_id?: Maybe<OrderBy>;
  unit_of_multiplier_id?: Maybe<OrderBy>;
};

/** select columns of table "project.project_budget_item_summary" */
export enum ProjectProjectBudgetItemSummarySelectColumn {
  /** column name */
  AmountUnitName = 'amount_unit_name',
  /** column name */
  BudgetGroupId = 'budget_group_id',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  Id = 'id',
  /** column name */
  MultipierUnitName = 'multipier_unit_name',
  /** column name */
  Multiplier = 'multiplier',
  /** column name */
  Note = 'note',
  /** column name */
  OperatingActivityName = 'operating_activity_name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PricePerUnit = 'price_per_unit',
  /** column name */
  ProjectOperatingActivityId = 'project_operating_activity_id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  TargetAmount = 'target_amount',
  /** column name */
  TargetUnitId = 'target_unit_id',
  /** column name */
  TargetUnitName = 'target_unit_name',
  /** column name */
  Total = 'total',
  /** column name */
  UnitAmount = 'unit_amount',
  /** column name */
  UnitId = 'unit_id',
  /** column name */
  UnitOfMultiplierId = 'unit_of_multiplier_id'
}

/** aggregate stddev on columns */
export type ProjectProjectBudgetItemSummaryStddevFields = {
  __typename?: 'project_project_budget_item_summary_stddev_fields';
  multiplier?: Maybe<Scalars['Float']>;
  price_per_unit?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  target_amount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  unit_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ProjectProjectBudgetItemSummaryStddevPopFields = {
  __typename?: 'project_project_budget_item_summary_stddev_pop_fields';
  multiplier?: Maybe<Scalars['Float']>;
  price_per_unit?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  target_amount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  unit_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ProjectProjectBudgetItemSummaryStddevSampFields = {
  __typename?: 'project_project_budget_item_summary_stddev_samp_fields';
  multiplier?: Maybe<Scalars['Float']>;
  price_per_unit?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  target_amount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  unit_amount?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ProjectProjectBudgetItemSummarySumFields = {
  __typename?: 'project_project_budget_item_summary_sum_fields';
  multiplier?: Maybe<Scalars['numeric']>;
  price_per_unit?: Maybe<Scalars['numeric']>;
  sequence?: Maybe<Scalars['numeric']>;
  target_amount?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
  unit_amount?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type ProjectProjectBudgetItemSummaryVarPopFields = {
  __typename?: 'project_project_budget_item_summary_var_pop_fields';
  multiplier?: Maybe<Scalars['Float']>;
  price_per_unit?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  target_amount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  unit_amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ProjectProjectBudgetItemSummaryVarSampFields = {
  __typename?: 'project_project_budget_item_summary_var_samp_fields';
  multiplier?: Maybe<Scalars['Float']>;
  price_per_unit?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  target_amount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  unit_amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ProjectProjectBudgetItemSummaryVarianceFields = {
  __typename?: 'project_project_budget_item_summary_variance_fields';
  multiplier?: Maybe<Scalars['Float']>;
  price_per_unit?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  target_amount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  unit_amount?: Maybe<Scalars['Float']>;
};

/** update columns of table "project.project_budget_item" */
export enum ProjectProjectBudgetItemUpdateColumn {
  /** column name */
  BudgetGroupId = 'budget_group_id',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  Id = 'id',
  /** column name */
  Multiplier = 'multiplier',
  /** column name */
  Note = 'note',
  /** column name */
  PricePerUnit = 'price_per_unit',
  /** column name */
  ProjectOperatingActivityId = 'project_operating_activity_id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  Total = 'total',
  /** column name */
  UnitAmount = 'unit_amount',
  /** column name */
  UnitId = 'unit_id',
  /** column name */
  UnitOfMultiplierId = 'unit_of_multiplier_id'
}

/** aggregate var_pop on columns */
export type ProjectProjectBudgetItemVarPopFields = {
  __typename?: 'project_project_budget_item_var_pop_fields';
  /** ตัวคูณ */
  multiplier?: Maybe<Scalars['Float']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
  /** จำนวนเงินทั้งหมด */
  total?: Maybe<Scalars['Float']>;
  /** จำนวน */
  unit_amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project.project_budget_item" */
export type ProjectProjectBudgetItemVarPopOrderBy = {
  /** ตัวคูณ */
  multiplier?: Maybe<OrderBy>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** จำนวนเงินทั้งหมด */
  total?: Maybe<OrderBy>;
  /** จำนวน */
  unit_amount?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ProjectProjectBudgetItemVarSampFields = {
  __typename?: 'project_project_budget_item_var_samp_fields';
  /** ตัวคูณ */
  multiplier?: Maybe<Scalars['Float']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
  /** จำนวนเงินทั้งหมด */
  total?: Maybe<Scalars['Float']>;
  /** จำนวน */
  unit_amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project.project_budget_item" */
export type ProjectProjectBudgetItemVarSampOrderBy = {
  /** ตัวคูณ */
  multiplier?: Maybe<OrderBy>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** จำนวนเงินทั้งหมด */
  total?: Maybe<OrderBy>;
  /** จำนวน */
  unit_amount?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProjectProjectBudgetItemVarianceFields = {
  __typename?: 'project_project_budget_item_variance_fields';
  /** ตัวคูณ */
  multiplier?: Maybe<Scalars['Float']>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
  /** จำนวนเงินทั้งหมด */
  total?: Maybe<Scalars['Float']>;
  /** จำนวน */
  unit_amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project.project_budget_item" */
export type ProjectProjectBudgetItemVarianceOrderBy = {
  /** ตัวคูณ */
  multiplier?: Maybe<OrderBy>;
  /** ราคาต่อหน่วย */
  price_per_unit?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** จำนวนเงินทั้งหมด */
  total?: Maybe<OrderBy>;
  /** จำนวน */
  unit_amount?: Maybe<OrderBy>;
};

/** columns and relationships of "project.project_budget_summary" */
export type ProjectProjectBudgetSummary = {
  __typename?: 'project_project_budget_summary';
  /** An object relationship */
  activity?: Maybe<ProjectProjectOperatingActivity>;
  operating_activity_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  total_budget?: Maybe<Scalars['numeric']>;
  total_spend?: Maybe<Scalars['numeric']>;
  total_spend_plan?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "project.project_budget_summary" */
export type ProjectProjectBudgetSummaryAggregate = {
  __typename?: 'project_project_budget_summary_aggregate';
  aggregate?: Maybe<ProjectProjectBudgetSummaryAggregateFields>;
  nodes: Array<ProjectProjectBudgetSummary>;
};

/** aggregate fields of "project.project_budget_summary" */
export type ProjectProjectBudgetSummaryAggregateFields = {
  __typename?: 'project_project_budget_summary_aggregate_fields';
  avg?: Maybe<ProjectProjectBudgetSummaryAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectProjectBudgetSummaryMaxFields>;
  min?: Maybe<ProjectProjectBudgetSummaryMinFields>;
  stddev?: Maybe<ProjectProjectBudgetSummaryStddevFields>;
  stddev_pop?: Maybe<ProjectProjectBudgetSummaryStddevPopFields>;
  stddev_samp?: Maybe<ProjectProjectBudgetSummaryStddevSampFields>;
  sum?: Maybe<ProjectProjectBudgetSummarySumFields>;
  var_pop?: Maybe<ProjectProjectBudgetSummaryVarPopFields>;
  var_samp?: Maybe<ProjectProjectBudgetSummaryVarSampFields>;
  variance?: Maybe<ProjectProjectBudgetSummaryVarianceFields>;
};


/** aggregate fields of "project.project_budget_summary" */
export type ProjectProjectBudgetSummaryAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectProjectBudgetSummarySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project.project_budget_summary" */
export type ProjectProjectBudgetSummaryAggregateOrderBy = {
  avg?: Maybe<ProjectProjectBudgetSummaryAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProjectProjectBudgetSummaryMaxOrderBy>;
  min?: Maybe<ProjectProjectBudgetSummaryMinOrderBy>;
  stddev?: Maybe<ProjectProjectBudgetSummaryStddevOrderBy>;
  stddev_pop?: Maybe<ProjectProjectBudgetSummaryStddevPopOrderBy>;
  stddev_samp?: Maybe<ProjectProjectBudgetSummaryStddevSampOrderBy>;
  sum?: Maybe<ProjectProjectBudgetSummarySumOrderBy>;
  var_pop?: Maybe<ProjectProjectBudgetSummaryVarPopOrderBy>;
  var_samp?: Maybe<ProjectProjectBudgetSummaryVarSampOrderBy>;
  variance?: Maybe<ProjectProjectBudgetSummaryVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "project.project_budget_summary" */
export type ProjectProjectBudgetSummaryArrRelInsertInput = {
  data: Array<ProjectProjectBudgetSummaryInsertInput>;
};

/** aggregate avg on columns */
export type ProjectProjectBudgetSummaryAvgFields = {
  __typename?: 'project_project_budget_summary_avg_fields';
  total_budget?: Maybe<Scalars['Float']>;
  total_spend?: Maybe<Scalars['Float']>;
  total_spend_plan?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project.project_budget_summary" */
export type ProjectProjectBudgetSummaryAvgOrderBy = {
  total_budget?: Maybe<OrderBy>;
  total_spend?: Maybe<OrderBy>;
  total_spend_plan?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "project.project_budget_summary". All fields are combined with a logical 'AND'. */
export type ProjectProjectBudgetSummaryBoolExp = {
  _and?: Maybe<Array<ProjectProjectBudgetSummaryBoolExp>>;
  _not?: Maybe<ProjectProjectBudgetSummaryBoolExp>;
  _or?: Maybe<Array<ProjectProjectBudgetSummaryBoolExp>>;
  activity?: Maybe<ProjectProjectOperatingActivityBoolExp>;
  operating_activity_id?: Maybe<UuidComparisonExp>;
  project_id?: Maybe<UuidComparisonExp>;
  total_budget?: Maybe<NumericComparisonExp>;
  total_spend?: Maybe<NumericComparisonExp>;
  total_spend_plan?: Maybe<NumericComparisonExp>;
};

/** input type for inserting data into table "project.project_budget_summary" */
export type ProjectProjectBudgetSummaryInsertInput = {
  activity?: Maybe<ProjectProjectOperatingActivityObjRelInsertInput>;
  operating_activity_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  total_budget?: Maybe<Scalars['numeric']>;
  total_spend?: Maybe<Scalars['numeric']>;
  total_spend_plan?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type ProjectProjectBudgetSummaryMaxFields = {
  __typename?: 'project_project_budget_summary_max_fields';
  operating_activity_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  total_budget?: Maybe<Scalars['numeric']>;
  total_spend?: Maybe<Scalars['numeric']>;
  total_spend_plan?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "project.project_budget_summary" */
export type ProjectProjectBudgetSummaryMaxOrderBy = {
  operating_activity_id?: Maybe<OrderBy>;
  project_id?: Maybe<OrderBy>;
  total_budget?: Maybe<OrderBy>;
  total_spend?: Maybe<OrderBy>;
  total_spend_plan?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectProjectBudgetSummaryMinFields = {
  __typename?: 'project_project_budget_summary_min_fields';
  operating_activity_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  total_budget?: Maybe<Scalars['numeric']>;
  total_spend?: Maybe<Scalars['numeric']>;
  total_spend_plan?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "project.project_budget_summary" */
export type ProjectProjectBudgetSummaryMinOrderBy = {
  operating_activity_id?: Maybe<OrderBy>;
  project_id?: Maybe<OrderBy>;
  total_budget?: Maybe<OrderBy>;
  total_spend?: Maybe<OrderBy>;
  total_spend_plan?: Maybe<OrderBy>;
};

/** Ordering options when selecting data from "project.project_budget_summary". */
export type ProjectProjectBudgetSummaryOrderBy = {
  activity?: Maybe<ProjectProjectOperatingActivityOrderBy>;
  operating_activity_id?: Maybe<OrderBy>;
  project_id?: Maybe<OrderBy>;
  total_budget?: Maybe<OrderBy>;
  total_spend?: Maybe<OrderBy>;
  total_spend_plan?: Maybe<OrderBy>;
};

/** select columns of table "project.project_budget_summary" */
export enum ProjectProjectBudgetSummarySelectColumn {
  /** column name */
  OperatingActivityId = 'operating_activity_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  TotalBudget = 'total_budget',
  /** column name */
  TotalSpend = 'total_spend',
  /** column name */
  TotalSpendPlan = 'total_spend_plan'
}

/** aggregate stddev on columns */
export type ProjectProjectBudgetSummaryStddevFields = {
  __typename?: 'project_project_budget_summary_stddev_fields';
  total_budget?: Maybe<Scalars['Float']>;
  total_spend?: Maybe<Scalars['Float']>;
  total_spend_plan?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project.project_budget_summary" */
export type ProjectProjectBudgetSummaryStddevOrderBy = {
  total_budget?: Maybe<OrderBy>;
  total_spend?: Maybe<OrderBy>;
  total_spend_plan?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ProjectProjectBudgetSummaryStddevPopFields = {
  __typename?: 'project_project_budget_summary_stddev_pop_fields';
  total_budget?: Maybe<Scalars['Float']>;
  total_spend?: Maybe<Scalars['Float']>;
  total_spend_plan?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project.project_budget_summary" */
export type ProjectProjectBudgetSummaryStddevPopOrderBy = {
  total_budget?: Maybe<OrderBy>;
  total_spend?: Maybe<OrderBy>;
  total_spend_plan?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ProjectProjectBudgetSummaryStddevSampFields = {
  __typename?: 'project_project_budget_summary_stddev_samp_fields';
  total_budget?: Maybe<Scalars['Float']>;
  total_spend?: Maybe<Scalars['Float']>;
  total_spend_plan?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project.project_budget_summary" */
export type ProjectProjectBudgetSummaryStddevSampOrderBy = {
  total_budget?: Maybe<OrderBy>;
  total_spend?: Maybe<OrderBy>;
  total_spend_plan?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ProjectProjectBudgetSummarySumFields = {
  __typename?: 'project_project_budget_summary_sum_fields';
  total_budget?: Maybe<Scalars['numeric']>;
  total_spend?: Maybe<Scalars['numeric']>;
  total_spend_plan?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "project.project_budget_summary" */
export type ProjectProjectBudgetSummarySumOrderBy = {
  total_budget?: Maybe<OrderBy>;
  total_spend?: Maybe<OrderBy>;
  total_spend_plan?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ProjectProjectBudgetSummaryVarPopFields = {
  __typename?: 'project_project_budget_summary_var_pop_fields';
  total_budget?: Maybe<Scalars['Float']>;
  total_spend?: Maybe<Scalars['Float']>;
  total_spend_plan?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project.project_budget_summary" */
export type ProjectProjectBudgetSummaryVarPopOrderBy = {
  total_budget?: Maybe<OrderBy>;
  total_spend?: Maybe<OrderBy>;
  total_spend_plan?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ProjectProjectBudgetSummaryVarSampFields = {
  __typename?: 'project_project_budget_summary_var_samp_fields';
  total_budget?: Maybe<Scalars['Float']>;
  total_spend?: Maybe<Scalars['Float']>;
  total_spend_plan?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project.project_budget_summary" */
export type ProjectProjectBudgetSummaryVarSampOrderBy = {
  total_budget?: Maybe<OrderBy>;
  total_spend?: Maybe<OrderBy>;
  total_spend_plan?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProjectProjectBudgetSummaryVarianceFields = {
  __typename?: 'project_project_budget_summary_variance_fields';
  total_budget?: Maybe<Scalars['Float']>;
  total_spend?: Maybe<Scalars['Float']>;
  total_spend_plan?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project.project_budget_summary" */
export type ProjectProjectBudgetSummaryVarianceOrderBy = {
  total_budget?: Maybe<OrderBy>;
  total_spend?: Maybe<OrderBy>;
  total_spend_plan?: Maybe<OrderBy>;
};

/** columns and relationships of "project.project_budget_template" */
export type ProjectProjectBudgetTemplate = {
  __typename?: 'project_project_budget_template';
  deleted_at?: Maybe<Scalars['timestamptz']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  max_price_per_unit?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  price_per_unit?: Maybe<Scalars['numeric']>;
  project_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  unit?: Maybe<ProjectUnit>;
  unit_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  unit_of_multiplier?: Maybe<ProjectUnit>;
  unit_of_multiplier_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "project.project_budget_template" */
export type ProjectProjectBudgetTemplateAggregate = {
  __typename?: 'project_project_budget_template_aggregate';
  aggregate?: Maybe<ProjectProjectBudgetTemplateAggregateFields>;
  nodes: Array<ProjectProjectBudgetTemplate>;
};

/** aggregate fields of "project.project_budget_template" */
export type ProjectProjectBudgetTemplateAggregateFields = {
  __typename?: 'project_project_budget_template_aggregate_fields';
  avg?: Maybe<ProjectProjectBudgetTemplateAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectProjectBudgetTemplateMaxFields>;
  min?: Maybe<ProjectProjectBudgetTemplateMinFields>;
  stddev?: Maybe<ProjectProjectBudgetTemplateStddevFields>;
  stddev_pop?: Maybe<ProjectProjectBudgetTemplateStddevPopFields>;
  stddev_samp?: Maybe<ProjectProjectBudgetTemplateStddevSampFields>;
  sum?: Maybe<ProjectProjectBudgetTemplateSumFields>;
  var_pop?: Maybe<ProjectProjectBudgetTemplateVarPopFields>;
  var_samp?: Maybe<ProjectProjectBudgetTemplateVarSampFields>;
  variance?: Maybe<ProjectProjectBudgetTemplateVarianceFields>;
};


/** aggregate fields of "project.project_budget_template" */
export type ProjectProjectBudgetTemplateAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectProjectBudgetTemplateSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project.project_budget_template" */
export type ProjectProjectBudgetTemplateAggregateOrderBy = {
  avg?: Maybe<ProjectProjectBudgetTemplateAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProjectProjectBudgetTemplateMaxOrderBy>;
  min?: Maybe<ProjectProjectBudgetTemplateMinOrderBy>;
  stddev?: Maybe<ProjectProjectBudgetTemplateStddevOrderBy>;
  stddev_pop?: Maybe<ProjectProjectBudgetTemplateStddevPopOrderBy>;
  stddev_samp?: Maybe<ProjectProjectBudgetTemplateStddevSampOrderBy>;
  sum?: Maybe<ProjectProjectBudgetTemplateSumOrderBy>;
  var_pop?: Maybe<ProjectProjectBudgetTemplateVarPopOrderBy>;
  var_samp?: Maybe<ProjectProjectBudgetTemplateVarSampOrderBy>;
  variance?: Maybe<ProjectProjectBudgetTemplateVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "project.project_budget_template" */
export type ProjectProjectBudgetTemplateArrRelInsertInput = {
  data: Array<ProjectProjectBudgetTemplateInsertInput>;
};

/** aggregate avg on columns */
export type ProjectProjectBudgetTemplateAvgFields = {
  __typename?: 'project_project_budget_template_avg_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
  max_price_per_unit?: Maybe<Scalars['Float']>;
  price_per_unit?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project.project_budget_template" */
export type ProjectProjectBudgetTemplateAvgOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  max_price_per_unit?: Maybe<OrderBy>;
  price_per_unit?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "project.project_budget_template". All fields are combined with a logical 'AND'. */
export type ProjectProjectBudgetTemplateBoolExp = {
  _and?: Maybe<Array<ProjectProjectBudgetTemplateBoolExp>>;
  _not?: Maybe<ProjectProjectBudgetTemplateBoolExp>;
  _or?: Maybe<Array<ProjectProjectBudgetTemplateBoolExp>>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  fiscal_year?: Maybe<IntComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  max_price_per_unit?: Maybe<NumericComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  price_per_unit?: Maybe<NumericComparisonExp>;
  project_id?: Maybe<UuidComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
  unit?: Maybe<ProjectUnitBoolExp>;
  unit_id?: Maybe<StringComparisonExp>;
  unit_of_multiplier?: Maybe<ProjectUnitBoolExp>;
  unit_of_multiplier_id?: Maybe<StringComparisonExp>;
};

/** input type for inserting data into table "project.project_budget_template" */
export type ProjectProjectBudgetTemplateInsertInput = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  max_price_per_unit?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  price_per_unit?: Maybe<Scalars['numeric']>;
  project_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['numeric']>;
  unit?: Maybe<ProjectUnitObjRelInsertInput>;
  unit_id?: Maybe<Scalars['String']>;
  unit_of_multiplier?: Maybe<ProjectUnitObjRelInsertInput>;
  unit_of_multiplier_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ProjectProjectBudgetTemplateMaxFields = {
  __typename?: 'project_project_budget_template_max_fields';
  deleted_at?: Maybe<Scalars['timestamptz']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  max_price_per_unit?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  price_per_unit?: Maybe<Scalars['numeric']>;
  project_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['numeric']>;
  unit_id?: Maybe<Scalars['String']>;
  unit_of_multiplier_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "project.project_budget_template" */
export type ProjectProjectBudgetTemplateMaxOrderBy = {
  deleted_at?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  max_price_per_unit?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  price_per_unit?: Maybe<OrderBy>;
  project_id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
  unit_id?: Maybe<OrderBy>;
  unit_of_multiplier_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectProjectBudgetTemplateMinFields = {
  __typename?: 'project_project_budget_template_min_fields';
  deleted_at?: Maybe<Scalars['timestamptz']>;
  fiscal_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  max_price_per_unit?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  price_per_unit?: Maybe<Scalars['numeric']>;
  project_id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['numeric']>;
  unit_id?: Maybe<Scalars['String']>;
  unit_of_multiplier_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "project.project_budget_template" */
export type ProjectProjectBudgetTemplateMinOrderBy = {
  deleted_at?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  max_price_per_unit?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  price_per_unit?: Maybe<OrderBy>;
  project_id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
  unit_id?: Maybe<OrderBy>;
  unit_of_multiplier_id?: Maybe<OrderBy>;
};

/** Ordering options when selecting data from "project.project_budget_template". */
export type ProjectProjectBudgetTemplateOrderBy = {
  deleted_at?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  max_price_per_unit?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  price_per_unit?: Maybe<OrderBy>;
  project_id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
  unit?: Maybe<ProjectUnitOrderBy>;
  unit_id?: Maybe<OrderBy>;
  unit_of_multiplier?: Maybe<ProjectUnitOrderBy>;
  unit_of_multiplier_id?: Maybe<OrderBy>;
};

/** select columns of table "project.project_budget_template" */
export enum ProjectProjectBudgetTemplateSelectColumn {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  Id = 'id',
  /** column name */
  MaxPricePerUnit = 'max_price_per_unit',
  /** column name */
  Name = 'name',
  /** column name */
  PricePerUnit = 'price_per_unit',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  UnitId = 'unit_id',
  /** column name */
  UnitOfMultiplierId = 'unit_of_multiplier_id'
}

/** aggregate stddev on columns */
export type ProjectProjectBudgetTemplateStddevFields = {
  __typename?: 'project_project_budget_template_stddev_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
  max_price_per_unit?: Maybe<Scalars['Float']>;
  price_per_unit?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project.project_budget_template" */
export type ProjectProjectBudgetTemplateStddevOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  max_price_per_unit?: Maybe<OrderBy>;
  price_per_unit?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ProjectProjectBudgetTemplateStddevPopFields = {
  __typename?: 'project_project_budget_template_stddev_pop_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
  max_price_per_unit?: Maybe<Scalars['Float']>;
  price_per_unit?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project.project_budget_template" */
export type ProjectProjectBudgetTemplateStddevPopOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  max_price_per_unit?: Maybe<OrderBy>;
  price_per_unit?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ProjectProjectBudgetTemplateStddevSampFields = {
  __typename?: 'project_project_budget_template_stddev_samp_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
  max_price_per_unit?: Maybe<Scalars['Float']>;
  price_per_unit?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project.project_budget_template" */
export type ProjectProjectBudgetTemplateStddevSampOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  max_price_per_unit?: Maybe<OrderBy>;
  price_per_unit?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ProjectProjectBudgetTemplateSumFields = {
  __typename?: 'project_project_budget_template_sum_fields';
  fiscal_year?: Maybe<Scalars['Int']>;
  max_price_per_unit?: Maybe<Scalars['numeric']>;
  price_per_unit?: Maybe<Scalars['numeric']>;
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "project.project_budget_template" */
export type ProjectProjectBudgetTemplateSumOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  max_price_per_unit?: Maybe<OrderBy>;
  price_per_unit?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ProjectProjectBudgetTemplateVarPopFields = {
  __typename?: 'project_project_budget_template_var_pop_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
  max_price_per_unit?: Maybe<Scalars['Float']>;
  price_per_unit?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project.project_budget_template" */
export type ProjectProjectBudgetTemplateVarPopOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  max_price_per_unit?: Maybe<OrderBy>;
  price_per_unit?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ProjectProjectBudgetTemplateVarSampFields = {
  __typename?: 'project_project_budget_template_var_samp_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
  max_price_per_unit?: Maybe<Scalars['Float']>;
  price_per_unit?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project.project_budget_template" */
export type ProjectProjectBudgetTemplateVarSampOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  max_price_per_unit?: Maybe<OrderBy>;
  price_per_unit?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProjectProjectBudgetTemplateVarianceFields = {
  __typename?: 'project_project_budget_template_variance_fields';
  fiscal_year?: Maybe<Scalars['Float']>;
  max_price_per_unit?: Maybe<Scalars['Float']>;
  price_per_unit?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project.project_budget_template" */
export type ProjectProjectBudgetTemplateVarianceOrderBy = {
  fiscal_year?: Maybe<OrderBy>;
  max_price_per_unit?: Maybe<OrderBy>;
  price_per_unit?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** unique or primary key constraints on table "project.project" */
export enum ProjectProjectConstraint {
  /** unique or primary key constraint */
  ProjectPkey = 'project_pkey'
}

/**
 * Event Log
 *
 *
 * columns and relationships of "project.project_event_log"
 */
export type ProjectProjectEventLog = {
  __typename?: 'project_project_event_log';
  /** หน่วยงาน */
  actor_organization_id?: Maybe<Scalars['uuid']>;
  /** ผู้ที่กระทำ */
  actor_user_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  approved?: Maybe<ProjectApproved>;
  /** สถานะการตรวจสอบของโครงการ */
  approved_id?: Maybe<ProjectApprovedEnum>;
  /** วันที่บันทึกข้อมูล */
  created_at: Scalars['timestamptz'];
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** An object relationship */
  organization?: Maybe<Organization>;
  /** An object relationship */
  project: ProjectProject;
  /** โครงการ */
  project_id: Scalars['uuid'];
  /** true = แสดงให้ทุกคนมองเห็น, false = แสดงให้เห็นเฉพาะผู้ที่ได้รับอนุญาต */
  public: Scalars['Boolean'];
  /** รหัสอ้างอิง */
  reference?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  remark?: Maybe<Scalars['String']>;
  /** สถานะโครงการ */
  step_id?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "project.project_event_log" */
export type ProjectProjectEventLogAggregate = {
  __typename?: 'project_project_event_log_aggregate';
  aggregate?: Maybe<ProjectProjectEventLogAggregateFields>;
  nodes: Array<ProjectProjectEventLog>;
};

/** aggregate fields of "project.project_event_log" */
export type ProjectProjectEventLogAggregateFields = {
  __typename?: 'project_project_event_log_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ProjectProjectEventLogMaxFields>;
  min?: Maybe<ProjectProjectEventLogMinFields>;
};


/** aggregate fields of "project.project_event_log" */
export type ProjectProjectEventLogAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectProjectEventLogSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project.project_event_log" */
export type ProjectProjectEventLogAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ProjectProjectEventLogMaxOrderBy>;
  min?: Maybe<ProjectProjectEventLogMinOrderBy>;
};

/** input type for inserting array relation for remote table "project.project_event_log" */
export type ProjectProjectEventLogArrRelInsertInput = {
  data: Array<ProjectProjectEventLogInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectProjectEventLogOnConflict>;
};

/** Boolean expression to filter rows from the table "project.project_event_log". All fields are combined with a logical 'AND'. */
export type ProjectProjectEventLogBoolExp = {
  _and?: Maybe<Array<ProjectProjectEventLogBoolExp>>;
  _not?: Maybe<ProjectProjectEventLogBoolExp>;
  _or?: Maybe<Array<ProjectProjectEventLogBoolExp>>;
  actor_organization_id?: Maybe<UuidComparisonExp>;
  actor_user_id?: Maybe<UuidComparisonExp>;
  approved?: Maybe<ProjectApprovedBoolExp>;
  approved_id?: Maybe<ProjectApprovedEnumComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  organization?: Maybe<OrganizationBoolExp>;
  project?: Maybe<ProjectProjectBoolExp>;
  project_id?: Maybe<UuidComparisonExp>;
  public?: Maybe<BooleanComparisonExp>;
  reference?: Maybe<StringComparisonExp>;
  remark?: Maybe<StringComparisonExp>;
  step_id?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<UsersBoolExp>;
};

/** unique or primary key constraints on table "project.project_event_log" */
export enum ProjectProjectEventLogConstraint {
  /** unique or primary key constraint */
  ProjectEventLogPkey = 'project_event_log_pkey'
}

/** input type for inserting data into table "project.project_event_log" */
export type ProjectProjectEventLogInsertInput = {
  /** หน่วยงาน */
  actor_organization_id?: Maybe<Scalars['uuid']>;
  /** ผู้ที่กระทำ */
  actor_user_id?: Maybe<Scalars['uuid']>;
  approved?: Maybe<ProjectApprovedObjRelInsertInput>;
  /** สถานะการตรวจสอบของโครงการ */
  approved_id?: Maybe<ProjectApprovedEnum>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  organization?: Maybe<OrganizationObjRelInsertInput>;
  project?: Maybe<ProjectProjectObjRelInsertInput>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** true = แสดงให้ทุกคนมองเห็น, false = แสดงให้เห็นเฉพาะผู้ที่ได้รับอนุญาต */
  public?: Maybe<Scalars['Boolean']>;
  /** รหัสอ้างอิง */
  reference?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  remark?: Maybe<Scalars['String']>;
  /** สถานะโครงการ */
  step_id?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<UsersObjRelInsertInput>;
};

/** aggregate max on columns */
export type ProjectProjectEventLogMaxFields = {
  __typename?: 'project_project_event_log_max_fields';
  /** หน่วยงาน */
  actor_organization_id?: Maybe<Scalars['uuid']>;
  /** ผู้ที่กระทำ */
  actor_user_id?: Maybe<Scalars['uuid']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** รหัสอ้างอิง */
  reference?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  remark?: Maybe<Scalars['String']>;
  /** สถานะโครงการ */
  step_id?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "project.project_event_log" */
export type ProjectProjectEventLogMaxOrderBy = {
  /** หน่วยงาน */
  actor_organization_id?: Maybe<OrderBy>;
  /** ผู้ที่กระทำ */
  actor_user_id?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** คำอธิบาย */
  description?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** โครงการ */
  project_id?: Maybe<OrderBy>;
  /** รหัสอ้างอิง */
  reference?: Maybe<OrderBy>;
  /** หมายเหตุ */
  remark?: Maybe<OrderBy>;
  /** สถานะโครงการ */
  step_id?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectProjectEventLogMinFields = {
  __typename?: 'project_project_event_log_min_fields';
  /** หน่วยงาน */
  actor_organization_id?: Maybe<Scalars['uuid']>;
  /** ผู้ที่กระทำ */
  actor_user_id?: Maybe<Scalars['uuid']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** รหัสอ้างอิง */
  reference?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  remark?: Maybe<Scalars['String']>;
  /** สถานะโครงการ */
  step_id?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "project.project_event_log" */
export type ProjectProjectEventLogMinOrderBy = {
  /** หน่วยงาน */
  actor_organization_id?: Maybe<OrderBy>;
  /** ผู้ที่กระทำ */
  actor_user_id?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** คำอธิบาย */
  description?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** โครงการ */
  project_id?: Maybe<OrderBy>;
  /** รหัสอ้างอิง */
  reference?: Maybe<OrderBy>;
  /** หมายเหตุ */
  remark?: Maybe<OrderBy>;
  /** สถานะโครงการ */
  step_id?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "project.project_event_log" */
export type ProjectProjectEventLogMutationResponse = {
  __typename?: 'project_project_event_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectProjectEventLog>;
};

/** on conflict condition type for table "project.project_event_log" */
export type ProjectProjectEventLogOnConflict = {
  constraint: ProjectProjectEventLogConstraint;
  update_columns?: Array<ProjectProjectEventLogUpdateColumn>;
  where?: Maybe<ProjectProjectEventLogBoolExp>;
};

/** Ordering options when selecting data from "project.project_event_log". */
export type ProjectProjectEventLogOrderBy = {
  actor_organization_id?: Maybe<OrderBy>;
  actor_user_id?: Maybe<OrderBy>;
  approved?: Maybe<ProjectApprovedOrderBy>;
  approved_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  organization?: Maybe<OrganizationOrderBy>;
  project?: Maybe<ProjectProjectOrderBy>;
  project_id?: Maybe<OrderBy>;
  public?: Maybe<OrderBy>;
  reference?: Maybe<OrderBy>;
  remark?: Maybe<OrderBy>;
  step_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user?: Maybe<UsersOrderBy>;
};

/** primary key columns input for table: project_project_event_log */
export type ProjectProjectEventLogPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "project.project_event_log" */
export enum ProjectProjectEventLogSelectColumn {
  /** column name */
  ActorOrganizationId = 'actor_organization_id',
  /** column name */
  ActorUserId = 'actor_user_id',
  /** column name */
  ApprovedId = 'approved_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Public = 'public',
  /** column name */
  Reference = 'reference',
  /** column name */
  Remark = 'remark',
  /** column name */
  StepId = 'step_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "project.project_event_log" */
export type ProjectProjectEventLogSetInput = {
  /** หน่วยงาน */
  actor_organization_id?: Maybe<Scalars['uuid']>;
  /** ผู้ที่กระทำ */
  actor_user_id?: Maybe<Scalars['uuid']>;
  /** สถานะการตรวจสอบของโครงการ */
  approved_id?: Maybe<ProjectApprovedEnum>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** true = แสดงให้ทุกคนมองเห็น, false = แสดงให้เห็นเฉพาะผู้ที่ได้รับอนุญาต */
  public?: Maybe<Scalars['Boolean']>;
  /** รหัสอ้างอิง */
  reference?: Maybe<Scalars['String']>;
  /** หมายเหตุ */
  remark?: Maybe<Scalars['String']>;
  /** สถานะโครงการ */
  step_id?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "project.project_event_log" */
export enum ProjectProjectEventLogUpdateColumn {
  /** column name */
  ActorOrganizationId = 'actor_organization_id',
  /** column name */
  ActorUserId = 'actor_user_id',
  /** column name */
  ApprovedId = 'approved_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Public = 'public',
  /** column name */
  Reference = 'reference',
  /** column name */
  Remark = 'remark',
  /** column name */
  StepId = 'step_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for incrementing numeric columns in table "project.project" */
export type ProjectProjectIncInput = {
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** งบประมาณ */
  total_budget?: Maybe<Scalars['numeric']>;
};

/**
 * ตัวชี้วัดโครงการ
 *
 *
 * columns and relationships of "project.project_indicator"
 */
export type ProjectProjectIndicator = {
  __typename?: 'project_project_indicator';
  /** จำนวน */
  amount: Scalars['numeric'];
  /** Primary key */
  id: Scalars['uuid'];
  /** An object relationship */
  indicator_type: ProjectIndicatorType;
  /** ประเภทตัวชี้วัดโครงการ */
  indicator_type_id: ProjectIndicatorTypeEnum;
  /** ชื่อตัวชี้วัด */
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  project?: Maybe<ProjectProject>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  project_objective: ProjectProjectObjective;
  /** วัตถุประสงค์โครงการ */
  project_objective_id: Scalars['uuid'];
  /** ลำดับที่ */
  sequence: Scalars['numeric'];
  /** An object relationship */
  unit?: Maybe<ProjectUnit>;
  /** หน่วยนับ */
  unit_id?: Maybe<ProjectUnitEnum>;
};

/** aggregated selection of "project.project_indicator" */
export type ProjectProjectIndicatorAggregate = {
  __typename?: 'project_project_indicator_aggregate';
  aggregate?: Maybe<ProjectProjectIndicatorAggregateFields>;
  nodes: Array<ProjectProjectIndicator>;
};

/** aggregate fields of "project.project_indicator" */
export type ProjectProjectIndicatorAggregateFields = {
  __typename?: 'project_project_indicator_aggregate_fields';
  avg?: Maybe<ProjectProjectIndicatorAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectProjectIndicatorMaxFields>;
  min?: Maybe<ProjectProjectIndicatorMinFields>;
  stddev?: Maybe<ProjectProjectIndicatorStddevFields>;
  stddev_pop?: Maybe<ProjectProjectIndicatorStddevPopFields>;
  stddev_samp?: Maybe<ProjectProjectIndicatorStddevSampFields>;
  sum?: Maybe<ProjectProjectIndicatorSumFields>;
  var_pop?: Maybe<ProjectProjectIndicatorVarPopFields>;
  var_samp?: Maybe<ProjectProjectIndicatorVarSampFields>;
  variance?: Maybe<ProjectProjectIndicatorVarianceFields>;
};


/** aggregate fields of "project.project_indicator" */
export type ProjectProjectIndicatorAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectProjectIndicatorSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project.project_indicator" */
export type ProjectProjectIndicatorAggregateOrderBy = {
  avg?: Maybe<ProjectProjectIndicatorAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProjectProjectIndicatorMaxOrderBy>;
  min?: Maybe<ProjectProjectIndicatorMinOrderBy>;
  stddev?: Maybe<ProjectProjectIndicatorStddevOrderBy>;
  stddev_pop?: Maybe<ProjectProjectIndicatorStddevPopOrderBy>;
  stddev_samp?: Maybe<ProjectProjectIndicatorStddevSampOrderBy>;
  sum?: Maybe<ProjectProjectIndicatorSumOrderBy>;
  var_pop?: Maybe<ProjectProjectIndicatorVarPopOrderBy>;
  var_samp?: Maybe<ProjectProjectIndicatorVarSampOrderBy>;
  variance?: Maybe<ProjectProjectIndicatorVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "project.project_indicator" */
export type ProjectProjectIndicatorArrRelInsertInput = {
  data: Array<ProjectProjectIndicatorInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectProjectIndicatorOnConflict>;
};

/** aggregate avg on columns */
export type ProjectProjectIndicatorAvgFields = {
  __typename?: 'project_project_indicator_avg_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project.project_indicator" */
export type ProjectProjectIndicatorAvgOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "project.project_indicator". All fields are combined with a logical 'AND'. */
export type ProjectProjectIndicatorBoolExp = {
  _and?: Maybe<Array<ProjectProjectIndicatorBoolExp>>;
  _not?: Maybe<ProjectProjectIndicatorBoolExp>;
  _or?: Maybe<Array<ProjectProjectIndicatorBoolExp>>;
  amount?: Maybe<NumericComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  indicator_type?: Maybe<ProjectIndicatorTypeBoolExp>;
  indicator_type_id?: Maybe<ProjectIndicatorTypeEnumComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  project?: Maybe<ProjectProjectBoolExp>;
  project_id?: Maybe<UuidComparisonExp>;
  project_objective?: Maybe<ProjectProjectObjectiveBoolExp>;
  project_objective_id?: Maybe<UuidComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
  unit?: Maybe<ProjectUnitBoolExp>;
  unit_id?: Maybe<ProjectUnitEnumComparisonExp>;
};

/** unique or primary key constraints on table "project.project_indicator" */
export enum ProjectProjectIndicatorConstraint {
  /** unique or primary key constraint */
  ProjectIndicatorPkey = 'project_indicator_pkey'
}

/** input type for incrementing numeric columns in table "project.project_indicator" */
export type ProjectProjectIndicatorIncInput = {
  /** จำนวน */
  amount?: Maybe<Scalars['numeric']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "project.project_indicator" */
export type ProjectProjectIndicatorInsertInput = {
  /** จำนวน */
  amount?: Maybe<Scalars['numeric']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  indicator_type?: Maybe<ProjectIndicatorTypeObjRelInsertInput>;
  /** ประเภทตัวชี้วัดโครงการ */
  indicator_type_id?: Maybe<ProjectIndicatorTypeEnum>;
  /** ชื่อตัวชี้วัด */
  name?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectProjectObjRelInsertInput>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  project_objective?: Maybe<ProjectProjectObjectiveObjRelInsertInput>;
  /** วัตถุประสงค์โครงการ */
  project_objective_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  unit?: Maybe<ProjectUnitObjRelInsertInput>;
  /** หน่วยนับ */
  unit_id?: Maybe<ProjectUnitEnum>;
};

/** aggregate max on columns */
export type ProjectProjectIndicatorMaxFields = {
  __typename?: 'project_project_indicator_max_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['numeric']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อตัวชี้วัด */
  name?: Maybe<Scalars['String']>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** วัตถุประสงค์โครงการ */
  project_objective_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "project.project_indicator" */
export type ProjectProjectIndicatorMaxOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ชื่อตัวชี้วัด */
  name?: Maybe<OrderBy>;
  /** โครงการ */
  project_id?: Maybe<OrderBy>;
  /** วัตถุประสงค์โครงการ */
  project_objective_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectProjectIndicatorMinFields = {
  __typename?: 'project_project_indicator_min_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['numeric']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อตัวชี้วัด */
  name?: Maybe<Scalars['String']>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** วัตถุประสงค์โครงการ */
  project_objective_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "project.project_indicator" */
export type ProjectProjectIndicatorMinOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ชื่อตัวชี้วัด */
  name?: Maybe<OrderBy>;
  /** โครงการ */
  project_id?: Maybe<OrderBy>;
  /** วัตถุประสงค์โครงการ */
  project_objective_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** response of any mutation on the table "project.project_indicator" */
export type ProjectProjectIndicatorMutationResponse = {
  __typename?: 'project_project_indicator_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectProjectIndicator>;
};

/** on conflict condition type for table "project.project_indicator" */
export type ProjectProjectIndicatorOnConflict = {
  constraint: ProjectProjectIndicatorConstraint;
  update_columns?: Array<ProjectProjectIndicatorUpdateColumn>;
  where?: Maybe<ProjectProjectIndicatorBoolExp>;
};

/** Ordering options when selecting data from "project.project_indicator". */
export type ProjectProjectIndicatorOrderBy = {
  amount?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  indicator_type?: Maybe<ProjectIndicatorTypeOrderBy>;
  indicator_type_id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  project?: Maybe<ProjectProjectOrderBy>;
  project_id?: Maybe<OrderBy>;
  project_objective?: Maybe<ProjectProjectObjectiveOrderBy>;
  project_objective_id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
  unit?: Maybe<ProjectUnitOrderBy>;
  unit_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: project_project_indicator */
export type ProjectProjectIndicatorPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "project.project_indicator" */
export enum ProjectProjectIndicatorSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  Id = 'id',
  /** column name */
  IndicatorTypeId = 'indicator_type_id',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ProjectObjectiveId = 'project_objective_id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  UnitId = 'unit_id'
}

/** input type for updating data in table "project.project_indicator" */
export type ProjectProjectIndicatorSetInput = {
  /** จำนวน */
  amount?: Maybe<Scalars['numeric']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ประเภทตัวชี้วัดโครงการ */
  indicator_type_id?: Maybe<ProjectIndicatorTypeEnum>;
  /** ชื่อตัวชี้วัด */
  name?: Maybe<Scalars['String']>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** วัตถุประสงค์โครงการ */
  project_objective_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** หน่วยนับ */
  unit_id?: Maybe<ProjectUnitEnum>;
};

/** aggregate stddev on columns */
export type ProjectProjectIndicatorStddevFields = {
  __typename?: 'project_project_indicator_stddev_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project.project_indicator" */
export type ProjectProjectIndicatorStddevOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ProjectProjectIndicatorStddevPopFields = {
  __typename?: 'project_project_indicator_stddev_pop_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project.project_indicator" */
export type ProjectProjectIndicatorStddevPopOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ProjectProjectIndicatorStddevSampFields = {
  __typename?: 'project_project_indicator_stddev_samp_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project.project_indicator" */
export type ProjectProjectIndicatorStddevSampOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ProjectProjectIndicatorSumFields = {
  __typename?: 'project_project_indicator_sum_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['numeric']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "project.project_indicator" */
export type ProjectProjectIndicatorSumOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "project.project_indicator" */
export enum ProjectProjectIndicatorUpdateColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  Id = 'id',
  /** column name */
  IndicatorTypeId = 'indicator_type_id',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ProjectObjectiveId = 'project_objective_id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  UnitId = 'unit_id'
}

/** aggregate var_pop on columns */
export type ProjectProjectIndicatorVarPopFields = {
  __typename?: 'project_project_indicator_var_pop_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project.project_indicator" */
export type ProjectProjectIndicatorVarPopOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ProjectProjectIndicatorVarSampFields = {
  __typename?: 'project_project_indicator_var_samp_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project.project_indicator" */
export type ProjectProjectIndicatorVarSampOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProjectProjectIndicatorVarianceFields = {
  __typename?: 'project_project_indicator_variance_fields';
  /** จำนวน */
  amount?: Maybe<Scalars['Float']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project.project_indicator" */
export type ProjectProjectIndicatorVarianceOrderBy = {
  /** จำนวน */
  amount?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** input type for inserting data into table "project.project" */
export type ProjectProjectInsertInput = {
  approved?: Maybe<ProjectApprovedObjRelInsertInput>;
  /** ข้อความเพิ่มเติมหลังตรวจสอบโครงการ */
  approved_comment?: Maybe<Scalars['String']>;
  /** วันที่ตรวจสอบโครงการ */
  approved_date?: Maybe<Scalars['timestamptz']>;
  /** สถานะการตรวจสอบของโครงการ */
  approved_id?: Maybe<ProjectApprovedEnum>;
  budget_plans?: Maybe<ProjectBudgetPlanArrRelInsertInput>;
  budget_summary?: Maybe<ProjectProjectBudgetSummaryArrRelInsertInput>;
  budget_templates?: Maybe<ProjectProjectBudgetTemplateArrRelInsertInput>;
  /** วันที่พิจารณา: ยกเลิกโครงการ */
  cancelled_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่พิจารณา: แก้ไขเอกสารเพิ่มเติม */
  change_request_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่เสร็จสิ้น */
  completed_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  created_by_user?: Maybe<UsersObjRelInsertInput>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ผลกระทบ */
  effect?: Maybe<Scalars['String']>;
  /** วันที่สิ้นสุด (ระยะเวลาดำเนินการ) */
  end_at?: Maybe<Scalars['date']>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** สาขาอุตสาหกรรม */
  industry?: Maybe<Scalars['String']>;
  /** หลักการและเหตุผลความจำเป็น */
  introduction?: Maybe<Scalars['String']>;
  /** ผลการดำเนินงานในปีที่ผ่านมา */
  last_overall_operation?: Maybe<Scalars['String']>;
  national_strategy_lvl1?: Maybe<ProjectNationalStrategyObjRelInsertInput>;
  /** ด้านยุทธศาสตร์ */
  national_strategy_lvl1_id?: Maybe<Scalars['String']>;
  national_strategy_lvl2?: Maybe<ProjectNationalStrategyObjRelInsertInput>;
  /** ประเด็นแผนแม่บท (จ.1) */
  national_strategy_lvl2_id?: Maybe<Scalars['String']>;
  national_strategy_lvl3?: Maybe<ProjectNationalStrategyObjRelInsertInput>;
  /** เป้าหมายแผนแม่บท (จ.2) */
  national_strategy_lvl3_id?: Maybe<Scalars['String']>;
  national_strategy_lvl4?: Maybe<ProjectNationalStrategyObjRelInsertInput>;
  /** แผนแม่บทย่อย */
  national_strategy_lvl4_id?: Maybe<Scalars['String']>;
  national_strategy_lvl5_id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationObjRelInsertInput>;
  /** หน่วยงานรับผิดชอบ */
  organization_id?: Maybe<Scalars['uuid']>;
  project_achievements?: Maybe<ProjectProjectAchievementArrRelInsertInput>;
  project_attachments?: Maybe<ProjectProjectAttachmentArrRelInsertInput>;
  project_event_logs?: Maybe<ProjectProjectEventLogArrRelInsertInput>;
  project_indicators?: Maybe<ProjectProjectIndicatorArrRelInsertInput>;
  project_members?: Maybe<ProjectProjectMemberArrRelInsertInput>;
  project_objectives?: Maybe<ProjectProjectObjectiveArrRelInsertInput>;
  project_operating_activities?: Maybe<ProjectProjectOperatingActivityArrRelInsertInput>;
  project_operating_areas?: Maybe<ProjectProjectOperatingAreaArrRelInsertInput>;
  project_step?: Maybe<ProjectProjectStepObjRelInsertInput>;
  project_target_groups?: Maybe<ProjectProjectTargetGroupArrRelInsertInput>;
  /** รหัสอ้างอิงโครงการ */
  reference?: Maybe<Scalars['String']>;
  /** วันที่พิจารณา: ไม่อนุมัติโครงการ */
  rejected_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่เริ่ม (ระยะเวลาดำเนินการ) */
  start_at?: Maybe<Scalars['date']>;
  /** สถานะโครงการ */
  step_id?: Maybe<Scalars['String']>;
  /** วันที่ส่งโครงการ */
  submitted_date?: Maybe<Scalars['timestamptz']>;
  /** ชื่อโครงการ */
  title?: Maybe<Scalars['String']>;
  /** งบประมาณ */
  total_budget?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
  updated_by_user?: Maybe<UsersObjRelInsertInput>;
  workload_summary?: Maybe<ProjectProjectWorkloadSummaryArrRelInsertInput>;
};

/** aggregate max on columns */
export type ProjectProjectMaxFields = {
  __typename?: 'project_project_max_fields';
  /** ข้อความเพิ่มเติมหลังตรวจสอบโครงการ */
  approved_comment?: Maybe<Scalars['String']>;
  /** วันที่ตรวจสอบโครงการ */
  approved_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่พิจารณา: ยกเลิกโครงการ */
  cancelled_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่พิจารณา: แก้ไขเอกสารเพิ่มเติม */
  change_request_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่เสร็จสิ้น */
  completed_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ผลกระทบ */
  effect?: Maybe<Scalars['String']>;
  /** วันที่สิ้นสุด (ระยะเวลาดำเนินการ) */
  end_at?: Maybe<Scalars['date']>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** สาขาอุตสาหกรรม */
  industry?: Maybe<Scalars['String']>;
  /** หลักการและเหตุผลความจำเป็น */
  introduction?: Maybe<Scalars['String']>;
  /** ผลการดำเนินงานในปีที่ผ่านมา */
  last_overall_operation?: Maybe<Scalars['String']>;
  /** ด้านยุทธศาสตร์ */
  national_strategy_lvl1_id?: Maybe<Scalars['String']>;
  /** ประเด็นแผนแม่บท (จ.1) */
  national_strategy_lvl2_id?: Maybe<Scalars['String']>;
  /** เป้าหมายแผนแม่บท (จ.2) */
  national_strategy_lvl3_id?: Maybe<Scalars['String']>;
  /** แผนแม่บทย่อย */
  national_strategy_lvl4_id?: Maybe<Scalars['String']>;
  national_strategy_lvl5_id?: Maybe<Scalars['String']>;
  /** หน่วยงานรับผิดชอบ */
  organization_id?: Maybe<Scalars['uuid']>;
  /** รหัสอ้างอิงโครงการ */
  reference?: Maybe<Scalars['String']>;
  /** วันที่พิจารณา: ไม่อนุมัติโครงการ */
  rejected_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่เริ่ม (ระยะเวลาดำเนินการ) */
  start_at?: Maybe<Scalars['date']>;
  /** สถานะโครงการ */
  step_id?: Maybe<Scalars['String']>;
  /** วันที่ส่งโครงการ */
  submitted_date?: Maybe<Scalars['timestamptz']>;
  /** ชื่อโครงการ */
  title?: Maybe<Scalars['String']>;
  /** งบประมาณ */
  total_budget?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "project.project" */
export type ProjectProjectMaxOrderBy = {
  /** ข้อความเพิ่มเติมหลังตรวจสอบโครงการ */
  approved_comment?: Maybe<OrderBy>;
  /** วันที่ตรวจสอบโครงการ */
  approved_date?: Maybe<OrderBy>;
  /** วันที่พิจารณา: ยกเลิกโครงการ */
  cancelled_date?: Maybe<OrderBy>;
  /** วันที่พิจารณา: แก้ไขเอกสารเพิ่มเติม */
  change_request_date?: Maybe<OrderBy>;
  /** วันที่เสร็จสิ้น */
  completed_date?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** ผลกระทบ */
  effect?: Maybe<OrderBy>;
  /** วันที่สิ้นสุด (ระยะเวลาดำเนินการ) */
  end_at?: Maybe<OrderBy>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** สาขาอุตสาหกรรม */
  industry?: Maybe<OrderBy>;
  /** หลักการและเหตุผลความจำเป็น */
  introduction?: Maybe<OrderBy>;
  /** ผลการดำเนินงานในปีที่ผ่านมา */
  last_overall_operation?: Maybe<OrderBy>;
  /** ด้านยุทธศาสตร์ */
  national_strategy_lvl1_id?: Maybe<OrderBy>;
  /** ประเด็นแผนแม่บท (จ.1) */
  national_strategy_lvl2_id?: Maybe<OrderBy>;
  /** เป้าหมายแผนแม่บท (จ.2) */
  national_strategy_lvl3_id?: Maybe<OrderBy>;
  /** แผนแม่บทย่อย */
  national_strategy_lvl4_id?: Maybe<OrderBy>;
  national_strategy_lvl5_id?: Maybe<OrderBy>;
  /** หน่วยงานรับผิดชอบ */
  organization_id?: Maybe<OrderBy>;
  /** รหัสอ้างอิงโครงการ */
  reference?: Maybe<OrderBy>;
  /** วันที่พิจารณา: ไม่อนุมัติโครงการ */
  rejected_date?: Maybe<OrderBy>;
  /** วันที่เริ่ม (ระยะเวลาดำเนินการ) */
  start_at?: Maybe<OrderBy>;
  /** สถานะโครงการ */
  step_id?: Maybe<OrderBy>;
  /** วันที่ส่งโครงการ */
  submitted_date?: Maybe<OrderBy>;
  /** ชื่อโครงการ */
  title?: Maybe<OrderBy>;
  /** งบประมาณ */
  total_budget?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/**
 * ผู้รับผิดชอบโครงการ
 *
 *
 * columns and relationships of "project.project_member"
 */
export type ProjectProjectMember = {
  __typename?: 'project_project_member';
  /** อีเมล */
  email?: Maybe<Scalars['String']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  organization?: Maybe<Organization>;
  /** หน่วยงานรับผิดชอบ */
  organization_id?: Maybe<Scalars['uuid']>;
  /** ตำแหน่ง */
  position?: Maybe<Scalars['String']>;
  /** An object relationship */
  project: ProjectProject;
  /** โครงการ */
  project_id: Scalars['uuid'];
  /** ลำดับที่ */
  sequence: Scalars['numeric'];
  /** โทร */
  tel?: Maybe<Scalars['String']>;
  /** คำนำหน้าชื่อ */
  title?: Maybe<Scalars['String']>;
};

/** aggregated selection of "project.project_member" */
export type ProjectProjectMemberAggregate = {
  __typename?: 'project_project_member_aggregate';
  aggregate?: Maybe<ProjectProjectMemberAggregateFields>;
  nodes: Array<ProjectProjectMember>;
};

/** aggregate fields of "project.project_member" */
export type ProjectProjectMemberAggregateFields = {
  __typename?: 'project_project_member_aggregate_fields';
  avg?: Maybe<ProjectProjectMemberAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectProjectMemberMaxFields>;
  min?: Maybe<ProjectProjectMemberMinFields>;
  stddev?: Maybe<ProjectProjectMemberStddevFields>;
  stddev_pop?: Maybe<ProjectProjectMemberStddevPopFields>;
  stddev_samp?: Maybe<ProjectProjectMemberStddevSampFields>;
  sum?: Maybe<ProjectProjectMemberSumFields>;
  var_pop?: Maybe<ProjectProjectMemberVarPopFields>;
  var_samp?: Maybe<ProjectProjectMemberVarSampFields>;
  variance?: Maybe<ProjectProjectMemberVarianceFields>;
};


/** aggregate fields of "project.project_member" */
export type ProjectProjectMemberAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectProjectMemberSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project.project_member" */
export type ProjectProjectMemberAggregateOrderBy = {
  avg?: Maybe<ProjectProjectMemberAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProjectProjectMemberMaxOrderBy>;
  min?: Maybe<ProjectProjectMemberMinOrderBy>;
  stddev?: Maybe<ProjectProjectMemberStddevOrderBy>;
  stddev_pop?: Maybe<ProjectProjectMemberStddevPopOrderBy>;
  stddev_samp?: Maybe<ProjectProjectMemberStddevSampOrderBy>;
  sum?: Maybe<ProjectProjectMemberSumOrderBy>;
  var_pop?: Maybe<ProjectProjectMemberVarPopOrderBy>;
  var_samp?: Maybe<ProjectProjectMemberVarSampOrderBy>;
  variance?: Maybe<ProjectProjectMemberVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "project.project_member" */
export type ProjectProjectMemberArrRelInsertInput = {
  data: Array<ProjectProjectMemberInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectProjectMemberOnConflict>;
};

/** aggregate avg on columns */
export type ProjectProjectMemberAvgFields = {
  __typename?: 'project_project_member_avg_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project.project_member" */
export type ProjectProjectMemberAvgOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "project.project_member". All fields are combined with a logical 'AND'. */
export type ProjectProjectMemberBoolExp = {
  _and?: Maybe<Array<ProjectProjectMemberBoolExp>>;
  _not?: Maybe<ProjectProjectMemberBoolExp>;
  _or?: Maybe<Array<ProjectProjectMemberBoolExp>>;
  email?: Maybe<StringComparisonExp>;
  first_name?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  last_name?: Maybe<StringComparisonExp>;
  organization?: Maybe<OrganizationBoolExp>;
  organization_id?: Maybe<UuidComparisonExp>;
  position?: Maybe<StringComparisonExp>;
  project?: Maybe<ProjectProjectBoolExp>;
  project_id?: Maybe<UuidComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
  tel?: Maybe<StringComparisonExp>;
  title?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "project.project_member" */
export enum ProjectProjectMemberConstraint {
  /** unique or primary key constraint */
  ProjectMemberPkey = 'project_member_pkey'
}

/** input type for incrementing numeric columns in table "project.project_member" */
export type ProjectProjectMemberIncInput = {
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "project.project_member" */
export type ProjectProjectMemberInsertInput = {
  /** อีเมล */
  email?: Maybe<Scalars['String']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationObjRelInsertInput>;
  /** หน่วยงานรับผิดชอบ */
  organization_id?: Maybe<Scalars['uuid']>;
  /** ตำแหน่ง */
  position?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectProjectObjRelInsertInput>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** โทร */
  tel?: Maybe<Scalars['String']>;
  /** คำนำหน้าชื่อ */
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ProjectProjectMemberMaxFields = {
  __typename?: 'project_project_member_max_fields';
  /** อีเมล */
  email?: Maybe<Scalars['String']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** หน่วยงานรับผิดชอบ */
  organization_id?: Maybe<Scalars['uuid']>;
  /** ตำแหน่ง */
  position?: Maybe<Scalars['String']>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** โทร */
  tel?: Maybe<Scalars['String']>;
  /** คำนำหน้าชื่อ */
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "project.project_member" */
export type ProjectProjectMemberMaxOrderBy = {
  /** อีเมล */
  email?: Maybe<OrderBy>;
  /** ชื่อ */
  first_name?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** นามสกุล */
  last_name?: Maybe<OrderBy>;
  /** หน่วยงานรับผิดชอบ */
  organization_id?: Maybe<OrderBy>;
  /** ตำแหน่ง */
  position?: Maybe<OrderBy>;
  /** โครงการ */
  project_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** โทร */
  tel?: Maybe<OrderBy>;
  /** คำนำหน้าชื่อ */
  title?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectProjectMemberMinFields = {
  __typename?: 'project_project_member_min_fields';
  /** อีเมล */
  email?: Maybe<Scalars['String']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** หน่วยงานรับผิดชอบ */
  organization_id?: Maybe<Scalars['uuid']>;
  /** ตำแหน่ง */
  position?: Maybe<Scalars['String']>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** โทร */
  tel?: Maybe<Scalars['String']>;
  /** คำนำหน้าชื่อ */
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "project.project_member" */
export type ProjectProjectMemberMinOrderBy = {
  /** อีเมล */
  email?: Maybe<OrderBy>;
  /** ชื่อ */
  first_name?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** นามสกุล */
  last_name?: Maybe<OrderBy>;
  /** หน่วยงานรับผิดชอบ */
  organization_id?: Maybe<OrderBy>;
  /** ตำแหน่ง */
  position?: Maybe<OrderBy>;
  /** โครงการ */
  project_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** โทร */
  tel?: Maybe<OrderBy>;
  /** คำนำหน้าชื่อ */
  title?: Maybe<OrderBy>;
};

/** response of any mutation on the table "project.project_member" */
export type ProjectProjectMemberMutationResponse = {
  __typename?: 'project_project_member_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectProjectMember>;
};

/** on conflict condition type for table "project.project_member" */
export type ProjectProjectMemberOnConflict = {
  constraint: ProjectProjectMemberConstraint;
  update_columns?: Array<ProjectProjectMemberUpdateColumn>;
  where?: Maybe<ProjectProjectMemberBoolExp>;
};

/** Ordering options when selecting data from "project.project_member". */
export type ProjectProjectMemberOrderBy = {
  email?: Maybe<OrderBy>;
  first_name?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  last_name?: Maybe<OrderBy>;
  organization?: Maybe<OrganizationOrderBy>;
  organization_id?: Maybe<OrderBy>;
  position?: Maybe<OrderBy>;
  project?: Maybe<ProjectProjectOrderBy>;
  project_id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
  tel?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
};

/** primary key columns input for table: project_project_member */
export type ProjectProjectMemberPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "project.project_member" */
export enum ProjectProjectMemberSelectColumn {
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Position = 'position',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  Tel = 'tel',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "project.project_member" */
export type ProjectProjectMemberSetInput = {
  /** อีเมล */
  email?: Maybe<Scalars['String']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** หน่วยงานรับผิดชอบ */
  organization_id?: Maybe<Scalars['uuid']>;
  /** ตำแหน่ง */
  position?: Maybe<Scalars['String']>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** โทร */
  tel?: Maybe<Scalars['String']>;
  /** คำนำหน้าชื่อ */
  title?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ProjectProjectMemberStddevFields = {
  __typename?: 'project_project_member_stddev_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project.project_member" */
export type ProjectProjectMemberStddevOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ProjectProjectMemberStddevPopFields = {
  __typename?: 'project_project_member_stddev_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project.project_member" */
export type ProjectProjectMemberStddevPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ProjectProjectMemberStddevSampFields = {
  __typename?: 'project_project_member_stddev_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project.project_member" */
export type ProjectProjectMemberStddevSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ProjectProjectMemberSumFields = {
  __typename?: 'project_project_member_sum_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "project.project_member" */
export type ProjectProjectMemberSumOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "project.project_member" */
export enum ProjectProjectMemberUpdateColumn {
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Position = 'position',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  Tel = 'tel',
  /** column name */
  Title = 'title'
}

/** aggregate var_pop on columns */
export type ProjectProjectMemberVarPopFields = {
  __typename?: 'project_project_member_var_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project.project_member" */
export type ProjectProjectMemberVarPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ProjectProjectMemberVarSampFields = {
  __typename?: 'project_project_member_var_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project.project_member" */
export type ProjectProjectMemberVarSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProjectProjectMemberVarianceFields = {
  __typename?: 'project_project_member_variance_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project.project_member" */
export type ProjectProjectMemberVarianceOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectProjectMinFields = {
  __typename?: 'project_project_min_fields';
  /** ข้อความเพิ่มเติมหลังตรวจสอบโครงการ */
  approved_comment?: Maybe<Scalars['String']>;
  /** วันที่ตรวจสอบโครงการ */
  approved_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่พิจารณา: ยกเลิกโครงการ */
  cancelled_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่พิจารณา: แก้ไขเอกสารเพิ่มเติม */
  change_request_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่เสร็จสิ้น */
  completed_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ผลกระทบ */
  effect?: Maybe<Scalars['String']>;
  /** วันที่สิ้นสุด (ระยะเวลาดำเนินการ) */
  end_at?: Maybe<Scalars['date']>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** สาขาอุตสาหกรรม */
  industry?: Maybe<Scalars['String']>;
  /** หลักการและเหตุผลความจำเป็น */
  introduction?: Maybe<Scalars['String']>;
  /** ผลการดำเนินงานในปีที่ผ่านมา */
  last_overall_operation?: Maybe<Scalars['String']>;
  /** ด้านยุทธศาสตร์ */
  national_strategy_lvl1_id?: Maybe<Scalars['String']>;
  /** ประเด็นแผนแม่บท (จ.1) */
  national_strategy_lvl2_id?: Maybe<Scalars['String']>;
  /** เป้าหมายแผนแม่บท (จ.2) */
  national_strategy_lvl3_id?: Maybe<Scalars['String']>;
  /** แผนแม่บทย่อย */
  national_strategy_lvl4_id?: Maybe<Scalars['String']>;
  national_strategy_lvl5_id?: Maybe<Scalars['String']>;
  /** หน่วยงานรับผิดชอบ */
  organization_id?: Maybe<Scalars['uuid']>;
  /** รหัสอ้างอิงโครงการ */
  reference?: Maybe<Scalars['String']>;
  /** วันที่พิจารณา: ไม่อนุมัติโครงการ */
  rejected_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่เริ่ม (ระยะเวลาดำเนินการ) */
  start_at?: Maybe<Scalars['date']>;
  /** สถานะโครงการ */
  step_id?: Maybe<Scalars['String']>;
  /** วันที่ส่งโครงการ */
  submitted_date?: Maybe<Scalars['timestamptz']>;
  /** ชื่อโครงการ */
  title?: Maybe<Scalars['String']>;
  /** งบประมาณ */
  total_budget?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "project.project" */
export type ProjectProjectMinOrderBy = {
  /** ข้อความเพิ่มเติมหลังตรวจสอบโครงการ */
  approved_comment?: Maybe<OrderBy>;
  /** วันที่ตรวจสอบโครงการ */
  approved_date?: Maybe<OrderBy>;
  /** วันที่พิจารณา: ยกเลิกโครงการ */
  cancelled_date?: Maybe<OrderBy>;
  /** วันที่พิจารณา: แก้ไขเอกสารเพิ่มเติม */
  change_request_date?: Maybe<OrderBy>;
  /** วันที่เสร็จสิ้น */
  completed_date?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<OrderBy>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<OrderBy>;
  /** ผลกระทบ */
  effect?: Maybe<OrderBy>;
  /** วันที่สิ้นสุด (ระยะเวลาดำเนินการ) */
  end_at?: Maybe<OrderBy>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** สาขาอุตสาหกรรม */
  industry?: Maybe<OrderBy>;
  /** หลักการและเหตุผลความจำเป็น */
  introduction?: Maybe<OrderBy>;
  /** ผลการดำเนินงานในปีที่ผ่านมา */
  last_overall_operation?: Maybe<OrderBy>;
  /** ด้านยุทธศาสตร์ */
  national_strategy_lvl1_id?: Maybe<OrderBy>;
  /** ประเด็นแผนแม่บท (จ.1) */
  national_strategy_lvl2_id?: Maybe<OrderBy>;
  /** เป้าหมายแผนแม่บท (จ.2) */
  national_strategy_lvl3_id?: Maybe<OrderBy>;
  /** แผนแม่บทย่อย */
  national_strategy_lvl4_id?: Maybe<OrderBy>;
  national_strategy_lvl5_id?: Maybe<OrderBy>;
  /** หน่วยงานรับผิดชอบ */
  organization_id?: Maybe<OrderBy>;
  /** รหัสอ้างอิงโครงการ */
  reference?: Maybe<OrderBy>;
  /** วันที่พิจารณา: ไม่อนุมัติโครงการ */
  rejected_date?: Maybe<OrderBy>;
  /** วันที่เริ่ม (ระยะเวลาดำเนินการ) */
  start_at?: Maybe<OrderBy>;
  /** สถานะโครงการ */
  step_id?: Maybe<OrderBy>;
  /** วันที่ส่งโครงการ */
  submitted_date?: Maybe<OrderBy>;
  /** ชื่อโครงการ */
  title?: Maybe<OrderBy>;
  /** งบประมาณ */
  total_budget?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<OrderBy>;
};

/** response of any mutation on the table "project.project" */
export type ProjectProjectMutationResponse = {
  __typename?: 'project_project_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectProject>;
};

/** input type for inserting object relation for remote table "project.project" */
export type ProjectProjectObjRelInsertInput = {
  data: ProjectProjectInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectProjectOnConflict>;
};

/**
 * วัตถุประสงค์ของโครงการ
 *
 *
 * columns and relationships of "project.project_objective"
 */
export type ProjectProjectObjective = {
  __typename?: 'project_project_objective';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** รายการวัตถุประสงค์ของโครงการ */
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  project: ProjectProject;
  /** An array relationship */
  project_achievements: Array<ProjectProjectAchievement>;
  /** An aggregate relationship */
  project_achievements_aggregate: ProjectProjectAchievementAggregate;
  /** โครงการ */
  project_id: Scalars['uuid'];
  /** An array relationship */
  project_indicators: Array<ProjectProjectIndicator>;
  /** An aggregate relationship */
  project_indicators_aggregate: ProjectProjectIndicatorAggregate;
  /** ลำดับที่ */
  sequence: Scalars['numeric'];
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/**
 * วัตถุประสงค์ของโครงการ
 *
 *
 * columns and relationships of "project.project_objective"
 */
export type ProjectProjectObjectiveProjectAchievementsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectAchievementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectAchievementOrderBy>>;
  where?: Maybe<ProjectProjectAchievementBoolExp>;
};


/**
 * วัตถุประสงค์ของโครงการ
 *
 *
 * columns and relationships of "project.project_objective"
 */
export type ProjectProjectObjectiveProjectAchievementsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectAchievementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectAchievementOrderBy>>;
  where?: Maybe<ProjectProjectAchievementBoolExp>;
};


/**
 * วัตถุประสงค์ของโครงการ
 *
 *
 * columns and relationships of "project.project_objective"
 */
export type ProjectProjectObjectiveProjectIndicatorsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectIndicatorSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectIndicatorOrderBy>>;
  where?: Maybe<ProjectProjectIndicatorBoolExp>;
};


/**
 * วัตถุประสงค์ของโครงการ
 *
 *
 * columns and relationships of "project.project_objective"
 */
export type ProjectProjectObjectiveProjectIndicatorsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectIndicatorSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectIndicatorOrderBy>>;
  where?: Maybe<ProjectProjectIndicatorBoolExp>;
};

/** aggregated selection of "project.project_objective" */
export type ProjectProjectObjectiveAggregate = {
  __typename?: 'project_project_objective_aggregate';
  aggregate?: Maybe<ProjectProjectObjectiveAggregateFields>;
  nodes: Array<ProjectProjectObjective>;
};

/** aggregate fields of "project.project_objective" */
export type ProjectProjectObjectiveAggregateFields = {
  __typename?: 'project_project_objective_aggregate_fields';
  avg?: Maybe<ProjectProjectObjectiveAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectProjectObjectiveMaxFields>;
  min?: Maybe<ProjectProjectObjectiveMinFields>;
  stddev?: Maybe<ProjectProjectObjectiveStddevFields>;
  stddev_pop?: Maybe<ProjectProjectObjectiveStddevPopFields>;
  stddev_samp?: Maybe<ProjectProjectObjectiveStddevSampFields>;
  sum?: Maybe<ProjectProjectObjectiveSumFields>;
  var_pop?: Maybe<ProjectProjectObjectiveVarPopFields>;
  var_samp?: Maybe<ProjectProjectObjectiveVarSampFields>;
  variance?: Maybe<ProjectProjectObjectiveVarianceFields>;
};


/** aggregate fields of "project.project_objective" */
export type ProjectProjectObjectiveAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectProjectObjectiveSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project.project_objective" */
export type ProjectProjectObjectiveAggregateOrderBy = {
  avg?: Maybe<ProjectProjectObjectiveAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProjectProjectObjectiveMaxOrderBy>;
  min?: Maybe<ProjectProjectObjectiveMinOrderBy>;
  stddev?: Maybe<ProjectProjectObjectiveStddevOrderBy>;
  stddev_pop?: Maybe<ProjectProjectObjectiveStddevPopOrderBy>;
  stddev_samp?: Maybe<ProjectProjectObjectiveStddevSampOrderBy>;
  sum?: Maybe<ProjectProjectObjectiveSumOrderBy>;
  var_pop?: Maybe<ProjectProjectObjectiveVarPopOrderBy>;
  var_samp?: Maybe<ProjectProjectObjectiveVarSampOrderBy>;
  variance?: Maybe<ProjectProjectObjectiveVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "project.project_objective" */
export type ProjectProjectObjectiveArrRelInsertInput = {
  data: Array<ProjectProjectObjectiveInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectProjectObjectiveOnConflict>;
};

/** aggregate avg on columns */
export type ProjectProjectObjectiveAvgFields = {
  __typename?: 'project_project_objective_avg_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project.project_objective" */
export type ProjectProjectObjectiveAvgOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "project.project_objective". All fields are combined with a logical 'AND'. */
export type ProjectProjectObjectiveBoolExp = {
  _and?: Maybe<Array<ProjectProjectObjectiveBoolExp>>;
  _not?: Maybe<ProjectProjectObjectiveBoolExp>;
  _or?: Maybe<Array<ProjectProjectObjectiveBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  project?: Maybe<ProjectProjectBoolExp>;
  project_achievements?: Maybe<ProjectProjectAchievementBoolExp>;
  project_id?: Maybe<UuidComparisonExp>;
  project_indicators?: Maybe<ProjectProjectIndicatorBoolExp>;
  sequence?: Maybe<NumericComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "project.project_objective" */
export enum ProjectProjectObjectiveConstraint {
  /** unique or primary key constraint */
  ProjectObjectivePkey = 'project_objective_pkey'
}

/** input type for incrementing numeric columns in table "project.project_objective" */
export type ProjectProjectObjectiveIncInput = {
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "project.project_objective" */
export type ProjectProjectObjectiveInsertInput = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รายการวัตถุประสงค์ของโครงการ */
  name?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectProjectObjRelInsertInput>;
  project_achievements?: Maybe<ProjectProjectAchievementArrRelInsertInput>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  project_indicators?: Maybe<ProjectProjectIndicatorArrRelInsertInput>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ProjectProjectObjectiveMaxFields = {
  __typename?: 'project_project_objective_max_fields';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รายการวัตถุประสงค์ของโครงการ */
  name?: Maybe<Scalars['String']>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "project.project_objective" */
export type ProjectProjectObjectiveMaxOrderBy = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** รายการวัตถุประสงค์ของโครงการ */
  name?: Maybe<OrderBy>;
  /** โครงการ */
  project_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectProjectObjectiveMinFields = {
  __typename?: 'project_project_objective_min_fields';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รายการวัตถุประสงค์ของโครงการ */
  name?: Maybe<Scalars['String']>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "project.project_objective" */
export type ProjectProjectObjectiveMinOrderBy = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** รายการวัตถุประสงค์ของโครงการ */
  name?: Maybe<OrderBy>;
  /** โครงการ */
  project_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "project.project_objective" */
export type ProjectProjectObjectiveMutationResponse = {
  __typename?: 'project_project_objective_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectProjectObjective>;
};

/** input type for inserting object relation for remote table "project.project_objective" */
export type ProjectProjectObjectiveObjRelInsertInput = {
  data: ProjectProjectObjectiveInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectProjectObjectiveOnConflict>;
};

/** on conflict condition type for table "project.project_objective" */
export type ProjectProjectObjectiveOnConflict = {
  constraint: ProjectProjectObjectiveConstraint;
  update_columns?: Array<ProjectProjectObjectiveUpdateColumn>;
  where?: Maybe<ProjectProjectObjectiveBoolExp>;
};

/** Ordering options when selecting data from "project.project_objective". */
export type ProjectProjectObjectiveOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  project?: Maybe<ProjectProjectOrderBy>;
  project_achievements_aggregate?: Maybe<ProjectProjectAchievementAggregateOrderBy>;
  project_id?: Maybe<OrderBy>;
  project_indicators_aggregate?: Maybe<ProjectProjectIndicatorAggregateOrderBy>;
  sequence?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: project_project_objective */
export type ProjectProjectObjectivePkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "project.project_objective" */
export enum ProjectProjectObjectiveSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "project.project_objective" */
export type ProjectProjectObjectiveSetInput = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** รายการวัตถุประสงค์ของโครงการ */
  name?: Maybe<Scalars['String']>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ProjectProjectObjectiveStddevFields = {
  __typename?: 'project_project_objective_stddev_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project.project_objective" */
export type ProjectProjectObjectiveStddevOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ProjectProjectObjectiveStddevPopFields = {
  __typename?: 'project_project_objective_stddev_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project.project_objective" */
export type ProjectProjectObjectiveStddevPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ProjectProjectObjectiveStddevSampFields = {
  __typename?: 'project_project_objective_stddev_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project.project_objective" */
export type ProjectProjectObjectiveStddevSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ProjectProjectObjectiveSumFields = {
  __typename?: 'project_project_objective_sum_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "project.project_objective" */
export type ProjectProjectObjectiveSumOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "project.project_objective" */
export enum ProjectProjectObjectiveUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type ProjectProjectObjectiveVarPopFields = {
  __typename?: 'project_project_objective_var_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project.project_objective" */
export type ProjectProjectObjectiveVarPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ProjectProjectObjectiveVarSampFields = {
  __typename?: 'project_project_objective_var_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project.project_objective" */
export type ProjectProjectObjectiveVarSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProjectProjectObjectiveVarianceFields = {
  __typename?: 'project_project_objective_variance_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project.project_objective" */
export type ProjectProjectObjectiveVarianceOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** on conflict condition type for table "project.project" */
export type ProjectProjectOnConflict = {
  constraint: ProjectProjectConstraint;
  update_columns?: Array<ProjectProjectUpdateColumn>;
  where?: Maybe<ProjectProjectBoolExp>;
};

/**
 * กิจกรรมดำเนินงานโครงการ
 *
 *
 * columns and relationships of "project.project_operating_activity"
 */
export type ProjectProjectOperatingActivity = {
  __typename?: 'project_project_operating_activity';
  /** An object relationship */
  budget_group: ProjectBudgetGroup;
  /** ประเภทรายละเอียดค่าใช้จ่าย กิจกรรมโครงการ */
  budget_group_id: ProjectBudgetGroupEnum;
  /** รายละเอียดเพิ่มเติมกิจกรรม */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** ชื่อกิจกรรม */
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  operating_activity_progresses: Array<ProjectOperatingActivityProgress>;
  /** An aggregate relationship */
  operating_activity_progresses_aggregate: ProjectOperatingActivityProgressAggregate;
  /** An object relationship */
  operating_activity_type: ProjectOperatingActivityType;
  /** ประเภทกิจกรรมดำเนินงาน */
  operating_activity_type_id: ProjectOperatingActivityTypeEnum;
  /** An object relationship */
  project: ProjectProject;
  /** An array relationship */
  project_budget_items: Array<ProjectProjectBudgetItem>;
  /** An aggregate relationship */
  project_budget_items_aggregate: ProjectProjectBudgetItemAggregate;
  /** โครงการ */
  project_id: Scalars['uuid'];
  /** ลำดับที่ */
  sequence: Scalars['numeric'];
  target_amount?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  target_unit?: Maybe<ProjectUnit>;
  target_unit_id?: Maybe<ProjectUnitEnum>;
};


/**
 * กิจกรรมดำเนินงานโครงการ
 *
 *
 * columns and relationships of "project.project_operating_activity"
 */
export type ProjectProjectOperatingActivityOperatingActivityProgressesArgs = {
  distinct_on?: Maybe<Array<ProjectOperatingActivityProgressSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectOperatingActivityProgressOrderBy>>;
  where?: Maybe<ProjectOperatingActivityProgressBoolExp>;
};


/**
 * กิจกรรมดำเนินงานโครงการ
 *
 *
 * columns and relationships of "project.project_operating_activity"
 */
export type ProjectProjectOperatingActivityOperatingActivityProgressesAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectOperatingActivityProgressSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectOperatingActivityProgressOrderBy>>;
  where?: Maybe<ProjectOperatingActivityProgressBoolExp>;
};


/**
 * กิจกรรมดำเนินงานโครงการ
 *
 *
 * columns and relationships of "project.project_operating_activity"
 */
export type ProjectProjectOperatingActivityProjectBudgetItemsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetItemOrderBy>>;
  where?: Maybe<ProjectProjectBudgetItemBoolExp>;
};


/**
 * กิจกรรมดำเนินงานโครงการ
 *
 *
 * columns and relationships of "project.project_operating_activity"
 */
export type ProjectProjectOperatingActivityProjectBudgetItemsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetItemOrderBy>>;
  where?: Maybe<ProjectProjectBudgetItemBoolExp>;
};

/** aggregated selection of "project.project_operating_activity" */
export type ProjectProjectOperatingActivityAggregate = {
  __typename?: 'project_project_operating_activity_aggregate';
  aggregate?: Maybe<ProjectProjectOperatingActivityAggregateFields>;
  nodes: Array<ProjectProjectOperatingActivity>;
};

/** aggregate fields of "project.project_operating_activity" */
export type ProjectProjectOperatingActivityAggregateFields = {
  __typename?: 'project_project_operating_activity_aggregate_fields';
  avg?: Maybe<ProjectProjectOperatingActivityAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectProjectOperatingActivityMaxFields>;
  min?: Maybe<ProjectProjectOperatingActivityMinFields>;
  stddev?: Maybe<ProjectProjectOperatingActivityStddevFields>;
  stddev_pop?: Maybe<ProjectProjectOperatingActivityStddevPopFields>;
  stddev_samp?: Maybe<ProjectProjectOperatingActivityStddevSampFields>;
  sum?: Maybe<ProjectProjectOperatingActivitySumFields>;
  var_pop?: Maybe<ProjectProjectOperatingActivityVarPopFields>;
  var_samp?: Maybe<ProjectProjectOperatingActivityVarSampFields>;
  variance?: Maybe<ProjectProjectOperatingActivityVarianceFields>;
};


/** aggregate fields of "project.project_operating_activity" */
export type ProjectProjectOperatingActivityAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectProjectOperatingActivitySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project.project_operating_activity" */
export type ProjectProjectOperatingActivityAggregateOrderBy = {
  avg?: Maybe<ProjectProjectOperatingActivityAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProjectProjectOperatingActivityMaxOrderBy>;
  min?: Maybe<ProjectProjectOperatingActivityMinOrderBy>;
  stddev?: Maybe<ProjectProjectOperatingActivityStddevOrderBy>;
  stddev_pop?: Maybe<ProjectProjectOperatingActivityStddevPopOrderBy>;
  stddev_samp?: Maybe<ProjectProjectOperatingActivityStddevSampOrderBy>;
  sum?: Maybe<ProjectProjectOperatingActivitySumOrderBy>;
  var_pop?: Maybe<ProjectProjectOperatingActivityVarPopOrderBy>;
  var_samp?: Maybe<ProjectProjectOperatingActivityVarSampOrderBy>;
  variance?: Maybe<ProjectProjectOperatingActivityVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "project.project_operating_activity" */
export type ProjectProjectOperatingActivityArrRelInsertInput = {
  data: Array<ProjectProjectOperatingActivityInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectProjectOperatingActivityOnConflict>;
};

/** aggregate avg on columns */
export type ProjectProjectOperatingActivityAvgFields = {
  __typename?: 'project_project_operating_activity_avg_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
  target_amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project.project_operating_activity" */
export type ProjectProjectOperatingActivityAvgOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  target_amount?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "project.project_operating_activity". All fields are combined with a logical 'AND'. */
export type ProjectProjectOperatingActivityBoolExp = {
  _and?: Maybe<Array<ProjectProjectOperatingActivityBoolExp>>;
  _not?: Maybe<ProjectProjectOperatingActivityBoolExp>;
  _or?: Maybe<Array<ProjectProjectOperatingActivityBoolExp>>;
  budget_group?: Maybe<ProjectBudgetGroupBoolExp>;
  budget_group_id?: Maybe<ProjectBudgetGroupEnumComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  operating_activity_progresses?: Maybe<ProjectOperatingActivityProgressBoolExp>;
  operating_activity_type?: Maybe<ProjectOperatingActivityTypeBoolExp>;
  operating_activity_type_id?: Maybe<ProjectOperatingActivityTypeEnumComparisonExp>;
  project?: Maybe<ProjectProjectBoolExp>;
  project_budget_items?: Maybe<ProjectProjectBudgetItemBoolExp>;
  project_id?: Maybe<UuidComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
  target_amount?: Maybe<NumericComparisonExp>;
  target_unit?: Maybe<ProjectUnitBoolExp>;
  target_unit_id?: Maybe<ProjectUnitEnumComparisonExp>;
};

/** unique or primary key constraints on table "project.project_operating_activity" */
export enum ProjectProjectOperatingActivityConstraint {
  /** unique or primary key constraint */
  ProjectOperatingActivityPkey = 'project_operating_activity_pkey'
}

/** input type for incrementing numeric columns in table "project.project_operating_activity" */
export type ProjectProjectOperatingActivityIncInput = {
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  target_amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "project.project_operating_activity" */
export type ProjectProjectOperatingActivityInsertInput = {
  budget_group?: Maybe<ProjectBudgetGroupObjRelInsertInput>;
  /** ประเภทรายละเอียดค่าใช้จ่าย กิจกรรมโครงการ */
  budget_group_id?: Maybe<ProjectBudgetGroupEnum>;
  /** รายละเอียดเพิ่มเติมกิจกรรม */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อกิจกรรม */
  name?: Maybe<Scalars['String']>;
  operating_activity_progresses?: Maybe<ProjectOperatingActivityProgressArrRelInsertInput>;
  operating_activity_type?: Maybe<ProjectOperatingActivityTypeObjRelInsertInput>;
  /** ประเภทกิจกรรมดำเนินงาน */
  operating_activity_type_id?: Maybe<ProjectOperatingActivityTypeEnum>;
  project?: Maybe<ProjectProjectObjRelInsertInput>;
  project_budget_items?: Maybe<ProjectProjectBudgetItemArrRelInsertInput>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  target_amount?: Maybe<Scalars['numeric']>;
  target_unit?: Maybe<ProjectUnitObjRelInsertInput>;
  target_unit_id?: Maybe<ProjectUnitEnum>;
};

/** aggregate max on columns */
export type ProjectProjectOperatingActivityMaxFields = {
  __typename?: 'project_project_operating_activity_max_fields';
  /** รายละเอียดเพิ่มเติมกิจกรรม */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อกิจกรรม */
  name?: Maybe<Scalars['String']>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  target_amount?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "project.project_operating_activity" */
export type ProjectProjectOperatingActivityMaxOrderBy = {
  /** รายละเอียดเพิ่มเติมกิจกรรม */
  description?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ชื่อกิจกรรม */
  name?: Maybe<OrderBy>;
  /** โครงการ */
  project_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  target_amount?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectProjectOperatingActivityMinFields = {
  __typename?: 'project_project_operating_activity_min_fields';
  /** รายละเอียดเพิ่มเติมกิจกรรม */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อกิจกรรม */
  name?: Maybe<Scalars['String']>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  target_amount?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "project.project_operating_activity" */
export type ProjectProjectOperatingActivityMinOrderBy = {
  /** รายละเอียดเพิ่มเติมกิจกรรม */
  description?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ชื่อกิจกรรม */
  name?: Maybe<OrderBy>;
  /** โครงการ */
  project_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  target_amount?: Maybe<OrderBy>;
};

/** response of any mutation on the table "project.project_operating_activity" */
export type ProjectProjectOperatingActivityMutationResponse = {
  __typename?: 'project_project_operating_activity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectProjectOperatingActivity>;
};

/** input type for inserting object relation for remote table "project.project_operating_activity" */
export type ProjectProjectOperatingActivityObjRelInsertInput = {
  data: ProjectProjectOperatingActivityInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectProjectOperatingActivityOnConflict>;
};

/** on conflict condition type for table "project.project_operating_activity" */
export type ProjectProjectOperatingActivityOnConflict = {
  constraint: ProjectProjectOperatingActivityConstraint;
  update_columns?: Array<ProjectProjectOperatingActivityUpdateColumn>;
  where?: Maybe<ProjectProjectOperatingActivityBoolExp>;
};

/** Ordering options when selecting data from "project.project_operating_activity". */
export type ProjectProjectOperatingActivityOrderBy = {
  budget_group?: Maybe<ProjectBudgetGroupOrderBy>;
  budget_group_id?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  operating_activity_progresses_aggregate?: Maybe<ProjectOperatingActivityProgressAggregateOrderBy>;
  operating_activity_type?: Maybe<ProjectOperatingActivityTypeOrderBy>;
  operating_activity_type_id?: Maybe<OrderBy>;
  project?: Maybe<ProjectProjectOrderBy>;
  project_budget_items_aggregate?: Maybe<ProjectProjectBudgetItemAggregateOrderBy>;
  project_id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
  target_amount?: Maybe<OrderBy>;
  target_unit?: Maybe<ProjectUnitOrderBy>;
  target_unit_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: project_project_operating_activity */
export type ProjectProjectOperatingActivityPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "project.project_operating_activity" */
export enum ProjectProjectOperatingActivitySelectColumn {
  /** column name */
  BudgetGroupId = 'budget_group_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OperatingActivityTypeId = 'operating_activity_type_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  TargetAmount = 'target_amount',
  /** column name */
  TargetUnitId = 'target_unit_id'
}

/** input type for updating data in table "project.project_operating_activity" */
export type ProjectProjectOperatingActivitySetInput = {
  /** ประเภทรายละเอียดค่าใช้จ่าย กิจกรรมโครงการ */
  budget_group_id?: Maybe<ProjectBudgetGroupEnum>;
  /** รายละเอียดเพิ่มเติมกิจกรรม */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อกิจกรรม */
  name?: Maybe<Scalars['String']>;
  /** ประเภทกิจกรรมดำเนินงาน */
  operating_activity_type_id?: Maybe<ProjectOperatingActivityTypeEnum>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  target_amount?: Maybe<Scalars['numeric']>;
  target_unit_id?: Maybe<ProjectUnitEnum>;
};

/** aggregate stddev on columns */
export type ProjectProjectOperatingActivityStddevFields = {
  __typename?: 'project_project_operating_activity_stddev_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
  target_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project.project_operating_activity" */
export type ProjectProjectOperatingActivityStddevOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  target_amount?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ProjectProjectOperatingActivityStddevPopFields = {
  __typename?: 'project_project_operating_activity_stddev_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
  target_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project.project_operating_activity" */
export type ProjectProjectOperatingActivityStddevPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  target_amount?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ProjectProjectOperatingActivityStddevSampFields = {
  __typename?: 'project_project_operating_activity_stddev_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
  target_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project.project_operating_activity" */
export type ProjectProjectOperatingActivityStddevSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  target_amount?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ProjectProjectOperatingActivitySumFields = {
  __typename?: 'project_project_operating_activity_sum_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  target_amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "project.project_operating_activity" */
export type ProjectProjectOperatingActivitySumOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  target_amount?: Maybe<OrderBy>;
};

/** update columns of table "project.project_operating_activity" */
export enum ProjectProjectOperatingActivityUpdateColumn {
  /** column name */
  BudgetGroupId = 'budget_group_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OperatingActivityTypeId = 'operating_activity_type_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  TargetAmount = 'target_amount',
  /** column name */
  TargetUnitId = 'target_unit_id'
}

/** aggregate var_pop on columns */
export type ProjectProjectOperatingActivityVarPopFields = {
  __typename?: 'project_project_operating_activity_var_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
  target_amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project.project_operating_activity" */
export type ProjectProjectOperatingActivityVarPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  target_amount?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ProjectProjectOperatingActivityVarSampFields = {
  __typename?: 'project_project_operating_activity_var_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
  target_amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project.project_operating_activity" */
export type ProjectProjectOperatingActivityVarSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  target_amount?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProjectProjectOperatingActivityVarianceFields = {
  __typename?: 'project_project_operating_activity_variance_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
  target_amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project.project_operating_activity" */
export type ProjectProjectOperatingActivityVarianceOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  target_amount?: Maybe<OrderBy>;
};

/**
 * พื้นที่ดำเนินการ
 *
 *
 * columns and relationships of "project.project_operating_area"
 */
export type ProjectProjectOperatingArea = {
  __typename?: 'project_project_operating_area';
  /** An object relationship */
  project: ProjectProject;
  /** โครงการ */
  project_id: Scalars['uuid'];
  /** An object relationship */
  province: Province;
  /** จังหวัด */
  province_id: Scalars['uuid'];
  /** ลำดับที่ */
  sequence: Scalars['numeric'];
};

/** aggregated selection of "project.project_operating_area" */
export type ProjectProjectOperatingAreaAggregate = {
  __typename?: 'project_project_operating_area_aggregate';
  aggregate?: Maybe<ProjectProjectOperatingAreaAggregateFields>;
  nodes: Array<ProjectProjectOperatingArea>;
};

/** aggregate fields of "project.project_operating_area" */
export type ProjectProjectOperatingAreaAggregateFields = {
  __typename?: 'project_project_operating_area_aggregate_fields';
  avg?: Maybe<ProjectProjectOperatingAreaAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectProjectOperatingAreaMaxFields>;
  min?: Maybe<ProjectProjectOperatingAreaMinFields>;
  stddev?: Maybe<ProjectProjectOperatingAreaStddevFields>;
  stddev_pop?: Maybe<ProjectProjectOperatingAreaStddevPopFields>;
  stddev_samp?: Maybe<ProjectProjectOperatingAreaStddevSampFields>;
  sum?: Maybe<ProjectProjectOperatingAreaSumFields>;
  var_pop?: Maybe<ProjectProjectOperatingAreaVarPopFields>;
  var_samp?: Maybe<ProjectProjectOperatingAreaVarSampFields>;
  variance?: Maybe<ProjectProjectOperatingAreaVarianceFields>;
};


/** aggregate fields of "project.project_operating_area" */
export type ProjectProjectOperatingAreaAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectProjectOperatingAreaSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project.project_operating_area" */
export type ProjectProjectOperatingAreaAggregateOrderBy = {
  avg?: Maybe<ProjectProjectOperatingAreaAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProjectProjectOperatingAreaMaxOrderBy>;
  min?: Maybe<ProjectProjectOperatingAreaMinOrderBy>;
  stddev?: Maybe<ProjectProjectOperatingAreaStddevOrderBy>;
  stddev_pop?: Maybe<ProjectProjectOperatingAreaStddevPopOrderBy>;
  stddev_samp?: Maybe<ProjectProjectOperatingAreaStddevSampOrderBy>;
  sum?: Maybe<ProjectProjectOperatingAreaSumOrderBy>;
  var_pop?: Maybe<ProjectProjectOperatingAreaVarPopOrderBy>;
  var_samp?: Maybe<ProjectProjectOperatingAreaVarSampOrderBy>;
  variance?: Maybe<ProjectProjectOperatingAreaVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "project.project_operating_area" */
export type ProjectProjectOperatingAreaArrRelInsertInput = {
  data: Array<ProjectProjectOperatingAreaInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectProjectOperatingAreaOnConflict>;
};

/** aggregate avg on columns */
export type ProjectProjectOperatingAreaAvgFields = {
  __typename?: 'project_project_operating_area_avg_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project.project_operating_area" */
export type ProjectProjectOperatingAreaAvgOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "project.project_operating_area". All fields are combined with a logical 'AND'. */
export type ProjectProjectOperatingAreaBoolExp = {
  _and?: Maybe<Array<ProjectProjectOperatingAreaBoolExp>>;
  _not?: Maybe<ProjectProjectOperatingAreaBoolExp>;
  _or?: Maybe<Array<ProjectProjectOperatingAreaBoolExp>>;
  project?: Maybe<ProjectProjectBoolExp>;
  project_id?: Maybe<UuidComparisonExp>;
  province?: Maybe<ProvinceBoolExp>;
  province_id?: Maybe<UuidComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "project.project_operating_area" */
export enum ProjectProjectOperatingAreaConstraint {
  /** unique or primary key constraint */
  ProjectOperatingAreaPkey = 'project_operating_area_pkey'
}

/** input type for incrementing numeric columns in table "project.project_operating_area" */
export type ProjectProjectOperatingAreaIncInput = {
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "project.project_operating_area" */
export type ProjectProjectOperatingAreaInsertInput = {
  project?: Maybe<ProjectProjectObjRelInsertInput>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  province?: Maybe<ProvinceObjRelInsertInput>;
  /** จังหวัด */
  province_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type ProjectProjectOperatingAreaMaxFields = {
  __typename?: 'project_project_operating_area_max_fields';
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** จังหวัด */
  province_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "project.project_operating_area" */
export type ProjectProjectOperatingAreaMaxOrderBy = {
  /** โครงการ */
  project_id?: Maybe<OrderBy>;
  /** จังหวัด */
  province_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectProjectOperatingAreaMinFields = {
  __typename?: 'project_project_operating_area_min_fields';
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** จังหวัด */
  province_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "project.project_operating_area" */
export type ProjectProjectOperatingAreaMinOrderBy = {
  /** โครงการ */
  project_id?: Maybe<OrderBy>;
  /** จังหวัด */
  province_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** response of any mutation on the table "project.project_operating_area" */
export type ProjectProjectOperatingAreaMutationResponse = {
  __typename?: 'project_project_operating_area_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectProjectOperatingArea>;
};

/** on conflict condition type for table "project.project_operating_area" */
export type ProjectProjectOperatingAreaOnConflict = {
  constraint: ProjectProjectOperatingAreaConstraint;
  update_columns?: Array<ProjectProjectOperatingAreaUpdateColumn>;
  where?: Maybe<ProjectProjectOperatingAreaBoolExp>;
};

/** Ordering options when selecting data from "project.project_operating_area". */
export type ProjectProjectOperatingAreaOrderBy = {
  project?: Maybe<ProjectProjectOrderBy>;
  project_id?: Maybe<OrderBy>;
  province?: Maybe<ProvinceOrderBy>;
  province_id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
};

/** primary key columns input for table: project_project_operating_area */
export type ProjectProjectOperatingAreaPkColumnsInput = {
  /** โครงการ */
  project_id: Scalars['uuid'];
  /** จังหวัด */
  province_id: Scalars['uuid'];
};

/** select columns of table "project.project_operating_area" */
export enum ProjectProjectOperatingAreaSelectColumn {
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ProvinceId = 'province_id',
  /** column name */
  Sequence = 'sequence'
}

/** input type for updating data in table "project.project_operating_area" */
export type ProjectProjectOperatingAreaSetInput = {
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** จังหวัด */
  province_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type ProjectProjectOperatingAreaStddevFields = {
  __typename?: 'project_project_operating_area_stddev_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project.project_operating_area" */
export type ProjectProjectOperatingAreaStddevOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ProjectProjectOperatingAreaStddevPopFields = {
  __typename?: 'project_project_operating_area_stddev_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project.project_operating_area" */
export type ProjectProjectOperatingAreaStddevPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ProjectProjectOperatingAreaStddevSampFields = {
  __typename?: 'project_project_operating_area_stddev_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project.project_operating_area" */
export type ProjectProjectOperatingAreaStddevSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ProjectProjectOperatingAreaSumFields = {
  __typename?: 'project_project_operating_area_sum_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "project.project_operating_area" */
export type ProjectProjectOperatingAreaSumOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "project.project_operating_area" */
export enum ProjectProjectOperatingAreaUpdateColumn {
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ProvinceId = 'province_id',
  /** column name */
  Sequence = 'sequence'
}

/** aggregate var_pop on columns */
export type ProjectProjectOperatingAreaVarPopFields = {
  __typename?: 'project_project_operating_area_var_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project.project_operating_area" */
export type ProjectProjectOperatingAreaVarPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ProjectProjectOperatingAreaVarSampFields = {
  __typename?: 'project_project_operating_area_var_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project.project_operating_area" */
export type ProjectProjectOperatingAreaVarSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProjectProjectOperatingAreaVarianceFields = {
  __typename?: 'project_project_operating_area_variance_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project.project_operating_area" */
export type ProjectProjectOperatingAreaVarianceOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** Ordering options when selecting data from "project.project". */
export type ProjectProjectOrderBy = {
  approved?: Maybe<ProjectApprovedOrderBy>;
  approved_comment?: Maybe<OrderBy>;
  approved_date?: Maybe<OrderBy>;
  approved_id?: Maybe<OrderBy>;
  budget_plans_aggregate?: Maybe<ProjectBudgetPlanAggregateOrderBy>;
  budget_summary_aggregate?: Maybe<ProjectProjectBudgetSummaryAggregateOrderBy>;
  budget_templates_aggregate?: Maybe<ProjectProjectBudgetTemplateAggregateOrderBy>;
  cancelled_date?: Maybe<OrderBy>;
  change_request_date?: Maybe<OrderBy>;
  completed_date?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  created_by_user?: Maybe<UsersOrderBy>;
  deleted_at?: Maybe<OrderBy>;
  effect?: Maybe<OrderBy>;
  end_at?: Maybe<OrderBy>;
  fiscal_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  industry?: Maybe<OrderBy>;
  introduction?: Maybe<OrderBy>;
  last_overall_operation?: Maybe<OrderBy>;
  national_strategy_lvl1?: Maybe<ProjectNationalStrategyOrderBy>;
  national_strategy_lvl1_id?: Maybe<OrderBy>;
  national_strategy_lvl2?: Maybe<ProjectNationalStrategyOrderBy>;
  national_strategy_lvl2_id?: Maybe<OrderBy>;
  national_strategy_lvl3?: Maybe<ProjectNationalStrategyOrderBy>;
  national_strategy_lvl3_id?: Maybe<OrderBy>;
  national_strategy_lvl4?: Maybe<ProjectNationalStrategyOrderBy>;
  national_strategy_lvl4_id?: Maybe<OrderBy>;
  national_strategy_lvl5_id?: Maybe<OrderBy>;
  organization?: Maybe<OrganizationOrderBy>;
  organization_id?: Maybe<OrderBy>;
  project_achievements_aggregate?: Maybe<ProjectProjectAchievementAggregateOrderBy>;
  project_attachments_aggregate?: Maybe<ProjectProjectAttachmentAggregateOrderBy>;
  project_event_logs_aggregate?: Maybe<ProjectProjectEventLogAggregateOrderBy>;
  project_indicators_aggregate?: Maybe<ProjectProjectIndicatorAggregateOrderBy>;
  project_members_aggregate?: Maybe<ProjectProjectMemberAggregateOrderBy>;
  project_objectives_aggregate?: Maybe<ProjectProjectObjectiveAggregateOrderBy>;
  project_operating_activities_aggregate?: Maybe<ProjectProjectOperatingActivityAggregateOrderBy>;
  project_operating_areas_aggregate?: Maybe<ProjectProjectOperatingAreaAggregateOrderBy>;
  project_step?: Maybe<ProjectProjectStepOrderBy>;
  project_target_groups_aggregate?: Maybe<ProjectProjectTargetGroupAggregateOrderBy>;
  reference?: Maybe<OrderBy>;
  rejected_date?: Maybe<OrderBy>;
  start_at?: Maybe<OrderBy>;
  step_id?: Maybe<OrderBy>;
  submitted_date?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  total_budget?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
  updated_by_user?: Maybe<UsersOrderBy>;
  workload_summary_aggregate?: Maybe<ProjectProjectWorkloadSummaryAggregateOrderBy>;
};

/** primary key columns input for table: project_project */
export type ProjectProjectPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "project.project" */
export enum ProjectProjectSelectColumn {
  /** column name */
  ApprovedComment = 'approved_comment',
  /** column name */
  ApprovedDate = 'approved_date',
  /** column name */
  ApprovedId = 'approved_id',
  /** column name */
  CancelledDate = 'cancelled_date',
  /** column name */
  ChangeRequestDate = 'change_request_date',
  /** column name */
  CompletedDate = 'completed_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Effect = 'effect',
  /** column name */
  EndAt = 'end_at',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  Introduction = 'introduction',
  /** column name */
  LastOverallOperation = 'last_overall_operation',
  /** column name */
  NationalStrategyLvl1Id = 'national_strategy_lvl1_id',
  /** column name */
  NationalStrategyLvl2Id = 'national_strategy_lvl2_id',
  /** column name */
  NationalStrategyLvl3Id = 'national_strategy_lvl3_id',
  /** column name */
  NationalStrategyLvl4Id = 'national_strategy_lvl4_id',
  /** column name */
  NationalStrategyLvl5Id = 'national_strategy_lvl5_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Reference = 'reference',
  /** column name */
  RejectedDate = 'rejected_date',
  /** column name */
  StartAt = 'start_at',
  /** column name */
  StepId = 'step_id',
  /** column name */
  SubmittedDate = 'submitted_date',
  /** column name */
  Title = 'title',
  /** column name */
  TotalBudget = 'total_budget',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "project.project" */
export type ProjectProjectSetInput = {
  /** ข้อความเพิ่มเติมหลังตรวจสอบโครงการ */
  approved_comment?: Maybe<Scalars['String']>;
  /** วันที่ตรวจสอบโครงการ */
  approved_date?: Maybe<Scalars['timestamptz']>;
  /** สถานะการตรวจสอบของโครงการ */
  approved_id?: Maybe<ProjectApprovedEnum>;
  /** วันที่พิจารณา: ยกเลิกโครงการ */
  cancelled_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่พิจารณา: แก้ไขเอกสารเพิ่มเติม */
  change_request_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่เสร็จสิ้น */
  completed_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** บันทึกข้อมูลโดย */
  created_by?: Maybe<Scalars['uuid']>;
  /** วันที่ลบข้อมูล */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** ผลกระทบ */
  effect?: Maybe<Scalars['String']>;
  /** วันที่สิ้นสุด (ระยะเวลาดำเนินการ) */
  end_at?: Maybe<Scalars['date']>;
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** สาขาอุตสาหกรรม */
  industry?: Maybe<Scalars['String']>;
  /** หลักการและเหตุผลความจำเป็น */
  introduction?: Maybe<Scalars['String']>;
  /** ผลการดำเนินงานในปีที่ผ่านมา */
  last_overall_operation?: Maybe<Scalars['String']>;
  /** ด้านยุทธศาสตร์ */
  national_strategy_lvl1_id?: Maybe<Scalars['String']>;
  /** ประเด็นแผนแม่บท (จ.1) */
  national_strategy_lvl2_id?: Maybe<Scalars['String']>;
  /** เป้าหมายแผนแม่บท (จ.2) */
  national_strategy_lvl3_id?: Maybe<Scalars['String']>;
  /** แผนแม่บทย่อย */
  national_strategy_lvl4_id?: Maybe<Scalars['String']>;
  national_strategy_lvl5_id?: Maybe<Scalars['String']>;
  /** หน่วยงานรับผิดชอบ */
  organization_id?: Maybe<Scalars['uuid']>;
  /** รหัสอ้างอิงโครงการ */
  reference?: Maybe<Scalars['String']>;
  /** วันที่พิจารณา: ไม่อนุมัติโครงการ */
  rejected_date?: Maybe<Scalars['timestamptz']>;
  /** วันที่เริ่ม (ระยะเวลาดำเนินการ) */
  start_at?: Maybe<Scalars['date']>;
  /** สถานะโครงการ */
  step_id?: Maybe<Scalars['String']>;
  /** วันที่ส่งโครงการ */
  submitted_date?: Maybe<Scalars['timestamptz']>;
  /** ชื่อโครงการ */
  title?: Maybe<Scalars['String']>;
  /** งบประมาณ */
  total_budget?: Maybe<Scalars['numeric']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** แก้ไขข้อมูลโดย */
  updated_by?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ProjectProjectStddevFields = {
  __typename?: 'project_project_stddev_fields';
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Float']>;
  /** งบประมาณ */
  total_budget?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project.project" */
export type ProjectProjectStddevOrderBy = {
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<OrderBy>;
  /** งบประมาณ */
  total_budget?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ProjectProjectStddevPopFields = {
  __typename?: 'project_project_stddev_pop_fields';
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Float']>;
  /** งบประมาณ */
  total_budget?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project.project" */
export type ProjectProjectStddevPopOrderBy = {
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<OrderBy>;
  /** งบประมาณ */
  total_budget?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ProjectProjectStddevSampFields = {
  __typename?: 'project_project_stddev_samp_fields';
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Float']>;
  /** งบประมาณ */
  total_budget?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project.project" */
export type ProjectProjectStddevSampOrderBy = {
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<OrderBy>;
  /** งบประมาณ */
  total_budget?: Maybe<OrderBy>;
};

/**
 * สถานะโครงการ
 *
 *
 * columns and relationships of "project.project_step"
 */
export type ProjectProjectStep = {
  __typename?: 'project_project_step';
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
  /** An array relationship */
  projects: Array<ProjectProject>;
  /** An aggregate relationship */
  projects_aggregate: ProjectProjectAggregate;
  /** ลำดับ */
  value: Scalars['numeric'];
};


/**
 * สถานะโครงการ
 *
 *
 * columns and relationships of "project.project_step"
 */
export type ProjectProjectStepProjectsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOrderBy>>;
  where?: Maybe<ProjectProjectBoolExp>;
};


/**
 * สถานะโครงการ
 *
 *
 * columns and relationships of "project.project_step"
 */
export type ProjectProjectStepProjectsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOrderBy>>;
  where?: Maybe<ProjectProjectBoolExp>;
};

/** aggregated selection of "project.project_step" */
export type ProjectProjectStepAggregate = {
  __typename?: 'project_project_step_aggregate';
  aggregate?: Maybe<ProjectProjectStepAggregateFields>;
  nodes: Array<ProjectProjectStep>;
};

/** aggregate fields of "project.project_step" */
export type ProjectProjectStepAggregateFields = {
  __typename?: 'project_project_step_aggregate_fields';
  avg?: Maybe<ProjectProjectStepAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectProjectStepMaxFields>;
  min?: Maybe<ProjectProjectStepMinFields>;
  stddev?: Maybe<ProjectProjectStepStddevFields>;
  stddev_pop?: Maybe<ProjectProjectStepStddevPopFields>;
  stddev_samp?: Maybe<ProjectProjectStepStddevSampFields>;
  sum?: Maybe<ProjectProjectStepSumFields>;
  var_pop?: Maybe<ProjectProjectStepVarPopFields>;
  var_samp?: Maybe<ProjectProjectStepVarSampFields>;
  variance?: Maybe<ProjectProjectStepVarianceFields>;
};


/** aggregate fields of "project.project_step" */
export type ProjectProjectStepAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectProjectStepSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ProjectProjectStepAvgFields = {
  __typename?: 'project_project_step_avg_fields';
  /** ลำดับ */
  value?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "project.project_step". All fields are combined with a logical 'AND'. */
export type ProjectProjectStepBoolExp = {
  _and?: Maybe<Array<ProjectProjectStepBoolExp>>;
  _not?: Maybe<ProjectProjectStepBoolExp>;
  _or?: Maybe<Array<ProjectProjectStepBoolExp>>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  projects?: Maybe<ProjectProjectBoolExp>;
  value?: Maybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "project.project_step" */
export enum ProjectProjectStepConstraint {
  /** unique or primary key constraint */
  ProjectStepPkey = 'project_step_pkey'
}

/** input type for incrementing numeric columns in table "project.project_step" */
export type ProjectProjectStepIncInput = {
  /** ลำดับ */
  value?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "project.project_step" */
export type ProjectProjectStepInsertInput = {
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  projects?: Maybe<ProjectProjectArrRelInsertInput>;
  /** ลำดับ */
  value?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type ProjectProjectStepMaxFields = {
  __typename?: 'project_project_step_max_fields';
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับ */
  value?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type ProjectProjectStepMinFields = {
  __typename?: 'project_project_step_min_fields';
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับ */
  value?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "project.project_step" */
export type ProjectProjectStepMutationResponse = {
  __typename?: 'project_project_step_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectProjectStep>;
};

/** input type for inserting object relation for remote table "project.project_step" */
export type ProjectProjectStepObjRelInsertInput = {
  data: ProjectProjectStepInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectProjectStepOnConflict>;
};

/** on conflict condition type for table "project.project_step" */
export type ProjectProjectStepOnConflict = {
  constraint: ProjectProjectStepConstraint;
  update_columns?: Array<ProjectProjectStepUpdateColumn>;
  where?: Maybe<ProjectProjectStepBoolExp>;
};

/** Ordering options when selecting data from "project.project_step". */
export type ProjectProjectStepOrderBy = {
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  projects_aggregate?: Maybe<ProjectProjectAggregateOrderBy>;
  value?: Maybe<OrderBy>;
};

/** primary key columns input for table: project_project_step */
export type ProjectProjectStepPkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "project.project_step" */
export enum ProjectProjectStepSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "project.project_step" */
export type ProjectProjectStepSetInput = {
  /** คำอธิบาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  /** ลำดับ */
  value?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type ProjectProjectStepStddevFields = {
  __typename?: 'project_project_step_stddev_fields';
  /** ลำดับ */
  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ProjectProjectStepStddevPopFields = {
  __typename?: 'project_project_step_stddev_pop_fields';
  /** ลำดับ */
  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ProjectProjectStepStddevSampFields = {
  __typename?: 'project_project_step_stddev_samp_fields';
  /** ลำดับ */
  value?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ProjectProjectStepSumFields = {
  __typename?: 'project_project_step_sum_fields';
  /** ลำดับ */
  value?: Maybe<Scalars['numeric']>;
};

/** update columns of table "project.project_step" */
export enum ProjectProjectStepUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type ProjectProjectStepVarPopFields = {
  __typename?: 'project_project_step_var_pop_fields';
  /** ลำดับ */
  value?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ProjectProjectStepVarSampFields = {
  __typename?: 'project_project_step_var_samp_fields';
  /** ลำดับ */
  value?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ProjectProjectStepVarianceFields = {
  __typename?: 'project_project_step_variance_fields';
  /** ลำดับ */
  value?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ProjectProjectSumFields = {
  __typename?: 'project_project_sum_fields';
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Int']>;
  /** งบประมาณ */
  total_budget?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "project.project" */
export type ProjectProjectSumOrderBy = {
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<OrderBy>;
  /** งบประมาณ */
  total_budget?: Maybe<OrderBy>;
};

/**
 * กลุ่มเป้าหมายของโครงการ
 *
 *
 * columns and relationships of "project.project_target_group"
 */
export type ProjectProjectTargetGroup = {
  __typename?: 'project_project_target_group';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  project: ProjectProject;
  /** โครงการ */
  project_id: Scalars['uuid'];
  /** ลำดับที่ */
  sequence: Scalars['numeric'];
  /** An object relationship */
  target_group: ProjectTargetGroup;
  /** กลุ่มเป้าหมาย */
  target_group_id: Scalars['String'];
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "project.project_target_group" */
export type ProjectProjectTargetGroupAggregate = {
  __typename?: 'project_project_target_group_aggregate';
  aggregate?: Maybe<ProjectProjectTargetGroupAggregateFields>;
  nodes: Array<ProjectProjectTargetGroup>;
};

/** aggregate fields of "project.project_target_group" */
export type ProjectProjectTargetGroupAggregateFields = {
  __typename?: 'project_project_target_group_aggregate_fields';
  avg?: Maybe<ProjectProjectTargetGroupAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectProjectTargetGroupMaxFields>;
  min?: Maybe<ProjectProjectTargetGroupMinFields>;
  stddev?: Maybe<ProjectProjectTargetGroupStddevFields>;
  stddev_pop?: Maybe<ProjectProjectTargetGroupStddevPopFields>;
  stddev_samp?: Maybe<ProjectProjectTargetGroupStddevSampFields>;
  sum?: Maybe<ProjectProjectTargetGroupSumFields>;
  var_pop?: Maybe<ProjectProjectTargetGroupVarPopFields>;
  var_samp?: Maybe<ProjectProjectTargetGroupVarSampFields>;
  variance?: Maybe<ProjectProjectTargetGroupVarianceFields>;
};


/** aggregate fields of "project.project_target_group" */
export type ProjectProjectTargetGroupAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectProjectTargetGroupSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project.project_target_group" */
export type ProjectProjectTargetGroupAggregateOrderBy = {
  avg?: Maybe<ProjectProjectTargetGroupAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProjectProjectTargetGroupMaxOrderBy>;
  min?: Maybe<ProjectProjectTargetGroupMinOrderBy>;
  stddev?: Maybe<ProjectProjectTargetGroupStddevOrderBy>;
  stddev_pop?: Maybe<ProjectProjectTargetGroupStddevPopOrderBy>;
  stddev_samp?: Maybe<ProjectProjectTargetGroupStddevSampOrderBy>;
  sum?: Maybe<ProjectProjectTargetGroupSumOrderBy>;
  var_pop?: Maybe<ProjectProjectTargetGroupVarPopOrderBy>;
  var_samp?: Maybe<ProjectProjectTargetGroupVarSampOrderBy>;
  variance?: Maybe<ProjectProjectTargetGroupVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "project.project_target_group" */
export type ProjectProjectTargetGroupArrRelInsertInput = {
  data: Array<ProjectProjectTargetGroupInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectProjectTargetGroupOnConflict>;
};

/** aggregate avg on columns */
export type ProjectProjectTargetGroupAvgFields = {
  __typename?: 'project_project_target_group_avg_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project.project_target_group" */
export type ProjectProjectTargetGroupAvgOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "project.project_target_group". All fields are combined with a logical 'AND'. */
export type ProjectProjectTargetGroupBoolExp = {
  _and?: Maybe<Array<ProjectProjectTargetGroupBoolExp>>;
  _not?: Maybe<ProjectProjectTargetGroupBoolExp>;
  _or?: Maybe<Array<ProjectProjectTargetGroupBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  project?: Maybe<ProjectProjectBoolExp>;
  project_id?: Maybe<UuidComparisonExp>;
  sequence?: Maybe<NumericComparisonExp>;
  target_group?: Maybe<ProjectTargetGroupBoolExp>;
  target_group_id?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "project.project_target_group" */
export enum ProjectProjectTargetGroupConstraint {
  /** unique or primary key constraint */
  ProjectTargetGroupPkey = 'project_target_group_pkey'
}

/** input type for incrementing numeric columns in table "project.project_target_group" */
export type ProjectProjectTargetGroupIncInput = {
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "project.project_target_group" */
export type ProjectProjectTargetGroupInsertInput = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  project?: Maybe<ProjectProjectObjRelInsertInput>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  target_group?: Maybe<ProjectTargetGroupObjRelInsertInput>;
  /** กลุ่มเป้าหมาย */
  target_group_id?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ProjectProjectTargetGroupMaxFields = {
  __typename?: 'project_project_target_group_max_fields';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** กลุ่มเป้าหมาย */
  target_group_id?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "project.project_target_group" */
export type ProjectProjectTargetGroupMaxOrderBy = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** โครงการ */
  project_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** กลุ่มเป้าหมาย */
  target_group_id?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectProjectTargetGroupMinFields = {
  __typename?: 'project_project_target_group_min_fields';
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** กลุ่มเป้าหมาย */
  target_group_id?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "project.project_target_group" */
export type ProjectProjectTargetGroupMinOrderBy = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** โครงการ */
  project_id?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** กลุ่มเป้าหมาย */
  target_group_id?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "project.project_target_group" */
export type ProjectProjectTargetGroupMutationResponse = {
  __typename?: 'project_project_target_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectProjectTargetGroup>;
};

/** on conflict condition type for table "project.project_target_group" */
export type ProjectProjectTargetGroupOnConflict = {
  constraint: ProjectProjectTargetGroupConstraint;
  update_columns?: Array<ProjectProjectTargetGroupUpdateColumn>;
  where?: Maybe<ProjectProjectTargetGroupBoolExp>;
};

/** Ordering options when selecting data from "project.project_target_group". */
export type ProjectProjectTargetGroupOrderBy = {
  created_at?: Maybe<OrderBy>;
  project?: Maybe<ProjectProjectOrderBy>;
  project_id?: Maybe<OrderBy>;
  sequence?: Maybe<OrderBy>;
  target_group?: Maybe<ProjectTargetGroupOrderBy>;
  target_group_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: project_project_target_group */
export type ProjectProjectTargetGroupPkColumnsInput = {
  /** โครงการ */
  project_id: Scalars['uuid'];
  /** กลุ่มเป้าหมาย */
  target_group_id: Scalars['String'];
};

/** select columns of table "project.project_target_group" */
export enum ProjectProjectTargetGroupSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  TargetGroupId = 'target_group_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "project.project_target_group" */
export type ProjectProjectTargetGroupSetInput = {
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** โครงการ */
  project_id?: Maybe<Scalars['uuid']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** กลุ่มเป้าหมาย */
  target_group_id?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ProjectProjectTargetGroupStddevFields = {
  __typename?: 'project_project_target_group_stddev_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project.project_target_group" */
export type ProjectProjectTargetGroupStddevOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ProjectProjectTargetGroupStddevPopFields = {
  __typename?: 'project_project_target_group_stddev_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project.project_target_group" */
export type ProjectProjectTargetGroupStddevPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ProjectProjectTargetGroupStddevSampFields = {
  __typename?: 'project_project_target_group_stddev_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project.project_target_group" */
export type ProjectProjectTargetGroupStddevSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ProjectProjectTargetGroupSumFields = {
  __typename?: 'project_project_target_group_sum_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "project.project_target_group" */
export type ProjectProjectTargetGroupSumOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "project.project_target_group" */
export enum ProjectProjectTargetGroupUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  TargetGroupId = 'target_group_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type ProjectProjectTargetGroupVarPopFields = {
  __typename?: 'project_project_target_group_var_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project.project_target_group" */
export type ProjectProjectTargetGroupVarPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ProjectProjectTargetGroupVarSampFields = {
  __typename?: 'project_project_target_group_var_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project.project_target_group" */
export type ProjectProjectTargetGroupVarSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProjectProjectTargetGroupVarianceFields = {
  __typename?: 'project_project_target_group_variance_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project.project_target_group" */
export type ProjectProjectTargetGroupVarianceOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "project.project" */
export enum ProjectProjectUpdateColumn {
  /** column name */
  ApprovedComment = 'approved_comment',
  /** column name */
  ApprovedDate = 'approved_date',
  /** column name */
  ApprovedId = 'approved_id',
  /** column name */
  CancelledDate = 'cancelled_date',
  /** column name */
  ChangeRequestDate = 'change_request_date',
  /** column name */
  CompletedDate = 'completed_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Effect = 'effect',
  /** column name */
  EndAt = 'end_at',
  /** column name */
  FiscalYear = 'fiscal_year',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  Introduction = 'introduction',
  /** column name */
  LastOverallOperation = 'last_overall_operation',
  /** column name */
  NationalStrategyLvl1Id = 'national_strategy_lvl1_id',
  /** column name */
  NationalStrategyLvl2Id = 'national_strategy_lvl2_id',
  /** column name */
  NationalStrategyLvl3Id = 'national_strategy_lvl3_id',
  /** column name */
  NationalStrategyLvl4Id = 'national_strategy_lvl4_id',
  /** column name */
  NationalStrategyLvl5Id = 'national_strategy_lvl5_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Reference = 'reference',
  /** column name */
  RejectedDate = 'rejected_date',
  /** column name */
  StartAt = 'start_at',
  /** column name */
  StepId = 'step_id',
  /** column name */
  SubmittedDate = 'submitted_date',
  /** column name */
  Title = 'title',
  /** column name */
  TotalBudget = 'total_budget',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type ProjectProjectVarPopFields = {
  __typename?: 'project_project_var_pop_fields';
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Float']>;
  /** งบประมาณ */
  total_budget?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project.project" */
export type ProjectProjectVarPopOrderBy = {
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<OrderBy>;
  /** งบประมาณ */
  total_budget?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ProjectProjectVarSampFields = {
  __typename?: 'project_project_var_samp_fields';
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Float']>;
  /** งบประมาณ */
  total_budget?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project.project" */
export type ProjectProjectVarSampOrderBy = {
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<OrderBy>;
  /** งบประมาณ */
  total_budget?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProjectProjectVarianceFields = {
  __typename?: 'project_project_variance_fields';
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<Scalars['Float']>;
  /** งบประมาณ */
  total_budget?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project.project" */
export type ProjectProjectVarianceOrderBy = {
  /** ปีงบประมาณ */
  fiscal_year?: Maybe<OrderBy>;
  /** งบประมาณ */
  total_budget?: Maybe<OrderBy>;
};

/** columns and relationships of "project.project_workload_summary" */
export type ProjectProjectWorkloadSummary = {
  __typename?: 'project_project_workload_summary';
  /** An object relationship */
  activity?: Maybe<ProjectProjectOperatingActivity>;
  operating_activity_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  target_amount?: Maybe<Scalars['numeric']>;
  target_unit_id?: Maybe<Scalars['String']>;
  target_unit_name?: Maybe<Scalars['String']>;
  total_workload?: Maybe<Scalars['numeric']>;
  total_workload_plan?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "project.project_workload_summary" */
export type ProjectProjectWorkloadSummaryAggregate = {
  __typename?: 'project_project_workload_summary_aggregate';
  aggregate?: Maybe<ProjectProjectWorkloadSummaryAggregateFields>;
  nodes: Array<ProjectProjectWorkloadSummary>;
};

/** aggregate fields of "project.project_workload_summary" */
export type ProjectProjectWorkloadSummaryAggregateFields = {
  __typename?: 'project_project_workload_summary_aggregate_fields';
  avg?: Maybe<ProjectProjectWorkloadSummaryAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectProjectWorkloadSummaryMaxFields>;
  min?: Maybe<ProjectProjectWorkloadSummaryMinFields>;
  stddev?: Maybe<ProjectProjectWorkloadSummaryStddevFields>;
  stddev_pop?: Maybe<ProjectProjectWorkloadSummaryStddevPopFields>;
  stddev_samp?: Maybe<ProjectProjectWorkloadSummaryStddevSampFields>;
  sum?: Maybe<ProjectProjectWorkloadSummarySumFields>;
  var_pop?: Maybe<ProjectProjectWorkloadSummaryVarPopFields>;
  var_samp?: Maybe<ProjectProjectWorkloadSummaryVarSampFields>;
  variance?: Maybe<ProjectProjectWorkloadSummaryVarianceFields>;
};


/** aggregate fields of "project.project_workload_summary" */
export type ProjectProjectWorkloadSummaryAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectProjectWorkloadSummarySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project.project_workload_summary" */
export type ProjectProjectWorkloadSummaryAggregateOrderBy = {
  avg?: Maybe<ProjectProjectWorkloadSummaryAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProjectProjectWorkloadSummaryMaxOrderBy>;
  min?: Maybe<ProjectProjectWorkloadSummaryMinOrderBy>;
  stddev?: Maybe<ProjectProjectWorkloadSummaryStddevOrderBy>;
  stddev_pop?: Maybe<ProjectProjectWorkloadSummaryStddevPopOrderBy>;
  stddev_samp?: Maybe<ProjectProjectWorkloadSummaryStddevSampOrderBy>;
  sum?: Maybe<ProjectProjectWorkloadSummarySumOrderBy>;
  var_pop?: Maybe<ProjectProjectWorkloadSummaryVarPopOrderBy>;
  var_samp?: Maybe<ProjectProjectWorkloadSummaryVarSampOrderBy>;
  variance?: Maybe<ProjectProjectWorkloadSummaryVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "project.project_workload_summary" */
export type ProjectProjectWorkloadSummaryArrRelInsertInput = {
  data: Array<ProjectProjectWorkloadSummaryInsertInput>;
};

/** aggregate avg on columns */
export type ProjectProjectWorkloadSummaryAvgFields = {
  __typename?: 'project_project_workload_summary_avg_fields';
  target_amount?: Maybe<Scalars['Float']>;
  total_workload?: Maybe<Scalars['Float']>;
  total_workload_plan?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project.project_workload_summary" */
export type ProjectProjectWorkloadSummaryAvgOrderBy = {
  target_amount?: Maybe<OrderBy>;
  total_workload?: Maybe<OrderBy>;
  total_workload_plan?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "project.project_workload_summary". All fields are combined with a logical 'AND'. */
export type ProjectProjectWorkloadSummaryBoolExp = {
  _and?: Maybe<Array<ProjectProjectWorkloadSummaryBoolExp>>;
  _not?: Maybe<ProjectProjectWorkloadSummaryBoolExp>;
  _or?: Maybe<Array<ProjectProjectWorkloadSummaryBoolExp>>;
  activity?: Maybe<ProjectProjectOperatingActivityBoolExp>;
  operating_activity_id?: Maybe<UuidComparisonExp>;
  project_id?: Maybe<UuidComparisonExp>;
  target_amount?: Maybe<NumericComparisonExp>;
  target_unit_id?: Maybe<StringComparisonExp>;
  target_unit_name?: Maybe<StringComparisonExp>;
  total_workload?: Maybe<NumericComparisonExp>;
  total_workload_plan?: Maybe<NumericComparisonExp>;
};

/** input type for inserting data into table "project.project_workload_summary" */
export type ProjectProjectWorkloadSummaryInsertInput = {
  activity?: Maybe<ProjectProjectOperatingActivityObjRelInsertInput>;
  operating_activity_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  target_amount?: Maybe<Scalars['numeric']>;
  target_unit_id?: Maybe<Scalars['String']>;
  target_unit_name?: Maybe<Scalars['String']>;
  total_workload?: Maybe<Scalars['numeric']>;
  total_workload_plan?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type ProjectProjectWorkloadSummaryMaxFields = {
  __typename?: 'project_project_workload_summary_max_fields';
  operating_activity_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  target_amount?: Maybe<Scalars['numeric']>;
  target_unit_id?: Maybe<Scalars['String']>;
  target_unit_name?: Maybe<Scalars['String']>;
  total_workload?: Maybe<Scalars['numeric']>;
  total_workload_plan?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "project.project_workload_summary" */
export type ProjectProjectWorkloadSummaryMaxOrderBy = {
  operating_activity_id?: Maybe<OrderBy>;
  project_id?: Maybe<OrderBy>;
  target_amount?: Maybe<OrderBy>;
  target_unit_id?: Maybe<OrderBy>;
  target_unit_name?: Maybe<OrderBy>;
  total_workload?: Maybe<OrderBy>;
  total_workload_plan?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectProjectWorkloadSummaryMinFields = {
  __typename?: 'project_project_workload_summary_min_fields';
  operating_activity_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  target_amount?: Maybe<Scalars['numeric']>;
  target_unit_id?: Maybe<Scalars['String']>;
  target_unit_name?: Maybe<Scalars['String']>;
  total_workload?: Maybe<Scalars['numeric']>;
  total_workload_plan?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "project.project_workload_summary" */
export type ProjectProjectWorkloadSummaryMinOrderBy = {
  operating_activity_id?: Maybe<OrderBy>;
  project_id?: Maybe<OrderBy>;
  target_amount?: Maybe<OrderBy>;
  target_unit_id?: Maybe<OrderBy>;
  target_unit_name?: Maybe<OrderBy>;
  total_workload?: Maybe<OrderBy>;
  total_workload_plan?: Maybe<OrderBy>;
};

/** Ordering options when selecting data from "project.project_workload_summary". */
export type ProjectProjectWorkloadSummaryOrderBy = {
  activity?: Maybe<ProjectProjectOperatingActivityOrderBy>;
  operating_activity_id?: Maybe<OrderBy>;
  project_id?: Maybe<OrderBy>;
  target_amount?: Maybe<OrderBy>;
  target_unit_id?: Maybe<OrderBy>;
  target_unit_name?: Maybe<OrderBy>;
  total_workload?: Maybe<OrderBy>;
  total_workload_plan?: Maybe<OrderBy>;
};

/** select columns of table "project.project_workload_summary" */
export enum ProjectProjectWorkloadSummarySelectColumn {
  /** column name */
  OperatingActivityId = 'operating_activity_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  TargetAmount = 'target_amount',
  /** column name */
  TargetUnitId = 'target_unit_id',
  /** column name */
  TargetUnitName = 'target_unit_name',
  /** column name */
  TotalWorkload = 'total_workload',
  /** column name */
  TotalWorkloadPlan = 'total_workload_plan'
}

/** aggregate stddev on columns */
export type ProjectProjectWorkloadSummaryStddevFields = {
  __typename?: 'project_project_workload_summary_stddev_fields';
  target_amount?: Maybe<Scalars['Float']>;
  total_workload?: Maybe<Scalars['Float']>;
  total_workload_plan?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project.project_workload_summary" */
export type ProjectProjectWorkloadSummaryStddevOrderBy = {
  target_amount?: Maybe<OrderBy>;
  total_workload?: Maybe<OrderBy>;
  total_workload_plan?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ProjectProjectWorkloadSummaryStddevPopFields = {
  __typename?: 'project_project_workload_summary_stddev_pop_fields';
  target_amount?: Maybe<Scalars['Float']>;
  total_workload?: Maybe<Scalars['Float']>;
  total_workload_plan?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project.project_workload_summary" */
export type ProjectProjectWorkloadSummaryStddevPopOrderBy = {
  target_amount?: Maybe<OrderBy>;
  total_workload?: Maybe<OrderBy>;
  total_workload_plan?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ProjectProjectWorkloadSummaryStddevSampFields = {
  __typename?: 'project_project_workload_summary_stddev_samp_fields';
  target_amount?: Maybe<Scalars['Float']>;
  total_workload?: Maybe<Scalars['Float']>;
  total_workload_plan?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project.project_workload_summary" */
export type ProjectProjectWorkloadSummaryStddevSampOrderBy = {
  target_amount?: Maybe<OrderBy>;
  total_workload?: Maybe<OrderBy>;
  total_workload_plan?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ProjectProjectWorkloadSummarySumFields = {
  __typename?: 'project_project_workload_summary_sum_fields';
  target_amount?: Maybe<Scalars['numeric']>;
  total_workload?: Maybe<Scalars['numeric']>;
  total_workload_plan?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "project.project_workload_summary" */
export type ProjectProjectWorkloadSummarySumOrderBy = {
  target_amount?: Maybe<OrderBy>;
  total_workload?: Maybe<OrderBy>;
  total_workload_plan?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ProjectProjectWorkloadSummaryVarPopFields = {
  __typename?: 'project_project_workload_summary_var_pop_fields';
  target_amount?: Maybe<Scalars['Float']>;
  total_workload?: Maybe<Scalars['Float']>;
  total_workload_plan?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project.project_workload_summary" */
export type ProjectProjectWorkloadSummaryVarPopOrderBy = {
  target_amount?: Maybe<OrderBy>;
  total_workload?: Maybe<OrderBy>;
  total_workload_plan?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ProjectProjectWorkloadSummaryVarSampFields = {
  __typename?: 'project_project_workload_summary_var_samp_fields';
  target_amount?: Maybe<Scalars['Float']>;
  total_workload?: Maybe<Scalars['Float']>;
  total_workload_plan?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project.project_workload_summary" */
export type ProjectProjectWorkloadSummaryVarSampOrderBy = {
  target_amount?: Maybe<OrderBy>;
  total_workload?: Maybe<OrderBy>;
  total_workload_plan?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProjectProjectWorkloadSummaryVarianceFields = {
  __typename?: 'project_project_workload_summary_variance_fields';
  target_amount?: Maybe<Scalars['Float']>;
  total_workload?: Maybe<Scalars['Float']>;
  total_workload_plan?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project.project_workload_summary" */
export type ProjectProjectWorkloadSummaryVarianceOrderBy = {
  target_amount?: Maybe<OrderBy>;
  total_workload?: Maybe<OrderBy>;
  total_workload_plan?: Maybe<OrderBy>;
};

/** columns and relationships of "project.sub_plan" */
export type ProjectSubPlan = {
  __typename?: 'project_sub_plan';
  /** An array relationship */
  children: Array<ProjectSubPlan>;
  /** An aggregate relationship */
  children_aggregate: ProjectSubPlanAggregate;
  depth?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  parent?: Maybe<ProjectSubPlan>;
  parent_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "project.sub_plan" */
export type ProjectSubPlanChildrenArgs = {
  distinct_on?: Maybe<Array<ProjectSubPlanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectSubPlanOrderBy>>;
  where?: Maybe<ProjectSubPlanBoolExp>;
};


/** columns and relationships of "project.sub_plan" */
export type ProjectSubPlanChildrenAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectSubPlanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectSubPlanOrderBy>>;
  where?: Maybe<ProjectSubPlanBoolExp>;
};

/** aggregated selection of "project.sub_plan" */
export type ProjectSubPlanAggregate = {
  __typename?: 'project_sub_plan_aggregate';
  aggregate?: Maybe<ProjectSubPlanAggregateFields>;
  nodes: Array<ProjectSubPlan>;
};

/** aggregate fields of "project.sub_plan" */
export type ProjectSubPlanAggregateFields = {
  __typename?: 'project_sub_plan_aggregate_fields';
  avg?: Maybe<ProjectSubPlanAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectSubPlanMaxFields>;
  min?: Maybe<ProjectSubPlanMinFields>;
  stddev?: Maybe<ProjectSubPlanStddevFields>;
  stddev_pop?: Maybe<ProjectSubPlanStddevPopFields>;
  stddev_samp?: Maybe<ProjectSubPlanStddevSampFields>;
  sum?: Maybe<ProjectSubPlanSumFields>;
  var_pop?: Maybe<ProjectSubPlanVarPopFields>;
  var_samp?: Maybe<ProjectSubPlanVarSampFields>;
  variance?: Maybe<ProjectSubPlanVarianceFields>;
};


/** aggregate fields of "project.sub_plan" */
export type ProjectSubPlanAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectSubPlanSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project.sub_plan" */
export type ProjectSubPlanAggregateOrderBy = {
  avg?: Maybe<ProjectSubPlanAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProjectSubPlanMaxOrderBy>;
  min?: Maybe<ProjectSubPlanMinOrderBy>;
  stddev?: Maybe<ProjectSubPlanStddevOrderBy>;
  stddev_pop?: Maybe<ProjectSubPlanStddevPopOrderBy>;
  stddev_samp?: Maybe<ProjectSubPlanStddevSampOrderBy>;
  sum?: Maybe<ProjectSubPlanSumOrderBy>;
  var_pop?: Maybe<ProjectSubPlanVarPopOrderBy>;
  var_samp?: Maybe<ProjectSubPlanVarSampOrderBy>;
  variance?: Maybe<ProjectSubPlanVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "project.sub_plan" */
export type ProjectSubPlanArrRelInsertInput = {
  data: Array<ProjectSubPlanInsertInput>;
};

/** aggregate avg on columns */
export type ProjectSubPlanAvgFields = {
  __typename?: 'project_sub_plan_avg_fields';
  depth?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project.sub_plan" */
export type ProjectSubPlanAvgOrderBy = {
  depth?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "project.sub_plan". All fields are combined with a logical 'AND'. */
export type ProjectSubPlanBoolExp = {
  _and?: Maybe<Array<ProjectSubPlanBoolExp>>;
  _not?: Maybe<ProjectSubPlanBoolExp>;
  _or?: Maybe<Array<ProjectSubPlanBoolExp>>;
  children?: Maybe<ProjectSubPlanBoolExp>;
  depth?: Maybe<IntComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  parent?: Maybe<ProjectSubPlanBoolExp>;
  parent_id?: Maybe<StringComparisonExp>;
};

/** input type for incrementing numeric columns in table "project.sub_plan" */
export type ProjectSubPlanIncInput = {
  depth?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "project.sub_plan" */
export type ProjectSubPlanInsertInput = {
  children?: Maybe<ProjectSubPlanArrRelInsertInput>;
  depth?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<ProjectSubPlanObjRelInsertInput>;
  parent_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ProjectSubPlanMaxFields = {
  __typename?: 'project_sub_plan_max_fields';
  depth?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "project.sub_plan" */
export type ProjectSubPlanMaxOrderBy = {
  depth?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  parent_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectSubPlanMinFields = {
  __typename?: 'project_sub_plan_min_fields';
  depth?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "project.sub_plan" */
export type ProjectSubPlanMinOrderBy = {
  depth?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  parent_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "project.sub_plan" */
export type ProjectSubPlanMutationResponse = {
  __typename?: 'project_sub_plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectSubPlan>;
};

/** input type for inserting object relation for remote table "project.sub_plan" */
export type ProjectSubPlanObjRelInsertInput = {
  data: ProjectSubPlanInsertInput;
};

/** Ordering options when selecting data from "project.sub_plan". */
export type ProjectSubPlanOrderBy = {
  children_aggregate?: Maybe<ProjectSubPlanAggregateOrderBy>;
  depth?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  parent?: Maybe<ProjectSubPlanOrderBy>;
  parent_id?: Maybe<OrderBy>;
};

/** select columns of table "project.sub_plan" */
export enum ProjectSubPlanSelectColumn {
  /** column name */
  Depth = 'depth',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id'
}

/** input type for updating data in table "project.sub_plan" */
export type ProjectSubPlanSetInput = {
  depth?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ProjectSubPlanStddevFields = {
  __typename?: 'project_sub_plan_stddev_fields';
  depth?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project.sub_plan" */
export type ProjectSubPlanStddevOrderBy = {
  depth?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ProjectSubPlanStddevPopFields = {
  __typename?: 'project_sub_plan_stddev_pop_fields';
  depth?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project.sub_plan" */
export type ProjectSubPlanStddevPopOrderBy = {
  depth?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ProjectSubPlanStddevSampFields = {
  __typename?: 'project_sub_plan_stddev_samp_fields';
  depth?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project.sub_plan" */
export type ProjectSubPlanStddevSampOrderBy = {
  depth?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ProjectSubPlanSumFields = {
  __typename?: 'project_sub_plan_sum_fields';
  depth?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "project.sub_plan" */
export type ProjectSubPlanSumOrderBy = {
  depth?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ProjectSubPlanVarPopFields = {
  __typename?: 'project_sub_plan_var_pop_fields';
  depth?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project.sub_plan" */
export type ProjectSubPlanVarPopOrderBy = {
  depth?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ProjectSubPlanVarSampFields = {
  __typename?: 'project_sub_plan_var_samp_fields';
  depth?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project.sub_plan" */
export type ProjectSubPlanVarSampOrderBy = {
  depth?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProjectSubPlanVarianceFields = {
  __typename?: 'project_sub_plan_variance_fields';
  depth?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project.sub_plan" */
export type ProjectSubPlanVarianceOrderBy = {
  depth?: Maybe<OrderBy>;
};

/**
 * กลุ่มเป้าหมาย
 *
 *
 * columns and relationships of "project.target_group"
 */
export type ProjectTargetGroup = {
  __typename?: 'project_target_group';
  /** คำอธิบาย กลุ่มเป้าหมาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
  /** An array relationship */
  project_target_groups: Array<ProjectProjectTargetGroup>;
  /** An aggregate relationship */
  project_target_groups_aggregate: ProjectProjectTargetGroupAggregate;
};


/**
 * กลุ่มเป้าหมาย
 *
 *
 * columns and relationships of "project.target_group"
 */
export type ProjectTargetGroupProjectTargetGroupsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectTargetGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectTargetGroupOrderBy>>;
  where?: Maybe<ProjectProjectTargetGroupBoolExp>;
};


/**
 * กลุ่มเป้าหมาย
 *
 *
 * columns and relationships of "project.target_group"
 */
export type ProjectTargetGroupProjectTargetGroupsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectTargetGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectTargetGroupOrderBy>>;
  where?: Maybe<ProjectProjectTargetGroupBoolExp>;
};

/** aggregated selection of "project.target_group" */
export type ProjectTargetGroupAggregate = {
  __typename?: 'project_target_group_aggregate';
  aggregate?: Maybe<ProjectTargetGroupAggregateFields>;
  nodes: Array<ProjectTargetGroup>;
};

/** aggregate fields of "project.target_group" */
export type ProjectTargetGroupAggregateFields = {
  __typename?: 'project_target_group_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ProjectTargetGroupMaxFields>;
  min?: Maybe<ProjectTargetGroupMinFields>;
};


/** aggregate fields of "project.target_group" */
export type ProjectTargetGroupAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectTargetGroupSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "project.target_group". All fields are combined with a logical 'AND'. */
export type ProjectTargetGroupBoolExp = {
  _and?: Maybe<Array<ProjectTargetGroupBoolExp>>;
  _not?: Maybe<ProjectTargetGroupBoolExp>;
  _or?: Maybe<Array<ProjectTargetGroupBoolExp>>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  project_target_groups?: Maybe<ProjectProjectTargetGroupBoolExp>;
};

/** unique or primary key constraints on table "project.target_group" */
export enum ProjectTargetGroupConstraint {
  /** unique or primary key constraint */
  TargetGroupPkey = 'target_group_pkey'
}

/** input type for inserting data into table "project.target_group" */
export type ProjectTargetGroupInsertInput = {
  /** คำอธิบาย กลุ่มเป้าหมาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  project_target_groups?: Maybe<ProjectProjectTargetGroupArrRelInsertInput>;
};

/** aggregate max on columns */
export type ProjectTargetGroupMaxFields = {
  __typename?: 'project_target_group_max_fields';
  /** คำอธิบาย กลุ่มเป้าหมาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ProjectTargetGroupMinFields = {
  __typename?: 'project_target_group_min_fields';
  /** คำอธิบาย กลุ่มเป้าหมาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "project.target_group" */
export type ProjectTargetGroupMutationResponse = {
  __typename?: 'project_target_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectTargetGroup>;
};

/** input type for inserting object relation for remote table "project.target_group" */
export type ProjectTargetGroupObjRelInsertInput = {
  data: ProjectTargetGroupInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectTargetGroupOnConflict>;
};

/** on conflict condition type for table "project.target_group" */
export type ProjectTargetGroupOnConflict = {
  constraint: ProjectTargetGroupConstraint;
  update_columns?: Array<ProjectTargetGroupUpdateColumn>;
  where?: Maybe<ProjectTargetGroupBoolExp>;
};

/** Ordering options when selecting data from "project.target_group". */
export type ProjectTargetGroupOrderBy = {
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  project_target_groups_aggregate?: Maybe<ProjectProjectTargetGroupAggregateOrderBy>;
};

/** primary key columns input for table: project_target_group */
export type ProjectTargetGroupPkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "project.target_group" */
export enum ProjectTargetGroupSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "project.target_group" */
export type ProjectTargetGroupSetInput = {
  /** คำอธิบาย กลุ่มเป้าหมาย */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** update columns of table "project.target_group" */
export enum ProjectTargetGroupUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/**
 * หน่วยนับ
 *
 *
 * columns and relationships of "project.unit"
 */
export type ProjectUnit = {
  __typename?: 'project_unit';
  /** An array relationship */
  budgets: Array<ProjectBudget>;
  /** An aggregate relationship */
  budgets_aggregate: ProjectBudgetAggregate;
  /** An array relationship */
  budgets_unit_of_multipliers: Array<ProjectBudget>;
  /** An aggregate relationship */
  budgets_unit_of_multipliers_aggregate: ProjectBudgetAggregate;
  /** คำอธิบาย หน่วยนับ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['String'];
  /** An array relationship */
  project_budget_items: Array<ProjectProjectBudgetItem>;
  /** An aggregate relationship */
  project_budget_items_aggregate: ProjectProjectBudgetItemAggregate;
  /** An array relationship */
  project_budget_items_by_unit_of_multipliers: Array<ProjectProjectBudgetItem>;
  /** An aggregate relationship */
  project_budget_items_by_unit_of_multipliers_aggregate: ProjectProjectBudgetItemAggregate;
  /** An array relationship */
  project_indicators: Array<ProjectProjectIndicator>;
  /** An aggregate relationship */
  project_indicators_aggregate: ProjectProjectIndicatorAggregate;
  /** An array relationship */
  project_operating_activities: Array<ProjectProjectOperatingActivity>;
  /** An aggregate relationship */
  project_operating_activities_aggregate: ProjectProjectOperatingActivityAggregate;
};


/**
 * หน่วยนับ
 *
 *
 * columns and relationships of "project.unit"
 */
export type ProjectUnitBudgetsArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetOrderBy>>;
  where?: Maybe<ProjectBudgetBoolExp>;
};


/**
 * หน่วยนับ
 *
 *
 * columns and relationships of "project.unit"
 */
export type ProjectUnitBudgetsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetOrderBy>>;
  where?: Maybe<ProjectBudgetBoolExp>;
};


/**
 * หน่วยนับ
 *
 *
 * columns and relationships of "project.unit"
 */
export type ProjectUnitBudgetsUnitOfMultipliersArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetOrderBy>>;
  where?: Maybe<ProjectBudgetBoolExp>;
};


/**
 * หน่วยนับ
 *
 *
 * columns and relationships of "project.unit"
 */
export type ProjectUnitBudgetsUnitOfMultipliersAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetOrderBy>>;
  where?: Maybe<ProjectBudgetBoolExp>;
};


/**
 * หน่วยนับ
 *
 *
 * columns and relationships of "project.unit"
 */
export type ProjectUnitProjectBudgetItemsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetItemOrderBy>>;
  where?: Maybe<ProjectProjectBudgetItemBoolExp>;
};


/**
 * หน่วยนับ
 *
 *
 * columns and relationships of "project.unit"
 */
export type ProjectUnitProjectBudgetItemsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetItemOrderBy>>;
  where?: Maybe<ProjectProjectBudgetItemBoolExp>;
};


/**
 * หน่วยนับ
 *
 *
 * columns and relationships of "project.unit"
 */
export type ProjectUnitProjectBudgetItemsByUnitOfMultipliersArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetItemOrderBy>>;
  where?: Maybe<ProjectProjectBudgetItemBoolExp>;
};


/**
 * หน่วยนับ
 *
 *
 * columns and relationships of "project.unit"
 */
export type ProjectUnitProjectBudgetItemsByUnitOfMultipliersAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetItemOrderBy>>;
  where?: Maybe<ProjectProjectBudgetItemBoolExp>;
};


/**
 * หน่วยนับ
 *
 *
 * columns and relationships of "project.unit"
 */
export type ProjectUnitProjectIndicatorsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectIndicatorSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectIndicatorOrderBy>>;
  where?: Maybe<ProjectProjectIndicatorBoolExp>;
};


/**
 * หน่วยนับ
 *
 *
 * columns and relationships of "project.unit"
 */
export type ProjectUnitProjectIndicatorsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectIndicatorSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectIndicatorOrderBy>>;
  where?: Maybe<ProjectProjectIndicatorBoolExp>;
};


/**
 * หน่วยนับ
 *
 *
 * columns and relationships of "project.unit"
 */
export type ProjectUnitProjectOperatingActivitiesArgs = {
  distinct_on?: Maybe<Array<ProjectProjectOperatingActivitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOperatingActivityOrderBy>>;
  where?: Maybe<ProjectProjectOperatingActivityBoolExp>;
};


/**
 * หน่วยนับ
 *
 *
 * columns and relationships of "project.unit"
 */
export type ProjectUnitProjectOperatingActivitiesAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectOperatingActivitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOperatingActivityOrderBy>>;
  where?: Maybe<ProjectProjectOperatingActivityBoolExp>;
};

/** aggregated selection of "project.unit" */
export type ProjectUnitAggregate = {
  __typename?: 'project_unit_aggregate';
  aggregate?: Maybe<ProjectUnitAggregateFields>;
  nodes: Array<ProjectUnit>;
};

/** aggregate fields of "project.unit" */
export type ProjectUnitAggregateFields = {
  __typename?: 'project_unit_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ProjectUnitMaxFields>;
  min?: Maybe<ProjectUnitMinFields>;
};


/** aggregate fields of "project.unit" */
export type ProjectUnitAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectUnitSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "project.unit". All fields are combined with a logical 'AND'. */
export type ProjectUnitBoolExp = {
  _and?: Maybe<Array<ProjectUnitBoolExp>>;
  _not?: Maybe<ProjectUnitBoolExp>;
  _or?: Maybe<Array<ProjectUnitBoolExp>>;
  budgets?: Maybe<ProjectBudgetBoolExp>;
  budgets_unit_of_multipliers?: Maybe<ProjectBudgetBoolExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  project_budget_items?: Maybe<ProjectProjectBudgetItemBoolExp>;
  project_budget_items_by_unit_of_multipliers?: Maybe<ProjectProjectBudgetItemBoolExp>;
  project_indicators?: Maybe<ProjectProjectIndicatorBoolExp>;
  project_operating_activities?: Maybe<ProjectProjectOperatingActivityBoolExp>;
};

/** unique or primary key constraints on table "project.unit" */
export enum ProjectUnitConstraint {
  /** unique or primary key constraint */
  UnitPkey = 'unit_pkey'
}

export enum ProjectUnitEnum {
  /** ปี */
  Year = 'Year',
  /** คัน */
  Carrent = 'carrent',
  /** ตอน */
  Chapters = 'chapters',
  /** คลิป */
  Clip = 'clip',
  /** คอลัมน์ */
  Column = 'column',
  /** วัน */
  Day = 'day',
  /** จุด */
  Digits = 'digits',
  /** โรงงาน */
  Factory = 'factory',
  /** สถานประกอบการ */
  Firm = 'firm',
  /** กิจการ */
  Firm02 = 'firm02',
  /** ชั่วโมง */
  Hour = 'hour',
  /** คน */
  Human = 'human',
  /** ชิ้น */
  Item = 'item',
  /** อัน */
  Item2 = 'item2',
  /** เรื่อง */
  Item3 = 'item3',
  /** แห่ง */
  Item4 = 'item4',
  /** หน่วยงาน */
  Item5 = 'item5',
  /** รายงาน */
  Item6 = 'item6',
  /** แผน */
  Item7 = 'item7',
  /** คะแนนขึ้นไป */
  Item8 = 'item8',
  /** กิโลเมตร */
  Kilo = 'kilo',
  /** เดือน */
  Monthly = 'monthly',
  /** คืน */
  Night = 'night',
  /** ล้านบาท/ปี */
  Outcome1 = 'outcome1',
  /** หน้า */
  Page = 'page',
  /** ร้อยละ */
  Percentage = 'percentage',
  /** ราย */
  Person = 'person',
  /** มื้อ */
  Potion = 'potion',
  /** ผลิตภัณฑ์ */
  Product = 'product',
  /** จังหวัด */
  Province = 'province',
  /** แผ่น */
  Sheet = 'sheet',
  /** ตารางเมตร */
  Squaremeter = 'squaremeter',
  /** เทส */
  Test = 'test',
  /** ทดสอบ999 */
  Test999 = 'test999',
  /** ครั้ง */
  Time = 'time',
  /** เที่ยว */
  Times = 'times',
  /** ตัน */
  Ton = 'ton'
}

/** Boolean expression to compare columns of type "project_unit_enum". All fields are combined with logical 'AND'. */
export type ProjectUnitEnumComparisonExp = {
  _eq?: Maybe<ProjectUnitEnum>;
  _in?: Maybe<Array<ProjectUnitEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<ProjectUnitEnum>;
  _nin?: Maybe<Array<ProjectUnitEnum>>;
};

/** input type for inserting data into table "project.unit" */
export type ProjectUnitInsertInput = {
  budgets?: Maybe<ProjectBudgetArrRelInsertInput>;
  budgets_unit_of_multipliers?: Maybe<ProjectBudgetArrRelInsertInput>;
  /** คำอธิบาย หน่วยนับ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
  project_budget_items?: Maybe<ProjectProjectBudgetItemArrRelInsertInput>;
  project_budget_items_by_unit_of_multipliers?: Maybe<ProjectProjectBudgetItemArrRelInsertInput>;
  project_indicators?: Maybe<ProjectProjectIndicatorArrRelInsertInput>;
  project_operating_activities?: Maybe<ProjectProjectOperatingActivityArrRelInsertInput>;
};

/** aggregate max on columns */
export type ProjectUnitMaxFields = {
  __typename?: 'project_unit_max_fields';
  /** คำอธิบาย หน่วยนับ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ProjectUnitMinFields = {
  __typename?: 'project_unit_min_fields';
  /** คำอธิบาย หน่วยนับ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "project.unit" */
export type ProjectUnitMutationResponse = {
  __typename?: 'project_unit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectUnit>;
};

/** input type for inserting object relation for remote table "project.unit" */
export type ProjectUnitObjRelInsertInput = {
  data: ProjectUnitInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectUnitOnConflict>;
};

/** on conflict condition type for table "project.unit" */
export type ProjectUnitOnConflict = {
  constraint: ProjectUnitConstraint;
  update_columns?: Array<ProjectUnitUpdateColumn>;
  where?: Maybe<ProjectUnitBoolExp>;
};

/** Ordering options when selecting data from "project.unit". */
export type ProjectUnitOrderBy = {
  budgets_aggregate?: Maybe<ProjectBudgetAggregateOrderBy>;
  budgets_unit_of_multipliers_aggregate?: Maybe<ProjectBudgetAggregateOrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  project_budget_items_aggregate?: Maybe<ProjectProjectBudgetItemAggregateOrderBy>;
  project_budget_items_by_unit_of_multipliers_aggregate?: Maybe<ProjectProjectBudgetItemAggregateOrderBy>;
  project_indicators_aggregate?: Maybe<ProjectProjectIndicatorAggregateOrderBy>;
  project_operating_activities_aggregate?: Maybe<ProjectProjectOperatingActivityAggregateOrderBy>;
};

/** primary key columns input for table: project_unit */
export type ProjectUnitPkColumnsInput = {
  /** Primary key */
  id: Scalars['String'];
};

/** select columns of table "project.unit" */
export enum ProjectUnitSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "project.unit" */
export type ProjectUnitSetInput = {
  /** คำอธิบาย หน่วยนับ */
  description?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['String']>;
};

/** update columns of table "project.unit" */
export enum ProjectUnitUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/**
 * จังหวัด
 *
 *
 * columns and relationships of "province"
 */
export type Province = {
  __typename?: 'province';
  /** รหัสจังหวัด */
  code?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** ชื่อจังหวัดอังกฤษ */
  name_en?: Maybe<Scalars['String']>;
  /** ชื่อจังหวัดไทย */
  name_th?: Maybe<Scalars['String']>;
  /** An array relationship */
  project_operating_areas: Array<ProjectProjectOperatingArea>;
  /** An aggregate relationship */
  project_operating_areas_aggregate: ProjectProjectOperatingAreaAggregate;
  /** An object relationship */
  region: Region;
  /** ภูมิภาค */
  region_id: Scalars['uuid'];
};


/**
 * จังหวัด
 *
 *
 * columns and relationships of "province"
 */
export type ProvinceProjectOperatingAreasArgs = {
  distinct_on?: Maybe<Array<ProjectProjectOperatingAreaSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOperatingAreaOrderBy>>;
  where?: Maybe<ProjectProjectOperatingAreaBoolExp>;
};


/**
 * จังหวัด
 *
 *
 * columns and relationships of "province"
 */
export type ProvinceProjectOperatingAreasAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectOperatingAreaSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOperatingAreaOrderBy>>;
  where?: Maybe<ProjectProjectOperatingAreaBoolExp>;
};

/** aggregated selection of "province" */
export type ProvinceAggregate = {
  __typename?: 'province_aggregate';
  aggregate?: Maybe<ProvinceAggregateFields>;
  nodes: Array<Province>;
};

/** aggregate fields of "province" */
export type ProvinceAggregateFields = {
  __typename?: 'province_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ProvinceMaxFields>;
  min?: Maybe<ProvinceMinFields>;
};


/** aggregate fields of "province" */
export type ProvinceAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProvinceSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "province" */
export type ProvinceAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ProvinceMaxOrderBy>;
  min?: Maybe<ProvinceMinOrderBy>;
};

/** input type for inserting array relation for remote table "province" */
export type ProvinceArrRelInsertInput = {
  data: Array<ProvinceInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ProvinceOnConflict>;
};

/** Boolean expression to filter rows from the table "province". All fields are combined with a logical 'AND'. */
export type ProvinceBoolExp = {
  _and?: Maybe<Array<ProvinceBoolExp>>;
  _not?: Maybe<ProvinceBoolExp>;
  _or?: Maybe<Array<ProvinceBoolExp>>;
  code?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name_en?: Maybe<StringComparisonExp>;
  name_th?: Maybe<StringComparisonExp>;
  project_operating_areas?: Maybe<ProjectProjectOperatingAreaBoolExp>;
  region?: Maybe<RegionBoolExp>;
  region_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "province" */
export enum ProvinceConstraint {
  /** unique or primary key constraint */
  ProvinceCodeKey = 'province_code_key',
  /** unique or primary key constraint */
  ProvincePkey = 'province_pkey'
}

/** input type for inserting data into table "province" */
export type ProvinceInsertInput = {
  /** รหัสจังหวัด */
  code?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อจังหวัดอังกฤษ */
  name_en?: Maybe<Scalars['String']>;
  /** ชื่อจังหวัดไทย */
  name_th?: Maybe<Scalars['String']>;
  project_operating_areas?: Maybe<ProjectProjectOperatingAreaArrRelInsertInput>;
  region?: Maybe<RegionObjRelInsertInput>;
  /** ภูมิภาค */
  region_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ProvinceMaxFields = {
  __typename?: 'province_max_fields';
  /** รหัสจังหวัด */
  code?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อจังหวัดอังกฤษ */
  name_en?: Maybe<Scalars['String']>;
  /** ชื่อจังหวัดไทย */
  name_th?: Maybe<Scalars['String']>;
  /** ภูมิภาค */
  region_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "province" */
export type ProvinceMaxOrderBy = {
  /** รหัสจังหวัด */
  code?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ชื่อจังหวัดอังกฤษ */
  name_en?: Maybe<OrderBy>;
  /** ชื่อจังหวัดไทย */
  name_th?: Maybe<OrderBy>;
  /** ภูมิภาค */
  region_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProvinceMinFields = {
  __typename?: 'province_min_fields';
  /** รหัสจังหวัด */
  code?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อจังหวัดอังกฤษ */
  name_en?: Maybe<Scalars['String']>;
  /** ชื่อจังหวัดไทย */
  name_th?: Maybe<Scalars['String']>;
  /** ภูมิภาค */
  region_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "province" */
export type ProvinceMinOrderBy = {
  /** รหัสจังหวัด */
  code?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** ชื่อจังหวัดอังกฤษ */
  name_en?: Maybe<OrderBy>;
  /** ชื่อจังหวัดไทย */
  name_th?: Maybe<OrderBy>;
  /** ภูมิภาค */
  region_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "province" */
export type ProvinceMutationResponse = {
  __typename?: 'province_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Province>;
};

/** input type for inserting object relation for remote table "province" */
export type ProvinceObjRelInsertInput = {
  data: ProvinceInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<ProvinceOnConflict>;
};

/** on conflict condition type for table "province" */
export type ProvinceOnConflict = {
  constraint: ProvinceConstraint;
  update_columns?: Array<ProvinceUpdateColumn>;
  where?: Maybe<ProvinceBoolExp>;
};

/** Ordering options when selecting data from "province". */
export type ProvinceOrderBy = {
  code?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name_en?: Maybe<OrderBy>;
  name_th?: Maybe<OrderBy>;
  project_operating_areas_aggregate?: Maybe<ProjectProjectOperatingAreaAggregateOrderBy>;
  region?: Maybe<RegionOrderBy>;
  region_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: province */
export type ProvincePkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "province" */
export enum ProvinceSelectColumn {
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NameTh = 'name_th',
  /** column name */
  RegionId = 'region_id'
}

/** input type for updating data in table "province" */
export type ProvinceSetInput = {
  /** รหัสจังหวัด */
  code?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อจังหวัดอังกฤษ */
  name_en?: Maybe<Scalars['String']>;
  /** ชื่อจังหวัดไทย */
  name_th?: Maybe<Scalars['String']>;
  /** ภูมิภาค */
  region_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "province" */
export enum ProvinceUpdateColumn {
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NameTh = 'name_th',
  /** column name */
  RegionId = 'region_id'
}

export type QueryRoot = {
  __typename?: 'query_root';
  /** fetch data from the table: "account.account" */
  account_account: Array<AccountAccount>;
  /** fetch aggregated fields from the table: "account.account" */
  account_account_aggregate: AccountAccountAggregate;
  /** fetch data from the table: "account.account_balance" */
  account_account_balance: Array<AccountAccountBalance>;
  /** fetch aggregated fields from the table: "account.account_balance" */
  account_account_balance_aggregate: AccountAccountBalanceAggregate;
  /** fetch data from the table: "account.account_balance_sheet" */
  account_account_balance_sheet: Array<AccountAccountBalanceSheet>;
  /** fetch aggregated fields from the table: "account.account_balance_sheet" */
  account_account_balance_sheet_aggregate: AccountAccountBalanceSheetAggregate;
  /** fetch data from the table: "account.account" using primary key columns */
  account_account_by_pk?: Maybe<AccountAccount>;
  /** fetch data from the table: "account.account_category" */
  account_account_category: Array<AccountAccountCategory>;
  /** fetch aggregated fields from the table: "account.account_category" */
  account_account_category_aggregate: AccountAccountCategoryAggregate;
  /** fetch data from the table: "account.account_category" using primary key columns */
  account_account_category_by_pk?: Maybe<AccountAccountCategory>;
  /** fetch data from the table: "account.account_monthly_report" */
  account_account_monthly_report: Array<AccountAccountMonthlyReport>;
  /** fetch aggregated fields from the table: "account.account_monthly_report" */
  account_account_monthly_report_aggregate: AccountAccountMonthlyReportAggregate;
  /** fetch data from the table: "account.account_type" */
  account_account_type: Array<AccountAccountType>;
  /** fetch aggregated fields from the table: "account.account_type" */
  account_account_type_aggregate: AccountAccountTypeAggregate;
  /** fetch data from the table: "account.account_type" using primary key columns */
  account_account_type_by_pk?: Maybe<AccountAccountType>;
  /** fetch data from the table: "account.budget_account" */
  account_budget_account: Array<AccountBudgetAccount>;
  /** fetch aggregated fields from the table: "account.budget_account" */
  account_budget_account_aggregate: AccountBudgetAccountAggregate;
  /** fetch data from the table: "account.budget_account" using primary key columns */
  account_budget_account_by_pk?: Maybe<AccountBudgetAccount>;
  /** fetch data from the table: "account.budget_allocation" */
  account_budget_allocation: Array<AccountBudgetAllocation>;
  /** fetch aggregated fields from the table: "account.budget_allocation" */
  account_budget_allocation_aggregate: AccountBudgetAllocationAggregate;
  /** fetch data from the table: "account.budget_allocation" using primary key columns */
  account_budget_allocation_by_pk?: Maybe<AccountBudgetAllocation>;
  /** fetch data from the table: "account.budget_allocation_log" */
  account_budget_allocation_log: Array<AccountBudgetAllocationLog>;
  /** fetch aggregated fields from the table: "account.budget_allocation_log" */
  account_budget_allocation_log_aggregate: AccountBudgetAllocationLogAggregate;
  /** fetch data from the table: "account.budget_allocation_log" using primary key columns */
  account_budget_allocation_log_by_pk?: Maybe<AccountBudgetAllocationLog>;
  /** fetch data from the table: "account.budget_type" */
  account_budget_type: Array<AccountBudgetType>;
  /** fetch aggregated fields from the table: "account.budget_type" */
  account_budget_type_aggregate: AccountBudgetTypeAggregate;
  /** fetch data from the table: "account.budget_type" using primary key columns */
  account_budget_type_by_pk?: Maybe<AccountBudgetType>;
  /** fetch data from the table: "account.entry_type" */
  account_entry_type: Array<AccountEntryType>;
  /** fetch aggregated fields from the table: "account.entry_type" */
  account_entry_type_aggregate: AccountEntryTypeAggregate;
  /** fetch data from the table: "account.entry_type" using primary key columns */
  account_entry_type_by_pk?: Maybe<AccountEntryType>;
  /** fetch data from the table: "account.ledger_entry" */
  account_ledger_entry: Array<AccountLedgerEntry>;
  /** fetch aggregated fields from the table: "account.ledger_entry" */
  account_ledger_entry_aggregate: AccountLedgerEntryAggregate;
  /** fetch data from the table: "account.ledger_entry" using primary key columns */
  account_ledger_entry_by_pk?: Maybe<AccountLedgerEntry>;
  /** fetch data from the table: "account.ledger_entry_view" */
  account_ledger_entry_view: Array<AccountLedgerEntryView>;
  /** fetch aggregated fields from the table: "account.ledger_entry_view" */
  account_ledger_entry_view_aggregate: AccountLedgerEntryViewAggregate;
  /** fetch data from the table: "account.lending" */
  account_lending: Array<AccountLending>;
  /** fetch aggregated fields from the table: "account.lending" */
  account_lending_aggregate: AccountLendingAggregate;
  /** fetch data from the table: "account.lending" using primary key columns */
  account_lending_by_pk?: Maybe<AccountLending>;
  /** fetch data from the table: "account.lending_item" */
  account_lending_item: Array<AccountLendingItem>;
  /** fetch aggregated fields from the table: "account.lending_item" */
  account_lending_item_aggregate: AccountLendingItemAggregate;
  /** fetch data from the table: "account.lending_item" using primary key columns */
  account_lending_item_by_pk?: Maybe<AccountLendingItem>;
  /** fetch data from the table: "account.lending_status" */
  account_lending_status: Array<AccountLendingStatus>;
  /** fetch aggregated fields from the table: "account.lending_status" */
  account_lending_status_aggregate: AccountLendingStatusAggregate;
  /** fetch data from the table: "account.lending_status" using primary key columns */
  account_lending_status_by_pk?: Maybe<AccountLendingStatus>;
  /** fetch data from the table: "account.transaction" */
  account_transaction: Array<AccountTransaction>;
  /** fetch aggregated fields from the table: "account.transaction" */
  account_transaction_aggregate: AccountTransactionAggregate;
  /** fetch data from the table: "account.transaction" using primary key columns */
  account_transaction_by_pk?: Maybe<AccountTransaction>;
  /** fetch data from the table: "account.transaction_log" */
  account_transaction_log: Array<AccountTransactionLog>;
  /** fetch aggregated fields from the table: "account.transaction_log" */
  account_transaction_log_aggregate: AccountTransactionLogAggregate;
  /** fetch data from the table: "account.transaction_log" using primary key columns */
  account_transaction_log_by_pk?: Maybe<AccountTransactionLog>;
  /** fetch data from the table: "account.transaction_status" */
  account_transaction_status: Array<AccountTransactionStatus>;
  /** fetch aggregated fields from the table: "account.transaction_status" */
  account_transaction_status_aggregate: AccountTransactionStatusAggregate;
  /** fetch data from the table: "account.transaction_status" using primary key columns */
  account_transaction_status_by_pk?: Maybe<AccountTransactionStatus>;
  /** fetch data from the table: "account.transaction_type" */
  account_transaction_type: Array<AccountTransactionType>;
  /** fetch aggregated fields from the table: "account.transaction_type" */
  account_transaction_type_aggregate: AccountTransactionTypeAggregate;
  /** fetch data from the table: "account.transaction_type" using primary key columns */
  account_transaction_type_by_pk?: Maybe<AccountTransactionType>;
  /** fetch data from the table: "account.trial_balance_monthly" */
  account_trial_balance_monthly: Array<AccountTrialBalanceMonthly>;
  /** fetch aggregated fields from the table: "account.trial_balance_monthly" */
  account_trial_balance_monthly_aggregate: AccountTrialBalanceMonthlyAggregate;
  app_hello: HelloOutput;
  /** fetch data from the table: "attachment" */
  attachment: Array<Attachment>;
  /** fetch aggregated fields from the table: "attachment" */
  attachment_aggregate: AttachmentAggregate;
  /** fetch data from the table: "attachment" using primary key columns */
  attachment_by_pk?: Maybe<Attachment>;
  /** fetch data from the table: "audit.logged_actions" */
  audit_logged_actions: Array<AuditLoggedActions>;
  /** fetch aggregated fields from the table: "audit.logged_actions" */
  audit_logged_actions_aggregate: AuditLoggedActionsAggregate;
  /** fetch data from the table: "audit.logged_actions" using primary key columns */
  audit_logged_actions_by_pk?: Maybe<AuditLoggedActions>;
  /** fetch data from the table: "auth.account_providers" */
  auth_account_providers: Array<AuthAccountProviders>;
  /** fetch aggregated fields from the table: "auth.account_providers" */
  auth_account_providers_aggregate: AuthAccountProvidersAggregate;
  /** fetch data from the table: "auth.account_providers" using primary key columns */
  auth_account_providers_by_pk?: Maybe<AuthAccountProviders>;
  /** fetch data from the table: "auth.account_roles" */
  auth_account_roles: Array<AuthAccountRoles>;
  /** fetch aggregated fields from the table: "auth.account_roles" */
  auth_account_roles_aggregate: AuthAccountRolesAggregate;
  /** fetch data from the table: "auth.account_roles" using primary key columns */
  auth_account_roles_by_pk?: Maybe<AuthAccountRoles>;
  /** fetch data from the table: "auth.accounts" */
  auth_accounts: Array<AuthAccounts>;
  /** fetch aggregated fields from the table: "auth.accounts" */
  auth_accounts_aggregate: AuthAccountsAggregate;
  /** fetch data from the table: "auth.accounts" using primary key columns */
  auth_accounts_by_pk?: Maybe<AuthAccounts>;
  /** fetch data from the table: "auth.providers" */
  auth_providers: Array<AuthProviders>;
  /** fetch aggregated fields from the table: "auth.providers" */
  auth_providers_aggregate: AuthProvidersAggregate;
  /** fetch data from the table: "auth.providers" using primary key columns */
  auth_providers_by_pk?: Maybe<AuthProviders>;
  /** fetch data from the table: "auth.refresh_tokens" */
  auth_refresh_tokens: Array<AuthRefreshTokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  auth_refresh_tokens_aggregate: AuthRefreshTokensAggregate;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  auth_refresh_tokens_by_pk?: Maybe<AuthRefreshTokens>;
  /** fetch data from the table: "auth.roles" */
  auth_roles: Array<AuthRoles>;
  /** fetch aggregated fields from the table: "auth.roles" */
  auth_roles_aggregate: AuthRolesAggregate;
  /** fetch data from the table: "auth.roles" using primary key columns */
  auth_roles_by_pk?: Maybe<AuthRoles>;
  /** fetch data from the table: "bank_branch" */
  bank_branch: Array<BankBranch>;
  /** fetch aggregated fields from the table: "bank_branch" */
  bank_branch_aggregate: BankBranchAggregate;
  /** fetch data from the table: "bank_branch" using primary key columns */
  bank_branch_by_pk?: Maybe<BankBranch>;
  /** fetch data from the table: "contract.af_status" */
  contract_af_status: Array<ContractAfStatus>;
  /** fetch aggregated fields from the table: "contract.af_status" */
  contract_af_status_aggregate: ContractAfStatusAggregate;
  /** fetch data from the table: "contract.af_status" using primary key columns */
  contract_af_status_by_pk?: Maybe<ContractAfStatus>;
  /** fetch data from the table: "contract.agreement" */
  contract_agreement: Array<ContractAgreement>;
  /** fetch data from the table: "contract.agreement_af_status_count" */
  contract_agreement_af_status_count: Array<ContractAgreementAfStatusCount>;
  /** fetch aggregated fields from the table: "contract.agreement_af_status_count" */
  contract_agreement_af_status_count_aggregate: ContractAgreementAfStatusCountAggregate;
  /** fetch aggregated fields from the table: "contract.agreement" */
  contract_agreement_aggregate: ContractAgreementAggregate;
  /** fetch data from the table: "contract.agreement_attachment" */
  contract_agreement_attachment: Array<ContractAgreementAttachment>;
  /** fetch aggregated fields from the table: "contract.agreement_attachment" */
  contract_agreement_attachment_aggregate: ContractAgreementAttachmentAggregate;
  /** fetch data from the table: "contract.agreement_attachment" using primary key columns */
  contract_agreement_attachment_by_pk?: Maybe<ContractAgreementAttachment>;
  /** fetch data from the table: "contract.agreement" using primary key columns */
  contract_agreement_by_pk?: Maybe<ContractAgreement>;
  /** fetch data from the table: "contract.agreement_detail" */
  contract_agreement_detail: Array<ContractAgreementDetail>;
  /** fetch aggregated fields from the table: "contract.agreement_detail" */
  contract_agreement_detail_aggregate: ContractAgreementDetailAggregate;
  /** fetch data from the table: "contract.agreement_detail" using primary key columns */
  contract_agreement_detail_by_pk?: Maybe<ContractAgreementDetail>;
  /** fetch data from the table: "contract.agreement_disbursement" */
  contract_agreement_disbursement: Array<ContractAgreementDisbursement>;
  /** fetch aggregated fields from the table: "contract.agreement_disbursement" */
  contract_agreement_disbursement_aggregate: ContractAgreementDisbursementAggregate;
  /** fetch data from the table: "contract.agreement_disbursement" using primary key columns */
  contract_agreement_disbursement_by_pk?: Maybe<ContractAgreementDisbursement>;
  /** fetch data from the table: "contract.agreement_fee_charge" */
  contract_agreement_fee_charge: Array<ContractAgreementFeeCharge>;
  /** fetch aggregated fields from the table: "contract.agreement_fee_charge" */
  contract_agreement_fee_charge_aggregate: ContractAgreementFeeChargeAggregate;
  /** fetch data from the table: "contract.agreement_fee_charge" using primary key columns */
  contract_agreement_fee_charge_by_pk?: Maybe<ContractAgreementFeeCharge>;
  /** fetch data from the table: "contract.agreement_guarantor" */
  contract_agreement_guarantor: Array<ContractAgreementGuarantor>;
  /** fetch aggregated fields from the table: "contract.agreement_guarantor" */
  contract_agreement_guarantor_aggregate: ContractAgreementGuarantorAggregate;
  /** fetch data from the table: "contract.agreement_guarantor" using primary key columns */
  contract_agreement_guarantor_by_pk?: Maybe<ContractAgreementGuarantor>;
  /** fetch data from the table: "contract.agreement_receipt" */
  contract_agreement_receipt: Array<ContractAgreementReceipt>;
  /** fetch aggregated fields from the table: "contract.agreement_receipt" */
  contract_agreement_receipt_aggregate: ContractAgreementReceiptAggregate;
  /** fetch data from the table: "contract.agreement_receipt" using primary key columns */
  contract_agreement_receipt_by_pk?: Maybe<ContractAgreementReceipt>;
  /** fetch data from the table: "contract.agreement_repay_current_month" */
  contract_agreement_repay_current_month: Array<ContractAgreementRepayCurrentMonth>;
  /** fetch aggregated fields from the table: "contract.agreement_repay_current_month" */
  contract_agreement_repay_current_month_aggregate: ContractAgreementRepayCurrentMonthAggregate;
  /** fetch data from the table: "contract.agreement_status" */
  contract_agreement_status: Array<ContractAgreementStatus>;
  /** fetch aggregated fields from the table: "contract.agreement_status" */
  contract_agreement_status_aggregate: ContractAgreementStatusAggregate;
  /** fetch data from the table: "contract.agreement_status" using primary key columns */
  contract_agreement_status_by_pk?: Maybe<ContractAgreementStatus>;
  /** fetch data from the table: "contract.agreement_status_mapper" */
  contract_agreement_status_mapper: Array<ContractAgreementStatusMapper>;
  /** fetch aggregated fields from the table: "contract.agreement_status_mapper" */
  contract_agreement_status_mapper_aggregate: ContractAgreementStatusMapperAggregate;
  /** fetch data from the table: "contract.agreement_status_mapper" using primary key columns */
  contract_agreement_status_mapper_by_pk?: Maybe<ContractAgreementStatusMapper>;
  /** fetch data from the table: "contract.agreement_transaction" */
  contract_agreement_transaction: Array<ContractAgreementTransaction>;
  /** fetch aggregated fields from the table: "contract.agreement_transaction" */
  contract_agreement_transaction_aggregate: ContractAgreementTransactionAggregate;
  /** fetch data from the table: "contract.agreement_transaction" using primary key columns */
  contract_agreement_transaction_by_pk?: Maybe<ContractAgreementTransaction>;
  /** fetch data from the table: "contract.agreement_withdraw" */
  contract_agreement_withdraw: Array<ContractAgreementWithdraw>;
  /** fetch aggregated fields from the table: "contract.agreement_withdraw" */
  contract_agreement_withdraw_aggregate: ContractAgreementWithdrawAggregate;
  /** fetch data from the table: "contract.agreement_withdraw" using primary key columns */
  contract_agreement_withdraw_by_pk?: Maybe<ContractAgreementWithdraw>;
  /** fetch data from the table: "contract.attachment_type" */
  contract_attachment_type: Array<ContractAttachmentType>;
  /** fetch aggregated fields from the table: "contract.attachment_type" */
  contract_attachment_type_aggregate: ContractAttachmentTypeAggregate;
  /** fetch data from the table: "contract.attachment_type" using primary key columns */
  contract_attachment_type_by_pk?: Maybe<ContractAttachmentType>;
  /** fetch data from the table: "contract.grade" */
  contract_grade: Array<ContractGrade>;
  /** fetch aggregated fields from the table: "contract.grade" */
  contract_grade_aggregate: ContractGradeAggregate;
  /** fetch data from the table: "contract.grade" using primary key columns */
  contract_grade_by_pk?: Maybe<ContractGrade>;
  /** fetch data from the table: "contract.transaction_creator_type" */
  contract_transaction_creator_type: Array<ContractTransactionCreatorType>;
  /** fetch aggregated fields from the table: "contract.transaction_creator_type" */
  contract_transaction_creator_type_aggregate: ContractTransactionCreatorTypeAggregate;
  /** fetch data from the table: "contract.transaction_creator_type" using primary key columns */
  contract_transaction_creator_type_by_pk?: Maybe<ContractTransactionCreatorType>;
  /** fetch data from the table: "current_user_permissions" */
  current_user_permissions: Array<CurrentUserPermissions>;
  /** fetch aggregated fields from the table: "current_user_permissions" */
  current_user_permissions_aggregate: CurrentUserPermissionsAggregate;
  /** fetch data from the table: "department" */
  department: Array<Department>;
  /** fetch aggregated fields from the table: "department" */
  department_aggregate: DepartmentAggregate;
  /** fetch data from the table: "department" using primary key columns */
  department_by_pk?: Maybe<Department>;
  /** fetch data from the table: "feature_toggle" */
  feature_toggle: Array<FeatureToggle>;
  /** fetch aggregated fields from the table: "feature_toggle" */
  feature_toggle_aggregate: FeatureToggleAggregate;
  /** fetch data from the table: "feature_toggle" using primary key columns */
  feature_toggle_by_pk?: Maybe<FeatureToggle>;
  /** fetch data from the table: "fiscal_year" */
  fiscal_year: Array<FiscalYear>;
  /** fetch aggregated fields from the table: "fiscal_year" */
  fiscal_year_aggregate: FiscalYearAggregate;
  /** fetch data from the table: "fiscal_year" using primary key columns */
  fiscal_year_by_pk?: Maybe<FiscalYear>;
  getJuristicDetailById?: Maybe<JuristicDetailOutput>;
  /** fetch data from the table: "import_log" */
  import_log: Array<ImportLog>;
  /** fetch aggregated fields from the table: "import_log" */
  import_log_aggregate: ImportLogAggregate;
  /** fetch data from the table: "import_log" using primary key columns */
  import_log_by_pk?: Maybe<ImportLog>;
  /** fetch data from the table: "juristic" */
  juristic: Array<Juristic>;
  /** fetch data from the table: "juristic_address" */
  juristic_address: Array<JuristicAddress>;
  /** fetch aggregated fields from the table: "juristic_address" */
  juristic_address_aggregate: JuristicAddressAggregate;
  /** fetch data from the table: "juristic_address" using primary key columns */
  juristic_address_by_pk?: Maybe<JuristicAddress>;
  /** fetch aggregated fields from the table: "juristic" */
  juristic_aggregate: JuristicAggregate;
  /** fetch data from the table: "juristic" using primary key columns */
  juristic_by_pk?: Maybe<Juristic>;
  /** fetch data from the table: "juristic_committee" */
  juristic_committee: Array<JuristicCommittee>;
  /** fetch aggregated fields from the table: "juristic_committee" */
  juristic_committee_aggregate: JuristicCommitteeAggregate;
  /** fetch data from the table: "juristic_committee" using primary key columns */
  juristic_committee_by_pk?: Maybe<JuristicCommittee>;
  /** fetch data from the table: "juristic_standard_objective" */
  juristic_standard_objective: Array<JuristicStandardObjective>;
  /** fetch aggregated fields from the table: "juristic_standard_objective" */
  juristic_standard_objective_aggregate: JuristicStandardObjectiveAggregate;
  /** fetch data from the table: "juristic_standard_objective" using primary key columns */
  juristic_standard_objective_by_pk?: Maybe<JuristicStandardObjective>;
  /** fetch data from the table: "ledger.account_budget_balance" */
  ledger_account_budget_balance: Array<LedgerAccountBudgetBalance>;
  /** fetch aggregated fields from the table: "ledger.account_budget_balance" */
  ledger_account_budget_balance_aggregate: LedgerAccountBudgetBalanceAggregate;
  /** fetch data from the table: "ledger.account_budget_balance_debt" */
  ledger_account_budget_balance_debt: Array<LedgerAccountBudgetBalanceDebt>;
  /** fetch aggregated fields from the table: "ledger.account_budget_balance_debt" */
  ledger_account_budget_balance_debt_aggregate: LedgerAccountBudgetBalanceDebtAggregate;
  /** fetch data from the table: "ledger.account_budget_balance_expense" */
  ledger_account_budget_balance_expense: Array<LedgerAccountBudgetBalanceExpense>;
  /** fetch aggregated fields from the table: "ledger.account_budget_balance_expense" */
  ledger_account_budget_balance_expense_aggregate: LedgerAccountBudgetBalanceExpenseAggregate;
  /** fetch data from the table: "ledger.account_budget_monthly" */
  ledger_account_budget_monthly: Array<LedgerAccountBudgetMonthly>;
  /** fetch aggregated fields from the table: "ledger.account_budget_monthly" */
  ledger_account_budget_monthly_aggregate: LedgerAccountBudgetMonthlyAggregate;
  /** fetch data from the table: "ledger.budget_allocation" */
  ledger_budget_allocation: Array<LedgerBudgetAllocation>;
  /** fetch aggregated fields from the table: "ledger.budget_allocation" */
  ledger_budget_allocation_aggregate: LedgerBudgetAllocationAggregate;
  /** fetch data from the table: "ledger.budget_allocation" using primary key columns */
  ledger_budget_allocation_by_pk?: Maybe<LedgerBudgetAllocation>;
  /** fetch data from the table: "ledger.ledger" */
  ledger_ledger: Array<LedgerLedger>;
  /** fetch aggregated fields from the table: "ledger.ledger" */
  ledger_ledger_aggregate: LedgerLedgerAggregate;
  /** fetch data from the table: "ledger.ledger_attachment" */
  ledger_ledger_attachment: Array<LedgerLedgerAttachment>;
  /** fetch aggregated fields from the table: "ledger.ledger_attachment" */
  ledger_ledger_attachment_aggregate: LedgerLedgerAttachmentAggregate;
  /** fetch data from the table: "ledger.ledger_attachment" using primary key columns */
  ledger_ledger_attachment_by_pk?: Maybe<LedgerLedgerAttachment>;
  /** fetch data from the table: "ledger.ledger" using primary key columns */
  ledger_ledger_by_pk?: Maybe<LedgerLedger>;
  /** fetch data from the table: "ledger.ledger_log" */
  ledger_ledger_log: Array<LedgerLedgerLog>;
  /** fetch aggregated fields from the table: "ledger.ledger_log" */
  ledger_ledger_log_aggregate: LedgerLedgerLogAggregate;
  /** fetch data from the table: "ledger.ledger_log" using primary key columns */
  ledger_ledger_log_by_pk?: Maybe<LedgerLedgerLog>;
  /** fetch data from the table: "ledger.ledger_status" */
  ledger_ledger_status: Array<LedgerLedgerStatus>;
  /** fetch aggregated fields from the table: "ledger.ledger_status" */
  ledger_ledger_status_aggregate: LedgerLedgerStatusAggregate;
  /** fetch data from the table: "ledger.ledger_status" using primary key columns */
  ledger_ledger_status_by_pk?: Maybe<LedgerLedgerStatus>;
  /** fetch data from the table: "ledger.ledger_type" */
  ledger_ledger_type: Array<LedgerLedgerType>;
  /** fetch aggregated fields from the table: "ledger.ledger_type" */
  ledger_ledger_type_aggregate: LedgerLedgerTypeAggregate;
  /** fetch data from the table: "ledger.ledger_type" using primary key columns */
  ledger_ledger_type_by_pk?: Maybe<LedgerLedgerType>;
  /** fetch data from the table: "ledger.receivable" */
  ledger_receivable: Array<LedgerReceivable>;
  /** fetch aggregated fields from the table: "ledger.receivable" */
  ledger_receivable_aggregate: LedgerReceivableAggregate;
  /** fetch data from the table: "ledger.receivable" using primary key columns */
  ledger_receivable_by_pk?: Maybe<LedgerReceivable>;
  /** fetch data from the table: "ledger.receivable_item" */
  ledger_receivable_item: Array<LedgerReceivableItem>;
  /** fetch aggregated fields from the table: "ledger.receivable_item" */
  ledger_receivable_item_aggregate: LedgerReceivableItemAggregate;
  /** fetch data from the table: "ledger.receivable_item" using primary key columns */
  ledger_receivable_item_by_pk?: Maybe<LedgerReceivableItem>;
  /** fetch data from the table: "ledger.receivable_log" */
  ledger_receivable_log: Array<LedgerReceivableLog>;
  /** fetch aggregated fields from the table: "ledger.receivable_log" */
  ledger_receivable_log_aggregate: LedgerReceivableLogAggregate;
  /** fetch data from the table: "ledger.receivable_log" using primary key columns */
  ledger_receivable_log_by_pk?: Maybe<LedgerReceivableLog>;
  /** fetch data from the table: "ledger.receivable_status" */
  ledger_receivable_status: Array<LedgerReceivableStatus>;
  /** fetch aggregated fields from the table: "ledger.receivable_status" */
  ledger_receivable_status_aggregate: LedgerReceivableStatusAggregate;
  /** fetch data from the table: "ledger.receivable_status" using primary key columns */
  ledger_receivable_status_by_pk?: Maybe<LedgerReceivableStatus>;
  /** fetch data from the table: "ledger.total_report_monthly" */
  ledger_total_report_monthly: Array<LedgerTotalReportMonthly>;
  /** fetch aggregated fields from the table: "ledger.total_report_monthly" */
  ledger_total_report_monthly_aggregate: LedgerTotalReportMonthlyAggregate;
  /** fetch data from the table: "ledger.total_report_yearly" */
  ledger_total_report_yearly: Array<LedgerTotalReportYearly>;
  /** fetch aggregated fields from the table: "ledger.total_report_yearly" */
  ledger_total_report_yearly_aggregate: LedgerTotalReportYearlyAggregate;
  /** fetch data from the table: "loan.account_type" */
  loan_account_type: Array<LoanAccountType>;
  /** fetch aggregated fields from the table: "loan.account_type" */
  loan_account_type_aggregate: LoanAccountTypeAggregate;
  /** fetch data from the table: "loan.account_type" using primary key columns */
  loan_account_type_by_pk?: Maybe<LoanAccountType>;
  /** fetch data from the table: "loan.application_attachment" */
  loan_application_attachment: Array<LoanApplicationAttachment>;
  /** fetch aggregated fields from the table: "loan.application_attachment" */
  loan_application_attachment_aggregate: LoanApplicationAttachmentAggregate;
  /** fetch data from the table: "loan.application_attachment" using primary key columns */
  loan_application_attachment_by_pk?: Maybe<LoanApplicationAttachment>;
  /** fetch data from the table: "loan.application_bank_branch_transfer" */
  loan_application_bank_branch_transfer: Array<LoanApplicationBankBranchTransfer>;
  /** fetch aggregated fields from the table: "loan.application_bank_branch_transfer" */
  loan_application_bank_branch_transfer_aggregate: LoanApplicationBankBranchTransferAggregate;
  /** fetch data from the table: "loan.application_bank_branch_transfer" using primary key columns */
  loan_application_bank_branch_transfer_by_pk?: Maybe<LoanApplicationBankBranchTransfer>;
  /** fetch data from the table: "loan.application_business_operation" */
  loan_application_business_operation: Array<LoanApplicationBusinessOperation>;
  /** fetch aggregated fields from the table: "loan.application_business_operation" */
  loan_application_business_operation_aggregate: LoanApplicationBusinessOperationAggregate;
  /** fetch data from the table: "loan.application_business_operation" using primary key columns */
  loan_application_business_operation_by_pk?: Maybe<LoanApplicationBusinessOperation>;
  /** fetch data from the table: "loan.application_committee" */
  loan_application_committee: Array<LoanApplicationCommittee>;
  /** fetch aggregated fields from the table: "loan.application_committee" */
  loan_application_committee_aggregate: LoanApplicationCommitteeAggregate;
  /** fetch data from the table: "loan.application_committee" using primary key columns */
  loan_application_committee_by_pk?: Maybe<LoanApplicationCommittee>;
  /** fetch data from the table: "loan.application_current_loan" */
  loan_application_current_loan: Array<LoanApplicationCurrentLoan>;
  /** fetch aggregated fields from the table: "loan.application_current_loan" */
  loan_application_current_loan_aggregate: LoanApplicationCurrentLoanAggregate;
  /** fetch data from the table: "loan.application_current_loan" using primary key columns */
  loan_application_current_loan_by_pk?: Maybe<LoanApplicationCurrentLoan>;
  /** fetch data from the table: "loan.application_detail" */
  loan_application_detail: Array<LoanApplicationDetail>;
  /** fetch aggregated fields from the table: "loan.application_detail" */
  loan_application_detail_aggregate: LoanApplicationDetailAggregate;
  /** fetch data from the table: "loan.application_detail" using primary key columns */
  loan_application_detail_by_pk?: Maybe<LoanApplicationDetail>;
  /** fetch data from the table: "loan.application_event_log" */
  loan_application_event_log: Array<LoanApplicationEventLog>;
  /** fetch aggregated fields from the table: "loan.application_event_log" */
  loan_application_event_log_aggregate: LoanApplicationEventLogAggregate;
  /** fetch data from the table: "loan.application_event_log" using primary key columns */
  loan_application_event_log_by_pk?: Maybe<LoanApplicationEventLog>;
  /** fetch data from the table: "loan.application_header" */
  loan_application_header: Array<LoanApplicationHeader>;
  /** fetch aggregated fields from the table: "loan.application_header" */
  loan_application_header_aggregate: LoanApplicationHeaderAggregate;
  /** fetch data from the table: "loan.application_header" using primary key columns */
  loan_application_header_by_pk?: Maybe<LoanApplicationHeader>;
  /** fetch data from the table: "loan.application_header_pdf" */
  loan_application_header_pdf: Array<LoanApplicationHeaderPdf>;
  /** fetch aggregated fields from the table: "loan.application_header_pdf" */
  loan_application_header_pdf_aggregate: LoanApplicationHeaderPdfAggregate;
  /** fetch data from the table: "loan.application_header_pdf" using primary key columns */
  loan_application_header_pdf_by_pk?: Maybe<LoanApplicationHeaderPdf>;
  /** fetch data from the table: "loan.application_objective" */
  loan_application_objective: Array<LoanApplicationObjective>;
  /** fetch aggregated fields from the table: "loan.application_objective" */
  loan_application_objective_aggregate: LoanApplicationObjectiveAggregate;
  /** fetch data from the table: "loan.application_objective" using primary key columns */
  loan_application_objective_by_pk?: Maybe<LoanApplicationObjective>;
  /** fetch data from the table: "loan.application_organization_transfer" */
  loan_application_organization_transfer: Array<LoanApplicationOrganizationTransfer>;
  /** fetch aggregated fields from the table: "loan.application_organization_transfer" */
  loan_application_organization_transfer_aggregate: LoanApplicationOrganizationTransferAggregate;
  /** fetch data from the table: "loan.application_organization_transfer" using primary key columns */
  loan_application_organization_transfer_by_pk?: Maybe<LoanApplicationOrganizationTransfer>;
  /** fetch data from the table: "loan.application_profile" */
  loan_application_profile: Array<LoanApplicationProfile>;
  /** fetch aggregated fields from the table: "loan.application_profile" */
  loan_application_profile_aggregate: LoanApplicationProfileAggregate;
  /** fetch data from the table: "loan.application_profile" using primary key columns */
  loan_application_profile_by_pk?: Maybe<LoanApplicationProfile>;
  /** fetch data from the table: "loan.application_profile_s_curve" */
  loan_application_profile_s_curve: Array<LoanApplicationProfileSCurve>;
  /** fetch aggregated fields from the table: "loan.application_profile_s_curve" */
  loan_application_profile_s_curve_aggregate: LoanApplicationProfileSCurveAggregate;
  /** fetch data from the table: "loan.application_profile_s_curve" using primary key columns */
  loan_application_profile_s_curve_by_pk?: Maybe<LoanApplicationProfileSCurve>;
  /** fetch data from the table: "loan.application_sequence_no" */
  loan_application_sequence_no: Array<LoanApplicationSequenceNo>;
  /** fetch aggregated fields from the table: "loan.application_sequence_no" */
  loan_application_sequence_no_aggregate: LoanApplicationSequenceNoAggregate;
  /** fetch data from the table: "loan.application_sequence_no" using primary key columns */
  loan_application_sequence_no_by_pk?: Maybe<LoanApplicationSequenceNo>;
  /** fetch data from the table: "loan.application_step" */
  loan_application_step: Array<LoanApplicationStep>;
  /** fetch aggregated fields from the table: "loan.application_step" */
  loan_application_step_aggregate: LoanApplicationStepAggregate;
  /** fetch data from the table: "loan.application_step" using primary key columns */
  loan_application_step_by_pk?: Maybe<LoanApplicationStep>;
  /** fetch data from the table: "loan.attachment_type" */
  loan_attachment_type: Array<LoanAttachmentType>;
  /** fetch aggregated fields from the table: "loan.attachment_type" */
  loan_attachment_type_aggregate: LoanAttachmentTypeAggregate;
  /** fetch data from the table: "loan.attachment_type" using primary key columns */
  loan_attachment_type_by_pk?: Maybe<LoanAttachmentType>;
  /** fetch data from the table: "loan.bank_approve" */
  loan_bank_approve: Array<LoanBankApprove>;
  /** fetch aggregated fields from the table: "loan.bank_approve" */
  loan_bank_approve_aggregate: LoanBankApproveAggregate;
  /** fetch data from the table: "loan.bank_approve" using primary key columns */
  loan_bank_approve_by_pk?: Maybe<LoanBankApprove>;
  /** fetch data from the table: "loan.business_group" */
  loan_business_group: Array<LoanBusinessGroup>;
  /** fetch aggregated fields from the table: "loan.business_group" */
  loan_business_group_aggregate: LoanBusinessGroupAggregate;
  /** fetch data from the table: "loan.business_group" using primary key columns */
  loan_business_group_by_pk?: Maybe<LoanBusinessGroup>;
  /** fetch data from the table: "loan.business_operation" */
  loan_business_operation: Array<LoanBusinessOperation>;
  /** fetch aggregated fields from the table: "loan.business_operation" */
  loan_business_operation_aggregate: LoanBusinessOperationAggregate;
  /** fetch data from the table: "loan.business_operation" using primary key columns */
  loan_business_operation_by_pk?: Maybe<LoanBusinessOperation>;
  /** fetch data from the table: "loan.business_proprietary" */
  loan_business_proprietary: Array<LoanBusinessProprietary>;
  /** fetch aggregated fields from the table: "loan.business_proprietary" */
  loan_business_proprietary_aggregate: LoanBusinessProprietaryAggregate;
  /** fetch data from the table: "loan.business_proprietary" using primary key columns */
  loan_business_proprietary_by_pk?: Maybe<LoanBusinessProprietary>;
  /** fetch data from the table: "loan.business_status" */
  loan_business_status: Array<LoanBusinessStatus>;
  /** fetch aggregated fields from the table: "loan.business_status" */
  loan_business_status_aggregate: LoanBusinessStatusAggregate;
  /** fetch data from the table: "loan.business_status" using primary key columns */
  loan_business_status_by_pk?: Maybe<LoanBusinessStatus>;
  /** fetch data from the table: "loan.business_type" */
  loan_business_type: Array<LoanBusinessType>;
  /** fetch aggregated fields from the table: "loan.business_type" */
  loan_business_type_aggregate: LoanBusinessTypeAggregate;
  /** fetch data from the table: "loan.business_type" using primary key columns */
  loan_business_type_by_pk?: Maybe<LoanBusinessType>;
  /** fetch data from the table: "loan.committee_approve" */
  loan_committee_approve: Array<LoanCommitteeApprove>;
  /** fetch aggregated fields from the table: "loan.committee_approve" */
  loan_committee_approve_aggregate: LoanCommitteeApproveAggregate;
  /** fetch data from the table: "loan.committee_approve" using primary key columns */
  loan_committee_approve_by_pk?: Maybe<LoanCommitteeApprove>;
  /** fetch data from the table: "loan.current_loan" */
  loan_current_loan: Array<LoanCurrentLoan>;
  /** fetch aggregated fields from the table: "loan.current_loan" */
  loan_current_loan_aggregate: LoanCurrentLoanAggregate;
  /** fetch data from the table: "loan.current_loan" using primary key columns */
  loan_current_loan_by_pk?: Maybe<LoanCurrentLoan>;
  /** fetch data from the table: "loan.debt_restructuring" */
  loan_debt_restructuring: Array<LoanDebtRestructuring>;
  /** fetch aggregated fields from the table: "loan.debt_restructuring" */
  loan_debt_restructuring_aggregate: LoanDebtRestructuringAggregate;
  /** fetch data from the table: "loan.debt_restructuring" using primary key columns */
  loan_debt_restructuring_by_pk?: Maybe<LoanDebtRestructuring>;
  /** fetch data from the table: "loan.document_approve" */
  loan_document_approve: Array<LoanDocumentApprove>;
  /** fetch aggregated fields from the table: "loan.document_approve" */
  loan_document_approve_aggregate: LoanDocumentApproveAggregate;
  /** fetch data from the table: "loan.document_approve" using primary key columns */
  loan_document_approve_by_pk?: Maybe<LoanDocumentApprove>;
  /** fetch data from the table: "loan.financial_agreement" */
  loan_financial_agreement: Array<LoanFinancialAgreement>;
  /** fetch aggregated fields from the table: "loan.financial_agreement" */
  loan_financial_agreement_aggregate: LoanFinancialAgreementAggregate;
  /** fetch data from the table: "loan.financial_agreement" using primary key columns */
  loan_financial_agreement_by_pk?: Maybe<LoanFinancialAgreement>;
  /** fetch data from the table: "loan.financial_rescue" */
  loan_financial_rescue: Array<LoanFinancialRescue>;
  /** fetch aggregated fields from the table: "loan.financial_rescue" */
  loan_financial_rescue_aggregate: LoanFinancialRescueAggregate;
  /** fetch data from the table: "loan.financial_rescue" using primary key columns */
  loan_financial_rescue_by_pk?: Maybe<LoanFinancialRescue>;
  /** fetch data from the table: "loan.objective" */
  loan_objective: Array<LoanObjective>;
  /** fetch aggregated fields from the table: "loan.objective" */
  loan_objective_aggregate: LoanObjectiveAggregate;
  /** fetch data from the table: "loan.objective" using primary key columns */
  loan_objective_by_pk?: Maybe<LoanObjective>;
  /** fetch data from the table: "loan.program" */
  loan_program: Array<LoanProgram>;
  /** fetch aggregated fields from the table: "loan.program" */
  loan_program_aggregate: LoanProgramAggregate;
  /** fetch data from the table: "loan.program" using primary key columns */
  loan_program_by_pk?: Maybe<LoanProgram>;
  /** fetch data from the table: "loan.program_status" */
  loan_program_status: Array<LoanProgramStatus>;
  /** fetch aggregated fields from the table: "loan.program_status" */
  loan_program_status_aggregate: LoanProgramStatusAggregate;
  /** fetch data from the table: "loan.program_status" using primary key columns */
  loan_program_status_by_pk?: Maybe<LoanProgramStatus>;
  /** fetch data from the table: "loan.program_transaction" */
  loan_program_transaction: Array<LoanProgramTransaction>;
  /** fetch aggregated fields from the table: "loan.program_transaction" */
  loan_program_transaction_aggregate: LoanProgramTransactionAggregate;
  /** fetch data from the table: "loan.program_transaction" using primary key columns */
  loan_program_transaction_by_pk?: Maybe<LoanProgramTransaction>;
  /** fetch data from the table: "loan.program_transaction_type" */
  loan_program_transaction_type: Array<LoanProgramTransactionType>;
  /** fetch aggregated fields from the table: "loan.program_transaction_type" */
  loan_program_transaction_type_aggregate: LoanProgramTransactionTypeAggregate;
  /** fetch data from the table: "loan.program_transaction_type" using primary key columns */
  loan_program_transaction_type_by_pk?: Maybe<LoanProgramTransactionType>;
  /** fetch data from the table: "loan.proprietary" */
  loan_proprietary: Array<LoanProprietary>;
  /** fetch aggregated fields from the table: "loan.proprietary" */
  loan_proprietary_aggregate: LoanProprietaryAggregate;
  /** fetch data from the table: "loan.proprietary" using primary key columns */
  loan_proprietary_by_pk?: Maybe<LoanProprietary>;
  /** fetch data from the table: "loan.repay_type" */
  loan_repay_type: Array<LoanRepayType>;
  /** fetch aggregated fields from the table: "loan.repay_type" */
  loan_repay_type_aggregate: LoanRepayTypeAggregate;
  /** fetch data from the table: "loan.repay_type" using primary key columns */
  loan_repay_type_by_pk?: Maybe<LoanRepayType>;
  /** fetch data from the table: "loan.s_curve" */
  loan_s_curve: Array<LoanSCurve>;
  /** fetch aggregated fields from the table: "loan.s_curve" */
  loan_s_curve_aggregate: LoanSCurveAggregate;
  /** fetch data from the table: "loan.s_curve" using primary key columns */
  loan_s_curve_by_pk?: Maybe<LoanSCurve>;
  /** fetch data from the table: "loan.s_curve_type" */
  loan_s_curve_type: Array<LoanSCurveType>;
  /** fetch aggregated fields from the table: "loan.s_curve_type" */
  loan_s_curve_type_aggregate: LoanSCurveTypeAggregate;
  /** fetch data from the table: "loan.s_curve_type" using primary key columns */
  loan_s_curve_type_by_pk?: Maybe<LoanSCurveType>;
  /** fetch data from the table: "loan.sme_definition" */
  loan_sme_definition: Array<LoanSmeDefinition>;
  /** fetch aggregated fields from the table: "loan.sme_definition" */
  loan_sme_definition_aggregate: LoanSmeDefinitionAggregate;
  /** fetch data from the table: "loan.sme_definition" using primary key columns */
  loan_sme_definition_by_pk?: Maybe<LoanSmeDefinition>;
  /** fetch data from the table: "loan_source.business_type" */
  loan_source_business_type: Array<LoanSourceBusinessType>;
  /** fetch aggregated fields from the table: "loan_source.business_type" */
  loan_source_business_type_aggregate: LoanSourceBusinessTypeAggregate;
  /** fetch data from the table: "loan_source.business_type" using primary key columns */
  loan_source_business_type_by_pk?: Maybe<LoanSourceBusinessType>;
  /** fetch data from the table: "loan_source.financial_institution" */
  loan_source_financial_institution: Array<LoanSourceFinancialInstitution>;
  /** fetch aggregated fields from the table: "loan_source.financial_institution" */
  loan_source_financial_institution_aggregate: LoanSourceFinancialInstitutionAggregate;
  /** fetch data from the table: "loan_source.financial_institution" using primary key columns */
  loan_source_financial_institution_by_pk?: Maybe<LoanSourceFinancialInstitution>;
  /** fetch data from the table: "loan_source.loan" */
  loan_source_loan: Array<LoanSourceLoan>;
  /** fetch aggregated fields from the table: "loan_source.loan" */
  loan_source_loan_aggregate: LoanSourceLoanAggregate;
  /** fetch data from the table: "loan_source.loan_attachment" */
  loan_source_loan_attachment: Array<LoanSourceLoanAttachment>;
  /** fetch aggregated fields from the table: "loan_source.loan_attachment" */
  loan_source_loan_attachment_aggregate: LoanSourceLoanAttachmentAggregate;
  /** fetch data from the table: "loan_source.loan_attachment" using primary key columns */
  loan_source_loan_attachment_by_pk?: Maybe<LoanSourceLoanAttachment>;
  /** fetch data from the table: "loan_source.loan_business_type" */
  loan_source_loan_business_type: Array<LoanSourceLoanBusinessType>;
  /** fetch aggregated fields from the table: "loan_source.loan_business_type" */
  loan_source_loan_business_type_aggregate: LoanSourceLoanBusinessTypeAggregate;
  /** fetch data from the table: "loan_source.loan_business_type" using primary key columns */
  loan_source_loan_business_type_by_pk?: Maybe<LoanSourceLoanBusinessType>;
  /** fetch data from the table: "loan_source.loan" using primary key columns */
  loan_source_loan_by_pk?: Maybe<LoanSourceLoan>;
  /** fetch data from the table: "loan_source.objective" */
  loan_source_objective: Array<LoanSourceObjective>;
  /** fetch aggregated fields from the table: "loan_source.objective" */
  loan_source_objective_aggregate: LoanSourceObjectiveAggregate;
  /** fetch data from the table: "loan_source.objective" using primary key columns */
  loan_source_objective_by_pk?: Maybe<LoanSourceObjective>;
  /** fetch data from the table: "loan_source.search_log" */
  loan_source_search_log: Array<LoanSourceSearchLog>;
  /** fetch aggregated fields from the table: "loan_source.search_log" */
  loan_source_search_log_aggregate: LoanSourceSearchLogAggregate;
  /** fetch data from the table: "loan_source.search_log" using primary key columns */
  loan_source_search_log_by_pk?: Maybe<LoanSourceSearchLog>;
  /** fetch data from the table: "loan.transfer_status" */
  loan_transfer_status: Array<LoanTransferStatus>;
  /** fetch aggregated fields from the table: "loan.transfer_status" */
  loan_transfer_status_aggregate: LoanTransferStatusAggregate;
  /** fetch data from the table: "loan.transfer_status" using primary key columns */
  loan_transfer_status_by_pk?: Maybe<LoanTransferStatus>;
  /** fetch data from the table: "me" */
  me: Array<Me>;
  /** fetch aggregated fields from the table: "me" */
  me_aggregate: MeAggregate;
  /** fetch data from the table: "month_of_fiscal_year" */
  month_of_fiscal_year: Array<MonthOfFiscalYear>;
  /** fetch aggregated fields from the table: "month_of_fiscal_year" */
  month_of_fiscal_year_aggregate: MonthOfFiscalYearAggregate;
  /** fetch data from the table: "name_title" */
  name_title: Array<NameTitle>;
  /** fetch aggregated fields from the table: "name_title" */
  name_title_aggregate: NameTitleAggregate;
  /** fetch data from the table: "name_title" using primary key columns */
  name_title_by_pk?: Maybe<NameTitle>;
  /** fetch data from the table: "notification" */
  notification: Array<Notification>;
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: NotificationAggregate;
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<Notification>;
  /** fetch data from the table: "notification_status" */
  notification_status: Array<NotificationStatus>;
  /** fetch aggregated fields from the table: "notification_status" */
  notification_status_aggregate: NotificationStatusAggregate;
  /** fetch data from the table: "notification_status" using primary key columns */
  notification_status_by_pk?: Maybe<NotificationStatus>;
  /** fetch data from the table: "organization" */
  organization: Array<Organization>;
  /** fetch aggregated fields from the table: "organization" */
  organization_aggregate: OrganizationAggregate;
  /** fetch data from the table: "organization" using primary key columns */
  organization_by_pk?: Maybe<Organization>;
  /** fetch data from the table: "organization_other" */
  organization_other: Array<OrganizationOther>;
  /** fetch aggregated fields from the table: "organization_other" */
  organization_other_aggregate: OrganizationOtherAggregate;
  /** fetch data from the table: "organization_province" */
  organization_province: Array<OrganizationProvince>;
  /** fetch aggregated fields from the table: "organization_province" */
  organization_province_aggregate: OrganizationProvinceAggregate;
  /** fetch data from the table: "organization_type" */
  organization_type: Array<OrganizationType>;
  /** fetch aggregated fields from the table: "organization_type" */
  organization_type_aggregate: OrganizationTypeAggregate;
  /** fetch data from the table: "organization_type" using primary key columns */
  organization_type_by_pk?: Maybe<OrganizationType>;
  /** fetch data from the table: "permission" */
  permission: Array<Permission>;
  /** fetch aggregated fields from the table: "permission" */
  permission_aggregate: PermissionAggregate;
  /** fetch data from the table: "permission" using primary key columns */
  permission_by_pk?: Maybe<Permission>;
  /** fetch data from the table: "posts" */
  posts: Array<Posts>;
  /** fetch aggregated fields from the table: "posts" */
  posts_aggregate: PostsAggregate;
  /** fetch data from the table: "posts" using primary key columns */
  posts_by_pk?: Maybe<Posts>;
  /** fetch data from the table: "project.approved" */
  project_approved: Array<ProjectApproved>;
  /** fetch aggregated fields from the table: "project.approved" */
  project_approved_aggregate: ProjectApprovedAggregate;
  /** fetch data from the table: "project.approved" using primary key columns */
  project_approved_by_pk?: Maybe<ProjectApproved>;
  /** fetch data from the table: "project.attachment_type" */
  project_attachment_type: Array<ProjectAttachmentType>;
  /** fetch aggregated fields from the table: "project.attachment_type" */
  project_attachment_type_aggregate: ProjectAttachmentTypeAggregate;
  /** fetch data from the table: "project.attachment_type" using primary key columns */
  project_attachment_type_by_pk?: Maybe<ProjectAttachmentType>;
  /** fetch data from the table: "project.budget" */
  project_budget: Array<ProjectBudget>;
  /** fetch aggregated fields from the table: "project.budget" */
  project_budget_aggregate: ProjectBudgetAggregate;
  /** fetch data from the table: "project.budget" using primary key columns */
  project_budget_by_pk?: Maybe<ProjectBudget>;
  /** fetch data from the table: "project.budget_group" */
  project_budget_group: Array<ProjectBudgetGroup>;
  /** fetch aggregated fields from the table: "project.budget_group" */
  project_budget_group_aggregate: ProjectBudgetGroupAggregate;
  /** fetch data from the table: "project.budget_group" using primary key columns */
  project_budget_group_by_pk?: Maybe<ProjectBudgetGroup>;
  /** fetch data from the table: "project.budget_group_mapping" */
  project_budget_group_mapping: Array<ProjectBudgetGroupMapping>;
  /** fetch aggregated fields from the table: "project.budget_group_mapping" */
  project_budget_group_mapping_aggregate: ProjectBudgetGroupMappingAggregate;
  /** fetch data from the table: "project.budget_group_mapping" using primary key columns */
  project_budget_group_mapping_by_pk?: Maybe<ProjectBudgetGroupMapping>;
  /** fetch data from the table: "project.budget_plan" */
  project_budget_plan: Array<ProjectBudgetPlan>;
  /** fetch aggregated fields from the table: "project.budget_plan" */
  project_budget_plan_aggregate: ProjectBudgetPlanAggregate;
  /** fetch data from the table: "project.indicator_type" */
  project_indicator_type: Array<ProjectIndicatorType>;
  /** fetch aggregated fields from the table: "project.indicator_type" */
  project_indicator_type_aggregate: ProjectIndicatorTypeAggregate;
  /** fetch data from the table: "project.indicator_type" using primary key columns */
  project_indicator_type_by_pk?: Maybe<ProjectIndicatorType>;
  /** fetch data from the table: "project.master_plan" */
  project_master_plan: Array<ProjectMasterPlan>;
  /** fetch aggregated fields from the table: "project.master_plan" */
  project_master_plan_aggregate: ProjectMasterPlanAggregate;
  /** fetch data from the table: "project.national_strategy" */
  project_national_strategy: Array<ProjectNationalStrategy>;
  /** fetch aggregated fields from the table: "project.national_strategy" */
  project_national_strategy_aggregate: ProjectNationalStrategyAggregate;
  /** fetch data from the table: "project.national_strategy" using primary key columns */
  project_national_strategy_by_pk?: Maybe<ProjectNationalStrategy>;
  /** fetch data from the table: "project.operating_activity_progress" */
  project_operating_activity_progress: Array<ProjectOperatingActivityProgress>;
  /** fetch aggregated fields from the table: "project.operating_activity_progress" */
  project_operating_activity_progress_aggregate: ProjectOperatingActivityProgressAggregate;
  /** fetch data from the table: "project.operating_activity_progress" using primary key columns */
  project_operating_activity_progress_by_pk?: Maybe<ProjectOperatingActivityProgress>;
  /** fetch data from the table: "project.operating_activity_progress_revision" */
  project_operating_activity_progress_revision: Array<ProjectOperatingActivityProgressRevision>;
  /** fetch aggregated fields from the table: "project.operating_activity_progress_revision" */
  project_operating_activity_progress_revision_aggregate: ProjectOperatingActivityProgressRevisionAggregate;
  /** fetch data from the table: "project.operating_activity_progress_revision" using primary key columns */
  project_operating_activity_progress_revision_by_pk?: Maybe<ProjectOperatingActivityProgressRevision>;
  /** fetch data from the table: "project.operating_activity_type" */
  project_operating_activity_type: Array<ProjectOperatingActivityType>;
  /** fetch aggregated fields from the table: "project.operating_activity_type" */
  project_operating_activity_type_aggregate: ProjectOperatingActivityTypeAggregate;
  /** fetch data from the table: "project.operating_activity_type" using primary key columns */
  project_operating_activity_type_by_pk?: Maybe<ProjectOperatingActivityType>;
  /** fetch data from the table: "project.project" */
  project_project: Array<ProjectProject>;
  /** fetch data from the table: "project.project_achievement" */
  project_project_achievement: Array<ProjectProjectAchievement>;
  /** fetch aggregated fields from the table: "project.project_achievement" */
  project_project_achievement_aggregate: ProjectProjectAchievementAggregate;
  /** fetch data from the table: "project.project_achievement" using primary key columns */
  project_project_achievement_by_pk?: Maybe<ProjectProjectAchievement>;
  /** fetch aggregated fields from the table: "project.project" */
  project_project_aggregate: ProjectProjectAggregate;
  /** fetch data from the table: "project.project_attachment" */
  project_project_attachment: Array<ProjectProjectAttachment>;
  /** fetch aggregated fields from the table: "project.project_attachment" */
  project_project_attachment_aggregate: ProjectProjectAttachmentAggregate;
  /** fetch data from the table: "project.project_attachment" using primary key columns */
  project_project_attachment_by_pk?: Maybe<ProjectProjectAttachment>;
  /** fetch data from the table: "project.project_budget_item" */
  project_project_budget_item: Array<ProjectProjectBudgetItem>;
  /** fetch aggregated fields from the table: "project.project_budget_item" */
  project_project_budget_item_aggregate: ProjectProjectBudgetItemAggregate;
  /** fetch data from the table: "project.project_budget_item" using primary key columns */
  project_project_budget_item_by_pk?: Maybe<ProjectProjectBudgetItem>;
  /** fetch data from the table: "project.project_budget_item_summary" */
  project_project_budget_item_summary: Array<ProjectProjectBudgetItemSummary>;
  /** fetch aggregated fields from the table: "project.project_budget_item_summary" */
  project_project_budget_item_summary_aggregate: ProjectProjectBudgetItemSummaryAggregate;
  /** fetch data from the table: "project.project_budget_summary" */
  project_project_budget_summary: Array<ProjectProjectBudgetSummary>;
  /** fetch aggregated fields from the table: "project.project_budget_summary" */
  project_project_budget_summary_aggregate: ProjectProjectBudgetSummaryAggregate;
  /** fetch data from the table: "project.project_budget_template" */
  project_project_budget_template: Array<ProjectProjectBudgetTemplate>;
  /** fetch aggregated fields from the table: "project.project_budget_template" */
  project_project_budget_template_aggregate: ProjectProjectBudgetTemplateAggregate;
  /** fetch data from the table: "project.project" using primary key columns */
  project_project_by_pk?: Maybe<ProjectProject>;
  /** fetch data from the table: "project.project_event_log" */
  project_project_event_log: Array<ProjectProjectEventLog>;
  /** fetch aggregated fields from the table: "project.project_event_log" */
  project_project_event_log_aggregate: ProjectProjectEventLogAggregate;
  /** fetch data from the table: "project.project_event_log" using primary key columns */
  project_project_event_log_by_pk?: Maybe<ProjectProjectEventLog>;
  /** fetch data from the table: "project.project_indicator" */
  project_project_indicator: Array<ProjectProjectIndicator>;
  /** fetch aggregated fields from the table: "project.project_indicator" */
  project_project_indicator_aggregate: ProjectProjectIndicatorAggregate;
  /** fetch data from the table: "project.project_indicator" using primary key columns */
  project_project_indicator_by_pk?: Maybe<ProjectProjectIndicator>;
  /** fetch data from the table: "project.project_member" */
  project_project_member: Array<ProjectProjectMember>;
  /** fetch aggregated fields from the table: "project.project_member" */
  project_project_member_aggregate: ProjectProjectMemberAggregate;
  /** fetch data from the table: "project.project_member" using primary key columns */
  project_project_member_by_pk?: Maybe<ProjectProjectMember>;
  /** fetch data from the table: "project.project_objective" */
  project_project_objective: Array<ProjectProjectObjective>;
  /** fetch aggregated fields from the table: "project.project_objective" */
  project_project_objective_aggregate: ProjectProjectObjectiveAggregate;
  /** fetch data from the table: "project.project_objective" using primary key columns */
  project_project_objective_by_pk?: Maybe<ProjectProjectObjective>;
  /** fetch data from the table: "project.project_operating_activity" */
  project_project_operating_activity: Array<ProjectProjectOperatingActivity>;
  /** fetch aggregated fields from the table: "project.project_operating_activity" */
  project_project_operating_activity_aggregate: ProjectProjectOperatingActivityAggregate;
  /** fetch data from the table: "project.project_operating_activity" using primary key columns */
  project_project_operating_activity_by_pk?: Maybe<ProjectProjectOperatingActivity>;
  /** fetch data from the table: "project.project_operating_area" */
  project_project_operating_area: Array<ProjectProjectOperatingArea>;
  /** fetch aggregated fields from the table: "project.project_operating_area" */
  project_project_operating_area_aggregate: ProjectProjectOperatingAreaAggregate;
  /** fetch data from the table: "project.project_operating_area" using primary key columns */
  project_project_operating_area_by_pk?: Maybe<ProjectProjectOperatingArea>;
  /** fetch data from the table: "project.project_step" */
  project_project_step: Array<ProjectProjectStep>;
  /** fetch aggregated fields from the table: "project.project_step" */
  project_project_step_aggregate: ProjectProjectStepAggregate;
  /** fetch data from the table: "project.project_step" using primary key columns */
  project_project_step_by_pk?: Maybe<ProjectProjectStep>;
  /** fetch data from the table: "project.project_target_group" */
  project_project_target_group: Array<ProjectProjectTargetGroup>;
  /** fetch aggregated fields from the table: "project.project_target_group" */
  project_project_target_group_aggregate: ProjectProjectTargetGroupAggregate;
  /** fetch data from the table: "project.project_target_group" using primary key columns */
  project_project_target_group_by_pk?: Maybe<ProjectProjectTargetGroup>;
  /** fetch data from the table: "project.project_workload_summary" */
  project_project_workload_summary: Array<ProjectProjectWorkloadSummary>;
  /** fetch aggregated fields from the table: "project.project_workload_summary" */
  project_project_workload_summary_aggregate: ProjectProjectWorkloadSummaryAggregate;
  /** fetch data from the table: "project.sub_plan" */
  project_sub_plan: Array<ProjectSubPlan>;
  /** fetch aggregated fields from the table: "project.sub_plan" */
  project_sub_plan_aggregate: ProjectSubPlanAggregate;
  /** fetch data from the table: "project.target_group" */
  project_target_group: Array<ProjectTargetGroup>;
  /** fetch aggregated fields from the table: "project.target_group" */
  project_target_group_aggregate: ProjectTargetGroupAggregate;
  /** fetch data from the table: "project.target_group" using primary key columns */
  project_target_group_by_pk?: Maybe<ProjectTargetGroup>;
  /** fetch data from the table: "project.unit" */
  project_unit: Array<ProjectUnit>;
  /** fetch aggregated fields from the table: "project.unit" */
  project_unit_aggregate: ProjectUnitAggregate;
  /** fetch data from the table: "project.unit" using primary key columns */
  project_unit_by_pk?: Maybe<ProjectUnit>;
  /** fetch data from the table: "province" */
  province: Array<Province>;
  /** fetch aggregated fields from the table: "province" */
  province_aggregate: ProvinceAggregate;
  /** fetch data from the table: "province" using primary key columns */
  province_by_pk?: Maybe<Province>;
  /** fetch data from the table: "region" */
  region: Array<Region>;
  /** fetch aggregated fields from the table: "region" */
  region_aggregate: RegionAggregate;
  /** fetch data from the table: "region" using primary key columns */
  region_by_pk?: Maybe<Region>;
  /** fetch data from the table: "user_mapping" */
  user_mapping: Array<UserMapping>;
  /** fetch aggregated fields from the table: "user_mapping" */
  user_mapping_aggregate: UserMappingAggregate;
  /** fetch data from the table: "user_mapping" using primary key columns */
  user_mapping_by_pk?: Maybe<UserMapping>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "users_permission" */
  users_permission: Array<UsersPermission>;
  /** fetch aggregated fields from the table: "users_permission" */
  users_permission_aggregate: UsersPermissionAggregate;
  /** fetch data from the table: "users_permission" using primary key columns */
  users_permission_by_pk?: Maybe<UsersPermission>;
};


export type QueryRootAccountAccountArgs = {
  distinct_on?: Maybe<Array<AccountAccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountOrderBy>>;
  where?: Maybe<AccountAccountBoolExp>;
};


export type QueryRootAccountAccountAggregateArgs = {
  distinct_on?: Maybe<Array<AccountAccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountOrderBy>>;
  where?: Maybe<AccountAccountBoolExp>;
};


export type QueryRootAccountAccountBalanceArgs = {
  distinct_on?: Maybe<Array<AccountAccountBalanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountBalanceOrderBy>>;
  where?: Maybe<AccountAccountBalanceBoolExp>;
};


export type QueryRootAccountAccountBalanceAggregateArgs = {
  distinct_on?: Maybe<Array<AccountAccountBalanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountBalanceOrderBy>>;
  where?: Maybe<AccountAccountBalanceBoolExp>;
};


export type QueryRootAccountAccountBalanceSheetArgs = {
  distinct_on?: Maybe<Array<AccountAccountBalanceSheetSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountBalanceSheetOrderBy>>;
  where?: Maybe<AccountAccountBalanceSheetBoolExp>;
};


export type QueryRootAccountAccountBalanceSheetAggregateArgs = {
  distinct_on?: Maybe<Array<AccountAccountBalanceSheetSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountBalanceSheetOrderBy>>;
  where?: Maybe<AccountAccountBalanceSheetBoolExp>;
};


export type QueryRootAccountAccountByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAccountAccountCategoryArgs = {
  distinct_on?: Maybe<Array<AccountAccountCategorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountCategoryOrderBy>>;
  where?: Maybe<AccountAccountCategoryBoolExp>;
};


export type QueryRootAccountAccountCategoryAggregateArgs = {
  distinct_on?: Maybe<Array<AccountAccountCategorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountCategoryOrderBy>>;
  where?: Maybe<AccountAccountCategoryBoolExp>;
};


export type QueryRootAccountAccountCategoryByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAccountAccountMonthlyReportArgs = {
  distinct_on?: Maybe<Array<AccountAccountMonthlyReportSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountMonthlyReportOrderBy>>;
  where?: Maybe<AccountAccountMonthlyReportBoolExp>;
};


export type QueryRootAccountAccountMonthlyReportAggregateArgs = {
  distinct_on?: Maybe<Array<AccountAccountMonthlyReportSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountMonthlyReportOrderBy>>;
  where?: Maybe<AccountAccountMonthlyReportBoolExp>;
};


export type QueryRootAccountAccountTypeArgs = {
  distinct_on?: Maybe<Array<AccountAccountTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountTypeOrderBy>>;
  where?: Maybe<AccountAccountTypeBoolExp>;
};


export type QueryRootAccountAccountTypeAggregateArgs = {
  distinct_on?: Maybe<Array<AccountAccountTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountTypeOrderBy>>;
  where?: Maybe<AccountAccountTypeBoolExp>;
};


export type QueryRootAccountAccountTypeByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootAccountBudgetAccountArgs = {
  distinct_on?: Maybe<Array<AccountBudgetAccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetAccountOrderBy>>;
  where?: Maybe<AccountBudgetAccountBoolExp>;
};


export type QueryRootAccountBudgetAccountAggregateArgs = {
  distinct_on?: Maybe<Array<AccountBudgetAccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetAccountOrderBy>>;
  where?: Maybe<AccountBudgetAccountBoolExp>;
};


export type QueryRootAccountBudgetAccountByPkArgs = {
  account_id: Scalars['uuid'];
  budget_id: Scalars['String'];
  fiscal_year: Scalars['Int'];
};


export type QueryRootAccountBudgetAllocationArgs = {
  distinct_on?: Maybe<Array<AccountBudgetAllocationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetAllocationOrderBy>>;
  where?: Maybe<AccountBudgetAllocationBoolExp>;
};


export type QueryRootAccountBudgetAllocationAggregateArgs = {
  distinct_on?: Maybe<Array<AccountBudgetAllocationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetAllocationOrderBy>>;
  where?: Maybe<AccountBudgetAllocationBoolExp>;
};


export type QueryRootAccountBudgetAllocationByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAccountBudgetAllocationLogArgs = {
  distinct_on?: Maybe<Array<AccountBudgetAllocationLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetAllocationLogOrderBy>>;
  where?: Maybe<AccountBudgetAllocationLogBoolExp>;
};


export type QueryRootAccountBudgetAllocationLogAggregateArgs = {
  distinct_on?: Maybe<Array<AccountBudgetAllocationLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetAllocationLogOrderBy>>;
  where?: Maybe<AccountBudgetAllocationLogBoolExp>;
};


export type QueryRootAccountBudgetAllocationLogByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAccountBudgetTypeArgs = {
  distinct_on?: Maybe<Array<AccountBudgetTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetTypeOrderBy>>;
  where?: Maybe<AccountBudgetTypeBoolExp>;
};


export type QueryRootAccountBudgetTypeAggregateArgs = {
  distinct_on?: Maybe<Array<AccountBudgetTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetTypeOrderBy>>;
  where?: Maybe<AccountBudgetTypeBoolExp>;
};


export type QueryRootAccountBudgetTypeByPkArgs = {
  fiscal_year: Scalars['Int'];
  id: Scalars['String'];
};


export type QueryRootAccountEntryTypeArgs = {
  distinct_on?: Maybe<Array<AccountEntryTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountEntryTypeOrderBy>>;
  where?: Maybe<AccountEntryTypeBoolExp>;
};


export type QueryRootAccountEntryTypeAggregateArgs = {
  distinct_on?: Maybe<Array<AccountEntryTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountEntryTypeOrderBy>>;
  where?: Maybe<AccountEntryTypeBoolExp>;
};


export type QueryRootAccountEntryTypeByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootAccountLedgerEntryArgs = {
  distinct_on?: Maybe<Array<AccountLedgerEntrySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLedgerEntryOrderBy>>;
  where?: Maybe<AccountLedgerEntryBoolExp>;
};


export type QueryRootAccountLedgerEntryAggregateArgs = {
  distinct_on?: Maybe<Array<AccountLedgerEntrySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLedgerEntryOrderBy>>;
  where?: Maybe<AccountLedgerEntryBoolExp>;
};


export type QueryRootAccountLedgerEntryByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAccountLedgerEntryViewArgs = {
  distinct_on?: Maybe<Array<AccountLedgerEntryViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLedgerEntryViewOrderBy>>;
  where?: Maybe<AccountLedgerEntryViewBoolExp>;
};


export type QueryRootAccountLedgerEntryViewAggregateArgs = {
  distinct_on?: Maybe<Array<AccountLedgerEntryViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLedgerEntryViewOrderBy>>;
  where?: Maybe<AccountLedgerEntryViewBoolExp>;
};


export type QueryRootAccountLendingArgs = {
  distinct_on?: Maybe<Array<AccountLendingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLendingOrderBy>>;
  where?: Maybe<AccountLendingBoolExp>;
};


export type QueryRootAccountLendingAggregateArgs = {
  distinct_on?: Maybe<Array<AccountLendingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLendingOrderBy>>;
  where?: Maybe<AccountLendingBoolExp>;
};


export type QueryRootAccountLendingByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAccountLendingItemArgs = {
  distinct_on?: Maybe<Array<AccountLendingItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLendingItemOrderBy>>;
  where?: Maybe<AccountLendingItemBoolExp>;
};


export type QueryRootAccountLendingItemAggregateArgs = {
  distinct_on?: Maybe<Array<AccountLendingItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLendingItemOrderBy>>;
  where?: Maybe<AccountLendingItemBoolExp>;
};


export type QueryRootAccountLendingItemByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAccountLendingStatusArgs = {
  distinct_on?: Maybe<Array<AccountLendingStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLendingStatusOrderBy>>;
  where?: Maybe<AccountLendingStatusBoolExp>;
};


export type QueryRootAccountLendingStatusAggregateArgs = {
  distinct_on?: Maybe<Array<AccountLendingStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLendingStatusOrderBy>>;
  where?: Maybe<AccountLendingStatusBoolExp>;
};


export type QueryRootAccountLendingStatusByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootAccountTransactionArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};


export type QueryRootAccountTransactionAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};


export type QueryRootAccountTransactionByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAccountTransactionLogArgs = {
  distinct_on?: Maybe<Array<AccountTransactionLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionLogOrderBy>>;
  where?: Maybe<AccountTransactionLogBoolExp>;
};


export type QueryRootAccountTransactionLogAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTransactionLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionLogOrderBy>>;
  where?: Maybe<AccountTransactionLogBoolExp>;
};


export type QueryRootAccountTransactionLogByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAccountTransactionStatusArgs = {
  distinct_on?: Maybe<Array<AccountTransactionStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionStatusOrderBy>>;
  where?: Maybe<AccountTransactionStatusBoolExp>;
};


export type QueryRootAccountTransactionStatusAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTransactionStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionStatusOrderBy>>;
  where?: Maybe<AccountTransactionStatusBoolExp>;
};


export type QueryRootAccountTransactionStatusByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootAccountTransactionTypeArgs = {
  distinct_on?: Maybe<Array<AccountTransactionTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionTypeOrderBy>>;
  where?: Maybe<AccountTransactionTypeBoolExp>;
};


export type QueryRootAccountTransactionTypeAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTransactionTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionTypeOrderBy>>;
  where?: Maybe<AccountTransactionTypeBoolExp>;
};


export type QueryRootAccountTransactionTypeByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootAccountTrialBalanceMonthlyArgs = {
  distinct_on?: Maybe<Array<AccountTrialBalanceMonthlySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTrialBalanceMonthlyOrderBy>>;
  where?: Maybe<AccountTrialBalanceMonthlyBoolExp>;
};


export type QueryRootAccountTrialBalanceMonthlyAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTrialBalanceMonthlySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTrialBalanceMonthlyOrderBy>>;
  where?: Maybe<AccountTrialBalanceMonthlyBoolExp>;
};


export type QueryRootAttachmentArgs = {
  distinct_on?: Maybe<Array<AttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AttachmentOrderBy>>;
  where?: Maybe<AttachmentBoolExp>;
};


export type QueryRootAttachmentAggregateArgs = {
  distinct_on?: Maybe<Array<AttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AttachmentOrderBy>>;
  where?: Maybe<AttachmentBoolExp>;
};


export type QueryRootAttachmentByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAuditLoggedActionsArgs = {
  distinct_on?: Maybe<Array<AuditLoggedActionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuditLoggedActionsOrderBy>>;
  where?: Maybe<AuditLoggedActionsBoolExp>;
};


export type QueryRootAuditLoggedActionsAggregateArgs = {
  distinct_on?: Maybe<Array<AuditLoggedActionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuditLoggedActionsOrderBy>>;
  where?: Maybe<AuditLoggedActionsBoolExp>;
};


export type QueryRootAuditLoggedActionsByPkArgs = {
  event_id: Scalars['bigint'];
};


export type QueryRootAuthAccountProvidersArgs = {
  distinct_on?: Maybe<Array<AuthAccountProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountProvidersOrderBy>>;
  where?: Maybe<AuthAccountProvidersBoolExp>;
};


export type QueryRootAuthAccountProvidersAggregateArgs = {
  distinct_on?: Maybe<Array<AuthAccountProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountProvidersOrderBy>>;
  where?: Maybe<AuthAccountProvidersBoolExp>;
};


export type QueryRootAuthAccountProvidersByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAuthAccountRolesArgs = {
  distinct_on?: Maybe<Array<AuthAccountRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountRolesOrderBy>>;
  where?: Maybe<AuthAccountRolesBoolExp>;
};


export type QueryRootAuthAccountRolesAggregateArgs = {
  distinct_on?: Maybe<Array<AuthAccountRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountRolesOrderBy>>;
  where?: Maybe<AuthAccountRolesBoolExp>;
};


export type QueryRootAuthAccountRolesByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAuthAccountsArgs = {
  distinct_on?: Maybe<Array<AuthAccountsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountsOrderBy>>;
  where?: Maybe<AuthAccountsBoolExp>;
};


export type QueryRootAuthAccountsAggregateArgs = {
  distinct_on?: Maybe<Array<AuthAccountsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountsOrderBy>>;
  where?: Maybe<AuthAccountsBoolExp>;
};


export type QueryRootAuthAccountsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAuthProvidersArgs = {
  distinct_on?: Maybe<Array<AuthProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthProvidersOrderBy>>;
  where?: Maybe<AuthProvidersBoolExp>;
};


export type QueryRootAuthProvidersAggregateArgs = {
  distinct_on?: Maybe<Array<AuthProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthProvidersOrderBy>>;
  where?: Maybe<AuthProvidersBoolExp>;
};


export type QueryRootAuthProvidersByPkArgs = {
  provider: Scalars['String'];
};


export type QueryRootAuthRefreshTokensArgs = {
  distinct_on?: Maybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthRefreshTokensOrderBy>>;
  where?: Maybe<AuthRefreshTokensBoolExp>;
};


export type QueryRootAuthRefreshTokensAggregateArgs = {
  distinct_on?: Maybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthRefreshTokensOrderBy>>;
  where?: Maybe<AuthRefreshTokensBoolExp>;
};


export type QueryRootAuthRefreshTokensByPkArgs = {
  refresh_token: Scalars['uuid'];
};


export type QueryRootAuthRolesArgs = {
  distinct_on?: Maybe<Array<AuthRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthRolesOrderBy>>;
  where?: Maybe<AuthRolesBoolExp>;
};


export type QueryRootAuthRolesAggregateArgs = {
  distinct_on?: Maybe<Array<AuthRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthRolesOrderBy>>;
  where?: Maybe<AuthRolesBoolExp>;
};


export type QueryRootAuthRolesByPkArgs = {
  role: Scalars['String'];
};


export type QueryRootBankBranchArgs = {
  distinct_on?: Maybe<Array<BankBranchSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BankBranchOrderBy>>;
  where?: Maybe<BankBranchBoolExp>;
};


export type QueryRootBankBranchAggregateArgs = {
  distinct_on?: Maybe<Array<BankBranchSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BankBranchOrderBy>>;
  where?: Maybe<BankBranchBoolExp>;
};


export type QueryRootBankBranchByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootContractAfStatusArgs = {
  distinct_on?: Maybe<Array<ContractAfStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAfStatusOrderBy>>;
  where?: Maybe<ContractAfStatusBoolExp>;
};


export type QueryRootContractAfStatusAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAfStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAfStatusOrderBy>>;
  where?: Maybe<ContractAfStatusBoolExp>;
};


export type QueryRootContractAfStatusByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootContractAgreementArgs = {
  distinct_on?: Maybe<Array<ContractAgreementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementOrderBy>>;
  where?: Maybe<ContractAgreementBoolExp>;
};


export type QueryRootContractAgreementAfStatusCountArgs = {
  distinct_on?: Maybe<Array<ContractAgreementAfStatusCountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementAfStatusCountOrderBy>>;
  where?: Maybe<ContractAgreementAfStatusCountBoolExp>;
};


export type QueryRootContractAgreementAfStatusCountAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementAfStatusCountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementAfStatusCountOrderBy>>;
  where?: Maybe<ContractAgreementAfStatusCountBoolExp>;
};


export type QueryRootContractAgreementAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementOrderBy>>;
  where?: Maybe<ContractAgreementBoolExp>;
};


export type QueryRootContractAgreementAttachmentArgs = {
  distinct_on?: Maybe<Array<ContractAgreementAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementAttachmentOrderBy>>;
  where?: Maybe<ContractAgreementAttachmentBoolExp>;
};


export type QueryRootContractAgreementAttachmentAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementAttachmentOrderBy>>;
  where?: Maybe<ContractAgreementAttachmentBoolExp>;
};


export type QueryRootContractAgreementAttachmentByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootContractAgreementByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootContractAgreementDetailArgs = {
  distinct_on?: Maybe<Array<ContractAgreementDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementDetailOrderBy>>;
  where?: Maybe<ContractAgreementDetailBoolExp>;
};


export type QueryRootContractAgreementDetailAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementDetailOrderBy>>;
  where?: Maybe<ContractAgreementDetailBoolExp>;
};


export type QueryRootContractAgreementDetailByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootContractAgreementDisbursementArgs = {
  distinct_on?: Maybe<Array<ContractAgreementDisbursementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementDisbursementOrderBy>>;
  where?: Maybe<ContractAgreementDisbursementBoolExp>;
};


export type QueryRootContractAgreementDisbursementAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementDisbursementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementDisbursementOrderBy>>;
  where?: Maybe<ContractAgreementDisbursementBoolExp>;
};


export type QueryRootContractAgreementDisbursementByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootContractAgreementFeeChargeArgs = {
  distinct_on?: Maybe<Array<ContractAgreementFeeChargeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementFeeChargeOrderBy>>;
  where?: Maybe<ContractAgreementFeeChargeBoolExp>;
};


export type QueryRootContractAgreementFeeChargeAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementFeeChargeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementFeeChargeOrderBy>>;
  where?: Maybe<ContractAgreementFeeChargeBoolExp>;
};


export type QueryRootContractAgreementFeeChargeByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootContractAgreementGuarantorArgs = {
  distinct_on?: Maybe<Array<ContractAgreementGuarantorSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementGuarantorOrderBy>>;
  where?: Maybe<ContractAgreementGuarantorBoolExp>;
};


export type QueryRootContractAgreementGuarantorAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementGuarantorSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementGuarantorOrderBy>>;
  where?: Maybe<ContractAgreementGuarantorBoolExp>;
};


export type QueryRootContractAgreementGuarantorByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootContractAgreementReceiptArgs = {
  distinct_on?: Maybe<Array<ContractAgreementReceiptSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementReceiptOrderBy>>;
  where?: Maybe<ContractAgreementReceiptBoolExp>;
};


export type QueryRootContractAgreementReceiptAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementReceiptSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementReceiptOrderBy>>;
  where?: Maybe<ContractAgreementReceiptBoolExp>;
};


export type QueryRootContractAgreementReceiptByPkArgs = {
  receipt_no: Scalars['String'];
};


export type QueryRootContractAgreementRepayCurrentMonthArgs = {
  distinct_on?: Maybe<Array<ContractAgreementRepayCurrentMonthSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementRepayCurrentMonthOrderBy>>;
  where?: Maybe<ContractAgreementRepayCurrentMonthBoolExp>;
};


export type QueryRootContractAgreementRepayCurrentMonthAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementRepayCurrentMonthSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementRepayCurrentMonthOrderBy>>;
  where?: Maybe<ContractAgreementRepayCurrentMonthBoolExp>;
};


export type QueryRootContractAgreementStatusArgs = {
  distinct_on?: Maybe<Array<ContractAgreementStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementStatusOrderBy>>;
  where?: Maybe<ContractAgreementStatusBoolExp>;
};


export type QueryRootContractAgreementStatusAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementStatusOrderBy>>;
  where?: Maybe<ContractAgreementStatusBoolExp>;
};


export type QueryRootContractAgreementStatusByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootContractAgreementStatusMapperArgs = {
  distinct_on?: Maybe<Array<ContractAgreementStatusMapperSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementStatusMapperOrderBy>>;
  where?: Maybe<ContractAgreementStatusMapperBoolExp>;
};


export type QueryRootContractAgreementStatusMapperAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementStatusMapperSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementStatusMapperOrderBy>>;
  where?: Maybe<ContractAgreementStatusMapperBoolExp>;
};


export type QueryRootContractAgreementStatusMapperByPkArgs = {
  bank_status: Scalars['String'];
};


export type QueryRootContractAgreementTransactionArgs = {
  distinct_on?: Maybe<Array<ContractAgreementTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementTransactionOrderBy>>;
  where?: Maybe<ContractAgreementTransactionBoolExp>;
};


export type QueryRootContractAgreementTransactionAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementTransactionOrderBy>>;
  where?: Maybe<ContractAgreementTransactionBoolExp>;
};


export type QueryRootContractAgreementTransactionByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootContractAgreementWithdrawArgs = {
  distinct_on?: Maybe<Array<ContractAgreementWithdrawSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementWithdrawOrderBy>>;
  where?: Maybe<ContractAgreementWithdrawBoolExp>;
};


export type QueryRootContractAgreementWithdrawAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementWithdrawSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementWithdrawOrderBy>>;
  where?: Maybe<ContractAgreementWithdrawBoolExp>;
};


export type QueryRootContractAgreementWithdrawByPkArgs = {
  draw_down_no: Scalars['String'];
};


export type QueryRootContractAttachmentTypeArgs = {
  distinct_on?: Maybe<Array<ContractAttachmentTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAttachmentTypeOrderBy>>;
  where?: Maybe<ContractAttachmentTypeBoolExp>;
};


export type QueryRootContractAttachmentTypeAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAttachmentTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAttachmentTypeOrderBy>>;
  where?: Maybe<ContractAttachmentTypeBoolExp>;
};


export type QueryRootContractAttachmentTypeByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootContractGradeArgs = {
  distinct_on?: Maybe<Array<ContractGradeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractGradeOrderBy>>;
  where?: Maybe<ContractGradeBoolExp>;
};


export type QueryRootContractGradeAggregateArgs = {
  distinct_on?: Maybe<Array<ContractGradeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractGradeOrderBy>>;
  where?: Maybe<ContractGradeBoolExp>;
};


export type QueryRootContractGradeByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootContractTransactionCreatorTypeArgs = {
  distinct_on?: Maybe<Array<ContractTransactionCreatorTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractTransactionCreatorTypeOrderBy>>;
  where?: Maybe<ContractTransactionCreatorTypeBoolExp>;
};


export type QueryRootContractTransactionCreatorTypeAggregateArgs = {
  distinct_on?: Maybe<Array<ContractTransactionCreatorTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractTransactionCreatorTypeOrderBy>>;
  where?: Maybe<ContractTransactionCreatorTypeBoolExp>;
};


export type QueryRootContractTransactionCreatorTypeByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootCurrentUserPermissionsArgs = {
  distinct_on?: Maybe<Array<CurrentUserPermissionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CurrentUserPermissionsOrderBy>>;
  where?: Maybe<CurrentUserPermissionsBoolExp>;
};


export type QueryRootCurrentUserPermissionsAggregateArgs = {
  distinct_on?: Maybe<Array<CurrentUserPermissionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CurrentUserPermissionsOrderBy>>;
  where?: Maybe<CurrentUserPermissionsBoolExp>;
};


export type QueryRootDepartmentArgs = {
  distinct_on?: Maybe<Array<DepartmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DepartmentOrderBy>>;
  where?: Maybe<DepartmentBoolExp>;
};


export type QueryRootDepartmentAggregateArgs = {
  distinct_on?: Maybe<Array<DepartmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DepartmentOrderBy>>;
  where?: Maybe<DepartmentBoolExp>;
};


export type QueryRootDepartmentByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootFeatureToggleArgs = {
  distinct_on?: Maybe<Array<FeatureToggleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FeatureToggleOrderBy>>;
  where?: Maybe<FeatureToggleBoolExp>;
};


export type QueryRootFeatureToggleAggregateArgs = {
  distinct_on?: Maybe<Array<FeatureToggleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FeatureToggleOrderBy>>;
  where?: Maybe<FeatureToggleBoolExp>;
};


export type QueryRootFeatureToggleByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootFiscalYearArgs = {
  distinct_on?: Maybe<Array<FiscalYearSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FiscalYearOrderBy>>;
  where?: Maybe<FiscalYearBoolExp>;
};


export type QueryRootFiscalYearAggregateArgs = {
  distinct_on?: Maybe<Array<FiscalYearSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FiscalYearOrderBy>>;
  where?: Maybe<FiscalYearBoolExp>;
};


export type QueryRootFiscalYearByPkArgs = {
  year: Scalars['Int'];
};


export type QueryRootGetJuristicDetailByIdArgs = {
  pk: PkInput;
};


export type QueryRootImportLogArgs = {
  distinct_on?: Maybe<Array<ImportLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ImportLogOrderBy>>;
  where?: Maybe<ImportLogBoolExp>;
};


export type QueryRootImportLogAggregateArgs = {
  distinct_on?: Maybe<Array<ImportLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ImportLogOrderBy>>;
  where?: Maybe<ImportLogBoolExp>;
};


export type QueryRootImportLogByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootJuristicArgs = {
  distinct_on?: Maybe<Array<JuristicSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicOrderBy>>;
  where?: Maybe<JuristicBoolExp>;
};


export type QueryRootJuristicAddressArgs = {
  distinct_on?: Maybe<Array<JuristicAddressSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicAddressOrderBy>>;
  where?: Maybe<JuristicAddressBoolExp>;
};


export type QueryRootJuristicAddressAggregateArgs = {
  distinct_on?: Maybe<Array<JuristicAddressSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicAddressOrderBy>>;
  where?: Maybe<JuristicAddressBoolExp>;
};


export type QueryRootJuristicAddressByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootJuristicAggregateArgs = {
  distinct_on?: Maybe<Array<JuristicSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicOrderBy>>;
  where?: Maybe<JuristicBoolExp>;
};


export type QueryRootJuristicByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootJuristicCommitteeArgs = {
  distinct_on?: Maybe<Array<JuristicCommitteeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicCommitteeOrderBy>>;
  where?: Maybe<JuristicCommitteeBoolExp>;
};


export type QueryRootJuristicCommitteeAggregateArgs = {
  distinct_on?: Maybe<Array<JuristicCommitteeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicCommitteeOrderBy>>;
  where?: Maybe<JuristicCommitteeBoolExp>;
};


export type QueryRootJuristicCommitteeByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootJuristicStandardObjectiveArgs = {
  distinct_on?: Maybe<Array<JuristicStandardObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicStandardObjectiveOrderBy>>;
  where?: Maybe<JuristicStandardObjectiveBoolExp>;
};


export type QueryRootJuristicStandardObjectiveAggregateArgs = {
  distinct_on?: Maybe<Array<JuristicStandardObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicStandardObjectiveOrderBy>>;
  where?: Maybe<JuristicStandardObjectiveBoolExp>;
};


export type QueryRootJuristicStandardObjectiveByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLedgerAccountBudgetBalanceArgs = {
  distinct_on?: Maybe<Array<LedgerAccountBudgetBalanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerAccountBudgetBalanceOrderBy>>;
  where?: Maybe<LedgerAccountBudgetBalanceBoolExp>;
};


export type QueryRootLedgerAccountBudgetBalanceAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerAccountBudgetBalanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerAccountBudgetBalanceOrderBy>>;
  where?: Maybe<LedgerAccountBudgetBalanceBoolExp>;
};


export type QueryRootLedgerAccountBudgetBalanceDebtArgs = {
  distinct_on?: Maybe<Array<LedgerAccountBudgetBalanceDebtSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerAccountBudgetBalanceDebtOrderBy>>;
  where?: Maybe<LedgerAccountBudgetBalanceDebtBoolExp>;
};


export type QueryRootLedgerAccountBudgetBalanceDebtAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerAccountBudgetBalanceDebtSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerAccountBudgetBalanceDebtOrderBy>>;
  where?: Maybe<LedgerAccountBudgetBalanceDebtBoolExp>;
};


export type QueryRootLedgerAccountBudgetBalanceExpenseArgs = {
  distinct_on?: Maybe<Array<LedgerAccountBudgetBalanceExpenseSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerAccountBudgetBalanceExpenseOrderBy>>;
  where?: Maybe<LedgerAccountBudgetBalanceExpenseBoolExp>;
};


export type QueryRootLedgerAccountBudgetBalanceExpenseAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerAccountBudgetBalanceExpenseSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerAccountBudgetBalanceExpenseOrderBy>>;
  where?: Maybe<LedgerAccountBudgetBalanceExpenseBoolExp>;
};


export type QueryRootLedgerAccountBudgetMonthlyArgs = {
  distinct_on?: Maybe<Array<LedgerAccountBudgetMonthlySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerAccountBudgetMonthlyOrderBy>>;
  where?: Maybe<LedgerAccountBudgetMonthlyBoolExp>;
};


export type QueryRootLedgerAccountBudgetMonthlyAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerAccountBudgetMonthlySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerAccountBudgetMonthlyOrderBy>>;
  where?: Maybe<LedgerAccountBudgetMonthlyBoolExp>;
};


export type QueryRootLedgerBudgetAllocationArgs = {
  distinct_on?: Maybe<Array<LedgerBudgetAllocationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerBudgetAllocationOrderBy>>;
  where?: Maybe<LedgerBudgetAllocationBoolExp>;
};


export type QueryRootLedgerBudgetAllocationAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerBudgetAllocationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerBudgetAllocationOrderBy>>;
  where?: Maybe<LedgerBudgetAllocationBoolExp>;
};


export type QueryRootLedgerBudgetAllocationByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLedgerLedgerArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerOrderBy>>;
  where?: Maybe<LedgerLedgerBoolExp>;
};


export type QueryRootLedgerLedgerAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerOrderBy>>;
  where?: Maybe<LedgerLedgerBoolExp>;
};


export type QueryRootLedgerLedgerAttachmentArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerAttachmentOrderBy>>;
  where?: Maybe<LedgerLedgerAttachmentBoolExp>;
};


export type QueryRootLedgerLedgerAttachmentAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerAttachmentOrderBy>>;
  where?: Maybe<LedgerLedgerAttachmentBoolExp>;
};


export type QueryRootLedgerLedgerAttachmentByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLedgerLedgerByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLedgerLedgerLogArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerLogOrderBy>>;
  where?: Maybe<LedgerLedgerLogBoolExp>;
};


export type QueryRootLedgerLedgerLogAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerLogOrderBy>>;
  where?: Maybe<LedgerLedgerLogBoolExp>;
};


export type QueryRootLedgerLedgerLogByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLedgerLedgerStatusArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerStatusOrderBy>>;
  where?: Maybe<LedgerLedgerStatusBoolExp>;
};


export type QueryRootLedgerLedgerStatusAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerStatusOrderBy>>;
  where?: Maybe<LedgerLedgerStatusBoolExp>;
};


export type QueryRootLedgerLedgerStatusByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootLedgerLedgerTypeArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerTypeOrderBy>>;
  where?: Maybe<LedgerLedgerTypeBoolExp>;
};


export type QueryRootLedgerLedgerTypeAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerTypeOrderBy>>;
  where?: Maybe<LedgerLedgerTypeBoolExp>;
};


export type QueryRootLedgerLedgerTypeByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootLedgerReceivableArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableOrderBy>>;
  where?: Maybe<LedgerReceivableBoolExp>;
};


export type QueryRootLedgerReceivableAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableOrderBy>>;
  where?: Maybe<LedgerReceivableBoolExp>;
};


export type QueryRootLedgerReceivableByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLedgerReceivableItemArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableItemOrderBy>>;
  where?: Maybe<LedgerReceivableItemBoolExp>;
};


export type QueryRootLedgerReceivableItemAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableItemOrderBy>>;
  where?: Maybe<LedgerReceivableItemBoolExp>;
};


export type QueryRootLedgerReceivableItemByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLedgerReceivableLogArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableLogOrderBy>>;
  where?: Maybe<LedgerReceivableLogBoolExp>;
};


export type QueryRootLedgerReceivableLogAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableLogOrderBy>>;
  where?: Maybe<LedgerReceivableLogBoolExp>;
};


export type QueryRootLedgerReceivableLogByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLedgerReceivableStatusArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableStatusOrderBy>>;
  where?: Maybe<LedgerReceivableStatusBoolExp>;
};


export type QueryRootLedgerReceivableStatusAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableStatusOrderBy>>;
  where?: Maybe<LedgerReceivableStatusBoolExp>;
};


export type QueryRootLedgerReceivableStatusByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootLedgerTotalReportMonthlyArgs = {
  distinct_on?: Maybe<Array<LedgerTotalReportMonthlySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerTotalReportMonthlyOrderBy>>;
  where?: Maybe<LedgerTotalReportMonthlyBoolExp>;
};


export type QueryRootLedgerTotalReportMonthlyAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerTotalReportMonthlySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerTotalReportMonthlyOrderBy>>;
  where?: Maybe<LedgerTotalReportMonthlyBoolExp>;
};


export type QueryRootLedgerTotalReportYearlyArgs = {
  distinct_on?: Maybe<Array<LedgerTotalReportYearlySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerTotalReportYearlyOrderBy>>;
  where?: Maybe<LedgerTotalReportYearlyBoolExp>;
};


export type QueryRootLedgerTotalReportYearlyAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerTotalReportYearlySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerTotalReportYearlyOrderBy>>;
  where?: Maybe<LedgerTotalReportYearlyBoolExp>;
};


export type QueryRootLoanAccountTypeArgs = {
  distinct_on?: Maybe<Array<LoanAccountTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanAccountTypeOrderBy>>;
  where?: Maybe<LoanAccountTypeBoolExp>;
};


export type QueryRootLoanAccountTypeAggregateArgs = {
  distinct_on?: Maybe<Array<LoanAccountTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanAccountTypeOrderBy>>;
  where?: Maybe<LoanAccountTypeBoolExp>;
};


export type QueryRootLoanAccountTypeByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootLoanApplicationAttachmentArgs = {
  distinct_on?: Maybe<Array<LoanApplicationAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationAttachmentOrderBy>>;
  where?: Maybe<LoanApplicationAttachmentBoolExp>;
};


export type QueryRootLoanApplicationAttachmentAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationAttachmentOrderBy>>;
  where?: Maybe<LoanApplicationAttachmentBoolExp>;
};


export type QueryRootLoanApplicationAttachmentByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanApplicationBankBranchTransferArgs = {
  distinct_on?: Maybe<Array<LoanApplicationBankBranchTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationBankBranchTransferOrderBy>>;
  where?: Maybe<LoanApplicationBankBranchTransferBoolExp>;
};


export type QueryRootLoanApplicationBankBranchTransferAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationBankBranchTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationBankBranchTransferOrderBy>>;
  where?: Maybe<LoanApplicationBankBranchTransferBoolExp>;
};


export type QueryRootLoanApplicationBankBranchTransferByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanApplicationBusinessOperationArgs = {
  distinct_on?: Maybe<Array<LoanApplicationBusinessOperationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationBusinessOperationOrderBy>>;
  where?: Maybe<LoanApplicationBusinessOperationBoolExp>;
};


export type QueryRootLoanApplicationBusinessOperationAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationBusinessOperationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationBusinessOperationOrderBy>>;
  where?: Maybe<LoanApplicationBusinessOperationBoolExp>;
};


export type QueryRootLoanApplicationBusinessOperationByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanApplicationCommitteeArgs = {
  distinct_on?: Maybe<Array<LoanApplicationCommitteeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationCommitteeOrderBy>>;
  where?: Maybe<LoanApplicationCommitteeBoolExp>;
};


export type QueryRootLoanApplicationCommitteeAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationCommitteeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationCommitteeOrderBy>>;
  where?: Maybe<LoanApplicationCommitteeBoolExp>;
};


export type QueryRootLoanApplicationCommitteeByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanApplicationCurrentLoanArgs = {
  distinct_on?: Maybe<Array<LoanApplicationCurrentLoanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationCurrentLoanOrderBy>>;
  where?: Maybe<LoanApplicationCurrentLoanBoolExp>;
};


export type QueryRootLoanApplicationCurrentLoanAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationCurrentLoanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationCurrentLoanOrderBy>>;
  where?: Maybe<LoanApplicationCurrentLoanBoolExp>;
};


export type QueryRootLoanApplicationCurrentLoanByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanApplicationDetailArgs = {
  distinct_on?: Maybe<Array<LoanApplicationDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationDetailOrderBy>>;
  where?: Maybe<LoanApplicationDetailBoolExp>;
};


export type QueryRootLoanApplicationDetailAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationDetailOrderBy>>;
  where?: Maybe<LoanApplicationDetailBoolExp>;
};


export type QueryRootLoanApplicationDetailByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanApplicationEventLogArgs = {
  distinct_on?: Maybe<Array<LoanApplicationEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationEventLogOrderBy>>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
};


export type QueryRootLoanApplicationEventLogAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationEventLogOrderBy>>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
};


export type QueryRootLoanApplicationEventLogByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanApplicationHeaderArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};


export type QueryRootLoanApplicationHeaderAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};


export type QueryRootLoanApplicationHeaderByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanApplicationHeaderPdfArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderPdfSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderPdfOrderBy>>;
  where?: Maybe<LoanApplicationHeaderPdfBoolExp>;
};


export type QueryRootLoanApplicationHeaderPdfAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderPdfSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderPdfOrderBy>>;
  where?: Maybe<LoanApplicationHeaderPdfBoolExp>;
};


export type QueryRootLoanApplicationHeaderPdfByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanApplicationObjectiveArgs = {
  distinct_on?: Maybe<Array<LoanApplicationObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationObjectiveOrderBy>>;
  where?: Maybe<LoanApplicationObjectiveBoolExp>;
};


export type QueryRootLoanApplicationObjectiveAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationObjectiveOrderBy>>;
  where?: Maybe<LoanApplicationObjectiveBoolExp>;
};


export type QueryRootLoanApplicationObjectiveByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanApplicationOrganizationTransferArgs = {
  distinct_on?: Maybe<Array<LoanApplicationOrganizationTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationOrganizationTransferOrderBy>>;
  where?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
};


export type QueryRootLoanApplicationOrganizationTransferAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationOrganizationTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationOrganizationTransferOrderBy>>;
  where?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
};


export type QueryRootLoanApplicationOrganizationTransferByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanApplicationProfileArgs = {
  distinct_on?: Maybe<Array<LoanApplicationProfileSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationProfileOrderBy>>;
  where?: Maybe<LoanApplicationProfileBoolExp>;
};


export type QueryRootLoanApplicationProfileAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationProfileSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationProfileOrderBy>>;
  where?: Maybe<LoanApplicationProfileBoolExp>;
};


export type QueryRootLoanApplicationProfileByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanApplicationProfileSCurveArgs = {
  distinct_on?: Maybe<Array<LoanApplicationProfileSCurveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationProfileSCurveOrderBy>>;
  where?: Maybe<LoanApplicationProfileSCurveBoolExp>;
};


export type QueryRootLoanApplicationProfileSCurveAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationProfileSCurveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationProfileSCurveOrderBy>>;
  where?: Maybe<LoanApplicationProfileSCurveBoolExp>;
};


export type QueryRootLoanApplicationProfileSCurveByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanApplicationSequenceNoArgs = {
  distinct_on?: Maybe<Array<LoanApplicationSequenceNoSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationSequenceNoOrderBy>>;
  where?: Maybe<LoanApplicationSequenceNoBoolExp>;
};


export type QueryRootLoanApplicationSequenceNoAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationSequenceNoSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationSequenceNoOrderBy>>;
  where?: Maybe<LoanApplicationSequenceNoBoolExp>;
};


export type QueryRootLoanApplicationSequenceNoByPkArgs = {
  organization_id: Scalars['uuid'];
  program_id: Scalars['uuid'];
  year: Scalars['Int'];
};


export type QueryRootLoanApplicationStepArgs = {
  distinct_on?: Maybe<Array<LoanApplicationStepSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationStepOrderBy>>;
  where?: Maybe<LoanApplicationStepBoolExp>;
};


export type QueryRootLoanApplicationStepAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationStepSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationStepOrderBy>>;
  where?: Maybe<LoanApplicationStepBoolExp>;
};


export type QueryRootLoanApplicationStepByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootLoanAttachmentTypeArgs = {
  distinct_on?: Maybe<Array<LoanAttachmentTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanAttachmentTypeOrderBy>>;
  where?: Maybe<LoanAttachmentTypeBoolExp>;
};


export type QueryRootLoanAttachmentTypeAggregateArgs = {
  distinct_on?: Maybe<Array<LoanAttachmentTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanAttachmentTypeOrderBy>>;
  where?: Maybe<LoanAttachmentTypeBoolExp>;
};


export type QueryRootLoanAttachmentTypeByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootLoanBankApproveArgs = {
  distinct_on?: Maybe<Array<LoanBankApproveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBankApproveOrderBy>>;
  where?: Maybe<LoanBankApproveBoolExp>;
};


export type QueryRootLoanBankApproveAggregateArgs = {
  distinct_on?: Maybe<Array<LoanBankApproveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBankApproveOrderBy>>;
  where?: Maybe<LoanBankApproveBoolExp>;
};


export type QueryRootLoanBankApproveByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootLoanBusinessGroupArgs = {
  distinct_on?: Maybe<Array<LoanBusinessGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessGroupOrderBy>>;
  where?: Maybe<LoanBusinessGroupBoolExp>;
};


export type QueryRootLoanBusinessGroupAggregateArgs = {
  distinct_on?: Maybe<Array<LoanBusinessGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessGroupOrderBy>>;
  where?: Maybe<LoanBusinessGroupBoolExp>;
};


export type QueryRootLoanBusinessGroupByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanBusinessOperationArgs = {
  distinct_on?: Maybe<Array<LoanBusinessOperationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessOperationOrderBy>>;
  where?: Maybe<LoanBusinessOperationBoolExp>;
};


export type QueryRootLoanBusinessOperationAggregateArgs = {
  distinct_on?: Maybe<Array<LoanBusinessOperationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessOperationOrderBy>>;
  where?: Maybe<LoanBusinessOperationBoolExp>;
};


export type QueryRootLoanBusinessOperationByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootLoanBusinessProprietaryArgs = {
  distinct_on?: Maybe<Array<LoanBusinessProprietarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessProprietaryOrderBy>>;
  where?: Maybe<LoanBusinessProprietaryBoolExp>;
};


export type QueryRootLoanBusinessProprietaryAggregateArgs = {
  distinct_on?: Maybe<Array<LoanBusinessProprietarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessProprietaryOrderBy>>;
  where?: Maybe<LoanBusinessProprietaryBoolExp>;
};


export type QueryRootLoanBusinessProprietaryByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanBusinessStatusArgs = {
  distinct_on?: Maybe<Array<LoanBusinessStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessStatusOrderBy>>;
  where?: Maybe<LoanBusinessStatusBoolExp>;
};


export type QueryRootLoanBusinessStatusAggregateArgs = {
  distinct_on?: Maybe<Array<LoanBusinessStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessStatusOrderBy>>;
  where?: Maybe<LoanBusinessStatusBoolExp>;
};


export type QueryRootLoanBusinessStatusByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanBusinessTypeArgs = {
  distinct_on?: Maybe<Array<LoanBusinessTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessTypeOrderBy>>;
  where?: Maybe<LoanBusinessTypeBoolExp>;
};


export type QueryRootLoanBusinessTypeAggregateArgs = {
  distinct_on?: Maybe<Array<LoanBusinessTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessTypeOrderBy>>;
  where?: Maybe<LoanBusinessTypeBoolExp>;
};


export type QueryRootLoanBusinessTypeByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootLoanCommitteeApproveArgs = {
  distinct_on?: Maybe<Array<LoanCommitteeApproveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanCommitteeApproveOrderBy>>;
  where?: Maybe<LoanCommitteeApproveBoolExp>;
};


export type QueryRootLoanCommitteeApproveAggregateArgs = {
  distinct_on?: Maybe<Array<LoanCommitteeApproveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanCommitteeApproveOrderBy>>;
  where?: Maybe<LoanCommitteeApproveBoolExp>;
};


export type QueryRootLoanCommitteeApproveByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootLoanCurrentLoanArgs = {
  distinct_on?: Maybe<Array<LoanCurrentLoanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanCurrentLoanOrderBy>>;
  where?: Maybe<LoanCurrentLoanBoolExp>;
};


export type QueryRootLoanCurrentLoanAggregateArgs = {
  distinct_on?: Maybe<Array<LoanCurrentLoanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanCurrentLoanOrderBy>>;
  where?: Maybe<LoanCurrentLoanBoolExp>;
};


export type QueryRootLoanCurrentLoanByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootLoanDebtRestructuringArgs = {
  distinct_on?: Maybe<Array<LoanDebtRestructuringSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanDebtRestructuringOrderBy>>;
  where?: Maybe<LoanDebtRestructuringBoolExp>;
};


export type QueryRootLoanDebtRestructuringAggregateArgs = {
  distinct_on?: Maybe<Array<LoanDebtRestructuringSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanDebtRestructuringOrderBy>>;
  where?: Maybe<LoanDebtRestructuringBoolExp>;
};


export type QueryRootLoanDebtRestructuringByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootLoanDocumentApproveArgs = {
  distinct_on?: Maybe<Array<LoanDocumentApproveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanDocumentApproveOrderBy>>;
  where?: Maybe<LoanDocumentApproveBoolExp>;
};


export type QueryRootLoanDocumentApproveAggregateArgs = {
  distinct_on?: Maybe<Array<LoanDocumentApproveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanDocumentApproveOrderBy>>;
  where?: Maybe<LoanDocumentApproveBoolExp>;
};


export type QueryRootLoanDocumentApproveByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootLoanFinancialAgreementArgs = {
  distinct_on?: Maybe<Array<LoanFinancialAgreementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanFinancialAgreementOrderBy>>;
  where?: Maybe<LoanFinancialAgreementBoolExp>;
};


export type QueryRootLoanFinancialAgreementAggregateArgs = {
  distinct_on?: Maybe<Array<LoanFinancialAgreementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanFinancialAgreementOrderBy>>;
  where?: Maybe<LoanFinancialAgreementBoolExp>;
};


export type QueryRootLoanFinancialAgreementByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootLoanFinancialRescueArgs = {
  distinct_on?: Maybe<Array<LoanFinancialRescueSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanFinancialRescueOrderBy>>;
  where?: Maybe<LoanFinancialRescueBoolExp>;
};


export type QueryRootLoanFinancialRescueAggregateArgs = {
  distinct_on?: Maybe<Array<LoanFinancialRescueSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanFinancialRescueOrderBy>>;
  where?: Maybe<LoanFinancialRescueBoolExp>;
};


export type QueryRootLoanFinancialRescueByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootLoanObjectiveArgs = {
  distinct_on?: Maybe<Array<LoanObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanObjectiveOrderBy>>;
  where?: Maybe<LoanObjectiveBoolExp>;
};


export type QueryRootLoanObjectiveAggregateArgs = {
  distinct_on?: Maybe<Array<LoanObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanObjectiveOrderBy>>;
  where?: Maybe<LoanObjectiveBoolExp>;
};


export type QueryRootLoanObjectiveByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootLoanProgramArgs = {
  distinct_on?: Maybe<Array<LoanProgramSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramOrderBy>>;
  where?: Maybe<LoanProgramBoolExp>;
};


export type QueryRootLoanProgramAggregateArgs = {
  distinct_on?: Maybe<Array<LoanProgramSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramOrderBy>>;
  where?: Maybe<LoanProgramBoolExp>;
};


export type QueryRootLoanProgramByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanProgramStatusArgs = {
  distinct_on?: Maybe<Array<LoanProgramStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramStatusOrderBy>>;
  where?: Maybe<LoanProgramStatusBoolExp>;
};


export type QueryRootLoanProgramStatusAggregateArgs = {
  distinct_on?: Maybe<Array<LoanProgramStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramStatusOrderBy>>;
  where?: Maybe<LoanProgramStatusBoolExp>;
};


export type QueryRootLoanProgramStatusByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootLoanProgramTransactionArgs = {
  distinct_on?: Maybe<Array<LoanProgramTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramTransactionOrderBy>>;
  where?: Maybe<LoanProgramTransactionBoolExp>;
};


export type QueryRootLoanProgramTransactionAggregateArgs = {
  distinct_on?: Maybe<Array<LoanProgramTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramTransactionOrderBy>>;
  where?: Maybe<LoanProgramTransactionBoolExp>;
};


export type QueryRootLoanProgramTransactionByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanProgramTransactionTypeArgs = {
  distinct_on?: Maybe<Array<LoanProgramTransactionTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramTransactionTypeOrderBy>>;
  where?: Maybe<LoanProgramTransactionTypeBoolExp>;
};


export type QueryRootLoanProgramTransactionTypeAggregateArgs = {
  distinct_on?: Maybe<Array<LoanProgramTransactionTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramTransactionTypeOrderBy>>;
  where?: Maybe<LoanProgramTransactionTypeBoolExp>;
};


export type QueryRootLoanProgramTransactionTypeByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanProprietaryArgs = {
  distinct_on?: Maybe<Array<LoanProprietarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProprietaryOrderBy>>;
  where?: Maybe<LoanProprietaryBoolExp>;
};


export type QueryRootLoanProprietaryAggregateArgs = {
  distinct_on?: Maybe<Array<LoanProprietarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProprietaryOrderBy>>;
  where?: Maybe<LoanProprietaryBoolExp>;
};


export type QueryRootLoanProprietaryByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootLoanRepayTypeArgs = {
  distinct_on?: Maybe<Array<LoanRepayTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanRepayTypeOrderBy>>;
  where?: Maybe<LoanRepayTypeBoolExp>;
};


export type QueryRootLoanRepayTypeAggregateArgs = {
  distinct_on?: Maybe<Array<LoanRepayTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanRepayTypeOrderBy>>;
  where?: Maybe<LoanRepayTypeBoolExp>;
};


export type QueryRootLoanRepayTypeByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootLoanSCurveArgs = {
  distinct_on?: Maybe<Array<LoanSCurveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSCurveOrderBy>>;
  where?: Maybe<LoanSCurveBoolExp>;
};


export type QueryRootLoanSCurveAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSCurveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSCurveOrderBy>>;
  where?: Maybe<LoanSCurveBoolExp>;
};


export type QueryRootLoanSCurveByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootLoanSCurveTypeArgs = {
  distinct_on?: Maybe<Array<LoanSCurveTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSCurveTypeOrderBy>>;
  where?: Maybe<LoanSCurveTypeBoolExp>;
};


export type QueryRootLoanSCurveTypeAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSCurveTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSCurveTypeOrderBy>>;
  where?: Maybe<LoanSCurveTypeBoolExp>;
};


export type QueryRootLoanSCurveTypeByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanSmeDefinitionArgs = {
  distinct_on?: Maybe<Array<LoanSmeDefinitionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSmeDefinitionOrderBy>>;
  where?: Maybe<LoanSmeDefinitionBoolExp>;
};


export type QueryRootLoanSmeDefinitionAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSmeDefinitionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSmeDefinitionOrderBy>>;
  where?: Maybe<LoanSmeDefinitionBoolExp>;
};


export type QueryRootLoanSmeDefinitionByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanSourceBusinessTypeArgs = {
  distinct_on?: Maybe<Array<LoanSourceBusinessTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceBusinessTypeOrderBy>>;
  where?: Maybe<LoanSourceBusinessTypeBoolExp>;
};


export type QueryRootLoanSourceBusinessTypeAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSourceBusinessTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceBusinessTypeOrderBy>>;
  where?: Maybe<LoanSourceBusinessTypeBoolExp>;
};


export type QueryRootLoanSourceBusinessTypeByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootLoanSourceFinancialInstitutionArgs = {
  distinct_on?: Maybe<Array<LoanSourceFinancialInstitutionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceFinancialInstitutionOrderBy>>;
  where?: Maybe<LoanSourceFinancialInstitutionBoolExp>;
};


export type QueryRootLoanSourceFinancialInstitutionAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSourceFinancialInstitutionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceFinancialInstitutionOrderBy>>;
  where?: Maybe<LoanSourceFinancialInstitutionBoolExp>;
};


export type QueryRootLoanSourceFinancialInstitutionByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootLoanSourceLoanArgs = {
  distinct_on?: Maybe<Array<LoanSourceLoanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceLoanOrderBy>>;
  where?: Maybe<LoanSourceLoanBoolExp>;
};


export type QueryRootLoanSourceLoanAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSourceLoanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceLoanOrderBy>>;
  where?: Maybe<LoanSourceLoanBoolExp>;
};


export type QueryRootLoanSourceLoanAttachmentArgs = {
  distinct_on?: Maybe<Array<LoanSourceLoanAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceLoanAttachmentOrderBy>>;
  where?: Maybe<LoanSourceLoanAttachmentBoolExp>;
};


export type QueryRootLoanSourceLoanAttachmentAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSourceLoanAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceLoanAttachmentOrderBy>>;
  where?: Maybe<LoanSourceLoanAttachmentBoolExp>;
};


export type QueryRootLoanSourceLoanAttachmentByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanSourceLoanBusinessTypeArgs = {
  distinct_on?: Maybe<Array<LoanSourceLoanBusinessTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceLoanBusinessTypeOrderBy>>;
  where?: Maybe<LoanSourceLoanBusinessTypeBoolExp>;
};


export type QueryRootLoanSourceLoanBusinessTypeAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSourceLoanBusinessTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceLoanBusinessTypeOrderBy>>;
  where?: Maybe<LoanSourceLoanBusinessTypeBoolExp>;
};


export type QueryRootLoanSourceLoanBusinessTypeByPkArgs = {
  business_type_id: Scalars['String'];
  loan_id: Scalars['uuid'];
};


export type QueryRootLoanSourceLoanByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanSourceObjectiveArgs = {
  distinct_on?: Maybe<Array<LoanSourceObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceObjectiveOrderBy>>;
  where?: Maybe<LoanSourceObjectiveBoolExp>;
};


export type QueryRootLoanSourceObjectiveAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSourceObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceObjectiveOrderBy>>;
  where?: Maybe<LoanSourceObjectiveBoolExp>;
};


export type QueryRootLoanSourceObjectiveByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanSourceSearchLogArgs = {
  distinct_on?: Maybe<Array<LoanSourceSearchLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceSearchLogOrderBy>>;
  where?: Maybe<LoanSourceSearchLogBoolExp>;
};


export type QueryRootLoanSourceSearchLogAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSourceSearchLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceSearchLogOrderBy>>;
  where?: Maybe<LoanSourceSearchLogBoolExp>;
};


export type QueryRootLoanSourceSearchLogByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLoanTransferStatusArgs = {
  distinct_on?: Maybe<Array<LoanTransferStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanTransferStatusOrderBy>>;
  where?: Maybe<LoanTransferStatusBoolExp>;
};


export type QueryRootLoanTransferStatusAggregateArgs = {
  distinct_on?: Maybe<Array<LoanTransferStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanTransferStatusOrderBy>>;
  where?: Maybe<LoanTransferStatusBoolExp>;
};


export type QueryRootLoanTransferStatusByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootMeArgs = {
  distinct_on?: Maybe<Array<MeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MeOrderBy>>;
  where?: Maybe<MeBoolExp>;
};


export type QueryRootMeAggregateArgs = {
  distinct_on?: Maybe<Array<MeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MeOrderBy>>;
  where?: Maybe<MeBoolExp>;
};


export type QueryRootMonthOfFiscalYearArgs = {
  distinct_on?: Maybe<Array<MonthOfFiscalYearSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MonthOfFiscalYearOrderBy>>;
  where?: Maybe<MonthOfFiscalYearBoolExp>;
};


export type QueryRootMonthOfFiscalYearAggregateArgs = {
  distinct_on?: Maybe<Array<MonthOfFiscalYearSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MonthOfFiscalYearOrderBy>>;
  where?: Maybe<MonthOfFiscalYearBoolExp>;
};


export type QueryRootNameTitleArgs = {
  distinct_on?: Maybe<Array<NameTitleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NameTitleOrderBy>>;
  where?: Maybe<NameTitleBoolExp>;
};


export type QueryRootNameTitleAggregateArgs = {
  distinct_on?: Maybe<Array<NameTitleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NameTitleOrderBy>>;
  where?: Maybe<NameTitleBoolExp>;
};


export type QueryRootNameTitleByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootNotificationArgs = {
  distinct_on?: Maybe<Array<NotificationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NotificationOrderBy>>;
  where?: Maybe<NotificationBoolExp>;
};


export type QueryRootNotificationAggregateArgs = {
  distinct_on?: Maybe<Array<NotificationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NotificationOrderBy>>;
  where?: Maybe<NotificationBoolExp>;
};


export type QueryRootNotificationByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootNotificationStatusArgs = {
  distinct_on?: Maybe<Array<NotificationStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NotificationStatusOrderBy>>;
  where?: Maybe<NotificationStatusBoolExp>;
};


export type QueryRootNotificationStatusAggregateArgs = {
  distinct_on?: Maybe<Array<NotificationStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NotificationStatusOrderBy>>;
  where?: Maybe<NotificationStatusBoolExp>;
};


export type QueryRootNotificationStatusByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootOrganizationArgs = {
  distinct_on?: Maybe<Array<OrganizationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrganizationOrderBy>>;
  where?: Maybe<OrganizationBoolExp>;
};


export type QueryRootOrganizationAggregateArgs = {
  distinct_on?: Maybe<Array<OrganizationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrganizationOrderBy>>;
  where?: Maybe<OrganizationBoolExp>;
};


export type QueryRootOrganizationByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootOrganizationOtherArgs = {
  distinct_on?: Maybe<Array<OrganizationOtherSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrganizationOtherOrderBy>>;
  where?: Maybe<OrganizationOtherBoolExp>;
};


export type QueryRootOrganizationOtherAggregateArgs = {
  distinct_on?: Maybe<Array<OrganizationOtherSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrganizationOtherOrderBy>>;
  where?: Maybe<OrganizationOtherBoolExp>;
};


export type QueryRootOrganizationProvinceArgs = {
  distinct_on?: Maybe<Array<OrganizationProvinceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrganizationProvinceOrderBy>>;
  where?: Maybe<OrganizationProvinceBoolExp>;
};


export type QueryRootOrganizationProvinceAggregateArgs = {
  distinct_on?: Maybe<Array<OrganizationProvinceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrganizationProvinceOrderBy>>;
  where?: Maybe<OrganizationProvinceBoolExp>;
};


export type QueryRootOrganizationTypeArgs = {
  distinct_on?: Maybe<Array<OrganizationTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrganizationTypeOrderBy>>;
  where?: Maybe<OrganizationTypeBoolExp>;
};


export type QueryRootOrganizationTypeAggregateArgs = {
  distinct_on?: Maybe<Array<OrganizationTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrganizationTypeOrderBy>>;
  where?: Maybe<OrganizationTypeBoolExp>;
};


export type QueryRootOrganizationTypeByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootPermissionArgs = {
  distinct_on?: Maybe<Array<PermissionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PermissionOrderBy>>;
  where?: Maybe<PermissionBoolExp>;
};


export type QueryRootPermissionAggregateArgs = {
  distinct_on?: Maybe<Array<PermissionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PermissionOrderBy>>;
  where?: Maybe<PermissionBoolExp>;
};


export type QueryRootPermissionByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootPostsArgs = {
  distinct_on?: Maybe<Array<PostsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PostsOrderBy>>;
  where?: Maybe<PostsBoolExp>;
};


export type QueryRootPostsAggregateArgs = {
  distinct_on?: Maybe<Array<PostsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PostsOrderBy>>;
  where?: Maybe<PostsBoolExp>;
};


export type QueryRootPostsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootProjectApprovedArgs = {
  distinct_on?: Maybe<Array<ProjectApprovedSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectApprovedOrderBy>>;
  where?: Maybe<ProjectApprovedBoolExp>;
};


export type QueryRootProjectApprovedAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectApprovedSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectApprovedOrderBy>>;
  where?: Maybe<ProjectApprovedBoolExp>;
};


export type QueryRootProjectApprovedByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootProjectAttachmentTypeArgs = {
  distinct_on?: Maybe<Array<ProjectAttachmentTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectAttachmentTypeOrderBy>>;
  where?: Maybe<ProjectAttachmentTypeBoolExp>;
};


export type QueryRootProjectAttachmentTypeAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectAttachmentTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectAttachmentTypeOrderBy>>;
  where?: Maybe<ProjectAttachmentTypeBoolExp>;
};


export type QueryRootProjectAttachmentTypeByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootProjectBudgetArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetOrderBy>>;
  where?: Maybe<ProjectBudgetBoolExp>;
};


export type QueryRootProjectBudgetAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetOrderBy>>;
  where?: Maybe<ProjectBudgetBoolExp>;
};


export type QueryRootProjectBudgetByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootProjectBudgetGroupArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetGroupOrderBy>>;
  where?: Maybe<ProjectBudgetGroupBoolExp>;
};


export type QueryRootProjectBudgetGroupAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetGroupOrderBy>>;
  where?: Maybe<ProjectBudgetGroupBoolExp>;
};


export type QueryRootProjectBudgetGroupByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootProjectBudgetGroupMappingArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetGroupMappingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetGroupMappingOrderBy>>;
  where?: Maybe<ProjectBudgetGroupMappingBoolExp>;
};


export type QueryRootProjectBudgetGroupMappingAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetGroupMappingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetGroupMappingOrderBy>>;
  where?: Maybe<ProjectBudgetGroupMappingBoolExp>;
};


export type QueryRootProjectBudgetGroupMappingByPkArgs = {
  budget_group_id: ProjectBudgetGroupEnum;
  budget_id: Scalars['uuid'];
};


export type QueryRootProjectBudgetPlanArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetPlanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetPlanOrderBy>>;
  where?: Maybe<ProjectBudgetPlanBoolExp>;
};


export type QueryRootProjectBudgetPlanAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetPlanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetPlanOrderBy>>;
  where?: Maybe<ProjectBudgetPlanBoolExp>;
};


export type QueryRootProjectIndicatorTypeArgs = {
  distinct_on?: Maybe<Array<ProjectIndicatorTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectIndicatorTypeOrderBy>>;
  where?: Maybe<ProjectIndicatorTypeBoolExp>;
};


export type QueryRootProjectIndicatorTypeAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectIndicatorTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectIndicatorTypeOrderBy>>;
  where?: Maybe<ProjectIndicatorTypeBoolExp>;
};


export type QueryRootProjectIndicatorTypeByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootProjectMasterPlanArgs = {
  distinct_on?: Maybe<Array<ProjectMasterPlanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectMasterPlanOrderBy>>;
  where?: Maybe<ProjectMasterPlanBoolExp>;
};


export type QueryRootProjectMasterPlanAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectMasterPlanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectMasterPlanOrderBy>>;
  where?: Maybe<ProjectMasterPlanBoolExp>;
};


export type QueryRootProjectNationalStrategyArgs = {
  distinct_on?: Maybe<Array<ProjectNationalStrategySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectNationalStrategyOrderBy>>;
  where?: Maybe<ProjectNationalStrategyBoolExp>;
};


export type QueryRootProjectNationalStrategyAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectNationalStrategySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectNationalStrategyOrderBy>>;
  where?: Maybe<ProjectNationalStrategyBoolExp>;
};


export type QueryRootProjectNationalStrategyByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootProjectOperatingActivityProgressArgs = {
  distinct_on?: Maybe<Array<ProjectOperatingActivityProgressSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectOperatingActivityProgressOrderBy>>;
  where?: Maybe<ProjectOperatingActivityProgressBoolExp>;
};


export type QueryRootProjectOperatingActivityProgressAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectOperatingActivityProgressSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectOperatingActivityProgressOrderBy>>;
  where?: Maybe<ProjectOperatingActivityProgressBoolExp>;
};


export type QueryRootProjectOperatingActivityProgressByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootProjectOperatingActivityProgressRevisionArgs = {
  distinct_on?: Maybe<Array<ProjectOperatingActivityProgressRevisionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectOperatingActivityProgressRevisionOrderBy>>;
  where?: Maybe<ProjectOperatingActivityProgressRevisionBoolExp>;
};


export type QueryRootProjectOperatingActivityProgressRevisionAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectOperatingActivityProgressRevisionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectOperatingActivityProgressRevisionOrderBy>>;
  where?: Maybe<ProjectOperatingActivityProgressRevisionBoolExp>;
};


export type QueryRootProjectOperatingActivityProgressRevisionByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootProjectOperatingActivityTypeArgs = {
  distinct_on?: Maybe<Array<ProjectOperatingActivityTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectOperatingActivityTypeOrderBy>>;
  where?: Maybe<ProjectOperatingActivityTypeBoolExp>;
};


export type QueryRootProjectOperatingActivityTypeAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectOperatingActivityTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectOperatingActivityTypeOrderBy>>;
  where?: Maybe<ProjectOperatingActivityTypeBoolExp>;
};


export type QueryRootProjectOperatingActivityTypeByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootProjectProjectArgs = {
  distinct_on?: Maybe<Array<ProjectProjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOrderBy>>;
  where?: Maybe<ProjectProjectBoolExp>;
};


export type QueryRootProjectProjectAchievementArgs = {
  distinct_on?: Maybe<Array<ProjectProjectAchievementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectAchievementOrderBy>>;
  where?: Maybe<ProjectProjectAchievementBoolExp>;
};


export type QueryRootProjectProjectAchievementAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectAchievementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectAchievementOrderBy>>;
  where?: Maybe<ProjectProjectAchievementBoolExp>;
};


export type QueryRootProjectProjectAchievementByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootProjectProjectAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOrderBy>>;
  where?: Maybe<ProjectProjectBoolExp>;
};


export type QueryRootProjectProjectAttachmentArgs = {
  distinct_on?: Maybe<Array<ProjectProjectAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectAttachmentOrderBy>>;
  where?: Maybe<ProjectProjectAttachmentBoolExp>;
};


export type QueryRootProjectProjectAttachmentAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectAttachmentOrderBy>>;
  where?: Maybe<ProjectProjectAttachmentBoolExp>;
};


export type QueryRootProjectProjectAttachmentByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootProjectProjectBudgetItemArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetItemOrderBy>>;
  where?: Maybe<ProjectProjectBudgetItemBoolExp>;
};


export type QueryRootProjectProjectBudgetItemAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetItemOrderBy>>;
  where?: Maybe<ProjectProjectBudgetItemBoolExp>;
};


export type QueryRootProjectProjectBudgetItemByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootProjectProjectBudgetItemSummaryArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetItemSummarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetItemSummaryOrderBy>>;
  where?: Maybe<ProjectProjectBudgetItemSummaryBoolExp>;
};


export type QueryRootProjectProjectBudgetItemSummaryAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetItemSummarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetItemSummaryOrderBy>>;
  where?: Maybe<ProjectProjectBudgetItemSummaryBoolExp>;
};


export type QueryRootProjectProjectBudgetSummaryArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetSummarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetSummaryOrderBy>>;
  where?: Maybe<ProjectProjectBudgetSummaryBoolExp>;
};


export type QueryRootProjectProjectBudgetSummaryAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetSummarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetSummaryOrderBy>>;
  where?: Maybe<ProjectProjectBudgetSummaryBoolExp>;
};


export type QueryRootProjectProjectBudgetTemplateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetTemplateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetTemplateOrderBy>>;
  where?: Maybe<ProjectProjectBudgetTemplateBoolExp>;
};


export type QueryRootProjectProjectBudgetTemplateAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetTemplateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetTemplateOrderBy>>;
  where?: Maybe<ProjectProjectBudgetTemplateBoolExp>;
};


export type QueryRootProjectProjectByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootProjectProjectEventLogArgs = {
  distinct_on?: Maybe<Array<ProjectProjectEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectEventLogOrderBy>>;
  where?: Maybe<ProjectProjectEventLogBoolExp>;
};


export type QueryRootProjectProjectEventLogAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectEventLogOrderBy>>;
  where?: Maybe<ProjectProjectEventLogBoolExp>;
};


export type QueryRootProjectProjectEventLogByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootProjectProjectIndicatorArgs = {
  distinct_on?: Maybe<Array<ProjectProjectIndicatorSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectIndicatorOrderBy>>;
  where?: Maybe<ProjectProjectIndicatorBoolExp>;
};


export type QueryRootProjectProjectIndicatorAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectIndicatorSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectIndicatorOrderBy>>;
  where?: Maybe<ProjectProjectIndicatorBoolExp>;
};


export type QueryRootProjectProjectIndicatorByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootProjectProjectMemberArgs = {
  distinct_on?: Maybe<Array<ProjectProjectMemberSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectMemberOrderBy>>;
  where?: Maybe<ProjectProjectMemberBoolExp>;
};


export type QueryRootProjectProjectMemberAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectMemberSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectMemberOrderBy>>;
  where?: Maybe<ProjectProjectMemberBoolExp>;
};


export type QueryRootProjectProjectMemberByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootProjectProjectObjectiveArgs = {
  distinct_on?: Maybe<Array<ProjectProjectObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectObjectiveOrderBy>>;
  where?: Maybe<ProjectProjectObjectiveBoolExp>;
};


export type QueryRootProjectProjectObjectiveAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectObjectiveOrderBy>>;
  where?: Maybe<ProjectProjectObjectiveBoolExp>;
};


export type QueryRootProjectProjectObjectiveByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootProjectProjectOperatingActivityArgs = {
  distinct_on?: Maybe<Array<ProjectProjectOperatingActivitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOperatingActivityOrderBy>>;
  where?: Maybe<ProjectProjectOperatingActivityBoolExp>;
};


export type QueryRootProjectProjectOperatingActivityAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectOperatingActivitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOperatingActivityOrderBy>>;
  where?: Maybe<ProjectProjectOperatingActivityBoolExp>;
};


export type QueryRootProjectProjectOperatingActivityByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootProjectProjectOperatingAreaArgs = {
  distinct_on?: Maybe<Array<ProjectProjectOperatingAreaSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOperatingAreaOrderBy>>;
  where?: Maybe<ProjectProjectOperatingAreaBoolExp>;
};


export type QueryRootProjectProjectOperatingAreaAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectOperatingAreaSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOperatingAreaOrderBy>>;
  where?: Maybe<ProjectProjectOperatingAreaBoolExp>;
};


export type QueryRootProjectProjectOperatingAreaByPkArgs = {
  project_id: Scalars['uuid'];
  province_id: Scalars['uuid'];
};


export type QueryRootProjectProjectStepArgs = {
  distinct_on?: Maybe<Array<ProjectProjectStepSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectStepOrderBy>>;
  where?: Maybe<ProjectProjectStepBoolExp>;
};


export type QueryRootProjectProjectStepAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectStepSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectStepOrderBy>>;
  where?: Maybe<ProjectProjectStepBoolExp>;
};


export type QueryRootProjectProjectStepByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootProjectProjectTargetGroupArgs = {
  distinct_on?: Maybe<Array<ProjectProjectTargetGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectTargetGroupOrderBy>>;
  where?: Maybe<ProjectProjectTargetGroupBoolExp>;
};


export type QueryRootProjectProjectTargetGroupAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectTargetGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectTargetGroupOrderBy>>;
  where?: Maybe<ProjectProjectTargetGroupBoolExp>;
};


export type QueryRootProjectProjectTargetGroupByPkArgs = {
  project_id: Scalars['uuid'];
  target_group_id: Scalars['String'];
};


export type QueryRootProjectProjectWorkloadSummaryArgs = {
  distinct_on?: Maybe<Array<ProjectProjectWorkloadSummarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectWorkloadSummaryOrderBy>>;
  where?: Maybe<ProjectProjectWorkloadSummaryBoolExp>;
};


export type QueryRootProjectProjectWorkloadSummaryAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectWorkloadSummarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectWorkloadSummaryOrderBy>>;
  where?: Maybe<ProjectProjectWorkloadSummaryBoolExp>;
};


export type QueryRootProjectSubPlanArgs = {
  distinct_on?: Maybe<Array<ProjectSubPlanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectSubPlanOrderBy>>;
  where?: Maybe<ProjectSubPlanBoolExp>;
};


export type QueryRootProjectSubPlanAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectSubPlanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectSubPlanOrderBy>>;
  where?: Maybe<ProjectSubPlanBoolExp>;
};


export type QueryRootProjectTargetGroupArgs = {
  distinct_on?: Maybe<Array<ProjectTargetGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectTargetGroupOrderBy>>;
  where?: Maybe<ProjectTargetGroupBoolExp>;
};


export type QueryRootProjectTargetGroupAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectTargetGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectTargetGroupOrderBy>>;
  where?: Maybe<ProjectTargetGroupBoolExp>;
};


export type QueryRootProjectTargetGroupByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootProjectUnitArgs = {
  distinct_on?: Maybe<Array<ProjectUnitSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectUnitOrderBy>>;
  where?: Maybe<ProjectUnitBoolExp>;
};


export type QueryRootProjectUnitAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectUnitSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectUnitOrderBy>>;
  where?: Maybe<ProjectUnitBoolExp>;
};


export type QueryRootProjectUnitByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootProvinceArgs = {
  distinct_on?: Maybe<Array<ProvinceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProvinceOrderBy>>;
  where?: Maybe<ProvinceBoolExp>;
};


export type QueryRootProvinceAggregateArgs = {
  distinct_on?: Maybe<Array<ProvinceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProvinceOrderBy>>;
  where?: Maybe<ProvinceBoolExp>;
};


export type QueryRootProvinceByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootRegionArgs = {
  distinct_on?: Maybe<Array<RegionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RegionOrderBy>>;
  where?: Maybe<RegionBoolExp>;
};


export type QueryRootRegionAggregateArgs = {
  distinct_on?: Maybe<Array<RegionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RegionOrderBy>>;
  where?: Maybe<RegionBoolExp>;
};


export type QueryRootRegionByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootUserMappingArgs = {
  distinct_on?: Maybe<Array<UserMappingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserMappingOrderBy>>;
  where?: Maybe<UserMappingBoolExp>;
};


export type QueryRootUserMappingAggregateArgs = {
  distinct_on?: Maybe<Array<UserMappingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserMappingOrderBy>>;
  where?: Maybe<UserMappingBoolExp>;
};


export type QueryRootUserMappingByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootUsersArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};


export type QueryRootUsersAggregateArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};


export type QueryRootUsersByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootUsersPermissionArgs = {
  distinct_on?: Maybe<Array<UsersPermissionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersPermissionOrderBy>>;
  where?: Maybe<UsersPermissionBoolExp>;
};


export type QueryRootUsersPermissionAggregateArgs = {
  distinct_on?: Maybe<Array<UsersPermissionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersPermissionOrderBy>>;
  where?: Maybe<UsersPermissionBoolExp>;
};


export type QueryRootUsersPermissionByPkArgs = {
  permission_id: Scalars['String'];
  user_id: Scalars['uuid'];
};

/**
 * ภูมิภาคจังหวัด
 *
 *
 * columns and relationships of "region"
 */
export type Region = {
  __typename?: 'region';
  /** Primary key */
  id: Scalars['uuid'];
  /** ชื่อภูมิภาคอังกฤษ */
  name_en?: Maybe<Scalars['String']>;
  /** ชื่อภูมิภาคไทย */
  name_th?: Maybe<Scalars['String']>;
  /** An array relationship */
  provinces: Array<Province>;
  /** An aggregate relationship */
  provinces_aggregate: ProvinceAggregate;
};


/**
 * ภูมิภาคจังหวัด
 *
 *
 * columns and relationships of "region"
 */
export type RegionProvincesArgs = {
  distinct_on?: Maybe<Array<ProvinceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProvinceOrderBy>>;
  where?: Maybe<ProvinceBoolExp>;
};


/**
 * ภูมิภาคจังหวัด
 *
 *
 * columns and relationships of "region"
 */
export type RegionProvincesAggregateArgs = {
  distinct_on?: Maybe<Array<ProvinceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProvinceOrderBy>>;
  where?: Maybe<ProvinceBoolExp>;
};

/** aggregated selection of "region" */
export type RegionAggregate = {
  __typename?: 'region_aggregate';
  aggregate?: Maybe<RegionAggregateFields>;
  nodes: Array<Region>;
};

/** aggregate fields of "region" */
export type RegionAggregateFields = {
  __typename?: 'region_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<RegionMaxFields>;
  min?: Maybe<RegionMinFields>;
};


/** aggregate fields of "region" */
export type RegionAggregateFieldsCountArgs = {
  columns?: Maybe<Array<RegionSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "region". All fields are combined with a logical 'AND'. */
export type RegionBoolExp = {
  _and?: Maybe<Array<RegionBoolExp>>;
  _not?: Maybe<RegionBoolExp>;
  _or?: Maybe<Array<RegionBoolExp>>;
  id?: Maybe<UuidComparisonExp>;
  name_en?: Maybe<StringComparisonExp>;
  name_th?: Maybe<StringComparisonExp>;
  provinces?: Maybe<ProvinceBoolExp>;
};

/** unique or primary key constraints on table "region" */
export enum RegionConstraint {
  /** unique or primary key constraint */
  RegionPkey = 'region_pkey'
}

/** input type for inserting data into table "region" */
export type RegionInsertInput = {
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อภูมิภาคอังกฤษ */
  name_en?: Maybe<Scalars['String']>;
  /** ชื่อภูมิภาคไทย */
  name_th?: Maybe<Scalars['String']>;
  provinces?: Maybe<ProvinceArrRelInsertInput>;
};

/** aggregate max on columns */
export type RegionMaxFields = {
  __typename?: 'region_max_fields';
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อภูมิภาคอังกฤษ */
  name_en?: Maybe<Scalars['String']>;
  /** ชื่อภูมิภาคไทย */
  name_th?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type RegionMinFields = {
  __typename?: 'region_min_fields';
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อภูมิภาคอังกฤษ */
  name_en?: Maybe<Scalars['String']>;
  /** ชื่อภูมิภาคไทย */
  name_th?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "region" */
export type RegionMutationResponse = {
  __typename?: 'region_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Region>;
};

/** input type for inserting object relation for remote table "region" */
export type RegionObjRelInsertInput = {
  data: RegionInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<RegionOnConflict>;
};

/** on conflict condition type for table "region" */
export type RegionOnConflict = {
  constraint: RegionConstraint;
  update_columns?: Array<RegionUpdateColumn>;
  where?: Maybe<RegionBoolExp>;
};

/** Ordering options when selecting data from "region". */
export type RegionOrderBy = {
  id?: Maybe<OrderBy>;
  name_en?: Maybe<OrderBy>;
  name_th?: Maybe<OrderBy>;
  provinces_aggregate?: Maybe<ProvinceAggregateOrderBy>;
};

/** primary key columns input for table: region */
export type RegionPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "region" */
export enum RegionSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NameTh = 'name_th'
}

/** input type for updating data in table "region" */
export type RegionSetInput = {
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** ชื่อภูมิภาคอังกฤษ */
  name_en?: Maybe<Scalars['String']>;
  /** ชื่อภูมิภาคไทย */
  name_th?: Maybe<Scalars['String']>;
};

/** update columns of table "region" */
export enum RegionUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NameTh = 'name_th'
}

export type SubscriptionRoot = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "account.account" */
  account_account: Array<AccountAccount>;
  /** fetch aggregated fields from the table: "account.account" */
  account_account_aggregate: AccountAccountAggregate;
  /** fetch data from the table: "account.account_balance" */
  account_account_balance: Array<AccountAccountBalance>;
  /** fetch aggregated fields from the table: "account.account_balance" */
  account_account_balance_aggregate: AccountAccountBalanceAggregate;
  /** fetch data from the table: "account.account_balance_sheet" */
  account_account_balance_sheet: Array<AccountAccountBalanceSheet>;
  /** fetch aggregated fields from the table: "account.account_balance_sheet" */
  account_account_balance_sheet_aggregate: AccountAccountBalanceSheetAggregate;
  /** fetch data from the table: "account.account" using primary key columns */
  account_account_by_pk?: Maybe<AccountAccount>;
  /** fetch data from the table: "account.account_category" */
  account_account_category: Array<AccountAccountCategory>;
  /** fetch aggregated fields from the table: "account.account_category" */
  account_account_category_aggregate: AccountAccountCategoryAggregate;
  /** fetch data from the table: "account.account_category" using primary key columns */
  account_account_category_by_pk?: Maybe<AccountAccountCategory>;
  /** fetch data from the table: "account.account_monthly_report" */
  account_account_monthly_report: Array<AccountAccountMonthlyReport>;
  /** fetch aggregated fields from the table: "account.account_monthly_report" */
  account_account_monthly_report_aggregate: AccountAccountMonthlyReportAggregate;
  /** fetch data from the table: "account.account_type" */
  account_account_type: Array<AccountAccountType>;
  /** fetch aggregated fields from the table: "account.account_type" */
  account_account_type_aggregate: AccountAccountTypeAggregate;
  /** fetch data from the table: "account.account_type" using primary key columns */
  account_account_type_by_pk?: Maybe<AccountAccountType>;
  /** fetch data from the table: "account.budget_account" */
  account_budget_account: Array<AccountBudgetAccount>;
  /** fetch aggregated fields from the table: "account.budget_account" */
  account_budget_account_aggregate: AccountBudgetAccountAggregate;
  /** fetch data from the table: "account.budget_account" using primary key columns */
  account_budget_account_by_pk?: Maybe<AccountBudgetAccount>;
  /** fetch data from the table: "account.budget_allocation" */
  account_budget_allocation: Array<AccountBudgetAllocation>;
  /** fetch aggregated fields from the table: "account.budget_allocation" */
  account_budget_allocation_aggregate: AccountBudgetAllocationAggregate;
  /** fetch data from the table: "account.budget_allocation" using primary key columns */
  account_budget_allocation_by_pk?: Maybe<AccountBudgetAllocation>;
  /** fetch data from the table: "account.budget_allocation_log" */
  account_budget_allocation_log: Array<AccountBudgetAllocationLog>;
  /** fetch aggregated fields from the table: "account.budget_allocation_log" */
  account_budget_allocation_log_aggregate: AccountBudgetAllocationLogAggregate;
  /** fetch data from the table: "account.budget_allocation_log" using primary key columns */
  account_budget_allocation_log_by_pk?: Maybe<AccountBudgetAllocationLog>;
  /** fetch data from the table: "account.budget_type" */
  account_budget_type: Array<AccountBudgetType>;
  /** fetch aggregated fields from the table: "account.budget_type" */
  account_budget_type_aggregate: AccountBudgetTypeAggregate;
  /** fetch data from the table: "account.budget_type" using primary key columns */
  account_budget_type_by_pk?: Maybe<AccountBudgetType>;
  /** fetch data from the table: "account.entry_type" */
  account_entry_type: Array<AccountEntryType>;
  /** fetch aggregated fields from the table: "account.entry_type" */
  account_entry_type_aggregate: AccountEntryTypeAggregate;
  /** fetch data from the table: "account.entry_type" using primary key columns */
  account_entry_type_by_pk?: Maybe<AccountEntryType>;
  /** fetch data from the table: "account.ledger_entry" */
  account_ledger_entry: Array<AccountLedgerEntry>;
  /** fetch aggregated fields from the table: "account.ledger_entry" */
  account_ledger_entry_aggregate: AccountLedgerEntryAggregate;
  /** fetch data from the table: "account.ledger_entry" using primary key columns */
  account_ledger_entry_by_pk?: Maybe<AccountLedgerEntry>;
  /** fetch data from the table: "account.ledger_entry_view" */
  account_ledger_entry_view: Array<AccountLedgerEntryView>;
  /** fetch aggregated fields from the table: "account.ledger_entry_view" */
  account_ledger_entry_view_aggregate: AccountLedgerEntryViewAggregate;
  /** fetch data from the table: "account.lending" */
  account_lending: Array<AccountLending>;
  /** fetch aggregated fields from the table: "account.lending" */
  account_lending_aggregate: AccountLendingAggregate;
  /** fetch data from the table: "account.lending" using primary key columns */
  account_lending_by_pk?: Maybe<AccountLending>;
  /** fetch data from the table: "account.lending_item" */
  account_lending_item: Array<AccountLendingItem>;
  /** fetch aggregated fields from the table: "account.lending_item" */
  account_lending_item_aggregate: AccountLendingItemAggregate;
  /** fetch data from the table: "account.lending_item" using primary key columns */
  account_lending_item_by_pk?: Maybe<AccountLendingItem>;
  /** fetch data from the table: "account.lending_status" */
  account_lending_status: Array<AccountLendingStatus>;
  /** fetch aggregated fields from the table: "account.lending_status" */
  account_lending_status_aggregate: AccountLendingStatusAggregate;
  /** fetch data from the table: "account.lending_status" using primary key columns */
  account_lending_status_by_pk?: Maybe<AccountLendingStatus>;
  /** fetch data from the table: "account.transaction" */
  account_transaction: Array<AccountTransaction>;
  /** fetch aggregated fields from the table: "account.transaction" */
  account_transaction_aggregate: AccountTransactionAggregate;
  /** fetch data from the table: "account.transaction" using primary key columns */
  account_transaction_by_pk?: Maybe<AccountTransaction>;
  /** fetch data from the table: "account.transaction_log" */
  account_transaction_log: Array<AccountTransactionLog>;
  /** fetch aggregated fields from the table: "account.transaction_log" */
  account_transaction_log_aggregate: AccountTransactionLogAggregate;
  /** fetch data from the table: "account.transaction_log" using primary key columns */
  account_transaction_log_by_pk?: Maybe<AccountTransactionLog>;
  /** fetch data from the table: "account.transaction_status" */
  account_transaction_status: Array<AccountTransactionStatus>;
  /** fetch aggregated fields from the table: "account.transaction_status" */
  account_transaction_status_aggregate: AccountTransactionStatusAggregate;
  /** fetch data from the table: "account.transaction_status" using primary key columns */
  account_transaction_status_by_pk?: Maybe<AccountTransactionStatus>;
  /** fetch data from the table: "account.transaction_type" */
  account_transaction_type: Array<AccountTransactionType>;
  /** fetch aggregated fields from the table: "account.transaction_type" */
  account_transaction_type_aggregate: AccountTransactionTypeAggregate;
  /** fetch data from the table: "account.transaction_type" using primary key columns */
  account_transaction_type_by_pk?: Maybe<AccountTransactionType>;
  /** fetch data from the table: "account.trial_balance_monthly" */
  account_trial_balance_monthly: Array<AccountTrialBalanceMonthly>;
  /** fetch aggregated fields from the table: "account.trial_balance_monthly" */
  account_trial_balance_monthly_aggregate: AccountTrialBalanceMonthlyAggregate;
  /** fetch data from the table: "attachment" */
  attachment: Array<Attachment>;
  /** fetch aggregated fields from the table: "attachment" */
  attachment_aggregate: AttachmentAggregate;
  /** fetch data from the table: "attachment" using primary key columns */
  attachment_by_pk?: Maybe<Attachment>;
  /** fetch data from the table: "audit.logged_actions" */
  audit_logged_actions: Array<AuditLoggedActions>;
  /** fetch aggregated fields from the table: "audit.logged_actions" */
  audit_logged_actions_aggregate: AuditLoggedActionsAggregate;
  /** fetch data from the table: "audit.logged_actions" using primary key columns */
  audit_logged_actions_by_pk?: Maybe<AuditLoggedActions>;
  /** fetch data from the table: "auth.account_providers" */
  auth_account_providers: Array<AuthAccountProviders>;
  /** fetch aggregated fields from the table: "auth.account_providers" */
  auth_account_providers_aggregate: AuthAccountProvidersAggregate;
  /** fetch data from the table: "auth.account_providers" using primary key columns */
  auth_account_providers_by_pk?: Maybe<AuthAccountProviders>;
  /** fetch data from the table: "auth.account_roles" */
  auth_account_roles: Array<AuthAccountRoles>;
  /** fetch aggregated fields from the table: "auth.account_roles" */
  auth_account_roles_aggregate: AuthAccountRolesAggregate;
  /** fetch data from the table: "auth.account_roles" using primary key columns */
  auth_account_roles_by_pk?: Maybe<AuthAccountRoles>;
  /** fetch data from the table: "auth.accounts" */
  auth_accounts: Array<AuthAccounts>;
  /** fetch aggregated fields from the table: "auth.accounts" */
  auth_accounts_aggregate: AuthAccountsAggregate;
  /** fetch data from the table: "auth.accounts" using primary key columns */
  auth_accounts_by_pk?: Maybe<AuthAccounts>;
  /** fetch data from the table: "auth.providers" */
  auth_providers: Array<AuthProviders>;
  /** fetch aggregated fields from the table: "auth.providers" */
  auth_providers_aggregate: AuthProvidersAggregate;
  /** fetch data from the table: "auth.providers" using primary key columns */
  auth_providers_by_pk?: Maybe<AuthProviders>;
  /** fetch data from the table: "auth.refresh_tokens" */
  auth_refresh_tokens: Array<AuthRefreshTokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  auth_refresh_tokens_aggregate: AuthRefreshTokensAggregate;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  auth_refresh_tokens_by_pk?: Maybe<AuthRefreshTokens>;
  /** fetch data from the table: "auth.roles" */
  auth_roles: Array<AuthRoles>;
  /** fetch aggregated fields from the table: "auth.roles" */
  auth_roles_aggregate: AuthRolesAggregate;
  /** fetch data from the table: "auth.roles" using primary key columns */
  auth_roles_by_pk?: Maybe<AuthRoles>;
  /** fetch data from the table: "bank_branch" */
  bank_branch: Array<BankBranch>;
  /** fetch aggregated fields from the table: "bank_branch" */
  bank_branch_aggregate: BankBranchAggregate;
  /** fetch data from the table: "bank_branch" using primary key columns */
  bank_branch_by_pk?: Maybe<BankBranch>;
  /** fetch data from the table: "contract.af_status" */
  contract_af_status: Array<ContractAfStatus>;
  /** fetch aggregated fields from the table: "contract.af_status" */
  contract_af_status_aggregate: ContractAfStatusAggregate;
  /** fetch data from the table: "contract.af_status" using primary key columns */
  contract_af_status_by_pk?: Maybe<ContractAfStatus>;
  /** fetch data from the table: "contract.agreement" */
  contract_agreement: Array<ContractAgreement>;
  /** fetch data from the table: "contract.agreement_af_status_count" */
  contract_agreement_af_status_count: Array<ContractAgreementAfStatusCount>;
  /** fetch aggregated fields from the table: "contract.agreement_af_status_count" */
  contract_agreement_af_status_count_aggregate: ContractAgreementAfStatusCountAggregate;
  /** fetch aggregated fields from the table: "contract.agreement" */
  contract_agreement_aggregate: ContractAgreementAggregate;
  /** fetch data from the table: "contract.agreement_attachment" */
  contract_agreement_attachment: Array<ContractAgreementAttachment>;
  /** fetch aggregated fields from the table: "contract.agreement_attachment" */
  contract_agreement_attachment_aggregate: ContractAgreementAttachmentAggregate;
  /** fetch data from the table: "contract.agreement_attachment" using primary key columns */
  contract_agreement_attachment_by_pk?: Maybe<ContractAgreementAttachment>;
  /** fetch data from the table: "contract.agreement" using primary key columns */
  contract_agreement_by_pk?: Maybe<ContractAgreement>;
  /** fetch data from the table: "contract.agreement_detail" */
  contract_agreement_detail: Array<ContractAgreementDetail>;
  /** fetch aggregated fields from the table: "contract.agreement_detail" */
  contract_agreement_detail_aggregate: ContractAgreementDetailAggregate;
  /** fetch data from the table: "contract.agreement_detail" using primary key columns */
  contract_agreement_detail_by_pk?: Maybe<ContractAgreementDetail>;
  /** fetch data from the table: "contract.agreement_disbursement" */
  contract_agreement_disbursement: Array<ContractAgreementDisbursement>;
  /** fetch aggregated fields from the table: "contract.agreement_disbursement" */
  contract_agreement_disbursement_aggregate: ContractAgreementDisbursementAggregate;
  /** fetch data from the table: "contract.agreement_disbursement" using primary key columns */
  contract_agreement_disbursement_by_pk?: Maybe<ContractAgreementDisbursement>;
  /** fetch data from the table: "contract.agreement_fee_charge" */
  contract_agreement_fee_charge: Array<ContractAgreementFeeCharge>;
  /** fetch aggregated fields from the table: "contract.agreement_fee_charge" */
  contract_agreement_fee_charge_aggregate: ContractAgreementFeeChargeAggregate;
  /** fetch data from the table: "contract.agreement_fee_charge" using primary key columns */
  contract_agreement_fee_charge_by_pk?: Maybe<ContractAgreementFeeCharge>;
  /** fetch data from the table: "contract.agreement_guarantor" */
  contract_agreement_guarantor: Array<ContractAgreementGuarantor>;
  /** fetch aggregated fields from the table: "contract.agreement_guarantor" */
  contract_agreement_guarantor_aggregate: ContractAgreementGuarantorAggregate;
  /** fetch data from the table: "contract.agreement_guarantor" using primary key columns */
  contract_agreement_guarantor_by_pk?: Maybe<ContractAgreementGuarantor>;
  /** fetch data from the table: "contract.agreement_receipt" */
  contract_agreement_receipt: Array<ContractAgreementReceipt>;
  /** fetch aggregated fields from the table: "contract.agreement_receipt" */
  contract_agreement_receipt_aggregate: ContractAgreementReceiptAggregate;
  /** fetch data from the table: "contract.agreement_receipt" using primary key columns */
  contract_agreement_receipt_by_pk?: Maybe<ContractAgreementReceipt>;
  /** fetch data from the table: "contract.agreement_repay_current_month" */
  contract_agreement_repay_current_month: Array<ContractAgreementRepayCurrentMonth>;
  /** fetch aggregated fields from the table: "contract.agreement_repay_current_month" */
  contract_agreement_repay_current_month_aggregate: ContractAgreementRepayCurrentMonthAggregate;
  /** fetch data from the table: "contract.agreement_status" */
  contract_agreement_status: Array<ContractAgreementStatus>;
  /** fetch aggregated fields from the table: "contract.agreement_status" */
  contract_agreement_status_aggregate: ContractAgreementStatusAggregate;
  /** fetch data from the table: "contract.agreement_status" using primary key columns */
  contract_agreement_status_by_pk?: Maybe<ContractAgreementStatus>;
  /** fetch data from the table: "contract.agreement_status_mapper" */
  contract_agreement_status_mapper: Array<ContractAgreementStatusMapper>;
  /** fetch aggregated fields from the table: "contract.agreement_status_mapper" */
  contract_agreement_status_mapper_aggregate: ContractAgreementStatusMapperAggregate;
  /** fetch data from the table: "contract.agreement_status_mapper" using primary key columns */
  contract_agreement_status_mapper_by_pk?: Maybe<ContractAgreementStatusMapper>;
  /** fetch data from the table: "contract.agreement_transaction" */
  contract_agreement_transaction: Array<ContractAgreementTransaction>;
  /** fetch aggregated fields from the table: "contract.agreement_transaction" */
  contract_agreement_transaction_aggregate: ContractAgreementTransactionAggregate;
  /** fetch data from the table: "contract.agreement_transaction" using primary key columns */
  contract_agreement_transaction_by_pk?: Maybe<ContractAgreementTransaction>;
  /** fetch data from the table: "contract.agreement_withdraw" */
  contract_agreement_withdraw: Array<ContractAgreementWithdraw>;
  /** fetch aggregated fields from the table: "contract.agreement_withdraw" */
  contract_agreement_withdraw_aggregate: ContractAgreementWithdrawAggregate;
  /** fetch data from the table: "contract.agreement_withdraw" using primary key columns */
  contract_agreement_withdraw_by_pk?: Maybe<ContractAgreementWithdraw>;
  /** fetch data from the table: "contract.attachment_type" */
  contract_attachment_type: Array<ContractAttachmentType>;
  /** fetch aggregated fields from the table: "contract.attachment_type" */
  contract_attachment_type_aggregate: ContractAttachmentTypeAggregate;
  /** fetch data from the table: "contract.attachment_type" using primary key columns */
  contract_attachment_type_by_pk?: Maybe<ContractAttachmentType>;
  /** fetch data from the table: "contract.grade" */
  contract_grade: Array<ContractGrade>;
  /** fetch aggregated fields from the table: "contract.grade" */
  contract_grade_aggregate: ContractGradeAggregate;
  /** fetch data from the table: "contract.grade" using primary key columns */
  contract_grade_by_pk?: Maybe<ContractGrade>;
  /** fetch data from the table: "contract.transaction_creator_type" */
  contract_transaction_creator_type: Array<ContractTransactionCreatorType>;
  /** fetch aggregated fields from the table: "contract.transaction_creator_type" */
  contract_transaction_creator_type_aggregate: ContractTransactionCreatorTypeAggregate;
  /** fetch data from the table: "contract.transaction_creator_type" using primary key columns */
  contract_transaction_creator_type_by_pk?: Maybe<ContractTransactionCreatorType>;
  /** fetch data from the table: "current_user_permissions" */
  current_user_permissions: Array<CurrentUserPermissions>;
  /** fetch aggregated fields from the table: "current_user_permissions" */
  current_user_permissions_aggregate: CurrentUserPermissionsAggregate;
  /** fetch data from the table: "department" */
  department: Array<Department>;
  /** fetch aggregated fields from the table: "department" */
  department_aggregate: DepartmentAggregate;
  /** fetch data from the table: "department" using primary key columns */
  department_by_pk?: Maybe<Department>;
  /** fetch data from the table: "feature_toggle" */
  feature_toggle: Array<FeatureToggle>;
  /** fetch aggregated fields from the table: "feature_toggle" */
  feature_toggle_aggregate: FeatureToggleAggregate;
  /** fetch data from the table: "feature_toggle" using primary key columns */
  feature_toggle_by_pk?: Maybe<FeatureToggle>;
  /** fetch data from the table: "fiscal_year" */
  fiscal_year: Array<FiscalYear>;
  /** fetch aggregated fields from the table: "fiscal_year" */
  fiscal_year_aggregate: FiscalYearAggregate;
  /** fetch data from the table: "fiscal_year" using primary key columns */
  fiscal_year_by_pk?: Maybe<FiscalYear>;
  /** fetch data from the table: "import_log" */
  import_log: Array<ImportLog>;
  /** fetch aggregated fields from the table: "import_log" */
  import_log_aggregate: ImportLogAggregate;
  /** fetch data from the table: "import_log" using primary key columns */
  import_log_by_pk?: Maybe<ImportLog>;
  /** fetch data from the table: "juristic" */
  juristic: Array<Juristic>;
  /** fetch data from the table: "juristic_address" */
  juristic_address: Array<JuristicAddress>;
  /** fetch aggregated fields from the table: "juristic_address" */
  juristic_address_aggregate: JuristicAddressAggregate;
  /** fetch data from the table: "juristic_address" using primary key columns */
  juristic_address_by_pk?: Maybe<JuristicAddress>;
  /** fetch aggregated fields from the table: "juristic" */
  juristic_aggregate: JuristicAggregate;
  /** fetch data from the table: "juristic" using primary key columns */
  juristic_by_pk?: Maybe<Juristic>;
  /** fetch data from the table: "juristic_committee" */
  juristic_committee: Array<JuristicCommittee>;
  /** fetch aggregated fields from the table: "juristic_committee" */
  juristic_committee_aggregate: JuristicCommitteeAggregate;
  /** fetch data from the table: "juristic_committee" using primary key columns */
  juristic_committee_by_pk?: Maybe<JuristicCommittee>;
  /** fetch data from the table: "juristic_standard_objective" */
  juristic_standard_objective: Array<JuristicStandardObjective>;
  /** fetch aggregated fields from the table: "juristic_standard_objective" */
  juristic_standard_objective_aggregate: JuristicStandardObjectiveAggregate;
  /** fetch data from the table: "juristic_standard_objective" using primary key columns */
  juristic_standard_objective_by_pk?: Maybe<JuristicStandardObjective>;
  /** fetch data from the table: "ledger.account_budget_balance" */
  ledger_account_budget_balance: Array<LedgerAccountBudgetBalance>;
  /** fetch aggregated fields from the table: "ledger.account_budget_balance" */
  ledger_account_budget_balance_aggregate: LedgerAccountBudgetBalanceAggregate;
  /** fetch data from the table: "ledger.account_budget_balance_debt" */
  ledger_account_budget_balance_debt: Array<LedgerAccountBudgetBalanceDebt>;
  /** fetch aggregated fields from the table: "ledger.account_budget_balance_debt" */
  ledger_account_budget_balance_debt_aggregate: LedgerAccountBudgetBalanceDebtAggregate;
  /** fetch data from the table: "ledger.account_budget_balance_expense" */
  ledger_account_budget_balance_expense: Array<LedgerAccountBudgetBalanceExpense>;
  /** fetch aggregated fields from the table: "ledger.account_budget_balance_expense" */
  ledger_account_budget_balance_expense_aggregate: LedgerAccountBudgetBalanceExpenseAggregate;
  /** fetch data from the table: "ledger.account_budget_monthly" */
  ledger_account_budget_monthly: Array<LedgerAccountBudgetMonthly>;
  /** fetch aggregated fields from the table: "ledger.account_budget_monthly" */
  ledger_account_budget_monthly_aggregate: LedgerAccountBudgetMonthlyAggregate;
  /** fetch data from the table: "ledger.budget_allocation" */
  ledger_budget_allocation: Array<LedgerBudgetAllocation>;
  /** fetch aggregated fields from the table: "ledger.budget_allocation" */
  ledger_budget_allocation_aggregate: LedgerBudgetAllocationAggregate;
  /** fetch data from the table: "ledger.budget_allocation" using primary key columns */
  ledger_budget_allocation_by_pk?: Maybe<LedgerBudgetAllocation>;
  /** fetch data from the table: "ledger.ledger" */
  ledger_ledger: Array<LedgerLedger>;
  /** fetch aggregated fields from the table: "ledger.ledger" */
  ledger_ledger_aggregate: LedgerLedgerAggregate;
  /** fetch data from the table: "ledger.ledger_attachment" */
  ledger_ledger_attachment: Array<LedgerLedgerAttachment>;
  /** fetch aggregated fields from the table: "ledger.ledger_attachment" */
  ledger_ledger_attachment_aggregate: LedgerLedgerAttachmentAggregate;
  /** fetch data from the table: "ledger.ledger_attachment" using primary key columns */
  ledger_ledger_attachment_by_pk?: Maybe<LedgerLedgerAttachment>;
  /** fetch data from the table: "ledger.ledger" using primary key columns */
  ledger_ledger_by_pk?: Maybe<LedgerLedger>;
  /** fetch data from the table: "ledger.ledger_log" */
  ledger_ledger_log: Array<LedgerLedgerLog>;
  /** fetch aggregated fields from the table: "ledger.ledger_log" */
  ledger_ledger_log_aggregate: LedgerLedgerLogAggregate;
  /** fetch data from the table: "ledger.ledger_log" using primary key columns */
  ledger_ledger_log_by_pk?: Maybe<LedgerLedgerLog>;
  /** fetch data from the table: "ledger.ledger_status" */
  ledger_ledger_status: Array<LedgerLedgerStatus>;
  /** fetch aggregated fields from the table: "ledger.ledger_status" */
  ledger_ledger_status_aggregate: LedgerLedgerStatusAggregate;
  /** fetch data from the table: "ledger.ledger_status" using primary key columns */
  ledger_ledger_status_by_pk?: Maybe<LedgerLedgerStatus>;
  /** fetch data from the table: "ledger.ledger_type" */
  ledger_ledger_type: Array<LedgerLedgerType>;
  /** fetch aggregated fields from the table: "ledger.ledger_type" */
  ledger_ledger_type_aggregate: LedgerLedgerTypeAggregate;
  /** fetch data from the table: "ledger.ledger_type" using primary key columns */
  ledger_ledger_type_by_pk?: Maybe<LedgerLedgerType>;
  /** fetch data from the table: "ledger.receivable" */
  ledger_receivable: Array<LedgerReceivable>;
  /** fetch aggregated fields from the table: "ledger.receivable" */
  ledger_receivable_aggregate: LedgerReceivableAggregate;
  /** fetch data from the table: "ledger.receivable" using primary key columns */
  ledger_receivable_by_pk?: Maybe<LedgerReceivable>;
  /** fetch data from the table: "ledger.receivable_item" */
  ledger_receivable_item: Array<LedgerReceivableItem>;
  /** fetch aggregated fields from the table: "ledger.receivable_item" */
  ledger_receivable_item_aggregate: LedgerReceivableItemAggregate;
  /** fetch data from the table: "ledger.receivable_item" using primary key columns */
  ledger_receivable_item_by_pk?: Maybe<LedgerReceivableItem>;
  /** fetch data from the table: "ledger.receivable_log" */
  ledger_receivable_log: Array<LedgerReceivableLog>;
  /** fetch aggregated fields from the table: "ledger.receivable_log" */
  ledger_receivable_log_aggregate: LedgerReceivableLogAggregate;
  /** fetch data from the table: "ledger.receivable_log" using primary key columns */
  ledger_receivable_log_by_pk?: Maybe<LedgerReceivableLog>;
  /** fetch data from the table: "ledger.receivable_status" */
  ledger_receivable_status: Array<LedgerReceivableStatus>;
  /** fetch aggregated fields from the table: "ledger.receivable_status" */
  ledger_receivable_status_aggregate: LedgerReceivableStatusAggregate;
  /** fetch data from the table: "ledger.receivable_status" using primary key columns */
  ledger_receivable_status_by_pk?: Maybe<LedgerReceivableStatus>;
  /** fetch data from the table: "ledger.total_report_monthly" */
  ledger_total_report_monthly: Array<LedgerTotalReportMonthly>;
  /** fetch aggregated fields from the table: "ledger.total_report_monthly" */
  ledger_total_report_monthly_aggregate: LedgerTotalReportMonthlyAggregate;
  /** fetch data from the table: "ledger.total_report_yearly" */
  ledger_total_report_yearly: Array<LedgerTotalReportYearly>;
  /** fetch aggregated fields from the table: "ledger.total_report_yearly" */
  ledger_total_report_yearly_aggregate: LedgerTotalReportYearlyAggregate;
  /** fetch data from the table: "loan.account_type" */
  loan_account_type: Array<LoanAccountType>;
  /** fetch aggregated fields from the table: "loan.account_type" */
  loan_account_type_aggregate: LoanAccountTypeAggregate;
  /** fetch data from the table: "loan.account_type" using primary key columns */
  loan_account_type_by_pk?: Maybe<LoanAccountType>;
  /** fetch data from the table: "loan.application_attachment" */
  loan_application_attachment: Array<LoanApplicationAttachment>;
  /** fetch aggregated fields from the table: "loan.application_attachment" */
  loan_application_attachment_aggregate: LoanApplicationAttachmentAggregate;
  /** fetch data from the table: "loan.application_attachment" using primary key columns */
  loan_application_attachment_by_pk?: Maybe<LoanApplicationAttachment>;
  /** fetch data from the table: "loan.application_bank_branch_transfer" */
  loan_application_bank_branch_transfer: Array<LoanApplicationBankBranchTransfer>;
  /** fetch aggregated fields from the table: "loan.application_bank_branch_transfer" */
  loan_application_bank_branch_transfer_aggregate: LoanApplicationBankBranchTransferAggregate;
  /** fetch data from the table: "loan.application_bank_branch_transfer" using primary key columns */
  loan_application_bank_branch_transfer_by_pk?: Maybe<LoanApplicationBankBranchTransfer>;
  /** fetch data from the table: "loan.application_business_operation" */
  loan_application_business_operation: Array<LoanApplicationBusinessOperation>;
  /** fetch aggregated fields from the table: "loan.application_business_operation" */
  loan_application_business_operation_aggregate: LoanApplicationBusinessOperationAggregate;
  /** fetch data from the table: "loan.application_business_operation" using primary key columns */
  loan_application_business_operation_by_pk?: Maybe<LoanApplicationBusinessOperation>;
  /** fetch data from the table: "loan.application_committee" */
  loan_application_committee: Array<LoanApplicationCommittee>;
  /** fetch aggregated fields from the table: "loan.application_committee" */
  loan_application_committee_aggregate: LoanApplicationCommitteeAggregate;
  /** fetch data from the table: "loan.application_committee" using primary key columns */
  loan_application_committee_by_pk?: Maybe<LoanApplicationCommittee>;
  /** fetch data from the table: "loan.application_current_loan" */
  loan_application_current_loan: Array<LoanApplicationCurrentLoan>;
  /** fetch aggregated fields from the table: "loan.application_current_loan" */
  loan_application_current_loan_aggregate: LoanApplicationCurrentLoanAggregate;
  /** fetch data from the table: "loan.application_current_loan" using primary key columns */
  loan_application_current_loan_by_pk?: Maybe<LoanApplicationCurrentLoan>;
  /** fetch data from the table: "loan.application_detail" */
  loan_application_detail: Array<LoanApplicationDetail>;
  /** fetch aggregated fields from the table: "loan.application_detail" */
  loan_application_detail_aggregate: LoanApplicationDetailAggregate;
  /** fetch data from the table: "loan.application_detail" using primary key columns */
  loan_application_detail_by_pk?: Maybe<LoanApplicationDetail>;
  /** fetch data from the table: "loan.application_event_log" */
  loan_application_event_log: Array<LoanApplicationEventLog>;
  /** fetch aggregated fields from the table: "loan.application_event_log" */
  loan_application_event_log_aggregate: LoanApplicationEventLogAggregate;
  /** fetch data from the table: "loan.application_event_log" using primary key columns */
  loan_application_event_log_by_pk?: Maybe<LoanApplicationEventLog>;
  /** fetch data from the table: "loan.application_header" */
  loan_application_header: Array<LoanApplicationHeader>;
  /** fetch aggregated fields from the table: "loan.application_header" */
  loan_application_header_aggregate: LoanApplicationHeaderAggregate;
  /** fetch data from the table: "loan.application_header" using primary key columns */
  loan_application_header_by_pk?: Maybe<LoanApplicationHeader>;
  /** fetch data from the table: "loan.application_header_pdf" */
  loan_application_header_pdf: Array<LoanApplicationHeaderPdf>;
  /** fetch aggregated fields from the table: "loan.application_header_pdf" */
  loan_application_header_pdf_aggregate: LoanApplicationHeaderPdfAggregate;
  /** fetch data from the table: "loan.application_header_pdf" using primary key columns */
  loan_application_header_pdf_by_pk?: Maybe<LoanApplicationHeaderPdf>;
  /** fetch data from the table: "loan.application_objective" */
  loan_application_objective: Array<LoanApplicationObjective>;
  /** fetch aggregated fields from the table: "loan.application_objective" */
  loan_application_objective_aggregate: LoanApplicationObjectiveAggregate;
  /** fetch data from the table: "loan.application_objective" using primary key columns */
  loan_application_objective_by_pk?: Maybe<LoanApplicationObjective>;
  /** fetch data from the table: "loan.application_organization_transfer" */
  loan_application_organization_transfer: Array<LoanApplicationOrganizationTransfer>;
  /** fetch aggregated fields from the table: "loan.application_organization_transfer" */
  loan_application_organization_transfer_aggregate: LoanApplicationOrganizationTransferAggregate;
  /** fetch data from the table: "loan.application_organization_transfer" using primary key columns */
  loan_application_organization_transfer_by_pk?: Maybe<LoanApplicationOrganizationTransfer>;
  /** fetch data from the table: "loan.application_profile" */
  loan_application_profile: Array<LoanApplicationProfile>;
  /** fetch aggregated fields from the table: "loan.application_profile" */
  loan_application_profile_aggregate: LoanApplicationProfileAggregate;
  /** fetch data from the table: "loan.application_profile" using primary key columns */
  loan_application_profile_by_pk?: Maybe<LoanApplicationProfile>;
  /** fetch data from the table: "loan.application_profile_s_curve" */
  loan_application_profile_s_curve: Array<LoanApplicationProfileSCurve>;
  /** fetch aggregated fields from the table: "loan.application_profile_s_curve" */
  loan_application_profile_s_curve_aggregate: LoanApplicationProfileSCurveAggregate;
  /** fetch data from the table: "loan.application_profile_s_curve" using primary key columns */
  loan_application_profile_s_curve_by_pk?: Maybe<LoanApplicationProfileSCurve>;
  /** fetch data from the table: "loan.application_sequence_no" */
  loan_application_sequence_no: Array<LoanApplicationSequenceNo>;
  /** fetch aggregated fields from the table: "loan.application_sequence_no" */
  loan_application_sequence_no_aggregate: LoanApplicationSequenceNoAggregate;
  /** fetch data from the table: "loan.application_sequence_no" using primary key columns */
  loan_application_sequence_no_by_pk?: Maybe<LoanApplicationSequenceNo>;
  /** fetch data from the table: "loan.application_step" */
  loan_application_step: Array<LoanApplicationStep>;
  /** fetch aggregated fields from the table: "loan.application_step" */
  loan_application_step_aggregate: LoanApplicationStepAggregate;
  /** fetch data from the table: "loan.application_step" using primary key columns */
  loan_application_step_by_pk?: Maybe<LoanApplicationStep>;
  /** fetch data from the table: "loan.attachment_type" */
  loan_attachment_type: Array<LoanAttachmentType>;
  /** fetch aggregated fields from the table: "loan.attachment_type" */
  loan_attachment_type_aggregate: LoanAttachmentTypeAggregate;
  /** fetch data from the table: "loan.attachment_type" using primary key columns */
  loan_attachment_type_by_pk?: Maybe<LoanAttachmentType>;
  /** fetch data from the table: "loan.bank_approve" */
  loan_bank_approve: Array<LoanBankApprove>;
  /** fetch aggregated fields from the table: "loan.bank_approve" */
  loan_bank_approve_aggregate: LoanBankApproveAggregate;
  /** fetch data from the table: "loan.bank_approve" using primary key columns */
  loan_bank_approve_by_pk?: Maybe<LoanBankApprove>;
  /** fetch data from the table: "loan.business_group" */
  loan_business_group: Array<LoanBusinessGroup>;
  /** fetch aggregated fields from the table: "loan.business_group" */
  loan_business_group_aggregate: LoanBusinessGroupAggregate;
  /** fetch data from the table: "loan.business_group" using primary key columns */
  loan_business_group_by_pk?: Maybe<LoanBusinessGroup>;
  /** fetch data from the table: "loan.business_operation" */
  loan_business_operation: Array<LoanBusinessOperation>;
  /** fetch aggregated fields from the table: "loan.business_operation" */
  loan_business_operation_aggregate: LoanBusinessOperationAggregate;
  /** fetch data from the table: "loan.business_operation" using primary key columns */
  loan_business_operation_by_pk?: Maybe<LoanBusinessOperation>;
  /** fetch data from the table: "loan.business_proprietary" */
  loan_business_proprietary: Array<LoanBusinessProprietary>;
  /** fetch aggregated fields from the table: "loan.business_proprietary" */
  loan_business_proprietary_aggregate: LoanBusinessProprietaryAggregate;
  /** fetch data from the table: "loan.business_proprietary" using primary key columns */
  loan_business_proprietary_by_pk?: Maybe<LoanBusinessProprietary>;
  /** fetch data from the table: "loan.business_status" */
  loan_business_status: Array<LoanBusinessStatus>;
  /** fetch aggregated fields from the table: "loan.business_status" */
  loan_business_status_aggregate: LoanBusinessStatusAggregate;
  /** fetch data from the table: "loan.business_status" using primary key columns */
  loan_business_status_by_pk?: Maybe<LoanBusinessStatus>;
  /** fetch data from the table: "loan.business_type" */
  loan_business_type: Array<LoanBusinessType>;
  /** fetch aggregated fields from the table: "loan.business_type" */
  loan_business_type_aggregate: LoanBusinessTypeAggregate;
  /** fetch data from the table: "loan.business_type" using primary key columns */
  loan_business_type_by_pk?: Maybe<LoanBusinessType>;
  /** fetch data from the table: "loan.committee_approve" */
  loan_committee_approve: Array<LoanCommitteeApprove>;
  /** fetch aggregated fields from the table: "loan.committee_approve" */
  loan_committee_approve_aggregate: LoanCommitteeApproveAggregate;
  /** fetch data from the table: "loan.committee_approve" using primary key columns */
  loan_committee_approve_by_pk?: Maybe<LoanCommitteeApprove>;
  /** fetch data from the table: "loan.current_loan" */
  loan_current_loan: Array<LoanCurrentLoan>;
  /** fetch aggregated fields from the table: "loan.current_loan" */
  loan_current_loan_aggregate: LoanCurrentLoanAggregate;
  /** fetch data from the table: "loan.current_loan" using primary key columns */
  loan_current_loan_by_pk?: Maybe<LoanCurrentLoan>;
  /** fetch data from the table: "loan.debt_restructuring" */
  loan_debt_restructuring: Array<LoanDebtRestructuring>;
  /** fetch aggregated fields from the table: "loan.debt_restructuring" */
  loan_debt_restructuring_aggregate: LoanDebtRestructuringAggregate;
  /** fetch data from the table: "loan.debt_restructuring" using primary key columns */
  loan_debt_restructuring_by_pk?: Maybe<LoanDebtRestructuring>;
  /** fetch data from the table: "loan.document_approve" */
  loan_document_approve: Array<LoanDocumentApprove>;
  /** fetch aggregated fields from the table: "loan.document_approve" */
  loan_document_approve_aggregate: LoanDocumentApproveAggregate;
  /** fetch data from the table: "loan.document_approve" using primary key columns */
  loan_document_approve_by_pk?: Maybe<LoanDocumentApprove>;
  /** fetch data from the table: "loan.financial_agreement" */
  loan_financial_agreement: Array<LoanFinancialAgreement>;
  /** fetch aggregated fields from the table: "loan.financial_agreement" */
  loan_financial_agreement_aggregate: LoanFinancialAgreementAggregate;
  /** fetch data from the table: "loan.financial_agreement" using primary key columns */
  loan_financial_agreement_by_pk?: Maybe<LoanFinancialAgreement>;
  /** fetch data from the table: "loan.financial_rescue" */
  loan_financial_rescue: Array<LoanFinancialRescue>;
  /** fetch aggregated fields from the table: "loan.financial_rescue" */
  loan_financial_rescue_aggregate: LoanFinancialRescueAggregate;
  /** fetch data from the table: "loan.financial_rescue" using primary key columns */
  loan_financial_rescue_by_pk?: Maybe<LoanFinancialRescue>;
  /** fetch data from the table: "loan.objective" */
  loan_objective: Array<LoanObjective>;
  /** fetch aggregated fields from the table: "loan.objective" */
  loan_objective_aggregate: LoanObjectiveAggregate;
  /** fetch data from the table: "loan.objective" using primary key columns */
  loan_objective_by_pk?: Maybe<LoanObjective>;
  /** fetch data from the table: "loan.program" */
  loan_program: Array<LoanProgram>;
  /** fetch aggregated fields from the table: "loan.program" */
  loan_program_aggregate: LoanProgramAggregate;
  /** fetch data from the table: "loan.program" using primary key columns */
  loan_program_by_pk?: Maybe<LoanProgram>;
  /** fetch data from the table: "loan.program_status" */
  loan_program_status: Array<LoanProgramStatus>;
  /** fetch aggregated fields from the table: "loan.program_status" */
  loan_program_status_aggregate: LoanProgramStatusAggregate;
  /** fetch data from the table: "loan.program_status" using primary key columns */
  loan_program_status_by_pk?: Maybe<LoanProgramStatus>;
  /** fetch data from the table: "loan.program_transaction" */
  loan_program_transaction: Array<LoanProgramTransaction>;
  /** fetch aggregated fields from the table: "loan.program_transaction" */
  loan_program_transaction_aggregate: LoanProgramTransactionAggregate;
  /** fetch data from the table: "loan.program_transaction" using primary key columns */
  loan_program_transaction_by_pk?: Maybe<LoanProgramTransaction>;
  /** fetch data from the table: "loan.program_transaction_type" */
  loan_program_transaction_type: Array<LoanProgramTransactionType>;
  /** fetch aggregated fields from the table: "loan.program_transaction_type" */
  loan_program_transaction_type_aggregate: LoanProgramTransactionTypeAggregate;
  /** fetch data from the table: "loan.program_transaction_type" using primary key columns */
  loan_program_transaction_type_by_pk?: Maybe<LoanProgramTransactionType>;
  /** fetch data from the table: "loan.proprietary" */
  loan_proprietary: Array<LoanProprietary>;
  /** fetch aggregated fields from the table: "loan.proprietary" */
  loan_proprietary_aggregate: LoanProprietaryAggregate;
  /** fetch data from the table: "loan.proprietary" using primary key columns */
  loan_proprietary_by_pk?: Maybe<LoanProprietary>;
  /** fetch data from the table: "loan.repay_type" */
  loan_repay_type: Array<LoanRepayType>;
  /** fetch aggregated fields from the table: "loan.repay_type" */
  loan_repay_type_aggregate: LoanRepayTypeAggregate;
  /** fetch data from the table: "loan.repay_type" using primary key columns */
  loan_repay_type_by_pk?: Maybe<LoanRepayType>;
  /** fetch data from the table: "loan.s_curve" */
  loan_s_curve: Array<LoanSCurve>;
  /** fetch aggregated fields from the table: "loan.s_curve" */
  loan_s_curve_aggregate: LoanSCurveAggregate;
  /** fetch data from the table: "loan.s_curve" using primary key columns */
  loan_s_curve_by_pk?: Maybe<LoanSCurve>;
  /** fetch data from the table: "loan.s_curve_type" */
  loan_s_curve_type: Array<LoanSCurveType>;
  /** fetch aggregated fields from the table: "loan.s_curve_type" */
  loan_s_curve_type_aggregate: LoanSCurveTypeAggregate;
  /** fetch data from the table: "loan.s_curve_type" using primary key columns */
  loan_s_curve_type_by_pk?: Maybe<LoanSCurveType>;
  /** fetch data from the table: "loan.sme_definition" */
  loan_sme_definition: Array<LoanSmeDefinition>;
  /** fetch aggregated fields from the table: "loan.sme_definition" */
  loan_sme_definition_aggregate: LoanSmeDefinitionAggregate;
  /** fetch data from the table: "loan.sme_definition" using primary key columns */
  loan_sme_definition_by_pk?: Maybe<LoanSmeDefinition>;
  /** fetch data from the table: "loan_source.business_type" */
  loan_source_business_type: Array<LoanSourceBusinessType>;
  /** fetch aggregated fields from the table: "loan_source.business_type" */
  loan_source_business_type_aggregate: LoanSourceBusinessTypeAggregate;
  /** fetch data from the table: "loan_source.business_type" using primary key columns */
  loan_source_business_type_by_pk?: Maybe<LoanSourceBusinessType>;
  /** fetch data from the table: "loan_source.financial_institution" */
  loan_source_financial_institution: Array<LoanSourceFinancialInstitution>;
  /** fetch aggregated fields from the table: "loan_source.financial_institution" */
  loan_source_financial_institution_aggregate: LoanSourceFinancialInstitutionAggregate;
  /** fetch data from the table: "loan_source.financial_institution" using primary key columns */
  loan_source_financial_institution_by_pk?: Maybe<LoanSourceFinancialInstitution>;
  /** fetch data from the table: "loan_source.loan" */
  loan_source_loan: Array<LoanSourceLoan>;
  /** fetch aggregated fields from the table: "loan_source.loan" */
  loan_source_loan_aggregate: LoanSourceLoanAggregate;
  /** fetch data from the table: "loan_source.loan_attachment" */
  loan_source_loan_attachment: Array<LoanSourceLoanAttachment>;
  /** fetch aggregated fields from the table: "loan_source.loan_attachment" */
  loan_source_loan_attachment_aggregate: LoanSourceLoanAttachmentAggregate;
  /** fetch data from the table: "loan_source.loan_attachment" using primary key columns */
  loan_source_loan_attachment_by_pk?: Maybe<LoanSourceLoanAttachment>;
  /** fetch data from the table: "loan_source.loan_business_type" */
  loan_source_loan_business_type: Array<LoanSourceLoanBusinessType>;
  /** fetch aggregated fields from the table: "loan_source.loan_business_type" */
  loan_source_loan_business_type_aggregate: LoanSourceLoanBusinessTypeAggregate;
  /** fetch data from the table: "loan_source.loan_business_type" using primary key columns */
  loan_source_loan_business_type_by_pk?: Maybe<LoanSourceLoanBusinessType>;
  /** fetch data from the table: "loan_source.loan" using primary key columns */
  loan_source_loan_by_pk?: Maybe<LoanSourceLoan>;
  /** fetch data from the table: "loan_source.objective" */
  loan_source_objective: Array<LoanSourceObjective>;
  /** fetch aggregated fields from the table: "loan_source.objective" */
  loan_source_objective_aggregate: LoanSourceObjectiveAggregate;
  /** fetch data from the table: "loan_source.objective" using primary key columns */
  loan_source_objective_by_pk?: Maybe<LoanSourceObjective>;
  /** fetch data from the table: "loan_source.search_log" */
  loan_source_search_log: Array<LoanSourceSearchLog>;
  /** fetch aggregated fields from the table: "loan_source.search_log" */
  loan_source_search_log_aggregate: LoanSourceSearchLogAggregate;
  /** fetch data from the table: "loan_source.search_log" using primary key columns */
  loan_source_search_log_by_pk?: Maybe<LoanSourceSearchLog>;
  /** fetch data from the table: "loan.transfer_status" */
  loan_transfer_status: Array<LoanTransferStatus>;
  /** fetch aggregated fields from the table: "loan.transfer_status" */
  loan_transfer_status_aggregate: LoanTransferStatusAggregate;
  /** fetch data from the table: "loan.transfer_status" using primary key columns */
  loan_transfer_status_by_pk?: Maybe<LoanTransferStatus>;
  /** fetch data from the table: "me" */
  me: Array<Me>;
  /** fetch aggregated fields from the table: "me" */
  me_aggregate: MeAggregate;
  /** fetch data from the table: "month_of_fiscal_year" */
  month_of_fiscal_year: Array<MonthOfFiscalYear>;
  /** fetch aggregated fields from the table: "month_of_fiscal_year" */
  month_of_fiscal_year_aggregate: MonthOfFiscalYearAggregate;
  /** fetch data from the table: "name_title" */
  name_title: Array<NameTitle>;
  /** fetch aggregated fields from the table: "name_title" */
  name_title_aggregate: NameTitleAggregate;
  /** fetch data from the table: "name_title" using primary key columns */
  name_title_by_pk?: Maybe<NameTitle>;
  /** fetch data from the table: "notification" */
  notification: Array<Notification>;
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: NotificationAggregate;
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<Notification>;
  /** fetch data from the table: "notification_status" */
  notification_status: Array<NotificationStatus>;
  /** fetch aggregated fields from the table: "notification_status" */
  notification_status_aggregate: NotificationStatusAggregate;
  /** fetch data from the table: "notification_status" using primary key columns */
  notification_status_by_pk?: Maybe<NotificationStatus>;
  /** fetch data from the table: "organization" */
  organization: Array<Organization>;
  /** fetch aggregated fields from the table: "organization" */
  organization_aggregate: OrganizationAggregate;
  /** fetch data from the table: "organization" using primary key columns */
  organization_by_pk?: Maybe<Organization>;
  /** fetch data from the table: "organization_other" */
  organization_other: Array<OrganizationOther>;
  /** fetch aggregated fields from the table: "organization_other" */
  organization_other_aggregate: OrganizationOtherAggregate;
  /** fetch data from the table: "organization_province" */
  organization_province: Array<OrganizationProvince>;
  /** fetch aggregated fields from the table: "organization_province" */
  organization_province_aggregate: OrganizationProvinceAggregate;
  /** fetch data from the table: "organization_type" */
  organization_type: Array<OrganizationType>;
  /** fetch aggregated fields from the table: "organization_type" */
  organization_type_aggregate: OrganizationTypeAggregate;
  /** fetch data from the table: "organization_type" using primary key columns */
  organization_type_by_pk?: Maybe<OrganizationType>;
  /** fetch data from the table: "permission" */
  permission: Array<Permission>;
  /** fetch aggregated fields from the table: "permission" */
  permission_aggregate: PermissionAggregate;
  /** fetch data from the table: "permission" using primary key columns */
  permission_by_pk?: Maybe<Permission>;
  /** fetch data from the table: "posts" */
  posts: Array<Posts>;
  /** fetch aggregated fields from the table: "posts" */
  posts_aggregate: PostsAggregate;
  /** fetch data from the table: "posts" using primary key columns */
  posts_by_pk?: Maybe<Posts>;
  /** fetch data from the table: "project.approved" */
  project_approved: Array<ProjectApproved>;
  /** fetch aggregated fields from the table: "project.approved" */
  project_approved_aggregate: ProjectApprovedAggregate;
  /** fetch data from the table: "project.approved" using primary key columns */
  project_approved_by_pk?: Maybe<ProjectApproved>;
  /** fetch data from the table: "project.attachment_type" */
  project_attachment_type: Array<ProjectAttachmentType>;
  /** fetch aggregated fields from the table: "project.attachment_type" */
  project_attachment_type_aggregate: ProjectAttachmentTypeAggregate;
  /** fetch data from the table: "project.attachment_type" using primary key columns */
  project_attachment_type_by_pk?: Maybe<ProjectAttachmentType>;
  /** fetch data from the table: "project.budget" */
  project_budget: Array<ProjectBudget>;
  /** fetch aggregated fields from the table: "project.budget" */
  project_budget_aggregate: ProjectBudgetAggregate;
  /** fetch data from the table: "project.budget" using primary key columns */
  project_budget_by_pk?: Maybe<ProjectBudget>;
  /** fetch data from the table: "project.budget_group" */
  project_budget_group: Array<ProjectBudgetGroup>;
  /** fetch aggregated fields from the table: "project.budget_group" */
  project_budget_group_aggregate: ProjectBudgetGroupAggregate;
  /** fetch data from the table: "project.budget_group" using primary key columns */
  project_budget_group_by_pk?: Maybe<ProjectBudgetGroup>;
  /** fetch data from the table: "project.budget_group_mapping" */
  project_budget_group_mapping: Array<ProjectBudgetGroupMapping>;
  /** fetch aggregated fields from the table: "project.budget_group_mapping" */
  project_budget_group_mapping_aggregate: ProjectBudgetGroupMappingAggregate;
  /** fetch data from the table: "project.budget_group_mapping" using primary key columns */
  project_budget_group_mapping_by_pk?: Maybe<ProjectBudgetGroupMapping>;
  /** fetch data from the table: "project.budget_plan" */
  project_budget_plan: Array<ProjectBudgetPlan>;
  /** fetch aggregated fields from the table: "project.budget_plan" */
  project_budget_plan_aggregate: ProjectBudgetPlanAggregate;
  /** fetch data from the table: "project.indicator_type" */
  project_indicator_type: Array<ProjectIndicatorType>;
  /** fetch aggregated fields from the table: "project.indicator_type" */
  project_indicator_type_aggregate: ProjectIndicatorTypeAggregate;
  /** fetch data from the table: "project.indicator_type" using primary key columns */
  project_indicator_type_by_pk?: Maybe<ProjectIndicatorType>;
  /** fetch data from the table: "project.master_plan" */
  project_master_plan: Array<ProjectMasterPlan>;
  /** fetch aggregated fields from the table: "project.master_plan" */
  project_master_plan_aggregate: ProjectMasterPlanAggregate;
  /** fetch data from the table: "project.national_strategy" */
  project_national_strategy: Array<ProjectNationalStrategy>;
  /** fetch aggregated fields from the table: "project.national_strategy" */
  project_national_strategy_aggregate: ProjectNationalStrategyAggregate;
  /** fetch data from the table: "project.national_strategy" using primary key columns */
  project_national_strategy_by_pk?: Maybe<ProjectNationalStrategy>;
  /** fetch data from the table: "project.operating_activity_progress" */
  project_operating_activity_progress: Array<ProjectOperatingActivityProgress>;
  /** fetch aggregated fields from the table: "project.operating_activity_progress" */
  project_operating_activity_progress_aggregate: ProjectOperatingActivityProgressAggregate;
  /** fetch data from the table: "project.operating_activity_progress" using primary key columns */
  project_operating_activity_progress_by_pk?: Maybe<ProjectOperatingActivityProgress>;
  /** fetch data from the table: "project.operating_activity_progress_revision" */
  project_operating_activity_progress_revision: Array<ProjectOperatingActivityProgressRevision>;
  /** fetch aggregated fields from the table: "project.operating_activity_progress_revision" */
  project_operating_activity_progress_revision_aggregate: ProjectOperatingActivityProgressRevisionAggregate;
  /** fetch data from the table: "project.operating_activity_progress_revision" using primary key columns */
  project_operating_activity_progress_revision_by_pk?: Maybe<ProjectOperatingActivityProgressRevision>;
  /** fetch data from the table: "project.operating_activity_type" */
  project_operating_activity_type: Array<ProjectOperatingActivityType>;
  /** fetch aggregated fields from the table: "project.operating_activity_type" */
  project_operating_activity_type_aggregate: ProjectOperatingActivityTypeAggregate;
  /** fetch data from the table: "project.operating_activity_type" using primary key columns */
  project_operating_activity_type_by_pk?: Maybe<ProjectOperatingActivityType>;
  /** fetch data from the table: "project.project" */
  project_project: Array<ProjectProject>;
  /** fetch data from the table: "project.project_achievement" */
  project_project_achievement: Array<ProjectProjectAchievement>;
  /** fetch aggregated fields from the table: "project.project_achievement" */
  project_project_achievement_aggregate: ProjectProjectAchievementAggregate;
  /** fetch data from the table: "project.project_achievement" using primary key columns */
  project_project_achievement_by_pk?: Maybe<ProjectProjectAchievement>;
  /** fetch aggregated fields from the table: "project.project" */
  project_project_aggregate: ProjectProjectAggregate;
  /** fetch data from the table: "project.project_attachment" */
  project_project_attachment: Array<ProjectProjectAttachment>;
  /** fetch aggregated fields from the table: "project.project_attachment" */
  project_project_attachment_aggregate: ProjectProjectAttachmentAggregate;
  /** fetch data from the table: "project.project_attachment" using primary key columns */
  project_project_attachment_by_pk?: Maybe<ProjectProjectAttachment>;
  /** fetch data from the table: "project.project_budget_item" */
  project_project_budget_item: Array<ProjectProjectBudgetItem>;
  /** fetch aggregated fields from the table: "project.project_budget_item" */
  project_project_budget_item_aggregate: ProjectProjectBudgetItemAggregate;
  /** fetch data from the table: "project.project_budget_item" using primary key columns */
  project_project_budget_item_by_pk?: Maybe<ProjectProjectBudgetItem>;
  /** fetch data from the table: "project.project_budget_item_summary" */
  project_project_budget_item_summary: Array<ProjectProjectBudgetItemSummary>;
  /** fetch aggregated fields from the table: "project.project_budget_item_summary" */
  project_project_budget_item_summary_aggregate: ProjectProjectBudgetItemSummaryAggregate;
  /** fetch data from the table: "project.project_budget_summary" */
  project_project_budget_summary: Array<ProjectProjectBudgetSummary>;
  /** fetch aggregated fields from the table: "project.project_budget_summary" */
  project_project_budget_summary_aggregate: ProjectProjectBudgetSummaryAggregate;
  /** fetch data from the table: "project.project_budget_template" */
  project_project_budget_template: Array<ProjectProjectBudgetTemplate>;
  /** fetch aggregated fields from the table: "project.project_budget_template" */
  project_project_budget_template_aggregate: ProjectProjectBudgetTemplateAggregate;
  /** fetch data from the table: "project.project" using primary key columns */
  project_project_by_pk?: Maybe<ProjectProject>;
  /** fetch data from the table: "project.project_event_log" */
  project_project_event_log: Array<ProjectProjectEventLog>;
  /** fetch aggregated fields from the table: "project.project_event_log" */
  project_project_event_log_aggregate: ProjectProjectEventLogAggregate;
  /** fetch data from the table: "project.project_event_log" using primary key columns */
  project_project_event_log_by_pk?: Maybe<ProjectProjectEventLog>;
  /** fetch data from the table: "project.project_indicator" */
  project_project_indicator: Array<ProjectProjectIndicator>;
  /** fetch aggregated fields from the table: "project.project_indicator" */
  project_project_indicator_aggregate: ProjectProjectIndicatorAggregate;
  /** fetch data from the table: "project.project_indicator" using primary key columns */
  project_project_indicator_by_pk?: Maybe<ProjectProjectIndicator>;
  /** fetch data from the table: "project.project_member" */
  project_project_member: Array<ProjectProjectMember>;
  /** fetch aggregated fields from the table: "project.project_member" */
  project_project_member_aggregate: ProjectProjectMemberAggregate;
  /** fetch data from the table: "project.project_member" using primary key columns */
  project_project_member_by_pk?: Maybe<ProjectProjectMember>;
  /** fetch data from the table: "project.project_objective" */
  project_project_objective: Array<ProjectProjectObjective>;
  /** fetch aggregated fields from the table: "project.project_objective" */
  project_project_objective_aggregate: ProjectProjectObjectiveAggregate;
  /** fetch data from the table: "project.project_objective" using primary key columns */
  project_project_objective_by_pk?: Maybe<ProjectProjectObjective>;
  /** fetch data from the table: "project.project_operating_activity" */
  project_project_operating_activity: Array<ProjectProjectOperatingActivity>;
  /** fetch aggregated fields from the table: "project.project_operating_activity" */
  project_project_operating_activity_aggregate: ProjectProjectOperatingActivityAggregate;
  /** fetch data from the table: "project.project_operating_activity" using primary key columns */
  project_project_operating_activity_by_pk?: Maybe<ProjectProjectOperatingActivity>;
  /** fetch data from the table: "project.project_operating_area" */
  project_project_operating_area: Array<ProjectProjectOperatingArea>;
  /** fetch aggregated fields from the table: "project.project_operating_area" */
  project_project_operating_area_aggregate: ProjectProjectOperatingAreaAggregate;
  /** fetch data from the table: "project.project_operating_area" using primary key columns */
  project_project_operating_area_by_pk?: Maybe<ProjectProjectOperatingArea>;
  /** fetch data from the table: "project.project_step" */
  project_project_step: Array<ProjectProjectStep>;
  /** fetch aggregated fields from the table: "project.project_step" */
  project_project_step_aggregate: ProjectProjectStepAggregate;
  /** fetch data from the table: "project.project_step" using primary key columns */
  project_project_step_by_pk?: Maybe<ProjectProjectStep>;
  /** fetch data from the table: "project.project_target_group" */
  project_project_target_group: Array<ProjectProjectTargetGroup>;
  /** fetch aggregated fields from the table: "project.project_target_group" */
  project_project_target_group_aggregate: ProjectProjectTargetGroupAggregate;
  /** fetch data from the table: "project.project_target_group" using primary key columns */
  project_project_target_group_by_pk?: Maybe<ProjectProjectTargetGroup>;
  /** fetch data from the table: "project.project_workload_summary" */
  project_project_workload_summary: Array<ProjectProjectWorkloadSummary>;
  /** fetch aggregated fields from the table: "project.project_workload_summary" */
  project_project_workload_summary_aggregate: ProjectProjectWorkloadSummaryAggregate;
  /** fetch data from the table: "project.sub_plan" */
  project_sub_plan: Array<ProjectSubPlan>;
  /** fetch aggregated fields from the table: "project.sub_plan" */
  project_sub_plan_aggregate: ProjectSubPlanAggregate;
  /** fetch data from the table: "project.target_group" */
  project_target_group: Array<ProjectTargetGroup>;
  /** fetch aggregated fields from the table: "project.target_group" */
  project_target_group_aggregate: ProjectTargetGroupAggregate;
  /** fetch data from the table: "project.target_group" using primary key columns */
  project_target_group_by_pk?: Maybe<ProjectTargetGroup>;
  /** fetch data from the table: "project.unit" */
  project_unit: Array<ProjectUnit>;
  /** fetch aggregated fields from the table: "project.unit" */
  project_unit_aggregate: ProjectUnitAggregate;
  /** fetch data from the table: "project.unit" using primary key columns */
  project_unit_by_pk?: Maybe<ProjectUnit>;
  /** fetch data from the table: "province" */
  province: Array<Province>;
  /** fetch aggregated fields from the table: "province" */
  province_aggregate: ProvinceAggregate;
  /** fetch data from the table: "province" using primary key columns */
  province_by_pk?: Maybe<Province>;
  /** fetch data from the table: "region" */
  region: Array<Region>;
  /** fetch aggregated fields from the table: "region" */
  region_aggregate: RegionAggregate;
  /** fetch data from the table: "region" using primary key columns */
  region_by_pk?: Maybe<Region>;
  /** fetch data from the table: "user_mapping" */
  user_mapping: Array<UserMapping>;
  /** fetch aggregated fields from the table: "user_mapping" */
  user_mapping_aggregate: UserMappingAggregate;
  /** fetch data from the table: "user_mapping" using primary key columns */
  user_mapping_by_pk?: Maybe<UserMapping>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "users_permission" */
  users_permission: Array<UsersPermission>;
  /** fetch aggregated fields from the table: "users_permission" */
  users_permission_aggregate: UsersPermissionAggregate;
  /** fetch data from the table: "users_permission" using primary key columns */
  users_permission_by_pk?: Maybe<UsersPermission>;
};


export type SubscriptionRootAccountAccountArgs = {
  distinct_on?: Maybe<Array<AccountAccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountOrderBy>>;
  where?: Maybe<AccountAccountBoolExp>;
};


export type SubscriptionRootAccountAccountAggregateArgs = {
  distinct_on?: Maybe<Array<AccountAccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountOrderBy>>;
  where?: Maybe<AccountAccountBoolExp>;
};


export type SubscriptionRootAccountAccountBalanceArgs = {
  distinct_on?: Maybe<Array<AccountAccountBalanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountBalanceOrderBy>>;
  where?: Maybe<AccountAccountBalanceBoolExp>;
};


export type SubscriptionRootAccountAccountBalanceAggregateArgs = {
  distinct_on?: Maybe<Array<AccountAccountBalanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountBalanceOrderBy>>;
  where?: Maybe<AccountAccountBalanceBoolExp>;
};


export type SubscriptionRootAccountAccountBalanceSheetArgs = {
  distinct_on?: Maybe<Array<AccountAccountBalanceSheetSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountBalanceSheetOrderBy>>;
  where?: Maybe<AccountAccountBalanceSheetBoolExp>;
};


export type SubscriptionRootAccountAccountBalanceSheetAggregateArgs = {
  distinct_on?: Maybe<Array<AccountAccountBalanceSheetSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountBalanceSheetOrderBy>>;
  where?: Maybe<AccountAccountBalanceSheetBoolExp>;
};


export type SubscriptionRootAccountAccountByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAccountAccountCategoryArgs = {
  distinct_on?: Maybe<Array<AccountAccountCategorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountCategoryOrderBy>>;
  where?: Maybe<AccountAccountCategoryBoolExp>;
};


export type SubscriptionRootAccountAccountCategoryAggregateArgs = {
  distinct_on?: Maybe<Array<AccountAccountCategorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountCategoryOrderBy>>;
  where?: Maybe<AccountAccountCategoryBoolExp>;
};


export type SubscriptionRootAccountAccountCategoryByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAccountAccountMonthlyReportArgs = {
  distinct_on?: Maybe<Array<AccountAccountMonthlyReportSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountMonthlyReportOrderBy>>;
  where?: Maybe<AccountAccountMonthlyReportBoolExp>;
};


export type SubscriptionRootAccountAccountMonthlyReportAggregateArgs = {
  distinct_on?: Maybe<Array<AccountAccountMonthlyReportSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountMonthlyReportOrderBy>>;
  where?: Maybe<AccountAccountMonthlyReportBoolExp>;
};


export type SubscriptionRootAccountAccountTypeArgs = {
  distinct_on?: Maybe<Array<AccountAccountTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountTypeOrderBy>>;
  where?: Maybe<AccountAccountTypeBoolExp>;
};


export type SubscriptionRootAccountAccountTypeAggregateArgs = {
  distinct_on?: Maybe<Array<AccountAccountTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountAccountTypeOrderBy>>;
  where?: Maybe<AccountAccountTypeBoolExp>;
};


export type SubscriptionRootAccountAccountTypeByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootAccountBudgetAccountArgs = {
  distinct_on?: Maybe<Array<AccountBudgetAccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetAccountOrderBy>>;
  where?: Maybe<AccountBudgetAccountBoolExp>;
};


export type SubscriptionRootAccountBudgetAccountAggregateArgs = {
  distinct_on?: Maybe<Array<AccountBudgetAccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetAccountOrderBy>>;
  where?: Maybe<AccountBudgetAccountBoolExp>;
};


export type SubscriptionRootAccountBudgetAccountByPkArgs = {
  account_id: Scalars['uuid'];
  budget_id: Scalars['String'];
  fiscal_year: Scalars['Int'];
};


export type SubscriptionRootAccountBudgetAllocationArgs = {
  distinct_on?: Maybe<Array<AccountBudgetAllocationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetAllocationOrderBy>>;
  where?: Maybe<AccountBudgetAllocationBoolExp>;
};


export type SubscriptionRootAccountBudgetAllocationAggregateArgs = {
  distinct_on?: Maybe<Array<AccountBudgetAllocationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetAllocationOrderBy>>;
  where?: Maybe<AccountBudgetAllocationBoolExp>;
};


export type SubscriptionRootAccountBudgetAllocationByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAccountBudgetAllocationLogArgs = {
  distinct_on?: Maybe<Array<AccountBudgetAllocationLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetAllocationLogOrderBy>>;
  where?: Maybe<AccountBudgetAllocationLogBoolExp>;
};


export type SubscriptionRootAccountBudgetAllocationLogAggregateArgs = {
  distinct_on?: Maybe<Array<AccountBudgetAllocationLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetAllocationLogOrderBy>>;
  where?: Maybe<AccountBudgetAllocationLogBoolExp>;
};


export type SubscriptionRootAccountBudgetAllocationLogByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAccountBudgetTypeArgs = {
  distinct_on?: Maybe<Array<AccountBudgetTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetTypeOrderBy>>;
  where?: Maybe<AccountBudgetTypeBoolExp>;
};


export type SubscriptionRootAccountBudgetTypeAggregateArgs = {
  distinct_on?: Maybe<Array<AccountBudgetTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountBudgetTypeOrderBy>>;
  where?: Maybe<AccountBudgetTypeBoolExp>;
};


export type SubscriptionRootAccountBudgetTypeByPkArgs = {
  fiscal_year: Scalars['Int'];
  id: Scalars['String'];
};


export type SubscriptionRootAccountEntryTypeArgs = {
  distinct_on?: Maybe<Array<AccountEntryTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountEntryTypeOrderBy>>;
  where?: Maybe<AccountEntryTypeBoolExp>;
};


export type SubscriptionRootAccountEntryTypeAggregateArgs = {
  distinct_on?: Maybe<Array<AccountEntryTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountEntryTypeOrderBy>>;
  where?: Maybe<AccountEntryTypeBoolExp>;
};


export type SubscriptionRootAccountEntryTypeByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootAccountLedgerEntryArgs = {
  distinct_on?: Maybe<Array<AccountLedgerEntrySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLedgerEntryOrderBy>>;
  where?: Maybe<AccountLedgerEntryBoolExp>;
};


export type SubscriptionRootAccountLedgerEntryAggregateArgs = {
  distinct_on?: Maybe<Array<AccountLedgerEntrySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLedgerEntryOrderBy>>;
  where?: Maybe<AccountLedgerEntryBoolExp>;
};


export type SubscriptionRootAccountLedgerEntryByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAccountLedgerEntryViewArgs = {
  distinct_on?: Maybe<Array<AccountLedgerEntryViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLedgerEntryViewOrderBy>>;
  where?: Maybe<AccountLedgerEntryViewBoolExp>;
};


export type SubscriptionRootAccountLedgerEntryViewAggregateArgs = {
  distinct_on?: Maybe<Array<AccountLedgerEntryViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLedgerEntryViewOrderBy>>;
  where?: Maybe<AccountLedgerEntryViewBoolExp>;
};


export type SubscriptionRootAccountLendingArgs = {
  distinct_on?: Maybe<Array<AccountLendingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLendingOrderBy>>;
  where?: Maybe<AccountLendingBoolExp>;
};


export type SubscriptionRootAccountLendingAggregateArgs = {
  distinct_on?: Maybe<Array<AccountLendingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLendingOrderBy>>;
  where?: Maybe<AccountLendingBoolExp>;
};


export type SubscriptionRootAccountLendingByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAccountLendingItemArgs = {
  distinct_on?: Maybe<Array<AccountLendingItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLendingItemOrderBy>>;
  where?: Maybe<AccountLendingItemBoolExp>;
};


export type SubscriptionRootAccountLendingItemAggregateArgs = {
  distinct_on?: Maybe<Array<AccountLendingItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLendingItemOrderBy>>;
  where?: Maybe<AccountLendingItemBoolExp>;
};


export type SubscriptionRootAccountLendingItemByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAccountLendingStatusArgs = {
  distinct_on?: Maybe<Array<AccountLendingStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLendingStatusOrderBy>>;
  where?: Maybe<AccountLendingStatusBoolExp>;
};


export type SubscriptionRootAccountLendingStatusAggregateArgs = {
  distinct_on?: Maybe<Array<AccountLendingStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLendingStatusOrderBy>>;
  where?: Maybe<AccountLendingStatusBoolExp>;
};


export type SubscriptionRootAccountLendingStatusByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootAccountTransactionArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};


export type SubscriptionRootAccountTransactionAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};


export type SubscriptionRootAccountTransactionByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAccountTransactionLogArgs = {
  distinct_on?: Maybe<Array<AccountTransactionLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionLogOrderBy>>;
  where?: Maybe<AccountTransactionLogBoolExp>;
};


export type SubscriptionRootAccountTransactionLogAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTransactionLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionLogOrderBy>>;
  where?: Maybe<AccountTransactionLogBoolExp>;
};


export type SubscriptionRootAccountTransactionLogByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAccountTransactionStatusArgs = {
  distinct_on?: Maybe<Array<AccountTransactionStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionStatusOrderBy>>;
  where?: Maybe<AccountTransactionStatusBoolExp>;
};


export type SubscriptionRootAccountTransactionStatusAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTransactionStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionStatusOrderBy>>;
  where?: Maybe<AccountTransactionStatusBoolExp>;
};


export type SubscriptionRootAccountTransactionStatusByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootAccountTransactionTypeArgs = {
  distinct_on?: Maybe<Array<AccountTransactionTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionTypeOrderBy>>;
  where?: Maybe<AccountTransactionTypeBoolExp>;
};


export type SubscriptionRootAccountTransactionTypeAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTransactionTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionTypeOrderBy>>;
  where?: Maybe<AccountTransactionTypeBoolExp>;
};


export type SubscriptionRootAccountTransactionTypeByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootAccountTrialBalanceMonthlyArgs = {
  distinct_on?: Maybe<Array<AccountTrialBalanceMonthlySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTrialBalanceMonthlyOrderBy>>;
  where?: Maybe<AccountTrialBalanceMonthlyBoolExp>;
};


export type SubscriptionRootAccountTrialBalanceMonthlyAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTrialBalanceMonthlySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTrialBalanceMonthlyOrderBy>>;
  where?: Maybe<AccountTrialBalanceMonthlyBoolExp>;
};


export type SubscriptionRootAttachmentArgs = {
  distinct_on?: Maybe<Array<AttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AttachmentOrderBy>>;
  where?: Maybe<AttachmentBoolExp>;
};


export type SubscriptionRootAttachmentAggregateArgs = {
  distinct_on?: Maybe<Array<AttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AttachmentOrderBy>>;
  where?: Maybe<AttachmentBoolExp>;
};


export type SubscriptionRootAttachmentByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAuditLoggedActionsArgs = {
  distinct_on?: Maybe<Array<AuditLoggedActionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuditLoggedActionsOrderBy>>;
  where?: Maybe<AuditLoggedActionsBoolExp>;
};


export type SubscriptionRootAuditLoggedActionsAggregateArgs = {
  distinct_on?: Maybe<Array<AuditLoggedActionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuditLoggedActionsOrderBy>>;
  where?: Maybe<AuditLoggedActionsBoolExp>;
};


export type SubscriptionRootAuditLoggedActionsByPkArgs = {
  event_id: Scalars['bigint'];
};


export type SubscriptionRootAuthAccountProvidersArgs = {
  distinct_on?: Maybe<Array<AuthAccountProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountProvidersOrderBy>>;
  where?: Maybe<AuthAccountProvidersBoolExp>;
};


export type SubscriptionRootAuthAccountProvidersAggregateArgs = {
  distinct_on?: Maybe<Array<AuthAccountProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountProvidersOrderBy>>;
  where?: Maybe<AuthAccountProvidersBoolExp>;
};


export type SubscriptionRootAuthAccountProvidersByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAuthAccountRolesArgs = {
  distinct_on?: Maybe<Array<AuthAccountRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountRolesOrderBy>>;
  where?: Maybe<AuthAccountRolesBoolExp>;
};


export type SubscriptionRootAuthAccountRolesAggregateArgs = {
  distinct_on?: Maybe<Array<AuthAccountRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountRolesOrderBy>>;
  where?: Maybe<AuthAccountRolesBoolExp>;
};


export type SubscriptionRootAuthAccountRolesByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAuthAccountsArgs = {
  distinct_on?: Maybe<Array<AuthAccountsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountsOrderBy>>;
  where?: Maybe<AuthAccountsBoolExp>;
};


export type SubscriptionRootAuthAccountsAggregateArgs = {
  distinct_on?: Maybe<Array<AuthAccountsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthAccountsOrderBy>>;
  where?: Maybe<AuthAccountsBoolExp>;
};


export type SubscriptionRootAuthAccountsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAuthProvidersArgs = {
  distinct_on?: Maybe<Array<AuthProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthProvidersOrderBy>>;
  where?: Maybe<AuthProvidersBoolExp>;
};


export type SubscriptionRootAuthProvidersAggregateArgs = {
  distinct_on?: Maybe<Array<AuthProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthProvidersOrderBy>>;
  where?: Maybe<AuthProvidersBoolExp>;
};


export type SubscriptionRootAuthProvidersByPkArgs = {
  provider: Scalars['String'];
};


export type SubscriptionRootAuthRefreshTokensArgs = {
  distinct_on?: Maybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthRefreshTokensOrderBy>>;
  where?: Maybe<AuthRefreshTokensBoolExp>;
};


export type SubscriptionRootAuthRefreshTokensAggregateArgs = {
  distinct_on?: Maybe<Array<AuthRefreshTokensSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthRefreshTokensOrderBy>>;
  where?: Maybe<AuthRefreshTokensBoolExp>;
};


export type SubscriptionRootAuthRefreshTokensByPkArgs = {
  refresh_token: Scalars['uuid'];
};


export type SubscriptionRootAuthRolesArgs = {
  distinct_on?: Maybe<Array<AuthRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthRolesOrderBy>>;
  where?: Maybe<AuthRolesBoolExp>;
};


export type SubscriptionRootAuthRolesAggregateArgs = {
  distinct_on?: Maybe<Array<AuthRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthRolesOrderBy>>;
  where?: Maybe<AuthRolesBoolExp>;
};


export type SubscriptionRootAuthRolesByPkArgs = {
  role: Scalars['String'];
};


export type SubscriptionRootBankBranchArgs = {
  distinct_on?: Maybe<Array<BankBranchSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BankBranchOrderBy>>;
  where?: Maybe<BankBranchBoolExp>;
};


export type SubscriptionRootBankBranchAggregateArgs = {
  distinct_on?: Maybe<Array<BankBranchSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BankBranchOrderBy>>;
  where?: Maybe<BankBranchBoolExp>;
};


export type SubscriptionRootBankBranchByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootContractAfStatusArgs = {
  distinct_on?: Maybe<Array<ContractAfStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAfStatusOrderBy>>;
  where?: Maybe<ContractAfStatusBoolExp>;
};


export type SubscriptionRootContractAfStatusAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAfStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAfStatusOrderBy>>;
  where?: Maybe<ContractAfStatusBoolExp>;
};


export type SubscriptionRootContractAfStatusByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootContractAgreementArgs = {
  distinct_on?: Maybe<Array<ContractAgreementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementOrderBy>>;
  where?: Maybe<ContractAgreementBoolExp>;
};


export type SubscriptionRootContractAgreementAfStatusCountArgs = {
  distinct_on?: Maybe<Array<ContractAgreementAfStatusCountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementAfStatusCountOrderBy>>;
  where?: Maybe<ContractAgreementAfStatusCountBoolExp>;
};


export type SubscriptionRootContractAgreementAfStatusCountAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementAfStatusCountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementAfStatusCountOrderBy>>;
  where?: Maybe<ContractAgreementAfStatusCountBoolExp>;
};


export type SubscriptionRootContractAgreementAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementOrderBy>>;
  where?: Maybe<ContractAgreementBoolExp>;
};


export type SubscriptionRootContractAgreementAttachmentArgs = {
  distinct_on?: Maybe<Array<ContractAgreementAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementAttachmentOrderBy>>;
  where?: Maybe<ContractAgreementAttachmentBoolExp>;
};


export type SubscriptionRootContractAgreementAttachmentAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementAttachmentOrderBy>>;
  where?: Maybe<ContractAgreementAttachmentBoolExp>;
};


export type SubscriptionRootContractAgreementAttachmentByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootContractAgreementByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootContractAgreementDetailArgs = {
  distinct_on?: Maybe<Array<ContractAgreementDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementDetailOrderBy>>;
  where?: Maybe<ContractAgreementDetailBoolExp>;
};


export type SubscriptionRootContractAgreementDetailAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementDetailOrderBy>>;
  where?: Maybe<ContractAgreementDetailBoolExp>;
};


export type SubscriptionRootContractAgreementDetailByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootContractAgreementDisbursementArgs = {
  distinct_on?: Maybe<Array<ContractAgreementDisbursementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementDisbursementOrderBy>>;
  where?: Maybe<ContractAgreementDisbursementBoolExp>;
};


export type SubscriptionRootContractAgreementDisbursementAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementDisbursementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementDisbursementOrderBy>>;
  where?: Maybe<ContractAgreementDisbursementBoolExp>;
};


export type SubscriptionRootContractAgreementDisbursementByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootContractAgreementFeeChargeArgs = {
  distinct_on?: Maybe<Array<ContractAgreementFeeChargeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementFeeChargeOrderBy>>;
  where?: Maybe<ContractAgreementFeeChargeBoolExp>;
};


export type SubscriptionRootContractAgreementFeeChargeAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementFeeChargeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementFeeChargeOrderBy>>;
  where?: Maybe<ContractAgreementFeeChargeBoolExp>;
};


export type SubscriptionRootContractAgreementFeeChargeByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootContractAgreementGuarantorArgs = {
  distinct_on?: Maybe<Array<ContractAgreementGuarantorSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementGuarantorOrderBy>>;
  where?: Maybe<ContractAgreementGuarantorBoolExp>;
};


export type SubscriptionRootContractAgreementGuarantorAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementGuarantorSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementGuarantorOrderBy>>;
  where?: Maybe<ContractAgreementGuarantorBoolExp>;
};


export type SubscriptionRootContractAgreementGuarantorByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootContractAgreementReceiptArgs = {
  distinct_on?: Maybe<Array<ContractAgreementReceiptSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementReceiptOrderBy>>;
  where?: Maybe<ContractAgreementReceiptBoolExp>;
};


export type SubscriptionRootContractAgreementReceiptAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementReceiptSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementReceiptOrderBy>>;
  where?: Maybe<ContractAgreementReceiptBoolExp>;
};


export type SubscriptionRootContractAgreementReceiptByPkArgs = {
  receipt_no: Scalars['String'];
};


export type SubscriptionRootContractAgreementRepayCurrentMonthArgs = {
  distinct_on?: Maybe<Array<ContractAgreementRepayCurrentMonthSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementRepayCurrentMonthOrderBy>>;
  where?: Maybe<ContractAgreementRepayCurrentMonthBoolExp>;
};


export type SubscriptionRootContractAgreementRepayCurrentMonthAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementRepayCurrentMonthSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementRepayCurrentMonthOrderBy>>;
  where?: Maybe<ContractAgreementRepayCurrentMonthBoolExp>;
};


export type SubscriptionRootContractAgreementStatusArgs = {
  distinct_on?: Maybe<Array<ContractAgreementStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementStatusOrderBy>>;
  where?: Maybe<ContractAgreementStatusBoolExp>;
};


export type SubscriptionRootContractAgreementStatusAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementStatusOrderBy>>;
  where?: Maybe<ContractAgreementStatusBoolExp>;
};


export type SubscriptionRootContractAgreementStatusByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootContractAgreementStatusMapperArgs = {
  distinct_on?: Maybe<Array<ContractAgreementStatusMapperSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementStatusMapperOrderBy>>;
  where?: Maybe<ContractAgreementStatusMapperBoolExp>;
};


export type SubscriptionRootContractAgreementStatusMapperAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementStatusMapperSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementStatusMapperOrderBy>>;
  where?: Maybe<ContractAgreementStatusMapperBoolExp>;
};


export type SubscriptionRootContractAgreementStatusMapperByPkArgs = {
  bank_status: Scalars['String'];
};


export type SubscriptionRootContractAgreementTransactionArgs = {
  distinct_on?: Maybe<Array<ContractAgreementTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementTransactionOrderBy>>;
  where?: Maybe<ContractAgreementTransactionBoolExp>;
};


export type SubscriptionRootContractAgreementTransactionAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementTransactionOrderBy>>;
  where?: Maybe<ContractAgreementTransactionBoolExp>;
};


export type SubscriptionRootContractAgreementTransactionByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootContractAgreementWithdrawArgs = {
  distinct_on?: Maybe<Array<ContractAgreementWithdrawSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementWithdrawOrderBy>>;
  where?: Maybe<ContractAgreementWithdrawBoolExp>;
};


export type SubscriptionRootContractAgreementWithdrawAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementWithdrawSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementWithdrawOrderBy>>;
  where?: Maybe<ContractAgreementWithdrawBoolExp>;
};


export type SubscriptionRootContractAgreementWithdrawByPkArgs = {
  draw_down_no: Scalars['String'];
};


export type SubscriptionRootContractAttachmentTypeArgs = {
  distinct_on?: Maybe<Array<ContractAttachmentTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAttachmentTypeOrderBy>>;
  where?: Maybe<ContractAttachmentTypeBoolExp>;
};


export type SubscriptionRootContractAttachmentTypeAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAttachmentTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAttachmentTypeOrderBy>>;
  where?: Maybe<ContractAttachmentTypeBoolExp>;
};


export type SubscriptionRootContractAttachmentTypeByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootContractGradeArgs = {
  distinct_on?: Maybe<Array<ContractGradeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractGradeOrderBy>>;
  where?: Maybe<ContractGradeBoolExp>;
};


export type SubscriptionRootContractGradeAggregateArgs = {
  distinct_on?: Maybe<Array<ContractGradeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractGradeOrderBy>>;
  where?: Maybe<ContractGradeBoolExp>;
};


export type SubscriptionRootContractGradeByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootContractTransactionCreatorTypeArgs = {
  distinct_on?: Maybe<Array<ContractTransactionCreatorTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractTransactionCreatorTypeOrderBy>>;
  where?: Maybe<ContractTransactionCreatorTypeBoolExp>;
};


export type SubscriptionRootContractTransactionCreatorTypeAggregateArgs = {
  distinct_on?: Maybe<Array<ContractTransactionCreatorTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractTransactionCreatorTypeOrderBy>>;
  where?: Maybe<ContractTransactionCreatorTypeBoolExp>;
};


export type SubscriptionRootContractTransactionCreatorTypeByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootCurrentUserPermissionsArgs = {
  distinct_on?: Maybe<Array<CurrentUserPermissionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CurrentUserPermissionsOrderBy>>;
  where?: Maybe<CurrentUserPermissionsBoolExp>;
};


export type SubscriptionRootCurrentUserPermissionsAggregateArgs = {
  distinct_on?: Maybe<Array<CurrentUserPermissionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CurrentUserPermissionsOrderBy>>;
  where?: Maybe<CurrentUserPermissionsBoolExp>;
};


export type SubscriptionRootDepartmentArgs = {
  distinct_on?: Maybe<Array<DepartmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DepartmentOrderBy>>;
  where?: Maybe<DepartmentBoolExp>;
};


export type SubscriptionRootDepartmentAggregateArgs = {
  distinct_on?: Maybe<Array<DepartmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DepartmentOrderBy>>;
  where?: Maybe<DepartmentBoolExp>;
};


export type SubscriptionRootDepartmentByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootFeatureToggleArgs = {
  distinct_on?: Maybe<Array<FeatureToggleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FeatureToggleOrderBy>>;
  where?: Maybe<FeatureToggleBoolExp>;
};


export type SubscriptionRootFeatureToggleAggregateArgs = {
  distinct_on?: Maybe<Array<FeatureToggleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FeatureToggleOrderBy>>;
  where?: Maybe<FeatureToggleBoolExp>;
};


export type SubscriptionRootFeatureToggleByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootFiscalYearArgs = {
  distinct_on?: Maybe<Array<FiscalYearSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FiscalYearOrderBy>>;
  where?: Maybe<FiscalYearBoolExp>;
};


export type SubscriptionRootFiscalYearAggregateArgs = {
  distinct_on?: Maybe<Array<FiscalYearSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FiscalYearOrderBy>>;
  where?: Maybe<FiscalYearBoolExp>;
};


export type SubscriptionRootFiscalYearByPkArgs = {
  year: Scalars['Int'];
};


export type SubscriptionRootImportLogArgs = {
  distinct_on?: Maybe<Array<ImportLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ImportLogOrderBy>>;
  where?: Maybe<ImportLogBoolExp>;
};


export type SubscriptionRootImportLogAggregateArgs = {
  distinct_on?: Maybe<Array<ImportLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ImportLogOrderBy>>;
  where?: Maybe<ImportLogBoolExp>;
};


export type SubscriptionRootImportLogByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootJuristicArgs = {
  distinct_on?: Maybe<Array<JuristicSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicOrderBy>>;
  where?: Maybe<JuristicBoolExp>;
};


export type SubscriptionRootJuristicAddressArgs = {
  distinct_on?: Maybe<Array<JuristicAddressSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicAddressOrderBy>>;
  where?: Maybe<JuristicAddressBoolExp>;
};


export type SubscriptionRootJuristicAddressAggregateArgs = {
  distinct_on?: Maybe<Array<JuristicAddressSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicAddressOrderBy>>;
  where?: Maybe<JuristicAddressBoolExp>;
};


export type SubscriptionRootJuristicAddressByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootJuristicAggregateArgs = {
  distinct_on?: Maybe<Array<JuristicSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicOrderBy>>;
  where?: Maybe<JuristicBoolExp>;
};


export type SubscriptionRootJuristicByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootJuristicCommitteeArgs = {
  distinct_on?: Maybe<Array<JuristicCommitteeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicCommitteeOrderBy>>;
  where?: Maybe<JuristicCommitteeBoolExp>;
};


export type SubscriptionRootJuristicCommitteeAggregateArgs = {
  distinct_on?: Maybe<Array<JuristicCommitteeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicCommitteeOrderBy>>;
  where?: Maybe<JuristicCommitteeBoolExp>;
};


export type SubscriptionRootJuristicCommitteeByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootJuristicStandardObjectiveArgs = {
  distinct_on?: Maybe<Array<JuristicStandardObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicStandardObjectiveOrderBy>>;
  where?: Maybe<JuristicStandardObjectiveBoolExp>;
};


export type SubscriptionRootJuristicStandardObjectiveAggregateArgs = {
  distinct_on?: Maybe<Array<JuristicStandardObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<JuristicStandardObjectiveOrderBy>>;
  where?: Maybe<JuristicStandardObjectiveBoolExp>;
};


export type SubscriptionRootJuristicStandardObjectiveByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLedgerAccountBudgetBalanceArgs = {
  distinct_on?: Maybe<Array<LedgerAccountBudgetBalanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerAccountBudgetBalanceOrderBy>>;
  where?: Maybe<LedgerAccountBudgetBalanceBoolExp>;
};


export type SubscriptionRootLedgerAccountBudgetBalanceAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerAccountBudgetBalanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerAccountBudgetBalanceOrderBy>>;
  where?: Maybe<LedgerAccountBudgetBalanceBoolExp>;
};


export type SubscriptionRootLedgerAccountBudgetBalanceDebtArgs = {
  distinct_on?: Maybe<Array<LedgerAccountBudgetBalanceDebtSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerAccountBudgetBalanceDebtOrderBy>>;
  where?: Maybe<LedgerAccountBudgetBalanceDebtBoolExp>;
};


export type SubscriptionRootLedgerAccountBudgetBalanceDebtAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerAccountBudgetBalanceDebtSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerAccountBudgetBalanceDebtOrderBy>>;
  where?: Maybe<LedgerAccountBudgetBalanceDebtBoolExp>;
};


export type SubscriptionRootLedgerAccountBudgetBalanceExpenseArgs = {
  distinct_on?: Maybe<Array<LedgerAccountBudgetBalanceExpenseSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerAccountBudgetBalanceExpenseOrderBy>>;
  where?: Maybe<LedgerAccountBudgetBalanceExpenseBoolExp>;
};


export type SubscriptionRootLedgerAccountBudgetBalanceExpenseAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerAccountBudgetBalanceExpenseSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerAccountBudgetBalanceExpenseOrderBy>>;
  where?: Maybe<LedgerAccountBudgetBalanceExpenseBoolExp>;
};


export type SubscriptionRootLedgerAccountBudgetMonthlyArgs = {
  distinct_on?: Maybe<Array<LedgerAccountBudgetMonthlySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerAccountBudgetMonthlyOrderBy>>;
  where?: Maybe<LedgerAccountBudgetMonthlyBoolExp>;
};


export type SubscriptionRootLedgerAccountBudgetMonthlyAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerAccountBudgetMonthlySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerAccountBudgetMonthlyOrderBy>>;
  where?: Maybe<LedgerAccountBudgetMonthlyBoolExp>;
};


export type SubscriptionRootLedgerBudgetAllocationArgs = {
  distinct_on?: Maybe<Array<LedgerBudgetAllocationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerBudgetAllocationOrderBy>>;
  where?: Maybe<LedgerBudgetAllocationBoolExp>;
};


export type SubscriptionRootLedgerBudgetAllocationAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerBudgetAllocationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerBudgetAllocationOrderBy>>;
  where?: Maybe<LedgerBudgetAllocationBoolExp>;
};


export type SubscriptionRootLedgerBudgetAllocationByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLedgerLedgerArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerOrderBy>>;
  where?: Maybe<LedgerLedgerBoolExp>;
};


export type SubscriptionRootLedgerLedgerAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerOrderBy>>;
  where?: Maybe<LedgerLedgerBoolExp>;
};


export type SubscriptionRootLedgerLedgerAttachmentArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerAttachmentOrderBy>>;
  where?: Maybe<LedgerLedgerAttachmentBoolExp>;
};


export type SubscriptionRootLedgerLedgerAttachmentAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerAttachmentOrderBy>>;
  where?: Maybe<LedgerLedgerAttachmentBoolExp>;
};


export type SubscriptionRootLedgerLedgerAttachmentByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLedgerLedgerByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLedgerLedgerLogArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerLogOrderBy>>;
  where?: Maybe<LedgerLedgerLogBoolExp>;
};


export type SubscriptionRootLedgerLedgerLogAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerLogOrderBy>>;
  where?: Maybe<LedgerLedgerLogBoolExp>;
};


export type SubscriptionRootLedgerLedgerLogByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLedgerLedgerStatusArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerStatusOrderBy>>;
  where?: Maybe<LedgerLedgerStatusBoolExp>;
};


export type SubscriptionRootLedgerLedgerStatusAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerStatusOrderBy>>;
  where?: Maybe<LedgerLedgerStatusBoolExp>;
};


export type SubscriptionRootLedgerLedgerStatusByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootLedgerLedgerTypeArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerTypeOrderBy>>;
  where?: Maybe<LedgerLedgerTypeBoolExp>;
};


export type SubscriptionRootLedgerLedgerTypeAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerTypeOrderBy>>;
  where?: Maybe<LedgerLedgerTypeBoolExp>;
};


export type SubscriptionRootLedgerLedgerTypeByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootLedgerReceivableArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableOrderBy>>;
  where?: Maybe<LedgerReceivableBoolExp>;
};


export type SubscriptionRootLedgerReceivableAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableOrderBy>>;
  where?: Maybe<LedgerReceivableBoolExp>;
};


export type SubscriptionRootLedgerReceivableByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLedgerReceivableItemArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableItemOrderBy>>;
  where?: Maybe<LedgerReceivableItemBoolExp>;
};


export type SubscriptionRootLedgerReceivableItemAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableItemOrderBy>>;
  where?: Maybe<LedgerReceivableItemBoolExp>;
};


export type SubscriptionRootLedgerReceivableItemByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLedgerReceivableLogArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableLogOrderBy>>;
  where?: Maybe<LedgerReceivableLogBoolExp>;
};


export type SubscriptionRootLedgerReceivableLogAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableLogOrderBy>>;
  where?: Maybe<LedgerReceivableLogBoolExp>;
};


export type SubscriptionRootLedgerReceivableLogByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLedgerReceivableStatusArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableStatusOrderBy>>;
  where?: Maybe<LedgerReceivableStatusBoolExp>;
};


export type SubscriptionRootLedgerReceivableStatusAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerReceivableStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerReceivableStatusOrderBy>>;
  where?: Maybe<LedgerReceivableStatusBoolExp>;
};


export type SubscriptionRootLedgerReceivableStatusByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootLedgerTotalReportMonthlyArgs = {
  distinct_on?: Maybe<Array<LedgerTotalReportMonthlySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerTotalReportMonthlyOrderBy>>;
  where?: Maybe<LedgerTotalReportMonthlyBoolExp>;
};


export type SubscriptionRootLedgerTotalReportMonthlyAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerTotalReportMonthlySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerTotalReportMonthlyOrderBy>>;
  where?: Maybe<LedgerTotalReportMonthlyBoolExp>;
};


export type SubscriptionRootLedgerTotalReportYearlyArgs = {
  distinct_on?: Maybe<Array<LedgerTotalReportYearlySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerTotalReportYearlyOrderBy>>;
  where?: Maybe<LedgerTotalReportYearlyBoolExp>;
};


export type SubscriptionRootLedgerTotalReportYearlyAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerTotalReportYearlySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerTotalReportYearlyOrderBy>>;
  where?: Maybe<LedgerTotalReportYearlyBoolExp>;
};


export type SubscriptionRootLoanAccountTypeArgs = {
  distinct_on?: Maybe<Array<LoanAccountTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanAccountTypeOrderBy>>;
  where?: Maybe<LoanAccountTypeBoolExp>;
};


export type SubscriptionRootLoanAccountTypeAggregateArgs = {
  distinct_on?: Maybe<Array<LoanAccountTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanAccountTypeOrderBy>>;
  where?: Maybe<LoanAccountTypeBoolExp>;
};


export type SubscriptionRootLoanAccountTypeByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootLoanApplicationAttachmentArgs = {
  distinct_on?: Maybe<Array<LoanApplicationAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationAttachmentOrderBy>>;
  where?: Maybe<LoanApplicationAttachmentBoolExp>;
};


export type SubscriptionRootLoanApplicationAttachmentAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationAttachmentOrderBy>>;
  where?: Maybe<LoanApplicationAttachmentBoolExp>;
};


export type SubscriptionRootLoanApplicationAttachmentByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanApplicationBankBranchTransferArgs = {
  distinct_on?: Maybe<Array<LoanApplicationBankBranchTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationBankBranchTransferOrderBy>>;
  where?: Maybe<LoanApplicationBankBranchTransferBoolExp>;
};


export type SubscriptionRootLoanApplicationBankBranchTransferAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationBankBranchTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationBankBranchTransferOrderBy>>;
  where?: Maybe<LoanApplicationBankBranchTransferBoolExp>;
};


export type SubscriptionRootLoanApplicationBankBranchTransferByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanApplicationBusinessOperationArgs = {
  distinct_on?: Maybe<Array<LoanApplicationBusinessOperationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationBusinessOperationOrderBy>>;
  where?: Maybe<LoanApplicationBusinessOperationBoolExp>;
};


export type SubscriptionRootLoanApplicationBusinessOperationAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationBusinessOperationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationBusinessOperationOrderBy>>;
  where?: Maybe<LoanApplicationBusinessOperationBoolExp>;
};


export type SubscriptionRootLoanApplicationBusinessOperationByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanApplicationCommitteeArgs = {
  distinct_on?: Maybe<Array<LoanApplicationCommitteeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationCommitteeOrderBy>>;
  where?: Maybe<LoanApplicationCommitteeBoolExp>;
};


export type SubscriptionRootLoanApplicationCommitteeAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationCommitteeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationCommitteeOrderBy>>;
  where?: Maybe<LoanApplicationCommitteeBoolExp>;
};


export type SubscriptionRootLoanApplicationCommitteeByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanApplicationCurrentLoanArgs = {
  distinct_on?: Maybe<Array<LoanApplicationCurrentLoanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationCurrentLoanOrderBy>>;
  where?: Maybe<LoanApplicationCurrentLoanBoolExp>;
};


export type SubscriptionRootLoanApplicationCurrentLoanAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationCurrentLoanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationCurrentLoanOrderBy>>;
  where?: Maybe<LoanApplicationCurrentLoanBoolExp>;
};


export type SubscriptionRootLoanApplicationCurrentLoanByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanApplicationDetailArgs = {
  distinct_on?: Maybe<Array<LoanApplicationDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationDetailOrderBy>>;
  where?: Maybe<LoanApplicationDetailBoolExp>;
};


export type SubscriptionRootLoanApplicationDetailAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationDetailSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationDetailOrderBy>>;
  where?: Maybe<LoanApplicationDetailBoolExp>;
};


export type SubscriptionRootLoanApplicationDetailByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanApplicationEventLogArgs = {
  distinct_on?: Maybe<Array<LoanApplicationEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationEventLogOrderBy>>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
};


export type SubscriptionRootLoanApplicationEventLogAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationEventLogOrderBy>>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
};


export type SubscriptionRootLoanApplicationEventLogByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanApplicationHeaderArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};


export type SubscriptionRootLoanApplicationHeaderAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderOrderBy>>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
};


export type SubscriptionRootLoanApplicationHeaderByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanApplicationHeaderPdfArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderPdfSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderPdfOrderBy>>;
  where?: Maybe<LoanApplicationHeaderPdfBoolExp>;
};


export type SubscriptionRootLoanApplicationHeaderPdfAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationHeaderPdfSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationHeaderPdfOrderBy>>;
  where?: Maybe<LoanApplicationHeaderPdfBoolExp>;
};


export type SubscriptionRootLoanApplicationHeaderPdfByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanApplicationObjectiveArgs = {
  distinct_on?: Maybe<Array<LoanApplicationObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationObjectiveOrderBy>>;
  where?: Maybe<LoanApplicationObjectiveBoolExp>;
};


export type SubscriptionRootLoanApplicationObjectiveAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationObjectiveOrderBy>>;
  where?: Maybe<LoanApplicationObjectiveBoolExp>;
};


export type SubscriptionRootLoanApplicationObjectiveByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanApplicationOrganizationTransferArgs = {
  distinct_on?: Maybe<Array<LoanApplicationOrganizationTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationOrganizationTransferOrderBy>>;
  where?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
};


export type SubscriptionRootLoanApplicationOrganizationTransferAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationOrganizationTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationOrganizationTransferOrderBy>>;
  where?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
};


export type SubscriptionRootLoanApplicationOrganizationTransferByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanApplicationProfileArgs = {
  distinct_on?: Maybe<Array<LoanApplicationProfileSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationProfileOrderBy>>;
  where?: Maybe<LoanApplicationProfileBoolExp>;
};


export type SubscriptionRootLoanApplicationProfileAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationProfileSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationProfileOrderBy>>;
  where?: Maybe<LoanApplicationProfileBoolExp>;
};


export type SubscriptionRootLoanApplicationProfileByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanApplicationProfileSCurveArgs = {
  distinct_on?: Maybe<Array<LoanApplicationProfileSCurveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationProfileSCurveOrderBy>>;
  where?: Maybe<LoanApplicationProfileSCurveBoolExp>;
};


export type SubscriptionRootLoanApplicationProfileSCurveAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationProfileSCurveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationProfileSCurveOrderBy>>;
  where?: Maybe<LoanApplicationProfileSCurveBoolExp>;
};


export type SubscriptionRootLoanApplicationProfileSCurveByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanApplicationSequenceNoArgs = {
  distinct_on?: Maybe<Array<LoanApplicationSequenceNoSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationSequenceNoOrderBy>>;
  where?: Maybe<LoanApplicationSequenceNoBoolExp>;
};


export type SubscriptionRootLoanApplicationSequenceNoAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationSequenceNoSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationSequenceNoOrderBy>>;
  where?: Maybe<LoanApplicationSequenceNoBoolExp>;
};


export type SubscriptionRootLoanApplicationSequenceNoByPkArgs = {
  organization_id: Scalars['uuid'];
  program_id: Scalars['uuid'];
  year: Scalars['Int'];
};


export type SubscriptionRootLoanApplicationStepArgs = {
  distinct_on?: Maybe<Array<LoanApplicationStepSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationStepOrderBy>>;
  where?: Maybe<LoanApplicationStepBoolExp>;
};


export type SubscriptionRootLoanApplicationStepAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationStepSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationStepOrderBy>>;
  where?: Maybe<LoanApplicationStepBoolExp>;
};


export type SubscriptionRootLoanApplicationStepByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootLoanAttachmentTypeArgs = {
  distinct_on?: Maybe<Array<LoanAttachmentTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanAttachmentTypeOrderBy>>;
  where?: Maybe<LoanAttachmentTypeBoolExp>;
};


export type SubscriptionRootLoanAttachmentTypeAggregateArgs = {
  distinct_on?: Maybe<Array<LoanAttachmentTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanAttachmentTypeOrderBy>>;
  where?: Maybe<LoanAttachmentTypeBoolExp>;
};


export type SubscriptionRootLoanAttachmentTypeByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootLoanBankApproveArgs = {
  distinct_on?: Maybe<Array<LoanBankApproveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBankApproveOrderBy>>;
  where?: Maybe<LoanBankApproveBoolExp>;
};


export type SubscriptionRootLoanBankApproveAggregateArgs = {
  distinct_on?: Maybe<Array<LoanBankApproveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBankApproveOrderBy>>;
  where?: Maybe<LoanBankApproveBoolExp>;
};


export type SubscriptionRootLoanBankApproveByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootLoanBusinessGroupArgs = {
  distinct_on?: Maybe<Array<LoanBusinessGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessGroupOrderBy>>;
  where?: Maybe<LoanBusinessGroupBoolExp>;
};


export type SubscriptionRootLoanBusinessGroupAggregateArgs = {
  distinct_on?: Maybe<Array<LoanBusinessGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessGroupOrderBy>>;
  where?: Maybe<LoanBusinessGroupBoolExp>;
};


export type SubscriptionRootLoanBusinessGroupByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanBusinessOperationArgs = {
  distinct_on?: Maybe<Array<LoanBusinessOperationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessOperationOrderBy>>;
  where?: Maybe<LoanBusinessOperationBoolExp>;
};


export type SubscriptionRootLoanBusinessOperationAggregateArgs = {
  distinct_on?: Maybe<Array<LoanBusinessOperationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessOperationOrderBy>>;
  where?: Maybe<LoanBusinessOperationBoolExp>;
};


export type SubscriptionRootLoanBusinessOperationByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootLoanBusinessProprietaryArgs = {
  distinct_on?: Maybe<Array<LoanBusinessProprietarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessProprietaryOrderBy>>;
  where?: Maybe<LoanBusinessProprietaryBoolExp>;
};


export type SubscriptionRootLoanBusinessProprietaryAggregateArgs = {
  distinct_on?: Maybe<Array<LoanBusinessProprietarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessProprietaryOrderBy>>;
  where?: Maybe<LoanBusinessProprietaryBoolExp>;
};


export type SubscriptionRootLoanBusinessProprietaryByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanBusinessStatusArgs = {
  distinct_on?: Maybe<Array<LoanBusinessStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessStatusOrderBy>>;
  where?: Maybe<LoanBusinessStatusBoolExp>;
};


export type SubscriptionRootLoanBusinessStatusAggregateArgs = {
  distinct_on?: Maybe<Array<LoanBusinessStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessStatusOrderBy>>;
  where?: Maybe<LoanBusinessStatusBoolExp>;
};


export type SubscriptionRootLoanBusinessStatusByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanBusinessTypeArgs = {
  distinct_on?: Maybe<Array<LoanBusinessTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessTypeOrderBy>>;
  where?: Maybe<LoanBusinessTypeBoolExp>;
};


export type SubscriptionRootLoanBusinessTypeAggregateArgs = {
  distinct_on?: Maybe<Array<LoanBusinessTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanBusinessTypeOrderBy>>;
  where?: Maybe<LoanBusinessTypeBoolExp>;
};


export type SubscriptionRootLoanBusinessTypeByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootLoanCommitteeApproveArgs = {
  distinct_on?: Maybe<Array<LoanCommitteeApproveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanCommitteeApproveOrderBy>>;
  where?: Maybe<LoanCommitteeApproveBoolExp>;
};


export type SubscriptionRootLoanCommitteeApproveAggregateArgs = {
  distinct_on?: Maybe<Array<LoanCommitteeApproveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanCommitteeApproveOrderBy>>;
  where?: Maybe<LoanCommitteeApproveBoolExp>;
};


export type SubscriptionRootLoanCommitteeApproveByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootLoanCurrentLoanArgs = {
  distinct_on?: Maybe<Array<LoanCurrentLoanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanCurrentLoanOrderBy>>;
  where?: Maybe<LoanCurrentLoanBoolExp>;
};


export type SubscriptionRootLoanCurrentLoanAggregateArgs = {
  distinct_on?: Maybe<Array<LoanCurrentLoanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanCurrentLoanOrderBy>>;
  where?: Maybe<LoanCurrentLoanBoolExp>;
};


export type SubscriptionRootLoanCurrentLoanByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootLoanDebtRestructuringArgs = {
  distinct_on?: Maybe<Array<LoanDebtRestructuringSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanDebtRestructuringOrderBy>>;
  where?: Maybe<LoanDebtRestructuringBoolExp>;
};


export type SubscriptionRootLoanDebtRestructuringAggregateArgs = {
  distinct_on?: Maybe<Array<LoanDebtRestructuringSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanDebtRestructuringOrderBy>>;
  where?: Maybe<LoanDebtRestructuringBoolExp>;
};


export type SubscriptionRootLoanDebtRestructuringByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootLoanDocumentApproveArgs = {
  distinct_on?: Maybe<Array<LoanDocumentApproveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanDocumentApproveOrderBy>>;
  where?: Maybe<LoanDocumentApproveBoolExp>;
};


export type SubscriptionRootLoanDocumentApproveAggregateArgs = {
  distinct_on?: Maybe<Array<LoanDocumentApproveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanDocumentApproveOrderBy>>;
  where?: Maybe<LoanDocumentApproveBoolExp>;
};


export type SubscriptionRootLoanDocumentApproveByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootLoanFinancialAgreementArgs = {
  distinct_on?: Maybe<Array<LoanFinancialAgreementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanFinancialAgreementOrderBy>>;
  where?: Maybe<LoanFinancialAgreementBoolExp>;
};


export type SubscriptionRootLoanFinancialAgreementAggregateArgs = {
  distinct_on?: Maybe<Array<LoanFinancialAgreementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanFinancialAgreementOrderBy>>;
  where?: Maybe<LoanFinancialAgreementBoolExp>;
};


export type SubscriptionRootLoanFinancialAgreementByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootLoanFinancialRescueArgs = {
  distinct_on?: Maybe<Array<LoanFinancialRescueSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanFinancialRescueOrderBy>>;
  where?: Maybe<LoanFinancialRescueBoolExp>;
};


export type SubscriptionRootLoanFinancialRescueAggregateArgs = {
  distinct_on?: Maybe<Array<LoanFinancialRescueSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanFinancialRescueOrderBy>>;
  where?: Maybe<LoanFinancialRescueBoolExp>;
};


export type SubscriptionRootLoanFinancialRescueByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootLoanObjectiveArgs = {
  distinct_on?: Maybe<Array<LoanObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanObjectiveOrderBy>>;
  where?: Maybe<LoanObjectiveBoolExp>;
};


export type SubscriptionRootLoanObjectiveAggregateArgs = {
  distinct_on?: Maybe<Array<LoanObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanObjectiveOrderBy>>;
  where?: Maybe<LoanObjectiveBoolExp>;
};


export type SubscriptionRootLoanObjectiveByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootLoanProgramArgs = {
  distinct_on?: Maybe<Array<LoanProgramSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramOrderBy>>;
  where?: Maybe<LoanProgramBoolExp>;
};


export type SubscriptionRootLoanProgramAggregateArgs = {
  distinct_on?: Maybe<Array<LoanProgramSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramOrderBy>>;
  where?: Maybe<LoanProgramBoolExp>;
};


export type SubscriptionRootLoanProgramByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanProgramStatusArgs = {
  distinct_on?: Maybe<Array<LoanProgramStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramStatusOrderBy>>;
  where?: Maybe<LoanProgramStatusBoolExp>;
};


export type SubscriptionRootLoanProgramStatusAggregateArgs = {
  distinct_on?: Maybe<Array<LoanProgramStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramStatusOrderBy>>;
  where?: Maybe<LoanProgramStatusBoolExp>;
};


export type SubscriptionRootLoanProgramStatusByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootLoanProgramTransactionArgs = {
  distinct_on?: Maybe<Array<LoanProgramTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramTransactionOrderBy>>;
  where?: Maybe<LoanProgramTransactionBoolExp>;
};


export type SubscriptionRootLoanProgramTransactionAggregateArgs = {
  distinct_on?: Maybe<Array<LoanProgramTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramTransactionOrderBy>>;
  where?: Maybe<LoanProgramTransactionBoolExp>;
};


export type SubscriptionRootLoanProgramTransactionByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanProgramTransactionTypeArgs = {
  distinct_on?: Maybe<Array<LoanProgramTransactionTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramTransactionTypeOrderBy>>;
  where?: Maybe<LoanProgramTransactionTypeBoolExp>;
};


export type SubscriptionRootLoanProgramTransactionTypeAggregateArgs = {
  distinct_on?: Maybe<Array<LoanProgramTransactionTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProgramTransactionTypeOrderBy>>;
  where?: Maybe<LoanProgramTransactionTypeBoolExp>;
};


export type SubscriptionRootLoanProgramTransactionTypeByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanProprietaryArgs = {
  distinct_on?: Maybe<Array<LoanProprietarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProprietaryOrderBy>>;
  where?: Maybe<LoanProprietaryBoolExp>;
};


export type SubscriptionRootLoanProprietaryAggregateArgs = {
  distinct_on?: Maybe<Array<LoanProprietarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanProprietaryOrderBy>>;
  where?: Maybe<LoanProprietaryBoolExp>;
};


export type SubscriptionRootLoanProprietaryByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootLoanRepayTypeArgs = {
  distinct_on?: Maybe<Array<LoanRepayTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanRepayTypeOrderBy>>;
  where?: Maybe<LoanRepayTypeBoolExp>;
};


export type SubscriptionRootLoanRepayTypeAggregateArgs = {
  distinct_on?: Maybe<Array<LoanRepayTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanRepayTypeOrderBy>>;
  where?: Maybe<LoanRepayTypeBoolExp>;
};


export type SubscriptionRootLoanRepayTypeByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootLoanSCurveArgs = {
  distinct_on?: Maybe<Array<LoanSCurveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSCurveOrderBy>>;
  where?: Maybe<LoanSCurveBoolExp>;
};


export type SubscriptionRootLoanSCurveAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSCurveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSCurveOrderBy>>;
  where?: Maybe<LoanSCurveBoolExp>;
};


export type SubscriptionRootLoanSCurveByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootLoanSCurveTypeArgs = {
  distinct_on?: Maybe<Array<LoanSCurveTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSCurveTypeOrderBy>>;
  where?: Maybe<LoanSCurveTypeBoolExp>;
};


export type SubscriptionRootLoanSCurveTypeAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSCurveTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSCurveTypeOrderBy>>;
  where?: Maybe<LoanSCurveTypeBoolExp>;
};


export type SubscriptionRootLoanSCurveTypeByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanSmeDefinitionArgs = {
  distinct_on?: Maybe<Array<LoanSmeDefinitionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSmeDefinitionOrderBy>>;
  where?: Maybe<LoanSmeDefinitionBoolExp>;
};


export type SubscriptionRootLoanSmeDefinitionAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSmeDefinitionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSmeDefinitionOrderBy>>;
  where?: Maybe<LoanSmeDefinitionBoolExp>;
};


export type SubscriptionRootLoanSmeDefinitionByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanSourceBusinessTypeArgs = {
  distinct_on?: Maybe<Array<LoanSourceBusinessTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceBusinessTypeOrderBy>>;
  where?: Maybe<LoanSourceBusinessTypeBoolExp>;
};


export type SubscriptionRootLoanSourceBusinessTypeAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSourceBusinessTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceBusinessTypeOrderBy>>;
  where?: Maybe<LoanSourceBusinessTypeBoolExp>;
};


export type SubscriptionRootLoanSourceBusinessTypeByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootLoanSourceFinancialInstitutionArgs = {
  distinct_on?: Maybe<Array<LoanSourceFinancialInstitutionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceFinancialInstitutionOrderBy>>;
  where?: Maybe<LoanSourceFinancialInstitutionBoolExp>;
};


export type SubscriptionRootLoanSourceFinancialInstitutionAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSourceFinancialInstitutionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceFinancialInstitutionOrderBy>>;
  where?: Maybe<LoanSourceFinancialInstitutionBoolExp>;
};


export type SubscriptionRootLoanSourceFinancialInstitutionByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootLoanSourceLoanArgs = {
  distinct_on?: Maybe<Array<LoanSourceLoanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceLoanOrderBy>>;
  where?: Maybe<LoanSourceLoanBoolExp>;
};


export type SubscriptionRootLoanSourceLoanAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSourceLoanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceLoanOrderBy>>;
  where?: Maybe<LoanSourceLoanBoolExp>;
};


export type SubscriptionRootLoanSourceLoanAttachmentArgs = {
  distinct_on?: Maybe<Array<LoanSourceLoanAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceLoanAttachmentOrderBy>>;
  where?: Maybe<LoanSourceLoanAttachmentBoolExp>;
};


export type SubscriptionRootLoanSourceLoanAttachmentAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSourceLoanAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceLoanAttachmentOrderBy>>;
  where?: Maybe<LoanSourceLoanAttachmentBoolExp>;
};


export type SubscriptionRootLoanSourceLoanAttachmentByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanSourceLoanBusinessTypeArgs = {
  distinct_on?: Maybe<Array<LoanSourceLoanBusinessTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceLoanBusinessTypeOrderBy>>;
  where?: Maybe<LoanSourceLoanBusinessTypeBoolExp>;
};


export type SubscriptionRootLoanSourceLoanBusinessTypeAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSourceLoanBusinessTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceLoanBusinessTypeOrderBy>>;
  where?: Maybe<LoanSourceLoanBusinessTypeBoolExp>;
};


export type SubscriptionRootLoanSourceLoanBusinessTypeByPkArgs = {
  business_type_id: Scalars['String'];
  loan_id: Scalars['uuid'];
};


export type SubscriptionRootLoanSourceLoanByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanSourceObjectiveArgs = {
  distinct_on?: Maybe<Array<LoanSourceObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceObjectiveOrderBy>>;
  where?: Maybe<LoanSourceObjectiveBoolExp>;
};


export type SubscriptionRootLoanSourceObjectiveAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSourceObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceObjectiveOrderBy>>;
  where?: Maybe<LoanSourceObjectiveBoolExp>;
};


export type SubscriptionRootLoanSourceObjectiveByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanSourceSearchLogArgs = {
  distinct_on?: Maybe<Array<LoanSourceSearchLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceSearchLogOrderBy>>;
  where?: Maybe<LoanSourceSearchLogBoolExp>;
};


export type SubscriptionRootLoanSourceSearchLogAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSourceSearchLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceSearchLogOrderBy>>;
  where?: Maybe<LoanSourceSearchLogBoolExp>;
};


export type SubscriptionRootLoanSourceSearchLogByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLoanTransferStatusArgs = {
  distinct_on?: Maybe<Array<LoanTransferStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanTransferStatusOrderBy>>;
  where?: Maybe<LoanTransferStatusBoolExp>;
};


export type SubscriptionRootLoanTransferStatusAggregateArgs = {
  distinct_on?: Maybe<Array<LoanTransferStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanTransferStatusOrderBy>>;
  where?: Maybe<LoanTransferStatusBoolExp>;
};


export type SubscriptionRootLoanTransferStatusByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootMeArgs = {
  distinct_on?: Maybe<Array<MeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MeOrderBy>>;
  where?: Maybe<MeBoolExp>;
};


export type SubscriptionRootMeAggregateArgs = {
  distinct_on?: Maybe<Array<MeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MeOrderBy>>;
  where?: Maybe<MeBoolExp>;
};


export type SubscriptionRootMonthOfFiscalYearArgs = {
  distinct_on?: Maybe<Array<MonthOfFiscalYearSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MonthOfFiscalYearOrderBy>>;
  where?: Maybe<MonthOfFiscalYearBoolExp>;
};


export type SubscriptionRootMonthOfFiscalYearAggregateArgs = {
  distinct_on?: Maybe<Array<MonthOfFiscalYearSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MonthOfFiscalYearOrderBy>>;
  where?: Maybe<MonthOfFiscalYearBoolExp>;
};


export type SubscriptionRootNameTitleArgs = {
  distinct_on?: Maybe<Array<NameTitleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NameTitleOrderBy>>;
  where?: Maybe<NameTitleBoolExp>;
};


export type SubscriptionRootNameTitleAggregateArgs = {
  distinct_on?: Maybe<Array<NameTitleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NameTitleOrderBy>>;
  where?: Maybe<NameTitleBoolExp>;
};


export type SubscriptionRootNameTitleByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootNotificationArgs = {
  distinct_on?: Maybe<Array<NotificationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NotificationOrderBy>>;
  where?: Maybe<NotificationBoolExp>;
};


export type SubscriptionRootNotificationAggregateArgs = {
  distinct_on?: Maybe<Array<NotificationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NotificationOrderBy>>;
  where?: Maybe<NotificationBoolExp>;
};


export type SubscriptionRootNotificationByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootNotificationStatusArgs = {
  distinct_on?: Maybe<Array<NotificationStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NotificationStatusOrderBy>>;
  where?: Maybe<NotificationStatusBoolExp>;
};


export type SubscriptionRootNotificationStatusAggregateArgs = {
  distinct_on?: Maybe<Array<NotificationStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NotificationStatusOrderBy>>;
  where?: Maybe<NotificationStatusBoolExp>;
};


export type SubscriptionRootNotificationStatusByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootOrganizationArgs = {
  distinct_on?: Maybe<Array<OrganizationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrganizationOrderBy>>;
  where?: Maybe<OrganizationBoolExp>;
};


export type SubscriptionRootOrganizationAggregateArgs = {
  distinct_on?: Maybe<Array<OrganizationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrganizationOrderBy>>;
  where?: Maybe<OrganizationBoolExp>;
};


export type SubscriptionRootOrganizationByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootOrganizationOtherArgs = {
  distinct_on?: Maybe<Array<OrganizationOtherSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrganizationOtherOrderBy>>;
  where?: Maybe<OrganizationOtherBoolExp>;
};


export type SubscriptionRootOrganizationOtherAggregateArgs = {
  distinct_on?: Maybe<Array<OrganizationOtherSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrganizationOtherOrderBy>>;
  where?: Maybe<OrganizationOtherBoolExp>;
};


export type SubscriptionRootOrganizationProvinceArgs = {
  distinct_on?: Maybe<Array<OrganizationProvinceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrganizationProvinceOrderBy>>;
  where?: Maybe<OrganizationProvinceBoolExp>;
};


export type SubscriptionRootOrganizationProvinceAggregateArgs = {
  distinct_on?: Maybe<Array<OrganizationProvinceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrganizationProvinceOrderBy>>;
  where?: Maybe<OrganizationProvinceBoolExp>;
};


export type SubscriptionRootOrganizationTypeArgs = {
  distinct_on?: Maybe<Array<OrganizationTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrganizationTypeOrderBy>>;
  where?: Maybe<OrganizationTypeBoolExp>;
};


export type SubscriptionRootOrganizationTypeAggregateArgs = {
  distinct_on?: Maybe<Array<OrganizationTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrganizationTypeOrderBy>>;
  where?: Maybe<OrganizationTypeBoolExp>;
};


export type SubscriptionRootOrganizationTypeByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootPermissionArgs = {
  distinct_on?: Maybe<Array<PermissionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PermissionOrderBy>>;
  where?: Maybe<PermissionBoolExp>;
};


export type SubscriptionRootPermissionAggregateArgs = {
  distinct_on?: Maybe<Array<PermissionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PermissionOrderBy>>;
  where?: Maybe<PermissionBoolExp>;
};


export type SubscriptionRootPermissionByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootPostsArgs = {
  distinct_on?: Maybe<Array<PostsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PostsOrderBy>>;
  where?: Maybe<PostsBoolExp>;
};


export type SubscriptionRootPostsAggregateArgs = {
  distinct_on?: Maybe<Array<PostsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PostsOrderBy>>;
  where?: Maybe<PostsBoolExp>;
};


export type SubscriptionRootPostsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootProjectApprovedArgs = {
  distinct_on?: Maybe<Array<ProjectApprovedSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectApprovedOrderBy>>;
  where?: Maybe<ProjectApprovedBoolExp>;
};


export type SubscriptionRootProjectApprovedAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectApprovedSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectApprovedOrderBy>>;
  where?: Maybe<ProjectApprovedBoolExp>;
};


export type SubscriptionRootProjectApprovedByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootProjectAttachmentTypeArgs = {
  distinct_on?: Maybe<Array<ProjectAttachmentTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectAttachmentTypeOrderBy>>;
  where?: Maybe<ProjectAttachmentTypeBoolExp>;
};


export type SubscriptionRootProjectAttachmentTypeAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectAttachmentTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectAttachmentTypeOrderBy>>;
  where?: Maybe<ProjectAttachmentTypeBoolExp>;
};


export type SubscriptionRootProjectAttachmentTypeByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootProjectBudgetArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetOrderBy>>;
  where?: Maybe<ProjectBudgetBoolExp>;
};


export type SubscriptionRootProjectBudgetAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetOrderBy>>;
  where?: Maybe<ProjectBudgetBoolExp>;
};


export type SubscriptionRootProjectBudgetByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootProjectBudgetGroupArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetGroupOrderBy>>;
  where?: Maybe<ProjectBudgetGroupBoolExp>;
};


export type SubscriptionRootProjectBudgetGroupAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetGroupOrderBy>>;
  where?: Maybe<ProjectBudgetGroupBoolExp>;
};


export type SubscriptionRootProjectBudgetGroupByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootProjectBudgetGroupMappingArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetGroupMappingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetGroupMappingOrderBy>>;
  where?: Maybe<ProjectBudgetGroupMappingBoolExp>;
};


export type SubscriptionRootProjectBudgetGroupMappingAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetGroupMappingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetGroupMappingOrderBy>>;
  where?: Maybe<ProjectBudgetGroupMappingBoolExp>;
};


export type SubscriptionRootProjectBudgetGroupMappingByPkArgs = {
  budget_group_id: ProjectBudgetGroupEnum;
  budget_id: Scalars['uuid'];
};


export type SubscriptionRootProjectBudgetPlanArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetPlanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetPlanOrderBy>>;
  where?: Maybe<ProjectBudgetPlanBoolExp>;
};


export type SubscriptionRootProjectBudgetPlanAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectBudgetPlanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectBudgetPlanOrderBy>>;
  where?: Maybe<ProjectBudgetPlanBoolExp>;
};


export type SubscriptionRootProjectIndicatorTypeArgs = {
  distinct_on?: Maybe<Array<ProjectIndicatorTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectIndicatorTypeOrderBy>>;
  where?: Maybe<ProjectIndicatorTypeBoolExp>;
};


export type SubscriptionRootProjectIndicatorTypeAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectIndicatorTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectIndicatorTypeOrderBy>>;
  where?: Maybe<ProjectIndicatorTypeBoolExp>;
};


export type SubscriptionRootProjectIndicatorTypeByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootProjectMasterPlanArgs = {
  distinct_on?: Maybe<Array<ProjectMasterPlanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectMasterPlanOrderBy>>;
  where?: Maybe<ProjectMasterPlanBoolExp>;
};


export type SubscriptionRootProjectMasterPlanAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectMasterPlanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectMasterPlanOrderBy>>;
  where?: Maybe<ProjectMasterPlanBoolExp>;
};


export type SubscriptionRootProjectNationalStrategyArgs = {
  distinct_on?: Maybe<Array<ProjectNationalStrategySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectNationalStrategyOrderBy>>;
  where?: Maybe<ProjectNationalStrategyBoolExp>;
};


export type SubscriptionRootProjectNationalStrategyAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectNationalStrategySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectNationalStrategyOrderBy>>;
  where?: Maybe<ProjectNationalStrategyBoolExp>;
};


export type SubscriptionRootProjectNationalStrategyByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootProjectOperatingActivityProgressArgs = {
  distinct_on?: Maybe<Array<ProjectOperatingActivityProgressSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectOperatingActivityProgressOrderBy>>;
  where?: Maybe<ProjectOperatingActivityProgressBoolExp>;
};


export type SubscriptionRootProjectOperatingActivityProgressAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectOperatingActivityProgressSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectOperatingActivityProgressOrderBy>>;
  where?: Maybe<ProjectOperatingActivityProgressBoolExp>;
};


export type SubscriptionRootProjectOperatingActivityProgressByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootProjectOperatingActivityProgressRevisionArgs = {
  distinct_on?: Maybe<Array<ProjectOperatingActivityProgressRevisionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectOperatingActivityProgressRevisionOrderBy>>;
  where?: Maybe<ProjectOperatingActivityProgressRevisionBoolExp>;
};


export type SubscriptionRootProjectOperatingActivityProgressRevisionAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectOperatingActivityProgressRevisionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectOperatingActivityProgressRevisionOrderBy>>;
  where?: Maybe<ProjectOperatingActivityProgressRevisionBoolExp>;
};


export type SubscriptionRootProjectOperatingActivityProgressRevisionByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootProjectOperatingActivityTypeArgs = {
  distinct_on?: Maybe<Array<ProjectOperatingActivityTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectOperatingActivityTypeOrderBy>>;
  where?: Maybe<ProjectOperatingActivityTypeBoolExp>;
};


export type SubscriptionRootProjectOperatingActivityTypeAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectOperatingActivityTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectOperatingActivityTypeOrderBy>>;
  where?: Maybe<ProjectOperatingActivityTypeBoolExp>;
};


export type SubscriptionRootProjectOperatingActivityTypeByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootProjectProjectArgs = {
  distinct_on?: Maybe<Array<ProjectProjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOrderBy>>;
  where?: Maybe<ProjectProjectBoolExp>;
};


export type SubscriptionRootProjectProjectAchievementArgs = {
  distinct_on?: Maybe<Array<ProjectProjectAchievementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectAchievementOrderBy>>;
  where?: Maybe<ProjectProjectAchievementBoolExp>;
};


export type SubscriptionRootProjectProjectAchievementAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectAchievementSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectAchievementOrderBy>>;
  where?: Maybe<ProjectProjectAchievementBoolExp>;
};


export type SubscriptionRootProjectProjectAchievementByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootProjectProjectAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOrderBy>>;
  where?: Maybe<ProjectProjectBoolExp>;
};


export type SubscriptionRootProjectProjectAttachmentArgs = {
  distinct_on?: Maybe<Array<ProjectProjectAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectAttachmentOrderBy>>;
  where?: Maybe<ProjectProjectAttachmentBoolExp>;
};


export type SubscriptionRootProjectProjectAttachmentAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectAttachmentOrderBy>>;
  where?: Maybe<ProjectProjectAttachmentBoolExp>;
};


export type SubscriptionRootProjectProjectAttachmentByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootProjectProjectBudgetItemArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetItemOrderBy>>;
  where?: Maybe<ProjectProjectBudgetItemBoolExp>;
};


export type SubscriptionRootProjectProjectBudgetItemAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetItemSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetItemOrderBy>>;
  where?: Maybe<ProjectProjectBudgetItemBoolExp>;
};


export type SubscriptionRootProjectProjectBudgetItemByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootProjectProjectBudgetItemSummaryArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetItemSummarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetItemSummaryOrderBy>>;
  where?: Maybe<ProjectProjectBudgetItemSummaryBoolExp>;
};


export type SubscriptionRootProjectProjectBudgetItemSummaryAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetItemSummarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetItemSummaryOrderBy>>;
  where?: Maybe<ProjectProjectBudgetItemSummaryBoolExp>;
};


export type SubscriptionRootProjectProjectBudgetSummaryArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetSummarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetSummaryOrderBy>>;
  where?: Maybe<ProjectProjectBudgetSummaryBoolExp>;
};


export type SubscriptionRootProjectProjectBudgetSummaryAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetSummarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetSummaryOrderBy>>;
  where?: Maybe<ProjectProjectBudgetSummaryBoolExp>;
};


export type SubscriptionRootProjectProjectBudgetTemplateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetTemplateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetTemplateOrderBy>>;
  where?: Maybe<ProjectProjectBudgetTemplateBoolExp>;
};


export type SubscriptionRootProjectProjectBudgetTemplateAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectBudgetTemplateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectBudgetTemplateOrderBy>>;
  where?: Maybe<ProjectProjectBudgetTemplateBoolExp>;
};


export type SubscriptionRootProjectProjectByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootProjectProjectEventLogArgs = {
  distinct_on?: Maybe<Array<ProjectProjectEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectEventLogOrderBy>>;
  where?: Maybe<ProjectProjectEventLogBoolExp>;
};


export type SubscriptionRootProjectProjectEventLogAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectEventLogOrderBy>>;
  where?: Maybe<ProjectProjectEventLogBoolExp>;
};


export type SubscriptionRootProjectProjectEventLogByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootProjectProjectIndicatorArgs = {
  distinct_on?: Maybe<Array<ProjectProjectIndicatorSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectIndicatorOrderBy>>;
  where?: Maybe<ProjectProjectIndicatorBoolExp>;
};


export type SubscriptionRootProjectProjectIndicatorAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectIndicatorSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectIndicatorOrderBy>>;
  where?: Maybe<ProjectProjectIndicatorBoolExp>;
};


export type SubscriptionRootProjectProjectIndicatorByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootProjectProjectMemberArgs = {
  distinct_on?: Maybe<Array<ProjectProjectMemberSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectMemberOrderBy>>;
  where?: Maybe<ProjectProjectMemberBoolExp>;
};


export type SubscriptionRootProjectProjectMemberAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectMemberSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectMemberOrderBy>>;
  where?: Maybe<ProjectProjectMemberBoolExp>;
};


export type SubscriptionRootProjectProjectMemberByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootProjectProjectObjectiveArgs = {
  distinct_on?: Maybe<Array<ProjectProjectObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectObjectiveOrderBy>>;
  where?: Maybe<ProjectProjectObjectiveBoolExp>;
};


export type SubscriptionRootProjectProjectObjectiveAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectObjectiveSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectObjectiveOrderBy>>;
  where?: Maybe<ProjectProjectObjectiveBoolExp>;
};


export type SubscriptionRootProjectProjectObjectiveByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootProjectProjectOperatingActivityArgs = {
  distinct_on?: Maybe<Array<ProjectProjectOperatingActivitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOperatingActivityOrderBy>>;
  where?: Maybe<ProjectProjectOperatingActivityBoolExp>;
};


export type SubscriptionRootProjectProjectOperatingActivityAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectOperatingActivitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOperatingActivityOrderBy>>;
  where?: Maybe<ProjectProjectOperatingActivityBoolExp>;
};


export type SubscriptionRootProjectProjectOperatingActivityByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootProjectProjectOperatingAreaArgs = {
  distinct_on?: Maybe<Array<ProjectProjectOperatingAreaSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOperatingAreaOrderBy>>;
  where?: Maybe<ProjectProjectOperatingAreaBoolExp>;
};


export type SubscriptionRootProjectProjectOperatingAreaAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectOperatingAreaSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOperatingAreaOrderBy>>;
  where?: Maybe<ProjectProjectOperatingAreaBoolExp>;
};


export type SubscriptionRootProjectProjectOperatingAreaByPkArgs = {
  project_id: Scalars['uuid'];
  province_id: Scalars['uuid'];
};


export type SubscriptionRootProjectProjectStepArgs = {
  distinct_on?: Maybe<Array<ProjectProjectStepSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectStepOrderBy>>;
  where?: Maybe<ProjectProjectStepBoolExp>;
};


export type SubscriptionRootProjectProjectStepAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectStepSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectStepOrderBy>>;
  where?: Maybe<ProjectProjectStepBoolExp>;
};


export type SubscriptionRootProjectProjectStepByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootProjectProjectTargetGroupArgs = {
  distinct_on?: Maybe<Array<ProjectProjectTargetGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectTargetGroupOrderBy>>;
  where?: Maybe<ProjectProjectTargetGroupBoolExp>;
};


export type SubscriptionRootProjectProjectTargetGroupAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectTargetGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectTargetGroupOrderBy>>;
  where?: Maybe<ProjectProjectTargetGroupBoolExp>;
};


export type SubscriptionRootProjectProjectTargetGroupByPkArgs = {
  project_id: Scalars['uuid'];
  target_group_id: Scalars['String'];
};


export type SubscriptionRootProjectProjectWorkloadSummaryArgs = {
  distinct_on?: Maybe<Array<ProjectProjectWorkloadSummarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectWorkloadSummaryOrderBy>>;
  where?: Maybe<ProjectProjectWorkloadSummaryBoolExp>;
};


export type SubscriptionRootProjectProjectWorkloadSummaryAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectWorkloadSummarySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectWorkloadSummaryOrderBy>>;
  where?: Maybe<ProjectProjectWorkloadSummaryBoolExp>;
};


export type SubscriptionRootProjectSubPlanArgs = {
  distinct_on?: Maybe<Array<ProjectSubPlanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectSubPlanOrderBy>>;
  where?: Maybe<ProjectSubPlanBoolExp>;
};


export type SubscriptionRootProjectSubPlanAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectSubPlanSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectSubPlanOrderBy>>;
  where?: Maybe<ProjectSubPlanBoolExp>;
};


export type SubscriptionRootProjectTargetGroupArgs = {
  distinct_on?: Maybe<Array<ProjectTargetGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectTargetGroupOrderBy>>;
  where?: Maybe<ProjectTargetGroupBoolExp>;
};


export type SubscriptionRootProjectTargetGroupAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectTargetGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectTargetGroupOrderBy>>;
  where?: Maybe<ProjectTargetGroupBoolExp>;
};


export type SubscriptionRootProjectTargetGroupByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootProjectUnitArgs = {
  distinct_on?: Maybe<Array<ProjectUnitSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectUnitOrderBy>>;
  where?: Maybe<ProjectUnitBoolExp>;
};


export type SubscriptionRootProjectUnitAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectUnitSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectUnitOrderBy>>;
  where?: Maybe<ProjectUnitBoolExp>;
};


export type SubscriptionRootProjectUnitByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootProvinceArgs = {
  distinct_on?: Maybe<Array<ProvinceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProvinceOrderBy>>;
  where?: Maybe<ProvinceBoolExp>;
};


export type SubscriptionRootProvinceAggregateArgs = {
  distinct_on?: Maybe<Array<ProvinceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProvinceOrderBy>>;
  where?: Maybe<ProvinceBoolExp>;
};


export type SubscriptionRootProvinceByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootRegionArgs = {
  distinct_on?: Maybe<Array<RegionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RegionOrderBy>>;
  where?: Maybe<RegionBoolExp>;
};


export type SubscriptionRootRegionAggregateArgs = {
  distinct_on?: Maybe<Array<RegionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RegionOrderBy>>;
  where?: Maybe<RegionBoolExp>;
};


export type SubscriptionRootRegionByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootUserMappingArgs = {
  distinct_on?: Maybe<Array<UserMappingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserMappingOrderBy>>;
  where?: Maybe<UserMappingBoolExp>;
};


export type SubscriptionRootUserMappingAggregateArgs = {
  distinct_on?: Maybe<Array<UserMappingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserMappingOrderBy>>;
  where?: Maybe<UserMappingBoolExp>;
};


export type SubscriptionRootUserMappingByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootUsersArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};


export type SubscriptionRootUsersAggregateArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};


export type SubscriptionRootUsersByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootUsersPermissionArgs = {
  distinct_on?: Maybe<Array<UsersPermissionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersPermissionOrderBy>>;
  where?: Maybe<UsersPermissionBoolExp>;
};


export type SubscriptionRootUsersPermissionAggregateArgs = {
  distinct_on?: Maybe<Array<UsersPermissionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersPermissionOrderBy>>;
  where?: Maybe<UsersPermissionBoolExp>;
};


export type SubscriptionRootUsersPermissionByPkArgs = {
  permission_id: Scalars['String'];
  user_id: Scalars['uuid'];
};


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type TimestampComparisonExp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/**
 * user_mapping
 *
 *
 * columns and relationships of "user_mapping"
 */
export type UserMapping = {
  __typename?: 'user_mapping';
  /** อีเมล */
  email: Scalars['String'];
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  organization?: Maybe<Organization>;
  /** organization.id */
  organization_id?: Maybe<Scalars['uuid']>;
  /** สอจ. */
  organization_name?: Maybe<Scalars['String']>;
  /** ตำแหน่ง */
  position?: Maybe<Scalars['String']>;
  /** จังหวัด */
  province?: Maybe<Scalars['String']>;
  /** โทรศัพท์ */
  tel?: Maybe<Scalars['String']>;
  /** คำนำหน้า */
  title?: Maybe<Scalars['String']>;
};

/** aggregated selection of "user_mapping" */
export type UserMappingAggregate = {
  __typename?: 'user_mapping_aggregate';
  aggregate?: Maybe<UserMappingAggregateFields>;
  nodes: Array<UserMapping>;
};

/** aggregate fields of "user_mapping" */
export type UserMappingAggregateFields = {
  __typename?: 'user_mapping_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserMappingMaxFields>;
  min?: Maybe<UserMappingMinFields>;
};


/** aggregate fields of "user_mapping" */
export type UserMappingAggregateFieldsCountArgs = {
  columns?: Maybe<Array<UserMappingSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_mapping" */
export type UserMappingAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<UserMappingMaxOrderBy>;
  min?: Maybe<UserMappingMinOrderBy>;
};

/** input type for inserting array relation for remote table "user_mapping" */
export type UserMappingArrRelInsertInput = {
  data: Array<UserMappingInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<UserMappingOnConflict>;
};

/** Boolean expression to filter rows from the table "user_mapping". All fields are combined with a logical 'AND'. */
export type UserMappingBoolExp = {
  _and?: Maybe<Array<UserMappingBoolExp>>;
  _not?: Maybe<UserMappingBoolExp>;
  _or?: Maybe<Array<UserMappingBoolExp>>;
  email?: Maybe<StringComparisonExp>;
  first_name?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  last_name?: Maybe<StringComparisonExp>;
  organization?: Maybe<OrganizationBoolExp>;
  organization_id?: Maybe<UuidComparisonExp>;
  organization_name?: Maybe<StringComparisonExp>;
  position?: Maybe<StringComparisonExp>;
  province?: Maybe<StringComparisonExp>;
  tel?: Maybe<StringComparisonExp>;
  title?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "user_mapping" */
export enum UserMappingConstraint {
  /** unique or primary key constraint */
  UserMappingEmailKey = 'user_mapping_email_key',
  /** unique or primary key constraint */
  UserMappingPkey = 'user_mapping_pkey'
}

/** input type for inserting data into table "user_mapping" */
export type UserMappingInsertInput = {
  /** อีเมล */
  email?: Maybe<Scalars['String']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationObjRelInsertInput>;
  /** organization.id */
  organization_id?: Maybe<Scalars['uuid']>;
  /** สอจ. */
  organization_name?: Maybe<Scalars['String']>;
  /** ตำแหน่ง */
  position?: Maybe<Scalars['String']>;
  /** จังหวัด */
  province?: Maybe<Scalars['String']>;
  /** โทรศัพท์ */
  tel?: Maybe<Scalars['String']>;
  /** คำนำหน้า */
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UserMappingMaxFields = {
  __typename?: 'user_mapping_max_fields';
  /** อีเมล */
  email?: Maybe<Scalars['String']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** organization.id */
  organization_id?: Maybe<Scalars['uuid']>;
  /** สอจ. */
  organization_name?: Maybe<Scalars['String']>;
  /** ตำแหน่ง */
  position?: Maybe<Scalars['String']>;
  /** จังหวัด */
  province?: Maybe<Scalars['String']>;
  /** โทรศัพท์ */
  tel?: Maybe<Scalars['String']>;
  /** คำนำหน้า */
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_mapping" */
export type UserMappingMaxOrderBy = {
  /** อีเมล */
  email?: Maybe<OrderBy>;
  /** ชื่อ */
  first_name?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** นามสกุล */
  last_name?: Maybe<OrderBy>;
  /** organization.id */
  organization_id?: Maybe<OrderBy>;
  /** สอจ. */
  organization_name?: Maybe<OrderBy>;
  /** ตำแหน่ง */
  position?: Maybe<OrderBy>;
  /** จังหวัด */
  province?: Maybe<OrderBy>;
  /** โทรศัพท์ */
  tel?: Maybe<OrderBy>;
  /** คำนำหน้า */
  title?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type UserMappingMinFields = {
  __typename?: 'user_mapping_min_fields';
  /** อีเมล */
  email?: Maybe<Scalars['String']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** organization.id */
  organization_id?: Maybe<Scalars['uuid']>;
  /** สอจ. */
  organization_name?: Maybe<Scalars['String']>;
  /** ตำแหน่ง */
  position?: Maybe<Scalars['String']>;
  /** จังหวัด */
  province?: Maybe<Scalars['String']>;
  /** โทรศัพท์ */
  tel?: Maybe<Scalars['String']>;
  /** คำนำหน้า */
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_mapping" */
export type UserMappingMinOrderBy = {
  /** อีเมล */
  email?: Maybe<OrderBy>;
  /** ชื่อ */
  first_name?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** นามสกุล */
  last_name?: Maybe<OrderBy>;
  /** organization.id */
  organization_id?: Maybe<OrderBy>;
  /** สอจ. */
  organization_name?: Maybe<OrderBy>;
  /** ตำแหน่ง */
  position?: Maybe<OrderBy>;
  /** จังหวัด */
  province?: Maybe<OrderBy>;
  /** โทรศัพท์ */
  tel?: Maybe<OrderBy>;
  /** คำนำหน้า */
  title?: Maybe<OrderBy>;
};

/** response of any mutation on the table "user_mapping" */
export type UserMappingMutationResponse = {
  __typename?: 'user_mapping_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserMapping>;
};

/** on conflict condition type for table "user_mapping" */
export type UserMappingOnConflict = {
  constraint: UserMappingConstraint;
  update_columns?: Array<UserMappingUpdateColumn>;
  where?: Maybe<UserMappingBoolExp>;
};

/** Ordering options when selecting data from "user_mapping". */
export type UserMappingOrderBy = {
  email?: Maybe<OrderBy>;
  first_name?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  last_name?: Maybe<OrderBy>;
  organization?: Maybe<OrganizationOrderBy>;
  organization_id?: Maybe<OrderBy>;
  organization_name?: Maybe<OrderBy>;
  position?: Maybe<OrderBy>;
  province?: Maybe<OrderBy>;
  tel?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
};

/** primary key columns input for table: user_mapping */
export type UserMappingPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "user_mapping" */
export enum UserMappingSelectColumn {
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OrganizationName = 'organization_name',
  /** column name */
  Position = 'position',
  /** column name */
  Province = 'province',
  /** column name */
  Tel = 'tel',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "user_mapping" */
export type UserMappingSetInput = {
  /** อีเมล */
  email?: Maybe<Scalars['String']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** organization.id */
  organization_id?: Maybe<Scalars['uuid']>;
  /** สอจ. */
  organization_name?: Maybe<Scalars['String']>;
  /** ตำแหน่ง */
  position?: Maybe<Scalars['String']>;
  /** จังหวัด */
  province?: Maybe<Scalars['String']>;
  /** โทรศัพท์ */
  tel?: Maybe<Scalars['String']>;
  /** คำนำหน้า */
  title?: Maybe<Scalars['String']>;
};

/** update columns of table "user_mapping" */
export enum UserMappingUpdateColumn {
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OrganizationName = 'organization_name',
  /** column name */
  Position = 'position',
  /** column name */
  Province = 'province',
  /** column name */
  Tel = 'tel',
  /** column name */
  Title = 'title'
}

/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type Users = {
  __typename?: 'users';
  /** An object relationship */
  account?: Maybe<AuthAccounts>;
  /** An array relationship */
  agreement_receipts_by_created_by: Array<ContractAgreementReceipt>;
  /** An aggregate relationship */
  agreement_receipts_by_created_by_aggregate: ContractAgreementReceiptAggregate;
  /** An array relationship */
  agreement_receipts_by_updated_by: Array<ContractAgreementReceipt>;
  /** An aggregate relationship */
  agreement_receipts_by_updated_by_aggregate: ContractAgreementReceiptAggregate;
  /** An array relationship */
  agreement_transactions: Array<ContractAgreementTransaction>;
  /** An aggregate relationship */
  agreement_transactions_aggregate: ContractAgreementTransactionAggregate;
  /** An array relationship */
  applicationBankBranchTransfersByCreatedBy: Array<LoanApplicationBankBranchTransfer>;
  /** An aggregate relationship */
  applicationBankBranchTransfersByCreatedBy_aggregate: LoanApplicationBankBranchTransferAggregate;
  /** An array relationship */
  applicationBankBranchTransfersByUpdatedBy: Array<LoanApplicationBankBranchTransfer>;
  /** An aggregate relationship */
  applicationBankBranchTransfersByUpdatedBy_aggregate: LoanApplicationBankBranchTransferAggregate;
  /** An array relationship */
  applicationEventLogsByActorUserId: Array<LoanApplicationEventLog>;
  /** An aggregate relationship */
  applicationEventLogsByActorUserId_aggregate: LoanApplicationEventLogAggregate;
  /** An array relationship */
  applicationOrganizationTransfersByUpdatedBy: Array<LoanApplicationOrganizationTransfer>;
  /** An aggregate relationship */
  applicationOrganizationTransfersByUpdatedBy_aggregate: LoanApplicationOrganizationTransferAggregate;
  /** An array relationship */
  application_event_logs: Array<LoanApplicationEventLog>;
  /** An aggregate relationship */
  application_event_logs_aggregate: LoanApplicationEventLogAggregate;
  /** An array relationship */
  application_organization_transfers: Array<LoanApplicationOrganizationTransfer>;
  /** An aggregate relationship */
  application_organization_transfers_aggregate: LoanApplicationOrganizationTransferAggregate;
  /** An array relationship */
  application_transfers: Array<LoanApplicationOrganizationTransfer>;
  /** An aggregate relationship */
  application_transfers_aggregate: LoanApplicationOrganizationTransferAggregate;
  /** An array relationship */
  approved_ledgers: Array<LedgerLedger>;
  /** An aggregate relationship */
  approved_ledgers_aggregate: LedgerLedgerAggregate;
  /** ที่อยู่รูปภาพ */
  avatar_url?: Maybe<Scalars['String']>;
  /** An object relationship */
  bank_branch?: Maybe<BankBranch>;
  /** ธนาคาร */
  bank_branch_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  change_request_ledgers: Array<LedgerLedger>;
  /** An aggregate relationship */
  change_request_ledgers_aggregate: LedgerLedgerAggregate;
  /** consent_checked */
  consent_checked: Scalars['Boolean'];
  /** วันที่บันทึกข้อมูล */
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  department?: Maybe<Department>;
  /** หน่วยงาน */
  department_id?: Maybe<Scalars['uuid']>;
  /** ชื่อแสดง */
  display_name?: Maybe<Scalars['String']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id: Scalars['uuid'];
  /** An array relationship */
  import_logs: Array<ImportLog>;
  /** An aggregate relationship */
  import_logs_aggregate: ImportLogAggregate;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  lendings: Array<AccountLending>;
  /** An array relationship */
  lendingsByUpdatedBy: Array<AccountLending>;
  /** An aggregate relationship */
  lendingsByUpdatedBy_aggregate: AccountLendingAggregate;
  /** An aggregate relationship */
  lendings_aggregate: AccountLendingAggregate;
  /** An array relationship */
  locked_ledgers: Array<LedgerLedger>;
  /** An aggregate relationship */
  locked_ledgers_aggregate: LedgerLedgerAggregate;
  /** An object relationship */
  name_title?: Maybe<NameTitle>;
  /** Noted */
  note?: Maybe<Scalars['String']>;
  /** An array relationship */
  notificationsBySenderId: Array<Notification>;
  /** An aggregate relationship */
  notificationsBySenderId_aggregate: NotificationAggregate;
  /** An array relationship */
  notificationsByUserId: Array<Notification>;
  /** An aggregate relationship */
  notificationsByUserId_aggregate: NotificationAggregate;
  /** An object relationship */
  organization?: Maybe<Organization>;
  /** หน่วยงาน */
  organization_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  projectAttachmentsByUpdatedBy: Array<ProjectProjectAttachment>;
  /** An aggregate relationship */
  projectAttachmentsByUpdatedBy_aggregate: ProjectProjectAttachmentAggregate;
  /** An array relationship */
  project_attachments: Array<ProjectProjectAttachment>;
  /** An aggregate relationship */
  project_attachments_aggregate: ProjectProjectAttachmentAggregate;
  /** An array relationship */
  project_event_logs: Array<ProjectProjectEventLog>;
  /** An aggregate relationship */
  project_event_logs_aggregate: ProjectProjectEventLogAggregate;
  /** An array relationship */
  projects: Array<ProjectProject>;
  /** An array relationship */
  projectsByUpdatedBy: Array<ProjectProject>;
  /** An aggregate relationship */
  projectsByUpdatedBy_aggregate: ProjectProjectAggregate;
  /** An aggregate relationship */
  projects_aggregate: ProjectProjectAggregate;
  /** อ้างอิง */
  reference?: Maybe<Scalars['String']>;
  /** An array relationship */
  search_logs: Array<LoanSourceSearchLog>;
  /** An aggregate relationship */
  search_logs_aggregate: LoanSourceSearchLogAggregate;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  submitted_ledgers: Array<LedgerLedger>;
  /** An aggregate relationship */
  submitted_ledgers_aggregate: LedgerLedgerAggregate;
  /** เบอร์โทรศัพท์ */
  tel?: Maybe<Scalars['String']>;
  /** คำนำหน้า */
  title_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  transaction_logs: Array<AccountTransactionLog>;
  /** An aggregate relationship */
  transaction_logs_aggregate: AccountTransactionLogAggregate;
  /** An array relationship */
  transactions: Array<AccountTransaction>;
  /** An array relationship */
  transactionsByApprovedBy: Array<AccountTransaction>;
  /** An aggregate relationship */
  transactionsByApprovedBy_aggregate: AccountTransactionAggregate;
  /** An array relationship */
  transactionsByCancelledBy: Array<AccountTransaction>;
  /** An aggregate relationship */
  transactionsByCancelledBy_aggregate: AccountTransactionAggregate;
  /** An array relationship */
  transactionsByChangeRequestedBy: Array<AccountTransaction>;
  /** An aggregate relationship */
  transactionsByChangeRequestedBy_aggregate: AccountTransactionAggregate;
  /** An array relationship */
  transactionsByRejectedBy: Array<AccountTransaction>;
  /** An aggregate relationship */
  transactionsByRejectedBy_aggregate: AccountTransactionAggregate;
  /** An array relationship */
  transactionsByUpdatedBy: Array<AccountTransaction>;
  /** An aggregate relationship */
  transactionsByUpdatedBy_aggregate: AccountTransactionAggregate;
  /** An aggregate relationship */
  transactions_aggregate: AccountTransactionAggregate;
  /** วันที่แก้ไขข้อมูล */
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  users_permissions: Array<UsersPermission>;
  /** An aggregate relationship */
  users_permissions_aggregate: UsersPermissionAggregate;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersAgreementReceiptsByCreatedByArgs = {
  distinct_on?: Maybe<Array<ContractAgreementReceiptSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementReceiptOrderBy>>;
  where?: Maybe<ContractAgreementReceiptBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersAgreementReceiptsByCreatedByAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementReceiptSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementReceiptOrderBy>>;
  where?: Maybe<ContractAgreementReceiptBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersAgreementReceiptsByUpdatedByArgs = {
  distinct_on?: Maybe<Array<ContractAgreementReceiptSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementReceiptOrderBy>>;
  where?: Maybe<ContractAgreementReceiptBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersAgreementReceiptsByUpdatedByAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementReceiptSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementReceiptOrderBy>>;
  where?: Maybe<ContractAgreementReceiptBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersAgreementTransactionsArgs = {
  distinct_on?: Maybe<Array<ContractAgreementTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementTransactionOrderBy>>;
  where?: Maybe<ContractAgreementTransactionBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersAgreementTransactionsAggregateArgs = {
  distinct_on?: Maybe<Array<ContractAgreementTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContractAgreementTransactionOrderBy>>;
  where?: Maybe<ContractAgreementTransactionBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersApplicationBankBranchTransfersByCreatedByArgs = {
  distinct_on?: Maybe<Array<LoanApplicationBankBranchTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationBankBranchTransferOrderBy>>;
  where?: Maybe<LoanApplicationBankBranchTransferBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersApplicationBankBranchTransfersByCreatedByAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationBankBranchTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationBankBranchTransferOrderBy>>;
  where?: Maybe<LoanApplicationBankBranchTransferBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersApplicationBankBranchTransfersByUpdatedByArgs = {
  distinct_on?: Maybe<Array<LoanApplicationBankBranchTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationBankBranchTransferOrderBy>>;
  where?: Maybe<LoanApplicationBankBranchTransferBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersApplicationBankBranchTransfersByUpdatedByAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationBankBranchTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationBankBranchTransferOrderBy>>;
  where?: Maybe<LoanApplicationBankBranchTransferBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersApplicationEventLogsByActorUserIdArgs = {
  distinct_on?: Maybe<Array<LoanApplicationEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationEventLogOrderBy>>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersApplicationEventLogsByActorUserIdAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationEventLogOrderBy>>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersApplicationOrganizationTransfersByUpdatedByArgs = {
  distinct_on?: Maybe<Array<LoanApplicationOrganizationTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationOrganizationTransferOrderBy>>;
  where?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersApplicationOrganizationTransfersByUpdatedByAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationOrganizationTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationOrganizationTransferOrderBy>>;
  where?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersApplicationEventLogsArgs = {
  distinct_on?: Maybe<Array<LoanApplicationEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationEventLogOrderBy>>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersApplicationEventLogsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationEventLogOrderBy>>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersApplicationOrganizationTransfersArgs = {
  distinct_on?: Maybe<Array<LoanApplicationOrganizationTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationOrganizationTransferOrderBy>>;
  where?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersApplicationOrganizationTransfersAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationOrganizationTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationOrganizationTransferOrderBy>>;
  where?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersApplicationTransfersArgs = {
  distinct_on?: Maybe<Array<LoanApplicationOrganizationTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationOrganizationTransferOrderBy>>;
  where?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersApplicationTransfersAggregateArgs = {
  distinct_on?: Maybe<Array<LoanApplicationOrganizationTransferSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationOrganizationTransferOrderBy>>;
  where?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersApprovedLedgersArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerOrderBy>>;
  where?: Maybe<LedgerLedgerBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersApprovedLedgersAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerOrderBy>>;
  where?: Maybe<LedgerLedgerBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersChangeRequestLedgersArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerOrderBy>>;
  where?: Maybe<LedgerLedgerBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersChangeRequestLedgersAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerOrderBy>>;
  where?: Maybe<LedgerLedgerBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersImportLogsArgs = {
  distinct_on?: Maybe<Array<ImportLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ImportLogOrderBy>>;
  where?: Maybe<ImportLogBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersImportLogsAggregateArgs = {
  distinct_on?: Maybe<Array<ImportLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ImportLogOrderBy>>;
  where?: Maybe<ImportLogBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersLendingsArgs = {
  distinct_on?: Maybe<Array<AccountLendingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLendingOrderBy>>;
  where?: Maybe<AccountLendingBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersLendingsByUpdatedByArgs = {
  distinct_on?: Maybe<Array<AccountLendingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLendingOrderBy>>;
  where?: Maybe<AccountLendingBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersLendingsByUpdatedByAggregateArgs = {
  distinct_on?: Maybe<Array<AccountLendingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLendingOrderBy>>;
  where?: Maybe<AccountLendingBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersLendingsAggregateArgs = {
  distinct_on?: Maybe<Array<AccountLendingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountLendingOrderBy>>;
  where?: Maybe<AccountLendingBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersLockedLedgersArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerOrderBy>>;
  where?: Maybe<LedgerLedgerBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersLockedLedgersAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerOrderBy>>;
  where?: Maybe<LedgerLedgerBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersNotificationsBySenderIdArgs = {
  distinct_on?: Maybe<Array<NotificationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NotificationOrderBy>>;
  where?: Maybe<NotificationBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersNotificationsBySenderIdAggregateArgs = {
  distinct_on?: Maybe<Array<NotificationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NotificationOrderBy>>;
  where?: Maybe<NotificationBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersNotificationsByUserIdArgs = {
  distinct_on?: Maybe<Array<NotificationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NotificationOrderBy>>;
  where?: Maybe<NotificationBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersNotificationsByUserIdAggregateArgs = {
  distinct_on?: Maybe<Array<NotificationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NotificationOrderBy>>;
  where?: Maybe<NotificationBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersProjectAttachmentsByUpdatedByArgs = {
  distinct_on?: Maybe<Array<ProjectProjectAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectAttachmentOrderBy>>;
  where?: Maybe<ProjectProjectAttachmentBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersProjectAttachmentsByUpdatedByAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectAttachmentOrderBy>>;
  where?: Maybe<ProjectProjectAttachmentBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersProjectAttachmentsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectAttachmentOrderBy>>;
  where?: Maybe<ProjectProjectAttachmentBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersProjectAttachmentsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectAttachmentOrderBy>>;
  where?: Maybe<ProjectProjectAttachmentBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersProjectEventLogsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectEventLogOrderBy>>;
  where?: Maybe<ProjectProjectEventLogBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersProjectEventLogsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectEventLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectEventLogOrderBy>>;
  where?: Maybe<ProjectProjectEventLogBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersProjectsArgs = {
  distinct_on?: Maybe<Array<ProjectProjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOrderBy>>;
  where?: Maybe<ProjectProjectBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersProjectsByUpdatedByArgs = {
  distinct_on?: Maybe<Array<ProjectProjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOrderBy>>;
  where?: Maybe<ProjectProjectBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersProjectsByUpdatedByAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOrderBy>>;
  where?: Maybe<ProjectProjectBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersProjectsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectProjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectProjectOrderBy>>;
  where?: Maybe<ProjectProjectBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersSearchLogsArgs = {
  distinct_on?: Maybe<Array<LoanSourceSearchLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceSearchLogOrderBy>>;
  where?: Maybe<LoanSourceSearchLogBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersSearchLogsAggregateArgs = {
  distinct_on?: Maybe<Array<LoanSourceSearchLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanSourceSearchLogOrderBy>>;
  where?: Maybe<LoanSourceSearchLogBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersSubmittedLedgersArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerOrderBy>>;
  where?: Maybe<LedgerLedgerBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersSubmittedLedgersAggregateArgs = {
  distinct_on?: Maybe<Array<LedgerLedgerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LedgerLedgerOrderBy>>;
  where?: Maybe<LedgerLedgerBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersTransactionLogsArgs = {
  distinct_on?: Maybe<Array<AccountTransactionLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionLogOrderBy>>;
  where?: Maybe<AccountTransactionLogBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersTransactionLogsAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTransactionLogSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionLogOrderBy>>;
  where?: Maybe<AccountTransactionLogBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersTransactionsArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersTransactionsByApprovedByArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersTransactionsByApprovedByAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersTransactionsByCancelledByArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersTransactionsByCancelledByAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersTransactionsByChangeRequestedByArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersTransactionsByChangeRequestedByAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersTransactionsByRejectedByArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersTransactionsByRejectedByAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersTransactionsByUpdatedByArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersTransactionsByUpdatedByAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersTransactionsAggregateArgs = {
  distinct_on?: Maybe<Array<AccountTransactionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AccountTransactionOrderBy>>;
  where?: Maybe<AccountTransactionBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersUsersPermissionsArgs = {
  distinct_on?: Maybe<Array<UsersPermissionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersPermissionOrderBy>>;
  where?: Maybe<UsersPermissionBoolExp>;
};


/**
 * ผู้ใช้งาน
 *
 *
 * columns and relationships of "users"
 */
export type UsersUsersPermissionsAggregateArgs = {
  distinct_on?: Maybe<Array<UsersPermissionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersPermissionOrderBy>>;
  where?: Maybe<UsersPermissionBoolExp>;
};

/** aggregated selection of "users" */
export type UsersAggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<UsersAggregateFields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type UsersAggregateFields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<UsersAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<UsersMaxFields>;
  min?: Maybe<UsersMinFields>;
  stddev?: Maybe<UsersStddevFields>;
  stddev_pop?: Maybe<UsersStddevPopFields>;
  stddev_samp?: Maybe<UsersStddevSampFields>;
  sum?: Maybe<UsersSumFields>;
  var_pop?: Maybe<UsersVarPopFields>;
  var_samp?: Maybe<UsersVarSampFields>;
  variance?: Maybe<UsersVarianceFields>;
};


/** aggregate fields of "users" */
export type UsersAggregateFieldsCountArgs = {
  columns?: Maybe<Array<UsersSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type UsersAggregateOrderBy = {
  avg?: Maybe<UsersAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<UsersMaxOrderBy>;
  min?: Maybe<UsersMinOrderBy>;
  stddev?: Maybe<UsersStddevOrderBy>;
  stddev_pop?: Maybe<UsersStddevPopOrderBy>;
  stddev_samp?: Maybe<UsersStddevSampOrderBy>;
  sum?: Maybe<UsersSumOrderBy>;
  var_pop?: Maybe<UsersVarPopOrderBy>;
  var_samp?: Maybe<UsersVarSampOrderBy>;
  variance?: Maybe<UsersVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "users" */
export type UsersArrRelInsertInput = {
  data: Array<UsersInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<UsersOnConflict>;
};

/** aggregate avg on columns */
export type UsersAvgFields = {
  __typename?: 'users_avg_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "users" */
export type UsersAvgOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type UsersBoolExp = {
  _and?: Maybe<Array<UsersBoolExp>>;
  _not?: Maybe<UsersBoolExp>;
  _or?: Maybe<Array<UsersBoolExp>>;
  account?: Maybe<AuthAccountsBoolExp>;
  agreement_receipts_by_created_by?: Maybe<ContractAgreementReceiptBoolExp>;
  agreement_receipts_by_updated_by?: Maybe<ContractAgreementReceiptBoolExp>;
  agreement_transactions?: Maybe<ContractAgreementTransactionBoolExp>;
  applicationBankBranchTransfersByCreatedBy?: Maybe<LoanApplicationBankBranchTransferBoolExp>;
  applicationBankBranchTransfersByUpdatedBy?: Maybe<LoanApplicationBankBranchTransferBoolExp>;
  applicationEventLogsByActorUserId?: Maybe<LoanApplicationEventLogBoolExp>;
  applicationOrganizationTransfersByUpdatedBy?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
  application_event_logs?: Maybe<LoanApplicationEventLogBoolExp>;
  application_organization_transfers?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
  application_transfers?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
  approved_ledgers?: Maybe<LedgerLedgerBoolExp>;
  avatar_url?: Maybe<StringComparisonExp>;
  bank_branch?: Maybe<BankBranchBoolExp>;
  bank_branch_id?: Maybe<StringComparisonExp>;
  change_request_ledgers?: Maybe<LedgerLedgerBoolExp>;
  consent_checked?: Maybe<BooleanComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  department?: Maybe<DepartmentBoolExp>;
  department_id?: Maybe<UuidComparisonExp>;
  display_name?: Maybe<StringComparisonExp>;
  first_name?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  import_logs?: Maybe<ImportLogBoolExp>;
  last_name?: Maybe<StringComparisonExp>;
  lendings?: Maybe<AccountLendingBoolExp>;
  lendingsByUpdatedBy?: Maybe<AccountLendingBoolExp>;
  locked_ledgers?: Maybe<LedgerLedgerBoolExp>;
  name_title?: Maybe<NameTitleBoolExp>;
  note?: Maybe<StringComparisonExp>;
  notificationsBySenderId?: Maybe<NotificationBoolExp>;
  notificationsByUserId?: Maybe<NotificationBoolExp>;
  organization?: Maybe<OrganizationBoolExp>;
  organization_id?: Maybe<UuidComparisonExp>;
  projectAttachmentsByUpdatedBy?: Maybe<ProjectProjectAttachmentBoolExp>;
  project_attachments?: Maybe<ProjectProjectAttachmentBoolExp>;
  project_event_logs?: Maybe<ProjectProjectEventLogBoolExp>;
  projects?: Maybe<ProjectProjectBoolExp>;
  projectsByUpdatedBy?: Maybe<ProjectProjectBoolExp>;
  reference?: Maybe<StringComparisonExp>;
  search_logs?: Maybe<LoanSourceSearchLogBoolExp>;
  sequence?: Maybe<NumericComparisonExp>;
  submitted_ledgers?: Maybe<LedgerLedgerBoolExp>;
  tel?: Maybe<StringComparisonExp>;
  title_id?: Maybe<StringComparisonExp>;
  transaction_logs?: Maybe<AccountTransactionLogBoolExp>;
  transactions?: Maybe<AccountTransactionBoolExp>;
  transactionsByApprovedBy?: Maybe<AccountTransactionBoolExp>;
  transactionsByCancelledBy?: Maybe<AccountTransactionBoolExp>;
  transactionsByChangeRequestedBy?: Maybe<AccountTransactionBoolExp>;
  transactionsByRejectedBy?: Maybe<AccountTransactionBoolExp>;
  transactionsByUpdatedBy?: Maybe<AccountTransactionBoolExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  users_permissions?: Maybe<UsersPermissionBoolExp>;
};

/** unique or primary key constraints on table "users" */
export enum UsersConstraint {
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** input type for incrementing numeric columns in table "users" */
export type UsersIncInput = {
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "users" */
export type UsersInsertInput = {
  account?: Maybe<AuthAccountsObjRelInsertInput>;
  agreement_receipts_by_created_by?: Maybe<ContractAgreementReceiptArrRelInsertInput>;
  agreement_receipts_by_updated_by?: Maybe<ContractAgreementReceiptArrRelInsertInput>;
  agreement_transactions?: Maybe<ContractAgreementTransactionArrRelInsertInput>;
  applicationBankBranchTransfersByCreatedBy?: Maybe<LoanApplicationBankBranchTransferArrRelInsertInput>;
  applicationBankBranchTransfersByUpdatedBy?: Maybe<LoanApplicationBankBranchTransferArrRelInsertInput>;
  applicationEventLogsByActorUserId?: Maybe<LoanApplicationEventLogArrRelInsertInput>;
  applicationOrganizationTransfersByUpdatedBy?: Maybe<LoanApplicationOrganizationTransferArrRelInsertInput>;
  application_event_logs?: Maybe<LoanApplicationEventLogArrRelInsertInput>;
  application_organization_transfers?: Maybe<LoanApplicationOrganizationTransferArrRelInsertInput>;
  application_transfers?: Maybe<LoanApplicationOrganizationTransferArrRelInsertInput>;
  approved_ledgers?: Maybe<LedgerLedgerArrRelInsertInput>;
  /** ที่อยู่รูปภาพ */
  avatar_url?: Maybe<Scalars['String']>;
  bank_branch?: Maybe<BankBranchObjRelInsertInput>;
  /** ธนาคาร */
  bank_branch_id?: Maybe<Scalars['String']>;
  change_request_ledgers?: Maybe<LedgerLedgerArrRelInsertInput>;
  /** consent_checked */
  consent_checked?: Maybe<Scalars['Boolean']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  department?: Maybe<DepartmentObjRelInsertInput>;
  /** หน่วยงาน */
  department_id?: Maybe<Scalars['uuid']>;
  /** ชื่อแสดง */
  display_name?: Maybe<Scalars['String']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  import_logs?: Maybe<ImportLogArrRelInsertInput>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  lendings?: Maybe<AccountLendingArrRelInsertInput>;
  lendingsByUpdatedBy?: Maybe<AccountLendingArrRelInsertInput>;
  locked_ledgers?: Maybe<LedgerLedgerArrRelInsertInput>;
  name_title?: Maybe<NameTitleObjRelInsertInput>;
  /** Noted */
  note?: Maybe<Scalars['String']>;
  notificationsBySenderId?: Maybe<NotificationArrRelInsertInput>;
  notificationsByUserId?: Maybe<NotificationArrRelInsertInput>;
  organization?: Maybe<OrganizationObjRelInsertInput>;
  /** หน่วยงาน */
  organization_id?: Maybe<Scalars['uuid']>;
  projectAttachmentsByUpdatedBy?: Maybe<ProjectProjectAttachmentArrRelInsertInput>;
  project_attachments?: Maybe<ProjectProjectAttachmentArrRelInsertInput>;
  project_event_logs?: Maybe<ProjectProjectEventLogArrRelInsertInput>;
  projects?: Maybe<ProjectProjectArrRelInsertInput>;
  projectsByUpdatedBy?: Maybe<ProjectProjectArrRelInsertInput>;
  /** อ้างอิง */
  reference?: Maybe<Scalars['String']>;
  search_logs?: Maybe<LoanSourceSearchLogArrRelInsertInput>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  submitted_ledgers?: Maybe<LedgerLedgerArrRelInsertInput>;
  /** เบอร์โทรศัพท์ */
  tel?: Maybe<Scalars['String']>;
  /** คำนำหน้า */
  title_id?: Maybe<Scalars['String']>;
  transaction_logs?: Maybe<AccountTransactionLogArrRelInsertInput>;
  transactions?: Maybe<AccountTransactionArrRelInsertInput>;
  transactionsByApprovedBy?: Maybe<AccountTransactionArrRelInsertInput>;
  transactionsByCancelledBy?: Maybe<AccountTransactionArrRelInsertInput>;
  transactionsByChangeRequestedBy?: Maybe<AccountTransactionArrRelInsertInput>;
  transactionsByRejectedBy?: Maybe<AccountTransactionArrRelInsertInput>;
  transactionsByUpdatedBy?: Maybe<AccountTransactionArrRelInsertInput>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
  users_permissions?: Maybe<UsersPermissionArrRelInsertInput>;
};

/** aggregate max on columns */
export type UsersMaxFields = {
  __typename?: 'users_max_fields';
  /** ที่อยู่รูปภาพ */
  avatar_url?: Maybe<Scalars['String']>;
  /** ธนาคาร */
  bank_branch_id?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** หน่วยงาน */
  department_id?: Maybe<Scalars['uuid']>;
  /** ชื่อแสดง */
  display_name?: Maybe<Scalars['String']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** Noted */
  note?: Maybe<Scalars['String']>;
  /** หน่วยงาน */
  organization_id?: Maybe<Scalars['uuid']>;
  /** อ้างอิง */
  reference?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** เบอร์โทรศัพท์ */
  tel?: Maybe<Scalars['String']>;
  /** คำนำหน้า */
  title_id?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "users" */
export type UsersMaxOrderBy = {
  /** ที่อยู่รูปภาพ */
  avatar_url?: Maybe<OrderBy>;
  /** ธนาคาร */
  bank_branch_id?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** หน่วยงาน */
  department_id?: Maybe<OrderBy>;
  /** ชื่อแสดง */
  display_name?: Maybe<OrderBy>;
  /** ชื่อ */
  first_name?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** นามสกุล */
  last_name?: Maybe<OrderBy>;
  /** Noted */
  note?: Maybe<OrderBy>;
  /** หน่วยงาน */
  organization_id?: Maybe<OrderBy>;
  /** อ้างอิง */
  reference?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** เบอร์โทรศัพท์ */
  tel?: Maybe<OrderBy>;
  /** คำนำหน้า */
  title_id?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type UsersMinFields = {
  __typename?: 'users_min_fields';
  /** ที่อยู่รูปภาพ */
  avatar_url?: Maybe<Scalars['String']>;
  /** ธนาคาร */
  bank_branch_id?: Maybe<Scalars['String']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** หน่วยงาน */
  department_id?: Maybe<Scalars['uuid']>;
  /** ชื่อแสดง */
  display_name?: Maybe<Scalars['String']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** Noted */
  note?: Maybe<Scalars['String']>;
  /** หน่วยงาน */
  organization_id?: Maybe<Scalars['uuid']>;
  /** อ้างอิง */
  reference?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** เบอร์โทรศัพท์ */
  tel?: Maybe<Scalars['String']>;
  /** คำนำหน้า */
  title_id?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "users" */
export type UsersMinOrderBy = {
  /** ที่อยู่รูปภาพ */
  avatar_url?: Maybe<OrderBy>;
  /** ธนาคาร */
  bank_branch_id?: Maybe<OrderBy>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<OrderBy>;
  /** หน่วยงาน */
  department_id?: Maybe<OrderBy>;
  /** ชื่อแสดง */
  display_name?: Maybe<OrderBy>;
  /** ชื่อ */
  first_name?: Maybe<OrderBy>;
  /** Primary key */
  id?: Maybe<OrderBy>;
  /** นามสกุล */
  last_name?: Maybe<OrderBy>;
  /** Noted */
  note?: Maybe<OrderBy>;
  /** หน่วยงาน */
  organization_id?: Maybe<OrderBy>;
  /** อ้างอิง */
  reference?: Maybe<OrderBy>;
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
  /** เบอร์โทรศัพท์ */
  tel?: Maybe<OrderBy>;
  /** คำนำหน้า */
  title_id?: Maybe<OrderBy>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "users" */
export type UsersMutationResponse = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type UsersObjRelInsertInput = {
  data: UsersInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<UsersOnConflict>;
};

/** on conflict condition type for table "users" */
export type UsersOnConflict = {
  constraint: UsersConstraint;
  update_columns?: Array<UsersUpdateColumn>;
  where?: Maybe<UsersBoolExp>;
};

/** Ordering options when selecting data from "users". */
export type UsersOrderBy = {
  account?: Maybe<AuthAccountsOrderBy>;
  agreement_receipts_by_created_by_aggregate?: Maybe<ContractAgreementReceiptAggregateOrderBy>;
  agreement_receipts_by_updated_by_aggregate?: Maybe<ContractAgreementReceiptAggregateOrderBy>;
  agreement_transactions_aggregate?: Maybe<ContractAgreementTransactionAggregateOrderBy>;
  applicationBankBranchTransfersByCreatedBy_aggregate?: Maybe<LoanApplicationBankBranchTransferAggregateOrderBy>;
  applicationBankBranchTransfersByUpdatedBy_aggregate?: Maybe<LoanApplicationBankBranchTransferAggregateOrderBy>;
  applicationEventLogsByActorUserId_aggregate?: Maybe<LoanApplicationEventLogAggregateOrderBy>;
  applicationOrganizationTransfersByUpdatedBy_aggregate?: Maybe<LoanApplicationOrganizationTransferAggregateOrderBy>;
  application_event_logs_aggregate?: Maybe<LoanApplicationEventLogAggregateOrderBy>;
  application_organization_transfers_aggregate?: Maybe<LoanApplicationOrganizationTransferAggregateOrderBy>;
  application_transfers_aggregate?: Maybe<LoanApplicationOrganizationTransferAggregateOrderBy>;
  approved_ledgers_aggregate?: Maybe<LedgerLedgerAggregateOrderBy>;
  avatar_url?: Maybe<OrderBy>;
  bank_branch?: Maybe<BankBranchOrderBy>;
  bank_branch_id?: Maybe<OrderBy>;
  change_request_ledgers_aggregate?: Maybe<LedgerLedgerAggregateOrderBy>;
  consent_checked?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  department?: Maybe<DepartmentOrderBy>;
  department_id?: Maybe<OrderBy>;
  display_name?: Maybe<OrderBy>;
  first_name?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  import_logs_aggregate?: Maybe<ImportLogAggregateOrderBy>;
  last_name?: Maybe<OrderBy>;
  lendingsByUpdatedBy_aggregate?: Maybe<AccountLendingAggregateOrderBy>;
  lendings_aggregate?: Maybe<AccountLendingAggregateOrderBy>;
  locked_ledgers_aggregate?: Maybe<LedgerLedgerAggregateOrderBy>;
  name_title?: Maybe<NameTitleOrderBy>;
  note?: Maybe<OrderBy>;
  notificationsBySenderId_aggregate?: Maybe<NotificationAggregateOrderBy>;
  notificationsByUserId_aggregate?: Maybe<NotificationAggregateOrderBy>;
  organization?: Maybe<OrganizationOrderBy>;
  organization_id?: Maybe<OrderBy>;
  projectAttachmentsByUpdatedBy_aggregate?: Maybe<ProjectProjectAttachmentAggregateOrderBy>;
  project_attachments_aggregate?: Maybe<ProjectProjectAttachmentAggregateOrderBy>;
  project_event_logs_aggregate?: Maybe<ProjectProjectEventLogAggregateOrderBy>;
  projectsByUpdatedBy_aggregate?: Maybe<ProjectProjectAggregateOrderBy>;
  projects_aggregate?: Maybe<ProjectProjectAggregateOrderBy>;
  reference?: Maybe<OrderBy>;
  search_logs_aggregate?: Maybe<LoanSourceSearchLogAggregateOrderBy>;
  sequence?: Maybe<OrderBy>;
  submitted_ledgers_aggregate?: Maybe<LedgerLedgerAggregateOrderBy>;
  tel?: Maybe<OrderBy>;
  title_id?: Maybe<OrderBy>;
  transaction_logs_aggregate?: Maybe<AccountTransactionLogAggregateOrderBy>;
  transactionsByApprovedBy_aggregate?: Maybe<AccountTransactionAggregateOrderBy>;
  transactionsByCancelledBy_aggregate?: Maybe<AccountTransactionAggregateOrderBy>;
  transactionsByChangeRequestedBy_aggregate?: Maybe<AccountTransactionAggregateOrderBy>;
  transactionsByRejectedBy_aggregate?: Maybe<AccountTransactionAggregateOrderBy>;
  transactionsByUpdatedBy_aggregate?: Maybe<AccountTransactionAggregateOrderBy>;
  transactions_aggregate?: Maybe<AccountTransactionAggregateOrderBy>;
  updated_at?: Maybe<OrderBy>;
  users_permissions_aggregate?: Maybe<UsersPermissionAggregateOrderBy>;
};

/**
 * User Permission
 *
 *
 * columns and relationships of "users_permission"
 */
export type UsersPermission = {
  __typename?: 'users_permission';
  /** An object relationship */
  permission: Permission;
  /** รหัสอ้างอิง Permission */
  permission_id: Scalars['String'];
  /** An object relationship */
  user: Users;
  /** รหัสอ้างอิง User */
  user_id: Scalars['uuid'];
};

/** aggregated selection of "users_permission" */
export type UsersPermissionAggregate = {
  __typename?: 'users_permission_aggregate';
  aggregate?: Maybe<UsersPermissionAggregateFields>;
  nodes: Array<UsersPermission>;
};

/** aggregate fields of "users_permission" */
export type UsersPermissionAggregateFields = {
  __typename?: 'users_permission_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UsersPermissionMaxFields>;
  min?: Maybe<UsersPermissionMinFields>;
};


/** aggregate fields of "users_permission" */
export type UsersPermissionAggregateFieldsCountArgs = {
  columns?: Maybe<Array<UsersPermissionSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users_permission" */
export type UsersPermissionAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<UsersPermissionMaxOrderBy>;
  min?: Maybe<UsersPermissionMinOrderBy>;
};

/** input type for inserting array relation for remote table "users_permission" */
export type UsersPermissionArrRelInsertInput = {
  data: Array<UsersPermissionInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<UsersPermissionOnConflict>;
};

/** Boolean expression to filter rows from the table "users_permission". All fields are combined with a logical 'AND'. */
export type UsersPermissionBoolExp = {
  _and?: Maybe<Array<UsersPermissionBoolExp>>;
  _not?: Maybe<UsersPermissionBoolExp>;
  _or?: Maybe<Array<UsersPermissionBoolExp>>;
  permission?: Maybe<PermissionBoolExp>;
  permission_id?: Maybe<StringComparisonExp>;
  user?: Maybe<UsersBoolExp>;
  user_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "users_permission" */
export enum UsersPermissionConstraint {
  /** unique or primary key constraint */
  UsersPermissionPkey = 'users_permission_pkey',
  /** unique or primary key constraint */
  UsersPermissionUserIdPermissionIdKey = 'users_permission_user_id_permission_id_key'
}

/** input type for inserting data into table "users_permission" */
export type UsersPermissionInsertInput = {
  permission?: Maybe<PermissionObjRelInsertInput>;
  /** รหัสอ้างอิง Permission */
  permission_id?: Maybe<Scalars['String']>;
  user?: Maybe<UsersObjRelInsertInput>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type UsersPermissionMaxFields = {
  __typename?: 'users_permission_max_fields';
  /** รหัสอ้างอิง Permission */
  permission_id?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "users_permission" */
export type UsersPermissionMaxOrderBy = {
  /** รหัสอ้างอิง Permission */
  permission_id?: Maybe<OrderBy>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type UsersPermissionMinFields = {
  __typename?: 'users_permission_min_fields';
  /** รหัสอ้างอิง Permission */
  permission_id?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "users_permission" */
export type UsersPermissionMinOrderBy = {
  /** รหัสอ้างอิง Permission */
  permission_id?: Maybe<OrderBy>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "users_permission" */
export type UsersPermissionMutationResponse = {
  __typename?: 'users_permission_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UsersPermission>;
};

/** on conflict condition type for table "users_permission" */
export type UsersPermissionOnConflict = {
  constraint: UsersPermissionConstraint;
  update_columns?: Array<UsersPermissionUpdateColumn>;
  where?: Maybe<UsersPermissionBoolExp>;
};

/** Ordering options when selecting data from "users_permission". */
export type UsersPermissionOrderBy = {
  permission?: Maybe<PermissionOrderBy>;
  permission_id?: Maybe<OrderBy>;
  user?: Maybe<UsersOrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: users_permission */
export type UsersPermissionPkColumnsInput = {
  /** รหัสอ้างอิง Permission */
  permission_id: Scalars['String'];
  /** รหัสอ้างอิง User */
  user_id: Scalars['uuid'];
};

/** select columns of table "users_permission" */
export enum UsersPermissionSelectColumn {
  /** column name */
  PermissionId = 'permission_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "users_permission" */
export type UsersPermissionSetInput = {
  /** รหัสอ้างอิง Permission */
  permission_id?: Maybe<Scalars['String']>;
  /** รหัสอ้างอิง User */
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "users_permission" */
export enum UsersPermissionUpdateColumn {
  /** column name */
  PermissionId = 'permission_id',
  /** column name */
  UserId = 'user_id'
}

/** primary key columns input for table: users */
export type UsersPkColumnsInput = {
  /** Primary key */
  id: Scalars['uuid'];
};

/** select columns of table "users" */
export enum UsersSelectColumn {
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  BankBranchId = 'bank_branch_id',
  /** column name */
  ConsentChecked = 'consent_checked',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Note = 'note',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Reference = 'reference',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  Tel = 'tel',
  /** column name */
  TitleId = 'title_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "users" */
export type UsersSetInput = {
  /** ที่อยู่รูปภาพ */
  avatar_url?: Maybe<Scalars['String']>;
  /** ธนาคาร */
  bank_branch_id?: Maybe<Scalars['String']>;
  /** consent_checked */
  consent_checked?: Maybe<Scalars['Boolean']>;
  /** วันที่บันทึกข้อมูล */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** หน่วยงาน */
  department_id?: Maybe<Scalars['uuid']>;
  /** ชื่อแสดง */
  display_name?: Maybe<Scalars['String']>;
  /** ชื่อ */
  first_name?: Maybe<Scalars['String']>;
  /** Primary key */
  id?: Maybe<Scalars['uuid']>;
  /** นามสกุล */
  last_name?: Maybe<Scalars['String']>;
  /** Noted */
  note?: Maybe<Scalars['String']>;
  /** หน่วยงาน */
  organization_id?: Maybe<Scalars['uuid']>;
  /** อ้างอิง */
  reference?: Maybe<Scalars['String']>;
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
  /** เบอร์โทรศัพท์ */
  tel?: Maybe<Scalars['String']>;
  /** คำนำหน้า */
  title_id?: Maybe<Scalars['String']>;
  /** วันที่แก้ไขข้อมูล */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type UsersStddevFields = {
  __typename?: 'users_stddev_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "users" */
export type UsersStddevOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type UsersStddevPopFields = {
  __typename?: 'users_stddev_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "users" */
export type UsersStddevPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type UsersStddevSampFields = {
  __typename?: 'users_stddev_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "users" */
export type UsersStddevSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type UsersSumFields = {
  __typename?: 'users_sum_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "users" */
export type UsersSumOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** update columns of table "users" */
export enum UsersUpdateColumn {
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  BankBranchId = 'bank_branch_id',
  /** column name */
  ConsentChecked = 'consent_checked',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Note = 'note',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Reference = 'reference',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  Tel = 'tel',
  /** column name */
  TitleId = 'title_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type UsersVarPopFields = {
  __typename?: 'users_var_pop_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "users" */
export type UsersVarPopOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type UsersVarSampFields = {
  __typename?: 'users_var_samp_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "users" */
export type UsersVarSampOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type UsersVarianceFields = {
  __typename?: 'users_variance_fields';
  /** ลำดับที่ */
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "users" */
export type UsersVarianceOrderBy = {
  /** ลำดับที่ */
  sequence?: Maybe<OrderBy>;
};


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type GetFeatureByPkQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetFeatureByPkQuery = (
  { __typename?: 'query_root' }
  & { row?: Maybe<(
    { __typename?: 'feature_toggle' }
    & Pick<FeatureToggle, 'id' | 'enable' | 'description'>
  )> }
);

export type GetUserPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserPermissionsQuery = (
  { __typename?: 'query_root' }
  & { user_permissions: Array<(
    { __typename?: 'current_user_permissions' }
    & Pick<CurrentUserPermissions, 'user_id' | 'permission_id'>
  )> }
);

export type AccountFragment = (
  { __typename?: 'account_account' }
  & Pick<AccountAccount, 'id' | 'code' | 'name' | 'category_id' | 'default_entry_type_id'>
  & { account_type?: Maybe<(
    { __typename?: 'account_account_type' }
    & AccountTypeFragment
  )>, account_category?: Maybe<(
    { __typename?: 'account_account_category' }
    & AccountCategoryFragment
  )>, entry_type: (
    { __typename?: 'account_entry_type' }
    & Pick<AccountEntryType, 'id' | 'description'>
  ) }
);

export type AccountFullFragment = (
  { __typename?: 'account_account' }
  & Pick<AccountAccount, 'id' | 'code' | 'name' | 'fiscal_year' | 'note' | 'parent_id' | 'type_id' | 'category_id' | 'default_entry_type_id' | 'created_at' | 'updated_at' | 'deleted_at'>
  & { account_type?: Maybe<(
    { __typename?: 'account_account_type' }
    & AccountTypeFragment
  )>, account_category?: Maybe<(
    { __typename?: 'account_account_category' }
    & AccountCategoryFragment
  )>, entry_type: (
    { __typename?: 'account_entry_type' }
    & Pick<AccountEntryType, 'id' | 'description'>
  ) }
);

export type AccountTypeFragment = (
  { __typename?: 'account_account_type' }
  & Pick<AccountAccountType, 'id' | 'description'>
);

export type UpdateAccountByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: Maybe<AccountAccountSetInput>;
}>;


export type UpdateAccountByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_account_account_by_pk?: Maybe<(
    { __typename?: 'account_account' }
    & AccountFullFragment
  )> }
);

export type InsertAccountOneMutationVariables = Exact<{
  object: AccountAccountInsertInput;
}>;


export type InsertAccountOneMutation = (
  { __typename?: 'mutation_root' }
  & { insert_account_account_one?: Maybe<(
    { __typename?: 'account_account' }
    & AccountFullFragment
  )> }
);

export type GetAccountsQueryVariables = Exact<{
  where?: Maybe<AccountAccountBoolExp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountAccountOrderBy> | AccountAccountOrderBy>;
}>;


export type GetAccountsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'account_account' }
    & AccountFragment
  )> }
);

export type GetAccountsFullQueryVariables = Exact<{
  distinctOn?: Maybe<Array<AccountAccountSelectColumn> | AccountAccountSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountAccountOrderBy> | AccountAccountOrderBy>;
  where?: Maybe<AccountAccountBoolExp>;
}>;


export type GetAccountsFullQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'account_account' }
    & AccountFullFragment
  )> }
);

export type GetAccountByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetAccountByPkQuery = (
  { __typename?: 'query_root' }
  & { entity?: Maybe<(
    { __typename?: 'account_account' }
    & AccountFullFragment
  )> }
);

export type GetAccountTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountTypesQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'account_account_type' }
    & AccountTypeFragment
  )> }
);

export type GetAccountTrialBalancesQueryVariables = Exact<{
  fiscalYear: Scalars['Int'];
  organizationId: Scalars['uuid'];
}>;


export type GetAccountTrialBalancesQuery = (
  { __typename?: 'query_root' }
  & { account_account: Array<(
    { __typename?: 'account_account' }
    & Pick<AccountAccount, 'id' | 'code' | 'name' | 'default_entry_type_id'>
    & { balances: Array<(
      { __typename?: 'account_account_balance_sheet' }
      & Pick<AccountAccountBalanceSheet, 'id' | 'balance' | 'budget_id' | 'fiscal_year' | 'organization_id' | 'type_id'>
    )>, balances_aggregate: (
      { __typename?: 'account_account_balance_sheet_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'account_account_balance_sheet_aggregate_fields' }
        & { sum?: Maybe<(
          { __typename?: 'account_account_balance_sheet_sum_fields' }
          & Pick<AccountAccountBalanceSheetSumFields, 'balance'>
        )> }
      )> }
    ) }
  )> }
);

export type GetAccountTrialBalancesMonthlyQueryVariables = Exact<{
  date: Scalars['date'];
  inDate: Scalars['date'];
  organizationId: Scalars['uuid'];
}>;


export type GetAccountTrialBalancesMonthlyQuery = (
  { __typename?: 'query_root' }
  & { organization?: Maybe<(
    { __typename?: 'organization' }
    & Pick<Organization, 'id' | 'name' | 'short_name'>
  )>, rows: Array<(
    { __typename?: 'account_account' }
    & Pick<AccountAccount, 'id' | 'name' | 'code' | 'type_id'>
    & { account_type?: Maybe<(
      { __typename?: 'account_account_type' }
      & Pick<AccountAccountType, 'id' | 'description'>
    )>, debit_bring: (
      { __typename?: 'account_ledger_entry_view_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'account_ledger_entry_view_aggregate_fields' }
        & { sum?: Maybe<(
          { __typename?: 'account_ledger_entry_view_sum_fields' }
          & Pick<AccountLedgerEntryViewSumFields, 'amount'>
        )> }
      )> }
    ), credit_bring: (
      { __typename?: 'account_ledger_entry_view_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'account_ledger_entry_view_aggregate_fields' }
        & { sum?: Maybe<(
          { __typename?: 'account_ledger_entry_view_sum_fields' }
          & Pick<AccountLedgerEntryViewSumFields, 'amount'>
        )> }
      )> }
    ), debit_total: (
      { __typename?: 'account_ledger_entry_view_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'account_ledger_entry_view_aggregate_fields' }
        & { sum?: Maybe<(
          { __typename?: 'account_ledger_entry_view_sum_fields' }
          & Pick<AccountLedgerEntryViewSumFields, 'amount'>
        )> }
      )> }
    ), credit_total: (
      { __typename?: 'account_ledger_entry_view_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'account_ledger_entry_view_aggregate_fields' }
        & { sum?: Maybe<(
          { __typename?: 'account_ledger_entry_view_sum_fields' }
          & Pick<AccountLedgerEntryViewSumFields, 'amount'>
        )> }
      )> }
    ) }
  )> }
);

export type AccountBudgetAllocationFragment = (
  { __typename?: 'account_budget_allocation' }
  & Pick<AccountBudgetAllocation, 'id' | 'budget_total' | 'budget_type_id' | 'created_at' | 'fiscal_year' | 'organization_id' | 'updated_at'>
  & { budget_type?: Maybe<(
    { __typename?: 'account_budget_type' }
    & Pick<AccountBudgetType, 'description' | 'id' | 'sequence' | 'fiscal_year'>
  )>, organization: (
    { __typename?: 'organization' }
    & Pick<Organization, 'name' | 'province' | 'reference' | 'area_code' | 'short_name' | 'unit_code'>
  ) }
);

export type GetAccountBudgetAllocationsQueryVariables = Exact<{
  distinctOn?: Maybe<Array<AccountBudgetAllocationSelectColumn> | AccountBudgetAllocationSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountBudgetAllocationOrderBy> | AccountBudgetAllocationOrderBy>;
  where?: Maybe<AccountBudgetAllocationBoolExp>;
}>;


export type GetAccountBudgetAllocationsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'account_budget_allocation' }
    & AccountBudgetAllocationFragment
  )> }
);

export type GetOrganizationBudgetsByFiscalYearQueryVariables = Exact<{
  distinctOn?: Maybe<Array<OrganizationSelectColumn> | OrganizationSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OrganizationOrderBy> | OrganizationOrderBy>;
  fiscalYear: Scalars['Int'];
}>;


export type GetOrganizationBudgetsByFiscalYearQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'organization' }
    & { budget_allocations: Array<(
      { __typename?: 'account_budget_allocation' }
      & AccountBudgetAllocationFragment
    )> }
    & OrganizationFragment
  )> }
);

export type AccountCategoryFragment = (
  { __typename?: 'account_account_category' }
  & Pick<AccountAccountCategory, 'id' | 'name' | 'note' | 'deleted_at' | 'created_at' | 'updated_at'>
);

export type UpdateAccountCategoryByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: Maybe<AccountAccountCategorySetInput>;
}>;


export type UpdateAccountCategoryByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_account_account_category_by_pk?: Maybe<(
    { __typename?: 'account_account_category' }
    & AccountCategoryFragment
  )> }
);

export type InsertAccountCategoryOneMutationVariables = Exact<{
  object: AccountAccountCategoryInsertInput;
}>;


export type InsertAccountCategoryOneMutation = (
  { __typename?: 'mutation_root' }
  & { insert_account_account_category_one?: Maybe<(
    { __typename?: 'account_account_category' }
    & AccountCategoryFragment
  )> }
);

export type GetAccountCategoriesQueryVariables = Exact<{
  distinctOn?: Maybe<Array<AccountAccountCategorySelectColumn> | AccountAccountCategorySelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountAccountCategoryOrderBy> | AccountAccountCategoryOrderBy>;
  where?: Maybe<AccountAccountCategoryBoolExp>;
}>;


export type GetAccountCategoriesQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'account_account_category' }
    & AccountCategoryFragment
  )> }
);

export type GetAccountCategoryByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetAccountCategoryByPkQuery = (
  { __typename?: 'query_root' }
  & { row?: Maybe<(
    { __typename?: 'account_account_category' }
    & AccountCategoryFragment
  )> }
);

export type AccountLendingFragment = (
  { __typename?: 'account_lending' }
  & Pick<AccountLending, 'amount' | 'borrower_name' | 'created_at' | 'created_by' | 'date' | 'description' | 'due_date' | 'fiscal_year' | 'gfmis_id' | 'id' | 'organization_id' | 'reference' | 'status_id' | 'updated_by' | 'updated_at' | 'remark'>
  & { status: (
    { __typename?: 'account_lending_status' }
    & AccountLendingStatusFragment
  ), organization: (
    { __typename?: 'organization' }
    & Pick<Organization, 'area_code' | 'id' | 'name' | 'short_name' | 'reference'>
  ) }
);

export type AccountLendingItemFragment = (
  { __typename?: 'account_lending_item' }
  & Pick<AccountLendingItem, 'id' | 'updated_by' | 'updated_at' | 'note' | 'name' | 'lending_id' | 'date' | 'created_by' | 'created_at' | 'amount'>
);

export type AccountLendingStatusFragment = (
  { __typename?: 'account_lending_status' }
  & Pick<AccountLendingStatus, 'id' | 'description'>
);

export type UpdateAccountLendingByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: Maybe<AccountLendingSetInput>;
}>;


export type UpdateAccountLendingByPkMutation = (
  { __typename?: 'mutation_root' }
  & { account_lending?: Maybe<(
    { __typename?: 'account_lending' }
    & AccountLendingFragment
  )> }
);

export type InsertAccountLendingOneMutationVariables = Exact<{
  object: AccountLendingInsertInput;
}>;


export type InsertAccountLendingOneMutation = (
  { __typename?: 'mutation_root' }
  & { account_lending?: Maybe<(
    { __typename?: 'account_lending' }
    & AccountLendingFragment
  )> }
);

export type DeleteAccountLendingByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteAccountLendingByPkMutation = (
  { __typename?: 'mutation_root' }
  & { account_lending?: Maybe<(
    { __typename?: 'account_lending' }
    & AccountLendingFragment
  )> }
);

export type ReplaceAccountLendingItemsMutationVariables = Exact<{
  lendingId: Scalars['uuid'];
  itemIds: Array<Scalars['uuid']> | Scalars['uuid'];
  insert_lending_items: Array<AccountLendingItemInsertInput> | AccountLendingItemInsertInput;
}>;


export type ReplaceAccountLendingItemsMutation = (
  { __typename?: 'mutation_root' }
  & { deleted_account_lendings?: Maybe<(
    { __typename?: 'account_lending_item_mutation_response' }
    & Pick<AccountLendingItemMutationResponse, 'affected_rows'>
  )>, account_lendings?: Maybe<(
    { __typename?: 'account_lending_item_mutation_response' }
    & Pick<AccountLendingItemMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'account_lending_item' }
      & AccountLendingItemFragment
    )> }
  )> }
);

export type GetAccountLendingsQueryVariables = Exact<{
  where?: Maybe<AccountLendingBoolExp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountLendingOrderBy> | AccountLendingOrderBy>;
}>;


export type GetAccountLendingsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'account_lending' }
    & { lending_items: Array<(
      { __typename?: 'account_lending_item' }
      & AccountLendingItemFragment
    )> }
    & AccountLendingFragment
  )> }
);

export type GetAccountLendingByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetAccountLendingByPkQuery = (
  { __typename?: 'query_root' }
  & { row?: Maybe<(
    { __typename?: 'account_lending' }
    & { lending_items: Array<(
      { __typename?: 'account_lending_item' }
      & AccountLendingItemFragment
    )> }
    & AccountLendingFragment
  )> }
);

export type GetAccountTransactionsOfLendingByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetAccountTransactionsOfLendingByPkQuery = (
  { __typename?: 'query_root' }
  & { transactions: Array<(
    { __typename?: 'account_transaction' }
    & AccountTransactionFragment
  )> }
);

export type PublicAttachmentFragment = (
  { __typename?: 'attachment' }
  & Pick<Attachment, 'deleted_at' | 'created_at' | 'created_by' | 'file_path' | 'filename' | 'id' | 'mimetype' | 'name' | 'original_name'>
);

export type GetPublicAttachmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPublicAttachmentsQuery = (
  { __typename?: 'query_root' }
  & { attachments: Array<(
    { __typename?: 'attachment' }
    & PublicAttachmentFragment
  )> }
);

export type InsertPublicAttachmentOneMutationVariables = Exact<{
  object: AttachmentInsertInput;
}>;


export type InsertPublicAttachmentOneMutation = (
  { __typename?: 'mutation_root' }
  & { row?: Maybe<(
    { __typename?: 'attachment' }
    & PublicAttachmentFragment
  )> }
);

export type DeletePublicAttachmentByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeletePublicAttachmentByPkMutation = (
  { __typename?: 'mutation_root' }
  & { row?: Maybe<(
    { __typename?: 'attachment' }
    & PublicAttachmentFragment
  )> }
);

export type PostFragment = (
  { __typename?: 'posts' }
  & Pick<Posts, 'created_at' | 'created_by' | 'description' | 'id' | 'key' | 'published' | 'title' | 'updated_at' | 'updated_by'>
);

export type GetPublicPostsQueryVariables = Exact<{
  distinctOn?: Maybe<Array<PostsSelectColumn> | PostsSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<PostsOrderBy> | PostsOrderBy>;
  where?: Maybe<PostsBoolExp>;
}>;


export type GetPublicPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & PostFragment
  )> }
);

export type InsertPublicPostOneMutationVariables = Exact<{
  object: PostsInsertInput;
}>;


export type InsertPublicPostOneMutation = (
  { __typename?: 'mutation_root' }
  & { row?: Maybe<(
    { __typename?: 'posts' }
    & PostFragment
  )> }
);

export type UpdatePublicPostByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: Maybe<PostsSetInput>;
}>;


export type UpdatePublicPostByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_posts_by_pk?: Maybe<(
    { __typename?: 'posts' }
    & PostFragment
  )> }
);

export type DeletePublicPostByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeletePublicPostByPkMutation = (
  { __typename?: 'mutation_root' }
  & { row?: Maybe<(
    { __typename?: 'posts' }
    & PostFragment
  )> }
);

export type BankBranchFragment = (
  { __typename?: 'bank_branch' }
  & Pick<BankBranch, 'branch' | 'id' | 'note' | 'province'>
);

export type UpdateBankBranchByPkMutationVariables = Exact<{
  id: Scalars['String'];
  _set?: Maybe<BankBranchSetInput>;
}>;


export type UpdateBankBranchByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_bank_branch_by_pk?: Maybe<(
    { __typename?: 'bank_branch' }
    & BankBranchFragment
  )> }
);

export type InsertBankBranchOneMutationVariables = Exact<{
  object: BankBranchInsertInput;
}>;


export type InsertBankBranchOneMutation = (
  { __typename?: 'mutation_root' }
  & { insert_bank_branch_one?: Maybe<(
    { __typename?: 'bank_branch' }
    & BankBranchFragment
  )> }
);

export type GetBankBranchesQueryVariables = Exact<{
  distinctOn?: Maybe<Array<BankBranchSelectColumn> | BankBranchSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BankBranchOrderBy> | BankBranchOrderBy>;
  where?: Maybe<BankBranchBoolExp>;
}>;


export type GetBankBranchesQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'bank_branch' }
    & BankBranchFragment
  )> }
);

export type GetBankBranchByPkQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetBankBranchByPkQuery = (
  { __typename?: 'query_root' }
  & { entity?: Maybe<(
    { __typename?: 'bank_branch' }
    & BankBranchFragment
  )> }
);

export type BudgetTypeFragment = (
  { __typename?: 'account_budget_type' }
  & Pick<AccountBudgetType, 'id' | 'description' | 'sequence' | 'deleted_at' | 'fiscal_year' | 'account_id' | 'closed' | 'closed_at' | 'group_id' | 'group_name'>
  & { account?: Maybe<(
    { __typename?: 'account_account' }
    & Pick<AccountAccount, 'id' | 'code' | 'name'>
  )> }
);

export type BudgetAccountFragment = (
  { __typename?: 'account_budget_account' }
  & Pick<AccountBudgetAccount, 'budget_id'>
  & { budget_type?: Maybe<(
    { __typename?: 'account_budget_type' }
    & BudgetTypeFragment
  )>, account: (
    { __typename?: 'account_account' }
    & Pick<AccountAccount, 'id' | 'type_id' | 'name' | 'code' | 'deleted_at'>
    & { account_type?: Maybe<(
      { __typename?: 'account_account_type' }
      & Pick<AccountAccountType, 'id' | 'description'>
    )> }
  ) }
);

export type UpdateBudgetTypeByPkMutationVariables = Exact<{
  id: Scalars['String'];
  fiscal_year: Scalars['Int'];
  _set?: Maybe<AccountBudgetTypeSetInput>;
}>;


export type UpdateBudgetTypeByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_account_budget_type_by_pk?: Maybe<(
    { __typename?: 'account_budget_type' }
    & BudgetTypeFragment
  )> }
);

export type InsertBudgetTypeOneMutationVariables = Exact<{
  object: AccountBudgetTypeInsertInput;
}>;


export type InsertBudgetTypeOneMutation = (
  { __typename?: 'mutation_root' }
  & { insert_account_budget_type_one?: Maybe<(
    { __typename?: 'account_budget_type' }
    & BudgetTypeFragment
  )> }
);

export type InsertBudgetAllocationsMutationVariables = Exact<{
  objects: Array<AccountBudgetAllocationInsertInput> | AccountBudgetAllocationInsertInput;
}>;


export type InsertBudgetAllocationsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_account_budget_allocation?: Maybe<(
    { __typename?: 'account_budget_allocation_mutation_response' }
    & Pick<AccountBudgetAllocationMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'account_budget_allocation' }
      & Pick<AccountBudgetAllocation, 'id' | 'budget_total'>
    )> }
  )> }
);

export type ReplaceBudgetAccountsMutationVariables = Exact<{
  budgetId: Scalars['String'];
  fiscalYear: Scalars['Int'];
  selectedIds: Array<Scalars['uuid']> | Scalars['uuid'];
  budgetAccounts: Array<AccountBudgetAccountInsertInput> | AccountBudgetAccountInsertInput;
}>;


export type ReplaceBudgetAccountsMutation = (
  { __typename?: 'mutation_root' }
  & { deleted_rows?: Maybe<(
    { __typename?: 'account_budget_account_mutation_response' }
    & Pick<AccountBudgetAccountMutationResponse, 'affected_rows'>
  )>, insert_rows?: Maybe<(
    { __typename?: 'account_budget_account_mutation_response' }
    & Pick<AccountBudgetAccountMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'account_budget_account' }
      & Pick<AccountBudgetAccount, 'budget_id' | 'account_id' | 'fiscal_year'>
    )> }
  )> }
);

export type GetBudgetTypesQueryVariables = Exact<{
  distinctOn?: Maybe<Array<AccountBudgetTypeSelectColumn> | AccountBudgetTypeSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountBudgetTypeOrderBy> | AccountBudgetTypeOrderBy>;
  where?: Maybe<AccountBudgetTypeBoolExp>;
}>;


export type GetBudgetTypesQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'account_budget_type' }
    & { accounts_aggregate: (
      { __typename?: 'account_budget_account_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'account_budget_account_aggregate_fields' }
        & Pick<AccountBudgetAccountAggregateFields, 'count'>
      )> }
    ) }
    & BudgetTypeFragment
  )> }
);

export type GetBudgetTypeByPkQueryVariables = Exact<{
  id: Scalars['String'];
  fiscalYear: Scalars['Int'];
}>;


export type GetBudgetTypeByPkQuery = (
  { __typename?: 'query_root' }
  & { entity?: Maybe<(
    { __typename?: 'account_budget_type' }
    & BudgetTypeFragment
  )> }
);

export type GetAccountBudgetsByBudgetIdWithFiscalYearQueryVariables = Exact<{
  budgetId: Scalars['String'];
  fiscalYear: Scalars['Int'];
}>;


export type GetAccountBudgetsByBudgetIdWithFiscalYearQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'account_account' }
    & Pick<AccountAccount, 'id' | 'name' | 'code'>
    & { account_type?: Maybe<(
      { __typename?: 'account_account_type' }
      & Pick<AccountAccountType, 'id' | 'description'>
    )>, budgets: Array<(
      { __typename?: 'account_budget_account' }
      & Pick<AccountBudgetAccount, 'account_id' | 'budget_id'>
    )>, budgets_aggregate: (
      { __typename?: 'account_budget_account_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'account_budget_account_aggregate_fields' }
        & Pick<AccountBudgetAccountAggregateFields, 'count'>
      )> }
    ) }
  )> }
);

export type GetOrganizationsWithBudgetQueryVariables = Exact<{
  budgetId: Scalars['String'];
  fiscalYear: Scalars['Int'];
}>;


export type GetOrganizationsWithBudgetQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'organization' }
    & Pick<Organization, 'id' | 'name' | 'short_name' | 'reference'>
    & { budget_allocations: Array<(
      { __typename?: 'account_budget_allocation' }
      & Pick<AccountBudgetAllocation, 'id' | 'budget_type_id' | 'fiscal_year' | 'budget_total'>
    )> }
  )> }
);

export type GetContractAgreementTransactionsQueryVariables = Exact<{
  distinctOn?: Maybe<Array<ContractAgreementTransactionSelectColumn> | ContractAgreementTransactionSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ContractAgreementTransactionOrderBy> | ContractAgreementTransactionOrderBy>;
  where?: Maybe<ContractAgreementTransactionBoolExp>;
}>;


export type GetContractAgreementTransactionsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'contract_agreement_transaction' }
    & { agreement?: Maybe<(
      { __typename?: 'contract_agreement' }
      & Pick<ContractAgreement, 'juristic_name_th'>
    )> }
    & ContractAgreementTransactionFragment
  )> }
);

export type ContractAgreementRepayFragment = (
  { __typename?: 'contract_agreement_repay_current_month' }
  & Pick<ContractAgreementRepayCurrentMonth, 'contract_number' | 'register_number' | 'remaining_repay_day' | 'repay_date' | 'repay_day_of_month' | 'repay_status'>
  & { agreement?: Maybe<(
    { __typename?: 'contract_agreement' }
    & ContractAgreementFragment
  )> }
);

export type GetContractAgreementRepaysQueryVariables = Exact<{
  distinctOn?: Maybe<Array<ContractAgreementRepayCurrentMonthSelectColumn> | ContractAgreementRepayCurrentMonthSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ContractAgreementRepayCurrentMonthOrderBy> | ContractAgreementRepayCurrentMonthOrderBy>;
  where?: Maybe<ContractAgreementRepayCurrentMonthBoolExp>;
}>;


export type GetContractAgreementRepaysQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'contract_agreement_repay_current_month' }
    & ContractAgreementRepayFragment
  )> }
);

export type ContractAgreementFragment = (
  { __typename?: 'contract_agreement' }
  & Pick<ContractAgreement, 'address' | 'af_number' | 'af_status' | 'af_status_description' | 'insurance_due_date' | 'af_status_name' | 'repay_day_of_month' | 'approved_date' | 'bank_branch_id' | 'cf_number' | 'business_group' | 'business_type' | 'contact_date' | 'contact_email' | 'contact_fax' | 'contact_first_name' | 'contact_last_name' | 'contact_tel' | 'contact_mobile' | 'contract_number' | 'created_by' | 'created_at' | 'credit_total' | 'customer_type' | 'd_damt' | 'effective_date' | 'first_repay_date' | 'first_withdraw_date' | 'grace_period' | 'id' | 'imported_at' | 'juristic_id' | 'juristic_name_en' | 'juristic_name_th' | 'loan_description' | 'monthly_payment' | 'objective' | 'period' | 'program_id' | 'program_name' | 'program_reference' | 'project_description' | 'register_number' | 'updated_by' | 'guarantor_name' | 'guarantor_citizen_id' | 'guarantor_description'>
  & { bank_branch?: Maybe<(
    { __typename?: 'bank_branch' }
    & Pick<BankBranch, 'id' | 'branch' | 'province'>
  )>, program: (
    { __typename?: 'loan_program' }
    & Pick<LoanProgram, 'name'>
  ), transactions_aggregate: (
    { __typename?: 'contract_agreement_transaction_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'contract_agreement_transaction_aggregate_fields' }
      & { sum?: Maybe<(
        { __typename?: 'contract_agreement_transaction_sum_fields' }
        & Pick<ContractAgreementTransactionSumFields, 'interest_amount'>
      )> }
    )> }
  ) }
);

export type ContractAgreementTransactionFragment = (
  { __typename?: 'contract_agreement_transaction' }
  & Pick<ContractAgreementTransaction, 'contract_number' | 'created_at' | 'creator_type' | 'credit_total' | 'default_rate' | 'due_date' | 'grade_value' | 'id' | 'imported_at' | 'interest_rate' | 'interest_rate_type' | 'last_repay_date' | 'outstanding_balance' | 'repay_day_of_month' | 'user_id' | 'withdraw_date' | 'accru_total' | 'aging_grade' | 'aging_outstanding_period' | 'approved_date' | 'ar_code' | 'bank_branch_officer' | 'bot_code' | 'capital_npl' | 'capital_outstanding' | 'commercial_credit_department' | 'commercial_credit_department_officer' | 'credit_amount' | 'debt_restructuring_department' | 'debt_restructuring_department_officer' | 'debt_type' | 'document_department' | 'document_department_officer' | 'effective_date' | 'fine_accru' | 'fine_amount' | 'fine_outstanding' | 'first_repay_date' | 'fully_repay_flag' | 'grade_gl' | 'industry' | 'insurance_interest' | 'interest_accru' | 'interest_amount' | 'interest_outstanding' | 'lawsuit_department' | 'lawsuit_department_officer' | 'monthly_payment' | 'non_recognition_interest' | 'old_ref' | 'out_insurance' | 'restructuring_first_repay_date' | 'restructuring_effective_date' | 'restructuring_approved_date' | 'outstanding_capital_start_date' | 'outstanding_interest_start_date' | 'outstanding_period' | 'outstanding_total' | 'period' | 'previous_outstanding_balance' | 'project_reference' | 'register_number' | 'repay_balance' | 'recognition_interest' | 'status' | 'suspended' | 'suspened_interest' | 'suspened_non_recognition_interest' | 'term_overdue_gl' | 'suspened_recognition_interest' | 'responsible_organization' | 'spread_rate' | 'cluster' | 'res_n'>
  & { user?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'display_name'>
  )> }
);

export type ContractAgreementAttachmentFragment = (
  { __typename?: 'contract_agreement_attachment' }
  & Pick<ContractAgreementAttachment, 'agreement_id' | 'updated_by' | 'updated_at' | 'type_id' | 'size' | 'original_name' | 'note' | 'mimetype' | 'file_path' | 'id' | 'filename' | 'deleted_at' | 'created_by' | 'created_at'>
  & { attachment_type: (
    { __typename?: 'contract_attachment_type' }
    & Pick<ContractAttachmentType, 'id' | 'description'>
  ) }
);

export type UpdateContractAgreementByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: Maybe<ContractAgreementSetInput>;
}>;


export type UpdateContractAgreementByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_contract_agreement_by_pk?: Maybe<(
    { __typename?: 'contract_agreement' }
    & ContractAgreementFragment
  )> }
);

export type InsertContractAgreementAttachmentOneMutationVariables = Exact<{
  object: ContractAgreementAttachmentInsertInput;
}>;


export type InsertContractAgreementAttachmentOneMutation = (
  { __typename?: 'mutation_root' }
  & { insert_contract_agreement_attachment_one?: Maybe<(
    { __typename?: 'contract_agreement_attachment' }
    & ContractAgreementAttachmentFragment
  )> }
);

export type ReplaceContractAgreementFeeChargesMutationVariables = Exact<{
  contract_number: Scalars['String'];
  register_number: Scalars['String'];
  insertedIds: Array<Scalars['uuid']> | Scalars['uuid'];
  insertedData: Array<ContractAgreementFeeChargeInsertInput> | ContractAgreementFeeChargeInsertInput;
}>;


export type ReplaceContractAgreementFeeChargesMutation = (
  { __typename?: 'mutation_root' }
  & { deleted_rows?: Maybe<(
    { __typename?: 'contract_agreement_fee_charge_mutation_response' }
    & Pick<ContractAgreementFeeChargeMutationResponse, 'affected_rows'>
  )>, inserted_rows?: Maybe<(
    { __typename?: 'contract_agreement_fee_charge_mutation_response' }
    & Pick<ContractAgreementFeeChargeMutationResponse, 'affected_rows'>
  )> }
);

export type GetContractAgreementsQueryVariables = Exact<{
  distinctOn?: Maybe<Array<ContractAgreementSelectColumn> | ContractAgreementSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ContractAgreementOrderBy> | ContractAgreementOrderBy>;
  where?: Maybe<ContractAgreementBoolExp>;
}>;


export type GetContractAgreementsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'contract_agreement' }
    & ContractAgreementFragment
  )> }
);

export type GetContractAgreementByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetContractAgreementByPkQuery = (
  { __typename?: 'query_root' }
  & { entity?: Maybe<(
    { __typename?: 'contract_agreement' }
    & ContractAgreementFragment
  )> }
);

export type GetContractAgreementTransactionByIdQueryVariables = Exact<{
  registerNumber: Scalars['String'];
  contractNumber: Scalars['String'];
}>;


export type GetContractAgreementTransactionByIdQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'contract_agreement_transaction' }
    & ContractAgreementTransactionFragment
  )> }
);

export type GetContractAgreementFeeChargesQueryVariables = Exact<{
  distinctOn?: Maybe<Array<ContractAgreementFeeChargeSelectColumn> | ContractAgreementFeeChargeSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ContractAgreementFeeChargeOrderBy> | ContractAgreementFeeChargeOrderBy>;
  where?: Maybe<ContractAgreementFeeChargeBoolExp>;
}>;


export type GetContractAgreementFeeChargesQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'contract_agreement_fee_charge' }
    & Pick<ContractAgreementFeeCharge, 'amount' | 'contract_number' | 'created_at' | 'date' | 'description' | 'id' | 'register_number' | 'updated_at'>
  )> }
);

export type GetContractAgreementAttachmentsQueryVariables = Exact<{
  distinctOn?: Maybe<Array<ContractAgreementAttachmentSelectColumn> | ContractAgreementAttachmentSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ContractAgreementAttachmentOrderBy> | ContractAgreementAttachmentOrderBy>;
  where?: Maybe<ContractAgreementAttachmentBoolExp>;
}>;


export type GetContractAgreementAttachmentsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'contract_agreement_attachment' }
    & ContractAgreementAttachmentFragment
  )> }
);

export type GetAfStatusesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAfStatusesQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'contract_af_status' }
    & Pick<ContractAfStatus, 'id' | 'description'>
  )> }
);

export type GetContractAgreementStatusReportQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContractAgreementStatusReportQuery = (
  { __typename?: 'query_root' }
  & { status_rows: Array<(
    { __typename?: 'contract_agreement_af_status_count' }
    & Pick<ContractAgreementAfStatusCount, 'af_status_id' | 'af_status_description' | 'bank_program_reference' | 'count' | 'program_id'>
    & { program?: Maybe<(
      { __typename?: 'loan_program' }
      & Pick<LoanProgram, 'name'>
    )> }
  )>, unique_contract_agreement_count: (
    { __typename?: 'contract_agreement_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'contract_agreement_aggregate_fields' }
      & Pick<ContractAgreementAggregateFields, 'count'>
    )> }
  ), all_ccontract_agreement_count: (
    { __typename?: 'contract_agreement_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'contract_agreement_aggregate_fields' }
      & Pick<ContractAgreementAggregateFields, 'count'>
    )> }
  ), all_contract_detail_count: (
    { __typename?: 'contract_agreement_detail_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'contract_agreement_detail_aggregate_fields' }
      & Pick<ContractAgreementDetailAggregateFields, 'count'>
    )> }
  ), all_contract_transaction_count: (
    { __typename?: 'contract_agreement_transaction_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'contract_agreement_transaction_aggregate_fields' }
      & Pick<ContractAgreementTransactionAggregateFields, 'count'>
    )> }
  ) }
);

export type ContractAgreementDetailFragment = (
  { __typename?: 'contract_agreement_detail' }
  & Pick<ContractAgreementDetail, 'af_number' | 'af_status' | 'af_status_description' | 'approved_date' | 'cf_number' | 'business_type' | 'contact_date' | 'withdraw_total' | 'contract_number' | 'credit_total' | 'effective_date' | 'first_withdraw_date' | 'id' | 'period' | 'register_number' | 'credit_balance' | 'request_date' | 'need_credit' | 'isic_description' | 'interest_description' | 'fl' | 'closing_date' | 'created_at' | 'imported_at'>
  & { agreement?: Maybe<(
    { __typename?: 'contract_agreement' }
    & Pick<ContractAgreement, 'juristic_id' | 'juristic_name_th'>
  )> }
);

export type UpdateContractAgreementDetailByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: Maybe<ContractAgreementDetailSetInput>;
}>;


export type UpdateContractAgreementDetailByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_contract_agreement_detail_by_pk?: Maybe<(
    { __typename?: 'contract_agreement_detail' }
    & ContractAgreementDetailFragment
  )> }
);

export type GetContractAgreementDetailsQueryVariables = Exact<{
  distinctOn?: Maybe<Array<ContractAgreementDetailSelectColumn> | ContractAgreementDetailSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ContractAgreementDetailOrderBy> | ContractAgreementDetailOrderBy>;
  where?: Maybe<ContractAgreementDetailBoolExp>;
}>;


export type GetContractAgreementDetailsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'contract_agreement_detail' }
    & ContractAgreementDetailFragment
  )> }
);

export type GetContractAgreementDetailByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetContractAgreementDetailByPkQuery = (
  { __typename?: 'query_root' }
  & { entity?: Maybe<(
    { __typename?: 'contract_agreement_detail' }
    & ContractAgreementDetailFragment
  )> }
);

export type ContractAgreementReceiptFragment = (
  { __typename?: 'contract_agreement_receipt' }
  & Pick<ContractAgreementReceipt, 'receipt_no' | 'contract_number' | 'register_number' | 'created_at' | 'imported_at' | 'amount' | 'created_by' | 'updated_by' | 'updated_at' | 'juristic_name' | 'status' | 'report_date' | 'previous_outstanding_balance' | 'outstanding_balance' | 'other_income' | 'last_repay_date' | 'interest_amount' | 'fine_amount' | 'credit_amount'>
  & { id: ContractAgreementReceipt['receipt_no'] }
  & { agreement?: Maybe<(
    { __typename?: 'contract_agreement' }
    & Pick<ContractAgreement, 'juristic_name_th' | 'juristic_id' | 'id'>
  )> }
);

export type GetContractAgreementReceiptsQueryVariables = Exact<{
  distinctOn?: Maybe<Array<ContractAgreementReceiptSelectColumn> | ContractAgreementReceiptSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ContractAgreementReceiptOrderBy> | ContractAgreementReceiptOrderBy>;
  where?: Maybe<ContractAgreementReceiptBoolExp>;
}>;


export type GetContractAgreementReceiptsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'contract_agreement_receipt' }
    & ContractAgreementReceiptFragment
  )> }
);

export type GetContractAgreementReceiptByPkQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetContractAgreementReceiptByPkQuery = (
  { __typename?: 'query_root' }
  & { entity?: Maybe<(
    { __typename?: 'contract_agreement_receipt' }
    & ContractAgreementReceiptFragment
  )> }
);

export type ContractAgreementWithdrawFragment = (
  { __typename?: 'contract_agreement_withdraw' }
  & Pick<ContractAgreementWithdraw, 'contract_number' | 'credit_total' | 'register_number' | 'credit_balance' | 'request_date' | 'created_at' | 'imported_at' | 'amount' | 'bank_branch_id' | 'bank_branch_name' | 'created_by' | 'withdraw_date' | 'updated_by' | 'updated_at' | 'program_reference' | 'program_id' | 'program_name' | 'juristic_name' | 'draw_down_no'>
  & { id: ContractAgreementWithdraw['draw_down_no'] }
  & { agreement?: Maybe<(
    { __typename?: 'contract_agreement' }
    & Pick<ContractAgreement, 'juristic_name_th' | 'juristic_id' | 'id'>
  )>, program?: Maybe<(
    { __typename?: 'loan_program' }
    & Pick<LoanProgram, 'id' | 'name' | 'reference'>
  )> }
);

export type GetContractAgreementWithdrawsQueryVariables = Exact<{
  distinctOn?: Maybe<Array<ContractAgreementWithdrawSelectColumn> | ContractAgreementWithdrawSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ContractAgreementWithdrawOrderBy> | ContractAgreementWithdrawOrderBy>;
  where?: Maybe<ContractAgreementWithdrawBoolExp>;
}>;


export type GetContractAgreementWithdrawsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'contract_agreement_withdraw' }
    & ContractAgreementWithdrawFragment
  )> }
);

export type GetContractAgreementWithdrawByPkQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetContractAgreementWithdrawByPkQuery = (
  { __typename?: 'query_root' }
  & { entity?: Maybe<(
    { __typename?: 'contract_agreement_withdraw' }
    & ContractAgreementWithdrawFragment
  )> }
);

export type GetMatrixReportByOrganizationIdQueryVariables = Exact<{
  fiscalYear: Scalars['Int'];
  organizationId?: Maybe<Scalars['uuid']>;
}>;


export type GetMatrixReportByOrganizationIdQuery = (
  { __typename?: 'query_root' }
  & { budget_rows: Array<(
    { __typename?: 'account_budget_type' }
    & Pick<AccountBudgetType, 'id' | 'description' | 'sequence' | 'deleted_at' | 'fiscal_year' | 'account_id'>
  )>, account_rows: Array<(
    { __typename?: 'account_account' }
    & Pick<AccountAccount, 'id' | 'code' | 'name'>
    & { account_type?: Maybe<(
      { __typename?: 'account_account_type' }
      & Pick<AccountAccountType, 'id' | 'description'>
    )> }
  )>, account_balance_sheet_rows: Array<(
    { __typename?: 'account_account_balance_sheet' }
    & Pick<AccountAccountBalanceSheet, 'balance' | 'budget_id' | 'fiscal_year' | 'id' | 'name' | 'organization_id'>
  )> }
);

export type GetMonthlyReportByOrganizationIdQueryVariables = Exact<{
  fiscalYear: Scalars['Int'];
  organizationId?: Maybe<Scalars['uuid']>;
}>;


export type GetMonthlyReportByOrganizationIdQuery = (
  { __typename?: 'query_root' }
  & { budget_rows: Array<(
    { __typename?: 'account_budget_type' }
    & Pick<AccountBudgetType, 'id' | 'description' | 'sequence' | 'deleted_at' | 'fiscal_year' | 'account_id'>
  )>, account_rows: Array<(
    { __typename?: 'account_account' }
    & Pick<AccountAccount, 'id' | 'code' | 'name'>
    & { account_type?: Maybe<(
      { __typename?: 'account_account_type' }
      & Pick<AccountAccountType, 'id' | 'description'>
    )> }
  )>, account_monthly_report_rows: Array<(
    { __typename?: 'account_account_monthly_report' }
    & Pick<AccountAccountMonthlyReport, 'october' | 'november' | 'december' | 'january' | 'february' | 'march' | 'april' | 'may' | 'june' | 'july' | 'august' | 'september' | 'budget_id' | 'fiscal_year' | 'id' | 'name' | 'organization_id'>
  )> }
);

export type AccountLedgerEntryFragment = (
  { __typename?: 'account_ledger_entry' }
  & Pick<AccountLedgerEntry, 'account_id' | 'amount' | 'budget_id' | 'created_at' | 'date' | 'entry_type' | 'fiscal_year' | 'id' | 'gfmis_id' | 'name' | 'note' | 'organization_id' | 'reference' | 'transaction_id' | 'created_by'>
  & { organization?: Maybe<(
    { __typename?: 'organization' }
    & Pick<Organization, 'id' | 'name' | 'short_name'>
  )>, entry_type_node: (
    { __typename?: 'account_entry_type' }
    & Pick<AccountEntryType, 'id' | 'description'>
  ), budget_type?: Maybe<(
    { __typename?: 'account_budget_type' }
    & Pick<AccountBudgetType, 'id' | 'description' | 'fiscal_year'>
  )>, account: (
    { __typename?: 'account_account' }
    & Pick<AccountAccount, 'code' | 'name' | 'id'>
    & { account_type?: Maybe<(
      { __typename?: 'account_account_type' }
      & Pick<AccountAccountType, 'description' | 'id'>
    )> }
  ) }
);

export type AccountTransactionFragment = (
  { __typename?: 'account_transaction' }
  & Pick<AccountTransaction, 'id' | 'gfmis_id' | 'organization_id' | 'status_id' | 'approved_at' | 'approved_by' | 'cancelled_at' | 'cancelled_by' | 'change_requested_at' | 'change_requested_by' | 'date' | 'rejected_at' | 'rejected_by' | 'reference' | 'fiscal_year' | 'description' | 'created_at' | 'created_by' | 'lending_id' | 'is_allocation'>
  & { transaction_type: (
    { __typename?: 'account_transaction_type' }
    & Pick<AccountTransactionType, 'id' | 'description'>
  ), created_by_user?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'display_name'>
  )>, transaction_status: (
    { __typename?: 'account_transaction_status' }
    & Pick<AccountTransactionStatus, 'id' | 'description'>
  ), organization?: Maybe<(
    { __typename?: 'organization' }
    & Pick<Organization, 'name' | 'short_name' | 'area_code' | 'unit_code' | 'reference'>
  )> }
);

export type AccountTransactionLogFragment = (
  { __typename?: 'account_transaction_log' }
  & Pick<AccountTransactionLog, 'id' | 'description' | 'comment' | 'created_by' | 'created_at' | 'new_status' | 'old_status' | 'transaction_id'>
  & { transaction_status_new?: Maybe<(
    { __typename?: 'account_transaction_status' }
    & Pick<AccountTransactionStatus, 'id' | 'description'>
  )>, transaction_status_old?: Maybe<(
    { __typename?: 'account_transaction_status' }
    & Pick<AccountTransactionStatus, 'id' | 'description'>
  )>, created_by_user: (
    { __typename?: 'users' }
    & Pick<Users, 'display_name' | 'first_name' | 'last_name' | 'reference'>
    & { organization?: Maybe<(
      { __typename?: 'organization' }
      & Pick<Organization, 'short_name'>
    )> }
  ) }
);

export type InsertAccountTransactionOneMutationVariables = Exact<{
  object: AccountTransactionInsertInput;
}>;


export type InsertAccountTransactionOneMutation = (
  { __typename?: 'mutation_root' }
  & { insert_account_transaction_one?: Maybe<(
    { __typename?: 'account_transaction' }
    & Pick<AccountTransaction, 'id' | 'description' | 'created_at' | 'created_by' | 'date' | 'organization_id'>
    & { organization?: Maybe<(
      { __typename?: 'organization' }
      & Pick<Organization, 'name' | 'short_name'>
    )> }
  )> }
);

export type ReplaceAccountTransactionOneMutationVariables = Exact<{
  transactionId: Scalars['uuid'];
  selectedLedgerIds: Array<Scalars['uuid']> | Scalars['uuid'];
  insertTransaction: AccountTransactionInsertInput;
  transactionOnConflict?: Maybe<AccountTransactionOnConflict>;
}>;


export type ReplaceAccountTransactionOneMutation = (
  { __typename?: 'mutation_root' }
  & { delete_rows?: Maybe<(
    { __typename?: 'account_ledger_entry_mutation_response' }
    & Pick<AccountLedgerEntryMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'account_ledger_entry' }
      & Pick<AccountLedgerEntry, 'id'>
    )> }
  )>, insert_row?: Maybe<(
    { __typename?: 'account_transaction' }
    & Pick<AccountTransaction, 'id' | 'description' | 'created_at' | 'created_by' | 'date' | 'organization_id'>
    & { organization?: Maybe<(
      { __typename?: 'organization' }
      & Pick<Organization, 'name' | 'short_name'>
    )> }
  )> }
);

export type ApproveAccountTransactionMutationVariables = Exact<{
  data: AccountTransactionApprovalInput;
}>;


export type ApproveAccountTransactionMutation = (
  { __typename?: 'mutation_root' }
  & { account_transaction_approve: (
    { __typename?: 'AccountTransactionApprovalOutput' }
    & Pick<AccountTransactionApprovalOutput, 'transactionId'>
  ) }
);

export type DuplicateAccountBudgetByFiscalYearMutationVariables = Exact<{
  data: AccountBudgetDuplicateByFiscalYearInput;
}>;


export type DuplicateAccountBudgetByFiscalYearMutation = (
  { __typename?: 'mutation_root' }
  & { account_budget: (
    { __typename?: 'AccountBudgetDuplicateByFiscalYearOutput' }
    & Pick<AccountBudgetDuplicateByFiscalYearOutput, 'budgetIds' | 'fiscalYear'>
  ) }
);

export type GetAccountTransactionsQueryVariables = Exact<{
  distinctOn?: Maybe<Array<AccountTransactionSelectColumn> | AccountTransactionSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountTransactionOrderBy> | AccountTransactionOrderBy>;
  where?: Maybe<AccountTransactionBoolExp>;
}>;


export type GetAccountTransactionsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'account_transaction' }
    & { ledger_entries: Array<(
      { __typename?: 'account_ledger_entry' }
      & AccountLedgerEntryFragment
    )>, credit_total: (
      { __typename?: 'account_ledger_entry_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'account_ledger_entry_aggregate_fields' }
        & { sum?: Maybe<(
          { __typename?: 'account_ledger_entry_sum_fields' }
          & Pick<AccountLedgerEntrySumFields, 'amount'>
        )> }
      )> }
    ), debit_total: (
      { __typename?: 'account_ledger_entry_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'account_ledger_entry_aggregate_fields' }
        & { sum?: Maybe<(
          { __typename?: 'account_ledger_entry_sum_fields' }
          & Pick<AccountLedgerEntrySumFields, 'amount'>
        )> }
      )> }
    ) }
    & AccountTransactionFragment
  )> }
);

export type GetAccountTransactionByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetAccountTransactionByPkQuery = (
  { __typename?: 'query_root' }
  & { row?: Maybe<(
    { __typename?: 'account_transaction' }
    & { ledger_entries: Array<(
      { __typename?: 'account_ledger_entry' }
      & AccountLedgerEntryFragment
    )> }
    & AccountTransactionFragment
  )> }
);

export type GetAccountBudgetAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountBudgetAccountsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'account_budget_account' }
    & Pick<AccountBudgetAccount, 'account_id' | 'budget_id' | 'fiscal_year'>
    & { account: (
      { __typename?: 'account_account' }
      & Pick<AccountAccount, 'id' | 'code' | 'name'>
      & { account_type?: Maybe<(
        { __typename?: 'account_account_type' }
        & Pick<AccountAccountType, 'id' | 'description'>
      )> }
    ), budget_type?: Maybe<(
      { __typename?: 'account_budget_type' }
      & Pick<AccountBudgetType, 'id' | 'description' | 'fiscal_year'>
    )> }
  )> }
);

export type GetAccountLedgerEntriesQueryVariables = Exact<{
  distinctOn?: Maybe<Array<AccountLedgerEntrySelectColumn> | AccountLedgerEntrySelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountLedgerEntryOrderBy> | AccountLedgerEntryOrderBy>;
  where?: Maybe<AccountLedgerEntryBoolExp>;
}>;


export type GetAccountLedgerEntriesQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'account_ledger_entry' }
    & { transaction?: Maybe<(
      { __typename?: 'account_transaction' }
      & AccountTransactionFragment
    )> }
    & AccountLedgerEntryFragment
  )> }
);

export type GetAccountTransactionLogsByTransactionPkQueryVariables = Exact<{
  transactionId: Scalars['uuid'];
}>;


export type GetAccountTransactionLogsByTransactionPkQuery = (
  { __typename?: 'query_root' }
  & { transaction_logs: Array<(
    { __typename?: 'account_transaction_log' }
    & AccountTransactionLogFragment
  )> }
);

export type ImportLogFragment = (
  { __typename?: 'import_log' }
  & Pick<ImportLog, 'id' | 'name' | 'filename' | 'user_id' | 'created_at'>
  & { user?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'display_name'>
  )> }
);

export type GetImportLogsQueryVariables = Exact<{
  distinctOn?: Maybe<Array<ImportLogSelectColumn> | ImportLogSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ImportLogOrderBy> | ImportLogOrderBy>;
  where?: Maybe<ImportLogBoolExp>;
}>;


export type GetImportLogsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'import_log' }
    & ImportLogFragment
  )> }
);

export type JuristicFragment = (
  { __typename?: 'juristic' }
  & Pick<Juristic, 'id' | 'juristic_status' | 'name_en' | 'name_th' | 'number_of_commitee' | 'number_of_objective' | 'paid_register_capital' | 'register_capital' | 'register_date' | 'type' | 'is_member'>
);

export type JuristicAddressFragment = (
  { __typename?: 'juristic_address' }
  & Pick<JuristicAddress, 'address_no' | 'amphoe' | 'building' | 'district' | 'email' | 'floor' | 'id' | 'moo' | 'phone' | 'province' | 'road' | 'room_no' | 'sequence' | 'soi' | 'village_name'>
);

export type JuristicCommitteeFragment = (
  { __typename?: 'juristic_committee' }
  & Pick<JuristicCommittee, 'citizen_id' | 'first_name' | 'id' | 'last_name' | 'sequence' | 'title'>
);

export type UpdateJuristicByPkMutationVariables = Exact<{
  id: Scalars['String'];
  _set?: Maybe<JuristicSetInput>;
}>;


export type UpdateJuristicByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_juristic_by_pk?: Maybe<(
    { __typename?: 'juristic' }
    & JuristicFragment
  )> }
);

export type GetJuristicsQueryVariables = Exact<{
  distinctOn?: Maybe<Array<JuristicSelectColumn> | JuristicSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<JuristicOrderBy> | JuristicOrderBy>;
  where?: Maybe<JuristicBoolExp>;
}>;


export type GetJuristicsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'juristic' }
    & JuristicFragment
  )> }
);

export type GetJuristicTypeSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetJuristicTypeSummaryQuery = (
  { __typename?: 'query_root' }
  & { total_count: (
    { __typename?: 'juristic_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'juristic_aggregate_fields' }
      & Pick<JuristicAggregateFields, 'count'>
    )> }
  ), incorporated_count: (
    { __typename?: 'juristic_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'juristic_aggregate_fields' }
      & Pick<JuristicAggregateFields, 'count'>
    )> }
  ), partnership_limited_count: (
    { __typename?: 'juristic_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'juristic_aggregate_fields' }
      & Pick<JuristicAggregateFields, 'count'>
    )> }
  ), company_limited_count: (
    { __typename?: 'juristic_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'juristic_aggregate_fields' }
      & Pick<JuristicAggregateFields, 'count'>
    )> }
  ), public_company_limited_count: (
    { __typename?: 'juristic_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'juristic_aggregate_fields' }
      & Pick<JuristicAggregateFields, 'count'>
    )> }
  ), undefined_count: (
    { __typename?: 'juristic_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'juristic_aggregate_fields' }
      & Pick<JuristicAggregateFields, 'count'>
    )> }
  ) }
);

export type GetJuristicByPkQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetJuristicByPkQuery = (
  { __typename?: 'query_root' }
  & { entity?: Maybe<(
    { __typename?: 'juristic' }
    & { juristic_addresses: Array<(
      { __typename?: 'juristic_address' }
      & JuristicAddressFragment
    )>, juristic_committees: Array<(
      { __typename?: 'juristic_committee' }
      & JuristicCommitteeFragment
    )> }
    & JuristicFragment
  )> }
);

export type GetJuristicCommitteesQueryVariables = Exact<{
  distinctOn?: Maybe<Array<JuristicCommitteeSelectColumn> | JuristicCommitteeSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<JuristicCommitteeOrderBy> | JuristicCommitteeOrderBy>;
  where?: Maybe<JuristicCommitteeBoolExp>;
}>;


export type GetJuristicCommitteesQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'juristic_committee' }
    & JuristicCommitteeFragment
  )> }
);

export type GetJuristicAddressesQueryVariables = Exact<{
  distinctOn?: Maybe<Array<JuristicAddressSelectColumn> | JuristicAddressSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<JuristicAddressOrderBy> | JuristicAddressOrderBy>;
  where?: Maybe<JuristicAddressBoolExp>;
}>;


export type GetJuristicAddressesQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'juristic_address' }
    & JuristicAddressFragment
  )> }
);

export type BudgetAllocationFragment = (
  { __typename?: 'ledger_budget_allocation' }
  & Pick<LedgerBudgetAllocation, 'id' | 'amount' | 'budget_id' | 'created_at' | 'created_by' | 'fiscal_year' | 'organization_id' | 'updated_at' | 'updated_by'>
);

export type GetLedgerBudgetAllocationsQueryVariables = Exact<{
  fiscalYear: Scalars['Int'];
}>;


export type GetLedgerBudgetAllocationsQuery = (
  { __typename?: 'query_root' }
  & { organizations: Array<(
    { __typename?: 'organization' }
    & OrganizationFragment
  )>, budgets: Array<(
    { __typename?: 'account_budget_type' }
    & Pick<AccountBudgetType, 'id' | 'description' | 'sequence' | 'fiscal_year' | 'closed' | 'closed_at'>
    & { allocations: Array<(
      { __typename?: 'ledger_budget_allocation' }
      & Pick<LedgerBudgetAllocation, 'id' | 'organization_id' | 'amount' | 'budget_id' | 'fiscal_year'>
    )> }
  )> }
);

export type InsertLedgerBudgetAllocationsMutationVariables = Exact<{
  objects: Array<LedgerBudgetAllocationInsertInput> | LedgerBudgetAllocationInsertInput;
}>;


export type InsertLedgerBudgetAllocationsMutation = (
  { __typename?: 'mutation_root' }
  & { budget_allocations?: Maybe<(
    { __typename?: 'ledger_budget_allocation_mutation_response' }
    & Pick<LedgerBudgetAllocationMutationResponse, 'affected_rows'>
  )> }
);

export type InsertLedgerBudgetAllocationOneMutationVariables = Exact<{
  object: LedgerBudgetAllocationInsertInput;
}>;


export type InsertLedgerBudgetAllocationOneMutation = (
  { __typename?: 'mutation_root' }
  & { budget_allocations?: Maybe<(
    { __typename?: 'ledger_budget_allocation' }
    & BudgetAllocationFragment
  )> }
);

export type UpdateLedgerBudgetAllocationByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type UpdateLedgerBudgetAllocationByPkMutation = (
  { __typename?: 'mutation_root' }
  & { budget_allocation?: Maybe<(
    { __typename?: 'ledger_budget_allocation' }
    & BudgetAllocationFragment
  )> }
);

export type GetLedgerMatrixReportByOrganizationIdQueryVariables = Exact<{
  fiscalYear: Scalars['Int'];
  organizationId?: Maybe<Scalars['uuid']>;
}>;


export type GetLedgerMatrixReportByOrganizationIdQuery = (
  { __typename?: 'query_root' }
  & { budget_rows: Array<(
    { __typename?: 'account_budget_type' }
    & Pick<AccountBudgetType, 'id' | 'description' | 'sequence' | 'deleted_at' | 'fiscal_year' | 'account_id' | 'group_id' | 'group_name'>
    & { allocations: Array<(
      { __typename?: 'ledger_budget_allocation' }
      & Pick<LedgerBudgetAllocation, 'id' | 'budget_id' | 'organization_id' | 'amount' | 'fiscal_year'>
    )> }
  )>, account_rows: Array<(
    { __typename?: 'account_account' }
    & Pick<AccountAccount, 'id' | 'code' | 'name'>
    & { account_type?: Maybe<(
      { __typename?: 'account_account_type' }
      & Pick<AccountAccountType, 'id' | 'description'>
    )> }
  )>, debt_account_budget_balance_rows: Array<(
    { __typename?: 'ledger_account_budget_balance_debt' }
    & Pick<LedgerAccountBudgetBalanceDebt, 'balance' | 'budget_id' | 'fiscal_year' | 'account_id' | 'organization_id' | 'budget_total' | 'budget_balance' | 'budget_group_id' | 'budget_group_name'>
  )>, expense_account_budget_balance_rows: Array<(
    { __typename?: 'ledger_account_budget_balance_expense' }
    & Pick<LedgerAccountBudgetBalanceExpense, 'balance' | 'budget_id' | 'fiscal_year' | 'account_id' | 'organization_id' | 'budget_total' | 'budget_balance' | 'budget_group_id' | 'budget_group_name'>
  )> }
);

export type GetLedgerMonthlyReportByOrganizationIdQueryVariables = Exact<{
  fiscalYear: Scalars['Int'];
  organizationId?: Maybe<Scalars['uuid']>;
}>;


export type GetLedgerMonthlyReportByOrganizationIdQuery = (
  { __typename?: 'query_root' }
  & { budget_rows: Array<(
    { __typename?: 'account_budget_type' }
    & Pick<AccountBudgetType, 'id' | 'description' | 'sequence' | 'deleted_at' | 'fiscal_year' | 'account_id'>
  )>, account_rows: Array<(
    { __typename?: 'account_account' }
    & Pick<AccountAccount, 'id' | 'code' | 'name'>
    & { account_type?: Maybe<(
      { __typename?: 'account_account_type' }
      & Pick<AccountAccountType, 'id' | 'description'>
    )> }
  )>, ledger_account_budget_monthly_rows: Array<(
    { __typename?: 'ledger_account_budget_monthly' }
    & Pick<LedgerAccountBudgetMonthly, 'october' | 'november' | 'december' | 'january' | 'february' | 'march' | 'april' | 'may' | 'june' | 'july' | 'august' | 'september' | 'budget_id' | 'fiscal_year' | 'id' | 'name' | 'organization_id'>
  )> }
);

export type ReceivableItemFragment = (
  { __typename?: 'ledger_receivable_item' }
  & Pick<LedgerReceivableItem, 'id' | 'amount' | 'created_at' | 'created_by' | 'date' | 'locked_at' | 'locked_by' | 'name' | 'note' | 'updated_by' | 'updated_at' | 'receivable_id'>
);

export type GetReceivableItemsQueryVariables = Exact<{
  distinctOn?: Maybe<Array<LedgerReceivableItemSelectColumn> | LedgerReceivableItemSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<LedgerReceivableItemOrderBy> | LedgerReceivableItemOrderBy>;
  where?: Maybe<LedgerReceivableItemBoolExp>;
}>;


export type GetReceivableItemsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'ledger_receivable_item' }
    & ReceivableItemFragment
  )> }
);

export type GetReceivableItemByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetReceivableItemByPkQuery = (
  { __typename?: 'query_root' }
  & { row?: Maybe<(
    { __typename?: 'ledger_receivable_item' }
    & ReceivableItemFragment
  )> }
);

export type InsertReceivableItemOneMutationVariables = Exact<{
  object: LedgerReceivableItemInsertInput;
}>;


export type InsertReceivableItemOneMutation = (
  { __typename?: 'mutation_root' }
  & { insert_ledger_receivable_item_one?: Maybe<(
    { __typename?: 'ledger_receivable_item' }
    & ReceivableItemFragment
  )> }
);

export type UpdateReceivableItemByPkMutationVariables = Exact<{
  _set?: Maybe<LedgerReceivableItemSetInput>;
  id: Scalars['uuid'];
}>;


export type UpdateReceivableItemByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_ledger_receivable_item_by_pk?: Maybe<(
    { __typename?: 'ledger_receivable_item' }
    & ReceivableItemFragment
  )> }
);

export type DeleteReceivableItemByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteReceivableItemByPkMutation = (
  { __typename?: 'mutation_root' }
  & { delete_ledger_receivable_item_by_pk?: Maybe<(
    { __typename?: 'ledger_receivable_item' }
    & ReceivableItemFragment
  )> }
);

export type ReplaceReceivableItemsMutationVariables = Exact<{
  receivableId: Scalars['uuid'];
  itemIds: Array<Scalars['uuid']> | Scalars['uuid'];
  insert_receivable_items: Array<LedgerReceivableItemInsertInput> | LedgerReceivableItemInsertInput;
}>;


export type ReplaceReceivableItemsMutation = (
  { __typename?: 'mutation_root' }
  & { deleted_ledger_receivables?: Maybe<(
    { __typename?: 'ledger_receivable_item_mutation_response' }
    & Pick<LedgerReceivableItemMutationResponse, 'affected_rows'>
  )>, ledger_receivables?: Maybe<(
    { __typename?: 'ledger_receivable_item_mutation_response' }
    & Pick<LedgerReceivableItemMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'ledger_receivable_item' }
      & ReceivableItemFragment
    )> }
  )> }
);

export type ReceivableFragment = (
  { __typename?: 'ledger_receivable' }
  & Pick<LedgerReceivable, 'id' | 'amount' | 'approved_at' | 'approved_by' | 'borrower_name' | 'created_at' | 'created_by' | 'date' | 'due_date' | 'gfmis_id' | 'locked_at' | 'locked_by' | 'note' | 'refund_amount' | 'organization_id' | 'proposal' | 'received_date' | 'reference' | 'submitted_at' | 'submitted_by' | 'updated_at' | 'updated_by' | 'done_at' | 'status_id' | 'done_by' | 'fiscal_year' | 'budget_id' | 'expense_account_id'>
  & { organization: (
    { __typename?: 'organization' }
    & Pick<Organization, 'id' | 'name' | 'province' | 'short_name' | 'reference'>
  ), status: (
    { __typename?: 'ledger_receivable_status' }
    & Pick<LedgerReceivableStatus, 'id' | 'description'>
  ), created_by_user?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'display_name'>
  )>, updated_by_user?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'display_name'>
  )>, budget?: Maybe<(
    { __typename?: 'account_budget_type' }
    & Pick<AccountBudgetType, 'id' | 'fiscal_year' | 'description'>
  )>, expense_account?: Maybe<(
    { __typename?: 'account_account' }
    & Pick<AccountAccount, 'id' | 'name' | 'code'>
  )> }
);

export type ReceivableLogFragment = (
  { __typename?: 'ledger_receivable_log' }
  & Pick<LedgerReceivableLog, 'id' | 'description' | 'new_status_id' | 'receivable_id' | 'organization_id' | 'created_by' | 'created_at' | 'action'>
  & { new_status: (
    { __typename?: 'ledger_receivable_status' }
    & Pick<LedgerReceivableStatus, 'description' | 'id'>
  ), old_status: (
    { __typename?: 'ledger_receivable_status' }
    & Pick<LedgerReceivableStatus, 'id' | 'description'>
  ), created_by_user: (
    { __typename?: 'users' }
    & Pick<Users, 'display_name'>
  ) }
);

export type ReceivableWithItemsFragment = (
  { __typename?: 'ledger_receivable' }
  & { receivable_items: Array<(
    { __typename?: 'ledger_receivable_item' }
    & ReceivableItemFragment
  )> }
  & ReceivableFragment
);

export type GetReceivablesQueryVariables = Exact<{
  distinctOn?: Maybe<Array<LedgerReceivableSelectColumn> | LedgerReceivableSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<LedgerReceivableOrderBy> | LedgerReceivableOrderBy>;
  where?: Maybe<LedgerReceivableBoolExp>;
}>;


export type GetReceivablesQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'ledger_receivable' }
    & ReceivableFragment
  )> }
);

export type GetReceivableByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetReceivableByPkQuery = (
  { __typename?: 'query_root' }
  & { row?: Maybe<(
    { __typename?: 'ledger_receivable' }
    & ReceivableWithItemsFragment
  )>, ledgers: Array<(
    { __typename?: 'ledger_ledger' }
    & LedgerFragment
  )>, logs: Array<(
    { __typename?: 'ledger_receivable_log' }
    & ReceivableLogFragment
  )> }
);

export type InsertReceivableOneMutationVariables = Exact<{
  object: LedgerReceivableInsertInput;
}>;


export type InsertReceivableOneMutation = (
  { __typename?: 'mutation_root' }
  & { insert_ledger_receivable_one?: Maybe<(
    { __typename?: 'ledger_receivable' }
    & ReceivableFragment
  )> }
);

export type UpdateReceivableByPkMutationVariables = Exact<{
  _set?: Maybe<LedgerReceivableSetInput>;
  id: Scalars['uuid'];
}>;


export type UpdateReceivableByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_ledger_receivable_by_pk?: Maybe<(
    { __typename?: 'ledger_receivable' }
    & ReceivableFragment
  )> }
);

export type SubmitReceivableByPkMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type SubmitReceivableByPkMutation = (
  { __typename?: 'mutation_root' }
  & { row: (
    { __typename?: 'ReceivableSubmitOutput' }
    & Pick<ReceivableSubmitOutput, 'receivableId'>
  ) }
);

export type DoneReceivableByPkMutationVariables = Exact<{
  data: ReceivableCloseInput;
}>;


export type DoneReceivableByPkMutation = (
  { __typename?: 'mutation_root' }
  & { row: (
    { __typename?: 'ReceivableCloseOutput' }
    & Pick<ReceivableCloseOutput, 'receivableId'>
  ) }
);

export type DeleteReceivableByPkMutationVariables = Exact<{
  data: ReceivableDeleteInput;
}>;


export type DeleteReceivableByPkMutation = (
  { __typename?: 'mutation_root' }
  & { row: (
    { __typename?: 'ReceivableDeleteOutput' }
    & Pick<ReceivableDeleteOutput, 'receivableId'>
  ) }
);

export type LedgerFragment = (
  { __typename?: 'ledger_ledger' }
  & Pick<LedgerLedger, 'id' | 'updated_by' | 'updated_at' | 'submitted_by' | 'submitted_at' | 'organization_id' | 'receivable_id' | 'note' | 'locked_by' | 'locked_at' | 'gfmis_id' | 'fiscal_year' | 'date' | 'created_by' | 'created_at' | 'change_request_by' | 'change_request_at' | 'budget_id' | 'approved_by' | 'approved_at' | 'amount' | 'account_id' | 'status_id' | 'type_id'>
  & { created_by_user: (
    { __typename?: 'users' }
    & Pick<Users, 'display_name'>
  ), organization: (
    { __typename?: 'organization' }
    & Pick<Organization, 'name' | 'short_name' | 'reference' | 'province' | 'id'>
  ), budget?: Maybe<(
    { __typename?: 'account_budget_type' }
    & Pick<AccountBudgetType, 'account_id' | 'id' | 'fiscal_year' | 'description'>
  )>, account: (
    { __typename?: 'account_account' }
    & Pick<AccountAccount, 'code' | 'id' | 'fiscal_year' | 'name' | 'parent_id' | 'type_id'>
  ), status: (
    { __typename?: 'ledger_ledger_status' }
    & Pick<LedgerLedgerStatus, 'id' | 'description'>
  ), receivable?: Maybe<(
    { __typename?: 'ledger_receivable' }
    & Pick<LedgerReceivable, 'id' | 'reference' | 'borrower_name' | 'amount' | 'refund_amount'>
  )> }
);

export type LedgerLogFragment = (
  { __typename?: 'ledger_ledger_log' }
  & Pick<LedgerLedgerLog, 'id' | 'description' | 'ledger_id' | 'new_status_id' | 'organization_id' | 'old_status_id' | 'created_at' | 'action' | 'created_by'>
  & { new_status: (
    { __typename?: 'ledger_ledger_status' }
    & Pick<LedgerLedgerStatus, 'id' | 'description'>
  ), created_by_user: (
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'display_name'>
  ), old_status: (
    { __typename?: 'ledger_ledger_status' }
    & Pick<LedgerLedgerStatus, 'id' | 'description'>
  ) }
);

export type GetLedgerLogsByPkQueryVariables = Exact<{
  ledgerId: Scalars['uuid'];
}>;


export type GetLedgerLogsByPkQuery = (
  { __typename?: 'query_root' }
  & { logs: Array<(
    { __typename?: 'ledger_ledger_log' }
    & LedgerLogFragment
  )> }
);

export type GetLedgersQueryVariables = Exact<{
  distinctOn?: Maybe<Array<LedgerLedgerSelectColumn> | LedgerLedgerSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<LedgerLedgerOrderBy> | LedgerLedgerOrderBy>;
  where?: Maybe<LedgerLedgerBoolExp>;
}>;


export type GetLedgersQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'ledger_ledger' }
    & LedgerFragment
  )> }
);

export type GetLedgerByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetLedgerByPkQuery = (
  { __typename?: 'query_root' }
  & { row?: Maybe<(
    { __typename?: 'ledger_ledger' }
    & LedgerFragment
  )>, logs: Array<(
    { __typename?: 'ledger_ledger_log' }
    & LedgerLogFragment
  )> }
);

export type InsertLedgerOneMutationVariables = Exact<{
  object: LedgerLedgerInsertInput;
}>;


export type InsertLedgerOneMutation = (
  { __typename?: 'mutation_root' }
  & { insert_ledger_ledger_one?: Maybe<(
    { __typename?: 'ledger_ledger' }
    & LedgerFragment
  )> }
);

export type UpdateLedgerByPkMutationVariables = Exact<{
  _set?: Maybe<LedgerLedgerSetInput>;
  id: Scalars['uuid'];
}>;


export type UpdateLedgerByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_ledger_ledger_by_pk?: Maybe<(
    { __typename?: 'ledger_ledger' }
    & LedgerFragment
  )> }
);

export type DeleteLedgerByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteLedgerByPkMutation = (
  { __typename?: 'mutation_root' }
  & { delete_ledger_ledger_by_pk?: Maybe<(
    { __typename?: 'ledger_ledger' }
    & LedgerFragment
  )> }
);

export type LedgerAttachmentFragment = (
  { __typename?: 'ledger_ledger_attachment' }
  & Pick<LedgerLedgerAttachment, 'id' | 'filename' | 'file_path' | 'created_by' | 'created_at' | 'ledger_id' | 'note' | 'original_name' | 'updated_at' | 'updated_by'>
);

export type GetLedgerAttachmentsQueryVariables = Exact<{
  distinctOn?: Maybe<Array<LedgerLedgerAttachmentSelectColumn> | LedgerLedgerAttachmentSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<LedgerLedgerAttachmentOrderBy> | LedgerLedgerAttachmentOrderBy>;
  where?: Maybe<LedgerLedgerAttachmentBoolExp>;
}>;


export type GetLedgerAttachmentsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'ledger_ledger_attachment' }
    & LedgerAttachmentFragment
  )> }
);

export type GetLedgerAttachmentByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetLedgerAttachmentByPkQuery = (
  { __typename?: 'query_root' }
  & { row?: Maybe<(
    { __typename?: 'ledger_ledger_attachment' }
    & LedgerAttachmentFragment
  )> }
);

export type InsertLedgerAttachmentOneMutationVariables = Exact<{
  object: LedgerLedgerAttachmentInsertInput;
}>;


export type InsertLedgerAttachmentOneMutation = (
  { __typename?: 'mutation_root' }
  & { insert_ledger_ledger_attachment_one?: Maybe<(
    { __typename?: 'ledger_ledger_attachment' }
    & LedgerAttachmentFragment
  )> }
);

export type UpdateLedgerAttachmentByPkMutationVariables = Exact<{
  _set?: Maybe<LedgerLedgerAttachmentSetInput>;
  id: Scalars['uuid'];
}>;


export type UpdateLedgerAttachmentByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_ledger_ledger_attachment_by_pk?: Maybe<(
    { __typename?: 'ledger_ledger_attachment' }
    & LedgerAttachmentFragment
  )> }
);

export type DeleteLedgerAttachmentByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteLedgerAttachmentByPkMutation = (
  { __typename?: 'mutation_root' }
  & { delete_ledger_ledger_attachment_by_pk?: Maybe<(
    { __typename?: 'ledger_ledger_attachment' }
    & LedgerAttachmentFragment
  )> }
);

export type SubmitLedgerByPkMutationVariables = Exact<{
  data: LedgerSubmitInput;
}>;


export type SubmitLedgerByPkMutation = (
  { __typename?: 'mutation_root' }
  & { ledger: (
    { __typename?: 'LedgerSubmitOutput' }
    & Pick<LedgerSubmitOutput, 'ledgerId'>
  ) }
);

export type ApproveLedgerByPkMutationVariables = Exact<{
  data: LedgerApproveInput;
}>;


export type ApproveLedgerByPkMutation = (
  { __typename?: 'mutation_root' }
  & { ledger: (
    { __typename?: 'LedgerApproveOutput' }
    & Pick<LedgerApproveOutput, 'ledgerId' | 'statusId'>
  ) }
);

export type GetLedgerTotalReportQueryVariables = Exact<{
  fiscalYear: Scalars['Int'];
}>;


export type GetLedgerTotalReportQuery = (
  { __typename?: 'query_root' }
  & { organizations: Array<(
    { __typename?: 'organization' }
    & Pick<Organization, 'id' | 'name' | 'reference' | 'short_name'>
  )>, budgets: Array<(
    { __typename?: 'account_budget_type' }
    & Pick<AccountBudgetType, 'id' | 'description' | 'fiscal_year' | 'group_id' | 'group_name' | 'account_id' | 'closed' | 'closed_at'>
  )>, accounts: Array<(
    { __typename?: 'account_account' }
    & Pick<AccountAccount, 'id' | 'name' | 'code'>
  )>, total: Array<(
    { __typename?: 'ledger_total_report_yearly' }
    & Pick<LedgerTotalReportYearly, 'account_id' | 'budget_balance' | 'allocated_budget' | 'budget_id' | 'expense_total' | 'fiscal_year' | 'organization_id'>
  )> }
);

export type GetLoanAccountTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLoanAccountTypesQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'loan_account_type' }
    & Pick<LoanAccountType, 'id' | 'description' | 'sequence'>
  )> }
);

export type LoanApplicationAttachmentFragment = (
  { __typename?: 'loan_application_attachment' }
  & Pick<LoanApplicationAttachment, 'size' | 'original_name' | 'note' | 'mimetype' | 'id' | 'filename' | 'attachment_type_id' | 'application_header_id' | 'file_path' | 'created_at' | 'approve' | 'approve_at'>
  & { attachment_type: (
    { __typename?: 'loan_attachment_type' }
    & Pick<LoanAttachmentType, 'id' | 'sequence' | 'required' | 'description'>
  ) }
);

export type InsertLoanApplicationAttachmentOneMutationVariables = Exact<{
  object: LoanApplicationAttachmentInsertInput;
}>;


export type InsertLoanApplicationAttachmentOneMutation = (
  { __typename?: 'mutation_root' }
  & { entity?: Maybe<(
    { __typename?: 'loan_application_attachment' }
    & LoanApplicationAttachmentFragment
  )> }
);

export type UpdateLoanApplicationAttachmentByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: Maybe<LoanApplicationAttachmentSetInput>;
}>;


export type UpdateLoanApplicationAttachmentByPkMutation = (
  { __typename?: 'mutation_root' }
  & { entity?: Maybe<(
    { __typename?: 'loan_application_attachment' }
    & LoanApplicationAttachmentFragment
  )> }
);

export type DeleteLoanApplicationAttachmentByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteLoanApplicationAttachmentByPkMutation = (
  { __typename?: 'mutation_root' }
  & { entity?: Maybe<(
    { __typename?: 'loan_application_attachment' }
    & LoanApplicationAttachmentFragment
  )> }
);

export type ApproveLoanApplicationAttachmentsMutationVariables = Exact<{
  true_ids: Array<Scalars['uuid']> | Scalars['uuid'];
  false_ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type ApproveLoanApplicationAttachmentsMutation = (
  { __typename?: 'mutation_root' }
  & { true?: Maybe<(
    { __typename?: 'loan_application_attachment_mutation_response' }
    & Pick<LoanApplicationAttachmentMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'loan_application_attachment' }
      & Pick<LoanApplicationAttachment, 'id' | 'approve'>
    )> }
  )>, false?: Maybe<(
    { __typename?: 'loan_application_attachment_mutation_response' }
    & Pick<LoanApplicationAttachmentMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'loan_application_attachment' }
      & Pick<LoanApplicationAttachment, 'id' | 'approve'>
    )> }
  )> }
);

export type GetLoanApplicationAttachmentsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<LoanApplicationAttachmentSelectColumn> | LoanApplicationAttachmentSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanApplicationAttachmentOrderBy> | LoanApplicationAttachmentOrderBy>;
  where?: Maybe<LoanApplicationAttachmentBoolExp>;
}>;


export type GetLoanApplicationAttachmentsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'loan_application_attachment' }
    & LoanApplicationAttachmentFragment
  )> }
);

export type GetLoanApplicationAttachmentsSummaryQueryVariables = Exact<{
  applicationHeaderId: Scalars['uuid'];
}>;


export type GetLoanApplicationAttachmentsSummaryQuery = (
  { __typename?: 'query_root' }
  & { total: (
    { __typename?: 'loan_application_attachment_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_attachment_aggregate_fields' }
      & Pick<LoanApplicationAttachmentAggregateFields, 'count'>
    )>, nodes: Array<(
      { __typename?: 'loan_application_attachment' }
      & Pick<LoanApplicationAttachment, 'approve'>
    )> }
  ), type_1: (
    { __typename?: 'loan_application_attachment_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_attachment_aggregate_fields' }
      & Pick<LoanApplicationAttachmentAggregateFields, 'count'>
    )>, nodes: Array<(
      { __typename?: 'loan_application_attachment' }
      & Pick<LoanApplicationAttachment, 'approve'>
    )> }
  ), type_2: (
    { __typename?: 'loan_application_attachment_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_attachment_aggregate_fields' }
      & Pick<LoanApplicationAttachmentAggregateFields, 'count'>
    )>, nodes: Array<(
      { __typename?: 'loan_application_attachment' }
      & Pick<LoanApplicationAttachment, 'approve'>
    )> }
  ), type_3: (
    { __typename?: 'loan_application_attachment_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_attachment_aggregate_fields' }
      & Pick<LoanApplicationAttachmentAggregateFields, 'count'>
    )>, nodes: Array<(
      { __typename?: 'loan_application_attachment' }
      & Pick<LoanApplicationAttachment, 'approve'>
    )> }
  ), type_4: (
    { __typename?: 'loan_application_attachment_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_attachment_aggregate_fields' }
      & Pick<LoanApplicationAttachmentAggregateFields, 'count'>
    )>, nodes: Array<(
      { __typename?: 'loan_application_attachment' }
      & Pick<LoanApplicationAttachment, 'approve'>
    )> }
  ), type_5: (
    { __typename?: 'loan_application_attachment_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_attachment_aggregate_fields' }
      & Pick<LoanApplicationAttachmentAggregateFields, 'count'>
    )>, nodes: Array<(
      { __typename?: 'loan_application_attachment' }
      & Pick<LoanApplicationAttachment, 'approve'>
    )> }
  ), type_6: (
    { __typename?: 'loan_application_attachment_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_attachment_aggregate_fields' }
      & Pick<LoanApplicationAttachmentAggregateFields, 'count'>
    )>, nodes: Array<(
      { __typename?: 'loan_application_attachment' }
      & Pick<LoanApplicationAttachment, 'approve'>
    )> }
  ), type_7: (
    { __typename?: 'loan_application_attachment_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_attachment_aggregate_fields' }
      & Pick<LoanApplicationAttachmentAggregateFields, 'count'>
    )>, nodes: Array<(
      { __typename?: 'loan_application_attachment' }
      & Pick<LoanApplicationAttachment, 'approve'>
    )> }
  ), type_8: (
    { __typename?: 'loan_application_attachment_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_attachment_aggregate_fields' }
      & Pick<LoanApplicationAttachmentAggregateFields, 'count'>
    )>, nodes: Array<(
      { __typename?: 'loan_application_attachment' }
      & Pick<LoanApplicationAttachment, 'approve'>
    )> }
  ), type_9: (
    { __typename?: 'loan_application_attachment_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_attachment_aggregate_fields' }
      & Pick<LoanApplicationAttachmentAggregateFields, 'count'>
    )>, nodes: Array<(
      { __typename?: 'loan_application_attachment' }
      & Pick<LoanApplicationAttachment, 'approve'>
    )> }
  ), type_10: (
    { __typename?: 'loan_application_attachment_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_attachment_aggregate_fields' }
      & Pick<LoanApplicationAttachmentAggregateFields, 'count'>
    )>, nodes: Array<(
      { __typename?: 'loan_application_attachment' }
      & Pick<LoanApplicationAttachment, 'approve'>
    )> }
  ), type_99: (
    { __typename?: 'loan_application_attachment_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_attachment_aggregate_fields' }
      & Pick<LoanApplicationAttachmentAggregateFields, 'count'>
    )>, nodes: Array<(
      { __typename?: 'loan_application_attachment' }
      & Pick<LoanApplicationAttachment, 'approve'>
    )> }
  ) }
);

export type LoanApplicationEventLogFragment = (
  { __typename?: 'loan_application_event_log' }
  & Pick<LoanApplicationEventLog, 'application_header_id' | 'step_id' | 'sme_user_id' | 'remark' | 'reference' | 'juristic_id' | 'id' | 'document_approve_value' | 'description' | 'created_at' | 'committee_approve_value' | 'bank_approve_value' | 'actor_user_id' | 'actor_organization_id'>
  & { sme_user: (
    { __typename?: 'users' }
    & Pick<Users, 'first_name' | 'last_name'>
    & { name_title?: Maybe<(
      { __typename?: 'name_title' }
      & Pick<NameTitle, 'description'>
    )> }
  ), actor_user: (
    { __typename?: 'users' }
    & Pick<Users, 'first_name' | 'last_name'>
    & { name_title?: Maybe<(
      { __typename?: 'name_title' }
      & Pick<NameTitle, 'description'>
    )> }
  ) }
);

export type GetLoanApplicationEventLogsQueryVariables = Exact<{
  distinctOn?: Maybe<Array<LoanApplicationEventLogSelectColumn> | LoanApplicationEventLogSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<LoanApplicationEventLogOrderBy> | LoanApplicationEventLogOrderBy>;
  where?: Maybe<LoanApplicationEventLogBoolExp>;
}>;


export type GetLoanApplicationEventLogsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'loan_application_event_log' }
    & LoanApplicationEventLogFragment
  )> }
);

export type GetLoanBusinessGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLoanBusinessGroupsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'loan_business_group' }
    & Pick<LoanBusinessGroup, 'id' | 'description' | 'sequence' | 'program_id'>
  )> }
);

export type GetLoanBusinessProprietariesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLoanBusinessProprietariesQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'loan_business_proprietary' }
    & Pick<LoanBusinessProprietary, 'id' | 'description' | 'sequence' | 'need_addition'>
  )> }
);

export type GetLoanBusinessStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLoanBusinessStatusQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'loan_business_status' }
    & Pick<LoanBusinessStatus, 'id' | 'description' | 'sequence'>
  )> }
);

export type GetLoanBusinessTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLoanBusinessTypesQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'loan_business_type' }
    & Pick<LoanBusinessType, 'id' | 'description' | 'sequence' | 'program_id' | 'active'>
  )> }
);

export type GetLoanCurrentLoanQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLoanCurrentLoanQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'loan_current_loan' }
    & Pick<LoanCurrentLoan, 'id' | 'description' | 'need_addition' | 'sequence'>
  )> }
);

export type GetLoanFinancialRescuesQueryVariables = Exact<{
  distinct_on?: Maybe<Array<LoanFinancialRescueSelectColumn> | LoanFinancialRescueSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<LoanFinancialRescueOrderBy> | LoanFinancialRescueOrderBy>;
  where?: Maybe<LoanFinancialRescueBoolExp>;
}>;


export type GetLoanFinancialRescuesQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'loan_financial_rescue' }
    & Pick<LoanFinancialRescue, 'id' | 'description' | 'parent_id' | 'sequence'>
    & { children: Array<(
      { __typename?: 'loan_financial_rescue' }
      & Pick<LoanFinancialRescue, 'id' | 'description' | 'sequence'>
    )> }
  )> }
);

export type GetLoanProprietariesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLoanProprietariesQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'loan_proprietary' }
    & Pick<LoanProprietary, 'id' | 'description' | 'need_addition' | 'sequence'>
  )> }
);

export type GetLoanRepayTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLoanRepayTypesQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'loan_repay_type' }
    & Pick<LoanRepayType, 'id' | 'description' | 'parent_id' | 'sequence'>
    & { children: Array<(
      { __typename?: 'loan_repay_type' }
      & Pick<LoanRepayType, 'id' | 'description' | 'sequence'>
    )> }
  )> }
);

export type GetLoanSmeDefinitionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLoanSmeDefinitionsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'loan_sme_definition' }
    & Pick<LoanSmeDefinition, 'id' | 'description' | 'sequence'>
  )> }
);

export type LoanApplicationHeaderFragment = (
  { __typename?: 'loan_application_header' }
  & Pick<LoanApplicationHeader, 'id' | 'bank_approve_date' | 'bank_approve_credit' | 'business_group_description' | 'business_group_id' | 'business_proprietary_description' | 'business_proprietary_else' | 'business_proprietary_id' | 'business_status_description' | 'business_status_id' | 'business_type_description' | 'business_type_id' | 'committee_approve_credit' | 'committee_approve_date' | 'completed_date' | 'contact_email' | 'contact_first_name' | 'contact_last_name' | 'contact_tel' | 'contact_position' | 'created_at' | 'created_by' | 'deleted_at' | 'document_approve_date' | 'email' | 'expect_credit_total' | 'juristic_id' | 'name' | 'office_address_amphoe' | 'office_address_district' | 'office_address_province' | 'office_address_street' | 'office_address_zipcode' | 'program_id' | 'project_value_total' | 'reference' | 'self_spend_total' | 'start_date' | 'step_id' | 'submitted_date' | 'tel' | 'updated_at' | 'updated_by' | 'working_address_zipcode' | 'working_address_street' | 'working_address_province' | 'working_address_is_office_address' | 'working_address_district' | 'working_address_amphoe' | 'user_id' | 'organization_id' | 'bank_branch_id' | 'document_approve_value' | 'committee_approve_value' | 'bank_approve_value' | 'document_approve_comment' | 'committee_approve_comment' | 'bank_approve_comment'>
  & { bank_approve?: Maybe<(
    { __typename?: 'loan_bank_approve' }
    & Pick<LoanBankApprove, 'value' | 'description'>
  )>, committee_approve?: Maybe<(
    { __typename?: 'loan_committee_approve' }
    & Pick<LoanCommitteeApprove, 'value' | 'description'>
  )>, document_approve?: Maybe<(
    { __typename?: 'loan_document_approve' }
    & Pick<LoanDocumentApprove, 'value' | 'description'>
  )>, application_step: (
    { __typename?: 'loan_application_step' }
    & Pick<LoanApplicationStep, 'description' | 'value'>
  ), organization?: Maybe<(
    { __typename?: 'organization' }
    & Pick<Organization, 'name' | 'short_name'>
  )>, bank_branch?: Maybe<(
    { __typename?: 'bank_branch' }
    & Pick<BankBranch, 'branch'>
  )> }
);

export type LoanApplicationHeaderFullFragment = (
  { __typename?: 'loan_application_header' }
  & { detail?: Maybe<(
    { __typename?: 'loan_application_detail' }
    & LoanApplicationDetailFragment
  )>, profile?: Maybe<(
    { __typename?: 'loan_application_profile' }
    & LoanApplicationProfileFragment
  )>, committees: Array<(
    { __typename?: 'loan_application_committee' }
    & Pick<LoanApplicationCommittee, 'active' | 'authorization' | 'contact_address_amphoe' | 'contact_address_district' | 'contact_address_mobile' | 'contact_address_fax' | 'contact_address_province' | 'contact_address_street' | 'contact_address_tel' | 'contact_address_zipcode' | 'first_name' | 'from_dbd' | 'id' | 'full_name' | 'last_name' | 'title' | 'primary_address_amphoe' | 'primary_address_district' | 'primary_address_is_contact_address' | 'primary_address_province' | 'primary_address_zipcode' | 'primary_address_street' | 'sequence' | 'citizen_id' | 'working_address_amphoe' | 'working_address_district' | 'working_address_fax' | 'working_address_is_contact_address' | 'working_address_province' | 'working_address_street' | 'working_address_tel' | 'working_address_zipcode' | 'application_header_id'>
  )>, objectives: Array<(
    { __typename?: 'loan_application_objective' }
    & Pick<LoanApplicationObjective, 'id' | 'title' | 'description' | 'project_value' | 'self_spend' | 'need_credit' | 'need_addition'>
  )> }
  & LoanApplicationHeaderFragment
);

export type LoanApplicationDetailFragment = (
  { __typename?: 'loan_application_detail' }
  & Pick<LoanApplicationDetail, 'id' | 'business_proposal' | 'cost_cash' | 'cost' | 'cost_day' | 'created_at' | 'cost_credit' | 'created_by' | 'current_liability_amount_total' | 'current_liability_day' | 'current_loan_description' | 'current_loan_else' | 'current_loan_id' | 'current_loan_list' | 'debt_restructuring_description' | 'debt_restructuring_else' | 'debt_restructuring_id' | 'deleted_at' | 'earned_income_credit' | 'financial_agreement_description' | 'financial_agreement_else' | 'financial_agreement_id' | 'financial_rescue_description' | 'financial_rescue_else' | 'financial_rescue_id' | 'finished_goods_amount_total' | 'foreign_income_cash' | 'foreign_income_credit' | 'foreign_income_day' | 'foreign_income_percent' | 'gross_profit' | 'inventory_valuation' | 'local_income_cash' | 'local_income_credit' | 'local_income_day' | 'local_income_percent' | 'net_profit' | 'proprietary_description' | 'proprietary_else' | 'proprietary_id' | 'raw_material_amount_total' | 'repay_type_description' | 'repay_type_else' | 'repay_type_id' | 'service_cost' | 'sme_definition_description' | 'updated_by' | 'updated_at' | 'sme_definition_else' | 'sme_definition_id' | 'account_type_id' | 'account_type_else' | 'account_type_description'>
  & { repay_type?: Maybe<(
    { __typename?: 'loan_repay_type' }
    & Pick<LoanRepayType, 'sequence' | 'id' | 'description' | 'active'>
  )>, proprietary?: Maybe<(
    { __typename?: 'loan_proprietary' }
    & Pick<LoanProprietary, 'sequence' | 'id' | 'description' | 'active'>
  )>, financial_rescue?: Maybe<(
    { __typename?: 'loan_financial_rescue' }
    & Pick<LoanFinancialRescue, 'sequence' | 'id' | 'description' | 'active'>
  )>, financial_agreement?: Maybe<(
    { __typename?: 'loan_financial_agreement' }
    & Pick<LoanFinancialAgreement, 'sequence' | 'id' | 'description' | 'active'>
  )>, debt_restructuring?: Maybe<(
    { __typename?: 'loan_debt_restructuring' }
    & Pick<LoanDebtRestructuring, 'sequence' | 'id' | 'description' | 'active'>
  )>, current_loan?: Maybe<(
    { __typename?: 'loan_current_loan' }
    & Pick<LoanCurrentLoan, 'sequence' | 'id' | 'description' | 'active'>
  )>, account_type?: Maybe<(
    { __typename?: 'loan_account_type' }
    & Pick<LoanAccountType, 'sequence' | 'id' | 'description' | 'active'>
  )> }
);

export type LoanApplicationProfileFragment = (
  { __typename?: 'loan_application_profile' }
  & Pick<LoanApplicationProfile, 'company_history' | 'company_product' | 'current_customer' | 'distribution' | 'executive_history' | 'id' | 'innovation_usage' | 'major_customer' | 'major_supplier' | 'prominent_point' | 'target_group' | 'technology_usage' | 'else_usage'>
);

export type UpdateLoanApplicationHeaderByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: Maybe<LoanApplicationHeaderSetInput>;
}>;


export type UpdateLoanApplicationHeaderByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_loan_application_header_by_pk?: Maybe<(
    { __typename?: 'loan_application_header' }
    & LoanApplicationHeaderFragment
  )> }
);

export type ApproveLoanApplicationMutationVariables = Exact<{
  data: ApproveLoanApplicationInput;
}>;


export type ApproveLoanApplicationMutation = (
  { __typename?: 'mutation_root' }
  & { row?: Maybe<(
    { __typename?: 'ApproveLoanApplicationOutput' }
    & Pick<ApproveLoanApplicationOutput, 'application_header_id'>
  )> }
);

export type GetLoanApplicationHeadersQueryVariables = Exact<{
  distinctOn?: Maybe<Array<LoanApplicationHeaderSelectColumn> | LoanApplicationHeaderSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<LoanApplicationHeaderOrderBy> | LoanApplicationHeaderOrderBy>;
  where?: Maybe<LoanApplicationHeaderBoolExp>;
}>;


export type GetLoanApplicationHeadersQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'loan_application_header' }
    & LoanApplicationHeaderFragment
  )> }
);

export type GetLoanApplicationHeaderSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLoanApplicationHeaderSummaryQuery = (
  { __typename?: 'query_root' }
  & { total_count: (
    { __typename?: 'loan_application_header_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_header_aggregate_fields' }
      & Pick<LoanApplicationHeaderAggregateFields, 'count'>
    )> }
  ), submitted_count: (
    { __typename?: 'loan_application_header_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_header_aggregate_fields' }
      & Pick<LoanApplicationHeaderAggregateFields, 'count'>
    )> }
  ), document_approved_count: (
    { __typename?: 'loan_application_header_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_header_aggregate_fields' }
      & Pick<LoanApplicationHeaderAggregateFields, 'count'>
    )> }
  ), committee_approved_count: (
    { __typename?: 'loan_application_header_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_header_aggregate_fields' }
      & Pick<LoanApplicationHeaderAggregateFields, 'count'>
    )> }
  ), sme_sign_count: (
    { __typename?: 'loan_application_header_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_header_aggregate_fields' }
      & Pick<LoanApplicationHeaderAggregateFields, 'count'>
    )> }
  ), bank_count: (
    { __typename?: 'loan_application_header_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_header_aggregate_fields' }
      & Pick<LoanApplicationHeaderAggregateFields, 'count'>
    )> }
  ), completed_count: (
    { __typename?: 'loan_application_header_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_header_aggregate_fields' }
      & Pick<LoanApplicationHeaderAggregateFields, 'count'>
    )> }
  ), cancelled_count: (
    { __typename?: 'loan_application_header_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_header_aggregate_fields' }
      & Pick<LoanApplicationHeaderAggregateFields, 'count'>
    )> }
  ), document_change_request_count: (
    { __typename?: 'loan_application_header_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_header_aggregate_fields' }
      & Pick<LoanApplicationHeaderAggregateFields, 'count'>
    )> }
  ), committee_change_request_count: (
    { __typename?: 'loan_application_header_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_header_aggregate_fields' }
      & Pick<LoanApplicationHeaderAggregateFields, 'count'>
    )> }
  ), document_reject_count: (
    { __typename?: 'loan_application_header_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_header_aggregate_fields' }
      & Pick<LoanApplicationHeaderAggregateFields, 'count'>
    )> }
  ), committee_reject_count: (
    { __typename?: 'loan_application_header_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_header_aggregate_fields' }
      & Pick<LoanApplicationHeaderAggregateFields, 'count'>
    )> }
  ), bank_reject_count: (
    { __typename?: 'loan_application_header_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_header_aggregate_fields' }
      & Pick<LoanApplicationHeaderAggregateFields, 'count'>
    )> }
  ) }
);

export type GetLoanApplicationHeaderByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetLoanApplicationHeaderByPkQuery = (
  { __typename?: 'query_root' }
  & { entity?: Maybe<(
    { __typename?: 'loan_application_header' }
    & LoanApplicationHeaderFullFragment
  )>, pending_transfer_aggregate: (
    { __typename?: 'loan_application_organization_transfer_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_organization_transfer_aggregate_fields' }
      & Pick<LoanApplicationOrganizationTransferAggregateFields, 'count'>
    )>, rows: Array<(
      { __typename?: 'loan_application_organization_transfer' }
      & Pick<LoanApplicationOrganizationTransfer, 'id' | 'application_header_id' | 'status_value' | 'from_organization_id' | 'to_organization_id'>
      & { from_organization: (
        { __typename?: 'organization' }
        & Pick<Organization, 'name'>
      ), to_organization: (
        { __typename?: 'organization' }
        & Pick<Organization, 'name'>
      ) }
    )> }
  ) }
);

export type LoanApplicationOrganizationTransferFragment = (
  { __typename?: 'loan_application_organization_transfer' }
  & Pick<LoanApplicationOrganizationTransfer, 'id' | 'updated_at' | 'status_value' | 'old_reference' | 'new_reference' | 'note' | 'approver_id' | 'application_header_id' | 'created_at' | 'approved_at' | 'approved_note'>
  & { to_organization: (
    { __typename?: 'organization' }
    & Pick<Organization, 'id' | 'area_code' | 'name' | 'name_en' | 'province' | 'reference' | 'sequence' | 'short_name' | 'short_name_en' | 'unit_code'>
  ), status: (
    { __typename?: 'loan_transfer_status' }
    & Pick<LoanTransferStatus, 'value' | 'description'>
  ), from_organization: (
    { __typename?: 'organization' }
    & Pick<Organization, 'area_code' | 'id' | 'name' | 'name_en' | 'province' | 'reference' | 'sequence' | 'short_name' | 'short_name_en' | 'unit_code'>
  ), approver?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'display_name' | 'first_name' | 'id' | 'last_name' | 'department_id' | 'organization_id' | 'tel' | 'title_id' | 'reference' | 'sequence'>
  )>, application_header: (
    { __typename?: 'loan_application_header' }
    & Pick<LoanApplicationHeader, 'name' | 'juristic_id' | 'reference' | 'submitted_date'>
  ) }
);

export type LoanApplicationOrganizationTransferFullFragment = (
  { __typename?: 'loan_application_organization_transfer' }
  & Pick<LoanApplicationOrganizationTransfer, 'old_reference' | 'new_reference' | 'id' | 'updated_at' | 'note' | 'approver_id' | 'application_header_id' | 'created_at' | 'approved_at' | 'approved_note'>
  & { to_organization: (
    { __typename?: 'organization' }
    & Pick<Organization, 'id' | 'area_code' | 'name' | 'name_en' | 'province' | 'reference' | 'sequence' | 'short_name' | 'short_name_en' | 'unit_code'>
  ), status: (
    { __typename?: 'loan_transfer_status' }
    & Pick<LoanTransferStatus, 'value' | 'description'>
  ), from_organization: (
    { __typename?: 'organization' }
    & Pick<Organization, 'area_code' | 'id' | 'name' | 'name_en' | 'province' | 'reference' | 'sequence' | 'short_name' | 'short_name_en' | 'unit_code'>
  ), approver?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'display_name' | 'first_name' | 'id' | 'last_name' | 'department_id' | 'organization_id' | 'tel' | 'title_id' | 'reference' | 'sequence'>
  )>, application_header: (
    { __typename?: 'loan_application_header' }
    & Pick<LoanApplicationHeader, 'id' | 'name' | 'juristic_id' | 'reference' | 'submitted_date' | 'business_type_description' | 'business_group_description' | 'business_status_description' | 'business_proprietary_description' | 'tel' | 'email' | 'contact_first_name' | 'contact_last_name' | 'contact_tel' | 'contact_email' | 'expect_credit_total' | 'committee_approve_credit'>
  ) }
);

export type UpdateLoanApplicationOrganizationTransferByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: Maybe<LoanApplicationOrganizationTransferSetInput>;
}>;


export type UpdateLoanApplicationOrganizationTransferByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_loan_application_organization_transfer_by_pk?: Maybe<(
    { __typename?: 'loan_application_organization_transfer' }
    & LoanApplicationOrganizationTransferFragment
  )> }
);

export type LoanApplicationOrganizationTransferApprovalByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: Maybe<LoanApplicationOrganizationTransferSetInput>;
  application_header_id: Scalars['uuid'];
  to_organization_id: Scalars['uuid'];
}>;


export type LoanApplicationOrganizationTransferApprovalByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_loan_application_organization_transfer_by_pk?: Maybe<(
    { __typename?: 'loan_application_organization_transfer' }
    & LoanApplicationOrganizationTransferFragment
  )>, update_loan_application_header_by_pk?: Maybe<(
    { __typename?: 'loan_application_header' }
    & Pick<LoanApplicationHeader, 'id'>
  )> }
);

export type InsertLoanApplicationOrganizationTransferOneMutationVariables = Exact<{
  object: LoanApplicationOrganizationTransferInsertInput;
}>;


export type InsertLoanApplicationOrganizationTransferOneMutation = (
  { __typename?: 'mutation_root' }
  & { insert_loan_application_organization_transfer_one?: Maybe<(
    { __typename?: 'loan_application_organization_transfer' }
    & LoanApplicationOrganizationTransferFragment
  )> }
);

export type ApproveLoanApplicationOrganizationTransferMutationVariables = Exact<{
  data: ApproveLoanApplicationOrganizationTransferInput;
}>;


export type ApproveLoanApplicationOrganizationTransferMutation = (
  { __typename?: 'mutation_root' }
  & { response?: Maybe<(
    { __typename?: 'ApproveLoanApplicationOrganizationTransferOutput' }
    & Pick<ApproveLoanApplicationOrganizationTransferOutput, 'organization_transfer_id'>
  )> }
);

export type GetLoanApplicationOrganizationTransfersQueryVariables = Exact<{
  distinctOn?: Maybe<Array<LoanApplicationOrganizationTransferSelectColumn> | LoanApplicationOrganizationTransferSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<LoanApplicationOrganizationTransferOrderBy> | LoanApplicationOrganizationTransferOrderBy>;
  where?: Maybe<LoanApplicationOrganizationTransferBoolExp>;
}>;


export type GetLoanApplicationOrganizationTransfersQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'loan_application_organization_transfer' }
    & LoanApplicationOrganizationTransferFragment
  )> }
);

export type GetLoanApplicationOrganizationTransferSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLoanApplicationOrganizationTransferSummaryQuery = (
  { __typename?: 'query_root' }
  & { total_count: (
    { __typename?: 'loan_application_organization_transfer_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_organization_transfer_aggregate_fields' }
      & Pick<LoanApplicationOrganizationTransferAggregateFields, 'count'>
    )> }
  ), pending_count: (
    { __typename?: 'loan_application_organization_transfer_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_organization_transfer_aggregate_fields' }
      & Pick<LoanApplicationOrganizationTransferAggregateFields, 'count'>
    )> }
  ), approved_count: (
    { __typename?: 'loan_application_organization_transfer_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_organization_transfer_aggregate_fields' }
      & Pick<LoanApplicationOrganizationTransferAggregateFields, 'count'>
    )> }
  ), rejected_count: (
    { __typename?: 'loan_application_organization_transfer_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_organization_transfer_aggregate_fields' }
      & Pick<LoanApplicationOrganizationTransferAggregateFields, 'count'>
    )> }
  ), cancelled_count: (
    { __typename?: 'loan_application_organization_transfer_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_application_organization_transfer_aggregate_fields' }
      & Pick<LoanApplicationOrganizationTransferAggregateFields, 'count'>
    )> }
  ) }
);

export type GetLoanApplicationOrganizationTransferByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetLoanApplicationOrganizationTransferByPkQuery = (
  { __typename?: 'query_root' }
  & { entity?: Maybe<(
    { __typename?: 'loan_application_organization_transfer' }
    & LoanApplicationOrganizationTransferFullFragment
  )> }
);

export type LoanProgramFragment = (
  { __typename?: 'loan_program' }
  & Pick<LoanProgram, 'id' | 'budget_total' | 'name' | 'reference' | 'start_date' | 'end_date' | 'description' | 'status' | 'max_credit'>
);

export type UpdateLoanProgramByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: Maybe<LoanProgramSetInput>;
}>;


export type UpdateLoanProgramByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_loan_program_by_pk?: Maybe<(
    { __typename?: 'loan_program' }
    & LoanProgramFragment
  )> }
);

export type GetLoanProgramsQueryVariables = Exact<{
  distinctOn?: Maybe<Array<LoanProgramSelectColumn> | LoanProgramSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<LoanProgramOrderBy> | LoanProgramOrderBy>;
  where?: Maybe<LoanProgramBoolExp>;
}>;


export type GetLoanProgramsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'loan_program' }
    & LoanProgramFragment
  )> }
);

export type GetLoanProgramByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetLoanProgramByPkQuery = (
  { __typename?: 'query_root' }
  & { entity?: Maybe<(
    { __typename?: 'loan_program' }
    & LoanProgramFragment
  )> }
);

export type LoanSourceFragment = (
  { __typename?: 'loan_source_loan' }
  & Pick<LoanSourceLoan, 'created_at' | 'created_by' | 'credit_limit' | 'id' | 'max_interest' | 'min_interest' | 'name' | 'period' | 'promotion' | 'published' | 'reference' | 'source_id' | 'updated_by' | 'updated_at'>
  & { financial_institution?: Maybe<(
    { __typename?: 'loan_source_financial_institution' }
    & Pick<LoanSourceFinancialInstitution, 'description' | 'id'>
  )>, loan_business_types: Array<(
    { __typename?: 'loan_source_loan_business_type' }
    & Pick<LoanSourceLoanBusinessType, 'business_type_id' | 'loan_id'>
    & { business_type: (
      { __typename?: 'loan_source_business_type' }
      & Pick<LoanSourceBusinessType, 'description'>
    ) }
  )>, objectives: Array<(
    { __typename?: 'loan_source_objective' }
    & Pick<LoanSourceObjective, 'id' | 'loan_id' | 'name' | 'sequence'>
  )>, attachments: Array<(
    { __typename?: 'loan_source_loan_attachment' }
    & LoanSourceAttachmentFragment
  )> }
);

export type LoanSourceAttachmentFragment = (
  { __typename?: 'loan_source_loan_attachment' }
  & Pick<LoanSourceLoanAttachment, 'created_at' | 'created_by' | 'file_path' | 'filename' | 'id' | 'loan_id' | 'mimetype' | 'note' | 'original_name' | 'size' | 'updated_at' | 'updated_by'>
);

export type LoanSourceObjectiveFragment = (
  { __typename?: 'loan_source_objective' }
  & Pick<LoanSourceObjective, 'id' | 'loan_id' | 'name' | 'sequence'>
);

export type LoanSourceInstitutionFragment = (
  { __typename?: 'loan_source_financial_institution' }
  & Pick<LoanSourceFinancialInstitution, 'id' | 'description'>
);

export type InsertLoanSourceOneMutationVariables = Exact<{
  object: LoanSourceLoanInsertInput;
}>;


export type InsertLoanSourceOneMutation = (
  { __typename?: 'mutation_root' }
  & { row?: Maybe<(
    { __typename?: 'loan_source_loan' }
    & LoanSourceFragment
  )> }
);

export type UpdateLoanSourceByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: Maybe<LoanSourceLoanSetInput>;
  insertedObjectiveIds: Array<Scalars['uuid']> | Scalars['uuid'];
  insertedObjectiveData: Array<LoanSourceObjectiveInsertInput> | LoanSourceObjectiveInsertInput;
  insertedBusinessTypeIds: Array<Scalars['String']> | Scalars['String'];
  insertedBusinessTypeData: Array<LoanSourceLoanBusinessTypeInsertInput> | LoanSourceLoanBusinessTypeInsertInput;
}>;


export type UpdateLoanSourceByPkMutation = (
  { __typename?: 'mutation_root' }
  & { row?: Maybe<(
    { __typename?: 'loan_source_loan' }
    & LoanSourceFragment
  )>, deleted_objective_rows?: Maybe<(
    { __typename?: 'loan_source_objective_mutation_response' }
    & Pick<LoanSourceObjectiveMutationResponse, 'affected_rows'>
  )>, inserted_objective_rows?: Maybe<(
    { __typename?: 'loan_source_objective_mutation_response' }
    & Pick<LoanSourceObjectiveMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'loan_source_objective' }
      & Pick<LoanSourceObjective, 'id' | 'loan_id' | 'name' | 'sequence'>
    )> }
  )>, deleted_business_type_rows?: Maybe<(
    { __typename?: 'loan_source_loan_business_type_mutation_response' }
    & Pick<LoanSourceLoanBusinessTypeMutationResponse, 'affected_rows'>
  )>, inserted_business_type_rows?: Maybe<(
    { __typename?: 'loan_source_loan_business_type_mutation_response' }
    & Pick<LoanSourceLoanBusinessTypeMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'loan_source_loan_business_type' }
      & Pick<LoanSourceLoanBusinessType, 'business_type_id' | 'loan_id'>
    )> }
  )> }
);

export type DeleteLoanSourceByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteLoanSourceByPkMutation = (
  { __typename?: 'mutation_root' }
  & { row?: Maybe<(
    { __typename?: 'loan_source_loan' }
    & LoanSourceFragment
  )> }
);

export type InsertLoanSourceAttachmentMutationVariables = Exact<{
  object: LoanSourceLoanAttachmentInsertInput;
}>;


export type InsertLoanSourceAttachmentMutation = (
  { __typename?: 'mutation_root' }
  & { insert_loan_source_loan_attachment_one?: Maybe<(
    { __typename?: 'loan_source_loan_attachment' }
    & LoanSourceAttachmentFragment
  )> }
);

export type InsertLoanSourceInstitutionOneMutationVariables = Exact<{
  object: LoanSourceFinancialInstitutionInsertInput;
}>;


export type InsertLoanSourceInstitutionOneMutation = (
  { __typename?: 'mutation_root' }
  & { row?: Maybe<(
    { __typename?: 'loan_source_financial_institution' }
    & LoanSourceInstitutionFragment
  )> }
);

export type UpdateLoanSourceInstitutionByPkMutationVariables = Exact<{
  id: Scalars['String'];
  _set?: Maybe<LoanSourceFinancialInstitutionSetInput>;
}>;


export type UpdateLoanSourceInstitutionByPkMutation = (
  { __typename?: 'mutation_root' }
  & { row?: Maybe<(
    { __typename?: 'loan_source_financial_institution' }
    & LoanSourceInstitutionFragment
  )> }
);

export type DeleteLoanSourceInstitutionByPkMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteLoanSourceInstitutionByPkMutation = (
  { __typename?: 'mutation_root' }
  & { row?: Maybe<(
    { __typename?: 'loan_source_financial_institution' }
    & LoanSourceInstitutionFragment
  )> }
);

export type GetLoanSourcesQueryVariables = Exact<{
  distinctOn?: Maybe<Array<LoanSourceLoanSelectColumn> | LoanSourceLoanSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<LoanSourceLoanOrderBy> | LoanSourceLoanOrderBy>;
  where?: Maybe<LoanSourceLoanBoolExp>;
}>;


export type GetLoanSourcesQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'loan_source_loan' }
    & LoanSourceFragment
  )> }
);

export type GetLoanSourceByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetLoanSourceByPkQuery = (
  { __typename?: 'query_root' }
  & { row?: Maybe<(
    { __typename?: 'loan_source_loan' }
    & Pick<LoanSourceLoan, 'content'>
    & LoanSourceFragment
  )> }
);

export type GetFinancialInstitutesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFinancialInstitutesQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'loan_source_financial_institution' }
    & LoanSourceInstitutionFragment
  )> }
);

export type GetLoanSourceBusinesTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLoanSourceBusinesTypesQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'loan_source_business_type' }
    & Pick<LoanSourceBusinessType, 'id' | 'description'>
  )> }
);

export type GetLoanSourceSearchLogsQueryVariables = Exact<{
  distinctOn?: Maybe<Array<LoanSourceSearchLogSelectColumn> | LoanSourceSearchLogSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<LoanSourceSearchLogOrderBy> | LoanSourceSearchLogOrderBy>;
  where?: Maybe<LoanSourceSearchLogBoolExp>;
}>;


export type GetLoanSourceSearchLogsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'loan_source_search_log' }
    & Pick<LoanSourceSearchLog, 'id' | 'key' | 'value' | 'created_at'>
  )>, search: (
    { __typename?: 'loan_source_search_log_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_source_search_log_aggregate_fields' }
      & Pick<LoanSourceSearchLogAggregateFields, 'count'>
    )> }
  ), interest: (
    { __typename?: 'loan_source_search_log_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_source_search_log_aggregate_fields' }
      & Pick<LoanSourceSearchLogAggregateFields, 'count'>
    )> }
  ), credit_limit: (
    { __typename?: 'loan_source_search_log_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_source_search_log_aggregate_fields' }
      & Pick<LoanSourceSearchLogAggregateFields, 'count'>
    )> }
  ), business_type: (
    { __typename?: 'loan_source_search_log_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'loan_source_search_log_aggregate_fields' }
      & Pick<LoanSourceSearchLogAggregateFields, 'count'>
    )> }
  ) }
);

export type OrganizationFragment = (
  { __typename?: 'organization' }
  & Pick<Organization, 'area_code' | 'id' | 'name' | 'name_en' | 'province' | 'reference' | 'sequence' | 'short_name' | 'short_name_en' | 'unit_code'>
);

export type OrganizationPartialFragment = (
  { __typename?: 'organization' }
  & Pick<Organization, 'area_code' | 'id' | 'name' | 'province' | 'reference'>
);

export type UpdateOrganizationByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: Maybe<OrganizationSetInput>;
}>;


export type UpdateOrganizationByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_organization_by_pk?: Maybe<(
    { __typename?: 'organization' }
    & OrganizationFragment
  )> }
);

export type InsertOrganizationOneMutationVariables = Exact<{
  object: OrganizationInsertInput;
}>;


export type InsertOrganizationOneMutation = (
  { __typename?: 'mutation_root' }
  & { insert_organization_one?: Maybe<(
    { __typename?: 'organization' }
    & OrganizationFragment
  )> }
);

export type GetOrganizationsQueryVariables = Exact<{
  distinctOn?: Maybe<Array<OrganizationSelectColumn> | OrganizationSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OrganizationOrderBy> | OrganizationOrderBy>;
  where?: Maybe<OrganizationBoolExp>;
}>;


export type GetOrganizationsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'organization' }
    & OrganizationFragment
  )> }
);

export type GetOrganizationByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetOrganizationByPkQuery = (
  { __typename?: 'query_root' }
  & { entity?: Maybe<(
    { __typename?: 'organization' }
    & OrganizationFragment
  )> }
);

export type GetOrganizationPartialsQueryVariables = Exact<{
  distinctOn?: Maybe<Array<OrganizationSelectColumn> | OrganizationSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OrganizationOrderBy> | OrganizationOrderBy>;
  where?: Maybe<OrganizationBoolExp>;
}>;


export type GetOrganizationPartialsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'organization' }
    & OrganizationPartialFragment
  )> }
);

export type ProjectPartialFragment = (
  { __typename?: 'project_project' }
  & Pick<ProjectProject, 'id' | 'fiscal_year' | 'title' | 'reference' | 'industry' | 'introduction' | 'start_at' | 'end_at' | 'step_id' | 'national_strategy_lvl1_id' | 'national_strategy_lvl2_id' | 'national_strategy_lvl3_id' | 'national_strategy_lvl4_id' | 'organization_id' | 'submitted_date' | 'approved_date' | 'approved_comment' | 'approved_id' | 'completed_date' | 'created_at' | 'created_by' | 'deleted_at' | 'last_overall_operation' | 'updated_by' | 'updated_at' | 'total_budget'>
  & { national_strategy_lvl1?: Maybe<(
    { __typename?: 'project_national_strategy' }
    & Pick<ProjectNationalStrategy, 'id' | 'name' | 'depth' | 'parent_id'>
  )>, national_strategy_lvl2?: Maybe<(
    { __typename?: 'project_national_strategy' }
    & Pick<ProjectNationalStrategy, 'id' | 'name' | 'depth' | 'parent_id'>
  )>, national_strategy_lvl3?: Maybe<(
    { __typename?: 'project_national_strategy' }
    & Pick<ProjectNationalStrategy, 'id' | 'name' | 'depth' | 'parent_id'>
  )>, national_strategy_lvl4?: Maybe<(
    { __typename?: 'project_national_strategy' }
    & Pick<ProjectNationalStrategy, 'id' | 'name' | 'depth' | 'parent_id'>
  )> }
);

export type ProjectFragment = (
  { __typename?: 'project_project' }
  & { organization?: Maybe<(
    { __typename?: 'organization' }
    & Pick<Organization, 'id' | 'name' | 'short_name'>
  )>, project_step: (
    { __typename?: 'project_project_step' }
    & Pick<ProjectProjectStep, 'id' | 'description'>
  ), project_objectives: Array<(
    { __typename?: 'project_project_objective' }
    & ProjectObjectiveFragment
  )>, project_target_groups: Array<(
    { __typename?: 'project_project_target_group' }
    & Pick<ProjectProjectTargetGroup, 'target_group_id' | 'sequence'>
  )>, project_operating_areas: Array<(
    { __typename?: 'project_project_operating_area' }
    & Pick<ProjectProjectOperatingArea, 'province_id' | 'sequence'>
  )>, project_operating_activities: Array<(
    { __typename?: 'project_project_operating_activity' }
    & { budget_items: Array<(
      { __typename?: 'project_project_budget_item' }
      & ProjectBudgetItemFragment
    )> }
    & ProjectOperatingActivityFragment
  )>, project_indicators: Array<(
    { __typename?: 'project_project_indicator' }
    & ProjectIndicatorFragment
  )>, output: Array<(
    { __typename?: 'project_project_indicator' }
    & ProjectIndicatorFragment
  )>, outcome: Array<(
    { __typename?: 'project_project_indicator' }
    & ProjectIndicatorFragment
  )>, project_achievements: Array<(
    { __typename?: 'project_project_achievement' }
    & ProjectAchievementFragment
  )>, project_members: Array<(
    { __typename?: 'project_project_member' }
    & ProjectMemberFragment
  )> }
  & ProjectPartialFragment
);

export type ProjectAttachmentFragment = (
  { __typename?: 'project_project_attachment' }
  & Pick<ProjectProjectAttachment, 'attachment_type_id' | 'updated_at' | 'size' | 'project_id' | 'original_name' | 'note' | 'mimetype' | 'id' | 'file_path' | 'deleted_at' | 'filename' | 'created_by' | 'created_at' | 'updated_by'>
);

export type ProjectOperatingActivityFragment = (
  { __typename?: 'project_project_operating_activity' }
  & Pick<ProjectProjectOperatingActivity, 'id' | 'project_id' | 'name' | 'sequence' | 'description' | 'target_amount' | 'target_unit_id' | 'budget_group_id' | 'operating_activity_type_id'>
  & { budget_group: (
    { __typename?: 'project_budget_group' }
    & Pick<ProjectBudgetGroup, 'id' | 'description'>
  ), target_unit?: Maybe<(
    { __typename?: 'project_unit' }
    & Pick<ProjectUnit, 'id' | 'description'>
  )>, operating_activity_type: (
    { __typename?: 'project_operating_activity_type' }
    & Pick<ProjectOperatingActivityType, 'id' | 'description'>
  ) }
);

export type ProjectMemberFragment = (
  { __typename?: 'project_project_member' }
  & Pick<ProjectProjectMember, 'id' | 'first_name' | 'last_name' | 'email' | 'organization_id' | 'position' | 'project_id' | 'sequence' | 'tel' | 'title'>
);

export type ProjectAchievementFragment = (
  { __typename?: 'project_project_achievement' }
  & Pick<ProjectProjectAchievement, 'id' | 'kpi' | 'name' | 'project_id' | 'project_objective_id' | 'sequence'>
);

export type ProjectObjectivePartialFragment = (
  { __typename?: 'project_project_objective' }
  & Pick<ProjectProjectObjective, 'id' | 'name' | 'sequence' | 'project_id'>
);

export type ProjectObjectiveFragment = (
  { __typename?: 'project_project_objective' }
  & Pick<ProjectProjectObjective, 'id' | 'name' | 'sequence' | 'project_id'>
  & { output: Array<(
    { __typename?: 'project_project_indicator' }
    & ProjectIndicatorFragment
  )>, outcome: Array<(
    { __typename?: 'project_project_indicator' }
    & ProjectIndicatorFragment
  )>, achievements: Array<(
    { __typename?: 'project_project_achievement' }
    & ProjectAchievementFragment
  )> }
);

export type ProjectIndicatorFragment = (
  { __typename?: 'project_project_indicator' }
  & Pick<ProjectProjectIndicator, 'id' | 'amount' | 'name' | 'indicator_type_id' | 'unit_id' | 'project_objective_id' | 'project_id' | 'sequence'>
  & { unit?: Maybe<(
    { __typename?: 'project_unit' }
    & Pick<ProjectUnit, 'id' | 'description'>
  )> }
);

export type ProjectBudgetItemFragment = (
  { __typename?: 'project_project_budget_item' }
  & Pick<ProjectProjectBudgetItem, 'id' | 'budget_group_id' | 'budget_id' | 'multiplier' | 'note' | 'unit_amount' | 'unit_of_multiplier_id' | 'unit_id' | 'price_per_unit' | 'sequence' | 'total' | 'project_operating_activity_id'>
  & { unit_of_multiplier?: Maybe<(
    { __typename?: 'project_unit' }
    & Pick<ProjectUnit, 'description'>
  )>, unit?: Maybe<(
    { __typename?: 'project_unit' }
    & Pick<ProjectUnit, 'description'>
  )>, budget_group: (
    { __typename?: 'project_budget_group' }
    & Pick<ProjectBudgetGroup, 'id' | 'description'>
  ), budget: (
    { __typename?: 'project_budget' }
    & Pick<ProjectBudget, 'id' | 'name' | 'fiscal_year'>
  ) }
);

export type ProjectBudgetTemplateFragment = (
  { __typename?: 'project_project_budget_template' }
  & Pick<ProjectProjectBudgetTemplate, 'id' | 'name' | 'max_price_per_unit' | 'fiscal_year' | 'deleted_at' | 'price_per_unit' | 'sequence' | 'unit_id' | 'unit_of_multiplier_id'>
  & { unit_of_multiplier?: Maybe<(
    { __typename?: 'project_unit' }
    & Pick<ProjectUnit, 'description'>
  )>, unit?: Maybe<(
    { __typename?: 'project_unit' }
    & Pick<ProjectUnit, 'description'>
  )> }
);

export type ProjectEventLogFragment = (
  { __typename?: 'project_project_event_log' }
  & Pick<ProjectProjectEventLog, 'id' | 'description' | 'created_at' | 'actor_user_id' | 'actor_organization_id' | 'approved_id' | 'project_id' | 'public' | 'reference' | 'remark' | 'step_id' | 'updated_at'>
  & { user?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'display_name' | 'id' | 'first_name' | 'last_name' | 'title_id'>
  )>, organization?: Maybe<(
    { __typename?: 'organization' }
    & Pick<Organization, 'id' | 'name' | 'area_code' | 'reference' | 'province' | 'short_name' | 'unit_code'>
  )>, approved?: Maybe<(
    { __typename?: 'project_approved' }
    & Pick<ProjectApproved, 'description' | 'id'>
  )> }
);

export type ProjectOperatingActivityWithProgressesFragment = (
  { __typename?: 'project_project_operating_activity' }
  & { budget_items_aggregate: (
    { __typename?: 'project_project_budget_item_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'project_project_budget_item_aggregate_fields' }
      & { sum?: Maybe<(
        { __typename?: 'project_project_budget_item_sum_fields' }
        & Pick<ProjectProjectBudgetItemSumFields, 'total'>
      )> }
    )> }
  ), progresses: Array<(
    { __typename?: 'project_operating_activity_progress' }
    & Pick<ProjectOperatingActivityProgress, 'id' | 'month' | 'revision' | 'spend' | 'spend_plan' | 'project_operating_activity_id' | 'workload' | 'workload_plan'>
  )> }
  & ProjectOperatingActivityFragment
);

export type InsertProjectOneMutationVariables = Exact<{
  object: ProjectProjectInsertInput;
}>;


export type InsertProjectOneMutation = (
  { __typename?: 'mutation_root' }
  & { insert_project_project_one?: Maybe<(
    { __typename?: 'project_project' }
    & ProjectPartialFragment
  )> }
);

export type UpdateProjectByPkTmpMutationVariables = Exact<{
  project?: Maybe<ProjectProjectSetInput>;
  id: Scalars['uuid'];
}>;


export type UpdateProjectByPkTmpMutation = (
  { __typename?: 'mutation_root' }
  & { project?: Maybe<(
    { __typename?: 'project_project' }
    & ProjectPartialFragment
  )> }
);

export type UpdateProjectByPkMutationVariables = Exact<{
  project?: Maybe<ProjectProjectSetInput>;
  id: Scalars['uuid'];
  insertedProvinceIds: Array<Scalars['uuid']> | Scalars['uuid'];
  operatingAreas: Array<ProjectProjectOperatingAreaInsertInput> | ProjectProjectOperatingAreaInsertInput;
  insertedTargetGroupIds: Array<Scalars['String']> | Scalars['String'];
  operatingTargetGroups: Array<ProjectProjectTargetGroupInsertInput> | ProjectProjectTargetGroupInsertInput;
}>;


export type UpdateProjectByPkMutation = (
  { __typename?: 'mutation_root' }
  & { project?: Maybe<(
    { __typename?: 'project_project' }
    & ProjectPartialFragment
  )>, delete_project_project_target_group?: Maybe<(
    { __typename?: 'project_project_target_group_mutation_response' }
    & Pick<ProjectProjectTargetGroupMutationResponse, 'affected_rows'>
  )>, insert_project_project_target_group?: Maybe<(
    { __typename?: 'project_project_target_group_mutation_response' }
    & Pick<ProjectProjectTargetGroupMutationResponse, 'affected_rows'>
  )>, delete_project_project_operating_area?: Maybe<(
    { __typename?: 'project_project_operating_area_mutation_response' }
    & Pick<ProjectProjectOperatingAreaMutationResponse, 'affected_rows'>
  )>, insert_project_project_operating_area?: Maybe<(
    { __typename?: 'project_project_operating_area_mutation_response' }
    & Pick<ProjectProjectOperatingAreaMutationResponse, 'affected_rows'>
  )> }
);

export type ReplaceProjectOperatingActivitiesMutationVariables = Exact<{
  projectId: Scalars['uuid'];
  insertedActivityIds?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  insertActivityData: Array<ProjectProjectOperatingActivityInsertInput> | ProjectProjectOperatingActivityInsertInput;
}>;


export type ReplaceProjectOperatingActivitiesMutation = (
  { __typename?: 'mutation_root' }
  & { deleted_rows?: Maybe<(
    { __typename?: 'project_project_operating_activity_mutation_response' }
    & Pick<ProjectProjectOperatingActivityMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'project_project_operating_activity' }
      & Pick<ProjectProjectOperatingActivity, 'id' | 'project_id' | 'budget_group_id'>
    )> }
  )>, insert_rows?: Maybe<(
    { __typename?: 'project_project_operating_activity_mutation_response' }
    & Pick<ProjectProjectOperatingActivityMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'project_project_operating_activity' }
      & Pick<ProjectProjectOperatingActivity, 'id' | 'project_id' | 'budget_group_id'>
    )> }
  )> }
);

export type ReplaceProjectBudgetItemsMutationVariables = Exact<{
  projectId: Scalars['uuid'];
  activityId: Scalars['uuid'];
  insertedBudgetItemIds?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  insertBudgetItemsData: Array<ProjectProjectBudgetItemInsertInput> | ProjectProjectBudgetItemInsertInput;
}>;


export type ReplaceProjectBudgetItemsMutation = (
  { __typename?: 'mutation_root' }
  & { deleted_rows?: Maybe<(
    { __typename?: 'project_project_budget_item_mutation_response' }
    & Pick<ProjectProjectBudgetItemMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'project_project_budget_item' }
      & Pick<ProjectProjectBudgetItem, 'id' | 'multiplier' | 'unit_id' | 'unit_of_multiplier_id' | 'unit_amount' | 'total' | 'sequence' | 'price_per_unit' | 'budget_group_id' | 'note' | 'budget_id'>
    )> }
  )>, insert_rows?: Maybe<(
    { __typename?: 'project_project_budget_item_mutation_response' }
    & Pick<ProjectProjectBudgetItemMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'project_project_budget_item' }
      & Pick<ProjectProjectBudgetItem, 'id' | 'multiplier' | 'unit_id' | 'unit_of_multiplier_id' | 'unit_amount' | 'total' | 'sequence' | 'price_per_unit' | 'budget_group_id' | 'note' | 'budget_id'>
    )> }
  )> }
);

export type ReplaceProjectObjectivesMutationVariables = Exact<{
  projectId: Scalars['uuid'];
  insertedIndicatorsIds?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  insertedObjectivesIds?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  insertedAchievementsIds?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  insertedObjectivesData: Array<ProjectProjectObjectiveInsertInput> | ProjectProjectObjectiveInsertInput;
}>;


export type ReplaceProjectObjectivesMutation = (
  { __typename?: 'mutation_root' }
  & { delete_project_project_objective?: Maybe<(
    { __typename?: 'project_project_objective_mutation_response' }
    & Pick<ProjectProjectObjectiveMutationResponse, 'affected_rows'>
  )>, delete_project_project_indicator?: Maybe<(
    { __typename?: 'project_project_indicator_mutation_response' }
    & Pick<ProjectProjectIndicatorMutationResponse, 'affected_rows'>
  )>, delete_project_project_achievement?: Maybe<(
    { __typename?: 'project_project_achievement_mutation_response' }
    & Pick<ProjectProjectAchievementMutationResponse, 'affected_rows'>
  )>, insert_project_project_objective?: Maybe<(
    { __typename?: 'project_project_objective_mutation_response' }
    & Pick<ProjectProjectObjectiveMutationResponse, 'affected_rows'>
  )> }
);

export type ProjectSubmitFormMutationVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ProjectSubmitFormMutation = (
  { __typename?: 'mutation_root' }
  & { project_project_submit: (
    { __typename?: 'ProjectSubmitOutput' }
    & Pick<ProjectSubmitOutput, 'projectId'>
  ) }
);

export type ProjectApproveFormMutationVariables = Exact<{
  data: ProjectApprovalInput;
}>;


export type ProjectApproveFormMutation = (
  { __typename?: 'mutation_root' }
  & { project_project_approve: (
    { __typename?: 'ProjectApprovalOutput' }
    & Pick<ProjectApprovalOutput, 'projectId'>
  ) }
);

export type UpdateProjectOperatingActivityProgressesMutationVariables = Exact<{
  objects: Array<ProjectOperatingActivityProgressInsertInput> | ProjectOperatingActivityProgressInsertInput;
  update_columns: Array<ProjectOperatingActivityProgressUpdateColumn> | ProjectOperatingActivityProgressUpdateColumn;
}>;


export type UpdateProjectOperatingActivityProgressesMutation = (
  { __typename?: 'mutation_root' }
  & { operating_activity_progresses?: Maybe<(
    { __typename?: 'project_operating_activity_progress_mutation_response' }
    & Pick<ProjectOperatingActivityProgressMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'project_operating_activity_progress' }
      & Pick<ProjectOperatingActivityProgress, 'id' | 'month' | 'revision' | 'spend' | 'workload'>
    )> }
  )> }
);

export type InsertProjectAttachmentOneMutationVariables = Exact<{
  object: ProjectProjectAttachmentInsertInput;
}>;


export type InsertProjectAttachmentOneMutation = (
  { __typename?: 'mutation_root' }
  & { attachment?: Maybe<(
    { __typename?: 'project_project_attachment' }
    & ProjectAttachmentFragment
  )> }
);

export type DeleteProjectAttachmentByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteProjectAttachmentByPkMutation = (
  { __typename?: 'mutation_root' }
  & { attachment?: Maybe<(
    { __typename?: 'project_project_attachment' }
    & ProjectAttachmentFragment
  )> }
);

export type ProjectDetailFragment = (
  { __typename?: 'project_project' }
  & Pick<ProjectProject, 'id' | 'approved_comment' | 'approved_date' | 'approved_id' | 'cancelled_date' | 'change_request_date' | 'completed_date' | 'created_at' | 'created_by' | 'deleted_at' | 'effect' | 'end_at' | 'fiscal_year' | 'industry' | 'introduction' | 'last_overall_operation' | 'national_strategy_lvl1_id' | 'national_strategy_lvl2_id' | 'national_strategy_lvl3_id' | 'national_strategy_lvl4_id' | 'national_strategy_lvl5_id' | 'organization_id' | 'reference' | 'updated_by' | 'updated_at' | 'total_budget' | 'title' | 'submitted_date' | 'step_id' | 'start_at' | 'rejected_date'>
);

export type UpdateProjectDetailByPkMutationVariables = Exact<{
  projectId: Scalars['uuid'];
  data?: Maybe<ProjectProjectSetInput>;
}>;


export type UpdateProjectDetailByPkMutation = (
  { __typename?: 'mutation_root' }
  & { project?: Maybe<(
    { __typename?: 'project_project' }
    & Pick<ProjectProject, 'id'>
  )> }
);

export type InsertProjectObjectiveOneMutationVariables = Exact<{
  data: ProjectProjectObjectiveInsertInput;
}>;


export type InsertProjectObjectiveOneMutation = (
  { __typename?: 'mutation_root' }
  & { objective?: Maybe<(
    { __typename?: 'project_project_objective' }
    & ProjectObjectivePartialFragment
  )> }
);

export type UpdateProjectObjectiveByPkMutationVariables = Exact<{
  objectiveId: Scalars['uuid'];
  data?: Maybe<ProjectProjectObjectiveSetInput>;
}>;


export type UpdateProjectObjectiveByPkMutation = (
  { __typename?: 'mutation_root' }
  & { objective?: Maybe<(
    { __typename?: 'project_project_objective' }
    & ProjectObjectivePartialFragment
  )> }
);

export type DeleteProjectObjectiveByPkMutationVariables = Exact<{
  objectiveId: Scalars['uuid'];
}>;


export type DeleteProjectObjectiveByPkMutation = (
  { __typename?: 'mutation_root' }
  & { objective?: Maybe<(
    { __typename?: 'project_project_objective' }
    & ProjectObjectivePartialFragment
  )> }
);

export type InsertProjectIndicatorOneMutationVariables = Exact<{
  data: ProjectProjectIndicatorInsertInput;
}>;


export type InsertProjectIndicatorOneMutation = (
  { __typename?: 'mutation_root' }
  & { objective?: Maybe<(
    { __typename?: 'project_project_indicator' }
    & ProjectIndicatorFragment
  )> }
);

export type UpdateProjectIndicatorByPkMutationVariables = Exact<{
  indicatorId: Scalars['uuid'];
  data?: Maybe<ProjectProjectIndicatorSetInput>;
}>;


export type UpdateProjectIndicatorByPkMutation = (
  { __typename?: 'mutation_root' }
  & { objective?: Maybe<(
    { __typename?: 'project_project_indicator' }
    & ProjectIndicatorFragment
  )> }
);

export type DeleteProjectIndicatorByPkMutationVariables = Exact<{
  indicatorId: Scalars['uuid'];
}>;


export type DeleteProjectIndicatorByPkMutation = (
  { __typename?: 'mutation_root' }
  & { objective?: Maybe<(
    { __typename?: 'project_project_indicator' }
    & ProjectIndicatorFragment
  )> }
);

export type InsertProjectAchievementOneMutationVariables = Exact<{
  data: ProjectProjectAchievementInsertInput;
}>;


export type InsertProjectAchievementOneMutation = (
  { __typename?: 'mutation_root' }
  & { objective?: Maybe<(
    { __typename?: 'project_project_achievement' }
    & ProjectAchievementFragment
  )> }
);

export type UpdateProjectAchievementByPkMutationVariables = Exact<{
  achievementId: Scalars['uuid'];
  data?: Maybe<ProjectProjectAchievementSetInput>;
}>;


export type UpdateProjectAchievementByPkMutation = (
  { __typename?: 'mutation_root' }
  & { objective?: Maybe<(
    { __typename?: 'project_project_achievement' }
    & ProjectAchievementFragment
  )> }
);

export type DeleteProjectAchievementByPkMutationVariables = Exact<{
  achievementId: Scalars['uuid'];
}>;


export type DeleteProjectAchievementByPkMutation = (
  { __typename?: 'mutation_root' }
  & { objective?: Maybe<(
    { __typename?: 'project_project_achievement' }
    & ProjectAchievementFragment
  )> }
);

export type InsertProjectMemberOneMutationVariables = Exact<{
  data: ProjectProjectMemberInsertInput;
}>;


export type InsertProjectMemberOneMutation = (
  { __typename?: 'mutation_root' }
  & { member?: Maybe<(
    { __typename?: 'project_project_member' }
    & ProjectMemberFragment
  )> }
);

export type UpdateProjectMemberByPkMutationVariables = Exact<{
  memberId: Scalars['uuid'];
  data?: Maybe<ProjectProjectMemberSetInput>;
}>;


export type UpdateProjectMemberByPkMutation = (
  { __typename?: 'mutation_root' }
  & { member?: Maybe<(
    { __typename?: 'project_project_member' }
    & ProjectMemberFragment
  )> }
);

export type DeleteProjectMemberByPkMutationVariables = Exact<{
  memberId: Scalars['uuid'];
}>;


export type DeleteProjectMemberByPkMutation = (
  { __typename?: 'mutation_root' }
  & { member?: Maybe<(
    { __typename?: 'project_project_member' }
    & ProjectMemberFragment
  )> }
);

export type GetProjectsQueryVariables = Exact<{
  distinctOn?: Maybe<Array<ProjectProjectSelectColumn> | ProjectProjectSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProjectProjectOrderBy> | ProjectProjectOrderBy>;
  where?: Maybe<ProjectProjectBoolExp>;
}>;


export type GetProjectsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'project_project' }
    & ProjectFragment
  )> }
);

export type GetProjectByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetProjectByPkQuery = (
  { __typename?: 'query_root' }
  & { row?: Maybe<(
    { __typename?: 'project_project' }
    & ProjectFragment
  )> }
);

export type GetProjectMasterDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProjectMasterDataQuery = (
  { __typename?: 'query_root' }
  & { target_groups: Array<(
    { __typename?: 'project_target_group' }
    & Pick<ProjectTargetGroup, 'id' | 'description'>
  )>, regions: Array<(
    { __typename?: 'region' }
    & Pick<Region, 'id' | 'name_th'>
    & { provinces: Array<(
      { __typename?: 'province' }
      & Pick<Province, 'id' | 'code' | 'name_th'>
    )> }
  )>, units: Array<(
    { __typename?: 'project_unit' }
    & Pick<ProjectUnit, 'id' | 'description'>
  )>, activity_types: Array<(
    { __typename?: 'project_operating_activity_type' }
    & Pick<ProjectOperatingActivityType, 'id' | 'description'>
  )>, indicator_types: Array<(
    { __typename?: 'project_indicator_type' }
    & Pick<ProjectIndicatorType, 'id' | 'description'>
  )>, budget_groups: Array<(
    { __typename?: 'project_budget_group' }
    & Pick<ProjectBudgetGroup, 'id' | 'description'>
  )>, budgets: Array<(
    { __typename?: 'project_budget' }
    & Pick<ProjectBudget, 'id' | 'name' | 'price_per_unit' | 'unit_id' | 'fiscal_year' | 'deleted_at' | 'unit_of_multiplier_id'>
    & { unit_of_multiplier?: Maybe<(
      { __typename?: 'project_unit' }
      & Pick<ProjectUnit, 'description'>
    )>, unit?: Maybe<(
      { __typename?: 'project_unit' }
      & Pick<ProjectUnit, 'description'>
    )> }
  )>, budget_group_mapping: Array<(
    { __typename?: 'project_budget_group_mapping' }
    & Pick<ProjectBudgetGroupMapping, 'budget_id' | 'budget_group_id'>
  )> }
);

export type GetProjectByPkWithMasterDataQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetProjectByPkWithMasterDataQuery = (
  { __typename?: 'query_root' }
  & { project?: Maybe<(
    { __typename?: 'project_project' }
    & ProjectFragment
  )>, project_budget_items: Array<(
    { __typename?: 'project_project_budget_item' }
    & ProjectBudgetItemFragment
  )>, target_groups: Array<(
    { __typename?: 'project_target_group' }
    & Pick<ProjectTargetGroup, 'id' | 'description'>
  )>, regions: Array<(
    { __typename?: 'region' }
    & Pick<Region, 'id' | 'name_th'>
    & { provinces: Array<(
      { __typename?: 'province' }
      & Pick<Province, 'id' | 'code' | 'name_th'>
    )> }
  )>, units: Array<(
    { __typename?: 'project_unit' }
    & Pick<ProjectUnit, 'id' | 'description'>
  )>, activity_types: Array<(
    { __typename?: 'project_operating_activity_type' }
    & Pick<ProjectOperatingActivityType, 'id' | 'description'>
  )>, indicator_types: Array<(
    { __typename?: 'project_indicator_type' }
    & Pick<ProjectIndicatorType, 'id' | 'description'>
  )>, budget_groups: Array<(
    { __typename?: 'project_budget_group' }
    & Pick<ProjectBudgetGroup, 'id' | 'description'>
  )>, budgets: Array<(
    { __typename?: 'project_budget' }
    & Pick<ProjectBudget, 'id' | 'name' | 'price_per_unit' | 'unit_id' | 'fiscal_year' | 'deleted_at' | 'unit_of_multiplier_id'>
    & { unit_of_multiplier?: Maybe<(
      { __typename?: 'project_unit' }
      & Pick<ProjectUnit, 'description'>
    )>, unit?: Maybe<(
      { __typename?: 'project_unit' }
      & Pick<ProjectUnit, 'description'>
    )> }
  )>, budget_group_mapping: Array<(
    { __typename?: 'project_budget_group_mapping' }
    & Pick<ProjectBudgetGroupMapping, 'budget_id' | 'budget_group_id'>
  )>, event_logs: Array<(
    { __typename?: 'project_project_event_log' }
    & ProjectEventLogFragment
  )> }
);

export type GetProjectBudgetItemsByProjectIdQueryVariables = Exact<{
  projectId: Scalars['uuid'];
  fiscalYear: Scalars['Int'];
}>;


export type GetProjectBudgetItemsByProjectIdQuery = (
  { __typename?: 'query_root' }
  & { budgets: Array<(
    { __typename?: 'project_budget' }
    & Pick<ProjectBudget, 'id' | 'fiscal_year' | 'name' | 'price_per_unit' | 'unit_id' | 'unit_of_multiplier_id'>
  )>, project_budget_items: Array<(
    { __typename?: 'project_project_budget_item' }
    & ProjectBudgetItemFragment
  )> }
);

export type GetProjectEventLogsByProjectIdQueryVariables = Exact<{
  projectId: Scalars['uuid'];
}>;


export type GetProjectEventLogsByProjectIdQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'project_project_event_log' }
    & ProjectEventLogFragment
  )> }
);

export type GetProjectOperatingActivitiesByProjectIdQueryVariables = Exact<{
  projectId: Scalars['uuid'];
}>;


export type GetProjectOperatingActivitiesByProjectIdQuery = (
  { __typename?: 'query_root' }
  & { project?: Maybe<(
    { __typename?: 'project_project' }
    & ProjectPartialFragment
  )>, rows: Array<(
    { __typename?: 'project_project_operating_activity' }
    & ProjectOperatingActivityWithProgressesFragment
  )> }
);

export type ProjectOperatingActivityWithBudgetFragment = (
  { __typename?: 'project_project_operating_activity' }
  & Pick<ProjectProjectOperatingActivity, 'id' | 'project_id' | 'name' | 'sequence' | 'description' | 'target_amount' | 'target_unit_id' | 'budget_group_id' | 'operating_activity_type_id'>
  & { budget_group: (
    { __typename?: 'project_budget_group' }
    & Pick<ProjectBudgetGroup, 'id' | 'description'>
  ), target_unit?: Maybe<(
    { __typename?: 'project_unit' }
    & Pick<ProjectUnit, 'id' | 'description'>
  )>, operating_activity_type: (
    { __typename?: 'project_operating_activity_type' }
    & Pick<ProjectOperatingActivityType, 'id' | 'description'>
  ), budget_items: Array<(
    { __typename?: 'project_project_budget_item' }
    & ProjectBudgetItemFragment
  )> }
);

export type GetProjectActivitiesByProjectIdQueryVariables = Exact<{
  projectId: Scalars['uuid'];
}>;


export type GetProjectActivitiesByProjectIdQuery = (
  { __typename?: 'query_root' }
  & { activities: Array<(
    { __typename?: 'project_project_operating_activity' }
    & ProjectOperatingActivityWithBudgetFragment
  )>, budget_groups: Array<(
    { __typename?: 'project_budget_group' }
    & Pick<ProjectBudgetGroup, 'id' | 'description'>
  )>, budgets: Array<(
    { __typename?: 'project_project_budget_template' }
    & ProjectBudgetTemplateFragment
  )>, budget_group_mapping: Array<(
    { __typename?: 'project_budget_group_mapping' }
    & Pick<ProjectBudgetGroupMapping, 'budget_id' | 'budget_group_id'>
  )>, units: Array<(
    { __typename?: 'project_unit' }
    & Pick<ProjectUnit, 'id' | 'description'>
  )> }
);

export type GetProjectAttachmentsByProjectIdQueryVariables = Exact<{
  projectId: Scalars['uuid'];
}>;


export type GetProjectAttachmentsByProjectIdQuery = (
  { __typename?: 'query_root' }
  & { attachments: Array<(
    { __typename?: 'project_project_attachment' }
    & ProjectAttachmentFragment
  )> }
);

export type GetProjectObjectivesByProjectIdQueryVariables = Exact<{
  projectId: Scalars['uuid'];
}>;


export type GetProjectObjectivesByProjectIdQuery = (
  { __typename?: 'query_root' }
  & { objectives: Array<(
    { __typename?: 'project_project_objective' }
    & ProjectObjectiveFragment
  )> }
);

export type GetProjectMembersByProjectIdQueryVariables = Exact<{
  projectId: Scalars['uuid'];
}>;


export type GetProjectMembersByProjectIdQuery = (
  { __typename?: 'query_root' }
  & { members: Array<(
    { __typename?: 'project_project_member' }
    & ProjectMemberFragment
  )> }
);

export type ProjectBudgetFragment = (
  { __typename?: 'project_budget' }
  & Pick<ProjectBudget, 'id' | 'name' | 'price_per_unit' | 'fiscal_year' | 'unit_id' | 'unit_of_multiplier_id'>
  & { unit?: Maybe<(
    { __typename?: 'project_unit' }
    & Pick<ProjectUnit, 'description' | 'id'>
  )>, unit_of_multiplier?: Maybe<(
    { __typename?: 'project_unit' }
    & Pick<ProjectUnit, 'description' | 'id'>
  )>, budget_group_mappings: Array<(
    { __typename?: 'project_budget_group_mapping' }
    & { budget_group: (
      { __typename?: 'project_budget_group' }
      & Pick<ProjectBudgetGroup, 'description'>
    ) }
  )> }
);

export type UnitTypeFragment = (
  { __typename?: 'project_unit' }
  & Pick<ProjectUnit, 'id' | 'description'>
);

export type InsertBudgetOneMutationVariables = Exact<{
  object: ProjectBudgetInsertInput;
}>;


export type InsertBudgetOneMutation = (
  { __typename?: 'mutation_root' }
  & { insert_project_budget_one?: Maybe<(
    { __typename?: 'project_budget' }
    & ProjectBudgetFragment
  )> }
);

export type UpdateBudgetByPkMutationVariables = Exact<{
  _set?: Maybe<ProjectBudgetSetInput>;
  id: Scalars['uuid'];
}>;


export type UpdateBudgetByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_project_budget_by_pk?: Maybe<(
    { __typename?: 'project_budget' }
    & ProjectBudgetFragment
  )> }
);

export type DeleteBudgetByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteBudgetByPkMutation = (
  { __typename?: 'mutation_root' }
  & { budget?: Maybe<(
    { __typename?: 'project_budget' }
    & ProjectBudgetFragment
  )> }
);

export type GetBudgetsQueryVariables = Exact<{
  distinctOn?: Maybe<Array<ProjectBudgetSelectColumn> | ProjectBudgetSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProjectBudgetOrderBy> | ProjectBudgetOrderBy>;
  where?: Maybe<ProjectBudgetBoolExp>;
}>;


export type GetBudgetsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'project_budget' }
    & ProjectBudgetFragment
  )> }
);

export type GetUnitIdQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnitIdQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'project_unit' }
    & UnitTypeFragment
  )> }
);

export type GetBudgetByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetBudgetByPkQuery = (
  { __typename?: 'query_root' }
  & { row?: Maybe<(
    { __typename?: 'project_budget' }
    & ProjectBudgetFragment
  )> }
);

export type GetProjectSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProjectSummaryQuery = (
  { __typename?: 'query_root' }
  & { projects: Array<(
    { __typename?: 'project_project' }
    & Pick<ProjectProject, 'id' | 'title' | 'organization_id'>
    & { organization?: Maybe<(
      { __typename?: 'organization' }
      & Pick<Organization, 'id' | 'short_name'>
    )>, budget_summary: Array<(
      { __typename?: 'project_project_budget_summary' }
      & Pick<ProjectProjectBudgetSummary, 'operating_activity_id' | 'project_id' | 'total_budget' | 'total_spend' | 'total_spend_plan'>
      & { activity?: Maybe<(
        { __typename?: 'project_project_operating_activity' }
        & Pick<ProjectProjectOperatingActivity, 'id' | 'name'>
      )> }
    )>, workload_summary: Array<(
      { __typename?: 'project_project_workload_summary' }
      & Pick<ProjectProjectWorkloadSummary, 'operating_activity_id' | 'project_id' | 'target_amount' | 'target_unit_id' | 'target_unit_name' | 'total_workload' | 'total_workload_plan'>
      & { activity?: Maybe<(
        { __typename?: 'project_project_operating_activity' }
        & Pick<ProjectProjectOperatingActivity, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type FiscalYearFragment = (
  { __typename?: 'fiscal_year' }
  & Pick<FiscalYear, 'year'>
);

export type ProjectBudgetGroupMappingFragment = (
  { __typename?: 'project_budget' }
  & Pick<ProjectBudget, 'id' | 'fiscal_year' | 'max_price_per_unit' | 'name' | 'sequence' | 'price_per_unit' | 'unit_id' | 'unit_of_multiplier_id' | 'deleted_at'>
  & { unit_of_multiplier?: Maybe<(
    { __typename?: 'project_unit' }
    & Pick<ProjectUnit, 'id' | 'description'>
  )>, unit?: Maybe<(
    { __typename?: 'project_unit' }
    & Pick<ProjectUnit, 'id' | 'description'>
  )>, group_mappings: (
    { __typename?: 'project_budget_group_mapping_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'project_budget_group_mapping_aggregate_fields' }
      & Pick<ProjectBudgetGroupMappingAggregateFields, 'count'>
    )> }
  ) }
);

export type ProjectBudgetGroupFragment = (
  { __typename?: 'project_budget_group' }
  & Pick<ProjectBudgetGroup, 'id' | 'description'>
);

export type GetProjectBudgetGroupsQueryVariables = Exact<{
  distinctOn?: Maybe<Array<ProjectBudgetGroupSelectColumn> | ProjectBudgetGroupSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProjectBudgetGroupOrderBy> | ProjectBudgetGroupOrderBy>;
  where?: Maybe<ProjectBudgetGroupBoolExp>;
}>;


export type GetProjectBudgetGroupsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'project_budget_group' }
    & ProjectBudgetGroupFragment
  )> }
);

export type GetProjectBudgetGroupByPkQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetProjectBudgetGroupByPkQuery = (
  { __typename?: 'query_root' }
  & { row?: Maybe<(
    { __typename?: 'project_budget_group' }
    & ProjectBudgetGroupFragment
  )> }
);

export type GetProjectBudgetsByFiscalYearAndGroupIdQueryVariables = Exact<{
  fiscalYear: Scalars['Int'];
  budgetGroupId: ProjectBudgetGroupEnum;
}>;


export type GetProjectBudgetsByFiscalYearAndGroupIdQuery = (
  { __typename?: 'query_root' }
  & { budgets: Array<(
    { __typename?: 'project_budget' }
    & ProjectBudgetGroupMappingFragment
  )> }
);

export type ReplaceProjectBudgetByFiscalYearAndGroupIdMutationVariables = Exact<{
  fiscalYear: Scalars['Int'];
  budgetGroupId: ProjectBudgetGroupEnum;
  budgetIds: Array<Scalars['uuid']> | Scalars['uuid'];
  budgetGroupMappingData: Array<ProjectBudgetGroupMappingInsertInput> | ProjectBudgetGroupMappingInsertInput;
}>;


export type ReplaceProjectBudgetByFiscalYearAndGroupIdMutation = (
  { __typename?: 'mutation_root' }
  & { delete_project_budget_group_mapping?: Maybe<(
    { __typename?: 'project_budget_group_mapping_mutation_response' }
    & Pick<ProjectBudgetGroupMappingMutationResponse, 'affected_rows'>
  )>, insert_project_budget_group_mapping?: Maybe<(
    { __typename?: 'project_budget_group_mapping_mutation_response' }
    & Pick<ProjectBudgetGroupMappingMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'project_budget_group_mapping' }
      & Pick<ProjectBudgetGroupMapping, 'budget_group_id' | 'budget_id'>
    )> }
  )> }
);

export type GetProjectBudgetItemSummaryByOrganizationIdQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
}>;


export type GetProjectBudgetItemSummaryByOrganizationIdQuery = (
  { __typename?: 'query_root' }
  & { project_project_budget_item_summary: Array<(
    { __typename?: 'project_project_budget_item_summary' }
    & Pick<ProjectProjectBudgetItemSummary, 'id' | 'amount_unit_name' | 'budget_group_id' | 'budget_id' | 'multipier_unit_name' | 'multiplier' | 'note' | 'operating_activity_name' | 'organization_id' | 'price_per_unit' | 'project_operating_activity_id' | 'sequence' | 'target_amount' | 'target_unit_id' | 'total' | 'target_unit_name' | 'unit_of_multiplier_id' | 'unit_id' | 'unit_amount'>
  )> }
);

export type NationalStrategyFragment = (
  { __typename?: 'project_national_strategy' }
  & Pick<ProjectNationalStrategy, 'id' | 'name' | 'depth' | 'parent_id'>
);

export type InsertNationalStrategyOneMutationVariables = Exact<{
  object: ProjectNationalStrategyInsertInput;
}>;


export type InsertNationalStrategyOneMutation = (
  { __typename?: 'mutation_root' }
  & { national_strategy?: Maybe<(
    { __typename?: 'project_national_strategy' }
    & NationalStrategyFragment
  )> }
);

export type UpdateNationalStrategyByPkMutationVariables = Exact<{
  _set?: Maybe<ProjectNationalStrategySetInput>;
  id: Scalars['String'];
}>;


export type UpdateNationalStrategyByPkMutation = (
  { __typename?: 'mutation_root' }
  & { national_strategy?: Maybe<(
    { __typename?: 'project_national_strategy' }
    & NationalStrategyFragment
  )> }
);

export type DeleteNationalStrategyByPkMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteNationalStrategyByPkMutation = (
  { __typename?: 'mutation_root' }
  & { national_strategy?: Maybe<(
    { __typename?: 'project_national_strategy' }
    & NationalStrategyFragment
  )> }
);

export type GetNationalStrategiesQueryVariables = Exact<{
  distinctOn?: Maybe<Array<ProjectNationalStrategySelectColumn> | ProjectNationalStrategySelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProjectNationalStrategyOrderBy> | ProjectNationalStrategyOrderBy>;
  where?: Maybe<ProjectNationalStrategyBoolExp>;
}>;


export type GetNationalStrategiesQuery = (
  { __typename?: 'query_root' }
  & { national_strategies: Array<(
    { __typename?: 'project_national_strategy' }
    & { parent?: Maybe<(
      { __typename?: 'project_national_strategy' }
      & NationalStrategyFragment
    )> }
    & NationalStrategyFragment
  )> }
);

export type GetNationalStrategiesTreeViewQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNationalStrategiesTreeViewQuery = (
  { __typename?: 'query_root' }
  & { national_strategies: Array<(
    { __typename?: 'project_national_strategy' }
    & { children: Array<(
      { __typename?: 'project_national_strategy' }
      & { children: Array<(
        { __typename?: 'project_national_strategy' }
        & { children: Array<(
          { __typename?: 'project_national_strategy' }
          & { children: Array<(
            { __typename?: 'project_national_strategy' }
            & NationalStrategyFragment
          )> }
          & NationalStrategyFragment
        )> }
        & NationalStrategyFragment
      )> }
      & NationalStrategyFragment
    )> }
    & NationalStrategyFragment
  )> }
);

export type GetNationalStrategyByPkQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetNationalStrategyByPkQuery = (
  { __typename?: 'query_root' }
  & { national_strategy?: Maybe<(
    { __typename?: 'project_national_strategy' }
    & { children: Array<(
      { __typename?: 'project_national_strategy' }
      & NationalStrategyFragment
    )> }
    & NationalStrategyFragment
  )> }
);

export type TargetGroupFragment = (
  { __typename?: 'project_target_group' }
  & Pick<ProjectTargetGroup, 'description' | 'id'>
);

export type InsertTargetGroupOneMutationVariables = Exact<{
  object: ProjectTargetGroupInsertInput;
}>;


export type InsertTargetGroupOneMutation = (
  { __typename?: 'mutation_root' }
  & { insert_project_target_group_one?: Maybe<(
    { __typename?: 'project_target_group' }
    & TargetGroupFragment
  )> }
);

export type UpdateTargetGroupByPkMutationVariables = Exact<{
  _set?: Maybe<ProjectTargetGroupSetInput>;
  id: Scalars['String'];
}>;


export type UpdateTargetGroupByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_project_target_group_by_pk?: Maybe<(
    { __typename?: 'project_target_group' }
    & TargetGroupFragment
  )> }
);

export type GetTargetGroupsQueryVariables = Exact<{
  distinctOn?: Maybe<Array<ProjectTargetGroupSelectColumn> | ProjectTargetGroupSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProjectTargetGroupOrderBy> | ProjectTargetGroupOrderBy>;
  where?: Maybe<ProjectTargetGroupBoolExp>;
}>;


export type GetTargetGroupsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'project_target_group' }
    & TargetGroupFragment
  )> }
);

export type GetTargetGroupByPkQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetTargetGroupByPkQuery = (
  { __typename?: 'query_root' }
  & { row?: Maybe<(
    { __typename?: 'project_target_group' }
    & TargetGroupFragment
  )> }
);

export type UnitFragment = (
  { __typename?: 'project_unit' }
  & Pick<ProjectUnit, 'description' | 'id'>
);

export type InsertUnitOneMutationVariables = Exact<{
  object: ProjectUnitInsertInput;
}>;


export type InsertUnitOneMutation = (
  { __typename?: 'mutation_root' }
  & { unit?: Maybe<(
    { __typename?: 'project_unit' }
    & UnitFragment
  )> }
);

export type UpdateUnitByPkMutationVariables = Exact<{
  _set?: Maybe<ProjectUnitSetInput>;
  id: Scalars['String'];
}>;


export type UpdateUnitByPkMutation = (
  { __typename?: 'mutation_root' }
  & { unit?: Maybe<(
    { __typename?: 'project_unit' }
    & UnitFragment
  )> }
);

export type DeleteUnitByPkMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteUnitByPkMutation = (
  { __typename?: 'mutation_root' }
  & { unit?: Maybe<(
    { __typename?: 'project_unit' }
    & UnitFragment
  )> }
);

export type GetUnitsQueryVariables = Exact<{
  distinctOn?: Maybe<Array<ProjectUnitSelectColumn> | ProjectUnitSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProjectUnitOrderBy> | ProjectUnitOrderBy>;
  where?: Maybe<ProjectUnitBoolExp>;
}>;


export type GetUnitsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'project_unit' }
    & UnitFragment
  )> }
);

export type GetUnitByPkQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUnitByPkQuery = (
  { __typename?: 'query_root' }
  & { row?: Maybe<(
    { __typename?: 'project_unit' }
    & UnitFragment
  )> }
);

export type GetUnauthorizedUserAccountByIdQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type GetUnauthorizedUserAccountByIdQuery = (
  { __typename?: 'query_root' }
  & { user?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'display_name'>
    & { account?: Maybe<(
      { __typename?: 'auth_accounts' }
      & Pick<AuthAccounts, 'id' | 'email'>
    )> }
  )> }
);

export type UserFragment = (
  { __typename?: 'users' }
  & Pick<Users, 'avatar_url' | 'created_at' | 'department_id' | 'display_name' | 'first_name' | 'id' | 'last_name' | 'organization_id' | 'reference' | 'sequence' | 'title_id' | 'consent_checked' | 'updated_at' | 'tel' | 'note' | 'bank_branch_id'>
  & { organization?: Maybe<(
    { __typename?: 'organization' }
    & Pick<Organization, 'name' | 'area_code' | 'province' | 'reference' | 'sequence' | 'short_name' | 'unit_code'>
  )>, account?: Maybe<(
    { __typename?: 'auth_accounts' }
    & Pick<AuthAccounts, 'email' | 'default_role' | 'id' | 'active' | 'custom_register_data'>
    & { account_providers: Array<(
      { __typename?: 'auth_account_providers' }
      & Pick<AuthAccountProviders, 'auth_provider'>
    )> }
  )>, name_title?: Maybe<(
    { __typename?: 'name_title' }
    & Pick<NameTitle, 'description' | 'id'>
  )>, department?: Maybe<(
    { __typename?: 'department' }
    & Pick<Department, 'id' | 'name' | 'name_en' | 'short_name' | 'short_name_en'>
  )> }
);

export type UserAccountRolesFragment = (
  { __typename?: 'users' }
  & { account?: Maybe<(
    { __typename?: 'auth_accounts' }
    & { account_roles: Array<(
      { __typename?: 'auth_account_roles' }
      & Pick<AuthAccountRoles, 'role'>
    )> }
  )> }
);

export type PermissionFragment = (
  { __typename?: 'permission' }
  & Pick<Permission, 'id' | 'description' | 'active' | 'sequence'>
);

export type UserPermissionFragment = (
  { __typename?: 'users_permission' }
  & Pick<UsersPermission, 'user_id' | 'permission_id'>
);

export type UpdateUserByPkMutationVariables = Exact<{
  user_id: Scalars['uuid'];
  _set?: Maybe<UsersSetInput>;
}>;


export type UpdateUserByPkMutation = (
  { __typename?: 'mutation_root' }
  & { row?: Maybe<(
    { __typename?: 'users' }
    & UserFragment
  )> }
);

export type UpdateAuthAccountByPkMutationVariables = Exact<{
  account_id: Scalars['uuid'];
  _set?: Maybe<AuthAccountsSetInput>;
}>;


export type UpdateAuthAccountByPkMutation = (
  { __typename?: 'mutation_root' }
  & { row?: Maybe<(
    { __typename?: 'auth_accounts' }
    & Pick<AuthAccounts, 'id'>
  )> }
);

export type ReplaceAccountRolesMutationVariables = Exact<{
  account_id: Scalars['uuid'];
  selected_roles: Array<Scalars['String']> | Scalars['String'];
  insert_roles: Array<AuthAccountRolesInsertInput> | AuthAccountRolesInsertInput;
  default_role: Scalars['String'];
}>;


export type ReplaceAccountRolesMutation = (
  { __typename?: 'mutation_root' }
  & { delete_rows?: Maybe<(
    { __typename?: 'auth_account_roles_mutation_response' }
    & Pick<AuthAccountRolesMutationResponse, 'affected_rows'>
  )>, insert_rows?: Maybe<(
    { __typename?: 'auth_account_roles_mutation_response' }
    & Pick<AuthAccountRolesMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'auth_account_roles' }
      & Pick<AuthAccountRoles, 'id' | 'role'>
    )> }
  )>, row?: Maybe<(
    { __typename?: 'auth_accounts' }
    & Pick<AuthAccounts, 'id' | 'default_role'>
  )> }
);

export type ReplaceUserPermissionsMutationVariables = Exact<{
  user_id: Scalars['uuid'];
  selected_permissions: Array<Scalars['String']> | Scalars['String'];
  insert_permissions: Array<UsersPermissionInsertInput> | UsersPermissionInsertInput;
}>;


export type ReplaceUserPermissionsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_rows?: Maybe<(
    { __typename?: 'users_permission_mutation_response' }
    & Pick<UsersPermissionMutationResponse, 'affected_rows'>
  )>, insert_rows?: Maybe<(
    { __typename?: 'users_permission_mutation_response' }
    & Pick<UsersPermissionMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'users_permission' }
      & UserPermissionFragment
    )> }
  )> }
);

export type ChangePasswordMutationVariables = Exact<{
  data: ChangePasswordInput;
}>;


export type ChangePasswordMutation = (
  { __typename?: 'mutation_root' }
  & { account: (
    { __typename?: 'ChangePasswordOutput' }
    & Pick<ChangePasswordOutput, 'accountId'>
  ) }
);

export type GetUsersQueryVariables = Exact<{
  distinctOn?: Maybe<Array<UsersSelectColumn> | UsersSelectColumn>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<UsersOrderBy> | UsersOrderBy>;
  where?: Maybe<UsersBoolExp>;
}>;


export type GetUsersQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'users' }
    & { user_permissions: Array<(
      { __typename?: 'users_permission' }
      & { permission: (
        { __typename?: 'permission' }
        & PermissionFragment
      ) }
      & UserPermissionFragment
    )> }
    & UserFragment
    & UserAccountRolesFragment
  )> }
);

export type GetAccountRolesSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountRolesSummaryQuery = (
  { __typename?: 'query_root' }
  & { total: (
    { __typename?: 'auth_account_roles_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'auth_account_roles_aggregate_fields' }
      & Pick<AuthAccountRolesAggregateFields, 'count'>
    )> }
  ), user: (
    { __typename?: 'auth_account_roles_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'auth_account_roles_aggregate_fields' }
      & Pick<AuthAccountRolesAggregateFields, 'count'>
    )> }
  ), employee: (
    { __typename?: 'auth_account_roles_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'auth_account_roles_aggregate_fields' }
      & Pick<AuthAccountRolesAggregateFields, 'count'>
    )> }
  ), bank: (
    { __typename?: 'auth_account_roles_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'auth_account_roles_aggregate_fields' }
      & Pick<AuthAccountRolesAggregateFields, 'count'>
    )> }
  ), sysadmin: (
    { __typename?: 'auth_account_roles_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'auth_account_roles_aggregate_fields' }
      & Pick<AuthAccountRolesAggregateFields, 'count'>
    )> }
  ) }
);

export type GetAccountRolesByAccountIdQueryVariables = Exact<{
  account_id: Scalars['uuid'];
}>;


export type GetAccountRolesByAccountIdQuery = (
  { __typename?: 'query_root' }
  & { account?: Maybe<(
    { __typename?: 'auth_accounts' }
    & Pick<AuthAccounts, 'id' | 'default_role'>
  )>, rows: Array<(
    { __typename?: 'auth_account_roles' }
    & Pick<AuthAccountRoles, 'role'>
  )> }
);

export type GetUserByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetUserByPkQuery = (
  { __typename?: 'query_root' }
  & { entity?: Maybe<(
    { __typename?: 'users' }
    & UserFragment
    & UserAccountRolesFragment
  )>, returning?: Maybe<(
    { __typename?: 'users' }
    & { permissions: Array<(
      { __typename?: 'users_permission' }
      & { permission: (
        { __typename?: 'permission' }
        & PermissionFragment
      ) }
      & UserPermissionFragment
    )> }
    & UserFragment
    & UserAccountRolesFragment
  )> }
);

export type GetPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPermissionsQuery = (
  { __typename?: 'query_root' }
  & { rows: Array<(
    { __typename?: 'permission' }
    & Pick<Permission, 'id' | 'description' | 'active' | 'sequence'>
  )> }
);

export type GetUserPermissionsByUserIdQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type GetUserPermissionsByUserIdQuery = (
  { __typename?: 'query_root' }
  & { permissions: Array<(
    { __typename?: 'permission' }
    & PermissionFragment
  )>, user_permissions: Array<(
    { __typename?: 'users_permission' }
    & UserPermissionFragment
  )> }
);

export type GetFiscalYearsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFiscalYearsQuery = (
  { __typename?: 'query_root' }
  & { fiscal_years: Array<(
    { __typename?: 'fiscal_year' }
    & FiscalYearFragment
  )> }
);

export const AccountTypeFragmentDoc = gql`
    fragment AccountType on account_account_type {
  id
  description
}
    `;
export const AccountCategoryFragmentDoc = gql`
    fragment AccountCategory on account_account_category {
  id
  name
  note
  deleted_at
  created_at
  updated_at
}
    `;
export const AccountFragmentDoc = gql`
    fragment Account on account_account {
  id
  code
  name
  category_id
  account_type {
    ...AccountType
  }
  account_category {
    ...AccountCategory
  }
  default_entry_type_id
  entry_type {
    id
    description
  }
}
    ${AccountTypeFragmentDoc}
${AccountCategoryFragmentDoc}`;
export const AccountFullFragmentDoc = gql`
    fragment AccountFull on account_account {
  id
  code
  name
  fiscal_year
  note
  parent_id
  type_id
  account_type {
    ...AccountType
  }
  category_id
  account_category {
    ...AccountCategory
  }
  default_entry_type_id
  entry_type {
    id
    description
  }
  created_at
  updated_at
  deleted_at
}
    ${AccountTypeFragmentDoc}
${AccountCategoryFragmentDoc}`;
export const AccountBudgetAllocationFragmentDoc = gql`
    fragment AccountBudgetAllocation on account_budget_allocation {
  id
  budget_total
  budget_type {
    description
    id
    sequence
    fiscal_year
  }
  budget_type_id
  created_at
  fiscal_year
  organization_id
  updated_at
  organization {
    name
    province
    reference
    area_code
    short_name
    unit_code
  }
}
    `;
export const AccountLendingStatusFragmentDoc = gql`
    fragment AccountLendingStatus on account_lending_status {
  id
  description
}
    `;
export const AccountLendingFragmentDoc = gql`
    fragment AccountLending on account_lending {
  amount
  borrower_name
  created_at
  created_by
  date
  description
  due_date
  fiscal_year
  gfmis_id
  id
  organization_id
  reference
  status_id
  updated_by
  updated_at
  status {
    ...AccountLendingStatus
  }
  organization {
    area_code
    id
    name
    short_name
    reference
  }
  remark
}
    ${AccountLendingStatusFragmentDoc}`;
export const AccountLendingItemFragmentDoc = gql`
    fragment AccountLendingItem on account_lending_item {
  id
  updated_by
  updated_at
  note
  name
  lending_id
  date
  created_by
  created_at
  amount
}
    `;
export const PublicAttachmentFragmentDoc = gql`
    fragment PublicAttachment on attachment {
  deleted_at
  created_at
  created_by
  file_path
  filename
  id
  mimetype
  name
  original_name
}
    `;
export const PostFragmentDoc = gql`
    fragment Post on posts {
  created_at
  created_by
  description
  id
  key
  published
  title
  updated_at
  updated_by
}
    `;
export const BankBranchFragmentDoc = gql`
    fragment BankBranch on bank_branch {
  branch
  id
  note
  province
}
    `;
export const BudgetTypeFragmentDoc = gql`
    fragment BudgetType on account_budget_type {
  id
  description
  sequence
  deleted_at
  fiscal_year
  account_id
  account {
    id
    code
    name
  }
  closed
  closed_at
  group_id
  group_name
}
    `;
export const BudgetAccountFragmentDoc = gql`
    fragment BudgetAccount on account_budget_account {
  budget_id
  budget_type {
    ...BudgetType
  }
  account {
    id
    account_type {
      id
      description
    }
    type_id
    name
    code
    deleted_at
  }
}
    ${BudgetTypeFragmentDoc}`;
export const ContractAgreementFragmentDoc = gql`
    fragment ContractAgreement on contract_agreement {
  address
  af_number
  af_status
  af_status_description
  insurance_due_date
  af_status_name
  repay_day_of_month
  approved_date
  bank_branch_id
  bank_branch {
    id
    branch
    province
  }
  cf_number
  business_group
  business_type
  contact_date
  contact_email
  contact_fax
  contact_first_name
  contact_last_name
  contact_tel
  contact_mobile
  contract_number
  created_by
  created_at
  credit_total
  customer_type
  d_damt
  effective_date
  first_repay_date
  first_withdraw_date
  grace_period
  id
  imported_at
  juristic_id
  juristic_name_en
  juristic_name_th
  loan_description
  monthly_payment
  objective
  period
  program_id
  program_name
  program_reference
  project_description
  program {
    name
  }
  register_number
  updated_by
  guarantor_name
  guarantor_citizen_id
  guarantor_description
  transactions_aggregate: agreement_transactions_aggregate {
    aggregate {
      sum {
        interest_amount
      }
    }
  }
}
    `;
export const ContractAgreementRepayFragmentDoc = gql`
    fragment ContractAgreementRepay on contract_agreement_repay_current_month {
  contract_number
  register_number
  remaining_repay_day
  repay_date
  repay_day_of_month
  repay_status
  agreement {
    ...ContractAgreement
  }
}
    ${ContractAgreementFragmentDoc}`;
export const ContractAgreementTransactionFragmentDoc = gql`
    fragment ContractAgreementTransaction on contract_agreement_transaction {
  contract_number
  created_at
  creator_type
  credit_total
  default_rate
  due_date
  grade_value
  id
  imported_at
  interest_rate
  interest_rate_type
  last_repay_date
  outstanding_balance
  repay_day_of_month
  user_id
  user {
    display_name
  }
  withdraw_date
  accru_total
  aging_grade
  aging_outstanding_period
  approved_date
  ar_code
  bank_branch_officer
  bot_code
  capital_npl
  capital_outstanding
  commercial_credit_department
  commercial_credit_department_officer
  credit_amount
  debt_restructuring_department
  debt_restructuring_department_officer
  debt_type
  document_department
  document_department_officer
  effective_date
  fine_accru
  fine_amount
  fine_outstanding
  first_repay_date
  fully_repay_flag
  grade_gl
  industry
  insurance_interest
  interest_accru
  interest_amount
  interest_outstanding
  lawsuit_department
  lawsuit_department_officer
  monthly_payment
  non_recognition_interest
  old_ref
  out_insurance
  restructuring_first_repay_date
  restructuring_effective_date
  restructuring_approved_date
  outstanding_capital_start_date
  outstanding_interest_start_date
  outstanding_period
  outstanding_total
  period
  previous_outstanding_balance
  project_reference
  register_number
  repay_balance
  recognition_interest
  status
  suspended
  suspened_interest
  suspened_non_recognition_interest
  term_overdue_gl
  suspened_recognition_interest
  responsible_organization
  spread_rate
  cluster
  res_n
}
    `;
export const ContractAgreementAttachmentFragmentDoc = gql`
    fragment ContractAgreementAttachment on contract_agreement_attachment {
  agreement_id
  updated_by
  updated_at
  type_id
  size
  original_name
  note
  mimetype
  file_path
  id
  filename
  deleted_at
  created_by
  created_at
  attachment_type {
    id
    description
  }
}
    `;
export const ContractAgreementDetailFragmentDoc = gql`
    fragment ContractAgreementDetail on contract_agreement_detail {
  agreement {
    juristic_id
    juristic_name_th
  }
  af_number
  af_status
  af_status_description
  approved_date
  cf_number
  business_type
  contact_date
  withdraw_total
  contract_number
  credit_total
  effective_date
  first_withdraw_date
  id
  period
  register_number
  credit_balance
  request_date
  need_credit
  isic_description
  interest_description
  fl
  credit_balance
  closing_date
  created_at
  imported_at
}
    `;
export const ContractAgreementReceiptFragmentDoc = gql`
    fragment ContractAgreementReceipt on contract_agreement_receipt {
  id: receipt_no
  receipt_no
  agreement {
    juristic_name_th
    juristic_id
    id
  }
  contract_number
  register_number
  created_at
  imported_at
  amount
  created_by
  updated_by
  updated_at
  juristic_name
  status
  report_date
  receipt_no
  previous_outstanding_balance
  outstanding_balance
  other_income
  last_repay_date
  interest_amount
  fine_amount
  credit_amount
}
    `;
export const ContractAgreementWithdrawFragmentDoc = gql`
    fragment ContractAgreementWithdraw on contract_agreement_withdraw {
  id: draw_down_no
  agreement {
    juristic_name_th
    juristic_id
    id
  }
  contract_number
  credit_total
  register_number
  credit_balance
  request_date
  credit_balance
  created_at
  imported_at
  amount
  bank_branch_id
  bank_branch_name
  created_by
  withdraw_date
  updated_by
  updated_at
  program_reference
  program_id
  program_name
  juristic_name
  draw_down_no
  program {
    id
    name
    reference
  }
}
    `;
export const AccountLedgerEntryFragmentDoc = gql`
    fragment AccountLedgerEntry on account_ledger_entry {
  account_id
  amount
  budget_id
  created_at
  date
  entry_type
  fiscal_year
  id
  gfmis_id
  name
  note
  organization_id
  reference
  transaction_id
  organization {
    id
    name
    short_name
  }
  created_by
  entry_type_node {
    id
    description
  }
  budget_type {
    id
    description
    fiscal_year
  }
  account {
    code
    name
    id
    account_type {
      description
      id
    }
  }
}
    `;
export const AccountTransactionFragmentDoc = gql`
    fragment AccountTransaction on account_transaction {
  id
  gfmis_id
  organization_id
  status_id
  approved_at
  approved_by
  cancelled_at
  cancelled_by
  change_requested_at
  change_requested_by
  date
  rejected_at
  rejected_by
  reference
  fiscal_year
  description
  created_at
  created_by
  lending_id
  is_allocation
  transaction_type {
    id
    description
  }
  created_by_user {
    id
    display_name
  }
  transaction_status {
    id
    description
  }
  organization {
    name
    short_name
    area_code
    unit_code
    reference
  }
}
    `;
export const AccountTransactionLogFragmentDoc = gql`
    fragment AccountTransactionLog on account_transaction_log {
  id
  description
  comment
  created_by
  created_at
  new_status
  old_status
  transaction_status_new {
    id
    description
  }
  transaction_status_old {
    id
    description
  }
  transaction_id
  created_by_user {
    display_name
    first_name
    last_name
    reference
    organization {
      short_name
    }
  }
}
    `;
export const ImportLogFragmentDoc = gql`
    fragment ImportLog on import_log {
  id
  name
  filename
  user_id
  user {
    id
    display_name
  }
  created_at
}
    `;
export const JuristicFragmentDoc = gql`
    fragment Juristic on juristic {
  id
  juristic_status
  name_en
  name_th
  number_of_commitee
  number_of_objective
  paid_register_capital
  register_capital
  register_date
  type
  is_member
}
    `;
export const JuristicAddressFragmentDoc = gql`
    fragment JuristicAddress on juristic_address {
  address_no
  amphoe
  building
  district
  email
  floor
  id
  moo
  phone
  province
  road
  room_no
  sequence
  soi
  village_name
}
    `;
export const JuristicCommitteeFragmentDoc = gql`
    fragment JuristicCommittee on juristic_committee {
  citizen_id
  first_name
  id
  last_name
  sequence
  title
}
    `;
export const BudgetAllocationFragmentDoc = gql`
    fragment BudgetAllocation on ledger_budget_allocation {
  id
  amount
  budget_id
  created_at
  created_by
  fiscal_year
  organization_id
  updated_at
  updated_by
}
    `;
export const ReceivableLogFragmentDoc = gql`
    fragment ReceivableLog on ledger_receivable_log {
  id
  description
  new_status {
    description
    id
  }
  new_status_id
  receivable_id
  organization_id
  old_status {
    id
    description
  }
  created_by
  created_by_user {
    display_name
  }
  created_at
  action
}
    `;
export const ReceivableFragmentDoc = gql`
    fragment Receivable on ledger_receivable {
  id
  amount
  approved_at
  approved_by
  borrower_name
  created_at
  created_by
  date
  due_date
  gfmis_id
  locked_at
  locked_by
  note
  refund_amount
  organization_id
  proposal
  organization {
    id
    name
    province
    short_name
    reference
  }
  received_date
  reference
  submitted_at
  submitted_by
  updated_at
  updated_by
  done_at
  status_id
  status {
    id
    description
  }
  created_by_user {
    display_name
  }
  updated_by_user {
    display_name
  }
  done_by
  fiscal_year
  budget_id
  budget {
    id
    fiscal_year
    description
  }
  expense_account_id
  expense_account {
    id
    name
    code
  }
}
    `;
export const ReceivableItemFragmentDoc = gql`
    fragment ReceivableItem on ledger_receivable_item {
  id
  amount
  created_at
  created_by
  date
  locked_at
  locked_by
  name
  note
  updated_by
  updated_at
  receivable_id
}
    `;
export const ReceivableWithItemsFragmentDoc = gql`
    fragment ReceivableWithItems on ledger_receivable {
  ...Receivable
  receivable_items {
    ...ReceivableItem
  }
}
    ${ReceivableFragmentDoc}
${ReceivableItemFragmentDoc}`;
export const LedgerFragmentDoc = gql`
    fragment Ledger on ledger_ledger {
  id
  updated_by
  updated_at
  submitted_by
  submitted_at
  organization_id
  receivable_id
  note
  locked_by
  locked_at
  gfmis_id
  fiscal_year
  date
  created_by
  created_at
  change_request_by
  change_request_at
  budget_id
  approved_by
  approved_at
  amount
  account_id
  created_by_user {
    display_name
  }
  organization {
    name
    short_name
    reference
    province
    id
  }
  budget {
    account_id
    id
    fiscal_year
    description
  }
  account {
    code
    id
    fiscal_year
    name
    parent_id
    type_id
  }
  status_id
  status {
    id
    description
  }
  receivable {
    id
    reference
    borrower_name
    amount
    refund_amount
  }
  type_id
}
    `;
export const LedgerLogFragmentDoc = gql`
    fragment LedgerLog on ledger_ledger_log {
  id
  description
  ledger_id
  new_status_id
  organization_id
  old_status_id
  new_status {
    id
    description
  }
  created_by_user {
    id
    display_name
  }
  created_at
  action
  created_by
  old_status {
    id
    description
  }
}
    `;
export const LedgerAttachmentFragmentDoc = gql`
    fragment LedgerAttachment on ledger_ledger_attachment {
  id
  filename
  file_path
  created_by
  created_at
  ledger_id
  note
  original_name
  updated_at
  updated_by
}
    `;
export const LoanApplicationAttachmentFragmentDoc = gql`
    fragment LoanApplicationAttachment on loan_application_attachment {
  size
  original_name
  note
  mimetype
  id
  filename
  attachment_type_id
  application_header_id
  file_path
  attachment_type {
    id
    sequence
    required
    description
  }
  created_at
  approve
  approve_at
}
    `;
export const LoanApplicationEventLogFragmentDoc = gql`
    fragment LoanApplicationEventLog on loan_application_event_log {
  application_header_id
  step_id
  sme_user_id
  remark
  reference
  juristic_id
  id
  document_approve_value
  description
  created_at
  committee_approve_value
  bank_approve_value
  actor_user_id
  actor_organization_id
  sme_user {
    first_name
    last_name
    name_title {
      description
    }
  }
  actor_user {
    first_name
    last_name
    name_title {
      description
    }
  }
}
    `;
export const LoanApplicationHeaderFragmentDoc = gql`
    fragment LoanApplicationHeader on loan_application_header {
  id
  bank_approve {
    value
    description
  }
  bank_approve_date
  bank_approve_credit
  business_group_description
  business_group_id
  business_proprietary_description
  business_proprietary_else
  business_proprietary_id
  business_status_description
  business_status_id
  business_type_description
  business_type_id
  committee_approve {
    value
    description
  }
  committee_approve_credit
  committee_approve_date
  completed_date
  contact_email
  contact_first_name
  contact_last_name
  contact_tel
  contact_position
  created_at
  created_by
  deleted_at
  document_approve {
    value
    description
  }
  document_approve_date
  email
  expect_credit_total
  juristic_id
  name
  office_address_amphoe
  office_address_district
  office_address_province
  office_address_street
  office_address_zipcode
  program_id
  project_value_total
  reference
  self_spend_total
  start_date
  step_id
  submitted_date
  tel
  updated_at
  updated_by
  working_address_zipcode
  working_address_street
  working_address_province
  working_address_is_office_address
  working_address_district
  working_address_amphoe
  user_id
  application_step {
    description
    value
  }
  organization_id
  organization {
    name
    short_name
  }
  bank_branch_id
  bank_branch {
    branch
  }
  document_approve_value
  committee_approve_value
  bank_approve_value
  document_approve_comment
  committee_approve_comment
  bank_approve_comment
}
    `;
export const LoanApplicationDetailFragmentDoc = gql`
    fragment LoanApplicationDetail on loan_application_detail {
  id
  business_proposal
  cost_cash
  cost
  cost_day
  created_at
  cost_credit
  created_by
  current_liability_amount_total
  current_liability_day
  current_loan_description
  current_loan_else
  current_loan_id
  current_loan_list
  debt_restructuring_description
  debt_restructuring_else
  debt_restructuring_id
  deleted_at
  earned_income_credit
  financial_agreement_description
  financial_agreement_else
  financial_agreement_id
  financial_rescue_description
  financial_rescue_else
  financial_rescue_id
  finished_goods_amount_total
  foreign_income_cash
  foreign_income_credit
  foreign_income_day
  foreign_income_percent
  gross_profit
  inventory_valuation
  local_income_cash
  local_income_credit
  local_income_day
  local_income_percent
  net_profit
  proprietary_description
  proprietary_else
  proprietary_id
  raw_material_amount_total
  repay_type_description
  repay_type_else
  repay_type_id
  service_cost
  sme_definition_description
  updated_by
  updated_at
  sme_definition_else
  sme_definition_id
  account_type_id
  account_type_else
  account_type_description
  repay_type {
    sequence
    id
    description
    active
  }
  proprietary {
    sequence
    id
    description
    active
  }
  financial_rescue {
    sequence
    id
    description
    active
  }
  financial_agreement {
    sequence
    id
    description
    active
  }
  debt_restructuring {
    sequence
    id
    description
    active
  }
  current_loan {
    sequence
    id
    description
    active
  }
  account_type {
    sequence
    id
    description
    active
  }
}
    `;
export const LoanApplicationProfileFragmentDoc = gql`
    fragment LoanApplicationProfile on loan_application_profile {
  company_history
  company_product
  current_customer
  distribution
  executive_history
  id
  innovation_usage
  major_customer
  major_supplier
  prominent_point
  target_group
  technology_usage
  else_usage
}
    `;
export const LoanApplicationHeaderFullFragmentDoc = gql`
    fragment LoanApplicationHeaderFull on loan_application_header {
  ...LoanApplicationHeader
  detail: application_detail {
    ...LoanApplicationDetail
  }
  profile: application_profile {
    ...LoanApplicationProfile
  }
  committees: application_committees(where: {authorization: {_eq: true}}) {
    active
    authorization
    contact_address_amphoe
    contact_address_district
    contact_address_mobile
    contact_address_fax
    contact_address_province
    contact_address_street
    contact_address_tel
    contact_address_zipcode
    first_name
    from_dbd
    id
    full_name
    last_name
    title
    primary_address_amphoe
    primary_address_district
    primary_address_is_contact_address
    primary_address_province
    primary_address_zipcode
    primary_address_street
    sequence
    citizen_id
    title
    working_address_amphoe
    working_address_district
    working_address_fax
    working_address_is_contact_address
    working_address_province
    working_address_street
    working_address_tel
    working_address_zipcode
    application_header_id
  }
  objectives: application_objectives(order_by: [{sequence: asc_nulls_last}]) {
    id
    title
    description
    project_value
    self_spend
    need_credit
    need_addition
  }
}
    ${LoanApplicationHeaderFragmentDoc}
${LoanApplicationDetailFragmentDoc}
${LoanApplicationProfileFragmentDoc}`;
export const LoanApplicationOrganizationTransferFragmentDoc = gql`
    fragment LoanApplicationOrganizationTransfer on loan_application_organization_transfer {
  id
  updated_at
  to_organization {
    id
    area_code
    name
    name_en
    province
    reference
    sequence
    short_name
    short_name_en
    unit_code
  }
  status_value
  status {
    value
    description
  }
  old_reference
  new_reference
  note
  from_organization {
    area_code
    id
    name
    name_en
    province
    reference
    sequence
    short_name
    short_name_en
    unit_code
  }
  approver {
    display_name
    first_name
    id
    last_name
    department_id
    organization_id
    tel
    title_id
    reference
    sequence
  }
  approver_id
  application_header_id
  application_header {
    name
    juristic_id
    reference
    submitted_date
  }
  created_at
  approved_at
  approved_note
}
    `;
export const LoanApplicationOrganizationTransferFullFragmentDoc = gql`
    fragment LoanApplicationOrganizationTransferFull on loan_application_organization_transfer {
  old_reference
  new_reference
  id
  updated_at
  to_organization {
    id
    area_code
    name
    name_en
    province
    reference
    sequence
    short_name
    short_name_en
    unit_code
  }
  status {
    value
    description
  }
  note
  from_organization {
    area_code
    id
    name
    name_en
    province
    reference
    sequence
    short_name
    short_name_en
    unit_code
  }
  approver {
    display_name
    first_name
    id
    last_name
    department_id
    organization_id
    tel
    title_id
    reference
    sequence
  }
  approver_id
  application_header_id
  application_header {
    id
    name
    juristic_id
    reference
    submitted_date
    business_type_description
    business_group_description
    business_status_description
    business_proprietary_description
    tel
    email
    contact_first_name
    contact_last_name
    contact_tel
    contact_email
    expect_credit_total
    committee_approve_credit
  }
  created_at
  approved_at
  approved_note
}
    `;
export const LoanProgramFragmentDoc = gql`
    fragment LoanProgram on loan_program {
  id
  budget_total
  name
  reference
  start_date
  end_date
  description
  status
  max_credit
}
    `;
export const LoanSourceAttachmentFragmentDoc = gql`
    fragment LoanSourceAttachment on loan_source_loan_attachment {
  created_at
  created_by
  file_path
  filename
  id
  loan_id
  mimetype
  note
  original_name
  size
  updated_at
  updated_by
}
    `;
export const LoanSourceFragmentDoc = gql`
    fragment LoanSource on loan_source_loan {
  created_at
  created_by
  credit_limit
  id
  max_interest
  min_interest
  name
  period
  promotion
  published
  reference
  source_id
  updated_by
  updated_at
  financial_institution {
    description
    id
  }
  loan_business_types {
    business_type_id
    loan_id
    business_type {
      description
    }
  }
  objectives {
    id
    loan_id
    name
    sequence
  }
  attachments {
    ...LoanSourceAttachment
  }
}
    ${LoanSourceAttachmentFragmentDoc}`;
export const LoanSourceObjectiveFragmentDoc = gql`
    fragment LoanSourceObjective on loan_source_objective {
  id
  loan_id
  name
  sequence
}
    `;
export const LoanSourceInstitutionFragmentDoc = gql`
    fragment LoanSourceInstitution on loan_source_financial_institution {
  id
  description
}
    `;
export const OrganizationFragmentDoc = gql`
    fragment Organization on organization {
  area_code
  id
  name
  name_en
  province
  reference
  sequence
  short_name
  short_name_en
  unit_code
}
    `;
export const OrganizationPartialFragmentDoc = gql`
    fragment OrganizationPartial on organization {
  area_code
  id
  name
  province
  reference
}
    `;
export const ProjectPartialFragmentDoc = gql`
    fragment ProjectPartial on project_project {
  id
  fiscal_year
  title
  reference
  industry
  introduction
  start_at
  end_at
  step_id
  national_strategy_lvl1_id
  national_strategy_lvl2_id
  national_strategy_lvl3_id
  national_strategy_lvl4_id
  national_strategy_lvl1 {
    id
    name
    depth
    parent_id
  }
  national_strategy_lvl2 {
    id
    name
    depth
    parent_id
  }
  national_strategy_lvl3 {
    id
    name
    depth
    parent_id
  }
  national_strategy_lvl4 {
    id
    name
    depth
    parent_id
  }
  organization_id
  submitted_date
  approved_date
  approved_comment
  approved_id
  completed_date
  created_at
  created_by
  deleted_at
  last_overall_operation
  updated_by
  updated_at
  total_budget
}
    `;
export const ProjectIndicatorFragmentDoc = gql`
    fragment ProjectIndicator on project_project_indicator {
  id
  amount
  name
  indicator_type_id
  unit_id
  project_objective_id
  project_id
  sequence
  unit {
    id
    description
  }
}
    `;
export const ProjectAchievementFragmentDoc = gql`
    fragment ProjectAchievement on project_project_achievement {
  id
  kpi
  name
  project_id
  project_objective_id
  sequence
}
    `;
export const ProjectObjectiveFragmentDoc = gql`
    fragment ProjectObjective on project_project_objective {
  id
  name
  sequence
  project_id
  output: project_indicators(
    where: {indicator_type_id: {_eq: output}}
    order_by: {sequence: asc_nulls_last}
  ) {
    ...ProjectIndicator
  }
  outcome: project_indicators(
    where: {indicator_type_id: {_eq: outcome}}
    order_by: {sequence: asc_nulls_last}
  ) {
    ...ProjectIndicator
  }
  achievements: project_achievements(order_by: {sequence: asc_nulls_last}) {
    ...ProjectAchievement
  }
}
    ${ProjectIndicatorFragmentDoc}
${ProjectAchievementFragmentDoc}`;
export const ProjectOperatingActivityFragmentDoc = gql`
    fragment ProjectOperatingActivity on project_project_operating_activity {
  id
  project_id
  name
  sequence
  description
  target_amount
  target_unit_id
  budget_group {
    id
    description
  }
  target_unit {
    id
    description
  }
  budget_group_id
  operating_activity_type_id
  operating_activity_type {
    id
    description
  }
}
    `;
export const ProjectBudgetItemFragmentDoc = gql`
    fragment ProjectBudgetItem on project_project_budget_item {
  id
  budget_group_id
  budget_id
  multiplier
  note
  unit_amount
  unit_of_multiplier_id
  unit_id
  price_per_unit
  sequence
  total
  project_operating_activity_id
  unit_of_multiplier: unit_by_unit_of_multiplier {
    description
  }
  unit {
    description
  }
  budget_group {
    id
    description
  }
  budget {
    id
    name
    fiscal_year
  }
}
    `;
export const ProjectMemberFragmentDoc = gql`
    fragment ProjectMember on project_project_member {
  id
  first_name
  last_name
  email
  organization_id
  position
  project_id
  sequence
  tel
  title
}
    `;
export const ProjectFragmentDoc = gql`
    fragment Project on project_project {
  ...ProjectPartial
  organization {
    id
    name
    short_name
  }
  project_step {
    id
    description
  }
  project_objectives(order_by: {created_at: asc}) {
    ...ProjectObjective
  }
  project_target_groups {
    target_group_id
    sequence
  }
  project_operating_areas {
    province_id
    sequence
  }
  project_operating_activities {
    ...ProjectOperatingActivity
    budget_items: project_budget_items {
      ...ProjectBudgetItem
    }
  }
  project_indicators(order_by: {sequence: asc_nulls_last}) {
    ...ProjectIndicator
  }
  output: project_indicators(
    where: {indicator_type_id: {_eq: output}}
    order_by: {sequence: asc_nulls_last}
  ) {
    ...ProjectIndicator
  }
  outcome: project_indicators(
    where: {indicator_type_id: {_eq: outcome}}
    order_by: {sequence: asc_nulls_last}
  ) {
    ...ProjectIndicator
  }
  project_achievements {
    ...ProjectAchievement
  }
  project_members {
    ...ProjectMember
  }
}
    ${ProjectPartialFragmentDoc}
${ProjectObjectiveFragmentDoc}
${ProjectOperatingActivityFragmentDoc}
${ProjectBudgetItemFragmentDoc}
${ProjectIndicatorFragmentDoc}
${ProjectAchievementFragmentDoc}
${ProjectMemberFragmentDoc}`;
export const ProjectAttachmentFragmentDoc = gql`
    fragment ProjectAttachment on project_project_attachment {
  attachment_type_id
  updated_at
  size
  project_id
  original_name
  note
  mimetype
  id
  file_path
  deleted_at
  filename
  created_by
  created_at
  updated_by
}
    `;
export const ProjectObjectivePartialFragmentDoc = gql`
    fragment ProjectObjectivePartial on project_project_objective {
  id
  name
  sequence
  project_id
}
    `;
export const ProjectBudgetTemplateFragmentDoc = gql`
    fragment ProjectBudgetTemplate on project_project_budget_template {
  id
  name
  max_price_per_unit
  fiscal_year
  deleted_at
  price_per_unit
  sequence
  unit_id
  unit_of_multiplier_id
  unit_of_multiplier {
    description
  }
  unit {
    description
  }
}
    `;
export const ProjectEventLogFragmentDoc = gql`
    fragment ProjectEventLog on project_project_event_log {
  id
  description
  created_at
  actor_user_id
  actor_organization_id
  approved_id
  project_id
  public
  reference
  remark
  step_id
  updated_at
  user {
    display_name
    id
    first_name
    last_name
    title_id
  }
  organization {
    id
    name
    area_code
    reference
    province
    short_name
    unit_code
  }
  approved {
    description
    id
  }
}
    `;
export const ProjectOperatingActivityWithProgressesFragmentDoc = gql`
    fragment ProjectOperatingActivityWithProgresses on project_project_operating_activity {
  ...ProjectOperatingActivity
  budget_items_aggregate: project_budget_items_aggregate {
    aggregate {
      sum {
        total
      }
    }
  }
  progresses: operating_activity_progresses {
    id
    month
    revision
    spend
    spend_plan
    project_operating_activity_id
    workload
    workload_plan
  }
}
    ${ProjectOperatingActivityFragmentDoc}`;
export const ProjectDetailFragmentDoc = gql`
    fragment ProjectDetail on project_project {
  id
  approved_comment
  approved_date
  approved_id
  cancelled_date
  change_request_date
  completed_date
  created_at
  created_by
  deleted_at
  effect
  end_at
  fiscal_year
  industry
  introduction
  last_overall_operation
  national_strategy_lvl1_id
  national_strategy_lvl2_id
  national_strategy_lvl3_id
  national_strategy_lvl4_id
  national_strategy_lvl5_id
  organization_id
  reference
  updated_by
  updated_at
  total_budget
  title
  submitted_date
  step_id
  start_at
  rejected_date
}
    `;
export const ProjectOperatingActivityWithBudgetFragmentDoc = gql`
    fragment ProjectOperatingActivityWithBudget on project_project_operating_activity {
  id
  project_id
  name
  sequence
  description
  target_amount
  target_unit_id
  budget_group {
    id
    description
  }
  target_unit {
    id
    description
  }
  budget_group_id
  operating_activity_type_id
  operating_activity_type {
    id
    description
  }
  budget_items: project_budget_items {
    ...ProjectBudgetItem
  }
}
    ${ProjectBudgetItemFragmentDoc}`;
export const ProjectBudgetFragmentDoc = gql`
    fragment ProjectBudget on project_budget {
  id
  name
  price_per_unit
  fiscal_year
  unit_id
  unit_of_multiplier_id
  unit {
    description
    id
  }
  unit_of_multiplier {
    description
    id
  }
  budget_group_mappings {
    budget_group {
      description
    }
  }
}
    `;
export const UnitTypeFragmentDoc = gql`
    fragment UnitType on project_unit {
  id
  description
}
    `;
export const FiscalYearFragmentDoc = gql`
    fragment FiscalYear on fiscal_year {
  year
}
    `;
export const ProjectBudgetGroupMappingFragmentDoc = gql`
    fragment ProjectBudgetGroupMapping on project_budget {
  id
  fiscal_year
  max_price_per_unit
  name
  sequence
  price_per_unit
  unit_id
  unit_of_multiplier_id
  deleted_at
  unit_of_multiplier {
    id
    description
  }
  unit {
    id
    description
  }
  group_mappings: budget_group_mappings_aggregate(
    where: {budget_group_id: {_eq: $budgetGroupId}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const ProjectBudgetGroupFragmentDoc = gql`
    fragment ProjectBudgetGroup on project_budget_group {
  id
  description
}
    `;
export const NationalStrategyFragmentDoc = gql`
    fragment NationalStrategy on project_national_strategy {
  id
  name
  depth
  parent_id
}
    `;
export const TargetGroupFragmentDoc = gql`
    fragment TargetGroup on project_target_group {
  description
  id
}
    `;
export const UnitFragmentDoc = gql`
    fragment Unit on project_unit {
  description
  id
}
    `;
export const UserFragmentDoc = gql`
    fragment User on users {
  avatar_url
  created_at
  department_id
  display_name
  first_name
  id
  last_name
  organization_id
  reference
  sequence
  title_id
  consent_checked
  updated_at
  tel
  organization {
    name
    area_code
    province
    reference
    sequence
    short_name
    unit_code
  }
  account {
    email
    default_role
    id
    active
    custom_register_data
    account_providers {
      auth_provider
    }
  }
  name_title {
    description
    id
  }
  department {
    id
    name
    name_en
    short_name
    short_name_en
  }
  note
  bank_branch_id
}
    `;
export const UserAccountRolesFragmentDoc = gql`
    fragment UserAccountRoles on users {
  account {
    account_roles {
      role
    }
  }
}
    `;
export const PermissionFragmentDoc = gql`
    fragment Permission on permission {
  id
  description
  active
  sequence
}
    `;
export const UserPermissionFragmentDoc = gql`
    fragment UserPermission on users_permission {
  user_id
  permission_id
}
    `;
export const GetFeatureByPkDocument = gql`
    query GetFeatureByPk($id: String!) {
  row: feature_toggle_by_pk(id: $id) {
    id
    enable
    description
  }
}
    `;
export type GetFeatureByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetFeatureByPkQuery, GetFeatureByPkQueryVariables>, 'query'> & ({ variables: GetFeatureByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetFeatureByPkComponent = (props: GetFeatureByPkComponentProps) => (
      <ApolloReactComponents.Query<GetFeatureByPkQuery, GetFeatureByPkQueryVariables> query={GetFeatureByPkDocument} {...props} />
    );
    

/**
 * __useGetFeatureByPkQuery__
 *
 * To run a query within a React component, call `useGetFeatureByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFeatureByPkQuery(baseOptions: Apollo.QueryHookOptions<GetFeatureByPkQuery, GetFeatureByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureByPkQuery, GetFeatureByPkQueryVariables>(GetFeatureByPkDocument, options);
      }
export function useGetFeatureByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureByPkQuery, GetFeatureByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureByPkQuery, GetFeatureByPkQueryVariables>(GetFeatureByPkDocument, options);
        }
export type GetFeatureByPkQueryHookResult = ReturnType<typeof useGetFeatureByPkQuery>;
export type GetFeatureByPkLazyQueryHookResult = ReturnType<typeof useGetFeatureByPkLazyQuery>;
export type GetFeatureByPkQueryResult = Apollo.QueryResult<GetFeatureByPkQuery, GetFeatureByPkQueryVariables>;
export const GetUserPermissionsDocument = gql`
    query GetUserPermissions {
  user_permissions: current_user_permissions {
    user_id
    permission_id
  }
}
    `;
export type GetUserPermissionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>, 'query'>;

    export const GetUserPermissionsComponent = (props: GetUserPermissionsComponentProps) => (
      <ApolloReactComponents.Query<GetUserPermissionsQuery, GetUserPermissionsQueryVariables> query={GetUserPermissionsDocument} {...props} />
    );
    

/**
 * __useGetUserPermissionsQuery__
 *
 * To run a query within a React component, call `useGetUserPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>(GetUserPermissionsDocument, options);
      }
export function useGetUserPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>(GetUserPermissionsDocument, options);
        }
export type GetUserPermissionsQueryHookResult = ReturnType<typeof useGetUserPermissionsQuery>;
export type GetUserPermissionsLazyQueryHookResult = ReturnType<typeof useGetUserPermissionsLazyQuery>;
export type GetUserPermissionsQueryResult = Apollo.QueryResult<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>;
export const UpdateAccountByPkDocument = gql`
    mutation UpdateAccountByPk($id: uuid!, $_set: account_account_set_input) {
  update_account_account_by_pk(pk_columns: {id: $id}, _set: $_set) {
    ...AccountFull
  }
}
    ${AccountFullFragmentDoc}`;
export type UpdateAccountByPkMutationFn = Apollo.MutationFunction<UpdateAccountByPkMutation, UpdateAccountByPkMutationVariables>;
export type UpdateAccountByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateAccountByPkMutation, UpdateAccountByPkMutationVariables>, 'mutation'>;

    export const UpdateAccountByPkComponent = (props: UpdateAccountByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateAccountByPkMutation, UpdateAccountByPkMutationVariables> mutation={UpdateAccountByPkDocument} {...props} />
    );
    

/**
 * __useUpdateAccountByPkMutation__
 *
 * To run a mutation, you first call `useUpdateAccountByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountByPkMutation, { data, loading, error }] = useUpdateAccountByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateAccountByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountByPkMutation, UpdateAccountByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountByPkMutation, UpdateAccountByPkMutationVariables>(UpdateAccountByPkDocument, options);
      }
export type UpdateAccountByPkMutationHookResult = ReturnType<typeof useUpdateAccountByPkMutation>;
export type UpdateAccountByPkMutationResult = Apollo.MutationResult<UpdateAccountByPkMutation>;
export type UpdateAccountByPkMutationOptions = Apollo.BaseMutationOptions<UpdateAccountByPkMutation, UpdateAccountByPkMutationVariables>;
export const InsertAccountOneDocument = gql`
    mutation InsertAccountOne($object: account_account_insert_input!) {
  insert_account_account_one(object: $object) {
    ...AccountFull
  }
}
    ${AccountFullFragmentDoc}`;
export type InsertAccountOneMutationFn = Apollo.MutationFunction<InsertAccountOneMutation, InsertAccountOneMutationVariables>;
export type InsertAccountOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertAccountOneMutation, InsertAccountOneMutationVariables>, 'mutation'>;

    export const InsertAccountOneComponent = (props: InsertAccountOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertAccountOneMutation, InsertAccountOneMutationVariables> mutation={InsertAccountOneDocument} {...props} />
    );
    

/**
 * __useInsertAccountOneMutation__
 *
 * To run a mutation, you first call `useInsertAccountOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertAccountOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAccountOneMutation, { data, loading, error }] = useInsertAccountOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertAccountOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertAccountOneMutation, InsertAccountOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertAccountOneMutation, InsertAccountOneMutationVariables>(InsertAccountOneDocument, options);
      }
export type InsertAccountOneMutationHookResult = ReturnType<typeof useInsertAccountOneMutation>;
export type InsertAccountOneMutationResult = Apollo.MutationResult<InsertAccountOneMutation>;
export type InsertAccountOneMutationOptions = Apollo.BaseMutationOptions<InsertAccountOneMutation, InsertAccountOneMutationVariables>;
export const GetAccountsDocument = gql`
    query GetAccounts($where: account_account_bool_exp, $limit: Int, $offset: Int, $orderBy: [account_account_order_by!]) {
  rows: account_account(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...Account
  }
}
    ${AccountFragmentDoc}`;
export type GetAccountsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountsQuery, GetAccountsQueryVariables>, 'query'>;

    export const GetAccountsComponent = (props: GetAccountsComponentProps) => (
      <ApolloReactComponents.Query<GetAccountsQuery, GetAccountsQueryVariables> query={GetAccountsDocument} {...props} />
    );
    

/**
 * __useGetAccountsQuery__
 *
 * To run a query within a React component, call `useGetAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, options);
      }
export function useGetAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, options);
        }
export type GetAccountsQueryHookResult = ReturnType<typeof useGetAccountsQuery>;
export type GetAccountsLazyQueryHookResult = ReturnType<typeof useGetAccountsLazyQuery>;
export type GetAccountsQueryResult = Apollo.QueryResult<GetAccountsQuery, GetAccountsQueryVariables>;
export const GetAccountsFullDocument = gql`
    query GetAccountsFull($distinctOn: [account_account_select_column!], $limit: Int, $offset: Int, $orderBy: [account_account_order_by!], $where: account_account_bool_exp) {
  rows: account_account(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...AccountFull
  }
}
    ${AccountFullFragmentDoc}`;
export type GetAccountsFullComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountsFullQuery, GetAccountsFullQueryVariables>, 'query'>;

    export const GetAccountsFullComponent = (props: GetAccountsFullComponentProps) => (
      <ApolloReactComponents.Query<GetAccountsFullQuery, GetAccountsFullQueryVariables> query={GetAccountsFullDocument} {...props} />
    );
    

/**
 * __useGetAccountsFullQuery__
 *
 * To run a query within a React component, call `useGetAccountsFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsFullQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAccountsFullQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountsFullQuery, GetAccountsFullQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountsFullQuery, GetAccountsFullQueryVariables>(GetAccountsFullDocument, options);
      }
export function useGetAccountsFullLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountsFullQuery, GetAccountsFullQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountsFullQuery, GetAccountsFullQueryVariables>(GetAccountsFullDocument, options);
        }
export type GetAccountsFullQueryHookResult = ReturnType<typeof useGetAccountsFullQuery>;
export type GetAccountsFullLazyQueryHookResult = ReturnType<typeof useGetAccountsFullLazyQuery>;
export type GetAccountsFullQueryResult = Apollo.QueryResult<GetAccountsFullQuery, GetAccountsFullQueryVariables>;
export const GetAccountByPkDocument = gql`
    query GetAccountByPk($id: uuid!) {
  entity: account_account_by_pk(id: $id) {
    ...AccountFull
  }
}
    ${AccountFullFragmentDoc}`;
export type GetAccountByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountByPkQuery, GetAccountByPkQueryVariables>, 'query'> & ({ variables: GetAccountByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAccountByPkComponent = (props: GetAccountByPkComponentProps) => (
      <ApolloReactComponents.Query<GetAccountByPkQuery, GetAccountByPkQueryVariables> query={GetAccountByPkDocument} {...props} />
    );
    

/**
 * __useGetAccountByPkQuery__
 *
 * To run a query within a React component, call `useGetAccountByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountByPkQuery(baseOptions: Apollo.QueryHookOptions<GetAccountByPkQuery, GetAccountByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountByPkQuery, GetAccountByPkQueryVariables>(GetAccountByPkDocument, options);
      }
export function useGetAccountByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountByPkQuery, GetAccountByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountByPkQuery, GetAccountByPkQueryVariables>(GetAccountByPkDocument, options);
        }
export type GetAccountByPkQueryHookResult = ReturnType<typeof useGetAccountByPkQuery>;
export type GetAccountByPkLazyQueryHookResult = ReturnType<typeof useGetAccountByPkLazyQuery>;
export type GetAccountByPkQueryResult = Apollo.QueryResult<GetAccountByPkQuery, GetAccountByPkQueryVariables>;
export const GetAccountTypesDocument = gql`
    query GetAccountTypes {
  rows: account_account_type {
    ...AccountType
  }
}
    ${AccountTypeFragmentDoc}`;
export type GetAccountTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountTypesQuery, GetAccountTypesQueryVariables>, 'query'>;

    export const GetAccountTypesComponent = (props: GetAccountTypesComponentProps) => (
      <ApolloReactComponents.Query<GetAccountTypesQuery, GetAccountTypesQueryVariables> query={GetAccountTypesDocument} {...props} />
    );
    

/**
 * __useGetAccountTypesQuery__
 *
 * To run a query within a React component, call `useGetAccountTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountTypesQuery, GetAccountTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountTypesQuery, GetAccountTypesQueryVariables>(GetAccountTypesDocument, options);
      }
export function useGetAccountTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountTypesQuery, GetAccountTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountTypesQuery, GetAccountTypesQueryVariables>(GetAccountTypesDocument, options);
        }
export type GetAccountTypesQueryHookResult = ReturnType<typeof useGetAccountTypesQuery>;
export type GetAccountTypesLazyQueryHookResult = ReturnType<typeof useGetAccountTypesLazyQuery>;
export type GetAccountTypesQueryResult = Apollo.QueryResult<GetAccountTypesQuery, GetAccountTypesQueryVariables>;
export const GetAccountTrialBalancesDocument = gql`
    query GetAccountTrialBalances($fiscalYear: Int!, $organizationId: uuid!) {
  account_account(order_by: {code: asc}) {
    id
    code
    name
    default_entry_type_id
    balances(
      where: {fiscal_year: {_eq: $fiscalYear}, organization_id: {_eq: $organizationId}}
    ) {
      id
      balance
      budget_id
      fiscal_year
      organization_id
      type_id
    }
    balances_aggregate(
      where: {fiscal_year: {_eq: $fiscalYear}, organization_id: {_eq: $organizationId}}
    ) {
      aggregate {
        sum {
          balance
        }
      }
    }
  }
}
    `;
export type GetAccountTrialBalancesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountTrialBalancesQuery, GetAccountTrialBalancesQueryVariables>, 'query'> & ({ variables: GetAccountTrialBalancesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAccountTrialBalancesComponent = (props: GetAccountTrialBalancesComponentProps) => (
      <ApolloReactComponents.Query<GetAccountTrialBalancesQuery, GetAccountTrialBalancesQueryVariables> query={GetAccountTrialBalancesDocument} {...props} />
    );
    

/**
 * __useGetAccountTrialBalancesQuery__
 *
 * To run a query within a React component, call `useGetAccountTrialBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountTrialBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountTrialBalancesQuery({
 *   variables: {
 *      fiscalYear: // value for 'fiscalYear'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetAccountTrialBalancesQuery(baseOptions: Apollo.QueryHookOptions<GetAccountTrialBalancesQuery, GetAccountTrialBalancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountTrialBalancesQuery, GetAccountTrialBalancesQueryVariables>(GetAccountTrialBalancesDocument, options);
      }
export function useGetAccountTrialBalancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountTrialBalancesQuery, GetAccountTrialBalancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountTrialBalancesQuery, GetAccountTrialBalancesQueryVariables>(GetAccountTrialBalancesDocument, options);
        }
export type GetAccountTrialBalancesQueryHookResult = ReturnType<typeof useGetAccountTrialBalancesQuery>;
export type GetAccountTrialBalancesLazyQueryHookResult = ReturnType<typeof useGetAccountTrialBalancesLazyQuery>;
export type GetAccountTrialBalancesQueryResult = Apollo.QueryResult<GetAccountTrialBalancesQuery, GetAccountTrialBalancesQueryVariables>;
export const GetAccountTrialBalancesMonthlyDocument = gql`
    query GetAccountTrialBalancesMonthly($date: date!, $inDate: date!, $organizationId: uuid!) {
  organization: organization_by_pk(id: $organizationId) {
    id
    name
    short_name
  }
  rows: account_account(order_by: {code: asc}) {
    id
    name
    code
    type_id
    account_type {
      id
      description
    }
    debit_bring: ledger_entry_views_aggregate(
      where: {date: {_lt: $date}, entry_type: {_eq: "debit"}, organization_id: {_eq: $organizationId}}
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
    credit_bring: ledger_entry_views_aggregate(
      where: {date: {_lt: $date}, entry_type: {_eq: "credit"}, organization_id: {_eq: $organizationId}}
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
    debit_total: ledger_entry_views_aggregate(
      where: {_and: [{date: {_gte: $date}}, {date: {_lte: $inDate}}], entry_type: {_eq: "debit"}, organization_id: {_eq: $organizationId}}
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
    credit_total: ledger_entry_views_aggregate(
      where: {_and: [{date: {_gte: $date}}, {date: {_lte: $inDate}}], entry_type: {_eq: "credit"}, organization_id: {_eq: $organizationId}}
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
}
    `;
export type GetAccountTrialBalancesMonthlyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountTrialBalancesMonthlyQuery, GetAccountTrialBalancesMonthlyQueryVariables>, 'query'> & ({ variables: GetAccountTrialBalancesMonthlyQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAccountTrialBalancesMonthlyComponent = (props: GetAccountTrialBalancesMonthlyComponentProps) => (
      <ApolloReactComponents.Query<GetAccountTrialBalancesMonthlyQuery, GetAccountTrialBalancesMonthlyQueryVariables> query={GetAccountTrialBalancesMonthlyDocument} {...props} />
    );
    

/**
 * __useGetAccountTrialBalancesMonthlyQuery__
 *
 * To run a query within a React component, call `useGetAccountTrialBalancesMonthlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountTrialBalancesMonthlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountTrialBalancesMonthlyQuery({
 *   variables: {
 *      date: // value for 'date'
 *      inDate: // value for 'inDate'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetAccountTrialBalancesMonthlyQuery(baseOptions: Apollo.QueryHookOptions<GetAccountTrialBalancesMonthlyQuery, GetAccountTrialBalancesMonthlyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountTrialBalancesMonthlyQuery, GetAccountTrialBalancesMonthlyQueryVariables>(GetAccountTrialBalancesMonthlyDocument, options);
      }
export function useGetAccountTrialBalancesMonthlyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountTrialBalancesMonthlyQuery, GetAccountTrialBalancesMonthlyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountTrialBalancesMonthlyQuery, GetAccountTrialBalancesMonthlyQueryVariables>(GetAccountTrialBalancesMonthlyDocument, options);
        }
export type GetAccountTrialBalancesMonthlyQueryHookResult = ReturnType<typeof useGetAccountTrialBalancesMonthlyQuery>;
export type GetAccountTrialBalancesMonthlyLazyQueryHookResult = ReturnType<typeof useGetAccountTrialBalancesMonthlyLazyQuery>;
export type GetAccountTrialBalancesMonthlyQueryResult = Apollo.QueryResult<GetAccountTrialBalancesMonthlyQuery, GetAccountTrialBalancesMonthlyQueryVariables>;
export const GetAccountBudgetAllocationsDocument = gql`
    query GetAccountBudgetAllocations($distinctOn: [account_budget_allocation_select_column!], $limit: Int, $offset: Int, $orderBy: [account_budget_allocation_order_by!], $where: account_budget_allocation_bool_exp) {
  rows: account_budget_allocation(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...AccountBudgetAllocation
  }
}
    ${AccountBudgetAllocationFragmentDoc}`;
export type GetAccountBudgetAllocationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountBudgetAllocationsQuery, GetAccountBudgetAllocationsQueryVariables>, 'query'>;

    export const GetAccountBudgetAllocationsComponent = (props: GetAccountBudgetAllocationsComponentProps) => (
      <ApolloReactComponents.Query<GetAccountBudgetAllocationsQuery, GetAccountBudgetAllocationsQueryVariables> query={GetAccountBudgetAllocationsDocument} {...props} />
    );
    

/**
 * __useGetAccountBudgetAllocationsQuery__
 *
 * To run a query within a React component, call `useGetAccountBudgetAllocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountBudgetAllocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountBudgetAllocationsQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAccountBudgetAllocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountBudgetAllocationsQuery, GetAccountBudgetAllocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountBudgetAllocationsQuery, GetAccountBudgetAllocationsQueryVariables>(GetAccountBudgetAllocationsDocument, options);
      }
export function useGetAccountBudgetAllocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountBudgetAllocationsQuery, GetAccountBudgetAllocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountBudgetAllocationsQuery, GetAccountBudgetAllocationsQueryVariables>(GetAccountBudgetAllocationsDocument, options);
        }
export type GetAccountBudgetAllocationsQueryHookResult = ReturnType<typeof useGetAccountBudgetAllocationsQuery>;
export type GetAccountBudgetAllocationsLazyQueryHookResult = ReturnType<typeof useGetAccountBudgetAllocationsLazyQuery>;
export type GetAccountBudgetAllocationsQueryResult = Apollo.QueryResult<GetAccountBudgetAllocationsQuery, GetAccountBudgetAllocationsQueryVariables>;
export const GetOrganizationBudgetsByFiscalYearDocument = gql`
    query GetOrganizationBudgetsByFiscalYear($distinctOn: [organization_select_column!], $limit: Int, $offset: Int, $orderBy: [organization_order_by!], $fiscalYear: Int!) {
  rows: organization(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...Organization
    budget_allocations(where: {fiscal_year: {_eq: $fiscalYear}}) {
      ...AccountBudgetAllocation
    }
  }
}
    ${OrganizationFragmentDoc}
${AccountBudgetAllocationFragmentDoc}`;
export type GetOrganizationBudgetsByFiscalYearComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetOrganizationBudgetsByFiscalYearQuery, GetOrganizationBudgetsByFiscalYearQueryVariables>, 'query'> & ({ variables: GetOrganizationBudgetsByFiscalYearQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetOrganizationBudgetsByFiscalYearComponent = (props: GetOrganizationBudgetsByFiscalYearComponentProps) => (
      <ApolloReactComponents.Query<GetOrganizationBudgetsByFiscalYearQuery, GetOrganizationBudgetsByFiscalYearQueryVariables> query={GetOrganizationBudgetsByFiscalYearDocument} {...props} />
    );
    

/**
 * __useGetOrganizationBudgetsByFiscalYearQuery__
 *
 * To run a query within a React component, call `useGetOrganizationBudgetsByFiscalYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationBudgetsByFiscalYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationBudgetsByFiscalYearQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      fiscalYear: // value for 'fiscalYear'
 *   },
 * });
 */
export function useGetOrganizationBudgetsByFiscalYearQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationBudgetsByFiscalYearQuery, GetOrganizationBudgetsByFiscalYearQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationBudgetsByFiscalYearQuery, GetOrganizationBudgetsByFiscalYearQueryVariables>(GetOrganizationBudgetsByFiscalYearDocument, options);
      }
export function useGetOrganizationBudgetsByFiscalYearLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationBudgetsByFiscalYearQuery, GetOrganizationBudgetsByFiscalYearQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationBudgetsByFiscalYearQuery, GetOrganizationBudgetsByFiscalYearQueryVariables>(GetOrganizationBudgetsByFiscalYearDocument, options);
        }
export type GetOrganizationBudgetsByFiscalYearQueryHookResult = ReturnType<typeof useGetOrganizationBudgetsByFiscalYearQuery>;
export type GetOrganizationBudgetsByFiscalYearLazyQueryHookResult = ReturnType<typeof useGetOrganizationBudgetsByFiscalYearLazyQuery>;
export type GetOrganizationBudgetsByFiscalYearQueryResult = Apollo.QueryResult<GetOrganizationBudgetsByFiscalYearQuery, GetOrganizationBudgetsByFiscalYearQueryVariables>;
export const UpdateAccountCategoryByPkDocument = gql`
    mutation UpdateAccountCategoryByPk($id: uuid!, $_set: account_account_category_set_input) {
  update_account_account_category_by_pk(pk_columns: {id: $id}, _set: $_set) {
    ...AccountCategory
  }
}
    ${AccountCategoryFragmentDoc}`;
export type UpdateAccountCategoryByPkMutationFn = Apollo.MutationFunction<UpdateAccountCategoryByPkMutation, UpdateAccountCategoryByPkMutationVariables>;
export type UpdateAccountCategoryByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateAccountCategoryByPkMutation, UpdateAccountCategoryByPkMutationVariables>, 'mutation'>;

    export const UpdateAccountCategoryByPkComponent = (props: UpdateAccountCategoryByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateAccountCategoryByPkMutation, UpdateAccountCategoryByPkMutationVariables> mutation={UpdateAccountCategoryByPkDocument} {...props} />
    );
    

/**
 * __useUpdateAccountCategoryByPkMutation__
 *
 * To run a mutation, you first call `useUpdateAccountCategoryByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountCategoryByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountCategoryByPkMutation, { data, loading, error }] = useUpdateAccountCategoryByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateAccountCategoryByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountCategoryByPkMutation, UpdateAccountCategoryByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountCategoryByPkMutation, UpdateAccountCategoryByPkMutationVariables>(UpdateAccountCategoryByPkDocument, options);
      }
export type UpdateAccountCategoryByPkMutationHookResult = ReturnType<typeof useUpdateAccountCategoryByPkMutation>;
export type UpdateAccountCategoryByPkMutationResult = Apollo.MutationResult<UpdateAccountCategoryByPkMutation>;
export type UpdateAccountCategoryByPkMutationOptions = Apollo.BaseMutationOptions<UpdateAccountCategoryByPkMutation, UpdateAccountCategoryByPkMutationVariables>;
export const InsertAccountCategoryOneDocument = gql`
    mutation InsertAccountCategoryOne($object: account_account_category_insert_input!) {
  insert_account_account_category_one(object: $object) {
    ...AccountCategory
  }
}
    ${AccountCategoryFragmentDoc}`;
export type InsertAccountCategoryOneMutationFn = Apollo.MutationFunction<InsertAccountCategoryOneMutation, InsertAccountCategoryOneMutationVariables>;
export type InsertAccountCategoryOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertAccountCategoryOneMutation, InsertAccountCategoryOneMutationVariables>, 'mutation'>;

    export const InsertAccountCategoryOneComponent = (props: InsertAccountCategoryOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertAccountCategoryOneMutation, InsertAccountCategoryOneMutationVariables> mutation={InsertAccountCategoryOneDocument} {...props} />
    );
    

/**
 * __useInsertAccountCategoryOneMutation__
 *
 * To run a mutation, you first call `useInsertAccountCategoryOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertAccountCategoryOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAccountCategoryOneMutation, { data, loading, error }] = useInsertAccountCategoryOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertAccountCategoryOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertAccountCategoryOneMutation, InsertAccountCategoryOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertAccountCategoryOneMutation, InsertAccountCategoryOneMutationVariables>(InsertAccountCategoryOneDocument, options);
      }
export type InsertAccountCategoryOneMutationHookResult = ReturnType<typeof useInsertAccountCategoryOneMutation>;
export type InsertAccountCategoryOneMutationResult = Apollo.MutationResult<InsertAccountCategoryOneMutation>;
export type InsertAccountCategoryOneMutationOptions = Apollo.BaseMutationOptions<InsertAccountCategoryOneMutation, InsertAccountCategoryOneMutationVariables>;
export const GetAccountCategoriesDocument = gql`
    query GetAccountCategories($distinctOn: [account_account_category_select_column!], $limit: Int, $offset: Int, $orderBy: [account_account_category_order_by!], $where: account_account_category_bool_exp) {
  rows: account_account_category(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...AccountCategory
  }
}
    ${AccountCategoryFragmentDoc}`;
export type GetAccountCategoriesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountCategoriesQuery, GetAccountCategoriesQueryVariables>, 'query'>;

    export const GetAccountCategoriesComponent = (props: GetAccountCategoriesComponentProps) => (
      <ApolloReactComponents.Query<GetAccountCategoriesQuery, GetAccountCategoriesQueryVariables> query={GetAccountCategoriesDocument} {...props} />
    );
    

/**
 * __useGetAccountCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAccountCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountCategoriesQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAccountCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountCategoriesQuery, GetAccountCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountCategoriesQuery, GetAccountCategoriesQueryVariables>(GetAccountCategoriesDocument, options);
      }
export function useGetAccountCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountCategoriesQuery, GetAccountCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountCategoriesQuery, GetAccountCategoriesQueryVariables>(GetAccountCategoriesDocument, options);
        }
export type GetAccountCategoriesQueryHookResult = ReturnType<typeof useGetAccountCategoriesQuery>;
export type GetAccountCategoriesLazyQueryHookResult = ReturnType<typeof useGetAccountCategoriesLazyQuery>;
export type GetAccountCategoriesQueryResult = Apollo.QueryResult<GetAccountCategoriesQuery, GetAccountCategoriesQueryVariables>;
export const GetAccountCategoryByPkDocument = gql`
    query GetAccountCategoryByPk($id: uuid!) {
  row: account_account_category_by_pk(id: $id) {
    ...AccountCategory
  }
}
    ${AccountCategoryFragmentDoc}`;
export type GetAccountCategoryByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountCategoryByPkQuery, GetAccountCategoryByPkQueryVariables>, 'query'> & ({ variables: GetAccountCategoryByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAccountCategoryByPkComponent = (props: GetAccountCategoryByPkComponentProps) => (
      <ApolloReactComponents.Query<GetAccountCategoryByPkQuery, GetAccountCategoryByPkQueryVariables> query={GetAccountCategoryByPkDocument} {...props} />
    );
    

/**
 * __useGetAccountCategoryByPkQuery__
 *
 * To run a query within a React component, call `useGetAccountCategoryByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountCategoryByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountCategoryByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountCategoryByPkQuery(baseOptions: Apollo.QueryHookOptions<GetAccountCategoryByPkQuery, GetAccountCategoryByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountCategoryByPkQuery, GetAccountCategoryByPkQueryVariables>(GetAccountCategoryByPkDocument, options);
      }
export function useGetAccountCategoryByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountCategoryByPkQuery, GetAccountCategoryByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountCategoryByPkQuery, GetAccountCategoryByPkQueryVariables>(GetAccountCategoryByPkDocument, options);
        }
export type GetAccountCategoryByPkQueryHookResult = ReturnType<typeof useGetAccountCategoryByPkQuery>;
export type GetAccountCategoryByPkLazyQueryHookResult = ReturnType<typeof useGetAccountCategoryByPkLazyQuery>;
export type GetAccountCategoryByPkQueryResult = Apollo.QueryResult<GetAccountCategoryByPkQuery, GetAccountCategoryByPkQueryVariables>;
export const UpdateAccountLendingByPkDocument = gql`
    mutation UpdateAccountLendingByPk($id: uuid!, $_set: account_lending_set_input) {
  account_lending: update_account_lending_by_pk(
    pk_columns: {id: $id}
    _set: $_set
  ) {
    ...AccountLending
  }
}
    ${AccountLendingFragmentDoc}`;
export type UpdateAccountLendingByPkMutationFn = Apollo.MutationFunction<UpdateAccountLendingByPkMutation, UpdateAccountLendingByPkMutationVariables>;
export type UpdateAccountLendingByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateAccountLendingByPkMutation, UpdateAccountLendingByPkMutationVariables>, 'mutation'>;

    export const UpdateAccountLendingByPkComponent = (props: UpdateAccountLendingByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateAccountLendingByPkMutation, UpdateAccountLendingByPkMutationVariables> mutation={UpdateAccountLendingByPkDocument} {...props} />
    );
    

/**
 * __useUpdateAccountLendingByPkMutation__
 *
 * To run a mutation, you first call `useUpdateAccountLendingByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountLendingByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountLendingByPkMutation, { data, loading, error }] = useUpdateAccountLendingByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateAccountLendingByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountLendingByPkMutation, UpdateAccountLendingByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountLendingByPkMutation, UpdateAccountLendingByPkMutationVariables>(UpdateAccountLendingByPkDocument, options);
      }
export type UpdateAccountLendingByPkMutationHookResult = ReturnType<typeof useUpdateAccountLendingByPkMutation>;
export type UpdateAccountLendingByPkMutationResult = Apollo.MutationResult<UpdateAccountLendingByPkMutation>;
export type UpdateAccountLendingByPkMutationOptions = Apollo.BaseMutationOptions<UpdateAccountLendingByPkMutation, UpdateAccountLendingByPkMutationVariables>;
export const InsertAccountLendingOneDocument = gql`
    mutation InsertAccountLendingOne($object: account_lending_insert_input!) {
  account_lending: insert_account_lending_one(object: $object) {
    ...AccountLending
  }
}
    ${AccountLendingFragmentDoc}`;
export type InsertAccountLendingOneMutationFn = Apollo.MutationFunction<InsertAccountLendingOneMutation, InsertAccountLendingOneMutationVariables>;
export type InsertAccountLendingOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertAccountLendingOneMutation, InsertAccountLendingOneMutationVariables>, 'mutation'>;

    export const InsertAccountLendingOneComponent = (props: InsertAccountLendingOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertAccountLendingOneMutation, InsertAccountLendingOneMutationVariables> mutation={InsertAccountLendingOneDocument} {...props} />
    );
    

/**
 * __useInsertAccountLendingOneMutation__
 *
 * To run a mutation, you first call `useInsertAccountLendingOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertAccountLendingOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAccountLendingOneMutation, { data, loading, error }] = useInsertAccountLendingOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertAccountLendingOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertAccountLendingOneMutation, InsertAccountLendingOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertAccountLendingOneMutation, InsertAccountLendingOneMutationVariables>(InsertAccountLendingOneDocument, options);
      }
export type InsertAccountLendingOneMutationHookResult = ReturnType<typeof useInsertAccountLendingOneMutation>;
export type InsertAccountLendingOneMutationResult = Apollo.MutationResult<InsertAccountLendingOneMutation>;
export type InsertAccountLendingOneMutationOptions = Apollo.BaseMutationOptions<InsertAccountLendingOneMutation, InsertAccountLendingOneMutationVariables>;
export const DeleteAccountLendingByPkDocument = gql`
    mutation DeleteAccountLendingByPk($id: uuid!) {
  account_lending: delete_account_lending_by_pk(id: $id) {
    ...AccountLending
  }
}
    ${AccountLendingFragmentDoc}`;
export type DeleteAccountLendingByPkMutationFn = Apollo.MutationFunction<DeleteAccountLendingByPkMutation, DeleteAccountLendingByPkMutationVariables>;
export type DeleteAccountLendingByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteAccountLendingByPkMutation, DeleteAccountLendingByPkMutationVariables>, 'mutation'>;

    export const DeleteAccountLendingByPkComponent = (props: DeleteAccountLendingByPkComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteAccountLendingByPkMutation, DeleteAccountLendingByPkMutationVariables> mutation={DeleteAccountLendingByPkDocument} {...props} />
    );
    

/**
 * __useDeleteAccountLendingByPkMutation__
 *
 * To run a mutation, you first call `useDeleteAccountLendingByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountLendingByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountLendingByPkMutation, { data, loading, error }] = useDeleteAccountLendingByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAccountLendingByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountLendingByPkMutation, DeleteAccountLendingByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccountLendingByPkMutation, DeleteAccountLendingByPkMutationVariables>(DeleteAccountLendingByPkDocument, options);
      }
export type DeleteAccountLendingByPkMutationHookResult = ReturnType<typeof useDeleteAccountLendingByPkMutation>;
export type DeleteAccountLendingByPkMutationResult = Apollo.MutationResult<DeleteAccountLendingByPkMutation>;
export type DeleteAccountLendingByPkMutationOptions = Apollo.BaseMutationOptions<DeleteAccountLendingByPkMutation, DeleteAccountLendingByPkMutationVariables>;
export const ReplaceAccountLendingItemsDocument = gql`
    mutation ReplaceAccountLendingItems($lendingId: uuid!, $itemIds: [uuid!]!, $insert_lending_items: [account_lending_item_insert_input!]!) {
  deleted_account_lendings: delete_account_lending_item(
    where: {id: {_nin: $itemIds}, lending_id: {_eq: $lendingId}}
  ) {
    affected_rows
  }
  account_lendings: insert_account_lending_item(
    objects: $insert_lending_items
    on_conflict: {constraint: lending_item_pkey, update_columns: [amount, date, name, note]}
  ) {
    affected_rows
    returning {
      ...AccountLendingItem
    }
  }
}
    ${AccountLendingItemFragmentDoc}`;
export type ReplaceAccountLendingItemsMutationFn = Apollo.MutationFunction<ReplaceAccountLendingItemsMutation, ReplaceAccountLendingItemsMutationVariables>;
export type ReplaceAccountLendingItemsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReplaceAccountLendingItemsMutation, ReplaceAccountLendingItemsMutationVariables>, 'mutation'>;

    export const ReplaceAccountLendingItemsComponent = (props: ReplaceAccountLendingItemsComponentProps) => (
      <ApolloReactComponents.Mutation<ReplaceAccountLendingItemsMutation, ReplaceAccountLendingItemsMutationVariables> mutation={ReplaceAccountLendingItemsDocument} {...props} />
    );
    

/**
 * __useReplaceAccountLendingItemsMutation__
 *
 * To run a mutation, you first call `useReplaceAccountLendingItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceAccountLendingItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceAccountLendingItemsMutation, { data, loading, error }] = useReplaceAccountLendingItemsMutation({
 *   variables: {
 *      lendingId: // value for 'lendingId'
 *      itemIds: // value for 'itemIds'
 *      insert_lending_items: // value for 'insert_lending_items'
 *   },
 * });
 */
export function useReplaceAccountLendingItemsMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceAccountLendingItemsMutation, ReplaceAccountLendingItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplaceAccountLendingItemsMutation, ReplaceAccountLendingItemsMutationVariables>(ReplaceAccountLendingItemsDocument, options);
      }
export type ReplaceAccountLendingItemsMutationHookResult = ReturnType<typeof useReplaceAccountLendingItemsMutation>;
export type ReplaceAccountLendingItemsMutationResult = Apollo.MutationResult<ReplaceAccountLendingItemsMutation>;
export type ReplaceAccountLendingItemsMutationOptions = Apollo.BaseMutationOptions<ReplaceAccountLendingItemsMutation, ReplaceAccountLendingItemsMutationVariables>;
export const GetAccountLendingsDocument = gql`
    query GetAccountLendings($where: account_lending_bool_exp, $limit: Int, $offset: Int, $orderBy: [account_lending_order_by!]) {
  rows: account_lending(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...AccountLending
    lending_items {
      ...AccountLendingItem
    }
  }
}
    ${AccountLendingFragmentDoc}
${AccountLendingItemFragmentDoc}`;
export type GetAccountLendingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountLendingsQuery, GetAccountLendingsQueryVariables>, 'query'>;

    export const GetAccountLendingsComponent = (props: GetAccountLendingsComponentProps) => (
      <ApolloReactComponents.Query<GetAccountLendingsQuery, GetAccountLendingsQueryVariables> query={GetAccountLendingsDocument} {...props} />
    );
    

/**
 * __useGetAccountLendingsQuery__
 *
 * To run a query within a React component, call `useGetAccountLendingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountLendingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountLendingsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAccountLendingsQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountLendingsQuery, GetAccountLendingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountLendingsQuery, GetAccountLendingsQueryVariables>(GetAccountLendingsDocument, options);
      }
export function useGetAccountLendingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountLendingsQuery, GetAccountLendingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountLendingsQuery, GetAccountLendingsQueryVariables>(GetAccountLendingsDocument, options);
        }
export type GetAccountLendingsQueryHookResult = ReturnType<typeof useGetAccountLendingsQuery>;
export type GetAccountLendingsLazyQueryHookResult = ReturnType<typeof useGetAccountLendingsLazyQuery>;
export type GetAccountLendingsQueryResult = Apollo.QueryResult<GetAccountLendingsQuery, GetAccountLendingsQueryVariables>;
export const GetAccountLendingByPkDocument = gql`
    query GetAccountLendingByPk($id: uuid!) {
  row: account_lending_by_pk(id: $id) {
    ...AccountLending
    lending_items {
      ...AccountLendingItem
    }
  }
}
    ${AccountLendingFragmentDoc}
${AccountLendingItemFragmentDoc}`;
export type GetAccountLendingByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountLendingByPkQuery, GetAccountLendingByPkQueryVariables>, 'query'> & ({ variables: GetAccountLendingByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAccountLendingByPkComponent = (props: GetAccountLendingByPkComponentProps) => (
      <ApolloReactComponents.Query<GetAccountLendingByPkQuery, GetAccountLendingByPkQueryVariables> query={GetAccountLendingByPkDocument} {...props} />
    );
    

/**
 * __useGetAccountLendingByPkQuery__
 *
 * To run a query within a React component, call `useGetAccountLendingByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountLendingByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountLendingByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountLendingByPkQuery(baseOptions: Apollo.QueryHookOptions<GetAccountLendingByPkQuery, GetAccountLendingByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountLendingByPkQuery, GetAccountLendingByPkQueryVariables>(GetAccountLendingByPkDocument, options);
      }
export function useGetAccountLendingByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountLendingByPkQuery, GetAccountLendingByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountLendingByPkQuery, GetAccountLendingByPkQueryVariables>(GetAccountLendingByPkDocument, options);
        }
export type GetAccountLendingByPkQueryHookResult = ReturnType<typeof useGetAccountLendingByPkQuery>;
export type GetAccountLendingByPkLazyQueryHookResult = ReturnType<typeof useGetAccountLendingByPkLazyQuery>;
export type GetAccountLendingByPkQueryResult = Apollo.QueryResult<GetAccountLendingByPkQuery, GetAccountLendingByPkQueryVariables>;
export const GetAccountTransactionsOfLendingByPkDocument = gql`
    query GetAccountTransactionsOfLendingByPk($id: uuid!) {
  transactions: account_transaction(
    where: {lending_id: {_eq: $id}}
    order_by: {created_at: desc}
  ) {
    ...AccountTransaction
  }
}
    ${AccountTransactionFragmentDoc}`;
export type GetAccountTransactionsOfLendingByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountTransactionsOfLendingByPkQuery, GetAccountTransactionsOfLendingByPkQueryVariables>, 'query'> & ({ variables: GetAccountTransactionsOfLendingByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAccountTransactionsOfLendingByPkComponent = (props: GetAccountTransactionsOfLendingByPkComponentProps) => (
      <ApolloReactComponents.Query<GetAccountTransactionsOfLendingByPkQuery, GetAccountTransactionsOfLendingByPkQueryVariables> query={GetAccountTransactionsOfLendingByPkDocument} {...props} />
    );
    

/**
 * __useGetAccountTransactionsOfLendingByPkQuery__
 *
 * To run a query within a React component, call `useGetAccountTransactionsOfLendingByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountTransactionsOfLendingByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountTransactionsOfLendingByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountTransactionsOfLendingByPkQuery(baseOptions: Apollo.QueryHookOptions<GetAccountTransactionsOfLendingByPkQuery, GetAccountTransactionsOfLendingByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountTransactionsOfLendingByPkQuery, GetAccountTransactionsOfLendingByPkQueryVariables>(GetAccountTransactionsOfLendingByPkDocument, options);
      }
export function useGetAccountTransactionsOfLendingByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountTransactionsOfLendingByPkQuery, GetAccountTransactionsOfLendingByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountTransactionsOfLendingByPkQuery, GetAccountTransactionsOfLendingByPkQueryVariables>(GetAccountTransactionsOfLendingByPkDocument, options);
        }
export type GetAccountTransactionsOfLendingByPkQueryHookResult = ReturnType<typeof useGetAccountTransactionsOfLendingByPkQuery>;
export type GetAccountTransactionsOfLendingByPkLazyQueryHookResult = ReturnType<typeof useGetAccountTransactionsOfLendingByPkLazyQuery>;
export type GetAccountTransactionsOfLendingByPkQueryResult = Apollo.QueryResult<GetAccountTransactionsOfLendingByPkQuery, GetAccountTransactionsOfLendingByPkQueryVariables>;
export const GetPublicAttachmentsDocument = gql`
    query GetPublicAttachments {
  attachments: attachment {
    ...PublicAttachment
  }
}
    ${PublicAttachmentFragmentDoc}`;
export type GetPublicAttachmentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPublicAttachmentsQuery, GetPublicAttachmentsQueryVariables>, 'query'>;

    export const GetPublicAttachmentsComponent = (props: GetPublicAttachmentsComponentProps) => (
      <ApolloReactComponents.Query<GetPublicAttachmentsQuery, GetPublicAttachmentsQueryVariables> query={GetPublicAttachmentsDocument} {...props} />
    );
    

/**
 * __useGetPublicAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetPublicAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicAttachmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPublicAttachmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetPublicAttachmentsQuery, GetPublicAttachmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublicAttachmentsQuery, GetPublicAttachmentsQueryVariables>(GetPublicAttachmentsDocument, options);
      }
export function useGetPublicAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicAttachmentsQuery, GetPublicAttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublicAttachmentsQuery, GetPublicAttachmentsQueryVariables>(GetPublicAttachmentsDocument, options);
        }
export type GetPublicAttachmentsQueryHookResult = ReturnType<typeof useGetPublicAttachmentsQuery>;
export type GetPublicAttachmentsLazyQueryHookResult = ReturnType<typeof useGetPublicAttachmentsLazyQuery>;
export type GetPublicAttachmentsQueryResult = Apollo.QueryResult<GetPublicAttachmentsQuery, GetPublicAttachmentsQueryVariables>;
export const InsertPublicAttachmentOneDocument = gql`
    mutation InsertPublicAttachmentOne($object: attachment_insert_input!) {
  row: insert_attachment_one(object: $object) {
    ...PublicAttachment
  }
}
    ${PublicAttachmentFragmentDoc}`;
export type InsertPublicAttachmentOneMutationFn = Apollo.MutationFunction<InsertPublicAttachmentOneMutation, InsertPublicAttachmentOneMutationVariables>;
export type InsertPublicAttachmentOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertPublicAttachmentOneMutation, InsertPublicAttachmentOneMutationVariables>, 'mutation'>;

    export const InsertPublicAttachmentOneComponent = (props: InsertPublicAttachmentOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertPublicAttachmentOneMutation, InsertPublicAttachmentOneMutationVariables> mutation={InsertPublicAttachmentOneDocument} {...props} />
    );
    

/**
 * __useInsertPublicAttachmentOneMutation__
 *
 * To run a mutation, you first call `useInsertPublicAttachmentOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPublicAttachmentOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPublicAttachmentOneMutation, { data, loading, error }] = useInsertPublicAttachmentOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertPublicAttachmentOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertPublicAttachmentOneMutation, InsertPublicAttachmentOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertPublicAttachmentOneMutation, InsertPublicAttachmentOneMutationVariables>(InsertPublicAttachmentOneDocument, options);
      }
export type InsertPublicAttachmentOneMutationHookResult = ReturnType<typeof useInsertPublicAttachmentOneMutation>;
export type InsertPublicAttachmentOneMutationResult = Apollo.MutationResult<InsertPublicAttachmentOneMutation>;
export type InsertPublicAttachmentOneMutationOptions = Apollo.BaseMutationOptions<InsertPublicAttachmentOneMutation, InsertPublicAttachmentOneMutationVariables>;
export const DeletePublicAttachmentByPkDocument = gql`
    mutation DeletePublicAttachmentByPk($id: uuid!) {
  row: delete_attachment_by_pk(id: $id) {
    ...PublicAttachment
  }
}
    ${PublicAttachmentFragmentDoc}`;
export type DeletePublicAttachmentByPkMutationFn = Apollo.MutationFunction<DeletePublicAttachmentByPkMutation, DeletePublicAttachmentByPkMutationVariables>;
export type DeletePublicAttachmentByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletePublicAttachmentByPkMutation, DeletePublicAttachmentByPkMutationVariables>, 'mutation'>;

    export const DeletePublicAttachmentByPkComponent = (props: DeletePublicAttachmentByPkComponentProps) => (
      <ApolloReactComponents.Mutation<DeletePublicAttachmentByPkMutation, DeletePublicAttachmentByPkMutationVariables> mutation={DeletePublicAttachmentByPkDocument} {...props} />
    );
    

/**
 * __useDeletePublicAttachmentByPkMutation__
 *
 * To run a mutation, you first call `useDeletePublicAttachmentByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePublicAttachmentByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePublicAttachmentByPkMutation, { data, loading, error }] = useDeletePublicAttachmentByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePublicAttachmentByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeletePublicAttachmentByPkMutation, DeletePublicAttachmentByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePublicAttachmentByPkMutation, DeletePublicAttachmentByPkMutationVariables>(DeletePublicAttachmentByPkDocument, options);
      }
export type DeletePublicAttachmentByPkMutationHookResult = ReturnType<typeof useDeletePublicAttachmentByPkMutation>;
export type DeletePublicAttachmentByPkMutationResult = Apollo.MutationResult<DeletePublicAttachmentByPkMutation>;
export type DeletePublicAttachmentByPkMutationOptions = Apollo.BaseMutationOptions<DeletePublicAttachmentByPkMutation, DeletePublicAttachmentByPkMutationVariables>;
export const GetPublicPostsDocument = gql`
    query GetPublicPosts($distinctOn: [posts_select_column!], $limit: Int, $offset: Int, $orderBy: [posts_order_by!], $where: posts_bool_exp) {
  posts(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...Post
  }
}
    ${PostFragmentDoc}`;
export type GetPublicPostsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPublicPostsQuery, GetPublicPostsQueryVariables>, 'query'>;

    export const GetPublicPostsComponent = (props: GetPublicPostsComponentProps) => (
      <ApolloReactComponents.Query<GetPublicPostsQuery, GetPublicPostsQueryVariables> query={GetPublicPostsDocument} {...props} />
    );
    

/**
 * __useGetPublicPostsQuery__
 *
 * To run a query within a React component, call `useGetPublicPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicPostsQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPublicPostsQuery(baseOptions?: Apollo.QueryHookOptions<GetPublicPostsQuery, GetPublicPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublicPostsQuery, GetPublicPostsQueryVariables>(GetPublicPostsDocument, options);
      }
export function useGetPublicPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicPostsQuery, GetPublicPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublicPostsQuery, GetPublicPostsQueryVariables>(GetPublicPostsDocument, options);
        }
export type GetPublicPostsQueryHookResult = ReturnType<typeof useGetPublicPostsQuery>;
export type GetPublicPostsLazyQueryHookResult = ReturnType<typeof useGetPublicPostsLazyQuery>;
export type GetPublicPostsQueryResult = Apollo.QueryResult<GetPublicPostsQuery, GetPublicPostsQueryVariables>;
export const InsertPublicPostOneDocument = gql`
    mutation InsertPublicPostOne($object: posts_insert_input!) {
  row: insert_posts_one(object: $object) {
    ...Post
  }
}
    ${PostFragmentDoc}`;
export type InsertPublicPostOneMutationFn = Apollo.MutationFunction<InsertPublicPostOneMutation, InsertPublicPostOneMutationVariables>;
export type InsertPublicPostOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertPublicPostOneMutation, InsertPublicPostOneMutationVariables>, 'mutation'>;

    export const InsertPublicPostOneComponent = (props: InsertPublicPostOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertPublicPostOneMutation, InsertPublicPostOneMutationVariables> mutation={InsertPublicPostOneDocument} {...props} />
    );
    

/**
 * __useInsertPublicPostOneMutation__
 *
 * To run a mutation, you first call `useInsertPublicPostOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPublicPostOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPublicPostOneMutation, { data, loading, error }] = useInsertPublicPostOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertPublicPostOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertPublicPostOneMutation, InsertPublicPostOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertPublicPostOneMutation, InsertPublicPostOneMutationVariables>(InsertPublicPostOneDocument, options);
      }
export type InsertPublicPostOneMutationHookResult = ReturnType<typeof useInsertPublicPostOneMutation>;
export type InsertPublicPostOneMutationResult = Apollo.MutationResult<InsertPublicPostOneMutation>;
export type InsertPublicPostOneMutationOptions = Apollo.BaseMutationOptions<InsertPublicPostOneMutation, InsertPublicPostOneMutationVariables>;
export const UpdatePublicPostByPkDocument = gql`
    mutation UpdatePublicPostByPk($id: uuid!, $_set: posts_set_input) {
  update_posts_by_pk(pk_columns: {id: $id}, _set: $_set) {
    ...Post
  }
}
    ${PostFragmentDoc}`;
export type UpdatePublicPostByPkMutationFn = Apollo.MutationFunction<UpdatePublicPostByPkMutation, UpdatePublicPostByPkMutationVariables>;
export type UpdatePublicPostByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdatePublicPostByPkMutation, UpdatePublicPostByPkMutationVariables>, 'mutation'>;

    export const UpdatePublicPostByPkComponent = (props: UpdatePublicPostByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdatePublicPostByPkMutation, UpdatePublicPostByPkMutationVariables> mutation={UpdatePublicPostByPkDocument} {...props} />
    );
    

/**
 * __useUpdatePublicPostByPkMutation__
 *
 * To run a mutation, you first call `useUpdatePublicPostByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicPostByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicPostByPkMutation, { data, loading, error }] = useUpdatePublicPostByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdatePublicPostByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePublicPostByPkMutation, UpdatePublicPostByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePublicPostByPkMutation, UpdatePublicPostByPkMutationVariables>(UpdatePublicPostByPkDocument, options);
      }
export type UpdatePublicPostByPkMutationHookResult = ReturnType<typeof useUpdatePublicPostByPkMutation>;
export type UpdatePublicPostByPkMutationResult = Apollo.MutationResult<UpdatePublicPostByPkMutation>;
export type UpdatePublicPostByPkMutationOptions = Apollo.BaseMutationOptions<UpdatePublicPostByPkMutation, UpdatePublicPostByPkMutationVariables>;
export const DeletePublicPostByPkDocument = gql`
    mutation DeletePublicPostByPk($id: uuid!) {
  row: delete_posts_by_pk(id: $id) {
    ...Post
  }
}
    ${PostFragmentDoc}`;
export type DeletePublicPostByPkMutationFn = Apollo.MutationFunction<DeletePublicPostByPkMutation, DeletePublicPostByPkMutationVariables>;
export type DeletePublicPostByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletePublicPostByPkMutation, DeletePublicPostByPkMutationVariables>, 'mutation'>;

    export const DeletePublicPostByPkComponent = (props: DeletePublicPostByPkComponentProps) => (
      <ApolloReactComponents.Mutation<DeletePublicPostByPkMutation, DeletePublicPostByPkMutationVariables> mutation={DeletePublicPostByPkDocument} {...props} />
    );
    

/**
 * __useDeletePublicPostByPkMutation__
 *
 * To run a mutation, you first call `useDeletePublicPostByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePublicPostByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePublicPostByPkMutation, { data, loading, error }] = useDeletePublicPostByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePublicPostByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeletePublicPostByPkMutation, DeletePublicPostByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePublicPostByPkMutation, DeletePublicPostByPkMutationVariables>(DeletePublicPostByPkDocument, options);
      }
export type DeletePublicPostByPkMutationHookResult = ReturnType<typeof useDeletePublicPostByPkMutation>;
export type DeletePublicPostByPkMutationResult = Apollo.MutationResult<DeletePublicPostByPkMutation>;
export type DeletePublicPostByPkMutationOptions = Apollo.BaseMutationOptions<DeletePublicPostByPkMutation, DeletePublicPostByPkMutationVariables>;
export const UpdateBankBranchByPkDocument = gql`
    mutation UpdateBankBranchByPk($id: String!, $_set: bank_branch_set_input) {
  update_bank_branch_by_pk(pk_columns: {id: $id}, _set: $_set) {
    ...BankBranch
  }
}
    ${BankBranchFragmentDoc}`;
export type UpdateBankBranchByPkMutationFn = Apollo.MutationFunction<UpdateBankBranchByPkMutation, UpdateBankBranchByPkMutationVariables>;
export type UpdateBankBranchByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateBankBranchByPkMutation, UpdateBankBranchByPkMutationVariables>, 'mutation'>;

    export const UpdateBankBranchByPkComponent = (props: UpdateBankBranchByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateBankBranchByPkMutation, UpdateBankBranchByPkMutationVariables> mutation={UpdateBankBranchByPkDocument} {...props} />
    );
    

/**
 * __useUpdateBankBranchByPkMutation__
 *
 * To run a mutation, you first call `useUpdateBankBranchByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBankBranchByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBankBranchByPkMutation, { data, loading, error }] = useUpdateBankBranchByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateBankBranchByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBankBranchByPkMutation, UpdateBankBranchByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBankBranchByPkMutation, UpdateBankBranchByPkMutationVariables>(UpdateBankBranchByPkDocument, options);
      }
export type UpdateBankBranchByPkMutationHookResult = ReturnType<typeof useUpdateBankBranchByPkMutation>;
export type UpdateBankBranchByPkMutationResult = Apollo.MutationResult<UpdateBankBranchByPkMutation>;
export type UpdateBankBranchByPkMutationOptions = Apollo.BaseMutationOptions<UpdateBankBranchByPkMutation, UpdateBankBranchByPkMutationVariables>;
export const InsertBankBranchOneDocument = gql`
    mutation InsertBankBranchOne($object: bank_branch_insert_input!) {
  insert_bank_branch_one(object: $object) {
    ...BankBranch
  }
}
    ${BankBranchFragmentDoc}`;
export type InsertBankBranchOneMutationFn = Apollo.MutationFunction<InsertBankBranchOneMutation, InsertBankBranchOneMutationVariables>;
export type InsertBankBranchOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertBankBranchOneMutation, InsertBankBranchOneMutationVariables>, 'mutation'>;

    export const InsertBankBranchOneComponent = (props: InsertBankBranchOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertBankBranchOneMutation, InsertBankBranchOneMutationVariables> mutation={InsertBankBranchOneDocument} {...props} />
    );
    

/**
 * __useInsertBankBranchOneMutation__
 *
 * To run a mutation, you first call `useInsertBankBranchOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertBankBranchOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertBankBranchOneMutation, { data, loading, error }] = useInsertBankBranchOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertBankBranchOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertBankBranchOneMutation, InsertBankBranchOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertBankBranchOneMutation, InsertBankBranchOneMutationVariables>(InsertBankBranchOneDocument, options);
      }
export type InsertBankBranchOneMutationHookResult = ReturnType<typeof useInsertBankBranchOneMutation>;
export type InsertBankBranchOneMutationResult = Apollo.MutationResult<InsertBankBranchOneMutation>;
export type InsertBankBranchOneMutationOptions = Apollo.BaseMutationOptions<InsertBankBranchOneMutation, InsertBankBranchOneMutationVariables>;
export const GetBankBranchesDocument = gql`
    query GetBankBranches($distinctOn: [bank_branch_select_column!], $limit: Int, $offset: Int, $orderBy: [bank_branch_order_by!], $where: bank_branch_bool_exp) {
  rows: bank_branch(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...BankBranch
  }
}
    ${BankBranchFragmentDoc}`;
export type GetBankBranchesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBankBranchesQuery, GetBankBranchesQueryVariables>, 'query'>;

    export const GetBankBranchesComponent = (props: GetBankBranchesComponentProps) => (
      <ApolloReactComponents.Query<GetBankBranchesQuery, GetBankBranchesQueryVariables> query={GetBankBranchesDocument} {...props} />
    );
    

/**
 * __useGetBankBranchesQuery__
 *
 * To run a query within a React component, call `useGetBankBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankBranchesQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBankBranchesQuery(baseOptions?: Apollo.QueryHookOptions<GetBankBranchesQuery, GetBankBranchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBankBranchesQuery, GetBankBranchesQueryVariables>(GetBankBranchesDocument, options);
      }
export function useGetBankBranchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBankBranchesQuery, GetBankBranchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBankBranchesQuery, GetBankBranchesQueryVariables>(GetBankBranchesDocument, options);
        }
export type GetBankBranchesQueryHookResult = ReturnType<typeof useGetBankBranchesQuery>;
export type GetBankBranchesLazyQueryHookResult = ReturnType<typeof useGetBankBranchesLazyQuery>;
export type GetBankBranchesQueryResult = Apollo.QueryResult<GetBankBranchesQuery, GetBankBranchesQueryVariables>;
export const GetBankBranchByPkDocument = gql`
    query GetBankBranchByPk($id: String!) {
  entity: bank_branch_by_pk(id: $id) {
    ...BankBranch
  }
}
    ${BankBranchFragmentDoc}`;
export type GetBankBranchByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBankBranchByPkQuery, GetBankBranchByPkQueryVariables>, 'query'> & ({ variables: GetBankBranchByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBankBranchByPkComponent = (props: GetBankBranchByPkComponentProps) => (
      <ApolloReactComponents.Query<GetBankBranchByPkQuery, GetBankBranchByPkQueryVariables> query={GetBankBranchByPkDocument} {...props} />
    );
    

/**
 * __useGetBankBranchByPkQuery__
 *
 * To run a query within a React component, call `useGetBankBranchByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankBranchByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankBranchByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBankBranchByPkQuery(baseOptions: Apollo.QueryHookOptions<GetBankBranchByPkQuery, GetBankBranchByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBankBranchByPkQuery, GetBankBranchByPkQueryVariables>(GetBankBranchByPkDocument, options);
      }
export function useGetBankBranchByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBankBranchByPkQuery, GetBankBranchByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBankBranchByPkQuery, GetBankBranchByPkQueryVariables>(GetBankBranchByPkDocument, options);
        }
export type GetBankBranchByPkQueryHookResult = ReturnType<typeof useGetBankBranchByPkQuery>;
export type GetBankBranchByPkLazyQueryHookResult = ReturnType<typeof useGetBankBranchByPkLazyQuery>;
export type GetBankBranchByPkQueryResult = Apollo.QueryResult<GetBankBranchByPkQuery, GetBankBranchByPkQueryVariables>;
export const UpdateBudgetTypeByPkDocument = gql`
    mutation UpdateBudgetTypeByPk($id: String!, $fiscal_year: Int!, $_set: account_budget_type_set_input) {
  update_account_budget_type_by_pk(
    pk_columns: {id: $id, fiscal_year: $fiscal_year}
    _set: $_set
  ) {
    ...BudgetType
  }
}
    ${BudgetTypeFragmentDoc}`;
export type UpdateBudgetTypeByPkMutationFn = Apollo.MutationFunction<UpdateBudgetTypeByPkMutation, UpdateBudgetTypeByPkMutationVariables>;
export type UpdateBudgetTypeByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateBudgetTypeByPkMutation, UpdateBudgetTypeByPkMutationVariables>, 'mutation'>;

    export const UpdateBudgetTypeByPkComponent = (props: UpdateBudgetTypeByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateBudgetTypeByPkMutation, UpdateBudgetTypeByPkMutationVariables> mutation={UpdateBudgetTypeByPkDocument} {...props} />
    );
    

/**
 * __useUpdateBudgetTypeByPkMutation__
 *
 * To run a mutation, you first call `useUpdateBudgetTypeByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBudgetTypeByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBudgetTypeByPkMutation, { data, loading, error }] = useUpdateBudgetTypeByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fiscal_year: // value for 'fiscal_year'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateBudgetTypeByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBudgetTypeByPkMutation, UpdateBudgetTypeByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBudgetTypeByPkMutation, UpdateBudgetTypeByPkMutationVariables>(UpdateBudgetTypeByPkDocument, options);
      }
export type UpdateBudgetTypeByPkMutationHookResult = ReturnType<typeof useUpdateBudgetTypeByPkMutation>;
export type UpdateBudgetTypeByPkMutationResult = Apollo.MutationResult<UpdateBudgetTypeByPkMutation>;
export type UpdateBudgetTypeByPkMutationOptions = Apollo.BaseMutationOptions<UpdateBudgetTypeByPkMutation, UpdateBudgetTypeByPkMutationVariables>;
export const InsertBudgetTypeOneDocument = gql`
    mutation InsertBudgetTypeOne($object: account_budget_type_insert_input!) {
  insert_account_budget_type_one(object: $object) {
    ...BudgetType
  }
}
    ${BudgetTypeFragmentDoc}`;
export type InsertBudgetTypeOneMutationFn = Apollo.MutationFunction<InsertBudgetTypeOneMutation, InsertBudgetTypeOneMutationVariables>;
export type InsertBudgetTypeOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertBudgetTypeOneMutation, InsertBudgetTypeOneMutationVariables>, 'mutation'>;

    export const InsertBudgetTypeOneComponent = (props: InsertBudgetTypeOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertBudgetTypeOneMutation, InsertBudgetTypeOneMutationVariables> mutation={InsertBudgetTypeOneDocument} {...props} />
    );
    

/**
 * __useInsertBudgetTypeOneMutation__
 *
 * To run a mutation, you first call `useInsertBudgetTypeOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertBudgetTypeOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertBudgetTypeOneMutation, { data, loading, error }] = useInsertBudgetTypeOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertBudgetTypeOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertBudgetTypeOneMutation, InsertBudgetTypeOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertBudgetTypeOneMutation, InsertBudgetTypeOneMutationVariables>(InsertBudgetTypeOneDocument, options);
      }
export type InsertBudgetTypeOneMutationHookResult = ReturnType<typeof useInsertBudgetTypeOneMutation>;
export type InsertBudgetTypeOneMutationResult = Apollo.MutationResult<InsertBudgetTypeOneMutation>;
export type InsertBudgetTypeOneMutationOptions = Apollo.BaseMutationOptions<InsertBudgetTypeOneMutation, InsertBudgetTypeOneMutationVariables>;
export const InsertBudgetAllocationsDocument = gql`
    mutation InsertBudgetAllocations($objects: [account_budget_allocation_insert_input!]!) {
  insert_account_budget_allocation(
    objects: $objects
    on_conflict: {constraint: budget_allocation_organization_id_budget_type_id_fiscal_year_ke, update_columns: [budget_total]}
  ) {
    affected_rows
    returning {
      id
      budget_total
    }
  }
}
    `;
export type InsertBudgetAllocationsMutationFn = Apollo.MutationFunction<InsertBudgetAllocationsMutation, InsertBudgetAllocationsMutationVariables>;
export type InsertBudgetAllocationsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertBudgetAllocationsMutation, InsertBudgetAllocationsMutationVariables>, 'mutation'>;

    export const InsertBudgetAllocationsComponent = (props: InsertBudgetAllocationsComponentProps) => (
      <ApolloReactComponents.Mutation<InsertBudgetAllocationsMutation, InsertBudgetAllocationsMutationVariables> mutation={InsertBudgetAllocationsDocument} {...props} />
    );
    

/**
 * __useInsertBudgetAllocationsMutation__
 *
 * To run a mutation, you first call `useInsertBudgetAllocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertBudgetAllocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertBudgetAllocationsMutation, { data, loading, error }] = useInsertBudgetAllocationsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertBudgetAllocationsMutation(baseOptions?: Apollo.MutationHookOptions<InsertBudgetAllocationsMutation, InsertBudgetAllocationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertBudgetAllocationsMutation, InsertBudgetAllocationsMutationVariables>(InsertBudgetAllocationsDocument, options);
      }
export type InsertBudgetAllocationsMutationHookResult = ReturnType<typeof useInsertBudgetAllocationsMutation>;
export type InsertBudgetAllocationsMutationResult = Apollo.MutationResult<InsertBudgetAllocationsMutation>;
export type InsertBudgetAllocationsMutationOptions = Apollo.BaseMutationOptions<InsertBudgetAllocationsMutation, InsertBudgetAllocationsMutationVariables>;
export const ReplaceBudgetAccountsDocument = gql`
    mutation ReplaceBudgetAccounts($budgetId: String!, $fiscalYear: Int!, $selectedIds: [uuid!]!, $budgetAccounts: [account_budget_account_insert_input!]!) {
  deleted_rows: delete_account_budget_account(
    where: {account_id: {_nin: $selectedIds}, budget_id: {_eq: $budgetId}, fiscal_year: {_eq: $fiscalYear}}
  ) {
    affected_rows
  }
  insert_rows: insert_account_budget_account(
    objects: $budgetAccounts
    on_conflict: {constraint: budget_account_pkey, update_columns: [account_id, budget_id, fiscal_year]}
  ) {
    affected_rows
    returning {
      budget_id
      account_id
      fiscal_year
    }
  }
}
    `;
export type ReplaceBudgetAccountsMutationFn = Apollo.MutationFunction<ReplaceBudgetAccountsMutation, ReplaceBudgetAccountsMutationVariables>;
export type ReplaceBudgetAccountsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReplaceBudgetAccountsMutation, ReplaceBudgetAccountsMutationVariables>, 'mutation'>;

    export const ReplaceBudgetAccountsComponent = (props: ReplaceBudgetAccountsComponentProps) => (
      <ApolloReactComponents.Mutation<ReplaceBudgetAccountsMutation, ReplaceBudgetAccountsMutationVariables> mutation={ReplaceBudgetAccountsDocument} {...props} />
    );
    

/**
 * __useReplaceBudgetAccountsMutation__
 *
 * To run a mutation, you first call `useReplaceBudgetAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceBudgetAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceBudgetAccountsMutation, { data, loading, error }] = useReplaceBudgetAccountsMutation({
 *   variables: {
 *      budgetId: // value for 'budgetId'
 *      fiscalYear: // value for 'fiscalYear'
 *      selectedIds: // value for 'selectedIds'
 *      budgetAccounts: // value for 'budgetAccounts'
 *   },
 * });
 */
export function useReplaceBudgetAccountsMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceBudgetAccountsMutation, ReplaceBudgetAccountsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplaceBudgetAccountsMutation, ReplaceBudgetAccountsMutationVariables>(ReplaceBudgetAccountsDocument, options);
      }
export type ReplaceBudgetAccountsMutationHookResult = ReturnType<typeof useReplaceBudgetAccountsMutation>;
export type ReplaceBudgetAccountsMutationResult = Apollo.MutationResult<ReplaceBudgetAccountsMutation>;
export type ReplaceBudgetAccountsMutationOptions = Apollo.BaseMutationOptions<ReplaceBudgetAccountsMutation, ReplaceBudgetAccountsMutationVariables>;
export const GetBudgetTypesDocument = gql`
    query GetBudgetTypes($distinctOn: [account_budget_type_select_column!], $limit: Int, $offset: Int, $orderBy: [account_budget_type_order_by!], $where: account_budget_type_bool_exp) {
  rows: account_budget_type(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...BudgetType
    accounts_aggregate: budget_accounts_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${BudgetTypeFragmentDoc}`;
export type GetBudgetTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBudgetTypesQuery, GetBudgetTypesQueryVariables>, 'query'>;

    export const GetBudgetTypesComponent = (props: GetBudgetTypesComponentProps) => (
      <ApolloReactComponents.Query<GetBudgetTypesQuery, GetBudgetTypesQueryVariables> query={GetBudgetTypesDocument} {...props} />
    );
    

/**
 * __useGetBudgetTypesQuery__
 *
 * To run a query within a React component, call `useGetBudgetTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBudgetTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBudgetTypesQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBudgetTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetBudgetTypesQuery, GetBudgetTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBudgetTypesQuery, GetBudgetTypesQueryVariables>(GetBudgetTypesDocument, options);
      }
export function useGetBudgetTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBudgetTypesQuery, GetBudgetTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBudgetTypesQuery, GetBudgetTypesQueryVariables>(GetBudgetTypesDocument, options);
        }
export type GetBudgetTypesQueryHookResult = ReturnType<typeof useGetBudgetTypesQuery>;
export type GetBudgetTypesLazyQueryHookResult = ReturnType<typeof useGetBudgetTypesLazyQuery>;
export type GetBudgetTypesQueryResult = Apollo.QueryResult<GetBudgetTypesQuery, GetBudgetTypesQueryVariables>;
export const GetBudgetTypeByPkDocument = gql`
    query GetBudgetTypeByPk($id: String!, $fiscalYear: Int!) {
  entity: account_budget_type_by_pk(id: $id, fiscal_year: $fiscalYear) {
    ...BudgetType
  }
}
    ${BudgetTypeFragmentDoc}`;
export type GetBudgetTypeByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBudgetTypeByPkQuery, GetBudgetTypeByPkQueryVariables>, 'query'> & ({ variables: GetBudgetTypeByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBudgetTypeByPkComponent = (props: GetBudgetTypeByPkComponentProps) => (
      <ApolloReactComponents.Query<GetBudgetTypeByPkQuery, GetBudgetTypeByPkQueryVariables> query={GetBudgetTypeByPkDocument} {...props} />
    );
    

/**
 * __useGetBudgetTypeByPkQuery__
 *
 * To run a query within a React component, call `useGetBudgetTypeByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBudgetTypeByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBudgetTypeByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *      fiscalYear: // value for 'fiscalYear'
 *   },
 * });
 */
export function useGetBudgetTypeByPkQuery(baseOptions: Apollo.QueryHookOptions<GetBudgetTypeByPkQuery, GetBudgetTypeByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBudgetTypeByPkQuery, GetBudgetTypeByPkQueryVariables>(GetBudgetTypeByPkDocument, options);
      }
export function useGetBudgetTypeByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBudgetTypeByPkQuery, GetBudgetTypeByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBudgetTypeByPkQuery, GetBudgetTypeByPkQueryVariables>(GetBudgetTypeByPkDocument, options);
        }
export type GetBudgetTypeByPkQueryHookResult = ReturnType<typeof useGetBudgetTypeByPkQuery>;
export type GetBudgetTypeByPkLazyQueryHookResult = ReturnType<typeof useGetBudgetTypeByPkLazyQuery>;
export type GetBudgetTypeByPkQueryResult = Apollo.QueryResult<GetBudgetTypeByPkQuery, GetBudgetTypeByPkQueryVariables>;
export const GetAccountBudgetsByBudgetIdWithFiscalYearDocument = gql`
    query GetAccountBudgetsByBudgetIdWithFiscalYear($budgetId: String!, $fiscalYear: Int!) {
  rows: account_account(order_by: [{code: asc}]) {
    id
    name
    code
    account_type {
      id
      description
    }
    budgets: budget_accounts(
      where: {fiscal_year: {_eq: $fiscalYear}, budget_id: {_eq: $budgetId}}
    ) {
      account_id
      budget_id
    }
    budgets_aggregate: budget_accounts_aggregate(
      where: {fiscal_year: {_eq: $fiscalYear}, budget_id: {_eq: $budgetId}}
    ) {
      aggregate {
        count
      }
    }
  }
}
    `;
export type GetAccountBudgetsByBudgetIdWithFiscalYearComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountBudgetsByBudgetIdWithFiscalYearQuery, GetAccountBudgetsByBudgetIdWithFiscalYearQueryVariables>, 'query'> & ({ variables: GetAccountBudgetsByBudgetIdWithFiscalYearQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAccountBudgetsByBudgetIdWithFiscalYearComponent = (props: GetAccountBudgetsByBudgetIdWithFiscalYearComponentProps) => (
      <ApolloReactComponents.Query<GetAccountBudgetsByBudgetIdWithFiscalYearQuery, GetAccountBudgetsByBudgetIdWithFiscalYearQueryVariables> query={GetAccountBudgetsByBudgetIdWithFiscalYearDocument} {...props} />
    );
    

/**
 * __useGetAccountBudgetsByBudgetIdWithFiscalYearQuery__
 *
 * To run a query within a React component, call `useGetAccountBudgetsByBudgetIdWithFiscalYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountBudgetsByBudgetIdWithFiscalYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountBudgetsByBudgetIdWithFiscalYearQuery({
 *   variables: {
 *      budgetId: // value for 'budgetId'
 *      fiscalYear: // value for 'fiscalYear'
 *   },
 * });
 */
export function useGetAccountBudgetsByBudgetIdWithFiscalYearQuery(baseOptions: Apollo.QueryHookOptions<GetAccountBudgetsByBudgetIdWithFiscalYearQuery, GetAccountBudgetsByBudgetIdWithFiscalYearQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountBudgetsByBudgetIdWithFiscalYearQuery, GetAccountBudgetsByBudgetIdWithFiscalYearQueryVariables>(GetAccountBudgetsByBudgetIdWithFiscalYearDocument, options);
      }
export function useGetAccountBudgetsByBudgetIdWithFiscalYearLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountBudgetsByBudgetIdWithFiscalYearQuery, GetAccountBudgetsByBudgetIdWithFiscalYearQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountBudgetsByBudgetIdWithFiscalYearQuery, GetAccountBudgetsByBudgetIdWithFiscalYearQueryVariables>(GetAccountBudgetsByBudgetIdWithFiscalYearDocument, options);
        }
export type GetAccountBudgetsByBudgetIdWithFiscalYearQueryHookResult = ReturnType<typeof useGetAccountBudgetsByBudgetIdWithFiscalYearQuery>;
export type GetAccountBudgetsByBudgetIdWithFiscalYearLazyQueryHookResult = ReturnType<typeof useGetAccountBudgetsByBudgetIdWithFiscalYearLazyQuery>;
export type GetAccountBudgetsByBudgetIdWithFiscalYearQueryResult = Apollo.QueryResult<GetAccountBudgetsByBudgetIdWithFiscalYearQuery, GetAccountBudgetsByBudgetIdWithFiscalYearQueryVariables>;
export const GetOrganizationsWithBudgetDocument = gql`
    query GetOrganizationsWithBudget($budgetId: String!, $fiscalYear: Int!) {
  rows: organization(order_by: {reference: asc}) {
    id
    name
    short_name
    reference
    budget_allocations(
      where: {budget_type_id: {_eq: $budgetId}, fiscal_year: {_eq: $fiscalYear}}
    ) {
      id
      budget_type_id
      fiscal_year
      budget_total
    }
  }
}
    `;
export type GetOrganizationsWithBudgetComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetOrganizationsWithBudgetQuery, GetOrganizationsWithBudgetQueryVariables>, 'query'> & ({ variables: GetOrganizationsWithBudgetQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetOrganizationsWithBudgetComponent = (props: GetOrganizationsWithBudgetComponentProps) => (
      <ApolloReactComponents.Query<GetOrganizationsWithBudgetQuery, GetOrganizationsWithBudgetQueryVariables> query={GetOrganizationsWithBudgetDocument} {...props} />
    );
    

/**
 * __useGetOrganizationsWithBudgetQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsWithBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsWithBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsWithBudgetQuery({
 *   variables: {
 *      budgetId: // value for 'budgetId'
 *      fiscalYear: // value for 'fiscalYear'
 *   },
 * });
 */
export function useGetOrganizationsWithBudgetQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationsWithBudgetQuery, GetOrganizationsWithBudgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationsWithBudgetQuery, GetOrganizationsWithBudgetQueryVariables>(GetOrganizationsWithBudgetDocument, options);
      }
export function useGetOrganizationsWithBudgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationsWithBudgetQuery, GetOrganizationsWithBudgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationsWithBudgetQuery, GetOrganizationsWithBudgetQueryVariables>(GetOrganizationsWithBudgetDocument, options);
        }
export type GetOrganizationsWithBudgetQueryHookResult = ReturnType<typeof useGetOrganizationsWithBudgetQuery>;
export type GetOrganizationsWithBudgetLazyQueryHookResult = ReturnType<typeof useGetOrganizationsWithBudgetLazyQuery>;
export type GetOrganizationsWithBudgetQueryResult = Apollo.QueryResult<GetOrganizationsWithBudgetQuery, GetOrganizationsWithBudgetQueryVariables>;
export const GetContractAgreementTransactionsDocument = gql`
    query GetContractAgreementTransactions($distinctOn: [contract_agreement_transaction_select_column!], $limit: Int, $offset: Int, $orderBy: [contract_agreement_transaction_order_by!], $where: contract_agreement_transaction_bool_exp) {
  rows: contract_agreement_transaction(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...ContractAgreementTransaction
    agreement {
      juristic_name_th
    }
  }
}
    ${ContractAgreementTransactionFragmentDoc}`;
export type GetContractAgreementTransactionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetContractAgreementTransactionsQuery, GetContractAgreementTransactionsQueryVariables>, 'query'>;

    export const GetContractAgreementTransactionsComponent = (props: GetContractAgreementTransactionsComponentProps) => (
      <ApolloReactComponents.Query<GetContractAgreementTransactionsQuery, GetContractAgreementTransactionsQueryVariables> query={GetContractAgreementTransactionsDocument} {...props} />
    );
    

/**
 * __useGetContractAgreementTransactionsQuery__
 *
 * To run a query within a React component, call `useGetContractAgreementTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractAgreementTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractAgreementTransactionsQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetContractAgreementTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<GetContractAgreementTransactionsQuery, GetContractAgreementTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractAgreementTransactionsQuery, GetContractAgreementTransactionsQueryVariables>(GetContractAgreementTransactionsDocument, options);
      }
export function useGetContractAgreementTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractAgreementTransactionsQuery, GetContractAgreementTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractAgreementTransactionsQuery, GetContractAgreementTransactionsQueryVariables>(GetContractAgreementTransactionsDocument, options);
        }
export type GetContractAgreementTransactionsQueryHookResult = ReturnType<typeof useGetContractAgreementTransactionsQuery>;
export type GetContractAgreementTransactionsLazyQueryHookResult = ReturnType<typeof useGetContractAgreementTransactionsLazyQuery>;
export type GetContractAgreementTransactionsQueryResult = Apollo.QueryResult<GetContractAgreementTransactionsQuery, GetContractAgreementTransactionsQueryVariables>;
export const GetContractAgreementRepaysDocument = gql`
    query GetContractAgreementRepays($distinctOn: [contract_agreement_repay_current_month_select_column!], $limit: Int, $offset: Int, $orderBy: [contract_agreement_repay_current_month_order_by!], $where: contract_agreement_repay_current_month_bool_exp) {
  rows: contract_agreement_repay_current_month(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...ContractAgreementRepay
  }
}
    ${ContractAgreementRepayFragmentDoc}`;
export type GetContractAgreementRepaysComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetContractAgreementRepaysQuery, GetContractAgreementRepaysQueryVariables>, 'query'>;

    export const GetContractAgreementRepaysComponent = (props: GetContractAgreementRepaysComponentProps) => (
      <ApolloReactComponents.Query<GetContractAgreementRepaysQuery, GetContractAgreementRepaysQueryVariables> query={GetContractAgreementRepaysDocument} {...props} />
    );
    

/**
 * __useGetContractAgreementRepaysQuery__
 *
 * To run a query within a React component, call `useGetContractAgreementRepaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractAgreementRepaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractAgreementRepaysQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetContractAgreementRepaysQuery(baseOptions?: Apollo.QueryHookOptions<GetContractAgreementRepaysQuery, GetContractAgreementRepaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractAgreementRepaysQuery, GetContractAgreementRepaysQueryVariables>(GetContractAgreementRepaysDocument, options);
      }
export function useGetContractAgreementRepaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractAgreementRepaysQuery, GetContractAgreementRepaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractAgreementRepaysQuery, GetContractAgreementRepaysQueryVariables>(GetContractAgreementRepaysDocument, options);
        }
export type GetContractAgreementRepaysQueryHookResult = ReturnType<typeof useGetContractAgreementRepaysQuery>;
export type GetContractAgreementRepaysLazyQueryHookResult = ReturnType<typeof useGetContractAgreementRepaysLazyQuery>;
export type GetContractAgreementRepaysQueryResult = Apollo.QueryResult<GetContractAgreementRepaysQuery, GetContractAgreementRepaysQueryVariables>;
export const UpdateContractAgreementByPkDocument = gql`
    mutation UpdateContractAgreementByPk($id: uuid!, $_set: contract_agreement_set_input) {
  update_contract_agreement_by_pk(pk_columns: {id: $id}, _set: $_set) {
    ...ContractAgreement
  }
}
    ${ContractAgreementFragmentDoc}`;
export type UpdateContractAgreementByPkMutationFn = Apollo.MutationFunction<UpdateContractAgreementByPkMutation, UpdateContractAgreementByPkMutationVariables>;
export type UpdateContractAgreementByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateContractAgreementByPkMutation, UpdateContractAgreementByPkMutationVariables>, 'mutation'>;

    export const UpdateContractAgreementByPkComponent = (props: UpdateContractAgreementByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateContractAgreementByPkMutation, UpdateContractAgreementByPkMutationVariables> mutation={UpdateContractAgreementByPkDocument} {...props} />
    );
    

/**
 * __useUpdateContractAgreementByPkMutation__
 *
 * To run a mutation, you first call `useUpdateContractAgreementByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractAgreementByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractAgreementByPkMutation, { data, loading, error }] = useUpdateContractAgreementByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateContractAgreementByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContractAgreementByPkMutation, UpdateContractAgreementByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContractAgreementByPkMutation, UpdateContractAgreementByPkMutationVariables>(UpdateContractAgreementByPkDocument, options);
      }
export type UpdateContractAgreementByPkMutationHookResult = ReturnType<typeof useUpdateContractAgreementByPkMutation>;
export type UpdateContractAgreementByPkMutationResult = Apollo.MutationResult<UpdateContractAgreementByPkMutation>;
export type UpdateContractAgreementByPkMutationOptions = Apollo.BaseMutationOptions<UpdateContractAgreementByPkMutation, UpdateContractAgreementByPkMutationVariables>;
export const InsertContractAgreementAttachmentOneDocument = gql`
    mutation InsertContractAgreementAttachmentOne($object: contract_agreement_attachment_insert_input!) {
  insert_contract_agreement_attachment_one(object: $object) {
    ...ContractAgreementAttachment
  }
}
    ${ContractAgreementAttachmentFragmentDoc}`;
export type InsertContractAgreementAttachmentOneMutationFn = Apollo.MutationFunction<InsertContractAgreementAttachmentOneMutation, InsertContractAgreementAttachmentOneMutationVariables>;
export type InsertContractAgreementAttachmentOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertContractAgreementAttachmentOneMutation, InsertContractAgreementAttachmentOneMutationVariables>, 'mutation'>;

    export const InsertContractAgreementAttachmentOneComponent = (props: InsertContractAgreementAttachmentOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertContractAgreementAttachmentOneMutation, InsertContractAgreementAttachmentOneMutationVariables> mutation={InsertContractAgreementAttachmentOneDocument} {...props} />
    );
    

/**
 * __useInsertContractAgreementAttachmentOneMutation__
 *
 * To run a mutation, you first call `useInsertContractAgreementAttachmentOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertContractAgreementAttachmentOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertContractAgreementAttachmentOneMutation, { data, loading, error }] = useInsertContractAgreementAttachmentOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertContractAgreementAttachmentOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertContractAgreementAttachmentOneMutation, InsertContractAgreementAttachmentOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertContractAgreementAttachmentOneMutation, InsertContractAgreementAttachmentOneMutationVariables>(InsertContractAgreementAttachmentOneDocument, options);
      }
export type InsertContractAgreementAttachmentOneMutationHookResult = ReturnType<typeof useInsertContractAgreementAttachmentOneMutation>;
export type InsertContractAgreementAttachmentOneMutationResult = Apollo.MutationResult<InsertContractAgreementAttachmentOneMutation>;
export type InsertContractAgreementAttachmentOneMutationOptions = Apollo.BaseMutationOptions<InsertContractAgreementAttachmentOneMutation, InsertContractAgreementAttachmentOneMutationVariables>;
export const ReplaceContractAgreementFeeChargesDocument = gql`
    mutation ReplaceContractAgreementFeeCharges($contract_number: String!, $register_number: String!, $insertedIds: [uuid!]!, $insertedData: [contract_agreement_fee_charge_insert_input!]!) {
  deleted_rows: delete_contract_agreement_fee_charge(
    where: {id: {_nin: $insertedIds}, contract_number: {_eq: $contract_number}, register_number: {_eq: $register_number}}
  ) {
    affected_rows
  }
  inserted_rows: insert_contract_agreement_fee_charge(
    objects: $insertedData
    on_conflict: {constraint: agreement_fee_charge_pkey, update_columns: [amount, date, description]}
  ) {
    affected_rows
  }
}
    `;
export type ReplaceContractAgreementFeeChargesMutationFn = Apollo.MutationFunction<ReplaceContractAgreementFeeChargesMutation, ReplaceContractAgreementFeeChargesMutationVariables>;
export type ReplaceContractAgreementFeeChargesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReplaceContractAgreementFeeChargesMutation, ReplaceContractAgreementFeeChargesMutationVariables>, 'mutation'>;

    export const ReplaceContractAgreementFeeChargesComponent = (props: ReplaceContractAgreementFeeChargesComponentProps) => (
      <ApolloReactComponents.Mutation<ReplaceContractAgreementFeeChargesMutation, ReplaceContractAgreementFeeChargesMutationVariables> mutation={ReplaceContractAgreementFeeChargesDocument} {...props} />
    );
    

/**
 * __useReplaceContractAgreementFeeChargesMutation__
 *
 * To run a mutation, you first call `useReplaceContractAgreementFeeChargesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceContractAgreementFeeChargesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceContractAgreementFeeChargesMutation, { data, loading, error }] = useReplaceContractAgreementFeeChargesMutation({
 *   variables: {
 *      contract_number: // value for 'contract_number'
 *      register_number: // value for 'register_number'
 *      insertedIds: // value for 'insertedIds'
 *      insertedData: // value for 'insertedData'
 *   },
 * });
 */
export function useReplaceContractAgreementFeeChargesMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceContractAgreementFeeChargesMutation, ReplaceContractAgreementFeeChargesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplaceContractAgreementFeeChargesMutation, ReplaceContractAgreementFeeChargesMutationVariables>(ReplaceContractAgreementFeeChargesDocument, options);
      }
export type ReplaceContractAgreementFeeChargesMutationHookResult = ReturnType<typeof useReplaceContractAgreementFeeChargesMutation>;
export type ReplaceContractAgreementFeeChargesMutationResult = Apollo.MutationResult<ReplaceContractAgreementFeeChargesMutation>;
export type ReplaceContractAgreementFeeChargesMutationOptions = Apollo.BaseMutationOptions<ReplaceContractAgreementFeeChargesMutation, ReplaceContractAgreementFeeChargesMutationVariables>;
export const GetContractAgreementsDocument = gql`
    query GetContractAgreements($distinctOn: [contract_agreement_select_column!], $limit: Int, $offset: Int, $orderBy: [contract_agreement_order_by!], $where: contract_agreement_bool_exp) {
  rows: contract_agreement(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...ContractAgreement
  }
}
    ${ContractAgreementFragmentDoc}`;
export type GetContractAgreementsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetContractAgreementsQuery, GetContractAgreementsQueryVariables>, 'query'>;

    export const GetContractAgreementsComponent = (props: GetContractAgreementsComponentProps) => (
      <ApolloReactComponents.Query<GetContractAgreementsQuery, GetContractAgreementsQueryVariables> query={GetContractAgreementsDocument} {...props} />
    );
    

/**
 * __useGetContractAgreementsQuery__
 *
 * To run a query within a React component, call `useGetContractAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractAgreementsQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetContractAgreementsQuery(baseOptions?: Apollo.QueryHookOptions<GetContractAgreementsQuery, GetContractAgreementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractAgreementsQuery, GetContractAgreementsQueryVariables>(GetContractAgreementsDocument, options);
      }
export function useGetContractAgreementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractAgreementsQuery, GetContractAgreementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractAgreementsQuery, GetContractAgreementsQueryVariables>(GetContractAgreementsDocument, options);
        }
export type GetContractAgreementsQueryHookResult = ReturnType<typeof useGetContractAgreementsQuery>;
export type GetContractAgreementsLazyQueryHookResult = ReturnType<typeof useGetContractAgreementsLazyQuery>;
export type GetContractAgreementsQueryResult = Apollo.QueryResult<GetContractAgreementsQuery, GetContractAgreementsQueryVariables>;
export const GetContractAgreementByPkDocument = gql`
    query GetContractAgreementByPk($id: uuid!) {
  entity: contract_agreement_by_pk(id: $id) {
    ...ContractAgreement
  }
}
    ${ContractAgreementFragmentDoc}`;
export type GetContractAgreementByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetContractAgreementByPkQuery, GetContractAgreementByPkQueryVariables>, 'query'> & ({ variables: GetContractAgreementByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetContractAgreementByPkComponent = (props: GetContractAgreementByPkComponentProps) => (
      <ApolloReactComponents.Query<GetContractAgreementByPkQuery, GetContractAgreementByPkQueryVariables> query={GetContractAgreementByPkDocument} {...props} />
    );
    

/**
 * __useGetContractAgreementByPkQuery__
 *
 * To run a query within a React component, call `useGetContractAgreementByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractAgreementByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractAgreementByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContractAgreementByPkQuery(baseOptions: Apollo.QueryHookOptions<GetContractAgreementByPkQuery, GetContractAgreementByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractAgreementByPkQuery, GetContractAgreementByPkQueryVariables>(GetContractAgreementByPkDocument, options);
      }
export function useGetContractAgreementByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractAgreementByPkQuery, GetContractAgreementByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractAgreementByPkQuery, GetContractAgreementByPkQueryVariables>(GetContractAgreementByPkDocument, options);
        }
export type GetContractAgreementByPkQueryHookResult = ReturnType<typeof useGetContractAgreementByPkQuery>;
export type GetContractAgreementByPkLazyQueryHookResult = ReturnType<typeof useGetContractAgreementByPkLazyQuery>;
export type GetContractAgreementByPkQueryResult = Apollo.QueryResult<GetContractAgreementByPkQuery, GetContractAgreementByPkQueryVariables>;
export const GetContractAgreementTransactionByIdDocument = gql`
    query GetContractAgreementTransactionById($registerNumber: String!, $contractNumber: String!) {
  rows: contract_agreement_transaction(
    where: {contract_number: {_eq: $contractNumber}, register_number: {_eq: $registerNumber}}
    order_by: {created_at: desc_nulls_last}
  ) {
    ...ContractAgreementTransaction
  }
}
    ${ContractAgreementTransactionFragmentDoc}`;
export type GetContractAgreementTransactionByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetContractAgreementTransactionByIdQuery, GetContractAgreementTransactionByIdQueryVariables>, 'query'> & ({ variables: GetContractAgreementTransactionByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetContractAgreementTransactionByIdComponent = (props: GetContractAgreementTransactionByIdComponentProps) => (
      <ApolloReactComponents.Query<GetContractAgreementTransactionByIdQuery, GetContractAgreementTransactionByIdQueryVariables> query={GetContractAgreementTransactionByIdDocument} {...props} />
    );
    

/**
 * __useGetContractAgreementTransactionByIdQuery__
 *
 * To run a query within a React component, call `useGetContractAgreementTransactionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractAgreementTransactionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractAgreementTransactionByIdQuery({
 *   variables: {
 *      registerNumber: // value for 'registerNumber'
 *      contractNumber: // value for 'contractNumber'
 *   },
 * });
 */
export function useGetContractAgreementTransactionByIdQuery(baseOptions: Apollo.QueryHookOptions<GetContractAgreementTransactionByIdQuery, GetContractAgreementTransactionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractAgreementTransactionByIdQuery, GetContractAgreementTransactionByIdQueryVariables>(GetContractAgreementTransactionByIdDocument, options);
      }
export function useGetContractAgreementTransactionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractAgreementTransactionByIdQuery, GetContractAgreementTransactionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractAgreementTransactionByIdQuery, GetContractAgreementTransactionByIdQueryVariables>(GetContractAgreementTransactionByIdDocument, options);
        }
export type GetContractAgreementTransactionByIdQueryHookResult = ReturnType<typeof useGetContractAgreementTransactionByIdQuery>;
export type GetContractAgreementTransactionByIdLazyQueryHookResult = ReturnType<typeof useGetContractAgreementTransactionByIdLazyQuery>;
export type GetContractAgreementTransactionByIdQueryResult = Apollo.QueryResult<GetContractAgreementTransactionByIdQuery, GetContractAgreementTransactionByIdQueryVariables>;
export const GetContractAgreementFeeChargesDocument = gql`
    query GetContractAgreementFeeCharges($distinctOn: [contract_agreement_fee_charge_select_column!], $limit: Int, $offset: Int, $orderBy: [contract_agreement_fee_charge_order_by!], $where: contract_agreement_fee_charge_bool_exp) {
  rows: contract_agreement_fee_charge(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    amount
    contract_number
    created_at
    date
    description
    id
    register_number
    updated_at
  }
}
    `;
export type GetContractAgreementFeeChargesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetContractAgreementFeeChargesQuery, GetContractAgreementFeeChargesQueryVariables>, 'query'>;

    export const GetContractAgreementFeeChargesComponent = (props: GetContractAgreementFeeChargesComponentProps) => (
      <ApolloReactComponents.Query<GetContractAgreementFeeChargesQuery, GetContractAgreementFeeChargesQueryVariables> query={GetContractAgreementFeeChargesDocument} {...props} />
    );
    

/**
 * __useGetContractAgreementFeeChargesQuery__
 *
 * To run a query within a React component, call `useGetContractAgreementFeeChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractAgreementFeeChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractAgreementFeeChargesQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetContractAgreementFeeChargesQuery(baseOptions?: Apollo.QueryHookOptions<GetContractAgreementFeeChargesQuery, GetContractAgreementFeeChargesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractAgreementFeeChargesQuery, GetContractAgreementFeeChargesQueryVariables>(GetContractAgreementFeeChargesDocument, options);
      }
export function useGetContractAgreementFeeChargesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractAgreementFeeChargesQuery, GetContractAgreementFeeChargesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractAgreementFeeChargesQuery, GetContractAgreementFeeChargesQueryVariables>(GetContractAgreementFeeChargesDocument, options);
        }
export type GetContractAgreementFeeChargesQueryHookResult = ReturnType<typeof useGetContractAgreementFeeChargesQuery>;
export type GetContractAgreementFeeChargesLazyQueryHookResult = ReturnType<typeof useGetContractAgreementFeeChargesLazyQuery>;
export type GetContractAgreementFeeChargesQueryResult = Apollo.QueryResult<GetContractAgreementFeeChargesQuery, GetContractAgreementFeeChargesQueryVariables>;
export const GetContractAgreementAttachmentsDocument = gql`
    query GetContractAgreementAttachments($distinctOn: [contract_agreement_attachment_select_column!], $limit: Int, $offset: Int, $orderBy: [contract_agreement_attachment_order_by!], $where: contract_agreement_attachment_bool_exp) {
  rows: contract_agreement_attachment(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...ContractAgreementAttachment
  }
}
    ${ContractAgreementAttachmentFragmentDoc}`;
export type GetContractAgreementAttachmentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetContractAgreementAttachmentsQuery, GetContractAgreementAttachmentsQueryVariables>, 'query'>;

    export const GetContractAgreementAttachmentsComponent = (props: GetContractAgreementAttachmentsComponentProps) => (
      <ApolloReactComponents.Query<GetContractAgreementAttachmentsQuery, GetContractAgreementAttachmentsQueryVariables> query={GetContractAgreementAttachmentsDocument} {...props} />
    );
    

/**
 * __useGetContractAgreementAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetContractAgreementAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractAgreementAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractAgreementAttachmentsQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetContractAgreementAttachmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetContractAgreementAttachmentsQuery, GetContractAgreementAttachmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractAgreementAttachmentsQuery, GetContractAgreementAttachmentsQueryVariables>(GetContractAgreementAttachmentsDocument, options);
      }
export function useGetContractAgreementAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractAgreementAttachmentsQuery, GetContractAgreementAttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractAgreementAttachmentsQuery, GetContractAgreementAttachmentsQueryVariables>(GetContractAgreementAttachmentsDocument, options);
        }
export type GetContractAgreementAttachmentsQueryHookResult = ReturnType<typeof useGetContractAgreementAttachmentsQuery>;
export type GetContractAgreementAttachmentsLazyQueryHookResult = ReturnType<typeof useGetContractAgreementAttachmentsLazyQuery>;
export type GetContractAgreementAttachmentsQueryResult = Apollo.QueryResult<GetContractAgreementAttachmentsQuery, GetContractAgreementAttachmentsQueryVariables>;
export const GetAfStatusesDocument = gql`
    query GetAfStatuses {
  rows: contract_af_status {
    id
    description
  }
}
    `;
export type GetAfStatusesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAfStatusesQuery, GetAfStatusesQueryVariables>, 'query'>;

    export const GetAfStatusesComponent = (props: GetAfStatusesComponentProps) => (
      <ApolloReactComponents.Query<GetAfStatusesQuery, GetAfStatusesQueryVariables> query={GetAfStatusesDocument} {...props} />
    );
    

/**
 * __useGetAfStatusesQuery__
 *
 * To run a query within a React component, call `useGetAfStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAfStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAfStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAfStatusesQuery(baseOptions?: Apollo.QueryHookOptions<GetAfStatusesQuery, GetAfStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAfStatusesQuery, GetAfStatusesQueryVariables>(GetAfStatusesDocument, options);
      }
export function useGetAfStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAfStatusesQuery, GetAfStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAfStatusesQuery, GetAfStatusesQueryVariables>(GetAfStatusesDocument, options);
        }
export type GetAfStatusesQueryHookResult = ReturnType<typeof useGetAfStatusesQuery>;
export type GetAfStatusesLazyQueryHookResult = ReturnType<typeof useGetAfStatusesLazyQuery>;
export type GetAfStatusesQueryResult = Apollo.QueryResult<GetAfStatusesQuery, GetAfStatusesQueryVariables>;
export const GetContractAgreementStatusReportDocument = gql`
    query GetContractAgreementStatusReport {
  status_rows: contract_agreement_af_status_count(
    order_by: {count: desc_nulls_last}
  ) {
    af_status_id
    af_status_description
    bank_program_reference
    count
    program_id
    program {
      name
    }
  }
  unique_contract_agreement_count: contract_agreement_aggregate(
    distinct_on: [contract_number, register_number]
  ) {
    aggregate {
      count
    }
  }
  all_ccontract_agreement_count: contract_agreement_aggregate {
    aggregate {
      count
    }
  }
  all_contract_detail_count: contract_agreement_detail_aggregate {
    aggregate {
      count
    }
  }
  all_contract_transaction_count: contract_agreement_transaction_aggregate {
    aggregate {
      count
    }
  }
}
    `;
export type GetContractAgreementStatusReportComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetContractAgreementStatusReportQuery, GetContractAgreementStatusReportQueryVariables>, 'query'>;

    export const GetContractAgreementStatusReportComponent = (props: GetContractAgreementStatusReportComponentProps) => (
      <ApolloReactComponents.Query<GetContractAgreementStatusReportQuery, GetContractAgreementStatusReportQueryVariables> query={GetContractAgreementStatusReportDocument} {...props} />
    );
    

/**
 * __useGetContractAgreementStatusReportQuery__
 *
 * To run a query within a React component, call `useGetContractAgreementStatusReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractAgreementStatusReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractAgreementStatusReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContractAgreementStatusReportQuery(baseOptions?: Apollo.QueryHookOptions<GetContractAgreementStatusReportQuery, GetContractAgreementStatusReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractAgreementStatusReportQuery, GetContractAgreementStatusReportQueryVariables>(GetContractAgreementStatusReportDocument, options);
      }
export function useGetContractAgreementStatusReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractAgreementStatusReportQuery, GetContractAgreementStatusReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractAgreementStatusReportQuery, GetContractAgreementStatusReportQueryVariables>(GetContractAgreementStatusReportDocument, options);
        }
export type GetContractAgreementStatusReportQueryHookResult = ReturnType<typeof useGetContractAgreementStatusReportQuery>;
export type GetContractAgreementStatusReportLazyQueryHookResult = ReturnType<typeof useGetContractAgreementStatusReportLazyQuery>;
export type GetContractAgreementStatusReportQueryResult = Apollo.QueryResult<GetContractAgreementStatusReportQuery, GetContractAgreementStatusReportQueryVariables>;
export const UpdateContractAgreementDetailByPkDocument = gql`
    mutation UpdateContractAgreementDetailByPk($id: uuid!, $_set: contract_agreement_detail_set_input) {
  update_contract_agreement_detail_by_pk(pk_columns: {id: $id}, _set: $_set) {
    ...ContractAgreementDetail
  }
}
    ${ContractAgreementDetailFragmentDoc}`;
export type UpdateContractAgreementDetailByPkMutationFn = Apollo.MutationFunction<UpdateContractAgreementDetailByPkMutation, UpdateContractAgreementDetailByPkMutationVariables>;
export type UpdateContractAgreementDetailByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateContractAgreementDetailByPkMutation, UpdateContractAgreementDetailByPkMutationVariables>, 'mutation'>;

    export const UpdateContractAgreementDetailByPkComponent = (props: UpdateContractAgreementDetailByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateContractAgreementDetailByPkMutation, UpdateContractAgreementDetailByPkMutationVariables> mutation={UpdateContractAgreementDetailByPkDocument} {...props} />
    );
    

/**
 * __useUpdateContractAgreementDetailByPkMutation__
 *
 * To run a mutation, you first call `useUpdateContractAgreementDetailByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractAgreementDetailByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractAgreementDetailByPkMutation, { data, loading, error }] = useUpdateContractAgreementDetailByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateContractAgreementDetailByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContractAgreementDetailByPkMutation, UpdateContractAgreementDetailByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContractAgreementDetailByPkMutation, UpdateContractAgreementDetailByPkMutationVariables>(UpdateContractAgreementDetailByPkDocument, options);
      }
export type UpdateContractAgreementDetailByPkMutationHookResult = ReturnType<typeof useUpdateContractAgreementDetailByPkMutation>;
export type UpdateContractAgreementDetailByPkMutationResult = Apollo.MutationResult<UpdateContractAgreementDetailByPkMutation>;
export type UpdateContractAgreementDetailByPkMutationOptions = Apollo.BaseMutationOptions<UpdateContractAgreementDetailByPkMutation, UpdateContractAgreementDetailByPkMutationVariables>;
export const GetContractAgreementDetailsDocument = gql`
    query GetContractAgreementDetails($distinctOn: [contract_agreement_detail_select_column!], $limit: Int, $offset: Int, $orderBy: [contract_agreement_detail_order_by!], $where: contract_agreement_detail_bool_exp) {
  rows: contract_agreement_detail(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...ContractAgreementDetail
  }
}
    ${ContractAgreementDetailFragmentDoc}`;
export type GetContractAgreementDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetContractAgreementDetailsQuery, GetContractAgreementDetailsQueryVariables>, 'query'>;

    export const GetContractAgreementDetailsComponent = (props: GetContractAgreementDetailsComponentProps) => (
      <ApolloReactComponents.Query<GetContractAgreementDetailsQuery, GetContractAgreementDetailsQueryVariables> query={GetContractAgreementDetailsDocument} {...props} />
    );
    

/**
 * __useGetContractAgreementDetailsQuery__
 *
 * To run a query within a React component, call `useGetContractAgreementDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractAgreementDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractAgreementDetailsQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetContractAgreementDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetContractAgreementDetailsQuery, GetContractAgreementDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractAgreementDetailsQuery, GetContractAgreementDetailsQueryVariables>(GetContractAgreementDetailsDocument, options);
      }
export function useGetContractAgreementDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractAgreementDetailsQuery, GetContractAgreementDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractAgreementDetailsQuery, GetContractAgreementDetailsQueryVariables>(GetContractAgreementDetailsDocument, options);
        }
export type GetContractAgreementDetailsQueryHookResult = ReturnType<typeof useGetContractAgreementDetailsQuery>;
export type GetContractAgreementDetailsLazyQueryHookResult = ReturnType<typeof useGetContractAgreementDetailsLazyQuery>;
export type GetContractAgreementDetailsQueryResult = Apollo.QueryResult<GetContractAgreementDetailsQuery, GetContractAgreementDetailsQueryVariables>;
export const GetContractAgreementDetailByPkDocument = gql`
    query GetContractAgreementDetailByPk($id: uuid!) {
  entity: contract_agreement_detail_by_pk(id: $id) {
    ...ContractAgreementDetail
  }
}
    ${ContractAgreementDetailFragmentDoc}`;
export type GetContractAgreementDetailByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetContractAgreementDetailByPkQuery, GetContractAgreementDetailByPkQueryVariables>, 'query'> & ({ variables: GetContractAgreementDetailByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetContractAgreementDetailByPkComponent = (props: GetContractAgreementDetailByPkComponentProps) => (
      <ApolloReactComponents.Query<GetContractAgreementDetailByPkQuery, GetContractAgreementDetailByPkQueryVariables> query={GetContractAgreementDetailByPkDocument} {...props} />
    );
    

/**
 * __useGetContractAgreementDetailByPkQuery__
 *
 * To run a query within a React component, call `useGetContractAgreementDetailByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractAgreementDetailByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractAgreementDetailByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContractAgreementDetailByPkQuery(baseOptions: Apollo.QueryHookOptions<GetContractAgreementDetailByPkQuery, GetContractAgreementDetailByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractAgreementDetailByPkQuery, GetContractAgreementDetailByPkQueryVariables>(GetContractAgreementDetailByPkDocument, options);
      }
export function useGetContractAgreementDetailByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractAgreementDetailByPkQuery, GetContractAgreementDetailByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractAgreementDetailByPkQuery, GetContractAgreementDetailByPkQueryVariables>(GetContractAgreementDetailByPkDocument, options);
        }
export type GetContractAgreementDetailByPkQueryHookResult = ReturnType<typeof useGetContractAgreementDetailByPkQuery>;
export type GetContractAgreementDetailByPkLazyQueryHookResult = ReturnType<typeof useGetContractAgreementDetailByPkLazyQuery>;
export type GetContractAgreementDetailByPkQueryResult = Apollo.QueryResult<GetContractAgreementDetailByPkQuery, GetContractAgreementDetailByPkQueryVariables>;
export const GetContractAgreementReceiptsDocument = gql`
    query GetContractAgreementReceipts($distinctOn: [contract_agreement_receipt_select_column!], $limit: Int, $offset: Int, $orderBy: [contract_agreement_receipt_order_by!], $where: contract_agreement_receipt_bool_exp) {
  rows: contract_agreement_receipt(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...ContractAgreementReceipt
  }
}
    ${ContractAgreementReceiptFragmentDoc}`;
export type GetContractAgreementReceiptsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetContractAgreementReceiptsQuery, GetContractAgreementReceiptsQueryVariables>, 'query'>;

    export const GetContractAgreementReceiptsComponent = (props: GetContractAgreementReceiptsComponentProps) => (
      <ApolloReactComponents.Query<GetContractAgreementReceiptsQuery, GetContractAgreementReceiptsQueryVariables> query={GetContractAgreementReceiptsDocument} {...props} />
    );
    

/**
 * __useGetContractAgreementReceiptsQuery__
 *
 * To run a query within a React component, call `useGetContractAgreementReceiptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractAgreementReceiptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractAgreementReceiptsQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetContractAgreementReceiptsQuery(baseOptions?: Apollo.QueryHookOptions<GetContractAgreementReceiptsQuery, GetContractAgreementReceiptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractAgreementReceiptsQuery, GetContractAgreementReceiptsQueryVariables>(GetContractAgreementReceiptsDocument, options);
      }
export function useGetContractAgreementReceiptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractAgreementReceiptsQuery, GetContractAgreementReceiptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractAgreementReceiptsQuery, GetContractAgreementReceiptsQueryVariables>(GetContractAgreementReceiptsDocument, options);
        }
export type GetContractAgreementReceiptsQueryHookResult = ReturnType<typeof useGetContractAgreementReceiptsQuery>;
export type GetContractAgreementReceiptsLazyQueryHookResult = ReturnType<typeof useGetContractAgreementReceiptsLazyQuery>;
export type GetContractAgreementReceiptsQueryResult = Apollo.QueryResult<GetContractAgreementReceiptsQuery, GetContractAgreementReceiptsQueryVariables>;
export const GetContractAgreementReceiptByPkDocument = gql`
    query GetContractAgreementReceiptByPk($id: String!) {
  entity: contract_agreement_receipt_by_pk(receipt_no: $id) {
    ...ContractAgreementReceipt
  }
}
    ${ContractAgreementReceiptFragmentDoc}`;
export type GetContractAgreementReceiptByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetContractAgreementReceiptByPkQuery, GetContractAgreementReceiptByPkQueryVariables>, 'query'> & ({ variables: GetContractAgreementReceiptByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetContractAgreementReceiptByPkComponent = (props: GetContractAgreementReceiptByPkComponentProps) => (
      <ApolloReactComponents.Query<GetContractAgreementReceiptByPkQuery, GetContractAgreementReceiptByPkQueryVariables> query={GetContractAgreementReceiptByPkDocument} {...props} />
    );
    

/**
 * __useGetContractAgreementReceiptByPkQuery__
 *
 * To run a query within a React component, call `useGetContractAgreementReceiptByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractAgreementReceiptByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractAgreementReceiptByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContractAgreementReceiptByPkQuery(baseOptions: Apollo.QueryHookOptions<GetContractAgreementReceiptByPkQuery, GetContractAgreementReceiptByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractAgreementReceiptByPkQuery, GetContractAgreementReceiptByPkQueryVariables>(GetContractAgreementReceiptByPkDocument, options);
      }
export function useGetContractAgreementReceiptByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractAgreementReceiptByPkQuery, GetContractAgreementReceiptByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractAgreementReceiptByPkQuery, GetContractAgreementReceiptByPkQueryVariables>(GetContractAgreementReceiptByPkDocument, options);
        }
export type GetContractAgreementReceiptByPkQueryHookResult = ReturnType<typeof useGetContractAgreementReceiptByPkQuery>;
export type GetContractAgreementReceiptByPkLazyQueryHookResult = ReturnType<typeof useGetContractAgreementReceiptByPkLazyQuery>;
export type GetContractAgreementReceiptByPkQueryResult = Apollo.QueryResult<GetContractAgreementReceiptByPkQuery, GetContractAgreementReceiptByPkQueryVariables>;
export const GetContractAgreementWithdrawsDocument = gql`
    query GetContractAgreementWithdraws($distinctOn: [contract_agreement_withdraw_select_column!], $limit: Int, $offset: Int, $orderBy: [contract_agreement_withdraw_order_by!], $where: contract_agreement_withdraw_bool_exp) {
  rows: contract_agreement_withdraw(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...ContractAgreementWithdraw
  }
}
    ${ContractAgreementWithdrawFragmentDoc}`;
export type GetContractAgreementWithdrawsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetContractAgreementWithdrawsQuery, GetContractAgreementWithdrawsQueryVariables>, 'query'>;

    export const GetContractAgreementWithdrawsComponent = (props: GetContractAgreementWithdrawsComponentProps) => (
      <ApolloReactComponents.Query<GetContractAgreementWithdrawsQuery, GetContractAgreementWithdrawsQueryVariables> query={GetContractAgreementWithdrawsDocument} {...props} />
    );
    

/**
 * __useGetContractAgreementWithdrawsQuery__
 *
 * To run a query within a React component, call `useGetContractAgreementWithdrawsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractAgreementWithdrawsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractAgreementWithdrawsQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetContractAgreementWithdrawsQuery(baseOptions?: Apollo.QueryHookOptions<GetContractAgreementWithdrawsQuery, GetContractAgreementWithdrawsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractAgreementWithdrawsQuery, GetContractAgreementWithdrawsQueryVariables>(GetContractAgreementWithdrawsDocument, options);
      }
export function useGetContractAgreementWithdrawsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractAgreementWithdrawsQuery, GetContractAgreementWithdrawsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractAgreementWithdrawsQuery, GetContractAgreementWithdrawsQueryVariables>(GetContractAgreementWithdrawsDocument, options);
        }
export type GetContractAgreementWithdrawsQueryHookResult = ReturnType<typeof useGetContractAgreementWithdrawsQuery>;
export type GetContractAgreementWithdrawsLazyQueryHookResult = ReturnType<typeof useGetContractAgreementWithdrawsLazyQuery>;
export type GetContractAgreementWithdrawsQueryResult = Apollo.QueryResult<GetContractAgreementWithdrawsQuery, GetContractAgreementWithdrawsQueryVariables>;
export const GetContractAgreementWithdrawByPkDocument = gql`
    query GetContractAgreementWithdrawByPk($id: String!) {
  entity: contract_agreement_withdraw_by_pk(draw_down_no: $id) {
    ...ContractAgreementWithdraw
  }
}
    ${ContractAgreementWithdrawFragmentDoc}`;
export type GetContractAgreementWithdrawByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetContractAgreementWithdrawByPkQuery, GetContractAgreementWithdrawByPkQueryVariables>, 'query'> & ({ variables: GetContractAgreementWithdrawByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetContractAgreementWithdrawByPkComponent = (props: GetContractAgreementWithdrawByPkComponentProps) => (
      <ApolloReactComponents.Query<GetContractAgreementWithdrawByPkQuery, GetContractAgreementWithdrawByPkQueryVariables> query={GetContractAgreementWithdrawByPkDocument} {...props} />
    );
    

/**
 * __useGetContractAgreementWithdrawByPkQuery__
 *
 * To run a query within a React component, call `useGetContractAgreementWithdrawByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractAgreementWithdrawByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractAgreementWithdrawByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContractAgreementWithdrawByPkQuery(baseOptions: Apollo.QueryHookOptions<GetContractAgreementWithdrawByPkQuery, GetContractAgreementWithdrawByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractAgreementWithdrawByPkQuery, GetContractAgreementWithdrawByPkQueryVariables>(GetContractAgreementWithdrawByPkDocument, options);
      }
export function useGetContractAgreementWithdrawByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractAgreementWithdrawByPkQuery, GetContractAgreementWithdrawByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractAgreementWithdrawByPkQuery, GetContractAgreementWithdrawByPkQueryVariables>(GetContractAgreementWithdrawByPkDocument, options);
        }
export type GetContractAgreementWithdrawByPkQueryHookResult = ReturnType<typeof useGetContractAgreementWithdrawByPkQuery>;
export type GetContractAgreementWithdrawByPkLazyQueryHookResult = ReturnType<typeof useGetContractAgreementWithdrawByPkLazyQuery>;
export type GetContractAgreementWithdrawByPkQueryResult = Apollo.QueryResult<GetContractAgreementWithdrawByPkQuery, GetContractAgreementWithdrawByPkQueryVariables>;
export const GetMatrixReportByOrganizationIdDocument = gql`
    query GetMatrixReportByOrganizationId($fiscalYear: Int!, $organizationId: uuid) {
  budget_rows: account_budget_type(
    where: {fiscal_year: {_eq: $fiscalYear}}
    order_by: {sequence: asc}
  ) {
    id
    description
    sequence
    deleted_at
    fiscal_year
    account_id
  }
  account_rows: account_account(
    where: {deleted_at: {_is_null: true}}
    order_by: {code: asc}
  ) {
    id
    code
    name
    account_type {
      id
      description
    }
  }
  account_balance_sheet_rows: account_account_balance_sheet(
    where: {fiscal_year: {_eq: $fiscalYear}, organization_id: {_eq: $organizationId}}
  ) {
    balance
    budget_id
    fiscal_year
    id
    name
    organization_id
  }
}
    `;
export type GetMatrixReportByOrganizationIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMatrixReportByOrganizationIdQuery, GetMatrixReportByOrganizationIdQueryVariables>, 'query'> & ({ variables: GetMatrixReportByOrganizationIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetMatrixReportByOrganizationIdComponent = (props: GetMatrixReportByOrganizationIdComponentProps) => (
      <ApolloReactComponents.Query<GetMatrixReportByOrganizationIdQuery, GetMatrixReportByOrganizationIdQueryVariables> query={GetMatrixReportByOrganizationIdDocument} {...props} />
    );
    

/**
 * __useGetMatrixReportByOrganizationIdQuery__
 *
 * To run a query within a React component, call `useGetMatrixReportByOrganizationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatrixReportByOrganizationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatrixReportByOrganizationIdQuery({
 *   variables: {
 *      fiscalYear: // value for 'fiscalYear'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetMatrixReportByOrganizationIdQuery(baseOptions: Apollo.QueryHookOptions<GetMatrixReportByOrganizationIdQuery, GetMatrixReportByOrganizationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMatrixReportByOrganizationIdQuery, GetMatrixReportByOrganizationIdQueryVariables>(GetMatrixReportByOrganizationIdDocument, options);
      }
export function useGetMatrixReportByOrganizationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMatrixReportByOrganizationIdQuery, GetMatrixReportByOrganizationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMatrixReportByOrganizationIdQuery, GetMatrixReportByOrganizationIdQueryVariables>(GetMatrixReportByOrganizationIdDocument, options);
        }
export type GetMatrixReportByOrganizationIdQueryHookResult = ReturnType<typeof useGetMatrixReportByOrganizationIdQuery>;
export type GetMatrixReportByOrganizationIdLazyQueryHookResult = ReturnType<typeof useGetMatrixReportByOrganizationIdLazyQuery>;
export type GetMatrixReportByOrganizationIdQueryResult = Apollo.QueryResult<GetMatrixReportByOrganizationIdQuery, GetMatrixReportByOrganizationIdQueryVariables>;
export const GetMonthlyReportByOrganizationIdDocument = gql`
    query GetMonthlyReportByOrganizationId($fiscalYear: Int!, $organizationId: uuid) {
  budget_rows: account_budget_type(
    where: {fiscal_year: {_eq: $fiscalYear}}
    order_by: {sequence: asc}
  ) {
    id
    description
    sequence
    deleted_at
    fiscal_year
    account_id
  }
  account_rows: account_account(
    where: {deleted_at: {_is_null: true}}
    order_by: {code: asc}
  ) {
    id
    code
    name
    account_type {
      id
      description
    }
  }
  account_monthly_report_rows: account_account_monthly_report(
    where: {fiscal_year: {_eq: $fiscalYear}, organization_id: {_eq: $organizationId}}
  ) {
    october
    november
    december
    january
    february
    march
    april
    may
    june
    july
    august
    september
    budget_id
    fiscal_year
    id
    name
    organization_id
  }
}
    `;
export type GetMonthlyReportByOrganizationIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMonthlyReportByOrganizationIdQuery, GetMonthlyReportByOrganizationIdQueryVariables>, 'query'> & ({ variables: GetMonthlyReportByOrganizationIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetMonthlyReportByOrganizationIdComponent = (props: GetMonthlyReportByOrganizationIdComponentProps) => (
      <ApolloReactComponents.Query<GetMonthlyReportByOrganizationIdQuery, GetMonthlyReportByOrganizationIdQueryVariables> query={GetMonthlyReportByOrganizationIdDocument} {...props} />
    );
    

/**
 * __useGetMonthlyReportByOrganizationIdQuery__
 *
 * To run a query within a React component, call `useGetMonthlyReportByOrganizationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonthlyReportByOrganizationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonthlyReportByOrganizationIdQuery({
 *   variables: {
 *      fiscalYear: // value for 'fiscalYear'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetMonthlyReportByOrganizationIdQuery(baseOptions: Apollo.QueryHookOptions<GetMonthlyReportByOrganizationIdQuery, GetMonthlyReportByOrganizationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMonthlyReportByOrganizationIdQuery, GetMonthlyReportByOrganizationIdQueryVariables>(GetMonthlyReportByOrganizationIdDocument, options);
      }
export function useGetMonthlyReportByOrganizationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMonthlyReportByOrganizationIdQuery, GetMonthlyReportByOrganizationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMonthlyReportByOrganizationIdQuery, GetMonthlyReportByOrganizationIdQueryVariables>(GetMonthlyReportByOrganizationIdDocument, options);
        }
export type GetMonthlyReportByOrganizationIdQueryHookResult = ReturnType<typeof useGetMonthlyReportByOrganizationIdQuery>;
export type GetMonthlyReportByOrganizationIdLazyQueryHookResult = ReturnType<typeof useGetMonthlyReportByOrganizationIdLazyQuery>;
export type GetMonthlyReportByOrganizationIdQueryResult = Apollo.QueryResult<GetMonthlyReportByOrganizationIdQuery, GetMonthlyReportByOrganizationIdQueryVariables>;
export const InsertAccountTransactionOneDocument = gql`
    mutation InsertAccountTransactionOne($object: account_transaction_insert_input!) {
  insert_account_transaction_one(object: $object) {
    id
    description
    created_at
    created_by
    date
    organization_id
    organization {
      name
      short_name
    }
  }
}
    `;
export type InsertAccountTransactionOneMutationFn = Apollo.MutationFunction<InsertAccountTransactionOneMutation, InsertAccountTransactionOneMutationVariables>;
export type InsertAccountTransactionOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertAccountTransactionOneMutation, InsertAccountTransactionOneMutationVariables>, 'mutation'>;

    export const InsertAccountTransactionOneComponent = (props: InsertAccountTransactionOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertAccountTransactionOneMutation, InsertAccountTransactionOneMutationVariables> mutation={InsertAccountTransactionOneDocument} {...props} />
    );
    

/**
 * __useInsertAccountTransactionOneMutation__
 *
 * To run a mutation, you first call `useInsertAccountTransactionOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertAccountTransactionOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAccountTransactionOneMutation, { data, loading, error }] = useInsertAccountTransactionOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertAccountTransactionOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertAccountTransactionOneMutation, InsertAccountTransactionOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertAccountTransactionOneMutation, InsertAccountTransactionOneMutationVariables>(InsertAccountTransactionOneDocument, options);
      }
export type InsertAccountTransactionOneMutationHookResult = ReturnType<typeof useInsertAccountTransactionOneMutation>;
export type InsertAccountTransactionOneMutationResult = Apollo.MutationResult<InsertAccountTransactionOneMutation>;
export type InsertAccountTransactionOneMutationOptions = Apollo.BaseMutationOptions<InsertAccountTransactionOneMutation, InsertAccountTransactionOneMutationVariables>;
export const ReplaceAccountTransactionOneDocument = gql`
    mutation ReplaceAccountTransactionOne($transactionId: uuid!, $selectedLedgerIds: [uuid!]!, $insertTransaction: account_transaction_insert_input!, $transactionOnConflict: account_transaction_on_conflict) {
  delete_rows: delete_account_ledger_entry(
    where: {transaction_id: {_eq: $transactionId}, id: {_nin: $selectedLedgerIds}}
  ) {
    affected_rows
    returning {
      id
    }
  }
  insert_row: insert_account_transaction_one(
    object: $insertTransaction
    on_conflict: $transactionOnConflict
  ) {
    id
    description
    created_at
    created_by
    date
    organization_id
    organization {
      name
      short_name
    }
  }
}
    `;
export type ReplaceAccountTransactionOneMutationFn = Apollo.MutationFunction<ReplaceAccountTransactionOneMutation, ReplaceAccountTransactionOneMutationVariables>;
export type ReplaceAccountTransactionOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReplaceAccountTransactionOneMutation, ReplaceAccountTransactionOneMutationVariables>, 'mutation'>;

    export const ReplaceAccountTransactionOneComponent = (props: ReplaceAccountTransactionOneComponentProps) => (
      <ApolloReactComponents.Mutation<ReplaceAccountTransactionOneMutation, ReplaceAccountTransactionOneMutationVariables> mutation={ReplaceAccountTransactionOneDocument} {...props} />
    );
    

/**
 * __useReplaceAccountTransactionOneMutation__
 *
 * To run a mutation, you first call `useReplaceAccountTransactionOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceAccountTransactionOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceAccountTransactionOneMutation, { data, loading, error }] = useReplaceAccountTransactionOneMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      selectedLedgerIds: // value for 'selectedLedgerIds'
 *      insertTransaction: // value for 'insertTransaction'
 *      transactionOnConflict: // value for 'transactionOnConflict'
 *   },
 * });
 */
export function useReplaceAccountTransactionOneMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceAccountTransactionOneMutation, ReplaceAccountTransactionOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplaceAccountTransactionOneMutation, ReplaceAccountTransactionOneMutationVariables>(ReplaceAccountTransactionOneDocument, options);
      }
export type ReplaceAccountTransactionOneMutationHookResult = ReturnType<typeof useReplaceAccountTransactionOneMutation>;
export type ReplaceAccountTransactionOneMutationResult = Apollo.MutationResult<ReplaceAccountTransactionOneMutation>;
export type ReplaceAccountTransactionOneMutationOptions = Apollo.BaseMutationOptions<ReplaceAccountTransactionOneMutation, ReplaceAccountTransactionOneMutationVariables>;
export const ApproveAccountTransactionDocument = gql`
    mutation ApproveAccountTransaction($data: AccountTransactionApprovalInput!) {
  account_transaction_approve(data: $data) {
    transactionId
  }
}
    `;
export type ApproveAccountTransactionMutationFn = Apollo.MutationFunction<ApproveAccountTransactionMutation, ApproveAccountTransactionMutationVariables>;
export type ApproveAccountTransactionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ApproveAccountTransactionMutation, ApproveAccountTransactionMutationVariables>, 'mutation'>;

    export const ApproveAccountTransactionComponent = (props: ApproveAccountTransactionComponentProps) => (
      <ApolloReactComponents.Mutation<ApproveAccountTransactionMutation, ApproveAccountTransactionMutationVariables> mutation={ApproveAccountTransactionDocument} {...props} />
    );
    

/**
 * __useApproveAccountTransactionMutation__
 *
 * To run a mutation, you first call `useApproveAccountTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveAccountTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveAccountTransactionMutation, { data, loading, error }] = useApproveAccountTransactionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useApproveAccountTransactionMutation(baseOptions?: Apollo.MutationHookOptions<ApproveAccountTransactionMutation, ApproveAccountTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveAccountTransactionMutation, ApproveAccountTransactionMutationVariables>(ApproveAccountTransactionDocument, options);
      }
export type ApproveAccountTransactionMutationHookResult = ReturnType<typeof useApproveAccountTransactionMutation>;
export type ApproveAccountTransactionMutationResult = Apollo.MutationResult<ApproveAccountTransactionMutation>;
export type ApproveAccountTransactionMutationOptions = Apollo.BaseMutationOptions<ApproveAccountTransactionMutation, ApproveAccountTransactionMutationVariables>;
export const DuplicateAccountBudgetByFiscalYearDocument = gql`
    mutation DuplicateAccountBudgetByFiscalYear($data: AccountBudgetDuplicateByFiscalYearInput!) {
  account_budget: account_budget_duplicate_by_fiscal_year(data: $data) {
    budgetIds
    fiscalYear
  }
}
    `;
export type DuplicateAccountBudgetByFiscalYearMutationFn = Apollo.MutationFunction<DuplicateAccountBudgetByFiscalYearMutation, DuplicateAccountBudgetByFiscalYearMutationVariables>;
export type DuplicateAccountBudgetByFiscalYearComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DuplicateAccountBudgetByFiscalYearMutation, DuplicateAccountBudgetByFiscalYearMutationVariables>, 'mutation'>;

    export const DuplicateAccountBudgetByFiscalYearComponent = (props: DuplicateAccountBudgetByFiscalYearComponentProps) => (
      <ApolloReactComponents.Mutation<DuplicateAccountBudgetByFiscalYearMutation, DuplicateAccountBudgetByFiscalYearMutationVariables> mutation={DuplicateAccountBudgetByFiscalYearDocument} {...props} />
    );
    

/**
 * __useDuplicateAccountBudgetByFiscalYearMutation__
 *
 * To run a mutation, you first call `useDuplicateAccountBudgetByFiscalYearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateAccountBudgetByFiscalYearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateAccountBudgetByFiscalYearMutation, { data, loading, error }] = useDuplicateAccountBudgetByFiscalYearMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDuplicateAccountBudgetByFiscalYearMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateAccountBudgetByFiscalYearMutation, DuplicateAccountBudgetByFiscalYearMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateAccountBudgetByFiscalYearMutation, DuplicateAccountBudgetByFiscalYearMutationVariables>(DuplicateAccountBudgetByFiscalYearDocument, options);
      }
export type DuplicateAccountBudgetByFiscalYearMutationHookResult = ReturnType<typeof useDuplicateAccountBudgetByFiscalYearMutation>;
export type DuplicateAccountBudgetByFiscalYearMutationResult = Apollo.MutationResult<DuplicateAccountBudgetByFiscalYearMutation>;
export type DuplicateAccountBudgetByFiscalYearMutationOptions = Apollo.BaseMutationOptions<DuplicateAccountBudgetByFiscalYearMutation, DuplicateAccountBudgetByFiscalYearMutationVariables>;
export const GetAccountTransactionsDocument = gql`
    query GetAccountTransactions($distinctOn: [account_transaction_select_column!], $limit: Int, $offset: Int, $orderBy: [account_transaction_order_by!], $where: account_transaction_bool_exp) {
  rows: account_transaction(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...AccountTransaction
    ledger_entries(order_by: [{created_at: asc}]) {
      ...AccountLedgerEntry
    }
    credit_total: ledger_entries_aggregate(where: {entry_type: {_eq: credit}}) {
      aggregate {
        sum {
          amount
        }
      }
    }
    debit_total: ledger_entries_aggregate(where: {entry_type: {_eq: debit}}) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
}
    ${AccountTransactionFragmentDoc}
${AccountLedgerEntryFragmentDoc}`;
export type GetAccountTransactionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountTransactionsQuery, GetAccountTransactionsQueryVariables>, 'query'>;

    export const GetAccountTransactionsComponent = (props: GetAccountTransactionsComponentProps) => (
      <ApolloReactComponents.Query<GetAccountTransactionsQuery, GetAccountTransactionsQueryVariables> query={GetAccountTransactionsDocument} {...props} />
    );
    

/**
 * __useGetAccountTransactionsQuery__
 *
 * To run a query within a React component, call `useGetAccountTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountTransactionsQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAccountTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountTransactionsQuery, GetAccountTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountTransactionsQuery, GetAccountTransactionsQueryVariables>(GetAccountTransactionsDocument, options);
      }
export function useGetAccountTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountTransactionsQuery, GetAccountTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountTransactionsQuery, GetAccountTransactionsQueryVariables>(GetAccountTransactionsDocument, options);
        }
export type GetAccountTransactionsQueryHookResult = ReturnType<typeof useGetAccountTransactionsQuery>;
export type GetAccountTransactionsLazyQueryHookResult = ReturnType<typeof useGetAccountTransactionsLazyQuery>;
export type GetAccountTransactionsQueryResult = Apollo.QueryResult<GetAccountTransactionsQuery, GetAccountTransactionsQueryVariables>;
export const GetAccountTransactionByPkDocument = gql`
    query GetAccountTransactionByPk($id: uuid!) {
  row: account_transaction_by_pk(id: $id) {
    ...AccountTransaction
    ledger_entries(order_by: [{created_at: asc}]) {
      ...AccountLedgerEntry
    }
  }
}
    ${AccountTransactionFragmentDoc}
${AccountLedgerEntryFragmentDoc}`;
export type GetAccountTransactionByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountTransactionByPkQuery, GetAccountTransactionByPkQueryVariables>, 'query'> & ({ variables: GetAccountTransactionByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAccountTransactionByPkComponent = (props: GetAccountTransactionByPkComponentProps) => (
      <ApolloReactComponents.Query<GetAccountTransactionByPkQuery, GetAccountTransactionByPkQueryVariables> query={GetAccountTransactionByPkDocument} {...props} />
    );
    

/**
 * __useGetAccountTransactionByPkQuery__
 *
 * To run a query within a React component, call `useGetAccountTransactionByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountTransactionByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountTransactionByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountTransactionByPkQuery(baseOptions: Apollo.QueryHookOptions<GetAccountTransactionByPkQuery, GetAccountTransactionByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountTransactionByPkQuery, GetAccountTransactionByPkQueryVariables>(GetAccountTransactionByPkDocument, options);
      }
export function useGetAccountTransactionByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountTransactionByPkQuery, GetAccountTransactionByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountTransactionByPkQuery, GetAccountTransactionByPkQueryVariables>(GetAccountTransactionByPkDocument, options);
        }
export type GetAccountTransactionByPkQueryHookResult = ReturnType<typeof useGetAccountTransactionByPkQuery>;
export type GetAccountTransactionByPkLazyQueryHookResult = ReturnType<typeof useGetAccountTransactionByPkLazyQuery>;
export type GetAccountTransactionByPkQueryResult = Apollo.QueryResult<GetAccountTransactionByPkQuery, GetAccountTransactionByPkQueryVariables>;
export const GetAccountBudgetAccountsDocument = gql`
    query GetAccountBudgetAccounts {
  rows: account_budget_account(where: {account: {deleted_at: {_is_null: true}}}) {
    account_id
    budget_id
    fiscal_year
    account {
      id
      code
      name
      account_type {
        id
        description
      }
    }
    budget_type {
      id
      description
      fiscal_year
    }
  }
}
    `;
export type GetAccountBudgetAccountsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountBudgetAccountsQuery, GetAccountBudgetAccountsQueryVariables>, 'query'>;

    export const GetAccountBudgetAccountsComponent = (props: GetAccountBudgetAccountsComponentProps) => (
      <ApolloReactComponents.Query<GetAccountBudgetAccountsQuery, GetAccountBudgetAccountsQueryVariables> query={GetAccountBudgetAccountsDocument} {...props} />
    );
    

/**
 * __useGetAccountBudgetAccountsQuery__
 *
 * To run a query within a React component, call `useGetAccountBudgetAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountBudgetAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountBudgetAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountBudgetAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountBudgetAccountsQuery, GetAccountBudgetAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountBudgetAccountsQuery, GetAccountBudgetAccountsQueryVariables>(GetAccountBudgetAccountsDocument, options);
      }
export function useGetAccountBudgetAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountBudgetAccountsQuery, GetAccountBudgetAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountBudgetAccountsQuery, GetAccountBudgetAccountsQueryVariables>(GetAccountBudgetAccountsDocument, options);
        }
export type GetAccountBudgetAccountsQueryHookResult = ReturnType<typeof useGetAccountBudgetAccountsQuery>;
export type GetAccountBudgetAccountsLazyQueryHookResult = ReturnType<typeof useGetAccountBudgetAccountsLazyQuery>;
export type GetAccountBudgetAccountsQueryResult = Apollo.QueryResult<GetAccountBudgetAccountsQuery, GetAccountBudgetAccountsQueryVariables>;
export const GetAccountLedgerEntriesDocument = gql`
    query GetAccountLedgerEntries($distinctOn: [account_ledger_entry_select_column!], $limit: Int, $offset: Int, $orderBy: [account_ledger_entry_order_by!], $where: account_ledger_entry_bool_exp) {
  rows: account_ledger_entry(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...AccountLedgerEntry
    transaction {
      ...AccountTransaction
    }
  }
}
    ${AccountLedgerEntryFragmentDoc}
${AccountTransactionFragmentDoc}`;
export type GetAccountLedgerEntriesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountLedgerEntriesQuery, GetAccountLedgerEntriesQueryVariables>, 'query'>;

    export const GetAccountLedgerEntriesComponent = (props: GetAccountLedgerEntriesComponentProps) => (
      <ApolloReactComponents.Query<GetAccountLedgerEntriesQuery, GetAccountLedgerEntriesQueryVariables> query={GetAccountLedgerEntriesDocument} {...props} />
    );
    

/**
 * __useGetAccountLedgerEntriesQuery__
 *
 * To run a query within a React component, call `useGetAccountLedgerEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountLedgerEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountLedgerEntriesQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAccountLedgerEntriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountLedgerEntriesQuery, GetAccountLedgerEntriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountLedgerEntriesQuery, GetAccountLedgerEntriesQueryVariables>(GetAccountLedgerEntriesDocument, options);
      }
export function useGetAccountLedgerEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountLedgerEntriesQuery, GetAccountLedgerEntriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountLedgerEntriesQuery, GetAccountLedgerEntriesQueryVariables>(GetAccountLedgerEntriesDocument, options);
        }
export type GetAccountLedgerEntriesQueryHookResult = ReturnType<typeof useGetAccountLedgerEntriesQuery>;
export type GetAccountLedgerEntriesLazyQueryHookResult = ReturnType<typeof useGetAccountLedgerEntriesLazyQuery>;
export type GetAccountLedgerEntriesQueryResult = Apollo.QueryResult<GetAccountLedgerEntriesQuery, GetAccountLedgerEntriesQueryVariables>;
export const GetAccountTransactionLogsByTransactionPkDocument = gql`
    query GetAccountTransactionLogsByTransactionPk($transactionId: uuid!) {
  transaction_logs: account_transaction_log(
    where: {transaction_id: {_eq: $transactionId}}
    order_by: {created_at: desc}
  ) {
    ...AccountTransactionLog
  }
}
    ${AccountTransactionLogFragmentDoc}`;
export type GetAccountTransactionLogsByTransactionPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountTransactionLogsByTransactionPkQuery, GetAccountTransactionLogsByTransactionPkQueryVariables>, 'query'> & ({ variables: GetAccountTransactionLogsByTransactionPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAccountTransactionLogsByTransactionPkComponent = (props: GetAccountTransactionLogsByTransactionPkComponentProps) => (
      <ApolloReactComponents.Query<GetAccountTransactionLogsByTransactionPkQuery, GetAccountTransactionLogsByTransactionPkQueryVariables> query={GetAccountTransactionLogsByTransactionPkDocument} {...props} />
    );
    

/**
 * __useGetAccountTransactionLogsByTransactionPkQuery__
 *
 * To run a query within a React component, call `useGetAccountTransactionLogsByTransactionPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountTransactionLogsByTransactionPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountTransactionLogsByTransactionPkQuery({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useGetAccountTransactionLogsByTransactionPkQuery(baseOptions: Apollo.QueryHookOptions<GetAccountTransactionLogsByTransactionPkQuery, GetAccountTransactionLogsByTransactionPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountTransactionLogsByTransactionPkQuery, GetAccountTransactionLogsByTransactionPkQueryVariables>(GetAccountTransactionLogsByTransactionPkDocument, options);
      }
export function useGetAccountTransactionLogsByTransactionPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountTransactionLogsByTransactionPkQuery, GetAccountTransactionLogsByTransactionPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountTransactionLogsByTransactionPkQuery, GetAccountTransactionLogsByTransactionPkQueryVariables>(GetAccountTransactionLogsByTransactionPkDocument, options);
        }
export type GetAccountTransactionLogsByTransactionPkQueryHookResult = ReturnType<typeof useGetAccountTransactionLogsByTransactionPkQuery>;
export type GetAccountTransactionLogsByTransactionPkLazyQueryHookResult = ReturnType<typeof useGetAccountTransactionLogsByTransactionPkLazyQuery>;
export type GetAccountTransactionLogsByTransactionPkQueryResult = Apollo.QueryResult<GetAccountTransactionLogsByTransactionPkQuery, GetAccountTransactionLogsByTransactionPkQueryVariables>;
export const GetImportLogsDocument = gql`
    query GetImportLogs($distinctOn: [import_log_select_column!], $limit: Int, $offset: Int, $orderBy: [import_log_order_by!], $where: import_log_bool_exp) {
  rows: import_log(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...ImportLog
  }
}
    ${ImportLogFragmentDoc}`;
export type GetImportLogsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetImportLogsQuery, GetImportLogsQueryVariables>, 'query'>;

    export const GetImportLogsComponent = (props: GetImportLogsComponentProps) => (
      <ApolloReactComponents.Query<GetImportLogsQuery, GetImportLogsQueryVariables> query={GetImportLogsDocument} {...props} />
    );
    

/**
 * __useGetImportLogsQuery__
 *
 * To run a query within a React component, call `useGetImportLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImportLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImportLogsQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetImportLogsQuery(baseOptions?: Apollo.QueryHookOptions<GetImportLogsQuery, GetImportLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetImportLogsQuery, GetImportLogsQueryVariables>(GetImportLogsDocument, options);
      }
export function useGetImportLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetImportLogsQuery, GetImportLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetImportLogsQuery, GetImportLogsQueryVariables>(GetImportLogsDocument, options);
        }
export type GetImportLogsQueryHookResult = ReturnType<typeof useGetImportLogsQuery>;
export type GetImportLogsLazyQueryHookResult = ReturnType<typeof useGetImportLogsLazyQuery>;
export type GetImportLogsQueryResult = Apollo.QueryResult<GetImportLogsQuery, GetImportLogsQueryVariables>;
export const UpdateJuristicByPkDocument = gql`
    mutation UpdateJuristicByPk($id: String!, $_set: juristic_set_input) {
  update_juristic_by_pk(pk_columns: {id: $id}, _set: $_set) {
    ...Juristic
  }
}
    ${JuristicFragmentDoc}`;
export type UpdateJuristicByPkMutationFn = Apollo.MutationFunction<UpdateJuristicByPkMutation, UpdateJuristicByPkMutationVariables>;
export type UpdateJuristicByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateJuristicByPkMutation, UpdateJuristicByPkMutationVariables>, 'mutation'>;

    export const UpdateJuristicByPkComponent = (props: UpdateJuristicByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateJuristicByPkMutation, UpdateJuristicByPkMutationVariables> mutation={UpdateJuristicByPkDocument} {...props} />
    );
    

/**
 * __useUpdateJuristicByPkMutation__
 *
 * To run a mutation, you first call `useUpdateJuristicByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJuristicByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJuristicByPkMutation, { data, loading, error }] = useUpdateJuristicByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateJuristicByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJuristicByPkMutation, UpdateJuristicByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJuristicByPkMutation, UpdateJuristicByPkMutationVariables>(UpdateJuristicByPkDocument, options);
      }
export type UpdateJuristicByPkMutationHookResult = ReturnType<typeof useUpdateJuristicByPkMutation>;
export type UpdateJuristicByPkMutationResult = Apollo.MutationResult<UpdateJuristicByPkMutation>;
export type UpdateJuristicByPkMutationOptions = Apollo.BaseMutationOptions<UpdateJuristicByPkMutation, UpdateJuristicByPkMutationVariables>;
export const GetJuristicsDocument = gql`
    query GetJuristics($distinctOn: [juristic_select_column!], $limit: Int, $offset: Int, $orderBy: [juristic_order_by!], $where: juristic_bool_exp) {
  rows: juristic(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...Juristic
  }
}
    ${JuristicFragmentDoc}`;
export type GetJuristicsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetJuristicsQuery, GetJuristicsQueryVariables>, 'query'>;

    export const GetJuristicsComponent = (props: GetJuristicsComponentProps) => (
      <ApolloReactComponents.Query<GetJuristicsQuery, GetJuristicsQueryVariables> query={GetJuristicsDocument} {...props} />
    );
    

/**
 * __useGetJuristicsQuery__
 *
 * To run a query within a React component, call `useGetJuristicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJuristicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJuristicsQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetJuristicsQuery(baseOptions?: Apollo.QueryHookOptions<GetJuristicsQuery, GetJuristicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJuristicsQuery, GetJuristicsQueryVariables>(GetJuristicsDocument, options);
      }
export function useGetJuristicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJuristicsQuery, GetJuristicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJuristicsQuery, GetJuristicsQueryVariables>(GetJuristicsDocument, options);
        }
export type GetJuristicsQueryHookResult = ReturnType<typeof useGetJuristicsQuery>;
export type GetJuristicsLazyQueryHookResult = ReturnType<typeof useGetJuristicsLazyQuery>;
export type GetJuristicsQueryResult = Apollo.QueryResult<GetJuristicsQuery, GetJuristicsQueryVariables>;
export const GetJuristicTypeSummaryDocument = gql`
    query GetJuristicTypeSummary {
  total_count: juristic_aggregate {
    aggregate {
      count
    }
  }
  incorporated_count: juristic_aggregate(
    where: {type: {_eq: "ห้างหุ้นส่วนสามัญ"}}
  ) {
    aggregate {
      count
    }
  }
  partnership_limited_count: juristic_aggregate(
    where: {type: {_eq: "ห้างหุ้นส่วน"}}
  ) {
    aggregate {
      count
    }
  }
  company_limited_count: juristic_aggregate(where: {type: {_eq: "บริษัทจำกัด"}}) {
    aggregate {
      count
    }
  }
  public_company_limited_count: juristic_aggregate(
    where: {type: {_eq: "บริษัทจำกัดมหาชน"}}
  ) {
    aggregate {
      count
    }
  }
  undefined_count: juristic_aggregate(
    where: {type: {_nin: ["ห้างหุ้นส่วนสามัญ", "ห้างหุ้นส่วน", "บริษัทจำกัด", "บริษัทจำกัดมหาชน"]}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export type GetJuristicTypeSummaryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetJuristicTypeSummaryQuery, GetJuristicTypeSummaryQueryVariables>, 'query'>;

    export const GetJuristicTypeSummaryComponent = (props: GetJuristicTypeSummaryComponentProps) => (
      <ApolloReactComponents.Query<GetJuristicTypeSummaryQuery, GetJuristicTypeSummaryQueryVariables> query={GetJuristicTypeSummaryDocument} {...props} />
    );
    

/**
 * __useGetJuristicTypeSummaryQuery__
 *
 * To run a query within a React component, call `useGetJuristicTypeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJuristicTypeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJuristicTypeSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetJuristicTypeSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetJuristicTypeSummaryQuery, GetJuristicTypeSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJuristicTypeSummaryQuery, GetJuristicTypeSummaryQueryVariables>(GetJuristicTypeSummaryDocument, options);
      }
export function useGetJuristicTypeSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJuristicTypeSummaryQuery, GetJuristicTypeSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJuristicTypeSummaryQuery, GetJuristicTypeSummaryQueryVariables>(GetJuristicTypeSummaryDocument, options);
        }
export type GetJuristicTypeSummaryQueryHookResult = ReturnType<typeof useGetJuristicTypeSummaryQuery>;
export type GetJuristicTypeSummaryLazyQueryHookResult = ReturnType<typeof useGetJuristicTypeSummaryLazyQuery>;
export type GetJuristicTypeSummaryQueryResult = Apollo.QueryResult<GetJuristicTypeSummaryQuery, GetJuristicTypeSummaryQueryVariables>;
export const GetJuristicByPkDocument = gql`
    query GetJuristicByPk($id: String!) {
  entity: juristic_by_pk(id: $id) {
    ...Juristic
    juristic_addresses {
      ...JuristicAddress
    }
    juristic_committees {
      ...JuristicCommittee
    }
  }
}
    ${JuristicFragmentDoc}
${JuristicAddressFragmentDoc}
${JuristicCommitteeFragmentDoc}`;
export type GetJuristicByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetJuristicByPkQuery, GetJuristicByPkQueryVariables>, 'query'> & ({ variables: GetJuristicByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetJuristicByPkComponent = (props: GetJuristicByPkComponentProps) => (
      <ApolloReactComponents.Query<GetJuristicByPkQuery, GetJuristicByPkQueryVariables> query={GetJuristicByPkDocument} {...props} />
    );
    

/**
 * __useGetJuristicByPkQuery__
 *
 * To run a query within a React component, call `useGetJuristicByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJuristicByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJuristicByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJuristicByPkQuery(baseOptions: Apollo.QueryHookOptions<GetJuristicByPkQuery, GetJuristicByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJuristicByPkQuery, GetJuristicByPkQueryVariables>(GetJuristicByPkDocument, options);
      }
export function useGetJuristicByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJuristicByPkQuery, GetJuristicByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJuristicByPkQuery, GetJuristicByPkQueryVariables>(GetJuristicByPkDocument, options);
        }
export type GetJuristicByPkQueryHookResult = ReturnType<typeof useGetJuristicByPkQuery>;
export type GetJuristicByPkLazyQueryHookResult = ReturnType<typeof useGetJuristicByPkLazyQuery>;
export type GetJuristicByPkQueryResult = Apollo.QueryResult<GetJuristicByPkQuery, GetJuristicByPkQueryVariables>;
export const GetJuristicCommitteesDocument = gql`
    query GetJuristicCommittees($distinctOn: [juristic_committee_select_column!], $limit: Int, $offset: Int, $orderBy: [juristic_committee_order_by!], $where: juristic_committee_bool_exp) {
  rows: juristic_committee(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...JuristicCommittee
  }
}
    ${JuristicCommitteeFragmentDoc}`;
export type GetJuristicCommitteesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetJuristicCommitteesQuery, GetJuristicCommitteesQueryVariables>, 'query'>;

    export const GetJuristicCommitteesComponent = (props: GetJuristicCommitteesComponentProps) => (
      <ApolloReactComponents.Query<GetJuristicCommitteesQuery, GetJuristicCommitteesQueryVariables> query={GetJuristicCommitteesDocument} {...props} />
    );
    

/**
 * __useGetJuristicCommitteesQuery__
 *
 * To run a query within a React component, call `useGetJuristicCommitteesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJuristicCommitteesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJuristicCommitteesQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetJuristicCommitteesQuery(baseOptions?: Apollo.QueryHookOptions<GetJuristicCommitteesQuery, GetJuristicCommitteesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJuristicCommitteesQuery, GetJuristicCommitteesQueryVariables>(GetJuristicCommitteesDocument, options);
      }
export function useGetJuristicCommitteesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJuristicCommitteesQuery, GetJuristicCommitteesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJuristicCommitteesQuery, GetJuristicCommitteesQueryVariables>(GetJuristicCommitteesDocument, options);
        }
export type GetJuristicCommitteesQueryHookResult = ReturnType<typeof useGetJuristicCommitteesQuery>;
export type GetJuristicCommitteesLazyQueryHookResult = ReturnType<typeof useGetJuristicCommitteesLazyQuery>;
export type GetJuristicCommitteesQueryResult = Apollo.QueryResult<GetJuristicCommitteesQuery, GetJuristicCommitteesQueryVariables>;
export const GetJuristicAddressesDocument = gql`
    query GetJuristicAddresses($distinctOn: [juristic_address_select_column!], $limit: Int, $offset: Int, $orderBy: [juristic_address_order_by!], $where: juristic_address_bool_exp) {
  rows: juristic_address(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...JuristicAddress
  }
}
    ${JuristicAddressFragmentDoc}`;
export type GetJuristicAddressesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetJuristicAddressesQuery, GetJuristicAddressesQueryVariables>, 'query'>;

    export const GetJuristicAddressesComponent = (props: GetJuristicAddressesComponentProps) => (
      <ApolloReactComponents.Query<GetJuristicAddressesQuery, GetJuristicAddressesQueryVariables> query={GetJuristicAddressesDocument} {...props} />
    );
    

/**
 * __useGetJuristicAddressesQuery__
 *
 * To run a query within a React component, call `useGetJuristicAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJuristicAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJuristicAddressesQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetJuristicAddressesQuery(baseOptions?: Apollo.QueryHookOptions<GetJuristicAddressesQuery, GetJuristicAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJuristicAddressesQuery, GetJuristicAddressesQueryVariables>(GetJuristicAddressesDocument, options);
      }
export function useGetJuristicAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJuristicAddressesQuery, GetJuristicAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJuristicAddressesQuery, GetJuristicAddressesQueryVariables>(GetJuristicAddressesDocument, options);
        }
export type GetJuristicAddressesQueryHookResult = ReturnType<typeof useGetJuristicAddressesQuery>;
export type GetJuristicAddressesLazyQueryHookResult = ReturnType<typeof useGetJuristicAddressesLazyQuery>;
export type GetJuristicAddressesQueryResult = Apollo.QueryResult<GetJuristicAddressesQuery, GetJuristicAddressesQueryVariables>;
export const GetLedgerBudgetAllocationsDocument = gql`
    query GetLedgerBudgetAllocations($fiscalYear: Int!) {
  organizations: organization(order_by: {reference: asc}) {
    ...Organization
  }
  budgets: account_budget_type(
    where: {fiscal_year: {_eq: $fiscalYear}}
    order_by: {sequence: asc}
  ) {
    id
    description
    sequence
    fiscal_year
    closed
    closed_at
    allocations {
      id
      organization_id
      amount
      budget_id
      fiscal_year
    }
  }
}
    ${OrganizationFragmentDoc}`;
export type GetLedgerBudgetAllocationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLedgerBudgetAllocationsQuery, GetLedgerBudgetAllocationsQueryVariables>, 'query'> & ({ variables: GetLedgerBudgetAllocationsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLedgerBudgetAllocationsComponent = (props: GetLedgerBudgetAllocationsComponentProps) => (
      <ApolloReactComponents.Query<GetLedgerBudgetAllocationsQuery, GetLedgerBudgetAllocationsQueryVariables> query={GetLedgerBudgetAllocationsDocument} {...props} />
    );
    

/**
 * __useGetLedgerBudgetAllocationsQuery__
 *
 * To run a query within a React component, call `useGetLedgerBudgetAllocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLedgerBudgetAllocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLedgerBudgetAllocationsQuery({
 *   variables: {
 *      fiscalYear: // value for 'fiscalYear'
 *   },
 * });
 */
export function useGetLedgerBudgetAllocationsQuery(baseOptions: Apollo.QueryHookOptions<GetLedgerBudgetAllocationsQuery, GetLedgerBudgetAllocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLedgerBudgetAllocationsQuery, GetLedgerBudgetAllocationsQueryVariables>(GetLedgerBudgetAllocationsDocument, options);
      }
export function useGetLedgerBudgetAllocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLedgerBudgetAllocationsQuery, GetLedgerBudgetAllocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLedgerBudgetAllocationsQuery, GetLedgerBudgetAllocationsQueryVariables>(GetLedgerBudgetAllocationsDocument, options);
        }
export type GetLedgerBudgetAllocationsQueryHookResult = ReturnType<typeof useGetLedgerBudgetAllocationsQuery>;
export type GetLedgerBudgetAllocationsLazyQueryHookResult = ReturnType<typeof useGetLedgerBudgetAllocationsLazyQuery>;
export type GetLedgerBudgetAllocationsQueryResult = Apollo.QueryResult<GetLedgerBudgetAllocationsQuery, GetLedgerBudgetAllocationsQueryVariables>;
export const InsertLedgerBudgetAllocationsDocument = gql`
    mutation InsertLedgerBudgetAllocations($objects: [ledger_budget_allocation_insert_input!]!) {
  budget_allocations: insert_ledger_budget_allocation(
    objects: $objects
    on_conflict: {constraint: budget_allocation_budget_id_organization_id_fiscal_year_key, update_columns: [amount]}
  ) {
    affected_rows
  }
}
    `;
export type InsertLedgerBudgetAllocationsMutationFn = Apollo.MutationFunction<InsertLedgerBudgetAllocationsMutation, InsertLedgerBudgetAllocationsMutationVariables>;
export type InsertLedgerBudgetAllocationsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertLedgerBudgetAllocationsMutation, InsertLedgerBudgetAllocationsMutationVariables>, 'mutation'>;

    export const InsertLedgerBudgetAllocationsComponent = (props: InsertLedgerBudgetAllocationsComponentProps) => (
      <ApolloReactComponents.Mutation<InsertLedgerBudgetAllocationsMutation, InsertLedgerBudgetAllocationsMutationVariables> mutation={InsertLedgerBudgetAllocationsDocument} {...props} />
    );
    

/**
 * __useInsertLedgerBudgetAllocationsMutation__
 *
 * To run a mutation, you first call `useInsertLedgerBudgetAllocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLedgerBudgetAllocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLedgerBudgetAllocationsMutation, { data, loading, error }] = useInsertLedgerBudgetAllocationsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertLedgerBudgetAllocationsMutation(baseOptions?: Apollo.MutationHookOptions<InsertLedgerBudgetAllocationsMutation, InsertLedgerBudgetAllocationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLedgerBudgetAllocationsMutation, InsertLedgerBudgetAllocationsMutationVariables>(InsertLedgerBudgetAllocationsDocument, options);
      }
export type InsertLedgerBudgetAllocationsMutationHookResult = ReturnType<typeof useInsertLedgerBudgetAllocationsMutation>;
export type InsertLedgerBudgetAllocationsMutationResult = Apollo.MutationResult<InsertLedgerBudgetAllocationsMutation>;
export type InsertLedgerBudgetAllocationsMutationOptions = Apollo.BaseMutationOptions<InsertLedgerBudgetAllocationsMutation, InsertLedgerBudgetAllocationsMutationVariables>;
export const InsertLedgerBudgetAllocationOneDocument = gql`
    mutation InsertLedgerBudgetAllocationOne($object: ledger_budget_allocation_insert_input!) {
  budget_allocations: insert_ledger_budget_allocation_one(
    object: $object
    on_conflict: {constraint: budget_allocation_budget_id_organization_id_fiscal_year_key, update_columns: [amount]}
  ) {
    ...BudgetAllocation
  }
}
    ${BudgetAllocationFragmentDoc}`;
export type InsertLedgerBudgetAllocationOneMutationFn = Apollo.MutationFunction<InsertLedgerBudgetAllocationOneMutation, InsertLedgerBudgetAllocationOneMutationVariables>;
export type InsertLedgerBudgetAllocationOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertLedgerBudgetAllocationOneMutation, InsertLedgerBudgetAllocationOneMutationVariables>, 'mutation'>;

    export const InsertLedgerBudgetAllocationOneComponent = (props: InsertLedgerBudgetAllocationOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertLedgerBudgetAllocationOneMutation, InsertLedgerBudgetAllocationOneMutationVariables> mutation={InsertLedgerBudgetAllocationOneDocument} {...props} />
    );
    

/**
 * __useInsertLedgerBudgetAllocationOneMutation__
 *
 * To run a mutation, you first call `useInsertLedgerBudgetAllocationOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLedgerBudgetAllocationOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLedgerBudgetAllocationOneMutation, { data, loading, error }] = useInsertLedgerBudgetAllocationOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertLedgerBudgetAllocationOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertLedgerBudgetAllocationOneMutation, InsertLedgerBudgetAllocationOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLedgerBudgetAllocationOneMutation, InsertLedgerBudgetAllocationOneMutationVariables>(InsertLedgerBudgetAllocationOneDocument, options);
      }
export type InsertLedgerBudgetAllocationOneMutationHookResult = ReturnType<typeof useInsertLedgerBudgetAllocationOneMutation>;
export type InsertLedgerBudgetAllocationOneMutationResult = Apollo.MutationResult<InsertLedgerBudgetAllocationOneMutation>;
export type InsertLedgerBudgetAllocationOneMutationOptions = Apollo.BaseMutationOptions<InsertLedgerBudgetAllocationOneMutation, InsertLedgerBudgetAllocationOneMutationVariables>;
export const UpdateLedgerBudgetAllocationByPkDocument = gql`
    mutation UpdateLedgerBudgetAllocationByPk($id: uuid!) {
  budget_allocation: update_ledger_budget_allocation_by_pk(pk_columns: {id: $id}) {
    ...BudgetAllocation
  }
}
    ${BudgetAllocationFragmentDoc}`;
export type UpdateLedgerBudgetAllocationByPkMutationFn = Apollo.MutationFunction<UpdateLedgerBudgetAllocationByPkMutation, UpdateLedgerBudgetAllocationByPkMutationVariables>;
export type UpdateLedgerBudgetAllocationByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateLedgerBudgetAllocationByPkMutation, UpdateLedgerBudgetAllocationByPkMutationVariables>, 'mutation'>;

    export const UpdateLedgerBudgetAllocationByPkComponent = (props: UpdateLedgerBudgetAllocationByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateLedgerBudgetAllocationByPkMutation, UpdateLedgerBudgetAllocationByPkMutationVariables> mutation={UpdateLedgerBudgetAllocationByPkDocument} {...props} />
    );
    

/**
 * __useUpdateLedgerBudgetAllocationByPkMutation__
 *
 * To run a mutation, you first call `useUpdateLedgerBudgetAllocationByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLedgerBudgetAllocationByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLedgerBudgetAllocationByPkMutation, { data, loading, error }] = useUpdateLedgerBudgetAllocationByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateLedgerBudgetAllocationByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLedgerBudgetAllocationByPkMutation, UpdateLedgerBudgetAllocationByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLedgerBudgetAllocationByPkMutation, UpdateLedgerBudgetAllocationByPkMutationVariables>(UpdateLedgerBudgetAllocationByPkDocument, options);
      }
export type UpdateLedgerBudgetAllocationByPkMutationHookResult = ReturnType<typeof useUpdateLedgerBudgetAllocationByPkMutation>;
export type UpdateLedgerBudgetAllocationByPkMutationResult = Apollo.MutationResult<UpdateLedgerBudgetAllocationByPkMutation>;
export type UpdateLedgerBudgetAllocationByPkMutationOptions = Apollo.BaseMutationOptions<UpdateLedgerBudgetAllocationByPkMutation, UpdateLedgerBudgetAllocationByPkMutationVariables>;
export const GetLedgerMatrixReportByOrganizationIdDocument = gql`
    query GetLedgerMatrixReportByOrganizationId($fiscalYear: Int!, $organizationId: uuid) {
  budget_rows: account_budget_type(
    where: {fiscal_year: {_eq: $fiscalYear}}
    order_by: {sequence: asc}
  ) {
    id
    description
    sequence
    deleted_at
    fiscal_year
    account_id
    group_id
    group_name
    allocations(
      where: {fiscal_year: {_eq: $fiscalYear}, organization_id: {_eq: $organizationId}}
    ) {
      id
      budget_id
      organization_id
      amount
      fiscal_year
    }
  }
  account_rows: account_account(
    where: {deleted_at: {_is_null: true}}
    order_by: {code: asc}
  ) {
    id
    code
    name
    account_type {
      id
      description
    }
  }
  debt_account_budget_balance_rows: ledger_account_budget_balance_debt(
    where: {fiscal_year: {_eq: $fiscalYear}, organization_id: {_eq: $organizationId}}
  ) {
    balance
    budget_id
    fiscal_year
    account_id
    organization_id
    budget_total
    budget_balance
    budget_group_id
    budget_group_name
  }
  expense_account_budget_balance_rows: ledger_account_budget_balance_expense(
    where: {fiscal_year: {_eq: $fiscalYear}, organization_id: {_eq: $organizationId}}
  ) {
    balance
    budget_id
    fiscal_year
    account_id
    organization_id
    budget_total
    budget_balance
    budget_group_id
    budget_group_name
  }
}
    `;
export type GetLedgerMatrixReportByOrganizationIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLedgerMatrixReportByOrganizationIdQuery, GetLedgerMatrixReportByOrganizationIdQueryVariables>, 'query'> & ({ variables: GetLedgerMatrixReportByOrganizationIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLedgerMatrixReportByOrganizationIdComponent = (props: GetLedgerMatrixReportByOrganizationIdComponentProps) => (
      <ApolloReactComponents.Query<GetLedgerMatrixReportByOrganizationIdQuery, GetLedgerMatrixReportByOrganizationIdQueryVariables> query={GetLedgerMatrixReportByOrganizationIdDocument} {...props} />
    );
    

/**
 * __useGetLedgerMatrixReportByOrganizationIdQuery__
 *
 * To run a query within a React component, call `useGetLedgerMatrixReportByOrganizationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLedgerMatrixReportByOrganizationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLedgerMatrixReportByOrganizationIdQuery({
 *   variables: {
 *      fiscalYear: // value for 'fiscalYear'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetLedgerMatrixReportByOrganizationIdQuery(baseOptions: Apollo.QueryHookOptions<GetLedgerMatrixReportByOrganizationIdQuery, GetLedgerMatrixReportByOrganizationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLedgerMatrixReportByOrganizationIdQuery, GetLedgerMatrixReportByOrganizationIdQueryVariables>(GetLedgerMatrixReportByOrganizationIdDocument, options);
      }
export function useGetLedgerMatrixReportByOrganizationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLedgerMatrixReportByOrganizationIdQuery, GetLedgerMatrixReportByOrganizationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLedgerMatrixReportByOrganizationIdQuery, GetLedgerMatrixReportByOrganizationIdQueryVariables>(GetLedgerMatrixReportByOrganizationIdDocument, options);
        }
export type GetLedgerMatrixReportByOrganizationIdQueryHookResult = ReturnType<typeof useGetLedgerMatrixReportByOrganizationIdQuery>;
export type GetLedgerMatrixReportByOrganizationIdLazyQueryHookResult = ReturnType<typeof useGetLedgerMatrixReportByOrganizationIdLazyQuery>;
export type GetLedgerMatrixReportByOrganizationIdQueryResult = Apollo.QueryResult<GetLedgerMatrixReportByOrganizationIdQuery, GetLedgerMatrixReportByOrganizationIdQueryVariables>;
export const GetLedgerMonthlyReportByOrganizationIdDocument = gql`
    query GetLedgerMonthlyReportByOrganizationId($fiscalYear: Int!, $organizationId: uuid) {
  budget_rows: account_budget_type(
    where: {fiscal_year: {_eq: $fiscalYear}}
    order_by: {sequence: asc}
  ) {
    id
    description
    sequence
    deleted_at
    fiscal_year
    account_id
  }
  account_rows: account_account(
    where: {deleted_at: {_is_null: true}}
    order_by: {code: asc}
  ) {
    id
    code
    name
    account_type {
      id
      description
    }
  }
  ledger_account_budget_monthly_rows: ledger_account_budget_monthly(
    where: {fiscal_year: {_eq: $fiscalYear}, organization_id: {_eq: $organizationId}}
  ) {
    october
    november
    december
    january
    february
    march
    april
    may
    june
    july
    august
    september
    budget_id
    fiscal_year
    id
    name
    organization_id
  }
}
    `;
export type GetLedgerMonthlyReportByOrganizationIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLedgerMonthlyReportByOrganizationIdQuery, GetLedgerMonthlyReportByOrganizationIdQueryVariables>, 'query'> & ({ variables: GetLedgerMonthlyReportByOrganizationIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLedgerMonthlyReportByOrganizationIdComponent = (props: GetLedgerMonthlyReportByOrganizationIdComponentProps) => (
      <ApolloReactComponents.Query<GetLedgerMonthlyReportByOrganizationIdQuery, GetLedgerMonthlyReportByOrganizationIdQueryVariables> query={GetLedgerMonthlyReportByOrganizationIdDocument} {...props} />
    );
    

/**
 * __useGetLedgerMonthlyReportByOrganizationIdQuery__
 *
 * To run a query within a React component, call `useGetLedgerMonthlyReportByOrganizationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLedgerMonthlyReportByOrganizationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLedgerMonthlyReportByOrganizationIdQuery({
 *   variables: {
 *      fiscalYear: // value for 'fiscalYear'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetLedgerMonthlyReportByOrganizationIdQuery(baseOptions: Apollo.QueryHookOptions<GetLedgerMonthlyReportByOrganizationIdQuery, GetLedgerMonthlyReportByOrganizationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLedgerMonthlyReportByOrganizationIdQuery, GetLedgerMonthlyReportByOrganizationIdQueryVariables>(GetLedgerMonthlyReportByOrganizationIdDocument, options);
      }
export function useGetLedgerMonthlyReportByOrganizationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLedgerMonthlyReportByOrganizationIdQuery, GetLedgerMonthlyReportByOrganizationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLedgerMonthlyReportByOrganizationIdQuery, GetLedgerMonthlyReportByOrganizationIdQueryVariables>(GetLedgerMonthlyReportByOrganizationIdDocument, options);
        }
export type GetLedgerMonthlyReportByOrganizationIdQueryHookResult = ReturnType<typeof useGetLedgerMonthlyReportByOrganizationIdQuery>;
export type GetLedgerMonthlyReportByOrganizationIdLazyQueryHookResult = ReturnType<typeof useGetLedgerMonthlyReportByOrganizationIdLazyQuery>;
export type GetLedgerMonthlyReportByOrganizationIdQueryResult = Apollo.QueryResult<GetLedgerMonthlyReportByOrganizationIdQuery, GetLedgerMonthlyReportByOrganizationIdQueryVariables>;
export const GetReceivableItemsDocument = gql`
    query GetReceivableItems($distinctOn: [ledger_receivable_item_select_column!], $limit: Int, $offset: Int, $orderBy: [ledger_receivable_item_order_by!], $where: ledger_receivable_item_bool_exp) {
  rows: ledger_receivable_item(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...ReceivableItem
  }
}
    ${ReceivableItemFragmentDoc}`;
export type GetReceivableItemsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetReceivableItemsQuery, GetReceivableItemsQueryVariables>, 'query'>;

    export const GetReceivableItemsComponent = (props: GetReceivableItemsComponentProps) => (
      <ApolloReactComponents.Query<GetReceivableItemsQuery, GetReceivableItemsQueryVariables> query={GetReceivableItemsDocument} {...props} />
    );
    

/**
 * __useGetReceivableItemsQuery__
 *
 * To run a query within a React component, call `useGetReceivableItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceivableItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceivableItemsQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetReceivableItemsQuery(baseOptions?: Apollo.QueryHookOptions<GetReceivableItemsQuery, GetReceivableItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReceivableItemsQuery, GetReceivableItemsQueryVariables>(GetReceivableItemsDocument, options);
      }
export function useGetReceivableItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReceivableItemsQuery, GetReceivableItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReceivableItemsQuery, GetReceivableItemsQueryVariables>(GetReceivableItemsDocument, options);
        }
export type GetReceivableItemsQueryHookResult = ReturnType<typeof useGetReceivableItemsQuery>;
export type GetReceivableItemsLazyQueryHookResult = ReturnType<typeof useGetReceivableItemsLazyQuery>;
export type GetReceivableItemsQueryResult = Apollo.QueryResult<GetReceivableItemsQuery, GetReceivableItemsQueryVariables>;
export const GetReceivableItemByPkDocument = gql`
    query GetReceivableItemByPk($id: uuid!) {
  row: ledger_receivable_item_by_pk(id: $id) {
    ...ReceivableItem
  }
}
    ${ReceivableItemFragmentDoc}`;
export type GetReceivableItemByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetReceivableItemByPkQuery, GetReceivableItemByPkQueryVariables>, 'query'> & ({ variables: GetReceivableItemByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetReceivableItemByPkComponent = (props: GetReceivableItemByPkComponentProps) => (
      <ApolloReactComponents.Query<GetReceivableItemByPkQuery, GetReceivableItemByPkQueryVariables> query={GetReceivableItemByPkDocument} {...props} />
    );
    

/**
 * __useGetReceivableItemByPkQuery__
 *
 * To run a query within a React component, call `useGetReceivableItemByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceivableItemByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceivableItemByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReceivableItemByPkQuery(baseOptions: Apollo.QueryHookOptions<GetReceivableItemByPkQuery, GetReceivableItemByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReceivableItemByPkQuery, GetReceivableItemByPkQueryVariables>(GetReceivableItemByPkDocument, options);
      }
export function useGetReceivableItemByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReceivableItemByPkQuery, GetReceivableItemByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReceivableItemByPkQuery, GetReceivableItemByPkQueryVariables>(GetReceivableItemByPkDocument, options);
        }
export type GetReceivableItemByPkQueryHookResult = ReturnType<typeof useGetReceivableItemByPkQuery>;
export type GetReceivableItemByPkLazyQueryHookResult = ReturnType<typeof useGetReceivableItemByPkLazyQuery>;
export type GetReceivableItemByPkQueryResult = Apollo.QueryResult<GetReceivableItemByPkQuery, GetReceivableItemByPkQueryVariables>;
export const InsertReceivableItemOneDocument = gql`
    mutation InsertReceivableItemOne($object: ledger_receivable_item_insert_input!) {
  insert_ledger_receivable_item_one(object: $object) {
    ...ReceivableItem
  }
}
    ${ReceivableItemFragmentDoc}`;
export type InsertReceivableItemOneMutationFn = Apollo.MutationFunction<InsertReceivableItemOneMutation, InsertReceivableItemOneMutationVariables>;
export type InsertReceivableItemOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertReceivableItemOneMutation, InsertReceivableItemOneMutationVariables>, 'mutation'>;

    export const InsertReceivableItemOneComponent = (props: InsertReceivableItemOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertReceivableItemOneMutation, InsertReceivableItemOneMutationVariables> mutation={InsertReceivableItemOneDocument} {...props} />
    );
    

/**
 * __useInsertReceivableItemOneMutation__
 *
 * To run a mutation, you first call `useInsertReceivableItemOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertReceivableItemOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertReceivableItemOneMutation, { data, loading, error }] = useInsertReceivableItemOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertReceivableItemOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertReceivableItemOneMutation, InsertReceivableItemOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertReceivableItemOneMutation, InsertReceivableItemOneMutationVariables>(InsertReceivableItemOneDocument, options);
      }
export type InsertReceivableItemOneMutationHookResult = ReturnType<typeof useInsertReceivableItemOneMutation>;
export type InsertReceivableItemOneMutationResult = Apollo.MutationResult<InsertReceivableItemOneMutation>;
export type InsertReceivableItemOneMutationOptions = Apollo.BaseMutationOptions<InsertReceivableItemOneMutation, InsertReceivableItemOneMutationVariables>;
export const UpdateReceivableItemByPkDocument = gql`
    mutation UpdateReceivableItemByPk($_set: ledger_receivable_item_set_input, $id: uuid!) {
  update_ledger_receivable_item_by_pk(pk_columns: {id: $id}, _set: $_set) {
    ...ReceivableItem
  }
}
    ${ReceivableItemFragmentDoc}`;
export type UpdateReceivableItemByPkMutationFn = Apollo.MutationFunction<UpdateReceivableItemByPkMutation, UpdateReceivableItemByPkMutationVariables>;
export type UpdateReceivableItemByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateReceivableItemByPkMutation, UpdateReceivableItemByPkMutationVariables>, 'mutation'>;

    export const UpdateReceivableItemByPkComponent = (props: UpdateReceivableItemByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateReceivableItemByPkMutation, UpdateReceivableItemByPkMutationVariables> mutation={UpdateReceivableItemByPkDocument} {...props} />
    );
    

/**
 * __useUpdateReceivableItemByPkMutation__
 *
 * To run a mutation, you first call `useUpdateReceivableItemByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReceivableItemByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReceivableItemByPkMutation, { data, loading, error }] = useUpdateReceivableItemByPkMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateReceivableItemByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReceivableItemByPkMutation, UpdateReceivableItemByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReceivableItemByPkMutation, UpdateReceivableItemByPkMutationVariables>(UpdateReceivableItemByPkDocument, options);
      }
export type UpdateReceivableItemByPkMutationHookResult = ReturnType<typeof useUpdateReceivableItemByPkMutation>;
export type UpdateReceivableItemByPkMutationResult = Apollo.MutationResult<UpdateReceivableItemByPkMutation>;
export type UpdateReceivableItemByPkMutationOptions = Apollo.BaseMutationOptions<UpdateReceivableItemByPkMutation, UpdateReceivableItemByPkMutationVariables>;
export const DeleteReceivableItemByPkDocument = gql`
    mutation DeleteReceivableItemByPk($id: uuid!) {
  delete_ledger_receivable_item_by_pk(id: $id) {
    ...ReceivableItem
  }
}
    ${ReceivableItemFragmentDoc}`;
export type DeleteReceivableItemByPkMutationFn = Apollo.MutationFunction<DeleteReceivableItemByPkMutation, DeleteReceivableItemByPkMutationVariables>;
export type DeleteReceivableItemByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteReceivableItemByPkMutation, DeleteReceivableItemByPkMutationVariables>, 'mutation'>;

    export const DeleteReceivableItemByPkComponent = (props: DeleteReceivableItemByPkComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteReceivableItemByPkMutation, DeleteReceivableItemByPkMutationVariables> mutation={DeleteReceivableItemByPkDocument} {...props} />
    );
    

/**
 * __useDeleteReceivableItemByPkMutation__
 *
 * To run a mutation, you first call `useDeleteReceivableItemByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReceivableItemByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReceivableItemByPkMutation, { data, loading, error }] = useDeleteReceivableItemByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReceivableItemByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReceivableItemByPkMutation, DeleteReceivableItemByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReceivableItemByPkMutation, DeleteReceivableItemByPkMutationVariables>(DeleteReceivableItemByPkDocument, options);
      }
export type DeleteReceivableItemByPkMutationHookResult = ReturnType<typeof useDeleteReceivableItemByPkMutation>;
export type DeleteReceivableItemByPkMutationResult = Apollo.MutationResult<DeleteReceivableItemByPkMutation>;
export type DeleteReceivableItemByPkMutationOptions = Apollo.BaseMutationOptions<DeleteReceivableItemByPkMutation, DeleteReceivableItemByPkMutationVariables>;
export const ReplaceReceivableItemsDocument = gql`
    mutation ReplaceReceivableItems($receivableId: uuid!, $itemIds: [uuid!]!, $insert_receivable_items: [ledger_receivable_item_insert_input!]!) {
  deleted_ledger_receivables: delete_ledger_receivable_item(
    where: {id: {_nin: $itemIds}, receivable_id: {_eq: $receivableId}}
  ) {
    affected_rows
  }
  ledger_receivables: insert_ledger_receivable_item(
    objects: $insert_receivable_items
    on_conflict: {constraint: receivable_item_pkey, update_columns: [amount, date, name, note, locked_at, locked_by]}
  ) {
    affected_rows
    returning {
      ...ReceivableItem
    }
  }
}
    ${ReceivableItemFragmentDoc}`;
export type ReplaceReceivableItemsMutationFn = Apollo.MutationFunction<ReplaceReceivableItemsMutation, ReplaceReceivableItemsMutationVariables>;
export type ReplaceReceivableItemsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReplaceReceivableItemsMutation, ReplaceReceivableItemsMutationVariables>, 'mutation'>;

    export const ReplaceReceivableItemsComponent = (props: ReplaceReceivableItemsComponentProps) => (
      <ApolloReactComponents.Mutation<ReplaceReceivableItemsMutation, ReplaceReceivableItemsMutationVariables> mutation={ReplaceReceivableItemsDocument} {...props} />
    );
    

/**
 * __useReplaceReceivableItemsMutation__
 *
 * To run a mutation, you first call `useReplaceReceivableItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceReceivableItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceReceivableItemsMutation, { data, loading, error }] = useReplaceReceivableItemsMutation({
 *   variables: {
 *      receivableId: // value for 'receivableId'
 *      itemIds: // value for 'itemIds'
 *      insert_receivable_items: // value for 'insert_receivable_items'
 *   },
 * });
 */
export function useReplaceReceivableItemsMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceReceivableItemsMutation, ReplaceReceivableItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplaceReceivableItemsMutation, ReplaceReceivableItemsMutationVariables>(ReplaceReceivableItemsDocument, options);
      }
export type ReplaceReceivableItemsMutationHookResult = ReturnType<typeof useReplaceReceivableItemsMutation>;
export type ReplaceReceivableItemsMutationResult = Apollo.MutationResult<ReplaceReceivableItemsMutation>;
export type ReplaceReceivableItemsMutationOptions = Apollo.BaseMutationOptions<ReplaceReceivableItemsMutation, ReplaceReceivableItemsMutationVariables>;
export const GetReceivablesDocument = gql`
    query GetReceivables($distinctOn: [ledger_receivable_select_column!], $limit: Int, $offset: Int, $orderBy: [ledger_receivable_order_by!], $where: ledger_receivable_bool_exp) {
  rows: ledger_receivable(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...Receivable
  }
}
    ${ReceivableFragmentDoc}`;
export type GetReceivablesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetReceivablesQuery, GetReceivablesQueryVariables>, 'query'>;

    export const GetReceivablesComponent = (props: GetReceivablesComponentProps) => (
      <ApolloReactComponents.Query<GetReceivablesQuery, GetReceivablesQueryVariables> query={GetReceivablesDocument} {...props} />
    );
    

/**
 * __useGetReceivablesQuery__
 *
 * To run a query within a React component, call `useGetReceivablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceivablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceivablesQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetReceivablesQuery(baseOptions?: Apollo.QueryHookOptions<GetReceivablesQuery, GetReceivablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReceivablesQuery, GetReceivablesQueryVariables>(GetReceivablesDocument, options);
      }
export function useGetReceivablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReceivablesQuery, GetReceivablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReceivablesQuery, GetReceivablesQueryVariables>(GetReceivablesDocument, options);
        }
export type GetReceivablesQueryHookResult = ReturnType<typeof useGetReceivablesQuery>;
export type GetReceivablesLazyQueryHookResult = ReturnType<typeof useGetReceivablesLazyQuery>;
export type GetReceivablesQueryResult = Apollo.QueryResult<GetReceivablesQuery, GetReceivablesQueryVariables>;
export const GetReceivableByPkDocument = gql`
    query GetReceivableByPk($id: uuid!) {
  row: ledger_receivable_by_pk(id: $id) {
    ...ReceivableWithItems
  }
  ledgers: ledger_ledger(
    where: {receivable_id: {_eq: $id}}
    order_by: {date: desc}
  ) {
    ...Ledger
  }
  logs: ledger_receivable_log(
    where: {receivable_id: {_eq: $id}}
    order_by: {created_at: desc}
  ) {
    ...ReceivableLog
  }
}
    ${ReceivableWithItemsFragmentDoc}
${LedgerFragmentDoc}
${ReceivableLogFragmentDoc}`;
export type GetReceivableByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetReceivableByPkQuery, GetReceivableByPkQueryVariables>, 'query'> & ({ variables: GetReceivableByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetReceivableByPkComponent = (props: GetReceivableByPkComponentProps) => (
      <ApolloReactComponents.Query<GetReceivableByPkQuery, GetReceivableByPkQueryVariables> query={GetReceivableByPkDocument} {...props} />
    );
    

/**
 * __useGetReceivableByPkQuery__
 *
 * To run a query within a React component, call `useGetReceivableByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceivableByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceivableByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReceivableByPkQuery(baseOptions: Apollo.QueryHookOptions<GetReceivableByPkQuery, GetReceivableByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReceivableByPkQuery, GetReceivableByPkQueryVariables>(GetReceivableByPkDocument, options);
      }
export function useGetReceivableByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReceivableByPkQuery, GetReceivableByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReceivableByPkQuery, GetReceivableByPkQueryVariables>(GetReceivableByPkDocument, options);
        }
export type GetReceivableByPkQueryHookResult = ReturnType<typeof useGetReceivableByPkQuery>;
export type GetReceivableByPkLazyQueryHookResult = ReturnType<typeof useGetReceivableByPkLazyQuery>;
export type GetReceivableByPkQueryResult = Apollo.QueryResult<GetReceivableByPkQuery, GetReceivableByPkQueryVariables>;
export const InsertReceivableOneDocument = gql`
    mutation InsertReceivableOne($object: ledger_receivable_insert_input!) {
  insert_ledger_receivable_one(object: $object) {
    ...Receivable
  }
}
    ${ReceivableFragmentDoc}`;
export type InsertReceivableOneMutationFn = Apollo.MutationFunction<InsertReceivableOneMutation, InsertReceivableOneMutationVariables>;
export type InsertReceivableOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertReceivableOneMutation, InsertReceivableOneMutationVariables>, 'mutation'>;

    export const InsertReceivableOneComponent = (props: InsertReceivableOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertReceivableOneMutation, InsertReceivableOneMutationVariables> mutation={InsertReceivableOneDocument} {...props} />
    );
    

/**
 * __useInsertReceivableOneMutation__
 *
 * To run a mutation, you first call `useInsertReceivableOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertReceivableOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertReceivableOneMutation, { data, loading, error }] = useInsertReceivableOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertReceivableOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertReceivableOneMutation, InsertReceivableOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertReceivableOneMutation, InsertReceivableOneMutationVariables>(InsertReceivableOneDocument, options);
      }
export type InsertReceivableOneMutationHookResult = ReturnType<typeof useInsertReceivableOneMutation>;
export type InsertReceivableOneMutationResult = Apollo.MutationResult<InsertReceivableOneMutation>;
export type InsertReceivableOneMutationOptions = Apollo.BaseMutationOptions<InsertReceivableOneMutation, InsertReceivableOneMutationVariables>;
export const UpdateReceivableByPkDocument = gql`
    mutation UpdateReceivableByPk($_set: ledger_receivable_set_input, $id: uuid!) {
  update_ledger_receivable_by_pk(pk_columns: {id: $id}, _set: $_set) {
    ...Receivable
  }
}
    ${ReceivableFragmentDoc}`;
export type UpdateReceivableByPkMutationFn = Apollo.MutationFunction<UpdateReceivableByPkMutation, UpdateReceivableByPkMutationVariables>;
export type UpdateReceivableByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateReceivableByPkMutation, UpdateReceivableByPkMutationVariables>, 'mutation'>;

    export const UpdateReceivableByPkComponent = (props: UpdateReceivableByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateReceivableByPkMutation, UpdateReceivableByPkMutationVariables> mutation={UpdateReceivableByPkDocument} {...props} />
    );
    

/**
 * __useUpdateReceivableByPkMutation__
 *
 * To run a mutation, you first call `useUpdateReceivableByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReceivableByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReceivableByPkMutation, { data, loading, error }] = useUpdateReceivableByPkMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateReceivableByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReceivableByPkMutation, UpdateReceivableByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReceivableByPkMutation, UpdateReceivableByPkMutationVariables>(UpdateReceivableByPkDocument, options);
      }
export type UpdateReceivableByPkMutationHookResult = ReturnType<typeof useUpdateReceivableByPkMutation>;
export type UpdateReceivableByPkMutationResult = Apollo.MutationResult<UpdateReceivableByPkMutation>;
export type UpdateReceivableByPkMutationOptions = Apollo.BaseMutationOptions<UpdateReceivableByPkMutation, UpdateReceivableByPkMutationVariables>;
export const SubmitReceivableByPkDocument = gql`
    mutation SubmitReceivableByPk($id: String!) {
  row: ledger_submit_receivable(data: {receivableId: $id}) {
    receivableId
  }
}
    `;
export type SubmitReceivableByPkMutationFn = Apollo.MutationFunction<SubmitReceivableByPkMutation, SubmitReceivableByPkMutationVariables>;
export type SubmitReceivableByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubmitReceivableByPkMutation, SubmitReceivableByPkMutationVariables>, 'mutation'>;

    export const SubmitReceivableByPkComponent = (props: SubmitReceivableByPkComponentProps) => (
      <ApolloReactComponents.Mutation<SubmitReceivableByPkMutation, SubmitReceivableByPkMutationVariables> mutation={SubmitReceivableByPkDocument} {...props} />
    );
    

/**
 * __useSubmitReceivableByPkMutation__
 *
 * To run a mutation, you first call `useSubmitReceivableByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitReceivableByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitReceivableByPkMutation, { data, loading, error }] = useSubmitReceivableByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubmitReceivableByPkMutation(baseOptions?: Apollo.MutationHookOptions<SubmitReceivableByPkMutation, SubmitReceivableByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitReceivableByPkMutation, SubmitReceivableByPkMutationVariables>(SubmitReceivableByPkDocument, options);
      }
export type SubmitReceivableByPkMutationHookResult = ReturnType<typeof useSubmitReceivableByPkMutation>;
export type SubmitReceivableByPkMutationResult = Apollo.MutationResult<SubmitReceivableByPkMutation>;
export type SubmitReceivableByPkMutationOptions = Apollo.BaseMutationOptions<SubmitReceivableByPkMutation, SubmitReceivableByPkMutationVariables>;
export const DoneReceivableByPkDocument = gql`
    mutation DoneReceivableByPk($data: ReceivableCloseInput!) {
  row: ledger_close_receivable(data: $data) {
    receivableId
  }
}
    `;
export type DoneReceivableByPkMutationFn = Apollo.MutationFunction<DoneReceivableByPkMutation, DoneReceivableByPkMutationVariables>;
export type DoneReceivableByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DoneReceivableByPkMutation, DoneReceivableByPkMutationVariables>, 'mutation'>;

    export const DoneReceivableByPkComponent = (props: DoneReceivableByPkComponentProps) => (
      <ApolloReactComponents.Mutation<DoneReceivableByPkMutation, DoneReceivableByPkMutationVariables> mutation={DoneReceivableByPkDocument} {...props} />
    );
    

/**
 * __useDoneReceivableByPkMutation__
 *
 * To run a mutation, you first call `useDoneReceivableByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDoneReceivableByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [doneReceivableByPkMutation, { data, loading, error }] = useDoneReceivableByPkMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDoneReceivableByPkMutation(baseOptions?: Apollo.MutationHookOptions<DoneReceivableByPkMutation, DoneReceivableByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DoneReceivableByPkMutation, DoneReceivableByPkMutationVariables>(DoneReceivableByPkDocument, options);
      }
export type DoneReceivableByPkMutationHookResult = ReturnType<typeof useDoneReceivableByPkMutation>;
export type DoneReceivableByPkMutationResult = Apollo.MutationResult<DoneReceivableByPkMutation>;
export type DoneReceivableByPkMutationOptions = Apollo.BaseMutationOptions<DoneReceivableByPkMutation, DoneReceivableByPkMutationVariables>;
export const DeleteReceivableByPkDocument = gql`
    mutation DeleteReceivableByPk($data: ReceivableDeleteInput!) {
  row: ledger_delete_receivable(data: $data) {
    receivableId
  }
}
    `;
export type DeleteReceivableByPkMutationFn = Apollo.MutationFunction<DeleteReceivableByPkMutation, DeleteReceivableByPkMutationVariables>;
export type DeleteReceivableByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteReceivableByPkMutation, DeleteReceivableByPkMutationVariables>, 'mutation'>;

    export const DeleteReceivableByPkComponent = (props: DeleteReceivableByPkComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteReceivableByPkMutation, DeleteReceivableByPkMutationVariables> mutation={DeleteReceivableByPkDocument} {...props} />
    );
    

/**
 * __useDeleteReceivableByPkMutation__
 *
 * To run a mutation, you first call `useDeleteReceivableByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReceivableByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReceivableByPkMutation, { data, loading, error }] = useDeleteReceivableByPkMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteReceivableByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReceivableByPkMutation, DeleteReceivableByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReceivableByPkMutation, DeleteReceivableByPkMutationVariables>(DeleteReceivableByPkDocument, options);
      }
export type DeleteReceivableByPkMutationHookResult = ReturnType<typeof useDeleteReceivableByPkMutation>;
export type DeleteReceivableByPkMutationResult = Apollo.MutationResult<DeleteReceivableByPkMutation>;
export type DeleteReceivableByPkMutationOptions = Apollo.BaseMutationOptions<DeleteReceivableByPkMutation, DeleteReceivableByPkMutationVariables>;
export const GetLedgerLogsByPkDocument = gql`
    query GetLedgerLogsByPk($ledgerId: uuid!) {
  logs: ledger_ledger_log(
    where: {ledger_id: {_eq: $ledgerId}}
    order_by: {created_at: desc}
  ) {
    ...LedgerLog
  }
}
    ${LedgerLogFragmentDoc}`;
export type GetLedgerLogsByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLedgerLogsByPkQuery, GetLedgerLogsByPkQueryVariables>, 'query'> & ({ variables: GetLedgerLogsByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLedgerLogsByPkComponent = (props: GetLedgerLogsByPkComponentProps) => (
      <ApolloReactComponents.Query<GetLedgerLogsByPkQuery, GetLedgerLogsByPkQueryVariables> query={GetLedgerLogsByPkDocument} {...props} />
    );
    

/**
 * __useGetLedgerLogsByPkQuery__
 *
 * To run a query within a React component, call `useGetLedgerLogsByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLedgerLogsByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLedgerLogsByPkQuery({
 *   variables: {
 *      ledgerId: // value for 'ledgerId'
 *   },
 * });
 */
export function useGetLedgerLogsByPkQuery(baseOptions: Apollo.QueryHookOptions<GetLedgerLogsByPkQuery, GetLedgerLogsByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLedgerLogsByPkQuery, GetLedgerLogsByPkQueryVariables>(GetLedgerLogsByPkDocument, options);
      }
export function useGetLedgerLogsByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLedgerLogsByPkQuery, GetLedgerLogsByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLedgerLogsByPkQuery, GetLedgerLogsByPkQueryVariables>(GetLedgerLogsByPkDocument, options);
        }
export type GetLedgerLogsByPkQueryHookResult = ReturnType<typeof useGetLedgerLogsByPkQuery>;
export type GetLedgerLogsByPkLazyQueryHookResult = ReturnType<typeof useGetLedgerLogsByPkLazyQuery>;
export type GetLedgerLogsByPkQueryResult = Apollo.QueryResult<GetLedgerLogsByPkQuery, GetLedgerLogsByPkQueryVariables>;
export const GetLedgersDocument = gql`
    query GetLedgers($distinctOn: [ledger_ledger_select_column!], $limit: Int, $offset: Int, $orderBy: [ledger_ledger_order_by!], $where: ledger_ledger_bool_exp) {
  rows: ledger_ledger(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...Ledger
  }
}
    ${LedgerFragmentDoc}`;
export type GetLedgersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLedgersQuery, GetLedgersQueryVariables>, 'query'>;

    export const GetLedgersComponent = (props: GetLedgersComponentProps) => (
      <ApolloReactComponents.Query<GetLedgersQuery, GetLedgersQueryVariables> query={GetLedgersDocument} {...props} />
    );
    

/**
 * __useGetLedgersQuery__
 *
 * To run a query within a React component, call `useGetLedgersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLedgersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLedgersQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLedgersQuery(baseOptions?: Apollo.QueryHookOptions<GetLedgersQuery, GetLedgersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLedgersQuery, GetLedgersQueryVariables>(GetLedgersDocument, options);
      }
export function useGetLedgersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLedgersQuery, GetLedgersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLedgersQuery, GetLedgersQueryVariables>(GetLedgersDocument, options);
        }
export type GetLedgersQueryHookResult = ReturnType<typeof useGetLedgersQuery>;
export type GetLedgersLazyQueryHookResult = ReturnType<typeof useGetLedgersLazyQuery>;
export type GetLedgersQueryResult = Apollo.QueryResult<GetLedgersQuery, GetLedgersQueryVariables>;
export const GetLedgerByPkDocument = gql`
    query GetLedgerByPk($id: uuid!) {
  row: ledger_ledger_by_pk(id: $id) {
    ...Ledger
  }
  logs: ledger_ledger_log(
    where: {ledger_id: {_eq: $id}}
    order_by: {created_at: desc}
  ) {
    ...LedgerLog
  }
}
    ${LedgerFragmentDoc}
${LedgerLogFragmentDoc}`;
export type GetLedgerByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLedgerByPkQuery, GetLedgerByPkQueryVariables>, 'query'> & ({ variables: GetLedgerByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLedgerByPkComponent = (props: GetLedgerByPkComponentProps) => (
      <ApolloReactComponents.Query<GetLedgerByPkQuery, GetLedgerByPkQueryVariables> query={GetLedgerByPkDocument} {...props} />
    );
    

/**
 * __useGetLedgerByPkQuery__
 *
 * To run a query within a React component, call `useGetLedgerByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLedgerByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLedgerByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLedgerByPkQuery(baseOptions: Apollo.QueryHookOptions<GetLedgerByPkQuery, GetLedgerByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLedgerByPkQuery, GetLedgerByPkQueryVariables>(GetLedgerByPkDocument, options);
      }
export function useGetLedgerByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLedgerByPkQuery, GetLedgerByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLedgerByPkQuery, GetLedgerByPkQueryVariables>(GetLedgerByPkDocument, options);
        }
export type GetLedgerByPkQueryHookResult = ReturnType<typeof useGetLedgerByPkQuery>;
export type GetLedgerByPkLazyQueryHookResult = ReturnType<typeof useGetLedgerByPkLazyQuery>;
export type GetLedgerByPkQueryResult = Apollo.QueryResult<GetLedgerByPkQuery, GetLedgerByPkQueryVariables>;
export const InsertLedgerOneDocument = gql`
    mutation InsertLedgerOne($object: ledger_ledger_insert_input!) {
  insert_ledger_ledger_one(object: $object) {
    ...Ledger
  }
}
    ${LedgerFragmentDoc}`;
export type InsertLedgerOneMutationFn = Apollo.MutationFunction<InsertLedgerOneMutation, InsertLedgerOneMutationVariables>;
export type InsertLedgerOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertLedgerOneMutation, InsertLedgerOneMutationVariables>, 'mutation'>;

    export const InsertLedgerOneComponent = (props: InsertLedgerOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertLedgerOneMutation, InsertLedgerOneMutationVariables> mutation={InsertLedgerOneDocument} {...props} />
    );
    

/**
 * __useInsertLedgerOneMutation__
 *
 * To run a mutation, you first call `useInsertLedgerOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLedgerOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLedgerOneMutation, { data, loading, error }] = useInsertLedgerOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertLedgerOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertLedgerOneMutation, InsertLedgerOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLedgerOneMutation, InsertLedgerOneMutationVariables>(InsertLedgerOneDocument, options);
      }
export type InsertLedgerOneMutationHookResult = ReturnType<typeof useInsertLedgerOneMutation>;
export type InsertLedgerOneMutationResult = Apollo.MutationResult<InsertLedgerOneMutation>;
export type InsertLedgerOneMutationOptions = Apollo.BaseMutationOptions<InsertLedgerOneMutation, InsertLedgerOneMutationVariables>;
export const UpdateLedgerByPkDocument = gql`
    mutation UpdateLedgerByPk($_set: ledger_ledger_set_input, $id: uuid!) {
  update_ledger_ledger_by_pk(pk_columns: {id: $id}, _set: $_set) {
    ...Ledger
  }
}
    ${LedgerFragmentDoc}`;
export type UpdateLedgerByPkMutationFn = Apollo.MutationFunction<UpdateLedgerByPkMutation, UpdateLedgerByPkMutationVariables>;
export type UpdateLedgerByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateLedgerByPkMutation, UpdateLedgerByPkMutationVariables>, 'mutation'>;

    export const UpdateLedgerByPkComponent = (props: UpdateLedgerByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateLedgerByPkMutation, UpdateLedgerByPkMutationVariables> mutation={UpdateLedgerByPkDocument} {...props} />
    );
    

/**
 * __useUpdateLedgerByPkMutation__
 *
 * To run a mutation, you first call `useUpdateLedgerByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLedgerByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLedgerByPkMutation, { data, loading, error }] = useUpdateLedgerByPkMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateLedgerByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLedgerByPkMutation, UpdateLedgerByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLedgerByPkMutation, UpdateLedgerByPkMutationVariables>(UpdateLedgerByPkDocument, options);
      }
export type UpdateLedgerByPkMutationHookResult = ReturnType<typeof useUpdateLedgerByPkMutation>;
export type UpdateLedgerByPkMutationResult = Apollo.MutationResult<UpdateLedgerByPkMutation>;
export type UpdateLedgerByPkMutationOptions = Apollo.BaseMutationOptions<UpdateLedgerByPkMutation, UpdateLedgerByPkMutationVariables>;
export const DeleteLedgerByPkDocument = gql`
    mutation DeleteLedgerByPk($id: uuid!) {
  delete_ledger_ledger_by_pk(id: $id) {
    ...Ledger
  }
}
    ${LedgerFragmentDoc}`;
export type DeleteLedgerByPkMutationFn = Apollo.MutationFunction<DeleteLedgerByPkMutation, DeleteLedgerByPkMutationVariables>;
export type DeleteLedgerByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteLedgerByPkMutation, DeleteLedgerByPkMutationVariables>, 'mutation'>;

    export const DeleteLedgerByPkComponent = (props: DeleteLedgerByPkComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteLedgerByPkMutation, DeleteLedgerByPkMutationVariables> mutation={DeleteLedgerByPkDocument} {...props} />
    );
    

/**
 * __useDeleteLedgerByPkMutation__
 *
 * To run a mutation, you first call `useDeleteLedgerByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLedgerByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLedgerByPkMutation, { data, loading, error }] = useDeleteLedgerByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLedgerByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLedgerByPkMutation, DeleteLedgerByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLedgerByPkMutation, DeleteLedgerByPkMutationVariables>(DeleteLedgerByPkDocument, options);
      }
export type DeleteLedgerByPkMutationHookResult = ReturnType<typeof useDeleteLedgerByPkMutation>;
export type DeleteLedgerByPkMutationResult = Apollo.MutationResult<DeleteLedgerByPkMutation>;
export type DeleteLedgerByPkMutationOptions = Apollo.BaseMutationOptions<DeleteLedgerByPkMutation, DeleteLedgerByPkMutationVariables>;
export const GetLedgerAttachmentsDocument = gql`
    query GetLedgerAttachments($distinctOn: [ledger_ledger_attachment_select_column!], $limit: Int, $offset: Int, $orderBy: [ledger_ledger_attachment_order_by!], $where: ledger_ledger_attachment_bool_exp) {
  rows: ledger_ledger_attachment(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...LedgerAttachment
  }
}
    ${LedgerAttachmentFragmentDoc}`;
export type GetLedgerAttachmentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLedgerAttachmentsQuery, GetLedgerAttachmentsQueryVariables>, 'query'>;

    export const GetLedgerAttachmentsComponent = (props: GetLedgerAttachmentsComponentProps) => (
      <ApolloReactComponents.Query<GetLedgerAttachmentsQuery, GetLedgerAttachmentsQueryVariables> query={GetLedgerAttachmentsDocument} {...props} />
    );
    

/**
 * __useGetLedgerAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetLedgerAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLedgerAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLedgerAttachmentsQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLedgerAttachmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetLedgerAttachmentsQuery, GetLedgerAttachmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLedgerAttachmentsQuery, GetLedgerAttachmentsQueryVariables>(GetLedgerAttachmentsDocument, options);
      }
export function useGetLedgerAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLedgerAttachmentsQuery, GetLedgerAttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLedgerAttachmentsQuery, GetLedgerAttachmentsQueryVariables>(GetLedgerAttachmentsDocument, options);
        }
export type GetLedgerAttachmentsQueryHookResult = ReturnType<typeof useGetLedgerAttachmentsQuery>;
export type GetLedgerAttachmentsLazyQueryHookResult = ReturnType<typeof useGetLedgerAttachmentsLazyQuery>;
export type GetLedgerAttachmentsQueryResult = Apollo.QueryResult<GetLedgerAttachmentsQuery, GetLedgerAttachmentsQueryVariables>;
export const GetLedgerAttachmentByPkDocument = gql`
    query GetLedgerAttachmentByPk($id: uuid!) {
  row: ledger_ledger_attachment_by_pk(id: $id) {
    ...LedgerAttachment
  }
}
    ${LedgerAttachmentFragmentDoc}`;
export type GetLedgerAttachmentByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLedgerAttachmentByPkQuery, GetLedgerAttachmentByPkQueryVariables>, 'query'> & ({ variables: GetLedgerAttachmentByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLedgerAttachmentByPkComponent = (props: GetLedgerAttachmentByPkComponentProps) => (
      <ApolloReactComponents.Query<GetLedgerAttachmentByPkQuery, GetLedgerAttachmentByPkQueryVariables> query={GetLedgerAttachmentByPkDocument} {...props} />
    );
    

/**
 * __useGetLedgerAttachmentByPkQuery__
 *
 * To run a query within a React component, call `useGetLedgerAttachmentByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLedgerAttachmentByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLedgerAttachmentByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLedgerAttachmentByPkQuery(baseOptions: Apollo.QueryHookOptions<GetLedgerAttachmentByPkQuery, GetLedgerAttachmentByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLedgerAttachmentByPkQuery, GetLedgerAttachmentByPkQueryVariables>(GetLedgerAttachmentByPkDocument, options);
      }
export function useGetLedgerAttachmentByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLedgerAttachmentByPkQuery, GetLedgerAttachmentByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLedgerAttachmentByPkQuery, GetLedgerAttachmentByPkQueryVariables>(GetLedgerAttachmentByPkDocument, options);
        }
export type GetLedgerAttachmentByPkQueryHookResult = ReturnType<typeof useGetLedgerAttachmentByPkQuery>;
export type GetLedgerAttachmentByPkLazyQueryHookResult = ReturnType<typeof useGetLedgerAttachmentByPkLazyQuery>;
export type GetLedgerAttachmentByPkQueryResult = Apollo.QueryResult<GetLedgerAttachmentByPkQuery, GetLedgerAttachmentByPkQueryVariables>;
export const InsertLedgerAttachmentOneDocument = gql`
    mutation InsertLedgerAttachmentOne($object: ledger_ledger_attachment_insert_input!) {
  insert_ledger_ledger_attachment_one(object: $object) {
    ...LedgerAttachment
  }
}
    ${LedgerAttachmentFragmentDoc}`;
export type InsertLedgerAttachmentOneMutationFn = Apollo.MutationFunction<InsertLedgerAttachmentOneMutation, InsertLedgerAttachmentOneMutationVariables>;
export type InsertLedgerAttachmentOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertLedgerAttachmentOneMutation, InsertLedgerAttachmentOneMutationVariables>, 'mutation'>;

    export const InsertLedgerAttachmentOneComponent = (props: InsertLedgerAttachmentOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertLedgerAttachmentOneMutation, InsertLedgerAttachmentOneMutationVariables> mutation={InsertLedgerAttachmentOneDocument} {...props} />
    );
    

/**
 * __useInsertLedgerAttachmentOneMutation__
 *
 * To run a mutation, you first call `useInsertLedgerAttachmentOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLedgerAttachmentOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLedgerAttachmentOneMutation, { data, loading, error }] = useInsertLedgerAttachmentOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertLedgerAttachmentOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertLedgerAttachmentOneMutation, InsertLedgerAttachmentOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLedgerAttachmentOneMutation, InsertLedgerAttachmentOneMutationVariables>(InsertLedgerAttachmentOneDocument, options);
      }
export type InsertLedgerAttachmentOneMutationHookResult = ReturnType<typeof useInsertLedgerAttachmentOneMutation>;
export type InsertLedgerAttachmentOneMutationResult = Apollo.MutationResult<InsertLedgerAttachmentOneMutation>;
export type InsertLedgerAttachmentOneMutationOptions = Apollo.BaseMutationOptions<InsertLedgerAttachmentOneMutation, InsertLedgerAttachmentOneMutationVariables>;
export const UpdateLedgerAttachmentByPkDocument = gql`
    mutation UpdateLedgerAttachmentByPk($_set: ledger_ledger_attachment_set_input, $id: uuid!) {
  update_ledger_ledger_attachment_by_pk(pk_columns: {id: $id}, _set: $_set) {
    ...LedgerAttachment
  }
}
    ${LedgerAttachmentFragmentDoc}`;
export type UpdateLedgerAttachmentByPkMutationFn = Apollo.MutationFunction<UpdateLedgerAttachmentByPkMutation, UpdateLedgerAttachmentByPkMutationVariables>;
export type UpdateLedgerAttachmentByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateLedgerAttachmentByPkMutation, UpdateLedgerAttachmentByPkMutationVariables>, 'mutation'>;

    export const UpdateLedgerAttachmentByPkComponent = (props: UpdateLedgerAttachmentByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateLedgerAttachmentByPkMutation, UpdateLedgerAttachmentByPkMutationVariables> mutation={UpdateLedgerAttachmentByPkDocument} {...props} />
    );
    

/**
 * __useUpdateLedgerAttachmentByPkMutation__
 *
 * To run a mutation, you first call `useUpdateLedgerAttachmentByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLedgerAttachmentByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLedgerAttachmentByPkMutation, { data, loading, error }] = useUpdateLedgerAttachmentByPkMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateLedgerAttachmentByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLedgerAttachmentByPkMutation, UpdateLedgerAttachmentByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLedgerAttachmentByPkMutation, UpdateLedgerAttachmentByPkMutationVariables>(UpdateLedgerAttachmentByPkDocument, options);
      }
export type UpdateLedgerAttachmentByPkMutationHookResult = ReturnType<typeof useUpdateLedgerAttachmentByPkMutation>;
export type UpdateLedgerAttachmentByPkMutationResult = Apollo.MutationResult<UpdateLedgerAttachmentByPkMutation>;
export type UpdateLedgerAttachmentByPkMutationOptions = Apollo.BaseMutationOptions<UpdateLedgerAttachmentByPkMutation, UpdateLedgerAttachmentByPkMutationVariables>;
export const DeleteLedgerAttachmentByPkDocument = gql`
    mutation DeleteLedgerAttachmentByPk($id: uuid!) {
  delete_ledger_ledger_attachment_by_pk(id: $id) {
    ...LedgerAttachment
  }
}
    ${LedgerAttachmentFragmentDoc}`;
export type DeleteLedgerAttachmentByPkMutationFn = Apollo.MutationFunction<DeleteLedgerAttachmentByPkMutation, DeleteLedgerAttachmentByPkMutationVariables>;
export type DeleteLedgerAttachmentByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteLedgerAttachmentByPkMutation, DeleteLedgerAttachmentByPkMutationVariables>, 'mutation'>;

    export const DeleteLedgerAttachmentByPkComponent = (props: DeleteLedgerAttachmentByPkComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteLedgerAttachmentByPkMutation, DeleteLedgerAttachmentByPkMutationVariables> mutation={DeleteLedgerAttachmentByPkDocument} {...props} />
    );
    

/**
 * __useDeleteLedgerAttachmentByPkMutation__
 *
 * To run a mutation, you first call `useDeleteLedgerAttachmentByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLedgerAttachmentByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLedgerAttachmentByPkMutation, { data, loading, error }] = useDeleteLedgerAttachmentByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLedgerAttachmentByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLedgerAttachmentByPkMutation, DeleteLedgerAttachmentByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLedgerAttachmentByPkMutation, DeleteLedgerAttachmentByPkMutationVariables>(DeleteLedgerAttachmentByPkDocument, options);
      }
export type DeleteLedgerAttachmentByPkMutationHookResult = ReturnType<typeof useDeleteLedgerAttachmentByPkMutation>;
export type DeleteLedgerAttachmentByPkMutationResult = Apollo.MutationResult<DeleteLedgerAttachmentByPkMutation>;
export type DeleteLedgerAttachmentByPkMutationOptions = Apollo.BaseMutationOptions<DeleteLedgerAttachmentByPkMutation, DeleteLedgerAttachmentByPkMutationVariables>;
export const SubmitLedgerByPkDocument = gql`
    mutation SubmitLedgerByPk($data: LedgerSubmitInput!) {
  ledger: ledger_submit_ledger(data: $data) {
    ledgerId
  }
}
    `;
export type SubmitLedgerByPkMutationFn = Apollo.MutationFunction<SubmitLedgerByPkMutation, SubmitLedgerByPkMutationVariables>;
export type SubmitLedgerByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubmitLedgerByPkMutation, SubmitLedgerByPkMutationVariables>, 'mutation'>;

    export const SubmitLedgerByPkComponent = (props: SubmitLedgerByPkComponentProps) => (
      <ApolloReactComponents.Mutation<SubmitLedgerByPkMutation, SubmitLedgerByPkMutationVariables> mutation={SubmitLedgerByPkDocument} {...props} />
    );
    

/**
 * __useSubmitLedgerByPkMutation__
 *
 * To run a mutation, you first call `useSubmitLedgerByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitLedgerByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitLedgerByPkMutation, { data, loading, error }] = useSubmitLedgerByPkMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSubmitLedgerByPkMutation(baseOptions?: Apollo.MutationHookOptions<SubmitLedgerByPkMutation, SubmitLedgerByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitLedgerByPkMutation, SubmitLedgerByPkMutationVariables>(SubmitLedgerByPkDocument, options);
      }
export type SubmitLedgerByPkMutationHookResult = ReturnType<typeof useSubmitLedgerByPkMutation>;
export type SubmitLedgerByPkMutationResult = Apollo.MutationResult<SubmitLedgerByPkMutation>;
export type SubmitLedgerByPkMutationOptions = Apollo.BaseMutationOptions<SubmitLedgerByPkMutation, SubmitLedgerByPkMutationVariables>;
export const ApproveLedgerByPkDocument = gql`
    mutation ApproveLedgerByPk($data: LedgerApproveInput!) {
  ledger: ledger_approve_ledger(data: $data) {
    ledgerId
    statusId
  }
}
    `;
export type ApproveLedgerByPkMutationFn = Apollo.MutationFunction<ApproveLedgerByPkMutation, ApproveLedgerByPkMutationVariables>;
export type ApproveLedgerByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ApproveLedgerByPkMutation, ApproveLedgerByPkMutationVariables>, 'mutation'>;

    export const ApproveLedgerByPkComponent = (props: ApproveLedgerByPkComponentProps) => (
      <ApolloReactComponents.Mutation<ApproveLedgerByPkMutation, ApproveLedgerByPkMutationVariables> mutation={ApproveLedgerByPkDocument} {...props} />
    );
    

/**
 * __useApproveLedgerByPkMutation__
 *
 * To run a mutation, you first call `useApproveLedgerByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveLedgerByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveLedgerByPkMutation, { data, loading, error }] = useApproveLedgerByPkMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useApproveLedgerByPkMutation(baseOptions?: Apollo.MutationHookOptions<ApproveLedgerByPkMutation, ApproveLedgerByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveLedgerByPkMutation, ApproveLedgerByPkMutationVariables>(ApproveLedgerByPkDocument, options);
      }
export type ApproveLedgerByPkMutationHookResult = ReturnType<typeof useApproveLedgerByPkMutation>;
export type ApproveLedgerByPkMutationResult = Apollo.MutationResult<ApproveLedgerByPkMutation>;
export type ApproveLedgerByPkMutationOptions = Apollo.BaseMutationOptions<ApproveLedgerByPkMutation, ApproveLedgerByPkMutationVariables>;
export const GetLedgerTotalReportDocument = gql`
    query GetLedgerTotalReport($fiscalYear: Int!) {
  organizations: organization(order_by: {reference: asc_nulls_last}) {
    id
    name
    reference
    short_name
  }
  budgets: account_budget_type(
    where: {fiscal_year: {_eq: $fiscalYear}}
    order_by: {sequence: asc_nulls_last}
  ) {
    id
    description
    fiscal_year
    group_id
    group_name
    account_id
    closed
    closed_at
  }
  accounts: account_account(order_by: {code: asc}) {
    id
    name
    code
  }
  total: ledger_total_report_yearly(where: {fiscal_year: {_eq: $fiscalYear}}) {
    account_id
    budget_balance
    allocated_budget
    budget_id
    expense_total
    fiscal_year
    organization_id
  }
}
    `;
export type GetLedgerTotalReportComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLedgerTotalReportQuery, GetLedgerTotalReportQueryVariables>, 'query'> & ({ variables: GetLedgerTotalReportQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLedgerTotalReportComponent = (props: GetLedgerTotalReportComponentProps) => (
      <ApolloReactComponents.Query<GetLedgerTotalReportQuery, GetLedgerTotalReportQueryVariables> query={GetLedgerTotalReportDocument} {...props} />
    );
    

/**
 * __useGetLedgerTotalReportQuery__
 *
 * To run a query within a React component, call `useGetLedgerTotalReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLedgerTotalReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLedgerTotalReportQuery({
 *   variables: {
 *      fiscalYear: // value for 'fiscalYear'
 *   },
 * });
 */
export function useGetLedgerTotalReportQuery(baseOptions: Apollo.QueryHookOptions<GetLedgerTotalReportQuery, GetLedgerTotalReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLedgerTotalReportQuery, GetLedgerTotalReportQueryVariables>(GetLedgerTotalReportDocument, options);
      }
export function useGetLedgerTotalReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLedgerTotalReportQuery, GetLedgerTotalReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLedgerTotalReportQuery, GetLedgerTotalReportQueryVariables>(GetLedgerTotalReportDocument, options);
        }
export type GetLedgerTotalReportQueryHookResult = ReturnType<typeof useGetLedgerTotalReportQuery>;
export type GetLedgerTotalReportLazyQueryHookResult = ReturnType<typeof useGetLedgerTotalReportLazyQuery>;
export type GetLedgerTotalReportQueryResult = Apollo.QueryResult<GetLedgerTotalReportQuery, GetLedgerTotalReportQueryVariables>;
export const GetLoanAccountTypesDocument = gql`
    query GetLoanAccountTypes {
  rows: loan_account_type(
    where: {deleted_at: {_is_null: true}}
    order_by: {sequence: asc_nulls_last}
  ) {
    id
    description
    sequence
  }
}
    `;
export type GetLoanAccountTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanAccountTypesQuery, GetLoanAccountTypesQueryVariables>, 'query'>;

    export const GetLoanAccountTypesComponent = (props: GetLoanAccountTypesComponentProps) => (
      <ApolloReactComponents.Query<GetLoanAccountTypesQuery, GetLoanAccountTypesQueryVariables> query={GetLoanAccountTypesDocument} {...props} />
    );
    

/**
 * __useGetLoanAccountTypesQuery__
 *
 * To run a query within a React component, call `useGetLoanAccountTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanAccountTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanAccountTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoanAccountTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetLoanAccountTypesQuery, GetLoanAccountTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanAccountTypesQuery, GetLoanAccountTypesQueryVariables>(GetLoanAccountTypesDocument, options);
      }
export function useGetLoanAccountTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanAccountTypesQuery, GetLoanAccountTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanAccountTypesQuery, GetLoanAccountTypesQueryVariables>(GetLoanAccountTypesDocument, options);
        }
export type GetLoanAccountTypesQueryHookResult = ReturnType<typeof useGetLoanAccountTypesQuery>;
export type GetLoanAccountTypesLazyQueryHookResult = ReturnType<typeof useGetLoanAccountTypesLazyQuery>;
export type GetLoanAccountTypesQueryResult = Apollo.QueryResult<GetLoanAccountTypesQuery, GetLoanAccountTypesQueryVariables>;
export const InsertLoanApplicationAttachmentOneDocument = gql`
    mutation InsertLoanApplicationAttachmentOne($object: loan_application_attachment_insert_input!) {
  entity: insert_loan_application_attachment_one(object: $object) {
    ...LoanApplicationAttachment
  }
}
    ${LoanApplicationAttachmentFragmentDoc}`;
export type InsertLoanApplicationAttachmentOneMutationFn = Apollo.MutationFunction<InsertLoanApplicationAttachmentOneMutation, InsertLoanApplicationAttachmentOneMutationVariables>;
export type InsertLoanApplicationAttachmentOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertLoanApplicationAttachmentOneMutation, InsertLoanApplicationAttachmentOneMutationVariables>, 'mutation'>;

    export const InsertLoanApplicationAttachmentOneComponent = (props: InsertLoanApplicationAttachmentOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertLoanApplicationAttachmentOneMutation, InsertLoanApplicationAttachmentOneMutationVariables> mutation={InsertLoanApplicationAttachmentOneDocument} {...props} />
    );
    

/**
 * __useInsertLoanApplicationAttachmentOneMutation__
 *
 * To run a mutation, you first call `useInsertLoanApplicationAttachmentOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLoanApplicationAttachmentOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLoanApplicationAttachmentOneMutation, { data, loading, error }] = useInsertLoanApplicationAttachmentOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertLoanApplicationAttachmentOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertLoanApplicationAttachmentOneMutation, InsertLoanApplicationAttachmentOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLoanApplicationAttachmentOneMutation, InsertLoanApplicationAttachmentOneMutationVariables>(InsertLoanApplicationAttachmentOneDocument, options);
      }
export type InsertLoanApplicationAttachmentOneMutationHookResult = ReturnType<typeof useInsertLoanApplicationAttachmentOneMutation>;
export type InsertLoanApplicationAttachmentOneMutationResult = Apollo.MutationResult<InsertLoanApplicationAttachmentOneMutation>;
export type InsertLoanApplicationAttachmentOneMutationOptions = Apollo.BaseMutationOptions<InsertLoanApplicationAttachmentOneMutation, InsertLoanApplicationAttachmentOneMutationVariables>;
export const UpdateLoanApplicationAttachmentByPkDocument = gql`
    mutation UpdateLoanApplicationAttachmentByPk($id: uuid!, $_set: loan_application_attachment_set_input) {
  entity: update_loan_application_attachment_by_pk(
    pk_columns: {id: $id}
    _set: $_set
  ) {
    ...LoanApplicationAttachment
  }
}
    ${LoanApplicationAttachmentFragmentDoc}`;
export type UpdateLoanApplicationAttachmentByPkMutationFn = Apollo.MutationFunction<UpdateLoanApplicationAttachmentByPkMutation, UpdateLoanApplicationAttachmentByPkMutationVariables>;
export type UpdateLoanApplicationAttachmentByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateLoanApplicationAttachmentByPkMutation, UpdateLoanApplicationAttachmentByPkMutationVariables>, 'mutation'>;

    export const UpdateLoanApplicationAttachmentByPkComponent = (props: UpdateLoanApplicationAttachmentByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateLoanApplicationAttachmentByPkMutation, UpdateLoanApplicationAttachmentByPkMutationVariables> mutation={UpdateLoanApplicationAttachmentByPkDocument} {...props} />
    );
    

/**
 * __useUpdateLoanApplicationAttachmentByPkMutation__
 *
 * To run a mutation, you first call `useUpdateLoanApplicationAttachmentByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoanApplicationAttachmentByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoanApplicationAttachmentByPkMutation, { data, loading, error }] = useUpdateLoanApplicationAttachmentByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateLoanApplicationAttachmentByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLoanApplicationAttachmentByPkMutation, UpdateLoanApplicationAttachmentByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLoanApplicationAttachmentByPkMutation, UpdateLoanApplicationAttachmentByPkMutationVariables>(UpdateLoanApplicationAttachmentByPkDocument, options);
      }
export type UpdateLoanApplicationAttachmentByPkMutationHookResult = ReturnType<typeof useUpdateLoanApplicationAttachmentByPkMutation>;
export type UpdateLoanApplicationAttachmentByPkMutationResult = Apollo.MutationResult<UpdateLoanApplicationAttachmentByPkMutation>;
export type UpdateLoanApplicationAttachmentByPkMutationOptions = Apollo.BaseMutationOptions<UpdateLoanApplicationAttachmentByPkMutation, UpdateLoanApplicationAttachmentByPkMutationVariables>;
export const DeleteLoanApplicationAttachmentByPkDocument = gql`
    mutation DeleteLoanApplicationAttachmentByPk($id: uuid!) {
  entity: delete_loan_application_attachment_by_pk(id: $id) {
    ...LoanApplicationAttachment
  }
}
    ${LoanApplicationAttachmentFragmentDoc}`;
export type DeleteLoanApplicationAttachmentByPkMutationFn = Apollo.MutationFunction<DeleteLoanApplicationAttachmentByPkMutation, DeleteLoanApplicationAttachmentByPkMutationVariables>;
export type DeleteLoanApplicationAttachmentByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteLoanApplicationAttachmentByPkMutation, DeleteLoanApplicationAttachmentByPkMutationVariables>, 'mutation'>;

    export const DeleteLoanApplicationAttachmentByPkComponent = (props: DeleteLoanApplicationAttachmentByPkComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteLoanApplicationAttachmentByPkMutation, DeleteLoanApplicationAttachmentByPkMutationVariables> mutation={DeleteLoanApplicationAttachmentByPkDocument} {...props} />
    );
    

/**
 * __useDeleteLoanApplicationAttachmentByPkMutation__
 *
 * To run a mutation, you first call `useDeleteLoanApplicationAttachmentByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLoanApplicationAttachmentByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLoanApplicationAttachmentByPkMutation, { data, loading, error }] = useDeleteLoanApplicationAttachmentByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLoanApplicationAttachmentByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLoanApplicationAttachmentByPkMutation, DeleteLoanApplicationAttachmentByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLoanApplicationAttachmentByPkMutation, DeleteLoanApplicationAttachmentByPkMutationVariables>(DeleteLoanApplicationAttachmentByPkDocument, options);
      }
export type DeleteLoanApplicationAttachmentByPkMutationHookResult = ReturnType<typeof useDeleteLoanApplicationAttachmentByPkMutation>;
export type DeleteLoanApplicationAttachmentByPkMutationResult = Apollo.MutationResult<DeleteLoanApplicationAttachmentByPkMutation>;
export type DeleteLoanApplicationAttachmentByPkMutationOptions = Apollo.BaseMutationOptions<DeleteLoanApplicationAttachmentByPkMutation, DeleteLoanApplicationAttachmentByPkMutationVariables>;
export const ApproveLoanApplicationAttachmentsDocument = gql`
    mutation ApproveLoanApplicationAttachments($true_ids: [uuid!]!, $false_ids: [uuid!]!) {
  true: update_loan_application_attachment(
    _set: {approve: true}
    where: {id: {_in: $true_ids}}
  ) {
    affected_rows
    returning {
      id
      approve
    }
  }
  false: update_loan_application_attachment(
    _set: {approve: false}
    where: {id: {_in: $false_ids}}
  ) {
    affected_rows
    returning {
      id
      approve
    }
  }
}
    `;
export type ApproveLoanApplicationAttachmentsMutationFn = Apollo.MutationFunction<ApproveLoanApplicationAttachmentsMutation, ApproveLoanApplicationAttachmentsMutationVariables>;
export type ApproveLoanApplicationAttachmentsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ApproveLoanApplicationAttachmentsMutation, ApproveLoanApplicationAttachmentsMutationVariables>, 'mutation'>;

    export const ApproveLoanApplicationAttachmentsComponent = (props: ApproveLoanApplicationAttachmentsComponentProps) => (
      <ApolloReactComponents.Mutation<ApproveLoanApplicationAttachmentsMutation, ApproveLoanApplicationAttachmentsMutationVariables> mutation={ApproveLoanApplicationAttachmentsDocument} {...props} />
    );
    

/**
 * __useApproveLoanApplicationAttachmentsMutation__
 *
 * To run a mutation, you first call `useApproveLoanApplicationAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveLoanApplicationAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveLoanApplicationAttachmentsMutation, { data, loading, error }] = useApproveLoanApplicationAttachmentsMutation({
 *   variables: {
 *      true_ids: // value for 'true_ids'
 *      false_ids: // value for 'false_ids'
 *   },
 * });
 */
export function useApproveLoanApplicationAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<ApproveLoanApplicationAttachmentsMutation, ApproveLoanApplicationAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveLoanApplicationAttachmentsMutation, ApproveLoanApplicationAttachmentsMutationVariables>(ApproveLoanApplicationAttachmentsDocument, options);
      }
export type ApproveLoanApplicationAttachmentsMutationHookResult = ReturnType<typeof useApproveLoanApplicationAttachmentsMutation>;
export type ApproveLoanApplicationAttachmentsMutationResult = Apollo.MutationResult<ApproveLoanApplicationAttachmentsMutation>;
export type ApproveLoanApplicationAttachmentsMutationOptions = Apollo.BaseMutationOptions<ApproveLoanApplicationAttachmentsMutation, ApproveLoanApplicationAttachmentsMutationVariables>;
export const GetLoanApplicationAttachmentsDocument = gql`
    query GetLoanApplicationAttachments($distinct_on: [loan_application_attachment_select_column!], $limit: Int, $offset: Int, $order_by: [loan_application_attachment_order_by!], $where: loan_application_attachment_bool_exp) {
  rows: loan_application_attachment(
    distinct_on: $distinct_on
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: $where
  ) {
    ...LoanApplicationAttachment
  }
}
    ${LoanApplicationAttachmentFragmentDoc}`;
export type GetLoanApplicationAttachmentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanApplicationAttachmentsQuery, GetLoanApplicationAttachmentsQueryVariables>, 'query'>;

    export const GetLoanApplicationAttachmentsComponent = (props: GetLoanApplicationAttachmentsComponentProps) => (
      <ApolloReactComponents.Query<GetLoanApplicationAttachmentsQuery, GetLoanApplicationAttachmentsQueryVariables> query={GetLoanApplicationAttachmentsDocument} {...props} />
    );
    

/**
 * __useGetLoanApplicationAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetLoanApplicationAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanApplicationAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanApplicationAttachmentsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLoanApplicationAttachmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetLoanApplicationAttachmentsQuery, GetLoanApplicationAttachmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanApplicationAttachmentsQuery, GetLoanApplicationAttachmentsQueryVariables>(GetLoanApplicationAttachmentsDocument, options);
      }
export function useGetLoanApplicationAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanApplicationAttachmentsQuery, GetLoanApplicationAttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanApplicationAttachmentsQuery, GetLoanApplicationAttachmentsQueryVariables>(GetLoanApplicationAttachmentsDocument, options);
        }
export type GetLoanApplicationAttachmentsQueryHookResult = ReturnType<typeof useGetLoanApplicationAttachmentsQuery>;
export type GetLoanApplicationAttachmentsLazyQueryHookResult = ReturnType<typeof useGetLoanApplicationAttachmentsLazyQuery>;
export type GetLoanApplicationAttachmentsQueryResult = Apollo.QueryResult<GetLoanApplicationAttachmentsQuery, GetLoanApplicationAttachmentsQueryVariables>;
export const GetLoanApplicationAttachmentsSummaryDocument = gql`
    query GetLoanApplicationAttachmentsSummary($applicationHeaderId: uuid!) {
  total: loan_application_attachment_aggregate(
    where: {application_header_id: {_eq: $applicationHeaderId}}
  ) {
    aggregate {
      count
    }
    nodes {
      approve
    }
  }
  type_1: loan_application_attachment_aggregate(
    where: {application_header_id: {_eq: $applicationHeaderId}, attachment_type_id: {_eq: "type_1"}}
  ) {
    aggregate {
      count
    }
    nodes {
      approve
    }
  }
  type_2: loan_application_attachment_aggregate(
    where: {application_header_id: {_eq: $applicationHeaderId}, attachment_type_id: {_eq: "type_2"}}
  ) {
    aggregate {
      count
    }
    nodes {
      approve
    }
  }
  type_3: loan_application_attachment_aggregate(
    where: {application_header_id: {_eq: $applicationHeaderId}, attachment_type_id: {_eq: "type_3"}}
  ) {
    aggregate {
      count
    }
    nodes {
      approve
    }
  }
  type_4: loan_application_attachment_aggregate(
    where: {application_header_id: {_eq: $applicationHeaderId}, attachment_type_id: {_eq: "type_4"}}
  ) {
    aggregate {
      count
    }
    nodes {
      approve
    }
  }
  type_5: loan_application_attachment_aggregate(
    where: {application_header_id: {_eq: $applicationHeaderId}, attachment_type_id: {_eq: "type_5"}}
  ) {
    aggregate {
      count
    }
    nodes {
      approve
    }
  }
  type_6: loan_application_attachment_aggregate(
    where: {application_header_id: {_eq: $applicationHeaderId}, attachment_type_id: {_eq: "type_6"}}
  ) {
    aggregate {
      count
    }
    nodes {
      approve
    }
  }
  type_7: loan_application_attachment_aggregate(
    where: {application_header_id: {_eq: $applicationHeaderId}, attachment_type_id: {_eq: "type_7"}}
  ) {
    aggregate {
      count
    }
    nodes {
      approve
    }
  }
  type_8: loan_application_attachment_aggregate(
    where: {application_header_id: {_eq: $applicationHeaderId}, attachment_type_id: {_eq: "type_8"}}
  ) {
    aggregate {
      count
    }
    nodes {
      approve
    }
  }
  type_9: loan_application_attachment_aggregate(
    where: {application_header_id: {_eq: $applicationHeaderId}, attachment_type_id: {_eq: "type_9"}}
  ) {
    aggregate {
      count
    }
    nodes {
      approve
    }
  }
  type_10: loan_application_attachment_aggregate(
    where: {application_header_id: {_eq: $applicationHeaderId}, attachment_type_id: {_eq: "type_10"}}
  ) {
    aggregate {
      count
    }
    nodes {
      approve
    }
  }
  type_99: loan_application_attachment_aggregate(
    where: {application_header_id: {_eq: $applicationHeaderId}, attachment_type_id: {_eq: "type_99"}}
  ) {
    aggregate {
      count
    }
    nodes {
      approve
    }
  }
}
    `;
export type GetLoanApplicationAttachmentsSummaryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanApplicationAttachmentsSummaryQuery, GetLoanApplicationAttachmentsSummaryQueryVariables>, 'query'> & ({ variables: GetLoanApplicationAttachmentsSummaryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLoanApplicationAttachmentsSummaryComponent = (props: GetLoanApplicationAttachmentsSummaryComponentProps) => (
      <ApolloReactComponents.Query<GetLoanApplicationAttachmentsSummaryQuery, GetLoanApplicationAttachmentsSummaryQueryVariables> query={GetLoanApplicationAttachmentsSummaryDocument} {...props} />
    );
    

/**
 * __useGetLoanApplicationAttachmentsSummaryQuery__
 *
 * To run a query within a React component, call `useGetLoanApplicationAttachmentsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanApplicationAttachmentsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanApplicationAttachmentsSummaryQuery({
 *   variables: {
 *      applicationHeaderId: // value for 'applicationHeaderId'
 *   },
 * });
 */
export function useGetLoanApplicationAttachmentsSummaryQuery(baseOptions: Apollo.QueryHookOptions<GetLoanApplicationAttachmentsSummaryQuery, GetLoanApplicationAttachmentsSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanApplicationAttachmentsSummaryQuery, GetLoanApplicationAttachmentsSummaryQueryVariables>(GetLoanApplicationAttachmentsSummaryDocument, options);
      }
export function useGetLoanApplicationAttachmentsSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanApplicationAttachmentsSummaryQuery, GetLoanApplicationAttachmentsSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanApplicationAttachmentsSummaryQuery, GetLoanApplicationAttachmentsSummaryQueryVariables>(GetLoanApplicationAttachmentsSummaryDocument, options);
        }
export type GetLoanApplicationAttachmentsSummaryQueryHookResult = ReturnType<typeof useGetLoanApplicationAttachmentsSummaryQuery>;
export type GetLoanApplicationAttachmentsSummaryLazyQueryHookResult = ReturnType<typeof useGetLoanApplicationAttachmentsSummaryLazyQuery>;
export type GetLoanApplicationAttachmentsSummaryQueryResult = Apollo.QueryResult<GetLoanApplicationAttachmentsSummaryQuery, GetLoanApplicationAttachmentsSummaryQueryVariables>;
export const GetLoanApplicationEventLogsDocument = gql`
    query GetLoanApplicationEventLogs($distinctOn: [loan_application_event_log_select_column!], $limit: Int, $offset: Int, $orderBy: [loan_application_event_log_order_by!], $where: loan_application_event_log_bool_exp) {
  rows: loan_application_event_log(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...LoanApplicationEventLog
  }
}
    ${LoanApplicationEventLogFragmentDoc}`;
export type GetLoanApplicationEventLogsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanApplicationEventLogsQuery, GetLoanApplicationEventLogsQueryVariables>, 'query'>;

    export const GetLoanApplicationEventLogsComponent = (props: GetLoanApplicationEventLogsComponentProps) => (
      <ApolloReactComponents.Query<GetLoanApplicationEventLogsQuery, GetLoanApplicationEventLogsQueryVariables> query={GetLoanApplicationEventLogsDocument} {...props} />
    );
    

/**
 * __useGetLoanApplicationEventLogsQuery__
 *
 * To run a query within a React component, call `useGetLoanApplicationEventLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanApplicationEventLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanApplicationEventLogsQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLoanApplicationEventLogsQuery(baseOptions?: Apollo.QueryHookOptions<GetLoanApplicationEventLogsQuery, GetLoanApplicationEventLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanApplicationEventLogsQuery, GetLoanApplicationEventLogsQueryVariables>(GetLoanApplicationEventLogsDocument, options);
      }
export function useGetLoanApplicationEventLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanApplicationEventLogsQuery, GetLoanApplicationEventLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanApplicationEventLogsQuery, GetLoanApplicationEventLogsQueryVariables>(GetLoanApplicationEventLogsDocument, options);
        }
export type GetLoanApplicationEventLogsQueryHookResult = ReturnType<typeof useGetLoanApplicationEventLogsQuery>;
export type GetLoanApplicationEventLogsLazyQueryHookResult = ReturnType<typeof useGetLoanApplicationEventLogsLazyQuery>;
export type GetLoanApplicationEventLogsQueryResult = Apollo.QueryResult<GetLoanApplicationEventLogsQuery, GetLoanApplicationEventLogsQueryVariables>;
export const GetLoanBusinessGroupsDocument = gql`
    query GetLoanBusinessGroups {
  rows: loan_business_group(
    where: {deleted_at: {_is_null: true}}
    order_by: {sequence: asc_nulls_last}
  ) {
    id
    description
    sequence
    program_id
  }
}
    `;
export type GetLoanBusinessGroupsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanBusinessGroupsQuery, GetLoanBusinessGroupsQueryVariables>, 'query'>;

    export const GetLoanBusinessGroupsComponent = (props: GetLoanBusinessGroupsComponentProps) => (
      <ApolloReactComponents.Query<GetLoanBusinessGroupsQuery, GetLoanBusinessGroupsQueryVariables> query={GetLoanBusinessGroupsDocument} {...props} />
    );
    

/**
 * __useGetLoanBusinessGroupsQuery__
 *
 * To run a query within a React component, call `useGetLoanBusinessGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanBusinessGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanBusinessGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoanBusinessGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetLoanBusinessGroupsQuery, GetLoanBusinessGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanBusinessGroupsQuery, GetLoanBusinessGroupsQueryVariables>(GetLoanBusinessGroupsDocument, options);
      }
export function useGetLoanBusinessGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanBusinessGroupsQuery, GetLoanBusinessGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanBusinessGroupsQuery, GetLoanBusinessGroupsQueryVariables>(GetLoanBusinessGroupsDocument, options);
        }
export type GetLoanBusinessGroupsQueryHookResult = ReturnType<typeof useGetLoanBusinessGroupsQuery>;
export type GetLoanBusinessGroupsLazyQueryHookResult = ReturnType<typeof useGetLoanBusinessGroupsLazyQuery>;
export type GetLoanBusinessGroupsQueryResult = Apollo.QueryResult<GetLoanBusinessGroupsQuery, GetLoanBusinessGroupsQueryVariables>;
export const GetLoanBusinessProprietariesDocument = gql`
    query GetLoanBusinessProprietaries {
  rows: loan_business_proprietary(
    where: {deleted_at: {_is_null: true}}
    order_by: {sequence: asc_nulls_last}
  ) {
    id
    description
    sequence
    need_addition
  }
}
    `;
export type GetLoanBusinessProprietariesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanBusinessProprietariesQuery, GetLoanBusinessProprietariesQueryVariables>, 'query'>;

    export const GetLoanBusinessProprietariesComponent = (props: GetLoanBusinessProprietariesComponentProps) => (
      <ApolloReactComponents.Query<GetLoanBusinessProprietariesQuery, GetLoanBusinessProprietariesQueryVariables> query={GetLoanBusinessProprietariesDocument} {...props} />
    );
    

/**
 * __useGetLoanBusinessProprietariesQuery__
 *
 * To run a query within a React component, call `useGetLoanBusinessProprietariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanBusinessProprietariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanBusinessProprietariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoanBusinessProprietariesQuery(baseOptions?: Apollo.QueryHookOptions<GetLoanBusinessProprietariesQuery, GetLoanBusinessProprietariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanBusinessProprietariesQuery, GetLoanBusinessProprietariesQueryVariables>(GetLoanBusinessProprietariesDocument, options);
      }
export function useGetLoanBusinessProprietariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanBusinessProprietariesQuery, GetLoanBusinessProprietariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanBusinessProprietariesQuery, GetLoanBusinessProprietariesQueryVariables>(GetLoanBusinessProprietariesDocument, options);
        }
export type GetLoanBusinessProprietariesQueryHookResult = ReturnType<typeof useGetLoanBusinessProprietariesQuery>;
export type GetLoanBusinessProprietariesLazyQueryHookResult = ReturnType<typeof useGetLoanBusinessProprietariesLazyQuery>;
export type GetLoanBusinessProprietariesQueryResult = Apollo.QueryResult<GetLoanBusinessProprietariesQuery, GetLoanBusinessProprietariesQueryVariables>;
export const GetLoanBusinessStatusDocument = gql`
    query GetLoanBusinessStatus {
  rows: loan_business_status(
    where: {deleted_at: {_is_null: true}}
    order_by: {sequence: asc_nulls_last}
  ) {
    id
    description
    sequence
  }
}
    `;
export type GetLoanBusinessStatusComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanBusinessStatusQuery, GetLoanBusinessStatusQueryVariables>, 'query'>;

    export const GetLoanBusinessStatusComponent = (props: GetLoanBusinessStatusComponentProps) => (
      <ApolloReactComponents.Query<GetLoanBusinessStatusQuery, GetLoanBusinessStatusQueryVariables> query={GetLoanBusinessStatusDocument} {...props} />
    );
    

/**
 * __useGetLoanBusinessStatusQuery__
 *
 * To run a query within a React component, call `useGetLoanBusinessStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanBusinessStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanBusinessStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoanBusinessStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetLoanBusinessStatusQuery, GetLoanBusinessStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanBusinessStatusQuery, GetLoanBusinessStatusQueryVariables>(GetLoanBusinessStatusDocument, options);
      }
export function useGetLoanBusinessStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanBusinessStatusQuery, GetLoanBusinessStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanBusinessStatusQuery, GetLoanBusinessStatusQueryVariables>(GetLoanBusinessStatusDocument, options);
        }
export type GetLoanBusinessStatusQueryHookResult = ReturnType<typeof useGetLoanBusinessStatusQuery>;
export type GetLoanBusinessStatusLazyQueryHookResult = ReturnType<typeof useGetLoanBusinessStatusLazyQuery>;
export type GetLoanBusinessStatusQueryResult = Apollo.QueryResult<GetLoanBusinessStatusQuery, GetLoanBusinessStatusQueryVariables>;
export const GetLoanBusinessTypesDocument = gql`
    query GetLoanBusinessTypes {
  rows: loan_business_type(
    where: {deleted_at: {_is_null: true}}
    order_by: {sequence: asc_nulls_last}
  ) {
    id
    description
    sequence
    program_id
    active
  }
}
    `;
export type GetLoanBusinessTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanBusinessTypesQuery, GetLoanBusinessTypesQueryVariables>, 'query'>;

    export const GetLoanBusinessTypesComponent = (props: GetLoanBusinessTypesComponentProps) => (
      <ApolloReactComponents.Query<GetLoanBusinessTypesQuery, GetLoanBusinessTypesQueryVariables> query={GetLoanBusinessTypesDocument} {...props} />
    );
    

/**
 * __useGetLoanBusinessTypesQuery__
 *
 * To run a query within a React component, call `useGetLoanBusinessTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanBusinessTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanBusinessTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoanBusinessTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetLoanBusinessTypesQuery, GetLoanBusinessTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanBusinessTypesQuery, GetLoanBusinessTypesQueryVariables>(GetLoanBusinessTypesDocument, options);
      }
export function useGetLoanBusinessTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanBusinessTypesQuery, GetLoanBusinessTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanBusinessTypesQuery, GetLoanBusinessTypesQueryVariables>(GetLoanBusinessTypesDocument, options);
        }
export type GetLoanBusinessTypesQueryHookResult = ReturnType<typeof useGetLoanBusinessTypesQuery>;
export type GetLoanBusinessTypesLazyQueryHookResult = ReturnType<typeof useGetLoanBusinessTypesLazyQuery>;
export type GetLoanBusinessTypesQueryResult = Apollo.QueryResult<GetLoanBusinessTypesQuery, GetLoanBusinessTypesQueryVariables>;
export const GetLoanCurrentLoanDocument = gql`
    query GetLoanCurrentLoan {
  rows: loan_current_loan(
    where: {deleted_at: {_is_null: true}}
    order_by: {sequence: asc_nulls_last}
  ) {
    id
    description
    need_addition
    sequence
  }
}
    `;
export type GetLoanCurrentLoanComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanCurrentLoanQuery, GetLoanCurrentLoanQueryVariables>, 'query'>;

    export const GetLoanCurrentLoanComponent = (props: GetLoanCurrentLoanComponentProps) => (
      <ApolloReactComponents.Query<GetLoanCurrentLoanQuery, GetLoanCurrentLoanQueryVariables> query={GetLoanCurrentLoanDocument} {...props} />
    );
    

/**
 * __useGetLoanCurrentLoanQuery__
 *
 * To run a query within a React component, call `useGetLoanCurrentLoanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanCurrentLoanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanCurrentLoanQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoanCurrentLoanQuery(baseOptions?: Apollo.QueryHookOptions<GetLoanCurrentLoanQuery, GetLoanCurrentLoanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanCurrentLoanQuery, GetLoanCurrentLoanQueryVariables>(GetLoanCurrentLoanDocument, options);
      }
export function useGetLoanCurrentLoanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanCurrentLoanQuery, GetLoanCurrentLoanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanCurrentLoanQuery, GetLoanCurrentLoanQueryVariables>(GetLoanCurrentLoanDocument, options);
        }
export type GetLoanCurrentLoanQueryHookResult = ReturnType<typeof useGetLoanCurrentLoanQuery>;
export type GetLoanCurrentLoanLazyQueryHookResult = ReturnType<typeof useGetLoanCurrentLoanLazyQuery>;
export type GetLoanCurrentLoanQueryResult = Apollo.QueryResult<GetLoanCurrentLoanQuery, GetLoanCurrentLoanQueryVariables>;
export const GetLoanFinancialRescuesDocument = gql`
    query GetLoanFinancialRescues($distinct_on: [loan_financial_rescue_select_column!], $limit: Int, $offset: Int, $order_by: [loan_financial_rescue_order_by!] = {sequence: asc_nulls_last}, $where: loan_financial_rescue_bool_exp = {deleted_at: {_is_null: true}, parent_id: {_is_null: true}}) {
  rows: loan_financial_rescue(
    distinct_on: $distinct_on
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: $where
  ) {
    id
    description
    parent_id
    children {
      id
      description
      sequence
    }
    sequence
  }
}
    `;
export type GetLoanFinancialRescuesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanFinancialRescuesQuery, GetLoanFinancialRescuesQueryVariables>, 'query'>;

    export const GetLoanFinancialRescuesComponent = (props: GetLoanFinancialRescuesComponentProps) => (
      <ApolloReactComponents.Query<GetLoanFinancialRescuesQuery, GetLoanFinancialRescuesQueryVariables> query={GetLoanFinancialRescuesDocument} {...props} />
    );
    

/**
 * __useGetLoanFinancialRescuesQuery__
 *
 * To run a query within a React component, call `useGetLoanFinancialRescuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanFinancialRescuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanFinancialRescuesQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLoanFinancialRescuesQuery(baseOptions?: Apollo.QueryHookOptions<GetLoanFinancialRescuesQuery, GetLoanFinancialRescuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanFinancialRescuesQuery, GetLoanFinancialRescuesQueryVariables>(GetLoanFinancialRescuesDocument, options);
      }
export function useGetLoanFinancialRescuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanFinancialRescuesQuery, GetLoanFinancialRescuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanFinancialRescuesQuery, GetLoanFinancialRescuesQueryVariables>(GetLoanFinancialRescuesDocument, options);
        }
export type GetLoanFinancialRescuesQueryHookResult = ReturnType<typeof useGetLoanFinancialRescuesQuery>;
export type GetLoanFinancialRescuesLazyQueryHookResult = ReturnType<typeof useGetLoanFinancialRescuesLazyQuery>;
export type GetLoanFinancialRescuesQueryResult = Apollo.QueryResult<GetLoanFinancialRescuesQuery, GetLoanFinancialRescuesQueryVariables>;
export const GetLoanProprietariesDocument = gql`
    query GetLoanProprietaries {
  rows: loan_proprietary(
    where: {deleted_at: {_is_null: true}}
    order_by: {sequence: asc_nulls_last}
  ) {
    id
    description
    need_addition
    sequence
  }
}
    `;
export type GetLoanProprietariesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanProprietariesQuery, GetLoanProprietariesQueryVariables>, 'query'>;

    export const GetLoanProprietariesComponent = (props: GetLoanProprietariesComponentProps) => (
      <ApolloReactComponents.Query<GetLoanProprietariesQuery, GetLoanProprietariesQueryVariables> query={GetLoanProprietariesDocument} {...props} />
    );
    

/**
 * __useGetLoanProprietariesQuery__
 *
 * To run a query within a React component, call `useGetLoanProprietariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanProprietariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanProprietariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoanProprietariesQuery(baseOptions?: Apollo.QueryHookOptions<GetLoanProprietariesQuery, GetLoanProprietariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanProprietariesQuery, GetLoanProprietariesQueryVariables>(GetLoanProprietariesDocument, options);
      }
export function useGetLoanProprietariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanProprietariesQuery, GetLoanProprietariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanProprietariesQuery, GetLoanProprietariesQueryVariables>(GetLoanProprietariesDocument, options);
        }
export type GetLoanProprietariesQueryHookResult = ReturnType<typeof useGetLoanProprietariesQuery>;
export type GetLoanProprietariesLazyQueryHookResult = ReturnType<typeof useGetLoanProprietariesLazyQuery>;
export type GetLoanProprietariesQueryResult = Apollo.QueryResult<GetLoanProprietariesQuery, GetLoanProprietariesQueryVariables>;
export const GetLoanRepayTypesDocument = gql`
    query GetLoanRepayTypes {
  rows: loan_repay_type(
    where: {deleted_at: {_is_null: true}, parent_id: {_is_null: true}}
    order_by: {sequence: asc_nulls_last}
  ) {
    id
    description
    parent_id
    children {
      id
      description
      sequence
    }
    sequence
  }
}
    `;
export type GetLoanRepayTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanRepayTypesQuery, GetLoanRepayTypesQueryVariables>, 'query'>;

    export const GetLoanRepayTypesComponent = (props: GetLoanRepayTypesComponentProps) => (
      <ApolloReactComponents.Query<GetLoanRepayTypesQuery, GetLoanRepayTypesQueryVariables> query={GetLoanRepayTypesDocument} {...props} />
    );
    

/**
 * __useGetLoanRepayTypesQuery__
 *
 * To run a query within a React component, call `useGetLoanRepayTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanRepayTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanRepayTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoanRepayTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetLoanRepayTypesQuery, GetLoanRepayTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanRepayTypesQuery, GetLoanRepayTypesQueryVariables>(GetLoanRepayTypesDocument, options);
      }
export function useGetLoanRepayTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanRepayTypesQuery, GetLoanRepayTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanRepayTypesQuery, GetLoanRepayTypesQueryVariables>(GetLoanRepayTypesDocument, options);
        }
export type GetLoanRepayTypesQueryHookResult = ReturnType<typeof useGetLoanRepayTypesQuery>;
export type GetLoanRepayTypesLazyQueryHookResult = ReturnType<typeof useGetLoanRepayTypesLazyQuery>;
export type GetLoanRepayTypesQueryResult = Apollo.QueryResult<GetLoanRepayTypesQuery, GetLoanRepayTypesQueryVariables>;
export const GetLoanSmeDefinitionsDocument = gql`
    query GetLoanSMEDefinitions {
  rows: loan_sme_definition(
    where: {deleted_at: {_is_null: true}}
    order_by: {sequence: asc_nulls_last}
  ) {
    id
    description
    sequence
  }
}
    `;
export type GetLoanSmeDefinitionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanSmeDefinitionsQuery, GetLoanSmeDefinitionsQueryVariables>, 'query'>;

    export const GetLoanSmeDefinitionsComponent = (props: GetLoanSmeDefinitionsComponentProps) => (
      <ApolloReactComponents.Query<GetLoanSmeDefinitionsQuery, GetLoanSmeDefinitionsQueryVariables> query={GetLoanSmeDefinitionsDocument} {...props} />
    );
    

/**
 * __useGetLoanSmeDefinitionsQuery__
 *
 * To run a query within a React component, call `useGetLoanSmeDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanSmeDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanSmeDefinitionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoanSmeDefinitionsQuery(baseOptions?: Apollo.QueryHookOptions<GetLoanSmeDefinitionsQuery, GetLoanSmeDefinitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanSmeDefinitionsQuery, GetLoanSmeDefinitionsQueryVariables>(GetLoanSmeDefinitionsDocument, options);
      }
export function useGetLoanSmeDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanSmeDefinitionsQuery, GetLoanSmeDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanSmeDefinitionsQuery, GetLoanSmeDefinitionsQueryVariables>(GetLoanSmeDefinitionsDocument, options);
        }
export type GetLoanSmeDefinitionsQueryHookResult = ReturnType<typeof useGetLoanSmeDefinitionsQuery>;
export type GetLoanSmeDefinitionsLazyQueryHookResult = ReturnType<typeof useGetLoanSmeDefinitionsLazyQuery>;
export type GetLoanSmeDefinitionsQueryResult = Apollo.QueryResult<GetLoanSmeDefinitionsQuery, GetLoanSmeDefinitionsQueryVariables>;
export const UpdateLoanApplicationHeaderByPkDocument = gql`
    mutation UpdateLoanApplicationHeaderByPk($id: uuid!, $_set: loan_application_header_set_input) {
  update_loan_application_header_by_pk(pk_columns: {id: $id}, _set: $_set) {
    ...LoanApplicationHeader
  }
}
    ${LoanApplicationHeaderFragmentDoc}`;
export type UpdateLoanApplicationHeaderByPkMutationFn = Apollo.MutationFunction<UpdateLoanApplicationHeaderByPkMutation, UpdateLoanApplicationHeaderByPkMutationVariables>;
export type UpdateLoanApplicationHeaderByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateLoanApplicationHeaderByPkMutation, UpdateLoanApplicationHeaderByPkMutationVariables>, 'mutation'>;

    export const UpdateLoanApplicationHeaderByPkComponent = (props: UpdateLoanApplicationHeaderByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateLoanApplicationHeaderByPkMutation, UpdateLoanApplicationHeaderByPkMutationVariables> mutation={UpdateLoanApplicationHeaderByPkDocument} {...props} />
    );
    

/**
 * __useUpdateLoanApplicationHeaderByPkMutation__
 *
 * To run a mutation, you first call `useUpdateLoanApplicationHeaderByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoanApplicationHeaderByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoanApplicationHeaderByPkMutation, { data, loading, error }] = useUpdateLoanApplicationHeaderByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateLoanApplicationHeaderByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLoanApplicationHeaderByPkMutation, UpdateLoanApplicationHeaderByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLoanApplicationHeaderByPkMutation, UpdateLoanApplicationHeaderByPkMutationVariables>(UpdateLoanApplicationHeaderByPkDocument, options);
      }
export type UpdateLoanApplicationHeaderByPkMutationHookResult = ReturnType<typeof useUpdateLoanApplicationHeaderByPkMutation>;
export type UpdateLoanApplicationHeaderByPkMutationResult = Apollo.MutationResult<UpdateLoanApplicationHeaderByPkMutation>;
export type UpdateLoanApplicationHeaderByPkMutationOptions = Apollo.BaseMutationOptions<UpdateLoanApplicationHeaderByPkMutation, UpdateLoanApplicationHeaderByPkMutationVariables>;
export const ApproveLoanApplicationDocument = gql`
    mutation ApproveLoanApplication($data: ApproveLoanApplicationInput!) {
  row: approve_loan_application(data: $data) {
    application_header_id
  }
}
    `;
export type ApproveLoanApplicationMutationFn = Apollo.MutationFunction<ApproveLoanApplicationMutation, ApproveLoanApplicationMutationVariables>;
export type ApproveLoanApplicationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ApproveLoanApplicationMutation, ApproveLoanApplicationMutationVariables>, 'mutation'>;

    export const ApproveLoanApplicationComponent = (props: ApproveLoanApplicationComponentProps) => (
      <ApolloReactComponents.Mutation<ApproveLoanApplicationMutation, ApproveLoanApplicationMutationVariables> mutation={ApproveLoanApplicationDocument} {...props} />
    );
    

/**
 * __useApproveLoanApplicationMutation__
 *
 * To run a mutation, you first call `useApproveLoanApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveLoanApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveLoanApplicationMutation, { data, loading, error }] = useApproveLoanApplicationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useApproveLoanApplicationMutation(baseOptions?: Apollo.MutationHookOptions<ApproveLoanApplicationMutation, ApproveLoanApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveLoanApplicationMutation, ApproveLoanApplicationMutationVariables>(ApproveLoanApplicationDocument, options);
      }
export type ApproveLoanApplicationMutationHookResult = ReturnType<typeof useApproveLoanApplicationMutation>;
export type ApproveLoanApplicationMutationResult = Apollo.MutationResult<ApproveLoanApplicationMutation>;
export type ApproveLoanApplicationMutationOptions = Apollo.BaseMutationOptions<ApproveLoanApplicationMutation, ApproveLoanApplicationMutationVariables>;
export const GetLoanApplicationHeadersDocument = gql`
    query GetLoanApplicationHeaders($distinctOn: [loan_application_header_select_column!], $limit: Int, $offset: Int, $orderBy: [loan_application_header_order_by!], $where: loan_application_header_bool_exp) {
  rows: loan_application_header(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...LoanApplicationHeader
  }
}
    ${LoanApplicationHeaderFragmentDoc}`;
export type GetLoanApplicationHeadersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanApplicationHeadersQuery, GetLoanApplicationHeadersQueryVariables>, 'query'>;

    export const GetLoanApplicationHeadersComponent = (props: GetLoanApplicationHeadersComponentProps) => (
      <ApolloReactComponents.Query<GetLoanApplicationHeadersQuery, GetLoanApplicationHeadersQueryVariables> query={GetLoanApplicationHeadersDocument} {...props} />
    );
    

/**
 * __useGetLoanApplicationHeadersQuery__
 *
 * To run a query within a React component, call `useGetLoanApplicationHeadersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanApplicationHeadersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanApplicationHeadersQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLoanApplicationHeadersQuery(baseOptions?: Apollo.QueryHookOptions<GetLoanApplicationHeadersQuery, GetLoanApplicationHeadersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanApplicationHeadersQuery, GetLoanApplicationHeadersQueryVariables>(GetLoanApplicationHeadersDocument, options);
      }
export function useGetLoanApplicationHeadersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanApplicationHeadersQuery, GetLoanApplicationHeadersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanApplicationHeadersQuery, GetLoanApplicationHeadersQueryVariables>(GetLoanApplicationHeadersDocument, options);
        }
export type GetLoanApplicationHeadersQueryHookResult = ReturnType<typeof useGetLoanApplicationHeadersQuery>;
export type GetLoanApplicationHeadersLazyQueryHookResult = ReturnType<typeof useGetLoanApplicationHeadersLazyQuery>;
export type GetLoanApplicationHeadersQueryResult = Apollo.QueryResult<GetLoanApplicationHeadersQuery, GetLoanApplicationHeadersQueryVariables>;
export const GetLoanApplicationHeaderSummaryDocument = gql`
    query GetLoanApplicationHeaderSummary {
  total_count: loan_application_header_aggregate(
    where: {step_id: {_nin: ["draft", "rejected"]}}
  ) {
    aggregate {
      count
    }
  }
  submitted_count: loan_application_header_aggregate(
    where: {step_id: {_eq: "submitted"}}
  ) {
    aggregate {
      count
    }
  }
  document_approved_count: loan_application_header_aggregate(
    where: {step_id: {_eq: "document_approved"}, document_approve_value: {_eq: approved}}
  ) {
    aggregate {
      count
    }
  }
  committee_approved_count: loan_application_header_aggregate(
    where: {step_id: {_eq: "commitee_approved"}, committee_approve_value: {_eq: approved}}
  ) {
    aggregate {
      count
    }
  }
  sme_sign_count: loan_application_header_aggregate(
    where: {step_id: {_eq: "sme_sign"}}
  ) {
    aggregate {
      count
    }
  }
  bank_count: loan_application_header_aggregate(
    where: {step_id: {_eq: "bank_approved"}, bank_approve_value: {_eq: approved}}
  ) {
    aggregate {
      count
    }
  }
  completed_count: loan_application_header_aggregate(
    where: {step_id: {_eq: "completed"}}
  ) {
    aggregate {
      count
    }
  }
  cancelled_count: loan_application_header_aggregate(
    where: {step_id: {_eq: "cancelled"}}
  ) {
    aggregate {
      count
    }
  }
  document_change_request_count: loan_application_header_aggregate(
    where: {step_id: {_eq: "draft"}, document_approve_value: {_eq: change_request}}
  ) {
    aggregate {
      count
    }
  }
  committee_change_request_count: loan_application_header_aggregate(
    where: {step_id: {_eq: "draft"}, committee_approve_value: {_eq: change_request}}
  ) {
    aggregate {
      count
    }
  }
  document_reject_count: loan_application_header_aggregate(
    where: {step_id: {_eq: "rejected"}, document_approve_value: {_eq: disapprove}}
  ) {
    aggregate {
      count
    }
  }
  committee_reject_count: loan_application_header_aggregate(
    where: {step_id: {_eq: "rejected"}, committee_approve_value: {_eq: disapprove}}
  ) {
    aggregate {
      count
    }
  }
  bank_reject_count: loan_application_header_aggregate(
    where: {step_id: {_eq: "rejected"}, bank_approve_value: {_eq: disapprove}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export type GetLoanApplicationHeaderSummaryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanApplicationHeaderSummaryQuery, GetLoanApplicationHeaderSummaryQueryVariables>, 'query'>;

    export const GetLoanApplicationHeaderSummaryComponent = (props: GetLoanApplicationHeaderSummaryComponentProps) => (
      <ApolloReactComponents.Query<GetLoanApplicationHeaderSummaryQuery, GetLoanApplicationHeaderSummaryQueryVariables> query={GetLoanApplicationHeaderSummaryDocument} {...props} />
    );
    

/**
 * __useGetLoanApplicationHeaderSummaryQuery__
 *
 * To run a query within a React component, call `useGetLoanApplicationHeaderSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanApplicationHeaderSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanApplicationHeaderSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoanApplicationHeaderSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetLoanApplicationHeaderSummaryQuery, GetLoanApplicationHeaderSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanApplicationHeaderSummaryQuery, GetLoanApplicationHeaderSummaryQueryVariables>(GetLoanApplicationHeaderSummaryDocument, options);
      }
export function useGetLoanApplicationHeaderSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanApplicationHeaderSummaryQuery, GetLoanApplicationHeaderSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanApplicationHeaderSummaryQuery, GetLoanApplicationHeaderSummaryQueryVariables>(GetLoanApplicationHeaderSummaryDocument, options);
        }
export type GetLoanApplicationHeaderSummaryQueryHookResult = ReturnType<typeof useGetLoanApplicationHeaderSummaryQuery>;
export type GetLoanApplicationHeaderSummaryLazyQueryHookResult = ReturnType<typeof useGetLoanApplicationHeaderSummaryLazyQuery>;
export type GetLoanApplicationHeaderSummaryQueryResult = Apollo.QueryResult<GetLoanApplicationHeaderSummaryQuery, GetLoanApplicationHeaderSummaryQueryVariables>;
export const GetLoanApplicationHeaderByPkDocument = gql`
    query GetLoanApplicationHeaderByPk($id: uuid!) {
  entity: loan_application_header_by_pk(id: $id) {
    ...LoanApplicationHeaderFull
  }
  pending_transfer_aggregate: loan_application_organization_transfer_aggregate(
    where: {application_header_id: {_eq: $id}, status_value: {_eq: pending}}
  ) {
    aggregate {
      count
    }
    rows: nodes {
      id
      application_header_id
      status_value
      from_organization {
        name
      }
      from_organization_id
      to_organization {
        name
      }
      to_organization_id
    }
  }
}
    ${LoanApplicationHeaderFullFragmentDoc}`;
export type GetLoanApplicationHeaderByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanApplicationHeaderByPkQuery, GetLoanApplicationHeaderByPkQueryVariables>, 'query'> & ({ variables: GetLoanApplicationHeaderByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLoanApplicationHeaderByPkComponent = (props: GetLoanApplicationHeaderByPkComponentProps) => (
      <ApolloReactComponents.Query<GetLoanApplicationHeaderByPkQuery, GetLoanApplicationHeaderByPkQueryVariables> query={GetLoanApplicationHeaderByPkDocument} {...props} />
    );
    

/**
 * __useGetLoanApplicationHeaderByPkQuery__
 *
 * To run a query within a React component, call `useGetLoanApplicationHeaderByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanApplicationHeaderByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanApplicationHeaderByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLoanApplicationHeaderByPkQuery(baseOptions: Apollo.QueryHookOptions<GetLoanApplicationHeaderByPkQuery, GetLoanApplicationHeaderByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanApplicationHeaderByPkQuery, GetLoanApplicationHeaderByPkQueryVariables>(GetLoanApplicationHeaderByPkDocument, options);
      }
export function useGetLoanApplicationHeaderByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanApplicationHeaderByPkQuery, GetLoanApplicationHeaderByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanApplicationHeaderByPkQuery, GetLoanApplicationHeaderByPkQueryVariables>(GetLoanApplicationHeaderByPkDocument, options);
        }
export type GetLoanApplicationHeaderByPkQueryHookResult = ReturnType<typeof useGetLoanApplicationHeaderByPkQuery>;
export type GetLoanApplicationHeaderByPkLazyQueryHookResult = ReturnType<typeof useGetLoanApplicationHeaderByPkLazyQuery>;
export type GetLoanApplicationHeaderByPkQueryResult = Apollo.QueryResult<GetLoanApplicationHeaderByPkQuery, GetLoanApplicationHeaderByPkQueryVariables>;
export const UpdateLoanApplicationOrganizationTransferByPkDocument = gql`
    mutation UpdateLoanApplicationOrganizationTransferByPk($id: uuid!, $_set: loan_application_organization_transfer_set_input) {
  update_loan_application_organization_transfer_by_pk(
    pk_columns: {id: $id}
    _set: $_set
  ) {
    ...LoanApplicationOrganizationTransfer
  }
}
    ${LoanApplicationOrganizationTransferFragmentDoc}`;
export type UpdateLoanApplicationOrganizationTransferByPkMutationFn = Apollo.MutationFunction<UpdateLoanApplicationOrganizationTransferByPkMutation, UpdateLoanApplicationOrganizationTransferByPkMutationVariables>;
export type UpdateLoanApplicationOrganizationTransferByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateLoanApplicationOrganizationTransferByPkMutation, UpdateLoanApplicationOrganizationTransferByPkMutationVariables>, 'mutation'>;

    export const UpdateLoanApplicationOrganizationTransferByPkComponent = (props: UpdateLoanApplicationOrganizationTransferByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateLoanApplicationOrganizationTransferByPkMutation, UpdateLoanApplicationOrganizationTransferByPkMutationVariables> mutation={UpdateLoanApplicationOrganizationTransferByPkDocument} {...props} />
    );
    

/**
 * __useUpdateLoanApplicationOrganizationTransferByPkMutation__
 *
 * To run a mutation, you first call `useUpdateLoanApplicationOrganizationTransferByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoanApplicationOrganizationTransferByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoanApplicationOrganizationTransferByPkMutation, { data, loading, error }] = useUpdateLoanApplicationOrganizationTransferByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateLoanApplicationOrganizationTransferByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLoanApplicationOrganizationTransferByPkMutation, UpdateLoanApplicationOrganizationTransferByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLoanApplicationOrganizationTransferByPkMutation, UpdateLoanApplicationOrganizationTransferByPkMutationVariables>(UpdateLoanApplicationOrganizationTransferByPkDocument, options);
      }
export type UpdateLoanApplicationOrganizationTransferByPkMutationHookResult = ReturnType<typeof useUpdateLoanApplicationOrganizationTransferByPkMutation>;
export type UpdateLoanApplicationOrganizationTransferByPkMutationResult = Apollo.MutationResult<UpdateLoanApplicationOrganizationTransferByPkMutation>;
export type UpdateLoanApplicationOrganizationTransferByPkMutationOptions = Apollo.BaseMutationOptions<UpdateLoanApplicationOrganizationTransferByPkMutation, UpdateLoanApplicationOrganizationTransferByPkMutationVariables>;
export const LoanApplicationOrganizationTransferApprovalByPkDocument = gql`
    mutation LoanApplicationOrganizationTransferApprovalByPk($id: uuid!, $_set: loan_application_organization_transfer_set_input, $application_header_id: uuid!, $to_organization_id: uuid!) {
  update_loan_application_organization_transfer_by_pk(
    pk_columns: {id: $id}
    _set: $_set
  ) {
    ...LoanApplicationOrganizationTransfer
  }
  update_loan_application_header_by_pk(
    pk_columns: {id: $application_header_id}
    _set: {organization_id: $to_organization_id}
  ) {
    id
  }
}
    ${LoanApplicationOrganizationTransferFragmentDoc}`;
export type LoanApplicationOrganizationTransferApprovalByPkMutationFn = Apollo.MutationFunction<LoanApplicationOrganizationTransferApprovalByPkMutation, LoanApplicationOrganizationTransferApprovalByPkMutationVariables>;
export type LoanApplicationOrganizationTransferApprovalByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoanApplicationOrganizationTransferApprovalByPkMutation, LoanApplicationOrganizationTransferApprovalByPkMutationVariables>, 'mutation'>;

    export const LoanApplicationOrganizationTransferApprovalByPkComponent = (props: LoanApplicationOrganizationTransferApprovalByPkComponentProps) => (
      <ApolloReactComponents.Mutation<LoanApplicationOrganizationTransferApprovalByPkMutation, LoanApplicationOrganizationTransferApprovalByPkMutationVariables> mutation={LoanApplicationOrganizationTransferApprovalByPkDocument} {...props} />
    );
    

/**
 * __useLoanApplicationOrganizationTransferApprovalByPkMutation__
 *
 * To run a mutation, you first call `useLoanApplicationOrganizationTransferApprovalByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoanApplicationOrganizationTransferApprovalByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loanApplicationOrganizationTransferApprovalByPkMutation, { data, loading, error }] = useLoanApplicationOrganizationTransferApprovalByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *      application_header_id: // value for 'application_header_id'
 *      to_organization_id: // value for 'to_organization_id'
 *   },
 * });
 */
export function useLoanApplicationOrganizationTransferApprovalByPkMutation(baseOptions?: Apollo.MutationHookOptions<LoanApplicationOrganizationTransferApprovalByPkMutation, LoanApplicationOrganizationTransferApprovalByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoanApplicationOrganizationTransferApprovalByPkMutation, LoanApplicationOrganizationTransferApprovalByPkMutationVariables>(LoanApplicationOrganizationTransferApprovalByPkDocument, options);
      }
export type LoanApplicationOrganizationTransferApprovalByPkMutationHookResult = ReturnType<typeof useLoanApplicationOrganizationTransferApprovalByPkMutation>;
export type LoanApplicationOrganizationTransferApprovalByPkMutationResult = Apollo.MutationResult<LoanApplicationOrganizationTransferApprovalByPkMutation>;
export type LoanApplicationOrganizationTransferApprovalByPkMutationOptions = Apollo.BaseMutationOptions<LoanApplicationOrganizationTransferApprovalByPkMutation, LoanApplicationOrganizationTransferApprovalByPkMutationVariables>;
export const InsertLoanApplicationOrganizationTransferOneDocument = gql`
    mutation InsertLoanApplicationOrganizationTransferOne($object: loan_application_organization_transfer_insert_input!) {
  insert_loan_application_organization_transfer_one(object: $object) {
    ...LoanApplicationOrganizationTransfer
  }
}
    ${LoanApplicationOrganizationTransferFragmentDoc}`;
export type InsertLoanApplicationOrganizationTransferOneMutationFn = Apollo.MutationFunction<InsertLoanApplicationOrganizationTransferOneMutation, InsertLoanApplicationOrganizationTransferOneMutationVariables>;
export type InsertLoanApplicationOrganizationTransferOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertLoanApplicationOrganizationTransferOneMutation, InsertLoanApplicationOrganizationTransferOneMutationVariables>, 'mutation'>;

    export const InsertLoanApplicationOrganizationTransferOneComponent = (props: InsertLoanApplicationOrganizationTransferOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertLoanApplicationOrganizationTransferOneMutation, InsertLoanApplicationOrganizationTransferOneMutationVariables> mutation={InsertLoanApplicationOrganizationTransferOneDocument} {...props} />
    );
    

/**
 * __useInsertLoanApplicationOrganizationTransferOneMutation__
 *
 * To run a mutation, you first call `useInsertLoanApplicationOrganizationTransferOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLoanApplicationOrganizationTransferOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLoanApplicationOrganizationTransferOneMutation, { data, loading, error }] = useInsertLoanApplicationOrganizationTransferOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertLoanApplicationOrganizationTransferOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertLoanApplicationOrganizationTransferOneMutation, InsertLoanApplicationOrganizationTransferOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLoanApplicationOrganizationTransferOneMutation, InsertLoanApplicationOrganizationTransferOneMutationVariables>(InsertLoanApplicationOrganizationTransferOneDocument, options);
      }
export type InsertLoanApplicationOrganizationTransferOneMutationHookResult = ReturnType<typeof useInsertLoanApplicationOrganizationTransferOneMutation>;
export type InsertLoanApplicationOrganizationTransferOneMutationResult = Apollo.MutationResult<InsertLoanApplicationOrganizationTransferOneMutation>;
export type InsertLoanApplicationOrganizationTransferOneMutationOptions = Apollo.BaseMutationOptions<InsertLoanApplicationOrganizationTransferOneMutation, InsertLoanApplicationOrganizationTransferOneMutationVariables>;
export const ApproveLoanApplicationOrganizationTransferDocument = gql`
    mutation ApproveLoanApplicationOrganizationTransfer($data: ApproveLoanApplicationOrganizationTransferInput!) {
  response: approve_loan_application_organization_transfer(data: $data) {
    organization_transfer_id
  }
}
    `;
export type ApproveLoanApplicationOrganizationTransferMutationFn = Apollo.MutationFunction<ApproveLoanApplicationOrganizationTransferMutation, ApproveLoanApplicationOrganizationTransferMutationVariables>;
export type ApproveLoanApplicationOrganizationTransferComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ApproveLoanApplicationOrganizationTransferMutation, ApproveLoanApplicationOrganizationTransferMutationVariables>, 'mutation'>;

    export const ApproveLoanApplicationOrganizationTransferComponent = (props: ApproveLoanApplicationOrganizationTransferComponentProps) => (
      <ApolloReactComponents.Mutation<ApproveLoanApplicationOrganizationTransferMutation, ApproveLoanApplicationOrganizationTransferMutationVariables> mutation={ApproveLoanApplicationOrganizationTransferDocument} {...props} />
    );
    

/**
 * __useApproveLoanApplicationOrganizationTransferMutation__
 *
 * To run a mutation, you first call `useApproveLoanApplicationOrganizationTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveLoanApplicationOrganizationTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveLoanApplicationOrganizationTransferMutation, { data, loading, error }] = useApproveLoanApplicationOrganizationTransferMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useApproveLoanApplicationOrganizationTransferMutation(baseOptions?: Apollo.MutationHookOptions<ApproveLoanApplicationOrganizationTransferMutation, ApproveLoanApplicationOrganizationTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveLoanApplicationOrganizationTransferMutation, ApproveLoanApplicationOrganizationTransferMutationVariables>(ApproveLoanApplicationOrganizationTransferDocument, options);
      }
export type ApproveLoanApplicationOrganizationTransferMutationHookResult = ReturnType<typeof useApproveLoanApplicationOrganizationTransferMutation>;
export type ApproveLoanApplicationOrganizationTransferMutationResult = Apollo.MutationResult<ApproveLoanApplicationOrganizationTransferMutation>;
export type ApproveLoanApplicationOrganizationTransferMutationOptions = Apollo.BaseMutationOptions<ApproveLoanApplicationOrganizationTransferMutation, ApproveLoanApplicationOrganizationTransferMutationVariables>;
export const GetLoanApplicationOrganizationTransfersDocument = gql`
    query GetLoanApplicationOrganizationTransfers($distinctOn: [loan_application_organization_transfer_select_column!], $limit: Int, $offset: Int, $orderBy: [loan_application_organization_transfer_order_by!], $where: loan_application_organization_transfer_bool_exp) {
  rows: loan_application_organization_transfer(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...LoanApplicationOrganizationTransfer
  }
}
    ${LoanApplicationOrganizationTransferFragmentDoc}`;
export type GetLoanApplicationOrganizationTransfersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanApplicationOrganizationTransfersQuery, GetLoanApplicationOrganizationTransfersQueryVariables>, 'query'>;

    export const GetLoanApplicationOrganizationTransfersComponent = (props: GetLoanApplicationOrganizationTransfersComponentProps) => (
      <ApolloReactComponents.Query<GetLoanApplicationOrganizationTransfersQuery, GetLoanApplicationOrganizationTransfersQueryVariables> query={GetLoanApplicationOrganizationTransfersDocument} {...props} />
    );
    

/**
 * __useGetLoanApplicationOrganizationTransfersQuery__
 *
 * To run a query within a React component, call `useGetLoanApplicationOrganizationTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanApplicationOrganizationTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanApplicationOrganizationTransfersQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLoanApplicationOrganizationTransfersQuery(baseOptions?: Apollo.QueryHookOptions<GetLoanApplicationOrganizationTransfersQuery, GetLoanApplicationOrganizationTransfersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanApplicationOrganizationTransfersQuery, GetLoanApplicationOrganizationTransfersQueryVariables>(GetLoanApplicationOrganizationTransfersDocument, options);
      }
export function useGetLoanApplicationOrganizationTransfersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanApplicationOrganizationTransfersQuery, GetLoanApplicationOrganizationTransfersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanApplicationOrganizationTransfersQuery, GetLoanApplicationOrganizationTransfersQueryVariables>(GetLoanApplicationOrganizationTransfersDocument, options);
        }
export type GetLoanApplicationOrganizationTransfersQueryHookResult = ReturnType<typeof useGetLoanApplicationOrganizationTransfersQuery>;
export type GetLoanApplicationOrganizationTransfersLazyQueryHookResult = ReturnType<typeof useGetLoanApplicationOrganizationTransfersLazyQuery>;
export type GetLoanApplicationOrganizationTransfersQueryResult = Apollo.QueryResult<GetLoanApplicationOrganizationTransfersQuery, GetLoanApplicationOrganizationTransfersQueryVariables>;
export const GetLoanApplicationOrganizationTransferSummaryDocument = gql`
    query GetLoanApplicationOrganizationTransferSummary {
  total_count: loan_application_organization_transfer_aggregate {
    aggregate {
      count
    }
  }
  pending_count: loan_application_organization_transfer_aggregate(
    where: {status_value: {_eq: pending}}
  ) {
    aggregate {
      count
    }
  }
  approved_count: loan_application_organization_transfer_aggregate(
    where: {status_value: {_eq: approved}}
  ) {
    aggregate {
      count
    }
  }
  rejected_count: loan_application_organization_transfer_aggregate(
    where: {status_value: {_eq: rejected}}
  ) {
    aggregate {
      count
    }
  }
  cancelled_count: loan_application_organization_transfer_aggregate(
    where: {status_value: {_eq: cancelled}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export type GetLoanApplicationOrganizationTransferSummaryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanApplicationOrganizationTransferSummaryQuery, GetLoanApplicationOrganizationTransferSummaryQueryVariables>, 'query'>;

    export const GetLoanApplicationOrganizationTransferSummaryComponent = (props: GetLoanApplicationOrganizationTransferSummaryComponentProps) => (
      <ApolloReactComponents.Query<GetLoanApplicationOrganizationTransferSummaryQuery, GetLoanApplicationOrganizationTransferSummaryQueryVariables> query={GetLoanApplicationOrganizationTransferSummaryDocument} {...props} />
    );
    

/**
 * __useGetLoanApplicationOrganizationTransferSummaryQuery__
 *
 * To run a query within a React component, call `useGetLoanApplicationOrganizationTransferSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanApplicationOrganizationTransferSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanApplicationOrganizationTransferSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoanApplicationOrganizationTransferSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetLoanApplicationOrganizationTransferSummaryQuery, GetLoanApplicationOrganizationTransferSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanApplicationOrganizationTransferSummaryQuery, GetLoanApplicationOrganizationTransferSummaryQueryVariables>(GetLoanApplicationOrganizationTransferSummaryDocument, options);
      }
export function useGetLoanApplicationOrganizationTransferSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanApplicationOrganizationTransferSummaryQuery, GetLoanApplicationOrganizationTransferSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanApplicationOrganizationTransferSummaryQuery, GetLoanApplicationOrganizationTransferSummaryQueryVariables>(GetLoanApplicationOrganizationTransferSummaryDocument, options);
        }
export type GetLoanApplicationOrganizationTransferSummaryQueryHookResult = ReturnType<typeof useGetLoanApplicationOrganizationTransferSummaryQuery>;
export type GetLoanApplicationOrganizationTransferSummaryLazyQueryHookResult = ReturnType<typeof useGetLoanApplicationOrganizationTransferSummaryLazyQuery>;
export type GetLoanApplicationOrganizationTransferSummaryQueryResult = Apollo.QueryResult<GetLoanApplicationOrganizationTransferSummaryQuery, GetLoanApplicationOrganizationTransferSummaryQueryVariables>;
export const GetLoanApplicationOrganizationTransferByPkDocument = gql`
    query GetLoanApplicationOrganizationTransferByPk($id: uuid!) {
  entity: loan_application_organization_transfer_by_pk(id: $id) {
    ...LoanApplicationOrganizationTransferFull
  }
}
    ${LoanApplicationOrganizationTransferFullFragmentDoc}`;
export type GetLoanApplicationOrganizationTransferByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanApplicationOrganizationTransferByPkQuery, GetLoanApplicationOrganizationTransferByPkQueryVariables>, 'query'> & ({ variables: GetLoanApplicationOrganizationTransferByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLoanApplicationOrganizationTransferByPkComponent = (props: GetLoanApplicationOrganizationTransferByPkComponentProps) => (
      <ApolloReactComponents.Query<GetLoanApplicationOrganizationTransferByPkQuery, GetLoanApplicationOrganizationTransferByPkQueryVariables> query={GetLoanApplicationOrganizationTransferByPkDocument} {...props} />
    );
    

/**
 * __useGetLoanApplicationOrganizationTransferByPkQuery__
 *
 * To run a query within a React component, call `useGetLoanApplicationOrganizationTransferByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanApplicationOrganizationTransferByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanApplicationOrganizationTransferByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLoanApplicationOrganizationTransferByPkQuery(baseOptions: Apollo.QueryHookOptions<GetLoanApplicationOrganizationTransferByPkQuery, GetLoanApplicationOrganizationTransferByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanApplicationOrganizationTransferByPkQuery, GetLoanApplicationOrganizationTransferByPkQueryVariables>(GetLoanApplicationOrganizationTransferByPkDocument, options);
      }
export function useGetLoanApplicationOrganizationTransferByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanApplicationOrganizationTransferByPkQuery, GetLoanApplicationOrganizationTransferByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanApplicationOrganizationTransferByPkQuery, GetLoanApplicationOrganizationTransferByPkQueryVariables>(GetLoanApplicationOrganizationTransferByPkDocument, options);
        }
export type GetLoanApplicationOrganizationTransferByPkQueryHookResult = ReturnType<typeof useGetLoanApplicationOrganizationTransferByPkQuery>;
export type GetLoanApplicationOrganizationTransferByPkLazyQueryHookResult = ReturnType<typeof useGetLoanApplicationOrganizationTransferByPkLazyQuery>;
export type GetLoanApplicationOrganizationTransferByPkQueryResult = Apollo.QueryResult<GetLoanApplicationOrganizationTransferByPkQuery, GetLoanApplicationOrganizationTransferByPkQueryVariables>;
export const UpdateLoanProgramByPkDocument = gql`
    mutation UpdateLoanProgramByPk($id: uuid!, $_set: loan_program_set_input) {
  update_loan_program_by_pk(pk_columns: {id: $id}, _set: $_set) {
    ...LoanProgram
  }
}
    ${LoanProgramFragmentDoc}`;
export type UpdateLoanProgramByPkMutationFn = Apollo.MutationFunction<UpdateLoanProgramByPkMutation, UpdateLoanProgramByPkMutationVariables>;
export type UpdateLoanProgramByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateLoanProgramByPkMutation, UpdateLoanProgramByPkMutationVariables>, 'mutation'>;

    export const UpdateLoanProgramByPkComponent = (props: UpdateLoanProgramByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateLoanProgramByPkMutation, UpdateLoanProgramByPkMutationVariables> mutation={UpdateLoanProgramByPkDocument} {...props} />
    );
    

/**
 * __useUpdateLoanProgramByPkMutation__
 *
 * To run a mutation, you first call `useUpdateLoanProgramByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoanProgramByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoanProgramByPkMutation, { data, loading, error }] = useUpdateLoanProgramByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateLoanProgramByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLoanProgramByPkMutation, UpdateLoanProgramByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLoanProgramByPkMutation, UpdateLoanProgramByPkMutationVariables>(UpdateLoanProgramByPkDocument, options);
      }
export type UpdateLoanProgramByPkMutationHookResult = ReturnType<typeof useUpdateLoanProgramByPkMutation>;
export type UpdateLoanProgramByPkMutationResult = Apollo.MutationResult<UpdateLoanProgramByPkMutation>;
export type UpdateLoanProgramByPkMutationOptions = Apollo.BaseMutationOptions<UpdateLoanProgramByPkMutation, UpdateLoanProgramByPkMutationVariables>;
export const GetLoanProgramsDocument = gql`
    query GetLoanPrograms($distinctOn: [loan_program_select_column!], $limit: Int, $offset: Int, $orderBy: [loan_program_order_by!], $where: loan_program_bool_exp) {
  rows: loan_program(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...LoanProgram
  }
}
    ${LoanProgramFragmentDoc}`;
export type GetLoanProgramsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanProgramsQuery, GetLoanProgramsQueryVariables>, 'query'>;

    export const GetLoanProgramsComponent = (props: GetLoanProgramsComponentProps) => (
      <ApolloReactComponents.Query<GetLoanProgramsQuery, GetLoanProgramsQueryVariables> query={GetLoanProgramsDocument} {...props} />
    );
    

/**
 * __useGetLoanProgramsQuery__
 *
 * To run a query within a React component, call `useGetLoanProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanProgramsQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLoanProgramsQuery(baseOptions?: Apollo.QueryHookOptions<GetLoanProgramsQuery, GetLoanProgramsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanProgramsQuery, GetLoanProgramsQueryVariables>(GetLoanProgramsDocument, options);
      }
export function useGetLoanProgramsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanProgramsQuery, GetLoanProgramsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanProgramsQuery, GetLoanProgramsQueryVariables>(GetLoanProgramsDocument, options);
        }
export type GetLoanProgramsQueryHookResult = ReturnType<typeof useGetLoanProgramsQuery>;
export type GetLoanProgramsLazyQueryHookResult = ReturnType<typeof useGetLoanProgramsLazyQuery>;
export type GetLoanProgramsQueryResult = Apollo.QueryResult<GetLoanProgramsQuery, GetLoanProgramsQueryVariables>;
export const GetLoanProgramByPkDocument = gql`
    query GetLoanProgramByPk($id: uuid!) {
  entity: loan_program_by_pk(id: $id) {
    ...LoanProgram
  }
}
    ${LoanProgramFragmentDoc}`;
export type GetLoanProgramByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanProgramByPkQuery, GetLoanProgramByPkQueryVariables>, 'query'> & ({ variables: GetLoanProgramByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLoanProgramByPkComponent = (props: GetLoanProgramByPkComponentProps) => (
      <ApolloReactComponents.Query<GetLoanProgramByPkQuery, GetLoanProgramByPkQueryVariables> query={GetLoanProgramByPkDocument} {...props} />
    );
    

/**
 * __useGetLoanProgramByPkQuery__
 *
 * To run a query within a React component, call `useGetLoanProgramByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanProgramByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanProgramByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLoanProgramByPkQuery(baseOptions: Apollo.QueryHookOptions<GetLoanProgramByPkQuery, GetLoanProgramByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanProgramByPkQuery, GetLoanProgramByPkQueryVariables>(GetLoanProgramByPkDocument, options);
      }
export function useGetLoanProgramByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanProgramByPkQuery, GetLoanProgramByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanProgramByPkQuery, GetLoanProgramByPkQueryVariables>(GetLoanProgramByPkDocument, options);
        }
export type GetLoanProgramByPkQueryHookResult = ReturnType<typeof useGetLoanProgramByPkQuery>;
export type GetLoanProgramByPkLazyQueryHookResult = ReturnType<typeof useGetLoanProgramByPkLazyQuery>;
export type GetLoanProgramByPkQueryResult = Apollo.QueryResult<GetLoanProgramByPkQuery, GetLoanProgramByPkQueryVariables>;
export const InsertLoanSourceOneDocument = gql`
    mutation InsertLoanSourceOne($object: loan_source_loan_insert_input!) {
  row: insert_loan_source_loan_one(object: $object) {
    ...LoanSource
  }
}
    ${LoanSourceFragmentDoc}`;
export type InsertLoanSourceOneMutationFn = Apollo.MutationFunction<InsertLoanSourceOneMutation, InsertLoanSourceOneMutationVariables>;
export type InsertLoanSourceOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertLoanSourceOneMutation, InsertLoanSourceOneMutationVariables>, 'mutation'>;

    export const InsertLoanSourceOneComponent = (props: InsertLoanSourceOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertLoanSourceOneMutation, InsertLoanSourceOneMutationVariables> mutation={InsertLoanSourceOneDocument} {...props} />
    );
    

/**
 * __useInsertLoanSourceOneMutation__
 *
 * To run a mutation, you first call `useInsertLoanSourceOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLoanSourceOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLoanSourceOneMutation, { data, loading, error }] = useInsertLoanSourceOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertLoanSourceOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertLoanSourceOneMutation, InsertLoanSourceOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLoanSourceOneMutation, InsertLoanSourceOneMutationVariables>(InsertLoanSourceOneDocument, options);
      }
export type InsertLoanSourceOneMutationHookResult = ReturnType<typeof useInsertLoanSourceOneMutation>;
export type InsertLoanSourceOneMutationResult = Apollo.MutationResult<InsertLoanSourceOneMutation>;
export type InsertLoanSourceOneMutationOptions = Apollo.BaseMutationOptions<InsertLoanSourceOneMutation, InsertLoanSourceOneMutationVariables>;
export const UpdateLoanSourceByPkDocument = gql`
    mutation UpdateLoanSourceByPk($id: uuid!, $_set: loan_source_loan_set_input, $insertedObjectiveIds: [uuid!]!, $insertedObjectiveData: [loan_source_objective_insert_input!]!, $insertedBusinessTypeIds: [String!]!, $insertedBusinessTypeData: [loan_source_loan_business_type_insert_input!]!) {
  row: update_loan_source_loan_by_pk(pk_columns: {id: $id}, _set: $_set) {
    ...LoanSource
  }
  deleted_objective_rows: delete_loan_source_objective(
    where: {id: {_nin: $insertedObjectiveIds}, loan_id: {_eq: $id}}
  ) {
    affected_rows
  }
  inserted_objective_rows: insert_loan_source_objective(
    objects: $insertedObjectiveData
    on_conflict: {constraint: objective_pkey, update_columns: [name, sequence]}
  ) {
    affected_rows
    returning {
      id
      loan_id
      name
      sequence
    }
  }
  deleted_business_type_rows: delete_loan_source_loan_business_type(
    where: {business_type_id: {_nin: $insertedBusinessTypeIds}, loan_id: {_eq: $id}}
  ) {
    affected_rows
  }
  inserted_business_type_rows: insert_loan_source_loan_business_type(
    objects: $insertedBusinessTypeData
    on_conflict: {constraint: loan_business_type_pkey, update_columns: [loan_id, business_type_id]}
  ) {
    affected_rows
    returning {
      business_type_id
      loan_id
    }
  }
}
    ${LoanSourceFragmentDoc}`;
export type UpdateLoanSourceByPkMutationFn = Apollo.MutationFunction<UpdateLoanSourceByPkMutation, UpdateLoanSourceByPkMutationVariables>;
export type UpdateLoanSourceByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateLoanSourceByPkMutation, UpdateLoanSourceByPkMutationVariables>, 'mutation'>;

    export const UpdateLoanSourceByPkComponent = (props: UpdateLoanSourceByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateLoanSourceByPkMutation, UpdateLoanSourceByPkMutationVariables> mutation={UpdateLoanSourceByPkDocument} {...props} />
    );
    

/**
 * __useUpdateLoanSourceByPkMutation__
 *
 * To run a mutation, you first call `useUpdateLoanSourceByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoanSourceByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoanSourceByPkMutation, { data, loading, error }] = useUpdateLoanSourceByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *      insertedObjectiveIds: // value for 'insertedObjectiveIds'
 *      insertedObjectiveData: // value for 'insertedObjectiveData'
 *      insertedBusinessTypeIds: // value for 'insertedBusinessTypeIds'
 *      insertedBusinessTypeData: // value for 'insertedBusinessTypeData'
 *   },
 * });
 */
export function useUpdateLoanSourceByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLoanSourceByPkMutation, UpdateLoanSourceByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLoanSourceByPkMutation, UpdateLoanSourceByPkMutationVariables>(UpdateLoanSourceByPkDocument, options);
      }
export type UpdateLoanSourceByPkMutationHookResult = ReturnType<typeof useUpdateLoanSourceByPkMutation>;
export type UpdateLoanSourceByPkMutationResult = Apollo.MutationResult<UpdateLoanSourceByPkMutation>;
export type UpdateLoanSourceByPkMutationOptions = Apollo.BaseMutationOptions<UpdateLoanSourceByPkMutation, UpdateLoanSourceByPkMutationVariables>;
export const DeleteLoanSourceByPkDocument = gql`
    mutation DeleteLoanSourceByPk($id: uuid!) {
  row: delete_loan_source_loan_by_pk(id: $id) {
    ...LoanSource
  }
}
    ${LoanSourceFragmentDoc}`;
export type DeleteLoanSourceByPkMutationFn = Apollo.MutationFunction<DeleteLoanSourceByPkMutation, DeleteLoanSourceByPkMutationVariables>;
export type DeleteLoanSourceByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteLoanSourceByPkMutation, DeleteLoanSourceByPkMutationVariables>, 'mutation'>;

    export const DeleteLoanSourceByPkComponent = (props: DeleteLoanSourceByPkComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteLoanSourceByPkMutation, DeleteLoanSourceByPkMutationVariables> mutation={DeleteLoanSourceByPkDocument} {...props} />
    );
    

/**
 * __useDeleteLoanSourceByPkMutation__
 *
 * To run a mutation, you first call `useDeleteLoanSourceByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLoanSourceByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLoanSourceByPkMutation, { data, loading, error }] = useDeleteLoanSourceByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLoanSourceByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLoanSourceByPkMutation, DeleteLoanSourceByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLoanSourceByPkMutation, DeleteLoanSourceByPkMutationVariables>(DeleteLoanSourceByPkDocument, options);
      }
export type DeleteLoanSourceByPkMutationHookResult = ReturnType<typeof useDeleteLoanSourceByPkMutation>;
export type DeleteLoanSourceByPkMutationResult = Apollo.MutationResult<DeleteLoanSourceByPkMutation>;
export type DeleteLoanSourceByPkMutationOptions = Apollo.BaseMutationOptions<DeleteLoanSourceByPkMutation, DeleteLoanSourceByPkMutationVariables>;
export const InsertLoanSourceAttachmentDocument = gql`
    mutation InsertLoanSourceAttachment($object: loan_source_loan_attachment_insert_input!) {
  insert_loan_source_loan_attachment_one(object: $object) {
    ...LoanSourceAttachment
  }
}
    ${LoanSourceAttachmentFragmentDoc}`;
export type InsertLoanSourceAttachmentMutationFn = Apollo.MutationFunction<InsertLoanSourceAttachmentMutation, InsertLoanSourceAttachmentMutationVariables>;
export type InsertLoanSourceAttachmentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertLoanSourceAttachmentMutation, InsertLoanSourceAttachmentMutationVariables>, 'mutation'>;

    export const InsertLoanSourceAttachmentComponent = (props: InsertLoanSourceAttachmentComponentProps) => (
      <ApolloReactComponents.Mutation<InsertLoanSourceAttachmentMutation, InsertLoanSourceAttachmentMutationVariables> mutation={InsertLoanSourceAttachmentDocument} {...props} />
    );
    

/**
 * __useInsertLoanSourceAttachmentMutation__
 *
 * To run a mutation, you first call `useInsertLoanSourceAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLoanSourceAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLoanSourceAttachmentMutation, { data, loading, error }] = useInsertLoanSourceAttachmentMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertLoanSourceAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<InsertLoanSourceAttachmentMutation, InsertLoanSourceAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLoanSourceAttachmentMutation, InsertLoanSourceAttachmentMutationVariables>(InsertLoanSourceAttachmentDocument, options);
      }
export type InsertLoanSourceAttachmentMutationHookResult = ReturnType<typeof useInsertLoanSourceAttachmentMutation>;
export type InsertLoanSourceAttachmentMutationResult = Apollo.MutationResult<InsertLoanSourceAttachmentMutation>;
export type InsertLoanSourceAttachmentMutationOptions = Apollo.BaseMutationOptions<InsertLoanSourceAttachmentMutation, InsertLoanSourceAttachmentMutationVariables>;
export const InsertLoanSourceInstitutionOneDocument = gql`
    mutation InsertLoanSourceInstitutionOne($object: loan_source_financial_institution_insert_input!) {
  row: insert_loan_source_financial_institution_one(object: $object) {
    ...LoanSourceInstitution
  }
}
    ${LoanSourceInstitutionFragmentDoc}`;
export type InsertLoanSourceInstitutionOneMutationFn = Apollo.MutationFunction<InsertLoanSourceInstitutionOneMutation, InsertLoanSourceInstitutionOneMutationVariables>;
export type InsertLoanSourceInstitutionOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertLoanSourceInstitutionOneMutation, InsertLoanSourceInstitutionOneMutationVariables>, 'mutation'>;

    export const InsertLoanSourceInstitutionOneComponent = (props: InsertLoanSourceInstitutionOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertLoanSourceInstitutionOneMutation, InsertLoanSourceInstitutionOneMutationVariables> mutation={InsertLoanSourceInstitutionOneDocument} {...props} />
    );
    

/**
 * __useInsertLoanSourceInstitutionOneMutation__
 *
 * To run a mutation, you first call `useInsertLoanSourceInstitutionOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLoanSourceInstitutionOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLoanSourceInstitutionOneMutation, { data, loading, error }] = useInsertLoanSourceInstitutionOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertLoanSourceInstitutionOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertLoanSourceInstitutionOneMutation, InsertLoanSourceInstitutionOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLoanSourceInstitutionOneMutation, InsertLoanSourceInstitutionOneMutationVariables>(InsertLoanSourceInstitutionOneDocument, options);
      }
export type InsertLoanSourceInstitutionOneMutationHookResult = ReturnType<typeof useInsertLoanSourceInstitutionOneMutation>;
export type InsertLoanSourceInstitutionOneMutationResult = Apollo.MutationResult<InsertLoanSourceInstitutionOneMutation>;
export type InsertLoanSourceInstitutionOneMutationOptions = Apollo.BaseMutationOptions<InsertLoanSourceInstitutionOneMutation, InsertLoanSourceInstitutionOneMutationVariables>;
export const UpdateLoanSourceInstitutionByPkDocument = gql`
    mutation UpdateLoanSourceInstitutionByPk($id: String!, $_set: loan_source_financial_institution_set_input) {
  row: update_loan_source_financial_institution_by_pk(
    pk_columns: {id: $id}
    _set: $_set
  ) {
    ...LoanSourceInstitution
  }
}
    ${LoanSourceInstitutionFragmentDoc}`;
export type UpdateLoanSourceInstitutionByPkMutationFn = Apollo.MutationFunction<UpdateLoanSourceInstitutionByPkMutation, UpdateLoanSourceInstitutionByPkMutationVariables>;
export type UpdateLoanSourceInstitutionByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateLoanSourceInstitutionByPkMutation, UpdateLoanSourceInstitutionByPkMutationVariables>, 'mutation'>;

    export const UpdateLoanSourceInstitutionByPkComponent = (props: UpdateLoanSourceInstitutionByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateLoanSourceInstitutionByPkMutation, UpdateLoanSourceInstitutionByPkMutationVariables> mutation={UpdateLoanSourceInstitutionByPkDocument} {...props} />
    );
    

/**
 * __useUpdateLoanSourceInstitutionByPkMutation__
 *
 * To run a mutation, you first call `useUpdateLoanSourceInstitutionByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoanSourceInstitutionByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoanSourceInstitutionByPkMutation, { data, loading, error }] = useUpdateLoanSourceInstitutionByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateLoanSourceInstitutionByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLoanSourceInstitutionByPkMutation, UpdateLoanSourceInstitutionByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLoanSourceInstitutionByPkMutation, UpdateLoanSourceInstitutionByPkMutationVariables>(UpdateLoanSourceInstitutionByPkDocument, options);
      }
export type UpdateLoanSourceInstitutionByPkMutationHookResult = ReturnType<typeof useUpdateLoanSourceInstitutionByPkMutation>;
export type UpdateLoanSourceInstitutionByPkMutationResult = Apollo.MutationResult<UpdateLoanSourceInstitutionByPkMutation>;
export type UpdateLoanSourceInstitutionByPkMutationOptions = Apollo.BaseMutationOptions<UpdateLoanSourceInstitutionByPkMutation, UpdateLoanSourceInstitutionByPkMutationVariables>;
export const DeleteLoanSourceInstitutionByPkDocument = gql`
    mutation DeleteLoanSourceInstitutionByPk($id: String!) {
  row: delete_loan_source_financial_institution_by_pk(id: $id) {
    ...LoanSourceInstitution
  }
}
    ${LoanSourceInstitutionFragmentDoc}`;
export type DeleteLoanSourceInstitutionByPkMutationFn = Apollo.MutationFunction<DeleteLoanSourceInstitutionByPkMutation, DeleteLoanSourceInstitutionByPkMutationVariables>;
export type DeleteLoanSourceInstitutionByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteLoanSourceInstitutionByPkMutation, DeleteLoanSourceInstitutionByPkMutationVariables>, 'mutation'>;

    export const DeleteLoanSourceInstitutionByPkComponent = (props: DeleteLoanSourceInstitutionByPkComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteLoanSourceInstitutionByPkMutation, DeleteLoanSourceInstitutionByPkMutationVariables> mutation={DeleteLoanSourceInstitutionByPkDocument} {...props} />
    );
    

/**
 * __useDeleteLoanSourceInstitutionByPkMutation__
 *
 * To run a mutation, you first call `useDeleteLoanSourceInstitutionByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLoanSourceInstitutionByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLoanSourceInstitutionByPkMutation, { data, loading, error }] = useDeleteLoanSourceInstitutionByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLoanSourceInstitutionByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLoanSourceInstitutionByPkMutation, DeleteLoanSourceInstitutionByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLoanSourceInstitutionByPkMutation, DeleteLoanSourceInstitutionByPkMutationVariables>(DeleteLoanSourceInstitutionByPkDocument, options);
      }
export type DeleteLoanSourceInstitutionByPkMutationHookResult = ReturnType<typeof useDeleteLoanSourceInstitutionByPkMutation>;
export type DeleteLoanSourceInstitutionByPkMutationResult = Apollo.MutationResult<DeleteLoanSourceInstitutionByPkMutation>;
export type DeleteLoanSourceInstitutionByPkMutationOptions = Apollo.BaseMutationOptions<DeleteLoanSourceInstitutionByPkMutation, DeleteLoanSourceInstitutionByPkMutationVariables>;
export const GetLoanSourcesDocument = gql`
    query GetLoanSources($distinctOn: [loan_source_loan_select_column!], $limit: Int, $offset: Int, $orderBy: [loan_source_loan_order_by!], $where: loan_source_loan_bool_exp) {
  rows: loan_source_loan(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...LoanSource
  }
}
    ${LoanSourceFragmentDoc}`;
export type GetLoanSourcesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanSourcesQuery, GetLoanSourcesQueryVariables>, 'query'>;

    export const GetLoanSourcesComponent = (props: GetLoanSourcesComponentProps) => (
      <ApolloReactComponents.Query<GetLoanSourcesQuery, GetLoanSourcesQueryVariables> query={GetLoanSourcesDocument} {...props} />
    );
    

/**
 * __useGetLoanSourcesQuery__
 *
 * To run a query within a React component, call `useGetLoanSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanSourcesQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLoanSourcesQuery(baseOptions?: Apollo.QueryHookOptions<GetLoanSourcesQuery, GetLoanSourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanSourcesQuery, GetLoanSourcesQueryVariables>(GetLoanSourcesDocument, options);
      }
export function useGetLoanSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanSourcesQuery, GetLoanSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanSourcesQuery, GetLoanSourcesQueryVariables>(GetLoanSourcesDocument, options);
        }
export type GetLoanSourcesQueryHookResult = ReturnType<typeof useGetLoanSourcesQuery>;
export type GetLoanSourcesLazyQueryHookResult = ReturnType<typeof useGetLoanSourcesLazyQuery>;
export type GetLoanSourcesQueryResult = Apollo.QueryResult<GetLoanSourcesQuery, GetLoanSourcesQueryVariables>;
export const GetLoanSourceByPkDocument = gql`
    query GetLoanSourceByPk($id: uuid!) {
  row: loan_source_loan_by_pk(id: $id) {
    ...LoanSource
    content
  }
}
    ${LoanSourceFragmentDoc}`;
export type GetLoanSourceByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanSourceByPkQuery, GetLoanSourceByPkQueryVariables>, 'query'> & ({ variables: GetLoanSourceByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLoanSourceByPkComponent = (props: GetLoanSourceByPkComponentProps) => (
      <ApolloReactComponents.Query<GetLoanSourceByPkQuery, GetLoanSourceByPkQueryVariables> query={GetLoanSourceByPkDocument} {...props} />
    );
    

/**
 * __useGetLoanSourceByPkQuery__
 *
 * To run a query within a React component, call `useGetLoanSourceByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanSourceByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanSourceByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLoanSourceByPkQuery(baseOptions: Apollo.QueryHookOptions<GetLoanSourceByPkQuery, GetLoanSourceByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanSourceByPkQuery, GetLoanSourceByPkQueryVariables>(GetLoanSourceByPkDocument, options);
      }
export function useGetLoanSourceByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanSourceByPkQuery, GetLoanSourceByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanSourceByPkQuery, GetLoanSourceByPkQueryVariables>(GetLoanSourceByPkDocument, options);
        }
export type GetLoanSourceByPkQueryHookResult = ReturnType<typeof useGetLoanSourceByPkQuery>;
export type GetLoanSourceByPkLazyQueryHookResult = ReturnType<typeof useGetLoanSourceByPkLazyQuery>;
export type GetLoanSourceByPkQueryResult = Apollo.QueryResult<GetLoanSourceByPkQuery, GetLoanSourceByPkQueryVariables>;
export const GetFinancialInstitutesDocument = gql`
    query GetFinancialInstitutes {
  rows: loan_source_financial_institution {
    ...LoanSourceInstitution
  }
}
    ${LoanSourceInstitutionFragmentDoc}`;
export type GetFinancialInstitutesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetFinancialInstitutesQuery, GetFinancialInstitutesQueryVariables>, 'query'>;

    export const GetFinancialInstitutesComponent = (props: GetFinancialInstitutesComponentProps) => (
      <ApolloReactComponents.Query<GetFinancialInstitutesQuery, GetFinancialInstitutesQueryVariables> query={GetFinancialInstitutesDocument} {...props} />
    );
    

/**
 * __useGetFinancialInstitutesQuery__
 *
 * To run a query within a React component, call `useGetFinancialInstitutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancialInstitutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancialInstitutesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFinancialInstitutesQuery(baseOptions?: Apollo.QueryHookOptions<GetFinancialInstitutesQuery, GetFinancialInstitutesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFinancialInstitutesQuery, GetFinancialInstitutesQueryVariables>(GetFinancialInstitutesDocument, options);
      }
export function useGetFinancialInstitutesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFinancialInstitutesQuery, GetFinancialInstitutesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFinancialInstitutesQuery, GetFinancialInstitutesQueryVariables>(GetFinancialInstitutesDocument, options);
        }
export type GetFinancialInstitutesQueryHookResult = ReturnType<typeof useGetFinancialInstitutesQuery>;
export type GetFinancialInstitutesLazyQueryHookResult = ReturnType<typeof useGetFinancialInstitutesLazyQuery>;
export type GetFinancialInstitutesQueryResult = Apollo.QueryResult<GetFinancialInstitutesQuery, GetFinancialInstitutesQueryVariables>;
export const GetLoanSourceBusinesTypesDocument = gql`
    query GetLoanSourceBusinesTypes {
  rows: loan_source_business_type {
    id
    description
  }
}
    `;
export type GetLoanSourceBusinesTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanSourceBusinesTypesQuery, GetLoanSourceBusinesTypesQueryVariables>, 'query'>;

    export const GetLoanSourceBusinesTypesComponent = (props: GetLoanSourceBusinesTypesComponentProps) => (
      <ApolloReactComponents.Query<GetLoanSourceBusinesTypesQuery, GetLoanSourceBusinesTypesQueryVariables> query={GetLoanSourceBusinesTypesDocument} {...props} />
    );
    

/**
 * __useGetLoanSourceBusinesTypesQuery__
 *
 * To run a query within a React component, call `useGetLoanSourceBusinesTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanSourceBusinesTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanSourceBusinesTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoanSourceBusinesTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetLoanSourceBusinesTypesQuery, GetLoanSourceBusinesTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanSourceBusinesTypesQuery, GetLoanSourceBusinesTypesQueryVariables>(GetLoanSourceBusinesTypesDocument, options);
      }
export function useGetLoanSourceBusinesTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanSourceBusinesTypesQuery, GetLoanSourceBusinesTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanSourceBusinesTypesQuery, GetLoanSourceBusinesTypesQueryVariables>(GetLoanSourceBusinesTypesDocument, options);
        }
export type GetLoanSourceBusinesTypesQueryHookResult = ReturnType<typeof useGetLoanSourceBusinesTypesQuery>;
export type GetLoanSourceBusinesTypesLazyQueryHookResult = ReturnType<typeof useGetLoanSourceBusinesTypesLazyQuery>;
export type GetLoanSourceBusinesTypesQueryResult = Apollo.QueryResult<GetLoanSourceBusinesTypesQuery, GetLoanSourceBusinesTypesQueryVariables>;
export const GetLoanSourceSearchLogsDocument = gql`
    query GetLoanSourceSearchLogs($distinctOn: [loan_source_search_log_select_column!], $limit: Int, $offset: Int, $orderBy: [loan_source_search_log_order_by!], $where: loan_source_search_log_bool_exp) {
  rows: loan_source_search_log(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    id
    key
    value
    created_at
  }
  search: loan_source_search_log_aggregate(where: {key: {_eq: "search"}}) {
    aggregate {
      count(columns: key)
    }
  }
  interest: loan_source_search_log_aggregate(where: {key: {_eq: "interest"}}) {
    aggregate {
      count(columns: key)
    }
  }
  credit_limit: loan_source_search_log_aggregate(
    where: {key: {_eq: "credit_limit"}}
  ) {
    aggregate {
      count(columns: key)
    }
  }
  business_type: loan_source_search_log_aggregate(
    where: {key: {_eq: "business_type"}}
  ) {
    aggregate {
      count(columns: key)
    }
  }
}
    `;
export type GetLoanSourceSearchLogsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoanSourceSearchLogsQuery, GetLoanSourceSearchLogsQueryVariables>, 'query'>;

    export const GetLoanSourceSearchLogsComponent = (props: GetLoanSourceSearchLogsComponentProps) => (
      <ApolloReactComponents.Query<GetLoanSourceSearchLogsQuery, GetLoanSourceSearchLogsQueryVariables> query={GetLoanSourceSearchLogsDocument} {...props} />
    );
    

/**
 * __useGetLoanSourceSearchLogsQuery__
 *
 * To run a query within a React component, call `useGetLoanSourceSearchLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanSourceSearchLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanSourceSearchLogsQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLoanSourceSearchLogsQuery(baseOptions?: Apollo.QueryHookOptions<GetLoanSourceSearchLogsQuery, GetLoanSourceSearchLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoanSourceSearchLogsQuery, GetLoanSourceSearchLogsQueryVariables>(GetLoanSourceSearchLogsDocument, options);
      }
export function useGetLoanSourceSearchLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoanSourceSearchLogsQuery, GetLoanSourceSearchLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoanSourceSearchLogsQuery, GetLoanSourceSearchLogsQueryVariables>(GetLoanSourceSearchLogsDocument, options);
        }
export type GetLoanSourceSearchLogsQueryHookResult = ReturnType<typeof useGetLoanSourceSearchLogsQuery>;
export type GetLoanSourceSearchLogsLazyQueryHookResult = ReturnType<typeof useGetLoanSourceSearchLogsLazyQuery>;
export type GetLoanSourceSearchLogsQueryResult = Apollo.QueryResult<GetLoanSourceSearchLogsQuery, GetLoanSourceSearchLogsQueryVariables>;
export const UpdateOrganizationByPkDocument = gql`
    mutation UpdateOrganizationByPk($id: uuid!, $_set: organization_set_input) {
  update_organization_by_pk(pk_columns: {id: $id}, _set: $_set) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;
export type UpdateOrganizationByPkMutationFn = Apollo.MutationFunction<UpdateOrganizationByPkMutation, UpdateOrganizationByPkMutationVariables>;
export type UpdateOrganizationByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateOrganizationByPkMutation, UpdateOrganizationByPkMutationVariables>, 'mutation'>;

    export const UpdateOrganizationByPkComponent = (props: UpdateOrganizationByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateOrganizationByPkMutation, UpdateOrganizationByPkMutationVariables> mutation={UpdateOrganizationByPkDocument} {...props} />
    );
    

/**
 * __useUpdateOrganizationByPkMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationByPkMutation, { data, loading, error }] = useUpdateOrganizationByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateOrganizationByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationByPkMutation, UpdateOrganizationByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationByPkMutation, UpdateOrganizationByPkMutationVariables>(UpdateOrganizationByPkDocument, options);
      }
export type UpdateOrganizationByPkMutationHookResult = ReturnType<typeof useUpdateOrganizationByPkMutation>;
export type UpdateOrganizationByPkMutationResult = Apollo.MutationResult<UpdateOrganizationByPkMutation>;
export type UpdateOrganizationByPkMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationByPkMutation, UpdateOrganizationByPkMutationVariables>;
export const InsertOrganizationOneDocument = gql`
    mutation InsertOrganizationOne($object: organization_insert_input!) {
  insert_organization_one(object: $object) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;
export type InsertOrganizationOneMutationFn = Apollo.MutationFunction<InsertOrganizationOneMutation, InsertOrganizationOneMutationVariables>;
export type InsertOrganizationOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertOrganizationOneMutation, InsertOrganizationOneMutationVariables>, 'mutation'>;

    export const InsertOrganizationOneComponent = (props: InsertOrganizationOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertOrganizationOneMutation, InsertOrganizationOneMutationVariables> mutation={InsertOrganizationOneDocument} {...props} />
    );
    

/**
 * __useInsertOrganizationOneMutation__
 *
 * To run a mutation, you first call `useInsertOrganizationOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertOrganizationOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertOrganizationOneMutation, { data, loading, error }] = useInsertOrganizationOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertOrganizationOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertOrganizationOneMutation, InsertOrganizationOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertOrganizationOneMutation, InsertOrganizationOneMutationVariables>(InsertOrganizationOneDocument, options);
      }
export type InsertOrganizationOneMutationHookResult = ReturnType<typeof useInsertOrganizationOneMutation>;
export type InsertOrganizationOneMutationResult = Apollo.MutationResult<InsertOrganizationOneMutation>;
export type InsertOrganizationOneMutationOptions = Apollo.BaseMutationOptions<InsertOrganizationOneMutation, InsertOrganizationOneMutationVariables>;
export const GetOrganizationsDocument = gql`
    query GetOrganizations($distinctOn: [organization_select_column!], $limit: Int, $offset: Int, $orderBy: [organization_order_by!], $where: organization_bool_exp) {
  rows: organization(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;
export type GetOrganizationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>, 'query'>;

    export const GetOrganizationsComponent = (props: GetOrganizationsComponentProps) => (
      <ApolloReactComponents.Query<GetOrganizationsQuery, GetOrganizationsQueryVariables> query={GetOrganizationsDocument} {...props} />
    );
    

/**
 * __useGetOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
      }
export function useGetOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
        }
export type GetOrganizationsQueryHookResult = ReturnType<typeof useGetOrganizationsQuery>;
export type GetOrganizationsLazyQueryHookResult = ReturnType<typeof useGetOrganizationsLazyQuery>;
export type GetOrganizationsQueryResult = Apollo.QueryResult<GetOrganizationsQuery, GetOrganizationsQueryVariables>;
export const GetOrganizationByPkDocument = gql`
    query GetOrganizationByPk($id: uuid!) {
  entity: organization_by_pk(id: $id) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;
export type GetOrganizationByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetOrganizationByPkQuery, GetOrganizationByPkQueryVariables>, 'query'> & ({ variables: GetOrganizationByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetOrganizationByPkComponent = (props: GetOrganizationByPkComponentProps) => (
      <ApolloReactComponents.Query<GetOrganizationByPkQuery, GetOrganizationByPkQueryVariables> query={GetOrganizationByPkDocument} {...props} />
    );
    

/**
 * __useGetOrganizationByPkQuery__
 *
 * To run a query within a React component, call `useGetOrganizationByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationByPkQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationByPkQuery, GetOrganizationByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationByPkQuery, GetOrganizationByPkQueryVariables>(GetOrganizationByPkDocument, options);
      }
export function useGetOrganizationByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationByPkQuery, GetOrganizationByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationByPkQuery, GetOrganizationByPkQueryVariables>(GetOrganizationByPkDocument, options);
        }
export type GetOrganizationByPkQueryHookResult = ReturnType<typeof useGetOrganizationByPkQuery>;
export type GetOrganizationByPkLazyQueryHookResult = ReturnType<typeof useGetOrganizationByPkLazyQuery>;
export type GetOrganizationByPkQueryResult = Apollo.QueryResult<GetOrganizationByPkQuery, GetOrganizationByPkQueryVariables>;
export const GetOrganizationPartialsDocument = gql`
    query GetOrganizationPartials($distinctOn: [organization_select_column!], $limit: Int, $offset: Int, $orderBy: [organization_order_by!], $where: organization_bool_exp) {
  rows: organization(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...OrganizationPartial
  }
}
    ${OrganizationPartialFragmentDoc}`;
export type GetOrganizationPartialsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetOrganizationPartialsQuery, GetOrganizationPartialsQueryVariables>, 'query'>;

    export const GetOrganizationPartialsComponent = (props: GetOrganizationPartialsComponentProps) => (
      <ApolloReactComponents.Query<GetOrganizationPartialsQuery, GetOrganizationPartialsQueryVariables> query={GetOrganizationPartialsDocument} {...props} />
    );
    

/**
 * __useGetOrganizationPartialsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationPartialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationPartialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationPartialsQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetOrganizationPartialsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationPartialsQuery, GetOrganizationPartialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationPartialsQuery, GetOrganizationPartialsQueryVariables>(GetOrganizationPartialsDocument, options);
      }
export function useGetOrganizationPartialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationPartialsQuery, GetOrganizationPartialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationPartialsQuery, GetOrganizationPartialsQueryVariables>(GetOrganizationPartialsDocument, options);
        }
export type GetOrganizationPartialsQueryHookResult = ReturnType<typeof useGetOrganizationPartialsQuery>;
export type GetOrganizationPartialsLazyQueryHookResult = ReturnType<typeof useGetOrganizationPartialsLazyQuery>;
export type GetOrganizationPartialsQueryResult = Apollo.QueryResult<GetOrganizationPartialsQuery, GetOrganizationPartialsQueryVariables>;
export const InsertProjectOneDocument = gql`
    mutation InsertProjectOne($object: project_project_insert_input!) {
  insert_project_project_one(object: $object) {
    ...ProjectPartial
  }
}
    ${ProjectPartialFragmentDoc}`;
export type InsertProjectOneMutationFn = Apollo.MutationFunction<InsertProjectOneMutation, InsertProjectOneMutationVariables>;
export type InsertProjectOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertProjectOneMutation, InsertProjectOneMutationVariables>, 'mutation'>;

    export const InsertProjectOneComponent = (props: InsertProjectOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertProjectOneMutation, InsertProjectOneMutationVariables> mutation={InsertProjectOneDocument} {...props} />
    );
    

/**
 * __useInsertProjectOneMutation__
 *
 * To run a mutation, you first call `useInsertProjectOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertProjectOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProjectOneMutation, { data, loading, error }] = useInsertProjectOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertProjectOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertProjectOneMutation, InsertProjectOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertProjectOneMutation, InsertProjectOneMutationVariables>(InsertProjectOneDocument, options);
      }
export type InsertProjectOneMutationHookResult = ReturnType<typeof useInsertProjectOneMutation>;
export type InsertProjectOneMutationResult = Apollo.MutationResult<InsertProjectOneMutation>;
export type InsertProjectOneMutationOptions = Apollo.BaseMutationOptions<InsertProjectOneMutation, InsertProjectOneMutationVariables>;
export const UpdateProjectByPkTmpDocument = gql`
    mutation UpdateProjectByPk_Tmp($project: project_project_set_input, $id: uuid!) {
  project: update_project_project_by_pk(pk_columns: {id: $id}, _set: $project) {
    ...ProjectPartial
  }
}
    ${ProjectPartialFragmentDoc}`;
export type UpdateProjectByPkTmpMutationFn = Apollo.MutationFunction<UpdateProjectByPkTmpMutation, UpdateProjectByPkTmpMutationVariables>;
export type UpdateProjectByPkTmpComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateProjectByPkTmpMutation, UpdateProjectByPkTmpMutationVariables>, 'mutation'>;

    export const UpdateProjectByPkTmpComponent = (props: UpdateProjectByPkTmpComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateProjectByPkTmpMutation, UpdateProjectByPkTmpMutationVariables> mutation={UpdateProjectByPkTmpDocument} {...props} />
    );
    

/**
 * __useUpdateProjectByPkTmpMutation__
 *
 * To run a mutation, you first call `useUpdateProjectByPkTmpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectByPkTmpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectByPkTmpMutation, { data, loading, error }] = useUpdateProjectByPkTmpMutation({
 *   variables: {
 *      project: // value for 'project'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateProjectByPkTmpMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectByPkTmpMutation, UpdateProjectByPkTmpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectByPkTmpMutation, UpdateProjectByPkTmpMutationVariables>(UpdateProjectByPkTmpDocument, options);
      }
export type UpdateProjectByPkTmpMutationHookResult = ReturnType<typeof useUpdateProjectByPkTmpMutation>;
export type UpdateProjectByPkTmpMutationResult = Apollo.MutationResult<UpdateProjectByPkTmpMutation>;
export type UpdateProjectByPkTmpMutationOptions = Apollo.BaseMutationOptions<UpdateProjectByPkTmpMutation, UpdateProjectByPkTmpMutationVariables>;
export const UpdateProjectByPkDocument = gql`
    mutation UpdateProjectByPk($project: project_project_set_input, $id: uuid!, $insertedProvinceIds: [uuid!]!, $operatingAreas: [project_project_operating_area_insert_input!]!, $insertedTargetGroupIds: [String!]!, $operatingTargetGroups: [project_project_target_group_insert_input!]!) {
  project: update_project_project_by_pk(pk_columns: {id: $id}, _set: $project) {
    ...ProjectPartial
  }
  delete_project_project_target_group(
    where: {project_id: {_eq: $id}, target_group_id: {_nin: $insertedTargetGroupIds}}
  ) {
    affected_rows
  }
  insert_project_project_target_group(
    objects: $operatingTargetGroups
    on_conflict: {constraint: project_target_group_pkey, update_columns: [target_group_id, sequence]}
  ) {
    affected_rows
  }
  delete_project_project_operating_area(
    where: {project_id: {_eq: $id}, province_id: {_nin: $insertedProvinceIds}}
  ) {
    affected_rows
  }
  insert_project_project_operating_area(
    objects: $operatingAreas
    on_conflict: {constraint: project_operating_area_pkey, update_columns: [province_id, sequence]}
  ) {
    affected_rows
  }
}
    ${ProjectPartialFragmentDoc}`;
export type UpdateProjectByPkMutationFn = Apollo.MutationFunction<UpdateProjectByPkMutation, UpdateProjectByPkMutationVariables>;
export type UpdateProjectByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateProjectByPkMutation, UpdateProjectByPkMutationVariables>, 'mutation'>;

    export const UpdateProjectByPkComponent = (props: UpdateProjectByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateProjectByPkMutation, UpdateProjectByPkMutationVariables> mutation={UpdateProjectByPkDocument} {...props} />
    );
    

/**
 * __useUpdateProjectByPkMutation__
 *
 * To run a mutation, you first call `useUpdateProjectByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectByPkMutation, { data, loading, error }] = useUpdateProjectByPkMutation({
 *   variables: {
 *      project: // value for 'project'
 *      id: // value for 'id'
 *      insertedProvinceIds: // value for 'insertedProvinceIds'
 *      operatingAreas: // value for 'operatingAreas'
 *      insertedTargetGroupIds: // value for 'insertedTargetGroupIds'
 *      operatingTargetGroups: // value for 'operatingTargetGroups'
 *   },
 * });
 */
export function useUpdateProjectByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectByPkMutation, UpdateProjectByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectByPkMutation, UpdateProjectByPkMutationVariables>(UpdateProjectByPkDocument, options);
      }
export type UpdateProjectByPkMutationHookResult = ReturnType<typeof useUpdateProjectByPkMutation>;
export type UpdateProjectByPkMutationResult = Apollo.MutationResult<UpdateProjectByPkMutation>;
export type UpdateProjectByPkMutationOptions = Apollo.BaseMutationOptions<UpdateProjectByPkMutation, UpdateProjectByPkMutationVariables>;
export const ReplaceProjectOperatingActivitiesDocument = gql`
    mutation ReplaceProjectOperatingActivities($projectId: uuid!, $insertedActivityIds: [uuid!], $insertActivityData: [project_project_operating_activity_insert_input!]!) {
  deleted_rows: delete_project_project_operating_activity(
    where: {project_id: {_eq: $projectId}, id: {_nin: $insertedActivityIds}}
  ) {
    affected_rows
    returning {
      id
      project_id
      budget_group_id
    }
  }
  insert_rows: insert_project_project_operating_activity(
    objects: $insertActivityData
    on_conflict: {constraint: project_operating_activity_pkey, update_columns: [description, name, budget_group_id, sequence, target_amount, target_unit_id]}
  ) {
    affected_rows
    returning {
      id
      project_id
      budget_group_id
    }
  }
}
    `;
export type ReplaceProjectOperatingActivitiesMutationFn = Apollo.MutationFunction<ReplaceProjectOperatingActivitiesMutation, ReplaceProjectOperatingActivitiesMutationVariables>;
export type ReplaceProjectOperatingActivitiesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReplaceProjectOperatingActivitiesMutation, ReplaceProjectOperatingActivitiesMutationVariables>, 'mutation'>;

    export const ReplaceProjectOperatingActivitiesComponent = (props: ReplaceProjectOperatingActivitiesComponentProps) => (
      <ApolloReactComponents.Mutation<ReplaceProjectOperatingActivitiesMutation, ReplaceProjectOperatingActivitiesMutationVariables> mutation={ReplaceProjectOperatingActivitiesDocument} {...props} />
    );
    

/**
 * __useReplaceProjectOperatingActivitiesMutation__
 *
 * To run a mutation, you first call `useReplaceProjectOperatingActivitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceProjectOperatingActivitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceProjectOperatingActivitiesMutation, { data, loading, error }] = useReplaceProjectOperatingActivitiesMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      insertedActivityIds: // value for 'insertedActivityIds'
 *      insertActivityData: // value for 'insertActivityData'
 *   },
 * });
 */
export function useReplaceProjectOperatingActivitiesMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceProjectOperatingActivitiesMutation, ReplaceProjectOperatingActivitiesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplaceProjectOperatingActivitiesMutation, ReplaceProjectOperatingActivitiesMutationVariables>(ReplaceProjectOperatingActivitiesDocument, options);
      }
export type ReplaceProjectOperatingActivitiesMutationHookResult = ReturnType<typeof useReplaceProjectOperatingActivitiesMutation>;
export type ReplaceProjectOperatingActivitiesMutationResult = Apollo.MutationResult<ReplaceProjectOperatingActivitiesMutation>;
export type ReplaceProjectOperatingActivitiesMutationOptions = Apollo.BaseMutationOptions<ReplaceProjectOperatingActivitiesMutation, ReplaceProjectOperatingActivitiesMutationVariables>;
export const ReplaceProjectBudgetItemsDocument = gql`
    mutation ReplaceProjectBudgetItems($projectId: uuid!, $activityId: uuid!, $insertedBudgetItemIds: [uuid!], $insertBudgetItemsData: [project_project_budget_item_insert_input!]!) {
  deleted_rows: delete_project_project_budget_item(
    where: {project_operating_activity_id: {_eq: $activityId}, project_operating_activity: {project_id: {_eq: $projectId}}, id: {_nin: $insertedBudgetItemIds}}
  ) {
    affected_rows
    returning {
      id
      multiplier
      unit_id
      unit_of_multiplier_id
      unit_amount
      total
      sequence
      price_per_unit
      budget_group_id
      note
      budget_id
    }
  }
  insert_rows: insert_project_project_budget_item(
    objects: $insertBudgetItemsData
    on_conflict: {constraint: project_budget_item_pkey, update_columns: [budget_group_id, sequence, budget_id, multiplier, note, price_per_unit, project_operating_activity_id, sequence, total, unit_amount, unit_id, unit_of_multiplier_id]}
  ) {
    affected_rows
    returning {
      id
      multiplier
      unit_id
      unit_of_multiplier_id
      unit_amount
      total
      sequence
      price_per_unit
      budget_group_id
      note
      budget_id
    }
  }
}
    `;
export type ReplaceProjectBudgetItemsMutationFn = Apollo.MutationFunction<ReplaceProjectBudgetItemsMutation, ReplaceProjectBudgetItemsMutationVariables>;
export type ReplaceProjectBudgetItemsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReplaceProjectBudgetItemsMutation, ReplaceProjectBudgetItemsMutationVariables>, 'mutation'>;

    export const ReplaceProjectBudgetItemsComponent = (props: ReplaceProjectBudgetItemsComponentProps) => (
      <ApolloReactComponents.Mutation<ReplaceProjectBudgetItemsMutation, ReplaceProjectBudgetItemsMutationVariables> mutation={ReplaceProjectBudgetItemsDocument} {...props} />
    );
    

/**
 * __useReplaceProjectBudgetItemsMutation__
 *
 * To run a mutation, you first call `useReplaceProjectBudgetItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceProjectBudgetItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceProjectBudgetItemsMutation, { data, loading, error }] = useReplaceProjectBudgetItemsMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      activityId: // value for 'activityId'
 *      insertedBudgetItemIds: // value for 'insertedBudgetItemIds'
 *      insertBudgetItemsData: // value for 'insertBudgetItemsData'
 *   },
 * });
 */
export function useReplaceProjectBudgetItemsMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceProjectBudgetItemsMutation, ReplaceProjectBudgetItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplaceProjectBudgetItemsMutation, ReplaceProjectBudgetItemsMutationVariables>(ReplaceProjectBudgetItemsDocument, options);
      }
export type ReplaceProjectBudgetItemsMutationHookResult = ReturnType<typeof useReplaceProjectBudgetItemsMutation>;
export type ReplaceProjectBudgetItemsMutationResult = Apollo.MutationResult<ReplaceProjectBudgetItemsMutation>;
export type ReplaceProjectBudgetItemsMutationOptions = Apollo.BaseMutationOptions<ReplaceProjectBudgetItemsMutation, ReplaceProjectBudgetItemsMutationVariables>;
export const ReplaceProjectObjectivesDocument = gql`
    mutation ReplaceProjectObjectives($projectId: uuid!, $insertedIndicatorsIds: [uuid!], $insertedObjectivesIds: [uuid!], $insertedAchievementsIds: [uuid!], $insertedObjectivesData: [project_project_objective_insert_input!]!) {
  delete_project_project_objective(
    where: {project_id: {_eq: $projectId}, id: {_nin: $insertedObjectivesIds}}
  ) {
    affected_rows
  }
  delete_project_project_indicator(
    where: {project_id: {_eq: $projectId}, id: {_nin: $insertedIndicatorsIds}}
  ) {
    affected_rows
  }
  delete_project_project_achievement(
    where: {project_id: {_eq: $projectId}, id: {_nin: $insertedAchievementsIds}}
  ) {
    affected_rows
  }
  insert_project_project_objective(
    objects: $insertedObjectivesData
    on_conflict: {constraint: project_objective_pkey, update_columns: [sequence, name]}
  ) {
    affected_rows
  }
}
    `;
export type ReplaceProjectObjectivesMutationFn = Apollo.MutationFunction<ReplaceProjectObjectivesMutation, ReplaceProjectObjectivesMutationVariables>;
export type ReplaceProjectObjectivesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReplaceProjectObjectivesMutation, ReplaceProjectObjectivesMutationVariables>, 'mutation'>;

    export const ReplaceProjectObjectivesComponent = (props: ReplaceProjectObjectivesComponentProps) => (
      <ApolloReactComponents.Mutation<ReplaceProjectObjectivesMutation, ReplaceProjectObjectivesMutationVariables> mutation={ReplaceProjectObjectivesDocument} {...props} />
    );
    

/**
 * __useReplaceProjectObjectivesMutation__
 *
 * To run a mutation, you first call `useReplaceProjectObjectivesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceProjectObjectivesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceProjectObjectivesMutation, { data, loading, error }] = useReplaceProjectObjectivesMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      insertedIndicatorsIds: // value for 'insertedIndicatorsIds'
 *      insertedObjectivesIds: // value for 'insertedObjectivesIds'
 *      insertedAchievementsIds: // value for 'insertedAchievementsIds'
 *      insertedObjectivesData: // value for 'insertedObjectivesData'
 *   },
 * });
 */
export function useReplaceProjectObjectivesMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceProjectObjectivesMutation, ReplaceProjectObjectivesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplaceProjectObjectivesMutation, ReplaceProjectObjectivesMutationVariables>(ReplaceProjectObjectivesDocument, options);
      }
export type ReplaceProjectObjectivesMutationHookResult = ReturnType<typeof useReplaceProjectObjectivesMutation>;
export type ReplaceProjectObjectivesMutationResult = Apollo.MutationResult<ReplaceProjectObjectivesMutation>;
export type ReplaceProjectObjectivesMutationOptions = Apollo.BaseMutationOptions<ReplaceProjectObjectivesMutation, ReplaceProjectObjectivesMutationVariables>;
export const ProjectSubmitFormDocument = gql`
    mutation ProjectSubmitForm($projectId: ID!) {
  project_project_submit(data: {projectId: $projectId}) {
    projectId
  }
}
    `;
export type ProjectSubmitFormMutationFn = Apollo.MutationFunction<ProjectSubmitFormMutation, ProjectSubmitFormMutationVariables>;
export type ProjectSubmitFormComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ProjectSubmitFormMutation, ProjectSubmitFormMutationVariables>, 'mutation'>;

    export const ProjectSubmitFormComponent = (props: ProjectSubmitFormComponentProps) => (
      <ApolloReactComponents.Mutation<ProjectSubmitFormMutation, ProjectSubmitFormMutationVariables> mutation={ProjectSubmitFormDocument} {...props} />
    );
    

/**
 * __useProjectSubmitFormMutation__
 *
 * To run a mutation, you first call `useProjectSubmitFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectSubmitFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectSubmitFormMutation, { data, loading, error }] = useProjectSubmitFormMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectSubmitFormMutation(baseOptions?: Apollo.MutationHookOptions<ProjectSubmitFormMutation, ProjectSubmitFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectSubmitFormMutation, ProjectSubmitFormMutationVariables>(ProjectSubmitFormDocument, options);
      }
export type ProjectSubmitFormMutationHookResult = ReturnType<typeof useProjectSubmitFormMutation>;
export type ProjectSubmitFormMutationResult = Apollo.MutationResult<ProjectSubmitFormMutation>;
export type ProjectSubmitFormMutationOptions = Apollo.BaseMutationOptions<ProjectSubmitFormMutation, ProjectSubmitFormMutationVariables>;
export const ProjectApproveFormDocument = gql`
    mutation ProjectApproveForm($data: ProjectApprovalInput!) {
  project_project_approve(data: $data) {
    projectId
  }
}
    `;
export type ProjectApproveFormMutationFn = Apollo.MutationFunction<ProjectApproveFormMutation, ProjectApproveFormMutationVariables>;
export type ProjectApproveFormComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ProjectApproveFormMutation, ProjectApproveFormMutationVariables>, 'mutation'>;

    export const ProjectApproveFormComponent = (props: ProjectApproveFormComponentProps) => (
      <ApolloReactComponents.Mutation<ProjectApproveFormMutation, ProjectApproveFormMutationVariables> mutation={ProjectApproveFormDocument} {...props} />
    );
    

/**
 * __useProjectApproveFormMutation__
 *
 * To run a mutation, you first call `useProjectApproveFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectApproveFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectApproveFormMutation, { data, loading, error }] = useProjectApproveFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProjectApproveFormMutation(baseOptions?: Apollo.MutationHookOptions<ProjectApproveFormMutation, ProjectApproveFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectApproveFormMutation, ProjectApproveFormMutationVariables>(ProjectApproveFormDocument, options);
      }
export type ProjectApproveFormMutationHookResult = ReturnType<typeof useProjectApproveFormMutation>;
export type ProjectApproveFormMutationResult = Apollo.MutationResult<ProjectApproveFormMutation>;
export type ProjectApproveFormMutationOptions = Apollo.BaseMutationOptions<ProjectApproveFormMutation, ProjectApproveFormMutationVariables>;
export const UpdateProjectOperatingActivityProgressesDocument = gql`
    mutation UpdateProjectOperatingActivityProgresses($objects: [project_operating_activity_progress_insert_input!]!, $update_columns: [project_operating_activity_progress_update_column!]!) {
  operating_activity_progresses: insert_project_operating_activity_progress(
    objects: $objects
    on_conflict: {constraint: operating_activity_progress_pkey, update_columns: $update_columns}
  ) {
    affected_rows
    returning {
      id
      month
      revision
      spend
      workload
    }
  }
}
    `;
export type UpdateProjectOperatingActivityProgressesMutationFn = Apollo.MutationFunction<UpdateProjectOperatingActivityProgressesMutation, UpdateProjectOperatingActivityProgressesMutationVariables>;
export type UpdateProjectOperatingActivityProgressesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateProjectOperatingActivityProgressesMutation, UpdateProjectOperatingActivityProgressesMutationVariables>, 'mutation'>;

    export const UpdateProjectOperatingActivityProgressesComponent = (props: UpdateProjectOperatingActivityProgressesComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateProjectOperatingActivityProgressesMutation, UpdateProjectOperatingActivityProgressesMutationVariables> mutation={UpdateProjectOperatingActivityProgressesDocument} {...props} />
    );
    

/**
 * __useUpdateProjectOperatingActivityProgressesMutation__
 *
 * To run a mutation, you first call `useUpdateProjectOperatingActivityProgressesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectOperatingActivityProgressesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectOperatingActivityProgressesMutation, { data, loading, error }] = useUpdateProjectOperatingActivityProgressesMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      update_columns: // value for 'update_columns'
 *   },
 * });
 */
export function useUpdateProjectOperatingActivityProgressesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectOperatingActivityProgressesMutation, UpdateProjectOperatingActivityProgressesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectOperatingActivityProgressesMutation, UpdateProjectOperatingActivityProgressesMutationVariables>(UpdateProjectOperatingActivityProgressesDocument, options);
      }
export type UpdateProjectOperatingActivityProgressesMutationHookResult = ReturnType<typeof useUpdateProjectOperatingActivityProgressesMutation>;
export type UpdateProjectOperatingActivityProgressesMutationResult = Apollo.MutationResult<UpdateProjectOperatingActivityProgressesMutation>;
export type UpdateProjectOperatingActivityProgressesMutationOptions = Apollo.BaseMutationOptions<UpdateProjectOperatingActivityProgressesMutation, UpdateProjectOperatingActivityProgressesMutationVariables>;
export const InsertProjectAttachmentOneDocument = gql`
    mutation InsertProjectAttachmentOne($object: project_project_attachment_insert_input!) {
  attachment: insert_project_project_attachment_one(object: $object) {
    ...ProjectAttachment
  }
}
    ${ProjectAttachmentFragmentDoc}`;
export type InsertProjectAttachmentOneMutationFn = Apollo.MutationFunction<InsertProjectAttachmentOneMutation, InsertProjectAttachmentOneMutationVariables>;
export type InsertProjectAttachmentOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertProjectAttachmentOneMutation, InsertProjectAttachmentOneMutationVariables>, 'mutation'>;

    export const InsertProjectAttachmentOneComponent = (props: InsertProjectAttachmentOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertProjectAttachmentOneMutation, InsertProjectAttachmentOneMutationVariables> mutation={InsertProjectAttachmentOneDocument} {...props} />
    );
    

/**
 * __useInsertProjectAttachmentOneMutation__
 *
 * To run a mutation, you first call `useInsertProjectAttachmentOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertProjectAttachmentOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProjectAttachmentOneMutation, { data, loading, error }] = useInsertProjectAttachmentOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertProjectAttachmentOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertProjectAttachmentOneMutation, InsertProjectAttachmentOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertProjectAttachmentOneMutation, InsertProjectAttachmentOneMutationVariables>(InsertProjectAttachmentOneDocument, options);
      }
export type InsertProjectAttachmentOneMutationHookResult = ReturnType<typeof useInsertProjectAttachmentOneMutation>;
export type InsertProjectAttachmentOneMutationResult = Apollo.MutationResult<InsertProjectAttachmentOneMutation>;
export type InsertProjectAttachmentOneMutationOptions = Apollo.BaseMutationOptions<InsertProjectAttachmentOneMutation, InsertProjectAttachmentOneMutationVariables>;
export const DeleteProjectAttachmentByPkDocument = gql`
    mutation DeleteProjectAttachmentByPk($id: uuid!) {
  attachment: delete_project_project_attachment_by_pk(id: $id) {
    ...ProjectAttachment
  }
}
    ${ProjectAttachmentFragmentDoc}`;
export type DeleteProjectAttachmentByPkMutationFn = Apollo.MutationFunction<DeleteProjectAttachmentByPkMutation, DeleteProjectAttachmentByPkMutationVariables>;
export type DeleteProjectAttachmentByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteProjectAttachmentByPkMutation, DeleteProjectAttachmentByPkMutationVariables>, 'mutation'>;

    export const DeleteProjectAttachmentByPkComponent = (props: DeleteProjectAttachmentByPkComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteProjectAttachmentByPkMutation, DeleteProjectAttachmentByPkMutationVariables> mutation={DeleteProjectAttachmentByPkDocument} {...props} />
    );
    

/**
 * __useDeleteProjectAttachmentByPkMutation__
 *
 * To run a mutation, you first call `useDeleteProjectAttachmentByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectAttachmentByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectAttachmentByPkMutation, { data, loading, error }] = useDeleteProjectAttachmentByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectAttachmentByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectAttachmentByPkMutation, DeleteProjectAttachmentByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectAttachmentByPkMutation, DeleteProjectAttachmentByPkMutationVariables>(DeleteProjectAttachmentByPkDocument, options);
      }
export type DeleteProjectAttachmentByPkMutationHookResult = ReturnType<typeof useDeleteProjectAttachmentByPkMutation>;
export type DeleteProjectAttachmentByPkMutationResult = Apollo.MutationResult<DeleteProjectAttachmentByPkMutation>;
export type DeleteProjectAttachmentByPkMutationOptions = Apollo.BaseMutationOptions<DeleteProjectAttachmentByPkMutation, DeleteProjectAttachmentByPkMutationVariables>;
export const UpdateProjectDetailByPkDocument = gql`
    mutation UpdateProjectDetailByPk($projectId: uuid!, $data: project_project_set_input) {
  project: update_project_project_by_pk(pk_columns: {id: $projectId}, _set: $data) {
    id
  }
}
    `;
export type UpdateProjectDetailByPkMutationFn = Apollo.MutationFunction<UpdateProjectDetailByPkMutation, UpdateProjectDetailByPkMutationVariables>;
export type UpdateProjectDetailByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateProjectDetailByPkMutation, UpdateProjectDetailByPkMutationVariables>, 'mutation'>;

    export const UpdateProjectDetailByPkComponent = (props: UpdateProjectDetailByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateProjectDetailByPkMutation, UpdateProjectDetailByPkMutationVariables> mutation={UpdateProjectDetailByPkDocument} {...props} />
    );
    

/**
 * __useUpdateProjectDetailByPkMutation__
 *
 * To run a mutation, you first call `useUpdateProjectDetailByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectDetailByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectDetailByPkMutation, { data, loading, error }] = useUpdateProjectDetailByPkMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProjectDetailByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectDetailByPkMutation, UpdateProjectDetailByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectDetailByPkMutation, UpdateProjectDetailByPkMutationVariables>(UpdateProjectDetailByPkDocument, options);
      }
export type UpdateProjectDetailByPkMutationHookResult = ReturnType<typeof useUpdateProjectDetailByPkMutation>;
export type UpdateProjectDetailByPkMutationResult = Apollo.MutationResult<UpdateProjectDetailByPkMutation>;
export type UpdateProjectDetailByPkMutationOptions = Apollo.BaseMutationOptions<UpdateProjectDetailByPkMutation, UpdateProjectDetailByPkMutationVariables>;
export const InsertProjectObjectiveOneDocument = gql`
    mutation InsertProjectObjectiveOne($data: project_project_objective_insert_input!) {
  objective: insert_project_project_objective_one(object: $data) {
    ...ProjectObjectivePartial
  }
}
    ${ProjectObjectivePartialFragmentDoc}`;
export type InsertProjectObjectiveOneMutationFn = Apollo.MutationFunction<InsertProjectObjectiveOneMutation, InsertProjectObjectiveOneMutationVariables>;
export type InsertProjectObjectiveOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertProjectObjectiveOneMutation, InsertProjectObjectiveOneMutationVariables>, 'mutation'>;

    export const InsertProjectObjectiveOneComponent = (props: InsertProjectObjectiveOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertProjectObjectiveOneMutation, InsertProjectObjectiveOneMutationVariables> mutation={InsertProjectObjectiveOneDocument} {...props} />
    );
    

/**
 * __useInsertProjectObjectiveOneMutation__
 *
 * To run a mutation, you first call `useInsertProjectObjectiveOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertProjectObjectiveOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProjectObjectiveOneMutation, { data, loading, error }] = useInsertProjectObjectiveOneMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertProjectObjectiveOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertProjectObjectiveOneMutation, InsertProjectObjectiveOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertProjectObjectiveOneMutation, InsertProjectObjectiveOneMutationVariables>(InsertProjectObjectiveOneDocument, options);
      }
export type InsertProjectObjectiveOneMutationHookResult = ReturnType<typeof useInsertProjectObjectiveOneMutation>;
export type InsertProjectObjectiveOneMutationResult = Apollo.MutationResult<InsertProjectObjectiveOneMutation>;
export type InsertProjectObjectiveOneMutationOptions = Apollo.BaseMutationOptions<InsertProjectObjectiveOneMutation, InsertProjectObjectiveOneMutationVariables>;
export const UpdateProjectObjectiveByPkDocument = gql`
    mutation UpdateProjectObjectiveByPk($objectiveId: uuid!, $data: project_project_objective_set_input) {
  objective: update_project_project_objective_by_pk(
    pk_columns: {id: $objectiveId}
    _set: $data
  ) {
    ...ProjectObjectivePartial
  }
}
    ${ProjectObjectivePartialFragmentDoc}`;
export type UpdateProjectObjectiveByPkMutationFn = Apollo.MutationFunction<UpdateProjectObjectiveByPkMutation, UpdateProjectObjectiveByPkMutationVariables>;
export type UpdateProjectObjectiveByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateProjectObjectiveByPkMutation, UpdateProjectObjectiveByPkMutationVariables>, 'mutation'>;

    export const UpdateProjectObjectiveByPkComponent = (props: UpdateProjectObjectiveByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateProjectObjectiveByPkMutation, UpdateProjectObjectiveByPkMutationVariables> mutation={UpdateProjectObjectiveByPkDocument} {...props} />
    );
    

/**
 * __useUpdateProjectObjectiveByPkMutation__
 *
 * To run a mutation, you first call `useUpdateProjectObjectiveByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectObjectiveByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectObjectiveByPkMutation, { data, loading, error }] = useUpdateProjectObjectiveByPkMutation({
 *   variables: {
 *      objectiveId: // value for 'objectiveId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProjectObjectiveByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectObjectiveByPkMutation, UpdateProjectObjectiveByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectObjectiveByPkMutation, UpdateProjectObjectiveByPkMutationVariables>(UpdateProjectObjectiveByPkDocument, options);
      }
export type UpdateProjectObjectiveByPkMutationHookResult = ReturnType<typeof useUpdateProjectObjectiveByPkMutation>;
export type UpdateProjectObjectiveByPkMutationResult = Apollo.MutationResult<UpdateProjectObjectiveByPkMutation>;
export type UpdateProjectObjectiveByPkMutationOptions = Apollo.BaseMutationOptions<UpdateProjectObjectiveByPkMutation, UpdateProjectObjectiveByPkMutationVariables>;
export const DeleteProjectObjectiveByPkDocument = gql`
    mutation DeleteProjectObjectiveByPk($objectiveId: uuid!) {
  objective: delete_project_project_objective_by_pk(id: $objectiveId) {
    ...ProjectObjectivePartial
  }
}
    ${ProjectObjectivePartialFragmentDoc}`;
export type DeleteProjectObjectiveByPkMutationFn = Apollo.MutationFunction<DeleteProjectObjectiveByPkMutation, DeleteProjectObjectiveByPkMutationVariables>;
export type DeleteProjectObjectiveByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteProjectObjectiveByPkMutation, DeleteProjectObjectiveByPkMutationVariables>, 'mutation'>;

    export const DeleteProjectObjectiveByPkComponent = (props: DeleteProjectObjectiveByPkComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteProjectObjectiveByPkMutation, DeleteProjectObjectiveByPkMutationVariables> mutation={DeleteProjectObjectiveByPkDocument} {...props} />
    );
    

/**
 * __useDeleteProjectObjectiveByPkMutation__
 *
 * To run a mutation, you first call `useDeleteProjectObjectiveByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectObjectiveByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectObjectiveByPkMutation, { data, loading, error }] = useDeleteProjectObjectiveByPkMutation({
 *   variables: {
 *      objectiveId: // value for 'objectiveId'
 *   },
 * });
 */
export function useDeleteProjectObjectiveByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectObjectiveByPkMutation, DeleteProjectObjectiveByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectObjectiveByPkMutation, DeleteProjectObjectiveByPkMutationVariables>(DeleteProjectObjectiveByPkDocument, options);
      }
export type DeleteProjectObjectiveByPkMutationHookResult = ReturnType<typeof useDeleteProjectObjectiveByPkMutation>;
export type DeleteProjectObjectiveByPkMutationResult = Apollo.MutationResult<DeleteProjectObjectiveByPkMutation>;
export type DeleteProjectObjectiveByPkMutationOptions = Apollo.BaseMutationOptions<DeleteProjectObjectiveByPkMutation, DeleteProjectObjectiveByPkMutationVariables>;
export const InsertProjectIndicatorOneDocument = gql`
    mutation InsertProjectIndicatorOne($data: project_project_indicator_insert_input!) {
  objective: insert_project_project_indicator_one(object: $data) {
    ...ProjectIndicator
  }
}
    ${ProjectIndicatorFragmentDoc}`;
export type InsertProjectIndicatorOneMutationFn = Apollo.MutationFunction<InsertProjectIndicatorOneMutation, InsertProjectIndicatorOneMutationVariables>;
export type InsertProjectIndicatorOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertProjectIndicatorOneMutation, InsertProjectIndicatorOneMutationVariables>, 'mutation'>;

    export const InsertProjectIndicatorOneComponent = (props: InsertProjectIndicatorOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertProjectIndicatorOneMutation, InsertProjectIndicatorOneMutationVariables> mutation={InsertProjectIndicatorOneDocument} {...props} />
    );
    

/**
 * __useInsertProjectIndicatorOneMutation__
 *
 * To run a mutation, you first call `useInsertProjectIndicatorOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertProjectIndicatorOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProjectIndicatorOneMutation, { data, loading, error }] = useInsertProjectIndicatorOneMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertProjectIndicatorOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertProjectIndicatorOneMutation, InsertProjectIndicatorOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertProjectIndicatorOneMutation, InsertProjectIndicatorOneMutationVariables>(InsertProjectIndicatorOneDocument, options);
      }
export type InsertProjectIndicatorOneMutationHookResult = ReturnType<typeof useInsertProjectIndicatorOneMutation>;
export type InsertProjectIndicatorOneMutationResult = Apollo.MutationResult<InsertProjectIndicatorOneMutation>;
export type InsertProjectIndicatorOneMutationOptions = Apollo.BaseMutationOptions<InsertProjectIndicatorOneMutation, InsertProjectIndicatorOneMutationVariables>;
export const UpdateProjectIndicatorByPkDocument = gql`
    mutation UpdateProjectIndicatorByPk($indicatorId: uuid!, $data: project_project_indicator_set_input) {
  objective: update_project_project_indicator_by_pk(
    pk_columns: {id: $indicatorId}
    _set: $data
  ) {
    ...ProjectIndicator
  }
}
    ${ProjectIndicatorFragmentDoc}`;
export type UpdateProjectIndicatorByPkMutationFn = Apollo.MutationFunction<UpdateProjectIndicatorByPkMutation, UpdateProjectIndicatorByPkMutationVariables>;
export type UpdateProjectIndicatorByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateProjectIndicatorByPkMutation, UpdateProjectIndicatorByPkMutationVariables>, 'mutation'>;

    export const UpdateProjectIndicatorByPkComponent = (props: UpdateProjectIndicatorByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateProjectIndicatorByPkMutation, UpdateProjectIndicatorByPkMutationVariables> mutation={UpdateProjectIndicatorByPkDocument} {...props} />
    );
    

/**
 * __useUpdateProjectIndicatorByPkMutation__
 *
 * To run a mutation, you first call `useUpdateProjectIndicatorByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectIndicatorByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectIndicatorByPkMutation, { data, loading, error }] = useUpdateProjectIndicatorByPkMutation({
 *   variables: {
 *      indicatorId: // value for 'indicatorId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProjectIndicatorByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectIndicatorByPkMutation, UpdateProjectIndicatorByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectIndicatorByPkMutation, UpdateProjectIndicatorByPkMutationVariables>(UpdateProjectIndicatorByPkDocument, options);
      }
export type UpdateProjectIndicatorByPkMutationHookResult = ReturnType<typeof useUpdateProjectIndicatorByPkMutation>;
export type UpdateProjectIndicatorByPkMutationResult = Apollo.MutationResult<UpdateProjectIndicatorByPkMutation>;
export type UpdateProjectIndicatorByPkMutationOptions = Apollo.BaseMutationOptions<UpdateProjectIndicatorByPkMutation, UpdateProjectIndicatorByPkMutationVariables>;
export const DeleteProjectIndicatorByPkDocument = gql`
    mutation DeleteProjectIndicatorByPk($indicatorId: uuid!) {
  objective: delete_project_project_indicator_by_pk(id: $indicatorId) {
    ...ProjectIndicator
  }
}
    ${ProjectIndicatorFragmentDoc}`;
export type DeleteProjectIndicatorByPkMutationFn = Apollo.MutationFunction<DeleteProjectIndicatorByPkMutation, DeleteProjectIndicatorByPkMutationVariables>;
export type DeleteProjectIndicatorByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteProjectIndicatorByPkMutation, DeleteProjectIndicatorByPkMutationVariables>, 'mutation'>;

    export const DeleteProjectIndicatorByPkComponent = (props: DeleteProjectIndicatorByPkComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteProjectIndicatorByPkMutation, DeleteProjectIndicatorByPkMutationVariables> mutation={DeleteProjectIndicatorByPkDocument} {...props} />
    );
    

/**
 * __useDeleteProjectIndicatorByPkMutation__
 *
 * To run a mutation, you first call `useDeleteProjectIndicatorByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectIndicatorByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectIndicatorByPkMutation, { data, loading, error }] = useDeleteProjectIndicatorByPkMutation({
 *   variables: {
 *      indicatorId: // value for 'indicatorId'
 *   },
 * });
 */
export function useDeleteProjectIndicatorByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectIndicatorByPkMutation, DeleteProjectIndicatorByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectIndicatorByPkMutation, DeleteProjectIndicatorByPkMutationVariables>(DeleteProjectIndicatorByPkDocument, options);
      }
export type DeleteProjectIndicatorByPkMutationHookResult = ReturnType<typeof useDeleteProjectIndicatorByPkMutation>;
export type DeleteProjectIndicatorByPkMutationResult = Apollo.MutationResult<DeleteProjectIndicatorByPkMutation>;
export type DeleteProjectIndicatorByPkMutationOptions = Apollo.BaseMutationOptions<DeleteProjectIndicatorByPkMutation, DeleteProjectIndicatorByPkMutationVariables>;
export const InsertProjectAchievementOneDocument = gql`
    mutation InsertProjectAchievementOne($data: project_project_achievement_insert_input!) {
  objective: insert_project_project_achievement_one(object: $data) {
    ...ProjectAchievement
  }
}
    ${ProjectAchievementFragmentDoc}`;
export type InsertProjectAchievementOneMutationFn = Apollo.MutationFunction<InsertProjectAchievementOneMutation, InsertProjectAchievementOneMutationVariables>;
export type InsertProjectAchievementOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertProjectAchievementOneMutation, InsertProjectAchievementOneMutationVariables>, 'mutation'>;

    export const InsertProjectAchievementOneComponent = (props: InsertProjectAchievementOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertProjectAchievementOneMutation, InsertProjectAchievementOneMutationVariables> mutation={InsertProjectAchievementOneDocument} {...props} />
    );
    

/**
 * __useInsertProjectAchievementOneMutation__
 *
 * To run a mutation, you first call `useInsertProjectAchievementOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertProjectAchievementOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProjectAchievementOneMutation, { data, loading, error }] = useInsertProjectAchievementOneMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertProjectAchievementOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertProjectAchievementOneMutation, InsertProjectAchievementOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertProjectAchievementOneMutation, InsertProjectAchievementOneMutationVariables>(InsertProjectAchievementOneDocument, options);
      }
export type InsertProjectAchievementOneMutationHookResult = ReturnType<typeof useInsertProjectAchievementOneMutation>;
export type InsertProjectAchievementOneMutationResult = Apollo.MutationResult<InsertProjectAchievementOneMutation>;
export type InsertProjectAchievementOneMutationOptions = Apollo.BaseMutationOptions<InsertProjectAchievementOneMutation, InsertProjectAchievementOneMutationVariables>;
export const UpdateProjectAchievementByPkDocument = gql`
    mutation UpdateProjectAchievementByPk($achievementId: uuid!, $data: project_project_achievement_set_input) {
  objective: update_project_project_achievement_by_pk(
    pk_columns: {id: $achievementId}
    _set: $data
  ) {
    ...ProjectAchievement
  }
}
    ${ProjectAchievementFragmentDoc}`;
export type UpdateProjectAchievementByPkMutationFn = Apollo.MutationFunction<UpdateProjectAchievementByPkMutation, UpdateProjectAchievementByPkMutationVariables>;
export type UpdateProjectAchievementByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateProjectAchievementByPkMutation, UpdateProjectAchievementByPkMutationVariables>, 'mutation'>;

    export const UpdateProjectAchievementByPkComponent = (props: UpdateProjectAchievementByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateProjectAchievementByPkMutation, UpdateProjectAchievementByPkMutationVariables> mutation={UpdateProjectAchievementByPkDocument} {...props} />
    );
    

/**
 * __useUpdateProjectAchievementByPkMutation__
 *
 * To run a mutation, you first call `useUpdateProjectAchievementByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectAchievementByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectAchievementByPkMutation, { data, loading, error }] = useUpdateProjectAchievementByPkMutation({
 *   variables: {
 *      achievementId: // value for 'achievementId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProjectAchievementByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectAchievementByPkMutation, UpdateProjectAchievementByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectAchievementByPkMutation, UpdateProjectAchievementByPkMutationVariables>(UpdateProjectAchievementByPkDocument, options);
      }
export type UpdateProjectAchievementByPkMutationHookResult = ReturnType<typeof useUpdateProjectAchievementByPkMutation>;
export type UpdateProjectAchievementByPkMutationResult = Apollo.MutationResult<UpdateProjectAchievementByPkMutation>;
export type UpdateProjectAchievementByPkMutationOptions = Apollo.BaseMutationOptions<UpdateProjectAchievementByPkMutation, UpdateProjectAchievementByPkMutationVariables>;
export const DeleteProjectAchievementByPkDocument = gql`
    mutation DeleteProjectAchievementByPk($achievementId: uuid!) {
  objective: delete_project_project_achievement_by_pk(id: $achievementId) {
    ...ProjectAchievement
  }
}
    ${ProjectAchievementFragmentDoc}`;
export type DeleteProjectAchievementByPkMutationFn = Apollo.MutationFunction<DeleteProjectAchievementByPkMutation, DeleteProjectAchievementByPkMutationVariables>;
export type DeleteProjectAchievementByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteProjectAchievementByPkMutation, DeleteProjectAchievementByPkMutationVariables>, 'mutation'>;

    export const DeleteProjectAchievementByPkComponent = (props: DeleteProjectAchievementByPkComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteProjectAchievementByPkMutation, DeleteProjectAchievementByPkMutationVariables> mutation={DeleteProjectAchievementByPkDocument} {...props} />
    );
    

/**
 * __useDeleteProjectAchievementByPkMutation__
 *
 * To run a mutation, you first call `useDeleteProjectAchievementByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectAchievementByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectAchievementByPkMutation, { data, loading, error }] = useDeleteProjectAchievementByPkMutation({
 *   variables: {
 *      achievementId: // value for 'achievementId'
 *   },
 * });
 */
export function useDeleteProjectAchievementByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectAchievementByPkMutation, DeleteProjectAchievementByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectAchievementByPkMutation, DeleteProjectAchievementByPkMutationVariables>(DeleteProjectAchievementByPkDocument, options);
      }
export type DeleteProjectAchievementByPkMutationHookResult = ReturnType<typeof useDeleteProjectAchievementByPkMutation>;
export type DeleteProjectAchievementByPkMutationResult = Apollo.MutationResult<DeleteProjectAchievementByPkMutation>;
export type DeleteProjectAchievementByPkMutationOptions = Apollo.BaseMutationOptions<DeleteProjectAchievementByPkMutation, DeleteProjectAchievementByPkMutationVariables>;
export const InsertProjectMemberOneDocument = gql`
    mutation InsertProjectMemberOne($data: project_project_member_insert_input!) {
  member: insert_project_project_member_one(object: $data) {
    ...ProjectMember
  }
}
    ${ProjectMemberFragmentDoc}`;
export type InsertProjectMemberOneMutationFn = Apollo.MutationFunction<InsertProjectMemberOneMutation, InsertProjectMemberOneMutationVariables>;
export type InsertProjectMemberOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertProjectMemberOneMutation, InsertProjectMemberOneMutationVariables>, 'mutation'>;

    export const InsertProjectMemberOneComponent = (props: InsertProjectMemberOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertProjectMemberOneMutation, InsertProjectMemberOneMutationVariables> mutation={InsertProjectMemberOneDocument} {...props} />
    );
    

/**
 * __useInsertProjectMemberOneMutation__
 *
 * To run a mutation, you first call `useInsertProjectMemberOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertProjectMemberOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProjectMemberOneMutation, { data, loading, error }] = useInsertProjectMemberOneMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertProjectMemberOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertProjectMemberOneMutation, InsertProjectMemberOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertProjectMemberOneMutation, InsertProjectMemberOneMutationVariables>(InsertProjectMemberOneDocument, options);
      }
export type InsertProjectMemberOneMutationHookResult = ReturnType<typeof useInsertProjectMemberOneMutation>;
export type InsertProjectMemberOneMutationResult = Apollo.MutationResult<InsertProjectMemberOneMutation>;
export type InsertProjectMemberOneMutationOptions = Apollo.BaseMutationOptions<InsertProjectMemberOneMutation, InsertProjectMemberOneMutationVariables>;
export const UpdateProjectMemberByPkDocument = gql`
    mutation UpdateProjectMemberByPk($memberId: uuid!, $data: project_project_member_set_input) {
  member: update_project_project_member_by_pk(
    pk_columns: {id: $memberId}
    _set: $data
  ) {
    ...ProjectMember
  }
}
    ${ProjectMemberFragmentDoc}`;
export type UpdateProjectMemberByPkMutationFn = Apollo.MutationFunction<UpdateProjectMemberByPkMutation, UpdateProjectMemberByPkMutationVariables>;
export type UpdateProjectMemberByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateProjectMemberByPkMutation, UpdateProjectMemberByPkMutationVariables>, 'mutation'>;

    export const UpdateProjectMemberByPkComponent = (props: UpdateProjectMemberByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateProjectMemberByPkMutation, UpdateProjectMemberByPkMutationVariables> mutation={UpdateProjectMemberByPkDocument} {...props} />
    );
    

/**
 * __useUpdateProjectMemberByPkMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMemberByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMemberByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMemberByPkMutation, { data, loading, error }] = useUpdateProjectMemberByPkMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProjectMemberByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMemberByPkMutation, UpdateProjectMemberByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectMemberByPkMutation, UpdateProjectMemberByPkMutationVariables>(UpdateProjectMemberByPkDocument, options);
      }
export type UpdateProjectMemberByPkMutationHookResult = ReturnType<typeof useUpdateProjectMemberByPkMutation>;
export type UpdateProjectMemberByPkMutationResult = Apollo.MutationResult<UpdateProjectMemberByPkMutation>;
export type UpdateProjectMemberByPkMutationOptions = Apollo.BaseMutationOptions<UpdateProjectMemberByPkMutation, UpdateProjectMemberByPkMutationVariables>;
export const DeleteProjectMemberByPkDocument = gql`
    mutation DeleteProjectMemberByPk($memberId: uuid!) {
  member: delete_project_project_member_by_pk(id: $memberId) {
    ...ProjectMember
  }
}
    ${ProjectMemberFragmentDoc}`;
export type DeleteProjectMemberByPkMutationFn = Apollo.MutationFunction<DeleteProjectMemberByPkMutation, DeleteProjectMemberByPkMutationVariables>;
export type DeleteProjectMemberByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteProjectMemberByPkMutation, DeleteProjectMemberByPkMutationVariables>, 'mutation'>;

    export const DeleteProjectMemberByPkComponent = (props: DeleteProjectMemberByPkComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteProjectMemberByPkMutation, DeleteProjectMemberByPkMutationVariables> mutation={DeleteProjectMemberByPkDocument} {...props} />
    );
    

/**
 * __useDeleteProjectMemberByPkMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMemberByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMemberByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMemberByPkMutation, { data, loading, error }] = useDeleteProjectMemberByPkMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useDeleteProjectMemberByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectMemberByPkMutation, DeleteProjectMemberByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectMemberByPkMutation, DeleteProjectMemberByPkMutationVariables>(DeleteProjectMemberByPkDocument, options);
      }
export type DeleteProjectMemberByPkMutationHookResult = ReturnType<typeof useDeleteProjectMemberByPkMutation>;
export type DeleteProjectMemberByPkMutationResult = Apollo.MutationResult<DeleteProjectMemberByPkMutation>;
export type DeleteProjectMemberByPkMutationOptions = Apollo.BaseMutationOptions<DeleteProjectMemberByPkMutation, DeleteProjectMemberByPkMutationVariables>;
export const GetProjectsDocument = gql`
    query GetProjects($distinctOn: [project_project_select_column!], $limit: Int, $offset: Int, $orderBy: [project_project_order_by!], $where: project_project_bool_exp) {
  rows: project_project(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;
export type GetProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectsQuery, GetProjectsQueryVariables>, 'query'>;

    export const GetProjectsComponent = (props: GetProjectsComponentProps) => (
      <ApolloReactComponents.Query<GetProjectsQuery, GetProjectsQueryVariables> query={GetProjectsDocument} {...props} />
    );
    

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetProjectsQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
      }
export function useGetProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
        }
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<typeof useGetProjectsLazyQuery>;
export type GetProjectsQueryResult = Apollo.QueryResult<GetProjectsQuery, GetProjectsQueryVariables>;
export const GetProjectByPkDocument = gql`
    query GetProjectByPk($id: uuid!) {
  row: project_project_by_pk(id: $id) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;
export type GetProjectByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectByPkQuery, GetProjectByPkQueryVariables>, 'query'> & ({ variables: GetProjectByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetProjectByPkComponent = (props: GetProjectByPkComponentProps) => (
      <ApolloReactComponents.Query<GetProjectByPkQuery, GetProjectByPkQueryVariables> query={GetProjectByPkDocument} {...props} />
    );
    

/**
 * __useGetProjectByPkQuery__
 *
 * To run a query within a React component, call `useGetProjectByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectByPkQuery(baseOptions: Apollo.QueryHookOptions<GetProjectByPkQuery, GetProjectByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectByPkQuery, GetProjectByPkQueryVariables>(GetProjectByPkDocument, options);
      }
export function useGetProjectByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectByPkQuery, GetProjectByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectByPkQuery, GetProjectByPkQueryVariables>(GetProjectByPkDocument, options);
        }
export type GetProjectByPkQueryHookResult = ReturnType<typeof useGetProjectByPkQuery>;
export type GetProjectByPkLazyQueryHookResult = ReturnType<typeof useGetProjectByPkLazyQuery>;
export type GetProjectByPkQueryResult = Apollo.QueryResult<GetProjectByPkQuery, GetProjectByPkQueryVariables>;
export const GetProjectMasterDataDocument = gql`
    query GetProjectMasterData {
  target_groups: project_target_group {
    id
    description
  }
  regions: region {
    id
    name_th
    provinces {
      id
      code
      name_th
    }
  }
  units: project_unit {
    id
    description
  }
  activity_types: project_operating_activity_type {
    id
    description
  }
  indicator_types: project_indicator_type {
    id
    description
  }
  budget_groups: project_budget_group {
    id
    description
  }
  budgets: project_budget {
    id
    name
    price_per_unit
    unit_id
    fiscal_year
    deleted_at
    unit_of_multiplier_id
    unit_of_multiplier {
      description
    }
    unit {
      description
    }
  }
  budget_group_mapping: project_budget_group_mapping {
    budget_id
    budget_group_id
  }
}
    `;
export type GetProjectMasterDataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectMasterDataQuery, GetProjectMasterDataQueryVariables>, 'query'>;

    export const GetProjectMasterDataComponent = (props: GetProjectMasterDataComponentProps) => (
      <ApolloReactComponents.Query<GetProjectMasterDataQuery, GetProjectMasterDataQueryVariables> query={GetProjectMasterDataDocument} {...props} />
    );
    

/**
 * __useGetProjectMasterDataQuery__
 *
 * To run a query within a React component, call `useGetProjectMasterDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectMasterDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectMasterDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectMasterDataQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectMasterDataQuery, GetProjectMasterDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectMasterDataQuery, GetProjectMasterDataQueryVariables>(GetProjectMasterDataDocument, options);
      }
export function useGetProjectMasterDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectMasterDataQuery, GetProjectMasterDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectMasterDataQuery, GetProjectMasterDataQueryVariables>(GetProjectMasterDataDocument, options);
        }
export type GetProjectMasterDataQueryHookResult = ReturnType<typeof useGetProjectMasterDataQuery>;
export type GetProjectMasterDataLazyQueryHookResult = ReturnType<typeof useGetProjectMasterDataLazyQuery>;
export type GetProjectMasterDataQueryResult = Apollo.QueryResult<GetProjectMasterDataQuery, GetProjectMasterDataQueryVariables>;
export const GetProjectByPkWithMasterDataDocument = gql`
    query GetProjectByPkWithMasterData($id: uuid!) {
  project: project_project_by_pk(id: $id) {
    ...Project
  }
  project_budget_items: project_project_budget_item(
    where: {project_operating_activity: {project_id: {_eq: $id}}}
  ) {
    ...ProjectBudgetItem
  }
  target_groups: project_target_group {
    id
    description
  }
  regions: region {
    id
    name_th
    provinces {
      id
      code
      name_th
    }
  }
  units: project_unit {
    id
    description
  }
  activity_types: project_operating_activity_type {
    id
    description
  }
  indicator_types: project_indicator_type {
    id
    description
  }
  budget_groups: project_budget_group {
    id
    description
  }
  budgets: project_budget {
    id
    name
    price_per_unit
    unit_id
    fiscal_year
    deleted_at
    unit_of_multiplier_id
    unit_of_multiplier {
      description
    }
    unit {
      description
    }
  }
  budget_group_mapping: project_budget_group_mapping {
    budget_id
    budget_group_id
  }
  event_logs: project_project_event_log(
    where: {project_id: {_eq: $id}}
    order_by: [{created_at: desc}]
  ) {
    ...ProjectEventLog
  }
}
    ${ProjectFragmentDoc}
${ProjectBudgetItemFragmentDoc}
${ProjectEventLogFragmentDoc}`;
export type GetProjectByPkWithMasterDataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectByPkWithMasterDataQuery, GetProjectByPkWithMasterDataQueryVariables>, 'query'> & ({ variables: GetProjectByPkWithMasterDataQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetProjectByPkWithMasterDataComponent = (props: GetProjectByPkWithMasterDataComponentProps) => (
      <ApolloReactComponents.Query<GetProjectByPkWithMasterDataQuery, GetProjectByPkWithMasterDataQueryVariables> query={GetProjectByPkWithMasterDataDocument} {...props} />
    );
    

/**
 * __useGetProjectByPkWithMasterDataQuery__
 *
 * To run a query within a React component, call `useGetProjectByPkWithMasterDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectByPkWithMasterDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectByPkWithMasterDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectByPkWithMasterDataQuery(baseOptions: Apollo.QueryHookOptions<GetProjectByPkWithMasterDataQuery, GetProjectByPkWithMasterDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectByPkWithMasterDataQuery, GetProjectByPkWithMasterDataQueryVariables>(GetProjectByPkWithMasterDataDocument, options);
      }
export function useGetProjectByPkWithMasterDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectByPkWithMasterDataQuery, GetProjectByPkWithMasterDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectByPkWithMasterDataQuery, GetProjectByPkWithMasterDataQueryVariables>(GetProjectByPkWithMasterDataDocument, options);
        }
export type GetProjectByPkWithMasterDataQueryHookResult = ReturnType<typeof useGetProjectByPkWithMasterDataQuery>;
export type GetProjectByPkWithMasterDataLazyQueryHookResult = ReturnType<typeof useGetProjectByPkWithMasterDataLazyQuery>;
export type GetProjectByPkWithMasterDataQueryResult = Apollo.QueryResult<GetProjectByPkWithMasterDataQuery, GetProjectByPkWithMasterDataQueryVariables>;
export const GetProjectBudgetItemsByProjectIdDocument = gql`
    query GetProjectBudgetItemsByProjectId($projectId: uuid!, $fiscalYear: Int!) {
  budgets: project_budget(
    where: {fiscal_year: {_eq: $fiscalYear}, deleted_at: {_is_null: true}}
  ) {
    id
    fiscal_year
    name
    price_per_unit
    unit_id
    unit_of_multiplier_id
  }
  project_budget_items: project_project_budget_item(
    where: {project_operating_activity: {project_id: {_eq: $projectId}}}
  ) {
    ...ProjectBudgetItem
  }
}
    ${ProjectBudgetItemFragmentDoc}`;
export type GetProjectBudgetItemsByProjectIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectBudgetItemsByProjectIdQuery, GetProjectBudgetItemsByProjectIdQueryVariables>, 'query'> & ({ variables: GetProjectBudgetItemsByProjectIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetProjectBudgetItemsByProjectIdComponent = (props: GetProjectBudgetItemsByProjectIdComponentProps) => (
      <ApolloReactComponents.Query<GetProjectBudgetItemsByProjectIdQuery, GetProjectBudgetItemsByProjectIdQueryVariables> query={GetProjectBudgetItemsByProjectIdDocument} {...props} />
    );
    

/**
 * __useGetProjectBudgetItemsByProjectIdQuery__
 *
 * To run a query within a React component, call `useGetProjectBudgetItemsByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectBudgetItemsByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectBudgetItemsByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      fiscalYear: // value for 'fiscalYear'
 *   },
 * });
 */
export function useGetProjectBudgetItemsByProjectIdQuery(baseOptions: Apollo.QueryHookOptions<GetProjectBudgetItemsByProjectIdQuery, GetProjectBudgetItemsByProjectIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectBudgetItemsByProjectIdQuery, GetProjectBudgetItemsByProjectIdQueryVariables>(GetProjectBudgetItemsByProjectIdDocument, options);
      }
export function useGetProjectBudgetItemsByProjectIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectBudgetItemsByProjectIdQuery, GetProjectBudgetItemsByProjectIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectBudgetItemsByProjectIdQuery, GetProjectBudgetItemsByProjectIdQueryVariables>(GetProjectBudgetItemsByProjectIdDocument, options);
        }
export type GetProjectBudgetItemsByProjectIdQueryHookResult = ReturnType<typeof useGetProjectBudgetItemsByProjectIdQuery>;
export type GetProjectBudgetItemsByProjectIdLazyQueryHookResult = ReturnType<typeof useGetProjectBudgetItemsByProjectIdLazyQuery>;
export type GetProjectBudgetItemsByProjectIdQueryResult = Apollo.QueryResult<GetProjectBudgetItemsByProjectIdQuery, GetProjectBudgetItemsByProjectIdQueryVariables>;
export const GetProjectEventLogsByProjectIdDocument = gql`
    query GetProjectEventLogsByProjectId($projectId: uuid!) {
  rows: project_project_event_log(
    where: {project_id: {_eq: $projectId}}
    order_by: [{created_at: desc}]
  ) {
    ...ProjectEventLog
  }
}
    ${ProjectEventLogFragmentDoc}`;
export type GetProjectEventLogsByProjectIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectEventLogsByProjectIdQuery, GetProjectEventLogsByProjectIdQueryVariables>, 'query'> & ({ variables: GetProjectEventLogsByProjectIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetProjectEventLogsByProjectIdComponent = (props: GetProjectEventLogsByProjectIdComponentProps) => (
      <ApolloReactComponents.Query<GetProjectEventLogsByProjectIdQuery, GetProjectEventLogsByProjectIdQueryVariables> query={GetProjectEventLogsByProjectIdDocument} {...props} />
    );
    

/**
 * __useGetProjectEventLogsByProjectIdQuery__
 *
 * To run a query within a React component, call `useGetProjectEventLogsByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectEventLogsByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectEventLogsByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectEventLogsByProjectIdQuery(baseOptions: Apollo.QueryHookOptions<GetProjectEventLogsByProjectIdQuery, GetProjectEventLogsByProjectIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectEventLogsByProjectIdQuery, GetProjectEventLogsByProjectIdQueryVariables>(GetProjectEventLogsByProjectIdDocument, options);
      }
export function useGetProjectEventLogsByProjectIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectEventLogsByProjectIdQuery, GetProjectEventLogsByProjectIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectEventLogsByProjectIdQuery, GetProjectEventLogsByProjectIdQueryVariables>(GetProjectEventLogsByProjectIdDocument, options);
        }
export type GetProjectEventLogsByProjectIdQueryHookResult = ReturnType<typeof useGetProjectEventLogsByProjectIdQuery>;
export type GetProjectEventLogsByProjectIdLazyQueryHookResult = ReturnType<typeof useGetProjectEventLogsByProjectIdLazyQuery>;
export type GetProjectEventLogsByProjectIdQueryResult = Apollo.QueryResult<GetProjectEventLogsByProjectIdQuery, GetProjectEventLogsByProjectIdQueryVariables>;
export const GetProjectOperatingActivitiesByProjectIdDocument = gql`
    query GetProjectOperatingActivitiesByProjectId($projectId: uuid!) {
  project: project_project_by_pk(id: $projectId) {
    ...ProjectPartial
  }
  rows: project_project_operating_activity(
    where: {project_id: {_eq: $projectId}}
    order_by: {sequence: asc}
  ) {
    ...ProjectOperatingActivityWithProgresses
  }
}
    ${ProjectPartialFragmentDoc}
${ProjectOperatingActivityWithProgressesFragmentDoc}`;
export type GetProjectOperatingActivitiesByProjectIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectOperatingActivitiesByProjectIdQuery, GetProjectOperatingActivitiesByProjectIdQueryVariables>, 'query'> & ({ variables: GetProjectOperatingActivitiesByProjectIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetProjectOperatingActivitiesByProjectIdComponent = (props: GetProjectOperatingActivitiesByProjectIdComponentProps) => (
      <ApolloReactComponents.Query<GetProjectOperatingActivitiesByProjectIdQuery, GetProjectOperatingActivitiesByProjectIdQueryVariables> query={GetProjectOperatingActivitiesByProjectIdDocument} {...props} />
    );
    

/**
 * __useGetProjectOperatingActivitiesByProjectIdQuery__
 *
 * To run a query within a React component, call `useGetProjectOperatingActivitiesByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectOperatingActivitiesByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectOperatingActivitiesByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectOperatingActivitiesByProjectIdQuery(baseOptions: Apollo.QueryHookOptions<GetProjectOperatingActivitiesByProjectIdQuery, GetProjectOperatingActivitiesByProjectIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectOperatingActivitiesByProjectIdQuery, GetProjectOperatingActivitiesByProjectIdQueryVariables>(GetProjectOperatingActivitiesByProjectIdDocument, options);
      }
export function useGetProjectOperatingActivitiesByProjectIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectOperatingActivitiesByProjectIdQuery, GetProjectOperatingActivitiesByProjectIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectOperatingActivitiesByProjectIdQuery, GetProjectOperatingActivitiesByProjectIdQueryVariables>(GetProjectOperatingActivitiesByProjectIdDocument, options);
        }
export type GetProjectOperatingActivitiesByProjectIdQueryHookResult = ReturnType<typeof useGetProjectOperatingActivitiesByProjectIdQuery>;
export type GetProjectOperatingActivitiesByProjectIdLazyQueryHookResult = ReturnType<typeof useGetProjectOperatingActivitiesByProjectIdLazyQuery>;
export type GetProjectOperatingActivitiesByProjectIdQueryResult = Apollo.QueryResult<GetProjectOperatingActivitiesByProjectIdQuery, GetProjectOperatingActivitiesByProjectIdQueryVariables>;
export const GetProjectActivitiesByProjectIdDocument = gql`
    query GetProjectActivitiesByProjectId($projectId: uuid!) {
  activities: project_project_operating_activity(
    where: {project_id: {_eq: $projectId}}
    order_by: {sequence: asc}
  ) {
    ...ProjectOperatingActivityWithBudget
  }
  budget_groups: project_budget_group {
    id
    description
  }
  budgets: project_project_budget_template(where: {project_id: {_eq: $projectId}}) {
    ...ProjectBudgetTemplate
  }
  budget_group_mapping: project_budget_group_mapping {
    budget_id
    budget_group_id
  }
  units: project_unit {
    id
    description
  }
}
    ${ProjectOperatingActivityWithBudgetFragmentDoc}
${ProjectBudgetTemplateFragmentDoc}`;
export type GetProjectActivitiesByProjectIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectActivitiesByProjectIdQuery, GetProjectActivitiesByProjectIdQueryVariables>, 'query'> & ({ variables: GetProjectActivitiesByProjectIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetProjectActivitiesByProjectIdComponent = (props: GetProjectActivitiesByProjectIdComponentProps) => (
      <ApolloReactComponents.Query<GetProjectActivitiesByProjectIdQuery, GetProjectActivitiesByProjectIdQueryVariables> query={GetProjectActivitiesByProjectIdDocument} {...props} />
    );
    

/**
 * __useGetProjectActivitiesByProjectIdQuery__
 *
 * To run a query within a React component, call `useGetProjectActivitiesByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectActivitiesByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectActivitiesByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectActivitiesByProjectIdQuery(baseOptions: Apollo.QueryHookOptions<GetProjectActivitiesByProjectIdQuery, GetProjectActivitiesByProjectIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectActivitiesByProjectIdQuery, GetProjectActivitiesByProjectIdQueryVariables>(GetProjectActivitiesByProjectIdDocument, options);
      }
export function useGetProjectActivitiesByProjectIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectActivitiesByProjectIdQuery, GetProjectActivitiesByProjectIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectActivitiesByProjectIdQuery, GetProjectActivitiesByProjectIdQueryVariables>(GetProjectActivitiesByProjectIdDocument, options);
        }
export type GetProjectActivitiesByProjectIdQueryHookResult = ReturnType<typeof useGetProjectActivitiesByProjectIdQuery>;
export type GetProjectActivitiesByProjectIdLazyQueryHookResult = ReturnType<typeof useGetProjectActivitiesByProjectIdLazyQuery>;
export type GetProjectActivitiesByProjectIdQueryResult = Apollo.QueryResult<GetProjectActivitiesByProjectIdQuery, GetProjectActivitiesByProjectIdQueryVariables>;
export const GetProjectAttachmentsByProjectIdDocument = gql`
    query GetProjectAttachmentsByProjectId($projectId: uuid!) {
  attachments: project_project_attachment(where: {project_id: {_eq: $projectId}}) {
    ...ProjectAttachment
  }
}
    ${ProjectAttachmentFragmentDoc}`;
export type GetProjectAttachmentsByProjectIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectAttachmentsByProjectIdQuery, GetProjectAttachmentsByProjectIdQueryVariables>, 'query'> & ({ variables: GetProjectAttachmentsByProjectIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetProjectAttachmentsByProjectIdComponent = (props: GetProjectAttachmentsByProjectIdComponentProps) => (
      <ApolloReactComponents.Query<GetProjectAttachmentsByProjectIdQuery, GetProjectAttachmentsByProjectIdQueryVariables> query={GetProjectAttachmentsByProjectIdDocument} {...props} />
    );
    

/**
 * __useGetProjectAttachmentsByProjectIdQuery__
 *
 * To run a query within a React component, call `useGetProjectAttachmentsByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectAttachmentsByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectAttachmentsByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectAttachmentsByProjectIdQuery(baseOptions: Apollo.QueryHookOptions<GetProjectAttachmentsByProjectIdQuery, GetProjectAttachmentsByProjectIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectAttachmentsByProjectIdQuery, GetProjectAttachmentsByProjectIdQueryVariables>(GetProjectAttachmentsByProjectIdDocument, options);
      }
export function useGetProjectAttachmentsByProjectIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectAttachmentsByProjectIdQuery, GetProjectAttachmentsByProjectIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectAttachmentsByProjectIdQuery, GetProjectAttachmentsByProjectIdQueryVariables>(GetProjectAttachmentsByProjectIdDocument, options);
        }
export type GetProjectAttachmentsByProjectIdQueryHookResult = ReturnType<typeof useGetProjectAttachmentsByProjectIdQuery>;
export type GetProjectAttachmentsByProjectIdLazyQueryHookResult = ReturnType<typeof useGetProjectAttachmentsByProjectIdLazyQuery>;
export type GetProjectAttachmentsByProjectIdQueryResult = Apollo.QueryResult<GetProjectAttachmentsByProjectIdQuery, GetProjectAttachmentsByProjectIdQueryVariables>;
export const GetProjectObjectivesByProjectIdDocument = gql`
    query GetProjectObjectivesByProjectId($projectId: uuid!) {
  objectives: project_project_objective(
    where: {project_id: {_eq: $projectId}}
    order_by: {sequence: asc}
  ) {
    ...ProjectObjective
  }
}
    ${ProjectObjectiveFragmentDoc}`;
export type GetProjectObjectivesByProjectIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectObjectivesByProjectIdQuery, GetProjectObjectivesByProjectIdQueryVariables>, 'query'> & ({ variables: GetProjectObjectivesByProjectIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetProjectObjectivesByProjectIdComponent = (props: GetProjectObjectivesByProjectIdComponentProps) => (
      <ApolloReactComponents.Query<GetProjectObjectivesByProjectIdQuery, GetProjectObjectivesByProjectIdQueryVariables> query={GetProjectObjectivesByProjectIdDocument} {...props} />
    );
    

/**
 * __useGetProjectObjectivesByProjectIdQuery__
 *
 * To run a query within a React component, call `useGetProjectObjectivesByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectObjectivesByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectObjectivesByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectObjectivesByProjectIdQuery(baseOptions: Apollo.QueryHookOptions<GetProjectObjectivesByProjectIdQuery, GetProjectObjectivesByProjectIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectObjectivesByProjectIdQuery, GetProjectObjectivesByProjectIdQueryVariables>(GetProjectObjectivesByProjectIdDocument, options);
      }
export function useGetProjectObjectivesByProjectIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectObjectivesByProjectIdQuery, GetProjectObjectivesByProjectIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectObjectivesByProjectIdQuery, GetProjectObjectivesByProjectIdQueryVariables>(GetProjectObjectivesByProjectIdDocument, options);
        }
export type GetProjectObjectivesByProjectIdQueryHookResult = ReturnType<typeof useGetProjectObjectivesByProjectIdQuery>;
export type GetProjectObjectivesByProjectIdLazyQueryHookResult = ReturnType<typeof useGetProjectObjectivesByProjectIdLazyQuery>;
export type GetProjectObjectivesByProjectIdQueryResult = Apollo.QueryResult<GetProjectObjectivesByProjectIdQuery, GetProjectObjectivesByProjectIdQueryVariables>;
export const GetProjectMembersByProjectIdDocument = gql`
    query GetProjectMembersByProjectId($projectId: uuid!) {
  members: project_project_member(
    where: {project_id: {_eq: $projectId}}
    order_by: {sequence: asc}
  ) {
    ...ProjectMember
  }
}
    ${ProjectMemberFragmentDoc}`;
export type GetProjectMembersByProjectIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectMembersByProjectIdQuery, GetProjectMembersByProjectIdQueryVariables>, 'query'> & ({ variables: GetProjectMembersByProjectIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetProjectMembersByProjectIdComponent = (props: GetProjectMembersByProjectIdComponentProps) => (
      <ApolloReactComponents.Query<GetProjectMembersByProjectIdQuery, GetProjectMembersByProjectIdQueryVariables> query={GetProjectMembersByProjectIdDocument} {...props} />
    );
    

/**
 * __useGetProjectMembersByProjectIdQuery__
 *
 * To run a query within a React component, call `useGetProjectMembersByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectMembersByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectMembersByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectMembersByProjectIdQuery(baseOptions: Apollo.QueryHookOptions<GetProjectMembersByProjectIdQuery, GetProjectMembersByProjectIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectMembersByProjectIdQuery, GetProjectMembersByProjectIdQueryVariables>(GetProjectMembersByProjectIdDocument, options);
      }
export function useGetProjectMembersByProjectIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectMembersByProjectIdQuery, GetProjectMembersByProjectIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectMembersByProjectIdQuery, GetProjectMembersByProjectIdQueryVariables>(GetProjectMembersByProjectIdDocument, options);
        }
export type GetProjectMembersByProjectIdQueryHookResult = ReturnType<typeof useGetProjectMembersByProjectIdQuery>;
export type GetProjectMembersByProjectIdLazyQueryHookResult = ReturnType<typeof useGetProjectMembersByProjectIdLazyQuery>;
export type GetProjectMembersByProjectIdQueryResult = Apollo.QueryResult<GetProjectMembersByProjectIdQuery, GetProjectMembersByProjectIdQueryVariables>;
export const InsertBudgetOneDocument = gql`
    mutation InsertBudgetOne($object: project_budget_insert_input!) {
  insert_project_budget_one(object: $object) {
    ...ProjectBudget
  }
}
    ${ProjectBudgetFragmentDoc}`;
export type InsertBudgetOneMutationFn = Apollo.MutationFunction<InsertBudgetOneMutation, InsertBudgetOneMutationVariables>;
export type InsertBudgetOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertBudgetOneMutation, InsertBudgetOneMutationVariables>, 'mutation'>;

    export const InsertBudgetOneComponent = (props: InsertBudgetOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertBudgetOneMutation, InsertBudgetOneMutationVariables> mutation={InsertBudgetOneDocument} {...props} />
    );
    

/**
 * __useInsertBudgetOneMutation__
 *
 * To run a mutation, you first call `useInsertBudgetOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertBudgetOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertBudgetOneMutation, { data, loading, error }] = useInsertBudgetOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertBudgetOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertBudgetOneMutation, InsertBudgetOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertBudgetOneMutation, InsertBudgetOneMutationVariables>(InsertBudgetOneDocument, options);
      }
export type InsertBudgetOneMutationHookResult = ReturnType<typeof useInsertBudgetOneMutation>;
export type InsertBudgetOneMutationResult = Apollo.MutationResult<InsertBudgetOneMutation>;
export type InsertBudgetOneMutationOptions = Apollo.BaseMutationOptions<InsertBudgetOneMutation, InsertBudgetOneMutationVariables>;
export const UpdateBudgetByPkDocument = gql`
    mutation UpdateBudgetByPk($_set: project_budget_set_input, $id: uuid!) {
  update_project_budget_by_pk(pk_columns: {id: $id}, _set: $_set) {
    ...ProjectBudget
  }
}
    ${ProjectBudgetFragmentDoc}`;
export type UpdateBudgetByPkMutationFn = Apollo.MutationFunction<UpdateBudgetByPkMutation, UpdateBudgetByPkMutationVariables>;
export type UpdateBudgetByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateBudgetByPkMutation, UpdateBudgetByPkMutationVariables>, 'mutation'>;

    export const UpdateBudgetByPkComponent = (props: UpdateBudgetByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateBudgetByPkMutation, UpdateBudgetByPkMutationVariables> mutation={UpdateBudgetByPkDocument} {...props} />
    );
    

/**
 * __useUpdateBudgetByPkMutation__
 *
 * To run a mutation, you first call `useUpdateBudgetByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBudgetByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBudgetByPkMutation, { data, loading, error }] = useUpdateBudgetByPkMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateBudgetByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBudgetByPkMutation, UpdateBudgetByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBudgetByPkMutation, UpdateBudgetByPkMutationVariables>(UpdateBudgetByPkDocument, options);
      }
export type UpdateBudgetByPkMutationHookResult = ReturnType<typeof useUpdateBudgetByPkMutation>;
export type UpdateBudgetByPkMutationResult = Apollo.MutationResult<UpdateBudgetByPkMutation>;
export type UpdateBudgetByPkMutationOptions = Apollo.BaseMutationOptions<UpdateBudgetByPkMutation, UpdateBudgetByPkMutationVariables>;
export const DeleteBudgetByPkDocument = gql`
    mutation DeleteBudgetByPk($id: uuid!) {
  budget: delete_project_budget_by_pk(id: $id) {
    ...ProjectBudget
  }
}
    ${ProjectBudgetFragmentDoc}`;
export type DeleteBudgetByPkMutationFn = Apollo.MutationFunction<DeleteBudgetByPkMutation, DeleteBudgetByPkMutationVariables>;
export type DeleteBudgetByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteBudgetByPkMutation, DeleteBudgetByPkMutationVariables>, 'mutation'>;

    export const DeleteBudgetByPkComponent = (props: DeleteBudgetByPkComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteBudgetByPkMutation, DeleteBudgetByPkMutationVariables> mutation={DeleteBudgetByPkDocument} {...props} />
    );
    

/**
 * __useDeleteBudgetByPkMutation__
 *
 * To run a mutation, you first call `useDeleteBudgetByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBudgetByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBudgetByPkMutation, { data, loading, error }] = useDeleteBudgetByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBudgetByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBudgetByPkMutation, DeleteBudgetByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBudgetByPkMutation, DeleteBudgetByPkMutationVariables>(DeleteBudgetByPkDocument, options);
      }
export type DeleteBudgetByPkMutationHookResult = ReturnType<typeof useDeleteBudgetByPkMutation>;
export type DeleteBudgetByPkMutationResult = Apollo.MutationResult<DeleteBudgetByPkMutation>;
export type DeleteBudgetByPkMutationOptions = Apollo.BaseMutationOptions<DeleteBudgetByPkMutation, DeleteBudgetByPkMutationVariables>;
export const GetBudgetsDocument = gql`
    query GetBudgets($distinctOn: [project_budget_select_column!], $limit: Int, $offset: Int, $orderBy: [project_budget_order_by!], $where: project_budget_bool_exp) {
  rows: project_budget(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...ProjectBudget
  }
}
    ${ProjectBudgetFragmentDoc}`;
export type GetBudgetsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBudgetsQuery, GetBudgetsQueryVariables>, 'query'>;

    export const GetBudgetsComponent = (props: GetBudgetsComponentProps) => (
      <ApolloReactComponents.Query<GetBudgetsQuery, GetBudgetsQueryVariables> query={GetBudgetsDocument} {...props} />
    );
    

/**
 * __useGetBudgetsQuery__
 *
 * To run a query within a React component, call `useGetBudgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBudgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBudgetsQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBudgetsQuery(baseOptions?: Apollo.QueryHookOptions<GetBudgetsQuery, GetBudgetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBudgetsQuery, GetBudgetsQueryVariables>(GetBudgetsDocument, options);
      }
export function useGetBudgetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBudgetsQuery, GetBudgetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBudgetsQuery, GetBudgetsQueryVariables>(GetBudgetsDocument, options);
        }
export type GetBudgetsQueryHookResult = ReturnType<typeof useGetBudgetsQuery>;
export type GetBudgetsLazyQueryHookResult = ReturnType<typeof useGetBudgetsLazyQuery>;
export type GetBudgetsQueryResult = Apollo.QueryResult<GetBudgetsQuery, GetBudgetsQueryVariables>;
export const GetUnitIdDocument = gql`
    query GetUnitId {
  rows: project_unit {
    ...UnitType
  }
}
    ${UnitTypeFragmentDoc}`;
export type GetUnitIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUnitIdQuery, GetUnitIdQueryVariables>, 'query'>;

    export const GetUnitIdComponent = (props: GetUnitIdComponentProps) => (
      <ApolloReactComponents.Query<GetUnitIdQuery, GetUnitIdQueryVariables> query={GetUnitIdDocument} {...props} />
    );
    

/**
 * __useGetUnitIdQuery__
 *
 * To run a query within a React component, call `useGetUnitIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnitIdQuery(baseOptions?: Apollo.QueryHookOptions<GetUnitIdQuery, GetUnitIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitIdQuery, GetUnitIdQueryVariables>(GetUnitIdDocument, options);
      }
export function useGetUnitIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitIdQuery, GetUnitIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitIdQuery, GetUnitIdQueryVariables>(GetUnitIdDocument, options);
        }
export type GetUnitIdQueryHookResult = ReturnType<typeof useGetUnitIdQuery>;
export type GetUnitIdLazyQueryHookResult = ReturnType<typeof useGetUnitIdLazyQuery>;
export type GetUnitIdQueryResult = Apollo.QueryResult<GetUnitIdQuery, GetUnitIdQueryVariables>;
export const GetBudgetByPkDocument = gql`
    query GetBudgetByPk($id: uuid!) {
  row: project_budget_by_pk(id: $id) {
    ...ProjectBudget
  }
}
    ${ProjectBudgetFragmentDoc}`;
export type GetBudgetByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBudgetByPkQuery, GetBudgetByPkQueryVariables>, 'query'> & ({ variables: GetBudgetByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBudgetByPkComponent = (props: GetBudgetByPkComponentProps) => (
      <ApolloReactComponents.Query<GetBudgetByPkQuery, GetBudgetByPkQueryVariables> query={GetBudgetByPkDocument} {...props} />
    );
    

/**
 * __useGetBudgetByPkQuery__
 *
 * To run a query within a React component, call `useGetBudgetByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBudgetByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBudgetByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBudgetByPkQuery(baseOptions: Apollo.QueryHookOptions<GetBudgetByPkQuery, GetBudgetByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBudgetByPkQuery, GetBudgetByPkQueryVariables>(GetBudgetByPkDocument, options);
      }
export function useGetBudgetByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBudgetByPkQuery, GetBudgetByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBudgetByPkQuery, GetBudgetByPkQueryVariables>(GetBudgetByPkDocument, options);
        }
export type GetBudgetByPkQueryHookResult = ReturnType<typeof useGetBudgetByPkQuery>;
export type GetBudgetByPkLazyQueryHookResult = ReturnType<typeof useGetBudgetByPkLazyQuery>;
export type GetBudgetByPkQueryResult = Apollo.QueryResult<GetBudgetByPkQuery, GetBudgetByPkQueryVariables>;
export const GetProjectSummaryDocument = gql`
    query GetProjectSummary {
  projects: project_project(where: {step_id: {_nin: ["cancelled", "rejected"]}}) {
    id
    title
    organization_id
    organization {
      id
      short_name
    }
    budget_summary {
      operating_activity_id
      project_id
      total_budget
      total_spend
      total_spend_plan
      activity {
        id
        name
      }
    }
    workload_summary {
      operating_activity_id
      project_id
      target_amount
      target_unit_id
      target_unit_name
      total_workload
      total_workload_plan
      activity {
        id
        name
      }
    }
  }
}
    `;
export type GetProjectSummaryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectSummaryQuery, GetProjectSummaryQueryVariables>, 'query'>;

    export const GetProjectSummaryComponent = (props: GetProjectSummaryComponentProps) => (
      <ApolloReactComponents.Query<GetProjectSummaryQuery, GetProjectSummaryQueryVariables> query={GetProjectSummaryDocument} {...props} />
    );
    

/**
 * __useGetProjectSummaryQuery__
 *
 * To run a query within a React component, call `useGetProjectSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectSummaryQuery, GetProjectSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectSummaryQuery, GetProjectSummaryQueryVariables>(GetProjectSummaryDocument, options);
      }
export function useGetProjectSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectSummaryQuery, GetProjectSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectSummaryQuery, GetProjectSummaryQueryVariables>(GetProjectSummaryDocument, options);
        }
export type GetProjectSummaryQueryHookResult = ReturnType<typeof useGetProjectSummaryQuery>;
export type GetProjectSummaryLazyQueryHookResult = ReturnType<typeof useGetProjectSummaryLazyQuery>;
export type GetProjectSummaryQueryResult = Apollo.QueryResult<GetProjectSummaryQuery, GetProjectSummaryQueryVariables>;
export const GetProjectBudgetGroupsDocument = gql`
    query GetProjectBudgetGroups($distinctOn: [project_budget_group_select_column!], $limit: Int, $offset: Int, $orderBy: [project_budget_group_order_by!], $where: project_budget_group_bool_exp) {
  rows: project_budget_group(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...ProjectBudgetGroup
  }
}
    ${ProjectBudgetGroupFragmentDoc}`;
export type GetProjectBudgetGroupsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectBudgetGroupsQuery, GetProjectBudgetGroupsQueryVariables>, 'query'>;

    export const GetProjectBudgetGroupsComponent = (props: GetProjectBudgetGroupsComponentProps) => (
      <ApolloReactComponents.Query<GetProjectBudgetGroupsQuery, GetProjectBudgetGroupsQueryVariables> query={GetProjectBudgetGroupsDocument} {...props} />
    );
    

/**
 * __useGetProjectBudgetGroupsQuery__
 *
 * To run a query within a React component, call `useGetProjectBudgetGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectBudgetGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectBudgetGroupsQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetProjectBudgetGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectBudgetGroupsQuery, GetProjectBudgetGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectBudgetGroupsQuery, GetProjectBudgetGroupsQueryVariables>(GetProjectBudgetGroupsDocument, options);
      }
export function useGetProjectBudgetGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectBudgetGroupsQuery, GetProjectBudgetGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectBudgetGroupsQuery, GetProjectBudgetGroupsQueryVariables>(GetProjectBudgetGroupsDocument, options);
        }
export type GetProjectBudgetGroupsQueryHookResult = ReturnType<typeof useGetProjectBudgetGroupsQuery>;
export type GetProjectBudgetGroupsLazyQueryHookResult = ReturnType<typeof useGetProjectBudgetGroupsLazyQuery>;
export type GetProjectBudgetGroupsQueryResult = Apollo.QueryResult<GetProjectBudgetGroupsQuery, GetProjectBudgetGroupsQueryVariables>;
export const GetProjectBudgetGroupByPkDocument = gql`
    query GetProjectBudgetGroupByPk($id: String!) {
  row: project_budget_group_by_pk(id: $id) {
    ...ProjectBudgetGroup
  }
}
    ${ProjectBudgetGroupFragmentDoc}`;
export type GetProjectBudgetGroupByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectBudgetGroupByPkQuery, GetProjectBudgetGroupByPkQueryVariables>, 'query'> & ({ variables: GetProjectBudgetGroupByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetProjectBudgetGroupByPkComponent = (props: GetProjectBudgetGroupByPkComponentProps) => (
      <ApolloReactComponents.Query<GetProjectBudgetGroupByPkQuery, GetProjectBudgetGroupByPkQueryVariables> query={GetProjectBudgetGroupByPkDocument} {...props} />
    );
    

/**
 * __useGetProjectBudgetGroupByPkQuery__
 *
 * To run a query within a React component, call `useGetProjectBudgetGroupByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectBudgetGroupByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectBudgetGroupByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectBudgetGroupByPkQuery(baseOptions: Apollo.QueryHookOptions<GetProjectBudgetGroupByPkQuery, GetProjectBudgetGroupByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectBudgetGroupByPkQuery, GetProjectBudgetGroupByPkQueryVariables>(GetProjectBudgetGroupByPkDocument, options);
      }
export function useGetProjectBudgetGroupByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectBudgetGroupByPkQuery, GetProjectBudgetGroupByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectBudgetGroupByPkQuery, GetProjectBudgetGroupByPkQueryVariables>(GetProjectBudgetGroupByPkDocument, options);
        }
export type GetProjectBudgetGroupByPkQueryHookResult = ReturnType<typeof useGetProjectBudgetGroupByPkQuery>;
export type GetProjectBudgetGroupByPkLazyQueryHookResult = ReturnType<typeof useGetProjectBudgetGroupByPkLazyQuery>;
export type GetProjectBudgetGroupByPkQueryResult = Apollo.QueryResult<GetProjectBudgetGroupByPkQuery, GetProjectBudgetGroupByPkQueryVariables>;
export const GetProjectBudgetsByFiscalYearAndGroupIdDocument = gql`
    query GetProjectBudgetsByFiscalYearAndGroupId($fiscalYear: Int!, $budgetGroupId: project_budget_group_enum!) {
  budgets: project_budget(where: {fiscal_year: {_eq: $fiscalYear}}) {
    ...ProjectBudgetGroupMapping
  }
}
    ${ProjectBudgetGroupMappingFragmentDoc}`;
export type GetProjectBudgetsByFiscalYearAndGroupIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectBudgetsByFiscalYearAndGroupIdQuery, GetProjectBudgetsByFiscalYearAndGroupIdQueryVariables>, 'query'> & ({ variables: GetProjectBudgetsByFiscalYearAndGroupIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetProjectBudgetsByFiscalYearAndGroupIdComponent = (props: GetProjectBudgetsByFiscalYearAndGroupIdComponentProps) => (
      <ApolloReactComponents.Query<GetProjectBudgetsByFiscalYearAndGroupIdQuery, GetProjectBudgetsByFiscalYearAndGroupIdQueryVariables> query={GetProjectBudgetsByFiscalYearAndGroupIdDocument} {...props} />
    );
    

/**
 * __useGetProjectBudgetsByFiscalYearAndGroupIdQuery__
 *
 * To run a query within a React component, call `useGetProjectBudgetsByFiscalYearAndGroupIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectBudgetsByFiscalYearAndGroupIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectBudgetsByFiscalYearAndGroupIdQuery({
 *   variables: {
 *      fiscalYear: // value for 'fiscalYear'
 *      budgetGroupId: // value for 'budgetGroupId'
 *   },
 * });
 */
export function useGetProjectBudgetsByFiscalYearAndGroupIdQuery(baseOptions: Apollo.QueryHookOptions<GetProjectBudgetsByFiscalYearAndGroupIdQuery, GetProjectBudgetsByFiscalYearAndGroupIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectBudgetsByFiscalYearAndGroupIdQuery, GetProjectBudgetsByFiscalYearAndGroupIdQueryVariables>(GetProjectBudgetsByFiscalYearAndGroupIdDocument, options);
      }
export function useGetProjectBudgetsByFiscalYearAndGroupIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectBudgetsByFiscalYearAndGroupIdQuery, GetProjectBudgetsByFiscalYearAndGroupIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectBudgetsByFiscalYearAndGroupIdQuery, GetProjectBudgetsByFiscalYearAndGroupIdQueryVariables>(GetProjectBudgetsByFiscalYearAndGroupIdDocument, options);
        }
export type GetProjectBudgetsByFiscalYearAndGroupIdQueryHookResult = ReturnType<typeof useGetProjectBudgetsByFiscalYearAndGroupIdQuery>;
export type GetProjectBudgetsByFiscalYearAndGroupIdLazyQueryHookResult = ReturnType<typeof useGetProjectBudgetsByFiscalYearAndGroupIdLazyQuery>;
export type GetProjectBudgetsByFiscalYearAndGroupIdQueryResult = Apollo.QueryResult<GetProjectBudgetsByFiscalYearAndGroupIdQuery, GetProjectBudgetsByFiscalYearAndGroupIdQueryVariables>;
export const ReplaceProjectBudgetByFiscalYearAndGroupIdDocument = gql`
    mutation ReplaceProjectBudgetByFiscalYearAndGroupId($fiscalYear: Int!, $budgetGroupId: project_budget_group_enum!, $budgetIds: [uuid!]!, $budgetGroupMappingData: [project_budget_group_mapping_insert_input!]!) {
  delete_project_budget_group_mapping(
    where: {budget_group_id: {_eq: $budgetGroupId}, budget: {fiscal_year: {_eq: $fiscalYear}}, budget_id: {_nin: $budgetIds}}
  ) {
    affected_rows
  }
  insert_project_budget_group_mapping(
    objects: $budgetGroupMappingData
    on_conflict: {constraint: budget_group_mapping_pkey, update_columns: []}
  ) {
    affected_rows
    returning {
      budget_group_id
      budget_id
    }
  }
}
    `;
export type ReplaceProjectBudgetByFiscalYearAndGroupIdMutationFn = Apollo.MutationFunction<ReplaceProjectBudgetByFiscalYearAndGroupIdMutation, ReplaceProjectBudgetByFiscalYearAndGroupIdMutationVariables>;
export type ReplaceProjectBudgetByFiscalYearAndGroupIdComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReplaceProjectBudgetByFiscalYearAndGroupIdMutation, ReplaceProjectBudgetByFiscalYearAndGroupIdMutationVariables>, 'mutation'>;

    export const ReplaceProjectBudgetByFiscalYearAndGroupIdComponent = (props: ReplaceProjectBudgetByFiscalYearAndGroupIdComponentProps) => (
      <ApolloReactComponents.Mutation<ReplaceProjectBudgetByFiscalYearAndGroupIdMutation, ReplaceProjectBudgetByFiscalYearAndGroupIdMutationVariables> mutation={ReplaceProjectBudgetByFiscalYearAndGroupIdDocument} {...props} />
    );
    

/**
 * __useReplaceProjectBudgetByFiscalYearAndGroupIdMutation__
 *
 * To run a mutation, you first call `useReplaceProjectBudgetByFiscalYearAndGroupIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceProjectBudgetByFiscalYearAndGroupIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceProjectBudgetByFiscalYearAndGroupIdMutation, { data, loading, error }] = useReplaceProjectBudgetByFiscalYearAndGroupIdMutation({
 *   variables: {
 *      fiscalYear: // value for 'fiscalYear'
 *      budgetGroupId: // value for 'budgetGroupId'
 *      budgetIds: // value for 'budgetIds'
 *      budgetGroupMappingData: // value for 'budgetGroupMappingData'
 *   },
 * });
 */
export function useReplaceProjectBudgetByFiscalYearAndGroupIdMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceProjectBudgetByFiscalYearAndGroupIdMutation, ReplaceProjectBudgetByFiscalYearAndGroupIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplaceProjectBudgetByFiscalYearAndGroupIdMutation, ReplaceProjectBudgetByFiscalYearAndGroupIdMutationVariables>(ReplaceProjectBudgetByFiscalYearAndGroupIdDocument, options);
      }
export type ReplaceProjectBudgetByFiscalYearAndGroupIdMutationHookResult = ReturnType<typeof useReplaceProjectBudgetByFiscalYearAndGroupIdMutation>;
export type ReplaceProjectBudgetByFiscalYearAndGroupIdMutationResult = Apollo.MutationResult<ReplaceProjectBudgetByFiscalYearAndGroupIdMutation>;
export type ReplaceProjectBudgetByFiscalYearAndGroupIdMutationOptions = Apollo.BaseMutationOptions<ReplaceProjectBudgetByFiscalYearAndGroupIdMutation, ReplaceProjectBudgetByFiscalYearAndGroupIdMutationVariables>;
export const GetProjectBudgetItemSummaryByOrganizationIdDocument = gql`
    query GetProjectBudgetItemSummaryByOrganizationId($organizationId: uuid!) {
  project_project_budget_item_summary(
    where: {organization_id: {_eq: $organizationId}}
  ) {
    id
    amount_unit_name
    budget_group_id
    budget_id
    multipier_unit_name
    multiplier
    note
    operating_activity_name
    organization_id
    price_per_unit
    project_operating_activity_id
    sequence
    target_amount
    target_unit_id
    total
    target_unit_name
    unit_of_multiplier_id
    unit_id
    unit_amount
  }
}
    `;
export type GetProjectBudgetItemSummaryByOrganizationIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectBudgetItemSummaryByOrganizationIdQuery, GetProjectBudgetItemSummaryByOrganizationIdQueryVariables>, 'query'> & ({ variables: GetProjectBudgetItemSummaryByOrganizationIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetProjectBudgetItemSummaryByOrganizationIdComponent = (props: GetProjectBudgetItemSummaryByOrganizationIdComponentProps) => (
      <ApolloReactComponents.Query<GetProjectBudgetItemSummaryByOrganizationIdQuery, GetProjectBudgetItemSummaryByOrganizationIdQueryVariables> query={GetProjectBudgetItemSummaryByOrganizationIdDocument} {...props} />
    );
    

/**
 * __useGetProjectBudgetItemSummaryByOrganizationIdQuery__
 *
 * To run a query within a React component, call `useGetProjectBudgetItemSummaryByOrganizationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectBudgetItemSummaryByOrganizationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectBudgetItemSummaryByOrganizationIdQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetProjectBudgetItemSummaryByOrganizationIdQuery(baseOptions: Apollo.QueryHookOptions<GetProjectBudgetItemSummaryByOrganizationIdQuery, GetProjectBudgetItemSummaryByOrganizationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectBudgetItemSummaryByOrganizationIdQuery, GetProjectBudgetItemSummaryByOrganizationIdQueryVariables>(GetProjectBudgetItemSummaryByOrganizationIdDocument, options);
      }
export function useGetProjectBudgetItemSummaryByOrganizationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectBudgetItemSummaryByOrganizationIdQuery, GetProjectBudgetItemSummaryByOrganizationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectBudgetItemSummaryByOrganizationIdQuery, GetProjectBudgetItemSummaryByOrganizationIdQueryVariables>(GetProjectBudgetItemSummaryByOrganizationIdDocument, options);
        }
export type GetProjectBudgetItemSummaryByOrganizationIdQueryHookResult = ReturnType<typeof useGetProjectBudgetItemSummaryByOrganizationIdQuery>;
export type GetProjectBudgetItemSummaryByOrganizationIdLazyQueryHookResult = ReturnType<typeof useGetProjectBudgetItemSummaryByOrganizationIdLazyQuery>;
export type GetProjectBudgetItemSummaryByOrganizationIdQueryResult = Apollo.QueryResult<GetProjectBudgetItemSummaryByOrganizationIdQuery, GetProjectBudgetItemSummaryByOrganizationIdQueryVariables>;
export const InsertNationalStrategyOneDocument = gql`
    mutation InsertNationalStrategyOne($object: project_national_strategy_insert_input!) {
  national_strategy: insert_project_national_strategy_one(object: $object) {
    ...NationalStrategy
  }
}
    ${NationalStrategyFragmentDoc}`;
export type InsertNationalStrategyOneMutationFn = Apollo.MutationFunction<InsertNationalStrategyOneMutation, InsertNationalStrategyOneMutationVariables>;
export type InsertNationalStrategyOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertNationalStrategyOneMutation, InsertNationalStrategyOneMutationVariables>, 'mutation'>;

    export const InsertNationalStrategyOneComponent = (props: InsertNationalStrategyOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertNationalStrategyOneMutation, InsertNationalStrategyOneMutationVariables> mutation={InsertNationalStrategyOneDocument} {...props} />
    );
    

/**
 * __useInsertNationalStrategyOneMutation__
 *
 * To run a mutation, you first call `useInsertNationalStrategyOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertNationalStrategyOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertNationalStrategyOneMutation, { data, loading, error }] = useInsertNationalStrategyOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertNationalStrategyOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertNationalStrategyOneMutation, InsertNationalStrategyOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertNationalStrategyOneMutation, InsertNationalStrategyOneMutationVariables>(InsertNationalStrategyOneDocument, options);
      }
export type InsertNationalStrategyOneMutationHookResult = ReturnType<typeof useInsertNationalStrategyOneMutation>;
export type InsertNationalStrategyOneMutationResult = Apollo.MutationResult<InsertNationalStrategyOneMutation>;
export type InsertNationalStrategyOneMutationOptions = Apollo.BaseMutationOptions<InsertNationalStrategyOneMutation, InsertNationalStrategyOneMutationVariables>;
export const UpdateNationalStrategyByPkDocument = gql`
    mutation UpdateNationalStrategyByPk($_set: project_national_strategy_set_input, $id: String!) {
  national_strategy: update_project_national_strategy_by_pk(
    pk_columns: {id: $id}
    _set: $_set
  ) {
    ...NationalStrategy
  }
}
    ${NationalStrategyFragmentDoc}`;
export type UpdateNationalStrategyByPkMutationFn = Apollo.MutationFunction<UpdateNationalStrategyByPkMutation, UpdateNationalStrategyByPkMutationVariables>;
export type UpdateNationalStrategyByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateNationalStrategyByPkMutation, UpdateNationalStrategyByPkMutationVariables>, 'mutation'>;

    export const UpdateNationalStrategyByPkComponent = (props: UpdateNationalStrategyByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateNationalStrategyByPkMutation, UpdateNationalStrategyByPkMutationVariables> mutation={UpdateNationalStrategyByPkDocument} {...props} />
    );
    

/**
 * __useUpdateNationalStrategyByPkMutation__
 *
 * To run a mutation, you first call `useUpdateNationalStrategyByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNationalStrategyByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNationalStrategyByPkMutation, { data, loading, error }] = useUpdateNationalStrategyByPkMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateNationalStrategyByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNationalStrategyByPkMutation, UpdateNationalStrategyByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNationalStrategyByPkMutation, UpdateNationalStrategyByPkMutationVariables>(UpdateNationalStrategyByPkDocument, options);
      }
export type UpdateNationalStrategyByPkMutationHookResult = ReturnType<typeof useUpdateNationalStrategyByPkMutation>;
export type UpdateNationalStrategyByPkMutationResult = Apollo.MutationResult<UpdateNationalStrategyByPkMutation>;
export type UpdateNationalStrategyByPkMutationOptions = Apollo.BaseMutationOptions<UpdateNationalStrategyByPkMutation, UpdateNationalStrategyByPkMutationVariables>;
export const DeleteNationalStrategyByPkDocument = gql`
    mutation DeleteNationalStrategyByPk($id: String!) {
  national_strategy: delete_project_national_strategy_by_pk(id: $id) {
    ...NationalStrategy
  }
}
    ${NationalStrategyFragmentDoc}`;
export type DeleteNationalStrategyByPkMutationFn = Apollo.MutationFunction<DeleteNationalStrategyByPkMutation, DeleteNationalStrategyByPkMutationVariables>;
export type DeleteNationalStrategyByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteNationalStrategyByPkMutation, DeleteNationalStrategyByPkMutationVariables>, 'mutation'>;

    export const DeleteNationalStrategyByPkComponent = (props: DeleteNationalStrategyByPkComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteNationalStrategyByPkMutation, DeleteNationalStrategyByPkMutationVariables> mutation={DeleteNationalStrategyByPkDocument} {...props} />
    );
    

/**
 * __useDeleteNationalStrategyByPkMutation__
 *
 * To run a mutation, you first call `useDeleteNationalStrategyByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNationalStrategyByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNationalStrategyByPkMutation, { data, loading, error }] = useDeleteNationalStrategyByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNationalStrategyByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNationalStrategyByPkMutation, DeleteNationalStrategyByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNationalStrategyByPkMutation, DeleteNationalStrategyByPkMutationVariables>(DeleteNationalStrategyByPkDocument, options);
      }
export type DeleteNationalStrategyByPkMutationHookResult = ReturnType<typeof useDeleteNationalStrategyByPkMutation>;
export type DeleteNationalStrategyByPkMutationResult = Apollo.MutationResult<DeleteNationalStrategyByPkMutation>;
export type DeleteNationalStrategyByPkMutationOptions = Apollo.BaseMutationOptions<DeleteNationalStrategyByPkMutation, DeleteNationalStrategyByPkMutationVariables>;
export const GetNationalStrategiesDocument = gql`
    query GetNationalStrategies($distinctOn: [project_national_strategy_select_column!], $limit: Int, $offset: Int, $orderBy: [project_national_strategy_order_by!], $where: project_national_strategy_bool_exp) {
  national_strategies: project_national_strategy(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...NationalStrategy
    parent {
      ...NationalStrategy
    }
  }
}
    ${NationalStrategyFragmentDoc}`;
export type GetNationalStrategiesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetNationalStrategiesQuery, GetNationalStrategiesQueryVariables>, 'query'>;

    export const GetNationalStrategiesComponent = (props: GetNationalStrategiesComponentProps) => (
      <ApolloReactComponents.Query<GetNationalStrategiesQuery, GetNationalStrategiesQueryVariables> query={GetNationalStrategiesDocument} {...props} />
    );
    

/**
 * __useGetNationalStrategiesQuery__
 *
 * To run a query within a React component, call `useGetNationalStrategiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNationalStrategiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNationalStrategiesQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetNationalStrategiesQuery(baseOptions?: Apollo.QueryHookOptions<GetNationalStrategiesQuery, GetNationalStrategiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNationalStrategiesQuery, GetNationalStrategiesQueryVariables>(GetNationalStrategiesDocument, options);
      }
export function useGetNationalStrategiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNationalStrategiesQuery, GetNationalStrategiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNationalStrategiesQuery, GetNationalStrategiesQueryVariables>(GetNationalStrategiesDocument, options);
        }
export type GetNationalStrategiesQueryHookResult = ReturnType<typeof useGetNationalStrategiesQuery>;
export type GetNationalStrategiesLazyQueryHookResult = ReturnType<typeof useGetNationalStrategiesLazyQuery>;
export type GetNationalStrategiesQueryResult = Apollo.QueryResult<GetNationalStrategiesQuery, GetNationalStrategiesQueryVariables>;
export const GetNationalStrategiesTreeViewDocument = gql`
    query GetNationalStrategiesTreeView {
  national_strategies: project_national_strategy(where: {depth: {_eq: 0}}) {
    ...NationalStrategy
    children: national_strategies {
      ...NationalStrategy
      children: national_strategies {
        ...NationalStrategy
        children: national_strategies {
          ...NationalStrategy
          children: national_strategies {
            ...NationalStrategy
          }
        }
      }
    }
  }
}
    ${NationalStrategyFragmentDoc}`;
export type GetNationalStrategiesTreeViewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetNationalStrategiesTreeViewQuery, GetNationalStrategiesTreeViewQueryVariables>, 'query'>;

    export const GetNationalStrategiesTreeViewComponent = (props: GetNationalStrategiesTreeViewComponentProps) => (
      <ApolloReactComponents.Query<GetNationalStrategiesTreeViewQuery, GetNationalStrategiesTreeViewQueryVariables> query={GetNationalStrategiesTreeViewDocument} {...props} />
    );
    

/**
 * __useGetNationalStrategiesTreeViewQuery__
 *
 * To run a query within a React component, call `useGetNationalStrategiesTreeViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNationalStrategiesTreeViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNationalStrategiesTreeViewQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNationalStrategiesTreeViewQuery(baseOptions?: Apollo.QueryHookOptions<GetNationalStrategiesTreeViewQuery, GetNationalStrategiesTreeViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNationalStrategiesTreeViewQuery, GetNationalStrategiesTreeViewQueryVariables>(GetNationalStrategiesTreeViewDocument, options);
      }
export function useGetNationalStrategiesTreeViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNationalStrategiesTreeViewQuery, GetNationalStrategiesTreeViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNationalStrategiesTreeViewQuery, GetNationalStrategiesTreeViewQueryVariables>(GetNationalStrategiesTreeViewDocument, options);
        }
export type GetNationalStrategiesTreeViewQueryHookResult = ReturnType<typeof useGetNationalStrategiesTreeViewQuery>;
export type GetNationalStrategiesTreeViewLazyQueryHookResult = ReturnType<typeof useGetNationalStrategiesTreeViewLazyQuery>;
export type GetNationalStrategiesTreeViewQueryResult = Apollo.QueryResult<GetNationalStrategiesTreeViewQuery, GetNationalStrategiesTreeViewQueryVariables>;
export const GetNationalStrategyByPkDocument = gql`
    query GetNationalStrategyByPk($id: String!) {
  national_strategy: project_national_strategy_by_pk(id: $id) {
    ...NationalStrategy
    children: national_strategies {
      ...NationalStrategy
    }
  }
}
    ${NationalStrategyFragmentDoc}`;
export type GetNationalStrategyByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetNationalStrategyByPkQuery, GetNationalStrategyByPkQueryVariables>, 'query'> & ({ variables: GetNationalStrategyByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetNationalStrategyByPkComponent = (props: GetNationalStrategyByPkComponentProps) => (
      <ApolloReactComponents.Query<GetNationalStrategyByPkQuery, GetNationalStrategyByPkQueryVariables> query={GetNationalStrategyByPkDocument} {...props} />
    );
    

/**
 * __useGetNationalStrategyByPkQuery__
 *
 * To run a query within a React component, call `useGetNationalStrategyByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNationalStrategyByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNationalStrategyByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNationalStrategyByPkQuery(baseOptions: Apollo.QueryHookOptions<GetNationalStrategyByPkQuery, GetNationalStrategyByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNationalStrategyByPkQuery, GetNationalStrategyByPkQueryVariables>(GetNationalStrategyByPkDocument, options);
      }
export function useGetNationalStrategyByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNationalStrategyByPkQuery, GetNationalStrategyByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNationalStrategyByPkQuery, GetNationalStrategyByPkQueryVariables>(GetNationalStrategyByPkDocument, options);
        }
export type GetNationalStrategyByPkQueryHookResult = ReturnType<typeof useGetNationalStrategyByPkQuery>;
export type GetNationalStrategyByPkLazyQueryHookResult = ReturnType<typeof useGetNationalStrategyByPkLazyQuery>;
export type GetNationalStrategyByPkQueryResult = Apollo.QueryResult<GetNationalStrategyByPkQuery, GetNationalStrategyByPkQueryVariables>;
export const InsertTargetGroupOneDocument = gql`
    mutation InsertTargetGroupOne($object: project_target_group_insert_input!) {
  insert_project_target_group_one(object: $object) {
    ...TargetGroup
  }
}
    ${TargetGroupFragmentDoc}`;
export type InsertTargetGroupOneMutationFn = Apollo.MutationFunction<InsertTargetGroupOneMutation, InsertTargetGroupOneMutationVariables>;
export type InsertTargetGroupOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertTargetGroupOneMutation, InsertTargetGroupOneMutationVariables>, 'mutation'>;

    export const InsertTargetGroupOneComponent = (props: InsertTargetGroupOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertTargetGroupOneMutation, InsertTargetGroupOneMutationVariables> mutation={InsertTargetGroupOneDocument} {...props} />
    );
    

/**
 * __useInsertTargetGroupOneMutation__
 *
 * To run a mutation, you first call `useInsertTargetGroupOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTargetGroupOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTargetGroupOneMutation, { data, loading, error }] = useInsertTargetGroupOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertTargetGroupOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertTargetGroupOneMutation, InsertTargetGroupOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertTargetGroupOneMutation, InsertTargetGroupOneMutationVariables>(InsertTargetGroupOneDocument, options);
      }
export type InsertTargetGroupOneMutationHookResult = ReturnType<typeof useInsertTargetGroupOneMutation>;
export type InsertTargetGroupOneMutationResult = Apollo.MutationResult<InsertTargetGroupOneMutation>;
export type InsertTargetGroupOneMutationOptions = Apollo.BaseMutationOptions<InsertTargetGroupOneMutation, InsertTargetGroupOneMutationVariables>;
export const UpdateTargetGroupByPkDocument = gql`
    mutation UpdateTargetGroupByPk($_set: project_target_group_set_input, $id: String!) {
  update_project_target_group_by_pk(pk_columns: {id: $id}, _set: $_set) {
    ...TargetGroup
  }
}
    ${TargetGroupFragmentDoc}`;
export type UpdateTargetGroupByPkMutationFn = Apollo.MutationFunction<UpdateTargetGroupByPkMutation, UpdateTargetGroupByPkMutationVariables>;
export type UpdateTargetGroupByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateTargetGroupByPkMutation, UpdateTargetGroupByPkMutationVariables>, 'mutation'>;

    export const UpdateTargetGroupByPkComponent = (props: UpdateTargetGroupByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateTargetGroupByPkMutation, UpdateTargetGroupByPkMutationVariables> mutation={UpdateTargetGroupByPkDocument} {...props} />
    );
    

/**
 * __useUpdateTargetGroupByPkMutation__
 *
 * To run a mutation, you first call `useUpdateTargetGroupByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTargetGroupByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTargetGroupByPkMutation, { data, loading, error }] = useUpdateTargetGroupByPkMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateTargetGroupByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTargetGroupByPkMutation, UpdateTargetGroupByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTargetGroupByPkMutation, UpdateTargetGroupByPkMutationVariables>(UpdateTargetGroupByPkDocument, options);
      }
export type UpdateTargetGroupByPkMutationHookResult = ReturnType<typeof useUpdateTargetGroupByPkMutation>;
export type UpdateTargetGroupByPkMutationResult = Apollo.MutationResult<UpdateTargetGroupByPkMutation>;
export type UpdateTargetGroupByPkMutationOptions = Apollo.BaseMutationOptions<UpdateTargetGroupByPkMutation, UpdateTargetGroupByPkMutationVariables>;
export const GetTargetGroupsDocument = gql`
    query GetTargetGroups($distinctOn: [project_target_group_select_column!], $limit: Int, $offset: Int, $orderBy: [project_target_group_order_by!], $where: project_target_group_bool_exp) {
  rows: project_target_group(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...TargetGroup
  }
}
    ${TargetGroupFragmentDoc}`;
export type GetTargetGroupsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTargetGroupsQuery, GetTargetGroupsQueryVariables>, 'query'>;

    export const GetTargetGroupsComponent = (props: GetTargetGroupsComponentProps) => (
      <ApolloReactComponents.Query<GetTargetGroupsQuery, GetTargetGroupsQueryVariables> query={GetTargetGroupsDocument} {...props} />
    );
    

/**
 * __useGetTargetGroupsQuery__
 *
 * To run a query within a React component, call `useGetTargetGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTargetGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTargetGroupsQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTargetGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetTargetGroupsQuery, GetTargetGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTargetGroupsQuery, GetTargetGroupsQueryVariables>(GetTargetGroupsDocument, options);
      }
export function useGetTargetGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTargetGroupsQuery, GetTargetGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTargetGroupsQuery, GetTargetGroupsQueryVariables>(GetTargetGroupsDocument, options);
        }
export type GetTargetGroupsQueryHookResult = ReturnType<typeof useGetTargetGroupsQuery>;
export type GetTargetGroupsLazyQueryHookResult = ReturnType<typeof useGetTargetGroupsLazyQuery>;
export type GetTargetGroupsQueryResult = Apollo.QueryResult<GetTargetGroupsQuery, GetTargetGroupsQueryVariables>;
export const GetTargetGroupByPkDocument = gql`
    query GetTargetGroupByPk($id: String!) {
  row: project_target_group_by_pk(id: $id) {
    ...TargetGroup
  }
}
    ${TargetGroupFragmentDoc}`;
export type GetTargetGroupByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTargetGroupByPkQuery, GetTargetGroupByPkQueryVariables>, 'query'> & ({ variables: GetTargetGroupByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTargetGroupByPkComponent = (props: GetTargetGroupByPkComponentProps) => (
      <ApolloReactComponents.Query<GetTargetGroupByPkQuery, GetTargetGroupByPkQueryVariables> query={GetTargetGroupByPkDocument} {...props} />
    );
    

/**
 * __useGetTargetGroupByPkQuery__
 *
 * To run a query within a React component, call `useGetTargetGroupByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTargetGroupByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTargetGroupByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTargetGroupByPkQuery(baseOptions: Apollo.QueryHookOptions<GetTargetGroupByPkQuery, GetTargetGroupByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTargetGroupByPkQuery, GetTargetGroupByPkQueryVariables>(GetTargetGroupByPkDocument, options);
      }
export function useGetTargetGroupByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTargetGroupByPkQuery, GetTargetGroupByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTargetGroupByPkQuery, GetTargetGroupByPkQueryVariables>(GetTargetGroupByPkDocument, options);
        }
export type GetTargetGroupByPkQueryHookResult = ReturnType<typeof useGetTargetGroupByPkQuery>;
export type GetTargetGroupByPkLazyQueryHookResult = ReturnType<typeof useGetTargetGroupByPkLazyQuery>;
export type GetTargetGroupByPkQueryResult = Apollo.QueryResult<GetTargetGroupByPkQuery, GetTargetGroupByPkQueryVariables>;
export const InsertUnitOneDocument = gql`
    mutation InsertUnitOne($object: project_unit_insert_input!) {
  unit: insert_project_unit_one(object: $object) {
    ...Unit
  }
}
    ${UnitFragmentDoc}`;
export type InsertUnitOneMutationFn = Apollo.MutationFunction<InsertUnitOneMutation, InsertUnitOneMutationVariables>;
export type InsertUnitOneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertUnitOneMutation, InsertUnitOneMutationVariables>, 'mutation'>;

    export const InsertUnitOneComponent = (props: InsertUnitOneComponentProps) => (
      <ApolloReactComponents.Mutation<InsertUnitOneMutation, InsertUnitOneMutationVariables> mutation={InsertUnitOneDocument} {...props} />
    );
    

/**
 * __useInsertUnitOneMutation__
 *
 * To run a mutation, you first call `useInsertUnitOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUnitOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUnitOneMutation, { data, loading, error }] = useInsertUnitOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertUnitOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertUnitOneMutation, InsertUnitOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertUnitOneMutation, InsertUnitOneMutationVariables>(InsertUnitOneDocument, options);
      }
export type InsertUnitOneMutationHookResult = ReturnType<typeof useInsertUnitOneMutation>;
export type InsertUnitOneMutationResult = Apollo.MutationResult<InsertUnitOneMutation>;
export type InsertUnitOneMutationOptions = Apollo.BaseMutationOptions<InsertUnitOneMutation, InsertUnitOneMutationVariables>;
export const UpdateUnitByPkDocument = gql`
    mutation UpdateUnitByPk($_set: project_unit_set_input, $id: String!) {
  unit: update_project_unit_by_pk(pk_columns: {id: $id}, _set: $_set) {
    ...Unit
  }
}
    ${UnitFragmentDoc}`;
export type UpdateUnitByPkMutationFn = Apollo.MutationFunction<UpdateUnitByPkMutation, UpdateUnitByPkMutationVariables>;
export type UpdateUnitByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUnitByPkMutation, UpdateUnitByPkMutationVariables>, 'mutation'>;

    export const UpdateUnitByPkComponent = (props: UpdateUnitByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUnitByPkMutation, UpdateUnitByPkMutationVariables> mutation={UpdateUnitByPkDocument} {...props} />
    );
    

/**
 * __useUpdateUnitByPkMutation__
 *
 * To run a mutation, you first call `useUpdateUnitByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnitByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnitByPkMutation, { data, loading, error }] = useUpdateUnitByPkMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUnitByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUnitByPkMutation, UpdateUnitByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUnitByPkMutation, UpdateUnitByPkMutationVariables>(UpdateUnitByPkDocument, options);
      }
export type UpdateUnitByPkMutationHookResult = ReturnType<typeof useUpdateUnitByPkMutation>;
export type UpdateUnitByPkMutationResult = Apollo.MutationResult<UpdateUnitByPkMutation>;
export type UpdateUnitByPkMutationOptions = Apollo.BaseMutationOptions<UpdateUnitByPkMutation, UpdateUnitByPkMutationVariables>;
export const DeleteUnitByPkDocument = gql`
    mutation DeleteUnitByPk($id: String!) {
  unit: delete_project_unit_by_pk(id: $id) {
    ...Unit
  }
}
    ${UnitFragmentDoc}`;
export type DeleteUnitByPkMutationFn = Apollo.MutationFunction<DeleteUnitByPkMutation, DeleteUnitByPkMutationVariables>;
export type DeleteUnitByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteUnitByPkMutation, DeleteUnitByPkMutationVariables>, 'mutation'>;

    export const DeleteUnitByPkComponent = (props: DeleteUnitByPkComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteUnitByPkMutation, DeleteUnitByPkMutationVariables> mutation={DeleteUnitByPkDocument} {...props} />
    );
    

/**
 * __useDeleteUnitByPkMutation__
 *
 * To run a mutation, you first call `useDeleteUnitByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUnitByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUnitByPkMutation, { data, loading, error }] = useDeleteUnitByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUnitByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUnitByPkMutation, DeleteUnitByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUnitByPkMutation, DeleteUnitByPkMutationVariables>(DeleteUnitByPkDocument, options);
      }
export type DeleteUnitByPkMutationHookResult = ReturnType<typeof useDeleteUnitByPkMutation>;
export type DeleteUnitByPkMutationResult = Apollo.MutationResult<DeleteUnitByPkMutation>;
export type DeleteUnitByPkMutationOptions = Apollo.BaseMutationOptions<DeleteUnitByPkMutation, DeleteUnitByPkMutationVariables>;
export const GetUnitsDocument = gql`
    query GetUnits($distinctOn: [project_unit_select_column!], $limit: Int, $offset: Int, $orderBy: [project_unit_order_by!], $where: project_unit_bool_exp) {
  rows: project_unit(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...Unit
  }
}
    ${UnitFragmentDoc}`;
export type GetUnitsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUnitsQuery, GetUnitsQueryVariables>, 'query'>;

    export const GetUnitsComponent = (props: GetUnitsComponentProps) => (
      <ApolloReactComponents.Query<GetUnitsQuery, GetUnitsQueryVariables> query={GetUnitsDocument} {...props} />
    );
    

/**
 * __useGetUnitsQuery__
 *
 * To run a query within a React component, call `useGetUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitsQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUnitsQuery(baseOptions?: Apollo.QueryHookOptions<GetUnitsQuery, GetUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitsQuery, GetUnitsQueryVariables>(GetUnitsDocument, options);
      }
export function useGetUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitsQuery, GetUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitsQuery, GetUnitsQueryVariables>(GetUnitsDocument, options);
        }
export type GetUnitsQueryHookResult = ReturnType<typeof useGetUnitsQuery>;
export type GetUnitsLazyQueryHookResult = ReturnType<typeof useGetUnitsLazyQuery>;
export type GetUnitsQueryResult = Apollo.QueryResult<GetUnitsQuery, GetUnitsQueryVariables>;
export const GetUnitByPkDocument = gql`
    query GetUnitByPk($id: String!) {
  row: project_unit_by_pk(id: $id) {
    ...Unit
  }
}
    ${UnitFragmentDoc}`;
export type GetUnitByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUnitByPkQuery, GetUnitByPkQueryVariables>, 'query'> & ({ variables: GetUnitByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUnitByPkComponent = (props: GetUnitByPkComponentProps) => (
      <ApolloReactComponents.Query<GetUnitByPkQuery, GetUnitByPkQueryVariables> query={GetUnitByPkDocument} {...props} />
    );
    

/**
 * __useGetUnitByPkQuery__
 *
 * To run a query within a React component, call `useGetUnitByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUnitByPkQuery(baseOptions: Apollo.QueryHookOptions<GetUnitByPkQuery, GetUnitByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitByPkQuery, GetUnitByPkQueryVariables>(GetUnitByPkDocument, options);
      }
export function useGetUnitByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitByPkQuery, GetUnitByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitByPkQuery, GetUnitByPkQueryVariables>(GetUnitByPkDocument, options);
        }
export type GetUnitByPkQueryHookResult = ReturnType<typeof useGetUnitByPkQuery>;
export type GetUnitByPkLazyQueryHookResult = ReturnType<typeof useGetUnitByPkLazyQuery>;
export type GetUnitByPkQueryResult = Apollo.QueryResult<GetUnitByPkQuery, GetUnitByPkQueryVariables>;
export const GetUnauthorizedUserAccountByIdDocument = gql`
    query GetUnauthorizedUserAccountById($userId: uuid!) {
  user: users_by_pk(id: $userId) {
    id
    display_name
    account {
      id
      email
    }
  }
}
    `;
export type GetUnauthorizedUserAccountByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUnauthorizedUserAccountByIdQuery, GetUnauthorizedUserAccountByIdQueryVariables>, 'query'> & ({ variables: GetUnauthorizedUserAccountByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUnauthorizedUserAccountByIdComponent = (props: GetUnauthorizedUserAccountByIdComponentProps) => (
      <ApolloReactComponents.Query<GetUnauthorizedUserAccountByIdQuery, GetUnauthorizedUserAccountByIdQueryVariables> query={GetUnauthorizedUserAccountByIdDocument} {...props} />
    );
    

/**
 * __useGetUnauthorizedUserAccountByIdQuery__
 *
 * To run a query within a React component, call `useGetUnauthorizedUserAccountByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnauthorizedUserAccountByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnauthorizedUserAccountByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUnauthorizedUserAccountByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUnauthorizedUserAccountByIdQuery, GetUnauthorizedUserAccountByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnauthorizedUserAccountByIdQuery, GetUnauthorizedUserAccountByIdQueryVariables>(GetUnauthorizedUserAccountByIdDocument, options);
      }
export function useGetUnauthorizedUserAccountByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnauthorizedUserAccountByIdQuery, GetUnauthorizedUserAccountByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnauthorizedUserAccountByIdQuery, GetUnauthorizedUserAccountByIdQueryVariables>(GetUnauthorizedUserAccountByIdDocument, options);
        }
export type GetUnauthorizedUserAccountByIdQueryHookResult = ReturnType<typeof useGetUnauthorizedUserAccountByIdQuery>;
export type GetUnauthorizedUserAccountByIdLazyQueryHookResult = ReturnType<typeof useGetUnauthorizedUserAccountByIdLazyQuery>;
export type GetUnauthorizedUserAccountByIdQueryResult = Apollo.QueryResult<GetUnauthorizedUserAccountByIdQuery, GetUnauthorizedUserAccountByIdQueryVariables>;
export const UpdateUserByPkDocument = gql`
    mutation UpdateUserByPk($user_id: uuid!, $_set: users_set_input) {
  row: update_users_by_pk(pk_columns: {id: $user_id}, _set: $_set) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type UpdateUserByPkMutationFn = Apollo.MutationFunction<UpdateUserByPkMutation, UpdateUserByPkMutationVariables>;
export type UpdateUserByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserByPkMutation, UpdateUserByPkMutationVariables>, 'mutation'>;

    export const UpdateUserByPkComponent = (props: UpdateUserByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserByPkMutation, UpdateUserByPkMutationVariables> mutation={UpdateUserByPkDocument} {...props} />
    );
    

/**
 * __useUpdateUserByPkMutation__
 *
 * To run a mutation, you first call `useUpdateUserByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserByPkMutation, { data, loading, error }] = useUpdateUserByPkMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateUserByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserByPkMutation, UpdateUserByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserByPkMutation, UpdateUserByPkMutationVariables>(UpdateUserByPkDocument, options);
      }
export type UpdateUserByPkMutationHookResult = ReturnType<typeof useUpdateUserByPkMutation>;
export type UpdateUserByPkMutationResult = Apollo.MutationResult<UpdateUserByPkMutation>;
export type UpdateUserByPkMutationOptions = Apollo.BaseMutationOptions<UpdateUserByPkMutation, UpdateUserByPkMutationVariables>;
export const UpdateAuthAccountByPkDocument = gql`
    mutation UpdateAuthAccountByPk($account_id: uuid!, $_set: auth_accounts_set_input) {
  row: update_auth_accounts_by_pk(pk_columns: {id: $account_id}, _set: $_set) {
    id
  }
}
    `;
export type UpdateAuthAccountByPkMutationFn = Apollo.MutationFunction<UpdateAuthAccountByPkMutation, UpdateAuthAccountByPkMutationVariables>;
export type UpdateAuthAccountByPkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateAuthAccountByPkMutation, UpdateAuthAccountByPkMutationVariables>, 'mutation'>;

    export const UpdateAuthAccountByPkComponent = (props: UpdateAuthAccountByPkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateAuthAccountByPkMutation, UpdateAuthAccountByPkMutationVariables> mutation={UpdateAuthAccountByPkDocument} {...props} />
    );
    

/**
 * __useUpdateAuthAccountByPkMutation__
 *
 * To run a mutation, you first call `useUpdateAuthAccountByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAuthAccountByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAuthAccountByPkMutation, { data, loading, error }] = useUpdateAuthAccountByPkMutation({
 *   variables: {
 *      account_id: // value for 'account_id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateAuthAccountByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAuthAccountByPkMutation, UpdateAuthAccountByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAuthAccountByPkMutation, UpdateAuthAccountByPkMutationVariables>(UpdateAuthAccountByPkDocument, options);
      }
export type UpdateAuthAccountByPkMutationHookResult = ReturnType<typeof useUpdateAuthAccountByPkMutation>;
export type UpdateAuthAccountByPkMutationResult = Apollo.MutationResult<UpdateAuthAccountByPkMutation>;
export type UpdateAuthAccountByPkMutationOptions = Apollo.BaseMutationOptions<UpdateAuthAccountByPkMutation, UpdateAuthAccountByPkMutationVariables>;
export const ReplaceAccountRolesDocument = gql`
    mutation ReplaceAccountRoles($account_id: uuid!, $selected_roles: [String!]!, $insert_roles: [auth_account_roles_insert_input!]!, $default_role: String!) {
  delete_rows: delete_auth_account_roles(
    where: {account_id: {_eq: $account_id}, role: {_nin: $selected_roles}}
  ) {
    affected_rows
  }
  insert_rows: insert_auth_account_roles(
    objects: $insert_roles
    on_conflict: {constraint: user_roles_account_id_role_key, update_columns: []}
  ) {
    affected_rows
    returning {
      id
      role
    }
  }
  row: update_auth_accounts_by_pk(
    pk_columns: {id: $account_id}
    _set: {default_role: $default_role}
  ) {
    id
    default_role
  }
}
    `;
export type ReplaceAccountRolesMutationFn = Apollo.MutationFunction<ReplaceAccountRolesMutation, ReplaceAccountRolesMutationVariables>;
export type ReplaceAccountRolesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReplaceAccountRolesMutation, ReplaceAccountRolesMutationVariables>, 'mutation'>;

    export const ReplaceAccountRolesComponent = (props: ReplaceAccountRolesComponentProps) => (
      <ApolloReactComponents.Mutation<ReplaceAccountRolesMutation, ReplaceAccountRolesMutationVariables> mutation={ReplaceAccountRolesDocument} {...props} />
    );
    

/**
 * __useReplaceAccountRolesMutation__
 *
 * To run a mutation, you first call `useReplaceAccountRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceAccountRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceAccountRolesMutation, { data, loading, error }] = useReplaceAccountRolesMutation({
 *   variables: {
 *      account_id: // value for 'account_id'
 *      selected_roles: // value for 'selected_roles'
 *      insert_roles: // value for 'insert_roles'
 *      default_role: // value for 'default_role'
 *   },
 * });
 */
export function useReplaceAccountRolesMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceAccountRolesMutation, ReplaceAccountRolesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplaceAccountRolesMutation, ReplaceAccountRolesMutationVariables>(ReplaceAccountRolesDocument, options);
      }
export type ReplaceAccountRolesMutationHookResult = ReturnType<typeof useReplaceAccountRolesMutation>;
export type ReplaceAccountRolesMutationResult = Apollo.MutationResult<ReplaceAccountRolesMutation>;
export type ReplaceAccountRolesMutationOptions = Apollo.BaseMutationOptions<ReplaceAccountRolesMutation, ReplaceAccountRolesMutationVariables>;
export const ReplaceUserPermissionsDocument = gql`
    mutation ReplaceUserPermissions($user_id: uuid!, $selected_permissions: [String!]!, $insert_permissions: [users_permission_insert_input!]!) {
  delete_rows: delete_users_permission(
    where: {user_id: {_eq: $user_id}, permission_id: {_nin: $selected_permissions}}
  ) {
    affected_rows
  }
  insert_rows: insert_users_permission(
    objects: $insert_permissions
    on_conflict: {constraint: users_permission_user_id_permission_id_key, update_columns: []}
  ) {
    affected_rows
    returning {
      ...UserPermission
    }
  }
}
    ${UserPermissionFragmentDoc}`;
export type ReplaceUserPermissionsMutationFn = Apollo.MutationFunction<ReplaceUserPermissionsMutation, ReplaceUserPermissionsMutationVariables>;
export type ReplaceUserPermissionsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReplaceUserPermissionsMutation, ReplaceUserPermissionsMutationVariables>, 'mutation'>;

    export const ReplaceUserPermissionsComponent = (props: ReplaceUserPermissionsComponentProps) => (
      <ApolloReactComponents.Mutation<ReplaceUserPermissionsMutation, ReplaceUserPermissionsMutationVariables> mutation={ReplaceUserPermissionsDocument} {...props} />
    );
    

/**
 * __useReplaceUserPermissionsMutation__
 *
 * To run a mutation, you first call `useReplaceUserPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceUserPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceUserPermissionsMutation, { data, loading, error }] = useReplaceUserPermissionsMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      selected_permissions: // value for 'selected_permissions'
 *      insert_permissions: // value for 'insert_permissions'
 *   },
 * });
 */
export function useReplaceUserPermissionsMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceUserPermissionsMutation, ReplaceUserPermissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplaceUserPermissionsMutation, ReplaceUserPermissionsMutationVariables>(ReplaceUserPermissionsDocument, options);
      }
export type ReplaceUserPermissionsMutationHookResult = ReturnType<typeof useReplaceUserPermissionsMutation>;
export type ReplaceUserPermissionsMutationResult = Apollo.MutationResult<ReplaceUserPermissionsMutation>;
export type ReplaceUserPermissionsMutationOptions = Apollo.BaseMutationOptions<ReplaceUserPermissionsMutation, ReplaceUserPermissionsMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($data: ChangePasswordInput!) {
  account: auth_account_change_password(data: $data) {
    accountId
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;
export type ChangePasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangePasswordMutation, ChangePasswordMutationVariables>, 'mutation'>;

    export const ChangePasswordComponent = (props: ChangePasswordComponentProps) => (
      <ApolloReactComponents.Mutation<ChangePasswordMutation, ChangePasswordMutationVariables> mutation={ChangePasswordDocument} {...props} />
    );
    

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const GetUsersDocument = gql`
    query GetUsers($distinctOn: [users_select_column!], $limit: Int, $offset: Int, $orderBy: [users_order_by!], $where: users_bool_exp) {
  rows: users(
    distinct_on: $distinctOn
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    ...User
    ...UserAccountRoles
    user_permissions: users_permissions {
      ...UserPermission
      permission {
        ...Permission
      }
    }
  }
}
    ${UserFragmentDoc}
${UserAccountRolesFragmentDoc}
${UserPermissionFragmentDoc}
${PermissionFragmentDoc}`;
export type GetUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUsersQuery, GetUsersQueryVariables>, 'query'>;

    export const GetUsersComponent = (props: GetUsersComponentProps) => (
      <ApolloReactComponents.Query<GetUsersQuery, GetUsersQueryVariables> query={GetUsersDocument} {...props} />
    );
    

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      distinctOn: // value for 'distinctOn'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetAccountRolesSummaryDocument = gql`
    query GetAccountRolesSummary {
  total: auth_account_roles_aggregate(distinct_on: account_id) {
    aggregate {
      count
    }
  }
  user: auth_account_roles_aggregate(where: {role: {_eq: "user"}}) {
    aggregate {
      count
    }
  }
  employee: auth_account_roles_aggregate(where: {role: {_eq: "employee"}}) {
    aggregate {
      count
    }
  }
  bank: auth_account_roles_aggregate(where: {role: {_eq: "bank"}}) {
    aggregate {
      count
    }
  }
  sysadmin: auth_account_roles_aggregate(where: {role: {_eq: "sysadmin"}}) {
    aggregate {
      count
    }
  }
}
    `;
export type GetAccountRolesSummaryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountRolesSummaryQuery, GetAccountRolesSummaryQueryVariables>, 'query'>;

    export const GetAccountRolesSummaryComponent = (props: GetAccountRolesSummaryComponentProps) => (
      <ApolloReactComponents.Query<GetAccountRolesSummaryQuery, GetAccountRolesSummaryQueryVariables> query={GetAccountRolesSummaryDocument} {...props} />
    );
    

/**
 * __useGetAccountRolesSummaryQuery__
 *
 * To run a query within a React component, call `useGetAccountRolesSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountRolesSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountRolesSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountRolesSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountRolesSummaryQuery, GetAccountRolesSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountRolesSummaryQuery, GetAccountRolesSummaryQueryVariables>(GetAccountRolesSummaryDocument, options);
      }
export function useGetAccountRolesSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountRolesSummaryQuery, GetAccountRolesSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountRolesSummaryQuery, GetAccountRolesSummaryQueryVariables>(GetAccountRolesSummaryDocument, options);
        }
export type GetAccountRolesSummaryQueryHookResult = ReturnType<typeof useGetAccountRolesSummaryQuery>;
export type GetAccountRolesSummaryLazyQueryHookResult = ReturnType<typeof useGetAccountRolesSummaryLazyQuery>;
export type GetAccountRolesSummaryQueryResult = Apollo.QueryResult<GetAccountRolesSummaryQuery, GetAccountRolesSummaryQueryVariables>;
export const GetAccountRolesByAccountIdDocument = gql`
    query GetAccountRolesByAccountId($account_id: uuid!) {
  account: auth_accounts_by_pk(id: $account_id) {
    id
    default_role
  }
  rows: auth_account_roles(where: {account_id: {_eq: $account_id}}) {
    role
  }
}
    `;
export type GetAccountRolesByAccountIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountRolesByAccountIdQuery, GetAccountRolesByAccountIdQueryVariables>, 'query'> & ({ variables: GetAccountRolesByAccountIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAccountRolesByAccountIdComponent = (props: GetAccountRolesByAccountIdComponentProps) => (
      <ApolloReactComponents.Query<GetAccountRolesByAccountIdQuery, GetAccountRolesByAccountIdQueryVariables> query={GetAccountRolesByAccountIdDocument} {...props} />
    );
    

/**
 * __useGetAccountRolesByAccountIdQuery__
 *
 * To run a query within a React component, call `useGetAccountRolesByAccountIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountRolesByAccountIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountRolesByAccountIdQuery({
 *   variables: {
 *      account_id: // value for 'account_id'
 *   },
 * });
 */
export function useGetAccountRolesByAccountIdQuery(baseOptions: Apollo.QueryHookOptions<GetAccountRolesByAccountIdQuery, GetAccountRolesByAccountIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountRolesByAccountIdQuery, GetAccountRolesByAccountIdQueryVariables>(GetAccountRolesByAccountIdDocument, options);
      }
export function useGetAccountRolesByAccountIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountRolesByAccountIdQuery, GetAccountRolesByAccountIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountRolesByAccountIdQuery, GetAccountRolesByAccountIdQueryVariables>(GetAccountRolesByAccountIdDocument, options);
        }
export type GetAccountRolesByAccountIdQueryHookResult = ReturnType<typeof useGetAccountRolesByAccountIdQuery>;
export type GetAccountRolesByAccountIdLazyQueryHookResult = ReturnType<typeof useGetAccountRolesByAccountIdLazyQuery>;
export type GetAccountRolesByAccountIdQueryResult = Apollo.QueryResult<GetAccountRolesByAccountIdQuery, GetAccountRolesByAccountIdQueryVariables>;
export const GetUserByPkDocument = gql`
    query GetUserByPk($id: uuid!) {
  entity: users_by_pk(id: $id) {
    ...User
    ...UserAccountRoles
  }
  returning: users_by_pk(id: $id) {
    ...User
    ...UserAccountRoles
    permissions: users_permissions {
      ...UserPermission
      permission {
        ...Permission
      }
    }
  }
}
    ${UserFragmentDoc}
${UserAccountRolesFragmentDoc}
${UserPermissionFragmentDoc}
${PermissionFragmentDoc}`;
export type GetUserByPkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserByPkQuery, GetUserByPkQueryVariables>, 'query'> & ({ variables: GetUserByPkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUserByPkComponent = (props: GetUserByPkComponentProps) => (
      <ApolloReactComponents.Query<GetUserByPkQuery, GetUserByPkQueryVariables> query={GetUserByPkDocument} {...props} />
    );
    

/**
 * __useGetUserByPkQuery__
 *
 * To run a query within a React component, call `useGetUserByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByPkQuery(baseOptions: Apollo.QueryHookOptions<GetUserByPkQuery, GetUserByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByPkQuery, GetUserByPkQueryVariables>(GetUserByPkDocument, options);
      }
export function useGetUserByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByPkQuery, GetUserByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByPkQuery, GetUserByPkQueryVariables>(GetUserByPkDocument, options);
        }
export type GetUserByPkQueryHookResult = ReturnType<typeof useGetUserByPkQuery>;
export type GetUserByPkLazyQueryHookResult = ReturnType<typeof useGetUserByPkLazyQuery>;
export type GetUserByPkQueryResult = Apollo.QueryResult<GetUserByPkQuery, GetUserByPkQueryVariables>;
export const GetPermissionsDocument = gql`
    query GetPermissions {
  rows: permission {
    id
    description
    active
    sequence
  }
}
    `;
export type GetPermissionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPermissionsQuery, GetPermissionsQueryVariables>, 'query'>;

    export const GetPermissionsComponent = (props: GetPermissionsComponentProps) => (
      <ApolloReactComponents.Query<GetPermissionsQuery, GetPermissionsQueryVariables> query={GetPermissionsDocument} {...props} />
    );
    

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, options);
      }
export function useGetPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, options);
        }
export type GetPermissionsQueryHookResult = ReturnType<typeof useGetPermissionsQuery>;
export type GetPermissionsLazyQueryHookResult = ReturnType<typeof useGetPermissionsLazyQuery>;
export type GetPermissionsQueryResult = Apollo.QueryResult<GetPermissionsQuery, GetPermissionsQueryVariables>;
export const GetUserPermissionsByUserIdDocument = gql`
    query GetUserPermissionsByUserId($userId: uuid!) {
  permissions: permission(order_by: {sequence: asc}) {
    ...Permission
  }
  user_permissions: users_permission(where: {user_id: {_eq: $userId}}) {
    ...UserPermission
  }
}
    ${PermissionFragmentDoc}
${UserPermissionFragmentDoc}`;
export type GetUserPermissionsByUserIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserPermissionsByUserIdQuery, GetUserPermissionsByUserIdQueryVariables>, 'query'> & ({ variables: GetUserPermissionsByUserIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUserPermissionsByUserIdComponent = (props: GetUserPermissionsByUserIdComponentProps) => (
      <ApolloReactComponents.Query<GetUserPermissionsByUserIdQuery, GetUserPermissionsByUserIdQueryVariables> query={GetUserPermissionsByUserIdDocument} {...props} />
    );
    

/**
 * __useGetUserPermissionsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetUserPermissionsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPermissionsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPermissionsByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserPermissionsByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserPermissionsByUserIdQuery, GetUserPermissionsByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPermissionsByUserIdQuery, GetUserPermissionsByUserIdQueryVariables>(GetUserPermissionsByUserIdDocument, options);
      }
export function useGetUserPermissionsByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPermissionsByUserIdQuery, GetUserPermissionsByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPermissionsByUserIdQuery, GetUserPermissionsByUserIdQueryVariables>(GetUserPermissionsByUserIdDocument, options);
        }
export type GetUserPermissionsByUserIdQueryHookResult = ReturnType<typeof useGetUserPermissionsByUserIdQuery>;
export type GetUserPermissionsByUserIdLazyQueryHookResult = ReturnType<typeof useGetUserPermissionsByUserIdLazyQuery>;
export type GetUserPermissionsByUserIdQueryResult = Apollo.QueryResult<GetUserPermissionsByUserIdQuery, GetUserPermissionsByUserIdQueryVariables>;
export const GetFiscalYearsDocument = gql`
    query GetFiscalYears {
  fiscal_years: fiscal_year(order_by: {year: desc}) {
    ...FiscalYear
  }
}
    ${FiscalYearFragmentDoc}`;
export type GetFiscalYearsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetFiscalYearsQuery, GetFiscalYearsQueryVariables>, 'query'>;

    export const GetFiscalYearsComponent = (props: GetFiscalYearsComponentProps) => (
      <ApolloReactComponents.Query<GetFiscalYearsQuery, GetFiscalYearsQueryVariables> query={GetFiscalYearsDocument} {...props} />
    );
    

/**
 * __useGetFiscalYearsQuery__
 *
 * To run a query within a React component, call `useGetFiscalYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFiscalYearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFiscalYearsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFiscalYearsQuery(baseOptions?: Apollo.QueryHookOptions<GetFiscalYearsQuery, GetFiscalYearsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFiscalYearsQuery, GetFiscalYearsQueryVariables>(GetFiscalYearsDocument, options);
      }
export function useGetFiscalYearsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFiscalYearsQuery, GetFiscalYearsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFiscalYearsQuery, GetFiscalYearsQueryVariables>(GetFiscalYearsDocument, options);
        }
export type GetFiscalYearsQueryHookResult = ReturnType<typeof useGetFiscalYearsQuery>;
export type GetFiscalYearsLazyQueryHookResult = ReturnType<typeof useGetFiscalYearsLazyQuery>;
export type GetFiscalYearsQueryResult = Apollo.QueryResult<GetFiscalYearsQuery, GetFiscalYearsQueryVariables>;