import React from 'react';
import { Chip } from '@material-ui/core';
import { ChipProps as MuiChipProps } from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { LedgerFragment, LedgerLedgerStatusEnum } from '@app/generated/graphql';
import { useColors } from '@app/shared/styles';
import ApproveIcon from '@material-ui/icons/Check';
import LockedIcon from '@material-ui/icons/Lock';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 0,
  },
}));

export const LedgerStatus = ({ status, ChipProps }: { status: LedgerFragment['status']; ChipProps?: MuiChipProps }) => {
  const colors = useColors();
  const classes = useStyles();
  const classNameMapping = {
    [LedgerLedgerStatusEnum.Approved]: colors.approved,
    [LedgerLedgerStatusEnum.Locked]: colors.cancelled,
    [LedgerLedgerStatusEnum.ChangeRequest]: colors.changeRequest,
    [LedgerLedgerStatusEnum.Draft]: colors.new,
    [LedgerLedgerStatusEnum.Rejected]: colors.rejected,
    [LedgerLedgerStatusEnum.Submitted]: colors.submitted,
  };

  const icon = {
    [LedgerLedgerStatusEnum.Approved]: <ApproveIcon />,
    [LedgerLedgerStatusEnum.Locked]: <LockedIcon />,
    [LedgerLedgerStatusEnum.ChangeRequest]: null,
    [LedgerLedgerStatusEnum.Draft]: null,
    [LedgerLedgerStatusEnum.Rejected]: null,
    [LedgerLedgerStatusEnum.Submitted]: null,
  };

  return (
    <Chip
      label={status.description}
      variant="default"
      color="primary"
      icon={icon[status.id as LedgerLedgerStatusEnum] as any}
      classes={{
        root: classes.root,
        colorPrimary: classNameMapping[status.id as LedgerLedgerStatusEnum] || colors.new,
      }}
      {...ChipProps}
    />
  );
};
