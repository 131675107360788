import { Checkbox } from '@aginix/mui-react-hook-form-input';
import {
  useGetUserPermissionsByUserIdQuery,
  useReplaceUserPermissionsMutation,
  UserPermissionFragment,
  PermissionFragment,
} from '@app/generated/graphql';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { FormControl, FormControlLabel, FormGroup, Button, Typography, Grid } from '@material-ui/core';
import { InfoCard } from '@mystiny/ui';
import React, { Fragment } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { bannerCheckboxStylesHook } from '@mui-treasury/styles/checkbox/banner';

const UserPermissionsForm = ({
  userId,
  permissions,
  userPermissions,
}: {
  userId: string;
  permissions: PermissionFragment[];
  userPermissions: UserPermissionFragment[];
}) => {
  const checkboxStyles = bannerCheckboxStylesHook.useCheckbox();
  const formControlLabelStyles = bannerCheckboxStylesHook.useFormControlLabel();
  const { enqueueFailure, enqueueSuccess } = useResponseBar();
  const form = useForm<any>({
    defaultValues: {
      ...userPermissions,
    },
  });

  const [mutateFn] = useReplaceUserPermissionsMutation({
    onCompleted: () => {
      enqueueSuccess('บันทึกข้อมูลสิทธิ์ผู้ใช้สำเร็จ');
    },
    onError: () => {
      enqueueFailure('เกิดข้อผิดพลาดไม่สามารถบันทึกข้อมูลได้');
    },
    context: {
      headers: {
        'x-hasura-default-role': 'sysadmin',
      },
    },
  });

  const handleSubmit = form.handleSubmit((data) => {
    const selectPermissions = Object.keys(data)
      .filter((permission_id) => data[permission_id] === true)
      .map((permission_id) => permission_id);
    mutateFn({
      variables: {
        user_id: userId,
        selected_permissions: selectPermissions,
        insert_permissions: selectPermissions.map((permission_id) => ({
          user_id: userId,
          permission_id: permission_id,
        })),
      },
    });
  });

  return (
    <FormProvider {...form}>
      <form id="user-permissions-form" onSubmit={handleSubmit}>
        {/* <FormLayout>
          <FormLayoutGroup> */}

        <FormControl component="fieldset" required>
          <FormGroup>
            <Grid container>
              {permissions.map((permission) => (
                <Grid item xs={12} md={4} key={permission.id}>
                  <FormControlLabel
                    classes={formControlLabelStyles}
                    control={
                      <Checkbox
                        name={permission.id}
                        defaultChecked={userPermissions.some((up) => up.permission_id === permission.id)}
                        classes={checkboxStyles}
                      />
                    }
                    label={
                      <Fragment>
                        {permission.description}
                        <Typography color="textSecondary">{permission.id}</Typography>
                      </Fragment>
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </FormControl>
      </form>
    </FormProvider>
  );
};

const UserPermissionsSection = ({ userId }: { userId: string }) => {
  const { data, loading } = useGetUserPermissionsByUserIdQuery({
    fetchPolicy: 'no-cache',
    variables: {
      userId: userId,
    },
    context: {
      headers: {
        'x-hasura-default-role': 'sysadmin',
      },
    },
  });

  if (!data?.user_permissions && loading) {
    return null;
  }

  return (
    <InfoCard
      title="สิทธิ์การเข้าถึงส่วนงานภายในระบบ"
      actionTopRight={
        <Button variant="contained" color="primary" size="small" type="submit" form="user-permissions-form">
          บันทึก
        </Button>
      }
    >
      {data?.user_permissions ? (
        <UserPermissionsForm
          userId={userId}
          permissions={data.permissions || []}
          userPermissions={data.user_permissions || []}
        />
      ) : null}
    </InfoCard>
  );
};

export default UserPermissionsSection;
