import { Suspense, useEffect } from 'react';
import BackOfficeLayout from '@app/layout/BackOfficeLayout';
import ErrorFallback from '@app/views/ErrorFallback';
import RouteWithLayout from '@app/components/common/RouteWithLayout';
import { errorHandler } from '@app/shared/libs/error-handler';
import { ErrorBoundary } from 'react-error-boundary';
import { Redirect, Route, Switch } from 'react-router-dom';
import NotFound from '@app/views/NotFound';
import SuspenseLoading from './components/SuspenseLoading';
import FirebaseSignInWithCustomToken from '@app/firebase/firebase_sign_in_with_custom_token';
import ErrorPage from '@app/error_page';

import Login from '@app/pages/login';
import Unauthorized from '@app/pages/unauthorized';

import UserList from '@app/pages/users/list';
import UserDetail from '@app/pages/users/detail';
import UserCreate from '@app/pages/users/create';

import LoanApplicationDashboard from '@app/pages/loan-applications/dashboard';

import OrganizationList from '@app/pages/organizations/list';
import OrganizationDetail from '@app/pages/organizations/detail';

import StrategyList from '@app/pages/project-strategies/list';
import StrategyCreate from '@app/pages/project-strategies/create';
import StrategyDetail from '@app/pages/project-strategies/detail';

import BudgetTypeCreate from '@app/pages/budget-type/create';
import BudgetTypeList from '@app/pages/budget-type/list';
import BudgetTypeDetail from '@app/pages/budget-type/detail';

import LedgerBudgetAllocation from '@app/pages/ledgers/budgets';
import LedgerList from '@app/pages/ledgers';
import LedgerDetail from '@app/pages/ledgers/detail';
import LedgerCreate from '@app/pages/ledgers/create';
import ReceivableList from '@app/pages/ledgers/receivable-list';
import ReceivableDetail from '@app/pages/ledgers/receivable-detail';
import ReceivableCreate from '@app/pages/ledgers/receivable-create';
import LedgerMatrixReport from '@app/pages/ledgers/matrix-report';
import LedgerMatrixReport2 from '@app/pages/ledgers/matrix-report-2';
import LedgerMonthlyReport from '@app/pages/ledgers/monthly-report';
import LedgerTotalReport from '@app/pages/ledgers/total-report';

import AccountCreate from '@app/pages/account/create';
import AccountList from '@app/pages/account/list';
import AccountDetail from '@app/pages/account/detail';

import LoanProgramList from '@app/pages/loan-program/list';
import LoanProgramDetail from '@app/pages/loan-program/detail';

import AdminGoogleAnalytics from '@app/pages/admin/google-analytics';

import ProjectList from '@app/pages/projects/list';
import ProjectDetail from '@app/pages/projects/detail';
import ProjectCreate from '@app/pages/projects/create';
import ProjectDashboard from '@app/pages/projects/dashboard';
import ProjectTodo from '@app/pages/projects/todo';
import ProjectPerformanceReport from '@app/pages/projects/performance-report';
import ProjectProposalComparison from '@app/pages/projects/proposal-comparison';
import ProjectBudgetComparison from '@app/pages/projects/budget-comparison';
import ProjectBudgetGroupList from '@app/pages/project-budget-groups/list';
import ProjectBudgetGroupDetail from '@app/pages/project-budget-groups/detail';

import ProjectBudgetList from '@app/pages/project-budgets/list';
import ProjectBudgetCreate from '@app/pages/project-budgets/create';
import ProjectBudgetDetail from '@app/pages/project-budgets/detail';

import UnitList from '@app/pages/project-units/list';
import UnitCreate from '@app/pages/project-units/create';
import UnitDetil from '@app/pages/project-units/detail';

import TargetGroupList from '@app/pages/project-target-groups/list';
import TargetGroupCreate from '@app/pages/project-target-groups/create';
import TargetGroupDetail from '@app/pages/project-target-groups/detail';

import StrategyTreeView from '@app/pages/project-strategy-treview/detail';
import AttachmentsView from '@app/views/Admin/Attachment';
import LogoutPage from '@app/logout_page';

const V2Redirect = () => {
  useEffect(() => {
    window.location.href = process.env.REACT_APP_PORTAL_V2_ENDPOINT as string;
  }, []);

  return <div />;
};

const Routes = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
      <Suspense fallback={<SuspenseLoading />}>
        <Switch>
          <RouteWithLayout layout={BackOfficeLayout} path="/v2" component={V2Redirect} />

          <RouteWithLayout exact path="/bof/attachments" component={AttachmentsView} layout={BackOfficeLayout} />

          <RouteWithLayout
            exact
            path="/bof/projects/master-data/strategy/treeview"
            component={StrategyTreeView}
            layout={BackOfficeLayout}
          />

          <RouteWithLayout
            exact
            path="/bof/dashboard/loan-applications"
            component={LoanApplicationDashboard}
            layout={BackOfficeLayout}
          />

          <RouteWithLayout exact path="/bof/users/create" component={UserCreate} layout={BackOfficeLayout} />
          <RouteWithLayout exact path="/bof/users" component={UserList} layout={BackOfficeLayout} />
          <RouteWithLayout exact path="/bof/users/:id" component={UserDetail} layout={BackOfficeLayout} />

          <RouteWithLayout
            exact
            path="/bof/projects/master-data/strategy-subject"
            component={StrategyList}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout
            exact
            path="/bof/projects/master-data/strategy-subject/create"
            component={StrategyCreate}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout
            exact
            path="/bof/projects/master-data/strategy-subject/:id"
            component={StrategyDetail}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout exact path="/bof/organizations" component={OrganizationList} layout={BackOfficeLayout} />
          <RouteWithLayout
            exact
            path="/bof/organizations/:id"
            component={OrganizationDetail}
            layout={BackOfficeLayout}
          />

          <RouteWithLayout
            exact
            path="/bof/accounting/budgets"
            component={LedgerBudgetAllocation}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout exact path="/bof/accounting/ledgers" component={LedgerList} layout={BackOfficeLayout} />
          <RouteWithLayout
            exact
            path="/bof/accounting/reports/total-report"
            component={LedgerTotalReport}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout
            exact
            path="/bof/accounting/reports/matrix-report"
            component={LedgerMatrixReport}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout
            exact
            path="/bof/accounting/reports/matrix-report-2"
            component={LedgerMatrixReport2}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout
            exact
            path="/bof/accounting/reports/monthly-report"
            component={LedgerMonthlyReport}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout
            exact
            path="/bof/accounting/ledgers/create"
            component={LedgerCreate}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout
            exact
            path="/bof/accounting/ledgers/:id"
            component={LedgerDetail}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout
            exact
            path="/bof/accounting/receivables"
            component={ReceivableList}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout
            exact
            path="/bof/accounting/receivables/create"
            component={ReceivableCreate}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout
            exact
            path="/bof/accounting/receivables/:id"
            component={ReceivableDetail}
            layout={BackOfficeLayout}
          />

          <RouteWithLayout
            path="/bof/accounting/accounts/create"
            exact
            component={AccountCreate}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout exact path="/bof/accounting/accounts" component={AccountList} layout={BackOfficeLayout} />
          <RouteWithLayout
            exact
            path="/bof/accounting/accounts/:id"
            component={AccountDetail}
            layout={BackOfficeLayout}
          />

          <RouteWithLayout
            path="/bof/accounting/budget-types/create"
            exact
            component={BudgetTypeCreate}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout
            exact
            path="/bof/accounting/budget-types"
            component={BudgetTypeList}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout
            exact
            path="/bof/accounting/budget-types/:id/:fiscalYear"
            component={BudgetTypeDetail}
            layout={BackOfficeLayout}
          />

          <RouteWithLayout
            exact
            path="/bof/admin/google-analytics"
            component={AdminGoogleAnalytics}
            layout={BackOfficeLayout}
          />

          {/* แผนงานและงบประมาณ */}

          <RouteWithLayout exact path="/bof/projects/todo" component={ProjectTodo} layout={BackOfficeLayout} />
          <RouteWithLayout
            exact
            path="/bof/projects/performance-reports/:id"
            component={ProjectPerformanceReport}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout
            exact
            path="/bof/projects/dashboard"
            component={ProjectDashboard}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout
            exact
            path="/bof/projects/proposals-comparison"
            component={ProjectProposalComparison}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout
            exact
            path="/bof/projects/budgets-comparison"
            component={ProjectBudgetComparison}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout exact path="/bof/projects/proposals" component={ProjectList} layout={BackOfficeLayout} />
          <RouteWithLayout
            exact
            path="/bof/projects/proposals/create"
            component={ProjectCreate}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout
            exact
            path="/bof/projects/proposals/:id"
            component={ProjectDetail}
            layout={BackOfficeLayout}
          />

          <RouteWithLayout
            exact
            path="/bof/projects/master-data/budget-groups"
            component={ProjectBudgetGroupList}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout
            exact
            path="/bof/projects/master-data/budget-groups/:id"
            component={ProjectBudgetGroupDetail}
            layout={BackOfficeLayout}
          />

          <RouteWithLayout
            exact
            path="/bof/projects/master-data/budgets"
            component={ProjectBudgetList}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout
            exact
            path="/bof/projects/master-data/budgets/create"
            component={ProjectBudgetCreate}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout
            exact
            path="/bof/projects/master-data/budgets/detail/:id"
            component={ProjectBudgetDetail}
            layout={BackOfficeLayout}
          />

          <RouteWithLayout
            exact
            path="/bof/projects/master-data/target-groups"
            component={TargetGroupList}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout
            exact
            path="/bof/projects/master-data/target-groups/create"
            component={TargetGroupCreate}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout
            exact
            path="/bof/projects/master-data/target-groups/:id"
            component={TargetGroupDetail}
            layout={BackOfficeLayout}
          />

          <RouteWithLayout
            exact
            path="/bof/projects/master-data/units"
            component={UnitList}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout
            exact
            path="/bof/projects/master-data/units/create"
            component={UnitCreate}
            layout={BackOfficeLayout}
          />
          <RouteWithLayout
            exact
            path="/bof/projects/master-data/units/:id"
            component={UnitDetil}
            layout={BackOfficeLayout}
          />

          <RouteWithLayout exact path="/bof/programs" component={LoanProgramList} layout={BackOfficeLayout} />
          <RouteWithLayout exact path="/bof/programs/:id" component={LoanProgramDetail} layout={BackOfficeLayout} />

          {/* Redirect from /bof to ____ */}
          <Redirect path="/bof" to="/bof/dashboard/loan-applications" exact />

          <Route path="/unauthorized" exact component={Unauthorized} />
          <Route path="/logout" exact component={LogoutPage} />
          <Route path="/login" exact component={Login} />
          <Route path="/sso" exact component={FirebaseSignInWithCustomToken} />
          <Route path="/error" exact component={ErrorPage} />
          <Redirect path="/" to="/bof" exact />

          <RouteWithLayout component={NotFound} layout={BackOfficeLayout} />
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Routes;
