import React, { useMemo, useState } from 'react';
import { Row } from 'react-data-grid';
import { GetTargetGroupsComponent } from '@app/generated/graphql';
import { targetGrouponColumns } from './columns';
import DataGridContainer from '@app/shared/components/DataGridContainer';
import LoadMoreRowsTag from '@app/shared/components/LoadMoreRowsTag';
import { listPageContent } from '@app/shared/hoc/list-page';
import DataGridFetchMore from '@app/shared/components/DataGridFetchMore';
import { useRdgSort } from '@app/shared/hooks/useRdgSort';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { groupBy as rowGrouper } from 'lodash';
import { Box, Typography } from '@material-ui/core';
import LinkButton from '@app/shared/components/buttons/LinkButton';
import AddIcon from '@material-ui/icons/Add';
import PageTitle from '@app/shared/components/PageTitle';

const TargetGroupList = () => {
  const history = useHistory();
  const [filters, setFilters] = useState<any>({
    description: '',
    id: '',
  });
  const [value] = useDebounce(filters, 200);
  const [offset, setOffset] = React.useState(0);
  const { handleSort, orderBy, sort } = useRdgSort();
  const targetgroupList = useMemo(
    () => (
      <GetTargetGroupsComponent
        variables={{
          where: {
            description: {
              _ilike: value.description ? `%${value.description}%` : undefined,
            },
            id: {
              _ilike: value.id ? `%${value.id}%` : undefined,
            },
          },
          orderBy: orderBy,
        }}
        fetchPolicy="cache-and-network"
      >
        {({ data, fetchMore, loading, variables }) => (
          <DataGridContainer height="80vh">
            <DataGridFetchMore
              fetchMore={fetchMore}
              variables={variables}
              offset={offset}
              setOffset={setOffset}
              sortColumn={sort?.column}
              sortDirection={sort?.direction}
              onSort={handleSort}
              rowRenderer={(props) => (
                <Row
                  {...props}
                  onDoubleClick={() => history.push(`/bof/projects/master-data/target-groups/${props.row.id}`)}
                />
              )}
              defaultColumnOptions={{ sortable: true }}
              rows={data?.rows || []}
              columns={targetGrouponColumns}
              rowGrouper={rowGrouper}
              filters={filters}
              onFiltersChange={setFilters}
              enableFilters
            />
            <LoadMoreRowsTag loading={loading} />
          </DataGridContainer>
        )}
      </GetTargetGroupsComponent>
    ),
    [filters, setFilters, value, offset, setOffset, handleSort, orderBy, sort, history]
  );

  return (
    <React.Fragment>
      <Box padding={1}>
        <PageTitle
          title={<Typography variant="h6">กลุ่มเป้าหมาย</Typography>}
          actions={
            <LinkButton startIcon={<AddIcon />} to="/bof/projects/master-data/target-groups/create">
              เพิ่มกลุ่มเป้าหมาย
            </LinkButton>
          }
        />
      </Box>
      {targetgroupList}
    </React.Fragment>
  );
};

export default listPageContent(TargetGroupList);
