import React, { Fragment } from 'react';
import TextFilter from '@app/components/data-grid/TextFilter';
import { ProjectFragment } from '@app/generated/graphql';
import { Column } from 'react-data-grid';
import { ProjectStatus } from '../components/ProjectStatus';
import { DateUtils } from '@app/shared/libs/date';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { Link as MuiLink } from '@material-ui/core';

export const projectonColumns: Column<ProjectFragment>[] = [
  {
    key: 'step_id',
    name: 'สถานะ',
    filterRenderer: (p) => <TextFilter {...p} />,
    frozen: true,
    width: 120,
    formatter: ({ row }) => (
      <Fragment>
        <ProjectStatus project={row} />
      </Fragment>
    ),
  },
  {
    key: 'title',
    name: 'ชื่อคำขอ',
    frozen: true,
    minWidth: 330,
    filterRenderer: (p) => <TextFilter {...p} />,
    formatter: ({ row }) => (
      <MuiLink component={Link} to={`/bof/projects/proposals/${row.id}`}>
        {row.title}
      </MuiLink>
    ),
  },
  {
    key: 'submitted_date',
    name: 'วันที่ยื่นคำขอ',
    filterRenderer: (p) => <TextFilter {...p} />,
    width: 125,
    formatter: ({ row }) => <Fragment>{row.submitted_date ? DateUtils.toThaiDate(row.submitted_date) : '-'}</Fragment>,
  },
  {
    key: 'national_strategy_lvl1.name',
    name: 'ด้านยุทธศาสตร์',
    minWidth: 190,
    filterRenderer: (p) => <TextFilter {...p} />,
    formatter: ({ row }) => <Fragment>{row.national_strategy_lvl1?.name || '-'}</Fragment>,
  },
  {
    key: 'industry',
    name: 'สาขาอุตสาหกรรม',
    minWidth: 160,
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'organization.short_name',
    name: 'หน่วยงานรับผิดชอบ',
    minWidth: 140,
    filterRenderer: (p) => <TextFilter {...p} />,
    formatter: ({ row }) => <Fragment>{row.organization?.short_name}</Fragment>,
  },
  {
    key: 'fiscal_year',
    name: 'ปีงบประมาณ',
    width: 130,
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'total_budget',
    name: 'งบประมาณ',
    minWidth: 140,
    formatter: ({ row }) => (
      <Fragment>
        {row.total_budget ? (
          <NumberFormat
            fixedDecimalScale
            decimalScale={2}
            displayType="text"
            value={row.total_budget || 0}
            thousandSeparator
          />
        ) : (
          '-'
        )}
      </Fragment>
    ),
  },
  {
    key: 'updated_at',
    name: 'วันที่แก้ไขล่าสุด',
    filterRenderer: (p) => <TextFilter {...p} />,
    minWidth: 165,
    formatter: ({ row }) => <Fragment>{DateUtils.toThaiDatetime(row.updated_at)}</Fragment>,
  },
  {
    key: 'created_at',
    name: 'วันที่สร้างคำขอ',
    filterRenderer: (p) => <TextFilter {...p} />,
    minWidth: 165,
    formatter: ({ row }) => <Fragment>{DateUtils.toThaiDatetime(row.created_at)}</Fragment>,
  },
];
