import { IconButton, InputAdornment, TextField, Tooltip } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';
import { FilterRendererProps } from 'react-data-grid';

const TextFilter = (props: FilterRendererProps<any>) => (
  <TextField
    style={{
      paddingTop: 4,
    }}
    InputProps={{
      startAdornment: (
        <Tooltip title="Filter list" aria-label="filter">
          <InputAdornment position="start">
            <FilterListIcon fontSize="small" />
          </InputAdornment>
        </Tooltip>
      ),
      endAdornment: (
        <React.Fragment>
          {props.value ? (
            <Tooltip title="Clear filter" aria-label="clear">
              <InputAdornment position="end">
                <IconButton aria-label="clear filter" onClick={() => props.onChange('')}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            </Tooltip>
          ) : null}
        </React.Fragment>
      ),
    }}
    value={props.value}
    fullWidth
    onChange={(e) => props.onChange(e.target.value)}
  />
);

export default TextFilter;
