import React, { useCallback } from 'react';
import { GetProjectByPkWithMasterDataQuery } from '@app/generated/graphql';
import { Box, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Paper, Typography } from '@material-ui/core';
import { Checkbox } from '@aginix/mui-react-hook-form-input';
import { useProjectEditable } from '@app/views/Project/contexts/ProjectContext';
import { makeStyles } from '@material-ui/core/styles';

type OperatingAreaProps = {
  regions: GetProjectByPkWithMasterDataQuery['regions'];
  project: GetProjectByPkWithMasterDataQuery['project'];
};

const useStyles = makeStyles((theme) => ({
  container: {
    overflowY: 'scroll',
    height: 340,
  },
  box: {
    minHeight: '101%',
  },
  '@global': {
    '::-webkit-scrollbar': {
      // -webkit-appearance: none;
      // width: 10px;

      WebkitAppearance: 'none',
      width: 6,
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: 5,
      backgroundColor: 'rgba(0,0,0,.5)',
      WebkitBoxShadow: '0 0 1px rgba(255,255,255,.5)',
      // border-radius: 5px;
      // background-color: rgba(0,0,0,.5);
      // -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    },
  },
}));

export const ProjectOperatingAreas = ({ project, regions }: OperatingAreaProps) => {
  const classes = useStyles();
  const disabled = !useProjectEditable();

  const isChecked = useCallback(
    (provinceId: string) => project?.project_operating_areas.some((area) => area.province_id === provinceId) || false,
    [project]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">พื้นที่ดำเนินการ</Typography>
      </Grid>
      {regions.map((region) => (
        <Grid item xs={12} md={4} key={region.id}>
          <Paper variant="outlined" className={classes.container}>
            <Box p={1} className={classes.box}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  {region.name_th} ({region.provinces.length} จังหวัด)
                </FormLabel>
                <FormGroup>
                  {region.provinces.map((province) => (
                    <FormControlLabel
                      key={province.id}
                      control={
                        <Checkbox
                          disabled={disabled}
                          defaultChecked={isChecked(province.id)}
                          name={`operating_areas[${province.id}]`}
                        />
                      }
                      label={province.name_th}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};
