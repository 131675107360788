import React, { Fragment, useCallback, useState } from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
} from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { ErrorMessage, TextField } from '@aginix/mui-react-hook-form-input';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { createNumberFormatInput } from '@app/components/NumberFormatInput';
import { GetBudgetTypesDocument, useDuplicateAccountBudgetByFiscalYearMutation } from '@app/generated/graphql';
import ButtonCircularProgress from '@app/shared/components/ButtonCircularProgress';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';

const NumberFormatInput = createNumberFormatInput({
  allowNegative: true,
  thousandSeparator: false,
});

type FiscalForm = {
  from_fiscal_year: string;
  to_fiscal_year: string;
};

const CopyDialog = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const form = useForm({
    mode: 'onBlur',
  });
  const [duplicateFn, { loading }] = useDuplicateAccountBudgetByFiscalYearMutation({
    onCompleted: () => {
      enqueueSuccess('คัดลอกงบประมาณเสร็จสิ้น');
      onClose();
    },
    onError: () => {
      enqueueFailure('เกิดข้อผิดพลาด ไม่สามารถคัดลอกงบประมาณได้');
    },
    refetchQueries: [
      {
        query: GetBudgetTypesDocument,
      },
    ],
  });

  const fromFiscalYear = form.watch('from_fiscal_year');

  const handleSubmit = form.handleSubmit((formData: FiscalForm) => {
    duplicateFn({
      variables: {
        data: {
          fromFiscalYear: Number(formData.from_fiscal_year),
          toFiscalYear: Number(formData.to_fiscal_year),
        },
      },
    });
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="copy-budget-dialog-title"
      aria-describedby="copy-budget-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="copy-budget-dialog-title">คัดลอกงบประมาณ</DialogTitle>
      <DialogContent>
        <DialogContentText>
          คัดลอกข้อมูลงบประมาณ โดยจะคัดลอกชื่อ และการตั้งค่าทั้งหมด <br />
          <span style={{ color: 'red' }}>ยกเว้นการจัดสรรงบประมาณ</span>
        </DialogContentText>
        <FormProvider {...form}>
          <FormControl fullWidth>
            <TextField
              label="จากปีงบประมาณ (พ.ศ.)"
              name="from_fiscal_year"
              variant="outlined"
              size="small"
              required
              fullWidth
              rules={{
                required: 'กรุณากรอกปีงบประมาณ (พ.ศ.)',
              }}
              InputProps={{
                inputComponent: NumberFormatInput as any,
              }}
            />
            <ErrorMessage name="from_fiscal_year" />
          </FormControl>
          <div>
            <ArrowDownwardIcon fontSize="large" />
          </div>
          <FormControl fullWidth>
            <TextField
              label="ปีงบประมาณ (พ.ศ.)"
              name="to_fiscal_year"
              variant="outlined"
              size="small"
              required
              fullWidth
              rules={{
                required: 'กรุณากรอกปีงบประมาณ (พ.ศ.)',
                validate: (data: string) => (fromFiscalYear === data ? 'ปีงบประมาณห้ามเหมือนกัน' : undefined),
              }}
              InputProps={{
                inputComponent: NumberFormatInput as any,
              }}
            />
            <ErrorMessage name="to_fiscal_year" />
          </FormControl>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button color="primary" disabled={loading} onClick={onClose}>
          ยกเลิก {loading && <ButtonCircularProgress />}
        </Button>
        <Button color="primary" disabled={loading} onClick={handleSubmit}>
          ตกลง {loading && <ButtonCircularProgress />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const CopyButton = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Fragment>
      <Button startIcon={<FileCopyIcon />} color="primary" variant="outlined" onClick={handleOpen}>
        คัดลอกข้อมูล
      </Button>

      {open && <CopyDialog open={open} onClose={handleClose} />}
    </Fragment>
  );
};
