import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Toolbar, { ToolbarProps } from '@material-ui/core/Toolbar';
import Logo from '@app/static/moi-sme-logo.png';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 72,
    height: 'auto',
    margin: theme.spacing(2, 0, 0, 0),
  },
}));

const AnonymousTopBar = (props: ToolbarProps) => {
  const classes = useStyles();
  return (
    <Toolbar variant="regular" {...props}>
      <Link to="/" component={RouterLink}>
        <img
          src={Logo}
          alt="กองทุนพัฒนาเอสเอ็มอี ตามแนวประชารัฐ"
          title="กองทุนพัฒนาเอสเอ็มอี ตามแนวประชารัฐ"
          className={classes.logo}
        />
      </Link>
    </Toolbar>
  );
};

export default AnonymousTopBar;
