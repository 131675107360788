import { ErrorMessage, TextField } from '@aginix/mui-react-hook-form-input';
import { GetUnitIdComponent } from '@app/generated/graphql';
import { FormProps } from '@app/global-props';
import AutocompleteInput from '@app/shared/components/AutocompleteInput';
import { FormControl } from '@material-ui/core';
import { FormLayout, FormLayoutGroup } from '@mystiny/ui';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { createNumberFormatInput } from '@app/components/NumberFormatInput';
import { sortBy } from 'lodash';

const NumberFormatInput = createNumberFormatInput({
  allowNegative: false,
  thousandSeparator: true,
});

const FiscalFormatInput = createNumberFormatInput({
  allowNegative: false,
  thousandSeparator: false,
});

export const Form = (_: FormProps) => {
  const form = useFormContext();
  return (
    <FormLayout>
      <FormLayoutGroup>
        <FormControl fullWidth error={!!form.errors['name']}>
          <TextField label="ชื่อ" name="name" variant="outlined" size="small" rules={{ required: 'กรุณาระบุข้อมูล' }} fullWidth />
          <ErrorMessage name="name" />
        </FormControl>
      </FormLayoutGroup>
      <FormLayoutGroup>
        <FormControl fullWidth error={!!form.errors['fiscal_year']}>
          <TextField
            label="ปีงบประมาณ (พ.ศ.)"
            name="fiscal_year"
            variant="outlined"
            size="small"
            fullWidth
            rules={{ required: 'กรุณาระบุข้อมูล' }} 
            InputProps={{
              inputComponent: FiscalFormatInput as any,
            }}
          />
          <ErrorMessage name="fiscal_year" />
        </FormControl>
      </FormLayoutGroup>
      <FormLayoutGroup>
        <FormControl fullWidth error={!!form.errors['price_per_unit']}>
          <TextField
            label="ราคาต่อหน่วย"
            name="price_per_unit"
            variant="outlined"
            size="small"
            rules={{ required: 'กรุณาระบุข้อมูล' }} 
            fullWidth
            InputProps={{
              inputComponent: NumberFormatInput as any,
            }}
          />
          <ErrorMessage name="price_per_unit" />
        </FormControl>
      </FormLayoutGroup>
      <FormLayoutGroup>
        <GetUnitIdComponent fetchPolicy="cache-first">
          {({ data, loading }) => (
            <FormControl fullWidth>
              <AutocompleteInput
                label="หน่วยนับ"
                name="unit_id"
                options={data?.rows ? sortBy(data?.rows, ['description', 'id']) : []}
                loading={loading}
                disableClearable
                rules={{ required: 'กรุณาระบุข้อมูล' }} 
                size="small"
                primaryKey="id"
                getOptionLabel={(option: any) => `${option.description}`}
              />
              <ErrorMessage name="unit_id" />
            </FormControl>
          )}
        </GetUnitIdComponent>
      </FormLayoutGroup>
      <FormLayoutGroup>
        <GetUnitIdComponent fetchPolicy="cache-first">
          {({ data, loading }) => (
            <FormControl fullWidth>
              <AutocompleteInput
                label="หน่วยนับตัวคูณ"
                name="unit_of_multiplier_id"
                options={data?.rows ? sortBy(data?.rows, ['description', 'id']) : []}
                loading={loading}
                disableClearable
                rules={{ required: 'กรุณาระบุข้อมูล' }} 
                size="small"
                primaryKey="id"
                getOptionLabel={(option: any) => `${option.description}`}
              />
              <ErrorMessage name="unit_of_multiplier_id" />
            </FormControl>
          )}
        </GetUnitIdComponent>
      </FormLayoutGroup>
    </FormLayout>
  );
};
