import Page from '@app/components/Page';
import { listPageContent } from '@app/shared/hoc/list-page';
import { useTypography } from '@app/shared/styles/typography';
import { Typography } from '@material-ui/core';
import { InfoCard } from '@mystiny/ui';

const ProjectProposalComparison = () => {
  const typographyClasses = useTypography();
  return (
    <Page
      title={
        <Typography variant="h5" color="primary" className={typographyClasses.pageTitle}>
          รายงานสรุปการดำเนินงาน
        </Typography>
      }
    >
      <InfoCard>
        <iframe
          style={{ width: '100%', height: '90vh', border: 0 }}
          frameBorder="0"
          title="รายงานแผนงานและงบประมาณ"
          width="100%"
          height="100%"
          allowFullScreen
          src="https://datastudio.google.com/embed/reporting/de93867f-461c-4846-92fa-c9ff9e917726/page/edx9B"
        />
      </InfoCard>
    </Page>
  );
};

export default listPageContent(ProjectProposalComparison);
