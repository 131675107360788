import React, { useCallback, Fragment } from 'react';
import { listPageContent } from '@app/shared/hoc/list-page';
import { useInsertLedgerBudgetAllocationOneMutation, useGetLedgerBudgetAllocationsQuery } from '@app/generated/graphql';
import { FormProvider, useForm } from 'react-hook-form';
import { Select } from '@aginix/mui-react-hook-form-input';
import { InfoCard } from '@mystiny/ui';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  FormControl,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import Spacing from '@app/components/common/Spacing';
import PageTitle from '@app/shared/components/PageTitle';
import EdiText from 'react-editext';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { DateUtils } from '@app/shared/libs/date';

const getList = (startYear: number, endYear: number): number[] => {
  let list = [];
  for (let i = startYear; i <= endYear; i++) {
    list.push(i);
  }
  return list;
};

const DisplayNumber = ({ value, ...props }: NumberFormatProps) => (
  <NumberFormat value={value} displayType="text" thousandSeparator {...props} />
);

type HandleSaveProps = {
  organizationId: string;
  budgetId: string;
};

const useStyles = makeStyles(() => ({
  budgetInputColumn: {
    minWidth: 200,
  },
}));

const BudgetAllocation = () => {
  const classes = useStyles();
  const form = useForm({
    defaultValues: {
      year: DateUtils.getThaiFiscalYear(new Date()),
    },
  });

  const year = form.watch('year', DateUtils.getThaiFiscalYear(new Date())) as number;

  const { data, loading } = useGetLedgerBudgetAllocationsQuery({
    variables: {
      fiscalYear: year,
    },
  });

  const [updateFn] = useInsertLedgerBudgetAllocationOneMutation();

  const handleSave = useCallback(
    ({ organizationId, budgetId }: HandleSaveProps) =>
      (amount: number) => {
        updateFn({
          variables: {
            object: {
              fiscal_year: year,
              organization_id: organizationId,
              budget_id: budgetId,
              amount,
            },
          },
        });
      },
    [updateFn, year]
  );

  return (
    <Fragment>
      <PageTitle
        title={
          <Typography variant="h5" color="primary">
            จัดสรรงบประมาณ
          </Typography>
        }
      />
      <Spacing />
      <InfoCard>
        <FormProvider {...form}>
          <form noValidate>
            <FormControl margin="dense" variant="outlined">
              <InputLabel htmlFor="year">ปีงบประมาณ</InputLabel>
              <Select name="year" variant="outlined" label="ปีงบประมาณ" fullWidth>
                {getList(2564, new Date().getFullYear() + 543 + 1).map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        </FormProvider>
        <Spacing />
        <Typography variant="h6" gutterBottom>
          ปีงบประมาณ พ.ศ. {year}
        </Typography>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>หน่วยงาน</TableCell>
                {data?.budgets.map((budget) => (
                  <TableCell className={classes.budgetInputColumn} key={`${budget.id}-${budget.fiscal_year}-header`}>
                    {budget.description}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TableRow>
                  <TableCell>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
              {data?.organizations.map((organization, index) => (
                <TableRow key={organization.id}>
                  <TableCell>{organization.short_name}</TableCell>
                  {data?.budgets.map((budget, budgetIdx) => (
                    <TableCell className={classes.budgetInputColumn} key={`${budget.id}-${budget.fiscal_year}-input`}>
                      <EdiText
                        tabIndex={(index + 1) * data?.budgets.length + budgetIdx}
                        type="text"
                        submitOnEnter
                        validationMessage="ต้องเป็นตัวเลขเท่านั้น"
                        validation={(val) => !Number.isNaN(Number(val))}
                        cancelOnEscape
                        cancelOnUnfocus
                        value={
                          budget.allocations.find((l) => l.organization_id === organization.id)?.amount?.toString() ||
                          '0'
                        }
                        onSave={handleSave({ organizationId: organization.id, budgetId: budget.id })}
                        startEditingOnFocus
                        renderValue={(value) => <DisplayNumber value={value} />}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </InfoCard>
    </Fragment>
  );
};

export default listPageContent(BudgetAllocation);
