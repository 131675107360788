import {
  useUpdateProjectByPkMutation,
  ProjectFragment,
  GetProjectByPkWithMasterDataQuery,
  ProjectProject,
  ProjectProjectOperatingArea,
} from '@app/generated/graphql';
import React from 'react';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from './Form';
import { DateUtils } from '@app/shared/libs/date';
import { setYear } from 'date-fns';

export interface ProjectDetailFormProps {
  data: GetProjectByPkWithMasterDataQuery;
  render?: (props: { onSubmit: () => any; loading: boolean }) => React.ReactNode;
}

const ProjectDetailForm = ({ data, render }: ProjectDetailFormProps) => {
  const form = useForm<ProjectFragment>({
    defaultValues: data.project as any,
    shouldFocusError: true,
  });
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [updateFn, { loading }] = useUpdateProjectByPkMutation({
    onCompleted: () => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
    },
    onError: (err: any) => {
      console.error(err);
      enqueueFailure('เกิดข้อผิดพลาด');
    },
  });

  const handleSubmit = form.handleSubmit((formData: ProjectProject) => {
    const projectId = data!.project!.id;
    const areas = (formData as any)['operating_areas'];
    formData['project_operating_areas'] = Object.keys(areas)
      .filter((provinceId) => areas[provinceId])
      .map((provinceId, index) => ({
        project_id: projectId,
        province_id: provinceId,
        sequence: index,
      })) as ProjectProjectOperatingArea[];

    const startAt = DateUtils.getStartThatFiscalYearDate(setYear(new Date(), Number(formData.fiscal_year) - 543));
    const endAt = DateUtils.getEndThatFiscalYearDate(setYear(new Date(), Number(formData.fiscal_year) - 543));

    updateFn({
      variables: {
        id: projectId,
        project: {
          introduction: formData.introduction,
          title: formData.title,
          effect: formData.effect,
          start_at: startAt,
          end_at: endAt,
          last_overall_operation: formData.last_overall_operation,
          industry: formData.industry,
          fiscal_year: formData.fiscal_year,
          organization_id: formData.organization_id,
        },
        insertedProvinceIds: formData.project_operating_areas?.map((obj) => obj.province_id) || [],
        insertedTargetGroupIds: formData.project_target_groups?.map((obj) => obj.target_group_id) || [],
        operatingAreas: (formData.project_operating_areas || []) as any,
        operatingTargetGroups: (formData.project_target_groups?.map((obj) => ({
          ...obj,
          project_id: projectId,
        })) || []) as any,
      },
    });
  });

  return (
    <FormProvider {...form}>
      <Form data={data} mode="update" />
      {render &&
        render({
          onSubmit: handleSubmit,
          loading: loading,
        })}
    </FormProvider>
  );
};

export default ProjectDetailForm;
