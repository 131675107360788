import Spacing from '@app/components/common/Spacing';
import PageTitle from '@app/shared/components/PageTitle';
import {
  AppBar,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Divider,
  MenuItem,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Typography,
  Tabs,
  Tab,
} from '@material-ui/core';
import { head } from 'lodash';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
  useGetLedgerMatrixReportByOrganizationIdQuery,
  GetLedgerMatrixReportByOrganizationIdQuery,
  useGetOrganizationsQuery,
} from '@app/generated/graphql';
import { listPageContent } from '@app/shared/hoc/list-page';
import { FormLayout, FormLayoutGroup, InfoCard } from '@mystiny/ui';
import { FormProvider, useForm } from 'react-hook-form';
import { DateUtils } from '@app/shared/libs/date';
import { Select } from '@aginix/mui-react-hook-form-input';
import AutocompleteInput from '@app/shared/components/AutocompleteInput';
import { useIfUser } from '@app/components/common/Routes';
import { ExportButton } from './export/ExportButton';
import NumberFormat from 'react-number-format';

import { TabPanel } from '@app/components/TabPanel';
import { useUserHasAnyPermissions } from '@app/user-permission';
import { useCurrentUser } from '@app/hooks';

const MatrixReportDataTable = ({
  budget_rows,
  account_rows,
  account_budget_balance_rows,
}: {
  budget_rows: GetLedgerMatrixReportByOrganizationIdQuery['budget_rows'];
  account_rows: GetLedgerMatrixReportByOrganizationIdQuery['account_rows'];
  account_budget_balance_rows: GetLedgerMatrixReportByOrganizationIdQuery['expense_account_budget_balance_rows'];
}) => {
  const dataRows = account_rows.map((account) => {
    const rowTotal = account_budget_balance_rows
      .filter((row) => row.account_id === account.id)
      .reduce((prev, ab) => prev + (ab?.balance || 0), 0);

    const budgets: any = budget_rows.reduce((prev, budget) => {
      const accountBudgets = account_budget_balance_rows.filter(
        (row) => row.account_id === account.id && row.budget_id === budget.id && row.fiscal_year === budget.fiscal_year
      );

      const total = accountBudgets.reduce((prev, ab) => prev + (ab?.balance || 0), 0);

      return {
        ...prev,
        [budget.description!]: total,
      };
    }, {});

    return {
      name: account.code + ' ' + account.name,
      account,
      budgets,
      total: rowTotal,
    };
  });

  const summaryRow = useMemo(() => {
    const budgets: any = budget_rows.reduce((prev, budget) => {
      const accountBudgets = account_budget_balance_rows.filter(
        (row) => row.budget_id === budget.id && row.fiscal_year === budget.fiscal_year
      );

      const total = accountBudgets.reduce((prev, ab) => prev + (ab?.balance || 0), 0);

      return {
        ...prev,
        [budget.description!]: total,
      };
    }, {});

    return { name: 'รวมทั้งหมด', budgets, total: dataRows.reduce((prev, row) => prev + row.total, 0) };
  }, [dataRows, budget_rows, account_budget_balance_rows]);

  const outstandingRow = useMemo(() => {
    const budgets: any = budget_rows.reduce((prev, budget) => {
      const accountBudgets = account_budget_balance_rows.filter(
        (row) => row.budget_id === budget.id && row.fiscal_year === budget.fiscal_year
      );

      const total =
        (head(budget.allocations)?.amount || 0) -
        Math.abs(accountBudgets.reduce((prev, ab) => prev + (ab?.balance || 0), 0));

      return {
        ...prev,
        [budget.description!]: total,
      };
    }, {});

    return {
      name: 'คงเหลือ',
      budgets,
      total:
        budget_rows.reduce((prev, budget) => prev + (head(budget.allocations)?.amount || 0), 0) -
        Math.abs(dataRows.reduce((prev, row) => prev + row.total, 0)),
    };
  }, [dataRows, budget_rows, account_budget_balance_rows]);

  const budgetAllocationRow = useMemo(() => {
    const budgets: { [key: string]: number } = budget_rows.reduce((prev, budget) => {
      return {
        ...prev,
        [budget.description!]: head(budget.allocations)?.amount || 0,
      };
    }, {});
    return {
      name: 'วงเงินได้รับจัดสรร',
      budgets,
      total: Object.values(budgets).reduce((prev: number, total: number) => prev + total, 0),
    };
  }, [budget_rows]);

  const exportData = [budgetAllocationRow, ...dataRows, summaryRow, outstandingRow];

  return (
    <Fragment>
      <Grid container justify="flex-end" spacing={1}>
        <Grid item>
          <ExportButton data={exportData} name="รายงาน Matrix Report" />
        </Grid>
      </Grid>
      <Spacing />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 230 }} align="center">
                รหัสและชื่อบัญชี
              </TableCell>
              {budget_rows.map((budget) => (
                <TableCell key={budget.id} align="center" style={{ minWidth: 160 }}>
                  {budget.description}
                </TableCell>
              ))}
              <TableCell align="center" style={{ minWidth: 120, maxWidth: 160 }}>
                รวม
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" style={{ minWidth: 230 }}>
                วงเงินได้รับจัดสรร
              </TableCell>
              {budget_rows.map((budget) => (
                <TableCell key={budget.id} align="center" style={{ minWidth: 160 }}>
                  <NumberFormat
                    value={head(budget.allocations)?.amount || '0'}
                    displayType={'text'}
                    type="text"
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator
                  />
                </TableCell>
              ))}
              <TableCell align="center" style={{ minWidth: 120, maxWidth: 160 }}>
                <NumberFormat
                  value={budget_rows.reduce((prev, budget) => prev + (head(budget.allocations)?.amount || 0), 0)}
                  displayType={'text'}
                  type="text"
                  decimalScale={2}
                  fixedDecimalScale
                  thousandSeparator
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRows.map(({ account, budgets, total }) => (
              <TableRow key={account.id}>
                <TableCell style={{ minWidth: 230 }}>
                  {account.code} {account.name}
                </TableCell>
                {budget_rows.map((budget) => {
                  return (
                    <TableCell key={account.id + '-' + budget.id} align="right">
                      <NumberFormat
                        value={budgets[budget!.description!]}
                        displayType={'text'}
                        type="text"
                        decimalScale={2}
                        fixedDecimalScale
                        thousandSeparator
                      />
                    </TableCell>
                  );
                })}
                <TableCell align="right">
                  <NumberFormat
                    value={total}
                    displayType={'text'}
                    type="text"
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator
                  />
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell align="right">รวมทั้งหมด</TableCell>
              {budget_rows.map((budget) => {
                const accountBudgets = account_budget_balance_rows.filter(
                  (row) => row.budget_id === budget.id && row.fiscal_year === budget.fiscal_year
                );

                const total = accountBudgets.reduce((prev, ab) => prev + (ab?.balance || 0), 0);

                return (
                  <TableCell key={`total-${budget.id}`} align="right">
                    <NumberFormat
                      value={total}
                      displayType={'text'}
                      type="text"
                      decimalScale={2}
                      fixedDecimalScale
                      thousandSeparator
                    />
                  </TableCell>
                );
              })}
              <TableCell align="right" style={{ minWidth: 120, maxWidth: 160 }}>
                <NumberFormat
                  value={dataRows.reduce((prev, row) => prev + row.total, 0 as number)}
                  displayType={'text'}
                  type="text"
                  decimalScale={2}
                  fixedDecimalScale
                  thousandSeparator
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right">คงเหลือ</TableCell>
              {budget_rows.map((budget) => {
                const accountBudgets = account_budget_balance_rows.filter(
                  (row) => row.budget_id === budget.id && row.fiscal_year === budget.fiscal_year
                );

                const total = accountBudgets.reduce((prev, ab) => prev + (ab?.balance || 0), 0);
                const budgetAllocation = head(budget.allocations)?.amount || 0;
                const value = budgetAllocation - Math.abs(total);

                return (
                  <TableCell key={`outstanding-${budget.id}`} align="right">
                    <NumberFormat
                      value={value}
                      displayType={'text'}
                      allowNegative
                      type="text"
                      decimalScale={2}
                      fixedDecimalScale
                      thousandSeparator
                    />
                  </TableCell>
                );
              })}
              <TableCell align="right" style={{ minWidth: 120, maxWidth: 160 }}>
                <NumberFormat
                  value={
                    budget_rows.reduce((prev, budget) => prev + (head(budget.allocations)?.amount || 0), 0) -
                    Math.abs(dataRows.reduce((prev, row) => prev + row.total, 0))
                  }
                  displayType={'text'}
                  type="text"
                  decimalScale={2}
                  fixedDecimalScale
                  thousandSeparator
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

const MatrixReportView = () => {
  const user = useCurrentUser();
  const isSysadmin = useIfUser({ condition: ({ roles }) => roles.includes('sysadmin') });
  const isModuleAdmin = useUserHasAnyPermissions(['ACCOUNTING_ADMIN']);
  const form = useForm({
    defaultValues: {
      fiscal_year: DateUtils.getThaiFiscalYear(new Date()),
      organization_id: user?.organizationId,
    },
  });
  const [value, setValue] = useState(0);
  const handleChange = useCallback((_, value: number) => {
    setValue(value);
  }, []);

  useEffect(() => {
    form.setValue('organization_id', user?.organizationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const fiscalYear = form.watch('fiscal_year', DateUtils.getThaiFiscalYear(new Date()));
  const organizationId = form.watch('organization_id');

  const { data: organizationData, loading: organizationLoading } = useGetOrganizationsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const { data, loading } = useGetLedgerMatrixReportByOrganizationIdQuery({
    variables: {
      fiscalYear: Number(fiscalYear),
      organizationId: form.watch('organization_id'),
    },
  });

  const organization = useMemo(
    () => organizationData?.rows.find((org) => org.id === organizationId),
    [organizationData, organizationId]
  );

  const organizationOptions = useMemo(
    () => [{ id: undefined, name: 'ภาพรวม' }, ...(organizationData?.rows || [])],
    [organizationData]
  );

  return (
    <Fragment>
      <PageTitle
        title={
          <Typography variant="h5" color="primary">
            Matrix Report
          </Typography>
        }
        actions={<Fragment></Fragment>}
      />
      <Spacing />
      <InfoCard>
        <Grid container spacing={2} justify="space-between">
          <Grid item md={5}>
            <Typography variant="h6" color="primary">
              {loading ? '....' : organization?.name || 'ระดับภาพรวมทุกหน่วยงาน'}
            </Typography>
            <Typography variant="h6" color="textSecondary">
              ปีงบประมาณพ.ศ. {fiscalYear}
            </Typography>
          </Grid>
          <Grid item md={7}>
            <FormProvider {...form}>
              <form noValidate>
                <FormLayout>
                  <FormLayoutGroup>
                    <FormControl fullWidth margin="dense">
                      {isSysadmin || isModuleAdmin ? (
                        <AutocompleteInput
                          label="หน่วยงาน"
                          name="organization_id"
                          options={organizationOptions}
                          loading={organizationLoading}
                          disableClearable
                          primaryKey="id"
                          getOptionLabel={(option: any) => `${option.name}`}
                          size="small"
                        />
                      ) : (
                        <TextField
                          label="หน่วยงาน"
                          size="small"
                          name="organization_name"
                          id="organization_name"
                          variant="outlined"
                          value={user?.organization?.name}
                          disabled
                        />
                      )}
                    </FormControl>
                    <FormControl fullWidth margin="dense" variant="outlined">
                      <InputLabel htmlFor="fiscal_year">ปีงบประมาณ</InputLabel>
                      <Select name="fiscal_year" id="fiscal_year" label="ปีงบประมาณ">
                        <MenuItem value="2564">2564</MenuItem>
                        <MenuItem value="2563">2563</MenuItem>
                      </Select>
                    </FormControl>
                  </FormLayoutGroup>
                </FormLayout>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
        <Spacing />
        <Divider />
        <Spacing />
        {loading ? (
          <CircularProgress />
        ) : (
          <Fragment>
            {data ? (
              <Fragment>
                <AppBar position="static" variant="elevation" color="transparent">
                  <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="disabled tabs example"
                  >
                    <Tab label="EX1" />
                    <Tab label="EX2" />
                  </Tabs>
                </AppBar>
                <Spacing />
                <TabPanel value={value} index={0}>
                  <MatrixReportDataTable
                    budget_rows={data.budget_rows}
                    account_rows={data.account_rows}
                    account_budget_balance_rows={data.expense_account_budget_balance_rows}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <MatrixReportDataTable
                    budget_rows={data.budget_rows}
                    account_rows={data.account_rows}
                    account_budget_balance_rows={data.debt_account_budget_balance_rows as any}
                  />
                </TabPanel>
              </Fragment>
            ) : (
              <Box mt={4}>
                <Typography variant="h5" color="primary">
                  ไม่พบข้อมูล
                </Typography>
              </Box>
            )}
          </Fragment>
        )}
      </InfoCard>
    </Fragment>
  );
};

export default listPageContent(MatrixReportView);
