import { useGetProjectByPkWithMasterDataQuery } from '@app/generated/graphql';
import Page from '@app/components/Page';
import DescriptionIcon from '@material-ui/icons/Description';
import { ButtonProps } from '@material-ui/core/Button';
import { pageContent } from '@app/shared/hoc/page-content';
import { Button, CircularProgress, Divider, Grid, Typography } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import PageTitle from '@app/shared/components/PageTitle';
import BackButton from '@app/shared/components/buttons/BackButton';
import { InfoCard } from '@mystiny/ui';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Spacing from '@app/components/common/Spacing';
import SaveButton from '@app/shared/components/buttons/SaveButton';
import { ProjectContext } from '@app/views/Project/contexts/ProjectContext';
import {
  ProjectCompatabilityForm,
  ProjectBudgets,
  ProjectEventLog,
  ProjectOperatingActivities,
  ProjectObjectivesForm,
  ProjectSubmitButton,
  ProjectStatus,
  ProjectOrganizationAndMembers,
} from './components';
import { ProjectOperatingPlan } from '@app/views/Project/components/ProjectOperatingPlan';
import ProjectStepper from './components/ProjectStepper';
import ProjectDetailForm from './components/ProjectDetailForm';
import DeleteButton from '@app/shared/components/buttons/DeleteButton';
import { UserPermission } from '@app/user-permission';
import { ProjectVerification } from './components/ProjectWorkflow';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Link } from 'react-router-dom';

const NextButton = (props: ButtonProps) => (
  <Button variant="contained" color="primary" endIcon={<ChevronRightIcon />} {...props}>
    ถัดไป
  </Button>
);

const PrevButton = (props: ButtonProps) => (
  <Button variant="outlined" color="primary" startIcon={<ChevronLeftIcon />} {...props}>
    ย้อนกลับ
  </Button>
);

const ProjectDetailPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [step, setStep] = useState<number>(0);
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetProjectByPkWithMasterDataQuery({
    variables: {
      id: id,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    history.replace('#' + step);
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    const step = Number(location.hash.replace('#', ''));
    if (Number.isInteger(step)) {
      setStep(step);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading && !data?.project) {
    return <CircularProgress />;
  }

  if (!data?.project) {
    return null;
  }

  const editable = ['draft', 'change_request'].includes(data?.project?.step_id || 'draft');

  return (
    <Page
      title={
        <PageTitle
          title={
            <React.Fragment>
              <Typography variant="h6" component="div">
                <DescriptionIcon color="primary" fontSize="small" /> {data.project.title}
              </Typography>
              <ProjectStatus project={data.project} />
            </React.Fragment>
          }
          actions={
            <div>
              <BackButton to="/bof/projects/proposals" />
            </div>
          }
        />
      }
    >
      {data?.project?.step_id === 'approved' && (
        <Fragment>
          <Alert severity="info">
            <AlertTitle>ท่านสามารถบันทึกแผนการปฏิบัติงานได้</AlertTitle>
            <Button component={Link} to={`/bof/projects/performance-reports/${id}`} color="primary" variant="outlined">
              คลิกที่นี่ เพื่อไปหน้าบันทึกแผนการปฏิบัติงาน
            </Button>
          </Alert>
          <Spacing />
        </Fragment>
      )}
      <ProjectContext.Provider value={data as any}>
        <InfoCard>
          <ProjectStepper step={step} />
          <Divider />
          {step === 0 && (
            <Fragment>
              <ProjectDetailForm
                data={data}
                render={({ onSubmit }) => (
                  <Grid container justify="space-between" spacing={1}>
                    <Grid item>
                      {['draft', 'cancelled', 'submitted', 'change_request'].includes(data.project?.step_id || '') && (
                        <DeleteButton />
                      )}
                    </Grid>
                    {editable && (
                      <Grid item>
                        <SaveButton onClick={onSubmit}>บันทึกร่าง</SaveButton>
                      </Grid>
                    )}
                    <Grid item>
                      <NextButton onClick={() => setStep(1)} />
                    </Grid>
                  </Grid>
                )}
              />
            </Fragment>
          )}
          {step === 1 && (
            <Fragment>
              <Spacing />
              <ProjectObjectivesForm projectId={id} />
              <Grid container justify="space-between" spacing={1}>
                <Grid item>
                  <PrevButton onClick={() => setStep(0)} />
                </Grid>
                <Grid item>
                  <NextButton onClick={() => setStep(2)} />
                </Grid>
              </Grid>
            </Fragment>
          )}
          {step === 2 && (
            <Fragment>
              <Spacing />
              <ProjectOperatingActivities
                projectId={data?.project?.id}
                render={({ onSubmit }) => (
                  <Grid container justify="space-between" spacing={1}>
                    <Grid item>
                      <PrevButton onClick={() => setStep(1)} />
                    </Grid>
                    {editable && (
                      <Grid item>
                        <SaveButton onClick={onSubmit}>บันทึกร่าง</SaveButton>
                      </Grid>
                    )}
                    <Grid item>
                      <NextButton onClick={() => setStep(3)} />
                    </Grid>
                  </Grid>
                )}
              />
            </Fragment>
          )}
          {step === 3 && (
            <Fragment>
              <Spacing />
              <ProjectCompatabilityForm
                project={data.project}
                render={({ onSubmit }) => (
                  <Grid container justify="space-between" spacing={1}>
                    <Grid item>
                      <PrevButton onClick={() => setStep(2)} />
                    </Grid>
                    {editable && (
                      <Grid item>
                        <SaveButton onClick={onSubmit}>บันทึกร่าง</SaveButton>
                      </Grid>
                    )}
                    <Grid item>
                      <NextButton onClick={() => setStep(4)} />
                    </Grid>
                  </Grid>
                )}
              />
            </Fragment>
          )}
          {step === 4 && (
            <Fragment>
              <Spacing />
              <ProjectBudgets projectId={id} />
              <Spacing />
              <Spacing />
              <Grid container justify="space-between" spacing={1}>
                <Grid item>
                  <PrevButton onClick={() => setStep(3)} />
                </Grid>
                <Grid item>
                  <NextButton onClick={() => setStep(5)} />
                </Grid>
              </Grid>
            </Fragment>
          )}
          {step === 5 && (
            <Fragment>
              <Spacing />
              <ProjectOperatingPlan projectId={id} />
              <Spacing />
              <Spacing />
              <Grid container justify="space-between" spacing={1}>
                <Grid item>
                  <PrevButton onClick={() => setStep(4)} />
                </Grid>
                <Grid item>
                  <NextButton onClick={() => setStep(6)} />
                </Grid>
              </Grid>
            </Fragment>
          )}
          {step === 6 && (
            <Fragment>
              <Spacing />
              <Typography variant="h6" color="primary">
                แนบไฟล์เอกสาร
              </Typography>
              <Spacing />
              <Grid container justify="space-between" spacing={1}>
                <Grid item>
                  <PrevButton onClick={() => setStep(5)} />
                </Grid>
                <Grid item>
                  <NextButton onClick={() => setStep(7)} />
                </Grid>
              </Grid>
            </Fragment>
          )}
          {step === 7 && (
            <Fragment>
              <Spacing />
              <Typography variant="h6" color="primary" gutterBottom>
                ผู้รับผิดชอบโครงการ
              </Typography>
              <ProjectOrganizationAndMembers projectId={data?.project?.id} />
              <Spacing />

              <Grid container justify="space-between" spacing={1}>
                <Grid item>
                  <PrevButton onClick={() => setStep(6)} />
                </Grid>
                <Grid item>
                  <NextButton onClick={() => setStep(8)} />
                </Grid>
              </Grid>
            </Fragment>
          )}
          {step === 8 && (
            <Fragment>
              <Spacing />
              <Typography variant="h6" color="primary">
                ตรวจสอบการบันทึกผล
              </Typography>
              <Grid container justify="space-between" spacing={1}>
                <Grid item>
                  <PrevButton onClick={() => setStep(7)} />
                </Grid>
                <Grid item>
                  <NextButton onClick={() => setStep(9)} />
                </Grid>
              </Grid>
            </Fragment>
          )}
          {step === 9 && (
            <Fragment>
              <Typography variant="body1" color="error" align="right" gutterBottom>
                *เมื่อส่งคำขอแล้วจะไม่สามารถแก้ไขข้อมูลได้อีกและจะถูกพิจารณาในขั้นถัดไป
              </Typography>
              <Spacing />
              <Grid container justify="space-between" spacing={1}>
                <Grid item>
                  <PrevButton onClick={() => setStep(8)} />
                </Grid>
                <Grid item>
                  <ProjectSubmitButton />
                </Grid>
              </Grid>
            </Fragment>
          )}
        </InfoCard>
        <Spacing />
        {data?.project.step_id === 'submitted' && (
          <UserPermission permissionIds={['PROJECT_ADMIN']}>
            <ProjectVerification project={data?.project} />
          </UserPermission>
        )}
        <Spacing />
        <InfoCard title="ประวัติการทำรายการ" noPadding>
          <ProjectEventLog projectId={id} />
        </InfoCard>
      </ProjectContext.Provider>
    </Page>
  );
};

export default pageContent(ProjectDetailPage);
