import Button, { ButtonProps } from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';

const DeleteButton = ({ children, ...props }: Omit<ButtonProps, 'startIcon'>) => (
  <Button variant="contained" color="secondary" startIcon={<DeleteIcon />} {...props}>
    {children ? children : 'ลบข้อมูล'}
  </Button>
);

export default DeleteButton;
