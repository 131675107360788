import React, { Fragment, FC, useCallback } from 'react';
import { Hidden, IconButton, Typography, ListItemText } from '@material-ui/core';
import { TopBar, TopBarUserMenu, TopBarSpace, TopBarProps } from '@mystiny/ui';
import { withStyles } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';
import AppsIcon from '@material-ui/icons/Apps';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { Feature } from '@app/feature-toggle';
import clsx from 'clsx';
import { NotificationsPopover } from '@app/NotificationsPopover';
import { notifications } from '@app/notifications';
import api from '@app/libs/api';
import { useCurrentUser } from '@app/hooks';

const useStyles = makeStyles((theme) => ({
  userMenu: {
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(1.5),
      paddingLeft: theme.spacing(1.5),
    },
  },
  navbarBrand: {
    [theme.breakpoints.up('md')]: {
      width: 215,
      minWidth: 215,
      display: 'unset',
    },
    display: 'none',
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      color: theme.palette.common.white,
    },
  },
  drawerToggle: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
}));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const AppsMenu = ({ classes }: { classes: any }) => {
  const location = useLocation();
  const startsWith = useCallback((startPath: string) => location.pathname.startsWith(startPath), [location.pathname]);

  return (
    <Feature name="TOP_BAR_NEW_VERSION">
      <Hidden smDown>
        <div className={classes.drawerToggle}>
          <IconButton>
            <MenuIcon />
          </IconButton>
        </div>
      </Hidden>
      <div className={classes.drawerToggle}>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button startIcon={<AppsIcon />} variant="text" color="primary" size="large" {...bindTrigger(popupState)}>
                คำขอสินเชื่อกองทุน
              </Button>
              <Menu {...bindMenu(popupState)}>
                <StyledMenuItem
                  onClick={popupState.close}
                  className={clsx({
                    active: startsWith('/bof/loan'),
                  })}
                >
                  <ListItemText primary="คำขอสินเชื่อกองทุน" />
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={popupState.close}
                  className={clsx({
                    active: startsWith('/bof/accounting'),
                  })}
                >
                  <ListItemText primary="บัญชีและการเงิน" />
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={popupState.close}
                  className={clsx({
                    active: startsWith('/bof/projects'),
                  })}
                >
                  <ListItemText primary="แผนงานและงบประมาณ" />
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={popupState.close}
                  disabled
                  className={clsx({
                    active: startsWith('/bof/loan'),
                  })}
                >
                  <ListItemText primary="แหล่งเงินทุน SME Bank" />
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={popupState.close}
                  className={clsx({
                    active: startsWith('/bof/loan'),
                  })}
                  disabled
                >
                  <ListItemText primary="ผู้ดูแลระบบ" />
                </StyledMenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </div>
    </Feature>
  );
};

const BackOfficeTopBar: FC<TopBarProps> = (props) => {
  const classes = useStyles();
  const user = useCurrentUser();
  const { enqueueSuccess } = useResponseBar();

  return (
    <Fragment>
      <TopBar
        AppBarProps={{
          variant: 'elevation',
          color: 'inherit',
          style: { backgroundColor: 'rgba(222, 255, 223, 0.44)' },
        }}
        ToolbarProps={{ disableGutters: false }}
        {...props}
      >
        <div className={classes.navbarBrand}>
          <Link to="/bof">
            <Typography variant="h6" color="inherit" noWrap>
              <Hidden smUp>
                <Typography
                  style={{ color: process.env.REACT_APP_APP_ENV === 'production' ? 'red' : 'green' }}
                  variant="body1"
                >
                  {process.env.REACT_APP_APP_ENV === 'production'
                    ? '⚠️ ใช้งาน "เซิฟเวอร์จริง"'
                    : '⚠️ ใช้งาน "เซิฟเวอร์ทดสอบ"'}
                </Typography>
              </Hidden>
              <Hidden xsDown>Back Office</Hidden>
            </Typography>
          </Link>
        </div>
        <AppsMenu classes={classes} />
        <TopBarSpace />
        <Hidden xsDown>
          <Typography style={{ color: process.env.REACT_APP_APP_ENV === 'production' ? 'red' : 'green' }} variant="h6">
            {process.env.REACT_APP_APP_ENV === 'production'
              ? '⚠️ ท่านกำลังใช้งาน "เซิฟเวอร์จริง"'
              : '⚠️ ท่านกำลังใช้งาน "เซิฟเวอร์ทดสอบ"'}
          </Typography>
        </Hidden>
        <TopBarSpace />
        <NotificationsPopover notifications={notifications} />
        <div className={classes.userMenu}>
          <TopBarUserMenu
            actions={[
              {
                spacer: true,
              },
              {
                content: 'ออกจากระบบ',
                onAction: async () => {
                  await api.logout();
                  // Hard redirect to first page
                  window.location.href = '/';
                  enqueueSuccess('ออกจากระบบสำเร็จ');
                },
              },
            ]}
            name={user?.displayName || '...'}
            detail={`${user?.account?.defaultRole} ${
              user?.organization?.shortName ? `(${user?.organization?.shortName})` : ''
            }`}
          />
        </div>
      </TopBar>
    </Fragment>
  );
};

export default BackOfficeTopBar;
