import { TextField } from '@aginix/mui-react-hook-form-input';
import * as uuid from 'uuid';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  useGetProjectOperatingActivitiesByProjectIdQuery,
  ProjectOperatingActivityWithProgressesFragment,
  ProjectOperatingActivityProgressInsertInput,
  GetProjectOperatingActivitiesByProjectIdQuery,
  useUpdateProjectOperatingActivityProgressesMutation,
  ProjectOperatingActivityProgressUpdateColumn,
} from '@app/generated/graphql';
import SaveButton from '@app/shared/components/buttons/SaveButton';
import { DateUtils, monthsShort } from '@app/shared/libs/date';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { InfoCard } from '@mystiny/ui';
import React, { Fragment, useMemo, memo } from 'react';
import { Control, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import NumberFormat from 'react-number-format';
import { createNumberFormatInput } from '@app/components/NumberFormatInput';
import { green, red } from '@material-ui/core/colors';

import { ExportButton } from '@app/views/Project/components/ProjectOperatingActivitiesProgress/export/ExportButton';

const NumberFormatInput = createNumberFormatInput({
  thousandSeparator: true,
  allowNegative: true,
  allowEmptyFormatting: false,
  style: { textAlign: 'right' },
});

const DisplayNumber = ({ value }: { value: number }) => (
  <NumberFormat value={value} displayType="text" thousandSeparator />
);

const useStyles = makeStyles((theme) => ({
  dense: {
    padding: theme.spacing(1),
  },
}));

const TotalOperating = memo(
  ({
    activityIndex,
    control,
    defaultValue,
    type = 'plan',
  }: {
    activityIndex: number;
    control: Control;
    defaultValue: ProjectOperatingActivityWithProgressesFragment['progresses'];
    type?: 'plan' | 'actual';
  }) => {
    const classes = useStyles();
    const activity = useWatch<ProjectOperatingActivityWithProgressesFragment>({
      name: `activities[${activityIndex}]`,
      control: control,
      // defaultValue: defaultValue,
    });

    if (!activity) {
      return (
        <Fragment>
          <TableCell component="th" className={clsx('text-right', classes.dense)}>
            0
          </TableCell>
          <TableCell component="th" className={clsx('text-right', classes.dense)}>
            0
          </TableCell>
        </Fragment>
      );
    }

    const target = activity.progresses.reduce((sum, progress) => sum + (Number(progress.workload) || 0), 0);
    const budget = activity.progresses.reduce((sum, progress) => sum + (Number(progress.spend) || 0), 0);

    const targetPlan = activity.progresses.reduce((sum, progress) => sum + (Number(progress.workload_plan) || 0), 0);
    const budgetPlan = activity.progresses.reduce((sum, progress) => sum + (Number(progress.spend_plan) || 0), 0);

    const targetDiff = targetPlan - target;
    const budgetDiff = budgetPlan - budget;

    return (
      <Fragment>
        <TableCell component="th" className={clsx('text-right', classes.dense)}>
          <div>
            <DisplayNumber value={type === 'plan' ? targetPlan : target} />
          </div>
          {type !== 'plan' && targetDiff !== 0 ? (
            <span style={{ color: targetDiff > 0 ? red[700] : green[700] }}>
              (<DisplayNumber value={targetDiff} />)
            </span>
          ) : (
            ''
          )}
        </TableCell>
        <TableCell component="th" className={clsx('text-right', classes.dense)}>
          <div>
            <DisplayNumber value={type === 'plan' ? budgetPlan : budget} />
          </div>
          {type !== 'plan' && budgetDiff !== 0 ? (
            <span style={{ color: budgetDiff > 0 ? red[700] : green[700] }}>
              (<DisplayNumber value={budgetDiff} />)
            </span>
          ) : (
            ''
          )}
        </TableCell>
      </Fragment>
    );
  },
  (prev, next) => prev.activityIndex === next.activityIndex
);

const ProjectOperatingActivitiesProgressForm = ({ rows, project }: GetProjectOperatingActivitiesByProjectIdQuery) => {
  const classes = useStyles();
  const form = useForm<{ activities: ProjectOperatingActivityWithProgressesFragment[] }>({
    defaultValues: {
      activities: rows,
    },
  });

  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [updateFn, { loading: updating }] = useUpdateProjectOperatingActivityProgressesMutation({
    onCompleted: () => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
    },
    onError: (err: any) => {
      console.error(err);
      enqueueFailure('เกิดข้อผิดพลาด');
    },
  });
  const handleSubmit = form.handleSubmit((formData) => {
    const cleanedData = formData.activities.reduce<ProjectOperatingActivityProgressInsertInput[]>((prev, activity) => {
      const progresses = activity.progresses.map<ProjectOperatingActivityProgressInsertInput>((progress) => ({
        id: progress.id,
        month: progress.month,
        spend: Number(progress.spend) || null,
        workload: Number(progress.workload) || null,
        revision: Number(progress.revision) || 0,
        project_operating_activity_id: activity.id,

        spend_plan: undefined,
        workload_plan: undefined,
      }));

      return [...prev, ...progresses];
    }, []);
    updateFn({
      variables: {
        objects: cleanedData,
        update_columns: [
          ProjectOperatingActivityProgressUpdateColumn.Revision,
          ProjectOperatingActivityProgressUpdateColumn.Spend,
          ProjectOperatingActivityProgressUpdateColumn.Workload,
          ProjectOperatingActivityProgressUpdateColumn.Revision,
          ProjectOperatingActivityProgressUpdateColumn.ProjectOperatingActivityId,
        ],
      },
    });
  });

  const months = useMemo(() => DateUtils.getAllMonthBetween(project!.start_at, project!.end_at), [project]);

  const dataForExport = rows.map((activity, activityIndex) => ({
    activity_name: activity.name,
    target_amount: activity.target_amount,
    target_unit: activity.target_unit,
    total_budget: activity.budget_items_aggregate?.aggregate?.sum?.total,
    months: months.reduce<any[]>(
      (prev, date) => [
        ...prev,
        {
          month: date.getMonth(),
          // [date.getMonth()]: {
          budget: activity.progresses?.find((progress) => progress.month === date.getMonth() + 1)?.spend_plan,
          workload: activity.progresses?.find((progress) => progress.month === date.getMonth() + 1)?.workload_plan,
          budget_actual: activity.progresses?.find((progress) => progress.month === date.getMonth() + 1)?.spend,
          workload_actual: activity.progresses?.find((progress) => progress.month === date.getMonth() + 1)?.workload,
          // },
        } as any,
      ],
      []
    ),
  }));

  return (
    <FormProvider {...form}>
      <InfoCard
        title={`แผน/ผลการปฏิบัติงานละแผน/ผลการใช้จ่ายเงินประจำปีงบประมาณ พ.ศ. ${project?.fiscal_year}`}
        // subheader={
        //   <Typography color="primary">
        //     * เป้าหมาย/งบประมาณ ข้อความสีแดงหมายถึง ผลรวมของข้อมูลฝั่งผล น้อยกว่าแผน, ข้อความสีเขียวหมายถึง
        //     ผลรวมของข้อมูลฝั่งผล มากกว่าแผน <br />
        //   </Typography>
        // }
        subheader={
          <Fragment>
            <Typography color="error">
              * เป้าหมาย/งบประมาณ ข้อความสีแดงหมายถึง ส่วนต่างระหว่างกลุ่มเป้าหมาย/งบประมาณ และแผน <br />
              หากมีเครื่องหมายติดลบ (-) หมายถึง ส่วนต่างเกินกว่าเป้าที่ตั้งไว้
            </Typography>
            <Typography color="primary">* Export ข้อมูล จะนำออกเฉพาะข้อมูลที่บันทึกแล้วเท่านั้น</Typography>
          </Fragment>
        }
        actionTopRight={
          <div>
            <Box mr={1} component="span">
              <ExportButton data={dataForExport} name="แผน/ผลการปฏิบัติงานละการใช้จ่ายเงินประจำปีงบประมาณ" />
            </Box>
            {<SaveButton disabled={updating} onClick={handleSubmit} />}
          </div>
        }
      >
        <Box width="100%">
          <TableContainer>
            <Table className="bordered">
              <TableHead>
                <TableRow>
                  <TableCell component="th" style={{ minWidth: 220 }} rowSpan={2}>
                    กิจกรรม/ค่าใช้จ่าย/รายการ
                  </TableCell>
                  <TableCell component="th" style={{ minWidth: 120 }} rowSpan={2}>
                    กลุ่มเป้าหมาย
                  </TableCell>
                  <TableCell component="th" style={{ minWidth: 120 }} rowSpan={2}>
                    งบประมาณ
                  </TableCell>
                  <TableCell component="th" align="center" colSpan={2}>
                    รวม
                  </TableCell>
                  {months.map((date) => (
                    <TableCell key={`month-${date.getTime()}`} component="th" align="center" colSpan={2}>
                      {monthsShort[date.getMonth()]}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell component="th" style={{ minWidth: 105 }} align="center">
                    เป้าหมาย
                  </TableCell>
                  <TableCell component="th" style={{ minWidth: 105 }} align="center">
                    งบประมาณ
                  </TableCell>
                  {months.map((date) => (
                    <Fragment key={`plan-${date.getTime()}`}>
                      <TableCell component="th" style={{ minWidth: 105 }} align="center">
                        เป้าหมาย
                      </TableCell>
                      <TableCell component="th" style={{ minWidth: 105 }} align="center">
                        งบประมาณ
                      </TableCell>
                    </Fragment>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((activity, activityIndex) => (
                  <Fragment key={activity.id}>
                    <TableRow role="checkbox" tabIndex={-1}>
                      <TableCell rowSpan={2}>
                        {activityIndex + 1}. {activity.name}
                        <TextField type="hidden" defaultValue={activity.id} name={`activities[${activityIndex}].id`} />
                      </TableCell>
                      <TableCell align="center" rowSpan={2}>
                        <DisplayNumber value={activity.target_amount} />
                        <br /> {activity.target_unit?.description || ''}
                        {!activity.target_amount && !activity.target_unit ? '-' : ''}
                      </TableCell>
                      <TableCell align="center" rowSpan={2}>
                        <DisplayNumber value={activity.budget_items_aggregate.aggregate?.sum?.total} />
                      </TableCell>
                      <TotalOperating
                        control={form.control}
                        defaultValue={activity.progresses}
                        activityIndex={activityIndex}
                      />
                      {DateUtils.getAllMonthBetween(project!.start_at, project!.end_at).map((date, index) => {
                        const progress = activity.progresses.find((progress) => progress.month === date.getMonth() + 1);
                        return (
                          <Fragment key={`${activity.id}-${date.getTime()}`}>
                            <TableCell component="th" className={clsx('text-right', classes.dense)}>
                              <TextField
                                disabled
                                className="text-right"
                                defaultValue={progress?.workload_plan}
                                name={`activities[${activityIndex}].progresses[${index}].workload_plan`}
                                InputProps={{
                                  inputComponent: NumberFormatInput as any,
                                }}
                              />
                            </TableCell>
                            <TableCell component="th" className={clsx('text-right', classes.dense)}>
                              <TextField
                                disabled
                                className="text-right"
                                defaultValue={progress?.spend_plan}
                                name={`activities[${activityIndex}].progresses[${index}].spend_plan`}
                                InputProps={{
                                  inputComponent: NumberFormatInput as any,
                                }}
                              />
                              <span style={{ display: 'none' }}>
                                <TextField
                                  type="hidden"
                                  defaultValue={progress?.id || uuid.v4()}
                                  name={`activities[${activityIndex}].progresses[${index}].id`}
                                />
                                <TextField
                                  type="hidden"
                                  defaultValue={date.getMonth() + 1}
                                  name={`activities[${activityIndex}].progresses[${index}].month`}
                                />
                              </span>
                            </TableCell>
                          </Fragment>
                        );
                      })}
                    </TableRow>
                    <TableRow>
                      <TotalOperating
                        control={form.control}
                        defaultValue={activity.progresses}
                        activityIndex={activityIndex}
                        type="actual"
                      />
                      {DateUtils.getAllMonthBetween(project!.start_at, project!.end_at).map((date, index) => {
                        const progress = activity.progresses.find((progress) => progress.month === date.getMonth() + 1);
                        return (
                          <Fragment key={`actual-${activity.id}-${date.getTime()}`}>
                            <TableCell component="th" className={clsx('text-right', classes.dense)}>
                              <TextField
                                className="text-right"
                                defaultValue={progress?.workload}
                                name={`activities[${activityIndex}].progresses[${index}].workload`}
                                InputProps={{
                                  inputComponent: NumberFormatInput as any,
                                }}
                              />
                            </TableCell>
                            <TableCell component="th" className={clsx('text-right', classes.dense)}>
                              <TextField
                                className="text-right"
                                defaultValue={progress?.spend}
                                name={`activities[${activityIndex}].progresses[${index}].spend`}
                                InputProps={{
                                  inputComponent: NumberFormatInput as any,
                                }}
                              />
                            </TableCell>
                          </Fragment>
                        );
                      })}
                    </TableRow>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </InfoCard>
    </FormProvider>
  );
};

export const ProjectOperatingActivitiesProgress = ({ projectId }: { projectId: string }) => {
  const { data, loading } = useGetProjectOperatingActivitiesByProjectIdQuery({
    variables: {
      projectId,
    },
    fetchPolicy: 'no-cache',
  });

  if (!data?.rows && loading) {
    return <CircularProgress />;
  }

  if (!data?.rows) {
    return <CircularProgress />;
  }

  return <ProjectOperatingActivitiesProgressForm {...data!} />;
};
