import DataGridContainer from '@app/shared/components/DataGridContainer';
import React, { Fragment } from 'react';
import DataGrid, { Column } from 'react-data-grid';
import { ProjectEventLogFragment, useGetProjectEventLogsByProjectIdQuery } from '@app/generated/graphql';
import { DateUtils } from '@app/shared/libs/date';

const columns: Column<ProjectEventLogFragment>[] = [
  {
    key: 'created_at',
    name: 'วันที่ทำรายการ',
    formatter: ({ row }) => <Fragment>{DateUtils.toThaiDatetime(row.created_at)}</Fragment>,
  },
  {
    key: 'description',
    name: 'คำอธิบาย',
    formatter: ({ row }) => (
      <Fragment>
        {row.step_id === 'draft'
          ? row.description
          : row.step_id === 'submitted'
          ? 'ส่งแบบฟอร์มคำของบประมาณ'
          : `สถานะ${row.approved?.description} -> สถานะ${row.approved?.description}`}
      </Fragment>
    ),
  },
  {
    key: 'remark',
    name: 'หมายเหตุ',
    formatter: ({ row }) => <Fragment>{row.remark}</Fragment>,
  },
  {
    key: 'created_by.display_name',
    name: 'ชื่อผู้ทำรายการ',
    formatter: ({ row }) => <Fragment>{row.user?.display_name}</Fragment>,
  },
];

export const ProjectEventLog = ({ projectId }: { projectId: string }) => {
  const { data } = useGetProjectEventLogsByProjectIdQuery({
    variables: {
      projectId,
    },
  });
  return (
    <DataGridContainer height={240}>
      <DataGrid
        columns={columns}
        defaultColumnOptions={{ resizable: true }}
        className="fill-grid rdg-light"
        rows={data?.rows || []}
      />
    </DataGridContainer>
  );
};
