import Button, { ButtonProps } from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';

const EditButton = (props: Omit<ButtonProps, 'startIcon'>) => (
  <Button variant="outlined" color="primary" startIcon={<EditIcon />} {...props}>
    แก้ไขข้อมูล
  </Button>
);

export default EditButton;
