import {
  GetBudgetTypeByPkQuery,
  useGetAccountBudgetsByBudgetIdWithFiscalYearQuery,
  useReplaceBudgetAccountsMutation,
} from '@app/generated/graphql';
import SaveButton from '@app/shared/components/buttons/SaveButton';
import { CircularProgress, FormControl, FormControlLabel, FormGroup, FormLabel, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { InfoCard } from '@mystiny/ui';
import { groupBy } from 'lodash';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Checkbox } from '@aginix/mui-react-hook-form-input';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import ButtonCircularProgress from '@app/shared/components/ButtonCircularProgress';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
}));

export const BudgetAccountsForm = ({ data }: { data: GetBudgetTypeByPkQuery['entity'] }) => {
  const classes = useStyles();
  const form = useForm();
  const { enqueueSuccess } = useResponseBar();
  const [mutateFn, { loading: updating }] = useReplaceBudgetAccountsMutation({
    onCompleted: () => {
      enqueueSuccess('บันทึกข้อมูลรายชื่อบัญชีสำเร็จ');
    },
  });
  const { data: response, loading } = useGetAccountBudgetsByBudgetIdWithFiscalYearQuery({
    variables: {
      fiscalYear: data?.fiscal_year!,
      budgetId: data?.id!,
    },
    fetchPolicy: 'no-cache',
  });

  const grouped = groupBy(response?.rows || [], 'account_type.description');

  const handleSubmit = form.handleSubmit((dataForm) => {
    const { id: budgetId, fiscal_year: fiscalYear } = data!;
    const selectedAccountIds = Object.keys(dataForm).filter((accountId) => dataForm[accountId]);
    const budgetAccounts = selectedAccountIds.map((accountId) => ({
      account_id: accountId,
      budget_id: budgetId,
      fiscal_year: fiscalYear,
    }));

    mutateFn({
      variables: {
        budgetId: budgetId,
        fiscalYear: fiscalYear,
        selectedIds: selectedAccountIds,
        budgetAccounts: budgetAccounts,
      },
    });
  });

  return (
    <InfoCard
      title="รายชื่อบัญชีภายใต้งบประมาณ"
      actions={
        <SaveButton disabled={updating} onClick={handleSubmit}>
          บันทึก {updating && <ButtonCircularProgress />}
        </SaveButton>
      }
    >
      <Typography variant="body1" color="primary">
        *รายชื่อบัญชีที่เลือก จะถูกแสดงในแบบฟอร์มต่างๆ สำหรับการบันทึกข้อมูลงบประมาณ และบัญชี
      </Typography>
      {loading && <CircularProgress />}
      {response?.rows ? (
        <FormProvider {...form}>
          {Object.keys(grouped).map((groupName: string) => (
            <div key={groupName}>
              <FormControl component="fieldset" disabled={updating} className={classes.formControl}>
                <FormLabel component="legend">{groupName === 'undefined' ? 'ไม่มีประเภทบัญชี' : groupName}</FormLabel>
                <FormGroup>
                  {grouped[groupName].map((row) => (
                    <FormControlLabel
                      key={row.id}
                      control={
                        <Checkbox
                          value={row.id}
                          defaultChecked={row.budgets_aggregate?.aggregate?.count !== 0}
                          name={row.id}
                        />
                      }
                      label={`${row.code} : ${row.name}`}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </div>
          ))}
        </FormProvider>
      ) : (
        <Typography variant="h6">ไม่มีข้อมูล</Typography>
      )}
    </InfoCard>
  );
};
