import { ErrorMessage } from '@aginix/mui-react-hook-form-input';
import Spacing from '@app/components/common/Spacing';
import {
  ProjectFragment,
  useGetNationalStrategiesTreeViewQuery,
  useUpdateProjectByPkTmpMutation,
} from '@app/generated/graphql';
import AutocompleteInput from '@app/shared/components/AutocompleteInput';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { Box, CircularProgress, FormControl, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useProjectEditable } from '../contexts/ProjectContext';

export const ProjectCompatabilityForm = ({
  project,
  render,
}: {
  project: any;
  render: (props: { onSubmit: () => any }) => React.ReactNode;
}) => {
  const editable = useProjectEditable();
  const form = useForm<ProjectFragment>({
    defaultValues: project!,
  });
  const { enqueueSuccess, enqueueFailure } = useResponseBar();

  const { data, loading: fetching } = useGetNationalStrategiesTreeViewQuery({});

  const [updateFn, { loading }] = useUpdateProjectByPkTmpMutation({
    onCompleted: () => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
    },
    onError: (err: any) => {
      console.error(err);
      enqueueFailure('เกิดข้อผิดพลาด');
    },
  });

  const handleSubmit = form.handleSubmit((formData: any) => {
    updateFn({
      variables: {
        id: project!.id,
        project: {
          national_strategy_lvl1_id: formData.national_strategy_lvl1_id,
          national_strategy_lvl2_id: formData.national_strategy_lvl2_id,
          national_strategy_lvl3_id: formData.national_strategy_lvl3_id,
          national_strategy_lvl4_id: formData.national_strategy_lvl4_id,
        },
      },
    });
  });

  const level1Id = form.watch('national_strategy_lvl1_id');

  useEffect(() => {
    form.setValue('national_strategy_lvl2_id', undefined);
    form.setValue('national_strategy_lvl3_id', undefined);
    form.setValue('national_strategy_lvl4_id', undefined);
  }, [form, level1Id]);

  const level2 = level1Id
    ? data?.national_strategies.find((strategy) => strategy.id === level1Id) || undefined
    : undefined;

  if (fetching) {
    return (
      <Grid container justify="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <FormProvider {...form}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">ยุทธศาสตร์ชาติ</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <AutocompleteInput
              size="small"
              disabled={!editable}
              label="ด้านยุทธศาสตร์ชาติ"
              name="national_strategy_lvl1_id"
              defaultValue={project?.national_strategy_lvl1_id}
              options={data?.national_strategies || []}
              loading={loading}
              primaryKey="id"
              getOptionLabel={(option: any) => option.name}
            />
            <ErrorMessage name="national_strategy_lvl1_id" />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box paddingLeft={2}>
            <FormControl fullWidth>
              <AutocompleteInput
                size="small"
                label="ประเด็นแผนแม่บท (จ.1)"
                name="national_strategy_lvl2_id"
                defaultValue={project?.national_strategy_lvl2_id}
                options={level2?.children?.find((i) => i.name === 'ประเด็น')?.children || []}
                disabled={!editable || !level2}
                loading={loading}
                primaryKey="id"
                getOptionLabel={(option: any) => option.name}
              />
              <ErrorMessage name="national_strategy_lvl2_id" />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box paddingLeft={2}>
            <FormControl fullWidth>
              <AutocompleteInput
                size="small"
                label="เป้าหมายแผนแม่บท (จ.2)"
                name="national_strategy_lvl3_id"
                defaultValue={project?.national_strategy_lvl3_id}
                options={level2?.children?.find((i) => i.name === 'เป้าหมาย')?.children || []}
                disabled={!editable || !level2}
                loading={loading}
                primaryKey="id"
                getOptionLabel={(option: any) => option.name}
              />
              <ErrorMessage name="national_strategy_lvl3_id" />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box paddingLeft={2}>
            <FormControl fullWidth>
              <AutocompleteInput
                size="small"
                label="แผนแม่บทย่อย"
                name="national_strategy_lvl4_id"
                defaultValue={project?.national_strategy_lvl4_id}
                options={level2?.children?.find((i) => i.name === 'ตัวชี้วัด')?.children || []}
                disabled={!editable || !level2}
                loading={loading}
                primaryKey="id"
                getOptionLabel={(option: any) => option.name}
              />
              <ErrorMessage name="national_strategy_lvl4_id" />
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <Spacing />
      {render({
        onSubmit: handleSubmit,
      })}
    </FormProvider>
  );
};
