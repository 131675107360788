import { useInsertTargetGroupOneMutation, TargetGroupFragment } from '@app/generated/graphql';
import DescriptionIcon from '@material-ui/icons/Description';
import { pageContent } from '@app/shared/hoc/page-content';
import React from 'react';
import PageTitle from '@app/shared/components/PageTitle';
import BackButton from '@app/shared/components/buttons/BackButton';
import { InfoCard } from '@mystiny/ui';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { FormProvider, useForm } from 'react-hook-form';
import Spacing from '@app/components/common/Spacing';
import { Typography } from '@material-ui/core';
import SaveButton from '@app/shared/components/buttons/SaveButton';
import Page from '@app/components/Page';
import { Form } from '@app/views/ProjectTargetGroup/components/Form';
import { useHistory } from 'react-router-dom';

const TargetGroupCreate = () => {
  const history = useHistory();
  const form = useForm<TargetGroupFragment>();
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [createFn] = useInsertTargetGroupOneMutation({
    onCompleted: ({ insert_project_target_group_one }) => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
      history.push('/bof/projects/master-data/target-groups/' + insert_project_target_group_one?.id);
    },
    onError: (err: any) => {
      console.error(err);
      enqueueFailure('เกิดข้อผิดพลาด' + err);
    },
  });
  const handleSubmit = form.handleSubmit((formData: TargetGroupFragment) => {
    createFn({
      variables: {
        object: {
          id: formData.id,
          description: formData.description,
        },
      },
    });
  });

  return (
    <Page
      title={
        <PageTitle
          title={
            <React.Fragment>
              <Typography variant="h6" component="div">
                <DescriptionIcon color="primary" fontSize="small" /> เพิ่มกลุ่มเป้าหมาย
              </Typography>
            </React.Fragment>
          }
          actions={
            <div>
              <BackButton to="/bof/projects/master-data/target-groups" />
            </div>
          }
        />
      }
    >
      <Spacing />
      <InfoCard title="ข้อมูลกลุ่มเป้าหมาย" actionTopRight={<SaveButton onClick={handleSubmit} />}>
        <FormProvider {...form}>
          <Form mode="create" />
        </FormProvider>
      </InfoCard>
      <Spacing />
    </Page>
  );
};

export default pageContent(TargetGroupCreate);
