import React, { Fragment, useState } from 'react';
import { ProjectBudgetFragment } from '@app/generated/graphql';
import { TextField, Typography } from '@material-ui/core';

export const SampleBudget = ({ data }: { data: ProjectBudgetFragment }) => {
  const [amount, setAmount] = useState(1);

  const totalAmount = Number(amount || 1) * data.price_per_unit;
  return (
    <Fragment>
      <TextField
        onChange={(event) => {
          const value = event.target.value;
          if (value) {
            setAmount(Number(value));
          }
        }}
        label={`จำนวน${data.unit?.description}`}
        value={amount}
      />
      <Typography variant="body1">
        {data.name} จำนวน <b>{amount}</b> คน จำนวน 6 {data.unit_of_multiplier?.description}ๆ ละ {data.price_per_unit} บาท จำนวน 1{' '}
        {data.unit?.description} รวมเป็นเงิน {totalAmount} บาท
      </Typography>
    </Fragment>
  );
};
