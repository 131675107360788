import React, { useState } from 'react';
import { LedgerLedgerTypeEnum, LedgerFragment } from '@app/generated/graphql';
import DataGridContainer from '@app/shared/components/DataGridContainer';
import DataGrid from 'react-data-grid';
import { ledgerColumns } from '../columns';
import { InfoCard } from '@mystiny/ui';
import LedgerTypeFilter from './LedgerTypeFilter';

export interface ReceivableLedgersProps {
  ledgers: LedgerFragment[];
}

export const ReceivableLedgers = ({ ledgers }: ReceivableLedgersProps) => {
  const [ledgerType, setLedgerType] = useState<LedgerLedgerTypeEnum>(LedgerLedgerTypeEnum.Expense);
  return (
    <InfoCard title="ค่าใช้จ่ายที่เกี่ยวข้อง" noPadding>
      <LedgerTypeFilter index={ledgerType} setIndex={setLedgerType} />
      <DataGridContainer height={280}>
        <DataGrid
          columns={ledgerColumns}
          rows={ledgers.filter((row) => row.type_id === ledgerType) || []}
          className="fill-grid rdg-light"
          defaultColumnOptions={{ sortable: true, resizable: true }}
        />
      </DataGridContainer>
    </InfoCard>
  );
};
