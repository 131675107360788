import { DatePicker, ErrorMessage, TextField } from '@aginix/mui-react-hook-form-input';
import { useIfUser } from '@app/components/common/Routes';
import {
  ReceivableFragment,
  GetOrganizationsComponent,
  LedgerReceivableStatusEnum,
  useGetBudgetTypesQuery,
} from '@app/generated/graphql';
import { FormProps } from '@app/global-props';
import AutocompleteInput from '@app/shared/components/AutocompleteInput';
import { FormControl, FormHelperText, TextField as MuiTextField, Typography } from '@material-ui/core';
import { FormLayout, FormLayoutGroup } from '@mystiny/ui';
import React, { Fragment, useMemo } from 'react';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { DateUtils } from '@app/shared/libs/date';
import { createNumberFormatInput } from '@app/components/NumberFormatInput';
import Select from 'react-select';

const NumberFormatInput = createNumberFormatInput({
  allowNegative: false,
  thousandSeparator: true,
});

const BudgetAutocomplete = ({
  control,
  fiscalYear,
  disabled,
}: {
  control: Control;
  fiscalYear: number;
  disabled?: boolean;
}) => {
  const { data: budget, loading: budgetLoading } = useGetBudgetTypesQuery({
    fetchPolicy: 'cache-first',
  });

  const options = useMemo(() => (budget?.rows ? budget?.rows.filter((row) => row.fiscal_year === fiscalYear) : []), [
    budget,
    fiscalYear,
  ]);

  return (
    <FormControl fullWidth>
      <Typography color="textSecondary">งบประมาณ</Typography>
      <Controller
        name="budget"
        control={control}
        rules={{ required: 'กรุณาระบุงบประมาณ' }}
        render={({ name, value, onChange }) => (
          <Select
            name={name}
            isSearchable
            isLoading={budgetLoading}
            value={value}
            onChange={onChange}
            isDisabled={disabled}
            options={options}
            placeholder="งบประมาณ"
            getOptionLabel={(v) => v.description}
            getOptionValue={(option) => option.id}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        )}
      />
      <ErrorMessage name="budget" />
    </FormControl>
  );
};

export const ReceivableForm = ({ data, mode }: FormProps<ReceivableFragment>) => {
  const isSysadmin = useIfUser({ condition: ({ roles }) => roles.includes('sysadmin') });
  const form = useFormContext();

  const disabled =
    mode === 'update' &&
    [LedgerReceivableStatusEnum.Submitted, LedgerReceivableStatusEnum.Done].includes(
      data?.status_id || LedgerReceivableStatusEnum.Draft
    );

  const fiscalYear = DateUtils.getThaiFiscalYear(form.watch('date'));

  return (
    <FormLayout>
      <FormLayoutGroup>
        <FormControl fullWidth>
          <DatePicker
            label="วันที่ทำรายการ"
            name="date"
            id="date"
            format="dd/MM/yyyy"
            inputVariant="outlined"
            size="small"
            fullWidth
            required
            disabled={disabled}
          />
          {/* <FormHelperText>สามารถทำรายการย้อนหลังได้ 30 วัน</FormHelperText> */}
        </FormControl>
        <FormControl fullWidth>
          <MuiTextField
            label="ปีงบประมาณ (พ.ศ.)"
            name="fiscal_year"
            id="fiscal_year"
            variant="standard"
            size="small"
            value={fiscalYear}
            InputProps={{ readOnly: true }}
            disabled={disabled}
          />
          <FormHelperText>*คำนวณอัตโนมัติจากข้อมูลวันที่ทำรายการ</FormHelperText>
        </FormControl>
      </FormLayoutGroup>
      <FormLayoutGroup>
        <BudgetAutocomplete control={form.control} fiscalYear={fiscalYear} disabled={disabled} />
      </FormLayoutGroup>
      <FormLayoutGroup>
        <FormControl fullWidth error={!!form.errors['gfmis_id']}>
          <TextField
            label="เลขที่ใบขอเบิก GFMIS"
            name="gfmis_id"
            id="gfmis_id"
            variant="outlined"
            size="small"
            rules={{
              required: 'กรุณากรอกเลขที่ใบขอเบิก',
              minLength: { value: 10, message: 'กรุณาระบุให้ครบ 10 ตัวอักษร' },
              maxLength: { value: 10, message: 'กรุณาระบุให้ครบ 10 ตัวอักษร' },
            }}
            required
            disabled={disabled}
          />
          <ErrorMessage name="gfmis_id" />
        </FormControl>
        <FormControl fullWidth error={!!form.errors['reference']}>
          <TextField
            label="เลขที่ใบยืม"
            name="reference"
            id="reference"
            variant="outlined"
            size="small"
            rules={{ required: 'เลขที่ใบยืม' }}
            required
            disabled={disabled}
          />
          <ErrorMessage name="reference" />
        </FormControl>
      </FormLayoutGroup>
      <FormLayoutGroup>
        <FormControl fullWidth error={!!form.errors['borrower_name']}>
          <TextField
            label="ชื่อผู้ยืม"
            name="borrower_name"
            id="borrower_name"
            variant="outlined"
            size="small"
            rules={{ required: 'กรุณากรอกชื่อผู้ยืม' }}
            required
            disabled={disabled}
          />
          <ErrorMessage name="borrower_name" />
        </FormControl>
        <FormControl fullWidth error={!!form.errors['amount']}>
          <TextField
            label="จำนวนเงินยืม"
            name="amount"
            variant="outlined"
            size="small"
            fullWidth
            rules={{
              required: 'กรุณากรอกจำนวนเงิน',
              min: {
                value: 0,
                message: 'จำนวนเงินต้องมากกว่า 0 เท่านั้น',
              },
            }}
            InputProps={{
              inputComponent: NumberFormatInput as any,
            }}
            required
            disabled={disabled}
          />
          <ErrorMessage name="amount" />
        </FormControl>
      </FormLayoutGroup>
      <FormLayoutGroup>
        <FormControl fullWidth error={!!form.errors['proposal']}>
          <TextField
            label="วัตถุประสงค์"
            name="proposal"
            id="proposal"
            variant="outlined"
            size="small"
            rules={{ required: 'วัตถุประสงค์' }}
            required
            disabled={disabled}
          />
          <ErrorMessage name="proposal" />
        </FormControl>
        <FormControl fullWidth error={!!form.errors['due_date']}>
          <DatePicker
            label="วันที่ครบกำหนด"
            name="due_date"
            id="due_date"
            format="dd/MM/yyyy"
            inputVariant="outlined"
            size="small"
            fullWidth
            // disablePast
            required
            disabled={disabled}
          />
          <ErrorMessage name="due_date" />
        </FormControl>
      </FormLayoutGroup>
      {isSysadmin && (
        <FormLayoutGroup>
          <GetOrganizationsComponent fetchPolicy="cache-and-network">
            {({ data, loading }) => (
              <FormControl fullWidth>
                <AutocompleteInput
                  label="หน่วยงาน"
                  name="organization_id"
                  options={data?.rows || []}
                  loading={loading}
                  disableClearable
                  size="small"
                  primaryKey="id"
                  getOptionLabel={(option: any) => `${option.name}`}
                  rules={{ required: 'กรุณาระบุหน่วยงาน' }}
                  required
                  disabled={disabled}
                />
                <ErrorMessage name="organization_id" />
              </FormControl>
            )}
          </GetOrganizationsComponent>
        </FormLayoutGroup>
      )}
      <FormLayoutGroup>
        <FormControl fullWidth error={!!form.errors['note']}>
          <TextField
            label="หมายเหตุ"
            name="note"
            variant="outlined"
            rows={3}
            size="small"
            fullWidth
            multiline
            disabled={disabled}
          />
          <ErrorMessage name="note" />
        </FormControl>
      </FormLayoutGroup>
      {mode === 'update' && (
        <Fragment>
          <FormLayoutGroup>
            <MuiTextField
              fullWidth
              variant="standard"
              disabled
              label="แก้ไขล่าสุดเมื่อวันที่"
              size="small"
              InputProps={{ readOnly: true }}
              value={DateUtils.toThaiDatetime(data?.updated_at)}
            />
            <MuiTextField
              fullWidth
              variant="standard"
              disabled
              label="สร้างเมื่อวันที่"
              size="small"
              InputProps={{ readOnly: true }}
              value={DateUtils.toThaiDatetime(data?.created_at)}
            />
          </FormLayoutGroup>
          <FormLayoutGroup>
            <MuiTextField
              fullWidth
              variant="standard"
              disabled
              label="แก้ไขโดย"
              size="small"
              InputProps={{ readOnly: true }}
              value={data?.updated_by_user?.display_name}
            />
            <MuiTextField
              fullWidth
              variant="standard"
              disabled
              label="สร้างโดย"
              size="small"
              InputProps={{ readOnly: true }}
              value={data?.created_by_user?.display_name}
            />
          </FormLayoutGroup>
        </Fragment>
      )}
    </FormLayout>
  );
};
