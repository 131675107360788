import {
  useGetLedgerByPkQuery,
  useUpdateLedgerByPkMutation,
  LedgerFragment,
  LedgerLedgerSetInput,
  LedgerLedgerStatusEnum,
  useDeleteLedgerByPkMutation,
  LedgerLogFragment,
  useApproveLedgerByPkMutation,
  useSubmitLedgerByPkMutation,
  GetLedgerByPkDocument,
  GetLedgerLogsByPkDocument,
  LedgerLedgerApproveEnum,
} from '@app/generated/graphql';
import DescriptionIcon from '@material-ui/icons/Description';
import CheckIcon from '@material-ui/icons/Check';
import RestoreIcon from '@material-ui/icons/Restore';
import { pageContent } from '@app/shared/hoc/page-content';
import ButtonCircularProgress from '@app/shared/components/ButtonCircularProgress';
import React, { useCallback } from 'react';
import PageTitle from '@app/shared/components/PageTitle';
import BackButton from '@app/shared/components/buttons/BackButton';
import { InfoCard } from '@mystiny/ui';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { FormProvider, useForm } from 'react-hook-form';
import Spacing from '@app/components/common/Spacing';
import { Button, CircularProgress, Divider, Grid, Typography } from '@material-ui/core';
import SaveButton from '@app/shared/components/buttons/SaveButton';
import Page from '@app/components/Page';
import { useIfUser } from '@app/components/common/Routes';
import { DateUtils } from '@app/shared/libs/date';
import { LedgerForm } from './LedgerForm';
import { useTypography } from '@app/shared/styles/typography';
import { useParams } from 'react-router-dom';
import { LedgerStatus } from './components/LedgerStatus';
import { LedgerVerification } from './components/LedgerWorkflow';
import DeleteButton from '@app/shared/components/buttons/DeleteButton';
import { useHistory } from 'react-router-dom';
import NotFound from '../NotFound';
import { UserPermission } from '@app/user-permission';
import LedgerStepper from './components/LedgerStepper';
import LedgerLogs from './components/LedgerLogs';
import { prompt } from '@app/shared/libs';
import { If } from '@app/components/extras';

const LedgerDetailForm = ({ defaultValue, logs }: { defaultValue: LedgerFragment; logs: LedgerLogFragment[] }) => {
  const { id: ledgerId, status_id: statusId } = defaultValue;
  const typographyClasses = useTypography();
  const history = useHistory();
  const form = useForm<LedgerFragment>({
    defaultValues: defaultValue,
  });

  const isSysadmin = useIfUser({ condition: ({ roles }) => roles.includes('sysadmin') });
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [updateFn, { loading: updating }] = useUpdateLedgerByPkMutation({
    onCompleted: () => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
    },
    onError: (err: any) => {
      console.error(err);
      enqueueFailure('เกิดข้อผิดพลาด' + err);
    },
  });

  const refetchQueries = [
    {
      query: GetLedgerByPkDocument,
      variables: {
        id: ledgerId,
      },
    },
    {
      query: GetLedgerLogsByPkDocument,
      variables: {
        ledgerId: ledgerId,
      },
    },
  ];

  const [deleteFn, { loading: deleting }] = useDeleteLedgerByPkMutation({
    onCompleted: () => {
      enqueueSuccess('ลบข้อมูลสำเร็จ');
      history.push('/bof/accounting/ledgers');
    },
    onError: (err: any) => {
      console.error(err);
      enqueueFailure('เกิดข้อผิดพลาด' + err);
    },
  });

  const [revertFn, { loading: reverting }] = useApproveLedgerByPkMutation({
    onCompleted: () => {
      enqueueSuccess('ย้อนสถานะกลับเป็นแบบร่างสำเร็จ');
    },
    onError: () => {
      enqueueFailure('ไม่สามารถย้อนสถานะกลับเป็นแบบร่างได้');
    },
    refetchQueries,
  });

  const [submitFn, { loading: submitting }] = useSubmitLedgerByPkMutation({
    onCompleted: () => {
      enqueueSuccess('ยืนยันข้อมูลสำเร็จ');
    },
    onError: () => {
      enqueueFailure('ไม่สามารถบันทึกข้อมูลได้');
    },
    refetchQueries,
  });

  const handleSave = form.handleSubmit((formData: LedgerFragment) => {
    const fiscalYear = DateUtils.getThaiFiscalYear(formData.date);

    const accountId = (formData.account as any).account_id;

    const data: LedgerLedgerSetInput = {
      date: formData.date,
      note: formData.note,
      gfmis_id: formData.gfmis_id,
      fiscal_year: fiscalYear,
      receivable_id: formData.receivable_id,
      account_id: accountId,
      budget_id: formData.budget!.id,
      amount: formData.amount,
    };

    if (isSysadmin) {
      data.organization_id = formData.organization_id;
    }

    return updateFn({
      variables: {
        id: defaultValue.id,
        _set: data,
      },
    });
  });

  const handleSubmit = useCallback(async () => {
    if (
      window.confirm(
        'ท่านแน่ใจที่จะยืนยันข้อมูลใช่หรือไม่?\n\nสถานะจะถูกปรับจาก "แบบร่าง" เป็น "รอการอนุมัติ" ซึ่งข้อมูลจะไม่สามารถถูกแก้ไขได้'
      )
    ) {
      await handleSave();
      return submitFn({
        variables: {
          data: {
            ledgerId: ledgerId,
            note: 'ยืนยันข้อมูล',
          },
        },
      });
    }
  }, [ledgerId, handleSave, submitFn]);

  const handleDraft = useCallback(() => {
    if (window.confirm('ท่านแน่ใจที่จะปรับสถานะกลับคืนเป็น "แบบร่าง" ใช่หรือไม่?')) {
      revertFn({
        variables: {
          data: {
            ledgerId: ledgerId,
            note: 'ย้อนสถานะกลับเป็นแบบร่าง',
            statusId: LedgerLedgerApproveEnum.Draft,
          },
        },
      });
    }
  }, [ledgerId, revertFn]);

  const handleDelete = useCallback(() => {
    prompt(
      'ท่านแน่ใจที่จะลบข้อมูลใช่หรือไม่?\n\nเป็นการลบข้อมูลออกจากระบบถาวร โดยที่จะไม่สามารถกู้คืนกลับมาได้\n\nโปรดพิมพ์คำว่า "delete" เพื่อยืนยันการลบข้อมูล',
      {
        value: 'delete',
        callback: () => {
          deleteFn({
            variables: {
              id: defaultValue.id,
            },
          });
        },
      }
    );
  }, [deleteFn, defaultValue]);

  const loading = reverting || submitting || updating || deleting;
  const editable = !(defaultValue.locked_at || statusId !== LedgerLedgerStatusEnum.Draft);

  return (
    <Page
      title={
        <PageTitle
          title={
            <React.Fragment>
              <Typography variant="h5" color="primary" className={typographyClasses.pageTitle}>
                <DescriptionIcon color="primary" fontSize="small" /> ข้อมูลค่าใช้จ่าย
              </Typography>
              <LedgerStatus status={defaultValue.status} />
            </React.Fragment>
          }
          actions={
            <div>
              <BackButton to={`/bof/accounting/ledgers`} />
            </div>
          }
        />
      }
    >
      <InfoCard
        actions={
          <Grid container spacing={1} justify="space-between">
            <Grid item>
              <If
                condition={[
                  LedgerLedgerStatusEnum.Draft,
                  LedgerLedgerStatusEnum.Submitted,
                  LedgerLedgerStatusEnum.ChangeRequest,
                  LedgerLedgerStatusEnum.Rejected,
                  LedgerLedgerStatusEnum.Approved,
                ].includes(statusId)}
              >
                <DeleteButton onClick={handleDelete} disabled={loading} />
              </If>
            </Grid>
            <Grid item>
              <If
                condition={[
                  LedgerLedgerStatusEnum.Draft,
                  LedgerLedgerStatusEnum.Submitted,
                  LedgerLedgerStatusEnum.ChangeRequest,
                ].includes(statusId)}
              >
                <If condition={statusId === LedgerLedgerStatusEnum.Submitted}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<RestoreIcon />}
                    disabled={loading}
                    onClick={handleDraft}
                  >
                    ย้อนสถานะกลับเป็นแบบร่าง {reverting && <ButtonCircularProgress />}
                  </Button>
                </If>
                <If condition={[LedgerLedgerStatusEnum.Draft, LedgerLedgerStatusEnum.ChangeRequest].includes(statusId)}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CheckIcon />}
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    ยืนยันข้อมูล {submitting && <ButtonCircularProgress />}
                  </Button>
                </If>
              </If>
            </Grid>
            <Grid item>
              <If condition={editable}>
                <SaveButton onClick={handleSave} disabled={loading} />
              </If>
            </Grid>
          </Grid>
        }
      >
        <LedgerStepper status={statusId} />
        <Divider />
        <Spacing />
        <Typography variant="h6" color="textSecondary" gutterBottom>
          แบบฟอร์มข้อมูลค่าใช้จ่าย
        </Typography>
        <FormProvider {...form}>
          <LedgerForm mode="update" data={defaultValue} />
        </FormProvider>
      </InfoCard>
      <Spacing />
      <UserPermission permissionIds={['ACCOUNTING_MANAGER']}>
        {statusId === LedgerLedgerStatusEnum.Submitted && <LedgerVerification ledger={defaultValue} />}
      </UserPermission>
      <Spacing />
      <LedgerLogs logs={logs} />
    </Page>
  );
};

const LedgerDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetLedgerByPkQuery({
    variables: {
      id: id,
    },
  });

  if (loading && !data?.row) {
    return <CircularProgress />;
  }

  if (!data?.row) {
    return <NotFound />;
  }

  return <LedgerDetailForm defaultValue={data?.row} logs={data?.logs} />;
};

export default pageContent(LedgerDetail);
