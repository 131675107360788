import {
  useUpdateNationalStrategyByPkMutation,
  useDeleteNationalStrategyByPkMutation,
  NationalStrategyFragment,
  useGetNationalStrategyByPkQuery,
} from '@app/generated/graphql';
import Page from '@app/components/Page';
import DescriptionIcon from '@material-ui/icons/Description';
import { pageContent } from '@app/shared/hoc/page-content';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from '@app/shared/components/PageTitle';
import BackButton from '@app/shared/components/buttons/BackButton';
import { InfoCard } from '@mystiny/ui';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { FormProvider, useForm } from 'react-hook-form';
import Spacing from '@app/components/common/Spacing';
import SaveButton from '@app/shared/components/buttons/SaveButton';
import DeleteButton from '@app/shared/components/buttons/DeleteButton';
import { Form } from './components/Form';
import { useHistory } from 'react-router-dom';

const NationalStrategyDetailForm = ({ data }: { data: NationalStrategyFragment }) => {
  const { id } = data;
  const history = useHistory();
  const form = useForm<NationalStrategyFragment>({
    defaultValues: data,
  });
  const { enqueueSuccess, enqueueFailure } = useResponseBar();

  const [updateFn, { loading: updating }] = useUpdateNationalStrategyByPkMutation({
    onCompleted: () => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
    },
    onError: (err: any) => {
      console.error(err);
      enqueueFailure('เกิดข้อผิดพลาด');
    },
  });

  const [deleteFn, { loading: deleting }] = useDeleteNationalStrategyByPkMutation({
    onCompleted: () => {
      enqueueSuccess('ลบข้อมูลสำเร็จ');
      history.push('/bof/projects/master-data/strategy-subject');
    },
    onError: (err: any) => {
      console.error(err);
      enqueueFailure('เกิดข้อผิดพลาด');
    },
  });

  const handleSubmit = form.handleSubmit((formData: NationalStrategyFragment) => {
    updateFn({
      variables: {
        id: data.id,
        _set: {
          id: formData.id,
          name: formData.name,
          parent_id: formData.parent_id,
          depth: formData.depth,
        },
      },
    });
  });

  const handleDelete = useCallback(() => {
    if (
      window.confirm(
        'ท่านแน่ใจที่จะลบข้อมูลใช่หรือไม่?\n\nเป็นการลบข้อมูลออกจากระบบถาวร โดยที่จะไม่สามารถกู้คืนกลับมาได้'
      )
    ) {
      deleteFn({
        variables: {
          id,
        },
      });
    }
  }, [id, deleteFn]);

  const loading = updating || deleting;

  return (
    <Page
      title={
        <PageTitle
          title={
            <React.Fragment>
              <Typography variant="h6" component="div">
                <DescriptionIcon color="primary" fontSize="small" /> ยุทธศาสตร์
              </Typography>
            </React.Fragment>
          }
          actions={
            <div>
              <BackButton to="/bof/projects/master-data/strategy-subject" />
            </div>
          }
        />
      }
    >
      <InfoCard
        title="ข้อมูลยุทธศาสตร์"
        actions={
          <Grid container justify="space-between">
            <Grid item>
              <DeleteButton disabled={loading} onClick={handleDelete} />
            </Grid>
            <Grid item>
              <SaveButton disabled={loading} onClick={handleSubmit} />
            </Grid>
          </Grid>
        }
      >
        <FormProvider {...form}>
          <Form mode="update" />
        </FormProvider>
      </InfoCard>
      <Spacing />
    </Page>
  );
};

const NationalStrategyDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetNationalStrategyByPkQuery({
    variables: {
      id: id,
    },
  });

  if (loading && !data?.national_strategy) {
    return <CircularProgress />;
  }

  if (!data?.national_strategy) {
    return null;
  }

  return (
    <React.Fragment>
      <NationalStrategyDetailForm data={data?.national_strategy} />
    </React.Fragment>
  );
};

export default pageContent(NationalStrategyDetailPage);
