import React, { memo } from 'react';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LedgerReceivableStatusEnum } from '@app/generated/graphql';

const useStyles = makeStyles(() => ({ root: {} }));

const statusValueMapping = {
  [LedgerReceivableStatusEnum.Draft]: 0,
  [LedgerReceivableStatusEnum.Submitted]: 1,
  [LedgerReceivableStatusEnum.Done]: 2,
};

const ReceivableStepper = ({ status }: { status: LedgerReceivableStatusEnum }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stepper activeStep={statusValueMapping[status]} alternativeLabel>
        <Step active={statusValueMapping[status] >= 0} completed={statusValueMapping[status] > 0}>
          <StepLabel>ข้อมูลเงินยืม</StepLabel>
        </Step>
        <Step active={statusValueMapping[status] >= 1} completed={statusValueMapping[status] >= 2}>
          <StepLabel>ล้างหนี้เงินยืม</StepLabel>
        </Step>
        <Step active={statusValueMapping[status] >= 2} completed={statusValueMapping[status] >= 2}>
          <StepLabel>เสร็จสิ้น</StepLabel>
        </Step>
      </Stepper>
    </div>
  );
};

export default memo(ReceivableStepper, (prev, next) => prev.status === next.status);
