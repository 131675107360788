import React, { useMemo, useState } from 'react';
import { Row } from 'react-data-grid';
import { GetOrganizationsComponent } from '@app/generated/graphql';
import { organizationColumns } from './columns';
import DataGridContainer from '@app/shared/components/DataGridContainer';
import LoadMoreRowsTag from '@app/shared/components/LoadMoreRowsTag';
import { listPageContent } from '@app/shared/hoc/list-page';
import DataGridFetchMore from '@app/shared/components/DataGridFetchMore';
import { useRdgSort } from '@app/shared/hooks/useRdgSort';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

const OrganizationList = () => {
  const history = useHistory();
  const [filters, setFilters] = useState<any>({
    name: '',
    province: '',
    area_code: '',
    unit_code: '',
    reference: '',
  });
  const [value] = useDebounce(filters, 200);
  const [offset, setOffset] = React.useState(0);
  const { handleSort, orderBy, sort } = useRdgSort();

  const organizationList = useMemo(
    () => (
      <GetOrganizationsComponent
        variables={{
          where: {
            name: {
              _ilike: value.name ? `%${value.name}%` : undefined,
            },
            province: {
              _ilike: value.province ? `%${value.province}%` : undefined,
            },
            area_code: {
              _ilike: value.area_code ? `%${value.area_code}%` : undefined,
            },
            unit_code: {
              _ilike: value.unit_code ? `%${value.unit_code}%` : undefined,
            },
            reference: {
              _ilike: value.reference ? `%${value.reference}%` : undefined,
            },
          },
          limit: 50,
          offset: 0,
          orderBy: orderBy,
        }}
        fetchPolicy="cache-and-network"
      >
        {({ data, fetchMore, loading, variables }) => (
          <DataGridContainer height="80vh">
            <DataGridFetchMore
              fetchMore={fetchMore}
              variables={variables}
              offset={offset}
              setOffset={setOffset}
              sortColumn={sort?.column}
              sortDirection={sort?.direction}
              onSort={handleSort}
              rowRenderer={(props) => (
                <Row {...props} onDoubleClick={() => history.push(`/bof/organizations/${props.row.id}`)} />
              )}
              defaultColumnOptions={{ sortable: true }}
              rows={data?.rows || []}
              columns={organizationColumns}
              filters={filters}
              onFiltersChange={setFilters}
              enableFilters
            />
            <LoadMoreRowsTag loading={loading} />
          </DataGridContainer>
        )}
      </GetOrganizationsComponent>
    ),
    [filters, setFilters, value, offset, setOffset, handleSort, orderBy, sort, history]
  );

  return <React.Fragment>{organizationList}</React.Fragment>;
};

export default listPageContent(OrganizationList);
