import { GetLedgersQuery } from '@app/generated/graphql';
import { DateUtils } from '@app/shared/libs/date';

export function mapper(data: GetLedgersQuery['rows']) {
  return data.map((entry) => {
    return {
      สถานะ: entry.status.description,
      วันที่ทำรายการ: DateUtils.toThaiDatetime(entry.date),
      รหัสบัญชี: entry.account.code,
      ชื่อบัญชี: entry.account.name,
      งบประมาณ: entry.budget?.description,
      ปีงบประมาณ: entry.fiscal_year,
      จำนวนเงิน: entry.amount,
      'เลขที่ขอเบิก GFMIS': entry.gfmis_id,
      หน่วยงาน: entry.organization?.short_name,
      รายละเอียด: entry.note,
      ทะเบียนคุมลูกหนี้เงินยืม: entry.receivable?.reference,
      วันที่สร้างรายการ: DateUtils.toThaiDatetime(entry.created_at),
    };
  });
}
