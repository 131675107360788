import { GetProjectByPkWithMasterDataDocument, useProjectSubmitFormMutation } from '@app/generated/graphql';
import SendIcon from '@material-ui/icons/Send';
import { Button } from '@material-ui/core';
import React, { Fragment, useCallback } from 'react';
import ButtonCircularProgress from '@app/shared/components/ButtonCircularProgress';
import { useProject } from '@app/views/Project/contexts/ProjectContext';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { prompt } from '@app/shared/libs';

export const ProjectSubmitButton = () => {
  const { project } = useProject();
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [submitFn, { loading }] = useProjectSubmitFormMutation({
    onCompleted: () => {
      enqueueSuccess('ส่งแบบฟอร์มเรียบร้อย');
    },
    onError: () => {
      enqueueFailure('เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง');
    },
    refetchQueries: [
      {
        query: GetProjectByPkWithMasterDataDocument,
        variables: {
          id: project?.id,
        },
      },
    ],
  });

  const handleSubmit = useCallback(() => {
    prompt(
      'คำของบประมาณของหน่วยงานท่าน\nจะไม่สามารถแก้ไขได้อีกและจะถูกพิจารณาในขั้นตอนถัดไป\n\nโปรดพิมพ์ "yes" เพื่อยืนยัน',
      {
        value: 'yes',
        callback: () => {
          submitFn({
            variables: {
              projectId: project!.id,
            },
          });
        },
      }
    );
  }, [submitFn, project]);

  return (
    <Fragment>
      <Button disabled={loading} startIcon={<SendIcon />} variant="contained" color="primary" onClick={handleSubmit}>
        Sign-Off {loading && <ButtonCircularProgress />}
      </Button>
    </Fragment>
  );
};
