import React, { Fragment } from 'react';
import TextFilter from '@app/components/data-grid/TextFilter';
import { ReceivableFragment } from '@app/generated/graphql';
import { Column } from 'react-data-grid';
import NumberFormat from 'react-number-format';
import { DateUtils } from '@app/shared/libs/date';
import { ReceivableStatus } from '../components/ReceivableStatus';

export const receivableColumns: Column<ReceivableFragment>[] = [
  {
    key: 'status',
    name: 'สถานะ',
    width: 85,
    formatter: ({ row }) => <ReceivableStatus ChipProps={{ size: 'small' }} status={row.status} />,
  },
  {
    key: 'date',
    name: 'วันที่ทำรายการ',
    width: 110,
    filterRenderer: (p) => <TextFilter {...p} />,
    formatter: ({ row }) => <Fragment>{DateUtils.toThaiDate(row.date)}</Fragment>,
  },
  {
    key: 'gfmis_id',
    name: 'เลขที่ขอเบิก GFMIS',
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'reference',
    name: 'เลขที่ใบยืม',
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'budget.description',
    name: 'งบประมาณ',
    filterRenderer: (p) => <TextFilter {...p} />,
    formatter: ({ row }) => <Fragment>{row.budget?.description}</Fragment>,
  },
  {
    key: 'borrower_name',
    name: 'ชื่อผู้ยืม',
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'proposal',
    name: 'วัตถุประสงค์',
    filterRenderer: (p) => <TextFilter {...p} />,
  },
  {
    key: 'actual_amount',
    name: 'จำนวนเงิน',
    cellClass: 'text-right',
    width: 135,
    formatter: ({ row }) => (
      <NumberFormat
        fixedDecimalScale
        decimalScale={2}
        displayType="text"
        value={row.amount - row.refund_amount}
        thousandSeparator
      />
    ),
  },
  {
    key: 'amount',
    cellClass: 'text-right',
    name: 'ยอดเงินยืม',
    formatter: ({ row }) => (
      <NumberFormat fixedDecimalScale decimalScale={2} displayType="text" value={row.amount} thousandSeparator />
    ),
  },
  {
    key: 'refund_amount',
    cellClass: 'text-right',
    name: 'ยอดเงินคืน',
    width: 135,
    formatter: ({ row }) => (
      <NumberFormat fixedDecimalScale decimalScale={2} displayType="text" value={row.refund_amount} thousandSeparator />
    ),
  },
  {
    key: 'organization.short_name',
    name: 'หน่วยงาน',
    width: 160,
    filterRenderer: (p) => <TextFilter {...p} />,
    formatter: ({ row }) => <Fragment>{row.organization.short_name}</Fragment>,
  },
  {
    key: 'note',
    name: 'หมายเหตุ',
    filterRenderer: (p) => <TextFilter {...p} />,
  },
];
