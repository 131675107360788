import { listPageContent } from '@app/shared/hoc/list-page';
import React from 'react';

const GoogleAnalytics = () => {
  return (
    <div>
      <iframe
        style={{ width: '100%', height: '90vh', border: 0 }}
        frameBorder="0"
        title="ThaiSMEFund Dashboard"
        width="100%"
        height="100%"
        allowFullScreen
        src="https://datastudio.google.com/embed/reporting/4edef17d-6b14-41c3-8c3d-cd4864b331de/page/1M"
      />
    </div>
  );
};

export default listPageContent(GoogleAnalytics);
