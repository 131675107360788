import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

export interface NumberFormatInputProps extends NumberFormatProps {
  inputRef: (instance: NumberFormat<any> | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatInput = (props: NumberFormatInputProps) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      allowEmptyFormatting={false}
      allowNegative={false}
      min={0}
      thousandSeparator
      {...other}
      getInputRef={inputRef}
      onValueChange={(values: any) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
};

export const createNumberFormatInput =
  (props: Partial<NumberFormatInputProps>) => (overrides: NumberFormatInputProps) =>
    <NumberFormatInput {...props} {...overrides} />;

export default NumberFormatInput;
