import { Avatar, Button } from '@material-ui/core';

const LoginWithIndustrySSO = () => {
  return (
    <Button
      size="large"
      variant="contained"
      component="a"
      href="https://authen.industry.go.th/"
      color="primary"
      fullWidth
    >
      <Avatar
        style={{
          width: 32,
          height: 32,
          marginRight: 8,
        }}
        src="/industry-logo.png"
      />{' '}
      เข้าด้วยระบบยืนยันตัวตน Industry SSO
    </Button>
  );
};

export default LoginWithIndustrySSO;
